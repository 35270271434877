import { MessagePlus } from "components/icons";
import SelectedRowsContext from "components/ui/data-grid/SelectedRowsContext";
import DropdownItem from "components/ui/Dropdown/components/DropdownItem";
import { NotificationStatus } from "components/ui/Notifications/NotificationSnackbar";
import Tooltip from "components/ui/Tooltip";
import { T } from "components/ui/Typography";
import * as Pages from "config/routes/Pages";
import usePushNotification from "hooks/usePushNotification";
import useSegment from "hooks/useSegment";
import useUpdateAttributesForAllRecords from "hooks/useUpdateAttributesForAllRecords";
import useUserProfile from "hooks/useUserProfile";
import _ from "lodash";
import { makeStyles } from "makeStyles";
import PartnerConnection from "models/PartnerConnection";
import Record from "models/Record";
import { JSONAPIResource } from "models/types";
import { useContext, useState } from "react";
import { defineMessages, FormattedMessage, useIntl } from "react-intl";
import { useDispatch } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import { bulkAddRecords, updateAttributesOnRecord } from "redux/api/actions";
import { NotificationActionType } from "redux/notifications/typing";
import JSONAPIService from "services/JSONAPIService";
import { PipelineEvent, PipelineItemSource } from "tracking";

export type AddToPipelineOptionProps = {
  // For Account Mapping
  partnerName?: string;
  partnershipId?: number | null;
  isDemo?: boolean;
  // For Account Mapping and Mapping 360
  disabled: boolean;
  rows: Record[];
};

const AddToPipelineOption = ({
  partnerName,
  partnershipId = null,
  isDemo,
  disabled,
  rows,
}: AddToPipelineOptionProps) => {
  const { classes } = useStyles();
  const location = useLocation();
  const page = Pages.fromLocation(location);
  const history = useHistory();

  const pushNotification = usePushNotification();
  const [loading, setLoading] = useState(false);
  const { clearSelection } = useContext(SelectedRowsContext);

  const selectedRowsWithoutPipeline = getRowsWithoutPipeline(rows, page);
  const updateAllRecords = useUpdateAttributesForAllRecords(
    rows[0]?.type ?? ""
  );
  const dispatch = useDispatch();
  const { track } = useSegment();
  const { profile } = useUserProfile();
  const intl = useIntl();

  const countPart =
    rows.length > 0
      ? intl.formatMessage(i18n.selectedCount, {
          selectedRowCount: rows.length,
        })
      : "";

  // For Account Mapping only, available types are "shared_accounts", "matches"
  const updateAMAfterPartnerConnectionCreation = (
    createdPartnerConnections: PartnerConnection[]
  ) => {
    createdPartnerConnections.forEach(
      (partnerConnection: PartnerConnection) => {
        const isPartnerConnectionNewProspect =
          typeof partnerConnection.rawCompanyId !== "number" &&
          !partnerConnection.rawCompanyId;

        updateAllRecords(
          { hasPartnerConnection: true },
          isPartnerConnectionNewProspect
            ? {
                partnershipId: partnerConnection.partnershipId,
                rightCountryCode: partnerConnection.newProspectCountry,
                rightDomain: partnerConnection.newProspectDomain,
              }
            : {
                partnershipId: partnershipId,
                rawCompanyId: partnerConnection.attributes.raw_company_id,
              }
        );
      }
    );
  };

  // For 360 0nly, available types are "crm_accounts", "nearbound_accounts", "nearbound_prospects"
  const updateRawCompaniesAfterCreation = (
    createdPartnerConnections: PartnerConnection[]
  ) => {
    selectedRowsWithoutPipeline.forEach((row) => {
      if (row.type === "crm_accounts") {
        dispatch(
          updateAttributesOnRecord(row, {
            partnerConnectionCount: row.partnerConnectionCount + 1,
          })
        );
      } else if (row.type === "nearbound_accounts") {
        dispatch(
          updateAttributesOnRecord(row, {
            partnerDiscussionFor: [
              ...row.partnerDiscussionFor,
              ...createdPartnerConnections.map((item) => ({
                partner_connection_id: Number(item.id),
                partner_name: item.partnerName,
                partnership_id: item.partnershipId,
              })),
            ],
          })
        );
      } else if (row.type === "nearbound_prospects") {
        dispatch(
          updateAttributesOnRecord(row, {
            partnerConnectionFor: [
              ...row.partnerConnectionFor,
              ...createdPartnerConnections.map((item) => ({
                partner_connection_id: Number(item.id),
                partner_name: item.partnerName,
                partnership_id: item.partnershipId,
              })),
            ],
          })
        );
      } else {
        throw new Error("Unknown row type in updateRawCompaniesAfterCreation");
      }
    });
  };

  const createPipelines = async () => {
    if (isDemo) {
      pushNotification({ ...i18n.notOnDemo });
      clearSelection();
    } else {
      setLoading(true);
      const service = new JSONAPIService("partner_connections");
      try {
        const response = await service.rawPost<{ data: any }>(
          "",
          "bulk/",
          {
            data: getPipelineToCreatePayload(
              profile.id,
              page,
              selectedRowsWithoutPipeline,
              partnershipId
            ),
          },
          { include: ["discussion_participants"] }
        );
        await dispatch(bulkAddRecords(response.data));

        const createdPartnerConnections = (response.data
          .data as JSONAPIResource<"partner_connections">[]).map(
          (item) => new PartnerConnection(item)
        );
        if (createdPartnerConnections.length === 1) {
          history.push({
            search: `?discussion=${createdPartnerConnections[0].id}`,
          });
        }
        switch (page) {
          case Pages.Enum.AccountMapping:
            updateAMAfterPartnerConnectionCreation(createdPartnerConnections);
            break;
          case Pages.Enum.MyAccounts:
            updateRawCompaniesAfterCreation(createdPartnerConnections);
            break;
          default:
            throw new Error("Called from Not Found Page, in Raw Post");
        }
        pushNotification(
          {
            status: NotificationStatus.success,
            message:
              page === Pages.Enum.AccountMapping
                ? i18n.partnerConnectionsCreatedWith
                : i18n.partnerConnectionsCreated,
          },
          {
            accountName:
              selectedRowsWithoutPipeline.length === 1
                ? selectedRowsWithoutPipeline[0].leftName ??
                  selectedRowsWithoutPipeline[0].rightName
                : "",
            count: createdPartnerConnections.length,
            partnershipId,
            partnerName,
          },
          {
            type: NotificationActionType.addedToPipelineActions,
            partnerConnections: createdPartnerConnections,
            rawCompanies:
              page === Pages.Enum.MyAccounts ? selectedRowsWithoutPipeline : [],
          }
        );
        track(PipelineEvent.addToPipeline, {
          from: Pages.toString(page),
          bulk: true,
          number_created: createdPartnerConnections.length,
        });
      } catch (_error: any) {
        pushNotification(i18n.partnerConnectionsCreationFailed);
      } finally {
        setLoading(false);
        clearSelection();
      }
    }
  };

  return (
    <>
      <Tooltip
        title={
          rows.length === 0 ? (
            <FormattedMessage {...i18n.noAccountSelected} />
          ) : selectedRowsWithoutPipeline.length === 0 ? (
            <FormattedMessage {...i18n.pipelineAlreadyExists} />
          ) : (
            ""
          )
        }
        placement="top"
      >
        <span data-testid="addToPipelineBtn">
          <DropdownItem
            name={
              <>
                <T className={classes.title}>
                  <MessagePlus className={classes.icon} />
                  <FormattedMessage
                    {...i18n.addToPipeline}
                    values={{
                      countPart,
                    }}
                  />
                </T>
              </>
            }
            onChange={createPipelines}
            isDisabled={disabled || selectedRowsWithoutPipeline.length === 0}
            isLoading={loading}
          />
        </span>
      </Tooltip>
    </>
  );
};

export default AddToPipelineOption;

// Helpers

const getRowsWithoutPipeline = (selectedRows: Record[], page: Pages.Enum) => {
  switch (page) {
    case Pages.Enum.AccountMapping:
      return selectedRows.filter(
        (row: Record) => _.get(row, "hasPartnerConnection", false) === false
      );

    case Pages.Enum.MyAccounts:
      return selectedRows;
    default:
      throw new Error("Called from NotFound page, in getRowsWithoutPipeline");
  }
};

const getPipelineToCreatePayload = (
  userId: number,
  page: Pages.Enum,
  rows: Record[],
  partnershipId?: number | null
) => {
  const nonConflictingRows = _.uniqBy(
    rows,
    (row: Record) => row.rawCompanyId || row.leftRawCompanyId || row.id
  );
  return _.map(nonConflictingRows, (row: Record) => {
    let newProspectAttributes = {};
    switch (row.type) {
      case "shared_accounts":
        if (!!row.rightName) {
          newProspectAttributes = {
            new_prospect_name: row.rightName,
            new_prospect_country: row.rightCountryCode,
            new_prospect_domain: row.rightDomain,
            raw_company_id: null,
          };
        }
        if (!!row.leftName) {
          newProspectAttributes = {
            raw_company_id: row.rawCompanyId,
          };
        }
        break;
      case "nearbound_prospects":
        newProspectAttributes = {
          new_prospect_name: row.name,
          new_prospect_country: row.countryIsoCode,
          new_prospect_domain: row.domain,
          raw_company_id: null,
        };
        break;
      case "crm_accounts":
        newProspectAttributes = { raw_company_id: row.id };
        break;
      case "nearbound_accounts":
      case "matches":
        newProspectAttributes = { raw_company_id: row.rawCompanyId };
        break;
      case "matched_accounts":
        newProspectAttributes = { raw_company_id: row.leftRawCompanyId };
        break;

      default:
        throw new Error("Unknown row type in getPipelineToCreatePayload");
    }

    return {
      type: "partner_connections",
      attributes: {
        user_id: userId,
        ...newProspectAttributes,
        partnership_id: partnershipId,
        source:
          page === Pages.Enum.AccountMapping
            ? row.type === "shared_accounts"
              ? PipelineItemSource.newProspects
              : PipelineItemSource.accountMapping
            : page === Pages.Enum.MyAccounts
            ? row.type === "nearbound_prospects"
              ? PipelineItemSource.newProspects360
              : PipelineItemSource.mapping360
            : "",
      },
    };
  });
};

/// CSS

const useStyles = makeStyles()((theme) => ({
  icon: {
    color: theme.palette.midnight,
    width: theme.spacing(2),
    height: theme.spacing(2),
  },
  title: {
    display: "flex",
    alignItems: "center",
    columnGap: 6,
  },
}));

// I18N

const i18n = defineMessages({
  addToPipeline: {
    id: "crm.AddToPipelineOption.addToPipeline",
    defaultMessage: "Add {countPart} to Collaborate",
  },
  selectedCount: {
    id: "crm.AddToPipelineOption.selectedCount",
    defaultMessage: "{selectedRowCount} selected",
  },
  notOnDemo: {
    id: "crm.AddToPipelineOption.notOnDemo",
    defaultMessage: "Not available on demo",
  },
  noAccountSelected: {
    id: "crm.AddToPipelineOption.noAccountSelected",
    defaultMessage: "Start collaborating on your accounts",
  },
  pipelineAlreadyExists: {
    id: "crm.AddToPipelineOption.pipelineAlreadyExists",
    defaultMessage: "Selected accounts are already in Collaborate",
  },
  partnerConnectionsCreationFailed: {
    id: "crm.AddToPipelineOption.partnerConnectionsCreationFailed",
    defaultMessage:
      "Something went wrong, accounts could not be added to Collaborate",
  },
  partnerConnectionsCreated: {
    id: "crm.AddToPipelineOption.partnerConnectionsCreated",
    defaultMessage:
      "{count} {count, plural, one {account has} other {accounts have}} been successfully added to Collaborate",
  },
  partnerConnectionsCreatedWith: {
    id: "crm.AddToPipelineOption.partnerConnectionsCreatedWith",
    defaultMessage:
      "{count, plural, one {{accountName} has} other {{count} accounts have}} been successfully added to Collaborate with {partnerName}",
  },
});

export const _private = {
  i18n,
};
