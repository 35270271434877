import { Divider } from "@mui/material";
import Toggle from "components/ui/Toggle";
import { Props as ToggleProps } from "components/ui/Toggle";
import { T } from "components/ui/Typography";
import { makeStyles } from "makeStyles";
import { FormattedMessage, MessageDescriptor } from "react-intl";

export type CustomToggleProps = {
  i18nLabel: MessageDescriptor;
  toggleProps: ToggleProps;
};

export type CustomSectionProps = {
  title: MessageDescriptor;
  toggleList: CustomToggleProps[];
};

export const CustomSection = ({ title, toggleList }: CustomSectionProps) => {
  const { classes } = useStyles();

  return (
    <>
      <T titleSmall className={classes.title}>
        <FormattedMessage {...title} />
      </T>

      {toggleList?.map((toggle, index) => (
        <label className={classes.toggleItem} key={index}>
          <T>
            <FormattedMessage {...toggle.i18nLabel} />
          </T>
          <Toggle {...toggle.toggleProps} />
        </label>
      ))}

      <Divider className={classes.divider} />
    </>
  );
};

// CSS

const useStyles = makeStyles()((theme) => ({
  titleContainer: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    paddingRight: 12,
  },
  title: {
    padding: "8px 6px",
  },
  toggleItem: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    paddingLeft: 6,
    paddingRight: theme.spacing(1),
    marginBottom: 6,
  },
  divider: {
    height: 1,
    backgroundColor: theme.palette.taupe500,
    border: "none",
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
}));
