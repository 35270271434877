import Grid from "@mui/material/Grid";
import { ArrowRight } from "components/icons";
import Alert from "components/ui/Alert";
import Button from "components/ui/Button";
import {
  PasswordConstraints,
  passwordSchema,
} from "components/ui/PasswordConstraints";
import { TextInput } from "components/ui/TextInput";
import { Formik } from "formik";
import forms from "i18n/forms";
import generic from "i18n/generic";
import { makeStyles } from "makeStyles";
import { useEffect, useRef } from "react";
import { FormattedMessage, MessageDescriptor, useIntl } from "react-intl";
import { object, string } from "yup";

import i18n from "./i18n";
import LinkToLogin from "./LinkToLogin";
import { InitialValues } from "./types";

const schema = object().shape({
  email: string()
    .required(() => forms.email_required.id)
    .email(() => forms.email_invalid.id),
  first_name: string().required(() => forms.first_name_required),
  last_name: string().required(() => forms.last_name_required),
  password: passwordSchema, // pragma: allowlist secret
});

type Props = {
  disableEmail?: boolean;
  initialValues: InitialValues;
  onSubmit: $TSFixMeFunction;
};

function getEmailError(errorId: string): MessageDescriptor {
  return { id: errorId };
}

const BaseRegistrationForm = ({
  onSubmit,
  initialValues,
  disableEmail,
}: Props) => {
  const { classes } = useStyles();
  const intl = useIntl();
  const inputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    inputRef.current?.focus();
  }, []);

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={onSubmit}
      validateOnMount
      validationSchema={schema}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        isSubmitting,
        touched,
        values,
      }) => (
        <form onSubmit={handleSubmit} className={classes.root}>
          <Grid container spacing={1}>
            <Grid item xs={12}>
              {touched.email && errors.email !== undefined && (
                <Alert variant="error" icon>
                  <FormattedMessage {...getEmailError(errors.email)} />
                </Alert>
              )}
            </Grid>
            <Grid item xs={12}>
              <TextInput
                variant="tertiary"
                inputProps={{ autoComplete: "email" }}
                fullWidth
                name="email"
                onBlur={handleBlur}
                onChange={handleChange}
                placeholder={intl.formatMessage(generic.email)}
                value={values.email}
                disabled={disableEmail || isSubmitting}
                error={Boolean(errors.email && touched.email)}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextInput
                variant="tertiary"
                inputRef={inputRef}
                inputProps={{ autoComplete: "given-name" }}
                fullWidth
                name="first_name"
                onBlur={handleBlur}
                onChange={handleChange}
                placeholder={intl.formatMessage(generic.first_name)}
                disabled={isSubmitting}
                error={Boolean(errors.first_name && touched.first_name)}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextInput
                variant="tertiary"
                inputProps={{ autoComplete: "family-name" }}
                fullWidth
                name="last_name"
                onBlur={handleBlur}
                onChange={handleChange}
                placeholder={intl.formatMessage(generic.last_name)}
                disabled={isSubmitting}
                error={Boolean(errors.last_name && touched.last_name)}
              />
            </Grid>
            <Grid item xs={12}>
              <TextInput
                variant="tertiary"
                inputProps={{ autoComplete: "new-password" }}
                fullWidth
                name="password"
                onBlur={handleBlur}
                onChange={handleChange}
                placeholder={intl.formatMessage(generic.password)}
                type="password"
                disabled={isSubmitting}
              />
              <PasswordConstraints value={values.password} />
            </Grid>
            <Grid item xs={12}>
              <Button
                type="submit"
                label={i18n.next}
                loading={isSubmitting}
                RightIcon={ArrowRight}
                classes={{ btn: classes.submitBtn }}
              />
              <LinkToLogin />
            </Grid>
          </Grid>
        </form>
      )}
    </Formik>
  );
};

export default BaseRegistrationForm;

// STYLES

const useStyles = makeStyles()((theme) => ({
  root: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    marginBottom: 25,
  },
  submitBtn: {
    marginBottom: 25,
    width: "100%",
  },
}));
