import _ from "lodash";
import { FilterType } from "screens/Frontoffice/screens/DataTables/shared/types";

import { RootStateWithDirectory } from "./types";

export const selectDirectoryView = (state: RootStateWithDirectory) =>
  state.directory;

export const selectHasFilterApplied = (state: RootStateWithDirectory) => {
  const filters: FilterType[] = state.directory.view?.filters ?? [];
  const appliedFilters = filters.filter(
    (item) =>
      !["is_in_crm", "is_you_may_know", "is_recommended"].includes(
        item.fieldname
      )
  );
  let hasFilterApplied = false;
  _.each(appliedFilters, (filter) => {
    hasFilterApplied = true;
  });
  return hasFilterApplied;
};
