import Grid from "@mui/material/Grid";
import { T } from "components/ui/Typography";
import produce from "immer";
import _ from "lodash";
import { useMemo } from "react";
import { defineMessages, FormattedMessage } from "react-intl";
import {
  FieldType,
  FilterType,
} from "screens/Frontoffice/screens/DataTables/shared/types";
import FilterRow from "screens/Frontoffice/shared/components/Filters/FilterRow";

type Props = {
  filters: FilterType[];
  fields: Record<string, FieldType>;
  onChange: (filters: FilterType[]) => void;
  partnerName: string;
  disabled?: boolean;
  displayEmpty?: boolean;
};

const Filters = ({
  disabled = false,
  displayEmpty = true,
  filters,
  fields,
  onChange,
  partnerName,
}: Props) => {
  const usedFieldNames = filters.map((filter) => filter.fieldname);
  const availableFields = useMemo(
    () =>
      _.reduce(
        fields,
        (acc, field, name) => {
          acc[name] = { ...field, isDisabled: usedFieldNames.includes(name) };
          return acc;
        },
        {} as Record<string, FieldType>
      ),
    [usedFieldNames, fields]
  );

  const updateAtIndex = (index: number) => (filter: FilterType) =>
    onChange(
      produce(filters, (draft) => {
        draft[index] = filter;
      })
    );

  const removeAtIndex = (index: number) => () =>
    onChange(
      produce(filters, (draft) => {
        draft.splice(index, 1);
      })
    );

  return (
    <Grid container spacing={2} direction="column">
      {filters.map((filter, index) => (
        <Grid key={index} item>
          <FilterRow
            filter={filter}
            onChange={updateAtIndex(index)}
            onRemove={removeAtIndex(index)}
            fields={availableFields}
            partnerName={partnerName}
            disabled={disabled}
          />
        </Grid>
      ))}
      {filters.length === 0 && displayEmpty && (
        <Grid item>
          <T>
            <FormattedMessage {...i18n.noFilters} />
          </T>
        </Grid>
      )}
      {!disabled && (
        <Grid item>
          <FilterRow
            isAdding
            fields={availableFields}
            onChange={updateAtIndex(filters.length)}
            onRemove={removeAtIndex(filters.length)}
            partnerName={partnerName}
          />
        </Grid>
      )}
    </Grid>
  );
};

export default Filters;

/// I18N

const i18n = defineMessages({
  noFilters: {
    id: "Filters.noFilters",
    defaultMessage: "There are no filters applied.",
  },
});
