import { CircularProgress } from "@mui/material";
import { AxiosResponse } from "axios";
import Tag from "components/ui/Tag";
import { T } from "components/ui/Typography";
import { makeStyles } from "makeStyles";
import { Factory } from "models";
import Partnership from "models/Partnership";
import Record from "models/Record";
import { useEffect, useState } from "react";
import { defineMessages, FormattedMessage } from "react-intl";
import JSONAPIService from "services/JSONAPIService";
import { JSONAPIListResponse } from "services/types";

const i18n = defineMessages({
  partnerNotSharing: {
    id:
      "crm.PartnershipSettings.GeneralTab.OpportPartnerDetail.partnerNotSharing",
    defaultMessage: "{partnerName} has not activated Data Sharing yet.",
  },
  opportsNotShared: {
    id:
      "crm.PartnershipSettings.GeneralTab.OpportPartnerDetail.opportsNotShared",
    defaultMessage:
      "{partnerName} has not granted you access to any Open deal information.",
  },
  opportsShared: {
    id: "crm.PartnershipSettings.GeneralTab.OpportPartnerDetail.opportsShared",
    defaultMessage:
      "{partnerName} is sharing whether the account has an open deal or not, but is not sharing additional information about the deal.",
  },
});

type Props = {
  partnership: Partnership;
  partner: Record;
  isPartnerSharing: boolean;
};

const OpportPartnerDetail = ({
  partnership,
  partner,
  isPartnerSharing,
}: Props) => {
  const { classes } = useStyles();
  const opportsSharing = partnership.getAccountOpportsSharing(partner);

  const [sharedFields, setSharedFields] = useState<Record[]>([]);
  const [sharedFieldsLoading, setSharedFieldsLoading] = useState<boolean>(true);

  useEffect(() => {
    const fetchShareFields = async () => {
      setSharedFieldsLoading(true);
      const service = new JSONAPIService("partnerships");
      const response = (await service.fetchRelated(
        partnership.id,
        "partner-shared-opportunity-fields/"
      )) as AxiosResponse<JSONAPIListResponse>;
      const partnershipCrmFields = response?.data.data
        .map((rawRecord) => Factory.createRecord(rawRecord))
        .sort((record1, record2) =>
          record1.displayIndex > record2.displayIndex ? 1 : -1
        );
      setSharedFields(partnershipCrmFields);
    };
    fetchShareFields().then(() => setSharedFieldsLoading(false));
  }, [partnership.id]);

  if (!isPartnerSharing) {
    return (
      <T>
        <FormattedMessage
          {...i18n.partnerNotSharing}
          values={{ partnerName: partner.name }}
        />
      </T>
    );
  } else if (!opportsSharing) {
    return (
      <T>
        <FormattedMessage
          {...i18n.opportsNotShared}
          values={{ partnerName: partner.name }}
        />
      </T>
    );
  } else if (sharedFieldsLoading) {
    return <CircularProgress size={32} />;
  } else if (sharedFields.length === 0) {
    return (
      <T>
        <FormattedMessage
          {...i18n.opportsShared}
          values={{ partnerName: partner.name }}
        />
      </T>
    );
  }
  return (
    <div className={classes.container}>
      {sharedFields.map((field) => (
        <Tag label={field.crmFieldLabel} />
      ))}
    </div>
  );
};

const useStyles = makeStyles()((theme) => ({
  container: {
    display: "flex",
    columnGap: 4,
  },
}));

export default OpportPartnerDetail;
