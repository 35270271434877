import CircularProgress from "@mui/material/CircularProgress";
import { Download } from "components/icons";
import Button from "components/ui/Button";
import DropdownItem from "components/ui/Dropdown/components/DropdownItem";
import Tooltip from "components/ui/Tooltip";
import { T } from "components/ui/Typography";
import { makeStyles } from "makeStyles";
import { defineMessages, FormattedMessage } from "react-intl";
import { ReportType } from "screens/Frontoffice/screens/DataTables/shared/utils";

type ExportCsvProps = {
  // For Account Mapping
  isDemo?: boolean;
  isDisabled?: boolean;
  // For Pipeline, Account Mapping and Mapping 360
  reportType: ReportType;
  isCsvGenerating: boolean;
  isCsvReady: boolean;
  downloadCsv: () => void;
  selectedRowsCount?: number;
};

type ExportCrmProps = {
  // For Account Mapping
  tooltip?: JSX.Element;
};

type Props = ExportCsvProps & ExportCrmProps;

const ExportCsv = ({
  isDemo,
  isDisabled,
  reportType,
  isCsvGenerating,
  isCsvReady,
  downloadCsv,
  tooltip,
  selectedRowsCount = 0,
}: Props) => {
  const { classes } = useStyles();
  const pipelineButton = reportType === ReportType.COLLABORATE;

  const getLabel = () => {
    if (isCsvGenerating) return i18n.generatingAsCsv;
    if (pipelineButton) return i18n.downloadAsCsv;
    if (selectedRowsCount > 0) return i18n.downloadSelectedAsCsv;
    return i18n.downloadAllAsCsv;
  };

  const isDemoTooltip = (
    <T>
      <FormattedMessage {...i18n.notAvailableForDemo} />
    </T>
  );

  const btn = (
    <Button
      label={
        <>
          <T className={classes.title}>
            <Download className={classes.icon} />
            <FormattedMessage
              {...getLabel()}
              values={{ count: selectedRowsCount }}
            />
          </T>
        </>
      }
      variant="secondary"
      onClick={downloadCsv}
      disabled={isDemo || isCsvGenerating || isDisabled}
      size={pipelineButton ? "small" : undefined}
    />
  );

  const item = (
    <DropdownItem
      name={
        <>
          <T className={classes.title}>
            <Download className={classes.icon} />
            <FormattedMessage
              {...getLabel()}
              values={{ count: selectedRowsCount }}
            />
          </T>
        </>
      }
      onChange={downloadCsv}
      isDisabled={isDemo || isCsvGenerating || isDisabled}
    />
  );

  if (isDemo || isDisabled) {
    return (
      <Tooltip title={isDemo ? isDemoTooltip : tooltip} placement="bottom">
        <span>{item}</span>
      </Tooltip>
    );
  }

  return isCsvReady ? (
    pipelineButton ? (
      btn
    ) : (
      item
    )
  ) : (
    <div data-testid="export-csv-loader" className={classes.progressContainer}>
      <CircularProgress color="inherit" size={16} />
    </div>
  );
};

export default ExportCsv;

/// CSS

const useStyles = makeStyles()((theme) => ({
  icon: {
    color: theme.palette.midnight,
    width: 15,
    height: 15,
  },
  title: {
    display: "flex",
    alignItems: "center",
    columnGap: 6,
  },
  progressContainer: {
    display: "flex",
    justifyContent: "center",
  },
}));

// I18N

const i18n = defineMessages({
  notAvailableForDemo: {
    id: "crm.Reports.ExportCsv.notAvailableForDemo",
    defaultMessage: "Not available for the Demonstration Partner",
  },
  downloadAsCsv: {
    id: "crm.Reports.ExportCsv.downloadAsCsv",
    defaultMessage: "Download as CSV",
  },
  downloadSelectedAsCsv: {
    id: "crm.Reports.ExportCsv.downloadSelectedAsCsv",
    defaultMessage: "Download {count} selected as CSV",
  },
  downloadAllAsCsv: {
    id: "crm.Reports.ExportCsv.downloadAllAsCsv",
    defaultMessage: "Download all as CSV",
  },
  generatingAsCsv: {
    id: "crm.Reports.ExportCsv.generatingAsCsv",
    defaultMessage: "Generating as CSV",
  },
});

export const _private = {
  i18n,
};
