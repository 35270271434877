import { createMakeStyles } from "tss-react/compat";

import muiTheme from "./config/theme";

function useTheme() {
  return muiTheme;
}

export const {
  makeStyles,
  useStyles, //<- To use when you need css or cx but don't have custom classes
} = createMakeStyles({ useTheme });
