import { VisibilityOutlined } from "@mui/icons-material";
import FiberManualRecord from "@mui/icons-material/FiberManualRecord";
import { Box, ListItem, ListItemAvatar, ListItemText } from "@mui/material";
import { Plus } from "components/icons";
import CompanyAvatar from "components/ui/avatars/CompanyAvatar";
import Button from "components/ui/Button";
import { T } from "components/ui/Typography";
import { getTimeSince } from "helpers/dateUtils";
import useSegment from "hooks/useSegment";
import useUserProfile from "hooks/useUserProfile";
import { makeStyles } from "makeStyles";
import Notification, { NOTIFICATION_TYPE_NAME } from "models/Notification";
import { MouseEvent } from "react";
import { defineMessages, FormattedHTMLMessage, useIntl } from "react-intl";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import {
  setSelectedPartner,
  setSelectedPartnershipId,
} from "redux/notificationWidget/actions";
import { markNotificationAsRead } from "redux/notificationWidget/thunks";
import { NotificationEvent } from "tracking";

type Props = {
  notification: Notification;
  onClose: () => void;
};

export const NotificationItem = ({ notification, onClose }: Props) => {
  const dispatch = useDispatch();
  const intl = useIntl();
  const { classes } = useStyles();
  const { profile } = useUserProfile();
  const { track } = useSegment();
  const history = useHistory();

  const partner = notification.partnership.getPartner(profile);

  const lastUpdate =
    notification.updatedAt === null
      ? notification.createdAt
      : notification.updatedAt;

  const markAsRead = async () => {
    await dispatch(markNotificationAsRead(notification));
  };

  const getTextForNotificationType = () => {
    switch (notification.notificationType) {
      case Notification.TYPE_REFERRED_ACCOUNT:
        return (
          <FormattedHTMLMessage
            {...i18n.hasReferred}
            values={{
              partner: partner.name,
              count: notification.information.number_of_referred_accounts,
            }}
          />
        );
      case Notification.TYPE_ADDED_DISCUSSIONS:
        return (
          <>
            <FormattedHTMLMessage
              {...i18n.hasReferred}
              values={{
                partner: partner.name,
                count: notification.information.number_of_referred_accounts,
              }}
            />
            <br />
            <FormattedHTMLMessage {...i18n.addedDiscussions} />
          </>
        );
      case Notification.TYPE_MISSING_IN_CRM:
        return (
          <>
            <FormattedHTMLMessage
              {...i18n.hasReferred}
              values={{
                partner: partner.name,
                count: notification.information.number_of_referred_accounts,
              }}
            />
            <br />
            <T className={classes.danger}>
              <FormattedHTMLMessage
                {...i18n.addToCrm}
                values={{
                  count: notification.information.number_of_referred_accounts,
                }}
              />
            </T>
          </>
        );
      default:
        return null;
    }
  };

  const getActionContent = () => {
    switch (notification.notificationType) {
      case Notification.TYPE_ADDED_DISCUSSIONS:
        return {
          onClick: handleRedirectCollaborate,
          element: (
            <Button
              onClick={handleRedirectCollaborate}
              label={i18n.seeDiscussions}
              LeftIcon={VisibilityOutlined}
              size="xSmall"
            />
          ),
        };
      case Notification.TYPE_MISSING_IN_CRM:
        return {
          onClick: handleButtonClick,
          element: (
            <Button
              onClick={handleButtonClick}
              label={i18n.createDiscussions}
              LeftIcon={Plus}
              size="xSmall"
            />
          ),
        };
      case Notification.TYPE_REFERRED_ACCOUNT:
        return {
          onClick: handleNotificationClick,
        };
      default:
        throw new Error("Invalid notification type");
    }
  };

  const handleRedirectCollaborate = (event: MouseEvent) => {
    event.stopPropagation();
    event.preventDefault();
    onClose();
    history.push({
      pathname: "/pipeline",
      search: `?referring-partnership=${notification.partnership.id}`,
    });
    track(NotificationEvent.clickedOnNotification, {
      notificationType: NOTIFICATION_TYPE_NAME[notification.notificationType],
    });
    markAsRead();
  };

  const handleButtonClick = (event: MouseEvent) => {
    event.stopPropagation();
    event.preventDefault();
    onClose();
    handleNotificationClick();
  };

  const handleNotificationClick = () => {
    dispatch(setSelectedPartner(partner));
    dispatch(setSelectedPartnershipId(notification.partnership.id));
    track(NotificationEvent.clickedOnNotification, {
      notificationType: NOTIFICATION_TYPE_NAME[notification.notificationType],
    });
    markAsRead();
  };

  const handleRedirectCollaborateNoModal = (event: MouseEvent) => {
    onClose();
    history.push({
      pathname: "/pipeline",
      search: `?referring-partnership=${notification.partnership.id}`,
    });
    track(NotificationEvent.clickedOnNotification, {
      notificationType: NOTIFICATION_TYPE_NAME[notification.notificationType],
    });
    markAsRead();
  };

  return (
    <ListItem
      className={classes.listItem}
      onClick={handleRedirectCollaborateNoModal}
    >
      <ListItemAvatar>
        <div className={classes.leftContainer}>
          {!notification.readByUser && (
            <FiberManualRecord className={classes.dot} />
          )}
          <CompanyAvatar
            size="sm"
            company={partner}
            classes={{ avatar: classes.avatar }}
          />
        </div>
      </ListItemAvatar>
      <Box>
        <ListItemText
          className={classes.listItemText}
          primary={<T>{getTextForNotificationType()}</T>}
          secondary={
            <T className={classes.time}>
              {getTimeSince(lastUpdate.toISOString(), intl)}
            </T>
          }
        />
        {getActionContent()?.element}
      </Box>
    </ListItem>
  );
};

export default NotificationItem;

/// CSS

const useStyles = makeStyles()((theme, props) => ({
  listItem: {
    cursor: "pointer",
    paddingLeft: 12,
    paddingRight: 12,
    paddingTop: 6,
    paddingBottom: 6,
    borderRadius: 4,
    alignItems: "flex-start",
    "&:hover": {
      background: theme.palette.taupe250,
    },
    "&:not(:last-child)": {
      "&::after": {
        content: `''`,
        borderBottom: `1px solid ${theme.palette.taupe500}`,
        position: "absolute",
        left: theme.spacing(1),
        right: theme.spacing(1),
        bottom: 0,
      },
    },
  },
  listItemText: {
    marginBottom: 8,
    marginTop: 0,
  },
  leftContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    width: 42,
  },
  dot: {
    height: 6,
    width: 6,
    color: theme.palette.green600,
  },
  avatar: {
    marginLeft: theme.spacing(1),
  },
  time: {
    color: theme.palette.midnight500,
    fontSize: 11,
    lineHeight: "16.5px",
  },
  danger: {
    color: theme.palette.red500v2,
  },
}));

// I18N

const i18n = defineMessages({
  addedDiscussions: {
    id: "Navbar.NotificationItem.addedDiscussions",
    defaultMessage: "They have been added to Collaborate.",
  },
  addToCrm: {
    id: "Navbar.NotificationItem.adToCrm",
    defaultMessage:
      "Add {count, plural, one {1 account} other {# accounts}} to your CRM to create these Collaborations",
  },
  createDiscussions: {
    id: "Navbar.NotificationItem.createDiscussions",
    defaultMessage: "Add to Collaborate",
  },
  hasReferred: {
    id: "Navbar.NotificationItem.hasReferred",
    defaultMessage:
      "<b>{partner}</b> has referred <b>{count, plural, one {1 account} other {# accounts}}</b>",
  },
  noPendingReferrals: {
    id: "Navbar.NotificationItem.noPendingReferrals",
    defaultMessage: "There are no pending referrals from {partner}",
  },
  seeDiscussions: {
    id: "Navbar.NotificationItem.seeDiscussions",
    defaultMessage: "Go to Collaborate",
  },
});
