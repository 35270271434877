import { isFulfilled } from "@reduxjs/toolkit";
import { NotificationStatus } from "components/ui/Notifications/NotificationSnackbar";
import usePushNotification from "hooks/usePushNotification";
import useSegment from "hooks/useSegment";
import generic from "i18n/generic";
import { PayingFeature } from "models/CompanyPayingFeatureSubscription";
import { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { selectActivePayingFeatures } from "redux/api/selectors";
import { rawPost } from "redux/api/thunks";
import { selectPartnershipGetIntroIds } from "redux/init/selectors";
import { loadPartnershipsGetIntro } from "redux/init/thunks";
import {
  openWidget,
  toggleFree,
  togglePower,
  togglePro,
} from "redux/premiumPlanWidget/actions";
import { PremiumPlanEvent } from "tracking";

import { TabProps } from "../../types";

export const useCollaborationTab = ({
  saving,
  setSaveButtonEnabled,
  afterSave,
  partner,
  partnership,
  profile,
}: TabProps) => {
  const { track } = useSegment();
  const dispatch = useDispatch();
  const pushNotification = usePushNotification();
  const payingFeatures = useSelector(selectActivePayingFeatures);
  const partnershipGetIntroIds = useSelector(selectPartnershipGetIntroIds);

  // Get Intro Toggle States
  const initialGetIntroEnabled =
    !partnershipGetIntroIds || partnershipGetIntroIds.includes(partnership.id);
  const [getIntroEnabled, setGetIntroEnabled] = useState<boolean>(
    initialGetIntroEnabled
  );

  const hasRestrictPartnersCRMSyncPayingFeature = payingFeatures.includes(
    PayingFeature.RestrictPartnersCRMSync
  );
  const hasIntroductionRequestFormsPayingFeature = payingFeatures.includes(
    PayingFeature.IntroductionRequestForms
  );
  const canEditIntroForm = Boolean(
    hasIntroductionRequestFormsPayingFeature && profile.isCompanyAdmin()
  );
  const hasGetIntroToggleUpdates = initialGetIntroEnabled !== getIntroEnabled;

  const openPremiumWidget = useCallback(() => {
    dispatch(toggleFree(false));
    dispatch(togglePro(false));
    dispatch(togglePower(true));
    dispatch(openWidget(true));
    track(PremiumPlanEvent.explorePlan, {
      from: "get intro template from get intro settings",
    });
  }, [dispatch, track]);

  const saveGetIntroToggle = useCallback(async () => {
    const result = await dispatch(
      rawPost({
        type: "sync_to_crm_settings",
        path: "toggle-get-intro/",
        payload: {
          data: {
            partnership_id: partnership.id,
            get_intro_enabled: getIntroEnabled,
          },
        },
      })
    );
    if (isFulfilled(result)) {
      await dispatch(loadPartnershipsGetIntro());
    } else {
      setGetIntroEnabled((prev) => !prev);
      pushNotification("default_error");
    }
  }, [dispatch, partnership.id, getIntroEnabled, pushNotification]);

  // useEffect to save

  useEffect(() => {
    if (!saving) return;
    if (hasGetIntroToggleUpdates) {
      saveGetIntroToggle();
    }
  }, [saving]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    setSaveButtonEnabled(hasGetIntroToggleUpdates);
  }, [hasGetIntroToggleUpdates, setSaveButtonEnabled]);

  useEffect(() => {
    if (saving && !hasGetIntroToggleUpdates) {
      afterSave();
      pushNotification({
        message: { ...generic.edits_saved },
        status: NotificationStatus.success,
      });
    }
  }, [saving, hasGetIntroToggleUpdates]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    track("Viewed partnership settings Collaboration tab", {
      partner: partner.name,
    });
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return {
    hasRestrictPartnersCRMSyncPayingFeature,
    canEditIntroForm,
    upgradeIntroForm: hasIntroductionRequestFormsPayingFeature
      ? null
      : openPremiumWidget,
    // Get Intro Toggle
    getIntroEnabled,
    setGetIntroEnabled,
  };
};
