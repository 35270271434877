import { createSelector } from "@reduxjs/toolkit";
import _ from "lodash";
import User from "models/User";
import { RevealStore } from "redux/typing";

import { RootStateWithUser } from "./typing";

export const selectProfile = (state: RootStateWithUser) =>
  state.user.profile.data || new User();

export const selectLoggedIn = (state: RevealStore) => ({
  loggedIn: state.user.login.loggedIn,
});

export const selectHasSources = createSelector(
  (state: RevealStore) => state.api.entities["integrations"] ?? {},
  (state: RevealStore) => state.api.entities["crm_credentials"] ?? {},
  (state: RevealStore) => state.user.profile.data,
  (integrations, crmCredendentials, user) =>
    Boolean(
      (user.company?.integrationCount ?? 0) > 0 ||
        (integrations !== undefined && !_.isEmpty(integrations)) ||
        (crmCredendentials !== undefined && !_.isEmpty(crmCredendentials))
    )
);
