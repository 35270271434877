import { Check } from "components/icons";
import SelectBoxGroup from "components/ui/SelectBoxGroup";
import { T } from "components/ui/Typography";
import useAvailableRoles from "hooks/useAvailableRoles";
import { makeStyles } from "makeStyles";
import { PayingFeature } from "models/CompanyPayingFeatureSubscription";
import {
  defineMessages,
  FormattedMessage,
  MessageDescriptor,
} from "react-intl";
import { useSelector } from "react-redux";
import { selectActivePayingFeatures } from "redux/api/selectors";

import { Role } from "../types";

type Props = {
  value?: Role;
  onSelect?: (value: Role) => void;
  showUnassignable?: boolean;
};

const Roles = ({ value, onSelect, showUnassignable = true }: Props) => {
  const payingFeatures = useSelector(selectActivePayingFeatures);
  const availableRoles = useAvailableRoles(showUnassignable);
  const { classes } = useStyles();
  const hasSalesInvitationsFeature = payingFeatures.includes(
    PayingFeature.SalesInvitations
  );

  const options = roles
    .map((roleName: Role) => {
      const role = availableRoles.find(({ name }) => name === roleName);
      if (role) {
        if (
          hasSalesInvitationsFeature &&
          (roleName === Role.sales || roleName === Role.salesManager) &&
          !features[roleName]?.includes(i18n.featureInvitePartners)
        ) {
          features[roleName].push(i18n.featureInvitePartners);
        }
        const content = (
          <>
            <T h4 bold className={classes.tileTitle}>
              {role.label ?? roleName}
            </T>
            {(features[roleName] ?? []).map((message: MessageDescriptor) => (
              <T bodySmall key={message.id}>
                <Check className={classes.check} />
                <FormattedMessage {...message} />
              </T>
            ))}
          </>
        );
        return {
          value: roleName as string,
          content,
          disabled: !role.canAssign,
        };
      }
      return { value: "", content: "" };
    })
    .filter((role) => Boolean(role.value));

  return (
    <SelectBoxGroup
      values={[value]}
      onSelect={onSelect}
      options={options}
      classes={{ container: classes.container }}
      multiSelect={false}
    />
  );
};

export default Roles;

/// CSS

const useStyles = makeStyles()((theme) => ({
  check: {
    width: 16,
    marginRight: 4,
  },
  tileTitle: {
    marginBottom: theme.spacing(1),
  },
  container: {
    padding: theme.spacing(3),
  },
}));

/// I18N

const i18n = defineMessages({
  // Features
  featureAccountMapping: {
    id: "Users.UserDialog.Feature.AccountMapping",
    defaultMessage: "Account Mapping",
  },
  featureDeletePartnerships: {
    id: "Users.UserDialog.Feature.DeletePartnerships",
    defaultMessage: "Delete partnerships",
  },
  featureMapping360: {
    id: "Users.UserDialog.Feature.360Mapping",
    defaultMessage: "360° Mapping",
  },
  featureAllAccounts: {
    id: "Users.UserDialog.Feature.AllAccounts",
    defaultMessage: "Access to all accounts",
  },
  featureOwnAccounts: {
    id: "Users.UserDialog.Feature.OwnAccounts",
    defaultMessage: "Access to own accounts",
  },
  featureInvitePartners: {
    id: "Users.UserDialog.Feature.InvitePartners",
    defaultMessage: "Invite partners",
  },
  featureShareData: {
    id: "Users.UserDialog.Feature.ShareData",
    defaultMessage: "Share data with partners",
  },
  featureExportData: {
    id: "Users.UserDialog.Feature.ExportData",
    defaultMessage: "Export data",
  },
  featureManageSources: {
    id: "Users.UserDialog.Feature.ManageSources",
    defaultMessage: "Manage data sources",
  },
  featureManageUsers: {
    id: "Users.UserDialog.Feature.ManageUsers",
    defaultMessage: "Manage users",
  },
});

/// Constants

const roles: Role[] = [
  Role.sales,
  Role.salesManager,
  Role.partnerManager,
  Role.admin,
];

const features: Record<Role, MessageDescriptor[]> = {
  [Role.admin]: [
    i18n.featureAccountMapping,
    i18n.featureMapping360,
    i18n.featureAllAccounts,
    i18n.featureInvitePartners,
    i18n.featureShareData,
    i18n.featureExportData,
    i18n.featureManageSources,
    i18n.featureManageUsers,
    i18n.featureDeletePartnerships,
  ],
  [Role.partnerManager]: [
    i18n.featureAccountMapping,
    i18n.featureMapping360,
    i18n.featureAllAccounts,
    i18n.featureInvitePartners,
    i18n.featureShareData,
    i18n.featureExportData,
  ],
  [Role.salesManager]: [
    i18n.featureAccountMapping,
    i18n.featureMapping360,
    i18n.featureAllAccounts,
  ],
  [Role.sales]: [
    i18n.featureAccountMapping,
    i18n.featureMapping360,
    i18n.featureOwnAccounts,
  ],
};
