import { createSvgIcon } from "@mui/material/utils";

import { SvgIcon } from "./types";

const displayName = "PlusCircle";

const PlusCircle: SvgIcon = createSvgIcon(
  <g fill="none">
    <path
      d="M12.6751 8.40001C12.6751 8.02721 12.3729 7.72501 12.0001 7.72501C11.6273 7.72501 11.3251 8.02721 11.3251 8.40001L11.3251 11.325H8.4001C8.02731 11.325 7.7251 11.6272 7.7251 12C7.7251 12.3728 8.02731 12.675 8.4001 12.675H11.3251L11.3251 15.6C11.3251 15.9728 11.6273 16.275 12.0001 16.275C12.3729 16.275 12.6751 15.9728 12.6751 15.6L12.6751 12.675H15.6001C15.9729 12.675 16.2751 12.3728 16.2751 12C16.2751 11.6272 15.9729 11.325 15.6001 11.325H12.6751V8.40001Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12 19.65C16.225 19.65 19.65 16.225 19.65 12C19.65 7.77502 16.225 4.35 12 4.35C7.77502 4.35 4.35 7.77502 4.35 12C4.35 16.225 7.77502 19.65 12 19.65ZM12 21C16.9706 21 21 16.9706 21 12C21 7.02944 16.9706 3 12 3C7.02944 3 3 7.02944 3 12C3 16.9706 7.02944 21 12 21Z"
      fill="currentColor"
    />
  </g>,
  displayName
);

PlusCircle.displayName = displayName;
export default PlusCircle;
