import Grid from "@mui/material/Grid";
import muiTheme from "config/theme";
import { makeStyles } from "makeStyles";
import { forwardRef, ReactNode } from "react";

type StyleProps = {
  centered?: boolean;
  size: "xs" | "sm" | "md";
};

type TileProps = Partial<StyleProps> & {
  checked?: boolean;
  children?: ReactNode | ReactNode[];
  className?: string;
  hover?: boolean;
  inactive?: boolean;
  onClick?: () => void;
};

const Tile = forwardRef<$TSFixMe, TileProps>(
  (
    {
      centered,
      checked,
      children,
      className,
      hover,
      inactive,
      size = "md",
      ...props
    },
    ref
  ) => {
    const { classes, cx } = useStyles({ centered, size });

    return (
      <Grid
        ref={ref}
        item
        className={cx(className, classes.root, {
          [classes.hover]: hover && !inactive,
          [classes.inactive]: inactive,
          [classes.checked]: checked,
          [classes.centered]: centered,
        })}
        {...props}
      >
        {children}
      </Grid>
    );
  }
);

export default Tile;

// CSS Helpers

const sizeConfig = {
  md: {
    rootMargin: muiTheme.spacing(1),
    centeredPadding: `${muiTheme.spacing(2)}px !important`,
  },
  sm: {
    rootMargin: muiTheme.spacing(0.5),
    centeredPadding: `${muiTheme.spacing(2)}px !important`,
  },
  xs: {
    rootMargin: muiTheme.spacing(0.5),
    centeredPadding: 0,
  },
};

// CSS

export const useStyles = makeStyles<StyleProps>()((theme, { size }) => ({
  root: {
    margin: `${sizeConfig[size].rootMargin} 0`,
    backgroundColor: theme.palette.ivory,
    border: `1px solid ${theme.palette.taupe500}`,
    borderRadius: 8,
    padding: 12,
    width: "100%",
    height: 60,
    [theme.breakpoints.down("md")]: {
      flexWrap: "wrap",
      height: "auto",
    },
  },
  container: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    alignItems: "flex-start",
  },
  inactive: {
    color: theme.palette.greyReveal,
    "& img": {
      opacity: 0.5,
    },
  },
  centered: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
    padding: sizeConfig[size].centeredPadding,
  },
  hover: {
    cursor: "pointer",
    "&:hover": {
      borderColor: theme.palette.midnight,
    },
  },
  checked: {
    borderImage: theme.palette.gradients.darkPurpleToGreen,
    borderImageSlice: 1,
    background: `linear-gradient(${theme.palette.ivory}, ${theme.palette.ivory}) padding-box,
    ${theme.palette.gradients.darkPurpleToGreen} border-box`,
  },
}));
