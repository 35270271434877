import { useState } from "react";

const useQuery = (initState: $TSFixMe) => {
  const [query, setQuery] = useState(initState);
  const handler = (filterName: $TSFixMe, filterValue: $TSFixMe) =>
    setQuery({ ...query, [filterName]: filterValue });
  return [query, handler, setQuery];
};

export default useQuery;
