import PageForbidden from "components/ui/PageForbidden";
import { PayingFeature } from "models/CompanyPayingFeatureSubscription";
import { ComponentType } from "react";
import { useSelector } from "react-redux";
import { selectActivePayingFeatures } from "redux/api/selectors";

export type WithCompanyPayingFeatures = {
  payingFeatures: PayingFeature[];
};

type WithCompanyPayingFeaturesOptions<FallbackProps> = {
  Fallback?: ComponentType<FallbackProps>;
  fallbackProps?: FallbackProps;
};

const resolveAnyPayingFeature = (features: string[], featureList: string[]) =>
  features.some((feature) => featureList.includes(feature));

export function withCompanyPayingFeatures<
  T extends WithCompanyPayingFeatures = WithCompanyPayingFeatures,
  FallbackProps = {}
>(
  features: string[],
  options: WithCompanyPayingFeaturesOptions<FallbackProps> = {}
) {
  return (WrappedComponent: ComponentType<T>) => {
    const {
      Fallback = PageForbidden,
      fallbackProps = {} as FallbackProps,
    } = options;

    const Component = (props: Omit<T, keyof WithCompanyPayingFeatures>) => {
      const payingFeatures = useSelector(selectActivePayingFeatures);

      return resolveAnyPayingFeature(features, payingFeatures) ? (
        <WrappedComponent {...(props as T)} />
      ) : (
        <Fallback {...fallbackProps} />
      );
    };

    Component.displayName = `withPayingFeatures(${
      WrappedComponent.displayName || "WrappedComponent"
    })`;

    return Component;
  };
}
