import { useDispatch } from "react-redux";

import { FieldType } from "../../../types";
import { OwnerFilterMenu } from "./OwnerFilterMenu";

type Props = {
  field?: FieldType;
  value: null | number[];
  setOwnerFilter: (value: null | string[]) => void;
};

export const OwnerFilter = ({ field, setOwnerFilter, value }: Props) => {
  const dispatch = useDispatch();

  const handleChange = (value: string[] | null) => {
    dispatch(setOwnerFilter(value?.map((id) => id) || null));
  };

  return (
    <>
      <OwnerFilterMenu
        options={field?.options ?? {}}
        onChange={handleChange}
        value={value?.map((item) => String(item)) ?? null}
      />
    </>
  );
};
