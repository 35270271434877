import _ from "lodash";
import { ChangeEvent, useCallback, useEffect, useRef, useState } from "react";
import {
  FilterType,
  MatchFilterType,
} from "screens/Frontoffice/screens/DataTables/shared/types";

type Props = {
  fieldName: string;
  filters: FilterType[];
  setFilters: (filters: FilterType[]) => void;
};

export const useQuickSearch = ({ fieldName, filters, setFilters }: Props) => {
  const companyNameFilter = filters.find(
    (filter) => filter.fieldname === fieldName
  );
  const [searchValue, setSearchValue] = useState(
    companyNameFilter?.value ?? ""
  );

  const hasDebouncedBeenTriggered = useRef(false); // Where the user is Typing => True ? QuickSearch : FilterWidget

  const handleChangeSearch = (e: ChangeEvent<HTMLInputElement>) => {
    setSearchValue(e.target.value);
    debouncedSetFilters(e.target.value);
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedSetFilters = useCallback(
    _.debounce((value) => {
      hasDebouncedBeenTriggered.current = true;

      if (value.length === 0) {
        handleClearSearch();
        return;
      }

      if (companyNameFilter) {
        const index = filters.findIndex(
          (filter) => filter.fieldname === fieldName
        );
        const newFilters = [...filters];
        newFilters[index] = { ...newFilters[index], value };
        setFilters(newFilters);
      } else {
        setFilters([
          ...filters,
          {
            fieldname: fieldName,
            type: MatchFilterType.CONTAINS,
            value,
          },
        ]);
      }
    }, 300),
    [companyNameFilter, filters]
  );

  const handleClearSearch = () => {
    setSearchValue("");
    setFilters(filters.filter((filter) => filter.fieldname !== fieldName));
  };

  useEffect(() => {
    if (companyNameFilter) {
      if (hasDebouncedBeenTriggered.current) {
        // Make sure the QuickSearch shows what the user type
        companyNameFilter.value !== searchValue && setSearchValue(searchValue);
      } else {
        // Update the quick search this way only when user update the filter widget
        companyNameFilter.value !== searchValue &&
          setSearchValue(companyNameFilter.value ?? "");
      }
      hasDebouncedBeenTriggered.current = false;
    } else {
      setSearchValue("");
    }
  }, [companyNameFilter]); // eslint-disable-line react-hooks/exhaustive-deps

  return { searchValue, handleChangeSearch, handleClearSearch };
};
