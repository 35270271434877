import { createSvgIcon } from "@mui/material/utils";

import { SvgIcon } from "./types";

const displayName = "ChevronTop";

const ChevronTop: SvgIcon = createSvgIcon(
  // Using transform to enlarge from provided viewbox="0 0 16 16"
  // to default "0 0 24 24" from `createSvgIcon` -> `SvgIcon`.
  <g fill="none" transform="matrix(1.5,0,0,1.5,0,0)">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4.1212 10.0641C4.28279 10.2257 4.54479 10.2257 4.70639 10.0641L7.90247 6.86803C7.95633 6.81416 8.04367 6.81416 8.09753 6.86803L11.2936 10.0641C11.4552 10.2257 11.7172 10.2257 11.8788 10.0641C12.0404 9.90251 12.0404 9.64051 11.8788 9.47892L8.68272 6.28284C8.30567 5.90578 7.69433 5.90578 7.31728 6.28284L4.1212 9.47891C3.9596 9.64051 3.9596 9.90251 4.1212 10.0641Z"
      fill="currentColor"
    />
  </g>,
  displayName
);

ChevronTop.displayName = displayName;
export default ChevronTop;
