import _ from "lodash";
import { SwRecordType } from "models/CrmField";

export type SWQLNode =
  | SWQLBooleanNode
  | SWQLRuleNode
  | SWQLEmptyNode
  | SWQLConstantNode
  | SWQLLookupNode
  | SWQLLookupEmptyNode
  | SWQLLookupExistsNode;

//
// NODE TYPES
//
export type SWQLBooleanNode = {
  type: NodeType.Boolean;
  operator: SWQLBooleanOperator;
  args: SWQLNode[];
};

export type SWQLRuleNode = {
  type: NodeType.Rule;
  operation: SWQLRuleOperation;
  left: SWQLRuleNodeLeft;
  right: SWQLRuleNodeRight;
};

export type SWQLEmptyNode = {
  type: NodeType.Empty;
};

export type SWQLConstantNode = {
  type: NodeType.Constant;
  value: boolean;
};

export type SWQLLookupNode = {
  type: NodeType.Lookup;
  rule: SWQLNode;
  operation: "anyMatch" | "allMatch" | "noMatch";
  lookup_path: SWQLTarget;
};

export type SWQLLookupEmptyNode = {
  type: NodeType.LookupEmpty;
  lookup_path: string;
};

export type SWQLLookupExistsNode = {
  type: NodeType.LookupExists;
  lookup_path: string;
};

//
// POSSIBLE TARGET RECORDS
//

export enum SWQLTarget {
  RawCompany = "RawCompany",
  RawOpportunity = "RawOpportunity",
  RawUser = "RawUser",
}

//
// INTERNAL NODE DEFINITIONS
//
export enum NodeType {
  Boolean = "boolean_op",
  Rule = "operation",
  Empty = "empty",
  Constant = "constant",
  Lookup = "lookup",
  LookupEmpty = "lookup_empty",
  LookupExists = "lookup_exists",
}

export type SWQLRuleNodeLeft = {
  type: "field";
  field_id: FieldId;
  field_name: string;
};

export type SWQLRuleNodeRight = {
  type: "value" | "date";
  value: SWQLRightValue;
};

export type SWQLBooleanOperator = "and" | "or";
// $TSFixMe -- This type should be an explicit union of SwQLOperator.(... some type or str value).
export type SWQLRuleOperation = string;
// $TSFixMe -- This type should be an explicit union of (???).
type CurrencyValue = {
  amount: number | null;
  currency_iso_code: string;
};
export type SWQLRightValue =
  | string
  | boolean
  | number
  | string[]
  | CurrencyValue
  | null;

//
// FIELD TYPE
//
export type SWQLField = {
  id: FieldId;
  type: SWQLFieldType;
  label: string;
  value: $TSFixMe;
  options: SWQLFieldOptions;
  hasOptions?: boolean;
  recordType: SwRecordType;
};

// $TSFixMe -- This type should be an explicit union of (???).
// Maybe it should be generic since this can be used with CrmFields or other ones (or do they?).
// Nonetheless, even it's only used with CrmFields, those are defined in another module and we
// should not mix their boundaries.
export type SWQLFieldType = number;
// $TSFixMe -- Is this an array or an object?
export type SWQLFieldOptions = $TSFixMe;
// $TSFixMe: choose either string or number.
export type FieldId = number | string;

//
// TYPE GUARDS
//
export function isSWQLEmptyNode(node: SWQLNode): node is SWQLEmptyNode {
  // N.B. Some queries come from the back-end as undefined or as {}
  // So we need to make sure those translate to emtpy nodes also.
  // In the future when we add decoders we should properly decode them
  // to SWQLEmptyNode's and remove this `!node?.type ||` part.
  return !node?.type || node.type === NodeType.Empty;
}

export function isSWQLBooleanNode(node: SWQLNode): node is SWQLBooleanNode {
  return node.type === NodeType.Boolean;
}

export function isSWQLRuleNode(node: SWQLNode): node is SWQLRuleNode {
  return node.type === NodeType.Rule;
}

export function isSWQLConstantNode(node: SWQLNode): node is SWQLConstantNode {
  return node.type === NodeType.Constant;
}

export function isSWQLLookupNode(node: SWQLNode): node is SWQLLookupNode {
  return node.type === NodeType.Lookup;
}

export function isSWQLLookupEmptyNode(
  node: SWQLNode
): node is SWQLLookupEmptyNode {
  return node.type === NodeType.LookupEmpty;
}

export function isSWQLLookupExistsNode(
  node: SWQLNode
): node is SWQLLookupExistsNode {
  return node.type === NodeType.LookupExists;
}

export function isSWQLField(value: any): value is SWQLField {
  return _.isObject(value) && _.has(value, "id") && _.has(value, "type");
}

//
// HELPERS
//
export function emptyNode(): SWQLEmptyNode {
  return {
    type: NodeType.Empty,
  };
}
