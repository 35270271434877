import config from "config";
import {
  MAPPING_360_PARTNER_LIMIT,
  OFFLINE_ACCOUNT_MAPPING_LIMIT,
  segmentTag,
  SHARED_PIPELINE_LIMIT,
} from "config/constants";
import _ from "lodash";
import User, { IIdentifyManualOptions } from "models/User";
import UserService from "services/UserService";

/* eslint-disable no-console */

const isSegmentEnabledForProfile = (profile?: User) =>
  _.isUndefined(profile) ||
  (profile &&
    profile.id &&
    (!profile.company || !profile.company.disableAnalytics));

const isSegmentEnabled = (profile?: User) => {
  const service = new UserService();
  return (
    window.analytics &&
    !service.isImpersonating &&
    config().appName !== "backoffice" &&
    isSegmentEnabledForProfile(profile)
  );
};

interface PayingFeature {
  featureKey: string;
  companyAttr: string;
  limitValue: number;
}

const my360MappingUnlocked: PayingFeature = {
  featureKey: "My360MappingUnlocked",
  companyAttr: "nbSharedAccountMapping",
  limitValue: MAPPING_360_PARTNER_LIMIT,
};

const unlimitedPipelineSharing: PayingFeature = {
  featureKey: "UnlimitedPipelineSharing",
  companyAttr: "nbSharedPipelines",
  limitValue: SHARED_PIPELINE_LIMIT,
};

const unlimitedOfflineAccountMapping: PayingFeature = {
  featureKey: "UnlimitedOfflineAccountMapping",
  companyAttr: "ghostPartnership",
  limitValue: OFFLINE_ACCOUNT_MAPPING_LIMIT,
};

const activePayingFeatures = (profile?: User) => {
  return profile?.company?.companyPayingFeatureSubscriptions
    ? _.filter(
        profile.company.companyPayingFeatureSubscriptions,
        (subscription) => subscription.isActive
      )
        .map((subscription) => subscription.payingFeature)
        .sort()
    : undefined;
};

const hasPayingFeatureEnabled = (profile?: User, payingFeatureName?: string) =>
  activePayingFeatures(profile)?.includes(payingFeatureName);

const isLimitReached = (profile: User, payingFeature: PayingFeature) => {
  const isEnabled = hasPayingFeatureEnabled(profile, payingFeature.featureKey);
  return isEnabled === undefined
    ? undefined
    : !hasPayingFeatureEnabled(profile, payingFeature.featureKey) &&
        _.get(profile, `company.${payingFeature.companyAttr}`, 0) >=
          payingFeature.limitValue;
};

export const start = () => {
  console.groupCollapsed("Starting Segment tracking");
  if (!segmentTag) {
    console.warn(
      "Segment will not start as the configuration does not provide a value" +
        "for segmentTag parameter"
    );
    console.groupEnd();
    return;
  }
  const analytics = (window.analytics = window.analytics || []);
  // @ts-expect-error
  if (!analytics.initialize) {
    // @ts-expect-error
    if (analytics.invoked) {
      console.warn("Segment already started. 2 would be one time too many!");
      console.groupEnd();
      return;
    }
    // @ts-expect-error
    analytics.invoked = true;
    // @ts-expect-error
    analytics.methods = [
      "trackSubmit",
      "trackClick",
      "trackLink",
      "trackForm",
      "pageview",
      "identify",
      "reset",
      "group",
      "track",
      "ready",
      "alias",
      "debug",
      "page",
      "once",
      "off",
      "on",
    ];
    // @ts-expect-error
    analytics.factory = (tag: string) => {
      return (...args: any[]) => {
        const e = Array.prototype.slice.call(args);
        e.unshift(tag);
        // @ts-expect-error
        analytics.push(e);
        return analytics;
      };
    };
    // @ts-expect-error
    analytics.methods.forEach((method: string) => {
      // @ts-expect-error
      analytics[method] = analytics.factory(method);
    });
    analytics.load = (tag: string, options?: SegmentAnalytics.SegmentOpts) => {
      var element = document.createElement("script");
      element.type = "text/javascript";
      element.async = true;
      element.src = `https://cdn.segment.com/analytics.js/v1/${tag}/analytics.min.js`;
      var firstScriptTag = document.getElementsByTagName("script")[0];
      // @ts-expect-error ts-migrate(2531) FIXME: Object is possibly 'null'.
      firstScriptTag.parentNode.insertBefore(element, firstScriptTag);
      // @ts-expect-error
      analytics._loadOptions = options;
    };
    // @ts-expect-error
    analytics.SNIPPET_VERSION = "4.1.0";
    console.info("Segment is now configured");
    analytics.load(segmentTag);
  }
  console.groupEnd();
};

const stop = () => {
  // @ts-expect-error
  window.analytics = false;
};

export const page = () => {
  // Segment Page Call
  if (isSegmentEnabled()) {
    window.analytics.page();
  }
};

export const track = (eventName: string, properties: Object = {}) => {
  // Segment Track Call
  if (isSegmentEnabled()) {
    window.analytics.track(eventName, properties);
  }
};

export const getProfileRoleForIdentify = (profile: User) => {
  const orderedByRankRoles = [
    "company.admin",
    "company.partnership_manager",
    "company.sales_manager",
    "company.sales",
  ];
  const assignedRoles = _.map(profile.roleAssignments, _.property("role"));
  return orderedByRankRoles.find((role) => assignedRoles.includes(role));
};

export const identify = (profile: User, options?: IIdentifyManualOptions) => {
  if (isSegmentEnabled(profile) && profile) {
    const integrationAttr = _.get(
      profile,
      "company.mappedIntegrationAttributes"
    );
    const integrations = _.get(profile, "company.integrations");

    const companyData = {
      id: profile.companyId,
      name: profile.companyName,
      domainName: _.get(profile, "company.domainName"),
      size: profile.companySize,
      ecosystem: _.get(profile, "company.ecosystem"),
      country: _.get(profile, "company.country"),
      pricingPlan: _.get(profile, "company.featureGroupName"),
      integrationCount: _.get(profile, "company.integrationCount"),
      crm: !!integrations
        ? _.map(
            integrations,
            (integration: { provider: string }) => integration.provider
          )
        : undefined,
      activePartnershipsCount: _.get(profile, "company.activePartnership"),
      partnershipInvitesCount: _.get(profile, "company.createdPartnership"),
      pendingPartnershipCount: _.get(profile, "company.pendingPartnership"),
      pendingPartnershipToAcceptCount: _.get(
        profile,
        "company.pendingToAcceptPartnership"
      ),
      numberCompanyUsers: _.get(profile, "company.userCount"),
      numberAssignedLicenses: _.get(profile, "company.licensedUserCount"),
      numberAllowedLicenses:
        _.get(profile, "company.licensedUserCount") +
        _.get(profile, "company.additionalLicenseQuota"),
      createdAt: _.get(profile, "company.createdAt"),
      activeSince: _.get(profile, "company.activeSince"),
      promoterSince: _.get(profile, "company.promoterSince"),
      topPromoterSince: _.get(profile, "company.topPromoterSince"),
      viral: _.get(profile, "company.viral"),
      stage: _.get(profile, "company.stage"),
      payingFeaturesEnabled: activePayingFeatures(profile),
      myecosystemFeatureActivated: _.get(
        profile,
        "company.myecosystemFeatureActivated"
      ),
      hasEnabledDefaultSource: _.get(
        profile,
        "company.hasEnabledDefaultSource"
      ),
      statusRulesDefined: !!integrationAttr
        ? integrationAttr.includes("status")
        : undefined,
      partnerMappingRulesDefined: !!integrationAttr
        ? integrationAttr.includes("status-partner")
        : undefined,
      strategicAccountsDefined: !!integrationAttr
        ? integrationAttr.includes("starred")
        : undefined,
      pipelineSharedLimitReached: isLimitReached(
        profile,
        unlimitedPipelineSharing
      ),
      offlineAccountMappingLimitReached: isLimitReached(
        profile,
        unlimitedOfflineAccountMapping
      ),
      my360MappingLimitReached: isLimitReached(profile, my360MappingUnlocked),
      ...options,
    };

    const userManualidentifyOptions = Object.entries(options ?? {}).reduce(
      (acc: any, [key, value]) => {
        acc[`company${key.charAt(0).toUpperCase() + key.slice(1)}`] = value;
        return acc;
      },
      {}
    );

    window.analytics.identify(
      String(profile.id),
      {
        id: profile.id,
        authProvider: profile.authProvider,
        email: profile.email,
        name: profile.fullName,
        role: getProfileRoleForIdentify(profile),
        job: profile.job,
        jobPosition: profile.role,
        workExperience: profile.workExperience,
        country: profile.country,
        confirmedEmail: Boolean(profile.confirmedAt),
        hasUserLicense: profile.hasLicense,
        createdAt: profile.createdAt,
        companyId: companyData.id,
        companyName: companyData.name,
        companySize: companyData.size,
        companyEcosystem: companyData.ecosystem,
        companyCountry: companyData.country,
        companyIntegrationCount: companyData.integrationCount,
        companyCrm: companyData.crm,
        companyPricingPlan: companyData.pricingPlan,
        companyActivePartnershipsCount: companyData.activePartnershipsCount,
        companyPartnershipInvitesCount: companyData.partnershipInvitesCount,
        companyPendingPartnershipCount: companyData.pendingPartnershipCount,
        companyPendingPartnershipToAcceptCount:
          companyData.pendingPartnershipToAcceptCount,
        companyNumberCompanyUsers: companyData.numberCompanyUsers,
        companyNumberAssignedLicenses: companyData.numberAssignedLicenses,
        companyNumberAllowedLicenses: companyData.numberAllowedLicenses,
        companyCreatedAt: companyData.createdAt,
        companyActiveSince: companyData.activeSince,
        companyPromoterSince: companyData.promoterSince,
        companyTopPromoterSince: companyData.topPromoterSince,
        companyViral: companyData.viral,
        companyStage: companyData.stage,
        companyPayingFeaturesEnabled: companyData.payingFeaturesEnabled,
        companyMyecosystemFeatureActivated:
          companyData.myecosystemFeatureActivated,
        companyHasEnabledDefaultSource: companyData.hasEnabledDefaultSource,
        companyStatusRulesDefined: companyData.statusRulesDefined,
        companyPartnerMappingRulesDefined:
          companyData.partnerMappingRulesDefined,
        companyStrategicAccountsDefined: companyData.strategicAccountsDefined,
        companyPipelineSharedLimitReached:
          companyData.pipelineSharedLimitReached,
        companyOfflineAccountMappingLimitReached:
          companyData.offlineAccountMappingLimitReached,
        companyMy360MappingLimitReached: companyData.my360MappingLimitReached,
        company: companyData,
        ...userManualidentifyOptions,
      },
      {
        integrations: {
          Intercom: {
            user_hash: profile.intercomHash,
          },
        },
      }
    );

    if (profile.companyId)
      window.analytics.group(String(profile.companyId), companyData);
  }
};

const moduleDefaultExports = {
  identify,
  start,
  page,
  track,
  stop,
};

export default moduleDefaultExports;
