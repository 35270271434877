import { Location } from "history";
import _ from "lodash";
import { parse } from "query-string";

function getFirstValueFromURL(
  location: Location,
  key: string,
  defaultValue: string
): string;
function getFirstValueFromURL(
  location: Location,
  key: string,
  defaultValue?: string | undefined
): string | undefined;
function getFirstValueFromURL(
  location: Location,
  key: string,
  defaultValue?: string
): string | undefined {
  const parsedSearch = parse(location.search);
  const value = parsedSearch[key];
  return (_.isArray(value) ? value[0] : value) || defaultValue;
}

export default getFirstValueFromURL;
