import _ from "lodash";
import SavedFilter, { SavedFilterType } from "models/SavedFilter";
import { APICollection } from "redux/api/typing";
import { RevealStore } from "redux/typing";

import { ExtendedAccountMappingResource, ThreeSixty } from "./constants";

export const selectSavedFilters = (
  accountType: ExtendedAccountMappingResource,
  partnershipId?: number
) => (state: RevealStore) => {
  const hasNewAccountMappingPF = state.user?.profile?.data?.company?.companyPayingFeatureSubscriptions?.find(
    (pf: any) => pf.payingFeature === "UseNewAccountMapping"
  )?.enabled;
  const records = _.values(
    (state.api.entities["saved_filters"] ?? {}) as APICollection<SavedFilter>
  );
  const filteredRecords = records
    .filter((filter) =>
      accountType === ThreeSixty.threeSixty
        ? filter.viewType === SavedFilterType.MyEcosystem
        : accountType === ThreeSixty.nearboundAccounts
        ? filter.viewType === SavedFilterType.NearboundAccounts
        : accountType === ThreeSixty.nearboundProspects
        ? filter.viewType === SavedFilterType.NearboundProspects
        : [
            hasNewAccountMappingPF
              ? SavedFilterType.NewAccountMapping
              : SavedFilterType.AccountMapping,
            SavedFilterType.LeadOverlaps,
            SavedFilterType.NewProspects,
          ].includes(filter.viewType)
    )
    .filter((filter) =>
      partnershipId
        ? !filter.hasCustomPartnerField ||
          filter.partnershipId === partnershipId
        : true
    );

  return { records: filteredRecords, totalCount: records.length };
};
