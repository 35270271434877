import { isFulfilled, isRejected } from "@reduxjs/toolkit";
import { Edit, SendTilted, TrashThin } from "components/icons";
import Button from "components/ui/Button";
import Popup from "components/ui/Popup";
import { T } from "components/ui/Typography";
import { createInvite } from "components/users/list/InvitationModal";
import useAvailableRoles from "hooks/useAvailableRoles";
import usePushNotification from "hooks/usePushNotification";
import useUserProfile from "hooks/useUserProfile";
import generic from "i18n/generic";
import { makeStyles } from "makeStyles";
import { useCallback, useState } from "react";
import { defineMessages, FormattedMessage } from "react-intl";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router";
import { destroy, indexAll, retreive } from "redux/api/thunks";
import { fields, included } from "redux/init/constants";
import { loadUsers } from "redux/init/thunks";
import { JSONAPIListResponse } from "services/types";

import { UpdatePartnershipOwnerDialog } from "../../../shared/components/UpdatePartnershipOwnerDialog";
import { hasNotBeenInvitedOrWasMoreThan1DayAgo } from "../../../shared/utils";

export const ActionCell = ({
  dataItem,
  refreshUsers,
}: {
  dataItem: any;
  refreshUsers: () => void;
}) => {
  const { classes } = useCellStyles();
  const history = useHistory();

  const dispatch = useDispatch();
  const { profile } = useUserProfile();
  const availableRoles = useAvailableRoles(true);
  const pushNotification = usePushNotification();
  const [loadingIsUsed, setLoadingIsUsed] = useState<boolean>(false);
  const [loadingDelete, setLoadingDelete] = useState<boolean>(false);
  const [confirmationIsOpen, setConfirmationIsOpen] = useState<boolean>(false);
  const [partnershipIdsToMap, setPartnershipIdsToMap] = useState<number[]>([]);

  const roles = dataItem.roleAssignments;
  const currentRole = roles?.length ? roles[0].role : null;
  const isCurrentUser = dataItem.id === profile.id;
  const canResendInvite = hasNotBeenInvitedOrWasMoreThan1DayAgo(
    dataItem.invitationSentAt
  );
  const canDeleteUser = profile.hasPermissions(
    ["company.delete_users"],
    profile.company
  );

  const goToUserModel = useCallback(
    () => history.push(`/settings/team/${dataItem.id}`),
    [history, dataItem.id]
  );

  const resendInvite = async () => {
    try {
      await createInvite(profile, {
        first_name: dataItem.firstName,
        last_name: dataItem.lastName,
        email: dataItem.email,
      });
      await fetchUser(dataItem.id);
      pushNotification(i18n.inviteSentAgain);
    } catch {
      pushNotification("default_error");
    }
  };

  const fetchUser = async (id: number) => {
    dispatch(
      retreive({
        id: id,
        type: "users",
        options: { include: ["invited_by", "role_assignments", "company"] },
      })
    );
  };

  const checkIfUserIsUsed = async () => {
    //Checking if user is used as partership owner in any partnership
    setLoadingIsUsed(true);
    const result = await dispatch(
      indexAll({
        type: "partnerships",
        options: {
          filters: {
            partnership_owner_id: dataItem.id,
          },
          include: included,
          fields,
        },
      })
    );
    if (isFulfilled(result)) {
      const response = result.payload as JSONAPIListResponse;
      const partnershipIds = response.data.map(
        (partnership) => +partnership.id
      );

      if (partnershipIds.length > 0) {
        setPartnershipIdsToMap(partnershipIds);
      } else {
        deleteUser();
      }
      setConfirmationIsOpen(false);
    } else {
      pushNotification("default_error");
    }
    setLoadingIsUsed(false);
  };

  const deleteUser = async () => {
    setLoadingDelete(true);
    const response = await dispatch(
      destroy({
        type: "users",
        id: dataItem.id,
      })
    );
    setLoadingDelete(false);
    if (isRejected(response)) {
      pushNotification("default_error");
    } else {
      refreshUsers();
      dispatch(loadUsers());
      pushNotification({ ...generic.edits_saved });
    }
    setConfirmationIsOpen(false);
  };

  const closeUpdatePartnershipOwnerDialog = () => {
    setPartnershipIdsToMap([]);
  };

  const getAction = () => {
    if (dataItem.pendingInvitation) {
      return (
        <Button
          label={i18n.resend}
          size="small"
          variant="secondary"
          onClick={resendInvite}
          disabled={!canResendInvite}
          LeftIcon={SendTilted}
        />
      );
    }
    if (currentRole !== null) {
      const role = availableRoles?.find(
        ({ name }: { name: string }) => name === currentRole
      );
      const isEditable = Boolean(
        dataItem.id === profile.id || (role && !role.canAssign)
      );

      return (
        <Button
          label={i18n.edit}
          size="small"
          variant="tertiary"
          disabled={isEditable}
          onClick={goToUserModel}
          LeftIcon={Edit}
        />
      );
    }
  };

  return (
    <>
      <div className={classes.actionContainer}>
        {getAction()}
        {canDeleteUser && (
          <Button
            label=""
            variant="quinary"
            size="small"
            LeftIcon={TrashThin}
            onClick={() => setConfirmationIsOpen(true)}
            loading={loadingDelete}
            data-testid="delete-btn"
            disabled={isCurrentUser}
          />
        )}
      </div>
      {canDeleteUser && !isCurrentUser && (
        <Popup
          isOpen={confirmationIsOpen}
          title={
            <T h3 bold>
              <FormattedMessage
                {...i18n.confirmTitle}
                values={{ fullName: dataItem.fullName }}
              />
            </T>
          }
          handleClose={() => {
            setConfirmationIsOpen(false);
          }}
          handleSave={checkIfUserIsUsed}
          maxWidth="sm"
          saveButtonText={i18n.delete}
          saveButtonIcon={TrashThin}
          loadingSave={loadingIsUsed}
          variant="secondary"
        >
          <T>
            <FormattedMessage
              {...i18n.confirmSubTitle}
              values={{ firstName: dataItem.firstName }}
            />
          </T>
        </Popup>
      )}
      {partnershipIdsToMap.length > 0 && (
        <UpdatePartnershipOwnerDialog
          handleClose={closeUpdatePartnershipOwnerDialog}
          userName={dataItem.fullName}
          partnershipIds={partnershipIdsToMap}
          callback={deleteUser}
        />
      )}
    </>
  );
};

/// CSS

const useCellStyles = makeStyles()((theme) => ({
  container: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    minHeight: 28,
  },
  actionContainer: {
    display: "flex",
    columnGap: theme.spacing(1),
    "& .MuiSvgIcon-root": {
      fontSize: 12,
      color: theme.palette.alpha800,
    },
  },
  disabled: {
    opacity: "50%",
  },
  marginRight: {
    marginRight: theme.spacing(4.5),
  },
  sendButton: {
    color: "red",
    svg: {
      transform: "rotate(45deg)",
      fill: "red",
    },
  },
}));

/// I18N

const i18n = defineMessages({
  edit: {
    id: "Users.ActionCell.Edit",
    defaultMessage: "Edit",
  },
  pendingInvitation: {
    id: "Users.ActionCell.PendingInvitation",
    defaultMessage: "Invited by {name}",
  },
  resend: {
    id: "Users.ActionCell.ResendInvitation",
    defaultMessage: "Resend invite",
  },
  pendingApproval: {
    id: "Users.ActionCell.PendingApproval",
    defaultMessage: "Pending approval",
  },
  approve: {
    id: "Users.ActionCell.Approve",
    defaultMessage: "Approve",
  },
  inviteSentAgain: {
    id: "Users.ActionCell.InvitationReSent",
    defaultMessage: "The invitation was sent again.",
  },
  confirmTitle: {
    id: "Users.ActionCell.confirmTitle",
    defaultMessage:
      "Are you sure you want to delete {fullName} from your Reveal workspace?",
  },
  confirmSubTitle: {
    id: "Users.ActionCell.confirmSubTitle",
    defaultMessage:
      "{firstName} will no longer be able to access your workspace.",
  },
  delete: {
    id: "Users.ActionCell.delete",
    defaultMessage: "Permanently delete",
  },
});
