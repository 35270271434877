import { createSvgIcon } from "@mui/material/utils";

import { SvgIcon } from "./types";

const displayName = "PersonStar";

const PersonStar: SvgIcon = createSvgIcon(
  // Using transform to enlarge from provided viewbox="0 0 16 16"
  // to default "0 0 24 24" from `createSvgIcon` -> `SvgIcon`.
  <g fill="none" transform="matrix(1.5,0,0,1.5,0,0)">
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M7.99982 2C7.17139 2 6.49982 2.67157 6.49982 3.5C6.49982 4.32843 7.17139 5 7.99982 5C8.82825 5 9.49982 4.32843 9.49982 3.5C9.49982 2.67157 8.82825 2 7.99982 2ZM5.49982 3.5C5.49982 2.11929 6.61911 1 7.99982 1C9.38053 1 10.4998 2.11929 10.4998 3.5C10.4998 4.88071 9.38053 6 7.99982 6C6.61911 6 5.49982 4.88071 5.49982 3.5ZM3.35679 7.99139L5.7855 9.75595C6.04837 9.94693 6.15836 10.2855 6.05796 10.5945L5.13027 13.4496L7.55898 11.685C7.82185 11.4941 8.17779 11.4941 8.44066 11.685L10.8694 13.4496L9.94168 10.5945C9.84128 10.2855 9.95127 9.94693 10.2141 9.75595L12.6428 7.99139H3.35679ZM2.14654 8.34815C1.55875 7.9211 1.86083 6.99139 2.58737 6.99139H13.4123C14.1388 6.99139 14.4409 7.9211 13.8531 8.34815L10.9489 10.4582L12.0582 13.8723C12.2827 14.5633 11.4919 15.1379 10.9041 14.7109L7.99982 12.6008L5.09558 14.7109C4.50781 15.1379 3.71694 14.5633 3.94145 13.8723L5.05077 10.4582L2.14654 8.34815Z"
      fill="currentColor"
    />
  </g>,
  displayName
);

PersonStar.displayName = displayName;
export default PersonStar;
