import { createSvgIcon } from "@mui/material/utils";

import { SvgIcon } from "./types";

const displayName = "BookmarkAdd";

const BookmarkAdd: SvgIcon = createSvgIcon(
  // Using transform to enlarge from provided viewbox="0 0 16 16"
  // to default "0 0 24 24" from `createSvgIcon` -> `SvgIcon`.
  <g fill="none" transform="matrix(1.5,0,0,1.5,0,0)">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3.5 1C2.67157 1 2 1.67157 2 2.5V13.2817C2 14.4318 3.24105 15.154 4.24101 14.5859L7.753 12.5904C7.90617 12.5034 8.09383 12.5034 8.247 12.5904L11.759 14.5859C12.759 15.154 14 14.4318 14 13.2817V2.5C14 1.67157 13.3284 1 12.5 1H3.5ZM8 3.25C8.27614 3.25 8.5 3.47386 8.5 3.75V6.25H11.125C11.4011 6.25 11.625 6.47386 11.625 6.75C11.625 7.02614 11.4011 7.25 11.125 7.25H8.5V9.75C8.5 10.0261 8.27614 10.25 8 10.25C7.72386 10.25 7.5 10.0261 7.5 9.75V7.25H5.125C4.84886 7.25 4.625 7.02614 4.625 6.75C4.625 6.47386 4.84886 6.25 5.125 6.25H7.5V3.75C7.5 3.47386 7.72386 3.25 8 3.25Z"
      fill="currentColor"
    />
  </g>,
  displayName
);

BookmarkAdd.displayName = displayName;
export default BookmarkAdd;
