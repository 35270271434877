import { Box } from "@mui/material";
import withCompanyPermissions from "components/hoc/withCompanyPermissions";
import PageForbidden from "components/ui/PageForbidden";
import { QuickSearch } from "components/ui/QuickSearch";
import { T } from "components/ui/Typography";
import useSegment from "hooks/useSegment";
import useTrack from "hooks/useTrack";
import useUserProfile from "hooks/useUserProfile";
import _ from "lodash";
import { makeStyles } from "makeStyles";
import { PayingFeature } from "models/CompanyPayingFeatureSubscription";
import {
  ChangeEvent,
  FocusEvent,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { defineMessages, FormattedMessage, useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { selectActivePayingFeatures } from "redux/api/selectors";
import { setFilters } from "redux/directory/actions";
import { AvailableDirectoryColumns } from "redux/directory/defaults";
import { selectDirectoryView } from "redux/directory/selectors";
import { DirectoryEvent } from "tracking";

import { useTableRows } from "../../DataTables/shared/hooks";
import { MatchFilterType } from "../../DataTables/shared/types";
import { Content } from "./Content";

const Directory = () => {
  const { classes } = useStyles();
  const intl = useIntl();
  const dispatch = useDispatch();
  const { view } = useSelector(selectDirectoryView);
  const [currentSearch, setCurrentSearch] = useState("");
  const searchInputRef = useRef<HTMLInputElement>(null);
  const payingFeatures = useSelector(selectActivePayingFeatures);
  const { profile } = useUserProfile();
  const { track } = useSegment();
  const filters = view.filters;
  const sort = view.sort;
  const currentSearchFilter = filters.find(
    (f) => f.fieldname === AvailableDirectoryColumns.name
  )?.value;

  const { records, count, sync, fetching, loadMore, refresh } = useTableRows(
    "directory_companies",
    filters,
    sort,
    null,
    true,
    undefined,
    undefined,
    undefined,
    undefined,
    true
  );

  useTrack(DirectoryEvent.viewDirectory);

  const dispatchSearch = useMemo(
    () =>
      _.debounce((search: string) => {
        dispatch(
          setFilters([
            ...filters.filter(
              (f) => f.fieldname !== AvailableDirectoryColumns.name
            ),
            {
              fieldname: AvailableDirectoryColumns.name,
              value: search,
              type: MatchFilterType.CONTAINS,
            },
          ])
        );
      }, 150),
    [dispatch, filters]
  );

  const trackSearch = useMemo(
    () =>
      _.debounce((search: string) => {
        track(DirectoryEvent.searchedDirectory, { keyword: search });
      }, 3000),
    [track]
  );

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    setCurrentSearch(event.target.value);
    trackSearch(event.target.value);
    dispatchSearch(event.target.value);
  };

  const handleClear = () => {
    setCurrentSearch("");
    dispatchSearch("");
  };

  // Due to an issue with google recaptcha, input loses focus each recaptcha call.
  // We need to check the relatedTarget and refocusing the input.
  const onBlur = (e: FocusEvent<HTMLInputElement>) => {
    if (e.relatedTarget && searchInputRef.current !== null) {
      if (e.relatedTarget.nodeName === "IFRAME") {
        searchInputRef.current.focus();
      }
    }
  };

  const input = (
    <QuickSearch
      autoFocus
      ref={searchInputRef}
      value={currentSearch}
      placeholder={intl.formatMessage(i18n.search)}
      onChange={handleChange}
      onClear={handleClear}
      onBlur={onBlur}
    />
  );

  // sets the initial value of the search input
  useEffect(() => {
    setCurrentSearch(currentSearchFilter ?? "");
  }, [currentSearchFilter]); // eslint-disable-line react-hooks/exhaustive-deps

  if (
    !profile.canManagePartnerships &&
    !payingFeatures.includes(PayingFeature.SalesInvitations)
  ) {
    return <PageForbidden />;
  }

  return (
    <>
      <div className={classes.root}>
        <Box mt={2.5} mx={2.5}>
          <T h2 bold>
            <FormattedMessage {...i18n.directory} />
          </T>
        </Box>
        <Box display="flex" gap={2} height="100%">
          <Content
            input={input}
            loadMore={loadMore}
            reset={refresh}
            rows={records}
            rowsCount={count ?? 0}
            loading={fetching || !sync}
          />
        </Box>
      </div>
    </>
  );
};

export default withCompanyPermissions(["company.partners_access"])(Directory);

/// CSS

const useStyles = makeStyles()((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    height: "100%",
  },
  search: {
    width: 164,
  },
}));

/// I18N

const i18n = defineMessages({
  directory: {
    id: "Directory.Title",
    defaultMessage: "Directory",
  },
  search: {
    id: "Directory.Search",
    defaultMessage: "Search Partners",
  },
  loadMore: {
    id: "Directory.loadMore",
    defaultMessage: "Load more",
  },
  noResult: {
    id: "Directory.noResult",
    defaultMessage: "There are no companies matching these filters.",
  },
});
