import { createAction } from "@reduxjs/toolkit";
import { NotificationStatus } from "components/ui/Notifications/NotificationSnackbar";
import uniqueId from "lodash/uniqueId";
import { MessageDescriptor } from "react-intl";

import {
  NotificationAction,
  NotificationItem,
  NotificationType,
} from "./typing";

/**
 * Action creator to push a new notification to the store.
 */
export const pushNotification = createAction(
  NotificationType.push,
  (
    message: string | MessageDescriptor,
    context?: Record<string, any>,
    actions?: NotificationAction,
    description?: string | MessageDescriptor,
    status?: NotificationStatus
  ): { payload: NotificationItem } => ({
    payload: {
      id: uniqueId("notification-"),
      timestamp: Date.now(),
      message,
      description,
      status,
      actions,
      context,
      read: false,
    },
  })
);

export const markNotificationAsRead = createAction<string>(
  NotificationType.markAsRead
);
