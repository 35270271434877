import {
  Avatar,
  Box,
  ClassNameMap,
  FormControl,
  SelectChangeEvent,
} from "@mui/material";
import { AxiosResponse } from "axios";
import { SelectInput } from "components/ui/SelectInput";
import { T } from "components/ui/Typography";
import SuggestedPartner from "models/SuggestedPartner";
import { useEffect, useState } from "react";
import { defineMessages, useIntl } from "react-intl";
import JSONAPIService from "services/JSONAPIService";

import { IContact, IInviteForm, INewContact } from "../types";

const LOADING = "Loading...";

type Props = {
  classes: ClassNameMap<string>;
  selectedUser?: IContact | INewContact | null;
  setFieldValue: (
    field: string,
    value: any,
    shouldValidate?: boolean | undefined
  ) => void;
  setSelectedUser: (contact: IContact | INewContact | null) => void;
  suggestions: SuggestedPartner[];
  values: IInviteForm;
};
const newContactValue = JSON.stringify({ isNewContact: true });
export const InviteFormContactSelect = ({
  classes,
  selectedUser,
  setFieldValue,
  setSelectedUser,
  suggestions,
  values,
}: Props) => {
  const intl = useIntl();
  const [contacts, setContacts] = useState<IContact[] | null>(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    let mounted = true;
    setLoading(true);
    const fetchContacts = async () => {
      const service = new JSONAPIService("crm_accounts");
      const contacts: AxiosResponse = await service.rawGet(
        `${
          suggestions.find(
            (suggestion) => suggestion.partnerName === values.company.name
          )?.rawCompany?.id ?? -1
        }/contacts/`
      );
      return contacts.data.data;
    };
    fetchContacts()
      .then((contacts) => {
        if (mounted) {
          setContacts(contacts);
        }
      })
      .finally(() => setLoading(false));
    return () => {
      mounted = false;
    };
  }, [values.company.name, suggestions]);

  useEffect(() => {
    if (!selectedUser) {
      setContacts(null);
    }
  }, [selectedUser]);

  const selectUser = (event: SelectChangeEvent<string>) => {
    if (event.target.value) {
      const contact = JSON.parse(event.target.value) as IContact | INewContact;
      if ("isNewContact" in contact) {
        setFieldValue("email", "");
        setFieldValue("first_name", "");
        setFieldValue("last_name", "");
        setSelectedUser({ isNewContact: true });
        return;
      }
      setSelectedUser(contact);
      setFieldValue("email", contact.email);
      setFieldValue("first_name", contact.first_name);
      setFieldValue("last_name", contact.last_name);
    } else {
      setFieldValue("email", "");
      setFieldValue("first_name", "");
      setFieldValue("last_name", "");
      setSelectedUser(null);
    }
  };

  return (
    <FormControl fullWidth className={classes.companyForm}>
      <SelectInput
        displayEmpty={false}
        disabled={loading}
        onChange={selectUser}
        placeholder={intl.formatMessage(i18n.contactPlaceholder)}
        value={
          loading
            ? LOADING
            : !selectedUser
            ? ""
            : !("isNewContact" in selectedUser)
            ? JSON.stringify(selectedUser)
            : newContactValue
        }
        options={[
          {
            label: intl.formatMessage(i18n.newContact),
            value: newContactValue,
          },
          ...(contacts?.map((item) => ({
            value: JSON.stringify(item),
            label: (
              <Box className={classes.labelBox}>
                <Avatar sx={{ fontSize: 12, width: 24, height: 24 }}>
                  {item.first_name && item.last_name
                    ? `${item.first_name
                        .charAt(0)
                        .toUpperCase()}${item.last_name
                        .charAt(0)
                        .toUpperCase()}`
                    : item.first_name
                    ? item.first_name.charAt(0).toUpperCase()
                    : item.last_name
                    ? item.last_name.charAt(0).toUpperCase()
                    : "NA"}
                </Avatar>
                &nbsp; &nbsp;
                {item.first_name && (
                  <>
                    {item.first_name}
                    &nbsp;
                  </>
                )}
                {item.last_name && (
                  <>
                    {item.last_name}
                    &nbsp;
                  </>
                )}
                {(item.first_name || item.last_name) && <>&middot;&nbsp;</>}
                <T>{item.email}</T>
                <T className={classes.contactTitle}>{item.title}</T>
              </Box>
            ),
          })) ?? []),
        ]}
      />
    </FormControl>
  );
};

const i18n = defineMessages({
  contactPlaceholder: {
    id: "partners.invite.contactPlaceholder",
    defaultMessage: "Select contact from CRM",
  },
  newContact: {
    id: "partners.invite.newContact",
    defaultMessage: "Your own contact",
  },
});
