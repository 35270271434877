import {
  gsheetClientId,
  hubspotClientId,
  msdynamicsClientId,
  pipedriveClientId,
  ProviderType,
  salesforceClientId,
  zohoClientId,
} from "config/constants";
import {
  CRMProviderType,
  ProviderConfig,
} from "screens/Frontoffice/shared/helpers/types";

export const crmProviders: Record<CRMProviderType, ProviderConfig> = {
  [ProviderType.gsheet]: {
    name: "Google Sheets",
    logo: "/images/providers/Gsheet.png",
    oauth:
      "https://accounts.google.com/o/oauth2/v2/auth?prompt=consent&access_type=offline&",
    clientId: gsheetClientId,
  },
  [ProviderType.hubspot]: {
    name: "HubSpot",
    logo: "/images/providers/Hubspot.png",
    oauth: "https://app.hubspot.com/oauth/authorize?",
    clientId: hubspotClientId,
  },
  [ProviderType.msDynamics]: {
    name: "Microsoft Dynamics",
    logo: "/images/providers/MSDynamics.png",
    oauth: "https://login.microsoftonline.com/common/oauth2/v2.0/authorize?",
    clientId: msdynamicsClientId,
  },
  [ProviderType.pipedrive]: {
    name: "Pipedrive",
    logo: "/images/providers/Pipedrive.png",
    oauth: "https://oauth.pipedrive.com/oauth/authorize?",
    clientId: pipedriveClientId,
  },
  [ProviderType.salesforce]: {
    name: "Salesforce",
    logo: "/images/providers/Salesforce.png",
    oauth: "https://login.salesforce.com/services/oauth2/authorize?",
    oauthSandbox: "https://test.salesforce.com/services/oauth2/authorize?",
    clientId: salesforceClientId,
  },
  [ProviderType.zoho]: {
    name: "Zoho",
    logo: "/images/providers/Zoho.png",
    oauth: "https://accounts.zoho.com/oauth/v2/auth?",
    clientId: zohoClientId,
    scope:
      "ZohoCRM.modules.READ,ZohoCRM.settings.READ,ZohoCRM.users.READ,ZohoCRM.org.READ",
  },
  [ProviderType.upsales]: {
    name: "Upsales",
    logo: "/images/providers/Upsales.png",
  },
  [ProviderType.freshsales]: {
    name: "Freshsales",
    logo: "/images/providers/Freshsales.png",
  },
  [ProviderType.stripe]: {
    name: "Stripe",
    logo: "/images/providers/Stripe.png",
  },
};

export const crmFileProviderLabelAndLogos = {
  [ProviderType.file]: {
    name: "CSV",
    logo: "/images/providers/CSV.png",
  },
  [ProviderType.gsheet]: {
    name: "Google Sheets",
    logo: "/images/providers/Gsheet.png",
  },
};

export enum ConnectCrmStep {
  SourceSelect = "SourceSelect",
  ColumnMapping = "ColumnMapping",
  StatusRules = "StatusRules",
  Assignment = "Assignment",
  SourceActivation = "SourceActivation",
}
