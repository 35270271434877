import { Facet } from "components/ui/Facet";
import ToggleBtnGroup from "components/ui/ToggleBtnGroup";
import useSegment from "hooks/useSegment";
import { makeStyles } from "makeStyles";
import {
  defineMessages,
  FormattedMessage,
  MessageDescriptor,
} from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { setFilters } from "redux/directory/actions";
import { selectDirectoryView } from "redux/directory/selectors";
import {
  DirectoryFacets,
  DirectoryToggleFilterType,
} from "redux/directory/types";
import { DirectoryEvent } from "tracking";

import { FilterType, MatchFilterType } from "../../DataTables/shared/types";

export type DirectoryToggleFilterProps = {
  facets: { [key in DirectoryFacets]?: number };
  callback?: () => void;
};

export const DirectoryToggleFilter = ({
  facets,
  callback,
}: DirectoryToggleFilterProps) => {
  const { classes } = useStyles();
  const { track } = useSegment();
  const { view } = useSelector(selectDirectoryView);
  const dispatch = useDispatch();
  const filters = view.filters;

  const handleSetValue = async (value: string) => {
    track(DirectoryEvent.clickedSuggestionsTab, { value });
    if (!value) {
      await dispatch(
        setFilters([
          ...filters.filter(
            (item) =>
              !(Object.values(DirectoryToggleFilterType) as string[]).includes(
                item.fieldname
              )
          ),
        ])
      );
    } else {
      await dispatch(
        setFilters([
          ...filters.filter(
            (item) =>
              !(Object.values(DirectoryToggleFilterType) as string[]).includes(
                item.fieldname
              )
          ),
          { fieldname: value, value: true, type: MatchFilterType.IS },
        ])
      );
    }
    callback?.();
  };

  const filterLabel = (
    label: MessageDescriptor,
    filterType?: DirectoryFacets
  ) => (
    <div className={classes.labelContainer}>
      <span>{<FormattedMessage {...label} />}</span>
      {filterType && (
        <Facet
          value={(facets as { [key in DirectoryFacets]?: number })[filterType]}
          isSelected={getCurrentDirectoryFacet(filters) === filterType}
        />
      )}
    </div>
  );

  return (
    <ToggleBtnGroup
      items={[
        {
          label: filterLabel(i18n.all, DirectoryFacets.all),
          value: "",
          tooltip: <FormattedMessage {...i18n.all} />,
        },
        {
          label: filterLabel(i18n.highOverlap, DirectoryFacets.IsRecommended),
          value: DirectoryToggleFilterType.IsRecommended,
          tooltip: <FormattedMessage {...i18n.highOverlapTooltip} />,
        },
        {
          label: filterLabel(i18n.manual, DirectoryFacets.IsYouMayKnow),
          value: DirectoryToggleFilterType.IsYouMayKnow,
          tooltip: <FormattedMessage {...i18n.manualTooltip} />,
        },
        {
          label: filterLabel(i18n.crm, DirectoryFacets.IsInCrm),
          value: DirectoryToggleFilterType.IsInCrm,
          tooltip: <FormattedMessage {...i18n.crmTooltip} />,
        },
      ]}
      setValue={handleSetValue}
      value={
        filters.find((item) =>
          (Object.values(DirectoryToggleFilterType) as string[]).includes(
            item.fieldname
          )
        )?.fieldname ?? ""
      }
      classes={{ root: classes.toggleBtnGroup }}
    />
  );
};

// Helpers

const getCurrentDirectoryFacet = (filters: FilterType[]) => {
  if (
    filters.find((item) => item.fieldname === DirectoryToggleFilterType.IsInCrm)
  ) {
    return DirectoryFacets.IsInCrm;
  } else if (
    filters.find(
      (item) => item.fieldname === DirectoryToggleFilterType.IsRecommended
    )
  ) {
    return DirectoryFacets.IsRecommended;
  } else if (
    filters.find(
      (item) => item.fieldname === DirectoryToggleFilterType.IsYouMayKnow
    )
  ) {
    return DirectoryFacets.IsYouMayKnow;
  }
  return DirectoryFacets.all;
};

// CSS

const useStyles = makeStyles()((theme) => ({
  labelContainer: {
    display: "flex",
    columnGap: theme.spacing(1),
  },
  facet: {
    display: "flex",
    justifyContent: "center",
    minWidth: 22.5,
  },
  toggleBtnGroup: {
    [theme.breakpoints.down("md")]: {
      display: "table",
    },
  },
}));

// I18N

const i18n = defineMessages({
  all: {
    id: "Directory.ToggleFilter.all",
    defaultMessage: "All",
  },
  crm: {
    id: "Directory.ToggleFilter.crm",
    defaultMessage: "Partners in my CRM",
  },
  crmTooltip: {
    id: "Directory.ToggleFilter.crmTooltip",
    defaultMessage: "Partners identified in your CRM",
  },
  highOverlap: {
    id: "Directory.ToggleFilter.highOverlap",
    defaultMessage: "Recommended by Reveal",
  },
  highOverlapTooltip: {
    id: "Directory.ToggleFilter.highOverlapTooltip",
    defaultMessage:
      "We recommend these partners based on your high level of CRM overlap.",
  },
  manual: {
    id: "Directory.ToggleFilter.manual",
    defaultMessage: "Companies you may know",
  },
  manualTooltip: {
    id: "Directory.ToggleFilter.manualTooltip",
    defaultMessage:
      "These companies share industry synergies with you. They may be existing or potential partners.",
  },
});
