import { TypographyVariant } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import { makeStyles } from "makeStyles";
import { forwardRef, Ref } from "react";

import {
  customStyles,
  HTMLTextElement,
  isH1,
  isH2,
  isH3,
  isH4,
  Props,
} from "./types";

export const MaterialUITypographyConnector = forwardRef(
  (props: Props, ref: Ref<HTMLTextElement>) => {
    let { variant, extraStyles } = getOldVariantStyles(props.oldVariant);
    const { classes, cx } = useStyles({ ...props, extraStyles });

    const htmlProps = {
      variant,
      className: cx(classes.typography, props.className),
      title: props.title,
      noWrap: props.noWrap,
      onBlur: props.onBlur,
      onFocus: props.onFocus,
      onMouseLeave: props.onMouseLeave,
      onMouseOver: props.onMouseOver,
      onTouchEnd: props.onTouchEnd,
      onTouchStart: props.onTouchStart,
      ref,
    };

    if (Boolean(variant)) {
      return <Typography {...htmlProps}>{props.children}</Typography>;
    }

    // If no `oldVariant` was specified, then we rely on default and normal variant property.
    // This only works for 1:1 mappings between old and new styles, so we restrict to
    // - headings 1, 2, 3, 4 (we don't have clear 1:1 mappings for h5 nor h6);
    // - body1 <-> smallBody (which is the default for old and new styles).

    variant = "body1";

    if (isH1(props)) {
      variant = "h1";
    }

    if (isH2(props)) {
      variant = "h2";
    }

    if (isH3(props)) {
      variant = "h3";
    }

    if (isH4(props)) {
      variant = "h4";
    }

    return (
      <Typography {...htmlProps} variant={variant}>
        {props.children}
      </Typography>
    );
  }
);

type StyleProps = Props & Pick<VariantAndStyles, "extraStyles">;

export const useStyles = makeStyles<StyleProps>()((theme, props) => ({
  typography: {
    ...customStyles(props),
    ...(props.bold ? { fontWeight: "bold" as const } : {}),
    ...props.extraStyles,
  },
}));

type VariantAndStyles = {
  variant?: TypographyVariant;
  extraStyles: { [key: string]: React.CSSProperties };
};

const getOldVariantStyles = (oldVariant?: string): VariantAndStyles => {
  if (!oldVariant) {
    return {
      variant: undefined,
      extraStyles: {},
    };
  }

  const [variant, ...extraStylesStrings] = oldVariant.split("_");

  if (
    ![
      "h1",
      "h2",
      "h3",
      "h4",
      "h5",
      "h6",
      "subtitle1",
      "subtitle2",
      "body1",
      "body2",
      "caption",
      "button",
      "overline",
    ].includes(variant)
  ) {
    throw Error(
      "`oldVariant` prop should be prefixed by a valid variant like `body1_fontSize:13px`"
    );
  }

  const extraStyles: any = {};
  extraStylesStrings.forEach((styleString) => {
    const [key, value] = styleString.split(":");
    extraStyles[key] = value;
  });

  return {
    variant,
    extraStyles,
  } as VariantAndStyles;
};
