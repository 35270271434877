import CompanyAvatar from "components/ui/avatars/CompanyAvatar";
import { useEffect, useState } from "react";
import { CompanyAvatarService } from "services/CompanyAvatarService";
import { PartnershipResult } from "services/GlobalSearchService";

import Highlighted from "./Highlighted";
import OptionContainer from "./OptionContainer";

type Props = {
  partnership: PartnershipResult;
  searchWords: string[];
};

const PartnerOption = ({ partnership, searchWords }: Props) => {
  const [avatarSrc, setAvatarSrc] = useState<string | null>(null);

  useEffect(() => {
    const service = new CompanyAvatarService();
    service
      .getForPartner(partnership.partner_id, partnership.id)
      .then(setAvatarSrc);
  }, [partnership.id, partnership.partner_id]);

  const left = (
    <>
      <CompanyAvatar
        companyName={partnership.partner_name}
        size="xxs"
        src={avatarSrc}
      />
      <Highlighted searchWords={searchWords} text={partnership.partner_name} />
    </>
  );
  return <OptionContainer left={left} />;
};

export default PartnerOption;
