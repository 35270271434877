import Box from "@mui/material/Box";
import { T } from "components/ui/Typography";
import { makeStyles } from "makeStyles";
import { MouseEvent, ReactElement } from "react";

import CompanyAvatar from "../avatars/CompanyAvatar";

type Props = {
  partnerName?: ReactElement | string;
  avatarUrl?: string;
  handleClick?: (e: MouseEvent) => void;
  isGold?: boolean;
};

const PartnerChip = ({
  partnerName = "",
  avatarUrl,
  handleClick = () => {},
  isGold = false,
}: Props) => {
  const { classes, cx } = useStyles();

  return (
    <Box
      className={cx(classes.container, {
        [classes.gold]: isGold,
        [classes.pointer]: !!handleClick,
      })}
      onClick={handleClick}
    >
      <CompanyAvatar size="xs" src={avatarUrl} />
      <T>{partnerName}</T>
    </Box>
  );
};

export default PartnerChip;

// CSS

const useStyles = makeStyles()((theme) => ({
  container: {
    display: "inline-flex",
    columnGap: 6,
    alignItems: "center",
    height: 32,
    borderRadius: 6,
    border: `1px solid ${theme.palette.greyscale250}`,
    paddingLeft: theme.spacing(0.5),
    paddingRight: theme.spacing(1),
  },
  gold: {
    borderColor: theme.palette.gold200,
    backgroundColor: theme.palette.gold050,
  },
  pointer: {
    cursor: "pointer",
  },
}));
