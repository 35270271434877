import CompanyAvatar from "components/ui/avatars/CompanyAvatar";
import { T } from "components/ui/Typography";
import { makeStyles } from "makeStyles";
import { PartnerRepresentation } from "models/Partnership";
import Record from "models/Record";
import { defineMessages, FormattedMessage, useIntl } from "react-intl";

type Props = {
  recordsToRefer: Record[];
  partner?: PartnerRepresentation | null;
  isDemo?: boolean;
};

const ReferTitle = ({ recordsToRefer, partner, isDemo = false }: Props) => {
  const { classes } = useStyles();
  const intl = useIntl();

  const severalAccounts = recordsToRefer.length > 1;
  const severalPartners = !Boolean(partner) && !isDemo; // 360 Mapping does not include partner property

  // Records on 360 Mapping have name property
  // instead of leftName
  const toReferLabel = severalAccounts
    ? intl.formatMessage(i18n.severalAccounts, {
        count: recordsToRefer.length,
      })
    : recordsToRefer[0]?.name ?? recordsToRefer[0]?.leftName;
  const partnerLabel = severalPartners
    ? intl.formatMessage(i18n.partners)
    : isDemo
    ? "Demo"
    : partner?.name;

  // Title for most cases
  const detailedTitle = (
    <T h3 bold className={classes.title}>
      <FormattedMessage {...i18n.refer} />
      <div className={classes.boldLabel}>
        {!severalAccounts && (
          <CompanyAvatar companyName={toReferLabel} size="xs" />
        )}
        {toReferLabel}
      </div>
      <FormattedMessage {...i18n.to} />
      <div className={classes.boldLabel}>
        {!severalPartners && (
          <CompanyAvatar
            companyName={partnerLabel}
            src={
              isDemo
                ? "/images/reveal-logo-black-on-purple.svg"
                : partner?.avatarUrl
            }
            size="xs"
          />
        )}
        {partnerLabel}
      </div>
    </T>
  );

  // Title for ReferType.MULTIPLE_TO_MULTIPLE
  const simplifiedTitle = (
    <T h3 bold className={classes.centerElement}>
      <FormattedMessage {...i18n.simplifiedTitle} />
    </T>
  );

  return severalAccounts && severalPartners ? simplifiedTitle : detailedTitle;
};

export default ReferTitle;

// CSS

const useStyles = makeStyles()((theme) => ({
  title: { display: "flex", flexWrap: "wrap", columnGap: 4 },
  centerElement: {
    textAlign: "center",
  },
  boldLabel: {
    display: "flex",
    flexWrap: "nowrap",
    columnGap: 4,
    fontWeight: "bold",
    color: theme.palette.midnight,
  },
}));

// I18N

const i18n = defineMessages({
  refer: {
    id: "DataTables.ActionBtn.ReferOption.ReferTitle.refer",
    defaultMessage: "Refer",
  },
  to: {
    id: "DataTables.ActionBtn.ReferOption.ReferTitle.to",
    defaultMessage: "to",
  },
  partners: {
    id: "DataTables.ActionBtn.ReferOption.ReferTitle.partners",
    defaultMessage: "partner(s)",
  },
  simplifiedTitle: {
    id: "DataTables.ActionBtn.ReferOption.ReferTitle.simplifiedTitle",
    defaultMessage: "Choose the partner(s) you want to refer accounts to",
  },
  severalAccounts: {
    id: "DataTables.ActionBtn.ReferOption.ReferTitle.severalAccounts",
    defaultMessage: "{count} accounts",
  },
});
