import getFirstValueFromURL from "helpers/getFirstValueFromURL";
import useAllRecords from "hooks/useAllRecords";
import useUserProfile from "hooks/useUserProfile";
import CrmCredential from "models/CrmCredential";
import { useLocation } from "react-router";

type Props = { onRefresh?: () => void };

export const useConnectCrmDialog = ({ onRefresh }: Props = {}) => {
  const location = useLocation();
  const { profile } = useUserProfile();

  const loading = location.hash === "#loading";
  const fieldsNotReady = location.hash === "#fields-not-ready";
  const isConnectCrmDialogOpen =
    getFirstValueFromURL(location, "init") === "true";
  const provider = getFirstValueFromURL(location, "provider");
  const integrationId = getFirstValueFromURL(location, "integration");

  const { records: integrations, refresh } = useAllRecords("integrations", {
    filters: {
      company: profile.company.id,
    },
    include: ["partnership_integrations"],
    fields: {
      integrations: [
        "company",
        "mapping_configured",
        "name",
        "provider",
        "default_source",
        "partnership_integrations",
        "api_error",
        "last_sync_started_at",
      ],
    },
  });

  const {
    loading: loadingCrmCredentials,
    records: crmCredentials,
    refresh: refreshCredentials,
  } = useAllRecords<CrmCredential>(
    "crm_credentials",
    {
      filters: {
        company_id: profile.company.id,
      },
    },
    undefined,
    [profile.company.integrationCount]
  );

  const refreshForConnectCrmDialog = () => {
    refreshCredentials();
    refresh();
    onRefresh?.();
  };

  return {
    integrationId,
    provider,
    isConnectCrmDialogOpen,
    loading,
    fieldsNotReady,
    crmCredentials,
    integrations,
    loadingCrmCredentials,
    refresh,
    refreshForConnectCrmDialog,
  };
};
