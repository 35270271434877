import { Chip } from "@mui/material";
import Grid from "@mui/material/Grid";
import Toggle from "components/ui/Toggle";
import Tooltip from "components/ui/Tooltip";
import { T } from "components/ui/Typography";
import _ from "lodash";
import { makeStyles } from "makeStyles";
import { defineMessages, FormattedMessage } from "react-intl";

import { ColumnConfigExtendedType } from "./ColumnWidget";

type Props = {
  field: ColumnConfigExtendedType;
  isActive: boolean;
  handleChange?: $TSFixMeFunction;
  draggable?: boolean;
  isToggleDisabled?: boolean;
};

const ColumnItem = ({
  field,
  isActive,
  handleChange,
  draggable = false,
  isToggleDisabled = false,
}: Props) => {
  const { classes } = useStyles({ draggable });

  const label = _.get(field, `label`, "");
  const removable = field.removable ?? true;
  const disabled = field.disabled;

  return (
    <Grid item container className={classes.root}>
      <Grid
        item
        justifyContent="space-between"
        alignItems="center"
        wrap="nowrap"
        container
      >
        <Grid item container alignItems="center">
          <T className={classes.text}>{label}</T>
          {!field.smartField && (
            <Chip
              className={classes.chip}
              label={<FormattedMessage {...i18n.customField} />}
            />
          )}
        </Grid>
      </Grid>
      <Tooltip
        title={
          disabled ? (
            <FormattedMessage {...i18n.ongoingImport} />
          ) : !removable ? (
            <FormattedMessage {...i18n.notRemovable} />
          ) : (
            ""
          )
        }
        placement="right"
      >
        <div>
          <Toggle
            checked={isActive}
            handleChange={handleChange}
            disabled={!removable || isToggleDisabled || disabled}
          />
        </div>
      </Tooltip>
    </Grid>
  );
};

export default ColumnItem;

// I18N

const i18n = defineMessages({
  notRemovable: {
    id: "ColumnWidget.ColumnItem.notRemovable",
    defaultMessage: "This column can’t be removed",
  },
  ongoingImport: {
    id: "ColumnWidget.ColumnItem.ongoingImport",
    defaultMessage: "Ongoing import - Available soon",
  },
  customField: {
    id: "ColumnWidget.ColumnItem.customField",
    defaultMessage: "Custom field",
  },
});

// CSS

export const useStyles = makeStyles<{ draggable: boolean }>()(
  (theme, { draggable }) => ({
    root: {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      flexWrap: "nowrap",
      paddingLeft: draggable ? 0 : 22,
      paddingRight: theme.spacing(1),
    },
    text: {
      marginRight: theme.spacing(1),
    },
    chip: {
      height: theme.spacing(2),
      fontSize: 9,
      // @ts-expect-error ts-migrate(2339) FIXME: Property 'light' does not exist on type 'Color'.
      backgroundColor: theme.palette.grey.light,
      marginRight: theme.spacing(3),
    },
  })
);
