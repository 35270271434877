import { useCallback, useEffect, useState } from "react";

type UsePollingReturnType<T> = {
  data: T | null;
  error: Error | null;
};

export const usePolling = <T>(
  fetchFunction: () => Promise<T>,
  intervalInMilliseconds: number,
  shouldPoll: boolean = true
): UsePollingReturnType<T> => {
  const [data, setData] = useState<T | null>(null);
  const [error, setError] = useState<Error | null>(null);

  const fetchData = useCallback(async () => {
    try {
      const response = await fetchFunction();
      setData(response);
    } catch (err) {
      setError(err as Error);
    }
  }, [fetchFunction]);

  useEffect(() => {
    if (!shouldPoll) {
      return;
    }

    const intervalId = setInterval(() => {
      fetchData();
    }, intervalInMilliseconds);

    return () => {
      clearInterval(intervalId);
    };
  }, [fetchData, intervalInMilliseconds, shouldPoll]);

  return { data, error };
};
