import Popup from "components/ui/Popup";
import { T } from "components/ui/Typography";
import { defineMessages, FormattedMessage } from "react-intl";

type Props = {
  isOpen?: boolean;
  onClose: () => void;
  next: string | Location;
};

export const StripeConnectDialog = ({
  isOpen = false,
  onClose,
  next,
}: Props) => {
  return (
    <Popup
      isOpen={isOpen}
      title={
        <T h2 bold>
          <FormattedMessage {...i18n.title} />
        </T>
      }
      handleClose={onClose}
      hideActions
      maxWidth="sm"
      variant="secondary"
    >
      <p>
        <FormattedMessage {...i18n.stripeDescription_1} />
      </p>
      <p>
        <FormattedMessage {...i18n.stripeDescription_2} />
      </p>
      <a
        href="https://marketplace.stripe.com/apps/reveal"
        rel="noreferrer"
        target="_blank"
      >
        https://marketplace.stripe.com/apps/reveal
      </a>
    </Popup>
  );
};

/// I18N

const i18n = defineMessages({
  title: {
    id: "StripeConnectDialog.title",
    defaultMessage: "Connect Stripe ",
  },
  stripeDescription_1: {
    id: "StripeConnectDialog.stripeDescription_1",
    defaultMessage:
      "To connect your Stripe Account, please have your Stripe Admin enable the connection in the Reveal App Settings within Stripe.",
  },
  stripeDescription_2: {
    id: "StripeConnectDialog.stripeDescription_2",
    defaultMessage:
      "You can find the Reveal App in the Stripe Marketplace here:",
  },
});
