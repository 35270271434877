import {
  Companies as CompaniesIcon,
  Partnerships as PartnershipsIcon,
  ProductionDashboard as ProductionDashboardIcon,
  Runs as RunsIcon,
  Users as UsersIcon,
} from "components/icons";
import { defineMessages } from "react-intl";

const i18n = defineMessages({
  users: {
    id: "backoffice.sections.users",
    defaultMessage: "Users",
  },
  companies: {
    id: "backoffice.sections.companies",
    defaultMessage: "Companies",
  },
  partnerships: {
    id: "backoffice.sections.partnerships",
    defaultMessage: "Partnerships",
  },
  runs: {
    id: "backoffice.sections.runs",
    defaultMessage: "Runs",
  },
  productionDashboard: {
    id: "backoffice.sections.productionDashboard",
    defaultMessage: "Production dashboard",
  },
});

export const backofficeSections = [
  {
    path: "/backoffice/partnerships",
    title: i18n.partnerships,
    icon: PartnershipsIcon,
  },
  {
    path: "/backoffice/companies",
    title: i18n.companies,
    icon: CompaniesIcon,
  },
  {
    path: "/backoffice/users",
    title: i18n.users,
    icon: UsersIcon,
  },
  {
    path: "/backoffice/runs",
    title: i18n.runs,
    icon: RunsIcon,
  },
  {
    path: "/backoffice/production-dashboard",
    title: i18n.productionDashboard,
    icon: ProductionDashboardIcon,
  },
];
