import { partnersBase } from "config/constants";
import { constant, guard, object, string } from "decoders";

import { Record } from "./Record";
import { JSONAPIResourceOrRecord } from "./types";

const apiInvitationURLDecoder = object({
  type: constant("invitation_urls" as const),
  id: string,
  attributes: object({
    invitation_id: string,
  }),
});

const apiInvitationURLGuard = guard(apiInvitationURLDecoder);

export default class InvitationURL extends Record<"invitation_urls"> {
  invitationId: string;

  constructor(data: JSONAPIResourceOrRecord<"invitation_urls">) {
    super(data);
    const { attributes } = apiInvitationURLGuard(data);
    this.invitationId = attributes.invitation_id;
  }

  getInvitationURL() {
    return partnersBase + `/i/${this.invitationId}`;
  }
}
