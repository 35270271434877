import { NotificationStatus } from "components/ui/Notifications/NotificationSnackbar";
import { isMessageDescriptor } from "helpers/isMessageDescriptor";
import generic from "i18n/generic";
import { useCallback } from "react";
import { MessageDescriptor } from "react-intl";
import { useDispatch } from "react-redux";
import { pushNotification } from "redux/notifications/actions";
import { NotificationAction } from "redux/notifications/typing";

type notificationContent = {
  status?: NotificationStatus;
  message: string | MessageDescriptor;
  description?: string | MessageDescriptor;
};

const usePushNotification = () => {
  const dispatch = useDispatch();
  return useCallback(
    (
      content:
        | string
        | MessageDescriptor
        | notificationContent
        | "default_error",
      context?: Record<string, any>,
      actions?: NotificationAction
    ) => {
      let message, description, status;
      if (content === "default_error") {
        dispatch(
          pushNotification(
            generic.error_unknown,
            undefined,
            undefined,
            undefined,
            NotificationStatus.warning
          )
        );
      } else {
        if (typeof content === "string" || isMessageDescriptor(content)) {
          message = content;
        } else {
          message = content.message;
          description = content.description;
          status = content.status;
        }
        dispatch(
          pushNotification(message, context, actions, description, status)
        );
      }
    },
    [dispatch]
  );
};

export default usePushNotification;
