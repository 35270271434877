import SuggestedPartner from "models/SuggestedPartner";
import { APICollection } from "redux/api/typing";
import { RootStateWithUser } from "redux/user/typing";

export enum SuggestedPartnersActions {
  addSuggestions = "suggestedPartners/addSuggestions",
  initialize = "suggestedPartners/initialize",
  initializeForDirectory = "suggestedPartners/initializeForDirectory",
  dismissSuggestion = "suggestedPartners/dismissSuggestion",
  fetchRecordCounts = "suggestedPartners/fetchRecordCounts",
}

export interface SuggestedPartnersState {
  hasMore?: boolean | null;
  status: "pending" | "ready" | null;
  suggestionIds: number[];
  recordCounts?: { [x: string]: number };
}

export interface RootStateWithSuggestedPartners extends RootStateWithUser {
  suggestedPartners: SuggestedPartnersState;
  api: {
    entities: {
      suggested_partners?: APICollection<SuggestedPartner>;
    };
  };
}
