import { Paid } from "components/icons";
import Button from "components/ui/Button";
import UpdatePlanDialog from "components/ui/navbar/PremiumPlanWidget/components/UpdatePlanDialog";
import { T } from "components/ui/Typography";
import getFirstValueFromURL from "helpers/getFirstValueFromURL";
import useSegment from "hooks/useSegment";
import { makeStyles } from "makeStyles";
import Match from "models/Match";
import Record from "models/Record";
import { useState } from "react";
import {
  defineMessages,
  FormattedHTMLMessage,
  FormattedMessage,
} from "react-intl";
import { useLocation } from "react-router-dom";
import { PremiumPlanEvent } from "tracking";

import { getCustomersAndProspects } from "./AccountTabs/components/AccountTabs";

type Props = {
  account: Record<"nearbound_prospects" | "crm_accounts">;
  isLimited?: boolean;
};

const PartnerInsightsCTA = ({ account, isLimited = true }: Props) => {
  const { track } = useSegment();
  const location = useLocation();
  const { classes } = useStyles();
  const [dialogOpen, setDialogOpen] = useState(false);

  const { customers, prospects } = getCustomersAndProspects(account);
  const tabValue = +(
    getFirstValueFromURL(location, "tab") ?? Match.STATUS_CUSTOMER
  );
  const remainingCount = Math.max(
    0,
    (tabValue === Match.STATUS_CUSTOMER ? customers : prospects).length - 1
  ); // -1 because the first one is already displayed

  const handleOpenDialog = () => {
    track(PremiumPlanEvent.incentiveDrawer360);
    setDialogOpen(true);
  };

  if (!isLimited) return null;

  return (
    <div className={classes.root}>
      <T h4 bold className={classes.text}>
        <Paid />
        <FormattedMessage
          {...i18n.unlockMore}
          values={{ count: remainingCount }}
        />
      </T>
      <T className={classes.text}>
        <FormattedHTMLMessage
          {...i18n.discoverMore}
          values={{ name: account.name }}
        />
      </T>
      <Button
        variant="secondary"
        label={<FormattedMessage {...i18n.getAccess} />}
        onClick={() => handleOpenDialog()}
        LeftIcon={Paid}
        size="small"
        classes={{ btn: classes.btn }}
      />
      <UpdatePlanDialog
        isOpen={dialogOpen}
        onClose={() => setDialogOpen(false)}
      />
    </div>
  );
};

export default PartnerInsightsCTA;

// CSS

export const useStyles = makeStyles()((theme) => ({
  root: {
    display: "flex",
    background: theme.palette.gradients.darkPurpleToGreen,
    height: 149,
    borderRadius: 6,
    padding: 20,
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    textAlign: "center",
    rowGap: 4,
  },
  text: {
    color: theme.palette.ivory,
    display: "flex",
    alignItems: "center",
    columnGap: 6,
  },
  btn: {
    marginTop: 12,
  },
}));

// I18N

const i18n = defineMessages({
  unlockMore: {
    id: "crm.Ecosystem.AccountDetails.PartnerInsightsCTA.unlockMore",
    defaultMessage:
      "Unlock {count, plural, =0 {partner insights} one {1 more partner insight} other {# more partner insights}}",
  },
  discoverMore: {
    id: "crm.Ecosystem.AccountDetails.PartnerInsightsCTA.discoverMore",
    defaultMessage:
      "Discover more deal influencers at {name} and request introductions from partners<br/>to generate and close more deals.",
  },
  getAccess: {
    id: "crm.Ecosystem.AccountDetails.PartnerInsightsCTA.getAccess",
    defaultMessage: "Get access",
  },
});
