import { Plus, TrashThin } from "components/icons";
import AccountAvatarWithCompany from "components/ui/avatars/AccountAvatarWithCompany";
import Button from "components/ui/Button";
import { T } from "components/ui/Typography";
import { makeStyles } from "makeStyles";
import { defineMessages } from "react-intl";

type Props = {
  name: string;
  avatar?: string | null;
  companyAvatar?: string | null;
  companyName: string;
  role?: string | null;
  onDelete?: (id: string) => void;
  onAdd?: () => void;
  action: "delete" | "add";
};

export const Participant = ({
  name,
  avatar,
  companyAvatar,
  companyName,
  role,
  onDelete,
  onAdd,
  action,
}: Props) => {
  const { classes } = useStyles();

  return (
    <div className={classes.root}>
      <div className={classes.titleContainer}>
        <AccountAvatarWithCompany
          avatar={avatar}
          fullName={name}
          companyAvatar={companyAvatar}
          companyName={companyName}
        />
        <T noWrap bold title={name} className={classes.name}>
          {name}
        </T>
        {role && (
          <>
            <T titleSmall className={classes.role}>
              {"·"}
            </T>
            <T noWrap className={classes.role}>
              {role}
            </T>
          </>
        )}
      </div>
      {action === "add" && (
        <Button
          LeftIcon={Plus}
          label={i18n.add}
          size="xSmall"
          variant="tertiary"
          onClick={onAdd}
        />
      )}
      {action === "delete" && (
        <Button
          LeftIcon={TrashThin}
          size="xSmall"
          onClick={onDelete}
          variant="tertiary"
        />
      )}
    </div>
  );
};

// CSS

export const useStyles = makeStyles()((theme) => ({
  root: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  titleContainer: {
    display: "flex",
    alignItems: "center",
    columnGap: 4,
    maxWidth: 340,
  },
  name: {
    marginLeft: theme.spacing(1),
  },
  role: {
    color: theme.palette.alpha500,
  },
}));

/// I18N

const i18n = defineMessages({
  add: {
    id: "Datables.Pipeline.MessageDrawer.Participant.title",
    defaultMessage: "Add",
  },
});
