import { createSvgIcon } from "@mui/material/utils";

import { SvgIcon } from "./types";

const displayName = "Link";

const Link: SvgIcon = createSvgIcon(
  // Using transform to enlarge from provided viewbox="0 0 12 12"
  // to default "0 0 24 24" from `createSvgIcon` -> `SvgIcon`.
  <g fill="none" transform="matrix(2,0,0,2,0,0)">
    <path
      d="M6.74614 5.25342C7.77664 6.28392 7.77664 7.95417 6.74614 8.98467L5.25364 10.4772C4.22314 11.5077 2.55289 11.5077 1.52239 10.4772C0.491887 9.44667 0.491887 7.77642 1.52239 6.74592L2.83339 5.47242"
      stroke="currentColor"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M5.25359 6.74614C4.22309 5.71564 4.22309 4.04539 5.25359 3.01489L6.74609 1.52239C7.77659 0.491887 9.44684 0.491887 10.4773 1.52239C11.5078 2.55289 11.5078 4.22314 10.4773 5.25364L9.16634 6.52714"
      stroke="currentColor"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </g>,
  displayName
);

Link.displayName = displayName;

export default Link;
