import history from "_history";
import Close from "@mui/icons-material/Close";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";
import TabList from "components/ui/TabList";
import Title from "components/ui/Title";
import getFirstValueFromURL from "helpers/getFirstValueFromURL";
import usePushNotification from "hooks/usePushNotification";
import useWidth from "hooks/useWidth";
import { makeStyles } from "makeStyles";
import { useEffect, useRef, useState } from "react";
import { defineMessages, FormattedMessage } from "react-intl";
import { useLocation } from "react-router-dom";
import {
  crmFileProviderLabelAndLogos,
  crmProviders,
} from "screens/Frontoffice/screens/Sources/constants";
import {
  DataProviderType,
  isCrmFileType,
  isCrmProviderType,
} from "screens/Frontoffice/shared/helpers/types";

import { generateNotification } from "../helpers/utils";
import { AssignmentWidgetTab } from "./AssignmentWidgetTab";
import CrmFieldSettingsWidget from "./CrmFieldSettingsWidget";
import CsvColumnMappingWidget, {
  CsvColumnMappingWidgetGhost,
} from "./CsvColumnMappingWidget";
import OpenOpportunitiesWidget from "./OpenOpportunitiesWidget";
import OpportunitiesTrackingWidget from "./OpportunitiesTrackingWidget";
import PrivateAccountsWidget from "./PrivateAccountsWidget";
import StatusRulesWidget from "./StatusRulesWidget";
import StrategicAccountsWidget from "./StrategicAccountsWidget";

type Props = {
  integrationId: number;
  sourceType: DataProviderType;
  configs?: $TSFixMe;
  integrationCount: number;
  hasPartnerships?: boolean;
  isGhost?: boolean;
  refresh?: () => void;
};

const SourceSettingsDialog = ({
  integrationId,
  sourceType,
  configs = defaultConfigs,
  integrationCount,
  hasPartnerships,
  isGhost,
  refresh,
}: Props) => {
  const pushNotification = usePushNotification();
  const crmProvider = isCrmProviderType(sourceType)
    ? crmProviders[sourceType]
    : crmFileProviderLabelAndLogos[sourceType];
  const location = useLocation();
  const scrollerRef = useRef<HTMLDivElement>(null);
  const [currentTab, setCurrentTab] = useState(
    (isGhost && "#column-mapping-private") ||
      location.hash ||
      (isCrmFileType(sourceType) ? "#column-mapping" : "#status-rules")
  );
  const isUpdated = getFirstValueFromURL(location, "updated") === "true";
  const isNew = getFirstValueFromURL(location, "init") === "true";
  const width = useWidth();

  const { classes } = useStyles({ isNew });
  const { classes: titleClasses } = useTitleStyles();
  const { classes: dialogClasses } = useDialogStyles();
  const dialogProps = {
    maxWidth: "lg" as const,
    fullWidth: true,
    fullScreen: width === "sm" || width === "xs" || false,
  };
  const config = configs[currentTab];

  const handleClose = () => {
    if (isNew) {
      refresh?.();
    }
    if (isNew || isUpdated) {
      setTimeout(() => {
        // to avoid the click action self close incoming notification
        pushNotification(generateNotification(sourceType, isNew), {
          provider: crmProvider.name,
        });
      }, 0);
    }
    if (isGhost) {
      history.replace({ ...location, search: "", hash: "" });
    } else {
      history.replace({
        pathname: "/sources",
        hash: "",
      });
    }
  };

  const noFieldsCallback = () => {
    if (isGhost) {
      handleClose();
      pushNotification(i18n.fieldsNotReady);
    } else {
      history.replace({
        pathname: "/sources",
        search: `?provider=${sourceType}`,
        hash: "#fields-not-ready",
      });
    }
  };

  const callback = () => {
    let callbackHash = config.callbackHash;
    if (isGhost) {
      if (currentTab === "#status-rules") {
        callbackHash = null;
      }
    } else if (
      integrationCount > 1 &&
      hasPartnerships &&
      currentTab === "#strategic-accounts"
    ) {
      callbackHash = "#assignment";
    }
    if (callbackHash) {
      return setCurrentTab(callbackHash);
    }
    return handleClose();
  };

  const availableTabs = isGhost
    ? ghostTabs
    : isCrmFileType(sourceType)
    ? fileTabs
    : sourceType === "stripe"
    ? stripeTabs
    : crmTabs;

  // Adding Assignment tab if there are partnerships availables
  if (hasPartnerships && !availableTabs.includes(assignmentTab)) {
    availableTabs.splice(2, 0, assignmentTab);
  }

  // Adding Tracking tab only for Salesforce source
  if (
    sourceType === "salesforce" &&
    !availableTabs.includes(opportunitiesTrackingTab)
  ) {
    availableTabs.splice(
      availableTabs.findIndex((tab) => tab === customFieldsTab),
      0,
      opportunitiesTrackingTab
    );
  } else if (
    sourceType !== "salesforce" &&
    availableTabs.includes(opportunitiesTrackingTab)
  ) {
    availableTabs.splice(
      availableTabs.findIndex((tab) => tab === opportunitiesTrackingTab),
      1
    );
  }

  useEffect(() => {
    if (!isGhost) {
      if (location.hash !== currentTab) {
        history.replace({
          pathname: location.pathname,
          search: location.search,
          hash: currentTab,
        });
      }
    }
  }, [currentTab]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Dialog
      open
      {...dialogProps}
      classes={dialogClasses}
      onClose={handleClose}
      disableEnforceFocus
    >
      <DialogTitle className={classes.title}>
        <div className={classes.headerContainer}>
          <Title
            section={<FormattedMessage {...i18n.title} />}
            label={isNew ? config.label : ""}
            avatar={crmProvider.logo}
            variant="h1"
            classes={titleClasses}
          />
          <IconButton
            className={classes.iconBtn}
            onClick={handleClose}
            size="large"
          >
            <Close className={classes.closeIcon} />
          </IconButton>
          {!isNew && (
            <TabList
              tabs={availableTabs}
              selectedValue={currentTab}
              setValue={setCurrentTab}
            />
          )}
        </div>
      </DialogTitle>
      <>
        <DialogContent ref={scrollerRef}>
          <div className={classes.contentContainer}>
            <config.Component
              integrationId={integrationId}
              recordId={integrationId}
              handleClose={handleClose}
              scrollerRef={scrollerRef}
              provider={crmProvider.name}
              sourceType={sourceType}
              defaultAll={integrationCount === 1 && isNew}
              callback={isNew ? callback : null}
              noFieldsCallback={noFieldsCallback}
              isGhost={isGhost}
              refresh={refresh}
            />
          </div>
        </DialogContent>
      </>
    </Dialog>
  );
};

export default SourceSettingsDialog;

/// CSS

const useDialogStyles = makeStyles()((theme) => ({
  paper: {
    backgroundColor: theme.palette.ivory,
    minHeight: "70%",
    [theme.breakpoints.down("md")]: {
      height: "100%",
    },
  },
}));

const useTitleStyles = makeStyles()(() => ({
  avatar: {
    width: 60,
    height: 60,
  },
  section: {
    fontWeight: 600,
  },
}));

const useStyles = makeStyles<{ isNew: boolean }>()((theme, { isNew }) => ({
  title: {
    display: "flex",
    justifyContent: "space-between",
    padding: theme.spacing(3),
    paddingBottom: theme.spacing(1),
  },
  headerContainer: {
    width: "100%",
  },
  contentContainer: {
    marginTop: theme.spacing(isNew ? 0 : 4),
  },
  iconBtn: {
    marginRight: theme.spacing(2),
    flexDirection: "column",
    width: 40,
    height: 40,
    position: "absolute",
    top: 24,
    right: 0,
    "&:focus": {
      outline: 0,
    },
  },
  closeIcon: {
    color: theme.palette.midnight,
    fontSize: 32,
  },
}));

/// I18N

const i18n = defineMessages({
  addedCrmDescription: {
    id: "crm.PartnershipSettings.SourceSettingsDialog.addedCrmDescription",
    defaultMessage: "Accounts are currently processing",
  },
  addedFileMessage: {
    id: "crm.PartnershipSettings.SourceSettingsDialog.addedFileMessage",
    defaultMessage:
      "CSV file have been successfully added to your data sources",
  },
  updatedFileMessage: {
    id: "crm.PartnershipSettings.SourceSettingsDialog.updatedFileMessage",
    defaultMessage:
      "CSV file have been successfully updated in your data sources",
  },
  addedGsheetDescription: {
    id: "crm.PartnershipSettings.SourceSettingsDialog.addedGsheetDescription",
    defaultMessage: "This sheet is currently processing",
  },
  addedCrmMessage: {
    id: "crm.PartnershipSettings.SourceSettingsDialog.addedCrmMessage",
    defaultMessage:
      "{provider} has been successfully added to your data sources",
  },
  updatedCrmMessage: {
    id: "crm.PartnershipSettings.SourceSettingsDialog.updatedCrmMessage",
    defaultMessage:
      "{provider} has been successfully updated in your data sources",
  },
  title: {
    id: "crm.PartnershipSettings.SourceSettingsDialog.title",
    defaultMessage: "Data source settings",
  },
  csvFile: {
    id: "crm.PartnershipSettings.SourceSettingsDialog.csvFile",
    defaultMessage: "CSV file",
  },
  csvColumnMapping: {
    id: "crm.PartnershipSettings.SourceSettingsDialog.csvColumnMapping",
    defaultMessage: "Column mapping",
  },
  statusRules: {
    id: "crm.PartnershipSettings.SourceSettingsDialog.statusRules",
    defaultMessage: "Status rules",
  },
  strategicAccounts: {
    id: "crm.PartnershipSettings.SourceSettingsDialog.strategicAccounts",
    defaultMessage: "Strategic accounts",
  },
  assignment: {
    id: "crm.PartnershipSettings.SourceSettingsDialog.assignment",
    defaultMessage: "Assignment",
  },
  privateAccounts: {
    id: "crm.PartnershipSettings.SourceSettingsDialog.privateAccounts",
    defaultMessage: "Private accounts",
  },
  customFields: {
    id: "crm.PartnershipSettings.SourceSettingsDialog.customFields",
    defaultMessage: "Custom fields",
  },
  openOpportunities: {
    id: "crm.PartnershipSettings.SourceSettingsDialog.openOpportunities",
    defaultMessage: "Open opportunities",
  },
  opportunitiesTracking: {
    id: "crm.settings.AssignmentWidget.opportunitiesTracking",
    defaultMessage: "Tracking",
  },
  fieldsNotReady: {
    id: "crm.settings.AssignmentWidget.fieldsNotReady",
    defaultMessage:
      "It's taking some time to import your fields. Please refresh this page in a few minutes.",
  },
});

export const defaultConfigs = {
  "#column-mapping": {
    Component: CsvColumnMappingWidget,
    label: <FormattedMessage {...i18n.csvColumnMapping} />,
    callbackHash: "#status-rules",
  },
  "#column-mapping-private": {
    Component: CsvColumnMappingWidgetGhost,
    label: <FormattedMessage {...i18n.csvColumnMapping} />,
    callbackHash: "#status-rules",
  },
  "#status-rules": {
    Component: StatusRulesWidget,
    label: <FormattedMessage {...i18n.statusRules} />,
    callbackHash: "#strategic-accounts",
  },
  "#open-opportunities": {
    Component: OpenOpportunitiesWidget,
    label: <FormattedMessage {...i18n.openOpportunities} />,
    callbackHash: "#strategic-accounts",
  },
  "#strategic-accounts": {
    Component: StrategicAccountsWidget,
    label: <FormattedMessage {...i18n.strategicAccounts} />,
    callbackHash: "#private-accounts",
  },
  "#assignment": {
    Component: AssignmentWidgetTab,
    label: <FormattedMessage {...i18n.assignment} />,
    callbackHash: "#private-accounts",
  },
  "#private-accounts": {
    Component: PrivateAccountsWidget,
    label: <FormattedMessage {...i18n.privateAccounts} />,
    callbackHash: "#custom-fields",
  },
  "#tracking": {
    Component: OpportunitiesTrackingWidget,
    label: <FormattedMessage {...i18n.opportunitiesTracking} />,
    callbackHash: "#custom-fields",
  },
  "#custom-fields": {
    Component: CrmFieldSettingsWidget,
    label: <FormattedMessage {...i18n.customFields} />,
    callbackHash: null,
  },
};

const statusRulesTab = {
  value: "#status-rules",
  label: <FormattedMessage {...i18n.statusRules} />,
};

const strategicAccountsTab = {
  value: "#strategic-accounts",
  label: <FormattedMessage {...i18n.strategicAccounts} />,
};

const privateAccountsTab = {
  value: "#private-accounts",
  label: <FormattedMessage {...i18n.privateAccounts} />,
};

const customFieldsTab = {
  value: "#custom-fields",
  label: <FormattedMessage {...i18n.customFields} />,
};

const columnMappingTab = {
  value: "#column-mapping",
  label: <FormattedMessage {...i18n.csvColumnMapping} />,
};

const csvColumnMappingPrivateTab = {
  value: "#column-mapping-private",
  label: <FormattedMessage {...i18n.csvColumnMapping} />,
};

const assignmentTab = {
  value: "#assignment",
  label: <FormattedMessage {...i18n.assignment} />,
};

const openOpportunitiesTab = {
  value: "#open-opportunities",
  label: <FormattedMessage {...i18n.openOpportunities} />,
};

const opportunitiesTrackingTab = {
  value: "#tracking",
  label: <FormattedMessage {...i18n.opportunitiesTracking} />,
};

const baseTabs = [
  statusRulesTab,
  strategicAccountsTab,
  privateAccountsTab,
  customFieldsTab,
];

const crmTabs = [statusRulesTab, openOpportunitiesTab, ...baseTabs.slice(1)];
const stripeTabs = baseTabs;
const fileTabs = [columnMappingTab, ...baseTabs];
const ghostTabs = [csvColumnMappingPrivateTab, statusRulesTab];
