import { createSvgIcon } from "@mui/material/utils";

import { SvgIcon } from "./types";

const displayName = "Favorite";

const Favorite: SvgIcon = createSvgIcon(
  // Using transform to enlarge from provided viewbox="0 0 16 16"
  // to default "0 0 24 24" from `createSvgIcon` -> `SvgIcon`.
  <g fill="none" transform="matrix(1.5,0,0,1.5,0,0)">
    <path
      d="M8.60195 4.37308C8.60711 4.36598 8.6103 4.36194 8.61167 4.36024L8.61198 4.35992C8.87711 4.08777 9.19405 3.87147 9.54411 3.72378C9.89418 3.57609 10.2703 3.5 10.6502 3.5C11.0302 3.5 11.4062 3.57609 11.7563 3.72378C12.1053 3.871 12.4213 4.08639 12.6859 4.35733C13.2087 4.91124 13.5 5.64413 13.5 6.40589C13.5 7.16423 13.2113 7.89394 12.693 8.44696L8.02869 12.836L3.3569 8.44267C3.08617 8.17815 2.87092 7.86226 2.72378 7.51349C2.57609 7.16343 2.5 6.78734 2.5 6.40739C2.5 6.02745 2.57609 5.65136 2.72378 5.30129C2.87147 4.95123 3.08777 4.63429 3.35992 4.36916L3.36004 4.36929L3.36916 4.35992C3.63429 4.08777 3.95123 3.87147 4.30129 3.72378C4.65136 3.57609 5.02745 3.5 5.40739 3.5C5.78734 3.5 6.16343 3.57609 6.51349 3.72378C6.86297 3.87122 7.17944 4.08705 7.4443 4.35856C7.44559 4.36017 7.44908 4.36456 7.45498 4.37272C7.46698 4.38929 7.4801 4.4089 7.50026 4.43914L7.50138 4.44083C7.51907 4.46736 7.54324 4.50361 7.56886 4.53867C7.59436 4.57357 7.63127 4.62124 7.67763 4.66698L8.03117 5.01581L8.38236 4.66462C8.42782 4.61916 8.46417 4.5719 8.48943 4.53717C8.51427 4.50301 8.53789 4.46758 8.55529 4.44148L8.55735 4.43839C8.57729 4.40848 8.59028 4.38913 8.60195 4.37308Z"
      stroke="currentColor"
    />
  </g>,
  displayName
);

Favorite.displayName = displayName;
export default Favorite;
