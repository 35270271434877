import { createSvgIcon } from "@mui/material/utils";

import { SvgIcon } from "./types";

const displayName = "QuickFilterInverted";

const QuickFilterInverted: SvgIcon = createSvgIcon(
  <g fill="none" transform="matrix(1.5,0,0,1.5,0,0)">
    <rect width="16" height="16" rx="8" fill="#0A151B" />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M4.07311 4.34766C4.01548 4.34766 3.96875 4.39438 3.96875 4.45202C3.96875 5.06178 4.27349 5.6312 4.78085 5.96944L6.85905 7.35491C7.30243 7.65049 7.56875 8.14811 7.56875 8.68099V12.003L8.30601 11.5115C8.38425 11.4594 8.43125 11.3716 8.43125 11.2775V8.68099C8.43125 8.14811 8.69757 7.65049 9.14095 7.35491L11.2192 5.96944C11.7265 5.6312 12.0312 5.06178 12.0312 4.45202C12.0312 4.39438 11.9845 4.34766 11.9269 4.34766H4.07311ZM12.9688 4.45202C12.9688 3.87661 12.5023 3.41016 11.9269 3.41016H4.07311C3.49771 3.41016 3.03125 3.87661 3.03125 4.45202C3.03125 5.37524 3.49265 6.23737 4.26082 6.74948L6.33902 8.13495C6.52159 8.25667 6.63125 8.46157 6.63125 8.68099V12.5286C6.63125 13.0527 7.21541 13.3653 7.65152 13.0746L8.82604 12.2916C9.1651 12.0655 9.36875 11.685 9.36875 11.2775V8.68099C9.36875 8.46157 9.47841 8.25667 9.66098 8.13495L11.7392 6.74949C12.5073 6.23738 12.9688 5.37524 12.9688 4.45202Z"
      fill="#FAFAF6"
    />
  </g>,
  displayName
);
QuickFilterInverted.displayName = displayName;
export default QuickFilterInverted;
