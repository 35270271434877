import { NotificationStatus } from "components/ui/Notifications/NotificationSnackbar";
import { crmProviders, ProviderType } from "config/constants";
import getFirstValueFromURL from "helpers/getFirstValueFromURL";
import { Location } from "history";
import useStorage from "hooks/useStorage";
import useUserProfile from "hooks/useUserProfile";
import { stringify } from "query-string";
import { useCallback, useMemo } from "react";
import { defineMessages } from "react-intl";
import { useHistory, useLocation } from "react-router-dom";

import usePushNotification from "./usePushNotification";

const redirectUrl = (crm: string, reverse: boolean = false) => {
  const protocol = window.location.protocol;
  const host = window.location.host;
  if (reverse) {
    return `${protocol}//${host}/${crm}/oauth`;
  }
  return `${protocol}//${host}/oauth/${crm}`;
};

const getSalesforceOauthURL = (state: string) => {
  const sandbox = localStorage.sfSandbox === "1";
  const salesforceProvider = crmProviders[ProviderType.salesforce];
  const params = {
    client_id: salesforceProvider.clientId,
    redirect_uri: redirectUrl("salesforce"),
    response_type: "code",
    state,
  };
  const url = sandbox
    ? salesforceProvider.oauthSandbox
    : salesforceProvider.oauth;
  return url + stringify(params);
};

const getGsheetOauthURL = (state: string) => {
  const gsheetProvider = crmProviders[ProviderType.gsheet];
  const params = {
    scope: [
      "https://www.googleapis.com/auth/spreadsheets.readonly",
      "https://www.googleapis.com/auth/userinfo.email",
      "https://www.googleapis.com/auth/userinfo.profile",
    ].join(" "),
    client_id: gsheetProvider.clientId,
    redirect_uri: redirectUrl("gsheet"),
    response_type: "code",
    state,
  };
  return gsheetProvider.oauth + stringify(params);
};

const getHubspotOauthURL = (state: string) => {
  const hubspotProvider = crmProviders[ProviderType.hubspot];
  const params = {
    scope: [
      "crm.objects.companies.read",
      "crm.objects.companies.write",
      "crm.objects.contacts.read",
      "crm.objects.deals.read",
      "crm.objects.owners.read",
      "crm.schemas.companies.read",
      "crm.schemas.companies.write",
      "crm.schemas.contacts.read",
      "crm.schemas.deals.read",
      "oauth",
    ].join(" "),
    client_id: hubspotProvider.clientId,
    redirect_uri: redirectUrl("hubspot"),
    state,
  };
  return hubspotProvider.oauth + stringify(params);
};

const getMSDynamicsOauthURL = (state: string, scope: string) => {
  const msDynamicsProvider = crmProviders[ProviderType.msDynamics];
  const params = {
    scope: scope,
    client_id: msDynamicsProvider.clientId,
    redirect_uri: redirectUrl("ms_dynamics"),
    response_type: "code",
    state,
  };
  return msDynamicsProvider.oauth + stringify(params);
};

const getPipedriveOauthURL = (state: string) => {
  const pipedriveProvider = crmProviders[ProviderType.pipedrive];
  const params = {
    client_id: pipedriveProvider.clientId,
    redirect_uri: redirectUrl("pipedrive"),
    state,
  };
  return pipedriveProvider.oauth + stringify(params);
};

const getZohoOauthURL = (state: string) => {
  const zohoProvider = crmProviders[ProviderType.zoho];
  const params = {
    scope: zohoProvider.scope,
    client_id: zohoProvider.clientId,
    redirect_uri: redirectUrl("zoho"),
    response_type: "code",
    access_type: "offline",
    prompt: "consent",
    state,
  };
  return zohoProvider.oauth + stringify(params);
};

const getSlackOauthURL = (state: string) => {
  const slackProvider = crmProviders[ProviderType.slack];
  const params = {
    scope: slackProvider.scope,
    client_id: slackProvider.clientId,
    redirect_uri: redirectUrl("slack", true),
    state,
  };
  return slackProvider.oauth + stringify(params);
};

const getPartnerStackOauthURL = (state: string) => {
  const partnerStackProvider = crmProviders[ProviderType.partnerStack];
  const params = {
    scope: partnerStackProvider.scope,
    client_id: partnerStackProvider.clientId,
    redirect_uri: redirectUrl("partnerstack", true),
    state,
  };
  return partnerStackProvider.oauth + stringify(params);
};

export const getProviderOauthURL = (
  provider: string,
  hashedState: string,
  scope: string = ""
) => {
  switch (provider) {
    case ProviderType.gsheet:
      return getGsheetOauthURL(hashedState);
    case ProviderType.hubspot:
      return getHubspotOauthURL(hashedState);
    case ProviderType.msDynamics:
      if (!Boolean(scope)) {
        throw new Error(`Missing scope for CRM ${provider}`);
      }
      return getMSDynamicsOauthURL(hashedState, scope);
    case ProviderType.pipedrive:
      return getPipedriveOauthURL(hashedState);
    case ProviderType.salesforce:
      return getSalesforceOauthURL(hashedState);
    case ProviderType.zoho:
      return getZohoOauthURL(hashedState);
    case ProviderType.slack:
      return getSlackOauthURL(hashedState);
    case ProviderType.partnerStack:
      return getPartnerStackOauthURL(hashedState);
    default:
      throw new Error(`Unknown CRM ${provider}`);
  }
};

const dec2hex = (dec: $TSFixMe) => {
  return dec < 10 ? "0" + String(dec) : dec.toString(16);
};

const generateRandom = () => {
  var arr = new Uint8Array(24);
  crypto.getRandomValues(arr);
  return Array.from(arr, dec2hex).join("");
};

const hashState = async (userId: number, state: State) => {
  const base = `${userId}.${state.timestamp}.${state.random}.${JSON.stringify(
    state.next
  )}`;
  const encoder = new TextEncoder();
  const hashBuffer = await crypto.subtle.digest(
    "SHA-256",
    encoder.encode(base)
  );
  return Array.from(new Uint8Array(hashBuffer), dec2hex).join("");
};

export type State = {
  next?: Location | string;
  timestamp?: number;
  nonce?: string;
  random?: string;
};

const useOauthState = () => {
  const { profile } = useUserProfile();
  const pushNotification = usePushNotification();
  const { value: state, updateStorage: setState } = useStorage<State>("oauth", {
    default: {},
  });
  const locationState = useLocation();
  const history = useHistory();

  const start = useCallback(
    async (provider: string, next?: string | Location, scope?: string) => {
      const newState = {
        nonce: generateRandom(),
        next:
          next ?? getFirstValueFromURL(locationState, "next") ?? locationState,
        timestamp: Date.now(),
      };
      setState(newState);
      const hashedState = await hashState(profile.id, newState);
      window.location.replace(
        getProviderOauthURL(provider, hashedState, scope)
      );
    },
    [locationState, profile.id, setState]
  );

  const lock = useCallback((value: string) => {
    if (sessionStorage.oauthLock === value) {
      return false;
    }
    sessionStorage.oauthLock = value;
    return true;
  }, []);

  const isValid = useCallback(
    async (value: string) => {
      const currentStateHash = await hashState(profile.id, state);
      return lock(value) && value === currentStateHash;
    },
    [state, lock, profile.id]
  );

  const gotToNext = useCallback(
    (addSuccessNotification: boolean = false) => {
      const next = state.next;
      setState({});
      if (
        next &&
        (typeof next === "string" ||
          getFirstValueFromURL(next, "onboarding") === "true")
      ) {
        history.push(next);
      } else {
        history.push("/");
      }
      if (addSuccessNotification) {
        pushNotification(
          {
            status: NotificationStatus.success,
            message: i18n.successfully,
          },
          undefined,
          undefined
        );
      }
    },
    [history, state, setState] // eslint-disable-line react-hooks/exhaustive-deps
  );

  return useMemo(() => ({ start, isValid, lock, next: gotToNext }), [
    start,
    isValid,
    lock,
    gotToNext,
  ]);
};

export default useOauthState;

// I18N

const i18n = defineMessages({
  successfully: {
    id: "hooks.useOauthState.successfully",
    defaultMessage:
      "This integration has been successfully connected to Reveal",
  },
});

export const _private = {
  i18n,
};
