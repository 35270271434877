import { createAction } from "@reduxjs/toolkit";

import { PremiumPlanWidgetActions } from "./types";

export const initWidget = createAction(PremiumPlanWidgetActions.initWidget);

export const openWidget = createAction<boolean>(
  PremiumPlanWidgetActions.openWidget
);

export const toggleFree = createAction<boolean>(
  PremiumPlanWidgetActions.toggleFree
);

export const togglePro = createAction<boolean>(
  PremiumPlanWidgetActions.togglePro
);

export const togglePower = createAction<boolean>(
  PremiumPlanWidgetActions.togglePower
);
