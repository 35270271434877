import { Plus } from "components/icons";
import Button from "components/ui/Button";
import DraggableContainer from "components/ui/DraggableContainer";
import DraggableItem from "components/ui/DraggableItem";
import FiltersPopover from "components/ui/filters/FiltersPopover";
import { getAvailableColumOptions } from "components/ui/filters/utils";
import { T } from "components/ui/Typography";
import muiTheme from "config/theme";
import useSegment from "hooks/useSegment";
import _ from "lodash";
import { makeStyles } from "makeStyles";
import { ReactElement, useEffect, useState } from "react";
import { defineMessages, FormattedMessage, useIntl } from "react-intl";
import {
  DataTableType,
  MatchSort,
  SortType,
} from "screens/Frontoffice/screens/DataTables/shared/types";
import { TableEvent } from "tracking";

import SortItem from "./SortItem";

const i18n = defineMessages({
  sortBy: {
    id: "crm.AccountMapping.Filter.SortWidget.sortBy",
    defaultMessage: "Sort by",
  },
  thenBy: {
    id: "crm.AccountMapping.Filter.SortWidget.thenBy",
    defaultMessage: "then by",
  },
  addBtn: {
    id: "crm.AccountMapping.Filter.SortWidget.addBtn",
    defaultMessage: "Add sorting rule",
  },
  emptyText: {
    id: "crm.AccountMapping.Filter.SortWidget.emptyText",
    defaultMessage: "No sorts applied to this view",
  },
});

const useStyles = makeStyles()((theme) => ({
  button: {
    marginTop: 14,
  },
  emptyTitle: {
    padding: theme.spacing(1),
  },
  dragIconMargin: {
    marginLeft: 20,
  },
}));

const usePopoverStyles = makeStyles()((theme) => ({
  childrenContainer: {
    padding: 12,
  },
}));

type Props = {
  partnerName?: ReactElement | string;
  openedWidget?: string | null;
  setOpenedWidget: (filterType: string | null) => void;
  sort: SortType[];
  onSortUpdate: $TSFixMeFunction;
  fields: $TSFixMe;
  viewType: DataTableType;
};

const SortWidget = ({
  partnerName,
  fields,
  sort,
  onSortUpdate,
  openedWidget,
  setOpenedWidget,
  viewType,
}: Props) => {
  const { classes, cx } = useStyles();
  const { classes: popoverClasses } = usePopoverStyles();
  const { track } = useSegment();
  const intl = useIntl();
  const [addingFilter, setAddingFilter] = useState(false);
  const getColumOptions = (selectedValue = null) => {
    const options = getAvailableColumOptions(
      partnerName,
      fields,
      sort,
      selectedValue,
      "sort"
    );
    return options;
  };

  const handleOpenWidget = (filterType: string | null) => {
    if (filterType) {
      track(TableEvent.openSortWidget, { location: viewType });
    }
    setOpenedWidget(filterType);
  };

  const addFilter = (value: MatchSort) => {
    onSortUpdate([...sort, value]);
    setAddingFilter(false);
    track(TableEvent.sortApplied, {
      location: viewType,
      fieldName: value.fieldname,
      order: value.order,
    });
  };

  const updateFilter = (value: MatchSort, index: number) => {
    const newPresets = [...sort];
    newPresets[index] = value;
    onSortUpdate(newPresets);
    track(TableEvent.sortApplied, {
      location: viewType,
      fieldName: value.fieldname,
      order: value.order,
    });
  };

  const deleteFilter = (index: number) => {
    const newSort = [...sort];
    newSort.splice(index, 1);
    onSortUpdate(newSort);
    track(TableEvent.sortRemoved, {
      location: viewType,
      fieldName: sort?.[index]?.fieldname,
      order: sort?.[index]?.order,
    });
  };

  const reorder = (startIndex: $TSFixMe, endIndex: $TSFixMe) => {
    const newSort = [...sort];
    const [removed] = newSort.splice(startIndex, 1);
    newSort.splice(endIndex, 0, removed);
    onSortUpdate(newSort);
  };

  useEffect(() => {
    if (!_.isEmpty(fields) && sort.length > 0) {
      const newSort: $TSFixMe = [];
      _.each(sort, (item: $TSFixMe) => {
        if (item.fieldname in fields) {
          newSort.push(item);
        }
      });
      if (!_.isEqual(sort, newSort)) {
        onSortUpdate(newSort);
      }
    }
  }, [fields]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <FiltersPopover
      filterType={"sort"}
      openedWidget={openedWidget}
      setOpenedWidget={handleOpenWidget}
      selectedItemsCount={sort.length}
      classes={popoverClasses}
      placement="right"
    >
      {sort.length === 0 && !addingFilter && (
        <T
          // @ts-expect-error
          color={muiTheme.palette.grey.darkBlue}
          className={classes.emptyTitle}
        >
          <FormattedMessage {...i18n.emptyText} />
        </T>
      )}
      <DraggableContainer handleDragEnd={reorder}>
        {sort.map((item: $TSFixMe, index: $TSFixMe) => (
          // @ts-expect-error ts-migrate(2786) FIXME: 'DraggableItem' cannot be used as a JSX component.
          <DraggableItem
            key={item.fieldname}
            id={item.fieldname}
            index={index}
            disabled={sort.length === 1}
          >
            <SortItem
              label={intl.formatMessage(
                index === 0 ? i18n.sortBy : i18n.thenBy
              )}
              options={getColumOptions(item)}
              index={index}
              sort={item}
              fields={fields}
              handleChange={updateFilter}
              handleDelete={() => deleteFilter(index)}
            />
          </DraggableItem>
        ))}
      </DraggableContainer>
      {addingFilter && (
        <div className={cx({ [classes.dragIconMargin]: sort.length > 1 })}>
          <SortItem
            label={intl.formatMessage(
              sort.length === 0 ? i18n.sortBy : i18n.thenBy
            )}
            options={getColumOptions(null)}
            fields={fields}
            handleChange={addFilter}
            handleDelete={() => setAddingFilter(false)}
          />
        </div>
      )}
      {!addingFilter && (
        <div className={classes.button}>
          <Button
            disabled={addingFilter}
            onClick={() => setAddingFilter(true)}
            LeftIcon={Plus}
            label={i18n.addBtn}
            size="small"
          />
        </div>
      )}
    </FiltersPopover>
  );
};

export default SortWidget;
