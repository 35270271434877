import { isFulfilled } from "@reduxjs/toolkit";
import { AxiosResponse } from "axios";
import usePushNotification from "hooks/usePushNotification";
import { useCallback, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { rawGet } from "redux/api/thunks";

interface RawField {
  default_value: string;
  internal_name: string;
  name: string;
  placeholder_text: string;
  required: boolean;
  hidden: boolean;
  type: string;
  options: {
    [key: string]: string;
  };
}

export interface PartnerStackField {
  name: string;
  label: string;
  placeholder: string;
  defaultValue: string;
  type: string;
  options: {
    value: string;
    label: string;
  }[];
}

const useRequiredFields = (partnerStackPartnershipId: number) => {
  const dispatch = useDispatch();
  const pushNotification = usePushNotification();
  const [loading, setLoading] = useState(false);
  const [fields, setFields] = useState<PartnerStackField[]>([]);

  const collectFields = useCallback(async () => {
    setLoading(true);
    const result = await dispatch(
      rawGet({
        type: "partnerstack",
        path: `partnerships/${partnerStackPartnershipId}/leads/form-templates/`,
        options: {
          config: {
            params: {
              request_type: "partner",
            },
          },
        },
      })
    );
    if (isFulfilled(result)) {
      const response = result.payload as AxiosResponse;
      const fields: RawField[] = response.data.data.items[0]?.fields ?? [];
      const companyFieldIndex = fields
        .map((rawField: RawField) => rawField.internal_name)
        .indexOf("company");
      const companyField: RawField[] =
        companyFieldIndex !== -1 ? fields.splice(companyFieldIndex, 1) : []; // Remove company field
      const mappedFields: PartnerStackField[] = [...companyField, ...fields] // Add it in first place
        .filter((field: RawField) => {
          return field.required;
        })
        .map((field: RawField) => {
          return {
            name: field.internal_name,
            label: field.name,
            placeholder: field.placeholder_text,
            defaultValue: field.default_value,
            type: field.type,
            options: Object.keys(field.options).map((key) => ({
              value: field.options[key], // Replace number key for label
              label: field.options[key],
            })),
          } as PartnerStackField;
        });
      setFields(mappedFields);
    } else {
      pushNotification("default_error");
    }
    setLoading(false);
  }, [partnerStackPartnershipId, dispatch]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    collectFields();
  }, [collectFields, partnerStackPartnershipId]);

  return {
    loading,
    fields,
  };
};

export default useRequiredFields;
