import Box from "@mui/material/Box";
import useUserProfile from "hooks/useUserProfile";
import { makeStyles } from "makeStyles";
import { AvailableNearboundAccountsColumns } from "redux/mapping360NearboundAccounts/defaults";

import { CellProps } from "../../../utils";
import { Mapping360NearboundProspectsBookmark } from "./Mapping360NearboundProspectsBookmark";

export const Mapping360NearboundProspectsActionCell = ({
  row,
  activeSlack,
}: CellProps) => {
  const { profile } = useUserProfile();
  const { classes } = useStyles();

  return (
    <Box
      className={classes.container}
      id="noTriggerCell"
      data-testid="noTriggerCell"
    >
      {profile.ownsPipeline && (
        <Mapping360NearboundProspectsBookmark row={row} />
      )}
    </Box>
  );
};

export const isMapping360NearboundProspectsActionCell = ({
  fieldName,
}: CellProps) => {
  return (
    fieldName === AvailableNearboundAccountsColumns.NearboundProspectsActions
  );
};

// CSS

const useStyles = makeStyles()((theme) => ({
  container: {
    display: "flex",
    alignItems: "center",
    columnGap: 4,
  },
}));
