import { Close, LaunchRounded } from "@mui/icons-material";
import { Box, Divider, Grid } from "@mui/material";
import { ChevronDown, ChevronTop, Info, Plus } from "components/icons";
import Button from "components/ui/Button";
import Dropdown from "components/ui/Dropdown/components/Dropdown";
import { IDropdownOption } from "components/ui/Dropdown/components/types";
import DropdownSubmitFooter from "components/ui/DropdownSubmitFooter";
import PageLoader from "components/ui/PageLoader";
import { SharedOpportunityDisplay } from "components/ui/SharedOpportunityDisplay/SharedOpportunityDisplay";
import Tooltip from "components/ui/Tooltip";
import { T } from "components/ui/Typography";
import { getSharingStatus } from "helpers/sharedOpportunitiesUtils";
import useAmountFormatter from "hooks/useAmountFormatter";
import { useOpportunityDropdown } from "hooks/useOpportunityDropdown";
import { makeStyles } from "makeStyles";
import PartnerConnection, {
  ISharedOpportunity,
  IStatus,
  Status,
} from "models/PartnerConnection";
import { useMemo, useState } from "react";
import {
  defineMessages,
  FormattedHTMLMessage,
  FormattedMessage,
  useIntl,
} from "react-intl";
import { Link } from "react-router-dom";

export const Opportunities = ({
  partnerConnection,
}: {
  partnerConnection: PartnerConnection;
}) => {
  const { classes, cx } = useStyles();
  const intl = useIntl();
  const [ids, setIds] = useState<string[] | null>(
    partnerConnection.sharedOpportunities?.map((o) => String(o.id)) ?? null
  );
  const [loading, setLoading] = useState(false);

  const {
    allowEdit,
    anchorEl,
    availableOpports,
    handleClose,
    handleOpen,
    isLoadingAvailableOpports,
    linkOpportunity,
    unlinkOpportunity,
    unlinkedOpenOpportunityCount,
    updateAttributes,
    isPartnerCell,
  } = useOpportunityDropdown({ partnerConnection });

  const opportunityOptions = useMemo(() => {
    return [
      ...((availableOpports ?? []).map((o) => {
        return {
          id: String(o.id),
          isOpen: o.isOpen,
          amount: o.amountInDollars,
          name: o.name,
          date: o.closeDate?.toISOString().split("T")[0] ?? o.closeDate,
          isWon: o.isWon,
          stage: o.stage,
        };
      }) as IDropdownOption[]),
      ...(partnerConnection.sharedOpportunities ?? []).map(
        (o) =>
          ({
            id: String(o.id),
            isOpen: o.status === Status.Open,
            amount: o.amount,
            name: o.opportunity_name,
            date: o.close_date,
            isWon: o.status === Status.Won,
            stage: o.stage,
          } as IDropdownOption)
      ),
    ];
  }, [availableOpports, partnerConnection.sharedOpportunities]);

  const handleOpportunities = async () => {
    setLoading(true);

    handleClose();

    // the ones to be added
    const opportunities = availableOpports?.filter((o) =>
      ids?.includes(String(o.id))
    );
    if (opportunities && opportunities?.length > 0) {
      await Promise.all(
        opportunities.map((opportunity) =>
          linkOpportunity(String(opportunity.id))
        )
      );
    }

    // the ones to be removed
    const opportunitiesToRemove = partnerConnection.sharedOpportunities?.filter(
      (o) => !ids?.includes(String(o.id))
    );
    if (opportunitiesToRemove && opportunitiesToRemove.length > 0) {
      await Promise.all(
        opportunitiesToRemove.map((opportunity) =>
          unlinkOpportunity(String(opportunity.id))
        )
      );
    }
    await updateAttributes({
      added: opportunities?.map((o) => String(o.id)) ?? [],
      removed: opportunitiesToRemove?.map((o) => String(o.id)) ?? [],
    });
    setLoading(false);
  };

  const shouldDisplayButton =
    (partnerConnection.unlinkedOpportunityCount ?? 0) > 0 ||
    (partnerConnection.sharedOpportunities?.length ?? 0) > 0;
  const sharingStatus = getSharingStatus(partnerConnection.sharedOpportunities);

  return (
    <Box className={classes.container}>
      <Box display="flex" gap="4px" alignItems="center">
        <T h4 bold>
          <FormattedMessage {...i18n.attachedOpportunities} />
        </T>
        <Tooltip
          title={
            <FormattedMessage
              {...(isPartnerCell ? i18n.partnerTooltip : i18n.selfTooltip)}
              values={{
                a: (chunks: string) => (
                  <Link
                    className={classes.link}
                    target="_blank"
                    to={"/settings/collaborate"}
                  >
                    {chunks}
                  </Link>
                ),
              }}
            />
          }
        >
          <Info className={classes.tooltipIcon} />
        </Tooltip>
      </Box>
      {shouldDisplayButton && allowEdit && (
        <Button
          label={intl.formatMessage(i18n.chooseOpport, {
            count: unlinkedOpenOpportunityCount,
          })}
          variant={
            (partnerConnection.sharedOpportunities?.length ?? 0) > 0
              ? "tertiary"
              : "primary"
          }
          LeftIcon={Plus}
          onClick={handleOpen}
          size="xSmall"
          classes={{ btn: classes.btn }}
          disabled={loading}
          loading={loading}
        />
      )}
      {loading && <PageLoader />}
      {!loading && (
        <>
          {!!sharingStatus && !sharingStatus.isMinimalSharing && (
            <T className={cx(classes.alpha750, classes.italic)}>
              <FormattedHTMLMessage {...i18n.notSharing} />
            </T>
          )}
          {sharingStatus?.isMinimalSharing &&
            !sharingStatus.isNormalSharing && (
              <OpportunitiesMinimalSharingGroupDisplay
                opportunities={partnerConnection.sharedOpportunities ?? []}
              />
            )}
          {sharingStatus?.isNormalSharing &&
            partnerConnection.sharedOpportunities?.map((opportunity) => (
              <OpportunityWithDetails
                allowEdit={allowEdit}
                key={opportunity.id}
                opportunity={opportunity}
                unlinkOpportunity={unlinkOpportunity}
                updateAttributes={updateAttributes}
                setIds={setIds}
              />
            ))}
        </>
      )}
      {!!anchorEl && (
        <Dropdown
          isOpportunityItem
          isLoading={isLoadingAvailableOpports}
          isMultiselect
          options={opportunityOptions}
          anchorEl={anchorEl}
          onClose={handleClose}
          open={!!anchorEl}
          value={ids}
          withSearch={false}
          onChange={(ids) => setIds(ids)}
          CustomFooter={
            <DropdownSubmitFooter
              onApply={handleOpportunities}
              onCancel={handleClose}
              cancelLabel={i18n.close}
              applyLabel={i18n.confirm}
            />
          }
        />
      )}
    </Box>
  );
};

const OpportunityWithDetails = ({
  allowEdit,
  opportunity,
  unlinkOpportunity,
  updateAttributes,
  setIds,
}: {
  allowEdit?: boolean | null;
  opportunity: ISharedOpportunity;
  unlinkOpportunity: (opportunityId: string) => Promise<void>;
  updateAttributes: (args: {
    added: string[];
    removed: string[];
  }) => Promise<void>;
  setIds: React.Dispatch<React.SetStateAction<string[] | null>>;
}) => {
  const { classes } = useStyles();
  const [isExpanded, setIsExpanded] = useState(false);
  const intl = useIntl();
  const amountFormatter = useAmountFormatter(intl);
  const [isLoading, setIsLoading] = useState(false);

  const handleRedirect = (url: string) => {
    window.open(url, "_blank");
  };

  const handleRemove = async () => {
    setIsLoading(true);
    await unlinkOpportunity(String(opportunity.id));
    await updateAttributes({
      added: [],
      removed: [String(opportunity.id)],
    });
    setIds(
      (value) =>
        value?.filter((record) => Number(record) !== opportunity.id) ?? null
    );
    setIsLoading(false);
  };

  return (
    <Box className={classes.opportunityDetailsContainer}>
      {opportunity.next_step && (
        <Box className={classes.expandButton}>
          <Button
            LeftIcon={isExpanded ? ChevronTop : ChevronDown}
            onClick={() => setIsExpanded((prev) => !prev)}
            variant="tertiary"
            size="xxSmall"
          />
        </Box>
      )}
      {opportunity.opportunity_name && (
        <Box flexGrow={1} marginRight="36px">
          <T titleBig>{opportunity.opportunity_name}</T>
        </Box>
      )}
      <Box display="flex" alignItems="center" gap="8px">
        <SharedOpportunityDisplay
          opportunity={{
            status: opportunity.status,
            stage: opportunity.stage,
            id: 1,
          }}
          withStage
        />
        {opportunity.amount && (
          <T titleBig>{amountFormatter(opportunity.amount, "USD")}</T>
        )}
      </Box>
      {!!opportunity.owner?.full_name && (
        <Box display="flex" gap="2px" marginTop="6px" flexDirection="column">
          <T className={classes.alpha500}>
            <FormattedMessage {...i18n.owner} />
          </T>
          <T>{opportunity.owner.full_name}</T>
        </Box>
      )}
      {opportunity.close_date && (
        <Box display="flex" gap="2px" marginTop="6px" flexDirection="column">
          <T className={classes.alpha500}>
            <FormattedMessage {...i18n.closeDate} />
          </T>
          <T>
            {intl.formatDate(new Date(opportunity.close_date), {
              year: "numeric",
              month: "short",
              day: "numeric",
            })}
          </T>
        </Box>
      )}
      {isExpanded && opportunity.next_step && (
        <Box display="flex" gap="2px" marginTop="6px" flexDirection="column">
          <T className={classes.alpha500}>
            <FormattedMessage {...i18n.nextStep} />
          </T>
          <T>{opportunity.next_step}</T>
        </Box>
      )}
      {(opportunity.url || allowEdit) && (
        <Box display="flex" gap="4px" marginTop="6px">
          {opportunity.url && (
            <Button
              label={i18n.openInCrm}
              RightIcon={LaunchRounded}
              size="xSmall"
              variant="tertiary"
              onClick={() => handleRedirect(opportunity.url ?? "")}
              classes={{
                icon: classes.buttonIcon,
              }}
            />
          )}
          {allowEdit && (
            <Button
              label={i18n.remove}
              LeftIcon={Close}
              onClick={handleRemove}
              size="xSmall"
              variant="tertiary"
              classes={{
                icon: classes.buttonIcon,
              }}
              loading={isLoading}
              disabled={isLoading}
            />
          )}
        </Box>
      )}
    </Box>
  );
};

const OpportunitiesMinimalSharingGroupDisplay = ({
  opportunities,
}: {
  opportunities: ISharedOpportunity[];
}) => {
  const { classes } = useStyles();

  const wonOpportunitiesCount = opportunities.filter(
    (o) => o.status === Status.Won
  ).length;
  const lostOpportunitiesCount = opportunities.filter(
    (o) => o.status === Status.Lost
  ).length;
  const openOpportunitiesCount = opportunities.filter(
    (o) => o.status === Status.Open
  ).length;

  const rowContent = (count: number, status: IStatus) => {
    return count > 0 ? (
      <>
        <Grid item container alignItems="center" justifyContent="space-between">
          <Grid item>
            <SharedOpportunityDisplay
              opportunity={{
                status,
                id: 1,
              }}
            />
          </Grid>
          <Grid item>
            <T className={classes.alpha750}>
              <FormattedMessage
                {...i18n.opportunityCountLabel}
                values={{ count }}
              />
            </T>
          </Grid>
        </Grid>
        <Divider className={classes.divider} />
      </>
    ) : null;
  };

  return (
    <Grid container flexDirection="column" className={classes.dividerContainer}>
      {rowContent(openOpportunitiesCount, Status.Open)}
      {rowContent(wonOpportunitiesCount, Status.Won)}
      {rowContent(lostOpportunitiesCount, Status.Lost)}
    </Grid>
  );
};

// CSS

const useStyles = makeStyles()((theme) => ({
  alpha500: {
    color: theme.palette.alpha500,
  },
  alpha750: {
    color: theme.palette.alpha750,
  },
  buttonIcon: {
    height: 12,
    width: 12,
  },
  btn: {
    width: "100%",
    margin: "8px 0",
  },
  container: {
    display: "flex",
    flexDirection: "column",
    gap: "8px",
    marginTop: theme.spacing(3),
  },
  divider: {
    margin: "8px 0",
    borderBottom: `1px solid ${theme.palette.greyLight100}`,
  },
  dividerContainer: {
    "& > .MuiDivider-root:last-child": {
      display: "none",
    },
  },
  expandButton: {
    display: "flex",
    position: "absolute",
    right: 12,
    top: 12,
  },
  italic: {
    fontStyle: "italic",
  },
  link: {
    textDecoration: "underline",
  },
  opportunityDetailsContainer: {
    backgroundColor: theme.palette.ivory,
    borderRadius: "6px",
    display: "flex",
    flexDirection: "column",
    padding: 12,
    position: "relative",
    gap: "6px",
  },
  tooltipIcon: {
    color: theme.palette.alpha500,
    fontSize: 16,
  },
}));

// I18N

const i18n = defineMessages({
  attachedOpportunities: {
    id: "Pipeline.MessageDrawer.attachedOpportunities",
    defaultMessage: "Attached Opportunities",
  },
  chooseOpport: {
    id: "Pipeline.MessageDrawer.chooseOpport",
    defaultMessage: `{count, plural,
      =0 {Attach opportunity}
      other {Attach opportunity ({count} open)}
    }`,
  },
  close: {
    id: "Pipeline.MessageDrawer.close",
    defaultMessage: "Close",
  },
  closeDate: {
    id: "Pipeline.MessageDrawer.closeDate",
    defaultMessage: "Close date:",
  },
  confirm: {
    id: "Pipeline.MessageDrawer.confirm",
    defaultMessage: "Confirm",
  },
  nextStep: {
    id: "Pipeline.MessageDrawer.nextStep",
    defaultMessage: "Next step:",
  },
  notSharing: {
    id: "Pipeline.MessageDrawer.notSharing",
    defaultMessage:
      "Your partner has not approved opportunity data sharing with you.<br />Send partner a message to request it.",
  },
  openInCrm: {
    id: "Pipeline.MessageDrawer.openInCrm",
    defaultMessage: "Open in CRM",
  },
  opportunityCountLabel: {
    id: "Pipeline.MessageDrawer.opportunityCountLabel",
    defaultMessage: `{count, plural,
      =1 {1 Opportunity}
      other {{count} Opportunities}
    }`,
  },
  owner: {
    id: "Pipeline.MessageDrawer.owner",
    defaultMessage: "Owner:",
  },
  selfTooltip: {
    id: "Pipeline.MessageDrawer.partnerTooltip",
    defaultMessage:
      "Each partner will see only the opportunity fields that you've defined as shared in your sharing settings <a>here</a>.",
  },
  partnerTooltip: {
    id: "Pipeline.MessageDrawer.selfTooltip",
    defaultMessage:
      "You will only see the info that your partner has defined in their opportunity sharing settings.",
  },
  remove: {
    id: "Pipeline.MessageDrawer.remove",
    defaultMessage: "Remove",
  },
});
