import { Business } from "@mui/icons-material";
import Person from "@mui/icons-material/Person";
import { Box } from "@mui/material";
import { alpha } from "@mui/material/styles";
import Alert from "components/ui/Alert";
import { T } from "components/ui/Typography";
import useUserProfile from "hooks/useUserProfile";
import { makeStyles } from "makeStyles";
import { useEffect, useRef, useState } from "react";
import { defineMessages, FormattedMessage } from "react-intl";
import UserService from "services/UserService";

const depersonate = async () => {
  const service = new UserService();
  await service.depersonate().finally();
  window.location.replace("/");
};

const SudoWidget = () => {
  const { classes, cx } = useStyles();
  const { profile } = useUserProfile();
  const service = new UserService();
  const userId = useRef<number | undefined>();
  const [_timer, setTimer] = useState(Date.now()); // eslint-disable-line @typescript-eslint/no-unused-vars

  useEffect(() => {
    let active = true;
    if (service.isImpersonating && userId.current === undefined) {
      userId.current = service.parsedToken.id;
      const interval = setInterval(() => {
        if (active) {
          setTimer(Date.now());
        }
      }, 1000);
      return () => {
        clearInterval(interval);
        active = false;
      };
    }
    return () => {
      active = false;
    };
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  if (service.isImpersonating) {
    if (userId.current !== service.parsedToken.id) {
      return (
        <div className={classes.blocking}>
          <div className={classes.alert}>
            <T titleBig>
              <FormattedMessage {...i18n.obsolete} />
            </T>
          </div>
        </div>
      );
    }
    return (
      <div className={classes.root}>
        <Alert variant="error" onClose={depersonate} className={classes.alert}>
          <Box className={classes.line}>
            <Person className={classes.icon} />
            <FormattedMessage {...i18n.person} values={service.parsedToken} />
          </Box>
          <Box className={classes.line}>
            <Business className={cx(classes.icon, classes.businessIcon)} />
            <FormattedMessage
              {...i18n.company}
              values={{ companyName: profile.companyName }}
            />
          </Box>
        </Alert>
      </div>
    );
  }
  return null;
};

export default SudoWidget;

/// CSS

const useStyles = makeStyles()((theme) => ({
  root: {
    position: "fixed",
    bottom: 80,
    right: 24,
    WebkitTransition: "1s ease-in-out",
    MozTransition: "1s ease-in-out",
    OTransition: "1s ease-in-out",
    transition: "1s ease-in-out",
    zIndex: 100,
  },
  icon: {
    marginRight: theme.spacing(1),
    fontSize: 18,
  },
  businessIcon: {
    position: "relative",
    left: 1,
    bottom: 2,
  },
  blocking: {
    position: "fixed",
    width: "100%",
    height: "100%",
    zIndex: 10000,
    top: 0,
    left: 0,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    background: alpha(theme.palette.lightPurple as string, 0.75),
  },
  alert: {
    background: theme.palette.taupe,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: 8,

    "& p": {
      flexDirection: "column",
    },
  },
  line: {
    alignSelf: "flex-start",
  },
}));

/// I18N

const i18n = defineMessages({
  person: {
    id: "SudoWidget.person",
    defaultMessage: "Impersonating {first_name} {last_name}",
  },
  company: {
    id: "SudoWidget.company",
    defaultMessage: "Company: {companyName}",
  },
  stop: {
    id: "SudoWidget.stop",
    defaultMessage: "Stop",
  },
  obsolete: {
    id: "SudoWidget.Obsolete",
    defaultMessage:
      "This session is obsolete. Please refresh your tab or close it.",
  },
});
