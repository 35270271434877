import { MatchFieldType, MatchFilterType } from "./enums";
import { TYPE_LABELS } from "./MatchFilter";

class MatchField {
  static TYPES = [
    MatchFieldType.UNKNOWN,
    MatchFieldType.BOOLEAN,
    MatchFieldType.TEXT,
    MatchFieldType.DATE,
    MatchFieldType.DATETIME,
    MatchFieldType.NUMBER,
    MatchFieldType.INTEGER,
    MatchFieldType.PICKLIST,
    MatchFieldType.USER,
    MatchFieldType.SINGLE_CHOICE_PICKLIST,
    MatchFieldType.REFERENCES_USER,
    MatchFieldType.REFERENCES_ACCOUNT,
    MatchFieldType.REFERENCES_OPPORTUNITY,
    MatchFieldType.REFERENCES_CONTACT,
  ];

  static FILTER_TYPES: { [value in MatchFieldType]: MatchFilterType[] } = {
    [MatchFieldType.UNKNOWN]: [],
    [MatchFieldType.TEXT]: [
      MatchFilterType.CONTAINS,
      MatchFilterType.DOES_NOT_CONTAIN,
    ],
    [MatchFieldType.PICKLIST]: [
      MatchFilterType.ANY_OF,
      MatchFilterType.NONE_OF,
    ],
    [MatchFieldType.MULTI_PICKLIST]: [
      MatchFilterType.CONTAINS_ANY,
      MatchFilterType.CONTAINS_EXACT,
      MatchFilterType.CONTAINS_AT_LEAST,
    ],
    [MatchFieldType.BOOLEAN]: [MatchFilterType.IS],
    [MatchFieldType.INTEGER]: [
      MatchFilterType.IS,
      MatchFilterType.IS_NOT,
      MatchFilterType.GT,
      MatchFilterType.LT,
      MatchFilterType.GTE,
      MatchFilterType.LTE,
    ],
    [MatchFieldType.NUMBER]: [
      MatchFilterType.IS,
      MatchFilterType.IS_NOT,
      MatchFilterType.GT,
      MatchFilterType.LT,
      MatchFilterType.GTE,
      MatchFilterType.LTE,
    ],
    [MatchFieldType.DATE]: [
      MatchFilterType.IS,
      MatchFilterType.IS_NOT,
      MatchFilterType.GT,
      MatchFilterType.LT,
      MatchFilterType.GTE,
      MatchFilterType.LTE,
    ],
    [MatchFieldType.DATETIME]: [
      MatchFilterType.GT,
      MatchFilterType.LT,
      MatchFilterType.GTE,
      MatchFilterType.LTE,
    ],
    [MatchFieldType.CURRENCY]: [
      MatchFilterType.IS,
      MatchFilterType.IS_NOT,
      MatchFilterType.GT,
      MatchFilterType.LT,
      MatchFilterType.GTE,
      MatchFilterType.LTE,
    ],
    [MatchFieldType.USER]: [MatchFilterType.ANY_OF, MatchFilterType.NONE_OF],
    [MatchFieldType.SINGLE_CHOICE_PICKLIST]: [MatchFilterType.IS],
    [MatchFieldType.REFERENCES_USER]: [
      MatchFilterType.IS,
      MatchFilterType.IS_NOT,
      MatchFilterType.CONTAINS,
      MatchFilterType.DOES_NOT_CONTAIN,
    ],
    [MatchFieldType.REFERENCES_ACCOUNT]: [
      MatchFilterType.IS,
      MatchFilterType.IS_NOT,
      MatchFilterType.CONTAINS,
      MatchFilterType.DOES_NOT_CONTAIN,
    ],
    [MatchFieldType.REFERENCES_CONTACT]: [
      MatchFilterType.IS,
      MatchFilterType.IS_NOT,
      MatchFilterType.CONTAINS,
      MatchFilterType.DOES_NOT_CONTAIN,
    ],
    [MatchFieldType.REFERENCES_OPPORTUNITY]: [
      MatchFilterType.IS,
      MatchFilterType.IS_NOT,
      MatchFilterType.CONTAINS,
      MatchFilterType.DOES_NOT_CONTAIN,
    ],
  };
  static TYPES_DEFAULT_VALUES: {
    [key in MatchFieldType]: string | string[] | boolean;
  } = {
    [MatchFieldType.UNKNOWN]: "",
    [MatchFieldType.TEXT]: "",
    [MatchFieldType.PICKLIST]: [],
    [MatchFieldType.MULTI_PICKLIST]: [],
    [MatchFieldType.BOOLEAN]: true,
    [MatchFieldType.INTEGER]: "",
    [MatchFieldType.NUMBER]: "",
    [MatchFieldType.DATE]: "",
    [MatchFieldType.DATETIME]: "",
    [MatchFieldType.CURRENCY]: "",
    [MatchFieldType.USER]: "",
    [MatchFieldType.SINGLE_CHOICE_PICKLIST]: "",
    [MatchFieldType.REFERENCES_USER]: "",
    [MatchFieldType.REFERENCES_ACCOUNT]: "",
    [MatchFieldType.REFERENCES_CONTACT]: "",
    [MatchFieldType.REFERENCES_OPPORTUNITY]: "",
  };

  static getFilterTypeOptions(
    fieldType: MatchFieldType,
    allowedOperations?: string[]
  ) {
    const availableKeys = this.FILTER_TYPES[fieldType] ?? [];
    const options = availableKeys
      .filter((key) => !allowedOperations || allowedOperations.includes(key))
      .map((key: MatchFilterType) => ({
        value: key,
        label: TYPE_LABELS[key],
      }));
    return options;
  }

  static canFilterOrSort(fieldType: MatchFieldType) {
    return Boolean(this.FILTER_TYPES[fieldType]);
  }
}

export default MatchField;
