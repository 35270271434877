import { isFulfilled, isRejected } from "@reduxjs/toolkit";
import { isRowClickable } from "helpers/partnerConnectionUtils";
import usePushNotification from "hooks/usePushNotification";
import useRecord from "hooks/useRecord";
import useSegment from "hooks/useSegment";
import useSelectorWithDeepEquality from "hooks/useSelectorWithDeepEquality";
import useUserProfile from "hooks/useUserProfile";
import _ from "lodash";
import DiscussionParticipant from "models/DiscussionParticipant";
import Message, { MessageType } from "models/Message";
import PartnerConnection from "models/PartnerConnection";
import Record from "models/Record";
import { JSONAPIResource } from "models/types";
import { useCallback, useEffect, useState } from "react";
import { defineMessages, FormattedMessage } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { updateAttributesOnRecord } from "redux/api/actions";
import { rawGet, rawPost } from "redux/api/thunks";
import { selectPartnershipsFilterData } from "redux/pipeline/selectors";
import { loadUnreadConversations } from "redux/pipeline/thunks";
import { PartnershipFilterData } from "redux/pipeline/typing";
import { RevealStore } from "redux/typing";
import { NonUserUrl } from "screens/Frontoffice/shared/helpers/types";
import { JSONAPIListResponse, JSONAPIResponse } from "services/types";
import { PipelineEvent } from "tracking";

type Props = {
  isReady?: boolean;
  partnerConnectionId: number;
};

const useMessageDrawerApiLogic = ({
  isReady = true,
  partnerConnectionId,
}: Props) => {
  const dispatch = useDispatch();
  const pushNotification = usePushNotification();
  const { profile } = useUserProfile();
  const { track } = useSegment();
  const partnersInfo = useSelectorWithDeepEquality(selectPartnershipsFilterData)
    .data;
  const history = useHistory();
  const isNonUser = history.location.pathname === NonUserUrl.Base;

  const conversations: {
    [key: string]: Record;
  } = useSelector((state: RevealStore) =>
    _.get(state, "api.entities.conversations", {})
  );
  const conversation = Object.values(conversations).find(
    (conversation) => conversation.partnerConnectionId === partnerConnectionId
  );

  const { record: partnerConnection, reload } = useRecord(
    "partner_connections",
    partnerConnectionId,
    isReady,
    {
      include: ["discussion_participants"],
    }
  );
  const partner = partnersInfo.find(
    ({ id }) => id === partnerConnection?.partnershipId
  );
  const allParticipants =
    partnerConnection?.discussionParticipants.map(
      (item: DiscussionParticipant) => ({
        ...item,
        role: item.userId && partner ? getUserRole(item.userId, partner) : null,
      })
    ) ?? [];
  const activeParticipants = allParticipants.filter(
    (item: DiscussionParticipant) => !item.isRemoved && !item.isDeleted
  );

  const participantId =
    activeParticipants.filter(
      (item: DiscussionParticipant) => item.userId === profile.id
    )[0]?.id ?? null;
  const isLocked =
    !profile.isCompanyAdmin() && !Boolean(participantId) && !isNonUser;

  const [loading, setLoading] = useState<boolean>(true);
  const [conversationId, setConversationId] = useState<number | null>(null);
  const [messages, setMessages] = useState<Message[]>([]);

  const createConversation = useCallback(async () => {
    const result = await dispatch(
      rawPost({
        type: "conversations",
        path: "",
        payload: {
          data: {
            type: "conversations",
            attributes: {
              partner_connection_id: partnerConnectionId,
            },
          },
        },
      })
    );
    if (isFulfilled(result)) {
      const response = result.payload as JSONAPIResponse;
      setConversationId(+response.data.id);
      return new Record(response.data) as Record<string>;
    } else {
      pushNotification(i18n.createConversationError);
      return null;
    }
  }, [partnerConnectionId, dispatch, pushNotification]);

  const getConversation = useCallback(async () => {
    if (!partnerConnection || isLocked) {
      return;
    }
    if (!isRowClickable(partnerConnection as PartnerConnection)) {
      setLoading(false);
      history.replace({ search: "" });
      return;
    }
    const result = await dispatch(
      rawGet({
        type: "conversations",
        path: "/",
        options: {
          filters: {
            partner_connection_id: partnerConnectionId,
          },
        },
      })
    );
    if (isFulfilled(result)) {
      const response = result.payload as JSONAPIListResponse;
      if (response.data?.length > 0) {
        setConversationId(+response.data[0].id);
      } else {
        setLoading(false);
      }
    } else {
      pushNotification("default_error");
    }
  }, [
    partnerConnection,
    partnerConnectionId,
    dispatch,
    pushNotification,
    history,
    isLocked,
  ]);

  const addUserAsParticipant = useCallback(async () => {
    const result = await dispatch(
      rawPost({
        type: "partner_connections",
        path: `${partnerConnectionId}/add-participant/`,
        payload: {
          data: {
            id: partnerConnectionId,
            type: "partner_connections",
            attributes: {
              user_id: profile.id,
            },
          },
        },
      })
    );
    if (isFulfilled(result)) {
      reload();
      return true;
    } else {
      pushNotification(i18n.addParticipantError);
      return false;
    }
  }, [partnerConnectionId, profile, reload, dispatch, pushNotification]);

  const getMessages = useCallback(
    async (conversationId: number) => {
      const result = await dispatch(
        rawGet({
          type: "conversations",
          path: `/${conversationId}/messages/`,
        })
      );
      if (isFulfilled(result)) {
        const response = result.payload as JSONAPIListResponse<"messages">;
        if (response.data?.length > 0) {
          setMessages(
            response.data.map(
              (message: JSONAPIResource<"messages">) => new Message(message)
            )
          );
        }
      } else {
        pushNotification(i18n.getMessagesError);
      }
      setLoading(false);
    },
    [dispatch, pushNotification]
  );

  const ackMessages = useCallback(async () => {
    if (!conversationId) {
      return;
    }
    const result = await dispatch(
      rawPost({
        type: "conversations",
        id: conversationId,
        path: "/ack-messages/",
        payload: {},
      })
    );
    if (isRejected(result)) {
      return;
    }

    dispatch(loadUnreadConversations());

    if (!conversation) {
      return;
    }

    // the conversation data is updated here in order to remove "New" tag
    // from the inbox column, without making any new requests
    dispatch(
      updateAttributesOnRecord(conversation, {
        lastAckAt: new Date().toISOString(),
      })
    );
  }, [conversation, conversationId, dispatch]);

  const addMessage = useCallback(
    async (message: string, type: MessageType) => {
      let _conversationId = conversationId;
      let _conversation = conversation;
      if (!_conversationId) {
        _conversation = (await createConversation()) ?? undefined;
        _conversationId = _conversation?.id ?? null;
      }
      if (!_conversationId) {
        return;
      }
      let isUserAdded = isNonUser || !!participantId;
      if (!isUserAdded) {
        isUserAdded = await addUserAsParticipant();
      }
      if (!isUserAdded) {
        return;
      }

      const result = await dispatch(
        rawPost({
          type: "discussion_messages",
          path: "",
          payload: {
            data: {
              type: "messages",
              attributes: {
                conversation_id: _conversationId,
                message_type: type,
                content: message,
              },
            },
          },
        })
      );
      if (isRejected(result)) {
        pushNotification(i18n.sendMessageError);
        return;
      }
      track(PipelineEvent.messageSent);
      if (_conversation) {
        await dispatch(
          updateAttributesOnRecord(_conversation, {
            lastMessageContent: message,
            lastMessageType: type,
            lastMessageParticipantId: participantId,
            lastMessageSentAt: new Date().toISOString(),
            lastAckAt: null,
          })
        );
      }
      await ackMessages();
      if (_conversationId) {
        getMessages(_conversationId);
      }
    },
    [
      ackMessages,
      participantId,
      conversationId,
      createConversation,
      addUserAsParticipant,
      getMessages,
      dispatch,
      pushNotification,
      conversation,
      track,
      isNonUser,
    ]
  );

  useEffect(() => {
    getConversation();
  }, [getConversation]);

  useEffect(() => {
    ackMessages();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [conversationId]);

  useEffect(() => {
    if (!conversationId) {
      return;
    }

    getMessages(conversationId);

    const interval = setInterval(() => {
      getMessages(conversationId);
    }, 5000);

    return () => clearInterval(interval);
  }, [conversationId, getMessages]);

  return {
    ackMessages,
    loading,
    isLocked,
    partnerConnection,
    activeParticipants,
    allParticipants,
    messages,
    reload,
    addMessage,
  };
};

export default useMessageDrawerApiLogic;

// Helpers

const getUserRole = (userId: number, partner: PartnershipFilterData) => {
  if (userId === partner.owner?.id) {
    return (
      <FormattedMessage
        {...i18n.partnershipOwner}
        values={{ company: partner.owner.companyName }}
      />
    );
  } else if (userId === partner.partnerOwner?.id) {
    return (
      <FormattedMessage
        {...i18n.partnershipOwner}
        values={{ company: partner.partnerOwner.companyName }}
      />
    );
  }
  return null;
};

// I18N

const i18n = defineMessages({
  addParticipantError: {
    id:
      "Datables.Pipeline.MessageDrawer.useMessageDrawerApiLogic.addParticipantError",
    defaultMessage: "Error while adding participant",
  },
  createConversationError: {
    id:
      "Datables.Pipeline.MessageDrawer.useMessageDrawerApiLogic.createConversationError",
    defaultMessage: "Error while creating conversation",
  },
  getMessagesError: {
    id:
      "Datables.Pipeline.MessageDrawer.useMessageDrawerApiLogic.getMessagesError",
    defaultMessage: "Error while getting messages",
  },
  partnershipOwner: {
    id:
      "Datables.Pipeline.MessageDrawer.useMessageDrawerApiLogic.partnershipOwner",
    defaultMessage: "{company} Partnership Owner",
  },
  sendMessageError: {
    id:
      "Datables.Pipeline.MessageDrawer.useMessageDrawerApiLogic.sendMessageError",
    defaultMessage: "Error while sending message",
  },
});
