import { makeStyles } from "makeStyles";
import { forwardRef, Ref } from "react";

import {
  customStyles,
  HTMLTextElement,
  isBodyBig,
  isBodySmall,
  isH1,
  isH2,
  isH3,
  isH4,
  isLabelBig,
  isLabelSmall,
  isTitleBig,
  isTitleSmall,
  isUppercase,
  Props,
} from "./types";

export const T = forwardRef((props: Props, ref: Ref<HTMLTextElement>) => {
  const { classes, cx } = useStyles(props);
  const className = cx(classes.typography, props.className);
  const htmlProps = {
    className,
    title: props.title,
    onBlur: props.onBlur,
    onFocus: props.onFocus,
    onMouseLeave: props.onMouseLeave,
    onMouseOver: props.onMouseOver,
    onTouchEnd: props.onTouchEnd,
    onTouchStart: props.onTouchStart,
    ref,
  };

  if (isH1(props)) {
    return <h1 {...htmlProps}>{props.children}</h1>;
  }

  if (isH2(props)) {
    return <h2 {...htmlProps}>{props.children}</h2>;
  }

  if (isH3(props)) {
    return <h3 {...htmlProps}>{props.children}</h3>;
  }

  if (isH4(props)) {
    return <h4 {...htmlProps}>{props.children}</h4>;
  }

  if (props.span) {
    return <span {...htmlProps}>{props.children}</span>;
  }

  return <p {...htmlProps}>{props.children}</p>;
});

const fontFamily = "'Inter', sans-serif";

export const useStyles = makeStyles<Props>()((theme, props) => ({
  typography: {
    fontFamily,
    margin: 0,
    ...customStyles(props),
    ...(props.noWrap
      ? {
          overflow: "hidden",
          whiteSpace: "nowrap" as const,
          textOverflow: "ellipsis",
        }
      : {}),
    ...getFontStyles(props, false),
    [theme.breakpoints.down("md")]: {
      ...getFontStyles(props, true),
    },
  },
}));

function getFontStyles(props: Props, small: boolean = false) {
  // Default value when no variant is specified.
  const bodySmallStyle = {
    fontSize: small ? "11px" : "12px",
    lineHeight: "18px",
    fontWeight: props.bold ? 500 : 400,
  };

  switch (true) {
    case isH1(props):
      return {
        fontSize: small ? "20px" : "32px",
        lineHeight: "41px",
        fontWeight: props.bold ? 600 : 400,
      };
    case isH2(props):
      return {
        fontSize: small ? "18px" : "24px",
        lineHeight: "31px",
        fontWeight: props.bold ? 600 : 400,
      };
    case isH3(props):
      return {
        fontSize: small ? "16px" : "18px",
        lineHeight: "23px",
        fontWeight: props.bold ? 600 : 400,
      };
    case isH4(props):
      return {
        fontSize: small ? "14px" : "16px",
        lineHeight: "21px",
        fontWeight: props.bold ? 600 : 400,
      };
    case isBodyBig(props):
      return {
        fontSize: small ? "13px" : "14px",
        lineHeight: "21px",
        fontWeight: props.bold ? 500 : 400,
      };
    case isBodySmall(props):
      return bodySmallStyle;
    case isLabelBig(props):
      return {
        fontSize: small ? "13px" : "14px",
        lineHeight: "14px",
        fontWeight: 500,
      };
    case isLabelSmall(props):
      return {
        fontSize: small ? "11px" : "12px",
        lineHeight: "12px",
        fontWeight: 500,
      };
    case isTitleBig(props):
      return {
        fontSize: small ? "13px" : "14px",
        lineHeight: "18px",
        fontWeight: 600,
      };
    case isTitleSmall(props):
      return {
        fontSize: small ? "11px" : "12px",
        lineHeight: "16px",
        fontWeight: 600,
      };
    case isUppercase(props):
      return {
        fontSize: "10px",
        lineHeight: "10px",
        fontWeight: 500,
        textTransform: "uppercase" as const,
      };
    default:
      return bodySmallStyle;
  }
}

export const _private = {
  getFontStyles,
  fontFamily,
};
