import { createSvgIcon } from "@mui/material/utils";

import { SvgIcon } from "./types";

const displayName = "MessageChecked";

const MessageChecked: SvgIcon = createSvgIcon(
  // Using transform to enlarge from provided viewbox="0 0 16 16"
  // to default "0 0 24 24" from `createSvgIcon` -> `SvgIcon`.
  <g fill="none" transform="matrix(1.5,0,0,1.5,0,0)">
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M1.5 3.5C1.5 2.39543 2.39543 1.5 3.5 1.5H12.5C13.6046 1.5 14.5 2.39543 14.5 3.5V10.9615C14.5 12.0661 13.6046 12.9615 12.5 12.9615H9.352C9.13774 12.9615 8.92915 13.0304 8.75695 13.1578L4.92857 15.9923V12.9615H3.5C2.39543 12.9615 1.5 12.0661 1.5 10.9615V3.5ZM11.3536 5.85355C11.5488 5.65829 11.5488 5.34171 11.3536 5.14645C11.1583 4.95118 10.8417 4.95118 10.6464 5.14645L7.10934 8.68355L5.36265 6.84329C5.17255 6.643 4.85607 6.63474 4.65579 6.82485C4.4555 7.01495 4.44724 7.33143 4.63735 7.53171L6.38404 9.37198C6.77128 9.77996 7.4187 9.7884 7.81645 9.39066L11.3536 5.85355Z"
      fill="currentColor"
    />
  </g>,
  displayName
);

MessageChecked.displayName = displayName;
export default MessageChecked;
