import history from "_history";
import Button from "components/ui/Button";
import Tooltip from "components/ui/Tooltip";
import useRecord from "hooks/useRecord";
import useSegment from "hooks/useSegment";
import _ from "lodash";
import {
  AccountMappingShare,
  NewLeadsShare,
} from "models/AccountMappingSettings";
import { PayingFeature } from "models/CompanyPayingFeatureSubscription";
import Partnership, { PartnershipStatus } from "models/Partnership";
import { useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { loadView, updateView } from "redux/accountMapping/thunks";
import { AccountMappingResource } from "redux/accountMapping/types";
import { AvailableAnalytisColumns } from "redux/analytics/defaults";
import { selectActivePayingFeatures } from "redux/api/selectors";
import {
  getLeftIcon,
  getTooltipMessage,
  Sharing,
} from "screens/Frontoffice/screens/Partners/shared/utils";
import { AdvancedAnalyticsEvent } from "tracking";

import { CellProps } from "../../utils";

const AccountMappingCell = (props: CellProps) => {
  const partnershipId = _.get(props, "row.partnershipId", null);

  const payingFeatures = useSelector(selectActivePayingFeatures);
  const isMatchedAccount = payingFeatures.includes(
    PayingFeature.UseNewAccountMapping
  );
  const { value, resourceType, kpi, sharing } = getValues(
    props,
    isMatchedAccount
  );
  const isAccountOverlaps =
    resourceType === AccountMappingResource.matches ||
    resourceType === AccountMappingResource.matched_accounts;
  const dispatch = useDispatch();
  const { track } = useSegment();
  const intl = useIntl();
  const partnershipPathname = `/partnerships/${partnershipId}`;
  const { record } = useRecord("partnerships", partnershipId, false);

  if (!partnershipId) return null;

  const goToAccountMapping = async (
    resourceType: AccountMappingResource,
    kpi: string
  ) => {
    if (record) {
      const partnership = record as Partnership;
      await dispatch(loadView({ partnership, isMatchedAccount }));
      await dispatch(
        updateView({
          accountType: resourceType,
          partnership,
          isMatchedAccount,
        })
      );
      track(AdvancedAnalyticsEvent.redirectToAccountMapping, {
        to: resourceType,
      });
      history.push({
        pathname: `${partnershipPathname}/account-mapping`,
        search: `?kpi=${kpi}`,
      });
    }
  };

  return (
    <Tooltip
      title={getTooltipMessage(isAccountOverlaps, sharing, intl)}
      placement="top"
    >
      <div>
        <Button
          LeftIcon={getLeftIcon(sharing)}
          label={String(value)}
          size="small"
          onClick={() => goToAccountMapping(resourceType, kpi)}
          variant={"septenary"}
        />
      </div>
    </Tooltip>
  );
};

export default AccountMappingCell;

// Helpers

export const isAccountMappingCell = ({ fieldName }: CellProps) => {
  return (
    fieldName === AvailableAnalytisColumns.overlaps ||
    fieldName === AvailableAnalytisColumns.newProspectsFromPartner
  );
};

export const getAccountMappingCopyContent = (
  props: CellProps,
  isMatchedAccount: boolean
) => {
  const { value } = getValues(props, isMatchedAccount);
  return value;
};

const getValues = (props: CellProps, isMatchedAccount: boolean) => {
  const { fieldName, row } = props;
  let resourceType = isMatchedAccount
    ? AccountMappingResource.matched_accounts
    : AccountMappingResource.matches;
  let kpi = "all_overlaps";
  let value;
  let sharing: Sharing = {
    left: false,
    right: false,
  };
  const isGhost =
    _.get(row, "partnershipStatus", null) === PartnershipStatus.Ghost;

  if (fieldName === AvailableAnalytisColumns.overlaps) {
    // Loading values for overlaps
    value = _.get(row, `metrics.${AvailableAnalytisColumns.overlaps}`, 0);
    let mySharedOverlaps = _.get(
      row,
      "mySharedOverlaps",
      AccountMappingShare.Never
    );
    if (mySharedOverlaps === null) {
      mySharedOverlaps = AccountMappingShare.Never;
    }
    let partnerSharedOverlaps = _.get(
      row,
      "partnerSharedOverlaps",
      AccountMappingShare.Never
    );
    if (partnerSharedOverlaps === null) {
      partnerSharedOverlaps = AccountMappingShare.Never;
    }
    if (
      mySharedOverlaps === AccountMappingShare.CommonCustomers ||
      partnerSharedOverlaps === AccountMappingShare.CommonCustomers
    ) {
      kpi = "common_customers";
    }
    sharing = {
      left: isGhost || mySharedOverlaps !== AccountMappingShare.Never,
      right: isGhost || partnerSharedOverlaps !== AccountMappingShare.Never,
    };
  } else if (fieldName === AvailableAnalytisColumns.newProspectsFromPartner) {
    // Loading values for new prospects
    value = _.get(
      row,
      `metrics.${AvailableAnalytisColumns.newProspectsFromPartner}`,
      0
    );
    kpi = "nb_of_new_prospects";
    resourceType = AccountMappingResource.shared_accounts;
    const mySharedNewProspects = _.get(
      row,
      "mySharedNewProspects",
      NewLeadsShare.Never
    );
    const partnerSharedNewProspects = _.get(
      row,
      "partnerSharedNewProspects",
      NewLeadsShare.Never
    );
    sharing = {
      left: isGhost || mySharedNewProspects === NewLeadsShare.Always,
      right: isGhost || partnerSharedNewProspects === NewLeadsShare.Always,
    };
  }
  return { value: value ?? 0, resourceType, kpi, sharing };
};
