import { isRejected } from "@reduxjs/toolkit";
import { Plus, Trash } from "components/icons";
import AccountAvatar from "components/ui/avatars/AccountAvatar";
import Button from "components/ui/Button";
import { T } from "components/ui/Typography";
import usePushNotification from "hooks/usePushNotification";
import { makeStyles } from "makeStyles";
import User from "models/User";
import { ChangeEvent, useRef } from "react";
import { defineMessages, FormattedMessage } from "react-intl";
import { useDispatch } from "react-redux";
import { fetchProfile } from "redux/user/thunks";
import JSONAPIService from "services/JSONAPIService";

type Props = {
  profile: User;
};

const attachToRecord = async (user: User, avatar: File) => {
  const service = new JSONAPIService("users");
  return await service.rawUpload(user.id, "/avatar", { avatar });
};

const removeFromRecord = async (user: User) => {
  const service = new JSONAPIService("users");
  return await service.rawDelete(user.id, "", "/avatar");
};

export const UserAvatar = ({ profile }: Props) => {
  const { classes } = useStyles();
  const inputRef = useRef<HTMLInputElement | null>(null);
  const dispatch = useDispatch();
  const pushNotification = usePushNotification();

  const onClickOpenInput = () => {
    if (inputRef.current) {
      inputRef.current.click();
    }
  };

  const deleteAvatar = async () => {
    try {
      await removeFromRecord(profile);
      const result = await dispatch(fetchProfile());
      if (isRejected(result)) {
        throw new Error("Error fetching profile");
      }
    } catch {
      pushNotification("default_error");
    }
  };

  const handleChange = async (event: ChangeEvent<HTMLInputElement>) => {
    if (event.target.files && event.target.files?.length > 0) {
      try {
        await attachToRecord(profile, event.target.files[0]);
        const result = await dispatch(fetchProfile());
        if (isRejected(result)) {
          throw new Error("Error fetching profile");
        }
      } catch {
        pushNotification("default_error");
      }
    }
  };

  return (
    <div className={classes.root}>
      <AccountAvatar user={profile} size="xl" />
      {!profile.avatarUrl ? (
        <>
          <div className={classes.add}>
            <Button
              label={i18n.btnLabel}
              LeftIcon={Plus}
              onClick={onClickOpenInput}
              size="small"
              variant="secondary"
            />
            <T bodySmall>
              <FormattedMessage {...i18n.instructions} />
            </T>
          </div>
          <input
            ref={inputRef}
            className={classes.input}
            type="file"
            data-testid="avatar-file-input"
            accept=".png,.jpg,.jpeg"
            onChange={handleChange}
          />
        </>
      ) : (
        <Button
          label={i18n.removeBtnLabel}
          LeftIcon={Trash}
          onClick={deleteAvatar}
          size="small"
          variant="secondary"
        />
      )}
    </div>
  );
};

export default UserAvatar;

/// CSS

const useStyles = makeStyles()((theme) => ({
  root: {
    display: "flex",
    alignItems: "center",
    gap: 12,
  },
  input: {
    display: "none",
  },
  add: {
    display: "flex",
    alignItems: "center",
    gap: 12,
    [theme.breakpoints.down("md")]: {
      flexDirection: "column",
      alignItems: "flex-start",
      gap: 4,
    },
  },
}));

// I18N

const i18n = defineMessages({
  btnLabel: {
    id: "Profile.UserAvatar.Button",
    defaultMessage: "Upload photo",
  },
  instructions: {
    id: "Profile.UserAvatar.Instructions",
    defaultMessage: "At least 256x256px PNG or JPG file",
  },
  removeBtnLabel: {
    id: "Profile.UserAvatar.RemoveButton",
    defaultMessage: "Remove",
  },
});
