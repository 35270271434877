import { T } from "components/ui/Typography";
import _ from "lodash";
import { makeStyles } from "makeStyles";
import Match from "models/Match";
import { defineMessages, FormattedMessage } from "react-intl";
import { useSelector } from "react-redux";
import {
  selectOnlyNewUpdatesSinceDate,
  selectOnlyNewUpdatesSinceFieldname,
} from "redux/accountMapping/selector";
import {
  AccountMappingStandardFieldName,
  DisplaySinceFilterFieldname,
} from "screens/Frontoffice/screens/DataTables/screens/AccountMapping/shared/types";

import { CellProps, getIsNewMatch } from "../utils";
import NewChip from "./CompanyNameCell/components/NewChip";

const StatusCell = ({ fieldName, value, field, row }: CellProps) => {
  const { classes } = useStyles();
  const displaySinceFilterFieldname = useSelector(
    selectOnlyNewUpdatesSinceFieldname
  );
  const displaySinceFilterDate = useSelector(selectOnlyNewUpdatesSinceDate);

  const side = getStatusSide(fieldName);

  const statusChanged = hasStatusChanged(
    side,
    row,
    displaySinceFilterFieldname,
    displaySinceFilterDate
  );

  const label = _.get(field, `options.${value}`, "");
  let customerFor = getCustomerFor(side, value, row);

  return (
    <div title={label} className={classes.root}>
      <T noWrap>
        {label}
        {label && customerFor && " "}
        {label && customerFor && customerFor}
      </T>
      {statusChanged && (
        <div className={classes.newChip}>
          <NewChip tooltip={i18n.statusChanged} />
        </div>
      )}
    </div>
  );
};

export default StatusCell;

// Helpers

export const isStatusCell = ({ fieldName }: CellProps) => {
  return [
    AccountMappingStandardFieldName.rightStatus,
    AccountMappingStandardFieldName.leftStatus,
  ].includes(fieldName as AccountMappingStandardFieldName);
};

export const getStatusSide = (fieldName: string) => {
  return fieldName === AccountMappingStandardFieldName.rightStatus
    ? "right"
    : fieldName === AccountMappingStandardFieldName.leftStatus
    ? "left"
    : undefined;
};

export const hasStatusChanged = (
  side: string | undefined,
  row: any,
  displaySinceFilterFieldname: DisplaySinceFilterFieldname | null,
  displaySinceFilterDate: Date | null
) => {
  if (getIsNewMatch(row, displaySinceFilterDate)) return false;

  if (
    displaySinceFilterFieldname === DisplaySinceFilterFieldname.StatusChanged ||
    (displaySinceFilterFieldname ===
      DisplaySinceFilterFieldname.MyOpportunityOrPartnerStatusChanged &&
      side === "right")
  ) {
    const statusChangedAt = _.get(row, `${side}StatusChangedAt`, null);
    return (
      displaySinceFilterDate &&
      new Date(statusChangedAt) >= displaySinceFilterDate
    );
  }

  return false;
};

const getCustomerFor = (side: string | undefined, value: any, row: any) => {
  let customerFor = null;

  if (value === Match.STATUS_CUSTOMER) {
    let firstWonOpportAt = null;
    if (side) {
      firstWonOpportAt = _.get(
        row,
        `${side}${
          row.type === "matched_accounts" ? "WonOpportunityAt" : "WonOpportAt"
        }`,
        null
      );
    } else {
      firstWonOpportAt = _.get(row, "firstWonOpportAtDate", null);
    }

    if (firstWonOpportAt) {
      const today = new Date(Date.now());
      const startDate = new Date(firstWonOpportAt);
      let yearsDiff = today.getFullYear() - startDate.getFullYear();
      let monthsDiff =
        yearsDiff * 12 + (today.getMonth() - startDate.getMonth());
      if (yearsDiff > 0) {
        customerFor = (
          <FormattedMessage {...i18n.years} values={{ count: yearsDiff }} />
        );
      } else if (monthsDiff > 0) {
        customerFor = (
          <FormattedMessage {...i18n.months} values={{ count: monthsDiff }} />
        );
      }
    }
  }
  return customerFor;
};

// CSS

const useStyles = makeStyles()((theme) => ({
  root: {
    display: "flex",
    position: "relative",
    width: "100%",
  },
  newChip: {
    position: "absolute",
    display: "flex",
    height: "100%",
    right: "-9px",
    width: 32,
    alignItems: "center",
    justifyContent: "end",
  },
}));

// I18N

const i18n = defineMessages({
  months: {
    id: "crm.AccountMapping.CellFormatters.StatusCell.months",
    defaultMessage: "({count} {count, plural, one {mo} other {mos}}.)",
  },
  years: {
    id: "crm.AccountMapping.CellFormatters.StatusCell.years",
    defaultMessage: "({count} {count, plural, one {yr} other {yrs}}.)",
  },
  statusChanged: {
    id: "crm.AccountMapping.CellFormatters.StatusCell.statusChanged",
    defaultMessage: "Status changed",
  },
});
