import Tooltip from "components/ui/Tooltip";
import { T } from "components/ui/Typography";
import { makeStyles } from "makeStyles";
import { defineMessages, FormattedMessage } from "react-intl";

export const ListedOnOpportunitiesLabel = () => {
  const { classes } = useStyles();
  return (
    <Tooltip title={<FormattedMessage {...i18n.dealInfluencerTooltip} />}>
      <T className={classes.container}>
        <FormattedMessage {...i18n.dealInfluencer} />
      </T>
    </Tooltip>
  );
};

const useStyles = makeStyles()((theme) => ({
  container: {
    background: theme.palette.greenBrand100,
    borderRadius: 4,
    color: theme.palette.greenBrand700,
    fontSize: 10,
    fontWeight: 500,
    letterSpacing: "0.2px",
    padding: "1px 6px",
    textTransform: "uppercase",
  },
}));

const i18n = defineMessages({
  dealInfluencer: {
    id: "crm.Ecosystem.AccountView.ListedOnOpportunitiesLabel.dealInfluencer",
    defaultMessage: "Deal Influencer",
  },
  dealInfluencerTooltip: {
    id:
      "crm.Ecosystem.AccountView.ListedOnOpportunitiesLabel.dealInfluencerTooltip",
    defaultMessage:
      "Deal influencers are contacts listed by your partners at the opportunity level. They are likely key stakeholders.",
  },
});
