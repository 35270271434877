export enum NonUserActions {
  initLongLivedToken = "nonUser/initLongLivedToken",
  initNewShortLivedTokenEmail = "nonUser/initNewShortLivedTokenEmail",
  refreshToken = "nonUser/refreshToken",
  setError = "nonUser/setError",
}

export type NonUserState = {
  ready: boolean;
  error?: ErrorType | null;
};

export interface RootStateWithNonUser {
  nonUser: NonUserState;
}

export enum ErrorType {
  ExpiredToken = "EXPIRED_TOKEN",
  InvalidToken = "INVALID_TOKEN",
  Generic = "GENERIC",
  NewEmailSent = "NEW_EMAIL_SENT",
}
