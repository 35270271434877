import Grid from "@mui/material/Grid";
import { CategoryType } from "components/ui/data-grid/cellRenderers/pipeline/CategoryCell";
import { SelectedRowsProvider } from "components/ui/data-grid/SelectedRowsContext";
import { FilterWidget } from "components/ui/filters/filter/FilterWidget";
import { QuickSearch } from "components/ui/QuickSearch";
import Toggle from "components/ui/Toggle";
import { T } from "components/ui/Typography";
import { useQuickSearch } from "hooks/useQuickSearch";
import useSegment from "hooks/useSegment";
import useSelectorWithDeepEquality from "hooks/useSelectorWithDeepEquality";
import useUserProfile from "hooks/useUserProfile";
import _ from "lodash";
import { makeStyles } from "makeStyles";
import Match from "models/Match";
import PartnerConnection, {
  stageDecodeMapping,
} from "models/PartnerConnection";
import { Status, StatusLabel } from "models/RawOpportunity";
import { RecordType } from "models/types";
import User from "models/User";
import { parse } from "query-string";
import { useCallback, useEffect, useMemo, useState } from "react";
import { defineMessages, FormattedMessage, useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { RouteComponentProps, useLocation } from "react-router-dom";
import { useHistory } from "react-router-dom";
import { indexAll } from "redux/api/thunks";
import { selectDashboardData } from "redux/dashboard/selectors";
import { initializePartnershipsAndCompanyNames } from "redux/init/thunks";
import { AvailablePipelineColumns } from "redux/pipeline/defaults";
import {
  selectPartnerConnections,
  selectPipelineSlice,
  selectShowArchived,
} from "redux/pipeline/selectors";
import {
  loadView,
  toggleArchivedFilter,
  updateView,
} from "redux/pipeline/thunks";
import { setOwnerFilter } from "redux/pipeline/thunks";
import { AvailablePipelineAttributeColumns } from "redux/pipelineAttribute/defaults";
import { RevealStore } from "redux/typing";
import { formatRangeFilters } from "redux/utils";
import DateRangeFilter from "screens/Frontoffice/shared/components/DateRangeFilter";
import { PipelineEvent } from "tracking";

import { OwnerFilter } from "../../../shared/components/OwnerFilter";
import PartnerFilter from "../../../shared/components/PartnerFilter";
import { useTableRows } from "../../../shared/hooks";
import {
  DataTableType,
  FilterType,
  MatchFieldType,
  MatchFilterType,
} from "../../../shared/types";
import { fields, PipelineViewParameters } from "../constants";
import ActionButtons from "./ActionButtons";
import { InboundOutboundSelect } from "./InboundOutboundSelect";
import { MyPipelineExportButton } from "./MyPipelineExportButton";
import { MyPipelineTable } from "./MyPipelineTable";
import ViewFilter from "./ViewFilter";

enum KindType {
  Sourcing = 1,
  Influence = 2,
  Other = 3,
  "Co-selling" = 4,
  Reselling = 5,
  None = 6,
}

type Params = {
  partnershipId?: string;
};

const MyPipeline = ({ match }: RouteComponentProps<Params>) => {
  const { classes, cx } = useStyles();
  const history = useHistory();
  const { hash, search: searchRaw } = useLocation();
  const { track } = useSegment();
  const dispatch = useDispatch();
  const intl = useIntl();
  const { partnershipId } = getParams(match.params);
  const { ready: dashboardReady } = useSelector(selectDashboardData);
  const { ready, view } = useSelector(selectPipelineSlice);
  const { filters, sort } = view ?? {};
  const formattedFilters = formatRangeFilters(filters);
  const { profile } = useUserProfile();

  const users: { [id: number]: User } = useSelector((state: RevealStore) =>
    _.get(state, "api.entities.users", {})
  );
  const userList = Object.values(users);
  const { partnerConnections } = useSelectorWithDeepEquality(
    selectPartnerConnections
  );

  const [partnerSelectorIsOpen, setPartnerSelectorIsOpen] = useState<boolean>(
    false
  );
  const [openedWidget, setOpenedWidget] = useState<string | null>(null);

  const showArchived = useSelectorWithDeepEquality(selectShowArchived);

  const setView = useCallback(
    (value: PipelineViewParameters) => dispatch(updateView(value)),
    [dispatch]
  );
  const setFilters = useCallback(
    (filters: FilterType[]) => {
      setView({ filters });
    },
    [setView]
  );

  const { searchValue, handleChangeSearch, handleClearSearch } = useQuickSearch(
    {
      fieldName: AvailablePipelineAttributeColumns.accountName,
      filters: filters ?? [],
      setFilters,
    }
  );

  const fetchConversations = useCallback(
    (slice: number[]) => {
      dispatch(
        indexAll({
          type: "conversations",
          options: {
            filters: { "partner_connection_id.in": slice.join(",") },
          },
        })
      );
    },
    [dispatch]
  );

  const { sync, records, count, fetching, loadMore, refresh } = useTableRows(
    RecordType.PartnerConnection,
    formattedFilters,
    sort,
    null,
    true,
    ["discussion_participants"],
    undefined,
    undefined,
    undefined,
    true,
    false,
    fetchConversations
  );

  const _fields = useMemo(() => {
    const _fields = _.cloneDeep(fields); // Without cloning it, "fields" will mute and can cause issues while debugging (ie: wrong log values)
    for (let [key, value] of Object.entries(_fields)) {
      if (value.type === MatchFieldType.USER) {
        value.options = Object.fromEntries(
          userList.map((user) => [user.id, user.fullName])
        );
      }
      if (key === AvailablePipelineColumns.category) {
        value.options = Object.fromEntries(
          Object.values(CategoryType).map((category) => [category, category])
        );
      }
      if (key === AvailablePipelineColumns.rawCompanyOwnerId) {
        value.options = Object.fromEntries(
          partnerConnections.map((partnerConnection) => [
            partnerConnection.rawCompanyOwnerId,
            partnerConnection.rawCompanyOwnerName,
          ])
        );
      }
      if (key === AvailablePipelineColumns.rawOpportunityStatus) {
        const statusKeysList = Object.keys(Status);
        // Using the keys of the Status enum to get the associated i18n related message in the StatusLabel object.
        value.options = Object.fromEntries(
          statusKeysList.map((key) => [
            key.toLowerCase(),
            intl.formatMessage(StatusLabel[Status[key as keyof typeof Status]]),
          ])
        );
      }
      if (key === AvailablePipelineColumns.stage) {
        value.options = stageDecodeMapping;
      }
      if (key === AvailablePipelineColumns.kind) {
        const kindTypeMapping: { [key: number]: string } = {};
        for (const key in KindType) {
          const value = KindType[key];
          if (typeof value === "number") {
            kindTypeMapping[value] = key;
          }
        }
        value.options = kindTypeMapping;
      }
      if (key === AvailablePipelineColumns.rawCompanyStatus) {
        const statusLabelsFormatted: { [key: number]: string } = {};
        for (const key in Match.STATUS_LABELS) {
          statusLabelsFormatted[key] = intl.formatMessage(
            Match.STATUS_LABELS[key]
          );
        }
        value.options = statusLabelsFormatted;
      }
    }
    return _fields;
  }, [partnerConnections, userList, intl]);

  const rows = records as PartnerConnection[];
  const fieldsInWidget = {
    ..._.omit(_fields, [
      AvailablePipelineColumns.partnership,
      AvailablePipelineColumns.rawCompanyOwnerId,
      "participant_user_id",
    ]),
    [AvailablePipelineColumns.rawCompanyName]: {
      ..._fields[AvailablePipelineColumns.rawCompanyName],
      label: "Account",
    },
  };

  const toggleArchive = () => dispatch(toggleArchivedFilter());

  useEffect(() => {
    const search = parse(searchRaw);
    if (!search?.["referring-partnership"]) {
      return;
    }
    const partnershipId = search["referring-partnership"] as string;
    setView({
      filters: [
        ...(filters?.filter(
          (item) =>
            !([
              AvailablePipelineColumns.partnership,
              AvailablePipelineColumns.category,
            ] as string[]).includes(item.fieldname)
        ) ?? []),
        {
          fieldname: AvailablePipelineColumns.partnership,
          type: MatchFilterType.ANY_OF,
          value: [partnershipId],
        },
        {
          fieldname: AvailablePipelineColumns.category,
          type: MatchFilterType.ANY_OF,
          value: [CategoryType.PartnerReferral],
        },
      ],
    });
    history.replace({
      search: "",
    });
  }, [setView, filters, history, searchRaw]);

  useEffect(() => {
    dispatch(loadView());
  }, [dispatch]);

  useEffect(() => {
    if (!dashboardReady) {
      dispatch(initializePartnershipsAndCompanyNames());
    }
  }, [dashboardReady, dispatch]);

  useEffect(() => {
    if (hash === "#refresh") {
      refresh();
      history.replace(history.location.pathname);
    }
  }, [hash, refresh, history]);

  useEffect(() => {
    track(PipelineEvent.viewPipeline);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (ready && !!sort?.length) {
      // Reset sort to default on first table load if sort applied
      setView({ sort: [] });
    }
  }, [ready]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <SelectedRowsProvider>
      <div className={classes.root}>
        <div className={classes.header}>
          <div className={classes.headerRow}>
            <div className={classes.title}>
              <T h3 bold>
                <FormattedMessage {...i18n.title} />
              </T>
              <InboundOutboundSelect />
            </div>
          </div>
          <div
            className={cx(classes.headerRow, {
              [classes.alignRight]: !profile.ownsPipeline,
            })}
          >
            {profile.ownsPipeline && (
              <Grid item className={classes.actionsAndLegend}>
                <div id="actions" className={classes.actionButtonsAndToggle}>
                  <ActionButtons records={records} refresh={refresh} />
                  <MyPipelineExportButton />
                  <QuickSearch
                    value={searchValue}
                    onChange={handleChangeSearch}
                    onClear={handleClearSearch}
                    isSmall
                  />
                </div>
              </Grid>
            )}
            <div className={classes.headerActions}>
              {profile.ownsPipeline && <ViewFilter />}
              <PartnerFilter
                partnerSelectorIsOpen={partnerSelectorIsOpen}
                setPartnerSelectorIsOpen={setPartnerSelectorIsOpen}
                selectedPartnershipId={partnershipId}
                loadingPartners={!dashboardReady}
              />
              <OwnerFilter
                field={_fields[AvailablePipelineColumns.rawCompanyOwnerId]}
                setOwnerFilter={setOwnerFilter}
                value={
                  filters?.find(
                    (filter) =>
                      filter.fieldname ===
                      AvailablePipelineColumns.rawCompanyOwnerId
                  )?.value ?? null
                }
              />
              <DateRangeFilter />
              <FilterWidget
                disabled={false}
                fields={fieldsInWidget}
                filters={filters ?? []}
                onFilterUpdate={setFilters}
                openedWidget={openedWidget}
                setOpenedWidget={setOpenedWidget}
                viewType={DataTableType.COLLABORATE}
              />
              <Toggle
                handleChange={toggleArchive}
                checked={showArchived}
                size="small"
              />
              <T>
                <FormattedMessage {...i18n.showArchived} />
              </T>
            </div>
          </div>
        </div>

        <MyPipelineTable
          rows={!ready ? [] : rows}
          rowsCount={count}
          fields={_fields}
          loading={!ready || !sync || fetching || count === null}
          loadMore={loadMore}
          fetching={fetching}
          setView={setView}
          refreshTable={refresh}
        />
      </div>
    </SelectedRowsProvider>
  );
};

export default MyPipeline;

// HELPERS

const getParams = (params: Params) => {
  const partnershipId = params.partnershipId || "";

  return {
    partnershipId:
      partnershipId === "" || isNaN(+partnershipId) ? null : +partnershipId,
  };
};

// CSS

const useStyles = makeStyles()((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    height: "100%",
  },
  header: {
    display: "flex",
    flexDirection: "column",
    margin: theme.spacing(2.5),
    rowGap: theme.spacing(2.5),
  },
  headerRow: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  alignRight: {
    justifyContent: "flex-end",
  },
  headerActions: {
    display: "flex",
    alignItems: "center",
    gap: "6px",
  },
  actionsAndLegend: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  flex: {
    display: "flex",
  },
  title: {
    "& > h3": {
      color: theme.palette.midnight,
    },
    display: "flex",
    alignItems: "center",
    gap: theme.spacing(1.5),
  },
  actionButtonsAndToggle: {
    display: "flex",
    alignItems: "center",
    gap: theme.spacing(1),
    // This is set to avoid flickering when switching from/to partners view
    height: 36,
  },
}));

// I18N

const i18n = defineMessages({
  title: {
    id: "MyPipeline.title",
    defaultMessage: "Collaborate",
  },
  showArchived: {
    id: "MyPipeline.showArchived",
    defaultMessage: "Show archived",
  },
});
