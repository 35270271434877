import Match from "models/Match";
import {
  ColumnConfigType,
  MatchFilterType,
  orderColumns,
} from "screens/Frontoffice/screens/DataTables/shared/types";

export enum AvailablePipelineColumns {
  createdAt = "createdAt",
  rawCompanyName = "rawCompanyName",
  rawCompanyStatus = "rawCompanyStatus",
  rawCompanyOwnerId = "rawCompanyOwnerId",
  category = "category",
  from = "requesterFullName",
  partnership = "partnership",
  kind = "kind",
  stage = "stage",
  activityDate = "activityDate",
  externalComment = "externalComment",
  rawOpportunityStatus = "rawOpportunityStatus",
  discussionParticipants = "discussionParticipants",
}

const defaultPipelineColumnKeys = [
  AvailablePipelineColumns.rawCompanyName,
  AvailablePipelineColumns.externalComment,
  AvailablePipelineColumns.discussionParticipants,
  AvailablePipelineColumns.createdAt,
  AvailablePipelineColumns.stage,
  AvailablePipelineColumns.kind,
  AvailablePipelineColumns.activityDate,
  AvailablePipelineColumns.rawCompanyStatus,
  AvailablePipelineColumns.rawCompanyOwnerId,
  AvailablePipelineColumns.category,
  AvailablePipelineColumns.from,
  AvailablePipelineColumns.partnership,
  AvailablePipelineColumns.rawOpportunityStatus,
];

export const pipelineColumnConfig: {
  [key: string]: ColumnConfigType;
} = {
  [AvailablePipelineColumns.rawCompanyName]: {
    key: AvailablePipelineColumns.rawCompanyName,
    width: 360,
    frozen: true,
    removable: false,
    fixedPosition: 1,
  },
  [AvailablePipelineColumns.discussionParticipants]: {
    key: AvailablePipelineColumns.discussionParticipants,
    width: 120,
    frozen: false,
    removable: true,
  },
  [AvailablePipelineColumns.createdAt]: {
    key: AvailablePipelineColumns.createdAt,
    width: 150,
    frozen: false,
    removable: true,
  },
  [AvailablePipelineColumns.rawCompanyStatus]: {
    key: AvailablePipelineColumns.rawCompanyStatus,
    width: 150,
    frozen: false,
    removable: true,
  },
  [AvailablePipelineColumns.rawCompanyOwnerId]: {
    key: AvailablePipelineColumns.rawCompanyOwnerId,
    width: 93,
    frozen: false,
    removable: true,
  },
  [AvailablePipelineColumns.partnership]: {
    key: AvailablePipelineColumns.partnership,
    width: 140,
    frozen: false,
    removable: true,
  },
  [AvailablePipelineColumns.activityDate]: {
    key: AvailablePipelineColumns.activityDate,
    width: 144,
    frozen: false,
    removable: true,
  },
  [AvailablePipelineColumns.stage]: {
    key: AvailablePipelineColumns.stage,
    width: 132,
    frozen: false,
    removable: true,
  },
  [AvailablePipelineColumns.kind]: {
    key: AvailablePipelineColumns.kind,
    width: 124,
    frozen: false,
    removable: true,
  },
  [AvailablePipelineColumns.externalComment]: {
    key: AvailablePipelineColumns.externalComment,
    width: 93,
    frozen: false,
    removable: true,
  },
  [AvailablePipelineColumns.rawOpportunityStatus]: {
    key: AvailablePipelineColumns.rawOpportunityStatus,
    width: 390,
    frozen: false,
    removable: true,
  },
  [AvailablePipelineColumns.category]: {
    key: AvailablePipelineColumns.category,
    width: 150,
    frozen: false,
    removable: true,
  },
  [AvailablePipelineColumns.from]: {
    key: AvailablePipelineColumns.from,
    width: 160,
    frozen: false,
    removable: true,
  },
};

export const defaultPipelineColumnConfig = (
  key: string,
  field: { label: string }
) => ({
  key,
  width: 100,
  frozen: false,
});

export const applyPipelineColumnOrder = (columns: ColumnConfigType[]) =>
  orderColumns(columns);

export const defaultPipelineColumns = applyPipelineColumnOrder(
  defaultPipelineColumnKeys.map((key) => pipelineColumnConfig[key])
);

export const defaultPipelineFilters = [
  {
    fieldname: "perspective",
    type: MatchFilterType.IS,
    value: Match.PERSPECTIVE_MINE,
  },
  {
    fieldname: "archived",
    type: MatchFilterType._NO_OPERATOR,
    value: false,
  },
];
export const defaultPipelineSort = [];

export const getDefaultPipelineFilters = (userId: number) => [
  ...defaultPipelineFilters,
  {
    fieldname: "user",
    type: MatchFilterType.ANY_OF,
    value: [userId + ""],
  },
];

export const PIPELINE_FIELDS_WITH_YOU_TAG = [
  AvailablePipelineColumns.rawCompanyStatus,
  AvailablePipelineColumns.rawCompanyOwnerId,
];
