import { CloseModal, Directory, PersonStar } from "components/icons";
import Button from "components/ui/Button";
import DialogManager from "components/ui/DialogManager";
import { T } from "components/ui/Typography";
import muiTheme from "config/theme";
import useUserProfile from "hooks/useUserProfile";
import { makeStyles } from "makeStyles";
import { useEffect } from "react";
import {
  defineMessages,
  FormattedHTMLMessage,
  FormattedMessage,
} from "react-intl";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { update } from "redux/api/thunks";
import { fetchProfileInBackground } from "redux/user/thunks";

type Props = {
  onClose: () => void;
};

export const FinalStepDialog = ({ onClose }: Props) => {
  const { classes } = useStyles();
  const { classes: modalStyle } = useModalStyles();
  const { profile } = useUserProfile();
  const history = useHistory();
  const dispatch = useDispatch();

  const goToDemoAccountMapping = () => {
    onClose();
    history.replace({
      pathname: "/partnerships/demo/account-mapping",
      hash: "get-started",
    });
  };

  const goToDirectory = () => {
    onClose();
    history.replace({
      pathname: "/directory",
      hash: "get-started",
    });
  };

  useEffect(() => {
    if (!profile.company.finalOnboardingStepCompleted) {
      (async () => {
        await dispatch(
          update({
            id: profile.company.id,
            type: "companies",
            attributes: { final_onboarding_step_completed: true },
          })
        );
        dispatch(fetchProfileInBackground());
      })();
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <DialogManager
      modalStyle={modalStyle}
      maxWidth="md"
      isOpen={true}
      handleClose={onClose}
      DialogGlobalComponent={
        <div className={classes.container}>
          <div className={classes.closeBtn} onClick={onClose}>
            <CloseModal />
          </div>
          <div className={classes.titleSection}>
            <img
              src={`/images/checkCircleGreenBig.png`}
              alt=""
              className={classes.check}
            />
            <T h2 bold>
              <FormattedMessage
                {...i18n.title}
                values={{ name: profile.firstName }}
              />
            </T>
            <T bodyBig color={muiTheme.palette.alpha500}>
              <FormattedMessage {...i18n.subtitle} />
            </T>
          </div>
          <div className={classes.boxContainer}>
            <div className={classes.box}>
              <PersonStar />
              <T h4 bold>
                <FormattedMessage {...i18n.accountMapping} />
              </T>
              <T labelSmall className={classes.boxDecription}>
                <FormattedHTMLMessage {...i18n.leftDescription} />
              </T>
              <Button
                onClick={goToDemoAccountMapping}
                label={i18n.leftButton}
              />
            </div>
            <div className={classes.box}>
              <Directory />
              <T h4 bold>
                <FormattedMessage {...i18n.directory} />
              </T>
              <T labelSmall className={classes.boxDecription}>
                <FormattedHTMLMessage {...i18n.rightDescription} />
              </T>
              <Button onClick={goToDirectory} label={i18n.rightButton} />
            </div>
          </div>
        </div>
      }
    />
  );
};

const useStyles = makeStyles()((theme) => ({
  container: {
    width: "100%",
    height: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    gap: 32,
    padding: 8,
    "& button": {
      width: 300,
    },
  },
  titleSection: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    gap: 6,
  },
  check: {
    width: 40,
    height: 40,
    marginBottom: 6,
  },
  closeBtn: {
    position: "absolute",
    top: 12,
    right: 12,
    cursor: "pointer",
  },
  boxContainer: {
    display: "flex",
    columnGap: 12,
    width: "100%",
  },
  box: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
    padding: 24,
    rowGap: 12,
    borderRadius: 8,
    border: `1px solid ${theme.palette.greyLight100}`,
  },
  boxDecription: {
    color: theme.palette.alpha500,
    lineHeight: "18px",
    textAlign: "center",
    marginBottom: 12,
  },
}));

const useModalStyles = makeStyles()((theme) => ({
  dialogContainer: {
    "& .MuiDialog-container .MuiPaper-root": {
      borderRadius: 12,
    },
  },
}));

const i18n = defineMessages({
  title: {
    id: "FinalStepDialog.title",
    defaultMessage: "Congrats {name}, you’re all set up!",
  },
  subtitle: {
    id: "FinalStepDialog.subtitle",
    defaultMessage:
      "Connect and collaborate with unlimited partners - free forever",
  },
  next: {
    id: "FinalStepDialog.next",
    defaultMessage: "Next",
  },
  accountMapping: {
    id: "FinalStepDialog.accountMapping",
    defaultMessage: "Account mapping",
  },
  leftDescription: {
    id: "FinalStepDialog.leftDescription",
    defaultMessage:
      "Instantly see common accounts and new<br/>opportunities with partners.",
  },
  leftButton: {
    id: "FinalStepDialog.leftButton",
    defaultMessage: "Discover Account mapping",
  },
  directory: {
    id: "FinalStepDialog.directory",
    defaultMessage: "Directory",
  },
  rightDescription: {
    id: "FinalStepDialog.rightDescription",
    defaultMessage:
      "Connect with existing partners or expand your<br/>ecosystem with new partners.",
  },
  rightButton: {
    id: "FinalStepDialog.rightButton",
    defaultMessage: "Browse Directory",
  },
});
