import FiberManualRecord from "@mui/icons-material/FiberManualRecord";
import { T } from "components/ui/Typography";
import { makeStyles } from "makeStyles";
import { defineMessages, FormattedMessage } from "react-intl";

type Props = {
  side: "left" | "right";
};

const SideTitle = ({ side }: Props) => {
  const { classes, cx } = useStyles();

  return (
    <div className={classes.root}>
      <FiberManualRecord
        className={cx(classes.dot, {
          [classes.you]: side === "left",
          [classes.partner]: side === "right",
        })}
      />
      <T uppercase>
        <FormattedMessage {...(side === "left" ? i18n.you : i18n.partner)} />
      </T>
    </div>
  );
};

export default SideTitle;

// I18N

const i18n = defineMessages({
  you: {
    id: "crm.DataTables.Mapping360.you",
    defaultMessage: "you",
  },
  partner: {
    id: "crm.DataTables.Mapping360.partner",
    defaultMessage: "partner",
  },
});

/// CSS

const useStyles = makeStyles()((theme) => ({
  root: {
    display: "flex",
    alignItems: "center",
    gap: 6,
    marginTop: 3,
  },
  dot: {
    height: 10,
    width: 10,
  },
  you: {
    color: theme.palette.purple500,
  },
  partner: {
    color: theme.palette.green500,
  },
}));
