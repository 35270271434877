import { Box } from "@mui/material";
import { Sidebar } from "components/icons";
import { T } from "components/ui/Typography";
import { makeStyles } from "makeStyles";
import { defineMessages, FormattedMessage } from "react-intl";

export const SidebarHover = () => {
  const { classes, cx } = useStyles();
  return (
    <Box className={cx(classes.container, "showOnHover")}>
      <Sidebar className={classes.icon} />
      <T>
        <FormattedMessage {...i18n.open} />
      </T>
    </Box>
  );
};

const useStyles = makeStyles()((theme) => ({
  container: {
    alignItems: "center",
    border: `1px solid ${theme.palette.greyscale250}`,
    borderRadius: 4,
    display: "none",
    gap: 6,
    height: 24,
    padding: 4,
    width: 65,
  },
  icon: {
    height: 12,
    width: 14,
  },
}));

const i18n = defineMessages({
  open: {
    id: "crm.AccountMapping.CellFormatters.SidebarHover.open",
    defaultMessage: "Open",
  },
});
