import { withCompanyPayingFeatures } from "components/hoc/withCompanyPayingFeatures";
import withCompanyPermissions from "components/hoc/withCompanyPermissions";
import { WithUserProfile } from "components/hoc/withUserProfile";
import { SelectedRowsProvider } from "components/ui/data-grid/SelectedRowsContext";
import { FilterWidget } from "components/ui/filters/filter/FilterWidget";
import { T } from "components/ui/Typography";
import useSegment from "hooks/useSegment";
import { makeStyles } from "makeStyles";
import { PayingFeature } from "models/CompanyPayingFeatureSubscription";
import PipelineOpportunity from "models/PipelineOpportunity";
import { RecordType } from "models/types";
import { useCallback, useEffect, useState } from "react";
import { defineMessages, FormattedMessage } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { RouteComponentProps } from "react-router";
import { selectDashboardData } from "redux/dashboard/selectors";
import { initializePartnershipsAndCompanyNames } from "redux/init/thunks";
import { AvailablePipelineAttributeColumns } from "redux/pipelineAttribute/defaults";
import { selectPipelineAttributeSlice } from "redux/pipelineAttribute/selectors";
import { loadView, updateView } from "redux/pipelineAttribute/thunks";
import { initializePipelineAttributeFields } from "redux/pipelineAttribute/thunks";
import { setOwnerFilter } from "redux/pipelineAttribute/thunks";
import { formatRangeFilters } from "redux/utils";
import DateRangeFilter from "screens/Frontoffice/shared/components/DateRangeFilter";
import { PipelineEvent } from "tracking";

import { OwnerFilter } from "../../../shared/components/OwnerFilter";
import PartnerFilter from "../../../shared/components/PartnerFilter";
import { useTableRows } from "../../../shared/hooks";
import { DataTableType, FilterType } from "../../../shared/types";
import { PipelineViewParameters } from "../constants";
import { MyPipelineAttributeTable } from "./MyPipelineAttributeTable";
import OpportunityStatusFilter from "./OpportunityStatusFilter";

type Params = {
  partnershipId?: string;
};

const MyPipelineAttribute = ({ match }: RouteComponentProps<Params>) => {
  const { classes } = useStyles();
  const { track } = useSegment();
  const dispatch = useDispatch();
  const { ready: dashboardReady } = useSelector(selectDashboardData);
  const { ready, view } = useSelector(selectPipelineAttributeSlice);
  const { filters, sort } = view ?? {};
  const [partnerSelectorIsOpen, setPartnerSelectorIsOpen] = useState(false);
  const { partnershipId } = getParams(match.params);

  const formattedFilters = formatRangeFilters(filters);

  const [openedWidget, setOpenedWidget] = useState<string | null>(null);

  const {
    sync,
    records,
    count,
    fetching,
    fields,
    loadMore,
    refresh,
  } = useTableRows(
    RecordType.PipelineOpportunity,
    formattedFilters,
    sort,
    null,
    true,
    [],
    undefined,
    undefined,
    undefined,
    false,
    true
  );

  const rows = records as PipelineOpportunity[];

  const setView = useCallback(
    (value: PipelineViewParameters) => dispatch(updateView(value)),
    [dispatch]
  );

  const setFilters = useCallback(
    (filters: FilterType[]) => {
      setView({ filters });
    },
    [setView]
  );

  useEffect(() => {
    dispatch(initializePipelineAttributeFields());
  }, [dispatch]);

  useEffect(() => {
    dispatch(loadView());
  }, [dispatch]);

  useEffect(() => {
    if (!dashboardReady) {
      dispatch(initializePartnershipsAndCompanyNames());
    }
  }, [dashboardReady, dispatch]);

  useEffect(() => {
    track(PipelineEvent.viewPipeline);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (ready && !!sort?.length) {
      // Reset sort to default on first table load if sort applied
      setView({ sort: [] });
    }
  }, [ready]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <SelectedRowsProvider>
      <div className={classes.root}>
        <div className={classes.header}>
          <div className={classes.title}>
            <T h3 bold>
              <FormattedMessage {...i18n.title} />
            </T>
          </div>
          <div className={classes.headerRow}>
            <div className={classes.headerActions}>
              <OpportunityStatusFilter setView={setView} />
              <PartnerFilter
                partnerSelectorIsOpen={partnerSelectorIsOpen}
                setPartnerSelectorIsOpen={setPartnerSelectorIsOpen}
                selectedPartnershipId={partnershipId}
                loadingPartners={!dashboardReady}
              />
              <OwnerFilter
                field={fields[AvailablePipelineAttributeColumns.owner]}
                setOwnerFilter={setOwnerFilter}
                value={
                  filters?.find(
                    (filter) =>
                      filter.fieldname ===
                      AvailablePipelineAttributeColumns.owner
                  )?.value ?? null
                }
              />
              <DateRangeFilter />
              <FilterWidget
                disabled={false}
                fields={fields}
                filters={filters ?? []}
                onFilterUpdate={setFilters}
                openedWidget={openedWidget}
                setOpenedWidget={setOpenedWidget}
                viewType={DataTableType.ATTRIBUTE}
              />
            </div>
          </div>
        </div>
        <MyPipelineAttributeTable
          rows={!ready ? [] : rows}
          rowsCount={count}
          fields={fields}
          loading={!ready || !sync || fetching || count === null}
          loadMore={loadMore}
          fetching={fetching}
          setView={setView}
          refreshTable={refresh}
        />
      </div>
    </SelectedRowsProvider>
  );
};

export default withCompanyPayingFeatures([PayingFeature.AttributeEnabled])(
  withCompanyPermissions<WithUserProfile & RouteComponentProps<Params>>([
    "company.owns_pipeline",
  ])(MyPipelineAttribute)
);

const getParams = (params: Params) => {
  const partnershipId = params.partnershipId || "";

  return {
    partnershipId:
      partnershipId === "" || isNaN(+partnershipId) ? null : +partnershipId,
  };
};

// CSS

const useStyles = makeStyles()((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    height: "100%",
  },
  header: {
    display: "flex",
    flexDirection: "column",
    margin: theme.spacing(2.5),
    rowGap: theme.spacing(2.5),
  },
  headerRow: {
    display: "flex",
    alignItems: "center",
    justifyContent: "right",
  },
  headerActions: {
    display: "flex",
    alignItems: "center",
    gap: "6px",
  },
  actionsAndLegend: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  flex: {
    display: "flex",
  },
  title: {
    "& > h3": {
      color: theme.palette.midnight,
    },
    display: "flex",
    alignItems: "center",
    gap: theme.spacing(1.5),
  },
  actionButtonsAndToggle: {
    display: "flex",
    alignItems: "center",
    gap: theme.spacing(1),
    // This is set to avoid flickering when switching from/to partners view
    height: 36,
  },
}));

// I18N

const i18n = defineMessages({
  title: {
    id: "MyPipelineAttribute.title",
    defaultMessage: "Attribute",
  },
});
