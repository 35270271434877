import { ChevronDown, Settings } from "components/icons";
import Button from "components/ui/Button";
import Dropdown from "components/ui/Dropdown";
import { kindEncodeMapping } from "models/PartnerConnection";
import { useState } from "react";
import { defineMessages, useIntl } from "react-intl";

import { SharingLevel } from "../utils";

export const SharingLevelDropdown = ({
  onChange,
  onModalOpen,
  value,
  kind,
}: {
  kind: kindEncodeMapping;
  onModalOpen: () => void;
  value: SharingLevel;
  onChange: (level: SharingLevel, kind: kindEncodeMapping) => Promise<void>;
}) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const intl = useIntl();

  const handleChange = async (value: string[] | null) => {
    if (!value) return;
    setAnchorEl(null);
    await onChange(+value[0], kind);
  };

  const handleOpenModal = () => {
    setAnchorEl(null);
    onModalOpen();
  };

  if (!value) {
    return null;
  }

  return (
    <>
      <Button
        label={i18n[`sharingLevel${value}`]}
        onClick={(event) => setAnchorEl(event.currentTarget)}
        RightIcon={ChevronDown}
        size="xSmall"
        variant="quinary"
      />
      <Dropdown
        value={[value?.toString()]}
        options={[
          {
            id: SharingLevel.None.toString(),
            name: intl.formatMessage(i18n[`sharingLevel${SharingLevel.None}`]),
          },
          {
            id: SharingLevel.Limited.toString(),
            name: intl.formatMessage(
              i18n[`sharingLevel${SharingLevel.Limited}`]
            ),
          },
          {
            id: SharingLevel.Full.toString(),
            name: intl.formatMessage(i18n[`sharingLevel${SharingLevel.Full}`]),
          },
        ]}
        anchorEl={anchorEl}
        onClose={() => setAnchorEl(null)}
        open={Boolean(anchorEl)}
        onChange={handleChange}
        size="small" // TODO: check the width with product
        withSearch={false}
        CustomFooter={
          <Button
            label={i18n.configure}
            size="small"
            variant="tertiary"
            LeftIcon={Settings}
            onClick={handleOpenModal}
            ellipsis
          />
        }
      />
    </>
  );
};

const i18n = defineMessages({
  configure: {
    id: "SharingLevelDropdown.configure",
    defaultMessage: "Configure Settings",
  },
  [`sharingLevel${SharingLevel.None}`]: {
    id: "SharingLevelDropdown.sharingLevel.None",
    defaultMessage: "No Sharing",
  },
  [`sharingLevel${SharingLevel.Limited}`]: {
    id: "SharingLevelDropdown.sharingLevel.Limited",
    defaultMessage: "Limited Sharing",
  },
  [`sharingLevel${SharingLevel.Full}`]: {
    id: "SharingLevelDropdown.sharingLevel.Full",
    defaultMessage: "Transparent Sharing",
  },
});
