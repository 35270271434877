import { Theme, useTheme } from "@mui/material/styles";
import { PieChart } from "@mui/x-charts";
import { Info } from "components/icons";
import Tooltip from "components/ui/Tooltip";
import { T } from "components/ui/Typography";
import { ConfigContext } from "config/ConfigProvider";
import useAmountFormatter from "hooks/useAmountFormatter";
import generic from "i18n/generic";
import { makeStyles } from "makeStyles";
import SummaryMetrics from "models/SummaryMetrics";
import { useContext } from "react";
import {
  defineMessages,
  FormattedMessage,
  IntlShape,
  useIntl,
} from "react-intl";

export const TotalMetricsBox = ({
  summaryMetrics,
}: {
  summaryMetrics: SummaryMetrics | null;
}) => {
  const { classes } = useStyles();
  const theme = useTheme();
  const intl = useIntl();
  const amountFormatter = useAmountFormatter(intl);
  const config = useContext(ConfigContext);

  const tooltip = (
    <T>
      <FormattedMessage {...i18n.tooltip} />
      <span>
        &nbsp;
        <a
          className={classes.tooltipLink}
          target="_blank"
          href={`${config.partnerAnalyticsDashboardLink}`}
          rel="noopener noreferrer"
        >
          <FormattedMessage {...generic.learn_more} />
        </a>
      </span>
    </T>
  );

  const noDataTooltip = (
    <T>
      <FormattedMessage {...i18n.noData} />
    </T>
  );

  return (
    <div className={classes.root}>
      {summaryMetrics !== null && (
        <PieChart
          series={[
            {
              data: getData(summaryMetrics, intl, theme),
              cx: 25,
              cy: 30,
              innerRadius: 18,
              outerRadius: 30,
              valueFormatter: (value) => amountFormatter(value.value, "USD"),
            },
          ]}
          width={60}
          height={70}
          legend={{ hidden: true }}
        />
      )}
      <div className={classes.container}>
        <div className={classes.title}>
          <T bold>
            <FormattedMessage {...i18n.title} />
          </T>
          <Tooltip title={tooltip}>
            <Info className={classes.infoIcon} />
          </Tooltip>
        </div>
        <Tooltip title={summaryMetrics === null && noDataTooltip}>
          <T h2 bold className={classes.amountContainer}>
            {summaryMetrics === null
              ? "--"
              : amountFormatter(
                  summaryMetrics.totalRevenuePotential
                    ? summaryMetrics.totalRevenuePotential
                    : undefined,
                  "USD"
                )}
          </T>
        </Tooltip>
      </div>
    </div>
  );
};

// Helpers

const getData = (
  summaryMetrics: SummaryMetrics,
  intl: IntlShape,
  theme: Theme
) => {
  return [
    {
      label: intl.formatMessage(i18n.leadGen),
      value: summaryMetrics?.newProspectsRevenuePotential ?? 0,
      color: theme.palette.pie1,
    },
    {
      label: intl.formatMessage(i18n.source),
      value: summaryMetrics?.prospectsNoOpportunitiesRevenuePotential ?? 0,
      color: theme.palette.pie2,
    },
    {
      label: intl.formatMessage(i18n.influence),
      value: summaryMetrics?.prospectsWithOpportunitiesRevenuePotential ?? 0,
      color: theme.palette.purple,
    },
  ];
};

/// CSS

const useStyles = makeStyles()((theme) => ({
  root: {
    display: "flex",
    width: "100%",
    columnGap: 12,
  },
  container: {
    display: "flex",
    height: "100%",
    width: "100%",
    flexDirection: "column",
    justifyContent: "space-between",
  },
  title: {
    display: "flex",
    width: "100%",
    justifyContent: "space-between",
    alignItems: "center",
  },
  infoIcon: {
    fontSize: 16,
    color: theme.palette.greyscale650,
  },
  tooltipLink: {
    color: theme.palette.ivory,
    textDecoration: "underline",
    "&:hover": {
      cursor: "pointer",
      color: theme.palette.ivory,
      textDecorationColor: theme.palette.ivory,
    },
  },
  amountContainer: {
    display: "flex",
    width: "fit-content",
  },
}));

// I18N

const i18n = defineMessages({
  title: {
    id: "Analytics.SummaryMetrics.TotalMetricsBox.title",
    defaultMessage: "Partner Addressable Revenue",
  },
  leadGen: {
    id: "Analytics.SummaryMetrics.TotalMetricsBox.leadGen",
    defaultMessage: "Lead gen",
  },
  source: {
    id: "Analytics.SummaryMetrics.TotalMetricsBox.source",
    defaultMessage: "Source",
  },
  influence: {
    id: "Analytics.SummaryMetrics.TotalMetricsBox.influence",
    defaultMessage: "Influence",
  },
  tooltip: {
    id: "Analytics.SummaryMetrics.TotalMetricsBox.tooltip",
    defaultMessage: "The total revenue potential across all of my partners.",
  },
  noData: {
    id: "Analytics.SummaryMetrics.MetricsBox.noData",
    defaultMessage: "The  metric is still computing.",
  },
});
