import copy from "clipboard-copy";
import withCompanyPermissions from "components/hoc/withCompanyPermissions";
import { WithUserProfile } from "components/hoc/withUserProfile";
import { Launch, Link } from "components/icons";
import Button from "components/ui/Button";
import PageForbidden from "components/ui/PageForbidden";
import Tooltip from "components/ui/Tooltip";
import TwoSided from "components/ui/TwoSided/TwoSided";
import { T } from "components/ui/Typography";
import getFirstValueFromURL from "helpers/getFirstValueFromURL";
import useAllRecords from "hooks/useAllRecords";
import usePushNotification from "hooks/usePushNotification";
import useSegment from "hooks/useSegment";
import useUserProfile from "hooks/useUserProfile";
import generic from "i18n/generic";
import { makeStyles } from "makeStyles";
import { useState } from "react";
import {
  defineMessages,
  FormattedHTMLMessage,
  FormattedMessage,
} from "react-intl";
import { useSelector } from "react-redux";
import { RouteComponentProps } from "react-router";
import { selectActivePayingFeatures } from "redux/api/selectors";
import InviteForm from "screens/Frontoffice/shared/components/InviteForm";
import { InviteURLEvent } from "tracking";

type Props = RouteComponentProps;
const Invite = ({ history }: Props) => {
  const payingFeatures = useSelector(selectActivePayingFeatures);
  const { classes } = useStyles();
  const { track } = useSegment();
  const pushNotification = usePushNotification();
  const { profile } = useUserProfile();
  const [copied, setCopied] = useState(false);
  const fromSearch = getFirstValueFromURL(history.location, "fromSearch");
  const onboarding =
    getFirstValueFromURL(history.location, "onboarding") === "true";

  const { records } = useAllRecords("invitation_urls");
  const invitationURL = records[0] ?? null;

  const handleCopy = async () => {
    try {
      await copy(invitationURL.getInvitationURL());
      setCopied(true);
      track(InviteURLEvent.linkCopied, {
        invitationId: invitationURL.invitationId,
      });
    } catch {
      pushNotification("default_error");
    }
  };

  const isInIframe = () => {
    // try catch instead of ternary to provide error handling for cross-origin scenarios.
    try {
      return window.self !== window.top;
    } catch (e) {
      return true;
    }
  };

  if (!profile.canInvitePartner(payingFeatures)) {
    return <PageForbidden />;
  }
  return (
    <TwoSided
      classes={{
        leftPanelContainer: classes.leftPanelContainer,
        rightPanel: classes.rightPanel,
      }}
      left={
        <>
          <div className={classes.leftContent}>
            <T h2 bold>
              <FormattedMessage {...i18n.titleExample} />
            </T>
            <T bodyBig className={classes.marginBottom}>
              <FormattedHTMLMessage {...i18n.paragraph} />
            </T>
            <a
              className={classes.link}
              target="_blank"
              href={
                "https://intercom.help/Reveal4business/en/articles/4836122-manage-what-you-share-with-partners"
              }
              rel="noopener noreferrer"
            >
              <div className={classes.row}>
                <Launch className={classes.icon} />
                <T className={classes.linkText}>
                  <FormattedMessage {...i18n.howToManage} />
                </T>
              </div>
            </a>
            <a
              className={classes.link}
              target="_blank"
              href={
                "https://intercom.help/Reveal4business/en/collections/3519143-security-and-data-privacy"
              }
              rel="noopener noreferrer"
            >
              <div className={classes.row}>
                <Launch className={classes.icon} />
                <T className={classes.linkText}>
                  <FormattedMessage {...i18n.securityPrivacy} />
                </T>
              </div>
            </a>
          </div>
        </>
      }
      right={
        <>
          <T h2 bold className={classes.title}>
            <FormattedMessage
              {...(onboarding ? i18n.titleOnboarding : i18n.title)}
            />
            {invitationURL !== null && (
              <Tooltip
                title={copied ? <FormattedMessage {...generic.copied} /> : ""}
              >
                <div className={isInIframe() ? classes.iframeUrlContainer : ""}>
                  <Button
                    size="small"
                    variant="quinary"
                    LeftIcon={Link}
                    onClick={handleCopy}
                    label={i18n.copyInviteLink}
                  />
                  {isInIframe() && <T>{invitationURL.getInvitationURL()}</T>}
                </div>
              </Tooltip>
            )}
          </T>
          <InviteForm
            onInviteSuccess={() => history.push("/partnerships")}
            initial={fromSearch}
          />
        </>
      }
    />
  );
};

export default withCompanyPermissions<
  WithUserProfile & RouteComponentProps<{}>
>(["company.partnership_invite"])(Invite);

/// I18N

const i18n = defineMessages({
  howToManage: {
    id: "Invite.howToManage",
    defaultMessage: "How to manage your sharing settings",
  },
  securityPrivacy: {
    id: "Invite.securityPrivacy",
    defaultMessage: "Read data security & privacy",
  },
  titleExample: {
    id: "Invite.TitleExample",
    defaultMessage: "Inviting is not sharing",
  },
  title: {
    id: "Invite.Title",
    defaultMessage: "Invite a partner",
  },
  titleOnboarding: {
    id: "Invite.TitleOnboarding",
    defaultMessage: "Invite your first partner",
  },
  paragraph: {
    id: "Invite.Paragraph",
    defaultMessage:
      "Inviting your partner will give you access to high-level statistics, allowing you to assess the partnership's potential.<br/><br/>You can decide <b>if and what you're willing to share</b> later.",
  },
  copyInviteLink: {
    id: "Invite.copyInviteLink",
    defaultMessage: "Copy invite link",
  },
});

/// CSS

const useStyles = makeStyles()((theme) => ({
  icon: {
    color: theme.palette.ivory,
    height: 18,
    width: 12,
  },
  leftContent: {
    color: theme.palette.offWhite,
    zIndex: 1,
    display: "flex",
    flexDirection: "column",
    gap: 10,
    justifyContent: "center",
    alignItems: "flex-start",
    maxWidth: 600,
    textAlign: "left",
  },
  leftPanelContainer: {
    alignItems: "flex-start",
    padding: `${theme.spacing(4)} ${theme.spacing(4)}`,
  },
  leftPanelTile: {
    color: theme.palette.text.primary,
    textAlign: "left",
  },
  link: {
    color: theme.palette.ivory,
    textDecoration: "underline",
    "&:hover": {
      cursor: "pointer",
      textDecorationColor: theme.palette.primary.main,
    },
  },
  linkText: {
    color: theme.palette.ivory,
    fontWeight: 600,
  },
  marginBottom: {
    marginBottom: theme.spacing(2),
  },
  title: {
    marginLeft: theme.spacing(1),
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    "& svg": {
      fontSize: 12,
    },
  },
  rightPanel: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    padding: "0 50px",
    paddingBottom: 0,
  },
  row: {
    alignItems: "flexStart",
    display: "flex",
    gap: 10,
  },
  rvlProfile: {
    position: "absolute",
    left: "10%",
    right: "30%",
    top: "10%",
    bottom: "45%",
    background: theme.palette.darkPurple,
    borderRadius: "999px 999px 0 0",
  },
  iframeUrlContainer: {
    position: "relative",
    "& > p": {
      position: "absolute",
      right: 12,
      top: 28,
      fontWeight: 500,
    },
  },
}));
