import { makeStyles } from "makeStyles";

type Props = {
  openSidebar: () => void;
  showName?: boolean;
};

const Logo = ({ openSidebar, showName = true }: Props) => {
  const { classes } = useStyles();
  return (
    <a className={classes.root} href={"/"} onMouseEnter={openSidebar}>
      <img
        className={classes.logo}
        src={"/images/reveal-icon-purple.png"}
        alt=""
      />
      {showName && (
        <img
          className={classes.name}
          src={"/images/reveal-logotype-purple.png"}
          alt=""
        />
      )}
    </a>
  );
};

export default Logo;

/// CSS

const useStyles = makeStyles()((theme) => ({
  root: {
    display: "flex",
    alignItems: "center",
    marginBottom: theme.spacing(3),
    marginLeft: theme.spacing(0.5),
    marginTop: theme.spacing(1),
    overflowX: "hidden",
  },
  logo: {
    width: 23,
    height: 26,
  },
  name: {
    width: 83,
    height: 13,
    marginLeft: 12,
  },
}));
