import { ChevronDown, Pause, Trash } from "components/icons";
import Button from "components/ui/Button";
import DropdownOption from "components/ui/DropdownOption";
import DropdownPopover from "components/ui/DropdownPopover";
import { T } from "components/ui/Typography";
import useUserProfile from "hooks/useUserProfile";
import { makeStyles } from "makeStyles";
import Partnership from "models/Partnership";
import { useState } from "react";
import { defineMessages, FormattedMessage } from "react-intl";
import PartnershipStatusDialog, {
  DialogType,
} from "screens/Frontoffice/screens/Partners/shared/components/PartnershipStatusDialog";

type Props = {
  partnership: Partnership;
  saving: boolean;
  setSaving: (saving: boolean) => void;
};

const PartnershipStatus = ({ partnership, saving, setSaving }: Props) => {
  const [dialogType, setDialogType] = useState<DialogType | null>(null);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState<
    HTMLButtonElement | HTMLDivElement | null
  >(null);
  const { profile } = useUserProfile();

  const isPartnershipActive = !partnership.isPausedByCompany(profile.company);
  const hasNoMenuItems = partnership.isGhost() && !profile.isCompanyAdmin();
  const { classes } = useStyles({ hasNoMenuItems, isPartnershipActive });

  const handleOpenDialog = (dialogType: DialogType) => () => {
    setDialogOpen(true);
    setAnchorEl(null);
    setDialogType(dialogType);
  };

  const handleTogglePartnershipStatusMenu = (status: boolean) => (
    event: React.MouseEvent<HTMLDivElement | HTMLButtonElement>
  ) => {
    if (hasNoMenuItems) {
      return;
    }
    if (status) {
      setAnchorEl(event.currentTarget);
      return;
    }
    setAnchorEl(null);
  };

  return (
    <>
      <Button
        disabled={saving}
        label={
          <FormattedMessage
            {...(isPartnershipActive
              ? i18n.activePartnership
              : i18n.pausedPartnership)}
          />
        }
        size="xxSmall"
        onClick={handleTogglePartnershipStatusMenu(true)}
        RightIcon={!hasNoMenuItems ? ChevronDown : undefined}
        classes={{
          primary: classes.pauseBtn,
          labelContent: classes.labelContent,
        }}
      />
      <DropdownPopover
        anchorEl={anchorEl}
        onClose={handleTogglePartnershipStatusMenu(false)}
        open={Boolean(anchorEl)}
        position="left"
      >
        {!partnership.isGhost() && (
          <DropdownOption
            onClick={handleOpenDialog(DialogType.Activate)}
            classes={{ option: classes.option }}
          >
            <Pause className={classes.icon} />
            <T>
              <FormattedMessage
                {...(isPartnershipActive
                  ? i18n.pausePartnership
                  : i18n.activatePartnership)}
              />
            </T>
          </DropdownOption>
        )}
        {profile.canDeletePartnerships && (
          <DropdownOption
            onClick={handleOpenDialog(DialogType.Delete)}
            classes={{ option: classes.option }}
          >
            <Trash className={classes.icon} />
            <T>
              <FormattedMessage {...i18n.deletePartnership} />
            </T>
          </DropdownOption>
        )}
      </DropdownPopover>
      {!!dialogType && (
        <PartnershipStatusDialog
          isOpen={dialogOpen}
          onClose={() => {
            setDialogOpen(false);
            setDialogType(null);
          }}
          setLoading={setSaving}
          partnership={partnership}
          type={dialogType}
        />
      )}
    </>
  );
};

export default PartnershipStatus;

// CSS

const useStyles = makeStyles<{
  hasNoMenuItems: boolean;
  isPartnershipActive: boolean;
}>()((theme, { hasNoMenuItems, isPartnershipActive }) => ({
  icon: {
    height: 13,
  },
  option: {
    flexDirection: "row",
    alignItems: "center",
  },
  labelContent: {
    fontWeight: "500 !important",
    fontSize: "10px !important",
    letterSpacing: "0.02em",
    textTransform: "uppercase",
  },
  pauseBtn: {
    borderRadius: 4,
    color: isPartnershipActive
      ? theme.palette.greenBrand050
      : theme.palette.greenBrand700,
    cursor: hasNoMenuItems ? "default !important" : "pointer",
    background: isPartnershipActive
      ? theme.palette.greenBrand400
      : theme.palette.greenBrand100,
    "&:hover": {
      background: isPartnershipActive
        ? theme.palette.green700
        : theme.palette.greyscale400,
    },
  },
}));

// I18N

const i18n = defineMessages({
  activatePartnership: {
    id: "crm.PartnershipSettings.PartnershipStatus.activatePartnership",
    defaultMessage: "Activate partnership",
  },
  activePartnership: {
    id: "crm.PartnershipSettings.PartnershipStatus.activePartnership",
    defaultMessage: "Active",
  },
  deletePartnership: {
    id: "crm.PartnershipSettings.PartnershipStatus.deletePartnership",
    defaultMessage: "Delete partnership",
  },
  pausedPartnership: {
    id: "crm.PartnershipSettings.PartnershipStatus.pausedPartnership",
    defaultMessage: "Paused",
  },
  pausePartnership: {
    id: "crm.PartnershipSettings.PartnershipStatus.pausePartnership",
    defaultMessage: "Pause partnership",
  },
});
