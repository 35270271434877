import { isFulfilled } from "@reduxjs/toolkit";
import BaseLoginPage from "components/ui/BaseLoginPage";
import { track } from "helpers/segment";
import useRecord from "hooks/useRecord";
import useUserProfile from "hooks/useUserProfile";
import _ from "lodash";
import { useCallback, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { RouteComponentProps } from "react-router-dom";
import { rawPost } from "redux/api/thunks";
import { fields, included } from "redux/init/constants";
import { ConfirmIntroRequestContent } from "screens/Frontoffice/shared/components/ConfirmIntroRequestContent";
import { JSONAPIListResponse } from "services/types";
import { PipelineEvent } from "tracking";

import { ResponseContent } from "./ResponseContent";

export enum TemplateSource {
  openOpportunities = "opportunities",
  prospects = "prospects",
  jointCustomers = "joint-customers",
  sales = "sales",
  salesRevamped = "sales-revamped",
}

const validSources = [
  TemplateSource.openOpportunities,
  TemplateSource.prospects,
  TemplateSource.jointCustomers,
  TemplateSource.sales,
  TemplateSource.salesRevamped,
];

type Params = {
  templateSource: TemplateSource;
  partnershipId: string;
  matchId: string;
  userId: string;
};
type Props = RouteComponentProps<Params>;

export const AddToPipelineFromWeeklyDigest = ({ match }: Props) => {
  const dispatch = useDispatch();
  const { profile } = useUserProfile();
  const [requested, setRequested] = useState<boolean>(false);
  const [partnerManagerName, setPartnerManagerName] = useState<string>("");
  const [isSuccess, setIsSuccess] = useState<boolean | null>(null);

  const templateSource = match.params.templateSource;
  const isValidSource =
    validSources.filter((source) => source === templateSource).length > 0;

  const partnershipId = +match.params.partnershipId;
  const { record: partnership, error: partnershipError } = useRecord(
    "partnerships",
    partnershipId,
    true,
    {
      include: included,
      fields,
    }
  );
  const partner = partnership?.getPartner(profile);
  const partnerName = partner?.name;

  const { record: account, error: accountError } = useRecord(
    "matches",
    +match.params.matchId
  );
  const accountName = account?.leftName;

  const userId = +match.params.userId;

  const errors = !isValidSource || partnershipError || accountError;

  const createPartnerConnection = useCallback(async () => {
    if (errors) {
      setIsSuccess(false);
      return;
    }
    setRequested(true);
    if (partnership?.id && account?.id && !requested) {
      const result = await dispatch(
        rawPost({
          type: "partner_connections",
          path: "weekly-digest-introductions/",
          payload: {
            data: {
              type: "partner_connections",
              attributes: {
                raw_company_id: account.rawCompanyId,
                partnership_id: partnershipId,
                user_id: userId,
              },
            },
          },
        })
      );
      if (isFulfilled(result)) {
        const response = result.payload as JSONAPIListResponse;
        const userFirstname = _.get(response.data, "first_name", "");
        const userLastname = _.get(response.data, "last_name", "");
        setPartnerManagerName(`${userFirstname} ${userLastname}`);
        setIsSuccess(true);
        track(PipelineEvent.addToPipeline, {
          from: FROM_TRACKING[templateSource],
          bulk: false,
          number_created: 1,
        });
      } else {
        setIsSuccess(false);
      }
    }
  }, [
    errors,
    partnership?.id,
    account?.id,
    requested,
    dispatch,
    account?.rawCompanyId,
    partnershipId,
    userId,
    templateSource,
  ]);

  useEffect(() => {
    track(PipelineEvent.clickedFromWeeklyDigest, {
      from: FROM_TRACKING[templateSource],
      bulk: false,
      number_created: 1,
    });
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <BaseLoginPage hidePaper>
      {isSuccess === null ? (
        <ConfirmIntroRequestContent
          handleConfirm={createPartnerConnection}
          isLoading={requested}
        />
      ) : (
        <ResponseContent
          companyName={profile.company?.name ?? ""}
          companyLogo={profile.company?.avatarUrl ?? ""}
          accountName={accountName}
          partnerName={partnerName}
          partnerManagerName={partnerManagerName}
          isSuccess={isSuccess}
        />
      )}
    </BaseLoginPage>
  );
};

// Helpers

const FROM_TRACKING = {
  [TemplateSource.jointCustomers]: "weekly digest - joint customers",
  [TemplateSource.openOpportunities]: "weekly digest - opportunities",
  [TemplateSource.prospects]: "weekly digest - prospects",
  [TemplateSource.sales]: "weekly digest - sales",
  [TemplateSource.salesRevamped]: "weekly digest - sales revamped",
};
