import autobind from "auto-bind";
import axios, {
  AxiosError,
  AxiosInstance,
  AxiosRequestConfig,
  AxiosResponse,
} from "axios";

export const includes = [];

enum NonUserEndpoints {
  LongLivedTokenUrl = "/api/get-long-lived-token",
  RefreshShortLivedTokenUrl = "/api/refresh-short-lived-token",
  RefreshLongLivedTokenUrl = "/api/refresh-token",
}

export type NewSltEmailPayload = { raw_user_id: number; redirection: string };

class NonUserService {
  //using the same token as the user service, to allow all APIs to be called with it
  static TOKEN_NAME = "user.token";
  static NON_USER_FLAG = "non_user";

  axios: AxiosInstance;

  static getParsedToken(token: string | null) {
    if (token === null) {
      return {};
    }
    try {
      const base64 = token.split(".")[1].replace(/-/g, "+").replace(/_/g, "/");
      const jsonPayload = decodeURIComponent(
        atob(base64)
          .split("")
          .map(
            (char) => "%" + ("00" + char.charCodeAt(0).toString(16)).slice(-2)
          )
          .join("")
      );
      return JSON.parse(jsonPayload);
    } catch (_) {
      return {};
    }
  }

  constructor() {
    const config = require("config").default();
    autobind(this);
    this.axios = axios.create({
      baseURL: config.notificationsApiHost,
      headers: {
        "Content-Type": "application/json",
        "Accept-Language": "en",
        "JWT-aud": config.appName,
      },
    });
    this.axios.interceptors.response.use(
      (response: AxiosResponse) => response,
      this.interceptErrors
    );
  }

  get token() {
    return localStorage.getItem(NonUserService.TOKEN_NAME);
  }

  set token(value) {
    if (value === null) {
      localStorage.removeItem(NonUserService.TOKEN_NAME);
      localStorage.removeItem(NonUserService.NON_USER_FLAG);
    } else {
      localStorage.setItem(NonUserService.TOKEN_NAME, value);
      localStorage.setItem(NonUserService.NON_USER_FLAG, "true");
    }
    window.dispatchEvent(new Event("storage"));
  }

  get parsedToken() {
    return NonUserService.getParsedToken(this.token);
  }

  getResponseData<T>(response: AxiosResponse<T>): T {
    return response.data;
  }

  getAuth(token?: string) {
    const config: AxiosRequestConfig = this.token
      ? {
          headers: {
            Authorization: `Bearer ${token ?? this.token}`,
            "JWT-aud": "crm_user",
          },
        }
      : { headers: { "JWT-aud": "crm_user" } };
    return config;
  }

  extractToken<T>(response: AxiosResponse<T>): T & { token: string | null } {
    if (response.headers.authorization) {
      this.token = response.headers.authorization.split("Bearer ")[1];
    }
    return {
      ...response.data,
      token: this.token,
    };
  }

  // TODO: use this.token = null; in here, in a specific case
  interceptErrors<T>(error: AxiosError<T>) {
    return Promise.reject(error);
    // if (error.config?.url === UserEndpoints.LOGOUT_URL) {
    //   this.token = null;
    // }
  }

  async refreshLongLivedToken() {
    if (!this.token) {
      throw new Error("No token found");
    }
    let res;
    try {
      res = await this.axios.post(
        NonUserEndpoints.RefreshLongLivedTokenUrl,
        undefined,
        this.getAuth()
      );
    } catch (error) {
      this.token = null;
      throw error;
    }
    return this.extractToken(res);
  }

  refreshShortLivedToken(data: NewSltEmailPayload) {
    return this.axios.post(NonUserEndpoints.RefreshShortLivedTokenUrl, data);
  }

  async retrieveLongLivedToken(token: string) {
    const response = await this.axios.post(NonUserEndpoints.LongLivedTokenUrl, {
      token: token,
    });
    return this.extractToken(response);
  }
}

export default NonUserService;
