import { Box } from "@mui/material";
import { QuickFilter, QuickFilterInverted } from "components/icons";
import Dropdown from "components/ui/Dropdown";
import { IDropdownOption } from "components/ui/Dropdown/components/types";
import DropdownSubmitFooter from "components/ui/DropdownSubmitFooter";
import { makeStyles } from "makeStyles";
import { useEffect, useState } from "react";

type Props = {
  anchorEl: HTMLElement | null;
  options: IDropdownOption[];
  value: string[];
  onChange: (value: string[]) => void;
  onClose: () => void;
};

export const DirectoryMultiSelectFilter = ({
  anchorEl,
  onChange,
  onClose,
  options,
  value,
}: Props) => {
  const [ids, setIds] = useState<string[] | null | undefined>(value);
  const { classes } = useStyles();

  useEffect(() => {
    setIds(value);
  }, [anchorEl, value]);

  const handleSubmit = () => {
    if (!ids?.length) {
      onChange([]);
    } else {
      onChange(ids ?? []);
    }
    onClose();
  };

  return (
    <>
      <Box className={classes.buttonContainer}>
        {(ids?.length ?? 0) > 0 ? (
          <QuickFilterInverted className={classes.button} />
        ) : (
          <QuickFilter className={classes.buttonSmall} />
        )}
      </Box>
      <Dropdown
        value={ids}
        options={options}
        anchorEl={anchorEl}
        onClose={onClose}
        open={!!anchorEl}
        isMultiselect
        withCheckBox
        nullValueMeansAllSelected
        CustomFooter={
          <DropdownSubmitFooter
            onApply={handleSubmit}
            onCancel={onClose}
            disabledApplyBtn={false}
          />
        }
        onChange={setIds}
        size="small"
        allowSelectAll
      />
    </>
  );
};
// TODO/RVL-8562: move these to a shared directory
export const useStyles = makeStyles()((theme) => ({
  button: {
    width: 16,
    height: 16,
  },
  buttonSmall: {
    width: 15,
    height: 15,
  },
  buttonContainer: {
    cursor: "pointer",
    display: "flex",
    gap: "8px",
    alignItems: "center",
    marginLeft: 4,
  },
}));
