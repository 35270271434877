import { Dialog, DialogContent, Grid, Hidden } from "@mui/material";
import { makeStyles } from "makeStyles";
import { ReactNode } from "react";

type Props = {
  children: ReactNode;
  isOpen: boolean;
  onClose: () => void;
  sidePanel: ReactNode;
  isLight?: boolean;
};

export const DialogWithSidePanel = ({
  children,
  isOpen,
  onClose,
  sidePanel,
  isLight = false,
}: Props) => {
  const { classes } = useStyles({ isLight });
  return (
    <Dialog
      fullScreen
      open={isOpen}
      onClose={onClose}
      classes={{
        container: classes.dialogContainer,
        paper: classes.dialogPaper,
      }}
    >
      <DialogContent>
        <Grid container className={classes.container}>
          <Hidden mdDown>
            <Grid item className={classes.sideContent}>
              {sidePanel}
            </Grid>
          </Hidden>
          <Grid item className={classes.mainContent}>
            {children}
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
};

const useStyles = makeStyles<{ isLight?: boolean }>()((theme, { isLight }) => ({
  container: {
    height: "100%",
  },
  dialogContainer: {
    padding: theme.spacing(2),
  },
  dialogPaper: {
    background: isLight
      ? theme.palette.greyscale100
      : `linear-gradient(to right, ${theme.palette.midnight}, ${theme.palette.midnight} 90%, ${theme.palette.greyscale100} 90%, ${theme.palette.greyscale100} 100%)`, // workaround to fix the right corner color issue, replace this with 'theme.palette.midnight' and check the dialog right corners to see the problem
    borderRadius: "12px",
    "& .MuiDialogContent-root": {
      padding: 0,
    },
    "& .MuiDialogActions-root": {
      paddingBottom: "36px",
    },
  },
  mainContent: {
    background: theme.palette.greyscale100,
    display: "flex",
    padding: isLight
      ? theme.spacing(2.5)
      : `${theme.spacing(2)} ${theme.spacing(3)}`,
    width: isLight ? "calc(100% - 320px)" : "73%",
    [theme.breakpoints.down("md")]: {
      width: "100%",
    },
  },
  sideContent: {
    width: isLight ? "320px" : "27%",
    borderRight: isLight ? `1px solid ${theme.palette.alpha200}` : "none",
  },
}));
