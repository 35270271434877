import { createSvgIcon } from "@mui/material/utils";

import { SvgIcon } from "./types";

const displayName = "Open";

const Open: SvgIcon = createSvgIcon(
  // Using transform to enlarge from provided viewbox="0 0 16 16"
  // to default "0 0 24 24" from `createSvgIcon` -> `SvgIcon`.
  <g fill="none" transform="matrix(1.5,0,0,1.5,0,0)">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3.88569 2C2.84425 2 2 2.84425 2 3.88569V12.1142C2 13.1556 2.84425 13.9999 3.88569 13.9999H12.1142C13.1556 13.9999 13.9999 13.1556 13.9999 12.1142V8.68564C13.9999 8.40162 13.7696 8.17136 13.4856 8.17136C13.2016 8.17136 12.9713 8.40162 12.9713 8.68564V12.1142C12.9713 12.5876 12.5876 12.9713 12.1142 12.9713H3.88569C3.41231 12.9713 3.02856 12.5876 3.02856 12.1142V3.88569C3.02856 3.41231 3.41231 3.02856 3.88569 3.02856H7.31423C7.59826 3.02856 7.82851 2.79831 7.82851 2.51428C7.82851 2.23025 7.59826 2 7.31423 2H3.88569ZM13.4858 6.4571C13.2017 6.4571 12.9715 6.22685 12.9715 5.94282V3.75586L8.36377 8.36359C8.16293 8.56443 7.83731 8.56443 7.63647 8.36359C7.43563 8.16275 7.43563 7.83713 7.63647 7.63629L12.2442 3.02856L10.0572 3.02856C9.77321 3.02856 9.54296 2.79831 9.54296 2.51428C9.54296 2.23025 9.77321 2 10.0572 2H12.8001C13.4628 2 14.0001 2.53725 14.0001 3.19999V5.94282C14.0001 6.22685 13.7698 6.4571 13.4858 6.4571Z"
      fill="currentColor"
    />
  </g>,
  displayName
);

Open.displayName = displayName;
export default Open;
