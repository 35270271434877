import { PartnerRepresentation } from "models/Partnership";
import User from "models/User";
import { APICollection } from "redux/api/typing";

export enum NotificationWidgetActions {
  setSelectedPartner = "notificationWidget/setSelectedPartner",
  setSelectedPartnershipId = "notificationWidget/setSelectedPartnershipId",
  markNotificationAsRead = "notificationWidget/markNotificationAsRead",
}

export interface NotificationWidgetState {
  selectedPartner: PartnerRepresentation | null;
  selectedPartnershipId: number | null;
}

export interface RootStateWithNotificationWidget {
  notificationWidget: NotificationWidgetState;
  api: {
    entities: {
      notifications?: APICollection;
    };
  };
  user: {
    profile: {
      data: User;
    };
  };
}
