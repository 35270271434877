import Integration from "models/Integration";
import { IIdentifyManualOptions } from "models/User";
import { useCallback } from "react";
import { useDispatch } from "react-redux";
import { fetchProfile } from "redux/user/thunks";

import useSegment from "./useSegment";
import useUserProfile from "./useUserProfile";

export enum dataSourceEvent {
  disabled = "Disconnected data source",
  deleted = "Deleted data source",
}

const useTrackDisabledDataSource = (integrations: Integration[]) => {
  const { track } = useSegment();
  const { profile } = useUserProfile();
  const dispatch = useDispatch();
  const notDeletedIntegrations = integrations.filter(
    ({ deletedAt }) => deletedAt === undefined || deletedAt === null
  );
  const integrationsCount = notDeletedIntegrations.length;
  const crmIntegrations = notDeletedIntegrations.filter(
    ({ provider }) => provider !== "file"
  );
  const crmIntegrationsCount = crmIntegrations.length;
  const activeIntegrationsCount = notDeletedIntegrations.filter(
    ({ disabledAt }) => disabledAt === null
  ).length;
  const activeCrmIntegrationsCount = crmIntegrations.filter(
    ({ disabledAt }) => disabledAt === null
  ).length;

  return useCallback(
    (event: dataSourceEvent, provider: string) => {
      const integrationIsFile = provider === "file";
      let options: IIdentifyManualOptions = {};
      let data = {
        crm: provider,
        countOfIntegrations: integrationsCount,
        countOfCrmSources: crmIntegrationsCount,
        countOfActiveIntegrations: activeIntegrationsCount,
        countOfActiveCrmSources: activeCrmIntegrationsCount,
      };
      switch (event) {
        case dataSourceEvent.deleted:
          data = {
            ...data,
            ...(integrationIsFile
              ? { countOfActiveIntegrations: activeIntegrationsCount - 1 }
              : { countOfCrmSources: integrationsCount - 1 }),
            countOfIntegrations: integrationsCount - 1,
          };
          options = {
            integrationCount: integrationsCount - 1,
          };
          break;
        case dataSourceEvent.disabled:
          data = {
            ...data,
            ...(integrationIsFile
              ? {}
              : { countOfActiveCrmSources: activeCrmIntegrationsCount - 1 }),
            countOfActiveIntegrations: activeIntegrationsCount - 1,
          };
          break;
      }
      track(event, data);
      profile.identify(options);
      dispatch(fetchProfile());
    },
    [
      integrationsCount,
      crmIntegrationsCount,
      activeIntegrationsCount,
      activeCrmIntegrationsCount,
      track,
      profile,
      dispatch,
    ]
  );
};

export default useTrackDisabledDataSource;
