export interface PartnershipUpsidePotentialData {
  avgDealSize: number | null;
  avgWinRate: number | null;
  dealSizeIncrease: number | null;
  revenuePotential: number | null;
  winRateIncrease: number | null;
  newProspectsRevenuePotential: number | null;
  sourceableProspectsRevenuePotential: number | null;
  influenceableProspectsRevenuePotential: number | null;
  winRateOnPartnersCustomersVerifiesCentralLimit: boolean;
}

export interface UpsidesState {
  status: "pending" | "ready" | null;
  data: { [partnershipId: number]: PartnershipUpsidePotentialData };
}

export interface RootStateWithUpsides {
  upsides: UpsidesState;
  context: {
    ready: boolean;
    partnershipIds: number[];
  };
  dashboard: {
    upsidePartnershipIds: number[];
  };
}

export enum UpsidesActions {
  loadPartnershipUpsidePotential = "upsides/loadPartnershipUpsidePotential",
}
