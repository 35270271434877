import { createSvgIcon } from "@mui/material/utils";

import { SvgIcon } from "./types";

const displayName = "Hash";

const Hash: SvgIcon = createSvgIcon(
  // Using transform to enlarge from provided viewbox="0 0 16 16"
  // to default "0 0 24 24" from `createSvgIcon` -> `SvgIcon`.
  <g fill="none" transform="matrix(1.5,0,0,1.5,0,0)">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.57117 3.0761C7.61316 2.80317 7.42594 2.54787 7.15301 2.50588C6.88008 2.4639 6.62479 2.65111 6.5828 2.92404L6.17463 5.57715H4.76929C4.49314 5.57715 4.26929 5.80101 4.26929 6.07715C4.26929 6.35329 4.49314 6.57715 4.76929 6.57715H6.02078L5.58291 9.42334H4C3.72386 9.42334 3.5 9.6472 3.5 9.92334C3.5 10.1995 3.72386 10.4233 4 10.4233H5.42906L5.04434 12.924C5.00235 13.197 5.18956 13.4523 5.46249 13.4943C5.73543 13.5362 5.99072 13.349 6.03271 13.0761L6.44083 10.4233H9.27513L8.89041 12.924C8.84842 13.197 9.03563 13.4523 9.30856 13.4943C9.58149 13.5362 9.83679 13.349 9.87878 13.0761L10.2869 10.4233H11.6923C11.9684 10.4233 12.1923 10.1995 12.1923 9.92334C12.1923 9.6472 11.9684 9.42334 11.6923 9.42334H10.4407L10.8786 6.57715H12.4616C12.7377 6.57715 12.9616 6.35329 12.9616 6.07715C12.9616 5.80101 12.7377 5.57715 12.4616 5.57715H11.0325L11.4172 3.0761C11.4592 2.80317 11.272 2.54787 10.9991 2.50588C10.7262 2.4639 10.4709 2.65111 10.4289 2.92404L10.0207 5.57715H7.18639L7.57117 3.0761ZM9.42898 9.42334L9.86685 6.57715H7.03255L6.59467 9.42334H9.42898Z"
      fill="currentColor"
      fillOpacity="0.82"
    />
  </g>,
  displayName
);
Hash.displayName = displayName;
export default Hash;
