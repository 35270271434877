import { createReducer } from "@reduxjs/toolkit";

import { selectSourceId } from "./actions";
import { addView, loadView, loadViews } from "./thunks";
import { Mapping360NearboundAccountsState } from "./typing";

export default createReducer(
  {
    viewId: null,
    viewIds: [],
    selectedSourceId: null,
    ready: false,
  } as Mapping360NearboundAccountsState,
  (builder) =>
    builder
      .addCase(addView.fulfilled, (state, action) => {
        const viewId = Number(action.payload);
        if (!state.viewIds.includes(viewId)) {
          state.viewIds.push(viewId);
        }
      })
      .addCase(loadView.fulfilled, (state, action) => {
        const viewId = Number(action.payload.viewId);
        state.viewId = viewId;
        if (!state.viewIds.includes(viewId)) {
          state.viewIds.push(viewId);
        }
      })
      .addCase(selectSourceId, (state, action) => {
        state.selectedSourceId = action.payload;
      })
      .addCase(loadViews.fulfilled, (state, action) => {
        state.viewIds = action.payload.viewIds;
        state.ready = true;
      })
);
