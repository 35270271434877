import { createReducer } from "@reduxjs/toolkit";
import { logout } from "redux/user/thunks";

import { loadPartnershipUpsidePotential } from "./thunks";
import { UpsidesState } from "./types";

const initState: UpsidesState = {
  status: null,
  data: {},
};

const reducer = createReducer(initState, (builder) =>
  builder
    .addCase(loadPartnershipUpsidePotential.pending, (state) => {
      state.status = "pending";
    })
    .addCase(loadPartnershipUpsidePotential.fulfilled, (state, action) => {
      state.status = "ready";
      state.data = action.payload;
    })
    .addCase(loadPartnershipUpsidePotential.rejected, (state, action) => {
      state.status = null;
    })
    .addCase(logout.fulfilled, () => initState)
);

export default reducer;
