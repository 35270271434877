import Person from "@mui/icons-material/PersonOutline";
import Grid from "@mui/material/Grid";
import { T } from "components/ui/Typography";
import useAllRecords from "hooks/useAllRecords";
import usePushNotification from "hooks/usePushNotification";
import useUserProfile from "hooks/useUserProfile";
import { makeStyles } from "makeStyles";
import { defineMessages, FormattedMessage, useIntl } from "react-intl";
import InviteForm from "screens/Frontoffice/shared/components/InviteForm";
import JSONAPIService from "services/JSONAPIService";

const i18n = defineMessages({
  lastSent: {
    id: "crm.Invite.InviteList.lastSent",
    defaultMessage: "Last invited {time} by {name}",
  },
  resend: {
    id: "crm.Invite.InviteList.resend",
    defaultMessage: "Re-send",
  },
  pastInvites: {
    id: "crm.Invite.InviteList.pastInvites",
    defaultMessage: "Invited Users",
  },
  newInvite: {
    id: "crm.Invite.InviteList.newInvite",
    defaultMessage: "Invite someone else",
  },
  reminderSent: {
    id: "crm.Invite.InviteList.reminderSent",
    defaultMessage: "An invitation as been sent again to {user}",
  },
  inviteSentToAdmins: {
    id: "crm.Invite.InviteList.inviteSentToAdmins",
    defaultMessage: "This invitation has been sent to {partner}’s admins.",
  },
});

const useStyles = makeStyles()((theme) => ({
  root: {
    padding: theme.spacing(0, 4),
  },
  row: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  text: {
    display: "flex",
    alignItems: "center",
    "& > *:not(:last-child)": {
      marginRight: theme.spacing(1),
    },
  },
  resend: {
    color: theme.palette.link,
    borderStyle: "none",
    fontSize: "0.75rem",
    cursor: "pointer",
    "&:hover": {
      textDecoration: "underline",
    },
  },
  title: {
    marginTop: theme.spacing(2),
  },
}));

const InviteList = ({ record }: $TSFixMe) => {
  const { classes } = useStyles();
  const intl = useIntl();
  const { profile } = useUserProfile();
  const partner = record.getPartner(profile);
  const pushNotification = usePushNotification();
  const { records: invites, refresh } = useAllRecords("partnership_invites", {
    include: ["created_by_user"],
    filters: { partnership: record.id },
  });

  const remind = async (invite: $TSFixMe) => {
    const service = new JSONAPIService("partnership_invites");
    try {
      // @ts-expect-error ts-migrate(2554) FIXME: Expected 3-4 arguments, but got 2.
      await service.rawPost(invite.id, "/remind/");
      pushNotification(i18n.reminderSent, {
        user: `${invite.firstName} ${invite.lastName}`,
      });
      refresh();
    } catch (_) {
      pushNotification("default_error");
    }
  };

  return (
    <Grid container spacing={3} className={classes.root}>
      <Grid item xs={12} className={classes.row}>
        <T h4 className={classes.title}>
          <FormattedMessage {...i18n.pastInvites} />
        </T>
      </Grid>
      {invites.map((invite: $TSFixMe) => (
        <>
          <Grid item xs={12} className={classes.row} key={invite.id}>
            <div className={classes.text}>
              <Person />
              <T>
                {invite.firstName} {invite.lastName}
              </T>
              <T oldVariant="caption">&middot;</T>
              <T oldVariant="caption">
                <FormattedMessage
                  {...i18n.lastSent}
                  values={{
                    time: intl.formatRelativeTime(
                      Math.round(
                        // @ts-expect-error ts-migrate(2362) FIXME: The left-hand side of an arithmetic operation must... Remove this comment to see the full error message
                        (new Date(invite.lastSentAt) - Date.now()) /
                          (1000 * 24 * 60 * 60)
                      ),
                      "day",
                      {
                        numeric: "auto",
                      }
                    ),
                    name: invite.createdByUser.fullName,
                  }}
                />
              </T>
              {/* @ts-expect-error ts-migrate(2363) FIXME: The right-hand side of an arithmetic operation mus... Remove this comment to see the full error message */}
              {Date.now() - new Date(invite.lastSentAt) >
                1000 * 24 * 60 * 60 && (
                <button
                  onClick={() => remind(invite)}
                  className={classes.resend}
                >
                  <FormattedMessage {...i18n.resend} />
                </button>
              )}
            </div>
          </Grid>
        </>
      ))}
      {invites.length === 0 && (
        <Grid item xs={12} className={classes.row}>
          <div className={classes.text}>
            <T>
              <FormattedMessage
                {...i18n.inviteSentToAdmins}
                values={{ partner: partner.name }}
              />
            </T>
          </div>
        </Grid>
      )}
      <Grid item xs={12} className={classes.row}>
        <T h4 className={classes.title}>
          <FormattedMessage {...i18n.newInvite} />
        </T>
      </Grid>
      <Grid item xs={12} className={classes.row}>
        <InviteForm fullWidth partnership={record} onInviteSuccess={refresh} />
      </Grid>
    </Grid>
  );
};

export default InviteList;
