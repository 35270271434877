import { ClassNameMap } from "@mui/material";
import { T } from "components/ui/Typography";
import useAllRecords from "hooks/useAllRecords";
import { defineMessages, FormattedMessage } from "react-intl";
import { Link } from "react-router-dom";

type Props = {
  classes?: ClassNameMap<string>;
};

export const InviteFormStatusRulesForCrmTip = ({ classes }: Props) => {
  const { records: sources } = useAllRecords("integrations");

  const getLinkToStatusRules = () => {
    const basePath = "/sources";
    if (sources.length === 1) {
      const integration = sources[0];
      return (
        basePath +
        `?provider=${integration.provider.toLowerCase()}&integration=${
          integration.id
        }#status-rules`
      );
    }
    return basePath;
  };
  return (
    <T className={classes?.tip}>
      <FormattedMessage {...i18n.tipStart} />
      &nbsp;
      <Link
        className={classes?.link}
        target="_blank"
        to={getLinkToStatusRules()}
      >
        <FormattedMessage {...i18n.partnerStatusRules} />
      </Link>
      &nbsp;
      <FormattedMessage {...i18n.tipEnd} />
    </T>
  );
};

const i18n = defineMessages({
  partnerStatusRules: {
    id: "partners.invite.partnerStatusRules",
    defaultMessage: "Partner Status Rules",
  },
  tipStart: {
    id: "partners.invite.tipStart",
    defaultMessage: "Tip: set up your",
  },
  tipEnd: {
    id: "partners.invite.tipEnd",
    defaultMessage: "to start seeing Suggested Partners from your CRM!",
  },
});
