import { ClassNameMap } from "@mui/material";
import { ChevronDown, ChevronTop, Close } from "components/icons";
import Button from "components/ui/Button";
import Dropdown from "components/ui/Dropdown/components/Dropdown";
import { IDropdownOption } from "components/ui/Dropdown/components/types";
import { TextInput } from "components/ui/TextInput";
import { T } from "components/ui/Typography";
import { makeStyles } from "makeStyles";
import { ChangeEvent, useCallback, useEffect, useRef, useState } from "react";
import { useMergedClasses } from "tss-react";

import { YOUR_OWN_VALUE_OPTION } from "./FieldGroup";

type Props = {
  disabled?: boolean;
  error?: boolean;
  placeholder?: string;
  options?: IDropdownOption[];
  showSearchBar?: boolean;
  name: string;
  value?: string;
  onChange?: (name: string, value: string) => void;
  onDelete?: () => void; // only used for optional CRM fields
  customLabel?: string;
  endDecorator?: JSX.Element;
  isWritable?: boolean;
  isDecimal?: boolean;
  isInteger?: boolean;
  readOnly?: boolean; // When required, the field isn't editable
  classes?: ClassNameMap;
  isLoading?: boolean;
  hasAsyncSearch?: boolean;
  onAsyncSearch?: (query: string) => void;
  withGroups?: boolean;
};

const FieldSelector = ({
  disabled = false,
  error = false,
  placeholder,
  options = [],
  showSearchBar = false,
  name,
  value,
  onChange = () => {},
  onDelete,
  customLabel = "",
  endDecorator,
  isWritable = false,
  isDecimal = false,
  isInteger = false,
  readOnly = false,
  classes: newClasses = {},
  isLoading = false,
  hasAsyncSearch = false,
  onAsyncSearch = () => {},
  withGroups = true,
}: Props) => {
  const [anchorEl, setAnchorEl] = useState<Element | null>(null);
  const inputRef = useRef(null);

  const label = Boolean(customLabel) ? customLabel : value;
  const { classes: baseClasses } = useStyles({
    filled: Boolean(value),
    isOpen: Boolean(anchorEl),
    ...{ disabled, error, readOnly },
  });
  const classes = useMergedClasses(baseClasses, newClasses);

  const openDropdown = useCallback(() => {
    setAnchorEl(inputRef.current);
  }, []);

  const onSelectItems = useCallback(
    (items: string[] | null) => {
      setAnchorEl(null);
      if (items && items.length > 0) {
        onChange(name, items[0]);
      }
    },
    [onChange, name]
  );

  const onInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.validity.valid) {
      onChange(name, e.target.value);
    }
  };

  const onClose = useCallback(() => {
    setAnchorEl(null);
  }, []);

  const handleDropdown = () => {
    if (disabled || readOnly) {
      return;
    }
    if (anchorEl) {
      onClose();
    } else {
      openDropdown();
    }
  };

  // When adding an optional field we need to open the crm field dropdown by default
  useEffect(() => {
    if (!readOnly && onDelete) {
      handleDropdown();
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      <div ref={inputRef} className={classes.root}>
        {!isWritable ? (
          <>
            <div
              onClick={handleDropdown}
              className={classes.btn}
              data-testid={`fieldSelector ${name}`}
            >
              <div className={classes.labelContainer}>
                <T bold className={classes.label} title={value ? label : ""}>
                  {!value ? placeholder : label}
                </T>
              </div>
              <div className={classes.endDecorator}>
                {endDecorator}
                {!readOnly ? (
                  !!anchorEl ? (
                    <ChevronTop />
                  ) : (
                    <ChevronDown />
                  )
                ) : undefined}
              </div>
            </div>
            {onDelete && (
              <Button
                onClick={onDelete}
                variant="tertiary"
                size="xSmall"
                LeftIcon={Close}
                classes={{ icon: classes.closeIcon }}
                data-testid="remove-optional-field"
              />
            )}
          </>
        ) : (
          <>
            <TextInput
              name={name}
              placeholder={placeholder}
              value={value}
              onChange={onInputChange}
              variant={"newDesign"}
              disabled={disabled}
              inputProps={{
                readOnly,
                "data-testid": `textInput ${name}`,
                autoFocus: true,
              }}
              RightIcon={!!anchorEl ? ChevronTop : ChevronDown}
              RightIconAction={!disabled ? handleDropdown : undefined}
              iconSize="normal"
              error={error}
              decimal={isDecimal}
              numeric={isInteger}
              type={isDecimal || isInteger ? "number" : "text"}
              fullWidth
            />
          </>
        )}
      </div>
      <Dropdown
        size="inherit"
        options={options}
        value={isWritable ? [YOUR_OWN_VALUE_OPTION.id] : value ? [value] : []}
        withSearch={showSearchBar}
        open={!!anchorEl}
        anchorEl={anchorEl}
        withGroups={withGroups}
        onChange={onSelectItems}
        onClose={onClose}
        isLoading={isLoading}
        hasAsyncSearch={hasAsyncSearch}
        onAsyncSearch={onAsyncSearch}
      />
    </>
  );
};

export default FieldSelector;

// CSS

const useStyles = makeStyles<{
  filled: boolean;
  isOpen: boolean;
  disabled: boolean;
  error: boolean;
  readOnly: boolean;
}>()((theme, { filled, isOpen, disabled, error, readOnly }) => ({
  root: {
    display: "flex",
    alignItems: "center",
    gap: 4,
  },
  endDecorator: {
    display: "flex",
    gap: 2,
    marginTop: 8,
    "& > .MuiSvgIcon-root": {
      width: 16,
      height: 16,
      marginTop: 1,
    },
  },
  labelContainer: {
    width: "100%",
    height: "100%",
    marginTop: 8,
  },
  label: {
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    overflow: "hidden",
    fontStyle: "normal", // useful to overwrite
  },
  btn: {
    height: 36,
    width: "100%",
    borderRadius: 18,
    paddingLeft: "14px",
    paddingRight: readOnly ? "14px" : "10px",
    backgroundColor: error
      ? theme.palette.red200
      : disabled
      ? theme.palette.greyscale150
      : theme.palette.ivory,
    border:
      error && isOpen
        ? `1px solid ${theme.palette.newRed600}`
        : error || disabled
        ? `1px solid transparent`
        : isOpen
        ? `1px solid ${theme.palette.alpha400}`
        : `1px solid ${theme.palette.alpha250}`,
    color: theme.palette.alpha500,
    "& > div:first-child": {
      color: error
        ? theme.palette.red700
        : disabled
        ? theme.palette.alpha500
        : filled || isOpen
        ? theme.palette.greyscale950
        : undefined,
    },
    "& > div:last-child": {
      color: error
        ? theme.palette.red700
        : isOpen
        ? theme.palette.greyscale950
        : disabled
        ? theme.palette.alpha500
        : undefined,
    },
    "&:hover": {
      border: error
        ? `1px solid ${theme.palette.red500}`
        : disabled
        ? `1px solid transparent`
        : !isOpen
        ? `1px solid ${theme.palette.alpha300}`
        : undefined,
      color: !disabled ? theme.palette.greyscale950 : undefined,
    },
    cursor: disabled ? "default" : readOnly ? "text" : "pointer",
    display: "flex",
    justifyContent: "space-between",
  },
  closeIcon: {
    fontSize: 7,
  },
}));
