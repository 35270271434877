import { createSvgIcon } from "@mui/material/utils";

import { SvgIcon } from "./types";

const displayName = "Logout";

const Logout: SvgIcon = createSvgIcon(
  // Using transform to enlarge from provided viewbox="0 0 16 16"
  // to default "0 0 24 24" from `createSvgIcon` -> `SvgIcon`.
  <path
    fillRule="evenodd"
    clipRule="evenodd"
    d="M6.46795 3C5.10494 3 4 4.10494 4 5.46795V18.0321C4 19.3951 5.10494 20.5 6.46795 20.5H11.8526C13.2156 20.5 14.3205 19.3951 14.3205 18.0321V17.1346C14.3205 16.7629 14.0192 16.4615 13.6474 16.4615C13.2757 16.4615 12.9744 16.7629 12.9744 17.1346V18.0321C12.9744 18.6516 12.4721 19.1538 11.8526 19.1538H6.46795C5.8484 19.1538 5.34615 18.6516 5.34615 18.0321V5.46795C5.34615 4.8484 5.8484 4.34615 6.46795 4.34615H11.8526C12.4721 4.34615 12.9744 4.8484 12.9744 5.46795V6.36538C12.9744 6.73711 13.2757 7.03846 13.6474 7.03846C14.0192 7.03846 14.3205 6.73711 14.3205 6.36538V5.46795C14.3205 4.10494 13.2156 3 11.8526 3H6.46795ZM15.8638 8.13304C16.1267 7.87018 16.5528 7.87018 16.8157 8.13304L19.3221 10.6395C19.9354 11.2528 19.9354 12.2472 19.3221 12.8605L16.8157 15.367C16.5528 15.6298 16.1267 15.6298 15.8638 15.367C15.601 15.1041 15.601 14.6779 15.8638 14.4151L17.8558 12.4231H9.16026C8.78853 12.4231 8.48718 12.1217 8.48718 11.75C8.48718 11.3783 8.78853 11.0769 9.16026 11.0769H17.8558L15.8638 9.08491C15.601 8.82206 15.601 8.39589 15.8638 8.13304Z"
    fill="currentColor"
  />,
  displayName
);

Logout.displayName = displayName;
export default Logout;
