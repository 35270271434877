import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import Grid from "@mui/material/Grid";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import { isFulfilled } from "@reduxjs/toolkit";
import { Check } from "components/icons";
import Button from "components/ui/Button";
import { NotificationStatus } from "components/ui/Notifications/NotificationSnackbar";
import { T } from "components/ui/Typography";
import usePushNotification from "hooks/usePushNotification";
import useSegment from "hooks/useSegment";
import generic from "i18n/generic";
import { has } from "lodash";
import { makeStyles } from "makeStyles";
import ModelRecord from "models/Record";
import User from "models/User";
import { useState } from "react";
import { defineMessages, FormattedMessage } from "react-intl";
import { useDispatch } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import { create, destroy, retreive } from "redux/api/thunks";

import { useUpdateHasLicense } from "../shared/hooks/useUpdateHasLicense";
import { Role } from "../types";
import Roles from "./Roles";

type Props = {
  user: User;
};

type UseLocationProps = {
  shouldUpdateLicense: boolean;
};

export const UserDialog = ({ user }: Props) => {
  const { classes } = useStyles();
  const dispatch = useDispatch();
  const location = useLocation<UseLocationProps>();
  const theme = useTheme();
  const history = useHistory();
  const pushNotification = usePushNotification();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
  const { updateUserHasLicense } = useUpdateHasLicense();
  const userRole = user.roleAssignments[0]?.role ?? null;
  const [currentRole, setCurrentRole] = useState<Role | undefined>(userRole);
  const [submitting, setSubmitting] = useState<boolean>(false);
  const { track } = useSegment();

  const selectRole = (role: Role) => setCurrentRole(role);
  const close = () => history.push("/settings/team");

  const saveRole = async () => {
    setSubmitting(true);
    const result = await dispatch(
      create({
        type: "role_assignments",
        attributes: { role: currentRole },
        relationships: {
          record: {
            id: String(user.company.id),
            type: "companies",
          },
          user: {
            id: String(user.id),
            type: "users",
          },
        },
      })
    );

    if (isFulfilled(result)) {
      track("Edited user Role", { role: currentRole });
      await Promise.all(
        user.roleAssignments.map((record: ModelRecord) =>
          dispatch(destroy({ id: record.id, type: record.type }))
        )
      );
      dispatch(
        await retreive({
          id: user.id,
          type: "users",
          options: { include: ["role_assignments", "company"] },
        })
      );
      close();
      // In case user was pending approval
      if (!user.hasLicense && has(location.state, "shouldUpdateLicense")) {
        updateUserHasLicense(user.id, true);
      } else {
        pushNotification({
          status: NotificationStatus.success,
          message: generic.edits_saved,
        });
      }
    } else {
      pushNotification("default_error");
      setSubmitting(false);
    }
  };

  return (
    <Dialog open fullScreen={fullScreen} maxWidth="xl" onClose={close}>
      <T h3 bold textAlign="center" className={classes.title}>
        <FormattedMessage {...i18n.title} values={{ name: user.fullName }} />
      </T>
      <DialogContent>
        <Roles value={currentRole} onSelect={selectRole} />
        <Grid
          container
          spacing={1}
          className={classes.container}
          justifyContent="flex-end"
        >
          <Grid item className={classes.buttons}>
            <Button label={i18n.cancel} variant="secondary" onClick={close} />
            <Button
              label={i18n.save}
              disabled={currentRole === userRole}
              onClick={saveRole}
              loading={submitting}
              LeftIcon={Check}
            />
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
};

/// CSS

const useStyles = makeStyles()((theme) => ({
  title: {
    padding: theme.spacing(3),
  },
  container: {
    paddingTop: 0,
    padding: theme.spacing(3),
  },
  check: {
    width: 16,
    marginRight: 4,
  },
  inactive: {
    backgroundImage: "transparent",
    padding: 2,
  },
  buttons: {
    display: "flex",
    gap: 4,
  },
}));

/// I18N

const i18n = defineMessages({
  title: {
    id: "Users.UserDialog.Title",
    defaultMessage: "Choose a role for {name}",
  },
  cancel: {
    id: "Users.UserDialog.Cancel",
    defaultMessage: "Cancel",
  },
  save: {
    id: "Users.UserDialog.Save",
    defaultMessage: "Save",
  },
  disable: {
    id: "Users.UserDialog.Disable",
    defaultMessage: "Disable {name}",
  },
});
