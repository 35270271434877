import history from "_history";
import { ChevronLeft } from "@mui/icons-material";
import Button from "components/ui/Button";
import { useCallback } from "react";
import { defineMessages } from "react-intl";

const Navigation = () => {
  const goBack = useCallback(() => {
    history.goBack({ fallback: "/partnerships" });
  }, []);

  return (
    <Button
      label={i18n.back}
      LeftIcon={ChevronLeft}
      size="small"
      onClick={goBack}
      variant="tertiary"
    />
  );
};

export default Navigation;

/// I18N

const i18n = defineMessages({
  back: {
    id: "Frontoffice.DataTables.AccountMapping.Navigation.back",
    defaultMessage: "Back",
  },
});
