import Button from "@mui/material/Button";
import generic from "i18n/generic";
import { FormattedMessage } from "react-intl";

type Props = {
  handleClose: $TSFixMeFunction;
};

const NotFoundDialogActions = ({ handleClose }: Props) => {
  return (
    <Button variant="contained" color="primary" onClick={handleClose}>
      <FormattedMessage {...generic.dismiss} />
    </Button>
  );
};

export default NotFoundDialogActions;
