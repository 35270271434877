import { ClassNameMap } from "@mui/material";
import { memoUnlessPropsChange } from "helpers/memoUnlessPropsChange";
import { makeStyles } from "makeStyles";
import User from "models/User";
import { useMergedClasses } from "tss-react";

import Tooltip from "../Tooltip";
import AccountAvatar from "./AccountAvatar";
import CompanyAvatar from "./CompanyAvatar";

type StyleProps = {
  bgColor?: string;
};

type Props = StyleProps & {
  user?: User;
  avatar?: string | null;
  fullName?: string;
  companyAvatar?: string | null;
  companyName?: string;
  loading?: boolean;
  classes?: ClassNameMap;
  children?: React.ReactNode;
};

export const AccountAvatarWithCompany = memoUnlessPropsChange(
  [
    "classes",
    "avatar",
    "companyAvatar",
    "companyName",
    "fullName",
    "user",
    "loading",
    "bgColor",
    "onClick",
  ],
  ({
    user,
    avatar,
    companyAvatar,
    companyName,
    fullName,
    classes: newClasses,
    loading,
    bgColor,
    children,
  }: Props) => {
    const { classes: baseClasses } = useStyles({ bgColor });
    const classes = useMergedClasses(baseClasses, newClasses ?? {});
    return (
      <div className={classes.container}>
        <AccountAvatar
          src={avatar}
          fullName={fullName?.split(" ")[0] ?? ""}
          user={user}
          loading={loading}
          size={"md"}
        >
          {children}
        </AccountAvatar>
        <div className={classes.borderWrapper}>
          <Tooltip title={companyName} placement="right">
            <div>
              <CompanyAvatar
                company={user?.company}
                companyName={companyName}
                src={companyAvatar}
                size={"xxxs"}
              />
            </div>
          </Tooltip>
        </div>
      </div>
    );
  }
);

const useStyles = makeStyles<StyleProps>()((theme, { bgColor }) => ({
  container: {
    position: "relative",
  },
  borderWrapper: {
    position: "absolute",
    borderLeft: "2px solid",
    borderTop: "2px solid",
    borderColor: bgColor ?? theme.palette.white,
    backgroundColor: bgColor ?? theme.palette.white,
    borderRadius: 1.5,
    bottom: 0,
    right: 0,
  },
}));

export default AccountAvatarWithCompany;
