import { createSvgIcon } from "@mui/material/utils";

import { SvgIcon } from "./types";

const displayName = "InboundAndOutbound";

const InboundAndOutbound: SvgIcon = createSvgIcon(
  <g fill="none" transform="matrix(2,0,0,2,0,0)">
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M6.55816 1.4556C4.63142 1.21902 2.83639 2.21589 1.96733 3.82912C1.70516 4.31578 1.52716 4.85889 1.45554 5.4422C1.42399 5.69915 1.19011 5.88188 0.933156 5.85033C0.676202 5.81878 0.493477 5.5849 0.525026 5.32795C0.611081 4.62709 0.825407 3.97213 1.14197 3.38449C2.18808 1.44262 4.34956 0.239874 6.67241 0.525084C6.92936 0.556634 7.11209 0.790513 7.08054 1.04747C7.04899 1.30442 6.81511 1.48715 6.55816 1.4556ZM7.77194 2.02348C7.955 1.84042 8.2518 1.84042 8.43485 2.02348L10.2576 3.84618C10.3455 3.93409 10.3949 4.05332 10.3949 4.17764C10.3949 4.30196 10.3455 4.42119 10.2576 4.50909L8.43485 6.3318C8.2518 6.51486 7.955 6.51486 7.77194 6.3318C7.58888 6.14874 7.58888 5.85194 7.77194 5.66889L8.79444 4.64639H5.15903C4.90015 4.64639 4.69028 4.43652 4.69028 4.17764C4.69028 3.91876 4.90015 3.70889 5.15903 3.70889H8.79444L7.77194 2.68639C7.58888 2.50333 7.58888 2.20654 7.77194 2.02348ZM3.94826 5.66889C4.13132 5.85194 4.13132 6.14874 3.94826 6.3318L2.92577 7.35429L6.56118 7.35429C6.82006 7.35429 7.02993 7.56416 7.02993 7.82304C7.02993 8.08193 6.82006 8.29179 6.56118 8.29179L2.92577 8.29179L3.94826 9.31429C4.13132 9.49735 4.13132 9.79415 3.94826 9.97721C3.76521 10.1603 3.46841 10.1603 3.28535 9.9772L1.46265 8.1545C1.37474 8.06659 1.32535 7.94736 1.32535 7.82304C1.32535 7.69872 1.37474 7.57949 1.46265 7.49159L3.28535 5.66888C3.46841 5.48583 3.76521 5.48583 3.94826 5.66889ZM11.0671 6.15008C11.3241 6.18163 11.5068 6.41551 11.4753 6.67247C11.3892 7.37333 11.1749 8.02829 10.8583 8.61592C9.81222 10.5578 7.65074 11.7605 5.32789 11.4753C5.07093 11.4438 4.88821 11.2099 4.91976 10.9529C4.95131 10.696 5.18519 10.5133 5.44214 10.5448C7.36887 10.7814 9.1639 9.78452 10.033 8.1713C10.2951 7.68463 10.4731 7.14152 10.5448 6.55821C10.5763 6.30126 10.8102 6.11854 11.0671 6.15008Z"
      fill="currentColor"
    />
  </g>,

  displayName
);

InboundAndOutbound.displayName = displayName;
export default InboundAndOutbound;
