import { searchHost } from "config/constants";
import _ from "lodash";
import { Factory } from "models";
import { JSONSerializable } from "models/types";
import { stringify } from "query-string";

import BaseHttpService from "./BaseHTTPService";
import { JSONAPIListResponse } from "./types";

export class SearchService extends BaseHttpService {
  resource: string;
  filters: { [filtername: string]: JSONSerializable };

  constructor(resource: string, filters = {}) {
    super(`${searchHost}/api/search/${resource.replace(/_/g, "-")}/`);
    this.filters = filters;
    this.resource = resource;
  }

  clone(): SearchService {
    return new Proxy<SearchServiceType>(
      new SearchService(this.resource, this.filters) as SearchServiceType,
      magicHandler
    );
  }

  filter(key: string, value: JSONSerializable) {
    const next = this.clone();
    next.filters[key] = value;
    return next;
  }

  async perform(query = {}) {
    const _query = _.merge(
      _(this.filters)
        .toPairs()
        .map(([key, value]) => [`filter[q.${key}]`, value])
        .fromPairs()
        .value(),
      query
    );
    const response = await this.authGet<JSONAPIListResponse>(
      "?" + stringify(_query),
      {}
    );
    return {
      records: _.map(response.data.data, Factory.createRecord),
      count: response.data.meta?.record_count || 0,
    };
  }
}

type SearchServiceType = {
  [query: string]: (value: JSONSerializable) => SearchServiceType;
} & SearchService;

export const magicHandler = {
  get: (target: SearchServiceType, key: string) => {
    if (!(key in target)) {
      return (value: JSONSerializable) => target.filter(key, value);
    }
    return target[key];
  },
} as ProxyHandler<SearchServiceType>;

const searchServiceFactory = (resourceName: string) =>
  new Proxy(new SearchService(resourceName), magicHandler) as SearchServiceType;

export default searchServiceFactory;
