import { Check, Close } from "components/icons";
import { T } from "components/ui/Typography";
import forms from "i18n/forms";
import _ from "lodash";
import { makeStyles } from "makeStyles";
import { FormattedMessage } from "react-intl";
import * as yup from "yup";

export const passwordSchema = yup
  .string()
  .required()
  .min(8, "length")
  .matches(/[a-z]/, "complexity")
  .matches(/[A-Z0-9]/, "complexity");

type Props = {
  value?: string;
};

export const PasswordConstraints = ({ value }: Props) => {
  const { classes, cx } = useStyles();
  let state = {};
  try {
    passwordSchema.validateSync(value || "", { abortEarly: false });
    state = {
      length: false,
      complexity: false,
    };
  } catch (validationError) {
    if (validationError instanceof yup.ValidationError) {
      state = _(validationError.errors)
        .map((key) => [key, true])
        .fromPairs()
        .value();
    }
  }
  return (
    <div className={classes.root}>
      <T>
        {_.get(state, "length", false) ? (
          <Close className={cx(classes.icon, classes.error)} />
        ) : (
          <Check className={cx(classes.icon, classes.valid)} />
        )}
        <FormattedMessage {...forms.password_at_least_8} />
      </T>
      <T>
        {_.get(state, "complexity", false) ? (
          <Close className={cx(classes.icon, classes.error)} />
        ) : (
          <Check className={cx(classes.icon, classes.valid)} />
        )}
        <FormattedMessage {...forms.password_complexity} />
      </T>
    </div>
  );
};

// STYLES

const useStyles = makeStyles()((theme) => ({
  root: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(2),
    backgroundColor: theme.palette.taupe250,
    borderRadius: 6,
    padding: 13,
  },
  lineText: {
    display: "flex",
    alignItems: "center",
  },
  icon: {
    marginRight: theme.spacing(1),
    height: 16,
    width: 16,
  },
  error: {
    color: theme.palette.red500,
    padding: 3,
  },
  valid: {
    color: theme.palette.green600,
  },
}));
