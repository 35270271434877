import { makeStyles } from "makeStyles";
import { ISharedOpportunity, IStatus, Status } from "models/PartnerConnection";
import { defineMessages, useIntl } from "react-intl";
import { opportunityFields } from "screens/Frontoffice/screens/DataTables/screens/Pipeline/constants";

import { FieldDisplay } from "../RawOpportunityDisplay/FieldDisplay";

type SharedOpportunityDisplayProps = {
  opportunity: ISharedOpportunity;
  withStage?: boolean;
};

export const SharedOpportunityDisplay = ({
  opportunity,
  withStage,
}: SharedOpportunityDisplayProps) => {
  const status = opportunity.status
    ? (opportunity.status as IStatus)
    : Status.Lost;
  const fields: (keyof ISharedOpportunity)[] = ["amount", "status"];
  const { classes } = useOpportunityStyles({
    status,
    numberOfFields: [opportunity.amount, opportunity.status].filter(
      (value) => value !== undefined
    ).length,
  });
  const intl = useIntl();

  if (!opportunity.status && !opportunity.amount) return null;

  return (
    <div className={classes.container}>
      <div className={classes.flexAlignCenter}>
        {fields.map((fieldKey, index) => {
          if (!opportunity[fieldKey]) return null;

          return (
            <FieldDisplay
              key={fieldKey}
              maxWidth={120}
              {...{
                rawOpportunityField: true,
                isDisabled: false,
                isPrivate: false,
                partnerField: false,
                noSide: true,
                filter: false,
                sort: false,
                options: {},
                ...opportunityFields[fieldKey as keyof ISharedOpportunity], // name, type and label comes from here
                smartField:
                  opportunityFields[fieldKey as keyof ISharedOpportunity]
                    .smart_field, // the rest needs casing fix
                displayIndex:
                  opportunityFields[fieldKey as keyof ISharedOpportunity]
                    .display_index,
                fullyImported:
                  opportunityFields[fieldKey as keyof ISharedOpportunity]
                    .fully_imported,
                value:
                  fieldKey === "amount"
                    ? { amount: opportunity.amount, currency: "USD" }
                    : fieldKey === "status"
                    ? intl.formatMessage(
                        i18n[
                          opportunity[
                            fieldKey as keyof ISharedOpportunity
                          ] as IStatus
                        ],
                        {
                          stage: withStage
                            ? opportunity?.stage ?? undefined
                            : undefined,
                        }
                      )
                    : "",
              }}
              numberOfFields={fields.length}
              bold={index === 0}
            />
          );
        })}
      </div>
    </div>
  );
};

type StyleProps = {
  status?: IStatus | null;
  numberOfFields: number;
};

const useOpportunityStyles = makeStyles<StyleProps>()(
  (theme, { numberOfFields: n, status }) => ({
    container: {
      alignItems: "center",
      display: "flex",
      height: 20,
      borderRadius: 4,
      padding: "0 6px",
      backgroundColor:
        status === Status.Open
          ? theme.palette.greyLight050
          : status === Status.Won
          ? theme.palette.greenBrand100
          : status === Status.Lost
          ? theme.palette.red100
          : "unset",
      color:
        status === Status.Open
          ? theme.palette.midnight
          : status === Status.Won
          ? theme.palette.greenBrand700
          : status === Status.Lost
          ? theme.palette.red700v2
          : "unset",
    },
    flexAlignCenter: {
      display: "flex",
      alignItems: "center",
      gap: "4px",
    },
  })
);

const i18n = defineMessages({
  Open: {
    id: "components.ui.SharedOpportunityDisplay.Open",
    defaultMessage: "Open{stage, select, undefined {} other { ({stage})}}",
  },
  Lost: {
    id: "components.ui.SharedOpportunityDisplay.Lost",
    defaultMessage: "Closed lost",
  },
  Won: {
    id: "components.ui.SharedOpportunityDisplay.Won",
    defaultMessage: "Closed won",
  },
});
