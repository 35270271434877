import CheckRounded from "@mui/icons-material/CheckRounded";
import { Box, CircularProgress } from "@mui/material";
import { Edit, Info, Plus } from "components/icons";
import Button from "components/ui/Button";
import Dropdown from "components/ui/Dropdown/components/Dropdown";
import { IDropdownOption } from "components/ui/Dropdown/components/types";
import DropdownSubmitFooter from "components/ui/DropdownSubmitFooter";
import { SharedOpportunityDisplay } from "components/ui/SharedOpportunityDisplay/SharedOpportunityDisplay";
import Tooltip from "components/ui/Tooltip";
import { isRowClickable } from "helpers/partnerConnectionUtils";
import { useOpportunityDropdown } from "hooks/useOpportunityDropdown";
import { makeStyles } from "makeStyles";
import PartnerConnection, {
  ISharedOpportunity,
  Status,
} from "models/PartnerConnection";
import React, { useMemo, useState } from "react";
import { defineMessages, FormattedMessage, useIntl } from "react-intl";
import { AvailablePipelineColumns } from "redux/pipeline/defaults";

import { CellProps } from "../../utils";

export const OpportunityCell = ({
  row,
}: Omit<CellProps, "row"> & { row: PartnerConnection }) => {
  const { classes, cx } = useOpportunityCellStyles();
  const intl = useIntl();
  const [ids, setIds] = useState<string[] | null>(
    row.sharedOpportunities?.map((o) => String(o.id)) ?? null
  );
  const [loading, setLoading] = useState(false);

  const {
    allowEdit,
    anchorEl,
    availableOpports,
    handleClose,
    handleOpen,
    isLoadingAvailableOpports,
    isPartnerCell,
    linkOpportunity,
    unlinkOpportunity,
    unlinkedOpenOpportunityCount,
    updateAttributes,
  } = useOpportunityDropdown({ partnerConnection: row });

  const opportunityOptions = useMemo(() => {
    return [
      ...((availableOpports ?? []).map((o) => {
        return {
          id: String(o.id),
          isOpen: o.isOpen,
          amount: o.amountInDollars,
          name: o.name,
          date: o.closeDate?.toISOString().split("T")[0] ?? o.closeDate,
          isWon: o.isWon,
          stage: o.stage,
        };
      }) as IDropdownOption[]),
      ...(row.sharedOpportunities ?? []).map(
        (o) =>
          ({
            id: String(o.id),
            isOpen: o.status === Status.Open,
            amount: o.amount,
            name: o.opportunity_name,
            date: o.close_date,
            isWon: o.status === Status.Won,
            stage: o.stage,
          } as IDropdownOption)
      ),
    ];
  }, [availableOpports, row.sharedOpportunities]);

  const handleOpportunities = async () => {
    setLoading(true);
    handleClose();

    // the ones to be added
    const opportunities = availableOpports?.filter((o) =>
      ids?.includes(String(o.id))
    );
    if (opportunities && opportunities?.length > 0) {
      await Promise.all(
        opportunities.map((opportunity) =>
          linkOpportunity(String(opportunity.id))
        )
      );
    }

    // the ones to be removed
    const opportunitiesToRemove = row.sharedOpportunities?.filter(
      (o) => !ids?.includes(String(o.id))
    );
    if (opportunitiesToRemove && opportunitiesToRemove.length > 0) {
      await Promise.all(
        opportunitiesToRemove.map((opportunity) =>
          unlinkOpportunity(String(opportunity.id))
        )
      );
    }
    await updateAttributes({
      added: opportunities?.map((o) => String(o.id)) ?? [],
      removed: opportunitiesToRemove?.map((o) => String(o.id)) ?? [],
    });
    setLoading(false);
  };

  if (!row.rawCompanyId && allowEdit) {
    return (
      <Tooltip title={<FormattedMessage {...i18n.newProspectTooltip} />}>
        <Info className={classes.infoIcon} />
      </Tooltip>
    );
  }

  if (loading) {
    return (
      <Box
        className={cx(classes.cellContainer, {
          [classes.cursorPointer]: isRowClickable(row),
        })}
        justifyContent="center"
        width="100%"
      >
        <CircularProgress size={16} />
      </Box>
    );
  }

  return (
    <Box className={cx({ [classes.cursorPointer]: isRowClickable(row) })}>
      {row.sharedOpportunities?.length > 0 ? (
        <div className={classes.cellContainer}>
          {row.sharedOpportunities.map((item) => (
            <SharedOpportunityDisplay
              opportunity={item}
              key={item.opportunity_name}
            />
          ))}
          {allowEdit && (
            <Box className="showOnHover">
              <Button
                LeftIcon={Edit}
                onClick={handleOpen}
                variant="quinary"
                size="xxSmall"
                classes={{ icon: classes.buttonAlpha }}
              />
            </Box>
          )}
        </div>
      ) : isPartnerCell && row.hasOpenOpportunityLinked ? (
        <div className={classes.flex}>
          <CheckRounded
            className={classes.icon}
            data-testid={"pipeline-checkmark"}
          />
        </div>
      ) : !allowEdit ? (
        ""
      ) : (
        <div className={cx(classes.chooseOpportContainer, "showOnHover")}>
          {(row.unlinkedOpportunityCount ?? 0) > 0 && (
            <Button
              label={intl.formatMessage(i18n.chooseOpport, {
                count: unlinkedOpenOpportunityCount,
              })}
              variant="quinary"
              LeftIcon={Plus}
              classes={{
                icon: classes.buttonAlpha,
                labelContent: classes.buttonAlpha,
              }}
              onClick={handleOpen}
              size="xSmall"
            />
          )}
        </div>
      )}
      <Dropdown
        isOpportunityItem
        isLoading={isLoadingAvailableOpports}
        isMultiselect
        options={opportunityOptions}
        anchorEl={anchorEl}
        onClose={handleClose}
        open={!!anchorEl}
        value={ids}
        withSearch={false}
        onChange={(ids) => setIds(ids)}
        CustomFooter={
          <DropdownSubmitFooter
            onApply={handleOpportunities}
            onCancel={handleClose}
            cancelLabel={i18n.close}
            applyLabel={i18n.confirm}
          />
        }
      />
    </Box>
  );
};

// HELPERS

export const isPipelineOpportunityCell = ({ fieldName }: CellProps) =>
  fieldName === AvailablePipelineColumns.rawOpportunityStatus;

export const hasMultipleOpportunities = (
  sharedOpportunities: ISharedOpportunity[]
) => sharedOpportunities?.length > 1;

// CSS

const useOpportunityCellStyles = makeStyles()((theme) => ({
  cellContainer: {
    display: "flex",
    flexWrap: "wrap",
    alignItems: "center",
    gap: "6px",
    padding: "4px 0",
  },
  cursorPointer: {
    cursor: "pointer",
    "& p": {
      cursor: "pointer !important",
    },
  },
  addIcon: {
    color: theme.palette.alpha800,
    width: 20,
    height: 20,
    "&:hover": {
      color: theme.palette.darkPurple,
    },
  },
  buttonAlpha: {
    color: theme.palette.alpha500,
  },
  chooseOpportContainer: {
    display: "flex",
    alignItems: "center",
  },
  infoIcon: {
    color: theme.palette.gold400V2,
    width: 16,
    height: 16,
  },
  noOpport: {
    marginLeft: 10,
  },
  dropdownOption: {
    padding: theme.spacing(0),
    flexDirection: "row",
    alignItems: "center",
  },
  icon: {
    color: theme.palette.appGreen,
  },
  flex: {
    display: "flex",
    alignItems: "center",
  },
}));

// I18N

const i18n = defineMessages({
  chooseOpport: {
    id: "crm.MyPipeline.OpportunityCell.chooseOpport",
    defaultMessage: `{count, plural,
      =0 {Attach opportunity}
      other {Attach opportunity ({count} open)}
    }`,
  },
  noOpen: {
    id: "crm.MyPipeline.OpportunityCell.noOpen",
    defaultMessage: "No open opportunity",
  },
  noClosed: {
    id: "crm.MyPipeline.OpportunityCell.noClosed",
    defaultMessage: "No closed opportunity",
  },
  newProspectTooltip: {
    id: "crm.MyPipeline.OpportunityCell.newProspectTooltip",
    defaultMessage:
      "You cannot attach an opportunity to a new prospect collab that hasn't been linked to an account.",
  },
  noOpport: {
    id: "crm.MyPipeline.OpportunityCell.noOpport",
    defaultMessage: "No opportunity in CRM",
  },
  close: {
    id: "crm.MyPipeline.OpportunityCell.close",
    defaultMessage: "Close",
  },
  confirm: {
    id: "crm.MyPipeline.OpportunityCell.confirm",
    defaultMessage: "Confirm",
  },
});
