import CircularProgress from "@mui/material/CircularProgress";
import { isFulfilled } from "@reduxjs/toolkit";
import useDebouncedEffect from "hooks/useDebouncedEffect";
import {
  ComponentType,
  ReactElement,
  ReactNode,
  RefObject,
  useCallback,
  useMemo,
  useState,
} from "react";
import InfiniteScroll from "react-infinite-scroller";
import { useDispatch, useSelector } from "react-redux";
import { index } from "redux/api/thunks";
import { APICollection } from "redux/api/typing";
import { RevealStore } from "redux/typing";
import {
  FilterValue,
  JSONAPIListResponse,
  JSONAPIOptions,
} from "services/types";

const scrollerPropNames = [
  "children",
  "element",
  "hasMore",
  "initialLoad",
  "isReverse",
  "loader",
  "loadMore",
  "pageStart",
  "ref",
  "getScrollParent",
  "threshold",
  "useCapture",
  "useWindow",
];

const InfiniteResourceDefaultLoader = () => (
  <div
    style={{
      display: "flex",
      justifyContent: "center",
    }}
  >
    <CircularProgress />
  </div>
);

type InfiniteResourceProps = {
  Component: ComponentType<$TSFixMe>;
  callback?: ({
    count,
    loading,
  }: {
    count: number | null;
    loading: boolean;
  }) => void;
  debounce?: number;
  initialLoad?: boolean;
  head?: ReactNode;
  empty?: ReactNode;
  fields?: { [resource: string]: string[] };
  filters?: { [name: string]: FilterValue };
  page?: { size?: number; number?: number; offset?: number; limit?: number };
  hash?: string;
  include?: string[];
  loader?: ReactElement;
  relation?: string;
  resource: string;
  size?: number;
  sort?: string[] | { orderBy: string; order: string };
  rowProps?: $TSFixMe;
  scrollerRef?: RefObject<HTMLElement> | null;
  threshold?: number | null;
  element?: React.ReactNode | string | undefined;
};

const InfiniteResource = ({
  Component,
  callback,
  debounce = 300,
  initialLoad = false,
  empty,
  filters = {},
  fields = {},
  hash,
  head,
  include = [],
  loader = <InfiniteResourceDefaultLoader />,
  relation,
  resource,
  rowProps = {},
  size = 10,
  sort = [],
  scrollerRef = null,
  threshold = null,
  ...props
}: InfiniteResourceProps) => {
  const [loading, setLoading] = useState(true);
  const [hasMore, setHasMore] = useState(false);
  const [recordIds, setRecordIds] = useState<number[]>([]);
  const [page, setPage] = useState(1);
  const dispatch = useDispatch();

  const selector = useMemo(
    () => (state: RevealStore) =>
      (state.api.entities[resource] ?? {}) as APICollection,
    [resource]
  );

  const collection = useSelector(selector);

  const indexRecords = async (options: JSONAPIOptions) => {
    return await dispatch(index({ type: resource, options }));
  };

  const loadMore = async (_: $TSFixMe, startFresh = false) => {
    setLoading(true);
    const result = await indexRecords({
      include,
      filters,
      page: { number: startFresh ? 1 : page, size },
      sort,
      fields,
    });
    if (isFulfilled(result)) {
      const response = result.payload as JSONAPIListResponse;
      setHasMore(Boolean(response.links?.next));
      setRecordIds((current) => [
        ...current,
        ...response.data.map((val) => +val.id),
      ]);
      setPage(startFresh ? 2 : page + 1);
      if (callback) {
        callback({
          count: response.meta?.record_count ?? null,
          loading: false,
        });
      }
    }
    setLoading(false);
  };

  const reset = () => {
    setRecordIds(() => []);
    setPage(1);
    if (callback) {
      callback({
        count: null,
        loading: true,
      });
    }
    loadMore(page, true);
  };

  useDebouncedEffect(
    useCallback(reset, [filters, sort]), // eslint-disable-line react-hooks/exhaustive-deps
    JSON.stringify([filters, sort, hash]),
    debounce
  );

  const records = recordIds
    .map((id) => collection[id])
    .filter((record) => Boolean(record))
    .map((record) => <Component key={record.id} record={record} {...props} />);

  const scrollerProps = {};
  Object.keys(props)
    .filter((propName) => scrollerPropNames.includes(propName))
    .concat(["style", "className", "key"])
    // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
    .forEach((propName) => (scrollerProps[propName] = props[propName]));

  const getScrollParent = () => {
    return scrollerRef?.current ?? null;
  };

  return (
    <>
      <InfiniteScroll
        hasMore={hasMore && !loading}
        loadMore={loadMore}
        initialLoad={initialLoad}
        useWindow={!scrollerRef}
        threshold={threshold ?? undefined}
        getScrollParent={scrollerRef ? getScrollParent : undefined}
        {...scrollerProps}
      >
        {head}
        {records}
        {!loading && !hasMore && recordIds.length === 0 && empty}
        {loading && loader}
      </InfiniteScroll>
    </>
  );
};

export default InfiniteResource;
