import MenuItem from "@mui/material/MenuItem";
import Select, { SelectChangeEvent, SelectProps } from "@mui/material/Select";
import { ChevronDown } from "components/icons";
// Specially importing `_private.getFontStyles` outside tests because we don't
// need another component rendered (<T>) given that inputs already encompass text.
import { _private as _privateTypography } from "components/ui/Typography/Typography";
import { makeStyles } from "makeStyles";
import { ReactElement } from "react";

import { useDropdownItemStyles } from "./Dropdown/components/DropdownItem";

type Variant = "primary" | "secondary" | "tertiary";

export interface Option {
  value: string | number;
  label: ReactElement | string;
}

type CustomStyleProps = {
  variant: Variant;
  small?: boolean;
  error?: boolean;
  multiline?: boolean;
};

type CustomProps = SelectPartial<CustomStyleProps, "variant"> & {
  onChange?: (e: SelectChangeEvent) => void;
  placeholder?: string;
  options: Option[];
  endAdornment?: React.ReactNode;
};

export type Props = Omit<SelectProps, keyof CustomProps> & CustomProps;

export const SelectInput = (props: Props) => {
  const {
    endAdornment,
    variant = "primary",
    small,
    error,
    className,
    placeholder,
    displayEmpty = true,
    options,
    ...otherProps
  } = props;
  const styleProps = {
    variant,
    small,
    error,
  };
  const { classes, cx } = useStyles(styleProps);
  const { classes: inputClasses } = useInputStyles(styleProps);
  const {
    classes: dropdownItemClasses,
    cx: dropdownCx,
  } = useDropdownItemStyles({});

  return (
    <Select
      displayEmpty={true}
      className={cx(classes.baseStyles, className)}
      classes={inputClasses}
      IconComponent={ChevronDown}
      endAdornment={endAdornment}
      MenuProps={{ PopoverClasses: { paper: classes.popover } }}
      renderValue={(selected) => {
        if (!Boolean(selected) && placeholder !== undefined) {
          return <span className={classes.placeholder}>{placeholder}</span>;
        }
        const selectedOption = options.find(
          (option) => option.value === selected
        );
        if (selectedOption) {
          return selectedOption.label;
        }
        return selected;
      }}
      {...otherProps}
    >
      {placeholder !== undefined && (
        <MenuItem
          hidden={!displayEmpty}
          classes={{
            root: cx(
              dropdownItemClasses.singleItem,
              dropdownItemClasses.itemElement,
              classes.value
            ),
          }}
          value=""
        >
          <span
            className={dropdownCx(
              dropdownItemClasses.label,
              dropdownItemClasses.emptyLabel
            )}
          >
            {placeholder}
          </span>
        </MenuItem>
      )}
      {options.map((option: Option, i) => (
        <MenuItem
          key={i}
          classes={{
            root: cx(
              dropdownItemClasses.singleItem,
              dropdownItemClasses.itemElement,
              classes.value
            ),
          }}
          value={option.value}
        >
          <span className={dropdownItemClasses.label}>{option.label}</span>
        </MenuItem>
      ))}
    </Select>
  );
};

// Styles

const height = (props: CustomStyleProps) => (props.small ? "28px" : "36px");

const useInputStyles = makeStyles<CustomStyleProps>()((theme, props) => ({
  select: {
    ..._privateTypography.getFontStyles({ labelSmall: true }),
    fontFamily: _privateTypography.fontFamily,
    borderRadius: "18px",
    height: `${height(props)} !important`,
    display: "block",
    padding: props.small ? 7 : 12,
    boxSizing: "border-box",
    // Colors
    "&, &:hover, &.Mui-focused, &.Mui-disabled": {
      backgroundColor:
        props.variant === "primary"
          ? props.error
            ? theme.palette.red100
            : theme.palette.taupe500
          : props.variant === "secondary" && props.error
          ? theme.palette.red50
          : "transparent",
    },
    color:
      props.error && props.variant !== "tertiary"
        ? theme.palette.wine
        : theme.palette.midnight,
    border:
      props.variant === "tertiary"
        ? props.error
          ? `1px solid ${theme.palette.red500}`
          : `1px solid ${theme.palette.taupe}`
        : undefined,
    "&:hover": {
      border:
        props.variant === "tertiary" && !props.error
          ? `1px solid ${theme.palette.midnight500}`
          : undefined,
    },
    "&:focus": {
      borderRadius: "18px",
    },
  },
}));

const useStyles = makeStyles<CustomStyleProps>({ name: { SelectInput } })(
  (theme, props) => ({
    baseStyles: {
      height: height(props),
      "& .MuiOutlinedInput-notchedOutline": {
        border: "none",
      },
    },
    placeholder: {
      color:
        props.error && props.variant !== "tertiary"
          ? theme.palette.wine
          : theme.palette.midnight500,
      opacity: 1, // Firefox fix -- it always reduces the opacity.

      "&:hover ": {
        color:
          props.error && props.variant !== "tertiary"
            ? theme.palette.wine
            : theme.palette.midnight,
      },
    },
    popover: {
      marginTop: 2,
      padding: 8,
      backgroundColor: theme.palette.ivory,
      borderRadius: 8,
      border: `1px solid ${theme.palette.taupe500}`,
      boxShadow: "0px 4px 16px rgba(130, 136, 136, 0.16)",
      maxHeight: 224,
    },
    value: {
      "&:hover": {
        backgroundColor: `${theme.palette.offWhite} !important`,
        cursor: "pointer",
      },
      "&[aria-selected=true]": { backgroundColor: theme.palette.lightPurple },
    },
  })
);
