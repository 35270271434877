import {
  AnyAction,
  createAsyncThunk,
  isRejected,
  ThunkDispatch,
} from "@reduxjs/toolkit";
import { PayingFeature } from "models/CompanyPayingFeatureSubscription";
import { SuggestedPartnerSource } from "models/SuggestedPartner";
import { JSONAPIResource } from "models/types";
import User from "models/User";
import {
  selectActivePayingFeaturesFunc,
  selectPayingFeaturesFunc,
} from "redux/api/selectors";
import { indexAll } from "redux/api/thunks";
import { RevealStore } from "redux/typing";
import JSONAPIService from "services/JSONAPIService";
import { JSONAPIListResponse } from "services/types";

import { SuggestedPartnersActions } from "./types";

const getFeatureAccess = (user: User) => {
  return user.canManagePartnerships && user.company?.id;
};

export const dismissSuggestion = createAsyncThunk(
  SuggestedPartnersActions.dismissSuggestion,
  async ({ id }: { id: number }, thunkAPI) => {
    const service = new JSONAPIService("suggested_partners");
    await service.rawPost(id, "/dismiss/", undefined);
    const indexAllSuggestedPartnersAction = await thunkAPI.dispatch(
      indexAll({
        type: "suggested_partners",
        options: {
          include: ["partner"],
          filters: {
            source__in: Object.values(SuggestedPartnerSource),
            partner_on_reveal: true,
          },
        },
      })
    );
    if (isRejected(indexAllSuggestedPartnersAction)) {
      throw new Error("Could not fetch new suggestions");
    }
    return (
      indexAllSuggestedPartnersAction?.payload.data.map(
        (value: JSONAPIResource) => +value.id
      ) ?? []
    );
  }
);

export const initializeSuggestedPartners = createAsyncThunk(
  SuggestedPartnersActions.initialize,
  async ({ partnerOnReveal }: { partnerOnReveal: boolean }, thunkAPI) => {
    const state = thunkAPI.getState() as RevealStore;

    const companyPayingFeatureSubscriptions =
      state?.api?.entities?.["company_paying_feature_subscriptions"] ?? {};
    const companyPayingFeatureSubscriptionIds =
      state?.context?.companyPayingFeatureSubscriptionIds ?? [];
    const features = selectActivePayingFeaturesFunc(
      selectPayingFeaturesFunc(
        state.user.profile.data,
        companyPayingFeatureSubscriptions,
        companyPayingFeatureSubscriptionIds
      )
    );
    const hasSalesInvitationsPayingFeature = !!features.includes(
      PayingFeature.SalesInvitations
    );
    const hasSuggestedPartnersFeature = getFeatureAccess(
      state.user.profile.data
    ); //TODO

    let suggestionIds = [] as number[];
    if (!hasSuggestedPartnersFeature && !hasSalesInvitationsPayingFeature) {
      return { suggestionIds };
    }
    const response: JSONAPIListResponse<string> = await initializeSuggestedPartnersFetch(
      thunkAPI.dispatch,
      partnerOnReveal
    );
    suggestionIds =
      response?.data.map((value: JSONAPIResource) => +value.id) ?? [];
    return { suggestionIds };
  }
);

const initializeSuggestedPartnersFetch = async (
  dispatch: ThunkDispatch<unknown, unknown, AnyAction>,
  partnerOnReveal: boolean
) => {
  const indexAllSuggestedPartnersAction = await dispatch(
    indexAll({
      type: "suggested_partners",
      maxPages: 1,
      options: {
        include: ["partner"],
        filters: {
          ...(partnerOnReveal ? { partner_on_reveal: true } : {}),
        },
        sort: ["order_by_source", "is_owner"],
        // we limit the items at 100 for performance
        page: { size: 100 },
      },
    })
  );

  if (isRejected(indexAllSuggestedPartnersAction)) {
    throw new Error("Cannot initialize suggested partners");
  }

  return indexAllSuggestedPartnersAction?.payload as JSONAPIListResponse;
};
