import { ISharedOpportunity } from "models/PartnerConnection";

export const getSharingStatus = (
  sharedOpportunities: ISharedOpportunity[] | null
) => {
  if (!sharedOpportunities) {
    return;
  }

  return sharedOpportunities
    .map((opportunity) => getOpportunitySharingStatus(opportunity))
    .reduce(
      (acc, curr) => {
        return {
          isMinimalSharing: acc.isMinimalSharing && curr.isMinimalSharing,
          isNormalSharing: acc.isNormalSharing && curr.isNormalSharing,
        };
      },
      {
        isMinimalSharing: true,
        isNormalSharing: true,
      }
    );
};

const MEDIUM_SHARING_FIELD_NAMES: (keyof ISharedOpportunity)[] = [
  "stage",
  "amount",
  "close_date",
  "owner",
  "next_step",
  "opportunity_name",
];

// undefined means not shared, in the sharedOpportunity object
const getOpportunitySharingStatus = (sharedOpportunity: ISharedOpportunity) => {
  const isMinimalSharing = sharedOpportunity.status !== undefined;
  const isNormalSharing =
    sharedOpportunity.status !== undefined &&
    MEDIUM_SHARING_FIELD_NAMES.some(
      (field) => sharedOpportunity[field] !== undefined
    );

  return {
    isMinimalSharing,
    isNormalSharing,
  };
};
