import { OpenInNew } from "components/icons";
import Button from "components/ui/Button";
import { defineMessages } from "react-intl";
import { useHistory } from "react-router-dom";
import { SLACK_INTEGRATION_HASH } from "screens/Frontoffice/shared/helpers/types";

type Props = {
  disabled?: boolean;
};

export const SetUpSlackIntegrationButton = ({ disabled }: Props) => {
  const history = useHistory();
  const handleRedirect = () => {
    history.push(`/integrations${SLACK_INTEGRATION_HASH}`);
  };

  return (
    <Button
      disabled={disabled}
      onClick={handleRedirect}
      size="small"
      variant="secondary"
      label={i18n.buttonLabel}
      LeftIcon={OpenInNew}
    />
  );
};

const i18n = defineMessages({
  buttonLabel: {
    id: "CollaborationTab.SetUpSlackIntegrationButton.buttonLabel",
    defaultMessage: "Set up Slack integration",
  },
});
