import { makeStyles } from "makeStyles";
import { defineMessages, FormattedMessage } from "react-intl";

export const NewTag = ({
  color,
  count,
}: {
  count?: number;
  color: "primary" | "danger";
}) => {
  const { classes } = useStyles({ color });
  return (
    <div className={classes.root}>
      {count ?? <FormattedMessage {...i18n.new} />}
    </div>
  );
};

const i18n = defineMessages({
  new: {
    id: "NewTag.new",
    defaultMessage: "NEW",
  },
});

const useStyles = makeStyles<{ color: string }>()((theme, { color }) => ({
  root: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    padding: "0 6px",
    height: 16,
    borderRadius: 16,
    backgroundColor:
      color === "primary" ? theme.palette.purple : theme.palette.red500v2,
    color: theme.palette.ivory,
    fontSize: 10,
    fontWeight: 500,
    lineHeight: "150%",
  },
}));
