import {
  CircularProgress,
  FormControl,
  FormGroup,
  Grid,
  SelectChangeEvent,
} from "@mui/material";
import { isFulfilled } from "@reduxjs/toolkit";
import { ArrowRight } from "components/icons";
import Button from "components/ui/Button";
import DividerWithLabel from "components/ui/DividerWithLabel";
import { NotificationStatus } from "components/ui/Notifications/NotificationSnackbar";
import Popup from "components/ui/Popup";
import { SelectInput } from "components/ui/SelectInput";
import { TextInput } from "components/ui/TextInput";
import { T } from "components/ui/Typography";
import { Formik, FormikHelpers } from "formik";
import usePushNotification from "hooks/usePushNotification";
import forms from "i18n/forms";
import generic from "i18n/generic";
import _ from "lodash";
import { makeStyles } from "makeStyles";
import Record from "models/Record";
import { ChangeEvent } from "react";
import { defineMessages, FormattedMessage, useIntl } from "react-intl";
import { useDispatch } from "react-redux";
import { rawPost } from "redux/api/thunks";
import * as Yup from "yup";

import useRequiredFields from "../hooks/useRequiredFields";
import {
  FieldType,
  FieldValue,
  LeadStatus,
  PartnerStackPartnership,
  Values,
} from "../types";
import ReferStaticInfo from "./ReferStaticInfo";

type Props = {
  handleClose: () => void;
  accountToRefer: Record;
  partnerName: string;
  partnerStackPartnership: PartnerStackPartnership;
};

const ReferPartnerStackDialog = ({
  handleClose,
  accountToRefer,
  partnerName,
  partnerStackPartnership,
}: Props) => {
  const { classes } = useStyles();
  const intl = useIntl();
  const dispatch = useDispatch();
  const pushNotification = usePushNotification();

  const { loading, fields } = useRequiredFields(partnerStackPartnership.id);

  const referLead = async (values: Values) => {
    const meta: { [x: string]: string | string[] } = {};
    _.each(values.fields, (field: FieldValue, i: number) => {
      if (fields[i].type === FieldType.Checkbox) {
        meta[field.fieldName] = [field.value];
      } else {
        meta[field.fieldName] = field.value;
      }
    });
    const result = await dispatch(
      rawPost({
        type: "partnerstack",
        path: `partnerships/${partnerStackPartnership.id}/leads/`,
        payload: {
          source: "partner",
          data: {
            meta: meta,
            external_key: accountToRefer.rawCompanyProviderKey,
            status: LeadStatus.Open,
            partner_key: partnerStackPartnership.partnerKey,
          },
        },
      })
    );
    if (isFulfilled(result)) {
      handleClose();
      pushNotification(
        {
          status: NotificationStatus.success,
          message: i18n.leadSubmitted,
          description: i18n.leadSubmittedDescription,
        },
        {
          partnerName,
        },
        undefined
      );
    } else {
      pushNotification("default_error");
    }
  };

  const handleSave = async (values: Values, actions: FormikHelpers<Values>) => {
    await referLead(values);
    actions.resetForm();
  };

  // Formik helpers

  // Last name is displayed with first name
  const lastNameIndex = fields.findIndex((field) => field.name === "last_name");

  const initialValues: Values = {
    fields: fields.map((field) => ({
      fieldName: field.name,
      value: field.name === "company" ? accountToRefer.rightName : "",
    })),
  };

  const validationSchema = Yup.object().shape({
    fields: Yup.array().of(
      Yup.object().shape({
        fieldName: Yup.string().required(() => forms.generic_required),
        value: Yup.string().required(() => forms.generic_required),
      })
    ),
  });

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={handleSave}
      validationSchema={validationSchema}
      enableReinitialize
    >
      {({
        values,
        setFieldTouched,
        handleSubmit,
        handleChange,
        isSubmitting,
        errors,
        touched,
        resetForm,
      }) => (
        <Popup
          variant="secondary"
          title={
            <div>
              <T className={classes.title}>
                <FormattedMessage
                  {...i18n.title}
                  values={{
                    account: accountToRefer.rightName,
                    vendor: partnerName,
                  }}
                />
              </T>
              <T className={classes.subtitle}>
                <FormattedMessage
                  {...i18n.subtitle}
                  values={{
                    vendor: partnerName,
                  }}
                />
              </T>
            </div>
          }
          width={560}
          isOpen
          handleClose={handleClose}
          hideActions
          customActions={
            !loading ? (
              <>
                <Button
                  label={generic.cancel}
                  onClick={() => {
                    handleClose();
                    resetForm();
                  }}
                  variant="secondary"
                />
                <Button
                  RightIcon={ArrowRight}
                  label={
                    <FormattedMessage
                      {...i18n.saveButton}
                      values={{ vendor: partnerName }}
                    />
                  }
                  onClick={handleSubmit}
                  loading={isSubmitting}
                />
              </>
            ) : undefined
          }
        >
          {!loading ? (
            <Grid container direction={"column"} rowSpacing={1}>
              <ReferStaticInfo {...{ classes }} />
              <DividerWithLabel
                className={classes.divider}
                align="center"
                value={i18n.divider}
              />
              <>
                {values.fields.map((x, i) => {
                  // Skip last_name as it will be displayed with first_name
                  if (x.fieldName === "last_name") {
                    return null;
                  }
                  const fieldError = !_.isEmpty(
                    (errors.fields?.length && errors.fields[i]) || {}
                  );
                  const fieldTouched =
                    (touched.fields?.length && touched.fields[i]) || false;
                  const fieldType = fields[i].type;
                  const hasOptions =
                    fieldType === FieldType.Checkbox ||
                    fieldType === FieldType.Picklist;
                  const isDateTime = fieldType === FieldType.Datetime;
                  const isTextArea = fieldType === FieldType.TextArea;
                  const isNumber = fieldType === FieldType.Number;
                  return (
                    <Grid key={i} container item>
                      <Grid item xs={3}>
                        <T
                          className={
                            isTextArea
                              ? classes.inputMultilineDescription
                              : classes.inputDescription
                          }
                        >
                          {x.fieldName === "first_name" &&
                          lastNameIndex !== -1 ? (
                            <FormattedMessage {...i18n.contact} />
                          ) : (
                            fields[i].label
                          )}
                        </T>
                      </Grid>
                      <Grid item xs={9}>
                        {x.fieldName === "first_name" &&
                        lastNameIndex !== -1 ? (
                          <FormGroup row className={classes.formGroup}>
                            <FormControl fullWidth>
                              <TextInput
                                variant="newDesign"
                                className={classes.input}
                                name={`fields[${i}]`}
                                placeholder={fields[i].label}
                                error={Boolean(fieldTouched && fieldError)}
                                onChange={(
                                  e: ChangeEvent<HTMLInputElement>
                                ) => {
                                  setFieldTouched(e.target.name, false);
                                  handleChange({
                                    target: {
                                      name: `fields[${i}].value`,
                                      value: e.target.value,
                                    },
                                    type: "change",
                                  });
                                }}
                                inputProps={{ "data-testid": `fields[${i}]` }}
                                value={values.fields[i].value}
                              />
                            </FormControl>
                            <FormControl fullWidth>
                              <TextInput
                                variant="newDesign"
                                className={classes.input}
                                name={`fields[${lastNameIndex}]`}
                                placeholder={fields[lastNameIndex].label}
                                error={Boolean(
                                  touched.fields &&
                                    touched.fields[lastNameIndex] &&
                                    errors.fields &&
                                    errors.fields[lastNameIndex]
                                )}
                                onChange={(
                                  e: ChangeEvent<HTMLInputElement>
                                ) => {
                                  setFieldTouched(e.target.name, false);
                                  handleChange({
                                    target: {
                                      name: `fields[${lastNameIndex}].value`,
                                      value: e.target.value,
                                    },
                                    type: "change",
                                  });
                                }}
                                inputProps={{
                                  "data-testid": `fields[${lastNameIndex}]`,
                                }}
                                value={values.fields[lastNameIndex].value}
                              />
                            </FormControl>
                          </FormGroup>
                        ) : (
                          <FormControl fullWidth>
                            {hasOptions ? (
                              <SelectInput
                                variant="tertiary"
                                className={classes.input}
                                name={`fields[${i}]`}
                                placeholder={intl.formatMessage(
                                  i18n.selectValue
                                )}
                                error={Boolean(fieldTouched && fieldError)}
                                onChange={(e: SelectChangeEvent) => {
                                  setFieldTouched(e.target.name, false);
                                  handleChange({
                                    target: {
                                      name: `fields[${i}].value`,
                                      value: e.target.value,
                                    },
                                    type: "change",
                                  });
                                }}
                                inputProps={{ "data-testid": `fields[${i}]` }}
                                options={fields[i].options}
                                value={values.fields[i].value}
                              />
                            ) : (
                              <TextInput
                                type={
                                  isDateTime
                                    ? "date"
                                    : isNumber
                                    ? "number"
                                    : "text"
                                }
                                variant="newDesign"
                                className={classes.input}
                                name={`fields[${i}]`}
                                placeholder={fields[i].placeholder}
                                error={Boolean(fieldTouched && fieldError)}
                                onChange={(
                                  e: ChangeEvent<HTMLInputElement>
                                ) => {
                                  setFieldTouched(e.target.name, false);
                                  handleChange({
                                    target: {
                                      name: `fields[${i}].value`,
                                      value: e.target.value,
                                    },
                                    type: "change",
                                  });
                                }}
                                inputProps={{ "data-testid": `fields[${i}]` }}
                                multiline={isTextArea}
                                rows={isTextArea ? "3" : "1"}
                                rowsmax={isTextArea ? "10" : "1"}
                                decimal={isNumber}
                                value={values.fields[i].value}
                              />
                            )}
                          </FormControl>
                        )}
                      </Grid>
                    </Grid>
                  );
                })}
              </>
            </Grid>
          ) : (
            <div className={classes.loaderContainer}>
              <CircularProgress size={25} />
            </div>
          )}
        </Popup>
      )}
    </Formik>
  );
};

export default ReferPartnerStackDialog;

// CSS

const useStyles = makeStyles()((theme) => ({
  title: {
    fontSize: 18,
    fontWeight: "bold",
    textAlign: "center",
    marginBottom: 10,
  },
  subtitle: {
    fontSize: 12,
    textAlign: "center",
  },
  divider: {
    marginTop: 16,
    marginBottom: 16,
  },
  inputDescription: { paddingTop: 10, paddingBottom: 10 },
  inputMultilineDescription: { paddingTop: 27, paddingBottom: 27 },
  formGroup: {
    flexWrap: "nowrap",
    [theme.breakpoints.down("sm")]: {
      flexWrap: "wrap",
    },
    columnGap: 8,
  },
  input: {
    fontSize: 12,
  },
  loaderContainer: {
    display: "flex",
    justifyContent: "center",
    padding: 25,
  },
}));

// I18N

const i18n = defineMessages({
  title: {
    id: "DataTables.ActionBtn.ReferPartnerStackDialog.title",
    defaultMessage: "Refer {account} to {vendor} via PartnerStack",
  },
  subtitle: {
    id: "DataTables.ActionBtn.ReferPartnerStackDialog.subtitle",
    defaultMessage: "You’re submitting a lead to {vendor}",
  },
  divider: {
    id: "DataTables.ActionBtn.ReferPartnerStackDialog.divider",
    defaultMessage: "Please fill in the lead details",
  },
  contact: {
    id: "DataTables.ActionBtn.ReferPartnerStackDialog.contact",
    defaultMessage: "Contact",
  },
  selectValue: {
    id: "DataTables.ActionBtn.ReferPartnerStackDialog.selectValue",
    defaultMessage: "Select value",
  },
  saveButton: {
    id: "DataTables.ActionBtn.ReferPartnerStackDialog.saveButton",
    defaultMessage: "Refer lead to {vendor}",
  },
  leadSubmitted: {
    id: "DataTables.ActionBtn.ReferPartnerStackDialog.leadSubmitted",
    defaultMessage: "Your lead has been successfully submitted!",
  },
  leadSubmittedDescription: {
    id: "DataTables.ActionBtn.ReferPartnerStackDialog.leadSubmittedDescription",
    defaultMessage:
      "1 lead has been successfully submitted to {partnerName} via PartnerStack.",
  },
});

export const _private = {
  i18n,
};
