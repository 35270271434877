import { createAsyncThunk, isFulfilled, isRejected } from "@reduxjs/toolkit";
import _ from "lodash";
import { Factory } from "models";
import { rawGet } from "redux/api/thunks";

import {
  PartnershipUpsidePotentialData,
  RootStateWithUpsides,
  UpsidesActions,
} from "./types";

export const DEFAULT_BATCH_SIZE = 25;

export const loadPartnershipUpsidePotential = createAsyncThunk(
  UpsidesActions.loadPartnershipUpsidePotential,
  async (hasAdvancedAnalytics: boolean, thunkAPI) => {
    const state = thunkAPI.getState() as RootStateWithUpsides;
    const ready = state.context.ready;
    let page = 1;

    let result: {
      [partnershipId: number]: PartnershipUpsidePotentialData;
    } = {};
    const partnershipIds = hasAdvancedAnalytics
      ? state.dashboard.upsidePartnershipIds
      : state.dashboard.upsidePartnershipIds.slice(0, 3);
    if (ready && partnershipIds.length > 0) {
      const getUpsidePotential = await thunkAPI.dispatch(
        rawGet({
          type: "partnership_upside_potentials",
          path: "",
          options: {
            filters: {
              partnership_id__in: partnershipIds,
            },
            page: { number: page, size: DEFAULT_BATCH_SIZE },
          },
        })
      );

      if (isRejected(getUpsidePotential)) {
        throw new Error("Cannot load partnership upside potential");
      }

      const recordCount = _.get(getUpsidePotential.payload, "meta", {
        record_count: 0,
      }).record_count;
      const maxPages = Math.ceil(recordCount / DEFAULT_BATCH_SIZE);
      let data = _.get(getUpsidePotential.payload, "data", []);

      const responses = await Promise.all(
        _.range(page + 1, maxPages + 1).map((value) =>
          thunkAPI.dispatch(
            rawGet({
              type: "partnership_upside_potentials",
              path: "",
              options: {
                filters: {
                  partnership_id__in: partnershipIds,
                },
                page: { number: value, size: DEFAULT_BATCH_SIZE },
              },
            })
          )
        )
      );

      responses.forEach((response) => {
        if (isFulfilled(response)) {
          let newData = _.get(response.payload, "data", []);
          data = [...data, ...newData];
        }
      });

      data.forEach((item: any) => {
        const record = Factory.createRecord(item);
        result[record.partnershipId] = {
          avgDealSize: record.avgDealSize ?? null,
          avgWinRate: record.avgWinRate ?? null,
          dealSizeIncrease: record.dealSizeIncrease ?? null,
          influenceableProspectsRevenuePotential:
            record?.influenceableProspectsRevenuePotential > 0
              ? record.influenceableProspectsRevenuePotential
              : null,
          revenuePotential:
            record.revenuePotential && record.revenuePotential > 0
              ? record.revenuePotential
              : null,
          newProspectsRevenuePotential:
            record.newProspectsRevenuePotential &&
            record.newProspectsRevenuePotential > 0
              ? record.newProspectsRevenuePotential
              : null,
          sourceableProspectsRevenuePotential:
            record?.sourceableProspectsRevenuePotential > 0
              ? record.sourceableProspectsRevenuePotential
              : null,
          winRateIncrease: record.winRateIncrease
            ? record.winRateIncrease
            : null,
          winRateOnPartnersCustomersVerifiesCentralLimit: !!record.winRateOnPartnersCustomersVerifiesCentralLimit,
        } as PartnershipUpsidePotentialData;
      });
    }
    return result;
  }
);
