import Icon from "@mui/material/Icon";
import IconButton from "@mui/material/IconButton";
import Toolbar from "@mui/material/Toolbar";
import Tooltip from "@mui/material/Tooltip";
import { makeStyles } from "makeStyles";
import { ReactElement, ReactNode } from "react";
import { defineMessages, useIntl } from "react-intl";

type ListTableToolbarProps = {
  action?: ReactNode;
  toggleFilters?: $TSFixMeFunction;
  title: string | ReactElement | ReactElement[];
};

export const ListTableToolbar = ({
  action,
  toggleFilters,
  title,
}: ListTableToolbarProps) => {
  const intl = useIntl();
  const { classes } = useStyles();
  return (
    <Toolbar className={classes.tableToolbar}>
      <div className={classes.title}>{title}</div>
      <div className={classes.spacer} />
      {toggleFilters && (
        <Tooltip title="Filter list">
          <IconButton
            aria-label={intl.formatMessage(i18n.filter_list)}
            onClick={toggleFilters}
            size="large"
          >
            <Icon className={classes.search}>search</Icon>
          </IconButton>
        </Tooltip>
      )}
      {action}
    </Toolbar>
  );
};

export default ListTableToolbar;

// CSS

const useStyles = makeStyles()((theme) => ({
  tableToolbar: {
    paddingRight: theme.spacing(1),
    color: theme.palette.white,
    backgroundImage: theme.palette.gradients.darkPurpleToGreen,
  },

  title: {
    flex: "0 0 auto",
  },

  spacer: {
    flex: "1 1 100%",
  },

  search: {
    color: theme.palette.offWhite,
  },
}));

// I18N

const i18n = defineMessages({
  filter_list: {
    id: "backoffice.table.toolbar.filter_list",
    defaultMessage: "Filter list",
  },
});
