import { createSvgIcon } from "@mui/material/utils";

import { SvgIcon } from "./types";

const displayName = "CircleCheck";

const CircleCheck: SvgIcon = createSvgIcon(
  // Using transform to enlarge from provided viewbox="0 0 16 16"
  // to default "0 0 24 24" from `createSvgIcon` -> `SvgIcon`.
  <g fill="none" transform="matrix(1.5,0,0,1.5,0,0)">
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M1.5 8C1.5 4.41015 4.41015 1.5 8 1.5C11.5899 1.5 14.5 4.41015 14.5 8C14.5 11.5899 11.5899 14.5 8 14.5C4.41015 14.5 1.5 11.5899 1.5 8ZM11.6036 6.85355C11.7988 6.65829 11.7988 6.34171 11.6036 6.14645C11.4083 5.95118 11.0917 5.95118 10.8964 6.14645L7.57224 9.47065C7.37337 9.66953 7.04965 9.6653 6.85603 9.46131L5.11265 7.62454C4.92255 7.42425 4.60607 7.41599 4.40579 7.6061C4.2055 7.7962 4.19724 8.11268 4.38735 8.31296L6.13073 10.1497C6.71158 10.7617 7.68273 10.7744 8.27935 10.1778L11.6036 6.85355Z"
      fill="currentColor"
    />
  </g>,
  displayName
);

CircleCheck.displayName = displayName;
export default CircleCheck;
