import CircularProgress from "@mui/material/CircularProgress";
import { T } from "components/ui/Typography";
import usePushNotification from "hooks/usePushNotification";
import { makeStyles } from "makeStyles";
import { useEffect, useState } from "react";
import { defineMessages, FormattedMessage } from "react-intl";
import JSONAPIService from "services/JSONAPIService";

type Props = {
  filename?: string;
  file: File;
  partnershipId?: number;
  onUploadSuccess: (integrationId: number) => void;
  onUploadError: () => void;
};

const UploadManager = ({
  filename,
  file,
  partnershipId,
  onUploadSuccess,
  onUploadError,
}: Props) => {
  const [uploaded, setUploaded] = useState(0);
  const [mounted, setMounted] = useState(true);
  const { classes } = useStyles();
  const pushNotification = usePushNotification();

  useEffect(() => {
    const service = new JSONAPIService(
      partnershipId ? "ghost_sources" : "sources"
    );
    const payload = new FormData();
    payload.append("source_file", file);
    payload.append("name", filename || file.name);
    if (partnershipId) {
      payload.append("ghost_partnership_id", String(partnershipId));
    }
    const config = service.authenticate({
      headers: {
        Accept: "application/vnd.api+json",
        "Content-Type": "multipart/form-data",
      },
      onUploadProgress: ({ loaded, total }: $TSFixMe) =>
        setUploaded(Math.round((loaded / total) * 100)),
    });
    service.axios
      .post("/upload/", payload, config)
      .then((response: $TSFixMe) => {
        onUploadSuccess(response.data.data.attributes.integration_id);
      })
      .catch(() => {
        pushNotification("default_error");
        onUploadError();
      })
      .finally(() => {
        if (mounted) {
          setUploaded(100);
        }
      });
    return () => setMounted(false);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const uploading = uploaded < 100;

  return (
    <div className={classes.root}>
      <CircularProgress
        size={28}
        value={uploaded}
        variant={uploading ? "determinate" : "indeterminate"}
      />
      <T titleSmall>
        {uploading ? (
          <FormattedMessage {...i18n.uploadingText} values={{ uploaded }} />
        ) : (
          <FormattedMessage {...i18n.processingText} />
        )}
      </T>
    </div>
  );
};

export default UploadManager;

const i18n = defineMessages({
  uploadingText: {
    id: "UploadManager.uploadingText",
    defaultMessage: "Uploading file ({uploaded}%)",
  },
  processingText: {
    id: "UploadManager.processingText",
    defaultMessage: "Waiting for server to process the file",
  },
});

const useStyles = makeStyles()((theme) => ({
  root: {
    border: `1px dashed ${theme.palette.greyscale500}`,
    borderRadius: 8,
    display: "flex",
    alignItems: "center",
    columnGap: theme.spacing(1.5),
    padding: theme.spacing(2),
    minWidth: 400,
  },
}));
