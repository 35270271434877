import { JSONSerializable } from "models/types";
import {
  FilterType,
  PersistedColumnType,
  SortType,
} from "screens/Frontoffice/screens/DataTables/shared/types";

import {
  OldRootStateWithAccountMapping,
  RootStateWithAccountMapping,
} from "./accountMapping/types";
import { RootStateWithAnalytics } from "./analytics/types";
import { RootStateWithAPI } from "./api/typing";
import {
  RootStateWithDashboard,
  RootStateWithPartnerships,
} from "./dashboard/types";
import { RootStateWithDirectory } from "./directory/types";
import { RootStateWithContext } from "./init/typing";
import { RootStateWithMapping360 } from "./mapping360/typing";
import { RootStateWithMapping360NearboundAccounts } from "./mapping360NearboundAccounts/typing";
import { RootStateWithMeasure } from "./measure/types";
import { RootStateWithNonUser } from "./nonUser/typing";
import { RootStateWithNotificationWidget } from "./notificationWidget/types";
import { RootStateWithOnboarding } from "./onboarding/typing";
import { RootStateWithOverview } from "./overview/types";
import { PipelineExpectedState } from "./pipeline/typing";
import { PipelineAttributeExpectedState } from "./pipelineAttribute/typing";
import { RootStateWithPremiumPlanWidget } from "./premiumPlanWidget/types";
import { RevealStateWithStorage } from "./storage/typing";
import { RootStateWithSuggestedPartners } from "./suggestedPartners/types";
import { RootStateWithUpsides } from "./upsides/types";
import { RootStateWithUser } from "./user/typing";

export type RevealStore = PipelineExpectedState &
  PipelineAttributeExpectedState &
  RootStateWithAPI &
  RootStateWithUser &
  RootStateWithContext &
  RootStateWithAccountMapping &
  OldRootStateWithAccountMapping &
  RootStateWithMapping360 &
  RootStateWithMapping360NearboundAccounts &
  RootStateWithAnalytics &
  RootStateWithNotificationWidget &
  RootStateWithDashboard &
  RootStateWithDirectory &
  RootStateWithSuggestedPartners &
  RootStateWithOnboarding &
  RootStateWithOverview &
  RootStateWithPartnerships &
  RootStateWithPremiumPlanWidget &
  RevealStateWithStorage &
  RootStateWithUpsides &
  RootStateWithNonUser &
  RootStateWithMeasure;

export const asJSONSerializable = (
  values: (PersistedColumnType | FilterType | SortType)[]
): JSONSerializable[] => {
  return values.map((val: PersistedColumnType | FilterType | SortType) => ({
    ...val,
  }));
};

export enum DateRangeTimeframe {
  thisMonth = "THIS_MONTH",
  lastMonth = "LAST_MONTH",
  thisQuarter = "THIS_QUARTER",
  previousQuarter = "PREVIOUS_QUARTER",
  thisYear = "THIS_YEAR",
  previousYear = "PREVIOUS_YEAR",
  customRange = "CUSTOM_RANGE",
}

export type CustomDateRangeFilterType = {
  rangeType: DateRangeTimeframe.customRange;
  selectionRange: { start: Date | null; end: Date | null };
};

type PresetDateRangeFilterType = {
  rangeType:
    | DateRangeTimeframe.thisMonth
    | DateRangeTimeframe.lastMonth
    | DateRangeTimeframe.thisQuarter
    | DateRangeTimeframe.previousQuarter
    | DateRangeTimeframe.thisYear
    | DateRangeTimeframe.previousYear;
};

export type DateRangeFilterType =
  | CustomDateRangeFilterType
  | PresetDateRangeFilterType;
