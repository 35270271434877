import { Download, OpenInNew } from "components/icons";
import { ConfigContext } from "config/ConfigProvider";
import { useContext } from "react";
import { defineMessages, useIntl } from "react-intl";

import { BaseSidePanel } from "./BaseSidePanel";
import { ChatHelpBox } from "./ChatHelpBox";
import { VideoWithHelpBox } from "./VideoWithHelpBox";

export const CsvSidePanel = () => {
  const intl = useIntl();
  const config = useContext(ConfigContext);

  return (
    <BaseSidePanel>
      <VideoWithHelpBox
        i18nTitle={i18n.howToTitle}
        i18nSubtitle={i18n.text}
        linkElementList={[
          {
            i18n: i18n.readGuidelines,
            url: config.uploadACsvLink as string,
            icon: <OpenInNew />,
          },
          {
            i18n: i18n.downloadTemplate,
            url: config.templateFilePath as string,
            icon: <Download />,
          },
        ]}
        src={"https://www.youtube.com/embed/La5cptLCs24"}
        videoTitle={intl.formatMessage(i18n.videoCsv)}
      />
      <ChatHelpBox />
    </BaseSidePanel>
  );
};

const i18n = defineMessages({
  howToTitle: {
    id: "crm.settings.CsvSidePanel.howToTitle",
    defaultMessage: "How to plug your CSV file?",
  },
  text: {
    id: "crm.settings.CsvSidePanel.text",
    defaultMessage:
      "Start with a CSV file to quickly trial Reveal before you connect your actual CRM.",
  },
  readGuidelines: {
    id: "crm.settings.CsvSidePanel.readGuidelines",
    defaultMessage: "<a>Read Guidelines</a>",
  },
  downloadTemplate: {
    id: "crm.settings.CsvSidePanel.downloadTemplate",
    defaultMessage: "<lnk>Download Template</lnk>",
  },
  videoCsv: {
    id: "crm.settings.CsvSidePanel.videoCsv",
    defaultMessage: "Video tutorial for CSV upload",
  },
});
