import { Box, CircularProgress, Divider } from "@mui/material";
import Grid from "@mui/material/Grid";
import { Copy, Launch, PersonStar } from "components/icons";
import CompanyAvatar from "components/ui/avatars/CompanyAvatar";
import { CollapseList, CollapseWidget } from "components/ui/Collapse";
import Tooltip from "components/ui/Tooltip";
import { T } from "components/ui/Typography";
import useRecord from "hooks/useRecord";
import useSegment from "hooks/useSegment";
import useUserProfile from "hooks/useUserProfile";
import { makeStyles } from "makeStyles";
import ModelRecord from "models/Record";
import User from "models/User";
import { ReactNode, useCallback, useEffect, useState } from "react";
import {
  defineMessages,
  FormattedMessage,
  IntlShape,
  useIntl,
} from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { AccountMappingResource } from "redux/accountMapping/types";
import { retreive } from "redux/api/thunks";
import { selectActiveAndGhostPartnerships } from "redux/init/selectors";
import { GetIntroButton } from "screens/Frontoffice/screens/DataTables/screens/Mapping360/components/AccountView/components/AccountDetails/components/AccountTabs/components/GetIntroButton";
import { ListedOnOpportunitiesLabel } from "screens/Frontoffice/screens/DataTables/screens/Mapping360/components/AccountView/components/ListedOnOpportunitiesLabel";
import { PartnerAccount } from "screens/Frontoffice/screens/DataTables/screens/Mapping360/types";
import {
  getCommonContacts,
  removeDuplicates,
} from "screens/Frontoffice/screens/DataTables/screens/Mapping360/utils";
import { DetailedViewType } from "screens/Frontoffice/screens/DataTables/shared/types";
import { WebhookEventName, WebhookService } from "services/WebhookService";

import { AddToPipelineButton } from "./AddToPipelineButton";
import { TextWithCopyIcon } from "./TextWithCopyIcon";

type Props = {
  account: PartnerAccount;
  crmAccount: ModelRecord<"crm_accounts">;
  hideStatus?: boolean;
};

const PartnerAccountCard = ({ account, crmAccount, hideStatus }: Props) => {
  const { classes, cx } = useStyles();
  const dispatch = useDispatch();
  const intl = useIntl();
  const { track } = useSegment();
  const service = new WebhookService();
  const { profile } = useUserProfile();
  const matchId = account.matches[0].id; // match with the highest score
  const partnerships = useSelector(selectActiveAndGhostPartnerships);
  const [copied, setCopied] = useState(false);
  const partnership = partnerships.find(
    (partnership) =>
      account.partner?.id === partnership.destCompany?.id ||
      account.partner?.id === partnership.initiatorCompany.id
  );
  const partnerPartnershipOwner = [
    account.matches[0].partnershipDestOwner,
    account.matches[0].partnershipInitiatorOwner,
  ].find((ownerItem) => ownerItem?.companyName !== profile?.company?.name);
  const { record: match } = useRecord("matches", matchId, false, {
    include: ["right_contacts_data"],
  });

  useEffect(() => {
    if (copied) {
      const timer = setTimeout(() => setCopied(false), 2000);
      return () => clearTimeout(timer);
    }
  }, [copied]);

  const [isUnfolded, setIsUnfolded] = useState<boolean>(false);

  const handleCollapse = () => {
    // Load contacts only one time
    if (!isUnfolded && !match) {
      loadContacts();
    }
    setIsUnfolded(!isUnfolded);
  };

  const handleTrackEmail = () => {
    setCopied(true);
    service.track({
      profile: profile,
      eventName: WebhookEventName.RevealCopiedAEEmailFromAccountDetailDrawer,
      partnership: partnership,
      crmAccount: crmAccount,
      match: match,
    });
    track("Copied account owner email", {
      source: DetailedViewType.ACCOUNT_DRAWER,
    });
  };

  const loadContacts = useCallback(async () => {
    await dispatch(
      retreive({
        id: matchId,
        type: AccountMappingResource.matches,
        options: {
          fields: {
            [AccountMappingResource.matches]: ["right_contacts_data"],
          },
        },
      })
    );
  }, [dispatch, matchId]);

  const totalCount =
    account.matches[0].extraContactDataV2.number_of_contacts ?? 0;
  const commonCount =
    account.matches[0].extraContactDataV2.number_of_common_contacts ?? 0;
  const primaryCount =
    account.matches[0].extraContactDataV2.number_of_primary_contacts ?? 0;

  // Usefull to count unspecified elements
  const commonContacts = getCommonContacts(match, true);
  const commonContactsUnspecifiedCount = commonContacts.filter(
    (item) => !Boolean(item.jobtitle)
  ).length;
  // Clean array
  const commonContactsSpecifiedJob = removeDuplicates(
    commonContacts
  ).filter((item) => Boolean(item.jobtitle));

  // Usefull to count unspecified elements
  const otherContacts = getCommonContacts(match, false);
  const otherContactsUnspecifiedCount = otherContacts.filter(
    (item) => !Boolean(item.jobtitle)
  ).length;
  // Clean array
  const otherContactsSpecifiedJob = removeDuplicates(
    otherContacts
  ).filter((item) => Boolean(item.jobtitle));

  // Add header, show contacts in two columns and add unspecified count
  const getItemList = (isCommonContactsList: boolean) => {
    let firstItem = (
      <div className={classes.row}>
        <div className={classes.firstItem}>
          <T bold>
            {isCommonContactsList
              ? intl.formatMessage(i18n.commonContacts)
              : intl.formatMessage(i18n.otherPartnersContacts)}
          </T>
          <span className={classes.count}>
            {isCommonContactsList
              ? commonContacts.length
              : otherContacts.length}
          </span>
        </div>
      </div>
    );

    let items = (isCommonContactsList
      ? commonContactsSpecifiedJob
      : otherContactsSpecifiedJob
    ).map((contact, index) => {
      return (
        <div
          className={classes.row}
          key={`contact-common-${isCommonContactsList}-${index}`}
        >
          <Box display="flex" alignItems="center" gap="8px">
            <T>{contact.jobtitle}</T>
            {contact.contactUrl && (
              <Tooltip title={<FormattedMessage {...i18n.openInCrm} />}>
                <a
                  className={classes.icon}
                  href={contact.contactUrl}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <Launch className={classes.icon} />
                </a>
              </Tooltip>
            )}
          </Box>
          {contact.isPrimary && <ListedOnOpportunitiesLabel />}
        </div>
      );
    });

    let lastItem = (
      <T className={classes.lastItem}>
        {isCommonContactsList
          ? intl.formatMessage(i18n.unspecified, {
              unspecifiedCount: commonContactsUnspecifiedCount,
            })
          : intl.formatMessage(i18n.unspecified, {
              unspecifiedCount: otherContactsUnspecifiedCount,
            })}
      </T>
    );

    // Add items to the list
    let list = [firstItem, ...items];
    if (
      (isCommonContactsList && commonContactsUnspecifiedCount > 0) ||
      (!isCommonContactsList && otherContactsUnspecifiedCount > 0)
    ) {
      list.push(lastItem);
    }
    return list;
  };

  return (
    <Grid container className={classes.partnerCard} spacing={2}>
      <Grid item className={classes.title} xs={12}>
        <CompanyAvatar
          company={account.partner}
          companyName={account.ghostPartner?.companyName}
          companyDomain={account.ghostPartner?.companyDomain}
          classes={{ avatar: classes.avatar }}
        />
        <Box flexGrow={1}>
          <T className={classes.companyNameTitle}>
            {account.partner?.name || account.ghostPartner?.companyName || ""}
          </T>
          <Box display="flex" gap="4px">
            <T className={classes.label}>
              <FormattedMessage {...i18n.partnershipOwner} />
            </T>
            {partnerPartnershipOwner && (
              <Box className={classes.ownerContainer}>
                <T bodySmall>
                  {partnerPartnershipOwner.email ? (
                    <TextWithCopyIcon
                      email={partnerPartnershipOwner.email}
                      handleTrack={handleTrackEmail}
                      hoverText={intl.formatMessage(i18n.copyEmail, {
                        email: partnerPartnershipOwner.email,
                      })}
                      text={partnerPartnershipOwner.fullName}
                    />
                  ) : (
                    <span>{partnerPartnershipOwner.fullName}</span>
                  )}
                </T>
              </Box>
            )}
          </Box>
        </Box>
        {profile.ownsPipeline && (
          <AddToPipelineButton account={crmAccount} partnership={partnership} />
        )}
        {partnership && (
          <GetIntroButton
            accountId={crmAccount.id}
            partnershipId={partnership.id}
            isSecondary={profile.ownsPipeline}
          />
        )}
      </Grid>
      <Grid item className={classes.content}>
        <Grid
          container
          spacing={2}
          direction="column"
          margin="unset"
          width="unset"
        >
          {!hideStatus && account.firstWonOpportAt && (
            <Grid item className={classes.rowLabelContainer}>
              <T className={cx(classes.label, classes.rowLabel)}>
                <FormattedMessage
                  {...i18n.partnerStatus}
                  values={{ name: crmAccount.name }}
                />
              </T>
              <T>
                {getCustomerFor(
                  account.firstWonOpportAt,
                  account.partner?.name || account.ghostPartner?.companyName
                )}
              </T>
            </Grid>
          )}
          <Grid item className={classes.rowLabelContainer}>
            <T className={cx(classes.label, classes.rowLabel)}>
              <FormattedMessage
                {...i18n.accountOwner}
                values={{ name: crmAccount.name }}
              />
            </T>
            <Box>
              {getOwners(
                intl,
                account.owners ?? [],
                classes,
                handleTrackEmail,
                copied
              ).map((label) => label)}
            </Box>
          </Grid>
          <Grid item className={classes.rowLabelContainer}>
            <T className={cx(classes.label, classes.rowLabel)}>
              <FormattedMessage {...i18n.listOfContacts} />
            </T>
            <CollapseWidget
              Icon={PersonStar}
              isUnfolded={isUnfolded}
              onClick={totalCount > 0 ? handleCollapse : undefined}
              label={
                <Box display="flex" gap="4px" flexDirection="column">
                  <T>
                    {intl.formatMessage(i18n.totalContacts, {
                      ...{
                        totalContactCount: totalCount ?? 0,
                        primaryCount: primaryCount ?? 0,
                        commonCount: commonCount ?? 0,
                      },
                    })}
                  </T>
                </Box>
              }
            >
              {!match ? (
                <div className={classes.loader}>
                  <CircularProgress size={20} />{" "}
                </div>
              ) : null}
              {commonContacts.length > 0 ? (
                <CollapseList items={getItemList(true)} />
              ) : null}
              {commonContacts.length > 0 && otherContacts.length > 0 && (
                <Divider className={classes.divider} />
              )}
              {otherContacts.length > 0 ? (
                <CollapseList items={getItemList(false)} />
              ) : null}
            </CollapseWidget>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default PartnerAccountCard;

// CSS

const useStyles = makeStyles()((theme) => ({
  content: {
    padding: theme.spacing(2),
  },
  ownerContainer: {
    alignItems: "center",
    display: "flex",
    gap: 4,
    height: 18,
  },
  ownerCopyContainer: {
    alignItems: "center",
    borderRadius: 4,
    cursor: "pointer",
    display: "flex",
    gap: 4,
    padding: "0 1px",
    "&:hover": {
      background: theme.palette.greyscale100,
    },
  },
  avatar: {
    borderRadius: 6.66,
    height: 33.3,
    width: 33.3,
  },
  companyNameTitle: {
    color: theme.palette.midnight,
    fontWeight: 600,
    fontSize: 14,
    height: 21,
    lineHeight: "150%",
  },
  divider: {
    background: theme.palette.greyLight050,
  },
  icon: {
    display: "flex",
    height: 10,
    width: 10,
    "& path": {
      fill: theme.palette.alpha800,
    },
  },
  label: {
    color: theme.palette.alpha500,
  },
  partnerCard: {
    flexDirection: "column",
    flexWrap: "nowrap",
    margin: 0,
    borderRadius: 8,
    border: `1px solid ${theme.palette.greyLight100}`,
    width: "100%",
  },
  title: {
    alignItems: "center",
    background: theme.palette.greyLight050Alpha050,
    display: "flex",
    flexDirection: "row",
    gap: 6,
    padding: "10px 10px !important",
  },
  loader: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },
  row: {
    alignItems: "center",
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
    padding: "4px 6px",
  },
  rowLabel: {
    flexGrow: 1,
    maxWidth: 216,
  },
  greenIcon: {
    height: 16,
    width: 16,
    color: theme.palette.green700,
  },
  redIcon: {
    height: 16,
    width: 16,
    color: theme.palette.red600,
  },
  firstItem: {
    display: "flex",
    flexDirection: "row",
    columnGap: 8,
  },
  copyIcon: {
    color: theme.palette.midnight,
    height: 12,
    width: 12,
  },
  count: {
    backgroundColor: theme.palette.greyscale200,
    padding: "2px 5px 2px 5px",
    borderRadius: 17,
    fontSize: 9,
  },
  lastItem: {
    color: theme.palette.alpha500,
    fontStyle: "italic",
  },
  link: {
    color: theme.palette.purple,
    fontWeight: 400,
    fontSize: 12,
    lineHeight: "150%",
  },
  rowLabelContainer: {
    display: "flex",
    gap: 8,
    paddingLeft: "0 !important",
    paddingTop: "6px !important",
  },
}));

// I18N

const i18n = defineMessages({
  accountOwner: {
    id: "Ecosystem.PartnerAccountCard.accountOwner",
    defaultMessage: "{name} Account Owner",
  },
  copied: {
    id: "Ecosystem.PartnerAccountCard.copied",
    defaultMessage: "Copied!",
  },
  copyEmail: {
    id: "Ecosystem.PartnerAccountCard.copyEmail",
    defaultMessage: "Copy email: {email}",
  },
  copyEmailAddress: {
    id: "Ecosystem.PartnerAccountCard.copyEmailAddress",
    defaultMessage: "Copy email address",
  },
  listOfContacts: {
    id: "Ecosystem.PartnerAccountCard.listOfContacts",
    defaultMessage: "List of Contacts",
  },
  moreCount: {
    id: "Ecosystem.PartnerAccountCard.moreCount",
    defaultMessage: " & {count} more",
  },
  openInCrm: {
    id: "Ecosystem.PartnerAccountCard.openInCrm",
    defaultMessage: "Open in my CRM",
  },
  totalContacts: {
    id: "Ecosystem.PartnerAccountCard.totalContacts",
    defaultMessage:
      "{totalContactCount, plural, " +
      "=0 {0 contacts} " +
      "one {1 contact {primaryCount, select, 0 {{commonCount, select, 0 {} other {(1 in common)}}} other {{primaryCount, plural, one {(1 deal influencer{commonCount, select, 0 {} other { - {commonCount} in common}})} other {({primaryCount} deal influencers{commonCount, select, 0 {} other { - {commonCount} in common}})}}}}} " +
      "other {{totalContactCount} contacts {primaryCount, select, 0 {{commonCount, select, 0 {} other {({commonCount} in common)}}} other {{primaryCount, plural, one {(1 deal influencer{commonCount, select, 0 {} other { - {commonCount} in common}})} other {({primaryCount} deal influencers{commonCount, select, 0 {} other { - {commonCount} in common}})}}}}}}",
  },
  inCommon: {
    id: "Ecosystem.PartnerAccountCard.inCommon",
    defaultMessage: "({commonContactCount} in common)",
  },
  partnershipOwner: {
    id: "Ecosystem.PartnerAccountCard.partnershipOwner",
    defaultMessage: "Partnership Owner:",
  },
  partnerStatus: {
    id: "Ecosystem.PartnerAccountCard.partnerStatus",
    defaultMessage: "{name} Status",
  },
  commonContacts: {
    id: "Ecosystem.PartnerAccountCard.commonContacts",
    defaultMessage: "Common Contacts",
  },
  otherPartnersContacts: {
    id: "Ecosystem.PartnerAccountCard.otherPartnersContacts",
    defaultMessage: "Other Partners Contacts",
  },
  unspecified: {
    id: "Ecosystem.PartnerAccountCard.unspecified",
    defaultMessage: "+ {unspecifiedCount} unspecified",
  },
  unassigned: {
    id: "Ecosystem.PartnerAccountCard.unassigned",
    defaultMessage: "Unassigned",
  },
  unknownEmail: {
    id: "Ecosystem.PartnerAccountCard.unknownEmail",
    defaultMessage: "No email available",
  },
  months: {
    id: "Ecosystem.PartnerAccountCard.months",
    defaultMessage:
      "Customer of {name} for {count} {count, plural, one {month} other {months}}",
  },
  years: {
    id: "Ecosystem.PartnerAccountCard.years",
    defaultMessage:
      "Customer of {name} for {count} {count, plural, one {year} other {years}}",
  },
});

/// Utils

const getCustomerFor = (value?: string | null, name?: string) => {
  if (!value || !name) {
    return null;
  }
  const today = new Date(Date.now());
  const startDate = new Date(value);
  let yearsDiff = today.getFullYear() - startDate.getFullYear();
  let monthsDiff = yearsDiff * 12 + (today.getMonth() - startDate.getMonth());
  if (yearsDiff > 0) {
    return (
      <FormattedMessage {...i18n.years} values={{ count: yearsDiff, name }} />
    );
  } else if (monthsDiff > 0) {
    return (
      <FormattedMessage {...i18n.months} values={{ count: monthsDiff, name }} />
    );
  }
};

const getOwners = (
  intl: IntlShape,
  owners: User[],
  classes: Record<string, string>,
  handleTrack: () => void,
  copied: boolean
): ReactNode[] => {
  if (owners.filter(Boolean).length === 0) {
    return [<T bodySmall>{intl.formatMessage(i18n.unassigned)}</T>];
  }

  const handleCopy = async (email: string) => {
    try {
      await navigator.clipboard.writeText(email);
      handleTrack();
    } catch (err) {
      console.error("Failed to copy text: ", err);
    }
  };

  const formattedOwners = owners.filter(Boolean).map((user) => (
    <Box className={classes.ownerContainer} key={String(user.id)}>
      <T>
        <span>{user.fullName}</span>
      </T>
      {user.email && (
        <>
          <span>{" · "}</span>
          <Tooltip
            title={
              <FormattedMessage
                {...(copied ? i18n.copied : i18n.copyEmailAddress)}
              />
            }
          >
            <Box
              className={classes.ownerCopyContainer}
              onClick={() => handleCopy(user.email)}
              onCopy={handleTrack}
            >
              <T className={classes.link}>
                <span>{user.email}</span>
              </T>
              <Copy className={classes.copyIcon} />
            </Box>
          </Tooltip>
        </>
      )}
    </Box>
  ));
  if (formattedOwners.length < 3) {
    return formattedOwners;
  }
  return [
    ...formattedOwners.slice(0, 2),
    <T bodySmall>
      <FormattedMessage
        {...i18n.moreCount}
        values={{ count: formattedOwners.length - 2 }}
      />
    </T>,
  ];
};
