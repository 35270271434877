import { Box } from "@mui/material";
import { LockLight } from "components/icons";
import { isEmpty } from "lodash";
import { makeStyles } from "makeStyles";
import { AvailableAnalytisColumns } from "redux/analytics/defaults";

import { CellProps } from "../utils";

export const isLockedCell = (props: CellProps) => {
  if (props.row.type !== "partner_analytics") {
    return false;
  }
  if (
    [
      AvailableAnalytisColumns.favorite,
      AvailableAnalytisColumns.partnerCompanyName,
    ].includes(props.fieldName as AvailableAnalytisColumns)
  ) {
    return false;
  }
  if (isEmpty(props.row.metrics)) {
    return true;
  }
  return false;
};

export const isOverlayedCell = (props: CellProps) => {
  if (props.row.type !== "partner_analytics") {
    return false;
  }
  if (isEmpty(props.row.metrics)) {
    return true;
  }
  return false;
};

const LockedCell = () => {
  const { classes } = useStyles();
  return (
    <Box className={classes.container}>
      <LockLight />
    </Box>
  );
};

export default LockedCell;

const useStyles = makeStyles()((theme) => ({
  container: {
    "& > svg": {
      height: 16,
    },
  },
}));
