import { SWQLNode } from "components/ui/SWQL/SWQLTypes";
import _ from "lodash";
import { Factory } from "models";

import Integration from "./Integration";
import Partnership from "./Partnership";
import Record from "./Record";
import { JSONAPIResourceOrRecord } from "./types";

export default class PartnershipAllowedAccountsFilter extends Record {
  partnership: Partnership;
  integration: Integration;
  query: SWQLNode;

  constructor(data: JSONAPIResourceOrRecord<"allowed_accounts_filters">) {
    super(data);
    this.query = this.attributes.query as SWQLNode;
    /* Setting Partnership from relationships */
    const rawPartnership = this.relationships.partnership?.data;
    if (
      rawPartnership === null ||
      rawPartnership === undefined ||
      _.isArray(rawPartnership)
    ) {
      throw new TypeError(
        "partnership must be a Partnership instance on PartnershipAllowedAccountsFilter"
      );
    }
    this.partnership =
      rawPartnership instanceof Partnership
        ? rawPartnership
        : (Factory.createRecord(rawPartnership) as Partnership);

    /* Setting Integration from relationships */
    const rawIntegration = this.relationships.integration?.data;
    if (
      rawIntegration === null ||
      rawIntegration === undefined ||
      _.isArray(rawIntegration)
    ) {
      throw new TypeError(
        "integration must be an Integration instance on PartnershipAllowedAccountsFilter"
      );
    }
    this.integration =
      rawIntegration instanceof Integration
        ? rawIntegration
        : (Factory.createRecord(rawIntegration) as Integration);
  }
}
