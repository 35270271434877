import { Grid, Hidden, LinearProgress } from "@mui/material";
import { CellProps } from "components/ui/data-grid/utils";
import Tooltip from "components/ui/Tooltip";
import { T } from "components/ui/Typography";
import { makeStyles } from "makeStyles";
import { defineMessages, FormattedMessage } from "react-intl";
import { AvailableDirectoryColumns } from "redux/directory/defaults";

export const AccountOverlapCell = ({ row }: CellProps) => {
  const { classes } = useStyles();
  const { classes: progressClasses } = useProgressStyles();
  const { accountOverlap, name } = row;
  const displayableOverlap = !accountOverlap
    ? undefined
    : Math.round(+(accountOverlap ?? 0) * 100);

  return (
    <div className={classes.cellContainer}>
      {typeof displayableOverlap === "number" && (
        <Tooltip
          title={
            <T>
              <FormattedMessage
                {...i18n.overlapTooltip}
                values={{ value: displayableOverlap, company: name }}
              />
            </T>
          }
        >
          <Grid container className={classes.overlapContainer}>
            <Hidden mdDown>
              <Grid item flexGrow={1} flexShrink={1} width={56}>
                <LinearProgress
                  variant="determinate"
                  value={displayableOverlap ?? 0}
                  classes={progressClasses}
                />
              </Grid>
            </Hidden>
            <Grid item>
              {displayableOverlap && <T bold>{`${displayableOverlap}%`}</T>}
            </Grid>
          </Grid>
        </Tooltip>
      )}
    </div>
  );
};

export const isDirectoryAccountOverlapCell = ({ fieldName }: CellProps) => {
  return fieldName === AvailableDirectoryColumns.accountOverlap;
};

// CSS

const useStyles = makeStyles()((theme) => ({
  cellContainer: {
    //
  },
  overlapContainer: {
    alignItems: "center",
    gap: theme.spacing(1),
    marginRight: theme.spacing(4),
  },
}));

const useProgressStyles = makeStyles()((theme) => ({
  root: {
    height: theme.spacing(0.5),
    borderRadius: 8,
  },
  bar: {
    backgroundColor: theme.palette.primary.main,
    borderRadius: 8,
  },
  colorPrimary: {
    backgroundColor: theme.palette.taupe500,
  },
}));

/// I18N

const i18n = defineMessages({
  overlapTooltip: {
    id: "Directory.DirectoryTile.overlapTooltip",
    defaultMessage:
      "Approx. {value}% of your CRM accounts overlap with {company}",
  },
});
