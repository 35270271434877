import { createSvgIcon } from "@mui/material/utils";

import { SvgIcon } from "./types";

const displayName = "Play";

const Play: SvgIcon = createSvgIcon(
  // Using transform to enlarge from provided viewbox="0 0 16 16"
  // to default "0 0 24 24" from `createSvgIcon` -> `SvgIcon`.
  <g fill="none" transform="matrix(1.5,0,0,1.5,0,0)">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5.84027 12.0318L11.229 8.86195C11.8863 8.4753 11.8863 7.52473 11.229 7.13808L5.84027 3.96825C5.17364 3.57611 4.33325 4.05677 4.33325 4.83018V11.1699C4.33325 11.9433 5.17364 12.4239 5.84027 12.0318ZM11.736 9.72389C13.0506 8.95058 13.0506 7.04945 11.736 6.27615L6.34729 3.10632C5.01402 2.32204 3.33325 3.28335 3.33325 4.83018V11.1699C3.33325 12.7167 5.01402 13.678 6.34729 12.8937L11.736 9.72389Z"
      fill="currentColor"
    />
  </g>,
  displayName
);

Play.displayName = displayName;
export default Play;
