import Alert from "@mui/material/Alert";
import Grid from "@mui/material/Grid";
import { isRejected } from "@reduxjs/toolkit";
import redirectIfLoggedIn from "components/hoc/redirectIfLoggedIn";
import BaseLoginPage from "components/ui/BaseLoginPage";
import Button from "components/ui/Button";
import PageLoader from "components/ui/PageLoader";
import { T } from "components/ui/Typography";
import useOktaLogin from "hooks/useOktaLogin";
import usePushNotification from "hooks/usePushNotification";
import _ from "lodash";
import { makeStyles } from "makeStyles";
import { parse } from "query-string";
import { useEffect, useState } from "react";
import { defineMessages, FormattedMessage, useIntl } from "react-intl";
import { useDispatch } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import { setup } from "redux/init/thunks";
import { setLoggedIn } from "redux/user/actions";
import { fetchProfile } from "redux/user/thunks";
import UserService, { InvitationValues } from "services/UserService";

import BaseRegistrationForm from "./BaseRegistrationForm";

const getTokenFromURL = () => {
  const parsed = parse(window.location.search).token;
  if (_.isArray(parsed)) {
    return parsed[0];
  }
  return parsed || "";
};

const STATE_VERIFYING_TOKEN = 1;
const STATE_READY = 2;
const STATE_INVALID_TOKEN = 3;

export const AcceptInvitation = () => {
  const token = getTokenFromURL();
  const [state, setState] = useState(
    token ? STATE_VERIFYING_TOKEN : STATE_INVALID_TOKEN
  );
  const [details, setDetails] = useState(null);

  useEffect(() => {
    if (token) {
      const service = new UserService();
      service.invitationTokenDetails(token).then((details) => {
        if (details) {
          setDetails(details);
          setState(STATE_READY);
        } else {
          setState(STATE_INVALID_TOKEN);
        }
      });
    }
  }, [token, state]);

  let content = null;
  switch (state) {
    case STATE_READY:
      return <InvitationPage {...details} token={token} />;
    case STATE_INVALID_TOKEN:
      content = (
        <Alert severity="warning">
          <FormattedMessage {...i18n.errorMessage} />
          <Link to="/login">
            <FormattedMessage {...i18n.goLogin} />
          </Link>
        </Alert>
      );
      break;
    default:
      content = <PageLoader />;
  }

  return <BaseLoginPage hidePaper>{content}</BaseLoginPage>;
};

type Props = {
  token: string;
  company_name: string;
  first_name: string;
  oidp?: string;
} & InvitationValues;

const InvitationPage = ({
  token,
  company_name,
  first_name,
  oidp,
  ...initialValues
}: Props) => {
  const { classes } = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();
  const intl = useIntl();
  const pushNotification = usePushNotification();
  const oktaLogin = useOktaLogin();

  const handleSubmit = async (values: InvitationValues) => {
    const service = new UserService();
    try {
      await service.acceptInvitation(token, values);
      const results = await Promise.all([
        dispatch(fetchProfile()),
        setup(dispatch),
      ]);
      if (results.some(isRejected)) {
        pushNotification("default_error");
      } else {
        dispatch(setLoggedIn(true));
        history.push("/");
      }
    } catch {
      pushNotification("default_error");
    }
  };

  const contentNoSSO = (
    <>
      <T h2 bold textAlign="center" className={classes.gutterBottom}>
        <FormattedMessage {...i18n.title} values={{ company: company_name }} />
      </T>
      <BaseRegistrationForm
        initialValues={initialValues}
        disableEmail
        onSubmit={handleSubmit}
      />
    </>
  );

  const contentForSSO = (
    <>
      <T h2 bold textAlign="center">
        <FormattedMessage
          {...i18n.ssoMessage}
          values={{ firstName: first_name }}
        />
      </T>
      <T textAlign="center" className={classes.gutterBottom}>
        <FormattedMessage {...i18n.ssoCTA} values={{ company: company_name }} />
      </T>
      <Button
        label={intl.formatMessage(i18n.ssoLogin, { company: company_name })}
        onClick={async () => await oktaLogin(oidp, "/")}
        classes={{ btn: classes.fullWidth }}
      />
    </>
  );

  const content = oidp ? contentForSSO : contentNoSSO;

  return (
    <BaseLoginPage>
      <Grid
        container
        spacing={2}
        direction="column"
        alignContent="center"
        className={classes.root}
      >
        {content}
      </Grid>
    </BaseLoginPage>
  );
};

export default redirectIfLoggedIn("/profile")(AcceptInvitation);

// I18N

const i18n = defineMessages({
  title: {
    id: "AcceptInvitation.title",
    defaultMessage: "Join the {company} workspace on Reveal",
  },
  errorMessage: {
    id: "AcceptInvitation.errorMessage",
    defaultMessage:
      "Your invitation is not valid any longer, or does not exist.",
  },
  ssoMessage: {
    id: "AcceptInvitation.ssoMessage",
    defaultMessage: "Hello {firstName},",
  },
  ssoCTA: {
    id: "AcceptInvitation.ssoCTA",
    defaultMessage: "{company} is on Reveal!",
  },
  ssoLogin: {
    id: "AcceptInvitation.ssoLogin",
    defaultMessage: "Sign in to {company} workspace",
  },
  goLogin: {
    id: "AcceptInvitation.goLogin",
    defaultMessage: "Go to log in page",
  },
});

// CSS

const useStyles = makeStyles()((theme) => ({
  root: {
    width: 360,
    [theme.breakpoints.down("md")]: {
      width: "100%",
    },
  },
  fullWidth: {
    width: "100%",
  },
  gutterBottom: {
    marginBottom: 24,
    [theme.breakpoints.down("md")]: {
      marginBottom: 16,
    },
  },
}));
