import { createSvgIcon } from "@mui/material/utils";

import { SvgIcon } from "./types";

const displayName = "CircleInfo";

const CircleInfo: SvgIcon = createSvgIcon(
  // Using transform to enlarge from provided viewbox="0 0 16 16"
  // to default "0 0 24 24" from `createSvgIcon` -> `SvgIcon`.
  <g fill="none" transform="matrix(1.5,0,0,1.5,0,0)">
    <circle cx="8" cy="8" r="8" fill="currentColor" />
    <path d="M9 7H7V12H9V7Z" fill="#ffffff" />
    <path
      d="M8 6C8.55228 6 9 5.55228 9 5C9 4.44772 8.55228 4 8 4C7.44772 4 7 4.44772 7 5C7 5.55228 7.44772 6 8 6Z"
      fill="#ffffff"
    />
  </g>,
  displayName
);

CircleInfo.displayName = displayName;
export default CircleInfo;
