import { Grid } from "@mui/material";
import CompanyAvatar from "components/ui/avatars/CompanyAvatar";
import { CellProps } from "components/ui/data-grid/utils";
import Tooltip from "components/ui/Tooltip";
import { T } from "components/ui/Typography";
import { makeStyles } from "makeStyles";
import { AvailableDirectoryColumns } from "redux/directory/defaults";

export const CompanyNameCell = ({ row }: CellProps) => {
  const { classes } = useStyles();
  const { companyId, domainName, website, name, description } = row;

  const isPartnerOnReveal = !!companyId;

  return (
    <div className={classes.cellContainer}>
      <Tooltip
        title={
          !!description ? (
            <T className={classes.tooltip}>
              <span className={classes.descriptionHover}>{description}</span>
              {!!website && (
                <a
                  className={classes.tooltipLink}
                  href={website as string}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {website}
                </a>
              )}
            </T>
          ) : (
            ""
          )
        }
        placement="top"
      >
        <Grid
          item
          className={classes.nameContainer}
          xs={isPartnerOnReveal ? 6 : 14}
        >
          <>
            <CompanyAvatar
              companyName={name}
              size="xs"
              companyDomain={domainName}
            />
            <T bold className={classes.companyName}>
              {name}
            </T>
          </>
        </Grid>
      </Tooltip>
    </div>
  );
};

export const isDirectoryCompanyNameCell = ({ fieldName, row }: CellProps) => {
  return (
    fieldName === AvailableDirectoryColumns.name &&
    row.type === "directory_companies"
  );
};

// CSS

const useStyles = makeStyles()((theme) => ({
  cellContainer: {
    //
  },
  companyName: {
    color: theme.palette.midnight,
  },
  descriptionHover: {
    marginBottom: theme.spacing(1),
    display: "-webkit-box",
    "-webkit-line-clamp": "3",
    "-webkit-box-orient": "vertical",
    overflow: "hidden",
  },
  nameContainer: {
    display: "flex",
    columnGap: 12,
    alignItems: "center",
  },
  tooltip: {
    margin: theme.spacing(1),
  },
  tooltipLink: {
    cursor: "pointer",
    textDecoration: "underline",
    color: "unset",
  },
}));
