import { createSvgIcon } from "@mui/material/utils";

import { SvgIcon } from "./types";

const displayName = "Reveal";

const Reveal: SvgIcon = createSvgIcon(
  // Using transform to enlarge from provided viewbox="0 0 12 12"
  // to default "0 0 24 24" from `createSvgIcon` -> `SvgIcon`.
  <g fill="none" transform="matrix(2,0,0,2,0,0)">
    <path
      d="M5.93455 2.03571C4.07705 2.03571 2.57141 3.53775 2.57141 5.39081V9.74999H5.93455H9.29769V5.39081C9.29769 3.53775 7.79205 2.03571 5.93455 2.03571ZM5.72998 9.1704H3.22604L5.72998 7.62754V9.1704ZM5.72998 7.03162C4.9117 6.93366 4.27344 6.23162 4.27344 5.38264C4.27344 4.53366 4.9117 3.83979 5.72998 3.73366V7.03162ZM5.72998 3.35815C4.69076 3.45611 3.88066 4.32958 3.88066 5.39081C3.88066 6.33775 4.5271 7.13775 5.41085 7.36632L3.15239 8.76224V5.39081C3.15239 3.92958 4.2898 2.72958 5.72998 2.62346V3.35815ZM6.13912 3.74183C6.9574 3.83979 7.59566 4.54183 7.59566 5.39081C7.59566 6.23979 6.9574 6.93366 6.13912 7.03979V3.74183ZM6.13912 9.1704V7.62754L8.64306 9.1704H6.13912ZM8.71671 8.76224L6.45007 7.36632C7.33381 7.13775 7.98025 6.33775 7.98025 5.39081C7.98025 4.32958 7.17016 3.45611 6.13094 3.35815V2.62346C7.57111 2.72958 8.70853 3.92958 8.70853 5.39081V8.76224H8.71671Z"
      fill="currentColor"
    />
  </g>,
  displayName
);

Reveal.displayName = displayName;

export default Reveal;
