import Fade from "@mui/material/Fade";
import { T } from "components/ui/Typography";
import muiTheme from "config/theme";
import { makeStyles } from "makeStyles";
import User from "models/User";
import { defineMessages, FormattedMessage } from "react-intl";
import { Link, useHistory } from "react-router-dom";

import AccountAvatar from "../avatars/AccountAvatar";
import SidebarLogoutButton from "./SidebarLogoutButton";

export type Props = {
  profile: User;
  expanded: boolean;
  openSidebar?: () => void;
};

const SidebarProfile = ({
  profile,
  expanded,
  openSidebar = () => {},
}: Props) => {
  const history = useHistory();
  const { classes, cx } = useStyles();

  return (
    <div
      className={cx(classes.root, {
        [classes.expanded]: expanded,
        [classes.collapsed]: !expanded,
      })}
      onMouseEnter={openSidebar}
    >
      <span className={classes.avatar} onClick={() => history.push("/profile")}>
        <AccountAvatar user={profile} online size="sm" />
      </span>
      <Fade in={expanded}>
        <div className={classes.container}>
          <T color={muiTheme.palette.offWhite} className={classes.label}>
            {profile.fullName}
            <Link className={classes.link} to={"/profile"}>
              <FormattedMessage {...i18n.viewProfile} />
            </Link>
          </T>
          <SidebarLogoutButton expanded={expanded} openSidebar={openSidebar} />
        </div>
      </Fade>
    </div>
  );
};

const i18n = defineMessages({
  viewProfile: {
    id: "SidebarProfile.viewProfile",
    defaultMessage: "View Profile",
  },
});

const useStyles = makeStyles()((theme) => ({
  root: {
    display: "flex",
    alignItems: "center",
    justifyContent: "left",
    height: 68,
  },
  expanded: {
    width: 192,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  collapsed: {
    width: 68,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  container: {
    display: "flex",
    alignItems: "center",
  },
  label: {
    display: "flex",
    flexDirection: "column",
    fontWeight: 500,
    width: 116,
    marginLeft: 8,
  },
  avatar: {
    cursor: "pointer",
  },
  link: {
    fontWeight: 400,
    marginTop: 2,
    textDecoration: "none",
    color: theme.palette.greyscale550,
    "&:hover": {
      color: theme.palette.offWhite,
      textDecoration: "underline",
    },
  },
}));

export default SidebarProfile;
