import Close from "@mui/icons-material/Close";
import { Box, CircularProgress, IconButton } from "@mui/material";
import { ArrowLeft, Star } from "components/icons";
import CompanyAvatar from "components/ui/avatars/CompanyAvatar";
import Button from "components/ui/Button";
import { T } from "components/ui/Typography";
import useHasPayingFeature from "hooks/useHasPayingFeature";
import useUserProfile from "hooks/useUserProfile";
import { makeStyles } from "makeStyles";
import { PayingFeature } from "models/CompanyPayingFeatureSubscription";
import Record from "models/Record";
import { useCallback, useEffect } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { index } from "redux/api/thunks";

import AccountActionButtons from "./AccountActionButtons";
import { AccountDescription } from "./AccountDescription";
import AccountTabs from "./AccountTabs";
import PartnerInsightsCTA from "./PartnerInsightsCTA";

type AccountDetailsProps = {
  account: Record<"nearbound_prospects" | "crm_accounts"> | null;
  close: $TSFixMeFunction;
  ready?: boolean;
};

const AccountDetails = ({
  account,
  close,
  ready = true,
}: AccountDetailsProps) => {
  const dispatch = useDispatch();
  const { profile } = useUserProfile();
  const { classes } = useStyles();
  const history = useHistory();

  const isLimit360Drawer = useHasPayingFeature(
    PayingFeature.Limit360Drawer,
    profile
  );

  const loadPartnerConnections = useCallback(async () => {
    if (!account) {
      return;
    }
    dispatch(
      index({
        type: "partner_connections",
        options: {
          filters: {
            raw_company: account.id,
            archived: "false",
          },
          include: ["discussion_participants"],
        },
      })
    );
  }, [account, dispatch]);

  useEffect(() => {
    if (account?.type === "nearbound_prospects") {
      return;
    }
    loadPartnerConnections();
  }, [account, loadPartnerConnections]);

  if (!ready || !account) {
    return (
      <Box className={classes.loader}>
        <CircularProgress />
      </Box>
    );
  }
  return (
    <div className={classes.mainContainer}>
      <div className={classes.header}>
        <div className={classes.titleContainer}>
          <div>
            <div className={classes.headerTitle}>
              <Box marginRight="8px">
                <Button
                  label={i18n.back}
                  LeftIcon={ArrowLeft}
                  onClick={() => history.goBack()}
                  size="small"
                  variant="tertiary"
                />
              </Box>
              <CompanyAvatar companyDomain={account.domain} />
              <T h2 bold>
                {account.name}
              </T>
              {account.starred && <Star className={classes.icon} />}
            </div>
          </div>
          <IconButton onClick={close} className={classes.button}>
            <Close className={classes.iconSmall} />
          </IconButton>
        </div>
        <AccountDescription account={account} />
        <AccountActionButtons account={account} />
      </div>
      <div className={classes.tabsContainer}>
        <AccountTabs account={account} isLimited={isLimit360Drawer} />
      </div>
      <PartnerInsightsCTA account={account} isLimited={isLimit360Drawer} />
    </div>
  );
};

export default AccountDetails;

// CSS

export const useStyles = makeStyles()((theme) => ({
  loader: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "100%",
  },
  mainContainer: {
    padding: 16,
    [theme.breakpoints.down("md")]: {
      paddingLeft: theme.spacing(3),
      paddingRight: theme.spacing(3),
      paddingTop: 0,
    },
    display: "flex",
    flexDirection: "column",
    rowGap: theme.spacing(2),
    height: "100vh" /* Full height of the viewport */,
  },
  header: {
    display: "flex",
    flexDirection: "column",
    height: "fit-content",
    rowGap: theme.spacing(2),
  },
  titleContainer: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "flex-start",
  },
  headerTitle: {
    display: "flex",
    alignItems: "center",
    gap: 8,
  },
  button: {
    background: theme.palette.greyscale200,
    height: 24,
    marginTop: 4,
    width: 24,
  },
  icon: {
    width: 16,
    height: 16,
  },
  iconSmall: {
    width: 12,
    height: 12,
  },
  tabsContainer: {
    flex: 1 /* Take remaining vertical space */,
    overflowY: "auto",
    position: "relative",
  },
}));

const i18n = {
  back: {
    id: "crm.Ecosystem.AccountDetails.back",
    defaultMessage: "Back",
  },
};
