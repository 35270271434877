import { createSvgIcon } from "@mui/material/utils";

import { SvgIcon } from "./types";

const displayName = "Email";

const Email: SvgIcon = createSvgIcon(
  // Using transform to enlarge from provided viewbox="0 0 12 12"
  // to default "0 0 24 24" from `createSvgIcon` -> `SvgIcon`.
  <g fill="none" transform="matrix(2,0,0,2,0,0)">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M1.5 3.844C1.5 2.96379 2.21355 2.25024 3.09375 2.25024H9.20089C10.0811 2.25024 10.7946 2.96379 10.7946 3.84399V8.66542C10.7946 9.54562 10.0811 10.2592 9.20089 10.2592H3.09375C2.21355 10.2592 1.5 9.54563 1.5 8.66542V3.844ZM2.88731 3.62561C2.67794 3.47335 2.38478 3.51963 2.23251 3.729C2.08024 3.93837 2.12653 4.23154 2.3359 4.3838L5.20992 6.474C5.76877 6.88044 6.52587 6.88044 7.08472 6.474L9.95874 4.3838C10.1681 4.23154 10.2144 3.93837 10.0621 3.729C9.90986 3.51963 9.6167 3.47335 9.40733 3.62561L6.53331 5.71581C6.30319 5.88317 5.99145 5.88317 5.76133 5.71581L2.88731 3.62561Z"
      fill="currentColor"
    />
  </g>,
  displayName
);
Email.displayName = displayName;
export default Email;
