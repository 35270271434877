import { createSvgIcon } from "@mui/material/utils";

import { SvgIcon } from "./types";

const displayName = "MoveLayerRight";

const MoveLayerRight: SvgIcon = createSvgIcon(
  // Using transform to enlarge from provided viewbox="0 0 16 16"
  // to default "0 0 24 24" from `createSvgIcon` -> `SvgIcon`.
  <g fill="none" transform="matrix(1.5,0,0,1.5,0,0)">
    <path
      d="M7 4.5L11 8.5L7 12.5"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M11 8.5H2"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M13 3V14"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </g>,
  displayName
);

MoveLayerRight.displayName = displayName;
export default MoveLayerRight;
