import _ from "lodash";
import Record from "models/Record";
import {
  snakeCaseNameForNearboundAccountField,
  snakeCaseWithDoubleUnderscore,
} from "screens/Frontoffice/shared/helpers/snakeCase";

import { SortType } from "./base";
import { MatchFieldType, MatchSortOrder } from "./enums";

class MatchSort implements SortType {
  fieldname: string;
  order: MatchSortOrder;
  fieldtype: number;
  fieldoptions: { [option: string]: string | number };

  static ORDERS = [MatchSortOrder.ORDER_ASC, MatchSortOrder.ORDER_DESC];

  static PREFIX_DESC = "-";

  constructor(
    fieldname: string,
    order: MatchSortOrder,
    field?: { type: number; options?: { [option: string]: string | number } }
  ) {
    this.fieldname = fieldname;
    this.order = order;
    this.fieldtype = field?.type ?? MatchFieldType.UNKNOWN;
    this.fieldoptions = field?.options ?? {};
  }

  toggleOrder() {
    this.order =
      this.order === MatchSortOrder.ORDER_ASC
        ? MatchSortOrder.ORDER_DESC
        : MatchSortOrder.ORDER_ASC;
  }

  prefix() {
    return this.order === MatchSortOrder.ORDER_DESC
      ? MatchSort.PREFIX_DESC
      : "";
  }

  toString() {
    return this.prefix() + this.fieldname;
  }

  apiName(path?: string[]) {
    return (
      this.prefix() +
      (path
        ? snakeCaseNameForNearboundAccountField(this.fieldname, path)
        : snakeCaseWithDoubleUnderscore(this.fieldname))
    );
  }

  get predicate() {
    const get = _.property<Record, any>(this.fieldname);
    if (this.fieldtype === MatchFieldType.PICKLIST) {
      return (value: Record) => _.propertyOf(this.fieldoptions)(get(value));
    }
    if (this.fieldtype === MatchFieldType.TEXT) {
      return (value: Record) => _.toLower(get(value));
    }
    return this.fieldname;
  }

  static applyAll(
    records: Record[],
    raw: { fieldname: string; order: MatchSortOrder }[],
    fields: {
      [fieldname: string]: {
        type: number;
        options?: { [option: string]: any };
      };
    }
  ) {
    const sorts = _.map(raw, (item) => {
      const { fieldname, order } = item;
      return new MatchSort(fieldname, order, fields[fieldname]);
    });
    return _.orderBy(
      records,
      sorts.map(_.property("predicate")),
      sorts.map(_.property("order"))
    );
  }
}

export default MatchSort;
