import { ProviderType } from "config/constants";
import getFirstValueFromURL from "helpers/getFirstValueFromURL";
import { useHistory, useLocation } from "react-router-dom";

import { GsheetUrl, isCrmFileOAuthProviderType } from "../helpers/types";
import { ConnectFileButtonGroup } from "./ConnectFileButtonGroup";
import { GsheetAuthDialog } from "./GsheetAuthDialog";
import { GsheetTabSelectionDialog } from "./GsheetTabSelectionDialog";
import UploadDialog from "./UploadDialog";

type Props = {
  onUploadSuccess: (integrationId: number) => void;
  partnershipId?: number;
};

export const ConnectFileTile = ({ onUploadSuccess, partnershipId }: Props) => {
  const history = useHistory();
  const location = useLocation();
  const isOnboarding = getFirstValueFromURL(location, "onboarding") === "true";

  const handleClick = (provider: ProviderType) => () => {
    if (isCrmFileOAuthProviderType(provider)) {
      history.push({
        hash: GsheetUrl.AuthHash,
        search: `${isOnboarding ? "onboarding=true&" : ""}init=true${
          partnershipId ? "&isGhost=" + partnershipId : ""
        }`,
      }); // we only have gsheet atm
      return;
    }
    history.push({
      hash: "#upload-file",
      search: `${isOnboarding ? "onboarding=true&" : ""}init=true`,
    });
  };

  const handleClose = () => {
    history.push({
      search: "",
      hash: "",
    });
  };

  return (
    <div>
      <ConnectFileButtonGroup
        onGsheetClick={handleClick(ProviderType.gsheet)}
        onCsvClick={handleClick(ProviderType.file)}
      />
      <UploadDialog
        partnershipId={partnershipId}
        open={location.hash === "#upload-file"}
        onClose={handleClose}
        onUploadSuccess={onUploadSuccess}
      />
      <GsheetAuthDialog
        onClose={handleClose}
        open={location.hash === GsheetUrl.AuthHash}
      />
      <GsheetTabSelectionDialog
        onClose={handleClose}
        open={location.hash === GsheetUrl.TabSelectionHash}
        ghostPartnershipId={partnershipId}
      />
    </div>
  );
};
