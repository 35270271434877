import _ from "lodash";
import Match from "models/Match";
import {
  FilterType,
  MatchFilterType,
} from "screens/Frontoffice/screens/DataTables/shared/types";

/**
 * Capabilities Utils
 *
 * Supported Capabilities:
 *    - has_common_opportunities
 *    - has_customer_overlaps
 *    - has_market_overlap
 *    - has_opportunities
 *    - has_persona_overlap
 *    - has_prospect_overlaps
 *    - has_prospect_to_customer_overlaps
 *    - has_customer_to_prospect_overlaps
 *    - has_nb_of_new_prospects
 */

export const requiredCapabilitiesForKPI: Record<string, string[]> = {
  common_customers: ["has_customer_overlaps"],
  common_opportunities: ["has_common_opportunities"],
  prospects_to_customers: ["has_prospect_to_customer_overlaps"],
  customers_to_prospects: ["has_customer_to_prospect_overlaps"],
  nb_of_new_prospects: ["has_nb_of_new_prospects"],
  insight: ["has_opportunities"],
};

export const hasAllRequiredCapabilities = (
  capabilities: Record<string, boolean> = {},
  required: string[] = []
) => _.every([..._.map(required, (req) => _.get(capabilities, req))]);

export const getBaseFilters = (kpi: string): FilterType[] => {
  switch (kpi) {
    case "common_customers": // commonCustomers
      return [
        {
          fieldname: "leftStatus",
          type: MatchFilterType.ANY_OF,
          value: [Match.STATUS_CUSTOMER],
        },
        {
          fieldname: "rightStatus",
          type: MatchFilterType.ANY_OF,
          value: [Match.STATUS_CUSTOMER],
        },
      ];
    case "common_opportunities": // commonOpportunities
      return [
        {
          fieldname: "leftStatus",
          type: MatchFilterType.ANY_OF,
          value: [Match.STATUS_PROSPECT],
        },
        {
          fieldname: "rightStatus",
          type: MatchFilterType.ANY_OF,
          value: [Match.STATUS_PROSPECT],
        },
        {
          fieldname: "leftActive",
          type: MatchFilterType.IS,
          value: true,
        },
        {
          fieldname: "rightActive",
          type: MatchFilterType.IS,
          value: true,
        },
      ];
    case "customers_to_prospects": // customerMatchingProspects
      return [
        {
          fieldname: "leftStatus",
          type: MatchFilterType.ANY_OF,
          value: [Match.STATUS_CUSTOMER],
        },
        {
          fieldname: "rightStatus",
          type: MatchFilterType.ANY_OF,
          value: [Match.STATUS_PROSPECT],
        },
      ];
    case "prospects_to_customers": // prospectMatchingCustomers
      return [
        {
          fieldname: "leftStatus",
          type: MatchFilterType.ANY_OF,
          value: [Match.STATUS_PROSPECT],
        },
        {
          fieldname: "rightStatus",
          type: MatchFilterType.ANY_OF,
          value: [Match.STATUS_CUSTOMER],
        },
      ];
    case "nb_of_new_prospects":
      return [
        {
          fieldname: "perspective",
          type: MatchFilterType.IS,
          value: Match.PERSPECTIVE_THEIRS,
        },
      ];
    case "partner_nb_of_new_prospects":
      return [
        {
          fieldname: "perspective",
          type: MatchFilterType.IS,
          value: Match.PERSPECTIVE_MINE,
        },
      ];
    case "open_opportunities_to_customers":
      return [
        {
          fieldname: "leftStatus",
          type: MatchFilterType.NONE_OF,
          value: [Match.STATUS_CUSTOMER, Match.STATUS_PARTNER],
        },
        {
          fieldname: "rightStatus",
          type: MatchFilterType.ANY_OF,
          value: [Match.STATUS_CUSTOMER],
        },
        {
          fieldname: "leftActive",
          type: MatchFilterType.IS,
          value: true,
        },
      ];
    default:
      return [];
  }
};
