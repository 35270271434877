import { createSvgIcon } from "@mui/material/utils";

import { SvgIcon } from "./types";

const displayName = "Sidebar";

const Sidebar: SvgIcon = createSvgIcon(
  <g fill="none" transform="matrix(2,0,0,2,-2,0)">
    <g clip-path="url(#clip0_1397_221539)">
      <rect x="0.5" y="0.5" width="13" height="11" rx="1" stroke="#5A617F" />
      <rect x="7" y="2" width="5" height="8" rx="1" fill="#5A617F" />
    </g>
    <defs>
      <clipPath id="clip0_1397_221539">
        <rect width="14" height="12" fill="white" />
      </clipPath>
    </defs>
  </g>,
  displayName
);

Sidebar.displayName = displayName;
export default Sidebar;
