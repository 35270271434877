import { createSvgIcon } from "@mui/material/utils";

import { SvgIcon } from "./types";

const displayName = "ProfileCircleOutlined";

const ProfileCircleOutlined: SvgIcon = createSvgIcon(
  // Using transform to enlarge from provided viewbox="0 0 16 16"
  // to default "0 0 24 24" from `createSvgIcon` -> `SvgIcon`.
  <g fill="none" transform="matrix(1.5,0,0,1.5,0,0)">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M14.6666 7.99998C14.6666 10.0089 13.778 11.8103 12.3725 13.0325C12.329 13.0703 12.285 13.1076 12.2405 13.1443C11.0881 14.0953 9.61075 14.6666 7.99992 14.6666C6.32693 14.6666 4.79787 14.0504 3.62736 13.0325C2.22181 11.8103 1.33325 10.0089 1.33325 7.99998C1.33325 4.31808 4.31802 1.33331 7.99992 1.33331C11.6818 1.33331 14.6666 4.31808 14.6666 7.99998ZM7.99992 13.6666C8.07327 13.6666 8.14629 13.6653 8.21897 13.6625C9.28698 13.6219 10.2793 13.2857 11.1164 12.7334C10.4689 11.6919 9.31434 11 7.99992 11C6.68549 11 5.53092 11.6919 4.88341 12.7334C5.73005 13.292 6.73539 13.6295 7.81724 13.6638C7.87183 13.6655 7.92662 13.6664 7.98159 13.6666L7.99992 13.6666ZM7.99992 9.99998C9.63248 9.99998 11.0692 10.8383 11.9032 12.108C12.9894 11.0756 13.6666 9.61685 13.6666 7.99998C13.6666 4.87037 11.1295 2.33331 7.99992 2.33331C4.87031 2.33331 2.33325 4.87037 2.33325 7.99998C2.33325 9.61684 3.01042 11.0755 4.09661 12.108C4.93058 10.8383 6.36735 9.99998 7.99992 9.99998ZM9.66659 6.66665C9.66659 7.58712 8.92039 8.33331 7.99992 8.33331C7.07944 8.33331 6.33325 7.58712 6.33325 6.66665C6.33325 5.74617 7.07944 4.99998 7.99992 4.99998C8.92039 4.99998 9.66659 5.74617 9.66659 6.66665ZM10.6666 6.66665C10.6666 8.13941 9.47268 9.33331 7.99992 9.33331C6.52716 9.33331 5.33325 8.13941 5.33325 6.66665C5.33325 5.19389 6.52716 3.99998 7.99992 3.99998C9.47268 3.99998 10.6666 5.19389 10.6666 6.66665Z"
      fill="currentColor"
    />
  </g>,
  displayName
);

ProfileCircleOutlined.displayName = displayName;
export default ProfileCircleOutlined;
