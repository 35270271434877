import { Box } from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import { Lock } from "components/icons";
import { PayingFeatureTooltip } from "components/ui/PayingFeatureTooltip";
import Tooltip from "components/ui/Tooltip";
import { T } from "components/ui/Typography";
import { ConfigContext } from "config/ConfigProvider";
import { track } from "helpers/segment";
import useUserProfile from "hooks/useUserProfile";
import { makeStyles } from "makeStyles";
import { PayingFeature } from "models/CompanyPayingFeatureSubscription";
import Partnership from "models/Partnership";
import { ReactElement, ReactNode, useCallback, useContext } from "react";
import {
  defineMessages,
  FormattedHTMLMessage,
  FormattedMessage,
  FormattedNumber,
} from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { selectActivePayingFeatures } from "redux/api/selectors";
import { selectFirst3PartnershipIds } from "redux/init/selectors";
import {
  openWidget,
  toggleFree,
  togglePower,
  togglePro,
} from "redux/premiumPlanWidget/actions";
import { i18n as tooltipMessages } from "screens/Frontoffice/screens/DataTables/screens/Analytics/constants";
import { PremiumPlanEvent } from "tracking";
import { useMergedClasses } from "tss-react";

export enum OverlapItemType {
  market = "market",
  persona = "persona",
  winRate = "winRate",
  revenuePotential = "revenuePotential",
}

type OverlapItemProps = {
  classes?: Partial<ReturnType<typeof useOverlapItemStyles>["classes"]>;
  partnership: Partnership;
  value: number | null;
  valueInProgress?: boolean;
  type: OverlapItemType;
  loading?: boolean;
  hasSeparativeDot?: boolean;
  valueOnly?: boolean;
};

export const OverlapItem = ({
  partnership,
  value,
  valueInProgress = false,
  type,
  loading = false,
  hasSeparativeDot = false,
  valueOnly = false,
  classes: newClasses = {},
}: OverlapItemProps) => {
  const { classes: baseClasses } = useOverlapItemStyles({ type });
  const classes = useMergedClasses(baseClasses, newClasses);
  const { profile } = useUserProfile();
  const dispatch = useDispatch();
  const config = useContext(ConfigContext);
  const hasValue = value !== null;
  const formattedValue = getFormattedValue(value, type);
  const first3PartnershipIds = useSelector(selectFirst3PartnershipIds);
  const payingFeatures = useSelector(selectActivePayingFeatures);
  const hasAdvancedAnalyticsPayingFeature = payingFeatures.includes(
    PayingFeature.AdvancedAnalytics
  );
  const upsideField =
    type === OverlapItemType.winRate ||
    type === OverlapItemType.revenuePotential;
  const isLocked =
    upsideField &&
    !hasAdvancedAnalyticsPayingFeature &&
    !first3PartnershipIds.includes(partnership.id) &&
    !partnership.isDemo;

  const openPremiumWidget = useCallback(
    (type: OverlapItemType) => {
      dispatch(toggleFree(false));
      dispatch(togglePro(true));
      dispatch(togglePower(false));
      dispatch(openWidget(true));
      let from = "";
      if (type === OverlapItemType.winRate) {
        from = "Win Rate Partner Tile";
      } else if (type === OverlapItemType.revenuePotential) {
        from = "Revenue Potential Partner Tile";
      }
      track(PremiumPlanEvent.explorePlan, {
        from,
      });
    },
    [dispatch]
  );

  const label = <FormattedMessage {...getOverlapItemLabel(type)} />;

  const tooltipTitle = (
    <T>
      <FormattedHTMLMessage
        {...getOverlapItemTooltip(type, hasValue, valueInProgress)}
        values={{
          partnerName: partnership.getPartner(profile).name,
          value: Math.round((value ?? 0) * 100),
          isNegative: (value ?? 0) < 0,
          fieldName:
            type === OverlapItemType.winRate
              ? i18n.winRate.defaultMessage
              : type === OverlapItemType.revenuePotential
              ? i18n.revenuePotential.defaultMessage
              : "",
        }}
      />
      {upsideField && hasValue && (
        <span>
          &nbsp;
          <a
            className={classes.tooltipLink}
            target="_blank"
            href={`${config.partnerAnalyticsV0Link}`}
            rel="noopener noreferrer"
          >
            <FormattedMessage {...i18n.learnMore} />
          </a>
        </span>
      )}
    </T>
  );

  const tooltipLockedMessage = (
    <FormattedHTMLMessage
      {...(type === OverlapItemType.winRate
        ? i18n.winRateLockedTooltip
        : type === OverlapItemType.revenuePotential
        ? i18n.revenuePotentialLockedTooltip
        : "")}
      values={{
        partnerName: partnership.getPartner(profile).name,
      }}
    />
  );

  const overLapItemContent =
    isLocked &&
    [OverlapItemType.revenuePotential, OverlapItemType.winRate].includes(
      type
    ) ? (
      <Box className={classes.lockedOverlapItem}>
        <Lock />
        <Box className={classes.lockedOverlapItemMessage}>
          <T bold>
            <FormattedMessage {...i18n.unlock} />
          </T>
        </Box>
        {!valueOnly && <T>{label}</T>}
      </Box>
    ) : (
      <Box className={classes.overlapContainer}>
        <T bold>{loading ? <CircularProgress size={10} /> : formattedValue}</T>
        {!valueOnly && <T>{label}</T>}
      </Box>
    );

  return (
    <>
      {hasSeparativeDot && <Box mx={0.75}>·</Box>}
      <OverlapItemTooltip
        message={loading ? "" : tooltipTitle}
        showLockedVersion={isLocked}
        lockedMessage={tooltipLockedMessage}
        onClick={() => openPremiumWidget(type)}
      >
        {overLapItemContent}
      </OverlapItemTooltip>
    </>
  );
};

type OverlapItemTooltipProps = {
  message: ReactNode;
  showLockedVersion: boolean;
  lockedMessage: ReactNode;
  onClick: () => void;
  children: ReactElement;
};

export const OverlapItemTooltip = ({
  message,
  showLockedVersion,
  lockedMessage,
  onClick,
  children,
}: OverlapItemTooltipProps) => {
  return showLockedVersion ? (
    <PayingFeatureTooltip
      active
      message={lockedMessage}
      onClick={onClick}
      placement="top"
    >
      {children}
    </PayingFeatureTooltip>
  ) : (
    <Tooltip title={message}>{children}</Tooltip>
  );
};

// Helpers

const getFormattedValue = (value: number | null, type: OverlapItemType) => {
  if (value === null || isNaN(value)) {
    return "--";
  }

  switch (type) {
    case OverlapItemType.market:
    case OverlapItemType.persona:
    case OverlapItemType.winRate:
      const percent = (
        <FormattedNumber
          value={value}
          // eslint-disable-next-line react/style-prop-object
          style="percent"
          maximumFractionDigits={0}
        />
      );
      if (type === OverlapItemType.winRate) {
        let isNegative = value < 0;
        return (
          <span>
            {!isNegative && "+"}
            {percent}
          </span>
        );
      }
      return percent;
    case OverlapItemType.revenuePotential:
      return (
        <FormattedNumber
          value={value}
          // eslint-disable-next-line react/style-prop-object
          style="currency"
          currency="USD"
          notation="compact"
          compactDisplay="short"
        />
      );
    default:
      return value;
  }
};

const getOverlapItemLabel = (type: OverlapItemType) => {
  switch (type) {
    case OverlapItemType.market:
      return i18n.marketOverlap;
    case OverlapItemType.persona:
      return i18n.personaOverlap;
    case OverlapItemType.winRate:
      return i18n.winRate;
    case OverlapItemType.revenuePotential:
      return i18n.revenuePotential;
  }
};

const getOverlapItemTooltip = (
  type: OverlapItemType,
  hasValue: boolean,
  valueInProgress: boolean
) => {
  switch (type) {
    case OverlapItemType.market:
      return i18n.marketOverlapTooltip;
    case OverlapItemType.persona:
      return i18n.personaOverlapTooltip;
    case OverlapItemType.winRate:
      return hasValue
        ? tooltipMessages.winRateTooltip
        : valueInProgress
        ? i18n.updateInProgress
        : i18n.noData;
    case OverlapItemType.revenuePotential:
      return hasValue
        ? tooltipMessages.revenuePotentialTooltip
        : valueInProgress
        ? i18n.updateInProgress
        : i18n.noData;
  }
};

// CSS

const useOverlapItemStyles = makeStyles<{ type: OverlapItemType }>()(
  (theme, { type }) => ({
    overlapContainer: {
      display: "flex",
      alignItems: "center",
      columnGap: 4,
      height: "100%",
      cursor: "default",
    },
    lockedOverlapItem: {
      display: "flex",
      alignItems: "center",
      height: "100%",
      marginLeft: "2px",
      cursor: "default",
      "& .MuiSvgIcon-root": {
        height: "10px",
        width: "auto",
        marginRight: "6px",
      },
    },
    lockedOverlapItemMessage: {
      marginRight: "4px",
    },
    icon: {
      width: 16,
      height: 16,
    },
    loader: {
      marginTop: 0.5,
    },
    tooltipLink: {
      color: theme.palette.ivory,
      textDecoration: "underline",
      "&:hover": {
        cursor: "pointer",
        color: theme.palette.ivory,
        textDecorationColor: theme.palette.ivory,
      },
    },
  })
);

// I18N

export const i18n = defineMessages({
  unlock: {
    id: "partnerTile.OverlapItem.unlock",
    defaultMessage: "Unlock",
  },
  accountOverlaps: {
    id: "partnerTile.OverlapItem.accountOverlaps",
    defaultMessage: "Account Overlaps",
  },
  marketOverlap: {
    id: "partnerTile.OverlapItem.marketOverlap",
    defaultMessage: "Market Overlap",
  },
  personaOverlap: {
    id: "partnerTile.OverlapItem.personaOverlap",
    defaultMessage: "Persona Overlap",
  },
  winRate: {
    id: "partnerTile.OverlapItem.winRate",
    defaultMessage: "Win Rate Boost",
  },
  revenuePotential: {
    id: "partnerTile.OverlapItem.revenuePotential",
    defaultMessage: "Revenue Potential",
  },
  marketOverlapTooltip: {
    id: "partnerTile.OverlapItem.marketOverlapTooltip",
    defaultMessage:
      "The number of overlapping accounts that have a common past or current opportunity.",
  },
  personaOverlapTooltip: {
    id: "partnerTile.OverlapItem.personaOverlapTooltip",
    defaultMessage:
      "% of contacts that match {partnerName}’s contacts, within your Account overlap.",
  },
  winRateLockedTooltip: {
    id: "partnerTile.OverlapItem.winRateLockedTooltip",
    defaultMessage:
      "Upgrade to unlock your win rate on accounts that have {partnerName} as a customer.",
  },
  revenuePotentialLockedTooltip: {
    id: "partnerTile.OverlapItem.revenuePotentialLockedTooltip",
    defaultMessage:
      "Upgrade to unlock your total revenue potential with {partnerName}.",
  },
  learnMore: {
    id: "partnerTile.OverlapItem.learnMore",
    defaultMessage: "Learn more",
  },
  noData: {
    id: "partnerTile.OverlapItem.noData",
    defaultMessage: "Insufficient data available to determinate {fieldName}",
  },
  updateInProgress: {
    id: "partnerTile.OverlapItem.updateInProgress",
    defaultMessage: "{fieldName} update in progress",
  },
});
