import { createAction } from "@reduxjs/toolkit";
import { PartnerRepresentation } from "models/Partnership";

import { NotificationWidgetActions } from "./types";

export const setSelectedPartner = createAction<PartnerRepresentation | null>(
  NotificationWidgetActions.setSelectedPartner
);

export const setSelectedPartnershipId = createAction<number | null>(
  NotificationWidgetActions.setSelectedPartnershipId
);
