import { ChevronDown, ChevronTop, Plus } from "components/icons";
import Button from "components/ui/Button";
import Dropdown from "components/ui/Dropdown/components/Dropdown";
import {
  IDropdownGroup,
  IDropdownOption,
} from "components/ui/Dropdown/components/types";
import _ from "lodash";
import { makeStyles } from "makeStyles";
import { useCallback, useState } from "react";
import {
  defineMessages,
  FormattedMessage,
  IntlShape,
  useIntl,
} from "react-intl";
import { FieldType } from "screens/Frontoffice/screens/DataTables/shared/types";

type Props = {
  disabled?: boolean;
  fields: Record<string, FieldType>;
  value?: string;
  onChange: (value: string) => void;
  partnerName: string;
  isAdding: boolean;
};

const SelectField = ({
  disabled = false,
  fields,
  value,
  onChange,
  partnerName,
  isAdding = false,
}: Props) => {
  const [anchorEl, setAnchorEl] = useState<Element | null>(null);
  const intl = useIntl();

  const openDropdown = useCallback((event: React.SyntheticEvent) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  }, []);

  const onSelectItems = useCallback(
    (items: string[] | null) => {
      setAnchorEl(null);
      if (items && items.length > 0) {
        onChange(items[0]);
      }
    },
    [onChange]
  );

  const onClose = useCallback(() => {
    setAnchorEl(null);
  }, []);

  const options: IDropdownOption[] = getOptions(intl, fields);

  return (
    <>
      <Button
        LeftIcon={isAdding ? Plus : undefined}
        RightIcon={anchorEl ? ChevronTop : ChevronDown}
        variant={isAdding ? "secondary" : "tertiary"}
        label={
          value ? (
            <FieldDisplay
              name={value}
              label={fields[value]?.label ?? value}
              partnerName={partnerName}
            />
          ) : isAdding ? (
            i18n.addField
          ) : (
            i18n.selectField
          )
        }
        onClick={openDropdown}
        size="small"
        disabled={disabled}
      />
      <Dropdown
        options={options}
        open={!!anchorEl}
        anchorEl={anchorEl}
        withGroups
        onChange={onSelectItems}
        onClose={onClose}
      />
    </>
  );
};

export default SelectField;

// Internal Components

const FieldDisplay = ({
  name,
  label,
  partnerName,
}: {
  name: string;
  label: string;
  partnerName: string;
}) => {
  const { classes, cx } = useFieldDisplayStyles();
  return (
    <span>
      <span>{label}</span>
      {name.startsWith("left") && (
        <FormattedMessage {...i18n.your}>
          {(text) => (
            <span className={cx(classes.badge, classes.yours)}>{text}</span>
          )}
        </FormattedMessage>
      )}
      {name.startsWith("right") && (
        <span className={cx(classes.badge, classes.partners)}>
          {partnerName}
        </span>
      )}
    </span>
  );
};

// Internal Functions

const getOptions = (
  intl: IntlShape,
  fields: Record<string, FieldType>
): IDropdownOption[] => {
  const options: IDropdownOption[] = [
    {
      id: _.uniqueId("self"),
      group: [],
      name: intl.formatMessage(i18n.yourFields),
    },
    {
      id: _.uniqueId("partner"),
      group: [],
      name: intl.formatMessage(i18n.partnerFields),
    },
    {
      id: _.uniqueId("other"),
      group: [],
      name: intl.formatMessage(i18n.otherFields),
    },
  ];
  _.forEach(fields, (field, name) => {
    const option: IDropdownGroup = {
      id: name,
      name: field.label,
      options: [],
      disabled: field.isDisabled,
    };
    if (name.startsWith("left") && options[0]?.group) {
      options[0].group.push(option);
    } else if (name.startsWith("right") && options[1]?.group) {
      options[1].group.push(option);
    } else if (options[2]?.group) {
      options[2].group.push(option);
    }
  });

  if (options[2].group === undefined || options[2].group.length === 0) {
    options.splice(2, 1);
  }
  if (options[1].group === undefined || options[1].group?.length === 0) {
    options.splice(1, 1);
  }

  return options;
};

// CSS

const useFieldDisplayStyles = makeStyles()((theme) => ({
  badge: {
    padding: "2px 6px",
    borderRadius: 50,
    marginLeft: 4,
    color: theme.palette.offWhite,
    fontSize: 10,
  },
  yours: {
    backgroundColor: theme.palette.darkPurple,
  },
  partners: {
    backgroundColor: theme.palette.green,
  },
}));

// I18n

const i18n = defineMessages({
  selectField: {
    id: "Filters.SelectField.selectField",
    defaultMessage: "Select field",
  },
  addField: {
    id: "FilterRow.addField",
    defaultMessage: "Add filter",
  },
  your: {
    id: "Filters.SelectField.your",
    defaultMessage: "You",
  },
  partners: {
    id: "Filters.SelectField.partners",
    defaultMessage: "Partner",
  },
  // Option groups
  yourFields: {
    id: "Filters.SelectField.yourFields",
    defaultMessage: "Your fields",
  },
  partnerFields: {
    id: "Filters.SelectField.partnerFields",
    defaultMessage: "Partner fields",
  },
  otherFields: {
    id: "Filters.SelectField.otherFields",
    defaultMessage: "Other fields",
  },
});

// Exports

export const _private = {
  FieldDisplay,
  getOptions,
  i18n,
};
