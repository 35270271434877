import { createSvgIcon } from "@mui/material/utils";

import { SvgIcon } from "./types";

const displayName = "CheckboxChecked";

const CheckboxChecked: SvgIcon = createSvgIcon(
  // Using transform to enlarge from provided viewbox="0 0 16 16"
  // to default "0 0 24 24" from `createSvgIcon` -> `SvgIcon`.
  <g fill="none" transform="matrix(1.5,0,0,1.5,0,0)">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3.5 1.5C2.39543 1.5 1.5 2.39543 1.5 3.5V12.5C1.5 13.6046 2.39543 14.5 3.5 14.5H12.5C13.6046 14.5 14.5 13.6046 14.5 12.5V3.5C14.5 2.39543 13.6046 1.5 12.5 1.5H3.5ZM11.8536 5.64645C12.0488 5.84171 12.0488 6.15829 11.8536 6.35355L8.02935 10.1778C7.43273 10.7744 6.46158 10.7617 5.88073 10.1497L4.13735 8.31296C3.94724 8.11268 3.9555 7.7962 4.15579 7.6061C4.35607 7.41599 4.67255 7.42425 4.86265 7.62454L6.60603 9.46131C6.79965 9.6653 7.12337 9.66953 7.32224 9.47065L11.1464 5.64645C11.3417 5.45118 11.6583 5.45118 11.8536 5.64645Z"
      fill="currentColor"
    />
  </g>,
  displayName
);
CheckboxChecked.displayName = displayName;
export default CheckboxChecked;
