import BaseSettingsTab, {
  BaseSettingsTabProps,
} from "components/ui/BaseSettingsTab";
import { SWQLTarget } from "components/ui/SWQL/SWQLTypes";
import { ReactNode, useState } from "react";

import MappingRulesWidget, { RuleKeyAndLabel } from "./MappingRulesWidget";

type BaseMappingRulesWidgetProps = Omit<BaseSettingsTabProps, "saving"> & {
  integrationId: string | number;
  ruleList: RuleKeyAndLabel[];
  attribute: string;
  trackedLocation?: string;
  saveCallback?: () => void;
  noFieldsCallback?: () => void;
  swqlTarget: SWQLTarget;
  isGhost?: boolean;
  extraContent?: ReactNode;
};

const BaseMappingRulesWidget = ({
  integrationId,
  ruleList,
  attribute,
  trackedLocation,
  saveCallback,
  noFieldsCallback,
  swqlTarget,
  isGhost,
  extraContent,
  ...props
}: BaseMappingRulesWidgetProps) => {
  const [saving, setSaving] = useState(false);

  const handleSave = async () => {
    setSaving(true);
  };

  const _saveCallback = () => {
    setSaving(false);
    if (saveCallback) saveCallback();
  };

  return (
    <BaseSettingsTab saving={saving} handleSave={handleSave} {...props}>
      <MappingRulesWidget
        saving={saving}
        saveCallback={_saveCallback}
        // pass other attributes
        integrationId={integrationId}
        ruleList={ruleList}
        attribute={attribute}
        trackedLocation={trackedLocation}
        noFieldsCallback={noFieldsCallback}
        swqlTarget={swqlTarget}
        isGhost={isGhost}
      />
      {extraContent}
    </BaseSettingsTab>
  );
};

export default BaseMappingRulesWidget;
