import { createSelector } from "@reduxjs/toolkit";
import {
  allFeaturesList,
  FeaturePreset,
  powerList,
  PremiumFeatureRule,
  proList,
  RuleOperator,
} from "components/ui/navbar/PremiumPlanWidget/constants";
import _ from "lodash";
import CompanyPayingFeatureSubscription, {
  FeatureStatus,
} from "models/CompanyPayingFeatureSubscription";
import { RevealStore } from "redux/typing";

interface FeatureData {
  status: FeatureStatus;
  value: number;
}

export const selectPremiumPlanWidgetState = createSelector(
  (state: RevealStore) =>
    state.api.entities["company_paying_feature_subscriptions"] ?? {},
  (state: RevealStore) => state.user.profile.data,
  (state: RevealStore) => state.context.companyPayingFeatureSubscriptionIds,
  (state: RevealStore) => state.premiumPlanWidget,
  (
    companyPayingFeatureSubscriptions,
    user,
    companyPayingFeatureSubscriptionIds,
    state
  ) => {
    const allSubscriptions = companyPayingFeatureSubscriptionIds
      .map((id: number) => companyPayingFeatureSubscriptions[id])
      .filter(Boolean) as CompanyPayingFeatureSubscription[];
    // List of features included in company plan
    const featureGroupFeatures = user?.company?.featureGroupFeatures ?? [];

    // List of data (status and value) for all the features
    let allFeatureData: { [key: string]: FeatureData } = {};

    // Button status: Premium or Upgrade
    let isPremium = true;

    _.each(allFeaturesList, (preset: FeaturePreset) => {
      const subscription = getSubscription(allSubscriptions, preset.featureKey);
      const featureStatus = featureGroupFeatures.includes(preset.featureKey)
        ? FeatureStatus.unlimited
        : preset.rule
        ? getFeatureStatusFromRule(preset.rule, allSubscriptions, preset)
        : getFeatureStatus(subscription, preset);
      const value =
        user && preset.companyAttr
          ? _.get(user.company, preset.companyAttr, 0)
          : 0;

      if (
        featureStatus === FeatureStatus.locked ||
        featureStatus === FeatureStatus.limited ||
        featureStatus === FeatureStatus.trial
      ) {
        isPremium = false;
      }

      allFeatureData[preset.featureKey] = {
        status: featureStatus,
        value,
      };
    });

    // Commpute details for Pro features
    const proFeatures = getFeaturesDetail(proList, allFeatureData);
    // Commpute details for Power features
    const powerFeatures = getFeaturesDetail(powerList, allFeatureData);

    return {
      ...state,
      isPremium,
      proFeatures,
      powerFeatures,
    };
  }
);

// Helpers

const statusOrder = [
  { order: 0, key: FeatureStatus.unlimited },
  { order: 1, key: FeatureStatus.trial },
  { order: 2, key: FeatureStatus.limited },
  { order: 3, key: FeatureStatus.locked },
];

const getSubscription = (
  subscriptions: CompanyPayingFeatureSubscription[],
  featureKey: string
) =>
  subscriptions.filter(
    (subscription: CompanyPayingFeatureSubscription) =>
      subscription.payingFeature === featureKey
  )[0] ?? null;

const getFeatureStatus = (
  subscription: CompanyPayingFeatureSubscription | null,
  preset: FeaturePreset
) => {
  return subscription
    ? (subscription.status as FeatureStatus)
    : _.get(preset, "limitValue", false)
    ? FeatureStatus.limited
    : FeatureStatus.locked;
};

const getStatusFromList = (
  statusList: FeatureStatus[],
  operator: RuleOperator
) => {
  const statusAsNumbers = statusList.map(
    (status) => statusOrder.find((item) => status === item.key)?.order ?? 3
  );
  let status = 3;
  if (operator === "and") {
    status = Math.max.apply(Math, statusAsNumbers);
  } else if (operator === "or") {
    status = Math.min.apply(Math, statusAsNumbers);
  }
  return (
    statusOrder.find((item) => status === item.order)?.key ??
    FeatureStatus.locked
  );
};

const getFeatureStatusFromRule = (
  rule: PremiumFeatureRule,
  subscriptions: CompanyPayingFeatureSubscription[],
  preset: FeaturePreset
) => {
  const featureStatusList = rule.featureKeys.map((key) => {
    const subscription = getSubscription(subscriptions, key);
    return getFeatureStatus(subscription, preset);
  });
  const allEquals = featureStatusList.every((val, i, arr) => val === arr[0]);
  return allEquals
    ? featureStatusList[0]
    : getStatusFromList(featureStatusList, rule.operator);
};

const getFeaturesDetail = (
  features: FeaturePreset[],
  allFeatureData: { [key: string]: FeatureData }
) => {
  let generalStatus = FeatureStatus.unlimited;
  let lockedCount = 0;
  const featureList = features.map((feature) => {
    const { status, value } = _.get(allFeatureData, feature.featureKey, {
      status: FeatureStatus.limited,
      value: 0,
    });
    if (status === FeatureStatus.locked) {
      lockedCount++;
      generalStatus =
        lockedCount === features.length
          ? FeatureStatus.locked
          : FeatureStatus.limited;
    } else if (
      generalStatus === FeatureStatus.unlimited &&
      (status === FeatureStatus.limited || status === FeatureStatus.trial)
    ) {
      generalStatus = status;
    }
    return {
      ...feature,
      status,
      currentValue: value,
    };
  });
  return { generalStatus, featureList };
};

export const _private = {
  getSubscription,
  getFeatureStatus,
  getStatusFromList,
  getFeatureStatusFromRule,
};
