import { Property } from "csstype";
import { FocusEventHandler, MouseEventHandler, TouchEventHandler } from "react";

export type HTMLTextElement = HTMLHeadingElement &
  HTMLParagraphElement &
  HTMLSpanElement;

type H1 = { h1: boolean; bold?: boolean };
type H2 = { h2: boolean; bold?: boolean };
type H3 = { h3: boolean; bold?: boolean };
type H4 = { h4: boolean; bold?: boolean };
type BodyBig = { bodyBig: boolean; bold?: boolean };
type BodySmall = { bodySmall: boolean; bold?: boolean };
type LabelBig = { labelBig: boolean };
type LabelSmall = { labelSmall: boolean };
type TitleBig = { titleBig: boolean };
type TitleSmall = { titleSmall: boolean };
type Uppercase = { uppercase: boolean };
type HelperErrorMsg = {
  "This error may arise if more than one variant is selected": never;
};

type All = H1 &
  H2 &
  H3 &
  H4 &
  BodyBig &
  BodySmall &
  LabelBig &
  LabelSmall &
  TitleBig &
  TitleSmall &
  Uppercase &
  HelperErrorMsg;

type AllNever = { [key in keyof All]?: never };

type Variant<T> = T & Omit<AllNever, keyof T>;

type Variants =
  | Variant<H1>
  | Variant<H2>
  | Variant<H3>
  | Variant<H4>
  | Variant<BodyBig>
  | Variant<BodySmall>
  | Variant<LabelBig>
  | Variant<LabelSmall>
  | Variant<TitleBig>
  | Variant<TitleSmall>
  | Variant<Uppercase>
  // Default typography (when no variant is specified it's set to BodySmall).
  // You can also specify one of the variants like `variant="h1"` which is only useful when
  // typography is decided by some boolean value, e.g. `variant={big ? "labelBig" : "labelSmall"}`.
  | Variant<{
      variant?: Exclude<
        keyof All,
        "bold" | "This error may arise if more than one variant is selected"
      >;
      // This can lead to misinterpretation but it's needed. Only typography variants compatible
      // with `bold` will have the style applied. E.g. `<T variant="uppercase" bold>` won't be bold.
      bold?: boolean;
    }>
  // This HelperErrorMsg must come as last to show up on errors.
  | Variant<HelperErrorMsg>;

export type Props = Variants & {
  children?: React.ReactNode;
  noWrap?: boolean;
  className?: string;
  title?: string;
  span?: boolean;
  // Here we define the subset of styles which can be overwritten inline.
  textAlign?: Property.TextAlign;
  color?: Property.Color;
  // Handlers necessary to be used inside a Tooltip.
  onBlur?: FocusEventHandler;
  onFocus?: FocusEventHandler;
  onMouseLeave?: MouseEventHandler;
  onMouseOver?: MouseEventHandler;
  onTouchEnd?: TouchEventHandler;
  onTouchStart?: TouchEventHandler;
  // TODO: Remove after redesign deploy, only used by deprecated typography.
  oldVariant?: string;
};

//
// Helpers
//

export function customStyles({ textAlign, color }: Props) {
  const styles = {
    textAlign,
    color,
  };

  // Prune styles that are `undefined` to prevent them overwriting base values.
  (Object.keys(styles) as Array<keyof typeof styles>).forEach(
    (key) => styles[key] === undefined && delete styles[key]
  );

  return styles;
}

//
// Type guards
//

export function isH1(props: Props): props is Variant<H1> {
  return Boolean(props.h1);
}
export function isH2(props: Props): props is Variant<H2> {
  return Boolean(props.h2);
}
export function isH3(props: Props): props is Variant<H3> {
  return Boolean(props.h3);
}
export function isH4(props: Props): props is Variant<H4> {
  return Boolean(props.h4);
}
export function isBodyBig(props: Props): props is Variant<BodyBig> {
  return Boolean(props.bodyBig);
}
export function isBodySmall(props: Props): props is Variant<BodySmall> {
  return Boolean(props.bodySmall);
}
export function isLabelBig(props: Props): props is Variant<LabelBig> {
  return Boolean(props.labelBig);
}
export function isLabelSmall(props: Props): props is Variant<LabelSmall> {
  return Boolean(props.labelSmall);
}
export function isTitleBig(props: Props): props is Variant<TitleBig> {
  return Boolean(props.titleBig);
}
export function isTitleSmall(props: Props): props is Variant<TitleSmall> {
  return Boolean(props.titleSmall);
}
export function isUppercase(props: Props): props is Variant<Uppercase> {
  return Boolean(props.uppercase);
}
