import { createSvgIcon } from "@mui/material/utils";

import { SvgIcon } from "./types";

const displayName = "CalendarBig";

const CalendarBig: SvgIcon = createSvgIcon(
  // Using transform to enlarge from provided viewbox="0 0 16 16"
  // to default "0 0 24 24" from `createSvgIcon` -> `SvgIcon`.
  <g fill="none" transform="matrix(1.5,0,0,1.5,0,0)">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4.75 0.695313C4.75 0.41917 4.52614 0.195312 4.25 0.195312C3.97386 0.195312 3.75 0.41917 3.75 0.695312V1.69531H2.25C1.2835 1.69531 0.5 2.47881 0.5 3.44531V11.9453C0.5 12.9118 1.2835 13.6953 2.25 13.6953H11.75C12.7165 13.6953 13.5 12.9118 13.5 11.9453V3.44531C13.5 2.47881 12.7165 1.69531 11.75 1.69531H10.25V0.695313C10.25 0.41917 10.0261 0.195312 9.75 0.195312C9.47386 0.195312 9.25 0.41917 9.25 0.695312V1.69531H4.75V0.695313ZM9.25 3.69531V2.69531H4.75V3.69531C4.75 3.97145 4.52614 4.19531 4.25 4.19531C3.97386 4.19531 3.75 3.97145 3.75 3.69531V2.69531H2.25C1.83579 2.69531 1.5 3.0311 1.5 3.44531V6.19531H12.5V3.44531C12.5 3.0311 12.1642 2.69531 11.75 2.69531H10.25V3.69531C10.25 3.97145 10.0261 4.19531 9.75 4.19531C9.47386 4.19531 9.25 3.97145 9.25 3.69531ZM12.5 7.19531H1.5V11.9453C1.5 12.3595 1.83579 12.6953 2.25 12.6953H11.75C12.1642 12.6953 12.5 12.3595 12.5 11.9453V7.19531Z"
      fill="#FAFAF6"
    />
  </g>,
  displayName
);

CalendarBig.displayName = displayName;

export default CalendarBig;
