import { alpha } from "@mui/material";

/** Pinks */
export const darkPink = "#D72959";
export const mainPink = "#E34571";

/** Greys */
export const black = "#000000";
export const darkGrey = "#2c3340";
export const midGrey = "#7b7b7b";
export const grey = "#D2D2D2";
export const lightGrey = "#EFEFEF";
export const veryLightGrey = "#F9F8F8";
export const greyWhite = "#F3F3F3";
export const lightGreyWhite = "#fcfcfc";
export const white = "#FFFFFF";

/** Main Brand Color */
export const brand = mainPink;

/** Error Color */
export const error = "#A30145";
export const bloodred = "#bf152c";

/** External Brands */
export const hubspot = "#FF8F73";
export const pipedrive = "#26292C";
export const salesforce = "#04A1DF";
export const zoho = "#EB3D3C";

/* Other */
export const partner = "#ff5768";
export const blue = "#18A999";
export const ninaGreen = "#18A999";

/** Reveal Primary Colors */
export const purple = "#7E64FF";
export const purple040 = "#7E64FF0A";
export const darkPurple = "#5A28C3";
export const lightPurple = "#f5f3ff";
export const midnight = "#0A151B";
export const midnightAlpha040 = alpha("#0A151B", 0.04);
export const midnight500 = "#828888";
export const comet = "#1C2430";
export const darkPigeon = "#2A3343";
export const pigeon = "#5A617F";
export const greyReveal = "#8D8F9D";
export const grey500 = "#F8F8F8";
export const darkTaupe = "#CAC0B7";
export const taupe = "#E2DEDA";
export const taupe500 = "#ECE8E1";
export const taupe250 = "#F3F1EB";
export const offWhite = "#F6F2E8";
export const ivory = "#FAFAF6";
export const green = "#23D49F";
export const appGreen = "#23D49F";
export const green600 = "#18BF8D";
export const purple500 = "#7C62E1";
export const purple700 = "#322664";

/** Reveal Secondary Colors */
export const wine = "#A30145";
export const pictonBlue = "#30BCED";
export const orange = "#FC5130";
export const pistachio = "#98CE00";
export const elm = "#218380";
export const cornflowerBlue = "#7494EA";
export const gold = "#D7AF70";
export const londonHue = "#B58DB6";
export const coral = "#7494EA";

/** Colors 2.0 */
export const greyscale100 = "#F7F3E8";
export const greyscale150 = "#F1EDE4";
export const greyscale200 = "#EAE6DF";
export const greyscale250 = "#E2DEDA";
export const greyscale300 = "#D2CFCE";
export const greyscale350 = "#C0BFC2";
export const greyscale400 = "#B0AFB6";
export const greyscale450 = "#9E9FAA";
export const greyscale500 = "#8D8F9D";

export const greyscale550 = "#818496";
export const greyscale600 = "#74798F";
export const greyscale650 = "#676D87";
export const greyscale700 = "#5A617F";
export const greyscale750 = "#464D65";
export const greyscale800 = "#31394B";
export const greyscale850 = "#1C2430";
export const greyscale900 = "#131C26";
export const greyscale950 = "#0A151B";
export const greyscale1000 = "#070E13";

export const greyDarkMode200 = "#202834";
export const greyDarkMode800 = "#E7E8E9";

export const greyLight050 = "#F4F1E6";
export const greyLight050Alpha050 = alpha("#F4F1E6", 0.5);
export const greyLight100 = "#E9E5DB";
export const greyLight200 = "#D5D1C8";
export const greyLight300 = "#BEBBB3";

export const alpha050 = alpha("#8E7A29", 0.04);
export const alpha100 = alpha("#D0A643", 0.08);
export const alpha150 = alpha("#AE8C5D", 0.12);
export const alpha200 = alpha("#9A806A", 0.16);
export const alpha250 = alpha("#846F6C", 0.2);
export const alpha300 = alpha("#493E4C", 0.24);
export const alpha350 = alpha("#454153", 0.32);
export const alpha400 = alpha("#2C2848", 0.36);
export const alpha450 = alpha("#1D203F", 0.42);
export const alpha500 = alpha("#1E2343", 0.5);

export const alpha550 = alpha("#20274B", 0.56);
export const alpha600 = alpha("#1B224B", 0.6);
export const alpha650 = alpha("#151D47", 0.64);
export const alpha700 = alpha("#15204C", 0.7);
export const alpha750 = alpha("#0D173A", 0.76);
export const alpha800 = alpha("#050F24", 0.82);
export const alpha850 = alpha("#000714", 0.88);
export const alpha900 = alpha("#000914", 0.92);
export const alpha950 = alpha("#010C14", 0.96);
export const alpha1000 = alpha("#070E13", 1);

export const green050 = "#F5FEFB";
export const green100 = "#D5EEE5";
export const green100v2 = "#D2ECD7";
export const green200 = "#CCF9EC";
export const green300 = "#7FCAB0";
export const green400 = "#3BAE87";
export const green500 = "#2F8567";
export const newGreen600 = "#2DD09F";
export const green700 = "#26AD85";
export const green700v2 = "#123818";
export const green800 = "#1E8B6A";
export const green900 = "#197157";

export const greenBrand050 = "#EEF9F5";
export const greenBrand100 = "#DDF3EB";
export const greenBrand400 = "#29AF81";
export const greenBrand700 = "#023825";

export const red50 = "#F7EDED";
export const red050 = "#FDF7F9";
export const red050v2 = "#FEEDEE";
export const red100 = "#FDE2E3";
export const newRed100 = "#FAEEF3";
export const red200 = "#F3D6E0";
export const red300 = "#ECBDCE";
export const red400 = "#DD8186";
export const red500 = "#D05984";
export const red500v2 = "#EB1723";
export const red600 = "#BF3D6C";
export const newRed600 = "#BB5077";
export const red600v3 = "#AA1119";
export const red700 = "#9C4363";
export const red700v2 = "#650A0F";
export const red800 = "#7D354F";
export const red900 = "#662C41";

export const goldOverlay = "#FAF4E9";
export const gold050 = "#F8F0E4";
export const gold100 = "#F3E7D3";
export const gold200 = "#F5D29E";
export const gold300 = "#DBB67C";
export const gold400 = "#C9903A";
export const gold400V2 = "#DB880B";
export const gold500 = "#D7AF70";
export const gold600 = "#C29E65";
export const gold700 = "#462A00";
export const gold800 = "#816943";
export const gold900 = "#695637";

export const brown100 = "#F3E2D9";
export const brown700 = "#462919";

export const olive100 = "#E7E7CF";
export const olive700 = "#323214";

export const teal100 = "#D5EAE7";
export const teal700 = "#173532";

export const overlay = "#0A151B14";

export const blue100 = "#DDE6F4";

export const yellow100 = "#EBE6CC";
export const yellow700 = "#373011";

export const orange100 = "#F2E2DC";

export const purpleBrand050 = "#F2EFFC";
export const purpleBrand100 = "#E7E2F9";

export const pie1 = "#BCAFFA";
export const pie2 = "#DBD5F8";

export const cyan100 = "#D8E8F0";
export const cyan700 = "#183342";
