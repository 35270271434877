import { ArrowLeft, ArrowRight } from "components/icons";
import Button from "components/ui/Button";
import { SWQLTarget } from "components/ui/SWQL/SWQLTypes";
import { crmProviders, ProviderType } from "config/constants";
import getFirstValueFromURL from "helpers/getFirstValueFromURL";
import Match from "models/Match";
import { useState } from "react";
import { defineMessages, FormattedHTMLMessage } from "react-intl";
import { useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import { selectHasSources } from "redux/user/selectors";
import MappingRulesWidget from "screens/Frontoffice/shared/components/SourceSettingsDialog/components/MappingRulesWidget";
import { updateHistoryUpdatedSource } from "screens/Frontoffice/shared/components/SourceSettingsDialog/helpers/utils";
import { isCrmFileType } from "screens/Frontoffice/shared/helpers/types";

import { BaseContent, sharedStyles } from "./BaseContent";
import { ContentHeaderWithLogo } from "./ContentHeaderWithLogo";

export const StatusRulesContent = () => {
  const { classes } = sharedStyles({});
  const location = useLocation();
  const history = useHistory();
  const provider = getFirstValueFromURL(location, "provider");
  const integrationId = getFirstValueFromURL(location, "integration");
  const [saving, setSaving] = useState(false);
  const hasSources = useSelector(selectHasSources);

  if (!integrationId) {
    throw new Error("No integration ID");
  }
  if (!provider) {
    throw new Error("No provider");
  }
  const config = crmProviders[provider as ProviderType];

  const handleGoBack = () => {
    history.push({
      ...location,
      hash: "column-mapping",
    });
  };

  const handleSave = () => {
    setSaving(false);
    updateHistoryUpdatedSource(history, location);
    history.push({
      ...location,
      hash: hasSources ? "assignment" : "",
    });
  };

  return (
    <BaseContent>
      <ContentHeaderWithLogo
        imgSrc={config?.logo as string}
        imgAlt={config?.name as string}
        i18nTitle={i18n.title}
        i18nSubtitle={i18n.subtitle}
      />
      <div className={classes.fullWidth}>
        <MappingRulesWidget
          integrationId={integrationId}
          ruleList={RuleList}
          attribute={Match.STATUS_ATTRIBUTE}
          swqlTarget={SWQLTarget.RawCompany}
          saving={saving}
          saveCallback={handleSave}
          trackedLocation="new crm status rules"
        />
      </div>
      <div className={classes.action}>
        <Button
          disabled={saving}
          loading={saving}
          label={i18n.validate}
          RightIcon={ArrowRight}
          onClick={() => setSaving(true)}
        />
        {isCrmFileType(provider) && (
          <Button
            disabled={saving}
            label={i18n.back}
            LeftIcon={ArrowLeft}
            onClick={handleGoBack}
            variant="tertiary"
          />
        )}
      </div>
    </BaseContent>
  );
};

const i18n = defineMessages({
  back: {
    id: "StatusRulesContent.back",
    defaultMessage: "Back",
  },
  custormersAre: {
    id: "StatusRulesContent.customers_are",
    defaultMessage: "Create the rule to help us define your Customers:",
  },
  prospectsAre: {
    id: "StatusRulesContent.prospects_are",
    defaultMessage: "Create the rule to help us define your Prospects:",
  },
  partnersAre: {
    id: "StatusRulesContent.partners_are",
    defaultMessage: "Create the rule to help us define your Partners:",
  },
  title: {
    id: "StatusRulesContent.title",
    defaultMessage: "Define your status rules",
  },
  statusRulesAlertTitle: {
    id: "StatusRulesContent.statusRulesAlertTitle",
    defaultMessage: "Status Rules",
  },
  subtitle: {
    id: "StatusRulesContent.subtitle",
    defaultMessage:
      "Status rules help us understand how you define a customer, a prospect, or a partner in your CRM.",
  },
  validate: {
    id: "StatusRulesContent.validate",
    defaultMessage: "Validate",
  },
});

const RuleList = [
  {
    key: Match.STATUS_CUSTOMER,
    label: <FormattedHTMLMessage {...i18n.custormersAre} />,
  },
  {
    key: Match.STATUS_PROSPECT,
    label: <FormattedHTMLMessage {...i18n.prospectsAre} />,
  },
  {
    key: Match.STATUS_PARTNER,
    label: <FormattedHTMLMessage {...i18n.partnersAre} />,
  },
];
