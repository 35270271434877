import { defineMessages } from "react-intl";

export default defineMessages({
  company_self: {
    id: "forms.company.company_self",
    defaultMessage:
      "You selected your own company. Please select another company to send a partner invite.",
  },
  reconfirmEmail: {
    id: "forms.email.reconfirm",
    defaultMessage: "You will receive an email to confirm you own this address",
  },
  email_already_taken: {
    id: "forms.email.taken",
    description: "Error message when email is already used by another account",
    defaultMessage: "This email address is already used",
  },
  email_blacklisted_invite: {
    id: "forms.email.blacklisted.invite",
    description:
      "Error message when a user provided a blacklisted email address to invite a partner",
    defaultMessage:
      "You cannot invite partners with a personal email address. Please use a work email address.",
  },
  email_blacklisted_signin: {
    id: "forms.email.blacklisted.signin",
    description:
      "Error message when a user provided a blacklisted email address to sign in",
    defaultMessage:
      "You cannot sign in with a personal email address. Please use a work email address.",
  },
  email_blacklisted_signup: {
    id: "forms.email.blacklisted.signup",
    description:
      "Error message when a user provided a blacklisted email address to sign up",
    defaultMessage:
      "You cannot sign up with a personal email address. Please use a work email address.",
  },
  email_invalid: {
    id: "forms.email.invalid",
    description: "Error message when a user provided an invalid email address",
    defaultMessage: "The provided email address is not correct",
  },
  email_required: {
    id: "forms.email.required",
    description: "Error message when a required email field is not filled in",
    defaultMessage: "Email Address is Required",
  },
  email_self: {
    id: "forms.email.self",
    description: "Error message when a user provided their own email address",
    defaultMessage:
      "Please enter an email address for your contact at {companyName}",
  },
  url_invalid: {
    id: "forms.url.invalid",
    description: "Error message when a user provided an invalid url",
    defaultMessage: "The provided url is not correct",
  },
  first_name_required: {
    id: "forms.first_name.required",
    description:
      "Error message when a required first name field is not filled in",
    defaultMessage: "Please provide your First Name",
  },
  last_name_required: {
    id: "forms.last_name.required",
    description:
      "Error message when a required last name field is not filled in",
    defaultMessage: "Please provide your Last Name",
  },
  company_requires_sso: {
    id: "forms.company.requires_sso",
    description:
      "Error message when use tries to join a company requesting sso auth",
    defaultMessage: "This company requires SSO authentication",
  },
  company_name_required: {
    id: "forms.company_name.required",
    description: "Error message when a required company field is not filled in",
    defaultMessage: "Please provide your Company",
  },
  contact_first_name_required: {
    id: "forms.contact_first_name.required",
    description:
      "Error message when a required contact first name field is not filled in",
    defaultMessage: "Please provide the First Name",
  },
  contact_last_name_required: {
    id: "forms.contact_last_name.required",
    description:
      "Error message when a required contact last name field is not filled in",
    defaultMessage: "Please provide the Last Name",
  },
  contact_company_name_required: {
    id: "forms.contact_company_name.required",
    description:
      "Error message when a required contact company field is not filled in",
    defaultMessage:
      "Please provide the name of the Company you wish to invite as a partner.",
  },
  password_confirmation_required: {
    id: "forms.password_confirmation.required",
    description:
      "Error message when password confirmation field is not filled in",
    defaultMessage: "Please confirm your Password",
  },
  password_required: {
    id: "forms.password.required",
    description: "Error message when password field is not filled in",
    defaultMessage: "Please provide a Password",
  },
  password_too_short: {
    id: "forms.password.too_short",
    description:
      "Error message when an error message is too short (8 chars min)",
    defaultMessage:
      "The provided Password is too short (at least 8 characters)",
  },
  password_mismatch: {
    id: "forms.password.mismatch",
    description: "Error message when password and its confirmation differ",
    defaultMessage: "The Passwords mismatch",
  },
  password_at_least_8: {
    id: "forms.password.password_at_least_8",
    defaultMessage: "Must contain at least 8 characters",
  },
  password_complexity: {
    id: "forms.password.password_complexity",
    defaultMessage: "Must contain at least 1 uppercase letter or number",
  },
  generic_required: {
    id: "forms.required",
    description: "Generic error message for missing required field",
    defaultMessage: "This field is required",
  },
  generic_invalid: {
    id: "forms.invalid",
    description: "Generic error message for invalid value for field",
    defaultMessage: "This value is not valid",
  },
  unauthorized_email_domain: {
    id: "forms.unauthorized_email_domain_name",
    description: "Generic error message for unauthorized email",
    defaultMessage: "Please register with your company email",
  },
});
