import { Close } from "@mui/icons-material";
import { Box } from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import { Plus } from "components/icons";
import Button from "components/ui/Button";
import { T } from "components/ui/Typography";
import useUserProfile from "hooks/useUserProfile";
import { makeStyles } from "makeStyles";
import { defineMessages, FormattedMessage } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { selectHasFilterApplied } from "redux/pipeline/selectors";
import { removeAllFiltersExceptView } from "redux/pipeline/thunks";

type PipelineEmptyRowsViewProps = {
  loading?: boolean;
  startAdding?: () => void;
};

export const PipelineEmptyRowsView = ({
  loading,
  startAdding,
}: PipelineEmptyRowsViewProps) => {
  const dispatch = useDispatch();
  const { classes } = useStyles();
  const { profile } = useUserProfile();
  const hasFilters = useSelector(selectHasFilterApplied);

  const removeFilters = () => dispatch(removeAllFiltersExceptView());

  return (
    <div className={classes.root}>
      {loading ? (
        <CircularProgress />
      ) : (
        <>
          <Box className={classes.text}>
            <T h3 bold>
              <FormattedMessage {...i18n.noRecords} />
            </T>
          </Box>
          {!hasFilters && startAdding && profile.ownsPipeline && (
            <Box className={classes.button}>
              <Button
                label={i18n.addToPipeline}
                onClick={startAdding}
                LeftIcon={Plus}
                variant="tertiary"
              />
            </Box>
          )}
          {hasFilters && (
            <Box className={classes.button}>
              <Button
                label={i18n.clearFilters}
                onClick={removeFilters}
                LeftIcon={Close}
                variant="tertiary"
              />
            </Box>
          )}
        </>
      )}
    </div>
  );
};

export default PipelineEmptyRowsView;

/// CSS

const useStyles = makeStyles()((theme) => ({
  button: {
    marginTop: theme.spacing(2.5),
  },
  root: {
    display: "flex",
    flexDirection: "column",
    height: "100%",
    alignItems: "center",
    justifyContent: "center",
    padding: theme.spacing(4),
  },
  text: {
    marginBottom: theme.spacing(1),
  },
}));

// I18N

const i18n = defineMessages({
  clearFilters: {
    id: "Pipeline.PipelineEmptyRowsView.clearFilters",
    defaultMessage: "Clear all filters",
  },
  addToPipeline: {
    id: "Pipeline.PipelineEmptyRowsView.addToPipeline",
    defaultMessage: "Add to Collaborate",
  },
  noRecords: {
    id: "Pipeline.PipelineEmptyRowsView.noRecords",
    defaultMessage: "There is no Collaboration yet.",
  },
});
