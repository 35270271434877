import { createSvgIcon } from "@mui/material/utils";

import { SvgIcon } from "./types";

const displayName = "Sticker";

const Sticker: SvgIcon = createSvgIcon(
  // Using transform to enlarge from provided viewbox="0 0 16 16"
  // to default "0 0 24 24" from `createSvgIcon` -> `SvgIcon`.
  <g fill="none" transform="matrix(2,0,0,2,1,1)">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5.55496 1.03125C5.87437 1.03111 6.11065 1.03101 6.33828 1.08566C6.53916 1.13389 6.7312 1.21343 6.90735 1.32138C7.10695 1.44369 7.27395 1.61084 7.49971 1.83679L10.1632 4.5003C10.3892 4.72605 10.5563 4.89307 10.6786 5.09266C10.7866 5.26881 10.8661 5.46085 10.9144 5.66174C10.969 5.88935 10.9689 6.12563 10.9688 6.44503L10.9688 8.11955C10.9688 8.52296 10.9688 8.85409 10.9468 9.12338C10.924 9.4025 10.8752 9.65628 10.7542 9.8938C10.5654 10.2642 10.2642 10.5654 9.89379 10.7542C9.65627 10.8752 9.40248 10.924 9.12337 10.9468C8.85407 10.9688 8.523 10.9688 8.11958 10.9688H3.88046C3.47703 10.9688 3.14593 10.9688 2.87663 10.9468C2.59752 10.924 2.34373 10.8752 2.10621 10.7542C1.73576 10.5654 1.43458 10.2642 1.24583 9.8938C1.1248 9.65628 1.07605 9.4025 1.05324 9.12338C1.03124 8.85407 1.03124 8.52299 1.03125 8.11955V3.88048C1.03124 3.47704 1.03124 3.14595 1.05324 2.87664C1.07605 2.59753 1.1248 2.34375 1.24583 2.10622C1.43458 1.73577 1.73576 1.43459 2.10621 1.24584C2.34374 1.12482 2.59752 1.07606 2.87663 1.05325C3.14594 1.03125 3.47703 1.03126 3.88047 1.03126L5.55496 1.03125ZM6.96872 1.75013C6.96872 1.49124 6.75885 1.28138 6.49997 1.28138C6.24109 1.28138 6.03122 1.49124 6.03122 1.75013L6.03122 3.11966C6.03121 3.52309 6.03121 3.85419 6.05321 4.12349C6.07602 4.40261 6.12477 4.65639 6.2458 4.89392C6.43455 5.26436 6.73573 5.56554 7.10618 5.75429C7.3437 5.87532 7.59749 5.92408 7.8766 5.94688C8.14591 5.96889 8.477 5.96888 8.88045 5.96888H10.25C10.5089 5.96888 10.7187 5.75901 10.7187 5.50013C10.7187 5.24124 10.5089 5.03138 10.25 5.03138H8.89997C8.4722 5.03138 8.17956 5.03101 7.95294 5.0125C7.7318 4.99443 7.61513 4.96144 7.53179 4.91898C7.33775 4.82011 7.17999 4.66235 7.08112 4.4683C7.03866 4.38496 7.00567 4.26829 6.9876 4.04715C6.96908 3.82054 6.96872 3.5279 6.96872 3.10013V1.75013Z"
      fill="#DBAD36"
    />
  </g>,
  displayName
);

Sticker.displayName = displayName;
export default Sticker;
