import { TextInput } from "components/ui/TextInput";
import { defineMessages, useIntl } from "react-intl";

import OperatorAddWidget from "../OperatorAddWidget";
import { SWQLBooleanOperator, SWQLRightValue } from "../SWQLTypes";

type Props = {
  value?: string;
  onAddNode: (operator: SWQLBooleanOperator) => void;
  onChange: (value: SWQLRightValue, extra?: object) => void;
};

const TextField = ({ value, onChange, onAddNode }: Props) => {
  const intl = useIntl();
  const handleChange = ({ target: { value } }: $TSFixMe) => onChange(value);
  return (
    <>
      <TextInput
        small
        onChange={handleChange}
        placeholder={intl.formatMessage(i18n.placeholder)}
        value={value}
      />
      {Boolean(value) && <OperatorAddWidget onAddNode={onAddNode} />}
    </>
  );
};

export default TextField;

const i18n = defineMessages({
  placeholder: {
    id: "SWQL.SwQLValueFields.TextField.placeholder",
    defaultMessage: "Type value…",
  },
});
