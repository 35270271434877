import { History } from "history";
import Partnership from "models/Partnership";

export const goToPartnershipSettings = (
  partnership: Partnership,
  history: History,
  tab?: string
) => {
  const defaultTab = partnership.isGhost() ? "data-sources" : "general";
  history.push({
    pathname: `/partnerships/${partnership.id}/settings/${tab ?? defaultTab}`,
  });
};
