import { Box } from "@mui/material";
import { Fire } from "components/icons";
import Tooltip from "components/ui/Tooltip";
import { T } from "components/ui/Typography";
import { makeStyles } from "makeStyles";
import Match from "models/Match";
import { defineMessages, FormattedMessage } from "react-intl";
import { Available360MappingColumns } from "redux/mapping360/defaults";
import { AvailableNearboundAccountsColumns } from "redux/mapping360NearboundAccounts/defaults";

import { CellProps } from "../utils";

const PartnerSignalsCell = ({ value, row }: CellProps) => {
  const isCustomer = row.status === Match.STATUS_CUSTOMER;
  const { classes } = useStyles({
    isCustomer,
  });

  if (!value) {
    return null;
  }
  return (
    <Tooltip
      title={
        isCustomer ? (
          <T>
            <FormattedMessage {...i18n.alreadyCustomer} />
          </T>
        ) : (
          ""
        )
      }
    >
      <Box className={classes.container}>
        <Fire className={classes.icon} />
        {value}
      </Box>
    </Tooltip>
  );
};

export default PartnerSignalsCell;

// Helpers

export const isPartnerSignalsCell = ({ fieldName }: CellProps) => {
  return [
    Available360MappingColumns.partnerSignals,
    Available360MappingColumns.customerPresenceHighlightsCount,
    AvailableNearboundAccountsColumns.PartnerSignalsCount,
  ].includes(fieldName as Available360MappingColumns);
};

const useStyles = makeStyles<{ isCustomer: boolean }>()(
  (theme, { isCustomer }) => ({
    icon: {
      height: 15,
      "& g": {
        fill: isCustomer ? theme.palette.greyscale250 : theme.palette.gold300,
      },
    },
    container: {
      color: isCustomer ? theme.palette.greyscale250 : theme.palette.midnight,
    },
  })
);

const i18n = defineMessages({
  alreadyCustomer: {
    id: "cellRenderers.PartnerSignalsCell.alreadyCustomer",
    defaultMessage: "This account is already a Customer",
  },
});
