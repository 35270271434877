import { useMediaQuery } from "@mui/material";
import withCompanyPermissions from "components/hoc/withCompanyPermissions";
import { WithUserProfile } from "components/hoc/withUserProfile";
import theme from "config/theme";
import { Ref } from "react";
import { defineMessages } from "react-intl";

import { UnlockTableDialog } from "../../../shared/components/UnlockTableDialog";
import { MappingFilterProvider } from "../../../shared/contexts/MappingFilterContext";
import { expandNewMarketsFields } from "../constants";
import { useApiLogic } from "../hooks/useMapping360ApiLogic";
import Content from "./Content";

const Mapping360 = ({
  dialogRef,
}: WithUserProfile & { dialogRef?: Ref<HTMLElement> }) => {
  const isSmall = useMediaQuery(theme.breakpoints.down("md"));
  const {
    columns,
    count,
    fetching,
    fields,
    filters,
    hasGrowthKpisFeature,
    hasUnlockDialog,
    hiddenRows,
    isExpandToNewMarketsView,
    loadMore,
    openDealFields,
    ready,
    rows,
    selectedSourceId,
    setSelectedSourceId,
    setView,
    sort,
    sync,
    viewType,
  } = useApiLogic();

  return (
    <MappingFilterProvider
      hasGrowthKpisFeature={hasGrowthKpisFeature}
      isSmall={isSmall}
      viewType={viewType}
    >
      <Content
        rows={rows}
        rowsCount={count}
        filters={filters}
        setView={setView}
        columns={columns}
        sort={sort}
        selectedSourceId={selectedSourceId}
        setSelectedSourceId={setSelectedSourceId}
        fields={isExpandToNewMarketsView ? expandNewMarketsFields : fields}
        loading={!ready || !sync || fetching || count === null}
        loadMore={loadMore}
        openDealFields={openDealFields}
        fetching={fetching}
        hiddenRows={hiddenRows}
        dialogRef={dialogRef}
      />
      {hasUnlockDialog && (
        <UnlockTableDialog
          buttonLabel={i18n.unlockDialogButton}
          from="unlock 360 mapping cta"
          subtitle={i18n.unlockDialogSubtitle}
          title={i18n.unlockDialogTitle}
        />
      )}
    </MappingFilterProvider>
  );
};

export default withCompanyPermissions(["company.partners_access"])(Mapping360);

/// I18N

const i18n = defineMessages({
  unlockDialogTitle: {
    id: "crm.Ecosystem.EcosystemSignalsTable.unlockDialogTitle",
    defaultMessage: "Unlock partner revenue on all your accounts",
  },
  unlockDialogSubtitle: {
    id: "crm.Ecosystem.EcosystemSignalsTable.unlockDialogSubtitle",
    defaultMessage: "360 Mapping is limited to 5 rows in Reveal's free version",
  },
  unlockDialogButton: {
    id: "crm.Ecosystem.EcosystemSignalsTable.unlockDialogButton",
    defaultMessage: "See all details",
  },
});
