import { createSvgIcon } from "@mui/material/utils";

import { SvgIcon } from "./types";

const displayName = "Users";

const Users: SvgIcon = createSvgIcon(
  // Using transform to enlarge from provided viewBox="0 0 20 20"
  // to default "0 0 24 24" from `createSvgIcon` -> `SvgIcon`.
  <g fill="none" transform="matrix(1.2,0,0,1.2,0,0)">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3.125 10C3.125 6.20304 6.20304 3.125 10 3.125C13.797 3.125 16.875 6.20304 16.875 10C16.875 11.775 16.2023 13.3929 15.098 14.6126C14.6213 13.8933 13.9958 13.2801 13.2602 12.8167C12.284 12.2017 11.1538 11.8753 10 11.8753C8.84622 11.8753 7.71598 12.2017 6.73979 12.8167C6.00421 13.2801 5.37871 13.8933 4.90196 14.6126C3.79765 13.3929 3.125 11.775 3.125 10ZM5.83706 15.4718C6.99252 16.3522 8.43521 16.875 10 16.875C11.5648 16.875 13.0075 16.3522 14.1629 15.4718C13.7723 14.8264 13.2358 14.2787 12.5939 13.8743C11.8172 13.385 10.918 13.1253 10 13.1253C9.08203 13.1253 8.18279 13.385 7.40612 13.8743C6.7642 14.2787 6.22765 14.8264 5.83706 15.4718ZM10 1.875C5.51269 1.875 1.875 5.51269 1.875 10C1.875 14.4873 5.51269 18.125 10 18.125C14.4873 18.125 18.125 14.4873 18.125 10C18.125 5.51269 14.4873 1.875 10 1.875ZM9.99985 6.12518C8.96431 6.12518 8.12485 6.96465 8.12485 8.00018C8.12485 9.03572 8.96431 9.87518 9.99985 9.87518C11.0354 9.87518 11.8748 9.03572 11.8748 8.00018C11.8748 6.96465 11.0354 6.12518 9.99985 6.12518ZM6.87485 8.00018C6.87485 6.27429 8.27396 4.87518 9.99985 4.87518C11.7257 4.87518 13.1248 6.27429 13.1248 8.00018C13.1248 9.72607 11.7257 11.1252 9.99985 11.1252C8.27396 11.1252 6.87485 9.72607 6.87485 8.00018Z"
      fill="currentColor"
    />
  </g>,

  displayName
);

Users.displayName = displayName;
export default Users;
