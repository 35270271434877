import { AccessTime, AutoGraphRounded } from "@mui/icons-material";
import {
  Chat,
  Directory,
  Home,
  Integrations,
  Mapping360,
  PendingPartners,
  PlusCircle,
  Rocket,
  Scale,
  Settings,
  Sources,
} from "components/icons";
import { PayingFeature } from "models/CompanyPayingFeatureSubscription";
import User from "models/User";
import { defineMessages } from "react-intl";

import { SidebarButtonProps } from "./SidebarButton";

export type Permission =
  | "company.partners_access"
  | "company.manage_admins"
  | "company.restrict_partners_crm_sync"
  | "company.manage_partnerships"
  | "company.manage_partner_stack"
  | "company.manage_sources"
  | "company.all_accounts_access"
  | "company.manage_roles"
  | "company.manage_api_keys"
  | "company.manage_slack"
  | "company.owns_pipeline"
  | "company.update";

export type Section = SidebarButtonProps & {
  shouldDisplay: (profile: User, payingFeatures: PayingFeature[]) => boolean;
};

const i18n = defineMessages({
  accountSettings: {
    id: "Sidebar.accountSettings",
    defaultMessage: "Account Settings",
  },
  analytics: {
    id: "Sidebar.analytics",
    defaultMessage: "Analytics",
  },
  attribute: {
    id: "Sidebar.attribute",
    defaultMessage: "Attribute",
  },
  directory: {
    id: "Sidebar.directory",
    defaultMessage: "Directory",
  },
  ecosystem: {
    id: "Sidebar.ecosystem",
    defaultMessage: "ECOSYSTEM",
  },
  getStarted: {
    id: "Sidebar.getStarted",
    defaultMessage: "Get Started",
  },
  mapping360: {
    id: "Sidebar.mapping360",
    defaultMessage: "360° Goals",
  },
  collaborate: {
    id: "Sidebar.collaborate",
    defaultMessage: "Collaborate",
  },
  partners: {
    id: "Sidebar.partners",
    defaultMessage: "Partners",
  },
  revenue: {
    id: "Sidebar.revenue",
    defaultMessage: "REVENUE",
  },
  settings: {
    id: "Sidebar.settings",
    defaultMessage: "SETTINGS",
  },
  sources: {
    id: "Sidebar.source",
    defaultMessage: "Data Sources",
  },
  measure: {
    id: "Sidebar.measure",
    defaultMessage: "Measure",
  },
  integrations: {
    id: "Sidebar.integrations",
    defaultMessage: "Integrations",
  },
  inviteTeammates: {
    id: "Sidebar.inviteTeammates",
    defaultMessage: "Invite Teammates",
  },
  pendingPartners: {
    id: "Sidebar.pendingPartners",
    defaultMessage: "Pending Invites",
  },
});

const anyPayingFeature = (payingFeature: PayingFeature) => (
  payingFeatures: PayingFeature[]
) => {
  return payingFeatures.some((feature) => payingFeature === feature);
};

const anyPermission = (permissions: Permission[]) => (profile: User) => {
  if (!profile) {
    return false;
  }
  return Boolean(
    permissions.find((perm: Permission) =>
      profile.hasPermissions([perm], profile.company)
    )
  );
};

const shouldShowDirectory = (
  profile: User,
  payingFeatures: PayingFeature[]
) => {
  return (
    (anyPermission(["company.manage_partnerships"])(profile) ||
      anyPayingFeature(PayingFeature.SalesInvitations)(payingFeatures)) &&
    !!profile?.company?.inDirectory
  );
};

const sections: SidebarButtonProps[] = [
  {
    Icon: Home,
    label: i18n.partners,
    path: /^\/partnerships/,
    to: "/partnerships",
    subSections: [
      {
        Icon: AccessTime,
        label: i18n.pendingPartners,
        path: null,
        to: "/onboarding/partnership-activation#fromSidebar",
      },
    ],
    title: i18n.ecosystem,
  },
  {
    Icon: Scale,
    label: i18n.analytics,
    path: /^\/analytics/,
    to: "/analytics",
  },
  {
    Icon: Directory,
    label: i18n.directory,
    path: /^\/directory/,
    to: "/directory",
    shouldDisplay: (profile: User, payingFeatures: PayingFeature[]) =>
      shouldShowDirectory(profile, payingFeatures),
  },
  {
    Icon: Mapping360,
    label: i18n.mapping360,
    path: /^\/crm-accounts/,
    to: "/crm-accounts",
    title: i18n.revenue,
  },
  {
    Icon: Chat,
    label: i18n.collaborate,
    path: /^\/pipeline/,
    to: "/pipeline",
    hiddenMobile: true,
  },
  {
    Icon: AutoGraphRounded,
    label: i18n.measure,
    path: /^\/measure/,
    to: "/measure",
  },
  {
    Icon: Settings,
    label: i18n.accountSettings,
    path: /^\/settings/,
    title: i18n.settings,
    to: "/settings/team",
    shouldDisplay: (profile: User, _payingFeatures: PayingFeature[]) =>
      anyPermission([
        "company.manage_admins",
        "company.manage_roles",
        "company.update",
      ])(profile),
  },
  {
    Icon: Sources,
    label: i18n.sources,
    path: "/sources",
    to: "/sources",
    shouldDisplay: (profile: User, _payingFeatures: PayingFeature[]) =>
      anyPermission(["company.manage_sources"])(profile),
  },
  {
    Icon: Integrations,
    label: i18n.integrations,
    path: "/integrations",
    to: "/integrations",
  },
  {
    Icon: PlusCircle,
    label: i18n.inviteTeammates,
    path: null,
    to: "#invite-team",
    shouldDisplay: (profile: User, _payingFeatures: PayingFeature[]) =>
      profile.hasLicense,
  },
];

export const getStartedSection = {
  Icon: Rocket,
  label: i18n.getStarted,
  path: "/get-started",
  to: "/get-started",
};

export const pendingPartnersSection = {
  Icon: PendingPartners,
  label: i18n.pendingPartners,
  path: null,
  to: "/onboarding/partnership-activation#fromSidebar",
};

export default sections;
