import { useSelector } from "react-redux";
import { selectAdminUsers } from "redux/init/selectors";

import useUserProfile from "./useUserProfile";

export const useUserPromotedToAdmin = () => {
  // The logic is, if the company has at least one OTHER admin
  // and the user is not confirmed yet, but the user is admin
  // it means that this user is automatically promoted to admin role
  const { profile } = useUserProfile();
  const adminUsers = useSelector(selectAdminUsers);
  const hasAtLeastOneAdmin = adminUsers.length > 1;

  return profile.isCompanyAdmin() && !profile.confirmedAt && hasAtLeastOneAdmin;
};
