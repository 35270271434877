import { makeStyles } from "makeStyles";
import { ReactNode } from "react";

type Props = {
  children: ReactNode;
};

export const BaseContent = ({ children }: Props) => {
  const { classes } = useStyles();

  return <div className={classes.container}>{children}</div>;
};

const useStyles = makeStyles()((theme) => ({
  container: {
    margin: theme.spacing(0),
  },
}));

export const sharedStyles = makeStyles<{ isAllProvider?: boolean }>()(
  (theme, { isAllProvider }) => ({
    action: {
      position: "relative",
      display: "flex",
      flexDirection: "row-reverse",
      justifyContent: "space-between",
      marginTop: theme.spacing(4),
    },
    alertTitle: {
      display: "block",
      fontSize: 14,
      fontWeight: 600,
      lineHeight: "21px",
    },
    avatar: {
      borderColor: `${theme.palette.ivory} !important`,
      borderRadius: 8,
      borderWidth: "4px !important",
    },
    bigIcon: {
      height: 48,
      width: 48,
    },
    contentTitle: {
      fontWeight: 600,
      marginBottom: theme.spacing(0.5),
    },
    alphaText: {
      color: theme.palette.alpha500,
    },
    crmButton: {
      alignItems: "center",
      background: theme.palette.ivory,
      border: "1.5px solid transparent",
      borderRadius: 8,
      display: "flex",
      flexDirection: "column",
      gap: 16,
      height: 210,
      justifyContent: "center",
      position: "relative",
      width: 212,
      "& > h3": {
        fontWeight: 600,
      },
      "& > h4": {
        fontWeight: 600,
      },
      "&:hover": {
        border: "1.5px solid",
        borderColor: theme.palette.midnight,
        cursor: "pointer",
      },
    },
    crmButtonRow: {
      display: "flex",
      justifyContent: "center",
    },
    crmButtonSmall: {
      flex: `1 0 ${isAllProvider ? 18 : 21}%`,
    },
    crmButtonWide: {
      width: 440,
    },
    dataPreview: {
      background: theme.palette.ivory,
      borderRadius: 7,
      display: "flex",
      height: 64,
      justifyContent: "space-between",
      margin: "auto",
      marginBottom: 12,
      maxWidth: 400,
      padding: theme.spacing(2),
    },
    flexCenter: {
      alignItems: "center",
      flexDirection: "column",
      gap: theme.spacing(2),
      display: "flex",
      justifyContent: "center",
    },
    formPaper: {
      maxWidth: 720,
      padding: theme.spacing(3),
      borderRadius: 7,
      margin: "auto",
      background: theme.palette.ivory,
    },
    fullWidth: {
      maxWidth: "unset",
      width: "100%",
    },
    icon: {
      height: 44,
      width: 44,
    },
    iconContainer: {
      background: "white",
      borderRadius: 8,
    },
    linkInCrmButton: {
      bottom: theme.spacing(1),
      color: theme.palette.midnight,
      textDecoration: "underline",
      fontWeight: "bold",
      cursor: "pointer",
      position: "absolute",
      "&:hover": {
        color: theme.palette.primary.main,
      },
    },
    marginBottomLarge: {
      marginBottom: theme.spacing(4),
    },
    marginBottomMedium: {
      marginBottom: theme.spacing(3),
    },
    marginBottom: {
      marginBottom: theme.spacing(2),
    },
    textCenter: {
      textAlign: "center",
    },
    uploadZone: {
      display: "flex",
      flexDirection: "column",
      gap: theme.spacing(2),
      justifyContent: "center",
      maxWidth: 480,
      minHeight: 200,
      margin: "auto",
    },
  })
);
