import { useOktaAuth } from "@okta/okta-react";
import { AxiosError, AxiosResponse } from "axios";
import ErrorPage from "components/ui/ErrorPage";
import SplashScreen from "components/ui/SplashScreen";
import { goToNext } from "helpers/goToNext";
import useSegment from "hooks/useSegment";
import { KNOWN_ERROR_STATES_FROM_OKTA } from "models/types";
import { parse } from "query-string";
import { useCallback, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Redirect, useHistory, useLocation } from "react-router-dom";
import { initStore } from "redux/init/thunks";
import UserService from "services/UserService";

const OktaResolver = () => {
  const { authState, oktaAuth } = useOktaAuth();
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const { track } = useSegment();
  const [error, setError] = useState<AxiosError>();
  const { source_button: sourceButton } = parse(location.search);

  const next = goToNext(location);

  const resolveOktaToken = useCallback(
    async (
      accessToken: string,
      onSuccess: () => void,
      onError: (error: any) => void,
      sourceButton?: string | string[] | null
    ) => {
      const service = new UserService();
      try {
        await service.resolveOkta(accessToken, sourceButton);
        if (sourceButton === "google") {
          track("Signed in with Google");
        }
        onSuccess();
      } catch (error) {
        const errorData = ((error as AxiosError)?.response as AxiosResponse)
          ?.data?.error;
        if (authState?.isAuthenticated) {
          try {
            await oktaAuth.signOut({
              state:
                errorData in KNOWN_ERROR_STATES_FROM_OKTA
                  ? errorData
                  : undefined,
            });
          } catch (e) {}
        }
        if (!(errorData in KNOWN_ERROR_STATES_FROM_OKTA)) {
          onError(error);
        }
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [authState?.isAuthenticated, oktaAuth] // don't put track as a dependency here because it's causing this function to be called multiple times
  );

  const performTokenResolution = useCallback(
    (token) => {
      resolveOktaToken(
        token,
        async () => {
          // @ts-expect-error ts-migrate(2554) FIXME: Expected 0 arguments, but got 1.
          await initStore(true)(dispatch);
          history.push(next);
        },
        setError,
        sourceButton
      );
    },
    [dispatch, history, next, resolveOktaToken, sourceButton]
  );

  const accessToken = authState?.accessToken || {};
  useEffect(() => {
    if (authState?.isAuthenticated) {
      // @ts-expect-error ts-migrate(2339) FIXME: Property 'accessToken' does not exist on type '{}'.
      performTokenResolution(accessToken.accessToken);
    }
  }, [
    authState?.isAuthenticated,
    // @ts-expect-error ts-migrate(2339) FIXME: Property 'accessToken' does not exist on type '{}'.
    accessToken.accessToken,
    performTokenResolution,
  ]);

  if (
    (error?.response as AxiosResponse)?.data in KNOWN_ERROR_STATES_FROM_OKTA
  ) {
    return <SplashScreen />;
  }
  if (error) {
    return <ErrorPage title="An error occurred. Cannot log in." />;
  } else if (authState?.isAuthenticated) {
    return <SplashScreen />;
  } else {
    return <Redirect to="/login" />;
  }
};

export default OktaResolver;
