import { FilterWidget } from "components/ui/filters/filter/FilterWidget";
import { useCallback, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  selectFields,
  selectFilterWidgetFilters,
  selectNotFilterWidgetFilters,
} from "redux/analytics/selectors";
import { updateView } from "redux/analytics/thunks";
import {
  DataTableType,
  FilterType,
} from "screens/Frontoffice/screens/DataTables/shared/types";

type Props = {
  disabled?: boolean;
};

const AnalyticsFilterWidget = ({ disabled }: Props) => {
  const dispatch = useDispatch();
  const fields = useSelector(selectFields);
  const widgetFilters = useSelector(selectFilterWidgetFilters);
  const notWidgetFilters = useSelector(selectNotFilterWidgetFilters);
  const [openedWidget, setOpenedWidget] = useState<string | null>(null);

  const setFilters = useCallback(
    (filters: FilterType[]) => {
      dispatch(updateView({ filters: [...filters, ...notWidgetFilters] }));
    },
    [dispatch, notWidgetFilters]
  );

  return (
    <FilterWidget
      disabled={disabled}
      fields={fields}
      filters={widgetFilters}
      onFilterUpdate={setFilters}
      openedWidget={openedWidget}
      setOpenedWidget={setOpenedWidget}
      viewType={DataTableType.ANALYTICS}
    />
  );
};
export default AnalyticsFilterWidget;
