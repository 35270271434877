import _ from "lodash";
import { useState } from "react";
import { defineMessages } from "react-intl";
import {
  analyticsColumnConfig,
  applyAnalyticsColumnOrder,
  AvailableAnalytisColumns,
  defaultAnalyticsColumnConfig,
  metricsColumnsMapping,
} from "redux/analytics/defaults";
import { ColumnWidget } from "screens/Frontoffice/screens/DataTables/shared/components/ColumnWidget";
import { DataTableType } from "screens/Frontoffice/screens/DataTables/shared/types";

import useAnalyticsColumnWidgetAPILogic from "../hooks/useAnalyticsColumnWidgetAPILogic";

export const AnalyticsColumnWidget = () => {
  const [isLoading, setIsLoading] = useState(false);
  const {
    fields,
    columns,
    setColumns,
    summaryMetricsEnabled,
    toggleSummaryMetrics,
    revenueMetricsEnabled,
    toggleExpandRevenueMetrics,
    hasHighWinRatePartners,
    highlightHighWinRateEnabled,
    toggleHighWinRate,
    handleColumnDelete,
    handleColumnReorder,
  } = useAnalyticsColumnWidgetAPILogic();

  const highWinrateToggle = {
    i18nLabel: i18n.highWinRatePartners,
    toggleProps: {
      checked: highlightHighWinRateEnabled,
      handleChange: toggleHighWinRate,
    },
  };

  const isExpandMetricsDisabled =
    isLoading ||
    !columns.some((item) =>
      metricsColumnsMapping.some(
        ([primary, _secondary]) => item.key === primary
      )
    );

  const summaryMetricsToggle = {
    i18nLabel: i18n.summaryMetrics,
    toggleProps: {
      checked: summaryMetricsEnabled,
      handleChange: toggleSummaryMetrics,
    },
  };

  const revenueMetricsToggle = {
    i18nLabel: i18n.expandRevenueMetrics,
    toggleProps: {
      checked: revenueMetricsEnabled,
      disabled: isExpandMetricsDisabled,
      handleChange: toggleExpandRevenueMetrics,
    },
  };

  const customToggleList = _.compact([
    summaryMetricsToggle,
    revenueMetricsToggle,
    hasHighWinRatePartners ? highWinrateToggle : null,
  ]);
  const customSectionProps = {
    title: i18n.partnerAnalytics,
    toggleList: customToggleList,
  };

  return (
    <ColumnWidget
      viewType={DataTableType.ANALYTICS}
      columns={columns}
      orderColumns={applyAnalyticsColumnOrder}
      defaultColumnConfig={defaultAnalyticsColumnConfig}
      fields={fields ?? {}}
      setColumns={setColumns}
      availableFieldsDefaultConfig={analyticsColumnConfig}
      nonDeactivatableColumnsList={[
        AvailableAnalytisColumns.partnerCompanyName,
      ]}
      customSectionProps={customSectionProps}
      onColumnDelete={handleColumnDelete}
      onColumnReorder={handleColumnReorder}
      loadingCallback={(loading) => setIsLoading(loading)}
    />
  );
};

// I18N

const i18n = defineMessages({
  partnerAnalytics: {
    id: "AnalyticsColumnWidget.customSectionTitle",
    defaultMessage: "Partner Analytics",
  },
  summaryMetrics: {
    id: "AnalyticsColumnWidget.summaryMetrics",
    defaultMessage: "Display Summary metrics",
  },
  expandRevenueMetrics: {
    id: "AnalyticsColumnWidget.expandRevenueMetrics",
    defaultMessage: "Expand Revenue Metrics",
  },
  highWinRatePartners: {
    id: "AnalyticsColumnWidget.highWinRatePartners",
    defaultMessage: "Highlight High Win-rate Partners",
  },
});
