import {
  array,
  boolean,
  constant,
  guard,
  number,
  object,
  string,
} from "decoders";

import Record from "./Record";
import { JSONAPIResourceOrRecord } from "./types";

export type IQuestionForm = {
  questions: IQuestion[];
};

type IQuestion = {
  label: string;
};

const apiIntroductionRequestFormDecoder = object({
  type: constant("introduction_request_forms" as const),
  id: string,
  attributes: object({
    company_id: number,
    is_enabled: boolean,
    partnership_ids: array(number),
    form_data: object({
      questions: array(object({ label: string })),
    }),
  }),
});

const apiIntroductionRequestFormGuard = guard(
  apiIntroductionRequestFormDecoder
);

class IntroductionRequestForm extends Record<"introduction_request_forms"> {
  companyId: number;
  isEnabled: boolean;
  partnershipIds: number[];
  formData: IQuestionForm;

  constructor(resource: JSONAPIResourceOrRecord<"introduction_request_forms">) {
    super(resource);
    const { attributes } = apiIntroductionRequestFormGuard(resource);
    this.companyId = attributes.company_id;
    this.isEnabled = attributes.is_enabled;
    this.partnershipIds = attributes.partnership_ids;
    this.formData = attributes.form_data;
  }
}

export default IntroductionRequestForm;
