import { AxiosResponse } from "axios";
import _ from "lodash";
import { SwRecordType } from "models/CrmField";
import { useEffect, useState } from "react";
import JSONAPIService from "services/JSONAPIService";
import { JSONAPIListResponse } from "services/types";

const useDefaultDisplayIndex = (
  integrationId: number,
  recordType: SwRecordType,
  shouldOrder: boolean = true
) => {
  const [
    defaultDisplayIndexResponse,
    setDefaultDisplayIndexResponse,
  ] = useState<null | AxiosResponse<JSONAPIListResponse>>(null);
  const [loading, setLoading] = useState<boolean>(true);
  useEffect(() => {
    const fetchDefaultFields = async () => {
      setLoading(true);
      const service = new JSONAPIService("integrations");
      const response = (await service.fetchRelated(
        integrationId,
        "default-display-index/"
      )) as AxiosResponse<JSONAPIListResponse>;
      setDefaultDisplayIndexResponse(response);
    };
    fetchDefaultFields().then(() => setLoading(false));
  }, [integrationId]);

  const unordered = _.get(
    defaultDisplayIndexResponse?.data.data,
    recordType,
    {}
  );
  const fieldIds: number[] = Object.keys(unordered).map(Number);
  if (shouldOrder) {
    // unordered is a dict mapping field ids to display_index
    const sortedFieldIds: number[] = fieldIds.sort(function (a, b) {
      return unordered[a] - unordered[b];
    });
    return { fieldIds: sortedFieldIds, loading };
  }
  return { fieldIds, loading };
};

export default useDefaultDisplayIndex;
