import { Grid } from "@mui/material";
import { useStyles as useBaseLoginStyles } from "components/ui/BaseLoginPage";
import { T } from "components/ui/Typography";
import { makeStyles } from "makeStyles";
import { UserRole } from "models/User";
import {
  defineMessages,
  FormattedHTMLMessage,
  MessageDescriptor,
} from "react-intl";

type IContentByRole = {
  imgSource: string;
  jobPosition?: MessageDescriptor;
  name: string;
  quote?: MessageDescriptor;
};

export const LeftPanelContentByRole = (role: UserRole) => {
  const { classes } = useStyles();
  const { classes: baseLoginStyles } = useBaseLoginStyles({
    rightPanelCentered: true,
    titleVariant: "small",
  });
  let contentByRole: IContentByRole = { imgSource: "", name: "" };
  switch (role) {
    case UserRole.partnerships:
    case UserRole.other:
    case UserRole.operations:
      contentByRole = {
        imgSource: "/images/onboarding/JustinArc.png",
        quote: i18n.justinQuote,
        jobPosition: i18n.justinJobPosition,
        name: "Justin Borja",
      };
      break;
    case UserRole.growthOrMarketing:
      contentByRole = {
        imgSource: "/images/onboarding/RomainArc.png",
        quote: i18n.romainQuote,
        jobPosition: i18n.romainJobPosition,
        name: "Romain Fernandes Richard",
      };
      break;
    case UserRole.sales:
      contentByRole = {
        imgSource: "/images/onboarding/KalenArc.png",
        quote: i18n.kalenQuote,
        jobPosition: i18n.kalenJobPosition,
        name: "Kalen Kimm",
      };
      break;

    default:
      throw new Error(`Unknown role: ${role}`);
  }

  return (
    <>
      <Grid item xs={12}>
        <div className={classes.logoContainer}>
          <img src={contentByRole.imgSource} alt="" />
        </div>
      </Grid>
      <Grid item xs={12}>
        <T className={baseLoginStyles.title}>
          <FormattedHTMLMessage {...contentByRole.quote} />
        </T>
      </Grid>
      <Grid item xs={12}>
        <T className={classes.nameText}>{contentByRole.name}</T>
      </Grid>
      <Grid item xs={12}>
        <T className={classes.jobPositionText}>
          <FormattedHTMLMessage {...contentByRole.jobPosition} />
        </T>
      </Grid>
    </>
  );
};

const useStyles = makeStyles()((theme) => ({
  logoContainer: {
    textAlign: "center",
    padding: theme.spacing(3),
    "& > img": {
      maxWidth: "100%",
      maxHeight: 125,
    },
  },
  jobPositionText: {
    color: theme.palette.primary.main,
    textAlign: "center",
    fontWeight: 600,
    fontSize: 14,
    lineHeight: "21px",
  },
  nameText: {
    color: theme.palette.offWhite,
    textAlign: "center",
    fontSize: 14,
  },
  root: {
    width: 360,
    rowGap: theme.spacing(3),
    [theme.breakpoints.down("md")]: {
      width: "100%",
    },
  },
  fullWidth: {
    width: "100%",
  },
}));

const i18n = defineMessages({
  justinJobPosition: {
    id: "onboarding.justinJobPosition",
    defaultMessage: "Head of Partnerships at Rydoo",
  },
  justinQuote: {
    id: "onboarding.justinQuote",
    defaultMessage: "Reveal helped us increase our qualified pipeline by 3X",
  },
  romainJobPosition: {
    id: "onboarding.romainJobPosition",
    defaultMessage: "Global Outbound Growth Lead at PayFit",
  },
  romainQuote: {
    id: "onboarding.romainQuote",
    defaultMessage:
      "Ecosystem leads doubled our conversion rates.<br/>Thanks, Reveal",
  },
  kalenJobPosition: {
    id: "onboarding.kalenJobPosition",
    defaultMessage:
      "Director, Systems Integrator & Strategic Partnerships at UserTesting",
  },
  kalenQuote: {
    id: "onboarding.kalenQuote",
    defaultMessage: "Reveal helped increase our pipeline by 40%",
  },
});
