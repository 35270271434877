import { ClassNameMap, FormControl, FormHelperText } from "@mui/material";
import { TextInput } from "components/ui/TextInput";
import { FormikErrors, FormikTouched } from "formik";
import {
  defineMessages,
  FormattedMessage,
  MessageDescriptor,
  useIntl,
} from "react-intl";

import { IInviteForm } from "../types";

type Props = {
  classes: ClassNameMap<string>;
  errors: FormikErrors<IInviteForm>;
  handleChange: (event: any) => void;
  isSubmitting: boolean;
  touched: FormikTouched<IInviteForm>;
  values: IInviteForm;
};

export const InviteFormGenericFields = ({
  classes,
  errors,
  handleChange,
  isSubmitting,
  touched,
  values,
}: Props) => {
  const intl = useIntl();
  return (
    <>
      <FormControl
        fullWidth
        error={Boolean(errors.first_name && touched.first_name)}
      >
        <TextInput
          autoComplete="off"
          className={classes.input}
          name="first_name"
          onChange={handleChange}
          placeholder={intl.formatMessage(i18n.contactFirstName)}
          disabled={isSubmitting}
          inputProps={{ "data-testid": "first-name-field" }}
          error={Boolean(touched.first_name && errors.first_name)}
          value={values.first_name}
          variant="newDesign"
        />
      </FormControl>
      <FormControl
        fullWidth
        error={Boolean(errors.last_name && touched.last_name)}
      >
        <TextInput
          autoComplete="off"
          className={classes.input}
          name="last_name"
          onChange={handleChange}
          placeholder={intl.formatMessage(i18n.contactLastName)}
          disabled={isSubmitting}
          inputProps={{ "data-testid": "last-name-field" }}
          error={Boolean(touched.last_name && errors.last_name)}
          value={values.last_name}
          variant="newDesign"
        />
      </FormControl>
      <FormControl fullWidth error={Boolean(errors.email && touched.email)}>
        <TextInput
          autoComplete="off"
          className={classes.input}
          name="email"
          onChange={handleChange}
          placeholder={intl.formatMessage(i18n.contactEmail)}
          disabled={isSubmitting}
          inputProps={{ "data-testid": "email-field" }}
          error={Boolean(touched.email && errors.email)}
          value={values.email}
          variant="newDesign"
        />
        {errors.email && touched.email && errors.email !== "_do_not_display" && (
          <FormHelperText>
            <FormattedMessage
              {...(errors.email as MessageDescriptor)}
              values={{ companyName: values.company?.name ?? "" }}
            />
          </FormHelperText>
        )}
      </FormControl>
    </>
  );
};

const i18n = defineMessages({
  contactFirstName: {
    id: "partners.invite.contact_first_name",
    defaultMessage: "First name",
  },
  contactLastName: {
    id: "partners.invite.contact_last_name",
    defaultMessage: "Last name",
  },
  contactEmail: {
    id: "partners.invite.contact_email",
    defaultMessage: "Work e-mail",
  },
});
