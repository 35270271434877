import { Box } from "@mui/material";
import { DataGridPremium, GridColDef } from "@mui/x-data-grid-premium";
import withCompanyPermissions from "components/hoc/withCompanyPermissions";
import { WithUserProfile } from "components/hoc/withUserProfile";
import {
  ArrowDownAlpha,
  ArrowDownInverted,
  ArrowUpInverted,
  Info,
  Plus,
} from "components/icons";
import Button from "components/ui/Button";
import { QuickSearch } from "components/ui/QuickSearch";
import { T } from "components/ui/Typography";
import useAllRecords from "hooks/useAllRecords";
import useAvailableRoles from "hooks/useAvailableRoles";
import _ from "lodash";
import { makeStyles } from "makeStyles";
import User from "models/User";
import { ChangeEvent, useState } from "react";
import { defineMessages, FormattedMessage, useIntl } from "react-intl";
import { RouteComponentProps, useHistory, useLocation } from "react-router";
import AccountSettings from "screens/Frontoffice/screens/AccountSettings";

import { ActionCell } from "./ActionCell";
import { EnableCell } from "./EnableCell";
import { RoleCell } from "./RoleCell";
import { RolesDialog } from "./RolesDialog";
import { UserDialog } from "./UserDialog";
import { UserInfoCell } from "./UserInfoCell";

type Params = {
  userId: string;
};

type Props = WithUserProfile & RouteComponentProps<Params>;

const List = ({ profile, match }: Props) => {
  const history = useHistory();
  const intl = useIntl();
  const location = useLocation();
  const availableRoles = useAvailableRoles(true);
  const [search, setSearch] = useState<string>("");
  const [showRoles, setShowRoles] = useState<boolean>(false);

  const licenseCount = profile.company?.licensedUserCount ?? null;
  const licenseTotal = profile.company?.totalLicenseQuota ?? null;
  const remainingLicenses = licenseTotal - licenseCount;
  const hasLicenseCount = licenseCount !== null && licenseTotal !== null;
  const countWarning =
    hasLicenseCount && (remainingLicenses <= 3 || licenseCount > licenseTotal);

  const { classes } = useStyles({ countWarning });

  // Retrieving all users (even unlicensed ones)
  const { records, loading, refresh } = useAllRecords<User>(
    "users",
    {
      filters: {
        company: profile.company.id,
        search,
      },
      include: ["invited_by", "role_assignments", "company"],
    },
    undefined,
    [history.location.hash] // added this dependency to reload the list on invite dialog close
  );

  const _records = _.cloneDeep(records);
  // Adding users who requested a license at the top of the list
  _records.sort(function (x, y) {
    return x.licenseRequestedAt === null && y.licenseRequestedAt === null
      ? 0
      : x.licenseRequestedAt !== null
      ? -1
      : 1;
  });
  const currentUser = _records.find(
    (record) => record.id === +match.params.userId
  );

  const getRole = (roles: $TSFixMe) => {
    const currentRole = roles?.length ? roles[0].role : null;
    return (
      availableRoles.find(({ name }: { name: string }) => name === currentRole)
        ?.label || currentRole
    );
  };

  const columns: GridColDef[] = [
    {
      field: "isEnabled",
      headerName: "Enabled",
      renderCell: (params) => <EnableCell dataItem={params.row} />,
      flex: 0.25,
      disableColumnMenu: true,
      sortable: false,
    },
    {
      field: "fullName",
      headerClassName: "visible-sort-view",
      headerName: intl.formatMessage(i18n.name),
      renderCell: (params) => <UserInfoCell isName dataItem={params.row} />,
      flex: 1,
      disableColumnMenu: true,
    },
    {
      field: "email",
      headerClassName: "visible-sort-view",
      headerName: intl.formatMessage(i18n.email),
      renderCell: (params) => <UserInfoCell dataItem={params.row} />,
      flex: 1,
      disableColumnMenu: true,
    },
    {
      field: "roleAssignments",
      headerClassName: "visible-sort-view",
      headerName: intl.formatMessage(i18n.role),
      renderCell: (params) => <RoleCell dataItem={params.row} />,
      flex: 1,
      sortable: true,
      valueGetter: (params) => getRole(params.row.roleAssignments), // Needed to be able to sort
      disableColumnMenu: true,
    },
    {
      field: "action",
      headerName: intl.formatMessage(i18n.action),
      renderCell: (params) => (
        <ActionCell dataItem={params.row} refreshUsers={refresh} />
      ),
      flex: 1,
      headerAlign: "right",
      align: "right",
      disableColumnMenu: true,
      sortable: false,
    },
  ];

  return (
    <>
      <AccountSettings
        actions={
          <>
            <QuickSearch
              value={search}
              placeholder={intl.formatMessage(i18n.quickSearch)}
              onChange={(event: ChangeEvent<HTMLInputElement>) =>
                setSearch(event.target.value)
              }
              onClear={() => setSearch("")}
            />
            <Button
              label={i18n.inviteTeammate}
              LeftIcon={Plus}
              onClick={() =>
                history.push({ ...location, hash: "#invite-team" })
              }
            />
          </>
        }
        tab="team"
      >
        <Box className={classes.subContainer}>
          {hasLicenseCount && (
            <div className={classes.container}>
              <T labelSmall>
                <FormattedMessage {...i18n.remainingLicenses} />
              </T>
              <T labelSmall className={classes.leftText}>
                <FormattedMessage
                  {...i18n.count}
                  values={{ count: remainingLicenses, total: licenseTotal }}
                />
              </T>
            </div>
          )}
          <div
            onClick={() => setShowRoles(true)}
            className={classes.linkContainer}
          >
            <Info className={classes.icon} />
            <T labelSmall>
              <FormattedMessage {...i18n.readAboutPermissions} />
            </T>
          </div>
        </Box>
        <DataGridPremium
          columns={columns}
          rows={_records}
          loading={loading}
          autoHeight
          hideFooter
          className={classes.root}
          rowHeight={44}
          columnHeaderHeight={36}
          slots={{
            columnSortedAscendingIcon: ArrowUpInverted,
            columnUnsortedIcon: ArrowDownAlpha,
            columnSortedDescendingIcon: ArrowDownInverted,
          }}
          getRowClassName={(params) =>
            !!params.row.licenseRequestedAt ? "license-requested" : ""
          }
        />
      </AccountSettings>
      {currentUser && <UserDialog user={currentUser} />}
      {showRoles && <RolesDialog handleClose={() => setShowRoles(false)} />}
    </>
  );
};

export default withCompanyPermissions<Props>(["company.manage_roles"])(List);

/// CSS

export const useStyles = makeStyles<{
  countWarning: boolean;
}>()((theme, { countWarning }) => ({
  root: {
    border: "none",
    borderRadius: 8,
    "& .MuiDataGrid-columnHeaders": {
      borderRadius: "8px 8px 0 0",
    },
    "& .license-requested": {
      backgroundColor: "#F9F1EE",
    },
  },
  container: {
    display: "flex",
    alignItems: "center",
    columnGap: 3,
  },
  leftText: {
    color: countWarning ? theme.palette.red500 : theme.palette.midnight,
  },
  linkContainer: {
    display: "flex",
    alignItems: "center",
    columnGap: 6,
    marginLeft: "auto",
    marginRight: 8,
    "&:hover": {
      textDecoration: "underline",
      cursor: "pointer",
    },
  },
  icon: {
    width: 16,
    height: 16,
  },
  readAbout: {
    "&:hover": {
      textDecoration: "underline",
      cursor: "pointer",
    },
  },
  subContainer: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    margin: "0 4px 8px 4px",
  },
}));

/// I18N

const i18n = defineMessages({
  inviteTeammate: {
    id: "User.List.InviteTeamMate",
    defaultMessage: "Invite teammate",
  },
  name: {
    id: "Users.List.name",
    defaultMessage: "Name",
  },
  email: {
    id: "Users.List.email",
    defaultMessage: "Email",
  },
  role: {
    id: "Users.List.role",
    defaultMessage: "Role",
  },
  action: {
    id: "Users.List.action",
    defaultMessage: "Action",
  },
  quickSearch: {
    id: "Users.List.QuickSearch",
    defaultMessage: "Quick search",
  },
  readAboutPermissions: {
    id: "User.List.readAboutPermissions",
    defaultMessage: "User roles & permissions",
  },
  remainingLicenses: {
    id: "User.List.remainingLicenses",
    defaultMessage: "Remaining Licenses",
  },
  count: {
    id: "User.List.count",
    defaultMessage: "({count}/{total})",
  },
});
