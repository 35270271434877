import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";
import { getValidatedEnvironment } from "config/schema";
import User from "models/User";

export const start = () => {
  const env = getValidatedEnvironment();
  const sentry_dsn = env.sentry?.dsn;
  if (sentry_dsn) {
    Sentry.init({
      dsn: sentry_dsn,
      environment: env.environment,
      release: process.env.REACT_APP_RELEASE_TAG,
      integrations: [new Integrations.BrowserTracing()],
      sendDefaultPii: false,
      ignoreErrors: [
        /request failed with status code 401/i,
        /loading chunk \d+ failed/i,
      ],
      denyUrls: [/^chrome-extension:\/\//i],
    });
  }
};

export const identify = (user: User) => {
  Sentry.setUser({ id: String(user.id) });
};

export const reportError = (error: Error) => {
  Sentry.captureException(error);
};

const moduleDefaultExports = { start, identify, reportError };

export default moduleDefaultExports;
