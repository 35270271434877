import Avatar from "@mui/material/Avatar";
import AvatarGroup from "@mui/material/AvatarGroup";
import { T } from "components/ui/Typography";
import { makeStyles } from "makeStyles";
import { defineMessages, FormattedMessage } from "react-intl";
import { PartnershipFilterData } from "redux/pipeline/typing";

type Props = {
  availablePartners: PartnershipFilterData[];
  filteredPartnerIds: number[] | null;
};

const PartnerFilterLabel = ({
  availablePartners,
  filteredPartnerIds,
}: Props) => {
  const { classes } = useStyles();
  const selectedPartnerships = availablePartners.filter(
    (value) =>
      filteredPartnerIds === null || filteredPartnerIds.includes(value.id)
  );
  return (
    <div className={classes.root}>
      {(() => {
        if (filteredPartnerIds === null) {
          return (
            <T bold className={classes.alpha}>
              <FormattedMessage {...i18n.allPartners} />
            </T>
          );
        }

        if (filteredPartnerIds.length === 0) {
          return (
            <T bold className={classes.alpha}>
              <FormattedMessage {...i18n.noPartner} />
            </T>
          );
        }

        return (
          <>
            <AvatarGroup
              max={3}
              spacing={4}
              classes={{ root: classes.avatarGroup, avatar: classes.avatar }}
            >
              {selectedPartnerships.map((partner) => (
                <Avatar
                  key={partner.id}
                  alt={partner.name}
                  title={partner.name}
                  src={partner.logo || undefined}
                >
                  {partner.name?.charAt(0).toUpperCase()}
                </Avatar>
              ))}
            </AvatarGroup>
            {selectedPartnerships.length > 0 &&
              filteredPartnerIds.length === 1 && (
                <T bold className={classes.alpha}>
                  {selectedPartnerships[0].name}
                </T>
              )}
          </>
        );
      })()}
    </div>
  );
};

export default PartnerFilterLabel;

// CSS

const useStyles = makeStyles()((theme) => ({
  alpha: {
    color: theme.palette.alpha800,
  },
  root: {
    display: "flex",
    alignItems: "center",
    "& .MuiAvatar-colorDefault": {
      marginLeft: -2,
      width: 20,
    },
  },
  avatarGroup: {},
  avatar: {
    fontSize: 10,
    borderRadius: 8,
    "& > img": {
      background: theme.palette.white,
    },
    backgroundImage: theme.palette.gradients.darkPurpleToPurple,
    height: 16,
    width: 16,
  },
}));

// I18N

const i18n = defineMessages({
  allPartners: {
    id: "PartnerFilterLabel.allPartners",
    defaultMessage: "Partners",
  },
  noPartner: {
    id: "PartnerFilterLabel.noPartner",
    defaultMessage: "No partner",
  },
});
