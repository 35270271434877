import { createSvgIcon } from "@mui/material/utils";

import { SvgIcon } from "./types";

const displayName = "PlusMessage";

const PlusMessage: SvgIcon = createSvgIcon(
  <g fill="none" transform="matrix(1.5,0,0,1.5,2,2)">
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M0.5 2C0.5 0.895429 1.39543 0 2.5 0H11.5C12.6046 0 13.5 0.895431 13.5 2V9.46154C13.5 10.5661 12.6046 11.4615 11.5 11.4615H8.352C8.13774 11.4615 7.92915 11.5304 7.75695 11.6578L4.72609 13.9018C4.57433 14.0142 4.37222 14.0315 4.20358 13.9465C4.03495 13.8616 3.92857 13.6888 3.92857 13.5V11.4615H2.5C1.39543 11.4615 0.5 10.5661 0.5 9.46154V2ZM2.5 1C1.94771 1 1.5 1.44771 1.5 2V9.46154C1.5 10.0138 1.94771 10.4615 2.5 10.4615H4.42857C4.70471 10.4615 4.92857 10.6854 4.92857 10.9615V12.5077L7.16191 10.8542C7.5063 10.5992 7.92349 10.4615 8.352 10.4615H11.5C12.0523 10.4615 12.5 10.0138 12.5 9.46154V2C12.5 1.44772 12.0523 1 11.5 1H2.5ZM7 2.75C7.27614 2.75 7.5 2.97386 7.5 3.25V5.25H9.5C9.77614 5.25 10 5.47386 10 5.75C10 6.02614 9.77614 6.25 9.5 6.25H7.5V8.25C7.5 8.52614 7.27614 8.75 7 8.75C6.72386 8.75 6.5 8.52614 6.5 8.25V6.25H4.5C4.22386 6.25 4 6.02614 4 5.75C4 5.47386 4.22386 5.25 4.5 5.25H6.5V3.25C6.5 2.97386 6.72386 2.75 7 2.75Z"
      fill="currentColor"
    />
  </g>,
  displayName
);

PlusMessage.displayName = displayName;
export default PlusMessage;
