import { GridRowId } from "@mui/x-data-grid-premium";
import { createContext, ReactNode, useState } from "react";

const SelectedRowsContext = createContext<{
  selectedRowIds: GridRowId[];
  setSelectedRowIds: (value: GridRowId[]) => void;
  clearSelection: () => void;
}>({
  selectedRowIds: [],
  setSelectedRowIds: (value: GridRowId[]) => {},
  clearSelection: () => {},
});

export const SelectedRowsProvider = ({ children }: { children: ReactNode }) => {
  const [selectedRowIds, setSelectedRowIds] = useState<GridRowId[]>([]);
  const clearSelection = () => setSelectedRowIds([]);

  return (
    <SelectedRowsContext.Provider
      value={{ selectedRowIds, setSelectedRowIds, clearSelection }}
    >
      {children}
    </SelectedRowsContext.Provider>
  );
};

export default SelectedRowsContext;
