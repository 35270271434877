import { createSvgIcon } from "@mui/material/utils";

import { SvgIcon } from "./types";

const displayName = "CheckSmall";

const CheckSmall: SvgIcon = createSvgIcon(
  // Using transform to enlarge from provided viewbox="0 0 16 16"
  // to default "0 0 24 24" from `createSvgIcon` -> `SvgIcon`.
  <g fill="none" transform="matrix(1.5,0,0,1.5,0,0)">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M13.3536 4.64645C13.5488 4.84171 13.5488 5.15829 13.3536 5.35355L7.7608 10.9463C7.06474 11.6424 5.93174 11.6276 5.25407 10.9136L2.63735 8.15671C2.44724 7.95643 2.4555 7.63995 2.65579 7.44985C2.85608 7.25974 3.17255 7.268 3.36265 7.46829L5.97938 10.2252C6.26981 10.5312 6.75538 10.5375 7.05369 10.2392L12.6464 4.64645C12.8417 4.45118 13.1583 4.45118 13.3536 4.64645Z"
      fill="currentColor"
    />
  </g>,
  displayName
);

CheckSmall.displayName = displayName;
export default CheckSmall;
