import { Factory } from "models";
import { defineMessages } from "react-intl";

import Record from "./Record";
import { SuggestedPartner_JSONAPIResource } from "./types";

export enum SuggestedPartnerSource {
  Manual = "manual",
  ManualMirror = "manual_mirror",
  Crm = "crm",
  CrmMirror = "crm_mirror",
  HighPotentialOverlap = "high_potential_overlap",
  PartnerList = "partner_list",
  PartnerListMirror = "partner_list_mirror",
}

const i18n = defineMessages({
  crm: {
    id: "models.SuggestedPartner.crm",
    defaultMessage: "CRM",
  },
  crmMirror: {
    id: "models.SuggestedPartner.crmMirror",
    defaultMessage: "CRM Mirror",
  },
  highPotentialOverlap: {
    id: "models.SuggestedPartner.highPotentialOverlap",
    defaultMessage: "High Overlap",
  },
  manual: {
    id: "models.SuggestedPartner.manual",
    defaultMessage: "Manual",
  },
  manualMirror: {
    id: "models.SuggestedPartner.manualMirror",
    defaultMessage: "Manual Mirror",
  },
  partnerList: {
    id: "models.SuggestedPartner.partnerList",
    defaultMessage: "Partner List",
  },
  partnerListMirror: {
    id: "models.SuggestedPartner.partnerListMirror",
    defaultMessage: "Partner List Mirror",
  },
});

export const SuggestedPartnerSourceToDisplayLabel = {
  [SuggestedPartnerSource.Crm]: i18n.crm,
  [SuggestedPartnerSource.CrmMirror]: i18n.crmMirror,
  [SuggestedPartnerSource.HighPotentialOverlap]: i18n.highPotentialOverlap,
  [SuggestedPartnerSource.Manual]: i18n.manual,
  [SuggestedPartnerSource.ManualMirror]: i18n.manualMirror,
  [SuggestedPartnerSource.PartnerList]: i18n.partnerList,
  [SuggestedPartnerSource.PartnerListMirror]: i18n.partnerListMirror,
};

export class SuggestedPartner extends Record<"suggested_partners"> {
  company: Record;
  domain: string;
  partner: Record | null;
  partnerLogo: string | null;
  partnerName: string;
  fromHighPotentialOverlap: boolean;
  source: SuggestedPartnerSource;

  constructor(data: SuggestedPartner_JSONAPIResource) {
    super(data);
    this.domain = (this.attributes?.partner_domain_name ?? "") as string;
    this.partnerName = this.attributes.partner_name as string;
    this.source = this.attributes.source as SuggestedPartnerSource;
    this.fromHighPotentialOverlap = (this.attributes
      .from_high_potential_overlap || false) as boolean;
    if (typeof this.attributes.partner_logo === "string") {
      this.partnerLogo = this.attributes.partner_logo;
    } else {
      this.partnerLogo = null;
    }
    if (this.relationships.company.data === null) {
      throw Error("company is required for SuggestedPartner model");
    }
    if (this.relationships.company.data instanceof Array) {
      throw Error(
        "company must be a single resource for SuggestedPartner model"
      );
    }
    this.company = Factory.createRecord(this.relationships.company.data);
    if (
      this.relationships.partner &&
      this.relationships.partner.data !== null
    ) {
      if (this.relationships.partner.data instanceof Array) {
        throw Error(
          "partner must be a single resource for SuggestedPartner model"
        );
      }
      this.partner = Factory.createRecord(this.relationships.partner.data);
    } else {
      this.partner = null;
    }
  }
}

export function isSuggestedPartner(record: Record): record is SuggestedPartner {
  return record instanceof SuggestedPartner;
}

export default SuggestedPartner;
