import PageView from "models/PageView";
import { RevealStore } from "redux/typing";

export const selectMapping360View = (state: RevealStore) => state.mapping360;

export const selectViews = (state: RevealStore) => {
  const pageViews = (state.api.entities["page_views"] ?? {}) as Record<
    string,
    PageView
  >;
  return state.mapping360.viewIds.map((id) => pageViews[id]).filter(Boolean);
};

export const selectView = (state: RevealStore) => {
  const pageViews = (state.api.entities["page_views"] ?? {}) as Record<
    string,
    PageView
  >;
  return !!state.mapping360.viewId
    ? pageViews[state.mapping360.viewId]
    : undefined;
};
