import { createSvgIcon } from "@mui/material/utils";

import { SvgIcon } from "./types";

const displayName = "CircleWarning";

const CircleWarning: SvgIcon = createSvgIcon(
  // Using transform to enlarge from provided viewbox="0 0 16 16"
  // to default "0 0 24 24" from `createSvgIcon` -> `SvgIcon`.
  <g fill="none" transform="matrix(1.5,0,0,1.5,0,0)">
    <circle cx="8" cy="8" r="8" fill="currentColor" />
    <path d="M9 9H7V4H9V9Z" fill="#ffffff" />
    <path
      d="M8 10C8.55228 10 9 10.4477 9 11C9 11.5523 8.55228 12 8 12C7.44772 12 7 11.5523 7 11C7 10.4477 7.44772 10 8 10Z"
      fill="#ffffff"
    />
  </g>,
  displayName
);

CircleWarning.displayName = displayName;
export default CircleWarning;
