import { getValidatedEnvironment } from "config/schema";

const intercomAppId = getValidatedEnvironment()?.intercomAppId;

export const handleSetManualLink = () => {
  (window as any).intercomSettings = {
    app_id: intercomAppId,
    custom_launcher_selector: "#custom-intercom-link",
  };
};
