import { createSelector } from "@reduxjs/toolkit";
import _ from "lodash";
import CompanyPayingFeatureSubscription from "models/CompanyPayingFeatureSubscription";
import User from "models/User";
import { RevealStore } from "redux/typing";

import { RootStateWithContext } from "./typing";

export const selectFoldedSidebar = (state: RootStateWithContext) =>
  state.context.foldedSidebar;

export const selectCompanyPayingFeatureSubscriptions = createSelector(
  (state: RevealStore) =>
    state.api.entities["company_paying_feature_subscriptions"] ?? {},
  (state: RevealStore) => state.context,
  (
    companyPayingFeatureSubscriptions,
    { companyPayingFeatureSubscriptionIds }
  ) => {
    return {
      companyPayingFeatureSubscriptions: companyPayingFeatureSubscriptionIds
        .map((id: number) => companyPayingFeatureSubscriptions[id])
        .filter(Boolean) as CompanyPayingFeatureSubscription[],
    };
  }
);

const selectAllPartnerships = (state: RevealStore) => {
  const partnerships = state.api.entities["partnerships"] ?? {};
  return state.context.partnershipIds
    .map((partnershipId) => partnerships[partnershipId])
    .filter(Boolean);
};

export const selectAllPartnershipOwners = createSelector(
  selectAllPartnerships,
  (partnerships) => {
    return _.uniqBy(
      partnerships.filter((partnership) => partnership.partnershipOwner),
      "partnershipOwner"
    ).map((partnership) => partnership.partnershipOwner);
  }
);

export const selectPartnershipsReady = (state: RevealStore) =>
  state.context.ready;

export const selectAcceptedAndNotPausedPartnerships = createSelector(
  selectAllPartnerships,
  (partnerships) => {
    return partnerships.filter((partnership) => partnership.isAccepted());
  }
);

export const selectActivePartnerships = createSelector(
  selectAcceptedAndNotPausedPartnerships,
  (partnerships) => {
    return partnerships.filter((partnership) =>
      partnership.isAccountMappingEnabled()
    );
  }
);

export const selectGhostPartnerships = createSelector(
  selectAllPartnerships,
  (partnerships) => {
    return partnerships.filter((partnership) => partnership.isGhost());
  }
);

export const selectActiveAndGhostPartnerships = createSelector(
  selectActivePartnerships,
  selectGhostPartnerships,
  (actives, ghosts) => {
    return [...actives, ...ghosts];
  }
);

export const selectAllPartnershipTags = (state: RevealStore) => {
  const partnershipTags = state.api.entities["partnership_tags"] ?? {};
  return state.context.partnershipTagIds
    .map((tagId) => partnershipTags[tagId])
    .filter(Boolean);
};

export const selectPartnershipGetIntroIds = (state: RevealStore) => {
  return state.context.partnershipGetIntroIds;
};

export const selectFirst3PartnershipIds = createSelector(
  (state: RevealStore) => state.dashboard,
  ({ upsidePartnershipIds }) => {
    return upsidePartnershipIds.slice(0, 3);
  }
);

export const selectUsersWithLicense = (state: RevealStore) => {
  const users = state.api.entities["users"] ?? {};
  return state.context.userIds
    .map((userId) => users[userId] as User)
    .filter(Boolean);
};

export const selectAdminUsers = (state: RevealStore) => {
  const users = state.api.entities["users"] ?? {};
  return state.context.adminUserIds
    .map((userId) => users[userId] as User)
    .filter(Boolean);
};
