const wrapperStyles = (theme: $TSFixMe) => ({
  wrapper: {
    backgroundColor: theme.palette.grey.light,
    borderRadius: theme.spacing(1),
    margin: theme.spacing(1),
    flexGrow: 0,
    [theme.breakpoints.up("lg")]: {
      maxWidth: "30%",
      flexBasis: "30%",
      padding: theme.spacing(7),
    },
    [theme.breakpoints.down("lg")]: {
      maxWidth: "40%",
      flexBasis: "40%",
      padding: theme.spacing(5),
    },
    [theme.breakpoints.down("md")]: {
      maxWidth: "60%",
      flexBasis: "60%",
      padding: theme.spacing(4),
    },
    [theme.breakpoints.down("sm")]: {
      maxWidth: "90%",
      flexBasis: "90%",
      padding: theme.spacing(3),
    },
  },
});

export default wrapperStyles;
