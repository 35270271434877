import { createSvgIcon } from "@mui/material/utils";

import { SvgIcon } from "./types";

const displayName = "Close";

const Close: SvgIcon = createSvgIcon(
  // Using transform to enlarge from provided viewbox="0 0 14 14"
  // to default "0 0 24 24" from `createSvgIcon` -> `SvgIcon`.
  <g fill="none" transform="matrix(1.7143,0,0,1.7143,0,0)">
    <path
      d="M1.28034 0.21967C0.987446 -0.0732232 0.512572 -0.0732233 0.219679 0.21967C-0.0732145 0.512563 -0.0732146 0.987437 0.219679 1.28033L5.68935 6.75L0.21967 12.2197C-0.0732233 12.5126 -0.0732233 12.9874 0.21967 13.2803C0.512563 13.5732 0.987437 13.5732 1.28033 13.2803L6.75001 7.81066L12.2197 13.2803C12.5126 13.5732 12.9875 13.5732 13.2803 13.2803C13.5732 12.9874 13.5732 12.5126 13.2803 12.2197L7.81067 6.75L13.2803 1.28034C13.5732 0.987445 13.5732 0.512571 13.2803 0.219678C12.9874 -0.0732154 12.5126 -0.0732151 12.2197 0.219678L6.75001 5.68934L1.28034 0.21967Z"
      fill="currentColor"
    />
  </g>,
  displayName
);

Close.displayName = displayName;
export default Close;
