import forms from "i18n/forms";
import { CompanyEcosystem, CompanySize, CompanyType } from "models/Company";
import { PartnershipSource } from "models/Partnership";
import { object, string } from "yup";

import { isBlacklisted } from "../../helpers/emailValidation";

export type INewContact = { isNewContact: true };

export type IInviteForm = {
  company: {
    id?: number | null;
    name: string;
    domain: string | null;
    logo: string | null;
  };
  email: string;
  first_name: string;
  last_name: string;
  message: string | null;
};

export const initialValues = {
  company: {
    name: "",
    domain: null,
    logo: null,
  },
  first_name: "",
  last_name: "",
  email: "",
  message: "",
};

export enum DialogView {
  SuggestedPartnerAllAdmins = "SUGGESTED_PARTNER_ALL_ADMINS",
  SuggestedPartnerDirect = "SUGGESTED_PARTNER_DIRECT",
  GenericFirstInvite = "GENERIC_FIRST_INVITE",
  PartnershipExtraInviteDirect = "PARTNERSHIP_EXTRA_INVITE_DIRECT",
  PartnershipExtraInviteAllAdmins = "PARTNERSHIP_EXTRA_INVITE_ALL_ADMINS",
}

export enum FormStep {
  CompanyFromCrm = "company-from-crm",
  CompanyNoCrm = "company-no-crm",
  Contact = "contact",
}

export type IContact = {
  first_name?: string;
  last_name?: string;
  email: string;
  title?: string;
};

export type SelectedCompany = {
  accountOverlap?: string;
  contactOverlap?: string;
  description?: string;
  id: number;
  name: string;
  domain: string;
  logo: string;
  websiteUrl?: string;
  companyType?: CompanyType;
  ecosystem?: CompanyEcosystem;
  size?: CompanySize;
};

export enum SentFromURL {
  SuggestedPartner = "suggested-partner",
  SuggestedPartnerFromDirectory = "suggested-partner-from-directory",
  Directory = "company-from-directory",
  InvitePage = "invite-page",
}

export const FROM_URL_TO_PARTNERSHIP_SOURCE: {
  [key in SentFromURL]: PartnershipSource;
} = {
  [SentFromURL.SuggestedPartner]: PartnershipSource.SuggestedPartner,
  [SentFromURL.SuggestedPartnerFromDirectory]:
    PartnershipSource.SuggestedPartner,
  [SentFromURL.Directory]: PartnershipSource.Directory,
  [SentFromURL.InvitePage]: PartnershipSource.InviteForm,
};

const baseSchema = (currentProfileEmail: string) => ({
  email: string()
    // trick to only display error message for invalid email, otherwise the red background is enough
    .required(() => "_do_not_display")
    .trim()
    .email(() => forms.email_invalid)
    .test({
      name: "self-email",
      message: () => forms.email_self,
      test: (value) =>
        value?.toLowerCase().trim() !==
        currentProfileEmail?.toLowerCase().trim(),
    })
    .test({
      name: "personal-email",
      message: () => forms.email_blacklisted_invite,
      test: isBlacklisted,
    }),
  first_name: string().required(),
  last_name: string().required(),
  message: string().nullable(),
});

export const partnershipSchema = (
  currentProfileEmail: string,
  currentCompanyName: string
) =>
  object().shape({
    company: object()
      .shape({
        name: string()
          .test({
            name: "self-company",
            message: () => forms.company_self,
            test: (value) => value !== currentCompanyName,
          })
          .test({
            name: "required",
            message: () => forms.contact_company_name_required,
            test: (value) => !!value,
          }),
      })
      .required(),
    ...baseSchema(currentProfileEmail),
  });

export const inviteAdminsSchema = object().shape({
  message: string().required(),
});

export const inviteSchema = (currentProfileEmail: string) =>
  object().shape(baseSchema(currentProfileEmail));
