import history from "_history";
import { isFulfilled, isRejected } from "@reduxjs/toolkit";
import { AxiosResponse } from "axios";
import CompanyAvatar from "components/ui/avatars/CompanyAvatar";
import Popup from "components/ui/Popup";
import { T } from "components/ui/Typography";
import { reCaptchaSiteKey } from "config/constants";
import usePushNotification from "hooks/usePushNotification";
import useSegment from "hooks/useSegment";
import useUserProfile from "hooks/useUserProfile";
import generic from "i18n/generic";
import _ from "lodash";
import { makeStyles } from "makeStyles";
import Partnership, {
  PartnershipSource,
  PartnershipStatus,
} from "models/Partnership";
import { JSONAPIResource } from "models/types";
import { stringify } from "query-string";
import { useEffect, useRef, useState } from "react";
import ReCAPTCHA from "react-google-recaptcha";
import {
  defineMessages,
  FormattedHTMLMessage,
  FormattedMessage,
} from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { selectActivePayingFeatures } from "redux/api/selectors";
import { create, rawPost } from "redux/api/thunks";
import { addCreatedPartner } from "redux/dashboard/actions";
import { fields, included } from "redux/init/constants";
import JSONAPIService from "services/JSONAPIService";
import { JSONAPIResponse } from "services/types";
import { InviteURLEvent } from "tracking";

type Props = {
  invitationId: string;
};

type CompanyFromInvitation = {
  name: string;
  domain?: string;
  logo?: string;
  invitationUrlId?: number;
};

const InvitationURLModal = ({ invitationId }: Props) => {
  const { classes } = useStyles();
  const { profile } = useUserProfile();
  const { track } = useSegment();
  const dispatch = useDispatch();
  const pushNotification = usePushNotification();
  const payingFeatures = useSelector(selectActivePayingFeatures);
  const recaptchaRef = useRef<ReCAPTCHA>(null);
  const [loadingSave, setLoadingSave] = useState<boolean>(false);
  const [isOpen, setIsOpen] = useState<boolean>(true);
  // State used if there is a pending partnership and user is from the dest company
  // He will be able to accept the partnership invite from the modal
  const [acceptPartnershipId, setAcceptPartnershipId] = useState<number | null>(
    null
  );
  // State used if there a pending partnership and user is from the init company
  // When we don't find any invite for the invitationUrl, he will be able to send a new one from the modal
  const [
    otherInvitationPartnershipId,
    setOtherInvitationPartnershipId,
  ] = useState<number | null>(null);

  const [
    companyFromInvitation,
    setCompanyFromInvitation,
  ] = useState<CompanyFromInvitation | null>(null);

  const handleClose = () => {
    setIsOpen(false);
    setLoadingSave(false);
    // Updating the url without triggering reload
    window.history.replaceState("", "", "/partnerships");
  };

  const retreiveCompanyFromInvite = async () => {
    if (recaptchaRef.current !== null) {
      const recaptcha_token = await recaptchaRef.current.executeAsync();
      if (recaptcha_token) {
        const service = new JSONAPIService("invitation_urls");
        const invitationResponse: AxiosResponse = await service.rawGet(
          "/get-invitation-info?" +
            stringify({ invitation_id: invitationId, recaptcha_token })
        );
        const invitationInfo = invitationResponse.data.data;
        if (invitationInfo.company_id === profile.company.id) {
          // Cannot send invite to your own company
          pushNotification(i18n.sameCompanyInvitation, {
            company: profile.company.name,
          });
          handleClose();
        } else {
          let skipSetCompany = false;
          const service = new JSONAPIService("partnerships");
          const partnershipResponse: AxiosResponse = await service.rawGet("", {
            filters: { company: invitationInfo.company_id },
            fields,
            include: included,
          });
          const rawPartnership = partnershipResponse.data.data[0] ?? null;
          const invitationUrlIdsFromInvites = partnershipResponse.data.included
            ? partnershipResponse.data.included
                .filter(
                  (record: JSONAPIResource) =>
                    record.type === "partnership_invites"
                )
                .map(
                  (record: JSONAPIResource) =>
                    record.attributes?.invitation_url_id
                ) ?? []
            : [];

          if (rawPartnership !== null) {
            const partnership = new Partnership(rawPartnership);
            if (partnership.status === PartnershipStatus.Accepted) {
              skipSetCompany = true;
              pushNotification(i18n.alreadyConnected, {
                partner: invitationInfo.company_name,
              });
              // If there is an accepted partnership, and the overview is available, we open it
              if (partnership.isOverviewAndOverlapsAvailable()) {
                history.push({
                  pathname: `/partnerships/${partnership.id}/overview`,
                });
              } else {
                handleClose();
              }
            } else if (partnership.status === PartnershipStatus.Pending) {
              const isInitiator = partnership.isInitiator(profile.company);
              // if the user is the initiator of the pending partnership,
              if (isInitiator) {
                const hasExistingInvitation = invitationUrlIdsFromInvites.includes(
                  invitationInfo.id
                );
                if (hasExistingInvitation) {
                  // and an invitation has already been sent via the link url, he can't do anything
                  pushNotification(i18n.invitationAlreadySent, {
                    partner: invitationInfo.company_name,
                  });
                  skipSetCompany = true;
                  handleClose();
                } else {
                  // But if there is no invitation for this specific link,
                  // we allow the user to create a new invitation with the invitation_url_id
                  setOtherInvitationPartnershipId(partnership.id);
                }
              } else {
                // if the user is the dest of the pending partnership, he can accept the invite
                setAcceptPartnershipId(partnership.id);
              }
            }
          }
          if (!skipSetCompany) {
            setCompanyFromInvitation({
              name: invitationInfo.company_name,
              domain: invitationInfo.domain_name,
              logo: invitationInfo.company_logo,
              invitationUrlId: invitationInfo.id,
            });
          }
        }
        recaptchaRef.current?.reset();
      }
    }
  };

  const sendInvite = async () => {
    setLoadingSave(true);
    if (acceptPartnershipId) {
      if (
        isFulfilled(
          await dispatch(
            rawPost({
              type: "partnerships",
              id: acceptPartnershipId,
              path: "/accept-invite/",
              payload: undefined,
              options: {
                fields,
                include: included,
              },
            })
          )
        )
      ) {
        track(InviteURLEvent.partnershipAccepted, { invitationId });
      } else {
        pushNotification("default_error");
      }
    } else if (companyFromInvitation !== null) {
      if (otherInvitationPartnershipId) {
        // We create a new invitation with the invitation_url_id for the existing partnership
        const response = await dispatch(
          create({
            type: "partnership_invites",
            attributes: {
              invitation_url_id: companyFromInvitation.invitationUrlId,
            },
            relationships: {
              partnership: {
                type: "partnerships",
                id: String(otherInvitationPartnershipId),
              },
            },
          })
        );
        if (isRejected(response)) {
          pushNotification(i18n.invitationAlreadySent, {
            partner: companyFromInvitation.name,
          });
        } else {
          pushNotification({ ...i18n.invitationSent });
          track(InviteURLEvent.invitationSent, { invitationId });
          profile.identify({
            activePartnershipsCount:
              Number(_.get(profile, "company.activePartnership") ?? 0) + 1,
          });
        }
      } else {
        // We create the partnership and the invitation
        const result = await dispatch(
          create({
            type: "partnerships",
            attributes: {
              invites: [
                {
                  type: "partnership_invites",
                  attributes: {
                    invitation_url_id: companyFromInvitation.invitationUrlId,
                  },
                },
              ],
              source: PartnershipSource.InvitationURL,
            },
            options: { include: included, fields },
          })
        );
        if (isFulfilled(result)) {
          pushNotification({ ...i18n.invitationSent });
          const response = result.payload as JSONAPIResponse;
          dispatch(
            addCreatedPartner({
              id: +response.data.id,
              partnerName: response.data.attributes
                ?.requested_company_name as string,
            })
          );
          track(InviteURLEvent.invitationSent, { invitationId });
        } else {
          pushNotification("default_error");
        }
      }
    }
    handleClose();
  };

  useEffect(() => {
    if (invitationId) {
      if (profile.canInvitePartner(payingFeatures)) {
        retreiveCompanyFromInvite();
      } else {
        pushNotification(generic.not_authorized);
        handleClose();
      }
    }
  }, [invitationId]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      {companyFromInvitation !== null && (
        <Popup
          isOpen={isOpen}
          title={
            <T h3 bold className={classes.titleContainer}>
              <CompanyAvatar
                companyName={companyFromInvitation.name}
                companyDomain={companyFromInvitation.domain}
                src={companyFromInvitation.logo}
              />
              <FormattedMessage
                {...i18n.title}
                values={{ partner: companyFromInvitation.name }}
              />
            </T>
          }
          handleClose={handleClose}
          handleSave={sendInvite}
          maxWidth="sm"
          saveButtonText={
            acceptPartnershipId === null ? i18n.sendInvite : generic.accept
          }
          loadingSave={loadingSave}
          variant="secondary"
        >
          <div className={classes.popupContainer}>
            <T>
              <FormattedHTMLMessage
                {...(acceptPartnershipId === null
                  ? i18n.description
                  : i18n.acceptInviteDescription)}
                values={{ partner: companyFromInvitation.name }}
              />
            </T>
          </div>
        </Popup>
      )}
      <ReCAPTCHA
        ref={recaptchaRef}
        sitekey={reCaptchaSiteKey ?? ""}
        size="invisible"
      />
    </>
  );
};

export default InvitationURLModal;

// I18N

const i18n = defineMessages({
  title: {
    id: "InvitationURLModal.title",
    defaultMessage: "Connect with {partner}?",
  },
  description: {
    id: "InvitationURLModal.description",
    defaultMessage:
      "You’ve landed on Reveal by clicking on {partner}’s invite link.<br/>Please confirm that you want to connect with {partner} on Reveal by clicking “Send Invite” below.",
  },
  sendInvite: {
    id: "InvitationURLModal.sendInvite",
    defaultMessage: "Send invite",
  },
  acceptInviteDescription: {
    id: "InvitationURLModal.acceptInviteDescription",
    defaultMessage:
      "{partner} has previously sent you an invitation that is currently pending.<br/>Would you like to accept the partnership request with {partner}?",
  },
  invitationSent: {
    id: "InvitationURLModal.invitationSent",
    defaultMessage: "Your invitation has been sent.",
  },
  alreadyConnected: {
    id: "InvitationURLModal.alreadyConnected",
    defaultMessage: "{partner} is already a connected parter.",
  },
  invitationAlreadySent: {
    id: "InvitationURLModal.invitationAlreadySent",
    defaultMessage:
      "An invitation has already been sent to {partner} and is currently pending.",
  },
  sameCompanyInvitation: {
    id: "InvitationURLModal.sameCompanyInvitation",
    defaultMessage:
      "The invite link clicked belongs to your company, {company}.",
  },
});

// CSS

export const useStyles = makeStyles()((theme) => ({
  popupContainer: {
    display: "flex",
    flexDirection: "column",
    rowGap: theme.spacing(2),
  },
  titleContainer: {
    display: "flex",
    alignItems: "center",
    columnGap: theme.spacing(2),
  },
}));
