import { T } from "components/ui/Typography";
import { makeStyles } from "makeStyles";

import { hasNotBeenInvitedOrWasMoreThan1DayAgo } from "../shared/utils";

export const UserInfoCell = ({ dataItem, isName }: $TSFixMe) => {
  const { classes, cx } = useCellStyles();

  const name = dataItem.fullName;
  const email = dataItem.email;
  const roles = dataItem.roleAssignments;
  const currentRole = roles?.length ? roles[0].role : null;
  const canResendInvite = hasNotBeenInvitedOrWasMoreThan1DayAgo(
    dataItem.invitationSentAt
  );

  return (
    <T
      className={cx({
        [classes.disabled]:
          (dataItem.pendingInvitation && !canResendInvite) ||
          !currentRole ||
          !dataItem.hasLicense,
      })}
    >
      {isName ? name : email}
    </T>
  );
};

/// CSS

const useCellStyles = makeStyles()((theme) => ({
  disabled: {
    opacity: "50%",
  },
}));
