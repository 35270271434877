import useUserProfile from "hooks/useUserProfile";
import { useContext } from "react";
import { AccountMappingResource } from "redux/accountMapping/types";
import ViewContext from "screens/Frontoffice/screens/DataTables/screens/AccountMapping/contexts/ViewContext";
import { AccountMappingStandardFieldName } from "screens/Frontoffice/screens/DataTables/screens/AccountMapping/shared/types";

import { CellProps } from "../../../utils";
import AccountMappingBookmark from "./AccountMappingBookmark";
import AmNewProspectsBookmark from "./AmNewProspectsBookmark";

const AmActionsCell = (props: CellProps) => {
  const { row, fieldName } = props;

  const { profile } = useUserProfile();

  const { accountType, partnerName } = useContext(ViewContext);

  const hasOwnPipelinePermission = profile.ownsPipeline;

  return (
    <>
      {hasOwnPipelinePermission &&
        (accountType === AccountMappingResource.matches ||
          accountType === AccountMappingResource.matched_accounts) && (
          <AccountMappingBookmark
            partnerName={partnerName}
            match={row}
            hasPartnerConnection={row.hasPartnerConnection}
          />
        )}
      {hasOwnPipelinePermission &&
        accountType === AccountMappingResource.shared_accounts &&
        fieldName === AccountMappingStandardFieldName.rightNewProspectActions &&
        !!row?.[AccountMappingStandardFieldName.rightName] && (
          <AmNewProspectsBookmark
            partnerName={partnerName}
            newProspect={row}
            hasPartnerConnection={row.hasPartnerConnection}
          />
        )}
    </>
  );
};

export default AmActionsCell;

// Helpers

export const isAmActionsCell = ({ fieldName }: CellProps) => {
  return (
    fieldName === AccountMappingStandardFieldName.leftActions ||
    fieldName === AccountMappingStandardFieldName.rightNewProspectActions
  );
};
