import { List, ListItem } from "@mui/material";
import { makeStyles } from "makeStyles";
import { ReactElement } from "react";

type ListProps = {
  items: ReactElement[];
};

export const CollapseList = ({ items }: ListProps) => {
  const { classes } = useStyles();

  return (
    <List disablePadding className={classes.list}>
      {items.map((row, k) => (
        <ListItem disablePadding key={k}>
          {row}
        </ListItem>
      ))}
    </List>
  );
};

/// CSS

const useStyles = makeStyles()((theme) => ({
  list: { display: "flex", flexDirection: "column", rowGap: 4 },
}));
