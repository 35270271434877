import MuiCheckbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import { ClassNameMap } from "@mui/material/styles";
import { CheckboxChecked, CheckboxUnchecked } from "components/icons";
import { T } from "components/ui/Typography";
// Specially importing `_private.getFontStyles` outside tests because we need
// to override font styles that `FormControlLabel` inserts.
import { _private as _privateTypography } from "components/ui/Typography/Typography";
import { isMessageDescriptor } from "helpers/isMessageDescriptor";
import { makeStyles } from "makeStyles";
import { ChangeEvent, ReactNode } from "react";
import { FormattedHTMLMessage, MessageDescriptor } from "react-intl";
import { useMergedClasses } from "tss-react";

type Placements = "bottom" | "end" | "start" | "top";

type Props = {
  id?: string;
  label?: MessageDescriptor | ReactNode;
  labelPlacement?: Placements;
  value?: unknown;
  onChange?: (event: ChangeEvent<HTMLInputElement>, checked: boolean) => void;
  checked?: boolean;
  disabled?: boolean;
  classes?: ClassNameMap;
  withoutMargin?: boolean;
  indeterminate?: boolean;
};

const Checkbox = ({
  id,
  label,
  labelPlacement,
  classes: newClasses = {},
  withoutMargin,
  disabled,
  ...props
}: Props) => {
  const { classes } = useStyles({ withoutMargin });
  const { classes: baseCheckboxClasses } = useCheckboxStyles();
  const checkboxClasses = useMergedClasses(baseCheckboxClasses, newClasses);

  return (
    <FormControlLabel
      classes={classes}
      control={
        <MuiCheckbox
          id={id}
          classes={checkboxClasses}
          color="default"
          size="small"
          disableRipple
          icon={<CheckboxUnchecked />}
          checkedIcon={<CheckboxChecked />}
          disabled={disabled}
          {...props}
        />
      }
      label={
        <T span bodySmall className={classes.label}>
          {isMessageDescriptor(label) ? (
            <FormattedHTMLMessage {...label} />
          ) : (
            label
          )}
        </T>
      }
      labelPlacement={labelPlacement}
    />
  );
};

export default Checkbox;

// CSS

export const useStyles = makeStyles<{ withoutMargin?: boolean }>()(
  (theme, { withoutMargin }) => ({
    root: {
      marginBottom: 0,
      marginRight: withoutMargin ? 0 : 16,
    },
    label: {
      ..._privateTypography.getFontStyles({ bodySmall: true }),
      color: theme.palette.comet,
    },
  })
);

const useCheckboxStyles = makeStyles()((theme) => ({
  root: {
    color: theme.palette.comet,
    "&:hover": {
      backgroundColor: "transparent",
    },
  },
  disabled: {
    color: `${theme.palette.greyscale650} !important`,
  },
}));
