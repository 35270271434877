import { Box } from "@mui/material";
import Button from "components/ui/Button";
import { T } from "components/ui/Typography";
import { makeStyles } from "makeStyles";
import { PayingFeature } from "models/CompanyPayingFeatureSubscription";
import {
  defineMessages,
  FormattedHTMLMessage,
  FormattedMessage,
} from "react-intl";
import { useSelector } from "react-redux";
import { selectActivePayingFeatures } from "redux/api/selectors";

type Props = {
  onClose: () => void;
};

export const PartnerSignalsPopupContent = ({ onClose }: Props) => {
  const { classes } = useStyles();
  const hasAdvancedAnalytics = useSelector(selectActivePayingFeatures).includes(
    PayingFeature.AdvancedAnalytics
  );

  return (
    <Box className={classes.container}>
      <Box className={classes.imgContainer}>
        <img
          src="/images/partner-signals-popup.png"
          alt="partner-signals-img"
        />
      </Box>
      <Box className={classes.header}>
        <T h3 bold>
          <FormattedMessage {...i18n.partnerSignalsHeader} />
        </T>
      </Box>
      <Box className={classes.text}>
        <T>
          <FormattedHTMLMessage
            {...(hasAdvancedAnalytics
              ? i18n.partnerSignalsPremiumText
              : i18n.partnerSignalsText)}
          />
        </T>
      </Box>
      <Box className={classes.button}>
        <Button variant="tertiary" label={i18n.close} onClick={onClose} />
      </Box>
    </Box>
  );
};

const i18n = defineMessages({
  close: {
    id: "PartnerSignalsPopupContent.close",
    defaultMessage: "Close",
  },
  partnerSignalsPremiumText: {
    id: "PartnerSignalsPopupContent.partnerSignalsPremiumText",
    defaultMessage:
      "Partner Signals 🔥 indicate your prospects with a <b>high likelihood to convert into customers.</b> We've identified Conversion-Boosting Partners (highlighted in gold) that historically correlate with higher win rates for you.<br/><br/> 1 🔥 = 1 Conversion-Boosting Partner that listed the prospect as a customer.",
  },
  partnerSignalsText: {
    id: "PartnerSignalsPopupContent.partnerSignalsText",
    defaultMessage:
      "Partner Signals 🔥 indicate your prospects with a <b>high likelihood to convert into customers.</b> We've identified Conversion-Boosting Partners that historically correlate with higher win rates for you.<br/><br/> 1 🔥 = 1 Conversion-Boosting Partner that listed the prospect as a customer.",
  },
  partnerSignalsHeader: {
    id: "PartnerSignalsPopupContent.partnerSignalsHeader",
    defaultMessage: "Partner Signals",
  },
});

const useStyles = makeStyles()((theme) => ({
  button: {
    marginTop: theme.spacing(2.5),
  },
  container: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  header: {
    marginBottom: theme.spacing(1),
  },
  imgContainer: {
    width: 420,
    height: 220,
    marginBottom: theme.spacing(2.5),
    "& img": {
      height: "100%",
      width: "100%",
    },
  },
  text: {
    color: theme.palette.alpha750,
  },
}));
