import CompanyAvatar from "components/ui/avatars/CompanyAvatar";
import Checkbox from "components/ui/Checkbox";
import { T } from "components/ui/Typography";
import useUserProfile from "hooks/useUserProfile";
import { makeStyles } from "makeStyles";
import Partnership from "models/Partnership";
import Record from "models/Record";
import { ChangeEvent, ReactNode } from "react";

type Props = {
  partnership: Partnership;
  checked: boolean;
  overviewMessage?: ReactNode;
  toggleItem: (event: ChangeEvent<HTMLInputElement>) => void;
};

const PartnerItem = ({
  partnership,
  checked,
  overviewMessage,
  toggleItem,
}: Props) => {
  const { classes } = useStyles();
  const { profile } = useUserProfile();
  const partner = partnership.getPartner(profile) as Record;
  const isInitiator = partnership.isInitiator(profile.company);

  const clearbitLogo =
    !partner.avatarUrl && partnership.requestedCompanyDomain && isInitiator
      ? `https://logo.clearbit.com/${partnership.requestedCompanyDomain}`
      : null;

  const id = String(partnership.id);

  return (
    <div className="partner-item">
      <label htmlFor={id} className={classes.label}>
        <Checkbox
          id={id}
          checked={checked}
          value={partnership.id}
          onChange={toggleItem}
          withoutMargin
          data-testid={`partnership-${id}`}
        />
        <CompanyAvatar
          classes={{ avatar: classes.avatar }}
          company={partner}
          src={clearbitLogo}
          size="sm"
        />
        <T>{partner.name}</T>
      </label>
      {overviewMessage && <T className={classes.text}>{overviewMessage}</T>}
    </div>
  );
};

export default PartnerItem;

/// CSS

const useStyles = makeStyles()((theme) => ({
  label: {
    display: "flex",
    alignItems: "center",
    cursor: "pointer",
    fontSize: 14,
    fontWeight: 500,
  },
  avatar: {
    borderRadius: 8,
    marginRight: theme.spacing(1),
    height: 32,
    width: 32,
    fontSize: 15,
  },
  text: {
    color: theme.palette.darkPigeon,
    marginLeft: theme.spacing(3.5),
  },
}));
