/**
 *
 * We use feature flags to customize the build and hide new features from users
 * while they are being developed. For consistency we should use the following schema:
 * `REACT_APP_MYFLAG=true`, and then `JSON.parse` it to convert into a boolean.
 *
 * If you want to create a new flag, you may also want to add a `script` command in
 * `package.json` e.g.
 *
 * "start:partners:olddesign": "REACT_APP_FEATURE_FLAG_REDESIGN=false PORT=3002 react-scripts start"
 *
 */
export const FLAGS = {
  redesign: JSON.parse(
    process.env.REACT_APP_FEATURE_FLAG_REDESIGN ||
      process.env.STORYBOOK_FEATURE_FLAG_REDESIGN ||
      "true"
  ),
};
