import { createSvgIcon } from "@mui/material/utils";

import { SvgIcon } from "./types";

const displayName = "EditRect";

const EditRect: SvgIcon = createSvgIcon(
  // Using transform to enlarge from provided viewbox="0 0 12 12"
  // to default "0 0 24 24" from `createSvgIcon` -> `SvgIcon`.
  <g fill="none" transform="matrix(2,0,0,2,0,0)">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.3693 1.08132L9.91848 1.63049C10.4677 2.17966 10.4677 3.07005 9.91848 3.61923L5.765 7.7727C5.58478 7.95292 5.35897 8.08078 5.1117 8.14259L3.3689 8.57829C2.79673 8.72133 2.27846 8.20306 2.4215 7.63089L2.8572 5.88809C2.91901 5.64082 3.04687 5.41501 3.22709 5.23479L7.38056 1.08131C7.92974 0.532139 8.82013 0.532141 9.3693 1.08132ZM8.70639 1.74423C8.52333 1.56117 8.22653 1.56117 8.04348 1.74423L7.6506 2.1371L8.86269 3.34919L9.25556 2.95632C9.43862 2.77326 9.43862 2.47646 9.25556 2.2934L8.70639 1.74423ZM3.89001 5.8977L6.98769 2.80002L8.19978 4.0121L5.10209 7.10979C5.04202 7.16986 4.96675 7.21248 4.88432 7.23309L3.39417 7.60562L3.76671 6.11547C3.78731 6.03305 3.82993 5.95777 3.89001 5.8977Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0.96875 3C0.96875 2.43046 1.43046 1.96875 2 1.96875H5C5.25888 1.96875 5.46875 1.75888 5.46875 1.5C5.46875 1.24112 5.25888 1.03125 5 1.03125H2C0.912689 1.03125 0.03125 1.91269 0.03125 3V9C0.03125 10.0873 0.91269 10.9688 2 10.9688H8C9.08731 10.9688 9.96875 10.0873 9.96875 9V6C9.96875 5.74112 9.75888 5.53125 9.5 5.53125C9.24112 5.53125 9.03125 5.74112 9.03125 6V9C9.03125 9.56954 8.56954 10.0312 8 10.0312H2C1.43046 10.0312 0.96875 9.56954 0.96875 9V3Z"
      fill="currentColor"
    />
  </g>,
  displayName
);

EditRect.displayName = displayName;
export default EditRect;
