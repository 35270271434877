import _ from "lodash";
import {
  COMPANY_ECOSYSTEM_TO_CHILI_PIPER_VALUES,
  CompanyEcosystem,
  CompanySize,
} from "models/Company";
import User, { USER_ROLE_TO_CHILI_PIPER_VALUES, UserRole } from "models/User";

export const SMALL_COMPANY_SIZES = [
  CompanySize._1,
  CompanySize._2_10,
  CompanySize._11_50,
];

export const companySizeGreaterThan50 = (profile: User) => {
  const companySize = profile?.company?.size as CompanySize;
  return !!companySize && !SMALL_COMPANY_SIZES.includes(companySize);
};

export const companySizeGreaterThan10 = (profile: User) => {
  const companySize = profile?.company?.size as CompanySize;
  return (
    !!companySize && ![CompanySize._1, CompanySize._2_10].includes(companySize)
  );
};

export const FORM_CONTAINER_ID = "book-demo-form-container";

export const displayOnboardingMeetingDialog = (
  profile: User,
  onSuccess: () => void,
  onClose: () => void
) => {
  (window as any).ChiliPiper.submit(
    "reveal",
    companySizeGreaterThan50(profile) || _.get(profile, "company.viral")
      ? "inbound_router_onboard"
      : "inbound_router_group_onboard",
    {
      lead: {
        Campaign_content__c: "Onboarding Step",
        Company: profile.companyName,
        Email: profile.email,
        FirstName: profile.firstName,
        LastName: profile.lastName,
        LeadSource: "Chili Piper",
        Reveal_Job_position__c:
          USER_ROLE_TO_CHILI_PIPER_VALUES?.[
            (profile.attributes?.role as UserRole) ?? ""
          ],
        Reveal_Ecosystem__c:
          COMPANY_ECOSYSTEM_TO_CHILI_PIPER_VALUES?.[
            profile.company?.ecosystem as CompanyEcosystem
          ] ?? "",
        Time_Zone__c: Number(profile.timezone),
      },
      map: true,
      domElement: `#${FORM_CONTAINER_ID}`,
      onSuccess,
      onClose,
    }
  );
};
