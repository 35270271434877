import Paper from "@mui/material/Paper";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { isFulfilled } from "@reduxjs/toolkit";
import { Check } from "components/icons";
import {
  BasicDialog,
  BasicDialogVariant,
} from "components/ui/BasicDialog/BasicDialog";
import { Formik, FormikHelpers } from "formik";
import usePushNotification from "hooks/usePushNotification";
import forms from "i18n/forms";
import generic from "i18n/generic";
import _ from "lodash";
import { makeStyles } from "makeStyles";
import { defineMessages, FormattedMessage, useIntl } from "react-intl";
import { useDispatch } from "react-redux";
import { rawPost, retreive } from "redux/api/thunks";
import { fields, included } from "redux/init/constants";
import * as Yup from "yup";

import { PartnershipOwnerRow } from "./PartnershipOwnerRow";

export type partnershipOwner = {
  partnershipId: number;
  ownerId: string;
};

export type Values = {
  partnershipOwners: partnershipOwner[];
};

type UpdatePartnershipOwnerFormProps = {
  userName: string;
  partnershipIds: number[];
  handleClose: () => void;
  callback: () => void;
};

export const UpdatePartnershipOwnerForm = ({
  userName,
  partnershipIds,
  handleClose,
  callback,
}: UpdatePartnershipOwnerFormProps) => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const pushNotification = usePushNotification();
  const { classes: tableClasses } = useTableStyles();

  const initialValues: Values = {
    partnershipOwners: partnershipIds.map((id) => ({
      partnershipId: id,
      ownerId: "",
    })),
  };

  const validationSchema = Yup.object().shape({
    partnershipOwners: Yup.array().of(
      Yup.object().shape({
        partnershipId: Yup.string().required(() => forms.generic_required),
        ownerId: Yup.string().required(() => forms.generic_required),
      })
    ),
  });

  const reloadPartnership = (id: number) => {
    dispatch(
      retreive({
        id: id,
        type: "partnerships",
        options: {
          include: included,
          fields,
        },
      })
    );
  };

  const handleSave = async (values: Values, actions: FormikHelpers<Values>) => {
    const result = await dispatch(
      rawPost({
        type: "partnerships",
        path: "bulk-update-owner/",
        payload: {
          data: values.partnershipOwners.map((item) => ({
            type: "partnerships",
            id: item.partnershipId.toString(),
            owner_id: item.ownerId,
          })),
        },
      })
    );
    if (isFulfilled(result)) {
      handleClose();
      actions.resetForm();
      partnershipIds.forEach((id) => {
        reloadPartnership(id);
      });
      callback();
    } else {
      pushNotification("default_error");
    }
  };

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={handleSave}
      validationSchema={validationSchema}
      enableReinitialize
    >
      {({
        values,
        setFieldTouched,
        handleSubmit,
        handleChange,
        isSubmitting,
        errors,
        touched,
        resetForm,
      }) => (
        <BasicDialog
          variant={BasicDialogVariant.Delete}
          title={intl.formatMessage(i18n.title, {
            userName,
            count: partnershipIds.length,
          })}
          buttonProps={[
            {
              label: generic.cancel,
              variant: "tertiary",
              onClick: () => {
                handleClose();
                resetForm();
              },
              loading: isSubmitting,
            },
            {
              label: generic.confirm,
              LeftIcon: Check,
              onClick: handleSubmit,
              loading: isSubmitting,
            },
          ]}
          content={
            <TableContainer component={Paper} classes={tableClasses}>
              <Table size="small" aria-label="partnership owners table">
                <TableHead>
                  <TableRow>
                    <StyledTableCell>
                      <FormattedMessage {...generic.full_name} />
                    </StyledTableCell>
                    <StyledTableCell>
                      <FormattedMessage {...i18n.partnerOwnership} />
                    </StyledTableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {values.partnershipOwners.map((item, i) => {
                    const fieldError = !_.isEmpty(
                      (errors.partnershipOwners?.length &&
                        errors.partnershipOwners[i]) ||
                        {}
                    );
                    const fieldTouched =
                      (touched.partnershipOwners?.length &&
                        touched.partnershipOwners[i]) ||
                      false;

                    return (
                      <PartnershipOwnerRow
                        partnershipId={item.partnershipId}
                        error={Boolean(fieldTouched && fieldError)}
                        values={values}
                        index={i}
                        setFieldTouched={setFieldTouched}
                        handleChange={handleChange}
                      />
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>
          }
        />
      )}
    </Formik>
  );
};

// CSS

export const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.greyLight050,
    fontSize: 12,
  },
}));

const useTableStyles = makeStyles()((theme) => ({
  root: {
    marginTop: theme.spacing(2.5),
    borderRadius: "8px",
    border: `1px solid ${theme.palette.greyLight100}`,
  },
}));

// I18N

const i18n = defineMessages({
  title: {
    id: "UpdatePartnershipOwnerForm.title",
    defaultMessage:
      "Before deleting {userName}, please reassign the following {count, plural, one {partner} other {partners}}",
  },
  description: {
    id: "UpdatePartnershipOwnerForm.description",
    defaultMessage:
      "You have reached the maximum number of users allowed in your workspace.",
  },
  upgradePlan: {
    id: "UpdatePartnershipOwnerForm.upgradePlan",
    defaultMessage: "Upgrade Plan",
  },
  manageAccounts: {
    id: "UpdatePartnershipOwnerForm.manageAccounts",
    defaultMessage: "Manage accounts",
  },
  partnerOwnership: {
    id: "UpdatePartnershipOwnerForm.partnerOwnership",
    defaultMessage: "Partner Ownership",
  },
});
