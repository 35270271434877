import { createSvgIcon } from "@mui/material/utils";

import { SvgIcon } from "./types";

const displayName = "SendTitled";

const SendTitled: SvgIcon = createSvgIcon(
  // Using transform to enlarge from provided viewbox="0 0 12 12"
  // to default "0 0 24 24" from `createSvgIcon` -> `SvgIcon`.
  <g fill="none" transform="matrix(2,0,0,2,0,0)">
    <g clipPath="url(#clip0_676_2595)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.25619 4.44633L10.3419 1.65816L7.55369 9.74383L6.14133 6.68371C6.13823 6.67699 6.13508 6.67029 6.1319 6.66362L7.30295 5.49257C7.52262 5.2729 7.52262 4.91674 7.30295 4.69707C7.08328 4.4774 6.72713 4.4774 6.50746 4.69707L5.3364 5.86812C5.32973 5.86494 5.32303 5.86179 5.31631 5.85869L2.25619 4.44633ZM11.5309 1.66106C11.7856 0.922274 11.0777 0.214403 10.339 0.469157L1.44852 3.53483C0.649942 3.8102 0.594295 4.91834 1.36126 5.27233L4.84487 6.88014C4.96632 6.9362 5.06382 7.0337 5.11988 7.15515L6.72769 10.6388C7.08168 11.4057 8.18982 11.3501 8.46519 10.5515L11.5309 1.66106Z"
        fill="currentColor"
      />
    </g>
    <defs>
      <clipPath id="clip0_676_2595">
        <rect width="12" height="12" fill="white" />
      </clipPath>
    </defs>
  </g>,
  displayName
);

SendTitled.displayName = displayName;
export default SendTitled;
