import { createSvgIcon } from "@mui/material/utils";

import { SvgIcon } from "./types";

const displayName = "QuickFilterOn";

const QuickFilterOn: SvgIcon = createSvgIcon(
  // Using transform to enlarge from provided viewbox="0 0 16 16"
  // to default "0 0 24 24" from `createSvgIcon` -> `SvgIcon`.
  <g fill="none" transform="matrix(1.5,0,0,1.5,2,2)">
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M1.76415 0.375H9.47779C9.23958 0.743972 9.08013 1.16856 9.02321 1.625H1.76415C1.6873 1.625 1.625 1.6873 1.625 1.76415C1.625 2.57717 2.03132 3.33639 2.7078 3.78737L5.47874 5.63467C6.06991 6.02878 6.425 6.69227 6.425 7.40278V11.8322L7.40801 11.1768C7.51234 11.1073 7.575 10.9902 7.575 10.8648V7.40278C7.575 6.69227 7.93009 6.02878 8.52126 5.63467L10.2829 4.46028C10.7034 4.75433 11.2028 4.94332 11.743 4.98915L9.21464 6.67473C8.97121 6.83701 8.825 7.11022 8.825 7.40278V10.8648C8.825 11.4081 8.55346 11.9155 8.10139 12.2169L6.53536 13.2609C5.95388 13.6486 5.175 13.2317 5.175 12.5329V7.40278C5.175 7.11022 5.02879 6.83701 4.78536 6.67473L2.01442 4.82744C0.990201 4.14462 0.375 2.99511 0.375 1.76415C0.375 0.996944 0.996945 0.375 1.76415 0.375ZM12 3.5C12.8284 3.5 13.5 2.82843 13.5 2C13.5 1.17157 12.8284 0.5 12 0.5C11.1716 0.5 10.5 1.17157 10.5 2C10.5 2.82843 11.1716 3.5 12 3.5Z"
      fill="#050F24D1"
    />
  </g>,
  displayName
);
QuickFilterOn.displayName = displayName;
export default QuickFilterOn;
