import KeyContact from "@mui/icons-material/HowToReg";
import CircularProgress from "@mui/material/CircularProgress";
import Popover from "@mui/material/Popover";
import { ProfileCircle } from "components/icons";
import CompanyAvatar from "components/ui/avatars/CompanyAvatar";
import { T } from "components/ui/Typography";
import _ from "lodash";
import { makeStyles } from "makeStyles";
import { ReactElement, useContext, useState } from "react";
import { defineMessages, FormattedMessage } from "react-intl";
import ViewContext from "screens/Frontoffice/screens/DataTables/screens/AccountMapping/contexts/ViewContext";

type Props = {
  value?: number;
  label?: ReactElement | string;
  contacts?: $TSFixMe[];
  loadContacts: $TSFixMeFunction;
  unspecifiedText?: string;
};

const BaseContactsCell = ({
  value,
  label,
  contacts = [],
  loadContacts,
  unspecifiedText,
}: Props) => {
  const { classes } = useStyles({ value });
  const { classes: popoverClasses } = usePopoverStyles();
  const [anchorEl, setAnchorEl] = useState(null);
  const { partnerName, partnerAvatarUrl } = useContext(ViewContext);
  const unspecifiedContacts = contacts.filter((item) => !Boolean(item.jobtitle))
    .length;

  const getJobTitles = () => {
    const result = [];
    const jobTitleSize = 10;
    const titles = _.sortBy(contacts, ["opports"])
      .reverse()
      .filter((item: $TSFixMe) => Boolean(item.jobtitle))
      .map((item: $TSFixMe, index: $TSFixMe) => (
        <T key={index} className={classes.contactRow}>
          <ProfileCircle className={classes.contactIcon} />
          {item.jobtitle}
          {Boolean(item.opports) && (
            <KeyContact className={classes.keyContactIcon} />
          )}
        </T>
      ));
    if (titles.length > jobTitleSize) {
      result.push(titles.slice(0, jobTitleSize));
      result.push(
        <T>
          <FormattedMessage
            {...i18n.more}
            values={{
              count: unspecifiedContacts + titles.length - jobTitleSize,
            }}
          />
        </T>
      );
    } else {
      result.push(titles);
      if (unspecifiedContacts) {
        result.push(
          <T className={classes.contactRow}>
            <ProfileCircle className={classes.unspecifiedContactIcon} />
            {unspecifiedText}
          </T>
        );
      }
    }
    return result;
  };

  const handleOpen = async (event: $TSFixMe) => {
    if (value) {
      if (contacts.length < 1) {
        loadContacts();
      }
      setAnchorEl(event.currentTarget);
    }
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  if (value === undefined) return null;

  return (
    <div title={value ? value.toString() : undefined} className={classes.root}>
      <span
        aria-owns={open ? "contacts-popover" : undefined}
        aria-haspopup="true"
        onMouseEnter={handleOpen}
        onMouseLeave={handleClose}
      >
        <T className={classes.cellText}>{value}</T>
      </span>
      <Popover
        id="contacts-popover"
        sx={{
          pointerEvents: "none",
        }}
        open={open}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        onClose={handleClose}
        classes={popoverClasses}
        hideBackdrop
      >
        <div className={classes.header}>
          <T h4 bold className={classes.companyName}>
            <CompanyAvatar
              companyName={partnerName}
              src={partnerAvatarUrl}
              size="xs"
            />
            {partnerName}
          </T>
          <T className={classes.label}>{label}</T>
        </div>
        <div className={classes.contactList}>
          {contacts.length ? (
            getJobTitles()
          ) : (
            <div className={classes.loader}>
              <CircularProgress size={20} />
            </div>
          )}
        </div>
      </Popover>
    </div>
  );
};
export default BaseContactsCell;

// I18N

const i18n = defineMessages({
  more: {
    id: "ui.datagrid.cellRenderers.BaseContactsCell.more",
    defaultMessage: `+ {count} more`,
  },
});

// CSS

export const useStyles = makeStyles<{ value?: number }>()(
  (theme, { value }) => ({
    root: {
      textAlign: "right",
      cursor: "default",
    },
    cellText: {
      color: value ? theme.palette.midnight : theme.palette.greyscale500,
      borderRadius: 4,
      border: `1px solid ${theme.palette.greyscale250}`,
      padding: "3px 6px",
      height: 24,
      whiteSpace: "normal",
    },
    loader: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
    },
    header: {
      display: "flex",
      flexDirection: "column",
      rowGap: 7.5,
    },
    companyName: {
      color: theme.palette.midnight,
      display: "flex",
      flexDirection: "row",
      columnGap: 10,
    },
    label: {
      color: theme.palette.greyscale750,
    },
    contactList: {
      marginTop: 16,
      display: "flex",
      flexDirection: "column",
      rowGap: 6,
    },
    unspecifiedContactList: {
      display: "flex",
      flexDirection: "column",
      rowGap: 6,
    },
    contactRow: {
      display: "flex",
      flexDirection: "row",
      columnGap: 7.5,
      lineHeight: 1.2,
    },
    contactIcon: {
      width: 13,
      height: 13,
    },
    unspecifiedContactIcon: {
      color: theme.palette.greyscale500,
      width: 13,
      height: 13,
    },
    keyContactIcon: {
      color: theme.palette.midnight,
      width: 13,
      height: 13,
    },
  })
);

const usePopoverStyles = makeStyles()((theme) => ({
  paper: {
    padding: 16,
    boxShadow:
      "0px 6px 32px -2px rgba(7, 14, 19, 0.04), 0px 1px 1px rgba(7, 14, 19, 0.06), 0px 4px 12px -1px rgba(7, 14, 19, 0.04)",
    borderRadius: 8,
    minWidth: 300,
  },
}));
