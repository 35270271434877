import { isFulfilled } from "@reduxjs/toolkit";
import BaseSettingsTab from "components/ui/BaseSettingsTab";
import { emptyNode, SWQLField, SWQLNode } from "components/ui/SWQL/SWQLTypes";
import { mapCrmFieldToSWQLField } from "components/ui/SWQL/utils";
import useAllRecords from "hooks/useAllRecords";
import usePushNotification from "hooks/usePushNotification";
import useSegment from "hooks/useSegment";
import generic from "i18n/generic";
import _ from "lodash";
import CrmField from "models/CrmField";
import {
  Kind,
  kindDecodeMapping,
  kindEncodeMapping,
} from "models/PartnerConnection";
import { useEffect } from "react";
import { useState } from "react";
import { defineMessages, useIntl } from "react-intl";
import { useDispatch } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import { rawPost } from "redux/api/thunks";

import { updateHistoryUpdatedSource } from "../../../helpers/utils";
import { TrackingSettingsForKind } from "./TrackingSettingsForKind";

type Props = {
  integrationId: number;
  handleClose?: () => {};
  callback?: () => {};
};

type KindState = {
  swqlFilterRule: SWQLNode;
  partnerField: SWQLField | null;
  partnerManagerField: SWQLField | null;
};

const emptyState: KindState = {
  swqlFilterRule: emptyNode(),
  partnerField: null,
  partnerManagerField: null,
};

const OpportunitiesTrackingWidget = ({
  integrationId,
  callback,
  ...props
}: Props) => {
  const dispatch = useDispatch();
  const intl = useIntl();
  const pushNotification = usePushNotification();
  const { track } = useSegment();
  const history = useHistory();
  const location = useLocation();

  const [sourcingState, setSourcingState] = useState<KindState>(emptyState);
  const [influenceState, setInfluenceState] = useState<KindState>(emptyState);
  const [otherState, setOtherState] = useState<KindState>(emptyState);

  const [initialSourcingState, setInitialSourcingState] = useState<KindState>(
    emptyState
  );
  const [initialInfluenceState, setInitialInfluenceState] = useState<KindState>(
    emptyState
  );
  const [initialOtherState, setInitialOtherState] = useState<KindState>(
    emptyState
  );

  const [saving, setSaving] = useState(false);

  const {
    records: rulesRecords,
    loading: rulesLoading,
    refresh: rulesRefresh,
  }: {
    records: $TSFixMe[];
    refresh: () => void;
    loading: boolean;
  } = useAllRecords("partner_connection_sync_rules", {
    filters: {
      integration: integrationId,
    },
    include: ["crm_fields", "partner_field", "partner_manager_field"],
  });

  const {
    records: crmFieldsRecords,
    loading: fieldsLoading,
  }: {
    records: CrmField[];
    loading: boolean;
    refresh: () => void;
  } = useAllRecords("crm_fields", {
    filters: {
      integration: integrationId,
      sw_record_type: "RawOpportunity",
      syncable: true,
    },
  });

  useEffect(() => {
    if (rulesRecords.length > 0) {
      setSourcingState(mapKindRecordToState(rulesRecords, Kind.SOURCING));
      setInfluenceState(mapKindRecordToState(rulesRecords, Kind.INFLUENCE));
      setOtherState(mapKindRecordToState(rulesRecords, Kind.OTHER));

      setInitialSourcingState(
        mapKindRecordToState(rulesRecords, Kind.SOURCING)
      );
      setInitialInfluenceState(
        mapKindRecordToState(rulesRecords, Kind.INFLUENCE)
      );
      setInitialOtherState(mapKindRecordToState(rulesRecords, Kind.OTHER));
    }
  }, [rulesRecords]);

  const handleSave = async () => {
    setSaving(true);

    const data = [];

    if (!_.isEqual(sourcingState, initialSourcingState)) {
      data.push(mapKindStateToDataPayload(sourcingState, Kind.SOURCING));
    }

    if (!_.isEqual(influenceState, initialInfluenceState)) {
      data.push(mapKindStateToDataPayload(influenceState, Kind.INFLUENCE));
    }

    if (!_.isEqual(otherState, initialOtherState)) {
      data.push(mapKindStateToDataPayload(otherState, Kind.OTHER));
    }

    const resultAction = await dispatch(
      rawPost({
        type: "integrations",
        id: integrationId,
        path: "/partner-connection-sync-rules/",
        payload: { data },
        options: rawPostConfig,
      })
    );
    if (isFulfilled(resultAction)) {
      pushNotification({ ...generic.edits_saved });
      track("Edited data source opportunities tracking");
      rulesRefresh();
      updateHistoryUpdatedSource(history, location);
      if (callback) {
        callback();
      }
    } else {
      pushNotification("default_error");
    }

    setSaving(false);
  };

  const userHasNotChangedAnyFields =
    _.isEqual(sourcingState, initialSourcingState) &&
    _.isEqual(influenceState, initialInfluenceState) &&
    _.isEqual(otherState, initialOtherState);

  const swqlFieldOptions = crmFieldsRecords.map(mapCrmFieldToSWQLField);

  return (
    <BaseSettingsTab
      saving={saving}
      loading={rulesLoading || fieldsLoading}
      handleSave={handleSave}
      summary={intl.formatMessage(i18n.info)}
      disabled={userHasNotChangedAnyFields}
      {...props}
    >
      <TrackingSettingsForKind
        kind={Kind.SOURCING}
        integrationId={integrationId}
        swqlFieldOptions={swqlFieldOptions}
        {...sourcingState}
        {...onChangeHandlers(setSourcingState)}
      />
      <TrackingSettingsForKind
        kind={Kind.INFLUENCE}
        integrationId={integrationId}
        swqlFieldOptions={swqlFieldOptions}
        {...influenceState}
        {...onChangeHandlers(setInfluenceState)}
      />
      <TrackingSettingsForKind
        kind={Kind.OTHER}
        integrationId={integrationId}
        swqlFieldOptions={swqlFieldOptions}
        {...otherState}
        {...onChangeHandlers(setOtherState)}
      />
    </BaseSettingsTab>
  );
};

const mapKindRecordToState = (
  rulesRecords: $TSFixMe,
  kind: Kind
): KindState => {
  const kindRecord = rulesRecords.find(
    (record: $TSFixMe) => kindDecodeMapping[record.kind] === kind
  );

  return {
    swqlFilterRule: kindRecord?.query || emptyNode(),
    partnerField:
      (kindRecord?.partnerField &&
        mapCrmFieldToSWQLField(kindRecord.partnerField)) ||
      null,
    partnerManagerField:
      (kindRecord?.partnerManagerField &&
        mapCrmFieldToSWQLField(kindRecord.partnerManagerField)) ||
      null,
  };
};

const mapKindStateToDataPayload = (kindState: KindState, kind: Kind) => ({
  type: "partner_connection_sync_rules",
  attributes: {
    kind: kindEncodeMapping[kind],
    query: kindState.swqlFilterRule,
  },
  relationships: {
    partner_field: kindState.partnerField && {
      data: {
        type: "crm_fields",
        id: `${kindState.partnerField.id}`,
      },
    },
    partner_manager_field: kindState.partnerManagerField && {
      data: {
        type: "crm_fields",
        id: `${kindState.partnerManagerField.id}`,
      },
    },
  },
});

const onChangeHandlers = (setState: SetState<KindState>) => ({
  onChangeSwql: (swqlFilterRule: SWQLNode) =>
    setState((state) => ({ ...state, swqlFilterRule })),
  onPartnerChange: (partnerField: SWQLField | null) =>
    setState((state) => ({ ...state, partnerField })),
  onPartnerManagerChange: (partnerManagerField: SWQLField | null) =>
    setState((state) => ({ ...state, partnerManagerField })),
});

const i18n = defineMessages({
  info: {
    id: "Settings.SourceSettings.Widgets.OpportunitiesTrackingWidget.info",
    defaultMessage:
      "Set up rules to import your partner related opportunities from your CRM and track all partner generated business in Reveal Collaborate.",
  },
});

const rawPostConfig = {
  config: {
    headers: {
      "Content-Type": "application/json",
    },
  },
};

export default OpportunitiesTrackingWidget;
