import { isFulfilled } from "@reduxjs/toolkit";
import usePushNotification from "hooks/usePushNotification";
import IntroductionRequestForm, {
  IQuestionForm,
} from "models/IntroductionRequestForm";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { rawPatch, rawPost, retreive } from "redux/api/thunks";
import { JSONAPIResponse } from "services/types";

export const useGetIntroTemplateDrawer = (
  record: IntroductionRequestForm | null,
  setRecordId: (id: number) => void
) => {
  const dispatch = useDispatch();
  const pushNotification = usePushNotification();

  const [selectedPartnerIds, setSelectedPartnerIds] = useState<string[] | null>(
    null
  );
  const [resetCount, setResetCount] = useState(0);

  const reloadIntroRequestForm = (id: number) => {
    dispatch(
      retreive({
        id,
        type: "introduction_request_forms",
      })
    );
  };

  const saveForm = async (formValues: IQuestionForm) => {
    const data = {
      type: "introduction_request_forms",
      attributes: {
        is_enabled: record?.isEnabled ?? false,
        partnership_ids: selectedPartnerIds?.map((id) => +id) ?? [],
        form_data: formValues,
      },
    };
    let result;
    if (record) {
      result = await dispatch(
        rawPatch({
          id: record.id,
          type: "introduction_request_forms",
          path: "/",
          payload: { data: { ...data, id: record.id } },
        })
      );
    } else {
      result = await dispatch(
        rawPost({
          type: "introduction_request_forms",
          path: "",
          payload: { data },
        })
      );
    }
    if (isFulfilled(result)) {
      const response = result.payload as JSONAPIResponse;
      const id = +response.data.id;
      if (!record) setRecordId(id);
      reloadIntroRequestForm(id);
    } else {
      pushNotification("default_error");
    }
  };

  useEffect(() => {
    setSelectedPartnerIds(
      record?.partnershipIds.length
        ? record.partnershipIds.map((id) => String(id))
        : null
    );
  }, [record, resetCount]);

  return {
    selectedPartnerIds,
    setSelectedPartnerIds,
    saveForm,
    resetSelectedPartnerIds: () => setResetCount((current) => current + 1),
  };
};
