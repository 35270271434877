import { isFulfilled } from "@reduxjs/toolkit";
import { AxiosResponse } from "axios";
import { NotificationStatus } from "components/ui/Notifications/NotificationSnackbar";
import { crmProviders, ProviderType } from "config/constants";
import usePushNotification from "hooks/usePushNotification";
import useSegment from "hooks/useSegment";
import generic from "i18n/generic";
import _ from "lodash";
import { ShareOwner } from "models/AccountMappingSharingSettings";
import { PayingFeature } from "models/CompanyPayingFeatureSubscription";
import Integration from "models/Integration";
import Record from "models/Record";
import { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  selectActivePayingFeatures,
  selectAllIntegrations,
} from "redux/api/selectors";
import { index, rawGet, rawPatch, rawPost } from "redux/api/thunks";

import { TabProps } from "../../types";
import {
  IntroNotificationSettingsInitial,
  PartnershipChannel,
  PartnershipChannelMode,
  PartnershipChannelType,
} from "../types";

export const useGetIntroContent = ({
  saving,
  setSaveButtonEnabled,
  afterSave,
  partner,
  partnership,
}: TabProps) => {
  const { track } = useSegment();
  const dispatch = useDispatch();
  const pushNotification = usePushNotification();
  const payingFeatures = useSelector(selectActivePayingFeatures);
  const integrations = useSelector(selectAllIntegrations);
  const [hasConnectedSlack, setHasConnectedSlack] = useState<boolean | null>(
    null
  );

  // Internal Channel States
  const [loadingInternal, setLoadingInternal] = useState<boolean>(true);
  const [internalChannelEnabled, setInternalChannelEnabled] = useState<boolean>(
    false
  );
  const [
    internalChannelDefault,
    setInternalChannelDefault,
  ] = useState<PartnershipChannel | null>(null);
  const [
    internalChannelInitial,
    setInternalChannelInitial,
  ] = useState<PartnershipChannel | null>(null);
  const [internalSlackId, setInternalSlackId] = useState<string | null>(null);
  const [hasInternalChannel, setHasInternalChannel] = useState<boolean>(false);

  // Shared Channel States
  const [loadingShared, setLoadingShared] = useState<boolean>(true);
  const [sharedChannelEnabled, setSharedChannelEnabled] = useState<boolean>(
    false
  );
  const [
    sharedChannelInitial,
    setSharedChannelInitial,
  ] = useState<PartnershipChannel | null>(null);
  const [sharedSlackId, setSharedSlackId] = useState<string | null>(null);

  // Intro notifications settings States (partner's owners emails)
  const [
    introNotificationSettingsInitial,
    setIntroNotificationSettingsInitial,
  ] = useState<IntroNotificationSettingsInitial>(null);
  const [customCrmFields, setCustomCrmFields] = useState<any[]>([]);
  const [ownerCrmFieldNames, setOwnerCrmFieldNames] = useState<string[]>([]);
  const [
    shouldEmailAccountOwner,
    setShouldEmailAccountOwner,
  ] = useState<boolean>(false);
  const [
    shouldEmailPartnershipManager,
    setShouldEmailPartnershipManager,
  ] = useState<boolean>(false);

  const hasSlackIntegrationPayingFeature = payingFeatures.includes(
    PayingFeature.SlackIntegration
  );

  // Compute has updates
  const {
    hasUpdates,
    hasInternalUpdates,
    hasSharedUpdates,
    hasIntroNotificationSettingsUpdates,
  } = getHasUpdates(
    hasSlackIntegrationPayingFeature,
    loadingInternal,
    internalChannelEnabled,
    internalChannelInitial,
    internalSlackId,
    loadingShared,
    sharedChannelEnabled,
    sharedChannelInitial,
    sharedSlackId,
    introNotificationSettingsInitial,
    shouldEmailAccountOwner,
    shouldEmailPartnershipManager,
    Boolean(hasConnectedSlack),
    ownerCrmFieldNames
  );

  /**
   * Internal Channel functions
   * */

  const getHasConnectedSlack = useCallback(async () => {
    const result = await dispatch(
      index({
        type: "slack_credentials",
      })
    );
    if (isFulfilled(result)) {
      const response = result.payload as AxiosResponse;
      setHasConnectedSlack(response.data.length > 0);
    } else {
      setHasConnectedSlack(false);
      pushNotification("default_error");
    }
  }, [dispatch, pushNotification]);

  const getCustomCrmFields = async () => {
    const SalesforceAndOrHubspotIntegrationIds = partnership.integrations
      .filter((integration: Integration) =>
        [ProviderType.hubspot, ProviderType.salesforce].includes(
          integration.provider as ProviderType
        )
      )
      .map((integration) => integration.id);

    const result = await dispatch(
      rawGet({
        type: "crm_fields",
        path: "",
        options: {
          filters: {
            "integration_id.in": SalesforceAndOrHubspotIntegrationIds,
            sw_type: 11,
            sw_record_type: "RawCompany",
          },
        },
      })
    );
    if (isFulfilled(result)) {
      const response = result.payload as AxiosResponse;
      const sortedCustomCrmFields = _.sortBy(
        response.data,
        "relationships.integration.data.id"
      );
      setCustomCrmFields(sortedCustomCrmFields);
    } else {
      pushNotification("default_error");
    }
  };

  const getInternalChannelDefault = useCallback(async () => {
    const result = await dispatch(
      index({
        type: "default_channels",
      })
    );
    if (isFulfilled(result)) {
      const response = result.payload as AxiosResponse;
      if (response.data.length > 0) {
        const channel = {
          id: response.data[0].id,
          mode: PartnershipChannelMode.Default,
          slackId: response.data[0].attributes.slack_id,
        } as PartnershipChannel;
        setInternalChannelInitial(channel);
        setInternalChannelEnabled(true);
        setInternalSlackId(channel.slackId);
        setInternalChannelDefault(channel);
        return true;
      }
    } else {
      pushNotification("default_error");
    }
    return false;
  }, [dispatch, pushNotification]);

  const getInternalChannel = useCallback(
    async (hasDefault) => {
      const result = await dispatch(
        index({
          type: "partnership_channels",
          options: {
            filters: {
              partnership_id: partnership.id,
              channel_type: PartnershipChannelType.Internal,
            },
          },
        })
      );
      if (isFulfilled(result)) {
        const response = result.payload as AxiosResponse;
        if (response.data[0]) {
          const channel = {
            id: response.data[0].id,
            mode: String(response.data[0].attributes.mode),
            slackId: response.data[0].attributes.slack_id,
          } as PartnershipChannel;
          setInternalChannelInitial(channel);
          setInternalChannelEnabled(
            channel.mode !== PartnershipChannelMode.Off
          );
          setInternalSlackId(channel.slackId);
          setHasInternalChannel(true);
        } else if (hasDefault) {
          setInternalChannelEnabled(true);
        } else {
          setInternalChannelEnabled(false);
        }
      } else {
        pushNotification("default_error");
      }
      setLoadingInternal(false);
    },
    [dispatch, pushNotification, partnership]
  );

  const saveInternalChannel = useCallback(async () => {
    let result: any;
    const data = {
      type: "partnership_channels",
      attributes: {
        channel_type: PartnershipChannelType.Internal,
        mode: internalChannelEnabled
          ? internalSlackId === internalChannelDefault?.slackId
            ? PartnershipChannelMode.Default
            : PartnershipChannelMode.Custom
          : PartnershipChannelMode.Off,
        slack_id: internalSlackId,
      },
      relationships: {
        partnership: {
          data: { type: "partnerships", id: String(partnership.id) },
        },
      },
    };

    if (!hasInternalChannel) {
      result = await dispatch(
        rawPost({
          type: "partnership_channels",
          path: "",
          payload: {
            data,
          },
        })
      );
    } else {
      result = await dispatch(
        rawPatch({
          type: "partnership_channels",
          id: internalChannelInitial?.id,
          path: "",
          payload: {
            data: { ...data, id: internalChannelInitial?.id },
          },
        })
      );
    }

    if (isFulfilled(result)) {
      track("Edited partnership settings Collaboration (internal channel)", {
        partner: partner.name,
      });
      const channel = {
        id: result.payload.data.id,
        mode: String(result.payload.data.attributes.mode),
        slackId: result.payload.data.attributes.slack_id,
      } as PartnershipChannel;
      setInternalChannelInitial(channel);
      setHasInternalChannel(true);
    } else {
      pushNotification("default_error");
    }
  }, [
    dispatch,
    internalChannelEnabled,
    internalChannelDefault,
    internalChannelInitial,
    internalSlackId,
    partner,
    partnership,
    track,
    pushNotification,
    hasInternalChannel,
  ]);

  // End Internal Channel functions

  /**
   * Shared Channel functions
   */

  const getSharedChannel = useCallback(async () => {
    const result = await dispatch(
      index({
        type: "partnership_channels",
        options: {
          filters: {
            partnership_id: partnership.id,
            channel_type: PartnershipChannelType.Shared,
          },
        },
      })
    );

    if (isFulfilled(result)) {
      const response = result.payload as AxiosResponse;
      if (response.data[0]) {
        const channel = {
          id: response.data[0].id,
          mode: String(response.data[0].attributes.mode),
          slackId: response.data[0].attributes.slack_id,
        } as PartnershipChannel;
        setSharedChannelInitial(channel);
        setSharedChannelEnabled(channel.mode !== PartnershipChannelMode.Off);
        setSharedSlackId(channel.slackId);
      } else {
        setSharedChannelEnabled(false);
      }
    } else {
      pushNotification("default_error");
    }

    setLoadingShared(false);
  }, [dispatch, partnership.id, pushNotification]);

  const saveSharedChannel = useCallback(async () => {
    const data = {
      type: "partnership_channels",
      attributes: {
        channel_type: PartnershipChannelType.Shared,
        mode: sharedChannelEnabled
          ? PartnershipChannelMode.Custom
          : PartnershipChannelMode.Off,
        slack_id: sharedSlackId,
      },
      relationships: {
        partnership: {
          data: { type: "partnerships", id: String(partnership.id) },
        },
      },
    };

    let result = !sharedChannelInitial
      ? await dispatch(
          rawPost({
            type: "partnership_channels",
            path: "",
            payload: {
              data,
            },
          })
        )
      : await dispatch(
          rawPatch({
            type: "partnership_channels",
            id: sharedChannelInitial.id,
            path: "",
            payload: {
              data: { ...data, id: sharedChannelInitial.id },
            },
          })
        );

    if (isFulfilled(result)) {
      track("Edited partnership settings Collaboration (shared channel)", {
        partner: partner.name,
      });
      getSharedChannel();
    } else {
      pushNotification("default_error");
    }
  }, [
    sharedChannelEnabled,
    sharedSlackId,
    partnership.id,
    sharedChannelInitial,
    dispatch,
    track,
    partner.name,
    getSharedChannel,
    pushNotification,
  ]);

  // End Shared Channel functions

  /**
   * Notification Settings
   */

  const getProviderLogo = (id: number) => {
    const provider = integrations.find((integration) => integration.id === id)
      ?.provider;
    return crmProviders[provider as ProviderType]?.logo;
  };

  const getIntroNotificationSettings = useCallback(async () => {
    const result = await dispatch(
      rawGet({
        type: "introduction_notification_settings",
        path: `${partnership.id}/`,
      })
    );
    if (isFulfilled(result)) {
      const response = result.payload as AxiosResponse;
      const {
        owner_crm_field_names,
        should_email_account_owner,
        should_email_partnership_manager,
      } = response.data.attributes;
      setIntroNotificationSettingsInitial({
        owner_crm_field_names,
        should_email_account_owner,
        should_email_partnership_manager,
      });
      setOwnerCrmFieldNames(owner_crm_field_names);
      setShouldEmailAccountOwner(should_email_account_owner);
      setShouldEmailPartnershipManager(should_email_partnership_manager);
    } else {
      pushNotification("default_error");
    }
  }, [dispatch, partnership.id, pushNotification]);

  const saveIntroNotificationSettings = useCallback(async () => {
    const result = await dispatch(
      rawPatch({
        type: "introduction_notification_settings",
        id: partnership.id,
        path: "",
        payload: {
          data: {
            id: partnership.id,
            type: "introduction_notification_settings",
            attributes: {
              owner_crm_field_names: ownerCrmFieldNames,
              should_email_account_owner: shouldEmailAccountOwner,
              should_email_partnership_manager: shouldEmailPartnershipManager,
            },
          },
        },
      })
    );

    if (isFulfilled(result)) {
      getIntroNotificationSettings();
    } else {
      pushNotification("default_error");
    }
  }, [
    dispatch,
    getIntroNotificationSettings,
    ownerCrmFieldNames,
    partnership.id,
    pushNotification,
    shouldEmailAccountOwner,
    shouldEmailPartnershipManager,
  ]);

  // End Notification Settings

  const initialize = async () => {
    getCustomCrmFields();
    const hasDefault = await getInternalChannelDefault();
    getInternalChannel(hasDefault);
    getSharedChannel();
    getIntroNotificationSettings();
  };

  useEffect(() => {
    if (hasSlackIntegrationPayingFeature) {
      getHasConnectedSlack();
    } else {
      setHasConnectedSlack(false);
    }
  }, [hasSlackIntegrationPayingFeature]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (hasConnectedSlack !== null) {
      if (hasConnectedSlack && hasSlackIntegrationPayingFeature) {
        initialize();
      } else {
        setLoadingInternal(false);
        setLoadingShared(false);
        getIntroNotificationSettings();
      }
    }
  }, [hasConnectedSlack]); // eslint-disable-line react-hooks/exhaustive-deps

  // useEffect to save

  useEffect(() => {
    if (!saving) return;
    if (hasInternalUpdates) {
      saveInternalChannel();
    }
    if (hasSharedUpdates) {
      saveSharedChannel();
    }
    if (hasIntroNotificationSettingsUpdates) {
      saveIntroNotificationSettings();
    }
  }, [saving]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    setSaveButtonEnabled(hasUpdates);
  }, [hasUpdates, setSaveButtonEnabled]);

  useEffect(() => {
    if (saving && !hasUpdates) {
      afterSave();
      pushNotification({
        message: { ...generic.edits_saved },
        status: NotificationStatus.success,
      });
    }
  }, [saving, hasUpdates]); // eslint-disable-line react-hooks/exhaustive-deps

  return {
    loading: hasConnectedSlack === null,
    hasConnectedSlack: Boolean(hasConnectedSlack),
    hasSlackIntegrationPayingFeature,
    getProviderLogo,
    // Internal Channel
    loadingInternal,
    internalSlackId,
    setInternalSlackId,
    internalChannelEnabled,
    setInternalChannelEnabled,
    customCrmFields,
    ownerCrmFieldNames,
    setOwnerCrmFieldNames,
    // Shared Channel
    loadingShared,
    sharedSlackId,
    setSharedSlackId,
    sharedChannelEnabled,
    setSharedChannelEnabled,
    partnerOwnerShared:
      partnership.getAccountOwnerSharing(partner as Record) === ShareOwner.Full,
    shouldEmailAccountOwner,
    setShouldEmailAccountOwner,
    shouldEmailPartnershipManager,
    setShouldEmailPartnershipManager,
  };
};

// Helpers

export const getHasUpdates = (
  hasSlackIntegrationPayingFeature: boolean,
  loadingInternal: boolean,
  internalChannelEnabled: boolean,
  internalChannelInitial: PartnershipChannel | null,
  internalSlackId: string | null,
  loadingShared: boolean,
  sharedChannelEnabled: boolean,
  sharedChannelInitial: PartnershipChannel | null,
  sharedSlackId: string | null,
  introNotificationSettingsInitial: IntroNotificationSettingsInitial | null,
  shouldEmailAccountOwner: boolean,
  shouldEmailPartnershipManager: boolean,
  hasConnectedSlack: boolean,
  ownerCrmFieldNames: string[]
) => {
  const initialInternalEnabled =
    internalChannelInitial &&
    internalChannelInitial.mode !== PartnershipChannelMode.Off;
  const hasInternalUpdates =
    hasSlackIntegrationPayingFeature &&
    hasConnectedSlack &&
    (initialInternalEnabled !== internalChannelEnabled ||
      (internalChannelInitial?.slackId !== internalSlackId &&
        internalSlackId !== null));

  const initialSharedEnabled =
    sharedChannelInitial?.mode === PartnershipChannelMode.Custom;
  const hasSharedUpdates =
    hasSlackIntegrationPayingFeature &&
    hasConnectedSlack &&
    (initialSharedEnabled !== sharedChannelEnabled ||
      (sharedChannelInitial?.slackId !== sharedSlackId &&
        sharedSlackId !== null));

  const hasIntroNotificationSettingsUpdates =
    !_.isEqual(
      introNotificationSettingsInitial?.owner_crm_field_names,
      ownerCrmFieldNames
    ) ||
    introNotificationSettingsInitial?.should_email_account_owner !==
      shouldEmailAccountOwner ||
    introNotificationSettingsInitial?.should_email_partnership_manager !==
      shouldEmailPartnershipManager;

  const hasEmptyChannel =
    (internalChannelEnabled && internalSlackId === null) ||
    (sharedChannelEnabled && sharedSlackId === null);
  const disableSave = hasEmptyChannel || loadingShared || loadingInternal;
  const hasUpdates =
    (hasInternalUpdates ||
      hasIntroNotificationSettingsUpdates ||
      hasSharedUpdates) &&
    !disableSave;

  return {
    hasUpdates,
    hasInternalUpdates,
    hasSharedUpdates,
    hasIntroNotificationSettingsUpdates,
  };
};
