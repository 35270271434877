import { createAsyncThunk } from "@reduxjs/toolkit";
import { AxiosError, AxiosRequestConfig, AxiosResponse } from "axios";
import _ from "lodash";
import Record from "models/Record";
import {
  JSONAPIAttributes,
  JSONAPIIdentifier,
  JSONAPIResource,
  JSONSerializable,
  RecordType,
} from "models/types";
import JSONAPIService from "services/JSONAPIService";
import {
  JSONAPIListResponse,
  JSONAPIOptions,
  JSONAPIResponse,
  JSONAPIServiceOptions,
} from "services/types";

import { APIActions, PartnerAndOpportunityPresence } from "./typing";
import { catchKnownErrors } from "./utils";

export const retreive = createAsyncThunk(
  APIActions.retreive,
  async (parameters: {
    type: string;
    id: number;
    options?: JSONAPIOptions;
    serviceOptions?: JSONAPIServiceOptions;
  }) => {
    const service = new JSONAPIService(
      parameters.type,
      parameters.serviceOptions
    );
    const response = await service.get(parameters.id, parameters.options);
    return response.data;
  }
);

export const destroy = createAsyncThunk(
  APIActions.destroy,
  async (parameters: { type: string; id: number }) => {
    const service = new JSONAPIService(parameters.type);
    await service.delete(parameters.id);
    return { type: parameters.type, id: String(parameters.id) };
  }
);

export const create = createAsyncThunk(
  APIActions.create,
  async (
    parameters: {
      type: string;
      attributes: JSONAPIAttributes;
      relationships?: {
        [relation: string]: JSONAPIIdentifier | JSONAPIIdentifier[] | null;
      };
      options?: JSONAPIOptions;
      serviceOptions?: JSONAPIServiceOptions;
    },
    { dispatch, rejectWithValue }
  ) => {
    const service = new JSONAPIService(
      parameters.type,
      parameters.serviceOptions
    );
    const response = await service
      .create(
        parameters.attributes,
        parameters.relationships ?? {},
        parameters.options
      )
      .catch((error) => catchKnownErrors(error, dispatch, rejectWithValue));
    return response.data;
  }
);

export const update = createAsyncThunk<
  JSONAPIResponse<string>,
  {
    id: number;
    type: string;
    attributes?: JSONAPIAttributes;
    relationships?: {
      [relation: string]: JSONAPIIdentifier | JSONAPIIdentifier[] | null;
    };
    options?: JSONAPIOptions;
    serviceOptions?: JSONAPIServiceOptions;
  },
  {
    rejectValue: { code: string };
  }
>(
  APIActions.update,
  async (
    parameters: {
      id: number;
      type: string;
      attributes?: JSONAPIAttributes;
      relationships?: {
        [relation: string]: JSONAPIIdentifier | JSONAPIIdentifier[] | null;
      };
      options?: JSONAPIOptions;
      serviceOptions?: JSONAPIServiceOptions;
    },
    { rejectWithValue }
  ) => {
    const service = new JSONAPIService(
      parameters.type,
      parameters.serviceOptions
    );
    const response = await service
      .update(
        parameters.id,
        parameters.attributes ?? {},
        parameters.relationships ?? {},
        parameters.options
      )
      .catch((error) => {
        return rejectWithValue({
          code: ((error as AxiosError).response as AxiosResponse)?.data
            ?.errors?.[0]?.detail,
        });
      });
    return "data" in response ? response.data : response;
  }
);

export const rawGet = createAsyncThunk(
  APIActions.rawGet,
  async (parameters: {
    type: string;
    path: string;
    options?: { config?: AxiosRequestConfig } & JSONAPIOptions;
    serviceOptions?: JSONAPIServiceOptions;
  }) => {
    const service = new JSONAPIService(
      parameters.type,
      parameters.serviceOptions
    );
    const response = await service.rawGet(parameters.path, parameters.options);
    return response.data;
  }
);

export const rawPost = createAsyncThunk(
  APIActions.rawPost,
  async (
    parameters: {
      type: string;
      id?: number;
      path: string;
      payload: JSONSerializable;
      options?: JSONAPIOptions & { config?: AxiosRequestConfig };
      serviceOptions?: JSONAPIServiceOptions;
    },
    { dispatch, rejectWithValue }
  ) => {
    const service = new JSONAPIService(
      parameters.type,
      parameters.serviceOptions
    );
    const response = await service
      .rawPost(
        parameters.id ?? "",
        parameters.path,
        parameters.payload,
        parameters.options
      )
      .catch((error) => catchKnownErrors(error, dispatch, rejectWithValue));
    return response.data;
  }
);

export const rawPatch = createAsyncThunk(
  APIActions.rawPatch,
  async (
    parameters: {
      type: string;
      id?: number;
      path: string;
      payload: JSONSerializable;
      options?: JSONAPIOptions & { config?: AxiosRequestConfig };
      serviceOptions?: JSONAPIServiceOptions;
    },
    { dispatch, rejectWithValue }
  ) => {
    const service = new JSONAPIService(
      parameters.type,
      parameters.serviceOptions
    );
    const response = await service
      .rawPatch(
        parameters.id ?? "",
        parameters.path,
        parameters.payload,
        parameters.options
      )
      .catch((error) => catchKnownErrors(error, dispatch, rejectWithValue));
    return response.data;
  }
);

export const rawPut = createAsyncThunk(
  APIActions.rawPut,
  async (parameters: {
    type: string;
    id?: number;
    path: string;
    payload: JSONSerializable;
    options?: JSONAPIOptions & { config?: AxiosRequestConfig };
    serviceOptions?: JSONAPIServiceOptions;
  }) => {
    const service = new JSONAPIService(
      parameters.type,
      parameters.serviceOptions
    );
    const response = await service.rawPut(
      parameters.id ?? "",
      parameters.path,
      parameters.payload,
      parameters.options
    );
    return response.data;
  }
);

export const rawDelete = createAsyncThunk(
  APIActions.rawDelete,
  async (parameters: {
    type: string;
    id?: number;
    prefixPath: string;
    suffixPath: string;
    options?: JSONAPIOptions & { config?: AxiosRequestConfig; data?: any };
    serviceOptions?: JSONAPIServiceOptions;
  }) => {
    const service = new JSONAPIService(
      parameters.type,
      parameters.serviceOptions
    );
    const response = await service.rawDelete(
      parameters.id ?? "",
      parameters.prefixPath,
      parameters.suffixPath,
      parameters.options
    );
    return response.data;
  }
);

export const addRelated = createAsyncThunk(
  APIActions.addRelated,
  async (
    parameters:
      | {
          type: string;
          id: number;
          relation: string;
          related: JSONAPIIdentifier | JSONAPIIdentifier[];
          reload?: false;
        }
      | {
          type: string;
          id: number;
          relation: string;
          related: JSONAPIIdentifier | JSONAPIIdentifier[];
          reload: true;
          options?: JSONAPIOptions;
        }
  ) => {
    const service = new JSONAPIService(parameters.type);
    await service.add_related(
      parameters.id,
      parameters.relation,
      parameters.related
    );
    if (parameters.reload) {
      const response = await service.get(parameters.id, parameters.options);
      return response.data;
    }
  }
);

export const removeRelated = createAsyncThunk(
  APIActions.removeRelated,
  async (parameters: {
    type: string;
    id: number;
    relation: string;
    related: JSONAPIIdentifier[];
  }) => {
    const service = new JSONAPIService(parameters.type);
    await service.removeRelated(
      parameters.id,
      parameters.relation,
      parameters.related
    );
    return null;
  }
);

export const fetchRelated = createAsyncThunk(
  APIActions.fetchRelated,
  async (parameters: {
    type: string;
    id: number;
    relation: string;
    options?: JSONAPIOptions;
    config?: AxiosRequestConfig;
    serviceOptions?: JSONAPIServiceOptions;
  }) => {
    const service = new JSONAPIService(
      parameters.type,
      parameters.serviceOptions
    );
    const response = await service.fetchRelated(
      parameters.id,
      parameters.relation,
      parameters.options,
      parameters.config
    );
    return response.data;
  }
);

export const bulkUpdate = createAsyncThunk(
  APIActions.bulkUpdate,
  async (
    payload: { type: RecordType; id: number; attributes: JSONAPIAttributes }[]
  ) => {
    const data = payload.map(({ type, id, attributes }) => ({
      type,
      id: `${id}`,
      attributes: _.mapKeys(attributes, (_value, key) => _.snakeCase(key)),
    }));
    const service = new JSONAPIService("partner_connections");
    const response = await service.rawPatchBulk({ data });
    return response.data;
  }
);

// Currently we are not keeping the result values in store and are
// instead preserving them in the component state. Probably we are
// going to move them to the store when we have a good strategy to
// deal with loading and error states. So for now this action isn't
// consumed by any reducers. See `PartnerCell` for Pipeline table.
export const fetchPartnerAndOpportunityPresence = createAsyncThunk(
  APIActions.fetchPartnerAndOpportunityPresence,
  async (parameters: { id: number }) => {
    const service = new JSONAPIService("crm_accounts");
    const response = await service.get(parameters.id, {
      fields: {
        crm_accounts: ["partner_presence", "partner_opportunities"],
      },
    });

    const data = response.data.data;

    const relationships = data.relationships;
    const partnerPresenceData:
      | (JSONAPIResource | Record)[]
      | JSONAPIResource<string>
      | Record<string>
      | null
      | undefined = relationships?.partner_presence?.data;
    const opportunityPresenceData:
      | (JSONAPIResource | Record)[]
      | JSONAPIResource<string>
      | Record<string>
      | null
      | undefined = relationships?.partner_opportunities?.data;

    const partnerPresence: number[] =
      (_.isArray(partnerPresenceData) &&
        partnerPresenceData.map(
          (data: Record | JSONAPIResource) => +data.id
        )) ||
      [];

    const opportunityPresence: number[] =
      (_.isArray(opportunityPresenceData) &&
        opportunityPresenceData.map(
          (data: Record | JSONAPIResource) => +data.id
        )) ||
      [];

    const result: PartnerAndOpportunityPresence = {
      partnerPresence,
      opportunityPresence,
    };

    return result;
  }
);

export const index = createAsyncThunk(
  APIActions.index,
  async (parameters: {
    type: string;
    options?: JSONAPIOptions;
    serviceOptions?: JSONAPIServiceOptions;
    config?: AxiosRequestConfig;
  }) => {
    const service = new JSONAPIService(
      parameters.type,
      parameters.serviceOptions
    );
    const response = await service.index(parameters.options, parameters.config);
    return response.data;
  }
);

const DEFAULT_MAX_PAGES = 100;

export const indexAll = createAsyncThunk(
  APIActions.indexAll,
  async (parameters: {
    type: string;
    maxPages?: number;
    concurrency?: number;
    options?: JSONAPIOptions;
    onProgress?: (value: number) => void;
  }) => {
    const concurrency = parameters.concurrency ?? 1;
    const service = new JSONAPIService(parameters.type);
    const aggregate: JSONAPIListResponse = {
      data: [],
      included: [],
    };
    let responses: AxiosResponse<JSONAPIListResponse>[];
    let page = 1;
    let actualMaxPages = parameters.maxPages ?? DEFAULT_MAX_PAGES;
    let count: number;
    let links:
      | {
          next?: string | null;
        }
      | undefined
      | null;
    if (concurrency > 1) {
      const service = new JSONAPIService(parameters.type);
      const countResponse = await service.index({
        ...parameters.options,
        fields: {
          [parameters.type]: ["id"],
        },
        include: [],
        page: { size: parameters.options?.page?.size ? 1 : undefined },
      });
      if (countResponse.data.meta?.record_count !== undefined) {
        actualMaxPages = Math.min(
          actualMaxPages,
          Math.ceil(
            countResponse.data.meta?.record_count /
              (parameters.options?.page?.size ?? countResponse.data.data.length)
          )
        );
      }
    }
    while (page <= actualMaxPages) {
      responses = await Promise.all(
        _.range(page, Math.min(page + concurrency, actualMaxPages + 1)).map(
          (value) =>
            service.index({
              ...parameters.options,
              page: {
                number: value,
                size: parameters.options?.page?.size,
              },
            })
        )
      );
      responses.forEach((response) => {
        if (response.status === 200) {
          aggregate.data = [...aggregate.data, ...response.data.data];
          aggregate.included = [
            ...(aggregate.included ?? []),
            ...(response.data.included ?? []),
          ];
        }
      });
      const lastResponse = _.last(
        responses.filter((response) => response.status === 200)
      );
      if (lastResponse?.data.meta?.record_count) {
        count = lastResponse.data.meta.record_count;
        if (parameters.onProgress) {
          parameters.onProgress(Math.min(1, aggregate.data.length / count));
        }
      }
      links = lastResponse?.data.links;
      if (!lastResponse || !lastResponse.data.links?.next) {
        break;
      }
      page += concurrency;
    }
    return {
      data: aggregate.data,
      included: _.uniqBy(
        aggregate.included ?? [],
        (identifier) => `${identifier.type}.${identifier.id}`
      ),
      ...(!!links && { links }),
    };
  }
);

export const attachToRecord = createAsyncThunk(
  APIActions.attachToRecord,
  async (parameters: {
    type: string;
    recordId: number;
    path: string;
    files: { [name: string]: File };
    serviceOptions?: JSONAPIServiceOptions;
  }) => {
    const service = new JSONAPIService(parameters.type);
    const response = await service.rawUpload(
      parameters.recordId,
      parameters.path,
      parameters.files
    );
    return response.data;
  }
);
