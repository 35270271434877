import CompanyAvatar from "components/ui/avatars/CompanyAvatar";
import Tooltip from "components/ui/Tooltip";
import { T } from "components/ui/Typography";
import useUserProfile from "hooks/useUserProfile";
import { makeStyles } from "makeStyles";
import Partnership, { PartnerRepresentation } from "models/Partnership";
import Record from "models/Record";
import { defineMessages, FormattedMessage, useIntl } from "react-intl";

const ACCOUNTS_LIST_TOOLTIP_LENGTH = 10;
const PARTNERS_LIST_TOOLTIP_LENGTH = 5;

type Props = {
  recordsToRefer: Record[];
  partner?: PartnerRepresentation | null;
  selectedPartners?: Partnership[];
};

const ConfirmationTitle = ({
  recordsToRefer,
  partner,
  selectedPartners = [],
}: Props) => {
  const { classes } = useStyles();
  const intl = useIntl();
  const { profile } = useUserProfile();

  const severalAccounts = recordsToRefer.length > 1;
  const severalPartners = !Boolean(partner); // 360 Mapping does not include partner property
  const isOnePartner = selectedPartners.length === 1 || !severalPartners; // One partner selected on 360 Mapping or one partner from Account Mapping

  // Records on 360 Mapping have name property
  // instead of leftName
  const toReferLabel = severalAccounts
    ? intl.formatMessage(i18n.severalAccounts, {
        count: recordsToRefer.length,
      })
    : recordsToRefer[0]?.name ?? recordsToRefer[0]?.leftName;

  const partnerConfirmationLabel =
    selectedPartners.length > 1
      ? intl.formatMessage(i18n.countPartners, {
          count: selectedPartners.length,
        })
      : selectedPartners[0]?.getPartner(profile).name ?? partner?.name;

  const toReferList = (
    <div className={classes.tooltipList}>
      {recordsToRefer.slice(0, ACCOUNTS_LIST_TOOLTIP_LENGTH).map((account) => {
        return <span>{account.name ?? account.leftName}</span>;
      })}
      {recordsToRefer.length > ACCOUNTS_LIST_TOOLTIP_LENGTH && (
        <FormattedMessage
          {...i18n.andOthers}
          values={{
            count: recordsToRefer.length - ACCOUNTS_LIST_TOOLTIP_LENGTH,
          }}
        />
      )}
    </div>
  );

  const partnerList = (
    <div className={classes.tooltipList}>
      {selectedPartners
        .slice(0, PARTNERS_LIST_TOOLTIP_LENGTH)
        .map((partnership) => {
          const partner = partnership.getPartner(profile);
          return (
            <div className={classes.tooltipPartner}>
              <CompanyAvatar
                companyName={partner.name}
                src={partner.avatarUrl}
                size="xs"
              />
              <span className={classes.partnerName}>{partner.name}</span>
            </div>
          );
        })}
      {selectedPartners.length > PARTNERS_LIST_TOOLTIP_LENGTH && (
        <FormattedMessage
          {...i18n.andOthers}
          values={{
            count: selectedPartners.length - PARTNERS_LIST_TOOLTIP_LENGTH,
          }}
        />
      )}
    </div>
  );

  return (
    <T h3 className={classes.confirmationTitle}>
      <Tooltip
        title={recordsToRefer.length === 1 ? "" : toReferList}
        placement="top"
        arrow
      >
        <div className={classes.boldLabel}>{toReferLabel}</div>
      </Tooltip>
      <FormattedMessage
        {...i18n.referredTo}
        values={{ count: recordsToRefer.length }}
      />
      <Tooltip title={isOnePartner ? "" : partnerList} placement="top" arrow>
        <div className={classes.boldLabel}>
          {isOnePartner && (
            <CompanyAvatar
              companyName={partnerConfirmationLabel}
              src={
                selectedPartners[0]?.getPartner(profile).avatarUrl ??
                partner?.avatarUrl
              }
              size="xs"
            />
          )}
          {partnerConfirmationLabel}
        </div>
      </Tooltip>
      <FormattedMessage {...i18n.successfully} />
    </T>
  );
};

export default ConfirmationTitle;

// CSS

const useStyles = makeStyles()((theme) => ({
  confirmationTitle: {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "center",
    columnGap: 4,
    color: theme.palette.midnight500,
  },
  boldLabel: {
    display: "flex",
    flexWrap: "nowrap",
    columnGap: 4,
    fontWeight: "bold",
    color: theme.palette.midnight,
  },

  tooltipList: {
    display: "flex",
    flexDirection: "column",
    rowGap: 4,
  },
  tooltipPartner: {
    display: "flex",
    flexWrap: "nowrap",
    columnGap: 4,
  },
  partnerName: {
    margin: "4px 8px 2px 6px",
  },
}));

// I18N

const i18n = defineMessages({
  severalAccounts: {
    id: "DataTables.ActionBtn.ReferOption.ConfirmationTitle.severalAccounts",
    defaultMessage: "{count} accounts",
  },
  andOthers: {
    id: "DataTables.ActionBtn.ReferOption.ConfirmationTitle.andOthers",
    defaultMessage: "and {count, plural, one {1 other...} other {# others...}}",
  },
  referredTo: {
    id: "DataTables.ActionBtn.ReferOption.ConfirmationTitle.referredTo",
    defaultMessage: "{count, plural, one {has} other {have}} been referred to",
  },
  successfully: {
    id: "DataTables.ActionBtn.ReferOption.ConfirmationTitle.successfully",
    defaultMessage: "successfully!",
  },
  countPartners: {
    id: "DataTables.ActionBtn.ReferOption.ConfirmationTitle.countPartners",
    defaultMessage: "{count} partners",
  },
});
