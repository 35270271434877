import KeyContactIcon from "@mui/icons-material/HowToReg";
import _ from "lodash";
import { useContext, useState } from "react";
import {
  defineMessages,
  FormattedHTMLMessage,
  FormattedMessage,
} from "react-intl";
import ViewContext from "screens/Frontoffice/screens/DataTables/screens/AccountMapping/contexts/ViewContext";
import { AccountMappingStandardFieldName } from "screens/Frontoffice/screens/DataTables/screens/AccountMapping/shared/types";
import JSONAPIService from "services/JSONAPIService";

import { CellProps } from "../utils";
import BaseContactsCell from "./BaseContactsCell";
import { useStyles } from "./BaseContactsCell";
import { ContactType } from "./types";

const RightContactsCell = ({ fieldName, value, row }: CellProps) => {
  const { classes } = useStyles({ value: undefined });
  const [contacts, setContacts] = useState([]);
  const [unspecifiedText, setUnspecifiedText] = useState("");
  const { accountType } = useContext(ViewContext);
  const { scope, keyContact, contactPrefix } = getValues(fieldName);
  const countValue = _.get(row, `${contactPrefix}Count`, value);

  const getLabel = () => {
    if (scope === "all") {
      return i18n.allTitle;
    } else if (scope === "common") {
      return keyContact ? i18n.overlappingKeyTitle : i18n.commonTitle;
    } else if (scope === "other") {
      return keyContact ? i18n.nonOverlappingKeyTitle : i18n.otherTitle;
    } else {
      return "";
    }
  };

  const getUnspecifiedText = (contactList: $TSFixMe) => {
    const unspecifiedKeyContact = contactList.filter(
      (item: $TSFixMe) => !Boolean(item.jobtitle) && Boolean(item.opports)
    ).length;
    const otherUnspecified = contactList.filter(
      (item: $TSFixMe) => !Boolean(item.jobtitle) && !Boolean(item.opports)
    ).length;

    return (
      <div className={classes.unspecifiedContactList}>
        {Boolean(unspecifiedKeyContact) && (
          <div className={classes.contactRow}>
            <FormattedMessage
              {...i18n.unspecifiedKeyContact}
              values={{ value: unspecifiedKeyContact }}
            />
            <KeyContactIcon className={classes.keyContactIcon} />
          </div>
        )}
        {!keyContact && (
          <div className={classes.contactRow}>
            {Boolean(otherUnspecified) && (
              <FormattedMessage
                {...i18n.unspecified}
                values={{ value: otherUnspecified }}
              />
            )}
          </div>
        )}
      </div>
    );
  };

  const loadContacts = () => {
    if (("" + row.id).startsWith("demo")) {
      let demoContacts = require("data/demoContacts.json");
      if (keyContact) {
        demoContacts = demoContacts.filter(({ opports }: $TSFixMe) =>
          Boolean(opports)
        );
      }
      demoContacts = demoContacts.slice(0, countValue);
      setContacts(demoContacts);
      // @ts-expect-error
      setUnspecifiedText(getUnspecifiedText(demoContacts));
    } else if (row.type === "matched_accounts") {
      const contacts = _.get(row, `${contactPrefix}Toplist`, []).map(
        (contact: ContactType) => ({
          ...contact,
          opports: contact.opportunity_count,
        })
      );
      setContacts(contacts);
      // @ts-expect-error
      setUnspecifiedText(getUnspecifiedText(contacts));
    } else {
      const service = new JSONAPIService(accountType);
      service
        .get(row.id, {
          filters: {
            partnership: row.partnershipId,
          },
          fields: {
            [accountType]: ["right_contacts_data"],
          },
        })
        .then(({ data }: $TSFixMe) => {
          let contacts = data.data.attributes.right_contacts_data;
          if (keyContact) {
            contacts = contacts.filter(({ opports }: $TSFixMe) =>
              Boolean(opports)
            );
          }
          if (scope === "common") {
            contacts = contacts.filter(({ overlaps }: $TSFixMe) => overlaps);
          } else if (scope === "other") {
            contacts = contacts.filter(({ overlaps }: $TSFixMe) => !overlaps);
          }
          setContacts(contacts);
          // @ts-expect-error
          setUnspecifiedText(getUnspecifiedText(contacts));
        });
    }
  };

  return (
    <BaseContactsCell
      value={countValue}
      label={
        <FormattedHTMLMessage
          {...getLabel()}
          values={{ value: countValue, accountName: row.leftName }}
        />
      }
      contacts={contacts}
      loadContacts={loadContacts}
      unspecifiedText={unspecifiedText}
    />
  );
};

export default RightContactsCell;

// Helpers

export const isRightContactsCell = ({ fieldName }: CellProps) => {
  return [
    "rightContactCount",
    "rightExtraContacts",
    "rightContactOverlap",
    "rightExtraKeyContacts",
    "rightKeyContactOverlap",
    "rightExtraContactCount",
    "rightContactOverlapCount",
    "rightExtraDealInfluencerCount",
    "rightDealInfluencerOverlapCount",
  ].includes(fieldName);
};

const getValues = (fieldName: string) => {
  let scope, keyContact, contactPrefix;
  switch (fieldName) {
    case AccountMappingStandardFieldName.rightContactCount:
      scope = "all";
      keyContact = false;
      contactPrefix = "rightContact";
      break;
    case AccountMappingStandardFieldName.rightExtraContacts:
    case AccountMappingStandardFieldName.rightExtraContactCount:
      scope = "other";
      keyContact = false;
      contactPrefix = "rightExtraContact";
      break;
    case AccountMappingStandardFieldName.rightContactOverlap:
    case AccountMappingStandardFieldName.rightContactOverlapCount:
      scope = "common";
      keyContact = false;
      contactPrefix = "rightContactOverlap";
      break;
    case AccountMappingStandardFieldName.rightExtraKeyContacts:
    case AccountMappingStandardFieldName.rightExtraDealInfluencerCount:
      scope = "other";
      keyContact = true;
      contactPrefix = "rightDealInfluencerOverlap";
      break;
    case AccountMappingStandardFieldName.rightKeyContactOverlap:
    case AccountMappingStandardFieldName.rightDealInfluencerOverlapCount:
      scope = "common";
      keyContact = true;
      contactPrefix = "rightExtraDealInfluencer";
      break;
  }
  return { scope, keyContact, contactPrefix };
};

// I18N

const i18n = defineMessages({
  allTitle: {
    id: "ui.datagrid.cellRenderers.RightContactsCell.allTitle",
    defaultMessage:
      "{value, plural, one {1 contact at <b>{accountName}:</b>} other {# contacts at <b>{accountName}:</b>}}",
  },
  commonTitle: {
    id: "ui.datagrid.cellRenderers.RightContactsCell.commonTitle",
    defaultMessage:
      "{value, plural, one {1 common contact at <b>{accountName}:</b>} other {# common contacts at <b>{accountName}:</b>}}",
  },
  otherTitle: {
    id: "ui.datagrid.cellRenderers.RightContactsCell.otherTitle",
    defaultMessage:
      "{value, plural, one {1 other contact at <b>{accountName}:</b>} other {# other contacts at <b>{accountName}:</b>}}",
  },
  overlappingKeyTitle: {
    id: "ui.datagrid.cellRenderers.RightContactsCell.overlappingKeyTitle",
    defaultMessage:
      "{value, plural, one {1 known deal influencer at <b>{accountName}:</b>} other {# known deal influencers at <b>{accountName}:</b>}}",
  },
  nonOverlappingKeyTitle: {
    id: "ui.datagrid.cellRenderers.RightContactsCell.nonOverlappingKeyTitle",
    defaultMessage:
      "{value, plural, one {1 other deal influencer at <b>{accountName}:</b>} other {# other deal influencers at <b>{accountName}:</b>}}",
  },
  unspecified: {
    id: "ui.datagrid.cellRenderers.RightContactsCell.unspecified",
    defaultMessage: "{value} unspecified",
  },
  unspecifiedKeyContact: {
    id: "ui.datagrid.cellRenderers.RightContactsCell.unspecifiedKeyContact",
    defaultMessage:
      "{value, plural, one {1 unspecified deal influencer} other {# unspecified deal influencers}}",
  },
});
