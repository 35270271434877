import { CircularProgress, Grid } from "@mui/material";
import { Plus } from "components/icons";
import Button from "components/ui/Button";
import { makeStyles } from "makeStyles";
import { useContext } from "react";
import { defineMessages } from "react-intl";
import MappingFilterContext from "screens/Frontoffice/screens/DataTables/shared/contexts/MappingFilterContext";

type SmartViewDropdownFooterProps = {
  isLoading: boolean;
  handleClose: (event: React.MouseEvent) => void;
};

export const SmartViewDropdownFooter = ({
  isLoading,
  handleClose,
}: SmartViewDropdownFooterProps) => {
  const { classes } = useStyles();
  const { setOpenedWidget } = useContext(MappingFilterContext);

  const handleCreateNewView = (event: React.MouseEvent) => {
    setOpenedWidget("filter");
    handleClose(event);
  };

  if (isLoading) {
    return (
      <>
        <Grid item container className={classes.justifyCenter}>
          <CircularProgress size={12} />
        </Grid>
      </>
    );
  }
  return (
    <>
      <Button
        size="small"
        LeftIcon={Plus}
        label={i18n.createNewView}
        onClick={(event: React.MouseEvent) => handleCreateNewView(event)}
      />
    </>
  );
};

// CSS

const useStyles = makeStyles()((theme) => ({
  justifyCenter: {
    justifyContent: "center",
  },
}));

// I18N

const i18n = defineMessages({
  createNewView: {
    defaultMessage: "Create new view",
    id: "SmartViewDropdown.createNewView",
  },
});
