import { getValidatedEnvironment } from "config/schema";
import { makeStyles } from "makeStyles";

import OktaButton from "./OktaButton";

const googleOktaIDP = getValidatedEnvironment().okta?.googleIdpId;
const googleColor = "#4285F4";

const useStyles = makeStyles()((theme) => ({
  root: {
    borderColor: googleColor,
    backgroundColor: googleColor,
    display: "flex",
    padding: 0,
    alignItems: "center",
  },
  iconContainer: {
    // @ts-expect-error ts-migrate(2339) FIXME: Property 'white' does not exist on type 'Color'.
    backgroundColor: theme.palette.grey.white,
    padding: theme.spacing(1),
  },
  icon: {
    height: 18,
    width: 18,
  },
  label: {
    paddingLeft: theme.spacing(2),
    padding: theme.spacing(1),
    fontFamily: "Roboto",
    fontSize: 14,
    // @ts-expect-error ts-migrate(2339) FIXME: Property 'white' does not exist on type 'Color'.
    color: theme.palette.grey.white,
  },
}));

const OktaGoogleButton = () => {
  const { classes } = useStyles();
  return (
    // @ts-expect-error ts-migrate(2322) FIXME: Type 'string | undefined' is not assignable to typ... Remove this comment to see the full error message
    <OktaButton idp={googleOktaIDP} className={classes.root}>
      <div className={classes.iconContainer}>
        <img
          className={classes.icon}
          src="/images/brands/google-logo.png"
          alt=""
        />
      </div>
      <div className={classes.label}>Sign in with Google</div>
    </OktaButton>
  );
};

export default OktaGoogleButton;
