import { Grid } from "@mui/material";
import { T } from "components/ui/Typography";
import { makeStyles } from "makeStyles";

export const SmartViewDropdownHeader = ({ text }: { text: string }) => {
  const { classes } = useStyles();

  return (
    <Grid item className={classes.container}>
      <T className={classes.text}>{text}</T>
    </Grid>
  );
};

// CSS

const useStyles = makeStyles()((theme) => ({
  container: {
    height: 32,
    padding: "8px 6px",
  },
  text: {
    color: theme.palette.midnight,
    fontWeight: 600,
    fontSize: 12,
    lineHeight: "18px",
  },
}));
