import { createSelector } from "@reduxjs/toolkit";
import SuggestedPartner from "models/SuggestedPartner";
import { RevealStore } from "redux/typing";

export const selectSuggestions = createSelector(
  (state: RevealStore) => state.api.entities["suggested_partners"] ?? {},
  (state: RevealStore) => state.suggestedPartners,
  (suggestions, { hasMore, status, suggestionIds }) => {
    return {
      hasMore,
      suggestions: suggestionIds
        .map((id: number) => suggestions[id])
        .filter(Boolean) as SuggestedPartner[],
      status,
    };
  }
);
