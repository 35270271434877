import {
  array,
  constant,
  guard,
  iso8601,
  number,
  object,
  string,
} from "decoders";
import {
  FilterType,
  PersistedColumnType,
  SortType,
} from "screens/Frontoffice/screens/DataTables/shared/types";
import {
  columnDecoder,
  filterDecoder,
  sortDecoder,
} from "screens/Frontoffice/screens/DataTables/shared/types/decoders";

import Record from "./Record";
import { JSONAPIResourceOrRecord } from "./types";

const apiMyEcosystemViewDecoder = object({
  type: constant("my_ecosystem_views" as const),
  id: string,
  attributes: object({
    columns: array(columnDecoder),
    filter_order_list: array(number),
    filters: array(filterDecoder),
    sort: array(sortDecoder),
    created_at: iso8601,
    updated_at: iso8601,
  }),
});

const apiMyEcosystemViewGuard = guard(apiMyEcosystemViewDecoder);

class MyEcosystemView extends Record<"my_ecosystem_views"> {
  columns: PersistedColumnType[];
  filterOrderList: number[];
  filters: FilterType[];
  sort: SortType[];

  constructor(resource: JSONAPIResourceOrRecord<"my_ecosystem_views">) {
    super(resource);
    const { attributes } = apiMyEcosystemViewGuard(resource);
    this.columns = attributes.columns;
    this.filterOrderList = attributes.filter_order_list;
    this.filters = attributes.filters;
    this.sort = attributes.sort;
  }
}

export default MyEcosystemView;
