export enum MatchSortOrder {
  ORDER_ASC = "asc",
  ORDER_DESC = "desc",
}

export enum MatchFilterType {
  IS = "is",
  IS_NOT = "is_not",
  ANY_OF = "any_of",
  NONE_OF = "none_of",
  CONTAINS_ANY = "contains_any_of",
  CONTAINS_EXACT = "contains_exact",
  CONTAINS_AT_LEAST = "contains_at_least",
  CONTAINS = "contains",
  DOES_NOT_CONTAIN = "does_not_contain",
  GT = "gt",
  LT = "lt",
  GTE = "gte",
  LTE = "lte",
  _NO_OPERATOR = "",
  _IN_OPERATOR = "in",
}

export enum MatchFieldType {
  UNKNOWN = 0,
  TEXT = 1,
  PICKLIST = 2,
  BOOLEAN = 3,
  NUMBER = 4,
  INTEGER = 5,
  DATE = 6,
  DATETIME = 7,
  CURRENCY = 8,
  USER = 9,
  SINGLE_CHOICE_PICKLIST = 10,
  REFERENCES_USER = 11,
  REFERENCES_ACCOUNT = 12,
  REFERENCES_OPPORTUNITY = 13,
  REFERENCES_CONTACT = 14,
  MULTI_PICKLIST = 15,
}

export enum DataTableType {
  ACCOUNT_MAPPING = "Account Mapping",
  MAPPING_360 = "360 Mapping",
  ANALYTICS = "Analytics",
  COLLABORATE = "Pipeline",
  ATTRIBUTE = "Attribute",
  DIRECTORY = "Directory",
}

export enum DetailedViewType {
  ACCOUNT_DRAWER = "Single Account Drawer",
}

export type ViewType = DataTableType | DetailedViewType;
