import { CloseRounded } from "@mui/icons-material";
import { Grid, Step, StepLabel, Stepper } from "@mui/material";
import Button from "components/ui/Button";
import { ProviderType } from "config/constants";
import getFirstValueFromURL from "helpers/getFirstValueFromURL";
import usePushNotification from "hooks/usePushNotification";
import { get, isEmpty } from "lodash";
import { makeStyles } from "makeStyles";
import CrmCredential from "models/CrmCredential";
import Integration from "models/Integration";
import Partnership, { PartnershipStatus } from "models/Partnership";
import { ComponentType, useEffect, useState } from "react";
import { defineMessages, FormattedMessage } from "react-intl";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { APICollection } from "redux/api/typing";
import { RevealStore } from "redux/typing";
import { selectHasSources } from "redux/user/selectors";
import { DialogWithSidePanel } from "screens/Frontoffice/shared/components/DialogWithSidePanel";
import { generateNotification } from "screens/Frontoffice/shared/components/SourceSettingsDialog/helpers/utils";
import {
  CRMFileProviderType,
  isCrmFileType,
  isCrmProviderType,
} from "screens/Frontoffice/shared/helpers/types";

import {
  ConnectCrmStep,
  crmFileProviderLabelAndLogos,
  crmProviders,
} from "../../../constants";
import {
  AssignmentContent,
  ColumnMappingContent,
  SourceActivationContent,
  SourceSelectContent,
} from "./components/contents";
import { StatusRulesContent } from "./components/contents/StatusRulesContent";
import { AssignmentSidePanel } from "./components/SidePanels/AssignmentSidePanel";
import { CsvSidePanel } from "./components/SidePanels/CsvSidePanel";
import { GoogleSheetsSidePanel } from "./components/SidePanels/GoogleSheetsSidePanel";
import { SourceActivationSidePanel } from "./components/SidePanels/SourceActivationSidePanel";
import { SourceSelectSidePanel } from "./components/SidePanels/SourceSelectSidePanel";
import { StatusRulesSidePanel } from "./components/SidePanels/StatusRulesSidePanel";

type Props = {
  handleClose: () => void;
  isOpen: boolean;
  refresh: () => void;
};

const useShowAssignmentTab = () => {
  const location = useLocation();
  const hasPreviousSources = useSelector(selectHasSources);
  const integrationId = getFirstValueFromURL(location, "integration");
  const partnerships = useSelector((state: RevealStore) =>
    get(state, "api.entities.partnerships")
  ) as APICollection<Partnership>;
  const integrations = useSelector((state: RevealStore) =>
    get(state, "api.entities.integrations")
  ) as APICollection<Integration>;
  const crmCredentials = useSelector((state: RevealStore) =>
    get(state, "api.entities.crm_credentials")
  ) as APICollection<CrmCredential>;

  const hasActivePartnerhsips = Object.values(partnerships ?? {}).some(
    (record) => record.status === PartnershipStatus.Accepted
  );

  if (!hasActivePartnerhsips) {
    return false;
  }
  if (integrationId) {
    return (
      !isEmpty(
        Object.values(integrations ?? {}).filter(
          (item) => item.id !== +integrationId
        )
      ) ||
      !isEmpty(
        Object.values(crmCredentials ?? {}).filter(
          (item) => item.integrationId !== +integrationId
        )
      )
    );
  } else {
    return hasPreviousSources;
  }
};

export const ConnectCrmDialog = ({ handleClose, isOpen, refresh }: Props) => {
  const location = useLocation();
  const pushNotification = usePushNotification();
  const integrationId = getFirstValueFromURL(location, "integration");
  const provider = getFirstValueFromURL(location, "provider");
  const isNew = getFirstValueFromURL(location, "init") === "true";
  const isUpdated = getFirstValueFromURL(location, "updated") === "true";

  const showAssignment = useShowAssignmentTab();
  const { classes } = useStyles({ showAssignment, provider });
  const [step, setStep] = useState<ConnectCrmStep>(ConnectCrmStep.SourceSelect);
  const crmProvider =
    provider && isCrmProviderType(provider)
      ? crmProviders[provider]
      : crmFileProviderLabelAndLogos[provider as CRMFileProviderType];

  useEffect(() => {
    if (location.hash === "#status-rules") {
      setStep(ConnectCrmStep.StatusRules);
    } else if (location.hash === "#column-mapping") {
      setStep(ConnectCrmStep.ColumnMapping);
    } else if (location.hash === "#assignment" && showAssignment) {
      setStep(ConnectCrmStep.Assignment);
    } else if (integrationId) {
      setStep(ConnectCrmStep.SourceActivation);
    } else {
      setStep(ConnectCrmStep.SourceSelect);
    }
  }, [location, integrationId, showAssignment]);

  const pages = {
    [ConnectCrmStep.SourceSelect]: [SourceSelectSidePanel, SourceSelectContent],
    [ConnectCrmStep.ColumnMapping]: [
      provider === ProviderType.gsheet ? GoogleSheetsSidePanel : CsvSidePanel,
      ColumnMappingContent,
    ],
    [ConnectCrmStep.SourceActivation]: [
      SourceActivationSidePanel,
      SourceActivationContent,
    ],
    [ConnectCrmStep.Assignment]: [AssignmentSidePanel, AssignmentContent],
    [ConnectCrmStep.StatusRules]: [StatusRulesSidePanel, StatusRulesContent],
  };
  const SidePanel = pages[step][0] as ComponentType;
  const Content = pages[step][1] as ComponentType<{ onClose: () => void }>;

  const handleCloseWithRefresh = () => {
    if (isUpdated) {
      refresh();
      setTimeout(() => {
        // to avoid the click action self close incoming notification
        pushNotification(
          generateNotification(provider as ProviderType, isNew),
          {
            provider: crmProvider?.name,
          }
        );
      }, 0);
    }
    handleClose();
  };

  return (
    <DialogWithSidePanel
      isOpen={isOpen}
      onClose={handleCloseWithRefresh}
      sidePanel={<SidePanel />}
      isLight
    >
      <div className={classes.container}>
        <Grid container>
          <Grid item flexGrow={1}>
            <Stepper
              activeStep={Object.keys(ConnectCrmStep).indexOf(step)}
              className={classes.stepper}
            >
              {Object.values(ConnectCrmStep)
                .filter((stepItem) =>
                  showAssignment ? true : stepItem !== ConnectCrmStep.Assignment
                )
                .filter((stepItem) =>
                  isCrmFileType(provider)
                    ? true
                    : stepItem !== ConnectCrmStep.ColumnMapping
                )
                .map((stepItem, index) => {
                  const stepProps: { completed?: boolean } = {};
                  const labelProps: {
                    optional?: React.ReactNode;
                  } = {};
                  stepProps.completed =
                    index < Object.keys(ConnectCrmStep).indexOf(step);
                  return (
                    <Step key={stepItem} {...stepProps}>
                      <StepLabel
                        {...labelProps}
                        classes={{
                          iconContainer: classes.iconContainer,
                          label: classes.stepLabel,
                        }}
                      >
                        <FormattedMessage
                          {...CONNECT_CRM_STEP_TO_DISPLAY_LABELS[stepItem]}
                        />
                      </StepLabel>
                    </Step>
                  );
                })}
            </Stepper>
          </Grid>
          <Grid item>
            <Button
              LeftIcon={CloseRounded}
              onClick={handleCloseWithRefresh}
              size="small"
              variant="tertiary"
            />
          </Grid>
        </Grid>
        <div className={classes.stepperDivider} />
        <div className={classes.content}>
          <Content onClose={handleCloseWithRefresh} />
        </div>
      </div>
    </DialogWithSidePanel>
  );
};

const useStyles = makeStyles<{ showAssignment: boolean; provider?: string }>()(
  (theme, { showAssignment, provider }) => ({
    container: {
      display: "flex",
      flexDirection: "column",
      width: "100%",
    },
    content: {
      padding: theme.spacing(5),
    },
    dialogActions: {
      justifyContent: "center",
    },
    iconContainer: {
      "& .MuiStepIcon-root circle": {
        fill: theme.palette.alpha200,
        r: 9,
      },
      "&.Mui-completed svg": {
        fontSize: 18,
        color: theme.palette.green400,
      },
      "& .MuiStepIcon-root.Mui-active circle": {
        fill: theme.palette.greyscale100,
        stroke: theme.palette.midnight,
      },
      "& .MuiStepIcon-root text": {
        fill: theme.palette.alpha500,
        fontFamily: "Inter",
        fontSize: 9,
        fontWeight: 500,
      },
      "& .MuiStepIcon-root.Mui-active text": {
        fill: theme.palette.text.primary,
      },
    },
    stepper: {
      margin: "auto",
      maxWidth: 450 + 150 * (+showAssignment + +isCrmFileType(provider)),
    },
    stepperDivider: {
      position: "relative",
      right: theme.spacing(2.5),
      width: `calc(100% + (${theme.spacing(2.5)}*2))`,
      height: "1px",
      background: theme.palette.alpha200,
      marginTop: theme.spacing(2.5),
    },
    stepLabel: {
      fontFamily: "Inter",
      fontSize: 12,
    },
  })
);

const i18n = defineMessages({
  assignmentTab: {
    id: "ConnectCrmDialog.assignmentTab",
    defaultMessage: "Assignment",
  },
  columnMappingTab: {
    id: "ConnectCrmDialog.columnMappingTab",
    defaultMessage: "Column Mapping",
  },
  sourceSelectTab: {
    id: "ConnectCrmDialog.sourceSelectTab",
    defaultMessage: "Source Select",
  },
  statusRulesTab: {
    id: "ConnectCrmDialog.statusRulesTab",
    defaultMessage: "Status Rules",
  },
  sourceActivationTab: {
    id: "ConnectCrmDialog.sourceActivationTab",
    defaultMessage: "Source Activation",
  },
  summary: {
    id: "ConnectCrmDialog.summary",
    defaultMessage:
      "Please help us understand how you define Customers, Prospects and Partners in your {provider}.",
  },
});

const CONNECT_CRM_STEP_TO_DISPLAY_LABELS = {
  [ConnectCrmStep.SourceSelect]: i18n.sourceSelectTab,
  [ConnectCrmStep.ColumnMapping]: i18n.columnMappingTab,
  [ConnectCrmStep.StatusRules]: i18n.statusRulesTab,
  [ConnectCrmStep.Assignment]: i18n.assignmentTab,
  [ConnectCrmStep.SourceActivation]: i18n.sourceActivationTab,
};
