import Grid from "@mui/material/Grid";
import CompanyAvatar from "components/ui/avatars/CompanyAvatar";
import BaseLoginPage from "components/ui/BaseLoginPage";
import { T } from "components/ui/Typography";
import useUserProfile from "hooks/useUserProfile";
import { makeStyles } from "makeStyles";
import User from "models/User";
import { defineMessages, FormattedHTMLMessage } from "react-intl";
import { RevealStore } from "redux/typing";
import UserService from "services/UserService";

import CompanyFormDetails from "./CompanyFormDetails";

export const validateCompanyDetails = (profile: User, store: RevealStore) => {
  const service = new UserService();
  if (
    !service.isImpersonating &&
    (Boolean(profile.isCompanyAdmin()) || profile.canManagePartnerships)
  ) {
    const company = profile.company;
    if (!company.size || !company.ecosystem || !company.businessType) {
      return false;
    }
  }
  return true;
};

export const OnboardingPartnersCompanyDetails = () => {
  const { classes } = useStyles();
  const { profile } = useUserProfile();

  const leftPanelContent = (
    <>
      <Grid item xs={6}>
        <div className={classes.logoContainer}>
          <img src="/images/onboarding/Gdpr.png" alt="" />
          <T h4 className={classes.logoText}>
            <FormattedHTMLMessage {...i18n.gdprText} />
          </T>
        </div>
      </Grid>
      <Grid item xs={6}>
        <div className={classes.logoContainer}>
          <img src="/images/onboarding/Soc.png" alt="" />
          <T h4 className={classes.logoText}>
            <FormattedHTMLMessage {...i18n.socText} />
          </T>
        </div>
      </Grid>
    </>
  );

  return (
    <BaseLoginPage
      content={leftPanelContent}
      title={i18n.leftPanelTitle}
      titleSmall={i18n.leftPanelTitle}
      titleVariant="small"
    >
      <Grid
        container
        direction="column"
        alignContent="center"
        className={classes.root}
      >
        <Grid item className={classes.logo}>
          <CompanyAvatar size="xl" company={profile.company} />
        </Grid>
        <Grid item>
          <T h2 bold textAlign="center">
            {profile.company.name}
          </T>
        </Grid>
        <Grid item className={classes.marginBottom}>
          <T textAlign="center">
            <FormattedHTMLMessage {...i18n.subtitle} />
          </T>
        </Grid>
        <Grid item>
          <CompanyFormDetails company={profile.company} />
        </Grid>
      </Grid>
    </BaseLoginPage>
  );
};

export default OnboardingPartnersCompanyDetails;

// I18N

const i18n = defineMessages({
  gdprText: {
    id: "onboarding.companyDetails.gdprText",
    defaultMessage: "Fully GDPR & CCPA<br/>compliant",
  },
  leftPanelTitle: {
    id: "onboarding.companyDetails.leftPanelTitle",
    defaultMessage: "Reveal is secured<br/>by design",
  },
  socText: {
    id: "onboarding.companyDetails.socText",
    defaultMessage: "SOC II<br/>Exception-free",
  },
  subtitle: {
    id: "onboarding.companyDetails.subtitle",
    defaultMessage: "Let other Reveal users find you easily",
  },
});

// CSS

const useStyles = makeStyles()((theme) => ({
  logoContainer: {
    background: theme.palette.greyscale900,
    borderRadius: 8,
    textAlign: "center",
    padding: theme.spacing(3),
    "& > img": {
      maxWidth: "100%",
      maxHeight: 125,
    },
  },
  logoText: {
    color: theme.palette.offWhite,
    marginTop: theme.spacing(3.5),
  },
  marginBottom: {
    marginBottom: theme.spacing(2),
  },
  root: {
    width: 360,
    rowGap: "4px",
    [theme.breakpoints.down("md")]: {
      width: "100%",
    },
  },
  logo: {
    display: "flex",
    justifyContent: "center",
    margin: 16,
  },
}));
