import CloseIcon from "@mui/icons-material/Close";
import { isRejected } from "@reduxjs/toolkit";
import { Plus } from "components/icons";
import CompanyAvatar from "components/ui/avatars/CompanyAvatar";
import Button from "components/ui/Button";
import { T } from "components/ui/Typography";
import usePushNotification from "hooks/usePushNotification";
import useSegment from "hooks/useSegment";
import { makeStyles } from "makeStyles";
import SuggestedPartner from "models/SuggestedPartner";
import { parse, stringify } from "query-string";
import { forwardRef } from "react";
import { useDispatch } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import { dismissSuggestion } from "redux/suggestedPartners/thunks";
import { PartnersPageSuggestionsEvent } from "tracking";

type Props = {
  suggestedPartner: SuggestedPartner;
};

const SuggestedPartnerTile = forwardRef<$TSFixMe, Props>(
  ({ suggestedPartner }: Props, parentRef) => {
    const dispatch = useDispatch();
    const { classes } = useStyles();
    const history = useHistory();
    const location = useLocation();
    const pushNotification = usePushNotification();
    const { track } = useSegment();

    enum AnswerType {
      invite = 1,
      dismiss = 2,
    }

    const raiseUnhandledResponse = (value: never): never => {
      throw new TypeError("Unhandled answer type");
    };

    const answer = async (value: AnswerType) => {
      switch (value) {
        case AnswerType.invite:
          const { key, search, ...state } = location;
          const currentSearch = parse(search);
          currentSearch["suggested-partner"] = "" + suggestedPartner.id;
          history.push({
            ...state,
            search: stringify(currentSearch),
            hash: "#invite-partner",
          });
          track(PartnersPageSuggestionsEvent.CLICKED_INVITE, {
            invitedCompanyId: suggestedPartner.id,
            source: suggestedPartner.source,
          });
          return;
        case AnswerType.dismiss:
          const resultAction = await dispatch(
            dismissSuggestion({ id: suggestedPartner.id })
          );
          if (isRejected(resultAction)) {
            pushNotification("default_error");
            return;
          }
          track(PartnersPageSuggestionsEvent.DISMISSED_INVITE, {
            invitedCompanyId: suggestedPartner.id,
            source: suggestedPartner.source,
          });
          return;
        default:
          raiseUnhandledResponse(value);
      }
    };

    if (!suggestedPartner) {
      return null;
    }

    return (
      <div ref={parentRef} className={classes.root}>
        <div className={classes.container}>
          <div className={classes.nameContainer}>
            <CompanyAvatar
              companyName={suggestedPartner.partnerName}
              src={suggestedPartner.partnerLogo}
              size="xs"
            />
            <T
              title={suggestedPartner.partnerName}
              className={classes.twoLineEllipsis}
            >
              {suggestedPartner.partnerName}
            </T>
          </div>

          <div className={classes.actionsContainer}>
            <Button
              label=""
              variant="quinary"
              size="xSmall"
              LeftIcon={CloseIcon}
              onClick={() => answer(AnswerType.dismiss)}
              data-testid="dismiss-btn"
            />
            <Button
              label=""
              variant="tertiary"
              size="xSmall"
              LeftIcon={Plus}
              onClick={() => answer(AnswerType.invite)}
              data-testid="invite-btn"
            />
          </div>
        </div>
      </div>
    );
  }
);

export const useStyles = makeStyles()((theme) => ({
  root: {
    width: 166,
    height: 32,
  },
  container: {
    borderRadius: 6,
    padding: 4,
    display: "flex",
    height: "100%",
    position: "relative",
    backgroundColor: theme.palette.ivory,
    alignItems: "center",
    justifyContent: "space-between",
  },
  nameContainer: {
    display: "flex",
    columnGap: 6,
    alignItems: "center",
    maxWidth: 106,
  },
  actionsContainer: {
    display: "flex",
    columnGap: 2,
  },
  twoLineEllipsis: {
    overflow: "hidden",
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
  },
}));

export default SuggestedPartnerTile;
