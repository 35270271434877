import Box from "@mui/material/Box";
import Tooltip from "components/ui/Tooltip";
import { T } from "components/ui/Typography";
import { ConfigContext } from "config/ConfigProvider";
import generic from "i18n/generic";
import _ from "lodash";
import { makeStyles } from "makeStyles";
import { useContext } from "react";
import {
  defineMessages,
  FormattedHTMLMessage,
  FormattedMessage,
  FormattedNumber,
  MessageDescriptor,
} from "react-intl";
import { AvailableAnalytisColumns } from "redux/analytics/defaults";
import { i18n as analyticsTooltips } from "screens/Frontoffice/screens/DataTables/screens/Analytics/constants";

import { CellProps } from "../../utils";

type StyleProps = {
  hasValue: boolean;
  isNegative: boolean;
};

export enum BoostCellVariant {
  DealSize = "dealSize",
  WinRate = "winRate",
}

type BoostCellProps = CellProps & {
  variant: BoostCellVariant;
};

const BoostCell = ({ row, fieldName, variant }: BoostCellProps) => {
  const config = useContext(ConfigContext);
  const boostValue = _.get(row, `metrics.${fieldName}`, null);
  const hasInsufficientData = _.get(
    row,
    `metrics.insufficient_data_to_compute_${_.snakeCase(variant)}_boost`,
    null
  );
  const isNegative = boostValue < 0;
  const hasNoChange = !hasInsufficientData && !boostValue;
  const hasValue = !hasInsufficientData && !hasNoChange;
  const { classes } = useStyles({ hasValue, isNegative });

  const tooltip = (
    <T>
      <FormattedHTMLMessage
        {...(analyticsTooltips as Record<string, MessageDescriptor>)[
          `${variant}Tooltip`
        ]}
        values={{
          partnerName: row.partnerCompanyName,
          isNegative,
          value: Math.round((boostValue ?? 0) * 100),
        }}
      />
      <span>
        &nbsp;
        <a
          className={classes.tooltipLink}
          target="_blank"
          href={`${config.partnerAnalyticsDashboardLink}`}
          rel="noopener noreferrer"
        >
          <FormattedMessage {...generic.learn_more} />
        </a>
      </span>
    </T>
  );

  return (
    <Tooltip title={hasValue && tooltip}>
      <Box className={classes.cellContainer}>
        {hasInsufficientData && <FormattedMessage {...i18n.insufficientData} />}
        {hasNoChange && <FormattedMessage {...i18n.noChange} />}
        {hasValue && (
          <T>
            {!isNegative && "+"}
            <FormattedNumber
              value={boostValue}
              // eslint-disable-next-line react/style-prop-object
              style="percent"
              maximumFractionDigits={0}
            />
          </T>
        )}
      </Box>
    </Tooltip>
  );
};

export default BoostCell;

// Helpers

export const isWinRateCell = ({ fieldName }: CellProps) => {
  return fieldName === AvailableAnalytisColumns.winRate;
};

export const getBoostCellCopyContent = (props: CellProps) => {
  const { row, fieldName } = props;
  let value = _.get(row, `metrics.${fieldName}`, "--");
  if (value !== "--") {
    let isNegative = value < 0;
    let percentage = (value * 100).toFixed(0);
    value = `${!isNegative ? "+" : ""}${percentage}%`;
  }
  return value;
};

// CSS

const useStyles = makeStyles<StyleProps>()(
  (theme, { hasValue, isNegative }) => ({
    cellContainer: {
      cursor: "default",
      display: "flex",
      alignItems: "center",
      padding: "1px 6px",
      borderRadius: 4,
      backgroundColor: !hasValue
        ? "transparent"
        : isNegative
        ? theme.palette.red100
        : theme.palette.green100,
      color: !hasValue
        ? theme.palette.text.disabled
        : isNegative
        ? theme.palette.red700
        : theme.palette.green700,
    },
    tooltipLink: {
      color: theme.palette.ivory,
      textDecoration: "underline",
      "&:hover": {
        cursor: "pointer",
        color: theme.palette.ivory,
        textDecorationColor: theme.palette.ivory,
      },
    },
  })
);

// I18N

const i18n = defineMessages({
  noChange: {
    id: "BoostCell.noChange",
    defaultMessage: "No change",
  },
  insufficientData: {
    id: "BoostCell.insufficientData",
    defaultMessage: "Insufficient data",
  },
});
