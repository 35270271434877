import Tooltip from "components/ui/Tooltip";
import { T } from "components/ui/Typography";
import useUserProfile from "hooks/useUserProfile";
import _ from "lodash";
import { makeStyles } from "makeStyles";
import Partnership from "models/Partnership";
import { useMemo } from "react";
import { useSelector } from "react-redux";
import { selectAllPartnerships } from "redux/api/selectors";
import { AvailablePipelineAttributeColumns } from "redux/pipelineAttribute/defaults";

import PartnerChip from "../../PartnerChip";
import { CellProps } from "../../utils";

type Props = CellProps & { width: number };
type PartnerData = { partnerName: string; avatarUrl: string };

export const PartnerDiscussionsCell = (props: Props) => {
  const { classes, cx } = useStyles();
  const { value, width } = props;
  const { profile } = useUserProfile();
  const partnerships = useSelector(selectAllPartnerships) as Partnership[];
  const partnershipOptions = useMemo(
    () =>
      partnerships
        .filter((partnership) => {
          const dataSharingStatus = partnership.getDataSharingStatus(
            profile.company
          );
          return (
            partnership.isGhost() ||
            Partnership.isSharingOverlapping(dataSharingStatus)
          );
        })
        .reduce((acc, partnership) => {
          acc[partnership.id] = {
            partnerName: partnership.getPartner(profile).name,
            avatarUrl: partnership.getPartner(profile).avatarUrl,
          };
          return acc;
        }, {} as Record<number, PartnerData>),
    [partnerships, profile]
  );

  const partnerList: PartnerData[] = value.map(
    (v: { partnership_id: number }) => partnershipOptions[v.partnership_id]
  );
  const hiddenPartners = getHiddenPartners(
    partnerList.filter((partner) => !!partner),
    width
  );

  return (
    <div className={classes.cellContainer}>
      {partnerList.map((partner) => (
        <PartnerChip {...partner} />
      ))}
      {hiddenPartners.length > 0 && (
        <Tooltip
          title={
            <div className={classes.tooltipTagsContainer}>
              {hiddenPartners.map((partner) => (
                <PartnerChip {...partner} />
              ))}
            </div>
          }
        >
          <div
            className={cx(classes.countContainer, "partnerPresenceGradient")}
          >
            <T uppercase textAlign="right" className={classes.countText}>
              +{hiddenPartners.length}
            </T>
          </div>
        </Tooltip>
      )}
    </div>
  );
};

export const isPartnerDiscussionCell = ({ fieldName }: CellProps) => {
  return fieldName === AvailablePipelineAttributeColumns.partnerDiscussions;
};

// UTILS

const getItemWidth = (name: string | undefined) => {
  const padding = 11 + 11;
  return name ? name.length * 7 + padding : 0;
};

const shouldDisplayItem = (
  itemWidth: number,
  currentWidth: number,
  columnWidth: number
) => currentWidth + itemWidth < columnWidth;

const getHiddenPartners = (partners: PartnerData[], columnWidth: number) => {
  let currentWidth = 0;
  let displayedPartners: PartnerData[] = [];
  let hiddenPartners: PartnerData[] = [];
  _.each(partners, (partner) => {
    const itemWidth = getItemWidth(partner.partnerName);
    if (shouldDisplayItem(itemWidth, currentWidth, columnWidth)) {
      currentWidth += itemWidth;
      displayedPartners.push(partner);
    } else {
      hiddenPartners.push(partner);
    }
  });
  return hiddenPartners;
};

// CSS

const useStyles = makeStyles()((theme) => ({
  cellContainer: {
    display: "flex",
    alignItems: "center",
    width: "100%",
    columnGap: theme.spacing(0.5),
    position: "relative",
  },
  avatarContainer: {
    position: "absolute",
    marginTop: 4,
  },
  nameContainer: {
    display: "inline-flex",
    alignItems: "center",
    overflow: "hidden",
    width: "100%",
    height: 24,
    borderRadius: 4,
    backgroundColor: "transparent",
    paddingLeft: 22,
    cursor: "auto",
    "&:hover": {
      backgroundColor: "transparent",
    },
  },
  tag: {
    display: "inline-flex",
    alignItems: "center",
    height: 24,
    borderRadius: 18,
    border: `1px solid ${theme.palette.greyscale250}`,
    paddingLeft: 10,
    paddingRight: 10,
    cursor: "pointer",
    "&:hover": {
      backgroundColor: theme.palette.ivory,
    },
  },
  countContainer: {
    position: "absolute",
    display: "flex",
    height: "100%",
    right: "-10px",
    width: 50,
    alignItems: "center",
    justifyContent: "end",
    paddingRight: 10,
    background: `linear-gradient(270deg, ${theme.palette.ivory} 50.52%, rgba(250, 250, 246, 0) 100%)`,
  },
  countText: {
    borderRadius: 17,
    padding: "3px 6px",
    width: "fit-content",
    backgroundColor: theme.palette.greyscale150,
  },
  tooltipTagsContainer: {
    display: "flex",
    columnGap: 2,
    rowGap: 2,
    padding: "7px, 10px, 7px, 10px",
    flexWrap: "wrap",
  },
}));
