import { SelectChangeEvent, styled } from "@mui/material";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import { SelectInput } from "components/ui/SelectInput";
import useRecord from "hooks/useRecord";
import useUserProfile from "hooks/useUserProfile";
import generic from "i18n/generic";
import Record from "models/Record";
import { useMemo } from "react";
import { useIntl } from "react-intl";
import { useSelector } from "react-redux";
import { selectUsersWithLicense } from "redux/init/selectors";

import { Values } from "./UpdatePartnershipOwnerForm";

type Props = {
  partnershipId: number;
  values: Values;
  index: number;
  error: boolean;
  setFieldTouched: (name: string, touched: boolean) => void;
  handleChange: (event: any) => void;
};

export const PartnershipOwnerRow = ({
  partnershipId,
  index,
  values,
  setFieldTouched,
  handleChange,
  error,
}: Props) => {
  const intl = useIntl();
  const { profile } = useUserProfile();
  const { record } = useRecord("partnerships", partnershipId, false);
  const users = useSelector(selectUsersWithLicense);

  const userOptions = useMemo(() => {
    return users
      .filter((user) => record?.partnershipOwner.id !== user.id)
      .map((user) => {
        return {
          value: user.id.toString(),
          label: user.fullName,
        };
      });
  }, [users, record]);

  const partner = record?.getPartner(profile) as Record;
  const partnerName: string = partner?.name ?? "";

  if (record === null) return null;

  return (
    <TableRow
      key={partnerName}
      sx={{ height: 44, "&:last-child td, &:last-child th": { border: 0 } }}
    >
      <StyledTableCell>{partnerName}</StyledTableCell>
      <StyledTableCell align="left">
        {
          <SelectInput
            small
            variant="secondary"
            name={`partnershipOwners[${index}]`}
            placeholder={intl.formatMessage(generic.select)}
            error={error}
            onChange={(e: SelectChangeEvent) => {
              setFieldTouched(e.target.name, false);
              handleChange({
                target: {
                  name: `partnershipOwners[${index}].ownerId`,
                  value: e.target.value,
                },
                type: "change",
              });
            }}
            inputProps={{ "data-testid": `partnershipOwners[${index}]` }}
            options={userOptions}
            value={values.partnershipOwners[index].ownerId}
          />
        }
      </StyledTableCell>
    </TableRow>
  );
};

// Helpers

export const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.body}`]: {
    backgroundColor: theme.palette.ivory,
    fontSize: 12,
  },
}));
