import { createSvgIcon } from "@mui/material/utils";

import { SvgIcon } from "./types";

const displayName = "ArrowRight";

const ArrowRight: SvgIcon = createSvgIcon(
  // Using transform to enlarge from provided viewbox="0 0 16 16"
  // to default "0 0 24 24" from `createSvgIcon` -> `SvgIcon`.
  <g fill="none" transform="matrix(1.5,0,0,1.5,0,0)">
    <path
      d="M8.97986 11.9798C8.7846 12.175 8.7846 12.4916 8.97986 12.6869C9.17512 12.8821 9.49171 12.8821 9.68697 12.6869L13.5489 8.82495C14.0045 8.36934 14.0045 7.63064 13.5489 7.17503L9.68697 3.3131C9.49171 3.11784 9.17512 3.11784 8.97986 3.3131C8.7846 3.50836 8.7846 3.82495 8.97986 4.02021L12.4596 7.49999H2.66675C2.39061 7.49999 2.16675 7.72385 2.16675 7.99999C2.16675 8.27613 2.39061 8.49999 2.66675 8.49999H12.4596L8.97986 11.9798Z"
      fill="currentColor"
    />
  </g>,
  displayName
);

ArrowRight.displayName = displayName;
export default ArrowRight;
