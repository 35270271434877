import User from "models/User";

export interface ProfileState {
  data: User | null;
  requested: boolean;
}

export interface LoginState {
  loggedIn: boolean;
  pending: boolean;
}

export interface RootStateWithUser {
  user: {
    login: LoginState;
    profile: ProfileState;
  };
}

export enum UserActions {
  /**
   * Authentication
   */
  login = "user/login",
  logout = "user/logout",
  refreshToken = "user/refreshToken",
  setLoggedIn = "user/setLoggedIn",
  /**
   * Profile
   */
  fetchProfile = "user/fetchProfile",
  fetchProfileInBackground = "user/fetchProfileInBackground",
  setProfile = "user/setProfile",
  updateProfile = "user/updateProfile",
  /**
   * MFA
   */
  enableMFA = "user/enableMFA",
  disableMFA = "user/disableMFA",
  /**
   * Company
   */
  setCompany = "user/setCompany",
}
