import { defineMessages } from "react-intl";

export default defineMessages({
  emailFormTitle: {
    id: "Registration.emailFormTitle",
    defaultMessage: "Create your account for free",
  },
  createYourAccount: {
    id: "Registration.createYourAccount",
    defaultMessage: "Create your account",
  },
  registrationFormTitle: {
    id: "Registration.registrationFormTitle",
    defaultMessage: "Tell us more about you",
  },
  titleWithCompany: {
    id: "Registration.titleWithCompany",
    defaultMessage: "Sign up to partner with {companyName}",
  },
  next: {
    id: "Registration.next",
    defaultMessage: "Next",
  },
  alreadyMember: {
    id: "Registration.alreadyMember",
    defaultMessage: "Already a member?",
  },
  leftSideRegistrationFormSubtitle: {
    id: "Registration.leftSideRegistrationFormSubtitle",
    defaultMessage:
      "to a community of 15,000+<br/>Revenue Leaders succeeding<br/>with an ecosystem-first strategy",
  },
  leftSideRegistrationFormTitle: {
    id: "Registration.leftSideRegistrationFormTitle",
    defaultMessage: "Welcome!",
  },
  leftSideTitle: {
    id: "Registration.leftSideTitle",
    defaultMessage:
      "Start generating<br/>more revenue<br/>with your<br/>ecosystem",
  },
  login: {
    id: "Registration.login",
    defaultMessage: "Log in",
  },
});
