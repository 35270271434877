import { Box } from "@mui/material";
import { T } from "components/ui/Typography";
import _ from "lodash";
import { makeStyles } from "makeStyles";
import { defineMessages, FormattedMessage, useIntl } from "react-intl";

import Tooltip from "./Tooltip";

type StyleProps = {
  isSelected?: boolean;
  isMore?: boolean;
};

type FacetProps = Partial<StyleProps> & {
  value?: number;
  valueWithPrivate?: number;
  isMatches?: boolean;
};

export const Facet = ({
  value,
  valueWithPrivate,
  isSelected = false,
  isMore = false,
  isMatches = true,
}: FacetProps) => {
  const { classes } = useStyles({ isSelected, isMore });
  const intl = useIntl();

  const hasPrivates =
    Boolean(valueWithPrivate) && (valueWithPrivate ?? 0) > (value ?? 0);

  if (_.isUndefined(value)) {
    return null;
  }

  const message = isMatches ? i18n.hasPrivateMatches : i18n.hasPrivateResults;
  const tooltipContent = hasPrivates ? (
    <FormattedMessage {...message} values={{ count: valueWithPrivate }} />
  ) : (
    ""
  );

  const data = intl.formatNumber(value, {
    notation: "compact",
    compactDisplay: "short",
    maximumSignificantDigits: value < 1000 ? 3 : 2,
  });

  return (
    <Box display="flex" alignItems="center">
      <Tooltip title={tooltipContent}>
        <T uppercase className={classes.root}>
          {isMore && "+"}
          {data}
          {hasPrivates && <span className={classes.private}>*</span>}
        </T>
      </Tooltip>
    </Box>
  );
};

// CSS

export const useStyles = makeStyles<StyleProps>()(
  (theme, { isSelected, isMore }) => ({
    root: {
      color:
        isSelected || isMore ? theme.palette.offWhite : theme.palette.midnight,
      backgroundColor: isMore
        ? theme.palette.midnight
        : isSelected
        ? theme.palette.darkPigeon
        : theme.palette.taupe500,
      padding: isMore ? "7px 5px" : "0px 6px",
      borderRadius: isMore ? 4 : 24,
      lineHeight: "16px",
    },
    private: {
      color: isSelected ? theme.palette.taupe : theme.palette.red600,
      fontSize: "small",
    },
  })
);
// I18N

const i18n = defineMessages({
  hasPrivateMatches: {
    id: "Facet.hasPrivateMatches",
    defaultMessage:
      "Because of your respective privacy settings, you’re only seeing part of the {count} total matches found.",
  },
  hasPrivateResults: {
    id: "Facet.hasPrivateResults",
    defaultMessage:
      "Because of your respective privacy settings, you’re only seeing part of the {count} total results found.",
  },
});
