import { AxiosResponse } from "axios";
import usePushNotification from "hooks/usePushNotification";
import { JSONAPIResource } from "models/types";
import { useEffect, useState } from "react";
import { defineMessages } from "react-intl";
import JSONAPIService from "services/JSONAPIService";
import { JSONAPIListResponse, JSONAPIResponse } from "services/types";

import { DefinitionField, SharingLevel } from "../utils";

type IDefinitionDefault = {
  [sharingLevel in SharingLevel]: {
    [field in DefinitionField]: boolean;
  };
};

type IFieldDefinition = { field: DefinitionField; value: boolean };

export const useOpportunitySharingLevelDefinitionsApi = () => {
  const [
    definitionDefaults,
    setDefinitionDefaults,
  ] = useState<IDefinitionDefault>();
  const [isLoadingDefaults, setIsLoadingDefaults] = useState<boolean>(true);
  const [definitionRecords, setDefinitionRecords] = useState<
    JSONAPIResource<"opportunity_sharing_level_definitions">[] | undefined
  >();
  const service = new JSONAPIService("opportunity_sharing_level_definitions");
  const pushNotification = usePushNotification();

  const loadDefinitionRecords = async () => {
    let response:
      | AxiosResponse<
          JSONAPIListResponse<"opportunity_sharing_level_definitions">
        >
      | undefined;
    try {
      response = await service.rawGet("");
    } catch (error) {
      pushNotification(i18n.loadDefinitionError);
      return;
    }
    setDefinitionRecords(response?.data.data);
  };

  useEffect(() => {
    loadDefinitionRecords();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const loadDefinitionDefaults = async () => {
    let response: AxiosResponse<JSONAPIResponse> | undefined;
    try {
      response = await service.describe(true);
    } catch (error) {
      pushNotification(i18n.loadDefinitionError);
      return;
    } finally {
      setIsLoadingDefaults(false);
    }
    setDefinitionDefaults(response?.data.data?.defaults as IDefinitionDefault);
  };

  useEffect(() => {
    loadDefinitionDefaults();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleUpdateDefinitionDefaults = async (
    level: SharingLevel,
    definition: IFieldDefinition
  ) => {
    const recordId = definitionRecords?.find(
      (record) => record.attributes?.level === level
    )?.id;
    if (recordId) {
      await updateDefinitionDefaults(level, definition);
    } else {
      await addDefinitionDefaults(level, definition);
    }
  };

  const addDefinitionDefaults = async (
    level: SharingLevel,
    definition: IFieldDefinition
  ) => {
    setIsLoadingDefaults(true);
    let response:
      | AxiosResponse<JSONAPIResponse<"opportunity_sharing_level_definitions">>
      | undefined;
    try {
      response = await service.rawPost("", "", {
        data: {
          type: "opportunity_sharing_level_definitions",
          attributes: {
            level: level,
            ...(definitionDefaults?.[level] ?? {}),
            [definition.field]: definition.value,
          },
        },
      });
    } catch (error) {
      pushNotification(i18n.updateDefinitionError);
      return;
    } finally {
      setIsLoadingDefaults(false);
    }
    setDefinitionRecords(
      (prev) =>
        [
          ...(prev ?? []),
          ...[response?.data?.data ?? []],
        ] as JSONAPIResource<"opportunity_sharing_level_definitions">[]
    );
  };

  const updateDefinitionDefaults = async (
    level: SharingLevel,
    definition: IFieldDefinition
  ) => {
    const recordId = definitionRecords?.find(
      (record) => record.attributes?.level === level
    )?.id;
    if (!recordId) {
      return;
    }
    setIsLoadingDefaults(true);
    let response:
      | AxiosResponse<JSONAPIResponse<"opportunity_sharing_level_definitions">>
      | undefined;
    try {
      response = await service.rawPatch(+recordId, "", {
        data: {
          id: recordId,
          type: "opportunity_sharing_level_definitions",
          attributes: {
            [definition.field]: definition.value,
          },
        },
      });
    } catch (error) {
      pushNotification(i18n.updateDefinitionError);
      return;
    } finally {
      setIsLoadingDefaults(false);
    }
    setDefinitionRecords(
      (prev) =>
        [
          ...(prev ?? []).filter((item) => item.id !== recordId),
          ...[response?.data?.data ?? []],
        ] as JSONAPIResource<"opportunity_sharing_level_definitions">[]
    );
  };

  const rows = Object.values(DefinitionField).map((field) => ({
    id: field,
    opportunityFieldName: field,
    [SharingLevel.None.toString()]:
      definitionRecords?.find(
        (record) => record.attributes?.level === SharingLevel.None
      )?.attributes?.[field] ?? definitionDefaults?.[SharingLevel.None][field],
    [SharingLevel.Limited.toString()]:
      definitionRecords?.find(
        (record) => record.attributes?.level === SharingLevel.Limited
      )?.attributes?.[field] ??
      definitionDefaults?.[SharingLevel.Limited][field],
    [SharingLevel.Full.toString()]:
      definitionRecords?.find(
        (record) => record.attributes?.level === SharingLevel.Full
      )?.attributes?.[field] ?? definitionDefaults?.[SharingLevel.Full][field],
  }));

  return {
    isLoadingDefaults,
    rows,
    handleUpdateDefinitionDefaults,
  };
};

const i18n = defineMessages({
  loadDefinitionError: {
    id:
      "CollaborateSettings.useOpportunitySharingLevelDefinitionsApi.loadDefaultsError",
    defaultMessage: "Failed to load sharing settings",
  },
  updateDefinitionError: {
    id:
      "CollaborateSettings.useOpportunitySharingLevelDefinitionsApi.addDefinitionDefaultError",
    defaultMessage: "Failed to update sharing settings",
  },
});
