import { displayOnboardingMeetingDialog } from "helpers/bookMeetingUtils";
import usePushNotification from "hooks/usePushNotification";
import useSegment from "hooks/useSegment";
import useUserProfile from "hooks/useUserProfile";
import { useCallback, useEffect } from "react";
import { defineMessages } from "react-intl";
import { useLocation } from "react-router";

type Props = {
  isOpen: boolean;
  onClose: () => void;
};

export const BookMeetingDialog = ({ isOpen, onClose }: Props) => {
  const location = useLocation();
  const { track } = useSegment();
  const { profile } = useUserProfile();
  const pushNotification = usePushNotification();

  const handleSuccess = useCallback(() => {
    pushNotification({ ...i18n.eventScheduled });
    track("Booked sales meeting", {
      page: location.pathname,
    });
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    isOpen &&
      displayOnboardingMeetingDialog(
        profile,
        () => handleSuccess(),
        () => onClose()
      );
  }, [isOpen]); // eslint-disable-line react-hooks/exhaustive-deps

  return <></>;
};

/// I18N

const i18n = defineMessages({
  eventScheduled: {
    id: "BookMeetingDialog.eventScheduled",
    defaultMessage: "Your meeting has been scheduled.",
  },
});
