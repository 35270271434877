import AccountAvatar from "components/ui/avatars/AccountAvatar";
import CompanyAvatar from "components/ui/avatars/CompanyAvatar";
import Tooltip from "components/ui/Tooltip";
import { T } from "components/ui/Typography";
import { getLogo, ProviderType } from "config/constants";
import { makeStyles } from "makeStyles";
import { useEffect, useRef, useState } from "react";
import { defineMessages, FormattedMessage } from "react-intl";
import AccountStatus from "screens/Frontoffice/shared/components/AccountStatus";
import {
  AccountResult,
  isAccountResult,
  ProspectResult,
} from "services/GlobalSearchService";

import Highlighted from "./Highlighted";
import OptionContainer from "./OptionContainer";

type Props = {
  item: AccountResult | ProspectResult;
  integrations: Map<number, ProviderType>;
  searchWords: string[];
};

const AccountOption = ({ item, integrations, searchWords }: Props) => {
  if (isAccountResult(item)) {
    return (
      <NearboundAccount
        item={item}
        integrations={integrations}
        searchWords={searchWords}
      />
    );
  }
  return <NearboundProspect item={item} searchWords={searchWords} />;
};

export default AccountOption;

// Sub Components

type BaseOptionProps = {
  avatarSrc?: string;
  name: string;
  status?: number | null;
  owner?: string | null;
  searchWords: string[];
};

const BaseOption = ({
  avatarSrc,
  name,
  status,
  owner,
  searchWords,
}: BaseOptionProps) => {
  const companyNameRef = useRef<HTMLDivElement>(null);
  const ownerNameRef = useRef<HTMLDivElement>(null);
  const [isCompanyNameTruncated, setIsCompanyNameTruncated] = useState(false);
  const [isOwnerNameTruncated, setIsOwnerNameTruncated] = useState(false);
  const { classes } = useStyles({ status });

  useEffect(() => {
    if (companyNameRef.current) {
      const element = companyNameRef.current;
      setIsCompanyNameTruncated(element.scrollWidth > element.clientWidth);
    }
    if (ownerNameRef.current) {
      const element = ownerNameRef.current;
      setIsOwnerNameTruncated(element.scrollWidth > element.clientWidth);
    }
  }, [companyNameRef, ownerNameRef]);

  const left = (
    <>
      <CompanyAvatar src={avatarSrc} companyName={name} size="xxs" />
      <div className={classes.companyName}>
        <Tooltip title={name} hidden={!isCompanyNameTruncated}>
          <T ref={companyNameRef}>
            <Highlighted text={name} searchWords={searchWords} />
          </T>
        </Tooltip>
      </div>
      {status !== null && (
        <div className={classes.status}>
          <T className={classes.dot}>&middot;</T>
          <T>
            {status ? (
              <AccountStatus account={{ status }} />
            ) : (
              <FormattedMessage {...i18n.notInYourCrm} />
            )}
          </T>
        </div>
      )}
    </>
  );

  const right =
    typeof owner !== "undefined" ? (
      owner !== null ? (
        <>
          <AccountAvatar fullName={owner} size="xxs" />
          <Tooltip title={owner} hidden={!isOwnerNameTruncated}>
            <T ref={ownerNameRef}>{owner}</T>
          </Tooltip>
        </>
      ) : (
        <T className={classes.unassigned}>
          <FormattedMessage {...i18n.unassigned} />
        </T>
      )
    ) : undefined;
  return <OptionContainer left={left} right={right} />;
};

type NearboundAccountProps = {
  item: AccountResult;
  integrations: Map<number, ProviderType>;
  searchWords: string[];
};

const NearboundAccount = ({
  item,
  integrations,
  searchWords,
}: NearboundAccountProps) => {
  return (
    <BaseOption
      avatarSrc={getLogo(integrations.get(item.integration_id) ?? null)}
      name={item.name}
      status={item.status}
      owner={getOwnerFullName(item)}
      searchWords={searchWords}
    />
  );
};

type NearboundProspectProps = {
  item: ProspectResult;
  searchWords: string[];
};

const NearboundProspect = ({ item, searchWords }: NearboundProspectProps) => {
  return <BaseOption name={item.name} searchWords={searchWords} />;
};

// Helpers

const getOwnerFullName = (item: AccountResult) => {
  return item.owner?.full_name ?? item.owner?.email ?? null;
};

// CSS

const useStyles = makeStyles<{ status: number | null | undefined }>()(
  (theme, { status }) => ({
    companyName: {
      maxWidth: status ? 160 : 220,
      "& > p": {
        overflow: "hidden",
        whiteSpace: "nowrap",
        textOverflow: "ellipsis",
      },
    },
    status: {
      display: "flex",
      color: theme.palette.alpha500,
    },
    dot: {
      margin: theme.spacing(0, 0.5),
    },
    unassigned: {
      paddingRight: 1,
    },
  })
);

// I18n

const i18n = defineMessages({
  unassigned: {
    id: "GlobalSearch.AccountOption.unassigned",
    defaultMessage: "Unassigned",
  },
  notInYourCrm: {
    id: "GlobalSearch.AccountOption.notInYourCrm",
    defaultMessage: "Not in your CRM",
  },
});
