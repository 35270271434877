import { createSvgIcon } from "@mui/material/utils";

import { SvgIcon } from "./types";

const displayName = "MessageOutlined";

const MessageOutlined: SvgIcon = createSvgIcon(
  // Using transform to enlarge from provided viewbox="0 0 16 16"
  // to default "0 0 24 24" from `createSvgIcon` -> `SvgIcon`.
  <g fill="none" transform="matrix(1.5,0,0,1.5,5,4)">
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M0.03125 1.625C0.03125 0.744795 0.744797 0.03125 1.625 0.03125H8.375C9.2552 0.03125 9.96875 0.744796 9.96875 1.625V7.22115C9.96875 8.10136 9.2552 8.8149 8.375 8.8149H6.014C5.87339 8.8149 5.7365 8.86006 5.6235 8.94373L2.60268 11.1803V8.8149H1.625C0.744795 8.8149 0.03125 8.10136 0.03125 7.22115V1.625ZM1.625 0.96875C1.26256 0.96875 0.96875 1.26256 0.96875 1.625V7.22115C0.96875 7.58359 1.26256 7.8774 1.625 7.8774H3.54018V9.3197L5.06565 8.19027C5.34009 7.98708 5.67253 7.8774 6.014 7.8774H8.375C8.73744 7.8774 9.03125 7.58359 9.03125 7.22115V1.625C9.03125 1.26256 8.73744 0.96875 8.375 0.96875H1.625Z"
      fill="currentColor"
    />
  </g>,
  displayName
);

MessageOutlined.displayName = displayName;
export default MessageOutlined;
