import {
  ColumnConfigType,
  FilterType,
  SortType,
} from "screens/Frontoffice/screens/DataTables/shared/types";

export enum Mapping360NearboundAccountsActions {
  addView = "mapping360NearboundAccounts/addView",
  loadView = "mapping360NearboundAccounts/loadView",
  loadViews = "mapping360NearboundAccounts/loadViews",
  updateView = "mapping360NearboundAccounts/updateView",
  selectSourceId = "mapping360NearboundAccounts/selectSourceId",
}

export interface Mapping360NearboundAccountsState {
  viewId?: string | null | number;
  viewIds: number[];
  selectedSourceId: number | null;
  ready: boolean;
}

export interface RootStateWithMapping360NearboundAccounts {
  nearboundAccounts: Mapping360NearboundAccountsState;
}

export interface Mapping360NearboundAccountsViewParameters {
  filters?: FilterType[];
  columns?: ColumnConfigType[];
  sort?: SortType[];
  pinned?: boolean;
  id: number;
}
