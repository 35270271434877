import _ from "lodash";
import { Available360MappingColumns } from "redux/mapping360/defaults";

export const isBaseMultiplierValue = (fieldName: string) => {
  return (
    fieldName === Available360MappingColumns.winRateMultiplier ||
    fieldName === Available360MappingColumns.dealSizeMultiplier
  );
};

export const baseMultiplierValueFormatter = (row: any, fieldName: string) => {
  let averageFieldName, projectedFieldName;

  if (fieldName === Available360MappingColumns.winRateMultiplier) {
    averageFieldName = "averageWinRate";
    projectedFieldName = "projectedWinRate";
  } else if (fieldName === Available360MappingColumns.dealSizeMultiplier) {
    averageFieldName = "averageOpportunityAmount";
    projectedFieldName = "projectedOpportunityAmount";
  }

  const averageKpiValue = averageFieldName
    ? _.get(row, averageFieldName, null)
    : null;
  const projectedKpiValue = projectedFieldName
    ? _.get(row, projectedFieldName, null)
    : null;

  let result = "--";
  if (averageKpiValue && projectedKpiValue) {
    const multiplier = projectedKpiValue / averageKpiValue;
    if (multiplier > 1) {
      result = `x${multiplier.toFixed(1)}`;
    }
  }
  return result;
};
