import { createSvgIcon } from "@mui/material/utils";

import { SvgIcon } from "./types";

const displayName = "Columns";

const Columns: SvgIcon = createSvgIcon(
  // Using transform to enlarge from provided viewbox="0 0 16 16"
  // to default "0 0 24 24" from `createSvgIcon` -> `SvgIcon`.
  <g fill="none" transform="matrix(1.5,0,0,1.5,0,0)">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3.5 1.5C2.39543 1.5 1.5 2.39543 1.5 3.5V5.2002V12.5C1.5 13.6046 2.39543 14.5 3.5 14.5H5.98588C5.99057 14.5001 5.99528 14.5002 6 14.5002C6.00472 14.5002 6.00943 14.5001 6.01412 14.5H12.5C13.6046 14.5 14.5 13.6046 14.5 12.5V5.2002V3.5C14.5 2.39543 13.6046 1.5 12.5 1.5H3.5ZM13.5 4.7002V3.5C13.5 2.94772 13.0523 2.5 12.5 2.5H3.5C2.94772 2.5 2.5 2.94772 2.5 3.5V4.7002H6H13.5ZM2.5 5.7002V12.5C2.5 13.0523 2.94772 13.5 3.5 13.5H5.5V5.7002H2.5ZM6.5 13.5V5.7002H13.5V12.5C13.5 13.0523 13.0523 13.5 12.5 13.5H6.5Z"
      fill="currentColor"
      fillOpacity="0.82"
    />
  </g>,
  displayName
);

Columns.displayName = displayName;
export default Columns;
