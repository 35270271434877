import {
  constant,
  guard,
  iso8601,
  nullable,
  number,
  object,
  string,
} from "decoders";

import Record from "./Record";
import { JSONAPIResourceOrRecord } from "./types";

const apiMessageDecoder = object({
  type: constant("messages" as const),
  id: string,
  attributes: object({
    conversation_id: number,
    participant_id: number,
    message_type: number,
    content: string,
    sent_at: iso8601,
    edited_at: nullable(iso8601),
    deleted_at: nullable(iso8601),
  }),
});

const apiMessageGuard = guard(apiMessageDecoder);

export enum MessageType {
  internal = 0,
  external = 1,
  internalMigrated = 2,
  externalMigrated = 3,
}

export default class Message extends Record<"messages"> {
  id: number;
  conversationId: number;
  participantId: number;
  messageType: MessageType;
  content: string;
  sentAt: Date;
  editedAt: Date | null;
  deletedAt: Date | null;

  constructor(data: JSONAPIResourceOrRecord<"messages">) {
    super(data);
    const apiMessage = apiMessageGuard(data);
    const attrs = apiMessage.attributes;

    this.id = +apiMessage.id;
    this.conversationId = attrs.conversation_id;
    this.participantId = attrs.participant_id;
    this.messageType = attrs.message_type;
    this.content = attrs.content;
    this.sentAt = attrs.sent_at;
    this.editedAt = attrs.edited_at;
    this.deletedAt = attrs.deleted_at;
  }

  isInternal() {
    return (
      this.messageType === MessageType.internal ||
      this.messageType === MessageType.internalMigrated
    );
  }
}
