import { Lock } from "components/icons";
import { Facet } from "components/ui/Facet";
import ToggleBtnGroup from "components/ui/ToggleBtnGroup";
import _ from "lodash";
import { makeStyles } from "makeStyles";
import {
  defineMessages,
  FormattedMessage,
  MessageDescriptor,
} from "react-intl";
import { AnalyticsQuickFilterType } from "redux/analytics/types";

import useAPILogic from "../hooks/useAnalyticsToggleFilterAPILogic";

type Props = {
  disabled?: boolean;
};

export const AnalyticsToggleFilter = ({ disabled }: Props) => {
  const { classes } = useStyles();
  const { quickFiltersMetrics, quickFilterType, setFilter } = useAPILogic();

  const filterLabel = (
    label: MessageDescriptor,
    filterType?: AnalyticsQuickFilterType
  ) => {
    const value = filterType
      ? _.get(quickFiltersMetrics, filterType, undefined)
      : undefined;
    const isLocked = filterType !== AnalyticsQuickFilterType.All && disabled;
    return (
      <div className={classes.labelContainer}>
        <span>{<FormattedMessage {...label} />}</span>
        {filterType && !isLocked && (
          <Facet
            value={value === null ? undefined : value}
            isSelected={quickFilterType === filterType}
          />
        )}
        {isLocked && <Lock style={{ fontSize: 14, cursor: "not-allowed" }} />}
      </div>
    );
  };

  return (
    <ToggleBtnGroup
      items={[
        {
          label: filterLabel(i18n.all, AnalyticsQuickFilterType.All),
          value: AnalyticsQuickFilterType.All,
        },
        {
          label: filterLabel(
            i18n.favorites,
            AnalyticsQuickFilterType.Favorites
          ),
          value: AnalyticsQuickFilterType.Favorites,
          tooltip: <FormattedMessage {...i18n.favoritesTooltip} />,
          disabled,
        },
        {
          label: filterLabel(
            i18n.highWinRatePartners,
            AnalyticsQuickFilterType.HighWinRatePartners
          ),
          value: AnalyticsQuickFilterType.HighWinRatePartners,
          tooltip: <FormattedMessage {...i18n.highWinRatePartnersTooltip} />,
          disabled,
        },
        {
          label: filterLabel(
            i18n.myPartners,
            AnalyticsQuickFilterType.MyPartners
          ),
          value: AnalyticsQuickFilterType.MyPartners,
          tooltip: <FormattedMessage {...i18n.myPartnersTooltip} />,
          disabled,
        },
      ]}
      setValue={setFilter}
      value={quickFilterType}
      classes={{ root: classes.toggleBtnGroup }}
    />
  );
};

// CSS

const useStyles = makeStyles()((theme) => ({
  labelContainer: {
    display: "flex",
    alignItems: "center",
    columnGap: theme.spacing(1),
  },
  facet: {
    display: "flex",
    justifyContent: "center",
    minWidth: 22.5,
  },
  toggleBtnGroup: {
    [theme.breakpoints.down("md")]: {
      display: "table",
    },
  },
}));

// I18N

const i18n = defineMessages({
  all: {
    id: "Analytics.ToggleFilter.all",
    defaultMessage: "All",
  },
  favorites: {
    id: "Analytics.ToggleFilter.favorites",
    defaultMessage: "Favorites",
  },
  favoritesTooltip: {
    id: "Analytics.ToggleFilter.favoritesTooltip",
    defaultMessage: "Favorited partners",
  },
  highWinRatePartners: {
    id: "Analytics.ToggleFilter.highWinRatePartners",
    defaultMessage: "High win-rate partners",
  },
  highWinRatePartnersTooltip: {
    id: "Analytics.ToggleFilter.highWinRatePartnersTooltip",
    defaultMessage: "Partners with a win rate boost > +20%",
  },
  myPartners: {
    id: "Analytics.ToggleFilter.myPartners",
    defaultMessage: "My partners",
  },
  myPartnersTooltip: {
    id: "Analytics.ToggleFilter.myPartnersTooltip",
    defaultMessage: "Partnerships you own",
  },
});
