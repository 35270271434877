import { NotificationStatus } from "components/ui/Notifications/NotificationSnackbar";
import { ProviderType } from "config/constants";
import { History, Location } from "history";
import { defineMessages } from "react-intl";

const escapeRegExp = (value: string) => {
  return value.replace(/[.*+?^${}()|[\]\\]/g, "\\$&");
};

export const replaceAll = (text: string, find: string, replace: string) => {
  return text.replace(new RegExp(escapeRegExp(find), "g"), replace);
};

export const updateHistoryUpdatedSource = (
  history: History,
  location: Location
) => {
  const updatedLocation = {
    ...location,
    search: location.search
      ? `${replaceAll(location.search, "&updated=true", "")}&updated=true`
      : "updated=true",
  };
  history.replace(updatedLocation);
  return updatedLocation;
};

export const generateNotification = (
  provider: ProviderType,
  isNew: boolean
) => {
  const notification = {
    status: NotificationStatus.success,
  };

  switch (provider) {
    case ProviderType.gsheet:
      return {
        ...notification,
        message: isNew ? i18n.addedCrmMessage : i18n.updatedCrmMessage,
        description: i18n.addedGsheetDescription,
      };
    case ProviderType.file:
      return {
        ...notification,
        message: isNew ? i18n.addedFileMessage : i18n.updatedFileMessage,
        description: i18n.addedCrmDescription,
      };
    default:
      return {
        ...notification,
        message: isNew ? i18n.addedCrmMessage : i18n.updatedCrmMessage,
        description: i18n.addedCrmDescription,
      };
  }
};

const i18n = defineMessages({
  addedCrmDescription: {
    id: "crm.PartnershipSettings.SourceSettingsDialog.addedCrmDescription",
    defaultMessage: "Accounts are currently processing",
  },
  addedFileMessage: {
    id: "crm.PartnershipSettings.SourceSettingsDialog.addedFileMessage",
    defaultMessage:
      "CSV file have been successfully added to your data sources",
  },
  updatedFileMessage: {
    id: "crm.PartnershipSettings.SourceSettingsDialog.updatedFileMessage",
    defaultMessage:
      "CSV file have been successfully updated in your data sources",
  },
  addedGsheetDescription: {
    id: "crm.PartnershipSettings.SourceSettingsDialog.addedGsheetDescription",
    defaultMessage: "This sheet is currently processing",
  },
  addedCrmMessage: {
    id: "crm.PartnershipSettings.SourceSettingsDialog.addedCrmMessage",
    defaultMessage:
      "{provider} has been successfully added to your data sources",
  },
  updatedCrmMessage: {
    id: "crm.PartnershipSettings.SourceSettingsDialog.updatedCrmMessage",
    defaultMessage:
      "{provider} has been successfully updated in your data sources",
  },
});
