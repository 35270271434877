import { Box, Grid } from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import * as Icons from "components/icons";
import { CheckSmall, DragAndDrop } from "components/icons";
import CompanyAvatar from "components/ui/avatars/CompanyAvatar";
import Button from "components/ui/Button";
import Checkbox from "components/ui/Checkbox";
import { SharedOpportunityDisplay } from "components/ui/SharedOpportunityDisplay/SharedOpportunityDisplay";
import Toggle from "components/ui/Toggle";
import Tooltip from "components/ui/Tooltip";
import { T } from "components/ui/Typography/Typography";
import { makeStyles } from "makeStyles";
import { Status } from "models/PartnerConnection";
import React from "react";
import { FormattedDate } from "react-intl";

import { IDropdownItem } from "./types";

const DropdownItem: React.FC<IDropdownItem> = ({
  checkbox,
  label,
  logo,
  logoTooltip,
  toggle,
  isChecked,
  isExpanded,
  isIndeterminate,
  isHoverable,
  onChange,
  onSelect,
  isDraggable,
  name,
  subText,
  rightIcons,
  rightIconTooltip,
  subItems,
  onButtonClick,
  buttonLabel,
  buttonIcon,
  id,
  tooltipMessage,
  title,
  isDisabled,
  isVertical,
  isLoading,
  fullWidth,
  // Opport Props
  isOpportunityItem,
  amount,
  date,
  isOpen,
  isWon,
  height,
  stage,
}) => {
  return (
    <SingleItemWrapper
      height={isOpportunityItem ? "unset" : height}
      isHoverable={isHoverable}
      isOpen={isOpen}
      isOpportunityItem={isOpportunityItem}
      isVertical={isVertical}
      onChange={onChange}
      id={id}
      tooltipMessage={tooltipMessage}
      isDisabled={isDisabled || isLoading}
      isChecked={isChecked}
    >
      {isOpportunityItem ? (
        <SingleOpportunityItem
          id={id}
          isOpen={isOpen}
          date={date}
          name={name}
          isOpportunityItem
          amount={amount}
          onChange={onChange}
          isWon={isWon}
          stage={stage}
          isChecked={isChecked}
        />
      ) : (
        <SingleItem
          id={id}
          buttonLabel={buttonLabel}
          buttonIcon={buttonIcon}
          onButtonClick={onButtonClick}
          checkbox={checkbox}
          isDisabled={isDisabled}
          isExpanded={isExpanded}
          isVertical={isVertical}
          logo={logo}
          logoTooltip={logoTooltip}
          toggle={toggle}
          label={label}
          name={name}
          onSelect={onSelect}
          subText={subText}
          isChecked={isChecked}
          isIndeterminate={isIndeterminate}
          isLoading={isLoading}
          fullWidth={fullWidth}
          onChange={onChange}
          isDraggable={isDraggable}
          rightIcons={rightIcons}
          rightIconTooltip={rightIconTooltip}
          subItems={subItems}
          title={title}
          height={height}
        />
      )}
    </SingleItemWrapper>
  );
};

const SingleItem = ({
  checkbox,
  label,
  logo,
  logoTooltip,
  toggle,
  name,
  subText,
  onChange,
  onSelect,
  isDraggable,
  isExpanded,
  isVertical,
  rightIcons,
  rightIconTooltip,
  subItems,
  onButtonClick,
  buttonLabel,
  buttonIcon,
  isChecked,
  isDisabled,
  isIndeterminate,
  isLoading,
  fullWidth,
  title,
  id,
  height,
}: IDropdownItem) => {
  const icons = Object.values(Icons);
  const { classes, cx } = useDropdownItemStyles({
    subItems,
    isDraggable,
    isVertical,
    fullWidth,
    height,
  });
  const { classes: checkboxClasses } = useCheckboxStyles();
  const { classes: btnClasses } = useBtnStyles();

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    onSelect?.(e);
  };

  const leftItems = (
    <>
      {isDraggable && (
        <span className={classes.itemElement}>
          <DragAndDrop style={{ paddingTop: 4 }} />
        </span>
      )}
      {checkbox && onChange && (
        <span className={classes.itemElement}>
          <Checkbox
            id={id}
            checked={isChecked}
            indeterminate={!isChecked && isIndeterminate}
            onChange={handleChange}
            classes={checkboxClasses}
            withoutMargin
            disabled={isDisabled}
          />
        </span>
      )}
      {toggle && onChange && (
        <span className={classes.itemElement}>
          <Toggle
            id={id}
            checked={!!isChecked}
            size="small"
            handleChange={onChange}
          />
        </span>
      )}
      {logo && (
        <span className={classes.itemElement}>
          <Tooltip title={logoTooltip} placement="left">
            <div>
              <CompanyAvatar src={logo} size="xs" />
            </div>
          </Tooltip>
        </span>
      )}
    </>
  );

  const rightItems = (
    <>
      {isChecked && !(toggle || checkbox) && (
        <span className={classes.itemElement}>
          <CheckSmall className={classes.icon} />
        </span>
      )}
      {rightIcons && (
        <>
          {icons.map((IconComponent, key) => {
            const rightIcon = rightIcons?.find(
              (itemIcon: string) =>
                itemIcon === IconComponent.displayName?.replace(/Icon\b/, "")
            );
            return (
              <>
                {rightIcon && (
                  <span key={key} className={classes.itemElement}>
                    <Tooltip title={rightIconTooltip} placement="right">
                      <div>
                        <IconComponent className={classes.rightIcon} />
                      </div>
                    </Tooltip>
                  </span>
                )}
              </>
            );
          })}
        </>
      )}
      {subItems?.length ? (
        <span className={classes.itemElement}>
          {isExpanded ? <Icons.ChevronTop /> : <Icons.ChevronDown />}
        </span>
      ) : null}
      {onButtonClick && (
        <Button
          RightIcon={buttonIcon}
          label={buttonLabel}
          onClick={onButtonClick}
          size="small"
          variant="primary"
          classes={btnClasses}
        />
      )}
      {isLoading && (
        <CircularProgress
          color="inherit"
          size={16}
          className={classes.circularProgress}
        />
      )}
      {subText && (
        <span className={cx(classes.subText, "subText")}>{subText}</span>
      )}
    </>
  );

  if (isVertical) {
    return (
      <>
        <div className={classes.leftSide}>{leftItems}</div>
        <div className={classes.verticalItems}>
          <span className={classes.label} title={title}>
            {label ?? name}
          </span>
          {rightItems}
        </div>
      </>
    );
  }

  return (
    <>
      <div className={classes.leftSide}>
        {leftItems}
        <span className={classes.label} title={title}>
          {label ?? name}
        </span>
      </div>
      <div className={classes.rightSide}>{rightItems}</div>
    </>
  );
};

const SingleOpportunityItem = ({
  name,
  isOpen,
  date,
  isOpportunityItem,
  amount,
  isWon,
  stage,
  isChecked,
  id,
  onChange,
}: IDropdownItem) => {
  const { classes } = useDropdownItemStyles({
    isOpen,
    isOpportunityItem,
    isWon,
  });
  const { classes: checkboxClasses } = useCheckboxStyles();

  return (
    <Box className={classes.opportunityItemWrapper}>
      <Grid container gap="6px" alignItems="flex-start">
        <Grid item marginTop="-8px">
          <Checkbox
            id={id}
            checked={isChecked}
            classes={checkboxClasses}
            withoutMargin
            onChange={(e) => {
              onChange?.(e);
              e.stopPropagation();
            }}
          />
        </Grid>
        <Grid
          item
          container
          gap="4px"
          flexDirection="column"
          flex="1 0"
          minWidth={0}
        >
          <Grid item container>
            {name && <T>{name}&nbsp;</T>}
            {date && (
              <T>
                <FormattedDate
                  value={new Date(date)}
                  year="numeric"
                  month="long"
                  day="2-digit"
                />
              </T>
            )}
          </Grid>
          <Grid item maxWidth="100%" width="fit-content" overflow="hidden">
            <SharedOpportunityDisplay
              opportunity={{
                id: 1,
                stage,
                status: isWon ? Status.Won : isOpen ? Status.Open : Status.Lost,
                amount,
              }}
              withStage
            />
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
};

const SingleItemWrapper = ({
  children,
  isHoverable = true,
  isOpportunityItem,
  onChange,
  id,
  tooltipMessage = "",
  isOpen,
  isDisabled,
  isVertical,
  isChecked,
  height,
}: IDropdownItem) => {
  const { classes } = useDropdownItemStyles({
    isOpportunityItem,
    isOpen,
    isDisabled,
    isHoverable,
    onChange,
    isVertical,
    isChecked,
    height,
  });

  const handleClick = (e: React.MouseEvent) => {
    if (e.target instanceof HTMLInputElement && e.target.type === "checkbox")
      return;

    if (!isDisabled && isHoverable) {
      onChange?.(e);
    }
  };

  return (
    <Tooltip title={tooltipMessage}>
      <div
        id={id}
        onClick={handleClick}
        className={classes.singleItem}
        data-testid={`singleItem ${!isDisabled ? "enabled" : "disabled"}`}
      >
        {children}
      </div>
    </Tooltip>
  );
};

export default DropdownItem;

export const useDropdownItemStyles = makeStyles<
  IDropdownItem,
  "removeIcon" | "icon"
>()((theme, props) => ({
  opportunityItemWrapper: {
    height: "100%",
    padding: 4,
    width: "100%",
  },
  circularProgress: {
    marginLeft: theme.spacing(1),
  },
  singleItem: {
    gap: props.isVertical ? 6 : "unset",
    height: props.height ?? (props.isVertical ? 48 : 32),
    backgroundColor: theme.palette.ivory,
    display: "flex",
    alignItems: "center",
    justifyContent: props.isVertical ? "flex-start" : "space-Between",
    borderRadius: "4px",
    margin: "2px 0",
    opacity: props.isDisabled ? "0.5" : "1",
    color: props.isDisabled ? theme.palette.greyReveal : theme.palette.midnight,
    "&:hover, &.hovered": {
      backgroundColor: !props.isHoverable
        ? "unset"
        : !props.isOpen
        ? theme.palette.offWhite
        : theme.palette.taupe250,
      cursor:
        props.isDisabled || !props.isHoverable
          ? "default"
          : props.isDraggable
          ? "grab"
          : props.isOpen || props.onChange
          ? "pointer"
          : "default",
    },
  },
  verticalItems: {
    maxWidth: 200,
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  leftSide: {
    display: "flex",
    alignItems: "center",
    marginLeft: "4px",
    overflow: "hidden",
    maxWidth: props.fullWidth ? "none" : "260px",
    width: props.fullWidth ? "100%" : "auto",
    columnGap: 2,
  },
  rightSide: {
    display: "flex",
    alignItems: "center",
    paddingRight: "8px",
  },
  itemElement: {
    margin: "0 2px",
  },
  icon: {
    fontSize: 16,
  },
  rightIcon: {
    fontSize: 16,
    color: theme.palette.midnight,
  },
  removeIcon: {
    position: "relative",
    right: 4,
    fontSize: 8,
  },
  label: {
    display: props.isVertical ? "block" : "inline",
    maxWidth: props.isVertical ? 200 : "unset",
    fontSize: "12px",
    lineHeight: "18px",
    marginLeft: props.isVertical ? 0 : 4,
    fontWeight: props.subItems?.length ? 500 : 400,
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    width: "100%",
  },
  emptyLabel: {
    fontStyle: "italic",
    color: theme.palette.midnight500,
  },
  subText: {
    color: theme.palette.greyReveal,
    maxWidth: props.isVertical ? 200 : "unset",
    fontSize: "12px",
    lineHeight: "18px",
    fontWeight: 500,
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
  },
}));

export const useTabsStyles = makeStyles()((theme) => ({
  content: {
    display: "flex",
    alignItems: "center",
    height: "26px",
    borderRadius: 22,
    width: "130px",
  },
  activeButton: {
    background: theme.palette.midnight,
    color: "white",
    "&:hover": {
      backgroundColor: theme.palette.midnight,
      cursor: "pointer",
    },
  },
  toggle: {
    display: "flex",
    width: "100%",
    backgroundColor: theme.palette.offWhite,
    padding: "2px",
    justifyContent: "space-between",
  },
  spacer: {
    width: "100%",
    height: "1px",
    borderBottom: `1px solid #ece8e1`,
    margin: "8px 0",
  },
}));

export const useCheckboxStyles = makeStyles()((theme) => ({
  root: {
    color: theme.palette.midnight,
    marginRight: -10,
  },
}));

export const useBtnStyles = makeStyles()(() => ({
  btn: {
    whiteSpace: "nowrap",
  },
}));
