import { createAsyncThunk } from "@reduxjs/toolkit";
import { fields as demoFields } from "data/demo";
import _ from "lodash";
import { Factory } from "models";
import Partnership, { DEMO_ID } from "models/Partnership";
import PartnershipOverviewView from "models/PartnershipOverviewView";
import {
  JSONAPIAttributes,
  JSONAPIResource,
  JSONSerializable,
} from "models/types";
import {
  FieldType,
  FilterType,
  isFilterTypeArray,
  MatchFilter,
  RawFieldType,
} from "screens/Frontoffice/screens/DataTables/shared/types";
import JSONAPIService from "services/JSONAPIService";
import { JSONAPIResponse } from "services/types";

import {
  IOverviewParameters,
  IOverviewResponse,
  OverviewActions,
  overviewResponseGuard,
  RootStateWithOverview,
} from "./types";

/**
 * Thunks to initialize the Overview state for a given partnership.
 * If the partnership is already represented in the state, simply return the
 * existing state.
 * If not, fetch or create the PartnershipOverviewView records, and load the data
 * using configured filters.
 */
export const initialize = createAsyncThunk(
  OverviewActions.initialize,
  async (
    {
      partnership,
      fromAccountMapping = false,
    }: { partnership: Partnership; fromAccountMapping?: boolean },
    thunkAPI
  ) => {
    const currentState = thunkAPI.getState() as RootStateWithOverview;
    const overviewState = currentState.overview.views[partnership.id];
    let view: PartnershipOverviewView;
    let overview: IOverviewResponse | undefined = undefined;
    let fields: Record<string, FieldType> | undefined = undefined;
    if (overviewState?.view === undefined) {
      if (partnership.isDemo) {
        view = new PartnershipOverviewView({
          id: "0",
          type: "partnership_overview_views",
          attributes: {
            common_customers_filters: [],
            common_opportunities_filters: [],
            common_partners_filters: [],
            prospects_to_customers_filters: [],
          },
          relationships: {
            partnership: {
              data: {
                id: String(partnership.id),
                type: "partnerships",
              },
            },
          },
        });
        overview = await partnership.loadAssessment({
          common_customers: _.concat(
            view.sharedFilters,
            view.commonCustomersFilters
          ),
          common_opportunities: _.concat(
            view.sharedFilters,
            view.commonOpportunitiesFilters
          ),
          prospects_to_customers: _.concat(
            view.sharedFilters,
            view.prospectsToCustomersFilters
          ),
          customers_to_prospects: _.concat(
            view.sharedFilters,
            view.customersToProspectsFilters
          ),
          open_opportunities_to_customers: view.sharedFilters,
        });
        fields = demoFields;
      } else {
        [view, fields] = await Promise.all([
          getOrCreateView(partnership),
          collectFields(partnership, fromAccountMapping),
        ]);
        overview = await loadOverview(partnership, {
          common_customers: _.concat(
            view.sharedFilters,
            view.commonCustomersFilters
          ),
          common_opportunities: _.concat(
            view.sharedFilters,
            view.commonOpportunitiesFilters
          ),
          prospects_to_customers: _.concat(
            view.sharedFilters,
            view.prospectsToCustomersFilters
          ),
          customers_to_prospects: _.concat(
            view.sharedFilters,
            view.customersToProspectsFilters
          ),
          open_opportunities_to_customers: view.sharedFilters,
        });
      }
    } else {
      view = overviewState.view;
      fields =
        overviewState.fields ??
        (await collectFields(partnership, fromAccountMapping));
    }
    return { fields, view, overview };
  }
);

/**
 * Update filters: save filters on PartnershipOverviewView, and load overview with
 * the new filters.
 */
export const updateView = createAsyncThunk(
  OverviewActions.updateView,
  async (
    {
      partnership,
      parameters,
    }: { partnership: Partnership; parameters: IOverviewParameters },
    thunkAPI
  ) => {
    const currentState = thunkAPI.getState() as RootStateWithOverview;
    const view = currentState.overview.views[partnership.id]?.view;
    const state = currentState.overview.views[partnership.id]?.state;
    let updatedView: PartnershipOverviewView | undefined;
    if (view && state) {
      const {
        view: updatedView,
        parameters: curatedParameters,
      } = getUpdatedView(parameters, view);
      saveOverviewView(updatedView);
      if (curatedParameters) {
        const result = partnership.isDemo
          ? await partnership.loadAssessment(curatedParameters)
          : await loadOverview(partnership, curatedParameters);
        return {
          view: updatedView,
          overview: result,
        };
      }
    } else {
      updatedView = view;
    }
    return { view: updatedView, overview: undefined };
  }
);

/// Internal functions

type IOverviewParameter = keyof Omit<IOverviewParameters, "shared">;

const allKPIs: IOverviewParameter[] = [
  "common_customers",
  "common_opportunities",
  "customers_to_prospects",
  "prospects_to_customers",
  "open_opportunities_to_customers",
];

const getOrCreateView = async (partnership: Partnership) => {
  const service = new JSONAPIService("partnership_overview_views");
  const response = await service.index({
    filters: {
      partnership: partnership.id,
    },
  });
  let recordData: JSONAPIResource<"partnership_overview_views">;
  if (response.data.data.length > 0) {
    recordData = response.data.data[0];
  } else {
    recordData = (
      await service.create(
        {
          common_customers_filters: [],
          common_opportunities_filters: [],
          common_partners_filters: [],
          prospects_to_customers_filters: [],
          customers_to_prospects_filters: [],
        },
        {
          partnership: {
            id: String(partnership.id),
            type: "partnerships",
          },
        }
      )
    ).data.data;
  }
  const record = Factory.createRecord(recordData);
  return record;
};

const collectFields = async (
  partnership: Partnership,
  fromAccountMapping: boolean
) => {
  if (fromAccountMapping) {
    return;
  }
  const service = new JSONAPIService("matches");
  const resourceDescription = await service.describe<{
    data: { fields: RawFieldType[] };
  }>(false, {
    filters: { partnership: partnership.id },
  });
  return _.transform(
    resourceDescription.data.data.fields.filter(
      ({ name }) => name !== "left_status" && name !== "right_status"
    ),
    (acc, field) => {
      acc[_.camelCase(field.name)] = {
        label: field.label,
        type: field.type,
        options: field.options,
        smartField: field.smart_field,
        fullyImported: field.fully_imported,
        partnerField: field.name.startsWith("right"),
        isPrivate: field.private,
        isDisabled: field.disabled,
        noSide: !!field.no_side,
        filter: !!field.filter,
        sort: !!field.sort,
      };
    },
    {} as Record<string, FieldType>
  );
};

const loadOverview = async (
  partnership: Partnership,
  parameters: Omit<IOverviewParameters, "shared">
) => {
  const payload = {} as {
    [kpi: string]: { [filter: string]: JSONSerializable };
  };
  const overviewFromInsights = getOverviewFromInsights(partnership);
  _.forEach(parameters, (filters: FilterType[] | undefined, kpi: string) => {
    if (filters !== undefined && !_.isEmpty(filters)) {
      payload[kpi] = MatchFilter.forAPI(filters);
    }
  });
  const options = {
    fields: {
      partnership_overviews: _.union(
        _.difference(
          ["nb_matched_accounts", ...Object.keys(parameters)],
          Object.keys(overviewFromInsights)
        ),
        Object.keys(payload)
      ),
    },
  };
  if (_.isEmpty(options.fields.partnership_overviews)) {
    return overviewResponseGuard(overviewFromInsights);
  }
  const service = new JSONAPIService("partnerships");
  const response = await service.rawPost<JSONAPIResponse>(
    partnership.id,
    "/overview/",
    payload,
    options
  );
  return overviewResponseGuard({
    ..._.omit(overviewFromInsights, options.fields.partnership_overviews),
    ...response.data.data.attributes,
  });
};

const getOverviewFromInsights = (partnership: Partnership) => {
  const insights = partnership.insights;
  const overview: IOverviewResponse = _.omitBy(
    {
      nb_matched_accounts: insights?.matches,
      common_customers: insights?.companyCommonCustomers,
      common_opportunities: insights?.companyCommonOpportunities,
      prospects_to_customers: insights?.companyProspectsToCustomers,
      customers_to_prospects: insights?.companyCustomersToProspects,
      open_opportunities_to_customers:
        insights?.companyOpenOpportunitiesToCustomers,
    },
    _.isNil
  );
  return overview;
};

const getViewField = (kpi: string) => {
  return "shared_filters";
};

export const getUpdatedView = (
  parameters: IOverviewParameters,
  view: PartnershipOverviewView
) => {
  const { shared, ..._parameters } = parameters;
  const sharedFilters = (shared ?? view.sharedFilters ?? []).map((value) => ({
    fieldname: value.fieldname,
    type: value.type,
    value: value.value,
  }));

  /** Initialize data to be updated on  PartnershipOverviewView */
  const parametersToUpdate: JSONAPIAttributes = {};
  if (shared !== undefined) {
    parametersToUpdate[getViewField("shared")] = shared.map((value) => ({
      fieldname: value.fieldname,
      type: value.type,
      value: value.value,
    }));
  }
  /** Initialize payload for request for Overview */
  const requestPayload: Omit<IOverviewParameters, "shared"> = {};

  /**
   * We populate `requestPayload` and `parametersToUpdate` in this loop.
   *    requestPayload: if shared filters have changed, or filter for KPI has changed
   *    parametersToUpdate: if has changed
   */
  _.each(allKPIs, (key) => {
    const valueOnView = view.attributes[getViewField(key)] ?? [];
    const filters = _parameters[key] ?? valueOnView;
    if (isFilterTypeArray(filters)) {
      if (_parameters[key] !== undefined) {
        parametersToUpdate[getViewField(key)] = filters.map((value) => ({
          fieldname: value.fieldname,
          type: value.type,
          value: value.value,
        }));
      }
      if (_parameters[key] !== undefined || shared !== undefined) {
        requestPayload[key] = _.concat(filters, sharedFilters);
      }
    }
  });

  return {
    view: new PartnershipOverviewView({
      id: String(view.id),
      type: "partnership_overview_views",
      attributes: { ...view.attributes, ...parametersToUpdate },
      relationships: view.relationships,
    }),
    parameters: requestPayload,
  };
};

const saveOverviewView = async (view: PartnershipOverviewView) => {
  /**
   * Save changes if any, and if not demo
   */
  if (view.partnership.id !== DEMO_ID) {
    const service = new JSONAPIService("partnership_overview_views");
    await service.update(view.id, view.attributes);
  }
};
