import CrmField from "models/CrmField";
import Match from "models/Match";
import { PageType } from "models/PageView";
import {
  defineMessages,
  FormattedHTMLMessage,
  FormattedMessage,
  MessageDescriptor,
} from "react-intl";
import { Available360MappingColumns } from "redux/mapping360/defaults";
import { AvailableNearboundAccountsColumns } from "redux/mapping360NearboundAccounts/defaults";
import {
  MatchFilterType,
  MatchSortOrder,
  Preset,
} from "screens/Frontoffice/screens/DataTables/shared/types";

const i18n = defineMessages({
  coSell: {
    id: "mapping360.goalsPresets.coSell",
    defaultMessage: "Co-Sell",
  },
  coSellDescription: {
    id: "mapping360.goalsPresets.coSellDescription",
    defaultMessage: "My opportunities vs Partners' opportunities",
  },
  identifyMyOverlaps: {
    id: "mapping360.goalsPresets.identifyMyOverlaps",
    defaultMessage: "Identify my overlaps",
  },
  identifyMyOverlapsDescription: {
    id: "mapping360.goalsPresets.identifyMyOverlapsDescription",
    defaultMessage: "All my accounts",
  },
  retainAndUpsell: {
    id: "mapping360.goalsPresets.retainAndUpsell",
    defaultMessage: "Retain my Customers",
  },
  retainAndUpsellDescription: {
    id: "mapping360.goalsPresets.retainAndUpsellDescription",
    defaultMessage: "My customers vs Partners' customers",
  },
  sourceOpportunities: {
    id: "mapping360.goalsPresets.sourceOpportunities",
    defaultMessage: "Source Opportunities",
  },
  sourceOpportunitiesDescription: {
    id: "mapping360.goalsPresets.sourceOpportunitiesDescription",
    defaultMessage: "My prospects vs Partners' customers",
  },
  influenceDeals: {
    id: "mapping360.goalsPresets.influenceDeals",
    defaultMessage: "Influence Deals",
  },
  influenceDealsDescription: {
    id: "mapping360.goalsPresets.influenceDealsDescription",
    defaultMessage: "My opportunities vs Partners' customers",
  },
  expandToNewMarkets: {
    id: "mapping360.goalsPresets.expandToNewMarkets",
    defaultMessage: "Lead Generation (AI &#10024;)",
  },
  expandToNewMarketsDescription: {
    id: "mapping360.goalsPresets.expandToNewMarketsDescription",
    defaultMessage: "Partners' customers not in my CRM",
  },
});

export const goalPresets = {
  coSell: {
    filter: [
      {
        fieldname: Available360MappingColumns.status,
        type: MatchFilterType.ANY_OF,
        value: [Match.STATUS_PROSPECT],
      },
      {
        fieldname: Available360MappingColumns.active,
        type: MatchFilterType.IS,
        value: true,
      },
      {
        fieldname: "rightAccountStatus",
        type: MatchFilterType.CONTAINS_ANY,
        value: [Match.PARTNER_OPPORTUNITY],
      },
    ],
    sort: [
      {
        fieldname: "openOpportunitiesAmountInDollars",
        order: MatchSortOrder.ORDER_DESC,
        fieldtype: CrmField.TYPE_UNKNOWN,
      },
      {
        fieldname: Available360MappingColumns.nbOpportunities,
        order: MatchSortOrder.ORDER_DESC,
        fieldtype: CrmField.TYPE_UNKNOWN,
      },
    ],
    columns: [
      Available360MappingColumns.name,
      Available360MappingColumns.starred,
      Available360MappingColumns.status,
      Available360MappingColumns.partnerSignals,
      Available360MappingColumns.ownerName,
      Available360MappingColumns.active,
      Available360MappingColumns.partnerOpportunities,
      Available360MappingColumns.countryCode,
      Available360MappingColumns.domain,
    ],
  },
  coSellNearboundAccounts: {
    filter: [
      {
        fieldname: AvailableNearboundAccountsColumns.Status,
        type: MatchFilterType.ANY_OF,
        value: [Match.STATUS_PROSPECT],
      },
      {
        fieldname: AvailableNearboundAccountsColumns.HasOpenDeal,
        type: MatchFilterType.IS,
        value: true,
      },
      {
        fieldname: "openOpportunityForIds__Len",
        type: MatchFilterType.GT,
        value: "0",
      },
    ],
    sort: [
      // TODO: add openOpportunitiesAmountInDollars when API provides it
      {
        fieldname: "open_deals_count",
        order: MatchSortOrder.ORDER_DESC,
        fieldtype: CrmField.TYPE_UNKNOWN,
      },
    ],
    columns: [
      AvailableNearboundAccountsColumns.Name,
      AvailableNearboundAccountsColumns.IsStarred,
      AvailableNearboundAccountsColumns.Status,
      AvailableNearboundAccountsColumns.PartnerSignalsCount,
      AvailableNearboundAccountsColumns.OwnerFullName,
      AvailableNearboundAccountsColumns.HasOpenDeal,
      AvailableNearboundAccountsColumns.OpenOpportunityFor,
      AvailableNearboundAccountsColumns.CountryIsoCode,
      AvailableNearboundAccountsColumns.Domain,
    ],
  },
  expandToNewMarkets: {
    filter: [],
    sort: [
      {
        fieldname: Available360MappingColumns.customerPresenceHighlightsCount,
        order: MatchSortOrder.ORDER_DESC,
        fieldtype: CrmField.TYPE_UNKNOWN,
      },
      {
        fieldname: Available360MappingColumns.nbPartners,
        order: MatchSortOrder.ORDER_DESC,
        fieldtype: CrmField.TYPE_UNKNOWN,
      },
    ],
    columns: [
      Available360MappingColumns.name,
      Available360MappingColumns.createdAt,
      Available360MappingColumns.customerPresenceHighlightsCount,
      Available360MappingColumns.domain,
      Available360MappingColumns.dealInfluencerList,
      Available360MappingColumns.countryName,
      Available360MappingColumns.industry,
      Available360MappingColumns.employeeRange,
      Available360MappingColumns.customerOf,
    ],
  },
  identifyMyOverlaps: {
    filter: [],
    sort: [
      {
        fieldname: Available360MappingColumns.nbPartners,
        order: MatchSortOrder.ORDER_DESC,
        fieldtype: CrmField.TYPE_UNKNOWN,
      },
    ],
    columns: [
      Available360MappingColumns.name,
      Available360MappingColumns.starred,
      Available360MappingColumns.status,
      Available360MappingColumns.partnerSignals,
      Available360MappingColumns.ownerName,
      Available360MappingColumns.active,
      Available360MappingColumns.partnerPresence,
      Available360MappingColumns.countryCode,
      Available360MappingColumns.domain,
    ],
  },
  identifyMyOverlapsNearboundAccounts: {
    filter: [],
    sort: [
      {
        fieldname: "customerOfIds__Len", // nbPartners
        order: MatchSortOrder.ORDER_DESC,
        fieldtype: CrmField.TYPE_UNKNOWN,
      },
    ],
    columns: [
      AvailableNearboundAccountsColumns.Name,
      AvailableNearboundAccountsColumns.IsStarred,
      AvailableNearboundAccountsColumns.Status,
      AvailableNearboundAccountsColumns.PartnerSignalsCount,
      AvailableNearboundAccountsColumns.OwnerFullName,
      AvailableNearboundAccountsColumns.HasOpenDeal,
      AvailableNearboundAccountsColumns.CustomerOf,
      AvailableNearboundAccountsColumns.CountryIsoCode,
      AvailableNearboundAccountsColumns.Domain,
    ],
  },
  sourceOpportunities: {
    filter: [
      {
        fieldname: Available360MappingColumns.status,
        type: MatchFilterType.ANY_OF,
        value: [Match.STATUS_PROSPECT],
      },
      {
        fieldname: Available360MappingColumns.active,
        type: MatchFilterType.IS,
        value: false,
      },
      {
        fieldname: "rightAccountStatus",
        type: MatchFilterType.CONTAINS_ANY,
        value: [Match.PARTNER_CUSTOMER],
      },
    ],
    sort: [
      {
        fieldname: Available360MappingColumns.partnerSignals,
        order: MatchSortOrder.ORDER_DESC,
        fieldtype: CrmField.TYPE_UNKNOWN,
      },
      {
        fieldname: Available360MappingColumns.nbPartners,
        order: MatchSortOrder.ORDER_DESC,
        fieldtype: CrmField.TYPE_UNKNOWN,
      },
    ],
    columns: [
      Available360MappingColumns.name,
      Available360MappingColumns.starred,
      Available360MappingColumns.status,
      Available360MappingColumns.partnerSignals,
      Available360MappingColumns.ownerName,
      Available360MappingColumns.partnerPresence,
      Available360MappingColumns.countryCode,
      Available360MappingColumns.domain,
    ],
  },
  sourceOpportunitiesNearboundAccounts: {
    filter: [
      {
        fieldname: AvailableNearboundAccountsColumns.Status,
        type: MatchFilterType.ANY_OF,
        value: [Match.STATUS_PROSPECT],
      },
      {
        fieldname: AvailableNearboundAccountsColumns.HasOpenDeal,
        type: MatchFilterType.IS,
        value: false,
      },
      {
        fieldname: "customerOfIds__Len",
        type: MatchFilterType.GT,
        value: "0",
      },
    ],
    sort: [
      {
        fieldname: AvailableNearboundAccountsColumns.PartnerSignalsCount,
        order: MatchSortOrder.ORDER_DESC,
        fieldtype: CrmField.TYPE_UNKNOWN,
      },
      {
        fieldname: "customerOfIds__Len",
        order: MatchSortOrder.ORDER_DESC,
        fieldtype: CrmField.TYPE_UNKNOWN,
      },
    ],
    columns: [
      AvailableNearboundAccountsColumns.Name,
      AvailableNearboundAccountsColumns.IsStarred,
      AvailableNearboundAccountsColumns.Status,
      AvailableNearboundAccountsColumns.PartnerSignalsCount,
      AvailableNearboundAccountsColumns.OwnerFullName,
      AvailableNearboundAccountsColumns.CustomerOf,
      AvailableNearboundAccountsColumns.CountryIsoCode,
      AvailableNearboundAccountsColumns.Domain,
    ],
  },
  influenceDeals: {
    filter: [
      {
        fieldname: "rightAccountStatus",
        type: MatchFilterType.CONTAINS_ANY,
        value: [Match.PARTNER_CUSTOMER],
      },
      {
        fieldname: Available360MappingColumns.active,
        type: MatchFilterType.IS,
        value: true,
      },
    ],
    sort: [
      {
        fieldname: "openOpportunitiesAmountInDollars",
        order: MatchSortOrder.ORDER_DESC,
        fieldtype: CrmField.TYPE_UNKNOWN,
      },
    ],
    columns: [
      Available360MappingColumns.name,
      Available360MappingColumns.starred,
      Available360MappingColumns.status,
      Available360MappingColumns.partnerSignals,
      Available360MappingColumns.ownerName,
      Available360MappingColumns.active,
      Available360MappingColumns.partnerPresence,
      Available360MappingColumns.countryCode,
      Available360MappingColumns.domain,
    ],
  },
  influenceDealsNearboundAccounts: {
    filter: [
      {
        fieldname: "customerOfIds__Len",
        type: MatchFilterType.GT,
        value: "0",
      },
      {
        fieldname: AvailableNearboundAccountsColumns.HasOpenDeal,
        type: MatchFilterType.IS,
        value: true,
      },
    ],
    // TODO: add this default sort when API provides it
    sort: [],
    columns: [
      AvailableNearboundAccountsColumns.Name,
      AvailableNearboundAccountsColumns.IsStarred,
      AvailableNearboundAccountsColumns.Status,
      AvailableNearboundAccountsColumns.PartnerSignalsCount,
      AvailableNearboundAccountsColumns.OwnerFullName,
      AvailableNearboundAccountsColumns.HasOpenDeal,
      AvailableNearboundAccountsColumns.CustomerOf,
      AvailableNearboundAccountsColumns.CountryIsoCode,
      AvailableNearboundAccountsColumns.Domain,
    ],
  },
  retainAndUpsell: {
    filter: [
      {
        fieldname: Available360MappingColumns.status,
        type: MatchFilterType.ANY_OF,
        value: [Match.STATUS_CUSTOMER],
      },
      {
        fieldname: "rightAccountStatus",
        type: MatchFilterType.CONTAINS_ANY,
        value: [Match.PARTNER_CUSTOMER],
      },
    ],
    sort: [
      {
        fieldname: Available360MappingColumns.nbPartners,
        order: MatchSortOrder.ORDER_DESC,
        fieldtype: CrmField.TYPE_UNKNOWN,
      },
    ],
    columns: [
      Available360MappingColumns.name,
      Available360MappingColumns.starred,
      Available360MappingColumns.status,
      Available360MappingColumns.ownerName,
      Available360MappingColumns.partnerPresence,
      Available360MappingColumns.countryCode,
      Available360MappingColumns.domain,
    ],
  },
  retainAndUpsellNearboundAccounts: {
    filter: [
      {
        fieldname: AvailableNearboundAccountsColumns.Status,
        type: MatchFilterType.ANY_OF,
        value: [Match.STATUS_CUSTOMER],
      },
      {
        fieldname: "customerOfIds__Len",
        type: MatchFilterType.GT,
        value: "0",
      },
    ],
    sort: [
      {
        fieldname: "customerOfIds__Len",
        order: MatchSortOrder.ORDER_DESC,
        fieldtype: CrmField.TYPE_UNKNOWN,
      },
    ],
    columns: [
      AvailableNearboundAccountsColumns.Name,
      AvailableNearboundAccountsColumns.IsStarred,
      AvailableNearboundAccountsColumns.Status,
      AvailableNearboundAccountsColumns.OwnerFullName,
      AvailableNearboundAccountsColumns.CustomerOf,
      AvailableNearboundAccountsColumns.CountryIsoCode,
      AvailableNearboundAccountsColumns.Domain,
    ],
  },
} as { [key: string]: Preset };

export type IGoalPreset = {
  description: JSX.Element;
  id: number;
  isAvailableFree?: boolean;
  presets: Preset;
  label: JSX.Element;
  name: MessageDescriptor;
  pageType: PageType;
};
export type IGoalTeaserPreset = Omit<IGoalPreset, "presets">;
export type IGoal = IGoalPreset | IGoalTeaserPreset;
export const isIGoalPreset = (goal?: IGoal): goal is IGoalPreset => {
  return !!goal && goal.hasOwnProperty("presets");
};

export const identifyMyOverlaps: IGoalPreset = {
  description: <FormattedMessage {...i18n.identifyMyOverlapsDescription} />,
  id: 1,
  presets: goalPresets.identifyMyOverlaps,
  label: <FormattedMessage {...i18n.identifyMyOverlaps} />,
  name: i18n.identifyMyOverlaps,
  pageType: PageType.mapping360Overlaps,
};
export const identifyMyOverlapsNearboundAccounts: IGoalPreset = {
  ...identifyMyOverlaps,
  id: 7,
  presets: goalPresets.identifyMyOverlapsNearboundAccounts,
  pageType: PageType.mapping360OverlapsNearboundAccounts,
};
export const sourceOpportunities: IGoalPreset = {
  description: <FormattedMessage {...i18n.sourceOpportunitiesDescription} />,
  id: 2,
  presets: goalPresets.sourceOpportunities,
  label: <FormattedMessage {...i18n.sourceOpportunities} />,
  name: i18n.sourceOpportunities,
  pageType: PageType.mapping360Sourcing,
};
export const sourceOpportunitiesNearboundAccounts: IGoalPreset = {
  ...sourceOpportunities,
  id: 8,
  presets: goalPresets.sourceOpportunitiesNearboundAccounts,
  pageType: PageType.mapping360SourcingNearboundAccounts,
};
export const influenceDeals: IGoalPreset = {
  description: <FormattedMessage {...i18n.influenceDealsDescription} />,
  id: 3,
  presets: goalPresets.influenceDeals,
  label: <FormattedMessage {...i18n.influenceDeals} />,
  name: i18n.influenceDeals,
  pageType: PageType.mapping360Influence,
  isAvailableFree: true,
};
export const influenceDealsNearboundAccounts: IGoalPreset = {
  ...influenceDeals,
  id: 9,
  presets: goalPresets.influenceDealsNearboundAccounts,
  pageType: PageType.mapping360InfluenceNearboundAccounts,
};
export const expandToNewMarkets: IGoalPreset = {
  description: <FormattedMessage {...i18n.expandToNewMarketsDescription} />,
  id: 4,
  label: <FormattedHTMLMessage {...i18n.expandToNewMarkets} />,
  name: i18n.expandToNewMarkets,
  pageType: PageType.mapping360Expand,
  presets: goalPresets.expandToNewMarkets,
};
export const retainAndUpsell: IGoalPreset = {
  description: <FormattedMessage {...i18n.retainAndUpsellDescription} />,
  id: 5,
  presets: goalPresets.retainAndUpsell,
  label: <FormattedMessage {...i18n.retainAndUpsell} />,
  name: i18n.retainAndUpsell,
  pageType: PageType.mapping360Upsell,
};
export const retainAndUpsellNearboundAccounts: IGoalPreset = {
  ...retainAndUpsell,
  id: 11,
  presets: goalPresets.retainAndUpsellNearboundAccounts,
  pageType: PageType.mapping360UpsellNearboundAccounts,
};
export const coSell: IGoalPreset = {
  description: <FormattedMessage {...i18n.coSellDescription} />,
  id: 6,
  presets: goalPresets.coSell,
  label: <FormattedMessage {...i18n.coSell} />,
  name: i18n.coSell,
  pageType: PageType.mapping360CoSell,
};
export const coSellNearboundAccounts: IGoalPreset = {
  ...coSell,
  id: 12,
  presets: goalPresets.coSellNearboundAccounts,
  pageType: PageType.mapping360CoSellNearboundAccounts,
};

// unlocked goal based 360
export const mapping360Goals = [
  expandToNewMarkets,
  identifyMyOverlaps,
  sourceOpportunities,
  influenceDeals,
  retainAndUpsell,
  coSell,
];

export const nearboundAccountsGoals = [
  expandToNewMarkets,
  identifyMyOverlapsNearboundAccounts,
  sourceOpportunitiesNearboundAccounts,
  influenceDealsNearboundAccounts,
  retainAndUpsellNearboundAccounts,
  coSellNearboundAccounts,
];

// free users
export const mapping360GoalsLocked = [
  influenceDeals,
  expandToNewMarkets,
  identifyMyOverlaps,
  sourceOpportunities,
  retainAndUpsell,
  coSell,
];

export const nearboundAccountsGoalsLocked = [
  influenceDealsNearboundAccounts,
  expandToNewMarkets,
  identifyMyOverlapsNearboundAccounts,
  sourceOpportunitiesNearboundAccounts,
  retainAndUpsellNearboundAccounts,
  coSellNearboundAccounts,
];
