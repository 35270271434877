import { createReducer } from "@reduxjs/toolkit";
import _ from "lodash";

import { clear, persist } from "./actions";
import { StorageState } from "./typing";

const doPersist = (draft: StorageState, action: ReturnType<typeof persist>) => {
  const { key, value } = action.payload;
  _.set(draft, key, value);
};

const _unsetKey = (draft: StorageState, path: string[]) => {
  _.unset(draft, path);
  if (path.length >= 1 && _.isEmpty(_.get(draft, path.slice(0, -1)))) {
    _unsetKey(draft, path.slice(0, -1));
  }
};

const doClear = (draft: StorageState, action: ReturnType<typeof clear>) => {
  _unsetKey(draft, action.payload.split("."));
};

export default createReducer<StorageState>({}, (builder) =>
  builder.addCase(persist, doPersist).addCase(clear, doClear)
);
