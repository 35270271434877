import { PickerSelectionState } from "@mui/x-date-pickers/internals";
import { DateRange } from "@mui/x-date-pickers-pro";
import dayjs, { Dayjs } from "dayjs";
import { useEffect, useState } from "react";
import { DateRangeFilterType, DateRangeTimeframe } from "redux/typing";

export const useDateRangeFilter = ({
  data,
  handleChange,
}: {
  data: DateRangeFilterType;
  handleChange: (value: DateRangeFilterType | null) => void;
}) => {
  const [anchorEl, setAnchorEl] = useState<
    HTMLDivElement | HTMLButtonElement
  >();
  const [displayCalendar, setDisplayCalendar] = useState(false);
  // This state is used to hightlight the "Custom Range" dropdown item when editing the calendar.
  const [editingRange, setEditingRange] = useState(false);
  const [calendarValue, setCalendarValue] = useState<
    DateRange<Dayjs> | undefined
  >(undefined);

  const handleOpenOptions = (
    event: React.MouseEvent<HTMLDivElement | HTMLButtonElement>
  ) => {
    setAnchorEl(event.currentTarget);
    if (data && data.rangeType === DateRangeTimeframe.customRange) {
      setDisplayCalendar(true);
    }
  };
  const handleCloseOptions = () => {
    setAnchorEl(undefined);
    // This timeout corresponds to the fade-out transition of the popover
    setTimeout(() => {
      setEditingRange(false);
      setDisplayCalendar(false);
    }, 200);
  };

  const selectOption = (option: {
    value: DateRangeTimeframe | null;
    label: JSX.Element;
  }) => {
    if (option.value === DateRangeTimeframe.customRange) {
      setDisplayCalendar(true);
      // We need to dispatch this event to resize the popover after updating the content.
      window.dispatchEvent(new CustomEvent("resize"));
    } else {
      setCalendarValue(undefined);
      handleChange(option.value === null ? null : { rangeType: option.value });
      handleCloseOptions();
    }
  };

  const handleChangeDateRange = (
    value: DateRange<Dayjs>,
    selectionState?: PickerSelectionState | undefined
  ) => {
    setCalendarValue(value);
    if (selectionState === "finish") {
      setEditingRange(false);
      handleChange({
        rangeType: DateRangeTimeframe.customRange,
        selectionRange: {
          start: (value[0] as Dayjs).toDate(),
          end: (value[1] as Dayjs).toDate(),
        },
      });
      handleCloseOptions();
    } else {
      setEditingRange(true);
    }
  };

  useEffect(() => {
    if (data && data.rangeType === DateRangeTimeframe.customRange) {
      const value = Object.values(data.selectionRange).map((v: any) =>
        dayjs(v)
      );
      setCalendarValue(value as DateRange<Dayjs>);
    }
  }, [data]);

  return {
    anchorEl,
    displayCalendar,
    handleOpenOptions,
    handleCloseOptions,
    selectOption,
    handleChangeDateRange,
    calendarValue,
    editingRange,
  };
};
