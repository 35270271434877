import CrmField from "models/CrmField";
import SWQLOperator from "models/SWQLOperator";

import OperatorAddWidget from "../OperatorAddWidget";
import { useSWQLContext } from "../SWQLContext";
import {
  isSWQLBooleanNode,
  isSWQLConstantNode,
  isSWQLEmptyNode,
  isSWQLLookupEmptyNode,
  isSWQLLookupExistsNode,
  isSWQLLookupNode,
  SWQLBooleanOperator,
  SWQLNode,
  SWQLRightValue,
} from "../SWQLTypes";
import BooleanField from "./BooleanField";
import CurrencyField from "./CurrencyField";
import DateField from "./DateField";
import NumericField from "./NumericField";
import PicklistField from "./PicklistField";
import TextField from "./TextField";

export type ValueFieldProps = {
  node: SWQLNode;
  onChange: (node: SWQLNode) => void;
  onAddNode: (operator: SWQLBooleanOperator) => void;
};

const REFERENCES_TYPES = [
  CrmField.TYPE_REFERENCES_ACCOUNT,
  CrmField.TYPE_REFERENCES_USER,
  CrmField.TYPE_REFERENCES_OPPORTUNITY,
  CrmField.TYPE_REFERENCES_CONTACT,
];
const ValueField = ({ node, onChange, onAddNode }: ValueFieldProps) => {
  const { getField } = useSWQLContext();

  // TODO: this could be refactored such that node here is always a SWQLRuleNode.
  //       Probably with the node being passed down as prop instead of via context.
  if (
    isSWQLBooleanNode(node) ||
    isSWQLEmptyNode(node) ||
    isSWQLConstantNode(node) ||
    isSWQLLookupNode(node) ||
    isSWQLLookupExistsNode(node) ||
    isSWQLLookupEmptyNode(node)
  ) {
    return null;
  }

  const field = getField(node.left.field_id);

  if (field === undefined) {
    return null;
  }

  const handleChange = (value: SWQLRightValue, extra = {}) => {
    const newNode = { ...node };
    newNode.right = { ...node.right, ...extra, value };
    onChange(newNode);
  };

  const type = field.type;
  const value = node.right.value;

  if (
    [SWQLOperator.TYPE_IS, SWQLOperator.TYPE_IS_NOT].includes(node.operation) &&
    value === null
  ) {
    return <OperatorAddWidget onAddNode={onAddNode} />;
  }

  if (
    [CrmField.TYPE_TEXT, ...REFERENCES_TYPES].includes(type) &&
    !SWQLOperator.isInOrNotIn(node)
  ) {
    return (
      // @ts-expect-error -- We shouldn't cast the value here, the types should naturally match.
      <TextField value={value} onChange={handleChange} onAddNode={onAddNode} />
    );
  }

  if (
    type === CrmField.TYPE_PICKLIST ||
    ([CrmField.TYPE_TEXT, CrmField.TYPE_BOOLEAN, ...REFERENCES_TYPES].includes(
      type
    ) &&
      SWQLOperator.isInOrNotIn(node))
  ) {
    return (
      <PicklistField
        // @ts-expect-error -- We shouldn't cast the value here, the types should naturally match.
        value={value}
        onChange={handleChange}
        field={field}
        onAddNode={onAddNode}
      />
    );
  }

  if (type === CrmField.TYPE_NUMBER || type === CrmField.TYPE_INTEGER) {
    return (
      <NumericField
        // @ts-expect-error -- We shouldn't cast the value here, the types should naturally match.
        value={value}
        onChange={handleChange}
        onAddNode={onAddNode}
      />
    );
  }

  if (type === CrmField.TYPE_CURRENCY) {
    return (
      <CurrencyField
        // @ts-expect-error -- We shouldn't cast the value here, the types should naturally match.
        amount={node.right.value?.amount}
        onChange={handleChange}
        onAddNode={onAddNode}
        // @ts-expect-error -- We shouldn't cast the value here, the types should naturally match.
        currency={node.right.value?.currency_iso_code}
      />
    );
  }

  if (
    type === CrmField.TYPE_BOOLEAN &&
    (node.operation === SWQLOperator.TYPE_IS ||
      node.operation === SWQLOperator.TYPE_IS_NOT)
  ) {
    return (
      <BooleanField
        // @ts-expect-error -- We shouldn't cast the value here, the types should naturally match.
        value={value}
        onChange={handleChange}
        onAddNode={onAddNode}
      />
    );
  }

  if (type === CrmField.TYPE_DATETIME || type === CrmField.TYPE_DATE) {
    // @ts-expect-error -- We shouldn't cast the value here, the types should naturally match.
    return <DateField value={value} onChange={handleChange} />;
  }

  return null;
};

export default ValueField;
