import { createAction } from "@reduxjs/toolkit";
import Record from "models/Record";
import User from "models/User";

import { UserActions } from "./typing";

export const setProfile = createAction<User>(UserActions.setProfile);

export const setLoggedIn = createAction<boolean>(UserActions.setLoggedIn);

export const setCompany = createAction<Record>(UserActions.setCompany);
