import { cloneDeep } from "lodash";

export const getTrimmedData = <T>(object: T): T => {
  const obj = cloneDeep(object);
  if (obj && typeof obj === "object") {
    for (const key in obj) {
      const value = obj[key as keyof typeof obj];
      if (typeof value === "object") {
        obj[key as keyof typeof obj] = getTrimmedData(value);
      } else if (typeof value === "string") {
        ((obj[
          key as keyof typeof obj
        ] as unknown) as string) = (value as string).trim();
      }
    }
  }
  return obj;
};
