import Grid from "@mui/material/Grid";
import { isRejected } from "@reduxjs/toolkit";
import BaseLoginPage from "components/ui/BaseLoginPage";
import Button from "components/ui/Button";
import SelectBoxGroup from "components/ui/SelectBoxGroup";
import { T } from "components/ui/Typography";
import { goToNext } from "helpers/goToNext";
import usePushNotification from "hooks/usePushNotification";
import useSegment from "hooks/useSegment";
import useUserProfile from "hooks/useUserProfile";
import generic from "i18n/generic";
import { makeStyles } from "makeStyles";
import User, { USER_ROLE_LABELS, UserRole } from "models/User";
import { useEffect, useState } from "react";
import { defineMessages, FormattedHTMLMessage } from "react-intl";
import { useDispatch } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import { RevealStore } from "redux/typing";
import { fetchProfile, updateProfile } from "redux/user/thunks";
import UserService from "services/UserService";

export const validateUser = (profile: User, store: RevealStore) => {
  const service = new UserService();
  if (!service.isImpersonating && !profile.role) {
    return false;
  }
  return true;
};

export const OnboardingPartnersUser = () => {
  const location = useLocation();
  const history = useHistory();
  const dispatch = useDispatch();
  const { classes } = useStyles();
  const { track } = useSegment();
  const { classes: selectClasses } = useSelectStyles();
  const { profile } = useUserProfile();
  const pushNotification = usePushNotification();

  const [role, setRole] = useState("");

  const options = Object.values(UserRole).map((key) => {
    const content = <T textAlign="center">{USER_ROLE_LABELS[key]}</T>;
    return {
      value: key,
      content,
    };
  });

  const handleSubmit = async () => {
    const result = await dispatch(
      updateProfile({
        attributes: { role, timezone: new Date().getTimezoneOffset() },
      })
    );
    if (isRejected(result)) {
      pushNotification("default_error");
    } else {
      await dispatch(fetchProfile());
      track("Updated user role", { userId: profile.id });
      next();
    }
  };

  const next = () => {
    history.push(goToNext(location, "/"));
  };

  useEffect(() => {
    if (profile.role) {
      next();
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <BaseLoginPage
      title={i18n.leftPanelTitle}
      titleSmall={i18n.leftPanelTitleSmall}
    >
      <Grid
        container
        direction="column"
        alignContent="center"
        className={classes.root}
      >
        <Grid item xs={12}>
          <T h2 bold textAlign="center">
            <FormattedHTMLMessage {...i18n.title} />
          </T>
        </Grid>
        <Grid item xs={12} className={classes.marginBottom}>
          <T textAlign="center">
            <FormattedHTMLMessage {...i18n.subtitle} />
          </T>
        </Grid>
        <Grid item xs={12} className={classes.marginBottom}>
          <SelectBoxGroup
            values={[role]}
            onSelect={setRole}
            options={options}
            orientation="vertical"
            classes={selectClasses}
            multiSelect={false}
          />
        </Grid>
        <Grid item xs={12}>
          <Button
            label={generic.next}
            disabled={!Boolean(role)}
            onClick={handleSubmit}
            classes={{ btn: classes.fullWidth }}
          />
        </Grid>
      </Grid>
    </BaseLoginPage>
  );
};

export default OnboardingPartnersUser;

// I18N

const i18n = defineMessages({
  leftPanelTitle: {
    id: "onboarding.User.leftPanelTitle",
    defaultMessage: "Every team<br/>can win<br/>with Reveal",
  },
  leftPanelTitleSmall: {
    id: "onboarding.User.leftPanelTitleSmall",
    defaultMessage: "Every team can win<br/>with Reveal",
  },
  subtitle: {
    id: "onboarding.User.subtitle",
    defaultMessage:
      "Help us understand your needs to customize your partnership experience",
  },
  title: {
    id: "onboarding.User.title",
    defaultMessage: "What's your role?",
  },
});

// CSS

const useStyles = makeStyles()((theme) => ({
  root: {
    width: 360,
    [theme.breakpoints.down("md")]: {
      width: "100%",
    },
  },
  fullWidth: {
    width: "100%",
  },
  marginBottom: {
    marginBottom: theme.spacing(3),
  },
}));

const useSelectStyles = makeStyles()((theme) => ({
  container: {
    width: 360,
  },
  tile: {
    height: theme.spacing(6),
  },
  inactive: {
    border: `1px solid ${theme.palette.taupe}`,
    "&:hover": {
      border: `1px solid ${theme.palette.midnight}`,
    },
  },
  innerTile: {
    background: theme.palette.ivory,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  selectable: {
    "&:hover": {
      backgroundColor: theme.palette.ivory,
    },
  },
}));
