import _ from "lodash";

export const revenueMultiplierValueFormatter = (row: any) => {
  const averageWinRate = _.get(row, "averageWinRate", null);
  const projectedWinRate = _.get(row, "projectedWinRate", null);
  const averageOpportunityAmount = _.get(row, "averageOpportunityAmount", null);
  const projectedOpportunityAmount = _.get(
    row,
    "projectedOpportunityAmount",
    null
  );

  let revenueMultiplierValue = "--";

  if (
    _.every([
      // not undefined and not 0
      averageWinRate,
      projectedWinRate,
      averageOpportunityAmount,
      projectedOpportunityAmount,
    ])
  ) {
    const winRateMultiplier = projectedWinRate / averageWinRate;
    const amountMultiplier =
      projectedOpportunityAmount / averageOpportunityAmount;
    const revenueMultiplier = winRateMultiplier * amountMultiplier;
    if (revenueMultiplier > 1) {
      revenueMultiplierValue = `x${revenueMultiplier.toFixed(1)}`;
    }
  }

  return revenueMultiplierValue;
};
