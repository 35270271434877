import { createSvgIcon } from "@mui/material/utils";

import { SvgIcon } from "./types";

const displayName = "Runs";

const Runs: SvgIcon = createSvgIcon(
  // Using transform to enlarge from provided viewBox="0 0 20 20"
  // to default "0 0 24 24" from `createSvgIcon` -> `SvgIcon`.
  <g fill="none" transform="matrix(1.2,0,0,1.2,2,2)">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6.28972 2.35959C7.50957 2.00315 8.81212 2.05187 10.0019 2.49844C10.9895 2.8691 11.8552 3.49724 12.5121 4.3125H10.4374C10.0923 4.3125 9.81244 4.59232 9.81244 4.9375C9.81244 5.28268 10.0923 5.5625 10.4374 5.5625H13.2499C13.9403 5.5625 14.4999 5.00286 14.4999 4.3125V1.5C14.4999 1.15482 14.2201 0.875 13.8749 0.875C13.5298 0.875 13.2499 1.15482 13.2499 1.5V3.25076C12.4865 2.39658 11.5243 1.73468 10.4412 1.32816C8.99821 0.786567 7.41852 0.727482 5.93912 1.15977C4.45972 1.59205 3.16039 2.49239 2.23605 3.72572C1.31171 4.95904 0.812213 6.45885 0.8125 8.00011C0.812565 8.34529 1.09244 8.62506 1.43762 8.62499C1.78279 8.62493 2.06256 8.34505 2.0625 7.99988C2.06226 6.72901 2.47413 5.49233 3.2363 4.47538C3.99848 3.45843 5.06986 2.71604 6.28972 2.35959ZM14.4374 7.37502C14.7826 7.37495 15.0625 7.65472 15.0625 7.9999C15.0628 9.54116 14.5633 11.041 13.639 12.2743C12.7146 13.5076 11.4153 14.408 9.93591 14.8402C8.45651 15.2725 6.87682 15.2134 5.43385 14.6718C4.38006 14.2763 3.44069 13.6391 2.6875 12.8182V14.5625C2.6875 14.9077 2.40768 15.1875 2.0625 15.1875C1.71732 15.1875 1.4375 14.9077 1.4375 14.5625V11.75C1.4375 11.0597 1.99714 10.5 2.6875 10.5H5.5C5.84518 10.5 6.125 10.7798 6.125 11.125C6.125 11.4702 5.84518 11.75 5.5 11.75H3.41401C4.06446 12.5349 4.91094 13.1404 5.87309 13.5016C7.06291 13.9481 8.36546 13.9969 9.58532 13.6404C10.8052 13.284 11.8766 12.5416 12.6387 11.5246C13.4009 10.5077 13.8128 9.271 13.8125 8.00013C13.8125 7.65495 14.0922 7.37508 14.4374 7.37502Z"
      fill="currentColor"
    />
  </g>,

  displayName
);

Runs.displayName = displayName;
export default Runs;
