import { Drawer } from "@mui/material";
import { T } from "components/ui/Typography";
import { makeStyles } from "makeStyles";
import IntroductionRequestForm from "models/IntroductionRequestForm";
import { defineMessages, FormattedMessage } from "react-intl";

import { useGetIntroTemplateDrawer } from "../hooks/useGetIntroTemplateDrawer";
import PartnerSelector from "./PartnerSelector";
import QuestionForm from "./QuestionForm";

type Props = {
  isDrawerOpen: boolean;
  closeDrawer: () => void;
  introRequestForm: IntroductionRequestForm | null;
  setRecordId: (recordId: number) => void;
};

export const GetIntroTemplateDrawer = ({
  isDrawerOpen,
  closeDrawer,
  introRequestForm,
  setRecordId,
}: Props) => {
  const { classes, cx } = useStyles();
  const {
    selectedPartnerIds,
    setSelectedPartnerIds,
    saveForm,
    resetSelectedPartnerIds,
  } = useGetIntroTemplateDrawer(introRequestForm, setRecordId);

  const handleClose = () => {
    resetSelectedPartnerIds();
    closeDrawer();
  };

  return (
    <Drawer
      anchor="right"
      classes={{ paper: classes.drawerPaper }}
      open={isDrawerOpen}
      onClose={handleClose}
    >
      <div className={classes.header}>
        <T h3 bold>
          <FormattedMessage {...i18n.title} />
        </T>
        <T titleSmall className={classes.description}>
          <FormattedMessage {...i18n.description} />
        </T>
      </div>
      <div className={classes.container}>
        <T labelSmall>
          <FormattedMessage {...i18n.appliedTo} />
        </T>
        <PartnerSelector
          initialPartnerIds={selectedPartnerIds}
          setSelectedPartnerIds={setSelectedPartnerIds}
        />
      </div>
      <div className={cx(classes.container, classes.fullHeight)}>
        <T labelSmall>
          <FormattedMessage {...i18n.questions} />
        </T>
        <QuestionForm
          values={introRequestForm?.formData ?? null}
          onClose={handleClose}
          onSave={saveForm}
        />
      </div>
    </Drawer>
  );
};

const useStyles = makeStyles()((theme) => ({
  drawerPaper: {
    background: theme.palette.greyLight050,
    display: "flex",
    flexDirection: "column",
    rowGap: 20,
    padding: theme.spacing(3),
    width: 640,
  },
  header: {
    display: "flex",
    flexDirection: "column",
    rowGap: theme.spacing(0.5),
  },
  description: {
    fontWeight: 400,
    color: theme.palette.alpha500,
    paddingBottom: 10,
  },
  container: {
    display: "flex",
    flexDirection: "column",
    rowGap: theme.spacing(1),
  },
  fullHeight: {
    height: "100%",
  },
}));

const i18n = defineMessages({
  title: {
    id: "crm.AccountSettings.GetIntroTemplateDrawer.title",
    defaultMessage: "Get Intro custom template form",
  },
  description: {
    id: "crm.AccountSettings.GetIntroTemplateDrawer.description",
    defaultMessage:
      "This form will be shown to your sales team, to make sure their request match your standard.",
  },
  appliedTo: {
    id: "crm.AccountSettings.GetIntroTemplateDrawer.appliedTo",
    defaultMessage: "This form should be applied to:",
  },
  questions: {
    id: "crm.AccountSettings.GetIntroTemplateDrawer.questions",
    defaultMessage: "Questions:",
  },
});
