import Checkbox from "@mui/material/Checkbox";

import OperatorAddWidget from "../OperatorAddWidget";
import { SWQLBooleanOperator, SWQLRightValue } from "../SWQLTypes";

type Props = {
  value?: boolean;
  onChange: (value: SWQLRightValue, extra?: object) => void;
  onAddNode: (operator: SWQLBooleanOperator) => void;
};

const BooleanField = ({ value, onChange, onAddNode }: Props) => {
  return (
    <>
      <Checkbox checked={value} onChange={(e) => onChange(e.target.checked)} />
      <OperatorAddWidget onAddNode={onAddNode} />
    </>
  );
};

export default BooleanField;
