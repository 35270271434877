import { createSvgIcon } from "@mui/material/utils";

import { SvgIcon } from "./types";

const displayName = "Plus";

const Plus: SvgIcon = createSvgIcon(
  // Using transform to enlarge from provided viewbox="0 0 16 16"
  // to default "0 0 24 24" from `createSvgIcon` -> `SvgIcon`.
  <g fill="none" transform="matrix(1.5,0,0,1.5,0,0)">
    <path
      d="M8.49992 3.33331C8.49992 3.05717 8.27606 2.83331 7.99992 2.83331C7.72378 2.83331 7.49992 3.05717 7.49992 3.33331V7.49998H3.33325C3.05711 7.49998 2.83325 7.72384 2.83325 7.99998C2.83325 8.27612 3.05711 8.49998 3.33325 8.49998H7.49992V12.6666C7.49992 12.9428 7.72378 13.1666 7.99992 13.1666C8.27606 13.1666 8.49992 12.9428 8.49992 12.6666V8.49998H12.6666C12.9427 8.49998 13.1666 8.27612 13.1666 7.99998C13.1666 7.72384 12.9427 7.49998 12.6666 7.49998H8.49992V3.33331Z"
      fill="currentColor"
    />
  </g>,
  displayName
);

Plus.displayName = displayName;
export default Plus;
