import { LoginCallback } from "@okta/okta-react";
import OktaIdpInitiator from "components/okta/OktaIdpInitiator";
import OktaResolver from "components/okta/OktaResolver";
import LoginBackofficePage from "screens/Frontoffice/screens/Login/components/LoginBackoffice";
import RequestPartnerChangeDetails from "screens/Frontoffice/screens/PartnerDetails/components/RequestPartnerChangeDetails";
import Profile from "screens/Frontoffice/screens/Profile";
import AcceptInvitation from "screens/Frontoffice/screens/Registration/components/AcceptInvitation";
import EmailConfirmation from "screens/Frontoffice/screens/Registration/components/EmailConfirmation";
import ResetPassword from "screens/Frontoffice/screens/ResetPassword";

import { RouteConfig } from "./types";

export const sharedUsersRoutes: RouteConfig[] = [
  {
    path: "/password/reset",
    exact: true,
    component: ResetPassword,
    isProtected: false,
    hideNavbar: true,
  },
  {
    path: "/confirm/email",
    exact: true,
    component: EmailConfirmation,
    isProtected: false,
  },
  {
    path: "/profile",
    exact: true,
    component: Profile,
    isProtected: true,
    showSidebar: true,
  },
  {
    path: "/join-invited",
    component: AcceptInvitation,
    exact: true,
    isProtected: false,
    hideNavbar: true,
  },
  {
    path: "/sso/okta",
    component: LoginCallback,
    exact: true,
    isProtected: false,
  },
  {
    path: "/okta",
    component: OktaResolver,
    exact: true,
    isProtected: false,
  },
  {
    path: "/okta/:idpId",
    component: OktaIdpInitiator,
    exact: true,
    isProtected: false,
  },
  {
    path: "/request-partner-change-details",
    exact: true,
    component: RequestPartnerChangeDetails,
    isProtected: false,
  },
];

export default [
  {
    path: "/login",
    exact: true,
    component: LoginBackofficePage,
    isProtected: false,
    allowIncompleteOnboarding: true,
  },
  ...sharedUsersRoutes,
] as RouteConfig[];
