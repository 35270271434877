import { makeStyles } from "makeStyles";

import { Props, Variant } from "./types";

export const Box = ({
  children,
  className,
  containerClassName,
  id,
  onClick,
  variant = "default",
}: Props) => {
  const { classes, cx } = useStyles({ variant });
  return (
    <div id={id} className={cx(className, classes.root)} onClick={onClick}>
      <div className={cx(containerClassName, classes.container)}>
        {children}
      </div>
    </div>
  );
};

// CSS

const useStyles = makeStyles<{ variant: Variant }>()((theme, props) => ({
  root: {
    borderRadius: 8,
    backgroundImage:
      props.variant === "outlined"
        ? theme.palette.gradients.darkPurpleToGreen
        : undefined,
    padding: props.variant === "outlined" ? 1 : 0,
    position: "relative",
  },
  container: {
    padding: 20,
    borderRadius: 7,
    background: theme.palette.ivory,
    marginBottom: props.variant === "hover" ? 2 : 0,
    "&:hover": {
      cursor: props.variant === "hover" ? "pointer" : undefined,
      border:
        props.variant === "hover"
          ? `1px solid ${theme.palette.greyLight200}`
          : undefined,
      boxShadow:
        props.variant === "hover"
          ? "0px 0px 0px 3px rgba(10, 21, 27, 0.06)"
          : undefined,
      marginBottom: 0,
    },
  },
}));
