import { ChevronDown, ChevronTop } from "components/icons";
import Button from "components/ui/Button";
import Dropdown from "components/ui/Dropdown/components/Dropdown";
import { IDropdownOption } from "components/ui/Dropdown/components/types";
import { isMessageDescriptor } from "helpers/isMessageDescriptor";
import { useCallback, useState } from "react";
import { defineMessages, IntlShape, useIntl } from "react-intl";
import {
  MatchField,
  MatchFieldType,
  MatchFilterType,
} from "screens/Frontoffice/screens/DataTables/shared/types";
import { TYPE_LABELS } from "screens/Frontoffice/screens/DataTables/shared/types/MatchFilter";

type Props = {
  disabled?: boolean;
  fieldType?: MatchFieldType;
  value?: MatchFilterType;
  onChange: (value: MatchFilterType) => void;
};

const SelectOperation = ({
  disabled = false,
  fieldType,
  onChange,
  value,
}: Props) => {
  const intl = useIntl();
  const typeOptions = getOptions(intl, fieldType);
  const [anchorEl, setAnchorEl] = useState<Element | null>(null);

  const openDropdown = useCallback((event: React.SyntheticEvent) => {
    setAnchorEl(event.currentTarget);
  }, []);

  const onSelectItems = useCallback(
    (items: MatchFilterType[] | null) => {
      setAnchorEl(null);
      if (items && items.length > 0) {
        onChange(items[0]);
      }
    },
    [onChange]
  );

  const onClose = useCallback(() => {
    setAnchorEl(null);
  }, []);

  return (
    <>
      <Button
        RightIcon={
          typeOptions.length < 2
            ? undefined
            : anchorEl
            ? ChevronTop
            : ChevronDown
        }
        variant="tertiary"
        label={value ? TYPE_LABELS[value] : i18n.pickValue}
        onClick={typeOptions.length < 2 ? undefined : openDropdown}
        size="small"
        disabled={disabled}
      />
      <Dropdown<MatchFilterType>
        options={typeOptions}
        open={!!anchorEl}
        anchorEl={anchorEl}
        onClose={onClose}
        onChange={onSelectItems}
        withSearch={false}
      />
    </>
  );
};

/// Internal

const getOptions = (
  intl: IntlShape,
  fieldType?: MatchFieldType
): IDropdownOption<MatchFilterType>[] => {
  return fieldType
    ? MatchField.getFilterTypeOptions(fieldType).map((option) => ({
        id: option.value,
        name: String(
          isMessageDescriptor(option.label)
            ? intl.formatMessage(option.label)
            : option.label
        ),
      }))
    : [];
};

/// I18n

const i18n = defineMessages({
  pickValue: {
    id: "Gilters.SelectOperation.pickValue",
    defaultMessage: "Pick",
  },
});

/// Exports

export default SelectOperation;

export const _private = {
  getOptions,
};
