import { Skeleton } from "@mui/material";

import { isNearboundProspectCustomerCell } from "./NearboundProspectCustomerCell";
import {
  isPartnerPresenceCell,
  PartnerPresenceSkeleton,
} from "./PartnerPresenceCell";

type Props = {
  fieldName: string;
  itemCount: number;
};

const HiddenCell = ({ fieldName, itemCount }: Props) => {
  if (
    isPartnerPresenceCell({ fieldName }) ||
    isNearboundProspectCustomerCell({ fieldName })
  ) {
    return <PartnerPresenceSkeleton itemCount={itemCount} />;
  }
  return (
    <Skeleton
      variant="text"
      animation={false}
      style={{ filter: "blur(2px)", width: "70%" }}
    />
  );
};

export default HiddenCell;
