import { isFulfilled, isRejected } from "@reduxjs/toolkit";
import usePushNotification from "hooks/usePushNotification";
import useSegment from "hooks/useSegment";
import Partnership from "models/Partnership";
import Record from "models/Record";
import { useDispatch, useSelector } from "react-redux";
import { rawPost, removeRelated } from "redux/api/thunks";
import { selectAllPartnershipTags } from "redux/init/selectors";
import TagsWidget from "screens/Frontoffice/shared/components/TagsWidget";
import { TagData } from "screens/Frontoffice/shared/helpers/types";
import { TagsEvent } from "tracking";

// Limit of 10 tags max per partnership
const TAGS_LIMIT = 10;
// Tile content width minus the side padding
const TILE_CONTENT_WIDTH = 280 - 40;

type Props = {
  partnership: Partnership;
  inTile?: boolean;
  reloadPartnership: () => void;
};

const PartnershipTags = ({
  partnership,
  inTile = false,
  reloadPartnership,
}: Props) => {
  const dispatch = useDispatch();
  const { track } = useSegment();
  const pushNotification = usePushNotification();
  const partnershipTags = useSelector(selectAllPartnershipTags);
  const tagList = partnershipTags.map(
    (tag) =>
      ({
        tag: tag,
      } as TagData)
  );
  const selectedTags = (partnership.tags || []).map(
    (tag: Record) =>
      ({
        tag: tag,
      } as TagData)
  );

  const selectTag = async (tagId: number, tagName: string) => {
    const result = await dispatch(
      rawPost({
        type: "partnerships",
        id: partnership.id,
        path: "/relationships/tags/",
        payload: {
          data: [
            {
              type: "partnership_tags",
              id: tagId,
            },
          ],
        },
      })
    );
    if (isFulfilled(result)) {
      reloadPartnership();
      track(TagsEvent.tagAssigned, {
        tagName: tagName,
        partnershipId: partnership.id,
      });
    }
    if (isRejected(result)) {
      pushNotification("default_error");
    }
  };

  const unselectTag = async (tagId: number, tagName: string) => {
    const result = await dispatch(
      removeRelated({
        type: "partnerships",
        id: partnership.id,
        related: [
          {
            id: String(tagId),
            type: "partnership_tags",
          },
        ],
        relation: "tags",
      })
    );
    if (isFulfilled(result)) {
      reloadPartnership();
      track(TagsEvent.tagRemoved, {
        tagName: tagName,
        partnershipId: partnership.id,
      });
    }
    if (isRejected(result)) {
      pushNotification("default_error");
    }
  };

  return (
    <TagsWidget
      maxWidth={inTile ? TILE_CONTENT_WIDTH : null}
      allowSelect={selectedTags.length < TAGS_LIMIT}
      {...{ tagList, selectedTags, selectTag, unselectTag }}
    />
  );
};

export default PartnershipTags;
