import { parse } from "query-string";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";

type HasLoggedIn = {
  loggedIn: $TSFixMe;
};

const redirectIfLoggedIn = (redirectToURL: $TSFixMe) => (
  WrappedComponent: $TSFixMe
) => {
  const Component = ({ loggedIn, ...rest }: HasLoggedIn) => {
    const search = parse(window.location.search);
    if (loggedIn) {
      return <Redirect to={search.next || redirectToURL} />;
    }
    return <WrappedComponent {...rest} />;
  };

  const mapStateToProps = ({ user: { login } }: $TSFixMe) => ({
    loggedIn: login.loggedIn,
  });

  const connectedComponent = connect(mapStateToProps)(Component);
  connectedComponent.displayName = `redirectIfLoggedIn(${
    WrappedComponent.displayName || "WrappedComponent"
  })`;

  return connectedComponent;
};

export default redirectIfLoggedIn;
