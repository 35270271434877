import * as yup from "yup";

const crmProviders = yup.object({
  gsheet: yup.string(),
  hubspot: yup.string(),
  ms_dynamics: yup.string(),
  pipedrive: yup.string(),
  salesforce: yup.string(),
  zoho: yup.string(),
});

const crmProvidersAllRequired: typeof crmProviders = yup.object({
  gsheet: yup.string().required(),
  hubspot: yup.string().required(),
  ms_dynamics: yup.string().required(),
  pipedrive: yup.string().required(),
  salesforce: yup.string().required(),
  zoho: yup.string().required(),
});

export const schema = yup
  .object({
    environment: yup.string().required(),
    services: yup
      .object({
        http: yup
          .object({
            ask: yup.string().required(),
            crmExporter: yup.string().required(),
            digger: yup.string().required(),
            insights: yup.string().required(),
            notifications: yup.string().required(),
            messages: yup.string().required(),
            messaging: yup.string().required(),
            search: yup.string().required(),
            slack: yup.string().required(),
            synchronizer: yup.string().required(),
            upsides: yup.string().required(),
            webhooks: yup.string().required(),
          })
          .noUnknown()
          .required(),
        websocket: yup
          .object({
            messaging: yup.string().required(),
          })
          .noUnknown()
          .required(),
      })
      .noUnknown()
      .required(),
    hosts: yup
      .object({
        frontoffice: yup.string().required(),
        backoffice: yup.string().required(),
      })
      .noUnknown()
      .required(),
    intercomAppId: yup.string(),
    reCaptchaSiteKey: yup.string().required(),
    segmentTag: yup.string(),
    facebookId: yup.string(),
    slackClientId: yup.string().required(),
    partnerStackClientId: yup.string().required(),
    crmProviders: crmProviders
      .when("environment", (env: string, schema: typeof crmProviders) => {
        if (env === "production" || env === "staging") {
          return crmProvidersAllRequired;
        }
        return schema;
      })
      .noUnknown()
      .required(),
    sentry: yup
      .object({
        dsn: yup.string(),
        diggerProjectId: yup.string(),
      })
      .noUnknown(),
    okta: yup
      .object({
        issuer: yup.string().required(),
        customDomain: yup.string().required(),
        clientId: yup.string().required(),
        googleIdpId: yup.string().required(),
        extGoogleIdpId: yup.string().required(),
        isGoogleFrontofficeButtonEnabled: yup.boolean().nullable(),
        revealInternalGoogleIdpId: yup.string().nullable(),
      })
      .noUnknown(),
    newRelic: yup
      .object({
        accountID: yup.number().required(),
        licenseKey: yup.string().required(),
        applicationID: yup.string().required(),
      })
      .noUnknown(),
  })
  .noUnknown();

export const getValidatedEnvironment = () => {
  // @ts-expect-error
  const validated = schema.validateSync(window.__env__);
  if (validated === undefined) {
    throw new Error("Cannot load environment");
  }
  return validated;
};
