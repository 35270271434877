import { ClassNameMap, FormControl, FormGroup, Grid } from "@mui/material";
import { TextInput } from "components/ui/TextInput";
import { T } from "components/ui/Typography";
import useUserProfile from "hooks/useUserProfile";
import { defineMessages, FormattedMessage } from "react-intl";

type Props = {
  classes: ClassNameMap;
};

const ReferStaticInfo = ({ classes }: Props) => {
  const { profile } = useUserProfile();
  return (
    <Grid container item>
      <Grid item xs={3}>
        <T className={classes.inputDescription}>
          <FormattedMessage {...i18n.referringUser} />
        </T>
      </Grid>
      <Grid item xs={9}>
        <FormGroup row className={classes.formGroup}>
          <FormControl fullWidth>
            <TextInput
              className={classes.input}
              name="user_first_name"
              disabled
              value={profile.firstName}
            />
          </FormControl>
          <FormControl fullWidth>
            <TextInput
              className={classes.input}
              name="user_last_name"
              disabled
              value={profile.lastName}
            />
          </FormControl>
        </FormGroup>
      </Grid>
    </Grid>
  );
};

export default ReferStaticInfo;

// I18N

const i18n = defineMessages({
  referringUser: {
    id: "DataTables.ActionBtn.ReferStaticInfo.referringUser",
    defaultMessage: "Referring User",
  },
});
