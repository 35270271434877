import { NotificationStatus } from "components/ui/Notifications/NotificationSnackbar";
import PartnerConnection from "models/PartnerConnection";
import ModelRecord from "models/Record";
import { MessageDescriptor } from "react-intl";

/**
 * Type of actions for Notifications (internal)
 */
export enum NotificationType {
  push = "@NOTIFICATION_PUSH",
  markAsRead = "@NOTIFICATION_MARK_AS_READ",
}

/**
 * Notifications
 */

type NotificationItemContent<
  T extends NotificationAction | undefined = NotificationAction | undefined
> = {
  message: string | MessageDescriptor;
  description?: string | MessageDescriptor;
  context?: Record<string, any>;
  status?: NotificationStatus;
  /**
   * actions contains element to be passed to the rendered component
   * to reconstruct the actions
   */
  actions: T;
};

export type NotificationItem<
  T extends NotificationAction | undefined = NotificationAction | undefined
> = NotificationItemContent<T> & {
  id: string;
  timestamp: number;
  read: boolean;
};

/**
 * Notifications Actions
 *    They are defined using a NotificationActionType, and they can support parameters.
 *    If addind a new action to be rendered in the Notification component, define a type,
 *    and the required context.
 */
export enum NotificationActionType {
  linkActions = "link_action",
  addedToPipelineActions = "added_to_pipeline_action",
  removePipelineFiltersActions = "remove_pipeline_filters_action",
  archivePipelineActions = "archive_pipeline_action",
}

export type LinkAction = {
  type: NotificationActionType.linkActions;
  label: string | MessageDescriptor;
  link: string;
};

export type AddedToPipelineAction = {
  type: NotificationActionType.addedToPipelineActions;
  partnerConnections: PartnerConnection[];
  rawCompanies?: ModelRecord[];
};

export type RemovePipelineFiltersAction = {
  type: NotificationActionType.removePipelineFiltersActions;
};

export type ArchivePipelineAction = {
  type: NotificationActionType.archivePipelineActions;
  partnerConnectionIds: number[];
  shouldArchive: boolean;
};

export type NotificationAction =
  | LinkAction
  | AddedToPipelineAction
  | RemovePipelineFiltersAction
  | ArchivePipelineAction;
