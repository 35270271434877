import ClearRoundedIcon from "@mui/icons-material/ClearRounded";
import FiltersSelectField from "components/ui/filters/FiltersSelectField";
import { T } from "components/ui/Typography";
import { makeStyles } from "makeStyles";
import { ReactNode } from "react";

const useStyles = makeStyles<{ isDisabled?: boolean }>()(
  (theme, { isDisabled }) => ({
    root: {
      display: "flex",
      alignItems: "center",
      marginTop: 6,
      marginBottom: 6,
      marginRight: 6,
      minHeight: 30,
    },
    deleteBtn: {
      borderRadius: 5,
      marginRight: theme.spacing(1),
      "&:hover": {
        cursor: "pointer",
        backgroundColor: theme.palette.other.grid.lightBorder,
      },
    },
    clearIcon: {
      width: 15,
      height: 15,
      margin: 5,
    },
    itemLabel: {
      marginRight: theme.spacing(2),
    },
    itemContent: {
      display: "flex",
      alignItems: "center",
      fontSize: 12,
      color: isDisabled ? theme.palette.alpha500 : "unset",
    },
  })
);

type Props = {
  children?: ReactNode;
  isDisabled?: boolean;
  label: string;
  options?: $TSFixMe[];
  fieldname?: string;
  placeholder?: string;
  handleChange: $TSFixMeFunction;
  handleDelete: $TSFixMeFunction;
};

const FiltersBaseItem = ({
  children,
  isDisabled,
  label,
  options = [],
  fieldname = "",
  placeholder = "",
  handleChange,
  handleDelete,
}: Props) => {
  const { classes } = useStyles({ isDisabled });

  return (
    <div className={classes.root}>
      {!isDisabled && (
        <div className={classes.deleteBtn} onClick={handleDelete}>
          <ClearRoundedIcon
            className={classes.clearIcon}
            data-testid="delete-icon"
          />
        </div>
      )}
      <div className={classes.itemContent}>
        <T className={classes.itemLabel}>{label}</T>
        <FiltersSelectField
          isDisabled={isDisabled}
          value={options.filter((option) => option.value === fieldname)}
          options={options}
          handleChange={handleChange}
          placeholder={placeholder}
        />
        {children}
      </div>
    </div>
  );
};

export default FiltersBaseItem;
