import { SavedFilterType } from "models/SavedFilter";
import { AccountMappingResource } from "redux/accountMapping/types";

import { ExtendedAccountMappingResource, ThreeSixty } from "./constants";

export const savedFilterTypeFromViewType = (
  viewType: ExtendedAccountMappingResource
): SavedFilterType => {
  return viewType === AccountMappingResource.matches
    ? SavedFilterType.AccountMapping
    : viewType === AccountMappingResource.matched_accounts
    ? SavedFilterType.NewAccountMapping
    : viewType === AccountMappingResource.leadMatches
    ? SavedFilterType.LeadOverlaps
    : viewType === AccountMappingResource.shared_accounts
    ? SavedFilterType.NewProspects
    : viewType === ThreeSixty.threeSixty
    ? SavedFilterType.MyEcosystem
    : viewType === ThreeSixty.nearboundAccounts
    ? SavedFilterType.NearboundAccounts
    : SavedFilterType.NearboundProspects;
};
