import { isFulfilled } from "@reduxjs/toolkit";
import { Show } from "components/icons";
import Alert from "components/ui/Alert";
import Button from "components/ui/Button";
import usePushNotification from "hooks/usePushNotification";
import useUserProfile from "hooks/useUserProfile";
import { makeStyles } from "makeStyles";
import Partnership from "models/Partnership";
import { defineMessages, FormattedMessage } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import { bulkRemoveRecords } from "redux/api/actions";
import { selectActivePayingFeatures } from "redux/api/selectors";
import { rawPost } from "redux/api/thunks";
import { fields, included } from "redux/init/constants";

type Props = {
  partnership: Partnership;
};

const PartnerTilePendingContent = ({ partnership }: Props) => {
  const { classes } = useStyles();
  const { profile } = useUserProfile();
  const dispatch = useDispatch();
  const history = useHistory();
  const pushNotification = usePushNotification();
  const payingFeatures = useSelector(selectActivePayingFeatures);

  const moreCount = Math.max(0, (partnership.invites?.data || []).length - 1);

  if (partnership.isInitiator(profile.company)) {
    return (
      <>
        <div className={classes.leftSide}>
          <Alert variant="warning" icon className={classes.partnerMessage}>
            <FormattedMessage
              {...i18n.pendingInvite}
              values={{
                user: partnership.invitedUsername(),
              }}
            />
          </Alert>
        </div>
        {/* Sales team can create invitations if its company has the paying feature
          Other teams only need the permission */}
        {profile.canInvitePartner(payingFeatures) && (
          <div className={classes.rightSide}>
            <Button
              label={i18n.inviteOthers}
              size="small"
              variant="secondary"
              onClick={() =>
                history.push(`/partnerships/${partnership.id}#invitations`)
              }
              data-testid="invite-others-btn"
            />
          </div>
        )}
      </>
    );
  }

  const answerInvite = async (value: "accepted" | "declined") => {
    if (value === "accepted") {
      history.push(`/partnerships/ir/${partnership.id}`);
    } else {
      if (
        isFulfilled(
          await dispatch(
            rawPost({
              type: partnership.type,
              id: partnership.id,
              path: "/decline-invite/",
              payload: undefined,
              options: {
                fields,
                include: included,
              },
            })
          )
        )
      ) {
        dispatch(
          bulkRemoveRecords([
            { id: String(partnership.id), type: "partnerships" },
          ])
        );
      } else {
        pushNotification("default_error");
      }
    }
  };

  return (
    <>
      <div className={classes.leftSide}>
        <Alert variant="warning" icon className={classes.partnerMessage}>
          <FormattedMessage
            {...i18n.invitedBy}
            values={{
              user: !partnership.initiatorUser.deleted
                ? partnership.initiatorUser.fullName
                : partnership.initiatorCompany.name,
              moreCount: moreCount,
            }}
          />
        </Alert>
      </div>
      <div className={classes.rightSide}>
        <div className={classes.btnContainer}>
          {/* Sales team can accept invitations if its company has the paying feature
        Other teams only need the permission */}
          {profile.canInvitePartner(payingFeatures) && (
            <Button
              LeftIcon={Show}
              label={i18n.reviewAndAccept}
              size="small"
              onClick={() => answerInvite("accepted")}
            />
          )}
          {profile.canManagePartnerships && (
            <Button
              label={i18n.decline}
              size="small"
              onClick={() => answerInvite("declined")}
              variant="secondary"
            />
          )}
        </div>
      </div>
    </>
  );
};

const useStyles = makeStyles()((theme) => ({
  leftSide: { position: "absolute", bottom: 16, left: 16 },
  rightSide: {
    position: "absolute",
    bottom: 16,
    right: 16,
  },
  partnerMessage: {
    display: "-webkit-box",
    "-webkit-line-clamp": "2",
    "-webkit-box-orient": "vertical",
    wordBreak: "normal",
    overflow: "hidden",
    hyphens: "auto",
  },
  btnContainer: {
    display: "flex",
    columnGap: 4,
  },
}));

const i18n = defineMessages({
  pendingInvite: {
    id: "partnerTile.pendingInvite",
    defaultMessage: "{user} hasn't accepted your Reveal invitation yet.",
  },
  invitedBy: {
    id: "partnerTile.invited_by",
    defaultMessage:
      "You've been invited by {user}{moreCount, plural, =0 {} other {\u00A0and # more}}.",
  },
  decline: {
    id: "partnerTile.decline",
    defaultMessage: "Decline",
  },
  reviewAndAccept: {
    id: "partnerTile.reviewAndAccept",
    defaultMessage: "Review & Accept",
  },
  inviteOthers: {
    id: "partnerTile.inviteOthers",
    defaultMessage: "Invite others",
  },
});

export default PartnerTilePendingContent;
