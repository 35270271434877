import RadioButtonUncheckedIcon from "@mui/icons-material/RadioButtonUnchecked";
import {
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Radio,
} from "@mui/material";
import { CircleCheck } from "components/icons";
import { T } from "components/ui/Typography";
import { makeStyles } from "makeStyles";

import { OpportunityItem } from "../helpers/constants";

type Props = {
  items: OpportunityItem[];
  onChange: (items: number[]) => void;
  value: number[];
};

export const OpportunityList = ({ items, onChange, value }: Props) => {
  const { classes, cx } = useStyles();

  const handleToggle = (id: number) => {
    const currentIndex = value.indexOf(id);
    const newChecked = [...value];

    if (currentIndex === -1) {
      newChecked.push(id);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    onChange(newChecked);
  };

  return (
    <List className={classes.list}>
      {items.map((item) => (
        <ListItem
          key={item.id}
          disablePadding
          className={cx(classes.listItem, {
            [classes.selected]: value.indexOf(item.id) !== -1,
            [classes.unselected]: value.indexOf(item.id) === -1,
          })}
        >
          <ListItemButton
            className={classes.listItemButton}
            onClick={() => handleToggle(item.id)}
          >
            <ListItemIcon className={classes.listItemIcon}>
              <Radio
                edge="start"
                checked={value.indexOf(item.id) !== -1}
                tabIndex={-1}
                disableRipple
                icon={<RadioButtonUncheckedIcon />}
                checkedIcon={<CircleCheck />}
                onChange={(event) => event.stopPropagation()}
                className={classes.radio}
              />
            </ListItemIcon>
            <T>{item.stage}</T>
            <ListItemText className={classes.amountDetails}>
              <T bold>{item.amountDetails}</T>
            </ListItemText>
          </ListItemButton>
        </ListItem>
      ))}
    </List>
  );
};

const useStyles = makeStyles()((theme) => ({
  amountDetails: {
    textAlign: "right",
  },
  list: {
    padding: 0,
  },
  listItem: {
    height: 36,
    marginBottom: "10px",
    borderRadius: "6px",
    border: `2px solid ${theme.palette.purpleBrand050}`,
  },
  listItemButton: {
    height: 36,
    width: "100%",
    padding: "0.5rem",
    borderRadius: "6px",
  },
  listItemIcon: {
    marginLeft: 4,
    minWidth: 24,
    width: 24,
  },
  radio: {
    "& .MuiSvgIcon-root": {
      fontSize: 20,
      color: theme.palette.midnight,
    },
  },
  selected: {
    boxShadow: `inset 0 0 0 1px ${theme.palette.purple}`,
    backgroundColor: theme.palette.purpleBrand050,
  },
  unselected: {
    border: `1px solid ${theme.palette.midnight}`,
  },
}));
