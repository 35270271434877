import { createSvgIcon } from "@mui/material/utils";

import { SvgIcon } from "./types";

const displayName = "SignalNone";

const SignalNone: SvgIcon = createSvgIcon(
  // Using transform to enlarge from provided viewbox="0 0 16 16"
  // to default "0 0 24 24" from `createSvgIcon` -> `SvgIcon`.
  <g fill="none" transform="matrix(1.5,0,0,1.5,0,0)">
    <path
      d="M2 12C1.4 12 1 12.4 1 13V15C1 15.6 1.4 16 2 16C2.6 16 3 15.6 3 15V13C3 12.4 2.6 12 2 12Z"
      fill="#D2CFCE"
    />
    <path
      d="M6 8C5.4 8 5 8.4 5 9V15C5 15.6 5.4 16 6 16C6.6 16 7 15.6 7 15V9C7 8.4 6.6 8 6 8Z"
      fill="#D2CFCE"
    />
    <path
      d="M10 4C9.4 4 9 4.4 9 5V15C9 15.6 9.4 16 10 16C10.6 16 11 15.6 11 15V5C11 4.4 10.6 4 10 4Z"
      fill="#D2CFCE"
    />
    <path
      d="M14 0C13.4 0 13 0.4 13 1V15C13 15.6 13.4 16 14 16C14.6 16 15 15.6 15 15V1C15 0.4 14.6 0 14 0Z"
      fill="#D2CFCE"
    />
  </g>,
  displayName
);
SignalNone.displayName = displayName;
export default SignalNone;
