import { makeStyles } from "makeStyles";
import { AvailablePipelineColumns } from "redux/pipeline/defaults";
import {
  ColumnConfigType,
  FieldType,
  FilterType,
  MatchFieldType,
  SortType,
} from "screens/Frontoffice/screens/DataTables/shared/types";

export const fields: { [key: string]: FieldType } = {
  [AvailablePipelineColumns.rawCompanyName]: {
    label: "Collaboration",
    type: MatchFieldType.TEXT,
    options: {},
    smartField: true,
    fullyImported: true,
    isDisabled: false,
    noSide: true,
    isPrivate: false,
    partnerField: false,
    sort: true,
    filter: true,
    column: true,
  },
  [AvailablePipelineColumns.discussionParticipants]: {
    label: "Participants",
    type: MatchFieldType.TEXT,
    options: {},
    smartField: true,
    fullyImported: true,
    isDisabled: false,
    noSide: true,
    isPrivate: false,
    partnerField: false,
    sort: false,
    filter: false,
    column: true,
  },
  [AvailablePipelineColumns.createdAt]: {
    label: "Date added",
    type: MatchFieldType.DATE,
    options: {},
    smartField: true,
    fullyImported: true,
    isDisabled: false,
    noSide: true,
    isPrivate: false,
    partnerField: false,
    sort: true,
    filter: false,
    column: true,
  },
  [AvailablePipelineColumns.rawCompanyStatus]: {
    label: "Status",
    type: MatchFieldType.PICKLIST,
    options: {},
    smartField: true,
    fullyImported: true,
    isDisabled: false,
    noSide: true,
    isPrivate: false,
    partnerField: false,
    sort: true,
    filter: true,
    column: true,
  },
  [AvailablePipelineColumns.rawCompanyOwnerId]: {
    label: "Owner",
    type: MatchFieldType.PICKLIST,
    options: {},
    smartField: true,
    fullyImported: true,
    isDisabled: false,
    noSide: true,
    isPrivate: false,
    partnerField: false,
    sort: true,
    filter: true,
    column: true,
  },
  [AvailablePipelineColumns.partnership]: {
    label: "Partner",
    type: MatchFieldType.PICKLIST,
    options: {},
    smartField: true,
    fullyImported: true,
    isDisabled: false,
    noSide: true,
    isPrivate: false,
    partnerField: false,
    sort: false,
    filter: true,
    column: false,
  },
  [AvailablePipelineColumns.kind]: {
    label: "Attribution",
    type: MatchFieldType.PICKLIST,
    options: {},
    smartField: true,
    fullyImported: true,
    isDisabled: false,
    noSide: true,
    isPrivate: false,
    partnerField: false,
    sort: true,
    filter: true,
    column: true,
  },
  [AvailablePipelineColumns.activityDate]: {
    label: "Attribution date",
    type: MatchFieldType.DATETIME,
    options: {},
    smartField: true,
    fullyImported: true,
    isDisabled: false,
    noSide: true,
    isPrivate: false,
    partnerField: false,
    sort: true,
    filter: true,
    column: true,
  },
  [AvailablePipelineColumns.stage]: {
    label: "Stage",
    type: MatchFieldType.PICKLIST,
    options: {},
    smartField: true,
    fullyImported: true,
    isDisabled: false,
    noSide: true,
    isPrivate: false,
    partnerField: false,
    sort: true,
    filter: true,
    column: true,
  },
  [AvailablePipelineColumns.externalComment]: {
    label: "Inbox",
    type: MatchFieldType.TEXT,
    options: {},
    smartField: true,
    fullyImported: true,
    isDisabled: false,
    noSide: true,
    isPrivate: false,
    partnerField: false,
    sort: false,
    filter: true,
    column: true,
  },
  [AvailablePipelineColumns.rawOpportunityStatus]: {
    label: "Opportunity",
    type: MatchFieldType.PICKLIST,
    options: {},
    smartField: true,
    fullyImported: true,
    isDisabled: false,
    noSide: true,
    isPrivate: false,
    partnerField: false,
    sort: false,
    filter: true,
    column: true,
  },
  [AvailablePipelineColumns.category]: {
    label: "Category",
    type: MatchFieldType.PICKLIST,
    options: {},
    smartField: true,
    fullyImported: true,
    isDisabled: false,
    noSide: true,
    isPrivate: false,
    partnerField: false,
    sort: true,
    filter: true,
    column: true,
  },
  [AvailablePipelineColumns.from]: {
    label: "From",
    type: MatchFieldType.TEXT,
    options: {},
    smartField: true,
    fullyImported: true,
    isDisabled: false,
    noSide: true,
    isPrivate: false,
    partnerField: false,
    sort: true,
    filter: true,
    column: true,
  },
};

export type PipelineViewParameters = {
  filters?: FilterType[];
  columns?: ColumnConfigType[];
  sort?: SortType[];
  lastVisitedAt?: Date;
};

export const usePipelineToggleFilterStyles = makeStyles()((theme) => ({
  toggleButtonGroup: {
    backgroundColor: theme.palette.greyLight100,
    height: 28,
    "& .MuiButtonBase-root.MuiToggleButton-root.Mui-selected": {
      backgroundColor: theme.palette.ivory,
      "& > p": {
        color: `${theme.palette.midnight} !important`,
      },
      "&:hover": {
        backgroundColor: theme.palette.alpha200,
      },
    },
    "& .MuiButtonBase-root": {
      margin: 2,
    },
    "& p": {
      color: `${theme.palette.alpha500} !important`,
    },
  },
}));

export const opportunityFields = {
  id: {
    display_index: -1,
    name: "id",
    type: 1,
    label: "ID",
    smart_field: true,
    fully_imported: true,
  },
  account_name: {
    display_index: 0,
    name: "account_name",
    type: 1,
    label: "Account Name",
    smart_field: true,
    fully_imported: true,
  },
  status: {
    display_index: 1,
    name: "status",
    type: 1,
    label: "Status",
    smart_field: true,
    fully_imported: true,
  },
  stage: {
    display_index: 2,
    name: "stage",
    type: 1,
    label: "Stage",
    smart_field: true,
    fully_imported: true,
  },
  close_date: {
    display_index: 3,
    name: "close_date",
    type: 6,
    label: "Closed Date",
    smart_field: true,
    fully_imported: true,
  },
  owner: {
    display_index: 4,
    name: "owner",
    type: 11,
    label: "Owner",
    smart_field: true,
    fully_imported: true,
  },
  opportunity_name: {
    display_index: 5,
    name: "opportunity_name",
    type: 1,
    label: "Opportunity Name",
    smart_field: true,
    fully_imported: true,
  },
  amount: {
    display_index: 6,
    name: "amount",
    type: 8,
    label: "Amount",
    smart_field: true,
    fully_imported: true,
  },
  opportunity_type: {
    display_index: 7,
    name: "opportunity_type",
    type: 1,
    label: "Type",
    smart_field: true,
    fully_imported: true,
  },
  next_step: {
    display_index: 8,
    name: "next_step",
    type: 1,
    label: "Next Step",
    smart_field: true,
    fully_imported: true,
  },
  url: {
    display_index: 9,
    name: "url",
    type: 1,
    label: "URL",
    smart_field: true,
    fully_imported: true,
  },
};
