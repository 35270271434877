import { Box } from "@mui/material";
import { Info } from "components/icons";
import Tooltip from "components/ui/Tooltip";
import { T } from "components/ui/Typography";
import useHasPayingFeature from "hooks/useHasPayingFeature";
import useUserProfile from "hooks/useUserProfile";
import { makeStyles } from "makeStyles";
import { PayingFeature } from "models/CompanyPayingFeatureSubscription";
import { defineMessages, FormattedMessage } from "react-intl";

const BaseTitle = () => {
  const { profile } = useUserProfile();
  const isGoalBased360Limited = useHasPayingFeature(
    PayingFeature.GoalBased360Limited,
    profile
  );
  const isGoalBased360Unlocked = useHasPayingFeature(
    PayingFeature.GoalBased360Unlocked,
    profile
  );
  const isGoalBasedAvailable = isGoalBased360Limited || isGoalBased360Unlocked;
  const { classes, cx } = useStyles();

  return (
    <Box className={classes.container}>
      <T
        h2={!isGoalBasedAvailable}
        bold={!isGoalBasedAvailable}
        className={cx({ [classes.goalBasedTitle]: isGoalBasedAvailable })}
      >
        <FormattedMessage
          {...i18n[isGoalBasedAvailable ? "whatsYourGoal" : "_360Mapping"]}
        />
      </T>
      {isGoalBasedAvailable && (
        <Tooltip title={<FormattedMessage {...i18n.goalBasedTooltip} />}>
          <Info className={classes.infoIcon} />
        </Tooltip>
      )}
    </Box>
  );
};

export default BaseTitle;

const useStyles = makeStyles()((theme) => ({
  container: {
    alignItems: "center",
    display: "flex",
    gap: 6,
  },
  infoIcon: {
    color: theme.palette.midnight,
    width: 13,
  },
  goalBasedTitle: {
    fontWeight: 600,
    fontSize: 18,
    lineHeight: "23.4px",
  },
}));

const i18n = defineMessages({
  _360Mapping: {
    id: "Frontoffice.DataTables.AccountMapping360.Title.360Mapping",
    defaultMessage: "360° Mapping",
  },
  goalBasedTooltip: {
    id: "Frontoffice.DataTables.AccountMapping360.Title.goalBasedTooltip",
    defaultMessage:
      "Select a Goal below so Reveal identifies the most relevant accounts.",
  },
  whatsYourGoal: {
    id: "Frontoffice.DataTables.AccountMapping360.Title.whatsYourGoal",
    defaultMessage: "What's your goal?",
  },
});
