import { Avatar, Grid } from "@mui/material";
import { T } from "components/ui/Typography";
import { crmProviders, ProviderType } from "config/constants";
import getFirstValueFromURL from "helpers/getFirstValueFromURL";
import useOauthState from "hooks/useOauthState";
import { MouseEvent, useState } from "react";
import { defineMessages, FormattedMessage } from "react-intl";
import { useHistory, useLocation } from "react-router-dom";
import MsDynamicsCrmUrlDialog from "screens/Frontoffice/screens/Sources/shared/components/MsDynamicsCrmUrlDialog";
import {
  isAPIKeyAndDomainProviderType,
  isAPIKeyProviderType,
  isCrmFileOAuthProviderType,
  isCrmProviderType,
} from "screens/Frontoffice/shared/helpers/types";

import APIKeyAndDomainDialog from "../../../APIKeyAndDomainDialog";
import APIKeyDialog from "../../../APIKeyDialog";
import { StripeConnectDialog } from "../../../StripeConnectDialog";
import { sharedStyles } from "./BaseContent";

export const CrmAuthProviderSelectButtons = () => {
  const { start } = useOauthState();
  const location = useLocation();
  const isAllProvider = getFirstValueFromURL(location, "provider") === "all";
  const { cx, classes } = sharedStyles({ isAllProvider });
  const history = useHistory();
  const [provider, setProvider] = useState<ProviderType | undefined>(undefined);
  const [isMsDialogOpen, setIsMsDialogOpen] = useState(false);
  const [
    isAPIKeyAndDomainDialogOpen,
    setIsAPIKeyAndDomainDialogOpen,
  ] = useState(false);
  const [isAPIKeyDialogOpen, setIsAPIKeyDialogOpen] = useState(false);
  const [isStripeDialogOpen, setIsStripeKeyDialogOpen] = useState(false);
  const onboarding = getFirstValueFromURL(location, "onboarding") === "true";
  const next = (provider: ProviderType) =>
    getFirstValueFromURL(
      location,
      "next",
      onboarding
        ? `${location.pathname}?provider=${provider}&init=true&onboarding=true#loading`
        : `${location.pathname}?provider=${provider}&init=true#loading`
    );

  const startOauth = (provider: ProviderType) => {
    if (provider === ProviderType.salesforce) {
      localStorage.sfSandbox = 0;
    }
    start(provider, next(provider));
  };

  const startSandbox = (provider: ProviderType) => {
    localStorage.sfSandbox = 1;
    start(provider, next(provider));
  };

  const handleStartSandbox = (event: MouseEvent) => {
    event.stopPropagation();
    event.preventDefault();
    startSandbox(ProviderType.salesforce);
  };

  const handleOpenMSModal = () => {
    setIsMsDialogOpen(true);
  };
  const handleOpenAPIKeyAndDomainModal = () => {
    setIsAPIKeyAndDomainDialogOpen(true);
  };
  const handleOpenAPIKeyModal = () => {
    setIsAPIKeyDialogOpen(true);
  };
  const handleStripeConnectDialog = () => {
    setIsStripeKeyDialogOpen(true);
  };

  const handleReconnect = (provider: ProviderType) => {
    isAPIKeyAndDomainProviderType(provider)
      ? handleOpenAPIKeyAndDomainModal()
      : isAPIKeyProviderType(provider)
      ? handleOpenAPIKeyModal()
      : provider === ProviderType.msDynamics
      ? handleOpenMSModal()
      : provider === ProviderType.stripe
      ? handleStripeConnectDialog()
      : startOauth(provider);
  };

  const handleClick = (provider: ProviderType) => () => {
    setProvider(provider);
    handleReconnect(provider);
  };

  const handleClickRedirect = (provider: string) => () => {
    history.push({
      ...location,
      search: `init=true&provider=${provider}`,
    });
  };

  return (
    <Grid container gap={1.5}>
      {Object.values(ProviderType)
        .filter(
          (item) => isCrmProviderType(item) && !isCrmFileOAuthProviderType(item)
        )
        .map((provider) => (
          <Grid
            item
            className={cx(classes.crmButton, classes.crmButtonSmall)}
            key={`auth-provider-button-${provider}`}
            onClick={handleClick(provider)}
          >
            <CrmAvatar provider={provider} />
            {provider === ProviderType.salesforce && (
              <div
                className={classes.linkInCrmButton}
                onClick={handleStartSandbox}
              >
                <T>
                  <FormattedMessage {...i18n.connectSandbox} />
                </T>
              </div>
            )}
          </Grid>
        ))}
      {isAllProvider && (
        <>
          <Grid
            item
            className={cx(classes.crmButton, classes.crmButtonSmall)}
            key={`auth-provider-button-${ProviderType.file}`}
            onClick={handleClickRedirect(ProviderType.file)}
          >
            <CrmAvatar provider={ProviderType.file} />
          </Grid>
          <Grid
            item
            className={cx(classes.crmButton, classes.crmButtonSmall)}
            key={`auth-provider-button-${ProviderType.gsheet}`}
            onClick={handleClickRedirect(ProviderType.gsheet)}
          >
            <CrmAvatar provider={ProviderType.gsheet} />
          </Grid>
        </>
      )}
      {isAPIKeyAndDomainProviderType(provider) &&
        isAPIKeyAndDomainDialogOpen && (
          <APIKeyAndDomainDialog
            provider={provider}
            isOpen={isAPIKeyAndDomainDialogOpen}
            onClose={() => setIsAPIKeyAndDomainDialogOpen(false)}
            next={next(provider)}
          />
        )}
      {isAPIKeyProviderType(provider) && isAPIKeyDialogOpen && (
        <APIKeyDialog
          provider={provider}
          isOpen={isAPIKeyDialogOpen}
          onClose={() => setIsAPIKeyDialogOpen(false)}
          next={next(provider)}
        />
      )}
      {provider === ProviderType.stripe && isStripeDialogOpen && (
        <StripeConnectDialog
          isOpen={isStripeDialogOpen}
          onClose={() => setIsStripeKeyDialogOpen(false)}
          next={next(provider)}
        />
      )}
      {provider === ProviderType.msDynamics && (
        <MsDynamicsCrmUrlDialog
          isOpen={isMsDialogOpen}
          onClose={() => setIsMsDialogOpen(false)}
          next={next(provider)}
        />
      )}
    </Grid>
  );
};

type CrmAvatarProps = {
  provider: ProviderType;
};

const CrmAvatar = ({ provider }: CrmAvatarProps) => {
  const { classes } = sharedStyles({});

  const config = crmProviders[provider];

  return (
    <>
      <Avatar
        alt={config.name}
        className={classes.avatar}
        src={config.logo}
        variant="rounded"
      />
      <T h4>{config.name}</T>
    </>
  );
};

const i18n = defineMessages({
  connectSandbox: {
    id: "CrmAuthProviderSelectButtons.connectSandbox",
    defaultMessage: "Connect as sandbox",
  },
});
