import { T } from "components/ui/Typography";
import { makeStyles } from "makeStyles";
import { defineMessages, FormattedMessage } from "react-intl";

export const YouTag = ({ isPartner = false }: { isPartner?: boolean }) => {
  const { classes } = useStyles();
  return (
    <T className={classes.label}>
      <FormattedMessage {...i18n[isPartner ? "partner" : "you"]} />
    </T>
  );
};

const useStyles = makeStyles()((theme) => ({
  label: {
    alignItems: "center",
    border: `1px solid ${theme.palette.greyscale200}`,
    borderRadius: 4,
    color: theme.palette.alpha500,
    display: "flex",
    fontSize: 11,
    fontWeight: 400,
    height: 20,
    lineHeight: "150%",
    padding: "3px 6px",
  },
}));

const i18n = defineMessages({
  you: {
    id: "ShareListOptions.YouTag.you",
    defaultMessage: "You",
  },
  partner: {
    id: "ShareListOptions.YouTag.partner",
    defaultMessage: "Partner",
  },
});
