import { createContext, ReactNode } from "react";

import { SWQLBooleanOperator } from "./SWQLTypes";

export const ParentOperatorContext = createContext<
  SWQLBooleanOperator | undefined
>(undefined);

type Props = {
  operator?: SWQLBooleanOperator;
  children: ReactNode;
};

export const ParentOperatorContextProvider = ({
  operator,
  children,
}: Props) => (
  <ParentOperatorContext.Provider value={operator}>
    {children}
  </ParentOperatorContext.Provider>
);
