import { Box, CircularProgress, Popover } from "@mui/material";
import { Columns, Plus } from "components/icons";
import Button from "components/ui/Button";
import {
  HEADER_HEIGHT,
  PIPELINE_HEADER_HEIGHT,
} from "components/ui/data-grid/BaseDataGrid";
import DraggableContainer from "components/ui/DraggableContainer";
import DraggableItem from "components/ui/DraggableItem";
import { T } from "components/ui/Typography";
import useSegment from "hooks/useSegment";
import _ from "lodash";
import { makeStyles } from "makeStyles";
import { MouseEvent, useEffect, useState } from "react";
import { defineMessages, FormattedMessage } from "react-intl";
import {
  ColumnConfigType,
  DataTableType,
  FieldType,
} from "screens/Frontoffice/screens/DataTables/shared/types";
import { AdvancedAnalyticsEvent, PipelineEvent } from "tracking";

import ColumnItem from "./ColumnItem";
import { CustomSection, CustomSectionProps } from "./CustomSection";

export const DEFAULT_REVENUE_COLUMN_WIDTH = 100;

export type ColumnConfigExtendedType = ColumnConfigType & {
  label: string;
  private: boolean;
  disabled: boolean;
  hidden: boolean;
};

export type ColumnWidgetProps = {
  columns: ColumnConfigType[];
  orderColumns: (columns: ColumnConfigType[]) => ColumnConfigType[];
  defaultColumnConfig: (key: string, field: FieldType) => ColumnConfigType;
  fields: { [filterName: string]: FieldType };
  footer?: React.ReactNode;
  headerHeight?: number;
  setColumns: (columns: ColumnConfigType[]) => void;
  availableFieldsDefaultConfig: { [key: string]: ColumnConfigType };
  nonDeactivatableColumnsList?: ColumnConfigType["key"][];
  customSectionProps?: CustomSectionProps;
  onColumnDelete?: (
    fieldname: string,
    newColumns: ColumnConfigType[],
    index: number
  ) => void;
  onColumnReorder?: (
    newColumns: ColumnConfigType[],
    startIndex: number,
    endIndex: number,
    offset: number
  ) => ColumnConfigType[];
  loadingCallback?: (isLoading: boolean) => void;
  viewType?: DataTableType;
};

export const ColumnWidget = ({
  columns,
  orderColumns,
  defaultColumnConfig,
  fields,
  footer,
  headerHeight,
  setColumns,
  availableFieldsDefaultConfig,
  nonDeactivatableColumnsList,
  customSectionProps,
  onColumnDelete,
  onColumnReorder,
  loadingCallback,
  viewType,
}: ColumnWidgetProps) => {
  const { track } = useSegment();
  const { classes } = useStyles({
    headerHeight,
    isPipeline: [DataTableType.ATTRIBUTE, DataTableType.COLLABORATE].includes(
      viewType as DataTableType
    ),
  });
  const { classes: popoverClasses } = usePopoverStyles();

  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const [orderedSelectedFieldList, setOrderedSelectedFieldList] = useState<
    ColumnConfigType[]
  >(columns);
  const [isUpdatingColumns, setIsUpdatingColumns] = useState(false);

  const preparedFields: ColumnConfigExtendedType[] = getFields(
    availableFieldsDefaultConfig,
    defaultColumnConfig,
    fields
  );
  const selectedFields = getSelectedFields(
    preparedFields,
    orderedSelectedFieldList
  );
  const otherFields = getOtherFields(preparedFields, columns);
  const offset = getOffset(selectedFields);

  const trackOpenWidget = () => {
    switch (viewType) {
      case DataTableType.ANALYTICS:
        track(AdvancedAnalyticsEvent.openColumnWidget);
        break;
      case DataTableType.COLLABORATE:
      case DataTableType.ATTRIBUTE:
        track(PipelineEvent.openColumnWidget);
        break;
    }
  };

  const handleOpenColumnWidget = (event: MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
    trackOpenWidget();
  };

  const handleClose = (event: MouseEvent) => {
    setAnchorEl(null);
  };

  const reorder = (startIndex: number, endIndex: number) => {
    setIsUpdatingColumns(true);
    let newColumns: ColumnConfigType[];
    if (onColumnReorder) {
      newColumns = onColumnReorder(columns, startIndex, endIndex, offset);
    } else {
      newColumns = [...columns];
      const [removed] = newColumns.splice(startIndex + offset, 1);
      newColumns.splice(endIndex + offset, 0, removed);
    }
    setOrderedSelectedFieldList(newColumns); // Needed to keep new order during loading time
    setColumns(newColumns);
  };

  const addField = (fieldname: string) => {
    setIsUpdatingColumns(true);
    const field = preparedFields.find(
      (field: ColumnConfigExtendedType) => field.key === fieldname
    );
    if (field && !field.disabled) {
      setColumns(
        orderColumns([
          ...columns,
          {
            key: field.key,
            width: field.width,
            frozen: field.frozen,
            fixedPosition: field.fixedPosition,
          } as ColumnConfigType,
        ])
      );
    }
  };

  const deleteField = (fieldname: string) => {
    setIsUpdatingColumns(true);
    const newColumns = [...columns];
    const index = newColumns.findIndex((item) => item.key === fieldname);
    if (onColumnDelete) {
      onColumnDelete?.(fieldname, newColumns, index);
      return;
    }
    let removeCount = 1;
    newColumns.splice(index, removeCount);
    setColumns(newColumns);
  };

  useEffect(() => {
    if (!isEqualArraysOfColumnConfigType(columns, orderedSelectedFieldList)) {
      setOrderedSelectedFieldList(columns);
    }
    setIsUpdatingColumns(false);
  }, [columns, orderedSelectedFieldList]);

  useEffect(() => {
    loadingCallback?.(isUpdatingColumns);
  }, [isUpdatingColumns, loadingCallback]);

  return (
    <div className={classes.rightColumn}>
      <div className={classes.header}>
        <Columns className={classes.icon} />
      </div>
      <div className={classes.content}>
        <Button
          LeftIcon={Plus}
          label={""}
          size={"xSmall"}
          onClick={handleOpenColumnWidget}
          data-testid="manage-columns"
        />
        <Popover
          open={anchorEl !== null}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: "center",
            horizontal: "left",
          }}
          transformOrigin={{
            vertical: "center",
            horizontal: 317,
          }}
          classes={popoverClasses}
        >
          <div className={classes.popupContainer}>
            {/**
             * Optional section with custom toggles
             */}
            {customSectionProps && <CustomSection {...customSectionProps} />}
            {/**
             * Section with the columns toggles
             */}
            <Box className={classes.titleContainer}>
              <T titleSmall className={classes.title}>
                <FormattedMessage {...i18n.columns} />
              </T>
              {isUpdatingColumns && <CircularProgress size={12} />}
            </Box>
            <Box className={classes.listWrapper}>
              {selectedFields
                .filter((field: ColumnConfigExtendedType) =>
                  !!nonDeactivatableColumnsList
                    ? nonDeactivatableColumnsList?.includes(field.key)
                    : !!field.fixedPosition
                )
                .map((field: ColumnConfigExtendedType, index: number) => (
                  <ColumnItem
                    key={index}
                    field={field}
                    isActive
                    isToggleDisabled={isUpdatingColumns}
                  />
                ))}
              <DraggableContainer handleDragEnd={reorder}>
                {selectedFields
                  .filter(
                    (field: ColumnConfigExtendedType) =>
                      field.fixedPosition === undefined
                  )
                  .map((field: ColumnConfigExtendedType, index: number) => (
                    // @ts-expect-error ts-migrate(2786) FIXME: 'DraggableItem' cannot be used as a JSX component.
                    <DraggableItem
                      key={field.key}
                      id={field.key}
                      index={index}
                      isLoading={isUpdatingColumns}
                      isHidden={field.hidden}
                    >
                      <ColumnItem
                        key={index}
                        field={field}
                        isActive
                        draggable
                        handleChange={() => deleteField(field.key)}
                        isToggleDisabled={isUpdatingColumns}
                      />
                    </DraggableItem>
                  ))}
              </DraggableContainer>
              {otherFields.map(
                (field: ColumnConfigExtendedType, index: number) => (
                  <ColumnItem
                    key={index}
                    field={field}
                    isActive={false}
                    handleChange={() => addField(field.key)}
                    isToggleDisabled={isUpdatingColumns}
                  />
                )
              )}
            </Box>
            {footer && <div className={classes.footerContainer}>{footer}</div>}
          </div>
        </Popover>
      </div>{" "}
    </div>
  );
};

// Helpers

const compareLabel = (
  a: ColumnConfigExtendedType,
  b: ColumnConfigExtendedType
) => _.get(a, "label", "").localeCompare(_.get(b, "label", ""));

const getFields = (
  availableFieldsDefaultConfig: ColumnWidgetProps["availableFieldsDefaultConfig"],
  defaultColumnConfig: ColumnWidgetProps["defaultColumnConfig"],
  fields: Record<string, FieldType> = {}
) =>
  _.toPairs(
    _.pickBy(
      fields,
      (field, key) =>
        (_.keys(availableFieldsDefaultConfig).includes(key) ||
          !field.smartField) &&
        (field.column ?? true)
    )
  ).map(([key, field]) => ({
    ...(availableFieldsDefaultConfig[key] ?? defaultColumnConfig(key, field)),
    label: field.label,
    smartField: field?.smartField,
    private: field?.isPrivate ?? false,
    disabled: (field?.isDisabled ?? false) || !(field?.fullyImported ?? true),
    column: field.column ?? true,
    hidden: field.hidden ?? false,
  }));

const getFieldsByKey = (fields: ColumnConfigExtendedType[]) =>
  _.reduce(
    fields,
    (
      acc: { [key: string]: ColumnConfigExtendedType },
      field: ColumnConfigExtendedType
    ) => {
      acc[field.key] = field;
      return acc;
    },
    {}
  );

const getSelectedFields = (
  fields: ColumnConfigExtendedType[],
  columns: ColumnConfigType[]
) => {
  const fieldsByKey = getFieldsByKey(fields);
  return columns
    .map(({ key }: ColumnConfigType) => fieldsByKey[key])
    .filter(Boolean)
    .filter(_.negate(_.property("disabled")));
};

const getOtherFields = (
  fields: ColumnConfigExtendedType[],
  columns: ColumnConfigType[]
) => {
  const fieldsByKey = getFieldsByKey(fields);
  const selectedKeys = columns.map(_.property("key"));
  return fields
    .map(({ key }: ColumnConfigExtendedType) => fieldsByKey[key])
    .filter(
      ({ key, disabled, hidden }: ColumnConfigExtendedType) =>
        (!selectedKeys.includes(key) || disabled) && !hidden
    )
    .sort(compareLabel);
};

const getOffset = (fields: ColumnConfigType[]) =>
  fields.filter(
    (field) => field.removable === false && field.fixedPosition !== undefined
  ).length;

const isEqualArraysOfColumnConfigType = (
  arr1: ColumnConfigType[],
  arr2: ColumnConfigType[]
): boolean => {
  const sortedArr1 = _.sortBy(arr1, ["key"]);
  const sortedArr2 = _.sortBy(arr2, ["key"]);

  return _.isEqual(sortedArr1, sortedArr2);
};

// CSS

const useStyles = makeStyles<{ headerHeight?: number; isPipeline: boolean }>()(
  (theme, { headerHeight, isPipeline }) => ({
    listWrapper: {
      maxHeight: "calc(24px * 6.5)", // 24px (height of one item) * 6.5 (show 6 item and a half, signaling a scroll to the user)
      overflow: "auto",
    },
    footerContainer: {
      padding: "10px 10px 2px",
      backgroundColor: theme.palette.grey500,
    },
    rightColumn: {
      display: "flex",
      flexDirection: "column",
      position: "absolute",
      width: 36,
      right: 0,
      top: 0,
      height: "100%",
      backgroundColor: theme.palette.ivory,
    },
    header: {
      display: "flex",
      justifyContent: "center",
      paddingTop: theme.spacing(1),
      height:
        (headerHeight ?? isPipeline ? PIPELINE_HEADER_HEIGHT : HEADER_HEIGHT) +
        1,
      borderBottom: `1px solid ${theme.palette.alpha250}`,
      borderTop: isPipeline ? "none" : `1px solid ${theme.palette.alpha250}`,
      borderLeft: isPipeline ? `1px solid ${theme.palette.alpha250}` : "none",
    },
    icon: {
      width: 16,
    },
    content: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      flexGrow: 1,
      borderLeft: isPipeline ? `1px solid ${theme.palette.alpha250}` : "none",
    },
    popupContainer: {
      display: "flex",
      flexDirection: "column",
      width: "100%",
    },
    titleContainer: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      paddingRight: 12,
    },
    title: {
      padding: "8px 6px",
    },
    divider: {
      height: 1,
      backgroundColor: theme.palette.taupe500,
      border: "none",
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
    },
  })
);

const usePopoverStyles = makeStyles()((theme) => ({
  paper: {
    marginTop: 10,
    boxShadow: `-2px 3px 6px ${theme.palette.taupe} !important`,
    width: "fit-content",
    minWidth: 300,
    minHeight: 110,
    background: theme.palette.ivory,
    padding: theme.spacing(1),
    display: "flex",
  },
}));

const i18n = defineMessages({
  columns: {
    id: "ColumnWidget.columns",
    defaultMessage: "Columns",
  },
});
