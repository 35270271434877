import { OpenInNew } from "components/icons";
import { ConfigContext } from "config/ConfigProvider";
import { useContext } from "react";
import { defineMessages, useIntl } from "react-intl";

import { BaseSidePanel } from "./BaseSidePanel";
import { ChatHelpBox } from "./ChatHelpBox";
import { VideoWithHelpBox } from "./VideoWithHelpBox";

export const StatusRulesSidePanel = () => {
  const intl = useIntl();
  const config = useContext(ConfigContext);

  return (
    <BaseSidePanel>
      <VideoWithHelpBox
        i18nTitle={i18n.whatTitle}
        i18nSubtitle={i18n.statusRulesDefinition}
        linkElementList={[
          {
            i18n: i18n.bestPractices,
            url: config.defineDataSourceStatusRulesLink as string,
            icon: <OpenInNew />,
          },
        ]}
        src={"https://www.youtube.com/embed/FY2Ka-s0P5k"}
        videoTitle={intl.formatMessage(i18n.videoStatusRules)}
      />
      <ChatHelpBox />
    </BaseSidePanel>
  );
};

const i18n = defineMessages({
  bestPractices: {
    id: "crm.settings.StatusRulesSidePanel.bestPractices",
    defaultMessage: "<a>Best Practices</a>",
  },
  needHelpTitle: {
    id: "crm.settings.StatusRulesSidePanel.needHelpTitle",
    defaultMessage: "Need some help? Talk to our Activation Team?",
  },
  statusRulesDefinition: {
    id: "crm.settings.StatusRulesSidePanel.statusRulesDefinition",
    defaultMessage:
      "Status rules are criteria defining your Customers, Prospect and Partners.",
  },
  whatTitle: {
    id: "crm.settings.StatusRulesSidePanel.whatTitle",
    defaultMessage: "What are status rules?",
  },
  videoStatusRules: {
    id: "crm.settings.StatusRulesSidePanel.videoStatusRules",
    defaultMessage: "Video tutorial for status rules",
  },
});
