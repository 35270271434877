import { Box, Tooltip } from "@mui/material";
import ProgressPie from "components/icons/ProgressPie";
import { T } from "components/ui/Typography";
import useUserProfile from "hooks/useUserProfile";
import { makeStyles } from "makeStyles";
import Partnership from "models/Partnership";
import {
  defineMessages,
  FormattedMessage,
  FormattedNumber,
  MessageDescriptor,
  useIntl,
} from "react-intl";
import { useSelector } from "react-redux";
import { selectUpsidesData } from "redux/upsides/selectors";

import {
  OverlapItem,
  OverlapItemType,
} from "../../../shared/components/OverlapItem";
import {
  getLeftIcon,
  getOverlapItemValues,
  getSharingStatuses,
  getTooltipMessage,
} from "../../../shared/utils";

type Props = {
  partnership: Partnership;
};

export const PartnershipSettingsInsightOverview = ({ partnership }: Props) => {
  const intl = useIntl();
  const { classes } = useStyles();
  const { profile } = useUserProfile();
  const { matches, companyNbOfNewProspects } = partnership?.insights ?? {};
  const { status, data } = useSelector(selectUpsidesData);
  const partner = partnership.getPartner(profile);

  const {
    marketOverlap,
    personaOverlap,
    winRate,
    revenuePotential,
  } = getOverlapItemValues(partnership, data[partnership.id]);

  const overlapSharing = getSharingStatuses(true, profile.company, partnership);
  const newProspectSharing = getSharingStatuses(
    false,
    profile.company,
    partnership
  );
  const AccountOverlapsIcon = getLeftIcon(overlapSharing);
  const NewProspectsIcon = getLeftIcon(newProspectSharing);

  const isLoadingUpsides = !partnership.isDemo && status === "pending";

  return (
    <Box className={classes.container}>
      <T bold h4 className={classes.marginBottom}>
        <FormattedMessage {...i18n.overview} />
      </T>
      <OverviewRow text={i18n.accountOverlaps}>
        <Tooltip
          title={getTooltipMessage(true, overlapSharing, intl)}
          placement="top"
        >
          <Box>{<AccountOverlapsIcon className={classes.iconBig} />}</Box>
        </Tooltip>
        <T className={classes.rightAligned} bodyBig bold>
          {matches}
        </T>
      </OverviewRow>
      <OverviewRow text={i18n.newProspects}>
        <Tooltip
          title={getTooltipMessage(false, newProspectSharing, intl)}
          placement="top"
        >
          <Box>{<NewProspectsIcon className={classes.iconBig} />}</Box>
        </Tooltip>
        <T className={classes.rightAligned} bodyBig bold>
          {companyNbOfNewProspects}
        </T>
      </OverviewRow>
      <OverviewRow text={i18n.marketOverlap}>
        <Tooltip
          classes={{ arrow: classes.tooltipArrow, tooltip: classes.tooltip }}
          placement="top"
          title={
            <T>
              <FormattedMessage {...i18n.marketOverlapTooltip} />
            </T>
          }
          arrow
        >
          <T className={classes.rightAligned} bodyBig bold>
            {marketOverlap ? (
              <>
                <ProgressPie
                  className={classes.marginRight}
                  progress={marketOverlap}
                />
                <FormattedNumber
                  value={marketOverlap}
                  // eslint-disable-next-line react/style-prop-object
                  style="percent"
                  maximumFractionDigits={0}
                />
              </>
            ) : (
              "--"
            )}
          </T>
        </Tooltip>
      </OverviewRow>
      <OverviewRow text={i18n.personaOverlap}>
        <Tooltip
          classes={{ arrow: classes.tooltipArrow, tooltip: classes.tooltip }}
          placement="top"
          title={
            <T>
              <FormattedMessage
                {...i18n.personaOverlapTooltip}
                values={{ partnerName: partner.name }}
              />
            </T>
          }
          arrow
        >
          <T className={classes.rightAligned} bodyBig bold>
            {personaOverlap ? (
              <>
                <ProgressPie
                  className={classes.marginRight}
                  progress={personaOverlap}
                />
                <FormattedNumber
                  value={personaOverlap}
                  // eslint-disable-next-line react/style-prop-object
                  style="percent"
                  maximumFractionDigits={0}
                />
              </>
            ) : (
              "--"
            )}
          </T>
        </Tooltip>
      </OverviewRow>
      <OverviewRow text={i18n.winRate}>
        <T className={classes.rightAligned} bodyBig bold>
          <OverlapItem
            value={winRate}
            partnership={partnership}
            valueInProgress={!(partnership.id in data)}
            type={OverlapItemType.winRate}
            loading={isLoadingUpsides}
            valueOnly
            classes={{
              lockedOverlapItem: classes.lockedOverlapItem,
              lockedOverlapItemMessage: classes.lockedOverlapItemMessage,
              overlapContainer: classes.overlapContainer,
            }}
          />
        </T>
      </OverviewRow>
      <OverviewRow text={i18n.revenuePotential}>
        <T className={classes.rightAligned} bodyBig bold>
          <OverlapItem
            value={revenuePotential}
            partnership={partnership}
            valueInProgress={!(partnership.id in data)}
            type={OverlapItemType.revenuePotential}
            loading={isLoadingUpsides}
            valueOnly
            classes={{
              lockedOverlapItem: classes.lockedOverlapItem,
              lockedOverlapItemMessage: classes.lockedOverlapItemMessage,
              overlapContainer: classes.overlapContainer,
            }}
          />
        </T>
      </OverviewRow>
    </Box>
  );
};

type OverviewRowProps = {
  children: React.ReactNode;
  text: MessageDescriptor;
};

const OverviewRow = ({ children, text }: OverviewRowProps) => {
  const { classes, cx } = useStyles();

  return (
    <Box className={classes.row}>
      <T className={cx(classes.alphaText, classes.marginRight)}>
        <FormattedMessage {...text} />
      </T>
      {children}
    </Box>
  );
};

const useStyles = makeStyles()((theme) => ({
  alphaText: {
    color: theme.palette.alpha500,
    fontWeight: 500,
    fontSize: 12,
    lineHeight: "15.6px",
  },
  container: {
    display: "flex",
    flexDirection: "column",
    borderRadius: 8,
    backgroundColor: theme.palette.ivory,
    padding: theme.spacing(2.5),
  },
  marginBottom: {
    marginBottom: theme.spacing(2.5),
  },
  marginRight: {
    marginRight: 5,
  },
  iconBig: {
    height: 16,
  },
  lockedOverlapItem: {
    "& > svg": {
      color: theme.palette.greyscale950,
      height: "12px !important",
      marginLeft: 4,
    },
    borderBottom: `1px dashed ${theme.palette.greyLight300}`,
    borderRadius: 4,
    padding: "3px 0",
  },
  lockedOverlapItemMessage: {
    "& > p": {
      fontWeight: 400,
      fontSize: 12,
      lineHeight: "18px",
      color: theme.palette.greyscale950,
      marginRight: 2,
    },
  },
  overlapContainer: {
    "& > p": {
      fontWeight: 500,
      fontSize: 14,
      lineHeight: "21px",
    },
  },
  row: {
    display: "flex",
    alignItems: "center",
    marginBottom: theme.spacing(2),
    "& > *": {
      display: "flex",
      alignItems: "center",
    },
  },
  rightAligned: {
    marginLeft: "auto",
  },
  spinner: {
    margin: "4.5px 0",
  },
  tooltip: {
    backgroundColor: theme.palette.midnight,
  },
  tooltipArrow: {
    color: theme.palette.midnight,
  },
}));

const i18n = defineMessages({
  accountOverlaps: {
    id:
      "crm.PartnershipSettings.PartnershipSettingsInsightOverview.accountOverlaps",
    defaultMessage: "Account Overlaps",
  },
  marketOverlap: {
    id:
      "crm.PartnershipSettings.PartnershipSettingsInsightOverview.marketOverlap",
    defaultMessage: "Market Overlap",
  },
  marketOverlapTooltip: {
    id:
      "crm.PartnershipSettings.PartnershipSettingsInsightOverview.marketOverlapTooltip",
    defaultMessage:
      "% of overlapping accounts that have a common past or current opportunity.",
  },
  newProspects: {
    id:
      "crm.PartnershipSettings.PartnershipSettingsInsightOverview.newProspects",
    defaultMessage: "New Prospects",
  },
  overview: {
    id: "crm.PartnershipSettings.PartnershipSettingsInsightOverview.overview",
    defaultMessage: "Overview",
  },
  personaOverlap: {
    id:
      "crm.PartnershipSettings.PartnershipSettingsInsightOverview.personaOverlap",
    defaultMessage: "Persona Overlap",
  },
  personaOverlapTooltip: {
    id:
      "crm.PartnershipSettings.PartnershipSettingsInsightOverview.personaOverlapTooltip",
    defaultMessage:
      "% of contacts that match {partnerName}'s contacts, within your Account overlap.",
  },
  revenuePotential: {
    id:
      "crm.PartnershipSettings.PartnershipSettingsInsightOverview.revenuePotential",
    defaultMessage: "Revenue Potential",
  },
  unlock: {
    id: "crm.PartnershipSettings.PartnershipSettingsInsightOverview.unlock",
    defaultMessage: "Unlock",
  },
  winRate: {
    id: "crm.PartnershipSettings.PartnershipSettingsInsightOverview.winRate",
    defaultMessage: "Win Rate",
  },
});
