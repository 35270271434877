import { createSvgIcon } from "@mui/material/utils";

import { SvgIcon } from "./types";

const displayName = "SendLead";

const SendLead: SvgIcon = createSvgIcon(
  // Using transform to enlarge from provided viewbox="0 0 16 16"
  // to default "0 0 24 24" from `createSvgIcon` -> `SvgIcon`.
  <g fill="none" transform="matrix(1.5,0,0,1.5,0,0)">
    <path
      d="M7.8095 5.94602L7.865 6.50552L8.372 6.45527V5.94527H7.8095V5.94602ZM7.8095 10.4093H8.372V9.89927L7.86425 9.84977L7.8095 10.4093ZM1.0625 13.1828L0.5 13.1655L1.0625 13.1828ZM1.1975 13.23L0.7565 12.8805L1.1975 13.23ZM14.9435 8.45252L15.2945 8.89127L14.9435 8.45177V8.45252ZM7.247 2.93927V5.94527H8.372V2.93927H7.247ZM7.754 5.38502C3.758 5.78252 0.6215 9.09452 0.50075 13.1648L1.62575 13.1985C1.67677 11.5157 2.3379 9.90874 3.48593 8.67723C4.63397 7.44571 6.1906 6.6736 7.86575 6.50477L7.75325 5.38577L7.754 5.38502ZM1.6385 13.5795C3.0695 11.7743 5.36075 10.7355 7.75475 10.9695L7.86425 9.85052C5.0765 9.57752 2.41625 10.7865 0.7565 12.8805L1.6385 13.5795ZM7.247 10.4093V13.3793H8.372V10.4093H7.247ZM8.7695 14.1113L15.2945 8.89127L14.5917 8.01302L8.06675 13.233L8.77025 14.1105L8.7695 14.1113ZM15.2945 7.42727L8.7695 2.20727L8.0675 3.08627L14.5925 8.30627L15.2945 7.42727ZM7.247 13.3793C7.247 14.1653 8.156 14.6018 8.7695 14.1113L8.0675 13.233C8.09507 13.211 8.12829 13.1972 8.16335 13.1932C8.19841 13.1893 8.23387 13.1952 8.26568 13.2105C8.29748 13.2258 8.32433 13.2497 8.34314 13.2796C8.36195 13.3094 8.37195 13.344 8.372 13.3793H7.247ZM0.50075 13.1655C0.48125 13.8128 1.2785 14.0333 1.6385 13.5788L0.7565 12.8805C1.0265 12.5393 1.64 12.696 1.625 13.1993L0.5 13.1655H0.50075ZM15.2945 8.89127C15.4043 8.80344 15.4929 8.69204 15.5538 8.56534C15.6147 8.43863 15.6463 8.29985 15.6463 8.15927C15.6463 8.01869 15.6147 7.87991 15.5538 7.7532C15.4929 7.62649 15.4043 7.5151 15.2945 7.42727L14.5917 8.30552C14.5699 8.28795 14.5522 8.26569 14.54 8.24037C14.5279 8.21506 14.5216 8.18734 14.5216 8.15927C14.5216 8.13119 14.5279 8.10348 14.54 8.07816C14.5522 8.05285 14.5699 8.03059 14.5917 8.01302L15.2953 8.89127H15.2945ZM8.372 2.93927C8.37209 2.97461 8.36219 3.00927 8.34344 3.03923C8.32469 3.06919 8.29785 3.09324 8.26602 3.10861C8.23419 3.12397 8.19866 3.13003 8.16354 3.12608C8.12841 3.12212 8.09512 3.10832 8.0675 3.08627L8.77025 2.20727C8.156 1.71602 7.247 2.15327 7.247 2.93927H8.372Z"
      fill="currentColor"
    />
  </g>,
  displayName
);

SendLead.displayName = displayName;
export default SendLead;
