import { defineMessages } from "react-intl";

export default defineMessages({
  invitation_limit_error: {
    id: "error_messages.invitation_limit_error",
    defaultMessage: "The daily limit on invites sent has been reached.",
  },
  suggestion_already_exists_error: {
    id: "error_messages.suggestion_already_exists_error",
    defaultMessage: "Suggestion is invalid. Partnership already suggested.",
  },
  suggestion_partnership_exists_error: {
    id: "error_messages.suggestion_partnership_exists_error",
    defaultMessage: "This Partnership already exists",
  },
  invite_partnership_exists_error: {
    id: "error_messages.invite_partnership_exists_error",
    defaultMessage: "{companyName} is already a connected partner.",
  },
  invite_pending_current_email: {
    id: "error_messages.invite_pending_current_email",
    defaultMessage:
      "Your partnership with {companyName} is already pending. An invitation was previously sent to {name}.",
  },
  invite_pending_other_email: {
    id: "error_messages.invite_pending_other_email",
    defaultMessage: "Your partnership with {companyName} is already pending.",
  },
  external_key_already_exists_error: {
    id: "error_messages.external_key_already_exists_error",
    defaultMessage:
      "A lead has already be submitted to {companyName} for this account. You can't create a duplicate.",
  },
});
