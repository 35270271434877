import { AxiosError } from "axios";
import Button from "components/ui/Button";
import { T } from "components/ui/Typography";
import { Location } from "history";
import _ from "lodash";
import { makeStyles } from "makeStyles";
import { parse } from "query-string";
import { useCallback, useEffect, useState } from "react";
import { defineMessages, FormattedMessage } from "react-intl";
import { useHistory, useLocation } from "react-router";
import UserService from "services/UserService";

const getTokenFromURL = (location: Location) => {
  const parsed = parse(location.search);
  return (_.isArray(parsed.token) ? parsed.token[0] : parsed.token) ?? "";
};

enum EmailConfirmationError {
  emailAlreadyConfirmed = "has already been confirmed",
  tokenInvalid = "is invalid",
}

export const EmailConfirmation = () => {
  const { classes } = useStyles();
  const history = useHistory();
  const location = useLocation();
  const [error, setError] = useState<EmailConfirmationError>();

  const confirmEmail = useCallback(async (token: string) => {
    const service = new UserService();
    return await service.confirmEmail(token);
  }, []);

  useEffect(() => {
    let active = true;
    const setErrorIfActive = (value: EmailConfirmationError) => {
      if (active) {
        setError(value);
      }
    };
    const token = getTokenFromURL(location);
    if (token !== "") {
      confirmEmail(token).catch(
        (
          error: AxiosError<{ confirmation_token?: string[]; email?: string[] }>
        ) => {
          if (
            error.response?.data.confirmation_token?.includes(
              EmailConfirmationError.tokenInvalid
            )
          ) {
            setErrorIfActive(EmailConfirmationError.tokenInvalid);
          } else if (
            error.response?.data.email?.includes(
              EmailConfirmationError.emailAlreadyConfirmed
            )
          ) {
            setErrorIfActive(EmailConfirmationError.emailAlreadyConfirmed);
          }
        }
      );
    }
    return () => {
      active = false;
    };
  }, [confirmEmail, location]);

  const handleRedirect = (url: string) => {
    history.push(url);
  };

  const endButton = (
    <Button
      onClick={() => handleRedirect(error ? "/" : "/partnerships")}
      label={error ? i18n.closeTab : i18n.goToDashboard}
    />
  );

  return (
    <div className={classes.root}>
      {error && (
        <>
          <T h2>
            {error === EmailConfirmationError.tokenInvalid && (
              <FormattedMessage {...i18n.invalidLink} />
            )}
            {error === EmailConfirmationError.emailAlreadyConfirmed && (
              <FormattedMessage {...i18n.alreadyConfirmed} />
            )}
          </T>
          <br />
          <br />
          {endButton}
        </>
      )}
      {!error && (
        <>
          <T h2>
            <FormattedMessage {...i18n.confirmed} />
          </T>
          <br />
          <br />
          {endButton}
        </>
      )}
    </div>
  );
};

export default EmailConfirmation;

// CSS

const useStyles = makeStyles()((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    marginTop: theme.spacing(5),
    textAlign: "center",
    "& > :not(:last-child)": {
      marginBottom: theme.spacing(1),
    },
    padding: theme.spacing(3),
  },
  error: {
    marginTop: 90,
  },
}));

// I18N

const i18n = defineMessages({
  invalidLink: {
    id: "emailConfirmation.invalidLink",
    description: "Message for invalid link when trying to confirm email",
    defaultMessage: "This link is not valid.",
  },
  alreadyConfirmed: {
    id: "emailConfirmation.alreadyConfirmed",
    description: "Message for already confirmed email",
    defaultMessage: "Your email has already been confirmed",
  },
  confirmed: {
    id: "emailConfirmation.confirmed",
    description: "Message for already confirmed email",
    defaultMessage: "Your email has been confirmed",
  },
  goToDashboard: {
    id: "emailConfirmation.goToDashboard",
    defaultMessage: "Go to Dashboard",
  },
  closeTab: {
    id: "emailConfirmation.closeTab",
    defaultMessage: "Close tab",
  },
});
