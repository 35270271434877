import {
  AnyAction,
  createAsyncThunk,
  isFulfilled,
  isRejected,
  ThunkDispatch,
} from "@reduxjs/toolkit";
import _ from "lodash";
import { Factory } from "models";
import { PageType } from "models/PageView";
import { JSONAPIAttributes, JSONAPIResource } from "models/types";
import { create, index, rawGet, update } from "redux/api/thunks";
import { asJSONSerializable, RevealStore } from "redux/typing";
import {
  updateLastVisitedAt,
  updateLastVisitedAtAndFilters,
} from "redux/utils";
import {
  asPersistedColumn,
  ColumnConfigType,
  FilterType,
  SortType,
} from "screens/Frontoffice/screens/DataTables/shared/types";
import { JSONAPIResponse } from "services/types";
import UserService from "services/UserService";

import {
  defaultAnalyticsColumnWithMetrics,
  defaultAnalyticsFilters,
  defaultAnalyticsSort,
} from "./defaults";
import { selectHasAdvancedAnalytics } from "./selectors";
import { AnalyticsActions, RootStateWithAnalytics } from "./types";

export const loadView = createAsyncThunk(
  AnalyticsActions.loadView,
  async (_: void, thunkAPI) => {
    const state = thunkAPI.getState() as RevealStore;
    if (state.analytics.view) {
      return state.analytics.view;
    }
    const indexView = await thunkAPI.dispatch(
      index({
        type: "page_views",
        options: {
          filters: {
            page_type: PageType.partnerAnalytics,
          },
        },
      })
    );

    if (isRejected(indexView)) {
      throw new Error("Cannot load partner analytics view");
    }

    let recordData: JSONAPIResource;
    if (indexView.payload.data.length > 0) {
      recordData = indexView.payload.data[0];
      const record = Factory.createRecord(recordData);
      const hasAdvancedAnalytics = await selectHasAdvancedAnalytics(state);
      return updateLastVisitedAtAndFilters(
        record,
        thunkAPI.dispatch as ThunkDispatch<RevealStore, any, AnyAction>,
        hasAdvancedAnalytics
      );
    } else {
      const result = await thunkAPI.dispatch(
        create({
          type: "page_views",
          attributes: {
            page_type: PageType.partnerAnalytics,
            filters: defaultAnalyticsFilters,
            sort: defaultAnalyticsSort,
            columns: asJSONSerializable(
              defaultAnalyticsColumnWithMetrics.map(asPersistedColumn)
            ),
          },
        })
      );
      if (isFulfilled(result)) {
        const response = result.payload as JSONAPIResponse;
        recordData = response.data;
        const record = Factory.createRecord(recordData);
        await updateLastVisitedAt(
          record.id,
          thunkAPI.dispatch as ThunkDispatch<RevealStore, any, AnyAction>
        );
        return record;
      }
    }
  }
);

export const updateView = createAsyncThunk(
  AnalyticsActions.updateView,
  async (
    {
      filters,
      columns,
      sort,
      lastVisitedAt,
      filterOrderList,
    }: {
      filters?: FilterType[];
      columns?: ColumnConfigType[];
      sort?: SortType[];
      lastVisitedAt?: Date;
      filterOrderList?: number[];
    },
    thunkAPI
  ) => {
    const state = thunkAPI.getState() as RootStateWithAnalytics;
    const recordId = state.analytics.view?.id;
    if (recordId === undefined) {
      throw new Error("No view available for analytics from redux store");
    }

    const attributes: JSONAPIAttributes = {};
    if (filters) {
      attributes["filters"] = asJSONSerializable(filters);
    }
    if (columns) {
      attributes["columns"] = asJSONSerializable(
        columns.map(asPersistedColumn)
      );
    }
    if (sort) {
      attributes["sort"] = asJSONSerializable(sort);
    }
    if (filterOrderList) {
      attributes["filter_order_list"] = filterOrderList;
    }
    if (lastVisitedAt !== undefined) {
      const userService = new UserService();
      if (!userService.isImpersonating) {
        attributes["last_visited_at"] = lastVisitedAt.toISOString();
      }
    }

    if (_.isEmpty(attributes)) {
      return state.analytics.view;
    }

    const result = await thunkAPI.dispatch(
      update({
        id: recordId,
        type: "page_views",
        attributes,
      })
    );
    if (isFulfilled(result)) {
      const response = result.payload as JSONAPIResponse;
      const record = Factory.createRecord(response.data);
      return record;
    } else {
      throw new Error("Cannot update partner analytics view");
    }
  }
);

export const checkHighWinRatePresence = createAsyncThunk(
  AnalyticsActions.checkHighWinRatePresence,
  async (value: void, thunkAPI) => {
    const state = thunkAPI.getState() as RevealStore;
    if (state.analytics.hasHighWinRatePartners !== null) {
      return state.analytics.hasHighWinRatePartners;
    }

    const getHighWinRate = await thunkAPI.dispatch(
      rawGet({
        type: "partnership_upside_potentials",
        path: "",
        options: {
          filters: { "win_rate_increase.gte": 0.2 },
          page: { size: 1 },
        },
      })
    );

    if (isRejected(getHighWinRate)) {
      throw new Error("Cannot check high win rate presence");
    }

    return (getHighWinRate.payload as any).data.length > 0;
  }
);
