import withAPI, { WithAPI } from "components/hoc/withAPI";
import BaseSettingsTab from "components/ui/BaseSettingsTab";
import { SWQLTarget } from "components/ui/SWQL/SWQLTypes";
import useAllRecords from "hooks/useAllRecords";
import useHasPayingFeature from "hooks/useHasPayingFeature";
import usePushNotification from "hooks/usePushNotification";
import useSegment from "hooks/useSegment";
import useUserProfile from "hooks/useUserProfile";
import CrmField from "models/CrmField";
import { useEffect, useState } from "react";
import {
  defineMessages,
  FormattedHTMLMessage,
  FormattedMessage,
} from "react-intl";
import { useHistory, useLocation } from "react-router-dom";

import { updateHistoryUpdatedSource } from "../helpers/utils";
import BaseMappingRulesWidget from "./BaseMappingRulesWidget";
import OpportunityDisplayWidget from "./OpportunityDisplayWidget";

const i18n = defineMessages({
  summaryStart: {
    id: "crm.settings.OpenOpportunitiesWidget.summaryStart",
    defaultMessage: "Please help us identify your open opportunities",
  },
  openOpportunitiesAre: {
    id: "crm.settings.OpenOpportunitiesWidget.openOpportunitiesAre",
    defaultMessage: "<b>Open opportunities</b> are opportunities where",
  },
});

type Props = WithAPI & {
  integrationId: string | number;
  handleClose?: () => void;
  sourceType: string;
};

// Open opportunities always have `"is_open": true` in swql, that's why
// here we have `key: true` and `attribute="is_open"`.
const OpenOpportunitiesWidget = ({
  integrationId,
  rawPost,
  ...props
}: Props) => {
  const pushNotification = usePushNotification();
  const ruleList = [
    {
      key: true,
      label: <FormattedHTMLMessage {...i18n.openOpportunitiesAre} />,
    },
  ];
  const [selectedFields, setSelectedFields] = useState<CrmField[]>([]);
  const { profile } = useUserProfile();
  const hasOpenOpportunityDefinitionPayingFeature = useHasPayingFeature(
    "OpenOpportunityDefinition",
    profile
  );
  const { track } = useSegment();
  const history = useHistory();
  const location = useLocation();

  const {
    records: crmFieldsRecords,
    loading: crmFieldsLoading,
  }: {
    records: CrmField[];
    loading: boolean;
    refresh: () => void;
  } = useAllRecords("crm_fields", {
    filters: {
      integration: integrationId,
      sw_record_type: SWQLTarget.RawOpportunity,
      "sw_type.in": CrmField.SWQL_SUPPORTED_FIELD_TYPES_WITH_DATES,
      import_field: true,
      syncable: true,
    },
  });

  const stringifiedFields = JSON.stringify(crmFieldsRecords, [
    "attributes",
    "relationships",
  ]);

  useEffect(() => {
    if (!crmFieldsLoading && crmFieldsRecords.length) {
      const selected = crmFieldsRecords
        .filter(
          (field) =>
            field.displayIndex !== null && field.displayIndex !== undefined
        )
        .sort((a, b) => (a.displayIndex > b.displayIndex ? 1 : -1));
      setSelectedFields(selected);
    }
  }, [crmFieldsLoading, stringifiedFields]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (hasOpenOpportunityDefinitionPayingFeature === false) {
      track("Viewed data source open opportunities display");
    }
  }, [track, hasOpenOpportunityDefinitionPayingFeature]);

  const saveOpportDisplayFields = async () => {
    await rawPost(
      "integrations",
      +integrationId,
      "/displayed-opportunity-fields/",
      {
        data: selectedFields.map((field) => ({
          id: field.id,
          type: field.type,
        })),
      }
    ).then((resp: $TSFixMe) => {
      if (resp?.error) {
        pushNotification("default_error");
      } else {
        updateHistoryUpdatedSource(history, location);
      }
    });
  };

  const opportunityDisplay = (
    <OpportunityDisplayWidget
      integrationId={+integrationId}
      allFields={crmFieldsRecords}
      selectedFields={selectedFields}
      setSelectedFields={setSelectedFields}
      loading={crmFieldsLoading}
    />
  );

  if (hasOpenOpportunityDefinitionPayingFeature) {
    return (
      <BaseMappingRulesWidget
        integrationId={integrationId}
        ruleList={ruleList}
        attribute={"is_open"}
        summary={<FormattedMessage {...i18n.summaryStart} />}
        trackedLocation="data source open opportunities"
        swqlTarget={SWQLTarget.RawOpportunity}
        saveCallback={saveOpportDisplayFields}
        extraContent={opportunityDisplay}
        {...props}
      />
    );
  } else {
    return (
      <BaseSettingsTab
        handleSave={saveOpportDisplayFields}
        hideSummary={true}
        loading={hasOpenOpportunityDefinitionPayingFeature === null}
        {...props}
      >
        {opportunityDisplay}
      </BaseSettingsTab>
    );
  }
};

export default withAPI(OpenOpportunitiesWidget);
