import {
  Archive,
  Delete,
  SvgIconComponent,
  Unarchive,
} from "@mui/icons-material";
import { isFulfilled } from "@reduxjs/toolkit";
import { PlusMessage } from "components/icons";
import Button from "components/ui/Button";
import SelectedRowsContext from "components/ui/data-grid/SelectedRowsContext";
import Tooltip from "components/ui/Tooltip";
import usePushNotification from "hooks/usePushNotification";
import useSelectorWithDeepEquality from "hooks/useSelectorWithDeepEquality";
import _ from "lodash";
import { makeStyles } from "makeStyles";
import Match from "models/Match";
import PartnerConnection from "models/PartnerConnection";
import Record from "models/Record";
import { RecordType } from "models/types";
import { useCallback, useContext, useMemo, useState } from "react";
import { defineMessages, useIntl } from "react-intl";
import { useDispatch } from "react-redux";
import { bulkUpdate, rawDelete } from "redux/api/thunks";
import { NotificationActionType } from "redux/notifications/typing";
import {
  selectPerspective,
  selectShowArchived,
} from "redux/pipeline/selectors";

import { AddPipelineItemModal } from "./AddToPipelineModal/";
import MyPipelineDeleteConfirmationDialog from "./MyPipelineDeleteConfirmationDialog";

type Props = {
  records: Record[];
  refresh: () => void;
};

const ActionButtons = ({ records, refresh }: Props) => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const pushNotification = usePushNotification();
  const modalStyles = useModalStyles();
  const { selectedRowIds, clearSelection } = useContext(SelectedRowsContext);
  const perspective = useSelectorWithDeepEquality(selectPerspective);
  const showArchive = useSelectorWithDeepEquality(selectShowArchived);

  const [
    deleteConfirmationIsOpen,
    setDeleteConfirmationIsOpen,
  ] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [isAddPipelineModalOpen, setIsAddPipelineModalOpen] = useState<boolean>(
    false
  );

  const startAdding = () => {
    setIsAddPipelineModalOpen(true);
  };

  const updateArchiveState = useCallback(
    (shouldArchive: boolean) => async () => {
      let duplicateCount = 0;
      let noActionCount = 0;
      const filtered = selectedRowIds.filter((id) =>
        records.some((record) => {
          const isActive = record.archivedAt === null;
          let matchingFilter = record.id === id && shouldArchive === isActive;
          // When user unarchive a record, we check if there is no identical active record
          if (matchingFilter && !shouldArchive) {
            const isDuplicate = records
              .filter((item) => item.archivedAt === null)
              .some(
                (el) =>
                  el.rawCompanyId === record.rawCompanyId &&
                  el.partnershipId === record.partnershipId &&
                  el.userId === record.userId
              );
            if (isDuplicate) {
              duplicateCount++;
              matchingFilter = false;
            }
          }
          // Computing the count of items that are already in the wanted state.
          // We display this info in the notification.
          if (record.id === id && shouldArchive !== isActive) {
            noActionCount++;
          }
          return matchingFilter;
        })
      ) as number[];

      const partnerConnectionsToArchive = filtered.map((id) => ({
        type: RecordType.PartnerConnection,
        id,
        attributes: { archivedAt: shouldArchive ? new Date() : null },
      }));

      const result = await dispatch(bulkUpdate(partnerConnectionsToArchive));
      if (isFulfilled(result)) {
        // Clean hidden records from store
        if (
          partnerConnectionsToArchive.length > 0 &&
          shouldArchive &&
          !showArchive
        ) {
          refresh();
          // TODO fix processRemoveRecords reducer use in RVL-8276
          // dispatch(
          //   bulkRemoveRecords(
          //     partnerConnectionsToArchive.map(({ id, type }) => ({
          //       id: String(id),
          //       type,
          //     }))
          //   )
          // );
        }
      }

      if (filtered.length || duplicateCount || noActionCount) {
        pushNotification(
          shouldArchive
            ? i18n.partnerConnectionArchived
            : i18n.partnerConnectionReopened,
          {
            itemCount: filtered.length,
            duplicateCount,
            noActionCount,
          },
          {
            type: NotificationActionType.archivePipelineActions,
            partnerConnectionIds: filtered,
            shouldArchive: !shouldArchive,
          }
        );
      }
      clearSelection();
    },
    [dispatch, selectedRowIds, JSON.stringify(records), pushNotification] // eslint-disable-line react-hooks/exhaustive-deps
  );

  const deletePartnerConnections = useCallback(
    async (callback: () => void) => {
      /**
       * Cannot delete a PartnerConnection that has opportunities linked through a PartnerConnectionSyncRule
       * Using a set will make the check filtering on partnerConnection 10x faster
       * because "has" is O(1) in complexity, vs 0(n)
       * Reference: https://www.tech-hour.com/javascript-performance-and-optimization
       **/
      const selectedIdsSet = new Set(selectedRowIds);
      const selectedRecords = records.filter((record) =>
        selectedIdsSet.has(record.id)
      );
      const [blocked, toDelete] = _.partition(
        selectedRecords,
        (record: PartnerConnection) =>
          record.rawOpportunitiesWithRule?.length > 0
      ) as [PartnerConnection[], PartnerConnection[]];
      const idsToDelete: number[] = toDelete.map((record) => record.id);
      const blockedCount = blocked.length;

      const partnerConnectionPayload = {
        data: {
          data: idsToDelete.map((id) => ({
            id: id.toString(),
            type: "partner_connections",
          })),
        },
      };

      const result = await dispatch(
        rawDelete({
          type: "partner_connections",
          prefixPath: "bulk",
          suffixPath: "",
          options: partnerConnectionPayload,
        })
      );
      if (isFulfilled(result)) {
        // Clean deleted records from store
        refresh();
        // TODO fix processRemoveRecords reducer use in RVL-8276
        // await dispatch(bulkRemoveRecords(partnerConnectionPayload.data.data));
        clearSelection();
        callback();
        pushNotification(i18n.bulkDeleteSuccess, {
          itemCount: idsToDelete.length,
          blockedCount,
        });
      } else {
        pushNotification("default_error");
      }
    },
    [dispatch, JSON.stringify(records), selectedRowIds, pushNotification] // eslint-disable-line react-hooks/exhaustive-deps
  );

  const archivePartnerConnections = useMemo(() => updateArchiveState(true), [
    updateArchiveState,
  ]);
  const unarchivePartnerConnections = useMemo(() => updateArchiveState(false), [
    updateArchiveState,
  ]);

  const handleBulkDeletion = useCallback(() => {
    setLoading(true);

    deletePartnerConnections(() => {
      setDeleteConfirmationIsOpen(false);
      setLoading(false);
    });
  }, [deletePartnerConnections]);

  if (perspective === Match.PERSPECTIVE_THEIRS) return null;

  return (
    <>
      <Button
        LeftIcon={PlusMessage}
        label={i18n.createPartnerDiscussion}
        onClick={startAdding}
        size="small"
      />
      {selectedRowIds.length !== 0 && (
        <>
          <IconButton
            title={intl.formatMessage(i18n.archive)}
            Icon={Archive}
            onClick={archivePartnerConnections}
          />
          <IconButton
            title={intl.formatMessage(i18n.unarchive)}
            Icon={Unarchive}
            onClick={unarchivePartnerConnections}
          />
          <IconButton
            title={intl.formatMessage(i18n.deleteFromPipeline)}
            Icon={Delete}
            onClick={() => setDeleteConfirmationIsOpen(true)}
          />
        </>
      )}
      <MyPipelineDeleteConfirmationDialog
        modalStyles={modalStyles}
        deleteConfirmationIsOpen={deleteConfirmationIsOpen}
        setDeleteConfirmationIsOpen={setDeleteConfirmationIsOpen}
        loading={loading}
        handleBulkDeletion={handleBulkDeletion}
      />
      <AddPipelineItemModal
        isOpen={isAddPipelineModalOpen}
        onClose={() => setIsAddPipelineModalOpen(false)}
        refresh={refresh}
        records={records}
      />
    </>
  );
};

export default ActionButtons;

// Helpers

type IconButtonProps = {
  title: string;
  onClick: () => void;
  Icon: SvgIconComponent;
};

const IconButton = ({ title, Icon, onClick }: IconButtonProps) => {
  const { classes } = useIconButtonStyles();
  return (
    <div>
      <Tooltip title={title}>
        {/* N.B. `div` is used to wrap the button otherwise the tooltip only shows up after a click. */}
        <div>
          <Button
            LeftIcon={Icon}
            label=""
            aria-label={title}
            onClick={onClick}
            classes={classes}
            size="small"
          />
        </div>
      </Tooltip>
    </div>
  );
};

// CSS

const useIconButtonStyles = makeStyles()((theme) => ({
  btn: {
    "& > span > div": {
      padding: 7,
    },
    "& svg": {
      margin: "auto",
    },
  },
}));

const useModalStyles = makeStyles()((theme) => ({
  dialogContainer: {
    "& .MuiPaper-root": {
      textAlign: "center",
      padding: theme.spacing(5),
      width: 540,
    },
    "& .MuiDialogActions-root": {
      marginTop: theme.spacing(3),
      justifyContent: "center",
    },
  },
}));

// I18N

const i18n = defineMessages({
  createPartnerDiscussion: {
    id: "Pipeline.ActionButtons.createPartnerDiscussion",
    defaultMessage: "Add to Collaborate",
  },
  deleteFromPipeline: {
    id: "Pipeline.ActionButtons.deleteFromPipeline",
    defaultMessage: "Delete",
  },
  archive: {
    id: "Pipeline.ActionButtons.archive",
    defaultMessage: "Archive",
  },
  unarchive: {
    id: "Pipeline.ActionButtons.unarchive",
    defaultMessage: "Unarchive",
  },
  bulkDeleteSuccess: {
    id: "Pipeline.ActionButtons.bulkDeleteSuccess",
    defaultMessage: `
      {itemCount, plural, =0 {} one {1 item have been deleted.<br/>} other {# items have been deleted.<br/>}}
      {blockedCount, plural, =0 {} one {1 item couldn’t be deleted because it has been imported from your CRM.} other {# items couldn’t be deleted because they have been imported from your CRM.}}
    `,
  },
  partnerConnectionArchived: {
    id: "Pipeline.ActionButtons.partnerConnectionArchived",
    defaultMessage: `
        {itemCount, plural, =0 {} one {1 item have been archived.<br/>} other {# items have been archived.<br/>}}
        {noActionCount, plural, =0 {} one {1 item was already archived.} other {# items were already archived.}}
      `,
  },
  partnerConnectionReopened: {
    id: "Pipeline.ActionButtons.partnerConnectionReopened",
    defaultMessage: `
        {itemCount, plural, =0 {} one {1 item have been reopened.<br/>} other {# items have been reopened.<br/>}}
        {duplicateCount, plural, =0 {} one {1 item couldn't be reopened because there is duplicate.<br/>} other {# items couldn't be reopened because there are duplicates.<br/>}}
        {noActionCount, plural, =0 {} one {1 item was already opened.} other {# items were already opened.}}
      `,
  },
});
