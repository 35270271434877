import { Box } from "@mui/material";
import { Settings } from "components/icons";
import { T } from "components/ui/Typography";
import { parse, stringify } from "query-string";
import { defineMessages, FormattedMessage } from "react-intl";
import { useHistory, useLocation } from "react-router-dom";

import { BaseSidePanel, sharedCrmBaseSidePanelStyles } from "./BaseSidePanel";
import { HelpBox } from "./HelpBox";

export const SourceActivationSidePanel = () => {
  const { cx, classes } = sharedCrmBaseSidePanelStyles();
  const history = useHistory();
  const location = useLocation();

  const handleRedirect = () => {
    const parsed = parse(location.search);
    delete parsed.init;
    delete parsed.onboarding;
    history.replace({
      ...location,
      pathname: "/sources",
      search: stringify(parsed),
    });
  };
  return (
    <BaseSidePanel>
      <HelpBox
        i18nTitle={i18n.title}
        i18nSubtitle={i18n.text}
        extraSlot={
          <Box mt={2} onClick={handleRedirect}>
            <T className={cx(classes.helpBoxLink, classes.underline)}>
              <FormattedMessage {...i18n.dataSourceSettings} />
              <Settings />
            </T>
          </Box>
        }
      />
    </BaseSidePanel>
  );
};

const i18n = defineMessages({
  title: {
    id: "crm.settings.SourceActivationSidePanel.title",
    defaultMessage: "Your data is being processed",
  },
  text: {
    id: "crm.settings.SourceActivationSidePanel.text",
    defaultMessage: "You'll be notified when your data source is ready",
  },
  dataSourceSettings: {
    id: "crm.settings.SourceActivationSidePanel.dataSourceSettings",
    defaultMessage: "Data source settings",
  },
});
