import CircularProgress from "@mui/material/CircularProgress";
import Alert from "components/ui/Alert";
import { T } from "components/ui/Typography";
import { makeStyles } from "makeStyles";
import { defineMessages, FormattedMessage } from "react-intl";

type EmptyRowViewProps = {
  hasFilters?: boolean;
  loading?: boolean;
  isImporting?: boolean;
};

export const EmptyRowView = ({
  hasFilters,
  loading,
  isImporting,
}: EmptyRowViewProps) => {
  const { classes } = useStyles();

  return (
    <div className={classes.root}>
      {loading ? (
        <CircularProgress />
      ) : (
        <Alert variant="warning" icon>
          <T>
            <FormattedMessage
              {...(isImporting
                ? i18n.notReady
                : hasFilters
                ? i18n.noResultWithFilters
                : i18n.noResult)}
            />
          </T>
        </Alert>
      )}
    </div>
  );
};

export default EmptyRowView;

/// CSS

const useStyles = makeStyles()((theme) => ({
  root: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    padding: theme.spacing(4),
  },
}));

// I18N

const i18n = defineMessages({
  noResult: {
    id: "Mapping360.EmptyRowView.noResult",
    defaultMessage:
      'There are no companies to display. Make sure you have connected a data source ("Sources").',
  },
  noResultWithFilters: {
    id: "Mapping360.EmptyRowView.noResultWithFilters",
    defaultMessage: "There are no companies matching these filters.",
  },
  notReady: {
    id: "Mapping360.EmptyRowView.notReady",
    defaultMessage: "We're currently importing your data.",
  },
});
