import CircularProgress from "@mui/material/CircularProgress";
import { Download, Excel } from "components/icons";
import Button from "components/ui/Button";
import DropdownItem from "components/ui/Dropdown/components/DropdownItem";
import Tooltip from "components/ui/Tooltip";
import { T } from "components/ui/Typography";
import { makeStyles } from "makeStyles";
import { ReactNode } from "react";
import { defineMessages, FormattedMessage } from "react-intl";
import { ReportType } from "screens/Frontoffice/screens/DataTables/shared/utils";

type ExportXlsxProps = {
  isDisabled?: boolean;
  isGenerating: boolean;
  isReady: boolean;
  download: () => void;
  selectedRowsCount?: number;
  tooltip?: ReactNode;
  reportType: ReportType;
};

type Props = ExportXlsxProps;

// TODO-RVL-9406: For all Export... components, use a generic component to provide contents
const ExportXlsx = ({
  isDisabled,
  isGenerating,
  isReady,
  download,
  selectedRowsCount = 0,
  tooltip,
  reportType,
}: Props) => {
  const { classes } = useStyles();
  const isPipelineButton = reportType === ReportType.COLLABORATE;

  const getLabel = () => {
    if (isGenerating) return i18n.generating;
    if (selectedRowsCount > 0) return i18n.downloadSelected;
    return i18n.downloadAll;
  };

  const item = (
    <DropdownItem
      name={
        <>
          <T className={classes.title}>
            <Excel className={classes.icon} />
            <FormattedMessage
              {...getLabel()}
              values={{ count: selectedRowsCount }}
            />
          </T>
        </>
      }
      onChange={download}
      isDisabled={isGenerating || isDisabled}
    />
  );

  const btn = (
    <Button
      label={
        <>
          <T className={classes.title}>
            <Download className={classes.icon} />
            <FormattedMessage
              {...getLabel()}
              values={{ count: selectedRowsCount }}
            />
          </T>
        </>
      }
      variant="secondary"
      onClick={download}
      disabled={isGenerating || isDisabled}
      size={"small"}
    />
  );

  if (isDisabled) {
    return (
      <Tooltip title={tooltip} placement="bottom">
        <span>{isPipelineButton ? btn : item}</span>
      </Tooltip>
    );
  }

  return isReady ? (
    isPipelineButton ? (
      btn
    ) : (
      item
    )
  ) : (
    <div data-testid="export-xlsx-loader" className={classes.progressContainer}>
      <CircularProgress color="inherit" size={16} />
    </div>
  );
};

export default ExportXlsx;

/// CSS

const useStyles = makeStyles()((theme) => ({
  icon: {
    color: theme.palette.midnight,
    width: 15,
    height: 15,
  },
  title: {
    display: "flex",
    alignItems: "center",
    columnGap: 6,
  },
  progressContainer: {
    display: "flex",
    justifyContent: "center",
  },
}));

// I18N

const i18n = defineMessages({
  download: {
    id: "crm.Reports.ExportXlsx.downloadAsCsv",
    defaultMessage: "Download as .xlsx",
  },
  downloadSelected: {
    id: "crm.Reports.ExportXlsx.downloadSelectedAsCsv",
    defaultMessage: "Download {count} selected as .xlsx",
  },
  downloadAll: {
    id: "crm.Reports.ExportXlsx.downloadAllAsCsv",
    defaultMessage: "Download all as .xlsx",
  },
  generating: {
    id: "crm.Reports.ExportXlsx.generating",
    defaultMessage: "Generating as .xlsx",
  },
});

export const _private = {
  i18n,
};
