import Switch from "@mui/material/Switch";
import { makeStyles } from "makeStyles";
import { ChangeEvent, MouseEventHandler } from "react";

type Size = "big" | "small";

export type StyleProps = {
  size: Size;
  disabled?: boolean;
};

export type Props = Partial<StyleProps> & {
  checked: boolean;
  handleChange?: (
    event: ChangeEvent<HTMLInputElement>,
    checked: boolean
  ) => void;
  onClick?: MouseEventHandler<HTMLButtonElement>;
  className?: string;
  id?: string;
  name?: string;
};

const Toggle = ({
  id,
  checked,
  handleChange = () => {},
  onClick = () => {},
  disabled = false,
  name,
  size = "small",
  className,
}: Props) => {
  const { classes, cx } = useStyles({
    size,
  });
  const classNameFinal = cx(classes.root, className);

  return (
    <Switch
      id={id}
      checked={checked}
      onChange={handleChange}
      className={classNameFinal}
      disabled={disabled}
      data-testid="toggle"
      name={name}
      onClick={onClick}
    />
  );
};

// CSS Helpers

const toggleHeight = {
  big: 16,
  small: 12,
};

const toggleWidth = {
  big: 26,
  small: 18,
};

const toggleSize = {
  big: 12,
  small: 8,
};

// Styles

export const useStyles = makeStyles<{ size: Size }>()((theme, { size }) => ({
  root: {
    height: toggleHeight[size],
    width: toggleWidth[size],
    padding: 0,
    "& .MuiSwitch-switchBase": {
      padding: 2,
      "&.Mui-checked": {
        transform: size === "small" ? "translateX(6px)" : "translateX(10px)",
        color: theme.palette.white,
        "& + .MuiSwitch-track": {
          opacity: 1,
          backgroundColor: theme.palette.midnight,
        },
      },
      "&.Mui-disabled": {
        "& + .MuiSwitch-track": {
          opacity: 0.6,
        },
      },
    },
    "& .MuiSwitch-thumb": {
      boxShadow: "none",
      width: toggleSize[size],
      height: toggleSize[size],
      borderRadius: 6,
      transition: theme.transitions.create(["width"], {
        duration: 200,
      }),
    },
    "& .MuiSwitch-track": {
      borderRadius: 12,
      opacity: 1,
      backgroundColor: theme.palette.taupe,
      boxSizing: "border-box",
    },
  },
}));

export default Toggle;
