import {
  boolean,
  constant,
  guard,
  inexact,
  iso8601,
  nullable,
  number,
  object,
  optional,
  string,
} from "decoders";

import { Record } from "./Record";
import { JSONAPIResourceOrRecord } from "./types";

const notificationDecoder = object({
  type: constant("notifications" as const),
  id: string,
  attributes: object({
    read_by_user: boolean,
    notification_type: number,
    created_at: iso8601,
    updated_at: nullable(iso8601),
    completed_at: nullable(iso8601),
    information: inexact({
      number_of_referred_accounts: optional(number),
    }),
  }),
});

const notificationGuard = guard(notificationDecoder);

export default class Notification extends Record<"notifications"> {
  static TYPE_REFERRED_ACCOUNT = 1;
  static TYPE_ADDED_DISCUSSIONS = 2;
  static TYPE_MISSING_IN_CRM = 3;

  readByUser: boolean;
  notificationType: number;
  createdAt: Date;
  updatedAt: Date | null;
  completedAt: Date | null;
  information: {
    number_of_referred_accounts?: number;
    [key: string]: unknown;
  };

  constructor(data: JSONAPIResourceOrRecord<"notifications">) {
    super(data);
    const { attributes } = notificationGuard(data);
    this.readByUser = attributes.read_by_user;
    this.notificationType = attributes.notification_type;
    this.information = attributes.information;
    this.createdAt = attributes.created_at;
    this.updatedAt = attributes.updated_at;
    this.completedAt = attributes.completed_at;
  }
}

export const NOTIFICATION_TYPE_NAME = {
  [Notification.TYPE_REFERRED_ACCOUNT]: "Referred Accounts",
  [Notification.TYPE_ADDED_DISCUSSIONS]: "Added Discussions",
  [Notification.TYPE_MISSING_IN_CRM]: "Missing in CRM",
};
