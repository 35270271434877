import DialogActions from "@mui/material/DialogActions";
import FormControl from "@mui/material/FormControl";
import FormGroup from "@mui/material/FormGroup";
import FormHelperText from "@mui/material/FormHelperText";
import Grid from "@mui/material/Grid";
import axios from "axios";
import { Send } from "components/icons";
import CompanyAvatar from "components/ui/avatars/CompanyAvatar";
import Button from "components/ui/Button";
import { NotificationStatus } from "components/ui/Notifications/NotificationSnackbar";
import { SelectInput } from "components/ui/SelectInput";
import { TextInput } from "components/ui/TextInput";
import { T } from "components/ui/Typography";
import { Formik, FormikHelpers } from "formik";
import { getTrimmedData } from "helpers/trim";
import useAvailableRoles from "hooks/useAvailableRoles";
import usePushNotification from "hooks/usePushNotification";
import useSegment from "hooks/useSegment";
import useUserProfile from "hooks/useUserProfile";
import forms from "i18n/forms";
import _ from "lodash";
import { makeStyles } from "makeStyles";
import User from "models/User";
import { defineMessages, FormattedMessage, useIntl } from "react-intl";
import { useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";
import { retreive } from "redux/api/thunks";
import UserService from "services/UserService";
import { object, string } from "yup";

const DEFAULT_ROLES = [
  "company.admin",
  "company.sales",
  "company.partnership_manager",
  "company.sales_manager",
];

type FormValues = {
  first_name: string;
  last_name: string;
  email: string;
  role_name?: string;
};

type InvitationModalProps = {
  handleClose: () => void;
};

/** {{{ Formik Functions & Configs */
const inviteWithRoleSchema = object().shape({
  email: string()
    .required(() => forms.email_required)
    .email(() => forms.email_invalid),
  first_name: string().required(() => forms.contact_first_name_required),
  last_name: string().required(() => forms.contact_last_name_required),
  role_name: string().required(() => i18n.roleRequired),
});

const inviteWithoutRoleSchema = object().shape({
  email: string()
    .required(() => forms.email_required)
    .email(() => forms.email_invalid),
  first_name: string().required(() => forms.contact_first_name_required),
  last_name: string().required(() => forms.contact_last_name_required),
});

export const initialValues: FormValues = {
  first_name: "",
  last_name: "",
  email: "",
  role_name: "",
};
/** }}} */

export const createInvite = async (profile: User, values: FormValues) => {
  const service = new UserService();
  return await service.createInvitation({
    ..._(values).pick(["email", "first_name", "last_name"]).value(),
    role_name: values.role_name || undefined,
    company_id: profile.company.id,
  });
};

const DUPLICATE_INVITE_ERROR = "has already been taken";

const InvitationModal = ({ handleClose }: InvitationModalProps) => {
  const { classes } = useStyles();
  const { classes: avatarClasses } = useAvatarStyles();
  const dispatch = useDispatch();
  const { track } = useSegment();
  const pushNotification = usePushNotification();
  const { profile } = useUserProfile();
  const intl = useIntl();
  const location = useLocation();
  const roles = useAvailableRoles();
  const displayedRoles = roles.filter((role) =>
    DEFAULT_ROLES.includes(role.name)
  );

  const fetchUser = async (id: number) => {
    dispatch(
      retreive({
        id: id,
        type: "users",
        options: { include: ["invited_by", "role_assignments", "company"] },
      })
    );
  };

  const handleSubmit = async (
    valuesRaw: FormValues,
    actions: FormikHelpers<FormValues>
  ) => {
    actions.setSubmitting(true);
    const values = getTrimmedData(valuesRaw);
    try {
      const userCreated = await createInvite(profile, values);
      if (userCreated.error) {
        throw userCreated.payload;
      } else {
        pushNotification({ ...i18n.invitationSent });
        handleClose();
        actions.resetForm();
        track("Invited user");
        fetchUser(userCreated.id);
      }
    } catch (error) {
      if (
        axios.isAxiosError(error) &&
        error.response?.status === 404 &&
        (error.response.data?.email ?? []).includes(DUPLICATE_INVITE_ERROR)
      ) {
        pushNotification({
          message: i18n.alreadyInvited,
          status: NotificationStatus.warning,
        });
        return;
      }
      pushNotification("default_error");
    }
    actions.setSubmitting(false);
  };

  const canAssignRoles = profile.hasPermissions(
    ["company.manage_roles"],
    profile.company
  );

  const firstNamePrefill = new URLSearchParams(location.search).get(
    "first-name"
  );
  const lastNamePrefill = new URLSearchParams(location.search).get("last-name");

  return (
    <Grid className={classes.modalContainer}>
      <Grid className={classes.headerContainer}>
        <CompanyAvatar company={profile.company} classes={avatarClasses} />
        <T h2 bold textAlign="center" className={classes.modalTitle}>
          <FormattedMessage
            {...i18n.title}
            values={{ company: profile.company?.name }}
          />
        </T>
      </Grid>
      <Grid className={classes.formContainer}>
        <Formik
          initialValues={{
            ...initialValues,
            first_name: firstNamePrefill || "",
            last_name: lastNamePrefill || "",
          }}
          onSubmit={handleSubmit}
          validationSchema={
            canAssignRoles ? inviteWithRoleSchema : inviteWithoutRoleSchema
          }
        >
          {({
            values,
            handleSubmit,
            handleBlur,
            handleChange,
            isSubmitting,
            isValid,
            touched,
            errors,
          }) => (
            <form onSubmit={handleSubmit} className={classes.form}>
              <FormGroup row={true} className={classes.formGroupName}>
                <FormControl
                  fullWidth
                  error={Boolean(errors.first_name && touched.first_name)}
                >
                  <TextInput
                    className={classes.input}
                    variant="tertiary"
                    name="first_name"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    placeholder={intl.formatMessage(i18n.firstName)}
                    disabled={isSubmitting}
                    inputProps={{ "data-testid": "first-name-field" }}
                    value={values.first_name}
                    error={Boolean(errors.first_name && touched.first_name)}
                  />
                  <FormHelperText>
                    &nbsp;
                    {errors.first_name && touched.first_name && (
                      <FormattedMessage {...errors.first_name} />
                    )}
                  </FormHelperText>
                </FormControl>
                <FormControl
                  fullWidth
                  error={Boolean(errors.last_name && touched.last_name)}
                >
                  <TextInput
                    className={classes.input}
                    variant="tertiary"
                    name="last_name"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    placeholder={intl.formatMessage(i18n.lastName)}
                    disabled={isSubmitting}
                    inputProps={{ "data-testid": "last-name-field" }}
                    value={values.last_name}
                    error={Boolean(errors.last_name && touched.last_name)}
                  />
                  <FormHelperText>
                    &nbsp;
                    {errors.last_name && touched.last_name && (
                      <FormattedMessage {...errors.last_name} />
                    )}
                  </FormHelperText>
                </FormControl>
              </FormGroup>
              <FormControl
                fullWidth
                error={Boolean(errors.email && touched.email)}
              >
                <TextInput
                  className={classes.input}
                  variant="tertiary"
                  name="email"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  placeholder={intl.formatMessage(i18n.email)}
                  disabled={isSubmitting}
                  inputProps={{ "data-testid": "email-field" }}
                  value={values.email}
                  error={Boolean(errors.email && touched.email)}
                />
                <FormHelperText>
                  &nbsp;
                  {errors.email && touched.email && (
                    <FormattedMessage {...errors.email} />
                  )}
                </FormHelperText>
              </FormControl>
              {canAssignRoles && (
                <FormControl
                  fullWidth
                  error={Boolean(errors.role_name && touched.role_name)}
                >
                  <SelectInput
                    variant="tertiary"
                    name="role_name"
                    value={values.role_name}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    required
                    placeholder={intl.formatMessage(i18n.rolePlaceholder)}
                    className={classes.select}
                    options={displayedRoles.map((role) => ({
                      value: role.name,
                      label: role.label,
                    }))}
                    error={Boolean(errors.role_name && touched.role_name)}
                    inputProps={{ "data-testid": "selectUserRole" }}
                  />
                  <FormHelperText>
                    &nbsp;
                    {errors.role_name && touched.role_name && (
                      <FormattedMessage {...errors.role_name} />
                    )}
                  </FormHelperText>
                </FormControl>
              )}
              <DialogActions>
                <Button
                  label={i18n.cancel}
                  onClick={handleClose}
                  variant="secondary"
                />
                <Button
                  label={i18n.sendInvitation}
                  disabled={isSubmitting || !isValid}
                  type="submit"
                  data-testid="button-invite"
                  LeftIcon={Send}
                />
              </DialogActions>
            </form>
          )}
        </Formik>
      </Grid>
    </Grid>
  );
};

export default InvitationModal;

// CSS

const useStyles = makeStyles()((theme) => ({
  modalContainer: {
    display: "flex",
    alignItems: "flex-start",
    justifyContent: "flex-start",
    flexDirection: "column",
  },
  headerContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
    gap: 12,
    marginBottom: theme.spacing(3),
  },
  modalTitle: {
    [theme.breakpoints.down("md")]: {
      // TODO: Typography -- deal with different font styles in smaller screens.
      // To help find this later (cmd+f hook): oldVariant="h1_fontSize:20px".
      fontSize: 20,
    },
  },
  formContainer: {
    width: "100%",
  },
  form: {
    width: "100%",
  },
  formGroupName: {
    flexWrap: "nowrap",
    [theme.breakpoints.down("sm")]: {
      flexWrap: "wrap",
    },
  },
  input: {
    fontSize: 15,
    margin: theme.spacing(1),
  },
  select: {
    minWidth: 180,
    margin: theme.spacing(2, 1),
  },
}));

const useAvatarStyles = makeStyles()((theme) => ({
  avatar: {
    marginRight: theme.spacing(3),
  },
}));

// I18N

const i18n = defineMessages({
  alreadyInvited: {
    id: "PartnersUserList.invitationModal.alreadyInvited",
    defaultMessage: "This user has already been invited to your workspace.",
  },
  title: {
    id: "PartnersUserList.invitationModal.title",
    defaultMessage: "Invite a teammate to {company}'s workspace.",
  },
  invitationSent: {
    id: "PartnersUserList.invitationModal.invitationSent",
    defaultMessage: "Your invitation has been sent.",
  },
  sendInvitation: {
    id: "PartnersUserList.invitationModal.sendInvitation",
    defaultMessage: "Send invite",
  },
  cancel: {
    id: "PartnersUserList.invitationModal.cancel",
    defaultMessage: "Cancel",
  },
  firstName: {
    id: "PartnersUserList.invitationModal.firstName",
    defaultMessage: "Contact first name",
  },
  lastName: {
    id: "PartnersUserList.invitationModal.lastName",
    defaultMessage: "Contact last name",
  },
  email: {
    id: "PartnersUserList.invitationModal.email",
    defaultMessage: "Contact e-mail",
  },
  role: {
    id: "PartnersUserList.invitationModal.role",
    defaultMessage: "Role",
  },
  rolePlaceholder: {
    id: "InvitationModal.rolePlaceholder",
    defaultMessage: "Pick a role",
  },
  roleRequired: {
    id: "InvitationModal.roleRequired",
    defaultMessage: "You must pick a role",
  },
});

export const _private = {
  i18n,
};
