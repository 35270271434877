import { createSvgIcon } from "@mui/material/utils";

import { SvgIcon } from "./types";

const displayName = "Edit";

const Edit: SvgIcon = createSvgIcon(
  // Using transform to enlarge from provided viewbox="0 0 12 12"
  // to default "0 0 24 24" from `createSvgIcon` -> `SvgIcon`.
  <g fill="none" transform="matrix(2,0,0,2,0,0)">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.71967 2.56067C8.01257 2.26778 8.48744 2.26778 8.78033 2.56067L9.43934 3.21968C9.73224 3.51258 9.73224 3.98745 9.43934 4.28034L8.70264 5.01705C8.70263 5.01704 8.70262 5.01703 8.70261 5.01702L6.98297 3.29738L7.71967 2.56067ZM6.45264 3.82771L2.73551 7.54484C2.63939 7.64096 2.5712 7.76139 2.53823 7.89327L2.01539 9.98462L4.10675 9.46179C4.23862 9.42882 4.35906 9.36063 4.45518 9.26451L8.17231 5.54738C8.1723 5.54737 8.17229 5.54736 8.17228 5.54735L6.45264 3.82771ZM9.31066 2.03034C8.72488 1.44456 7.77513 1.44456 7.18934 2.03034L2.20518 7.01451C2.01294 7.20675 1.87656 7.44762 1.81062 7.71137L1.28779 9.80272C1.15046 10.352 1.64801 10.8496 2.19729 10.7122L4.28865 10.1894C4.5524 10.1235 4.79327 9.98708 4.98551 9.79484L9.96967 4.81067C10.5555 4.22489 10.5555 3.27514 9.96967 2.68935L9.31066 2.03034Z"
      fill="currentColor"
    />
  </g>,
  displayName
);

Edit.displayName = displayName;
export default Edit;
