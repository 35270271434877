import { makeStyles } from "makeStyles";

export const useTableStyles = makeStyles()(() => ({
  root: {},
  table: { borderBottom: "none" },
  tableWrapper: { border: "none", backgroundColor: "transparent" },
}));

export const useTableFiltersStyles = makeStyles()((theme) => ({
  filterContainer: {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "flex-start",
    backgroundColor: "transparent",
    marginBottom: theme.spacing(1),
  },
  filterForm: {
    display: "flex",
    alignItems: "center",
  },
  selectField: {
    marginRight: theme.spacing(2),
    minWidth: theme.spacing(40),
  },
}));

export const useTableHeadStyles = makeStyles()((theme) => ({
  tableRow: {
    "&> th": {
      fontSize: 16,
      borderStyle: "none",
      paddingLeft: theme.spacing(4),
      paddingRight: theme.spacing(4),
      paddingBottom: theme.spacing(2),
      "&:first-child": {
        paddingLeft: 0,
      },
      "&:last-child": {
        paddingRight: 0,
      },
    },
  },
}));

export const useRowStyles = makeStyles()((theme) => ({
  root: {
    "&> td": {
      paddingTop: theme.spacing(2),
      paddingLeft: theme.spacing(4),
      paddingRight: theme.spacing(4),
      fontSize: 14,
      borderBottomStyle: "solid",
      borderBottomWidth: 1,
      borderBottomColor: theme.palette.taupe,
      maxWidth: 300,
      [theme.breakpoints.down("lg")]: {
        paddingLeft: theme.spacing(3),
        paddingRight: theme.spacing(3),
        fontSize: 12,
      },
      [theme.breakpoints.down("md")]: {
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
      },
      [theme.breakpoints.down("sm")]: {
        paddingLeft: theme.spacing(1),
        paddingRight: theme.spacing(1),
      },
      "&:first-child": {
        paddingLeft: 0,
      },
      "&:last-child": {
        paddingRight: 0,
        borderBottomStyle: "solid",
        borderBottomWidth: 1,
        borderBottomColor: theme.palette.taupe,
      },
    },
  },
  arrowCell: {
    paddingLeft: "0px !important",
    paddingRight: "0px !important",
  },
  icon: {
    color: theme.palette.primary.main,
  },
}));
