import { isFulfilled } from "@reduxjs/toolkit";
import useUserProfile from "hooks/useUserProfile";
import PartnershipAnalyticsQuickFiltersMetrics from "models/PartnershipAnalyticsQuickFiltersMetrics";
import { JSONAPIResourceOrRecord } from "models/types";
import { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  selectAnalyticsView,
  selectHasAdvancedAnalytics,
  selectNotQuickFilterFilters,
  selectQuickFilterType,
} from "redux/analytics/selectors";
import { updateView } from "redux/analytics/thunks";
import {
  AnalyticsQuickFilterFieldname,
  AnalyticsQuickFilterType,
} from "redux/analytics/types";
import { rawGet } from "redux/api/thunks";
import {
  MatchFilter,
  MatchFilterType,
} from "screens/Frontoffice/screens/DataTables/shared/types";
import { FilterValue, JSONAPIResponse } from "services/types";

import { formatFilters } from "../../../shared/utils";

const useAnalyticsToggleFilterAPILogic = () => {
  const dispatch = useDispatch();
  const { profile } = useUserProfile();
  const { view } = useSelector(selectAnalyticsView);
  const hasAdvancedAnalytics = useSelector(selectHasAdvancedAnalytics);
  const notQuickFilterFilters = useSelector(selectNotQuickFilterFilters);
  const quickFilterType = useSelector(selectQuickFilterType);
  const { filters } = view ?? {};
  const updatedFilters = formatFilters(filters, hasAdvancedAnalytics, true);
  const formattedFilters = MatchFilter.forAPI(updatedFilters) as {
    [name: string]: FilterValue;
  };

  const [
    quickFiltersMetrics,
    setQuickFiltersMetrics,
  ] = useState<PartnershipAnalyticsQuickFiltersMetrics | null>(null);

  const getData = async () => {
    const result = await dispatch(
      rawGet({
        type: "partner_analytics",
        path: "quick-filters-metrics/",
        options: { filters: formattedFilters },
      })
    );

    if (isFulfilled(result)) {
      const response = result.payload as JSONAPIResponse;
      const record = new PartnershipAnalyticsQuickFiltersMetrics(
        response.data as JSONAPIResourceOrRecord<"partnership_analytics_quick_filters_metrics">
      );
      setQuickFiltersMetrics(record);
    }
  };

  const setFilter = useCallback(
    (value: AnalyticsQuickFilterType) => {
      const filters = [...notQuickFilterFilters];
      if (value === AnalyticsQuickFilterType.Favorites) {
        filters.push({
          fieldname: AnalyticsQuickFilterFieldname.Favorites,
          type: MatchFilterType.CONTAINS_ANY,
          value: profile.id,
        });
      } else if (value === AnalyticsQuickFilterType.HighWinRatePartners) {
        filters.push({
          fieldname: AnalyticsQuickFilterFieldname.HighWinRatePartners,
          type: MatchFilterType._NO_OPERATOR,
          value: true,
        });
      } else if (value === AnalyticsQuickFilterType.MyPartners) {
        filters.push({
          fieldname: AnalyticsQuickFilterFieldname.MyPartners,
          type: MatchFilterType.ANY_OF,
          value: profile.id,
        });
      }
      dispatch(updateView({ filters }));
    },
    [dispatch, notQuickFilterFilters, profile.id]
  );

  useEffect(() => {
    getData();
  }, [filters]); // eslint-disable-line react-hooks/exhaustive-deps

  return { quickFiltersMetrics, quickFilterType, setFilter };
};

export default useAnalyticsToggleFilterAPILogic;
