import { isFulfilled, isRejected } from "@reduxjs/toolkit";
import Record from "models/Record";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { retreive } from "redux/api/thunks";
import { APICollection } from "redux/api/typing";
import { RevealStore } from "redux/typing";
import { JSONAPIOptions } from "services/types";

const useRecord = <T extends string>(
  type: T,
  id: number | null,
  loadFromAPI: boolean = true,
  options?: JSONAPIOptions
): { record: Record<T> | null; error: boolean; reload: () => void } => {
  const dispatch = useDispatch();
  const [error, setError] = useState(false);
  const [version, setVersion] = useState(1);

  const reload = useCallback(() => setVersion((v) => v + 1), []);

  const selector = useMemo(
    () => (state: RevealStore) => {
      if (id !== null) {
        const collection = (state.api.entities[type] ?? {}) as APICollection<
          Record<T>
        >;
        if (collection[id]) {
          return collection[id];
        }
      }
      return null;
    },
    [type, id]
  );

  const record = useSelector(selector);

  useEffect(() => {
    const effect = async (recordId: number) => {
      try {
        const result = await dispatch(
          retreive({ id: recordId, type, options })
        );
        if (isFulfilled(result)) {
          setError(false);
        } else if (isRejected(result)) {
          throw result.error;
        }
      } catch {
        setError(true);
      }
    };

    if (id === null) {
      setError(false);
    } else if (loadFromAPI) {
      effect(id);
    }
  }, [dispatch, type, id, JSON.stringify(options), version, loadFromAPI]); // eslint-disable-line react-hooks/exhaustive-deps

  return useMemo(
    () => ({
      record,
      error,
      reload,
    }),
    [record, error, reload]
  );
};

export default useRecord;
