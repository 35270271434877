import Checkbox from "@mui/material/Checkbox";
import Tooltip from "@mui/material/Tooltip";
import SettingsSection from "components/ui/SettingsSection";
import Toggle from "components/ui/Toggle";
import { T } from "components/ui/Typography";
import { makeStyles } from "makeStyles";
import Partnership, { DataShare } from "models/Partnership";
import {
  defineMessages,
  FormattedHTMLMessage,
  FormattedMessage,
} from "react-intl";

type DataSharingTogglesBlockProps = {
  dataSharingStatus: DataShare;
  hasDataSource: boolean;
  hasDataSourceLinkedToPartnership: boolean;
  canManageDataSources: boolean;
  onToggleOverlappingAccounts: $TSFixMeFunction;
  onToggleWhitespace: $TSFixMeFunction;
  onToggleCommonCustomers: $TSFixMeFunction;
  partner: {
    name?: string;
  };
  disabled: boolean;
};

const DataSharingTogglesBlock = ({
  dataSharingStatus,
  hasDataSource,
  hasDataSourceLinkedToPartnership,
  canManageDataSources,
  onToggleOverlappingAccounts,
  onToggleWhitespace,
  onToggleCommonCustomers,
  partner,
  disabled,
}: DataSharingTogglesBlockProps) => {
  const { classes, cx } = useStyles();
  const { classes: checkboxClasses } = useCheckboxStyles();
  const { classes: tooltipClasses } = useTooltipStyles();

  const isDataSharingEnabled =
    hasDataSourceLinkedToPartnership &&
    Partnership.isSharingOverlapping(dataSharingStatus);

  const isSharingWhitespace =
    hasDataSourceLinkedToPartnership &&
    Partnership.isSharingWhitespace(dataSharingStatus);

  const TooltipWhenDisabled = ({ children }: { children: JSX.Element }) => {
    let title =
      !hasDataSource && canManageDataSources ? (
        <T oldVariant="subtitle2">
          <FormattedMessage {...i18n.connectSource} />
        </T>
      ) : !hasDataSource && canManageDataSources ? (
        <T oldVariant="subtitle2">
          <FormattedMessage {...i18n.adminConnectSource} />
        </T>
      ) : !hasDataSourceLinkedToPartnership ? (
        <T oldVariant="subtitle2">
          <FormattedMessage {...i18n.linkSourceToPartnership} />
        </T>
      ) : (
        ""
      );

    return (
      <Tooltip
        title={title}
        placement="top"
        arrow={true}
        leaveDelay={300}
        classes={tooltipClasses}
      >
        <div>{children}</div>
      </Tooltip>
    );
  };

  return (
    <SettingsSection
      title={<FormattedMessage {...i18n.dataSharing} />}
      variant="outlined"
    >
      <div className={classes.toggleRow}>
        <div className={classes.toggleLabelGroup}>
          <label className={classes.label}>
            <TooltipWhenDisabled>
              <Toggle
                checked={isDataSharingEnabled}
                handleChange={onToggleOverlappingAccounts}
                disabled={disabled || !hasDataSourceLinkedToPartnership}
                size="big"
              />
            </TooltipWhenDisabled>
            <T h4 bold className={classes.switchDescription}>
              <FormattedHTMLMessage {...i18n.shareOverlappingAccounts} />
            </T>
          </label>
          <T>
            <FormattedHTMLMessage
              {...i18n.shareOverlappingSummary}
              values={{ partner: partner.name }}
            />
          </T>
        </div>
        <label className={cx(classes.label, classes.checkboxLabel)}>
          <Checkbox
            checked={
              hasDataSourceLinkedToPartnership &&
              Partnership.isSharingCommonCustomers(dataSharingStatus)
            }
            classes={checkboxClasses}
            onChange={onToggleCommonCustomers}
            disabled={!isDataSharingEnabled}
          />
          <T className={classes.checkboxDescription}>
            <FormattedHTMLMessage {...i18n.commonCustomersCheckbox} />
          </T>
        </label>
      </div>
      <div className={classes.toggleLabelGroup}>
        <label className={classes.label}>
          <Toggle
            checked={isSharingWhitespace}
            handleChange={onToggleWhitespace}
            disabled={disabled || !hasDataSourceLinkedToPartnership}
            size="big"
          />
          <T h4 bold className={classes.switchDescription}>
            <FormattedHTMLMessage {...i18n.shareWhitespace} />
          </T>
        </label>
        <T>
          <FormattedHTMLMessage
            {...i18n.shareWhitespaceSummary}
            values={{ partner: partner.name }}
          />
        </T>
      </div>
    </SettingsSection>
  );
};

export default DataSharingTogglesBlock;

// I18N

const i18n = defineMessages({
  dataSharing: {
    id:
      "crm.PartnershipSettings.GeneralTab.DataSharingTogglesBlock.dataSharing",
    defaultMessage: "Data Sharing",
  },
  shareOverlappingAccounts: {
    id:
      "crm.PartnershipSettings.GeneralTab.DataSharingTogglesBlock.shareOverlappingAccounts",
    defaultMessage: "Share Account Overlaps",
  },
  shareOverlappingSummary: {
    id:
      "crm.PartnershipSettings.GeneralTab.DataSharingTogglesBlock.shareOverlappingSummary",
    defaultMessage: "Accounts that exist in both {partner}'s CRM and your CRM.",
  },
  shareWhitespace: {
    id:
      "crm.PartnershipSettings.GeneralTab.DataSharingTogglesBlock.shareWhitespace",
    defaultMessage: "Share New Prospects",
  },
  shareWhitespaceSummary: {
    id:
      "crm.PartnershipSettings.GeneralTab.DataSharingTogglesBlock.shareWhitespaceSummary",
    defaultMessage: "Customers that do not exist in {partner}'s CRM yet.",
  },
  commonCustomersCheckbox: {
    id:
      "crm.PartnershipSettings.GeneralTab.DataSharingTogglesBlock.commonCustomersCheckbox",
    defaultMessage: "Only common customers",
  },
  connectSource: {
    id:
      "crm.PartnershipSettings.GeneralTab.DataSharingTogglesBlock.connectSource",
    defaultMessage: "You need to connect a data source",
  },
  adminConnectSource: {
    id:
      "crm.PartnershipSettings.GeneralTab.DataSharingTogglesBlock.adminConnectSource",
    defaultMessage: "An admin needs to connect a data source",
  },
  linkSourceToPartnership: {
    id:
      "crm.PartnershipSettings.GeneralTab.DataSharingTogglesBlock.linkSourceToPartnership",
    defaultMessage: "Assign a data source to this partnership first",
  },
  configFilters: {
    id:
      "crm.PartnershipSettings.GeneralTab.DataSharingTogglesBlock.configFilters",
    defaultMessage: "Configure filters",
  },
});

// CSS

const useStyles = makeStyles()((theme) => ({
  toggleLabelGroup: {
    display: "flex",
    flexDirection: "column",
    gap: theme.spacing(1),
  },
  header: {
    paddingBottom: theme.spacing(6),
    paddingRight: theme.spacing(8),
  },
  title: {
    marginBottom: theme.spacing(2),
  },
  toggleRow: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    paddingBottom: theme.spacing(4),
    marginBottom: theme.spacing(3),
    borderBottom: `1px solid ${theme.palette.taupe500}`,
    "&:last-of-type": {
      borderBottom: "none",
      marginBottom: 0,
      paddingBottom: 0,
    },
  },
  label: {
    marginBottom: 0,
    display: "flex",
    alignItems: "center",
    [theme.breakpoints.down("md")]: {
      marginBottom: theme.spacing(2),
    },
  },
  switchDescription: {
    marginLeft: theme.spacing(2),
  },
  checkboxLabel: {
    marginLeft: theme.spacing(6),
  },
  checkboxDescription: {
    marginLeft: theme.spacing(2),
  },
}));

const useCheckboxStyles = makeStyles()((theme) => ({
  root: {
    padding: 0,
    width: 18,
    height: 18,
    "&:hover": {
      color: theme.palette.primary.main,
    },
  },
  checked: {
    "&&": {
      color: theme.palette.primary.main,
    },
    backgroundColor: theme.palette.white,
    "&:hover": {
      backgroundColor: theme.palette.white,
    },
  },
}));

const useTooltipStyles = makeStyles()((theme) => ({
  tooltip: {
    backgroundColor: theme.palette.midnight,
    padding: theme.spacing(1),
  },
}));
