import Alert from "components/ui/Alert";
import { T } from "components/ui/Typography";
import { useEffect, useState } from "react";
import { RouteComponentProps } from "react-router";
import UserService from "services/UserService";

const startImpersonating = async (userId: number) => {
  const service = new UserService();
  if (service.isImpersonating) {
    await service.depersonate();
  }
  await service.impersonate(userId);
  window.location.replace("/");
};

type Params = {
  userId: string;
};

function getUserId(params: Params) {
  const userId = params.userId;
  if (isNaN(+userId)) {
    throw new Error("User ID must be a number");
  }
  return +userId;
}

const SudoInitiator = ({ match }: RouteComponentProps<Params>) => {
  const [status, setStatus] = useState("Connecting...");
  const userId = getUserId(match.params);
  useEffect(() => {
    startImpersonating(userId).catch(() => setStatus("Error!"));
  }, [userId]);
  return (
    <Alert>
      <T titleSmall>{status}</T>
    </Alert>
  );
};

export default SudoInitiator;
