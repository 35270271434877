import { Refresh as RefreshIcon } from "components/icons";
import { T } from "components/ui/Typography/Typography";
import useAmountFormatter from "hooks/useAmountFormatter";
import { makeStyles } from "makeStyles";
import { defineMessages, useIntl } from "react-intl";
import { fieldFormatter } from "screens/Frontoffice/screens/DataTables/shared/fieldFormatter";

import { RawOpportunityField } from "./types";

type StyleProps = {
  numberOfFields: number;
  bold?: boolean;
  maxWidth?: number;
};

export const FieldDisplay = ({
  numberOfFields,
  bold,
  maxWidth,
  ...field
}: RawOpportunityField & StyleProps) => {
  const { classes } = useFieldStyles({ maxWidth });
  const intl = useIntl();
  const amountFormatter = useAmountFormatter(intl);

  let tooltipTitle, content, maybeIcon;

  if (field.fullyImported) {
    const formatted_field_value = fieldFormatter(
      field.value,
      field,
      intl,
      amountFormatter,
      true
    );
    tooltipTitle = `${field.label}: ${formatted_field_value}`;
    content = formatted_field_value;
  } else {
    tooltipTitle = intl.formatMessage(i18n.fieldBeingSynchronized, {
      fieldName: field.label,
    });
    content = field.label;
    maybeIcon = <RefreshIcon style={{ width: 12, height: 12 }} />;
  }

  return (
    <div className={classes.contentWrapper} title={tooltipTitle}>
      {maybeIcon}
      <T bold={bold} className={classes.content}>
        {content}
      </T>
    </div>
  );
};

// CSS

const useFieldStyles = makeStyles<{
  maxWidth?: number;
}>()((theme, { maxWidth }) => {
  return {
    contentWrapper: {
      display: "flex",
      alignItems: "center",
      maxWidth: maxWidth ?? "unset",
      "&:last-of-type": {
        // Necessary to shrink below the container size.
        minWidth: 0,
      },
      "& > svg": {
        marginRight: 5,
      },
    },
    content: {
      overflow: "hidden",
      textOverflow: "ellipsis",
      whiteSpace: "nowrap",
      cursor: "default",
    },
  };
});

// I18N

const i18n = defineMessages({
  fieldBeingSynchronized: {
    id:
      "components.ui.RawOpportunityDisplay.FieldDisplay.fieldBeingSynchronized",
    defaultMessage: `Field "{fieldName}" is still being synchronized.`,
  },
});
