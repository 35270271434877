import { TextInput } from "components/ui/TextInput";
import generic from "i18n/generic";
import { ChangeEvent, useCallback } from "react";
import { useIntl } from "react-intl";

type Props = {
  onSubmit: (otp: string) => void;
};

const OTPForm = ({ onSubmit }: Props) => {
  const intl = useIntl();

  const handleChange = useCallback(
    async (event: ChangeEvent<HTMLInputElement>) => {
      const value = event.target.value;
      if (value.match(/^\d{6}$/)) {
        onSubmit(value);
      }
    },
    [onSubmit]
  );

  return (
    <TextInput
      autocomplete="off"
      variant="tertiary"
      fullWidth
      name="password"
      onChange={handleChange}
      placeholder={intl.formatMessage(generic.otp)}
    />
  );
};

export default OTPForm;
