import Record from "models/Record";

import { JSONAPIResourceOrRecord } from "./types";

// Account Owner Enum

export enum ShareOwner {
  Full = 1,
  NameOnly = 2,
  Nothing = 3,
}

// Open Deal Enum

export enum ShareOpenDeal {
  Full = 1,
  NoAmount = 2,
  Nothing = 3,
}

function isValidShareOwner(value: any): value is ShareOwner {
  return Object.values(ShareOwner).includes(value);
}

export default class AccountMappingSharingSettings extends Record {
  shareOwner: ShareOwner;
  shareOpports: boolean;
  shareName: boolean;
  shareStatus: boolean;
  shareCountry: boolean;
  shareDomain: boolean;
  shareNumberOfContacts: boolean;
  company: Record | null = null;

  constructor(
    data: JSONAPIResourceOrRecord<"account_mapping_sharing_settings">
  ) {
    super(data);
    this.shareOpports = Boolean(this.attributes.share_opports);
    this.shareOwner = isValidShareOwner(this.attributes.share_owner)
      ? this.attributes.share_owner
      : ShareOwner.Full;
    this.shareName = Boolean(this.attributes.share_name);
    this.shareStatus = Boolean(this.attributes.share_status);
    this.shareCountry = Boolean(this.attributes.share_country);
    this.shareNumberOfContacts = Boolean(
      this.attributes.share_number_of_contacts
    );
    this.shareDomain = Boolean(this.attributes.share_website);
    if (this.relationships.company?.data instanceof Record) {
      this.company = this.relationships.company.data;
    }
  }
}
