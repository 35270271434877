import { makeStyles } from "makeStyles";
import { ReactNode } from "react";
// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module 'reac... Remove this comment to see the full error message
import { DragDropContext, Droppable } from "react-beautiful-dnd";

const useStyles = makeStyles()((theme) => ({
  root: {},
  draggingContainer: {
    // @ts-expect-error ts-migrate(2339) FIXME: Property 'lightBlue' does not exist on type 'Color... Remove this comment to see the full error message
    backgroundColor: theme.palette.grey.lightBlue,
    borderRadius: 3,
  },
}));

type Props = {
  children?: ReactNode;
  handleDragEnd: $TSFixMeFunction;
};

const DraggableContainer = ({ children, handleDragEnd }: Props) => {
  const { classes, cx } = useStyles();

  const onDragEnd = (result: $TSFixMe) => {
    if (!result.destination) {
      return;
    }
    handleDragEnd(result.source.index, result.destination.index);
  };

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <Droppable droppableId="droppable">
        {(provided: $TSFixMe, snapshot: $TSFixMe) => (
          <div
            {...provided.droppableProps}
            ref={provided.innerRef}
            className={cx({
              [classes.draggingContainer]: snapshot.isDraggingOver,
            })}
          >
            {children}
            {provided.placeholder}
          </div>
        )}
      </Droppable>
    </DragDropContext>
  );
};

export default DraggableContainer;
