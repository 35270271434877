import {
  Notification as NotificationIcon,
  NotificationNew,
} from "components/icons";
import Button from "components/ui/Button";
import DropdownPopover from "components/ui/DropdownPopover";
import { T } from "components/ui/Typography";
import useAllRecords from "hooks/useAllRecords";
import useUserProfile from "hooks/useUserProfile";
import { makeStyles } from "makeStyles";
import Notification from "models/Notification";
import Partnership from "models/Partnership";
import { parse } from "query-string";
import { useEffect, useState } from "react";
import { Scrollbars } from "react-custom-scrollbars";
import { defineMessages, FormattedMessage } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import { selectAllPartnerships } from "redux/api/selectors";
import { rawPost } from "redux/api/thunks";
import { selectPartnershipsReady } from "redux/init/selectors";
import {
  setSelectedPartner,
  setSelectedPartnershipId,
} from "redux/notificationWidget/actions";

import NotificationItem from "./NotificationItem";

export const NotificationWidget = () => {
  const dispatch = useDispatch();
  const { classes } = useStyles();
  const { classes: btnClasses } = useBtnStyles();
  const partnershipsReady = useSelector(selectPartnershipsReady);
  const { records: notifications, refresh } = useAllRecords("notifications");
  let hasNew = false;
  const [anchorEl, setAnchorEl] = useState<
    HTMLDivElement | HTMLButtonElement
  >();
  const partnerships = useSelector(selectAllPartnerships) as Partnership[];
  const { search: searchRaw, pathname } = useLocation();
  const history = useHistory();
  const { profile } = useUserProfile();

  useEffect(() => {
    const search = parse(searchRaw);
    if (!search?.["pending-referral"]) {
      return;
    }
    const partnershipId = search["pending-referral"] as string;
    const partnership = partnerships.find(
      (partnership) => partnership.id === Number(partnershipId)
    );
    if (!partnership) {
      return;
    }
    const notification = notifications.find(
      (notification) => notification.partnership.id === partnership.id
    );
    if (!notification) {
      return;
    }
    const partner = partnership.getPartner(profile);
    dispatch(setSelectedPartner(partner));
    dispatch(setSelectedPartnershipId(Number(partnershipId)));
    history.replace(pathname);
  }, [
    partnerships,
    dispatch,
    history,
    pathname,
    profile,
    searchRaw,
    notifications,
  ]);

  if (notifications.length === 0 || !partnershipsReady) {
    return null;
  } else {
    notifications.forEach((notification) => {
      if (!notification.readByUser) {
        hasNew = true;
      }
    });
  }

  const handleOpen = (
    event: React.MouseEvent<HTMLDivElement | HTMLButtonElement>
  ) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(undefined);
  };

  const markAllAsRead = async () => {
    // Adding the id of the notification that is at the top of the dropdown
    // so that we don't risk to mark a notification as read, that is not yet displayed
    await dispatch(
      rawPost({
        type: "notifications",
        path: "/mark-all-as-read/",
        payload: {
          data: {
            filters: {
              updated_at__lte:
                notifications[0].updatedAt || notifications[0].createdAt,
            },
          },
        },
      })
    );
    refresh();
  };

  return (
    <>
      <Button
        onClick={handleOpen}
        variant="tertiary"
        LeftIcon={hasNew ? NotificationNew : NotificationIcon}
        classes={btnClasses}
        data-testid="notification-widget"
        size="small"
      />
      <DropdownPopover
        anchorEl={anchorEl}
        onClose={handleClose}
        open={Boolean(anchorEl)}
        position="right"
        classes={{ paper: classes.dropdownContent }}
      >
        <div className={classes.header}>
          <T titleBig>
            <FormattedMessage {...i18n.notifications} />
          </T>
          <T className={classes.actionLink}>
            <span onClick={markAllAsRead}>
              <FormattedMessage {...i18n.markAsRead} />
            </span>
          </T>
        </div>
        <div>
          <Scrollbars autoHeight autoHeightMax={322} autoHeightMin={0}>
            {notifications.map((notification) => (
              <NotificationItem
                key={notification.id}
                notification={notification as Notification}
                onClose={handleClose}
              />
            ))}
          </Scrollbars>
        </div>
      </DropdownPopover>
    </>
  );
};

export default NotificationWidget;

/// CSS

const useStyles = makeStyles()((theme, props) => ({
  dropdownContent: {
    width: 420,
  },
  header: {
    display: "flex",
    justifyContent: "space-between",
    padding: 16,
    paddingBottom: 12,
  },
  actionLink: {
    cursor: "pointer",
    "&:hover": {
      textDecoration: "underline",
    },
  },
}));

export const useBtnStyles = makeStyles()(() => ({
  icon: {
    fontSize: 16,
  },
}));

// I18N

const i18n = defineMessages({
  notifications: {
    id: "Navbar.NotificationWidget.notifications",
    defaultMessage: "Notifications",
  },
  markAsRead: {
    id: "Navbar.NotificationWidget.markAsRead",
    defaultMessage: "Mark all as read",
  },
});
