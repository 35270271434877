import { Hidden } from "@mui/material";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import { Plus } from "components/icons";
import Button from "components/ui/Button";
import GlobalSearch from "components/ui/navbar/GlobalSearch";
import { ConfigContext } from "config/ConfigProvider";
import useUserProfile from "hooks/useUserProfile";
import { makeStyles } from "makeStyles";
import { useContext } from "react";
import { useSelector } from "react-redux";
import { matchPath, useHistory, useLocation } from "react-router";
import { selectActivePayingFeatures } from "redux/api/selectors";
import { selectLoggedIn } from "redux/user/selectors";
import { RequestIntro } from "screens/Frontoffice/screens/DataTables/shared/components/RequestIntro";
import Navigation from "screens/Frontoffice/shared/components/Navigation";

import Banner from "./Banner";
import MobileNavbar from "./MobileNavbar";
import NotificationWidget from "./NotificationWidget";
import PremiumPlanWidget from "./PremiumPlanWidget";

type StyleProps = {
  hasBanner: boolean;
};

const NavbarBase = () => {
  const history = useHistory();
  const location = useLocation();
  const { profile } = useUserProfile();
  const { appName } = useContext(ConfigContext);
  const frontOffice = appName === "partners";
  const payingFeatures = useSelector(selectActivePayingFeatures);
  const { loggedIn } = useSelector(selectLoggedIn);

  const isUserBannerVisible =
    profile.isUserLimitReached() && location.pathname === "/settings/team";
  const { classes, cx } = useStyles({ hasBanner: isUserBannerVisible });

  const isFromAccountMapping = !!matchPath(
    location.pathname,
    "/partnerships/:recordId/account-mapping"
  );
  const isFromPipeline = !!matchPath(location.pathname, "/pipeline");
  const isFromSettings = !!matchPath(
    location.pathname,
    "/partnerships/:recordId/settings"
  );

  return (
    <>
      <Hidden mdDown>
        <AppBar position="sticky" classes={{ root: classes.bg }}>
          {frontOffice && isUserBannerVisible && <Banner />}
          <Toolbar className={classes.root}>
            <div className={classes.container}>
              <div className={classes.item}>
                {(isFromAccountMapping || isFromPipeline || isFromSettings) && (
                  <Navigation />
                )}
              </div>
              {frontOffice && (
                <div className={classes.item}>
                  <GlobalSearch />
                </div>
              )}
              <div className={cx(classes.item, classes.right)}>
                {frontOffice && loggedIn && <RequestIntro />}
                {frontOffice && profile.canInvitePartner(payingFeatures) && (
                  <Button
                    label={"Invite new partner"}
                    LeftIcon={Plus}
                    onClick={() => history.push("/invite")}
                    size="small"
                  />
                )}
                {frontOffice && (
                  <>
                    <PremiumPlanWidget collapsed={isFromAccountMapping} />
                    {profile.ownsPipeline && <NotificationWidget />}
                  </>
                )}
              </div>
            </div>
          </Toolbar>
        </AppBar>
      </Hidden>
      <Hidden mdUp>
        <MobileNavbar />
      </Hidden>
    </>
  );
};

export const Navbar = NavbarBase;

/// CSS

const useStyles = makeStyles<StyleProps>()((theme, { hasBanner }) => ({
  bg: {
    height: hasBanner ? 95 : "auto",
    backgroundColor: theme.palette.offWhite,
  },
  root: {
    padding: theme.spacing(1.5),
    top: hasBanner ? 44 : 0,
    boxShadow: `0px -1px 0px 0px ${theme.palette.greyLight100} inset`,
  },
  mobile: {
    background: theme.palette.midnight,
  },
  container: {
    width: "100%",
    display: "flex",
  },
  item: {
    flex: 1,
  },
  right: {
    display: "flex",
    gap: 4,
    justifyContent: "flex-end",
  },
}));
