import { createSvgIcon } from "@mui/material/utils";

import { SvgIcon } from "./types";

const displayName = "Pause";

const Pause: SvgIcon = createSvgIcon(
  <g fill="none" transform="matrix(2,0,0,2,1,2)">
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M2.5 0C1.39543 0 0.5 0.895431 0.5 2V8C0.5 9.10457 1.39543 10 2.5 10H3C4.10457 10 5 9.10457 5 8V2C5 0.895431 4.10457 0 3 0H2.5ZM1.5 2C1.5 1.44772 1.94772 1 2.5 1H3C3.55228 1 4 1.44772 4 2V8C4 8.55228 3.55228 9 3 9H2.5C1.94772 9 1.5 8.55228 1.5 8V2ZM9 0C7.89543 0 7 0.895431 7 2V8C7 9.10457 7.89543 10 9 10H9.5C10.6046 10 11.5 9.10457 11.5 8V2C11.5 0.895431 10.6046 0 9.5 0H9ZM8 2C8 1.44772 8.44772 1 9 1H9.5C10.0523 1 10.5 1.44772 10.5 2V8C10.5 8.55228 10.0523 9 9.5 9H9C8.44772 9 8 8.55228 8 8V2Z"
      fill="#0A151B"
    />
  </g>,
  displayName
);

Pause.displayName = displayName;
export default Pause;
