import { Box, Grid } from "@mui/material";
import Button from "components/ui/Button";
import UpdatePlanDialog from "components/ui/navbar/PremiumPlanWidget/components/UpdatePlanDialog";
import { T } from "components/ui/Typography";
import useSegment from "hooks/useSegment";
import { makeStyles } from "makeStyles";
import { PageType } from "models/PageView";
import { useContext, useState } from "react";
import {
  defineMessages,
  FormattedHTMLMessage,
  FormattedMessage,
} from "react-intl";
import { PremiumPlanEvent } from "tracking";

import MappingFilterContext from "../../../shared/contexts/MappingFilterContext";
import { GoalBased360Event, goalBased360EventName } from "../utils";

export const DemoContent = () => {
  const { classes } = useStyles();
  const { track } = useSegment();
  const [dialogOpen, setDialogOpen] = useState(false);
  const { defaultView } = useContext(MappingFilterContext);

  const handleOpenDialog = () => {
    track(PremiumPlanEvent.goalBased360);
    setDialogOpen(true);
  };

  const handleTrack = () => {
    const eventName = defaultView
      ? goalBased360EventName(
          GoalBased360Event.GoalInfoButtonClicked,
          defaultView.name
        )
      : GoalBased360Event.GoalInfoButtonClicked;
    track(eventName);
  };

  const { title, subtitle, imgSource } = getDemoTexts(defaultView?.pageType);

  return (
    <Box className={classes.teaserContent}>
      <Grid container gap={4} justifyContent="space-between">
        <Grid item className={classes.titleContainer}>
          <T h2 bold className={classes.title}>
            {title && <FormattedMessage {...title} />}
          </T>
          <T className={classes.subtitle}>
            {subtitle && <FormattedHTMLMessage {...subtitle} />}
          </T>
          <Box display="flex" gap="6px">
            <Button label={i18n.learnMore} onClick={handleTrack} />
            <Button
              label={i18n.unlockGoal}
              onClick={handleOpenDialog}
              variant="tertiary"
            />
          </Box>
        </Grid>
        <Grid item>
          <img className={classes.teaserImage} src={imgSource} alt="" />
        </Grid>
      </Grid>
      <UpdatePlanDialog
        isOpen={dialogOpen}
        onClose={() => setDialogOpen(false)}
      />
    </Box>
  );
};

const getDemoTexts = (pageType?: PageType) => {
  switch (pageType) {
    case PageType.mapping360Expand:
      return {
        imgSource: "/images/expand-new-markets.png",
        subtitle: i18n.expandToNewMarketsDemoSubtitle,
        title: i18n.expandToNewMarketsDemoTitle,
      };
    case PageType.mapping360Overlaps:
      return {
        imgSource: "/images/overlaps.png",
        subtitle: i18n.overlapsDemoSubtitle,
        title: i18n.overlapsDemoTitle,
      };
    case PageType.mapping360Sourcing:
      return {
        imgSource: "/images/source-opportunities.png",
        subtitle: i18n.sourceOpportunitiesDemoSubtitle,
        title: i18n.sourceOpportunitiesDemoTitle,
      };
    case PageType.mapping360CoSell:
      return {
        imgSource: "/images/co-sell.png",
        subtitle: i18n.coSellDemoSubtitle,
        title: i18n.coSellDemoTitle,
      };
    case PageType.mapping360Upsell:
      return {
        imgSource: "/images/upsell.png",
        subtitle: i18n.upsellDemoSubtitle,
        title: i18n.upsellDemoTitle,
      };
    default:
      return {};
  }
};

const useStyles = makeStyles()((theme) => ({
  subtitle: {
    color: theme.palette.alpha750,
    fontSize: 14,
    fontWeight: 400,
    lineHeight: "150%",
    marginBottom: theme.spacing(3),
  },
  teaserContent: {
    background: theme.palette.ivory,
    borderRadius: 8,
    margin: theme.spacing(4, 3, 3, 3),
    padding: theme.spacing(4),
  },
  teaserImage: {
    height: 320,
  },
  title: {
    color: theme.palette.midnight,
    marginBottom: 10,
  },
  titleContainer: {
    maxWidth: 400,
    padding: theme.spacing(4),
  },
}));

const i18n = defineMessages({
  coSellDemoSubtitle: {
    id: "crm.Ecosystem.DemoContent.coSellDemoSubtitle",
    defaultMessage:
      "Which of your partners have common open opportunities with you? Find out where you can <b>co-sell</b> and win together.",
  },
  coSellDemoTitle: {
    id: "crm.Ecosystem.DemoContent.coSellDemoTitle",
    defaultMessage: "Win more opportunities, together.",
  },
  expandToNewMarketsDemoSubtitle: {
    id: "crm.Ecosystem.DemoContent.expandToNewMarketsDemoSubtitle",
    defaultMessage:
      "Get an enriched list of all the customers of your Partners that <b>aren't in your CRM yet</b>, prioritized from highest to lowest conversion potential.<br/><br/>Whether you want to benefit from your Partners' presence in new markets you're targeting, or just <b>boost your lead gen</b>: this is a gold mine.",
  },
  expandToNewMarketsDemoTitle: {
    id: "crm.Ecosystem.DemoContent.expandToNewMarketsDemoTitle",
    defaultMessage: "Leverage your partnership data for your Lead Gen.",
  },
  learnMore: {
    id: "crm.Ecosystem.DemoContent.learnMore",
    defaultMessage: "Learn More",
  },
  overlapsDemoSubtitle: {
    id: "crm.Ecosystem.DemoContent.overlapsDemoSubtitle",
    defaultMessage:
      "View your Partner Presence across all your prospects, open opportunities and customers, and identify all the places <b>where your partners can impact your revenue</b>.",
  },
  overlapsDemoTitle: {
    id: "crm.Ecosystem.DemoContent.overlapsDemoTitle",
    defaultMessage: "View your Partner Presence across all your accounts.",
  },
  sourceOpportunitiesDemoSubtitle: {
    id: "crm.Ecosystem.DemoContent.sourceOpportunitiesDemoSubtitle",
    defaultMessage:
      "Based on your history, Reveal identifies your prospects with the highest conversion potential, and which Partners can help you <b>open new opportunities</b> on these accounts.<br/><br/>Start impacting your <b>Pipeline metrics</b>.",
  },
  sourceOpportunitiesDemoTitle: {
    id: "crm.Ecosystem.DemoContent.sourceOpportunitiesDemoTitle",
    defaultMessage: "Source up to x2 more opportunities.",
  },
  unlockGoal: {
    id: "crm.Ecosystem.DemoContent.unlockGoal",
    defaultMessage: "Request Access",
  },
  upsellDemoSubtitle: {
    id: "crm.Ecosystem.DemoContent.upsellDemoSubtitle",
    defaultMessage:
      "Identify your Partner presence on your existing customers.<br/><br/>Leverage your partners' influence to better <b>retain customers</b>, or identify where an integration can be pushed to generate <b>upsales</b>.",
  },
  upsellDemoTitle: {
    id: "crm.Ecosystem.DemoContent.upsellDemoTitle",
    defaultMessage: "Help your Success team retain and upsell customers.",
  },
});
