import { Box, Tab, Tabs } from "@mui/material";
import { Info } from "components/icons";
import SendTitled from "components/icons/SendTilted";
import Button from "components/ui/Button";
import { TextInput } from "components/ui/TextInput";
import Tooltip from "components/ui/Tooltip";
import { Formik, FormikHelpers } from "formik";
import useSegment from "hooks/useSegment";
import useUserProfile from "hooks/useUserProfile";
import { makeStyles } from "makeStyles";
import { MessageType } from "models/Message";
import PartnerConnection from "models/PartnerConnection";
import { useState } from "react";
import { defineMessages, FormattedMessage, useIntl } from "react-intl";
import { WebhookEventName, WebhookService } from "services/WebhookService";
import { TrackingPayload } from "services/WebhookService";
import { PipelineEvent } from "tracking";
import { object, string } from "yup";

type Values = {
  message: string | null;
};

const initialValues: Values = {
  message: "",
};

const schema = object().shape({
  message: string().nullable(),
});

type Props = {
  addMessage: (message: string, type: MessageType) => void;
  partnerConnection: PartnerConnection;
};

export const MessageInput = ({ addMessage, partnerConnection }: Props) => {
  const intl = useIntl();
  const { track } = useSegment();
  const { classes } = useStyles();
  const { profile } = useUserProfile();
  const service = new WebhookService();

  const [type, setType] = useState<MessageType>(MessageType.external);

  const trackMessageEvents = () => {
    let eventName: WebhookEventName;
    if (type === MessageType.internal) {
      eventName = WebhookEventName.RevealInternalMessageSentInConversation;
    } else if (profile.company.id === partnerConnection.companyId) {
      eventName = WebhookEventName.RevealMyTeamSentMessageInConversation;
    } else {
      eventName = WebhookEventName.RevealPartnerSentMessageInConversation;
    }

    const eventPayload: TrackingPayload = {
      profile: profile,
      partnerConnection: partnerConnection,
      rawCompanyId: partnerConnection.rawCompanyId,
      rawCompanyProviderKey: partnerConnection.rawCompanyProviderKey,
      partnershipId: partnerConnection.partnershipId,
      eventName: eventName,
    };

    if (profile.company.id !== partnerConnection.companyId) {
      if (type === MessageType.internal) {
        eventPayload.partnerCompanyId = partnerConnection.companyId;
      } else {
        eventPayload.companyId = partnerConnection.companyId;
        eventPayload.partnerCompanyId = profile.company.id;
      }
    }
    service.track(eventPayload);
    track(PipelineEvent.clickedSendMessage, {
      type,
      perspective:
        profile.company.id === partnerConnection.companyId ? "Mine" : "Theirs",
    });
  };

  const handleSubmit = async (
    values: Values,
    actions: FormikHelpers<Values>
  ) => {
    if (values.message) {
      trackMessageEvents();
      addMessage(values.message.trim(), type);
      actions.resetForm();
    }
  };

  const handleChangeType = (
    event: React.SyntheticEvent,
    newValue: MessageType
  ) => {
    setType(newValue);
  };

  return (
    <div className={classes.root}>
      <Formik
        initialValues={initialValues}
        onSubmit={handleSubmit}
        validationSchema={schema}
      >
        {({ values, handleSubmit, handleChange, isSubmitting }) => {
          return (
            <>
              <Tabs
                value={type}
                onChange={handleChangeType}
                classes={{
                  flexContainer: classes.tabFlexContainer,
                  indicator: classes.tabIndicator,
                  root: classes.tabRoot,
                }}
              >
                <Tab
                  label={<FormattedMessage {...i18n.reply} />}
                  value={MessageType.external}
                />
                <Tab
                  label={
                    <Box className={classes.tabTitle}>
                      <FormattedMessage {...i18n.internalNote} />
                      <Tooltip
                        title={
                          <FormattedMessage {...i18n.tooltipInternalNote} />
                        }
                      >
                        <Info />
                      </Tooltip>
                    </Box>
                  }
                  value={MessageType.internal}
                />
              </Tabs>
              <TextInput
                multiline
                variant="tertiary"
                rows="3"
                className={classes.input}
                placeholder={intl.formatMessage(
                  type === MessageType.internal
                    ? i18n.placeholderInternalNote
                    : i18n.placeholderMessage
                )}
                value={values.message}
                onChange={handleChange}
                name="message"
                disabled={isSubmitting}
              />
              <Button
                size="small"
                label={i18n.send}
                onClick={handleSubmit}
                LeftIcon={SendTitled}
                classes={{ btn: classes.sendButton }}
                disabled={isSubmitting || !values.message}
              />
            </>
          );
        }}
      </Formik>
    </div>
  );
};

// CSS

export const useStyles = makeStyles()((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    paddingLeft: 20,
    paddingRight: 20,
    paddingBottom: 8,
  },
  tabFlexContainer: {
    columnGap: theme.spacing(2),
    marginLeft: theme.spacing(2),
  },
  tabRoot: {
    border: `1px solid ${theme.palette.greyscale200}`,
    borderBottom: "none",
    borderTopLeftRadius: 12,
    borderTopRightRadius: 12,
    minHeight: 40,
    "& .MuiTab-root": {
      padding: "11px 2px",
      minWidth: 30,
      minHeight: 40,
      textTransform: "none",
      fontSize: 12,
      color: theme.palette.alpha500,
      "&.Mui-selected": {
        color: theme.palette.midnight,
      },
    },
  },
  tabIndicator: {
    background: theme.palette.midnight,
  },
  tabTitle: {
    display: "flex",
    alignItems: "center",
    gap: 4,
    "& svg": {
      color: theme.palette.alpha500,
      height: 14,
      width: 14,
    },
  },
  input: {
    width: "100%",
    "& .MuiInputBase-root": {
      borderRadius: 12,
      borderTopLeftRadius: 0,
      borderTopRightRadius: 0,
      padding: 16,
      paddingBottom: 36,
      "&:hover": {
        borderColor: theme.palette.greyscale200,
      },
    },
  },
  sendButton: {
    position: "absolute",
    bottom: 20,
    right: 332,
  },
}));

// I18N

const i18n = defineMessages({
  reply: {
    id: "crm.MyPipeline.MessageDrawer.MessageInput.reply",
    defaultMessage: "Message",
  },
  internalNote: {
    id: "crm.MyPipeline.MessageDrawer.MessageInput.internalNote",
    defaultMessage: "Internal note",
  },
  placeholderMessage: {
    id: "crm.MyPipeline.MessageDrawer.MessageInput.placeholderMessage",
    defaultMessage: "Add your message...",
  },
  placeholderInternalNote: {
    id: "crm.MyPipeline.MessageDrawer.MessageInput.placeholderInternalNote",
    defaultMessage: "Add your internal note...",
  },
  tooltipInternalNote: {
    id: "crm.MyPipeline.MessageDrawer.MessageInput.tooltipInternalNote",
    defaultMessage:
      "Internal notes are shared with members from your workspace only",
  },
  send: {
    id: "crm.MyPipeline.MessageDrawer.MessageInput.send",
    defaultMessage: "Send",
  },
});
