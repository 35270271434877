import { createReducer } from "@reduxjs/toolkit";

import { setSelectedPartner, setSelectedPartnershipId } from "./actions";
import { NotificationWidgetState } from "./types";

const initState: NotificationWidgetState = {
  selectedPartner: null,
  selectedPartnershipId: null,
};

const reducer = createReducer(initState, (builder) =>
  builder
    .addCase(setSelectedPartner, (state, action) => {
      // @ts-ignore Type instantiation is excessively deep and possibly infinite
      state.selectedPartner = action.payload;
    })
    .addCase(setSelectedPartnershipId, (state, action) => {
      state.selectedPartnershipId = action.payload;
    })
);

export default reducer;
