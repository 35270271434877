import { useOktaAuth } from "@okta/okta-react";
import { stringify } from "query-string";

const useOktaLogin = (sourceButton?: string) => {
  const { oktaAuth } = useOktaAuth();
  return async (idp: $TSFixMe, next: $TSFixMe) => {
    const originalUri =
      "/okta?" + stringify({ next, source_button: sourceButton });
    await oktaAuth.signInWithRedirect({ originalUri, idp });
  };
};

export default useOktaLogin;
