import { Box } from "@mui/material";
import { Lock } from "components/icons";
import Toggle from "components/ui/Toggle";
import { T } from "components/ui/Typography";
import { makeStyles } from "makeStyles";
import { ChangeEvent, useCallback } from "react";
import { defineMessages, FormattedMessage } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import {
  selectNotOffineFilters,
  selectOffineFilter,
} from "redux/analytics/selectors";
import { updateView } from "redux/analytics/thunks";
import { AnalyticsOtherFilterFieldname } from "redux/analytics/types";

import { MatchFilterType } from "../../../shared/types";

type Props = {
  disabled?: boolean;
};

const AnalyticsOfflineFilter = ({ disabled }: Props) => {
  const dispatch = useDispatch();
  const { classes } = useStyles();
  const offineFilter = useSelector(selectOffineFilter);
  const notOffineFilters = useSelector(selectNotOffineFilters);

  const setOffineFilter = useCallback(
    (e: ChangeEvent<HTMLInputElement>, checked: boolean) => {
      const filters = checked
        ? [
            ...notOffineFilters,
            {
              fieldname: AnalyticsOtherFilterFieldname.IncludeOffline,
              type: MatchFilterType._NO_OPERATOR,
              value: true,
            },
          ]
        : notOffineFilters;
      dispatch(
        updateView({
          filters,
        })
      );
    },
    [dispatch, notOffineFilters]
  );

  return (
    <Box className={classes.root}>
      {disabled ? (
        <Lock style={{ fontSize: 16, cursor: "not-allowed" }} />
      ) : (
        <Toggle
          checked={offineFilter?.value ?? false}
          handleChange={setOffineFilter}
        />
      )}
      <T>
        <FormattedMessage {...i18n.toggleOfflinePartnerLabel} />
      </T>
    </Box>
  );
};

export default AnalyticsOfflineFilter;

/// CSS

const useStyles = makeStyles()((theme) => ({
  root: {
    display: "flex",
    alignItems: "center",
    marginLeft: 32,
    marginBottom: 5,
    gap: 8,
    "& p": {
      whiteSpace: "nowrap",
    },
  },
}));

/// I18N

const i18n = defineMessages({
  toggleOfflinePartnerLabel: {
    id: "crm.Analytics.toggleOfflinePartnerLabel",
    defaultMessage: "Show offline partners",
  },
});
