import { Info } from "components/icons";
import Button from "components/ui/Button";
import SelectedRowsContext from "components/ui/data-grid/SelectedRowsContext";
import useHasPayingFeature from "hooks/useHasPayingFeature";
import useSegment from "hooks/useSegment";
import useUserProfile from "hooks/useUserProfile";
import { PayingFeature } from "models/CompanyPayingFeatureSubscription";
import { useContext, useMemo } from "react";
import { defineMessages } from "react-intl";
import ActionBtn from "screens/Frontoffice/screens/DataTables/shared/components/ActionBtn";
import MappingFilterContext from "screens/Frontoffice/screens/DataTables/shared/contexts/MappingFilterContext";
import {
  DataTableType,
  MatchFilterType,
} from "screens/Frontoffice/screens/DataTables/shared/types";

import { GoalBased360Event, goalBased360EventName } from "../../utils";
import { HeaderActionsProps } from "./types";
import { prepareReportParameters } from "./utils";

const HeaderActions = ({
  rows,
  columns,
  fields,
  filters,
  sort,
  selectedSourceId,
  sourceFieldName,
}: HeaderActionsProps) => {
  const { selectedRowIds } = useContext(SelectedRowsContext);
  const { profile } = useUserProfile();
  const { track } = useSegment();
  const isGoalBased360Limited = useHasPayingFeature(
    PayingFeature.GoalBased360Limited,
    profile
  );
  const isGoalBased360Unlocked = useHasPayingFeature(
    PayingFeature.GoalBased360Unlocked,
    profile
  );
  const { defaultView } = useContext(MappingFilterContext);

  const selectedRows = rows.filter((row) => selectedRowIds.includes(row.id));
  const filtersWithRows = useMemo(
    () => [
      ...filters,
      ...(selectedRowIds.length
        ? [
            {
              fieldname: "id",
              type: MatchFilterType._IN_OPERATOR,
              value: selectedRowIds,
            },
          ]
        : []),
    ],
    [selectedRowIds, filters]
  );

  const handleTrack = () => {
    const eventName = defaultView
      ? goalBased360EventName(
          GoalBased360Event.GoalInfoButtonClicked,
          defaultView.name
        )
      : GoalBased360Event.GoalInfoButtonClicked;
    track(eventName);
  };

  return (
    <>
      <ActionBtn
        rows={selectedRows}
        parameters={prepareReportParameters(
          columns,
          filtersWithRows,
          sort,
          sourceFieldName,
          selectedSourceId,
          fields
        )}
        viewType={DataTableType.MAPPING_360}
      />
      {(isGoalBased360Limited || isGoalBased360Unlocked) && (
        <Button
          label={i18n.howItWorks}
          variant="tertiary"
          LeftIcon={Info}
          onClick={handleTrack}
        />
      )}
    </>
  );
};

export default HeaderActions;

const i18n = defineMessages({
  howItWorks: {
    id: "crm.Ecosystem.HeaderActions.howItWorks",
    defaultMessage: "How does this goal work?",
  },
});
