import { Skeleton } from "@mui/material";
import { makeStyles } from "makeStyles";

const PartnerTileSkeleton = () => {
  const { classes } = useStyles();

  return (
    <div className={classes.root}>
      <div className={classes.avatarAndPartnerNameContainer}>
        <Skeleton
          variant="rectangular"
          className={classes.logo}
          width={38}
          height={38}
        />
        <Skeleton
          variant="rectangular"
          className={classes.partnerName}
          width={150}
        />
      </div>
      <Skeleton variant="rectangular" height={30} width="100%" />
    </div>
  );
};

export const useStyles = makeStyles()((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    position: "relative",
    height: 117,
    borderRadius: 8,
    padding: 16,
    backgroundColor: theme.palette.ivory,
  },
  logo: {
    width: 38,
    height: 38,
    borderRadius: 8,
  },
  avatarAndPartnerNameContainer: {
    display: "flex",
    flexDirection: "row",
    columnGap: 12,
    marginBottom: 15,
  },
  partnerName: {
    marginTop: 10,
    borderRadius: 4,
  },
}));

export default PartnerTileSkeleton;
