import Partnership from "models/Partnership";

import { initState } from "./reducers";
import { RootStateWithOverview } from "./types";

export const selectOverviewForPartnership = (partnership: Partnership) => (
  state: RootStateWithOverview
) =>
  state.overview.views[partnership.id] ?? {
    view: undefined,
    state: {
      accountOverlap: partnership.insights?.marketOverlap,
      contactOverlap: partnership.insights?.personaOverlap,
      ...initState,
    },
  };
