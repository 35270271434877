import {
  array,
  boolean,
  constant,
  Decoder,
  guard,
  iso8601,
  map,
  nullable,
  number,
  object,
  oneOf,
  string,
} from "decoders";

import Record from "./Record";
import { JSONAPIResource, RecordType } from "./types";

export enum Stage {
  lead = "Lead",
  qualification = "Qualification",
  final = "Final",
}

export enum stageEncodeMapping {
  "Lead" = 1,
  "Qualification" = 2,
  "Final" = 3,
}

export const stageDecodeMapping: {
  [stage: number]: Stage;
} = {
  [stageEncodeMapping[Stage.lead]]: Stage.lead,
  [stageEncodeMapping[Stage.qualification]]: Stage.qualification,
  [stageEncodeMapping[Stage.final]]: Stage.final,
};

export const StageDecoder: Decoder<Stage> = map<stageEncodeMapping, Stage>(
  oneOf(
    Object.keys(stageEncodeMapping)
      .map((x) => parseInt(x))
      .filter((x) => !isNaN(x))
  ),
  (stage: stageEncodeMapping) => stageDecodeMapping[stage]
);

const apiPipelineOpportunity = object({
  type: constant(RecordType.PipelineOpportunity as const),
  id: string,
  attributes: object({
    integration_id: number,
    provider_key: string,
    name: nullable(string),
    raw_company_provider_key: string,
    raw_company_name: string,
    raw_company_starred: boolean,
    owner_provider_key: nullable(string),
    owner_full_name: nullable(string),
    owner_email: nullable(string),
    url: nullable(string),
    amount_display_value: nullable(
      object({ amount: number, currency: string })
    ),
    amount: nullable(number), // How to do this
    opportunity_type: nullable(string), // Is there a type ?
    next_step: nullable(string), // Is there a type ?
    stage: nullable(string), // Is there a type ?
    creation_date: nullable(iso8601),
    close_date: nullable(iso8601),
    status: string, // How to do this type
    partner_discussion_list: array(
      // Check if this is correct
      object({
        partnership_id: number,
        partner_id: number,
        partner_name: string,
        partner_connections: array(
          object({
            id: number,
            opportunity_relation: string,
          })
        ),
      })
    ),
    partner_attribution_list: array(
      // Check if this is correct
      object({
        partnership_id: number,
        partner_id: number,
        partner_name: string,
        sourcing: boolean,
        influencing: boolean,
        other: boolean,
      })
    ),
    sourcing_partner: nullable(string),
    influencing_partner: nullable(string),
    custom_fields: object({}), // type ?
    created_at: iso8601,
    updated_at: iso8601,
  }),
  relationships: object({}),
});

const apiPipelineOpportunityGuard = guard(apiPipelineOpportunity);

export default class PipelineOpportunity extends Record<RecordType.PipelineOpportunity> {
  id: number;
  integration_id: number;
  provider_key: string;
  name: string | null;
  raw_company_provider_key: string;
  raw_company_name: string;
  raw_company_starred: boolean;
  owner_provider_key: string | null;
  owner_full_name: string | null;
  owner_email: string | null;
  url: string | null;
  amount_display_value: { amount: number; currency: string } | null; // TODO type
  amount: number | null;
  opportunity_type: string | null; // TODO type
  next_step: string | null; // TODO type
  stage: string | null; // TODO type
  creation_date: Date | null;
  close_date: Date | null;
  status: string | null; // TODO type
  partner_discussion_list: {} | null; // TODO type
  partner_attribution_list: {} | null; // TODO type
  sourcing_partner: string | null;
  influencing_partner: string | null;
  custom_fields: {};
  created_at: Date;
  updated_at: Date;

  constructor(data: JSONAPIResource<RecordType.PipelineOpportunity>) {
    super(data);
    const apiPipelineOpportunity = apiPipelineOpportunityGuard(data);
    const attrs = apiPipelineOpportunity.attributes;
    // const relationships = apiPipelineOpportunity.relationships;

    this.id = +apiPipelineOpportunity.id;
    this.integration_id = attrs.integration_id;
    this.provider_key = attrs.provider_key;
    this.name = attrs.name;
    this.raw_company_provider_key = attrs.raw_company_provider_key;
    this.raw_company_name = attrs.raw_company_name;
    this.raw_company_starred = attrs.raw_company_starred;
    this.owner_provider_key = attrs.owner_provider_key;
    this.owner_full_name = attrs.owner_full_name;
    this.owner_email = attrs.owner_email;
    this.url = attrs.url;
    this.amount = attrs.amount;
    this.amount_display_value = attrs.amount_display_value;
    this.opportunity_type = attrs.opportunity_type;
    this.next_step = attrs.next_step;
    this.stage = attrs.stage;
    this.creation_date = attrs.creation_date;
    this.close_date = attrs.close_date;
    this.status = attrs.status;
    this.partner_discussion_list = attrs.partner_discussion_list;
    this.partner_attribution_list = attrs.partner_attribution_list;
    this.sourcing_partner = attrs.sourcing_partner;
    this.influencing_partner = attrs.influencing_partner;
    this.custom_fields = attrs.custom_fields;
    this.created_at = attrs.created_at;
    this.updated_at = attrs.updated_at;
  }
}

export const example = {
  id: "123456",
  type: "attributed_opportunities",
  attributes: {
    integration_id: 123,
    provider_key: "wertyh12345",
    name: "Renewal Invision",
    raw_company_name: "Invision",
    raw_company_starred: true,
    owner_full_name: "Jean Michel",
    owner_email: "jm@test.co",
    amount: { value: 13000, currency: "EUR" },
    opportunity_type: "Renewal",
    next_step: "Signature",
    stage: "Qualification",
    creation_date: "2023-10-21",
    close_date: "2024-01-01",
    status: "OPEN",
    partner_discussion_list: [
      {
        partnership_id: 12,
        partner_id: 2,
        partner_name: "Reveal",
        partner_connections: [
          {
            id: 345,
            opportunity_relation: "LINKED",
          },
          {
            id: 3445,
            opportunity_relation: "UNLINKED",
          },
        ],
      },
      {
        partnership_id: 123,
        partner_id: 234,
        partner_name: "ContentSquare",
        partner_connections: [
          {
            id: 353,
            opportunity_relation: "RECOMMENDED",
          },
        ],
      },
    ],
    partner_attribution_list: [
      {
        partnership_id: 142,
        partner_id: 222,
        partner_name: "AB Tasty",
        sourcing: true,
        influencing: false,
        other: false,
      },
    ],
    sourcing_partner: "Reveal",
    influencing_partner: null,
  },
};
