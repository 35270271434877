import { CloseRounded } from "@mui/icons-material";
import { Box, Grid } from "@mui/material";
import { isFulfilled, isRejected } from "@reduxjs/toolkit";
import { Check } from "components/icons";
import CompanyAvatar from "components/ui/avatars/CompanyAvatar";
import Button from "components/ui/Button";
import diffObjects from "helpers/diffObjects";
import usePushNotification from "hooks/usePushNotification";
import useSegment from "hooks/useSegment";
import useUserProfile from "hooks/useUserProfile";
import _ from "lodash";
import { makeStyles } from "makeStyles";
import Partnership, { DataShare, PartnershipStatus } from "models/Partnership";
import { useCallback, useEffect, useMemo, useState } from "react";
import { defineMessages, FormattedMessage } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { rawPost, retreive } from "redux/api/thunks";
import { fields, included } from "redux/init/constants";
import { RevealStore } from "redux/typing";
import { fetchProfileInBackground } from "redux/user/thunks";
import { InvitationReviewSharingSettings } from "screens/Frontoffice/screens/Partners/screens/Dashboard/components/InvitationReviewSharingSettings";
import { useUpdateDataSharing } from "screens/Frontoffice/screens/Partners/shared/api";
import { SelectedCompany } from "screens/Frontoffice/shared/components/InviteForm/types";
import JSONAPIService from "services/JSONAPIService";

type Props = {
  partnershipId: string;
  onClose?: () => void;
  isFromGetStarted?: boolean;
};

export const useInviteModal = ({
  partnershipId,
  onClose,
  isFromGetStarted,
}: Props) => {
  const { classes } = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();
  const { profile } = useUserProfile();
  const { track } = useSegment();
  const pushNotification = usePushNotification();
  const [
    selectedCompany,
    setSelectedCompany,
  ] = useState<SelectedCompany | null>(null);
  const [loading, setLoading] = useState(false);
  const [isAccepted, setIsAccepted] = useState(false);
  const [dataSharingStatus, setDataSharingStatus] = useState<DataShare | null>(
    null
  );
  const updateDataSharing = useUpdateDataSharing();

  const partnership = useSelector((state: RevealStore) =>
    _.get(_.get(state, "api.entities.partnerships"), partnershipId)
  ) as Partnership;

  const setSharingSettings = (dataSharing: DataShare) => {
    setDataSharingStatus(dataSharing);
  };

  const handleClose = useCallback(() => {
    onClose
      ? onClose()
      : history.replace({
          pathname: "/partnerships",
        });
  }, [history, onClose]);

  const goToDirectoryFromGetStarted = useCallback(() => {
    history.replace({
      pathname: "/directory",
      hash: "get-started",
    });
  }, [history]);

  const fetchCompany = useCallback(async () => {
    if (!partnership || isAccepted) {
      return;
    }
    if (partnership.status === PartnershipStatus.Accepted) {
      history.replace({
        pathname: `/partnerships/${partnership.id}/settings`,
      });
      return;
    }
    if (
      !partnership.initiatorCompany ||
      partnership.status !== PartnershipStatus.Pending ||
      partnership.destCompany?.name !== profile.company.name
    ) {
      handleClose();
      return;
    }
    setLoading(true);
    try {
      const response = await new JSONAPIService("companies").get(
        partnership.initiatorCompany.id
      );
      const info = response.data.data.attributes;
      setSelectedCompany({
        description: info?.description,
        domain: info?.domain_name,
        id: Number(response.data?.data.id),
        logo: info?.avatar_url,
        name: info?.name,
        websiteUrl: info?.website_url,
        companyType: info?.business_type,
        ecosystem: info?.ecosystem,
        size: info?.size,
      } as SelectedCompany);
    } catch (e) {
      setSelectedCompany({
        domain: "",
        id: partnership.initiatorCompany.id,
        name: partnership.initiatorCompany.attributes.name as string,
        logo: partnership.initiatorCompany.attributes.avatar_url as string,
      });
      return;
    } finally {
      setLoading(false);
    }
  }, [handleClose, history, isAccepted, partnership, profile.company.name]);

  const loadPartnership = useCallback(async () => {
    if (partnership) {
      return;
    }
    const action = await dispatch(
      retreive({
        id: +partnershipId,
        type: "partnerships",
        options: { include: included, fields },
      })
    );
    if (isRejected(action)) {
      handleClose();
    }
  }, [partnership, partnershipId, dispatch, handleClose]);

  const handleAccept = useCallback(async () => {
    setLoading(true);
    if (
      isFulfilled(
        await dispatch(
          rawPost({
            type: partnership.type,
            id: partnership.id,
            path: "/accept-invite/",
            payload: undefined,
            options: {
              fields,
              include: included,
            },
          })
        )
      )
    ) {
      track("Accepted partnership");
      profile.identify({
        activePartnershipsCount:
          _.get(profile, "company.activePartnership") + 1,
        pendingPartnershipCount:
          _.get(profile, "company.pendingPartnership") - 1,
        pendingPartnershipToAcceptCount:
          _.get(profile, "company.pendingToAcceptPartnership") - 1,
      });
    } else {
      pushNotification("default_error");
      return;
    }
    const partner = partnership.getPartner(profile);
    const errors = await updateDataSharing(
      diffObjects(
        { dataSharingStatus },
        { dataSharingStatus: partnership.getDataSharingStatus(profile.company) }
      ),
      partnership,
      profile.company,
      partner.name
    );
    if (errors.length) {
      pushNotification(i18n.failedSharingSettings);
      return;
    }
    if (isFromGetStarted && onClose) {
      onClose();
    }
    setLoading(false);
    dispatch(fetchProfileInBackground());
  }, [
    dataSharingStatus,
    dispatch,
    partnership,
    profile,
    pushNotification,
    track,
    updateDataSharing,
    isFromGetStarted,
    onClose,
  ]);

  const handleClickAccept = useCallback(async () => {
    setIsAccepted(true);
    await handleAccept();
  }, [handleAccept]);

  useEffect(() => {
    loadPartnership();
  }, [dispatch, loadPartnership]);

  useEffect(() => {
    fetchCompany();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetchCompany]);

  useEffect(() => {
    setDataSharingStatus(partnership?.getDataSharingStatus(profile.company));
  }, [partnership, profile.company]);

  const invitationSharingSettingsContent = useMemo(
    () =>
      selectedCompany ? (
        <Grid item xs={12} className={classes.sharingSettings}>
          <Box>
            <InvitationReviewSharingSettings
              onChange={setSharingSettings}
              sharingStatus={dataSharingStatus}
            />
          </Box>
        </Grid>
      ) : null,
    [classes, dataSharingStatus, selectedCompany]
  );

  const avatar = useMemo(
    () =>
      !!selectedCompany?.name ? (
        <CompanyAvatar
          classes={{ avatar: classes.avatar }}
          companyName={selectedCompany.name}
          companyDomain={selectedCompany.domain}
          src={selectedCompany.logo}
        />
      ) : null,
    [classes.avatar, selectedCompany]
  );

  const closeButton = useMemo(
    () => (
      <Grid item xs={12} className={classes.closeButton}>
        <Button
          LeftIcon={CloseRounded}
          onClick={handleClose}
          size="small"
          variant="tertiary"
        />
      </Grid>
    ),
    [classes.closeButton, handleClose]
  );

  const inviteButton = useMemo(
    () => (
      <div className={classes.buttonsContainer}>
        {isFromGetStarted && (
          <Button
            label={i18n.inviteNew}
            variant="secondary"
            onClick={goToDirectoryFromGetStarted}
          />
        )}
        <Button
          LeftIcon={Check}
          label={
            <FormattedMessage
              {...(selectedCompany
                ? i18n.inviteButtonLabel
                : i18n.inviteButtonLabelEmpty)}
              values={{ companyName: selectedCompany?.name }}
            />
          }
          onClick={handleClickAccept}
          disabled={!selectedCompany || loading}
          loading={!selectedCompany || loading}
        />
      </div>
    ),
    [
      classes.buttonsContainer,
      isFromGetStarted,
      goToDirectoryFromGetStarted,
      handleClickAccept,
      loading,
      selectedCompany,
    ]
  );

  return {
    avatar,
    closeButton,
    handleClose,
    invitationSharingSettingsContent,
    loading,
    partnership,
    selectedCompany,
    dataSharingStatus,
    isAccepted,
    inviteButton,
  };
};

export const useStyles = makeStyles()((theme) => ({
  avatar: {
    display: "inline-block",
    verticalAlign: "middle",
    height: 32,
    width: 32,
  },
  buttonsContainer: {
    display: "flex",
    justifyContent: "center",
    columnGap: theme.spacing(1),
    marginTop: theme.spacing(2.5),
  },
  closeButton: {
    display: "flex",
    flexDirection: "row-reverse",
  },
  closeButtonAbs: {
    position: "absolute",
    right: theme.spacing(2),
    top: theme.spacing(2),
  },
  container: {
    alignItems: "flex-start",
    gap: theme.spacing(1.5),
    justifyContent: "center",
  },
  inviteClarificationWithBorder: {
    border: `1px solid ${theme.palette.taupe500}`,
    borderRadius: 8,
    padding: theme.spacing(2),
  },
  marginTopSmall: {
    marginTop: theme.spacing(1),
  },
  marginTop: {
    marginTop: theme.spacing(2),
  },
  noWrap: {
    whiteSpace: "nowrap",
  },
  rowItem: {
    background: theme.palette.ivory,
    borderRadius: 8,
  },
  titleContainer: {
    display: "inline-block",
    marginBottom: theme.spacing(4),
    textAlign: "center",
    "& > h3": {
      display: "inline-block",
    },
  },
  paddingMedium: {
    padding: theme.spacing(3),
  },
  paddingLarge: {
    padding: `0 ${theme.spacing(8)}`,
  },
  sharingSettings: {
    marginTop: theme.spacing(1.5),
  },
  titleNarrow: {
    maxWidth: 520,
  },
}));

const i18n = defineMessages({
  failedSharingSettings: {
    id: "useInviteModal.failedSharingSettings",
    defaultMessage: "Failed to update the data sharing settings",
  },
  inviteButtonLabel: {
    id: "useInviteModal.inviteButtonLabel",
    defaultMessage: "Connect with {companyName}",
  },
  inviteButtonLabelEmpty: {
    id: "useInviteModal.inviteButtonLabelEmpty",
    defaultMessage: "Connect",
  },
  inviteNew: {
    id: "useInviteModal.inviteNew",
    defaultMessage: "Invite new partners",
  },
});
