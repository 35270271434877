export enum Enum {
  AccountMapping = "PAGE_ACCOUNT_MAPPING",
  Collaborate = "PAGE_PIPELINE",
  MyAccounts = "PAGE_MY_ACCOUNTS",
  ReferredAccounts = "PAGE_REFERRED_ACCOUNTS",
  NotFound = "PAGE_NOT_FOUND",
}

export const fromLocation = ({ pathname }: { pathname: string }) => {
  if (pathname.startsWith("/partnerships")) {
    return Enum.AccountMapping;
  }

  if (pathname.startsWith("/crm-accounts")) {
    return Enum.MyAccounts;
  }

  if (pathname.startsWith("/pipeline")) {
    return Enum.Collaborate;
  }

  return Enum.NotFound;
};

export const toString = (page: Enum, onNewProspects = false) => {
  switch (page) {
    case Enum.AccountMapping:
      if (onNewProspects) {
        return "account mapping new prospects";
      }
      return "account mapping";

    case Enum.MyAccounts:
      return "my accounts";

    case Enum.Collaborate:
      return "pipeline";

    case Enum.ReferredAccounts:
      return "referred accounts";
  }
};
