import { List } from "@mui/material";
import { PremiumSection } from "components/ui/navbar/PremiumPlanWidget/components/PremiumSection";
import useSegment from "hooks/useSegment";
import { makeStyles } from "makeStyles";
import {
  FeatureStatus,
  PayingFeature,
} from "models/CompanyPayingFeatureSubscription";
import { defineMessages, FormattedMessage } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router";
import { selectActivePayingFeatures } from "redux/api/selectors";
import {
  toggleFree,
  togglePower,
  togglePro,
} from "redux/premiumPlanWidget/actions";
import { selectPremiumPlanWidgetState } from "redux/premiumPlanWidget/selectors";

import { FeatureItem } from "./FeatureItem";
import { WidgetTag } from "./WidgetTag";

export enum Section {
  free = "Free",
  pro = "Pro",
  power = "Power",
}

export const PremiumPlanContent = () => {
  const location = useLocation();
  const { track } = useSegment();
  const { classes } = useStyles();
  const dispatch = useDispatch();
  const {
    freeUnfolded,
    proUnfolded,
    powerUnfolded,
    proFeatures,
    powerFeatures,
  } = useSelector(selectPremiumPlanWidgetState);
  const hasSmartViewsPro = useSelector(
    selectActivePayingFeatures
  ).some((item) =>
    [PayingFeature.SavedFiltersPRO, PayingFeature.SavedFiltersPOWER].includes(
      item
    )
  );

  const toggleSection = (section: Section, status: boolean) => {
    if (section === Section.free) dispatch(toggleFree(status));
    else if (section === Section.pro) dispatch(togglePro(status));
    else dispatch(togglePower(status));

    track(`${status ? "Unfolded" : "Folded"} Pricing plan`, {
      planName: section,
      page: location.pathname,
    });
  };

  return (
    <List className={classes.widgetsContainer}>
      <PremiumSection
        label={<FormattedMessage {...i18n.free} />}
        tag={<WidgetTag onHeader status={FeatureStatus.unlimited} />}
        isUnfolded={freeUnfolded}
        toggle={(status) => toggleSection(Section.free, status)}
      >
        <FeatureItem name={i18n.partners} status={FeatureStatus.unlimited} />
        <FeatureItem name={i18n.overlaps} status={FeatureStatus.unlimited} />
        <FeatureItem name={i18n.directory} status={FeatureStatus.unlimited} />
      </PremiumSection>
      <PremiumSection
        label={<FormattedMessage {...i18n.pro} />}
        tag={<WidgetTag onHeader status={proFeatures.generalStatus} />}
        isUnfolded={proUnfolded}
        toggle={(status) => toggleSection(Section.pro, status)}
      >
        {proFeatures.featureList.map((preset, k) => (
          <FeatureItem
            key={k}
            {...preset}
            limitValue={
              preset?.limitFunction?.(hasSmartViewsPro) ?? preset.limitValue
            }
          />
        ))}
      </PremiumSection>
      <PremiumSection
        label={<FormattedMessage {...i18n.power} />}
        tag={<WidgetTag onHeader status={powerFeatures.generalStatus} />}
        isUnfolded={powerUnfolded}
        toggle={(status) => toggleSection(Section.power, status)}
      >
        {powerFeatures.featureList.map((preset, k) => (
          <FeatureItem key={k} {...preset} />
        ))}
      </PremiumSection>
    </List>
  );
};

/// CSS

const useStyles = makeStyles()((theme) => ({
  widgetsContainer: {
    padding: 0,
    display: "flex",
    flexDirection: "column",
    rowGap: theme.spacing(0.5),
  },
}));

/// I18N

const i18n = defineMessages({
  free: {
    id: "navbar.PremiumPlanWidget.PremiumPlanContent.free",
    defaultMessage: "Free",
  },
  pro: {
    id: "navbar.PremiumPlanWidget.PremiumPlanContent.pro",
    defaultMessage: "Pro",
  },
  power: {
    id: "navbar.PremiumPlanWidget.PremiumPlanContent.power",
    defaultMessage: "Power",
  },
  partners: {
    id: "navbar.PremiumPlanWidget.PremiumPlanContent.partners",
    defaultMessage: "Partners & Account Mappings",
  },
  overlaps: {
    id: "navbar.PremiumPlanWidget.PremiumPlanContent.overlaps",
    defaultMessage: "Account Overlaps",
  },
  directory: {
    id: "navbar.PremiumPlanWidget.PremiumPlanContent.directory",
    defaultMessage: "Reveal Directory",
  },
});
