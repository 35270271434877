import {
  SWQLFieldOptions,
  SWQLFieldType,
  SWQLTarget,
} from "components/ui/SWQL/SWQLTypes";
import _ from "lodash";
import { defineMessages, FormattedMessage } from "react-intl";
import JSONAPIService from "services/JSONAPIService";

import Integration from "./Integration";
import { Record } from "./Record";
import SWQLOperator from "./SWQLOperator";
import { JSONAPIResourceOrRecord } from "./types";

export type CrmFieldType = {
  id: number;
  swType: SWQLFieldType;
  crmLabel: string;
  crmName: string;
  crmOptions: SWQLFieldOptions;
};

const blank = {
  value: null,
  label: "Blank",
  isItalic: true,
};

export enum SwRecordType {
  RawCompany = "RawCompany",
  RawOpportunity = "RawOpportunity",
  RawContact = "RawContact",
  RawUser = "RawUser",
  RawContactOpportunity = "RawContactOpportunity",
}

export default class CrmField extends Record<"crm_fields"> {
  swType: SWQLFieldType;
  crmLabel: string;
  crmName: string;
  crmOptions: SWQLFieldOptions;
  crmRecordType: string;
  swRecordType: SwRecordType;
  integration?: Integration;

  static TYPE_UNKNOWN = 0;
  static TYPE_TEXT = 1;
  static TYPE_PICKLIST = 2;
  static TYPE_BOOLEAN = 3;
  static TYPE_NUMBER = 4;
  static TYPE_INTEGER = 5;
  static TYPE_DATE = 6;
  static TYPE_DATETIME = 7;
  static TYPE_CURRENCY = 8;
  static TYPE_USER = 9;
  static TYPE_SINGLE_CHOICE_PICKLIST = 10;
  static TYPE_REFERENCES_USER = 11;
  static TYPE_REFERENCES_ACCOUNT = 12;
  static TYPE_REFERENCES_OPPORTUNITY = 13;
  static TYPE_REFERENCES_CONTACT = 14;
  static TYPE_MULTI_CHOICE_PICKLIST = 15;

  static SWQL_OPERATORS = {
    [CrmField.TYPE_TEXT]: [
      SWQLOperator.TYPE_CONTAINS,
      SWQLOperator.TYPE_DOES_NOT_CONTAIN,
      SWQLOperator.TYPE_IS,
      SWQLOperator.TYPE_IS_NOT,
      SWQLOperator.TYPE_IS_NULL,
      SWQLOperator.TYPE_IS_NOT_NULL,
    ],
    [CrmField.TYPE_PICKLIST]: [SWQLOperator.TYPE_IN, SWQLOperator.TYPE_NOT_IN],
    [CrmField.TYPE_BOOLEAN]: [
      SWQLOperator.TYPE_IS,
      SWQLOperator.TYPE_IS_NOT,
      SWQLOperator.TYPE_IN,
      SWQLOperator.TYPE_NOT_IN,
    ],
    [CrmField.TYPE_INTEGER]: [
      SWQLOperator.TYPE_IS,
      SWQLOperator.TYPE_IS_NOT,
      SWQLOperator.TYPE_GTE,
      SWQLOperator.TYPE_LTE,
      SWQLOperator.TYPE_GT,
      SWQLOperator.TYPE_LT,
      SWQLOperator.TYPE_IS_NULL,
      SWQLOperator.TYPE_IS_NOT_NULL,
    ],
    [CrmField.TYPE_NUMBER]: [
      SWQLOperator.TYPE_IS,
      SWQLOperator.TYPE_IS_NOT,
      SWQLOperator.TYPE_GTE,
      SWQLOperator.TYPE_LTE,
      SWQLOperator.TYPE_GT,
      SWQLOperator.TYPE_LT,
      SWQLOperator.TYPE_IS_NULL,
      SWQLOperator.TYPE_IS_NOT_NULL,
    ],
    [CrmField.TYPE_CURRENCY]: [
      SWQLOperator.TYPE_GTE,
      SWQLOperator.TYPE_LTE,
      SWQLOperator.TYPE_GT,
      SWQLOperator.TYPE_LT,
      SWQLOperator.TYPE_IS_NULL,
      SWQLOperator.TYPE_IS_NOT_NULL,
    ],
    [CrmField.TYPE_USER]: [
      SWQLOperator.TYPE_CONTAINS,
      SWQLOperator.TYPE_DOES_NOT_CONTAIN,
      SWQLOperator.TYPE_IN,
      SWQLOperator.TYPE_NOT_IN,
    ],
    [CrmField.TYPE_DATE]: [
      SWQLOperator.TYPE_GT,
      SWQLOperator.TYPE_GTE,
      SWQLOperator.TYPE_LT,
      SWQLOperator.TYPE_LTE,
      SWQLOperator.TYPE_IS_NULL,
      SWQLOperator.TYPE_IS_NOT_NULL,
    ],
    [CrmField.TYPE_DATETIME]: [
      SWQLOperator.TYPE_GT,
      SWQLOperator.TYPE_GTE,
      SWQLOperator.TYPE_LT,
      SWQLOperator.TYPE_LTE,
      SWQLOperator.TYPE_IS_NULL,
      SWQLOperator.TYPE_IS_NOT_NULL,
    ],
    [CrmField.TYPE_REFERENCES_USER]: [
      SWQLOperator.TYPE_IS,
      SWQLOperator.TYPE_IS_NOT,
      SWQLOperator.TYPE_IS_NULL,
      SWQLOperator.TYPE_IS_NOT_NULL,
      SWQLOperator.TYPE_IN,
      SWQLOperator.TYPE_NOT_IN,
    ],
    [CrmField.TYPE_REFERENCES_ACCOUNT]: [
      SWQLOperator.TYPE_IS,
      SWQLOperator.TYPE_IS_NOT,
      SWQLOperator.TYPE_IS_NULL,
      SWQLOperator.TYPE_IS_NOT_NULL,
      SWQLOperator.TYPE_IN,
      SWQLOperator.TYPE_NOT_IN,
    ],
    [CrmField.TYPE_REFERENCES_CONTACT]: [
      SWQLOperator.TYPE_IS,
      SWQLOperator.TYPE_IS_NOT,
      SWQLOperator.TYPE_IS_NULL,
      SWQLOperator.TYPE_IS_NOT_NULL,
      SWQLOperator.TYPE_IN,
      SWQLOperator.TYPE_NOT_IN,
    ],
    [CrmField.TYPE_REFERENCES_OPPORTUNITY]: [
      SWQLOperator.TYPE_IS,
      SWQLOperator.TYPE_IS_NOT,
      SWQLOperator.TYPE_IS_NULL,
      SWQLOperator.TYPE_IS_NOT_NULL,
      SWQLOperator.TYPE_IN,
      SWQLOperator.TYPE_NOT_IN,
    ],
  };

  static SWQL_SUPPORTED_FIELD_TYPES = [
    CrmField.TYPE_PICKLIST,
    CrmField.TYPE_BOOLEAN,
    CrmField.TYPE_TEXT,
    CrmField.TYPE_NUMBER,
    CrmField.TYPE_INTEGER,
    CrmField.TYPE_CURRENCY,
    CrmField.TYPE_REFERENCES_USER,
    CrmField.TYPE_REFERENCES_ACCOUNT,
    CrmField.TYPE_REFERENCES_CONTACT,
    CrmField.TYPE_REFERENCES_OPPORTUNITY,
  ];

  static SWQL_SUPPORTED_FIELD_TYPES_WITH_DATES = [
    ...CrmField.SWQL_SUPPORTED_FIELD_TYPES,
    CrmField.TYPE_DATE,
    CrmField.TYPE_DATETIME,
  ];

  static DEFAULT_VALUES = {
    [CrmField.TYPE_TEXT]: "",
    [CrmField.TYPE_INTEGER]: 0,
    [CrmField.TYPE_NUMBER]: 0,
    [CrmField.TYPE_CURRENCY]: 0,
    [CrmField.TYPE_PICKLIST]: [] as string[],
    [CrmField.TYPE_BOOLEAN]: true,
    [CrmField.TYPE_USER]: "",
  };

  constructor(data: JSONAPIResourceOrRecord<"crm_fields">) {
    super(data);
    this.swType = (this.attributes.sw_type as number) || 0;
    this.crmLabel = (this.attributes.crm_label as string) || "";
    this.crmName = (this.attributes.crm_name as string) || "";
    this.crmOptions = this.attributes.crm_options || {};
    this.crmRecordType = (this.attributes.crm_record_type as string) || "";
    this.swRecordType = this.attributes.sw_record_type as SwRecordType;
  }

  get options() {
    if (this.swType === CrmField.TYPE_BOOLEAN) {
      return [
        {
          value: true,
          label: "True",
          isItalic: false,
        },
        {
          value: false,
          label: "False",
          isItalic: false,
        },
        blank,
      ];
    }
    if (!_.isEmpty(this.crmOptions)) {
      const options = _(this.crmOptions)
        .map((label: $TSFixMe, value: $TSFixMe) => ({
          label,
          value,
          isItalic: false,
        }))
        .sortBy("label")
        .value();
      options.push(blank);
      return options;
    }
    return [];
  }

  static getOperatorOptions(fieldType: SWQLFieldType, hasOptions: boolean) {
    let availableKeys = this.SWQL_OPERATORS[fieldType] || [];
    if (fieldType === CrmField.TYPE_TEXT && hasOptions) {
      availableKeys = [
        ...this.SWQL_OPERATORS[this.TYPE_PICKLIST],
        ...availableKeys,
      ];
    }
    const options = availableKeys.map((key) => ({
      value: key,
      label:
        (fieldType === CrmField.TYPE_DATE ||
          fieldType === CrmField.TYPE_DATETIME) &&
        key in SWQLOperator.DATE_TYPE_LABELS
          ? SWQLOperator.DATE_TYPE_LABELS[key]
          : SWQLOperator.TYPE_LABELS[key],
    }));
    return options;
  }

  static async swqlAutocomplete(
    value: string,
    integrationId: number,
    swqlTarget: SWQLTarget[],
    enableDateTimes: boolean,
    params = {},
    swTypes?: SWQLFieldType[]
  ) {
    const service = new JSONAPIService("crm_fields");
    const result = await service.index({
      ...params,
      filters: {
        ..._.get(params, "filters", {}),
        search: value,
        integration: integrationId,
        syncable: true,
        "sw_record_type.in": swqlTarget,
        "sw_type.in":
          swTypes ??
          (enableDateTimes
            ? CrmField.SWQL_SUPPORTED_FIELD_TYPES_WITH_DATES
            : CrmField.SWQL_SUPPORTED_FIELD_TYPES),
      },
    });
    return {
      records: _.map(result.data.data, (item) => new CrmField(item)),
      count: result.data.meta?.record_count || 0,
    };
  }
}

// Labels

const i18n = defineMessages({
  unknown: {
    id: "models.CrmField.unknown",
    defaultMessage: "unknown",
  },
  text: {
    id: "models.CrmField.text",
    defaultMessage: "text",
  },
  picklist: {
    id: "models.CrmField.picklist",
    defaultMessage: "picklist",
  },
  boolean: {
    id: "models.CrmField.boolean",
    defaultMessage: "boolean",
  },
  number: {
    id: "models.CrmField.number",
    defaultMessage: "number",
  },
  integer: {
    id: "models.CrmField.integer",
    defaultMessage: "integer",
  },
  date: {
    id: "models.CrmField.date",
    defaultMessage: "date",
  },
  datetime: {
    id: "models.CrmField.datetime",
    defaultMessage: "datetime",
  },
  currency: {
    id: "models.CrmField.currency",
    defaultMessage: "currency",
  },
  user: {
    id: "models.CrmField.user",
    defaultMessage: "CRM User",
  },
  singleChoicePicklist: {
    id: "models.CrmField.singleChoicePicklist",
    defaultMessage: "Single choice picklist",
  },
  referencesUser: {
    id: "models.CrmField.referencesUser",
    defaultMessage: "User reference",
  },
  referencesAccount: {
    id: "models.CrmField.referencesAccount",
    defaultMessage: "Account reference",
  },
  referencesOpportunity: {
    id: "models.CrmField.referencesOpportunity",
    defaultMessage: "Opportunity reference",
  },
  referencesContact: {
    id: "models.CrmField.referencesContact",
    defaultMessage: "Contact reference",
  },
  multiChoicePicklist: {
    id: "models.CrmField.multiChoicePicklist",
    defaultMessage: "Multi choice picklist",
  },
});

export const TYPE_LABELS = {
  [CrmField.TYPE_UNKNOWN]: <FormattedMessage {...i18n.unknown} />,
  [CrmField.TYPE_TEXT]: <FormattedMessage {...i18n.text} />,
  [CrmField.TYPE_PICKLIST]: <FormattedMessage {...i18n.picklist} />,
  [CrmField.TYPE_BOOLEAN]: <FormattedMessage {...i18n.boolean} />,
  [CrmField.TYPE_NUMBER]: <FormattedMessage {...i18n.number} />,
  [CrmField.TYPE_INTEGER]: <FormattedMessage {...i18n.integer} />,
  [CrmField.TYPE_DATE]: <FormattedMessage {...i18n.date} />,
  [CrmField.TYPE_DATETIME]: <FormattedMessage {...i18n.datetime} />,
  [CrmField.TYPE_CURRENCY]: <FormattedMessage {...i18n.currency} />,
  [CrmField.TYPE_USER]: <FormattedMessage {...i18n.user} />,
  [CrmField.TYPE_SINGLE_CHOICE_PICKLIST]: (
    <FormattedMessage {...i18n.singleChoicePicklist} />
  ),
  [CrmField.TYPE_REFERENCES_USER]: (
    <FormattedMessage {...i18n.referencesUser} />
  ),
  [CrmField.TYPE_REFERENCES_ACCOUNT]: (
    <FormattedMessage {...i18n.referencesAccount} />
  ),
  [CrmField.TYPE_REFERENCES_OPPORTUNITY]: (
    <FormattedMessage {...i18n.referencesOpportunity} />
  ),
  [CrmField.TYPE_REFERENCES_CONTACT]: (
    <FormattedMessage {...i18n.referencesContact} />
  ),
  [CrmField.TYPE_MULTI_CHOICE_PICKLIST]: (
    <FormattedMessage {...i18n.singleChoicePicklist} />
  ),
};
