import { Available360MappingColumns } from "redux/mapping360/defaults";

import { dateValueToLocaleDateStringFormatter } from "./DateValueToLocaleDateStringFormatter";

export const isDateIdentifiedOnColumn = (fieldName: string, row: any) => {
  return (
    row?.type === "nearbound_prospects" &&
    fieldName === Available360MappingColumns.createdAt
  );
};

export const dateIndentifiedOnFormatter = (value: any) => {
  return value ? dateValueToLocaleDateStringFormatter(new Date(value)) : "--";
};
