import { Box } from "@mui/material";
import Tag from "components/ui/Tag";
import { T } from "components/ui/Typography";
import { getTimeSince } from "helpers/dateUtils";
import { makeStyles } from "makeStyles";
import Partnership from "models/Partnership";
import { useIntl } from "react-intl";

type Props = {
  partnership: Partnership;
};

export const InviteMessagePreview = ({ partnership }: Props) => {
  const { classes } = useStyles();
  const intl = useIntl();
  const invite =
    !!partnership?.invites?.length &&
    partnership.invites[partnership.invites.length - 1];
  const invitedBy = invite?.createdByUser?.fullName;
  const message = invite?.message;

  if (!invitedBy || !message) {
    return null;
  }
  return (
    <Box className={classes.container}>
      <Box className={classes.titleContainer}>
        <T className={classes.title}>{invitedBy}</T>
        <T bodySmall className={classes.lightTitle}>
          {getTimeSince(invite.createdAt, intl)}
        </T>
      </Box>
      <Box className={classes.tagContainer}>
        <Tag label={message} />
      </Box>
    </Box>
  );
};

const useStyles = makeStyles()((theme) => ({
  container: {
    borderRadius: 8,
    width: "100%",
  },
  tagContainer: {
    "& .MuiChip-root": {
      borderRadius: "0 10px 10px 10px",
      background: theme.palette.greyscale200,
      height: "auto",
      padding: `${theme.spacing(2)} ${theme.spacing(1.25)}`,
      width: "100%",
    },
    "& .MuiChip-label": {
      whiteSpace: "normal",
      width: "100%",
    },
  },
  lightTitle: {
    color: theme.palette.alpha500,
    lineHeight: "22px",
  },
  title: {
    marginBottom: theme.spacing(1),
    fontSize: 14,
    fontWeight: 600,
    lineHeight: "21px",
  },
  titleContainer: {
    display: "flex",
    gap: 6,
  },
}));
