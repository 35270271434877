import { SvgIconComponent } from "@mui/icons-material";
import Dialog from "@mui/material/Dialog";
import { Breakpoint, ClassNameMap, useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import { Check } from "components/icons";
import Button from "components/ui/Button";
import generic from "i18n/generic";
import { makeStyles } from "makeStyles";
import { MouseEventHandler, ReactElement } from "react";
import { MessageDescriptor } from "react-intl";
import { useMergedClasses } from "tss-react";

type Variant = "primary" | "secondary" | "tertiary";

type StyleProps = {
  variant?: Variant;
  center?: boolean;
  width?: number | "auto";
};

type ActionProps =
  | {
      hideActions?: false | undefined;
      handleSave: () => void;
    }
  | {
      hideActions: true;
      handleSave?: () => void;
    };

type Props = Partial<StyleProps> & {
  title?: ReactElement;
  children?: ReactElement | ReactElement[];
  handleClose?: MouseEventHandler<HTMLDivElement>;
  isOpen: boolean;
  maxWidth?: Breakpoint;
  fullWidth?: boolean;
  fullScreenBreakpoint?: Breakpoint;
  saveButtonText?: MessageDescriptor | JSX.Element;
  saveButtonIcon?: SvgIconComponent;
  secondButtonText?: MessageDescriptor;
  disableSave?: boolean;
  loadingSave?: boolean;
  handleSecondButtonClick?: () => void;
  customActions?: ReactElement;
  classes?: ClassNameMap;
} & ActionProps;

const Popup = ({
  title,
  children,
  fullWidth = true,
  maxWidth = "md",
  fullScreenBreakpoint = "xs",
  variant = "primary",
  center = false,
  isOpen,
  handleClose,
  handleSave,
  saveButtonText,
  saveButtonIcon,
  secondButtonText,
  disableSave,
  loadingSave,
  hideActions,
  customActions,
  handleSecondButtonClick,
  width,
  classes: newClasses,
}: Props) => {
  const theme = useTheme();
  const { classes: baseClasses } = useStyles({ variant, center, width });
  const classes = useMergedClasses(baseClasses, newClasses);
  const fullScreen = useMediaQuery(
    theme.breakpoints.down(fullScreenBreakpoint)
  );

  const hasSecondButton = secondButtonText && handleSecondButtonClick;

  return (
    <Dialog
      open={isOpen}
      fullWidth={fullWidth}
      maxWidth={maxWidth}
      fullScreen={fullScreen}
      onClose={handleClose}
      classes={{ paper: classes.paper }}
    >
      <>
        {title && <span className={classes.titleContainer}>{title}</span>}
        <span className={classes.contentContainer}>{children}</span>
        {!hideActions && (
          <div className={classes.buttonsContainer}>
            {hasSecondButton && (
              <Button
                label={generic.cancel}
                onClick={handleClose}
                variant="tertiary"
              />
            )}
            <div className={classes.actionsContainer}>
              <Button
                label={hasSecondButton ? secondButtonText : generic.cancel}
                onClick={
                  hasSecondButton ? handleSecondButtonClick : handleClose
                }
                variant="secondary"
              />
              <Button
                LeftIcon={saveButtonIcon || Check}
                label={saveButtonText || generic.save}
                onClick={handleSave}
                disabled={disableSave}
                loading={loadingSave}
              />
            </div>
          </div>
        )}
        {hideActions && customActions && (
          <div className={classes.buttonsContainer}>{customActions}</div>
        )}
      </>
    </Dialog>
  );
};

export default Popup;

/// CSS

export const useStyles = makeStyles<StyleProps>()(
  (theme, { variant, center, width }) => ({
    paper: {
      background:
        variant === "primary"
          ? theme.palette.offWhite
          : variant === "tertiary"
          ? theme.palette.greyscale100
          : theme.palette.ivory,
      padding: theme.spacing(3),
      borderRadius: 12,
      ...(!!width ? { width } : {}),
    },
    titleContainer: {
      marginBottom: theme.spacing(2),
    },
    contentContainer: {
      maxHeight: "100%",
      overflowY: "auto",
      width: "100%",
      alignItems: center ? "center" : "normal",
      justifyContent: center ? "center" : "normal",
    },
    buttonsContainer: {
      display: "flex",
      justifyContent: "space-between",
      marginTop: theme.spacing(3),
    },
    actionsContainer: {
      display: "flex",
      gap: theme.spacing(1),
      width: "100%",
      alignItems: "center",
      justifyContent: center ? "center" : "flex-end",
    },
  })
);
