import Record from "./Record";

export type JSONSerializable =
  | null
  | string
  | number
  | boolean
  | Date
  | JSONSerializable[]
  | { [key: string]: JSONSerializable }
  | { data: JSONAPIIdentifier[] }
  | undefined;

export interface JSONAPIAttributes {
  [attributes: string]: JSONSerializable;
}

export interface JSONAPIIdentifier<T = string> {
  id: string;
  type: T;
}

export interface JSONAPIResource<T = string> extends JSONAPIIdentifier<T> {
  attributes?: JSONAPIAttributes;
  defaults?: JSONAPIAttributes;
  relationships?: JSONAPIRelationships;
}

export interface JSONAPIRelationships {
  [relation: string]: {
    data: JSONAPIResource | JSONAPIResource[] | Record | Record[] | null;
  };
}

export type JSONAPIResourceOrRecord<R = string> =
  | JSONAPIResource<R>
  | Record<R>;

/** Partnership Specializations */

interface Partnership_JSONAPIRelationships extends JSONAPIRelationships {
  initiator_company: {
    data: JSONAPIResource | Record;
  };
  dest_company: {
    data: JSONAPIResource | Record | null;
  };
}

export interface Partnership_JSONAPIResource extends JSONAPIResource {
  type: "partnerships";
  relationships: Partnership_JSONAPIRelationships;
}

/** Type Guard for Partnership JSONAPI Resources
 *
 * Instanciating a Partnership requires relationships, and include:
 *   - dest_company (possibly null)
 *   - initiator_company
 *
 * @param data: JSONAPIResource
 * @returns boolean
 */
export function isPartnership_JSONAPIResource(
  data: JSONAPIResource | Record
): data is Partnership_JSONAPIResource {
  return (
    data.relationships?.dest_company !== undefined &&
    data.relationships?.initiator_company !== undefined
  );
}

/** Integration Specializations */

export interface Integration_JSONAPIResource extends JSONAPIResource {
  type: "integrations";
  attributes: {
    provider: string;
    [attribute: string]: JSONSerializable;
  };
}

/** Type Guard for Integration JSONAPI Resources
 *
 * Instanciating av Integration requires attributes, and include:
 *   - provider (string)
 *
 * @param data: JSONAPIResource
 * @returns boolean
 */
export function isIntegration_JSONAPIResource(
  data: JSONAPIResource | Record
): data is Integration_JSONAPIResource {
  return typeof data.attributes?.provider === "string";
}

/** SuggestedPartner Specializations */

interface SuggestedPartner_JSONAPIRelationships extends JSONAPIRelationships {
  company: {
    data: JSONAPIResource | Record;
  };
  partner: {
    data: JSONAPIResource | Record | null;
  };
}

export interface SuggestedPartner_JSONAPIResource extends JSONAPIResource {
  type: "suggested_partners";
  relationships: SuggestedPartner_JSONAPIRelationships;
}

/** Type Guard for SuggestedPartner JSONAPI Resources
 *
 * Instanciating a SuggestedPartner requires relationships, and include:
 *   - company
 *   - partner (possibly null)
 *
 * @param data: JSONAPIResource
 * @returns boolean
 */
export function isSuggestedPartner_JSONAPIResource(
  data: JSONAPIResource | Record
): data is SuggestedPartner_JSONAPIResource {
  return (
    data.relationships?.company !== undefined &&
    data.relationships?.partner !== undefined
  );
}

export enum RecordType {
  PartnerConnection = "partner_connections",
  PipelineOpportunity = "attributed_opportunities",
}

export enum KNOWN_ERROR_STATES_FROM_OKTA {
  OKTA_SSO_ENABLED_ERROR = "OKTA_SSO_ENABLED_ERROR",
  OKTA_USER_DB_CREATION_ERROR = "OKTA_USER_DB_CREATION_ERROR",
}
