import {
  boolean,
  constant,
  guard,
  nullable,
  number,
  object,
  optional,
  string,
} from "decoders";
import { mapNullable } from "helpers/mapNullable";

import Record from "./Record";
import { JSONAPIResource } from "./types";

const apiDiscussionParticipantDecoder = object({
  type: constant("discussion_participants" as const),
  id: string,
  attributes: object({
    user_id: nullable(number),
    is_removed: boolean,
    is_deleted: boolean,
    full_name: optional(nullable(string)),
    avatar_url: optional(nullable(string)),
    company_name: optional(nullable(string)),
    company_avatar_url: optional(nullable(string)),
    partner_connection_id: nullable(number),
  }),
});

const apiDiscussionParticipantGuard = guard(apiDiscussionParticipantDecoder);

export default class DiscussionParticipant extends Record<"discussion_participants"> {
  id: number;
  userId: number | null;
  isRemoved: boolean;
  isDeleted: boolean;
  fullName?: string | null;
  avatarUrl?: string | null;
  companyName?: string | null;
  companyAvatarUrl?: string | null;
  partnerConnectionId: number | null;
  role: string | null;

  constructor(data: JSONAPIResource<"discussion_participants">) {
    super(data);
    const apiDiscussionParticipant = apiDiscussionParticipantGuard(data);
    const attrs = apiDiscussionParticipant.attributes;

    this.id = +apiDiscussionParticipant.id;
    this.userId = mapNullable(attrs.user_id, Number);
    this.isRemoved = attrs.is_removed;
    this.isDeleted = attrs.is_deleted;
    this.fullName = attrs.full_name;
    this.avatarUrl = attrs.avatar_url;
    this.companyName = attrs.company_name;
    this.companyAvatarUrl = attrs.company_avatar_url;
    this.partnerConnectionId = attrs.partner_connection_id;
    this.role = null;
  }
}

export const example = {
  type: "discussion_participants",
  id: "75",
  attributes: {
    user_id: 12,
    is_removed: false,
    is_deleted: false,
    full_name: "John Doe",
    avatar_url: "https://www.w3schools.com/howto/img_avatar2.png",
    partner_connection_id: 1234,
  },
};
