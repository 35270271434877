import Check from "@mui/icons-material/Check";
import Button from "components/ui/Button";
import DialogManager from "components/ui/DialogManager";
import { T } from "components/ui/Typography";
import generic from "i18n/generic";
import { defineMessages, FormattedMessage } from "react-intl";

type MyPipelineDeleteConfirmationDialogProps = {
  modalStyles: any;
  deleteConfirmationIsOpen: any;
  setDeleteConfirmationIsOpen: any;
  loading: any;
  handleBulkDeletion: any;
};

const MyPipelineDeleteConfirmationDialog = ({
  modalStyles,
  deleteConfirmationIsOpen,
  setDeleteConfirmationIsOpen,
  loading,
  handleBulkDeletion,
}: MyPipelineDeleteConfirmationDialogProps) => {
  return (
    <DialogManager
      modalStyle={modalStyles}
      fullScreenBreakpoint="sm"
      isOpen={deleteConfirmationIsOpen}
      handleClose={() => setDeleteConfirmationIsOpen(false)}
      DialogTitleComponent={
        <T h3>
          <FormattedMessage {...i18n.deleteConfirmationModalTitle} />
        </T>
      }
      DialogContentComponent={
        <T oldVariant="body2">
          <FormattedMessage {...i18n.deleteConfirmationModalContent} />
        </T>
      }
      DialogActionsComponent={
        <>
          <Button
            label={{ ...generic.cancel }}
            onClick={() => setDeleteConfirmationIsOpen(false)}
            variant="secondary"
            disabled={loading}
          />
          <Button
            LeftIcon={Check}
            label={{ ...i18n.deleteConfirmationModalConfirm }}
            onClick={handleBulkDeletion}
            loading={loading}
          />
        </>
      }
      data-testid={"MyPipelineDeleteConfirmationDialog"}
    />
  );
};

const i18n = defineMessages({
  deleteConfirmationModalContent: {
    id: "MyPipelineDeleteConfirmationDialog.deleteConfirmationModalContent",
    defaultMessage:
      "By deleting them you'll permanently lose track of past associated activities and opportunities in your reporting.",
  },
  deleteConfirmationModalTitle: {
    id: "MyPipelineDeleteConfirmationDialog.deleteConfirmationModalTitle",
    defaultMessage: "Are you sure you want to delete these Collaborations?",
  },
  deleteConfirmationModalConfirm: {
    id: "MyPipelineDeleteConfirmationDialog.deleteConfirmationModalConfirm",
    defaultMessage: "Yes, delete",
  },
});

export default MyPipelineDeleteConfirmationDialog;
