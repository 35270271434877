import axios, { AxiosResponse } from "axios";
import CompanyAvatar from "components/ui/avatars/CompanyAvatar";
import DropdownItem from "components/ui/Dropdown/components/DropdownItem";
import { getLabel, getLogoSrc } from "components/ui/Integration";
import { T } from "components/ui/Typography";
import usePushNotification from "hooks/usePushNotification";
import useUserProfile from "hooks/useUserProfile";
import _ from "lodash";
import { makeStyles } from "makeStyles";
import Integration from "models/Integration";
import Match from "models/Match";
import Record from "models/Record";
import { useCallback, useEffect, useRef, useState } from "react";
import { defineMessages, FormattedMessage } from "react-intl";
import { useHistory } from "react-router";
import {
  FilterType,
  MatchFilter,
} from "screens/Frontoffice/screens/DataTables/shared/types";
import { CancellablePromise } from "services/BaseHTTPService";
import JSONAPIService from "services/JSONAPIService";
import { JSONAPIListResponse } from "services/types";

const LIMIT_ACCOUNTS_TO_CRM_EXPORT = 5000;

export type AccountMappingExportingParameters = {
  partnership_id: number;
  columns: string[];
  sort: object[];
  filters: FilterType[];
};

type ExportCrmProps = {
  options: Record[];
  crmDisabled: boolean;
  parameters: AccountMappingExportingParameters;
  openModal: (integration: Integration) => void;
  numberOfRecordsToExport: number | null;
  setNumberOfRecordsToExport: (number: number) => void;
};

type ExportStatus = { [key: string]: boolean };

const ExportCrm = ({
  crmDisabled,
  options,
  parameters,
  openModal,
  numberOfRecordsToExport,
  setNumberOfRecordsToExport,
}: ExportCrmProps) => {
  const { classes } = useStyles();
  const { profile } = useUserProfile();
  const history = useHistory();
  const isCanceled = useRef(false);
  const [exportReadyStatus, setExportReadyStatus] = useState<ExportStatus>({});
  const [pollCount, setPollCount] = useState(0);
  const [numberToExportLoading, setNumberToExportLoading] = useState(false);
  const pushNotification = usePushNotification();
  const currentPromise = useRef<CancellablePromise<
    AxiosResponse<JSONAPIListResponse>
  > | null>(null);

  const filters = parameters.filters;
  const partnershipId = parameters.partnership_id;

  const verifyState = useCallback(
    async (
      optionsPending: Record<string>[],
      exportReadyStatus: ExportStatus,
      newPollCount?: number
    ) => {
      const service = new JSONAPIService("crm_exports");
      const responses = await Promise.all(
        optionsPending
          .map((item) => item.id)
          .map(async (id) => {
            const result = await service.rawPost("", "last/", {
              data: {
                type: "crm_exports", // required by backend
                attributes: {
                  export_type: 1,
                  parameters: {
                    filters: filters as [],
                  },
                },
                relationships: {
                  integration: {
                    data: {
                      id,
                      type: "integrations",
                    },
                  },
                  partnership: {
                    data: {
                      id: partnershipId,
                      type: "partnerships",
                    },
                  },
                },
              },
            });
            return result;
          })
      );
      if (!isCanceled.current) {
        newPollCount && setPollCount(newPollCount);
        setExportReadyStatus({
          ...exportReadyStatus,
          ...responses.reduce((acc, item: any, index) => {
            if (!item?.data) {
              return acc;
            }
            return {
              ...acc,
              [optionsPending[index].provider]:
                !item.data.data || !!item.data.data?.export_completed_at,
            };
          }, {}),
        });
      }
    },
    [parameters, setExportReadyStatus, setPollCount] // eslint-disable-line react-hooks/exhaustive-deps
  );

  useEffect(() => {
    isCanceled.current = false;
    verifyState(options, exportReadyStatus);
    return () => {
      isCanceled.current = true;
    };
  }, [options, verifyState]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    isCanceled.current = false;
    const optionsPending = options.filter(
      (item) => !exportReadyStatus[item.attributes?.provider as string]
    );
    if (optionsPending.length) {
      const timeout = setTimeout(
        () => verifyState(optionsPending, exportReadyStatus, pollCount + 1),
        5000
      );
      return () => {
        clearTimeout(timeout);
        isCanceled.current = true;
      };
    }
  }, [options, verifyState, pollCount, exportReadyStatus]);

  const countNumberMatchingRecords = useCallback(async () => {
    if (!crmDisabled) {
      setNumberToExportLoading(true);
      const apiFilters = MatchFilter.forAPI(filters);
      if (_.get(apiFilters, ["perspective.is"]) === Match.PERSPECTIVE_MINE) {
        setNumberOfRecordsToExport(0);
      } else {
        const service = new JSONAPIService("shared_accounts");
        try {
          currentPromise.current = service.index({
            // @ts-ignore
            filters: _.merge(apiFilters, {
              partnership: partnershipId,
              "perspective.is": Match.PERSPECTIVE_THEIRS,
            }),
            page: { number: 1, size: 1 },
          });
          const response = await currentPromise.current;
          setNumberOfRecordsToExport(response.data.meta?.record_count || 0);
        } catch (error) {
          if (!axios.isCancel(error)) {
            pushNotification("default_error");
          }
        }
      }
      setNumberToExportLoading(false);
    }
  }, [crmDisabled, JSON.stringify(filters), partnershipId, pushNotification]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    countNumberMatchingRecords();
    return () => {
      if (currentPromise.current && currentPromise.current.cancel) {
        currentPromise.current.cancel();
      }
    };
  }, [countNumberMatchingRecords]);

  const redirectToSources = () => {
    history.push("/sources/");
  };

  const isAboveLimit =
    (numberOfRecordsToExport || 0) > LIMIT_ACCOUNTS_TO_CRM_EXPORT;
  const noRecordsSelected = numberOfRecordsToExport === 0;

  const crmItems = options.map((integration, k) => {
    const disabledForIntegration = crmDisabled || integration.disabledAt;
    const isLoading =
      !disabledForIntegration &&
      (numberOfRecordsToExport === null ||
        numberToExportLoading ||
        !exportReadyStatus[integration.provider]);

    const provider = getLabel(integration as Integration);
    return (
      <DropdownItem
        key={k + 1}
        name={
          <>
            <T className={classes.title}>
              <CompanyAvatar
                src={getLogoSrc(integration as Integration)}
                size="xxs"
              />
              <FormattedMessage
                {...i18n.integrationLabel}
                values={{ integration: provider }}
              />
            </T>
          </>
        }
        isLoading={isLoading}
        isDisabled={
          disabledForIntegration ||
          isAboveLimit ||
          noRecordsSelected ||
          !exportReadyStatus[integration.provider]
        }
        tooltipMessage={
          crmDisabled ? (
            <T>
              <FormattedMessage
                {...i18n.limitedToCrm}
                values={{ crm: getLabel(integration as Integration) }}
              />
            </T>
          ) : integration.disabledAt ? (
            <T>
              <FormattedMessage
                {...i18n.integrationTooltip}
                values={{ integration: getLabel(integration as Integration) }}
              />
              {profile.canManageSources && (
                <>
                  &nbsp;
                  <span
                    className={classes.tooltipLink}
                    onClick={() => redirectToSources()}
                  >
                    <FormattedMessage {...i18n.here} />
                  </span>
                </>
              )}
            </T>
          ) : isAboveLimit ? (
            <T>
              <FormattedMessage
                {...i18n.exceedAccounts}
                values={{
                  number: LIMIT_ACCOUNTS_TO_CRM_EXPORT.toLocaleString("en"),
                }}
              />
            </T>
          ) : noRecordsSelected ? (
            <T>
              <FormattedMessage
                {...i18n.noAccountsSelected}
                values={{
                  provider: provider,
                }}
              />
            </T>
          ) : !exportReadyStatus[integration.provider] ? (
            <T>
              <FormattedMessage {...i18n.exportInProgress} />
            </T>
          ) : (
            ""
          )
        }
        onChange={() => openModal(integration as Integration)}
      />
    );
  });

  return <>{crmItems}</>;
};

export default ExportCrm;

/// CSS

const useStyles = makeStyles()((theme) => ({
  tooltipLink: {
    cursor: "pointer",
    textDecoration: "underline",
  },
  circularProgress: {
    marginLeft: 105,
  },
  title: {
    display: "flex",
    alignItems: "center",
    columnGap: 6,
  },
}));

// I18N

const i18n = defineMessages({
  export: {
    id: "crm.Reports.ExportCrm.export",
    defaultMessage: "Export",
  },
  exportInProgress: {
    id: "crm.Reports.ExportCrm.exportInProgress",
    defaultMessage: "The selected accounts are currently being exported",
  },
  integrationLabel: {
    id: "crm.Reports.ExportCrm.integrationLabel",
    defaultMessage: "Export accounts to {integration}",
  },
  integrationTooltip: {
    id: "crm.Reports.ExportCrm.integrationTooltip",
    defaultMessage: "Please connect {integration} as a data source",
  },
  here: {
    id: "crm.Reports.ExportCrm.here",
    defaultMessage: "here",
  },
  exceedAccounts: {
    id: "crm.Reports.ExportCrm.exceedAccounts",
    defaultMessage:
      "CRM Exports are limited to {number} at a time. Please update your selection.",
  },
  noAccountsSelected: {
    id: "crm.Reports.ExportCrm.noAccountsSelected",
    defaultMessage:
      "Please select accounts not yet in your CRM to export to {provider}.",
  },
  limitedToCrm: {
    id: "crm.Reports.ExportCrm.limitedToCrm",
    defaultMessage: "Please contact our team to enable exporting to {crm}.",
  },
});
