import { ChevronDown } from "components/icons";
import { TextInput } from "components/ui/TextInput";
import useAvailableCurrencies from "hooks/useAvailableCurrencies";
import React, { MouseEvent, useMemo, useState } from "react";
import { defineMessages, useIntl } from "react-intl";

import Button from "../../Button";
import Dropdown from "../../Dropdown/components/Dropdown";
import OperatorAddWidget from "../OperatorAddWidget";
import { SWQLBooleanOperator, SWQLRightValue } from "../SWQLTypes";

type Props = {
  currency?: string;
  onChange: (value: SWQLRightValue, extra?: object) => void;
  onAddNode: (operator: SWQLBooleanOperator) => void;
  amount?: number | null;
};

const CurrencyField = ({
  onAddNode,
  amount = null,
  currency = "USD",
  onChange,
}: Props) => {
  const availableCurrencies = useAvailableCurrencies();
  const intl = useIntl();
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const [open, setOpen] = useState(false);

  const handleCloseDropdown = () => {
    setAnchorEl(null);
    setOpen(false);
  };

  const defaultCurrency = "USD";
  const currencyOptions = availableCurrencies
    .sort()
    .map((currency: $TSFixMe) => {
      return { label: currency, value: currency };
    });

  const selectedCurrency = currency
    ? currencyOptions.find((item: $TSFixMe) => currency === item.value)
    : { label: defaultCurrency, value: defaultCurrency };

  const handleChangeValue = (event: $TSFixMe) => {
    if (event.target.validity.valid) {
      onChange({ amount: +event.target.value, currency_iso_code: currency });
    }
  };

  const handleChangeCurrency = (data: $TSFixMe) => {
    onChange({ amount: amount, currency_iso_code: data.toString() });
    handleCloseDropdown();
  };

  const loadOption = useMemo(() => {
    return currencyOptions.map((o: { label: string; value: string }) => {
      return {
        id: o.value,
        name: o.label,
      };
    });
  }, [currencyOptions]);

  return (
    <>
      <TextInput
        small
        numeric
        onChange={handleChangeValue}
        placeholder={intl.formatMessage(i18n.placeholder)}
        value={amount}
      />
      <Button
        variant="tertiary"
        label={selectedCurrency && selectedCurrency.label}
        size="small"
        RightIcon={ChevronDown}
        onClick={(event: MouseEvent<HTMLDivElement>) => {
          setOpen(true);
          setAnchorEl(event.currentTarget);
        }}
      />
      <Dropdown
        value={selectedCurrency && [selectedCurrency.value]}
        options={loadOption}
        anchorEl={anchorEl}
        onClose={handleCloseDropdown}
        open={open}
        size="small"
        onChange={handleChangeCurrency}
        data-testid="currency-select"
      />
      {Boolean(selectedCurrency && amount !== null) && (
        <OperatorAddWidget onAddNode={onAddNode} />
      )}
    </>
  );
};

export default CurrencyField;

const i18n = defineMessages({
  placeholder: {
    id: "SWQL.SwQLValueFields.CurrencyField.placeholder",
    defaultMessage: "Type a value…",
  },
});
