import { createReducer } from "@reduxjs/toolkit";
import {
  addCreatedPartner,
  transformSuggestion,
} from "redux/dashboard/actions";
import { logout } from "redux/user/thunks";

import { addCreatedTag, toggleSidebar } from "./actions";
import {
  fetchSettings,
  initializePartnershipsAndCompanyNames,
  initStore,
  loadAdminUsers,
  loadCompanyPayingFeatureSubscriptions,
  loadPartnershipsGetIntro,
  loadPartnershipTags,
  loadUsers,
} from "./thunks";
import { Context } from "./typing";

const initialState: Context = {
  initialized: false,
  error: false,
  foldedSidebar: false,
  settings: {
    terms_of_use_version: "",
  },
  companyPayingFeatureSubscriptionIds: [],
  ready: null,
  partnershipIds: [],
  partnershipTagIds: [],
  partnershipGetIntroIds: null,
  userIds: [],
  adminUserIds: [],
};

export default createReducer(initialState, (builder) =>
  builder
    .addCase(initStore.fulfilled, (draft) => {
      draft.initialized = true;
      draft.error = false;
      draft.foldedSidebar = false;
    })
    .addCase(fetchSettings.fulfilled, (draft, action) => {
      draft.settings = action.payload;
    })
    .addCase(initStore.rejected, (draft) => {
      draft.error = true;
    })
    .addCase(fetchSettings.rejected, (draft, action) => {
      draft.error = true;
    })
    .addCase(toggleSidebar, (state: Context) => {
      state.foldedSidebar = !state.foldedSidebar;
    })
    .addCase(
      loadCompanyPayingFeatureSubscriptions.fulfilled,
      (state, action) => {
        state.companyPayingFeatureSubscriptionIds =
          action.payload.companyPayingFeatureSubscriptionIds;
      }
    )
    .addCase(loadPartnershipTags.fulfilled, (state, action) => {
      state.partnershipTagIds = action.payload.partnershipTagIds;
    })
    .addCase(loadPartnershipsGetIntro.fulfilled, (state, action) => {
      state.partnershipGetIntroIds = action.payload.partnershipGetIntroIds;
    })
    .addCase(loadUsers.fulfilled, (state, action) => {
      state.userIds = action.payload.userIds;
    })
    .addCase(loadAdminUsers.fulfilled, (state, action) => {
      state.adminUserIds = action.payload.adminUserIds;
    })
    .addCase(addCreatedTag, (state, action) => {
      state.partnershipTagIds = [...state.partnershipTagIds, action.payload];
    })
    .addCase(initializePartnershipsAndCompanyNames.pending, (state) => {
      state.ready = false;
    })
    .addCase(initializePartnershipsAndCompanyNames.rejected, (state) => {
      state.ready = null;
    })
    .addCase(
      initializePartnershipsAndCompanyNames.fulfilled,
      (state, action) => {
        state.ready = true;
        state.partnershipIds = action.payload.partnershipIds;
      }
    )
    .addCase(addCreatedPartner, (state, action) => {
      state.partnershipIds = [...state.partnershipIds, action.payload.id];
    })
    .addCase(transformSuggestion, (state, action) => {
      state.partnershipIds = [
        ...state.partnershipIds,
        action.payload.partnershipId,
      ];
    })
    .addCase(logout.fulfilled, (state) => {
      state.ready = false;
      state.partnershipIds = [];
      state.userIds = [];
      state.adminUserIds = [];
    })
);
