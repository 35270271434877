import { AxiosResponse } from "axios";
import usePushNotification from "hooks/usePushNotification";
import { kindEncodeMapping } from "models/PartnerConnection";
import { JSONAPIAttributes, JSONAPIResource } from "models/types";
import { useEffect, useState } from "react";
import { defineMessages } from "react-intl";
import JSONAPIService from "services/JSONAPIService";
import { JSONAPIListResponse, JSONAPIResponse } from "services/types";

import { SharingLevel } from "../utils";

export const useOpportunitySharingLevelAttributionsApi = () => {
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [
    attributionDefaults,
    setAttributionDefaults,
  ] = useState<JSONAPIAttributes>({});
  const [isLoadingDefaults, setIsLoadingDefaults] = useState<boolean>(true);
  const [attributionRecords, setAttributionRecords] = useState<
    JSONAPIResource<"opportunity_sharing_level_attributions">[] | undefined
  >();
  const service = new JSONAPIService("opportunity_sharing_level_attributions");
  const pushNotification = usePushNotification();

  const loadAttributionRecords = async () => {
    let response:
      | AxiosResponse<
          JSONAPIListResponse<"opportunity_sharing_level_attributions">
        >
      | undefined;
    try {
      response = await service.rawGet("");
    } catch (error) {
      pushNotification(i18n.loadAttributionError);
    }
    setAttributionRecords(response?.data.data);
  };

  useEffect(() => {
    loadAttributionRecords();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const loadAttributionDefaults = async () => {
    let response: AxiosResponse<JSONAPIResponse> | undefined;
    try {
      response = await service.describe(true);
    } catch (error) {
      pushNotification(i18n.loadAttributionError);
      return;
    } finally {
      setIsLoadingDefaults(false);
    }
    setAttributionDefaults(response?.data.data?.defaults ?? {});
  };

  useEffect(() => {
    loadAttributionDefaults();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleUpdateAttributionDefaults = async (
    level: SharingLevel,
    kind: kindEncodeMapping
  ) => {
    const recordId = attributionRecords?.find(
      (record) => record.attributes?.discussion_type === kind
    )?.id;
    if (recordId) {
      await updateAttributionDefaults(level, kind);
    } else {
      await addAttributionDefaults(level, kind);
    }
  };

  const addAttributionDefaults = async (
    level: SharingLevel,
    kind: kindEncodeMapping
  ) => {
    setIsLoadingDefaults(true);
    let response:
      | AxiosResponse<JSONAPIResponse<"opportunity_sharing_level_attributions">>
      | undefined;
    try {
      response = await service.rawPost("", "", {
        data: {
          type: "opportunity_sharing_level_attributions",
          attributes: {
            level: level,
            discussion_type: kind,
          },
        },
      });
    } catch (error) {
      pushNotification(i18n.updateAttributionError);
      return;
    } finally {
      setIsLoadingDefaults(false);
    }
    setAttributionRecords(
      (prev) =>
        [
          ...(prev ?? []),
          ...[response?.data?.data ?? []],
        ] as JSONAPIResource<"opportunity_sharing_level_attributions">[]
    );
  };

  const updateAttributionDefaults = async (
    level: SharingLevel,
    kind: kindEncodeMapping
  ) => {
    const recordId = attributionRecords?.find(
      (record) => record.attributes?.discussion_type === kind
    )?.id;
    if (!recordId) {
      return;
    }
    setIsLoadingDefaults(true);
    let response:
      | AxiosResponse<JSONAPIResponse<"opportunity_sharing_level_attributions">>
      | undefined;
    try {
      response = await service.rawPatch(+recordId, "", {
        data: {
          id: recordId,
          type: "opportunity_sharing_level_attributions",
          attributes: {
            level: level,
          },
        },
      });
    } catch (error) {
      pushNotification(i18n.updateAttributionError);
      return;
    } finally {
      setIsLoadingDefaults(false);
    }
    setAttributionRecords(
      (prev) =>
        [
          ...(prev ?? []).filter((item) => item.id !== recordId),
          ...[response?.data?.data ?? []],
        ] as JSONAPIResource<"opportunity_sharing_level_attributions">[]
    );
  };

  const defaultSettings = {
    ...attributionDefaults,
    ...attributionRecords?.reduce((acc, record) => {
      acc[record.attributes?.discussion_type as kindEncodeMapping] = record
        .attributes?.level as SharingLevel;
      return acc;
    }, {} as Record<kindEncodeMapping, SharingLevel>),
    id: "default",
  };

  // TODO: adding per partner settings is not in the MVP
  // const addRow = () => {
  //   // TODO: add a new row to the data grid with a state flag
  // };

  return {
    addAttributionDefaults,
    handleUpdateAttributionDefaults,
    defaultSettings,
    isLoadingDefaults,
    isModalOpen,
    setIsModalOpen,
  };
};

const i18n = defineMessages({
  configure: {
    id:
      "CollaborateSettings.useOpportunitySharingLevelAttributionsApi.configure",
    defaultMessage: "Configure Settings",
  },
  defaultSetting: {
    id:
      "CollaborateSettings.useOpportunitySharingLevelAttributionsApi.defaultSetting",
    defaultMessage: "Default setting",
  },
  description: {
    id:
      "CollaborateSettings.useOpportunitySharingLevelAttributionsApi.description",
    defaultMessage:
      "Reveal allows to monitor how collaboration on your partners' deals convert into opportunities and wins.<br/>Symmetrically, define below how much you want to share with partners about the status of the opportunities you're collaborating on together.",
  },
  loadAttributionError: {
    id:
      "CollaborateSettings.useOpportunitySharingLevelAttributionsApi.loadAttributionError",
    defaultMessage: "Failed to load sharing settings",
  },
  updateAttributionError: {
    id:
      "CollaborateSettings.useOpportunitySharingLevelAttributionsApi.updateAttributionError",
    defaultMessage: "Failed to update sharing settings",
  },
  title: {
    id: "CollaborateSettings.useOpportunitySharingLevelAttributionsApi.title",
    defaultMessage: "Collaborate - Opportunity sharing",
  },
});
