import { ConfigContext } from "config/ConfigProvider";
import segment from "helpers/segment";
import useUserProfile from "hooks/useUserProfile";
import _ from "lodash";
import { useCallback, useContext, useMemo } from "react";

const trackProfile = {
  userId: "id",
  userRole: "roleAssignments[0].role",
  companyId: "company.id",
  companyName: "company.name",
  companyDomain: "company.domainName",
  companySize: "company.size",
  companyCreatedAt: "company.createdAt",
};

const useSegment = () => {
  const config = useContext(ConfigContext);
  const { profile } = useUserProfile();

  const defaultData = useMemo(() => {
    return _.reduce(
      trackProfile,
      (result, path: string, key: string) => {
        const value = _.get(profile, path, undefined);
        if (value) {
          result[key] = value;
        }
        return result;
      },
      {} as { [key: string]: any }
    );
  }, [profile]);

  const track = useCallback(
    (eventName: string, data: Object = {}) => {
      if (config.enableTracking) {
        segment.track(eventName, { ...defaultData, ...data });
      }
    },
    [config.enableTracking, defaultData]
  );

  return useMemo(() => ({ track }), [track]);
};

export default useSegment;
