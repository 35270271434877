import { createSvgIcon } from "@mui/material/utils";

import { SvgIcon } from "./types";

const displayName = "Dollar";

const Dollar: SvgIcon = createSvgIcon(
  // Using transform to enlarge from provided viewbox="0 0 16 16"
  // to default "0 0 24 24" from `createSvgIcon` -> `SvgIcon`.
  <g fill="none" transform="matrix(1.5,0,0,1.5,0,0)">
    <path
      d="M8 2V13.25"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M10.625 3.87499C9.125 3.11899 5 2.77924 5 5.37499C5 8.01874 11 6.87874 11 9.87499C11 12.4152 6.713 12.275 5 11.375"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </g>,
  displayName
);

Dollar.displayName = displayName;
export default Dollar;
