import { createSvgIcon } from "@mui/material/utils";

import { SvgIcon } from "./types";

const displayName = "Send";

const Send: SvgIcon = createSvgIcon(
  // Using transform to enlarge from provided viewbox="0 0 16 16"
  // to default "0 0 24 24" from `createSvgIcon` -> `SvgIcon`.
  <g fill="none" transform="matrix(1.5,0,0,1.5,0,0)">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M1.11985 3.95344C0.499626 2.09277 2.40163 0.390399 4.18242 1.2123L14.4842 5.96697C16.2186 6.76747 16.2186 9.23257 14.4841 10.0331L4.18242 14.7877C2.40163 15.6096 0.49963 13.9072 1.11985 12.0466L2.23268 8.7081C2.38588 8.24848 2.38589 7.75157 2.23268 7.29194L1.11985 3.95344ZM3.71325 2.22883L14.015 6.98349C14.8822 7.38374 14.8822 8.61629 14.015 9.01654L3.71326 13.7712C2.82286 14.1821 1.87186 13.3309 2.18197 12.4006L3.2948 9.06215C3.34972 8.89738 3.39152 8.72941 3.42019 8.55978H9.93252C10.2417 8.55978 10.4923 8.30915 10.4923 7.99999C10.4923 7.69083 10.2417 7.44021 9.93252 7.44021H3.42018C3.39151 7.2706 3.34971 7.10265 3.2948 6.93791L2.18196 3.5994C1.87185 2.66906 2.82286 1.81788 3.71325 2.22883Z"
      fill="currentColor"
    />
  </g>,
  displayName
);

Send.displayName = displayName;
export default Send;
