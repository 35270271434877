import { track } from "helpers/segment";
import { get } from "lodash";
import { useCallback, useEffect } from "react";
import { useDispatch } from "react-redux";
import { matchPath } from "react-router";
import { useHistory, useLocation } from "react-router-dom";
import {
  openWidget,
  toggleFree,
  togglePower,
  togglePro,
} from "redux/premiumPlanWidget/actions";
import { PremiumPlanEvent } from "tracking";

export enum PremiumFeatureSections {
  activate = "activate",
  scale = "scale",
}

export const OpenPremiumWidget = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const hasSectionParam = matchPath(location.pathname, "/premium/:section");
  const sectionToOpen: string = hasSectionParam
    ? get(hasSectionParam.params, "section").split("?")[0]
    : "";
  const isValidSection =
    sectionToOpen === PremiumFeatureSections.activate ||
    sectionToOpen === PremiumFeatureSections.scale;

  const open = useCallback(() => {
    if (isValidSection) {
      dispatch(toggleFree(false));
      dispatch(togglePro(sectionToOpen === PremiumFeatureSections.activate));
      dispatch(togglePower(sectionToOpen === PremiumFeatureSections.scale));
      dispatch(openWidget(true));
      track(PremiumPlanEvent.explorePlan, {
        from: "notify on OpenPremiumWidget redirection",
      });
    }
  }, [isValidSection, sectionToOpen, dispatch]);

  useEffect(() => {
    open();
    history.push("/partnerships");
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return null;
};
