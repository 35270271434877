import { PushPin } from "@mui/icons-material";
import { Box, Chip, Grid } from "@mui/material";
import { Check, Edit, TrashThin } from "components/icons";
import AccountAvatar from "components/ui/avatars/AccountAvatar";
import Button from "components/ui/Button";
import { Facet } from "components/ui/Facet";
import Tooltip from "components/ui/Tooltip";
import { T } from "components/ui/Typography";
import useSegment from "hooks/useSegment";
import { useUser } from "hooks/useUser";
import useUserProfile from "hooks/useUserProfile";
import { makeStyles } from "makeStyles";
import { PayingFeature } from "models/CompanyPayingFeatureSubscription";
import SavedFilter, { SavedFilterType } from "models/SavedFilter";
import { useState } from "react";
import { defineMessages, FormattedMessage, useIntl } from "react-intl";
import { useSelector } from "react-redux";
import { AccountMappingResource } from "redux/accountMapping/types";
import { selectActivePayingFeatures } from "redux/api/selectors";
import {
  allLeads as oldLeads,
  allOverlaps as oldOverlaps,
  unPinnableFilters as oldUnpinnableFilters,
} from "screens/Frontoffice/screens/DataTables/screens/AccountMapping/shared/oldQuickFilterPresets";
import {
  allLeads,
  allOverlaps,
  unPinnableFilters,
} from "screens/Frontoffice/screens/DataTables/screens/AccountMapping/shared/quickFilterPresets";
import {
  Facets,
  Preset,
} from "screens/Frontoffice/screens/DataTables/shared/types";

import {
  ExtendedAccountMappingResource,
  SAVED_FILTER_TYPE_TO_DISPLAY_LABEL,
  SmartViewSegmentEvent,
  ThreeSixty,
} from "./constants";
import SmartViewCreateDialog from "./SmartViewCreateDialog";
import SmartViewDeleteDialog from "./SmartViewDeleteDialog";

type Props = {
  applyPreset: (
    preset: Preset,
    viewType: ExtendedAccountMappingResource
  ) => void;
  facets?: Facets;
  isPinned?: boolean;
  onSelect: (id: number) => void;
  savedFilter: SavedFilter;
  selectedFilterId?: number;
  onOrderChange: (newIndex?: number) => void;
  accountType: ExtendedAccountMappingResource;
  partnershipId?: number;
  onAddSmartView: () => void;
};

export const SmartViewDropdownListItem = ({
  applyPreset,
  facets,
  isPinned = false,
  onOrderChange,
  onSelect,
  savedFilter,
  selectedFilterId,
  accountType,
  onAddSmartView,
  partnershipId,
}: Props) => {
  const { classes, cx } = useStyles({ viewType: savedFilter.viewType });
  const { track } = useSegment();
  const intl = useIntl();
  const user = useUser({ id: savedFilter?.userId });
  const { profile } = useUserProfile();
  const payingFeatures = useSelector(selectActivePayingFeatures);
  const isMatchedAccount = payingFeatures.includes(
    PayingFeature.UseNewAccountMapping
  );

  const [isCreateDialogOpen, setIsCreateDialogOpen] = useState(false);
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);

  const hasPermission = savedFilter.userId === profile.id;

  const handleEvent = (event: React.MouseEvent) => {
    event.stopPropagation();
    event.preventDefault();
  };

  const handlePin = (event: React.MouseEvent) => {
    handleEvent(event);
    onOrderChange?.(isPinned ? undefined : 0);
  };
  const handleSetFilter = (event: React.MouseEvent) => {
    handleEvent(event);
    applyPreset(
      {
        filter: savedFilter.filters,
        sort: savedFilter.sort,
      },
      savedFilter.viewType === SavedFilterType.NewAccountMapping
        ? AccountMappingResource.matched_accounts
        : savedFilter.viewType === SavedFilterType.AccountMapping
        ? AccountMappingResource.matches
        : savedFilter.viewType === SavedFilterType.LeadOverlaps
        ? AccountMappingResource.leadMatches
        : savedFilter.viewType === SavedFilterType.NewProspects
        ? AccountMappingResource.shared_accounts
        : savedFilter.viewType === SavedFilterType.MyEcosystem
        ? ThreeSixty.threeSixty
        : savedFilter.viewType === SavedFilterType.NearboundAccounts
        ? ThreeSixty.nearboundAccounts
        : ThreeSixty.nearboundProspects
    );
    onSelect(savedFilter.id);
  };

  const handleClickEdit = (event: React.MouseEvent) => {
    handleEvent(event);
    track(SmartViewSegmentEvent.SaveViewClicked, {
      isPaidUser: profile.isPaidCustomer(payingFeatures),
    });
    setIsCreateDialogOpen(true);
  };

  const handleClickDelete = (event: React.MouseEvent) => {
    handleEvent(event);
    setIsDeleteDialogOpen(true);
  };

  const isSelected = selectedFilterId === savedFilter.id;
  const label = SAVED_FILTER_TYPE_TO_DISPLAY_LABEL?.[savedFilter.viewType];
  const isUnPinnable = (isMatchedAccount
    ? unPinnableFilters
    : oldUnpinnableFilters
  ).some((item) => Number(item.key) === savedFilter.id);

  return (
    <>
      <Grid item container className={classes.root} onClick={handleSetFilter}>
        <Tooltip
          title={
            isUnPinnable ? (
              <T>
                <FormattedMessage {...i18n.pinNotAllowed} />
              </T>
            ) : undefined
          }
        >
          <Box
            className={cx(classes.iconContainer, {
              [classes.notAllowed]: isUnPinnable,
            })}
            onClick={handlePin}
          >
            {isPinned && <PushPin />}
          </Box>
        </Tooltip>
        <Box>
          <T className={cx({ [classes.bold]: isSelected })}>
            {savedFilter.name}
          </T>
        </Box>
        {typeof facets?.[savedFilter.id] === "number" && (
          <Facet
            value={facets[savedFilter.id]}
            valueWithPrivate={
              savedFilter.id ===
              (isMatchedAccount ? allOverlaps.key : oldOverlaps.key)
                ? facets["allMatchesWithPrivate"]
                : savedFilter.id ===
                  (isMatchedAccount ? allLeads.key : oldLeads.key)
                ? facets["allNewLeadsWithPrivate"]
                : undefined
            }
            isMatches={
              savedFilter.id ===
              (isMatchedAccount ? allOverlaps.key : oldOverlaps.key)
            }
          />
        )}
        {user && <AccountAvatar user={user} size="xxs" />}
        <Box
          className={cx(classes.iconContainer, classes.iconContainerWithButton)}
        >
          {isSelected && <Check />}
          {hasPermission && (
            <span className={classes.hoverable}>
              <Button
                LeftIcon={Edit}
                onClick={handleClickEdit}
                size="xxxSmall"
                variant="quinary"
              />
              <Button
                LeftIcon={TrashThin}
                onClick={handleClickDelete}
                size="xxxSmall"
                variant="quinary"
                color="error"
              />
            </span>
          )}
        </Box>
        <Box className={classes.avatarContainer}>
          {label && (
            <Chip className={classes.chip} label={intl.formatMessage(label)} />
          )}
        </Box>
        {!isPinned && (
          <Box className={classes.hoverable}>
            <Button
              LeftIcon={PushPin}
              label={i18n.pinIt}
              onClick={handlePin}
              size="xxSmall"
              variant="tertiary"
            />
          </Box>
        )}
      </Grid>
      {isCreateDialogOpen && (
        <SmartViewCreateDialog
          accountType={accountType}
          isOpen={isCreateDialogOpen}
          onAddSmartView={onAddSmartView}
          onClose={() => setIsCreateDialogOpen(false)}
          partnershipId={partnershipId}
          selectedFilterId={savedFilter.id}
          isEditingName
        />
      )}
      {isDeleteDialogOpen && (
        <SmartViewDeleteDialog
          accountType={accountType}
          isOpen={isDeleteDialogOpen}
          onClose={() => setIsDeleteDialogOpen(false)}
          selectedFilterId={savedFilter.id}
        />
      )}
    </>
  );
};

const useStyles = makeStyles<{ viewType?: SavedFilterType }, "hoverable">()(
  (theme, { viewType }, classes) => ({
    avatarContainer: {
      display: "flex",
      marginLeft: "auto",
    },
    bold: {
      fontWeight: 600,
    },
    chip: {
      backgroundColor:
        viewType === SavedFilterType.AccountMapping ||
        viewType === SavedFilterType.NewAccountMapping
          ? theme.palette.brown100
          : viewType === SavedFilterType.LeadOverlaps
          ? theme.palette.teal100
          : theme.palette.olive100,
      height: 20,
      marginLeft: theme.spacing(4),
      borderRadius: 4,
      padding: "5px 6px",
      "& .MuiChip-label": {
        color:
          viewType === SavedFilterType.AccountMapping ||
          viewType === SavedFilterType.NewAccountMapping
            ? theme.palette.brown700
            : viewType === SavedFilterType.LeadOverlaps
            ? theme.palette.teal700
            : theme.palette.olive700,
        fontSize: 10,
        fontWeight: 500,
        lineHeight: "10px",
        padding: 0,
      },
    },
    iconContainer: {
      display: "flex",
      alignItems: "center",
      height: 16,
      "& > svg": {
        height: 16,
        width: 16,
      },
    },
    iconContainerWithButton: {
      "& > svg, & button": {
        marginRight: theme.spacing(0.5),
      },
    },
    notAllowed: {
      cursor: "not-allowed !important",
    },
    hoverable: {
      display: "none",
    },
    root: {
      height: 32,
      padding: "0px 6px",
      alignItems: "center",
      gap: theme.spacing(1),
      minWidth: 400,
      "&:hover": {
        backgroundColor: theme.palette.greyscale100,
        cursor: "pointer",

        [`& .${classes.hoverable}`]: {
          display: "flex",
        },
      },
    },
  })
);

const i18n = defineMessages({
  pinIt: {
    defaultMessage: "Pin it",
    id: "SmartViewDropdown.pinIt",
  },
  pinNotAllowed: {
    defaultMessage: "You can't unpin default views",
    id: "SmartViewDropdown.pinNotAllowed",
  },
});
