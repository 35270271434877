import { Box } from "@mui/material";
import { Info, Lock } from "components/icons";
import Toggle from "components/ui/Toggle";
import Tooltip from "components/ui/Tooltip";
import { T } from "components/ui/Typography";
import useSegment from "hooks/useSegment";
import useUserProfile from "hooks/useUserProfile";
import { makeStyles } from "makeStyles";
import { PayingFeature } from "models/CompanyPayingFeatureSubscription";
import {
  ChangeEvent,
  ReactElement,
  useCallback,
  useEffect,
  useState,
} from "react";
import { defineMessages, FormattedMessage, useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import {
  selectHasOnlyNewUpdatesSince,
  selectOnlyNewUpdatesSinceFieldname,
} from "redux/accountMapping/selector";
import { selectActivePayingFeatures } from "redux/api/selectors";
import {
  openWidget,
  toggleFree,
  togglePower,
  togglePro,
} from "redux/premiumPlanWidget/actions";
import {
  FilterType,
  MatchFilterType,
} from "screens/Frontoffice/screens/DataTables/shared/types";
import { AccountMappingEvent, PremiumPlanEvent } from "tracking";

import {
  DisplaySinceFilterFieldname,
  SinceOption,
} from "../../../shared/types";
import { getDisplaySinceFilter, getFiltersWithoutDisplaySince } from "../utils";
import { DisplaySinceDropdown, Option } from "./DisplaySinceDropdown";

type Props = {
  isOverlapView: boolean;
  filters: FilterType[];
  onToggleChange: (filters: FilterType[]) => void;
  isDemo: boolean;
};

const DisplayOnlyNewUpdatesSince = ({
  isOverlapView,
  filters,
  onToggleChange,
  isDemo = false,
}: Props) => {
  const { classes, cx } = useStyles();
  const intl = useIntl();
  const dispatch = useDispatch();
  const { track } = useSegment();
  const { profile } = useUserProfile();
  const hasOnlyNewUpdatesSince = useSelector(selectHasOnlyNewUpdatesSince);
  const displaySinceFilterFieldname = useSelector(
    selectOnlyNewUpdatesSinceFieldname
  ) as DisplaySinceFilterFieldname;
  const [selectedSince, setSelectedSince] = useState<SinceOption>(
    SinceOption.LastVisit
  );
  const payingFeatures = useSelector(selectActivePayingFeatures);

  // Filter is only for Pro+, but some legacy users have a free plan with many Pro feature activated.
  // So instead of checking their plan (free, pro or premium), we check this specific feature instead.
  const isFreemiumUser = !payingFeatures.includes(
    PayingFeature.FilteringUpdatesAccountMapping
  );

  const sinceOptions: Option[] = Object.values(SinceOption).map(
    (sinceOption) => ({
      value: sinceOption,
      label: i18n[sinceOption as keyof typeof i18n],
    })
  ); // Example of sinceOption mapped: sinceOptions[0] === { value : "lastVisit", label: i18n["lastVisit"] }

  const filtersWithoutDisplaySince = getFiltersWithoutDisplaySince(filters);
  const filtersWithDisplaySince = (sinceOption: SinceOption) => [
    ...filtersWithoutDisplaySince,
    {
      fieldname: displaySinceFilterFieldname,
      type: MatchFilterType.GTE,
      value: sinceOption,
    },
  ];

  const handleChangeToggle = (
    event: ChangeEvent<HTMLInputElement>,
    checked: boolean
  ) => {
    onToggleChange(
      checked
        ? filtersWithDisplaySince(selectedSince)
        : filtersWithoutDisplaySince
    );

    if (checked) {
      track(AccountMappingEvent.filteredUpdatesAM, {
        companyId: profile.companyId,
        userId: profile.id,
        timeStamp: new Date(),
      });
    }
  };

  const handleChangeValue = (option: Option) => {
    setSelectedSince(option.value);
    hasOnlyNewUpdatesSince &&
      onToggleChange(filtersWithDisplaySince(option.value));
  };

  const openPremiumWidget = useCallback(() => {
    dispatch(toggleFree(false));
    dispatch(togglePro(true));
    dispatch(togglePower(true));
    dispatch(openWidget(true));
    track(PremiumPlanEvent.explorePlan, {
      from: '"Display only new updates since" filter',
    });
  }, [dispatch, track]);

  useEffect(() => {
    if (hasOnlyNewUpdatesSince) {
      setSelectedSince(getDisplaySinceFilter(filters).value);
    }
  }, [hasOnlyNewUpdatesSince, filters]);

  const withRightInfoTooltip = (children: ReactElement, isShown: boolean) =>
    isShown ? (
      <Tooltip
        title={intl.formatMessage(
          i18n[isOverlapView ? "tooltipOverlap" : "tooltipNotOverlap"]
        )}
      >
        {children}
      </Tooltip>
    ) : (
      children
    );

  return withRightInfoTooltip(
    <Box
      className={cx(classes.box, {
        [classes.disabled]: !isOverlapView || isFreemiumUser || isDemo,
      })}
    >
      {isFreemiumUser || isDemo ? (
        <Tooltip
          title={
            <FormattedMessage
              {...i18n[isDemo ? "demoTooltip" : "tooltipLock"]}
              values={{
                btn: (chunks: string) => (
                  <span
                    className={classes.premiumWidgetBtn}
                    onClick={() => openPremiumWidget()}
                  >
                    {chunks}
                  </span>
                ),
              }}
            />
          }
        >
          <Lock sx={{ fontSize: 16 }} />
        </Tooltip>
      ) : (
        <Toggle
          checked={hasOnlyNewUpdatesSince}
          handleChange={handleChangeToggle}
          disabled={!isOverlapView}
        />
      )}

      <T>
        <FormattedMessage {...i18n.displayOnlyNewUpdatesSince} />
      </T>
      <DisplaySinceDropdown
        options={sinceOptions}
        onChange={handleChangeValue}
        value={selectedSince}
        isDisabled={!isOverlapView || isFreemiumUser || isDemo}
      />
      {withRightInfoTooltip(
        <Info className={classes.infoIcon} data-testid="info-icon" />,
        isOverlapView || (!isOverlapView && isFreemiumUser)
      )}
    </Box>,
    !isOverlapView && !isFreemiumUser
  );
};

export default DisplayOnlyNewUpdatesSince;

/// CSS

const useStyles = makeStyles()((theme) => ({
  box: {
    display: "flex",
    alignItems: "center",
    gap: "6px",
    width: "fit-content", // To ensure tooltip is above filter if !isOverlapView
    borderBottom: "1px solid transparent", // avoid flickering when the dropwdown add a black bottom border when hovered
  },
  disabled: {
    color: theme.palette.text.disabled,
  },
  infoIcon: {
    color: theme.palette.midnight,
    fontSize: 16,
  },
  buttonInactivate: {
    opacity: 0.6,
  },
  buttonActivate: {
    opacity: 1.0,
  },
  premiumWidgetBtn: {
    textDecoration: "underline",
    cursor: "pointer",
  },
}));

/// I18N

export const i18n = defineMessages({
  displayOnlyNewUpdatesSince: {
    id: "crm.AccountMapping.displayOnlyNewUpdatesSince.label",
    defaultMessage: "Display only new updates since",
  },
  tooltipLock: {
    id: "crm.AccountMapping.displayOnlyNewUpdatesSince.tooltip.lock",
    defaultMessage: "This is a pro feature. <btn>Upgrade to unlock it</btn>.",
  },
  demoTooltip: {
    id: "crm.AccountMapping.displayOnlyNewUpdatesSince.tooltip.demo",
    defaultMessage: "Not available for the Demo partner",
  },
  tooltipOverlap: {
    id: "crm.AccountMapping.displayOnlyNewUpdatesSince.tooltip.overlap",
    defaultMessage:
      "This toggle filters overlaps to identify accounts that were updated in the specified timeframe",
  },
  tooltipNotOverlap: {
    id: "crm.AccountMapping.displayOnlyNewUpdatesSince.tooltip.notOverlap",
    defaultMessage: "This feature is available only for Overlaps type of views",
  },
  lastVisit: {
    id:
      "crm.AccountMapping.displayOnlyNewUpdatesSince.dropdown.option.lastVisit",
    defaultMessage: "last visit",
  },
  last7Days: {
    id:
      "crm.AccountMapping.displayOnlyNewUpdatesSince.dropdown.option.last7Days",
    defaultMessage: "last 7 days",
  },
  last15Days: {
    id:
      "crm.AccountMapping.displayOnlyNewUpdatesSince.dropdown.option.last15Days",
    defaultMessage: "last 15 days",
  },
  last30Days: {
    id:
      "crm.AccountMapping.displayOnlyNewUpdatesSince.dropdown.option.last30Days",
    defaultMessage: "last 30 days",
  },
  last3Months: {
    id:
      "crm.AccountMapping.displayOnlyNewUpdatesSince.dropdown.option.last3Months",
    defaultMessage: "last 3 months",
  },
  last6Months: {
    id:
      "crm.AccountMapping.displayOnlyNewUpdatesSince.dropdown.option.last6Months",
    defaultMessage: "last 6 months",
  },
});
