import {
  createContext,
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";

import {
  emptyNode,
  FieldId,
  isSWQLBooleanNode,
  SWQLField,
  SWQLNode,
  SWQLTarget,
} from "./SWQLTypes";

// PathToNode:
//
// This path encoding is as simple as it gets: starting from the root node
// we can traverse based on the children index.
//
// e.g. Given the following tree
//
//   root
//   |    \
//   A     B
//   |   / | \
//   C  D  E  F
//            |
//            G
//
// we have the following encodings:
//
//   root: []
//      A: [0]
//      B: [1]
//      C: [0, 0]
//      D: [1, 0]
//      E: [1, 1]
//      F: [1, 2]
//      G: [1, 2, 0]
//
export type PathToNode = number[];

export type SWQLContext = {
  integrationId: number | string;
  enableDateTimes: boolean;
  swqlTarget: SWQLTarget[];
  addField: (field: SWQLField) => void;
  getField: (fieldId: FieldId) => SWQLField | undefined;
  getNode: (path: PathToNode) => SWQLNode;
  getParentNode: (path: PathToNode) => SWQLNode | undefined;
  updateNode: (path: PathToNode, newNode: SWQLNode) => void;
  deleteNode: (path: PathToNode) => void;
  fields: SWQLField[];
};

// N.B. This is not exported on purpose, so that the only way to access this
// context is through the provided hook, generating a throw whenever misused.
const swqlContext = createContext<SWQLContext | undefined>(undefined);

export function useSWQLContext(): SWQLContext {
  const context = useContext(swqlContext);

  if (context === undefined) {
    throw new Error("useSWQLContext must be used within a SWQLContextProvider");
  }

  return context;
}

enum Internal {
  DeleteNode = "__INTERNAL_SWQL_CONTEXT_DELETE_NODE",
}

export type SWQLContextProps = {
  rootNode: SWQLNode;
  integrationId: number | string;
  enableDateTimes: boolean;
  fields: SWQLField[];
  onRootUpdate: (node: SWQLNode, extraFields?: SWQLField[]) => void;
  children: ReactNode;
  swqlTarget: SWQLTarget[];
};

export const SWQLContextProvider = ({
  rootNode: initialRootNode = emptyNode(),
  children,
  fields = [] as SWQLField[],
  onRootUpdate,
  integrationId,
  swqlTarget,
  enableDateTimes,
}: SWQLContextProps) => {
  const [extraFields, setExtraFields] = useState([] as SWQLField[]);
  const allFields = useMemo(() => [...fields, ...extraFields], [
    fields,
    extraFields,
  ]);
  const [nodeTree, setNodeTree] = useState(initialRootNode);

  useEffect(() => {
    setNodeTree(initialRootNode);
  }, [initialRootNode]);

  const addField = (field: SWQLField) => {
    if (!allFields.find((item) => item.id === field.id)) {
      setExtraFields((value) => [...value, field]);
    }
  };

  const getField = (fieldId: FieldId): SWQLField | undefined => {
    return allFields.find(
      (field) => field.id.toString() === fieldId.toString()
    );
  };

  const getNode = useCallback(
    (path: PathToNode): SWQLNode => {
      return getNodeRecursive(nodeTree, path);
    },
    [nodeTree]
  );

  const getParentNode = useCallback(
    (path: PathToNode): SWQLNode | undefined => {
      if (path.length === 0) {
        return undefined;
      }

      const pathToParent = [...path];
      pathToParent.pop();

      return getNode(pathToParent);
    },
    [getNode]
  );

  const updateNode = useCallback(
    (path: PathToNode, newNode: SWQLNode) => {
      const updatedTree = updateNodeRecursive(nodeTree, path, newNode);

      setNodeTree(updatedTree);

      if (updatedTree !== nodeTree) {
        onRootUpdate(updatedTree, extraFields);
      }
    },
    [extraFields, nodeTree, onRootUpdate]
  );

  const deleteNode = useCallback(
    (path: PathToNode) => {
      const updatedTree = updateNodeRecursive(
        nodeTree,
        path,
        Internal.DeleteNode
      );

      setNodeTree(updatedTree);

      if (updatedTree !== nodeTree) {
        onRootUpdate(updatedTree, extraFields);
      }
    },
    [extraFields, nodeTree, onRootUpdate]
  );

  const contextValue: SWQLContext = {
    integrationId,
    enableDateTimes,
    swqlTarget,
    addField,
    getField,
    getNode,
    getParentNode,
    updateNode,
    deleteNode,
    fields: allFields,
  };

  return (
    <swqlContext.Provider value={contextValue}>{children}</swqlContext.Provider>
  );
};

// Helper

const getNodeRecursive = (node: SWQLNode, path: PathToNode): SWQLNode => {
  if (path.length === 0) {
    return node;
  }

  if (!isSWQLBooleanNode(node)) {
    throw Error("Tried to recurse in a non boolean SWQLNode.");
  }

  const remainingPath = [...path];
  // We can cast here because we test above for path length.
  const index = remainingPath.shift() as number;
  return getNodeRecursive(node.args[index], remainingPath);
};

const updateNodeRecursive = (
  currentNode: SWQLNode,
  path: PathToNode,
  newNode: SWQLNode | Internal.DeleteNode
): SWQLNode => {
  if (path.length === 0) {
    if (newNode === Internal.DeleteNode) {
      // Only happens with root node.
      return emptyNode();
    }

    return newNode;
  }

  if (!isSWQLBooleanNode(currentNode)) {
    throw Error("Tried to recurse in a non boolean SWQLNode.");
  }

  if (path.length === 1 && newNode === Internal.DeleteNode) {
    const [index] = path;
    const newChildrenNodes = [...currentNode.args];
    newChildrenNodes.splice(index, 1);
    // If there is only one node, it change from a type "boolean_op" to "operation"
    if (newChildrenNodes.length === 1) {
      return newChildrenNodes[0];
    }
    const newNode = {
      ...currentNode,
      args: newChildrenNodes,
    };

    return newNode;
  }

  const remainingPath = [...path];
  const [index] = remainingPath.splice(0, 1);

  const newChildNode = updateNodeRecursive(
    currentNode.args[index],
    remainingPath,
    newNode
  );

  const newArgs = [...currentNode.args];
  newArgs.splice(index, 1, newChildNode);

  return {
    ...currentNode,
    args: newArgs,
  };
};
