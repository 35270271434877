import { Check, Plus, TrashThin } from "components/icons";
import Button from "components/ui/Button";
import { T } from "components/ui/Typography";
import { Field, FieldArray, Form, Formik } from "formik";
import generic from "i18n/generic";
import { makeStyles } from "makeStyles";
import { IQuestionForm } from "models/IntroductionRequestForm";
import { defineMessages } from "react-intl";
import * as Yup from "yup";

import Question from "./Question";

type Props = {
  values: IQuestionForm | null;
  onClose: () => void;
  onSave: (values: IQuestionForm) => void;
};

const VALIDATION_SCHEMA = Yup.object().shape({
  questions: Yup.array()
    .of(
      Yup.object().shape({
        label: Yup.string().required("Required"),
      })
    )
    .required("Must have questions")
    .min(1, "Minimum of 1 question")
    .max(20, "Maximum of 20 questions"),
});

const QuestionForm = ({ values, onClose, onSave }: Props) => {
  const { classes } = useStyles();

  const initialValues: IQuestionForm = {
    questions: [
      {
        label: "",
      },
    ],
  };

  return (
    <Formik
      validationSchema={VALIDATION_SCHEMA}
      initialValues={values ?? initialValues}
      onSubmit={async (values) => {
        onSave(values);
        onClose();
      }}
    >
      {({ values, handleSubmit }) => (
        <Form style={{ height: "100%" }}>
          <FieldArray name="questions">
            {({ remove, push }) => (
              <div className={classes.root}>
                <div className={classes.form}>
                  {values.questions?.map((question, index) => {
                    const hideTrash = values.questions.length === 1;
                    return (
                      <div className={classes.row} key={index}>
                        <T className={classes.number}>{index + 1}</T>
                        <Field
                          index={index}
                          name={`questions.${index}.label`}
                          component={Question}
                        />
                        {!hideTrash && (
                          <TrashThin
                            className={classes.trashIcon}
                            onClick={() => remove(index)}
                          />
                        )}
                      </div>
                    );
                  })}
                  {values.questions.length < 20 && (
                    <Button
                      LeftIcon={Plus}
                      label={i18n.addQuestion}
                      classes={{ btn: classes.addButton }}
                      onClick={() => push({ label: "" })}
                      data-testid="add-question"
                      variant={"tertiary"}
                    />
                  )}
                </div>
                <div className={classes.buttons}>
                  <Button
                    label={generic.cancel}
                    variant="secondary"
                    onClick={onClose}
                  />
                  <Button
                    label={generic.save}
                    disabled={false}
                    onClick={handleSubmit}
                    loading={false}
                    LeftIcon={Check}
                  />
                </div>
              </div>
            )}
          </FieldArray>
        </Form>
      )}
    </Formik>
  );
};

const useStyles = makeStyles()((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    height: "100%",
  },
  form: {
    display: "flex",
    flexDirection: "column",
    rowGap: 12,
    overflow: "auto",
    height: "calc(100vh - 269px)",
  },
  row: {
    display: "flex",
    borderRadius: 6,
    padding: 8,
    columnGap: 8,
    backgroundColor: theme.palette.ivory,
  },
  number: {
    fontSize: 10,
    fontWeight: 600,
    lineHeight: "10px",
    borderRadius: 24,
    height: 18,
    width: 20,
    marginTop: 5,
    color: theme.palette.alpha500,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: theme.palette.greyLight050,
  },
  trashIcon: {
    color: theme.palette.red500v2,
    backgroundColor: theme.palette.red100,
    cursor: "pointer",
    borderRadius: 18,
    padding: 5,
    marginTop: 2,
  },
  addButton: {
    width: "100%",
    borderRadius: 18,
  },
  buttons: {
    display: "flex",
    justifyContent: "space-between",
    marginTop: 12,
  },
}));

const i18n = defineMessages({
  addQuestion: {
    id: "AccountSettings.GetIntroTemplate.QuestionForm.addQuestion",
    defaultMessage: "Add question",
  },
});

export default QuestionForm;
