import { Box } from "@mui/material";
import { OpenInNew, Plus } from "components/icons";
import { T } from "components/ui/Typography";
import { ConfigContext } from "config/ConfigProvider";
import { ProviderType } from "config/constants";
import getFirstValueFromURL from "helpers/getFirstValueFromURL";
import { useContext } from "react";
import { defineMessages, FormattedMessage, useIntl } from "react-intl";
import { useHistory, useLocation } from "react-router-dom";

import { BaseSidePanel, sharedCrmBaseSidePanelStyles } from "./BaseSidePanel";
import { ChatHelpBox } from "./ChatHelpBox";
import { CsvSidePanel } from "./CsvSidePanel";
import { GoogleSheetsSidePanel } from "./GoogleSheetsSidePanel";
import { HelpBox } from "./HelpBox";
import { VideoWithHelpBox } from "./VideoWithHelpBox";

export const SourceSelectSidePanel = () => {
  const { cx, classes } = sharedCrmBaseSidePanelStyles();
  const config = useContext(ConfigContext);
  const intl = useIntl();
  const history = useHistory();
  const location = useLocation();
  const provider = getFirstValueFromURL(location, "provider");

  return (
    content(provider) ?? (
      <BaseSidePanel>
        <VideoWithHelpBox
          src="https://www.youtube.com/embed/SIzHT3gGEkc"
          videoTitle={intl.formatMessage(i18n.videoCrm)}
          i18nTitle={i18n.howToPlug}
          i18nSubtitle={i18n.revealHelpYouLeverage}
          linkElementList={[
            {
              i18n: i18n.readGuidelines,
              url: config.connectCrmLink as string,
              icon: <OpenInNew />,
            },
          ]}
        />
        <HelpBox
          i18nTitle={i18n.noPermission}
          i18nSubtitle={i18n.inviteCrmAdmin}
          extraSlot={
            <Box
              mt={2}
              onClick={() =>
                history.push({ ...location, hash: "#invite-team" })
              }
            >
              <T className={cx(classes.helpBoxLink, classes.underline)}>
                <FormattedMessage {...i18n.inviteTeammate} />
                <Plus />
              </T>
            </Box>
          }
        />
        <HelpBox
          i18nTitle={i18n.securityAndPrivacy}
          i18nSubtitle={i18n.socCertified}
          linkElementList={[
            {
              i18n: i18n.learnMore,
              url: config.legalOverviewLink as string,
              icon: <OpenInNew />,
            },
          ]}
        />
        <ChatHelpBox />
      </BaseSidePanel>
    )
  );
};

const content = (provider: string | undefined) => {
  switch (provider) {
    case ProviderType.file:
      return <CsvSidePanel />;
    case ProviderType.gsheet:
      return <GoogleSheetsSidePanel />;
    default:
      return null;
  }
};

const i18n = defineMessages({
  videoCrm: {
    id: "crm.settings.SourceSelectSidePanel.videoCrm",
    defaultMessage: "Video tutorial for CRM connection",
  },
  howToPlug: {
    id: "crm.settings.SourceSelectSidePanel.howToPlug",
    defaultMessage: "How to plug your Data Source",
  },
  revealHelpYouLeverage: {
    id: "crm.settings.SourceSelectSidePanel.revealHelpYouLeverage",
    defaultMessage:
      "Reveal helps you leverage your ecosystem of existing or newly discovered partners.",
  },
  readGuidelines: {
    id: "crm.settings.SourceSelectSidePanel.readGuidelines",
    defaultMessage: "<a>Read guidelines</a>",
  },
  learnMore: {
    id: "crm.settings.SourceSelectSidePanel.learnMore",
    defaultMessage: "<a>Learn more</a>",
  },
  noPermission: {
    id: "crm.settings.SourceSelectSidePanel.noPermission",
    defaultMessage: "No permission to connect your CRM to Reveal?",
  },
  inviteCrmAdmin: {
    id: "crm.settings.SourceSelectSidePanel.inviteCrmAdmin",
    defaultMessage: "Invite your CRM Admin to use Reveal",
  },
  inviteTeammate: {
    id: "crm.settings.SourceSelectSidePanel.inviteTeammate",
    defaultMessage: "Invite a teammate",
  },
  securityAndPrivacy: {
    id: "crm.settings.SourceSelectSidePanel.securityAndPrivacy",
    defaultMessage: "Security & Data Privacy",
  },
  socCertified: {
    id: "crm.settings.SourceSelectSidePanel.socCertified",
    defaultMessage:
      "Reveal is SOC II certified and complies with Data Regulations including GDPR.",
  },
});
