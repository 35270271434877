import { CircularProgress } from "@mui/material";
import { ChevronDown } from "components/icons";
import Dropdown from "components/ui/Dropdown";
import DropdownSubmitFooter from "components/ui/DropdownSubmitFooter";
import { T } from "components/ui/Typography";
import useSelectorWithDeepEquality from "hooks/useSelectorWithDeepEquality";
import _ from "lodash";
import { makeStyles } from "makeStyles";
import { MouseEvent, useEffect, useState } from "react";
import { defineMessages, FormattedMessage } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { selectDashboardData } from "redux/dashboard/selectors";
import { initializePartnershipsAndCompanyNames } from "redux/init/thunks";
import { selectPartnershipsFilterData } from "redux/pipeline/selectors";

type Props = {
  initialPartnerIds: string[] | null;
  setSelectedPartnerIds: (ids: string[] | null) => void;
};

const PartnerSelector = ({
  initialPartnerIds,
  setSelectedPartnerIds,
}: Props) => {
  const { classes } = useStyles();
  const dispatch = useDispatch();
  const { ready: dashboardReady } = useSelector(selectDashboardData);
  const { data } = useSelectorWithDeepEquality(selectPartnershipsFilterData);
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const [ids, setIds] = useState<string[] | null>(null);

  const options = data.map((o) => {
    return {
      name: o.name,
      logo: o.logo,
      id: o.id.toString(),
    };
  });

  const sameSelection = _.isEqual(ids, initialPartnerIds);

  const handleOpenDropdown = (event: MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseDropdown = () => {
    setAnchorEl(null);
    setIds(initialPartnerIds);
  };

  const handleSubmit = () => {
    setSelectedPartnerIds(ids?.length === 0 ? null : ids);
    handleCloseDropdown();
  };

  useEffect(() => {
    if (dashboardReady === null) {
      dispatch(initializePartnershipsAndCompanyNames());
    }
  }, [dashboardReady, dispatch]);

  useEffect(() => {
    setIds(initialPartnerIds);
  }, [initialPartnerIds]);

  if (!dashboardReady) {
    return (
      <div className={classes.loader}>
        <CircularProgress size={20} />
      </div>
    );
  }

  return (
    <>
      <div className={classes.input} onClick={handleOpenDropdown}>
        <T>
          <FormattedMessage
            {...(ids === null ? i18n.allPartners : i18n.partnerCount)}
            values={{ count: ids?.length }}
          />
        </T>
        <ChevronDown style={{ fontSize: 18 }} />
      </div>
      <Dropdown
        options={options}
        anchorEl={anchorEl}
        onClose={handleCloseDropdown}
        open={Boolean(anchorEl)}
        withSearch
        size="inherit"
        withCheckBox
        value={ids}
        nullValueMeansAllSelected
        onChange={(ids) => setIds(ids)}
        isMultiselect
        allowSelectAll
        CustomFooter={
          <DropdownSubmitFooter
            onApply={handleSubmit}
            onCancel={handleCloseDropdown}
            disabledApplyBtn={sameSelection}
          />
        }
      />
    </>
  );
};

const useStyles = makeStyles()((theme) => ({
  loader: {
    display: "flex",
    justifyContent: "center",
  },
  input: {
    display: "flex",
    justifyContent: "space-between",
    borderRadius: 18,
    padding: 10,
    border: `1px solid ${theme.palette.greyLight200}`,
    cursor: "pointer",
    "&:hover": {
      borderColor: theme.palette.midnight500,
    },
  },
}));

const i18n = defineMessages({
  allPartners: {
    id: "AccountSettings.GetIntroTemplate.PartnerSelector.allPartners",
    defaultMessage: "All partners",
  },
  partnerCount: {
    id: "AccountSettings.GetIntroTemplate.PartnerSelector.partnerCount",
    defaultMessage: "{count} {count, plural, one {partner} other {partners}}",
  },
});

export default PartnerSelector;
