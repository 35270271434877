import {
  AnyAction,
  createAsyncThunk,
  isRejected,
  ThunkDispatch,
} from "@reduxjs/toolkit";
import _ from "lodash";
import PageView, { PageType } from "models/PageView";
import { JSONAPIAttributes, JSONAPIResource } from "models/types";
import { create, indexAll, update } from "redux/api/thunks";
import { asJSONSerializable, RevealStore } from "redux/typing";
import {
  expandToNewMarkets,
  identifyMyOverlapsNearboundAccounts,
  influenceDealsNearboundAccounts,
  isIGoalPreset,
  nearboundAccountsGoals,
} from "screens/Frontoffice/screens/DataTables/screens/Mapping360/goalsPresets";
import {
  asPersistedColumn,
  ColumnConfigType,
  FilterType,
  SortType,
} from "screens/Frontoffice/screens/DataTables/shared/types";

import {
  defaultNearboundAccountsColumns,
  defaultNearboundAccountsSort,
  nearboundAccountsColumnsConfig,
  nearboundAccountsPageTypes,
} from "./defaults"; // TODO: fix this import, update the defaults when the API is working
import { selectViews } from "./selectors";
import { Mapping360NearboundAccountsActions } from "./typing";

export const addView = createAsyncThunk<
  number,
  { pageType: PageType; pinned?: boolean }
>(
  Mapping360NearboundAccountsActions.addView,
  async ({ pageType, pinned }, thunkAPI) => {
    const goal = nearboundAccountsGoals.find(
      (goal) => goal.pageType === pageType
    );
    const createAction = await thunkAPI.dispatch(
      create({
        type: "page_views",
        attributes: {
          page_type: pageType,
          ...(typeof pinned === "boolean" && { pinned }),
          ...(isIGoalPreset(goal)
            ? {
                filters: asJSONSerializable(goal.presets.filter),
                sort: asJSONSerializable(goal.presets.sort),
                columns: asJSONSerializable(
                  goal.presets.columns?.map(
                    (column) => nearboundAccountsColumnsConfig[column]
                  ) ?? []
                ),
              }
            : {
                filters: [],
                sort: [],
                columns: [],
              }),
        },
      })
    );

    if (isRejected(createAction)) {
      throw new Error("Cannot create view");
    }

    return Number(createAction.payload.data.id);
  }
);

const getOrCreateView = async (
  isGoalBased360Limited: boolean,
  isGoalBased360Unlocked: boolean,
  isNewProspects360Unlocked: boolean,
  dispatch: ThunkDispatch<unknown, unknown, AnyAction>,
  views: PageView[],
  pageType?: PageType,
  lastViewId?: string | number | null
) => {
  let id: number | undefined = undefined;
  if (lastViewId && views.find((view) => view.id === Number(lastViewId))) {
    id = Number(lastViewId);
  } else if (isGoalBased360Limited && !isGoalBased360Unlocked) {
    id = views.find(
      (view) => view.pageType === PageType.mapping360InfluenceNearboundAccounts
    )?.id;
  } else if (isGoalBased360Unlocked && pageType) {
    id = views.find((view) => view.pageType === pageType)?.id;
  } else if (isGoalBased360Unlocked && !pageType) {
    id = views.find(
      (view) =>
        view.pinned && nearboundAccountsPageTypes.includes(view.pageType)
    )?.id;
    if (!id) {
      id = views.find(
        (view) =>
          view.pageType ===
          (isNewProspects360Unlocked
            ? PageType.mapping360Expand
            : PageType.mapping360OverlapsNearboundAccounts)
      )?.id;
    }
  } else if (!isGoalBased360Limited) {
    id = views.find(
      (view) =>
        view.pageType === PageType.mapping360NonGoalBasedNearboundAccounts
    )?.id;
  }
  if (id) {
    return String(id);
  }

  const goal = nearboundAccountsGoals.find(
    (goal) => goal.pageType === pageType
  );
  const createAction = await dispatch(
    create({
      type: "page_views",
      attributes: {
        page_type: isGoalBased360Unlocked
          ? pageType
            ? pageType
            : isNewProspects360Unlocked
            ? PageType.mapping360Expand
            : PageType.mapping360OverlapsNearboundAccounts // unlocked and no pageType means default view
          : isGoalBased360Limited
          ? PageType.mapping360InfluenceNearboundAccounts // locked means influence view
          : PageType.mapping360NonGoalBasedNearboundAccounts, // locked means non-goal based view
        ...(isGoalBased360Unlocked &&
          !isNewProspects360Unlocked &&
          !pageType && {
            // first time visiting the page, creating the first default view
            filters: asJSONSerializable(
              identifyMyOverlapsNearboundAccounts.presets.filter
            ),
            sort: asJSONSerializable(
              identifyMyOverlapsNearboundAccounts.presets.sort
            ),
            columns: asJSONSerializable(
              identifyMyOverlapsNearboundAccounts.presets.columns?.map(
                (column) => nearboundAccountsColumnsConfig[column]
              ) ?? []
            ),
            pinned: true,
          }),
        ...(isGoalBased360Unlocked &&
          isNewProspects360Unlocked &&
          !pageType && {
            // first time visiting the page, creating the first default view
            filters: asJSONSerializable(expandToNewMarkets.presets.filter),
            sort: asJSONSerializable(expandToNewMarkets.presets.sort),
            columns: asJSONSerializable(
              expandToNewMarkets.presets.columns?.map(
                (column) => nearboundAccountsColumnsConfig[column]
              ) ?? []
            ),
            pinned: true,
          }),
        ...(isGoalBased360Unlocked &&
          pageType &&
          isIGoalPreset(goal) && {
            // page type is present therefor it's not the first time visit, so we don't set pinned true
            filters: asJSONSerializable(goal.presets.filter),
            sort: asJSONSerializable(goal.presets.sort),
            columns: asJSONSerializable(
              goal.presets.columns?.map(
                (column) => nearboundAccountsColumnsConfig[column]
              ) ?? []
            ),
          }),
        ...(isGoalBased360Limited &&
          !isGoalBased360Unlocked && {
            // locked version is only influence view
            filters: asJSONSerializable(
              influenceDealsNearboundAccounts.presets.filter
            ),
            sort: asJSONSerializable(
              influenceDealsNearboundAccounts.presets.sort
            ),
            columns: asJSONSerializable(
              influenceDealsNearboundAccounts.presets.columns?.map(
                (column) => nearboundAccountsColumnsConfig[column]
              ) ?? []
            ),
          }),
        ...(!isGoalBased360Limited && {
          filters: [],
          sort: defaultNearboundAccountsSort,
          columns: asJSONSerializable(
            defaultNearboundAccountsColumns.map(asPersistedColumn)
          ),
          filter_order_list: [-1, -2, -3, -4, -5],
        }),
      },
    })
  );

  if (isRejected(createAction)) {
    throw new Error("Cannot initialize mapping360");
  }

  return createAction.payload.data.id;
};

export const loadView = createAsyncThunk<
  { viewId: string },
  {
    pageType?: PageType;
    isGoalBased360Limited: boolean;
    isGoalBased360Unlocked: boolean;
    isNewProspects360Unlocked: boolean;
    selectLastView?: boolean;
  }
>(
  Mapping360NearboundAccountsActions.loadView,
  async (
    {
      isGoalBased360Limited,
      isGoalBased360Unlocked,
      isNewProspects360Unlocked,
      pageType,
      selectLastView,
    },
    thunkAPI
  ) => {
    const state = thunkAPI.getState() as RevealStore;
    const views = selectViews(state);
    const lastViewId = state.nearboundAccounts.viewId;
    const viewId = await getOrCreateView(
      isGoalBased360Limited,
      isGoalBased360Unlocked,
      isNewProspects360Unlocked,
      thunkAPI.dispatch,
      views,
      pageType,
      selectLastView ? lastViewId : undefined
    );
    return {
      viewId,
    };
  }
);

export const loadViews = createAsyncThunk(
  Mapping360NearboundAccountsActions.loadViews,
  async (_, thunkAPI) => {
    const action = await thunkAPI.dispatch(
      indexAll({
        type: "page_views",
      })
    );

    if (isRejected(action)) {
      throw new Error("Cannot fetch Mapping 360 views");
    }

    return {
      viewIds: action.payload.data.map((value: JSONAPIResource) => +value.id),
    };
  }
);

export const updateView = createAsyncThunk(
  Mapping360NearboundAccountsActions.updateView,
  async (
    parameters: {
      filters?: FilterType[];
      columns?: ColumnConfigType[];
      sort?: SortType[];
      filterOrderList?: number[];
      pinned?: boolean;
      id: number;
    },
    thunkAPI
  ) => {
    const attributes = {} as JSONAPIAttributes;
    if (parameters.filters) {
      attributes["filters"] = asJSONSerializable(parameters.filters);
    }
    if (parameters.columns) {
      attributes["columns"] = asJSONSerializable(
        parameters.columns.map(asPersistedColumn)
      );
    }
    if (parameters.sort) {
      attributes["sort"] = asJSONSerializable(parameters.sort);
    }
    if (parameters.filterOrderList) {
      attributes["filter_order_list"] = parameters.filterOrderList;
    }
    if (typeof parameters.pinned === "boolean") {
      attributes["pinned"] = parameters.pinned;
    }
    if (_.isEmpty(attributes)) {
      return;
    }

    const action = await thunkAPI.dispatch(
      update({
        type: "page_views",
        id: parameters.id,
        attributes,
      })
    );

    if (isRejected(action)) {
      throw new Error("Cannot update");
    }
  }
);
