import { Box, DialogActions } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import Button, { Props as ButtonProps } from "components/ui/Button";
import { T } from "components/ui/Typography";
import { makeStyles } from "makeStyles";
import { ReactNode } from "react";

export enum BasicDialogVariant {
  Confirm = "CircleQuestion",
  Unlock = "unlock",
  Delete = "delete",
}

type BasicDialogProps = {
  variant?: BasicDialogVariant;
  title?: string;
  description?: string;
  content?: ReactNode;
  buttonProps: ButtonProps[];
  isNotInDialog?: boolean;
};

export const BasicDialog = ({
  variant,
  title,
  description,
  content,
  buttonProps,
  isNotInDialog = false,
}: BasicDialogProps) => {
  const { classes } = useStyles();
  const theme = useTheme();

  const withDialogActions = (children: ReactNode) => {
    const ButtonGroup = isNotInDialog ? Box : DialogActions;
    return (
      <ButtonGroup className={classes.buttonContainer}>{children}</ButtonGroup>
    );
  };

  return (
    <Box className={classes.container}>
      {variant && (
        <img className={classes.img} src={`/images/${variant}.png`} alt="" />
      )}

      {title && (
        <T h3 bold className={classes.title}>
          {title}
        </T>
      )}
      {description && <T color={theme.palette.text.secondary}>{description}</T>}
      {content && content}

      {withDialogActions(buttonProps.map((props) => <Button {...props} />))}
    </Box>
  );
};

// CSS

const useStyles = makeStyles()((theme) => ({
  container: {
    textAlign: "center",
  },
  buttonContainer: {
    display: "flex",
    justifyContent: "center",
    marginTop: 12,
    padding: 8,
    "& > :not(:first-of-type)": {
      marginLeft: 8,
    },
  },
  img: {
    width: 48,
    height: 48,
  },
  title: {
    marginTop: 12,
    marginBottom: 8,
  },
}));
