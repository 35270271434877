import { grey500, ninaGreen, offWhite, partner } from "../styles/colors";
import * as constants from "./constants";
import { FLAGS } from "./featureFlags";
import { getValidatedEnvironment } from "./schema";
import muiTheme from "./theme";

const env = getValidatedEnvironment();

const configurator = () => {
  const configurations = {
    [env.hosts.frontoffice]: {
      appName: "partners",
      title: "Reveal | Partners",
      theme: muiTheme,
      routes: require("./routes/partners").default,
      onboarding: require("./onboarding/partners").default,
      homeLink: "https://www.reveal.co",
      mainColor: partner,
      backgroundColor: FLAGS.redesign ? offWhite : grey500,
      canChangeCompany: false,
      privacyPolicyLink: "https://www.reveal.co/legals/privacy-policy",
      termsOfUseLink: "https://www.reveal.co/legals/terms",
      cookiesPolicyLink: "https://www.reveal.co/legal-terms/cookies-policy-en",
      templateFilePath: "/resources/reveal_csv_datasource_template.csv",
      templateNdaPath: "/resources/reveal_nda_template.pdf",
      templateGsheetLink:
        "https://docs.google.com/spreadsheets/d/1FGIYcEZsuRCEfq4fo6HTIPexRPoAz6mFoxjjaFT9F4I/edit?usp=sharing",
      apiKeyInfo: "/", // TODO add the link
      enableTracking: true,
      reveal4BusinessLink: "https://intercom.help/Reveal4business/en/",
      uploadACsvLink:
        "https://intercom.help/Reveal4business/en/articles/4835905-upload-a-csv",
      defineDataSourceStatusRulesLink:
        "https://intercom.help/Reveal4business/en/articles/4835944-define-your-data-source-status-rules",
      connectCrmLink: "https://intercom.help/Reveal4business/en/?q=connect+crm",
      legalOverviewLink: "https://reveal.co/legals/legal-overview",
      connectGoogleSheetLink:
        "https://intercom.help/Reveal4business/en/articles/6674631-connect-a-google-sheet",
      partnerAnalyticsV0Link:
        "https://intercom.help/Reveal4business/en/articles/7257704-partner-analytics-v0",
      partnerAnalyticsDashboardLink:
        "https://intercom.help/Reveal4business/en/articles/7924997-partner-analytics-dashboard",
      integrationLearnMoreCRM:
        "https://intercom.help/Reveal4business/en/collections/3873343-leveraging-partner-data-in-your-crm",
      integrationLearnMoreSlack:
        "https://intercom.help/Reveal4business/en/articles/7172673-reveal-x-slack-integration",
      integrationLearnMoreRevealAPI:
        "https://intercom.help/Reveal4business/en/articles/4838202-reveal-api",
      integrationLearnMorePartnerStack:
        "https://intercom.help/Reveal4business/en/articles/6951680-partnerstack-integration-setup-and-how-to-guide",
      ...constants,
    },
    [env.hosts.backoffice]: {
      appName: "backoffice",
      title: "Reveal | Back Office",
      theme: muiTheme,
      routes: require("./routes/backoffice").default,
      onboarding: [],
      homeLink: "/",
      mainColor: ninaGreen,
      backgroundColor: grey500,
      canChangeCompany: false,
      ignoreCookieConsent: true,
      enableTracking: false,
      ...constants,
    },
  };

  const origin = window.location.origin;
  if (!(origin in configurations)) {
    throw Error(
      `No valid configuration found for host ${window.location.origin}`
    );
  }

  return configurations[origin];
};

export default configurator;
