import { ClassNameMap } from "@mui/material/styles";
import Tooltip from "components/ui/Tooltip";
import { T } from "components/ui/Typography";
import { isMessageDescriptor } from "helpers/isMessageDescriptor";
import generic from "i18n/generic";
import { makeStyles } from "makeStyles";
import { MouseEvent, ReactElement, ReactNode } from "react";
import { FormattedMessage, MessageDescriptor } from "react-intl";

type Props = {
  active: boolean;
  children: ReactElement;
  message: MessageDescriptor | ReactNode;
  onClick: () => void;
  placement?: "top" | "right" | "left" | "bottom";
  classes?: ClassNameMap;
};

export const PayingFeatureTooltip = ({
  active,
  children,
  message,
  onClick,
  placement,
  classes: newClasses,
}: Props) => {
  const { classes } = useStyles();

  const cancelEvent = (event: MouseEvent) => {
    event.preventDefault();
    event.stopPropagation();
  };

  return active ? (
    <Tooltip
      title={
        <div onClick={cancelEvent}>
          <T>
            {isMessageDescriptor(message) ? (
              <FormattedMessage {...message} />
            ) : (
              message
            )}
            &nbsp;
            <span className={classes.tooltipLink} onClick={onClick}>
              <FormattedMessage {...generic.explorePlans} />
            </span>
          </T>
        </div>
      }
      placement={placement}
      classes={newClasses}
    >
      {children}
    </Tooltip>
  ) : (
    children
  );
};

// CSS

const useStyles = makeStyles()(() => ({
  tooltipLink: {
    cursor: "pointer",
    textDecoration: "underline",
  },
}));
