import { CellProps } from "components/ui/data-grid/utils";
import Tag from "components/ui/Tag";
import Tooltip from "components/ui/Tooltip";
import { T } from "components/ui/Typography";
import _ from "lodash";
import { makeStyles } from "makeStyles";
import { AvailableDirectoryColumns } from "redux/directory/defaults";

type Props = CellProps & {
  width: number;
};

export const MarketPresenceCell = ({ row, width }: Props) => {
  const { classes, cx } = useStyles();
  const { marketPresenceDisplayValue } = row;
  const countryNameList = (marketPresenceDisplayValue ?? "")
    .split(", ")
    .filter((country: string) => country !== "");

  const hiddenTags = getHiddenTags(countryNameList, width);

  return (
    <div className={classes.cellContainer}>
      {_.isEmpty(countryNameList) && <T>--</T>}
      {countryNameList.map((countryName: string, index: number) => (
        <div className={classes.tagContainer} key={`${row.id} ${index}`}>
          <Tag label={countryName} />
        </div>
      ))}
      {hiddenTags.length > 0 && (
        <Tooltip
          title={
            <div className={classes.tooltipTagsContainer}>
              {hiddenTags.map((tag) => (
                <Tag isSmall variant="dark" label={tag} />
              ))}
            </div>
          }
        >
          <div
            className={cx(classes.countContainer, "partnerPresenceGradient")}
          >
            <T uppercase textAlign="right" className={classes.countText}>
              +{hiddenTags.length}
            </T>
          </div>
        </Tooltip>
      )}
    </div>
  );
};

export const isDirectoryMarketPresenceCell = ({ fieldName }: CellProps) => {
  return fieldName === AvailableDirectoryColumns.marketPresence;
};

// Helpers

const getItemWidth = (name: string | undefined) => {
  const padding = 11 + 11;
  return name ? name.length * 7 + padding : 0;
};

const shouldDisplayItem = (
  itemWidth: number,
  currentWidth: number,
  columnWidth: number
) => currentWidth + itemWidth < columnWidth;

const getHiddenTags = (tags: string[], columnWidth: number) => {
  let currentWidth = 0;
  let displayedTags: string[] = [];
  let hiddenTags: string[] = [];
  _.each(tags, (tag) => {
    const itemWidth = getItemWidth(tag);
    if (shouldDisplayItem(itemWidth, currentWidth, columnWidth)) {
      currentWidth += itemWidth;
      displayedTags.push(tag);
    } else {
      hiddenTags.push(tag);
    }
  });
  return hiddenTags;
};

// CSS

const useStyles = makeStyles()((theme) => ({
  cellContainer: {
    position: "relative",
    display: "flex",
    alignItems: "center",
    gap: "2px",
    width: "100%",
  },
  tagContainer: {
    display: "flex",
    columnGap: 4,
    alignItems: "center",
    flexWrap: "wrap",
  },
  countContainer: {
    position: "absolute",
    display: "flex",
    height: "100%",
    right: "-10px",
    width: 50,
    alignItems: "center",
    justifyContent: "end",
    paddingRight: 10,
    background: `linear-gradient(270deg, ${theme.palette.ivory} 50.52%, rgba(250, 250, 246, 0) 100%)`,
  },
  countText: {
    borderRadius: 17,
    padding: "3px 6px",
    width: "fit-content",
    backgroundColor: theme.palette.greyscale150,
  },
  tooltipTagsContainer: {
    display: "flex",
    columnGap: 2,
    rowGap: 2,
    padding: "7px, 10px, 7px, 10px",
    flexWrap: "wrap",
  },
}));
