import { createReducer } from "@reduxjs/toolkit";
import { logout } from "redux/user/thunks";

import {
  initializePartnersToAccept,
  initializePartnersToShare,
} from "./thunks";
import { OnboardingState } from "./typing";

const initialState: OnboardingState = {
  notAcceptedReady: false,
  notAcceptedPartnershipIds: [],
  notSharedReady: false,
  notSharedPartnershipIds: [],
};

export default createReducer(initialState, (builder) =>
  builder
    .addCase(initializePartnersToAccept.fulfilled, (state, action) => {
      state.notAcceptedReady = true;
      state.notAcceptedPartnershipIds = action.payload.partnershipIds;
    })
    .addCase(initializePartnersToShare.fulfilled, (state, action) => {
      state.notSharedReady = true;
      state.notSharedPartnershipIds = action.payload.partnershipIds;
    })
    .addCase(logout.fulfilled, (state) => {
      state.notAcceptedReady = false;
      state.notAcceptedPartnershipIds = [];
      state.notSharedReady = false;
      state.notSharedPartnershipIds = [];
    })
);
