import { SvgIconComponent } from "@mui/icons-material";
import { Box, ListItemIcon, ListItemText } from "@mui/material";
import Collapse from "@mui/material/Collapse";
import ListItemButton from "@mui/material/ListItemButton";
import { ChevronDown, ChevronTop } from "components/icons";
import { makeStyles } from "makeStyles";
import { ReactElement, ReactNode } from "react";

type Props = {
  Icon?: SvgIconComponent;
  label: ReactElement | string;
  children?: ReactNode;
  isUnfolded: boolean;
  onClick?: () => void;
};

export const CollapseWidget = ({
  label,
  children,
  Icon,
  isUnfolded,
  onClick,
}: Props) => {
  const isFoldable = Boolean(onClick);
  const { classes } = useStyles({ isFoldable, isUnfolded });

  return (
    <Box flexGrow={1}>
      <ListItemButton
        classes={{ root: classes.widgetButton }}
        onClick={onClick}
      >
        <>
          {Icon && (
            <ListItemIcon className={classes.leftIcon}>
              <Icon />
            </ListItemIcon>
          )}
          <ListItemText primary={label} className={classes.label} />
          {isFoldable ? (
            isUnfolded ? (
              <ChevronTop className={classes.icon} />
            ) : (
              <ChevronDown className={classes.icon} />
            )
          ) : null}
        </>
      </ListItemButton>
      <Collapse
        in={isUnfolded}
        timeout="auto"
        classes={{ wrapperInner: classes.widgetContent }}
      >
        {children}
      </Collapse>
    </Box>
  );
};

/// CSS

const useStyles = makeStyles<{ isFoldable: boolean; isUnfolded: boolean }>()(
  (theme, { isFoldable, isUnfolded }) => ({
    widgetButton: {
      height: 18,
      display: "flex",
      padding: "6px 0px",
      cursor: isFoldable ? "pointer" : "default",
      background: isUnfolded ? theme.palette.greyscale200 : "transparent",
      "&:hover": {
        background: isFoldable ? theme.palette.greyscale100 : "transparent",
      },
      minWidth: 120,
      width: "fit-content",
    },
    widgetContent: {
      border: isUnfolded
        ? `1px solid ${theme.palette.greyscale200}`
        : undefined,
      borderRadius: 8,
      display: "flex",
      flexDirection: "column",
      flexGrow: 1,
      marginTop: 8,
      padding: 12,
      rowGap: 8,
      width: "100%",
    },
    icon: {
      color: theme.palette.greyReveal,
      width: theme.spacing(2),
      height: theme.spacing(2),
    },
    label: {
      borderBottom: `1px dashed ${theme.palette.greyLight300}`,
    },
    leftIcon: {
      color: theme.palette.midnight,
      minWidth: 22,
      "& > svg": {
        height: 16,
        width: 16,
      },
    },
  })
);
