import { isFulfilled, isRejected } from "@reduxjs/toolkit";
import { NotificationStatus } from "components/ui/Notifications/NotificationSnackbar";
import usePushNotification from "hooks/usePushNotification";
import generic from "i18n/generic";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { update } from "redux/api/thunks";
import { fetchProfileInBackground } from "redux/user/thunks";

export const useUpdateHasLicense = () => {
  const dispatch = useDispatch();
  const pushNotification = usePushNotification();
  const [isLoading, setIsLoading] = useState(false);

  const updateUserHasLicense = async (
    userId: number,
    newHasLicense: boolean,
    checked?: boolean,
    setIsChecked?: SetState<boolean>
  ) => {
    setIsLoading(true);
    if (checked !== undefined) setIsChecked?.(checked);
    const result = await dispatch(
      update({
        type: "users",
        id: userId,
        attributes: {
          has_license: newHasLicense,
        },
      })
    );

    if (isFulfilled(result)) {
      await dispatch(fetchProfileInBackground());
      pushNotification({
        status: NotificationStatus.success,
        message: generic.edits_saved,
      });
    }

    if (isRejected(result)) {
      pushNotification({
        status: NotificationStatus.error,
        message: generic.error_unknown,
      });
      if (checked !== undefined) setIsChecked?.(!checked);
    }

    setIsLoading(false);
  };

  return { isLoading, updateUserHasLicense };
};
