import { withCompanyPayingFeatures } from "components/hoc/withCompanyPayingFeatures";
import withCompanyPermissions from "components/hoc/withCompanyPermissions";
import { Chat, CircleCheck } from "components/icons";
import { T } from "components/ui/Typography";
import { ConfigContext } from "config/ConfigProvider";
import muiTheme from "config/theme";
import useSegment from "hooks/useSegment";
import useUserProfile from "hooks/useUserProfile";
import { makeStyles } from "makeStyles";
import { PayingFeature } from "models/CompanyPayingFeatureSubscription";
import { useCallback, useContext, useEffect, useState } from "react";
import { defineMessages, FormattedMessage } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { Link, useHistory, useLocation } from "react-router-dom";
import { selectDashboardData } from "redux/dashboard/selectors";
import { initializePartnershipsAndCompanyNames } from "redux/init/thunks";
import LoadingDataDialog from "screens/Frontoffice/shared/components/LoadingDataDialog";
import { GetStartedEvent } from "tracking";

import { useConnectCrmDialog } from "../../../shared/hooks/useConnectCrmDialog";
import { InvitationReviewModal } from "../../Partners/screens/Dashboard/components/InvitationReviewModal";
import { ConnectCrmDialog } from "../../Sources/shared/components/ConnectCrmDialog/ConnectCrmDialog";
import { BookMeetingDialog } from "./BookMeetingDialog";
import { FinalStepDialog } from "./FinalStepDialog";
import { WelcomeDialog } from "./WelcomeDialog";

const GetStarted = () => {
  const dispatch = useDispatch();
  const { classes, cx } = useStyles();
  const { profile } = useUserProfile();
  const { track } = useSegment();
  const history = useHistory();
  const location = useLocation();
  const config = useContext(ConfigContext);
  const {
    hasActiveOrCreatedPartnerships,
    hasSources,
    invitations,
    ready,
  } = useSelector(selectDashboardData);

  const {
    isConnectCrmDialogOpen,
    loading,
    fieldsNotReady,
    refreshForConnectCrmDialog,
  } = useConnectCrmDialog();

  const [isBookMeetingDialogOpen, setIsBookMeetingDialogOpen] = useState(false);
  const [isWelcomeDialogOpen, setIsWelcomeDialogOpen] = useState(false);
  const [pendingPartnershipId, setPendingPartnershipId] = useState<
    string | null
  >(null);
  const [isFinalStepDialogOpen, setIsFinalStepDialogOpen] = useState(false);

  const handleDataSourceStepClick = useCallback(() => {
    track(GetStartedEvent.step2Clicked);
    history.replace({
      ...location,
      search: "init=true&provider=all",
    });
  }, [track, history, location]);

  const handlePartnerStepClick = useCallback(() => {
    track(GetStartedEvent.step3Clicked);
    if (invitations.length > 0) {
      setPendingPartnershipId(String(invitations[0].id));
    } else {
      history.replace({
        pathname: "/directory",
        hash: "get-started",
      });
    }
  }, [track, invitations, history]);

  const stepList = [
    {
      id: 1,
      isChecked: profile.company.hasSeenOnboardingVideo,
      onClick: () => {
        setIsWelcomeDialogOpen(true);
      },
      i18nTitle: i18n.step1Title,
      i18nSubtitle: i18n.step1Subtitle,
    },
    {
      id: 2,
      isChecked: hasSources,
      onClick: handleDataSourceStepClick,
      i18nTitle: i18n.step2Title,
      i18nSubtitle: i18n.step2Subtitle,
    },
    {
      id: 3,
      isChecked: hasActiveOrCreatedPartnerships,
      onClick: handlePartnerStepClick,
      i18nTitle: i18n.step3Title,
      i18nSubtitle: i18n.step3Subtitle,
    },
  ];
  const stepCount = stepList.filter((step) => step.isChecked).length;

  const handleCloseCrmModal = () => {
    history.replace({
      ...location,
      search: "",
      hash: "",
    });
  };

  useEffect(() => {
    if (ready === null) {
      dispatch(initializePartnershipsAndCompanyNames());
    }
  }, [ready, dispatch]);

  useEffect(() => {
    if (hasSources && hasActiveOrCreatedPartnerships) {
      setIsFinalStepDialogOpen(true);
    }
  }, [hasSources, hasActiveOrCreatedPartnerships]);

  return (
    <>
      <div className={classes.root}>
        <T h2 bold>
          <FormattedMessage
            {...i18n.welcome}
            values={{ firstName: profile.firstName }}
          />
        </T>
        <div className={classes.container}>
          <div className={classes.stepTitleSectionWrapper}>
            <div className={classes.stepTitleSection}>
              <T h2>🚀</T>
              <T h2 bold>
                <FormattedMessage {...i18n.letsGetYouOnboarded} />
              </T>
            </div>
            <div className={classes.stepCount}>
              <T>
                <FormattedMessage
                  {...i18n.stepCount}
                  values={{ count: stepCount }}
                />
              </T>
            </div>
          </div>
          <div className={classes.divider} />
          <div>
            {stepList.map((step) => (
              <div
                className={classes.step}
                onClick={step.onClick}
                data-testid={`step-${step.id}`}
              >
                {step.isChecked ? (
                  <CircleCheck className={classes.check} />
                ) : (
                  <div className={classes.emptyCheck} />
                )}
                <div>
                  <T
                    bodyBig
                    bold
                    className={cx(classes.stepTitle, {
                      [classes.lineThrough]: step.isChecked,
                    })}
                  >
                    <FormattedMessage {...step.i18nTitle} />
                  </T>
                  <T color={muiTheme.palette.alpha500}>
                    <FormattedMessage {...step.i18nSubtitle} />
                  </T>
                </div>
              </div>
            ))}
          </div>
        </div>
        <div className={classes.help}>
          <T>
            <FormattedMessage
              {...i18n.needAssistance}
              values={{
                lnk: (chunks: string) => (
                  <Link
                    className={classes.link}
                    target="_blank"
                    to={{
                      pathname: config.reveal4BusinessLink,
                    }}
                  >
                    {chunks}
                  </Link>
                ),
              }}
            />
          </T>
          <Chat className={classes.iconChat} />
          <T className={classes.talkToSupport}>
            <FormattedMessage
              {...i18n.talkWithSupport}
              values={{
                btn: (chunks: string) => (
                  <span onClick={() => setIsBookMeetingDialogOpen(true)}>
                    {chunks}
                  </span>
                ),
              }}
            />
          </T>
        </div>
      </div>
      <BookMeetingDialog
        isOpen={isBookMeetingDialogOpen}
        onClose={() => setIsBookMeetingDialogOpen(false)}
      />
      {isWelcomeDialogOpen && (
        <WelcomeDialog
          isOpen={isWelcomeDialogOpen}
          onClose={() => setIsWelcomeDialogOpen(false)}
        />
      )}
      {isConnectCrmDialogOpen && !loading && (
        <ConnectCrmDialog
          handleClose={handleCloseCrmModal}
          isOpen={true}
          refresh={refreshForConnectCrmDialog}
        />
      )}
      {(loading || fieldsNotReady) && (
        <LoadingDataDialog
          notReadyText={i18n.fieldsNotReady}
          notReadyHash={"#fields-not-ready"}
        />
      )}
      {pendingPartnershipId && (
        <InvitationReviewModal
          partnershipId={pendingPartnershipId}
          onClose={() => setPendingPartnershipId(null)}
          isFromGetStarted
        />
      )}
      {isFinalStepDialogOpen && (
        <FinalStepDialog onClose={() => setIsFinalStepDialogOpen(false)} />
      )}
    </>
  );
};

export default withCompanyPayingFeatures([PayingFeature.NewOnboarding])(
  withCompanyPermissions(["company.manage_admins"])(GetStarted)
);

const useStyles = makeStyles()((theme) => ({
  root: {
    width: "100%",
    height: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },
  container: {
    width: "744px",
    background: theme.palette.ivory,
    borderRadius: "8px",
    padding: 8,
    margin: "48px 0 30px",
  },
  icon: {
    width: theme.spacing(2),
    height: theme.spacing(2),
  },
  stepTitleSectionWrapper: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "16px 20px",
  },
  stepTitleSection: {
    display: "flex",
    alignItems: "center",
    gap: 28,
  },
  lineThrough: {
    textDecoration: "line-through",
  },
  stepCount: {
    display: "flex",
    alignItems: "center",
    gap: 8,
  },
  divider: {
    width: "728px",
    height: "1px",
    background: theme.palette.greyLight050,
    margin: "8px auto",
  },
  step: {
    display: "flex",
    alignItems: "center",
    gap: 24,
    padding: "20px 24px",
    borderRadius: 8,
    "&:hover": {
      background: theme.palette.greyLight050Alpha050,
      cursor: "pointer",
    },
  },
  emptyCheck: {
    width: "20px",
    height: "20px",
    border: `1px solid ${theme.palette.greyLight100}`,
    borderRadius: "50%",
  },
  check: {
    color: theme.palette.green400,
  },
  stepTitle: {
    marginBottom: 4,
  },
  premiumTitle: {
    background: "linear-gradient(70deg, #5A28C3 9.2%, #32E7B1 100%)",
    "-webkit-background-clip": "text",
    "-webkit-text-fill-color": "transparent",
  },
  help: {
    display: "flex",
    alignItems: "center",
    gap: 4,
    color: theme.palette.alpha500,
  },
  iconChat: {
    width: theme.spacing(1.5),
    height: theme.spacing(1.5),
  },
  link: {
    color: "inherit",
    textDecoration: "none",
    cursor: "pointer",
    "&:hover": {
      color: "inherit",
    },
  },
  talkToSupport: {
    cursor: "pointer",
    "&:hover": {
      textDecoration: "underline",
    },
  },
}));

const i18n = defineMessages({
  welcome: {
    id: "GetStarted.welcome",
    defaultMessage: "Hey {firstName}, welcome to Reveal! 👋",
  },
  letsGetYouOnboarded: {
    id: "GetStarted.letsGetYouOnboarded",
    defaultMessage: "Let’s get you onboarded!",
  },
  stepCount: {
    id: "GetStarted.stepCount",
    defaultMessage: "{count}/3 steps",
  },
  step1Title: {
    id: "GetStarted.step1Title",
    defaultMessage: "A quick intro to Reveal",
  },
  step1Subtitle: {
    id: "GetStarted.step1Subtitle",
    defaultMessage: "Discover what you can achieve with Reveal",
  },
  step2Title: {
    id: "GetStarted.step2Title",
    defaultMessage: "Add data source",
  },
  step2Subtitle: {
    id: "GetStarted.step2Subtitle",
    defaultMessage: "Connect and get your data flowing in less than 3 minutes",
  },
  step3Title: {
    id: "GetStarted.step3Title",
    defaultMessage: "Connect with your first partner",
  },
  step3Subtitle: {
    id: "GetStarted.step3Subtitle",
    defaultMessage:
      "Accept your Partner’s invite to start your Nearbound journey",
  },
  needAssistance: {
    id: "GetStarted.needAssistance",
    defaultMessage: "Need assistance? Access the <lnk>Help Center</lnk> or",
  },
  talkWithSupport: {
    id: "GetStarted.talkWithSupport",
    defaultMessage: "<btn>Talk with a support agent</btn>",
  },
  fieldsNotReady: {
    id: "GetStarted.fieldsNotReady",
    defaultMessage:
      "It's taking some time to import your fields. Please refresh this page in a few minutes.",
  },
});
