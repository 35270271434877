import { Tab as TabConfig } from "components/ui/TabList";
import { defineMessages, FormattedMessage } from "react-intl";

export enum Tab {
  General = "general",
  DataSources = "data-sources",
  RestrictedAccounts = "restricted-accounts",
  Followers = "followers",
  Overview = "overview",
  Collaboration = "collaboration",
}

const i18n = defineMessages({
  general: {
    id: "crm.PartnershipSettings.constants.general",
    defaultMessage: "Sharing settings",
  },
  dataSources: {
    id: "crm.PartnershipSettings.constants.dataSources",
    defaultMessage: "Data Sources",
  },
  restrictedAccounts: {
    id: "crm.PartnershipSettings.constants.restrictedAccounts",
    defaultMessage: "Restricted Accounts",
  },
  followers: {
    id: "crm.PartnershipSettings.constants.followers",
    defaultMessage: "Followers",
  },
  overview: {
    id: "crm.PartnershipSettings.constants.overview",
    defaultMessage: "Overview",
  },
  collaboration: {
    id: "crm.PartnershipSettings.constants.collaboration",
    defaultMessage: "Get Intro settings",
  },
});

export const TABS: TabConfig<Tab>[] = [
  {
    value: Tab.Overview,
    label: <FormattedMessage {...i18n.overview} />,
  },
  { value: Tab.General, label: <FormattedMessage {...i18n.general} /> },
  {
    value: Tab.DataSources,
    label: <FormattedMessage {...i18n.dataSources} />,
  },
  {
    value: Tab.Collaboration,
    label: <FormattedMessage {...i18n.collaboration} />,
  },
  {
    value: Tab.RestrictedAccounts,
    label: <FormattedMessage {...i18n.restrictedAccounts} />,
  },
  {
    value: Tab.Followers,
    label: <FormattedMessage {...i18n.followers} />,
  },
];

export const isTabValue = (maybeTab?: string): maybeTab is Tab =>
  (Object.values(Tab) as string[]).includes(maybeTab || "not a tab");
