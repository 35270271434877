import { Box, CircularProgress, Paper } from "@mui/material";
import { ArrowLeft, ArrowRight } from "components/icons";
import Alert from "components/ui/Alert";
import Button from "components/ui/Button";
import Title from "components/ui/Title";
import { T } from "components/ui/Typography";
import { crmProviders, ProviderType } from "config/constants";
import getFirstValueFromURL from "helpers/getFirstValueFromURL";
import useSegment from "hooks/useSegment";
import { makeStyles } from "makeStyles";
import { defineMessages, FormattedMessage } from "react-intl";
import { useHistory, useLocation } from "react-router-dom";
import {
  BaseProps,
  useAssignmentWidget,
} from "screens/Frontoffice/shared/components/SourceSettingsDialog/components/AssignmentWidget";
import { updateHistoryUpdatedSource } from "screens/Frontoffice/shared/components/SourceSettingsDialog/helpers/utils";

import { BaseContent, sharedStyles } from "./BaseContent";

export const AssignmentContent = () => {
  const { classes: titleClasses } = useTitleStyles();
  const location = useLocation();
  const provider = getFirstValueFromURL(location, "provider");
  const integrationId = getFirstValueFromURL(location, "integration");
  const config = provider ? crmProviders[provider as ProviderType] : undefined;

  if (!integrationId) {
    throw new Error("No integration ID");
  }
  if (!provider) {
    throw new Error("No provider");
  }

  return (
    <BaseContent>
      <Title
        section={config?.name}
        label={<FormattedMessage {...i18n.section} />}
        avatar={config?.logo}
        variant="h1"
        classes={titleClasses}
      />
      <BaseAssignmentWidget integrationId={integrationId} />
    </BaseContent>
  );
};

const BaseAssignmentWidget = (props: BaseProps) => {
  const { defaultAll } = props;
  const location = useLocation();
  const history = useHistory();
  const { track } = useSegment();
  const {
    content,
    handleSave,
    hasChanged,
    saving,
    loading,
  } = useAssignmentWidget(props);
  const { cx, classes } = sharedStyles({});

  const handleGoBack = () => {
    history.replace({
      ...location,
      hash: "status-rules",
    });
  };

  const handleSaveMapping = async () => {
    await handleSave();
    track("Edited new crm assignment");
    const updatedLocation = updateHistoryUpdatedSource(history, location);
    history.replace({
      ...updatedLocation,
      hash: "",
    });
  };

  return (
    <>
      <Alert icon className={classes.marginBottomMedium}>
        <div>
          <T className={classes.alertTitle}>
            <FormattedMessage {...i18n.assignmentAlertTitle} />
          </T>
          <T>
            <FormattedMessage {...i18n.assignmentAlertText} />
          </T>
        </div>
      </Alert>
      <Paper className={cx(classes.formPaper, classes.fullWidth)}>
        {loading ? (
          <Box className={classes.textCenter}>
            <CircularProgress />
          </Box>
        ) : (
          content
        )}
      </Paper>
      <div className={classes.action}>
        <Button
          disabled={!hasChanged && !defaultAll}
          label={i18n.validate}
          loading={saving}
          RightIcon={ArrowRight}
          onClick={handleSaveMapping}
        />
        <Button
          disabled={saving}
          label={i18n.back}
          LeftIcon={ArrowLeft}
          onClick={handleGoBack}
          variant="tertiary"
        />
      </div>
    </>
  );
};

const useTitleStyles = makeStyles()(() => ({
  avatar: {
    width: 60,
    height: 60,
  },
  section: {
    fontWeight: 600,
  },
}));

const i18n = defineMessages({
  back: {
    id: "AssignmentContent.back",
    defaultMessage: "Back",
  },
  assignmentAlertText: {
    id: "AssignmentContent.assignmentAlertText",
    defaultMessage:
      "You will define which of your existing Reveal partners you share data with from this source. Data sharing will be defined by the level of sharing you have with each partner.",
  },
  assignmentAlertTitle: {
    id: "AssignmentContent.assignmentAlertTitle",
    defaultMessage: "Assignment",
  },
  section: {
    id: "AssignmentContent.section",
    defaultMessage: "Assign your data source",
  },
  validate: {
    id: "AssignmentContent.validate",
    defaultMessage: "Validate",
  },
});
