import { createSvgIcon } from "@mui/material/utils";

import { SvgIcon } from "./types";

const displayName = "Lock";

const Lock: SvgIcon = createSvgIcon(
  // Using transform to enlarge from provided viewbox="0 0 16 16"
  // to default "0 0 24 24" from `createSvgIcon` -> `SvgIcon`.
  <g fill="none" transform="matrix(1.5,0,0,1.5,1,-1)">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4.5 5C4.5 3.067 6.067 1.5 8 1.5C9.933 1.5 11.5 3.067 11.5 5V5.5C12.6046 5.5 13.5 6.39543 13.5 7.5V12.5C13.5 13.6046 12.6046 14.5 11.5 14.5H4.5C3.39543 14.5 2.5 13.6046 2.5 12.5V7.5C2.5 6.39543 3.39543 5.5 4.5 5.5V5ZM10.5 5.5H5.5V5C5.5 3.61929 6.61929 2.5 8 2.5C9.38071 2.5 10.5 3.61929 10.5 5V5.5ZM9.125 9.25C9.125 9.69172 8.87043 10.074 8.5 10.2581V11.375C8.5 11.6511 8.27614 11.875 8 11.875C7.72386 11.875 7.5 11.6511 7.5 11.375V10.2581C7.12957 10.074 6.875 9.69172 6.875 9.25C6.875 8.62868 7.37868 8.125 8 8.125C8.62132 8.125 9.125 8.62868 9.125 9.25Z"
      fill="currentColor"
    />
  </g>,
  displayName
);

Lock.displayName = displayName;
export default Lock;
