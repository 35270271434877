import { isFulfilled } from "@reduxjs/toolkit";
import Button from "components/ui/Button";
import { DatePicker } from "components/ui/DatePicker";
import { T } from "components/ui/Typography";
import usePushNotification from "hooks/usePushNotification";
import useSegment from "hooks/useSegment";
import generic from "i18n/generic";
import { makeStyles } from "makeStyles";
import { useCallback, useMemo } from "react";
import { FormattedMessage } from "react-intl";
import { useDispatch } from "react-redux";
import { update } from "redux/api/thunks";
import { AvailablePipelineColumns } from "redux/pipeline/defaults";
import { PipelineEvent, PipelineTableEditAction } from "tracking";

import { CellProps } from "../../utils";

export const ActivityDateCell = ({ row }: CellProps) => {
  const { classes } = useStyles();
  const { track } = useSegment();
  const dispatch = useDispatch();
  const pushNotification = usePushNotification();

  const updateActivityDate = useCallback(
    async (date: Date | null) => {
      const response = await dispatch(
        update({
          type: "partner_connections",
          id: row.id,
          attributes: {
            activity_date: date ? date?.toISOString() : null,
          },
        })
      );

      if (isFulfilled(response)) {
        track(PipelineEvent.tableEdit, {
          action: PipelineTableEditAction.updateActivityDate,
        });
      } else {
        pushNotification("default_error");
      }
    },
    [dispatch, row.id, track, pushNotification]
  );

  const datePickerChild = useCallback(
    (onClick) => {
      const date = row.activityDate;
      const label = date ? (
        new Date(date).toLocaleDateString()
      ) : (
        <T className={classes.alpha}>
          <FormattedMessage {...generic.select} />
        </T>
      );
      const handleClick = (e: MouseEvent) => {
        e.stopPropagation();
        e.preventDefault();
        onClick(e);
      };
      return (
        <Button
          label={label}
          onClick={handleClick}
          variant="septenary"
          size="small"
          ellipsis
          classes={{ btn: classes.btn }}
        />
      );
    },
    [classes.btn, classes.alpha, row.activityDate]
  );

  const DatePickerComponent = useMemo(() => {
    return (
      <DatePicker
        onChange={updateActivityDate}
        value={
          row.activityDate ? new Date(row.activityDate) : new Date(Date.now())
        }
      >
        {datePickerChild}
      </DatePicker>
    );
  }, [datePickerChild, row.activityDate, updateActivityDate]);

  return DatePickerComponent;
};

export const isActivityDateCell = ({ fieldName }: CellProps) => {
  return fieldName === AvailablePipelineColumns.activityDate;
};

// CSS

const useStyles = makeStyles()((theme) => ({
  alpha: {
    color: theme.palette.alpha500,
  },
  btn: {
    width: "fit-content",
  },
}));
