import User from "models/User";
import { APICollection } from "redux/api/typing";

export interface Settings {
  terms_of_use_version: string;
}

export interface Context {
  error: boolean;
  initialized: boolean;
  foldedSidebar: boolean;
  settings: Settings | null;
  companyPayingFeatureSubscriptionIds: number[];
  ready: boolean | null;
  partnershipIds: number[];
  partnershipTagIds: number[];
  partnershipGetIntroIds?: number[] | null;
  userIds: number[];
  adminUserIds: number[];
}

export interface RootStateWithContext {
  context: Context;
  api: {
    entities: {
      partnerships?: APICollection;
      company_paying_feature_subscriptions?: APICollection;
    };
  };
  user: {
    profile: {
      data: User;
    };
  };
}

export enum InitActions {
  initStore = "init/initStore",
  fetchSettings = "init/fetchSettings",
  toggleSidebar = "init/toggleSidebar",
  loadCompanyPayingFeatureSubscriptions = "init/loadCompanyPayingFeatureSubscriptions",
  initialize = "init/initializePartnershipsAndCompanyNames",
  loadPartnershipTags = "init/loadPartnershipTags",
  addCreatedTag = "init/addCreatedTag",
  loadUsers = "init/loadUsers",
  loadAdminUsers = "init/loadAdminUsers",
  loadPartnershipsGetIntro = "init/loadPartnershipsGetIntro",
}
