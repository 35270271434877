import { isRejected } from "@reduxjs/toolkit";
import { Plus, Trash } from "components/icons";
import { CompanyAvatar as CompanyAvatarComponent } from "components/ui/avatars/CompanyAvatar";
import Button from "components/ui/Button";
import { T } from "components/ui/Typography";
import usePushNotification from "hooks/usePushNotification";
import useUserProfile from "hooks/useUserProfile";
import generic from "i18n/generic";
import { makeStyles } from "makeStyles";
import { ChangeEvent, useRef } from "react";
import { defineMessages, FormattedMessage } from "react-intl";
import { useDispatch } from "react-redux";
import { fetchProfile } from "redux/user/thunks";
import JSONAPIService from "services/JSONAPIService";

const attachToRecord = async (id: number, avatar: File) => {
  const service = new JSONAPIService("companies");
  return await service.rawUpload(id, "/avatar", { avatar });
};

const removeFromRecord = async (id: number) => {
  const service = new JSONAPIService("companies");
  return await service.rawDelete(id, "", "/avatar");
};

export const CompanyAvatar = () => {
  const { classes } = useStyles();
  const inputRef = useRef<HTMLInputElement | null>(null);
  const dispatch = useDispatch();
  const pushNotification = usePushNotification();
  const {
    profile: { company },
  } = useUserProfile();

  const onClickOpenInput = () => {
    if (inputRef.current) {
      inputRef.current.click();
    }
  };

  const deleteAvatar = async () => {
    try {
      await removeFromRecord(company.id);
      const result = await dispatch(fetchProfile());
      if (isRejected(result)) {
        throw new Error("Error fetching profile");
      }
    } catch {
      pushNotification(generic.updateError);
    }
  };

  const handleChange = async (event: ChangeEvent<HTMLInputElement>) => {
    if (event.target.files && event.target.files?.length > 0) {
      try {
        await attachToRecord(company.id, event.target.files[0]);
        const result = await dispatch(fetchProfile());
        if (isRejected(result)) {
          throw new Error("Error fetching profile");
        }
      } catch {
        pushNotification(generic.updateError);
      }
    }
  };

  return (
    <div className={classes.root}>
      <CompanyAvatarComponent company={company} />
      {!company.avatarUrl ? (
        <>
          <div className={classes.add}>
            <Button
              label={i18n.btnLabel}
              LeftIcon={Plus}
              onClick={onClickOpenInput}
              size="small"
              variant="secondary"
            />
            <T bodySmall>
              <FormattedMessage {...i18n.instructions} />
            </T>
          </div>
          <input
            ref={inputRef}
            className={classes.input}
            type="file"
            data-testid="avatar-file-input"
            accept=".png,.jpg,.jpeg"
            onChange={handleChange}
          />
        </>
      ) : (
        <Button
          label={i18n.removeBtnLabel}
          LeftIcon={Trash}
          onClick={deleteAvatar}
          variant="secondary"
          size="small"
        />
      )}
    </div>
  );
};

export default CompanyAvatar;

/// CSS

const useStyles = makeStyles()((theme) => ({
  root: {
    display: "flex",
    alignItems: "center",
    gap: 12,
    marginBottom: theme.spacing(3),
  },
  input: {
    display: "none",
  },
  add: {
    display: "flex",
    alignItems: "center",
    gap: 12,
    [theme.breakpoints.down("md")]: {
      flexDirection: "column",
      alignItems: "flex-start",
      gap: 4,
    },
  },
}));

// I18N

const i18n = defineMessages({
  btnLabel: {
    id: "Workspace.Avatar.Button",
    defaultMessage: "Upload photo",
  },
  instructions: {
    id: "Workspace.Avatar.Instructions",
    defaultMessage: "At least 256x256px PNG or JPG file",
  },
  removeBtnLabel: {
    id: "Workspace.Avatar.RemoveButton",
    defaultMessage: "Remove",
  },
});
