import { createSvgIcon } from "@mui/material/utils";

import { SvgIcon } from "./types";

const displayName = "Info";

const Info: SvgIcon = createSvgIcon(
  // Using transform to enlarge from provided viewbox="0 0 16 16"
  // to default "0 0 24 24" from `createSvgIcon` -> `SvgIcon`.
  <g fill="none" transform="matrix(1.5,0,0,1.5,0,0)">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8 1.5C4.41015 1.5 1.5 4.41015 1.5 8C1.5 11.5899 4.41015 14.5 8 14.5C11.5899 14.5 14.5 11.5899 14.5 8C14.5 4.41015 11.5899 1.5 8 1.5ZM2.5 8C2.5 4.96243 4.96243 2.5 8 2.5C11.0376 2.5 13.5 4.96243 13.5 8C13.5 11.0376 11.0376 13.5 8 13.5C4.96243 13.5 2.5 11.0376 2.5 8ZM7.5 10.625C7.5 10.9011 7.72386 11.125 8 11.125C8.27614 11.125 8.5 10.9011 8.5 10.625V7.625C8.5 7.34886 8.27614 7.125 8 7.125C7.72386 7.125 7.5 7.34886 7.5 7.625V10.625ZM8 4.625C7.58579 4.625 7.25 4.96079 7.25 5.375C7.25 5.78921 7.58579 6.125 8 6.125C8.41421 6.125 8.75 5.78921 8.75 5.375C8.75 4.96079 8.41421 4.625 8 4.625Z"
      fill="currentColor"
    />
  </g>,
  displayName
);

Info.displayName = displayName;
export default Info;
