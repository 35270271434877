import { Box } from "@mui/material";
import { T } from "components/ui/Typography";
import { makeStyles } from "makeStyles";
import Match from "models/Match";
import PartnerConnection from "models/PartnerConnection";
import {
  defineMessages,
  FormattedMessage,
  IntlShape,
  useIntl,
} from "react-intl";
import { AvailablePipelineColumns } from "redux/pipeline/defaults";

import { CellProps } from "../../utils";

export const PipelineStatusCell = ({
  row,
}: Omit<CellProps, "row"> & { row: PartnerConnection }) => {
  const { classes } = useStyles();
  const intl = useIntl();

  const isPartnerConnectionNewProspect =
    typeof row.rawCompanyId !== "number" && !row.rawCompanyId;
  const value = pipelineStatusValueFormatter(row, intl);

  if (isPartnerConnectionNewProspect) {
    return (
      <Box>
        <T className={classes.alpha}>
          <FormattedMessage {...i18n.notInCrm} />
        </T>
      </Box>
    );
  }

  return <Box>{value}</Box>;
};

export const isPipelineStatusCell = ({ fieldName }: CellProps) =>
  fieldName === AvailablePipelineColumns.rawCompanyStatus;

const pipelineStatusValueFormatter = (
  row: PartnerConnection,
  intl: IntlShape
) => {
  const value = row.rawCompanyStatus;
  if (value !== null) {
    const label = Match.STATUS_LABELS[value];
    if (label) {
      return intl.formatMessage(label);
    }
  }
  return "--";
};

// CSS

const useStyles = makeStyles()((theme) => ({
  alpha: {
    color: theme.palette.alpha500,
  },
}));

// I18N

const i18n = defineMessages({
  notInCrm: {
    id: "crm.MyPipeline.CellFormatters.notInCrm",
    defaultMessage: "Not in your CRM",
  },
});
