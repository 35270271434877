import _, { cloneDeep } from "lodash";
import GhostPartner from "models/GhostPartner";
import Integration from "models/Integration";
import Match from "models/Match";
import ModelRecord from "models/Record";
import { MessageDescriptor } from "react-intl";
import {
  Available360MappingColumns,
  mapping360ColumnsConfig,
} from "redux/mapping360/defaults";
import {
  Facets,
  FieldType,
} from "screens/Frontoffice/screens/DataTables/shared/types";

import {
  Mapping360StandardFieldName,
  Mapping360StandardFields,
} from "./components/HeaderActions/types";
import { Contact, PartnerAccount, RawContact } from "./types";

export const MANUAL_KEY_TO_SORT = {
  partnerPresence: Available360MappingColumns.nbPartners,
  partnerOpportunities: Available360MappingColumns.nbOpportunities,
};

export const MANUAL_KEY_TO_FILTER = {
  partnerPresence: Available360MappingColumns.customerPresence,
  partnerOpportunities: Available360MappingColumns.prospectPresence,
};

export const enrichFields = (
  fields: Mapping360StandardFields,
  newKey: Mapping360StandardFieldName,
  newFieldsAttributes: Pick<FieldType, "label" | "type">
) => {
  const baseField = {
    fullyImported: true,
    isDisabled: false,
    isPrivate: false,
    last: true,
    partnershipCrmFieldId: undefined,
    smartField: true,
    partnerField: true,
    filter: false,
    sort: false,
    noSide: false,
    options: {},
  };
  fields[newKey] = {
    ...baseField,
    ...newFieldsAttributes,
  };
};

export const isMapping360StandardFields = (
  fields: Record<string, FieldType | undefined>
): fields is Mapping360StandardFields => {
  return "name" in fields;
};

export const mapping360ColumnsConfigForLegacyPage = () => {
  const config = cloneDeep(mapping360ColumnsConfig);

  config[Available360MappingColumns.partnerPresence].fixedPosition = -1;
  config[Available360MappingColumns.partnerOpportunities].fixedPosition = -2;
  config[Available360MappingColumns.partnerSignals].fixedPosition = -3;
  config[Available360MappingColumns.winRateMultiplier].fixedPosition = -4;
  config[Available360MappingColumns.revenueMultiplier].fixedPosition = -5;
  config[Available360MappingColumns.dealSizeMultiplier].fixedPosition = -6;

  return config;
};

export const addFacets = (facets: Facets, source: Integration) => {
  const newFacets: Facets = { ...facets };
  const stats: ModelRecord | undefined = source.statistics;
  if (stats) {
    newFacets[-1] += stats.numberOfAccounts;
    newFacets[-2] += stats.numberOfProspectsWithoutOpportunity;
    newFacets[-3] += stats.numberOfProspectsWithOpportunity;
    newFacets[-4] += stats.numberOfProspects;
    newFacets[-5] += stats.numberOfCustomers;
  }
  return newFacets;
};

export enum GoalBased360Event {
  GoalSelected = "Selected goal",
  GoalInfoButtonClicked = "Info button clicked on",
  RequestNewProspects = "Request 360 New Prospects",
}

export const goalBased360EventName = (
  eventType: string,
  goalName: MessageDescriptor
): string => {
  const eventName = `${eventType} ${goalName.defaultMessage}`;
  return eventName;
};

const acceptedStatus = [Match.STATUS_CUSTOMER, Match.STATUS_PROSPECT, null];

const mapStatusToCustomerOrActiveProspect = (match: Match) => {
  if (match.status === Match.STATUS_CUSTOMER) {
    return Match.STATUS_CUSTOMER;
  } else if (
    (match.status === Match.STATUS_PROSPECT || match.status === null) &&
    match.numberOfOpenOpportunities > 0
  ) {
    return Match.STATUS_ACTIVE_PROSPECT;
  }
};

export const getPartnerAccounts = (matches: [Match]) => {
  // @ts-ignore
  const partnerAccounts: PartnerAccount[] = _(matches)
    .filter((match: Match) => acceptedStatus.includes(match.status))
    .orderBy(
      [
        (match) => match.ghostPartner?.companyName || match.company?.name,
        "score",
      ],
      ["asc", "desc"]
    )
    .groupBy(
      (match: Match) =>
        `${_.get(match, "company.id", "")}-${_.get(
          match,
          "ghostPartner.id",
          ""
        )}`
    )
    .map((matches: Match[]) => {
      const nbKeyContacts = _.sum(
        _(matches)
          .map((match: Match) =>
            _.get(match, "extraContactDataV2.number_of_primary_contacts", 0)
          )
          .value()
      );
      const ghostPartner = matches[0].ghostPartner as GhostPartner;
      return {
        partner: ghostPartner ? null : (matches[0].company as ModelRecord),
        ghostPartner: ghostPartner,
        matches: _(matches)
          .orderBy(["status", "score"], ["asc", "desc"])
          .value(),
        firstWonOpportAt: _.min(
          _(matches)
            .map((match: Match) => match.firstWonOpportAt)
            .filter(Boolean)
            .value()
        ),
        nbKeyContacts: nbKeyContacts,
        nbOtherContacts:
          _.sum(
            _(matches)
              .map((match: Match) =>
                _.get(match, "extraContactDataV2.number_of_contacts", 0)
              )
              .value()
          ) - nbKeyContacts,
        nbOpenOpports: _.sum(
          _(matches)
            .map((match: Match) => _.get(match, "numberOfOpenOpportunities", 0))
            .value()
        ),
        owners: _(matches)
          .map((match: Match) => match.owner)
          .filter(Boolean)
          .uniqBy("id")
          .value(),
      } as PartnerAccount;
    })
    .flatMap((partnerAccount: PartnerAccount) => {
      return _(partnerAccount.matches)
        .map(mapStatusToCustomerOrActiveProspect)
        .uniq()
        .filter(Boolean)
        .map((status: number) => {
          return { ...partnerAccount, status: status } as PartnerAccount;
        })
        .value();
    })
    .value();
  return partnerAccounts;
};

export const getCustomers = (matches: [Match]) => {
  const accounts: PartnerAccount[] = getPartnerAccounts(matches);
  return accounts.filter(
    (account: PartnerAccount) => account.status === Match.STATUS_CUSTOMER
  );
};

export const getActiveProspects = (matches: [Match]) => {
  const accounts: PartnerAccount[] = getPartnerAccounts(matches);
  return accounts.filter(
    (account: PartnerAccount) => account.status === Match.STATUS_ACTIVE_PROSPECT
  );
};

// Partner contacts helpers

export const getCommonContacts = (
  match: ModelRecord<"matches"> | null,
  commonContacts: boolean
) => {
  const contacts: Contact[] = (match?.rightContactsData ?? []).map(
    (contact: RawContact) => {
      return {
        contactUrl: contact.contact_urls?.[0],
        jobtitle: contact.jobtitle,
        isCommon: contact.overlaps,
        isPrimary: contact.opports > 0,
      };
    }
  );

  // Split contacts by common contacts and others
  let items = filterByOverlaps(contacts, commonContacts);
  // Sort by primary attribute
  items = sortByPrimary(items);
  return items;
};

const filterByOverlaps = (contacts: Contact[], isCommon: boolean) => {
  return contacts.filter((contact) => contact.isCommon === isCommon);
};

const sortByPrimary = (contacts: Contact[]) => {
  return contacts.sort((a, b) => Number(b.isPrimary) - Number(a.isPrimary));
};

export const removeDuplicates = (contacts: Contact[]) => {
  return contacts.filter(
    (value, index, self) =>
      index ===
      self.findIndex(
        (t) => t.jobtitle === value.jobtitle && t.isPrimary === value.isPrimary
      )
  );
};
