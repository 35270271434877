import { Grid } from "@mui/material";
import { isRejected } from "@reduxjs/toolkit";
import BaseLoginPage from "components/ui/BaseLoginPage";
import Button from "components/ui/Button";
import { T } from "components/ui/Typography";
import {
  companySizeGreaterThan10,
  companySizeGreaterThan50,
  displayOnboardingMeetingDialog,
  FORM_CONTAINER_ID,
} from "helpers/bookMeetingUtils";
import { goToNext } from "helpers/goToNext";
import useUserProfile from "hooks/useUserProfile";
import useWidth from "hooks/useWidth";
import _ from "lodash";
import { makeStyles } from "makeStyles";
import { PayingFeature } from "models/CompanyPayingFeatureSubscription";
import User from "models/User";
import { useCallback, useEffect, useMemo, useState } from "react";
import { defineMessages, FormattedMessage } from "react-intl";
import { useDispatch } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import { rawPost } from "redux/api/thunks";
import { RevealStore } from "redux/typing";
import { setProfile } from "redux/user/actions";
import { fetchProfile } from "redux/user/thunks";
import UserService from "services/UserService";

// Conditions for not displaying Chili Piper:
// 0. User is impersonating or skipped the step already
// 1. User is from a free company and does not have more than 10 employees
// 2. User is from a paid company but is not an admin
export const validateBookDemo = (profile: User, store: RevealStore) => {
  const isPaidUser =
    profile?.company?.companyPayingFeatureSubscriptions?.find(
      (payingFeature: any) =>
        payingFeature.payingFeature === PayingFeature.UnlimitedPipelineSharing
    )?.enabled ?? false;
  const service = new UserService();
  const isFreeUserFromSmallCompany =
    !isPaidUser && !companySizeGreaterThan10(profile);
  const isPaidUserNotAdmin = isPaidUser && !profile.isCompanyAdmin();
  if (
    !service.isImpersonating &&
    !profile.hasOnboardingSkipStep("demo_booking") &&
    !isFreeUserFromSmallCompany &&
    !isPaidUserNotAdmin
  ) {
    return false;
  }
  return true;
};

const APPROVAL_REQUEST_PERIOD_IN_MILLIS = 24 * 60 * 60 * 1000;

const OnboardingBookDemo = () => {
  const { profile } = useUserProfile();
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const width = useWidth();
  const smallWidth = width === "sm" || width === "xs" || false;
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [isRouted, setIsRouted] = useState(false);
  const { classes } = useStyles({ smallWidth, isSubmitted });

  const approvalNeeded =
    Boolean(profile.company) && !profile.canAccessWorkspace;

  const canRequestApproval =
    approvalNeeded &&
    (profile.lastPermissionRequest === null ||
      Date.now() - new Date(profile.lastPermissionRequest).getTime() >
        APPROVAL_REQUEST_PERIOD_IN_MILLIS);

  const setStepAsViewedAndGoNext = useCallback(async () => {
    // Creating the onboardingSkip element to not display this step anymore
    await dispatch(
      rawPost({
        type: "onboarding_skips",
        path: "",
        payload: {
          data: {
            type: "onboarding_skips",
            attributes: {
              step: "demo_booking",
            },
          },
        },
      })
    );
    const fetchProfilerResult = await dispatch(fetchProfile());
    if (isRejected(fetchProfilerResult)) {
      throw new Error("Error fetching profile");
    }
    if (canRequestApproval) {
      const service = new UserService();
      const response = await service.requestPermissions();
      dispatch(setProfile(User.fromResponse(response)));
    }
    // Redirect to "next"
    history.push(goToNext(location, "/"));
  }, [dispatch, history, location, canRequestApproval]);

  const isCompanySizeGreaterThan50 = useMemo(
    () => companySizeGreaterThan50(profile),
    [profile]
  );
  const isViral = _.get(profile, "company.viral");

  useEffect(() => {
    if (profile.hasOnboardingSkipStep("demo_booking") || isRouted) {
      return;
    }
    setIsRouted(true);
    displayOnboardingMeetingDialog(
      profile,
      () => setIsSubmitted(true),
      () => setStepAsViewedAndGoNext()
    );
  }, [
    profile,
    setStepAsViewedAndGoNext,
    isCompanySizeGreaterThan50,
    isRouted,
    isViral,
  ]);

  const leftPanelContent = (
    <Grid item xs={12}>
      <div className={classes.logoContainer}>
        <img
          src="/images/onboarding/PartnerEcosystemPlatforms_UsersLoveUs.png"
          alt=""
        />
        <img
          src="/images/onboarding/PartnerEcosystemPlatforms_Leader.png"
          alt=""
        />
        <img
          src="/images/onboarding/PartnerEcosystemPlatforms_BestSupport.png"
          alt=""
        />
      </div>
    </Grid>
  );

  return (
    <BaseLoginPage
      hidePaper
      content={leftPanelContent}
      title={i18n.leftPanelTitle}
      titleVariant="small"
    >
      <div className={classes.root}>
        <img
          alt="Resource not found."
          src="/images/platform-experts.png"
          className={classes.revealers}
        />
        <T h2 bold className={classes.title}>
          <FormattedMessage {...i18n.title} />
        </T>
        <T className={classes.title}>
          <FormattedMessage
            {...(isCompanySizeGreaterThan50 || isViral
              ? i18n.subtitlePersonalMeeting
              : i18n.subtitleGroupMeeting)}
          />
        </T>
        <div className={classes.btnContainer}>
          <Button
            label={isSubmitted ? i18n.next : i18n.skip}
            variant="secondary"
            onClick={setStepAsViewedAndGoNext}
          />
        </div>
        <div id={FORM_CONTAINER_ID} className={classes.calendarContainer}></div>
      </div>
    </BaseLoginPage>
  );
};

export default OnboardingBookDemo;

// I18N

const i18n = defineMessages({
  leftPanelTitle: {
    id: "OnboardingBookDemo.leftPanelTitle",
    defaultMessage:
      "Reveal is Leader in the<br/>Partner Ecosystem<br/>Category, on G2",
  },
  next: {
    id: "OnboardingBookDemo.next",
    defaultMessage: "Next",
  },
  title: {
    id: "OnboardingBookDemo.title",
    defaultMessage: "We would love to meet!",
  },
  subtitlePersonalMeeting: {
    id: "OnboardingBookDemo.subtitlePersonalMeeting",
    defaultMessage: "Book an onboarding call with our team",
  },
  subtitleGroupMeeting: {
    id: "OnboardingBookDemo.subtitleGroupMeeting",
    defaultMessage: "Book a group enablement session",
  },
  skip: {
    id: "OnboardingBookDemo.skip",
    defaultMessage: "Skip",
  },
});

// CSS
const useStyles = makeStyles<{ smallWidth: boolean; isSubmitted: boolean }>()(
  (theme, { smallWidth, isSubmitted }) => ({
    calendarContainer: {
      height: "540px",
      width: smallWidth ? "340px" : "520px",
      position: "relative",
      overflow: "hidden",
      "& .chilipiper-popup": {
        background: "transparent !important",
        borderRadius: 10,
        height: "100%",
        overflow: "auto",
      },
      "& .chilipiper-popup-window": {
        borderRadius: 10,
        height: isSubmitted ? "688px !important" : "480px !important",
        position: "absolute",
        top: 0,
        width: smallWidth ? "340px !important" : "520px !important",
      },
    },
    logoContainer: {
      display: "flex",
      gap: theme.spacing(3),
      justifyContent: "center",
      "& > img": {
        maxWidth: "100%",
        maxHeight: 125,
      },
    },
    root: {
      alignItems: "center",
      display: "flex",
      width: "100%",
      flexDirection: "column",
      margin: "auto",
      rowGap: "8px",
    },
    revealers: {
      width: 160,
      marginBottom: theme.spacing(2),
      marginTop: theme.spacing(1),
      [theme.breakpoints.down("lg")]: {
        width: 114,
      },
    },
    title: {
      [theme.breakpoints.down("md")]: {
        color: theme.palette.offWhite,
      },
    },
    btnContainer: {
      display: "flex",
      justifyContent: "center",
      marginTop: theme.spacing(2),
      [theme.breakpoints.down("lg")]: {
        marginBottom: theme.spacing(4),
      },
    },
    gutterBottom: {
      marginBottom: 24,
    },
  })
);
