import { CircularProgress } from "@mui/material";
import { Plus, Undo } from "components/icons";
import Button from "components/ui/Button";
import Tag from "components/ui/Tag";
import Tooltip from "components/ui/Tooltip";
import { T } from "components/ui/Typography";
import { crmProviders, ProviderType } from "config/constants";
import { makeStyles } from "makeStyles";
import Integration from "models/Integration";
import { MouseEvent, useMemo, useState } from "react";
import { defineMessages, FormattedMessage, useIntl } from "react-intl";

import Dropdown from "./Dropdown/components/Dropdown";
import { IDropdownOption } from "./Dropdown/components/types";

export interface Option {
  id: string | number;
  crmLabel: string;
  integration?: Integration;
}

type Props = {
  allFields: Option[];
  selectedFields: Option[];
  setSelectedFields: (value: Option[]) => void;
  loading?: boolean;
  showIntegrationLogo?: boolean;
  emptySelectionPlaceholder?: JSX.Element;
  onReset?: () => void;
  showReset: boolean;
  hasAsyncSearch?: boolean;
  onAsyncSearch?: (query: string) => void;
  extraLastOption?: JSX.Element;
  disabled?: boolean;
  hideAddFieldButton?: boolean;
  isRulesWidget?: boolean;
  addButtonLabel?: string;
  fieldsLimit?: number;
};

export const ChooseFieldsBlock = ({
  allFields,
  selectedFields,
  setSelectedFields,
  loading,
  showIntegrationLogo,
  emptySelectionPlaceholder,
  onReset,
  showReset,
  hasAsyncSearch,
  onAsyncSearch,
  disabled,
  hideAddFieldButton,
  extraLastOption,
  isRulesWidget,
  addButtonLabel,
  fieldsLimit,
}: Props) => {
  const intl = useIntl();
  const { classes, cx } = useStyles({ hideAddFieldButton });
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const [open, setOpen] = useState(false);

  const handleCloseDropdown = () => {
    setOpen(false);
    setAnchorEl(null);
  };

  const remainingFieldsCount = fieldsLimit
    ? fieldsLimit - selectedFields.length
    : allFields.length - selectedFields.length;

  const handleDeleteField = (field: Option) => {
    setSelectedFields(selectedFields.filter((item) => item !== field));
  };

  const defaultAddButtonLabel = intl.formatMessage(i18n.addField);

  const addFieldButton = (hasAsyncSearch || remainingFieldsCount > 0) &&
    !hideAddFieldButton && (
      <Button
        LeftIcon={Plus}
        label={
          selectedFields.length === 0
            ? addButtonLabel ?? defaultAddButtonLabel
            : ""
        }
        aria-label={addButtonLabel ?? defaultAddButtonLabel}
        size={selectedFields.length === 0 ? "small" : "xSmall"}
        onClick={(event: MouseEvent<HTMLButtonElement>) => {
          setOpen(!open);
          setAnchorEl(event.currentTarget);
        }}
        data-testid="add-more-fields"
        disabled={disabled}
        variant={selectedFields.length === 0 ? undefined : "quinary"}
      />
    );

  const remainingFieldsInfo = (fieldsLimit || emptySelectionPlaceholder) &&
    remainingFieldsCount > 0 &&
    !isRulesWidget && (
      <T className={classes.infoFieldCount}>
        {selectedFields.length === 0 ? (
          emptySelectionPlaceholder
        ) : (
          <FormattedMessage
            {...i18n.remainingFields}
            values={{ count: remainingFieldsCount }}
          />
        )}
      </T>
    );

  const maybeResetButton = showReset && (
    <Tooltip
      title={
        <T>
          <FormattedMessage {...i18n.resetDefault} />
        </T>
      }
    >
      <div>
        <Button
          variant="tertiary"
          size="small"
          onClick={onReset}
          LeftIcon={Undo}
          classes={{ btn: classes.resetButton }}
          aria-label={intl.formatMessage(i18n.resetDefault)}
          disabled={disabled}
        />
      </div>
    </Tooltip>
  );

  const availableFields = allFields.filter(
    (field) => !selectedFields.includes(field)
  );

  const maybeIntegrationLogo = (field: Option) => {
    if (showIntegrationLogo && field.integration?.provider) {
      const provider = field.integration.provider;
      if (provider === ProviderType.file) {
        return "/images/providers/CSV.png";
      }
      const details = crmProviders[provider as keyof typeof crmProviders];
      if (details) {
        return details.logo;
      }
    }
  };

  const loadOption: IDropdownOption[] = useMemo(() => {
    return availableFields.map((item) => {
      return {
        id: String(item.id),
        name: item.crmLabel,
        logo: maybeIntegrationLogo(item) || null,
      };
    });
  }, [availableFields]); // eslint-disable-line react-hooks/exhaustive-deps

  const selectField = (value: string[] | null) => {
    const fields =
      value &&
      availableFields.find((field) =>
        isRulesWidget
          ? String(field.id) === value[0]
          : field.id === parseInt(value[0])
      );

    if (fields) {
      setSelectedFields([...selectedFields, fields]);
    }
    setOpen(false);
  };

  return loading ? (
    <CircularProgress />
  ) : (
    <div className={classes.container}>
      <div
        className={cx(
          classes.valuesContainer,
          isRulesWidget && classes.rulesValuesContainer
        )}
      >
        {selectedFields.map((field) => (
          <Tag
            label={field.crmLabel}
            onDelete={() => handleDeleteField(field)}
            disabled={disabled}
            logo={maybeIntegrationLogo(field) || undefined}
            classes={isRulesWidget ? { root: classes.rulesTag } : {}}
            type="squared"
          />
        ))}
        {(addFieldButton || remainingFieldsInfo) && (
          <div
            className={cx(
              classes.actionsContainer,
              isRulesWidget && classes.rulesActionsContainer
            )}
            key="add-field-block"
          >
            <Dropdown
              options={loadOption}
              anchorEl={anchorEl}
              onClose={handleCloseDropdown}
              open={open}
              size="small"
              onChange={selectField}
              CustomFooter={
                extraLastOption && (
                  <div className={classes.message}>{extraLastOption}</div>
                )
              }
              hasAsyncSearch={hasAsyncSearch}
              onAsyncSearch={onAsyncSearch}
            />
            {addFieldButton}
            {remainingFieldsInfo}
          </div>
        )}
      </div>
      {maybeResetButton}
    </div>
  );
};

type StyleProps = Pick<Props, "hideAddFieldButton">;

export const useStyles = makeStyles<StyleProps>()(
  (theme, { hideAddFieldButton }) => ({
    container: {
      display: "flex",
      justifyContent: "space-between",
    },
    valuesContainer: {
      display: "flex",
      flexWrap: "wrap",
      gap: 4,
      alignItems: "center",
    },
    rulesValuesContainer: {
      columnGap: 0,
      background: theme.palette.taupe500,
      borderRadius: 15,
    },
    rulesTag: {
      backgroundColor: theme.palette.ivory,
      margin: 2,
      height: 24,
    },
    actionsContainer: {
      display: "flex",
      alignItems: "center",
      height: 28,
    },
    rulesActionsContainer: {
      margin: 2,
      alignItems: "normal",
      height: 24,
    },
    infoFieldCount: {
      marginLeft: hideAddFieldButton ? 0 : theme.spacing(1),
    },
    resetButton: {
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1),
    },
    message: {
      fontSize: 12,
      color: theme.palette.midnight,
      margin: "0 4px",
      "& a": {
        color: theme.palette.midnight,
        textDecoration: "underline",
      },
    },
  })
);

const i18n = defineMessages({
  addField: {
    id: "components.ui.ChooseFieldsBlock.addField",
    defaultMessage: "Add field",
  },
  remainingFields: {
    id: "components.ui.ChooseFieldsBlock.remainingFields",
    defaultMessage:
      "{count, plural, one {Up to 1 more field} other {Up to # more fields}}",
  },
  resetDefault: {
    id: "components.ui.ChooseFieldsBlock.resetDefault",
    defaultMessage: "Reset default setting",
  },
});

export const _private = {
  i18n,
};
