import history from "_history";
import Avatar from "@mui/material/Avatar";
import Badge from "@mui/material/Badge";
import Box from "@mui/material/Box";
import { Offline, Settings } from "components/icons";
import CompanyAvatar from "components/ui/avatars/CompanyAvatar";
import Tooltip from "components/ui/Tooltip";
import { T } from "components/ui/Typography";
import useRecord from "hooks/useRecord";
import useSegment from "hooks/useSegment";
import _ from "lodash";
import { makeStyles } from "makeStyles";
import { PayingFeature } from "models/CompanyPayingFeatureSubscription";
import Partnership from "models/Partnership";
import { useEffect, useState } from "react";
import { defineMessages, FormattedMessage } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { loadView, updateView } from "redux/accountMapping/thunks";
import { AccountMappingResource } from "redux/accountMapping/types";
import { AvailableAnalytisColumns } from "redux/analytics/defaults";
import { selectHighlightHighWinRateEnabled } from "redux/analytics/selectors";
import { selectActivePayingFeatures } from "redux/api/selectors";
import { CompanyAvatarService } from "services/CompanyAvatarService";
import { AdvancedAnalyticsEvent } from "tracking";

import { CellProps } from "../../utils";

const PartnerNameCell = (props: CellProps) => {
  const highlightHighWinRateEnabled = useSelector(
    selectHighlightHighWinRateEnabled
  );
  const payingFeatures = useSelector(selectActivePayingFeatures);
  const isMatchedAccount = payingFeatures.includes(
    PayingFeature.UseNewAccountMapping
  );
  const dispatch = useDispatch();
  const { track } = useSegment();
  const { value, row } = props;
  const { partnerId, partnershipId, partnerAvatarSrc, winRate } = getValues(
    props
  );
  const [avatarSrc, setAvatarSrc] = useState<string | null>(
    partnerAvatarSrc ?? null
  );
  const record = useRecord("partnerships", partnershipId, false)
    .record as Partnership | null;
  const isInitiator = _.get(row, "myPartnershipSide", null) === "init";

  useEffect(() => {
    if (!partnerAvatarSrc && partnershipId !== null) {
      new CompanyAvatarService()
        .getForPartner(partnerId, partnershipId)
        .then(setAvatarSrc);
    } else {
      setAvatarSrc(partnerAvatarSrc ?? null);
    }
  }, [
    partnerId,
    isInitiator,
    partnerAvatarSrc,
    partnershipId,
    row.partnerRequestedCompanyDomain,
  ]);

  // Highlight if win rate is at least 20%
  const isHighlightedCell =
    highlightHighWinRateEnabled && Math.round((winRate as number) * 100) >= 20;

  const { classes, cx } = useStyles({ isHighlightedCell });

  const handleCompanyClick = async () => {
    if (record) {
      const partnership = record as Partnership;
      await dispatch(loadView({ partnership, isMatchedAccount }));
      await dispatch(
        updateView({
          accountType: isMatchedAccount
            ? AccountMappingResource.matched_accounts
            : AccountMappingResource.matches,
          partnership: partnership,
          isMatchedAccount,
        })
      );
      track(AdvancedAnalyticsEvent.clickOnPartnerNameCell);
      history.push({
        pathname: `/partnerships/${partnershipId}/account-mapping`,
      });
    }
  };

  const handleSettingsClick = async () => {
    track(AdvancedAnalyticsEvent.redirectToAccountSettings);
    if (record?.isGhost()) {
      history.push({
        pathname: `/partnerships/${partnershipId}/settings/data-sources`,
      });
      return;
    }
    history.push({
      pathname: `/partnerships/${partnershipId}/settings/general`,
    });
  };

  let avatar = <CompanyAvatar companyName={value} src={avatarSrc} size="xs" />;
  if (record?.isGhost()) {
    avatar = (
      <div>
        <Tooltip title={<FormattedMessage {...i18n.tooltipOffine} />}>
          <Badge
            overlap="circular"
            anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
            badgeContent={
              <Avatar className={classes.offlineBadge}>
                <Offline className={classes.badgeIcon} />
              </Avatar>
            }
          >
            {avatar}
          </Badge>
        </Tooltip>
      </div>
    );
  }

  return (
    <Box className={classes.cellContainer} title={value}>
      <Tooltip
        title={
          isHighlightedCell ? (
            <FormattedMessage
              {...i18n.highWinRatePartner}
              values={{ partnerName: value }}
            />
          ) : (
            ""
          )
        }
        placement="top"
      >
        <>
          <div className={classes.avatarContainer}>{avatar}</div>
          <div className={classes.nameContainer} onClick={handleCompanyClick}>
            <T noWrap>{value}</T>
          </div>
        </>
      </Tooltip>
      <span
        className={cx(classes.settings, "showOnHover")}
        onClick={handleSettingsClick}
      >
        <Settings className={classes.settingsIcon} />
      </span>
    </Box>
  );
};

export default PartnerNameCell;

// Helpers

export const isPartnerNameCell = ({ fieldName }: CellProps) => {
  return fieldName === AvailableAnalytisColumns.partnerCompanyName;
};

const getValues = ({ row }: CellProps) => {
  let partnerId: number | null = null;
  let partnershipId: number | null = null;
  let partnerAvatarSrc: string | null = null;
  let winRate: number | null = null;

  partnershipId = _.get(row, "partnershipId", null);
  partnerAvatarSrc = _.get(row, "partnerAvatarUrl", null);
  winRate = _.get(row, "metrics.win_rate", null);
  partnerId = _.get(row, "partnerId", null);
  return {
    partnerId,
    partnershipId,
    partnerAvatarSrc,
    winRate,
  };
};

// CSS

const useStyles = makeStyles<{ isHighlightedCell: boolean }>()(
  (theme, { isHighlightedCell }) => ({
    cellContainer: {
      display: "flex",
      alignItems: "center",
      width: "100%",
      columnGap: theme.spacing(0.5),
    },
    avatarContainer: {
      position: "absolute",
      marginLeft: theme.spacing(0.5),
    },
    nameContainer: {
      display: "inline-flex",
      alignItems: "center",
      overflow: "hidden",
      height: 32,
      borderRadius: 6,
      backgroundColor: isHighlightedCell
        ? theme.palette.gold050
        : "transparent",
      border: `1px solid ${
        isHighlightedCell ? theme.palette.gold200 : theme.palette.greyscale250
      }`,
      paddingLeft: 30,
      paddingRight: theme.spacing(1),
      cursor: "pointer",
      "&:hover": {
        backgroundColor: isHighlightedCell
          ? theme.palette.gold200
          : theme.palette.ivory,
      },
    },
    settings: {
      display: "none",
      backgroundColor: theme.palette.greyscale200,
      borderRadius: 12,
      padding: 6,
      cursor: "pointer",
    },
    settingsIcon: {
      color: theme.palette.alpha800,
      fontSize: 12,
    },
    offlineBadge: {
      backgroundColor: theme.palette.midnight,
      color: theme.palette.white,
      width: 15,
      height: 15,
      right: 3,
      bottom: 3,
      borderColor: theme.palette.white,
      border: `1.5px solid ${theme.palette.white}`,
      position: "absolute",
      flex: "none",
    },
    badgeIcon: {
      width: 12,
    },
  })
);

// I18N

const i18n = defineMessages({
  highWinRatePartner: {
    id: "ui.datagrid.cellRenderers.PartnerNameCell.highWinRatePartner",
    defaultMessage: "{partnerName} is a high win rate partner.",
  },
  tooltipOffine: {
    id: "ui.datagrid.cellRenderers.PartnerNameCell.tooltipOffine",
    defaultMessage: "Offline partnership",
  },
});
