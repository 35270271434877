import { TabContext, TabList } from "@mui/lab";
import { Box, Tab } from "@mui/material";
import { ClassNameMap } from "@mui/material/styles";
import { makeStyles } from "makeStyles";
import { ReactNode } from "react";
import { useMergedClasses } from "tss-react";

import { Facet } from "./Facet";

type TabData = {
  count?: number;
  value: string;
  label: string;
};

type Props = {
  children: ReactNode;
  tabValue: string;
  onChange: (_event: React.SyntheticEvent, newValue: string) => void;
  tabsData: TabData[];
  classes?: ClassNameMap;
};

export const TabListLight = ({
  tabValue,
  onChange,
  tabsData,
  children,
  classes: newClasses = {},
}: Props) => {
  const { classes: baseClasses } = useStyles();
  const classes = useMergedClasses(baseClasses, newClasses);

  return (
    <TabContext value={tabValue}>
      <Box className={classes.tabButtonGroup}>
        <TabList
          onChange={onChange}
          classes={{
            flexContainer: classes.tabFlexContainer,
            indicator: classes.tabIndicator,
            root: classes.tabsRoot,
          }}
        >
          {tabsData.map((data) => (
            <Tab
              key={data.value}
              classes={{
                root: classes.tabButton,
                selected: classes.tabButtonSelected,
              }}
              value={data.value}
              label={
                <Box className={classes.labelContainer}>
                  {data.label}
                  <Facet
                    value={data.count}
                    isSelected={data.value === tabValue}
                  />
                </Box>
              }
            />
          ))}
        </TabList>
      </Box>
      {children}
    </TabContext>
  );
};

const useStyles = makeStyles()((theme) => ({
  labelContainer: {
    alignItems: "center",
    display: "flex",
    gap: "6px",
    padding: theme.spacing(0, 1),
  },
  tabButton: {
    color: theme.palette.alpha750,
    fontSize: 12,
    fontWeight: 400,
    lineHeight: "150%",
    minWidth: "unset",
    padding: theme.spacing(1, 0),
    textTransform: "none",
    height: 42,
    minHeight: 42,
  },
  tabButtonSelected: {
    color: `${theme.palette.midnight} !important`,
    fontWeight: 500,
  },
  tabButtonGroup: {
    borderBottom: `1px solid ${theme.palette.greyLight100}`,
    position: "sticky",
    top: 0,
    backgroundColor: theme.palette.ivory,
    zIndex: 1,
  },
  tabIndicator: {
    background: theme.palette.midnight,
  },
  tabFlexContainer: {
    gap: theme.spacing(2.5),
  },
  tabsRoot: {
    height: 42,
    minHeight: 42,
  },
}));
