import { createSvgIcon } from "@mui/material/utils";

import { SvgIcon } from "./types";

const displayName = "Close";

const Close: SvgIcon = createSvgIcon(
  // Using transform to enlarge from provided viewbox="0 0 14 14"
  // to default "0 0 24 24" from `createSvgIcon` -> `SvgIcon`.
  <>
    <rect width="24" height="24" rx="12" fill="#EAE6DF" />
    <g clip-path="url(#clip0_1176_7936)">
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M9.34854 8.81802C9.20209 8.67157 8.96466 8.67157 8.81821 8.81802C8.67176 8.96447 8.67176 9.2019 8.81821 9.34835L11.4698 12L8.81817 14.6516C8.67173 14.7981 8.67173 15.0355 8.81817 15.1819C8.96462 15.3284 9.20206 15.3284 9.3485 15.1819L12.0002 12.5303L14.6518 15.182C14.7983 15.3284 15.0357 15.3284 15.1822 15.182C15.3286 15.0355 15.3286 14.7981 15.1822 14.6517L12.5305 12L15.1821 9.34831C15.3286 9.20187 15.3286 8.96443 15.1821 8.81798C15.0357 8.67154 14.7983 8.67154 14.6518 8.81798L12.0002 11.4696L9.34854 8.81802Z"
        fill="#050F24"
        fill-opacity="0.82"
      />
    </g>
    <defs>
      <clipPath id="clip0_1176_7936">
        <rect
          width="12"
          height="12"
          fill="currentColor"
          transform="translate(6 6)"
        />
      </clipPath>
    </defs>
  </>,
  displayName
);

Close.displayName = displayName;
export default Close;
