import LensIcon from "@mui/icons-material/Lens";
import { Grid } from "@mui/material";
import Checkbox from "components/ui/Checkbox";
import { ChooseFieldsBlock, Option } from "components/ui/ChooseFieldsBlock";
import SettingsSection from "components/ui/SettingsSection";
import ToggleBtnGroup from "components/ui/ToggleBtnGroup";
import { T } from "components/ui/Typography";
import useAllRecords from "hooks/useAllRecords";
import _ from "lodash";
import { makeStyles } from "makeStyles";
import CrmField from "models/CrmField";
import Partnership from "models/Partnership";
import Record from "models/Record";
import User from "models/User";
import { ReactElement } from "react";
import {
  defineMessages,
  FormattedHTMLMessage,
  FormattedMessage,
} from "react-intl";
import { Link as RedirectLink } from "react-router-dom";

import OpportPartnerDetail from "./OpportPartnerDetail";
import OwnerPartnerDetail from "./OwnerPartnerDetail";

type Props = {
  shareOwner: number;
  shareOpports: boolean;
  onOwnerChange: (newValue: number) => void;
  onOpportsChange: (newValue: boolean) => void;
  disabled?: boolean;
  loading: boolean;
  isPartnerSharing: boolean;
  partnership: Partnership;
  partner: Record;
  user: User;
  allOpportunityFields: CrmField[];
  sharedOpportunityFields: CrmField[];
  setSharedOpportunityFields: (newFields: CrmField[]) => void;
};

const DataSharingDetails = ({
  shareOwner,
  shareOpports,
  onOwnerChange,
  onOpportsChange,
  disabled,
  loading,
  isPartnerSharing,
  partnership,
  partner,
  user,
  allOpportunityFields,
  sharedOpportunityFields,
  setSharedOpportunityFields,
}: Props) => {
  const { records: sources, loading: sourcesLoading } = useAllRecords(
    "integrations"
  );
  const hasCrmSource = sources.some((source) => source.provider !== "file");

  const { classes } = useStyles({ hasCrmSource });

  const addMoreFields = (
    <div>
      <FormattedMessage
        {...(allOpportunityFields.length === 0
          ? i18n.noAvailableFields
          : i18n.importMoreFields)}
      />
      &nbsp;
      {user.isCompanyAdmin() ? (
        <RedirectLink target="_blank" to={"/sources"}>
          <FormattedMessage {...i18n.customFieldSettingsRedirect} />
        </RedirectLink>
      ) : (
        <FormattedMessage {...i18n.askAdminToAddFields} />
      )}
    </div>
  );

  return (
    <SettingsSection
      title={<FormattedMessage {...i18n.title} />}
      subtitle={
        <FormattedHTMLMessage
          {...i18n.summary}
          values={{ partnerName: partner.name }}
        />
      }
    >
      <DataSharingSection
        sectionTitle={<FormattedMessage {...i18n.accountOwner} />}
        partnerName={partner.name}
        sharingComponent={
          <ToggleBtnGroup
            withBorder
            value={shareOwner}
            setValue={onOwnerChange}
            items={Partnership.ownerSharingItems}
            disabled={disabled}
          />
        }
        sharedComponent={
          <OwnerPartnerDetail
            partnership={partnership}
            partner={partner}
            isPartnerSharing={isPartnerSharing}
          />
        }
      />
      <DataSharingSection
        sectionTitle={<FormattedMessage {...i18n.openDeal} />}
        partnerName={partner.name}
        sharingComponent={
          <ChooseFieldsBlock
            allFields={allOpportunityFields}
            selectedFields={sharedOpportunityFields}
            setSelectedFields={
              setSharedOpportunityFields as (value: Option[]) => void
            }
            loading={loading || sourcesLoading}
            showIntegrationLogo={sources?.length > 1 && hasCrmSource}
            emptySelectionPlaceholder={
              <Checkbox
                checked={shareOpports}
                onChange={(_, checked) => onOpportsChange(checked)}
                label={i18n.toggleOpportsSharing}
                classes={{ root: classes.toggleOpportsSharing }}
                disabled={disabled}
              />
            }
            onReset={() => setSharedOpportunityFields([])}
            showReset={!_.isEmpty(sharedOpportunityFields)}
            extraLastOption={addMoreFields}
            disabled={disabled}
            hideAddFieldButton={!hasCrmSource}
            fieldsLimit={4}
          />
        }
        sharedComponent={
          <OpportPartnerDetail
            partnership={partnership}
            partner={partner}
            isPartnerSharing={isPartnerSharing}
          />
        }
      />
    </SettingsSection>
  );
};

type DataSharingSectionProps = {
  sectionTitle: ReactElement | string;
  partnerName: string;
  sharingComponent: ReactElement;
  sharedComponent: ReactElement;
};

const DataSharingSection = ({
  sectionTitle,
  partnerName,
  sharingComponent,
  sharedComponent,
}: DataSharingSectionProps) => {
  const { classes } = useDataSharingSectionStyles();

  return (
    <div className={classes.objectSection}>
      <T h4 bold>
        {sectionTitle}
      </T>
      <Row ownSide={true} partnerName={partnerName}>
        {sharingComponent}
      </Row>
      <Row ownSide={false} partnerName={partnerName}>
        {sharedComponent}
      </Row>
    </div>
  );
};

type RowProps = {
  ownSide: boolean;
  partnerName: string;
  children: ReactElement;
};

const Row = ({ ownSide, partnerName, children }: RowProps) => {
  const { classes } = useRowStyles({ ownSide });
  const translation = ownSide ? (
    <FormattedMessage {...i18n.whatYouShare} />
  ) : (
    <FormattedMessage {...i18n.whatPartnerShare} values={{ partnerName }} />
  );
  return (
    <Grid container className={classes.fieldRow}>
      <Grid item md={4} xs={12} className={classes.sharingSide}>
        <T>
          <LensIcon className={classes.lens} />
          {translation}
        </T>
      </Grid>
      <Grid item md={8} xs={12}>
        {children}
      </Grid>
    </Grid>
  );
};

// CSS

export const useStyles = makeStyles<{ hasCrmSource: boolean }>()(
  (theme, { hasCrmSource }) => ({
    chip: {
      marginRight: theme.spacing(1),
      marginBottom: theme.spacing(1),
      fontSize: "12px",
      lineHeight: "18px",
    },
    toggleOpportsSharing: {
      marginLeft: hasCrmSource ? theme.spacing(1) : 0,
      paddingLeft: hasCrmSource ? undefined : 4,
    },
  })
);

const useDataSharingSectionStyles = makeStyles()((theme) => ({
  objectSection: {
    paddingBottom: theme.spacing(4),
    marginBottom: theme.spacing(3),
    borderBottom: `1px solid ${theme.palette.taupe500}`,
    "&:last-of-type": {
      borderBottom: "none",
      marginBottom: 0,
      paddingBottom: 0,
    },
  },
}));

export const useRowStyles = makeStyles<{ ownSide: boolean }>()(
  (theme, { ownSide }) => ({
    lens: {
      color: ownSide ? theme.palette.darkPurple : theme.palette.green600,
      width: 6,
      height: 6,
      margin: "0 8px 0 0",
    },
    sharingSide: {
      paddingRight: theme.spacing(1),
      [theme.breakpoints.down("md")]: {
        paddingBottom: theme.spacing(1),
      },
    },
    fieldRow: {
      alignItems: "center",
      minHeight: theme.spacing(2),
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
    },
  })
);

const i18n = defineMessages({
  title: {
    id: "crm.PartnershipSettings.GeneralTab.DataSharingDetails.title",
    defaultMessage: "Field Sharing",
  },
  summary: {
    id: "crm.PartnershipSettings.GeneralTab.DataSharingDetails.summary",
    defaultMessage: "Configure fields to be shared with {partnerName}",
  },
  whatYouShare: {
    id: "crm.PartnershipSettings.GeneralTab.DataSharingDetails.whatYouShare",
    defaultMessage: "What you share",
  },
  accountOwner: {
    id: "crm.PartnershipSettings.GeneralTab.DataSharingDetails.accountOwner",
    defaultMessage: "Account Owner",
  },
  openDeal: {
    id: "crm.PartnershipSettings.GeneralTab.DataSharingDetails.openDeal",
    defaultMessage: "Open Deal",
  },
  whatPartnerShare: {
    id:
      "crm.PartnershipSettings.GeneralTab.DataSharingDetails.whatPartnerShare",
    defaultMessage: "What {partnerName} shares",
  },
  toggleOpportsSharing: {
    id:
      "crm.PartnershipSettings.GeneralTab.DataSharingDetails.toggleOpportsSharing",
    defaultMessage: "Only share if the account has an open opportunity (Y/N)",
  },
  noAvailableFields: {
    id:
      "crm.PartnershipSettings.GeneralTab.DataSharingDetails.noAvailableFields",
    defaultMessage: "No field available.",
  },
  importMoreFields: {
    id:
      "crm.PartnershipSettings.GeneralTab.DataSharingDetails.importMoreFields",
    defaultMessage: "Can't find your field?",
  },
  customFieldSettingsRedirect: {
    id:
      "crm.PartnershipSettings.GeneralTab.DataSharingDetails.customFieldSettingsRedirect",
    defaultMessage: "Add custom fields.",
  },
  askAdminToAddFields: {
    id:
      "crm.PartnershipSettings.GeneralTab.DataSharingDetails.askAdminToAddFields",
    defaultMessage: "Ask an administrator.",
  },
});

export default DataSharingDetails;

export const _private = {
  i18n,
};
