import { defineMessages } from "react-intl";

import { BaseSidePanel } from "./BaseSidePanel";
import { ChatHelpBox } from "./ChatHelpBox";
import { HelpBox } from "./HelpBox";

export const AssignmentSidePanel = () => {
  return (
    <BaseSidePanel>
      <HelpBox i18nTitle={i18n.howToTitle} i18nSubtitle={i18n.text} />
      <ChatHelpBox />
    </BaseSidePanel>
  );
};

const i18n = defineMessages({
  howToTitle: {
    id: "crm.settings.AssignmentSidePanel.howToTitle",
    defaultMessage: "What is assignment?",
  },
  text: {
    id: "crm.settings.AssignmentSidePanel.text",
    defaultMessage:
      "Assign this new data source to your existing partnership connections on Reveal.",
  },
});
