import { Grid } from "@mui/material";
import ArrowRight from "components/icons/ArrowRight";
import {
  IDropdownGroup,
  IDropdownOption,
} from "components/ui/Dropdown/components/types";
import { T } from "components/ui/Typography";
import { FormikErrors, FormikTouched } from "formik";
import _ from "lodash";
import { makeStyles } from "makeStyles";
import { useEffect } from "react";
import { defineMessages, FormattedMessage, useIntl } from "react-intl";
import { MatchFieldType } from "screens/Frontoffice/screens/DataTables/shared/types";

import { RevealFieldType } from "../../../hooks/useMappingFields";
import { FieldRow, MappedRow } from "../../ExportCrmDialogManager";
import FieldSelector from "./FieldSelector";

export const BLANK_KEY = "__blank__";

type Props = {
  fieldIndex?: number;
  leftFields?: { [fieldname: string]: RevealFieldType };
  rightFields?: { [fieldname: string]: RevealFieldType };
  values: FieldRow;
  errors?: FormikErrors<FieldRow>;
  touched?: FormikTouched<FieldRow>;
  onChange?: (event: {
    target: {
      name: string;
      value: string | boolean | MappedRow[];
    };
    type: string;
  }) => void;
  onDelete?: () => void;
  partnerName?: string | null;
};

const FieldGroup = ({
  fieldIndex,
  leftFields = {},
  rightFields = {},
  values,
  errors = {},
  touched = {},
  onChange = () => {},
  onDelete,
  partnerName,
}: Props) => {
  const { classes, cx } = useStyles({ hasButton: !!onDelete });
  const intl = useIntl();

  const leftValue: string = values.reveal_field;
  const rightValue: string = values.crm_field;
  const defaultValueMode: boolean = values.default_value;
  const isRequired: boolean = values.required;

  const hasLeftValue = Boolean(leftValue);
  const hasRightValue = Boolean(rightValue);

  const groupType =
    hasRightValue && rightFields[rightValue]
      ? rightFields[rightValue].type
      : MatchFieldType.UNKNOWN;

  const isBooleanOption = (value: string) =>
    BOOLEAN_OPTIONS.map((option) => option.id).includes(value);

  const restartLeftFieldAndSetDefaultValue = (isDefaultValue: boolean) => {
    onChange({
      target: {
        name: `fields[${fieldIndex}].reveal_field`,
        value: "",
      },
      type: "change",
    });

    onChange({
      target: {
        name: `fields[${fieldIndex}].default_value`,
        value: isDefaultValue,
      },
      type: "change",
    });
  };

  const handleChange = (fieldName: string, newValue: any) => {
    const leftSide = fieldName.startsWith("reveal_");
    if (!leftSide) {
      // If right FieldSelector changes, restart left FieldSelector
      restartLeftFieldAndSetDefaultValue(false);
      // Toggle the "disable" state on the field
      // so that they are hidden/displayed from the list
      if (rightValue in rightFields) {
        rightFields[rightValue].disabled = false;
      }
      if (newValue in rightFields) {
        rightFields[newValue].disabled = true;
      }
    } else {
      // If extra option is selected
      // replace left FieldSelector with a TextInput
      // and reset its value so we show a placeholder
      if (newValue === YOUR_OWN_VALUE_OPTION.id) {
        restartLeftFieldAndSetDefaultValue(true);
        return; // Avoid FieldSelector value update
      }
      // If left FieldSelector changes, hide TextInput
      if (newValue in leftFields) {
        restartLeftFieldAndSetDefaultValue(false);
      } else if (
        hasRightValue &&
        (getOptionValues(rightFields[rightValue]).includes(newValue) ||
          isBooleanOption(newValue))
      ) {
        // If the new value belongs to the second group
        // and the group type is boolean or picklist
        // then we set the 'default value' attribute as True
        restartLeftFieldAndSetDefaultValue(
          groupType === MatchFieldType.BOOLEAN ||
            groupType === MatchFieldType.PICKLIST
        );
      }
    }
    // Update FieldSelector value
    onChange({
      target: {
        name: `fields[${fieldIndex}].${
          leftSide ? "reveal_field" : "crm_field"
        }`,
        value: newValue,
      },
      type: "change",
    });
  };

  const handleChangeMapped = (valueIndex: number, newValue: any) => {
    let value_mapping: MappedRow[] = values.value_mapping;
    // Update the value
    value_mapping[valueIndex].crm_value = newValue;
    onChange({
      target: {
        name: `fields[${fieldIndex}].value_mapping`,
        value: value_mapping,
      },
      type: "change",
    });
  };

  const handleDelete = () => {
    if (onDelete) {
      if (rightFields[rightValue]) {
        rightFields[rightValue].disabled = false;
      }
      onDelete();
    }
  };

  const leftFirstGroup: IDropdownOption = {
    id: _.uniqueId(`reveal_${fieldIndex}`),
    group: getGroupFromFields(filterByType(leftFields, groupType)),
    name: intl.formatMessage(i18n.partnerFields, { partner: partnerName }),
  };
  const leftSecondGroup: IDropdownOption = {
    id: _.uniqueId(`reveal_${fieldIndex}`),
    group: getGroupFromFieldOptions(rightFields[rightValue]),
    name: intl.formatMessage(i18n.crmValues),
  };
  const leftThirdGroup: IDropdownOption = {
    id: _.uniqueId(`reveal_${fieldIndex}`),
    group: [YOUR_OWN_VALUE_OPTION],
    name: "",
  };

  const rightGroup: IDropdownOption = {
    id: _.uniqueId(`crm_${fieldIndex}`),
    group: getGroupFromFields(rightFields),
    name: "",
  };

  const leftMappedGroup: IDropdownOption = {
    id: _.uniqueId(`reveal_mapped_${fieldIndex}`),
    group: getGroupFromFieldOptions(leftFields[leftValue]),
    name: "",
  };
  const rightMappedGroup: IDropdownOption = {
    id: _.uniqueId(`crm_mapped_${fieldIndex}`),
    group: getGroupFromFieldOptions(rightFields[rightValue]),
    name: "",
  };

  const getDropdownList = (options: IDropdownOption[]) => {
    if (options.length === 1) {
      if (options[0].group === undefined || options[0].group.length === 0) {
        return [];
      }
      return options;
    }
    if (rightValue === "Type") {
      options[0].group = []; // Hide first group
    }
    if (
      groupType === MatchFieldType.TEXT ||
      groupType === MatchFieldType.NUMBER ||
      groupType === MatchFieldType.INTEGER
    ) {
      options[1].group = []; // Hide second group
    } else if (groupType === MatchFieldType.BOOLEAN) {
      options[2].group = []; // Hide third group
    } else if (groupType === MatchFieldType.USER) {
      options[1].name = ""; // Hide second group name
      options[2].group = []; // Hide third group
    } else {
      options[2].group = []; // Hide third group
    }

    // Remove empty groups
    if (options[2].group === undefined || options[2].group.length === 0) {
      options.splice(2, 1);
    }
    if (options[1].group === undefined || options[1].group?.length === 0) {
      options.splice(1, 1);
    }
    if (options[0].group === undefined || options[0].group?.length === 0) {
      options.splice(0, 1);
    }

    return options;
  };

  // Get the label with a prefix
  const fieldDisplay = (value: string) => {
    let prefix, label;
    if (value in leftFields) {
      // leftFirstGroup
      prefix = partnerName;
      label = leftFields[value].label;
      return `${prefix} > ${label}`;
    }
    if (isBooleanOption(value)) {
      return value === BOOLEAN_OPTIONS[0].id
        ? intl.formatMessage(i18n.yes)
        : intl.formatMessage(i18n.no);
    }
    // leftSecondGroup & leftThirdGroup
    return value;
  };

  const showMappedSection =
    groupType === MatchFieldType.PICKLIST &&
    hasLeftValue &&
    leftValue in leftFields &&
    getDeepLength([leftMappedGroup]) > 0;

  // Update values dict with picklist options
  // so that this field group follows the validation schema
  useEffect(() => {
    if (groupType === MatchFieldType.PICKLIST) {
      let value_mapping: MappedRow[];
      if (showMappedSection) {
        value_mapping = (leftMappedGroup.group ?? []).map(
          (option: IDropdownOption) => {
            return {
              reveal_value: option.name,
              crm_value: "",
            } as MappedRow;
          }
        );
      } else {
        value_mapping = [];
      }
      onChange({
        target: {
          name: `fields[${fieldIndex}].value_mapping`,
          value: value_mapping,
        },
        type: "change",
      });
    }
  }, [leftValue]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Grid item container direction="column" rowGap={3}>
      <Grid item container columnSpacing={1}>
        <Grid item xs key={`left-${fieldIndex}`}>
          <FieldSelector
            name={`reveal_field_${fieldIndex}`}
            disabled={
              !hasRightValue ||
              (_.isEmpty(leftFields) && _.isEmpty(rightFields))
            }
            error={Boolean(touched.reveal_field && errors.reveal_field)}
            placeholder={
              !hasRightValue
                ? intl.formatMessage(i18n.noFieldToSelect)
                : !defaultValueMode
                ? intl.formatMessage(i18n.selectField)
                : intl.formatMessage(i18n.typeValue)
            }
            options={getDropdownList([
              leftFirstGroup,
              leftSecondGroup,
              leftThirdGroup,
            ])}
            showSearchBar={
              getDeepLength(
                getDropdownList([
                  leftFirstGroup,
                  leftSecondGroup,
                  leftThirdGroup,
                ])
              ) > 6
            }
            value={leftValue}
            onChange={handleChange}
            customLabel={fieldDisplay(leftValue)}
            isWritable={
              defaultValueMode &&
              groupType !== MatchFieldType.BOOLEAN &&
              groupType !== MatchFieldType.PICKLIST
            }
            isDecimal={groupType === MatchFieldType.NUMBER}
            isInteger={groupType === MatchFieldType.INTEGER}
            classes={{ btn: classes.leftField }}
          />
        </Grid>
        <Grid item xs={"auto"} key={`middle-${fieldIndex}`}>
          <ArrowRight className={classes.arrowRight} />
        </Grid>
        <Grid item xs key={`right-${fieldIndex}`}>
          <FieldSelector
            name={`crm_field_${fieldIndex}`}
            error={Boolean(touched.crm_field && errors.crm_field)}
            placeholder={intl.formatMessage(i18n.selectCrmField)}
            options={getDropdownList([rightGroup])}
            showSearchBar={getDeepLength(getDropdownList([rightGroup])) > 6}
            value={rightValue}
            onChange={handleChange}
            customLabel={
              hasRightValue ? rightFields[rightValue]?.label : undefined
            }
            readOnly={isRequired}
            onDelete={onDelete ? handleDelete : undefined} // only defined when the field isn't required
            classes={{ btn: classes.rightField }}
          />
        </Grid>
      </Grid>
      {showMappedSection && (
        <Grid container rowSpacing={1} className={classes.optionsMapping}>
          {(leftMappedGroup.group ?? []).map((option, i) => {
            const revealOcurrences = getOcurrencesFieldOptions(
              leftFields[leftValue],
              i
            );
            let leftLabel = option.name;
            const rightLabel = values.value_mapping[i]?.crm_value ?? "";

            // Compute if mapping line is on error state
            const mappedRowTouched = touched.value_mapping;
            const crmTouched = mappedRowTouched && Boolean(mappedRowTouched[i]);
            const mappedRowError = errors.value_mapping;
            const crmError = mappedRowError && Boolean(mappedRowError[i]);
            const error = Boolean(crmTouched && crmError);

            // Manage blank option
            let isBlank = false;
            if (leftLabel === BLANK_KEY) {
              isBlank = true;
              leftLabel = intl.formatMessage(i18n.blank); // Only update the displayed label (not the value)
            }
            return (
              <Grid container item columnSpacing={1}>
                <Grid item xs key={`left-${fieldIndex}-${i}`}>
                  <FieldSelector
                    name={`reveal_value_${i}`}
                    value={option.name}
                    customLabel={leftLabel}
                    classes={{
                      btn: classes.option,
                      label: cx({ [classes.blank]: isBlank }),
                    }}
                    readOnly
                    endDecorator={<T>{revealOcurrences}</T>}
                  />
                </Grid>
                <Grid item xs={"auto"} key={`middle-${fieldIndex}`}>
                  <ArrowRight className={classes.arrowRight} />
                </Grid>
                <Grid item xs key={`right-${fieldIndex}`}>
                  <FieldSelector
                    name={`crm_value_${i}`}
                    error={error}
                    placeholder={intl.formatMessage(i18n.selectValue)}
                    options={getDropdownList([rightMappedGroup])}
                    showSearchBar={
                      getDeepLength(getDropdownList([rightMappedGroup])) > 6
                    }
                    value={rightLabel}
                    onChange={(_fieldName: string, newValue: any) =>
                      handleChangeMapped(i, newValue)
                    }
                    customLabel={rightLabel}
                    classes={{ btn: classes.option }}
                  />
                </Grid>
              </Grid>
            );
          })}
        </Grid>
      )}
    </Grid>
  );
};

export default FieldGroup;

// Internal functions

const filterByType = (
  fields: { [fieldname: string]: RevealFieldType },
  type: MatchFieldType
) => {
  let filteredFields: { [fieldname: string]: RevealFieldType } = {};
  _.forEach(fields, (field, name) => {
    if (type === field.type) {
      filteredFields[name] = field;
    }
  });
  return filteredFields;
};

const getGroupFromFields = (fields: {
  [fieldname: string]: RevealFieldType;
}) => {
  let options: IDropdownGroup[] = [];
  _.forEach(fields, (field, name) => {
    if (!field.disabled) {
      const option: IDropdownGroup = {
        id: name,
        name: field.label,
        options: [],
        disabled: field.disabled,
      };
      options.push(option);
    }
  });

  return options;
};

const getGroupFromFieldOptions = (field: RevealFieldType) => {
  if (!field) {
    return [] as IDropdownGroup[];
  }

  if (field.type === MatchFieldType.BOOLEAN) {
    return BOOLEAN_OPTIONS as IDropdownGroup[];
  }

  if (!field.options) {
    return [] as IDropdownGroup[];
  }

  return field.options.map(
    (option) =>
      ({
        id: option.label.toString(),
        name: option.label.toString(),
      } as IDropdownGroup)
  );
};

const getOcurrencesFieldOptions = (field: RevealFieldType, index: number) => {
  if (!field || !field.options) {
    return "";
  }
  return field.options[index].use ?? "";
};

// Retrieve the list of values for the field options
const getOptionValues = (field: RevealFieldType) => {
  // Replace numeric key
  if (!field || !field.options) {
    return [];
  }
  return field.options.map((option) => option.value);
};

const getDeepLength = (options: IDropdownOption[]) => {
  return _.reduce(
    options,
    (result, option: IDropdownOption) => (result += option.group?.length ?? 0),
    0
  );
};

/// CSS

const useStyles = makeStyles<{ hasButton: boolean }>()(
  (theme, { hasButton }) => ({
    arrowRight: {
      marginTop: "50%",
      color: theme.palette.midnight,
      width: 16,
      height: 16,
    },
    optionsMapping: {
      backgroundColor: theme.palette.offWhite,
      borderRadius: "12px",
      padding: 16,
      position: "relative",
      "&::before": {
        content: '""',
        borderStyle: "solid",
        borderWidth: "0 12px 12px 12px",
        borderColor: `transparent transparent ${theme.palette.offWhite} transparent`,
        position: "absolute",
        top: "-10px",
      },
    },
    leftField: {
      maxWidth: "280px",
    },
    rightField: {
      maxWidth: hasButton ? "252px" : "280px",
    },
    option: {
      maxWidth: "264px",
    },
    blank: {
      fontStyle: "italic",
    },
  })
);

/// I18N

const i18n = defineMessages({
  noFieldToSelect: {
    id: "ExportOptions.FieldGroup.noFieldToSelect",
    defaultMessage: "No field to select",
  },
  selectField: {
    id: "ExportOptions.FieldGroup.selectField",
    defaultMessage: "Select field",
  },
  selectCrmField: {
    id: "ExportOptions.FieldGroup.selectCrmField",
    defaultMessage: "Select CRM field",
  },
  typeValue: {
    id: "ExportOptions.FieldGroup.typeValue",
    defaultMessage: "Type a value",
  },
  selectValue: {
    id: "ExportOptions.FieldGroup.selectValue",
    defaultMessage: "Select value",
  },
  partnerFields: {
    id: "ExportOptions.FieldGroup.partnerFields",
    defaultMessage: "{partner} fields",
  },
  crmValues: {
    id: "ExportOptions.FieldGroup.crmValues",
    defaultMessage: "CRM Values",
  },
  ownValue: {
    id: "ExportOptions.FieldGroup.ownValue",
    defaultMessage: "Your own value",
  },
  blank: {
    id: "ExportOptions.FieldGroup.blank",
    defaultMessage: "Blank",
  },
  yes: {
    id: "ExportOptions.FieldGroup.yes",
    defaultMessage: "Yes",
  },
  no: {
    id: "ExportOptions.FieldGroup.no",
    defaultMessage: "No",
  },
});

export const BOOLEAN_OPTIONS = [
  {
    id: _.uniqueId("true"),
    name: ((<FormattedMessage {...i18n.yes} />) as unknown) as string,
  },
  {
    id: _.uniqueId("false"),
    name: ((<FormattedMessage {...i18n.no} />) as unknown) as string,
  },
];

export const YOUR_OWN_VALUE_OPTION: IDropdownGroup = {
  id: _.uniqueId("yourOwnValue"),
  name: ((<FormattedMessage {...i18n.ownValue} />) as unknown) as string,
  options: [],
};
