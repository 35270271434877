import SudoWidget from "components/sudo/SudoWidget";
import sections from "components/ui/sidebar/sections";
import { widthClosed, widthOpened } from "components/ui/sidebar/Sidebar";
import { ConfigContext } from "config/ConfigProvider";
import { RouteConfig } from "config/routes/types";
import useUserProfile from "hooks/useUserProfile";
import { useUserPromotedToAdmin } from "hooks/useUserPromotedToAdmin";
import { makeStyles } from "makeStyles";
import { ReactNode, useContext } from "react";
import { defineMessages } from "react-intl";
import { connect, ConnectedComponent, useSelector } from "react-redux";
import { matchPath } from "react-router";
import { useLocation } from "react-router-dom";
import { selectFoldedSidebar } from "redux/init/selectors";
import { RevealStore } from "redux/typing";
import OnboardingService from "services/onboarding";

import { Navbar } from "./navbar/Navbar";
import Notification from "./Notifications/Notification";
import PageForbidden from "./PageForbidden";
import ScrollToTop from "./ScrollToTop";
import Sidebar from "./sidebar/Sidebar";
import SplashScreen from "./SplashScreen";

type StyleProps = {
  sidebarFolded: boolean;
  showSidebar: boolean;
};

type Props = {
  routes: RouteConfig[];
  cancelScrollTop?: boolean;
  children: ReactNode[] | ReactNode | string;
  store: RevealStore;
  forceAllowIncompleteOnboarding?: boolean;
};

const Page = ({
  routes = [],
  cancelScrollTop = false,
  children,
  store,
  forceAllowIncompleteOnboarding,
}: Props) => {
  const { profile } = useUserProfile();
  const location = useLocation();
  const config = useContext(ConfigContext);
  const isPromoted = useUserPromotedToAdmin();

  const findMatching: (
    route: RouteConfig,
    prefix?: string
  ) => RouteConfig | null = (route: RouteConfig, prefix: string = "") => {
    const match = matchPath(location.pathname, prefix + route.path);
    if (match) {
      return (
        (route.routes ?? [])
          .map((subRoute) => findMatching(subRoute, prefix + route.path))
          .find(Boolean) ?? route
      );
    }
    return null;
  };

  const currentRoute = routes.map((route) => findMatching(route)).find(Boolean);
  /**
   * Here is the list of attributes we check for:
   * - allowIncompleteOnboarding
   * - showSidebar
   * - hideNavbar
   * - forceSidebarFolded
   */
  const showSidebar = currentRoute?.showSidebar ?? false;
  const hideNavbar = currentRoute?.hideNavbar ?? false;
  const allowIncompleteOnboarding =
    forceAllowIncompleteOnboarding ??
    currentRoute?.allowIncompleteOnboarding ??
    false;

  const forceFolded = currentRoute?.forceSidebarFolded ?? false;
  const sidebarFolded = useSelector(selectFoldedSidebar) || forceFolded;
  const { classes } = useStyles({ sidebarFolded, showSidebar });

  if (!allowIncompleteOnboarding) {
    if (store.user.profile.requested) {
      return <SplashScreen />;
    }
    if (store.user.profile.data?.id) {
      const service = new OnboardingService(config, store);
      service.validateOrRedirect();
    }
  }

  return (
    <>
      <div id="#page-root" className={classes.root}>
        <ScrollToTop cancelScrollTop={cancelScrollTop}>
          {showSidebar && (
            <Sidebar
              profile={profile}
              sections={sections}
              folded={sidebarFolded}
              hideLockIcon={forceFolded}
            />
          )}
          <div className={classes.margin} />
          <div className={classes.content}>
            {!hideNavbar && <Navbar />}
            {isPromoted ? (
              <PageForbidden errorMessage={i18n.adminPromotedTitle} />
            ) : (
              children
            )}
          </div>
          <Notification sidebarFolded={sidebarFolded} />
        </ScrollToTop>
      </div>
      <SudoWidget />
    </>
  );
};

export const useStyles = makeStyles<StyleProps>()(
  (theme, { sidebarFolded, showSidebar }) => ({
    root: {
      display: "flex",
      height: "100%",
      overflow: "auto",
      backgroundColor: theme.palette.offWhite,
    },
    margin: {
      display: sidebarFolded ? "flex" : "none",
      flexGrow: 1,
      flexDirection: "column",
      maxWidth: widthClosed,
      [theme.breakpoints.down("md")]: {
        display: "none",
        maxWidth: "100%",
      },
    },
    content: {
      flexGrow: 1,
      display: "flex",
      flexDirection: "column",
      maxWidth: `calc(100% - ${
        showSidebar ? (sidebarFolded ? widthClosed : widthOpened) : 0
      }px)`,
      position: "relative",
      [theme.breakpoints.down("md")]: {
        paddingLeft: 0,
        maxWidth: "100%",
      },
    },
  })
);

export default connect((store: RevealStore) => ({
  store,
}))(Page) as ConnectedComponent<$TSFixMe, $TSFixMe>;

const i18n = defineMessages({
  adminPromotedTitle: {
    id: "Page.adminPromotedTitle",
    defaultMessage: "You have to confirm your email address before continuing.",
  },
});
