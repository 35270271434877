import { T } from "components/ui/Typography";
import { ShareOwner } from "models/AccountMappingSharingSettings";
import Partnership from "models/Partnership";
import Record from "models/Record";
import { defineMessages, FormattedMessage } from "react-intl";

const i18n = defineMessages({
  partnerNotSharing: {
    id:
      "crm.PartnershipSettings.GeneralTab.OwnerPartnerDetail.partnerNotSharing",
    defaultMessage: "{partnerName} has not activated Data Sharing yet.",
  },
  ownerNotShared: {
    id: "crm.PartnershipSettings.GeneralTab.OwnerPartnerDetail.ownerNotShared",
    defaultMessage:
      "{partnerName} has not granted you access to any Owner information.",
  },
  ownerNameOnly: {
    id: "crm.PartnershipSettings.GeneralTab.OwnerPartnerDetail.ownerNameOnly",
    defaultMessage:
      "{partnerName} has restricted your access to the Owner full name.",
  },
  ownerNameAndContactInfo: {
    id:
      "crm.PartnershipSettings.GeneralTab.OwnerPartnerDetail.ownerNameAndContactInfo",
    defaultMessage:
      "{partnerName} has granted you access to the Owner full name and contact information.",
  },
});

type Props = {
  partnership: Partnership;
  partner: Record;
  isPartnerSharing: boolean;
};

const OwnerPartnerDetail = ({
  partnership,
  partner,
  isPartnerSharing,
}: Props) => {
  const ownerSharing = partnership.getAccountOwnerSharing(partner);

  if (!isPartnerSharing) {
    return (
      <T>
        <FormattedMessage
          {...i18n.partnerNotSharing}
          values={{ partnerName: partner.name }}
        />
      </T>
    );
  } else {
    return <T>{accountOwnerMap(partner)[ownerSharing]}</T>;
  }
};

const accountOwnerMap = (
  partner: Record
): { [value in ShareOwner]: React.ReactElement } => ({
  [ShareOwner.Full]: (
    <FormattedMessage
      {...i18n.ownerNameAndContactInfo}
      values={{ partnerName: partner.name }}
    />
  ),
  [ShareOwner.NameOnly]: (
    <FormattedMessage
      {...i18n.ownerNameOnly}
      values={{ partnerName: partner.name }}
    />
  ),
  [ShareOwner.Nothing]: (
    <FormattedMessage
      {...i18n.ownerNotShared}
      values={{ partnerName: partner.name }}
    />
  ),
});

export default OwnerPartnerDetail;
