import { CloseRounded } from "@mui/icons-material";
import { Box } from "@mui/material";
import { ArrowRight } from "components/icons";
import Button from "components/ui/Button";
import { T } from "components/ui/Typography";
import { makeStyles } from "makeStyles";
import { defineMessages, FormattedMessage } from "react-intl";
import { useHistory } from "react-router-dom";
import { useStyles as useInviteStyles } from "screens/Frontoffice/shared/components/InviteForm/hooks/useInviteModal";

type Props = {
  companyName: string;
  isSharing: boolean;
  onClose: () => void;
  partnershipId: string;
};

export const InvitationSuccess = ({
  companyName,
  isSharing,
  onClose,
  partnershipId,
}: Props) => {
  const { classes } = useStyles();
  const { classes: inviteClasses } = useInviteStyles();
  const history = useHistory();

  const handleClick = () => {
    onClose();
    history.replace({
      pathname: `/partnerships/${partnershipId}/settings/overview`,
    });
  };

  return (
    <Box className={classes.container}>
      <Box className={inviteClasses.closeButtonAbs}>
        <Button
          LeftIcon={CloseRounded}
          onClick={onClose}
          size="small"
          variant="tertiary"
        />
      </Box>
      <div className={classes.marginBottom}>
        <img
          className={classes.bigIcon}
          src="/images/CircleCheckmarkSuccessBig.png"
          alt=""
        />
      </div>
      <T h2 bold className={classes.marginBottom}>
        <FormattedMessage
          {...i18n.connected}
          values={{
            companyName,
          }}
        />
      </T>
      {isSharing && (
        <T>
          <FormattedMessage {...i18n.beingComputed} />
        </T>
      )}
      <T className={classes.marginBottom}>
        <FormattedMessage {...i18n.accessOverview} />
      </T>
      <Button
        label={i18n.goToOverview}
        RightIcon={ArrowRight}
        onClick={handleClick}
      />
    </Box>
  );
};

const i18n = defineMessages({
  accessOverview: {
    id: "partners.InvitationSuccess.accessOverview",
    defaultMessage: "For now, you can access the overview in your partner page",
  },
  beingComputed: {
    id: "partners.InvitationSuccess.beingComputed",
    defaultMessage:
      "Your data is currently being computed and will be available in the next 24 hours.",
  },
  connected: {
    id: "partners.InvitationSuccess.connected",
    defaultMessage: "You are now connected with {companyName}",
  },
  goToOverview: {
    id: "partners.InvitationSuccess.goToOverview",
    defaultMessage: "Go to overview",
  },
});

const useStyles = makeStyles()((theme) => ({
  bigIcon: {
    height: 48,
    width: 48,
  },
  container: {
    alignItems: "center",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    margin: "auto",
    minHeight: 450,
  },
  marginBottom: {
    marginBottom: theme.spacing(2),
  },
}));
