import { COMPANY_ECOSYSTEM_LABELS, CompanyEcosystem } from "models/Company";
import { IntlShape } from "react-intl";
import { AvailableDirectoryColumns } from "redux/directory/defaults";

export const isDirectoryEcosystemColumn = (fieldName: string) =>
  fieldName === AvailableDirectoryColumns.ecosystem;

export const directoryEcosystemValueFormatter = (row: any, intl: IntlShape) => {
  return row?.ecosystem
    ? intl.formatMessage(
        COMPANY_ECOSYSTEM_LABELS[row.ecosystem as CompanyEcosystem].props
      )
    : "--";
};
