import { Box } from "@mui/material";
import { defineMessages } from "react-intl";
import { ChatLink } from "screens/Frontoffice/shared/components/DialogWithSidePanel";

import { HelpBox } from "./HelpBox";

export const ChatHelpBox = () => {
  return (
    <HelpBox
      i18nTitle={i18n.title}
      i18nSubtitle={i18n.subtitle}
      extraSlot={
        <Box mt={2}>
          <ChatLink />
        </Box>
      }
    />
  );
};

const i18n = defineMessages({
  title: {
    id: "crm.settings.ChatHelpBox.title",
    defaultMessage: "Need additional support?",
  },
  subtitle: {
    id: "crm.settings.ChatHelpBox.subtitle",
    defaultMessage: "Talk with a support agent",
  },
});
