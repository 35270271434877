import {
  AnyAction,
  createAsyncThunk,
  isFulfilled,
  isRejected,
  ThunkDispatch,
} from "@reduxjs/toolkit";
import _ from "lodash";
import { Factory } from "models";
import Match, { PipelineViewType } from "models/Match";
import { PageType } from "models/PageView";
import { JSONAPIAttributes, JSONAPIResource } from "models/types";
import { create, index, rawGet, update } from "redux/api/thunks";
import {
  asJSONSerializable,
  DateRangeFilterType,
  DateRangeTimeframe,
  RevealStore,
} from "redux/typing";
import { isCustomRangeFilter, updateLastVisitedAt } from "redux/utils";
import {
  asPersistedColumn,
  ColumnConfigType,
  FilterType,
  MatchFilterType,
  SortType,
} from "screens/Frontoffice/screens/DataTables/shared/types";
import { JSONAPIResponse } from "services/types";
import UserService from "services/UserService";

import {
  AvailablePipelineColumns,
  defaultPipelineColumns,
  defaultPipelineSort,
  getDefaultPipelineFilters,
} from "./defaults";
import { selectShowArchived } from "./selectors";
import { PipelineActions, PipelineExpectedState } from "./typing";

export const loadUnreadConversations = createAsyncThunk(
  PipelineActions.loadUnreadConversations,
  async (_: void, thunkAPI) => {
    const result = await thunkAPI.dispatch(
      rawGet({
        type: "conversations",
        path: "/unread/",
      })
    );
    if (isRejected(result)) {
      throw new Error("Cannot load unread conversations");
    }
    if (isFulfilled(result)) {
      const {
        partner_connection_ids_on_my_deals,
        partner_connection_ids_on_partner_deals,
      } = (result.payload as any)?.data ?? {};
      return {
        [Match.PERSPECTIVE_MINE]:
          partner_connection_ids_on_my_deals?.length ?? 0,
        [Match.PERSPECTIVE_THEIRS]:
          partner_connection_ids_on_partner_deals?.length ?? 0,
      };
    }
  }
);

export const loadView = createAsyncThunk(
  PipelineActions.loadView,
  async (_: void, thunkAPI) => {
    const state = thunkAPI.getState() as PipelineExpectedState;
    if (state.pipeline.view) {
      return state.pipeline.view;
    }
    const indexView = await thunkAPI.dispatch(
      index({
        type: "page_views",
        options: {
          filters: {
            page_type: PageType.pipeline,
          },
        },
      })
    );

    if (isRejected(indexView)) {
      throw new Error("Cannot load pipeline view");
    }

    let recordData: JSONAPIResource;
    if (indexView.payload.data.length > 0) {
      recordData = indexView.payload.data[0];
      const record = Factory.createRecord(recordData);
      await updateLastVisitedAt(
        record.id,
        thunkAPI.dispatch as ThunkDispatch<RevealStore, any, AnyAction>
      );
      return record;
    } else {
      const result = await thunkAPI.dispatch(
        create({
          type: "page_views",
          attributes: {
            page_type: PageType.pipeline,
            filters: getDefaultPipelineFilters(state.user.profile.data.id),
            sort: defaultPipelineSort,
            columns: asJSONSerializable(
              defaultPipelineColumns.map(asPersistedColumn)
            ),
          },
        })
      );
      if (isFulfilled(result)) {
        const response = result.payload as JSONAPIResponse;
        recordData = response.data;
        const record = Factory.createRecord(recordData);
        await updateLastVisitedAt(
          record.id,
          thunkAPI.dispatch as ThunkDispatch<RevealStore, any, AnyAction>
        );
        return record;
      }
    }
  }
);

export const updateView = createAsyncThunk(
  PipelineActions.updateView,
  async (
    {
      filters,
      columns,
      sort,
      lastVisitedAt,
      filterOrderList,
    }: {
      filters?: FilterType[];
      columns?: ColumnConfigType[];
      sort?: SortType[];
      lastVisitedAt?: Date;
      filterOrderList?: number[];
    },
    thunkAPI
  ) => {
    const state = thunkAPI.getState() as PipelineExpectedState;
    const recordId = state.pipeline.view?.id;
    if (recordId === undefined) {
      throw new Error("No view available for pipeline from redux store");
    }

    const attributes: JSONAPIAttributes = {};

    if (filters !== undefined) {
      attributes["filters"] = asJSONSerializable(filters);
    }

    if (columns !== undefined) {
      attributes["columns"] = asJSONSerializable(
        columns.map(asPersistedColumn)
      );
    }
    if (sort !== undefined) {
      attributes["sort"] = asJSONSerializable(sort);
    }
    if (filterOrderList !== undefined) {
      attributes["filter_order_list"] = filterOrderList;
    }
    if (lastVisitedAt !== undefined) {
      const userService = new UserService();
      if (!userService.isImpersonating) {
        attributes["last_visited_at"] = lastVisitedAt.toISOString();
      }
    }

    if (_.isEmpty(attributes)) {
      return state.pipeline.view;
    }

    const result = await thunkAPI.dispatch(
      update({
        id: recordId,
        type: "page_views",
        attributes,
      })
    );
    if (isFulfilled(result)) {
      const response = result.payload as JSONAPIResponse;
      const record = Factory.createRecord(response.data);
      return record;
    } else {
      throw new Error("Cannot update pipeline view");
    }
  }
);

export const setPartnerFilter = createAsyncThunk(
  PipelineActions.setPartnerFilter,
  async (partnershipIds: number[] | null, thunkAPI) => {
    const state = thunkAPI.getState() as RevealStore;
    const _filters: FilterType[] = state.pipeline.view?.filters ?? [];
    const otherFilters = _filters.filter(
      (item) => !["partnership"].includes(item.fieldname) ?? []
    );

    const filters = [...otherFilters];

    if (partnershipIds !== null && partnershipIds.length > 0) {
      filters.push({
        fieldname: "partnership",
        type: MatchFilterType.ANY_OF,
        value: partnershipIds,
      });
    }

    thunkAPI.dispatch(
      updateView({
        filters,
      })
    );
  }
);

export const setOwnerFilter = createAsyncThunk(
  PipelineActions.setOwnerFilter,
  async (userNames: string[] | null, thunkAPI) => {
    const state = thunkAPI.getState() as RevealStore;
    const _filters: FilterType[] = state.pipeline.view?.filters ?? [];
    const otherFilters = _filters.filter(
      (item) =>
        ![AvailablePipelineColumns.rawCompanyOwnerId as string].includes(
          item.fieldname
        ) ?? []
    );

    const filters = [...otherFilters];

    if (userNames) {
      filters.push({
        fieldname: AvailablePipelineColumns.rawCompanyOwnerId,
        type: MatchFilterType.ANY_OF,
        value: userNames,
      });
    }

    thunkAPI.dispatch(
      updateView({
        filters,
      })
    );
  }
);

export const setViewFilter = createAsyncThunk(
  PipelineActions.setViewFilter,
  async (isAllView: boolean, thunkAPI) => {
    const state = thunkAPI.getState() as RevealStore;
    const _filters: FilterType[] = state.pipeline.view?.filters ?? [];
    const userId = !isAllView ? state.user.profile.data.id : null;
    const otherFilters =
      _filters.filter(
        (item) => !["participant_user_id"].includes(item.fieldname)
      ) ?? [];

    const filters = [...otherFilters];
    if (userId) {
      filters.push({
        fieldname: "participant_user_id",
        type: MatchFilterType._NO_OPERATOR,
        value: [userId],
      });
    }

    thunkAPI.dispatch(
      updateView({
        filters,
      })
    );
  }
);

export const setPerspectiveFilter = createAsyncThunk(
  PipelineActions.setPerspectiveFilter,
  async (viewType: PipelineViewType, thunkAPI) => {
    const state = thunkAPI.getState() as RevealStore;
    const _filters: FilterType[] = state.pipeline.view?.filters ?? [];
    const otherFilters =
      _filters.filter((item) => !["perspective"].includes(item.fieldname)) ??
      [];

    const filters = [...otherFilters];
    filters.push({
      fieldname: "perspective",
      type: MatchFilterType.IS,
      value: viewType,
    });

    thunkAPI.dispatch(
      updateView({
        filters,
      })
    );
  }
);

export const toggleArchivedFilter = createAsyncThunk(
  PipelineActions.toggleArchivedFilter,
  async (_: undefined, thunkAPI) => {
    const state = thunkAPI.getState() as RevealStore;
    const _filters: FilterType[] = state.pipeline.view?.filters ?? [];
    const filters =
      _filters.filter((item) => item.fieldname !== "archived") ?? [];

    filters.push({
      fieldname: "archived",
      type: MatchFilterType._NO_OPERATOR,
      value: !selectShowArchived(state),
    });

    thunkAPI.dispatch(
      updateView({
        filters,
      })
    );
  }
);

export const setDateRangeFilter = createAsyncThunk(
  PipelineActions.setDateRangeFilter,
  async (dateRange: DateRangeFilterType | null, thunkAPI) => {
    const state = thunkAPI.getState() as RevealStore;
    const _filters: FilterType[] = state.pipeline.view?.filters ?? [];
    const filters =
      _filters.filter((item) => item.fieldname !== "date_range") ?? [];

    let value = dateRange;
    if (
      dateRange !== null &&
      isCustomRangeFilter(dateRange) &&
      dateRange.selectionRange.start !== null &&
      dateRange.selectionRange.end !== null
    ) {
      const dates = [
        dateRange.selectionRange.start,
        dateRange.selectionRange.end,
      ];
      value = {
        rangeType: DateRangeTimeframe.customRange,
        selectionRange: {
          start: _.min(dates) ?? null,
          end: _.max(dates) ?? null,
        },
      };
    }

    filters.push({
      fieldname: "date_range",
      type: MatchFilterType._NO_OPERATOR,
      value,
    });
    thunkAPI.dispatch(
      updateView({
        filters,
      })
    );
  }
);

export const removeAllFiltersExceptView = createAsyncThunk(
  PipelineActions.removeAllFiltersExceptView,
  async (_: undefined, thunkAPI) => {
    const state = thunkAPI.getState() as RevealStore;
    const _filters: FilterType[] = state.pipeline.view?.filters ?? [];
    const neededFilters =
      _filters.filter((item) =>
        ["user", "perspective", "archived"].includes(item.fieldname)
      ) ?? [];

    thunkAPI.dispatch(
      updateView({
        filters: neededFilters,
      })
    );
  }
);

export const resetTableFilters = createAsyncThunk(
  PipelineActions.resetTableFilters,
  async (_: undefined, thunkAPI) => {
    const state = thunkAPI.getState() as RevealStore;
    const _filters: FilterType[] = state.pipeline.view?.filters ?? [];
    const neededFilters =
      _filters.filter((item) =>
        [
          "user",
          "perspective",
          "archived",
          "date_range",
          "partnership",
        ].includes(item.fieldname)
      ) ?? [];

    thunkAPI.dispatch(
      updateView({
        filters: neededFilters,
      })
    );
  }
);
