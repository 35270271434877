import { T } from "components/ui/Typography";
import { FeatureStatus } from "models/CompanyPayingFeatureSubscription";
import { FormattedMessage, MessageDescriptor } from "react-intl";
import { makeStyles } from "tss-react/mui";

import { WidgetTag } from "./WidgetTag";

export type Props = {
  name: MessageDescriptor;
  isProgressive?: boolean;
  limitValue?: number;
  cannotExceedLimit?: boolean;
  status: FeatureStatus;
  currentValue?: number;
};

export const FeatureItem = ({
  name,
  isProgressive = false,
  limitValue = 0,
  cannotExceedLimit = false,
  status,
  currentValue = 0,
}: Props) => {
  const { classes } = useStyles();

  return (
    <>
      <div className={classes.featureContainer}>
        <T className={classes.lefSide}>
          <FormattedMessage {...name} />
        </T>
        <span className={classes.rightSide}>
          <WidgetTag
            status={status}
            isProgressive={isProgressive}
            limit={limitValue}
            currentValue={
              cannotExceedLimit && currentValue > limitValue
                ? limitValue
                : currentValue
            }
          />
        </span>
      </div>
    </>
  );
};

/// CSS

export const useStyles = makeStyles()((theme) => ({
  featureContainer: {
    display: "flex",
    justifyContent: "space-between",
    margin: "8px 0px 16px 0px",
  },
  lefSide: {
    color: theme.palette.taupe,
    fontSize: "11px",
  },
  rightSide: {
    color: theme.palette.taupe,
    fontSize: "11px",
  },
}));
