import { Close } from "components/icons";
import { getDefaultFilter } from "components/ui/filters/filter/FilterWidget";
import { T } from "components/ui/Typography";
import { makeStyles } from "makeStyles";
import { useCallback } from "react";
import { defineMessages, FormattedMessage } from "react-intl";
import {
  FieldType,
  FilterType,
  MatchFilterType,
} from "screens/Frontoffice/screens/DataTables/shared/types";

import SelectField from "./SelectField";
import SelectOperation from "./SelectOperation";
import ValueField from "./ValueField";

type Props = {
  disabled?: boolean;
  fields: Record<string, FieldType>;
  filter?: FilterType;
  isAdding?: boolean;
  onRemove: () => void;
  onChange: (filter: FilterType) => void;
  partnerName: string;
};

const FilterRow = ({
  disabled = false,
  fields,
  filter,
  isAdding = false,
  onRemove,
  onChange,
  partnerName,
}: Props) => {
  const { classes } = useStyles();

  const field = filter ? fields[filter.fieldname] : undefined;

  const onFieldChange = useCallback(
    (fieldname: string) => {
      if (fieldname in fields && fieldname !== filter?.fieldname) {
        const defaultFilter = getDefaultFilter(fieldname, fields);
        onChange({
          type: defaultFilter.defaultFilterType,
          value: defaultFilter.defaultValue,
          fieldname,
        });
      }
    },
    [fields, filter, onChange]
  );

  const onOperatorChange = useCallback(
    (operator: MatchFilterType) => {
      if (filter) {
        onChange({
          type: operator,
          value: filter.value,
          fieldname: filter.fieldname,
        });
      }
    },
    [filter, onChange]
  );

  const onValueChange = useCallback(
    (value: any) => {
      if (filter) {
        onChange({
          type: filter.type,
          value,
          fieldname: filter.fieldname,
        });
      }
    },
    [filter, onChange]
  );

  return (
    <div className={classes.root}>
      {!isAdding && (
        <>
          {disabled ? (
            <div className={classes.wherePlaceholder} />
          ) : (
            <button onClick={onRemove} className={classes.close}>
              <Close />
            </button>
          )}
          <T bodyBig>
            <FormattedMessage {...i18n.where} />
          </T>
        </>
      )}
      <SelectField
        value={filter?.fieldname}
        fields={fields}
        onChange={onFieldChange}
        partnerName={partnerName}
        isAdding={isAdding}
        disabled={disabled}
      />
      {filter?.fieldname && (
        <SelectOperation
          value={filter?.type}
          fieldType={field?.type}
          onChange={onOperatorChange}
          disabled={disabled}
        />
      )}
      {filter && (
        <ValueField
          filter={filter}
          fieldOptions={fields[filter.fieldname].options}
          fieldType={fields[filter.fieldname].type}
          onChange={onValueChange}
          disabled={disabled}
        />
      )}
    </div>
  );
};

export default FilterRow;

/// CSS

const useStyles = makeStyles()(() => ({
  root: {
    display: "flex",
    alignItems: "center",
    gap: 8,
  },
  close: {
    backgroundColor: "transparent",
    border: "none",
    "& svg": {
      fontSize: 7,
    },
  },
  wherePlaceholder: {
    width: 18,
  },
}));

// I18n

const i18n = defineMessages({
  where: {
    id: "FilterRow.where",
    defaultMessage: "Where",
  },
});
