import Grid from "@mui/material/Grid";
import { isFulfilled } from "@reduxjs/toolkit";
import withCompanyPermissions from "components/hoc/withCompanyPermissions";
import SettingsSection from "components/ui/SettingsSection";
import Toggle from "components/ui/Toggle";
import { T } from "components/ui/Typography";
import usePushNotification from "hooks/usePushNotification";
import useUserProfile from "hooks/useUserProfile";
import { makeStyles } from "makeStyles";
import { Factory } from "models";
import { useCallback } from "react";
import { defineMessages, FormattedMessage } from "react-intl";
import { useDispatch } from "react-redux";
import { update } from "redux/api/thunks";
import { setCompany } from "redux/user/actions";
import AccountSettings from "screens/Frontoffice/screens/AccountSettings";
import { JSONAPIResponse } from "services/types";

const Settings = () => {
  const { classes } = useStyles();
  const dispatch = useDispatch();
  const pushNotification = usePushNotification();
  const { profile } = useUserProfile();

  const toggle = useCallback(async () => {
    const result = await dispatch(
      update({
        type: "companies",
        id: profile.company.id,
        attributes: {
          opted_out_of_directory_at: !!profile.company.optedOutOfDirectoryAt
            ? null
            : new Date().toISOString(),
        },
      })
    );
    if (isFulfilled(result)) {
      const response = result.payload as JSONAPIResponse;
      dispatch(setCompany(Factory.createRecord(response.data)));
    } else {
      pushNotification("default_error");
    }
  }, [
    dispatch,
    profile.company.id,
    profile.company.optedOutOfDirectoryAt,
    pushNotification,
  ]);

  return (
    <AccountSettings tab="directory">
      <Grid item className={classes.root}>
        <SettingsSection
          title={<FormattedMessage {...i18n.optOutBlockTitle} />}
          subtitle={<FormattedMessage {...i18n.optOutBlockSubTitle} />}
        >
          <div className={classes.toggleLabelGroup}>
            <label className={classes.label}>
              <Toggle
                checked={profile.company.optedOutOfDirectoryAt === null}
                handleChange={toggle}
                size="big"
              />
              <T h4 bold>
                <FormattedMessage {...i18n.optOutToggleLabel} />
              </T>
            </label>
            <T bodySmall>
              <FormattedMessage {...i18n.optOutToggleInformation} />
            </T>
          </div>
        </SettingsSection>
      </Grid>
    </AccountSettings>
  );
};

export default withCompanyPermissions(["company.update"])(Settings);

/// CSS

const useStyles = makeStyles()((theme) => ({
  root: {
    padding: theme.spacing(1, 0),
  },
  toggleLabelGroup: {
    display: "flex",
    flexDirection: "column",
    gap: theme.spacing(1),
  },
  label: {
    marginBottom: 0,
    display: "flex",
    alignItems: "center",
    gap: 12,
    [theme.breakpoints.down("md")]: {
      marginBottom: theme.spacing(2),
    },
  },
}));

/// I18N

const i18n = defineMessages({
  optOutBlockTitle: {
    id: "Workspace.Directory.optOutBlockTitle",
    defaultMessage: "Partner Directory",
  },
  optOutBlockSubTitle: {
    id: "Workspace.Directory.optOutBlockSubTitle",
    defaultMessage:
      "Partner directory enables you to find new partners that are already on Reveal to grow your ecosystem.",
  },
  optOutToggleLabel: {
    id: "Workspace.Directory.optOutBlockToggleLabel",
    defaultMessage: "Opt-in to Reveal directory",
  },
  optOutToggleInformation: {
    id: "Workspace.Directory.optOutToggleInformation",
    defaultMessage:
      "By opting out your company won’t be displayed in the directory and your team won’t be able to consult the directory. ",
  },
});
