import Divider from "@mui/material/Divider";
import Grid from "@mui/material/Grid";
import Hidden from "@mui/material/Hidden";
import { isRejected } from "@reduxjs/toolkit";
import withUserProfile, {
  WithUserProfile,
} from "components/hoc/withUserProfile";
import { Check } from "components/icons";
import Button from "components/ui/Button";
import { SelectInput } from "components/ui/SelectInput";
import SettingsSection from "components/ui/SettingsSection";
import { TextInput } from "components/ui/TextInput";
import { T } from "components/ui/Typography";
import { Formik, FormikHelpers } from "formik";
import usePushNotification from "hooks/usePushNotification";
import forms from "i18n/forms";
import generic from "i18n/generic";
import { makeStyles } from "makeStyles";
import { USER_ROLE_LABELS, UserRole } from "models/User";
import { defineMessages, FormattedMessage, useIntl } from "react-intl";
import { useDispatch } from "react-redux";
import { updateProfile } from "redux/user/thunks";
import { object, string } from "yup";

import SecuritySettings from "./SecuritySettings";
import UnconfirmedAlert from "./UnconfirmedAlert";
import UserAvatar from "./UserAvatar";

type Values = {
  firstName: string;
  lastName: string;
  email: string;
  job: string;
  role: string;
};

const Profile = ({ profile }: WithUserProfile) => {
  const { classes } = useStyles();
  const dispatch = useDispatch();
  const intl = useIntl();
  const pushNotification = usePushNotification();

  const handleSubmit = async (
    values: Values,
    { resetForm }: FormikHelpers<Values>
  ) => {
    const attributes = {
      first_name: values.firstName,
      last_name: values.lastName,
      job: values.job,
      role: values.role,
    };
    const result = await dispatch(updateProfile({ attributes }));
    if (isRejected(result)) {
      pushNotification("default_error");
    } else {
      resetForm({ values });
    }
  };

  return (
    <Grid container className={classes.root} spacing={2} direction="column">
      <Formik
        initialValues={{
          firstName: profile.firstName,
          lastName: profile.lastName,
          email: profile.email,
          job: profile.job ?? "",
          role: profile.role,
        }}
        validationSchema={object().shape({
          email: string()
            .required(intl.formatMessage(forms.email_required))
            .email(intl.formatMessage(forms.email_invalid)),
          firstName: string().required(
            intl.formatMessage(forms.generic_required)
          ),
          lastName: string().required(
            intl.formatMessage(forms.generic_required)
          ),
          job: string().nullable(),
        })}
        onSubmit={handleSubmit}
      >
        {({
          errors,
          handleChange,
          values,
          dirty,
          isValid,
          isSubmitting,
          submitForm,
          resetForm,
        }) => {
          const buttons = (
            <div className={classes.actions}>
              {dirty && (
                <Button
                  label={i18n.cancel}
                  variant="secondary"
                  onClick={() => resetForm()}
                />
              )}
              <Button
                label={i18n.save}
                LeftIcon={Check}
                disabled={!dirty || !isValid}
                loading={isSubmitting}
                onClick={submitForm}
              />
            </div>
          );
          return (
            <>
              <Grid item className={classes.header}>
                <T h2 bold>
                  <FormattedMessage {...i18n.title} />
                </T>
                <Hidden mdDown>{buttons}</Hidden>
              </Grid>
              <Grid item>
                <SettingsSection
                  title={
                    <FormattedMessage {...i18n.personalInformationTitle} />
                  }
                >
                  <div className={classes.content}>
                    {profile.needsConfirmation && (
                      <>
                        <UnconfirmedAlert profile={profile} />
                        <Divider />
                      </>
                    )}
                    <UserAvatar profile={profile} />
                    <Divider />
                    <TextInput
                      variant="tertiary"
                      className={classes.input}
                      placeholder={intl.formatMessage(generic.first_name)}
                      required
                      value={values.firstName}
                      onChange={handleChange}
                      error={Boolean(errors.firstName)}
                      name="firstName"
                    />
                    <TextInput
                      variant="tertiary"
                      className={classes.input}
                      placeholder={intl.formatMessage(generic.last_name)}
                      required
                      value={values.lastName}
                      onChange={handleChange}
                      error={Boolean(errors.lastName)}
                      name="lastName"
                    />
                    <TextInput
                      variant="tertiary"
                      className={classes.input}
                      placeholder={intl.formatMessage(generic.email)}
                      required
                      disabled
                      value={values.email}
                      onChange={handleChange}
                      error={Boolean(errors.lastName)}
                      name="email"
                    />
                    <SelectInput
                      variant="tertiary"
                      className={classes.input}
                      displayEmpty={false}
                      placeholder={""}
                      value={values.role}
                      onChange={handleChange}
                      error={Boolean(errors.role)}
                      name="role"
                      options={Object.values(UserRole).map((key) => ({
                        value: key,
                        label: USER_ROLE_LABELS[key],
                      }))}
                    />
                    <TextInput
                      variant="tertiary"
                      className={classes.input}
                      placeholder={intl.formatMessage(generic.job_title)}
                      required
                      value={values.job}
                      onChange={handleChange}
                      error={Boolean(errors.job)}
                      name="job"
                    />
                  </div>
                </SettingsSection>
                <Hidden mdUp>
                  <Grid item>{buttons}</Grid>
                </Hidden>
                <SecuritySettings />
              </Grid>
            </>
          );
        }}
      </Formik>
    </Grid>
  );
};

export default withUserProfile(Profile);

/// CSS

const useStyles = makeStyles()((theme) => ({
  root: {
    padding: theme.spacing(2.5),
  },
  header: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  infoTitle: {
    padding: theme.spacing(2),
    paddingBottom: theme.spacing(4),
    [theme.breakpoints.down("md")]: {
      display: "flex",
      justifyContent: "center",
    },
  },
  row: {
    borderRadius: 8,
    background: theme.palette.ivory,
    paddingLeft: 24,
    paddingRight: 24,
    paddingTop: 20,
    paddingBottom: 20,
  },
  content: {
    display: "flex",
    flexDirection: "column",
    gap: theme.spacing(3),
  },
  actions: {
    display: "flex",
    gap: 4,
    [theme.breakpoints.down("md")]: {
      flexDirection: "row-reverse",
      justifyContent: "flex-end",
      marginTop: theme.spacing(2),
    },
  },
  input: {
    maxWidth: 500,
  },
}));

/// I18N

const i18n = defineMessages({
  title: {
    id: "Profile.Title",
    defaultMessage: "My Profile",
  },
  cancel: {
    id: "Profile.Cancel",
    defaultMessage: "Cancel",
  },
  save: {
    id: "Profile.Save",
    defaultMessage: "Save",
  },
  personalInformationTitle: {
    id: "Profile.PersonalInformationTitle",
    defaultMessage: "Personal Information",
  },
});
