import Grid from "@mui/material/Grid";
import Button from "components/ui/Button";
import { MessageDescriptor } from "react-intl";
import { useDispatch } from "react-redux";
import { markNotificationAsRead } from "redux/notifications/actions";
import { NotificationItem } from "redux/notifications/typing";

type Props = {
  notification: NotificationItem;
  label: string | MessageDescriptor;
  link: string;
};

const NotificationActionLink = ({ notification, label, link }: Props) => {
  const dispatch = useDispatch();

  const goToLink = () => {
    dispatch(markNotificationAsRead(notification.id));
    window.open(link);
  };

  return (
    <Grid container spacing={1}>
      <Grid item>
        <Button label={label} variant="senary" onClick={goToLink} />
      </Grid>
    </Grid>
  );
};

export default NotificationActionLink;
