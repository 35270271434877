import { CircleCheck } from "components/icons";
import { NearboundAccountOpportunityTableItem } from "components/ui/NearboundAccountOpportunityTableItem/NearboundAccountOpportunityTableItem";
import { IOpportunity } from "components/ui/NearboundAccountOpportunityTableItem/types";
import { RawOpportunityDisplay } from "components/ui/RawOpportunityDisplay/RawOpportunityDisplay";
import { T } from "components/ui/Typography";
import generic from "i18n/generic";
import _ from "lodash";
import { makeStyles } from "makeStyles";
import RawOpportunity from "models/RawOpportunity";
import { defineMessages, FormattedMessage, IntlShape } from "react-intl";
import { useSelector } from "react-redux";
import {
  selectOnlyNewUpdatesSinceDate,
  selectOnlyNewUpdatesSinceFieldname,
} from "redux/accountMapping/selector";
import { Available360MappingColumns } from "redux/mapping360/defaults";
import { AvailableNearboundAccountsColumns } from "redux/mapping360NearboundAccounts/defaults";
import {
  AccountMappingStandardFieldName,
  DisplaySinceFilterFieldname,
} from "screens/Frontoffice/screens/DataTables/screens/AccountMapping/shared/types";
import {
  FieldType,
  MatchFieldType,
} from "screens/Frontoffice/screens/DataTables/shared/types";

import { CellProps, getIsNewMatch } from "../utils";
import NewChip from "./CompanyNameCell/components/NewChip";

const extraFieldRegex = /^(crm|leftCrm|rightCrm)Field\d+$/;

type Props = CellProps & {
  fields: { [key: string]: FieldType };
  openDealFields?: { [key: string]: FieldType };
};

const OpenDealCell = ({
  fieldName,
  openDealFields,
  row,
  value,
  fields,
}: Props) => {
  const { classes } = useStyles();
  const displaySinceFilterFieldname = useSelector(
    selectOnlyNewUpdatesSinceFieldname
  );
  const displaySinceFilterDate = useSelector(selectOnlyNewUpdatesSinceDate);

  const side = getOpenDealSide(fieldName);

  const newOpportunity = hasNewOpportunity(
    side,
    row,
    displaySinceFilterFieldname,
    displaySinceFilterDate
  );

  const opportunities = getOpportunities(row, fieldName);
  const hasOpportunityDetails =
    row.type === "nearbound_accounts" || row.type === "matched_accounts"
      ? getHasOpportunityDetailsNearboundAccountOpportunity(
          opportunities as IOpportunity[],
          openDealFields
        )
      : getHasOpportunityDetails(row, fieldName);

  const currencyFieldNames: string[] = _.reduce(
    fields,
    (acc, field, name) => {
      if (field.type === MatchFieldType.CURRENCY) return [...acc, name];
      return acc;
    },
    [] as string[]
  );

  if (!hasOpportunityDetails) {
    if (value === true) {
      return (
        <div className={classes.root}>
          <CircleCheck className={classes.checkIcon} />
          {newOpportunity && (
            <div className={classes.newChip}>
              <NewChip tooltip={i18n.newOpportCreated} />
            </div>
          )}
        </div>
      );
    } else {
      return (
        <div className={classes.root}>
          <T>{"-"}</T>
        </div>
      );
    }
  }
  if (opportunities.length === 0) {
    return (
      <div className={classes.root}>
        <T>{"-"}</T>
      </div>
    );
  }

  // TODO: can remove this when the prev 360 logic is completely removed
  const opportunitiesProps = _(opportunities)
    .orderBy(
      currencyFieldNames.map((name) => name + ".amount"),
      Array(currencyFieldNames.length).fill("desc")
    )
    .slice(0, 3)
    .map((opportunity: RawOpportunity) => {
      const opportunityFieldKeys = _.keys(opportunity).filter(
        (key) => extraFieldRegex.test(key) && key in fields
      );
      const opportunityFields = opportunityFieldKeys.map((oppFieldKey) => ({
        ...fields[oppFieldKey],
        value: opportunity[oppFieldKey],
      }));

      return {
        opportunity: opportunity,
        fields: opportunityFields,
        disableDelete: true,
      };
    })
    .value();

  return (
    <div className={classes.opportunitiesWrapper}>
      {opportunitiesProps.map((props: $TSFixMe) =>
        !_.isEmpty(openDealFields) ? (
          <NearboundAccountOpportunityTableItem
            {...props}
            openDealFields={openDealFields}
          />
        ) : (
          <RawOpportunityDisplay {...props} />
        )
      )}
      {opportunities.length > 3 && (
        <T className={classes.andMore}>
          <FormattedMessage
            {...i18n.andMore}
            values={{ count: opportunities.length - 3 }}
          />
        </T>
      )}
      {newOpportunity && (
        <div className={classes.newChip}>
          <NewChip tooltip={i18n.newOpportCreated} />
        </div>
      )}
    </div>
  );
};

export default OpenDealCell;

// Helpers

export const isOpenDealCell = ({ fieldName }: CellProps) => {
  return (
    fieldName === AvailableNearboundAccountsColumns.HasOpenDeal ||
    fieldName === Available360MappingColumns.active ||
    fieldName === AccountMappingStandardFieldName.leftActive ||
    fieldName === AccountMappingStandardFieldName.rightActive ||
    fieldName === AccountMappingStandardFieldName.leftHasOpenDeal ||
    fieldName === AccountMappingStandardFieldName.rightHasOpenDeal
  );
};

export const getOpenDealSide = (fieldName: string) => {
  return fieldName === AccountMappingStandardFieldName.rightActive
    ? "right"
    : fieldName === AccountMappingStandardFieldName.leftActive
    ? "left"
    : undefined;
};

export const getOpenDealCopyContent = (
  { value, fieldName, row }: CellProps,
  intl: IntlShape
) => {
  const opportunities = getOpportunities(row, fieldName);
  if (opportunities.length === 0) {
    return intl.formatMessage(value ? generic.true : generic.false);
  } else {
    return intl.formatMessage(generic.true);
  }
};

const getOpportunities = (row: any, fieldName: string) => {
  let openOpportunityAttribute =
    row.type === "nearbound_accounts" ? "openDeals" : "openOpportunities";
  if (fieldName === AccountMappingStandardFieldName.leftActive) {
    openOpportunityAttribute = "leftOpenOpportunities";
  } else if (fieldName === AccountMappingStandardFieldName.rightActive) {
    openOpportunityAttribute = "rightOpenOpportunities";
  } else if (fieldName === AccountMappingStandardFieldName.leftHasOpenDeal) {
    openOpportunityAttribute = "leftOpenDeals";
  } else if (fieldName === AccountMappingStandardFieldName.rightHasOpenDeal) {
    openOpportunityAttribute = "rightOpenDeals";
  }
  return _.get(row, openOpportunityAttribute, []);
};

const getHasOpportunityDetails = (row: any, fieldName: string) => {
  let hasOpportunityDetailsAttribute = "hasOpportunityDetails";
  if (fieldName === AccountMappingStandardFieldName.leftActive) {
    hasOpportunityDetailsAttribute = "leftHasOpportunityDetails";
  } else if (fieldName === AccountMappingStandardFieldName.rightActive) {
    hasOpportunityDetailsAttribute = "rightHasOpportunityDetails";
  }
  return _.get(row, hasOpportunityDetailsAttribute, false);
};

const getHasOpportunityDetailsNearboundAccountOpportunity = (
  opportunities: IOpportunity[],
  openDealFields?: { [key: string]: FieldType }
) => {
  return Object.keys(openDealFields ?? {}).some((field) =>
    opportunities.some((opportunity) =>
      field.includes(String(opportunity.integration_id))
    )
  );
};

export const hasNewOpportunity = (
  side: string | undefined,
  row: any,
  displaySinceFilterFieldname: DisplaySinceFilterFieldname | null,
  displaySinceFilterDate: Date | null
) => {
  if (getIsNewMatch(row, displaySinceFilterDate)) return false;

  const opportChangedAt = _.get(row, `${side}ActiveChangedAt`, null);
  const hasChanged =
    displaySinceFilterDate &&
    new Date(opportChangedAt) >= displaySinceFilterDate;

  const lastCreatedOpportunityChanged =
    displaySinceFilterFieldname ===
      DisplaySinceFilterFieldname.LastCreatedOpportunityChanged && hasChanged;

  const myOpportunityOrPartnerStatusChanged =
    displaySinceFilterFieldname ===
      DisplaySinceFilterFieldname.MyOpportunityOrPartnerStatusChanged &&
    side === "left" &&
    hasChanged;

  return lastCreatedOpportunityChanged || myOpportunityOrPartnerStatusChanged;
};

/// CSS

export const useStyles = makeStyles()((theme) => ({
  root: {
    display: "flex",
    position: "relative",
    width: "100%",
    alignItems: "center",
  },
  newChip: {
    position: "absolute",
    display: "flex",
    height: "100%",
    right: "-9px",
    width: 32,
    alignItems: "center",
    justifyContent: "end",
  },
  opportunitiesWrapper: {
    display: "flex",
    position: "relative",
    width: "100%",
    flexDirection: "row",
    "& > *:not(:last-child)": {
      marginRight: theme.spacing(1),
    },
  },
  andMore: {
    padding: 6,
  },
  checkIcon: {
    fontSize: 16,
    color: theme.palette.green400,
  },
}));

/// I18N

const i18n = defineMessages({
  andMore: {
    id: "OpenDealCell.AndMore",
    defaultMessage: "+{count} more",
  },
  newOpportCreated: {
    id: "OpenDealCell.NewOpportCreated",
    defaultMessage: "New opportunity created",
  },
});
