import { T } from "components/ui/Typography";
import { makeStyles } from "makeStyles";
import { ReactNode } from "react";
import { defineMessages, FormattedMessage } from "react-intl";

const useStyles = makeStyles()((theme) => ({
  root: {
    position: "absolute",
    height: "auto",
    bottom: 0,
    top: 0,
    left: 0,
    right: 0,
    margin: theme.spacing(4),
  },
  container: {
    height: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    "& > *": {
      marginBottom: theme.spacing(4),
    },
  },
  logo: {
    width: 100,
  },
}));

const i18n = defineMessages({
  defaultTitle: {
    id: "ErrorPage.title",
    defaultMessage: "There was a problem…",
  },
  defaultSubtitle: {
    id: "ErrorPage.subtitle",
    defaultMessage:
      "Our team has been alerted and should act to solve this problem very quickly.",
  },
});

type Props = {
  title?: ReactNode;
  subtitle?: ReactNode;
};

const ErrorPage = ({ title, subtitle }: Props) => {
  const { classes } = useStyles();
  return (
    <div className={classes.root}>
      <div className={classes.container}>
        <img
          className={classes.logo}
          src="/images/reveal-icon-purple-square.png"
          alt=""
        />
        <T h1>{title || <FormattedMessage {...i18n.defaultTitle} />}</T>
        <T oldVariant="body2">
          {subtitle || <FormattedMessage {...i18n.defaultSubtitle} />}
        </T>
      </div>
    </div>
  );
};

export default ErrorPage;
