import { Grid } from "@mui/material";
import { ChooseFieldsBlock, Option } from "components/ui/ChooseFieldsBlock";
import { T } from "components/ui/Typography";
import useDefaultDisplayIndex from "hooks/useDefaultDisplayIndex";
import _ from "lodash";
import { makeStyles } from "makeStyles";
import CrmField, { SwRecordType } from "models/CrmField";
import {
  defineMessages,
  FormattedHTMLMessage,
  FormattedMessage,
} from "react-intl";
import { Link as RedirectLink, useLocation } from "react-router-dom";

type Props = {
  integrationId: number;
  allFields: CrmField[];
  selectedFields: CrmField[];
  setSelectedFields: (value: CrmField[]) => void;
  loading?: boolean;
};

const OpportunityDisplayWidget = ({
  integrationId,
  allFields,
  selectedFields,
  setSelectedFields,
  loading,
}: Props) => {
  const { classes } = useStyles();
  const { pathname } = useLocation();

  const {
    fieldIds: defaultFieldIds,
    loading: loadingDefaultFields,
  } = useDefaultDisplayIndex(integrationId, SwRecordType.RawOpportunity);

  // @ts-ignore
  const defaultFields: CrmField[] = defaultFieldIds
    .map((id) => allFields.find((field) => field.id === +id))
    .filter((field) => field !== undefined);

  const resetDefaultSetting = () => {
    setSelectedFields(defaultFields);
  };

  const isDefault = _.isEqual(selectedFields, defaultFields);

  const availableFields = allFields.filter(
    (field) => !selectedFields.includes(field)
  );

  const addMoreField = (
    <div>
      <FormattedMessage
        {...(availableFields.length === 0
          ? i18n.noAvailableFields
          : i18n.importMoreFields)}
      />
      &nbsp;
      <RedirectLink target="_blank" to={`${pathname}#custom-fields`}>
        <FormattedMessage {...i18n.customFieldSettingsRedirect} />
      </RedirectLink>
    </div>
  );

  return (
    <Grid container className={classes.root}>
      <Grid item md={4} xs={12}>
        <T h3 bold className={classes.title}>
          <FormattedMessage {...i18n.title} />
        </T>
        <T>
          <FormattedHTMLMessage {...i18n.summary} />
        </T>
      </Grid>
      <ChooseFieldsBlock
        allFields={allFields}
        selectedFields={selectedFields}
        setSelectedFields={setSelectedFields as (value: Option[]) => void}
        loading={loading || loadingDefaultFields}
        emptySelectionPlaceholder={<FormattedMessage {...i18n.noFields} />}
        onReset={resetDefaultSetting}
        showReset={!isDefault}
        extraLastOption={addMoreField}
        fieldsLimit={4}
      />
    </Grid>
  );
};

const useStyles = makeStyles()((theme) => ({
  root: {
    marginTop: theme.spacing(2),
    backgroundColor: theme.palette.ivory,
    borderRadius: 8,
    padding: 24,
  },
  title: {
    marginBottom: 16,
  },
}));

const i18n = defineMessages({
  title: {
    id: "crm.settings.OpportunityDisplayWidget.title",
    defaultMessage: "Opportunity display",
  },
  summary: {
    id: "crm.settings.OpportunityDisplayWidget.summary",
    defaultMessage: `Configure Open opportunities in Reveal.<br/>
    This is what you see, not what you share with partners - as shared fields can be configured on a per partner basis.`,
  },
  noFields: {
    id: "crm.settings.OpportunityDisplayWidget.noFields",
    defaultMessage:
      "You will only see if the account has open opportunity or not.",
  },
  noAvailableFields: {
    id: "crm.settings.OpportunityDisplayWidget.noAvailableFields",
    defaultMessage: "No field available.",
  },
  importMoreFields: {
    id: "crm.settings.OpportunityDisplayWidget.importMoreFields",
    defaultMessage: "Can't find your field?",
  },
  customFieldSettingsRedirect: {
    id: "crm.settings.OpportunityDisplayWidget.customFieldSettingsRedirect",
    defaultMessage: "Add custom fields.",
  },
});

export default OpportunityDisplayWidget;

export const _private = {
  i18n,
};
