import { AxiosResponse } from "axios";
import { ArrowRight } from "components/icons";
import Button from "components/ui/Button";
import { T } from "components/ui/Typography";
import getFirstValueFromURL from "helpers/getFirstValueFromURL";
import { makeStyles } from "makeStyles";
import { useEffect, useState } from "react";
import {
  defineMessages,
  FormattedMessage,
  MessageDescriptor,
} from "react-intl";
import { useHistory, useLocation } from "react-router-dom";
import JSONAPIService from "services/JSONAPIService";

import { BaseContent, sharedStyles } from "./BaseContent";

type Props = {
  onClose: () => void;
};

export const SourceActivationContent = ({ onClose }: Props) => {
  const { classes } = sharedStyles({});
  const { classes: btnClasses } = useBtnStyles();
  const history = useHistory();
  const location = useLocation();
  const integrationId = getFirstValueFromURL(location, "integration");
  const [accountCount, setAccountCount] = useState<number | undefined>(
    undefined
  );
  const [opportunityCount, setOpportunityCount] = useState<number | undefined>(
    undefined
  );

  useEffect(() => {
    const fetchInformation = async () => {
      const service = new JSONAPIService("integrations");
      let result: undefined | AxiosResponse = undefined;
      try {
        result = await service.rawGet(`/${integrationId}/crm-records-count/`);
      } catch (error) {
        return;
      }
      setAccountCount(result.data?.data?.account_count ?? undefined);
      setOpportunityCount(result.data?.data?.opportunity_count ?? undefined);
    };
    fetchInformation();
  }, [integrationId]);

  const handleClose = () => {
    onClose();
    history.replace({
      ...location,
      search: "",
    });
  };

  return (
    <BaseContent>
      <div className={classes.textCenter}>
        <div className={classes.marginBottom}>
          <img
            className={classes.bigIcon}
            src="/images/CircleCheckmarkSuccessBig.png"
            alt=""
          />
        </div>
        <T h2 className={classes.contentTitle}>
          <FormattedMessage {...i18n.title} />
        </T>
        <T
          className={
            accountCount || opportunityCount
              ? classes.marginBottomLarge
              : undefined
          }
        >
          <FormattedMessage {...i18n.subtitle} />
          <br />
          <FormattedMessage {...i18n.additionalDetails} />
        </T>
        {!!accountCount && (
          <DataPreview text={i18n.accounts} value={accountCount} />
        )}
        {!!opportunityCount && (
          <DataPreview text={i18n.opportunities} value={opportunityCount} />
        )}
        <Button
          classes={btnClasses}
          label={i18n.finalize}
          onClick={handleClose}
          RightIcon={ArrowRight}
        />
      </div>
    </BaseContent>
  );
};

const DataPreview = ({
  text,
  value,
}: {
  text: MessageDescriptor;
  value?: number;
}) => {
  const { classes } = sharedStyles({});
  return (
    <div className={classes.dataPreview}>
      <T h2 bold>
        <FormattedMessage {...text} />
      </T>
      <T h2 bold>
        {value}
      </T>
    </div>
  );
};

const i18n = defineMessages({
  accounts: {
    id: "SourceActivationContent.accounts",
    defaultMessage: "Accounts",
  },
  additionalDetails: {
    id: "SourceActivationContent.additionalDetails",
    defaultMessage:
      "You may define further the data you want to display on Reveal in the settings (private accounts, strategic accounts).",
  },
  finalize: {
    id: "SourceActivationContent.finalize",
    defaultMessage: "Finalize",
  },
  opportunities: {
    id: "SourceActivationContent.opportunities",
    defaultMessage: "Opportunities",
  },
  title: {
    id: "SourceActivationContent.title",
    defaultMessage: "Your source is almost ready",
  },
  subtitle: {
    id: "SourceActivationContent.subtitle",
    defaultMessage:
      "Don't worry, no data will be shared with any partners until you allow it on a partner by partner basis.",
  },
});

const useBtnStyles = makeStyles()((theme) => ({
  btn: {
    marginTop: theme.spacing(3),
  },
}));
