import Drawer from "@mui/material/Drawer";
import { FLAGS } from "config/featureFlags";
import { makeStyles } from "makeStyles";

const defaultDrawerWidth = 600;

type Props = {
  children?: React.ReactChild;
  isOpen?: boolean;
  inDialog?: boolean;
  width?: number;
  onClose?: () => void;
};

const ActionPanel = ({
  children,
  isOpen = false,
  inDialog = false,
  width = defaultDrawerWidth,
  onClose,
}: Props) => {
  const { classes } = useStyles({
    inDialog: inDialog && !FLAGS.redesign,
    width,
  });

  return (
    <Drawer
      anchor="right"
      open={isOpen}
      classes={{
        paper: classes.drawerPaper,
      }}
      data-testid="action-panel"
      onClose={onClose}
    >
      {children}
    </Drawer>
  );
};

export default ActionPanel;

// CSS

export const useStyles = makeStyles<{ inDialog: boolean; width: number }>()(
  (theme, { inDialog, width }) => ({
    drawerPaper: {
      backgroundColor: theme.palette.ivory,
      zIndex: 2500,
      width,
      marginTop: inDialog ? theme.spacing(2) : 0,
      marginRight: inDialog ? theme.spacing(2) : 0,
      height: inDialog ? "calc(100% - 32px)" : "100%",
      borderTopRightRadius: inDialog ? 4 : 0,
      borderBottomRightRadius: inDialog ? 4 : 0,
      [theme.breakpoints.down("md")]: {
        width: "100%",
        height: "100%",
        marginTop: 0,
        marginRight: 0,
      },
    },
  })
);
