import { DEFAULT_WIDTH as tagsDefaultWidth } from "components/ui/data-grid/cellRenderers/analytics/TagsCell";
import {
  ColumnConfigType,
  MatchFilterType,
  orderColumns,
} from "screens/Frontoffice/screens/DataTables/shared/types";

import {
  AnalyticsOtherFilterFieldname,
  AnalyticsQuickFilterFieldname,
} from "./types";

export enum AvailableAnalytisColumns {
  favorite = "favorite",
  partnerCompanyName = "partnerCompanyName",
  ownerCompany = "myPartnershipOwnerId",
  ownerPartner = "partnerPartnershipOwnerId",
  tags = "partnershipTags",
  // Metrics
  winRate = "win_rate",
  dealSize = "deal_size",
  revenuePotential = "revenue_potential",
  overlaps = "overlaps",
  newProspectsFromPartner = "new_prospects_from_partners_value",
  commonCustomers = "common_customers",
  commonOpportunities = "common_opportunities",
  opportunitiesPartnerCustomers = "opportunities_among_partners_customers_value",
  myProspectsPartnerCustomers = "my_prospects_partner_customers_value",
  newProspectsForPartner = "partner_new_prospects",
  myCustomersPartnerProspects = "my_customers_partner_prospects",
  marketOverlap = "market_overlap",
  personaOverlap = "persona_overlap",
}

export enum AdditionalAnalyticsColumns {
  newProspectsFromPartnerRevenue = "new_prospects_from_partners_revenue_potential",
  opportunitiesPartnerCustomersRevenue = "opportunities_among_partners_customers_revenue_potential",
  myProspectsPartnerCustomersRevenue = "my_prospects_partner_customers_revenue_potential",
}

export const metricsColumnsMapping: [
  AvailableAnalytisColumns,
  AdditionalAnalyticsColumns
][] = [
  [
    AvailableAnalytisColumns.newProspectsFromPartner,
    AdditionalAnalyticsColumns.newProspectsFromPartnerRevenue,
  ],
  [
    AvailableAnalytisColumns.opportunitiesPartnerCustomers,
    AdditionalAnalyticsColumns.opportunitiesPartnerCustomersRevenue,
  ],
  [
    AvailableAnalytisColumns.myProspectsPartnerCustomers,
    AdditionalAnalyticsColumns.myProspectsPartnerCustomersRevenue,
  ],
];

const defaultAnalyticsColumnWithMetricsKeys = [
  AvailableAnalytisColumns.favorite,
  AvailableAnalytisColumns.partnerCompanyName,
  AvailableAnalytisColumns.winRate,
  AvailableAnalytisColumns.dealSize,
  AvailableAnalytisColumns.revenuePotential,
  AvailableAnalytisColumns.overlaps,
  AvailableAnalytisColumns.newProspectsFromPartner,
  AdditionalAnalyticsColumns.newProspectsFromPartnerRevenue,
  AvailableAnalytisColumns.commonCustomers,
  AvailableAnalytisColumns.commonOpportunities,
  AvailableAnalytisColumns.opportunitiesPartnerCustomers,
  AdditionalAnalyticsColumns.opportunitiesPartnerCustomersRevenue,
  AvailableAnalytisColumns.myProspectsPartnerCustomers,
  AdditionalAnalyticsColumns.myProspectsPartnerCustomersRevenue,
  AvailableAnalytisColumns.tags,
];

export const revenueColumnConfig: {
  [key: string]: ColumnConfigType;
} = {
  [AdditionalAnalyticsColumns.newProspectsFromPartnerRevenue]: {
    key: AdditionalAnalyticsColumns.newProspectsFromPartnerRevenue,
    width: 100,
    frozen: false,
  },
  [AdditionalAnalyticsColumns.opportunitiesPartnerCustomersRevenue]: {
    key: AdditionalAnalyticsColumns.opportunitiesPartnerCustomersRevenue,
    width: 100,
    frozen: false,
  },
  [AdditionalAnalyticsColumns.myProspectsPartnerCustomersRevenue]: {
    key: AdditionalAnalyticsColumns.myProspectsPartnerCustomersRevenue,
    width: 100,
    frozen: false,
  },
};

export const analyticsColumnConfig: {
  [key: string]: ColumnConfigType;
} = {
  [AvailableAnalytisColumns.favorite]: {
    key: AvailableAnalytisColumns.favorite,
    width: 50,
    frozen: true,
    removable: false,
    fixedPosition: 1,
  },
  [AvailableAnalytisColumns.partnerCompanyName]: {
    key: AvailableAnalytisColumns.partnerCompanyName,
    width: 196,
    frozen: true,
    removable: false,
    fixedPosition: 2,
  },
  [AvailableAnalytisColumns.winRate]: {
    key: AvailableAnalytisColumns.winRate,
    width: 106,
    frozen: false,
    removable: true,
  },
  [AvailableAnalytisColumns.dealSize]: {
    key: AvailableAnalytisColumns.dealSize,
    width: 100,
    frozen: false,
    removable: true,
  },
  [AvailableAnalytisColumns.revenuePotential]: {
    key: AvailableAnalytisColumns.revenuePotential,
    width: 100,
    frozen: false,
    removable: true,
  },
  [AvailableAnalytisColumns.overlaps]: {
    key: AvailableAnalytisColumns.overlaps,
    width: 100,
    frozen: false,
    removable: true,
  },
  [AvailableAnalytisColumns.newProspectsFromPartner]: {
    key: AvailableAnalytisColumns.newProspectsFromPartner,
    width: 126,
    frozen: false,
    removable: true,
  },
  [AvailableAnalytisColumns.marketOverlap]: {
    key: AvailableAnalytisColumns.marketOverlap,
    width: 71,
    frozen: false,
    removable: true,
  },
  [AvailableAnalytisColumns.personaOverlap]: {
    key: AvailableAnalytisColumns.personaOverlap,
    width: 74,
    frozen: false,
    removable: true,
  },
  [AvailableAnalytisColumns.ownerCompany]: {
    key: AvailableAnalytisColumns.ownerCompany,
    width: 153,
    frozen: false,
    removable: true,
  },
  [AvailableAnalytisColumns.ownerPartner]: {
    key: AvailableAnalytisColumns.ownerPartner,
    width: 153,
    frozen: false,
    removable: true,
  },
  [AvailableAnalytisColumns.commonCustomers]: {
    key: AvailableAnalytisColumns.commonCustomers,
    width: 90,
    frozen: false,
    removable: true,
  },
  [AvailableAnalytisColumns.commonOpportunities]: {
    key: AvailableAnalytisColumns.commonOpportunities,
    width: 107,
    frozen: false,
    removable: true,
  },
  [AvailableAnalytisColumns.opportunitiesPartnerCustomers]: {
    key: AvailableAnalytisColumns.opportunitiesPartnerCustomers,
    width: 122,
    frozen: false,
    removable: true,
  },
  [AvailableAnalytisColumns.myProspectsPartnerCustomers]: {
    key: AvailableAnalytisColumns.myProspectsPartnerCustomers,
    width: 106,
    frozen: false,
    removable: true,
  },
  [AvailableAnalytisColumns.myCustomersPartnerProspects]: {
    key: AvailableAnalytisColumns.myCustomersPartnerProspects,
    width: 113,
    frozen: false,
    removable: true,
  },
  [AvailableAnalytisColumns.newProspectsForPartner]: {
    key: AvailableAnalytisColumns.newProspectsForPartner,
    width: 96,
    frozen: false,
    removable: true,
  },
  [AvailableAnalytisColumns.tags]: {
    key: AvailableAnalytisColumns.tags,
    width: tagsDefaultWidth,
    frozen: false,
    removable: true,
  },
};

export const analyticsColumnsWithMetricsConfig: {
  [key: string]: ColumnConfigType;
} = { ...analyticsColumnConfig, ...revenueColumnConfig };

const defaultAnalyticsColumnKeys = defaultAnalyticsColumnWithMetricsKeys.filter(
  (key) =>
    !Object.values(AdditionalAnalyticsColumns).includes(
      key as AdditionalAnalyticsColumns
    )
);

export const defaultAnalyticsColumnConfig = (
  key: string,
  field: { label: string }
) => ({
  key,
  width: 100,
  frozen: false,
});

export const applyAnalyticsColumnOrder = (columns: ColumnConfigType[]) =>
  orderColumns(columns);

export const defaultAnalyticsColumns = applyAnalyticsColumnOrder(
  defaultAnalyticsColumnKeys.map((key) => analyticsColumnConfig[key])
);
export const defaultAnalyticsColumnWithMetrics = applyAnalyticsColumnOrder(
  defaultAnalyticsColumnWithMetricsKeys.map(
    (key) => analyticsColumnsWithMetricsConfig[key]
  )
);

// TODO: Add the default sort
export const defaultAnalyticsSort = [];
// default analytics filters
export const defaultShowSummaryMetricsFilter = {
  fieldname: AnalyticsOtherFilterFieldname.showSummaryMetrics,
  type: MatchFilterType._NO_OPERATOR,
  value: true,
};
export const defaultHighlightHighWinRateFilter = {
  fieldname: AnalyticsOtherFilterFieldname.highlightHighWinRate,
  type: MatchFilterType._NO_OPERATOR,
  value: true,
};
export const defaultIncludeOfflineFilter = {
  fieldname: AnalyticsOtherFilterFieldname.IncludeOffline,
  type: MatchFilterType._NO_OPERATOR,
  value: false,
};

export const defaultAnalyticsFilters = [
  defaultShowSummaryMetricsFilter,
  defaultHighlightHighWinRateFilter,
];
// End default analytics filters

export const PERCENTAGE_FIELDS: string[] = [
  AvailableAnalytisColumns.marketOverlap,
  AvailableAnalytisColumns.personaOverlap,
  AvailableAnalytisColumns.winRate,
];

export const quickFilterFilters = [
  AnalyticsQuickFilterFieldname.Favorites,
  AnalyticsQuickFilterFieldname.HighWinRatePartners,
  AnalyticsQuickFilterFieldname.MyPartners,
];

export const notFilterWidgetFilters = [
  AnalyticsOtherFilterFieldname.Tags,
  AnalyticsOtherFilterFieldname.IncludeOffline,
  AnalyticsOtherFilterFieldname.showSummaryMetrics,
  AnalyticsOtherFilterFieldname.highlightHighWinRate,
  ...quickFilterFilters,
];
