import { Box, ClassNameMap } from "@mui/material";
import { Copy } from "components/icons";
import Tooltip from "components/ui/Tooltip";
import { makeStyles } from "makeStyles";
import { useEffect, useState } from "react";
import { defineMessages, FormattedMessage } from "react-intl";
import { useMergedClasses } from "tss-react";

type TextWithCopyIconProps = {
  classes?: ClassNameMap;
  email: string;
  text: string;
  handleTrack: () => void;
  hoverText?: string;
};

export const TextWithCopyIcon = ({
  classes: newClasses = {},
  email,
  text,
  handleTrack,
  hoverText,
}: TextWithCopyIconProps) => {
  const { classes: baseClasses } = useStyles();
  const classes = useMergedClasses(baseClasses, newClasses);
  const [copied, setCopied] = useState(false);

  const copyToClipboard = async (text: string) => {
    try {
      await navigator.clipboard.writeText(text);
      setCopied(true);
      handleTrack();
    } catch (err) {
      console.error("Failed to copy text: ", err);
    }
  };

  useEffect(() => {
    if (copied) {
      const timer = setTimeout(() => setCopied(false), 2000);
      return () => clearTimeout(timer);
    }
  }, [copied]);

  return (
    <Box alignItems="center" display="flex">
      <Box className={classes.email} onCopy={handleTrack}>
        {text}
      </Box>
      <Tooltip
        title={copied ? <FormattedMessage {...i18n.copied} /> : hoverText ?? ""}
      >
        <Copy
          className={classes.copyIcon}
          onClick={() => copyToClipboard(email)}
        />
      </Tooltip>
    </Box>
  );
};

const useStyles = makeStyles()((theme) => ({
  copyIcon: {
    color: theme.palette.alpha500,
    cursor: "pointer",
    height: 12,
    marginLeft: 6,
    width: 12,
  },
  email: {
    color: theme.palette.alpha500,
    fontFamily: "'Inter', sans-serif",
    fontWeight: 500,
  },
}));

const i18n = defineMessages({
  copied: {
    id: "Ecosystem.TextWithCopyIcon.copied",
    defaultMessage: "Copied!",
  },
});
