import { createAsyncThunk } from "@reduxjs/toolkit";
import _ from "lodash";
import Notification from "models/Notification";
import { updateAttributesOnRecord } from "redux/api/actions";
import { rawPost } from "redux/api/thunks";

import {
  NotificationWidgetActions,
  RootStateWithNotificationWidget,
} from "./types";

export const markNotificationAsRead = createAsyncThunk(
  NotificationWidgetActions.markNotificationAsRead,
  async (notification: Notification, thunkAPI) => {
    await thunkAPI.dispatch(
      rawPost({
        type: "notifications",
        path: "mark-all-as-read/",
        payload: {
          data: {
            filters: {
              notification_type: notification.notificationType,
              partnership_id: notification.partnership.id,
            },
          },
        },
      })
    );
    const currentState = thunkAPI.getState() as RootStateWithNotificationWidget;
    const notificationIdsForSamePartnerAndType: number[] = _(
      currentState.api.entities.notifications ?? {}
    )
      .filter((item: Notification) => {
        return (
          item.notificationType === notification.notificationType &&
          item.partnership.id === notification.partnership.id &&
          !item.readByUser
        );
      })
      .map((item) => item.id)
      .value();

    notificationIdsForSamePartnerAndType.forEach((notificationId) => {
      thunkAPI.dispatch(
        updateAttributesOnRecord(
          {
            type: "notifications",
            id: String(notificationId),
          },
          {
            readByUser: true,
          }
        )
      );
    });

    return { readNotification: notification };
  }
);
