import Grid from "@mui/material/Grid";
import { makeStyles } from "makeStyles";
import { ReactNode } from "react";

import Box from "./Box";
import { T } from "./Typography";

type Variant = "default" | "outlined";

type Props = {
  title?: ReactNode;
  subtitle?: ReactNode;
  children: ReactNode;
  variant?: Variant;
  extraContent?: ReactNode;
  iconSrc?: string;
};

const SettingsSection = ({
  children,
  subtitle,
  title,
  variant = "default",
  iconSrc = "",
  extraContent,
}: Props) => {
  const { classes } = useStyles({ variant });
  const hasLeftSection = !!title || !!subtitle || !!iconSrc;
  return (
    <Box className={classes.root} variant={variant}>
      <Grid container>
        {hasLeftSection && (
          <Grid item md={4} sm={12} className={classes.header}>
            {iconSrc && <img src={iconSrc} alt="" className={classes.icon} />}
            {title && (
              <T h3 bold className={classes.title}>
                {title}
              </T>
            )}
            {subtitle && <T bodySmall>{subtitle}</T>}
          </Grid>
        )}
        <Grid item md={hasLeftSection ? 8 : 12} sm={12}>
          {children}
        </Grid>
      </Grid>
      <Grid item md={12}>
        {extraContent}
      </Grid>
    </Box>
  );
};

export default SettingsSection;

/// CSS

type StyleProps = {
  variant: Variant;
};

export const useStyles = makeStyles<StyleProps>()((theme) => ({
  root: {
    padding: 1,
    flexDirection: "column",
    borderRadius: theme.spacing(1),
    [theme.breakpoints.down("md")]: {
      marginLeft: theme.spacing(2),
      marginRight: theme.spacing(2),
    },
    [theme.breakpoints.down("sm")]: {
      marginLeft: 0,
      marginRight: 0,
    },
  },
  content: {
    backgroundColor: theme.palette.ivory,
    padding: theme.spacing(4),
    borderRadius: 7,
    "& > :not(:last-child)": {
      marginBottom: theme.spacing(2),
    },
  },
  header: {
    paddingBottom: theme.spacing(6),
    paddingRight: theme.spacing(8),
  },
  title: {
    marginBottom: theme.spacing(2),
  },
  icon: {
    marginBottom: theme.spacing(2),
  },
}));
