import { makeStyles } from "makeStyles";
import { defineMessages, FormattedMessage } from "react-intl";

export const ComingSoonTag = () => {
  const { classes } = useStyles();
  return (
    <div className={classes.root}>
      <FormattedMessage {...i18n.comingSoon} />
    </div>
  );
};

const i18n = defineMessages({
  comingSoon: {
    id: "ComingSoonTag.comingSoon",
    defaultMessage: "Coming soon",
  },
});

const useStyles = makeStyles()((theme) => ({
  root: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    padding: "0 6px",
    height: 16,
    borderRadius: 16,
    backgroundColor: theme.palette.purple,
    color: theme.palette.ivory,
    fontSize: 10,
    fontWeight: 500,
    lineHeight: "150%",
  },
}));
