import sentry from "helpers/sentry";
import { Component } from "react";

import ErrorPage from "./ErrorPage";

type State = $TSFixMe;

export class ErrorHandler extends Component<{}, State> {
  constructor(props: {}) {
    super(props);
    this.state = { hasError: false };
  }

  componentDidCatch(error: Error) {
    sentry.reportError(error);
    this.setState({ hasError: true });
  }

  render() {
    if (this.state.hasError) {
      return <ErrorPage />;
    }
    return this.props.children;
  }
}

export default ErrorHandler;
