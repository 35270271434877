import { AvailableAnalytisColumns } from "redux/analytics/defaults";

import { CellProps } from "../../utils";
import BoostCell, { BoostCellVariant } from "../shared/BoostCell";

const DealSizeCell = ({ row, fieldName }: CellProps) => (
  <BoostCell
    row={row}
    fieldName={fieldName}
    variant={BoostCellVariant.DealSize}
  />
);

export default DealSizeCell;

// Helpers

export const isDealSizeCell = ({ fieldName }: CellProps) => {
  return fieldName === AvailableAnalytisColumns.dealSize;
};
