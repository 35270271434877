import _ from "lodash";
import ModelRecord from "models/Record";
import { defineMessages, MessageDescriptor, useIntl } from "react-intl";
import { useSelector } from "react-redux";
import { APICollection } from "redux/api/typing";
import { RevealStore } from "redux/typing";

import useUserProfile from "./useUserProfile";

const useAvailableRoles = (includeUnassignable = false) => {
  const { profile } = useUserProfile();
  const intl = useIntl();
  return useSelector((state: RevealStore) => {
    const roles: APICollection<ModelRecord<"roles">> = _(state).get(
      "api.entities.roles",
      {}
    );
    return _(roles)
      .values()
      .filter(
        (role) =>
          includeUnassignable ||
          !role.requiresPermission ||
          (profile &&
            profile.hasPermissions(
              [role.requiresPermission.name],
              profile.company
            ))
      )
      .map((role) => ({
        id: role.id,
        name: role.name,
        label:
          role.name in i18n ? intl.formatMessage(i18n[role.name]) : role.name,
        hasLabel: role.name in i18n,
        canAssign: Boolean(
          !role.requiresPermission ||
            (profile &&
              profile.hasPermissions(
                [role.requiresPermission.name],
                profile.company
              ))
        ),
      }))
      .orderBy(["hasLabel", "label"], ["desc", "asc"])
      .value();
  });
};

export default useAvailableRoles;

/// I18N

const i18n: Record<string, MessageDescriptor> = defineMessages({
  "company.admin": {
    id: "Role.Administrator",
    defaultMessage: "Administrator",
  },
  "company.partnership_manager": {
    id: "Role.PartnershipManager",
    defaultMessage: "Partnership Manager",
  },
  "company.sales": {
    id: "Role.Sales",
    defaultMessage: "Sales",
  },
  "company.sales_manager": {
    id: "Role.SalesManager",
    defaultMessage: "Sales Manager",
  },
});
