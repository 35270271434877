import { Check, OpenInNew } from "components/icons";
import { T } from "components/ui/Typography";
import { ConfigContext } from "config/ConfigProvider";
import { useContext } from "react";
import { defineMessages, FormattedMessage, useIntl } from "react-intl";

import { BaseSidePanel, sharedCrmBaseSidePanelStyles } from "./BaseSidePanel";
import { ChatHelpBox } from "./ChatHelpBox";
import { HelpBox } from "./HelpBox";
import { VideoWithHelpBox } from "./VideoWithHelpBox";

export const GoogleSheetsSidePanel = () => {
  const { classes } = sharedCrmBaseSidePanelStyles();
  const intl = useIntl();
  const config = useContext(ConfigContext);
  return (
    <BaseSidePanel>
      <VideoWithHelpBox
        i18nTitle={i18n.howToTitle}
        linkElementList={[
          {
            i18n: i18n.viewTemplate,
            url: config.templateGsheetLink as string,
            icon: <OpenInNew />,
          },
        ]}
        src={"https://www.youtube.com/embed/WMOENo8zd9w"}
        videoTitle={intl.formatMessage(i18n.videoGsheet)}
      />
      <HelpBox
        i18nTitle={i18n.uniqueIdTitle}
        i18nSubtitle={i18n.uniqueIdFirstParagraph}
        customSubtitle={
          <li className={classes.textList}>
            <ul className={classes.textListItem}>
              <T>
                <Check />
                <FormattedMessage {...i18n.firstPoint} />
              </T>
            </ul>
            <ul className={classes.textListItem}>
              <T>
                <Check />
                <FormattedMessage {...i18n.secondPoint} />
              </T>
            </ul>
          </li>
        }
        linkElementList={[
          {
            i18n: i18n.readGuidelines,
            url: config.connectGoogleSheetLink as string,
            icon: <OpenInNew />,
          },
        ]}
      />
      <ChatHelpBox />
    </BaseSidePanel>
  );
};

const i18n = defineMessages({
  firstPoint: {
    id: "crm.settings.GoogleSheetsSidePanel.firstPoint",
    defaultMessage:
      "Most CRMs already have a unique ID for accounts that you can use (for example: ‘Account ID’ in Salesforce, ‘Hub ID’ in HubSpot, etc.)",
  },
  howToTitle: {
    id: "crm.settings.GoogleSheetsSidePanel.howToTitle",
    defaultMessage: "How to plug your Google Sheet?",
  },
  readGuidelines: {
    id: "crm.settings.GoogleSheetsSidePanel.readGuidelines",
    defaultMessage: "<a>Read Guidelines</a>",
  },
  secondPoint: {
    id: "crm.settings.GoogleSheetsSidePanel.secondPoint",
    defaultMessage: "Or create your own (ex: 1,2,3,4,5)",
  },
  uniqueIdFirstParagraph: {
    id: "crm.settings.GoogleSheetsSidePanel.uniqueIdFirstParagraph",
    defaultMessage:
      "All accounts in your Google Sheet must have a unique ID associated to them. This is standard practice to track any changes on an account in the Google Sheet, so it can be updated in Reveal. To add a unique ID to your accounts, you have two options:",
  },
  uniqueIdTitle: {
    id: "crm.settings.GoogleSheetsSidePanel.uniqueIdTitle",
    defaultMessage: "What is unique ID?",
  },
  videoGsheet: {
    id: "crm.settings.GoogleSheetsSidePanel.videoGsheet",
    defaultMessage: "Video tutorial for connecting Google Sheets",
  },
  viewTemplate: {
    id: "crm.settings.GoogleSheetsSidePanel.viewTemplate",
    defaultMessage: "<a>View Template</a>",
  },
});
