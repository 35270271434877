import DialogActions from "@mui/material/DialogActions";
import FormControl from "@mui/material/FormControl";
import Grid from "@mui/material/Grid";
import { isFulfilled, isRejected } from "@reduxjs/toolkit";
import Button from "components/ui/Button";
import Popup from "components/ui/Popup";
import { TextInput } from "components/ui/TextInput";
import { T } from "components/ui/Typography";
import { ProviderType } from "config/constants";
import { Formik } from "formik";
import useAllRecords from "hooks/useAllRecords";
import usePushNotification from "hooks/usePushNotification";
import generic from "i18n/generic";
import { makeStyles } from "makeStyles";
import { defineMessages, FormattedMessage, useIntl } from "react-intl";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { create, update } from "redux/api/thunks";
import { crmProviders } from "screens/Frontoffice/screens/Sources/constants";
import { APIKeyProviderType } from "screens/Frontoffice/shared/helpers/types";
import { object, string } from "yup";

type Props = {
  provider: APIKeyProviderType;
  isOpen?: boolean;
  onClose: () => void;
  next: string | Location;
};

const schema = object().shape({
  key: string().required(),
});

const APIKeyDialog = ({ provider, isOpen = false, onClose, next }: Props) => {
  const { classes } = useStyles();
  const history = useHistory();
  const intl = useIntl();
  const dispatch = useDispatch();
  const pushNotification = usePushNotification();
  const providerName = crmProviders[provider].name;

  const { records: crmCredentials } = useAllRecords(
    "crm_credentials",
    { filters: { provider } },
    () => undefined,
    [provider]
  );
  const crmCredentialId = crmCredentials[0]?.id;

  const handleMSModalSubmit = async (
    values: { key: string },
    actions: $TSFixMe
  ) => {
    actions.setSubmitting(true);
    let result;
    crmCredentialId
      ? (result = await dispatch(
          update({
            type: "crm_credentials",
            id: crmCredentialId,
            attributes: {
              provider: provider,
              disabled: false,
              config: { api_key: values.key }, // pragma: allowlist secret
            },
          })
        ))
      : (result = await dispatch(
          create({
            type: "crm_credentials",
            attributes: {
              provider: provider,
              config: { api_key: values.key }, // pragma: allowlist secret
            },
          })
        ));

    if (isFulfilled(result)) {
      onClose();
      history.push(next);
    }
    if (isRejected(result)) {
      pushNotification("default_error");
    }
    actions.setSubmitting(false);
  };

  return (
    <Popup
      isOpen={isOpen}
      title={
        <T h2 bold>
          <FormattedMessage
            {...i18n.title}
            values={{ provider: providerName }}
          />
        </T>
      }
      handleClose={onClose}
      hideActions
      maxWidth="sm"
      variant="secondary"
    >
      <Formik
        initialValues={{
          key: "",
        }}
        onSubmit={handleMSModalSubmit}
        validationSchema={schema}
      >
        {({
          values,
          handleSubmit,
          handleChange,
          isSubmitting,
          touched,
          errors,
        }) => (
          <form onSubmit={handleSubmit}>
            <FormControl fullWidth error={Boolean(errors.key && touched.key)}>
              <T>
                <FormattedMessage {...providersDescription[provider]} />
                &nbsp;
              </T>
              <br />
              <TextInput
                className={classes.input}
                name="key"
                onChange={handleChange}
                placeholder={intl.formatMessage(i18n.placeholder)}
                disabled={isSubmitting}
                error={Boolean(touched.key && errors.key)}
                value={values.key}
              />
            </FormControl>
            <DialogActions>
              <Grid container className={classes.actionContainer}>
                <Button
                  label={generic.cancel}
                  onClick={onClose}
                  disabled={isSubmitting}
                  variant="secondary"
                />
                <Button
                  type="submit"
                  label={generic.next}
                  disabled={isSubmitting ? true : undefined}
                />
              </Grid>
            </DialogActions>
          </form>
        )}
      </Formik>
    </Popup>
  );
};

export default APIKeyDialog;

/// CSS

const useStyles = makeStyles()((theme) => ({
  input: {
    fontSize: 12,
    margin: theme.spacing(1),
  },
  actionContainer: {
    display: "flex",
    justifyContent: "flex-end",
    columnGap: theme.spacing(0.5),
  },
}));

/// I18N

const i18n = defineMessages({
  title: {
    id: "ConnectSource.APIKeyDialog.title",
    defaultMessage: "Add your {provider} API key",
  },
  stripeDescription: {
    id: "ConnectSource.APIKeyDialog.stripeDescription",
    defaultMessage: "Go to dashboard.stripe.com/apikeys to find your API key.",
  },
  freshsalesDescription: {
    id: "ConnectSource.APIKeyDialog.freshsalesDescription",
    defaultMessage:
      "Go to your API Settings within your Freshsales account to find your API Key.",
  },
  upsalesDescription: {
    id: "ConnectSource.APIKeyDialog.upsalesDescription",
    defaultMessage:
      "Go to the Settings page within your Upsales account to generate an API key.",
  },
  placeholder: {
    id: "ConnectSource.APIKeyDialog.placeholder",
    defaultMessage: "Your API key",
  },
});

export const providersDescription = {
  [ProviderType.upsales]: i18n.upsalesDescription,
  [ProviderType.freshsales]: i18n.freshsalesDescription,
  [ProviderType.stripe]: i18n.stripeDescription,
};
