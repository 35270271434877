import { createSvgIcon } from "@mui/material/utils";

import { SvgIcon } from "./types";

const displayName = "LockLight";

const LockLight: SvgIcon = createSvgIcon(
  // Using transform to enlarge from provided viewbox="0 0 16 16"
  // to default "0 0 24 24" from `createSvgIcon` -> `SvgIcon`.
  <g fill="none" transform="matrix(1.5,0,0,1.5,0,0)">
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M8 1.625C6.13604 1.625 4.625 3.13604 4.625 5V5.625H4.5C3.46447 5.625 2.625 6.46447 2.625 7.5V12.5C2.625 13.5355 3.46447 14.375 4.5 14.375H11.5C12.5355 14.375 13.375 13.5355 13.375 12.5V7.5C13.375 6.46447 12.5355 5.625 11.5 5.625H11.375V5C11.375 3.13604 9.86396 1.625 8 1.625ZM10.625 5.625V5C10.625 3.55025 9.44975 2.375 8 2.375C6.55025 2.375 5.375 3.55025 5.375 5V5.625H10.625ZM3.375 7.5C3.375 6.87868 3.87868 6.375 4.5 6.375H11.5C12.1213 6.375 12.625 6.87868 12.625 7.5V12.5C12.625 13.1213 12.1213 13.625 11.5 13.625H4.5C3.87868 13.625 3.375 13.1213 3.375 12.5V7.5ZM8.375 10.1773C8.74148 10.029 9 9.66967 9 9.25C9 8.69772 8.55228 8.25 8 8.25C7.44772 8.25 7 8.69772 7 9.25C7 9.66967 7.25852 10.029 7.625 10.1773V11.375C7.625 11.5821 7.79289 11.75 8 11.75C8.20711 11.75 8.375 11.5821 8.375 11.375V10.1773Z"
      fill="#0A151B"
    />
  </g>,
  displayName
);

LockLight.displayName = displayName;
export default LockLight;
