import _ from "lodash";
import { PayingFeature } from "models/CompanyPayingFeatureSubscription";
import { selectActivePayingFeatures } from "redux/api/selectors";
import { createSelector } from "reselect";
import {
  getDisplaySinceFilter,
  getSinceDate,
} from "screens/Frontoffice/screens/DataTables/screens/AccountMapping/components/DisplayOnlyNewUpdatesSince/utils";
import { overlapsSavedViews as oldOverlapsSavedViews } from "screens/Frontoffice/screens/DataTables/screens/AccountMapping/shared/oldQuickFilterPresets";
import { overlapsSavedViews } from "screens/Frontoffice/screens/DataTables/screens/AccountMapping/shared/quickFilterPresets";
import {
  DisplaySinceFilterFieldname,
  OverlapViewName,
} from "screens/Frontoffice/screens/DataTables/screens/AccountMapping/shared/types";
import { FilterType } from "screens/Frontoffice/screens/DataTables/shared/types";

import {
  AccountMappingResource,
  OldRootStateWithAccountMapping,
  RootStateWithAccountMapping,
} from "./types";

const selectCurrentPartnership = (
  state: RootStateWithAccountMapping | OldRootStateWithAccountMapping
) => state.accountMapping.currentPartnership;
const selectViews = (
  state: RootStateWithAccountMapping | OldRootStateWithAccountMapping
) => state.accountMapping.views;

export const selectAccountType = createSelector(
  selectCurrentPartnership,
  selectViews,
  (partnership, views) => {
    if (partnership !== null) {
      return views[partnership.id].accountType;
    }
  }
);

export const selectLastVisitedAt = createSelector(
  selectCurrentPartnership,
  selectViews,
  (partnership, views) => {
    if (partnership !== null) {
      return views[partnership.id].lastVisitedAt;
    }
  }
);

export const selectViewForCurrentPartnership = createSelector(
  selectCurrentPartnership,
  selectViews,
  (partnership, views) => {
    let view = undefined;
    let columns, filters, sort, filterOrderList;
    columns = filters = sort = [];
    if (partnership !== null) {
      view = views[partnership.id];
      const prefix = _.camelCase(views[partnership.id].accountType);
      const persisted = views[partnership.id].persisted;
      if (persisted) {
        columns = _.get(persisted, prefix + "Columns", []);
        filters = _.get(persisted, prefix + "Filters", []);
        sort = _.get(persisted, prefix + "Sort", []);
        filterOrderList = _.get(persisted, "filterOrderList", []);
      }
    }
    return {
      view,
      tableConfig: {
        columns,
        filters,
        sort,
        filterOrderList,
      },
    };
  }
);

export const selectHasOnlyNewUpdatesSince = createSelector(
  selectViewForCurrentPartnership,
  (view) =>
    Boolean(
      view.tableConfig.filters.filter((filter: FilterType) =>
        (Object.values(DisplaySinceFilterFieldname) as string[]).includes(
          filter.fieldname
        )
      ).length > 0
    )
);

export const selectOnlyNewUpdatesSinceDate = createSelector(
  selectViewForCurrentPartnership,
  selectHasOnlyNewUpdatesSince,
  selectLastVisitedAt,
  selectActivePayingFeatures,
  (view, hasOnlyNewUpdatesSince, lastVisitedAt, activePayingFeatures) => {
    const isMatchedAccount = activePayingFeatures.includes(
      PayingFeature.UseNewAccountMapping
    );
    const accountType = view.view?.accountType
      ? isMatchedAccount &&
        view.view?.accountType === AccountMappingResource.matches
        ? AccountMappingResource.matched_accounts
        : view.view?.accountType
      : isMatchedAccount
      ? AccountMappingResource.matched_accounts
      : AccountMappingResource.matches;

    if (
      (accountType !== AccountMappingResource.matches &&
        accountType !== AccountMappingResource.matched_accounts) ||
      !hasOnlyNewUpdatesSince
    ) {
      return null;
    }

    const currentDisplaySinceFilter = getDisplaySinceFilter(
      view.tableConfig.filters
    );
    const date = getSinceDate(lastVisitedAt, currentDisplaySinceFilter.value);
    return date ? new Date(date) : null;
  }
);

const customizerForStringNumber = (objValue: any, otherValue: any) =>
  !isNaN(objValue) && !isNaN(otherValue)
    ? Number(objValue) === Number(otherValue)
    : undefined;

const getOverlapSavedViewName = (
  filters: FilterType[],
  isMatchedAccount: boolean
) =>
  ((isMatchedAccount ? overlapsSavedViews : oldOverlapsSavedViews).find(
    (overlapView) =>
      _.isEqualWith(
        overlapView.filter,
        filters.filter((filter) =>
          overlapView.filter.some(
            (overlapFilter) => overlapFilter.fieldname === filter.fieldname
          )
        ),
        customizerForStringNumber // Avoid falsy return if comparing '1' and 1 for example
      )
  )?.name as OverlapViewName) || OverlapViewName.AllOverlaps;

export const getFilterFieldname = (
  filters: FilterType[],
  isMatchedAccount: boolean
) => {
  const overlapViewName = getOverlapSavedViewName(filters, isMatchedAccount);

  switch (overlapViewName) {
    case OverlapViewName.CommonCustomers:
    case OverlapViewName.CustomerMatchingProspects:
    case OverlapViewName.ProspectMatchingCustomers:
      return DisplaySinceFilterFieldname.StatusChanged;
    case OverlapViewName.CommonOpportunities:
      return DisplaySinceFilterFieldname.LastCreatedOpportunityChanged;
    case OverlapViewName.OpenOpportunitiesToCustomers:
      return DisplaySinceFilterFieldname.MyOpportunityOrPartnerStatusChanged;
    case OverlapViewName.AllOverlaps:
      return DisplaySinceFilterFieldname.CreatedAt;
  }
};

export const selectOnlyNewUpdatesSinceFieldname = createSelector(
  selectViewForCurrentPartnership,
  (view) => {
    const accountType =
      view.view?.accountType ?? AccountMappingResource.matches;

    if (
      accountType !== AccountMappingResource.matches &&
      accountType !== AccountMappingResource.matched_accounts
    ) {
      return null;
    }

    return getFilterFieldname(
      view.tableConfig.filters,
      accountType === AccountMappingResource.matched_accounts
    );
  }
);
