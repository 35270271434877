import { defineMessages, MessageDescriptor } from "react-intl";

/// I18N
const i18n = defineMessages({
  companyName: {
    id: "exportOptions.exportCrmDialog.companyName",
    defaultMessage: "Company Name",
  },
  accountName: {
    id: "exportOptions.exportCrmDialog.accountName",
    defaultMessage: "Account Name",
  },
  domainName: {
    id: "exportOptions.exportCrmDialog.domainName",
    defaultMessage: "Domain Name",
  },
  website: {
    id: "exportOptions.exportCrmDialog.website",
    defaultMessage: "Website",
  },
  country: {
    id: "exportOptions.exportCrmDialog.country",
    defaultMessage: "Country",
  },
  billingCountry: {
    id: "exportOptions.exportCrmDialog.billingCountry",
    defaultMessage: "Billing country",
  },
  revealPartner: {
    id: "exportOptions.exportCrmDialog.revealPartner",
    defaultMessage: "Reveal - {partnerName} customers",
  },
  accountSource: {
    id: "exportOptions.exportCrmDialog.accountSource",
    defaultMessage: "Account Source",
  },
});

export type CrmFieldPreset = {
  revealField: MessageDescriptor;
  name: string;
  crmField: MessageDescriptor;
};

export const defaultFields: CrmFieldPreset[] = [
  {
    revealField: i18n.companyName,
    name: "name",
    crmField: i18n.accountName,
  },
  {
    revealField: i18n.domainName,
    name: "domain",
    crmField: i18n.website,
  },
  {
    revealField: i18n.country,
    name: "country",
    crmField: i18n.billingCountry,
  },
  {
    revealField: i18n.revealPartner,
    name: "partner",
    crmField: i18n.accountSource,
  },
];
