import FiltersBaseItem from "components/ui/filters/FiltersBaseItem";
import { T } from "components/ui/Typography";
import _ from "lodash";
import { makeStyles } from "makeStyles";
import { defineMessages, FormattedMessage, useIntl } from "react-intl";
import {
  FieldType,
  MatchFieldType,
  MatchSort,
  MatchSortOrder,
} from "screens/Frontoffice/screens/DataTables/shared/types";

import OrderToggleButton from "./OrderToggleButton";

const i18n = defineMessages({
  from: {
    id: "crm.AccountMapping.Filter.SortItem.from",
    defaultMessage: "from",
  },
  placeholder: {
    id: "crm.AccountMapping.Filter.SortItem.placeholder",
    defaultMessage: "Select a column",
  },
});

const useStyles = makeStyles()((theme) => ({
  fromLabel: {
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
  },
}));

export const getDefaultSort = (
  fieldname: string,
  fields: { [fieldname: string]: FieldType }
) => {
  return new MatchSort(
    fieldname,
    fields[fieldname].type === MatchFieldType.BOOLEAN
      ? MatchSortOrder.ORDER_DESC
      : MatchSortOrder.ORDER_ASC
  );
};

type SortItemProps = {
  label: string;
  options?: $TSFixMe[];
  index?: number;
  sort?: $TSFixMe;
  fields?: $TSFixMe;
  handleChange: $TSFixMeFunction;
  handleDelete: $TSFixMeFunction;
};

const SortItem = ({
  label,
  options = [],
  index,
  sort = {},
  fields,
  handleChange,
  handleDelete,
}: SortItemProps) => {
  const { classes } = useStyles();
  const intl = useIntl();
  const orderType = sort
    ? _.get(
        options.find((el) => el.value === sort.fieldname),
        "type",
        null
      )
    : null;

  const handleChangeField = (fieldname: $TSFixMe) => {
    if (_.isUndefined(index)) {
      handleChange(getDefaultSort(fieldname, fields));
    } else {
      handleChange(_.merge(_.cloneDeep(sort), { fieldname }), index);
    }
  };

  const toggleOrder = () => {
    const newSort = new MatchSort(sort.fieldname, sort.order);
    newSort.toggleOrder();
    handleChange(newSort, index);
  };

  return (
    <FiltersBaseItem
      label={label}
      options={options}
      fieldname={sort.fieldname}
      placeholder={intl.formatMessage(i18n.placeholder)}
      handleChange={handleChangeField}
      handleDelete={handleDelete}
    >
      {!_.isEmpty(sort) && (
        <>
          <T className={classes.fromLabel}>
            <FormattedMessage {...i18n.from} />
          </T>
          <OrderToggleButton
            order={sort.order}
            type={orderType}
            handleChange={toggleOrder}
            fieldname={sort.fieldname}
          />
        </>
      )}
    </FiltersBaseItem>
  );
};

export default SortItem;
