import { Paper } from "@mui/material";
import { ArrowLeft, ArrowRight } from "components/icons";
import Button from "components/ui/Button";
import { Formik, FormikProps } from "formik";
import { defineMessages } from "react-intl";
import { useHistory, useLocation } from "react-router-dom";
import { updateHistoryUpdatedSource } from "screens/Frontoffice/shared/components/SourceSettingsDialog/helpers/utils";
import { GsheetUrl } from "screens/Frontoffice/shared/helpers/types";
import {
  IGsheetForm,
  TAB_SELECTION_VALIDATION_SCHEMA,
  useGsheetTabSelectionDialogContent,
} from "screens/Frontoffice/shared/hooks/useGsheetTabSelectionDialogContent";

import { BaseContent, sharedStyles } from "./BaseContent";

export const GoogleSheetsTabSelectContent = () => {
  const { classes } = sharedStyles({});
  const location = useLocation();
  const history = useHistory();
  const {
    dialogContent,
    dialogTitle,
    handleSubmit,
  } = useGsheetTabSelectionDialogContent({});

  const handleGoBack = () => {
    history.push({
      ...location,
      hash: GsheetUrl.AuthHash,
    });
  };

  const handleValidate = (props: FormikProps<IGsheetForm>) => () => {
    updateHistoryUpdatedSource(history, location);
    props.handleSubmit();
  };

  return (
    <BaseContent>
      <Formik
        initialValues={{ tab: "", url: "" }}
        onSubmit={handleSubmit}
        validationSchema={TAB_SELECTION_VALIDATION_SCHEMA}
      >
        {(props: FormikProps<IGsheetForm>) => (
          <>
            <div className={classes.marginBottomLarge}>
              <div className={classes.marginBottomMedium}>
                <div className={classes.flexCenter}>{dialogTitle}</div>
              </div>
              <Paper className={classes.formPaper}>
                {dialogContent(props)}
              </Paper>
            </div>
            <div className={classes.action}>
              <Button
                disabled={!(props.isValid && props.dirty)}
                label={i18n.validate}
                RightIcon={ArrowRight}
                onClick={handleValidate(props)}
              />
              <Button
                label={i18n.back}
                LeftIcon={ArrowLeft}
                onClick={handleGoBack}
                variant="tertiary"
              />
            </div>
          </>
        )}
      </Formik>
    </BaseContent>
  );
};

const i18n = defineMessages({
  back: {
    id: "GoogleSheetsTabSelectContent.back",
    defaultMessage: "Back",
  },
  validate: {
    id: "GoogleSheetsTabSelectContent.validate",
    defaultMessage: "Validate",
  },
});
