import { defineMessages, MessageDescriptor } from "react-intl";

import Record from "./Record";
import { Integration_JSONAPIResource } from "./types";

// These numbers come from rvlibs-crms ApiError numbers
export enum API_ERROR {
  API_ERROR_UNKNOWN = 0,
  API_ERROR_API_DISABLED = 1,
  UNAUTHORIZED_CREDENTIALS = 2,
  STRIPE_INTEGRATION_ALREADY_CONNECTED = 5,
}

export class Integration extends Record {
  apiError: API_ERROR | null;
  deletedAt: string | null;
  disabledAt: string | null;
  provider: string;
  lastSyncStartedAt: string | null;
  mappingConfigured: boolean | null;
  name: string | null;

  constructor(data: Integration_JSONAPIResource | Integration) {
    super(data);
    if (this.attributes.api_error === undefined) {
      this.apiError = null;
    } else {
      this.apiError = this.attributes.api_error as number;
    }
    if (typeof this.attributes.provider !== "string") {
      throw new Error("provider must have a value on Integration records");
    }
    this.provider = this.attributes.provider;
    if (typeof this.attributes.disabled_at === "string") {
      this.disabledAt = this.attributes.disabled_at;
    } else {
      this.disabledAt = null;
    }
    if (typeof this.attributes.deleted_at === "string") {
      this.deletedAt = this.attributes.deleted_at;
    } else {
      this.deletedAt = null;
    }
    if (typeof this.attributes.last_sync_started_at === "string") {
      this.lastSyncStartedAt = this.attributes.last_sync_started_at;
    } else {
      this.lastSyncStartedAt = null;
    }
    if (typeof this.attributes.name === "string") {
      this.name = this.attributes.name;
    } else {
      this.name = null;
    }
    this.mappingConfigured = !!(this.attributes?.mapping_configured ?? false);
  }

  getErrorMessage(): MessageDescriptor | null {
    switch (this.apiError) {
      case API_ERROR.API_ERROR_API_DISABLED:
        return i18n.API_ERROR_API_DISABLED;
      case API_ERROR.API_ERROR_UNKNOWN:
        return i18n.API_ERROR_UNKNOWN;
      case API_ERROR.STRIPE_INTEGRATION_ALREADY_CONNECTED:
        return i18n.STRIPE_INTEGRATION_ALREADY_CONNECTED;
      default:
        return null;
    }
  }
}

export function isIntegration(record: Record): record is Integration {
  return record instanceof Integration;
}

export default Integration;

const i18n = defineMessages({
  API_ERROR_API_DISABLED: {
    id: "Integration.API_ERROR_API_DISABLED",
    defaultMessage: "Your Salesforce organization doesn’t have API access.",
  },
  API_ERROR_UNKNOWN: {
    id: "Integration.API_ERROR_UNKNOWN",
    defaultMessage: "There is an issue with your data source.",
  },
  STRIPE_INTEGRATION_ALREADY_CONNECTED: {
    id: "Integration.STRIPE_INTEGRATION_ALREADY_CONNECTED",
    defaultMessage:
      "Stripe Integration already connected to another workspace.",
  },
});
