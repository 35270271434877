import Fade from "@mui/material/Fade";
import Grid from "@mui/material/Grid";
import { T } from "components/ui/Typography";
import { makeStyles } from "makeStyles";
import SuggestedPartner from "models/SuggestedPartner";
import { defineMessages, FormattedMessage } from "react-intl";

import SuggestedPartnerTile from "./SuggestedPartnerTile";

type Props = {
  suggestions: SuggestedPartner[];
};

const SuggestedPartners = ({ suggestions }: Props) => {
  const { classes } = useStyles();
  return (
    <Fade in={true}>
      <Grid item container direction="column">
        <Grid item className={classes.scrollSm}>
          <Grid
            container
            className={classes.container}
            columnGap={1}
            rowGap={1}
          >
            <Grid item>
              <T bold>
                <FormattedMessage {...i18n.suggestedPartners} />
              </T>
            </Grid>
            {suggestions.slice(0, 6).map((suggestion) => (
              <Grid item key={suggestion.id}>
                <Fade
                  in={true}
                  timeout={{
                    enter: 0,
                    exit: 1000,
                  }}
                >
                  <SuggestedPartnerTile suggestedPartner={suggestion} />
                </Fade>
              </Grid>
            ))}
          </Grid>
        </Grid>
      </Grid>
    </Fade>
  );
};

export default SuggestedPartners;

/// CSS

const useStyles = makeStyles()((theme) => ({
  scrollSm: {
    [theme.breakpoints.down("md")]: {
      maxWidth: "100%",
      overflowX: "scroll",
    },
  },
  container: {
    borderRadius: 8,
    border: `1px solid ${theme.palette.gold}`,
    backgroundColor: theme.palette.gold050,
    padding: "8px 8px 8px 12px",
    justifyContent: "flex-start",
    alignItems: "center",
    [theme.breakpoints.down("md")]: {
      minWidth: 1200,
    },
  },
}));

/// I18N

const i18n = defineMessages({
  suggestedPartners: {
    id: "Partners.SuggestedPartners",
    defaultMessage: "Suggested Partners",
  },
});
