import { createAction } from "@reduxjs/toolkit";

import { AccountMappingActions, AccountMappingResource } from "./types";

export const setAccountType = createAction<AccountMappingResource>(
  AccountMappingActions.setAccountType
);

export const setSelectedSourceId = createAction<number | null>(
  AccountMappingActions.setSelectedSourceId
);

export const setLastVisitedAt = createAction<Date | null>(
  AccountMappingActions.setLastVisitedAt
);
