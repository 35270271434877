import { defineMessages } from "react-intl";
import {
  AdditionalAnalyticsColumns,
  AvailableAnalytisColumns,
} from "redux/analytics/defaults";
import {
  ColumnConfigType,
  FieldType,
  FilterType,
  MatchFieldType,
  MatchFilterType,
  SortType,
} from "screens/Frontoffice/screens/DataTables/shared/types";

const ROUNDED_FIELD_FILTERS: string[] = [
  MatchFilterType.GT,
  MatchFilterType.LT,
  MatchFilterType.GTE,
  MatchFilterType.LTE,
];

export const fields: { [key: string]: FieldType } = {
  [AvailableAnalytisColumns.favorite]: {
    label: "",
    type: MatchFieldType.TEXT,
    options: {},
    smartField: true,
    fullyImported: true,
    isDisabled: false,
    noSide: true,
    isPrivate: false,
    partnerField: false,
    sort: false,
    filter: false,
    column: true,
  },
  [AvailableAnalytisColumns.partnerCompanyName]: {
    label: "Partner Name",
    type: MatchFieldType.TEXT,
    options: {},
    smartField: true,
    fullyImported: true,
    isDisabled: false,
    noSide: true,
    isPrivate: false,
    partnerField: false,
    sort: true,
    filter: true,
    column: true,
  },
  [AvailableAnalytisColumns.overlaps]: {
    label: "Overlaps",
    type: MatchFieldType.INTEGER,
    options: {},
    smartField: true,
    fullyImported: true,
    isDisabled: false,
    noSide: true,
    isPrivate: false,
    partnerField: false,
    sort: true,
    filter: true,
    column: true,
  },
  [AvailableAnalytisColumns.newProspectsFromPartner]: {
    label: "New Prospects from Partners",
    type: MatchFieldType.INTEGER,
    options: {},
    smartField: true,
    fullyImported: true,
    isDisabled: false,
    noSide: true,
    isPrivate: false,
    partnerField: false,
    sort: true,
    filter: true,
    column: true,
  },
  [AvailableAnalytisColumns.marketOverlap]: {
    label: "Market Overlap",
    type: MatchFieldType.INTEGER,
    options: {},
    smartField: true,
    fullyImported: true,
    isDisabled: false,
    noSide: true,
    isPrivate: false,
    partnerField: false,
    sort: true,
    filter: true,
    column: true,
    allowedOperations: ROUNDED_FIELD_FILTERS,
  },
  [AvailableAnalytisColumns.personaOverlap]: {
    label: "Persona Overlap",
    type: MatchFieldType.INTEGER,
    options: {},
    smartField: true,
    fullyImported: true,
    isDisabled: false,
    noSide: true,
    isPrivate: false,
    partnerField: false,
    sort: true,
    filter: true,
    column: true,
    allowedOperations: ROUNDED_FIELD_FILTERS,
  },
  [AvailableAnalytisColumns.ownerCompany]: {
    label: "Partner Owner at Your Company",
    type: MatchFieldType.USER,
    options: {},
    smartField: true,
    fullyImported: true,
    isDisabled: false,
    noSide: true,
    isPrivate: false,
    partnerField: false,
    sort: true,
    filter: true,
    column: true,
  },
  [AvailableAnalytisColumns.ownerPartner]: {
    label: "Partner Owner at Your Partner",
    type: MatchFieldType.USER,
    options: {},
    smartField: true,
    fullyImported: true,
    isDisabled: false,
    noSide: true,
    isPrivate: false,
    partnerField: false,
    sort: true,
    filter: true,
    column: true,
  },
  [AvailableAnalytisColumns.tags]: {
    label: "Tags",
    type: MatchFieldType.TEXT,
    options: {},
    smartField: true,
    fullyImported: true,
    isDisabled: false,
    noSide: true,
    isPrivate: false,
    partnerField: false,
    sort: false,
    filter: false,
    column: true,
  },
  [AvailableAnalytisColumns.commonCustomers]: {
    label: "Common Customers",
    type: MatchFieldType.INTEGER,
    options: {},
    smartField: true,
    fullyImported: true,
    isDisabled: false,
    noSide: true,
    isPrivate: false,
    partnerField: false,
    sort: true,
    filter: true,
    column: true,
  },
  [AvailableAnalytisColumns.commonOpportunities]: {
    label: "Common Opportunities",
    type: MatchFieldType.INTEGER,
    options: {},
    smartField: true,
    fullyImported: true,
    isDisabled: false,
    noSide: true,
    isPrivate: false,
    partnerField: false,
    sort: true,
    filter: true,
    column: true,
  },
  [AvailableAnalytisColumns.opportunitiesPartnerCustomers]: {
    label: "Opportunities among Partner's Customers",
    type: MatchFieldType.INTEGER,
    options: {},
    smartField: true,
    fullyImported: true,
    isDisabled: false,
    noSide: true,
    isPrivate: false,
    partnerField: false,
    sort: true,
    filter: true,
    column: true,
  },
  [AdditionalAnalyticsColumns.opportunitiesPartnerCustomersRevenue]: {
    label: "Opportunities among Partner's Customers Revenue",
    type: MatchFieldType.NUMBER,
    options: {},
    smartField: true,
    fullyImported: true,
    isDisabled: false,
    noSide: true,
    isPrivate: false,
    partnerField: false,
    sort: true,
    filter: true,
    column: true,
  },
  [AvailableAnalytisColumns.myProspectsPartnerCustomers]: {
    label: "My Prospects (no open deal) vs Partner's Customers",
    type: MatchFieldType.INTEGER,
    options: {},
    smartField: true,
    fullyImported: true,
    isDisabled: false,
    noSide: true,
    isPrivate: false,
    partnerField: false,
    sort: true,
    filter: true,
    column: true,
  },
  [AdditionalAnalyticsColumns.myProspectsPartnerCustomersRevenue]: {
    label: "My Prospects (no open deal) vs Partner's Customers Revenue",
    type: MatchFieldType.NUMBER,
    options: {},
    smartField: true,
    fullyImported: true,
    isDisabled: false,
    noSide: true,
    isPrivate: false,
    partnerField: false,
    sort: true,
    filter: true,
    column: true,
  },
  [AvailableAnalytisColumns.myCustomersPartnerProspects]: {
    label: "My Customers vs Partner's Prospects",
    type: MatchFieldType.INTEGER,
    options: {},
    smartField: true,
    fullyImported: true,
    isDisabled: false,
    noSide: true,
    isPrivate: false,
    partnerField: false,
    sort: true,
    filter: true,
    column: true,
  },
  [AvailableAnalytisColumns.newProspectsForPartner]: {
    label: "New Prospects for Partners",
    type: MatchFieldType.INTEGER,
    options: {},
    smartField: true,
    fullyImported: true,
    isDisabled: false,
    noSide: true,
    isPrivate: false,
    partnerField: false,
    sort: true,
    filter: true,
    column: true,
  },
  [AdditionalAnalyticsColumns.newProspectsFromPartnerRevenue]: {
    label: "New Prospects From Partners Revenue",
    type: MatchFieldType.NUMBER,
    options: {},
    smartField: true,
    fullyImported: true,
    isDisabled: false,
    noSide: true,
    isPrivate: false,
    partnerField: false,
    sort: true,
    filter: true,
    column: true,
  },
  [AvailableAnalytisColumns.winRate]: {
    label: "Win Rate Boost",
    type: MatchFieldType.INTEGER,
    options: {},
    smartField: true,
    fullyImported: true,
    isDisabled: false,
    noSide: true,
    isPrivate: false,
    partnerField: false,
    sort: true,
    filter: true,
    column: true,
    allowedOperations: ROUNDED_FIELD_FILTERS,
  },
  [AvailableAnalytisColumns.dealSize]: {
    label: "Deal Size Boost",
    type: MatchFieldType.INTEGER,
    options: {},
    smartField: true,
    fullyImported: true,
    isDisabled: false,
    noSide: true,
    isPrivate: false,
    partnerField: false,
    sort: true,
    filter: true,
    column: true,
    allowedOperations: ROUNDED_FIELD_FILTERS,
  },
  [AvailableAnalytisColumns.revenuePotential]: {
    label: "Revenue Potential",
    type: MatchFieldType.CURRENCY,
    options: {},
    smartField: true,
    fullyImported: true,
    isDisabled: false,
    noSide: true,
    isPrivate: false,
    partnerField: false,
    sort: true,
    filter: true,
    column: true,
  },
};

export type AnalyticsViewParameters = {
  filters?: FilterType[];
  columns?: ColumnConfigType[];
  sort?: SortType[];
  lastVisitedAt?: Date;
};

// I18N

export const i18n = defineMessages({
  winRateTooltip: {
    id: "dataTables.Analytics.winRateTooltip",
    defaultMessage:
      "Your new business win rate on {partnerName}'s customers is {value}% {isNegative, select, true {lower than} other {higher than}} your average win rate.",
  },
  dealSizeTooltip: {
    id: "dataTables.Analytics.dealSizeTooltip",
    defaultMessage:
      "Your new business deals are on avg. +{value}% bigger when the account is already a customer of partner {partnerName}.",
  },
  revenuePotentialTooltip: {
    id: "dataTables.Analytics.revenuePotentialTooltip",
    defaultMessage:
      "Revenue Potential = # of opportunities that can be influenced or sourced from {partnerName}'s customers * win rate * deal size.",
  },
  winRateHeaderTooltip: {
    id: "dataTables.Analytics.winRateHeaderTooltip",
    defaultMessage:
      "Measures the increase in your average win rate for new business with each partner's customers.",
  },
  dealSizeHeaderTooltip: {
    id: "dataTables.Analytics.dealSizeHeaderTooltip",
    defaultMessage:
      "Measures the increase in your average deal size for new business with each partner's customers.",
  },
  revenuePotentialHeaderTooltip: {
    id: "dataTables.Analytics.revenuePotentialHeaderTooltip",
    defaultMessage:
      "Revenue Potential = # of opportunities that can be influenced or sourced from partner's customers * win rate * deal size.",
  },
});
