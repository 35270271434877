import { createSvgIcon } from "@mui/material/utils";

import { SvgIcon } from "./types";

const displayName = "Notification";

const Notification: SvgIcon = createSvgIcon(
  <g fill="none" transform="matrix(1.5,0,0,1.5,0,0)">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10.4 12.7891H13.1515C13.6201 12.7891 14 12.3507 14 11.81C14 11.5503 13.9106 11.3013 13.7515 11.1177L13.1515 10.4254C12.9264 10.1657 12.8 9.81355 12.8 9.44632L12.8 7.23031C12.8 4.18272 10.6588 1.71216 8.01759 1.71216C5.37073 1.71216 3.22726 4.19283 3.2352 7.24688L3.24088 9.42974C3.24186 9.80674 3.10957 10.1679 2.87456 10.4298L2.25718 11.1179C2.09282 11.301 2 11.5534 2 11.817C2 12.3539 2.37718 12.7891 2.84245 12.7891H5.6C5.6 14.3185 6.67452 15.2122 8 15.2122C9.32548 15.2122 10.4 14.3185 10.4 12.7891ZM12.5151 11.1597L13.0272 11.7506H2.98488L3.4998 11.1768C3.91108 10.7184 4.1426 10.0864 4.14088 9.42662L4.1352 7.24376C4.12875 4.76445 5.86884 2.75062 8.01759 2.75062C10.1618 2.75062 11.9 4.75625 11.9 7.23031L11.9 9.44633C11.9 10.089 12.1212 10.7053 12.5151 11.1597ZM9.5 12.7891H6.5C6.5 13.745 7.17157 14.3122 8 14.3122C8.82843 14.3122 9.5 13.745 9.5 12.7891Z"
      fill="currentColor"
    />
  </g>,
  displayName
);

Notification.displayName = displayName;
export default Notification;
