import history from "_history";
import { parse, stringify } from "query-string";
import { useCallback, useEffect } from "react";

import useQuery from "./useQuery";

const useURIQuery = (defaultFilters: $TSFixMe, staticFilters = {}) => {
  const search = parse(window.location.search);
  const allowedURIParams = Object.keys(defaultFilters);
  const staticParams = Object.keys(staticFilters);

  const urlParams = useCallback(
    (query) =>
      Object.keys(query)
        .filter((param) => !staticParams.includes(param))
        .filter((param) => allowedURIParams.includes(param)),
    [allowedURIParams, staticParams]
  );

  const initParams = { ...staticFilters, ...defaultFilters };
  urlParams(search)
    .filter((param) => search[param] !== defaultFilters[param])
    .forEach((param) => (initParams[param] = search[param]));

  const [query, updateQuery, setQuery] = useQuery(initParams);

  useEffect(() => {
    const currentSearch = parse(window.location.search);
    urlParams(query).forEach((param) => {
      if (query[param] === defaultFilters[param]) {
        if (currentSearch[param]) {
          delete currentSearch[param];
        }
      } else {
        currentSearch[param] = query[param];
      }
    });
    history.replace({
      pathname: window.location.pathname,
      search: stringify(currentSearch),
      hash: window.location.hash,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(defaultFilters), JSON.stringify(query)]);

  return [
    query,
    updateQuery,
    (newQuery: $TSFixMe) =>
      setQuery({ ...staticFilters, ...defaultFilters, ...newQuery }),
  ];
};

export default useURIQuery;
