export const included = [
  "account_mapping_settings",
  "dest_company",
  "initiator_company",
  "partnership_owner",
  "initiator_user",
  "insights",
  "integrations",
  "invites",
  "partnership_overview_view",
  "partnership_integrations_settings",
  "sharing_settings",
  "tags",
  "watchers",
  "initiator_partner_stack_partnership",
  "dest_partner_stack_partnership",
  "partner_partnership_owner",
];

export const fields = {
  partnerships: [
    ...included,
    "capabilities",
    "dest_mapping_rules_progress",
    "dest_mapping_rules_set",
    "has_partner_integration",
    "init_mapping_rules_progress",
    "init_mapping_rules_set",
    "is_new_leads_enabled",
    "partner_has_disabled_integration",
    "paused_by_dest",
    "paused_by_init",
    "ready",
    "requested_company_name",
    "requested_company_domain",
    "status",
    "source",
    "watcher_ids",
    "partner_has_unlimited_pipeline_sharing",
    "is_initiator_partner_stack_partnership_eligible",
    "is_dest_partner_stack_partnership_eligible",
    "asymmetrical_privacy",
  ],
};
