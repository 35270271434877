import "./wdyr";
import "bootstrap/dist/css/bootstrap.css";
import "@fortawesome/fontawesome-free/css/all.min.css";
import "./store"; // Imported here for its side-effects.

import { LicenseInfo } from "@mui/x-license-pro";
// eslint-disable-next-line
import _ from "lodash";
import { render } from "react-dom";
import { Provider } from "react-redux";
import {
  MatchFilter,
  MatchSort,
} from "screens/Frontoffice/screens/DataTables/shared/types";

import App from "./App";
import { store } from "./store";

if (process.env.REACT_APP_MUI_X_PREMIUM) {
  LicenseInfo.setLicenseKey(process.env.REACT_APP_MUI_X_PREMIUM);
}

require("typeface-open-sans");

_.mixin({
  sortMatches: MatchSort.applyAll,
  filterMatches: MatchFilter.applyAll,
});

render(
  <Provider store={store}>
    <App />
  </Provider>,
  document.getElementById("root")
);
