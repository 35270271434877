import Grid from "@mui/material/Grid";
import withAPI, { WithAPI } from "components/hoc/withAPI";
import Button from "components/ui/Button";
import InfiniteTable from "components/ui/list-table/InfiniteTable";
import { T } from "components/ui/Typography";
import useDefaultDisplayIndex from "hooks/useDefaultDisplayIndex";
import usePushNotification from "hooks/usePushNotification";
import useSegment from "hooks/useSegment";
import generic from "i18n/generic";
import _ from "lodash";
import { makeStyles } from "makeStyles";
import { SwRecordType } from "models/CrmField";
import { RefObject, useEffect, useState } from "react";
import { defineMessages, FormattedMessage } from "react-intl";
import { useHistory, useLocation } from "react-router-dom";
import {
  useTableFiltersStyles,
  useTableHeadStyles,
  useTableStyles,
} from "styles/partners";

import { updateHistoryUpdatedSource } from "../../../helpers/utils";
import FieldSettingsRow from "./FieldSettingsRow";

const i18n = defineMessages({
  summary: {
    id: "crm.Settings.lists.crmFieldSettings.title",
    defaultMessage:
      "Select {provider} fields that you want to display, use as filters, or share with Partners. You will be able to configure what you display and what you share.",
  },
  saveBtn: {
    id: "crm.settings.crmFieldSettings.saveBtn",
    defaultMessage: "SAVE",
  },
  cancelBtn: {
    id: "crm.settings.crmFieldSettings.cancelBtn",
    defaultMessage: "CANCEL",
  },
  skipBtn: {
    id: "crm.settings.crmFieldSettings.skipBtn",
    defaultMessage: "SKIP FOR NOW",
  },
  search: {
    id: "crm.settings.crmFieldSettings.search",
    defaultMessage: "Search field",
  },
  crm_label: {
    id: "crm.settings.crmFieldSettings.crm_label",
    defaultMessage: "Fields",
  },
  crm_object_type: {
    id: "crm.settings.crmFieldSettings.crm_object_type",
    defaultMessage: "Object",
  },
  import_field: {
    id: "crm.settings.crmFieldSettings.import_field",
    defaultMessage: "Import",
  },
});

const useStyles = makeStyles()((theme) => ({
  root: {
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(4),
    [theme.breakpoints.down("md")]: {
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
    },
    [theme.breakpoints.down("sm")]: {
      paddingLeft: theme.spacing(0),
      paddingRight: theme.spacing(0),
    },
  },
  summary: {
    [theme.breakpoints.down("md")]: {
      // TODO: Typography -- deal with different font styles in smaller screens.
      // To help find this later (cmd+f hook): oldVariant="body1_fontSize:14px".
      fontSize: 14,
    },
    [theme.breakpoints.down("sm")]: {
      marginTop: theme.spacing(2),
    },
  },
  content: {
    width: "100%",
  },
  actionsContainer: {
    position: "absolute",
    bottom: 0,
    right: 0,
    padding: theme.spacing(2),
    backgroundColor: theme.palette.offWhite,
    // @ts-expect-error ts-migrate(2339) FIXME: Property 'light' does not exist on type 'Color'.
    borderTop: `1px solid ${theme.palette.grey.light}`,
    zIndex: 1,
  },
}));
const useSaveBtnStyles = makeStyles()((theme) => ({
  btn: {
    marginLeft: theme.spacing(2),
  },
}));

const useCustomClasses = makeStyles()((theme) => ({
  root: {
    backgroundColor: theme.palette.ivory,
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(10),
    [theme.breakpoints.down("md")]: {
      padding: theme.spacing(3),
    },
  },
}));

const useCustomHeadClasses = makeStyles()((theme) => ({
  tableRow: {
    "&> th": {
      "&:first-child": {
        width: "60%",
      },
    },
  },
}));

const tableFilters = [
  {
    id: "search",
    message: i18n.search,
    type: "text",
  },
];

const columns = [
  {
    id: "crm_label",
    message: i18n.crm_label,
  },
  {
    id: "crm_object_type",
    message: i18n.crm_object_type,
  },
  {
    id: "import_field",
    message: i18n.import_field,
  },
];

type Props = WithAPI & {
  integrationId: number;
  handleClose?: () => void;
  scrollerRef?: RefObject<HTMLElement>;
  provider: string;
  callback?: () => void;
};

const CrmFieldSettingsWidget = ({
  integrationId,
  handleClose,
  scrollerRef,
  provider,
  callback,
  rawPost,
}: Props) => {
  const { classes } = useStyles();
  const { classes: saveBtnClasses } = useSaveBtnStyles();
  const pushNotification = usePushNotification();
  const { track } = useSegment();
  const history = useHistory();
  const location = useLocation();
  const [updatedFields, setUpdatedFields] = useState({});
  const { classes: tableClasses } = useTableStyles();
  const { classes: customClasses } = useCustomClasses();
  const { classes: tableFiltersClasses } = useTableFiltersStyles();
  const { classes: tableHeadClasses } = useTableHeadStyles();
  const { classes: customHeadClasses } = useCustomHeadClasses();
  const [saving, setSaving] = useState(false);
  const handleSave = async () => {
    try {
      setSaving(true);
      await rawPost(
        "integrations",
        integrationId,
        "/crm-fields/",
        {
          data: _.map(updatedFields, (value: $TSFixMe, key: $TSFixMe) => {
            return { type: "crm_fields", id: key, attributes: value };
          }),
        },
        {
          config: {
            headers: {
              "Content-Type": "application/json",
            },
          },
        }
      ).then(() => {
        pushNotification({ ...generic.edits_saved });
        track("Edited data source custom fields");
        setUpdatedFields({});
        updateHistoryUpdatedSource(history, location);
      });
    } catch (_error) {
      pushNotification("default_error");
    } finally {
      setSaving(false);
      if (callback) {
        callback();
      }
    }
  };

  const handleUpdateField = (
    id: $TSFixMe,
    attribute: $TSFixMe,
    value: $TSFixMe
  ) => {
    setUpdatedFields({
      ...updatedFields,
      [id]: {
        // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
        ...(updatedFields[id] || {}),
        [attribute]: value,
      },
    });
  };

  const {
    fieldIds: defaultFieldIds,
    loading: loadingDefaultFields,
  } = useDefaultDisplayIndex(integrationId, SwRecordType.RawOpportunity);

  useEffect(() => {
    track("Viewed data source custom fields");
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Grid container className={classes.root}>
      <T className={classes.summary}>
        <FormattedMessage {...i18n.summary} values={{ provider }} />
      </T>
      <Grid className={classes.content}>
        <InfiniteTable
          Component={FieldSettingsRow}
          columns={columns}
          defaultFilters={{
            search: "",
            integration: integrationId,
            importable: true,
            syncable: true,
          }}
          defaultSort={{ by: "sw_record_type,crm_label", order: "asc" }}
          filters={tableFilters}
          resource="crm_fields"
          classes={{ ...tableClasses, ...customClasses }}
          tableFiltersClasses={tableFiltersClasses}
          tableHeadClasses={{ ...tableHeadClasses, ...customHeadClasses }}
          rowProps={{
            handleUpdateField,
            defaultFieldIds,
            loadingDefaultFields,
          }}
          scrollerRef={scrollerRef}
          threshold={-200}
        />
      </Grid>
      {handleClose && (
        <Grid
          container
          direction="row"
          justifyContent="flex-end"
          alignItems="center"
          className={classes.actionsContainer}
        >
          <Button
            label={callback ? i18n.skipBtn : i18n.cancelBtn}
            onClick={callback ? callback : handleClose}
            variant="secondary"
          />
          <Button
            label={i18n.saveBtn}
            onClick={handleSave}
            classes={saveBtnClasses}
            loading={saving}
          />
        </Grid>
      )}
    </Grid>
  );
};

export default withAPI(CrmFieldSettingsWidget);
