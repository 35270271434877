import { createSelector } from "@reduxjs/toolkit";
import _ from "lodash";
import CompanyPayingFeatureSubscription, {
  FeatureStatus,
  PayingFeature,
} from "models/CompanyPayingFeatureSubscription";
import Integration from "models/Integration";
import Partnership from "models/Partnership";
import User from "models/User";
import { RevealStore } from "redux/typing";

import { APICollection, RootStateWithAPI } from "./typing";

// We must keep the same reference to the empty object
// otherwise we may accidentally trigger effects derived from
// this selector's return value.
const emptyPartnershipCrmFields = {};

export const selectEntities = (state: RootStateWithAPI) => state.api.entities;

export const selectPartnershipCrmFields = (state: RootStateWithAPI) =>
  state.api.entities.partnership_crm_fields || emptyPartnershipCrmFields;

export const selectPayingFeaturesFunc = (
  user: User,
  companyPayingFeatureSubscriptions: APICollection<CompanyPayingFeatureSubscription>,
  companyPayingFeatureSubscriptionIds: number[]
) => {
  // List of features included in company plan
  const featureGroupFeatures = user?.company?.featureGroupFeatures ?? [];

  let allFeatureData: { [key: string]: { status: FeatureStatus } } = {};

  _.each(featureGroupFeatures, (featureKey: string) => {
    allFeatureData[featureKey] = {
      status: FeatureStatus.unlimited,
    };
  });

  const allSubscriptions = companyPayingFeatureSubscriptionIds
    .map((id: number) => companyPayingFeatureSubscriptions[id])
    .filter(
      (subscription: CompanyPayingFeatureSubscription) =>
        !featureGroupFeatures.includes(subscription.payingFeature)
    )
    .filter(Boolean) as CompanyPayingFeatureSubscription[];

  _.each(allSubscriptions, (subscription: CompanyPayingFeatureSubscription) => {
    allFeatureData[subscription.payingFeature] = {
      status: subscription.status as FeatureStatus,
    };
  });

  return allFeatureData;
};

export const selectActivePayingFeaturesFunc = (featureList: {
  [key: string]: {
    status: FeatureStatus;
  };
}) =>
  Object.keys(featureList).filter((featureKey) =>
    [FeatureStatus.unlimited, FeatureStatus.trial].includes(
      featureList[featureKey].status
    )
  ) as PayingFeature[];

export const selectPayingFeatures = createSelector(
  (state: RevealStore) => state.user.profile.data,
  (state: RevealStore) =>
    state.api.entities["company_paying_feature_subscriptions"] ?? {},
  (state: RevealStore) => state.context.companyPayingFeatureSubscriptionIds,
  selectPayingFeaturesFunc
);

export const selectActivePayingFeatures = createSelector(
  selectPayingFeatures,
  selectActivePayingFeaturesFunc
);

export const selectAllIntegrations = (state: RevealStore) =>
  Object.values(state.api.entities["integrations"] ?? {}) as Integration[];

export const selectAllPartnerships = (state: RevealStore) =>
  Object.values(state.api.entities["partnerships"] ?? {}) as Partnership[];

export const selectAllCompanies = (state: RevealStore) =>
  Object.values(state.api.entities["companies"] ?? {});

export const selectAllCompanyAsItems = createSelector(
  selectAllCompanies,
  (companies) =>
    companies.map((record) => ({
      id: record.id,
      label: record.name,
    }))
);

export const selectCompanyIdFromPartnerships = createSelector(
  (state: RevealStore) => state.api.entities["partnerships"] ?? {},
  (state: RevealStore) => state.user.profile.data,
  (partnerships, user) => {
    return {
      companyIds: Object.values(partnerships)
        .map((item) => _.get(item.getPartner(user), "id", null))
        .filter(Boolean),
    };
  }
);
