import SelectedRowsContext from "components/ui/data-grid/SelectedRowsContext";
import { getLabel, getLogoSrc } from "components/ui/Integration";
import useAllRecords from "hooks/useAllRecords";
import Integration from "models/Integration";
import { useContext } from "react";
import { defineMessages, useIntl } from "react-intl";
import { useParams } from "react-router-dom";

import SettingsSelect from "./SettingsSelect";

type SelectSourceButtonProps = {
  availableSources: Integration[];
  selectedSourceId: number | null;
  setSelectedSourceId: (value: number | null) => void;
};

const SelectSourceButton = ({
  selectedSourceId,
  setSelectedSourceId,
  availableSources,
}: SelectSourceButtonProps) => {
  const intl = useIntl();
  const { recordId } = useParams<{ recordId: string }>();
  const { clearSelection } = useContext(SelectedRowsContext);

  const { records: ghostIntegrations } = useAllRecords(
    "ghost_integrations",
    {
      filters: { ghost_partnership_id: recordId },
      include: ["partnership_integrations"],
      fields: {
        integrations: [
          "company",
          "mapping_configured",
          "name",
          "provider",
          "default_source",
          "partnership_integrations",
          "api_error",
        ],
      },
    },
    () => undefined,
    [],
    undefined,
    "integrations"
  );
  const ghostDataSourceIds = ghostIntegrations.map(
    (integration) => integration.id
  );

  if (availableSources === undefined || availableSources.length < 2) {
    return null;
  }

  const handleSelectSource = (data: { value: string | null }) => {
    setSelectedSourceId(data.value === null ? null : +data.value);
    clearSelection();
  };

  const sourcesOptions = [
    {
      value: null,
      label: intl.formatMessage(i18n.allSources),
      icon: "storage",
    },
    ...availableSources
      .filter((source) => !ghostDataSourceIds.includes(source.id))
      .map((integration) => ({
        value: String(integration.id),
        label: getLabel(integration),
        logo: getLogoSrc(integration),
      })),
  ];

  return (
    <SettingsSelect<string>
      options={sourcesOptions}
      value={selectedSourceId ? String(selectedSourceId) : null}
      onChange={handleSelectSource}
      variant="quinary"
      size="small"
    />
  );
};

export default SelectSourceButton;

/// I18N

const i18n = defineMessages({
  allSources: {
    id: "crm.SelectSourceBtn.allSources",
    defaultMessage: "All sources",
  },
});
