import { ChevronDown, Paid } from "components/icons";
import Button from "components/ui/Button";
import DropdownPopover from "components/ui/DropdownPopover";
import useSegment from "hooks/useSegment";
import { makeStyles } from "makeStyles";
import { useCallback, useEffect, useRef, useState } from "react";
import { defineMessages, FormattedMessage } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router";
import { initWidget, openWidget } from "redux/premiumPlanWidget/actions";
import { selectPremiumPlanWidgetState } from "redux/premiumPlanWidget/selectors";
import { PremiumPlanEvent } from "tracking";

import { PremiumPlanContent } from "./PremiumPlanContent";
import UpdatePlanDialog from "./UpdatePlanDialog";

type Props = {
  collapsed?: boolean;
};

const PremiumPlanWidget = ({ collapsed = false }: Props) => {
  const location = useLocation();
  const { track } = useSegment();
  const { classes } = useStyles();
  const buttonRef = useRef(null);
  const dispatch = useDispatch();
  const { isPremium, opened } = useSelector(selectPremiumPlanWidgetState);
  const [dialogOpen, setDialogOpen] = useState(false);

  const setOpenWidget = useCallback(
    (status: boolean, location?) => {
      dispatch(openWidget(status));
      if (status)
        track(PremiumPlanEvent.upgradeBtn, {
          page: location.pathname,
        });
    },
    [dispatch, track]
  );

  const handleOpenDialog = () => {
    setOpenWidget(false);
    setDialogOpen(true);
    track(PremiumPlanEvent.scaleBtn, { page: location.pathname });
  };

  useEffect(() => {
    dispatch(initWidget());
  }, [dispatch]);

  return (
    <div ref={buttonRef}>
      <Button
        label={
          !collapsed ? (
            <FormattedMessage
              {...(isPremium ? i18n.paidPlan : i18n.upgradePlan)}
            />
          ) : (
            ""
          )
        }
        onClick={() => setOpenWidget(true, location)}
        variant="secondary"
        LeftIcon={Paid}
        RightIcon={!collapsed ? ChevronDown : undefined}
        data-testid="premium-plan-widget"
        size="small"
      />
      <DropdownPopover
        anchorEl={buttonRef.current}
        onClose={() => setOpenWidget(false)}
        open={opened}
        position="right"
        classes={{ paper: classes.dropdownContent }}
      >
        <PremiumPlanContent />
        {!isPremium && (
          <Button
            label={<FormattedMessage {...i18n.upgradePlan} />}
            onClick={() => handleOpenDialog()}
            LeftIcon={Paid}
            classes={{ btn: classes.upgradeBtn }}
          />
        )}
      </DropdownPopover>
      <UpdatePlanDialog
        isOpen={dialogOpen}
        onClose={() => setDialogOpen(false)}
      />
    </div>
  );
};

export default PremiumPlanWidget;

/// CSS

export const useStyles = makeStyles()((theme) => ({
  dropdownContent: {
    background: theme.palette.midnight,
    padding: theme.spacing(1),
    width: 320,
    rowGap: theme.spacing(1),
  },
  upgradeBtn: {
    display: "flex",
    width: "100%",
  },
}));

/// I18N

const i18n = defineMessages({
  paidPlan: {
    id: "navbar.PremiumPlanWidget.PremiumPlanWidget.paidPlan",
    defaultMessage: "Premium",
  },
  upgradePlan: {
    id: "navbar.PremiumPlanWidget.PremiumPlanWidget.upgradePlan",
    defaultMessage: "Upgrade",
  },
});
