import { ProviderType } from "config/constants";
import _ from "lodash";

import Record from "./Record";
import { JSONAPIResourceOrRecord, JSONSerializable } from "./types";

export default class CrmCredential extends Record<"crm_credentials"> {
  provider: string;
  disabled: boolean;
  integrationId: number | null = null;
  config: { [key: string]: JSONSerializable };

  constructor(resource: JSONAPIResourceOrRecord<"crm_credentials">) {
    super(resource);
    if (typeof this.attributes.provider !== "string") {
      throw new TypeError(
        `CrmCredential expects a string for provider (got ${this.attributes.provider})`
      );
    }
    this.provider = this.attributes.provider;

    this.disabled = Boolean(this.attributes.disabled);
    if (!_.isObject(this.attributes.config)) {
      this.config = {};
    } else {
      this.config = this.attributes.config as {
        [key: string]: JSONSerializable;
      };
    }
    if (
      this.attributes.integration_id &&
      !isNaN(+this.attributes.integration_id)
    ) {
      this.integrationId = +this.attributes.integration_id;
    }
  }

  hasEmptyConfig(): boolean {
    if (this.provider === ProviderType.gsheet) {
      return !this.config.gsheet_id_and_tab;
    }
    return false;
  }
}
