import { ReactNode } from "react";

export enum PartnershipChannelType {
  Internal = "1",
  Shared = "2",
}

export enum PartnershipChannelMode {
  Off = "0",
  Default = "1",
  Custom = "2",
}

export type PartnershipChannel = {
  id: number;
  mode: PartnershipChannelMode;
  slackId: string;
};

export type ChannelDisabledSavingStatusProps = {
  internal: boolean;
  shared: boolean;
};

export type IntroNotificationSettingsInitial = {
  owner_crm_field_names: [];
  should_email_account_owner: boolean;
  should_email_partnership_manager: boolean;
} | null;

export type StakeholderRow = {
  label: ReactNode;
  isChecked: boolean;
  isDisabled?: boolean;
  logo?: string;
  tooltip?: ReactNode;
  onChange?: (_: any, checked: boolean) => void;
};
