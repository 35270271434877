import { isProviderType, ProviderType } from "config/constants";
import useUserProfile from "hooks/useUserProfile";
import _ from "lodash";
import { JSONAPIResource } from "models/types";
import { useCallback, useEffect, useMemo, useState } from "react";
import GlobalSearchService, {
  SearchResult,
} from "services/GlobalSearchService";
import JSONAPIService from "services/JSONAPIService";

/* Functions */

const DEBOUNCE_MS = 250;

const fetchIntegrations = async (): Promise<JSONAPIResource[]> => {
  const service = new JSONAPIService("integrations");
  try {
    const response = await service.index({
      fields: { integrations: ["id", "provider"] },
    });
    return response.data.data;
  } catch {
    return [];
  }
};

const buildMapping = (
  integrations: JSONAPIResource[]
): Map<number, ProviderType> => {
  const mapping = new Map<number, ProviderType>();
  integrations.forEach((integration: JSONAPIResource) => {
    const provider = integration.attributes?.provider;
    if (isProviderType(provider)) {
      mapping.set(+integration.id, provider);
    }
  });
  return mapping;
};

const fetchResults = _.debounce(
  (
    input: string,
    onStart: () => void,
    onFinish: () => void,
    callback: (searchResult: SearchResult) => void
  ) => {
    if (input !== "") {
      onStart();
      const searchService = new GlobalSearchService();
      searchService
        .q(input)
        .then(callback)
        .catch((error) => {
          console.warn("Error in Global Search:", error);
        })
        .finally(onFinish);
    }
  },
  DEBOUNCE_MS
);

/* Hook */
export const useAPI = (query: string) => {
  const { profile } = useUserProfile();
  const [integrationProviderMap, setIntegrationProviderMap] = useState<
    Map<number, ProviderType>
  >(new Map<number, ProviderType>());
  const [searchResult, setSearchResult] = useState<SearchResult>({
    partnerships: [],
    accounts: [],
  });
  const [loading, setLoading] = useState(false);

  const handleStart = useCallback(() => setLoading(true), []);
  const handleFinish = useCallback(() => setLoading(false), []);

  useEffect(() => {
    let active = true;
    if (query === "") {
      setSearchResult({ partnerships: [], accounts: [] });
      return undefined;
    } else {
      fetchResults(
        query,
        handleStart,
        handleFinish,
        (searchResult: SearchResult) => {
          if (active) {
            setSearchResult(searchResult);
          }
        }
      );
    }
    return () => {
      active = false;
    };
  }, [query, handleStart, handleFinish]);

  useEffect(() => {
    let active = true;
    if (profile.id) {
      fetchIntegrations().then((integrations: JSONAPIResource[]) => {
        if (active) {
          const mapping = buildMapping(integrations);
          setIntegrationProviderMap(mapping);
        }
      });
    }
    return () => {
      active = false;
    };
    /**
     * Adding the profile in the deps is an artifact to be sure to load Integrations
     * after loging procedure is complete
     **/
  }, [profile.id]);

  return useMemo(
    () => ({
      loading,
      integrationProviderMap,
      searchResult,
    }),
    [loading, searchResult, integrationProviderMap]
  );
};

/* Exports */

export default useAPI;
export const _private = {
  buildMapping,
  fetchIntegrations,
  fetchResults,
};
