import CrmField from "models/CrmField";
import { PageType } from "models/PageView";
import {
  ColumnConfigType,
  MatchSortOrder,
  orderColumns,
} from "screens/Frontoffice/screens/DataTables/shared/types";

export const PARTNER_PRESENCE_DEFAULT_WIDTH = 400;

export enum Available360MappingColumns {
  actions = "actions",
  active = "active",
  name = "name",
  status = "status",
  ownerName = "ownerName",
  countryCode = "countryCode",
  countryName = "countryName",
  customerOf = "customerPresenceList",
  domain = "domain",
  employeeRange = "employeeRange",
  industry = "industry",
  partnerPresence = "partnerPresence",
  partnerOpportunities = "partnerOpportunities",
  partnerSignals = "partnerSignals",
  starred = "starred",
  winRateMultiplier = "winRateMultiplier",
  revenueMultiplier = "revenueMultiplier",
  dealSizeMultiplier = "dealSizeMultiplier",
  customerPresence = "customerPresence",
  prospectPresence = "prospectPresence",
  customerPresencePartnerTags = "customerPresencePartnerTags",
  prospectPresencePartnerTags = "prospectPresencePartnerTags",
  nbPartners = "nbPartners",
  nbOpportunities = "nbOpportunities",
  customerPresenceHighlightsCount = "customerPresenceHighlightsCount",
  createdAt = "createdAt",
  dealInfluencerList = "dealInfluencerList",
}

export const default360MappingColumnKeys = [
  Available360MappingColumns.name,
  Available360MappingColumns.status,
  Available360MappingColumns.ownerName,
  Available360MappingColumns.countryCode,
  Available360MappingColumns.active,
  Available360MappingColumns.domain,
  Available360MappingColumns.partnerPresence,
  Available360MappingColumns.partnerOpportunities,
  Available360MappingColumns.winRateMultiplier,
  Available360MappingColumns.revenueMultiplier,
  Available360MappingColumns.dealSizeMultiplier,
  Available360MappingColumns.starred,
  Available360MappingColumns.createdAt,
  Available360MappingColumns.dealInfluencerList,
];

export const mapping360ColumnsConfig: { [key: string]: ColumnConfigType } = {
  [Available360MappingColumns.name]: {
    key: Available360MappingColumns.name,
    width: 180,
    frozen: true,
    fixedPosition: 1,
    removable: false,
  },
  [Available360MappingColumns.status]: {
    key: Available360MappingColumns.status,
    width: 140,
    frozen: false,
  },
  [Available360MappingColumns.starred]: {
    key: Available360MappingColumns.starred,
    width: 150,
    frozen: false,
  },
  [Available360MappingColumns.ownerName]: {
    key: Available360MappingColumns.ownerName,
    width: 170,
    frozen: false,
  },
  [Available360MappingColumns.countryCode]: {
    key: Available360MappingColumns.countryCode,
    width: 130,
    frozen: false,
  },
  [Available360MappingColumns.active]: {
    key: Available360MappingColumns.active,
    width: 310,
    frozen: false,
  },
  [Available360MappingColumns.domain]: {
    key: Available360MappingColumns.domain,
    width: 170,
    frozen: false,
  },
  [Available360MappingColumns.partnerPresence]: {
    key: Available360MappingColumns.partnerPresence,
    width: PARTNER_PRESENCE_DEFAULT_WIDTH,
    frozen: false,
  },
  [Available360MappingColumns.partnerOpportunities]: {
    key: Available360MappingColumns.partnerOpportunities,
    width: PARTNER_PRESENCE_DEFAULT_WIDTH,
    frozen: false,
  },
  [Available360MappingColumns.partnerSignals]: {
    key: Available360MappingColumns.partnerSignals,
    width: 136,
    frozen: false,
  },
  [Available360MappingColumns.winRateMultiplier]: {
    key: Available360MappingColumns.winRateMultiplier,
    width: 180,
    frozen: false,
  },
  [Available360MappingColumns.revenueMultiplier]: {
    key: Available360MappingColumns.revenueMultiplier,
    width: 180,
    frozen: false,
  },
  [Available360MappingColumns.dealSizeMultiplier]: {
    key: Available360MappingColumns.dealSizeMultiplier,
    width: 180,
    frozen: false,
  },
  [Available360MappingColumns.countryName]: {
    key: Available360MappingColumns.countryName,
    width: 112,
    frozen: false,
  },
  [Available360MappingColumns.industry]: {
    key: Available360MappingColumns.industry,
    width: 192,
    frozen: false,
  },
  [Available360MappingColumns.employeeRange]: {
    key: Available360MappingColumns.employeeRange,
    width: 128,
    frozen: false,
  },
  [Available360MappingColumns.customerOf]: {
    key: Available360MappingColumns.customerOf,
    width: 360,
    frozen: false,
  },
  [Available360MappingColumns.customerPresenceHighlightsCount]: {
    key: Available360MappingColumns.customerPresenceHighlightsCount,
    width: 156,
    frozen: false,
  },
  [Available360MappingColumns.createdAt]: {
    key: Available360MappingColumns.createdAt,
    width: 156,
    frozen: false,
  },
  [Available360MappingColumns.dealInfluencerList]: {
    key: Available360MappingColumns.dealInfluencerList,
    width: 292,
    frozen: false,
  },
};

export const apply360MappingColumnOrder = (columns: ColumnConfigType[]) =>
  orderColumns(columns);

export const default360MappingColumnConfig = (
  key: string,
  field: { label: string }
) => ({
  key,
  width: 8 * Math.max(field.label.length, 10),
  frozen: false,
});

export const default360MappingColumns = apply360MappingColumnOrder(
  default360MappingColumnKeys.map((key) => mapping360ColumnsConfig[key])
);

export const defaultMapping360Sort = [
  {
    fieldname: "active",
    order: MatchSortOrder.ORDER_DESC,
    fieldtype: CrmField.TYPE_CURRENCY,
  },
  {
    fieldname: "starred",
    order: MatchSortOrder.ORDER_DESC,
    fieldtype: CrmField.TYPE_BOOLEAN,
  },
  {
    fieldname: "nbPartners",
    order: MatchSortOrder.ORDER_DESC,
    fieldtype: CrmField.TYPE_INTEGER,
  },
];

export const Mapping360WithGrowthKPIsSort = [
  {
    fieldname: "revenueMultiplier",
    order: MatchSortOrder.ORDER_DESC,
    fieldtype: CrmField.TYPE_UNKNOWN,
  },
].concat(defaultMapping360Sort);

export const mapping360PageTypes = [
  PageType.mapping360Influence,
  PageType.mapping360NonGoalBased,
  PageType.mapping360Overlaps,
  PageType.mapping360Expand,
  PageType.mapping360CoSell,
  PageType.mapping360Sourcing,
  PageType.mapping360Upsell,
];
