import { isFulfilled } from "@reduxjs/toolkit";
import { ProviderType } from "config/constants";
import useAllRecords from "hooks/useAllRecords";
import _ from "lodash";
import CrmField from "models/CrmField";
import { useCallback, useEffect, useState } from "react";
import { MessageDescriptor } from "react-intl";
import { useDispatch } from "react-redux";
import { rawPost } from "redux/api/thunks";
import { FilterType } from "screens/Frontoffice/screens/DataTables/shared/types";
import { MatchFieldType } from "screens/Frontoffice/screens/DataTables/shared/types/enums";
import { JSONAPIResponse } from "services/types";

interface RawRevealField {
  name: string;
  label: string;
  type: MatchFieldType;
  option_count: { [label: string]: string } | null;
}

interface RawIntegrationData {
  [key: string]: {
    fields: RawRevealField[];
    number_of_records_to_export: number;
    provider: ProviderType;
  };
}

export type RevealOption = {
  value: string | null;
  label: string | MessageDescriptor;
  use?: string;
};

export interface RevealFieldType {
  id?: number;
  name: string;
  label: string;
  type: MatchFieldType;
  options: RevealOption[] | null;
  disabled: boolean;
}

export interface IntegrationData {
  id: string;
  provider: ProviderType;
  recordCount: number;
  fields: {
    [fieldname: string]: RevealFieldType;
  };
}

export enum SupportedResources {
  sharedAccounts = "shared_accounts",
  referredAccounts = "referred_accounts",
}

export const ExportTypes = {
  [SupportedResources.sharedAccounts]: 1,
  [SupportedResources.referredAccounts]: 2,
};

const crmFieldNameToExclude = [
  "Name",
  "Website",
  "BillingCountry",
  "AccountSource",
  "Type",
];

const supportedFieldTypes = [
  MatchFieldType.TEXT,
  MatchFieldType.BOOLEAN,
  MatchFieldType.NUMBER,
  MatchFieldType.INTEGER,
  MatchFieldType.PICKLIST,
];

const MAX_RECORD_TO_EXPORT_LIMIT = 300;

const useMappingFields = (
  resource: SupportedResources,
  integrationId: number,
  partnershipId: number | null,
  filters: FilterType[]
) => {
  const dispatch = useDispatch();
  const [totalCount, setTotalCount] = useState<number | null>(null);
  const [partnerIntegrationsData, setPartnerIntegrationsData] = useState<
    IntegrationData[]
  >([]);

  // Using a fake Owner field
  const ownerField = {
    id: 0,
    crmName: "Owner",
    crmLabel: "Owner",
    swType: MatchFieldType.USER,
    crmOptions: {},
  } as CrmField;

  // Get Type field to retrieve type options separatly
  // because we are not sure this field is required by the CRM but required on the modal
  const {
    loading: typeFieldLoading,
    records: typeField,
  }: {
    loading: boolean;
    records: CrmField[];
    refresh: () => void;
  } = useAllRecords("crm_fields", {
    filters: {
      integration: integrationId,
      sw_record_type: "RawCompany",
      crm_name: "Type",
      syncable: true,
    },
  });

  // Get required CRM fields
  const {
    loading: requiredCrmFieldsLoading,
    records: requiredCrmFields,
  }: {
    loading: boolean;
    records: CrmField[];
    refresh: () => void;
  } = useAllRecords("crm_fields", {
    filters: {
      integration: integrationId,
      sw_record_type: "RawCompany",
      required: true,
      syncable: true,
    },
  });

  // Get optional CRM fields
  const {
    loading: optionalCrmFieldsLoading,
    records: optionalCrmFields,
  }: {
    loading: boolean;
    records: CrmField[];
    refresh: () => void;
  } = useAllRecords("crm_fields", {
    filters: {
      integration: integrationId,
      sw_record_type: "RawCompany",
      required: "false",
      read_only: "false",
      syncable: true,
    },
  });

  // Get Reveal fields per partner integration
  const collectFields = useCallback(async () => {
    const result = await dispatch(
      rawPost({
        type: resource,
        path: "export-options/",
        payload: {
          data: {
            type: "export-options",
            attributes: {
              export_type: ExportTypes[resource],
              filters: filters as [],
            },
          },
        },
        options: {
          filters: { partnership: partnershipId },
        },
      })
    );
    if (isFulfilled(result)) {
      const response = result.payload as JSONAPIResponse;

      const _totalCount = _.get(response.data, "total_count", 0);
      const hasMoreThanMax = _totalCount > MAX_RECORD_TO_EXPORT_LIMIT;
      setTotalCount(_totalCount);

      const integrations: RawIntegrationData = _.get(
        response.data,
        "integrations"
      );

      // Compute the order of the partner integrations
      const keySorted = Object.keys(integrations).sort(function (a, b) {
        return (
          integrations[b].number_of_records_to_export -
          integrations[a].number_of_records_to_export
        );
      });

      // Process partner integrations data
      const formattedData = keySorted.map((key) => {
        const rawData = integrations[key];
        return {
          id: key,
          provider: rawData.provider,
          recordCount: rawData.number_of_records_to_export,
          fields: getProcessedRawFields(rawData.fields, hasMoreThanMax),
        } as IntegrationData;
      });

      setPartnerIntegrationsData(formattedData);
    }
  }, [resource, partnershipId, JSON.stringify(filters)]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    collectFields();
  }, [collectFields]);

  return {
    totalCount,
    partnerIntegrationsData,
    requiredCrmFieldsLoading: requiredCrmFieldsLoading || typeFieldLoading,
    requiredCrmFields: getProcessedCrmFields(
      [ownerField, ...typeField, ...filterCrmFields(requiredCrmFields)],
      true
    ),
    optionalCrmFieldsLoading,
    optionalCrmFields: getProcessedCrmFields(
      filterCrmFields(optionalCrmFields),
      false
    ),
  };
};

export default useMappingFields;

// Helpers

// Filter out fields mapped by default & unsupported types
const filterCrmFields = (crmFields: CrmField[]) =>
  crmFields
    .filter((field) => !crmFieldNameToExclude.includes(field.crmName))
    .filter((field) => supportedFieldTypes.includes(field.swType));

// Check the number of picklist options values use for the first MAX_RECORD_TO_EXPORT_LIMIT records.
// If there are more than MAX_RECORD_TO_EXPORT_LIMIT records selected
// then we display an estimation in percentage
const getProcessedRawFields = (
  fields: RawRevealField[],
  hasMoreThanMax: boolean
) => {
  // Fonction to format picklist options to map for Reveal fields
  const getPicklistOptions = (options: { [label: string]: string } | null) => {
    if (options && !_.isEmpty(options)) {
      return (
        Object.keys(options)
          .map((label) => {
            let use = options[label]; // Number (or estimated percentage) of records using the value
            // Hide estimation if it's 0%
            if (use === "~0%") {
              use = "";
            }
            return {
              value: label,
              label,
              use,
            } as RevealOption;
          })
          // Filter out unused options when there are less than MAX_RECORD_TO_EXPORT_LIMIT records
          .filter(
            (option) =>
              hasMoreThanMax || (!hasMoreThanMax && option.use !== "0")
          )
          // Sort by use
          .sort((a, b) => (b.use ?? "0").localeCompare(a.use ?? "0"))
      );
    }
    return null;
  };

  // Function to format Reveal fields
  const processField = (field: RawRevealField): RevealFieldType => ({
    name: field.name,
    label: field.label,
    type: field.type,
    options: getPicklistOptions(field.option_count),
    disabled: false,
  });

  return _.transform(
    fields,
    (result, field: RawRevealField) =>
      (result[field.name] = processField(field)),
    {} as { [fieldName: string]: RevealFieldType }
  );
};

const getProcessedCrmFields = (fields: CrmField[], isRequired: boolean) => {
  // Function to format options for Crm fields (to be displayed on the left side as "Crm Values")
  const getFieldOptions = (options: object) => {
    if (options && !_.isEmpty(options)) {
      const mappedOptions = Object.values(options).map((label) => {
        return {
          value: label,
          label,
        } as RevealOption;
      });
      return mappedOptions as RevealOption[];
    }
    return null;
  };

  // Function to format Crm fields
  const processField = (field: CrmField): RevealFieldType => ({
    id: field.id,
    name: field.crmName,
    label: field.crmLabel,
    type: field.swType,
    options: getFieldOptions(field.crmOptions),
    disabled: isRequired,
  });

  return _.transform(
    fields,
    (result, field: CrmField) => (result[field.crmName] = processField(field)),
    {} as { [fieldName: string]: RevealFieldType }
  );
};
