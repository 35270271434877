export const included = [
  "account_mapping_settings",
  "account_mapping_view",
  "dest_company",
  "initiator_company",
  "initiator_user",
  "insights",
  "integrations",
  "invites",
  "partnership_overview_view",
  "sharing_settings",
  "watchers",
  "initiator_partner_stack_partnership",
  "dest_partner_stack_partnership",
];

export const fields = {
  partnerships: [
    ...included,
    "capabilities",
    "dest_mapping_rules_progress",
    "dest_mapping_rules_set",
    "has_partner_integration",
    "init_mapping_rules_progress",
    "init_mapping_rules_set",
    "is_new_leads_enabled",
    "partner_has_disabled_integration",
    "paused_by_dest",
    "paused_by_init",
    "ready",
    "requested_company_name",
    "requested_company_domain",
    "status",
    "watcher_ids",
    "is_initiator_partner_stack_partnership_eligible",
    "is_dest_partner_stack_partnership_eligible",
  ],
};

export const booleanSharingSettingsColumnsMap: {
  [resource: string]: string;
} = {
  leftName: "share_name",
  leftStatus: "share_status",
  leftContactCount: "share_number_of_contacts",
  leftCountryCode: "share_country",
  leftDomain: "share_website",
};

export const getTitleWidth = (
  dialogRefCurrent: HTMLElement,
  titleWidth: number
) => {
  const element = dialogRefCurrent.querySelector(
    ".partnership-dialog-title"
  ) as HTMLElement;
  const newTitleWidth = element?.offsetWidth || 0;
  return titleWidth !== newTitleWidth ? newTitleWidth : titleWidth;
};

export const getIsSmall = (
  partnership: any,
  titleWidth: number,
  windowWidth: any
) => {
  const getActionBtnsWidth = () => {
    let width = 134 + 214; // Action btn + Csv export btn width
    if (partnership.integrations?.length > 1) {
      width += +178; // Source select btn width
    }
    if (partnership.isWhitespaceEnabled) {
      width += +135; // Account type btn width
    }
    return width;
  };

  const leftWidth = titleWidth + 64;
  const rightWidth = 260;
  const actionBtnsWidth = getActionBtnsWidth();
  const missingWidth =
    (windowWidth ?? 0) < leftWidth + rightWidth + actionBtnsWidth;

  return missingWidth;
};

export const GRANT_ACCESS_HASH = "#grant-access";
