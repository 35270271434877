import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";
import { Favorite, FavoriteFilled } from "components/icons";
import usePushNotification from "hooks/usePushNotification";
import useSegment from "hooks/useSegment";
import useUserProfile from "hooks/useUserProfile";
import { makeStyles } from "makeStyles";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { AvailableAnalytisColumns } from "redux/analytics/defaults";
import { updateAttributesOnRecord } from "redux/api/actions";
import { rawPost } from "redux/api/thunks";
import { AdvancedAnalyticsEvent } from "tracking";

import { CellProps } from "../../utils";

const FavoriteCell = (props: CellProps) => {
  const dispatch = useDispatch();
  const { track } = useSegment();
  const { profile } = useUserProfile();
  const { partnerAnalyticsId, partnershipId, watcherIds } = getValues(props);
  const isWatching = watcherIds.includes(+profile.id);
  const { classes } = useStyles();
  const pushNotification = usePushNotification();
  const [loading, setLoading] = useState(false);
  const Icon = isWatching ? FavoriteFilled : Favorite;

  const toggleWatch = async () => {
    try {
      setLoading(true);
      await dispatch(
        rawPost({
          type: "partnerships",
          id: partnershipId,
          path: isWatching ? "/unwatch/" : "/watch/",
          payload: {},
          options: {
            fields: {
              partnerships: ["watcher_ids", "watchers"],
            },
          },
        })
      );
      await dispatch(
        updateAttributesOnRecord(
          { id: String(partnerAnalyticsId), type: "partner_analytics" },
          {
            watcherIds: isWatching
              ? watcherIds.filter((id: number) => id !== +profile.id)
              : [...watcherIds, +profile.id],
          }
        )
      );
      track(AdvancedAnalyticsEvent.clickOnFavoriteCell);
      setLoading(false);
    } catch (_error) {
      pushNotification("default_error");
    }
  };

  return (
    <Box>
      {!loading ? (
        <div onClick={toggleWatch}>
          <Icon className={classes.icon} data-testid="watching-icon" />
        </div>
      ) : (
        <CircularProgress size={10} style={{ marginLeft: 5 }} />
      )}
    </Box>
  );
};

export default FavoriteCell;

// Helpers

export const isFavoriteCell = ({ fieldName }: CellProps) => {
  return fieldName === AvailableAnalytisColumns.favorite;
};

const getValues = ({ row }: CellProps) => {
  let partnerAnalyticsId = row["id"];
  let partnershipId = row["partnershipId"];
  let watcherIds = row["watcherIds"];
  return { partnerAnalyticsId, partnershipId, watcherIds };
};

// CSS

const useStyles = makeStyles()((theme) => ({
  icon: {
    fontSize: 20,
    cursor: "pointer",
  },
}));
