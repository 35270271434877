import { Edit } from "@mui/icons-material";
import { Favorite, FavoriteFilled, Settings, Trash } from "components/icons";
import AccountAvatar from "components/ui/avatars/AccountAvatar";
import Button from "components/ui/Button";
import Tooltip from "components/ui/Tooltip";
import { goToPartnershipSettings } from "helpers/navigation";
import usePushNotification from "hooks/usePushNotification";
import { makeStyles } from "makeStyles";
import Partnership from "models/Partnership";
import User from "models/User";
import { useState } from "react";
import { defineMessages, FormattedMessage } from "react-intl";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { rawPost } from "redux/api/thunks";
import PartnershipStatusDialog, {
  DialogType,
} from "screens/Frontoffice/screens/Partners/shared/components/PartnershipStatusDialog";

import PartnershipOwnerDropdown from "../../../../PartnershipSettings/components/PartnershipOwnerDropdown";

type Props = {
  partnership: Partnership;
  profile: User;
};

const PartnerTileActions = ({ partnership, profile }: Props) => {
  const [dialogOpen, setDialogOpen] = useState(false);
  const { classes } = useStyles();
  const isWatching = partnership.watcherIds.includes(+profile.id);
  const history = useHistory();
  const pushNotification = usePushNotification();
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();

  const isDeletable =
    partnership.isInitiator(profile.company) &&
    !partnership.isAccepted() &&
    profile.canDeletePartnerships &&
    !partnership.isGhost();

  const toggleWatch = async () => {
    try {
      setLoading(true);
      await dispatch(
        rawPost({
          type: "partnerships",
          id: partnership.id,
          path: isWatching ? "/unwatch/" : "/watch/",
          payload: {},
          options: {
            fields: {
              partnerships: ["watcher_ids", "watchers"],
            },
          },
        })
      );
      setLoading(false);
    } catch (_error) {
      pushNotification("default_error");
    }
  };

  const handleDeleteClick = () => {
    setDialogOpen(true);
  };

  const handleSettingsClick = () =>
    goToPartnershipSettings(partnership, history);

  return (
    <>
      {partnership.partnershipOwner && (
        <Tooltip
          title={
            <div>
              <FormattedMessage
                {...i18n.owner}
                values={{ ownerName: partnership.partnershipOwner.fullName }}
              />
              <Edit className={classes.tooltipIcon} />
            </div>
          }
        >
          <div>
            <PartnershipOwnerDropdown partnership={partnership}>
              <AccountAvatar user={partnership.partnershipOwner} size="sm" />
            </PartnershipOwnerDropdown>
          </div>
        </Tooltip>
      )}
      {!loading && (
        <>
          <Button
            label=""
            variant="quaternary"
            size="small"
            LeftIcon={isWatching ? FavoriteFilled : Favorite}
            onClick={toggleWatch}
            loading={loading}
            data-testid="favorite-btn"
          />
          {isDeletable && (
            <Button
              label=""
              variant="quaternary"
              size="small"
              LeftIcon={Trash}
              loading={loading}
              onClick={handleDeleteClick}
              data-testid="delete-btn"
            />
          )}
        </>
      )}
      {partnership.isParnershipSettingsAvailable(profile) && (
        <Button
          label=""
          variant="quaternary"
          size="small"
          LeftIcon={Settings}
          onClick={handleSettingsClick}
          data-testid="settings-btn"
        />
      )}
      <PartnershipStatusDialog
        isOpen={dialogOpen}
        onClose={() => {
          setDialogOpen(false);
        }}
        partnership={partnership}
        setLoading={setLoading}
        type={DialogType.DeletePending}
        data-testid="delete-btn"
      />
    </>
  );
};

export default PartnerTileActions;

// i18n
const i18n = defineMessages({
  owner: {
    id: "PartnerTile.PartnerTileActions.owner",
    defaultMessage: "Owner: {ownerName}",
  },
});

export const useStyles = makeStyles()((theme) => ({
  tooltipIcon: {
    height: 12,
    lineHeight: 18,
  },
}));
