import Button from "components/ui/Button";
import { T } from "components/ui/Typography";
import _ from "lodash";
import { makeStyles } from "makeStyles";
import { MouseEvent, useCallback, useState } from "react";
import { defineMessages, FormattedMessage } from "react-intl";
import {
  FieldType,
  FilterType,
} from "screens/Frontoffice/screens/DataTables/shared/types";

import OverviewFilters from "./OverviewFilters";

type Props = {
  fields: Record<string, FieldType>;
  filters?: FilterType[];
  onChange: (filters: FilterType[]) => any;
  partnerName: string;
};

const FilterButton = ({ fields, filters, onChange, partnerName }: Props) => {
  const { classes } = useStyles();
  const [dialogOpen, setDialogOpen] = useState(false);

  const _onClick = useCallback((event: MouseEvent) => {
    event.stopPropagation();
    setDialogOpen(true);
  }, []);

  const _onChange = useCallback(
    (filters: FilterType[]) => {
      setDialogOpen(false);
      onChange(filters);
    },
    [onChange]
  );

  const _onCancel = useCallback((event?: MouseEvent) => {
    if (event) {
      event.stopPropagation();
    }
    setDialogOpen(false);
  }, []);

  return (
    <div className={classes.root}>
      <Button
        onClick={_onClick}
        disabled={_.isEmpty(fields)}
        label={
          <div className={classes.labelWithBadge}>
            <T className={classes.label}>
              <FormattedMessage {...i18n.filter} />
            </T>
            {!!filters && filters.length > 0 && (
              <T uppercase className={classes.badge}>
                {filters.length}
              </T>
            )}
          </div>
        }
        variant="tertiary"
        classes={{ btn: classes.button }}
      />
      {filters !== undefined && dialogOpen && (
        <OverviewFilters
          open={dialogOpen}
          onCancel={_onCancel}
          onChange={_onChange}
          filters={filters}
          fields={fields}
          partnerName={partnerName}
        />
      )}
    </div>
  );
};

export default FilterButton;

/// CSS

const useStyles = makeStyles()((theme) => ({
  root: {
    position: "relative",
  },
  button: {
    backgroundColor: `${theme.palette.greyscale200} !important`,
  },
  labelWithBadge: {
    display: "flex",
    alignItems: "center",
  },
  icon: {
    width: 16,
    height: 16,
    color: "inherit",
  },
  badge: {
    backgroundColor: theme.palette.midnight,
    color: theme.palette.offWhite,
    padding: "3px 6px",
    borderRadius: 24,
    marginLeft: 6,
  },
  label: {
    fontSize: 12,
    lineHeight: "12px",
    fontWeight: 500,
  },
}));

const i18n = defineMessages({
  filter: {
    id: "Overview.OverviewSharedFilters.filter",
    defaultMessage: "Filter",
  },
});
