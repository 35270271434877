import useSegment from "hooks/useSegment";
import _ from "lodash";
import { JSONSerializable } from "models/types";
import { useEffect, useRef } from "react";

const DEFAULT_DEPS = [] as JSONSerializable[];

const useTrack = (
  eventName: string,
  eventParams?: Object,
  deps: JSONSerializable[] = DEFAULT_DEPS
) => {
  const { track } = useSegment();
  const lastEventName = useRef<string>();
  const lastEventParams = useRef<Object>();

  useEffect(() => {
    if (
      !_.isEqual(eventParams, lastEventParams.current) ||
      lastEventName.current !== eventName
    ) {
      track(eventName, eventParams);
      lastEventName.current = eventName;
      lastEventParams.current = eventParams;
    }
  }, [eventName, eventParams, track, deps]);
};

export default useTrack;
