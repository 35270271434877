import { T } from "components/ui/Typography";
import { makeStyles } from "makeStyles";
import { FormattedMessage, MessageDescriptor } from "react-intl";

type Props = {
  imgSrc: string;
  imgAlt: string;
  i18nTitle: MessageDescriptor;
  i18nSubtitle: MessageDescriptor;
};

export const ContentHeaderWithLogo = ({
  imgSrc,
  imgAlt,
  i18nTitle,
  i18nSubtitle,
}: Props) => {
  const { classes } = useStyles();
  return (
    <div className={classes.contentHeader}>
      <div className={classes.logo}>
        <img src={imgSrc} alt={imgAlt} />
      </div>
      <T h2 bold>
        <FormattedMessage {...i18nTitle} />
      </T>
      <T bodyBig className={classes.subtitle}>
        <FormattedMessage {...i18nSubtitle} />
      </T>
    </div>
  );
};

const useStyles = makeStyles()((theme) => ({
  contentHeader: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    gap: 12,
    marginBottom: 32,
  },
  logo: {
    width: 40,
    height: 40,
    "& img": {
      width: "100%",
      height: "100%",
      objectFit: "cover",
      objectPosition: "center",
    },
  },
  subtitle: {
    color: theme.palette.alpha500,
  },
}));
