import segment from "helpers/segment";
import { createBrowserHistory } from "history";

type GoBackOptions = {
  fallback: string;
};

// Augment `History` interface with optional fallback pathname which
// can be used to prevent `goBack` from leaving the app.
declare module "history" {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  export interface History<HistoryLocationState = unknown, T = unknown> {
    goBack(options?: GoBackOptions): void;
  }
}

const FIRST_HISTORY_ENTRY = "FIRST_HISTORY_ENTRY";

const history = createBrowserHistory();

// Add flag on first load.
history.replace({
  ...history.location,
  state: FIRST_HISTORY_ENTRY,
});

history.goBack = (options?: GoBackOptions) => {
  if (history.location.state === FIRST_HISTORY_ENTRY && options?.fallback) {
    history.replace(options.fallback, FIRST_HISTORY_ENTRY);
  } else {
    history.go(-1);
  }
};

history.listen(() => {
  segment.page();
});

export default history;
