import withCompanyPermissions from "components/hoc/withCompanyPermissions";
import { WithUserProfile } from "components/hoc/withUserProfile";
import { ChevronDown } from "components/icons";
import Button from "components/ui/Button";
import { makeStyles } from "makeStyles";
import Partnership from "models/Partnership";
import { defineMessages } from "react-intl";

import PartnershipOwnerDropdown from "./PartnershipOwnerDropdown";

type Props = WithUserProfile & {
  partnership: Partnership;
};

const PartnershipOwner = ({ partnership }: Props) => {
  const { classes, cx } = useStyles();
  const owner = partnership.partnershipOwner;
  const hasDeletedUserFullname =
    owner?.deleted && owner?.fullName === "deleted deleted";

  return (
    <PartnershipOwnerDropdown partnership={partnership}>
      <Button
        classes={{
          btn: cx(classes.btn, {
            [classes.danger]: owner?.deleted,
          }),
        }}
        label={
          hasDeletedUserFullname ? i18n.deletedUser : owner?.fullName ?? "--"
        }
        RightIcon={ChevronDown}
        variant="quaternary"
        size="xSmall"
      />
    </PartnershipOwnerDropdown>
  );
};

export default withCompanyPermissions<Props>(["company.manage_partnerships"], {
  Fallback: () => null,
})(PartnershipOwner);

const useStyles = makeStyles()((theme) => ({
  btn: {
    borderRadius: 4,
  },
  danger: {
    backgroundColor: `${theme.palette.red050v2} !important`,
    border: `1px solid ${theme.palette.red600v3} !important`,
  },
  labelContent: {
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    overflow: "hidden",
    display: "inline-block",
    maxWidth: 120,
  },
}));

const i18n = defineMessages({
  deletedUser: {
    defaultMessage: "Deleted User",
    id: "PartnershipOwner.deletedUser",
  },
});
