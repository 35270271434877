import { useTheme } from "@mui/material/styles";

const useSelectStyles = ({ contained = false, alignRight = false }) => {
  const theme = useTheme();

  const getControlStyles = ({ isFocused, hasValue }: $TSFixMe) => {
    const controlStyles = {
      fontFamily: "'Inter', sans-serif",
      display: "flex",
      alignItems: "center",
      cursor: "pointer",
      fontWeight: 400,
    };
    if (contained) {
      const borderColor = isFocused
        ? theme.palette.greyscale350
        : theme.palette.greyscale250;
      // @ts-expect-error ts-migrate(2339) FIXME: Property 'border' does not exist on type '{ fontFa... Remove this comment to see the full error message
      controlStyles.border = `1px solid ${borderColor}`;
      // @ts-expect-error ts-migrate(2339) FIXME: Property 'borderRadius' does not exist on type '{ ... Remove this comment to see the full error message
      controlStyles.borderRadius = 18;
      // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
      controlStyles["&:hover"] = { borderColor: theme.palette.grey.main };
      // @ts-expect-error ts-migrate(2339) FIXME: Property 'padding' does not exist on type '{ ... Remove this comment to see the full error message
      controlStyles.padding = "3px 5px";
      // @ts-expect-error ts-migrate(2339) FIXME: Property 'height' does not exist on type '{ fontFa... Remove this comment to see the full error message
      controlStyles.height = 28;
    } else {
      // @ts-expect-error ts-migrate(2339) FIXME: Property 'height' does not exist on type '{ fontFa... Remove this comment to see the full error message
      controlStyles.height = 28;
      // @ts-expect-error ts-migrate(2339) FIXME: Property 'borderBottom' does not exist on type '{ ... Remove this comment to see the full error message
      controlStyles.borderBottom = isFocused
        ? `1px solid ${theme.palette.primary.main}`
        : hasValue
        ? "none"
        : // @ts-expect-error ts-migrate(2339) FIXME: Property 'dark' does not exist on type 'Color'.
          `1px solid ${theme.palette.grey.dark}`;
      // @ts-expect-error ts-migrate(2339) FIXME: Property 'marginBottom' does not exist on type '{ ... Remove this comment to see the full error message
      controlStyles.marginBottom = 1;
      // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
      controlStyles["&:hover"] = {
        marginBottom: hasValue ? 0 : 1,
        borderBottom:
          isFocused || !hasValue
            ? `1px solid ${theme.palette.primary.main}`
            : // @ts-expect-error ts-migrate(2339) FIXME: Property 'dark' does not exist on type 'Color'.
              `1px solid ${theme.palette.grey.dark}`,
      };
    }
    return controlStyles;
  };

  return {
    control: (base: $TSFixMe, { isFocused, hasValue }: $TSFixMe) =>
      getControlStyles({ isFocused, hasValue }),
    valueContainer: (base: $TSFixMe) => ({
      ...base,
      padding: 0,
      paddingLeft: contained ? 5 : 0,
    }),
    input: (base: $TSFixMe) => ({
      ...base,
      margin: 0,
      padding: contained ? 5 : 0,
    }),
    placeholder: () => ({
      margin: 0,
      padding: contained ? 5 : 0,
    }),
    dropdownIndicator: () => ({
      margin: 0,
      marginRight: contained ? 5 : 0,
      width: 20,
      // @ts-expect-error ts-migrate(2339) FIXME: Property 'dark' does not exist on type 'Color'.
      color: theme.palette.grey.dark,
    }),
    menu: (base: $TSFixMe) => ({
      ...base,
      position: alignRight ? "absolute" : "initial",
      right: alignRight ? 0 : "auto",
      width: "max-content",
      maxWidth: 500,
    }),
    menuPortal: (base: $TSFixMe) => ({
      ...base,
      zIndex: 9999,
    }),
    option: (
      base: $TSFixMe,
      { data, isFocused, isSelected, isDisabled }: $TSFixMe
    ) => {
      const styles = {
        ...base,
        fontSize: 13,
      };
      if (isDisabled) {
        return {
          ...styles,
          cursor: "not-allowed",
          fontStyle: "italic",
          // @ts-expect-error ts-migrate(2339) FIXME: Property 'light' does not exist on type 'Color'.
          backgroundColor: theme.palette.grey.light,
        };
      }
      return {
        ...styles,
        cursor: "pointer",
        fontWeight: isSelected ? 500 : 400,
        fontStyle: data.isItalic ? "italic" : "normal",
        color: isSelected
          ? theme.palette.primary.main
          : // @ts-expect-error ts-migrate(2339) FIXME: Property 'dark' does not exist on type 'Color'.
            theme.palette.grey.dark,
        // @ts-expect-error ts-migrate(2339) FIXME: Property 'whitish' does not exist on type 'Color'.
        backgroundColor: isFocused ? theme.palette.grey.whitish : "",
      };
    },
    singleValue: () => ({}),
    multiValue: (base: $TSFixMe) => {
      return {
        ...base,
        borderRadius: 3,
        // @ts-expect-error ts-migrate(2339) FIXME: Property 'light' does not exist on type 'Color'.
        backgroundColor: theme.palette.grey.light,
      };
    },
  };
};

export default useSelectStyles;
