import BaseDataGrid from "components/ui/data-grid/BaseDataGrid";
import { makeStyles } from "makeStyles";
import Record from "models/Record";
import { defineMessages, FormattedHTMLMessage } from "react-intl";
import { metricsColumnsMapping } from "redux/analytics/defaults";
import { DataTableType } from "screens/Frontoffice/screens/DataTables/shared/types";

import { AnalyticsColumnWidget } from "../../AnalyticsColumnWidget";
import AnalyticsEmptyRowsView from "../../AnalyticsEmptyRowsView";
import useAnalyticsTableAPILogic from "../hooks/useAnalyticsTableAPILogic";

type Props = {
  rows?: Record[];
  rowsCount?: number | null;
  fetching?: boolean;
  isUnlocked: boolean;
  loadMore: () => void;
  loading?: boolean;
};

export const AnalyticsTable = ({
  rows = [],
  rowsCount,
  loading,
  fetching,
  isUnlocked,
  loadMore,
}: Props) => {
  const { classes } = useStyles();
  const {
    fields,
    columns,
    setView,
    setSort,
    setColumns,
  } = useAnalyticsTableAPILogic();

  return (
    <div className={classes.root}>
      <BaseDataGrid
        noSide
        hideHeaderMenus
        hasRowSelection={false}
        rows={rows}
        rowsCount={rowsCount ?? 0}
        setSort={setSort}
        sortOnHeaders={isUnlocked}
        lockHeaders={!isUnlocked}
        fields={fields ?? {}}
        loadMore={loadMore}
        fetching={fetching}
        columns={columns}
        columnGroups={metricsColumnsMapping}
        emptyRowsView={
          <AnalyticsEmptyRowsView loading={loading} setView={setView} />
        }
        smallScreenText={
          <>
            <FormattedHTMLMessage {...i18n.screenToSmall} />
          </>
        }
        loading={loading}
        setColumns={setColumns}
        viewType={DataTableType.ANALYTICS}
      />
      <AnalyticsColumnWidget />
    </div>
  );
};

// CSS

const useStyles = makeStyles()((theme) => ({
  root: {
    position: "relative",
    height: "100%",
    paddingRight: 36,
  },
}));

// I18N

const i18n = defineMessages({
  screenToSmall: {
    id: "Analytics.AnalyticsTable.screenToSmall",
    defaultMessage: `
      Your screen is too small to display this table.
      <br/><br/>
      Please try it from a wider device.
    `,
  },
  partnerAnalytics: {
    id: "AnalyticsTable.customSectionTitle",
    defaultMessage: "Partner Analytics",
  },
  summaryMetrics: {
    id: "ColumnWidget.summaryMetrics",
    defaultMessage: "Summary Metrics",
  },
  expandMetrics: {
    id: "ColumnWidget.expandMetrics",
    defaultMessage: "Expand Revenue Metrics",
  },
  highWinRatePartners: {
    id: "ColumnWidget.highWinRatePartners",
    defaultMessage: "High win rate partners",
  },
});
