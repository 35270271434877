import { SvgIconComponent } from "@mui/icons-material";
import MuiButton, { ButtonProps } from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import { T } from "components/ui/Typography";
import { PrimitiveType } from "intl-messageformat";
import { makeStyles } from "makeStyles";
import { forwardRef, isValidElement } from "react";
import { FormattedHTMLMessage, MessageDescriptor } from "react-intl";
import { useMergedClasses } from "tss-react";

export type Size =
  | "big"
  | "medium"
  | "small"
  | "xSmall"
  | "xxSmall"
  | "xxxSmall";

export type Variant =
  | "primary"
  | "secondary"
  | "tertiary"
  | "quaternary"
  | "quinary"
  | "senary"
  | "septenary";

type StyleProps = {
  label?: string | MessageDescriptor | JSX.Element;
  labelValues?: Record<string, PrimitiveType>;
  bold?: boolean;
  size: Size;
  disabled?: boolean;
  loading?: boolean;
  LeftIcon?: SvgIconComponent | string;
  RightIcon?: SvgIconComponent;
  variant: Variant;
  ellipsis?: boolean;
};

export type Props = Partial<StyleProps> & {
  onClick?: (event?: any) => void;
  variant?: Variant;
  classes?: Partial<ReturnType<typeof useStyles>["classes"]>;
  noWrap?: boolean;
} & Omit<ButtonProps, "onClick" | "disabled" | "variant" | "size">;

const Button = forwardRef<HTMLButtonElement, Props>(
  (
    {
      label,
      labelValues,
      onClick = () => {},
      LeftIcon,
      RightIcon,
      color = "primary",
      disabled = false,
      variant = "primary",
      size = "medium",
      bold = false,
      classes: newClasses = {},
      noWrap = false,
      loading = false,
      ellipsis = false,
      ...props
    }: Props,
    parentRef
  ) => {
    const { classes: baseClasses, cx } = useStyles({
      disabled,
      loading,
      LeftIcon,
      RightIcon,
      size,
      label,
      labelValues,
      variant,
      ellipsis,
    });

    const classes = useMergedClasses(baseClasses, newClasses);

    const labelContent =
      typeof label === "string" ? (
        <span>{label}</span>
      ) : isValidElement(label) ? (
        label
      ) : (
        <FormattedHTMLMessage {...label} values={labelValues} />
      );

    const LoadingIcon = (
      <CircularProgress
        size={15}
        className={cx(classes.loader, {
          [classes.loaderLight]: variant === "senary",
        })}
      />
    );

    return (
      <MuiButton
        onClick={onClick}
        className={cx(classes.btn, classes[variant], {
          [classes.error]: color === "error",
        })}
        disabled={disabled || loading}
        ref={parentRef}
        {...props}
      >
        <div
          className={cx(classes.content, {
            [classes.secondaryContent]: variant === "secondary",
            [classes.senaryContent]: variant === "senary",
            [classes.ellipsis]: ellipsis,
          })}
        >
          {LeftIcon &&
            (loading ? (
              LoadingIcon
            ) : typeof LeftIcon === "string" ? (
              <img src={LeftIcon} alt="icon" className={classes.iconImage} />
            ) : (
              <LeftIcon className={classes.icon} />
            ))}

          {label &&
            (size !== "xxxSmall" ? (
              <T
                variant={size === "big" ? "labelBig" : "labelSmall"}
                className={cx(classes.labelContent, {
                  [classes.ellipsis]: ellipsis,
                })}
              >
                {labelContent}
              </T>
            ) : (
              <T
                className={cx(classes.labelContent, {
                  [classes.ellipsis]: ellipsis,
                })}
              >
                {labelContent}
              </T>
            ))}

          {RightIcon &&
            (loading && !LeftIcon ? (
              LoadingIcon
            ) : (
              <RightIcon className={classes.icon} />
            ))}
        </div>
      </MuiButton>
    );
  }
);

// CSS Helpers

const buttonHeight = {
  big: 44,
  medium: 36,
  small: 28,
  xSmall: 24,
  xxSmall: 20,
  xxxSmall: 18,
};

const contentHeight = {
  big: 42,
  medium: 34,
  small: 26,
  xSmall: 22,
  xxSmall: 18,
  xxxSmall: 16,
};

const getYPadding = (variant: Variant) => {
  switch (variant) {
    default:
      return 0;
  }
};

const getLeftContentPadding = (
  size: Size,
  hasLeftIcon?: boolean,
  hasLabel?: boolean,
  variant?: Variant
) => {
  if (!hasLabel) {
    return 0;
  }
  switch (size) {
    case "big":
    case "medium":
      if (hasLeftIcon) {
        return 12;
      }
      return 16;
    case "small":
      if (hasLeftIcon || variant === "septenary") {
        return 8;
      }
      return 12;
    case "xSmall":
      if (hasLeftIcon) {
        return 8;
      }
      return 12;
    case "xxSmall":
    case "xxxSmall":
      if (hasLeftIcon) {
        return 4;
      }
      return 8;
  }
};

const getRightContentPadding = (
  size: Size,
  hasRightIcon?: boolean,
  hasLabel?: boolean,
  variant?: Variant
) => {
  if (!hasLabel) {
    return 0;
  }
  switch (size) {
    case "big":
    case "medium":
      if (hasRightIcon) {
        return 12;
      }
      return 16;
    case "small":
      if (hasRightIcon || variant === "septenary") {
        return 8;
      }
      return 12;
    case "xSmall":
      if (hasRightIcon) {
        return 8;
      }
      return 12;
    case "xxSmall":
    case "xxxSmall":
      if (hasRightIcon) {
        return 4;
      }
      return 8;
  }
};

const getLabelMargin = (size: Size, hasIcon?: boolean) => {
  if (!hasIcon) {
    return 0;
  }
  switch (size) {
    case "big":
    case "medium":
      return "10px";
    case "small":
    case "xSmall":
    case "xxSmall":
      return "5px";
    case "xxxSmall":
      return "4px";
  }
};

// Styles

export const useStyles = makeStyles<StyleProps>()((theme, props) => ({
  btn: {
    height: buttonHeight[props.size],
    minWidth: buttonHeight[props.size],
    width: props.ellipsis ? "100%" : "fit-content",
    padding: 1,
    borderRadius: 22,
    flexShrink: 0,
  },
  ellipsis: {
    overflow: "hidden",
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
  },
  content: {
    display: "flex",
    alignItems: "center",
    height: contentHeight[props.size],
    paddingTop: getYPadding(props.variant),
    paddingBottom: getYPadding(props.variant),
    paddingLeft: getLeftContentPadding(
      props.size,
      Boolean(props.LeftIcon),
      Boolean(props.label),
      props.variant
    ),
    paddingRight: getRightContentPadding(
      props.size,
      Boolean(props.RightIcon),
      Boolean(props.label),
      props.variant
    ),
    borderRadius: 22,
  },
  labelContent: {
    marginLeft: getLabelMargin(props.size, Boolean(props.LeftIcon)),
    marginRight: getLabelMargin(props.size, Boolean(props.RightIcon)),
  },
  error: {
    color: `${theme.palette.red500} !important`,
  },
  primary: {
    backgroundImage: theme.palette.gradients.darkPurpleToPurple,
    backgroundColor: theme.palette.darkPurple,
    color: theme.palette.offWhite,
    "&:hover": {
      backgroundImage: "none",
      backgroundColor: theme.palette.darkPurple,
    },
    "&:disabled": {
      backgroundImage: "none",
      backgroundColor: theme.palette.taupe,
      color: theme.palette.midnight500,
    },
  },
  secondary: {
    backgroundImage: theme.palette.gradients.darkPurpleToGreen,
    color: theme.palette.midnight,
    "&:hover": {
      backgroundImage: theme.palette.gradients.blueToGreen,
    },
    "&:disabled": {
      backgroundImage: "none",
      color: theme.palette.midnight500,
      backgroundColor: theme.palette.taupe,
    },
  },
  secondaryContent: {
    backgroundColor: props.disabled ? theme.palette.taupe : theme.palette.ivory,
    width: "100%",
    justifyContent: "center",
  },
  tertiary: {
    backgroundColor: theme.palette.taupe500,
    color: theme.palette.midnight,
    "&:hover": {
      backgroundColor: theme.palette.taupe,
    },
    "&$disabled": {
      color: theme.palette.midnight500,
      backgroundColor: theme.palette.taupe,
    },
  },
  quaternary: {
    borderStyle: "solid",
    borderWidth: 1,
    borderColor: theme.palette.taupe,
    color: theme.palette.midnight,
    backgroundColor: "transparent",
    "&:hover": {
      borderColor: theme.palette.midnight,
      backgroundColor: "transparent",
    },
    "&$disabled": {
      color: theme.palette.midnight500,
      backgroundColor: theme.palette.taupe,
    },
  },
  quinary: {
    color: theme.palette.midnight,
    backgroundColor: "transparent",
    "&:hover": {
      color: theme.palette.darkPigeon,
      backgroundColor: "transparent",
    },
    "&$disabled": {
      color: theme.palette.midnight500,
    },
  },
  senary: {
    backgroundImage: theme.palette.gradients.darkPurpleToGreen,
    color: theme.palette.offWhite,
    "&:hover": {
      backgroundImage: theme.palette.gradients.blueToGreen,
    },
    "&:disabled": {
      backgroundImage: "none",
      color: theme.palette.taupe,
      backgroundColor: theme.palette.midnight500,
    },
  },
  senaryContent: {
    backgroundColor:
      props.disabled || props.loading
        ? theme.palette.midnight500
        : theme.palette.midnight,
    width: "100%",
    justifyContent: "center",
  },
  septenary: {
    backgroundColor: theme.palette.ivory,
    color: theme.palette.midnight,
    border: `1px solid ${theme.palette.greyscale200}`,
    borderRadius: "4px",
    b: { margin: "0 2px" },
    "&:hover": {
      backgroundColor: theme.palette.ivory,
      borderColor: theme.palette.greyscale300,
    },
    "&:disabled": {
      color: theme.palette.darkTaupe,
      backgroundColor: theme.palette.greyscale100,
      border: `1px solid ${theme.palette.greyscale100}`,
    },
  },
  icon: {
    fontSize:
      props.size === "small" ||
      props.size === "xSmall" ||
      props.size === "xxSmall" ||
      props.size === "xxxSmall"
        ? 15
        : 20,
  },
  iconImage: {
    borderRadius: 2,
    margin: "0px !important",
    width:
      props.size === "small" ||
      props.size === "xSmall" ||
      props.size === "xxSmall" ||
      props.size === "xxxSmall"
        ? 15
        : 20,
  },
  loader: {
    color: theme.palette.midnight,
    padding: props.size === "small" ? 0 : 2.5,
  },
  loaderLight: {
    color: theme.palette.taupe,
  },
}));

export default Button;
