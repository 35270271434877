import { AmountFormatter } from "hooks/useAmountFormatter";
import _ from "lodash";
import {
  AdditionalAnalyticsColumns,
  AvailableAnalytisColumns,
} from "redux/analytics/defaults";

export const isPartnerAnalyticMetrics = (fieldName: string, row: any) => {
  return (
    row?.type === "partner_analytics" &&
    (fieldName === AvailableAnalytisColumns.overlaps ||
      fieldName === AvailableAnalytisColumns.newProspectsFromPartner ||
      fieldName === AvailableAnalytisColumns.winRate ||
      fieldName === AvailableAnalytisColumns.dealSize ||
      fieldName === AvailableAnalytisColumns.revenuePotential ||
      fieldName === AvailableAnalytisColumns.commonCustomers ||
      fieldName === AvailableAnalytisColumns.commonOpportunities ||
      fieldName === AvailableAnalytisColumns.opportunitiesPartnerCustomers ||
      fieldName === AvailableAnalytisColumns.myProspectsPartnerCustomers ||
      fieldName === AvailableAnalytisColumns.newProspectsForPartner ||
      fieldName === AvailableAnalytisColumns.myCustomersPartnerProspects ||
      fieldName ===
        AdditionalAnalyticsColumns.opportunitiesPartnerCustomersRevenue ||
      fieldName ===
        AdditionalAnalyticsColumns.myProspectsPartnerCustomersRevenue ||
      fieldName === AdditionalAnalyticsColumns.newProspectsFromPartnerRevenue)
  );
};

export const partnerAnalyticMetricValueFormatter = (
  row: any,
  fieldName: string,
  amountFormatter: AmountFormatter
) => {
  if (
    fieldName === AvailableAnalytisColumns.revenuePotential ||
    fieldName ===
      AdditionalAnalyticsColumns.opportunitiesPartnerCustomersRevenue ||
    fieldName ===
      AdditionalAnalyticsColumns.myProspectsPartnerCustomersRevenue ||
    fieldName === AdditionalAnalyticsColumns.newProspectsFromPartnerRevenue
  ) {
    const value = _.get(row, `metrics.${fieldName}`, "--") ?? "--";
    if (value === 0) return "--";
    if (value !== "--") return amountFormatter(value, "USD");
    return value;
  }
  return _.get(row, `metrics.${fieldName}`, "--") ?? "--";
};
