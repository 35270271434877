import { createSvgIcon } from "@mui/material/utils";

import { SvgIcon } from "./types";

const displayName = "Chat";

const Chat: SvgIcon = createSvgIcon(
  // Using transform to enlarge from provided viewbox="0 0 16 16"
  // to default "0 0 24 24" from `createSvgIcon` -> `SvgIcon`.
  <g fill="none" transform="matrix(1.5,0,0,1.5,0,0)">
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M1.5 3.5C1.5 2.39543 2.39543 1.5 3.5 1.5H12.5C13.6046 1.5 14.5 2.39543 14.5 3.5V10.9615C14.5 12.0661 13.6046 12.9615 12.5 12.9615H9.352C9.13774 12.9615 8.92915 13.0304 8.75695 13.1578L4.92857 15.9923V12.9615H3.5C2.39543 12.9615 1.5 12.0661 1.5 10.9615V3.5ZM3.5 2.5C2.94771 2.5 2.5 2.94771 2.5 3.5V10.9615C2.5 11.5138 2.94771 11.9615 3.5 11.9615H5.92857V14.0077L8.16191 12.3542C8.5063 12.0992 8.92349 11.9615 9.352 11.9615H12.5C13.0523 11.9615 13.5 11.5138 13.5 10.9615V3.5C13.5 2.94772 13.0523 2.5 12.5 2.5H3.5Z"
      fill="currentColor"
    />
  </g>,

  displayName
);

Chat.displayName = displayName;
export default Chat;
