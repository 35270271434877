import { Table, TableCell, TableRow } from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import Paper from "@mui/material/Paper";
import { ClassNameMap } from "@mui/material/styles";
import { T } from "components/ui/Typography";
import muiTheme from "config/theme";
import { makeStyles } from "makeStyles";
import { ComponentType, ReactNode, RefObject, useState } from "react";
import { defineMessages, FormattedMessage } from "react-intl";
import { useMergedClasses } from "tss-react";

import useURIQuery from "../../../hooks/useURIQuery";
import useURISort from "../../../hooks/useURISort";
import InfiniteResource from "../InfiniteResource";
import { ListTableFilters } from "./ListTableFilters";
import ListTableHead from "./ListTableHead";
import ListTableToolbar from "./ListTableToolbar";

type Props = {
  action?: ReactNode;
  classes?: ClassNameMap;
  Component: ComponentType<$TSFixMe>;
  columns: $TSFixMe[];
  defaultFilters?: $TSFixMe;
  defaultSort?: $TSFixMe;
  filters?: $TSFixMe[];
  include?: $TSFixMe[];
  hash?: string;
  relation?: string;
  resource: string;
  filterSelectItems?: $TSFixMe;
  tableFiltersClasses?: ClassNameMap;
  tableHeadClasses?: ClassNameMap;
  title?: {
    id: string;
    defaultMessage: string;
  };
  scrollerRef?: RefObject<HTMLElement> | null;
  fields?: $TSFixMe;
  threshold?: number;
  rowProps?: $TSFixMe;
};

const InfiniteTable = ({
  action,
  classes: customClasses = {},
  Component,
  columns,
  defaultFilters = {},
  defaultSort,
  filters = [],
  fields = {},
  include = [],
  hash,
  relation,
  resource,
  rowProps = {},
  filterSelectItems = {},
  tableFiltersClasses = {},
  tableHeadClasses = {},
  title,
  scrollerRef = null,
  threshold,
}: Props) => {
  const { classes: baseClasses } = useStyles();
  const classes = useMergedClasses(baseClasses, customClasses);
  const [query, handler] = useURIQuery(defaultFilters);
  const [order, orderBy, sortHandler] = useURISort(defaultSort);
  const [scrollState, setScrollState] = useState({
    count: null,
    loading: false,
  });

  const loader = (
    <TableRow key="loader">
      <TableCell colSpan={columns.length} align="center">
        <div style={{ display: "flex", justifyContent: "center" }}>
          <CircularProgress size={25} />
        </div>
      </TableCell>
    </TableRow>
  );

  const empty = (
    <TableRow key="loader">
      <TableCell colSpan={columns.length} align="center">
        <FormattedMessage {...i18n.emptyMessage} />
      </TableCell>
    </TableRow>
  );

  return (
    <Paper className={classes.root}>
      <div className={classes.tableWrapper}>
        {(title || action) && (
          <ListTableToolbar
            action={action}
            title={
              <T color={muiTheme.palette.white}>
                <FormattedMessage {...title} />
                {scrollState.count !== null && (
                  <span className={classes.count}>
                    <FormattedMessage {...i18n.count} values={scrollState} />
                  </span>
                )}
              </T>
            }
          />
        )}
        {filters.length > 0 && (
          <ListTableFilters
            tableFilters={filters}
            query={query}
            handleChange={handler}
            selectFilterItems={filterSelectItems}
            classes={tableFiltersClasses}
            resource={resource}
          />
        )}
        <Table className={classes.table}>
          <ListTableHead
            values={columns}
            sortableColums={columns
              .filter((col) => col.sortable)
              .map((col) => col.id)}
            order={order || ""}
            orderBy={orderBy || ""}
            onRequestSort={(_: $TSFixMe, newSort: $TSFixMe) => {
              const newOrder =
                newSort !== orderBy ? "asc" : order === "desc" ? "asc" : "desc";
              sortHandler(newOrder, newSort);
            }}
            classes={tableHeadClasses}
          />
          <InfiniteResource
            Component={Component}
            callback={setScrollState}
            element="tbody"
            empty={empty}
            filters={query}
            fields={fields}
            include={include}
            hash={hash}
            loader={loader}
            relation={relation}
            resource={resource}
            size={25}
            sort={{ orderBy, order }}
            style={{ fontSize: 12 }}
            scrollerRef={scrollerRef}
            threshold={threshold}
            {...rowProps}
          />
        </Table>
      </div>
    </Paper>
  );
};

export default InfiniteTable;

// I18N

const i18n = defineMessages({
  count: {
    id: "infiniteTable.count",
    defaultMessage:
      "({count, plural, =0 {No records matching filters} one {1 record found} other {# records found}})",
  },
  emptyMessage: {
    id: "infiniteTable.emptyMessage",
    defaultMessage: "No records match your search criteria",
  },
});

// CSS

const useStyles = makeStyles()((theme) => ({
  root: {
    backgroundClip: "border-box",
    backgroundColor: theme.palette.grey500,
    fontSize: 12,
  },
  tableWrapper: {
    overflowX: "auto",
    marginBottom: 1,
    backgroundColor: theme.palette.ivory,
  },
  table: {
    minWidth: 700,
  },
  count: {
    fontSize: 12,
    margin: theme.spacing(1),
  },
}));
