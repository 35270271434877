import { Grid, Hidden, Tooltip } from "@mui/material";
import { CellProps } from "components/ui/data-grid/utils";
import { T } from "components/ui/Typography";
import { makeStyles } from "makeStyles";
import { FormattedMessage } from "react-intl";
import { AvailableDirectoryColumns } from "redux/directory/defaults";
import { overlapToDisplayContent } from "screens/Frontoffice/shared/helpers/overlapToDisplayContent";

export const ContactOverlapCell = ({ row }: CellProps) => {
  const { classes } = useStyles();
  const { contactOverlap } = row;
  const contactOverlapContent = overlapToDisplayContent(contactOverlap);

  return (
    <div className={classes.cellContainer}>
      {contactOverlapContent.icon && (
        <Tooltip title={""}>
          <Grid container className={classes.contactOverlapContainer}>
            <Hidden mdDown>
              <Grid item>
                <contactOverlapContent.icon />
              </Grid>
            </Hidden>
            <Grid item>
              <T bold>
                <FormattedMessage {...contactOverlapContent.text} />
              </T>
            </Grid>
          </Grid>
        </Tooltip>
      )}
    </div>
  );
};

export const isDirectoryContactOverlapCell = ({ fieldName }: CellProps) => {
  return fieldName === AvailableDirectoryColumns.contactOverlap;
};

// CSS

const useStyles = makeStyles()((theme) => ({
  cellContainer: {
    //
  },
  contactOverlapContainer: {
    alignItems: "end",
    gap: theme.spacing(1),
    marginRight: theme.spacing(2),
    paddingBottom: theme.spacing(1),
  },
}));
