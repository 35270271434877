import Grid from "@mui/material/Grid";
import withRecord, { WithRecord } from "components/hoc/withRecord";
import Button from "components/ui/Button";
import { T } from "components/ui/Typography";
import useAllRecords from "hooks/useAllRecords";
import { makeStyles } from "makeStyles";
import Integration from "models/Integration";
import { useCallback, useEffect, useState } from "react";
import { FormattedRelativeTime } from "react-intl";
import { useHistory } from "react-router-dom";
import { AdminPage } from "screens/Backoffice/shared/components/AdminPage";
import JSONAPIService from "services/JSONAPIService";

type Props = WithRecord<Integration>;

const includes = ["company", "statistics"];
const fields = {
  integrations: [
    "company_id",
    "company",
    "name",
    "provider",
    "deleted_at",
    "created_at",
    "updated_at",
    "enabled_data_sync",
    "provider_key",
    "partnership_integrations",
    "statistics",
    "last_sync_started_at",
    "ghost_partnership_id",
    "mapping_configured",
    "use_synchronizer",
    "last_sync_started_at",
    "partnership_integrations",
    "statistics",
    "ghost_partnership_id",
    "use_synchronizer",
  ],
};

const synchronize = async (integration: Integration, full: boolean = true) => {
  const service = new JSONAPIService("integrations");
  await service.rawPost(
    integration.id,
    "/synchronize/",
    { full },
    { fields, include: includes }
  );
};

const SYNC_DELTA = 60 * 60;

const timeToEnable = (integration: Integration) => {
  const lastSyncStartedAt = integration.lastSyncStartedAt;
  return lastSyncStartedAt === null
    ? 0
    : SYNC_DELTA - (Date.now() - new Date(lastSyncStartedAt).getTime()) / 1000;
};

const IntegrationPage = ({ record: integration }: Props) => {
  const { classes } = useStyles();
  const [canTriggerIn, setCanTriggerIn] = useState(timeToEnable(integration));
  const [triggeringSync, setTriggeringSync] = useState(false);
  const history = useHistory();

  const tooLargeForManualSync =
    integration.statistics?.numberOfAccounts > 1000000;

  const triggerPartialSync = useCallback(async () => {
    setTriggeringSync(true);
    try {
      await synchronize(integration, false);
      setCanTriggerIn(SYNC_DELTA);
    } finally {
      setTriggeringSync(false);
    }
  }, [integration]);

  useEffect(() => {
    if (canTriggerIn > 0) {
      const handle = setTimeout(() => {
        setCanTriggerIn(timeToEnable(integration));
      }, 60 * 1000);
      return () => {
        clearTimeout(handle);
      };
    }
  }, [integration, canTriggerIn]);

  const { records: rules } = useAllRecords("mapping_rules", {
    filters: {
      integration: integration.id,
    },
  });
  const handleRedirectDataSources = () => {
    history.push(
      `/backoffice/companies/${integration.company.id}/data-sources`
    );
  };

  return (
    <AdminPage>
      <div className={classes.root}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <T h2>
              Integration {integration.company.name} / {integration.provider}{" "}
              (ID:&nbsp;
              {integration.id})
            </T>
          </Grid>
          <Grid item xs={12}>
            <T h3>Statistics</T>
          </Grid>
          <Grid item>
            <T bodySmall>
              Number of Accounts: {integration.statistics?.numberOfAccounts}
            </T>
            <T bodySmall>
              Number of Customers: {integration.statistics?.numberOfCustomers}
            </T>
            <T bodySmall>
              Number of Prospects: {integration.statistics?.numberOfProspects}
            </T>
            <T bodySmall>
              Number of Prospects w/ Open Opportunity:{" "}
              {integration.statistics?.numberOfProspectsWithOpportunity}
            </T>
            <T bodySmall>
              Number of Partners: {integration.statistics?.numberOfPartners}
            </T>
            <T bodySmall>
              Number of Opportunities:{" "}
              {integration.statistics?.numberOfOpportunities}
            </T>
            <T bodySmall>
              Number of Contacts: {integration.statistics?.numberOfContacts}
            </T>
          </Grid>
          <Grid item xs={12}>
            <T h3>Status Rules</T>
          </Grid>
          <Grid item>
            {rules.map((rule) => (
              <T bodySmall>
                {rule.recordType}/{rule.attribute}/{String(rule.value)}:{" "}
                {rule.swql}
              </T>
            ))}
          </Grid>
          <Grid item xs={12}>
            <Button label="Data sources" onClick={handleRedirectDataSources} />
          </Grid>
          <Grid item xs={12}>
            <T h3>Synchronization</T>
          </Grid>
          <Grid item display="flex" gap={1} alignItems="center">
            <Button
              label="Synchronize"
              onClick={triggerPartialSync}
              disabled={canTriggerIn > 0 || tooLargeForManualSync}
              loading={triggeringSync}
            />
            {tooLargeForManualSync && (
              <T bodySmall>Too large for manual sync</T>
            )}
            {!tooLargeForManualSync && canTriggerIn > 0 && (
              <T bodySmall>
                <FormattedRelativeTime
                  value={Math.round(canTriggerIn / 60)}
                  unit="minute"
                  format="short"
                />
              </T>
            )}
          </Grid>
        </Grid>
      </div>
    </AdminPage>
  );
};

export default withRecord<Integration, Props>("integrations", {
  include: includes,
  fields,
})(IntegrationPage);

// CSS

const useStyles = makeStyles()((theme) => ({
  root: {
    backgroundColor: theme.palette.ivory,
    borderRadius: "10px",
    margin: theme.spacing(3),
    padding: theme.spacing(3),
  },
}));
