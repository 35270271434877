import { ChevronDown } from "components/icons";
import useSelectStyles from "hooks/useSelectStyles";
import Select, { components } from "react-select";

const DropdownIndicator = (props: $TSFixMe) => {
  return (
    <components.DropdownIndicator {...props}>
      <ChevronDown />
    </components.DropdownIndicator>
  );
};

type FiltersSelectFieldProps = {
  isDisabled?: boolean;
  options: $TSFixMe[];
  handleChange: $TSFixMeFunction;
  value?: $TSFixMe[];
  isMulti?: boolean;
  contained?: boolean;
  placeholder?: string;
};

const FiltersSelectField = ({
  isDisabled,
  options,
  handleChange,
  value = [],
  isMulti = false,
  placeholder = "",
  contained = false,
  ...props
}: FiltersSelectFieldProps) => {
  const selectStyles = useSelectStyles({ contained, alignRight: true });

  const onChange = (data: $TSFixMe) => {
    if (isMulti) {
      handleChange(
        data === null ? [] : data.map((option: $TSFixMe) => option.value)
      );
    } else {
      handleChange(data.value);
    }
  };

  const filterByLabel = (option: $TSFixMe, searchText: $TSFixMe) => {
    if (option.data && option.data.textLabel) {
      if (
        option.data.textLabel.toLowerCase().includes(searchText.toLowerCase())
      ) {
        return true;
      } else {
        return false;
      }
    }
    if (option.label) {
      let label = option.label;
      if (label.props) {
        label = label.props.defaultMessage;
      }
      if (label?.toLowerCase()?.includes(searchText.toLowerCase())) {
        return true;
      } else {
        return false;
      }
    }
    return true;
  };

  return (
    <Select
      isDisabled={isDisabled}
      isMulti={isMulti}
      options={options}
      value={value}
      placeholder={placeholder}
      menuPortalTarget={document.body}
      onChange={onChange}
      filterOption={filterByLabel}
      styles={selectStyles}
      components={{
        DropdownIndicator,
        IndicatorSeparator: () => null,
        ClearIndicator: () => null,
      }}
      {...props}
    />
  );
};

export default FiltersSelectField;
