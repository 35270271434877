import _ from "lodash";
import { ReactNode } from "react";
import {
  FieldType,
  FilterType,
  MatchField,
  MatchFieldType,
  SortType,
} from "screens/Frontoffice/screens/DataTables/shared/types";
import {
  getHeaderPartnerName,
  isAccountMappingRightColumn,
  isMyAccountsRightColumn,
} from "screens/Frontoffice/screens/DataTables/shared/utils";

import PartnerNameChip from "../data-grid/PartnerNameChip";
import Tooltip from "../Tooltip";

export type ColumnOption = {
  value: string;
  label: JSX.Element;
  type: MatchFieldType;
  textLabel: string;
  isDisabled: boolean;
  partnerField: boolean;
};

export const getAvailableColumOptions = (
  partnerName: $TSFixMe,
  fields: { [key: string]: FieldType },
  filterValues: (FilterType | SortType)[],
  currentColumn: FilterType | SortType | null = null,
  widgetType: string = "filter",
  defaultValues?: (FilterType | SortType)[],
  defaultValueTooltip?: ReactNode
): ColumnOption[] => {
  const unavailableKeys = _.clone(
    currentColumn
      ? filterValues.filter(
          (el: FilterType | SortType) =>
            el.fieldname !== currentColumn.fieldname
        )
      : filterValues
  )
    .filter(
      (item) =>
        !defaultValues?.some(
          (defaultValue) => defaultValue.fieldname === item.fieldname
        )
    )
    .map(_.property<FilterType | SortType, string>("fieldname"))
    .concat(
      _.toPairs(fields)
        .filter(
          (item) =>
            (!item[1].smartField && !item[1].fullyImported) ||
            !MatchField.canFilterOrSort(item[1].type) ||
            !_.get(item[1], widgetType, true)
        )
        .map((item) => item[0])
    );

  const availableKeys: string[] = _.difference<string>(
    Object.keys(fields ?? {}),
    unavailableKeys
  );

  const options = availableKeys
    .map((key) => {
      const field = fields[key];
      return {
        value: key,
        label: (
          <Tooltip
            placement="right"
            title={
              !!defaultValues?.some(
                (defaultValue) => defaultValue.fieldname === key
              ) && defaultValueTooltip
            }
          >
            <div>
              {field.label}{" "}
              {!field.noSide && (
                <PartnerNameChip
                  partnerName={getHeaderPartnerName(key, partnerName)}
                />
              )}
            </div>
          </Tooltip>
        ),
        type: field.type,
        textLabel: field.label,
        isDisabled:
          field.isDisabled ||
          !!defaultValues?.some(
            (defaultValue) => defaultValue.fieldname === key
          ),
        partnerField: field.partnerField,
      };
    })
    .sort((a, b) => {
      const isRight = (field: {
        value: string;
        label: JSX.Element;
        partnerField: boolean;
        textLabel: string;
        type: MatchFieldType;
        isDisabled: boolean;
      }) => {
        return (
          isMyAccountsRightColumn(field.value) ||
          isAccountMappingRightColumn(field.value)
        );
      };
      if (isRight(a) === isRight(b)) {
        return a.textLabel.localeCompare(b.textLabel);
      }
      if (isRight(a)) {
        return 1;
      }
      return -1;
    });
  return options;
};
