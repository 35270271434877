import { Box } from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import { ChevronDown, ChevronTop } from "components/icons";
import CompanyAvatar from "components/ui/avatars/CompanyAvatar";
import Dropdown from "components/ui/Dropdown";
import { IDropdownOption } from "components/ui/Dropdown/components/types";
import DropdownSubmitFooter from "components/ui/DropdownSubmitFooter";
import { T } from "components/ui/Typography";
import useSelectorWithDeepEquality from "hooks/useSelectorWithDeepEquality";
import useUserProfile from "hooks/useUserProfile";
import _ from "lodash";
import { makeStyles } from "makeStyles";
import Partnership from "models/Partnership";
import { MouseEvent, useEffect, useState } from "react";
import { defineMessages, FormattedMessage } from "react-intl";
import { useSelector } from "react-redux";
import {
  selectActivePartnerships,
  selectPartnershipsReady,
} from "redux/init/selectors";

type Props = {
  selectedPartners: Partnership[];
  selectPartners: (value: Partnership[]) => void;
  disabled: boolean;
};

const PartnerSelector = ({
  selectedPartners,
  selectPartners,
  disabled,
}: Props) => {
  const [open, setOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const [options, setOptions] = useState<IDropdownOption[]>([]);
  const [selectedOptions, setSelectedOptions] = useState<string[] | null>([]);
  const { profile } = useUserProfile();

  const acceptedAndActivePartnerships = useSelectorWithDeepEquality(
    selectActivePartnerships
  );
  const partnershipReady = useSelector(selectPartnershipsReady);
  const loading = !partnershipReady;

  const disableDropdown = loading || disabled;
  const { classes } = useStyles({ disableDropdown });

  useEffect(() => {
    setOptions(
      acceptedAndActivePartnerships.map((partnership) => {
        const partner = partnership.getPartner(profile);
        return {
          name: partner.name,
          logo: partner.avatarUrl ?? null,
          id: partnership.id.toString(),
        };
      })
    );
  }, [acceptedAndActivePartnerships, profile]);

  const handleOpenDropdown = (event: MouseEvent<HTMLDivElement>) => {
    if (disableDropdown) {
      return;
    }
    setAnchorEl(event.currentTarget);
    selectCheckboxes();
    setOpen(!open);
  };

  const sameSelection = _.isEqual(
    selectedOptions,
    selectedPartners.map((partner) => partner.id.toString())
  );

  const handleCloseDropdown = () => {
    setOpen(false);
    setAnchorEl(null);
    selectCheckboxes();
  };

  const selectCheckboxes = () => {
    setSelectedOptions(
      selectedPartners.map((partner) => partner.id.toString())
    );
  };

  const handleSubmit = () => {
    let partners = acceptedAndActivePartnerships.filter(
      (value) =>
        selectedOptions === null ||
        selectedOptions.includes(value.id.toString())
    );
    selectPartners(partners);
    handleCloseDropdown();
  };

  return (
    <>
      <div onClick={handleOpenDropdown} className={classes.btn}>
        {selectedPartners.length === 0 ? (
          <T className={classes.label}>
            <FormattedMessage {...i18n.placeholder} />
          </T>
        ) : (
          <Box className={classes.partnersBox}>
            {selectedPartners.map((partnership, k) => {
              const partner = partnership.getPartner(profile);
              return (
                <div className={classes.partnerChip} key={k}>
                  <CompanyAvatar
                    companyName={partner.name}
                    src={partner.avatarUrl ?? null}
                    size="xs"
                  />
                  <T className={classes.partnerName}>{partner.name}</T>
                </div>
              );
            })}
          </Box>
        )}
        {disableDropdown ? (
          <CircularProgress size={24} color="primary" />
        ) : open ? (
          <ChevronTop />
        ) : (
          <ChevronDown />
        )}
      </div>
      <Dropdown
        options={options}
        anchorEl={anchorEl}
        onClose={handleCloseDropdown}
        open={Boolean(anchorEl)}
        withSearch
        size="inherit"
        withCheckBox
        value={selectedOptions}
        nullValueMeansAllSelected
        onChange={(ids) => setSelectedOptions(ids)}
        isMultiselect
        allowSelectAll
        CustomFooter={
          <DropdownSubmitFooter
            onApply={handleSubmit}
            onCancel={handleCloseDropdown}
            disabledApplyBtn={sameSelection}
          />
        }
      />
    </>
  );
};

export default PartnerSelector;

// I18N

const i18n = defineMessages({
  placeholder: {
    id: "DataTables.ActionBtn.ReferOption.ReferDialog.placeholder",
    defaultMessage: "Select partner(s)",
  },
});

// CSS

const useStyles = makeStyles<{ disableDropdown: boolean }>()(
  (theme, { disableDropdown }) => ({
    btn: {
      padding: "8px 16px",
      borderRadius: 18,
      minHeight: 36,
      backgroundColor: theme.palette.taupe500,
      cursor: disableDropdown ? "default" : "pointer",
      display: "flex",
      justifyContent: "space-between",
      color: theme.palette.midnight500,
      "&:hover": {
        color: disableDropdown
          ? theme.palette.midnight500
          : theme.palette.midnight,
      },
    },
    label: {
      marginTop: 3,
    },
    partnersBox: {
      display: "flex",
      flexWrap: "wrap",
      rowGap: "4px",
      maxHeight: "98px",
      overflow: "scroll",
    },
    partnerChip: {
      display: "flex",
      flexWrap: "nowrap",
    },
    partnerName: {
      margin: "4px 8px 2px 6px",
      color: theme.palette.midnight,
    },
  })
);
