import ArrowForwardRoundedIcon from "@mui/icons-material/ArrowForwardRounded";
import CheckRoundedIcon from "@mui/icons-material/CheckRounded";
import StarIcon from "@mui/icons-material/Star";
import { T } from "components/ui/Typography";
import { makeStyles } from "makeStyles";
import {
  MatchFieldType,
  MatchSortOrder,
} from "screens/Frontoffice/screens/DataTables/shared/types";

type Props = {
  type: MatchFieldType;
  direction: MatchSortOrder;
  selected: boolean;
  fieldname?: string;
};

const OrderIcon = ({ type, direction, selected, fieldname }: Props) => {
  const { classes } = useStyles({ selected });

  let firstIcon, secondIcon;
  switch (type) {
    case MatchFieldType.BOOLEAN:
      if (fieldname === "leftStarred") {
        firstIcon = <StarIcon className={classes.icon} />;
      } else {
        firstIcon = <CheckRoundedIcon className={classes.icon} />;
      }
      secondIcon = "--";
      break;
    case MatchFieldType.INTEGER:
    case MatchFieldType.NUMBER:
    case MatchFieldType.DATE:
    case MatchFieldType.DATETIME:
      firstIcon = "1";
      secondIcon = "9";
      break;
    case MatchFieldType.CURRENCY:
      firstIcon = "$";
      secondIcon = "$$$";
      break;
    default:
      firstIcon = "A";
      secondIcon = "Z";
  }

  return (
    <T oldVariant="body1_fontSize:12px" className={classes.text}>
      {direction === MatchSortOrder.ORDER_ASC ? firstIcon : secondIcon}
      <ArrowForwardRoundedIcon className={classes.arrow} />
      {direction === MatchSortOrder.ORDER_ASC ? secondIcon : firstIcon}
    </T>
  );
};

export default OrderIcon;

// CSS

const useStyles = makeStyles<{ selected: boolean }>()(
  (theme, { selected }) => ({
    text: {
      display: "flex",
      alignItems: "center",
      height: 20,
      color: selected
        ? theme.palette.white
        : // @ts-expect-error ts-migrate(2339) FIXME: Property 'dark' does not exist on type 'Color'.
          theme.palette.grey.dark,
    },
    arrow: {
      height: 12,
    },
    icon: {
      height: 12,
      width: 12,
    },
  })
);
