import {
  DATA_EXPORT_LIMIT,
  MAPPING_360_ROWS_LIMIT,
  SHARED_PIPELINE_LIMIT,
  SMART_VIEW_FREE_LIMIT,
  SMART_VIEW_PRO_LIMIT,
  WEEKLY_DIGEST_LIMIT,
} from "config/constants";
import { PayingFeature } from "models/CompanyPayingFeatureSubscription";
import { defineMessages, MessageDescriptor } from "react-intl";

/// I18N

const i18n = defineMessages({
  mappingFeature: {
    id: "navbar.PremiumPlanWidget.constants.mappingFeature",
    defaultMessage: "360 Mapping",
  },
  unlimitedWeeklyDigest: {
    id: "navbar.PremiumPlanWidget.constants.unlimitedWeeklyDigest",
    defaultMessage: "Weekly digest",
  },
  offlineAccountMappingFeature: {
    id: "navbar.PremiumPlanWidget.constants.offlineAccountMappingFeature",
    defaultMessage: "Offline Account Mappings",
  },
  pipelinesFeature: {
    id: "navbar.PremiumPlanWidget.constants.pipelinesFeature",
    defaultMessage: "Collaborate",
  },
  dataExport: {
    id: "navbar.PremiumPlanWidget.constants.dataExport",
    defaultMessage: "Data export",
  },
  newProspectFeature: {
    id: "navbar.PremiumPlanWidget.constants.newProspectFeature",
    defaultMessage: "New prospects",
  },
  advancedAnalytics: {
    id: "navbar.PremiumPlanWidget.constants.advancedAnalytics",
    defaultMessage: "Advanced Analytics",
  },
  crmWidget: {
    id: "navbar.PremiumPlanWidget.constants.crmWidget",
    defaultMessage: "CRM Widget",
  },
  slackIntegration: {
    id: "navbar.PremiumPlanWidget.constants.slackIntegration",
    defaultMessage: "Slack Integration",
  },
  ssoFeature: {
    id: "navbar.PremiumPlanWidget.constants.sso",
    defaultMessage: "SSO",
  },
  apiAndPrmIntegrationsFeature: {
    id: "navbar.PremiumPlanWidget.constants.apiAndPrmIntegrationsFeature",
    defaultMessage: "API & Integrations",
  },
  crmCustomObjects: {
    id: "navbar.PremiumPlanWidget.constants.crmCustomObjects",
    defaultMessage: "CRM custom objects",
  },
  csmFeature: {
    id: "navbar.PremiumPlanWidget.constants.csmFeature",
    defaultMessage: "Dedicated CSM",
  },
  smartViewsFeature: {
    id: "navbar.PremiumPlanWidget.constants.smartViewsFeature",
    defaultMessage: "Saved Views",
  },
});

/// Premium Feature Presets

export type RuleOperator = "and" | "or";

export type PremiumFeatureRule = {
  featureKeys: string[];
  operator: RuleOperator;
};

export interface FeaturePreset {
  name: MessageDescriptor;
  featureKey: PayingFeature;
  companyAttr?: string;
  isProgressive: boolean;
  limitFunction?: (args: any) => number;
  limitValue?: number;
  cannotExceedLimit?: boolean;
  rule?: PremiumFeatureRule;
}

const my360MappingUnlocked: FeaturePreset = {
  name: i18n.mappingFeature,
  featureKey: PayingFeature.My360MappingUnlocked,
  isProgressive: false,
  limitValue: MAPPING_360_ROWS_LIMIT,
  rule: {
    featureKeys: [
      PayingFeature.My360MappingUnlocked,
      PayingFeature.Limit360MappingByPartnerships,
    ],
    operator: "or",
  },
};
const weeklyDigest: FeaturePreset = {
  name: { ...i18n.unlimitedWeeklyDigest },
  featureKey: PayingFeature.UnlimitedWeeklyDigestContent,
  isProgressive: false,
  limitValue: WEEKLY_DIGEST_LIMIT,
};
const unlimitedPipelineSharing: FeaturePreset = {
  name: { ...i18n.pipelinesFeature },
  featureKey: PayingFeature.UnlimitedPipelineSharing,
  companyAttr: "nbSharedPipelines",
  isProgressive: true,
  limitValue: SHARED_PIPELINE_LIMIT,
};
const unlimitedRowsExport: FeaturePreset = {
  name: { ...i18n.dataExport },
  featureKey: PayingFeature.UnlimitedRowsExport,
  isProgressive: false,
  limitValue: DATA_EXPORT_LIMIT,
};
const exportNewProspect: FeaturePreset = {
  name: { ...i18n.newProspectFeature },
  featureKey: PayingFeature.ExportNewProspectToCSV,
  isProgressive: false,
  rule: {
    featureKeys: [
      PayingFeature.ExportNewProspectToCSV,
      PayingFeature.ExportNewProspectToCRM,
    ],
    operator: "or",
  },
};
const advancedAnalytics: FeaturePreset = {
  name: { ...i18n.advancedAnalytics },
  featureKey: PayingFeature.AdvancedAnalytics,
  isProgressive: false,
};
const crmWidget: FeaturePreset = {
  name: { ...i18n.crmWidget },
  featureKey: PayingFeature.APIAccess,
  isProgressive: false,
};
const slackIntegration: FeaturePreset = {
  name: { ...i18n.slackIntegration },
  featureKey: PayingFeature.SlackIntegration,
  isProgressive: false,
};
const crmCustomObjects: FeaturePreset = {
  name: { ...i18n.crmCustomObjects },
  featureKey: PayingFeature.DataPackage,
  isProgressive: false,
};
const apiAndPrmIntegrations: FeaturePreset = {
  name: { ...i18n.apiAndPrmIntegrationsFeature },
  featureKey: PayingFeature.DataPackage,
  isProgressive: false,
};
const csmFeature: FeaturePreset = {
  name: { ...i18n.csmFeature },
  featureKey: PayingFeature.DataPackage,
  isProgressive: false,
};
const smartViewsProFeature: FeaturePreset = {
  name: { ...i18n.smartViewsFeature },
  featureKey: PayingFeature.SavedFiltersPRO,
  isProgressive: false,
  limitFunction: (hasPro: boolean) =>
    hasPro ? SMART_VIEW_PRO_LIMIT : SMART_VIEW_FREE_LIMIT,
  rule: {
    featureKeys: [
      PayingFeature.SavedFiltersPRO,
      PayingFeature.SavedFiltersPOWER,
    ],
    operator: "and",
  },
};

export const proList = [
  my360MappingUnlocked,
  smartViewsProFeature,
  weeklyDigest,
  unlimitedPipelineSharing,
  unlimitedRowsExport,
  exportNewProspect,
  advancedAnalytics,
  crmWidget,
  slackIntegration,
];

export const powerList = [apiAndPrmIntegrations, crmCustomObjects, csmFeature];

export const allFeaturesList = [...proList, ...powerList];
