import { createSvgIcon } from "@mui/material/utils";

import { SvgIcon } from "./types";

const displayName = "Person";

const Person: SvgIcon = createSvgIcon(
  // Using transform to enlarge from provided viewbox="0 0 16 16"
  // to default "0 0 24 24" from `createSvgIcon` -> `SvgIcon`.
  <g fill="none" transform="matrix(1.5,0,0,1.5,0,0)">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.00008 6.99998C9.28875 6.99998 10.3334 5.95531 10.3334 4.66665C10.3334 3.37798 9.28875 2.33331 8.00008 2.33331C6.71142 2.33331 5.66675 3.37798 5.66675 4.66665C5.66675 5.95531 6.71142 6.99998 8.00008 6.99998ZM8.00008 7.99998C9.84103 7.99998 11.3334 6.5076 11.3334 4.66665C11.3334 2.8257 9.84103 1.33331 8.00008 1.33331C6.15913 1.33331 4.66675 2.8257 4.66675 4.66665C4.66675 6.5076 6.15913 7.99998 8.00008 7.99998Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6.00008 10.5C4.43527 10.5 3.16675 11.7685 3.16675 13.3333V14C3.16675 14.2761 2.94289 14.5 2.66675 14.5C2.39061 14.5 2.16675 14.2761 2.16675 14V13.3333C2.16675 11.2162 3.88299 9.5 6.00008 9.5H10.0001C12.1172 9.5 13.8334 11.2162 13.8334 13.3333V14C13.8334 14.2761 13.6096 14.5 13.3334 14.5C13.0573 14.5 12.8334 14.2761 12.8334 14V13.3333C12.8334 11.7685 11.5649 10.5 10.0001 10.5H6.00008Z"
      fill="currentColor"
    />
  </g>,
  displayName
);

Person.displayName = displayName;
export default Person;
