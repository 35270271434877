import { Close as CloseIcon, Open as OpenIcon } from "components/icons";
import Tooltip from "components/ui/Tooltip";
import { makeStyles } from "makeStyles";
import RawOpportunity, { Status } from "models/RawOpportunity";
import { MouseEvent, useCallback } from "react";
import { defineMessages, FormattedMessage } from "react-intl";

import { FieldDisplay } from "./FieldDisplay";
import { RawOpportunityField } from "./types";

type RawOpportunityDisplayProps = {
  opportunity: RawOpportunity;
  fields: RawOpportunityField[];
  disableDelete?: boolean;
  hideGoToOpportunityUrl?: boolean;
  onClickDelete?: (opportunity: RawOpportunity) => void;
  hideActions?: boolean;
  fromPipeline?: boolean;
};

export const RawOpportunityDisplay = ({
  opportunity,
  fields,
  disableDelete,
  hideGoToOpportunityUrl,
  onClickDelete,
  hideActions,
  fromPipeline = false,
}: RawOpportunityDisplayProps) => {
  const status = opportunity.getStatus ? opportunity.getStatus() : Status.Lost;
  const { classes, cx } = useOpportunityStyles({
    status,
    numberOfFields: fields.length,
    hideGoToOpportunityUrl: Boolean(hideGoToOpportunityUrl || hideActions),
    hasDeleteButton: Boolean(onClickDelete && !hideActions),
  });

  // Mutates in-place;
  fields.sort(
    (fieldLeft, fieldRight) => fieldLeft.displayIndex - fieldRight.displayIndex
  );

  return (
    <div className={classes.container}>
      <div
        className={cx(classes.innerContainer, {
          [classes.innerContainerPipeline]: fromPipeline,
        })}
      >
        <div className={classes.statusIndicator} />
        <div
          className={cx(classes.flexAlignCenter, classes.fieldsWrapper, {
            [classes.fieldsWrapperPipeline]: fromPipeline,
          })}
        >
          {fields.map((field) => (
            <FieldDisplay
              key={field.label}
              {...{ rawOpportunityField: true, ...field }}
              numberOfFields={fields.length}
            />
          ))}
        </div>

        {!hideActions && (
          <RawOpportunityActions
            opportunity={opportunity}
            hideGoToOpportunityUrl={hideGoToOpportunityUrl}
            disableDelete={disableDelete}
            onClickDelete={onClickDelete}
          />
        )}
      </div>
    </div>
  );
};

type StyleProps = {
  status: Status;
  numberOfFields: number;
  hideGoToOpportunityUrl?: boolean;
  hasDeleteButton: boolean;
};

const useOpportunityStyles = makeStyles<StyleProps>()(
  (
    theme,
    { numberOfFields: n, hasDeleteButton, hideGoToOpportunityUrl, status }
  ) => ({
    container: {
      // This is necessary to make the component have the width of its children.
      display: "inline-block",
      alignSelf: "start",
    },
    innerContainer: {
      display: "flex",
      alignItems: "center",
      borderRadius: 6,
      padding: "4px 0 4px 4px",
      border: `1px solid ${theme.palette.greyscale250}`,
      justifyContent: "space-between",
      "&:hover": {
        backgroundColor: theme.palette.ivory,
      },
    },
    innerContainerPipeline: {
      padding: "4px 0 4px 4px",
      border: `4px solid ${theme.palette.white}`,
      width: 333,
    },
    flexAlignCenter: {
      display: "flex",
      alignItems: "center",
      gap: "4px",
    },
    fieldsWrapper: {
      display: "flex",
      alignItems: "center",
      width:
        (n === 1 ? 100 : n === 2 ? 150 : n === 3 ? 210 : 260) +
        (hasDeleteButton ? 0 : 28) +
        (hideGoToOpportunityUrl ? 22 : 0),
    },
    fieldsWrapperPipeline: {
      width:
        300 - (hasDeleteButton ? 30 : 0) - (hideGoToOpportunityUrl ? 0 : 25),
    },
    statusIndicator: {
      width: 4,
      height: 24,
      borderRadius: 2,
      marginRight: theme.spacing(1),
      backgroundColor:
        status === Status.Open
          ? theme.palette.purple
          : status === Status.Won
          ? theme.palette.appGreen
          : theme.palette.darkTaupe,
    },
  })
);

// Delete/Close opportunity button

type RawOpportunityActionsProps = {
  opportunity: RawOpportunity;
  disableDelete?: boolean;
  hideGoToOpportunityUrl?: boolean;
  onClickDelete?: (opportunity: RawOpportunity) => void;
};

export const RawOpportunityActions = ({
  opportunity,
  hideGoToOpportunityUrl,
  disableDelete,
  onClickDelete,
}: RawOpportunityActionsProps) => {
  const { classes, cx } = useRawOpportunityActionsStyles({ disableDelete });

  const deleteOpportunity = useCallback(
    (event: MouseEvent) => {
      event.stopPropagation();
      event.preventDefault();
      return onClickDelete ? onClickDelete(opportunity) : undefined;
    },
    [onClickDelete, opportunity]
  );

  const handleStopPropagation = (event: MouseEvent) => {
    event.stopPropagation();
  };

  return (
    <div className={cx(classes.flexAlignCenter, classes.iconsWrapper)}>
      {!hideGoToOpportunityUrl && opportunity.url && (
        <a
          target="_blank"
          href={opportunity.url}
          rel="noopener noreferrer"
          className={cx(classes.flexAlignCenter, classes.openUrlAnchor)}
          onClick={handleStopPropagation}
        >
          <OpenIcon className={classes.openIcon} />
        </a>
      )}
      {onClickDelete && (
        <Tooltip
          title={
            disableDelete ? <FormattedMessage {...i18n.unlinkDisabled} /> : ""
          }
        >
          <div className={classes.deleteIconWrapper}>
            <CloseIcon
              className={classes.deleteIcon}
              onClick={disableDelete ? undefined : deleteOpportunity}
              data-testid="delete-icon"
            />
          </div>
        </Tooltip>
      )}
    </div>
  );
};

type RawOpportunityActionsStyleProps = {
  disableDelete?: boolean;
};

const useRawOpportunityActionsStyles = makeStyles<RawOpportunityActionsStyleProps>()(
  (theme, { disableDelete }) => ({
    flexAlignCenter: {
      display: "flex",
      alignItems: "center",
      gap: "4px",
    },
    iconsWrapper: {
      marginLeft: 10,
    },
    openUrlAnchor: {
      marginRight: 10,
    },
    openIcon: {
      width: 12,
      height: 12,
      color: theme.palette.midnight,
    },
    deleteIconWrapper: {
      border: `1px solid ${theme.palette.taupe}`,
      borderRadius: "50%",
      width: 24,
      height: 24,
      marginRight: 4,
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      boxSizing: "border-box",
      cursor: disableDelete ? "default" : "pointer",
      "&:active": {
        backgroundColor: disableDelete ? "transparent" : theme.palette.taupe250,
      },
    },
    deleteIcon: {
      width: "7px",
      height: "7px",
    },
  })
);

const i18n = defineMessages({
  unlinkDisabled: {
    id:
      "components.ui.RawOpportunityDisplay.RawOpportunityDisplay.unlinkDisabled",
    defaultMessage:
      "You can’t remove this opportunity because it’s imported from your CRM.",
  },
});
