import { createSvgIcon } from "@mui/material/utils";

import { SvgIcon } from "./types";

const displayName = "ArrowDownInverted";

const ArrowDownInverted: SvgIcon = createSvgIcon(
  <g fill="none" transform="matrix(1.5,0,0,1.5,0,0)">
    <rect width="16" height="16" rx="8" fill="#0A151B" />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M10.0521 9.28469C10.2351 9.10163 10.5319 9.10163 10.715 9.28469C10.8981 9.46775 10.8981 9.76455 10.715 9.94761L8.61275 12.0499C8.21002 12.4526 7.55707 12.4526 7.15434 12.0499L5.05209 9.94761C4.86903 9.76455 4.86903 9.46775 5.05209 9.28469C5.23515 9.10163 5.53194 9.10163 5.715 9.28469L7.41479 10.9845V4.61621C7.41479 4.35733 7.62466 4.14746 7.88354 4.14746C8.14243 4.14746 8.35229 4.35733 8.35229 4.61621V10.9845L10.0521 9.28469Z"
      fill="#FAFAF6"
    />
  </g>,
  displayName
);

ArrowDownInverted.displayName = displayName;
export default ArrowDownInverted;
