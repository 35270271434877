import Popover from "@mui/material/Popover";
import { ClassNameMap } from "@mui/material/styles";
import { makeStyles } from "makeStyles";
import { ReactNode } from "react";
import { useMergedClasses } from "tss-react";

type Props = {
  anchorEl?: Element | null;
  children?: ReactNode;
  classes?: ClassNameMap;
  open?: boolean;
  onClose: (event?: any) => void;
  position?: "left" | "right" | "center";
  size?: "normal" | "small";
};

const DropdownPopover = ({
  open = false,
  anchorEl = null,
  children,
  classes: newClasses = {},
  onClose,
  position = "center",
  size,
  ...props
}: Props) => {
  const { classes: baseClasses } = useStyles();
  const classes = useMergedClasses(baseClasses, newClasses);

  return (
    <Popover
      id="dropdown-popover"
      open={open}
      anchorEl={anchorEl}
      onClose={onClose}
      classes={{
        paper: classes.paper,
        root: classes.root,
      }}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: position,
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: position,
      }}
      {...props}
    >
      {children}
    </Popover>
  );
};

export default DropdownPopover;

// CSS

const useStyles = makeStyles()((theme) => ({
  paper: {
    display: "flex",
    flexDirection: "column",
    borderRadius: 8,
    border: `1px solid ${theme.palette.grey[300]}`,
    boxShadow: `-2px 3px 6px ${theme.palette.taupe} !important`,
    backgroundColor: theme.palette.ivory,
    padding: 8,
    marginTop: 4,
  },
  root: {
    zIndex: 999999,
    "& .MuiBackdrop-root": {
      background: "transparent",
    },
  },
}));
