import { createSvgIcon } from "@mui/material/utils";

import { SvgIcon } from "./types";

const displayName = "Bookmark";

const Bookmark: SvgIcon = createSvgIcon(
  // Using transform to enlarge from provided viewbox="0 0 16 16"
  // to default "0 0 24 24" from `createSvgIcon` -> `SvgIcon`.
  <g fill="none" transform="matrix(1.5,0,0,1.5,0,0)">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3.5 1C2.67157 1 2 1.67157 2 2.5V13.2817C2 14.4318 3.24105 15.154 4.24101 14.5859L7.753 12.5904C7.90617 12.5034 8.09383 12.5034 8.247 12.5904L11.759 14.5859C12.759 15.154 14 14.4318 14 13.2817V2.5C14 1.67157 13.3284 1 12.5 1H3.5Z"
      fill="currentColor"
    />
  </g>,
  displayName
);

Bookmark.displayName = displayName;
export default Bookmark;
