import { createSvgIcon } from "@mui/material/utils";

import { SvgIcon } from "./types";

const displayName = "Star";

const Star: SvgIcon = createSvgIcon(
  // Using transform to enlarge from provided viewbox="0 0 16 16"
  // to default "0 0 24 24" from `createSvgIcon` -> `SvgIcon`.
  <g fill="none" transform="matrix(1.5,0,0,1.5,0,0)">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.38833 1.98723C8.93078 0.670923 7.06922 0.670925 6.61167 1.98723L5.9168 3.98628C5.71495 4.56696 5.17305 4.96068 4.55842 4.97321L2.44247 5.01632C1.04921 5.04472 0.473954 6.81517 1.58444 7.65708L3.27093 8.93569C3.76081 9.30709 3.9678 9.94414 3.78979 10.5326L3.17693 12.5583C2.77339 13.8921 4.27943 14.9863 5.4233 14.1903L7.16047 12.9815C7.66508 12.6304 8.33492 12.6304 8.83953 12.9815L10.5767 14.1903C11.7206 14.9863 13.2266 13.8921 12.8231 12.5583L12.2102 10.5326C12.0322 9.94414 12.2392 9.30709 12.7291 8.93569L14.4156 7.65708C15.526 6.81517 14.9508 5.04472 13.5575 5.01632L11.4416 4.97321C10.8269 4.96068 10.285 4.56696 10.0832 3.98628L9.38833 1.98723Z"
      fill="currentColor"
    />
  </g>,
  displayName
);

Star.displayName = displayName;
export default Star;
