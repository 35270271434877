import { GoogleButton } from "components/ui/GoogleButton";
import { T } from "components/ui/Typography";
import { ProviderType } from "config/constants";
import getFirstValueFromURL from "helpers/getFirstValueFromURL";
import { makeStyles } from "makeStyles";
import { useMemo } from "react";
import { defineMessages, FormattedMessage } from "react-intl";
import { useLocation } from "react-router-dom";

import { GsheetUrl } from "../helpers/types";

type Props = {
  handleClick: () => void;
};

export const useGsheetAuthDialogContent = ({ handleClick }: Props) => {
  const { classes } = useStyles();
  const location = useLocation();

  const dialogContent = useMemo(
    () => (
      <div className={classes.flexCenter}>
        <GoogleButton onClick={handleClick} shape="pill" />
        <T h4 bold className={classes.title}>
          <FormattedMessage {...i18n.title} />
        </T>
        <T bodySmall className={classes.content}>
          <FormattedMessage {...i18n.detail} />
        </T>
      </div>
    ),
    [classes, handleClick]
  );

  const button = (
    <div className={classes.flexCenter}>
      <GoogleButton onClick={handleClick} shape="pill" />
    </div>
  );

  const dialogTitle = useMemo(
    () => (
      <div className={classes.flexCenter}>
        <div className={classes.iconContainer}>
          <img
            className={classes.icon}
            src="/images/providers/Gsheet.png"
            alt=""
          />
        </div>
      </div>
    ),
    [classes]
  );

  const isNew = getFirstValueFromURL(location, "init") === "true";
  const isOnboarding = getFirstValueFromURL(location, "onboarding") === "true";
  const ghostId = getFirstValueFromURL(location, "isGhost");

  const next = getFirstValueFromURL(
    location,
    "next",
    `${location.pathname}?${isNew ? "init=true&" : ""}${
      isOnboarding ? "onboarding=true&" : ""
    }provider=${ProviderType.gsheet}${ghostId ? "&isGhost=" + ghostId : ""}${
      GsheetUrl.TabSelectionHash
    }`
  );

  return { button, dialogContent, dialogTitle, next };
};

const i18n = defineMessages({
  authWithGoogle: {
    id: "GsheetAuthDialog.authWithGoogle",
    defaultMessage: "Sign in with Google",
  },
  title: {
    id: "GsheetAuthDialog.title",
    defaultMessage:
      "All Google sheets added to Reveal must already be owned or shared by the account you will use to authenticate.",
  },
  detail: {
    id: "GsheetAuthDialog.detail",
    defaultMessage:
      "We recommend that you use a dedicated account to share data in Reveal.",
  },
});

const useStyles = makeStyles()((theme) => ({
  icon: {
    height: 44,
    width: 44,
  },
  iconContainer: {
    background: "white",
    borderRadius: 8,
  },
  title: {
    paddingBottom: theme.spacing(1),
    textAlign: "center",
  },
  flexCenter: {
    alignItems: "center",
    flexDirection: "column",
    gap: theme.spacing(2),
    display: "flex",
    justifyContent: "center",
  },
  content: {
    textAlign: "center",
  },
}));
