import FormHelperText from "@mui/material/FormHelperText";
import Button from "components/ui/Button";
import DialogManager from "components/ui/DialogManager";
import { TextInput } from "components/ui/TextInput";
import { T } from "components/ui/Typography";
import UploadZone from "components/ui/UploadZone";
import { ConfigContext } from "config/ConfigProvider";
import muiTheme from "config/theme";
import { makeStyles } from "makeStyles";
import { ChangeEvent, useContext, useState } from "react";
import { defineMessages, FormattedMessage, useIntl } from "react-intl";
import { Link } from "react-router-dom";

import UploadManager from "./UploadManager";

type Props = {
  onClose: () => void;
  onUploadSuccess: (integrationId: number) => void;
  open?: boolean;
  partnershipId?: number;
};

const UploadDialog = ({
  open,
  onClose,
  onUploadSuccess,
  partnershipId,
}: Props) => {
  const { classes } = useStyles();
  const intl = useIntl();
  const [file, setFile] = useState<File | null>(null);
  const [uploading, setUploading] = useState(false);
  const [filename, setFilename] = useState("");
  const [showError, setShowError] = useState(false);
  const config = useContext(ConfigContext);

  const handleClose = () => {
    onClose();
    setFile(null);
    setFilename("");
    setUploading(false);
    setShowError(false);
  };

  const handleClick = () => {
    if (!file || !filename) {
      setShowError(true);
      return;
    }
    setUploading(true);
  };

  const dialogContent = (
    <>
      <TextInput
        name="name"
        value={filename}
        onChange={(event: ChangeEvent<HTMLInputElement>) =>
          setFilename(event.target.value)
        }
        placeholder={intl.formatMessage(i18n.namePlaceholder)}
        inputProps={{ "data-testid": "name-input" }}
        className={classes.filenameField}
        disabled={uploading}
        error={showError && !filename}
        helperText={
          showError && !filename ? intl.formatMessage(i18n.nameHelper) : ""
        }
      />

      {!uploading && <UploadZone onDrop={setFile} />}
      {showError && !file && (
        <FormHelperText error={true}>
          <FormattedMessage {...i18n.fileHelper} />
        </FormHelperText>
      )}
      {uploading && file && (
        <UploadManager
          filename={filename}
          file={file}
          partnershipId={partnershipId}
          onUploadSuccess={(integrationId: number) => {
            onUploadSuccess(integrationId);
          }}
          onUploadError={() => setUploading(false)}
        />
      )}
    </>
  );

  const dialogActions = (
    <div className={classes.footer}>
      <T color={muiTheme.palette.darkPurple}>
        <Link to={config.templateFilePath ?? ""} target="_blank" download>
          <FormattedMessage {...i18n.template} />
        </Link>
      </T>
      <div className={classes.btnContainer}>
        <Button
          label={i18n.close}
          onClick={handleClose}
          disabled={uploading}
          variant="secondary"
        />
        <Button
          label={i18n.upload}
          disabled={uploading}
          onClick={handleClick}
        />
      </div>
    </div>
  );

  return (
    <DialogManager
      fullScreenBreakpoint="sm"
      DialogTitleComponent={
        <T h3 className={classes.title}>
          <FormattedMessage {...i18n.title} />
        </T>
      }
      DialogContentComponent={dialogContent}
      DialogActionsComponent={dialogActions}
      handleClose={handleClose}
      isOpen={Boolean(open)}
    />
  );
};

export default UploadDialog;

// I18N

const i18n = defineMessages({
  title: {
    id: "UploadDialog.title",
    defaultMessage: "Add a new CSV file",
  },
  namePlaceholder: {
    id: "UploadDialog.namePlaceholder",
    defaultMessage: "Name this source",
  },
  nameHelper: {
    id: "UploadDialog.nameHelper",
    defaultMessage: "Please name this source",
  },
  fileHelper: {
    id: "UploadDialog.fileHelper",
    defaultMessage: "Please select a file",
  },
  close: {
    id: "UploadDialog.close",
    defaultMessage: "Close",
  },
  upload: {
    id: "UploadDialog.upload",
    defaultMessage: "Upload",
  },
  template: {
    id: "UploadDialog.template",
    defaultMessage: "Download template",
  },
});

// CSS

const useStyles = makeStyles()((theme) => ({
  title: {
    paddingBottom: theme.spacing(3),
  },
  filenameField: {
    marginBottom: theme.spacing(4),
    width: "100%",
  },
  footer: {
    display: "flex",
    justifyContent: "space-between",
    width: "100%",
    alignItems: "flex-end",
    padding: theme.spacing(2),
  },
  btnContainer: {
    display: "flex",
    columnGap: theme.spacing(0.5),
  },
}));
