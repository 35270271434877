import { createSvgIcon } from "@mui/material/utils";

import { SvgIcon } from "./types";

const displayName = "Refresh";

const Refresh: SvgIcon = createSvgIcon(
  // Using transform to enlarge from provided viewbox="0 0 12 12"
  // to default "0 0 24 24" from `createSvgIcon` -> `SvgIcon`.
  <g fill="none" transform="matrix(2,0,0,2,0,0)">
    <path
      d="M0.868421 5.57143C0.868421 2.9678 3.00685 0.857143 5.64474 0.857143C7.25432 0.857143 8.65423 1.6435 9.51303 2.8541L8.25127 2.85045C8.01147 2.84976 7.81649 3.04107 7.81579 3.27776C7.81509 3.51445 8.00892 3.70689 8.24873 3.70759L10.3039 3.71353L10.5645 3.71428C10.6799 3.71462 10.7907 3.66961 10.8724 3.5892C10.9541 3.5088 11 3.3996 11 3.28571L11 1C11 0.763306 10.8056 0.571428 10.5658 0.571429C10.326 0.571429 10.1316 0.763307 10.1316 1L10.1316 2.23564C9.1123 0.880646 7.49761 0 5.64474 0C2.52723 0 0 2.49441 0 5.57143C0 5.80812 0.194403 6 0.434211 6C0.674018 6 0.868421 5.80812 0.868421 5.57143Z"
      fill="currentColor"
    />
    <path
      d="M10.6316 6.42857C10.6316 9.0322 8.49315 11.1429 5.85526 11.1429C4.24568 11.1429 2.84577 10.3565 1.98697 9.1459L3.24873 9.14955C3.48853 9.15025 3.68351 8.95893 3.68421 8.72224C3.68491 8.48555 3.49108 8.29311 3.25127 8.29241L1.19608 8.28647L0.935483 8.28572C0.820103 8.28538 0.709333 8.33039 0.627627 8.4108C0.545922 8.4912 0.5 8.6004 0.5 8.71429L0.500002 11C0.500002 11.2367 0.694405 11.4286 0.934212 11.4286C1.17402 11.4286 1.36842 11.2367 1.36842 11L1.36842 9.76436C2.3877 11.1194 4.00239 12 5.85526 12C8.97277 12 11.5 9.50559 11.5 6.42857C11.5 6.19188 11.3056 6 11.0658 6C10.826 6 10.6316 6.19188 10.6316 6.42857Z"
      fill="currentColor"
    />
  </g>,
  displayName
);

Refresh.displayName = displayName;

export default Refresh;
