import { makeStyles } from "makeStyles";

import useSummaryMetricsAPILogic from "../hook/useSummaryMetricsAPILogic";
import { MetricsBox } from "./MetricsBox";
import { TotalMetricsBox } from "./TotalMetricsBox";

export const SummaryMetrics = () => {
  const { classes } = useStyles();
  const { summaryMetricsEnabled, summaryMetrics } = useSummaryMetricsAPILogic();

  if (!summaryMetricsEnabled) {
    return null;
  }

  return (
    <div className={classes.root}>
      <div className={classes.item}>
        <TotalMetricsBox summaryMetrics={summaryMetrics} />
      </div>
      <div className={classes.separator} />
      <div className={classes.item}>
        {
          <MetricsBox
            variant="leadGen"
            amount={summaryMetrics?.newProspectsRevenuePotential ?? null}
            count={summaryMetrics?.newProspectsCount ?? null}
          />
        }
      </div>
      <div className={classes.separator} />
      <div className={classes.item}>
        {
          <MetricsBox
            variant="source"
            amount={
              summaryMetrics?.prospectsNoOpportunitiesRevenuePotential ?? null
            }
            count={summaryMetrics?.prospectsNoOpportunitiesCount ?? null}
          />
        }
      </div>
      <div className={classes.separator} />
      <div className={classes.item}>
        {
          <MetricsBox
            variant="influence"
            amount={
              summaryMetrics?.prospectsWithOpportunitiesRevenuePotential ?? null
            }
            count={summaryMetrics?.prospectsWithOpportunitiesCount ?? null}
          />
        }
      </div>
    </div>
  );
};

/// CSS

const useStyles = makeStyles()((theme) => ({
  root: {
    display: "flex",
    width: "auto",
    height: 95,
    backgroundColor: theme.palette.ivory,
    margin: theme.spacing(2.5),
    borderRadius: theme.spacing(1),
    padding: 12,
    columnGap: 12,
  },
  item: {
    display: "flex",
    width: "100%",
  },
  separator: {
    backgroundColor: theme.palette.greyscale200,
    width: 1,
  },
}));
