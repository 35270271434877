import AddIcon from "@mui/icons-material/Add";
import ClearIcon from "@mui/icons-material/Clear";
import DoneIcon from "@mui/icons-material/Done";
import CircularProgress from "@mui/material/CircularProgress";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import withAPI, { WithAPI } from "components/hoc/withAPI";
import Button from "components/ui/Button";
import { T } from "components/ui/Typography";
import usePushNotification from "hooks/usePushNotification";
import { makeStyles } from "makeStyles";
import { useState } from "react";
import {
  defineMessages,
  FormattedHTMLMessage,
  FormattedMessage,
} from "react-intl";

import { TabProps } from "../../types";
import FollowersSearch from "./FollowersSearch";

const i18n = defineMessages({
  headline: {
    id: "crm.PartnershipSettings.WatchersSettings.headline",
    defaultMessage: `All users following a partnership will receive <b>all related notifications</b>.
    (Data sharing requests and updates / data update and availability notifications)`,
  },
  userFollowing: {
    id: "crm.PartnershipSettings.WatchersSettings.userFollowing",
    defaultMessage: "You are following this partnership",
  },
  userNotFollowing: {
    id: "crm.PartnershipSettings.WatchersSettings.userNotFollowing",
    defaultMessage: "You are not following this partnership",
  },
  follow: {
    id: "crm.PartnershipSettings.WatchersSettings.follow",
    defaultMessage: "Follow",
  },
  unfollow: {
    id: "crm.PartnershipSettings.WatchersSettings.unfollow",
    defaultMessage: "Unfollow",
  },
  followerList: {
    id: "crm.PartnershipSettings.WatchersSettings.followerList",
    defaultMessage: "Follower list",
  },
  name: {
    id: "crm.PartnershipSettings.WatchersSettings.name",
    defaultMessage: "Name",
  },
  you: {
    id: "crm.PartnershipSettings.WatchersSettings.you",
    defaultMessage: " (You)",
  },
  email: {
    id: "crm.PartnershipSettings.WatchersSettings.email",
    defaultMessage: "E-mail",
  },
  following: {
    id: "crm.PartnershipSettings.WatchersSettings.following",
    defaultMessage: "Following",
  },
  noUserFollowing: {
    id: "crm.PartnershipSettings.WatchersSettings.noUserFollowing",
    defaultMessage: "No user is following this partnership",
  },
});

const useStyles = makeStyles()((theme) => ({
  root: {
    flexDirection: "column",
    marginBottom: theme.spacing(4),
    padding: theme.spacing(2),
    backgroundColor: theme.palette.ivory,
    borderRadius: theme.spacing(1),
    "& > :not(:last-child)": {
      marginBottom: theme.spacing(2),
    },
    [theme.breakpoints.down("md")]: {
      marginLeft: theme.spacing(2),
      marginRight: theme.spacing(2),
    },
    [theme.breakpoints.down("sm")]: {
      marginLeft: 0,
      marginRight: 0,
    },
  },
  userWatchContainer: {
    display: "flex",
    width: "100%",
    justifyContent: "space-between",
    alignItems: "center",
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(4),
  },
  tableTitleContainer: {
    display: "flex",
    width: "100%",
    justifyContent: "space-between",
    alignItems: "center",
  },
  headerRow: {
    "&> th": {
      fontSize: 12,
      borderStyle: "none",
      padding: theme.spacing(1),
    },
  },
  tableRow: {
    "&> td": {
      fontSize: 12,
      padding: theme.spacing(1),
    },
  },
  followingIconContainer: {
    cursor: "pointer",
    width: 20,
    height: 20,
    "&:hover .hideOnHover": {
      display: "none",
    },
    "&:hover .showOnHover": {
      display: "block",
    },
  },
  followingIcon: {
    fontSize: 20,
    color: theme.palette.primary.main,
  },
  unfollowIcon: {
    fontSize: 20,
    // @ts-expect-error ts-migrate(2339) FIXME: Property 'dark' does not exist on type 'Color'.
    color: theme.palette.grey.dark,
    display: "none",
  },
  noFollowersText: {
    paddingBottom: theme.spacing(2),
  },
}));

const FollowersTab = ({
  partnership,
  profile,
  rawPost,
}: TabProps & WithAPI) => {
  const { classes, cx } = useStyles();
  const isWatching = partnership.watcherIds.includes(+profile.id);
  const pushNotification = usePushNotification();
  const [loading, setLoading] = useState(false);
  const [loadingAdd, setLoadingAdd] = useState(false);
  const [loadingRemove, setLoadingRemove] = useState({});

  const toggleWatch = async () => {
    try {
      setLoading(true);
      await rawPost(
        "partnerships",
        partnership.id,
        isWatching ? "/unwatch/" : "/watch/",
        {},
        {
          fields: {
            partnerships: ["watcher_ids", "watchers"],
          },
        }
      );
      setLoading(false);
    } catch (_error) {
      pushNotification("default_error");
    }
  };

  const toggleWatcher = async (user: $TSFixMe, action = "add") => {
    try {
      if (action === "remove") {
        setLoadingRemove({ ...loadingRemove, [user.id]: true });
      } else {
        setLoadingAdd(true);
      }
      await rawPost(
        "partnerships",
        partnership.id,
        "/toggle-watcher/",
        { user_id: user.id, action },
        {
          config: {
            headers: {
              "Content-Type": "application/json",
            },
          },
          fields: {
            partnerships: ["watcher_ids", "watchers"],
          },
        }
      );
      if (action === "remove") {
        setLoadingRemove({ ...loadingRemove, [user.id]: false });
      } else {
        setLoadingAdd(false);
      }
    } catch (_error) {
      pushNotification("default_error");
    }
  };

  return (
    <div className={classes.root}>
      <T>
        <FormattedHTMLMessage {...i18n.headline} />
      </T>
      <div className={classes.userWatchContainer}>
        <T>
          {isWatching ? (
            <FormattedMessage {...i18n.userFollowing} />
          ) : (
            <FormattedMessage {...i18n.userNotFollowing} />
          )}
        </T>
        <Button
          label={isWatching ? i18n.unfollow : i18n.follow}
          onClick={toggleWatch}
          RightIcon={isWatching ? ClearIcon : AddIcon}
          loading={loading}
        />
      </div>
      <div className={classes.tableTitleContainer}>
        <T bodyBig>
          <FormattedMessage {...i18n.followerList} />
        </T>
        <FollowersSearch
          addWatcher={toggleWatcher}
          watcherIds={partnership.watcherIds}
        />
      </div>
      {(partnership.watchers && partnership.watchers.length) || loadingAdd ? (
        <Table>
          <TableHead>
            <TableRow className={classes.headerRow}>
              <TableCell>
                <T bold>
                  <FormattedMessage {...i18n.name} />
                </T>
              </TableCell>
              <TableCell>
                <T bold>
                  <FormattedMessage {...i18n.email} />
                </T>
              </TableCell>
              <TableCell>
                <T bold>
                  <FormattedMessage {...i18n.following} />
                </T>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {partnership.watchers.map((user: $TSFixMe, key: $TSFixMe) => (
              <TableRow key={key} className={classes.tableRow}>
                <TableCell>
                  <T>
                    {user.fullName}
                    {user.id === profile.id && (
                      <FormattedMessage {...i18n.you} />
                    )}
                  </T>
                </TableCell>
                <TableCell>
                  <T>{user.email}</T>
                </TableCell>
                <TableCell>
                  <div className={classes.followingIconContainer}>
                    {loadingRemove.hasOwnProperty(user.id) &&
                    // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
                    loadingRemove[user.id] ? (
                      <CircularProgress size={15} />
                    ) : (
                      <>
                        <DoneIcon
                          className={cx(classes.followingIcon, "hideOnHover")}
                        />
                        <ClearIcon
                          className={cx(classes.unfollowIcon, "showOnHover")}
                          onClick={() => toggleWatcher(user, "remove")}
                        />
                      </>
                    )}
                  </div>
                </TableCell>
              </TableRow>
            ))}
            {loadingAdd && (
              <TableRow className={classes.tableRow}>
                <TableCell align="center" colSpan={3}>
                  <CircularProgress size={20} />
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      ) : (
        <T
          textAlign="center"
          className={classes.noFollowersText}
          oldVariant="body1_fontStyle:italic"
        >
          <FormattedMessage {...i18n.noUserFollowing} />
        </T>
      )}
    </div>
  );
};

export default withAPI(FollowersTab);
