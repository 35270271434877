import { Avatar, Chip } from "@mui/material";
import { ClassNameMap, lighten } from "@mui/material/styles";
import { Theme } from "@mui/material/styles";
import Close from "components/icons/Close";
import { T } from "components/ui/Typography";
import { replaceWithBr } from "helpers/textUtils";
import { makeStyles } from "makeStyles";
import { ReactElement, ReactNode } from "react";
import { useMergedClasses } from "tss-react";

type Type = "rounded" | "squared";

export type TagVariant =
  | "default"
  | "dark"
  | "active"
  | "pending"
  | "paused"
  | "offline"
  | "limited"
  | "unlimited";

export type StyleProps = {
  type?: Type;
  logo?: string;
  isSmall?: boolean;
  variant: TagVariant;
};

export type Props = Partial<StyleProps> & {
  label: ReactNode | string;
  icon?: ReactElement;
  onDelete?: (event: any) => void;
  disabled?: boolean;
  disabledUppercase?: boolean;
  classes?: ClassNameMap;
};

const Tag = ({
  label: labelRaw,
  isSmall = false,
  icon,
  logo,
  onDelete,
  type = "rounded",
  variant = "default",
  disabled,
  disabledUppercase = false,
  classes: customClasses = {},
}: Props) => {
  const { classes, cx } = useStyles({
    type,
    variant,
  });
  const { classes: baseChipClasses } = useChipStyles({
    type,
    variant,
    isSmall,
  });
  const chipClasses = useMergedClasses(baseChipClasses, customClasses);
  const label =
    typeof labelRaw === "string" ? replaceWithBr(labelRaw) : labelRaw;

  return (
    <Chip
      avatar={
        logo && !isSmall ? (
          <Avatar src={logo} className={classes.avatar} />
        ) : undefined
      }
      icon={icon}
      label={
        isSmall ? (
          <T
            uppercase
            className={cx(
              classes.label,
              disabledUppercase && classes.disabledUppercase
            )}
          >
            {label}
          </T>
        ) : (
          <T className={classes.label}>{label}</T>
        )
      }
      onDelete={onDelete}
      deleteIcon={<Close />}
      classes={chipClasses}
      disabled={disabled}
    />
  );
};

// Styles

export const useStyles = makeStyles<StyleProps>()(
  (theme, { type, variant }) => ({
    avatar: {
      marginLeft: "2px !important",
      marginRight: "-2px !important",
      borderRadius: type === "rounded" ? 16 : 4,
    },
    label: {
      color: `${getTextColor(theme, variant)} !important`,
    },
    disabledUppercase: {
      textTransform: "none",
    },
  })
);

export const useChipStyles = makeStyles<StyleProps>()(
  (theme, { type, variant, isSmall }) => ({
    root: {
      height: isSmall ? 24 : theme.spacing(3.5),
      backgroundColor: getBackgroundColor(theme, variant),
      border:
        variant === "default"
          ? `1px solid ${theme.palette.greyscale200}`
          : undefined,
      borderRadius: type === "rounded" ? 16 : 4,
      cursor: "default",
    },
    label: {
      paddingLeft: isSmall ? 6 : 10,
      paddingRight: isSmall ? 6 : 10,
    },
    icon: {
      color: `${getTextColor(theme, variant)} !important`,
      paddingRight: 4,
    },
    deleteIcon: {
      color: `${getTextColor(theme, variant)} !important`,
      height: isSmall ? 8 : 9,
      width: isSmall ? 8 : 9,
      marginRight: `${isSmall ? 2 : 6}px !important`,
      marginLeft: "-2px !important",
      padding: isSmall ? 3 : 4,
      borderRadius: type === "rounded" ? 16 : 4,
      boxSizing: "content-box",
      "&:hover": {
        backgroundColor: theme.palette.taupe,
      },
    },
    disabled: {
      opacity: "0.6 !important",
    },
  })
);

// CSS Helpers

const getTextColor = (theme: Theme, variant: TagVariant) => {
  switch (variant) {
    case "active":
    case "pending":
    case "paused":
    case "offline":
      return theme.palette.ivory;
    case "dark":
    case "limited":
      return theme.palette.offWhite;
    case "unlimited":
      return theme.palette.comet;
  }
  return theme.palette.midnight;
};

const getBackgroundColor = (theme: Theme, variant: TagVariant) => {
  switch (variant) {
    case "active":
      return theme.palette.green700;
    case "pending":
      return theme.palette.gold500;
    case "paused":
      return theme.palette.greyscale400;
    case "offline":
      return theme.palette.greyscale850;
    case "dark":
      return lighten(theme.palette.darkPigeon as string, 0.05);
    case "limited":
      return theme.palette.darkPurple;
    case "unlimited":
      return theme.palette.green400;
  }
  return theme.palette.ivory;
};

export default Tag;
