import { createSvgIcon } from "@mui/material/utils";

import { SvgIcon } from "./types";

const displayName = "Message";

const Message: SvgIcon = createSvgIcon(
  // Using transform to enlarge from provided viewbox="0 0 16 16"
  // to default "0 0 24 24" from `createSvgIcon` -> `SvgIcon`.
  <g fill="none" transform="matrix(1.5,0,0,1.5,0,0)">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4.52781 11.3934C4.71651 11.2361 4.95437 11.15 5.2 11.15H12.9C13.4799 11.15 13.95 10.6799 13.95 10.1V3.1C13.95 2.5201 13.4799 2.05 12.9 2.05H3.1C2.5201 2.05 2.05 2.5201 2.05 3.1V12.7109C2.05 13.0077 2.3961 13.1698 2.62406 12.9798L4.52781 11.3934ZM5.2 12.2H12.9C14.0598 12.2 15 11.2598 15 10.1V3.1C15 1.9402 14.0598 1 12.9 1H3.1C1.9402 1 1 1.9402 1 3.1V12.7109C1 13.8979 2.3844 14.5463 3.29626 13.7865L5.2 12.2Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4.67505 5.19993C4.67505 4.90998 4.9101 4.67493 5.20005 4.67493H10.8C11.09 4.67493 11.325 4.90998 11.325 5.19993C11.325 5.48988 11.09 5.72493 10.8 5.72493H5.20005C4.9101 5.72493 4.67505 5.48988 4.67505 5.19993Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4.67505 7.99998C4.67505 7.71003 4.9101 7.47498 5.20005 7.47498H8.00005C8.29 7.47498 8.52505 7.71003 8.52505 7.99998C8.52505 8.28993 8.29 8.52498 8.00005 8.52498H5.20005C4.9101 8.52498 4.67505 8.28993 4.67505 7.99998Z"
      fill="currentColor"
    />
  </g>,
  displayName
);

Message.displayName = displayName;
export default Message;
