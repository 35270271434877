import { Box } from "@mui/material";
import { isRejected, unwrapResult } from "@reduxjs/toolkit";
import CompanyAvatar from "components/ui/avatars/CompanyAvatar";
import Button from "components/ui/Button";
import { IDropdownOption } from "components/ui/Dropdown/components/types";
import Tooltip from "components/ui/Tooltip";
import { T } from "components/ui/Typography";
import usePushNotification from "hooks/usePushNotification";
import useSegment from "hooks/useSegment";
import useSelectorWithDeepEquality from "hooks/useSelectorWithDeepEquality";
import useUserProfile from "hooks/useUserProfile";
import { makeStyles } from "makeStyles";
import Match from "models/Match";
import User from "models/User";
import { useEffect, useState } from "react";
import { defineMessages, FormattedMessage, useIntl } from "react-intl";
import { useDispatch } from "react-redux";
import { fetchPartnerAndOpportunityPresence, update } from "redux/api/thunks";
import { PartnerAndOpportunityPresence } from "redux/api/typing";
import { AvailablePipelineColumns } from "redux/pipeline/defaults";
import {
  selectCurrentUser,
  selectPartnershipsFilterData,
  selectPerspective,
} from "redux/pipeline/selectors";
import { PartnershipFilterData } from "redux/pipeline/typing";
import { PipelineEvent, PipelineTableEditAction } from "tracking";

import { CellProps } from "../../utils";
import { DropdownCell } from "../shared/DropdownCell";
import { DropdownGroup } from "../shared/useDropdownCell";

export const PartnerDropdown = (props: CellProps) => {
  const dispatch = useDispatch();
  const { classes } = usePartnerDropdownStyles();
  const { track } = useSegment();
  const intl = useIntl();
  const { profile } = useUserProfile();
  const pushNotification = usePushNotification();
  const perspective = useSelectorWithDeepEquality(selectPerspective);
  const partnersInfo = useSelectorWithDeepEquality(selectPartnershipsFilterData)
    .data;
  const [hasDropdownOpened, setHasDropdownOpened] = useState(false);
  const [
    partnerAndOpportunityPresence,
    setPartnerAndOpportunityPresence,
  ] = useState<PartnerAndOpportunityPresence | null>(null);
  const { row } = props;
  const { selectedItem, optionsGroups } = useComponentData(
    partnersInfo,
    row.partnershipId,
    partnerAndOpportunityPresence
  );
  const currentUser = useSelectorWithDeepEquality(selectCurrentUser);
  const {
    noSelectedPartner,
    nameFromSync,
    allowEdit,
    disabledText,
  } = getValues({
    row,
    currentUser,
    profile,
    isPartnerView: perspective === Match.PERSPECTIVE_THEIRS,
  });

  const updatePartner = async (newPartnerData: IDropdownOption) => {
    const response = await dispatch(
      update({
        id: row.id,
        type: "partner_connections",
        attributes: {
          partnership_id: newPartnerData.id,
        },
        options: {
          include: ["discussion_participants"],
        },
      })
    );

    track(PipelineEvent.tableEdit, {
      action:
        row.partnershipId === null
          ? PipelineTableEditAction.setPartner
          : PipelineTableEditAction.changePartner,
    });

    if (isRejected(response)) {
      pushNotification("default_error");
    }
  };

  useEffect(() => {
    if (row.rawCompanyId && hasDropdownOpened) {
      dispatch(fetchPartnerAndOpportunityPresence({ id: row.rawCompanyId }))
        // @ts-expect-error -- Once `Dispatch` is properly typed this should go away.
        .then(unwrapResult)
        .then(setPartnerAndOpportunityPresence)
        .catch((_error: $TSFixMe) => {
          pushNotification("default_error");
        });
    }
  }, [hasDropdownOpened, row.rawCompanyId]); // eslint-disable-line react-hooks/exhaustive-deps

  if (!allowEdit && noSelectedPartner) {
    return <></>;
  }

  if (!allowEdit || !!selectedItem) {
    let cell = <PartnerWithAvatar {...props} selectedItem={selectedItem} />;

    if (!nameFromSync && row.partnershipId === null) {
      cell = <></>;
    }

    return (
      <Box overflow="hidden">
        <Tooltip
          title={
            disabledText ? (
              disabledText
            ) : (
              <FormattedMessage {...i18n.partnerNoEdit} />
            )
          }
        >
          <div>
            <Button
              classes={{
                content: classes.content,
                septenary: classes.septenary,
              }}
              disableRipple
              label={cell}
              size="xSmall"
              variant={"septenary"}
              ellipsis
            />
          </div>
        </Tooltip>
      </Box>
    );
  }

  return (
    <DropdownCell
      optionsGroups={optionsGroups}
      getLabel={() => (
        <PartnerWithAvatar {...props} selectedItem={selectedItem} />
      )}
      selectedItem={noSelectedPartner ? null : selectedItem}
      unselectedText={intl.formatMessage(i18n.choosePartner)}
      isLoading={Boolean(row.rawCompanyId) && !partnerAndOpportunityPresence}
      onChange={updatePartner}
      cellType="partner"
      withSearch
      searchPlaceholder={intl.formatMessage(i18n.searchPartner)}
      onFirstOpenCallback={() => setHasDropdownOpened(true)}
    />
  );
};

const usePartnerDropdownStyles = makeStyles()((theme) => ({
  content: {
    padding: "3px 4px",
  },
  septenary: {
    borderColor: theme.palette.taupe,
  },
}));

// HELPERS

export const isPipelinePartnerCell = ({ fieldName }: CellProps) => {
  return fieldName === AvailablePipelineColumns.partnership;
};

const getValues = ({
  row,
  currentUser,
  profile,
  isPartnerView,
}: {
  row: any;
  currentUser: User;
  profile: User;
  isPartnerView: boolean;
}) => {
  const noSelectedPartner = !row.partnerAvatarUrl && !row.partnerName;
  const dataFromSync = row.rawOpportunitiesWithRule?.length > 0 ?? false;
  const nameFromSync = row.partnerName && !row["partnershipId"];
  const isAdminOrPM =
    profile.isCompanyAdmin() || profile.isPartnershipManager();
  const isUserItem = row.userId === currentUser.id;
  const isEditable = !isPartnerView && (isAdminOrPM || isUserItem);
  const allowEdit = isEditable && !dataFromSync && !nameFromSync;
  const disabledText =
    dataFromSync || nameFromSync ? (
      <FormattedMessage
        {...(nameFromSync ? i18n.notFoundInReveal : i18n.dataFromSync)}
        values={{ partner: row.partnerName }}
      />
    ) : undefined;

  return {
    noSelectedPartner,
    nameFromSync,
    allowEdit,
    disabledText,
  };
};

const PartnerWithAvatar = ({
  row,
  selectedItem,
}: CellProps & { selectedItem?: PartnershipFilterData }) => {
  const intl = useIntl();
  const { profile } = useUserProfile();
  const currentUser = useSelectorWithDeepEquality(selectCurrentUser);
  const perspective = useSelectorWithDeepEquality(selectPerspective);
  const { allowEdit, nameFromSync } = getValues({
    row,
    currentUser,
    profile,
    isPartnerView: perspective === Match.PERSPECTIVE_THEIRS,
  });
  const { classes, cx } = useStyles({
    allowEdit: allowEdit && !selectedItem,
    nameFromSync,
  });
  const partnerName = selectedItem?.name ?? row.partnerName;
  return (
    <Tooltip
      title={
        <FormattedMessage
          {...i18n.alreadyInPipeline}
          values={{
            partnerName,
            accountName: row.rawCompanyName,
          }}
        />
      }
    >
      <>
        {!nameFromSync && (
          <div className={classes.avatarContainer}>
            <CompanyAvatar
              companyName={partnerName}
              src={selectedItem?.logo ?? row.partnerAvatarUrl}
              size="xxs"
            />
          </div>
        )}
        <div className={classes.nameContainer}>
          <T
            noWrap
            className={cx({
              [classes.nameFromSync]: nameFromSync,
            })}
          >
            {partnerName === profile.company.name
              ? intl.formatMessage(i18n.you)
              : partnerName}
          </T>
        </div>
      </>
    </Tooltip>
  );
};

// CSS

const useStyles = makeStyles<{ allowEdit: boolean; nameFromSync: boolean }>()(
  (theme, { allowEdit, nameFromSync }) => ({
    nameFromSync: {
      fontStyle: "italic",
    },
    avatarContainer: {
      position: "absolute",
      marginTop: 4,
    },
    nameContainer: {
      display: "inline-flex",
      alignItems: "center",
      overflow: "hidden",
      width: "100%",
      height: 24,
      borderRadius: 4,
      backgroundColor: "transparent",
      paddingLeft: nameFromSync ? 0 : 22,
      cursor: allowEdit ? "pointer" : "auto",
      "&:hover": {
        backgroundColor: allowEdit ? theme.palette.ivory : "transparent",
      },
    },
  })
);

// I18N

const i18n = defineMessages({
  choosePartner: {
    id: "crm.MyPipeline.CellFormatters.choosePartner",
    defaultMessage: "Choose partner",
  },
  searchPartner: {
    id: "CellFormatters.searchPartner",
    defaultMessage: "Search partner",
  },
  noPartnersAvailable: {
    id: "CellFormatters.noPartnersAvailable",
    defaultMessage: "No partner available",
  },
  noPartnerMatchingFilters: {
    id: "CellFormatters.noPartnerMatchingFilters",
    defaultMessage: "No partner matches this search",
  },
  alreadyInPipeline: {
    id: "CellFormatters.alreadyInPipeline",
    defaultMessage: "{partnerName} x {accountName} is already in Collaborate",
  },
  isCustomerOf: {
    id: "CellFormatters.isCustomerOf",
    defaultMessage: "Is a customer of:",
  },
  isOpportunityFor: {
    id: "CellFormatters.isOpportunityOf",
    defaultMessage: "Is an opportunity for:",
  },
  otherPartners: {
    id: "CellFormatters.otherPartners",
    defaultMessage: "Other partners:",
  },
  you: {
    id: "CellFormatters.you",
    defaultMessage: "You",
  },
  dataFromSync: {
    id: "crm.MyPipeline.CellFormatters.dataFromSync",
    defaultMessage:
      "You can’t edit this data because it’s imported from your CRM.",
  },
  notFoundInReveal: {
    id: "crm.MyPipeline.CellFormatters.notFoundInReveal",
    defaultMessage:
      "We haven’t found a matching partner in Reveal for {partner}. Check the domain name on the Salesforce record.",
  },
  partnerNoEdit: {
    id: "crm.MyPipeline.CellFormatters.partnerNoEdit",
    defaultMessage: "The partner cannot be edited",
  },
});

const useComponentData = (
  partnersInfo: PartnershipFilterData[],
  partnershipId: number,
  partnerAndOpportunityPresence: PartnerAndOpportunityPresence | null
) => {
  const intl = useIntl();
  const selectedItem = partnersInfo.find(({ id }) => id === partnershipId);

  const groupIsCustomerOf: DropdownGroup<PartnershipFilterData> = {
    title: intl.formatMessage(i18n.isCustomerOf),
    noContentPlaceholder: intl.formatMessage(i18n.noPartnersAvailable),
    noContentMatchingPlaceholder: intl.formatMessage(
      i18n.noPartnerMatchingFilters
    ),
    options: partnersInfo.filter(
      (option) =>
        partnerAndOpportunityPresence &&
        option.partnerId &&
        partnerAndOpportunityPresence.partnerPresence.includes(option.partnerId)
    ),
  };

  const groupIsOpportunityFor: DropdownGroup<PartnershipFilterData> = {
    title: intl.formatMessage(i18n.isOpportunityFor),
    noContentPlaceholder: intl.formatMessage(i18n.noPartnersAvailable),
    noContentMatchingPlaceholder: intl.formatMessage(
      i18n.noPartnerMatchingFilters
    ),
    options: partnersInfo.filter(
      (option) =>
        partnerAndOpportunityPresence &&
        option.partnerId &&
        !partnerAndOpportunityPresence.partnerPresence.includes(
          option.partnerId
        ) &&
        partnerAndOpportunityPresence.opportunityPresence.includes(
          option.partnerId
        )
    ),
  };

  const groupOtherPartners: DropdownGroup<PartnershipFilterData> = {
    title: intl.formatMessage(i18n.otherPartners),
    noContentPlaceholder: intl.formatMessage(i18n.noPartnersAvailable),
    noContentMatchingPlaceholder: intl.formatMessage(
      i18n.noPartnerMatchingFilters
    ),
    options: partnersInfo.filter(
      (option) =>
        !partnerAndOpportunityPresence ||
        !option.partnerId ||
        (!partnerAndOpportunityPresence.partnerPresence.includes(
          option.partnerId
        ) &&
          !partnerAndOpportunityPresence.opportunityPresence.includes(
            option.partnerId
          ))
    ),
  };

  let optionsGroups = [] as DropdownGroup<PartnershipFilterData>[];
  if (groupOtherPartners.options.length === partnersInfo.length) {
    delete groupOtherPartners.title;
    optionsGroups = [groupOtherPartners];
  } else {
    if (groupIsCustomerOf.options.length > 0) {
      optionsGroups.push(groupIsCustomerOf);
    }

    if (groupIsOpportunityFor.options.length > 0) {
      optionsGroups.push(groupIsOpportunityFor);
    }
    if (groupOtherPartners.options.length > 0) {
      optionsGroups.push(groupOtherPartners);
    }
  }
  return {
    selectedItem,
    optionsGroups,
  };
};

export const _private = {
  i18n,
};
