import _ from "lodash";
import { useSelector } from "react-redux";
import { RevealStore } from "redux/typing";

const useAvailableCurrencies = () => {
  return useSelector((state: RevealStore) => {
    return _.values(state.api.entities.dollar_exchange_rates || {}).map(
      _.property("currencyIsoCode")
    );
  });
};

export default useAvailableCurrencies;
