import Grid from "@mui/material/Grid";
import Hidden from "@mui/material/Hidden";
import { ClassNameMap } from "@mui/material/styles";
import { makeStyles } from "makeStyles";
import { ReactNode } from "react";
import { useMergedClasses } from "tss-react";

type Props = {
  left: ReactNode;
  right: ReactNode;
  classes?: Partial<
    ClassNameMap<"leftPanelContainer" | "rightPanel" | "rvlProfile">
  >;
};

const TwoSided = ({ left, right, classes: newClasses = {} }: Props) => {
  const { classes: baseClasses } = useStyles();
  const classes = useMergedClasses(baseClasses, newClasses);

  return (
    <Grid container className={classes.root}>
      <Hidden mdDown>
        <Grid item xs={false} md={4}>
          <div className={classes.leftPanelContainer}>
            <div className={classes.leftPanelContent}>{left}</div>
          </div>
        </Grid>
      </Hidden>
      <Grid item xs={12} md={8} className={classes.rightPanel}>
        {right}
      </Grid>
    </Grid>
  );
};

export default TwoSided;

/// CSS

const useStyles = makeStyles()((theme) => ({
  root: {
    padding: theme.spacing(4),
    height: "100%",
  },
  leftPanelContainer: {
    borderRadius: 8,
    background: theme.palette.gradients.darkPurpleToPurple,
    height: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    position: "relative",
  },
  leftPanelContent: {
    zIndex: 1,
    display: "flex",
    flexDirection: "column",
    gap: theme.spacing(2),
    alignItems: "center",
    padding: 40,
    color: theme.palette.ivory,
    textAlign: "center",
  },
  rightPanel: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    padding: 50,
  },
}));
