import _ from "lodash";
import CrmField from "models/CrmField";

import {
  emptyNode,
  isSWQLEmptyNode,
  NodeType,
  SWQLField,
  SWQLNode,
  SWQLRuleNode,
} from "./SWQLTypes";

function simplifySwqlNode(node: SWQLNode): SWQLNode {
  switch (node.type) {
    case NodeType.Empty:
      return emptyNode();

    case NodeType.Constant:
      return node;

    case NodeType.Rule:
      // @ts-expect-error -- TODO: is this a bug?
      if (node.right.value && node.right.value.length < 1) {
        return emptyNode();
      }
      return node;

    case NodeType.Boolean:
      const filteredArgs = node.args
        .map(simplifySwqlNode)
        .filter((node) => !isSWQLEmptyNode(node));

      if (filteredArgs.length === 0) {
        return emptyNode();
      }

      if (filteredArgs.length === 1) {
        return simplifySwqlNode(filteredArgs[0]);
      }

      return { ...node, args: filteredArgs };
    case NodeType.Lookup:
      return {
        type: NodeType.Lookup,
        rule: simplifySwqlNode(node.rule) as SWQLRuleNode,
        operation: node.operation,
        lookup_path: node.lookup_path,
      };
    case NodeType.LookupEmpty:
    case NodeType.LookupExists:
      return node;
  }
}

export function simplifySwqlQuery(node: SWQLNode): SWQLNode | {} {
  const simplifiedNode = simplifySwqlNode(node);
  if (isSWQLEmptyNode(simplifiedNode)) {
    return {};
  }

  return simplifiedNode;
}

export const mapCrmFieldToSWQLField = (item: CrmField): SWQLField => ({
  id: item.id,
  type: item.swType,
  label: item.crmLabel,
  value: item.crmName,
  options: item.crmOptions,
  hasOptions: !_.isEmpty(item.crmOptions),
  recordType: item.swRecordType,
});
