import { CircularProgress } from "@mui/material";
import Avatar from "@mui/material/Avatar";
import Badge from "@mui/material/Badge";
import { ClassNameMap, Theme } from "@mui/material/styles";
import { memoUnlessPropsChange } from "helpers/memoUnlessPropsChange";
import { makeStyles } from "makeStyles";
import User from "models/User";
import { useMergedClasses } from "tss-react";
import { withStyles } from "tss-react/mui";

export type Size = "xl" | "lg" | "md" | "sm" | "xs" | "xxs";

type StyleProps = {
  loading?: boolean;
  size: Size;
};

type Props = Partial<StyleProps> & {
  user?: User;
  src?: string | null;
  fullName?: string | JSX.Element | null;
  classes?: ClassNameMap;
  online?: boolean;
  children?: React.ReactNode;
};

const StyledBadge = withStyles(Badge, (theme: Theme) => ({
  badge: {
    backgroundColor: theme.palette.appGreen,
    color: theme.palette.appGreen,
  },
}));

export const AccountAvatar = memoUnlessPropsChange(
  [
    "user",
    "src",
    "fullName",
    "classes",
    "size",
    "online",
    "loading",
    "onClick",
    "children",
  ],
  ({
    user,
    src,
    fullName,
    classes: newClasses,
    size = "md",
    online = false,
    loading,
    children,
    ...rest
  }: Props) => {
    const { classes: baseClasses } = useStyles({ loading, size });
    const classes = useMergedClasses(baseClasses, newClasses ?? {});
    let avatarUrl,
      initials = undefined;
    if (user) {
      if (user.avatarUrl && user.avatarUrl !== "/images/user.png") {
        avatarUrl = user.avatarUrl;
      }
      initials =
        user.firstName.charAt(0).toUpperCase() +
        user.lastName.charAt(0).toUpperCase();
    } else if (fullName) {
      var result =
        typeof fullName === "string"
          ? fullName.replace(/[^a-zA-Z- ]/g, "").match(/\b\w/g)
          : null;
      if (result !== null) {
        initials = result.join("").toUpperCase();
      }
    }
    return (
      <StyledBadge
        overlap="circular"
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        variant="dot"
        invisible={!online}
      >
        <Avatar
          {...rest}
          src={loading ? undefined : src || avatarUrl}
          className={classes.avatar}
        >
          {initials && !loading && (
            <div className={classes.initialsDiv}>{initials}</div>
          )}
          {loading && <CircularProgress size={10} />}
          {!loading && children}
        </Avatar>
      </StyledBadge>
    );
  }
);

// CSS Helpers

export const avatarSize = {
  xl: 56,
  lg: 44,
  md: 36,
  sm: 28,
  xs: 24,
  xxs: 16,
};

const textSize = {
  xl: 20,
  lg: 16,
  md: 14,
  sm: 12,
  xs: 10,
  xxs: 8,
};

const textLineHeight = {
  xl: 26,
  lg: 20.8,
  md: 28.2,
  sm: 18,
  xs: 15,
  xxs: 12,
};

const avatarBorderRadius = {
  xl: 28,
  lg: 22,
  md: 18,
  sm: 14,
  xs: 12,
  xxs: 8,
};

const badgeSize = {
  xl: 8,
  lg: 7,
  md: 6,
  sm: 6,
  xs: 5,
  xxs: 4,
};

const useStyles = makeStyles<StyleProps>()((theme, { loading, size }) => ({
  avatar: {
    width: avatarSize[size],
    height: avatarSize[size],
    borderRadius: avatarBorderRadius[size],
    backgroundColor: theme.palette.greyscale200,
    cursor: typeof loading === "boolean" ? "pointer" : "default",
  },
  initialsDiv: {
    width: "100%",
    height: "100%",
    color: theme.palette.midnight,
    fontSize: textSize[size],
    lineHeight: textLineHeight[size],
    fontWeight: 500,
    display: "flex",
    alignItems: "center",
    justifyContent: "space-around",
  },
  badge: {
    color: theme.palette.appGreen,
    width: badgeSize[size],
    height: badgeSize[size],
  },
}));

export default AccountAvatar;
