import { FileUpload } from "components/icons";
import { T } from "components/ui/Typography";
import { makeStyles } from "makeStyles";
import { useDropzone } from "react-dropzone";
import { defineMessages, FormattedMessage } from "react-intl";

import Tooltip from "./Tooltip";

type Props = {
  disabled?: boolean;
  onDrop: (files: File) => void;
};

const UploadZone = ({ disabled, onDrop }: Props) => {
  const handleDrop = (acceptedFiles: File[]) => {
    if (acceptedFiles.length > 0) {
      return onDrop(acceptedFiles[0]);
    }
  };
  const {
    rejectedFiles,
    acceptedFiles,
    getRootProps,
    getInputProps,
  } = useDropzone({
    disabled,
    onDrop: handleDrop,
    accept: ".csv",
    multiple: false,
    maxSize: 500000000, // 500Mb
  });
  const { classes, cx } = useStyles({ disabled });

  return (
    <>
      {rejectedFiles.length > 0 && (
        <p className={classes.errorText}>
          <FormattedMessage
            {...i18n.invalidFile}
            values={{ filename: rejectedFiles[0].name }}
          />
        </p>
      )}
      <Tooltip
        title={
          disabled ? (
            <T>
              <FormattedMessage {...i18n.enterNameFirst} />
            </T>
          ) : (
            ""
          )
        }
      >
        <div
          {...getRootProps({
            className: cx(classes.root, {
              [classes.success]: acceptedFiles.length > 0,
              [classes.error]: rejectedFiles.length > 0,
            }),
          })}
        >
          <input disabled={disabled} {...getInputProps()} />
          {acceptedFiles.length > 0 ? (
            <div className={classes.container}>
              <img
                src="/images/providers/CSV.png"
                alt=""
                className={classes.logo}
              />
              <T titleSmall>{acceptedFiles[0].name}</T>
            </div>
          ) : (
            <div className={classes.container}>
              <FileUpload className={classes.addIcon} />
              <span className={classes.textContainer}>
                <T titleSmall>{<FormattedMessage {...i18n.instructions} />}</T>
              </span>
            </div>
          )}
        </div>
      </Tooltip>
    </>
  );
};

export default UploadZone;

const useStyles = makeStyles<{ disabled?: boolean }>()(
  (theme, { disabled }) => ({
    root: {
      border: `1px dashed ${theme.palette.greyscale500}`,
      borderRadius: 8,
      cursor: disabled ? "no-drop" : "pointer",
      display: "flex",
      alignItems: "center",
      flexGrow: 1,
      padding: `${theme.spacing(2)} !important`,
      minWidth: 400,
    },
    logo: {
      height: 28,
      width: 28,
      borderRadius: 6,
    },
    container: {
      display: "flex",
      alignItems: "center",
      columnGap: theme.spacing(1.5),
      margin: "auto",
    },
    textContainer: {
      display: "flex",
      columnGap: theme.spacing(0.5),
      "& > p": {
        color: theme.palette.midnight,
        fontSize: 14,
        fontWeight: 500,
      },
    },
    addIcon: {
      height: 16,
      width: 16,
    },
    success: {
      borderColor: theme.palette.success.main,
    },
    error: {
      borderColor: theme.palette.error.main,
    },
    errorText: {
      color: theme.palette.error.main,
      fontSize: 13,
      marginBottom: theme.spacing(0.5),
    },
  })
);

const i18n = defineMessages({
  enterNameFirst: {
    id: "UploadZone.enterNameFirst",
    defaultMessage: "Please enter a name to identify your CSV source",
  },
  instructions: {
    id: "UploadZone.instructions",
    defaultMessage: "Drop CSV file here or browse",
  },
  invalidFile: {
    id: "UploadZone.invalidFile",
    defaultMessage: 'The file "{filename}" is not valid',
  },
});
