import { Sidebar } from "components/icons";
import Button from "components/ui/Button";
import { T } from "components/ui/Typography";
import useSelectorWithDeepEquality from "hooks/useSelectorWithDeepEquality";
import { makeStyles } from "makeStyles";
import Match from "models/Match";
import PartnerConnection from "models/PartnerConnection";
import { useHistory } from "react-router-dom";
import { selectPerspective } from "redux/pipeline/selectors";

type Props = {
  isDisabled?: boolean;
  partnerConnection?: PartnerConnection | null;
};

export const AccountNameOwner = ({
  isDisabled = false,
  partnerConnection,
}: Props) => {
  const { classes } = useStyles();
  const history = useHistory();
  const perspective = useSelectorWithDeepEquality(selectPerspective);
  const isInbound = perspective !== Match.PERSPECTIVE_THEIRS;

  const handleClick = () => {
    history.push({
      search: `?selected=${partnerConnection?.rawCompanyId}`,
    });
  };

  return (
    <div className={classes.accountNameOwner}>
      <T
        bold
        className={classes.ellipsis}
        title={partnerConnection?.rawCompanyName ?? undefined}
      >
        {partnerConnection?.rawCompanyName}
      </T>
      {partnerConnection?.rawCompanyOwnerName && (
        <>
          <span className={classes.alpha500}>&middot;</span>
          <T
            className={classes.ellipsis}
            title={partnerConnection.rawCompanyOwnerName}
          >
            {partnerConnection.rawCompanyOwnerName}
          </T>
        </>
      )}
      {isInbound && !isDisabled && (
        <Button
          classes={{ btn: classes.btn, icon: classes.icon }}
          label={i18n.open}
          onClick={handleClick}
          RightIcon={Sidebar}
          size="xSmall"
          variant="tertiary"
        />
      )}
    </div>
  );
};

const useStyles = makeStyles()((theme) => ({
  accountNameOwner: {
    alignItems: "center",
    display: "flex",
    gap: 4,
  },
  alpha500: {
    color: theme.palette.alpha500,
  },
  btn: {
    marginLeft: "auto",
  },
  ellipsis: {
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
  },
  icon: {
    height: 12,
  },
}));

const i18n = {
  open: {
    id:
      "Datables.Pipeline.MessageDrawer.DiscussionDetails.AccountNameOwner.open",
    defaultMessage: "Open",
  },
};
