import ChevronDown from "components/icons/ChevronDown";
import Button, { Size } from "components/ui/Button";
import DropdownOption from "components/ui/DropdownOption";
import DropdownPopover from "components/ui/DropdownPopover";
import { T } from "components/ui/Typography";
import { isMessageDescriptor } from "helpers/isMessageDescriptor";
import { makeStyles } from "makeStyles";
import { useState } from "react";
import { MessageDescriptor, useIntl } from "react-intl";

type Variant = "primary" | "secondary" | "tertiary" | "quaternary" | "quinary";

export type Option<L = string | MessageDescriptor, K = string> = {
  value: K | null;
  label: L;
  logo?: string;
};

export type Props<L, K> = {
  disabled?: boolean;
  label?: L;
  onChange: (option: Option<L, K>) => void;
  options: Option<L, K>[];
  value: string | null;
  variant: Variant;
  size: Size;
  onClick?: () => void;
};

export const SettingsSelect = <
  L extends string | MessageDescriptor = string | MessageDescriptor,
  K = string
>({
  disabled = false,
  label,
  onChange,
  options,
  value,
  variant = "quinary",
  size = "small",
  onClick,
}: Props<L, K>) => {
  const { classes } = useStyles();
  const intl = useIntl();
  const [anchorEl, setAnchorEl] = useState<
    HTMLDivElement | HTMLButtonElement
  >();

  const handleOpenOptions = (
    event: React.MouseEvent<HTMLDivElement | HTMLButtonElement>
  ) => {
    setAnchorEl(event.currentTarget);
    if (onClick !== undefined) {
      onClick();
    }
  };

  const handleCloseOptions = () => {
    setAnchorEl(undefined);
  };

  const handleChange = (option: Option<L, K>) => {
    onChange(option);
    handleCloseOptions();
  };

  const _label =
    label ?? options.find((option) => option.value === value)?.label;

  return (
    <>
      <Button
        label={_label}
        onClick={handleOpenOptions}
        variant={variant}
        RightIcon={ChevronDown}
        size={size}
        disabled={disabled}
        classes={{ content: classes.content }}
      />
      <DropdownPopover
        anchorEl={anchorEl}
        onClose={handleCloseOptions}
        open={Boolean(anchorEl)}
        position="right"
      >
        {options.map((option) => {
          const optionLabel = isMessageDescriptor(option.label)
            ? intl.formatMessage(option.label)
            : String(option.label);
          return (
            <DropdownOption
              key={String(option.value)}
              isActive={option.value === value}
              onClick={() => handleChange(option)}
              classes={{ option: classes.option }}
            >
              {option.logo && (
                <img
                  src={option.logo}
                  style={{ height: 20, marginRight: 10 }}
                  alt={optionLabel}
                />
              )}
              <T>{optionLabel}</T>
            </DropdownOption>
          );
        })}
      </DropdownPopover>
    </>
  );
};

export default SettingsSelect;

/// CSS

const useStyles = makeStyles()((theme, props) => ({
  option: {
    flexDirection: "row",
  },
  content: {
    paddingLeft: theme.spacing(1.6),
    paddingRight: theme.spacing(1.0),
  },
}));
