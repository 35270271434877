import { Box } from "@mui/material";
import Button from "components/ui/Button";
import { IDropdownOption } from "components/ui/Dropdown/components/types";
import Tooltip from "components/ui/Tooltip";
import { makeStyles } from "makeStyles";
import { Kind } from "models/PartnerConnection";
import { defineMessages, useIntl } from "react-intl";
import { AvailablePipelineColumns } from "redux/pipeline/defaults";

import { CellProps } from "../../../../utils";
import { DropdownCell } from "../../../shared/DropdownCell";
import { useKindCell } from "../hooks/useKindCell";
import { DropdownFooter } from "./DropdownFooter";

const KindCell = ({ row, value }: CellProps) => {
  const intl = useIntl();

  const {
    allowEdit,
    CustomHeader,
    disabledText,
    getLabelWithIcon,
    optionsGroups,
    updateAttributes,
    currentKindValue,
    setCurrentKindValue,
    clearAttributes,
  } = useKindCell({ row });
  const { classes } = useStyles();

  if (!allowEdit) {
    let label;
    if (!value) {
      label = "";
    } else {
      label = getLabelWithIcon(value);
    }
    if (disabledText) {
      return (
        <Tooltip title={disabledText} placement="top">
          <Box overflow="hidden">
            <Button label={label} size="small" variant={"septenary"} ellipsis />
          </Box>
        </Tooltip>
      );
    }
    return <>{label}</>;
  }

  const handleChange = (option: IDropdownOption) => {
    setCurrentKindValue(option.id as Kind);
  };

  const handleSave = (attributionDate: string | null) => {
    updateAttributes(attributionDate);
  };

  return (
    <DropdownCell<Kind>
      classes={{ spacer: classes.divider }}
      CustomHeader={CustomHeader}
      CustomFooter={
        <DropdownFooter
          isDisabled={!(currentKindValue ?? value)}
          onSave={handleSave}
          value={row.activityDate}
          onClear={clearAttributes}
        />
      }
      optionsGroups={optionsGroups}
      getLabel={getLabelWithIcon}
      selectedItem={currentKindValue ?? value}
      unselectedText={intl.formatMessage(i18n.chooseKind)}
      onChange={handleChange}
      cellType="kind"
      withSearch={false}
      size={224}
      onCloseCallback={() => setCurrentKindValue(null)}
    />
  );
};

export default KindCell;

// Helpers

export const isKindCell = ({ fieldName }: CellProps) =>
  fieldName === AvailablePipelineColumns.kind;

// CSS

const useStyles = makeStyles()((theme) => ({
  divider: {
    margin: "8px -12px",
    borderBottom: `1px solid ${theme.palette.taupe500}`,
    width: "unset",
  },
}));

// I18N

const i18n = defineMessages({
  chooseKind: {
    id: "CellRenderer.Pipeline.KindCell.chooseKind",
    defaultMessage: "Attribute",
  },
});
