import { isFulfilled, isRejected } from "@reduxjs/toolkit";
import { ExtendedAccountMappingResource } from "components/ui/filters/smartView/constants";
import { selectSavedFilters } from "components/ui/filters/smartView/selectors";
import { JSONAPIAttributes } from "models/types";
import { useCallback } from "react";
import { defineMessages } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { destroy, indexAll, rawPatch, rawPost } from "redux/api/thunks";

import usePushNotification from "./usePushNotification";

export const useSmartViews = (
  accountType: ExtendedAccountMappingResource,
  partnershipId?: number
) => {
  const dispatch = useDispatch();
  const pushNotification = usePushNotification();

  const { records, totalCount } = useSelector(
    selectSavedFilters(accountType, partnershipId)
  );

  const fetchViews = useCallback(async () => {
    return await dispatch(indexAll({ type: "saved_filters" }));
  }, [dispatch]);

  const addView = useCallback(
    async (attributes: JSONAPIAttributes) => {
      const result = await dispatch(
        rawPost({
          type: "saved_filters",
          path: "",
          payload: {
            data: {
              type: "saved_filters",
              attributes,
            },
          },
        })
      );
      if (isFulfilled(result)) {
        pushNotification(i18n.savedView);
      }
      if (isRejected(result)) {
        if (result.error.message === "409") {
          pushNotification(i18n.duplicateKeyError, { name: attributes.name });
        } else {
          pushNotification(i18n.failedToCreateView);
        }
      }
      await fetchViews();
      return result;
    },
    [dispatch, pushNotification, fetchViews]
  );

  const deleteView = useCallback(
    async (id: number) => {
      const result = await dispatch(
        destroy({
          type: "saved_filters",
          id,
        })
      );
      await fetchViews();
      return result;
    },
    [dispatch, fetchViews]
  );

  const updateView = useCallback(
    async (id: number, attributes: JSONAPIAttributes) => {
      return await dispatch(
        rawPatch({
          type: "saved_filters",
          id,
          path: "",
          payload: {
            data: {
              type: "saved_filters",
              attributes,
              id: id,
            },
          },
        })
      );
    },
    [dispatch]
  );

  return {
    deleteView,
    fetchViews,
    addView,
    records,
    totalCount,
    updateView,
  };
};

const i18n = defineMessages({
  duplicateKeyError: {
    id: "useSmartViews.duplicateKeyError",
    defaultMessage: '"{name}" is already in use. Please choose another name.',
  },
  failedToCreateView: {
    id: "useSmartViews.failedToCreateView",
    defaultMessage: "Failed to create view",
  },
  savedView: {
    id: "useSmartViews.savedView",
    defaultMessage: "Saved new view",
  },
});
