import { DialogContent, FormControl, FormHelperText } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import Grid from "@mui/material/Grid";
import { isFulfilled } from "@reduxjs/toolkit";
import Button from "components/ui/Button";
import SettingsSection from "components/ui/SettingsSection";
import { TextInput } from "components/ui/TextInput";
import Toggle from "components/ui/Toggle";
import { T } from "components/ui/Typography";
import usePushNotification from "hooks/usePushNotification";
import useUserProfile from "hooks/useUserProfile";
import generic from "i18n/generic";
import { makeStyles } from "makeStyles";
import User from "models/User";
import { QRCodeSVG } from "qrcode.react";
import { ChangeEvent, useCallback, useState } from "react";
import { defineMessages, FormattedMessage, useIntl } from "react-intl";
import { useDispatch } from "react-redux";
import { disableMFA, enableMFA } from "redux/user/thunks";

import OTPForm from "../../Login/components/OTPForm";

const Settings = () => {
  const { classes } = useStyles();
  const { profile } = useUserProfile();

  const [isOpen, setIsOpen] = useState(false);

  const toggle = useCallback(() => {
    setIsOpen((value) => !value);
  }, []);

  return (
    <SettingsSection title={<FormattedMessage {...i18n.title} />}>
      <div className={classes.toggleLabelGroup}>
        <label className={classes.label}>
          <Toggle
            checked={profile.mfaEnabled}
            handleChange={toggle}
            size="big"
          />
          <T h4 bold>
            <FormattedMessage {...i18n.label} />
          </T>
        </label>
        <T bodySmall>
          <FormattedMessage {...i18n.subtitle} />
        </T>
        <ToggleMFADialog
          profile={profile}
          isOpen={isOpen}
          close={() => setIsOpen(false)}
        />
      </div>
    </SettingsSection>
  );
};

export default Settings;

/// Dialogs

type ToggleMFADialogProps = {
  profile: User;
  isOpen: boolean;
  close: () => void;
};

const ToggleMFADialog = ({ profile, isOpen, close }: ToggleMFADialogProps) => {
  const { classes } = useStyles();

  return (
    <Dialog open={isOpen} onClose={close} classes={{ paper: classes.dialog }}>
      <DialogContent>
        <T h3 bold textAlign="center" className={classes.contentMain}>
          <FormattedMessage
            {...(profile.mfaEnabled ? i18n.disableMFA : i18n.enableMFA)}
          />
        </T>
        {profile.mfaEnabled && (
          <T className={classes.contentSub}>
            <FormattedMessage {...i18n.needPassword} />
          </T>
        )}
        {profile.mfaEnabled ? (
          <DisableContent onSuccess={close} />
        ) : (
          <OTPContent uri={profile.otpUri as string} onSuccess={close} />
        )}
      </DialogContent>
    </Dialog>
  );
};

type OTPContentProps = {
  uri: string;
  onSuccess: () => void;
};

const OTPContent = ({ uri, onSuccess }: OTPContentProps) => {
  const { classes } = useDialogStyles();
  const dispatch = useDispatch();
  const pushNotification = usePushNotification();

  const handleChange = useCallback(
    async (value: string) => {
      const result = await dispatch(enableMFA(value));
      if (isFulfilled(result)) {
        pushNotification(i18n.MFAEnabled);
        onSuccess();
      }
    },
    [dispatch, onSuccess, pushNotification]
  );

  return (
    <Grid container className={classes.body}>
      <Grid item xs={12} sm={4}>
        <QRCodeSVG value={uri} />
      </Grid>
      <Grid item container direction="column" xs={12} sm={8} spacing={2}>
        <Grid item>
          <T>
            <FormattedMessage {...i18n.enableMFAStep1} />
          </T>
        </Grid>
        <Grid item>
          <T>
            <FormattedMessage {...i18n.enableMFAStep2} />
          </T>
        </Grid>
        <Grid item>
          <div className={classes.row}>
            <OTPForm onSubmit={handleChange} />
          </div>
        </Grid>
      </Grid>
    </Grid>
  );
};

type DisableContentProps = {
  onSuccess: () => void;
};

const DisableContent = ({ onSuccess }: DisableContentProps) => {
  const { classes } = useDialogStyles();
  const intl = useIntl();
  const [password, setPassword] = useState("");
  const [error, setError] = useState(false);
  const dispatch = useDispatch();
  const pushNotification = usePushNotification();

  const handleChange = useCallback(
    async (evt: ChangeEvent<HTMLInputElement>) => {
      const value = evt.target.value;
      setPassword(value);
      setError(false);
    },
    []
  );

  const submit = useCallback(async () => {
    if (password) {
      const result = await dispatch(disableMFA(password));
      if (isFulfilled(result)) {
        pushNotification(i18n.MFADisabled);
        onSuccess();
      } else {
        setError(true);
      }
    }
  }, [password, onSuccess, dispatch, pushNotification]);

  return (
    <Grid container direction="column" className={classes.body}>
      <Grid item xs={12}>
        <FormControl fullWidth error={!!error}>
          <TextInput
            fullWidth
            name="password"
            onChange={handleChange}
            error={error}
            type="password"
            placeholder={intl.formatMessage(generic.password)}
          />
          <FormHelperText>
            {error && <FormattedMessage {...i18n.invalidPassword} />}
          </FormHelperText>
        </FormControl>
      </Grid>
      <Grid item className={classes.dialogActions}>
        <Button
          label={i18n.disableMFABtn}
          disabled={!password}
          onClick={submit}
        />
      </Grid>
    </Grid>
  );
};

/// CSS

const useStyles = makeStyles()((theme) => ({
  contentMain: {
    marginBottom: theme.spacing(1.5),
    textAlign: "center",
  },
  contentSub: {
    marginBottom: theme.spacing(2),
    textAlign: "center",
  },
  dialog: {
    borderRadius: "12px",
    maxWidth: "640px",
    "& .MuiDialogContent-root": {
      padding: "30px 45px",
    },
  },
  toggleLabelGroup: {
    display: "flex",
    flexDirection: "column",
    gap: theme.spacing(1),
  },
  label: {
    marginBottom: 0,
    display: "flex",
    alignItems: "center",
    gap: 12,
    [theme.breakpoints.down("md")]: {
      marginBottom: theme.spacing(2),
    },
  },
}));

const useDialogStyles = makeStyles()((theme) => ({
  body: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(1),
  },
  dialogActions: {
    marginTop: theme.spacing(4),
    textAlign: "center",
  },
  row: {
    display: "flex",
    gap: theme.spacing(1),
    alignItems: "center",
  },
}));

/// I18N

const i18n = defineMessages({
  title: {
    id: "Profile.SecuritySettings.title",
    defaultMessage: "Multi-Factor Authentication",
  },
  subtitle: {
    id: "Profile.SecuritySettings.subtitle",
    defaultMessage:
      "Make your account more secure with Multi-Factor Authentication (MFA). MFA requires both a password and verification code to log in.",
  },
  label: {
    id: "Profile.SecuritySettings.label",
    defaultMessage: "Enable MFA",
  },
  enableMFA: {
    id: "Profile.SecuritySettings.enableMFA",
    defaultMessage: "Enable Multi-Factor Authentication",
  },
  MFAEnabled: {
    id: "Profile.SecuritySettings.MFAEnabled",
    defaultMessage: "Multi-Factor Authentication has been enabled",
  },
  MFADisabled: {
    id: "Profile.SecuritySettings.MFADisabled",
    defaultMessage: "Multi-Factor Authentication has been disabled",
  },
  enableMFAStep1: {
    id: "Profile.SecuritySettings.enableMFAStep1",
    defaultMessage:
      "1. Scan the QR code using an authenticator app (Google Authenticator for instance)",
  },
  enableMFAStep2: {
    id: "Profile.SecuritySettings.enableMFAStep2",
    defaultMessage: "2. Enter the generated 6-digit one-time code below",
  },
  disableMFA: {
    id: "Profile.SecuritySettings.disableMFA",
    defaultMessage: "Disable Multi-Factor Authentication",
  },
  disableMFABtn: {
    id: "Profile.SecuritySettings.disableMFABtn",
    defaultMessage: "Disable MFA",
  },
  invalidOTP: {
    id: "Profile.SecuritySettings.invalidOTP",
    defaultMessage: "Invalid Code",
  },
  invalidPassword: {
    id: "Profile.SecuritySettings.invalidPassword",
    defaultMessage: "Invalid Password",
  },
  needPassword: {
    id: "Profile.SecuritySettings.needPwd",
    defaultMessage:
      "For security reasons, we need to verify your password before disabling Multi-Factor Authentication.",
  },
});
