import Integration from "./Integration";
import { Record } from "./Record";
import { JSONAPIResource } from "./types";

export default class FileSource extends Record {
  integration: Integration;

  constructor(data: JSONAPIResource | Record) {
    super(data);
    if (this.relationships?.integration?.data === undefined) {
      throw TypeError("integration is required for a FileSource");
    }
    this.integration = this.relationships?.integration.data as Integration;
  }
}
