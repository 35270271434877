import { ChevronDown, ChevronTop, Plus } from "components/icons";
import Button from "components/ui/Button";
import Dropdown from "components/ui/Dropdown/components/Dropdown";
import _ from "lodash";
import CrmField from "models/CrmField";
import { useCallback, useEffect, useMemo, useState } from "react";
import { defineMessages, useIntl } from "react-intl";

import { IDropdownOption } from "../Dropdown/components/types";
import { useSWQLContext } from "./SWQLContext";
import {
  emptyNode,
  NodeType,
  SWQLField,
  SWQLFieldType,
  SWQLLookupNode,
  SWQLTarget,
} from "./SWQLTypes";
import { mapCrmFieldToSWQLField } from "./utils";

const ACCOUNT_LEVEL_RECORD = "RawCompany";
const DEFAULT_OWNER_CRM_NAME = "OwnerId";

export type LookupValue =
  | "lookup_anyMatch"
  | "lookup_allMatch"
  | "lookup_noMatch";

type Props = {
  handleChange: (value: SWQLField | null) => void;
  handleChangeToLookup?: (value: SWQLLookupNode) => void;
  value?: SWQLField | LookupValue | null;
  filterOptions?: (options: SWQLField[]) => SWQLField[];
  allowEmptyValue?: boolean;
  partnerField?: SWQLField;
  size?: "small" | "normal";
  swTypes?: SWQLFieldType[];
};

const CrmFieldSelectDropdown = ({
  handleChange,
  handleChangeToLookup,
  value,
  filterOptions,
  allowEmptyValue,
  partnerField,
  size = "small",
  swTypes,
}: Props) => {
  const intl = useIntl();
  const { integrationId, swqlTarget, enableDateTimes } = useSWQLContext();

  const [anchorEl, setAnchorEl] = useState<Element | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isValueInitialized, setIsValueInitialized] = useState(false);
  const [options, setOptions] = useState<SWQLField[]>(
    isSWQLField(value) ? [value] : []
  );
  const [inputValue, setInputValue] = useState("");

  const loadIntegrationOptions = useMemo(
    () => _.throttle(loadOptions, 150),
    []
  );

  useEffect(() => {
    let active = true;

    const onSuccess = (options: SWQLField[]) => {
      if (!active) {
        return;
      }
      setOptions(filterOptions ? filterOptions(options) : options);
      setIsValueInitialized(true);
      if (partnerField?.value && !isValueInitialized) {
        const field = options?.find(
          (item) =>
            item.value === DEFAULT_OWNER_CRM_NAME &&
            item.recordType === ACCOUNT_LEVEL_RECORD
        );
        if (field) {
          handleChange(field);
        }
      }
    };
    const onError = (error: Error) => {
      if (active) {
      }
    };

    if (!anchorEl && isValueInitialized) {
      return;
    }

    loadIntegrationOptions(
      inputValue,
      Number(integrationId),
      swqlTarget,
      enableDateTimes,
      onSuccess,
      onError,
      setIsLoading,
      swTypes
    );
    return () => {
      active = false;
    };
  }, [
    anchorEl,
    inputValue,
    integrationId,
    loadIntegrationOptions,
    swqlTarget,
    filterOptions,
    enableDateTimes,
    swTypes,
    partnerField?.value,
    handleChange,
    isValueInitialized,
  ]);

  const handleOpenDropdown = useCallback((event: React.SyntheticEvent) => {
    setAnchorEl(event.currentTarget);
  }, []);

  const handleCloseDropdown = useCallback(() => {
    setAnchorEl(null);
  }, []);

  const handleValueChanged = useCallback(
    (value: string[] | null) => {
      if (value && value[0].startsWith("lookup") && handleChangeToLookup) {
        let operator: "anyMatch" | "allMatch" | "noMatch";
        switch (value[0]) {
          case "lookup_anyMatch":
            operator = "anyMatch";
            break;

          case "lookup_allMatch":
            operator = "allMatch";
            break;

          case "lookup_noMatch":
            operator = "noMatch";
            break;

          default:
            return;
        }
        handleChangeToLookup({
          type: NodeType.Lookup,
          operation: operator,
          rule: emptyNode(),
          lookup_path: SWQLTarget.RawOpportunity,
        });
        handleCloseDropdown();
      } else {
        const selectedValue = options?.find(
          (o) => value && String(o.id) === value[0]
        );
        if (selectedValue) {
          handleChange(selectedValue);
        } else if (allowEmptyValue) {
          handleChange(null);
        }
        handleCloseDropdown();
      }
    },
    [
      handleChange,
      handleCloseDropdown,
      handleChangeToLookup,
      options,
      allowEmptyValue,
    ]
  );

  const rawValue = typeof value === "string" ? value : String(value?.id);

  const preparedOptions = useMemo<IDropdownOption[]>(() => {
    const recordOptions = options.map((o) => ({
      id: String(o.id),
      name:
        partnerField?.recordType && ACCOUNT_LEVEL_RECORD === o.recordType
          ? `${partnerField.label} > ${o.label}`
          : o.label,
    }));
    if (
      !partnerField?.recordType &&
      _.isEqual(swqlTarget, [SWQLTarget.RawCompany])
    ) {
      return [
        {
          id: "opportunity",
          name: intl.formatMessage(i18n.lookup_opportunity),
          subItems: [
            {
              id: "lookup_anyMatch",
              name: intl.formatMessage(i18n.lookup_forAnyOpport),
            },
            {
              id: "lookup_allMatch",
              name: intl.formatMessage(i18n.lookup_forAllOpports),
            },
            {
              id: "lookup_noMatch",
              name: intl.formatMessage(i18n.lookup_forNoOpport),
            },
          ],
        },
        ...recordOptions,
      ];
    }
    return recordOptions;
  }, [
    options,
    partnerField?.label,
    partnerField?.recordType,
    swqlTarget,
    intl,
  ]);

  const buttonLabel = useMemo(() => {
    if (value == null) {
      return intl.formatMessage(i18n.selectFieldPlaceholder);
    }
    if (typeof value === "string") {
      switch (value) {
        case "lookup_anyMatch":
          return intl.formatMessage(i18n.lookup_forAnyOpport);
        case "lookup_allMatch":
          return intl.formatMessage(i18n.lookup_forAllOpports);
        case "lookup_noMatch":
          return intl.formatMessage(i18n.lookup_forNoOpport);
      }
    }
    if (partnerField?.recordType && ACCOUNT_LEVEL_RECORD === value.recordType) {
      return `${partnerField.label} > ${value.label}`;
    }
    return value.label;
  }, [value, partnerField?.label, partnerField?.recordType, intl]);

  return (
    <>
      <Button
        LeftIcon={!value ? Plus : undefined}
        RightIcon={value ? (anchorEl ? ChevronTop : ChevronDown) : undefined}
        variant={value ? "tertiary" : "primary"}
        label={buttonLabel}
        onClick={handleOpenDropdown}
        size="small"
      />
      <Dropdown
        isLoading={isLoading}
        value={[rawValue]}
        onChange={handleValueChanged}
        options={preparedOptions}
        open={!!anchorEl}
        onClose={handleCloseDropdown}
        anchorEl={anchorEl}
        size={size}
        hasAsyncSearch
        onAsyncSearch={setInputValue}
        allowEmptyValue={allowEmptyValue}
      />
    </>
  );
};

export default CrmFieldSelectDropdown;

const loadOptions = async (
  value: string,
  integrationId: number,
  swqlTarget: SWQLTarget[],
  enableDateTimes: boolean,
  onSuccess: (value: SWQLField[]) => void,
  onError: (value: Error) => void,
  setLoading: (value: boolean) => void,
  swTypes?: SWQLFieldType[]
) => {
  setLoading(true);
  try {
    const result = await CrmField.swqlAutocomplete(
      value,
      integrationId,
      swqlTarget,
      enableDateTimes,
      undefined,
      swTypes
    );
    onSuccess(result.records.map(mapCrmFieldToSWQLField));
  } catch (error) {
    onError(error as Error);
  } finally {
    setLoading(false);
  }
};

const i18n = defineMessages({
  selectFieldPlaceholder: {
    id: "SWQL.SWQLRule.selectFieldPlaceholder",
    defaultMessage: "Select CRM field",
  },
  lookup_opportunity: {
    id: "SWQL.SWQLRule.lookup_opportunity",
    defaultMessage: "Opportunity",
  },
  lookup_forAnyOpport: {
    id: "SWQL.SWQLRule.lookup_forAnyOpport",
    defaultMessage: "For any opportunity",
  },
  lookup_forAllOpports: {
    id: "SWQL.SWQLRule.lookup_forAllOpports",
    defaultMessage: "For all opportunities",
  },
  lookup_forNoOpport: {
    id: "SWQL.SWQLRule.lookup_forNoOpport",
    defaultMessage: "For no opportunity",
  },
});

/// Helpers

function isSWQLField(value?: string | null | SWQLField): value is SWQLField {
  return _.isObject(value);
}
