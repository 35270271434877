import { defineMessages, FormattedMessage } from "react-intl";

const i18n = defineMessages({
  _1: {
    id: "models.Company.size.1",
    defaultMessage: "1 employee",
  },
  _2_10: {
    id: "models.Company.size.2_10",
    defaultMessage: "2-10 employees",
  },
  _11_50: {
    id: "models.Company.size.11_50",
    defaultMessage: "11-50 employees",
  },
  _51_200: {
    id: "models.Company.size.51_200",
    defaultMessage: "51-200 employees",
  },
  _201_500: {
    id: "models.Company.size.201_500",
    defaultMessage: "201-500 employees",
  },
  _501_1000: {
    id: "models.Company.size.501_1000",
    defaultMessage: "501-1000 employees",
  },
  _1000_plus: {
    id: "models.Company.size.10000_plus",
    defaultMessage: "+1000 employees",
  },
  accounting: {
    id: "models.Company.ecosystem.accounting",
    defaultMessage: "Accounting",
  },
  collaborationAndProductivity: {
    id: "models.Company.ecosystem.collaborationAndProductivity",
    defaultMessage: "Collaboration & Productivity",
  },
  ecommerce: {
    id: "models.Company.ecosystem.ecommerce",
    defaultMessage: "E-commerce",
  },
  finance: {
    id: "models.Company.ecosystem.finance",
    defaultMessage: "Finance",
  },
  itAndAdministration: {
    id: "models.Company.ecosystem.itAndAdministration",
    defaultMessage: "IT and administration",
  },
  health: {
    id: "models.Company.ecosystem.health",
    defaultMessage: "Health",
  },
  humanResources: {
    id: "models.Company.ecosystem.humanResources",
    defaultMessage: "Human Resources",
  },
  legal: {
    id: "models.Company.ecosystem.legal",
    defaultMessage: "Legal",
  },
  marketingAndAnalytics: {
    id: "models.Company.ecosystem.marketingAndAnalytics",
    defaultMessage: "Marketing and Analytics",
  },
  sales: {
    id: "models.Company.ecosystem.sales",
    defaultMessage: "Sales",
  },
  agencies: {
    id: "models.Company.type.agencies",
    defaultMessage: "Agencies",
  },
  consulting: {
    id: "models.Company.type.consulting",
    defaultMessage: "Consulting",
  },
  hardwareVendors: {
    id: "models.Company.type.hardwareVendors",
    defaultMessage: "Hardware vendors",
  },
  softwareVendors: {
    id: "models.Company.type.softwareVendors",
    defaultMessage: "Software vendors",
  },
  cybersecurity: {
    id: "models.Company.ecosystem.cybersecurity",
    defaultMessage: "Cybersecurity",
  },
  customerExperience: {
    id: "models.Company.ecosystem.customerExperience",
    defaultMessage: "Customer experience",
  },
  marketplace: {
    id: "models.Company.ecosystem.marketplace",
    defaultMessage: "Marketplace",
  },
  customerSuccess: {
    id: "models.Company.ecosystem.customerSuccess",
    defaultMessage: "Customer success",
  },
  foodtechAndRestaurant: {
    id: "models.Company.ecosystem.foodtechAndRestaurant",
    defaultMessage: "Foodtech and Restaurant",
  },
  hotelAndTravel: {
    id: "models.Company.ecosystem.hotelAndTravel",
    defaultMessage: "Hotel and Travel",
  },
  other: {
    id: "models.Company.other",
    defaultMessage: "Other",
  },
});

// Company Size Enum

export enum CompanySize {
  _1 = "1",
  _2_10 = "2_10",
  _11_50 = "11_50",
  _51_200 = "51_200",
  _201_500 = "201_500",
  _501_1000 = "501_1000",
  _1000_plus = "1000_plus",
}

export const COMPANY_SIZE_LABELS = {
  [CompanySize._1]: <FormattedMessage {...i18n._1} />,
  [CompanySize._2_10]: <FormattedMessage {...i18n._2_10} />,
  [CompanySize._11_50]: <FormattedMessage {...i18n._11_50} />,
  [CompanySize._51_200]: <FormattedMessage {...i18n._51_200} />,
  [CompanySize._201_500]: <FormattedMessage {...i18n._201_500} />,
  [CompanySize._501_1000]: <FormattedMessage {...i18n._501_1000} />,
  [CompanySize._1000_plus]: <FormattedMessage {...i18n._1000_plus} />,
};

// Company Ecosystem Enum

export enum CompanyEcosystem {
  accounting = "accounting",
  collaborationAndProductivity = "collaboration_and_productivity",
  ecommerce = "ecommerce",
  finance = "finance",
  itAndAdministration = "it_and_administration",
  health = "health",
  humanResources = "human_resources",
  legal = "legal",
  marketingAndAnalytics = "marketing_and_analytics",
  sales = "sales",
  cybersecurity = "cybersecurity",
  customer_experience = "customer_experience",
  marketplace = "marketplace",
  customer_success = "customer_success",
  foodtech_and_restaurant = "foodtech_and_restaurant",
  hotel_and_travel = "hotel_and_travel",
  other = "other",
}

export const COMPANY_ECOSYSTEM_TO_CHILI_PIPER_VALUES = {
  [CompanyEcosystem.accounting]: "Accounting",
  [CompanyEcosystem.collaborationAndProductivity]:
    "Collaboration & Productivity",
  [CompanyEcosystem.ecommerce]: "E-commerce",
  [CompanyEcosystem.finance]: "Finance",
  [CompanyEcosystem.itAndAdministration]: "IT and administration",
  [CompanyEcosystem.health]: "Health",
  [CompanyEcosystem.humanResources]: "Human Resources",
  [CompanyEcosystem.legal]: "Legal",
  [CompanyEcosystem.marketingAndAnalytics]: "Marketing and Analytics",
  [CompanyEcosystem.sales]: "Sales",
  [CompanyEcosystem.cybersecurity]: "Cybersecurity",
  [CompanyEcosystem.customer_experience]: "Customer experience",
  [CompanyEcosystem.marketplace]: "Marketplace",
  [CompanyEcosystem.customer_success]: "Customer success",
  [CompanyEcosystem.foodtech_and_restaurant]: "Foodtech and Restaurant",
  [CompanyEcosystem.hotel_and_travel]: "Hotel and Travel",
  [CompanyEcosystem.other]: "Other",
};

export const COMPANY_ECOSYSTEM_LABELS = {
  [CompanyEcosystem.accounting]: <FormattedMessage {...i18n.accounting} />,
  [CompanyEcosystem.collaborationAndProductivity]: (
    <FormattedMessage {...i18n.collaborationAndProductivity} />
  ),
  [CompanyEcosystem.ecommerce]: <FormattedMessage {...i18n.ecommerce} />,
  [CompanyEcosystem.finance]: <FormattedMessage {...i18n.finance} />,
  [CompanyEcosystem.itAndAdministration]: (
    <FormattedMessage {...i18n.itAndAdministration} />
  ),
  [CompanyEcosystem.health]: <FormattedMessage {...i18n.health} />,
  [CompanyEcosystem.humanResources]: (
    <FormattedMessage {...i18n.humanResources} />
  ),
  [CompanyEcosystem.legal]: <FormattedMessage {...i18n.legal} />,
  [CompanyEcosystem.marketingAndAnalytics]: (
    <FormattedMessage {...i18n.marketingAndAnalytics} />
  ),
  [CompanyEcosystem.sales]: <FormattedMessage {...i18n.sales} />,
  [CompanyEcosystem.cybersecurity]: (
    <FormattedMessage {...i18n.cybersecurity} />
  ),
  [CompanyEcosystem.customer_experience]: (
    <FormattedMessage {...i18n.customerExperience} />
  ),
  [CompanyEcosystem.marketplace]: <FormattedMessage {...i18n.marketplace} />,
  [CompanyEcosystem.customer_success]: (
    <FormattedMessage {...i18n.customerSuccess} />
  ),
  [CompanyEcosystem.foodtech_and_restaurant]: (
    <FormattedMessage {...i18n.foodtechAndRestaurant} />
  ),
  [CompanyEcosystem.hotel_and_travel]: (
    <FormattedMessage {...i18n.hotelAndTravel} />
  ),
  [CompanyEcosystem.other]: <FormattedMessage {...i18n.other} />,
};

// Company Business type Enum

export enum CompanyType {
  agencies = "agencies",
  consulting = "consulting",
  hardwareVendors = "hardware_vendors",
  softwareVendors = "software_vendors",
  other = "other",
}

export const COMPANY_TYPE_LABELS = {
  [CompanyType.agencies]: <FormattedMessage {...i18n.agencies} />,
  [CompanyType.consulting]: <FormattedMessage {...i18n.consulting} />,
  [CompanyType.hardwareVendors]: <FormattedMessage {...i18n.hardwareVendors} />,
  [CompanyType.softwareVendors]: <FormattedMessage {...i18n.softwareVendors} />,
  [CompanyType.other]: <FormattedMessage {...i18n.other} />,
};

// Company Pricing Plan type Enum

export enum FeatureGroupName {
  Free = "Free",
  Pro = "Pro",
  Power = "Power",
  LeadGen = "Lead Generation Package",
  Engineering = "Engineering Only",
}

export type PlanType =
  | FeatureGroupName.Free
  | FeatureGroupName.Pro
  | FeatureGroupName.Power;
