import { Box } from "@mui/material";
import { isRejected } from "@reduxjs/toolkit";
import { Plus } from "components/icons";
import {
  BasicDialog,
  BasicDialogVariant,
} from "components/ui/BasicDialog/BasicDialog";
import Button from "components/ui/Button";
import DialogManager from "components/ui/DialogManager";
import Dropdown from "components/ui/Dropdown";
import { IDropdownOption } from "components/ui/Dropdown/components/types";
import { NotificationStatus } from "components/ui/Notifications/NotificationSnackbar";
import Tooltip from "components/ui/Tooltip";
import { T } from "components/ui/Typography";
import { getLogo } from "config/constants";
import { useCrmAccountsSearch } from "hooks/useCrmAccountsSearch";
import usePushNotification from "hooks/usePushNotification";
import generic from "i18n/generic";
import { makeStyles } from "makeStyles";
import PartnerConnection from "models/PartnerConnection";
import Record from "models/Record";
import { ChangeEvent, useMemo, useRef, useState } from "react";
import { defineMessages, FormattedMessage, useIntl } from "react-intl";
import { useDispatch } from "react-redux";
import { update } from "redux/api/thunks";

export const NewProspectAccountButton = ({
  partnerConnection,
}: {
  partnerConnection: PartnerConnection;
}) => {
  const { classes } = useStyles();
  const dispatch = useDispatch();
  const [inputValue, setInputValue] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [account, setAccount] = useState<Record | null>(null);
  const buttonRef = useRef<HTMLButtonElement | null>(null);
  const [isAccountDropdownOpen, setIsAccountDropdownOpen] = useState(false);
  const { loading: loadingRawAccount, options } = useCrmAccountsSearch(
    inputValue,
    setIsAccountDropdownOpen,
    isAccountDropdownOpen
  );
  const pushNotification = usePushNotification();
  const intl = useIntl();

  const handleCloseModal = () => {
    setAccount(null);
    setIsModalOpen(false);
  };

  const handleUpdatePartnerConnection = async () => {
    if (!account) {
      return;
    }

    const response = await dispatch(
      update({
        id: partnerConnection.id,
        type: "partner_connections",
        attributes: {
          raw_company_id: account.id,
        },
        options: {
          include: ["discussion_participants"],
        },
      })
    );

    if (isRejected(response)) {
      pushNotification(i18n.failedToLinkAccount);
      return;
    }

    pushNotification(
      {
        status: NotificationStatus.success,
        message: i18n.notificationSuccessTitle,
        description: i18n.notificationSuccessSubtitle,
      },
      {
        accountName: account?.name,
        prospectName: partnerConnection.newProspectName,
      }
    );
    handleCloseModal();
  };

  const handleClick = (event: ChangeEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    if (!isAccountDropdownOpen) {
      setIsAccountDropdownOpen(true);
      setInputValue("");
    }
  };

  const handleSetAccount = (ids: string[] | null) => {
    if (!ids) {
      return;
    }
    const account = options.find((item) => item.id === Number(ids[0]));
    if (account) {
      setAccount(account.source as Record);
    }
    setIsAccountDropdownOpen(false);
    setIsModalOpen(true);
  };

  const accountDropdownOptions = useMemo(() => {
    return options.map((item) => ({
      id: item.id + "",
      name: item.name,
      logo: getLogo(item.provider),
    })) as IDropdownOption[];
  }, [options]);

  return (
    <Box display="flex" flexShrink={1} minWidth={0} overflow="hidden">
      <Tooltip title={<FormattedMessage {...i18n.notLinked} />}>
        <Box maxWidth="100%">
          <Button
            label={partnerConnection.newProspectName ?? ""}
            classes={{
              tertiary: classes.btn,
              labelContent: classes.label,
              content: classes.maxWidth,
            }}
            LeftIcon={Plus}
            onClick={handleClick}
            ref={buttonRef}
            size="xSmall"
            variant="tertiary"
          />
        </Box>
      </Tooltip>
      <Dropdown
        isLoading={loadingRawAccount}
        options={accountDropdownOptions}
        anchorEl={buttonRef.current}
        onClose={() => setIsAccountDropdownOpen(false)}
        open={isAccountDropdownOpen}
        onChange={handleSetAccount}
        hasAsyncSearch
        onAsyncSearch={setInputValue}
        errorMessage={<FormattedMessage {...i18n.emptyListMessage} />}
        size={224}
        CustomHeader={
          <Box marginBottom="8px">
            <T titleSmall>
              <FormattedMessage {...i18n.dropdownTitle} />
            </T>
          </Box>
        }
        CustomFooter={
          <Box marginTop="8px">
            <T className={classes.footerText}>
              <FormattedMessage {...i18n.footerText} />
            </T>
          </Box>
        }
      />
      <DialogManager
        maxWidth="sm"
        fullScreenBreakpoint="sm"
        isOpen={isModalOpen}
        handleClose={handleCloseModal}
        DialogGlobalComponent={
          <BasicDialog
            variant={BasicDialogVariant.Confirm}
            title={intl.formatMessage(i18n.modalTitle, {
              accountName: account?.name,
              prospectName: partnerConnection.newProspectName,
            })}
            description={intl.formatMessage(i18n.modalSubtitle)}
            buttonProps={[
              {
                label: generic.cancel,
                variant: "tertiary",
                onClick: handleCloseModal,
              },
              {
                label: generic.confirm,
                onClick: handleUpdatePartnerConnection,
              },
            ]}
          />
        }
      />
    </Box>
  );
};

const useStyles = makeStyles()((theme) => ({
  btn: {
    background: theme.palette.midnightAlpha040,
    borderRadius: 4,
    color: theme.palette.alpha500,
    maxWidth: "100%",
  },
  footerText: {
    fontSize: 10,
    lineHeight: "150%",
  },
  maxWidth: {
    maxWidth: "100%",
  },
  label: {
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
  },
}));

const i18n = defineMessages({
  dropdownTitle: {
    id: "crm.MyPipeline.CellFormatters.dropdownTitle",
    defaultMessage: "Link Collab to a CRM account",
  },
  emptyListMessage: {
    id: "crm.MyPipeline.CellFormatters.emptyListMessage",
    defaultMessage: "Try typing to search for accounts.",
  },
  failedToLinkAccount: {
    id: "crm.MyPipeline.CellFormatters.failedToLinkAccount",
    defaultMessage: "Failed to link account",
  },
  footerText: {
    id: "crm.MyPipeline.CellFormatters.footerText",
    defaultMessage:
      "If you have recently created the account in your CRM, wait up to 24 hours to link it.",
  },
  modalSubtitle: {
    id: "crm.MyPipeline.CellFormatters.modalSubtitle",
    defaultMessage: "Once linked, it cannot be changed again",
  },
  modalTitle: {
    id: "crm.MyPipeline.CellFormatters.modalTitle",
    defaultMessage:
      "Are you sure you want to link {prospectName} New Prospect to {accountName} in your CRM?",
  },
  notificationSuccessTitle: {
    id: "crm.MyPipeline.CellFormatters.notificationSuccessTitle",
    defaultMessage: "Linked CRM account to prospect successfully",
  },
  notificationSuccessSubtitle: {
    id: "crm.MyPipeline.CellFormatters.notificationSuccessSubtitle",
    defaultMessage: "CRM account {accountName} added for {prospectName}",
  },
  notLinked: {
    id: "crm.MyPipeline.CellFormatters.notLinked",
    defaultMessage: "This collab is not linked to a CRM account yet.",
  },
});
