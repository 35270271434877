import { SWQLRuleNode, SWQLRuleNodeRight } from "components/ui/SWQL/SWQLTypes";
import { defineMessages, FormattedMessage } from "react-intl";

import CrmField from "./CrmField";

const i18n = defineMessages({
  is: {
    id: "models.SWQLOperator.is",
    defaultMessage: "is",
  },
  isNull: {
    id: "models.SWQLOperator.isNull",
    defaultMessage: "is Blank",
  },
  isNot: {
    id: "models.SWQLOperator.isNot",
    defaultMessage: "is not",
  },
  isNotNull: {
    id: "models.SWQLOperator.isNotNull",
    defaultMessage: "is not Blank",
  },
  isAnyOf: {
    id: "models.SWQLOperator.isAnyOf",
    defaultMessage: "is any of",
  },
  isNoneOf: {
    id: "models.SWQLOperator.isNoneOf",
    defaultMessage: "is none of",
  },
  contains: {
    id: "models.SWQLOperator.contains",
    defaultMessage: "contains",
  },
  doesNotContain: {
    id: "models.SWQLOperator.doesNotContain",
    defaultMessage: "does not contain",
  },
  greaterThanOrEqual: {
    id: "models.SWQLOperator.greaterThanOrEqual",
    defaultMessage: ">=",
  },
  lowerThanOrEqual: {
    id: "models.SWQLOperator.lowerThanOrEqual",
    defaultMessage: "<=",
  },
  greaterThan: {
    id: "models.SWQLOperator.greaterThan",
    defaultMessage: ">",
  },
  lowerThan: {
    id: "models.SWQLOperator.lowerThan",
    defaultMessage: "<",
  },
  earlierThan: {
    id: "models.SWQLOperator.earlierThan",
    defaultMessage: "is earlier than",
  },
  earlierThanOrEqual: {
    id: "models.SWQLOperator.earlierThanOrEqual",
    defaultMessage: "is earlier than or equal to",
  },
  laterThan: {
    id: "models.SWQLOperator.laterThan",
    defaultMessage: "is later than",
  },
  laterThanOrEqual: {
    id: "models.SWQLOperator.laterThanOrEqual",
    defaultMessage: "is later than or equal to",
  },
});

class SWQLOperator {
  static TYPE_IS = "is";
  static TYPE_IS_NULL = "is null";
  static TYPE_IS_NOT = "is not";
  static TYPE_IS_NOT_NULL = "is not null";
  static TYPE_IN = "in";
  static TYPE_NOT_IN = "not in";
  static TYPE_CONTAINS = "contains";
  static TYPE_DOES_NOT_CONTAIN = "does not contain";
  static TYPE_GTE = ">=";
  static TYPE_LTE = "<=";
  static TYPE_GT = ">";
  static TYPE_LT = "<";

  static TYPE_LABELS = {
    [SWQLOperator.TYPE_IS]: <FormattedMessage {...i18n.is} />,
    [SWQLOperator.TYPE_IS_NULL]: <FormattedMessage {...i18n.isNull} />,
    [SWQLOperator.TYPE_IS_NOT]: <FormattedMessage {...i18n.isNot} />,
    [SWQLOperator.TYPE_IS_NOT_NULL]: <FormattedMessage {...i18n.isNotNull} />,
    [SWQLOperator.TYPE_IN]: <FormattedMessage {...i18n.isAnyOf} />,
    [SWQLOperator.TYPE_NOT_IN]: <FormattedMessage {...i18n.isNoneOf} />,
    [SWQLOperator.TYPE_GTE]: <FormattedMessage {...i18n.greaterThanOrEqual} />,
    [SWQLOperator.TYPE_LTE]: <FormattedMessage {...i18n.lowerThanOrEqual} />,
    [SWQLOperator.TYPE_GT]: <FormattedMessage {...i18n.greaterThan} />,
    [SWQLOperator.TYPE_LT]: <FormattedMessage {...i18n.lowerThan} />,
    [SWQLOperator.TYPE_CONTAINS]: <FormattedMessage {...i18n.contains} />,
    [SWQLOperator.TYPE_DOES_NOT_CONTAIN]: (
      <FormattedMessage {...i18n.doesNotContain} />
    ),
  };

  static DATE_TYPE_LABELS = {
    [SWQLOperator.TYPE_GTE]: <FormattedMessage {...i18n.laterThanOrEqual} />,
    [SWQLOperator.TYPE_LTE]: <FormattedMessage {...i18n.earlierThanOrEqual} />,
    [SWQLOperator.TYPE_GT]: <FormattedMessage {...i18n.laterThan} />,
    [SWQLOperator.TYPE_LT]: <FormattedMessage {...i18n.earlierThan} />,
  };

  static getDefaultValue(fieldType: $TSFixMe, operator: $TSFixMe) {
    if ([this.TYPE_IS_NULL, this.TYPE_IS_NOT_NULL].includes(operator)) {
      return { value: null };
    }
    if (fieldType === CrmField.TYPE_BOOLEAN && operator !== "is") {
      return { value: [] };
    }
    if (fieldType === CrmField.TYPE_CURRENCY) {
      return { value: { amount: 0, currency_iso_code: "USD" } };
    }
    if (
      fieldType === CrmField.TYPE_DATE ||
      fieldType === CrmField.TYPE_DATETIME
    ) {
      return {
        value: "today",
        type: "date",
      } as SWQLRuleNodeRight;
    }
    return { value: CrmField.DEFAULT_VALUES[fieldType] };
  }

  static isInOrNotIn(node: SWQLRuleNode) {
    return [SWQLOperator.TYPE_IN, SWQLOperator.TYPE_NOT_IN].includes(
      node.operation
    );
  }
}

export default SWQLOperator;
