import { DataProviderType } from "screens/Frontoffice/shared/helpers/types";

export type OnboardingStepProps = {
  onClose: () => void;
  provider: DataProviderType;
};

export enum OnboardingSteps {
  loading = "#loading",
  columnMapping = "#column-mapping",
  statusRules = "#status-rules",
  strategicAccounts = "#strategic-accounts",
  done = "#done",
  fieldsNotReady = "#fields-not-ready",
}

export interface RulesWidgetProps {
  integrationId: number;
  handleClose: () => void;
  sourceType: string;
  callback?: () => void;
  noFieldsCallback?: () => void;
  hideSummary?: boolean;
  recordId?: number | null;
}

export type ISyncMetadata = {
  createdAt?: number | null;
  completedAt?: number | null;
  failedAt?: number | null;
};
