import CircularProgress from "@mui/material/CircularProgress";
import Grid from "@mui/material/Grid";
import { isFulfilled } from "@reduxjs/toolkit";
import { Check, Plus } from "components/icons";
import Button from "components/ui/Button";
import Popup from "components/ui/Popup";
import { T } from "components/ui/Typography";
import { crmProviders } from "config/constants";
import { Formik, FormikErrors, FormikHelpers, FormikTouched } from "formik";
import usePushNotification from "hooks/usePushNotification";
import forms from "i18n/forms";
import generic from "i18n/generic";
import _ from "lodash";
import { makeStyles } from "makeStyles";
import { ExportStatus } from "models/CrmExport";
import CrmField from "models/CrmField";
import { useEffect, useState } from "react";
import { defineMessages, FormattedMessage, useIntl } from "react-intl";
import { useDispatch } from "react-redux";
import { rawPost, retreive } from "redux/api/thunks";
import { FilterType } from "screens/Frontoffice/screens/DataTables/shared/types";
import { JSONAPIResponse } from "services/types";
import * as Yup from "yup";

import { defaultFields } from "../constants";
import {
  ExportTypes,
  IntegrationData,
  RevealFieldType,
  SupportedResources,
} from "../hooks/useMappingFields";
import {
  CrmExportInfo,
  FieldRow,
  MappedRow,
  Values,
} from "./ExportCrmDialogManager";
import FieldGroup from "./FieldGroup";
import { BLANK_KEY, BOOLEAN_OPTIONS } from "./FieldGroup/components/FieldGroup";
import OwnerRow from "./FieldGroup/components/OwnerRow";

type Props = {
  partnershipId: number;
  partnerName: string;
  resource: SupportedResources;
  isOpen: boolean;
  totalCount: number;
  partnerIntegrationData: IntegrationData;
  requiredCrmFieldsLoading: boolean;
  requiredCrmFields: { [fieldName: string]: RevealFieldType };
  optionalCrmFieldsLoading: boolean;
  optionalCrmFields: { [fieldName: string]: RevealFieldType };
  filters?: FilterType[];
  integrationId: number;
  hasMultiplePartnerIntegrations: boolean;
  callback: (crmExports: CrmExportInfo | null) => void;
  updateCrmInfo: (crmExports: CrmExportInfo) => void;
};

const ExportCrmDialog = ({
  partnershipId,
  partnerName,
  resource,
  isOpen,
  totalCount,
  partnerIntegrationData,
  requiredCrmFieldsLoading,
  requiredCrmFields,
  optionalCrmFieldsLoading,
  optionalCrmFields,
  filters = [],
  integrationId,
  hasMultiplePartnerIntegrations,
  callback,
  updateCrmInfo,
}: Props) => {
  const { classes } = useStyles();
  const dispatch = useDispatch();
  const pushNotification = usePushNotification();
  const intl = useIntl();

  const [crmExport, setCrmExport] = useState<CrmExportInfo | null>(null);

  const getCrmExport = (response: JSONAPIResponse) => {
    const link = response.data.attributes?.job_monitoring_url || "";

    return {
      id: +response.data.id,
      status: response.data.attributes?.status as number,
      link: link as string,
      exportCompleted: response.data.attributes?.export_completed_at !== null,
      exportedRecordCount: _.get(
        response.data.attributes?.statistics,
        "exported_record_count",
        0
      ),
      failedRecordCount: _.get(
        response.data.attributes?.statistics,
        "failed_record_count",
        0
      ),
    };
  };

  const fetchCrmExport = async () => {
    if (crmExport) {
      const result = await dispatch(
        retreive({
          id: crmExport.id,
          type: "crm_exports",
        })
      );
      if (isFulfilled(result)) {
        const response = result.payload as JSONAPIResponse;
        const newCrmExport = getCrmExport(response);
        if (
          crmExport.status !== newCrmExport.status ||
          crmExport.link !== newCrmExport.link ||
          crmExport.exportCompleted !== newCrmExport.exportCompleted
        ) {
          updateCrmInfo(newCrmExport);
        }
        setCrmExport(newCrmExport);
      }
    }
  };

  useEffect(() => {
    // We check the status of the last CrmExport if not already exported.
    // To be consistent with the mail we send to users once the export is complete,
    // we mark as completed when export_completed_at is updated (even if the status is still running).
    if (crmExport !== null) {
      const interval = setInterval(() => {
        if (
          (crmExport.status !== ExportStatus.successful &&
            crmExport.status !== ExportStatus.running) ||
          (crmExport.status === ExportStatus.running &&
            !crmExport.exportCompleted)
        ) {
          fetchCrmExport();
        } else {
          setCrmExport(null);
        }
      }, 5000);
      return () => clearInterval(interval);
    }
  }, [crmExport]); // eslint-disable-line react-hooks/exhaustive-deps

  const exportRows = async (values: Values) => {
    const mapping: { [x: string]: string | object[] } = {};
    const extraFields: object[] = [];
    _.each(values.fields, (value: FieldRow) => {
      const field =
        requiredCrmFields[value.crm_field] ||
        optionalCrmFields[value.crm_field];
      if (value.crm_field === "Owner") {
        mapping["owner_provider_key"] = value.reveal_field;
      } else if (value.crm_field === "Type") {
        mapping["status_raw"] = value.reveal_field;
      } else if (field) {
        const isDefaultValue = value.default_value;
        // Add Text fields
        if (field.type === CrmField.TYPE_TEXT) {
          extraFields.push({
            field_id: field.id,
            partner_field_name: isDefaultValue ? null : value.reveal_field,
            value_mapping: null,
            default_value: isDefaultValue ? value.reveal_field : "-",
          });
          // Add Boolean fields
        } else if (field.type === CrmField.TYPE_BOOLEAN) {
          extraFields.push({
            field_id: field.id,
            partner_field_name: isDefaultValue ? null : value.reveal_field,
            value_mapping: null,
            default_value: isDefaultValue
              ? value.reveal_field === BOOLEAN_OPTIONS[0].id // Checking if value equal "Yes"
              : false,
          });
          // Add Number & Integer fields
        } else if (
          field.type === CrmField.TYPE_NUMBER ||
          field.type === CrmField.TYPE_INTEGER
        ) {
          extraFields.push({
            field_id: field.id,
            partner_field_name: isDefaultValue ? null : value.reveal_field,
            value_mapping: null,
            default_value: isDefaultValue ? value.reveal_field : 0,
          });
          // Add Picklist fields
        } else if (field.type === CrmField.TYPE_PICKLIST) {
          // The default value for picklist is the one mapped to the "blank" option
          const defaultValue =
            value.value_mapping.find((item) => item.reveal_value === BLANK_KEY)
              ?.crm_value ?? "";
          const mappingValue = Object.assign(
            {},
            ...value.value_mapping
              .filter((item) => item.reveal_value !== BLANK_KEY)
              .map(({ reveal_value, crm_value }) => ({
                [reveal_value]: crm_value,
              }))
          );
          extraFields.push({
            field_id: field.id,
            partner_field_name:
              isDefaultValue || _.isEmpty(mappingValue)
                ? null
                : value.reveal_field,
            value_mapping:
              isDefaultValue || _.isEmpty(mappingValue) ? null : mappingValue,
            default_value: isDefaultValue ? value.reveal_field : defaultValue,
          });
        }
      }
    });
    if (extraFields.length > 0) {
      mapping["extra_fields"] = extraFields;
    }
    const result = await dispatch(
      rawPost({
        type: "crm_exports",
        path: "",
        payload: {
          data: {
            type: "crm_exports",
            attributes: {
              export_type: ExportTypes[resource],
              parameters: {
                filters: filters as [],
                mapping: mapping as {},
              },
            },
            relationships: {
              integration: {
                data: {
                  id: integrationId,
                  type: "integrations",
                },
              },
              partnership: {
                data: {
                  id: partnershipId,
                  type: "partnerships",
                },
              },
            },
          },
        },
      })
    );
    if (isFulfilled(result)) {
      const response = result.payload as JSONAPIResponse;
      const newCrmExport = getCrmExport(response);
      callback(newCrmExport);
      setCrmExport(newCrmExport);
    } else {
      pushNotification("default_error");
    }
  };

  const handleSave = async (values: Values, actions: FormikHelpers<Values>) => {
    await exportRows(values);
    actions.resetForm();
  };

  // Formik helpers

  const initialValues = {
    fields: Object.keys(requiredCrmFields).map((name) => ({
      reveal_field: "",
      crm_field: name,
      default_value: false,
      required: true,
      value_mapping: [] as MappedRow[],
    })),
  };

  const validationSchema = Yup.object().shape({
    fields: Yup.array().of(
      Yup.object().shape({
        reveal_field: Yup.string().required(() => forms.generic_required),
        crm_field: Yup.string().required(() => forms.generic_required),
        default_value: Yup.boolean(),
        required: Yup.boolean(),
        value_mapping: Yup.array()
          .of(
            Yup.object().shape({
              reveal_value: Yup.string(),
              crm_value: Yup.string(),
            })
          )
          .when("required", {
            is: true,
            then: Yup.array()
              .of(
                Yup.object()
                  .shape({
                    reveal_value: Yup.string().required(
                      () => forms.generic_required
                    ),
                    crm_value: Yup.string().required(
                      () => forms.generic_required
                    ),
                  })
                  .required(() => forms.generic_required)
              )
              .required(() => forms.generic_required),
          }),
      })
    ),
  });

  function onAddFieldToMap(
    values: Values,
    setValues: (values: Values) => void
  ) {
    // update dynamic form
    const fields = [...values.fields];
    fields.push({
      reveal_field: "",
      crm_field: "",
      default_value: false,
      required: false,
      value_mapping: [] as MappedRow[],
    });
    setValues({ ...values, fields });
  }

  function onDeleteFieldToMap(
    values: Values,
    setValues: (values: Values) => void,
    index: number
  ) {
    // update dynamic form
    const fields = [...values.fields];
    fields.splice(index, 1);
    setValues({ ...values, fields });
  }

  // End formik helpers

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={handleSave}
      validationSchema={validationSchema}
      enableReinitialize
    >
      {({
        values,
        setValues,
        setFieldTouched,
        handleSubmit,
        handleChange,
        isSubmitting,
        errors,
        touched,
        resetForm,
      }) => (
        <Popup
          variant="secondary"
          title={
            <div>
              <T className={classes.title}>
                <FormattedMessage
                  {...i18n.title}
                  values={{ number: totalCount }}
                />
              </T>
              <T className={classes.subtitle}>
                <FormattedMessage
                  {...i18n.subtitle}
                  values={{ partner: partnerName }}
                />
                {hasMultiplePartnerIntegrations && (
                  <>
                    <br />
                    <FormattedMessage
                      {...i18n.crmSubtitle}
                      values={{
                        count: partnerIntegrationData.recordCount,
                        partner: partnerName,
                        crm: crmProviders[partnerIntegrationData.provider].name,
                      }}
                    />
                  </>
                )}
              </T>
              <Grid container>
                <Grid item xs={6}>
                  <T className={classes.header}>
                    <FormattedMessage {...i18n.headerFirstCol} />
                  </T>
                </Grid>
                <Grid item xs={6}>
                  <T className={classes.header}>
                    <FormattedMessage {...i18n.headerSecondCol} />
                  </T>
                </Grid>
              </Grid>
            </div>
          }
          width={640}
          maxWidth={"lg"}
          isOpen={isOpen}
          hideActions
          customActions={
            <>
              <Button
                size="small"
                variant="quinary"
                LeftIcon={Plus}
                onClick={() => onAddFieldToMap(values, setValues)}
                label={i18n.addFieldToMap}
                loading={optionalCrmFieldsLoading}
              />
              <div className={classes.rightActionsContainer}>
                <Button
                  label={generic.skip}
                  onClick={() => {
                    callback(null);
                    resetForm();
                  }}
                  variant="secondary"
                />
                <Button
                  LeftIcon={Check}
                  label={
                    <FormattedMessage
                      {...i18n.createAccounts}
                      values={{ number: partnerIntegrationData.recordCount }}
                    />
                  }
                  onClick={handleSubmit}
                  disabled={requiredCrmFieldsLoading}
                  loading={isSubmitting}
                />
              </div>
            </>
          }
        >
          {!requiredCrmFieldsLoading ? (
            <Grid container rowSpacing={1}>
              {defaultFields.map((field) => (
                <FieldGroup
                  values={{
                    reveal_field: intl.formatMessage(field.revealField, {
                      partnerName: partnerName,
                    }),
                    crm_field: intl.formatMessage(field.crmField),
                    default_value: false,
                    required: true,
                    value_mapping: [],
                  }}
                  partnerName={partnerName}
                />
              ))}
              {values.fields.map((field, i) => {
                const fieldErrors = ((errors.fields?.length &&
                  errors.fields[i]) ||
                  {}) as FormikErrors<FieldRow>;
                const fieldTouched = ((touched.fields?.length &&
                  touched.fields[i]) ||
                  {}) as FormikTouched<FieldRow>;
                return field.crm_field === "Owner" ? (
                  <OwnerRow
                    fieldIndex={i}
                    integrationId={integrationId}
                    errors={fieldErrors}
                    touched={fieldTouched}
                    onChange={(e) => {
                      setFieldTouched(e.target.name, false);
                      handleChange(e);
                    }}
                  />
                ) : (
                  <FieldGroup
                    fieldIndex={i}
                    leftFields={partnerIntegrationData.fields}
                    rightFields={
                      field.required ? requiredCrmFields : optionalCrmFields
                    }
                    values={field}
                    errors={fieldErrors}
                    touched={fieldTouched}
                    onChange={(e) => {
                      setFieldTouched(e.target.name, false);
                      handleChange(e);
                    }}
                    partnerName={partnerName}
                    onDelete={
                      field.required
                        ? undefined
                        : () => onDeleteFieldToMap(values, setValues, i)
                    }
                  />
                );
              })}
            </Grid>
          ) : (
            <div className={classes.loaderContainer}>
              <CircularProgress size={25} />
            </div>
          )}
        </Popup>
      )}
    </Formik>
  );
};

export default ExportCrmDialog;

/// CSS

const useStyles = makeStyles()((theme) => ({
  title: {
    fontSize: 18,
    fontWeight: "bold",
    textAlign: "center",
    marginBottom: 10,
  },
  subtitle: {
    fontSize: 12,
    textAlign: "center",
  },
  header: {
    color: theme.palette.midnight500,
    fontSize: 10,
    textAlign: "center",
    margin: "28px auto 10px auto",
  },
  icon: {
    marginTop: "50%",
    color: theme.palette.midnight,
    width: 16,
    height: 16,
  },
  loaderContainer: {
    display: "flex",
    justifyContent: "center",
    padding: 25,
  },
  rightActionsContainer: {
    display: "flex",
    gap: theme.spacing(1),
    width: "100%",
    alignItems: "center",
    justifyContent: "flex-end",
  },
}));

/// I18N

const i18n = defineMessages({
  title: {
    id: "navbar.ExportOptions.ExportCrmDialog.title",
    defaultMessage:
      "Are you sure you want to create {number, plural, one {1 account} other {# accounts}} in your CRM?",
  },
  subtitle: {
    id: "navbar.ExportOptions.ExportCrmDialog.subtitle",
    defaultMessage: "These {partner} customers do not exist in your CRM.",
  },
  crmSubtitle: {
    id: "navbar.ExportOptions.ExportCrmDialog.crmSubtitle",
    defaultMessage: "Please map {count} accounts from {partner}’s {crm}.",
  },
  headerFirstCol: {
    id: "navbar.ExportOptions.ExportCrmDialog.headerFirstCol",
    defaultMessage: "REVEAL DATA",
  },
  headerSecondCol: {
    id: "navbar.ExportOptions.ExportCrmDialog.headerSecondCol",
    defaultMessage: "CRM FIELD",
  },
  addFieldToMap: {
    id: "navbar.ExportOptions.ExportCrmDialog.addFieldToMap",
    defaultMessage: "Add field to map",
  },
  createAccounts: {
    id: "navbar.ExportOptions.ExportCrmDialog.createAccounts",
    defaultMessage:
      "Create {number, plural, one {1 account} other {# accounts}}",
  },
});

export const _private = {
  i18n,
};
