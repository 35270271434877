import { createReducer } from "@reduxjs/toolkit";
import _ from "lodash";

import { setError } from "./actions";
import {
  initLongLivedToken,
  initNewShortLivedTokenEmail,
  refreshToken,
} from "./thunks";
import { ErrorType, NonUserState } from "./typing";

export default createReducer(
  {
    ready: false,
  } as NonUserState,
  (builder) =>
    builder
      .addCase(initLongLivedToken.fulfilled, (state) => {
        state.ready = true;
      })
      .addCase(initLongLivedToken.rejected, (state, action) => {
        state.ready = false;
        const error = _.get(action, "payload.errors[0]", { code: "" });
        switch (error.code) {
          case "TOKEN_ALREADY_USED":
          case "EXPIRED_TOKEN":
            state.error = ErrorType.ExpiredToken;
            break;
          case "INVALID_TOKEN":
            state.error = ErrorType.InvalidToken;
            break;
          default:
            state.error = ErrorType.Generic;
            break;
        }
      })
      .addCase(setError, (state, action) => {
        state.ready = false;
        state.error = action.payload;
      })
      .addCase(initNewShortLivedTokenEmail.fulfilled, (state) => {
        state.error = ErrorType.NewEmailSent;
      })
      .addCase(initNewShortLivedTokenEmail.rejected, (state) => {
        state.error = ErrorType.Generic;
      })
      .addCase(refreshToken.fulfilled, (state) => {
        state.ready = true;
      })
      .addCase(refreshToken.rejected, (state, action) => {
        const error = _.get(action, "payload.errors[0]", { status: "" });
        if (error.status === "403" && !action.payload?.hasSLT) {
          state.error = ErrorType.ExpiredToken;
        }
      })
);
