import { SWQLTarget } from "components/ui/SWQL/SWQLTypes";
import CrmField from "models/CrmField";
import { useEffect, useState } from "react";

const useIsSwQLReady = (
  integrationId: number,
  swqlTarget: SWQLTarget,
  maxPolls = 10
) => {
  const [state, setState] = useState({
    hasFields: false,
    nbPolls: 0,
  });

  const loading = maxPolls > state.nbPolls && !state.hasFields;

  useEffect(() => {
    let active = true;

    const handleResponse = ({ count }: { count: number }) => {
      if (active) {
        setState((current) => ({
          nbPolls: current.nbPolls + 1,
          hasFields: count > 0,
        }));
      }
    };

    if (!state.hasFields && loading) {
      CrmField.swqlAutocomplete("", integrationId, [swqlTarget], false, {
        page: { size: 1 },
      }).then(handleResponse);
    }

    return () => {
      active = false;
    };
  }, [state.nbPolls, integrationId, state.hasFields, loading, swqlTarget]);

  return { hasFields: state.hasFields, loading };
};

export default useIsSwQLReady;
