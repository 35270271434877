import { createSvgIcon } from "@mui/material/utils";

import { SvgIcon } from "./types";

const displayName = "File";

const File: SvgIcon = createSvgIcon(
  // Using transform to enlarge from provided viewbox="0 0 16 16"
  // to default "0 0 24 24" from `createSvgIcon` -> `SvgIcon`.
  <g fill="none" transform="matrix(1.5,0,0,1.5,0,0)">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M14.6 12.9C14.6 14.0598 13.6598 15 12.5 15H4.1C2.9402 15 2 14.0598 2 12.9V3.1C2 1.9402 2.9402 1 4.1 1H10.9302C11.107 1 11.2815 1.0223 11.45 1.06535C11.4805 1.07315 11.5108 1.08163 11.5409 1.09077C11.868 1.19021 12.1689 1.36893 12.4151 1.61508L13.9849 3.18492C14.2537 3.45371 14.4421 3.78785 14.5346 4.15C14.5353 4.15266 14.536 4.15531 14.5367 4.15797C14.5784 4.32398 14.6 4.4958 14.6 4.66985V12.9ZM12.5 13.95H4.1C3.5201 13.95 3.05 13.4799 3.05 12.9V3.1C3.05 2.5201 3.5201 2.05 4.1 2.05H10.4V3.1C10.4 4.2598 11.3402 5.2 12.5 5.2H13.55V12.9C13.55 13.4799 13.0799 13.95 12.5 13.95ZM13.4123 4.15C13.3663 4.06924 13.3093 3.99426 13.2425 3.92739L11.6726 2.35754C11.6111 2.296 11.5427 2.24289 11.4692 2.19895C11.4629 2.19514 11.4565 2.19139 11.45 2.18772V3.1C11.45 3.6799 11.9201 4.15 12.5 4.15H13.4123Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4.9751 7.30002C4.9751 7.01007 5.21015 6.77502 5.5001 6.77502H11.1001C11.39 6.77502 11.6251 7.01007 11.6251 7.30002C11.6251 7.58997 11.39 7.82502 11.1001 7.82502H5.5001C5.21015 7.82502 4.9751 7.58997 4.9751 7.30002Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4.9751 10.1C4.9751 9.81 5.21015 9.57495 5.5001 9.57495H8.3001C8.59005 9.57495 8.8251 9.81 8.8251 10.1C8.8251 10.3899 8.59005 10.625 8.3001 10.625H5.5001C5.21015 10.625 4.9751 10.3899 4.9751 10.1Z"
      fill="currentColor"
    />
  </g>,
  displayName
);

File.displayName = displayName;
export default File;
