import { T } from "components/ui/Typography";
import { makeStyles } from "makeStyles";
import { defineMessages, FormattedMessage } from "react-intl";

import OperatorAddBtn from "./OperatorAddBtn";
import { ParentOperatorContextProvider } from "./ParentOperatorContext";
import { PathToNode, useSWQLContext } from "./SWQLContext";
import SWQLRule from "./SWQLRule";
import {
  emptyNode,
  isSWQLBooleanNode,
  SWQLBooleanNode as SWQLBooleanNodeType,
  SWQLNode,
} from "./SWQLTypes";

type Props = { pathToNode: PathToNode };

const SWQLBooleanNode = ({ pathToNode }: Props) => {
  const { getNode, getParentNode, updateNode } = useSWQLContext();
  const node = getNode(pathToNode);
  const parentNode = getParentNode(pathToNode);
  const { classes, cx } = useStyles({ level: pathToNode.length });

  const addNewRule = () => {
    // TODO remove this `as`
    const newChildrenNodes = [
      ...(node as SWQLBooleanNodeType).args,
      emptyNode(),
    ];
    const newNode = {
      ...node,
      args: newChildrenNodes,
    };

    updateNode(pathToNode, newNode);
  };

  let parentOperator = undefined;
  if (parentNode && isSWQLBooleanNode(parentNode)) {
    parentOperator = parentNode.operator;
  }

  if (!isSWQLBooleanNode(node)) {
    return (
      <ParentOperatorContextProvider operator={parentOperator}>
        <SWQLRule pathToNode={pathToNode} />
      </ParentOperatorContextProvider>
    );
  }

  return (
    <div className={classes.groupContainer}>
      {node.args.map((_: SWQLNode, i: number) => (
        <div
          className={cx(classes.container, {
            [classes.isFirst]: i === 0,
          })}
          key={i}
        >
          {i > 0 && (
            <div className={classes.operatorContainer}>
              <T className={classes.operatorText}>
                {node.operator === "and" ? (
                  <FormattedMessage {...i18n.and} />
                ) : (
                  <FormattedMessage {...i18n.or} />
                )}
              </T>
            </div>
          )}
          <SWQLBooleanNode pathToNode={[...pathToNode, i]} />
        </div>
      ))}
      <div className={classes.addBtn}>
        <OperatorAddBtn operator={node.operator} handleClick={addNewRule} />
      </div>
    </div>
  );
};

export default SWQLBooleanNode;

// I18N

const i18n = defineMessages({
  and: {
    id: "SWQL.SWQLBooleanNode.and",
    defaultMessage: "AND",
  },
  or: {
    id: "SWQL.SWQLBooleanNode.or",
    defaultMessage: "OR",
  },
});

// CSS

const useStyles = makeStyles<{ level: number }>()((theme, { level }) => ({
  groupContainer: {
    display: "flex",
    flexDirection: "column",
    rowGap: theme.spacing(1),
    position: "relative",
    "&::before": {
      content: `''`,
      border: `0.7px solid ${theme.palette.taupe}`,
      position: "absolute",
      height: "calc(100% - 28px)",
      left: 14,
    },
    "&::after": {
      content: `''`,
      border: `0.7px solid ${theme.palette.offWhite}`,
      position: "absolute",
      height: 17,
      left: 14,
    },
  },
  container: {
    display: "flex",
  },
  isFirst: {
    paddingLeft: theme.spacing(5),
    "&::before": {
      content: `''`,
      border: `0.7px solid ${theme.palette.taupe}`,
      position: "absolute",
      top: 17,
      left: level === 0 ? 14 : 0,
      width: level === 0 ? 27 : 41,
    },
  },
  operatorContainer: {
    display: "flex",
    justifyContent: "center",
    marginTop: 7,
    width: theme.spacing(4),
    marginRight: theme.spacing(1),
    "&::after": {
      content: `''`,
      border: `0.7px solid ${theme.palette.taupe}`,
      position: "absolute",
      width: 18,
      marginTop: 10,
      left: 23,
    },
  },
  operatorText: {
    height: "fit-content",
    paddingRight: 3,
    backgroundColor: theme.palette.offWhite,
    zIndex: 1,
  },
  addBtn: {
    marginLeft: -10,
    paddingBottom: 10,
    paddingTop: 2,
    backgroundColor: "transparent",
    zIndex: 1,
  },
}));
