import history from "_history";
import { AnyAction, createAsyncThunk, ThunkDispatch } from "@reduxjs/toolkit";
import axios from "axios";
import segment from "helpers/segment";
import { JSONAPIAttributes, JSONAPIIdentifier } from "models/types";
import User from "models/User";
import { bulkAddRecords } from "redux/api/actions";
import { setup } from "redux/init/thunks";
import { RevealStore } from "redux/typing";
import UserService from "services/UserService";

import { setLoggedIn } from "./actions";
import { RootStateWithUser, UserActions } from "./typing";

/**
 * Utils
 */

const userFromProfile = async (
  service: UserService,
  dispatch: ThunkDispatch<RevealStore, any, AnyAction>
) => {
  const response = await service.profile();
  dispatch(bulkAddRecords(response));
  return User.fromResponse(response);
};

const process401orRaise = async (
  error: Error,
  dispatch: ThunkDispatch<any, any, AnyAction>
) => {
  if (axios.isAxiosError(error) && error.response?.status === 401) {
    return await dispatch(logout({ goHome: false }));
  }
  throw error;
};

/**
 * Authentication
 */

export const login = createAsyncThunk(
  UserActions.login,
  async (
    parameters: {
      username: string;
      password: string;
      recaptchaToken: string;
      otp?: string;
    },
    thunkAPI
  ) => {
    const userService = new UserService();
    /**
     * Call login endpoints
     */
    try {
      await userService.login(
        parameters.username,
        parameters.password,
        parameters.recaptchaToken,
        parameters.otp
      );
    } catch (error) {
      if (axios.isAxiosError(error)) {
        return thunkAPI.rejectWithValue(
          error.response?.data.error ?? "unknown"
        );
      }
      throw error;
    }
    /**
     * Fetch user profile and prepare user
     */
    let user: User = await userFromProfile(
      userService,
      thunkAPI.dispatch as ThunkDispatch<RevealStore, any, AnyAction>
    );

    if (!user.timezone) {
      await userService.updateProfile(user.id, {
        timezone: new Date().getTimezoneOffset(),
      });
      user = await userFromProfile(
        userService,
        thunkAPI.dispatch as ThunkDispatch<RevealStore, any, AnyAction>
      );
    }
    /**
     * Tracking
     */
    if (user.company && user.company.disableAnalytics) {
      segment.stop();
    }
    user.identify();
    setup(thunkAPI.dispatch as ThunkDispatch<RevealStore, any, AnyAction>);
    return user;
  }
);

export const logout = createAsyncThunk(
  UserActions.logout,
  async (parameters: { goHome?: boolean }, thunkAPI) => {
    const service = new UserService();
    await service.logout();
    thunkAPI.dispatch(setLoggedIn(false));
    if (parameters.goHome) {
      history.push("/");
    }
    segment.start();
  }
);

export const refreshToken = createAsyncThunk(
  UserActions.refreshToken,
  async (_, thunkAPI) => {
    const service = new UserService();
    try {
      await service.refreshToken();
    } catch (error: any) {
      throw thunkAPI.rejectWithValue(error.response.data);
    }
    const user = await userFromProfile(
      service,
      thunkAPI.dispatch as ThunkDispatch<RevealStore, any, AnyAction>
    );
    if (user.company && user.company.disableAnalytics) {
      segment.stop();
    }
    user.identify();
    return user;
  }
);

/**
 * Profile
 */

export const fetchProfile = createAsyncThunk(
  UserActions.fetchProfile,
  async (_action, thunkAPI) => {
    const userService = new UserService();
    try {
      const user = await userFromProfile(
        userService,
        thunkAPI.dispatch as ThunkDispatch<RevealStore, any, AnyAction>
      );
      return user;
    } catch (error) {
      throw await process401orRaise(error as Error, thunkAPI.dispatch);
    }
  }
);

export const fetchProfileInBackground = createAsyncThunk(
  UserActions.fetchProfileInBackground,
  async (_action, thunkAPI) => {
    const userService = new UserService();
    try {
      const user = await userFromProfile(
        userService,
        thunkAPI.dispatch as ThunkDispatch<RevealStore, any, AnyAction>
      );
      return user;
    } catch (error) {
      throw await process401orRaise(error as Error, thunkAPI.dispatch);
    }
  }
);

export const updateProfile = createAsyncThunk(
  UserActions.updateProfile,
  async (
    parameters: {
      attributes: JSONAPIAttributes;
      relationships?: { [reltation: string]: JSONAPIIdentifier };
    },
    thunkAPI
  ) => {
    const state = thunkAPI.getState() as RootStateWithUser;
    const currentUser = state.user.profile.data;
    if (currentUser === null || currentUser.isAnonymous) {
      return thunkAPI.rejectWithValue(new Error("No user to update"));
    }
    const service = new UserService();
    try {
      const response = await service.updateProfile(
        currentUser.id,
        parameters.attributes,
        parameters.relationships
      );
      const user = User.fromResponse(response);
      thunkAPI.dispatch(bulkAddRecords(response));
      user.identify();
      return user;
    } catch (error) {
      throw await process401orRaise(error as Error, thunkAPI.dispatch);
    }
  }
);

/**
 * Manage MFA
 */

export const enableMFA = createAsyncThunk(
  UserActions.enableMFA,
  async (otp: string, thunkAPI) => {
    const service = new UserService();
    const response = await service.enableMFA(otp);
    const user = User.fromResponse(response);
    thunkAPI.dispatch(bulkAddRecords(response));
    return user;
  }
);

export const disableMFA = createAsyncThunk(
  UserActions.disableMFA,
  async (password: string, thunkAPI) => {
    const service = new UserService();
    const response = await service.disableMFA(password);
    const user = User.fromResponse(response);
    thunkAPI.dispatch(bulkAddRecords(response));
    return user;
  }
);
