import withUserProfile, {
  WithUserProfile,
} from "components/hoc/withUserProfile";
import { T } from "components/ui/Typography";
import { ConfigContext } from "config/ConfigProvider";
import { makeStyles } from "makeStyles";
import { useContext } from "react";
import { defineMessages, FormattedMessage } from "react-intl";
import { useHistory } from "react-router";

import Button from "./Button";

const useStyles = makeStyles()((theme) => ({
  root: {
    position: "absolute",
    height: "auto",
    bottom: 0,
    top: 0,
    left: 0,
    right: 0,
    margin: theme.spacing(4),
  },
  container: {
    height: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    "& > *": {
      marginBottom: theme.spacing(4),
    },
  },
  image: {
    maxWidth: 300,
  },
}));

const i18n = defineMessages({
  title: {
    id: "NotFound.title",
    defaultMessage: "Page Not Found",
  },
  subtitle: {
    id: "NotFound.subtitle",
    defaultMessage:
      "The page you are looking for doesn't exist or has been moved.",
  },
  loggedInLink: {
    id: "NotFound.loggedInLink",
    defaultMessage: "Go to Dashboard",
  },
  loggedOutLink: {
    id: "NotFound.loggedOutLink",
    defaultMessage: "Sign In",
  },
});

const NotFound = ({ loggedIn }: WithUserProfile) => {
  const { classes } = useStyles();
  const history = useHistory();
  const appName = useContext(ConfigContext).appName;

  const handleRedirect = (url: string) => {
    history.push(url);
  };

  return (
    <div className={classes.root}>
      <div className={classes.container}>
        <T h1>
          <FormattedMessage {...i18n.title} />
        </T>
        <img alt="" className={classes.image} src="/images/lost-404.png" />
        <T oldVariant="body2">
          <FormattedMessage {...i18n.subtitle} />
        </T>
        {loggedIn && appName === "partners" && (
          <Button
            onClick={() => handleRedirect("/partnerships")}
            label={i18n.loggedInLink}
          />
        )}
        {!loggedIn && (
          <Button
            onClick={() => handleRedirect("/login")}
            label={i18n.loggedOutLink}
          />
        )}
      </div>
    </div>
  );
};

export default withUserProfile(NotFound, true);
