import clsx from "clsx";
import ToggleBtnGroup from "components/ui/ToggleBtnGroup";
import { useLazyEffect } from "hooks/useLazyEffect";
import _ from "lodash";
import { makeStyles } from "makeStyles";
import { ChangeEvent, useEffect, useRef, useState } from "react";
import { Scrollbars } from "react-custom-scrollbars";
import {
  defineMessages,
  FormattedHTMLMessage,
  MessageDescriptor,
  useIntl,
} from "react-intl";
import { useMergedClasses } from "tss-react";

import { ChevronRight, Search } from "../../../icons";
import Button from "../../Button";
import DraggableContainer from "../../DraggableContainer";
import DraggableItem from "../../DraggableItem";
import PageLoader from "../../PageLoader";
import { TextInput } from "../../TextInput";
import DropdownItem from "./DropdownItem";
import { useTabsStyles } from "./DropdownItem";
import DropdownMenu from "./DropdownMenu";
import OpportunityDropdown from "./OpportunityDropdown";
import {
  ERROR_MESSAGES_BY_STATE,
  ERROR_STATES,
  IDropdown,
  IDropdownOption,
  IDropdownTab,
} from "./types";

const Dropdown = <T extends string = string>(dropdownProps: IDropdown<T>) => {
  const {
    anchorEl,
    options = [],
    onClose,
    isDraggable,
    withToggle,
    withCheckBox,
    isOpportunityItem,
    onChange = () => {},
    onDragEnd,
    isMultiselect,
    isVertical,
    isMainSearch,
    tabs,
    CustomHeader,
    CustomFooter,
    withSearch = true,
    resetSearch = 0,
    open,
    position,
    allowSelectAll,
    value = [],
    nullValueMeansAllSelected,
    withGroups,
    searchPlaceholder,
    isLoading,
    hasAsyncSearch,
    allowEmptyValue,
    onAsyncSearch = () => {},
    searchCallback = () => {},
    withToggleFilter,
    toggleFilterValue = "",
    toggleFilterItems,
    onToggleFilterChange = () => {},
    fullWidth,
    classes: newClasses = {},
    errorMessage,
  } = dropdownProps;
  const intl = useIntl();
  const [items, setItems] = useState<IDropdownOption<T>[]>(options);
  const [searchQuery, setSearchQuery] = useState<string>("");
  const [searchOptions, setSearchOptions] = useState<IDropdownOption<T>[]>(
    getAllNestedItems(options)
  );
  const [activeTab, setActiveTab] = useState("");
  const [activeItem, setActiveItem] = useState("");
  const [errorMsg, setErrorMsg] = useState<MessageDescriptor | null>();
  const searchInputRef = useRef<HTMLInputElement>(null);
  const [expandedItems, setExpandedItems] = useState<IDropdownOption<T>[]>();

  const isAllSelected =
    (value && options.every((i) => value?.includes(i.id.toString() as T))) ||
    Boolean(nullValueMeansAllSelected && (value === null || value === ""));

  const { classes: baseClasses, cx } = useDropdownStyles();
  const classes = useMergedClasses(baseClasses, newClasses);
  const { classes: tabClasses } = useTabsStyles();
  const { classes: draggableItemClasses } = useDraggableItemStyles();

  const setInitialValues = () => {
    setActiveItem("");
    setItems(options);
    setSearchOptions(getAllNestedItems(options));
  };

  const optionsJSON = JSON.stringify(options.map((option) => option.id));

  useEffect(() => {
    setInitialValues();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [optionsJSON]);

  useEffect(() => {
    if (nullValueMeansAllSelected) {
      if (
        Array.isArray(value) &&
        value?.length ===
          getAllNestedItems(options).filter((item) => !item.subItems).length
      ) {
        onChange(null);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [optionsJSON, nullValueMeansAllSelected, onChange, value]);

  useEffect(() => {
    if (tabs?.length) {
      setActiveTab(tabs[0].id);
    }
  }, [tabs]);

  useEffect(() => {
    if (options?.length) {
      setItems(options);
      setSearchOptions(getAllNestedItems(options));
    } else {
      setItems([]);
      setSearchOptions([]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [optionsJSON]);

  useEffect(() => {
    if (tabs && activeTab) {
      const currentTab = tabs.find((t) => t.id === activeTab);
      if (currentTab?.options) setItems(currentTab?.options);
      return;
    }

    if ((tabs || options?.length) && !isLoading) {
      setErrorMsg(null);
      setItems(options);
      return;
    }

    if (!options.length && hasAsyncSearch && searchQuery.length) {
      setErrorMsg(ERROR_MESSAGES_BY_STATE[ERROR_STATES.NO_SEARCH_RESULTS]);
      return;
    }

    if (!options.length && !isLoading) {
      setErrorMsg(ERROR_MESSAGES_BY_STATE[ERROR_STATES.EMPTY_STATE]);
      return;
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeTab, optionsJSON, tabs, isLoading]);

  //logic for searching input only
  useLazyEffect(() => {
    setErrorMsg(null);
    const queryLowerCase = searchQuery.toLowerCase();
    if (hasAsyncSearch && searchQuery?.length) {
      if (!options.length) {
        setErrorMsg(ERROR_MESSAGES_BY_STATE[ERROR_STATES.NO_SEARCH_RESULTS]);
      }
      onAsyncSearch(searchQuery);
      return;
    } else if (hasAsyncSearch && !searchQuery?.length) {
      onAsyncSearch("");
      return;
    }
    if (searchOptions && queryLowerCase?.length) {
      const searchedItems = searchOptions
        ?.filter((o) => {
          const optionLowerCase = o?.name?.toLowerCase();
          return withGroups
            ? o?.group?.some((item) =>
                item.name?.toLowerCase().includes(queryLowerCase)
              )
            : optionLowerCase?.includes(queryLowerCase);
        })
        .map((o) => {
          return withGroups
            ? {
                ...o,
                group: o.group?.filter((item) =>
                  item.name?.toLowerCase().includes(queryLowerCase)
                ),
              }
            : o;
        });
      if (searchedItems?.length) {
        setItems(searchedItems);
      } else if (searchQuery && !searchedItems?.length) {
        setErrorMsg(ERROR_MESSAGES_BY_STATE[ERROR_STATES.NO_SEARCH_RESULTS]);
        setItems([]);
      }
    } else if (!queryLowerCase?.length) {
      setItems(options);
    }
    searchCallback(searchQuery);
  }, [searchQuery]);

  // Clear search when closing the dropdown
  // And focus search input when opening it
  useEffect(() => {
    if (!open) {
      setSearchQuery("");
    } else if (withSearch) {
      setTimeout(() => {
        if (searchInputRef.current) {
          searchInputRef.current.focus();
        }
      }, 50);
    }
  }, [open, withSearch]);

  useEffect(() => {
    if (resetSearch > 0) {
      setSearchQuery("");
    }
  }, [resetSearch]);

  const reorder = (startIndex: number, endIndex: number) => {
    const newSort = [...items];
    const [removed] = newSort.splice(startIndex, 1);
    newSort.splice(endIndex, 0, removed);
    setItems(newSort);
    if (onDragEnd) {
      onDragEnd(newSort, activeTab);
    }
  };

  const onItemSelectionChange = (
    e: ChangeEvent<HTMLInputElement>,
    item: IDropdownOption<T>
  ) => {
    e.stopPropagation();
    if (item.subItems) {
      onItem(item);
    } else {
      if (isMultiselect) {
        if (value && Array.isArray(value)) {
          const checked = value.find((optionId) => optionId === item.id);
          if (checked) {
            onChange(value.filter((optionId) => optionId !== item.id));
          } else {
            onChange([...value, item.id]);
          }
        } else if (value === null && nullValueMeansAllSelected) {
          if (isAllSelected) {
            onChange(
              getAllNestedItems(options)
                .filter((item) => !item.subItems)
                .filter((i) => i.id !== item.id)
                .map((i) => i.id)
            );
          } else {
            onChange([item.id]);
          }
        } else {
          onChange([item.id]);
        }
      } else {
        if (!withToggle && !withCheckBox) {
          if (value?.includes(String(item.id) as T) && allowEmptyValue) {
            onChange([]);
          } else {
            onChange([item.id]);
          }
        } else {
          if (value && Array.isArray(value)) {
            const checked = value.find((optionId) => optionId === item.id);
            if (!checked) {
              onChange(
                value?.filter((optionId: string) => optionId !== item.id) ?? []
              );
            } else {
              onChange([item.id]);
            }
          }
        }
      }
    }
  };

  const handleSelectAll = () => {
    if (!isAllSelected) {
      onChange(
        getAllNestedItems(items)
          .filter((item) => !item.subItems)
          .map((i) => i.id)
      );
    } else {
      onChange([]);
    }
  };

  const onItem = (item: IDropdownOption<T>) => {
    if (item.subItems?.length) {
      if (expandedItems?.find((i) => i.id === item.id)) {
        setExpandedItems([
          ...(expandedItems ?? []).filter((i) => i.id !== item.id),
        ]);
      } else {
        setExpandedItems([
          ...(expandedItems ?? []).filter((i) => i.id !== item.id),
          item,
        ]);
      }
    }
  };

  const onSelect = (item: IDropdownOption<T>) => {
    const deepestIds = getDeepestIds(item);

    if (value === null && nullValueMeansAllSelected) {
      onChange(
        getAllNestedItems(items)
          .filter((item) => !item.subItems)
          .map((i) => i.id)
          .filter((i) => !deepestIds.includes(i))
      );
    } else if (_.difference(deepestIds, value as T[]).length === 0) {
      // All deepestIds are already in value, so we remove them
      onChange(((value ?? []) as T[]).filter((i) => !deepestIds.includes(i)));
    } else {
      // Some or all deepestIds are not in value, so we add them
      onChange([
        ...((value ?? []) as T[]).filter((i) => !deepestIds.includes(i)),
        ...deepestIds,
      ]);
    }
  };

  const setTab = (tab: IDropdownTab<T>) => {
    tab?.options && setItems(tab.options);
    setActiveTab(tab.id);
  };

  const renderDropdownItem = (item: IDropdownOption<T>, index: number) => (
    <DropdownItem
      key={index}
      id={item.id}
      isChecked={
        _.difference(getDeepestIds(item), value as T[]).length === 0 ||
        isAllSelected
      }
      isExpanded={expandedItems?.some((i) => i.id === item.id)}
      isIndeterminate={
        _.intersection(getDeepestIds(item), value as T[]).length > 0
      }
      isHoverable={item.isHoverable}
      isVertical={isVertical}
      label={item.label}
      logo={item.logo}
      logoTooltip={item.logoTooltip}
      subItems={item.subItems}
      onChange={(e: any) =>
        item.onClick ? item.onClick(e) : onItemSelectionChange(e, item)
      }
      onSelect={() => onSelect(item)}
      name={item.name}
      subText={item.subText}
      isDraggable={isDraggable}
      toggle={withToggle}
      checkbox={withCheckBox}
      rightIcons={item.rightIcons}
      rightIconTooltip={item.rightIconTooltip}
      isDisabled={item.disabled}
      tooltipMessage={item.tooltipMessage}
      title={item.title}
      fullWidth={fullWidth}
      height={item.height}
    />
  );

  return (
    <DropdownMenu<T>
      {...dropdownProps}
      onClose={onClose}
      anchorEl={anchorEl}
      isMainSearch={isMainSearch}
      open={open}
      position={position}
    >
      <div
        className={cx(classes.root, {
          [classes.overflow]: isOpportunityItem,
        })}
      >
        {isOpportunityItem ? (
          isLoading ? (
            <PageLoader />
          ) : (
            <OpportunityDropdown
              {...dropdownProps}
              options={options}
              onChange={onChange}
            />
          )
        ) : (
          <div>
            {CustomHeader}
            {tabs && tabs.length && (
              <>
                <div style={{ borderRadius: 18 }} className={tabClasses.toggle}>
                  {tabs.map((t) => (
                    <Button
                      key={t.id}
                      className={clsx(
                        tabClasses.content,
                        activeTab === t.id && tabClasses.activeButton
                      )}
                      size="small"
                      label={t.name}
                      variant="quinary"
                      onClick={() => setTab(t)}
                    />
                  ))}
                </div>
                <div className={tabClasses.spacer} />
              </>
            )}
            {activeItem ? (
              <p className={classes.backLabel} onClick={setInitialValues}>
                <ChevronRight className={classes.backIcon} /> {activeItem}
              </p>
            ) : null}
            {withSearch && (
              <div className={classes.fullWidth}>
                <TextInput
                  ref={searchInputRef}
                  placeholder={
                    searchPlaceholder ?? intl.formatMessage(i18n.search)
                  }
                  value={searchQuery}
                  onChange={(e: ChangeEvent<HTMLInputElement>) => {
                    setSearchQuery(e.target.value);
                  }}
                  variant="newDesign"
                  small
                  LeftIcon={Search}
                />
                <div className={classes.spacer} />
              </div>
            )}
            {withToggleFilter && toggleFilterItems && (
              <div className={classes.fullWidth}>
                <ToggleBtnGroup
                  withBorder
                  size="small"
                  items={toggleFilterItems}
                  setValue={onToggleFilterChange}
                  value={toggleFilterValue}
                />
                <div className={classes.spacer} />
              </div>
            )}
            {isLoading ? (
              <PageLoader />
            ) : (
              <Scrollbars autoHeight autoHeightMax={224} autoHeightMin={0}>
                {!errorMsg ? (
                  isDraggable ? (
                    <DraggableContainer handleDragEnd={reorder}>
                      {items.map((item: IDropdownOption<T>, index: number) => (
                        // @ts-expect-error ts-migrate(2786) FIXME: 'DraggableItem' cannot be used as a JSX component.
                        <DraggableItem
                          key={item.id}
                          id={item.id}
                          index={index}
                          disabled={!isDraggable}
                          classes={{ root: draggableItemClasses.root }}
                          isDropdown
                        >
                          {renderDropdownItem(item, index)}
                        </DraggableItem>
                      ))}
                    </DraggableContainer>
                  ) : withGroups ? (
                    <>
                      {items.map((item, index) => (
                        <div key={index}>
                          {(item.name || options.length > 1) && (
                            <p className={classes.groupTitle}>{item.name}</p>
                          )}
                          {item.group?.map((i, index) =>
                            renderDropdownItem(i, index)
                          )}
                          {index < items.length - 1 && (
                            <div className={classes.spacer} />
                          )}
                        </div>
                      ))}
                    </>
                  ) : (
                    <>
                      {allowSelectAll && searchQuery === "" && (
                        <DropdownItem
                          id="select-all-id"
                          isChecked={
                            items?.every((i) => value?.includes(i.id)) ||
                            isAllSelected
                          }
                          name={intl.formatMessage(i18n.selectAll)}
                          toggle={withToggle}
                          checkbox={withCheckBox}
                          onChange={handleSelectAll}
                          onSelect={handleSelectAll}
                        />
                      )}
                      {items.map((item, index) => (
                        <div key={item.id}>
                          {renderDropdownItem(item, index)}
                          {expandedItems?.find((i) => i.id === item.id) && (
                            <div className={classes.subList}>
                              {item.subItems?.map((subItem, index) => (
                                <div key={subItem.id}>
                                  {renderDropdownItem(subItem, index)}
                                  {expandedItems?.find(
                                    (i) => i.id === subItem.id
                                  ) && (
                                    <div className={classes.subList}>
                                      {subItem.subItems?.map(
                                        (innerItem, index) =>
                                          renderDropdownItem(innerItem, index)
                                      )}
                                    </div>
                                  )}
                                </div>
                              ))}
                            </div>
                          )}
                        </div>
                      ))}
                    </>
                  )
                ) : (
                  <p className={classes.errorMsg}>
                    {errorMessage ? (
                      errorMessage
                    ) : (
                      <FormattedHTMLMessage
                        {...(errorMsg as MessageDescriptor)}
                      />
                    )}
                  </p>
                )}
              </Scrollbars>
            )}
            {CustomFooter && (
              <>
                {items.length ? <div className={classes.spacer} /> : null}
                {typeof CustomFooter === "function"
                  ? CustomFooter(value)
                  : CustomFooter}
              </>
            )}
          </div>
        )}
      </div>
    </DropdownMenu>
  );
};

const getDeepestIds = <T extends string = string>(
  option: IDropdownOption<T>
): T[] => {
  if (option.subItems) {
    // Recur through subItems if they exist
    return option.subItems.flatMap((subItem) => getDeepestIds(subItem));
  } else {
    // Return the id of the item itself if it has no subItems
    return [option.id];
  }
};

const getAllNestedItems = <T extends string = string>(
  options: IDropdownOption<T>[]
): IDropdownOption<T>[] => {
  return options.flatMap((option) => {
    const items: IDropdownOption<T>[] = [option];
    if (option.subItems?.length) {
      // Recur through subItems if they exist
      const subItems = option.subItems.flatMap((subItem) =>
        getAllNestedItems([subItem])
      );
      items.push(...subItems);
    }
    return items;
  });
};

export default Dropdown;

export const useDropdownStyles = makeStyles()((theme) => ({
  alpha: {
    color: theme.palette.alpha500,
  },
  root: {},
  spacer: {
    width: "100%",
    height: "1px",
    borderBottom: `1px solid ${theme.palette.taupe500}`,
    margin: "8px 0",
  },
  subList: {
    marginLeft: theme.spacing(2),
  },
  toggle: {
    display: "flex",
    width: "100%",
    backgroundColor: theme.palette.offWhite,
    padding: "2px",
    justifyContent: "space-between",
  },
  opportunityLabel: {
    color: theme.palette.midnight,
    margin: "11px 0",
    fontSize: "12px",
    fontWeight: 600,
  },
  backIcon: {
    transform: "rotate(180deg)",
  },
  backLabel: {
    fontSize: "12px",
    color: theme.palette.midnight,
    fontWeight: 600,
    display: "flex",
    alignItems: "center",
    margin: "7px 0",
    "&:hover": {
      cursor: "pointer",
    },
  },
  errorMsg: {
    textAlign: "center",
    fontSize: "12px",
    lineHeight: "20px",
    fontWeight: 400,
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    margin: 0,
    paddingTop: 10,
    paddingBottom: 10,
  },
  fullWidth: {
    "& div": {
      width: "100%",
    },
  },
  groupTitle: {
    color: theme.palette.midnight,
    fontSize: 12,
    fontWeight: 600,
    marginBottom: "10px",
  },
  overflow: {
    maxHeight: "300px",
    overflow: "auto",
  },
}));

export const useButtonStyles = makeStyles()(() => ({
  content: {
    display: "flex",
    alignItems: "center",
    height: "26px",
    overflow: "hidden",
    borderRadius: 22,
    width: "130px",
  },
  activeButton: {
    background: "#0A151B",
    color: "white",
    "&:hover": {
      backgroundColor: "#0A151B",
      cursor: "pointer",
    },
  },
}));

export const useDraggableItemStyles = makeStyles()(() => ({
  root: {
    display: "block",
    alignItems: "center",
    padding: 1,
    // Workaround for DraggableItem which had incorrect position because of being inside Material UI Popper - https://stackoverflow.com/questions/69227208/dragdrop-inside-popper-bug
    left: "auto !important",
    top: "auto !important",
  },
}));

const i18n = defineMessages({
  search: {
    id: "Dropdown.search",
    defaultMessage: "Search",
  },
  selectAll: {
    id: "Dropdown.selectAll",
    defaultMessage: "(Select all)",
  },
});
