import CloseIcon from "@mui/icons-material/Close";
import MuiButton from "@mui/material/Button";
import FormControl from "@mui/material/FormControl";
import Grid from "@mui/material/Grid";
import { lighten } from "@mui/material/styles";
import TextField from "@mui/material/TextField";
import { isRejected } from "@reduxjs/toolkit";
import withAPI, { WithAPI } from "components/hoc/withAPI";
import Button from "components/ui/Button";
import Tooltip from "components/ui/Tooltip";
import { T } from "components/ui/Typography";
import muiTheme from "config/theme";
import { Formik } from "formik";
import usePushNotification from "hooks/usePushNotification";
import useSegment from "hooks/useSegment";
import useUserProfile from "hooks/useUserProfile";
import forms from "i18n/forms";
import _ from "lodash";
import { makeStyles } from "makeStyles";
import Match from "models/Match";
import Partnership from "models/Partnership";
import SlackMessage from "models/SlackMessage";
import {
  defineMessages,
  FormattedHTMLMessage,
  FormattedMessage,
  useIntl,
} from "react-intl";
import { WebhookEventName, WebhookService } from "services/WebhookService";
import { object, string } from "yup";

import { AccountData } from "./types";

/** {{{ Formik Functions & Configs */

const schema = object().shape({
  message: string().required(() => forms.generic_required),
});

type Props = WithAPI & {
  account: AccountData;
  handleClose: $TSFixMeFunction;
  isDemo: boolean;
  partnership?: Partnership;
};

/** }}} */

const NotifyOwnerSlackModal = ({
  account,
  handleClose,
  isDemo,
  partnership,
  createRecord,
}: Props) => {
  const { classes } = useStyles();
  const { classes: btnClasses } = useBtnStyles();
  const intl = useIntl();
  const { profile } = useUserProfile();
  const pushNotification = usePushNotification();
  const service = new WebhookService();
  const { track } = useSegment();

  const getAccountDetails = () => {
    let result = [account.name];
    let statusLabel = account.status
      ? _.get(Match.STATUS_LABELS, account.status, null)
      : null;
    if (statusLabel) {
      let status = intl.formatMessage(statusLabel);
      if (account.starred) {
        status += " ⭐";
      }
      result.push(status);
    }
    if (account.ownerName) {
      result.push(intl.formatMessage(i18n.ownedBy) + " " + account.ownerName);
    }
    return result.join(" · ");
  };

  const handleSubmit = async (values: $TSFixMe, actions: $TSFixMe) => {
    actions.setSubmitting(true);
    try {
      const response = await createRecord("slack_messages", {
        message_type: SlackMessage.TYPE_NOTIFY_ACCOUNT_OWNER,
        record_id: account.id,
        parameters: { text: values.message },
      });
      if (isRejected(response)) {
        pushNotification("default_error");
      } else {
        track("Confirmed sending in “Notify owner on slack”");
        service.track({
          profile: profile,
          eventName: WebhookEventName.RevealConfirmedSentSlackToAccountOwner,
          partnership: partnership,
          rawCompanyId: Number(account.id),
          rawCompanyProviderKey: account.rawCompanyProviderKey,
        });
        pushNotification({ ...i18n.success });
        actions.resetForm();
        handleClose();
      }
    } catch (error) {
      pushNotification("default_error");
    }
    actions.setSubmitting(false);
  };

  return (
    <div className={classes.root}>
      <MuiButton
        aria-label="close"
        className={classes.closeButton}
        onClick={handleClose}
      >
        <CloseIcon className={classes.closeIcon} />
      </MuiButton>
      <Grid container className={classes.container}>
        <div className={classes.logoContainer}>
          <img
            className={classes.logo}
            src={"/images/reveal-icon-purple-square.png"}
            alt=""
          />
        </div>
        <Grid item container className={classes.content}>
          <div className={classes.header}>
            <T h3 className={classes.headerTitle}>
              {"Reveal"}
            </T>
            <T
              className={classes.headerApp}
              // @ts-expect-error ts-migrate(2339) FIXME: Property 'darkBlue' does not exist on type 'Color'... Remove this comment to see the full error message
              color={muiTheme.palette.grey.darkBlue}
            >
              {"APP"}
            </T>
          </div>
          <div className={classes.title}>
            {account.ownerName && (
              <T h4 color={muiTheme.palette.link} className={classes.userTag}>
                {`@${account.ownerName}`.replace(" ", "")}
              </T>
            )}
            &#128293;&nbsp;
            <T h4>
              <FormattedHTMLMessage
                {...i18n.title}
                values={{ userName: profile.fullName, company: account.name }}
              />
            </T>
          </div>
          <Formik
            initialValues={{ message: "" }}
            onSubmit={handleSubmit}
            validationSchema={schema}
          >
            {({
              values,
              handleSubmit,
              handleBlur,
              handleChange,
              isValid,
              isSubmitting,
              touched,
              errors,
            }) => (
              <form onSubmit={handleSubmit}>
                <div className={classes.inputContainer}>
                  <FormControl className={classes.messageControl} fullWidth>
                    <TextField
                      inputProps={{
                        className: classes.messageTextarea,
                      }}
                      name="message"
                      onBlur={handleBlur}
                      multiline
                      maxRows="15"
                      variant="outlined"
                      disabled={isSubmitting}
                      placeholder={intl.formatMessage(i18n.placeholder)}
                      value={values.message}
                      onChange={handleChange}
                      // @ts-expect-error ts-migrate(2322) FIXME: Type 'boolean | "" | undefined' is not assignable ... Remove this comment to see the full error message
                      error={errors.message && touched.message}
                      label={null}
                    />
                  </FormControl>
                </div>
                <T
                  color={muiTheme.palette.primary.main}
                  className={classes.error}
                >
                  {(touched.message && errors.message && (
                    <FormattedMessage {...errors.message} />
                  )) ||
                    ""}
                </T>
                {/* @ts-expect-error ts-migrate(2339) FIXME: Property 'darkBlue' does not exist on type 'Color'... Remove this comment to see the full error message */}
                <T color={muiTheme.palette.grey.darkBlue}>
                  {getAccountDetails()}
                </T>
                <Grid item sm xs={12} className={classes.btnContainer}>
                  <Tooltip
                    title={
                      isDemo ? (
                        <FormattedMessage {...i18n.notAvailableForDemo} />
                      ) : (
                        ""
                      )
                    }
                    placement="top"
                  >
                    <span>
                      <Button
                        type="submit"
                        label={i18n.send}
                        bold
                        disabled={isDemo || isSubmitting || !isValid}
                        classes={btnClasses}
                      />
                    </span>
                  </Tooltip>
                </Grid>
              </form>
            )}
          </Formik>
        </Grid>
      </Grid>
    </div>
  );
};

export default withAPI(NotifyOwnerSlackModal);

// I18N

const i18n = defineMessages({
  title: {
    id: "crm.NotifyOwnerSlackModal.title",
    defaultMessage: "<b>{userName}</b> sent you a message about {company}:",
  },
  placeholder: {
    id: "crm.NotifyOwnerSlackModal.placeholder",
    defaultMessage: "Add your message here",
  },
  send: {
    id: "crm.NotifyOwnerSlackModal.send",
    defaultMessage: "Send to Slack",
  },
  success: {
    id: "crm.NotifyOwnerSlackModal.success",
    defaultMessage: "Done 👌",
  },
  notAvailableForDemo: {
    id: "crm.NotifyOwnerSlackModal.notAvailableForDemo",
    defaultMessage: "Not available for the Demonstration Partner",
  },
  customer: {
    id: "crm.NotifyOwnerSlackModal.customer",
    defaultMessage: "Customer",
  },
  prospect: {
    id: "crm.NotifyOwnerSlackModal.prospect",
    defaultMessage: "Prospect",
  },
  partner: {
    id: "crm.NotifyOwnerSlackModal.partner",
    defaultMessage: "Partner",
  },
  ownedBy: {
    id: "crm.NotifyOwnerSlackModal.ownedBy",
    defaultMessage: "Owned by",
  },
});

// CSS

const useStyles = makeStyles()((theme) => ({
  root: {
    padding: theme.spacing(5),
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    padding: theme.spacing(0),
    minWidth: 0,
  },
  closeIcon: {
    fontSize: 32,
  },
  container: {
    flexWrap: "nowrap",
  },
  logoContainer: {
    height: "fit-content",
    backgroundColor: theme.palette.offWhite,
    borderRadius: 30,
    marginRight: theme.spacing(1),
  },
  logo: {
    height: 40,
    width: 40,
    padding: theme.spacing(1),
  },
  content: {
    flexDirection: "column",
  },
  header: {
    display: "flex",
    borderBottom: `1px solid ${theme.palette.other.grid.lightBorder}`,
    marginBottom: theme.spacing(3),
  },
  headerTitle: {
    marginBottom: 0,
  },
  headerApp: {
    backgroundColor: theme.palette.other.grid.lightBorder,
    height: theme.spacing(2),
    marginTop: theme.spacing(0.5),
    marginLeft: theme.spacing(1),
    borderRadius: 2,
    padding: "0px 4px",
  },
  title: {
    display: "flex",
  },
  userTag: {
    marginRight: theme.spacing(1),
  },
  inputContainer: {
    borderLeft: `4px solid ${theme.palette.other.grid.lightBorder}`,
    marginTop: theme.spacing(2),
  },
  messageControl: {
    "& .MuiOutlinedInput-multiline": {
      padding: theme.spacing(3),
      marginLeft: theme.spacing(1),
      // @ts-expect-error ts-migrate(2339) FIXME: Property 'lightBlue' does not exist on type 'Color... Remove this comment to see the full error message
      backgroundColor: lighten(theme.palette.grey.lightBlue, 0.5),
    },
  },
  messageTextarea: {
    fontSize: 15,
    // @ts-expect-error ts-migrate(2339) FIXME: Property 'dark' does not exist on type 'Color'.
    color: theme.palette.grey.dark,
    height: "60px !important",
  },
  error: {
    display: "flex",
    justifyContent: "flex-end",
    marginTop: theme.spacing(1),
  },
  btnContainer: {
    display: "flex",
    justifyContent: "flex-end",
    marginTop: theme.spacing(4),
  },
  button: {
    fontSize: 15,
    fontWeight: 700,
    padding: theme.spacing(1.5, 3),
  },
  btnIcon: {
    marginLeft: theme.spacing(3),
    padding: 2,
    width: 25,
    height: 25,
    // @ts-expect-error ts-migrate(2339) FIXME: Property 'main' does not exist on type 'Color'.
    border: `1px solid ${theme.palette.grey.main}`,
    // @ts-expect-error ts-migrate(2339) FIXME: Property 'white' does not exist on type 'Color'.
    backgroundColor: theme.palette.grey.white,
  },
}));

const useBtnStyles = makeStyles()((theme) => ({
  btn: {
    textTransform: "uppercase",
  },
}));
