import { createTheme, darken, lighten } from "@mui/material/styles";
import type {} from "@mui/x-data-grid-premium/themeAugmentation";
import { FLAGS } from "config/featureFlags";
import * as colors from "styles/colors";

/** Colors */
export const brand = "#ff5768";
export const orange = "#ff855c";
export const gradiantCenter = "#ff655e";
export const blue = "#2583fd";
export const green = "#2acb3a";
export const turquoise = "#00d5bb";

/** Greys */
export const black = "#2c3340";
export const darkGrey = "#2c3340";
export const midGrey = "#a9b0be";
export const grey = "#d3d8e1";

declare module "@mui/material/styles/createPalette" {
  interface Gradients {
    darkPurpleToPurple: string;
    darkPurpleToGreen: string;
    blueToGreen: string;
    treeColor: string;
  }

  interface PaletteColor {
    update?: string;
    darkLine?: string;
    lightLine?: string;
    lightBorder?: string;
    selectedCell?: string;
  }
  interface Palette {
    // Primary colors
    purple: React.CSSProperties["color"];
    purple040: React.CSSProperties["color"];
    darkPurple: React.CSSProperties["color"];
    lightPurple: React.CSSProperties["color"];
    midnight: React.CSSProperties["color"];
    midnight500: React.CSSProperties["color"];
    midnightAlpha040: React.CSSProperties["color"];
    comet: React.CSSProperties["color"];
    darkPigeon: React.CSSProperties["color"];
    pigeon: React.CSSProperties["color"];
    greyReveal: React.CSSProperties["color"];
    grey500: React.CSSProperties["color"];
    darkTaupe: React.CSSProperties["color"];
    taupe: React.CSSProperties["color"];
    taupe500: React.CSSProperties["color"];
    taupe250: React.CSSProperties["color"];
    offWhite: React.CSSProperties["color"];
    ivory: React.CSSProperties["color"];
    white: React.CSSProperties["color"];
    green: React.CSSProperties["color"];
    appGreen: React.CSSProperties["color"];
    green600: React.CSSProperties["color"];
    red700: React.CSSProperties["color"];
    red700v2: React.CSSProperties["color"];
    red500: React.CSSProperties["color"];
    red600: React.CSSProperties["color"];
    red200: React.CSSProperties["color"];
    red100: React.CSSProperties["color"];
    red50: React.CSSProperties["color"];
    link: React.CSSProperties["color"];
    // Secondary colors
    wine: React.CSSProperties["color"];
    pictonBlue: React.CSSProperties["color"];
    orange: React.CSSProperties["color"];
    pistachio: React.CSSProperties["color"];
    elm: React.CSSProperties["color"];
    cornflowerBlue: React.CSSProperties["color"];
    gold: React.CSSProperties["color"];
    londonHue: React.CSSProperties["color"];
    coral: React.CSSProperties["color"];
    // Gradients
    gradients: Gradients;

    // Colors 2.0
    greyscale100: React.CSSProperties["color"];
    greyscale150: React.CSSProperties["color"];
    greyscale200: React.CSSProperties["color"];
    greyscale250: React.CSSProperties["color"];
    greyscale300: React.CSSProperties["color"];
    greyscale350: React.CSSProperties["color"];
    greyscale400: React.CSSProperties["color"];
    greyscale450: React.CSSProperties["color"];
    greyscale500: React.CSSProperties["color"];

    greyscale550: React.CSSProperties["color"];
    greyscale600: React.CSSProperties["color"];
    greyscale650: React.CSSProperties["color"];
    greyscale700: React.CSSProperties["color"];
    greyscale750: React.CSSProperties["color"];
    greyscale800: React.CSSProperties["color"];
    greyscale850: React.CSSProperties["color"];
    greyscale900: React.CSSProperties["color"];
    greyscale950: React.CSSProperties["color"];
    greyscale1000: React.CSSProperties["color"];

    greyDarkMode200: React.CSSProperties["color"];
    greyDarkMode800: React.CSSProperties["color"];

    greyLight050: React.CSSProperties["color"];
    greyLight050Alpha050: React.CSSProperties["color"];
    greyLight100: React.CSSProperties["color"];
    greyLight200: React.CSSProperties["color"];
    greyLight300: React.CSSProperties["color"];

    alpha050: React.CSSProperties["color"];
    alpha100: React.CSSProperties["color"];
    alpha150: React.CSSProperties["color"];
    alpha200: React.CSSProperties["color"];
    alpha250: React.CSSProperties["color"];
    alpha300: React.CSSProperties["color"];
    alpha350: React.CSSProperties["color"];
    alpha400: React.CSSProperties["color"];
    alpha450: React.CSSProperties["color"];
    alpha500: React.CSSProperties["color"];

    alpha550: React.CSSProperties["color"];
    alpha600: React.CSSProperties["color"];
    alpha650: React.CSSProperties["color"];
    alpha700: React.CSSProperties["color"];
    alpha750: React.CSSProperties["color"];
    alpha800: React.CSSProperties["color"];
    alpha850: React.CSSProperties["color"];
    alpha900: React.CSSProperties["color"];
    alpha950: React.CSSProperties["color"];
    alpha1000: React.CSSProperties["color"];

    green050: React.CSSProperties["color"];
    green100: React.CSSProperties["color"];
    green100v2: React.CSSProperties["color"];
    green200: React.CSSProperties["color"];
    green300: React.CSSProperties["color"];
    green400: React.CSSProperties["color"];
    green500: React.CSSProperties["color"];
    newGreen600: React.CSSProperties["color"];
    green700: React.CSSProperties["color"];
    green700v2: React.CSSProperties["color"];
    green800: React.CSSProperties["color"];
    green900: React.CSSProperties["color"];

    greenBrand050: React.CSSProperties["color"];
    greenBrand100: React.CSSProperties["color"];
    greenBrand400: React.CSSProperties["color"];
    greenBrand700: React.CSSProperties["color"];

    purple500: React.CSSProperties["color"];
    purple700: React.CSSProperties["color"];

    red050: React.CSSProperties["color"];
    red050v2: React.CSSProperties["color"];
    newRed100: React.CSSProperties["color"];
    red300: React.CSSProperties["color"];
    red400: React.CSSProperties["color"];
    red500v2: React.CSSProperties["color"];
    newRed600: React.CSSProperties["color"];
    red600v3: React.CSSProperties["color"];
    red800: React.CSSProperties["color"];
    red900: React.CSSProperties["color"];

    goldOverlay: React.CSSProperties["color"];
    gold050: React.CSSProperties["color"];
    gold100: React.CSSProperties["color"];
    gold200: React.CSSProperties["color"];
    gold300: React.CSSProperties["color"];
    gold400: React.CSSProperties["color"];
    gold400V2: React.CSSProperties["color"];
    gold500: React.CSSProperties["color"];
    gold600: React.CSSProperties["color"];
    gold700: React.CSSProperties["color"];
    gold800: React.CSSProperties["color"];
    gold900: React.CSSProperties["color"];

    brown100: React.CSSProperties["color"];
    brown700: React.CSSProperties["color"];

    olive100: React.CSSProperties["color"];
    olive700: React.CSSProperties["color"];

    teal100: React.CSSProperties["color"];
    teal700: React.CSSProperties["color"];

    overlay: React.CSSProperties["color"];

    blue100: React.CSSProperties["color"];

    yellow100: React.CSSProperties["color"];
    yellow700: React.CSSProperties["color"];

    orange100: React.CSSProperties["color"];

    purpleBrand050: React.CSSProperties["color"];
    purpleBrand100: React.CSSProperties["color"];

    pie1: React.CSSProperties["color"];
    pie2: React.CSSProperties["color"];

    cyan100: React.CSSProperties["color"];
    cyan700: React.CSSProperties["color"];

    // Custom colors
    other: {
      blue: PaletteColor;
      yellow: PaletteColor;
      grid: PaletteColor;
      gradientCenter: PaletteColor;
    };
  }
}

const muiTheme = createTheme({
  palette: {
    primary: {
      main: colors.darkPurple,
      light: colors.purple,
      dark: darken(colors.darkPurple, 0.5),
      contrastText: colors.offWhite,
    },
    secondary: {
      main: green,
      light: lighten(green, 0.5),
      contrastText: colors.white,
    },
    text: {
      primary: colors.midnight,
      secondary: colors.alpha750,
    },
    other: {
      blue: {
        main: blue,
        light: lighten(blue, 0.5),
        update: lighten(colors.purple, 0.9),
      },
      yellow: {
        main: "#ffd21a",
        light: "#fffbbb",
        veryLight: "#fcfaec",
        update: lighten(colors.green, 0.9),
      },
      grid: {
        darkLine: "#c0ccd1",
        lightLine: "#bac7cd",
        lightBorder: "#eaeef0",
        selectedCell: lighten(colors.purple, 0.95),
      },
      gradiantCenter,
    },
    grey: {
      // @ts-expect-error
      dark: darkGrey,
      midDark: "#616161",
      mid: midGrey,
      main: grey,
      light: colors.lightGrey,
      veryLight: colors.veryLightGrey,
      whitish: colors.greyWhite,
      lightWhitish: colors.lightGreyWhite,
      white: colors.white,
      disabled: "#E0E0E0",
      lightGreen: "#f6f6f2",
      lightBlue: "#f7f9fb",
      darkBlue: "#778f9b",
      blue: "#afc2cb",
    },
    // Reveal Primary Colors
    purple: colors.purple,
    purple040: colors.purple040,
    darkPurple: colors.darkPurple,
    lightPurple: colors.lightPurple,
    midnight: colors.midnight,
    midnight500: colors.midnight500,
    midnightAlpha040: colors.midnightAlpha040,
    comet: colors.comet,
    darkPigeon: colors.darkPigeon,
    pigeon: colors.pigeon,
    greyReveal: colors.greyReveal,
    grey500: colors.grey500,
    darkTaupe: colors.darkTaupe,
    taupe: colors.taupe,
    taupe500: colors.taupe500,
    taupe250: colors.taupe250,
    offWhite: colors.offWhite,
    ivory: colors.ivory,
    white: colors.white,
    green: colors.green,
    appGreen: colors.appGreen,
    green600: colors.green600,
    purple500: colors.purple500,
    purple700: colors.purple700,
    link: FLAGS.redesign ? colors.purple : blue,
    // Reveal Secondary Colors
    wine: colors.wine,
    pictonBlue: colors.pictonBlue,
    orange: colors.orange,
    pistachio: colors.pistachio,
    elm: colors.elm,
    cornflowerBlue: colors.cornflowerBlue,
    gold: colors.gold,
    londonHue: colors.londonHue,
    coral: colors.coral,
    // Gradients
    gradients: {
      darkPurpleToPurple: `linear-gradient(70deg, ${colors.darkPurple} 9%, ${colors.purple} 100%)`,
      darkPurpleToGreen: `linear-gradient(70deg, ${colors.darkPurple} 9%, ${colors.green} 100%)`,
      blueToGreen: `linear-gradient(70deg, #497BBB 9%, ${colors.green} 100%)`,
      treeColor: `linear-gradient(180deg, ${colors.offWhite} 13%, ${colors.green} 50%, ${colors.purple} 90%)`,
    },
    // Colors 2.0
    greyscale100: colors.greyscale100,
    greyscale150: colors.greyscale150,
    greyscale200: colors.greyscale200,
    greyscale250: colors.greyscale250,
    greyscale300: colors.greyscale300,
    greyscale350: colors.greyscale350,
    greyscale400: colors.greyscale400,
    greyscale450: colors.greyscale450,
    greyscale500: colors.greyscale500,

    greyscale550: colors.greyscale550,
    greyscale600: colors.greyscale600,
    greyscale650: colors.greyscale650,
    greyscale700: colors.greyscale700,
    greyscale750: colors.greyscale750,
    greyscale800: colors.greyscale800,
    greyscale850: colors.greyscale850,
    greyscale900: colors.greyscale900,
    greyscale950: colors.greyscale950,
    greyscale1000: colors.greyscale1000,

    greyDarkMode200: colors.greyDarkMode200,
    greyDarkMode800: colors.greyDarkMode800,

    greyLight050: colors.greyLight050,
    greyLight050Alpha050: colors.greyLight050Alpha050,
    greyLight100: colors.greyLight100,
    greyLight200: colors.greyLight200,
    greyLight300: colors.greyLight300,

    alpha050: colors.alpha050,
    alpha100: colors.alpha100,
    alpha150: colors.alpha150,
    alpha200: colors.alpha200,
    alpha250: colors.alpha250,
    alpha300: colors.alpha300,
    alpha350: colors.alpha350,
    alpha400: colors.alpha400,
    alpha450: colors.alpha450,
    alpha500: colors.alpha500,

    alpha550: colors.alpha550,
    alpha600: colors.alpha600,
    alpha650: colors.alpha650,
    alpha700: colors.alpha700,
    alpha750: colors.alpha750,
    alpha800: colors.alpha800,
    alpha850: colors.alpha850,
    alpha900: colors.alpha900,
    alpha950: colors.alpha950,
    alpha1000: colors.alpha1000,

    green050: colors.green050,
    green100: colors.green100,
    green100v2: colors.green100v2,
    green200: colors.green200,
    green300: colors.green300,
    green400: colors.green400,
    green500: colors.green500,
    newGreen600: colors.newGreen600,
    green700: colors.green700,
    green700v2: colors.green700v2,
    green800: colors.green800,
    green900: colors.green900,

    greenBrand050: colors.greenBrand050,
    greenBrand100: colors.greenBrand100,
    greenBrand400: colors.greenBrand400,
    greenBrand700: colors.greenBrand700,

    red50: colors.red50,
    red050: colors.red050,
    red050v2: colors.red050v2,
    red100: colors.red100,
    newRed100: colors.newRed100,
    red200: colors.red200,
    red300: colors.red300,
    red400: colors.red400,
    red500: colors.red500,
    red500v2: colors.red500v2,
    red600: colors.red600,
    newRed600: colors.newRed600,
    red600v3: colors.red600v3,
    red700: colors.red700,
    red700v2: colors.red700v2,
    red800: colors.red800,
    red900: colors.red900,

    goldOverlay: colors.goldOverlay,
    gold050: colors.gold050,
    gold100: colors.gold100,
    gold200: colors.gold200,
    gold300: colors.gold300,
    gold400: colors.gold400,
    gold400V2: colors.gold400V2,
    gold500: colors.gold500,
    gold600: colors.gold600,
    gold700: colors.gold700,
    gold800: colors.gold800,
    gold900: colors.gold900,

    brown100: colors.brown100,
    brown700: colors.brown700,

    olive100: colors.olive100,
    olive700: colors.olive700,

    teal100: colors.teal100,
    teal700: colors.teal700,

    overlay: colors.overlay,
    blue100: colors.blue100,
    yellow100: colors.yellow100,
    yellow700: colors.yellow700,
    orange100: colors.orange100,
    purpleBrand050: colors.purpleBrand050,
    purpleBrand100: colors.purpleBrand100,

    pie1: colors.pie1,
    pie2: colors.pie2,

    cyan100: colors.cyan100,
    cyan700: colors.cyan700,
  },
  // @ts-expect-error ts-migrate(2322) FIXME: Type 'any[]' is not assignable to type 'Shadows'.
  shadows: Array(25).fill("none"),
  typography: {
    fontFamily: "'Inter', sans-serif",
    h1: {
      fontSize: "26px",
      lineHeight: "30px",
      fontWeight: 500,
    },
    h2: {
      fontSize: "24px",
      lineHeight: "28px",
      fontWeight: 500,
    },
    h3: {
      fontSize: "17px",
      lineHeight: "24px",
      fontWeight: 600,
    },
    h4: {
      fontSize: "17px",
      lineHeight: "24px",
      fontWeight: 400,
    },
    h5: {
      fontSize: "16px",
      lineHeight: "20px",
      fontWeight: 600,
    },
    h6: {
      fontSize: "14px",
      lineHeight: "20px",
      fontWeight: 600,
    },
    // All following typography styles are going to be replaced by `bodySmall` at first.
    subtitle1: {
      fontSize: "14px",
      lineHeight: "20px",
      fontWeight: 300,
    },
    subtitle2: {
      fontSize: "13px",
      lineHeight: "16x",
      fontWeight: 500,
    },
    body1: {
      fontSize: "15px",
      lineHeight: "24px",
      fontWeight: 500,
    },
    body2: {
      fontSize: "14px",
      lineHeight: "20px",
      fontWeight: 400,
    },
    caption: {
      fontSize: "12px",
      lineHeight: "16px",
      fontWeight: 400,
    },
  },
  components: {
    MuiBackdrop: {
      styleOverrides: {
        root: {
          backgroundColor: colors.midnight + "14", // The 14 is used to have transparency of 8%
        },
      },
    },
    MuiChip: {
      styleOverrides: {
        colorPrimary: {
          color: colors.white,
        },
      },
    },
    MuiInput: {
      styleOverrides: {
        underline: {
          "&:after": {
            borderBottomColor: colors.darkPurple,
          },
          "&$error:after": {
            borderBottomColor: colors.error,
          },
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          "&$focused $notchedOutline": {
            borderColor: colors.darkPurple,
          },
        },
      },
    },
    MuiFormHelperText: {
      styleOverrides: {
        root: {
          marginLeft: 8,
          "&$error": {
            color: colors.error,
          },
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: 5,
          textTransform: "none",
          "&:focus": {
            outline: 0,
          },
        },
        containedPrimary: {
          color: colors.offWhite,
          backgroundImage: `linear-gradient(30deg, ${colors.darkPurple} 50%, ${colors.purple} 100%)`,
          "&$disabled": {
            backgroundImage: "none",
          },
          "&:hover": {
            backgroundImage: `linear-gradient(30deg, ${colors.darkPurple} 0%, ${colors.green} 100%)`,
          },
        },
        containedSecondary: {
          color: colors.darkGrey,
          backgroundColor: "transparent",
          boxShadow: `inset 0 0 0 1px ${grey}`,
          "&:hover": {
            boxShadow: `inset 0 0 0 1px ${midGrey}`,
            backgroundColor: "transparent",
          },
        },
      },
    },
    MuiCheckbox: {
      styleOverrides: {
        root: {
          color: colors.grey,
        },
      },
    },
    MuiToolbar: {
      styleOverrides: {
        root: {
          minHeight: "42px !important",
        },
      },
    },
    MuiTableCell: {
      styleOverrides: {
        root: {
          fontSize: 12,
          lineHeight: "15px",
          paddingTop: 4,
          paddingBottom: 4,
          paddingRight: 10,
          paddingLeft: 10,
          "&:last-child": {
            paddingRight: 4,
          },
        },
        head: {
          backgroundColor: colors.greyLight050,
        },
      },
    },
    MuiBadge: {
      styleOverrides: {
        colorPrimary: {
          backgroundColor: colors.comet,
        },
      },
    },
    MuiDataGrid: {
      styleOverrides: {
        root: {
          fontFamily: "'Inter', sans-serif",
          background: colors.ivory,
          fontSize: 12,
          borderRadius: 0,
        },
        pinnedColumns: {
          background: colors.ivory,
          boxShadow: `2px 0px 4px -2px ${colors.ivory}`,
        },
        pinnedColumnHeaders: {
          background: colors.ivory,
        },
        columnHeaders: {
          background: colors.ivory,
          "&.grouped": {
            maxHeight: "88px !important",
          },
        },
        columnHeader: {
          "&.MuiDataGrid-columnHeader--sortable:hover svg[data-testid='ArrowDownAlphaIcon'] path": {
            fill: colors.midnight,
            fillOpacity: 1,
          },
          "&.visible-sort-view .MuiDataGrid-iconButtonContainer, &.visible-sort-view .MuiDataGrid-iconButtonContainer:hover": {
            visibility: "visible",
            width: "28px !important",
            "& .MuiDataGrid-sortIcon": {
              opacity: "1 !important",
            },
          },
          "&.checkbox": {
            padding: "0px !important",
            outline: "none",
          },
          "&.company-side": {
            borderTop: `solid 28px ${lighten(colors.purple, 0.8)} !important`,
            background: lighten(colors.purple, 0.95),
            "&:focus": {
              outline: `solid ${lighten(colors.purple, 0.8)} 1px`,
            },
            "&:focus-within": {
              outline: `solid ${lighten(colors.purple, 0.8)} 1px`,
            },
          },
          "&.company-side-checkbox": {
            borderTop: `solid 28px ${lighten(colors.purple, 0.8)} !important`,
            background: lighten(colors.purple, 0.95),
            padding: "0px !important",
            outline: "none",
          },
          "&.partner-side": {
            borderTop: `solid 28px ${lighten(colors.green700, 0.8)} !important`,
            background: lighten(colors.green700, 0.95),
            "&:focus": {
              outline: `solid ${lighten(colors.green700, 0.8)} 1px`,
            },
            "&:focus-within": {
              outline: `solid ${lighten(colors.green700, 0.8)} 1px`,
            },
          },
          "&.partner-side-checkbox": {
            borderTop: `solid 28px ${lighten(colors.green700, 0.8)} !important`,
            background: lighten(colors.green700, 0.95),
            padding: "0px !important",
            outline: "none",
          },
          "&.first-right": {
            borderLeft: `solid 1px ${colors.alpha200} !important`,
          },
          "&.clickable-header": {
            cursor: "pointer",

            "& svg[data-testid='QuickFilterIcon'] path": {
              fill: colors.alpha500,
            },

            "&:hover svg[data-testid='QuickFilterIcon'] path": {
              fill: colors.midnight,
            },
          },
          "&.no-side": {
            outline: "none",
            "& > .MuiDataGrid-columnHeaderDraggableContainer > .MuiDataGrid-columnHeaderTitleContainer": {
              alignItems: "center",
              gap: 4,
              paddingTop: 8,
              paddingBottom: 8,
              "& > .MuiDataGrid-columnHeaderTitleContainerContent > .MuiDataGrid-columnHeaderTitle": {
                whiteSpace: "normal",
                lineHeight: "normal",
                maxHeight: 50,
              },
              "& > .MuiDataGrid-columnHeaderTitleContainerContent > .line-clamp-container": {
                overflow: "hidden",
                "& > .MuiDataGrid-columnHeaderTitle": {
                  whiteSpace: "normal",
                  lineHeight: "normal",
                  maxHeight: 50,
                  display: "-webkit-box",
                  "-webkit-line-clamp": "2",
                  "-webkit-box-orient": "vertical",
                  overflow: "hidden",
                  position: "relative",
                },
              },
            },
          },
          "&.two-storey": {
            borderTop: `solid 34px transparent !important`,
            "& > .MuiDataGrid-columnSeparator": {
              marginTop: 4,
            },
            "& .MuiDataGrid-iconButtonContainer": {
              marginTop: -4,
            },
          },
          "&.single-storey": {
            "& > .MuiDataGrid-columnSeparator": {
              marginTop: -28,
            },
            "& .MuiDataGrid-iconButtonContainer": {
              marginTop: -4,
            },
          },
        },
        columnHeaderCheckbox: {
          padding: 0,
        },
        columnHeadersInner: {
          "&.company-side": {
            borderTop: `solid 28px ${lighten(colors.purple, 0.8)} !important`,
            background: lighten(colors.purple, 0.95),
            "&:focus": {
              outline: `solid ${lighten(colors.purple, 0.8)} 1px`,
            },
          },
          width: "100%",
        },
        columnHeaderTitleContainerContent: {
          width: "100%",
        },
        withBorderColor: {
          borderColor: colors.alpha250,
        },
        cell: {
          "&.first-right": {
            borderLeft: `solid 1px ${colors.alpha200} !important`,
          },
          "&.locked": {
            background: colors.overlay,
          },
          "&.purple-overlay": {
            background: colors.purple040,
          },
          "&.golden-overlay": {
            background: colors.goldOverlay,
          },
        },
        cellCheckbox: {
          borderRight: "none",
        },
        checkboxInput: {
          "& svg": {
            fontSize: 17,
            color: colors.midnight,
          },
          "&.Mui-disabled svg": {
            color: colors.taupe,
          },
        },
        row: {
          "&.highlighted-row-purple": {
            backgroundColor: colors.purpleBrand050,
          },
          "&.highlighted-row-green": {
            backgroundColor: colors.greenBrand050,
          },
          "& .showOnHover": {
            display: "none",
          },
          "&.Mui-hovered": {
            backgroundColor: colors.greyscale100,
            "& > .MuiDataGrid-cell > div >.partnerPresenceGradient": {
              background: `linear-gradient(270deg, ${colors.greyscale100} 50.52%, rgba(250, 250, 246, 0) 100%)`,
            },
            "& > .MuiDataGrid-cell .showOnHover": {
              display: "flex",
            },
          },
        },
      },
    },
  },
});

export default muiTheme;
