import { isRejected } from "@reduxjs/toolkit";
import Dropdown from "components/ui/Dropdown";
import { IDropdownOption } from "components/ui/Dropdown/components/types";
import usePushNotification from "hooks/usePushNotification";
import Partnership from "models/Partnership";
import { cloneElement, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { rawPost } from "redux/api/thunks";
import { selectUsersWithLicense } from "redux/init/selectors";

type Props = {
  partnership?: Partnership;
  children: React.ReactElement;
};

const PartnershipOwnerDropdown = ({ partnership, children }: Props) => {
  const dispatch = useDispatch();
  const pushNotification = usePushNotification();
  const [anchorEl, setAnchorEl] = useState<Element | null>(null);
  const [openDropdown, setOpenDropdown] = useState(false);
  const [processing, setProcessing] = useState(false);
  const users = useSelector(selectUsersWithLicense);
  const loading = users && users.length === 0;

  const userOptions = useMemo(() => {
    return users.map((user) => {
      return {
        id: user.id.toString(),
        name: user.fullName,
      };
    }) as IDropdownOption[];
  }, [users]);

  const owner = partnership?.partnershipOwner;

  const handleOpenDropdown = (event: React.SyntheticEvent) => {
    setAnchorEl(event.currentTarget);
    setOpenDropdown(true);
  };

  const handleCloseDropdown = () => {
    setAnchorEl(null);
    setOpenDropdown(false);
  };

  const updateOwner = async (ids: string[] | null) => {
    if (!partnership) {
      return;
    }
    if (ids !== null && (ids[0] !== owner?.id.toString() ?? "")) {
      setProcessing(true);
      const result = await dispatch(
        rawPost({
          type: "partnerships",
          id: partnership.id,
          path: "/update-owner/",
          payload: {
            data: {
              id: partnership.id,
              type: "partnerships",
              owner_id: ids[0],
            },
          },
          options: { include: ["partnership_owner"] },
        })
      );
      if (isRejected(result)) {
        pushNotification("default_error");
      }
      setProcessing(false);
    }
    handleCloseDropdown();
  };

  const childWithOnClick = cloneElement(children, {
    loading: loading || processing,
    onClick: handleOpenDropdown,
  });

  return (
    <>
      {childWithOnClick}
      <Dropdown
        onChange={updateOwner}
        options={userOptions}
        open={openDropdown}
        onClose={handleCloseDropdown}
        anchorEl={anchorEl}
        size="small"
        value={owner ? [owner.id.toString()] : []}
      />
    </>
  );
};

export default PartnershipOwnerDropdown;
