import SelectedRowsContext from "components/ui/data-grid/SelectedRowsContext";
import useUserProfile from "hooks/useUserProfile";
import { useContext, useMemo } from "react";
import { useSelector } from "react-redux";
import { selectPipelineSlice } from "redux/pipeline/selectors";
import { formatRangeFilters } from "redux/utils";
import { snakeCaseWithDoubleUnderscore } from "screens/Frontoffice/shared/helpers/snakeCase";
import UserService from "services/UserService";

import ExportOptions from "../../../shared/components/ActionBtn/components/ExportOptions";
import useCsvExport from "../../../shared/hooks/useCsvExport";
import { MatchFilterType } from "../../../shared/types";
import { ReportType } from "../../../shared/utils";

export const MyPipelineExportButton = () => {
  const { ready, view } = useSelector(selectPipelineSlice);
  const { profile } = useUserProfile();
  const service = new UserService();
  const displayExport =
    !service.isImpersonating || profile.company.allowedAdminsToGenerateReports;
  const { selectedRowIds } = useContext(SelectedRowsContext);

  const filtersWithRows = useMemo(
    () => [
      ...(view?.filters ?? []).map((filter) => ({
        ...filter,
        fieldname: snakeCaseWithDoubleUnderscore(filter.fieldname),
      })),
      ...(selectedRowIds.length
        ? [
            {
              fieldname: "id",
              type: MatchFilterType._IN_OPERATOR,
              value: selectedRowIds,
            },
          ]
        : []),
    ],
    [selectedRowIds, view]
  );
  const parameters = { filters: formatRangeFilters(filtersWithRows) };
  const { isGenerating, ready: exportReady, download } = useCsvExport(
    ReportType.COLLABORATE,
    parameters
  );

  return displayExport && ready ? (
    <ExportOptions
      reportType={ReportType.COLLABORATE}
      parameters={parameters}
      isCsvGenerating={isGenerating}
      isCsvReady={exportReady}
      downloadCsv={download}
      selectedRowsCount={selectedRowIds.length}
    />
  ) : null;
};
