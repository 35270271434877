import { Divider } from "@mui/material";
import Grid from "@mui/material/Grid";
import { isRejected } from "@reduxjs/toolkit";
import BaseSettingsTab from "components/ui/BaseSettingsTab";
import Checkbox from "components/ui/Checkbox";
import useAllRecords from "hooks/useAllRecords";
import usePushNotification from "hooks/usePushNotification";
import useUserProfile from "hooks/useUserProfile";
import generic from "i18n/generic";
import _ from "lodash";
import { makeStyles } from "makeStyles";
import { useCallback, useEffect, useMemo, useState } from "react";
import { defineMessages, FormattedMessage } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { rawPatch, retreive } from "redux/api/thunks";
import { RevealStore } from "redux/typing";

export type BaseProps = {
  integrationId: string;
  defaultAll?: boolean;
  presave?: () => void;
  callback?: () => void;
};

type Props = BaseProps & {
  handleClose?: () => void;
  summary?: string;
  isFromMakeDefault?: boolean;
};

export const useAssignmentWidget = ({
  defaultAll,
  integrationId,
  presave,
  callback,
}: BaseProps) => {
  const { profile } = useUserProfile();
  const dispatch = useDispatch();
  const pushNotification = usePushNotification();
  const [saving, setSaving] = useState(false);
  const [
    watchPartnershipIntegrations,
    setWatchPartnershipIntegrations,
  ] = useState(true);
  const { records: partnerships, loading } = useAllRecords("partnerships", {
    include: ["initiator_company", "dest_company", "integrations"],
  });
  const { classes } = useStyles({ partnershipCount: partnerships.length });
  const integration = useSelector((state: RevealStore) =>
    _.get(_.get(state, "api.entities.integrations"), integrationId)
  );

  const fetch = useCallback(async () => {
    setSaving(true);
    try {
      await dispatch(
        retreive({
          id: +integrationId,
          type: "integrations",
          options: {
            include: ["partnership_integrations"],
            fields: {
              integrations: ["partnership_integrations"],
            },
          },
        })
      );
    } catch (error) {}
    setSaving(false);
  }, [dispatch, integrationId]);

  useEffect(() => {
    fetch();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const partnershipIds = defaultAll
    ? _.map(partnerships, (item: $TSFixMe) => +item.id)
    : _.map(
        integration?.partnershipIntegrations,
        (item: $TSFixMe) => +item.partnershipId
      );

  const [initialPartnershipIds, setInitialPartnershipIds] = useState(
    partnershipIds
  );
  const [enabledPartnershipIds, setEnabledPartnershipIds] = useState(
    partnershipIds
  );
  const hasChanged = useMemo(
    () =>
      !_.isEqual(
        _.sortedUniq(enabledPartnershipIds),
        _.sortedUniq(initialPartnershipIds)
      ),
    [enabledPartnershipIds, initialPartnershipIds]
  );
  const isEnabled = useCallback(
    (partnershipId: $TSFixMe) => enabledPartnershipIds.includes(+partnershipId),
    [enabledPartnershipIds]
  );

  const togglePartnership = useCallback(
    (event: $TSFixMe) => {
      const { value: partnershipId, checked } = event.target;
      if (checked && !isEnabled(partnershipId)) {
        setEnabledPartnershipIds((current: $TSFixMe) => [
          ...current,
          +partnershipId,
        ]);
      } else if (!checked && isEnabled(partnershipId)) {
        setEnabledPartnershipIds((current: $TSFixMe) =>
          _.filter(current, (item: $TSFixMe) => +item !== +partnershipId)
        );
      }
    },
    [isEnabled]
  );

  const toggleAllPartnerships = useCallback(
    (event: $TSFixMe) => {
      const { checked } = event.target;
      if (checked) {
        setEnabledPartnershipIds(partnerships.map((item) => item.id));
      } else {
        setEnabledPartnershipIds([]);
      }
    },
    [partnerships]
  );

  const handleSave = useCallback(async () => {
    setSaving(true);
    if (presave) {
      await presave();
    }
    setWatchPartnershipIntegrations(false);
    const result = await dispatch(
      rawPatch({
        id: integration?.id,
        type: "integrations",
        path: "/relationships/partnerships/",
        payload: {
          data: enabledPartnershipIds.map((id) => ({
            type: "partnerships",
            id: `${id}`,
          })),
        },
      })
    );
    fetch();
    if (isRejected(result)) {
      pushNotification("default_error");
    } else {
      pushNotification({ ...generic.edits_saved });
      setInitialPartnershipIds(enabledPartnershipIds);
    }
    setSaving(false);
    setWatchPartnershipIntegrations(true);
    if (callback) {
      callback();
    }
  }, [
    callback,
    dispatch,
    enabledPartnershipIds,
    presave,
    pushNotification,
    integration,
    fetch,
  ]);

  useEffect(() => {
    if (watchPartnershipIntegrations) {
      setInitialPartnershipIds(partnershipIds);
      setEnabledPartnershipIds(partnershipIds);
    }
  }, [loading, integration?.partnershipIntegrations]); // eslint-disable-line react-hooks/exhaustive-deps
  const isToggleAllChecked = useMemo(
    () =>
      _.isEqual(
        _.sortBy(enabledPartnershipIds),
        _.sortBy(partnerships.map((item) => item.id))
      ),
    [enabledPartnershipIds, partnerships]
  );

  const content = useMemo(
    () => (
      <Grid container>
        <Grid item xs={12}>
          <Grid item>
            <Checkbox
              label={i18n.selectAll}
              onChange={toggleAllPartnerships}
              checked={isToggleAllChecked}
              indeterminate={
                !isToggleAllChecked && !!enabledPartnershipIds.length
              }
            />
          </Grid>
          <Divider />
        </Grid>
        <Grid item xs={12}>
          <Grid container className={classes.container}>
            {partnerships.map((partnership: $TSFixMe) => {
              const isDisabled =
                !!integration?.id &&
                partnership.integrations?.length === 1 &&
                +partnership.integrations[0].id === +integration.id &&
                isEnabled(partnership.id);
              return (
                <Grid item key={partnership.id}>
                  <Checkbox
                    id={`integration-partnership-${partnership.id}`}
                    label={partnership.getPartner(profile).name}
                    onChange={isDisabled ? () => {} : togglePartnership}
                    value={partnership.id}
                    checked={isEnabled(partnership.id)}
                    disabled={isDisabled}
                  />
                </Grid>
              );
            })}
          </Grid>
        </Grid>
      </Grid>
    ),
    [
      classes.container,
      enabledPartnershipIds.length,
      integration?.id,
      isEnabled,
      isToggleAllChecked,
      partnerships,
      profile,
      toggleAllPartnerships,
      togglePartnership,
    ]
  );

  return useMemo(
    () => ({
      content,
      enabledPartnershipIds,
      handleSave,
      hasChanged,
      loading,
      saving,
    }),
    [content, enabledPartnershipIds, handleSave, hasChanged, loading, saving]
  );
};

export const AssignmentWidget = (props: Props) => {
  const {
    callback,
    defaultAll,
    handleClose,
    isFromMakeDefault,
    summary,
  } = props;
  const {
    content,
    enabledPartnershipIds,
    handleSave,
    hasChanged,
    loading,
    saving,
  } = useAssignmentWidget(props);

  return (
    <BaseSettingsTab
      summary={summary}
      disabled={!hasChanged && !defaultAll}
      loading={loading}
      saving={saving}
      handleClose={handleClose}
      handleSave={handleSave}
      callback={callback}
      // @ts-expect-error ts-migrate(2322) FIXME: Type 'Element | null' is not assignable to type 's... Remove this comment to see the full error message
      saveLabel={
        isFromMakeDefault ? (
          <FormattedMessage
            {...i18n.makeDefault}
            values={{ count: enabledPartnershipIds.length }}
          />
        ) : null
      }
      {...props}
    >
      {content}
    </BaseSettingsTab>
  );
};

// CSS

export const useStyles = makeStyles<{ partnershipCount: number }>()(
  (theme, { partnershipCount }) => ({
    container: {
      display: "flex",
      flexDirection: "column",
      flexWrap: "wrap",
      width: "100%",
      height: Math.ceil(partnershipCount / 3) * 50,
    },
  })
);

// I18N

const i18n = defineMessages({
  makeDefault: {
    id: "Settings.DefaultSource.AssignmentWidget.makeDefault",
    defaultMessage:
      "Make default & assign to {count, plural, one {1 partnership} other {# partnerships}}",
  },
  makeDefaultSubtitle: {
    id: "Settings.DefaultSource.ToggleDefaultConfirm.makeDefaultSubtitle",
    defaultMessage:
      "You're adding this data source to the following partnership and all new partnerships moving forward.",
  },
  removeDefaultSubtitle: {
    id: "Settings.DefaultSource.ToggleDefaultConfirm.removeDefaultSubtitle",
    defaultMessage:
      "It will no longer be used by default for new partnerships connections. However you will still be able to manually assign it to partnerships.",
  },
  selectAll: {
    id: "Settings.DefaultSource.AssignmentWidget.selectAll",
    defaultMessage: "Select All",
  },
});
