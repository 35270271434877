import { FiberManualRecord } from "@mui/icons-material";
import { Theme } from "@mui/material";
import { Info } from "components/icons";
import Tooltip from "components/ui/Tooltip";
import { T } from "components/ui/Typography";
import { ConfigContext } from "config/ConfigProvider";
import useAmountFormatter from "hooks/useAmountFormatter";
import generic from "i18n/generic";
import { makeStyles } from "makeStyles";
import { useContext } from "react";
import { defineMessages, FormattedMessage, useIntl } from "react-intl";

type MetricsBoxVariant = "leadGen" | "source" | "influence";

type MetricsBoxStyleProps = {
  variant: MetricsBoxVariant;
};

type MetricsBoxProps = {
  amount: number | null;
  count: number | null;
} & MetricsBoxStyleProps;

export const MetricsBox = ({ variant, amount, count }: MetricsBoxProps) => {
  const intl = useIntl();
  const config = useContext(ConfigContext);
  const { classes, cx } = useStyles({ variant });
  const amountFormatter = useAmountFormatter(intl);

  const tooltip = (
    <T>
      <FormattedMessage {...getTooltip(variant)} />
      <span>
        &nbsp;
        <a
          className={classes.tooltipLink}
          target="_blank"
          href={`${config.partnerAnalyticsDashboardLink}`}
          rel="noopener noreferrer"
        >
          <FormattedMessage {...generic.learn_more} />
        </a>
      </span>
    </T>
  );

  const noDataTooltip = (
    <T>
      <FormattedMessage {...i18n.noData} />
    </T>
  );

  return (
    <div className={classes.boxContainer}>
      <div className={classes.boxHeader}>
        <div className={classes.title}>
          <FiberManualRecord className={classes.dot} />
          <T bold>
            <FormattedMessage {...getTitle(variant)} />
          </T>
        </div>
        <Tooltip title={tooltip}>
          <Info className={classes.infoIcon} />
        </Tooltip>
      </div>
      <Tooltip title={amount === null && noDataTooltip}>
        <T h3 bold className={classes.amountContainer}>
          {amount === null ? "--" : amountFormatter(amount, "USD")}
        </T>
      </Tooltip>
      <div className={classes.labelContainer}>
        {count !== null && (
          <T bold className={cx(classes.subTitle, classes.count)}>
            {count}
          </T>
        )}
        <T bold className={cx(classes.subTitle, classes.label)}>
          <FormattedMessage
            {...getDescription(variant, amount)}
            values={{ value: count === null ? "--" : count }}
          />
        </T>
      </div>
    </div>
  );
};

/// CSS

const getColor = (theme: Theme, variant: MetricsBoxVariant) => {
  switch (variant) {
    case "leadGen":
      return theme.palette.purple;
    case "source":
      return theme.palette.pie1;
    case "influence":
      return theme.palette.pie2;
  }
};

const getTooltip = (variant: MetricsBoxVariant) => {
  switch (variant) {
    case "leadGen":
      return i18n.leadGenTooltip;
    case "source":
      return i18n.sourceTooltip;
    case "influence":
      return i18n.influenceTooltip;
  }
};

const getTitle = (variant: MetricsBoxVariant) => {
  switch (variant) {
    case "leadGen":
      return i18n.leadGen;
    case "source":
      return i18n.source;
    case "influence":
      return i18n.influence;
  }
};

const getDescription = (variant: MetricsBoxVariant, amount: number | null) => {
  const isNull = amount === null;
  switch (variant) {
    case "leadGen":
      return isNull ? i18n.leadGenDescriptionEmpty : i18n.leadGenDescription;
    case "source":
      return isNull ? i18n.sourceDescriptionEmpty : i18n.sourceDescription;
    case "influence":
      return isNull
        ? i18n.influenceDescriptionEmpty
        : i18n.influenceDescription;
  }
};

const useStyles = makeStyles<MetricsBoxStyleProps>()((theme, props) => ({
  boxContainer: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
  },
  boxHeader: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: 13,
  },
  title: {
    display: "flex",
    alignItems: "center",
    columnGap: 6,
  },
  dot: {
    height: 7,
    width: 7,
    color: getColor(theme, props.variant ?? "leadGen"),
  },
  infoIcon: {
    fontSize: 16,
    color: theme.palette.greyscale650,
  },
  subTitle: {
    fontSize: 11,
    lineHeight: "16px",
  },
  amountContainer: {
    display: "flex",
    width: "fit-content",
  },
  labelContainer: {
    display: "flex",
    columnGap: 4,
  },
  count: {
    color: theme.palette.greyscale750,
  },
  label: {
    color: theme.palette.greyscale650,
  },
  tooltipLink: {
    color: theme.palette.ivory,
    textDecoration: "underline",
    "&:hover": {
      cursor: "pointer",
      color: theme.palette.ivory,
      textDecorationColor: theme.palette.ivory,
    },
  },
}));

// I18N

const i18n = defineMessages({
  leadGen: {
    id: "Analytics.SummaryMetrics.MetricsBox.leadGen",
    defaultMessage: "Lead gen",
  },
  leadGenDescription: {
    id: "Analytics.SummaryMetrics.MetricsBox.leadGenDescription",
    defaultMessage:
      "Partners’ {value, plural, one {Customer} other {Customers}} not in your CRM",
  },
  leadGenDescriptionEmpty: {
    id: "Analytics.SummaryMetrics.MetricsBox.leadGenDescriptionEmpty",
    defaultMessage: "-- Partners’ Customers not in your CRM",
  },
  leadGenTooltip: {
    id: "Analytics.SummaryMetrics.MetricsBox.leadGenTooltip",
    defaultMessage:
      "My partners’ customers that are not in my CRM. Revenue potential is based on the # of accounts * Win Rate * Deal Size.",
  },
  source: {
    id: "Analytics.SummaryMetrics.MetricsBox.source",
    defaultMessage: "Source",
  },
  sourceDescription: {
    id: "Analytics.SummaryMetrics.MetricsBox.sourceDescription",
    defaultMessage:
      "{value, plural, one {Prospect that is Partners’ Customer} other {Prospects that are Partners’ Customers}}",
  },
  sourceDescriptionEmpty: {
    id: "Analytics.SummaryMetrics.MetricsBox.sourceDescriptionEmpty",
    defaultMessage: "-- Prospects that are Partners’ Customers",
  },
  sourceTooltip: {
    id: "Analytics.SummaryMetrics.MetricsBox.sourceTooltip",
    defaultMessage:
      "My prospects with no open deal that are my partners' customers. Revenue potential is based on the # of accounts * Win Rate * Deal Size.",
  },
  influence: {
    id: "Analytics.SummaryMetrics.MetricsBox.influence",
    defaultMessage: "Influence",
  },
  influenceDescription: {
    id: "Analytics.SummaryMetrics.MetricsBox.influenceDescription",
    defaultMessage:
      "Open {value, plural, one {opportunity} other {opportunities}} on Partners' Customers",
  },
  influenceDescriptionEmpty: {
    id: "Analytics.SummaryMetrics.MetricsBox.influenceDescriptionEmpty",
    defaultMessage: "-- Open opportunities on Partners' Customers",
  },
  influenceTooltip: {
    id: "Analytics.SummaryMetrics.MetricsBox.influenceTooltip",
    defaultMessage:
      "My prospects with an open deal that are my partners' customers. Revenue potential is based on the # of accounts * Win Rate * Deal Size.",
  },
  noData: {
    id: "Analytics.SummaryMetrics.MetricsBox.noData",
    defaultMessage: "The  metric is still computing.",
  },
});
