import { useEffect } from "react";

type Props = {
  children: React.ReactNode[] | React.ReactNode;
  cancelScrollTop?: boolean;
};

function ScrollToTop(props: Props) {
  useEffect(() => {
    if (!props.cancelScrollTop) {
      window.scrollTo(0, 0);
    }
  }, [props.cancelScrollTop]);

  return <>{props.children}</>;
}

export default ScrollToTop;
