import { AvailableAnalytisColumns } from "redux/analytics/defaults";

import { CellProps } from "../../utils";
import BoostCell, { BoostCellVariant } from "../shared/BoostCell";

const WinRateCell = ({ row, fieldName }: CellProps) => (
  <BoostCell
    row={row}
    fieldName={fieldName}
    variant={BoostCellVariant.WinRate}
  />
);

export default WinRateCell;

// Helpers

export const isWinRateCell = ({ fieldName }: CellProps) => {
  return fieldName === AvailableAnalytisColumns.winRate;
};
