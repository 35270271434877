import _ from "lodash";
import { JSONSerializable } from "models/types";
import { defineMessages } from "react-intl";
import {
  snakeCaseNameForNearboundAccountField,
  snakeCaseWithDoubleUnderscore,
} from "screens/Frontoffice/shared/helpers/snakeCase";
import { FilterValue } from "services/types";

import { FieldType, FilterType } from "./base";
import { MatchFilterType } from "./enums";

const matches = (value: FilterValue) => (recordValue: any) =>
  _.isEqual(value, recordValue);
const iContains = (value: FilterValue) => {
  const needle = typeof value === "string" ? value.toLowerCase() : "";
  return (recordValue: any) =>
    (recordValue ?? "").toLowerCase().includes(needle);
};
const greaterThan = (value: FilterValue) => (recordValue: any) =>
  value === null || recordValue > value;
const lowerThan = (value: FilterValue) => (recordValue: any) =>
  value !== null && recordValue < value;
const asArray = (value: FilterValue) => (_.isArray(value) ? value : [value]);

const i18n = defineMessages({
  is: {
    id: "models.MatchFilter.is",
    defaultMessage: "is",
  },
  isNot: {
    id: "models.MatchFilter.isNot",
    defaultMessage: "is not",
  },
  isAnyOf: {
    id: "models.MatchFilter.isAnyOf",
    defaultMessage: "is any of",
  },
  isNotAnyOf: {
    id: "models.MatchFilter.isNotAnyOf",
    defaultMessage: "is not any of",
  },
  containsAny: {
    id: "models.MatchFilter.containsAny",
    defaultMessage: "is any of",
  },
  containsExact: {
    id: "models.MatchFilter.isExact",
    defaultMessage: "is all of",
  },
  containsAtLeast: {
    id: "models.MatchFilter.hasAtLeast",
    defaultMessage: "contains all of",
  },
  contains: {
    id: "models.MatchFilter.contains",
    defaultMessage: "contains",
  },
  doesNotContain: {
    id: "models.MatchFilter.doesNotContain",
    defaultMessage: "does not contain",
  },
});

export const TYPE_LABELS = {
  [MatchFilterType.IS]: i18n.is,
  [MatchFilterType.IS_NOT]: i18n.isNot,
  [MatchFilterType.ANY_OF]: i18n.isAnyOf,
  [MatchFilterType.NONE_OF]: i18n.isNotAnyOf,
  [MatchFilterType.CONTAINS_ANY]: i18n.containsAny,
  [MatchFilterType.CONTAINS_EXACT]: i18n.containsExact,
  [MatchFilterType.CONTAINS_AT_LEAST]: i18n.containsAtLeast,
  [MatchFilterType.CONTAINS]: i18n.contains,
  [MatchFilterType.DOES_NOT_CONTAIN]: i18n.doesNotContain,
  [MatchFilterType.GT]: ">",
  [MatchFilterType.LT]: "<",
  [MatchFilterType.GTE]: "≥",
  [MatchFilterType.LTE]: "≤",
  [MatchFilterType._NO_OPERATOR]: "",
  [MatchFilterType._IN_OPERATOR]: "", // TODO REMOVE
};

class MatchFilter implements FilterType {
  fieldname: string;
  type: MatchFilterType = MatchFilterType.IS;
  value: FilterValue;

  constructor(
    fieldname: string,
    value: FilterValue,
    type: MatchFilterType = MatchFilterType.IS
  ) {
    this.fieldname = fieldname;
    this.type = type;
    this.value = value;
  }

  apiName(path?: string[]) {
    const snakeName = path
      ? snakeCaseNameForNearboundAccountField(this.fieldname, path)
      : snakeCaseWithDoubleUnderscore(this.fieldname);
    if (this.type === MatchFilterType._NO_OPERATOR) {
      return snakeName;
    }
    return `${snakeName}.${this.type}`;
  }

  matcher(): (value: any) => boolean {
    switch (this.type) {
      case MatchFilterType.ANY_OF:
        return _.overSome(asArray(this.value).map(matches));
      case MatchFilterType.NONE_OF:
        return _.negate(_.overSome(asArray(this.value).map(matches)));
      case MatchFilterType.CONTAINS:
        return iContains(this.value);
      case MatchFilterType.DOES_NOT_CONTAIN:
        return _.negate(iContains(this.value));
      case MatchFilterType.GT:
        return greaterThan(this.value);
      case MatchFilterType.LT:
        return lowerThan(this.value);
      case MatchFilterType.GTE:
        return _.negate(lowerThan(this.value));
      case MatchFilterType.LTE:
        return _.negate(greaterThan(this.value));
      case MatchFilterType.IS_NOT:
        return _.negate(matches(this.value));
      default:
        return matches(this.value);
    }
  }

  static fromList(raw: FilterType[]) {
    return _.map(
      raw,
      ({ fieldname, type, value }: FilterType) =>
        new MatchFilter(fieldname, value, type)
    );
  }

  static forAPI(
    raw: FilterType[],
    fields?: {
      [fieldname: string]: FieldType;
    }
  ) {
    const filters = MatchFilter.fromList(raw.filter(Boolean));
    return _.transform(
      filters,
      (result: { [key: string]: JSONSerializable }, filter: MatchFilter) =>
        (result[
          filter.apiName(fields?.[filter.fieldname]?.jsonPath)
        ] = _.isArray(filter.value)
          ? filter.value.join(",")
          : _.isBoolean(filter.value)
          ? filter.value.toString()
          : filter.value),
      {}
    );
  }

  static applyAll(records: { [key: string]: any }, raw: FilterType[]) {
    const filters = MatchFilter.fromList(raw);
    return _.filter(
      records,
      _.conforms(
        _.transform(
          filters,
          (result, filter: MatchFilter) =>
            (result[filter.fieldname] = filter.matcher()),
          {} as { [filtername: string]: (value: any) => boolean }
        )
      )
    );
  }
}

export default MatchFilter;
