import { Box, Divider, Grid } from "@mui/material";
import { Calendar, Trash } from "components/icons";
import Button from "components/ui/Button";
import { DatePicker } from "components/ui/DatePicker";
import Tooltip from "components/ui/Tooltip";
import { T } from "components/ui/Typography";
import generic from "i18n/generic";
import { makeStyles } from "makeStyles";
import { useCallback, useState } from "react";
import { defineMessages, FormattedMessage } from "react-intl";

export const DropdownFooter = ({
  isDisabled,
  onClear,
  onSave,
  value,
}: {
  onSave: (attributionDate: string | null) => void;
  onClear: () => void;
  value?: string | null;
  isDisabled?: boolean;
}) => {
  const { classes } = useStyles();
  const [attributionDate, setAttributionDate] = useState<string | null>(
    value ? null : new Date().toISOString()
  );
  const datePickerChild = useCallback(
    (onClick) => {
      const label =
        value ?? attributionDate ? (
          new Date(attributionDate ?? value ?? "").toLocaleDateString()
        ) : (
          <T className={classes.alpha}>
            <FormattedMessage {...generic.select} />
          </T>
        );
      const handleClick = (e: MouseEvent) => {
        e.stopPropagation();
        e.preventDefault();
        onClick(e);
      };
      return (
        <Grid container alignItems="center" gap="8px">
          <Grid item>
            <T bold>
              <FormattedMessage {...i18n.date} />
            </T>
          </Grid>
          <Grid item flexGrow={1}>
            <Button
              label={label}
              onClick={handleClick}
              variant="septenary"
              size="small"
              ellipsis
              classes={{ btn: classes.btn, content: classes.labelContent }}
              RightIcon={Calendar}
            />
          </Grid>
        </Grid>
      );
    },
    [classes, value, attributionDate]
  );

  const handleSave = (e: MouseEvent) => {
    e.stopPropagation();
    document.body.click(); // closes the dropdown
    onSave(attributionDate ?? value ?? null);
  };

  const handleAttributionDateChange = (date: Date | null) => {
    setAttributionDate(date?.toISOString() ?? null);
  };

  const handleClear = (e: MouseEvent) => {
    e.stopPropagation();
    document.body.click(); // closes the dropdown
    onClear();
  };

  return (
    <Grid container direction="column">
      <Grid item>
        <DatePicker
          onChange={handleAttributionDateChange}
          value={
            value ?? attributionDate
              ? new Date(attributionDate ?? value ?? "")
              : undefined
          }
        >
          {datePickerChild}
        </DatePicker>
      </Grid>
      <Divider className={classes.divider} />
      <Grid
        item
        container
        justifyContent="space-between"
        gap="4px"
        flexGrow={1}
      >
        <Button
          label={i18n.clear}
          LeftIcon={Trash}
          onClick={handleClear}
          size="small"
          variant="tertiary"
        />
        <Tooltip
          title={<FormattedMessage {...i18n.applyDisabledTooltip} />}
          hidden={!isDisabled}
        >
          <Box>
            <Button
              label={i18n.apply}
              onClick={handleSave}
              size="small"
              disabled={isDisabled}
            />
          </Box>
        </Tooltip>
      </Grid>
    </Grid>
  );
};

const useStyles = makeStyles()((theme) => ({
  alpha: {
    color: theme.palette.alpha500,
  },
  btn: {
    borderRadius: "18px !important",
  },
  divider: {
    margin: "8px -12px",
    borderBottom: `1px solid ${theme.palette.taupe500}`,
  },
  labelContent: {
    display: "flex",
    justifyContent: "space-between",
    width: "100%",
  },
}));

const i18n = defineMessages({
  apply: {
    id: "CellRenderer.Pipeline.KindCell.DropdownFooter.apply",
    defaultMessage: "Apply",
  },
  applyDisabledTooltip: {
    id: "CellRenderer.Pipeline.KindCell.DropdownFooter.applyDisabledTooltip",
    defaultMessage: "Select Attribution type to apply",
  },
  clear: {
    id: "CellRenderer.Pipeline.KindCell.DropdownFooter.clear",
    defaultMessage: "Clear",
  },
  date: {
    id: "CellRenderer.Pipeline.KindCell.DropdownFooter.date",
    defaultMessage: "Date:",
  },
});
