import { createSvgIcon } from "@mui/material/utils";

import { SvgIcon } from "./types";

const displayName = "Copy";

const Copy: SvgIcon = createSvgIcon(
  // Using transform to enlarge from provided viewbox="0 0 16 16"
  // to default "0 0 24 24" from `createSvgIcon` -> `SvgIcon`.
  <g fill="none" transform="matrix(1.5,0,0,1.5,0,0)">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.4444 3.55556H13.3333C14.8061 3.55556 16 4.74946 16 6.22222V13.3333C16 14.8061 14.8061 16 13.3333 16H6.22222C4.74946 16 3.55556 14.8061 3.55556 13.3333V12.4444H2.66667C1.19391 12.4444 0 11.2505 0 9.77778V2.66667C0 1.19391 1.19391 0 2.66667 0H9.77778C11.2505 0 12.4444 1.19391 12.4444 2.66667V3.55556ZM9.77778 1.33333H2.66667C1.93029 1.33333 1.33333 1.93029 1.33333 2.66667V9.77778C1.33333 10.5142 1.93029 11.1111 2.66667 11.1111H3.55556V6.22222C3.55556 4.74946 4.74946 3.55556 6.22222 3.55556H11.1111V2.66667C11.1111 1.93029 10.5142 1.33333 9.77778 1.33333ZM4.88889 6.22222C4.88889 5.48584 5.48584 4.88889 6.22222 4.88889H13.3333C14.0697 4.88889 14.6667 5.48584 14.6667 6.22222V13.3333C14.6667 14.0697 14.0697 14.6667 13.3333 14.6667H6.22222C5.48584 14.6667 4.88889 14.0697 4.88889 13.3333V6.22222Z"
      fill="currentColor"
    />
  </g>,
  displayName
);

Copy.displayName = displayName;
export default Copy;
