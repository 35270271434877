import { Box, Grid } from "@mui/material";
import PageLoader from "components/ui/PageLoader";
import Popup from "components/ui/Popup";
import { T } from "components/ui/Typography";
import { DataShare } from "models/Partnership";
import { defineMessages, FormattedMessage } from "react-intl";
import { InviteClarification } from "screens/Frontoffice/shared/components/InviteForm/components/InviteClarification";
import { InviteMessagePreview } from "screens/Frontoffice/shared/components/InviteForm/components/InviteMessagePreview";
import {
  useInviteModal,
  useStyles,
} from "screens/Frontoffice/shared/components/InviteForm/hooks/useInviteModal";

import { InvitationSuccess } from "./InvitationSuccess";

type Props = {
  partnershipId: string;
  onClose?: () => void;
  isFromGetStarted?: boolean;
};

export const InvitationReviewModal = ({
  partnershipId,
  onClose,
  isFromGetStarted,
}: Props) => {
  const {
    avatar,
    closeButton,
    handleClose,
    invitationSharingSettingsContent,
    inviteButton,
    isAccepted,
    partnership,
    selectedCompany,
    dataSharingStatus,
  } = useInviteModal({ partnershipId, onClose, isFromGetStarted });
  const { classes, cx } = useStyles();

  const invite =
    !!partnership?.invites?.length &&
    partnership.invites[partnership.invites.length - 1];
  const invitedBy = invite?.createdByUser?.fullName;
  const message = invite?.message;

  const content = (
    <Grid item xs={12}>
      <Grid container className={classes.container}>
        {closeButton}
        {selectedCompany && (
          <Grid
            item
            xs={12}
            className={cx(
              classes.titleContainer,
              classes.titleNarrow,
              classes.marginTopSmall
            )}
          >
            <T h2 bold>
              <FormattedMessage
                {...i18n.invitedBy}
                values={{
                  companyName: selectedCompany?.name ?? "unknown",
                  userName: invitedBy,
                }}
              />
            </T>
          </Grid>
        )}
        {selectedCompany && (
          <Grid item xs={12}>
            <Box className={classes.rowItem}>
              <InviteMessagePreview partnership={partnership} />
            </Box>
          </Grid>
        )}
        <Grid item xs={12}>
          {!!selectedCompany?.domain || !!selectedCompany?.websiteUrl ? (
            <Box
              className={cx(
                classes.rowItem,
                classes.marginTopSmall,
                classes.inviteClarificationWithBorder
              )}
            >
              <InviteClarification
                unfolded={!message}
                hideDescription
                avatar={avatar}
                selectedCompany={selectedCompany}
              />
            </Box>
          ) : !selectedCompany ? (
            <PageLoader />
          ) : (
            <></>
          )}
        </Grid>
        {invitationSharingSettingsContent}
        <Grid item xs={12}>
          {inviteButton}
        </Grid>
      </Grid>
    </Grid>
  );

  return (
    <Popup
      isOpen={true}
      handleClose={handleClose}
      hideActions
      variant={isAccepted ? "tertiary" : "secondary"}
      width={isAccepted ? 700 : undefined}
      maxWidth={!isAccepted ? "md" : undefined}
    >
      <Grid container className={classes.container}>
        {isAccepted && !isFromGetStarted ? (
          <InvitationSuccess
            companyName={selectedCompany?.name ?? ""}
            isSharing={
              !!dataSharingStatus && dataSharingStatus !== DataShare.Nothing
            }
            onClose={handleClose}
            partnershipId={partnershipId}
          />
        ) : (
          content
        )}
      </Grid>
    </Popup>
  );
};

const i18n = defineMessages({
  invitedBy: {
    id: "InvitationReviewModal.invitedBy",
    defaultMessage:
      "You've been invited by {userName} from {companyName} to connect on Reveal",
  },
});
