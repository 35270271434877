import { ProviderType } from "config/constants";
import errorMessages from "i18n/errorMessages";
import CrmCredential from "models/CrmCredential";
import CrmField from "models/CrmField";
import Notification from "models/Notification";
import Partnership from "models/Partnership";
import Record from "models/Record";
import User from "models/User";

export const INVITATION_LIMIT_ERROR_MESSAGE =
  "Initiator company has reached the daily limit for invitations";

export enum ERROR_MESSAGE {
  ERROR_429 = "Request failed with status code 429",
}

export enum ERROR_CODE {
  SUGGESTION_ALREADY_EXISTS = "ERR_SUGGESTION_ALREADY_EXISTS_CODE",
  SUGGESTION_PARTNERSHIP_EXISTS = "ERR_SUGGESTION_PARTNERSHIP_EXISTS_CODE",
  INVITE_PARTNERSHIP_EXISTS = "ERR_INVITE_PARTNERSHIP_EXISTS",
  INVITE_PENDING_CURRENT_EMAIL = "ERR_INVITE_PENDING_PARTNERSHIP_CURRENT_EMAIL",
  INVITE_PENDING_OTHER_EMAIL = "ERR_INVITE_PENDING_PARTNERSHIP_OTHER_EMAIL",
  EXTERNAL_KEY_ALREADY_EXISTS = "ERR_EXTERNAL_KEY_ALREADY_EXISTS",
}

export const DESTINATION_COMPANY_NOT_FOUND_ERROR_MESSAGE =
  "Destination company not found";
export const INVITE_ALREADY_SENT_IN_24_HOURS =
  "Invites already sent in the last 24 hours";

export const ERROR_CODE_TO_ERROR_TRANSLATION = {
  [ERROR_CODE.SUGGESTION_ALREADY_EXISTS]:
    errorMessages.suggestion_already_exists_error,
  [ERROR_CODE.SUGGESTION_PARTNERSHIP_EXISTS]:
    errorMessages.suggestion_partnership_exists_error,
  [ERROR_CODE.INVITE_PARTNERSHIP_EXISTS]:
    errorMessages.invite_partnership_exists_error,
  [ERROR_CODE.INVITE_PENDING_CURRENT_EMAIL]:
    errorMessages.invite_pending_current_email,
  [ERROR_CODE.INVITE_PENDING_OTHER_EMAIL]:
    errorMessages.invite_pending_other_email,
  [ERROR_CODE.EXTERNAL_KEY_ALREADY_EXISTS]:
    errorMessages.external_key_already_exists_error,
};

export enum APIActions {
  /**
   * Standard actions
   */
  bulkUpdateAttributesOnRecord = "api/bulkUpdateAttributesOnRecord",
  updateAttributesOnRecord = "api/updateAttributesOnRecord",
  updateRelationshipsOnRecord = "api/updateRelationshipsOnRecord",
  /**
   * CRUD actions
   */
  addRelated = "api/addRelated",
  create = "api/create",
  destroy = "api/destroy",
  update = "api/update",
  bulkUpdate = "api/bulkUpdate",
  removeRelated = "api/removeRelated",
  bulkRemoveRecords = "api/bulkRemoveRecords",
  bulkAddRecords = "api/bulkAddRecords",
  index = "api/index",
  indexAll = "api/indexAll",
  retreive = "api/retreive",
  fetchRelated = "api/fetchRelated",
  /**
   * Raw actions
   */
  rawGet = "api/rawGet",
  rawPost = "api/rawPost",
  rawPatch = "api/rawPatch",
  rawDelete = "api/rawDelete",
  rawPut = "api/rawPut",
  attachToRecord = "api/attachToRecord",
  /**
   * Custom actions
   */
  fetchPartnerAndOpportunityPresence = "api/fetchPartnerAndOpportunityPresence",
  clearPartnershipCrmFields = "api/clearPartnershipCrmFields",
}

type CompanyId = number;

export type PartnerAndOpportunityPresence = {
  partnerPresence: CompanyId[];
  opportunityPresence: CompanyId[];
};

export interface PartnershipCrmField extends Record<"partnership_crm_fields"> {
  crmFieldLabel: string;
  partnership: Partnership;
  crmField: CrmField;
  displayIndex: number | null;
  integrationProvider: ProviderType;
}

export interface APICollection<T extends Record = Record> {
  [resourceId: string]: T;
}

export interface APIEntities {
  crm_credentials?: APICollection<CrmCredential>;
  crm_fields?: APICollection<CrmField>;
  users?: APICollection<User>;
  partnership_crm_fields?: APICollection<PartnershipCrmField>;
  notifications?: APICollection<Notification>;
  [resourceName: string]: APICollection | undefined;
}

export interface APIState {
  entities: APIEntities;
}

export interface RootStateWithAPI {
  api: APIState;
}
