import history from "_history";
import Alert from "@mui/material/Alert";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import generic from "i18n/generic";
import { FormattedMessage } from "react-intl";

type Props = {
  callBackUrl: string;
};

const UnauthorizedInfoDialog = ({ callBackUrl }: Props) => {
  const callback = () =>
    history.replace({
      pathname: callBackUrl,
    });

  return (
    <Dialog open onClose={callback}>
      <DialogTitle />
      <DialogContent>
        <Alert severity="warning">
          <FormattedMessage {...generic.not_authorized} />
        </Alert>
      </DialogContent>
      <DialogActions>
        <Button variant="contained" color="primary" onClick={callback}>
          <FormattedMessage {...generic.dismiss} />
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default UnauthorizedInfoDialog;
