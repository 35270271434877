import { SavedFilterType } from "models/SavedFilter";
import { AccountMappingResource } from "redux/accountMapping/types";

export const enum ThreeSixty {
  threeSixty = "360",
  nearboundProspects = "nearbound_prospects",
  nearboundAccounts = "nearbound_accounts",
}

export type ExtendedAccountMappingResource =
  | AccountMappingResource
  | ThreeSixty;

export const SAVED_FILTER_TYPE_TO_DISPLAY_LABEL = {
  [SavedFilterType.AccountMapping]: {
    defaultMessage: "OVERLAPS",
    id: "smartView.helpers.accountMapping",
  },
  [SavedFilterType.LeadOverlaps]: {
    defaultMessage: "LEAD MAPPING",
    id: "smartView.helpers.leadOverlaps",
  },
  [SavedFilterType.NewProspects]: {
    defaultMessage: "NEW PROSPECTS",
    id: "smartView.helpers.newProspects",
  },
  [SavedFilterType.MyEcosystem]: undefined,
  [SavedFilterType.NearboundProspects]: undefined,
  [SavedFilterType.NearboundAccounts]: undefined,
  [SavedFilterType.NewAccountMapping]: {
    defaultMessage: "OVERLAPS",
    id: "smartView.helpers.accountMapping",
  },
};

export enum SmartViewSegmentEvent {
  ViewsButtonClicked = "Views button clicked",
  SaveViewClicked = "Save view clicked",
  SavedView = "Saved view",
}
