import Grid from "@mui/material/Grid";
import useUserProfile from "hooks/useUserProfile";
import { makeStyles } from "makeStyles";
import Integration from "models/Integration";
import { ReactNode } from "react";
import { matchPath, useLocation } from "react-router";
import AccountMappingInfo from "screens/Frontoffice/screens/DataTables/screens/AccountMapping/components/AccountMappingInfo";
import SelectSourceButton from "screens/Frontoffice/screens/DataTables/shared/components/SelectSourceButton";
import { DataTableType } from "screens/Frontoffice/screens/DataTables/shared/types";

export type ActionHeaderRowProps = {
  columnWidget: ReactNode;
  filterWidget: ReactNode;
  rightSideActionsWidget?: ReactNode;
  sortWidget: ReactNode;
  quickViews: ReactNode;
  actions?: ReactNode;
  extraSettings?: ReactNode;
  title?: ReactNode;
  availableSources?: Integration[];
  selectedSourceId?: number | null;
  setSelectedSourceId?: (value: number | null) => void;
  viewType?: DataTableType;
};

const ActionHeaderRow = ({
  actions,
  columnWidget,
  filterWidget,
  sortWidget,
  quickViews,
  title,
  extraSettings,
  selectedSourceId,
  setSelectedSourceId = () => {},
  availableSources = [],
}: ActionHeaderRowProps) => {
  const { profile } = useUserProfile();
  const location = useLocation();

  const isFromAccountMapping = !!matchPath(
    location.pathname,
    "/partnerships/:recordId/account-mapping"
  );
  const isFrom360Goals = location.pathname.startsWith("/crm-accounts");

  const { classes, cx } = useStyles({ isFrom360Goals });

  return (
    <Grid className={classes.root} direction="column">
      <Grid item className={classes.row}>
        <div id="grid-title">{title}</div>
        <div
          id="grid-settings"
          className={cx(classes.settings, classes.gridSettings)}
        >
          <div id="extra-settings" className={classes.settings}>
            {extraSettings}
          </div>
          <div id="base-settings" className={classes.settings}>
            <SelectSourceButton
              selectedSourceId={selectedSourceId ?? null}
              setSelectedSourceId={setSelectedSourceId}
              availableSources={availableSources}
            />
            {columnWidget}
            {filterWidget}
            {sortWidget}
          </div>
        </div>
      </Grid>
      <Grid item className={classes.row}>
        <div id="grid-action" className={classes.gridActions}>
          {actions}
        </div>
        <div id="grid-quickviews">{quickViews}</div>
      </Grid>
      {isFromAccountMapping && profile.canManageSources && (
        <AccountMappingInfo />
      )}
    </Grid>
  );
};

export default ActionHeaderRow;

/// CSS

const useStyles = makeStyles<{ isFrom360Goals: boolean }>()(
  (theme, { isFrom360Goals }) => ({
    root: {
      margin: theme.spacing(2.5),
      [theme.breakpoints.down("lg")]: {
        margin: theme.spacing(1),
      },
    },
    row: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      marginBottom: isFrom360Goals ? 0 : 12,
    },
    gridSettings: {
      [theme.breakpoints.down("lg")]: {
        flexDirection: "column",
        alignItems: "end",
      },
    },
    settings: {
      display: "flex",
      alignItems: "center",
      gap: theme.spacing(0.5),
      [theme.breakpoints.down("lg")]: {
        gap: 0,
      },
    },
    gridActions: {
      display: "flex",
      gap: theme.spacing(0.75),
      marginRight: theme.spacing(1),
    },
  })
);
