import { makeStyles } from "makeStyles";
import { SVGProps } from "react";

const useStyles = makeStyles()((theme) => ({
  root: {
    width: "1em",
    height: "1em",
  },
}));

export type ProgressPieProps = {
  progress: number;
  className?: string;
} & SVGProps<SVGElement>;

const radius = 8.5;
const size = 24;
const stroke = 2;

const ProgressPie = ({
  className,
  progress,
  ref,
  ...props
}: ProgressPieProps) => {
  const { classes, cx } = useStyles();
  const x = size / 2 + radius * Math.cos((progress - 0.25) * 2 * Math.PI);
  const y = size / 2 + radius * Math.sin((progress - 0.25) * 2 * Math.PI);
  const largeArcFlag = progress < 0.5 ? 0 : 1;
  return (
    <svg
      className={cx(classes.root, className)}
      viewBox={`0 0 ${size} ${size}`}
      {...props}
    >
      {progress >= 1 ? (
        <circle cx={size / 2} cy={size / 2} r={radius} fill="currentColor" />
      ) : (
        <path
          d={`M ${size / 2},${size / 2} L ${size / 2},${
            size / 2 - radius
          } A ${radius},${radius} 0 ${largeArcFlag},1 ${x},${y} Z`}
          fill="currentColor"
        />
      )}
      <circle
        cx={size / 2}
        cy={size / 2}
        r={size / 2 - stroke / 2}
        stroke="currentColor"
        strokeWidth={stroke}
        fill="none"
      />
    </svg>
  );
};

export default ProgressPie;
