import CircularProgress from "@mui/material/CircularProgress";
import { NotificationStatus } from "components/ui/Notifications/NotificationSnackbar";
import Popup from "components/ui/Popup";
import usePushNotification from "hooks/usePushNotification";
import _ from "lodash";
import { ExportStatus } from "models/CrmExport";
import { useEffect, useState } from "react";
import { defineMessages } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { markNotificationAsRead } from "redux/notifications/actions";
import { selectUnreadNotifications } from "redux/notifications/selectors";
import {
  NotificationActionType,
  NotificationItem,
} from "redux/notifications/typing";
import { FilterType } from "screens/Frontoffice/screens/DataTables/shared/types";

import useMappingFields, {
  SupportedResources,
} from "../hooks/useMappingFields";
import ExportCrmDialog from "./ExportCrmDialog";

export type MappedRow = {
  reveal_value: string;
  crm_value: string;
};

export type FieldRow = {
  reveal_field: string;
  crm_field: string;
  default_value: boolean;
  required: boolean;
  value_mapping: MappedRow[];
};

export type Values = {
  fields: FieldRow[];
};

export type CrmExportInfo = {
  id: number;
  status: ExportStatus;
  link: string;
  exportCompleted: boolean;
  exportedRecordCount: number;
  failedRecordCount: number;
};

type Props = {
  resource: SupportedResources;
  partnershipId: number;
  partnerName: string;
  onClose: () => void;
  filters: FilterType[];
  integrationId: number;
  displayPreviousNotification?: () => void;
};

const ExportCrmDialogManager = ({
  resource,
  partnershipId,
  partnerName,
  onClose,
  filters = [],
  integrationId,
  displayPreviousNotification = () => {},
}: Props) => {
  const dispatch = useDispatch();
  const pushNotification = usePushNotification();
  const unreadNotifications = useSelector(selectUnreadNotifications);

  const {
    totalCount,
    partnerIntegrationsData,
    requiredCrmFieldsLoading,
    requiredCrmFields,
    optionalCrmFieldsLoading,
    optionalCrmFields,
  } = useMappingFields(resource, integrationId, partnershipId, filters);
  const hasMultiplePartnerIntegrations = partnerIntegrationsData.length > 1;

  const [
    currentIntegrationIndex,
    setCurrentIntegrationIndex,
  ] = useState<number>(0);
  const [crmExportsInfo, setCrmExportsInfo] = useState<{
    [integrationIndex: number]: CrmExportInfo;
  } | null>(null);

  const saveCrmExportInfo = (index: number, crmExport: CrmExportInfo) => {
    const _crmExportsInfo = { ...crmExportsInfo };
    _crmExportsInfo[index] = crmExport;
    setCrmExportsInfo(_crmExportsInfo);
  };

  const displayAllNotificationsAndClose = () => {
    // Display the previous notification if defined
    displayPreviousNotification();
    // Display crmExport notifications
    if (crmExportsInfo !== null) {
      Object.keys(crmExportsInfo).forEach((integrationIndex) => {
        displayNotification(
          +integrationIndex,
          crmExportsInfo[+integrationIndex]
        );
      });
    }

    // Close the dialog after the last step
    onClose();
    setCurrentIntegrationIndex(0);
  };

  const validateStep = async (crmExport: CrmExportInfo | null) => {
    if (crmExport !== null) {
      await saveCrmExportInfo(currentIntegrationIndex, crmExport);
    } else if (!hasMultiplePartnerIntegrations) {
      // When there is only one integration and the user skipped the step
      displayAllNotificationsAndClose();
    } else {
      // When there are several integrations and the user skipped the step
      if (currentIntegrationIndex + 1 < partnerIntegrationsData.length) {
        // if we aren't at the last step, we just dipslay the next one
        setCurrentIntegrationIndex(currentIntegrationIndex + 1);
      } else {
        // If we are the last step (that was skipped)
        displayAllNotificationsAndClose();
      }
    }
  };

  // Fonction to display the notification regarding
  // the informations we retreived for the crmExport
  const displayNotification = (index: number, crmExportInfo: CrmExportInfo) => {
    let status = NotificationStatus.loading;
    let label = i18n.exportingNotificationTitle;
    let description = i18n.exportingNotificationDescription;
    let linkLabel = i18n.monitorJob;

    if (
      crmExportInfo.status === ExportStatus.exportFailed ||
      (crmExportInfo.status === ExportStatus.successful &&
        crmExportInfo.exportedRecordCount === 0 &&
        crmExportInfo.failedRecordCount > 0)
    ) {
      status = NotificationStatus.warning;
      label = i18n.exportFailedTitle;
      description = i18n.exportFailedDescription;
      if (crmExportInfo.status === ExportStatus.exportFailed) {
        crmExportInfo.failedRecordCount =
          partnerIntegrationsData[index].recordCount;
      }
      linkLabel = i18n.viewExport;
    } else if (
      crmExportInfo.status === ExportStatus.successful ||
      (crmExportInfo.status === ExportStatus.running &&
        crmExportInfo.exportCompleted)
    ) {
      status = NotificationStatus.success;
      label = i18n.exportCompletedTitle;
      description =
        crmExportInfo.failedRecordCount > 0
          ? i18n.exportUncompletedDescription
          : i18n.exportCompletedDescription;
      linkLabel = i18n.viewExport;
    }

    const hasLink = crmExportInfo.link !== "";

    // Trigger new notification
    pushNotification(
      {
        status,
        message: label,
        description: description,
      },
      {
        number: partnerIntegrationsData[index].recordCount,
        numberComplete: crmExportInfo.exportedRecordCount,
        numberFail: crmExportInfo.failedRecordCount,
        partnerName,
      },
      hasLink
        ? {
            type: NotificationActionType.linkActions,
            label: linkLabel,
            link: crmExportInfo.link as string,
          }
        : undefined
    );
  };

  const updateCrmInfo = (index: number, crmInfo: CrmExportInfo) => {
    if (
      hasMultiplePartnerIntegrations &&
      currentIntegrationIndex + 1 < partnerIntegrationsData.length
    ) {
      // If we have multiple integrations and we aren't at the last step yet,
      // we store the info to display all the notifications at the end
      saveCrmExportInfo(index, crmInfo);
    } else {
      if (hasMultiplePartnerIntegrations) {
        // This case is triggered only when we are at the last step and the crmExport info is updated,
        // it means that we have a new version of the notification.
        // We need to mark as read the previous notifications
        _.each(unreadNotifications, (unreadNotification: NotificationItem) => {
          if (unreadNotification) {
            dispatch(markNotificationAsRead(unreadNotification.id));
          }
        });
      } else {
        // This case is triggered when the user validated the only step
        // We need to display the previous notification if defined
        displayPreviousNotification();
      }
      displayNotification(index, crmInfo);
    }
  };

  useEffect(() => {
    if (
      (crmExportsInfo !== null && !hasMultiplePartnerIntegrations) ||
      currentIntegrationIndex + 1 === partnerIntegrationsData.length
    ) {
      displayAllNotificationsAndClose();
    }
  }, [crmExportsInfo]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (totalCount === 0) {
      displayAllNotificationsAndClose();
    }
  }, [totalCount]); // eslint-disable-line react-hooks/exhaustive-deps

  if (totalCount === null)
    return (
      <Popup isOpen hideActions width="auto">
        <CircularProgress size={25} data-testid="export-crm-manager-loader" />
      </Popup>
    );

  return (
    <>
      {totalCount !== null &&
        partnerIntegrationsData.length > 0 &&
        partnerIntegrationsData.map((partnerIntegrationData, i) => (
          <ExportCrmDialog
            isOpen={currentIntegrationIndex === i}
            callback={validateStep}
            updateCrmInfo={(crmInfo: CrmExportInfo) =>
              updateCrmInfo(i, crmInfo)
            }
            {...{
              partnershipId,
              partnerName,
              resource,
              totalCount,
              partnerIntegrationData,
              requiredCrmFieldsLoading,
              requiredCrmFields,
              optionalCrmFieldsLoading,
              optionalCrmFields,
              filters,
              integrationId,
              hasMultiplePartnerIntegrations,
            }}
          />
        ))}
    </>
  );
};

export default ExportCrmDialogManager;

/// I18N

const i18n = defineMessages({
  exportingNotificationTitle: {
    id: "navbar.ExportOptions.ExportCrmDialog.exportingNotificationTitle",
    defaultMessage:
      "Exporting {number, plural, one {1 account} other {# accounts}} to Salesforce",
  },
  exportingNotificationDescription: {
    id: "navbar.ExportOptions.ExportCrmDialog.exportingNotificationDescription",
    defaultMessage:
      "You will be notified by email when the export is complete. ",
  },
  exportCompletedTitle: {
    id: "navbar.ExportOptions.ExportCrmDialog.exportCompletedTitle",
    defaultMessage: "Your export to Salesforce has completed",
  },
  exportCompletedDescription: {
    id: "navbar.ExportOptions.ExportCrmDialog.exportCompletedDescription",
    defaultMessage:
      "{number, plural, one {1 account} other {# accounts}} from {partnerName} have been successfully created in your Salesforce.",
  },
  exportUncompletedDescription: {
    id: "navbar.ExportOptions.ExportCrmDialog.exportUncompletedDescription",
    defaultMessage:
      "{numberComplete, plural, one {1 account} other {# accounts}} have been successfully created in your Salesforce.<br />{numberFail, plural, one {1 account} other {# accounts}} couldn’t be created in your Salesforce. Please contact our team.",
  },
  exportFailedTitle: {
    id: "navbar.ExportOptions.ExportCrmDialog.exportFailedTitle",
    defaultMessage: "Your export to Salesforce has failed",
  },
  exportFailedDescription: {
    id: "navbar.ExportOptions.ExportCrmDialog.exportFailedDescription",
    defaultMessage:
      "{numberFail, plural, one {1 account} other {# accounts}} couldn’t be created in your Salesforce. Please contact our team.",
  },
  monitorJob: {
    id: "navbar.ExportOptions.ExportCrmDialog.monitorJob",
    defaultMessage: "Monitor job in Salesforce",
  },
  viewExport: {
    id: "navbar.ExportOptions.ExportCrmDialog.viewExport",
    defaultMessage: "View export in Salesforce",
  },
});
