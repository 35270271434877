import { webhooksApiHost } from "config/constants";
import Match from "models/Match";
import PartnerConnection from "models/PartnerConnection";
import Partnership from "models/Partnership";
import Record from "models/Record";
import User from "models/User";
import moment from "moment";

import { Kind } from "../models/PartnerConnection";
import BaseHttpService from "./BaseHTTPService";

export enum WebhookEventName {
  RevealCopiedAEEmailFromAccountMapping = "reveal_copied_ae_email_from_account_mapping",
  RevealCopiedAEEmailFromNewProspects = "reveal_copied_ae_email_from_new_prospects",
  RevealCopiedAEEmailFromAccountDetailDrawer = "reveal_copied_ae_email_from_account_detail_drawer",
  RevealSearchAccountToOpenDetailDrawer = "reveal_search_account_to_open_detail_drawer",
  RevealOpenedDetailDrawerFrom360Mapping = "reveal_opened_detail_drawer_from_360_mapping",
  RevealCopiedContentFromAccountMapping = "reveal_copied_content_from_account_mapping",
  RevealCopiedContentFrom360Mapping = "reveal_copied_content_from_360_mapping",
  RevealCopiedContentFromNewProspects = "reveal_copied_content_from_new_prospects",
  RevealClickedSendSlackToAccountOwner = "reveal_clicked_send_slack_to_account_owner",
  RevealConfirmedSentSlackToAccountOwner = "reveal_confirmed_sent_slack_to_account_owner",
  RevealOpenedDealRoom = "reveal_opened_deal_room",
  RevealMyTeamSentMessageInDealRoom = "reveal_my_team_sent_message_in_deal_room",
  RevealPartnerSentMessageInDealRoom = "reveal_partner_sent_message_in_deal_room",
  RevealOpenedMessageDrawer = "reveal_opened_message_drawer",
  RevealMyTeamSentMessageInConversation = "reveal_my_team_sent_message_in_drawer",
  RevealInternalMessageSentInConversation = "reveal_internal_message_sent_in_drawer",
  RevealPartnerSentMessageInConversation = "reveal_partner_sent_message_in_drawer",
  RevealAddedCollaborateAttributionType = "reveal_added_collaborate_attribution_type",
}

export type WebhookTrack = {
  event_name: WebhookEventName;
};

export type WebhookPayload = {
  event_name: WebhookEventName;
  timestamp: string;
  base_data: {
    user_id: number;
    company_id: number;
    partnership_id: number | null;
    partner_company_id: number | null;
    integration_id: number | null;
  };
  reveal_data: {
    account_provider_key: string | null;
    raw_company_id: number | null;
    raw_opportunity_id: number | null;
    match_id: number | null;
    partner_connection_id: number | null;
    partner_connection_kind: Kind | null;
  };
};

export type TrackingPayload = {
  profile: User;
  eventName: WebhookEventName;
  partnership?: Partnership | null;
  crmAccount?: Record<"crm_accounts"> | null;
  match?: Match | null;
  partnerConnection?: PartnerConnection | null;
  partnerConnectionId?: number | null;
  partnerConnectionKind?: Kind | null;
  nearboundProspect?: Record<"nearbound_prospects"> | null;
  rawCompanyId?: number | null;
  rawCompanyProviderKey?: string | null;
  companyId?: number | null;
  partnerCompanyId?: number | null;
  partnershipId?: number | null;
};

export const WEBHOOK_TRACKING_TIMESTAMP_FORMAT = "YYYY-MM-DD HH:mm:ss.SSS";

export class WebhookService extends BaseHttpService {
  constructor() {
    super(`${webhooksApiHost}/incoming/analytics/reveal-app/`);
  }

  track(options: TrackingPayload) {
    const {
      profile,
      eventName,
      partnership,
      crmAccount,
      match,
      partnerConnection,
      partnerConnectionId,
      partnerConnectionKind,
      nearboundProspect,
      rawCompanyId,
      rawCompanyProviderKey,
      companyId,
      partnerCompanyId,
      partnershipId,
    } = options;

    const trackingCompanyId = companyId ?? profile?.company?.id;
    const partnerId = [
      partnership?.initiatorCompany.id,
      partnership?.destCompany?.id,
    ].find((item) => item !== trackingCompanyId);

    const baseData = {
      company_id: trackingCompanyId,
      user_id: profile.id,
      partnership_id: partnershipId ?? partnership?.id ?? null,
      partner_company_id: partnerCompanyId ?? partnerId ?? null,
      integration_id: partnership?.integration?.id ?? null,
    };

    const revealData = {
      account_provider_key:
        rawCompanyProviderKey ??
        match?.rawCompanyProviderKey ??
        crmAccount?.providerKey ??
        null,
      match_id: match?.id ?? null,
      raw_company_id:
        rawCompanyId ?? match?.rawCompanyId ?? crmAccount?.id ?? null,
      raw_opportunity_id: undefined ?? null,
      partner_connection_id:
        partnerConnectionId ?? partnerConnection?.id ?? null,
      partner_connection_kind:
        partnerConnectionKind ?? partnerConnection?.kind ?? null,
      ...(nearboundProspect && {
        nearbound_prospect_id: nearboundProspect.id,
        smart_domain: nearboundProspect.smartDomainKey,
        country: nearboundProspect.countryName,
      }),
    };

    const requestPayload: WebhookPayload = {
      event_name: eventName,
      base_data: baseData,
      reveal_data: revealData,
      timestamp: moment().utc().format(WEBHOOK_TRACKING_TIMESTAMP_FORMAT),
    };

    return this.authPost("", requestPayload, {});
  }
}
