import { Dialog } from "@mui/material";
import NotifyOwnerSlackModal from "components/ui/data-grid/cellRenderers/pipeline/SlackBtn/NotifyOwnerSlackModal";
import { AccountData } from "components/ui/data-grid/cellRenderers/pipeline/SlackBtn/types";
import { T } from "components/ui/Typography";
import * as Page from "config/routes/Pages";
import useSegment from "hooks/useSegment";
import useUserProfile from "hooks/useUserProfile";
import generic from "i18n/generic";
import _ from "lodash";
import { makeStyles } from "makeStyles";
import { PayingFeature } from "models/CompanyPayingFeatureSubscription";
import Partnership from "models/Partnership";
import Record from "models/Record";
import { MouseEvent, useCallback, useState } from "react";
import { defineMessages, FormattedMessage } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation } from "react-router-dom";
import { selectActivePayingFeatures } from "redux/api/selectors";
import {
  openWidget,
  toggleFree,
  togglePower,
  togglePro,
} from "redux/premiumPlanWidget/actions";
import { WebhookEventName, WebhookService } from "services/WebhookService";
import { PremiumPlanEvent } from "tracking";

type Props = {
  account:
    | Record<"nearbound_prospects" | "crm_accounts" | "nearbound_accounts">
    | null
    | AccountData;
  activeSlack?: boolean;
  partnership?: Partnership;
};

export const useSlackButton = ({
  activeSlack,
  account,
  partnership,
}: Props) => {
  const dispatch = useDispatch();
  const { profile } = useUserProfile();
  const [modalOpen, setModalOpen] = useState(false);
  const payingFeatures = useSelector(selectActivePayingFeatures);
  const canManageSlack = profile.canManageSlack;
  const { track } = useSegment();
  const location = useLocation();
  const { key, search, ...locationState } = location;
  const page = Page.fromLocation(locationState);
  const service = new WebhookService();
  const isNearboundAccount = (account as Record)?.type === "nearbound_accounts";
  const rawCompanyId = isNearboundAccount
    ? (account as Record)?.rawCompanyId
    : account?.id;

  const hasSlackIntegration = payingFeatures.includes(
    PayingFeature.SlackIntegration
  );

  // Slack is active if the feature is not limited and is set up
  // Demo partner always has Slack
  const slackIsActive = Boolean(hasSlackIntegration && activeSlack);

  const { classes } = useStyles();
  let isDemo = false;
  let hasContent = true;

  if (page === Page.Enum.AccountMapping) {
    isDemo = ("" + account?.id).startsWith("demo");
    hasContent = Boolean(_.get(account, "name", null));
  }

  const handleOpenModal = (event: MouseEvent) => {
    if (!account) {
      return;
    }
    if (slackIsActive) {
      service.track({
        profile: profile,
        eventName: WebhookEventName.RevealClickedSendSlackToAccountOwner,
        partnership: partnership,
        rawCompanyId: Number(rawCompanyId),
        rawCompanyProviderKey:
          (account as Record).providerKey || account.rawCompanyProviderKey,
      });
      setModalOpen(true);
    }
    event.preventDefault();
    event.stopPropagation();
  };
  const handleCloseModal = () => {
    setModalOpen(false);
  };

  const openPremiumWidget = useCallback(() => {
    dispatch(toggleFree(false));
    dispatch(togglePro(true));
    dispatch(togglePower(false));
    dispatch(openWidget(true));
    track(PremiumPlanEvent.explorePlan, {
      from: "notify on slack tooltip",
    });
  }, [dispatch, track]);

  const setupTooltip = (
    <T>
      <FormattedMessage {...i18n.setupSlack} />
      &nbsp;
      <Link to={"/integrations"} className={classes.link} target="_blank">
        <FormattedMessage {...i18n.here} />
      </Link>
    </T>
  );

  const isLimitedTooltip = (
    <T>
      <FormattedMessage {...i18n.limitReached} />
      &nbsp;
      <span className={classes.tooltipLink} onClick={() => openPremiumWidget()}>
        <FormattedMessage {...generic.explorePlans} />
      </span>
    </T>
  );

  const dialog = account ? (
    <Dialog
      open={modalOpen}
      fullWidth={true}
      maxWidth="md"
      onClose={handleCloseModal}
    >
      <NotifyOwnerSlackModal
        account={{
          name: (account as Record)?.leftName || account?.name,
          starred:
            (account as Record)?.isStarred ||
            (account as Record)?.leftStarred ||
            account?.starred,
          status: (account as Record)?.leftStatus ?? account?.status,
          ownerName:
            (account as Record)?.ownerFullName ??
            account.ownerName ??
            (account as Record)?.leftOwnerName,
          rawCompanyProviderKey:
            (account as Record)?.providerKey ?? account?.rawCompanyProviderKey,
          partnershipId: account?.partnershipId,
          id: (account as Record)?.rawCompanyId ?? account.id,
        }}
        handleClose={handleCloseModal}
        isDemo={isDemo}
        partnership={partnership}
      />
    </Dialog>
  ) : (
    <></>
  );

  return {
    tooltipTitle: slackIsActive ? (
      ""
    ) : !hasSlackIntegration ? (
      isLimitedTooltip
    ) : canManageSlack ? (
      setupTooltip
    ) : (
      <FormattedMessage
        {...i18n.askSetupSlack}
        values={{ companyName: profile.companyName }}
      />
    ),
    modalOpen,
    handleOpenModal,
    slackIsActive,
    hasContent,
    dialog,
  };
};

// I18N

const i18n = defineMessages({
  setupSlack: {
    id: "useSlackButton.setupSlack",
    defaultMessage: "You can setup the Slack integration",
  },
  here: {
    id: "useSlackButton.here",
    defaultMessage: "here",
  },
  askSetupSlack: {
    id: "useSlackButton.askSetupSlack",
    defaultMessage:
      "Please ask an Admin to setup the Slack integration for {companyName}.",
  },
  limitReached: {
    id: "useSlackButton.limitReached",
    defaultMessage: "Upgrade to use our Slack integration.",
  },
});

// CSS

const useStyles = makeStyles()((theme) => ({
  link: {
    color: theme.palette.white,
    fontWeight: 700,
    "&:hover": {
      color: theme.palette.white,
    },
  },
  tooltipLink: {
    cursor: "pointer",
    textDecoration: "underline",
  },
}));
