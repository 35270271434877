import { ChatBubbleOutlineRounded } from "@mui/icons-material";
import { T } from "components/ui/Typography";
import { makeStyles } from "makeStyles";
import { useEffect } from "react";
import { defineMessages, FormattedMessage } from "react-intl";
import { handleSetManualLink } from "screens/Frontoffice/shared/helpers/intercomChat";

import { sharedStyles } from "./BaseSidePanel";

type Props = { hasIconLeft?: boolean; isWhite?: boolean };

export const ChatLink = ({ hasIconLeft = false, isWhite = false }: Props) => {
  const { cx, classes } = sharedStyles();
  const { classes: ownClasses } = useStyles();

  const icon = (
    <ChatBubbleOutlineRounded
      className={cx(classes.icon, { [ownClasses.midnightColor]: !isWhite })}
    />
  );

  useEffect(() => {
    handleSetManualLink();
  }, []);

  return (
    <div
      className={cx(classes.link, { [ownClasses.midnightLink]: !isWhite })}
      id="custom-intercom-link"
    >
      <div className={cx(classes.row, { [ownClasses.gap]: !isWhite })}>
        {hasIconLeft && icon}
        <T
          className={cx(classes.linkText, {
            [ownClasses.midnightLink]: !isWhite,
          })}
        >
          <FormattedMessage {...i18n.contactUs} />
        </T>
        {!hasIconLeft && icon}
      </div>
    </div>
  );
};

const useStyles = makeStyles()((theme) => ({
  gap: {
    gap: 6,
  },
  midnightColor: {
    color: theme.palette.midnight,
  },
  midnightLink: {
    color: theme.palette.midnight,
    fontWeight: 400,
    textDecoration: "none",
    "&:hover": {
      textDecoration: "underline",
      textDecorationColor: "inherit",
    },
  },
}));

const i18n = defineMessages({
  contactUs: {
    id: "crm.settings.ChatLink.contactUs",
    defaultMessage: "Contact Us",
  },
});
