import { Plus } from "components/icons";
import Button from "components/ui/Button";
import { defineMessages } from "react-intl";
import { useHistory } from "react-router";

const InviteNewPartnerTile = () => {
  const history = useHistory();

  return (
    <Button
      label={i18n.inviteNewPartner}
      LeftIcon={Plus}
      onClick={() => history.push("/invite")}
    />
  );
};

export default InviteNewPartnerTile;

/// I18N

export const i18n = defineMessages({
  inviteNewPartner: {
    id: "Partners.InviteNewPartnerTile.inviteNewPartner",
    defaultMessage: "Invite new partner",
  },
});
