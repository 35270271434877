import User from "models/User";
import { APICollection } from "redux/api/typing";

export interface OnboardingState {
  notAcceptedReady: boolean;
  notAcceptedPartnershipIds: number[];
  notSharedReady: boolean;
  notSharedPartnershipIds: number[];
}

export interface RootStateWithOnboarding {
  onboarding: OnboardingState;
  api: {
    entities: {
      partnerships?: APICollection;
    };
  };
  user: {
    profile: {
      data: User;
    };
  };
}

export enum OnboardingActions {
  initializePartnersToAccept = "onboarding/initializePartnersToAccept",
  initializePartnersToShare = "onboarding/initializePartnersToShare",
}
