import { Avatar, AvatarGroup, Grid } from "@mui/material";
import { T } from "components/ui/Typography";
import { crmProviders, ProviderType } from "config/constants";
import getFirstValueFromURL from "helpers/getFirstValueFromURL";
import { defineMessages, FormattedMessage } from "react-intl";
import { useHistory, useLocation } from "react-router-dom";
import {
  isCrmFileOAuthProviderType,
  isCrmProviderType,
} from "screens/Frontoffice/shared/helpers/types";

import { sharedStyles } from "./BaseContent";
import { CrmAuthProviderSelectButtons } from "./CrmAuthProviderSelectButtons";

export const CrmSelectButtons = () => {
  const { cx, classes } = sharedStyles({});
  const history = useHistory();
  const location = useLocation();
  const provider = getFirstValueFromURL(location, "provider");

  const handleClick = (provider: string) => () => {
    history.push({
      ...location,
      search: `init=true&provider=${provider}`,
    });
  };
  return provider === "all" ? (
    <CrmAuthProviderSelectButtons />
  ) : (
    <Grid container gap={2}>
      <Grid item xs={12} className={classes.crmButtonRow}>
        <div
          className={cx(classes.crmButton, classes.crmButtonWide)}
          onClick={handleClick("crm")}
        >
          <AvatarGroup max={99} variant="rounded">
            {Object.values(ProviderType)
              .filter(
                (item) =>
                  isCrmProviderType(item) && !isCrmFileOAuthProviderType(item)
              )
              .map((provider) => (
                <Avatar
                  alt={crmProviders[provider].name}
                  className={classes.avatar}
                  key={`provider-avatar-${provider}`}
                  src={crmProviders[provider].logo}
                />
              ))}
          </AvatarGroup>
          <T h3>
            <FormattedMessage {...i18n.crm} />
          </T>
        </div>
      </Grid>
      <Grid item xs={12}>
        <Grid container className={classes.crmButtonRow} gap={2}>
          <Grid item>
            <div
              className={classes.crmButton}
              onClick={handleClick(ProviderType.gsheet)}
            >
              <Avatar
                alt={crmProviders.gsheet.name}
                className={classes.avatar}
                src={crmProviders.gsheet.logo}
                variant="rounded"
              />
              <T h3>
                <FormattedMessage {...i18n.gsheets} />
              </T>
            </div>
          </Grid>
          <Grid item>
            <div
              className={classes.crmButton}
              onClick={handleClick(ProviderType.file)}
            >
              <Avatar
                alt={crmProviders.file.name}
                className={classes.avatar}
                src={crmProviders.file.logo}
                variant="rounded"
              />
              <T h3>
                <FormattedMessage {...i18n.csv} />
              </T>
            </div>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

const i18n = defineMessages({
  gsheets: {
    id: "CrmSelectButtons.gsheets",
    defaultMessage: "Google Sheets",
  },
  crm: {
    id: "CrmSelectButtons.crm",
    defaultMessage: "CRM",
  },
  csv: {
    id: "CrmSelectButtons.csv",
    defaultMessage: "CSV File",
  },
});
