import { PushPin, PushPinOutlined } from "@mui/icons-material";
import Button from "components/ui/Button";
import Dialog from "components/ui/Dialog";
import Tooltip from "components/ui/Tooltip";
import useUserProfile from "hooks/useUserProfile";
import { makeStyles } from "makeStyles";
import Integration from "models/Integration";
import Record from "models/Record";
import { useState } from "react";
import { defineMessages, FormattedMessage } from "react-intl";

import ToggleDefaultConfirm from "./ToggleDefaultConfirm";

type Props = {
  record: Record;
  integration: Integration;
  defaultCount?: number;
  refreshSources?: () => void;
};

const TileDefault = ({
  record,
  integration,
  defaultCount,
  refreshSources,
}: Props) => {
  const disabled = integration.defaultSource && defaultCount === 1;
  const { classes } = useStyles();
  const { profile } = useUserProfile();
  const [open, setOpen] = useState(false);

  const handleOpenModal = () => {
    if (!disabled) {
      setOpen(true);
    }
  };
  const handleCloseModal = (refresh?: boolean) => {
    setOpen(false);
    if (refresh) {
      refreshSources?.();
    }
  };

  if (profile.company.integrationCount <= 1) {
    return null;
  }

  return (
    <>
      <Tooltip
        title={
          <FormattedMessage
            {...(disabled
              ? i18n.cannotRemove
              : integration.defaultSource
              ? i18n.removeDefault
              : i18n.makeDefault)}
          />
        }
      >
        <div className={classes.flexItem}>
          <Button
            data-testid={
              integration.defaultSource ? "default-icon" : "not-default-icon"
            }
            LeftIcon={integration.defaultSource ? PushPin : PushPinOutlined}
            onClick={handleOpenModal}
            size="small"
            variant="quaternary"
          />
        </div>
      </Tooltip>
      <Dialog
        isOpen={open}
        title={
          integration.defaultSource
            ? i18n.removeDefaultTitle
            : i18n.makeDefaultTitle
        }
        maxWidth="md"
        onClose={handleCloseModal}
      >
        <ToggleDefaultConfirm
          record={record}
          integration={integration}
          onClose={handleCloseModal}
        />
      </Dialog>
    </>
  );
};

export default TileDefault;

// CSS

export const useStyles = makeStyles()((theme) => ({
  flexItem: {
    display: "flex",
  },
}));

// I18N

const i18n = defineMessages({
  makeDefault: {
    id: "Settings.DefaultSource.TileDefault.makeDefault",
    defaultMessage: "Make default",
  },
  removeDefault: {
    id: "Settings.DefaultSource.TileDefault.removeDefault",
    defaultMessage: "Remove default",
  },
  makeDefaultTitle: {
    id: "Settings.DefaultSource.TileDefault.makeDefaultTitle",
    defaultMessage:
      "Are you sure you want to make this source a default data source?",
  },
  removeDefaultTitle: {
    id: "Settings.DefaultSource.TileDefault.removeDefaultTitle",
    defaultMessage:
      "Are you sure you no longer want this source to be a default data source?",
  },
  cannotRemove: {
    id: "Settings.DefaultSource.TileDefault.cannotRemove",
    defaultMessage:
      "Reveal requires at least 1 default source. Please set another default source if you want to remove this one.",
  },
});
