export enum SharingLevel {
  None = 1,
  Limited = 2,
  Full = 3,
}

export enum DefinitionField {
  AccountName = "account_name",
  Status = "status",
  Stage = "stage",
  CloseDate = "close_date",
  Owner = "owner",
  OpportunityName = "opportunity_name",
  Amount = "amount",
}
