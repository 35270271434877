import { createSvgIcon } from "@mui/material/utils";

import { SvgIcon } from "./types";

const displayName = "Outbound";

const Outbound: SvgIcon = createSvgIcon(
  <g fill="none" transform="matrix(2,0,0,2,0,0)">
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M0.53125 5.5C0.53125 2.77267 2.6647 0.53125 5.33333 0.53125C6.21079 0.53125 7.03383 0.77516 7.74138 1.2002C7.9633 1.33351 8.03513 1.62148 7.90182 1.8434C7.76851 2.06532 7.48054 2.13715 7.25862 2.00384C6.69142 1.66311 6.03446 1.46875 5.33333 1.46875C3.2155 1.46875 1.46875 3.25677 1.46875 5.5C1.46875 7.74323 3.2155 9.53125 5.33333 9.53125C6.03446 9.53125 6.69142 9.33689 7.25862 8.99616C7.48054 8.86285 7.76851 8.93468 7.90182 9.1566C8.03513 9.37852 7.9633 9.66649 7.74138 9.7998C7.03383 10.2248 6.21079 10.4688 5.33333 10.4688C2.6647 10.4688 0.53125 8.22733 0.53125 5.5ZM8.66854 3.16854C8.8516 2.98549 9.1484 2.98549 9.33146 3.16854L11.3315 5.16854C11.5145 5.3516 11.5145 5.6484 11.3315 5.83146L9.33146 7.83146C9.1484 8.01451 8.8516 8.01451 8.66854 7.83146C8.48549 7.6484 8.48549 7.3516 8.66854 7.16854L9.86834 5.96875H4.5C4.24112 5.96875 4.03125 5.75888 4.03125 5.5C4.03125 5.24112 4.24112 5.03125 4.5 5.03125H9.86834L8.66854 3.83146C8.48549 3.6484 8.48549 3.3516 8.66854 3.16854Z"
      fill="currentColor"
    />
  </g>,
  displayName
);

Outbound.displayName = displayName;
export default Outbound;
