import { GetIntro } from "components/icons";
import Button from "components/ui/Button";
import Tooltip from "components/ui/Tooltip";
import useSegment from "hooks/useSegment";
import useUserProfile from "hooks/useUserProfile";
import { PayingFeature } from "models/CompanyPayingFeatureSubscription";
import { parse, stringify } from "query-string";
import { MouseEvent } from "react";
import { defineMessages, FormattedMessage } from "react-intl";
import { useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import { selectActivePayingFeatures } from "redux/api/selectors";
import { selectPartnershipGetIntroIds } from "redux/init/selectors";
import { Mapping360Event } from "tracking";

type Props = {
  accountId: number;
  partnershipId: number;
  isSecondary?: boolean;
};

export const GetIntroButton = ({
  accountId,
  partnershipId,
  isSecondary,
}: Props) => {
  const location = useLocation();
  const history = useHistory();
  const currentSearch = parse(location.search);
  const { track } = useSegment();
  const { profile } = useUserProfile();
  const partnershipGetIntroIds = useSelector(selectPartnershipGetIntroIds);
  const payingFeatures = useSelector(selectActivePayingFeatures);
  const isPaidUser = payingFeatures.includes(
    // We rely on this basic feature for now as we don't have any related to intro requests.
    PayingFeature.UnlimitedPipelineSharing
  );
  const disabled =
    !!partnershipGetIntroIds && !partnershipGetIntroIds.includes(partnershipId);

  const handleGetIntroClick = (event: MouseEvent<SVGSVGElement>) => {
    track(Mapping360Event.getIntroFrom360DrawerClicked);
    currentSearch["selected-intro"] = String(accountId);
    currentSearch["partner-intro"] = String(partnershipId);
    const { key, search, ...state } = location;
    history.replace({ ...state, search: stringify(currentSearch) });
  };

  if (!profile.canRequestIntro || !isPaidUser) return null;

  return (
    <Tooltip
      title={
        <FormattedMessage {...(disabled ? i18n.disabled : i18n.getIntro)} />
      }
      hidden={!disabled && !isSecondary}
      placement="top"
    >
      <div>
        <Button
          variant={isSecondary ? "tertiary" : "primary"}
          disabled={disabled}
          LeftIcon={GetIntro}
          label={isSecondary ? undefined : i18n.getIntro}
          onClick={handleGetIntroClick}
          size="small"
        />
      </div>
    </Tooltip>
  );
};

const i18n = defineMessages({
  getIntro: {
    id: "Mapping360.GetIntroButton.getIntro",
    defaultMessage: "Get Intro",
  },
  disabled: {
    id: "Mapping360.GetIntroButton.disabled",
    defaultMessage: "Get Intro is disabled for this partner",
  },
});
