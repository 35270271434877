import { isFulfilled } from "@reduxjs/toolkit";
import usePushNotification from "hooks/usePushNotification";
import useRecord from "hooks/useRecord";
import useSegment from "hooks/useSegment";
import IntroductionRequestForm from "models/IntroductionRequestForm";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { rawGet, rawPatch, retreive } from "redux/api/thunks";
import { JSONAPIListResponse, JSONAPIResponse } from "services/types";
import { GetIntroTemplateEvent } from "tracking";

export const useGetIntroTemplate = () => {
  const { track } = useSegment();
  const dispatch = useDispatch();
  const pushNotification = usePushNotification();

  const [loading, setLoading] = useState<boolean>(false);
  const [recordId, setRecordId] = useState<number | null>(null);
  const { record } = useRecord("introduction_request_forms", recordId, false);

  const getForm = async () => {
    const result = await dispatch(
      rawGet({ type: "introduction_request_forms", path: "" })
    );
    if (isFulfilled(result)) {
      const response = result.payload as JSONAPIListResponse;
      if (response.data?.length > 0) {
        setRecordId(+response.data[0].id);
      }
    } else {
      pushNotification("default_error");
    }
    setLoading(false);
  };

  const reloadIntroRequestForm = (id: number) => {
    dispatch(
      retreive({
        id,
        type: "introduction_request_forms",
      })
    );
  };

  const toggleEnabled = async () => {
    if (record) {
      const result = await dispatch(
        rawPatch({
          id: record.id,
          type: "introduction_request_forms",
          path: "",
          payload: {
            data: {
              id: record.id,
              type: "introduction_request_forms",
              attributes: {
                is_enabled: !record.isEnabled,
              },
            },
          },
        })
      );
      if (isFulfilled(result)) {
        const response = result.payload as JSONAPIResponse;
        const id = +response.data.id;
        reloadIntroRequestForm(id);
      } else {
        pushNotification("default_error");
      }
    }
  };

  useEffect(() => {
    track(GetIntroTemplateEvent.ViewGetIntroTemplate);
    getForm();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return {
    loading,
    introRequestForm: record as IntroductionRequestForm | null,
    toggleEnabled,
    setRecordId,
  };
};
