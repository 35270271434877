import { ClassNameMap } from "@mui/material/styles";
import { DragAndDrop } from "components/icons";
import { makeStyles } from "makeStyles";
import { ReactNode } from "react";
// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module 'reac... Remove this comment to see the full error message
import { Draggable } from "react-beautiful-dnd";
import { useMergedClasses } from "tss-react";

type Props = {
  children?: ReactNode;
  id: string;
  index: number;
  disabled?: boolean;
  isDropdown?: boolean;
  classes?: ClassNameMap;
  isLoading?: boolean;
  isHidden?: boolean;
};

const DraggableItem = (props: Props) => {
  const {
    children,
    id,
    index,
    disabled = false,
    isDropdown = false,
    classes: newClasses = {},
    isLoading,
    isHidden = false,
  } = props;
  const { classes: baseClasses, cx } = useStyles({ isHidden });
  const classes = useMergedClasses(baseClasses, newClasses);

  if (disabled) {
    return children;
  }
  if (isLoading) {
    // returning an undraggable element while loading (with missing props)
    return (
      <Draggable draggableId={id} index={index}>
        {(provided: $TSFixMe, snapshot: $TSFixMe) => (
          <div
            ref={provided.innerRef}
            {...provided.draggableProps}
            style={provided.draggableProps.style}
            className={cx(classes.root, {
              [classes.draggingItem]: snapshot.isDragging,
            })}
          >
            {!isDropdown && <DragAndDrop className={classes.dragIcon} />}
            {children}
          </div>
        )}
      </Draggable>
    );
  }

  return (
    <Draggable draggableId={id} index={index}>
      {(provided: $TSFixMe, snapshot: $TSFixMe) => (
        <div
          ref={provided.innerRef}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
          style={provided.draggableProps.style}
          className={cx(classes.root, {
            [classes.draggingItem]: snapshot.isDragging,
          })}
        >
          {!isDropdown && <DragAndDrop className={classes.dragIcon} />}
          {children}
        </div>
      )}
    </Draggable>
  );
};

export default DraggableItem;

// CSS

const useStyles = makeStyles<{ isHidden: boolean }>()(
  (theme, { isHidden }) => ({
    root: {
      display: isHidden ? "none" : "flex",
      alignItems: "center",
    },
    dragIcon: {
      fontSize: 16,
      marginRight: 6,
    },
    draggingItem: {
      backgroundColor: theme.palette.white,
      borderRadius: 3,
      padding: 0,
      border: `1px solid ${theme.palette.other.grid.lightBorder}`,
    },
  })
);
