import { useOktaAuth } from "@okta/okta-react";
import { Logout } from "components/icons";
import { makeStyles } from "makeStyles";
import { defineMessages, useIntl } from "react-intl";
import { useDispatch } from "react-redux";
import { logout } from "redux/user/thunks";

export type StyleProps = {
  expanded: boolean;
};

export type Props = Partial<StyleProps> & {
  openSidebar?: () => void;
};

const SidebarLogoutButton = ({
  expanded = true,
  openSidebar = () => {},
}: Props) => {
  const dispatch = useDispatch();
  const { authState, oktaAuth } = useOktaAuth();
  const { classes } = useStyles({
    expanded,
  });
  const intl = useIntl();

  const handleLogout = async () => {
    if (authState?.isAuthenticated) {
      try {
        await oktaAuth.signOut();
      } catch (e) {}
    }
    dispatch(logout({ goHome: true }));
  };

  return (
    <div className={classes.root} title={intl.formatMessage(i18n.logout)}>
      <span className={classes.link} onClick={handleLogout}>
        <Logout className={classes.icon} />
      </span>
    </div>
  );
};

export default SidebarLogoutButton;

/// CSS

const useStyles = makeStyles<StyleProps>()((theme, props) => ({
  root: {
    display: "flex",
    alignItems: "center",
    width: props.expanded ? 160 : 36,
    height: 36,
  },
  icon: {
    width: 18,
    height: 18,
    marginLeft: 9,
    color: theme.palette.greyscale550,
  },
  container: {
    display: "flex",
    alignItems: "center",
  },
  label: {
    marginLeft: 11,
  },
  link: {
    display: "flex",
    textDecoration: "none",
    color: theme.palette.offWhite,
    cursor: "pointer",
    "&:hover": {
      color: theme.palette.offWhite,
      textDecoration: "underline",
    },
  },
}));

const i18n = defineMessages({
  logout: {
    id: "SidebarLogoutButton.logout",
    defaultMessage: "Logout",
  },
});
