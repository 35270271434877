import Record from "models/Record";
import { generateRecords } from "redux/api/utils";
import {
  crmAccountsFields,
  crmAccountsIncluded,
} from "screens/Frontoffice/screens/DataTables/shared/utils";
import JSONAPIService from "services/JSONAPIService";
import { JSONAPIResponse } from "services/types";

export const loadNearboundProspect = async (
  companyId: number,
  countryIsoCode: string,
  smartDomainKey: string
): Promise<Record<"nearbound_prospects">> => {
  const service = new JSONAPIService("nearbound_prospects");
  const response = await service.rawGet<JSONAPIResponse<"nearbound_prospects">>(
    `/resolve?key=${companyId}-${countryIsoCode}-${smartDomainKey}`
  );
  const store = generateRecords(response.data);
  const record = (store.nearbound_prospects ?? {})[response.data.data.id];
  if (record) {
    return record as Record<"nearbound_prospects">;
  }
  throw new Error("Cannot fetch NearboundProspect");
};

export const loadNearboundAccount = async (
  id: number
): Promise<Record<"crm_accounts">> => {
  const service = new JSONAPIService("crm_accounts");
  const response = await service.get<JSONAPIResponse<"crm_accounts">>(id, {
    include: crmAccountsIncluded,
    fields: crmAccountsFields,
  });
  const store = generateRecords(response.data);
  const record = (store.crm_accounts ?? {})[response.data.data.id];
  if (record) {
    return record as Record<"crm_accounts">;
  }
  throw new Error("Cannot fetch CrmAccount");
};
