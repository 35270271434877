import PageForbidden from "components/ui/PageForbidden";
import { ComponentType } from "react";

import withUserProfile, { WithUserProfile } from "./withUserProfile";

/** Resolve permission when one of the provided permissions is enough. */
const resolveAnyPermission = (profile: $TSFixMe, permissions: $TSFixMe) =>
  permissions.some((perm: $TSFixMe) =>
    profile.hasPermissions([perm], profile.company)
  );

/** Resolved permissions when all permissions are required */
const resolveAllPermissions = (profile: $TSFixMe, permissions: $TSFixMe) =>
  profile.hasPermissions(permissions, profile.company);

type WithCompanyPermissionsOptions<FallbackProps> = {
  Fallback?: ComponentType<FallbackProps>;
  fallbackProps?: FallbackProps;
  resolveAny?: boolean;
};

function withCompanyPermissions<
  T extends WithUserProfile = WithUserProfile,
  FallbackProps = {}
>(
  permissions: string[],
  options: WithCompanyPermissionsOptions<FallbackProps> = {}
) {
  return (WrappedComponent: ComponentType<T>) => {
    const {
      Fallback = PageForbidden,
      fallbackProps = {} as FallbackProps,
      resolveAny = false,
    } = options;

    const revolver = resolveAny ? resolveAnyPermission : resolveAllPermissions;

    const Component = withUserProfile<T>(({ profile, ...props }: T) => {
      return revolver(profile, permissions) ? (
        <WrappedComponent {...({ profile, ...props } as T)} />
      ) : (
        <Fallback {...fallbackProps} />
      );
    });

    Component.displayName = `withPermissions(${
      WrappedComponent.displayName || "WrappedComponent"
    })`;

    return Component;
  };
}

export default withCompanyPermissions;
