import { East } from "@mui/icons-material";
import Button from "components/ui/Button";
import DialogManager from "components/ui/DialogManager";
import { Formik, FormikProps } from "formik";
import generic from "i18n/generic";

import {
  IGsheetForm,
  TAB_SELECTION_VALIDATION_SCHEMA,
  useGsheetTabSelectionDialogContent,
} from "../hooks/useGsheetTabSelectionDialogContent";

type Props = {
  ghostPartnershipId?: number;
  onClose?: () => void;
  open?: boolean;
};

export const GsheetTabSelectionDialog = ({ open, ...props }: Props) => {
  const {
    handleClose,
    handleSubmit,
    dialogContent,
    dialogTitle,
  } = useGsheetTabSelectionDialogContent(props);
  return (
    <Formik
      initialValues={{ tab: "", url: "" }}
      onSubmit={handleSubmit}
      validationSchema={TAB_SELECTION_VALIDATION_SCHEMA}
    >
      {(props: FormikProps<IGsheetForm>) => (
        <DialogManager
          fullScreenBreakpoint="sm"
          maxWidth="md"
          DialogActionsComponent={
            <Button
              label={generic.next}
              RightIcon={East}
              onClick={props.handleSubmit}
            />
          }
          DialogTitleComponent={dialogTitle}
          DialogContentComponent={dialogContent(props)}
          handleClose={handleClose(props)}
          isOpen={Boolean(open)}
        />
      )}
    </Formik>
  );
};

// I18N
