import { Grid } from "@mui/material";
import Button from "components/ui/Button";
import { T } from "components/ui/Typography";
import { makeStyles } from "makeStyles";
import { defineMessages, FormattedMessage } from "react-intl";

type Props = {
  handleConfirm: () => void;
  isLoading: boolean;
};

export const ConfirmIntroRequestContent = ({
  handleConfirm,
  isLoading,
}: Props) => {
  const { classes } = useStyles();

  return (
    <Grid
      container
      direction="column"
      alignContent="center"
      className={classes.root}
    >
      <Grid item className={classes.boxContainer}>
        <T h3 bold>
          <FormattedMessage {...i18n.title} />
        </T>
        <T className={classes.subtitle}>
          <FormattedMessage {...i18n.description} />
        </T>
        <div className={classes.btnContainer}>
          <Button
            label={i18n.getIntro}
            onClick={handleConfirm}
            loading={isLoading}
          />
        </div>
      </Grid>
    </Grid>
  );
};

// I18N

const i18n = defineMessages({
  title: {
    id: "addToPipelineFromWeeklyDigest.confirmContent.title",
    defaultMessage: "Get an Introduction to get help on your Account",
  },
  description: {
    id: "addToPipelineFromWeeklyDigest.confirmContent.description",
    defaultMessage:
      "Introductions increase by +25% your chances to win an account.",
  },
  getIntro: {
    id: "addToPipelineFromWeeklyDigest.confirmContent.getIntro",
    defaultMessage: "Get an intro",
  },
});

// CSS

const useStyles = makeStyles()((theme) => ({
  root: {
    maxWidth: 820,
    padding: "0px 100px",
  },
  boxContainer: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    background: theme.palette.ivory,
    borderRadius: 7,
    padding: theme.spacing(3),
    alignItems: "center",
  },
  subtitle: {
    paddingTop: 4,
    color: theme.palette.alpha500,
  },
  btnContainer: {
    paddingTop: 20,
  },
}));
