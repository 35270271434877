import { CloseRounded } from "@mui/icons-material";
import { Plus } from "components/icons";
import SWQLOperator from "models/SWQLOperator";
import { defineMessages } from "react-intl";

import Button from "../Button";
import { PathToNode, useSWQLContext } from "./SWQLContext";
import {
  isSWQLBooleanNode,
  isSWQLEmptyNode,
  isSWQLRuleNode,
  SWQLEmptyNode,
  SWQLRuleNode,
} from "./SWQLTypes";

type Props = {
  node: SWQLRuleNode | SWQLEmptyNode;
  pathToNode: PathToNode;
};

export const SWQLPlaceholder = ({ node, pathToNode }: Props) => {
  const { getField, getParentNode } = useSWQLContext();
  const currentField = isSWQLRuleNode(node) && getField(node.left.field_id);
  const parentNode = getParentNode(pathToNode);
  const isRoot = !Boolean(parentNode);
  const value = isSWQLRuleNode(node) && node.right.value;
  const displayOperationButtons =
    !value &&
    value !== 0 &&
    !isSWQLBooleanNode(node) &&
    !(
      !isSWQLEmptyNode(node) &&
      [SWQLOperator.TYPE_IS, SWQLOperator.TYPE_IS_NOT].includes(
        node.operation
      ) &&
      value === null
    );

  return (
    <>
      {!currentField && (
        <Button
          disabled
          LeftIcon={Plus}
          variant="tertiary"
          label={i18n.operatorPlaceholder}
          size="small"
        />
      )}
      {(!currentField || (isSWQLRuleNode(node) && !node.operation)) && (
        <Button
          disabled
          LeftIcon={Plus}
          variant="tertiary"
          label={i18n.valuesPlaceholder}
          size="small"
        />
      )}
      {displayOperationButtons && (
        <>
          <Button
            disabled
            variant="tertiary"
            label={i18n.andPlaceholder}
            size="small"
          />
          <Button
            disabled
            variant="tertiary"
            label={i18n.orPlaceholder}
            size="small"
          />
        </>
      )}
      {isRoot && !isSWQLRuleNode(node) && (
        <Button
          disabled
          LeftIcon={CloseRounded}
          variant="tertiary"
          size="small"
        />
      )}
    </>
  );
};

const i18n = defineMessages({
  andPlaceholder: {
    id: "SWQL.SWQLPlaceholder.andPlaceholder",
    defaultMessage: "+ AND",
  },
  operatorPlaceholder: {
    id: "SWQL.SWQLPlaceholder.operatorPlaceholder",
    defaultMessage: "Select operator",
  },
  orPlaceholder: {
    id: "SWQL.SWQLPlaceholder.orPlaceholder",
    defaultMessage: "+ OR",
  },
  valuesPlaceholder: {
    id: "SWQL.SWQLPlaceholder.valuesPlaceholder",
    defaultMessage: "Add values",
  },
});
