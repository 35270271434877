import Button from "components/ui/Button";
import {
  PasswordConstraints,
  passwordSchema,
} from "components/ui/PasswordConstraints";
import { TextInput } from "components/ui/TextInput";
import { T } from "components/ui/Typography";
import { Formik } from "formik";
import usePushNotification from "hooks/usePushNotification";
import { makeStyles } from "makeStyles";
import { FormattedMessage, useIntl } from "react-intl";
import UserService from "services/UserService";
import * as yup from "yup";

import i18n from "./i18n";

/** {{{ Formik Functions & Configs */

export const schema = yup.object().shape({
  password: passwordSchema, // pragma: allowlist secret
});

type Props = {
  token: string;
  onSuccess: $TSFixMeFunction;
};

/** }}} */

export const ResetPasswordUpdateForm = ({ token, onSuccess }: Props) => {
  const intl = useIntl();
  const { classes } = useStyles();
  const pushNotification = usePushNotification();

  const updatePwd = async ({ password }: $TSFixMe) => {
    try {
      const service = new UserService();
      await service.updatePassword(token, password);
      onSuccess();
    } catch (_anyError) {
      pushNotification("default_error");
    }
  };

  return (
    <Formik
      initialValues={{ password: "" }}
      onSubmit={updatePwd}
      validateOnMount
      validationSchema={schema}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        touched,
        values,
        isSubmitting,
      }) => (
        <form onSubmit={handleSubmit} className={classes.root}>
          <T h2 bold textAlign="center" className={classes.gutterBottom}>
            <FormattedMessage {...i18n.createNewPwd} />
          </T>
          <TextInput
            variant="tertiary"
            type="password"
            name="password"
            fullWidth
            placeholder={intl.formatMessage(i18n.newPwdPh)}
            onBlur={handleBlur}
            onChange={handleChange}
            disabled={isSubmitting}
            inputProps={{
              "data-testid": "reset-password-input",
            }}
            error={Boolean(errors.password && touched.password)}
          />
          <PasswordConstraints value={values.password} />
          <Button
            type="submit"
            label={i18n.changePwdBtn}
            disabled={isSubmitting}
            classes={{ btn: classes.fullWidth }}
          />
        </form>
      )}
    </Formik>
  );
};

export default ResetPasswordUpdateForm;

// CSS

const useStyles = makeStyles()((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    rowGap: "8px",
  },
  gutterBottom: {
    marginBottom: 25,
    [theme.breakpoints.down("md")]: {
      marginBottom: 16,
    },
  },
  fullWidth: {
    width: "100%",
  },
}));
