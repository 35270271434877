import { Grid } from "@mui/material";
import { lighten } from "@mui/material/styles";
import redirectIfLoggedIn from "components/hoc/redirectIfLoggedIn";
import { Check } from "components/icons";
import BaseLoginPage from "components/ui/BaseLoginPage";
import Button from "components/ui/Button";
import PageLoader from "components/ui/PageLoader";
import { T } from "components/ui/Typography";
import _ from "lodash";
import { makeStyles } from "makeStyles";
import { parse } from "query-string";
import { useEffect, useState } from "react";
import { FormattedMessage } from "react-intl";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { initStore } from "redux/init/thunks";
import UserService from "services/UserService";

import i18n from "./i18n";
import ResetPasswordInitForm from "./ResetPasswordInitForm";
import ResetPasswordUpdateForm from "./ResetPasswordUpdateForm";

const getTokenFromURL = () => {
  const parsed = parse(window.location.search).token;
  if (_.isArray(parsed)) {
    return parsed[0];
  }
  return parsed || "";
};

const STATE_INIT = 0;
const STATE_REQUESTED = 1;
const STATE_VERIFYING_TOKEN = 2;
const STATE_UPDATE = 3;
const STATE_INVALID_TOKEN = 5;

export const ResetPassword = () => {
  const token = getTokenFromURL();
  const [state, setState] = useState(
    token ? STATE_VERIFYING_TOKEN : STATE_INIT
  );
  const { classes } = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();

  const startApp = async () => {
    await dispatch(initStore());
    history.push("/");
  };

  useEffect(() => {
    if (state === STATE_VERIFYING_TOKEN) {
      const service = new UserService();
      service.isPasswordTokenValid(token).then((result) => {
        setState(result ? STATE_UPDATE : STATE_INVALID_TOKEN);
      });
    }
  }, [token, state]);

  let content = null;
  switch (state) {
    case STATE_INIT:
      content = (
        <ResetPasswordInitForm onSuccess={() => setState(STATE_REQUESTED)} />
      );
      break;
    case STATE_REQUESTED:
      content = <ResetPasswordTriggered />;
      break;
    case STATE_VERIFYING_TOKEN:
      content = <PageLoader />;
      break;
    case STATE_UPDATE:
      content = <ResetPasswordUpdateForm token={token} onSuccess={startApp} />;
      break;
    case STATE_INVALID_TOKEN:
      content = <ResetPasswordInvalidToken />;
      break;
    default:
      return null;
  }

  return (
    <BaseLoginPage>
      <Grid
        container
        direction="column"
        alignContent="center"
        className={classes.root}
      >
        {content}
      </Grid>
    </BaseLoginPage>
  );
};

const ResetPasswordInvalidToken = () => {
  const { classes } = useStyles();
  const history = useHistory();

  return (
    <>
      <T h2 bold textAlign="center">
        <FormattedMessage {...i18n.invalidToken} />
      </T>
      <Button
        label={i18n.sendNewLinkBtn}
        onClick={() => history.push("/password/reset")}
        classes={{ btn: classes.fullWidth }}
      />
    </>
  );
};

const ResetPasswordTriggered = () => {
  const { classes } = useStyles();

  return (
    <div className={classes.centerContainer}>
      <Check className={classes.icon} />
      <T h2 bold textAlign="center">
        <FormattedMessage {...i18n.instructionsEmailSent} />
      </T>
      <T textAlign="center">
        <FormattedMessage {...i18n.instructionsReceiveEmail} />
      </T>
    </div>
  );
};

export default redirectIfLoggedIn("/profile")(ResetPassword);

// CSS

const useStyles = makeStyles()((theme) => ({
  root: {
    width: 360,
    rowGap: "8px",
    [theme.breakpoints.down("md")]: {
      width: "100%",
    },
  },
  centerContainer: {
    display: "flex",
    rowGap: "8px",
    flexDirection: "column",
    alignItems: "center",
  },
  icon: {
    width: 48,
    height: 48,
    color: theme.palette.green600,
    padding: 13,
    borderRadius: "25px",
    backgroundColor: lighten(theme.palette.green600 as string, 0.8),
  },
  fullWidth: {
    width: "100%",
  },
}));
