import history from "_history";
import Add from "@mui/icons-material/Add";
import { Box } from "@mui/material";
import Alert from "components/ui/Alert";
import Button from "components/ui/Button";
import { goToPartnershipSettings } from "helpers/navigation";
import usePushNotification from "hooks/usePushNotification";
import useUserProfile from "hooks/useUserProfile";
import { makeStyles } from "makeStyles";
import Partnership from "models/Partnership";
import { useState } from "react";
import { defineMessages, FormattedMessage } from "react-intl";
import { useSelector } from "react-redux";
import { selectUpsidesData } from "redux/upsides/selectors";
import { selectHasSources } from "redux/user/selectors";
import {
  OverlapItem,
  OverlapItemType,
} from "screens/Frontoffice/screens/Partners/shared/components/OverlapItem";
import PartnershipStatusDialog, {
  DialogType,
} from "screens/Frontoffice/screens/Partners/shared/components/PartnershipStatusDialog";
import { getOverlapItemValues } from "screens/Frontoffice/screens/Partners/shared/utils";
import JSONAPIService from "services/JSONAPIService";

const PARTNER_NAME_MAX_LENGTH = 20;

type Props = {
  partnership: Partnership;
};

const PartnerTileAcceptedContent = ({ partnership }: Props) => {
  const { classes, cx } = useStyles();
  const pushNotification = usePushNotification();
  const { profile } = useUserProfile();
  const [modalOpen, modalSetOpen] = useState(false);
  const hasSources = useSelector(selectHasSources);
  const { status, data } = useSelector(selectUpsidesData);
  const isLoadingUpsides = !partnership.isDemo && status === "pending";

  const {
    marketOverlap,
    personaOverlap,
    winRate,
    revenuePotential,
  } = getOverlapItemValues(partnership, data[partnership.id]);

  const handleOpenModal = () => modalSetOpen(true);
  const handleCloseModal = () => modalSetOpen(false);

  const partnerName = partnership.getPartner(profile).name;
  const warnings = partnership.warnings(profile.company);

  const nudgePartnerForSource = async () => {
    try {
      const service = new JSONAPIService("partnerships");
      // @ts-expect-error ts-migrate(2554) FIXME: Expected 3-4 arguments, but got 2.
      await service.rawPost(partnership.id, "/nudge-partner-for-source/");
      pushNotification(i18n.nudgeSuccess);
    } catch (_) {
      pushNotification("default_error");
    }
  };

  const goToSettingsDataSources = () =>
    goToPartnershipSettings(partnership, history, "data-sources");

  if (!partnership.isDemo) {
    if (!hasSources) {
      return (
        <>
          <div className={classes.leftSide}>
            <Alert variant="warning" icon className={classes.partnerMessage}>
              <FormattedMessage
                {...(profile.canManageSources
                  ? i18n.selfNoSource
                  : i18n.adminConnectSource)}
              />
            </Alert>
          </div>
          {profile.canManageSources && (
            <div className={classes.rightSide}>
              <Button
                LeftIcon={Add}
                label={i18n.addDataSource}
                size="small"
                onClick={() =>
                  history.push("/sources?onboarding=true&init=true")
                }
              />
            </div>
          )}
        </>
      );
    }

    if (warnings.company.missingIntegration) {
      return (
        <>
          <Box className={cx(classes.leftSide, classes.centered)}>
            <Alert variant="warning" icon className={classes.partnerMessage}>
              <FormattedMessage
                {...i18n.needToShare}
                values={{ partner: partnerName }}
              />
            </Alert>
          </Box>
          {profile.canManagePartnerships && (
            <div className={classes.rightSide}>
              <Button
                LeftIcon={Add}
                label={i18n.shareDataSource}
                size="small"
                onClick={goToSettingsDataSources}
              />
            </div>
          )}
        </>
      );
    }

    if (warnings.partner.missingIntegration) {
      return (
        <>
          <div className={classes.leftSide}>
            <Alert variant="warning" icon className={classes.partnerMessage}>
              <FormattedMessage
                {...i18n.partnerNoSource}
                values={{
                  partner:
                    partnerName?.length >= PARTNER_NAME_MAX_LENGTH
                      ? "your partner"
                      : partnerName,
                }}
              />
            </Alert>
          </div>
          {profile.canManagePartnerships && (
            <div className={classes.rightSide}>
              <Button
                label={i18n.nudge}
                size="small"
                onClick={nudgePartnerForSource}
                variant="secondary"
              />
            </div>
          )}
        </>
      );
    }

    if (warnings.partner.missingGhostIntegration) {
      return (
        <>
          <div className={classes.leftSide}>
            <Alert variant="warning" icon className={classes.partnerMessage}>
              <FormattedMessage
                {...i18n.addGhostData}
                values={{
                  partner:
                    partnerName?.length >= PARTNER_NAME_MAX_LENGTH
                      ? "partner"
                      : partnerName,
                }}
              />
            </Alert>
          </div>
          {profile.canManagePartnerships && (
            <div className={classes.rightSide}>
              <Button
                LeftIcon={Add}
                label={i18n.uploadPartnerData}
                size="small"
                onClick={goToSettingsDataSources}
              />
            </div>
          )}
        </>
      );
    }

    if (warnings.company.paused) {
      return (
        <>
          <div className={classes.leftSide}>
            <Alert variant="warning" icon className={classes.partnerMessage}>
              <FormattedMessage {...i18n.partnershipPausedByCompany} />
            </Alert>
          </div>
          {profile.canManagePartnerships && (
            <div className={classes.rightSide}>
              <Button
                label={i18n.reactivate}
                size="small"
                onClick={handleOpenModal}
                variant="secondary"
              />
              <PartnershipStatusDialog
                isOpen={modalOpen}
                onClose={handleCloseModal}
                partnership={partnership}
                type={DialogType.Activate}
              />
            </div>
          )}
        </>
      );
    }

    if (warnings.partner.paused) {
      return (
        <div className={classes.leftSide}>
          <Alert variant="warning" icon className={classes.partnerMessage}>
            <FormattedMessage {...i18n.partnershipPausedByPartner} />
          </Alert>
        </div>
      );
    }

    if (!partnership.ready) {
      return (
        <div className={classes.leftSide}>
          <Alert variant="warning" icon className={classes.partnerMessage}>
            <FormattedMessage {...i18n.notReady} />
          </Alert>
        </div>
      );
    }
  }

  return (
    <Box className={cx(classes.leftSide, classes.centered)}>
      <OverlapItem
        partnership={partnership}
        value={marketOverlap}
        type={OverlapItemType.market}
      />
      <OverlapItem
        partnership={partnership}
        value={personaOverlap}
        type={OverlapItemType.persona}
        hasSeparativeDot
      />
      <OverlapItem
        partnership={partnership}
        value={winRate}
        valueInProgress={!(partnership.id in data)}
        type={OverlapItemType.winRate}
        loading={isLoadingUpsides}
        hasSeparativeDot
      />
      <OverlapItem
        partnership={partnership}
        value={revenuePotential}
        valueInProgress={!(partnership.id in data)}
        type={OverlapItemType.revenuePotential}
        loading={isLoadingUpsides}
        hasSeparativeDot
      />
    </Box>
  );
};

// CSS

const useStyles = makeStyles()((theme) => ({
  leftSide: { position: "absolute", bottom: 16, left: 16, height: "28px" },
  rightSide: {
    position: "absolute",
    bottom: 16,
    right: 16,
  },
  partnerMessage: {
    display: "-webkit-box",
    "-webkit-line-clamp": "2",
    "-webkit-box-orient": "vertical",
    wordBreak: "normal",
    overflow: "hidden",
    hyphens: "auto",
  },
  smallIcon: {
    width: 16,
    height: 16,
  },
  overlapsContainer: {
    columnGap: 6,
    marginTop: 13,
  },
  centered: {
    display: "flex",
    alignItems: "center",
  },
}));

export const i18n = defineMessages({
  notReady: {
    id: "partnerTile.notReady",
    defaultMessage:
      "You will receive an e-mail as soon as your data is ready and available.",
  },
  selfNoSource: {
    id: "partnerTile.selfNoSource",
    defaultMessage: "You need to connect a data source.",
  },
  addDataSource: {
    id: "partnerTile.addDataSource",
    defaultMessage: "Add data source",
  },
  shareDataSource: {
    id: "partnerTile.shareDataSource",
    defaultMessage: "Share a data source",
  },
  needToShare: {
    id: "partnerTile.needToShare",
    defaultMessage: "You need to share a data source with {partner}.",
  },
  partnerNoSource: {
    id: "partnerTile.partnerNoSource",
    defaultMessage:
      "You're connected to {partner} but they have no active data source yet.",
  },
  partnershipPausedByCompany: {
    id: "partnerTile.partnershipPausedByCompany",
    defaultMessage: "You have paused this partnership.",
  },
  addGhostData: {
    id: "partnerTile.addGhostData",
    defaultMessage:
      "You can upload {partner} data and generate an account mapping.",
  },
  uploadPartnerData: {
    id: "partnerTile.uploadPartnerData",
    defaultMessage: "Upload Partner Data",
  },
  partnershipPausedByPartner: {
    id: "partnerTile.partnershipPausedByPartner",
    defaultMessage: "This partnership has been paused by your partner.",
  },
  nudge: {
    id: "partnerTile.nudge",
    defaultMessage: "Nudge",
  },
  reactivate: {
    id: "partnerTile.unpause",
    defaultMessage: "Reactivate",
  },
  nudgeSuccess: {
    id: "partnerTile.nudgeSuccess",
    defaultMessage: "Your partner has been reminded to connect a data source",
  },
  adminConnectSource: {
    id: "partnerTile.adminConnectSource",
    defaultMessage:
      "An administrator needs to connect a data source to Reveal.",
  },
});

export default PartnerTileAcceptedContent;
