import { Avatar, Box } from "@mui/material";
import { EditRect, Sticker } from "components/icons";
import Button from "components/ui/Button";
import { NewTag } from "components/ui/NewTag";
import { T } from "components/ui/Typography";
import { isRowClickable } from "helpers/partnerConnectionUtils";
import useUserProfile from "hooks/useUserProfile";
import _ from "lodash";
import { makeStyles } from "makeStyles";
import Match from "models/Match";
import { MessageType } from "models/Message";
import PartnerConnection from "models/PartnerConnection";
import Record from "models/Record";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { AvailablePipelineColumns } from "redux/pipeline/defaults";
import { selectPerspective } from "redux/pipeline/selectors";
import { RevealStore } from "redux/typing";

import { CellProps } from "../../utils";

export const CommentCell = ({
  row,
  fieldName,
}: Omit<CellProps, "row"> & { row: PartnerConnection }) => {
  const { profile } = useUserProfile();
  const conversations: {
    [key: string]: Record;
  } = useSelector((state: RevealStore) =>
    _.get(state, "api.entities.conversations", {})
  );
  const perspective = useSelector(selectPerspective);
  const conversation = Object.values(conversations).find(
    (conversation) => conversation.partnerConnectionId === row.id
  );
  const participant = row.discussionParticipants?.find(
    (participant) => participant.id === conversation?.lastMessageParticipantId
  );
  const isSelfParticipant = row.discussionParticipants?.some(
    (participant) => participant.userId === profile.id
  );

  const isNewMessage =
    conversation?.lastAckAt &&
    conversation?.lastMessageSentAt &&
    new Date(conversation.lastAckAt) < new Date(conversation.lastMessageSentAt);
  const { classes } = useStyles({ isNewMessage });

  useEffect(() => {
    const rowElements = document.querySelectorAll(`[data-id="${row.id}"]`);

    for (const rowElement of rowElements) {
      const className = `highlighted-row-${
        perspective === Match.PERSPECTIVE_MINE ? "purple" : "green"
      }`;
      if (isNewMessage) {
        rowElement.classList.add(className);
      } else {
        rowElement.classList.remove(className);
      }
    }
  }, [row, isNewMessage, perspective]);

  if (!isRowClickable(row)) {
    return null;
  }

  return (
    <>
      <div className={classes.wrapper}>
        {conversation?.lastMessageContent ? (
          <>
            {participant && (
              <Avatar
                className={classes.avatar}
                title={participant.fullName ?? undefined}
                src={participant.avatarUrl ?? undefined}
              >
                {participant.fullName?.charAt(0).toUpperCase()}
              </Avatar>
            )}
            <T className={classes.message}>{conversation.lastMessageContent}</T>
            {[MessageType.internal, MessageType.internalMigrated].includes(
              conversation.lastMessageType
            ) && <Sticker className={classes.iconSmall} />}
            {isNewMessage && <NewTag color="primary" />}
          </>
        ) : (
          isSelfParticipant && (
            <Box className="showOnHover">
              <Button
                label={i18n.newMessage}
                LeftIcon={EditRect}
                size="xSmall"
              />
            </Box>
          )
        )}
      </div>
    </>
  );
};

export const isCommentCell = ({ fieldName }: CellProps) =>
  fieldName === AvailablePipelineColumns.externalComment;

export const useStyles = makeStyles<{ isNewMessage: boolean }>()(
  (theme, { isNewMessage }) => ({
    avatar: {
      fontSize: 11,
      fontWeight: 500,
      color: theme.palette.midnight,
      backgroundColor: theme.palette.greyscale200,
      border: `${isNewMessage ? "1px" : "0"} solid ${
        theme.palette.ivory
      } !important`,
      height: 18,
      width: 18,
    },
    wrapper: {
      position: "relative",
      width: "100%",
      height: "100%",
      display: "flex",
      alignItems: "center",
      cursor: "pointer",
      gap: 8,
    },
    message: {
      flex: 1,
      overflow: "hidden",
      whiteSpace: "nowrap",
      textOverflow: "ellipsis",
      color: isNewMessage ? theme.palette.midnight : theme.palette.alpha500,
    },
    backgroundCircle: {
      display: "flex",
      position: "absolute",
      right: 0,
      justifyContent: "space-around",
      alignItems: "center",
      borderRadius: "50%",
      backgroundColor: theme.palette.taupe,
      width: 26,
      height: 26,
    },
    icon: {
      width: 16,
      height: 16,
    },
    iconSmall: {
      width: 12,
      height: 12,
      ...(!isNewMessage && {
        opacity: 0.5,
      }),
    },
  })
);

const i18n = {
  newMessage: {
    id: "DataGrid.CommentCell.newMessage",
    defaultMessage: "New message",
  },
};
