import { isFulfilled, isRejected } from "@reduxjs/toolkit";
import { Factory } from "models";
import Match from "models/Match";
import PartnerConnection, {
  ISharedOpportunity,
  Status,
} from "models/PartnerConnection";
import RawOpportunity from "models/RawOpportunity";
import User from "models/User";
import { useCallback, useState } from "react";
import { defineMessages } from "react-intl";
import { useDispatch } from "react-redux";
import { updateAttributesOnRecord } from "redux/api/actions";
import { addRelated, fetchRelated, removeRelated } from "redux/api/thunks";
import { selectCurrentUser, selectPerspective } from "redux/pipeline/selectors";
import { PipelineEvent, PipelineTableEditAction } from "tracking";

import usePushNotification from "./usePushNotification";
import useSegment from "./useSegment";
import useSelectorWithDeepEquality from "./useSelectorWithDeepEquality";
import useUserProfile from "./useUserProfile";

export const useOpportunityDropdown = ({
  partnerConnection,
}: {
  partnerConnection: PartnerConnection;
}) => {
  const [availableOpports, setAvailableOpports] = useState<
    RawOpportunity[] | null
  >(null);
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const dispatch = useDispatch();
  const pushNotification = usePushNotification();
  const { track } = useSegment();
  const { profile } = useUserProfile();
  const currentUser = useSelectorWithDeepEquality(selectCurrentUser);
  const perspective = useSelectorWithDeepEquality(selectPerspective);
  const [isLoadingAvailableOpports, setIsLoadingAvailableOpports] = useState(
    false
  );

  const { allowEdit, isPartnerCell } = getValues({
    partnerConnection,
    currentUser,
    profile,
    isPartnerView: perspective === Match.PERSPECTIVE_THEIRS,
  });

  const fetchAvailableRawOpp = useCallback(async () => {
    const result = await dispatch(
      fetchRelated({
        type: "partner_connections",
        id: partnerConnection.id,
        relation: "available-raw-opportunities/",
      })
    );

    if (isFulfilled(result)) {
      const response = result.payload;
      const opports: RawOpportunity[] = (response as any).data.map(
        (value: $TSFixMe) => Factory.createRecord(value)
      );
      setAvailableOpports(opports);
    }
  }, [dispatch, partnerConnection.id]);

  const handleOpen = async (event: React.MouseEvent<HTMLButtonElement>) => {
    setIsLoadingAvailableOpports(true);
    event.stopPropagation();
    event.preventDefault();
    setAnchorEl(event.currentTarget);
    if (availableOpports === null) {
      await fetchAvailableRawOpp();
    }
    setIsLoadingAvailableOpports(false);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const onUnlink = useCallback(
    async (opportunityId: string) => {
      const opport = partnerConnection.sharedOpportunities?.find(
        (opportunity) => opportunity.id === Number(opportunityId)
      );
      if (!opport) {
        return;
      }
      setAvailableOpports((value) =>
        value
          ? [
              ...value,
              new RawOpportunity({
                id: String(opport.id),
                attributes: {
                  close_date: opport.close_date,
                  amount_in_dollars: opport.amount,
                  is_open: opport.status === Status.Open,
                  is_won: opport.status === Status.Won,
                  name: opport.opportunity_name,
                },
                type: "raw_opportunities",
              }),
            ]
          : null
      );
    },
    [partnerConnection.sharedOpportunities]
  );

  const disableOpportunityDeletion = useCallback(
    (opportunityId: string) =>
      Boolean(
        isPartnerCell ||
          partnerConnection.rawOpportunitiesWithRule?.includes(opportunityId)
      ),
    [isPartnerCell, partnerConnection.rawOpportunitiesWithRule]
  );

  const unlinkOpportunity = useCallback(
    async (opportunityId: string) => {
      if (!disableOpportunityDeletion(opportunityId)) {
        const result = await dispatch(
          removeRelated({
            type: "partner_connections",
            id: partnerConnection.id,
            related: [
              {
                id: opportunityId,
                type: "raw_opportunities",
              },
            ],
            relation: "raw_opportunities",
          })
        );
        if (isRejected(result)) {
          pushNotification(i18n.errorCouldNotUnlink);
        } else {
          track(PipelineEvent.tableEdit, {
            action: PipelineTableEditAction.unlinkOpportunity,
          });
          onUnlink(opportunityId);
        }
      }
    },
    [
      dispatch,
      partnerConnection.id,
      onUnlink,
      pushNotification,
      track,
      disableOpportunityDeletion,
    ]
  );

  const linkOpportunity = useCallback(
    async (opportId: string) => {
      const opportunity = availableOpports?.find(
        (opportunity) => opportunity.id === Number(opportId)
      );
      if (!opportunity) {
        return;
      }
      const response = await dispatch(
        addRelated({
          type: "partner_connections",
          reload: true,
          id: partnerConnection.id,
          relation: "raw_opportunities",
          related: [{ id: String(opportunity.id), type: "raw_opportunities" }],
        })
      );

      if (isRejected(response)) {
        pushNotification("default_error");
      } else {
        track(PipelineEvent.tableEdit, {
          action: PipelineTableEditAction.linkOpportunity,
        });
        setAvailableOpports(
          (value) =>
            value?.filter((record) => Number(record.id) !== opportunity.id) ??
            null
        );
      }
    },
    [pushNotification, dispatch, track, partnerConnection.id, availableOpports]
  );

  const updateAttributes = useCallback(
    async ({ added, removed }: { added: string[]; removed: string[] }) => {
      await dispatch(
        updateAttributesOnRecord(
          {
            type: "partner_connections",
            id: String(partnerConnection.id),
          },
          {
            sharedOpportunities: [
              ...(partnerConnection.sharedOpportunities?.filter(
                (item) => !removed.includes(String(item.id))
              ) ?? []),
              ...(availableOpports
                ?.filter((opportunity) =>
                  added.includes(String(opportunity.id))
                )
                .map(
                  (opportunity) =>
                    ({
                      id: Number(opportunity.id),
                      opportunity_name: opportunity.name,
                      amount: opportunity.amountInDollars,
                      close_date:
                        opportunity.closeDate?.toISOString().split("T")[0] ??
                        opportunity.closeDate,
                      status: opportunity.getSharedOpportunityStatus(),
                      stage: opportunity.stage,
                    } as ISharedOpportunity)
                ) ?? []),
            ],
            unlinkedOpportunityCount:
              (partnerConnection.unlinkedOpportunityCount ?? 0) +
              removed.length -
              added.length,
          }
        )
      );
    },
    [
      availableOpports,
      dispatch,
      partnerConnection.id,
      partnerConnection.sharedOpportunities,
      partnerConnection.unlinkedOpportunityCount,
    ]
  );

  const unlinkedOpenOpportunityCount =
    (partnerConnection?.openOpportunityCount ?? 0) -
    (partnerConnection.sharedOpportunities ?? []).filter(
      (opport) => opport.status === Status.Open
    ).length;

  return {
    allowEdit,
    availableOpports,
    anchorEl,
    handleClose,
    handleOpen,
    isLoadingAvailableOpports,
    isPartnerCell,
    linkOpportunity,
    unlinkedOpenOpportunityCount,
    unlinkOpportunity,
    updateAttributes,
  };
};

const i18n = defineMessages({
  errorCouldNotUnlink: {
    id: "useOpportunityDropdown.errorCouldNotUnlink",
    defaultMessage: "Error, could not unlink Opportunity",
  },
});

const getValues = ({
  partnerConnection,
  currentUser,
  profile,
  isPartnerView,
}: {
  partnerConnection: PartnerConnection;
  currentUser: User;
  profile: User;
  isPartnerView: boolean;
}) => {
  const isAdminOrPM =
    profile.isCompanyAdmin() || profile.isPartnershipManager();
  const isUserItem = partnerConnection.userId === currentUser?.id;
  const dataFromSync =
    partnerConnection.rawOpportunitiesWithRule?.length > 0 ?? false;
  const allowEdit =
    !isPartnerView && !dataFromSync && (isAdminOrPM || isUserItem);
  const isPartnerCell = currentUser?.companyId !== partnerConnection.companyId;

  return { isPartnerCell, allowEdit };
};
