import { Chip, Grid } from "@mui/material";
import { Email, Open } from "components/icons";
import CompanyAvatar from "components/ui/avatars/CompanyAvatar";
import Button from "components/ui/Button";
import { T } from "components/ui/Typography";
import { makeStyles } from "makeStyles";
import {
  defineMessages,
  FormattedHTMLMessage,
  FormattedMessage,
} from "react-intl";
import { useHistory } from "react-router";

type Props = {
  companyName: string;
  companyLogo?: string;
  accountName: string;
  partnerName: string;
  partnerManagerName: string;
  isSuccess: boolean;
};

export const ResponseContent = ({
  companyName,
  companyLogo,
  accountName,
  partnerName,
  partnerManagerName,
  isSuccess,
}: Props) => {
  const { classes } = useStyles();
  const history = useHistory();

  const goToReveal = () => {
    history.push("/");
  };

  return (
    <Grid
      container
      direction="column"
      alignContent="center"
      className={classes.root}
    >
      <Grid item className={classes.avatarContainer}>
        <CompanyAvatar companyName={companyName} src={companyLogo} size="xxl" />
      </Grid>
      <Grid item>
        <T h2 bold textAlign="center">
          <FormattedMessage
            {...(isSuccess ? i18n.successTitle : i18n.failTitle)}
          />
        </T>
        <T textAlign="center" className={classes.subtitle}>
          <FormattedMessage
            {...(isSuccess ? i18n.successSubtitle : i18n.failSubtitle)}
            values={{ accountName, partnerName }}
          />
        </T>
      </Grid>
      {isSuccess ? (
        <>
          <Grid item className={classes.boxContainer}>
            <T h3 bold>
              <FormattedMessage {...i18n.recipients} />
            </T>
            <div className={classes.emailContainer}>
              <Email className={classes.emailIcon} />
              <T>
                <FormattedMessage
                  {...i18n.partnerManager}
                  values={{ partnerManager: partnerManagerName, partnerName }}
                />
              </T>
            </div>
          </Grid>
          <Grid item className={classes.boxContainer}>
            <T h3 bold>
              <FormattedMessage {...i18n.nextSteps} />
            </T>
            <T>
              <FormattedHTMLMessage
                {...i18n.followUp}
                values={{ partnerManager: partnerManagerName }}
              />
              <FormattedHTMLMessage {...i18n.addContext} />
            </T>
          </Grid>
        </>
      ) : (
        <Grid item className={classes.boxContainer}>
          <T h3 bold>
            <FormattedMessage {...i18n.tryAgain} />
          </T>
          <T>
            <FormattedMessage
              {...i18n.tryAgainContent}
              values={{ partnerName }}
            />
          </T>
        </Grid>
      )}
      <Grid item className={classes.boxContainer}>
        <T h3 bold>
          <FormattedMessage {...i18n.more} />
        </T>
        <div className={classes.btnContainer}>
          <Button label={i18n.goToReveal} onClick={goToReveal} />
          <a href="https://reveal.co/" rel="noopener noreferrer">
            <Chip
              variant="filled"
              className={classes.chip}
              avatar={<Open className={classes.icon} />}
              label={<FormattedMessage {...i18n.revealWebsite} />}
            />
          </a>
        </div>
      </Grid>
    </Grid>
  );
};

// I18N

const i18n = defineMessages({
  successTitle: {
    id: "addToPipelineFromWeeklyDigest.successTitle",
    defaultMessage: "Your Intro request was successfully sent",
  },
  failTitle: {
    id: "addToPipelineFromWeeklyDigest.failTitle",
    defaultMessage: "Your Intro request failed",
  },
  successSubtitle: {
    id: "addToPipelineFromWeeklyDigest.successSubtitle",
    defaultMessage:
      "You requested an intro to {accountName} via {partnerName}.",
  },
  failSubtitle: {
    id: "addToPipelineFromWeeklyDigest.failSubtitle",
    defaultMessage:
      "You tried to request an intro to {accountName} via {partnerName}.",
  },
  recipients: {
    id: "addToPipelineFromWeeklyDigest.recipients",
    defaultMessage: "Where was it sent?",
  },
  partnerManager: {
    id: "addToPipelineFromWeeklyDigest.partnerManager",
    defaultMessage:
      "{partnerManager}, Partner Owner of {partnerName} (you’re cc’d)",
  },
  nextSteps: {
    id: "addToPipelineFromWeeklyDigest.nextSteps",
    defaultMessage: "What’s next?",
  },
  tryAgain: {
    id: "addToPipelineFromWeeklyDigest.tryAgain",
    defaultMessage: "Try again",
  },
  tryAgainContent: {
    id: "addToPipelineFromWeeklyDigest.tryAgainContent",
    defaultMessage:
      "Your intro request did not go through. Try again, or contact the partnership manager in charge of {partnerName}, directly.",
  },
  more: {
    id: "addToPipelineFromWeeklyDigest.more",
    defaultMessage: "You want more ecosystem insights?",
  },
  goToReveal: {
    id: "addToPipelineFromWeeklyDigest.goToReveal",
    defaultMessage: "Go to Reveal",
  },
  revealWebsite: {
    id: "addToPipelineFromWeeklyDigest.revealWebsite",
    defaultMessage: "Reveal Website",
  },
  followUp: {
    id: "addToPipelineFromWeeklyDigest.followUp",
    defaultMessage:
      "• Follow up with your Partnership Manager ({partnerManager})<br/>",
  },
  addContext: {
    id: "addToPipelineFromWeeklyDigest.addContext",
    defaultMessage: `• Add context to your request:<br/>
      &emsp;a. What type of help are you looking for (Intel, Influence, Intro)<br/>
      &emsp;b. Who would you like an intro to, who is your current contact (if applicable)<br/>
      &emsp;c. When are you next meeting with the account (if applicable)<br/>
      &emsp;d. What can you help the partner with in return
    `,
  },
});

// CSS

const useStyles = makeStyles()((theme) => ({
  root: {
    rowGap: 20,
    maxWidth: 820,
    padding: "0px 100px",
  },
  avatarContainer: {
    display: "flex",
    justifyContent: "center",
    marginBottom: 10,
  },
  boxContainer: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    background: theme.palette.ivory,
    borderRadius: 7,
    padding: theme.spacing(3),
    rowGap: 12,
  },
  subtitle: {
    paddingTop: 4,
    color: theme.palette.alpha500,
  },
  emailContainer: {
    alignItems: "center",
    display: "flex",
    columnGap: 6,
  },
  btnContainer: {
    display: "flex",
    columnGap: theme.spacing(0.5),
    paddingTop: theme.spacing(1),
  },
  button: {
    textDecoration: "none",
    "&:hover": {
      textDecoration: "none",
    },
  },
  chip: {
    background: theme.palette.taupe500,
    cursor: "pointer",
    fontFamily: "'Inter', sans-serif",
    fontSize: 12,
    padding: `${theme.spacing(1.5)} ${theme.spacing(1)}`,
    "&:hover": {
      background: theme.palette.taupe,
    },
  },
  icon: {
    height: "16px !important",
    width: "16px !important",
  },
  emailIcon: {
    background: theme.palette.greyscale200,
    color: theme.palette.pigeon,
    padding: 6,
    borderRadius: 4,
  },
}));

export const _private = {
  i18n,
};
