import history from "_history";
import { parse, stringify } from "query-string";
import { useEffect } from "react";

import useSort from "./useSort";

const useURISort = (defaultSort = {}) => {
  const initSort = { ...defaultSort };
  const search = parse(window.location.search);
  if (search.sort) {
    // @ts-expect-error ts-migrate(2339) FIXME: Property 'order' does not exist on type '{}'.
    initSort.order = search.sort.indexOf("-") === -1 ? "asc" : "desc";
    // @ts-expect-error ts-migrate(2339) FIXME: Property 'by' does not exist on type '{}'.
    initSort.by = search.sort.slice(search.order === "desc" ? 1 : 0);
  }
  const [order, by, updateSort] = useSort(initSort);

  useEffect(() => {
    const currentSearch = parse(window.location.search);
    if (order && by) {
      // @ts-expect-error ts-migrate(2339) FIXME: Property 'order' does not exist on type '{}'.
      if (order === defaultSort.order && by === defaultSort.by) {
        if (currentSearch.sort) {
          delete currentSearch["sort"];
        }
      } else {
        currentSearch.sort = (order === "desc" ? "-" : "") + by;
      }
    }
    history.replace({
      pathname: window.location.pathname,
      search: stringify(currentSearch),
      hash: window.location.hash,
    });
    // @ts-expect-error ts-migrate(2339) FIXME: Property 'order' does not exist on type '{}'.
  }, [order, by, defaultSort.order, defaultSort.by]);

  return [order, by, updateSort];
};

export default useURISort;
