import { Available360MappingColumns } from "redux/mapping360/defaults";

import { FieldType, MatchFieldType, MatchFilterType } from "../../shared/types";

export const COMPANY_SIZE_OPTIONS = {
  "1": "Self-employed",
  "2_10": "2-10",
  "11_50": "11-50",
  "51_200": "51-200",
  "201_500": "201-500",
  "501_1000": "501-1000",
  "1001_5000": "1001-5000",
  "5001_10000": "5001-10,000",
  "10001_plus": "10,001+",
};

export const expandNewMarketsFields: { [key: string]: FieldType } = {
  [Available360MappingColumns.name]: {
    allowedOperations: [MatchFilterType.CONTAINS],
    label: "Company name",
    type: MatchFieldType.TEXT,
    options: {},
    smartField: true,
    fullyImported: true,
    isDisabled: false,
    noSide: true,
    isPrivate: false,
    partnerField: false,
    sort: true,
    filter: true,
    column: true,
  },
  [Available360MappingColumns.customerPresenceHighlightsCount]: {
    label: "Partner signals",
    type: MatchFieldType.NUMBER,
    options: {},
    smartField: true,
    fullyImported: true,
    isDisabled: false,
    noSide: true,
    isPrivate: false,
    partnerField: false,
    sort: true,
    filter: true,
    column: true,
  },
  [Available360MappingColumns.domain]: {
    allowedOperations: [MatchFilterType.CONTAINS],
    label: "Domain",
    type: MatchFieldType.TEXT,
    options: {},
    smartField: true,
    fullyImported: true,
    isDisabled: false,
    noSide: true,
    isPrivate: false,
    partnerField: false,
    sort: true,
    filter: true,
    column: true,
  },
  [Available360MappingColumns.countryName]: {
    label: "Country",
    type: MatchFieldType.TEXT,
    options: {},
    smartField: true,
    fullyImported: true,
    isDisabled: false,
    noSide: true,
    isPrivate: false,
    partnerField: false,
    sort: true,
    filter: false,
    column: true,
  },
  [Available360MappingColumns.industry]: {
    allowedOperations: [MatchFilterType.CONTAINS],
    label: "Industry",
    type: MatchFieldType.TEXT,
    options: {},
    smartField: true,
    fullyImported: true,
    isDisabled: false,
    noSide: true,
    isPrivate: false,
    partnerField: false,
    sort: true,
    filter: true,
    column: true,
  },
  [Available360MappingColumns.employeeRange]: {
    allowedOperations: [MatchFilterType.ANY_OF],
    label: "# of employees",
    type: MatchFieldType.PICKLIST,
    options: COMPANY_SIZE_OPTIONS,
    smartField: true,
    fullyImported: true,
    isDisabled: false,
    noSide: true,
    isPrivate: false,
    partnerField: false,
    sort: false,
    isOptionsSorted: true,
    filter: true,
    column: true,
  },
  [Available360MappingColumns.customerOf]: {
    label: "Is a customer of",
    type: MatchFieldType.PICKLIST,
    options: {},
    smartField: true,
    fullyImported: true,
    isDisabled: false,
    noSide: true,
    isPrivate: false,
    partnerField: false,
    sort: false,
    filter: false,
    column: true,
  },
  [Available360MappingColumns.createdAt]: {
    label: "Date identified on",
    type: MatchFieldType.DATE,
    options: {},
    smartField: true,
    fullyImported: true,
    isDisabled: false,
    noSide: true,
    isPrivate: false,
    partnerField: false,
    sort: true,
    filter: true,
    column: true,
  },

  [Available360MappingColumns.dealInfluencerList]: {
    label: "Deal influencers",
    type: MatchFieldType.TEXT,
    options: {},
    smartField: true,
    fullyImported: true,
    isDisabled: false,
    noSide: true,
    isPrivate: false,
    partnerField: false,
    sort: false,
    filter: true,
    column: true,
  },
};
