import GhostPartner from "models/GhostPartner";
import Match from "models/Match";
import Record from "models/Record";
import User from "models/User";

export type PartnerAccount = {
  partner: Record | null;
  ghostPartner: GhostPartner | null;
  matches: Match[];
  status?: number;
  firstWonOpportAt: string | null;
  nbKeyContacts: number;
  nbOtherContacts: number;
  owners: User[];
};

export type CustomerPresence = {
  partnership_id: number;
  partner_id: number;
};

export type RawContact = {
  contact_urls: string[];
  jobtitle: string;
  opports: number;
  overlaps: boolean;
};

export type Contact = {
  contactUrl?: string;
  jobtitle: string;
  isCommon: boolean;
  isPrimary: boolean;
};

export enum IntroRequestSource {
  Mapping360 = 4,
  AccountMapping = 5,
}
