import { TextInput } from "components/ui/TextInput";
import { makeStyles } from "makeStyles";
import Partnership from "models/Partnership";
import { ChangeEvent } from "react";
import { defineMessages, useIntl } from "react-intl";

import PartnerSelector from "./PartnerSelector";
import { ReferType } from "./ReferDialog";

type Props = {
  referType: ReferType;
  selectedPartners: Partnership[];
  setSelectedPartners: (value: Partnership[]) => void;
  isSubmitting: boolean;
  optionalMessage: string;
  setOptionalMessage: (value: string) => void;
};

const ReferContent = ({
  referType,
  selectedPartners,
  setSelectedPartners,
  isSubmitting,
  optionalMessage,
  setOptionalMessage,
}: Props) => {
  const { classes } = useStyles();
  const intl = useIntl();

  return (
    <div className={classes.inputContainer}>
      {(referType === ReferType.MULTIPLE_TO_MULTIPLE ||
        referType === ReferType.ONE_TO_MULTIPLE) && (
        <PartnerSelector
          selectPartners={setSelectedPartners}
          selectedPartners={selectedPartners}
          disabled={isSubmitting}
        />
      )}
      {referType !== ReferType.MULTIPLE_TO_MULTIPLE && (
        <TextInput
          className={classes.input}
          name="message"
          placeholder={intl.formatMessage(i18n.messageOptional)}
          onChange={(e: ChangeEvent<HTMLInputElement>) =>
            setOptionalMessage(e.target.value)
          }
          value={optionalMessage}
          multiline
          rows="3"
          rowsmax="10"
          disabled={isSubmitting}
        />
      )}
    </div>
  );
};

export default ReferContent;

// CSS

const useStyles = makeStyles()(() => ({
  inputContainer: {
    "&>div + div": {
      marginTop: 16,
    },
  },
  input: {
    fontSize: 12,
    width: "100%",
  },
}));

// I18N

const i18n = defineMessages({
  messageOptional: {
    id: "DataTables.ActionBtn.ReferOption.ReferContent.messageOptional",
    defaultMessage: "Write a comment (optional)",
  },
});
