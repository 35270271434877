import { LaunchRounded } from "@mui/icons-material";
import { Avatar, Box } from "@mui/material";
import { T } from "components/ui/Typography";
import useAmountFormatter from "hooks/useAmountFormatter";
import { makeStyles } from "makeStyles";
import Record from "models/Record";
import ReactCountryFlag from "react-country-flag";
import { defineMessages, FormattedMessage, useIntl } from "react-intl";
import countryList from "react-select-country-list";
import { COMPANY_SIZE_OPTIONS } from "screens/Frontoffice/screens/DataTables/screens/Mapping360/constants";
import { getStatusMessage } from "screens/Frontoffice/shared/components/AccountStatus";

type Props = {
  account: Record<"crm_accounts"> | Record<"nearbound_prospects">;
};

export const AccountDescription = ({ account }: Props) => {
  const { classes, cx } = useStyles();
  const isNearboundProspect = account.type === "nearbound_prospects";
  const statusText = isNearboundProspect
    ? i18n.notInYourCrm
    : getStatusMessage(account.status);
  const countryCode = isNearboundProspect
    ? countryList().getValue(account.countryName)
    : account.countryCode;
  const intl = useIntl();
  const amountFormatter = useAmountFormatter(intl);

  return (
    <Box className={classes.container}>
      <Box className={classes.item}>
        <T className={classes.label}>
          <FormattedMessage {...i18n.status} />
        </T>
        {statusText && (
          <>
            <T className={cx({ [classes.italic]: isNearboundProspect })}>
              <FormattedMessage {...statusText} />
            </T>
            {account.starred && (
              <T>
                <FormattedMessage {...i18n.strategic} />
              </T>
            )}
          </>
        )}
      </Box>
      {account.owner && (
        <Box className={classes.item}>
          <T className={classes.label}>
            <FormattedMessage {...i18n.ownedBy} />
          </T>
          <Box className={classes.item}>
            <Avatar className={classes.avatar}>
              {account.owner.fullName.charAt(0)}
            </Avatar>
            <T bold>{account.owner.fullName}</T>
          </Box>
        </Box>
      )}
      {countryCode && (
        <Box className={classes.item}>
          <T className={classes.label}>
            <FormattedMessage {...i18n.country} />
          </T>
          <Box className={classes.item}>
            <ReactCountryFlag countryCode={countryCode} />
            <T>{countryList().getLabel(countryCode)}</T>
          </Box>
        </Box>
      )}
      <Box className={classes.item}>
        <T className={classes.label}>
          <FormattedMessage {...i18n.domain} />
        </T>
        {account.domain && (
          <Box>
            <a
              className={cx(classes.link, classes.item)}
              href={`http://${account.domain}`}
              target="_blank"
              rel="noopener noreferrer"
            >
              {account.domain}
              <LaunchRounded className={classes.iconSmall} />
            </a>
          </Box>
        )}
      </Box>
      {account.openOpportunitiesAmountInDollars &&
        account.openOpportunities?.length > 0 && (
          <Box className={classes.item}>
            <T className={classes.label}>
              <FormattedMessage {...i18n.openDeal} />
            </T>
            <T className={classes.openDeal}>
              {amountFormatter(
                account.openOpportunitiesAmountInDollars.amount,
                "USD"
              )}
            </T>
            <T>
              <FormattedMessage
                {...i18n.openDealCount}
                values={{ value: account.openOpportunities?.length }}
              />
            </T>
          </Box>
        )}
      {account.industry && (
        <Box className={classes.item}>
          <T className={classes.label}>
            <FormattedMessage {...i18n.industry} />
          </T>
          <T>{account.industry}</T>
        </Box>
      )}
      {account.employeeRange && (
        <Box className={classes.item}>
          <T className={classes.label}>
            <FormattedMessage {...i18n.employeeRange} />
          </T>
          <T>
            {
              COMPANY_SIZE_OPTIONS[
                account.employeeRange as keyof typeof COMPANY_SIZE_OPTIONS
              ]
            }
          </T>
        </Box>
      )}
    </Box>
  );
};

const i18n = defineMessages({
  country: {
    id: "crm.Ecosystem.AccountDescription.country",
    defaultMessage: "Country",
  },
  domain: {
    id: "crm.Ecosystem.AccountDescription.domain",
    defaultMessage: "Domain name",
  },
  employeeRange: {
    id: "crm.Ecosystem.AccountDescription.employeeRange",
    defaultMessage: "Company size",
  },
  industry: {
    id: "crm.Ecosystem.AccountDescription.industry",
    defaultMessage: "Industry",
  },
  notInYourCrm: {
    id: "crm.Ecosystem.AccountDescription.notInYourCrm",
    defaultMessage: "Not in your CRM",
  },
  openDeal: {
    id: "crm.Ecosystem.AccountDescription.openDeal",
    defaultMessage: "Open deal(s)",
  },
  openDealCount: {
    id: "crm.Ecosystem.AccountDescription.openDealCount",
    defaultMessage: "{value, plural, one {(1 deal)} other {(# deals)}}",
  },
  ownedBy: {
    id: "crm.Ecosystem.AccountDescription.ownedBy",
    defaultMessage: "Owned by",
  },
  status: {
    id: "crm.Ecosystem.AccountDescription.status",
    defaultMessage: "Status",
  },
  strategic: {
    id: "crm.Ecosystem.AccountDescription.strategic",
    defaultMessage: "(Strategic)",
  },
});

export const useStyles = makeStyles()((theme) => ({
  avatar: {
    background: theme.palette.greyscale200,
    color: theme.palette.midnight,
    fontSize: 8,
    fontWeight: 500,
    height: 16,
    width: 16,
  },
  container: {
    background: theme.palette.greyLight050Alpha050,
    borderRadius: theme.spacing(1),
    display: "flex",
    flexDirection: "column",
    gap: 4,
    padding: theme.spacing(2),
  },
  iconSmall: {
    height: 12,
    width: 12,
  },
  italic: {
    fontStyle: "italic",
  },
  item: {
    alignItems: "center",
    display: "flex",
    gap: 6,
  },
  label: {
    alignItems: "center",
    color: theme.palette.alpha500,
    display: "flex",
    flexGrow: 1,
    height: 24,
    maxWidth: 224,
  },
  link: {
    borderBottom: `1px dashed ${theme.palette.greyLight300}`,
    color: theme.palette.midnight,
    fontSize: 12,
    fontWeight: 400,
    "&:hover": {
      textDecoration: "none",
    },
  },
  openDeal: {
    background: theme.palette.ivory,
    border: `1px solid ${theme.palette.greyscale250}`,
    borderRadius: 4,
    fontWeight: 500,
    padding: "1px 6px",
  },
}));
