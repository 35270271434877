import {
  array,
  boolean,
  constant,
  Decoder,
  either,
  guard,
  instanceOf,
  iso8601,
  map,
  nullable,
  number,
  object,
  oneOf,
  optional,
  string,
} from "decoders";

import DiscussionParticipant from "./DiscussionParticipant";
import Record from "./Record";
import { JSONAPIIdentifier, JSONAPIResource } from "./types";

export enum Kind {
  SOURCING = "SOURCING",
  INFLUENCE = "INFLUENCE",
  "CO-SELLING" = "CO-SELLING",
  RESELLING = "RESELLING",
  OTHER = "OTHER",
}

export enum kindEncodeMapping {
  SOURCING = 1,
  INFLUENCE = 2,
  OTHER = 3,
  "CO-SELLING" = 4,
  RESELLING = 5,
  NONE = 6,
}

export const kindDecodeMapping: {
  [kind: number]: Kind;
} = {
  [kindEncodeMapping[Kind.SOURCING]]: Kind.SOURCING,
  [kindEncodeMapping[Kind.INFLUENCE]]: Kind.INFLUENCE,
  [kindEncodeMapping[Kind.OTHER]]: Kind.OTHER,
  [kindEncodeMapping[Kind["CO-SELLING"]]]: Kind["CO-SELLING"],
  [kindEncodeMapping[Kind.RESELLING]]: Kind.RESELLING,
};

export const KindDecoder: Decoder<Kind> = map<kindEncodeMapping, Kind>(
  oneOf(
    Object.keys(kindEncodeMapping)
      .map((x) => parseInt(x))
      .filter((x) => !isNaN(x))
  ),
  (kind: kindEncodeMapping) => kindDecodeMapping[kind]
);

export enum Stage {
  toDiscuss = "New",
  toIntro = "Accepted",
  declined = "Declined",
  onHold = "On hold",
  introMade = "Attributed",
  meetingBooked = "Meeting booked",
  opportunity = "Opportunity",
  closed = "Closed",
}

export enum stageEncodeMapping {
  "New" = 1,
  "Accepted" = 3,
  "Declined" = 2,
  "On hold" = 7,
  "Attributed" = 4,
  "Meeting booked" = 5,
  "Opportunity" = 6,
  "Closed" = 8,
}

export const stageDecodeMapping: {
  [stage: number]: Stage;
} = {
  [stageEncodeMapping[Stage.toDiscuss]]: Stage.toDiscuss,
  [stageEncodeMapping[Stage.declined]]: Stage.declined,
  [stageEncodeMapping[Stage.toIntro]]: Stage.toIntro,
  [stageEncodeMapping[Stage.introMade]]: Stage.introMade,
  [stageEncodeMapping[Stage.meetingBooked]]: Stage.meetingBooked,
  [stageEncodeMapping[Stage.opportunity]]: Stage.opportunity,
  [stageEncodeMapping[Stage.onHold]]: Stage.onHold,
  [stageEncodeMapping[Stage.closed]]: Stage.closed,
};

export const StageDecoder: Decoder<Stage> = map<stageEncodeMapping, Stage>(
  oneOf(
    Object.keys(stageEncodeMapping)
      .map((x) => parseInt(x))
      .filter((x) => !isNaN(x))
  ),
  (stage: stageEncodeMapping) => stageDecodeMapping[stage]
);

export const Status = {
  Open: "Open",
  Won: "Won",
  Lost: "Lost",
} as const;
export type IStatus = keyof typeof Status;

export type ISharedOpportunity = {
  account_name?: string | null;
  id: number;
  status?: IStatus | null;
  stage?: string | null;
  amount?: number | null;
  close_date?: string | null;
  owner?: {
    email?: string | null;
    id: number;
    full_name?: string | null;
    user_id?: number | null;
  } | null;
  next_step?: string | null;
  opportunity_name?: string | null;
  opportunity_type?: string | null;
  url?: string | null;
};

const apiPartnerConnection = object({
  type: constant("partner_connections" as const),
  id: string,
  attributes: object({
    accepted_date: nullable(iso8601),
    activity_date: nullable(iso8601),
    created_at: iso8601,
    has_external_participants: optional(nullable(boolean)),
    is_shared: optional(nullable(boolean)),
    new_prospect_name: optional(nullable(string)),
    stage: StageDecoder,
    kind: KindDecoder,
    partnership_id: nullable(number),
    partner_avatar_url: nullable(string),
    partner_name: nullable(string),
    raw_company_id: nullable(number),
    raw_company_name: optional(nullable(string)),
    raw_company_owner_name: nullable(string),
    raw_company_starred: optional(nullable(boolean)),
    updated_at: iso8601,
    archived_at: nullable(iso8601),
    user_id: nullable(number),
    open_opportunity_count: nullable(number),
    unlinked_opportunity_count: nullable(number),
    private_collab: optional(nullable(boolean)),
    shared_opportunities: optional(
      nullable(
        array(
          object({
            account_name: optional(nullable(string)),
            id: number,
            status: optional(nullable(string)),
            stage: optional(nullable(string)),
            amount: optional(nullable(number)),
            close_date: optional(nullable(string)),
            owner: optional(
              nullable(
                object({
                  email: nullable(string),
                  id: number,
                  full_name: nullable(string),
                  user_id: nullable(number),
                })
              )
            ),
            next_step: optional(nullable(string)),
            opportunity_name: optional(nullable(string)),
            opportunity_type: optional(nullable(string)),
            url: optional(nullable(string)),
          })
        )
      )
    ),
  }),
  relationships: object({
    discussion_participants: object({
      data: either(
        array(instanceOf(DiscussionParticipant)),
        array(object({ id: string, type: string }))
      ),
    }),
  }),
});

const apiPartnerConnectionGuard = guard(apiPartnerConnection);

export default class PartnerConnection extends Record<"partner_connections"> {
  acceptedDate: Date | null;
  activityDate: Date | null;
  id: number;
  createdAt: Date;
  discussionParticipants: DiscussionParticipant[] | null;
  isShared?: boolean | null;
  newProspectName?: string | null;
  stage: Stage;
  kind: Kind;
  partnershipId: number | null;
  partnerAvatarUrl: string | null;
  partnerName: string | null;
  rawCompanyId: number | null;
  rawCompanyName?: string | null;
  rawCompanyOwnerName: string | null;
  rawCompanyStarred?: boolean | null;
  updatedAt: Date;
  archivedAt: Date | null;
  userId: number | null;
  sharedOpportunities: ISharedOpportunity[];
  openOpportunityCount: number | null;
  unlinkedOpportunityCount: number | null;
  privateCollab?: boolean | null;
  hasExternalParticipants?: boolean | null;

  constructor(data: JSONAPIResource<"partner_connections">) {
    super(data);
    const apiPartnerConnection = apiPartnerConnectionGuard(data);
    const attrs = apiPartnerConnection.attributes;
    const relationships = apiPartnerConnection.relationships;

    this.id = +apiPartnerConnection.id;
    this.acceptedDate = attrs.accepted_date;
    this.activityDate = attrs.activity_date;
    this.createdAt = attrs.created_at;
    this.isShared = attrs.is_shared;
    this.stage = attrs.stage;
    this.kind = attrs.kind;
    this.newProspectName = attrs.new_prospect_name;
    this.partnershipId = attrs.partnership_id;
    this.partnerAvatarUrl = attrs.partner_avatar_url;
    this.partnerName = attrs.partner_name;
    this.rawCompanyId = attrs.raw_company_id;
    this.rawCompanyName = attrs.raw_company_name;
    this.rawCompanyOwnerName = attrs.raw_company_owner_name;
    this.rawCompanyStarred = attrs.raw_company_starred;
    this.updatedAt = attrs.updated_at;
    this.archivedAt = attrs.archived_at;
    this.userId = attrs.user_id;
    // @ts-expect-error
    this.sharedOpportunities = attrs.shared_opportunities;
    // @ts-expect-error
    this.discussionParticipants = relationships.discussion_participants.data.map(
      (data: JSONAPIIdentifier) =>
        data instanceof DiscussionParticipant ? data : new Record(data)
    ) as DiscussionParticipant[];
    this.openOpportunityCount = attrs.open_opportunity_count;
    this.unlinkedOpportunityCount = attrs.unlinked_opportunity_count;
    this.privateCollab = attrs.private_collab;
    this.hasExternalParticipants = attrs.has_external_participants;
  }
}
