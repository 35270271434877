import { createSvgIcon } from "@mui/material/utils";

import { SvgIcon } from "./types";

const displayName = "PendingPartners";

const PendingPartners: SvgIcon = createSvgIcon(
  <g fill="none">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7 3.25C4.92893 3.25 3.25 4.92893 3.25 7V17C3.25 19.0711 4.92893 20.75 7 20.75H17C19.0711 20.75 20.75 19.0711 20.75 17V7C20.75 4.92893 19.0711 3.25 17 3.25H7ZM4.75 7C4.75 5.75736 5.75736 4.75 7 4.75H17C18.2426 4.75 19.25 5.75736 19.25 7V17C19.25 18.2426 18.2426 19.25 17 19.25H7C5.75736 19.25 4.75 18.2426 4.75 17V7ZM12 7.75C12.4142 7.75 12.75 8.08579 12.75 8.5V12.5C12.75 12.9142 12.4142 13.25 12 13.25C11.5858 13.25 11.25 12.9142 11.25 12.5V8.5C11.25 8.08579 11.5858 7.75 12 7.75ZM12.75 15.25C12.75 15.6642 12.4142 16 12 16C11.5858 16 11.25 15.6642 11.25 15.25C11.25 14.8358 11.5858 14.5 12 14.5C12.4142 14.5 12.75 14.8358 12.75 15.25Z"
      fill="currentColor"
    />
    <rect x="15" y="15" width="8" height="8" rx="4" fill="#BB5077" />
    <rect
      x="15"
      y="15"
      width="8"
      height="8"
      rx="4"
      stroke="#0A151B"
      stroke-width="2"
    />
  </g>,
  displayName
);

PendingPartners.displayName = displayName;
export default PendingPartners;
