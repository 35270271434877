import { FormHelperText } from "@mui/material";
import DialogActions from "@mui/material/DialogActions";
import FormControl from "@mui/material/FormControl";
import Grid from "@mui/material/Grid";
import { isFulfilled, isRejected } from "@reduxjs/toolkit";
import Button from "components/ui/Button";
import Popup from "components/ui/Popup";
import { TextInput } from "components/ui/TextInput";
import { T } from "components/ui/Typography";
import { Formik, FormikHelpers } from "formik";
import usePushNotification from "hooks/usePushNotification";
import useSegment from "hooks/useSegment";
import { useSmartViews } from "hooks/useSmartViews";
import useUserProfile from "hooks/useUserProfile";
import generic from "i18n/generic";
import { makeStyles } from "makeStyles";
import { MouseEventHandler, useContext } from "react";
import {
  defineMessages,
  FormattedMessage,
  MessageDescriptor,
  useIntl,
} from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { selectActivePayingFeatures } from "redux/api/selectors";
import { asJSONSerializable } from "redux/typing";
import { isIGoalPreset } from "screens/Frontoffice/screens/DataTables/screens/Mapping360/goalsPresets";
import MappingFilterContext from "screens/Frontoffice/screens/DataTables/shared/contexts/MappingFilterContext";
import {
  FilterType,
  SortType,
} from "screens/Frontoffice/screens/DataTables/shared/types";
import { object, string } from "yup";

import {
  ExtendedAccountMappingResource,
  SmartViewSegmentEvent,
} from "./constants";
import { useSmartViewTableConfig } from "./helpers";
import { savedFilterTypeFromViewType } from "./utils";

type Props = {
  isOpen: boolean;
  onAddSmartView: (id: number) => void;
  onClose: () => void;
  accountType: ExtendedAccountMappingResource;
  partnershipId?: number;
  selectedFilterId?: number;
  isEditingName?: boolean;
};

const SmartViewCreateDialog = ({
  accountType,
  onAddSmartView,
  isOpen,
  isEditingName,
  onClose,
  partnershipId,
  selectedFilterId,
}: Props) => {
  const { classes } = useStyles();
  const dispatch = useDispatch();
  const intl = useIntl();
  const { addView, records, updateView } = useSmartViews(
    accountType,
    partnershipId
  );
  const { orderArray, updateViewThunk, view } = useSmartViewTableConfig({
    accountType,
  });
  const { defaultView } = useContext(MappingFilterContext);
  const { track } = useSegment();
  const { profile } = useUserProfile();
  const payingFeatures = useSelector(selectActivePayingFeatures);

  const selectedSavedFilter = records.find(
    (record) => record.id === selectedFilterId
  );
  const filtersWithoutDefaults = isIGoalPreset(defaultView)
    ? view?.filters.filter(
        (item: FilterType) =>
          !defaultView.presets.filter?.some(
            (defaultFilter) => defaultFilter.fieldname === item.fieldname
          )
      )
    : view?.filters;
  const sortWithoutDefaults = isIGoalPreset(defaultView)
    ? view?.sort.filter(
        (item: SortType) =>
          !defaultView.presets.sort?.some(
            (defaultSort) => defaultSort.fieldname === item.fieldname
          )
      )
    : view?.sort;
  const pushNotification = usePushNotification();

  const handleSubmit = async (
    values: { name: string },
    actions: FormikHelpers<{ name: string }>
  ) => {
    actions.setSubmitting(true);

    if (isEditingName) {
      const result = await updateView(selectedSavedFilter!.id, {
        name: values.name,
      });
      if (isFulfilled(result)) {
        pushNotification(i18n.updatedFilter);
      }
      if (isRejected(result)) {
        if (result.error.message === "409") {
          dispatch(
            pushNotification(i18n.duplicateKeyError, { name: values.name })
          );
        } else {
          pushNotification(i18n.failedToUpdateView);
        }
      }
    } else {
      const result = await addView({
        name: values.name,
        view_type: savedFilterTypeFromViewType(accountType),
        filters: asJSONSerializable(filtersWithoutDefaults),
        sort: asJSONSerializable(sortWithoutDefaults),
        ...(partnershipId && { partnership_id: partnershipId }),
      });
      if (isFulfilled(result)) {
        const id = Number((result.payload as any).data.id);
        await dispatch(
          updateViewThunk({
            filterOrderList: [id, ...orderArray],
          })
        );
        onAddSmartView(id);
      }
    }
    track(SmartViewSegmentEvent.SavedView, {
      isPaidUser: profile.isPaidCustomer(payingFeatures),
    });
    actions.setSubmitting(false);
    onClose();
  };

  const handleClose: MouseEventHandler<HTMLDivElement> = (event) => {
    event.preventDefault();
    event.stopPropagation();
    onClose();
  };

  return (
    <Popup
      isOpen={isOpen}
      title={
        <>
          <T h2 bold>
            <FormattedMessage
              {...(isEditingName ? i18n.titleEdit : i18n.title)}
              values={{ name: selectedSavedFilter?.name }}
            />
          </T>
          {!isEditingName && (
            <T className={classes.subtitle}>
              <FormattedMessage {...i18n.subtitle} />
            </T>
          )}
        </>
      }
      handleClose={handleClose}
      hideActions
      maxWidth="sm"
      variant="secondary"
    >
      <Formik
        initialValues={{
          name: "",
        }}
        onSubmit={handleSubmit}
        validationSchema={schema}
      >
        {({
          values,
          handleSubmit,
          handleChange,
          isSubmitting,
          touched,
          errors,
        }) => (
          <form onSubmit={handleSubmit}>
            <FormControl fullWidth error={Boolean(errors.name && touched.name)}>
              <TextInput
                className={classes.input}
                name="name"
                onChange={handleChange}
                placeholder={intl.formatMessage(i18n.placeholder)}
                disabled={isSubmitting}
                error={Boolean(touched.name && errors.name)}
                value={values.name}
                variant="newDesign"
              />
              <FormHelperText>
                {errors.name && touched.name && (
                  <FormattedMessage {...(errors.name as MessageDescriptor)} />
                )}
              </FormHelperText>
            </FormControl>
            <DialogActions>
              <Grid container className={classes.actionContainer}>
                <Button
                  label={generic.cancel}
                  onClick={handleClose}
                  disabled={isSubmitting}
                  variant="secondary"
                />
                <Button
                  type="submit"
                  label={i18n.save}
                  disabled={isSubmitting ? true : undefined}
                />
              </Grid>
            </DialogActions>
          </form>
        )}
      </Formik>
    </Popup>
  );
};

export default SmartViewCreateDialog;

/// CSS

const useStyles = makeStyles()((theme) => ({
  input: {
    fontSize: 12,
    margin: theme.spacing(1),
    marginBottom: theme.spacing(2),
  },
  actionContainer: {
    display: "flex",
    justifyContent: "flex-end",
    columnGap: theme.spacing(0.5),
  },
  subtitle: {
    color: theme.palette.alpha750,
    marginTop: theme.spacing(1),
  },
}));

/// I18N

const i18n = defineMessages({
  duplicateKeyError: {
    id: "SmartViewCreateDialog.duplicateKeyError",
    defaultMessage: '"{name}" is already in use. Please choose another name.',
  },
  failedToUpdateView: {
    id: "SmartViewCreateDialog.failedToUpdateView",
    defaultMessage: "Failed to update view",
  },
  nameTooLong: {
    id: "SmartViewCreateDialog.nameTooLong",
    defaultMessage: "Max. 80 characters",
  },
  placeholder: {
    id: "SmartViewCreateDialog.placeholder",
    defaultMessage: "Dynamic view name",
  },
  title: {
    id: "SmartViewCreateDialog.title",
    defaultMessage: "Save new dynamic view",
  },
  titleEdit: {
    id: "SmartViewCreateDialog.titleEdit",
    defaultMessage: "Edit “{name}” view",
  },
  save: {
    id: "SmartViewCreateDialog.save",
    defaultMessage: "Save view",
  },
  subtitle: {
    id: "SmartViewCreateDialog.subtitle",
    defaultMessage: "Lists allow you to save time and focus on what matters.",
  },
  updatedFilter: {
    id: "SmartViewCreateDialog.updatedFilter",
    defaultMessage: "Updated filter",
  },
});

const schema = object().shape({
  name: string().required().max(80, i18n.nameTooLong),
});
