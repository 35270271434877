import { createSvgIcon } from "@mui/material/utils";

import { SvgIcon } from "./types";

const displayName = "GetIntro";

const GetIntro: SvgIcon = createSvgIcon(
  // Using transform to enlarge from provided viewbox="0 0 16 16"
  // to default "0 0 24 24" from `createSvgIcon` -> `SvgIcon`.
  <g fill="none" transform="matrix(1.5,0,0,1.5,0,0)">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.00011 2.5C6.6194 2.5 5.50011 3.61929 5.50011 5C5.50011 6.38071 6.6194 7.5 8.00011 7.5C9.38082 7.5 10.5001 6.38071 10.5001 5C10.5001 3.61929 9.38082 2.5 8.00011 2.5ZM4.50011 5C4.50011 3.067 6.06712 1.5 8.00011 1.5C9.93311 1.5 11.5001 3.067 11.5001 5C11.5001 6.933 9.93311 8.5 8.00011 8.5C6.06712 8.5 4.50011 6.933 4.50011 5ZM11.6466 9.89645C11.8418 9.70118 12.1584 9.70118 12.3537 9.89645L14.3537 11.8964C14.5489 12.0917 14.5489 12.4083 14.3537 12.6036L12.3537 14.6036C12.1584 14.7988 11.8418 14.7988 11.6466 14.6036C11.4513 14.4083 11.4513 14.0917 11.6466 13.8964L12.793 12.75H10.0001C9.72397 12.75 9.50011 12.5261 9.50011 12.25C9.50011 11.9739 9.72397 11.75 10.0001 11.75H12.793L11.6466 10.6036C11.4513 10.4083 11.4513 10.0917 11.6466 9.89645ZM4.26698 11.0741C5.33219 10.4186 6.56445 10.0713 7.82154 10.0713C8.09768 10.0713 8.32154 10.2951 8.32154 10.5713C8.32154 10.8474 8.09768 11.0713 7.82154 11.0713C6.74822 11.0713 5.69759 11.3679 4.79108 11.9257C3.88468 12.4835 3.15997 13.2793 2.69911 14.2199C2.57761 14.4678 2.27809 14.5704 2.03011 14.4489C1.78214 14.3274 1.67961 14.0278 1.80111 13.7799C2.34628 12.6672 3.20168 11.7296 4.26698 11.0741Z"
      fill="currentColor"
    />
  </g>,
  displayName
);

GetIntro.displayName = displayName;
export default GetIntro;
