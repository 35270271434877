import { HelpOutlineRounded } from "@mui/icons-material";
import { ConfigContext } from "config/ConfigProvider";
import { useContext } from "react";
import { defineMessages } from "react-intl";

import SidebarButton from "./SidebarButton";

type Props = {
  openSidebar: () => void;
};

export const SidebarHelpCenter = ({ openSidebar }: Props) => {
  const config = useContext(ConfigContext);
  const props = {
    callback: () => window.open(config.reveal4BusinessLink, "_blank"),
    label: i18n.helpCenter,
    Icon: HelpOutlineRounded,
    path: null,
    openSidebar,
  };
  return <SidebarButton {...props} fullWidth />;
};

const i18n = defineMessages({
  helpCenter: {
    id: "SidebarHelpCenter.helpCenter",
    defaultMessage: "Help Center",
  },
});
