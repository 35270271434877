import { SelectChangeEvent } from "@mui/material";
import Integration from "components/ui/Integration";
import { SelectInput } from "components/ui/SelectInput";
import SWQLBooleanNode from "components/ui/SWQL/SWQLBooleanNode";
import { useSWQLContext } from "components/ui/SWQL/SWQLContext";
import {
  emptyNode,
  isSWQLConstantNode,
  NodeType,
  SWQLNode,
} from "components/ui/SWQL/SWQLTypes";
import { T } from "components/ui/Typography";
import { makeStyles } from "makeStyles";
import IntegrationModel from "models/Integration";
import Partnership from "models/Partnership";
import { useCallback } from "react";
import { defineMessages, FormattedMessage, useIntl } from "react-intl";

type Props = {
  integration: IntegrationModel;
  partnership: Partnership;
};

const isAll = (node: SWQLNode): boolean =>
  isSWQLConstantNode(node) && node.value === true;

const RestrictedAccountsForIntegration = ({
  integration,
  partnership,
}: Props) => {
  const { classes } = useStyles();
  const intl = useIntl();
  const { getNode, updateNode } = useSWQLContext();
  const node = getNode([]);

  const handleChange = useCallback(
    (e: SelectChangeEvent) => {
      if (e.target.value === "all") {
        updateNode([], { type: NodeType.Constant, value: true });
      } else {
        updateNode([], emptyNode());
      }
    },
    [updateNode]
  );

  return (
    <>
      <div className={classes.row}>
        <T bodyBig className={classes.intro}>
          <FormattedMessage {...i18n.from} />
          <Integration integration={integration} size="md" />
          {!partnership.asymmetricalPartnership && (
            <FormattedMessage {...i18n.where} />
          )}
        </T>
        {partnership.asymmetricalPartnership && (
          <SelectInput
            options={[
              {
                label: intl.formatMessage(i18n.restrictAccountsMatchingRule),
                value: "custom",
              },
              {
                label: intl.formatMessage(i18n.allAccounts),
                value: "all",
              },
            ]}
            value={isAll(node) ? "all" : "custom"}
            onChange={handleChange}
            inputProps={{ "data-testid": "select-mode" }}
          />
        )}
      </div>
      {!isSWQLConstantNode(node) ? <SWQLBooleanNode pathToNode={[]} /> : ""}
    </>
  );
};

export default RestrictedAccountsForIntegration;

/// Styles

const useStyles = makeStyles()((theme) => ({
  row: {
    display: "flex",
    alignItems: "center",
    justifyContent: "start",
    gap: theme.spacing(1),
  },
  intro: {
    display: "flex",
    alignItems: "center",
    gap: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
}));

/// I18N

const i18n = defineMessages({
  from: {
    id: "RestrictedAccountsTab.from",
    defaultMessage: "from",
  },
  where: {
    id: "RestrictedAccountsTab.where",
    defaultMessage: " where",
  },
  allAccounts: {
    id: "RestrictedAccountsTab.allAccounts",
    defaultMessage: "restrict all accounts",
  },
  restrictAccountsMatchingRule: {
    id: "RestrictedAccountsTab.restrictAccountsMatchingRule",
    defaultMessage: "where matching a rule",
  },
});
