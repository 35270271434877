import { isFulfilled } from "@reduxjs/toolkit";
import { AxiosResponse } from "axios";
import usePushNotification from "hooks/usePushNotification";
import _ from "lodash";
import { PayingFeature } from "models/CompanyPayingFeatureSubscription";
import { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { selectActivePayingFeatures } from "redux/api/selectors";
import { index, rawGet } from "redux/api/thunks";
import {
  PartnershipChannelMode,
  PartnershipChannelType,
} from "screens/Frontoffice/screens/Partners/screens/PartnershipSettings/components/CollaborationTab/types";
import { JSONAPIResponse } from "services/types";

const useGetIntroSlackChannels = (
  partnershipId: number | null,
  isLocked: boolean = false
) => {
  const dispatch = useDispatch();
  const pushNotification = usePushNotification();
  const payingFeatures = useSelector(selectActivePayingFeatures);
  const [loading, setLoading] = useState<boolean>(false);
  const [defaultChannelName, setDefaultChannelName] = useState<string | null>(
    null
  );
  const [
    internalChannelNameLoading,
    setInternalChannelNameLoading,
  ] = useState<boolean>(false);
  const [internalChannelName, setInternalChannelName] = useState<string | null>(
    null
  );
  const [
    externalChannelNameLoading,
    setExternalChannelNameLoading,
  ] = useState<boolean>(false);
  const [externalChannelName, setExternalChannelName] = useState<string | null>(
    null
  );
  const [
    introNotificationSettingsLoading,
    setIntroNotificationSettingsLoading,
  ] = useState<boolean>(false);
  const [
    shouldEmailPartnershipManager,
    setShouldEmailPartnershipManager,
  ] = useState<boolean>(false);
  const [
    shouldEmailAccountOwner,
    setShouldEmailAccountOwner,
  ] = useState<boolean>(false);

  const hasSlackIntegrationPayingFeature = payingFeatures.includes(
    PayingFeature.SlackIntegration
  );

  const retrieveChannelName = useCallback(
    async (slackId: string, setName: (value: string) => void) => {
      const result = await dispatch(
        rawGet({ type: "slack_channels", path: `/by-slack-id/${slackId}/` })
      );
      if (isFulfilled(result)) {
        const response = result.payload as JSONAPIResponse;
        if (response.data.attributes?.name) {
          setName(`${response.data.attributes?.name}`);
        }
      } else {
        pushNotification("default_error");
      }
    },
    [dispatch, pushNotification]
  );

  const getDefaultChannel = useCallback(async () => {
    const result = await dispatch(
      index({
        type: "default_channels",
      })
    );
    if (isFulfilled(result)) {
      const response = result.payload as AxiosResponse;
      if (response.data.length > 0) {
        const defaultSlackId = response.data[0].attributes.slack_id;
        if (defaultSlackId) {
          retrieveChannelName(defaultSlackId, setDefaultChannelName);
        }
      }
    } else {
      pushNotification("default_error");
    }
  }, [dispatch, pushNotification, retrieveChannelName]);

  // Retrieve channels linked to the selected partnership (to display channels name in Intro Routing)
  const getPartnershipChannels = useCallback(
    async (partnershipId: number) => {
      setLoading(true);
      setInternalChannelName(null);
      setExternalChannelName(null);
      const result = await dispatch(
        index({
          type: "partnership_channels",
          options: {
            filters: {
              partnership_id: partnershipId,
            },
            fields: {
              partnership_channels: ["slack_id", "mode,channel_type"],
            },
          },
        })
      );
      if (isFulfilled(result)) {
        const response = result.payload as AxiosResponse;
        _.map(response.data, async (channel) => {
          const type = String(channel.attributes.channel_type);
          const mode = String(channel.attributes.mode);
          if (
            type === PartnershipChannelType.Internal &&
            mode === PartnershipChannelMode.Custom
          ) {
            const slackId = channel.attributes.slack_id;
            setInternalChannelNameLoading(true);
            await retrieveChannelName(slackId, (value) => {
              setInternalChannelName(value);
              setInternalChannelNameLoading(false);
            });
          } else if (
            type === PartnershipChannelType.Shared &&
            mode === PartnershipChannelMode.Custom
          ) {
            const slackId = channel.attributes.slack_id;
            setExternalChannelNameLoading(true);
            await retrieveChannelName(slackId, (value) => {
              setExternalChannelName(value);
              setExternalChannelNameLoading(false);
            });
          }
        });
        setLoading(false);
      } else {
        pushNotification("default_error");
      }
    },
    [dispatch, pushNotification, retrieveChannelName]
  );

  // Retrieve intro notification settings (to display external emails in Intro Routing)
  const getIntroNotificationSettings = useCallback(
    async (partnershipId: number) => {
      setIntroNotificationSettingsLoading(true);
      setShouldEmailPartnershipManager(false);
      setShouldEmailAccountOwner(false);
      const result = await dispatch(
        rawGet({
          type: "introduction_notification_settings",
          path: `${partnershipId}/`,
        })
      );
      if (isFulfilled(result)) {
        const response = result.payload as AxiosResponse;
        if (response.data.attributes?.should_email_partnership_manager) {
          setShouldEmailPartnershipManager(true);
        }
        if (response.data.attributes?.should_email_account_owner) {
          setShouldEmailAccountOwner(true);
        }
        setIntroNotificationSettingsLoading(false);
      } else {
        pushNotification("default_error");
      }
    },
    [dispatch, pushNotification]
  );

  useEffect(() => {
    if (!isLocked && hasSlackIntegrationPayingFeature) {
      getDefaultChannel();
    }
  }, [isLocked]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (
      partnershipId !== null &&
      !isLocked &&
      hasSlackIntegrationPayingFeature
    ) {
      getPartnershipChannels(partnershipId);
      getIntroNotificationSettings(partnershipId);
    }
  }, [partnershipId]); // eslint-disable-line react-hooks/exhaustive-deps

  return {
    loading:
      loading ||
      internalChannelNameLoading ||
      externalChannelNameLoading ||
      introNotificationSettingsLoading,
    internalChannelName: internalChannelName || defaultChannelName,
    externalChannelName,
    shouldEmailPartnershipManager,
    shouldEmailAccountOwner,
  };
};

export default useGetIntroSlackChannels;
