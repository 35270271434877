import { BaseQuickViews, QuickViewFilter } from "components/ui/BaseQuickViews";
import { ThreeSixty } from "components/ui/filters/smartView/constants";
import {
  quickViewsForSize,
  useSmartViewTableConfig,
} from "components/ui/filters/smartView/helpers";
import { SmartViewDropdown } from "components/ui/filters/smartView/SmartViewDropdown";
import { T } from "components/ui/Typography";
import { useSmartViews } from "hooks/useSmartViews";
import _ from "lodash";
import Integration from "models/Integration";
import { PageType } from "models/PageView";
import { useCallback, useContext, useEffect, useState } from "react";
import { nearboundAccountsPageTypes } from "redux/mapping360NearboundAccounts/defaults";
import {
  Facets,
  Preset,
} from "screens/Frontoffice/screens/DataTables/shared/types";

import MappingFilterContext from "../../../shared/contexts/MappingFilterContext";
import { addFacets } from "../utils";

const defaultFacets: Facets = {
  [-1]: 0,
  [-2]: 0,
  [-3]: 0,
  [-4]: 0,
  [-5]: 0,
};

type QuickViewsProps = {
  onSelect: (value: Preset) => void;
  sources?: Integration[];
  selectedSourceId: number | null;
};

const DROPDOWN_KEY = -999;

const QuickViews = ({
  onSelect,
  sources = [],
  selectedSourceId,
}: QuickViewsProps) => {
  const {
    unorderedQuickFilters,
    selectedSmartViewId,
    setSelectedSmartViewId,
    defaultView,
  } = useContext(MappingFilterContext);
  const accountType =
    defaultView?.pageType === PageType.mapping360Expand
      ? ThreeSixty.nearboundProspects
      : defaultView?.pageType &&
        nearboundAccountsPageTypes.includes(defaultView.pageType)
      ? ThreeSixty.nearboundAccounts
      : ThreeSixty.threeSixty;

  const [facets, setFacets] = useState(defaultFacets);
  const { fetchViews, records: smartViews } = useSmartViews(accountType);
  const { orderArray } = useSmartViewTableConfig({
    accountType,
  });

  useEffect(() => {
    fetchViews();
  }, [fetchViews]);

  useEffect(() => {
    let result = { ...defaultFacets };
    const selectedSource = sources.filter(
      ({ id }) => id === selectedSourceId
    )[0];
    if (selectedSource) {
      result = addFacets(result, selectedSource);
    } else {
      _.each(sources, (source: Integration) => {
        result = addFacets(result, source);
      });
    }
    setFacets(result);
  }, [sources, selectedSourceId]);

  const handleSelectSmartView = (id?: number) => {
    setSelectedSmartViewId(id);
  };

  let quickFilters: QuickViewFilter[] = [];
  let combinedFilterList = unorderedQuickFilters.concat(
    smartViews.map((view) => ({
      presets: {
        filter: view.filters,
        sort: view.sort,
      },
      key: view.id,
      label: <T>{view.name}</T>,
      name: view.name,
      hideOnSmallScreens: false,
    }))
  );
  for (let index of orderArray) {
    const filter = combinedFilterList.find((filter) => filter.key === index);
    if (filter) {
      quickFilters.push(filter);
    }
  }
  const dropdownButton = {
    key: DROPDOWN_KEY,
    presets: {
      filter: [],
      sort: [],
    },
    label: (
      <SmartViewDropdown
        accountType={accountType}
        applyPreset={onSelect}
        defaultFilterList={unorderedQuickFilters}
        facets={facets}
        filterList={quickFilters}
        onSelectSmartView={handleSelectSmartView}
        selectedSmartViewId={selectedSmartViewId}
      />
    ),
    name: "Saved views",
    hideOnSmallScreens: false,
    isButton: true,
  };

  const adaptedFilterListForSize = quickViewsForSize(
    quickFilters,
    1000,
    dropdownButton
  );

  const handleSelect = useCallback(
    (preset: Preset, id: number) => {
      if (id === DROPDOWN_KEY) {
        return;
      }
      setSelectedSmartViewId(id);
      onSelect(preset);
    },
    [onSelect, setSelectedSmartViewId]
  );

  return (
    <BaseQuickViews
      filterList={adaptedFilterListForSize}
      onSelect={handleSelect}
      facets={facets}
      selectedFilterKey={selectedSmartViewId}
    />
  );
};

export default QuickViews;
