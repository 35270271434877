import { createSvgIcon } from "@mui/material/utils";

import { SvgIcon } from "./types";

const displayName = "LockLightInverted";

const LockLightInverted: SvgIcon = createSvgIcon(
  // Using transform to enlarge from provided viewbox="0 0 16 16"
  // to default "0 0 24 24" from `createSvgIcon` -> `SvgIcon`.
  <g fill="none" transform="matrix(2,0,0,2,3.5,0)">
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M1.625 2.75C1.625 1.24848 2.84223 0.03125 4.34375 0.03125C5.84527 0.03125 7.0625 1.24848 7.0625 2.75V3.03396C7.89907 3.08249 8.5625 3.77627 8.5625 4.625V8.375C8.5625 9.2552 7.84895 9.96875 6.96875 9.96875H1.71875C0.838546 9.96875 0.125 9.2552 0.125 8.375V4.625C0.125 3.77627 0.788433 3.08249 1.625 3.03396V2.75ZM6.125 3.03125H2.5625V2.75C2.5625 1.76624 3.35999 0.96875 4.34375 0.96875C5.32751 0.96875 6.125 1.76624 6.125 2.75V3.03125ZM5.28125 5.9375C5.28125 6.28296 5.0944 6.58477 4.81625 6.7474V7.53125C4.81625 7.79013 4.60639 8 4.3475 8C4.08862 8 3.87875 7.79013 3.87875 7.53125V6.75174C3.59647 6.59018 3.40625 6.28605 3.40625 5.9375C3.40625 5.41973 3.82598 5 4.34375 5C4.86152 5 5.28125 5.41973 5.28125 5.9375Z"
      fill="#0A151B"
    />
  </g>,
  displayName
);

LockLightInverted.displayName = displayName;
export default LockLightInverted;
