import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import _ from "lodash";
import { Factory } from "models";
import Record from "models/Record";
import {
  JSONAPIAttributes,
  JSONAPIIdentifier,
  JSONAPIRelationships,
  JSONAPIResource,
} from "models/types";
import { combineReducers } from "redux";
import {
  isJSONAPIListResponse,
  isJSONAPIResponse,
  JSONAPIListResponse,
  JSONAPIResponse,
} from "services/types";

import {
  bulkAddRecords,
  bulkRemoveRecords,
  bulkUpdateAttributesOnRecords,
  clearPartnershipCrmFields,
  updateAttributesOnRecord,
  updateRelationshipsOnRecord,
} from "./actions";
import {
  addRelated,
  attachToRecord,
  bulkUpdate,
  create,
  destroy,
  fetchRelated,
  index,
  indexAll,
  rawGet,
  rawPatch,
  rawPost,
  rawPut,
  removeRelated,
  retreive,
  update,
} from "./thunks";
import { APICollection, APIEntities } from "./typing";

/**
 * Reconstruct the relations from what is available in the state
 * @param state current state of loaded entities
 */
const buildRelations = (state: APIEntities) => {
  _.map(state, (records) => {
    _.map(records, (record) => {
      _.map(record.relationships, (related, relation) => {
        const relationName = _.camelCase(relation);
        if (related.data === undefined) {
          delete record.relationships[relation];
        } else if (_.isArray(related.data)) {
          const resources: (JSONAPIResource | Record)[] = related.data;
          record[
            relationName
          ] = resources.map((resource: JSONAPIResource | Record) =>
            resolveResource(
              { id: String(resource.id), type: resource.type },
              state
            )
          );
        } else if (related.data !== null) {
          record[relationName] = resolveResource(
            { id: String(related.data.id), type: related.data.type },
            state
          );
        }
      });
    });
  });
};

/**
 * Resolve or build a record from a raw resource
 * @param resource Resource from the API
 * @param state Current state of the redux data store
 * @returns Record instance, either existing from the store or new from the resource
 */
const resolveResource = (
  resource: JSONAPIResource,
  state: APIEntities
): Record => {
  const collection = state[resource.type];
  if (collection && resource.id in collection) {
    return collection[resource.id];
  }
  return Factory.createRecord(resource);
};

/**
 * Creates a new Record from existing instance or resource, and upsert it in the state
 * @param state APIEntities, map of entities in store
 * @param record Record, or resource to be updated
 * @returns updated state
 */
export const insertOrUpdateRecord = (
  state: APIEntities,
  record: Record | JSONAPIResource
) => {
  let { id, type, attributes, relationships } = record;
  const collection = state[type] ?? {};
  if (!state[type]) {
    state[type] = collection;
  }
  const existingRecord = collection[id] || {};

  const customizer = (objValue: Record, srcValue: Record) => {
    if (_.isArray(objValue) || _.isObject(objValue)) {
      return srcValue;
    }
  };

  const relationshipsCustomizer = (objValue: Record, srcValue: Record) => {
    if (_.isObject(srcValue) && !_.has(srcValue, "data")) {
      return objValue;
    }
    return customizer(objValue, srcValue);
  };

  collection[id] = Factory.createRecord({
    id: String(id),
    type,
    attributes: _.mergeWith(existingRecord.attributes, attributes, customizer),
    relationships: _.mergeWith(
      existingRecord.relationships,
      relationships,
      relationshipsCustomizer
    ),
  });

  return state;
};

/**
 * Process `included` section in the payload of a resolved JSONAPI thunk
 * @param state APIEntities, map of entities in store
 * @param action Action to process, typically after a Thunk is fulfilled
 */
const processIncluded = (
  state: APIEntities,
  action: PayloadAction<JSONAPIListResponse> | PayloadAction<JSONAPIResponse>
) => {
  if (action.payload.included) {
    action.payload.included.reduce(insertOrUpdateRecord, state);
  }
};

const storeRecordsForResources = (
  state: APIEntities,
  action: PayloadAction<JSONAPIListResponse>
) => {
  processIncluded(state, action);
  action.payload.data.reduce(insertOrUpdateRecord, state);
  buildRelations(state);
};

const storeRecordsForResource = (
  state: APIEntities,
  action: PayloadAction<JSONAPIResponse>
) => {
  processIncluded(state, action);
  insertOrUpdateRecord(state, action.payload.data);
  buildRelations(state);
};

export const storeRecords = (
  state: APIEntities,
  action: PayloadAction<any>
) => {
  if (isJSONAPIListResponse(action.payload)) {
    storeRecordsForResources(
      state,
      action as PayloadAction<JSONAPIListResponse>
    );
  }
  if (isJSONAPIResponse(action.payload)) {
    storeRecordsForResource(state, action as PayloadAction<JSONAPIResponse>);
  }
};

export const processRemoveRelatedFulfilled = (
  state: APIEntities,
  action: ReturnType<typeof removeRelated.fulfilled>
) => {
  const { type, id, relation, related } = action.meta.arg;
  const collection = state[type];
  if (collection !== undefined) {
    const strId = String(id);
    const record = (state[type] ?? {})[strId];
    if (record !== undefined) {
      const relatedIds = related.map((value) => +value.id);
      let newData = null;
      const current = record.relationships[relation]?.data;
      if (_.isArray(current)) {
        newData = _.filter(
          current,
          (value: JSONAPIIdentifier) => !relatedIds.includes(+value.id)
        ) as JSONAPIIdentifier[];
      }
      collection[strId] = Factory.createRecord({
        id: strId,
        type,
        attributes: record.attributes,
        relationships: {
          ...record.relationships,
          [relation]: {
            data: newData,
          },
        },
      });
      buildRelations(state);
    }
  }
};

const processRemoveRecords = (
  state: APIEntities,
  action: PayloadAction<JSONAPIIdentifier | JSONAPIIdentifier[]>
) => {
  (_.isArray(action.payload) ? action.payload : [action.payload]).forEach(
    (identifier) => {
      const collection = state[identifier.type];
      if (collection) {
        delete collection[identifier.id];
      }
    }
  );
};

type UpdateAction =
  | ReturnType<typeof updateAttributesOnRecord>
  | ReturnType<typeof bulkUpdateAttributesOnRecords>;
type BulkUpdateAction = ReturnType<typeof bulkUpdateAttributesOnRecords>;

function isBulkUpdateActionGuard(
  action: UpdateAction
): action is BulkUpdateAction {
  return _.isArray(action.payload.record);
}

const updateAttributesOnRecordReducer = (
  state: APIEntities,
  action:
    | ReturnType<typeof updateAttributesOnRecord>
    | ReturnType<typeof bulkUpdateAttributesOnRecords>
) => {
  const identifiers = isBulkUpdateActionGuard(action)
    ? _.groupBy(
        action.payload.record,
        (value: JSONAPIIdentifier | Record) => value.type
      )
    : { [action.payload.record.type]: [action.payload.record] };
  _.each(identifiers, (items, type) => {
    const collection: APICollection = state[type] ?? {};
    _.each(items, (recordIdentifier) => {
      const record = collection[recordIdentifier.id];
      if (record) {
        collection[record.id] = Factory.createRecord({
          ...record,
          id: String(record.id),
          attributes: {
            ...record.attributes,
            ..._.reduce(
              action.payload.attributes,
              (acc, value, field) => {
                acc[_.snakeCase(field)] = value;
                return acc;
              },
              {} as JSONAPIAttributes
            ),
          },
          relationships: _.reduce(
            {
              ..._.reduce(
                record.relationships,
                (acc, relationData, relationName) => {
                  acc[relationName] = relationData.data as
                    | JSONAPIIdentifier
                    | JSONAPIIdentifier[]
                    | null;
                  return acc;
                },
                {} as {
                  [relation: string]:
                    | JSONAPIIdentifier
                    | JSONAPIIdentifier[]
                    | null;
                }
              ),
            },
            resolveRelationships(state),
            {} as JSONAPIRelationships
          ),
        });
      }
    });
  });
};

const resolveRelationships = (state: APIEntities) => (
  relationships: JSONAPIRelationships,
  data: JSONAPIIdentifier | JSONAPIIdentifier[] | null,
  relation: string
) => {
  if (data === null) {
    relationships[relation] = { data: null };
  } else if (_.isArray(data)) {
    relationships[relation] = {
      data: data.map((item) => resolveResource(item, state)),
    };
  } else {
    relationships[relation] = {
      data: resolveResource(data, state),
    };
  }
  return relationships;
};

const updateRelationshipsOnRecordReducer = (
  state: APIEntities,
  action: ReturnType<typeof updateRelationshipsOnRecord>
) => {
  const record = (state[action.payload.record.type] ?? {})[
    action.payload.record.id
  ];

  const prepareForUpdate = (record: Record): Record => ({
    ...record,
    relationships: _.reduce(
      {
        ..._.reduce(
          record.relationships,
          (acc, relationData, relationName) => {
            acc[relationName] = relationData.data as
              | JSONAPIIdentifier
              | JSONAPIIdentifier[]
              | null;
            return acc;
          },
          {} as typeof action.payload.relationships
        ),
        ...action.payload.relationships,
      },
      resolveRelationships(state),
      {} as JSONAPIRelationships
    ),
  });
  state[action.payload.record.type] = state[action.payload.record.type] ?? {};
  const collection: APICollection = state[action.payload.record.type] ?? {};
  collection[action.payload.record.id] = Factory.createRecord(
    prepareForUpdate(record)
  );
};

const clearPartnershipCrmFieldsInStore = (
  state: APIEntities,
  _action: ReturnType<typeof clearPartnershipCrmFields>
) => {
  state.partnership_crm_fields = {};
};

export const entities = createSlice({
  name: "api/entities",
  initialState: {} as APIEntities,
  reducers: {},
  extraReducers: (builder) => {
    // @ts-ignore/
    builder
      .addCase(addRelated.fulfilled, storeRecords)
      .addCase(attachToRecord.fulfilled, storeRecords)
      .addCase(bulkRemoveRecords, processRemoveRecords)
      .addCase(bulkAddRecords, storeRecords)
      .addCase(fetchRelated.fulfilled, storeRecords)
      .addCase(removeRelated.fulfilled, processRemoveRelatedFulfilled)
      .addCase(create.fulfilled, storeRecordsForResource)
      .addCase(update.fulfilled, storeRecordsForResource)
      .addCase(retreive.fulfilled, storeRecordsForResource)
      .addCase(destroy.fulfilled, processRemoveRecords)
      .addCase(bulkUpdate.fulfilled, storeRecordsForResources)
      .addCase(rawGet.fulfilled, storeRecords)
      .addCase(rawPatch.fulfilled, storeRecords)
      .addCase(rawPut.fulfilled, storeRecords)
      .addCase(rawPost.fulfilled, storeRecords)
      .addCase(index.fulfilled, storeRecords)
      .addCase(indexAll.fulfilled, storeRecordsForResources)
      .addCase(clearPartnershipCrmFields, clearPartnershipCrmFieldsInStore)
      .addCase(updateAttributesOnRecord, updateAttributesOnRecordReducer)
      .addCase(bulkUpdateAttributesOnRecords, updateAttributesOnRecordReducer)
      .addCase(updateRelationshipsOnRecord, updateRelationshipsOnRecordReducer);
  },
});

export default combineReducers({
  entities: entities.reducer,
});
