import DialogActions from "@mui/material/DialogActions";
import FormControl from "@mui/material/FormControl";
import Grid from "@mui/material/Grid";
import { isFulfilled, isRejected } from "@reduxjs/toolkit";
import withCompanyPermissions from "components/hoc/withCompanyPermissions";
import { Send } from "components/icons";
import Button from "components/ui/Button";
import TwoSided from "components/ui/TwoSided/TwoSided";
import { T } from "components/ui/Typography";
import { partnership } from "data/demo";
import { Formik } from "formik";
import usePushNotification from "hooks/usePushNotification";
import useSegment from "hooks/useSegment";
import useUserProfile from "hooks/useUserProfile";
import generic from "i18n/generic";
import _ from "lodash";
import { makeStyles } from "makeStyles";
import { PartnershipSource } from "models/Partnership";
import { JSONAPIAttributes } from "models/types";
import { defineMessages, FormattedMessage, useIntl } from "react-intl";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { create } from "redux/api/thunks";
import { addCreatedPartner } from "redux/dashboard/actions";
import { fields, included } from "redux/init/constants";
import { setCompany } from "redux/user/actions";
import PartnerTile from "screens/Frontoffice/screens/Partners/screens/Dashboard/components/PartnerTile";
import CompanyAutocomplete from "screens/Frontoffice/shared/components/CompanyAutocomplete";
import { JSONAPIResponse } from "services/types";
import { PartnershipEvent } from "tracking";
import { object, string } from "yup";

type Value = {
  company: {
    name: string;
    domain: string | null;
    logo: string | null;
  };
};

const schema = object().shape({
  company: object()
    .shape({
      name: string().required(),
    })
    .required(),
});

const InvitePrivate = () => {
  const { classes } = useStyles();
  const { profile } = useUserProfile();
  const history = useHistory();
  const { track } = useSegment();
  const pushNotification = usePushNotification();
  const intl = useIntl();
  const dispatch = useDispatch();

  const incrementGhostPartnershipCount = () => {
    const company = _.cloneDeep(profile.company);
    company.ghostPartnership += 1;
    dispatch(setCompany(company));
  };

  const handleSubmit = async (values: Value, actions: $TSFixMe) => {
    actions.setSubmitting(true);
    let data = {
      initiator_company_id: profile.company.id,
      initiator_user_id: profile.id,
      requested_company_name: values.company.name,
      requested_company_domain: values.company.domain,
      status: "ghost",
      source: PartnershipSource.Other,
    } as JSONAPIAttributes;
    const result = await dispatch(
      create({
        type: "partnerships",
        attributes: data,
        options: { include: included, fields },
      })
    );
    if (isFulfilled(result)) {
      const response = result.payload as JSONAPIResponse;
      dispatch(addCreatedPartner({ id: +response.data.id }));
      incrementGhostPartnershipCount();
      track(PartnershipEvent.ghostCreation, {
        partnerName: data.requested_company_name,
      });
      history.push({
        pathname: `/partnerships/${response.data.id}/settings`,
        hash: "data-sources",
      });
    }
    if (isRejected(result)) {
      pushNotification("default_error");
    }
    actions.setSubmitting(false);
  };

  const goBack = () => {
    history.goBack({ fallback: "/partnerships" });
  };

  return (
    <TwoSided
      classes={{ rightPanel: classes.rightPanel }}
      left={
        <>
          <div className={classes.rvlProfile} />
          <div className={classes.leftContent}>
            <div className={classes.leftPanelTile}>
              <PartnerTile record={partnership} />
            </div>
            <T h2 bold>
              <FormattedMessage {...i18n.title} />
            </T>
            <T>
              <FormattedMessage {...i18n.paragraph} />
            </T>
          </div>
        </>
      }
      right={
        <>
          <T h2 bold className={classes.title}>
            <FormattedMessage {...i18n.title} />
          </T>

          <Formik
            initialValues={{
              company: {
                name: "",
                logo: null,
                domain: null,
              },
            }}
            onSubmit={handleSubmit}
            validationSchema={schema}
          >
            {({
              values,
              handleSubmit,
              handleChange,
              isSubmitting,
              touched,
              errors,
            }) => (
              <form onSubmit={handleSubmit} className={classes.form}>
                <div className={classes.logo}>
                  <img
                    src={values.company.logo || "/images/background-square.png"}
                    alt=""
                  />
                </div>
                <FormControl
                  fullWidth
                  error={Boolean(
                    touched.company && errors.company && errors.company.name
                  )}
                >
                  <CompanyAutocomplete
                    variant="primary"
                    fullWidth
                    name="company"
                    onChange={handleChange}
                    placeholder={intl.formatMessage(generic.company_name)}
                    disabled={isSubmitting}
                    data-testid="company-field"
                    autoFocus
                    error={Boolean(
                      touched.company && errors.company && errors.company.name
                    )}
                    value={values.company}
                  />
                </FormControl>
                <DialogActions>
                  <Grid container className={classes.actionContainer}>
                    <Button
                      label={generic.cancel}
                      onClick={goBack}
                      disabled={isSubmitting}
                      variant="secondary"
                      data-testid="button-cancel"
                    />
                    <Button
                      type="submit"
                      label={i18n.create}
                      disabled={isSubmitting ? true : undefined}
                      RightIcon={Send}
                      data-testid="button-invite"
                    />
                  </Grid>
                </DialogActions>
              </form>
            )}
          </Formik>
        </>
      }
    />
  );
};

export default withCompanyPermissions(["company.manage_partnerships"])(
  InvitePrivate
);

/// I18N

const i18n = defineMessages({
  title: {
    id: "InvitePrivate.Title",
    defaultMessage: "Create Offline Account Mapping",
  },
  paragraph: {
    id: "InvitePrivate.Paragraph",
    defaultMessage:
      "Not all your partners are likely to join Reveal? No worries! You can create an account mapping without even inviting them by just uploading a list of customers that they may have shared with you outside of Reveal.",
  },
  create: {
    id: "InvitePrivate.create",
    defaultMessage: "Create",
  },
});

/// CSS

const useStyles = makeStyles()((theme) => ({
  leftContent: {
    zIndex: 1,
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    gap: 16,
    maxWidth: 600,
  },
  leftPanelTile: {
    color: theme.palette.text.primary,
    textAlign: "left",
  },
  title: {
    marginLeft: theme.spacing(1),
    marginBottom: theme.spacing(2),
  },
  rightPanel: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    padding: 50,
  },
  rvlProfile: {
    position: "absolute",
    left: "10%",
    right: "30%",
    top: "10%",
    bottom: "45%",
    background: theme.palette.darkPurple,
    borderRadius: "999px 999px 0 0",
  },
  logo: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    gap: theme.spacing(2),
    margin: 16,
    "& > img": {
      height: 36,
      width: 36,
      borderRadius: 6,
    },
  },
  form: {
    display: "flex",
    flexDirection: "column",
    rowGap: theme.spacing(2),
  },
  actionContainer: {
    display: "flex",
    justifyContent: "space-between",
  },
}));
