import { createSvgIcon } from "@mui/material/utils";

import { SvgIcon } from "./types";

const displayName = "Account";

const Account: SvgIcon = createSvgIcon(
  // Using transform to enlarge from provided viewbox="0 0 16 16"
  // to default "0 0 24 24" from `createSvgIcon` -> `SvgIcon`.
  <g fill="none" transform="matrix(1.5,0,0,1.5,0,0)">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M1.5 13.6149C1.5 13.3387 1.72386 13.1149 2 13.1149H14C14.2761 13.1149 14.5 13.3387 14.5 13.6149C14.5 13.891 14.2761 14.1149 14 14.1149H2C1.72386 14.1149 1.5 13.891 1.5 13.6149Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8 7.11487C8.27614 7.11487 8.5 7.33873 8.5 7.61487V11.6149C8.5 11.891 8.27614 12.1149 8 12.1149C7.72386 12.1149 7.5 11.891 7.5 11.6149V7.61487C7.5 7.33873 7.72386 7.11487 8 7.11487Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3.33325 7.11487C3.60939 7.11487 3.83325 7.33873 3.83325 7.61487V11.6149C3.83325 11.891 3.60939 12.1149 3.33325 12.1149C3.05711 12.1149 2.83325 11.891 2.83325 11.6149V7.61487C2.83325 7.33873 3.05711 7.11487 3.33325 7.11487Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.6667 7.11487C12.9429 7.11487 13.1667 7.33873 13.1667 7.61487V11.6149C13.1667 11.891 12.9429 12.1149 12.6667 12.1149C12.3906 12.1149 12.1667 11.891 12.1667 11.6149V7.61487C12.1667 7.33873 12.3906 7.11487 12.6667 7.11487Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.64902 2.48103C8.2387 2.28407 7.76115 2.28407 7.35083 2.48103L2.52396 4.79792C2.47144 4.82313 2.45125 4.85225 2.44115 4.8753C2.42885 4.9034 2.42383 4.94235 2.43357 4.98516C2.44331 5.02797 2.46469 5.06092 2.48794 5.08092C2.50702 5.09733 2.53783 5.11484 2.59608 5.11484H13.4038C13.462 5.11484 13.4928 5.09733 13.5119 5.08092C13.5352 5.06092 13.5565 5.02797 13.5663 4.98516C13.576 4.94235 13.571 4.9034 13.5587 4.8753C13.5486 4.85225 13.5284 4.82313 13.4759 4.79792L8.64902 2.48103ZM6.9181 1.57951C7.60196 1.25125 8.39788 1.25125 9.08175 1.57951L13.9086 3.8964C15.0277 4.43356 14.6451 6.11484 13.4038 6.11484H2.59608C1.35475 6.11484 0.972148 4.43356 2.09123 3.8964L6.9181 1.57951Z"
      fill="currentColor"
    />
  </g>,
  displayName
);

Account.displayName = displayName;
export default Account;
