import { createReducer } from "@reduxjs/toolkit";

import { checkHighWinRatePresence, loadView, updateView } from "./thunks";
import { PartnershipAnalyticsState } from "./types";

export default createReducer(
  {
    view: null,
    selectedSourceId: null,
    ready: false,
    hasHighWinRatePartners: null,
  } as PartnershipAnalyticsState,
  (builder) =>
    builder
      .addCase(loadView.fulfilled, (state, action) => {
        // @ts-ignore
        state.view = action.payload;
        state.ready = true;
      })
      .addCase(updateView.pending, (state, action) => {
        if (state.view) {
          const view = state.view;
          const { filters, columns, sort } = action.meta.arg;
          if (filters !== undefined) {
            view.filters = filters;
          }
          if (sort !== undefined) {
            view.sort = sort;
          }
          if (columns !== undefined) {
            view.columns = columns;
          }
          state.view = view;
        }
      })
      .addCase(updateView.fulfilled, (state, action) => {
        // @ts-ignore
        state.view = action.payload;
      })
      .addCase(checkHighWinRatePresence.fulfilled, (state, action) => {
        state.hasHighWinRatePartners = action.payload;
      })
);
