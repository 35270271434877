import { Facet } from "components/ui/Facet";
import ToggleBtnGroup from "components/ui/ToggleBtnGroup";
import useSegment from "hooks/useSegment";
import { makeStyles } from "makeStyles";
import {
  defineMessages,
  FormattedMessage,
  MessageDescriptor,
} from "react-intl";
import { PartnersEvent } from "tracking";

export enum PartnershipsFilterType {
  all = "all",
  favorites = "favorites",
  active = "active",
  pendingYou = "pendingYou",
  pendingPartner = "pendingPartner",
  paused = "paused",
  offline = "offline",
}

type Props = {
  currentFilter: PartnershipsFilterType;
  setCurrentFilter: (type: PartnershipsFilterType) => void;
  loading?: boolean;
  facets: { [type: string]: number };
};

const StatusFilter = ({
  currentFilter,
  setCurrentFilter,
  loading,
  facets,
}: Props) => {
  const { classes } = useStyles();
  const { track } = useSegment();

  const updateFilter = (value: PartnershipsFilterType) => {
    setCurrentFilter(value);
    track(PartnersEvent.partnerDashboardFilter, {
      filter: value,
    });
  };

  const fitlerLabel = (
    label: MessageDescriptor,
    filterType: PartnershipsFilterType
  ) => (
    <div className={classes.labelContainer}>
      <span>{<FormattedMessage {...label} />}</span>
      {!loading && (
        <Facet
          value={facets[filterType]}
          isSelected={filterType === currentFilter}
        />
      )}
    </div>
  );

  return (
    <ToggleBtnGroup
      items={[
        {
          label: fitlerLabel(i18n.all, PartnershipsFilterType.all),
          value: PartnershipsFilterType.all,
          tooltip: <FormattedMessage {...i18n.allTooltip} />,
        },
        {
          label: fitlerLabel(i18n.favorites, PartnershipsFilterType.favorites),
          value: PartnershipsFilterType.favorites,
          tooltip: <FormattedMessage {...i18n.favoritesTooltip} />,
        },
        {
          label: fitlerLabel(i18n.active, PartnershipsFilterType.active),
          value: PartnershipsFilterType.active,
          tooltip: <FormattedMessage {...i18n.activesTooltip} />,
        },
        {
          label: fitlerLabel(
            i18n.pendingYou,
            PartnershipsFilterType.pendingYou
          ),
          value: PartnershipsFilterType.pendingYou,
          tooltip: <FormattedMessage {...i18n.pendingYouTooltip} />,
        },
        {
          label: fitlerLabel(
            i18n.pendingPartner,
            PartnershipsFilterType.pendingPartner
          ),
          value: PartnershipsFilterType.pendingPartner,
          tooltip: <FormattedMessage {...i18n.pendingPartnerTooltip} />,
        },
        {
          label: fitlerLabel(i18n.paused, PartnershipsFilterType.paused),
          value: PartnershipsFilterType.paused,
          tooltip: <FormattedMessage {...i18n.pausedTooltip} />,
        },
        {
          label: fitlerLabel(i18n.offline, PartnershipsFilterType.offline),
          value: PartnershipsFilterType.offline,
          tooltip: <FormattedMessage {...i18n.offlineTooltip} />,
        },
      ]}
      setValue={updateFilter}
      value={currentFilter}
      classes={{ root: classes.toggleBtnGroup }}
    />
  );
};

export default StatusFilter;

/// CSS

const useStyles = makeStyles()((theme) => ({
  labelContainer: {
    display: "flex",
    columnGap: theme.spacing(1),
  },
  facet: {
    display: "flex",
    justifyContent: "center",
    minWidth: 22.5,
  },
  toggleBtnGroup: {
    [theme.breakpoints.down("md")]: {
      display: "table",
    },
  },
}));

/// I18N

const i18n = defineMessages({
  all: {
    id: "Partners.StatusFilter.all",
    defaultMessage: "All",
  },
  favorites: {
    id: "Partners.StatusFilter.favorites",
    defaultMessage: "Favorites",
  },
  active: {
    id: "Partners.StatusFilter.active",
    defaultMessage: "Active",
  },
  pendingYou: {
    id: "Partners.StatusFilter.pendingYou",
    defaultMessage: "Pending for You",
  },
  pendingPartner: {
    id: "Partners.StatusFilter.pendingPartner",
    defaultMessage: "Pending for Partner",
  },
  paused: {
    id: "Partners.StatusFilter.paused",
    defaultMessage: "Paused",
  },
  offline: {
    id: "Partners.StatusFilter.offline",
    defaultMessage: "Offline",
  },
  allTooltip: {
    id: "Partners.StatusFilter.allTooltip",
    defaultMessage: "All Partnerships",
  },
  favoritesTooltip: {
    id: "Partners.StatusFilter.favoritesTooltip",
    defaultMessage: "Favorited Partnerships",
  },
  activesTooltip: {
    id: "Partners.StatusFilter.activesTooltip",
    defaultMessage: "Partnerships with account mapping shared on both sides",
  },
  pendingYouTooltip: {
    id: "Partners.StatusFilter.pendingYouTooltip",
    defaultMessage: "Waiting on you to accept invites, share overlaps, etc.",
  },
  pendingPartnerTooltip: {
    id: "Partners.StatusFilter.pendingPartnerTooltip",
    defaultMessage:
      "Waiting on your partner to accept invites, share overlaps, etc.",
  },
  pausedTooltip: {
    id: "Partners.StatusFilter.pausedTooltip",
    defaultMessage: "Partnerships paused by you or your partners",
  },
  offlineTooltip: {
    id: "Partners.StatusFilter.offlineTooltip",
    defaultMessage: "Offline partnerships",
  },
});

export const _private = {
  i18n,
};

/// Helpers

export const partnershipsFilterLabel = {
  all: i18n.all,
  favorites: i18n.favorites,
  active: i18n.active,
  pendingYou: i18n.pendingYou,
  pendingPartner: i18n.pendingPartner,
  paused: i18n.paused,
  offline: i18n.offline,
};
