import { TextInput } from "components/ui/TextInput";
import { defineMessages, useIntl } from "react-intl";

import OperatorAddWidget from "../OperatorAddWidget";
import { SWQLBooleanOperator, SWQLRightValue } from "../SWQLTypes";

type Props = {
  value?: number;
  onChange: (value: SWQLRightValue, extra?: object) => void;
  onAddNode: (operator: SWQLBooleanOperator) => void;
};

const NumericField = ({ onChange, value = 0, onAddNode }: Props) => {
  const intl = useIntl();
  const handleChange = (event: $TSFixMe) => {
    if (event.target.validity.valid) {
      onChange(+event.target.value);
    }
  };
  return (
    <>
      <TextInput
        small
        numeric
        onChange={handleChange}
        placeholder={intl.formatMessage(i18n.placeholder)}
        value={value}
      />
      <OperatorAddWidget onAddNode={onAddNode} />
    </>
  );
};

export default NumericField;

const i18n = defineMessages({
  placeholder: {
    id: "SWQL.SwQLValueFields.NumericField.placeholder",
    defaultMessage: "Type a number…",
  },
});
