import ActionPanel from "components/ui/ActionPanel";
import getFirstValueFromURL from "helpers/getFirstValueFromURL";
import { parse, stringify } from "query-string";
import { useHistory, useLocation } from "react-router-dom";

import { Content } from "./Content";

export const MessageDrawer = () => {
  const history = useHistory();
  const location = useLocation();
  const partnerConnectionId =
    getFirstValueFromURL(location, "discussion") ?? null;

  const handleClose = () => {
    const currentSearch = parse(location.search);
    if ("discussion" in currentSearch) {
      delete currentSearch["discussion"];
    }
    history.replace({ ...location, search: stringify(currentSearch) });
  };

  return (
    <ActionPanel
      isOpen={Boolean(partnerConnectionId)}
      width={900}
      onClose={handleClose}
    >
      {partnerConnectionId ? (
        <Content
          partnerConnectionId={+partnerConnectionId}
          close={handleClose}
        />
      ) : undefined}
    </ActionPanel>
  );
};
