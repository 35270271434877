import { Divider } from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import { Check } from "components/icons";
import Button from "components/ui/Button";
import Popup from "components/ui/Popup";
import { SelectInput } from "components/ui/SelectInput";
import { TextInput } from "components/ui/TextInput";
import { T } from "components/ui/Typography";
import { Formik, FormikHelpers } from "formik";
import forms from "i18n/forms";
import generic from "i18n/generic";
import { makeStyles } from "makeStyles";
import { useEffect } from "react";
import { defineMessages, FormattedMessage, useIntl } from "react-intl";
import useGetIntroSlackChannels from "screens/Frontoffice/shared/hooks/useGetIntroSlackChannels";
import * as Yup from "yup";

import useRequestIntroDialogApiLogic from "../hooks/useRequestIntroDialogApiLogic";
import { IntroRouting } from "./IntroRouting";

export type Values = {
  partnershipId: string;
  contact?: string;
  request: string;
} & {
  [key in string]?: string; // Dynamically add comment keys
};

type Props = {
  accountId: number;
  partnerId?: number;
  isFixedPartner?: boolean;
};

export const RequestIntroDialog = ({
  accountId,
  partnerId,
  isFixedPartner,
}: Props) => {
  const { classes } = useStyles();
  const intl = useIntl();

  const {
    loading,
    account,
    availablePartners,
    partnerName,
    ownerName,
    contacts,
    partnershipId,
    questions,
    setPartnershipId,
    submitGetIntro,
    handleClose,
  } = useRequestIntroDialogApiLogic(
    accountId,
    isFixedPartner && partnerId ? partnerId : null
  );
  const {
    loading: loadingIntroRouting,
    internalChannelName,
    externalChannelName,
    shouldEmailPartnershipManager,
    shouldEmailAccountOwner,
  } = useGetIntroSlackChannels(partnershipId);

  const _contacts = contacts?.filter((contact) => !!contact.label);

  const fixedPartnerValue =
    availablePartners.find((option) => option.value === String(partnerId))
      ?.label ?? null;

  const handleSave = async (values: Values, actions: FormikHelpers<Values>) => {
    submitGetIntro(values);
    handleClose();
    actions.resetForm();
  };

  // Formik helpers

  const initialValues: Values = {
    partnershipId: partnershipId ? String(partnershipId) : "",
    contact: undefined,
    request: "",
    ...Object.fromEntries(
      questions ? questions.map((question) => [question.label, ""]) : []
    ),
  };

  const staticSchema = Yup.object().shape({
    partnershipId: Yup.string().required(() => forms.generic_required),
    contact: Yup.string(),
    request: Yup.string().required(() => forms.generic_required),
  });

  const dynamicSchema: Record<string, Yup.StringSchema> = {};
  questions?.forEach((question) => {
    dynamicSchema[question.label] = Yup.string().required(
      "This field is required"
    );
  });

  const validationSchema = Yup.object().shape({
    ...staticSchema.fields,
    ...dynamicSchema,
  });

  // End formik helpers

  useEffect(() => {
    if (partnerId) {
      setPartnershipId(partnerId);
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={handleSave}
      validationSchema={validationSchema}
      enableReinitialize
    >
      {({
        values,
        handleSubmit,
        handleChange,
        setFieldValue,
        isSubmitting,
        errors,
        touched,
        resetForm,
      }) => (
        <Popup
          variant="secondary"
          title={
            <T className={classes.title}>
              <FormattedMessage {...i18n.title} />
            </T>
          }
          handleClose={handleClose}
          width={640}
          maxWidth={"lg"}
          isOpen
          hideActions
          customActions={
            <>
              <Button
                label={generic.cancel}
                onClick={() => {
                  resetForm();
                  handleClose();
                }}
                variant="tertiary"
              />
              <Button
                LeftIcon={Check}
                label={<FormattedMessage {...i18n.sendRequest} />}
                onClick={handleSubmit}
                loading={isSubmitting}
              />
            </>
          }
        >
          {account === null || loading ? (
            <div className={classes.loaderContainer}>
              <CircularProgress />
            </div>
          ) : (
            <div className={classes.container}>
              <div className={classes.row}>
                <T titleSmall>
                  <FormattedMessage {...i18n.partner} />
                </T>
                {isFixedPartner ? (
                  <TextInput
                    className={classes.input}
                    disabled={true}
                    value={fixedPartnerValue}
                    data-testid="fixedPartnershipId"
                  />
                ) : (
                  <SelectInput
                    variant="tertiary"
                    name="partnershipId"
                    value={values.partnershipId}
                    onChange={(e) => {
                      setPartnershipId(+e.target.value);
                      setFieldValue("contact", undefined);
                      handleChange(e);
                    }}
                    required
                    placeholder={intl.formatMessage(i18n.partnerPlaceholder)}
                    className={classes.input}
                    options={availablePartners}
                    displayEmpty={false}
                    error={Boolean(
                      errors.partnershipId && touched.partnershipId
                    )}
                    inputProps={{ "data-testid": "selectPartnershipId" }}
                  />
                )}
              </div>
              <div className={classes.row}>
                <T>
                  <FormattedMessage {...i18n.partnerOwner} />
                </T>
                {values.partnershipId === "" ? (
                  <T className={classes.placeholder}>
                    <FormattedMessage {...i18n.choosePartner} />
                  </T>
                ) : (
                  <T className={classes.owner}>{ownerName ?? "--"}</T>
                )}
              </div>
              <div className={classes.row}>
                <T>
                  <FormattedMessage {...i18n.introRouting} />
                </T>
                {values.partnershipId === "" ? (
                  <T className={classes.placeholder}>
                    <FormattedMessage {...i18n.choosePartner} />
                  </T>
                ) : loadingIntroRouting ? (
                  <div className={classes.input}>
                    <CircularProgress size={15} />
                  </div>
                ) : (
                  <IntroRouting
                    externalChannelName={externalChannelName}
                    internalChannelName={internalChannelName}
                    ownerName={ownerName}
                    partnerName={partnerName}
                    shouldEmailPartnershipManager={
                      shouldEmailPartnershipManager
                    }
                    shouldEmailAccountOwner={shouldEmailAccountOwner}
                  />
                )}
              </div>
              <Divider className={classes.divider} />
              <div className={classes.row}>
                <T titleSmall>
                  <FormattedMessage {...i18n.toAccount} />
                </T>
                <TextInput
                  className={classes.input}
                  disabled={true}
                  value={account.name}
                />
              </div>
              {_contacts.length > 0 && (
                <div className={classes.row}>
                  <T>
                    <FormattedMessage {...i18n.chooseContact} />
                  </T>
                  {values.partnershipId === "" ? (
                    <T className={classes.placeholder}>
                      <FormattedMessage {...i18n.choosePartner} />
                    </T>
                  ) : (
                    <SelectInput
                      variant="tertiary"
                      name="contact"
                      value={values.contact ?? ""}
                      onChange={handleChange}
                      className={classes.input}
                      options={_contacts}
                      displayEmpty={false}
                      error={Boolean(errors.contact && touched.contact)}
                      inputProps={{ "data-testid": "selectContact" }}
                    />
                  )}
                </div>
              )}
              <div className={classes.row}>
                <T>
                  <FormattedMessage {...i18n.request} />
                </T>
                <TextInput
                  multiline
                  variant="tertiary"
                  rows="5"
                  className={classes.input}
                  placeholder={intl.formatMessage(i18n.requestPlaceholder)}
                  required={true}
                  value={values.request}
                  onChange={handleChange}
                  error={Boolean(errors.request)}
                  name="request"
                />
              </div>
              {questions &&
                questions.length > 0 &&
                questions.map((question) => (
                  <div className={classes.row}>
                    <T>{question.label}</T>
                    <TextInput
                      multiline
                      variant="tertiary"
                      rows="5"
                      className={classes.input}
                      required={true}
                      value={values[question.label]}
                      onChange={handleChange}
                      error={Boolean(errors[question.label])}
                      name={question.label}
                    />
                  </div>
                ))}
            </div>
          )}
        </Popup>
      )}
    </Formik>
  );
};

/// CSS

const useStyles = makeStyles()((theme) => ({
  title: {
    fontSize: 18,
    fontWeight: "bold",
    textAlign: "center",
    marginBottom: 10,
  },
  loaderContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    height: 300,
  },
  container: {
    display: "flex",
    flexDirection: "column",
    rowGap: theme.spacing(1),
  },
  row: {
    display: "flex",
    alignItems: "baseline",
    justifyContent: "space-between",
  },
  input: {
    width: 350,
    minWidth: 350,
  },
  placeholder: {
    color: theme.palette.alpha500,
    padding: "9px 0px",
    width: 350,
  },
  owner: {
    padding: "9px 0px",
    fontWeight: 500,
    width: 350,
  },
  divider: {
    margin: "8px 0px",
  },
}));

/// I18N

const i18n = defineMessages({
  title: {
    id: "mapping360.RequestIntroDialog.title",
    defaultMessage: "Request an introduction",
  },
  partner: {
    id: "mapping360.RequestIntroDialog.partner",
    defaultMessage: "Via Partner",
  },
  partnerPlaceholder: {
    id: "mapping360.RequestIntroDialog.partnerPlaceholder",
    defaultMessage: "Select a Partner",
  },
  partnerOwner: {
    id: "mapping360.RequestIntroDialog.partnerOwner",
    defaultMessage: "Partner Owner",
  },
  introRouting: {
    id: "mapping360.RequestIntroDialog.introRouting",
    defaultMessage: "Intro Routing",
  },
  choosePartner: {
    id: "mapping360.RequestIntroDialog.choosePartner",
    defaultMessage: "Choose a partner first",
  },
  toAccount: {
    id: "mapping360.RequestIntroDialog.toAccount",
    defaultMessage: "To Account",
  },
  chooseContact: {
    id: "mapping360.RequestIntroDialog.chooseContact",
    defaultMessage: "Choose a specific contact (optional)",
  },
  request: {
    id: "mapping360.RequestIntroDialog.request",
    defaultMessage: "Your request",
  },
  requestPlaceholder: {
    id: "mapping360.RequestIntroDialog.requestPlaceholder",
    defaultMessage:
      "Provide context on your deal and clarify your expectations",
  },
  sendRequest: {
    id: "mapping360.RequestIntroDialog.sendRequest",
    defaultMessage: "Send your Intro request",
  },
});

export const _private = {
  i18n,
};
