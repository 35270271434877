import { createReducer } from "@reduxjs/toolkit";

import { setFilters, setSort } from "./actions";
import { DirectoryState } from "./types";

export default createReducer(
  {
    view: {
      filters: [],
      sort: [],
      columns: [], // TODO: add default columns here
    },
  } as DirectoryState,
  (builder) =>
    builder
      .addCase(setFilters, (state, action) => {
        state.view.filters = action.payload;
      })
      .addCase(setSort, (state, action) => {
        state.view.sort = action.payload;
      })
);
