import { T } from "components/ui/Typography";
import { ProviderType } from "config/constants";
import useOauthState from "hooks/useOauthState";
import { defineMessages, FormattedMessage } from "react-intl";
import { useGsheetAuthDialogContent } from "screens/Frontoffice/shared/hooks/useGsheetAuthDialogContent";

import { BaseContent, sharedStyles } from "./BaseContent";

export const GoogleSheetsAuthContent = () => {
  const { cx, classes } = sharedStyles({});
  const { start } = useOauthState();

  const handleClick = () => {
    start(ProviderType.gsheet, next);
  };
  const { button, next } = useGsheetAuthDialogContent({ handleClick });

  return (
    <BaseContent>
      <div>
        <div className={classes.marginBottomLarge}>
          <div className={classes.marginBottomMedium}>
            <div className={classes.flexCenter}>
              <div className={classes.iconContainer}>
                <img
                  className={classes.icon}
                  src="/images/providers/Gsheet.png"
                  alt=""
                />
              </div>
            </div>
          </div>
          <T
            h2
            className={cx(
              classes.contentTitle,
              classes.textCenter,
              classes.marginBottomMedium
            )}
          >
            <FormattedMessage {...i18n.title} />
          </T>
          <T className={classes.textCenter}>
            <FormattedMessage {...i18n.subtitle} />
          </T>
        </div>
        {button}
      </div>
    </BaseContent>
  );
};

const i18n = defineMessages({
  title: {
    id: "GoogleSheetsAuthContent.title",
    defaultMessage: "Add Google Sheet",
  },
  subtitle: {
    id: "GoogleSheetsAuthContent.subtitle",
    defaultMessage:
      "All Google sheets added to Reveal must already be owned or shared by the account you will use to authenticate.",
  },
});
