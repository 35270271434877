import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import { ArrowLeft, ArrowRight } from "components/icons";
import { makeStyles } from "makeStyles";
import {
  Children,
  cloneElement,
  createContext,
  isValidElement,
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import { createPortal } from "react-dom";

import Button from "./Button";
import DividerWithLabel from "./DividerWithLabel";

enum DisplayNames {
  Item = "dot-stepper-item-display-name",
  Label = "dot-stepper-label-display-name",
  Pagination = "dot-stepper-pagination-display-name",
}

const DataContext = createContext<{
  boxRef: React.RefObject<HTMLDivElement>;
  setActivePage: (index: number) => void;
  activePage: number;
  itemsCount: number;
  value?: number;
}>({
  activePage: 0,
  boxRef: { current: null },
  setActivePage: () => {},
  itemsCount: 0,
  value: undefined,
});

export const useDotStepperContext = () => {
  const context = useContext(DataContext);
  if (!context) {
    throw new Error("Error in creating the context");
  }
  return context;
};

type DotStepperItemProps = {
  children: ReactNode;
  disabled?: boolean;
  onClick: (value: number) => void;
  value: number;
};

const DotStepperItem = ({
  children,
  disabled,
  onClick,
  value,
}: DotStepperItemProps) => {
  const { value: contextValue } = useDotStepperContext();
  const isSelected = value === contextValue;
  const { classes } = useStyles({ disabled, isSelected });
  return (
    <Grid item className={classes.gridItem}>
      <Box
        className={classes.itemContainer}
        onClick={() => !disabled && onClick(value)}
      >
        {children}
      </Box>
    </Grid>
  );
};
DotStepperItem.displayName = DisplayNames.Item;

const PAGINATION_COUNT = 5;
const SCROLL_WIDTH = 270;
const Pagination = () => {
  const { boxRef, itemsCount } = useDotStepperContext();
  const pageCount = Math.ceil(itemsCount / PAGINATION_COUNT);
  const { classes } = useStyles({ dotCount: pageCount });
  const [isNextDisabled, setIsNextDisabled] = useState(true);
  const [isBackDisabled, setIsBackDisabled] = useState(true);
  const [shouldRender, setShouldRender] = useState(false);

  const handleNext = () => {
    if (!boxRef.current) return;
    boxRef.current?.scrollBy({ left: SCROLL_WIDTH, behavior: "smooth" });
    if (isBackDisabled) {
      setIsBackDisabled(false);
    }
    if (
      boxRef.current.scrollLeft + SCROLL_WIDTH >=
      boxRef.current.scrollWidth - boxRef.current.clientWidth
    ) {
      setIsNextDisabled(true);
    }
  };

  const handleBack = () => {
    if (!boxRef.current) return;
    boxRef.current.scrollBy({ left: -SCROLL_WIDTH, behavior: "smooth" });
    if (isNextDisabled) {
      setIsNextDisabled(false);
    }
    if (boxRef.current.scrollLeft - SCROLL_WIDTH <= 0) {
      setIsBackDisabled(true);
    }
  };

  useEffect(() => {
    if (!boxRef.current || shouldRender) {
      return;
    }
    setShouldRender(true);

    if (!isNextDisabled) {
      return;
    }
    if (
      boxRef.current.scrollLeft <
      boxRef.current.scrollWidth - boxRef.current.clientWidth
    ) {
      setIsNextDisabled(false);
    }
  }, [boxRef, shouldRender, isNextDisabled]);

  if (!shouldRender) return null;

  return boxRef.current?.parentElement
    ? createPortal(
        <>
          {!isBackDisabled && (
            <Box className={classes.leftButton}>
              <Button
                LeftIcon={ArrowLeft}
                onClick={handleBack}
                size="xSmall"
                variant="senary"
              />
            </Box>
          )}
          {!isNextDisabled && (
            <Box className={classes.rightButton}>
              <Button
                LeftIcon={ArrowRight}
                onClick={handleNext}
                size="xSmall"
                variant="senary"
              />
            </Box>
          )}
        </>,
        boxRef.current.parentElement
      )
    : null;
};
Pagination.displayName = DisplayNames.Pagination;

type DotStepperProps = {
  children: ReactNode | ReactNode[];
  disabled?: boolean;
  value?: number;
};

const DotStepper = ({ children, disabled, value }: DotStepperProps) => {
  const { classes } = useStyles({});
  const [itemsCount, setItemsCount] = useState(0);
  const [activePage, setActivePage] = useState(0);
  const [activeItem, setActiveItem] = useState<number | undefined>(value);
  const boxRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (!!value && value !== activeItem) {
      setActiveItem(value);
    }
  }, [activeItem, value]);

  const changeActivePage = useCallback(
    (value: number) => {
      if (activePage !== value) {
        setActivePage(value);
      }
    },
    [setActivePage, activePage]
  );
  // const indexLimit = useMemo(() => activePage * PAGINATION_COUNT, [activePage]);
  // let itemsListStartingIndex = -1;
  const childrenValidated = Children.map(children, (child) => {
    if (isValidElement(child)) {
      return child;
    }
  });
  const itemsWithProps = Children.map(children, (child, index) => {
    if (isValidElement(child)) {
      if ((child?.type as any)?.displayName === DisplayNames.Item) {
        return cloneElement(child as React.ReactElement<DotStepperItemProps>, {
          disabled,
        });
      }
      return child;
    }
  });

  const itemsChildrenCount = Children.count(
    childrenValidated?.filter(
      (item) => (item as any).type.displayName === DisplayNames.Item
    )
  );
  if (itemsChildrenCount !== itemsCount) {
    setItemsCount(itemsChildrenCount);
  }

  return (
    <DataContext.Provider
      value={{
        activePage,
        boxRef,
        setActivePage: changeActivePage,
        itemsCount,
        value,
      }}
    >
      <Box className={classes.box}>
        <Grid container className={classes.container} ref={boxRef}>
          {itemsWithProps}
        </Grid>
      </Box>
    </DataContext.Provider>
  );
};

DotStepper.Item = DotStepperItem;
DotStepper.Label = DividerWithLabel;
DotStepper.Pagination = Pagination;

export { DotStepper };

// CSS

const useStyles = makeStyles<{
  disabled?: boolean;
  dotCount?: number;
  isSelected?: boolean;
}>()((theme, { disabled, dotCount, isSelected }) => ({
  dot: {
    width: 8,
    height: 8,
    marginRight: 8,
    color: isSelected ? theme.palette.primary.main : theme.palette.taupe,
    cursor: "pointer",
  },
  dotContainer: {
    width: 8,
    height: 8,
    marginRight: 6,
    padding: 0,
    "& .MuiStepLabel-iconContainer": {
      paddingRight: 0,
    },
  },
  leftButton: {
    left: -12,
    position: "absolute",
    top: "50%",
    transform: "translateY(-50%)",
  },
  rightButton: {
    right: -12,
    position: "absolute",
    top: "50%",
    transform: "translateY(-50%)",
  },
  gridItem: {
    minWidth: 257,
    width: 257,
  },
  itemContainer: {
    backgroundColor: theme.palette.ivory,
    borderRadius: 4,
    boxSizing: "border-box",
    height: 83,
    padding: theme.spacing(1.5, 2),
    position: "relative",
    outline: isSelected ? "1.25px solid" : "unset",
    outlineColor: isSelected ? theme.palette.primary.main : "unset",
    "&:hover": !disabled
      ? {
          cursor: "pointer",
          outlineColor: isSelected
            ? theme.palette.primary.main
            : theme.palette.midnight500,
          outlineWidth: 1,
          outlineStyle: "solid",
        }
      : {},
  },
  stepper: {
    justifyContent: (dotCount ?? 0) > 15 ? "start" : "center",
    marginLeft: 24,
    marginRight: 24,
    minWidth: 200,
    overflow: "auto",
    "& .MuiStepConnector-root": {
      display: "none",
    },
  },
  box: {
    borderRadius: 8,
    backgroundColor: "transparent",
    display: "flex",
    width: "100%",
    position: "relative",
  },
  container: {
    alignItems: "center",
    gap: "10px",
    flexWrap: "nowrap",
    height: 100,
    overflowX: "hidden",
    padding: "0 1px",
  },
}));
