import { createSvgIcon } from "@mui/material/utils";

import { SvgIcon } from "./types";

const displayName = "BookmarkCheckedOutlined";

const BookmarkCheckedOutlined: SvgIcon = createSvgIcon(
  <g transform="translate(2.4474453,3.1391154)">
    <path
      d="m 15.552555,14.513849 c 0,0.7625 -0.8191,1.2444 -1.4856,0.8741 l -3.54312,-1.9683 c -0.6040404,-0.3356 -1.3385204,-0.3356 -1.9425704,0 l -3.5430701,1.9683 c -0.66653,0.3703 -1.48564,-0.1116 -1.48564,-0.8741 V 3.5466798 c 0,-0.35362 0.14048,-0.69276 0.39052,-0.94281 0.25005,-0.2500399 0.58919,-0.3905199 0.94281,-0.3905199 h 9.3333705 c 0.3536,0 0.6927,0.14048 0.9428,0.3905199 0.25,0.25005 0.3905,0.58919 0.3905,0.94281 z"
      fill="none"
      strokeMiterlimit="10"
      strokeLinecap="square"
      stroke="currentColor"
    />
    <path
      d="m 8.7253156,8.8216704 c 0,0 3.0871594,-3.3062805 3.3938294,-3.7545502 0.3066,-0.44827 1.3936,0.078 0.7137,0.6903397 L 9.3657056,9.5483294 c -0.41262,0.4682106 -1.08163,0.4682106 -1.49426,0 L 6.1851748,7.6348999 c -0.17684,-0.20066 -0.17684,-0.526 0,-0.72666 0.17684,-0.2006604 0.46356,-0.2006604 0.6404,0 l 1.6862708,1.9134295 c 0.05895,0.06688 0.15452,0.06688 0.21347,1e-6 z"
      fill="currentColor"
    />
  </g>,
  displayName
);

BookmarkCheckedOutlined.displayName = displayName;
export default BookmarkCheckedOutlined;
