import { FilterType } from "screens/Frontoffice/screens/DataTables/shared/types";

export interface MeasureState {
  filters: FilterType[];
}

export enum MeasureActions {
  setFilters = "measure/setFilters",
}

export interface RootStateWithMeasure {
  measure: MeasureState;
}
