import DialogManager from "components/ui/DialogManager";
import { makeStyles } from "makeStyles";
import { useHistory, useLocation } from "react-router-dom";

import NotFoundDialogActions from "../ui/NotFoundDialogActions";
import NotFoundDialogContent from "../ui/NotFoundDialogContent";
import NotFoundDialogTitle from "../ui/NotFoundDialogTitle";

const useStyles = makeStyles()((theme) => ({
  dialogContainer: {
    "& .MuiDialogActions-root": {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      flexDirection: "row",
      padding: theme.spacing(2),
      paddingBottom: theme.spacing(3),
    },
    "& .MuiDialogContent-root": {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      flexDirection: "column",
      padding: theme.spacing(2),
    },
    "& .MuiDialogTitle-root": {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      flexDirection: "column",
      paddingTop: theme.spacing(3),
    },
  },
}));

type Props = {
  error: $TSFixMe;
  open: boolean;
  resetError: $TSFixMeFunction;
  redirect?: string;
};

const NotFoundDialogManager = ({
  open,
  error,
  resetError,
  // @ts-expect-error ts-migrate(2339) FIXME: Property 'redirectToOnClose' does not exist on typ... Remove this comment to see the full error message
  redirectToOnClose,
}: Props) => {
  const { classes } = useStyles();
  const location = useLocation();
  const history = useHistory();

  const handleClose = () => {
    resetError();
    if (redirectToOnClose) {
      history.push(redirectToOnClose);
    } else {
      history.push(location.pathname.split("/").slice(0, -1).join("/"));
    }
  };

  return (
    <DialogManager
      data-testid="dialog-manager"
      fullScreenBreakpoint="sm"
      handleClose={handleClose}
      isOpen={open}
      DialogTitleComponent={<NotFoundDialogTitle handleClose={handleClose} />}
      DialogContentComponent={<NotFoundDialogContent error={error} />}
      DialogActionsComponent={
        <NotFoundDialogActions handleClose={handleClose} />
      }
      modalStyle={classes}
    />
  );
};

export default NotFoundDialogManager;
