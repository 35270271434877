import { createAction } from "@reduxjs/toolkit";
import {
  FilterType,
  SortType,
} from "screens/Frontoffice/screens/DataTables/shared/types";

import { DirectoryActions } from "./types";

export const setFilters = createAction<FilterType[]>(
  DirectoryActions.setFilters
);

export const setSort = createAction<SortType[]>(DirectoryActions.setSort);
