import { lighten } from "@mui/material/styles";
import { Paid } from "components/icons";
import useSegment from "hooks/useSegment";
import { makeStyles } from "makeStyles";
import { useState } from "react";
import { defineMessages, FormattedMessage } from "react-intl";
import { useLocation } from "react-router-dom";
import { PremiumPlanEvent } from "tracking";

import { T } from "../Typography";
import UpdatePlanDialog from "./PremiumPlanWidget/components/UpdatePlanDialog";

export const BANNER_HEIGHT = 44;

const Banner = () => {
  const { track } = useSegment();
  const { pathname } = useLocation();
  const { classes } = useStyles();
  const [dialogOpen, setDialogOpen] = useState(false);

  const handleOpenDialog = () => {
    setDialogOpen(true);
    track(PremiumPlanEvent.bannerLink, { page: pathname });
  };

  return (
    <div className={classes.root}>
      <Paid className={classes.icon} />
      <T>
        <FormattedMessage {...i18n.messageUsers} />
      </T>
      <T bold>
        <span
          className={classes.tooltipLink}
          onClick={() => handleOpenDialog()}
        >
          <FormattedMessage {...i18n.link} />
        </span>
      </T>
      <UpdatePlanDialog
        isOpen={dialogOpen}
        onClose={() => setDialogOpen(false)}
      />
    </div>
  );
};

/// CSS

const useStyles = makeStyles()((theme) => ({
  root: {
    position: "absolute",
    backgroundColor: lighten(theme.palette.gold500 as string, 0.6),
    color: theme.palette.midnight,
    height: BANNER_HEIGHT,
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    columnGap: theme.spacing(0.5),
  },
  icon: {
    height: 16,
    width: 16,
  },
  tooltipLink: {
    cursor: "pointer",
    textDecoration: "underline",
  },
}));

// i18n

const i18n = defineMessages({
  messageUsers: {
    id: "navbar.Banner.messageUsers",
    defaultMessage:
      "You have reached the maximum number of users allowed in your workspace.",
  },
  link: {
    id: "navbar.Banner.link",
    defaultMessage: "Talk to our team to learn more",
  },
});
export default Banner;
