import CompanyAvatar from "components/ui/avatars/CompanyAvatar";
import DropdownItem from "components/ui/Dropdown/components/DropdownItem";
import Tooltip from "components/ui/Tooltip";
import { T } from "components/ui/Typography";
import { makeStyles } from "makeStyles";
import { PartnerRepresentation } from "models/Partnership";
import { defineMessages, FormattedMessage } from "react-intl";

type Props = {
  partner?: PartnerRepresentation | null;
  selectedRowCount: number;
  openModal: () => void;
};

const ReferPartnerStackOption = ({
  partner,
  selectedRowCount,
  openModal,
}: Props) => {
  const { classes } = useStyles();

  const partnerName = partner?.name;

  const onClick = () => {
    openModal();
  };

  return (
    <Tooltip
      title={
        selectedRowCount === 0 ? (
          <FormattedMessage {...i18n.noAccountSelected} />
        ) : selectedRowCount > 1 ? (
          <FormattedMessage {...i18n.selectOneAccount} />
        ) : (
          ""
        )
      }
      placement="top"
    >
      <span>
        <DropdownItem
          fullWidth
          name={
            <>
              <T className={classes.title}>
                <CompanyAvatar
                  src="/images/partners/PartnerStack.png"
                  size="xxs"
                />
                <FormattedMessage
                  {...i18n.referTo}
                  values={{ partner: partnerName }}
                />
              </T>
            </>
          }
          isDisabled={selectedRowCount === 0 || selectedRowCount > 1}
          onChange={onClick}
        />
      </span>
    </Tooltip>
  );
};

export default ReferPartnerStackOption;

/// CSS

const useStyles = makeStyles()(() => ({
  title: {
    display: "flex",
    alignItems: "center",
    columnGap: 6,
  },
}));

// I18N

const i18n = defineMessages({
  noAccountSelected: {
    id: "DataTables.ActionBtn.ReferPartnerStackOption.noAccountSelected",
    defaultMessage: "Select account to refer",
  },
  selectOneAccount: {
    id: "DataTables.ActionBtn.ReferPartnerStackOption.selectOneAccount",
    defaultMessage: "Please select one account only",
  },
  referTo: {
    id: "DataTables.ActionBtn.ReferPartnerStackOption.referTo",
    defaultMessage: "Refer to {partner} via PartnerStack",
  },
});
