import { ClassNameMap } from "@mui/material";
import { IDropdownOption } from "components/ui/Dropdown/components/types";
import { crmProviders, ProviderType } from "config/constants";
import _ from "lodash";
import { Stage } from "models/PartnerConnection";
import Record from "models/Record";
import {
  ReactNode,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { defineMessages, FormattedMessage } from "react-intl";
import { pushNotification } from "redux/notifications/actions";
import { v4 as uuidv4 } from "uuid";

export type CellType = "company" | "partner" | "stage" | "kind";

export type DropdownGroup<T> = {
  title?: string;
  noContentPlaceholder: string;
  noContentMatchingPlaceholder?: string;
  options: T[];
  onClick?: (item: T) => void;
};

export type DropdownCellProps<T> = {
  classes?: ClassNameMap;
  CustomFooter?: ReactNode;
  CustomHeader?: ReactNode;
  buttonClasses?: ClassNameMap;
  optionsGroups:
    | DropdownGroup<T>[]
    | ((value: string) => Promise<DropdownGroup<T>[]>);
  getLabel: (option: T) => string | JSX.Element;
  isDisabled?: (option: T) => boolean;
  isLoading?: boolean;
  selectedItem?: T | null;
  unselectedText: string;
  onChange: (newSelection: IDropdownOption) => void;
  withSearch?: boolean;
  searchPlaceholder?: string;
  showOnHoverOnly?: boolean;
  onFirstOpenCallback?: () => void;
  cellType?: CellType;
  size?: number;
  onCloseCallback?: () => void;
};

export const useDropdownCell = <T,>({
  optionsGroups: providedOptionsGroups,
  getLabel,
  selectedItem,
  isDisabled = () => false,
  isLoading,
  onChange,
  onFirstOpenCallback,
  cellType,
  CustomFooter,
}: DropdownCellProps<T>) => {
  const isAsync = !_.isArray(providedOptionsGroups);

  const searchInputRef = useRef<HTMLInputElement>(null);
  const firstOpenRef = useRef(true);

  const [optionsGroups, setOptionsGroups] = useState<DropdownGroup<T>[]>(
    !_.isArray(providedOptionsGroups) ? [] : providedOptionsGroups
  );
  const [open, setOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const [values, setValues] = useState<string[] | null>([]);
  const [asyncCompanySearch, setAsyncCompanySearch] = useState("");
  const [isLoadingOptions, setIsLoadingOptions] = useState(isLoading);

  // async options for company cell dropdown
  useEffect(() => {
    let active = true;
    if (!_.isArray(providedOptionsGroups) && cellType === "company") {
      setIsLoadingOptions(true);
      providedOptionsGroups(asyncCompanySearch)
        .then((options: DropdownGroup<T>[]) => {
          if (active && options?.length) {
            setOptionsGroups(options);
          }
        })
        .catch((error) => pushNotification(error))
        .finally(() => setIsLoadingOptions(false));
      return () => {
        active = false;
      };
    }
  }, [providedOptionsGroups, asyncCompanySearch, cellType]);

  useEffect(() => {
    if (_.isArray(providedOptionsGroups)) {
      setOptionsGroups(providedOptionsGroups);
    }
  }, [providedOptionsGroups]);

  useEffect(() => {
    setIsLoadingOptions(isLoading);
  }, [isLoading]);

  const handleOpen = (
    event: React.MouseEvent<HTMLDivElement | HTMLButtonElement>
  ) => {
    event.preventDefault();
    event.stopPropagation();
    setOpen(true);
    setAnchorEl(event.currentTarget);
    if (firstOpenRef.current) {
      firstOpenRef.current = false;
      onFirstOpenCallback && onFirstOpenCallback();
    }
    setTimeout(() => {
      if (searchInputRef.current) {
        searchInputRef.current.focus();
      }
    }, 50);
  };

  const handleClose = () => {
    setOpen(false);
    setAnchorEl(null);
  };

  const defineCompanyTooltipMessage = (o: T): ReactNode | string =>
    isDisabled(o) ? (
      <FormattedMessage
        {...i18n.alreadyUnassignedInPipeline}
        values={{
          accountName: (o as Partial<Record>).name,
        }}
      />
    ) : (
      ""
    );

  const dropdownOptions: IDropdownOption[] = useMemo(() => {
    if (selectedItem) {
      const valueId = ((selectedItem as unknown) as IDropdownOption)?.id?.toString();
      cellType === "stage" || cellType === "kind"
        ? setValues([String(selectedItem)])
        : setValues([valueId]);
    } else {
      setValues([]);
    }
    if (cellType === "stage" && optionsGroups) {
      return optionsGroups[0]?.options?.map((o) => ({
        id: String(o),
        name: String(o),
        label: getLabel(o),
        onClick:
          ((o as unknown) as Stage) === Stage.introMade
            ? optionsGroups[0]?.onClick?.(o)
            : undefined,
        rightIcons:
          ((o as unknown) as Stage) === Stage.introMade
            ? ["ChevronRight"]
            : undefined,
      }));
    } else if (cellType === "kind" && optionsGroups) {
      return optionsGroups[0]?.options?.map((o) => ({
        id: String(o),
        label: getLabel(o),
        name: String(o).replace(
          /(^\w|\s\w)(\S*)/g,
          (_, m1, m2) => m1.toUpperCase() + m2.toLowerCase()
        ),
      }));
    } else if (cellType === "company" && optionsGroups) {
      return optionsGroups[0]?.options?.map((o) => {
        let option = o as Partial<Record>;
        return {
          ...o,
          id: String(option.id),
          name: option.name,
          logo: crmProviders[option.provider as ProviderType]?.logo,
          disabled: isDisabled(o),
          tooltipMessage: defineCompanyTooltipMessage(o),
        };
      });
    }
    // type partner
    else {
      return optionsGroups.map((o: Partial<Record>) => ({
        id: uuidv4(),
        name: o.title,
        group: o.options,
      }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cellType, optionsGroups, selectedItem, open]);

  const onItemChange = (item: string[] | null) => {
    setValues(item);
    if (item) {
      const option =
        cellType === "partner"
          ? dropdownOptions
              .reduce((acc: IDropdownOption[], curr) => {
                curr?.group?.length && acc.push(...curr?.group);
                return acc;
              }, [])
              .find((o) => o.id === item[0])
          : dropdownOptions?.find((o) => o.id === item[0]);
      if (option) {
        onChange(option);
      }
    }
    if (!CustomFooter) {
      handleClose();
    }
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedOnSearch = useCallback(
    _.debounce((query: string) => setAsyncCompanySearch(query), 300, {
      trailing: true,
    }),
    []
  );

  return {
    handleOpen,
    handleClose,
    onItemChange,
    searchInputRef,
    open,
    anchorEl,
    values,
    isLoadingOptions,
    dropdownOptions,
    debouncedOnSearch,
    isAsync,
  };
};

const i18n = defineMessages({
  alreadyInPipeline: {
    id: "CellFormatters.alreadyInPipeline",
    defaultMessage: "{partnerName} x {accountName} is already in Collaborate",
  },
  alreadyUnassignedInPipeline: {
    id: "CellFormatters.alreadyUnassignedInPipeline",
    defaultMessage:
      "{accountName} was already added to Collaborate without an assigned partner",
  },
});
