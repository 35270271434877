import _ from "lodash";
import User from "models/User";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { retreive } from "redux/api/thunks";
import { RevealStore } from "redux/typing";

export const useUser = ({ id }: { id?: number }) => {
  const dispatch = useDispatch();
  const user: User | undefined = useSelector((state: RevealStore) =>
    _.get(_.get(state, "api.entities.users"), id ?? "")
  );

  const fetchUser = async (id: number) => {
    dispatch(
      retreive({
        id: id,
        type: "users",
      })
    );
  };

  useEffect(() => {
    if (!user && id) {
      fetchUser(id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, user]);

  return user;
};
