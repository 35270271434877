import { createSvgIcon } from "@mui/material/utils";

import { SvgIcon } from "./types";

const displayName = "Download";

const Download: SvgIcon = createSvgIcon(
  // Using transform to enlarge from provided viewbox="0 0 12 12"
  // to default "0 0 24 24" from `createSvgIcon` -> `SvgIcon`.
  <g fill="none" transform="matrix(2,0,0,2,0,0)">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6.00066 0.75C6.26698 0.75 6.48289 0.967361 6.48289 1.23549V6.86053L8.55179 4.77764C8.74011 4.58805 9.04544 4.58805 9.23376 4.77764C9.42208 4.96724 9.42208 5.27463 9.23376 5.46423L6.79626 7.91821C6.35684 8.3606 5.64441 8.3606 5.20499 7.91821L2.76624 5.46296C2.57792 5.27337 2.57792 4.96597 2.76624 4.77638C2.95456 4.58678 3.25989 4.58678 3.44822 4.77638L5.51843 6.86059V1.23549C5.51843 0.967361 5.73433 0.75 6.00066 0.75ZM1.33929 8.01136C1.33929 7.72895 1.12342 7.5 0.857143 7.5C0.590863 7.5 0.375 7.72895 0.375 8.01136V9.375C0.375 10.4105 1.1665 11.25 2.14286 11.25H9.85714C10.8335 11.25 11.625 10.4105 11.625 9.375V8.01136C11.625 7.72895 11.4091 7.5 11.1429 7.5C10.8766 7.5 10.6607 7.72895 10.6607 8.01136V9.375C10.6607 9.8457 10.3009 10.2273 9.85714 10.2273H2.14286C1.69906 10.2273 1.33929 9.8457 1.33929 9.375V8.01136Z"
      fill="currentColor"
    />
  </g>,
  displayName
);

Download.displayName = displayName;
export default Download;
