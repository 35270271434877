import Grid from "@mui/material/Grid";
import withCompanyPermissions from "components/hoc/withCompanyPermissions";
import { WithUserProfile } from "components/hoc/withUserProfile";
import useSegment from "hooks/useSegment";
import useUserProfile from "hooks/useUserProfile";
import _ from "lodash";
import { makeStyles } from "makeStyles";
import { PayingFeature } from "models/CompanyPayingFeatureSubscription";
import { SuggestedPartnerSource } from "models/SuggestedPartner";
import { useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { matchPath, Redirect, RouteComponentProps } from "react-router";
import { selectActivePayingFeatures } from "redux/api/selectors";
import { selectDashboardData } from "redux/dashboard/selectors";
import { initializePartnershipsAndCompanyNames } from "redux/init/thunks";
import { selectSuggestions } from "redux/suggestedPartners/selectors";
import { initializeSuggestedPartners } from "redux/suggestedPartners/thunks";
import { selectUpsidesData } from "redux/upsides/selectors";
import { loadPartnershipUpsidePotential } from "redux/upsides/thunks";
import { InviteURLEvent, PartnersEvent } from "tracking";

import { InvitationReviewModal } from "./InvitationReviewModal";
import { InvitationReviewModalOnboarding } from "./InvitationReviewModalOnboarding";
import InvitationURLModal from "./InvitationURLModal";
import PartnershipDetailDialog from "./PartnershipDetailDialog";
import Partnerships from "./Partnerships";
import SuggestedPartners from "./SuggestedPartners";

type Params = {
  recordId: string;
  dialogType: string;
};

const DEMO_ID = "demo";

const showGenericDialog = ({ recordId, dialogType }: Params) =>
  recordId !== DEMO_ID && dialogType === "invitations";

const Partners = ({ location, match }: RouteComponentProps<Params>) => {
  const { classes } = useStyles();
  const dispatch = useDispatch();
  const { track } = useSegment();
  const {
    partnerships,
    hasActiveOrCreatedPartnerships,
    hasSources,
    ready,
  } = useSelector(selectDashboardData);
  const { suggestions } = useSelector(selectSuggestions);
  const { profile } = useUserProfile();
  const { recordId, dialogType } = match.params;
  const payingFeatures = useSelector(selectActivePayingFeatures);
  const { status } = useSelector(selectUpsidesData);
  const hasInvitation = matchPath(
    location.pathname,
    "/partnerships/i/:invitationId"
  );
  const invitationId = hasInvitation
    ? _.get(hasInvitation.params, "invitationId").split("?")[0]
    : null;
  const invitationReview = matchPath(
    location.pathname,
    "/partnerships/ir/:partnershipId"
  );
  const partnershipId = invitationReview
    ? _.get(invitationReview.params, "partnershipId").split("?")[0]
    : null;

  useEffect(() => {
    if (ready === null) {
      dispatch(initializePartnershipsAndCompanyNames());
    } else if (ready && status === null) {
      const hasAdvancedAnalyticsPayingFeature = payingFeatures.includes(
        PayingFeature.AdvancedAnalytics
      );
      dispatch(
        loadPartnershipUpsidePotential(hasAdvancedAnalyticsPayingFeature)
      );
    }
  }, [ready, status, payingFeatures, dispatch]);

  useEffect(() => {
    dispatch(initializeSuggestedPartners({ partnerOnReveal: true }));
  }, [dispatch]);

  useEffect(() => {
    if (invitationId) {
      track(InviteURLEvent.signIn, { invitationId });
    }
  }, [invitationId]); // eslint-disable-line react-hooks/exhaustive-deps

  // Computing if we need to redirect old links from emails to new pages
  const needOldDialogRedirect =
    ["#account-mapping", "#overview", "#invitations"].includes(location.hash) &&
    recordId &&
    dialogType === undefined;

  useEffect(() => {
    if (!needOldDialogRedirect && location.hash !== "#invite") {
      track(PartnersEvent.viewDashboard);
    }
  }, [location.hash]); // eslint-disable-line react-hooks/exhaustive-deps

  const suggestionsWithoutHighPotentialOverlap = useMemo(
    () =>
      suggestions.filter(
        (suggestion) =>
          suggestion.source !== SuggestedPartnerSource.HighPotentialOverlap
      ),
    [suggestions]
  );

  // Keep that for a while after the re-design so emails are properly redirected
  if (needOldDialogRedirect) {
    const hash = location.hash === "#account-mapping" ? "" : location.hash;
    return (
      <Redirect
        to={`/partnerships/${recordId}/${location.hash.slice(1)}${hash}`}
      />
    );
  } else if (location.hash === "#invite") {
    return <Redirect to={`/invite/?${location.search.slice(1)}`} />;
  }
  return (
    <>
      <Grid container direction="column" className={classes.root}>
        {suggestionsWithoutHighPotentialOverlap.length > 0 ? (
          <SuggestedPartners
            suggestions={suggestionsWithoutHighPotentialOverlap}
          />
        ) : null}
        <Partnerships
          loading={!ready}
          partnerships={partnerships}
          hasActiveOrCreatedPartnerships={hasActiveOrCreatedPartnerships}
          hasSources={hasSources}
        />
      </Grid>
      {showGenericDialog(match.params) && (
        <PartnershipDetailDialog recordId={+recordId} />
      )}
      {invitationId !== null && (
        <InvitationURLModal invitationId={invitationId} />
      )}
      {partnershipId !== null &&
        profile.canInvitePartner(payingFeatures) &&
        ready &&
        !hasActiveOrCreatedPartnerships && (
          <InvitationReviewModalOnboarding partnershipId={partnershipId} />
        )}
      {partnershipId !== null &&
        profile.canInvitePartner(payingFeatures) &&
        ready &&
        hasActiveOrCreatedPartnerships && (
          <InvitationReviewModal partnershipId={partnershipId} />
        )}
    </>
  );
};

export default withCompanyPermissions<
  WithUserProfile & RouteComponentProps<Params>
>(["company.partners_access"])(Partners);

/// CSS

const useStyles = makeStyles()((theme) => ({
  root: {
    padding: theme.spacing(2.5),
    maxWidth: "100%",
    gap: theme.spacing(2.5),
  },
}));
