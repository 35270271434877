import { createSelector } from "@reduxjs/toolkit";
import Partnership from "models/Partnership";
import { RevealStore } from "redux/typing";

export const selectOnboardingPartnershipsToAcceptData = createSelector(
  (state: RevealStore) => state.api.entities["partnerships"] ?? {},
  (state: RevealStore) => state.onboarding,
  (partnerships, { notAcceptedReady, notAcceptedPartnershipIds }) => {
    return {
      partnerships: notAcceptedPartnershipIds
        .map((id: number) => partnerships[id])
        .filter(Boolean)
        .filter(
          (partnership: Partnership) =>
            partnership.hasInitiatorCompany() &&
            partnership.hasDestCompany() &&
            partnership.initiatorCompany.id !== partnership.destCompany?.id
        )
        .sort(
          (partnership, otherPartnership) =>
            (otherPartnership.insights?.matches ?? 0) -
            (partnership.insights?.matches ?? 0)
        ),
      ready: notAcceptedReady,
    };
  }
);

export const selectOnboardingPartnershipsToShareData = (
  onlyFavorites: Boolean
) =>
  createSelector(
    (state: RevealStore) => state.api.entities["partnerships"] ?? {},
    (state: RevealStore) => state.user.profile.data,
    (state: RevealStore) => state.onboarding,
    (partnerships, user, { notSharedReady, notSharedPartnershipIds }) => {
      return {
        partnerships: notSharedPartnershipIds
          .map((id: number) => partnerships[id])
          .filter(Boolean)
          .filter((partnership: Partnership) =>
            onlyFavorites ? partnership.watcherIds.includes(user.id) : true
          )
          .sort(
            (partnership, otherPartnership) =>
              (otherPartnership.insights?.matches ?? 0) -
              (partnership.insights?.matches ?? 0)
          ),
        ready: notSharedReady,
      };
    }
  );
