import { Plus } from "components/icons";
import Button from "components/ui/Button";
import Checkbox from "components/ui/Checkbox";
import Dropdown from "components/ui/Dropdown/components/Dropdown";
import { IDropdownOption } from "components/ui/Dropdown/components/types";
import Tag from "components/ui/Tag";
import { TextInput } from "components/ui/TextInput";
import _ from "lodash";
import { makeStyles } from "makeStyles";
import React, { useCallback, useState } from "react";
import {
  FilterType,
  MatchFieldType,
} from "screens/Frontoffice/screens/DataTables/shared/types";

type Props = {
  disabled?: boolean;
  fieldOptions?: Record<string, string | number>;
  fieldType: MatchFieldType;
  filter: FilterType;
  onChange: (value: any) => void;
};

export const ValueField = ({
  disabled = false,
  fieldType,
  filter,
  onChange,
  fieldOptions,
}: Props) => {
  const handleChange = useCallback(
    (event: React.FormEvent<HTMLInputElement>) => {
      if (fieldType === MatchFieldType.BOOLEAN) {
        onChange(event.currentTarget.checked);
      } else {
        const value = event.currentTarget.value;
        onChange(value);
      }
    },
    [onChange, fieldType]
  );

  switch (fieldType) {
    case MatchFieldType.REFERENCES_USER:
    case MatchFieldType.REFERENCES_ACCOUNT:
    case MatchFieldType.REFERENCES_CONTACT:
    case MatchFieldType.REFERENCES_OPPORTUNITY:
    case MatchFieldType.TEXT:
      return <TextInput value={filter.value} onChange={handleChange} small />;
    case MatchFieldType.CURRENCY:
      return (
        <TextInput
          value={filter.value}
          onChange={handleChange}
          numeric
          type="number"
          currency="$"
          small
          disabled={disabled}
        />
      );
    case MatchFieldType.INTEGER:
      return (
        <TextInput
          value={filter.value}
          onChange={handleChange}
          numeric
          type="number"
          small
          disabled={disabled}
        />
      );
    case MatchFieldType.NUMBER:
      return (
        <TextInput
          value={filter.value}
          onChange={handleChange}
          numeric
          type="number"
          small
          inputProps={{
            step: 0.01,
          }}
          disabled={disabled}
        />
      );
    case MatchFieldType.BOOLEAN:
      return (
        <Checkbox
          checked={!!filter.value}
          onChange={handleChange}
          disabled={disabled}
        />
      );
    case MatchFieldType.PICKLIST:
    case MatchFieldType.SINGLE_CHOICE_PICKLIST:
    case MatchFieldType.USER:
      const options = _(fieldOptions || {})
        .toPairs()
        .map(([value, label]) => ({
          value,
          label: String(label),
        }))
        .value();
      return (
        <Picklist
          value={_.isArray(filter.value) ? filter.value : []}
          onChange={onChange}
          options={options}
          multi={fieldType !== MatchFieldType.SINGLE_CHOICE_PICKLIST}
          disabled={disabled}
        />
      );
    case MatchFieldType.DATE:
    case MatchFieldType.DATETIME:
      return (
        <TextInput
          value={filter.value}
          onChange={handleChange}
          numeric
          type="date"
          small
          disabled={disabled}
        />
      );
    default:
      return null;
  }
};

export default ValueField;

/// Internal components

type PicklistProps = {
  disabled: boolean;
  options: { label: string; value: string | number }[];
  value: (string | number)[];
  onChange: (value: (string | number)[]) => void;
  multi: boolean;
};

const Picklist = ({
  disabled,
  options,
  value,
  multi,
  onChange,
}: PicklistProps) => {
  const { classes } = usePicklistStyles();
  const [anchorEl, setAnchorEl] = useState<Element | null>(null);
  const tags = options.filter(({ value: _v }) => value.includes(_v));

  const dropdownOptions: IDropdownOption[] = options
    .filter((option) => !value.includes(option.value))
    .map(({ value, label }) => ({
      id: String(value),
      name: label,
    }));

  const onDelete = (toRemove: string | number) => () => {
    onChange(value.filter((val) => val !== toRemove));
  };

  const onAddValue = useCallback(
    (toAdd: (string | number)[] | null) => {
      if (toAdd) onChange([...value, ...toAdd]);
    },
    [value, onChange]
  );

  const openDropdown = useCallback(
    (event: React.SyntheticEvent) => setAnchorEl(event.currentTarget),
    []
  );

  const closeDropdown = useCallback(() => setAnchorEl(null), []);

  const remainingOptionCount = multi
    ? Object.entries(options).length - value.length
    : value.length > 0
    ? 0
    : 1;

  return (
    <div className={classes.container}>
      {tags.map(({ label, value }) => (
        <Tag
          key={value}
          label={label}
          classes={{ root: classes.tag }}
          onDelete={onDelete(value)}
          disabled={disabled}
        />
      ))}
      {remainingOptionCount && !disabled && (
        <Button LeftIcon={Plus} size="small" onClick={openDropdown} />
      )}

      <Dropdown
        options={dropdownOptions}
        anchorEl={anchorEl}
        onClose={closeDropdown}
        open={!!anchorEl}
        size="small"
        onChange={onAddValue}
      />
    </div>
  );
};

/// CSS

const usePicklistStyles = makeStyles()((theme) => ({
  container: {
    backgroundColor: theme.palette.taupe,
    borderRadius: 16,
    padding: 2,
    display: "flex",
    flexWrap: "wrap",
    gap: 4,
    columnGap: 4,
  },
  tag: {
    backgroundColor: theme.palette.ivory,
  },
}));
