import { constant, guard, nullable, number, object, string } from "decoders";

import Record from "./Record";
import { JSONAPIResourceOrRecord } from "./types";

const apiPartnershipAnalyticsQuickFiltersMetricsDecoder = object({
  type: constant("partnership_analytics_quick_filters_metrics" as const),
  id: string,
  attributes: object({
    all: nullable(number),
    favorites: nullable(number),
    high_win_rate_partners: nullable(number),
    my_partners: nullable(number),
  }),
});

const apiPartnershipAnalyticsQuickFiltersMetricsGuard = guard(
  apiPartnershipAnalyticsQuickFiltersMetricsDecoder
);

class PartnershipAnalyticsQuickFiltersMetrics extends Record<"partnership_analytics_quick_filters_metrics"> {
  all: number | null;
  favorites: number | null;
  highWinRatePartners: number | null;
  myPartners: number | null;

  constructor(
    resource: JSONAPIResourceOrRecord<"partnership_analytics_quick_filters_metrics">
  ) {
    super(resource);
    const { attributes } = apiPartnershipAnalyticsQuickFiltersMetricsGuard(
      resource
    );
    this.all = attributes.all;
    this.favorites = attributes.favorites;
    this.highWinRatePartners = attributes.high_win_rate_partners;
    this.myPartners = attributes.my_partners;
  }
}

export default PartnershipAnalyticsQuickFiltersMetrics;
