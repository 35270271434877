import { Box, Grid } from "@mui/material";
import { Lock } from "components/icons";
import Toggle from "components/ui/Toggle";
import Tooltip from "components/ui/Tooltip";
import { T } from "components/ui/Typography";
import useUserProfile from "hooks/useUserProfile";
import { makeStyles } from "makeStyles";
import Partnership, { DataShare } from "models/Partnership";
import { defineMessages, FormattedMessage } from "react-intl";

type Props = {
  onChange: (dataShare: DataShare) => void;
  sharingStatus?: DataShare | null;
};

export const InvitationReviewSharingSettings = ({
  onChange,
  sharingStatus,
}: Props) => {
  const { cx, classes } = useStyles();
  const { profile } = useUserProfile();
  const handleToggleOverlappingAccounts = () => {
    if (!sharingStatus) {
      return;
    }
    onChange(Partnership.toggleSharingOverlapping(sharingStatus));
  };
  const handleToggleNewPropspects = () => {
    if (!sharingStatus) {
      return;
    }
    onChange(Partnership.toggleSharingWhitespace(sharingStatus));
  };
  return (
    <Grid container className={classes.container}>
      <Grid item xs={12} className={classes.marginBottom}>
        <T bold>
          <FormattedMessage {...i18n.title} />
        </T>
      </Grid>
      <Grid
        item
        className={cx(classes.dataSharingBlock, classes.purpleGradient)}
      >
        <T h3 bold className={classes.marginBottom}>
          <FormattedMessage {...i18n.overviewOnly} />
        </T>
        <Box className={classes.textWithIcon}>
          <Lock className={classes.icon} />
          <T className={classes.lightText}>
            <FormattedMessage {...i18n.notSharing} />
          </T>
        </Box>
      </Grid>
      <Grid
        item
        className={cx(classes.dataSharingBlock, {
          [classes.purpleGradient]:
            !!sharingStatus && Partnership.isSharingOverlapping(sharingStatus),
        })}
      >
        <T h3 bold className={classes.marginBottom}>
          <FormattedMessage {...i18n.shareOverlaps} />
        </T>
        <Tooltip
          hidden={profile.canManagePartnerships}
          title={
            <T>
              <FormattedMessage {...i18n.shareDataPermission} />
            </T>
          }
        >
          <Box className={classes.textWithIcon}>
            <Toggle
              checked={
                !!sharingStatus &&
                Partnership.isSharingOverlapping(sharingStatus)
              }
              className={classes.toggle}
              disabled={!profile.canManagePartnerships}
              handleChange={handleToggleOverlappingAccounts}
            />
            <T className={classes.lightText}>
              <FormattedMessage {...i18n.bothCrm} />
            </T>
          </Box>
        </Tooltip>
      </Grid>
      <Grid
        item
        className={cx(classes.dataSharingBlock, {
          [classes.purpleGradient]:
            !!sharingStatus && Partnership.isSharingWhitespace(sharingStatus),
        })}
      >
        <T h3 bold className={classes.marginBottom}>
          <FormattedMessage {...i18n.shareNewProspects} />
        </T>
        <Tooltip
          hidden={profile.canManagePartnerships}
          title={
            <T>
              <FormattedMessage {...i18n.shareDataPermission} />
            </T>
          }
        >
          <Box className={classes.textWithIcon}>
            <Toggle
              checked={
                !!sharingStatus &&
                Partnership.isSharingWhitespace(sharingStatus)
              }
              className={classes.toggle}
              disabled={!profile.canManagePartnerships}
              handleChange={handleToggleNewPropspects}
            />
            <T className={classes.lightText}>
              <FormattedMessage {...i18n.completeList} />
            </T>
          </Box>
        </Tooltip>
      </Grid>
    </Grid>
  );
};

const i18n = defineMessages({
  bothCrm: {
    id: "partners.InvitationReviewSharingSettings.bothCrm",
    defaultMessage: "Share accounts that are in both CRMs",
  },
  completeList: {
    id: "partners.InvitationReviewSharingSettings.completeList",
    defaultMessage: "You and your partner share complete list of customers",
  },
  notSharing: {
    id: "partners.InvitationReviewSharingSettings.notSharing",
    defaultMessage:
      "Access high level statistics - you will not be sharing lists of accounts",
  },
  overviewOnly: {
    id: "partners.InvitationReviewSharingSettings.overviewOnly",
    defaultMessage: "Assess Partner",
  },
  shareDataPermission: {
    id: "partners.InvitationReviewSharingSettings.shareDataPermission",
    defaultMessage:
      "Insufficient permissions - Ask your workspace administrator.",
  },
  shareNewProspects: {
    id: "partners.InvitationReviewSharingSettings.shareNewProspects",
    defaultMessage: "Share new prospects",
  },
  shareOverlaps: {
    id: "partners.InvitationReviewSharingSettings.shareOverlaps",
    defaultMessage: "Share overlaps",
  },
  title: {
    id: "partners.InvitationReviewSharingSettings.title",
    defaultMessage: "Connect safely: by default you're not sharing any data.",
  },
});

const useStyles = makeStyles()((theme) => ({
  container: {
    borderRadius: 8,
    columnGap: theme.spacing(1),
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "space-around",
    position: "relative",
    width: "100%",
    zIndex: 0,
  },
  dataSharingBlock: {
    background: theme.palette.ivory,
    border: `solid 1px ${theme.palette.taupe}`,
    borderRadius: 8,
    flex: "1 0 32%",
    padding: theme.spacing(2.5),
    position: "relative",
  },
  purpleGradient: {
    backgroundClip: "padding-box",
    border: "solid 1px transparent",
    "&:before": {
      content: '""',
      position: "absolute",
      top: 0,
      right: 0,
      bottom: 0,
      left: 0,
      zIndex: -1,
      margin: -1,
      borderRadius: "inherit",
      background: theme.palette.gradients.darkPurpleToGreen,
    },
  },
  icon: {
    height: 16,
    width: 12,
  },
  lightText: {
    color: theme.palette.alpha750,
  },
  marginBottom: {
    marginBottom: theme.spacing(2),
  },
  textWithIcon: {
    alignItems: "flexStart",
    display: "flex",
    gap: 6,
  },
  toggle: { marginTop: 3 },
}));
