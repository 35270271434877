import dayjs, { Dayjs } from "dayjs";
import { FilterType } from "screens/Frontoffice/screens/DataTables/shared/types";

import { DisplaySinceFilterFieldname, SinceOption } from "../../shared/types";

export const isDisplaySinceFilter = (filter: FilterType) =>
  (Object.values(DisplaySinceFilterFieldname) as string[]).includes(
    filter.fieldname
  );
export const getDisplaySinceFilter = (filters: FilterType[]) =>
  filters.find((filter) => isDisplaySinceFilter(filter)) as FilterType;
export const getFiltersWithoutDisplaySince = (filters: FilterType[]) =>
  filters.filter((filter) => !isDisplaySinceFilter(filter));

export const getSinceDate = (
  lastVisitedAt: Date | null | undefined,
  sinceOption: SinceOption
) => {
  const formatDate = (date: Dayjs) => date.toDate().toISOString().slice(0, 10);
  switch (sinceOption) {
    case SinceOption.LastVisit:
      return lastVisitedAt
        ? lastVisitedAt?.toISOString().slice(0, 10)
        : formatDate(dayjs());
    case SinceOption.Last7Days:
      return formatDate(dayjs().subtract(7, "days"));
    case SinceOption.Last15Days:
      return formatDate(dayjs().subtract(15, "days"));
    case SinceOption.Last30Days:
      return formatDate(dayjs().subtract(30, "days"));
    case SinceOption.Last3Months:
      return formatDate(dayjs().subtract(3, "months"));
    case SinceOption.Last6Months:
      return formatDate(dayjs().subtract(6, "months"));
  }
};
