import { companySizeGreaterThan50 } from "helpers/bookMeetingUtils";
import usePushNotification from "hooks/usePushNotification";
import useSegment from "hooks/useSegment";
import useUserProfile from "hooks/useUserProfile";
import _ from "lodash";
import {
  COMPANY_ECOSYSTEM_TO_CHILI_PIPER_VALUES,
  CompanyEcosystem,
} from "models/Company";
import { useCallback, useEffect, useMemo } from "react";
import { defineMessages } from "react-intl";
import { useLocation } from "react-router";

type Props = {
  isOpen: boolean;
  onClose: () => void;
};

const UpdatePlanDialog = ({ isOpen, onClose }: Props) => {
  const location = useLocation();
  const { track } = useSegment();
  const { profile } = useUserProfile();
  const pushNotification = usePushNotification();

  const isCompanySizeGreaterThan50 = useMemo(
    () => companySizeGreaterThan50(profile),
    [profile]
  );
  const isViral = _.get(profile, "company.viral");

  const handleSuccess = useCallback(() => {
    pushNotification({ ...i18n.eventScheduled });
    track("Booked sales meeting", {
      page: location.pathname,
    });
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    isOpen &&
      (window as any).ChiliPiper.submit(
        "reveal",
        isCompanySizeGreaterThan50 || isViral
          ? "inbound_router_onboard"
          : "inbound_router_group_onboard",
        {
          closeOnOutside: true,
          lead: {
            Campaign_content__c: "Upgrade Request",
            Company: profile.companyName,
            Email: profile.email,
            FirstName: profile.firstName,
            LastName: profile.lastName,
            LeadSource: "Chili Piper",
            Reveal_Ecosystem__c:
              COMPANY_ECOSYSTEM_TO_CHILI_PIPER_VALUES?.[
                profile.company?.ecosystem as CompanyEcosystem
              ],
            Time_Zone__c: Number(profile.timezone),
          },
          map: true,
          onSuccess: () => {
            handleSuccess();
            onClose();
          },
          onClose: () => onClose(),
        }
      );
  }, [isOpen]); // eslint-disable-line react-hooks/exhaustive-deps

  return <></>;
};

export default UpdatePlanDialog;

/// I18N

const i18n = defineMessages({
  eventScheduled: {
    id: "navbar.PremiumPlanWidget.UpdatePlanDialog.eventScheduled",
    defaultMessage: "Your meeting has been scheduled.",
  },
});
