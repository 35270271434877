import { AnyAction, isRejected } from "@reduxjs/toolkit";
import _ from "lodash";
import Record from "models/Record";
import { JSONAPIAttributes, JSONAPIRelationships } from "models/types";
import {
  ComponentType,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useSelector } from "react-redux";
import { RouteComponentProps } from "react-router";
import { destroy } from "redux/api/thunks";
import { APICollection } from "redux/api/typing";
import { RevealStore } from "redux/typing";
import { JSONAPIOptions } from "services/types";

import withAPI, { WithAPI } from "./withAPI";

export interface WithRecord<T = Record> extends Omit<WithAPI, "updateRecord"> {
  reloadRecord: () => void;
  updateRecord: (
    attributes: JSONAPIAttributes,
    relationships?: JSONAPIRelationships
  ) => any;
  record: T;
  removeRecord: () => Promise<
    ReturnType<typeof destroy.rejected | typeof destroy.fulfilled>
  >;
  removeOtherRecord: Pick<WithAPI, "removeRecord">;
  recordId?: number | null;
  ready: boolean;
}

interface Params {
  recordId: string;
}

interface Props<T = Record> {
  fetchRecord: Pick<WithAPI, "fetchRecord">;
  removeRecord: Pick<WithAPI, "fetchRecord">;
  updateRecord: $TSFixMeFunction;
  record?: T;
  recordId?: number | null;
}

interface Options {
  allowEarlyDisplay?: boolean;
  include?: string[];
  fields?: { [resourceName: string]: string[] };
  Loader?: ComponentType<any> | null;
  ErrorHandler?: ComponentType<any> | null;
  redirectToAfterError?: string | null;
  loadOnMount?: boolean;
  forceUrlResource?: string;
}

const defaultOptions = {
  include: [],
  fields: {},
  Loader: null,
  loadOnMount: true,
  ErrorHandler: null,
  redirectToAfterError: null,
  allowEarlyDisplay: true,
  forceUrlResource: undefined,
} as Options;

function getRecordId(
  rawRecordId: string | number | undefined | null,
  match: { params: Params } = { params: { recordId: "" } }
) {
  const recordId = +(rawRecordId || match.params.recordId);
  return isNaN(recordId) ? null : recordId;
}

function withRecord<
  T extends Record = Record,
  P extends WithRecord<T> = WithRecord<T>
>(recordType: string, options: Options = {}) {
  return (WrappedComponent: ComponentType<P>) => {
    const {
      allowEarlyDisplay,
      include,
      fields,
      Loader,
      loadOnMount,
      ErrorHandler,
      redirectToAfterError,
      forceUrlResource,
    } = _.defaults(options, defaultOptions);
    const Component = ({
      recordId,
      fetchRecord,
      match,
      removeRecord,
      updateRecord,
      record: propsRecord,
      ...props
    }: Omit<P, keyof WithRecord<T>> &
      Props<T> &
      WithAPI &
      RouteComponentProps<Params>) => {
      const [error, setError] = useState<Error | false>(false);
      const [ready, setReady] = useState(false);

      const collectionSelector = useMemo(
        () => (state: RevealStore) =>
          (state.api.entities[recordType] ?? {}) as APICollection,
        []
      );

      const collection = useSelector(collectionSelector);
      const thisId = recordId || getRecordId(recordId, match);
      const record =
        propsRecord || (thisId !== null ? collection[thisId] : undefined);

      const loadRecord = useCallback(async () => {
        if (thisId !== null) {
          return fetchRecord(forceUrlResource || recordType, +thisId, {
            include,
            fields,
          });
        }
        throw Error("Cannot load record without ID");
      }, [fetchRecord, thisId]);

      const updateThisRecord = useCallback(
        (
          attributes: JSONAPIAttributes,
          relationships: JSONAPIRelationships,
          options?: JSONAPIOptions
        ) => {
          if (thisId) {
            return updateRecord(
              recordType,
              +thisId,
              attributes,
              relationships,
              options,
              {
                url_resource: forceUrlResource,
              }
            );
          }
        },
        [updateRecord, thisId]
      );

      const removeThisRecord = useCallback(() => {
        if (thisId !== null) {
          return removeRecord(recordType, +thisId);
        }
      }, [removeRecord, thisId]);

      const resetError = useCallback(() => setError(false), []);

      useEffect(() => {
        let active = true;
        if (thisId) {
          setReady(false);
          if (!record || loadOnMount) {
            loadRecord()
              .then((action: AnyAction) => {
                if (isRejected(action)) {
                  throw action.error;
                }
              })
              .catch((error: Error) => {
                if (active) {
                  setError(error);
                }
              })
              .finally(() => {
                if (active) {
                  setReady(true);
                }
              });
          } else {
            setReady(true);
          }
        }
        return () => {
          active = false;
        };
      }, [thisId, loadRecord]); // eslint-disable-line react-hooks/exhaustive-deps

      if (!thisId) {
        return null;
      }

      if (error && ErrorHandler) {
        let props = {
          open: error ? true : false,
          error: error,
          resetError,
          redirectToOnClose: redirectToAfterError,
        };
        return <ErrorHandler {...props} />;
      }

      let defaultComponent = ready || !Loader ? null : <Loader />;

      try {
        if (!record || (!ready && !allowEarlyDisplay)) {
          return defaultComponent;
        }
        return (
          // @ts-expect-error Type ... is not assignable to type 'P'. TS2322
          <WrappedComponent
            record={record}
            reloadRecord={loadRecord}
            updateRecord={updateThisRecord}
            removeRecord={removeThisRecord}
            removeOtherRecord={removeRecord}
            fetchRecord={fetchRecord}
            ready={ready}
            {...props}
          />
        );
      } catch (_) {
        return defaultComponent;
      }
    };

    // @ts-expect-error
    const connected = withAPI<Props<T> & WithAPI>(Component) as ComponentType<
      Omit<P, keyof WithRecord<T>> & Pick<Props<T>, "record" | "recordId">
    >;
    connected.displayName = `withRecord('${recordType}')(${
      WrappedComponent.displayName || "Component"
    })`;

    return connected;
  };
}

export default withRecord;
