import { IntlShape } from "react-intl";

const useAmountFormatter = (intl: IntlShape) => {
  function formatAmount(amount: number = 0, currency: string) {
    return intl.formatNumber(amount, {
      style: "currency",
      currency: currency,
      notation: "compact",
    });
  }

  return formatAmount;
};

export type AmountFormatter = ReturnType<typeof useAmountFormatter>;

export default useAmountFormatter;
