import { T } from "components/ui/Typography";
import muiTheme from "config/theme";
import { defineMessages, FormattedMessage } from "react-intl";
import { Link } from "react-router-dom";

type widgetFooterProps = {
  integration?: $TSFixMe;
};

const getLinkToCustomFields = (integration: $TSFixMe) => {
  const basePath = "/sources";
  if (integration) {
    return (
      basePath +
      `?integration=${
        integration.id
      }&provider=${integration.provider.toLowerCase()}#custom-fields`
    );
  }
  return basePath;
};

export const WidgetFooter = ({ integration }: widgetFooterProps) => {
  const addCustomFieldLink = getLinkToCustomFields(integration);
  return (
    <>
      <T color={muiTheme.palette.greyReveal}>
        <FormattedMessage {...i18n.addCustomField} />
        &nbsp;
        <Link target="_blank" to={addCustomFieldLink}>
          <FormattedMessage {...i18n.footerLink} />
        </Link>
      </T>
      <T color={muiTheme.palette.greyReveal}>
        <FormattedMessage {...i18n.pushPartnerField} />
        &nbsp;
        <Link target="_blank" to={"/integrations"}>
          <FormattedMessage {...i18n.footerLink} />
        </Link>
      </T>
    </>
  );
};

// I18N

const i18n = defineMessages({
  addCustomField: {
    id: "filters.colum.ColumnWidget.addCustomField",
    defaultMessage: "To add custom fields from your CRM, ",
  },
  pushPartnerField: {
    id: "filters.colum.ColumnWidget.pushPartnerField",
    defaultMessage: "To push partner fields in your CRM, ",
  },
  footerLink: {
    id: "filters.colum.ColumnWidget.footerLink",
    defaultMessage: "please click here.",
  },
});
