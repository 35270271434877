import { ChevronDown, LockLightInverted } from "components/icons";
import Button from "components/ui/Button";
import Dropdown from "components/ui/Dropdown";
import {
  IDropdown,
  IDropdownOption,
} from "components/ui/Dropdown/components/types";
import DropdownSubmitFooter from "components/ui/DropdownSubmitFooter";
import { T } from "components/ui/Typography";
import { makeStyles } from "makeStyles";
import { ReactNode, useState } from "react";
import {
  defineMessages,
  FormattedMessage,
  MessageDescriptor,
} from "react-intl";

type BaseProps = {
  options: IDropdownOption[];
  label?: MessageDescriptor;
  customLabel?: ReactNode;
  hasCount?: boolean;
  value: string[];
  selectedItems: string[] | null;
  setSelectedItems: SetState<string[] | null>;
  onSubmit: () => void;
  disabled?: boolean;
};

type ToggleFilterProps = {
  withToggleFilter?: boolean;
  toggleFilter?: string;
  onFilterTypeChange?: (value: any) => void;
  onToggleFilterChange?: (value: any) => void;
  typeItems?: IDropdown["toggleFilterItems"];
};

type Props = BaseProps & ToggleFilterProps;

export const BaseFilter = ({
  label,
  customLabel,
  hasCount,
  options,
  value,
  selectedItems,
  setSelectedItems,
  disabled,
  onSubmit,
  ...optionalToggleFilterProps
}: Props) => {
  const { classes, cx } = useStyles({ disabled });
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const [open, setOpen] = useState(false);
  const [resetSearch, setResetSearch] = useState<number>(0);

  const {
    withToggleFilter,
    toggleFilter,
    onFilterTypeChange,
    onToggleFilterChange,
    typeItems,
  } = optionalToggleFilterProps;

  const handleToggleFilterChange = (value: string) => {
    if (withToggleFilter) {
      onFilterTypeChange?.(value);
      onToggleFilterChange?.(value);
    }
  };

  const openDropdown = (event: React.MouseEvent<HTMLElement>) => {
    if (!disabled) {
      setAnchorEl(event.currentTarget);
      setOpen(!open);
    }
  };

  const closeDropdown = () => {
    setAnchorEl(null);
    setOpen(false);
  };

  const handleSubmit = () => {
    onSubmit();
    closeDropdown();
  };

  const clearAll = () => {
    setResetSearch(resetSearch + 1);
    setSelectedItems([]);
  };

  return (
    <>
      <div className={classes.filterContainer}>
        <Button
          label={
            customLabel ?? (
              <div className={classes.groupFilter}>
                <T>
                  <FormattedMessage {...label} />
                </T>
                {hasCount && (
                  <T
                    uppercase
                    className={cx(classes.facet, {
                      [classes.displayNone]: value?.length === 0,
                    })}
                  >
                    {value?.length}
                  </T>
                )}
              </div>
            )
          }
          onClick={openDropdown}
          variant={"quinary"}
          RightIcon={disabled ? LockLightInverted : ChevronDown}
          size="small"
          disabled={open || disabled}
        />
      </div>
      <Dropdown
        value={selectedItems}
        options={options}
        anchorEl={anchorEl}
        onClose={closeDropdown}
        position="bottom-end"
        open={open}
        isMultiselect
        withCheckBox
        onChange={setSelectedItems}
        withToggleFilter={withToggleFilter}
        toggleFilterValue={toggleFilter}
        toggleFilterItems={typeItems}
        onToggleFilterChange={handleToggleFilterChange}
        resetSearch={resetSearch}
        CustomFooter={
          <DropdownSubmitFooter
            onApply={handleSubmit}
            onCancel={clearAll}
            disabledApplyBtn={false}
            cancelLabel={i18n.clearAll}
          />
        }
      />
    </>
  );
};

/// CSS

const useStyles = makeStyles<{
  disabled?: boolean;
}>()((theme, { disabled }) => ({
  filterContainer: {
    display: "flex",
    alignItems: "center",
    columnGap: 8,
    cursor: disabled ? "not-allowed" : "pointer",
  },
  groupFilter: {
    cursor: disabled ? "not-allowed" : "pointer",
    display: "flex",
    alignItems: "center",
    gap: 5,
  },
  facet: {
    color: theme.palette.ivory,
    backgroundColor: theme.palette.midnight,
    padding: "4px 6px",
    borderRadius: 24,
  },
  displayNone: {
    display: "none",
  },
}));

/// I18N

const i18n = defineMessages({
  clearAll: {
    id: "Partners.Partnerships.clearAll",
    defaultMessage: "Clear all",
  },
});
