import { Launch } from "@mui/icons-material";
import Close from "@mui/icons-material/Close";
import {
  Avatar,
  AvatarGroup,
  Box,
  CircularProgress,
  Grid,
  IconButton,
} from "@mui/material";
import { ArrowDown, Plus, Star } from "components/icons";
import CompanyAvatar from "components/ui/avatars/CompanyAvatar";
import Button from "components/ui/Button";
import Tooltip from "components/ui/Tooltip";
import { T } from "components/ui/Typography";
import { makeStyles } from "makeStyles";
import DiscussionParticipant from "models/DiscussionParticipant";
import { MessageType } from "models/Message";
import PartnerConnection from "models/PartnerConnection";
import { useEffect, useRef, useState } from "react";
import { defineMessages, FormattedMessage } from "react-intl";

import useMessageDrawerApiLogic from "../hooks/useMessageDrawerApiLogic";
import { DiscussionDetails } from "./DiscussionDetails";
import { MessageInput } from "./MessageInput";
import { MessageItem } from "./MessageItem";
import { Opportunities } from "./Opportunities";
import { ParticipantDialog } from "./ParticipantDialog";

const _PARTICIPANTS_DISPLAY_LIMIT = 5;

type Props = {
  partnerConnectionId: number;
  close?: () => void;
  isNonUser?: boolean;
  isReady?: boolean;
};

export const Content = ({
  partnerConnectionId,
  close,
  isNonUser = false,
  isReady = true,
}: Props) => {
  const messagesRef = useRef<HTMLDivElement>(null);
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [isCloseToBottom, setIsCloseToBottom] = useState<boolean>(true);

  const {
    loading,
    isLocked,
    partnerConnection,
    activeParticipants,
    allParticipants,
    messages,
    reload,
    addMessage,
  } = useMessageDrawerApiLogic({ partnerConnectionId, isReady });
  const { classes, cx } = useStyles({ isLocked: isLocked || isNonUser });

  const scrollToBottom = () => {
    if (messagesRef.current && messagesRef.current.scrollTo) {
      messagesRef.current.scrollTo(0, messagesRef.current.scrollHeight);
    }
  };

  useEffect(() => {
    scrollToBottom();
  }, [messages.length, allParticipants.length]);

  useEffect(() => {
    const checkScroll = () => {
      if (!messagesRef.current || messages.length <= 0) {
        return;
      }
      const { scrollTop, scrollHeight, clientHeight } = messagesRef.current;
      const isCloseToBottomNow = scrollHeight - (scrollTop + clientHeight) <= 8;

      setIsCloseToBottom(isCloseToBottomNow);
    };

    const messagesContainer = messagesRef.current;
    if (messagesContainer) {
      messagesContainer.addEventListener("scroll", checkScroll);
    }

    return () => messagesContainer?.removeEventListener("scroll", checkScroll);
  }, [messages]);

  if (!partnerConnection) {
    return (
      <Box className={classes.loader}>
        <CircularProgress />
      </Box>
    );
  }
  return (
    <div className={classes.mainContainer}>
      <div className={classes.header}>
        <div className={classes.headerLeft}>
          {partnerConnection["rawCompanyStarred"] && (
            <Tooltip title={<FormattedMessage {...i18n.strategicAccount} />}>
              <Star className={classes.starIcon} data-testid="starIcon" />
            </Tooltip>
          )}
          <T h3 bold noWrap>
            {partnerConnection["rawCompanyName"] ??
              partnerConnection.newProspectName}
          </T>
          <Close className={classes.xIcon} />
          <CompanyAvatar
            size="xs"
            companyName={partnerConnection.partnerName}
            src={partnerConnection.partnerAvatarUrl}
          />
          <T h3 bold>
            {partnerConnection.partnerName}
          </T>
        </div>
        {!!close && (
          <div className={classes.headerRight}>
            <IconButton onClick={close} className={classes.button}>
              <Close className={classes.iconSmall} />
            </IconButton>
          </div>
        )}
      </div>
      <Grid container className={classes.content}>
        <Grid
          item
          xs={8}
          display="flex"
          flexDirection="column"
          maxHeight="100%"
        >
          {isLocked ? (
            <div className={classes.locked}>
              <img src="/images/unlock.png" alt="" className={classes.logo} />
              <T h3 bold>
                <FormattedMessage {...i18n.lockedTitle} />
              </T>
              <T>
                <FormattedMessage {...i18n.lockedDescription} />
              </T>
            </div>
          ) : (
            <>
              {messages.length > 0 ? (
                <div
                  className={classes.messagesContainer}
                  ref={messagesRef}
                  data-testid="messages-container"
                >
                  {messages.map((message) => {
                    const participant = allParticipants.find(
                      (p: DiscussionParticipant) =>
                        p.id === message.participantId
                    );
                    if (
                      [
                        MessageType.internalMigrated,
                        MessageType.externalMigrated,
                      ].includes(message.messageType)
                    ) {
                      return <MessageItem message={message} isMigrated />;
                    }
                    if (!participant) {
                      return null;
                    }
                    return (
                      <MessageItem
                        message={message}
                        participant={participant}
                      />
                    );
                  })}
                  {!isCloseToBottom && (
                    <Box className={classes.scrollToBottomButton}>
                      <Button
                        LeftIcon={ArrowDown}
                        onClick={scrollToBottom}
                        variant="tertiary"
                        size="xSmall"
                      />
                    </Box>
                  )}
                </div>
              ) : loading ? (
                <Box className={classes.loader}>
                  <CircularProgress />
                </Box>
              ) : (
                <div className={classes.emptyContainer}>
                  <img
                    src="/images/no-messages.png"
                    alt=""
                    className={classes.empty}
                  />
                  <T h3 bold>
                    <FormattedMessage {...i18n.noMessages} />
                  </T>
                  <T>
                    <FormattedMessage
                      {...i18n.noMessagesSubtitle}
                      values={{
                        accountName: partnerConnection["rawCompanyName"],
                      }}
                    />
                  </T>
                  <Box display="flex" alignItems="center">
                    <AvatarGroup
                      max={_PARTICIPANTS_DISPLAY_LIMIT}
                      classes={{
                        root: classes.avatarGroup,
                        avatar: cx(classes.avatar, {
                          [classes.avatarCount]:
                            activeParticipants.length >
                            _PARTICIPANTS_DISPLAY_LIMIT,
                        }),
                      }}
                      onClick={!isNonUser ? () => setIsOpen(true) : undefined}
                    >
                      {activeParticipants.map((user: DiscussionParticipant) => (
                        <Avatar
                          title={user.fullName ?? undefined}
                          src={user.avatarUrl ?? undefined}
                        >
                          {user.fullName?.charAt(0).toUpperCase()}
                        </Avatar>
                      ))}
                    </AvatarGroup>
                    {!isNonUser &&
                      !isLocked &&
                      activeParticipants.length <= 0 && (
                        <Button
                          LeftIcon={Plus}
                          variant="quinary"
                          label={i18n.addParticipant}
                          onClick={() => setIsOpen(true)}
                          size="xSmall"
                        />
                      )}
                    {!isNonUser &&
                      !isLocked &&
                      activeParticipants.length > 0 && (
                        <Button
                          LeftIcon={Launch}
                          variant="quinary"
                          label={i18n.seeAll}
                          onClick={() => setIsOpen(true)}
                          size="xSmall"
                        />
                      )}
                  </Box>
                </div>
              )}
              <MessageInput
                addMessage={addMessage}
                partnerConnection={partnerConnection as PartnerConnection}
              />
              {!isNonUser && (
                <ParticipantDialog
                  isOpen={isOpen && !isNonUser}
                  partnerConnectionId={partnerConnection.id}
                  participants={activeParticipants}
                  onClose={() => setIsOpen(false)}
                  reloadRecord={reload}
                />
              )}
            </>
          )}
        </Grid>
        <Grid item xs={4} className={classes.contentRight}>
          <Grid container flexDirection="column" maxWidth="100%">
            <Grid item maxWidth="100%">
              <DiscussionDetails
                partnerConnection={
                  partnerConnection as PartnerConnection | null
                }
                handleOpenModal={() => setIsOpen(true)}
                activeParticipants={activeParticipants}
                isLocked={isLocked}
                isNonUser={isNonUser}
              />
            </Grid>
            {partnerConnection && (
              <Grid item maxWidth="100%">
                <Opportunities
                  partnerConnection={partnerConnection as PartnerConnection}
                />
              </Grid>
            )}
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
};

// CSS

export const useStyles = makeStyles<{ isLocked: boolean }>()(
  (theme, { isLocked }) => ({
    loader: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      height: "100%",
    },
    content: {
      flex: 1,
      overflowY: "auto",
    },
    contentRight: {
      background: theme.palette.greyLight050,
      borderLeft: `1px solid ${theme.palette.greyLight100}`,
      maxHeight: "100%",
      overflowY: "auto",
      padding: 20,
    },
    mainContainer: {
      display: "flex",
      flexDirection: "column",
      height: "100vh" /* Full height of the viewport */,
    },
    header: {
      display: "flex",
      justifyContent: "space-between",
      borderBottom: `1px solid ${theme.palette.greyLight100}`,
      padding: theme.spacing(2),
    },
    headerLeft: {
      display: "flex",
      alignItems: "center",
      gap: 8,
    },
    headerRight: {
      display: "flex",
      alignItems: "center",
      gap: 16,
    },
    button: {
      background: theme.palette.greyscale200,
      height: 24,
      width: 24,
    },
    iconSmall: {
      width: 12,
      height: 12,
    },
    messagesContainer: {
      flex: 1 /* Take remaining vertical space */,
      padding: "12px 20px",
      rowGap: 6,
      overflowY: "auto",
      position: "relative",
    },
    emptyContainer: {
      flex: 1 /* Take remaining vertical space */,
      padding: 12,
      overflowY: "auto",
      position: "relative",
      display: "flex",
      flexDirection: "column",
      rowGap: 4,
      alignItems: "center",
      justifyContent: "center",
    },
    empty: {
      height: 300,
    },
    locked: {
      display: "flex",
      flexDirection: "column",
      height: "100%",
      alignItems: "center",
      justifyContent: "center",
      rowGap: 4,
    },
    logo: {
      marginBottom: 12,
    },
    starIcon: {
      color: theme.palette.comet,
      marginLeft: theme.spacing(0.5),
      width: 14,
    },
    xIcon: {
      color: theme.palette.alpha500,
      width: 13,
    },
    avatarGroup: {
      alignItems: "center",
      cursor: isLocked ? "default" : "pointer",
    },
    avatar: {
      fontSize: 11,
      fontWeight: 500,
      color: theme.palette.midnight,
      backgroundColor: theme.palette.greyscale200,
      borderColor: theme.palette.ivory,
      height: 24,
      width: 24,
    },
    avatarCount: {
      "&:first-child": {
        paddingLeft: 4,
        backgroundColor: "unset",
        borderColor: "none",
      },
    },
    scrollToBottomButton: {
      position: "sticky",
      bottom: 0,
      left: "calc(50% - 12px)",
      display: "inline",
    },
  })
);

// I18N

const i18n = defineMessages({
  seeAll: {
    id: "crm.MyPipeline.MessageDrawer.Content.seeAll",
    defaultMessage: "See all participants",
  },
  strategicAccount: {
    id: "crm.MyPipeline.MessageDrawer.Content.strategicAccount",
    defaultMessage: "Strategic account",
  },
  lockedTitle: {
    id: "crm.MyPipeline.MessageDrawer.Content.lockedTitle",
    defaultMessage: "You don’t have access to this Collaboration",
  },
  lockedDescription: {
    id: "crm.MyPipeline.MessageDrawer.Content.lockedDescription",
    defaultMessage: "Reach out to your admins",
  },
  noMessages: {
    id: "crm.MyPipeline.MessageDrawer.Content.noMessages",
    defaultMessage: "You have no messages",
  },
  noMessagesSubtitle: {
    id: "crm.MyPipeline.MessageDrawer.Content.noMessagesSubtitle",
    defaultMessage: "Send your first message to discuss {accountName} with",
  },
  addParticipant: {
    id: "crm.MyPipeline.MessageDrawer.Content.addParticipant",
    defaultMessage: "Add participants",
  },
});
