import { SvgIconComponent } from "@mui/icons-material";
import {
  BaseTextFieldProps,
  InputAdornment,
  TextField as MuiTextField,
} from "@mui/material";
// Specially importing `_private.getFontStyles` outside tests because we don't
// need another component rendered (<T>) given that inputs already encompass text.
import {
  _private as _privateTypography,
  T,
} from "components/ui/Typography/Typography";
import { makeStyles } from "makeStyles";
import { ChangeEvent, forwardRef, useImperativeHandle, useRef } from "react";
import { defineMessages, FormattedMessage } from "react-intl";

type Variant =
  | "primary"
  | "secondary"
  | "tertiary"
  | "quaternary"
  | "newDesign";

type CustomStyleProps = {
  variant?: Variant;
  logo?: JSX.Element;
  LeftIcon?: SvgIconComponent;
  RightIcon?: SvgIconComponent;
  error?: boolean;
  small?: boolean;
  iconSize?: "xsmall" | "small" | "normal";
  multiline?: boolean;
  numeric?: boolean;
  decimal?: boolean;
  currency?: string;
  manualStartAdornment?: boolean; // to overwrite startAdornment in this component
  value?: any;
};

type CustomProps = SelectPartial<CustomStyleProps, "variant"> & {
  children?: React.ReactNode;
  onChange?: (e: ChangeEvent<HTMLInputElement>) => void;
  RightIconAction?: React.MouseEventHandler<HTMLSpanElement>;
  placeholder: string;
  // TODO: Remove after redesign deploy, only used by deprecated TextInput
  oldVariant?: BaseTextFieldProps["variant"];
};

export type TextInputRef = {
  focus: () => void;
  input: HTMLInputElement;
  container: HTMLDivElement;
};

export type Props = Omit<BaseTextFieldProps, keyof CustomProps> & CustomProps;

export const TextInput = forwardRef((props: Props, ref?) => {
  const {
    children,
    variant = "primary",
    small,
    iconSize = "small",
    logo,
    LeftIcon,
    RightIcon,
    RightIconAction,
    error,
    InputProps,
    inputProps,
    oldVariant, // Discarded
    label, // Discarded
    multiline = false,
    numeric = false,
    decimal = false,
    currency,
    manualStartAdornment,
    ...otherProps
  } = props;
  const styleProps = {
    variant,
    small,
    iconSize,
    multiline,
    numeric,
    decimal,
    LeftIcon,
    RightIcon,
    error,
    value: otherProps.value,
  };
  const { classes, cx } = useStyles(styleProps);
  const { classes: inputClasses } = useInputStyles(styleProps);
  const containerRef = useRef<HTMLDivElement>(null);
  const inputRef = useRef<HTMLInputElement>(null);

  // Remove custom styles (e.g. automatically added in Mui-Autocomplete).
  const { className: inputClassName, ...otherInputProps } = InputProps ?? {};
  const { className: htmlInputClass, ...otherHtmlInputProps } =
    inputProps ?? {};

  if (props.numeric) {
    otherHtmlInputProps["pattern"] = "[0-9]*";
  }

  if (props.decimal) {
    otherHtmlInputProps["step"] = "0.01";
  }

  useImperativeHandle(ref, () => ({
    focus: () => {
      inputRef.current?.focus();
    },
    get input() {
      return inputRef.current;
    },
    get container() {
      return containerRef.current;
    },
  }));

  return (
    <MuiTextField
      ref={containerRef}
      inputRef={inputRef}
      variant={multiline ? "outlined" : "filled"}
      InputProps={{
        disableUnderline: true,
        classes: inputClasses,
        ...(manualStartAdornment
          ? {}
          : {
              startAdornment: (
                <>
                  {logo}
                  {LeftIcon && <LeftIcon />}
                  {numeric && currency ? (
                    <InputAdornment position="start">{currency}</InputAdornment>
                  ) : undefined}
                </>
              ),
            }),
        endAdornment: RightIcon && (
          <span
            onClick={RightIconAction}
            className={cx({
              [classes.rightIconContainer]: !!RightIconAction,
              [classes.endIconWithText]: variant === "quaternary",
            })}
          >
            <RightIcon />
            {variant === "quaternary" && (
              <T oldVariant="caption">
                <FormattedMessage {...i18n.clear}></FormattedMessage>
              </T>
            )}
          </span>
        ),
        ...otherInputProps,
      }}
      inputProps={{
        ...otherHtmlInputProps,
        className: classes.baseHtmlInputStyles,
      }}
      hiddenLabel
      multiline={multiline}
      {...otherProps}
    >
      {children}
    </MuiTextField>
  );
});

// Styles

const height = (props: CustomStyleProps) =>
  props.multiline ? "auto" : props.small ? "28px" : "36px";
const paddingLeft = (props: CustomStyleProps) =>
  props.small ? "10px" : "16px";
const paddingRight = paddingLeft;
const iconWidth = (props: CustomStyleProps) =>
  props.iconSize === "normal" ? "24px" : "12.5px";
const iconHeight = (props: CustomStyleProps) =>
  props.iconSize === "normal" ? "24px" : "12.5px";
const iconMargin = (props: CustomStyleProps) =>
  props.small ? "8.17px" : "10.17px";
const iconPadding = (props: CustomStyleProps) =>
  props.small ? "7.33px" : "11.33px";

const useInputStyles = makeStyles<CustomStyleProps>()((theme, props) => ({
  root:
    props.variant !== "newDesign"
      ? {
          ..._privateTypography.getFontStyles({ labelSmall: true }),
          fontFamily: _privateTypography.fontFamily,
          borderRadius: props.variant === "quaternary" ? "6px" : "18px",
          height: height(props),
          paddingLeft: paddingLeft(props),
          paddingRight: paddingRight(props),
          alignItems: props.multiline ? "flex-start" : "center",
          // Colors
          "&, &:hover, &.Mui-disabled": {
            backgroundColor:
              props.variant === "primary" || props.variant === "quaternary"
                ? props.error
                  ? theme.palette.red100
                  : props.variant === "quaternary"
                  ? theme.palette.greyLight100
                  : theme.palette.taupe500
                : props.variant === "secondary" && props.error
                ? theme.palette.red50
                : "transparent",
          },
          color:
            props.error && props.variant !== "tertiary"
              ? theme.palette.wine
              : theme.palette.midnight,
          "& > ::placeholder": {
            color:
              props.error && props.variant !== "tertiary"
                ? theme.palette.wine
                : props.variant === "quaternary"
                ? theme.palette.alpha500
                : theme.palette.greyLight300,
            opacity: 1, // Firefox fix -- it always reduces the opacity.
          },
          "& > svg:first-child": {
            color: props.error
              ? theme.palette.wine
              : props.variant === "quaternary"
              ? theme.palette.alpha500
              : theme.palette.midnight500,
            opacity: 1, // Firefox fix -- it always reduces the opacity.
          },
          "&:hover > svg:first-child": {
            color: props.error
              ? theme.palette.wine
              : props.variant === "quaternary"
              ? theme.palette.alpha500
              : theme.palette.midnight,
          },
          "& > span>svg:last-child": {
            color:
              props.error && props.variant !== "tertiary"
                ? theme.palette.wine
                : props.variant === "quaternary"
                ? theme.palette.alpha500
                : theme.palette.midnight500,
            opacity: 1, // Firefox fix -- it always reduces the opacity.
          },
          "&:hover > span>svg:last-child": {
            color:
              props.error && props.variant !== "tertiary"
                ? theme.palette.wine
                : props.variant === "primary"
                ? theme.palette.midnight500
                : props.variant === "quaternary"
                ? theme.palette.alpha500
                : theme.palette.midnight,
          },
          border:
            props.variant === "tertiary" || props.variant === "quaternary"
              ? props.error
                ? `1px solid ${theme.palette.red500}`
                : props.variant === "quaternary"
                ? `2px solid transparent`
                : `1px solid ${theme.palette.taupe}`
              : undefined,
          "&.Mui-focused": {
            ...(props.variant === "quaternary"
              ? {
                  border: `2px solid ${theme.palette.taupe}`,
                  borderRadius: !!props.value ? "6px 6px 0 0" : 6,
                }
              : {}),
            backgroundColor:
              props.variant === "primary" || props.variant === "quaternary"
                ? props.error
                  ? theme.palette.red100
                  : props.variant === "quaternary"
                  ? theme.palette.ivory
                  : theme.palette.taupe500
                : props.variant === "secondary" && props.error
                ? theme.palette.red50
                : "transparent",
          },
          "&:hover": {
            border:
              props.variant === "tertiary" && !props.error
                ? `1px solid ${theme.palette.midnight500}`
                : undefined,
          },
          "& .MuiOutlinedInput-notchedOutline": {
            border: "none",
          },
        }
      : {
          ..._privateTypography.getFontStyles({ labelSmall: true }),
          fontFamily: _privateTypography.fontFamily,
          alignItems: props.multiline ? "flex-start" : "center",
          height: height(props),
          borderRadius: 18,
          backgroundColor: props.error
            ? theme.palette.red200
            : theme.palette.ivory,
          border: props.error
            ? `1px solid transparent`
            : `1px solid ${theme.palette.alpha250}`,
          "&:hover": {
            backgroundColor: props.error
              ? theme.palette.red200
              : theme.palette.ivory,
            border: props.error
              ? `1px solid ${theme.palette.red500}`
              : `1px solid ${theme.palette.alpha300}`,
          },
          "& > ::placeholder": {
            color: props.error ? theme.palette.red700 : theme.palette.alpha500,
            fontWeight: 400,
            opacity: 1, // Firefox fix -- it always reduces the opacity.
          },
          "&:not(:placeholder-shown)": {
            color: props.error
              ? theme.palette.red700
              : theme.palette.greyscale950,
          },
          "&.Mui-focused": {
            backgroundColor: props.error
              ? theme.palette.red200
              : theme.palette.ivory,
            border: props.error
              ? `1px solid ${theme.palette.newRed600}`
              : `1px solid ${theme.palette.alpha400}`,
            color: props.error
              ? theme.palette.red700
              : theme.palette.greyscale950,
          },
          "& .MuiOutlinedInput-notchedOutline": {
            border: "none",
          },
        },
  input: {
    textAlign: "left",
    textOverflow: "ellipsis",
  },
  adornedStart: {
    "& > svg:first-child": {
      width: iconWidth(props),
      height: iconHeight(props),
      marginRight: iconMargin(props),
    },
    paddingLeft: props.variant !== "newDesign" ? iconPadding(props) : "14px",
  },
  adornedEnd: {
    "& > span>svg:last-child":
      props.variant !== "newDesign"
        ? {
            width: props.variant === "quaternary" ? 8 : iconWidth(props),
            height: props.variant === "quaternary" ? 8 : iconHeight(props),
            marginLeft: iconMargin(props),
          }
        : {
            width: props.iconSize === "xsmall" ? 12 : 16,
            height: props.iconSize === "xsmall" ? 12 : 16,
            marginTop: 1,
            color: props.error ? theme.palette.red700 : theme.palette.alpha500,
            "&:hover": {
              color: theme.palette.greyscale950,
            },
          },
    // This selector is useful on multiline mode
    // in that case, the icon is not the last item.
    "& > span>svg:nth-last-child(2)": {
      ...(props.variant !== "quaternary"
        ? {
            width: iconWidth(props),
            height: iconHeight(props),
            marginLeft: iconMargin(props),
          }
        : {}),
    },
    paddingRight: props.variant !== "newDesign" ? iconPadding(props) : "10px",
  },
}));

const useStyles = makeStyles<CustomStyleProps>()((theme, props) => ({
  baseHtmlInputStyles: {
    height: height(props),
    padding: 0,
    display: "flex",
    alignItems: "center",
  },
  rightIconContainer: {
    cursor: "pointer",
  },
  endIconWithText: {
    color: theme.palette.alpha500,
    display: "flex",
    alignItems: "center",
    gap: 4,
    marginLeft: iconMargin(props),
    "& > svg": {
      width: 8,
      height: 8,
    },
  },
}));

const i18n = defineMessages({
  clear: {
    id: "TextInput.clear",
    defaultMessage: "Clear",
  },
});
