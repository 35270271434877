import Button from "components/ui/Button";
import { makeStyles } from "makeStyles";
import { defineMessages } from "react-intl";
import { useDispatch } from "react-redux";
import {
  openWidget,
  toggleFree,
  togglePower,
  togglePro,
} from "redux/premiumPlanWidget/actions";

export const UpgradeButton = () => {
  const { classes } = useStyles();
  const dispatch = useDispatch();

  const handleOpenPremiumPlanWidget = () => {
    dispatch(toggleFree(false));
    dispatch(togglePro(true));
    dispatch(togglePower(false));
    dispatch(openWidget(true));
  };

  return (
    <Button
      onClick={handleOpenPremiumPlanWidget}
      label={i18n.upgrade}
      size="xxxSmall"
      className={classes.btn}
    />
  );
};

export const useStyles = makeStyles()((theme) => ({
  btn: {
    color: theme.palette.ivory,
    background:
      "linear-gradient(70deg, rgba(90, 40, 195, 0.92) 9.2%, rgba(50, 231, 177, 0.92) 100%)",
    borderRadius: 16,
    padding: 0,
    "& p:first-of-type": {
      textTransform: "capitalize",
    },
  },
}));

const i18n = defineMessages({
  upgrade: {
    id: "CollaborationTab.UpgradeButton.upgrade",
    defaultMessage: "Upgrade",
  },
});
