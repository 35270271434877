import { createSvgIcon } from "@mui/material/utils";

import { SvgIcon } from "./types";

const displayName = "ChevronRight";

const ChevronRight: SvgIcon = createSvgIcon(
  // Using transform to enlarge from provided viewbox="0 0 16 16"
  // to default "0 0 24 24" from `createSvgIcon` -> `SvgIcon`.
  <g fill="none" transform="matrix(1.5,0,0,1.5,0,0)">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6.02867 11.9713C5.86707 11.8097 5.86707 11.5477 6.02867 11.3861L9.22475 8.19006C9.27861 8.1362 9.27861 8.04886 9.22475 7.995L6.02867 4.79892C5.86707 4.63732 5.86707 4.37532 6.02867 4.21373C6.19026 4.05213 6.45226 4.05213 6.61386 4.21373L9.80994 7.40981C10.187 7.78686 10.187 8.3982 9.80994 8.77525L6.61386 11.9713C6.45226 12.1329 6.19026 12.1329 6.02867 11.9713Z"
      fill="currentColor"
    />
  </g>,
  displayName
);

ChevronRight.displayName = displayName;
export default ChevronRight;
