import { FormControl } from "@mui/material";
import { TextInput } from "components/ui/TextInput";
import { T } from "components/ui/Typography";
import { FieldInputProps, FormikProps } from "formik";
import { makeStyles } from "makeStyles";
import { IQuestionForm } from "models/IntroductionRequestForm";
import { defineMessages, useIntl } from "react-intl";

export const MAX_LENGTH = 250;

type Props = {
  field: FieldInputProps<string>;
  form: FormikProps<IQuestionForm>;
  index: number;
};

const Question = ({ field, form, index }: Props) => {
  const intl = useIntl();
  const { classes } = useStyles();

  const { errors, touched } = form;
  const hasError =
    errors.questions?.length &&
    !!errors.questions[index] &&
    touched.questions?.length &&
    !!touched.questions[index];

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.value.length > MAX_LENGTH) {
      return;
    }
    field.onChange(event);
  };

  return (
    <FormControl fullWidth>
      <TextInput
        {...field}
        variant="tertiary"
        onChange={handleChange}
        multiline
        placeholder={intl.formatMessage(i18n.writeQuestion)}
        error={hasError}
        className={classes.input}
      />
      <div className={classes.charCounter}>
        <T>{`${field.value.length}/${MAX_LENGTH}`}</T>
      </div>
    </FormControl>
  );
};

const useStyles = makeStyles()((theme) => ({
  input: {
    "& .MuiInputBase-root": {
      borderRadius: 6,
      lineHeight: "18px",
      padding: "4px 5px",
    },
    "& .MuiInputBase-input": {
      paddingRight: 50,
    },
  },
  charCounter: {
    position: "absolute",
    right: 6,
    bottom: 5,
    color: theme.palette.greyLight300,
  },
}));

const i18n = defineMessages({
  writeQuestion: {
    id: "AccountSettings.GetIntroTemplate.Question.writeQuestion",
    defaultMessage: "Write question",
  },
});

export default Question;
