import { createSvgIcon } from "@mui/material/utils";

import { SvgIcon } from "./types";

const displayName = "ArrowDown";

const ArrowDown: SvgIcon = createSvgIcon(
  // Using transform to enlarge from provided viewbox="0 0 16 16"
  // to default "0 0 24 24" from `createSvgIcon` -> `SvgIcon`.
  <g fill="none" transform="matrix(1.5,0,0,1.5,0,0)">
    <path
      d="M8.50008 2.66666C8.50008 2.39051 8.27622 2.16666 8.00008 2.16666C7.72394 2.16666 7.50008 2.39051 7.50008 2.66666V12.4595L4.0203 8.97977C3.82504 8.78451 3.50846 8.78451 3.31319 8.97977C3.11793 9.17503 3.11793 9.49161 3.31319 9.68688L7.17512 13.5488C7.63073 14.0044 8.36943 14.0044 8.82504 13.5488L12.687 9.68688C12.8822 9.49161 12.8822 9.17503 12.687 8.97977C12.4917 8.78451 12.1751 8.78451 11.9799 8.97977L8.50008 12.4596V2.66666Z"
      fill="currentColor"
    />
  </g>,
  displayName
);

ArrowDown.displayName = displayName;
export default ArrowDown;
