import { PayingFeature } from "models/CompanyPayingFeatureSubscription";
import User from "models/User";
import { useSelector } from "react-redux";
import { selectActivePayingFeatures } from "redux/api/selectors";

const useHasPayingFeature = (payingFeature: string, profile: User) => {
  const payingFeatures = useSelector(selectActivePayingFeatures);
  const hasPayingFeature = payingFeatures.includes(
    payingFeature as PayingFeature
  );

  return profile.company ? hasPayingFeature : false;
};

export default useHasPayingFeature;
