import { createSvgIcon } from "@mui/material/utils";

import { SvgIcon } from "./types";

const displayName = "Clock";

const Clock: SvgIcon = createSvgIcon(
  // Using transform to enlarge from provided viewbox="0 0 16 16"
  // to default "0 0 24 24" from `createSvgIcon` -> `SvgIcon`.
  <g fill="none" transform="matrix(1.5,0,0,1.5,0,0)">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.99992 13.6667C11.1295 13.6667 13.6666 11.1297 13.6666 8.00004C13.6666 4.87043 11.1295 2.33337 7.99992 2.33337C4.87031 2.33337 2.33325 4.87043 2.33325 8.00004C2.33325 11.1297 4.87031 13.6667 7.99992 13.6667ZM7.99992 14.6667C11.6818 14.6667 14.6666 11.6819 14.6666 8.00004C14.6666 4.31814 11.6818 1.33337 7.99992 1.33337C4.31802 1.33337 1.33325 4.31814 1.33325 8.00004C1.33325 11.6819 4.31802 14.6667 7.99992 14.6667Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8 4.16663C8.27614 4.16663 8.5 4.39048 8.5 4.66663V7.72382C8.5 7.76802 8.51756 7.81041 8.54882 7.84167L10.0202 9.31307C10.2155 9.50834 10.2155 9.82492 10.0202 10.0202C9.82496 10.2154 9.50838 10.2154 9.31311 10.0202L7.84171 8.54878C7.62292 8.32998 7.5 8.03324 7.5 7.72382V4.66663C7.5 4.39048 7.72386 4.16663 8 4.16663Z"
      fill="currentColor"
    />
  </g>,
  displayName
);

Clock.displayName = displayName;
export default Clock;
