import CircularProgress from "@mui/material/CircularProgress";
import { Close } from "components/icons";
import SearchIcon from "components/icons/Search";
import Dropdown from "components/ui/Dropdown";
import { IDropdownOption } from "components/ui/Dropdown/components/types";
import { TextInput, TextInputRef } from "components/ui/TextInput";
import { T } from "components/ui/Typography";
import _ from "lodash";
import { makeStyles } from "makeStyles";
import {
  ChangeEvent,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { defineMessages, FormattedMessage, useIntl } from "react-intl";
import { isAccountResult } from "services/GlobalSearchService";

import useActions from "../hooks/useActions";
import useAPI from "../hooks/useAPI";
import AccountOption from "./AccountOption";
import Drawer from "./Drawer";
import PartnerOption from "./PartnerOption";

export const GlobalSearch = () => {
  const { classes } = useStyles();
  const inputRef = useRef<TextInputRef>();
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>();
  const intl = useIntl();
  const [open, setOpen] = useState(false);
  const [inputValue, setInputValue] = useState("");

  const { loading, searchResult, integrationProviderMap } = useAPI(inputValue);
  const { clearHandle, handle, handleSelectedValue } = useActions();

  useEffect(() => {
    setAnchorEl(inputRef.current?.container ?? null);
  }, [inputRef]);

  const handleClick = useCallback(() => {
    setAnchorEl(inputRef.current?.container ?? null);
    if (inputValue.length > 0) {
      setOpen(true);
    }
  }, [inputValue, inputRef]);

  const handleCloseDropdown = useCallback(() => {
    setAnchorEl(null);
    setOpen(false);
  }, []);

  const handleSelectedValueAndClose = useCallback(
    (value: string[] | null) => {
      handleSelectedValue(value);
      handleCloseDropdown();
    },
    [handleCloseDropdown, handleSelectedValue]
  );

  const dropdownOptions = useMemo(() => {
    const results: IDropdownOption[] = [];
    const searchWords = inputValue.split(" ");
    if (searchResult.accounts.length) {
      results.push({
        id: _.uniqueId("accounts"),
        name: intl.formatMessage(i18n.accountsLabel),
        group: searchResult.accounts.map((account) => ({
          id: isAccountResult(account)
            ? `na-${account.raw_company_id}`
            : `np-${account.country_iso_code}-${account.smart_domain_key}`,
          name: account.name,
          value: account,
          label: (
            <AccountOption
              item={account}
              integrations={integrationProviderMap}
              searchWords={searchWords}
            />
          ),
        })),
      });
    }
    if (searchResult.partnerships.length) {
      results.push({
        id: _.uniqueId("partnerships"),
        name: intl.formatMessage(i18n.partnersLabel),
        group: searchResult.partnerships.map((partnership) => ({
          id: `p-${partnership.id}`,
          name: partnership.partner_name,
          value: partnership,
          label: (
            <PartnerOption
              partnership={partnership}
              searchWords={searchWords}
            />
          ),
        })),
      });
    }
    return results;
  }, [
    searchResult.accounts,
    searchResult.partnerships,
    integrationProviderMap,
    intl,
    inputValue,
  ]);

  const handleChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      if (!anchorEl) {
        setAnchorEl(inputRef.current?.container ?? null);
      }
      setInputValue(event.target.value);
      setOpen(true);
    },
    [inputRef, anchorEl]
  );

  const handleClearInputValue = useCallback(() => {
    setInputValue("");
    inputRef.current?.focus();
  }, [inputRef]);

  const errorMessage = (
    <div className={classes.errorMainSearch}>
      <img
        src="/images/no-result.png"
        alt="no-result"
        width={188}
        height={89}
      />
      <div>
        <T h4 bold>
          <FormattedMessage {...i18n.noResults} />
        </T>
        <T>
          <FormattedMessage {...i18n.tryOtherKeywords} />
        </T>
      </div>
    </div>
  );

  return (
    <>
      <TextInput
        variant="quaternary"
        className={classes.root}
        ref={inputRef}
        onClick={handleClick}
        value={inputValue}
        id="input_id"
        fullWidth
        onChange={handleChange}
        placeholder={intl.formatMessage(i18n.placeholder)}
        small
        LeftIcon={
          loading
            ? () => (
                <span className={classes.loading}>
                  <CircularProgress color="inherit" size={11} />
                </span>
              )
            : SearchIcon
        }
        RightIcon={inputValue && Close}
        RightIconAction={handleClearInputValue}
      />
      <Dropdown
        isLoading={loading && false}
        options={dropdownOptions}
        anchorEl={anchorEl ?? null}
        fullWidth
        onClose={handleCloseDropdown}
        open={open && Boolean(inputValue)}
        withSearch={false}
        onChange={handleSelectedValueAndClose}
        size="inherit"
        isMainSearch
        errorMessage={errorMessage}
        withGroups
      />
      <Drawer handle={handle} clearHandle={clearHandle} />
    </>
  );
};

// I18n

const i18n = defineMessages({
  placeholder: {
    id: "GlobalSearch.placeholder",
    defaultMessage: "Search for Customers, Prospects or Partners…",
  },
  loading: {
    id: "GlobalSearch.loading",
    defaultMessage: "Loading…",
  },
  noResults: {
    id: "GlobalSearch.Dropdown.noResults",
    defaultMessage: "No results",
  },
  tryOtherKeywords: {
    id: "GlobalSearch.Dropdown.tryOtherKeywords",
    defaultMessage: "Try searching for other keywords",
  },
  accountsLabel: {
    id: "GlobalSearch.Dropdown.accountsLabel",
    defaultMessage: "Customers & Prospects",
  },
  partnersLabel: {
    id: "GlobalSearch.Dropdown.partnersLabel",
    defaultMessage: "Partners",
  },
});

const useStyles = makeStyles({ name: { GlobalSearch } })((theme) => ({
  root: {
    backgroundColor: "transparent",
    minWidth: 400,
    [theme.breakpoints.up("md")]: {
      maxWidth: "75%",
    },
    [theme.breakpoints.down("md")]: {
      maxWidth: `calc(100% - ${theme.spacing(2)})`,
    },
    "& .MuiFilledInput-root": {
      paddingTop: 0,
      paddingLeft: "11.33px",
    },
    "& .MuiInputBase-input": {
      padding: "0px !important",
    },
  },
  loading: {
    paddingRight: "9px",
  },

  errorMainSearch: {
    padding: 28,
    "& > div:first-of-type": {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      gap: 4,
      marginTop: 20,
    },
  },
}));
