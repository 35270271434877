import { createSvgIcon } from "@mui/material/utils";

import { SvgIcon } from "./types";

const displayName = "Tiles";

const Tiles: SvgIcon = createSvgIcon(
  // Using transform to enlarge from provided viewbox="0 0 16 16"
  // to default "0 0 24 24" from `createSvgIcon` -> `SvgIcon`.
  <g fill="none" transform="matrix(1.5,0,0,1.5,0,0)">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5.33333 3H4C3.44772 3 3 3.44772 3 4V5.33333C3 5.88562 3.44772 6.33333 4 6.33333H5.33333C5.88562 6.33333 6.33333 5.88562 6.33333 5.33333V4C6.33333 3.44772 5.88562 3 5.33333 3ZM4 2C2.89543 2 2 2.89543 2 4V5.33333C2 6.4379 2.89543 7.33333 4 7.33333H5.33333C6.4379 7.33333 7.33333 6.4379 7.33333 5.33333V4C7.33333 2.89543 6.4379 2 5.33333 2H4Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5.33333 9.66669H4C3.44772 9.66669 3 10.1144 3 10.6667V12C3 12.5523 3.44772 13 4 13H5.33333C5.88562 13 6.33333 12.5523 6.33333 12V10.6667C6.33333 10.1144 5.88562 9.66669 5.33333 9.66669ZM4 8.66669C2.89543 8.66669 2 9.56212 2 10.6667V12C2 13.1046 2.89543 14 4 14H5.33333C6.4379 14 7.33333 13.1046 7.33333 12V10.6667C7.33333 9.56212 6.4379 8.66669 5.33333 8.66669H4Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.0001 3H10.6667C10.1145 3 9.66675 3.44772 9.66675 4V5.33333C9.66675 5.88562 10.1145 6.33333 10.6667 6.33333H12.0001C12.5524 6.33333 13.0001 5.88562 13.0001 5.33333V4C13.0001 3.44772 12.5524 3 12.0001 3ZM10.6667 2C9.56218 2 8.66675 2.89543 8.66675 4V5.33333C8.66675 6.4379 9.56218 7.33333 10.6667 7.33333H12.0001C13.1047 7.33333 14.0001 6.4379 14.0001 5.33333V4C14.0001 2.89543 13.1047 2 12.0001 2H10.6667Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.0001 9.66669H10.6667C10.1145 9.66669 9.66675 10.1144 9.66675 10.6667V12C9.66675 12.5523 10.1145 13 10.6667 13H12.0001C12.5524 13 13.0001 12.5523 13.0001 12V10.6667C13.0001 10.1144 12.5524 9.66669 12.0001 9.66669ZM10.6667 8.66669C9.56218 8.66669 8.66675 9.56212 8.66675 10.6667V12C8.66675 13.1046 9.56218 14 10.6667 14H12.0001C13.1047 14 14.0001 13.1046 14.0001 12V10.6667C14.0001 9.56212 13.1047 8.66669 12.0001 8.66669H10.6667Z"
      fill="currentColor"
    />
  </g>,
  displayName
);

Tiles.displayName = displayName;
export default Tiles;
