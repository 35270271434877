import BaseDataGrid from "components/ui/data-grid/BaseDataGrid";
import useSelectorWithDeepEquality from "hooks/useSelectorWithDeepEquality";
import { makeStyles } from "makeStyles";
import Record from "models/Record";
import { useCallback } from "react";
import { defineMessages, FormattedHTMLMessage } from "react-intl";
import {
  applyPipelineAttributeColumnOrder,
  // AvailablePipelineAttributeColumns,
  defaultPipelineAttributeColumnConfig,
  defaultPipelineAttributeColumns,
  pipelineAttributeColumnConfig,
} from "redux/pipelineAttribute/defaults";
import { selectPipelineAttributeSlice } from "redux/pipelineAttribute/selectors";
import {
  ColumnConfigType,
  DataTableType,
  FieldType,
  PersistedColumnType,
  SortType,
  viewToColumns,
} from "screens/Frontoffice/screens/DataTables/shared/types";

import { ColumnWidget } from "../../../shared/components/ColumnWidget";
import { PipelineViewParameters } from "../constants";
import PipelineAttributeEmptyRowsView from "./PipelineAttributeEmptyRowsView";

type Props = {
  rows?: Record[];
  rowsCount?: number | null;
  fields?: { [filterName: string]: FieldType };
  fetching?: boolean;
  loadMore: () => void;
  loading?: boolean;
  setView: (value: PipelineViewParameters) => void;
  refreshTable?: () => void;
};

export const MyPipelineAttributeTable = ({
  rows = [],
  rowsCount,
  loading,
  fields,
  fetching,
  loadMore,
  setView,
  refreshTable,
}: Props) => {
  const { classes } = useStyles();
  const { view } = useSelectorWithDeepEquality(selectPipelineAttributeSlice);
  const { columns: viewColumns } = view ?? {};

  const _viewColumns: PersistedColumnType[] =
    viewColumns ?? defaultPipelineAttributeColumns;
  const columns = viewToColumns(
    _viewColumns,
    fields,
    pipelineAttributeColumnConfig,
    defaultPipelineAttributeColumnConfig,
    applyPipelineAttributeColumnOrder
  );

  const setColumns = useCallback(
    (columns: ColumnConfigType[]) => {
      setView({ columns });
    },
    [setView]
  );

  const setSort = (value: SortType[]) => {
    setView({ sort: value });
  };

  return (
    <div className={classes.root}>
      <BaseDataGrid
        noSide
        hideHeaderMenus
        rows={rows}
        rowsCount={rowsCount ?? 0}
        setSort={setSort}
        sortOnHeaders
        hasRowSelection={false}
        fields={fields ?? {}}
        loadMore={loadMore}
        fetching={fetching}
        columns={columns}
        emptyRowsView={<PipelineAttributeEmptyRowsView loading={loading} />}
        smallScreenText={
          <>
            <FormattedHTMLMessage {...i18n.screenToSmall} />
          </>
        }
        loading={loading}
        setColumns={setColumns}
        viewType={DataTableType.ATTRIBUTE}
        refreshTable={refreshTable}
      />
      <ColumnWidget
        viewType={DataTableType.ATTRIBUTE}
        columns={columns}
        orderColumns={applyPipelineAttributeColumnOrder}
        defaultColumnConfig={defaultPipelineAttributeColumnConfig}
        fields={fields ?? {}}
        setColumns={setColumns}
        availableFieldsDefaultConfig={pipelineAttributeColumnConfig}
      />
    </div>
  );
};

// I18N

const i18n = defineMessages({
  screenToSmall: {
    id: "DataTables.MyPipelineAttributeTable.screenToSmall",
    defaultMessage: `
      Your screen is too small to display this table.
      <br/><br/>
      Please try it from a wider device.
    `,
  },
});

// CSS

const useStyles = makeStyles()((theme) => ({
  root: {
    position: "relative",
    height: "100%",
    paddingRight: 36,
    "& .rightColumn, & .MuiDataGrid-root": {
      borderColor: "transparent",
    },
    "& .MuiDataGrid-columnHeaders": {
      borderRight: "none!important",
    },
  },
}));
