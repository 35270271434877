import StarIcon from "@mui/icons-material/Star";
import Tooltip from "components/ui/Tooltip";
import { makeStyles } from "makeStyles";
import { defineMessages, FormattedMessage } from "react-intl";
import { Available360MappingColumns } from "redux/mapping360/defaults";
import { AvailableNearboundAccountsColumns } from "redux/mapping360NearboundAccounts/defaults";
import { AccountMappingStandardFieldName } from "screens/Frontoffice/screens/DataTables/screens/AccountMapping/shared/types";

import { CellProps } from "../utils";

const StrategicAccountCell = ({ row }: CellProps) => {
  const { classes } = useStyles();

  const isStrategic =
    Boolean(row.isStarred) || // Nearbound Accounts
    Boolean(row.starred) || // 360 Mapping
    row.leftStarred ||
    Boolean(row.leftIsStarred); // Account Mapping new API

  if (!isStrategic) {
    return null;
  }

  return (
    <Tooltip title={<FormattedMessage {...i18n.strategicAccount} />}>
      <StarIcon className={classes.starIcon} data-testid="starIcon" />
    </Tooltip>
  );
};

export default StrategicAccountCell;

// Helpers

export const isStrategicAccountCell = ({ fieldName }: CellProps) => {
  return (
    [AvailableNearboundAccountsColumns.IsStarred].includes(
      fieldName as AvailableNearboundAccountsColumns
    ) ||
    [Available360MappingColumns.starred].includes(
      fieldName as Available360MappingColumns
    ) ||
    [AccountMappingStandardFieldName.leftStarred].includes(
      fieldName as AccountMappingStandardFieldName
    ) ||
    [AccountMappingStandardFieldName.leftIsStarred].includes(
      fieldName as AccountMappingStandardFieldName
    )
  );
};

const useStyles = makeStyles()((theme) => ({
  starIcon: {
    color: theme.palette.comet,
    width: 18,
  },
}));

const i18n = defineMessages({
  strategicAccount: {
    id: "CellFormatters.StrategicAccountCell.strategicAccount",
    defaultMessage: "Strategic account",
  },
});
