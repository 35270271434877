import Chip from "@mui/material/Chip";
import { makeStyles } from "makeStyles";
import { ReactElement } from "react";
import { defineMessages, FormattedMessage } from "react-intl";

type Props = {
  partnerName?: ReactElement | string;
};

const PartnerNameChip = ({ partnerName = "" }: Props) => {
  const { classes } = useStyles({ partnerName });

  return (
    <Chip
      className={classes.chip}
      label={partnerName || <FormattedMessage {...i18n.you} />}
    />
  );
};

export default PartnerNameChip;

// CSS

const useStyles = makeStyles<Props>()((theme, { partnerName }) => ({
  chip: {
    fontSize: 10,
    color: theme.palette.white,
    borderRadius: 15,
    height: 18,
    "& .MuiChip-label": {
      paddingLeft: 8,
      paddingRight: 8,
    },
    backgroundColor: partnerName
      ? theme.palette.appGreen
      : theme.palette.purple,
  },
}));

// I18N

const i18n = defineMessages({
  you: {
    id: "crm.AccountMapping.PartnerNameChip.you",
    defaultMessage: "You",
  },
});
