import _ from "lodash";

export type DiffObject = {
  [key: string]: {
    changed: boolean;
    newValue: any;
    oldValue: any;
  };
};

export default function diffObjects(newObject: any, oldObject: any) {
  const diff: DiffObject = {};

  _.forOwn(newObject, (newValue: any, key: string) => {
    const oldValue = oldObject[key];
    if (newValue !== oldValue) {
      diff[key] = {
        changed: true,
        newValue,
        oldValue,
      };
    }
  });

  return diff;
}
