import ClearRoundedIcon from "@mui/icons-material/ClearRounded";
import _ from "lodash";
import { makeStyles } from "makeStyles";
import CrmField from "models/CrmField";
import SWQLOperator from "models/SWQLOperator";
import { useCallback } from "react";

import CrmFieldSelectDropdown, { LookupValue } from "./CrmFieldSelectDropdown";
import OperatorSelectorDropdown from "./OperatorSelectorDropdown";
import { PathToNode, useSWQLContext } from "./SWQLContext";
import SWQLLookup from "./SWQLLookup";
import { SWQLPlaceholder } from "./SWQLPlaceholder";
import {
  emptyNode,
  isSWQLBooleanNode,
  isSWQLConstantNode,
  isSWQLEmptyNode,
  isSWQLLookupNode,
  isSWQLRuleNode,
  NodeType,
  SWQLBooleanNode as TSWQLBooleanNode,
  SWQLBooleanOperator,
  SWQLField,
  SWQLLookupNode,
  SWQLNode,
  SWQLRuleNodeRight,
} from "./SWQLTypes";
import ValueField from "./SWQLValueFields/ValueField";

// Exported for testing purposes.
export type SWQLRuleProps = { pathToNode: PathToNode };

const SWQLRule = ({ pathToNode }: SWQLRuleProps) => {
  const {
    addField,
    getField,
    getNode,
    getParentNode,
    updateNode,
    deleteNode,
    integrationId,
    enableDateTimes,
    fields,
  } = useSWQLContext();
  const node = getNode(pathToNode);
  const parentNode = getParentNode(pathToNode);
  const isRoot = !Boolean(parentNode);
  let currentField: SWQLField | LookupValue | undefined;

  if (isSWQLRuleNode(node)) {
    currentField = getField(node.left.field_id);
  } else if (isSWQLLookupNode(node)) {
    currentField = `lookup_${node.operation}` as LookupValue;
  }

  if (isSWQLBooleanNode(node) || isSWQLConstantNode(node)) {
    throw Error(
      "This code should never be reached: `SWQLRule` called with unsupported not type in context."
    );
  }

  const { classes, cx } = useStyles();

  let operator;
  if (isSWQLRuleNode(node)) {
    if (node.right.value === null) {
      if (node.operation === SWQLOperator.TYPE_IS) {
        operator = SWQLOperator.TYPE_IS_NULL;
      } else if (node.operation === SWQLOperator.TYPE_IS_NOT) {
        operator = SWQLOperator.TYPE_IS_NOT_NULL;
      }
    } else {
      operator = node.operation;
    }
  }

  const onDelete = useCallback(() => {
    deleteNode(pathToNode);
  }, [deleteNode, pathToNode]);

  const addNewBooleanNode = useCallback(
    (operator: SWQLBooleanOperator) => {
      const newNode: TSWQLBooleanNode = {
        type: NodeType.Boolean,
        operator: operator,
        args: [node, emptyNode()],
      };

      updateNode(pathToNode, newNode);
    },
    [updateNode, pathToNode, node]
  );

  const handleChangeField = useCallback(
    (field: SWQLField | null) => {
      if (!field) {
        updateNode(pathToNode, emptyNode());
        return;
      }
      addField(field);
      const defaultOperator = CrmField.getOperatorOptions(
        field.type,
        Boolean(field.hasOptions)
      )[0].value;

      const rightArgs: SWQLRuleNodeRight = {
        type: "value",
        ...SWQLOperator.getDefaultValue(field.type, defaultOperator),
      };

      updateNode(pathToNode, {
        type: NodeType.Rule,
        operation: defaultOperator,
        left: { type: "field", field_name: field.value, field_id: field.id },
        right: rightArgs,
      });
    },
    [updateNode, pathToNode, addField]
  );

  const handleChangeOperator = useCallback(
    (operator: { value: string }) => {
      if (!isSWQLRuleNode(node)) {
        throw Error(
          "This code should never be reached: `handleChangeOperator` called with SWQLEmptyNode."
        );
      }

      const newArg = { ...node };
      const defaultValue = SWQLOperator.getDefaultValue(
        currentField && typeof currentField !== "string"
          ? currentField.type
          : undefined,
        operator.value
      );
      newArg.operation = ["is null", "is not null"].includes(operator.value)
        ? operator.value.replace(" null", "")
        : operator.value;
      if (
        typeof node.right.value !== typeof defaultValue.value ||
        defaultValue.value === null ||
        node.right.value === null
      ) {
        newArg.right = {
          type: "value",
          ...defaultValue,
        };
      }
      updateNode(pathToNode, newArg);
    },
    [updateNode, pathToNode, node, currentField]
  );

  const onChangeToLookup = useCallback(
    (value: SWQLLookupNode) => {
      updateNode(pathToNode, value);
    },
    [updateNode, pathToNode]
  );

  const handleLookupRuleChange = useCallback(
    (value: SWQLNode) => {
      if (isSWQLLookupNode(node)) {
        updateNode(pathToNode, {
          ...node,
          rule: value,
        });
      }
    },
    [node, pathToNode, updateNode]
  );

  return (
    <div className={classes.root}>
      <CrmFieldSelectDropdown
        value={currentField}
        handleChange={handleChangeField}
        handleChangeToLookup={onChangeToLookup}
      />
      {isSWQLRuleNode(node) &&
        currentField &&
        typeof currentField !== "string" && (
          <>
            <OperatorSelectorDropdown
              operator={operator}
              fieldType={currentField.type}
              hasOptions={currentField.hasOptions}
              handleChange={handleChangeOperator}
            />
            {node.operation && (
              <ValueField
                node={node}
                onChange={(newNode: SWQLNode) => {
                  updateNode(pathToNode, newNode);
                }}
                onAddNode={addNewBooleanNode}
              />
            )}
          </>
        )}
      {(isSWQLRuleNode(node) || isSWQLEmptyNode(node)) && (
        <SWQLPlaceholder node={node} pathToNode={pathToNode} />
      )}
      {isSWQLLookupNode(node) && (
        <SWQLLookup
          integrationId={integrationId}
          node={node}
          onUpdate={handleLookupRuleChange}
          swqlTarget={node.lookup_path}
          enableDateTimes={enableDateTimes}
          onAddNode={addNewBooleanNode}
          fields={fields}
        />
      )}
      {(!isRoot || isSWQLRuleNode(node) || isSWQLLookupNode(node)) && (
        <div
          className={cx(classes.deleteBtn, "showOnHover")}
          onClick={onDelete}
          id={_.uniqueId("delete-rule-")}
        >
          <ClearRoundedIcon style={{ width: 15, height: 15 }} />
        </div>
      )}
    </div>
  );
};

export default SWQLRule;

// CSS

const useStyles = makeStyles()((theme) => ({
  root: {
    display: "flex",
    alignItems: "center",
    width: "fit-content",
    columnGap: theme.spacing(0.5),
    borderRadius: 3,
    "& .showOnHover": {
      display: "none",
    },
    "&:hover .showOnHover": {
      display: "block",
    },
  },
  deleteBtn: {
    display: "flex !important",
    alignItems: "center",
    justifyContent: "center",
    color: theme.palette.midnight,
    marginLeft: theme.spacing(1),
    width: 28,
    backgroundColor: theme.palette.taupe500,
    height: 28,
    borderRadius: 50,
    "&:hover": {
      cursor: "pointer",
    },
  },
}));
