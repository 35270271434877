import { Box, CircularProgress } from "@mui/material";
import { Info } from "components/icons";
import CompanyAvatar from "components/ui/avatars/CompanyAvatar";
import Tooltip from "components/ui/Tooltip";
import { T } from "components/ui/Typography";
import useUserProfile from "hooks/useUserProfile";
import { makeStyles } from "makeStyles";
import { defineMessages, FormattedHTMLMessage, useIntl } from "react-intl";

import { TabProps } from "../../types";
import { useGetIntroContent } from "../hooks/useGetIntroContent";
import InternalChannel from "./InternalChannel";
import { NotifySection } from "./NotifySection";
import SharedChannel from "./SharedChannel";

export const GetIntroContent = (props: TabProps) => {
  const { classes } = useStyles();
  const intl = useIntl();
  const { profile } = useUserProfile();
  const {
    loading,
    hasConnectedSlack,
    hasSlackIntegrationPayingFeature,
    getProviderLogo,
    // Internal Channel
    loadingInternal,
    internalSlackId,
    setInternalSlackId,
    internalChannelEnabled,
    setInternalChannelEnabled,
    customCrmFields,
    ownerCrmFieldNames,
    setOwnerCrmFieldNames,
    // Shared Channel
    loadingShared,
    sharedSlackId,
    setSharedSlackId,
    sharedChannelEnabled,
    setSharedChannelEnabled,
    partnerOwnerShared,
    shouldEmailAccountOwner,
    setShouldEmailAccountOwner,
    shouldEmailPartnershipManager,
    setShouldEmailPartnershipManager,
  } = useGetIntroContent(props);
  const { partner, saving, partnership } = props;
  const sharedProps = {
    saving,
    hasConnectedSlack,
    hasSlackIntegrationPayingFeature,
  };

  const handleChangeOwnerCustomCrmField = (name: string, checked: boolean) => {
    checked
      ? setOwnerCrmFieldNames((prevNames) => [...prevNames, name].sort())
      : setOwnerCrmFieldNames((prevNames) =>
          prevNames.filter((prevName) => prevName !== name)
        );
  };

  const customStackholdersRowList = customCrmFields.map((crmField) => ({
    label: crmField.attributes.crm_label,
    isChecked: ownerCrmFieldNames?.includes(crmField.attributes.crm_name),
    isDisabled: saving,
    logo: getProviderLogo(Number(crmField.relationships.integration.data.id)),
    onChange: (_: any, checked: boolean) =>
      handleChangeOwnerCustomCrmField(
        crmField.attributes.crm_name as string,
        checked
      ),
  }));

  const handleChangePartnershipManagerEmail = (_: any, checked: boolean) => {
    setShouldEmailPartnershipManager(checked);
  };
  const handleChangeAccountOwnerEmail = (_: any, checked: boolean) => {
    setShouldEmailAccountOwner(checked);
  };

  return (
    <>
      {/* SLACK SELECTORS  */}
      <div className={classes.container}>
        <div className={classes.heading}>
          <CompanyAvatar size="xs" src="/images/slack-logo.png" />
          <T bodyBig bold>
            Post intro requests to Slack
          </T>
        </div>
        <div className={classes.divider} />
        {loading ? (
          <div className={classes.loader}>
            <CircularProgress />
          </div>
        ) : (
          <>
            <InternalChannel
              loading={loadingInternal}
              slackId={internalSlackId}
              isEnabled={internalChannelEnabled}
              setIsEnabled={setInternalChannelEnabled}
              setSlackId={setInternalSlackId}
              {...sharedProps}
            />
            <div className={classes.divider} />
            <SharedChannel
              partner={partner}
              loading={loadingShared}
              slackId={sharedSlackId}
              setSlackId={setSharedSlackId}
              partnerOwnerShared={partnerOwnerShared}
              isEnabled={sharedChannelEnabled}
              setIsEnabled={setSharedChannelEnabled}
              {...sharedProps}
            />
          </>
        )}
      </div>

      {/* INTERNAL CHECKBOXES */}
      <NotifySection
        title={intl.formatMessage(i18n.internalNotify)}
        logo={profile.company.avatarUrl}
        stakeholderRowList={[
          {
            label: intl.formatMessage(i18n.requester),
            isChecked: true,
            isDisabled: true,
            logo: "/images/reveal-logo-black-on-purple.svg",
          },
          {
            label: intl.formatMessage(i18n.partnershipOwner),
            isChecked: true,
            isDisabled: true,
            logo: "/images/reveal-logo-black-on-purple.svg",
          },
          ...customStackholdersRowList,
        ]}
      />

      {/* PARTNER CHECKBOXES */}
      {!partnership.isGhost() && (
        <NotifySection
          title={intl.formatMessage(i18n.partnerNotify, {
            partner: partner.name,
          })}
          logo={partner.avatarUrl}
          stakeholderRowList={[
            {
              label: intl.formatMessage(i18n.partnershipOwner),
              isChecked: shouldEmailPartnershipManager,
              onChange: handleChangePartnershipManagerEmail,
              isDisabled: saving,
            },
            {
              label: (
                <Box display="flex" gap={0.8}>
                  {intl.formatMessage(i18n.accountOwner)}
                  <Tooltip
                    title={
                      <FormattedHTMLMessage
                        {...i18n[
                          partnerOwnerShared
                            ? "tooltipTitle"
                            : "partnerOwnerNotShared"
                        ]}
                        values={{
                          company: profile.companyName,
                          partner: partner.name,
                        }}
                      />
                    }
                  >
                    <Info
                      onClick={(e) => e.preventDefault()}
                      className={classes.infoIcon}
                      data-testid="info-icon"
                    />
                  </Tooltip>
                </Box>
              ),
              isChecked: shouldEmailAccountOwner,
              onChange: handleChangeAccountOwnerEmail,
              isDisabled: saving,
            },
          ]}
        />
      )}
    </>
  );
};

export const useStyles = makeStyles()((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    rowGap: theme.spacing(4),
  },
  container: {
    borderRadius: 8,
    border: "1px solid  #E9E5DB",
  },
  heading: {
    display: "flex",
    alignItems: "center",
    gap: 10,
    padding: 16,
  },
  divider: {
    width: "100%",
    height: 1,
    background: "#E9E5DB",
  },
  infoIcon: {
    fontSize: 16,
    cursor: "auto",
    position: "relative",
    top: 1,
    color: theme.palette.red500v2,
  },
  loader: {
    display: "flex",
    justifyContent: "center",
    marginTop: 100,
  },
}));

const i18n = defineMessages({
  title: {
    id: "CollaborationTab.title",
    defaultMessage: "Send an intro to {partner}",
  },
  description: {
    id: "CollaborationTab.description",
    defaultMessage:
      "Intro requests are a way for your Sales team to request help on deals. Each request creates a new Collaboration with the partner.",
  },
  internalNotify: {
    id: "CollaborationTab.internalNotify",
    defaultMessage: "Internal stakeholders to notify",
  },
  partnerNotify: {
    id: "CollaborationTab.partnerNotify",
    defaultMessage: "{partner} stakeholders to notify",
  },
  requester: {
    id: "CollaborationTab.requester",
    defaultMessage: "Requester",
  },
  partnershipOwner: {
    id: "CollaborationTab.partnershipOwner",
    defaultMessage: "Partnership owner",
  },
  accountOwner: {
    id: "CollaborationTab.accountOwner",
    defaultMessage: "Account owner",
  },
  partnerOwnerNotShared: {
    id: "CollaborationTab.partnerOwnerNotShared",
    defaultMessage: `<b>Warning</b>: this information has not been shared with {company}.
      The Account owner will still be notified in a separate email to avoid email sharing and keep their anonymity.<br/>
      Make sure you get {partner} approval before routing the intro to their account owners to avoid any backlash.`,
  },
  tooltipTitle: {
    id: "CollaborationTab.tooltipTitle",
    defaultMessage:
      "Make sure to get {partner}’s approval before routing the intro to their account owners.",
  },
});
