import { Box } from "@mui/material";
import { CategoryCell } from "components/ui/data-grid/cellRenderers/pipeline/CategoryCell";
import { T } from "components/ui/Typography";
import { makeStyles } from "makeStyles";
import PartnerConnection from "models/PartnerConnection";
import moment from "moment";

type Props = {
  partnerConnection?: PartnerConnection | null;
};

export const PartnerConnectionCreatedDetails = ({
  partnerConnection,
}: Props) => {
  const { classes } = useStyles();
  const createdAt = moment(partnerConnection?.createdAt).format("MMM D, YYYY");
  return (
    <div className={classes.partnerConnectionCreatedDetails}>
      <T className={classes.ellipsis} title={partnerConnection?.userFullName}>
        {partnerConnection?.userFullName}
      </T>
      {partnerConnection?.createdAt && (
        <>
          <span className={classes.alpha500}>&middot;</span>
          <T className={classes.ellipsis} title={createdAt}>
            {createdAt}
          </T>
        </>
      )}
      {partnerConnection?.category && (
        <>
          <span className={classes.alpha500}>&middot;</span>
          <Box className={classes.category}>
            <CategoryCell row={partnerConnection} fieldName="category" />
          </Box>
        </>
      )}
    </div>
  );
};

const useStyles = makeStyles()((theme) => ({
  alpha500: {
    color: theme.palette.alpha500,
  },
  category: {
    alignItems: "center",
    display: "flex",
    "& > *": {
      background: "unset",
      paddingLeft: 0,
    },
  },
  ellipsis: {
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
  },
  partnerConnectionCreatedDetails: {
    alignItems: "center",
    display: "flex",
    gap: 4,
  },
}));
