import {
  Box as BoxMui,
  FormControlLabel,
  FormGroup,
  useTheme,
} from "@mui/material";
import Divider from "@mui/material/Divider";
import Grid from "@mui/material/Grid";
import { isFulfilled } from "@reduxjs/toolkit";
import { AxiosResponse } from "axios";
import { withCompanyPayingFeatures } from "components/hoc/withCompanyPayingFeatures";
import withCompanyPermissions from "components/hoc/withCompanyPermissions";
import { WithUserProfile } from "components/hoc/withUserProfile";
import Alert from "components/ui/Alert";
import Box from "components/ui/Box";
import Button from "components/ui/Button";
import Checkbox from "components/ui/Checkbox";
import { NotificationStatus } from "components/ui/Notifications/NotificationSnackbar";
import { SWQLTarget } from "components/ui/SWQL/SWQLTypes";
import Toggle from "components/ui/Toggle";
import { T } from "components/ui/Typography";
import usePushNotification from "hooks/usePushNotification";
import useUserProfile from "hooks/useUserProfile";
import generic from "i18n/generic";
import { makeStyles } from "makeStyles";
import { PayingFeature } from "models/CompanyPayingFeatureSubscription";
import { useCallback, useEffect, useState } from "react";
import {
  defineMessages,
  FormattedHTMLMessage,
  FormattedMessage,
} from "react-intl";
import { useDispatch } from "react-redux";
import { RouteComponentProps } from "react-router";
import { retreive, update } from "redux/api/thunks";
import AccountSettings from "screens/Frontoffice/screens/AccountSettings";
import MappingRulesWidget from "screens/Frontoffice/shared/components/SourceSettingsDialog/components/MappingRulesWidget";

enum CompanyNotificationSettingsType {
  weeklyDigestToSalesforceUsers = 1,
}

export enum RuleTypeEnum {
  WithoutRules = 1,
  WithRules = 2,
}

type Params = {
  integrationId: string;
};

type Props = WithUserProfile & RouteComponentProps<Params>;

const NotificationsSettings = ({ match }: Props) => {
  /**
   * CUSTOM HOOKS, STATE AND VARIABLES
   */

  const { classes } = useStyles();
  const { profile } = useUserProfile();
  const theme = useTheme();
  const dispatch = useDispatch();
  const pushNotification = usePushNotification();

  const [storedState, setStoredState] = useState({
    isEnabled: false,
    ruleType: RuleTypeEnum.WithoutRules,
  });
  const [toggleState, setToggleState] = useState(false);

  const [ruleTypeState, setRuleTypeState] = useState(RuleTypeEnum.WithoutRules);
  const [savingToggle, setSavingToggle] = useState(false);
  const [rulesDifference, setRulesDifference] = useState(false);
  const [savingRules, setSavingRules] = useState(false);
  const [cancelling, setCancelling] = useState(false);
  const [isChecked, setIsChecked] = useState(false);
  const [hasDisplayedRules, setHasDisplayedRules] = useState(false);

  const isLoading = savingRules || savingToggle;
  const toggleDifference = storedState.isEnabled !== toggleState;
  const ruleTypeDifference = storedState.ruleType !== ruleTypeState;
  const salesforceId = +match.params.integrationId;
  const ruleList = [
    {
      key: true,
      label: <></>,
    },
  ];

  /**
   * FUNCTIONS
   */

  const retrieveState = useCallback(async () => {
    const result = await dispatch(
      retreive({
        type: "company_notification_settings",
        id: CompanyNotificationSettingsType.weeklyDigestToSalesforceUsers,
      })
    );
    if (isFulfilled(result)) {
      const response = result.payload as AxiosResponse;
      const {
        is_enabled,
        rule_type,
      }: {
        is_enabled: boolean;
        rule_type: RuleTypeEnum;
      } = response.data.attributes;
      setStoredState({ isEnabled: is_enabled, ruleType: rule_type });
      setToggleState(is_enabled);
      setRuleTypeState(rule_type);
      setIsChecked(rule_type === RuleTypeEnum.WithRules);
    } else {
      pushNotification("default_error");
    }
  }, [dispatch, pushNotification]);

  const updateState = useCallback(
    async (newToggleState: boolean, withRules: boolean) => {
      setSavingToggle(true);
      const newRuleType = withRules
        ? RuleTypeEnum.WithRules
        : RuleTypeEnum.WithoutRules;
      const result = await dispatch(
        update({
          type: "company_notification_settings",
          id: CompanyNotificationSettingsType.weeklyDigestToSalesforceUsers,
          attributes: {
            is_enabled: newToggleState,
            rule_type: newRuleType,
          },
        })
      );
      if (isFulfilled(result)) {
        setStoredState({ isEnabled: newToggleState, ruleType: newRuleType }); // update stored state
        newRuleType === RuleTypeEnum.WithoutRules &&
          pushNotification({
            status: NotificationStatus.success,
            message: generic.edits_saved,
          });
      } else {
        newRuleType === RuleTypeEnum.WithoutRules &&
          pushNotification("default_error");
      }
      setSavingToggle(false);
      afterAction(); // disable action buttons to prevent side effects in MappingRulesWidget
    },
    [dispatch, pushNotification]
  );

  const handleSaveBtn = () => {
    setSavingRules(true);
    if (!hasDisplayedRules && isChecked) {
      setIsChecked(false);
      updateState(toggleState, false); // save current toggle state
      return;
    }
    updateState(toggleState, isChecked);
  };

  const handleCancelBtn = () => {
    setCancelling(true); // restore stored rules
    setToggleState(storedState.isEnabled); // restore the stored state
  };

  const afterAction = () => {
    setSavingRules(false);
    setCancelling(false);
  };

  /**
   * USE EFFECTS
   */

  useEffect(() => {
    retrieveState();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (!toggleState) {
      setIsChecked(false);
    }
  }, [toggleState]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    setRuleTypeState(
      isChecked && hasDisplayedRules
        ? RuleTypeEnum.WithRules
        : RuleTypeEnum.WithoutRules
    );
  }, [isChecked, hasDisplayedRules]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <AccountSettings
      actions={
        <>
          <Button
            variant="secondary"
            label={generic.cancel}
            disabled={
              (!rulesDifference && !ruleTypeDifference && !toggleDifference) ||
              isLoading
            }
            onClick={handleCancelBtn}
          />
          <Button
            label={generic.save}
            onClick={handleSaveBtn}
            disabled={
              !rulesDifference && !ruleTypeDifference && !toggleDifference
            }
            loading={isLoading}
          />
        </>
      }
      tab="notifications"
    >
      <Box className={classes.root} variant={"default"}>
        <Grid container className={classes.container}>
          <T bodyBig className={classes.description}>
            <Alert variant="warning" icon>
              <FormattedMessage
                {...i18n.description}
                values={{ company: profile.company.name }}
              />
            </Alert>
          </T>
          <Divider />
          <FormGroup className={classes.toggleContainer}>
            <FormControlLabel
              className={classes.toggle}
              control={
                <Toggle
                  checked={toggleState}
                  disabled={savingToggle || isLoading}
                  handleChange={(_e, checked) => setToggleState(checked)}
                  size="small"
                />
              }
              label={
                <>
                  <T bodyBig bold>
                    <FormattedHTMLMessage {...i18n.rules} />
                  </T>
                  <T color={theme.palette.alpha750}>
                    <FormattedMessage {...i18n.rulesDefault} />
                  </T>
                </>
              }
            />
          </FormGroup>
          <BoxMui className={classes.checkboxContainer}>
            <Checkbox
              label="Define specific rules"
              checked={isChecked}
              onChange={(_e, checked) => setIsChecked(checked)}
              disabled={!toggleState || isLoading}
            />
          </BoxMui>
          {toggleState && isChecked && (
            <div className={classes.mappedRules}>
              <MappingRulesWidget
                saving={savingRules}
                cancelling={cancelling}
                integrationId={salesforceId}
                ruleList={ruleList}
                attribute={"should_receive_weekly_digest"}
                trackedLocation="account settings notifications"
                swqlTarget={SWQLTarget.RawUser}
                setActionsEnabled={setRulesDifference}
                afterAction={afterAction}
                hasDisplayedRulesCallback={(hasDisplayedRulesBool) =>
                  setHasDisplayedRules(hasDisplayedRulesBool)
                }
              />
            </div>
          )}
        </Grid>
      </Box>
    </AccountSettings>
  );
};

export default withCompanyPayingFeatures([PayingFeature.WeeklyDigestToSales])(
  withCompanyPermissions<Props>(["company.manage_admins"])(
    NotificationsSettings
  )
);

/// CSS

const useStyles = makeStyles()((theme) => ({
  root: {
    flexDirection: "column",
    borderRadius: theme.spacing(1),
    [theme.breakpoints.down("md")]: {
      marginLeft: theme.spacing(2),
      marginRight: theme.spacing(2),
    },
    [theme.breakpoints.down("sm")]: {
      marginLeft: 0,
      marginRight: 0,
    },
  },
  container: {
    display: "flex",
    flexDirection: "column",
  },
  description: {
    marginBottom: 12,
  },
  toggleContainer: {
    marginTop: 16,
    marginLeft: 12,
  },
  toggle: {
    display: "flex",
    flexDirection: "row",
    columnGap: 12,
    alignItems: "baseline",
    "& .MuiSwitch-root": {
      position: "relative",
      top: 2,
    },
  },
  checkboxContainer: {
    display: "flex",
    alignItems: "center",
    marginLeft: "30px",
  },
  mappedRules: {
    marginLeft: 10,
  },
}));

/// I18N

const i18n = defineMessages({
  description: {
    id: "Workspace.Notifications.description",
    defaultMessage:
      "Define which CRM users should receive notifications from {company}’s ecosystem on their assigned accounts (Weekly Digests).",
  },
  rules: {
    id: "Workspace.Notifications.rules",
    defaultMessage: "Sales Weekly Digest",
  },
  rulesDefault: {
    id: "Workspace.Notifications.rulesDefault",
    defaultMessage: "Will be sent to all active CRM users by default",
  },
});
