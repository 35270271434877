import { isFulfilled } from "@reduxjs/toolkit";
import { ChevronDown, ChevronTop, Close } from "components/icons";
import Alert from "components/ui/Alert";
import Button from "components/ui/Button";
import Dropdown from "components/ui/Dropdown/components/Dropdown";
import { IDropdownOption } from "components/ui/Dropdown/components/types";
import Tooltip from "components/ui/Tooltip";
import usePushNotification from "hooks/usePushNotification";
import _ from "lodash";
import { makeStyles } from "makeStyles";
import { useCallback, useEffect, useMemo, useState } from "react";
import { defineMessages, FormattedMessage, useIntl } from "react-intl";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { index, rawGet } from "redux/api/thunks";
import { JSONAPIListResponse, JSONAPIResponse } from "services/types";

type Props = {
  disabled?: boolean;
  hideWarning?: boolean;
  value?: string;
  onChange?: (value: string | null) => void;
  isShared?: boolean;
  hasUnselectChannelButton?: boolean;
  error?: boolean;
  loading?: boolean;
};

const SlackChannelSelector = ({
  disabled,
  value,
  onChange = () => {},
  isShared,
  hasUnselectChannelButton = false,
  error = false,
  hideWarning,
  loading,
}: Props) => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const pushNotification = usePushNotification();
  const [anchorEl, setAnchorEl] = useState<Element | null>(null);
  const [options, setOptions] = useState<IDropdownOption[]>([]);
  const [query, setQuery] = useState("");
  const [valueOption, setValueOption] = useState<IDropdownOption | null>(null);
  const { classes } = useStyles({ disabled });

  const handleOpenDropdown = useCallback(
    (event: React.SyntheticEvent) => {
      event.stopPropagation();
      if (anchorEl) {
        setAnchorEl(null);
        return;
      }
      if (disabled) {
        return;
      }
      setAnchorEl(event.currentTarget);
    },
    [disabled, anchorEl]
  );

  const handleCloseDropdown = useCallback(() => {
    setAnchorEl(null);
  }, []);

  const onSelectItems = useCallback(
    (items: string[] | null) => {
      handleCloseDropdown();
      if (items && items.length > 0) {
        onChange(items[0]);
      }
    },
    [handleCloseDropdown, onChange]
  );

  const fetchChannel = useCallback(async () => {
    const result = await dispatch(
      rawGet({ type: "slack_channels", path: `/by-slack-id/${value}/` })
    );
    if (isFulfilled(result)) {
      const response = result.payload as JSONAPIResponse;
      setValueOption({
        id: String(response.data.attributes?.slack_id),
        name: `# ${response.data.attributes?.name}`,
      });
    } else {
      pushNotification("default_error");
    }
  }, [dispatch, pushNotification, value]);

  useEffect(() => {
    if (value && valueOption?.id !== value) {
      fetchChannel();
    }
  }, [value, valueOption, fetchChannel]);

  const searchChannels = useCallback(async () => {
    const filters: { [key: string]: string } = {
      "name.icontains": query,
    };
    if (isShared !== undefined) {
      filters["is_shared"] = String(isShared);
    }
    const result = await dispatch(
      index({
        type: "slack_channels",
        options: { filters },
      })
    );
    if (isFulfilled(result)) {
      const response = result.payload as JSONAPIListResponse;
      setOptions(
        response.data.map((channel: any) => ({
          id: channel.attributes.slack_id,
          name: `# ${channel.attributes.name}`,
        }))
      );
    } else {
      pushNotification("default_error");
    }
  }, [dispatch, pushNotification, query, isShared]);

  const debouncedSearchChannels = useMemo(
    () => _.debounce((search: string) => setQuery(search), 300),
    []
  );

  useEffect(() => {
    searchChannels();
  }, [searchChannels]);

  useEffect(() => {
    if (!value) setValueOption(null);
  }, [value]);

  return (
    <div className={classes.wrapper}>
      <div className={classes.innerWrapper}>
        <Button
          label={valueOption?.name ?? intl.formatMessage(i18n.placeholder)}
          onClick={handleOpenDropdown}
          variant="tertiary"
          RightIcon={!!anchorEl ? ChevronTop : ChevronDown}
          disabled={disabled || loading}
          size="small"
          color={error ? "error" : undefined}
          loading={loading}
        />
        {hasUnselectChannelButton && value && (
          <Tooltip title={intl.formatMessage(i18n.unselectSlackChannel)}>
            <div>
              <Button
                LeftIcon={Close}
                size="small"
                variant="tertiary"
                onClick={() => onChange(null)}
                disabled={disabled || loading}
              />
            </div>
          </Tooltip>
        )}
      </div>
      {!hideWarning && (
        <Alert icon variant="warning" className={classes.alert}>
          <FormattedMessage
            {...i18n.privateChannelInfo}
            values={{
              lnk: (chunks: string) => (
                <Link
                  className={classes.link}
                  target="_blank"
                  to={{
                    pathname:
                      "https://intercom.help/Reveal4business/en/articles/8201401-connecting-a-private-slack-channel-to-reveal",
                  }}
                >
                  {chunks}
                </Link>
              ),
            }}
          />
        </Alert>
      )}
      <Dropdown
        options={options}
        value={value ? [value] : []}
        withSearch
        open={!!anchorEl}
        anchorEl={anchorEl}
        onChange={onSelectItems}
        onClose={handleCloseDropdown}
        hasAsyncSearch
        onAsyncSearch={debouncedSearchChannels}
      />
    </div>
  );
};

export default SlackChannelSelector;

/// I18N

const i18n = defineMessages({
  placeholder: {
    id: "SlackChannelSelector.placeholder",
    defaultMessage: "Select a Slack channel",
  },
  privateChannelInfo: {
    id: "SlackChannelSelector.privateChannelInfo",
    defaultMessage:
      "Public channels are listed by default. To connect a private Slack channel, you must first add the Reveal app to the channel. More information <lnk>here</lnk>.",
  },
  unselectSlackChannel: {
    id: "SlackChannelSelector.unselectSlackChannel",
    defaultMessage: "Unselect Slack channel",
  },
});

// CSS

const useStyles = makeStyles<{ disabled?: boolean }>()(
  (theme, { disabled }) => ({
    wrapper: {
      display: "flex",
      flexDirection: "column",
      gap: "12px",
    },
    innerWrapper: {
      display: "flex",
      gap: 4,
      "& svg[data-testid='CloseIcon']": {
        width: 10,
        height: 10,
      },
    },
    alert: {
      "& > div > p": {
        display: "inline-block!important", // Overrides the display flex of the FormattedMessage component
      },
    },
    link: {
      fontWeight: 500,
      color: theme.palette.text.primary,
      textDecoration: "underline",
      cursor: "pointer",
      "&:hover": {
        color: theme.palette.primary.main,
      },
    },
  })
);
