import Grid from "@mui/material/Grid";
import copy from "clipboard-copy";
import Tooltip from "components/ui/Tooltip";
import { T } from "components/ui/Typography";
import muiTheme from "config/theme";
import useSegment from "hooks/useSegment";
import useUserProfile from "hooks/useUserProfile";
import generic from "i18n/generic";
import { makeStyles } from "makeStyles";
import { useState } from "react";
import { FormattedMessage } from "react-intl";
import { Available360MappingColumns } from "redux/mapping360/defaults";
import { AvailableNearboundAccountsColumns } from "redux/mapping360NearboundAccounts/defaults";
import { AccountMappingStandardFieldName } from "screens/Frontoffice/screens/DataTables/screens/AccountMapping/shared/types";
import { DataTableType } from "screens/Frontoffice/screens/DataTables/shared/types";
import { WebhookEventName, WebhookService } from "services/WebhookService";

import { CellProps } from "../utils";

const OwnerCell = (props: CellProps) => {
  const { partnership, row, fieldName } = props;
  const { name, email } = getValues(props);
  const { classes, cx } = useStyles();
  const { track } = useSegment();
  const [copied, setCopied] = useState(false);
  const service = new WebhookService();
  const { profile } = useUserProfile();

  const handleCopy = async () => {
    await copy(email);
    setCopied(true);
    if (
      fieldName === AccountMappingStandardFieldName.rightOwnerName ||
      fieldName === AccountMappingStandardFieldName.rightOwnerFullName
    ) {
      track("Copied account owner email", {
        source: DataTableType.ACCOUNT_MAPPING,
      });
      if (row.type === "matches" || row.type === "matched_accounts") {
        service.track({
          profile: profile,
          eventName: WebhookEventName.RevealCopiedAEEmailFromAccountMapping,
          partnership: partnership,
          crmAccount: row,
        });
      } else if (row.type === "shared_accounts") {
        service.track({
          profile: profile,
          eventName: WebhookEventName.RevealCopiedAEEmailFromNewProspects,
          partnership: partnership,
          rawCompanyId: row.rawCompanyId,
        });
      }
    }
  };
  const handleClose = () => {
    setCopied(false);
  };

  return (
    <div className={classes.root}>
      <Tooltip
        title={
          email && (
            <>
              <Grid className={classes.tooltipHead}>
                <T className={classes.tooltipText}>
                  <b>{name}</b>
                </T>
                <T className={classes.tooltipTextCopied}>
                  {copied && <FormattedMessage {...generic.copied} />}
                </T>
              </Grid>
              <div onClick={handleCopy}>
                <T
                  color={muiTheme.palette.white}
                  className={classes.tooltipTextCopy}
                >
                  <i
                    className={cx("far", "fa-clipboard", classes.copyIcon)}
                  ></i>
                  {email}
                </T>
              </div>
            </>
          )
        }
        // @ts-expect-error ts-migrate(2322) FIXME: Type '{ children: Element; title: "" | Element | u... Remove this comment to see the full error message
        onClose={handleClose}
      >
        <span className={classes.container}>
          <T>{name}</T>
        </span>
      </Tooltip>
    </div>
  );
};

export default OwnerCell;

// Helpers

export const isOwnerCell = (props: CellProps) => {
  // We need a specific rendered for owner with an email
  const { name, email } = getValues(props);
  if (name && email) return true;
  return false;
};

const getValues = (props: CellProps) => {
  const { value, fieldName, row } = props;
  const isMatchedAccount = row.type === "matched_accounts";
  let name,
    email = null;
  if (
    fieldName === AccountMappingStandardFieldName.leftOwnerName ||
    fieldName === AccountMappingStandardFieldName.rightOwnerName ||
    fieldName === AccountMappingStandardFieldName.leftOwnerFullName ||
    fieldName === AccountMappingStandardFieldName.rightOwnerFullName
  ) {
    name = value;
    if (
      value &&
      row[fieldName.slice(0, isMatchedAccount ? -8 : -4) + "Email"]
    ) {
      email = row[fieldName.slice(0, isMatchedAccount ? -8 : -4) + "Email"];
    }
  }
  if (fieldName === Available360MappingColumns.ownerName && row.owner) {
    name = row.owner.fullName;
    email = row.owner.email;
  }
  if (fieldName === AvailableNearboundAccountsColumns.OwnerFullName) {
    name = row[AvailableNearboundAccountsColumns.OwnerFullName];
    email = row[AvailableNearboundAccountsColumns.OwnerEmail];
  }
  return { name, email };
};

// CSS

const useStyles = makeStyles()((theme) => ({
  root: {
    textAlign: "center",
  },
  container: {
    display: "inline-flex",
    alignItems: "center",
    borderRadius: 4,
    border: `1px solid ${theme.palette.greyscale250}`,
    padding: "3px 6px",
    height: 24,
    cursor: "default",
    "&:hover": {
      backgroundColor: theme.palette.ivory,
    },
  },
  icon: {
    color: theme.palette.comet,
    marginRight: 5,
    fontSize: 20,
  },
  copyIcon: {
    fontSize: "1.3em",
    marginRight: theme.spacing(1),
    cursor: "pointer",
  },
  tooltipHead: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "flex-start",
    flexDirection: "row",
  },
  tooltipTextCopied: {
    marginLeft: theme.spacing(2),
    minWidth: 50,
    marginBottom: 5,
  },
  tooltipText: {
    cursor: "default",
    marginBottom: 5,
  },
  tooltipTextCopy: {
    cursor: "pointer",
    "&:hover": {
      // @ts-expect-error ts-migrate(2339) FIXME: Property 'light' does not exist on type 'Color'.
      color: theme.palette.grey.light,
    },
  },
}));
