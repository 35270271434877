import { defineMessages } from "react-intl";

export default defineMessages({
  resetPwdTitle: {
    id: "resetPassword.resetPwdTitle",
    description: "Title of the reset password page",
    defaultMessage: "Reset your password",
  },
  instructionsTypeEmail: {
    id: "resetPassword.instructionsTypeEmail",
    description:
      "Instructions at first step, when user is asked to type his email",
    defaultMessage:
      "Enter your e-mail address below to receive the instructions to reset your password",
  },
  enterEmailPh: {
    id: "resetPassword.enterEmailPh",
    description: 'Placeholder for the "Email" field',
    defaultMessage: "Enter your e-mail",
  },
  sendInstructionsBtn: {
    id: "resetPassword.sendInstructionsBtn",
    description:
      "Text for the button that sends email to server to send instructions",
    defaultMessage: "Get link by e-mail",
  },
  instructionsEmailSent: {
    id: "resetPassword.instructionsEmailSent",
    description:
      "Title after email has been sent, when user is told they will receive an email",
    defaultMessage: "Your e-mail has been sent.",
  },
  instructionsReceiveEmail: {
    id: "resetPassword.instructionsReceiveEmail",
    description:
      "Instructions after email has been sent, when user is told they will receive an email",
    defaultMessage:
      "Check your e-mail and click on the secure link to reset your password.",
  },
  createNewPwd: {
    id: "resetPassword.createNewPwd",
    description: "Create new password title",
    defaultMessage: "Create a new password",
  },
  newPwdPh: {
    id: "resetPassword.newPwdPh",
    description: 'Placeholder for the "New Password" field',
    defaultMessage: "New Password",
  },
  changePwdBtn: {
    id: "resetPassword.changePwdBtn",
    description: "Label for the button that updated the password",
    defaultMessage: "Change my password",
  },
  invalidToken: {
    id: "resetPassword.invalidToken",
    description:
      "Message displayed to user when token is not valid anymore. Tells them to start over the process.",
    defaultMessage: "This link is not valid anymore.",
  },
  sendNewLinkBtn: {
    id: "resetPassword.sendNewLinkBtn",
    description: "Text for the button that sends email with link again",
    defaultMessage: "Click here to get a new link",
  },
});
