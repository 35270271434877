import { Box } from "@mui/material";
import withCompanyPermissions from "components/hoc/withCompanyPermissions";
import { WithUserProfile } from "components/hoc/withUserProfile";
import { T } from "components/ui/Typography";
import useTrack from "hooks/useTrack";
import { makeStyles } from "makeStyles";
import { Ref, useEffect } from "react";
import { defineMessages, FormattedMessage } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import {
  selectAnalyticsView,
  selectHasAdvancedAnalytics,
} from "redux/analytics/selectors";
import { loadView } from "redux/analytics/thunks";
import { selectDashboardData } from "redux/dashboard/selectors";
import { initializePartnershipsAndCompanyNames } from "redux/init/thunks";
import { selectUpsidesData } from "redux/upsides/selectors";
import { loadPartnershipUpsidePotential } from "redux/upsides/thunks";
import { AdvancedAnalyticsEvent } from "tracking";

import { UnlockTableDialog } from "../../../shared/components/UnlockTableDialog";
import { useTableRows } from "../../../shared/hooks";
import { formatFilters } from "../shared/utils";
import AnalyticsFilterWidget from "./AnalyticsFilterWidget";
import AnalyticsOfflineFilter from "./AnalyticsOfflineFilter";
import { AnalyticsTable } from "./AnalyticsTable";
import AnalyticsTagFilter from "./AnalyticsTagFilter";
import { AnalyticsToggleFilter } from "./AnalyticsToggleFilter";
import { SummaryMetrics } from "./SummaryMetrics";

const Analytics = ({
  dialogRef,
}: { dialogRef?: Ref<HTMLElement> } & WithUserProfile) => {
  const { classes } = useStyles();
  const dispatch = useDispatch();
  useTrack(AdvancedAnalyticsEvent.viewAnalyticsPage);
  const { ready, view } = useSelector(selectAnalyticsView);
  const { ready: dashboardReady } = useSelector(selectDashboardData);
  const { filters, sort } = view ?? {};
  const { status } = useSelector(selectUpsidesData);
  const hasAdvancedAnalytics = useSelector(selectHasAdvancedAnalytics);

  useEffect(() => {
    dispatch(loadView());
  }, [dispatch]);

  useEffect(() => {
    if (dashboardReady === null) {
      dispatch(initializePartnershipsAndCompanyNames());
    } else if (dashboardReady && status === null) {
      dispatch(loadPartnershipUpsidePotential(hasAdvancedAnalytics));
    }
  }, [dashboardReady, status, dispatch, hasAdvancedAnalytics]);

  const { sync, records, count, fetching, loadMore } = useTableRows(
    "partner_analytics",
    formatFilters(filters, hasAdvancedAnalytics),
    sort,
    null,
    true,
    undefined,
    undefined,
    undefined,
    undefined,
    true
  );

  return (
    <div className={classes.root}>
      <div className={classes.header}>
        <Box>
          <Box className={classes.title}>
            <T h2 bold>
              <FormattedMessage {...i18n.title} />
            </T>
            <AnalyticsToggleFilter disabled={!hasAdvancedAnalytics} />
          </Box>
          <T className={classes.subtitle}>
            <FormattedMessage {...i18n.subtitle} />
          </T>
        </Box>
        <div className={classes.filtersContainer}>
          <AnalyticsOfflineFilter disabled={!hasAdvancedAnalytics} />
          <Box display="flex">
            <AnalyticsTagFilter disabled={!hasAdvancedAnalytics} />
            <AnalyticsFilterWidget disabled={!hasAdvancedAnalytics} />
          </Box>
        </div>
      </div>
      <SummaryMetrics />
      <AnalyticsTable
        isUnlocked={hasAdvancedAnalytics}
        rows={!ready ? [] : records}
        rowsCount={count}
        loading={!ready || !sync || fetching || count === null}
        loadMore={loadMore}
        fetching={fetching}
      />
      {!hasAdvancedAnalytics && (
        <UnlockTableDialog
          buttonLabel={i18n.unlockDialogButton}
          from="Partner Analytics Page"
          subtitle={i18n.unlockDialogSubtitle}
          title={i18n.unlockDialogTitle}
        />
      )}
    </div>
  );
};

export default withCompanyPermissions(["company.partners_access"])(Analytics);

/// CSS

const useStyles = makeStyles()((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    height: "100%",
  },
  header: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    margin: theme.spacing(2.5, 2.5, 0, 2.5),
    [theme.breakpoints.down("lg")]: {
      marginLeft: theme.spacing(1),
    },
  },
  title: {
    alignItems: "center",
    display: "flex",
    columnGap: 12,
    marginBottom: theme.spacing(1),
  },
  subtitle: {
    color: theme.palette.text.secondary,
  },
  filtersContainer: {
    display: "flex",
    height: "100%",
    alignItems: "flex-end",
    columnGap: theme.spacing(2.5),
  },
}));

/// I18N

const i18n = defineMessages({
  title: {
    id: "crm.Analytics.title",
    defaultMessage: "Analytics",
  },
  unlockDialogTitle: {
    id: "crm.Analytics.unlockDialogTitle",
    defaultMessage: "Unlock Partner Analytics",
  },
  unlockDialogSubtitle: {
    id: "crm.Analytics.unlockDialogSubtitle",
    defaultMessage: "Partner Analytics is limited to 3 rows for free users",
  },
  unlockDialogButton: {
    id: "crm.Analytics.unlockDialogButton",
    defaultMessage: "Upgrade plan",
  },
  subtitle: {
    id: "crm.Analytics.subtitle",
    defaultMessage:
      "The Analytics feature analyzes the value of each partnership, helping you prioritise them and define the best way to collaborate with them.",
  },
});
