import { createSvgIcon } from "@mui/material/utils";

import { SvgIcon } from "./types";

const displayName = "Partnerships";

const Partnerships: SvgIcon = createSvgIcon(
  // Using transform to enlarge from provided viewBox="0 0 20 20"
  // to default "0 0 24 24" from `createSvgIcon` -> `SvgIcon`.
  <g fill="none" transform="matrix(1.2,0,0,1.2,0,0)">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.6593 3.0268C10.7352 2.25047 9.38851 2.24509 8.45831 3.01402L2.78218 7.70605C2.20764 8.18098 1.875 8.88752 1.875 9.63294V15.6252C1.875 17.0059 2.99429 18.1252 4.375 18.1252H15.625C17.0057 18.1252 18.125 17.0059 18.125 15.6252V9.62382C18.125 8.88538 17.7985 8.18471 17.2332 7.7097L11.6593 3.0268ZM9.25472 3.97747C9.71982 3.59301 10.3932 3.59569 10.8552 3.98386L16.4291 8.66676C16.7118 8.90426 16.875 9.2546 16.875 9.62382V15.6252C16.875 16.3156 16.3154 16.8752 15.625 16.8752H13.125V12.5001C13.125 11.1194 12.0057 10.0001 10.625 10.0001H9.375C7.99429 10.0001 6.875 11.1194 6.875 12.5001V16.8752H4.375C3.68464 16.8752 3.125 16.3156 3.125 15.6252V9.63294C3.125 9.26023 3.29132 8.90696 3.57859 8.6695L9.25472 3.97747ZM8.125 16.8752H11.875V12.5001C11.875 11.8098 11.3154 11.2501 10.625 11.2501H9.375C8.68464 11.2501 8.125 11.8098 8.125 12.5001V16.8752Z"
      fill="currentColor"
    />
  </g>,

  displayName
);

Partnerships.displayName = displayName;
export default Partnerships;
