import { createSvgIcon } from "@mui/material/utils";

import { SvgIcon } from "./types";

const displayName = "ProfileCircle";

const ProfileCircle: SvgIcon = createSvgIcon(
  // Using transform to enlarge from provided viewbox="0 0 16 16"
  // to default "0 0 24 24" from `createSvgIcon` -> `SvgIcon`.
  <g fill="none" transform="matrix(1.5,0,0,1.5,0,0)">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2.5 8C2.5 4.96243 4.96243 2.5 8 2.5C11.0376 2.5 13.5 4.96243 13.5 8C13.5 9.41999 12.9619 10.7143 12.0784 11.6901C11.697 11.1146 11.1966 10.6241 10.6082 10.2534C9.82721 9.76133 8.92302 9.50024 8 9.50024C7.07698 9.50024 6.17278 9.76133 5.39183 10.2534C4.80337 10.6241 4.30297 11.1146 3.92157 11.6901C3.03812 10.7143 2.5 9.41999 2.5 8ZM8 1.5C4.41015 1.5 1.5 4.41015 1.5 8C1.5 11.5899 4.41015 14.5 8 14.5C11.5899 14.5 14.5 11.5899 14.5 8C14.5 4.41015 11.5899 1.5 8 1.5ZM5.49988 6.40015C5.49988 5.01943 6.61917 3.90015 7.99988 3.90015C9.38059 3.90015 10.4999 5.01943 10.4999 6.40015C10.4999 7.78086 9.38059 8.90015 7.99988 8.90015C6.61917 8.90015 5.49988 7.78086 5.49988 6.40015Z"
      fill="currentColor"
    />
  </g>,
  displayName
);

ProfileCircle.displayName = displayName;
export default ProfileCircle;
