import history from "_history";
import { KeyboardArrowDown, KeyboardArrowRight } from "@mui/icons-material";
import CircularProgress from "@mui/material/CircularProgress";
import Grid from "@mui/material/Grid";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import { isFulfilled, isRejected } from "@reduxjs/toolkit";
import { Check } from "components/icons";
import BaseLoginPage from "components/ui/BaseLoginPage";
import Box from "components/ui/Box";
import Button from "components/ui/Button";
import Checkbox from "components/ui/Checkbox";
import { T } from "components/ui/Typography";
import { goToNext } from "helpers/goToNext";
import usePushNotification from "hooks/usePushNotification";
import useSegment from "hooks/useSegment";
import useUserProfile from "hooks/useUserProfile";
import _ from "lodash";
import { makeStyles } from "makeStyles";
import { NewLeadsShare } from "models/AccountMappingSettings";
import { PayingFeature } from "models/CompanyPayingFeatureSubscription";
import Partnership, { DataShare } from "models/Partnership";
import Record from "models/Record";
import { JSONSerializable } from "models/types";
import User from "models/User";
import {
  ChangeEvent,
  Dispatch,
  SetStateAction,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import {
  defineMessages,
  FormattedHTMLMessage,
  FormattedMessage,
} from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import { selectActivePayingFeatures } from "redux/api/selectors";
import { rawPost, rawPut } from "redux/api/thunks";
import {
  selectOnboardingPartnershipsToAcceptData,
  selectOnboardingPartnershipsToShareData,
} from "redux/onboarding/selectors";
import {
  initializePartnersToAccept,
  initializePartnersToShare,
} from "redux/onboarding/thunks";
import { RevealStore } from "redux/typing";
import { fetchProfile } from "redux/user/thunks";
import UserService from "services/UserService";
import { PartnershipEvent } from "tracking";

import ActivatePartnershipsConfirmDialog from "./ActivatePartnershipsConfirmDialog";
import PartnerItem from "./PartnerItem";

const THIRTY_DAYS_IN_MILLIS = 30 * 24 * 60 * 60 * 1000;
export const validatePartnerships = (profile: User, store: RevealStore) => {
  const service = new UserService();
  const company = profile.company;
  const isSalesInvitationsPayingFeatureEnabled = company.companyPayingFeatureSubscriptions.find(
    (payingFeature: any) =>
      payingFeature.payingFeature === PayingFeature.SalesInvitations
  ).enabled;
  const canInvitePartner =
    Boolean(profile.hasPermissions(["company.manage_partnerships"], company)) ||
    (Boolean(profile.hasPermissions(["company.partnership_invite"], company)) &&
      isSalesInvitationsPayingFeatureEnabled);
  const nbOfPartnerships =
    company.activePartnership + company.pendingPartnership;

  if (
    canInvitePartner &&
    !service.isImpersonating &&
    !profile.hasOnboardingSkipStep(
      "partnership_activation",
      THIRTY_DAYS_IN_MILLIS
    ) &&
    // Trigger the onboarding step when total # of partnerships for the company >= 3
    nbOfPartnerships > 2
  ) {
    return false;
  }
  return true;
};

type ISection = "partners" | "overlaps" | "whitespace";

export const OnboardingActivatePartnerships = () => {
  const { track } = useSegment();
  const location = useLocation();
  const _history = useHistory();
  const { profile } = useUserProfile();
  const { cx, classes } = useStyles();
  const pushNotification = usePushNotification();
  const dispatch = useDispatch();
  const [isExpanded, setIsExpanded] = useState([true, true, true]);

  const fromSidebar = location.hash === "#fromSidebar";
  const payingFeatures = useSelector(selectActivePayingFeatures);
  const skipStep =
    !profile.canInvitePartner(payingFeatures) ||
    (!fromSidebar &&
      profile.hasOnboardingSkipStep(
        "partnership_activation",
        THIRTY_DAYS_IN_MILLIS
      ));

  const {
    ready: notAcceptedReady,
    partnerships: notAcceptedPartnerships,
  } = useSelector(selectOnboardingPartnershipsToAcceptData);

  const [selectedToAcceptIds, setSelectedToAcceptIds] = useState<number[]>([]);
  const isAllNotAcceptedChecked =
    notAcceptedPartnerships.length === selectedToAcceptIds.length;
  const allNotAcceptedPartnershipsIds = notAcceptedPartnerships.map(
    (partnership: Partnership) => partnership.id
  );

  // Not full shared partnerships
  const isSalesUser = profile.isSales();
  // We always display only user favorite partnerships.
  // Unless the hash is present AND the user is admin. In that case we display all partnerships.
  const displayOnlyFavorites = !profile.isCompanyAdmin() || !fromSidebar;
  const {
    ready: notSharedReady,
    partnerships: notSharedPartnerships,
  } = useSelector(
    selectOnboardingPartnershipsToShareData(displayOnlyFavorites)
  );
  const notSharedOverlapsPartnerships = useMemo(
    () =>
      notSharedPartnerships.filter((partnership) => {
        const hasDataSource = partnership.hasDataSource();
        return (
          hasDataSource &&
          !Partnership.isSharingOverlapping(
            partnership.getDataSharingStatus(profile.company)
          )
        );
      }),
    [notSharedPartnerships, profile.company]
  );
  const notSharedWhitespacePartnerships = useMemo(
    () =>
      notSharedPartnerships.filter((partnership) => {
        const hasDataSource = partnership.hasDataSource();
        const dataSharingStatus = partnership.getDataSharingStatus(
          profile.company
        );
        return (
          hasDataSource && !Partnership.isSharingWhitespace(dataSharingStatus)
        );
      }),
    [notSharedPartnerships, profile.company]
  );

  const [selectedToShareOverlapsIds, setSelectedToShareOverlapsIds] = useState<
    number[]
  >([]);
  const [
    selectedToShareWhitespaceIds,
    setSelectedToShareWhitespaceIds,
  ] = useState<number[]>([]);
  const [initialStatuses, setInitialStatuses] = useState<{
    [id: number]: DataShare;
  }>({});
  const [sharingStatuses, setSharingStatuses] = useState<{
    [id: number]: DataShare;
  }>({});
  const ready = notAcceptedReady && notSharedReady;

  const isAllNotSharedOverlapsChecked =
    notSharedOverlapsPartnerships.length === selectedToShareOverlapsIds.length;
  const allNotSharedOverlapsIds = notSharedOverlapsPartnerships.map(
    (partnership: Partnership) => partnership.id
  );

  const isAllNotSharedWhitespaceChecked =
    notSharedWhitespacePartnerships.length ===
    selectedToShareWhitespaceIds.length;
  const allNotSharedWhitespaceIds = notSharedWhitespacePartnerships.map(
    (partnership: Partnership) => partnership.id
  );

  const totalPartners =
    notAcceptedPartnerships.length + notSharedPartnerships.length;
  const totalSelectedPartners = _.uniq([
    ...selectedToAcceptIds,
    ...selectedToShareOverlapsIds,
    ...selectedToShareWhitespaceIds,
  ]).length;

  const [loading, setLoading] = useState<boolean>(false);
  const [confirmDialogIsOpen, setConfirmDialogIsOpen] = useState<boolean>(
    false
  );

  const setStepAsViewedAndRedirect = async () => {
    // Update or create to update the completedAt field.
    const onboardingSkip = profile.onboardingSkips?.find(
      (onboardingSkip: Record) =>
        onboardingSkip.step === "partnership_activation"
    );
    if (onboardingSkip) {
      await dispatch(
        rawPut({
          type: "onboarding_skips",
          id: onboardingSkip.id,
          path: "",
          payload: {
            data: {
              id: onboardingSkip.id,
              type: "onboarding_skips",
              attributes: {
                completed_at: new Date().toISOString(),
              },
            },
          },
        })
      );
    } else {
      await dispatch(
        rawPost({
          type: "onboarding_skips",
          path: "",
          payload: {
            data: {
              type: "onboarding_skips",
              attributes: {
                step: "partnership_activation",
              },
            },
          },
        })
      );
    }
    const fetchProfilerResult = await dispatch(fetchProfile());
    if (isRejected(fetchProfilerResult)) {
      throw new Error("Error fetching profile");
    }
    goNext();
  };

  const handleSubmit = async () => {
    setLoading(true);
    setConfirmDialogIsOpen(false);
    // Payload
    const partnershipsToAccept = selectedToAcceptIds.map((id) => ({
      type: "partnerships",
      id,
    }));

    // Arrays for tracking
    const onlyNewProspects: number[] = [];
    const onlyOverlapping: number[] = [];
    const sharingBoth: number[] = [];
    // Payload
    const partnershipsToUpdate: JSONSerializable[] = [];

    _.uniq([
      ...selectedToShareOverlapsIds,
      ...selectedToShareWhitespaceIds,
    ]).forEach((id) => {
      if (initialStatuses[id] === DataShare.Nothing) {
        if (
          sharingStatuses[id] === DataShare.Overlapping ||
          sharingStatuses[id] === DataShare.OverlappingCommonCustomers
        ) {
          onlyOverlapping.push(id);
        } else {
          sharingBoth.push(id);
        }
      } else {
        onlyNewProspects.push(id);
      }
      partnershipsToUpdate.push({
        type: "partnerships",
        id,
        attributes: {
          sharing_settings: {
            share_accounts: Partnership.getAccountStatusSharingFromDataSharingStatus(
              sharingStatuses[id]
            ),
            share_new_leads: Partnership.isSharingWhitespace(
              sharingStatuses[id]
            )
              ? NewLeadsShare.Always
              : NewLeadsShare.Never,
          },
        },
      });
    });

    let errors = false;
    if (partnershipsToAccept.length > 0) {
      const newPartnershipsResult = await dispatch(
        rawPost({
          type: "partnerships",
          path: "bulk-accept-invite/",
          payload: { data: partnershipsToAccept },
        })
      );
      if (!isFulfilled(newPartnershipsResult)) {
        errors = true;
      }
    }

    if (partnershipsToUpdate.length > 0) {
      const updatedPartnershipsResult = await dispatch(
        rawPost({
          type: "partnerships",
          path: "bulk-update-account-mapping-settings/",
          payload: { data: partnershipsToUpdate },
        })
      );
      if (!isFulfilled(updatedPartnershipsResult)) {
        errors = true;
      }
    }

    if (!errors) {
      // Tracking section
      // Bulk event
      track("Bulk activated partnerships", {
        nbOfPartnersAccepted: selectedToAcceptIds.length,
        nbOfAcctOverlapsShared: onlyOverlapping.length + sharingBoth.length,
        nbOfNewProspectShared: onlyNewProspects.length + sharingBoth.length,
      });
      // Accepted invite
      selectedToAcceptIds.forEach((id) => {
        const partner = notAcceptedPartnerships
          .find((partnership: Partnership) => partnership.id === id)
          ?.getPartner(profile);
        track(PartnershipEvent.acceptedInvite, {
          partnerName: partner?.name,
          partnerId: partner?.id,
          from: fromSidebar ? "Bulk - Pending Partners" : "Bulk - Onboarding",
        });
      });
      if (selectedToAcceptIds.length) {
        profile.identify({
          activePartnershipsCount:
            Number(_.get(profile, "company.activePartnership") ?? 0) +
            selectedToAcceptIds.length,
          partnershipInvitesCount:
            Number(_.get(profile, "company.createdPartnership") ?? 0) -
            selectedToAcceptIds.length,
          pendingPartnershipCount:
            Number(_.get(profile, "company.pendingPartnership") ?? 0) -
            selectedToAcceptIds.length,
        });
      }
      // Enabled data sharing
      selectedToShareOverlapsIds.forEach((id) => {
        const partner = notSharedPartnerships
          .find((partnership: Partnership) => partnership.id === id)
          ?.getPartner(profile);
        if (sharingBoth.includes(id)) {
          track(PartnershipEvent.enabledAO, {
            partnerName: partner?.name,
            partnerId: partner?.id,
            from: fromSidebar ? "Bulk - Pending Partners" : "Bulk - Onboarding",
          });
          track(PartnershipEvent.enabledNP, {
            partnerName: partner?.name,
            partnerId: partner?.id,
            from: fromSidebar ? "Bulk - Pending Partners" : "Bulk - Onboarding",
          });
        } else {
          track(
            onlyOverlapping.includes(id)
              ? PartnershipEvent.enabledAO
              : PartnershipEvent.enabledNP,
            {
              partnerName: partner?.name,
              partnerId: partner?.id,
              from: fromSidebar
                ? "Bulk - Pending Partners"
                : "Bulk - Onboarding",
            }
          );
        }
      });
      // Computing the remaining partnerships
      // to update dashboard and to know
      // if we need to display the link in the sidebar
      dispatch(initializePartnersToAccept());
      await dispatch(initializePartnersToShare());
      if (fromSidebar) {
        goNext();
      } else {
        setStepAsViewedAndRedirect();
      }
    } else {
      pushNotification("default_error");
      setLoading(false);
    }
  };

  const getSectionState = (section: ISection) => {
    let stateSetter: Dispatch<SetStateAction<number[]>>;
    let allIds, selectedIds: number[];
    switch (section) {
      case "overlaps":
        allIds = allNotSharedOverlapsIds;
        stateSetter = setSelectedToShareOverlapsIds;
        selectedIds = selectedToShareOverlapsIds;
        break;
      case "partners":
        allIds = allNotAcceptedPartnershipsIds;
        stateSetter = setSelectedToAcceptIds;
        selectedIds = selectedToAcceptIds;
        break;
      case "whitespace":
        allIds = allNotSharedWhitespaceIds;
        stateSetter = setSelectedToShareWhitespaceIds;
        selectedIds = selectedToShareWhitespaceIds;
        break;
      default:
        throw new Error("Unknown section");
    }
    return { stateSetter, selectedIds, allIds };
  };

  const toggleSelectAll = (
    event: ChangeEvent<HTMLInputElement>,
    section: ISection
  ) => {
    const { stateSetter, allIds } = getSectionState(section);
    let sharingStatusesUpdated = _.cloneDeep(sharingStatuses);
    if (event.target.checked) {
      stateSetter(allIds);
      if (section === "overlaps") {
        allNotSharedOverlapsIds.forEach((id) => {
          if (Partnership.isSharingOverlapping(sharingStatuses[id])) {
            return;
          }
          sharingStatusesUpdated[id] = Partnership.toggleSharingOverlapping(
            sharingStatuses[id]
          );
        });
      }
      if (section === "whitespace") {
        allNotSharedWhitespaceIds.forEach((id) => {
          if (Partnership.isSharingWhitespace(sharingStatuses[id])) {
            return;
          }
          sharingStatusesUpdated[id] = Partnership.toggleSharingWhitespace(
            sharingStatuses[id]
          );
        });
      }
    } else {
      stateSetter([]);
      if (section === "overlaps") {
        allNotSharedOverlapsIds.forEach((id) => {
          if (!Partnership.isSharingOverlapping(sharingStatuses[id])) {
            return;
          }
          sharingStatusesUpdated[id] = Partnership.toggleSharingOverlapping(
            sharingStatuses[id]
          );
        });
      }
      if (section === "whitespace") {
        allNotSharedWhitespaceIds.forEach((id) => {
          if (!Partnership.isSharingWhitespace(sharingStatuses[id])) {
            return;
          }
          sharingStatusesUpdated[id] = Partnership.toggleSharingWhitespace(
            sharingStatuses[id]
          );
        });
      }
    }
    setSharingStatuses(sharingStatusesUpdated);
  };

  const toggleSelectOne = (
    event: ChangeEvent<HTMLInputElement>,
    section: ISection
  ) => {
    const { stateSetter, selectedIds } = getSectionState(section);
    const value = +event.target.value;
    if (event.target.checked) {
      stateSetter([...selectedIds, value]);
    } else {
      stateSetter(selectedIds.filter((current) => current !== value).sort());
    }
    if (section === "overlaps") {
      updateSharingStatus(
        value,
        Partnership.toggleSharingOverlapping(sharingStatuses[value])
      );
      if (!event.target.checked) {
        setSelectedToShareWhitespaceIds((ids) =>
          ids.filter((id) => id !== value)
        );
      }
    }
    if (section === "whitespace") {
      updateSharingStatus(
        value,
        Partnership.toggleSharingWhitespace(sharingStatuses[value])
      );
      if (event.target.checked) {
        setSelectedToShareOverlapsIds((ids) => _.uniq([...ids, value]));
      }
    }
  };

  const updateSharingStatus = (id: number, newSettings: DataShare) => {
    let newStatus = { ...sharingStatuses };
    newStatus[id] = newSettings;
    setSharingStatuses(newStatus);
  };

  const goNext = useCallback(() => {
    _history.push(goToNext(location, "/"));
  }, [_history, location]);

  const goBack = useCallback(() => {
    history.goBack({ fallback: "/partnerships" });
  }, []);

  // Set "all" checkbox from first section to true when is ready
  useEffect(() => {
    if (notAcceptedReady) {
      setSelectedToAcceptIds(allNotAcceptedPartnershipsIds);
    }
  }, [notAcceptedReady]); // eslint-disable-line react-hooks/exhaustive-deps

  // Set "all" checkbox from second section to true when is ready
  useEffect(() => {
    // Sales team does not see this section
    if (notSharedReady && !isSalesUser) {
      setSelectedToShareOverlapsIds(allNotSharedOverlapsIds);
      setSelectedToShareWhitespaceIds(allNotSharedWhitespaceIds);
      // Store initial statuses to track
      // and update statuses to enable toggles by default
      let initStatuses: { [id: number]: DataShare } = {};
      let currentStatuses: { [id: number]: DataShare } = {};
      notSharedPartnerships.forEach((partnership: Partnership) => {
        initStatuses[partnership.id] = partnership.getDataSharingStatus(
          profile.company
        );
        currentStatuses[partnership.id] = updateInitialState(
          partnership.getDataSharingStatus(profile.company)
        );
      });
      setInitialStatuses(initStatuses);
      setSharingStatuses(currentStatuses);
    }
  }, [notSharedReady]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (skipStep) {
      goNext();
    } else {
      if (
        ready &&
        (totalPartners === 0 ||
          (notAcceptedPartnerships.length === 0 && isSalesUser)) // Sales team does not see partnerships to share section
      ) {
        setStepAsViewedAndRedirect();
      }
    }
  }, [ready, skipStep]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleExpand = (index: number) => () => {
    const updated = [...isExpanded];
    updated[index] = !updated[index];
    setIsExpanded(updated);
  };

  return (
    <BaseLoginPage
      hidePaper
      rightPanelCentered={
        ready &&
        notAcceptedPartnerships.length +
          notSharedOverlapsPartnerships.length +
          notSharedWhitespacePartnerships.length <=
          3
      }
      title={i18n.leftTitle}
      titleSmall={i18n.leftTitle}
      values={{ totalPartners: totalPartners }}
      subtitle={i18n.leftSubtitle}
    >
      <Grid
        container
        direction="column"
        alignContent="center"
        className={classes.root}
      >
        <ActivatePartnershipsConfirmDialog
          isOpen={confirmDialogIsOpen}
          handleClose={() => setConfirmDialogIsOpen(false)}
          handleSave={() => handleSubmit()}
          pendingNumber={selectedToAcceptIds.length}
          totalNumber={totalSelectedPartners}
          updatingOverlapsNumber={selectedToShareOverlapsIds.length}
          updatingWhitespaceNumber={selectedToShareWhitespaceIds.length}
        />
        <div className={classes.saveContainer}>
          <Button
            label={fromSidebar ? i18n.close : i18n.skip}
            variant="secondary"
            onClick={fromSidebar ? goBack : setStepAsViewedAndRedirect}
          />
          <Button
            label={
              <FormattedMessage
                {...i18n.save}
                values={{ totalPartners: totalSelectedPartners }}
              />
            }
            LeftIcon={Check}
            disabled={!ready || loading || totalSelectedPartners === 0}
            onClick={setConfirmDialogIsOpen}
          />
        </div>

        {(!notAcceptedReady ||
          (notAcceptedReady && notAcceptedPartnerships.length > 0)) && (
          <Box className={classes.root}>
            <div className={classes.header}>
              <div className={classes.toggleItem} onClick={handleExpand(0)}>
                {isExpanded[0] ? <KeyboardArrowDown /> : <KeyboardArrowRight />}
                <T className={classes.sectionTitle}>
                  <FormattedHTMLMessage
                    {...i18n.firstSectionTitle}
                    values={{ totalPartners: notAcceptedPartnerships.length }}
                  />
                </T>
              </div>
              <Checkbox
                checked={isAllNotAcceptedChecked}
                indeterminate={
                  !isAllNotAcceptedChecked && !!selectedToAcceptIds.length
                }
                onChange={(e: ChangeEvent<HTMLInputElement>) =>
                  toggleSelectAll(e, "partners")
                }
                label={i18n.selectAll}
                classes={{ root: classes.checkbox }}
                labelPlacement="start"
                disabled={!notAcceptedReady || loading}
                withoutMargin
              />
            </div>
            {!notAcceptedReady || loading ? (
              <Grid item xs={12} className={classes.loaderContainer}>
                <CircularProgress />
              </Grid>
            ) : isExpanded[0] ? (
              <List className={classes.body}>
                {notAcceptedPartnerships.map((partnership: Partnership) => (
                  <ListItem key={partnership.id} className={classes.listItem}>
                    <PartnerItem
                      key={`accept-partnership-toggle-${partnership.id}`}
                      partnership={partnership}
                      checked={selectedToAcceptIds.includes(partnership.id)}
                      toggleItem={(e: ChangeEvent<HTMLInputElement>) =>
                        toggleSelectOne(e, "partners")
                      }
                    />
                  </ListItem>
                ))}
              </List>
            ) : (
              <></>
            )}
          </Box>
        )}

        {!isSalesUser &&
          (!notSharedReady ||
            (notSharedReady && notSharedOverlapsPartnerships.length > 0)) && (
            <Box className={classes.root}>
              <div className={classes.header}>
                <div className={classes.toggleItem} onClick={handleExpand(1)}>
                  {isExpanded[1] ? (
                    <KeyboardArrowDown />
                  ) : (
                    <KeyboardArrowRight />
                  )}
                  <T className={classes.sectionTitle}>
                    <FormattedHTMLMessage
                      {...i18n.secondSectionTitle}
                      values={{
                        totalPartners: notSharedOverlapsPartnerships.length,
                      }}
                    />
                  </T>
                </div>
                <Checkbox
                  checked={isAllNotSharedOverlapsChecked}
                  onChange={(e: ChangeEvent<HTMLInputElement>) =>
                    toggleSelectAll(e, "overlaps")
                  }
                  indeterminate={
                    !isAllNotSharedOverlapsChecked &&
                    !!selectedToShareOverlapsIds.length
                  }
                  label={i18n.selectAll}
                  classes={{ root: classes.checkbox }}
                  labelPlacement="start"
                  disabled={!notSharedReady || loading}
                  withoutMargin
                />
              </div>
              {!notSharedReady || loading ? (
                <Grid item xs={12} className={classes.loaderContainer}>
                  <CircularProgress />
                </Grid>
              ) : isExpanded[1] ? (
                <List
                  className={cx(classes.largeBody, "share-overlaps-section")}
                >
                  {notSharedOverlapsPartnerships.map(
                    (partnership: Partnership) => (
                      <PartnerItem
                        partnership={partnership}
                        key={`share-overlaps-toggle-${partnership.id}`}
                        checked={selectedToShareOverlapsIds.includes(
                          partnership.id
                        )}
                        overviewMessage={
                          !!(
                            partnership?.insights?.companyCommonCustomers
                              ?.count ||
                            partnership?.insights?.companyProspectsToCustomers
                              ?.count
                          ) && (
                            <FormattedHTMLMessage
                              {...i18n.overlapsOverview}
                              values={{
                                commonCustomers:
                                  partnership?.insights?.companyCommonCustomers
                                    ?.count ?? "",
                                prospectToCustomers:
                                  partnership?.insights
                                    ?.companyProspectsToCustomers?.count ?? "",
                              }}
                            />
                          )
                        }
                        toggleItem={(e: ChangeEvent<HTMLInputElement>) =>
                          toggleSelectOne(e, "overlaps")
                        }
                      />
                    )
                  )}
                </List>
              ) : (
                <></>
              )}
            </Box>
          )}

        {!isSalesUser &&
          (!notSharedReady ||
            (notSharedReady && notSharedWhitespacePartnerships.length > 0)) && (
            <Box className={classes.root}>
              <div className={classes.header}>
                <div className={classes.toggleItem} onClick={handleExpand(2)}>
                  {isExpanded[2] ? (
                    <KeyboardArrowDown />
                  ) : (
                    <KeyboardArrowRight />
                  )}
                  <T className={classes.sectionTitle}>
                    <FormattedHTMLMessage
                      {...i18n.thirdSectionTitle}
                      values={{
                        totalPartners: notSharedWhitespacePartnerships.length,
                      }}
                    />
                  </T>
                </div>
                <Checkbox
                  checked={isAllNotSharedWhitespaceChecked}
                  onChange={(e: ChangeEvent<HTMLInputElement>) =>
                    toggleSelectAll(e, "whitespace")
                  }
                  indeterminate={
                    !isAllNotSharedWhitespaceChecked &&
                    !!selectedToShareWhitespaceIds.length
                  }
                  label={i18n.selectAll}
                  classes={{ root: classes.checkbox }}
                  labelPlacement="start"
                  disabled={!notSharedReady || loading}
                  withoutMargin
                />
              </div>
              {!notSharedReady || loading ? (
                <Grid item xs={12} className={classes.loaderContainer}>
                  <CircularProgress />
                </Grid>
              ) : isExpanded[2] ? (
                <List
                  className={cx(classes.largeBody, "share-whitespace-section")}
                >
                  {notSharedWhitespacePartnerships.map(
                    (partnership: Partnership) => (
                      <PartnerItem
                        partnership={partnership}
                        key={`share-whitespace-toggle-${partnership.id}`}
                        checked={selectedToShareWhitespaceIds.includes(
                          partnership.id
                        )}
                        overviewMessage={
                          !!partnership?.insights?.companyNbOfNewProspects && (
                            <FormattedHTMLMessage
                              {...i18n.whitespaceOverview}
                              values={{
                                companyNbOfNewProspects:
                                  partnership.insights.companyNbOfNewProspects,
                                partnerName: partnership.getPartner(profile)
                                  .name,
                              }}
                            />
                          )
                        }
                        toggleItem={(e: ChangeEvent<HTMLInputElement>) =>
                          toggleSelectOne(e, "whitespace")
                        }
                      />
                    )
                  )}
                </List>
              ) : (
                <></>
              )}
            </Box>
          )}
      </Grid>
    </BaseLoginPage>
  );
};

export default OnboardingActivatePartnerships;

// Helper

// Enable account overlaps and whitespace by default
export const updateInitialState = (state: DataShare) => {
  switch (state) {
    case DataShare.Nothing:
      return DataShare.OverlappingAndWhitespace;
    case DataShare.Overlapping:
      return DataShare.OverlappingAndWhitespace;
    case DataShare.OverlappingCommonCustomers:
      return DataShare.OverlappingCommonCustomersAndWhitespace;
    default:
      return state as DataShare;
  }
};

// I18N

const i18n = defineMessages({
  leftTitle: {
    id: "onboarding.ActivatePartnerships.leftTitle",
    defaultMessage:
      "You can go<br/>way further<br/>on Reveal<br/>with {totalPartners, plural, =0 {these <br/>Partners} one {these <br/>Partners} other {these #<br/>Partners}}",
  },
  leftSubtitle: {
    id: "onboarding.ActivatePartnerships.leftSubtitle",
    defaultMessage: `We noticed that you have pending actions with different partners on Reveal.<br/><br/>
    Activating these partnerships can create so much value! `,
  },
  firstSectionTitle: {
    id: "onboarding.ActivatePartnerships.firstSectionTitle",
    defaultMessage:
      "Connect with {totalPartners, plural, =0 {Partners} one {1 Partner} other {# Partners}} on Reveal",
  },
  overlapsOverview: {
    id: "onboarding.ActivatePartnerships.overlapsOverview",
    defaultMessage: `
      {commonCustomers, plural,
        =0 {{prospectToCustomers, plural,
              one {Share overlaps to discover <b>1</b> prospect that is your partner's customer}
              other {Share overlaps to discover <b>{prospectToCustomers}</b> prospects that are your partner's customers}
            }}
        one {Share overlaps to discover <b>1</b> common customer{prospectToCustomers, plural,
                                                                 =0 {}
                                                                 one { and <b>1</b> prospect that is your partner's customer}
                                                                 other { and <b>{prospectToCustomers}</b> prospects that are your partner's customers}
                                                               }}
        other {Share overlaps to discover <b>{commonCustomers}</b> common customers{prospectToCustomers, plural,
                                                                                  =0 {}
                                                                                  one { and <b>1</b> prospect that is your partner's customer}
                                                                                  other { and <b>{prospectToCustomers}</b> prospects that are your partner's customers}
                                                                                }}
      }
    `,
  },
  secondSectionTitle: {
    id: "onboarding.ActivatePartnerships.secondSectionTitle",
    defaultMessage:
      "Share Account Overlaps with {totalPartners, plural, =0 {Partners} one {1 Partner} other {# Partners}}",
  },
  thirdSectionTitle: {
    id: "onboarding.ActivatePartnerships.thirdSectionTitle",
    defaultMessage:
      "Share New Prospects with {totalPartners, plural, =0 {Partners} one {1 Partner} other {# Partners}}",
  },
  skip: {
    id: "onboarding.ActivatePartnerships.skip",
    defaultMessage: "Skip",
  },
  close: {
    id: "onboarding.ActivatePartnerships.close",
    defaultMessage: "Close",
  },
  save: {
    id: "onboarding.ActivatePartnerships.save",
    defaultMessage:
      "Activate {totalPartners, plural, =0 {} one {1 partner} other {# partners}}",
  },
  selectAll: {
    id: "onboarding.ActivatePartnerships.selectAll",
    defaultMessage: "(Select all)",
  },
  whitespaceOverview: {
    id: "onboarding.ActivatePartnerships.whitespaceOverview",
    defaultMessage:
      "Share your complete list of customers and discover <b>{companyNbOfNewProspects}</b> customers of {partnerName} that are net new prospects for you.",
  },
});

// CSS

const useStyles = makeStyles()((theme) => ({
  root: {
    marginBottom: theme.spacing(1),
    rowGap: theme.spacing(2),
    width: 625,
    [theme.breakpoints.down("md")]: {
      width: "100%",
    },
  },
  saveContainer: {
    display: "flex",
    justifyContent: "flex-end",
    columnGap: theme.spacing(0.5),
    padding: `${theme.spacing(3)} 0 ${theme.spacing(2)} 0`,
    position: "sticky",
    top: 0,
    background: theme.palette.offWhite,
    zIndex: 1,
    [theme.breakpoints.down("md")]: {
      position: "unset",
      background: "unset",
      padding: 0,
    },
  },
  loaderContainer: {
    display: "flex",
    justifyContent: "center",
    margin: 50,
  },
  header: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    width: "100%",
    paddingBottom: 20,
    borderBottom: `1px solid ${theme.palette.taupe500}`,
  },
  checkbox: { padding: 0, marginLeft: 8 },
  sectionTitle: {
    fontWeight: 600,
    fontSize: 14,
    lineHeight: "18.2px",
  },
  listItem: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    paddingLeft: 0,
    "&:not(:only-child)": {
      borderBottom: "none",
      marginBottom: 0,
      paddingBottom: 0,
    },
  },
  body: {
    overflowY: "auto",
  },
  largeBody: {
    overflowY: "auto",
    "& > .partner-item:not(:last-child)": {
      marginBottom: theme.spacing(2.5),
    },
  },
  toggleItem: {
    alignItems: "center",
    cursor: "pointer",
    display: "flex",
    gap: theme.spacing(0.5),
  },
}));
