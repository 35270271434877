import _ from "lodash";
import { AvailableAnalytisColumns } from "redux/analytics/defaults";

export const isPartnerAnalyticUserColumn = (fieldName: string, row: any) => {
  return (
    row?.type === "partner_analytics" &&
    (fieldName === AvailableAnalytisColumns.ownerCompany ||
      fieldName === AvailableAnalytisColumns.ownerPartner)
  );
};

export const partnerAnalyticUserValueFormatter = (
  row: any,
  fieldName: string
) => {
  if (fieldName === AvailableAnalytisColumns.ownerCompany) {
    return _.get(row, "myPartnershipOwnerName", "--") ?? "--";
  } else if (fieldName === AvailableAnalytisColumns.ownerPartner) {
    return _.get(row, "partnerPartnershipOwnerName", "--") ?? "--";
  }
  return "--";
};
