import { searchHost } from "config/constants";
import {
  array,
  either,
  guard,
  nullable,
  number,
  object,
  oneOf,
  string,
} from "decoders";
import { stringify } from "query-string";

import BaseHttpService, { API_JSON, Headers } from "./BaseHTTPService";

enum ErrorCode {
  generic_http = "generic_http",
  unauthorized = "unauthorized",
  forbidden = "forbidden",
  bad_request = "bad_request",
  parse_error = "parse_error",
}

export default class GlobalSearchService extends BaseHttpService {
  constructor() {
    super(`${searchHost}/api/global/`, {
      [Headers.ContentType]: API_JSON,
      [Headers.Accept]: API_JSON,
    });
  }

  async q(query: string): Promise<SearchResult> {
    const queryString = stringify({
      q: query,
    });
    try {
      const response = await this.authGet(`?${queryString}`, {});
      return searchResultGuard(response.data);
    } catch (error) {
      if (error.isAxiosError) {
        /* Handling AxiosError case */
        switch (error.status) {
          case 401:
            throw new GlobalSearchError(ErrorCode.unauthorized, error);
          case 403:
            throw new GlobalSearchError(ErrorCode.forbidden, error);
          case 400:
            throw new GlobalSearchError(ErrorCode.bad_request, error);
          default:
            throw new GlobalSearchError(ErrorCode.generic_http, error);
        }
      }
      if (error.name === "Decoding error") {
        /* Handling Invalid Response */
        throw new GlobalSearchError(ErrorCode.parse_error, error);
      }
      throw error;
    }
  }
}

export class GlobalSearchError extends Error {
  message: string;
  caughtError: any;

  constructor(message: string, caughtError: any) {
    super();
    this.message = message;
    this.caughtError = caughtError;
  }
}

// Types

const searchResultPartnership = object({
  id: number,
  partner_id: nullable(number),
  partner_name: string,
  status: oneOf(["accepted", "pending", "paused", "ghost"]),
});

const searchResultPartnershipGuard = guard(searchResultPartnership);

export type PartnershipResult = ReturnType<typeof searchResultPartnershipGuard>;

const searchResultNearboundAccount = object({
  integration_id: number,
  provider_key: string,
  name: string,
  raw_company_id: number,
  status: nullable(oneOf([1, 2, 4])),
  domain: nullable(string),
  owner: nullable(
    object({
      full_name: nullable(string),
      email: nullable(string),
    })
  ),
});

const searchResultNearboundAccountGuard = guard(searchResultNearboundAccount);

export type AccountResult = ReturnType<
  typeof searchResultNearboundAccountGuard
>;

const searchResultNearboundProspect = object({
  country_iso_code: string,
  smart_domain_key: string,
  name: string,
  domain: nullable(string),
});

const searchResultNearboundProspectGuard = guard(searchResultNearboundProspect);

export type ProspectResult = ReturnType<
  typeof searchResultNearboundProspectGuard
>;

const searchResult = object({
  partnerships: array(searchResultPartnership),
  accounts: array(
    either(searchResultNearboundAccount, searchResultNearboundProspect)
  ),
});

const searchResultGuard = guard(searchResult);

export type SearchResult = ReturnType<typeof searchResultGuard>;

export const isAccountResult = (
  value: AccountResult | ProspectResult
): value is AccountResult => {
  return "raw_company_id" in value;
};
