import { createSelector } from "@reduxjs/toolkit";
import _ from "lodash";
import Partnership, { PartnershipStatus } from "models/Partnership";
import { RevealStore } from "redux/typing";

export const selectDashboardData = createSelector(
  (state: RevealStore) => state.api.entities["partnerships"] ?? {},
  (state: RevealStore) => state.api.entities["integrations"] ?? {},
  (state: RevealStore) => state.api.entities["crm_credentials"] ?? {},
  (state: RevealStore) => state.dashboard.search,
  (state: RevealStore) => state.user.profile.data,
  (state: RevealStore) => state.context,
  (
    partnerships,
    integrations,
    crmCredendentials,
    search,
    user,
    { ready, partnershipIds }
  ) => {
    const filteredIds =
      search.value && search.index
        ? _.intersection(
            partnershipIds,
            search.index.search(search.value).map(({ item }) => item.id)
          )
        : partnershipIds;
    return {
      partnerships: filteredIds
        .map((id: number) => partnerships[id])
        .filter(Boolean) as Partnership[],
      invitations: _.filter(
        partnerships,
        (partnership) => partnership?.isToAcceptByCompany(user.company) ?? false
      ) as Partnership[],
      ready,
      hasPartnerships: !_.isEmpty(partnerships),
      hasActiveOrCreatedPartnerships: _.values(partnerships).some(
        (record) =>
          record.initiatorCompany.id === user.company.id ||
          record.status === PartnershipStatus.Accepted
      ),
      hasSources: Boolean(
        (user.company?.integrationCount ?? 0) > 0 ||
          (integrations !== undefined && !_.isEmpty(integrations)) ||
          (crmCredendentials !== undefined && !_.isEmpty(crmCredendentials))
      ),
    };
  }
);

export const selectSearchValue = createSelector(
  (state: RevealStore) => state.dashboard.search,
  ({ value }) => value
);

export const selectOverlaps = createSelector(
  (state: RevealStore) =>
    state.api.entities["potential_partner_overlaps"] ?? {},
  (state: RevealStore) => state.dashboard,
  (overlaps, { overlapsReady }) => {
    return {
      overlaps: _.mapValues(
        _.keyBy(Object.values(overlaps), "matchedCompanyId"),
        (value) => {
          return {
            accountOverlap: value.accountOverlap,
            contactOverlap: value.contactOverlap,
          };
        }
      ),
      overlapsReady,
    };
  }
);
