import { BaseTextFieldProps } from "@mui/material";
import { Close, Search } from "components/icons";
import { makeStyles } from "makeStyles";
import { ChangeEvent, useRef } from "react";
import { defineMessages, useIntl } from "react-intl";

import { TextInput, TextInputRef } from "./TextInput";

type Props = BaseTextFieldProps & {
  value: string;
  onChange: (e: ChangeEvent<HTMLInputElement>) => void;
  onClear: () => void;
  isSmall?: boolean; // TODO remove once every page use the small variant by default
  isAM?: boolean; // TODO remove once every page use the am variant
};

export const QuickSearch = ({
  value,
  placeholder,
  onClear,
  isSmall,
  isAM,
  ...otherProps
}: Props) => {
  const { classes } = useStyles({ isSmall, isAM });
  const intl = useIntl();
  const inputRef = useRef<TextInputRef>(null);

  const handleClear = () => {
    onClear();
    inputRef?.current?.focus();
  };

  const endAdornment = (
    <div className={classes.endAdornment}>
      {value && (
        <span
          className={classes.clearIconContainer}
          onClick={handleClear}
          data-testid="clear"
        >
          <Close />
        </span>
      )}
      <span className={classes.searchIconContainer}>
        <Search />
      </span>
    </div>
  );

  return (
    <TextInput
      ref={inputRef}
      value={value}
      className={classes.textInput}
      variant="newDesign"
      small={false} // TODO: update it to "true" when working on the new headers design.
      placeholder={placeholder ?? intl.formatMessage(i18n.quickFind)}
      RightIcon={Search}
      InputProps={{ endAdornment }}
      {...otherProps}
    />
  );
};

// CSS

const useStyles = makeStyles<{ isSmall?: boolean; isAM?: boolean }>()(
  (theme, { isSmall, isAM }) => ({
    // TODO: remove isSmall logic
    // (value remaining should be the smallest one as component will use the "small" variant by default
    // once page revamp is done)
    textInput: {
      width: isSmall || isAM ? 128 : 195,
      "& > .MuiInputBase-adornedStart": { paddingLeft: isSmall ? 12 : 16 },
      "& > .MuiInputBase-adornedEnd": { paddingRight: isSmall ? 4 : 6 },
      "& input, > .MuiInputBase-root": { height: isSmall ? 28 : 36 },
      "& > .MuiInputBase-root:hover": {
        boxShadow: "0px 0px 0px 3px rgba(10,21,27,0.06)",
      },
    },
    endAdornment: {
      display: "flex",
      alignItems: "center",
      gap: 4,
      paddingLeft: 8,
    },
    clearIconContainer: {
      width: isSmall ? 10 : 12,
      height: isSmall ? 10 : 12,
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      cursor: "pointer",
      "& svg": {
        width: isSmall ? 8 : 10,
        height: isSmall ? 8 : 10,
        color: theme.palette.alpha500,
      },
    },
    searchIconContainer: {
      width: isSmall ? 20 : 24,
      height: isSmall ? 20 : 24,
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      color: theme.palette.ivory,
      borderRadius: 20,
      background: "linear-gradient(70deg, #5A28C3 9.2%, #7E64FF 100%)",
      "& svg": {
        width: isSmall ? 10 : 12,
        height: isSmall ? 10 : 12,
      },
    },
  })
);

// I18N

const i18n = defineMessages({
  quickFind: { id: "QuickSearch.quickFind", defaultMessage: "Quick find" },
});
