import { createSvgIcon } from "@mui/material/utils";

import { SvgIcon } from "./types";

const displayName = "Menu";

const Menu: SvgIcon = createSvgIcon(
  <path
    fillRule="evenodd"
    clipRule="evenodd"
    d="M4 6.75C4 6.33579 4.33579 6 4.75 6H19.75C20.1642 6 20.5 6.33579 20.5 6.75C20.5 7.16421 20.1642 7.5 19.75 7.5H4.75C4.33579 7.5 4 7.16421 4 6.75ZM4 11.75C4 11.3358 4.33579 11 4.75 11H14.75C15.1642 11 15.5 11.3358 15.5 11.75C15.5 12.1642 15.1642 12.5 14.75 12.5H4.75C4.33579 12.5 4 12.1642 4 11.75ZM4.75 16C4.33579 16 4 16.3358 4 16.75C4 17.1642 4.33579 17.5 4.75 17.5H10.75C11.1642 17.5 11.5 17.1642 11.5 16.75C11.5 16.3358 11.1642 16 10.75 16H4.75Z"
    fill="currentColor"
  />,
  displayName
);

Menu.displayName = displayName;
export default Menu;
