import _ from "lodash";
import { JSONAPIAttributes } from "models/types";
import { useDispatch, useSelector } from "react-redux";
import { bulkUpdateAttributesOnRecords } from "redux/api/actions";

import Record from "../models/Record";

const useUpdateAttributesForAllRecords = (resource: string) => {
  const records = useSelector(
    (state: {
      api: {
        entities: {
          [resourceName: string]: {
            [strId: string]: Record;
          };
        };
      };
    }) => state.api.entities[resource] || {}
  );
  const dispatch = useDispatch();

  return (attributes: JSONAPIAttributes, filters: object = {}) => {
    const matchingRecords = Object.values(records).filter((item) => {
      let itemMatches = true;
      _.forOwn(filters, function (value, key) {
        if (_.get(item, key, null) !== value) {
          itemMatches = false;
        }
      });
      return itemMatches;
    });
    dispatch(bulkUpdateAttributesOnRecords(matchingRecords, attributes));
  };
};

export default useUpdateAttributesForAllRecords;
