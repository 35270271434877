import { isFulfilled, PayloadAction } from "@reduxjs/toolkit";
import _ from "lodash";
import ModelRecord from "models/Record";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { indexAll } from "redux/api/thunks";
import { RevealStore } from "redux/typing";
import { JSONAPIListResponse } from "services/types";

import useSelectorWithDeepEquality from "./useSelectorWithDeepEquality";

// Defined here to preserve the reference: prevents rerendering.
const emptyArray: any[] = [];

const useAllRecords = <T extends ModelRecord = ModelRecord>(
  resource: string,
  options = {},
  callback: (value: number[]) => void = () => undefined,
  deps: any[] = emptyArray,
  concurrency?: number,
  entitiesResource?: string
) => {
  const [counter, setCounter] = useState(0);
  const [loading, setLoading] = useState(true);
  const [recordIds, setRecordIds] = useState<number[]>([]);
  const [progress, setProgress] = useState<number>(0);
  const dispatch = useDispatch<$TSFixMe>();
  const preservedRefToRecords = useRef<Record<string, T[]>>({});
  const unorderedRecords = useSelectorWithDeepEquality((state: RevealStore) =>
    Object.values(
      state.api.entities[entitiesResource || resource] ?? {}
    ).filter((item) => recordIds.includes(item.id))
  );

  const sortedRecords = _.sortBy(unorderedRecords, ({ id }) =>
    recordIds.indexOf(id)
  );
  const records = sortedRecords.length === 0 ? emptyArray : sortedRecords;

  // Preserving the reference is useful to be able to put the `records` variable in a
  // `useEffect` dependency array.
  //
  // Given that we use `Object.values` in the selector and `_.sortBy`, both of which
  // create a new arrays, we have to add this comparison to keep the same reference.
  if (!_.isEqual(preservedRefToRecords.current[resource], records)) {
    preservedRefToRecords.current[resource] = records;
  }

  const onSuccess = useCallback(callback, []); // eslint-disable-line react-hooks/exhaustive-deps

  const refresh = useCallback(() => setCounter((counter) => counter + 1), []);

  useEffect(() => {
    let active = true;
    setLoading(true);
    dispatch(
      indexAll({
        type: resource,
        options,
        concurrency,
        onProgress: setProgress,
      })
    ).then((action: PayloadAction<JSONAPIListResponse>) => {
      if (active) {
        if (isFulfilled(action)) {
          const responseIDs = action.payload.data.map(({ id }) => +id);
          setRecordIds(responseIDs);
          onSuccess(responseIDs);
        }
        setLoading(false);
      }
    });
    return () => {
      active = false;
    };
  }, [onSuccess, JSON.stringify(options), resource, counter, ...deps]); // eslint-disable-line react-hooks/exhaustive-deps

  const refRecords = preservedRefToRecords.current[resource];
  return useMemo(
    () => ({
      progress,
      loading,
      refresh,
      records: refRecords,
    }),
    [progress, loading, refresh, resource, refRecords] // eslint-disable-line react-hooks/exhaustive-deps
  );
};

export default useAllRecords;
