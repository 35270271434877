import { SvgIconComponent } from "@mui/icons-material";
import { PopperPlacementType } from "@mui/material";
import { MouseEvent, ReactElement, ReactNode } from "react";
import { defineMessages } from "react-intl";

import { useDropdownStyles } from "./Dropdown";

export interface IDropdown<T extends string = string> {
  disablePortal?: boolean;
  options: IDropdownOption<T>[];
  value?: T[] | null | T;
  isMultiselect?: boolean;
  isVertical?: boolean;
  anchorEl: Element | null;
  isDraggable?: boolean;
  onClose: () => void;
  tabs?: IDropdownTab<T>[];
  children?: ReactElement;
  withToggle?: boolean;
  withCheckBox?: boolean;
  isOpportunityItem?: boolean;
  isMainSearch?: boolean;
  onChange?: (ids: T[] | null) => void;
  onDragEnd?: (payload: IDropdownOption<T>[], activeTab?: string) => void;
  CustomHeader?: ReactNode;
  CustomFooter?: ReactNode | ((value: T | T[] | null) => ReactNode);
  withSearch?: boolean;
  resetSearch?: number; // Reset search when value changes
  size?: "normal" | "small" | "inherit" | number;
  open: boolean;
  position?: PopperPlacementType;
  allowSelectAll?: boolean;
  nullValueMeansAllSelected?: boolean;
  withGroups?: boolean;
  searchPlaceholder?: string;
  isLoading?: boolean;
  fullWidth?: boolean;
  hasAsyncSearch?: boolean;
  onAsyncSearch?: (query: string) => void;
  searchCallback?: (query: string) => void;
  allowEmptyValue?: boolean;
  withToggleFilter?: boolean;
  toggleFilterValue?: string;
  toggleFilterItems?: {
    value: T;
    label: JSX.Element | string | ReactNode;
  }[];
  onToggleFilterChange?: (value: any) => void;
  classes?: Partial<ReturnType<typeof useDropdownStyles>["classes"]>;
  errorMessage?: ReactElement;
}

type IDropdownItemBase = {
  checkbox?: boolean;
  logo?: string | null;
  logoTooltip?: ReactNode;
  toggle?: boolean;
  id?: string;
  isExpanded?: boolean;
  isHoverable?: boolean;
  label?: string | ReactElement;
  name?: string | ReactElement;
  subText?: string | ReactElement;
  onChange?: (value: any) => void;
  onSelect?: (value: any) => void;
  isDraggable?: boolean;
  onButtonClick?: (value: any) => void;
  isChecked?: boolean;
  isIndeterminate?: boolean;
  isDisabled?: boolean;
  isVertical?: boolean;
  isLoading?: boolean;
  fullWidth?: boolean;
  rightIcons?: string[];
  rightIconTooltip?: ReactNode;
  subItems?: IDropdownOption[];
  children?: any;
  buttonLabel?: string;
  buttonIcon?: SvgIconComponent;
  isSelectAll?: boolean;
  tooltipMessage?: ReactNode;
  isOpportunityItem?: boolean;
  title?: string;
  height?: number | string;
};

type IDropdownItemOpportunityProps = {
  isOpportunityItem?: boolean;
  amount?: number;
  date?: string;
  isOpen?: boolean;
  isWon?: boolean;
  stage?: string;
};

export type IDropdownItem = IDropdownItemBase & IDropdownItemOpportunityProps;

export interface IDropdownOption<T extends string = string> {
  amount?: number;
  id: T;
  isHoverable?: boolean;
  height?: number;
  label?: string | ReactElement;
  name: string;
  subText?: string | ReactElement;
  value?: string;
  isChecked?: boolean;
  isOpen?: boolean;
  disabled?: boolean;
  logo?: string | null;
  logoTooltip?: ReactNode;
  group?: IDropdownGroup<T>[];
  subItems?: IDropdownOption<T>[];
  tabId?: string;
  rightIcons?: string[];
  rightIconTooltip?: ReactNode;
  date?: string;
  isWon?: boolean;
  stage?: string;
  tooltipMessage?: ReactNode;
  title?: string;
  onClick?: (e: MouseEvent) => void;
}

export interface IDropdownTab<T extends string = string> {
  id: string;
  name: string;
  value: string;
  options?: IDropdownOption<T>[];
}

export interface IDropdownGroup<T extends string = string> {
  id: T;
  name: string;
  options?: IDropdownOption<T>[];
  disabled?: boolean;
}

export type Vertical = "bottom" | "center" | "top";

export enum ERROR_STATES {
  EMPTY_STATE = "empty_state",
  NO_SEARCH_RESULTS = "no_results",
  // can be expanded with more scenarios/states
}

export const ERROR_MESSAGES_BY_STATE = defineMessages({
  [ERROR_STATES.EMPTY_STATE]: {
    id: "Dropdown.EmptyState",
    defaultMessage: "Looks like the list is empty",
  },
  [ERROR_STATES.NO_SEARCH_RESULTS]: {
    id: "Dropdown.NoSearchResults",
    defaultMessage: "No results found",
  },
});
