import useSegment from "hooks/useSegment";
import _ from "lodash";
import { ChangeEvent, useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  AdditionalAnalyticsColumns,
  metricsColumnsMapping,
} from "redux/analytics/defaults";
import {
  selectAnalyticsView,
  selectExpandRevenueMetricsEnabled,
  selectFields,
  selectHighlightHighWinRateEnabled,
  selectSummaryMetricsEnabled,
  selectTableColumns,
} from "redux/analytics/selectors";
import { checkHighWinRatePresence, updateView } from "redux/analytics/thunks";
import { AnalyticsOtherFilterFieldname } from "redux/analytics/types";
import { DEFAULT_REVENUE_COLUMN_WIDTH } from "screens/Frontoffice/screens/DataTables/shared/components/ColumnWidget";
import {
  ColumnConfigType,
  FilterType,
  MatchFilterType,
} from "screens/Frontoffice/screens/DataTables/shared/types";
import { AdvancedAnalyticsEvent } from "tracking";

import { AnalyticsViewParameters } from "../../../constants";

const useAnalyticsColumnWidgetAPILogic = () => {
  const dispatch = useDispatch();
  const { track } = useSegment();
  const fields = useSelector(selectFields);
  const columns = useSelector(selectTableColumns);
  const summaryMetricsEnabled = useSelector(selectSummaryMetricsEnabled);
  const highlightHighWinRateEnabled = useSelector(
    selectHighlightHighWinRateEnabled
  );
  const revenueMetricsEnabled = useSelector(selectExpandRevenueMetricsEnabled);

  const { view, hasHighWinRatePartners } = useSelector(selectAnalyticsView);
  const { filters } = view ?? { filters: [] };

  const setView = useCallback(
    (value: AnalyticsViewParameters) => dispatch(updateView(value)),
    [dispatch]
  );

  const setFilters = useCallback(
    (filters: FilterType[]) => {
      setView({ filters });
    },
    [setView]
  );

  const setColumns = useCallback(
    (columns: ColumnConfigType[]) => {
      setView({ columns });
    },
    [setView]
  );

  const toggleSummaryMetrics = (event: ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      setFilters([
        ...filters,
        {
          fieldname: AnalyticsOtherFilterFieldname.showSummaryMetrics,
          type: MatchFilterType._NO_OPERATOR,
          value: true,
        },
      ]);
    } else {
      setFilters(
        filters.filter(
          (filter) =>
            AnalyticsOtherFilterFieldname.showSummaryMetrics !==
            filter.fieldname
        )
      );
    }
  };

  const toggleHighWinRate = (event: ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      setFilters([
        ...filters,
        {
          fieldname: AnalyticsOtherFilterFieldname.highlightHighWinRate,
          type: MatchFilterType._NO_OPERATOR,
          value: true,
        },
      ]);
    } else {
      setFilters(
        filters.filter(
          (filter) =>
            AnalyticsOtherFilterFieldname.highlightHighWinRate !==
            filter.fieldname
        )
      );
    }
  };

  const toggleExpandRevenueMetrics = (event: ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      const newColumns = _.cloneDeep(columns) ?? [];
      metricsColumnsMapping.forEach(([primary, secondary]) => {
        const index = newColumns?.findIndex((item) => item.key === primary);
        if (index && index !== -1) {
          newColumns.splice(index + 1, 0, {
            key: secondary,
            width: DEFAULT_REVENUE_COLUMN_WIDTH,
            frozen: false,
          });
        }
      });
      setColumns(newColumns);
      track(AdvancedAnalyticsEvent.expandRevenueMetrics);
    } else {
      setColumns(
        columns.filter(
          (column) =>
            !(Object.values(AdditionalAnalyticsColumns) as string[]).includes(
              column.key
            )
        )
      );
    }
  };

  const handleColumnDelete = (
    fieldname: string,
    newColumns: ColumnConfigType[],
    index: number
  ) => {
    let removeCount = 1;
    if (
      metricsColumnsMapping?.some(
        ([primary, secondary]) => primary === fieldname
      )
    ) {
      removeCount = 2;
    }
    newColumns.splice(index, removeCount);
    setColumns(newColumns);
  };

  const handleColumnReorder = (
    newColumns: ColumnConfigType[],
    startIndex: number,
    endIndex: number,
    offset: number
  ) => {
    let _newColumns = [...newColumns];
    // if the metrics are expanded, the additional columns are messing up with the indexes,
    // so I'm removing them and ading them back later in this function
    if (revenueMetricsEnabled) {
      _newColumns = _newColumns.filter(
        (item) =>
          !metricsColumnsMapping.some(
            ([_primary, secondary]) => item.key === secondary
          )
      );
    }
    const [removed] = _newColumns.splice(startIndex + offset, 1);
    _newColumns.splice(endIndex + offset, 0, removed);
    if (revenueMetricsEnabled) {
      metricsColumnsMapping.forEach(([primary, secondary]) => {
        const index = _newColumns.findIndex((item) => item.key === primary);
        if (index !== -1) {
          const removedItem = columns.find((item) => item.key === secondary);
          removedItem && _newColumns.splice(index + 1, 0, removedItem);
        }
      });
    }
    return _newColumns;
  };

  useEffect(() => {
    dispatch(checkHighWinRatePresence());
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return {
    fields,
    columns,
    setColumns,
    summaryMetricsEnabled,
    toggleSummaryMetrics,
    revenueMetricsEnabled,
    toggleExpandRevenueMetrics,
    hasHighWinRatePartners,
    highlightHighWinRateEnabled,
    toggleHighWinRate,
    handleColumnDelete,
    handleColumnReorder,
  };
};

export default useAnalyticsColumnWidgetAPILogic;
