import { Box, Divider, Grid } from "@mui/material";
import {
  DataGridPremium,
  GridColDef,
  GridRenderCellParams,
} from "@mui/x-data-grid-premium";
import { Info, Settings } from "components/icons";
import Alert from "components/ui/Alert";
import Button from "components/ui/Button";
import SettingsSection from "components/ui/SettingsSection";
import Tooltip from "components/ui/Tooltip";
import { T } from "components/ui/Typography";
import { makeStyles } from "makeStyles";
import { kindEncodeMapping } from "models/PartnerConnection";
import {
  defineMessages,
  FormattedHTMLMessage,
  FormattedMessage,
} from "react-intl";

import AccountSettings from "../../../components/AccountSettings";
import { useOpportunitySharingLevelAttributionsApi } from "../hooks/useOpportunitySharingLevelAttributionsApi";
import { OpportunitySharingLevelDefinitionsModal } from "./OpportunitySharingLevelDefinitionsModal";
import { SharingLevelDropdown } from "./SharingLevelDropdown";

export const CollaborateSettings = () => {
  const { classes } = useStyles();
  const {
    defaultSettings,
    handleUpdateAttributionDefaults,
    isLoadingDefaults,
    isModalOpen,
    setIsModalOpen,
  } = useOpportunitySharingLevelAttributionsApi();

  const generateColumn = (kind: kindEncodeMapping) => ({
    field: kind.toString(),
    flex: 1,
    renderHeader: () => (
      <>
        <T titleSmall={kind !== kindEncodeMapping.NONE}>
          <FormattedHTMLMessage {...i18n[`kind${kind}` as keyof typeof i18n]} />
        </T>
        {kind === kindEncodeMapping.NONE && (
          <Tooltip title={<FormattedHTMLMessage {...i18n.noneTooltip} />}>
            <Info className={classes.icon} />
          </Tooltip>
        )}
      </>
    ),
    renderCell: (params: GridRenderCellParams) => (
      <SharingLevelDropdown
        kind={kind}
        onChange={handleUpdateAttributionDefaults}
        onModalOpen={() => setIsModalOpen(true)}
        value={params.value}
      />
    ),
    disableColumnMenu: true,
    sortable: false,
  });

  const columns: GridColDef[] = [
    {
      field: "companyName",
      flex: 1,
      headerName: "",
      minWidth: 360,
      renderCell: () => (
        <Box>
          <T bold>
            <FormattedMessage {...i18n.defaultSetting} />
          </T>
          <T className={classes.alpha}>
            <FormattedMessage {...i18n.defaultSettingDescription} />
          </T>
        </Box>
      ), // TODO: render default row and company rows differently, not in the MVP
      disableColumnMenu: true,
      sortable: false,
    },
    ...[
      kindEncodeMapping.NONE,
      kindEncodeMapping.SOURCING,
      kindEncodeMapping.INFLUENCE,
      kindEncodeMapping["CO-SELLING"],
      kindEncodeMapping.RESELLING,
    ].map(generateColumn),
  ];

  // TODO: MVP contains only one default row, per partner rows will be added in the future
  const rows = [defaultSettings];

  // TODO: <AccountSettings calls "integrations" API on each tab render, this should be optimized for the tabs that don't need it
  return (
    <AccountSettings tab="collaborate">
      <Grid item className={classes.root}>
        <SettingsSection>
          <Grid container flexWrap="nowrap" justifyContent="space-between">
            <Grid item flexShrink={1}>
              <T className={classes.title}>
                <FormattedMessage {...i18n.title} />
              </T>
              <T className={classes.alpha}>
                <FormattedHTMLMessage {...i18n.description} />
              </T>
            </Grid>
            <Grid item minWidth={180} textAlign="right">
              <Button
                label={i18n.configure}
                onClick={() => setIsModalOpen(true)}
                RightIcon={Settings}
                size="small"
                variant="tertiary"
              />
            </Grid>
          </Grid>
          <Divider className={classes.divider} />
          <DataGridPremium
            columns={columns}
            disableVirtualization={process.env.NODE_ENV === "test"}
            rows={rows}
            loading={isLoadingDefaults}
            autoHeight
            hideFooter
            className={classes.root}
            getRowHeight={(params) => 56} // TODO: add a function to calculate row height for the default row vs others
            columnHeaderHeight={36}
            disableRowSelectionOnClick
            // TODO: disable cell selection
          />
          {/* TODO: add a footer here to add new columns, not in the MVP */}
          <Alert icon variant="warning" className={classes.marginTop}>
            <FormattedHTMLMessage {...i18n.collabSettingsFeatureWarning} />
          </Alert>
        </SettingsSection>
      </Grid>
      <OpportunitySharingLevelDefinitionsModal
        isModalOpen={isModalOpen}
        setIsModalOpen={setIsModalOpen}
      />
    </AccountSettings>
  );
};

const i18n = defineMessages({
  collabSettingsFeatureWarning: {
    id: "CollaborateSettings.collabSettingsFeatureWarning",
    defaultMessage:
      "Please note that these settings have been live since June 12, 2024, and are reflected in the opportunities attached to your collaborations.",
  },
  configure: {
    id: "CollaborateSettings.configure",
    defaultMessage: "Configure Settings",
  },
  coSelling: {
    id: "CollaborateSettings.coSelling",
    defaultMessage: "Co-selling deals",
  },
  defaultSetting: {
    id: "CollaborateSettings.defaultSetting",
    defaultMessage: "Default setting",
  },
  defaultSettingDescription: {
    id: "CollaborateSettings.defaultSettingDescription",
    defaultMessage: "Applies to all partners",
  },
  description: {
    id: "CollaborateSettings.description",
    defaultMessage:
      "Reveal allows to monitor how collaboration on your partners' deals convert into opportunities and wins.<br/>Symmetrically, define below how much you want to share with partners about the status of the opportunities you're collaborating on together.",
  },
  influence: {
    id: "CollaborateSettings.influence",
    defaultMessage: "Influenced",
  },
  none: {
    id: "CollaborateSettings.none",
    defaultMessage: "<i>Default type</i>",
  },
  noneTooltip: {
    id:
      "CollaborateSettings.useOpportunitySharingLevelAttributionsApi.noneTooltip",
    defaultMessage:
      "When new Discussions are created,<br/>they are assigned this type by default.",
  },
  reselling: {
    id: "CollaborateSettings.reselling",
    defaultMessage: "Reselling",
  },
  sourcing: {
    id: "CollaborateSettings.sourcing",
    defaultMessage: "Sourced",
  },
  title: {
    id: "CollaborateSettings.title",
    defaultMessage: "Collaborate - Opportunity sharing",
  },
  [`kind${kindEncodeMapping.INFLUENCE}`]: {
    id: "CollaborateSettings.kind.Influence",
    defaultMessage: "Influenced",
  },
  [`kind${kindEncodeMapping.RESELLING}`]: {
    id: "CollaborateSettings.kind.Reselling",
    defaultMessage: "Reselling",
  },
  [`kind${kindEncodeMapping.SOURCING}`]: {
    id: "CollaborateSettings.kind.Sourcing",
    defaultMessage: "Sourced",
  },
  [`kind${kindEncodeMapping["CO-SELLING"]}`]: {
    id: "CollaborateSettings.kind.CoSelling",
    defaultMessage: "Co-selling",
  },
  [`kind${kindEncodeMapping.NONE}`]: {
    id: "CollaborateSettings.kind.None",
    defaultMessage: "<i>Default type</i>",
  },
});

export const useStyles = makeStyles()((theme) => ({
  root: {
    border: `1px solid ${theme.palette.greyLight100}`,
    borderRadius: 6,
    "& .MuiDataGrid-columnHeaders": {
      background: theme.palette.greyLight050,
      borderBottom: `1px solid ${theme.palette.greyLight100}`,
    },
    ".MuiDataGrid-row .MuiDataGrid-cell:not(:first-child)": {
      padding: "10px 4px 10px 0",
    },
    ".MuiDataGrid-row .MuiDataGrid-cell:first-child": {
      padding: "10px 4px 10px 12px",
    },
  },
  alpha: {
    color: theme.palette.alpha500,
  },
  divider: {
    color: theme.palette.alpha200,
    margin: "16px -20px",
  },
  icon: {
    height: 16,
    marginLeft: 4,
    width: 16,
  },
  marginTop: {
    marginTop: 12,
  },
  title: {
    color: theme.palette.midnight,
    fontSize: "14px",
    fontWeight: 600,
    lineHeight: "150%",
  },
}));
