import { createSvgIcon } from "@mui/material/utils";

import { SvgIcon } from "./types";

const displayName = "CircleErrorOutlined";

const CircleErrorOutlined: SvgIcon = createSvgIcon(
  // Using transform to enlarge from provided viewbox="0 0 16 16"
  // to default "0 0 24 24" from `createSvgIcon` -> `SvgIcon`.
  <g fill="none" transform="matrix(1.5,0,0,1.5,0,0)">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M1.5 8C1.5 4.41015 4.41015 1.5 8 1.5C11.5899 1.5 14.5 4.41015 14.5 8C14.5 11.5899 11.5899 14.5 8 14.5C4.41015 14.5 1.5 11.5899 1.5 8ZM8 2.5C4.96243 2.5 2.5 4.96243 2.5 8C2.5 11.0376 4.96243 13.5 8 13.5C11.0376 13.5 13.5 11.0376 13.5 8C13.5 4.96243 11.0376 2.5 8 2.5ZM5.85364 5.90002C6.04891 5.70475 6.36549 5.70475 6.56075 5.90002L7.99646 7.33573L9.43201 5.90017C9.62727 5.70491 9.94386 5.70491 10.1391 5.90017C10.3344 6.09544 10.3344 6.41202 10.1391 6.60728L8.70357 8.04283L10.0963 9.43555C10.2915 9.63081 10.2915 9.9474 10.0963 10.1427C9.90102 10.3379 9.58444 10.3379 9.38918 10.1427L7.99646 8.74994L6.60358 10.1428C6.40832 10.3381 6.09174 10.3381 5.89648 10.1428C5.70121 9.94755 5.70121 9.63097 5.89648 9.43571L7.28935 8.04283L5.85364 6.60712C5.65838 6.41186 5.65838 6.09528 5.85364 5.90002Z"
      fill="currentColor"
    />
  </g>,
  displayName
);

CircleErrorOutlined.displayName = displayName;
export default CircleErrorOutlined;
