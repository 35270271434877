import { lighten, Theme } from "@mui/material/styles";
import {
  CircleCheck,
  CircleError,
  CircleInfo,
  CircleWarning,
  Close,
} from "components/icons";
import { T } from "components/ui/Typography";
import { makeStyles } from "makeStyles";
import { ReactNode } from "react";

export type AlertVariant = "error" | "warning" | "success" | "cta" | "default";

type AlertStyleProps = {
  variant?: AlertVariant;
  onClose?: () => void;
  width?: number | string;
  forceBorder?: boolean;
};

export type AlertProps = {
  children: ReactNode;
  className?: string;
  icon?: boolean;
} & AlertStyleProps;

const Alert = ({
  children,
  variant = "default",
  icon = false,
  onClose,
  className,
  width,
  forceBorder,
}: AlertProps) => {
  const { classes, cx } = useStyles({ variant, onClose, width, forceBorder });
  let iconElement: null | ReactNode = null;
  if (icon) {
    switch (variant) {
      case "error":
        iconElement = <CircleError className={classes.icon} />;
        break;
      case "success":
        iconElement = <CircleCheck className={classes.icon} />;
        break;
      case "warning":
        iconElement = <CircleWarning className={classes.icon} />;
        break;
      default:
        iconElement = <CircleInfo className={classes.icon} />;
    }
  }
  return (
    <div className={cx(classes.root, className)}>
      <div className={classes.left}>
        {iconElement}
        <T className={classes.content}>{children}</T>
      </div>
      {onClose && (
        <button
          className={classes.closeBtn}
          onClick={onClose}
          data-testid="close-button"
        >
          <Close className={classes.closeIcon} />
        </button>
      )}
    </div>
  );
};

export default Alert;

// CSS Helpers

const getBackgroundColor = (theme: Theme, variant: AlertVariant) => {
  switch (variant) {
    case "error":
      return lighten(theme.palette.red600 as string, 0.9);
    case "warning":
      return theme.palette.gold100;
    case "success":
      return lighten(theme.palette.green as string, 0.9);
    case "cta":
      return theme.palette.ivory;
  }
  return theme.palette.taupe;
};

const getColor = (theme: Theme, variant: AlertVariant) => {
  switch (variant) {
    case "error":
      return theme.palette.red600;
    case "warning":
    case "cta":
      return theme.palette.gold;
    case "success":
      return theme.palette.green;
  }
};

// STYLES

export const useStyles = makeStyles<AlertStyleProps>()((theme, props) => ({
  root: {
    alignItems: "center",
    backgroundColor: getBackgroundColor(theme, props.variant ?? "default"),
    borderRadius: 6,
    display: "flex",
    justifyContent: "space-between",
    padding: theme.spacing(1),
    paddingRight:
      props.onClose !== undefined ? theme.spacing(0.5) : theme.spacing(2),
    borderColor: getColor(theme, props.variant ?? "default"),
    borderStyle: props.onClose || props.forceBorder ? "solid" : "none",
    borderWidth: 1,
    width: props.width,
  },
  left: {
    width: "100%",
    alignItems: "center",
    display: "flex",
    gap: theme.spacing(1),
  },
  content: {
    display: "flex",
    width: "100%",
    justifyContent: "space-between",
    alignItems: "center",
  },
  icon: {
    height: 16,
    width: 16,
    color: getColor(theme, props.variant ?? "default"),
  },
  closeBtn: {
    border: "none",
    background: "transparent",
    padding: 0,
    display: "flex",
    alignItems: "center",
    cursor: "pointer",
  },
  closeIcon: {
    width: 9,
    height: 9,
    marginRight: 13,
    marginLeft: 13,
  },
}));
