import { constant, guard, nullable, number, object, string } from "decoders";

import Record from "./Record";
import { JSONAPIResourceOrRecord } from "./types";

const apiSummaryMetricsDecoder = object({
  type: constant("summary_metrics" as const),
  id: string,
  attributes: object({
    company_id: number,
    total_revenue_potential: nullable(number),
    new_prospects_revenue_potential: nullable(number),
    new_prospects_count: nullable(number),
    prospects_no_opportunities_revenue_potential: nullable(number),
    prospects_no_opportunities_count: nullable(number),
    prospects_with_opportunities_revenue_potential: nullable(number),
    prospects_with_opportunities_count: nullable(number),
  }),
});

const apiSummaryMetricsGuard = guard(apiSummaryMetricsDecoder);

class SummaryMetrics extends Record<"summary_metrics"> {
  companyId: number;
  totalRevenuePotential: number | null;
  newProspectsRevenuePotential: number | null;
  newProspectsCount: number | null;
  prospectsNoOpportunitiesRevenuePotential: number | null;
  prospectsNoOpportunitiesCount: number | null;
  prospectsWithOpportunitiesRevenuePotential: number | null;
  prospectsWithOpportunitiesCount: number | null;

  constructor(resource: JSONAPIResourceOrRecord<"summary_metrics">) {
    super(resource);
    const { attributes } = apiSummaryMetricsGuard(resource);
    this.companyId = attributes.company_id;
    this.totalRevenuePotential = attributes.total_revenue_potential;
    this.newProspectsRevenuePotential =
      attributes.new_prospects_revenue_potential;
    this.newProspectsCount = attributes.new_prospects_count;
    this.prospectsNoOpportunitiesRevenuePotential =
      attributes.prospects_no_opportunities_revenue_potential;
    this.prospectsNoOpportunitiesCount =
      attributes.prospects_no_opportunities_count;
    this.prospectsWithOpportunitiesRevenuePotential =
      attributes.prospects_with_opportunities_revenue_potential;
    this.prospectsWithOpportunitiesCount =
      attributes.prospects_with_opportunities_count;
  }
}

export default SummaryMetrics;
