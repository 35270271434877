import { AvailableDirectoryColumns } from "redux/directory/defaults";
import {
  ColumnConfigType,
  FieldType,
  FilterType,
  MatchFieldType,
  SortType,
} from "screens/Frontoffice/screens/DataTables/shared/types";

export const fields: { [key: string]: FieldType } = {
  [AvailableDirectoryColumns.name]: {
    label: "Company",
    type: MatchFieldType.TEXT,
    options: {},
    smartField: true,
    fullyImported: true,
    isDisabled: false,
    noSide: true,
    isPrivate: false,
    partnerField: false,
    sort: true,
    filter: false,
    column: true,
  },
  [AvailableDirectoryColumns.businessType]: {
    label: "Company Type",
    type: MatchFieldType.PICKLIST,
    options: {},
    smartField: true,
    fullyImported: true,
    isDisabled: false,
    noSide: true,
    isPrivate: false,
    partnerField: false,
    sort: false,
    filter: true,
    column: true,
  },
  [AvailableDirectoryColumns.ecosystem]: {
    label: "Company Ecosystem",
    type: MatchFieldType.PICKLIST,
    options: {},
    smartField: true,
    fullyImported: true,
    isDisabled: false,
    noSide: true,
    isPrivate: false,
    partnerField: false,
    sort: false,
    filter: true,
    column: true,
  },
  [AvailableDirectoryColumns.marketPresence]: {
    label: "Market Presence",
    type: MatchFieldType.PICKLIST,
    options: {},
    smartField: true,
    fullyImported: true,
    isDisabled: false,
    noSide: true,
    isPrivate: false,
    partnerField: false,
    sort: false,
    filter: true,
    column: true,
  },
  [AvailableDirectoryColumns.accountOverlap]: {
    label: "Account Overlap",
    type: MatchFieldType.NUMBER,
    options: {},
    smartField: true,
    fullyImported: true,
    isDisabled: false,
    noSide: true,
    isPrivate: false,
    partnerField: false,
    sort: true,
    filter: false,
    column: true,
  },
  [AvailableDirectoryColumns.contactOverlap]: {
    label: "Contact Overlap",
    type: MatchFieldType.NUMBER,
    options: {},
    smartField: true,
    fullyImported: true,
    isDisabled: false,
    noSide: true,
    isPrivate: false,
    partnerField: false,
    sort: true,
    filter: false,
    column: true,
  },
  [AvailableDirectoryColumns.partner]: {
    headerAlign: "right",
    label: "Action",
    type: MatchFieldType.UNKNOWN,
    options: {},
    smartField: true,
    fullyImported: true,
    isDisabled: false,
    noSide: true,
    isPrivate: false,
    partnerField: false,
    sort: false,
    filter: false,
    column: true,
  },
};

export type DirectoryViewParameters = {
  filters?: FilterType[];
  columns?: ColumnConfigType[];
  sort?: SortType[];
  lastVisitedAt?: Date;
};
