import { makeStyles } from "makeStyles";

import OperatorAddWidget from "./OperatorAddWidget";
import SWQLBooleanNode from "./SWQLBooleanNode";
import { SWQLContextProvider } from "./SWQLContext";
import {
  SWQLBooleanOperator,
  SWQLField,
  SWQLLookupNode,
  SWQLNode,
  SWQLTarget,
} from "./SWQLTypes";

type Props = {
  integrationId: string | number;
  node: SWQLLookupNode;
  onUpdate: (rule: SWQLNode) => void;
  swqlTarget: SWQLTarget;
  enableDateTimes?: boolean;
  onAddNode: (operator: SWQLBooleanOperator) => void;
  fields: SWQLField[];
};

const SWQLLookup = ({
  integrationId,
  node,
  onUpdate,
  swqlTarget,
  enableDateTimes = false,
  onAddNode,
  fields,
}: Props) => {
  const { classes } = useStyles();
  return (
    <>
      <div className={classes.root}>
        <SWQLContextProvider
          integrationId={integrationId}
          rootNode={node.rule}
          onRootUpdate={onUpdate}
          swqlTarget={[swqlTarget]}
          enableDateTimes={enableDateTimes}
          fields={fields}
        >
          <SWQLBooleanNode pathToNode={[]} />
        </SWQLContextProvider>
      </div>
      <OperatorAddWidget onAddNode={onAddNode} />
    </>
  );
};

export default SWQLLookup;

// CSS

const useStyles = makeStyles()((theme) => ({
  root: {
    background: `linear-gradient(${theme.palette.ivory}, ${theme.palette.ivory}) padding-box, ${theme.palette.gradients.darkPurpleToGreen} border-box`,
    borderColor: theme.palette.ivory,
    borderStyle: "dashed",
    borderRadius: 16,
    borderWidth: 1,
    display: "flex",
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "flex-start",
    minWidth: 300,
  },
}));
