import Alert from "components/ui/Alert";
import { defineMessages, FormattedMessage } from "react-intl";

import { CellProps } from "../utils";

const MissingValueCell = () => {
  return (
    <Alert variant="warning" icon width="100%">
      <FormattedMessage {...i18n.missingFromYourCrm} />
    </Alert>
  );
};

export default MissingValueCell;

// Helpers

export const isMissingValueCell = (props: CellProps) => {
  const { fieldName, row } = props;
  // On referred table, if there is not value for the right column, we dislay a warning message
  return (
    row.type === "referred_accounts" &&
    fieldName.startsWith("left") &&
    !row.matchingRawCompanyId
  );
};

// I18N

const i18n = defineMessages({
  missingFromYourCrm: {
    id: "ui.datagrid.cellRenderers.MissingValueCell",
    defaultMessage: "Missing from your CRM",
  },
});
