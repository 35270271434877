import TabList from "components/ui/TabList";
import { T } from "components/ui/Typography";
import useUserProfile from "hooks/useUserProfile";
import _ from "lodash";
import { makeStyles } from "makeStyles";
import Partnership from "models/Partnership";
import { MouseEvent, useCallback, useMemo } from "react";
import { defineMessages, FormattedMessage } from "react-intl";
import { useHistory } from "react-router-dom";
import { SharedFilters } from "redux/overview/types";
import {
  FilterType,
  MatchFilterType,
} from "screens/Frontoffice/screens/DataTables/shared/types";

type Props = {
  filters: FilterType[];
  onChange: (filters: FilterType[]) => void;
  partnership: Partnership;
};

const OverviewSharedFilters = ({ filters, onChange, partnership }: Props) => {
  const { classes } = useStyles();
  const history = useHistory();
  const { profile } = useUserProfile();

  const tab = filters.find(
    (filter) =>
      filter.fieldname === "leftStarred" &&
      filter.value === true &&
      filter.type === MatchFilterType.IS
  )
    ? SharedFilters.strategic
    : SharedFilters.all;

  const cancelEvent = (event: MouseEvent) => {
    event.preventDefault();
    event.stopPropagation();
  };

  const goToStrategicAccountsSettings = useCallback(() => {
    const integration = partnership.integrations?.[0];
    if (!integration) {
      return;
    }
    history.push({
      pathname: `/sources`,
      search: `?integration=${integration.id}&provider=${integration.provider}`,
      hash: "strategic-accounts",
    });
  }, [history, partnership.integrations]);

  const tabs = useMemo(
    () => [
      {
        value: SharedFilters.all,
        label: <FormattedMessage {...i18n.all} />,
      },
      {
        value: SharedFilters.strategic,
        label: <FormattedMessage {...i18n.strategic} />,
        tooltip: profile.canManageSources ? (
          <div onClick={cancelEvent}>
            <T>
              Add your strategic account&nbsp;
              <span
                className={classes.tooltipLink}
                onClick={goToStrategicAccountsSettings}
              >
                <FormattedMessage {...i18n.here} />
              </span>
            </T>
          </div>
        ) : undefined,
      },
    ],
    [
      classes.tooltipLink,
      goToStrategicAccountsSettings,
      profile.canManageSources,
    ]
  );

  const handleToggleChange = useCallback(
    (value: SharedFilters) => {
      const filtersWithoutStarred = filters.filter(
        (val) => val.fieldname !== "leftStarred"
      );
      onChange(
        value === SharedFilters.all
          ? filtersWithoutStarred
          : _.concat(filtersWithoutStarred, [
              {
                fieldname: "leftStarred",
                type: MatchFilterType.IS,
                value: true,
              },
            ])
      );
    },
    [onChange, filters]
  );

  return (
    <div className={classes.root}>
      <TabList<SharedFilters>
        tabs={tabs}
        selectedValue={tab}
        setValue={handleToggleChange}
      />
    </div>
  );
};

/// CSS

const useStyles = makeStyles()((theme) => ({
  root: {
    display: "flex",
    alignItems: "flex-start",
    justifyContent: "space-between",
    padding: theme.spacing(1, 0),
  },
  right: {
    display: "flex",
    alignItems: "flex-start",
    maxWidth: 165,
  },
  tooltipLink: {
    cursor: "pointer",
    textDecoration: "underline",
  },
}));

/// I18N

const i18n = defineMessages({
  all: {
    id: "Overview.OverviewSharedFilters.all",
    defaultMessage: "All",
  },
  here: {
    id: "Overview.OverviewSharedFilters.here",
    defaultMessage: "here",
  },
  strategic: {
    id: "Overview.OverviewSharedFilters.strategic",
    defaultMessage: "Strategic Accounts",
  },
});

/// Exports

export default OverviewSharedFilters;
