import { T } from "components/ui/Typography";
import { makeStyles } from "makeStyles";
import { FormattedMessage } from "react-intl";
import { Link, useLocation } from "react-router-dom";

import i18n from "./i18n";

const LinkToLogin = () => {
  const { classes } = useStyles();
  const { search } = useLocation();

  return (
    <T textAlign="center">
      <FormattedMessage {...i18n.alreadyMember} />{" "}
      <Link to={`/login${search}`} className={classes.linkColor}>
        <FormattedMessage {...i18n.login} />
      </Link>
    </T>
  );
};

export default LinkToLogin;

// CSS

const useStyles = makeStyles()((theme) => ({
  linkColor: {
    color: theme.palette.link,
  },
}));
