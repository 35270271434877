import { useEffect, useRef } from "react";

const useDebouncedEffect = (
  fn: Function,
  deps: string = "[]",
  debounce: number = 500
) => {
  const handleRef = useRef<number | null>(null);

  useEffect(() => {
    if (handleRef.current) {
      clearTimeout(handleRef.current);
      handleRef.current = null;
    }
    handleRef.current = setTimeout(fn, debounce);
    return () => {
      if (handleRef.current) {
        clearTimeout(handleRef.current);
      }
    };
  }, [deps]); // eslint-disable-line react-hooks/exhaustive-deps
};

export default useDebouncedEffect;
