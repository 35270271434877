import _ from "lodash";
import PartnerConnection from "models/PartnerConnection";
import { PartnershipStatus } from "models/Partnership";
import User from "models/User";
import { DateRangeFilterType, DateRangeTimeframe } from "redux/typing";
import { extractMinAndMaxDates } from "redux/utils";
import { createSelector } from "reselect";
import { FilterType } from "screens/Frontoffice/screens/DataTables/shared/types";

import { AvailablePipelineAttributeColumns } from "./defaults";
import {
  APIEntities,
  HiddenByFiltersReasons,
  PartnershipFilterData,
  PipelineAttributeExpectedState,
  RawCompanyIdToPartnerMap,
} from "./typing";

/**
 * Top level ("slice") selectors: using these we guarantee memoization of them
 * when accessing each via `createSelector()`, thus preventing recomputation.
 */

const selectEntitiesSlice = (state: PipelineAttributeExpectedState) =>
  state.api.entities;

export const selectPipelineAttributeSlice = (
  state: PipelineAttributeExpectedState
) => state.pipelineAttribute;

export const selectPartnerConnections = createSelector(
  selectEntitiesSlice,
  (entities: APIEntities) => {
    return {
      partnerConnections: _.values(entities.partner_connections),
    };
  }
);

export const selectHasFilterApplied = (
  state: PipelineAttributeExpectedState
) => {
  const filters: FilterType[] = state.pipelineAttribute.view?.filters ?? [];
  const appliedFilters = filters.filter(
    (item) => !["user"].includes(item.fieldname)
  );
  let hasFilterApplied = false;
  _.each(appliedFilters, (filter) => {
    if (
      filter.fieldname === "partnership" ||
      filter.fieldname === "date_range"
    ) {
      if (filter.value !== null) {
        hasFilterApplied = true;
      }
    } else {
      hasFilterApplied = true;
    }
  });
  return hasFilterApplied;
};

export const selectCurrentUser = (state: PipelineAttributeExpectedState) =>
  state.user.profile.data;

export const selectSelectedPartnerIds = (
  state: PipelineAttributeExpectedState
) => {
  const filters: FilterType[] = state.pipelineAttribute.view?.filters ?? [];
  return (
    filters.find((item) => item.fieldname === "partner_attribution")?.value ??
    null
  );
};

export const selectShowArchived = (state: PipelineAttributeExpectedState) => {
  const filters: FilterType[] = state.pipelineAttribute.view?.filters ?? [];
  return filters.find((item) => item.fieldname === "archived")?.value ?? false;
};

export const selectDateRangeFilterData = (
  state: PipelineAttributeExpectedState
) => {
  const filters: FilterType[] = state.pipelineAttribute.view?.filters ?? [];
  const dateRange =
    filters.find((item) => item.fieldname === "date_range")?.value ?? null;
  if (dateRange && dateRange.rangeType === DateRangeTimeframe.customRange) {
    return {
      ...dateRange,
      selectionRange: {
        start: new Date(dateRange.selectionRange.start),
        end: new Date(dateRange.selectionRange.end),
      },
    };
  }

  return dateRange;
};

export const selectTableFilters = (state: PipelineAttributeExpectedState) => {
  const filters: FilterType[] = state.pipelineAttribute.view?.filters ?? [];
  return filters.filter((item) =>
    _.values(AvailablePipelineAttributeColumns).includes(
      item.fieldname as AvailablePipelineAttributeColumns
    )
  );
};

export const selectIsCreatedRecordFilteredOut = createSelector(
  selectCurrentUser,
  selectSelectedPartnerIds,
  selectDateRangeFilterData,
  selectTableFilters,
  (
    currentUser: User,
    partnershipIds: number[],
    dateRange: DateRangeFilterType,
    tableFilters: FilterType[]
  ) => (createdRecord: PartnerConnection): HiddenByFiltersReasons => {
    return {
      partnershipId: !matchesPartnershipFilter(createdRecord, partnershipIds),
      dateRange:
        dateRange !== null && !matchesDateRange(createdRecord, dateRange),
      tableFilters: hasTableFilters(currentUser.id, tableFilters),
    };
  }
);

export const selectRawCompanyIdToPartnerMap = createSelector(
  selectPartnerConnections,
  selectCurrentUser,
  (
    { partnerConnections }: { partnerConnections: PartnerConnection[] },
    currentUser: User
  ) =>
    _.reduce(
      partnerConnections.filter(
        (partnerConnection) =>
          partnerConnection.userId === currentUser.id &&
          partnerConnection.archivedAt === null
      ),
      (acc, partnerConnection) => {
        if (partnerConnection.rawCompanyId !== null) {
          if (acc[partnerConnection.rawCompanyId] === undefined) {
            acc[partnerConnection.rawCompanyId] = [
              partnerConnection.partnershipId,
            ];
          } else if (
            !acc[partnerConnection.rawCompanyId].includes(
              partnerConnection.partnershipId
            )
          ) {
            acc[partnerConnection.rawCompanyId] = [
              ...acc[partnerConnection.rawCompanyId],
              partnerConnection.partnershipId,
            ];
          }
        }
        return acc;
      },
      {} as RawCompanyIdToPartnerMap
    )
);

// export const selectFields = (state: PipelineAttributeExpectedState) => {
//   const view = selectCurrentView(state);
//   switch (view) {
//     case PipelineViewType.mine:
//     case PipelineViewType.all:
//       return {
//         loading: state.pipelineAttribute.fieldsState.loading,
//         fields: _.pickBy(state.pipelineAttribute.fieldsState.fields, (field: PipelineField) => field.partnershipId === null),
//       };

//     case PipelineViewType.partner:
//       const partnershipIds = selectSelectedPartnerIds(state);

//       if (partnershipIds === null || partnershipIds.length !== 1) {
//         // The app should enforce that the partner view is only available when exactly one partner is selected
//         // so we should never be in that position. Return all fields instead of raising an error since redux has no
//         // way to enforce that this state configuration never happen
//         return state.pipelineAttribute.fieldsState;
//       }

//       return {
//         loading: state.pipelineAttribute.fieldsState.loading,
//         fields: _.pickBy(state.pipelineAttribute.fieldsState.fields, (field: PipelineField) => field.partnershipId === partnershipIds[0]),
//       };
//     default:
//       return {
//         loading: false,
//         fields: {},
//       };
//   }
// };

// Helpers

const hasTableFilters = (currentUserId: number, tableFilters: FilterType[]) => {
  if (tableFilters.length > 0) {
    const onlyFilterIsMine =
      tableFilters.length === 1 &&
      tableFilters[0].fieldname === "user" &&
      tableFilters[0].value[0] === String(currentUserId);
    if (onlyFilterIsMine) {
      return false;
    }
    return true;
  }
  return false;
};

const matchesPartnershipFilter = (
  value: PartnerConnection,
  partnershipIds: number[] | null
) =>
  partnershipIds === null ||
  (value.partnershipId === null
    ? partnershipIds.length === 0
    : partnershipIds.includes(value.partnershipId));

const matchesDateRange = (
  record: PartnerConnection,
  dateRange: DateRangeFilterType
) => {
  const [minDate, maxDate] = extractMinAndMaxDates(dateRange);
  const createdAt = new Date(record.createdAt);
  return (
    (minDate === null || minDate <= createdAt) &&
    (maxDate === null || maxDate >= createdAt)
  );
};

export const selectPartnershipsFilterData = (
  state: PipelineAttributeExpectedState
) => {
  const availablePartnerships = _.values(
    state.api.entities.partnerships ?? []
  ).reduce((acc, partnership) => {
    if (partnership.status === PartnershipStatus.Declined) {
      return acc;
    }
    const partner = partnership.getPartner(selectCurrentUser(state));
    return [
      ...acc,
      {
        id: partnership.id,
        partnerId: partner.id ?? null,
        name: partner.name,
        logo: partner.avatarUrl ?? null,
        owner: partnership.partnershipOwner,
      },
    ];
  }, [] as PartnershipFilterData[]);
  const filteredPartnerIds = selectSelectedPartnerIds(state);
  // If there is only one partnership available, we select it to allow sharing pipeline.
  const hasOnlyOnePartnership = availablePartnerships.length === 1;
  return {
    filteredPartnerIds: hasOnlyOnePartnership
      ? [availablePartnerships[0].id]
      : filteredPartnerIds,
    data: availablePartnerships,
  };
};
