import Button from "@mui/material/Button";
import DialogManager from "components/ui/DialogManager";
import Title from "components/ui/Title";
import { T } from "components/ui/Typography";
import { makeStyles } from "makeStyles";
import {
  defineMessages,
  FormattedHTMLMessage,
  FormattedMessage,
} from "react-intl";

const i18n = defineMessages({
  dialogTitle: {
    id: "ui.ConfirmShareFieldDialog.dialogTitle",
    defaultMessage:
      "Are you sure you want to share <b>{fieldName}</b> with {partnerName}?",
  },
  dialogText: {
    id: "ui.ConfirmShareFieldDialog.dialogText",
    defaultMessage:
      "This information will be accessible by all {partnerName} users.",
  },
  dialogSubtext: {
    id: "ui.ConfirmShareFieldDialog.dialogSubtext",
    defaultMessage: "Your workspace admins will be notified.",
  },
  cancel: {
    id: "ui.ConfirmShareFieldDialog.cancel",
    defaultMessage: "CANCEL",
  },
  confirm: {
    id: "ui.ConfirmShareFieldDialog.confirm",
    defaultMessage: "CONFIRM",
  },
});

const useStyles = makeStyles()((theme) => ({
  dialogBtn: {
    margin: theme.spacing(1),
  },
  dialogBtnText: {
    padding: `0px ${theme.spacing(1)}`,
  },
}));

const useDialogStyles = makeStyles()((theme) => ({
  dialogContainer: {
    "& .MuiDialogTitle-root": {
      margin: theme.spacing(2),
    },
    "& .MuiDialogContent-root": {
      margin: `${theme.spacing(1)} ${theme.spacing(2)}`,
    },
    "& .MuiDialogActions-root": {
      margin: theme.spacing(3),
    },
  },
}));

const useTitleStyles = makeStyles()((theme) => ({
  avatar: {
    width: 50,
    height: 50,
  },
}));

type Props = {
  dialogOpen: boolean;
  fieldName: string;
  handleCloseDialog: $TSFixMeFunction;
  handleShareField: $TSFixMeFunction;
  partner: $TSFixMe;
};

const ConfirmShareFieldDialog = ({
  dialogOpen,
  fieldName,
  handleCloseDialog,
  handleShareField,
  partner,
}: Props) => {
  const { classes } = useStyles();
  const { classes: dialogClasses } = useDialogStyles();
  const { classes: titleClasses } = useTitleStyles();

  return (
    <DialogManager
      modalStyle={dialogClasses}
      fullScreenBreakpoint="sm"
      handleClose={handleCloseDialog}
      isOpen={dialogOpen}
      DialogTitleComponent={
        <Title
          section={
            <FormattedHTMLMessage
              {...i18n.dialogTitle}
              values={{ fieldName: fieldName, partnerName: partner.name }}
            />
          }
          avatar={partner.avatarUrl}
          variant="h3"
          gutter={false}
          classes={titleClasses}
        />
      }
      DialogContentComponent={
        <>
          <T h4>
            <FormattedMessage
              {...i18n.dialogText}
              values={{ partnerName: partner.name }}
            />
          </T>
          <T h4 oldVariant="h4_fontWeight:300_fontStyle:italic">
            <FormattedMessage {...i18n.dialogSubtext} />
          </T>
        </>
      }
      DialogActionsComponent={
        <>
          <Button
            onClick={handleCloseDialog}
            variant="contained"
            color="secondary"
            className={classes.dialogBtn}
          >
            <T
              oldVariant="body1_fontSize:15px"
              className={classes.dialogBtnText}
            >
              <FormattedMessage {...i18n.cancel} />
            </T>
          </Button>
          <Button
            onClick={handleShareField}
            variant="contained"
            color="primary"
            className={classes.dialogBtn}
          >
            <T
              oldVariant="body1_fontSize:15px"
              className={classes.dialogBtnText}
            >
              <FormattedMessage {...i18n.confirm} />
            </T>
          </Button>
        </>
      }
    />
  );
};

export default ConfirmShareFieldDialog;
