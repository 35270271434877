export const included = [
  "account_mapping_settings",
  "dest_company",
  "initiator_company",
  "initiator_user",
  "insights",
  "integrations",
  "partnership_integrations_settings",
  "sharing_settings",
  "watchers",
];

export const fields = {
  partnerships: [
    ...included,
    "is_new_leads_enabled",
    "requested_company_name",
    "requested_company_domain",
    "status",
    "watcher_ids",
    "ready",
  ],
};
