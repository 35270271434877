import { defineMessages, IntlShape } from "react-intl";

type DifferenceType =
  | "years"
  | "months"
  | "weeks"
  | "days"
  | "hours"
  | "minutes";

function getDifference(date1: Date, date2: Date, type: DifferenceType) {
  let typeConst;
  switch (type) {
    case "years":
      typeConst = 1000 * 60 * 60 * 24 * 7 * 4 * 12;
      break;
    case "months":
      typeConst = 1000 * 60 * 60 * 24 * 7 * 4;
      break;
    case "weeks":
      typeConst = 1000 * 60 * 60 * 24 * 7;
      break;
    case "days":
      typeConst = 1000 * 60 * 60 * 24;
      break;
    case "hours":
      typeConst = 1000 * 60 * 60;
      break;
    default:
      typeConst = 1000 * 60;
  }

  const diffInMs = Math.abs(date2.getTime() - date1.getTime());
  return Math.trunc(diffInMs / typeConst);
}

export function getTimeSince(notificationDate: string, intl: IntlShape) {
  const today = new Date(Date.now());
  const lastUpdate = new Date(notificationDate);

  let minutesDiff = getDifference(today, lastUpdate, "minutes");
  let hoursDiff = getDifference(today, lastUpdate, "hours");
  let daysDiff = getDifference(today, lastUpdate, "days");
  let weeksDiff = getDifference(today, lastUpdate, "weeks");
  let monthsDiff = getDifference(today, lastUpdate, "months");
  let yearsDiff = getDifference(today, lastUpdate, "years");
  if (minutesDiff < 60) {
    return intl.formatMessage(i18n.min, {
      count: minutesDiff,
    });
  } else if (hoursDiff < 24) {
    return intl.formatMessage(i18n.hours, {
      count: hoursDiff,
    });
  } else if (daysDiff < 7) {
    return intl.formatMessage(i18n.days, {
      count: daysDiff,
    });
  } else if (weeksDiff < 4) {
    return intl.formatMessage(i18n.weeks, {
      count: weeksDiff,
    });
  } else if (monthsDiff < 12) {
    return intl.formatMessage(i18n.months, {
      count: monthsDiff,
    });
  } else {
    return intl.formatMessage(i18n.years, {
      count: yearsDiff,
    });
  }
}

// I18N

const i18n = defineMessages({
  min: {
    id: "Navbar.NotificationItem.min",
    defaultMessage: "{count} min ago",
  },
  hours: {
    id: "Navbar.NotificationItem.hours",
    defaultMessage: "{count} {count, plural, one {hour} other {hours}} ago",
  },
  days: {
    id: "Navbar.NotificationItem.days",
    defaultMessage: "{count} {count, plural, one {day} other {days}} ago",
  },
  weeks: {
    id: "Navbar.NotificationItem.weeks",
    defaultMessage: "{count} {count, plural, one {week} other {weeks}} ago",
  },
  months: {
    id: "Navbar.NotificationItem.months",
    defaultMessage: "{count} {count, plural, one {month} other {months}} ago",
  },
  years: {
    id: "Navbar.NotificationItem.years",
    defaultMessage: "{count} {count, plural, one {year} other {years}} ago",
  },
});
