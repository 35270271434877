import CompanyAvatar from "components/ui/avatars/CompanyAvatar";
import Checkbox from "components/ui/Checkbox";
import Tooltip from "components/ui/Tooltip";
import { T } from "components/ui/Typography";
import { makeStyles } from "makeStyles";
import { defineMessages, useIntl } from "react-intl";

import { StakeholderRow } from "../types";

type Props = {
  title: string;
  logo: string;
  stakeholderRowList: StakeholderRow[];
};

export const NotifySection = ({ title, logo, stakeholderRowList }: Props) => {
  const { classes } = useStyles();
  const intl = useIntl();

  return (
    <div className={classes.container}>
      <div className={classes.heading}>
        <CompanyAvatar size="xs" src={logo} />
        <T bodyBig bold>
          {title}
        </T>
      </div>
      <div className={classes.divider} />
      <div className={classes.checkContainer}>
        {stakeholderRowList.map((row) => (
          <div className={classes.checkRow}>
            <div className={classes.label}>
              {row.logo && <CompanyAvatar size="xxs" src={row.logo} />}
              <T bold>{row.label}</T>
            </div>
            <Tooltip hidden={!row.tooltip} placement="left" title={row.tooltip}>
              <Checkbox
                checked={row.isChecked}
                onChange={row.onChange}
                disabled={row.isDisabled}
                label={intl.formatMessage(i18n.sendEmail)}
                withoutMargin
              />
            </Tooltip>
          </div>
        ))}
      </div>
    </div>
  );
};

export const useStyles = makeStyles()((theme) => ({
  container: {
    borderRadius: 8,
    border: "1px solid  #E9E5DB",
  },
  heading: {
    display: "flex",
    alignItems: "center",
    gap: 10,
    padding: 16,
  },
  checkContainer: {
    display: "flex",
    flexDirection: "column",
    gap: 12,
    padding: 16,
  },
  checkRow: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    "& .MuiButtonBase-root": {
      padding: 0,
      marginRight: 6,
    },
  },
  divider: {
    width: "100%",
    height: 1,
    background: "#E9E5DB",
  },
  loader: {
    display: "flex",
    justifyContent: "center",
    marginTop: 100,
  },
  label: {
    display: "flex",
    alignItems: "center",
    gap: 6,
  },
}));

const i18n = defineMessages({
  sendEmail: {
    id: "CollaborationTab.NotifySection.sendEmail",
    defaultMessage: "Send Email",
  },
});
