import { createSvgIcon } from "@mui/material/utils";

import { SvgIcon } from "./types";

const displayName = "Hide";

const Hide: SvgIcon = createSvgIcon(
  // Using transform to center the icon.
  // initial size: "0 0 16 16" to "0 0 24 24"
  <g fill="none" transform="matrix(1.5,0,0,1.5,0,0)">
    <path
      d="M6.97898 6.19922C6.38773 6.56306 5.99121 7.23327 5.99121 7.99986C5.99121 9.15436 6.89056 10.0903 7.99997 10.0903C8.73661 10.0903 9.38065 9.67762 9.73027 9.06235L8.97014 8.27131C8.85528 8.71696 8.46445 9.04506 7.99997 9.04506C7.44527 9.04506 6.99559 8.57711 6.99559 7.99986C6.99559 7.5165 7.31088 7.10977 7.73911 6.99025L6.97898 6.19922Z"
      fill="currentColor"
    />
    <path
      d="M4.7959 3.95594C3.21849 4.85795 2.21266 6.28523 1.70489 7.16635C1.42519 7.65169 1.42519 8.24343 1.70489 8.72877C2.49196 10.0945 4.47567 12.7726 7.92991 12.7726C9.58657 12.7726 10.905 12.1566 11.9154 11.3648L11.2045 10.625C10.3491 11.2635 9.26393 11.7386 7.92991 11.7386C5.0236 11.7386 3.2984 9.4836 2.55695 8.197C2.4659 8.039 2.4659 7.85612 2.55695 7.69811C3.06545 6.81574 4.03666 5.47786 5.53424 4.72429L4.7959 3.95594Z"
      fill="currentColor"
    />
    <path
      d="M11.9692 9.95862C12.5722 9.35186 13.0128 8.70033 13.3029 8.197C13.3939 8.039 13.3939 7.85612 13.3029 7.69811C12.5614 6.41152 10.8362 4.15649 7.92991 4.15649C7.44113 4.15649 6.98575 4.22027 6.56284 4.3325L5.76571 3.50298C6.41071 3.26582 7.13098 3.12256 7.92991 3.12256C11.3841 3.12256 13.3679 5.80058 14.1549 7.16635C14.4346 7.65169 14.4346 8.24343 14.1549 8.72877C13.8479 9.26154 13.3588 9.99399 12.6713 10.6893L11.9692 9.95862Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M1.61877 1.35912C1.81488 1.15503 2.13285 1.15503 2.32897 1.35912L14.3815 13.9016C14.5776 14.1057 14.5776 14.4365 14.3815 14.6406C14.1854 14.8447 13.8674 14.8447 13.6713 14.6406L1.61877 2.09819C1.42265 1.8941 1.42265 1.56321 1.61877 1.35912Z"
      fill="currentColor"
    />
  </g>,
  displayName
);

Hide.displayName = displayName;
export default Hide;
