import { CircularProgress } from "@mui/material";
import SettingsSection from "components/ui/SettingsSection";
import { T } from "components/ui/Typography";
import muiTheme from "config/theme";
import { makeStyles } from "makeStyles";
import { defineMessages, FormattedMessage } from "react-intl";
import { Link } from "react-router-dom";

import { TabProps } from "../../types";
import { useCollaborationTab } from "../hooks/useCollaborationTab";
import { GetIntroContent } from "./GetIntroContent";
import { GetIntroToggle } from "./GetIntroToggle";

const CollaborationTab = (props: TabProps) => {
  const { classes } = useStyles();
  const { partner, saving } = props;
  const {
    hasRestrictPartnersCRMSyncPayingFeature,
    canEditIntroForm,
    upgradeIntroForm,
    // Get Intro Toggle
    getIntroEnabled,
    setGetIntroEnabled,
  } = useCollaborationTab(props);

  return (
    <SettingsSection>
      <div className={classes.heading}>
        <T h3 bold>
          <FormattedMessage
            {...i18n.title}
            values={{ partner: partner.name }}
          />
        </T>
        <T>
          <FormattedMessage {...i18n.description} />
        </T>
        <T color={muiTheme.palette.alpha500}>
          <FormattedMessage {...i18n.getIntroTemplate} />
        </T>
      </div>
      {canEditIntroForm && (
        <T color={muiTheme.palette.alpha500}>
          <FormattedMessage
            {...i18n.editForm}
            values={{
              lnk: (chunks: string) => (
                <Link target="_blank" to={"/settings/get-intro-templates"}>
                  {chunks}
                </Link>
              ),
            }}
          />
        </T>
      )}
      {upgradeIntroForm && (
        <span className={classes.link} onClick={() => upgradeIntroForm()}>
          <T>
            <FormattedMessage {...i18n.upgrade} />
          </T>
        </span>
      )}

      <div className={classes.container}>
        {saving && <CircularProgress size={12} className={classes.saving} />}
        <GetIntroToggle
          saving={saving}
          status={getIntroEnabled}
          setGetIntroEnabled={setGetIntroEnabled}
          hasRestrictPartnersCRMSyncPayingFeature={
            hasRestrictPartnersCRMSyncPayingFeature
          }
        />
        {getIntroEnabled && <GetIntroContent {...props} />}
      </div>
    </SettingsSection>
  );
};

export default CollaborationTab;

export const useStyles = makeStyles()((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    rowGap: theme.spacing(4),
  },
  heading: {
    display: "flex",
    flexDirection: "column",
    gap: 6,
  },
  container: {
    display: "flex",
    flexDirection: "column",
    rowGap: theme.spacing(4),
    marginTop: 12,
  },
  saving: {
    position: "absolute",
    top: 20,
    right: 20,
  },
  link: {
    cursor: "pointer",
    color: "#007bff",
    "&:hover": {
      textDecoration: "underline",
    },
  },
}));

const i18n = defineMessages({
  title: {
    id: "CollaborationTab.title",
    defaultMessage: "Send an intro to {partner}",
  },
  description: {
    id: "CollaborationTab.description",
    defaultMessage:
      "Intro requests are a way for your Sales team to request help on deals. Each request creates a new Discussion with the partner.",
  },
  getIntroTemplate: {
    id: "CollaborationTab.getIntroTemplate",
    defaultMessage:
      "Reveal allows you to customize the intro request form that your Sales team must complete in order to submit their request.",
  },
  editForm: {
    id: "CollaborationTab.editForm",
    defaultMessage: "Edit your Get Intro form <lnk>here</lnk>.",
  },
  upgrade: {
    id: "CollaborationTab.upgrade",
    defaultMessage: "Upgrade to customize.",
  },
});
