import { createSvgIcon } from "@mui/material/utils";

import { SvgIcon } from "./types";

const displayName = "MessageFilled";

const MessageFilled: SvgIcon = createSvgIcon(
  // Using transform to enlarge from provided viewbox="0 0 16 16"
  // to default "0 0 24 24" from `createSvgIcon` -> `SvgIcon`.
  <g fill="none" transform="matrix(1.5,0,0,1.5,0,0)">
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M1.5 3.5C1.5 2.39543 2.39543 1.5 3.5 1.5H12.5C13.6046 1.5 14.5 2.39543 14.5 3.5V10.9615C14.5 12.0661 13.6046 12.9615 12.5 12.9615H9.352C9.13774 12.9615 8.92915 13.0304 8.75695 13.1578L4.92857 15.9923V12.9615H3.5C2.39543 12.9615 1.5 12.0661 1.5 10.9615V3.5Z"
      fill="currentColor"
    />
  </g>,
  displayName
);

MessageFilled.displayName = displayName;
export default MessageFilled;
