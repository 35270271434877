import Alert from "components/ui/Alert";
import Button from "components/ui/Button";
import usePushNotification from "hooks/usePushNotification";
import { makeStyles } from "makeStyles";
import User from "models/User";
import { useState } from "react";
import { defineMessages, FormattedMessage, useIntl } from "react-intl";
import { useDispatch } from "react-redux";
import { fetchProfile } from "redux/user/thunks";
import JSONAPIService from "services/JSONAPIService";

const i18n = defineMessages({
  confirmationMessage: {
    id: "Profile.UnconfirmedAlert.Message",
    defaultMessage:
      "Your email has not been confirmed yet. You should have received a link via e-mail on {date}.",
  },
  getInstructions: {
    id: "Profile.UnconfirmedAlert.GetInstructions",
    defaultMessage: "Get instructions",
  },
  confirmResent: {
    id: "Profile.UnconfirmedAlert.ConfirmResent",
    defaultMessage: "Confirmation instructions sent",
  },
});

type Props = {
  profile: User;
};

const UnconfirmedAlert = ({ profile }: Props) => {
  const { classes } = useStyles();
  const dispatch = useDispatch();
  const intl = useIntl();
  const pushNotification = usePushNotification();
  const [loading, setLoading] = useState(false);

  const resend = async () => {
    const service = new JSONAPIService("users");
    setLoading(true);
    await service.rawPost(
      profile.id,
      "/send-confirmation-instructions",
      undefined
    );
    pushNotification(i18n.confirmResent);
    await dispatch(fetchProfile());
    setLoading(false);
  };

  return (
    <div className={classes.root}>
      <Alert variant="warning" icon>
        <FormattedMessage
          {...i18n.confirmationMessage}
          values={{
            email: profile.email,
            date: intl.formatDate(new Date(profile.confirmationSentAt)),
          }}
        />
      </Alert>
      <Button
        label={i18n.getInstructions}
        onClick={resend}
        variant="quaternary"
        loading={loading}
      ></Button>
    </div>
  );
};

export default UnconfirmedAlert;

/// CSS

const useStyles = makeStyles()((theme) => ({
  root: {
    display: "flex",
    justifyContent: "space-between",
    gap: theme.spacing(1),
    alignItems: "center",
    [theme.breakpoints.down("md")]: {
      flexDirection: "column",
      alignItems: "flex-start",
    },
  },
}));
