import { Close } from "@mui/icons-material";
import { Box } from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import { ArrowRight } from "components/icons";
import Button from "components/ui/Button";
import { T } from "components/ui/Typography";
import { makeStyles } from "makeStyles";
import { defineMessages, FormattedMessage } from "react-intl";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { selectAnalyticsView } from "redux/analytics/selectors";
import { AnalyticsOtherFilterFieldname } from "redux/analytics/types";

import { AnalyticsViewParameters } from "../constants";

type AnalyticsEmptyRowsViewProps = {
  loading?: boolean;
  setView: (value: AnalyticsViewParameters) => void;
};

export const AnalyticsEmptyRowsView = ({
  loading,
  setView,
}: AnalyticsEmptyRowsViewProps) => {
  const { classes } = useStyles();
  const { view } = useSelector(selectAnalyticsView);
  const history = useHistory();

  const hasFilters = (view?.filters?.length ?? 0) > 0;

  const handleRedirectToDirectory = () => {
    history.push("/directory");
  };
  const handleClearFilters = () => {
    setView({
      filters: view?.filters.filter((filter) =>
        [
          AnalyticsOtherFilterFieldname.showSummaryMetrics,
          AnalyticsOtherFilterFieldname.highlightHighWinRate,
        ].includes(filter.fieldname as AnalyticsOtherFilterFieldname)
      ),
    });
  };

  return (
    <div className={classes.root}>
      {loading ? (
        <CircularProgress />
      ) : (
        <>
          <Box className={classes.logoContainer}>
            <img src="/images/magnifier.png" alt="" />
          </Box>
          <Box className={classes.text}>
            <T h3 bold>
              <FormattedMessage {...i18n.noResultsFound} />
            </T>
          </Box>
          <Box className={classes.subText}>
            <T>
              <FormattedMessage
                {...(hasFilters ? i18n.strongFilters : i18n.noPartners)}
              />
            </T>
          </Box>
          {!hasFilters && (
            <Box className={classes.button}>
              <Button
                label={i18n.goToDirectory}
                onClick={handleRedirectToDirectory}
                RightIcon={ArrowRight}
                variant="tertiary"
              />
            </Box>
          )}
          {hasFilters && (
            <Box className={classes.button}>
              <Button
                label={i18n.clearFilters}
                onClick={handleClearFilters}
                LeftIcon={Close}
                variant="tertiary"
              />
            </Box>
          )}
        </>
      )}
    </div>
  );
};

export default AnalyticsEmptyRowsView;

/// CSS

const useStyles = makeStyles()((theme) => ({
  button: {
    marginTop: theme.spacing(2.5),
  },
  root: {
    display: "flex",
    flexDirection: "column",
    height: "100%",
    alignItems: "center",
    justifyContent: "center",
    padding: theme.spacing(4),
  },
  logoContainer: {
    marginBottom: theme.spacing(1.5),
    "& > img": {
      width: 48,
      height: 48,
    },
  },
  subText: {
    marginBottom: theme.spacing(1),
    color: theme.palette.alpha750,
  },
  text: {
    marginBottom: theme.spacing(1),
  },
}));

// I18N

const i18n = defineMessages({
  clearFilters: {
    id: "Analytics.Content.AnalyticsEmptyRowsView.clearFilters",
    defaultMessage: "Clear all filters",
  },
  goToDirectory: {
    id: "Analytics.Content.AnalyticsEmptyRowsView.goToDirectory",
    defaultMessage: "Go to directory",
  },
  noResultsFound: {
    id: "Analytics.Content.AnalyticsEmptyRowsView.noResultsFound",
    defaultMessage: "No results found",
  },
  noPartners: {
    id: "Analytics.Content.AnalyticsEmptyRowsView.noPartners",
    defaultMessage:
      "Connect & share your data with your partners to assess their potential",
  },
  strongFilters: {
    id: "Analytics.Content.AnalyticsEmptyRowsView.strongFilters",
    defaultMessage: "You applied filters that were too restrictive",
  },
});
