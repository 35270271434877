import { createSvgIcon } from "@mui/material/utils";

import { SvgIcon } from "./types";

const displayName = "ArrowUp";

const ArrowUp: SvgIcon = createSvgIcon(
  // Using transform to enlarge from provided viewbox="0 0 16 16"
  // to default "0 0 24 24" from `createSvgIcon` -> `SvgIcon`.
  <g fill="none" transform="matrix(1.5,0,0,1.5,0,0)">
    <path
      d="M4.0203 7.02021C3.82504 7.21547 3.50846 7.21547 3.31319 7.02021C3.11793 6.82495 3.11793 6.50837 3.31319 6.3131L7.17512 2.45118C7.63073 1.99556 8.36943 1.99556 8.82504 2.45118L12.687 6.3131C12.8822 6.50837 12.8822 6.82495 12.687 7.02021C12.4917 7.21547 12.1751 7.21547 11.9799 7.02021L8.50008 3.54043V13.3333C8.50008 13.6095 8.27622 13.8333 8.00008 13.8333C7.72394 13.8333 7.50008 13.6095 7.50008 13.3333V3.54043L4.0203 7.02021Z"
      fill="currentColor"
    />
  </g>,
  displayName
);

ArrowUp.displayName = displayName;
export default ArrowUp;
