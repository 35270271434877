import { useEffect, useRef } from "react";

// first useLazyEffects callback trigger is caused by dependency update, not the component mounting

export const useLazyEffect: typeof useEffect = (cb, dep) => {
  const initializeRef = useRef(false);

  useEffect((...args) => {
    if (initializeRef.current) {
      cb(...args);
    } else {
      initializeRef.current = true;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, dep);
};
