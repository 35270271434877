import { T } from "components/ui/Typography";
import { makeStyles } from "makeStyles";
import { defineMessages, FormattedMessage } from "react-intl";

const useStyles = makeStyles()((theme) => ({
  image: {
    maxWidth: 300,
    marginBottom: theme.spacing(2),
  },
  text: {
    marginLeft: theme.spacing(3),
    marginRight: theme.spacing(3),
  },
  textErrorcoDE: {
    marginLeft: theme.spacing(3),
    marginRight: theme.spacing(3),
    marginBlock: theme.spacing(3),
    textAlign: "center",
  },
}));

const i18n = defineMessages({
  subtitle: {
    id: "NotFound.resourceSubtitle",
    defaultMessage: "The resource you are looking for is not available.",
  },
});

type Props = {
  error: $TSFixMe;
};

const NotFoundDialogContent = ({ error }: Props) => {
  const { classes } = useStyles();

  return (
    <>
      {error?.response?.status && (
        <T h4 textAlign="center" className={classes.text}>
          ({error.response.status})
        </T>
      )}
      <img
        alt="Resource not found."
        className={classes.image}
        src="/images/lost-404.png"
      />
      <T textAlign="center" className={classes.text}>
        <FormattedMessage {...i18n.subtitle} />
      </T>
    </>
  );
};

export default NotFoundDialogContent;
