import { ClassNameMap } from "@mui/material/styles";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import Tooltip from "components/ui/Tooltip";
import { T } from "components/ui/Typography";
import { makeStyles } from "makeStyles";
import { MouseEvent, ReactNode, useEffect, useRef, useState } from "react";
import { useMergedClasses } from "tss-react";

type Size = "large" | "normal" | "small";

type StyleProps = {
  fullWidth?: boolean;
  withBorder?: boolean;
  size?: Size;
};

type Props<T extends string | number> = Partial<StyleProps> & {
  value?: T;
  items: {
    value: T;
    label: JSX.Element | string | ReactNode;
    disabled?: boolean;
    hidden?: boolean;
    tooltip?: JSX.Element | string;
  }[];
  disabled?: boolean;
  classes?: ClassNameMap;
} & (
    | {
        setValue: (newValue: T) => void;
        allowUnselect?: undefined;
      }
    | {
        setValue: (newValue: T | null) => void;
        allowUnselect: true;
      }
  );

const ToggleBtnGroup = <T extends string | number>({
  value,
  setValue,
  items,
  disabled,
  classes: customRowClasses,
  size = "normal",
  fullWidth,
  withBorder,
  allowUnselect,
}: Props<T>) => {
  const [isOverflowing, setIsOverflowing] = useState(false);
  const actualSize = isOverflowing
    ? size === "normal"
      ? "large"
      : "normal"
    : size;
  const { classes } = useToggleButtonGroupStyles({
    size: actualSize,
    fullWidth,
    withBorder,
  });
  const toggleButtonGroupClasses = useMergedClasses(classes, customRowClasses);
  const { classes: toggleButtonClasses } = useToggleButtonStyles();

  const itemsRef = useRef<(HTMLButtonElement | null)[]>([]);

  const handleChange = (_event: MouseEvent<HTMLElement>, value: T) => {
    if (value !== null) {
      setValue(value);
      return;
    }
    if (allowUnselect) {
      setValue(value);
    }
  };

  useEffect(() => {
    if (isOverflowing) {
      return;
    }
    itemsRef.current.forEach((e) => {
      if (
        e &&
        (e.offsetHeight < e.scrollHeight || e.offsetWidth < e.scrollWidth)
      ) {
        setIsOverflowing(true);
      }
    });
  });

  return (
    <ToggleButtonGroup
      size="small"
      value={value}
      exclusive
      onChange={handleChange}
      classes={toggleButtonGroupClasses}
    >
      {items
        .filter((item) => !item.hidden)
        .map((item, index) => (
          <ToggleButton
            value={item.value}
            disabled={disabled || item.disabled}
            key={item.value}
            classes={toggleButtonClasses}
            ref={(el) => (itemsRef.current[index] = el)}
          >
            <Tooltip title={item.tooltip ?? ""}>
              <T bold>{item.label}</T>
            </Tooltip>
          </ToggleButton>
        ))}
    </ToggleButtonGroup>
  );
};

export default ToggleBtnGroup;

// CSS

const useToggleButtonGroupStyles = makeStyles<StyleProps>({
  name: { ToggleButtonGroup },
})((theme, { size, fullWidth, withBorder }) => ({
  test: {
    display: "flex",
    columnGap: 10,
  },
  root: {
    display: "flex",
    columnGap: size === "small" ? 2 : 0,
    height: size === "normal" ? 36 : size === "large" ? 46 : 28,
    borderRadius: 18,
    border: withBorder ? `1px solid ${theme.palette.alpha250}` : "none",
    backgroundColor: theme.palette.ivory,
    ...(fullWidth
      ? {
          width: "-webkit-fill-available",
          // @ts-ignore
          width: "-moz-available", // eslint-disable-line no-dupe-keys
          // @ts-ignore
          width: "fill-available", // eslint-disable-line no-dupe-keys
        }
      : {
          width: "fit-content",
        }),
  },
  grouped: {
    margin: size === "small" ? 1 : 4,
    border: "none",
    flexGrow: 1,
    "&:not(:first-child)": {
      borderRadius: 18,
    },
    "&:first-child": {
      borderRadius: 18,
    },
  },
}));

const useToggleButtonStyles = makeStyles()((theme) => ({
  root: {
    border: "none !important",
    padding: "9px 12px",
    textTransform: "none",
    columnGap: 8,
    "&:focus": {
      outline: 0,
    },
    "&:hover": {
      borderRadius: "18px !important",
      backgroundColor: theme.palette.alpha200,
    },
    "&.Mui-selected": {
      borderRadius: "18px !important",
      backgroundColor: theme.palette.midnight,
      "& > p": {
        color: theme.palette.offWhite,
      },
      "&:hover": {
        backgroundColor: theme.palette.midnight,
      },
    },
    "&.Mui-disabled": {
      pointerEvents: "auto",
      "& > p, & svg": {
        color: theme.palette.midnight500,
      },
      "&:hover": {
        backgroundColor: theme.palette.ivory,
      },
    },
    "& > p": {
      color: theme.palette.midnight,
    },
  },
}));
