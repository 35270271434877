import { isFulfilled } from "@reduxjs/toolkit";
import SummaryMetrics from "models/SummaryMetrics";
import { JSONAPIResourceOrRecord } from "models/types";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { selectSummaryMetricsEnabled } from "redux/analytics/selectors";
import { rawGet } from "redux/api/thunks";
import { JSONAPIListResponse } from "services/types";

const useSummaryMetricsAPILogic = () => {
  const dispatch = useDispatch();
  const summaryMetricsEnabled = useSelector(selectSummaryMetricsEnabled);
  const [record, setRecord] = useState<SummaryMetrics | null>(null);

  const getData = async () => {
    const result = await dispatch(
      rawGet({ type: "summary_metrics", path: "" })
    );

    if (isFulfilled(result)) {
      const response = result.payload as JSONAPIListResponse;
      if (response.data?.length > 0) {
        const record = new SummaryMetrics(
          response.data[0] as JSONAPIResourceOrRecord<"summary_metrics">
        );
        setRecord(record);
      } else {
        console.error("Undefined summary_metrics results");
      }
    }
  };

  useEffect(() => {
    if (summaryMetricsEnabled) {
      getData();
    }
  }, [summaryMetricsEnabled]); // eslint-disable-line react-hooks/exhaustive-deps

  return {
    summaryMetricsEnabled,
    summaryMetrics: record,
  };
};

export default useSummaryMetricsAPILogic;
