import { AxiosResponse } from "axios";
import { BaseQuickViews, QuickViewFilter } from "components/ui/BaseQuickViews";
import { ExtendedAccountMappingResource } from "components/ui/filters/smartView/constants";
import { quickViewsForSize } from "components/ui/filters/smartView/helpers";
import { SmartViewDropdown } from "components/ui/filters/smartView/SmartViewDropdown";
import { T } from "components/ui/Typography";
import getFirstValueFromURL from "helpers/getFirstValueFromURL";
import { useSmartViews } from "hooks/useSmartViews";
import Partnership, { OverviewFiltersType } from "models/Partnership";
import { SavedFilterType } from "models/SavedFilter";
import { useContext, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import { AccountMappingResource } from "redux/accountMapping/types";
import { initialize } from "redux/overview/thunks";
import { Preset } from "screens/Frontoffice/screens/DataTables/shared/types";
import JSONAPIService from "services/JSONAPIService";
import { JSONAPIResponse } from "services/types";

import MappingFilterContext from "../../../shared/contexts/MappingFilterContext";
import {
  allLeads,
  allNewProspects,
  allOverlaps,
  commonCustomers,
  commonOpportunities,
  customerMatchingProspects,
  leadsMatchingCustomers,
  leadsMatchingProspects,
  openOpportunitiesToCustomers,
  prospectMatchingCustomers,
  whitespaceIShare,
  whitespacePartnerShares,
} from "../shared/oldQuickFilterPresets";
import { AccountMappingStandardFields } from "../shared/types";

type Props = {
  onSelect: (data: Preset, viewType: ExtendedAccountMappingResource) => void;
  partnership: Partnership;
  accountType: AccountMappingResource;
  fields?: AccountMappingStandardFields;
  isSmall?: boolean;
};

const KPI_TO_SMART_VIEW: { [key: string]: any } = {
  common_customers: commonCustomers,
  common_opportunities: commonOpportunities,
  customers_to_prospects: customerMatchingProspects,
  prospects_to_customers: prospectMatchingCustomers,
  nb_of_new_prospects: whitespacePartnerShares,
  all_overlaps: allOverlaps,
  all_new_prospects: allNewProspects,
  partner_nb_of_new_prospects: whitespaceIShare,
  open_opportunities_to_customers: openOpportunitiesToCustomers,
};

const DROPDOWN_KEY = -999;

const QuickFilters = ({ onSelect, partnership, accountType }: Props) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const kpi = getFirstValueFromURL(location, "kpi");
  const {
    selectedSmartViewId,
    setSelectedSmartViewId,
    unorderedQuickFilters,
    orderArray,
  } = useContext(MappingFilterContext);
  const [facets, setFacets] = useState({});
  const [assessment, setAssessment] = useState<
    undefined | OverviewFiltersType
  >();
  const { fetchViews, records: smartViews } = useSmartViews(
    accountType,
    partnership.id
  );
  useEffect(() => {
    const smartView = kpi && KPI_TO_SMART_VIEW?.[kpi];
    if (!kpi || selectedSmartViewId || !smartView) {
      return;
    }
    setSelectedSmartViewId(Number(smartView.key));
    onSelect(smartView.presets, smartView.viewType);
    history.replace({
      search: "",
    });
  }, [history, kpi, onSelect, selectedSmartViewId, setSelectedSmartViewId]);

  useEffect(() => {
    fetchViews();
  }, [fetchViews]);

  useEffect(() => {
    dispatch(initialize({ partnership }));
    partnership
      .loadAssessment(
        {
          common_opportunities: [],
          common_customers: [],
          prospects_to_customers: [],
          customers_to_prospects: [],
          open_opportunities_to_customers: [],
        },
        true
      )
      .then((overview) => {
        setAssessment(overview);
      });
  }, [dispatch, partnership]);

  const handleSelectSmartView = (id?: number) => {
    setSelectedSmartViewId(id);
  };

  const combinedFilterList = unorderedQuickFilters.concat(
    smartViews.map((view) => ({
      presets: {
        filter: view.filters,
        sort: view.sort,
      },
      key: view.id,
      label: <T>{view.name}</T>,
      name: view.name,
      hideOnSmallScreens: false,
      viewType:
        view.viewType === SavedFilterType.AccountMapping
          ? AccountMappingResource.matches
          : view.viewType === SavedFilterType.LeadOverlaps
          ? AccountMappingResource.leadMatches
          : AccountMappingResource.shared_accounts,
    }))
  );
  let quickFilters: QuickViewFilter[] = [];
  for (let index of orderArray) {
    const filter = combinedFilterList.find((filter) => filter.key === index);
    if (filter) {
      quickFilters.push(filter);
    }
  }

  const dropdownButton = {
    key: DROPDOWN_KEY,
    presets: {
      filter: [],
      sort: [],
    },
    label: (
      <SmartViewDropdown
        accountType={accountType}
        applyPreset={onSelect}
        defaultFilterList={unorderedQuickFilters}
        facets={facets}
        filterList={quickFilters}
        onSelectSmartView={handleSelectSmartView}
        partnershipId={partnership.id}
        selectedSmartViewId={selectedSmartViewId}
      />
    ),
    name: "Saved views",
    hideOnSmallScreens: false,
    isButton: true,
  };

  const adaptedFilterListForSize = quickViewsForSize(
    quickFilters,
    1000,
    dropdownButton
  );

  useEffect(() => {
    if (!partnership.isDemo) {
      if (accountType === AccountMappingResource.matches) {
        setFacets({
          [commonOpportunities.key]:
            assessment?.common_opportunities?.count_not_private,
          [commonCustomers.key]:
            assessment?.common_customers?.count_not_private,
          [prospectMatchingCustomers.key]:
            assessment?.prospects_to_customers?.count_not_private,
          [customerMatchingProspects.key]:
            assessment?.customers_to_prospects?.count_not_private,
          [allOverlaps.key]: assessment?.nb_matched_accounts_not_private,
          allMatchesWithPrivate: assessment?.nb_matched_accounts,
          ...(partnership.insights !== null && {
            [allNewProspects.key]:
              (partnership.insights.companyNbOfNewProspectsNotPrivate ?? 0) +
              (partnership.insights.partnerNbOfNewProspectsNotPrivate ?? 0),
            allNewLeadsWithPrivate:
              partnership.insights.companyNbOfNewProspects +
              partnership.insights.partnerNbOfNewProspects,
            [whitespaceIShare.key]:
              partnership.insights.partnerNbOfNewProspectsNotPrivate ?? 0,
            [whitespacePartnerShares.key]:
              partnership.insights.companyNbOfNewProspectsNotPrivate ?? 0,
            [openOpportunitiesToCustomers.key]:
              assessment?.open_opportunities_to_customers?.count ?? 0,
          }),
        });
      } else if (accountType === AccountMappingResource.leadMatches) {
        const service = new JSONAPIService("partnerships");
        service
          .fetchRelated<JSONAPIResponse<"partnership_lead_match_insights">>(
            partnership.id,
            "lead-match-insights/"
          )
          .then(
            (
              response: AxiosResponse<
                JSONAPIResponse<"partnership_lead_match_insights">
              >
            ) => {
              const insights = response?.data.data.attributes;
              setFacets((facets) => ({
                ...facets,
                [allLeads.key]: insights?.all,
                [leadsMatchingCustomers.key]: insights?.leads_against_customers,
                [leadsMatchingProspects.key]: insights?.leads_against_prospects,
              }));
            }
          );
      }
    } else {
      setFacets({ ...partnership.facets, ...partnership.newLeadsfacets });
    }
  }, [accountType, partnership.insights, assessment]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleSelect = (preset: Preset, id: number) => {
    const viewType = combinedFilterList.find(
      (filter: QuickViewFilter) => filter.key === id
    )?.viewType;
    if (id === DROPDOWN_KEY || !viewType) {
      return;
    }
    setSelectedSmartViewId(id);
    onSelect(preset, viewType as AccountMappingResource);
  };

  return (
    <BaseQuickViews
      filterList={adaptedFilterListForSize}
      facets={facets}
      onSelect={handleSelect}
      selectedFilterKey={selectedSmartViewId}
    />
  );
};

export default QuickFilters;

export const overlapsQuickViewFilteredFields = [
  "leftStatus",
  "rightStatus",
  "leftActive",
  "rightActive",
];
