import { createReducer } from "@reduxjs/toolkit";

import { markNotificationAsRead, pushNotification } from "./actions";
import { NotificationItem } from "./typing";

const getReaderFunc = (id: string) => (
  notification: NotificationItem
): NotificationItem => ({
  ...notification,
  read: id === notification.id || notification.read,
});

export default createReducer<NotificationItem[]>([], (builder) => {
  // @ts-ignore Type instantiation is excessively deep and possibly infinite
  builder
    .addCase(
      pushNotification,
      (state: NotificationItem[], action: { payload: NotificationItem }) => [
        ...state,
        action.payload,
      ]
    )
    .addCase(
      markNotificationAsRead,
      (state: NotificationItem[], action: { payload: string }) =>
        state.map(getReaderFunc(action.payload))
    );
});
