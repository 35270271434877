import Toggle from "components/ui/Toggle";
import Tooltip from "components/ui/Tooltip";
import { T } from "components/ui/Typography";
import muiTheme from "config/theme";
import { makeStyles } from "makeStyles";
import { ChangeEvent } from "react";
import { defineMessages, FormattedMessage } from "react-intl";

type Props = {
  saving: boolean;
  status: boolean;
  setGetIntroEnabled: SetState<boolean>;
  hasRestrictPartnersCRMSyncPayingFeature: boolean;
};

export const GetIntroToggle = ({
  saving,
  status,
  setGetIntroEnabled,
  hasRestrictPartnersCRMSyncPayingFeature,
}: Props) => {
  const { classes } = useStyles();

  const handleChange = (
    _event: ChangeEvent<HTMLInputElement>,
    checked: boolean
  ) => {
    setGetIntroEnabled(checked);
  };

  return (
    <div className={classes.container}>
      <label className={classes.toggleContainer}>
        <Tooltip
          title={<FormattedMessage {...i18n.tooltip} />}
          hidden={hasRestrictPartnersCRMSyncPayingFeature}
        >
          <span>
            <Toggle
              checked={status}
              handleChange={handleChange}
              disabled={
                saving || (!hasRestrictPartnersCRMSyncPayingFeature && status)
              }
            />
          </span>
        </Tooltip>
        <T>
          <FormattedMessage {...i18n.label} />
        </T>
      </label>
      <T color={muiTheme.palette.alpha500} className={classes.description}>
        <FormattedMessage {...i18n.description} />
      </T>
    </div>
  );
};

const useStyles = makeStyles()((theme) => ({
  container: {
    display: "flex",
    flexDirection: "column",
    padding: "18px 24px",
    borderRadius: 6,
    background: "#ECF1F9",
  },
  toggleContainer: {
    display: "flex",
    alignItems: "baseline",
    gap: 10,
    marginBottom: 2,
  },
  description: {
    marginLeft: 28,
  },
}));

const i18n = defineMessages({
  label: {
    id: "CollaborationTab.GetIntroToggle.label",
    defaultMessage: 'Enable the "Get Intro" button with this Partner',
  },
  description: {
    id: "CollaborationTab.GetIntroToggle.description",
    defaultMessage:
      "Allow your Sales Team to request introductions to this partner from your CRM widget, their weekly digest, or the Reveal platform.",
  },
  tooltip: {
    id: "CollaborationTab.GetIntroToggle.tooltip",
    defaultMessage: "Not available",
  },
});
