import Grid from "@mui/material/Grid";
import { Plus, Search } from "components/icons";
import Button from "components/ui/Button";
import { T } from "components/ui/Typography";
import useUserProfile from "hooks/useUserProfile";
import { makeStyles } from "makeStyles";
import { defineMessages, FormattedMessage, useIntl } from "react-intl";
import { useHistory } from "react-router";

export enum EmptySearchType {
  partner = 0,
  company = 1,
}

type Props = {
  search: string;
  handleOnClick: () => void;
  searchType: EmptySearchType;
  hasGlobalResults?: boolean; // Only defined for Partnerships
  filterName?: string; // Only defined for Partnerships. Exists only when user is filtering results
  selectedTags?: string; // Only defined for Partnerships. Exists only when user is filtering results
};

const EmptyResults = ({
  search,
  handleOnClick,
  searchType,
  hasGlobalResults = false,
  filterName = "",
  selectedTags = "",
}: Props) => {
  const { classes } = useStyles();
  const history = useHistory();
  const intl = useIntl();
  const { profile } = useUserProfile();
  const title = getTitle(searchType, filterName, search, selectedTags);
  const isSearching = Boolean(search || selectedTags);
  const isFiltering = Boolean(filterName);
  const subTitle = getSubTitle(isFiltering, isSearching, hasGlobalResults);
  const btnLabel = getButton(isFiltering, isSearching, hasGlobalResults);

  return (
    <Grid container justifyContent="center">
      <Grid
        container
        item
        direction="column"
        alignItems="center"
        className={classes.root}
      >
        <Grid item>
          <div className={classes.icon}>
            <Search />
          </div>
        </Grid>
        <Grid item>
          <T h4 bold>
            {title}
          </T>
        </Grid>
        <Grid item>
          <T bodySmall>
            <FormattedMessage {...subTitle} values={{ search }} />
          </T>
        </Grid>
        <Grid item className={classes.actions}>
          {profile.canManagePartnerships &&
            !hasGlobalResults &&
            isSearching && (
              <Button
                label={intl.formatMessage(i18n.invite, { search })}
                variant="secondary"
                LeftIcon={Plus}
                onClick={() => history.push(`/invite?fromSearch=${search}`)}
              />
            )}
          <Button label={btnLabel} variant="primary" onClick={handleOnClick} />
        </Grid>
      </Grid>
    </Grid>
  );
};

export default EmptyResults;

/// CSS

const useStyles = makeStyles()((theme) => ({
  root: { padding: 100 },
  icon: {
    borderRadius: 250,
    background: theme.palette.red100,
    color: theme.palette.wine,
    padding: 14,
    margin: theme.spacing(2),
  },
  actions: {
    display: "flex",
    gap: 4,
    marginTop: theme.spacing(3),
  },
}));

/// I18N

const i18n = defineMessages({
  partnertitle: {
    id: "Partners.EmptyResults.partnertitle",
    defaultMessage: `Sorry we couldn’t find any partner
    {isSearching, select, true { matching "{search}"} other {}}
    {hasTags, select, true { with tags "{selectedTags}"} other {}}
    {isFiltering, select, true { within "{filterName}"} other {}}
    `,
  },
  companySearchTitle: {
    id: "Partners.EmptyResults.CompanyTitle",
    defaultMessage: 'Sorry we couldn’t find any company matching "{search}"',
  },
  subtitleSearchEmpty: {
    id: "Partners.EmptyResults.SubTitleEmpty",
    defaultMessage: 'Please try another search or invite "{search}" on Reveal',
  },
  subtitleUpdate: {
    id: "Partners.EmptyResults.SubTitleUpdate",
    defaultMessage:
      'Please update the filter to "All" to see all partner results',
  },
  invite: {
    id: "Partners.EmptyResults.Invite",
    defaultMessage: 'Invite "{search}"',
  },
  clear: {
    id: "Partners.EmptyResults.Clear",
    defaultMessage: "Clear search",
  },
  update: {
    id: "Partners.EmptyResults.Update",
    defaultMessage: `Update Filter to "All"`,
  },
});

/// Utils

const handleUnsupportedValue = (value: never): never => {
  throw new TypeError(`Unsupported value: ${value}`);
};

const getTitle = (
  searchType: EmptySearchType,
  filterName?: string,
  search?: string,
  selectedTags?: string
) => {
  switch (searchType) {
    case EmptySearchType.partner:
      const isSearching = Boolean(search);
      const hasTags = Boolean(selectedTags);
      const isFiltering = Boolean(filterName);
      return (
        <FormattedMessage
          {...i18n.partnertitle}
          values={{
            isSearching,
            search,
            hasTags,
            selectedTags,
            isFiltering,
            filterName,
          }}
        />
      );
    case EmptySearchType.company:
      return (
        <FormattedMessage {...i18n.companySearchTitle} values={{ search }} />
      );
    default:
      handleUnsupportedValue(searchType);
  }
};

const getSubTitle = (
  isFiltering: boolean,
  isSearching: boolean,
  hasGlobalResults: boolean
) => {
  if (isFiltering && ((isSearching && hasGlobalResults) || !isSearching)) {
    return i18n.subtitleUpdate;
  }
  return i18n.subtitleSearchEmpty;
};

const getButton = (
  isFiltering: boolean,
  isSearching: boolean,
  hasGlobalResults: boolean
) => {
  if (isFiltering && ((isSearching && hasGlobalResults) || !isSearching)) {
    return i18n.update;
  }
  return i18n.clear;
};
