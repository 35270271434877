import LockIcon from "@mui/icons-material/Lock";
import Box from "@mui/material/Box";
import { OpenInNew } from "components/icons";
import Tooltip from "components/ui/Tooltip";
import { T } from "components/ui/Typography";
import * as Page from "config/routes/Pages";
import { makeStyles } from "makeStyles";
import { MouseEvent, useContext, useState } from "react";
import { defineMessages, FormattedMessage } from "react-intl";
import { useSelector } from "react-redux";
import { selectOnlyNewUpdatesSinceDate } from "redux/accountMapping/selector";
import { Available360MappingColumns } from "redux/mapping360/defaults";
import ViewContext from "screens/Frontoffice/screens/DataTables/screens/AccountMapping/contexts/ViewContext";
import { AccountMappingStandardFieldName } from "screens/Frontoffice/screens/DataTables/screens/AccountMapping/shared/types";

import { CellProps, getIsNewMatch } from "../../../utils";
import NewChip from "./NewChip";
import { SidebarHover } from "./SidebarHover";

const CompanyNameCell = (props: CellProps) => {
  const { value, row } = props;
  const { url, isPrivate, sourcePage } = getValues(props);
  const [hover, setHover] = useState(false);
  const { classes } = useStyles();
  const { partnerName } = useContext(ViewContext);
  const displaySinceFilterDate = useSelector(selectOnlyNewUpdatesSinceDate);
  const isNewMatch = getIsNewMatch(row, displaySinceFilterDate);

  let privateTooltip;
  if (sourcePage === Page.Enum.AccountMapping) {
    privateTooltip = (
      <FormattedMessage
        {...i18n.privateOrRestrictedAccount}
        values={{ partner: partnerName }}
      />
    );
  } else {
    privateTooltip = <FormattedMessage {...i18n.privateAccount} />;
  }

  const handleCancelEvent = (e: MouseEvent) => {
    e.stopPropagation();
  };

  if (!value) return null;
  return (
    <Box
      className={classes.cellContainer}
      title={value}
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
    >
      <div className={classes.overflowContainer}>
        <T noWrap>{value}</T>
      </div>
      {isPrivate && (
        <Tooltip title={privateTooltip}>
          <LockIcon
            className={classes.lockIcon}
            data-testid="lockIcon"
            fontSize="small"
          />
        </Tooltip>
      )}
      <SidebarHover />
      {isNewMatch && <NewChip tooltip={i18n.newMatch} />}
      {hover && url && sourcePage !== Page.Enum.MyAccounts && (
        <a
          target="_blank"
          href={url}
          rel="noopener noreferrer"
          data-testid="open-in-new"
          className={classes.openInNewTab}
          onClick={handleCancelEvent}
        >
          <OpenInNew className={classes.openInNewTabIcon} />
        </a>
      )}
    </Box>
  );
};

export default CompanyNameCell;

// Helpers

export const isCompanyNameCell = ({ fieldName, row }: CellProps) => {
  return (
    (fieldName === AccountMappingStandardFieldName.leftName ||
      fieldName === Available360MappingColumns.name) &&
    row.type !== "directory_companies"
  );
};

const getValues = ({ fieldName, row }: CellProps) => {
  let urlField,
    privateField,
    sourcePage = null;

  if (fieldName === AccountMappingStandardFieldName.leftName) {
    urlField = "leftUrl";
    privateField = "leftRestricted";
    sourcePage = Page.Enum.AccountMapping;
  } else if (fieldName === Available360MappingColumns.name) {
    urlField = "url";
    privateField = row.type === "nearbound_accounts" ? "isPrivate" : "private";
    sourcePage = Page.Enum.MyAccounts;
  }

  const url = row && urlField ? row[urlField] : undefined;
  const isPrivate = row && privateField && Boolean(row[privateField]);

  return {
    url,
    isPrivate,
    sourcePage,
  };
};

// CSS

const useStyles = makeStyles()((theme) => ({
  cellContainer: {
    display: "flex",
    alignItems: "center",
    width: "100%",
    columnGap: theme.spacing(0.5),
  },
  nameContainer: {
    display: "inline-flex",
    alignItems: "center",
    overflow: "hidden",
    height: 32,
    borderRadius: 6,
    border: `1px solid ${theme.palette.greyscale250}`,
    padding: `0 ${theme.spacing(1)}`,
  },
  overflowContainer: {
    overflow: "hidden",
  },
  lockIcon: {
    width: 14,
  },
  openInNewTab: {
    display: "flex",
    backgroundColor: theme.palette.greyscale200,
    borderRadius: 12,
    padding: 6,
  },
  openInNewTabIcon: {
    color: theme.palette.alpha800,
    fontSize: 12,
  },
}));

// I18N

const i18n = defineMessages({
  privateOrRestrictedAccount: {
    id: "ui.datagrid.cellRenderers.CompanyNameCell.privateOrRestrictedAccount",
    defaultMessage:
      "This account is marked as private or restricted. It’s not shared with {partner}, only you can see it.",
  },
  privateAccount: {
    id: "ui.datagrid.cellRenderers.CompanyNameCell.privateAccount",
    defaultMessage:
      "This account is marked as private. It’s not shared with any partner.",
  },
  newMatch: {
    id: "ui.datagrid.cellRenderers.CompanyNameCell.newMatch",
    defaultMessage: "New match",
  },
});
