import { facebookId } from "../config/constants";

/* eslint-disable no-console */

const configure = (
  f: Window & { fbq?: any; _fbq?: any },
  b: Document,
  e: "script",
  v: string,
  n?: any,
  t?: HTMLScriptElement,
  s?: HTMLElement
) => {
  if (f.fbq) {
    return;
  }
  n = f.fbq = function () {
    n.callMethod ? n.callMethod.apply(n, arguments) : n.queue.push(arguments);
  };
  if (!f._fbq) {
    f._fbq = n;
  }
  n.push = n;
  n.loaded = !0;
  n.version = "2.0";
  n.queue = [];
  t = b.createElement(e) as HTMLScriptElement;
  t.async = !0;
  t.src = v;
  s = b.getElementsByTagName(e)[0];
  if (s.parentNode) {
    s.parentNode.insertBefore(t, s);
  }
  return n;
};

export const start = () => {
  console.groupCollapsed("Starting Facebook tracking");
  if (!facebookId) {
    console.warn("Facebook not configured, skip boostraping");
  } else {
    const fbq = configure(
      window,
      document,
      "script",
      "https://connect.facebook.net/en_US/fbevents.js"
    );
    console.info("Configured");
    fbq("init", facebookId);
    console.info("Initialized");
    fbq("track", "PageView");
    console.info("Traked PageView");
    fbq("track", "ViewContent");
    console.info("Tracked ViewContent");
  }
  console.groupEnd();
};

const moduleDefaultExports = { start };

export default moduleDefaultExports;
