import CrmField from "models/CrmField";
import { PageType } from "models/PageView";
import {
  ColumnConfigType,
  MatchSortOrder,
  orderColumns,
} from "screens/Frontoffice/screens/DataTables/shared/types";

export const PARTNER_PRESENCE_DEFAULT_WIDTH = 400;

// TODO: prepare correct columns when the API is ready
export enum AvailableNearboundAccountsColumns {
  Actions = "actions",
  HasOpenDeal = "hasOpenDeal",
  Name = "name",
  Status = "status",
  OwnerFullName = "ownerFullName",
  OwnerEmail = "ownerEmail",
  CountryIsoCode = "countryIsoCode",
  CountryName = "countryName",
  CustomerOf = "customerOf",
  CustomerOfIds = "customerOfIds",
  Domain = "domain",
  OpenOpportunityFor = "openOpportunityFor",
  OpenOpportunityForIds = "openOpportunityForIds",
  PartnerSignalsCount = "partnerSignalsCount",
  IsStarred = "isStarred",
  // ----------------------------------------------
  // ------ FOR LEAD GEN --------------------------
  CustomerPresenceHighlightsCount = "customerPresenceHighlightsCount",
  Industry = "industry",
  EmployeeRange = "employeeRange",
  CustomerPresenceList = "customerPresenceList",
  NbPartners = "nbPartners",
  CreatedAt = "createdAt",
  DealInfluencerList = "dealInfluencerList",
  NearboundProspectsActions = "nearboundProspectsActions",
  // ----------------------------------------------
}

export const defaultNearboundAccountsColumnKeys = [
  AvailableNearboundAccountsColumns.Name,
  AvailableNearboundAccountsColumns.Status,
  AvailableNearboundAccountsColumns.OwnerFullName,
  AvailableNearboundAccountsColumns.CountryIsoCode,
  AvailableNearboundAccountsColumns.HasOpenDeal,
  AvailableNearboundAccountsColumns.Domain,
  AvailableNearboundAccountsColumns.CustomerOf,
  AvailableNearboundAccountsColumns.OpenOpportunityFor,
  AvailableNearboundAccountsColumns.IsStarred,
];

export const nearboundAccountsColumnsConfig: {
  [key: string]: ColumnConfigType;
} = {
  [AvailableNearboundAccountsColumns.Name]: {
    key: AvailableNearboundAccountsColumns.Name,
    width: 180,
    frozen: true,
    fixedPosition: 1,
    removable: false,
  },
  [AvailableNearboundAccountsColumns.IsStarred]: {
    key: AvailableNearboundAccountsColumns.IsStarred,
    width: 150,
    frozen: false,
  },
  [AvailableNearboundAccountsColumns.Status]: {
    key: AvailableNearboundAccountsColumns.Status,
    width: 140,
    frozen: false,
  },
  [AvailableNearboundAccountsColumns.OwnerFullName]: {
    key: AvailableNearboundAccountsColumns.OwnerFullName,
    width: 170,
    frozen: false,
  },
  [AvailableNearboundAccountsColumns.CountryIsoCode]: {
    key: AvailableNearboundAccountsColumns.CountryIsoCode,
    width: 130,
    frozen: false,
  },
  [AvailableNearboundAccountsColumns.HasOpenDeal]: {
    key: AvailableNearboundAccountsColumns.HasOpenDeal,
    width: 310,
    frozen: false,
  },
  [AvailableNearboundAccountsColumns.Domain]: {
    key: AvailableNearboundAccountsColumns.Domain,
    width: 170,
    frozen: false,
  },
  [AvailableNearboundAccountsColumns.CustomerOf]: {
    key: AvailableNearboundAccountsColumns.CustomerOf,
    width: PARTNER_PRESENCE_DEFAULT_WIDTH,
    frozen: false,
  },
  [AvailableNearboundAccountsColumns.OpenOpportunityFor]: {
    key: AvailableNearboundAccountsColumns.OpenOpportunityFor,
    width: PARTNER_PRESENCE_DEFAULT_WIDTH,
    frozen: false,
  },
  [AvailableNearboundAccountsColumns.PartnerSignalsCount]: {
    key: AvailableNearboundAccountsColumns.PartnerSignalsCount,
    width: 136,
    frozen: false,
  },
  // [AvailableNearboundAccountsColumns.winRateMultiplier]: {
  //   key: AvailableNearboundAccountsColumns.winRateMultiplier,
  //   width: 180,
  //   frozen: false,
  // },
  // [AvailableNearboundAccountsColumns.revenueMultiplier]: {
  //   key: AvailableNearboundAccountsColumns.revenueMultiplier,
  //   width: 180,
  //   frozen: false,
  // },
  // [AvailableNearboundAccountsColumns.dealSizeMultiplier]: {
  //   key: AvailableNearboundAccountsColumns.dealSizeMultiplier,
  //   width: 180,
  //   frozen: false,
  // },
  [AvailableNearboundAccountsColumns.CountryName]: {
    key: AvailableNearboundAccountsColumns.CountryName,
    width: 112,
    frozen: false,
  },
  [AvailableNearboundAccountsColumns.Industry]: {
    key: AvailableNearboundAccountsColumns.Industry,
    width: 192,
    frozen: false,
  },
  [AvailableNearboundAccountsColumns.EmployeeRange]: {
    key: AvailableNearboundAccountsColumns.EmployeeRange,
    width: 128,
    frozen: false,
  },
  [AvailableNearboundAccountsColumns.CustomerPresenceList]: {
    key: AvailableNearboundAccountsColumns.CustomerPresenceList,
    width: 360,
    frozen: false,
  },
  [AvailableNearboundAccountsColumns.CustomerPresenceHighlightsCount]: {
    key: AvailableNearboundAccountsColumns.CustomerPresenceHighlightsCount,
    width: 156,
    frozen: false,
  },
  [AvailableNearboundAccountsColumns.CreatedAt]: {
    key: AvailableNearboundAccountsColumns.CreatedAt,
    width: 156,
    frozen: false,
  },
  [AvailableNearboundAccountsColumns.DealInfluencerList]: {
    key: AvailableNearboundAccountsColumns.DealInfluencerList,
    width: 156,
    frozen: false,
  },
};

export const applyNearboundAccountsColumnOrder = (
  columns: ColumnConfigType[]
) => orderColumns(columns);

export const defaultNearboundAccountsColumnConfig = (
  key: string,
  field: { label: string }
) => ({
  key,
  width: 8 * Math.max(field.label.length, 10),
  frozen: false,
});

export const defaultNearboundAccountsColumns = applyNearboundAccountsColumnOrder(
  defaultNearboundAccountsColumnKeys.map(
    (key) => nearboundAccountsColumnsConfig[key]
  )
);

export const defaultNearboundAccountsSort = [
  {
    fieldname: AvailableNearboundAccountsColumns.HasOpenDeal,
    order: MatchSortOrder.ORDER_DESC,
    fieldtype: CrmField.TYPE_CURRENCY,
  },
  {
    fieldname: AvailableNearboundAccountsColumns.IsStarred,
    order: MatchSortOrder.ORDER_DESC,
    fieldtype: CrmField.TYPE_BOOLEAN,
  },
  // TODO: add the following sort when the API is ready
  // {
  //   fieldname: "nbPartners",
  //   order: MatchSortOrder.ORDER_DESC,
  //   fieldtype: CrmField.TYPE_INTEGER,
  // },
];

// export const Mapping360WithGrowthKPIsSort = [
//   TODO: add the following sort when the API is ready
//   {
//     fieldname: "revenueMultiplier",
//     order: MatchSortOrder.ORDER_DESC,
//     fieldtype: CrmField.TYPE_UNKNOWN,
//   },
// ].concat(defaultNearboundAccountsSort);

export const nearboundAccountsWithGrowthKPIsSort = defaultNearboundAccountsSort;

export const nearboundAccountsPageTypes = [
  PageType.mapping360InfluenceNearboundAccounts,
  PageType.mapping360NonGoalBasedNearboundAccounts,
  PageType.mapping360OverlapsNearboundAccounts,
  PageType.mapping360Expand,
  PageType.mapping360CoSellNearboundAccounts,
  PageType.mapping360SourcingNearboundAccounts,
  PageType.mapping360UpsellNearboundAccounts,
];
