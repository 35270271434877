import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { castDraft } from "immer";
import _ from "lodash";
import Record from "models/Record";
import User from "models/User";
import { combineReducers } from "redux";

import { setCompany, setLoggedIn, setProfile } from "./actions";
import {
  disableMFA,
  enableMFA,
  fetchProfile,
  fetchProfileInBackground,
  login as loginThunk,
  logout,
  refreshToken,
  updateProfile,
} from "./thunks";
import { LoginState, ProfileState } from "./typing";

const initialProfile: ProfileState = {
  data: null,
  requested: false,
};

const setUserFromAction = (
  state: ProfileState,
  action: PayloadAction<User>
) => {
  state.data = action.payload;
  state.requested = false;
};

const setCompanyFromAction = (
  state: ProfileState,
  action: PayloadAction<Record>
) => {
  if (state.data !== null) {
    state.data.company = action.payload;
  }
  return _.cloneDeep(state);
};

const setAnonymousUser = (state: ProfileState) => {
  state.data = new User();
};

const profile = createSlice({
  name: "user/profile",
  initialState: initialProfile,
  reducers: {},
  extraReducers: (builder) =>
    // @ts-ignore error TS2589: Type instantiation is excessively deep
    builder
      .addCase(loginThunk.pending, (draft) => {
        draft.requested = true;
      })
      .addCase(loginThunk.fulfilled, setUserFromAction)
      .addCase(loginThunk.rejected, (draft) => {
        draft.requested = false;
        draft.data = castDraft(new User());
      })
      .addCase(logout.fulfilled, setAnonymousUser)
      .addCase(refreshToken.rejected, setAnonymousUser)
      .addCase(refreshToken.fulfilled, setUserFromAction)
      /**
       * Profile
       */
      .addCase(fetchProfile.pending, (state) => {
        state.requested = true;
      })
      .addCase(fetchProfile.fulfilled, setUserFromAction)
      .addCase(fetchProfileInBackground.fulfilled, setUserFromAction)
      .addCase(updateProfile.fulfilled, setUserFromAction)
      .addCase(setProfile, setUserFromAction)
      /**
       * Company
       */
      .addCase(setCompany, setCompanyFromAction)
      /**
       * MFA
       */
      .addCase(disableMFA.fulfilled, setUserFromAction)
      .addCase(enableMFA.fulfilled, setUserFromAction),
});

const login = createSlice({
  name: "user/login",
  initialState: {
    loggedIn: false,
    pending: false,
  } as LoginState,
  reducers: {},
  extraReducers: (builder) =>
    builder
      .addCase(loginThunk.pending, (draft) => {
        draft.pending = true;
      })
      .addCase(loginThunk.rejected, (draft) => {
        draft.pending = false;
        draft.loggedIn = false;
      })
      .addCase(loginThunk.fulfilled, (draft) => {
        draft.pending = false;
        draft.loggedIn = true;
      })
      .addCase(logout.fulfilled, (draft) => {
        draft.loggedIn = false;
        draft.pending = false;
      })
      .addCase(refreshToken.pending, (draft) => {
        draft.pending = true;
      })
      .addCase(refreshToken.rejected, (draft) => {
        draft.pending = false;
        draft.loggedIn = false;
      })
      .addCase(refreshToken.fulfilled, (draft) => {
        draft.pending = false;
        draft.loggedIn = true;
      })
      .addCase(setLoggedIn, (draft, action) => {
        draft.loggedIn = action.payload;
      }),
});

export default combineReducers({
  profile: profile.reducer,
  login: login.reducer,
});
