import { makeStyles } from "makeStyles";
import { ReactNode } from "react";

type Props = {
  children: ReactNode;
};

export const BaseSidePanel = ({ children }: Props) => {
  const { classes } = useStyles();

  return <div className={classes.container}>{children}</div>;
};

const useStyles = makeStyles()((theme) => ({
  container: {
    margin: theme.spacing(4),
  },
}));
export const sharedStyles = makeStyles()((theme) => ({
  divider: {
    background: theme.palette.greyscale850,
    margin: `${theme.spacing(3.5)} 0`,
  },
  icon: {
    color: theme.palette.ivory,
    height: 18,
    width: 12,
  },
  link: {
    color: theme.palette.ivory,
    textDecoration: "underline",
    "&:hover": {
      cursor: "pointer",
      textDecorationColor: theme.palette.primary.main,
    },
  },
  linkText: {
    color: theme.palette.ivory,
    fontWeight: 600,
  },
  lightText: {
    color: theme.palette.taupe,
  },
  maskedImageContent: {
    maxHeight: "inherit",
    maxWidth: "inherit",
  },
  maskImage: {
    maxWidth: 260,
    position: "relative",
    display: "flex",
    marginTop: theme.spacing(6),
    "&::after": {
      content: "''",
      position: "absolute",
      left: 0,
      top: 0,
      width: "100%",
      height: "100%",
      background: `linear-gradient(0deg, ${theme.palette.midnight} 0%, rgba(10, 21, 27, 0.25) 100%)`,
    },
  },
  row: {
    alignItems: "flexStart",
    display: "flex",
    gap: 10,
    marginBottom: 4,
  },
  marginBottom: {
    marginBottom: theme.spacing(2),
  },
  smallTitle: {
    color: theme.palette.ivory,
    fontWeight: 700,
  },
  title: {
    color: theme.palette.ivory,
    fontWeight: 600,
    marginBottom: theme.spacing(1),
  },
  video: {
    borderRadius: 6,
    height: 1800, // setting it to be very big to make the default resolution of the video the highest
    width: "100%",
  },
  videoContainer: {
    "& > iframe": {
      height: "unset !important", // and reverting the height back to normal
    },
  },
}));
