import { createSvgIcon } from "@mui/material/utils";

import { SvgIcon } from "./types";

const displayName = "Sources";

const Sources: SvgIcon = createSvgIcon(
  // Using transform to enlarge from provided viewbox="0 0 16 16"
  // to default "0 0 24 24" from `createSvgIcon` -> `SvgIcon`.
  <g fill="none" transform="matrix(1.5,0,0,1.5,0,0)">
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M3.25818 4.10065C3.04057 4.28774 3 4.42441 3 4.5C3 4.57559 3.04057 4.71226 3.25818 4.89935C3.47417 5.08504 3.81596 5.27493 4.28178 5.44432C5.20932 5.7816 6.52384 6 8 6C9.47616 6 10.7907 5.7816 11.7182 5.44432C12.184 5.27493 12.5258 5.08504 12.7418 4.89935C12.9594 4.71226 13 4.57559 13 4.5C13 4.42441 12.9594 4.28774 12.7418 4.10065C12.5258 3.91496 12.184 3.72507 11.7182 3.55568C10.7907 3.2184 9.47616 3 8 3C6.52384 3 5.20932 3.2184 4.28178 3.55568C3.81596 3.72507 3.47417 3.91496 3.25818 4.10065ZM2.60626 3.34237C2.26724 3.63383 2 4.02331 2 4.5V8V11.5C2 11.9767 2.26724 12.3662 2.60626 12.6576C2.9469 12.9505 3.41056 13.1916 3.94004 13.3841C5.00311 13.7707 6.43859 14 8 14C9.56141 14 10.9969 13.7707 12.06 13.3841C12.5894 13.1916 13.0531 12.9505 13.3937 12.6576C13.7328 12.3662 14 11.9767 14 11.5V8V4.5C14 4.02331 13.7328 3.63383 13.3937 3.34237C13.0531 3.04951 12.5894 2.80843 12.06 2.61589C10.9969 2.22932 9.56141 2 8 2C6.43859 2 5.00311 2.22932 3.94004 2.61589C3.41056 2.80843 2.9469 3.04951 2.60626 3.34237ZM13 8V5.94214C12.7256 6.11092 12.4069 6.25794 12.06 6.38411C10.9969 6.77068 9.56141 7 8 7C6.43859 7 5.00311 6.77068 3.94004 6.38411C3.59306 6.25794 3.27435 6.11092 3 5.94214V8C3 8.07559 3.04057 8.21226 3.25818 8.39935C3.47417 8.58504 3.81596 8.77493 4.28178 8.94432C5.20932 9.2816 6.52384 9.5 8 9.5C9.47616 9.5 10.7907 9.2816 11.7182 8.94432C12.184 8.77493 12.5258 8.58504 12.7418 8.39935C12.9594 8.21226 13 8.07559 13 8ZM3 9.44214C3.27435 9.61092 3.59306 9.75794 3.94004 9.88411C5.00311 10.2707 6.43859 10.5 8 10.5C9.56141 10.5 10.9969 10.2707 12.06 9.88411C12.4069 9.75794 12.7256 9.61092 13 9.44214V11.5C13 11.5756 12.9594 11.7123 12.7418 11.8993C12.5258 12.085 12.184 12.2749 11.7182 12.4443C10.7907 12.7816 9.47616 13 8 13C6.52384 13 5.20932 12.7816 4.28178 12.4443C3.81596 12.2749 3.47417 12.085 3.25818 11.8993C3.04057 11.7123 3 11.5756 3 11.5V9.44214Z"
      fill="currentColor"
    />
  </g>,
  displayName
);

Sources.displayName = displayName;
export default Sources;
