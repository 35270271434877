import { Box, Grid } from "@mui/material";
import CompanyAvatar from "components/ui/avatars/CompanyAvatar";
import { T } from "components/ui/Typography";
import { DataShare } from "models/Partnership";
import { defineMessages, FormattedMessage } from "react-intl";
import { DialogWithSidePanel } from "screens/Frontoffice/shared/components/DialogWithSidePanel";
import { InviteMessagePreview } from "screens/Frontoffice/shared/components/InviteForm/components/InviteMessagePreview";
import {
  useInviteModal,
  useStyles,
} from "screens/Frontoffice/shared/components/InviteForm/hooks/useInviteModal";

import { InvitationReviewModalSidePanel } from "./InvitationReviewModalSidePanel";
import { InvitationSuccess } from "./InvitationSuccess";

type Props = {
  partnershipId: string;
};

export const InvitationReviewModalOnboarding = ({ partnershipId }: Props) => {
  const {
    closeButton,
    handleClose,
    invitationSharingSettingsContent,
    inviteButton,
    isAccepted,
    partnership,
    selectedCompany,
    dataSharingStatus,
  } = useInviteModal({ partnershipId });
  const { classes, cx } = useStyles();

  const invitedBy =
    !!partnership?.invites?.length &&
    partnership.invites[partnership.invites.length - 1]?.createdByUser
      ?.fullName;

  const content = (
    <Grid container className={classes.container}>
      {closeButton}
      <Grid item xs={12}>
        <Grid container className={cx(classes.container, classes.paddingLarge)}>
          <Grid item xs={12} className={classes.titleContainer}>
            <T h2 bold>
              <span className={classes.noWrap}>
                <FormattedMessage
                  {...i18n.invitedBy}
                  values={{ name: invitedBy ?? "unknown" }}
                />
              </span>
              &nbsp;
              {selectedCompany && (
                <span className={classes.noWrap}>
                  <CompanyAvatar
                    classes={{ avatar: classes.avatar }}
                    companyName={selectedCompany.name}
                    companyDomain={selectedCompany.domain}
                    src={selectedCompany.logo}
                  />
                  &nbsp;{selectedCompany.name}
                </span>
              )}
            </T>
          </Grid>
          {selectedCompany && (
            <Grid item xs={12}>
              <Box>
                <InviteMessagePreview partnership={partnership} />
              </Box>
            </Grid>
          )}
          {invitationSharingSettingsContent}
          <Grid item xs={12}>
            {inviteButton}
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );

  return (
    <>
      <DialogWithSidePanel
        sidePanel={<InvitationReviewModalSidePanel />}
        isOpen={true}
        onClose={handleClose}
      >
        {isAccepted ? (
          <InvitationSuccess
            companyName={selectedCompany?.name ?? ""}
            isSharing={
              !!dataSharingStatus && dataSharingStatus !== DataShare.Nothing
            }
            onClose={handleClose}
            partnershipId={partnershipId}
          />
        ) : (
          content
        )}
      </DialogWithSidePanel>
    </>
  );
};

const i18n = defineMessages({
  invitedBy: {
    id: "InvitationReviewModalOnboarding.invitedBy",
    defaultMessage: "{name} invited you to connect with",
  },
});
