import { createContext } from "react";
import { OnboardingStep } from "services/onboarding";
import { partner } from "styles/colors";

import config from ".";
import { CrmProviderType } from "./constants";

export type RevealConfig = {
  onboarding: OnboardingStep[];
  enableTracking: boolean;
  termsOfUseLink?: string;
  privacyPolicyLink?: string;
  templateFilePath?: string;
  templateNdaPath?: string;
  templateGsheetLink?: string;
  apiKeyInfo?: string;
  appName?: string;
  homeLink?: string;
  reveal4BusinessLink?: string;
  uploadACsvLink?: string;
  connectCrmLink?: string;
  legalOverviewLink?: string;
  defineDataSourceStatusRulesLink?: string;
  connectGoogleSheetLink?: string;
  partnerAnalyticsV0Link?: string;
  partnerAnalyticsDashboardLink?: string;
  mainColor: string;
  crmProviders?: CrmProviderType;
  integrationLearnMoreCRM?: string;
  integrationLearnMoreSlack?: string;
  integrationLearnMoreRevealAPI?: string;
  integrationLearnMorePartnerStack?: string;
};

export const ConfigContext = createContext<RevealConfig>({
  onboarding: [],
  enableTracking: true,
  mainColor: partner,
});

const ConfigProvider = ({ children }: { children: JSX.Element }) => (
  <ConfigContext.Provider value={config()}>{children}</ConfigContext.Provider>
);

export default ConfigProvider;
