import CircularProgress from "@mui/material/CircularProgress";

import Tile from "./Tile";

const LoaderTile = (props: $TSFixMe) => {
  return (
    <Tile centered inactive {...props}>
      <CircularProgress />
    </Tile>
  );
};

export default LoaderTile;
