import { Box } from "@mui/material";
import { T } from "components/ui/Typography";
import { makeStyles } from "makeStyles";
import { ReactNode } from "react";

type Props = {
  children: ReactNode;
};

export const BaseSidePanel = ({ children }: Props) => {
  const { classes } = useStyles();
  return (
    <div className={classes.container}>
      <>
        <Box mb={2}>
          <T h3 bold>
            Need help?
          </T>
        </Box>
        {children}
      </>
    </div>
  );
};

const useStyles = makeStyles()((theme) => ({
  container: {
    display: "flex",
    flexDirection: "column",
    gap: 8,
    margin: theme.spacing(4),
  },
}));

export const sharedCrmBaseSidePanelStyles = makeStyles()((theme) => ({
  iframeVideo: {
    display: "block",
    borderStyle: "none",
    borderRadius: "6px 6px 0 0",
  },
  underline: {
    cursor: "pointer",
    "&:hover": {
      textDecoration: "underline",
    },
  },
  helpBox: {
    background: theme.palette.greyLight100,
    borderRadius: 6,
    padding: theme.spacing(2),
  },
  noBorderRadiusTop: {
    borderRadius: "0 0 6px 6px",
  },
  helpBoxSubtitle: {
    marginTop: theme.spacing(0.5),
  },
  helpBoxLinkContainer: {
    marginTop: theme.spacing(2),
  },
  helpBoxLink: {
    display: "flex",
    alignItems: "center",
    gap: 6,
    textDecoration: "none",
    marginTop: theme.spacing(0.5),
    color: theme.palette.text.primary,
    cursor: "pointer",
    "& svg": {
      verticalAlign: "top",
      fontSize: 12,
    },
    "& a": {
      color: theme.palette.text.primary,
    },
    "& a:hover": {
      color: "inherit",
    },
  },
  textList: {
    listStyle: "none",
    marginTop: 6,
  },
  textListItem: {
    paddingLeft: 16,
    marginBottom: 6,
    "& svg": {
      verticalAlign: "middle",
      fontSize: 12,
      marginRight: 4,
    },
  },
}));
