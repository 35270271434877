import { CircularProgress } from "@mui/material";
import { isFulfilled } from "@reduxjs/toolkit";
import BaseLoginPage from "components/ui/BaseLoginPage";
import getFirstValueFromURL from "helpers/getFirstValueFromURL";
import { track } from "helpers/segment";
import _ from "lodash";
import { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RouteComponentProps, useLocation } from "react-router-dom";
import { rawPost } from "redux/api/thunks";
import { selectLoggedIn } from "redux/user/selectors";
import useGetIntroSlackChannels from "screens/Frontoffice/shared/hooks/useGetIntroSlackChannels";
import { JSONAPIListResponse } from "services/types";
import { PipelineEvent } from "tracking";

import { ConfirmIntroRequestContent } from "../../../shared/components/ConfirmIntroRequestContent";
import { ResponseData } from "../types";
import { ResponseContent } from "./ResponseContent";

export enum TemplateSource {
  OpenOpportunities = "opportunities",
  Prospects = "prospects",
  JointCustomers = "joint-customers",
  Sales = "sales",
  SalesRevamped = "sales-revamped",
}

const validSources = [
  TemplateSource.OpenOpportunities,
  TemplateSource.Prospects,
  TemplateSource.JointCustomers,
  TemplateSource.Sales,
  TemplateSource.SalesRevamped,
];

type Params = {
  templateSource: TemplateSource;
};
type Props = RouteComponentProps<Params>;

export const IntroRequestPage = ({ match }: Props) => {
  const dispatch = useDispatch();
  const location = useLocation();
  const token = getFirstValueFromURL(location, "token") ?? null;
  const { loggedIn } = useSelector(selectLoggedIn);
  const templateSource = match.params.templateSource;
  const isValidSource = validSources.includes(templateSource);

  const [requested, setRequested] = useState<boolean>(false);
  const [data, setData] = useState<ResponseData>({});
  const [isSuccess, setIsSuccess] = useState<boolean | null>(null);
  const [isInvalid, setInvalid] = useState<boolean>(
    token === null || !isValidSource
  );
  const [partnershipId, setPartnershipId] = useState<number | null>(null);

  const {
    loading: loadingChannels,
    internalChannelName,
    externalChannelName,
  } = useGetIntroSlackChannels(partnershipId, loggedIn);

  const createPartnerConnection = useCallback(async () => {
    if (requested || !token) return;
    setRequested(true);

    const result = await dispatch(
      rawPost({
        type: "introduction_requests",
        path: `weekly-digest/?token=${token}`,
        payload: {},
      })
    );
    if (isFulfilled(result)) {
      const response = result.payload as JSONAPIListResponse;
      const success = Boolean(_.get(response, "id", null));
      const partnershipId = _.get(response, "partnership_id", null);
      const _data = {
        companyName: _.get(response, "company_name", ""),
        companyLogo: _.get(response, "company_icon_url", ""),
        accountName: _.get(response, "requested_account.name", ""),
        partnerName: _.get(response, "partner_company.name", ""),
        partnerManagerName: _.get(response, "partnership_owner.name", ""),
      };
      if (partnershipId) {
        setPartnershipId(partnershipId);
      }
      setData(_data);
      setIsSuccess(success);
      track(PipelineEvent.addToPipeline, {
        from: FROM_TRACKING[templateSource],
        bulk: false,
        number_created: 1,
      });
    } else {
      setInvalid(true);
      setIsSuccess(false);
    }
  }, [requested, token, dispatch, templateSource]);

  useEffect(() => {
    if (isInvalid) {
      setIsSuccess(false);
    }
  }, [isInvalid]);

  useEffect(() => {
    if (
      !loadingChannels &&
      Boolean(internalChannelName || externalChannelName)
    ) {
      const newData = { ...data };
      if (internalChannelName !== null) {
        newData.internalChannelName = internalChannelName;
      }
      if (externalChannelName !== null) {
        newData.externalChannelName = externalChannelName;
      }
      setData(newData);
    }
  }, [loadingChannels, internalChannelName, externalChannelName]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    track(PipelineEvent.clickedFromWeeklyDigest, {
      from: FROM_TRACKING[templateSource],
      bulk: false,
      number_created: 1,
    });
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <BaseLoginPage hidePaper>
      {isSuccess === null ? (
        <ConfirmIntroRequestContent
          handleConfirm={createPartnerConnection}
          isLoading={requested}
        />
      ) : loadingChannels ? (
        <CircularProgress />
      ) : (
        <ResponseContent
          isSuccess={isSuccess}
          isInvalid={isInvalid}
          data={data}
        />
      )}
    </BaseLoginPage>
  );
};

// Helpers

const FROM_TRACKING = {
  [TemplateSource.JointCustomers]: "weekly digest - joint customers",
  [TemplateSource.OpenOpportunities]: "weekly digest - opportunities",
  [TemplateSource.Prospects]: "weekly digest - prospects",
  [TemplateSource.Sales]: "weekly digest - sales",
  [TemplateSource.SalesRevamped]: "weekly digest - sales revamped",
};
