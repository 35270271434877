import SplashScreen from "components/ui/SplashScreen";
import useOktaLogin from "hooks/useOktaLogin";
import { useEffect } from "react";
import { Redirect, RouteComponentProps } from "react-router";

type Params = {
  idpId: string;
};

type Props = {};

function getIdpId(params: Params) {
  const idpId = params.idpId;
  if (idpId === "") {
    return null;
  }
  return idpId;
}

const OktaIdpInitiator = ({ match }: Props & RouteComponentProps<Params>) => {
  const login = useOktaLogin();
  const idpId = getIdpId(match.params);

  useEffect(() => {
    if (idpId !== null) {
      login(idpId, "/");
    }
  }, [idpId, login]);

  if (idpId === null) {
    return <Redirect to="/login" />;
  }
  return <SplashScreen />;
};

export default OktaIdpInitiator;
