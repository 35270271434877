import { ChevronDown, ChevronTop, Plus } from "components/icons";
import Button from "components/ui/Button";
import Dropdown from "components/ui/Dropdown/components/Dropdown";
import CrmField from "models/CrmField";
import SWQLOperator from "models/SWQLOperator";
import { useState } from "react";
import { defineMessages, useIntl } from "react-intl";

import { IDropdownOption } from "../Dropdown/components/types";

type Props = {
  operator?: string;
  fieldType: number;
  hasOptions?: boolean;
  handleChange: $TSFixMeFunction;
};

type Option = {
  value: string;
  label: JSX.Element;
};

const OperatorSelectorDropdown = ({
  operator,
  fieldType,
  hasOptions = false,
  handleChange,
}: Props) => {
  const intl = useIntl();

  const options: Option[] = fieldType
    ? CrmField.getOperatorOptions(fieldType, hasOptions)
    : [];

  const [anchorEl, setAnchorEl] = useState<Element | null>(null);
  const [openDropdown, setOpenDropdown] = useState(false);

  const selectedOperator = options.find((option) => option.value === operator);

  const handleOpenDropdown = (event: React.SyntheticEvent) => {
    setAnchorEl(event.currentTarget);
    setOpenDropdown(!openDropdown);
  };

  const handleCloseDropdown = () => {
    setAnchorEl(null);
    setOpenDropdown(false);
  };

  const mapOptionsToDropdown = (options: Option[]): IDropdownOption[] => {
    return options.map((o) => ({
      id: o.value,
      value: o.value,
      name: SWQLOperator.TYPE_LABELS[o.value]?.props?.defaultMessage as string,
    }));
  };

  return (
    <>
      <Button
        LeftIcon={!selectedOperator ? Plus : undefined}
        RightIcon={
          selectedOperator
            ? openDropdown
              ? ChevronTop
              : ChevronDown
            : undefined
        }
        variant={selectedOperator ? "tertiary" : "primary"}
        label={selectedOperator?.label ?? intl.formatMessage(i18n.placeholder)}
        onClick={handleOpenDropdown}
        size="small"
        data-testid="operator-selector"
      />
      <Dropdown
        value={[selectedOperator?.value ?? ""]}
        onChange={(value) => {
          const selectedValue = options?.find(
            (o) => value && o.value === value[0]
          );
          selectedValue && handleChange(selectedValue);
          handleCloseDropdown();
        }}
        options={mapOptionsToDropdown(options)}
        open={openDropdown}
        onClose={handleCloseDropdown}
        anchorEl={anchorEl}
        withSearch
        size="small"
      />
    </>
  );
};

export default OperatorSelectorDropdown;

const i18n = defineMessages({
  placeholder: {
    id: "SWQL.OperatorSelector.placeholder",
    defaultMessage: "…",
  },
});
