import { Box } from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import Grid from "@mui/material/Grid";
import PageForbidden from "components/ui/PageForbidden";
import ToggleBtnGroup from "components/ui/ToggleBtnGroup";
import { T } from "components/ui/Typography";
import { salesNotificationsProviders } from "config/constants";
import { track } from "helpers/segment";
import useAllRecords from "hooks/useAllRecords";
import useHasPayingFeature from "hooks/useHasPayingFeature";
import useUserProfile from "hooks/useUserProfile";
import { makeStyles } from "makeStyles";
import { PayingFeature } from "models/CompanyPayingFeatureSubscription";
import { ComponentType, ReactNode, useCallback } from "react";
import { defineMessages, FormattedMessage } from "react-intl";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router";
import {
  openWidget,
  toggleFree,
  togglePower,
  togglePro,
} from "redux/premiumPlanWidget/actions";
import { PremiumPlanEvent } from "tracking";

export type AccountSettingsTab =
  | "team"
  | "directory"
  | "company"
  | "notifications"
  | "get-intro-templates"
  | "collaborate";

type Props = {
  children: ReactNode;
  actions?: ReactNode;
  tab: AccountSettingsTab;
  isChildrenTakesAllWidth?: boolean;
};

type StylesProps = {
  isChildrenTakesAllWidth?: boolean;
};

const AccountSettings: ComponentType<Props> = ({
  children,
  actions,
  tab,
  isChildrenTakesAllWidth = false,
}) => {
  const { classes } = useStyles({ isChildrenTakesAllWidth });
  const history = useHistory();
  const { profile } = useUserProfile();
  const dispatch = useDispatch();

  const onNotificationsTab = tab === "notifications";
  const hasWeeklyDigestToSalesPayingFeature = useHasPayingFeature(
    PayingFeature.WeeklyDigestToSales,
    profile
  );
  const hasIntroductionRequestFormsPayingFeature = useHasPayingFeature(
    PayingFeature.IntroductionRequestForms,
    profile
  );

  const { records: sources, loading } = useAllRecords("integrations");
  const crmSources = sources.filter((integration) =>
    salesNotificationsProviders.includes(integration.provider)
  );
  // For now, only Salesforce is used to set up Notifications tab (so the list just need to be > 0)
  const hasSalesforceEnabled = Boolean(
    crmSources.length > 0 && !crmSources[0].disabledAt
  );

  const goToTab = (value: AccountSettingsTab) => {
    let path = "/settings/" + value;
    if (value === "notifications") {
      path = `${path}/${crmSources[0].id}`;
    }
    history.push(path);
  };

  const openPremiumWidget = useCallback(() => {
    dispatch(toggleFree(false));
    dispatch(togglePro(false));
    dispatch(togglePower(false));
    dispatch(openWidget(true));
    track(PremiumPlanEvent.explorePlan, {
      from: "notify on Account Settings/Notifications tooltip",
    });
  }, [dispatch]);

  if (onNotificationsTab && !loading && !hasSalesforceEnabled) {
    return <PageForbidden />;
  }

  const isTeamDisabled = !profile.hasPermissions(
    ["company.manage_roles"],
    profile.company
  );
  const isCompanyDisabled = !profile.hasPermissions(
    ["company.update"],
    profile.company
  );
  const isDirectoryDisabled = !profile.hasPermissions(
    ["company.update"],
    profile.company
  );
  const isNotificationHidden = !profile.hasPermissions(
    ["company.manage_admins"],
    profile.company
  );
  const isGetIntroTemplatesHidden =
    !profile.isCompanyAdmin() || !hasIntroductionRequestFormsPayingFeature;

  const isNotificationDisabled =
    profile.hasPermissions(["company.manage_admins"], profile.company) &&
    (!hasWeeklyDigestToSalesPayingFeature || !hasSalesforceEnabled);

  return (
    <Grid
      container
      className={classes.root}
      direction="column"
      alignItems="stretch"
    >
      <Box className={classes.headerContainer}>
        <Grid item className={classes.header}>
          <T h2 bold>
            <FormattedMessage {...i18n.title} />
          </T>
        </Grid>
        <Grid item className={classes.header}>
          <div className={classes.headerBlock}>
            <ToggleBtnGroup
              items={[
                {
                  value: "team",
                  label: <FormattedMessage {...i18n.team} />,
                  disabled: isTeamDisabled,
                  tooltip: isTeamDisabled ? (
                    <FormattedMessage {...i18n.missingPermissions} />
                  ) : (
                    ""
                  ),
                },
                {
                  value: "company",
                  label: <FormattedMessage {...i18n.company} />,
                  disabled: isCompanyDisabled,
                  tooltip: isCompanyDisabled ? (
                    <FormattedMessage {...i18n.missingPermissions} />
                  ) : (
                    ""
                  ),
                },
                {
                  value: "directory",
                  label: <FormattedMessage {...i18n.directory} />,
                  disabled: isDirectoryDisabled,
                  tooltip: isDirectoryDisabled ? (
                    <FormattedMessage {...i18n.missingPermissions} />
                  ) : (
                    ""
                  ),
                },
                {
                  value: "notifications",
                  label: (
                    <span className={classes.NotificationsLabelContainer}>
                      <FormattedMessage {...i18n.notifications} />
                      {loading && !onNotificationsTab && (
                        <CircularProgress size={10} />
                      )}
                    </span>
                  ),
                  disabled: isNotificationDisabled,
                  hidden: isNotificationHidden,
                  tooltip:
                    loading || onNotificationsTab ? (
                      ""
                    ) : isNotificationDisabled && hasSalesforceEnabled ? (
                      <>
                        <FormattedMessage {...i18n.missingFeature} />
                        &nbsp;
                        <span
                          className={classes.tooltipLink}
                          onClick={() => openPremiumWidget()}
                        >
                          <FormattedMessage {...i18n.moreInfo} />
                        </span>
                      </>
                    ) : isNotificationDisabled ? (
                      <FormattedMessage {...i18n.comingSoon} />
                    ) : (
                      ""
                    ),
                },
                {
                  value: "get-intro-templates",
                  label: <FormattedMessage {...i18n.getIntroTemplate} />,
                  hidden: isGetIntroTemplatesHidden,
                },
                {
                  value: "collaborate",
                  label: <FormattedMessage {...i18n.collaborate} />,
                },
              ]}
              setValue={goToTab}
              value={tab}
            />
          </div>
          <div className={classes.headerBlock}>{actions}</div>
        </Grid>
      </Box>
      {children}
    </Grid>
  );
};

export default AccountSettings;

/// CSS

const useStyles = makeStyles<StylesProps>()(
  (theme, { isChildrenTakesAllWidth }) => ({
    root: {
      padding: isChildrenTakesAllWidth
        ? `${theme.spacing(2)} 0`
        : theme.spacing(2),
      "& > *": {
        width: "100%",
      },
    },
    headerContainer: {
      padding: isChildrenTakesAllWidth ? `0 ${theme.spacing(4)}` : 0,
    },
    header: {
      display: "flex",
      justifyContent: "space-between",
      [theme.breakpoints.down("md")]: {
        flexDirection: "column",
        gap: theme.spacing(2),
      },
      marginBottom: theme.spacing(2.5),
    },
    headerBlock: {
      display: "flex",
      gap: 8,
    },
    tooltipLink: {
      cursor: "pointer",
      textDecoration: "underline",
    },
    NotificationsLabelContainer: {
      display: "flex",
      alignItems: "center",
      columnGap: theme.spacing(1),
    },
  })
);

/// I18N

const i18n = defineMessages({
  collaborate: {
    id: "AccountSettings.Collaborate",
    defaultMessage: "Collaborate",
  },
  title: {
    id: "AccountSettings.Title",
    defaultMessage: "Account Settings",
  },
  // Tabs
  company: {
    id: "AccountSettings.Company",
    defaultMessage: "Company",
  },
  team: {
    id: "AccountSettings.Team",
    defaultMessage: "Teammates",
  },
  directory: {
    id: "AccountSettings.Directory",
    defaultMessage: "Directory",
  },
  notifications: {
    id: "AccountSettings.Notifications",
    defaultMessage: "Notifications",
  },
  getIntroTemplate: {
    id: "AccountSettings.getIntroTemplate",
    defaultMessage: "Get Intro template",
  },
  missingPermissions: {
    id: "AccountSettings.missingPermissions",
    defaultMessage: "You do not have the required permissions.",
  },
  missingFeature: {
    id: "AccountSettings.missingFeature",
    defaultMessage:
      "Send notifications to your sales team about changes in their accounts to help them drive revenue - even if they don’t have a Reveal account. Sample notifications include: accounts that have become customer of a partner, accounts that have a new opportunity with a partner or accounts that are now a prospect of a partner. Contact Reveal to learn more.",
  },
  moreInfo: {
    id: "AccountSettings.moreInfo",
    defaultMessage: "More info.",
  },
  comingSoon: {
    id: "AccountSettings.comingSoon",
    defaultMessage: "Coming soon",
  },
});
