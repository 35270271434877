import User from "models/User";
import { useSelector } from "react-redux";
import { RevealStore } from "redux/typing";
import { createSelector } from "reselect";

const selectProfile = createSelector(
  (state: RevealStore) => state.user,
  (userState) => {
    if (userState.login.loggedIn) {
      return {
        profile: userState.profile.data ?? new User(),
        loading: userState.profile.requested,
      };
    }
    return {
      profile: new User(),
      loading: false,
    };
  }
);

const useUserProfile = () => {
  return useSelector(selectProfile);
};

export default useUserProfile;
