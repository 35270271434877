import { createAsyncThunk, isRejected } from "@reduxjs/toolkit";
import { JSONAPIResource } from "models/types";
import { indexAll } from "redux/api/thunks";
import { RevealStore } from "redux/typing";
import { FilterValue } from "services/types";

import { fields, included } from "./constants";
import { OnboardingActions } from "./typing";

export const initializePartnersToAccept = createAsyncThunk(
  OnboardingActions.initializePartnersToAccept,
  async (_: undefined, thunkAPI) => {
    const state = thunkAPI.getState() as RevealStore;

    let filters = { needs_to_be_accepted_by_user: true } as {
      [name: string]: FilterValue;
    };

    // Sales, Sales Manager, Partnership managers should see only invites that have been addressed to them directly.
    // Admins should see all pending invites for their company (whether it’s been addressed to them directly or not).
    if (!state.user.profile.data.isCompanyAdmin()) {
      filters["user_directly_invited"] = true;
    }

    const indexAllPartnersToAcceptAction = await thunkAPI.dispatch(
      indexAll({
        type: "partnerships",
        options: { fields, filters, include: included, page: { size: 100 } },
        concurrency: 5,
      })
    );

    if (isRejected(indexAllPartnersToAcceptAction)) {
      throw new Error("Cannot initialize dashboard");
    }

    return {
      partnershipIds: indexAllPartnersToAcceptAction.payload.data.map(
        (value: JSONAPIResource) => +value.id
      ),
    };
  }
);

export const initializePartnersToShare = createAsyncThunk(
  OnboardingActions.initializePartnersToShare,
  async (_: undefined, thunkAPI) => {
    const state = thunkAPI.getState() as RevealStore;

    // Sales team does not see partnerships from share section
    if (state.user.profile.data.isSales()) {
      return { partnershipIds: [] };
    }

    let filters = { needs_sharing_from_user: true } as {
      [name: string]: FilterValue;
    };

    // Partnership managers will only see their favorites partnerships from share section
    // Even if they come from the sidebar item
    if (!state.user.profile.data.isCompanyAdmin()) {
      filters["watched_by_user"] = true;
    }

    const indexAllPartnersToShareAction = await thunkAPI.dispatch(
      indexAll({
        type: "partnerships",
        options: { fields, filters, include: included, page: { size: 100 } },
        concurrency: 5,
      })
    );

    if (isRejected(indexAllPartnersToShareAction)) {
      throw new Error("Cannot initialize dashboard");
    }

    return {
      partnershipIds: indexAllPartnersToShareAction.payload.data.map(
        (value: JSONAPIResource) => +value.id
      ),
    };
  }
);
