import _ from "lodash";

import Record from "./Record";
import { JSONAPIResource } from "./types";

export default class SourceConfiguration extends Record {
  rawCompanyCustomMapping: { [left: string]: string };

  constructor(data: JSONAPIResource | Record) {
    super(data);
    if (_.isObject(this.attributes.raw_company_custom_mapping)) {
      this.rawCompanyCustomMapping = this.attributes
        .raw_company_custom_mapping as { [key: string]: string };
    } else {
      this.rawCompanyCustomMapping = {};
    }
  }
}
