import { Divider, Grid } from "@mui/material";
import { isFulfilled } from "@reduxjs/toolkit";
import { Inbound, InboundAndOutbound, Outbound } from "components/icons";
import CompanyAvatar from "components/ui/avatars/CompanyAvatar";
import { T } from "components/ui/Typography";
import usePushNotification from "hooks/usePushNotification";
import useSegment from "hooks/useSegment";
import useSelectorWithDeepEquality from "hooks/useSelectorWithDeepEquality";
import useUserProfile from "hooks/useUserProfile";
import generic from "i18n/generic";
import _ from "lodash";
import { makeStyles } from "makeStyles";
import Match from "models/Match";
import PartnerConnection, {
  Kind,
  kindEncodeMapping,
  stageEncodeMapping,
} from "models/PartnerConnection";
import Partnership from "models/Partnership";
import User from "models/User";
import { useState } from "react";
import { defineMessages, FormattedMessage, useIntl } from "react-intl";
import { useDispatch } from "react-redux";
import { updateAttributesOnRecord } from "redux/api/actions";
import { selectAllPartnerships } from "redux/api/selectors";
import { update } from "redux/api/thunks";
import { selectCurrentUser, selectPerspective } from "redux/pipeline/selectors";
import { WebhookEventName, WebhookService } from "services/WebhookService";
import { PipelineEvent, PipelineTableEditAction } from "tracking";

import { DropdownGroup } from "../../../shared/useDropdownCell";

export const useKindCell = ({ row }: { row: PartnerConnection }) => {
  const dispatch = useDispatch();
  const { track } = useSegment();
  const intl = useIntl();
  const { profile } = useUserProfile();
  const pushNotification = usePushNotification();
  const currentUser = useSelectorWithDeepEquality(selectCurrentUser);
  const perspective = useSelectorWithDeepEquality(selectPerspective);
  const { allowEdit, disabledText } = getValues({
    row,
    currentUserId: currentUser?.id,
    profile,
    isPartnerView: perspective === Match.PERSPECTIVE_THEIRS,
  });
  const { classes } = useStyles({ allowEdit, disabledText });
  const partnerships = useSelectorWithDeepEquality(
    selectAllPartnerships
  ) as Partnership[];
  const partner = partnerships
    .find((p) => p.id === row.partnershipId)
    ?.getPartner(profile);
  const [currentKindValue, setCurrentKindValue] = useState<Kind | null>(null);

  const iconElement = (kind: Kind) =>
    isInboundAndOutbound(kind)
      ? InboundAndOutbound
      : perspective === Match.PERSPECTIVE_MINE
      ? Inbound
      : Outbound;

  const optionsGroups: DropdownGroup<Kind>[] = [
    {
      noContentPlaceholder: intl.formatMessage(i18n.noOptionsAvailable),
      options: _.values(Kind),
    },
  ];

  const getLabel = (kind: Kind) => _.capitalize(_.lowerCase(kind));
  const getLabelWithIcon = (kind: Kind) => {
    const IconElement = iconElement(kind);
    return (
      <div title={getLabel(kind)} className={classes.labelContainer}>
        <IconElement className={classes.icon} />
        <T noWrap>{getLabel(kind)}</T>
      </div>
    );
  };

  const clearAttributes = async () => {
    setCurrentKindValue(null);
    const response = await dispatch(
      update({
        id: row.id,
        type: "partner_connections",
        attributes: {
          activity_date: null,
          kind: kindEncodeMapping.NONE,
        },
      })
    );
    if (isFulfilled(response)) {
      track(PipelineEvent.tableEdit, {
        action: PipelineTableEditAction.updateKind,
      });
    } else {
      pushNotification("default_error");
    }
  };

  const updateAttributes = async (
    attributeDate?: string | null,
    fromStageCell?: boolean
  ) => {
    const service = new WebhookService();
    const newKind = currentKindValue ?? row.kind ?? null;
    const response = await dispatch(
      update({
        id: row.id,
        type: "partner_connections",
        attributes: {
          activity_date: attributeDate,
          kind: newKind ? kindEncodeMapping[newKind] : kindEncodeMapping.NONE,
          ...(fromStageCell && { stage: stageEncodeMapping.Attributed }),
        },
      })
    );

    if (isFulfilled(response)) {
      dispatch(
        updateAttributesOnRecord(
          {
            id: row.id.toString(),
            type: "partner_connections",
          },
          {
            kind: newKind ? kindEncodeMapping[newKind] : kindEncodeMapping.NONE,
            activityDate: attributeDate,
            ...(fromStageCell && { stage: stageEncodeMapping.Attributed }),
          }
        )
      );
      service.track({
        profile: profile,
        eventName: WebhookEventName.RevealAddedCollaborateAttributionType,
        partnerConnection: row,
        rawCompanyId: row.rawCompanyId,
        rawCompanyProviderKey: row.rawCompanyProviderKey,
        companyId: row.companyId,
        partnershipId: row.partnershipId,
        partnerConnectionKind: newKind,
      });
      track(PipelineEvent.tableEdit, {
        action: PipelineTableEditAction.updateKind,
      });
    } else {
      pushNotification("default_error");
    }
  };

  const CustomHeader = (
    <Grid container direction="column" gap="4px">
      <Grid item container gap="4px">
        <CompanyAvatar
          size="xxs"
          companyName={partner?.name}
          src={partner?.avatarUrl}
        />
        <T titleSmall>Log Attribution</T>
      </Grid>
      <Grid item>
        <T className={classes.alpha}>
          <FormattedMessage
            {...i18n.headerDescription}
            values={{ partnerName: partner?.name }}
          />
        </T>
      </Grid>
      <Divider className={classes.divider} />
    </Grid>
  );

  return {
    allowEdit,
    classes,
    CustomHeader,
    disabledText,
    getLabelWithIcon,
    optionsGroups,
    updateAttributes,
    setCurrentKindValue,
    currentKindValue,
    clearAttributes,
  };
};

const getValues = ({
  row,
  currentUserId,
  profile,
  isPartnerView,
}: {
  row: any;
  currentUserId?: number;
  profile: User;
  isPartnerView: boolean;
}) => {
  if (!currentUserId) {
    return { allowEdit: false, disabledText: undefined };
  }
  const isAdminOrPM =
    profile.isCompanyAdmin() || profile.isPartnershipManager();
  const isUserItem = row.userId === currentUserId;
  const dataFromSync = row.rawOpportunitiesWithRule?.length > 0 ?? false;
  const disabledText = dataFromSync ? (
    <FormattedMessage {...generic.cannotEditFromCRM} />
  ) : undefined;
  const allowEdit =
    !isPartnerView && !dataFromSync && (isAdminOrPM || isUserItem);

  return { allowEdit, disabledText };
};

const useStyles = makeStyles<{
  allowEdit: boolean;
  disabledText: JSX.Element | undefined;
}>()((theme, { allowEdit, disabledText }) => ({
  alpha: {
    color: theme.palette.alpha500,
    fontSize: 11,
    lineHeight: "150%",
    textWrap: "wrap",
  },
  divider: {
    margin: "8px -12px",
    borderBottom: `1px solid ${theme.palette.taupe500}`,
    width: "unset",
  },
  labelContainer: {
    display: "flex",
    alignItems: "center",
    gap: "6px",
    overflow: "hidden",
    marginLeft: allowEdit || !!disabledText ? 0 : 10,
  },
  icon: {
    width: 12,
    height: 12,
    color: theme.palette.alpha500,
  },
}));

const isInboundAndOutbound = (kind: Kind) =>
  [Kind["CO-SELLING"], Kind.RESELLING].includes(kind);

const i18n = defineMessages({
  noOptionsAvailable: {
    id: "CellRenderer.Pipeline.KindCell.noOptionsAvailable",
    defaultMessage: "No options available",
  },
  chooseKind: {
    id: "CellRenderer.Pipeline.KindCell.chooseKind",
    defaultMessage: "Attribute",
  },
  headerDescription: {
    id: "CellRenderer.Pipeline.KindCell.headerDescription",
    defaultMessage:
      'Attached opportunities will be considered "attributed" to {partnerName}.',
  },
});
