import {
  boolean,
  constant,
  Decoder,
  guard,
  iso8601,
  map,
  nullable,
  object,
  regex,
  string,
} from "decoders";
import { mapNullable } from "helpers/mapNullable";
import { defineMessages, FormattedMessage } from "react-intl";

import Record from "./Record";
import { JSONAPIResourceOrRecord } from "./types";

// Feature status Enum

export enum FeatureStatus {
  limited = "limited",
  locked = "locked",
  unlimited = "unlimited",
  trial = "trial",
}

export enum PayingFeature {
  UnlimitedRowsExport = "UnlimitedRowsExport",
  ExportNewProspectToCRM = "ExportNewProspectToCRM",
  ExportNewProspectToCSV = "ExportNewProspectToCSV",
  GrowthKpis = "Growth Kpis",
  Integration = "Integration",
  LeadImport = "LeadImport",
  My360MappingUnlocked = "My360MappingUnlocked",
  Limit360MappingByPartnerships = "Limit360MappingByPartnerships",
  SalesInvitations = "SalesInvitations",
  UnlimitedOfflineAccountMapping = "UnlimitedOfflineAccountMapping",
  UnlimitedPipelineSharing = "UnlimitedPipelineSharing",
  DedicatedTenant = "DedicatedTenant",
  PartnerStackIntegration = "PartnerStackIntegration",
  WeeklyDigestToSales = "WeeklyDigestToSales",
  UnlimitedWeeklyDigestContent = "UnlimitedWeeklyDigestContent",
  RestrictPartnersCRMSync = "RestrictPartnersCRMSync",
  AdvancedAnalytics = "AdvancedAnalytics",
  SingleSignOn = "SingleSignOn",
  APIAccess = "APIAccess",
  SlackIntegration = "SlackIntegration",
  DataPackage = "DataPackage",
  ForceDemoPartnerDisplay = "ForceDemoPartnerDisplay",
  SmartList = "SmartList",
  HideableStandardFields = "HideableStandardFields",
  SavedFiltersPRO = "SavedFiltersPRO",
  SavedFiltersPOWER = "SavedFiltersPOWER",
  OpenOpportunityDefinition = "OpenOpportunityDefinition",
  GoalBased360Limited = "GoalBased360Limited",
  GoalBased360Unlocked = "GoalBased360Unlocked",
  NewProspects360 = "NewProspects360",
  FilteringUpdatesAccountMapping = "FilteringUpdatesAccountMapping",
  CRMSyncWildcards = "CRMSyncWildcards",
  IntroductionRequestForms = "IntroductionRequestForms",
  Limit360Drawer = "Limit360Drawer",
  NewOnboarding = "NewOnboarding",
  UseNew360 = "UseNew360",
  AttributeEnabled = "AttributeEnabled",
  PartnerFieldMapping = "PartnerFieldMapping",
  WidgetPartnerField = "WidgetPartnerField",
  UseNewAccountMapping = "UseNewAccountMapping",
}

const i18n = defineMessages({
  limited: {
    id: "models.CompanyPayingFeatureSubscription.limited",
    defaultMessage: "Limited",
  },
  locked: {
    id: "models.CompanyPayingFeatureSubscription.locked",
    defaultMessage: "Locked",
  },
  unlimited: {
    id: "models.CompanyPayingFeatureSubscription.unlimited",
    defaultMessage: "Unlimited",
  },
  trial: {
    id: "models.CompanyPayingFeatureSubscription.trial",
    defaultMessage: "Trial",
  },
});

export const FEATURE_STATUS_LABELS = {
  [FeatureStatus.limited]: <FormattedMessage {...i18n.limited} />,
  [FeatureStatus.locked]: <FormattedMessage {...i18n.locked} />,
  [FeatureStatus.unlimited]: <FormattedMessage {...i18n.unlimited} />,
  [FeatureStatus.trial]: <FormattedMessage {...i18n.trial} />,
};

const dayPreciseDate: Decoder<Date> = map(
  regex(
    /^[0-9]{4}-(0[1-9]|1[012])-(0[1-9]|[12][0-9]|3[01])$/,
    "Invalid date format."
  ),
  (date: string) => new Date(date)
);

const apiCompanyPayingFeatureSubscriptionDecoder = object({
  type: constant("company_paying_feature_subscriptions" as const),
  id: string,
  attributes: object({
    requested_at: nullable(iso8601),
    enabled: boolean,
    available_until: nullable(dayPreciseDate),
    paying_feature: string,
  }),
});

const apiCompanyPayingFeatureSubscriptionGuard = guard(
  apiCompanyPayingFeatureSubscriptionDecoder
);
class CompanyPayingFeatureSubscription extends Record<"company_paying_feature_subscriptions"> {
  enabled: boolean;
  requestedAt: Date | null;
  availableUntil: Date | null;

  constructor(
    data: JSONAPIResourceOrRecord<"company_paying_feature_subscriptions">
  ) {
    super(data);
    const apiCompanyPayingFeatureSubscription = apiCompanyPayingFeatureSubscriptionGuard(
      data
    );
    const { attributes } = apiCompanyPayingFeatureSubscription;

    this.requestedAt = attributes.requested_at;
    this.enabled = attributes.enabled;
    this.availableUntil = mapNullable(
      attributes.available_until,
      (v) => new Date(v)
    );
  }

  get isActive() {
    const currentDate = new Date(Date.now());
    return (
      this.enabled &&
      (this.availableUntil === null || this.availableUntil > currentDate)
    );
  }

  get status() {
    const isActive = this.isActive;
    if (isActive) {
      if (this.availableUntil !== null) {
        return FeatureStatus.trial;
      }
      return FeatureStatus.unlimited;
    }
    return FeatureStatus.locked;
  }
}

export default CompanyPayingFeatureSubscription;
