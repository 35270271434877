import { createSvgIcon } from "@mui/material/utils";

import { SvgIcon } from "./types";

const displayName = "Launch";

const Launch: SvgIcon = createSvgIcon(
  // Using transform to enlarge from provided viewbox="0 0 12 12"
  // to default "0 0 24 24" from `createSvgIcon` -> `SvgIcon`.
  <g fill="none" transform="matrix(2,0,0,2,0,0)">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M1 1.75C1 1.33579 1.33579 1 1.75 1H4C4.27614 1 4.5 0.776142 4.5 0.5C4.5 0.223858 4.27614 0 4 0H1.75C0.783502 0 0 0.783502 0 1.75V10.25C0 11.2165 0.783502 12 1.75 12H10.25C11.2165 12 12 11.2165 12 10.25V8C12 7.72386 11.7761 7.5 11.5 7.5C11.2239 7.5 11 7.72386 11 8V10.25C11 10.6642 10.6642 11 10.25 11H1.75C1.33579 11 1 10.6642 1 10.25V1.75ZM7.08238 0.577652C7.08238 0.853794 7.30624 1.07765 7.58238 1.07765H10.1222L4.0289 7.17097C3.83364 7.36624 3.83364 7.68282 4.0289 7.87808C4.22417 8.07334 4.54075 8.07334 4.73601 7.87808L10.9577 1.65644V4.45293C10.9577 4.72907 11.1815 4.95293 11.4577 4.95293C11.7338 4.95293 11.9577 4.72907 11.9577 4.45293V1.32765C11.9577 0.637296 11.398 0.0776519 10.7077 0.0776519L7.58238 0.077652C7.30624 0.077652 7.08238 0.30151 7.08238 0.577652Z"
      fill="#FAFAF6"
    />
  </g>,
  displayName
);

Launch.displayName = displayName;

export default Launch;
