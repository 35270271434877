import Box from "@mui/material/Box";
import { GridColumnHeaderParams } from "@mui/x-data-grid-premium";
import { T } from "components/ui/Typography";
import { makeStyles } from "makeStyles";
import { useEffect, useRef } from "react";
import { DEFAULT_REVENUE_COLUMN_WIDTH } from "screens/Frontoffice/screens/DataTables/shared/components/ColumnWidget";

export type ColumnGroupRendererProps = {
  columnGroups?: [string, string][];
  params: GridColumnHeaderParams;
  sortDate: number;
};

const ColumnGroupRenderer = ({
  columnGroups,
  params,
  sortDate,
}: ColumnGroupRendererProps) => {
  const { classes, cx } = useStyles();
  const ref = useRef<HTMLDivElement>(null);

  const label = params.colDef.headerName ?? "";
  const isDoubleCell = columnGroups?.some(
    ([primary, _secondary]) => primary === params.field
  );
  const isBottomCell = columnGroups?.some(
    ([_primary, secondary]) => secondary === params.field
  );

  useEffect(() => {
    if (ref.current === null) {
      return;
    }
    const headerCell: null | HTMLDivElement = ref.current.closest(
      ".MuiDataGrid-columnHeader"
    );
    if (headerCell === null) {
      return;
    }
    headerCell.classList.add(
      isDoubleCell || isBottomCell ? "two-storey" : "single-storey"
    );
    const columnHeaderRow: null | HTMLDivElement = ref.current.closest(
      ".MuiDataGrid-columnHeaders"
    );
    if (columnHeaderRow === null) {
      return;
    }
    isDoubleCell && columnHeaderRow.classList.add("grouped");
    return () => {
      headerCell.classList.remove("two-storey");
      headerCell.classList.remove("single-storey");
      columnHeaderRow.classList.remove("grouped");
    };
  }, [isDoubleCell, isBottomCell, sortDate]);

  return (
    <>
      <Box ref={ref} className={classes.container}>
        <T
          noWrap
          className={cx({
            [classes.bold]: !isDoubleCell && !isBottomCell,
            [classes.purple]: isBottomCell,
          })}
        >
          {isBottomCell ? "Revenue" : isDoubleCell ? "# Accounts" : label}
        </T>
      </Box>
      {isDoubleCell && <div className={classes.sideTitle}>{label}</div>}
    </>
  );
};

export default ColumnGroupRenderer;

// CSS

const useStyles = makeStyles()((theme) => ({
  bold: {
    fontWeight: 600,
  },
  container: {
    display: "flex",
    alignItems: "center",
    width: "100%",
  },
  lockIcon: {
    height: 11,
    width: 12,
    marginRight: 6,
  },
  purple: {
    color: theme.palette.purple,
  },
  sideTitle: {
    maxWidth: `calc(90% + ${DEFAULT_REVENUE_COLUMN_WIDTH}px - 8px)`,
    position: "absolute",
    left: theme.spacing(1),
    fontWeight: 600,
    top: "-49px",
    textOverflow: "ellipsis",
    overflow: "hidden",
    whiteSpace: "nowrap",
  },
}));
