import CircularProgress from "@mui/material/CircularProgress";
import Grid from "@mui/material/Grid";
import SnackbarContent from "@mui/material/SnackbarContent";
import { Theme } from "@mui/material/styles";
import {
  CircleCheck,
  CircleError,
  CircleWarning,
  Close,
} from "components/icons";
import { T } from "components/ui/Typography";
import { makeStyles } from "makeStyles";
import { ReactNode } from "react";
import { FormattedHTMLMessage, MessageDescriptor } from "react-intl";

export enum NotificationStatus {
  loading = "loading",
  success = "success",
  warning = "warning",
  error = "error",
  none = "none",
}

type StyleProps = {
  status?: NotificationStatus;
};

export type NotificationSnackbarProps = {
  message: string | MessageDescriptor;
  description?: string | MessageDescriptor;
  context?: Record<string, any>;
  actions?: ReactNode | null;
  onClose: () => void;
} & StyleProps;

export const NotificationSnackbar = ({
  status = NotificationStatus.none,
  message,
  description,
  context,
  onClose,
  actions = null,
}: NotificationSnackbarProps) => {
  const { classes } = useStyles({ status });

  let iconStatus: null | ReactNode = null;
  if (status) {
    switch (status) {
      case NotificationStatus.loading:
        iconStatus = (
          <CircularProgress
            size={15}
            classes={{ colorPrimary: classes.loader }}
          />
        );
        break;
      case NotificationStatus.success:
        iconStatus = <CircleCheck className={classes.statusIcon} />;
        break;
      case NotificationStatus.warning:
        iconStatus = <CircleWarning className={classes.statusIcon} />;
        break;
      case NotificationStatus.error:
        iconStatus = <CircleError className={classes.statusIcon} />;
        break;
      default:
        iconStatus = null;
    }
  }

  const messageComponent =
    typeof message === "string" ? (
      message
    ) : (
      <FormattedHTMLMessage {...message} values={context} />
    );

  const descriptionComponent = Boolean(description) ? (
    typeof description === "string" ? (
      description
    ) : (
      <FormattedHTMLMessage {...description} values={context} />
    )
  ) : null;

  return (
    <SnackbarContent
      classes={{
        root: classes.root,
        message: classes.message,
        action: classes.action,
      }}
      className={classes.colors}
      message={
        <Grid
          container
          spacing={2}
          direction="column"
          className={classes.container}
        >
          <Grid item className={classes.textContainer}>
            <T titleBig className={classes.title}>
              {iconStatus}
              {messageComponent}
            </T>
            {descriptionComponent && <T>{descriptionComponent}</T>}
          </Grid>
          {actions !== null && <Grid item>{actions}</Grid>}
          {/* <Close className={classes.icon} onClick={onClose} /> */}
        </Grid>
      }
      action={<Close className={classes.icon} onClick={onClose} />}
    />
  );
};

export default NotificationSnackbar;

// CSS Helpers

const getColor = (theme: Theme, status: NotificationStatus) => {
  switch (status) {
    case NotificationStatus.warning:
      return theme.palette.gold;
    case NotificationStatus.success:
      return theme.palette.green;
    case NotificationStatus.error:
      return theme.palette.red600;
  }
};

// CSS

const useStyles = makeStyles<StyleProps>()((theme, { status }) => ({
  root: {
    borderRadius: theme.spacing(1),
  },
  container: {
    marginRight: theme.spacing(2),
    maxWidth: 445,
  },
  colors: {
    backgroundColor: theme.palette.midnight,
    color: theme.palette.white,
  },
  message: {
    display: "flex",
    minHeight: 30,
    alignItems: "center",
  },
  action: {
    marginBottom: "auto",
    padding: 3,
    paddingRight: 0,
  },
  textContainer: {
    display: "flex",
    flexDirection: "column",
    rowGap: theme.spacing(1),
  },
  loader: {
    color: theme.palette.white,
  },
  statusIcon: {
    height: 16,
    width: 16,
    color: getColor(theme, status ?? NotificationStatus.none),
  },
  title: {
    display: "flex",
    alignItems: "center",
    columnGap: theme.spacing(1),
  },
  icon: {
    color: theme.palette.white,
    height: 8,
    width: 8,
    cursor: "pointer",
  },
}));
