import { validatePartnerships } from "screens/Frontoffice/screens/Onboarding/screens/ActivatePartnerships/components/OnboardingActivatePartnerships";
import { validateCompanyDetails } from "screens/Frontoffice/screens/Onboarding/screens/CompanyFormDetails/components/OnboardingPartnersCompanyDetails";
import { validateInfluenceSource } from "screens/Frontoffice/screens/Onboarding/screens/InfluenceSource/components/OnboardingInfluenceSource";
import { validateUser } from "screens/Frontoffice/screens/Onboarding/screens/UserForm/components/OnboardingPartnersUser";
import { validateUserIntention } from "screens/Frontoffice/screens/Onboarding/screens/UserIntention/components/OnboardingUserIntention";
import { validateBookDemo } from "screens/Frontoffice/screens/Onboarding/shared/OnboardingBookDemo";

const onboardingConf = [
  {
    attr: "companyId",
    url: "/onboarding/company",
  },
  {
    attr: "",
    url: "/onboarding/company-details",
    validator: validateCompanyDetails,
  },
  {
    attr: "",
    url: "/onboarding/user",
    validator: validateUser,
  },
  {
    attr: "",
    url: "/onboarding/user-intention-influence-book-demo",
    validator: validateUserIntention,
  },
  {
    attr: "",
    url: "/onboarding/user-intention-influence-book-demo",
    validator: validateInfluenceSource,
  },
  {
    attr: "",
    url: "/onboarding/user-intention-influence-book-demo",
    validator: validateBookDemo,
  },
  {
    attr: "",
    url: "/onboarding/partnership-activation",
    validator: validatePartnerships,
  },
];

export default onboardingConf;
