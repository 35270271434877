import { FormControl, useMediaQuery, useTheme } from "@mui/material";
import Grid from "@mui/material/Grid";
import { isFulfilled } from "@reduxjs/toolkit";
import withCompanyPermissions from "components/hoc/withCompanyPermissions";
import { Check } from "components/icons";
import Button from "components/ui/Button";
import { Option, SelectInput } from "components/ui/SelectInput";
import SettingsSection from "components/ui/SettingsSection";
import { TextInput } from "components/ui/TextInput";
import {
  Field,
  FieldHookConfig,
  Form,
  Formik,
  FormikHelpers,
  useField,
} from "formik";
import usePushNotification from "hooks/usePushNotification";
import useUserProfile from "hooks/useUserProfile";
import generic from "i18n/generic";
import { isEqual } from "lodash";
import { makeStyles } from "makeStyles";
import { Factory } from "models";
import {
  COMPANY_ECOSYSTEM_LABELS,
  COMPANY_SIZE_LABELS,
  COMPANY_TYPE_LABELS,
  CompanyEcosystem,
  CompanySize,
  CompanyType,
} from "models/Company";
import { useCallback } from "react";
import {
  defineMessages,
  FormattedMessage,
  MessageDescriptor,
  useIntl,
} from "react-intl";
import { useDispatch } from "react-redux";
import { update } from "redux/api/thunks";
import { initializeSuggestedPartners } from "redux/suggestedPartners/thunks";
import { setCompany } from "redux/user/actions";
import AccountSettings from "screens/Frontoffice/screens/AccountSettings";
import { JSONAPIResponse } from "services/types";
import { object, string } from "yup";

import CompanyAvatar from "./CompanyAvatar";

const DESCRIPTION_MAX_LENGTH = 250;

const VALIDATION_SCHEMA = object({
  name: string(),
  website_url: string(),
  description: string().test(
    "length",
    () => i18n.descriptionLengthError,
    (value) => (value?.length ?? 0) <= DESCRIPTION_MAX_LENGTH
  ),
  size: string(),
  business_type: string(),
  ecosystem: string(),
});

type ICompanySettings = {
  name?: string;
  website_url?: string;
  description: string;
  size: string;
  business_type: string;
  ecosystem: string;
};

type IFormTextInputProps<T> = {
  maxLength?: number;
  multiline?: boolean;
  placeholder?: MessageDescriptor;
  rows?: string;
  rowsmax?: string;
  withCounter?: boolean;
} & FieldHookConfig<T>;

type IFormSelectInputProps<T> = {
  placeholder?: MessageDescriptor;
  options: Option[];
} & FieldHookConfig<T>;

const CharCounter = ({ max, current }: { max: number; current: number }) => {
  const { classes } = useStyles();
  const content = `${current}/${max}`;

  return <div className={classes.charCounter}>{content}</div>;
};

const TextInputForFormik = ({
  maxLength,
  multiline,
  placeholder,
  rows,
  withCounter,
  ...props
}: IFormTextInputProps<string>) => {
  const [field, meta] = useField(props);
  const intl = useIntl();
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (maxLength && event.target.value.length > maxLength) {
      return;
    }
    field.onChange(event);
  };
  return (
    <FormControl fullWidth>
      <TextInput
        variant="tertiary"
        {...field}
        onChange={handleChange}
        multiline={multiline}
        placeholder={intl.formatMessage(placeholder as MessageDescriptor)}
        rows={rows}
        disabled={props.disabled}
        error={!!meta.error && meta.touched}
      />
      {withCounter && (
        <CharCounter
          max={DESCRIPTION_MAX_LENGTH}
          current={field.value?.length ?? 0}
        />
      )}
    </FormControl>
  );
};

const SelectInputForFormik = ({
  options,
  placeholder,
  ...props
}: IFormSelectInputProps<string>) => {
  const [field, meta] = useField(props);
  const intl = useIntl();

  return (
    <FormControl fullWidth>
      <SelectInput
        variant="tertiary"
        displayEmpty={false}
        placeholder={
          !!placeholder
            ? intl.formatMessage(placeholder as MessageDescriptor)
            : ""
        }
        error={!!meta.error && meta.touched}
        {...field}
        options={options}
      />
    </FormControl>
  );
};

const CompanySettingsContainer = () => {
  const { classes } = useStyles();
  const dispatch = useDispatch();
  const pushNotification = usePushNotification();
  const {
    profile: { company },
  } = useUserProfile();

  const handleSubmit = useCallback(
    async (
      values: ICompanySettings,
      actions: FormikHelpers<ICompanySettings>
    ) => {
      actions.setSubmitting(true);
      delete values.website_url;
      delete values.name;
      const result = await dispatch(
        update({
          id: company.id,
          type: "companies",
          attributes: values,
        })
      );
      if (isFulfilled(result)) {
        const response = result.payload as JSONAPIResponse;
        await dispatch(setCompany(Factory.createRecord(response.data)));
        await dispatch(initializeSuggestedPartners({ partnerOnReveal: true }));
        pushNotification(i18n.submitSuccess);
      } else {
        pushNotification(i18n.submitError);
      }
      actions.setSubmitting(false);
    },
    [dispatch, pushNotification, company.id]
  );

  const initialValues = {
    name: company.name,
    business_type: company.businessType,
    description: company.description,
    website_url: company.websiteUrl,
    ecosystem: company.ecosystem,
    size: company.size,
  };
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down("sm"));
  return (
    <AccountSettings tab="company">
      <Grid item className={classes.root}>
        <SettingsSection
          title={<FormattedMessage {...i18n.companySettingsTitle} />}
        >
          <CompanyAvatar />
          <Formik
            initialValues={initialValues}
            onSubmit={handleSubmit}
            validationSchema={VALIDATION_SCHEMA}
          >
            {({ isSubmitting, values }) => (
              <Form className={classes.form}>
                <Field
                  disabled={true}
                  name="name"
                  as={TextInputForFormik}
                  placeholder={i18n.placeholderName}
                />
                <Field
                  disabled={true}
                  name="website_url"
                  as={TextInputForFormik}
                  placeholder={i18n.placeholderWebsite}
                />
                <Field
                  disabled={true}
                  name="size"
                  as={SelectInputForFormik}
                  options={Object.values(CompanySize).map((key) => ({
                    value: key,
                    label: COMPANY_SIZE_LABELS[key],
                  }))}
                  placeholder={i18n.placeholderSize}
                />
                <Field
                  disabled={true}
                  name="business_type"
                  as={SelectInputForFormik}
                  options={Object.values(CompanyType).map((key) => ({
                    value: key,
                    label: COMPANY_TYPE_LABELS[key],
                  }))}
                  placeholder={i18n.placeholderBusinessType}
                />
                <Field
                  disabled={true}
                  name="ecosystem"
                  as={SelectInputForFormik}
                  options={Object.values(CompanyEcosystem).map((key) => ({
                    value: key,
                    label: COMPANY_ECOSYSTEM_LABELS[key],
                  }))}
                  placeholder={i18n.placeholderEcosystem}
                />
                <Field
                  name="description"
                  as={TextInputForFormik}
                  maxLength={DESCRIPTION_MAX_LENGTH}
                  multiline={true}
                  rows={matches ? "10" : "6"}
                  placeholder={i18n.placeholderDescription}
                  withCounter
                />
                <Button
                  disabled={isEqual(initialValues, values)}
                  label={generic.save}
                  LeftIcon={Check}
                  loading={isSubmitting}
                  type="submit"
                />
              </Form>
            )}
          </Formik>
        </SettingsSection>
      </Grid>
    </AccountSettings>
  );
};

export const CompanySettings = withCompanyPermissions(["company.update"])(
  CompanySettingsContainer
);

/// CSS

const useStyles = makeStyles()((theme) => ({
  charCounter: {
    bottom: theme.spacing(2),
    color: theme.palette.midnight500,
    fontSize: 12,
    position: "absolute",
    right: theme.spacing(2),
  },
  root: {
    padding: theme.spacing(1, 0),
  },
  form: {
    display: "flex",
    flexDirection: "column",
    gap: theme.spacing(1),
    maxWidth: 380,
    "& > button": {
      marginTop: theme.spacing(2),
    },
  },
}));

/// I18N

const i18n = defineMessages({
  companySettingsTitle: {
    id: "Workspace.Company.companySettingsTitle",
    defaultMessage: "Company Information",
  },
  descriptionLengthError: {
    id: "Workspace.Company.descriptionLengthError",
    defaultMessage: "max. {length} characters",
  },
  placeholderName: {
    id: "Workspace.Company.placeholderName",
    defaultMessage: "Company name",
  },
  placeholderWebsite: {
    id: "Workspace.Company.placeholderWebsite",
    defaultMessage: "Company website",
  },
  placeholderSize: {
    id: "Workspace.Company.placeholderSize",
    defaultMessage: "Number of employees",
  },
  placeholderBusinessType: {
    id: "Workspace.Company.placeholderBusinessType",
    defaultMessage: "Company type",
  },
  placeholderEcosystem: {
    id: "Workspace.Company.placeholderEcosystem",
    defaultMessage: "Company ecosystem",
  },
  placeholderDescription: {
    id: "Workspace.Company.placeholderDescription",
    defaultMessage: "Company description",
  },
  submitError: {
    id: "Workspace.Company.submitError",
    defaultMessage: "Failed to update the company information",
  },
  submitSuccess: {
    id: "Workspace.Company.submitSuccess",
    defaultMessage: "The company information updated successfully",
  },
});
