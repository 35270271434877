import { AmountFormatter } from "hooks/useAmountFormatter";
import generic from "i18n/generic";
import _ from "lodash";
import { IntlShape } from "react-intl";

import { FieldType, MatchFieldType } from "./types";

export const fieldFormatter = (
  value: any,
  field: FieldType,
  intl: IntlShape,
  amountFormatter: AmountFormatter,
  rawOpportunityField: boolean = false
) => {
  // TODO-RVL-3254: this question mark after `field?.` may not be needed but I'm not confident it'll always exist.
  const type = field?.type || MatchFieldType.TEXT;
  switch (type) {
    case MatchFieldType.BOOLEAN:
      return typeof value === "boolean"
        ? intl.formatMessage(value ? generic.true : generic.false)
        : "";

    case MatchFieldType.INTEGER:
      return value;

    case MatchFieldType.PICKLIST:
      let formatted = "";
      if (field.smartField) {
        // If the field is a smart field (ie managed by Reveal, not from CRM),
        // we don't manage multiple values.
        formatted = _.get(field, `options.${value}`, "");
      } else {
        // If the field is not a smart field (ie extra field from CRM),
        // the backend send an array of labels.
        formatted = value && _.isArray(value) ? value.join(", ") : "";
      }
      return formatted;

    case MatchFieldType.CURRENCY:
      const currencyFormatted = value
        ? amountFormatter(value.amount, value.currency)
        : "";
      return currencyFormatted;

    case MatchFieldType.DATETIME:
    case MatchFieldType.DATE:
      if (!rawOpportunityField) {
        return value;
      }
      return value ? intl.formatDate(new Date(value)) : "";

    case MatchFieldType.USER:
      return _.isObject(value) ? _.keys(value).join(", ") : value;

    // Every other type should be displayed as is.
    default:
      return value;
  }
};
