import Match from "models/Match";
import { defineMessages, FormattedMessage } from "react-intl";

const i18n = defineMessages({
  customer: {
    id: "AccountStatus.customer",
    defaultMessage: "Customer",
  },
  prospect: {
    id: "AccountStatus.prospect",
    defaultMessage: "Prospect",
  },
  partner: {
    id: "AccountStatus.partner",
    defaultMessage: "Partner",
  },
  forYears: {
    id: "AccountStatus.forYears",
    defaultMessage: " for {delta, plural, one {a year} other {# years}}",
  },
  forMonths: {
    id: "AccountStatus.forMonths",
    defaultMessage:
      " {delta, plural, one {since last month} other {for # months}}",
  },
  forLessThanAMonth: {
    id: "AccountStatus.sinceLessThanAMonth",
    defaultMessage: " for less than a month",
  },
});

const SECONDS_IN_MONTH = 30 * 24 * 60 * 60;
const SECONDS_IN_YEAR = 365 * 24 * 60 * 60;

const renderStatusWithDelta = (statusRaw: $TSFixMe, strDate: $TSFixMe) => {
  // @ts-expect-error ts-migrate(2363) FIXME: The right-hand side of an arithmetic operation mus... Remove this comment to see the full error message
  const delta = Math.floor((Date.now() - new Date(strDate)) / 1000);
  if (delta < SECONDS_IN_MONTH) {
    return (
      <FormattedMessage {...i18n.forLessThanAMonth}>
        {(message) => (
          // @ts-expect-error ts-migrate(2322) FIXME: Type '{ children: any[]; type: string; title: stri... Remove this comment to see the full error message
          <span type="text" title={`${statusRaw}${message}`}>
            {statusRaw}
            {message}
          </span>
        )}
      </FormattedMessage>
    );
  }
  if (delta < SECONDS_IN_YEAR) {
    return (
      <FormattedMessage
        {...i18n.forMonths}
        values={{ delta: Math.floor(delta / SECONDS_IN_MONTH) }}
      >
        {(message) => (
          // @ts-expect-error ts-migrate(2322) FIXME: Type '{ children: any[]; type: string; title: stri... Remove this comment to see the full error message
          <span type="text" title={`${statusRaw}${message}`}>
            {statusRaw}
            {message}
          </span>
        )}
      </FormattedMessage>
    );
  }
  return (
    <FormattedMessage
      {...i18n.forYears}
      values={{ delta: Math.floor(delta / SECONDS_IN_YEAR) }}
    >
      {(message) => (
        // @ts-expect-error ts-migrate(2322) FIXME: Type '{ children: any[]; type: string; title: stri... Remove this comment to see the full error message
        <span type="text" title={`${statusRaw}${message}`}>
          {statusRaw}
          {message}
        </span>
      )}
    </FormattedMessage>
  );
};

export const getStatusMessage = (status: number) => {
  switch (+status) {
    case Match.STATUS_CUSTOMER:
      return i18n.customer;
    case Match.STATUS_PARTNER:
      return i18n.partner;
    case Match.STATUS_PROSPECT:
      return i18n.prospect;
    default:
      return null;
  }
};

type AccountStatusProps = {
  account: $TSFixMe;
  includeSince?: boolean;
  toolTip?: boolean;
};

const AccountStatus = ({
  account,
  includeSince,
  toolTip = false,
}: AccountStatusProps) => {
  const message = getStatusMessage(account.status);
  if (!message) {
    return null;
  }
  if (
    +account.status === Match.STATUS_CUSTOMER &&
    account.firstWonOpportAt &&
    includeSince
  ) {
    return renderStatusWithDelta(
      message.defaultMessage,
      account.firstWonOpportAt
    );
  }
  return <FormattedMessage {...message} />;
};

export default AccountStatus;
