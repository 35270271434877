import { useContext, useState } from "react";
import { defineMessages, FormattedHTMLMessage, useIntl } from "react-intl";
import ViewContext from "screens/Frontoffice/screens/DataTables/screens/AccountMapping/contexts/ViewContext";
import { AccountMappingStandardFieldName } from "screens/Frontoffice/screens/DataTables/screens/AccountMapping/shared/types";
import JSONAPIService from "services/JSONAPIService";

import { CellProps } from "../utils";
import BaseContactsCell from "./BaseContactsCell";
import { ContactType } from "./types";

const LeftContactsCell = ({ value, row }: CellProps) => {
  const intl = useIntl();
  const [contacts, setContacts] = useState([]);
  const [unspecifiedText, setUnspecifiedText] = useState<string>("");
  const { accountType } = useContext(ViewContext);

  const loadContacts = () => {
    if (("" + row.id).startsWith("demo")) {
      let demoContacts = require("data/demoContacts.json")
        .filter(({ opports }: $TSFixMe) => !Boolean(opports))
        .slice(0, value);
      setContacts(demoContacts);
      const unspecifiedContacts = demoContacts.filter(
        (item: $TSFixMe) => !Boolean(item.jobtitle)
      ).length;
      setUnspecifiedText(
        intl.formatMessage(i18n.unspecified, { value: unspecifiedContacts })
      );
    } else if (row.type === "matched_accounts") {
      const contacts = row.leftContactToplist.map((contact: ContactType) => ({
        ...contact,
        opports: contact.opportunity_count,
      }));
      setContacts(contacts);
      const unspecifiedContacts = contacts.filter(
        (item: $TSFixMe) => !Boolean(item.jobtitle)
      ).length;
      setUnspecifiedText(
        intl.formatMessage(i18n.unspecified, {
          value: unspecifiedContacts,
        })
      );
    } else {
      const service = new JSONAPIService(accountType);
      service
        .get(row.id, {
          filters: {
            partnership: row.partnershipId,
          },
          fields: {
            [accountType]: ["left_contacts_data"],
          },
        })
        .then(({ data }: $TSFixMe) => {
          const contacts = data.data.attributes.left_contacts_data;
          setContacts(contacts);
          const unspecifiedContacts = contacts.filter(
            (item: $TSFixMe) => !Boolean(item.jobtitle)
          ).length;
          setUnspecifiedText(
            intl.formatMessage(i18n.unspecified, {
              value: unspecifiedContacts,
            })
          );
        });
    }
  };

  return (
    <BaseContactsCell
      value={value}
      label={
        <FormattedHTMLMessage
          {...i18n.title}
          values={{ value, accountName: row.leftName }}
        />
      }
      contacts={contacts}
      loadContacts={loadContacts}
      unspecifiedText={unspecifiedText}
    />
  );
};

export default LeftContactsCell;

// Helpers

export const isLeftContactsCell = ({ fieldName }: CellProps) => {
  return fieldName === AccountMappingStandardFieldName.leftContactCount;
};

// I18N

const i18n = defineMessages({
  title: {
    id: "crm.AccountMapping.cellFormatters.LeftContactsCell.title",
    defaultMessage:
      "{value, plural, one {1 contact at <b>{accountName}:</b>} other {# contacts at <b>{accountName}:</b>}}",
  },
  unspecified: {
    id: "crm.AccountMapping.cellFormatters.LeftContactsCell.unspecified",
    defaultMessage: "{value} unspecified",
  },
});
