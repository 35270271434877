import { useContext } from "react";

import OperatorAddBtn from "./OperatorAddBtn";
import { ParentOperatorContext } from "./ParentOperatorContext";
import { SWQLBooleanOperator } from "./SWQLTypes";

type Props = {
  onAddNode: (operator: SWQLBooleanOperator) => void;
};

const OperatorAddWidget = ({ onAddNode }: Props) => {
  const parentOperator = useContext(ParentOperatorContext);
  return parentOperator ? (
    <OperatorAddBtn
      operator={parentOperator === "and" ? "or" : "and"}
      handleClick={onAddNode}
    />
  ) : (
    <>
      <OperatorAddBtn operator="and" handleClick={onAddNode} />
      <OperatorAddBtn operator="or" handleClick={onAddNode} />
    </>
  );
};

export default OperatorAddWidget;
