import JSONAPIService from "./JSONAPIService";

export class CompanyAvatarService {
  companiesService: JSONAPIService;
  partnershipsService: JSONAPIService;

  constructor() {
    this.companiesService = new JSONAPIService("companies");
    this.partnershipsService = new JSONAPIService("partnerships");
  }

  async getByCompanyId(companyId: number): Promise<string | null> {
    const key = `avatar/company/${companyId}`;
    const cachedValue = localStorage.getItem(key);
    if (cachedValue !== null) {
      return cachedValue ? String(cachedValue) : null;
    }
    try {
      const response = await this.companiesService.get(companyId, {
        fields: { companies: ["avatar_url", "domain_name"] },
      });
      const attributes = response.data.data.attributes ?? {};
      const url = attributes.avatar_url
        ? String(attributes.avatar_url)
        : attributes.domain_name
        ? this.getClearbitURL(String(attributes.domain_name))
        : null;
      if (url) {
        localStorage.setItem(key, url);
        return url;
      }
    } catch {}
    return null;
  }

  async getForPartner(
    companyId: number | null,
    partnershipId: number
  ): Promise<string | null> {
    if (companyId !== null) {
      const urlFromCompany = await this.getByCompanyId(companyId);
      if (urlFromCompany !== null) {
        return urlFromCompany;
      }
    }
    const key = `avatar/partner/${partnershipId}`;
    const cachedValue = localStorage.getItem(key);
    if (cachedValue !== null) {
      return cachedValue ? String(cachedValue) : null;
    }
    try {
      const response = await this.partnershipsService.get(partnershipId, {
        fields: { partnerships: ["requested_company_domain"] },
      });
      const attributes = response.data.data.attributes ?? {};
      if (attributes.requested_company_domain) {
        const url = this.getClearbitURL(
          String(attributes.requested_company_domain)
        );
        localStorage.setItem(key, url);
        return url;
      }
    } catch {}
    return null;
  }

  getClearbitURL(domain: string): string {
    return `https://logo.clearbit.com/${domain}`;
  }
}
