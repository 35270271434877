import { T } from "components/ui/Typography";
import { ReactNode } from "react";
import { FormattedMessage, MessageDescriptor } from "react-intl";
import { Link } from "react-router-dom";

import { sharedCrmBaseSidePanelStyles } from "./BaseSidePanel";

type LinkElement = {
  i18n: MessageDescriptor;
  url: string;
  icon: ReactNode;
};

export type Props = {
  i18nTitle: MessageDescriptor;
  i18nSubtitle?: MessageDescriptor | null;
  customSubtitle?: ReactNode;
  linkElementList?: LinkElement[] | null;
  extraSlot?: ReactNode;
  noTopBorderRadius?: boolean;
};

export const HelpBox = ({
  i18nTitle,
  i18nSubtitle = null,
  customSubtitle = null,
  linkElementList = null,
  extraSlot = null,
  noTopBorderRadius = false,
}: Props) => {
  const { cx, classes } = sharedCrmBaseSidePanelStyles();

  return (
    <div
      className={cx(classes.helpBox, {
        [classes.noBorderRadiusTop]: noTopBorderRadius,
      })}
    >
      <T bodyBig bold>
        <FormattedMessage {...i18nTitle} />
      </T>
      {i18nSubtitle && (
        <T className={classes.helpBoxSubtitle}>
          <FormattedMessage {...i18nSubtitle} />
        </T>
      )}
      {customSubtitle}
      {linkElementList && linkElementList.length > 0 && (
        <div className={classes.helpBoxLinkContainer}>
          {linkElementList.map((linkElement) => (
            <T className={classes.helpBoxLink}>
              <FormattedMessage
                {...linkElement.i18n}
                values={{
                  lnk: (chunks: string) => (
                    <Link target="_blank" to={linkElement.url ?? "#"}>
                      {chunks}
                    </Link>
                  ),
                  a: (chunks: string) => (
                    <a
                      target="_blank"
                      href={linkElement.url ?? "#"}
                      rel="noopener noreferrer"
                    >
                      {chunks}
                    </a>
                  ),
                }}
              />
              {linkElement.icon}
            </T>
          ))}
        </div>
      )}
      {extraSlot}
    </div>
  );
};
