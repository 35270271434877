import {
  TagFilter,
  TagFilterType,
} from "components/ui/filters/filter/TagFilter";
import Record from "models/Record";
import { useCallback, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  selecTagsFilter,
  selectNotTagsFilters,
} from "redux/analytics/selectors";
import { updateView } from "redux/analytics/thunks";
import { AnalyticsOtherFilterFieldname } from "redux/analytics/types";
import { MatchFilterType } from "screens/Frontoffice/screens/DataTables/shared/types";

type Props = {
  disabled?: boolean;
};

const AnalyticsTagFilter = ({ disabled }: Props) => {
  const dispatch = useDispatch();
  const tagsFilter = useSelector(selecTagsFilter);
  const notTagsFilters = useSelector(selectNotTagsFilters);

  const currentValue = tagsFilter?.value.map((id: number) => String(id)) ?? [];

  const [tagFilterType, setTagFilterType] = useState<TagFilterType>(
    tagsFilter?.type === MatchFilterType.CONTAINS_ANY ? "or" : "and"
  );

  const setView = useCallback(
    (tags: Record[]) => {
      dispatch(
        updateView({
          filters: [
            ...notTagsFilters,
            {
              fieldname: AnalyticsOtherFilterFieldname.Tags,
              type:
                tagFilterType === "and"
                  ? MatchFilterType.CONTAINS_EXACT
                  : MatchFilterType.CONTAINS_ANY,
              value: tags.map((tag) => tag.id),
            },
          ],
        })
      );
    },
    [dispatch, tagFilterType, notTagsFilters]
  );

  return (
    <TagFilter
      disabled={disabled}
      value={currentValue}
      onChange={setView}
      toggleFilterValue={tagFilterType}
      onToggleFilterChange={setTagFilterType}
    />
  );
};
export default AnalyticsTagFilter;
