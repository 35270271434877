import CircularProgress from "@mui/material/CircularProgress";

const PageLoader = () => {
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        padding: 50,
      }}
    >
      <CircularProgress />
    </div>
  );
};

export default PageLoader;
