import { Box } from "@mui/material";
import { isFulfilled, isRejected } from "@reduxjs/toolkit";
import { MessageOutlined, Plus } from "components/icons";
import Button from "components/ui/Button";
import { NotificationStatus } from "components/ui/Notifications/NotificationSnackbar";
import { Enum, toString } from "config/routes/Pages";
import usePushNotification from "hooks/usePushNotification";
import useSegment from "hooks/useSegment";
import useUserProfile from "hooks/useUserProfile";
import _ from "lodash";
import PartnerConnection from "models/PartnerConnection";
import Partnership from "models/Partnership";
import Record from "models/Record";
import { useCallback, useState } from "react";
import { defineMessages } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { updateAttributesOnRecord } from "redux/api/actions";
import { create, retreive } from "redux/api/thunks";
import { APICollection } from "redux/api/typing";
import { NotificationActionType } from "redux/notifications/typing";
import { selectPartnerConnections } from "redux/pipeline/selectors";
import { RevealStore } from "redux/typing";
import {
  crmAccountsFields,
  crmAccountsIncluded,
} from "screens/Frontoffice/screens/DataTables/shared/utils";
import { JSONAPIResponse } from "services/types";
import { PipelineEvent, PipelineItemSource } from "tracking";

type Props = {
  account: Record<"crm_accounts">;
  disabled?: boolean;
  partnership?: Partnership;
};

export const AddToPipelineButton = ({
  account,
  disabled,
  partnership,
}: Props) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const { partnerConnections } = useSelector(selectPartnerConnections);
  // TODO: remove this when fully migrated to nearbound_accounts, then account will be always nearbound_account
  // but now it's crm_account, so we need to find nearbound_account by rawCompanyId from the store
  const nearboundAccount = Object.values(
    useSelector((state: RevealStore) =>
      _.get(state, "api.entities.nearbound_accounts", {})
    ) as APICollection<Record>
  ).find((item) => item.rawCompanyId === account.id);
  const activePartnerConnections = partnerConnections.filter(
    (item) =>
      item.partnershipId === partnership?.id && item.rawCompanyId === account.id
  );
  const hasPartnerConnection = activePartnerConnections.length > 0;
  const mostRecentlyUpdatedPartnerConnection = activePartnerConnections.reduce(
    (acc, curr) => (curr.updatedAt > acc.updatedAt ? curr : acc),
    activePartnerConnections[0]
  );

  const { profile } = useUserProfile();
  const pushNotification = usePushNotification();
  const { track } = useSegment();

  const redirectToPartnerConnection = () => {
    history.push({
      search: `?discussion=${mostRecentlyUpdatedPartnerConnection.id}`,
    });
  };

  const createPartnerConnection = async (e: MouseEvent) => {
    if (hasPartnerConnection || !partnership) {
      return;
    }
    setLoading(true);
    const result = await dispatch(
      create({
        type: "partner_connections",
        attributes: {
          user_id: profile.id,
          partnership_id: partnership.id,
          raw_company_id: account.id,
          source: PipelineItemSource.drawer,
        },
        options: {
          include: ["discussion_participants"],
        },
      })
    );
    if (isFulfilled(result)) {
      const payload = result.payload as JSONAPIResponse<"partner_connections">;
      history.push({ search: `?discussion=${payload.data.id}` });
      pushNotification(
        {
          status: NotificationStatus.success,
          message: i18n.partnerConnectionCreated,
        },
        {
          accountName: account.name,
          partnershipId: partnership.id,
          partnerName: partnership.getPartner(profile).name,
        },
        {
          type: NotificationActionType.addedToPipelineActions,
          partnerConnections: [new PartnerConnection(payload.data)],
          rawCompanies: [nearboundAccount ?? account],
        }
      );
      if (!!nearboundAccount) {
        dispatch(
          updateAttributesOnRecord(nearboundAccount, {
            partnerDiscussionFor: [
              ...nearboundAccount.partnerDiscussionFor,
              {
                partner_connection_id: Number(payload.data.id),
                partner_name: payload.data.attributes?.partner_name,
                partnership_id: payload.data.attributes?.partnership_id,
              },
            ],
          })
        );
      } else {
        await loadAccount();
      }
      track(PipelineEvent.addToPipeline, {
        from: toString(Enum.MyAccounts),
        bulk: false,
        number_created: 1,
      });
    }
    if (isRejected(result)) {
      pushNotification(i18n.partnerConnectionCreationFailed);
    }
    setLoading(false);
  };

  const loadAccount = useCallback(async () => {
    setLoading(true);
    await dispatch(
      retreive({
        id: account.id,
        type: "crm_accounts",
        options: {
          fields: crmAccountsFields,
          include: crmAccountsIncluded,
        },
      })
    );
    setLoading(false);
  }, [dispatch, account.id]);

  if (hasPartnerConnection) {
    return (
      <Button
        disabled={disabled || loading}
        loading={loading}
        LeftIcon={MessageOutlined}
        label={i18n.openCollab}
        onClick={redirectToPartnerConnection}
        size="small"
        variant="tertiary"
      />
    );
  }

  return (
    <Box>
      <Button
        disabled={disabled || loading}
        loading={loading}
        LeftIcon={Plus}
        label={i18n.addToPipeline}
        onClick={createPartnerConnection}
        size="small"
      />
    </Box>
  );
};

const i18n = defineMessages({
  addToPipeline: {
    id: "Ecosystem.AddToPipelineButton.addToPipeline",
    defaultMessage: "Add collab",
  },
  openCollab: {
    id: "Ecosystem.AddToPipelineButton.openCollab",
    defaultMessage: "Open collab",
  },
  partnerConnectionCreated: {
    id: "Ecosystem.AddToPipelineButton.partnerConnectionCreated",
    defaultMessage:
      "{accountName} x {partnerName} has been successfully added to Collaborate",
  },
  partnerConnectionCreationFailed: {
    id: "Ecosystem.AddToPipelineButton.partnerConnectionCreationFailed",
    defaultMessage:
      "Something went wrong, account could not be added to Collaborate",
  },
});
