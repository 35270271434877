import ToggleBtnGroup from "components/ui/ToggleBtnGroup";
import useSelectorWithDeepEquality from "hooks/useSelectorWithDeepEquality";
import { useEffect, useState } from "react";
import { defineMessages, FormattedMessage } from "react-intl";
import { useDispatch } from "react-redux";
import { selectIsAllParticipantsView } from "redux/pipeline/selectors";
import { setViewFilter } from "redux/pipeline/thunks";

import { usePipelineToggleFilterStyles } from "../constants";

enum ViewType {
  USER = "USER",
  ALL = "ALL",
}

const viewTypeToBoolean = {
  [ViewType.USER]: false,
  [ViewType.ALL]: true,
};

const ViewFilter = () => {
  const { classes } = usePipelineToggleFilterStyles();
  const dispatch = useDispatch();
  const isAllView = useSelectorWithDeepEquality(selectIsAllParticipantsView);
  const [loadingViewType, setLoadingViewType] = useState<ViewType>();

  const handleSetValue = (value: ViewType) => {
    setLoadingViewType(value);
    dispatch(setViewFilter(viewTypeToBoolean[value]));
  };

  useEffect(() => {
    if (!!loadingViewType && viewTypeToBoolean[loadingViewType] === isAllView) {
      setLoadingViewType(undefined);
    }
  }, [isAllView, loadingViewType]);

  return (
    <ToggleBtnGroup
      disabled={!!loadingViewType}
      items={[
        {
          label: <FormattedMessage {...i18n.viewTypeMine} />,
          value: ViewType.USER,
        },
        {
          label: <FormattedMessage {...i18n.viewTypeAll} />,
          value: ViewType.ALL,
        },
      ]}
      setValue={handleSetValue}
      value={loadingViewType ?? (isAllView ? ViewType.ALL : ViewType.USER)}
      classes={{ root: classes.toggleButtonGroup }}
    />
  );
};

export default ViewFilter;

// I18N

const i18n = defineMessages({
  viewTypeMine: {
    id: "MyPipeline.ViewFilter.viewTypeMine",
    defaultMessage: "Mine",
  },
  viewTypeAll: {
    id: "MyPipeline.ViewFilter.viewTypeAll",
    defaultMessage: "All",
  },
});
