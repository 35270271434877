import { NotificationItem } from "./typing";

export const selectFirstUnreadNotification = (state: {
  notifications: NotificationItem[];
}) =>
  state.notifications.find((notification) => notification.read === false) ??
  null;

export const selectUnreadNotifications = (state: {
  notifications: NotificationItem[];
}) => state.notifications.filter((notification) => notification.read === false);
