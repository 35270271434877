import Record from "models/Record";
import {
  ColumnConfigType,
  orderColumns,
} from "screens/Frontoffice/screens/DataTables/shared/types";

export enum AvailableReferredAccountsColumns {
  rightName = "rightName",
  rightReferralDate = "rightReferralDate",
  rightStatus = "rightStatus",
  rightOwnerName = "rightOwnerName",
  rightComment = "rightComment",
  leftStatus = "leftStatus",
  leftOwnerName = "leftOwnerName",
}

export const defaultReferredAccountsColumnKeys = Object.values(
  AvailableReferredAccountsColumns
);

export const mappingReferredAccountsConfig: {
  [key: string]: ColumnConfigType;
} = {
  [AvailableReferredAccountsColumns.rightName]: {
    key: AvailableReferredAccountsColumns.rightName,
    width: 220,
    frozen: true,
    removable: false,
    flex: 1,
  },
  [AvailableReferredAccountsColumns.rightReferralDate]: {
    key: AvailableReferredAccountsColumns.rightReferralDate,
    width: 120,
    frozen: false,
    removable: false,
  },
  [AvailableReferredAccountsColumns.rightStatus]: {
    key: AvailableReferredAccountsColumns.rightStatus,
    width: 128,
    frozen: false,
    removable: false,
  },
  [AvailableReferredAccountsColumns.rightOwnerName]: {
    key: AvailableReferredAccountsColumns.rightOwnerName,
    width: 172,
    frozen: false,
    removable: false,
  },
  [AvailableReferredAccountsColumns.rightComment]: {
    key: AvailableReferredAccountsColumns.rightComment,
    width: 200,
    frozen: false,
    removable: false,
  },
  [AvailableReferredAccountsColumns.leftStatus]: {
    key: AvailableReferredAccountsColumns.leftStatus,
    width: 200,
    frozen: false,
    removable: false,
    colSpan: ({ row }: { row: Record }) => {
      if (!row?.matchingRawCompanyId) {
        return 2;
      }
      return undefined;
    },
  },
  [AvailableReferredAccountsColumns.leftOwnerName]: {
    key: AvailableReferredAccountsColumns.leftOwnerName,
    width: 208,
    frozen: false,
    removable: false,
  },
};

export const applyReferredAccountsColumnOrder = (columns: ColumnConfigType[]) =>
  orderColumns(columns);

export const defaultReferredAccountsColumnConfig = (
  key: string,
  field: { label: string }
) => ({
  key,
  width: 8 * Math.max(field.label.length, 10),
  frozen: false,
});

export const defaultReferredAccountsColumns = applyReferredAccountsColumnOrder(
  defaultReferredAccountsColumnKeys.map(
    (key) => mappingReferredAccountsConfig[key]
  )
);
