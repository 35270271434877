import { createSvgIcon } from "@mui/material/utils";

import { SvgIcon } from "./types";

const displayName = "TrashThin";

const TrashThin: SvgIcon = createSvgIcon(
  // Using transform to enlarge from provided viewbox="0 0 12 12"
  // to default "0 0 24 24" from `createSvgIcon` -> `SvgIcon`.
  <g fill="none" transform="matrix(2,0,0,2,0,0)">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5.03558 0.65625C4.15538 0.65625 3.44183 1.3698 3.44183 2.25V2.53125H2.25003H1.73207H1.59375C1.33487 2.53125 1.125 2.74112 1.125 3C1.125 3.25888 1.33487 3.46875 1.59375 3.46875H1.82582L2.4323 9.53358C2.51377 10.3483 3.19935 10.9688 4.01814 10.9688H7.98192C8.80071 10.9688 9.48629 10.3483 9.56776 9.53358L10.1742 3.46875H10.4062C10.6651 3.46875 10.875 3.25888 10.875 3C10.875 2.74112 10.6651 2.53125 10.4062 2.53125H10.268H9.75003H8.55791V2.25C8.55791 1.3698 7.84436 0.65625 6.96415 0.65625H5.03558ZM7.62041 2.53125V2.25C7.62041 1.88756 7.32659 1.59375 6.96415 1.59375H5.03558C4.67315 1.59375 4.37933 1.88756 4.37933 2.25V2.53125H7.62041ZM3.44183 3.46875H2.76799L3.36515 9.4403C3.3987 9.77578 3.68099 10.0312 4.01814 10.0312H7.98192C8.31907 10.0312 8.60137 9.77577 8.63491 9.4403L9.23207 3.46875H8.55791H8.08916H3.91058H3.44183ZM4.96875 4.875C5.22763 4.875 5.4375 5.08487 5.4375 5.34375V8.15625C5.4375 8.41513 5.22763 8.625 4.96875 8.625C4.70987 8.625 4.5 8.41513 4.5 8.15625V5.34375C4.5 5.08487 4.70987 4.875 4.96875 4.875ZM7.5 5.34375C7.5 5.08487 7.29013 4.875 7.03125 4.875C6.77237 4.875 6.5625 5.08487 6.5625 5.34375V8.15625C6.5625 8.41513 6.77237 8.625 7.03125 8.625C7.29013 8.625 7.5 8.41513 7.5 8.15625V5.34375Z"
      fill="currentColor"
    />
  </g>,
  displayName
);

TrashThin.displayName = displayName;
export default TrashThin;
