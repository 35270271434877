import Grid from "@mui/material/Grid";
import { isFulfilled } from "@reduxjs/toolkit";
import axios from "axios";
import { CircleWarningOutlined, Sandglass } from "components/icons";
import Button from "components/ui/Button";
import { T } from "components/ui/Typography";
import {
  APPROVAL_REQUEST_PERIOD_IN_MILLIS,
  LICENSE_REQUEST_PERIOD_IN_MILLIS,
} from "config/constants";
import usePushNotification from "hooks/usePushNotification";
import useSegment from "hooks/useSegment";
import useUserProfile from "hooks/useUserProfile";
import { makeStyles } from "makeStyles";
import User from "models/User";
import { useEffect, useState } from "react";
import {
  defineMessages,
  FormattedMessage,
  MessageDescriptor,
  useIntl,
} from "react-intl";
import { useDispatch } from "react-redux";
import { rawPost } from "redux/api/thunks";
import { setProfile } from "redux/user/actions";
import { fetchProfileInBackground } from "redux/user/thunks";
import UserService from "services/UserService";
import { UserEvent } from "tracking";

type Props = {
  errorMessage?: MessageDescriptor;
};

const NewPageForbidden = ({ errorMessage }: Props) => {
  const intl = useIntl();
  const { track } = useSegment();
  const { profile } = useUserProfile();
  const pushNotification = usePushNotification();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState<boolean>(false);
  const [licenseRequested, setLicenseRequested] = useState<boolean>(false);

  const hasLicense = profile.hasLicense;
  const { classes } = useStyles({ hasLicense });
  const approvalNeeded =
    Boolean(profile.company) && !profile.roleAssignments?.length;

  const canRequestApproval =
    approvalNeeded &&
    (profile.lastPermissionRequest === null ||
      Date.now() - new Date(profile.lastPermissionRequest).getTime() >
        APPROVAL_REQUEST_PERIOD_IN_MILLIS);
  const canRequestLicense =
    !approvalNeeded &&
    !hasLicense &&
    !licenseRequested &&
    (profile.licenseRequestedAt === null ||
      Date.now() - new Date(profile.licenseRequestedAt).getTime() >
        LICENSE_REQUEST_PERIOD_IN_MILLIS);

  const title = approvalNeeded
    ? i18n.titlePendingApproval
    : !hasLicense
    ? i18n.titleNoLicense
    : i18n.titleForbidden;

  const subtitle = approvalNeeded
    ? i18n.subtitlePendingApproval
    : !hasLicense
    ? i18n.subtitleNoLicense
    : null;

  const requestAccess = async () => {
    if (canRequestApproval) {
      setLoading(true);
      const service = new UserService();
      try {
        const response = await service.requestPermissions();
        pushNotification(i18n.notifyRequestPermissionsSent, {
          companyName: profile.company.name,
        });
        dispatch(setProfile(User.fromResponse(response)));
      } catch (error) {
        if (axios.isAxiosError(error)) {
          const status = error.response?.status;
          switch (status) {
            case 403:
              pushNotification(i18n.notifyRequestPermissionsErrorAlready);
              break;
            default:
              pushNotification(i18n.notifyRequestPermissionsErrorOthers);
          }
        }
      }
      setLoading(false);
    }
  };

  const requestLicense = async () => {
    if (!canRequestLicense) {
      return;
    }

    setLoading(true);
    const result = await dispatch(
      rawPost({
        type: "license_requests",
        path: "",
        payload: {
          data: {
            type: "license_requests",
            attributes: {},
          },
        },
      })
    );
    if (isFulfilled(result)) {
      setLicenseRequested(true);
      dispatch(fetchProfileInBackground());
      pushNotification(i18n.notifyRequestLicenseSent, {
        companyName: profile.company.name,
      });
    } else {
      pushNotification("default_error");
    }
    setLoading(false);
  };

  const handleClickDiscoverReveal = () => {
    window.open("https://reveal.co/product", "_blank");
  };

  useEffect(() => {
    if (!approvalNeeded && !hasLicense) {
      track(UserEvent.noLicensePage);
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Grid container justifyContent="center" className={classes.root}>
      <Grid container item direction="column" alignItems="center">
        <Grid item>
          {approvalNeeded ? (
            <Sandglass className={classes.icon} />
          ) : !hasLicense ? (
            <img
              className={classes.noLicenseImg}
              src="/images/no-license-img.svg"
              alt="no-license"
            />
          ) : (
            <CircleWarningOutlined className={classes.icon} />
          )}
        </Grid>
        <Grid item>
          <T h2={!hasLicense} h3={hasLicense} bold>
            <FormattedMessage {...(errorMessage ?? title)} />
          </T>
        </Grid>
        {subtitle && (
          <Grid item>
            <T bodyBig={!hasLicense}>
              <FormattedMessage
                {...subtitle}
                values={{ companyName: profile?.company?.name }}
              />
            </T>
          </Grid>
        )}
        {approvalNeeded && (
          <Grid item className={classes.actions}>
            <Button
              label={intl.formatMessage(
                !canRequestApproval
                  ? i18n.requestPending
                  : profile.lastPermissionRequest === null
                  ? i18n.requestAccess
                  : i18n.requestAccessAgain
              )}
              variant="primary"
              disabled={!canRequestApproval}
              onClick={requestAccess}
              loading={loading}
            />
          </Grid>
        )}
        {!approvalNeeded && !hasLicense && (
          <Grid item className={classes.actions}>
            <Button
              label={intl.formatMessage(
                !canRequestLicense
                  ? i18n.requestLicensePending
                  : profile.licenseRequestedAt === null
                  ? i18n.requestLicense
                  : i18n.requestLicenseAgain
              )}
              variant="primary"
              disabled={!canRequestLicense}
              onClick={requestLicense}
              loading={loading}
            />
            <Button
              label={intl.formatMessage(i18n.discoverReveal)}
              variant="tertiary"
              onClick={handleClickDiscoverReveal}
            />
          </Grid>
        )}
      </Grid>
    </Grid>
  );
};

export default NewPageForbidden;

/// CSS

const useStyles = makeStyles<{ hasLicense: boolean }>()(
  (theme, { hasLicense }) => ({
    root: {
      height: "100%",
      alignItems: "center",
      background: "linear-gradient(to bottom, #F6F2E8 0%, #FAFAF6 40%)",
      marginTop: hasLicense ? 0 : -40,
    },
    icon: {
      borderRadius: 80,
      color: theme.palette.ivory,
      padding: 10,
      width: 48,
      height: 48,
      background: "linear-gradient(180deg, #0A151B 50%, #7D3537 100%)",
      marginBottom: theme.spacing(2),
    },
    actions: {
      display: "flex",
      gap: 4,
      marginTop: theme.spacing(3),
    },
    noLicenseImg: {
      marginBottom: theme.spacing(5),
    },
  })
);

/// I18N

const i18n = defineMessages({
  titleNoLicense: {
    id: "Partners.NewPageForbidden.titleNoLicense",
    defaultMessage: "No license",
  },
  titlePendingApproval: {
    id: "Partners.NewPageForbidden.titlePendingApproval",
    defaultMessage: "Pending approval",
  },
  titleForbidden: {
    id: "NewPageForbidden.TitleForbidden",
    defaultMessage: "You don't have access to this page",
  },
  subtitleNoLicense: {
    id: "Partners.NewPageForbidden.subtitleNoLicense",
    defaultMessage:
      "You need to request a license from a company admin to access {companyName}’s workspace on Reveal.",
  },
  subtitlePendingApproval: {
    id: "NewPageForbidden.subtitlePendingApproval",
    defaultMessage:
      "You need to be approved by a company admin to access {companyName}’s workspace on Reveal.",
  },
  requestPending: {
    id: "NewPageForbidden.RequestedPending",
    defaultMessage: "Access requested",
  },
  requestAccess: {
    id: "NewPageForbidden.RequestAccess",
    defaultMessage: "Request access",
  },
  requestAccessAgain: {
    id: "NewPageForbidden.requestAccessAgain",
    defaultMessage: "Resend access request",
  },
  requestLicense: {
    id: "NewPageForbidden.RequestLicense",
    defaultMessage: "Request a license",
  },
  requestLicensePending: {
    id: "NewPageForbidden.requestLicensePending",
    defaultMessage: "License requested",
  },
  requestLicenseAgain: {
    id: "NewPageForbidden.requestLicenseAgain",
    defaultMessage: "Resend license request",
  },
  notifyRequestLicenseSent: {
    id: "NewPageForbidden.notifyRequestLicenseSent",
    defaultMessage: `A license request was sent to {companyName}'s administrators. Please wait for their approval`,
  },
  notifyRequestPermissionsSent: {
    id: "NewPageForbidden.notifyRequestPermissionsSent",
    defaultMessage: `A permission request was sent to {companyName}'s administrators. Please wait for their approval`,
  },
  notifyRequestPermissionsErrorAlready: {
    id: "NewPageForbidden.notifyRequestPermissionsErrorAlready",
    defaultMessage: "You already sent a request within the last 24 hours.",
  },
  notifyRequestPermissionsErrorOthers: {
    id: "NewPageForbidden.notifyRequestPermissionsErrorOthers",
    defaultMessage: "An error occurred, please retry later.",
  },
  discoverReveal: {
    id: "NewPageForbidden.discoverReveal",
    defaultMessage: "Discover Reveal",
  },
});

export const _private = {
  i18n,
};
