import Record from "./Record";
import { JSONAPIResourceOrRecord } from "./types";

// Account Mapping Enum

export enum AccountMappingStatus {
  Requested = "requested",
  Revoked__Internal = "revoked",
  PendingApproval__Internal = "pending_approval",
  Requested__Internal = "requested",
  RequestedByCompany = "requestedByCompany",
  RequestedByPartner = "requestedByPartner",
  Active = "active",
  Inactive = "inactive",
}

// Account Mapping Share Enum

export enum AccountMappingShare {
  Always = 1,
  CommonCustomers = 2,
  Never = 3,
}

export enum NewLeadsShare {
  Always = 1,
  Never = 2,
}

const isValidStatus = (value: any): value is AccountMappingStatus =>
  Object.values(AccountMappingStatus).includes(value);

const isValidSharing = (value: any): value is AccountMappingShare =>
  Object.values(AccountMappingShare).includes(value);

const isValidNewLeadsSharing = (value: any): value is NewLeadsShare =>
  Object.values(NewLeadsShare).includes(value);

export default class AccountMappingSettings extends Record {
  status: AccountMappingStatus;
  initApproved: AccountMappingShare;
  destApproved: AccountMappingShare;
  initSharingNewLeads: NewLeadsShare;
  destSharingNewLeads: NewLeadsShare;

  constructor(data: JSONAPIResourceOrRecord<"account_mapping_settings">) {
    super(data);
    this.status = isValidStatus(this.attributes.status)
      ? this.attributes.status
      : AccountMappingStatus.Inactive;
    this.initApproved = isValidSharing(this.attributes.init_approved)
      ? this.attributes.init_approved
      : AccountMappingShare.Never;
    this.destApproved = isValidSharing(this.attributes.dest_approved)
      ? this.attributes.dest_approved
      : AccountMappingShare.Never;
    this.initSharingNewLeads = isValidNewLeadsSharing(
      this.attributes.init_sharing_new_leads
    )
      ? this.attributes.init_sharing_new_leads
      : NewLeadsShare.Never;
    this.destSharingNewLeads = isValidNewLeadsSharing(
      this.attributes.dest_sharing_new_leads
    )
      ? this.attributes.dest_sharing_new_leads
      : NewLeadsShare.Never;
  }
}
