import Grid from "@mui/material/Grid";
import { ClassNameMap } from "@mui/material/styles";
import { T } from "components/ui/Typography";
import { makeStyles } from "makeStyles";
import { useMergedClasses } from "tss-react";

import CompanyAvatar from "./avatars/CompanyAvatar";

type Props = {
  section: React.ReactNode;
  label?: React.ReactNode;
  avatar?: string;
  variant?: "h1" | "h2" | "h3" | "h4" | "h5" | "h6";
  gutter?: boolean;
  subTitle?: React.ReactNode;
  classes?: ClassNameMap;
};

const Title = ({
  section,
  label,
  avatar = "",
  variant = "h1",
  gutter = true,
  subTitle,
  classes: newClasses = {},
}: Props) => {
  const { classes: baseClasses } = useStyles({ gutter });
  const classes = useMergedClasses(baseClasses, newClasses);

  return (
    <Grid item className={classes.gutter}>
      <Grid item xs={12} className={classes.header}>
        {avatar && <CompanyAvatar src={avatar} />}
        <div className={classes.groupTitle}>
          <T h2 bold oldVariant={variant} className={classes.section}>
            {section}
          </T>
          {label && (
            <>
              <div className={classes.arrowRight} />
              <T h2 bold oldVariant={variant} className={classes.label}>
                {label}
              </T>
            </>
          )}
        </div>
      </Grid>
      {subTitle && (
        <Grid item xs={12}>
          {subTitle}
        </Grid>
      )}
    </Grid>
  );
};

export default Title;

// CSS

const useStyles = makeStyles<{ gutter: boolean }>()((theme, { gutter }) => ({
  header: {
    display: "flex",
    alignItems: "center",
    columnGap: 12,
  },
  gutter: {
    marginBottom: theme.spacing(gutter ? 2 : 0),
  },
  groupTitle: {
    display: "flex",
    alignItems: "center",
    minWidth: 200,
  },
  section: {},
  arrowRight: {
    width: 0,
    height: 0,
    borderBottom: "7px solid transparent",
    borderTop: "7px solid transparent",
    borderLeft: `7px solid ${theme.palette.primary.main}`,
    marginLeft: 10,
    marginRight: 10,
  },
  label: {},
}));
