import { COMPANY_TYPE_LABELS, CompanyType } from "models/Company";
import { IntlShape } from "react-intl";
import { AvailableDirectoryColumns } from "redux/directory/defaults";

export const isDirectoryBusinessTypeColumn = (fieldName: string) =>
  fieldName === AvailableDirectoryColumns.businessType;

export const directoryBusinessTypeValueFormatter = (
  row: any,
  intl: IntlShape
) => {
  return row?.businessType
    ? intl.formatMessage(
        COMPANY_TYPE_LABELS[row.businessType as CompanyType].props
      )
    : "--";
};
