import { Paper } from "@mui/material";
import withRecord from "components/hoc/withRecord";
import { ArrowRight } from "components/icons";
import Button from "components/ui/Button";
import { crmProviders, ProviderType } from "config/constants";
import getFirstValueFromURL from "helpers/getFirstValueFromURL";
import SourceConfiguration from "models/SourceConfiguration";
import { defineMessages } from "react-intl";
import { useHistory, useLocation } from "react-router-dom";
import {
  Props as ColumnMappingProps,
  useColumnMappingWidget,
} from "screens/Frontoffice/shared/components/SourceSettingsDialog/components/CsvColumnMappingWidget";
import { updateHistoryUpdatedSource } from "screens/Frontoffice/shared/components/SourceSettingsDialog/helpers/utils";
import { isCrmFileType } from "screens/Frontoffice/shared/helpers/types";

import { BaseContent, sharedStyles } from "./BaseContent";
import { ContentHeaderWithLogo } from "./ContentHeaderWithLogo";

export const ColumnMappingContent = () => {
  const location = useLocation();
  const provider = getFirstValueFromURL(location, "provider");
  const integrationId = getFirstValueFromURL(location, "integration");
  const config = provider ? crmProviders[provider as ProviderType] : undefined;

  if (!integrationId) {
    throw new Error("No integration ID");
  }
  if (!provider) {
    throw new Error("No provider");
  }

  return (
    <BaseContent>
      <ContentHeaderWithLogo
        imgSrc={config?.logo as string}
        imgAlt={config?.name as string}
        i18nTitle={i18n.title}
        i18nSubtitle={i18n.subtitle}
      />
      <BaseColumnMappingWidget
        integrationId={+integrationId}
        recordId={+integrationId}
        sourceType={provider}
      />
    </BaseContent>
  );
};

type BaseProps = Omit<ColumnMappingProps, "handleClose">;

const BaseColumnMappingWidget = withRecord<SourceConfiguration, BaseProps>(
  "source_configurations",
  {
    allowEarlyDisplay: true,
  }
)((props: BaseProps) => {
  const { sourceType } = props;
  const location = useLocation();
  const history = useHistory();
  if (!isCrmFileType(sourceType)) {
    throw Error(`Cannot render with sourceType ${sourceType}`);
  }
  const { canSave, content, handleSave, saving } = useColumnMappingWidget(
    props
  );
  const { cx, classes } = sharedStyles({});

  const handleSaveMapping = async () => {
    await handleSave();
    const updatedLocation = updateHistoryUpdatedSource(history, location);
    history.replace({
      ...updatedLocation,
      hash: "status-rules",
    });
  };

  return (
    <>
      <Paper className={cx(classes.formPaper, classes.fullWidth)}>
        {content}
      </Paper>
      <div className={classes.action}>
        <Button
          disabled={!canSave()}
          label={i18n.validate}
          loading={saving}
          RightIcon={ArrowRight}
          onClick={handleSaveMapping}
        />
      </div>
    </>
  );
});

const i18n = defineMessages({
  back: {
    id: "ColumnMappingContent.back",
    defaultMessage: "Back",
  },
  subtitle: {
    id: "ColumnMappingContent.subtitle",
    defaultMessage:
      "Please help us understand how you map columns in your data source to company fields.",
  },
  title: {
    id: "ColumnMappingContent.title",
    defaultMessage: "Define your column mapping",
  },
  validate: {
    id: "ColumnMappingContent.validate",
    defaultMessage: "Validate",
  },
});
