import React from "react";
import { AccountMappingResource } from "redux/accountMapping/types";

import { AccountMappingViewContext } from "../shared/types";

const ViewContext = React.createContext<AccountMappingViewContext>({
  partnershipId: null,
  accountType: AccountMappingResource.matches,
  setAccountType: (value: AccountMappingResource) => {},
  lastVisitedAt: null,
  partnerName: null,
  partnerAvatarUrl: null,
});

export default ViewContext;
