import { AnyAction, PayloadAction, ThunkDispatch } from "@reduxjs/toolkit";
import errorMessages from "i18n/errorMessages";
import { produce } from "immer";
import _ from "lodash";
import { pushNotification } from "redux/notifications/actions";
import { JSONAPIListResponse, JSONAPIResponse } from "services/types";

import { storeRecords } from "./reducers";
import {
  APIEntities,
  DESTINATION_COMPANY_NOT_FOUND_ERROR_MESSAGE,
  ERROR_CODE,
  ERROR_CODE_TO_ERROR_TRANSLATION,
  INVITATION_LIMIT_ERROR_MESSAGE,
  INVITE_ALREADY_SENT_IN_24_HOURS,
} from "./typing";

export const catchKnownErrors = (
  error: any,
  dispatch: ThunkDispatch<unknown, unknown, AnyAction>,
  rejectWithValue: any
) => {
  if (error.response) {
    if (
      error.response.data.errors === DESTINATION_COMPANY_NOT_FOUND_ERROR_MESSAGE
    ) {
      dispatch(pushNotification(DESTINATION_COMPANY_NOT_FOUND_ERROR_MESSAGE));
      throw new Error(error.response.data.errors);
    }
    if (error.response.data.errors === INVITE_ALREADY_SENT_IN_24_HOURS) {
      dispatch(pushNotification(INVITE_ALREADY_SENT_IN_24_HOURS));
      throw new Error(error.response.data.errors);
    }
    const errorCode: string = _.get(error.response, "data.errors[0].code", "");
    const errorDetail: string = _.get(
      error.response,
      "data.errors[0].detail",
      ""
    );
    let errorDetailJson = null;
    try {
      errorDetailJson = JSON.parse(errorDetail);
    } catch (error) {}
    if (errorCode) {
      const errorTranslation =
        ERROR_CODE_TO_ERROR_TRANSLATION[errorCode as ERROR_CODE];
      if (errorTranslation) {
        dispatch(
          pushNotification(errorTranslation, {
            companyName: errorDetailJson?.target_company_name,
            name: `${errorDetailJson?.target_first_name} ${errorDetailJson?.target_last_name}`,
          })
        );
      }
      if (errorCode === ERROR_CODE.INVITE_PARTNERSHIP_EXISTS) {
        throw rejectWithValue(
          `/partnerships/${errorDetailJson.partnership_id}#account-mapping`
        );
      }
      if (
        errorCode === ERROR_CODE.INVITE_PENDING_CURRENT_EMAIL ||
        errorCode === ERROR_CODE.INVITE_PENDING_OTHER_EMAIL
      ) {
        throw rejectWithValue(
          `/partnerships/${errorDetailJson.partnership_id}#invitations`
        );
      }
    }
    if (errorDetail === INVITATION_LIMIT_ERROR_MESSAGE) {
      dispatch(pushNotification(errorMessages.invitation_limit_error));
      throw new Error(errorDetail);
    }
    if (error.response.status === 409) {
      throw new Error("409");
    }
  }
  throw error;
};

export const unwrapAsResourceID = (action: PayloadAction<JSONAPIResponse>) =>
  +action.payload.data?.id;

export const unwrapAsResourceIDs = (
  action: PayloadAction<JSONAPIListResponse>
) => action.payload.data.map((resource) => +resource.id);

export const generateRecords = (
  response: JSONAPIResponse | JSONAPIListResponse
) =>
  produce({} as APIEntities, (draft) =>
    // @ts-ignore Type instantiation is excessively deep and possibly infinite
    storeRecords(draft, { type: "any", payload: response })
  );
