export enum UserEvent {
  viewRegistration = "View Registration page",
  openUserLimitationPopUp = "User limitation pop up displayed",
  noLicensePage = "No licence page displayed",
  createdAccount = "Created account",
  everflowSignUp = "Everflow Sign-up",
}

export enum PartnersEvent {
  viewDashboard = "Viewed partnership dashboard",
  viewOverview = "Viewed overview",
  viewOverviewDemo = "Viewed overview Demo",
  viewAccountMapping = "Viewed account mapping",
  switchedMappingType = "Switched Mapping type",
  partnerDashboardFilter = "Partner Dashboard Status Filter",
  searchedPartner = "Searched Partners in Dashboard",
}

export enum PartnershipEvent {
  acceptedInvite = "Accepted invite",
  requestedAM = "Requested access to account mapping",
  cancelledAM = "Cancelled account mapping request",
  approvedAM = "Approved account mapping request",
  revokedAM = "Revoked account mapping access",
  requestedNP = "Requested access to new prospects",
  approvedNP = "Approved new prospects sharing request",
  cancelledNP = "Cancelled new prospects request",
  enabledAO = "Enabled overlap sharing",
  enabledNP = "Enabled new prospects sharing",
  disabledNP = "Disabled new prospects sharing",
  off = "Off",
  enabledByMe = "Off - Enabled by me",
  enabledByPartner = "Off - Enabled by partner",
  activated = "Activated",
  ghostCreation = "Created Offline Partnership",
}

export enum SuggestionFromCrmEvent {
  clickedSuggestedPartner = "Clicked - Suggested Partner from CRM",
  removedSuggestedPartner = "Removed - Suggested Partner from CRM",
  invited = "Invited - Suggested Partner from CRM",
}

export enum InviteFormEvent {
  invited = "Invited - Invite form",
  closed = "Closed - Invite form",
  clickedCcCheckbox = "Clicked CC checkbox",
}

export enum AccountMappingEvent {
  filteredUpdatesAM = "Filtered Updates Account Mapping",
  getIntroFromAMClicked = "Get Intro From AM Clicked",
}

export enum Mapping360Event {
  viewMapping360 = "Opened My accounts page",
  getIntroFrom360Clicked = "Get Intro From 360 Clicked",
  getIntroFrom360DrawerClicked = "Get Intro From 360 Drawer Clicked",
  exportDealInfluencersClicked = "Test export deal influencers",
  downloadNewProspectsWeeklyDigest = "Download new prospects weekly digest",
}

export enum PartnersPageSuggestionsEvent {
  CLICKED_INVITE = "Clicked invite - Partnerships suggestions",
  DISMISSED_INVITE = "Dismissed invite - Partnerships suggestions",
}

export enum PipelineEvent {
  tableEdit = "Edited Partner Connection",
  viewPipeline = "View Pipeline",
  addToPipeline = "Created Partner Connection",
  filteredOnPartner = "Filtered Pipeline Table by Partner",
  clickedFromWeeklyDigest = "Clicked Add from Weekly Digest",
  openColumnWidget = "Open Pipeline Column Widget",
  clickedSendMessage = "Clicked on send message",
  messageSent = "Sent message in drawer",
}

export enum PipelineItemSource {
  manual = 1, // default value
  accountMapping = 2,
  mapping360 = 3,
  weeklyDigest = 5,
  drawer = 9,
  newProspects = 10,
  newProspects360 = 11,
  newProspectDrawer = 12,
}

export enum PipelineTableEditAction {
  changePartner = "changePartner",
  setPartner = "setPartner",
  updateActivityDate = "updateActivityDate",
  updateStage = "updateStage",
  updateKind = "updateKind",
  linkOpportunity = "linkOpportunity",
  unlinkOpportunity = "unlinkOpportunity",
}

export enum DirectoryEvent {
  viewDirectory = "Viewed directory",
  requireHelp = "Require Help on Directory",
  filteredDirectory = "Filtered directory",
  searchedDirectory = "Searched directory",
  clickedInvite = "Clicked invite button on directory",
  continuedInvite = "Continued on invite clarification in directory",
  clickedConnectCrm = "Clicked on Connect your CRM alert",
  clickedSuggestionsTab = "Clicked on suggestions tab",
}

export enum PremiumPlanEvent {
  explorePlan = "Clicked explore plans (tooltip)",
  upgradeBtn = "Clicked on Upgrade button (navbar)",
  scaleBtn = "Clicked Unlock and Scale button",
  bannerLink = "Clicked link on update banner",
  goalBased360 = "Clicked unlock goal on Goal Based 360",
  incentiveDrawer360 = "Clicked 360 drawer incentive",
  integrationsGetIntegrationBtn = "Clicked Get Integrations box button",
  integrationsSlackBtn = "Clicked Slack Show me more button",
  integrationsCRMBtn = "Clicked CRM Show me more button",
  integrationsPartnerStackBtn = "Clicked PartnerStack Show me more button",
  integrationsRevealAPIBtn = "Clicked RevealAPI Show me more button",
  integrations3rdPartyBtn = "Clicked 3rdParty Show me more button",
}

export enum InviteURLEvent {
  linkCopied = "Invite URL copied by Reveal User",
  linkClicked = "Invite url clicked - User not signed in",
  signUp = "Invite URL clicked - User sign up",
  signIn = "Invite URL clicked - User sign in",
  invitationSent = "Invite URL - invitation sent",
  partnershipAccepted = "Invite URL - Accepted",
}

export enum AccountReferralEvent {
  clickedOnReferAccountsToPipeline = "Clicked on Refer Accounts to Pipeline",
  referredAccountsToPipeline = "Referred Accounts to Pipeline",
  addedReferredAccountsToPipeline = "Added Referred Accounts to Pipeline",
}

export enum NotificationEvent {
  clickedOnNotification = "Clicked on notification",
}

export enum TagsEvent {
  tagCreated = "Tag created",
  tagAssigned = "Tag assigned to partner",
  tagRemoved = "Tag removed from partner",
  filterByTag = "Filter by tag",
}

export enum TableEvent {
  selectedAccount = "Selected account",
  copiedData = "Copied data",
  copiedDataDisabled = "Tried to copy data (disabled)",
  openFilterWidget = "Open Filter Widget",
  openSortWidget = "Open Sort Widget",
  sortApplied = "Sort Applied",
  sortRemoved = "Sort Removed",
}

export enum AdvancedAnalyticsEvent {
  advancedAnalyticsActivated = "Advanced Analytics Activated",
  viewAnalyticsPage = "Opened Analytics page",
  openColumnWidget = "Open Analytics Column Widget",
  clickOnFavoriteCell = "Clicked on Favorite Cell",
  clickOnPartnerNameCell = "Clicked on Partner Name Cell",
  redirectToAccountMapping = "Redirect to Account Mapping",
  redirectToAccountSettings = "Redirect to Account Settings",
  expandRevenueMetrics = "Expand Revenue Metrics",
}

export enum IntegrationsEvent {
  clickOnCustomize = "Click Customize sync CRM",
}

export enum GetIntroTemplateEvent {
  ViewGetIntroTemplate = "Viewed Get Intro templates tab",
}

export enum GetStartedEvent {
  step1Completed = "Step 1 Completed",
  step2Clicked = "Step 2 Clicked",
  step3Clicked = "Step 3 Clicked",
}

export enum MeasureEvent {
  openedMeasure = "Opened Measure",
  appliedFilter = "Applied filter in measure",
  clickedIntoChart = "clicked into measure chart",
}
