import { createSvgIcon } from "@mui/material/utils";

import { SvgIcon } from "./types";

const displayName = "MessagePlus";

const MessagePlus: SvgIcon = createSvgIcon(
  // Using transform to enlarge from provided viewbox="0 0 16 16"
  // to default "0 0 24 24" from `createSvgIcon` -> `SvgIcon`.
  <g fill="none" transform="matrix(1.5,0,0,1.5,0,0)">
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M1.5 3.5C1.5 2.39543 2.39543 1.5 3.5 1.5H12.5C13.6046 1.5 14.5 2.39543 14.5 3.5V10.9615C14.5 12.0661 13.6046 12.9615 12.5 12.9615H9.352C9.13774 12.9615 8.92915 13.0304 8.75695 13.1578L5.72609 15.4018C5.57433 15.5142 5.37222 15.5315 5.20358 15.4465C5.03495 15.3616 4.92857 15.1888 4.92857 15V12.9615H3.5C2.39543 12.9615 1.5 12.0661 1.5 10.9615V3.5ZM3.5 2.5C2.94771 2.5 2.5 2.94771 2.5 3.5V10.9615C2.5 11.5138 2.94771 11.9615 3.5 11.9615H5.42857C5.70471 11.9615 5.92857 12.1854 5.92857 12.4615V14.0077L8.16191 12.3542C8.5063 12.0992 8.92349 11.9615 9.352 11.9615H12.5C13.0523 11.9615 13.5 11.5138 13.5 10.9615V3.5C13.5 2.94772 13.0523 2.5 12.5 2.5H3.5ZM8 4.25C8.27614 4.25 8.5 4.47386 8.5 4.75V6.75H10.5C10.7761 6.75 11 6.97386 11 7.25C11 7.52614 10.7761 7.75 10.5 7.75H8.5V9.75C8.5 10.0261 8.27614 10.25 8 10.25C7.72386 10.25 7.5 10.0261 7.5 9.75V7.75H5.5C5.22386 7.75 5 7.52614 5 7.25C5 6.97386 5.22386 6.75 5.5 6.75H7.5V4.75C7.5 4.47386 7.72386 4.25 8 4.25Z"
      fill="currentColor"
    />
  </g>,
  displayName
);

MessagePlus.displayName = displayName;
export default MessagePlus;
