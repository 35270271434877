import { createSvgIcon } from "@mui/material/utils";

import { SvgIcon } from "./types";

const displayName = "Excel";

const Excel: SvgIcon = createSvgIcon(
  <g fill="none" transform="matrix(1,0,0,1,-3,0)">
    <svg
      enable-background="new 0 0 30 30"
      id="Layer_1"
      version="1.1"
      viewBox="0 0 30 30"
      width="30px"
      xmlSpace="preserve"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <g>
        <polyline
          clip-rule="evenodd"
          fill="#08743B"
          fill-rule="evenodd"
          points="30,28.652 0,28.652 0,0 30,0 30,28.652  "
        />
        <polyline
          fill="#FFFFFF"
          points="23.211,21.771 18.186,21.771 14.756,16.922 11.154,21.771 6.11,21.771 12.371,13.895 7.313,7.007    12.413,7.007 14.781,10.752 17.302,7.007 22.488,7.007 17.189,13.895 23.211,21.771  "
        />
      </g>
    </svg>
  </g>,
  displayName
);
Excel.displayName = displayName;
export default Excel;
