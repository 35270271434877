import withUserProfile, {
  WithUserProfile,
} from "components/hoc/withUserProfile";
import Button from "components/ui/Button";
import { T } from "components/ui/Typography";
import { makeStyles } from "makeStyles";
import {
  defineMessages,
  FormattedHTMLMessage,
  FormattedMessage,
} from "react-intl";
import { useHistory } from "react-router";

export const RequestPartnerChangeDetails = ({ loggedIn }: WithUserProfile) => {
  const { classes } = useStyles();
  const history = useHistory();

  const handleRedirect = (url: string) => {
    history.push(url);
  };

  const endButton = loggedIn ? (
    <Button
      onClick={() => handleRedirect("/partnerships")}
      label={i18n.goToDashboard}
    />
  ) : (
    <Button
      onClick={() => handleRedirect("/login")}
      label={i18n.loggedOutLink}
    />
  );

  return (
    <div className={classes.root}>
      <>
        <T titleBig>
          <FormattedMessage {...i18n.title} />
        </T>
        <br />
        <T bodyBig>
          <FormattedHTMLMessage {...i18n.description} />
        </T>
        <br />
        <br />
        {endButton}
      </>
    </div>
  );
};

export default withUserProfile(RequestPartnerChangeDetails, true);

// CSS

const useStyles = makeStyles()((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    marginTop: theme.spacing(5),
    textAlign: "center",
    "& > :not(:last-child)": {
      marginBottom: theme.spacing(1),
    },
    padding: theme.spacing(3),
  },
}));

// I18N

const i18n = defineMessages({
  title: {
    id: "requestPartnerChangeDetails.title",
    defaultMessage: "Thank you!",
  },
  description: {
    id: "requestPartnerChangeDetails.description",
    defaultMessage:
      "We’ve received your request for more details about partner changes. <br/>We will send this request to your company’s Reveal admin in the next few days.",
  },
  goToDashboard: {
    id: "requestPartnerChangeDetails.goToDashboard",
    defaultMessage: "Go to Dashboard",
  },
  loggedOutLink: {
    id: "requestPartnerChangeDetails.loggedOutLink",
    defaultMessage: "Sign In",
  },
});
