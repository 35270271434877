import "./App.scss";

import history from "_history";
import createCache from "@emotion/cache";
import { CacheProvider, EmotionCache } from "@emotion/react";
import { StyledEngineProvider, ThemeProvider } from "@mui/material/styles";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import {
  AccessToken,
  IDToken,
  OktaAuth,
  TokenManagerInterface,
  TokenParams,
  toRelativeUrl,
  UserClaims,
} from "@okta/okta-auth-js";
import { Security } from "@okta/okta-react";
import RoutedApp from "components/routes/RoutedApp";
import RoutingContext from "components/routes/RoutingContext";
import { computeRoutes } from "components/routes/utils";
import ErrorHandler from "components/ui/ErrorHandler";
import ErrorPage from "components/ui/ErrorPage";
import oktaConfig from "config/okta";
import * as facebook from "helpers/facebook";
import { Suspense, useEffect, useMemo } from "react";
import { IntlProvider } from "react-intl";
import { connect } from "react-redux";
import { Router } from "react-router-dom";
import { bindActionCreators } from "redux";
import { initStore } from "redux/init/thunks";
import { RevealStore } from "redux/typing";
import { NonUserUrl } from "screens/Frontoffice/shared/helpers/types";
import { AppDispatch } from "store";

import CookieConsentBar from "./components/ui/CookieConsentBar";
import Page from "./components/ui/Page";
import PageLoader from "./components/ui/PageLoader";
import config from "./config";
import ConfigProvider from "./config/ConfigProvider";
import Segment from "./helpers/segment";
import Sentry from "./helpers/sentry";
import { getMessages } from "./i18n";

type AppProps = {
  initStore: () => any;
  contextError?: boolean;
};

const authClient = new OktaAuth(oktaConfig);
const tokenManager: TokenManagerInterface = authClient.tokenManager;
(async () => {
  const accessToken: AccessToken = (await tokenManager.get(
    "accessToken"
  )) as AccessToken;
  const idToken: IDToken = (await tokenManager.get("idToken")) as IDToken;
  if (accessToken) {
    const userInfo: UserClaims = await authClient.token.getUserInfo(
      accessToken,
      idToken
    );
    if (!userInfo) {
      const tokenParams: TokenParams = {
        scopes: oktaConfig.scopes,
      };
      authClient.token.getWithRedirect(tokenParams);
    }
  }
})();

const App = ({ contextError, initStore }: AppProps) => {
  const title = config().title;
  const backgroundColor = config().backgroundColor;
  const routes = config().routes;
  const allRoutes = useMemo(() => computeRoutes(routes), [routes]);
  const { pathname } = history.location;

  useEffect(() => {
    Segment.start();
    Sentry.start();
    facebook.start();
    if (pathname !== NonUserUrl.Base) {
      initStore();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initStore]);

  useEffect(() => {
    document.title = title;
    document.body.style.backgroundColor = backgroundColor;
  }, [title, backgroundColor]);

  // @ts-expect-error
  // createCache should return an EmotionCache, but the insert function is missing
  const muiCache: EmotionCache = createCache({
    key: "mui",
    // @ts-expect-error
    prepend: true,
  });

  const restoreOriginalUri = async (
    oktaAuth: OktaAuth,
    originalUri: string
  ) => {
    history.replace(toRelativeUrl(originalUri || "/", window.location.origin));
  };

  const content = contextError ? (
    <ErrorPage />
  ) : (
    <Router history={history}>
      <Security oktaAuth={authClient} restoreOriginalUri={restoreOriginalUri}>
        <Suspense
          fallback={
            <Page forceAllowIncompleteOnboarding>
              <PageLoader />
            </Page>
          }
        >
          <ErrorHandler>
            <RoutingContext.Provider value={allRoutes}>
              <Page routes={routes}>
                <RoutedApp routes={routes} />
              </Page>
            </RoutingContext.Provider>
          </ErrorHandler>
          <CookieConsentBar />
        </Suspense>
      </Security>
    </Router>
  );

  return (
    <ConfigProvider>
      <CacheProvider value={muiCache}>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <StyledEngineProvider injectFirst>
            <ThemeProvider theme={config().theme}>
              <IntlProvider
                key={"en"}
                locale={"en"}
                messages={getMessages("en")}
              >
                {content}
              </IntlProvider>
            </ThemeProvider>
          </StyledEngineProvider>
        </LocalizationProvider>
      </CacheProvider>
    </ConfigProvider>
  );
};

const mapProps = ({ context: { error } }: RevealStore) => ({
  contextError: error,
});
const mapDispatch = (dispatch: AppDispatch) =>
  bindActionCreators({ initStore }, dispatch);

export default connect(mapProps, mapDispatch)(App);
