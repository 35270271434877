import { Box } from "@mui/material";
import { MessageChecked } from "components/icons";
import { IDropdownOption } from "components/ui/Dropdown/components/types";
import Partnership from "models/Partnership";
import Record from "models/Record";
import User from "models/User";
import { defineMessages, IntlShape } from "react-intl";

export type IPartnerNameAndId = {
  name: string;
  id: number;
};

const checkExistingPartnerConnection = (
  partnershipId: number,
  pipelinePartners?: IPartnerNameAndId[]
) => pipelinePartners?.some((item) => item.id === partnershipId);

export const getPartnerOptionsWithCustomerAndOpportunityGroups = (
  partnerships: Partnership[],
  account: Record<
    "crm_accounts" | "nearbound_accounts" | "nearbound_prospects"
  >,
  pipelinePartners: IPartnerNameAndId[] | undefined,
  profile: User,
  intl: IntlShape
): IDropdownOption[] => {
  const items = [
    {
      id: "customer-group",
      name: intl.formatMessage(i18n.customerGroupTitle),
      group: partnerships
        .filter(
          (partnership) =>
            account.partnerPresence?.some(
              // for crm_accounts
              (partnerPresence: Record) =>
                Number(partnerPresence.id) ===
                partnership.getPartner(profile).id
            ) ??
            (account.customerOf ?? account.customerPresenceList)?.some(
              // customerOf is for nearbound_accounts, customerPresenceList is for nearbound_prospects
              (customer: Record) =>
                Number(customer.partner_id) ===
                partnership.getPartner(profile).id
            )
        )
        .map((partnership) => ({
          company: partnership.getPartner(profile),
          partnershipId: partnership.id,
        }))
        .map(({ company, partnershipId }) => {
          const hasPartnerConnection = checkExistingPartnerConnection(
            partnershipId,
            pipelinePartners
          );
          return {
            tooltipMessage: hasPartnerConnection
              ? intl.formatMessage(i18n.alreadyInPipeline)
              : "",
            id: String(partnershipId),
            name: company.name,
            ...(hasPartnerConnection && {
              label: (
                <Box display="flex" alignItems="center">
                  {company.name}
                  <MessageChecked style={{ height: 12 }} />
                </Box>
              ),
            }),
            logo: company.avatarUrl,
          };
        }),
    },
    {
      id: "opportunity-group",
      name: intl.formatMessage(i18n.opportunityGroupTitle),
      group: partnerships
        .filter(
          (partnership) =>
            account.partnerOpportunities?.some(
              // for crm_accounts
              (partnerPresence: Record) =>
                Number(partnerPresence.id) ===
                partnership.getPartner(profile).id
            ) ??
            account.openOpportunityFor?.some(
              // for nearbound_accounts
              (customer: Record) =>
                Number(customer.partner_id) ===
                partnership.getPartner(profile).id
            )
        )
        .map((partnership) => ({
          company: partnership.getPartner(profile),
          partnershipId: partnership.id,
        }))
        .map(({ company, partnershipId }) => {
          const hasPartnerConnection = checkExistingPartnerConnection(
            partnershipId,
            pipelinePartners
          );
          return {
            tooltipMessage: hasPartnerConnection
              ? intl.formatMessage(i18n.alreadyInPipeline)
              : "",
            id: String(partnershipId),
            logo: company.avatarUrl,
            name: company.name,
            ...(hasPartnerConnection && {
              label: (
                <Box display="flex" alignItems="center">
                  {company.name}
                  <MessageChecked style={{ height: 12 }} />
                </Box>
              ),
            }),
          };
        }),
    },
    {
      id: "other-group",
      name: intl.formatMessage(i18n.otherGroupTitle),
      group: partnerships
        .filter(
          (partnership) =>
            ![
              ...(account.partnerOpportunities ?? []),
              ...(account.partnerPresence ?? []),
            ].some(
              (item) => Number(item.id) === partnership.getPartner(profile).id
            ) &&
            ![
              ...(account.customerOf ?? []),
              ...(account.customerPresenceList ?? []),
              ...(account.openOpportunityFor ?? []),
            ].some(
              (item) =>
                Number(item.partner_id) === partnership.getPartner(profile).id
            )
        )
        .map((partnership) => ({
          company: partnership.getPartner(profile),
          partnershipId: partnership.id,
        }))
        .map(({ company, partnershipId }) => {
          const hasPartnerConnection = checkExistingPartnerConnection(
            partnershipId,
            pipelinePartners
          );
          return {
            tooltipMessage: hasPartnerConnection
              ? intl.formatMessage(i18n.alreadyInPipeline)
              : "",
            id: String(partnershipId),
            logo: company.avatarUrl,
            name: company.name,
            ...(hasPartnerConnection && {
              label: (
                <Box display="flex" alignItems="center">
                  {company.name}
                  <MessageChecked style={{ height: 12 }} />
                </Box>
              ),
            }),
          };
        }),
    },
  ].filter((item) => item.group.length > 0);

  return items.length === 1 ? items[0].group : items;
};

const i18n = defineMessages({
  customerGroupTitle: {
    id: "getPartnerOptionsWithCustomerAndOpportunityGroups.customerGroupTitle",
    defaultMessage: "Partners who listed account as customer",
  },
  opportunityGroupTitle: {
    id:
      "getPartnerOptionsWithCustomerAndOpportunityGroups.opportunityGroupTitle",
    defaultMessage: "Partners who listed account as open opportunity",
  },
  otherGroupTitle: {
    id: "getPartnerOptionsWithCustomerAndOpportunityGroups.otherGroupTitle",
    defaultMessage: "Other Partners",
  },
  alreadyInPipeline: {
    id: "getPartnerOptionsWithCustomerAndOpportunityGroups.alreadyInPipeline",
    defaultMessage: "There is already a collab with this partner",
  },
});
