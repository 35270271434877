import { Box } from "@mui/material";
import { Plus } from "components/icons";
import ChevronDown from "components/icons/ChevronDown";
import Button from "components/ui/Button";
import Dropdown from "components/ui/Dropdown/components/Dropdown";

import { DropdownCellProps, useDropdownCell } from "./useDropdownCell";

export function DropdownCell<T>(props: DropdownCellProps<T>) {
  const {
    buttonClasses,
    selectedItem,
    cellType,
    getLabel,
    unselectedText,
    CustomFooter,
    CustomHeader,
    classes,
    onCloseCallback,
    searchPlaceholder,
    size,
    withSearch,
  } = props;

  const {
    handleClose,
    handleOpen,
    open,
    anchorEl,
    dropdownOptions,
    isLoadingOptions,
    values,
    onItemChange,
    debouncedOnSearch,
    isAsync,
  } = useDropdownCell(props);

  const handleCloseDropdown = () => {
    handleClose();
    onCloseCallback?.();
  };

  return (
    <Box overflow="hidden">
      <Button
        classes={buttonClasses}
        LeftIcon={selectedItem ? undefined : Plus}
        RightIcon={cellType === "partner" ? undefined : ChevronDown}
        label={selectedItem ? getLabel(selectedItem) : unselectedText}
        size="small"
        onClick={handleOpen}
        variant={selectedItem ? "septenary" : "tertiary"}
        ellipsis
      />
      {open && (
        <Dropdown
          classes={classes}
          disablePortal
          CustomFooter={CustomFooter}
          CustomHeader={CustomHeader}
          isLoading={isLoadingOptions}
          withGroups={cellType === "partner"}
          value={values}
          options={dropdownOptions}
          anchorEl={anchorEl}
          onClose={handleCloseDropdown}
          open={open}
          onChange={onItemChange}
          size={size ?? "small"}
          searchPlaceholder={searchPlaceholder}
          hasAsyncSearch={isAsync}
          onAsyncSearch={debouncedOnSearch}
          withSearch={withSearch}
        />
      )}
    </Box>
  );
}
