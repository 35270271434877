import Button from "components/ui/Button";
import useRecord from "hooks/useRecord";
import useSegment from "hooks/useSegment";
import _ from "lodash";
import { PayingFeature } from "models/CompanyPayingFeatureSubscription";
import Partnership from "models/Partnership";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import { loadView, updateView } from "redux/accountMapping/thunks";
import { AvailableAnalytisColumns } from "redux/analytics/defaults";
import { selectActivePayingFeatures } from "redux/api/selectors";
import { AdvancedAnalyticsEvent } from "tracking";

import { CellProps } from "../../utils";

const RedirectCell = ({ row, fieldName, accountType }: CellProps) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { track } = useSegment();
  const { record } = useRecord("partnerships", row.partnershipId, false);
  const payingFeatures = useSelector(selectActivePayingFeatures);
  const isMatchedAccount = payingFeatures.includes(
    PayingFeature.UseNewAccountMapping
  );

  const value = _.get(row, `metrics.${fieldName}`, null);

  const redirectToAM = async () => {
    if (record) {
      const partnership = record as Partnership;
      await dispatch(loadView({ partnership, isMatchedAccount }));
      await dispatch(
        updateView({
          accountType,
          partnership,
          isMatchedAccount,
        })
      );
      track(AdvancedAnalyticsEvent.redirectToAccountMapping, {
        to: accountType,
      });

      const kpiMap: { [key: string]: string } = {
        my_prospects_partner_customers_value: "prospects_to_customers",
        my_customers_partner_prospects: "customers_to_prospects",
        opportunities_among_partners_customers_value:
          "open_opportunities_to_customers",
        partner_new_prospects: "partner_nb_of_new_prospects",
      };
      const kpi = kpiMap[fieldName] || fieldName;

      history.push(
        `/partnerships/${row.partnershipId}/account-mapping?kpi=${kpi}`
      );
    }
  };

  return (
    <Button
      label={String(value)}
      size="small"
      onClick={redirectToAM}
      variant={"septenary"}
    />
  );
};

export default RedirectCell;

// Helpers

export const isRedirectCell = ({ fieldName }: CellProps) => {
  return (
    fieldName === AvailableAnalytisColumns.commonCustomers ||
    fieldName === AvailableAnalytisColumns.commonOpportunities ||
    fieldName === AvailableAnalytisColumns.myCustomersPartnerProspects ||
    fieldName === AvailableAnalytisColumns.myProspectsPartnerCustomers ||
    fieldName === AvailableAnalytisColumns.newProspectsForPartner ||
    fieldName === AvailableAnalytisColumns.opportunitiesPartnerCustomers
  );
};
