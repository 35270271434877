import { Grid } from "@mui/material";
import withRecord, { WithRecord } from "components/hoc/withRecord";
import { ArrowRight, Minus, Plus } from "components/icons";
import BaseSettingsTab from "components/ui/BaseSettingsTab";
import Button from "components/ui/Button";
import { SelectInput } from "components/ui/SelectInput";
import { TextInput } from "components/ui/TextInput";
import { T } from "components/ui/Typography";
import { ProviderType } from "config/constants";
import useAllRecords from "hooks/useAllRecords";
import usePushNotification from "hooks/usePushNotification";
import generic from "i18n/generic";
import _ from "lodash";
import { makeStyles } from "makeStyles";
import CrmField from "models/CrmField";
import SourceConfiguration from "models/SourceConfiguration";
import { useEffect, useState } from "react";
import { defineMessages, FormattedMessage, useIntl } from "react-intl";
import { useHistory, useLocation } from "react-router-dom";
import { crmFileProviderLabelAndLogos } from "screens/Frontoffice/screens/Sources/constants";
import {
  CRMFileProviderType,
  isCrmFileType,
} from "screens/Frontoffice/shared/helpers/types";

import { updateHistoryUpdatedSource } from "../helpers/utils";

const LOADING = "Loading...";

type ColumnName = string;
type ColumnMapping = { [rawCompanyField: string]: ColumnName };

export type Props = WithRecord<SourceConfiguration> & {
  integrationId: number;
  handleClose: () => void;
  sourceType: string;
  hideSummary?: boolean;
  callback?: () => void;
  noFieldsCallback?: () => void;
  refresh?: () => void;
};

const ProviderTypeToColumns: {
  [key in CRMFileProviderType]: {
    requiredMapping: string[];
    defaultMappingDisplayed: string[];
    additionalMapping: string[];
  };
} = {
  [ProviderType.file]: {
    requiredMapping: ["name", "website", "status_raw"],
    defaultMappingDisplayed: ["name", "website", "status_raw", "country"],
    additionalMapping: [
      "owner_full_name",
      "owner_email",
      "address",
      "postal_code",
      "city",
      "email_hashed",
      "phone_hash",
      "number_of_open_opportunities",
      "open_opportunities_amount_in_dollars",
    ],
  },
  [ProviderType.gsheet]: {
    requiredMapping: ["name", "website", "status_raw", "provider_key"],
    defaultMappingDisplayed: [
      "name",
      "website",
      "status_raw",
      "provider_key",
      "country",
    ],
    additionalMapping: [
      "owner_full_name",
      "owner_email",
      "address",
      "postal_code",
      "city",

      "email_hashed",
      "phone_hash",
      "number_of_open_opportunities",
      "open_opportunities_amount_in_dollars",
    ],
  },
};

const ColumnMappingWidget = (props: Props) => {
  const { sourceType } = props;
  if (!isCrmFileType(sourceType)) {
    throw Error(`Cannot render with sourceType ${sourceType}`);
  }
  const { canSave, content, handleSave, saving } = useColumnMappingWidget(
    props
  );

  return (
    <BaseSettingsTab
      summary={
        <FormattedMessage
          {...i18n.summary}
          values={{ provider: crmFileProviderLabelAndLogos[sourceType].name }}
        />
      }
      disabled={!canSave()}
      saving={saving}
      handleSave={handleSave}
      {...props}
    >
      {content}
    </BaseSettingsTab>
  );
};

type BaseProps = Omit<Props, "handleClose">;

export const useColumnMappingWidget = ({
  callback,
  integrationId,
  record,
  refresh,
  sourceType,
  updateRecord,
}: BaseProps) => {
  const {
    additionalMapping,
    defaultMappingDisplayed,
    requiredMapping,
  } = ProviderTypeToColumns?.[sourceType as CRMFileProviderType];
  const [showAdditional, setShowAdditional] = useState(false);
  const [refreshFieldsCount, setRefreshFieldsCount] = useState<0 | 1>(0);
  const history = useHistory();
  const location = useLocation();
  const mappingFields = defaultMappingDisplayed.concat(additionalMapping);
  const pushNotification = usePushNotification();
  const [saving, setSaving] = useState(false);
  const { classes } = useStyles();
  const intl = useIntl();

  const initialMapping: ColumnMapping = {};
  mappingFields.forEach(
    (field) =>
      (initialMapping[field] =
        _.invert(record.rawCompanyCustomMapping)[field] || "")
  );

  const [currentMapping, setCurrentMapping] = useState<ColumnMapping>(
    initialMapping
  );

  const {
    records: crmFieldsRecords,
    loading: crmFieldsLoading,
    refresh: refreshCrmFields,
  }: {
    records: CrmField[];
    loading: boolean;
    refresh: () => void;
  } = useAllRecords("crm_fields", {
    filters: {
      integration: integrationId,
      sw_record_type: "RawCompany",
      syncable: true,
    },
  });

  // a workaround to refresh the fields only once if they are empty
  useEffect(() => {
    if (!!crmFieldsRecords.length || crmFieldsLoading || !!refreshFieldsCount) {
      return;
    }
    refreshCrmFields();
    setRefreshFieldsCount(1);
  }, [
    crmFieldsLoading,
    crmFieldsRecords.length,
    refreshCrmFields,
    refreshFieldsCount,
  ]);

  const availableColumns = crmFieldsLoading
    ? [LOADING]
    : crmFieldsRecords.map((crmField) => crmField.crmName);

  const changeMappingFor = (fieldName: string) => (event: $TSFixMe) => {
    const columnName = event.target.value;
    const oldCompanyFieldForColumn = _.findKey(
      currentMapping,
      (column) => column === columnName
    );

    const replacedFields = {
      [fieldName]: columnName,
    };

    if (oldCompanyFieldForColumn) {
      replacedFields[oldCompanyFieldForColumn] = "";
    }

    setCurrentMapping((oldMapping) => ({
      ...oldMapping,
      ...replacedFields,
    }));
  };

  const handleSave = async () => {
    setSaving(true);
    const response = await updateRecord(
      {
        raw_company_custom_mapping: _.omit(_.invert(currentMapping), [""]),
      },
      {}
    );

    if (response.error) {
      pushNotification("default_error");
    } else {
      pushNotification({ ...generic.edits_saved });
      updateHistoryUpdatedSource(history, location);
    }

    setSaving(false);
    callback?.();
    refresh?.();
  };

  const canSave = () => {
    const hasRequiredFields = requiredMapping.every(
      (companyField) => currentMapping[companyField] !== ""
    );

    return (
      hasRequiredFields &&
      !_.isEqual(currentMapping, _.invert(record.rawCompanyCustomMapping))
    );
  };

  const handleToggleExpand = () => {
    setShowAdditional((current) => !current);
  };

  const mappingFieldView = (fieldName: string) => {
    const currentValue = currentMapping[fieldName];
    const required = requiredMapping.includes(fieldName);

    if (
      !currentValue &&
      !showAdditional &&
      additionalMapping.includes(fieldName)
    ) {
      return null;
    }
    return (
      <Grid container className={classes.inputRow} key={fieldName}>
        <Grid item className={classes.inputColumn}>
          <SelectInput
            variant="tertiary"
            className={classes.fullwidth}
            name={fieldName}
            value={crmFieldsLoading ? LOADING : currentValue}
            onChange={changeMappingFor(fieldName)}
            data-testid={`select-${fieldName}`}
            required={required}
            placeholder={intl.formatMessage(i18n.selectPlaceholder)}
            options={availableColumns.map((csvColumn) => ({
              value: csvColumn,
              label: csvColumn,
            }))}
            error={required && !crmFieldsLoading && !currentValue}
          />
        </Grid>
        <Grid item>
          <ArrowRight className={classes.icon} />
        </Grid>
        <Grid item className={classes.inputColumn}>
          <TextInput
            disabled
            className={classes.fullwidth}
            value={`${intl.formatMessage(
              i18n[fieldName as keyof typeof i18n]
            )}${required ? " *" : ""}`}
          />
        </Grid>
      </Grid>
    );
  };

  const content = (
    <div className={classes.wrapper}>
      <Grid container className={classes.headerRow}>
        <Grid item className={classes.inputColumn}>
          <T className={classes.columnHeader}>
            {
              crmFileProviderLabelAndLogos[sourceType as CRMFileProviderType]
                .name
            }
            &nbsp;
            <FormattedMessage {...i18n.data} />
          </T>
        </Grid>
        <Grid item className={classes.inputColumn}>
          <T className={classes.columnHeader}>
            <FormattedMessage {...i18n.revealData} />
          </T>
        </Grid>
      </Grid>
      {mappingFields.map(mappingFieldView)}
      <div className={classes.expandButton}>
        <Button
          variant="secondary"
          LeftIcon={showAdditional ? Minus : Plus}
          label={
            <FormattedMessage
              {...(showAdditional ? i18n.hideAdditional : i18n.showAdditional)}
            />
          }
          onClick={handleToggleExpand}
        />
      </div>
    </div>
  );

  return { canSave, handleSave, content, saving };
};

export default withRecord<SourceConfiguration, Props>("source_configurations", {
  allowEarlyDisplay: true,
})(ColumnMappingWidget);

export const CsvColumnMappingWidgetGhost = withRecord<
  SourceConfiguration,
  Props
>("source_configurations", {
  allowEarlyDisplay: true,
  forceUrlResource: "ghost_source_configurations",
  include: ["source"],
})(ColumnMappingWidget);

// CSS

const useStyles = makeStyles()((theme) => ({
  columnHeader: {
    color: theme.palette.midnight500,
    fontSize: 10,
    fontWeight: 500,
    letterSpacing: "0.05em",
    lineHeight: "10px",
    textAlign: "center",
    textTransform: "uppercase",
  },
  expandButton: {
    margin: "auto",
    marginTop: theme.spacing(4),
  },
  fullwidth: {
    width: "100%",
  },
  headerRow: {
    justifyContent: "center",
    marginBottom: theme.spacing(1),
  },
  icon: {
    color: theme.palette.pigeon,
    height: 12,
  },
  inputColumn: {
    flexGrow: 1,
    textAlign: "center",
    maxWidth: 350,
  },
  inputRow: {
    alignItems: "center",
    justifyContent: "center",
    padding: `${theme.spacing(0.5)} ${theme.spacing(1)}`,
  },
  wrapper: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
  },
  mappingFieldLabel: {
    marginRight: theme.spacing(2),
    width: 200,
  },
}));

// I18N

const i18n = defineMessages({
  data: {
    id: "ColumnMappingWidget.data",
    defaultMessage: "Columns",
  },
  showAdditional: {
    id: "ColumnMappingWidget.showAdditional",
    defaultMessage: "Show other columns",
  },
  hideAdditional: {
    id: "ColumnMappingWidget.hideAdditional",
    defaultMessage: "Show fewer columns",
  },
  summary: {
    id: "ColumnMappingWidget.summary",
    defaultMessage:
      "Please help us understand how you map columns in your {provider} to company fields.",
  },
  custormersAre: {
    id: "ColumnMappingWidget.customers_are",
    defaultMessage: "<b>Customers</b> are companies where",
  },
  prospectsAre: {
    id: "ColumnMappingWidget.prospects_are",
    defaultMessage: "<b>Prospects</b> are companies where",
  },
  partnersAre: {
    id: "ColumnMappingWidget.partners_are",
    defaultMessage: "<b>Partners</b> are companies where",
  },
  revealData: {
    id: "ColumnMappingWidget.revealData",
    defaultMessage: "Reveal Columns",
  },
  selectPlaceholder: {
    id: "ColumnMappingWidget.selectPlaceholder",
    defaultMessage: "Pick a column",
  },
  name: {
    id: "ColumnMappingWidget.name",
    defaultMessage: "Company name",
  },
  website: {
    id: "ColumnMappingWidget.website",
    defaultMessage: "Company website",
  },
  status_raw: {
    id: "ColumnMappingWidget.status_raw",
    defaultMessage: "Company status",
  },
  address: {
    id: "ColumnMappingWidget.address",
    defaultMessage: "Address",
  },
  postal_code: {
    id: "ColumnMappingWidget.postal_code",
    defaultMessage: "Postal Code",
  },
  city: { id: "ColumnMappingWidget.city", defaultMessage: "City" },
  country: { id: "ColumnMappingWidget.country", defaultMessage: "Country" },
  email_hashed: {
    id: "ColumnMappingWidget.email_hashed",
    defaultMessage: "Email",
  },
  phone_hash: {
    id: "ColumnMappingWidget.phone_hash",
    defaultMessage: "Phone Number",
  },
  number_of_open_opportunities: {
    id: "ColumnMappingWidget.number_of_open_opportunities",
    defaultMessage: "Number of open opportunities",
  },
  open_opportunities_amount_in_dollars: {
    id: "ColumnMappingWidget.open_opportunitunities_amount_in_dollars",
    defaultMessage: "Open opportunities amount",
  },
  owner_email: {
    id: "ColumnMappingWidget.owner_email",
    defaultMessage: "Owner email",
  },
  owner_full_name: {
    id: "ColumnMappingWidget.owner_full_name",
    defaultMessage: "Owner name",
  },
  provider_key: {
    id: "ColumnMappingWidget.unique_id",
    defaultMessage: "Unique ID",
  },
});
