import Fuse from "fuse.js";
import CrmCredential from "models/CrmCredential";
import Integration from "models/Integration";
import Partnership from "models/Partnership";
import User from "models/User";
import { APICollection } from "redux/api/typing";
import { RootStateWithUser } from "redux/user/typing";

export enum DashboardActions {
  setSearch = "dashboard/setSearch",
  transformSuggestion = "dashboard/transformSuggestion",
  addCreatedPartner = "dashboard/addCreatedPartner",
}

export interface DashboardState {
  search: {
    value: string;
    index: Fuse<{ id: number; partnerName: string }> | null;
  };
  overlapsReady: boolean;
  upsidePartnershipIds: number[];
}

export interface RootStateWithDashboard {
  context: {
    ready: boolean | null;
    partnershipIds: number[];
  };
  dashboard: DashboardState;
  api: {
    entities: {
      potential_partner_overlaps?: APICollection;
      partnerships?: APICollection;
      crm_credentials?: APICollection<CrmCredential>;
      integrations?: APICollection<Integration>;
    };
  };
  user: {
    profile: {
      data: User;
    };
  };
}

export interface RootStateWithPartnerships extends RootStateWithUser {
  api: {
    entities: {
      partnerships?: APICollection<Partnership>;
    };
  };
}
