import { ThreeSixty } from "components/ui/filters/smartView/constants";
import useHasPayingFeature from "hooks/useHasPayingFeature";
import useSegment from "hooks/useSegment";
import useTrack from "hooks/useTrack";
import useUserProfile from "hooks/useUserProfile";
import _ from "lodash";
import { PayingFeature } from "models/CompanyPayingFeatureSubscription";
import { PageType } from "models/PageView";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { defineMessages, useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import { selectAnalyticsView } from "redux/analytics/selectors";
import { checkHighWinRatePresence } from "redux/analytics/thunks";
import { selectDashboardData } from "redux/dashboard/selectors";
import { initializePartnershipsAndCompanyNames } from "redux/init/thunks";
import { selectSourceId } from "redux/mapping360/actions";
import {
  Available360MappingColumns,
  default360MappingColumnConfig,
  default360MappingColumns,
  mapping360ColumnsConfig,
} from "redux/mapping360/defaults";
import { selectMapping360View, selectView } from "redux/mapping360/selectors";
import { loadView, loadViews, updateView } from "redux/mapping360/thunks";
import { selectSourceId as selectSourceIdNearboundAccounts } from "redux/mapping360NearboundAccounts/actions";
import {
  applyNearboundAccountsColumnOrder,
  AvailableNearboundAccountsColumns,
  defaultNearboundAccountsColumnConfig,
  defaultNearboundAccountsColumns,
  nearboundAccountsColumnsConfig,
} from "redux/mapping360NearboundAccounts/defaults";
import {
  selectNearboundAccountState,
  selectView as selectViewNearboundAccounts,
} from "redux/mapping360NearboundAccounts/selectors";
import {
  loadView as loadViewNearboundAccounts,
  loadViews as loadViewsNearboundAccounts,
  updateView as updateViewNearboundAccounts,
} from "redux/mapping360NearboundAccounts/thunks";
import { Mapping360NearboundAccountsViewParameters } from "redux/mapping360NearboundAccounts/typing";
import { Mapping360Event } from "tracking"; // TODO: relocate this to a nearbound accounts file

import { useTableRows } from "../../../shared/hooks";
import {
  ColumnConfigType,
  FieldType,
  FilterType,
  MatchFieldType,
  MatchFilterType,
  PersistedColumnType,
  SortType,
  viewToColumns,
} from "../../../shared/types";
import { expandNewMarketsFields } from "../constants";
import { mapping360Goals, nearboundAccountsGoals } from "../goalsPresets";
import { enrichFields, isMapping360StandardFields } from "../utils";

export const useApiLogic = () => {
  const { profile } = useUserProfile();
  const location = useLocation();
  const history = useHistory();
  const { track } = useSegment();
  const searchParams = useMemo(() => new URLSearchParams(location.search), [
    location.search,
  ]);
  const isSearchGoalLeadGen = searchParams.get("goal") === "lead-gen";
  const searchFilterCreatedAtGTE = searchParams.get("filter[created_at__gte]");
  const dispatch = useDispatch();
  const intl = useIntl();

  const { hasHighWinRatePartners } = useSelector(selectAnalyticsView);
  const is360NearboundAccountsUnlocked = useHasPayingFeature(
    PayingFeature.UseNew360,
    profile
  );
  const view = useSelector(
    is360NearboundAccountsUnlocked ? selectViewNearboundAccounts : selectView
  );
  const { ready: dashboardReady } = useSelector(selectDashboardData);
  const { ready, selectedSourceId } = useSelector(
    is360NearboundAccountsUnlocked
      ? selectNearboundAccountState
      : selectMapping360View
  );

  useTrack(Mapping360Event.viewMapping360);

  const filters: FilterType[] = view?.filters ?? [];
  const sort: SortType[] = view?.sort ?? [];
  const viewColumns: PersistedColumnType[] =
    view?.columns ??
    (is360NearboundAccountsUnlocked
      ? defaultNearboundAccountsColumns
      : default360MappingColumns);

  const isMy360MappingUnlocked = useHasPayingFeature(
    PayingFeature.My360MappingUnlocked,
    profile
  );
  const isGoalBased360Limited = useHasPayingFeature(
    PayingFeature.GoalBased360Limited,
    profile
  );
  const isGoalBased360Unlocked = useHasPayingFeature(
    PayingFeature.GoalBased360Unlocked,
    profile
  );
  const isNewProspects360Unlocked = useHasPayingFeature(
    PayingFeature.NewProspects360,
    profile
  );
  // This paying feature is added with an expiry date
  // and should not be used after the 01/06/2023
  const is360MappingLimitedByPartnerships = useHasPayingFeature(
    PayingFeature.Limit360MappingByPartnerships,
    profile
  );
  const is360MappingUnlocked = Boolean(
    is360MappingLimitedByPartnerships ||
      isMy360MappingUnlocked ||
      isGoalBased360Limited ||
      isGoalBased360Unlocked
  );
  const isExpandToNewMarketsView = view?.pageType === PageType.mapping360Expand;
  const viewType = isExpandToNewMarketsView
    ? ThreeSixty.nearboundProspects
    : is360NearboundAccountsUnlocked
    ? ThreeSixty.nearboundAccounts
    : ThreeSixty.threeSixty;

  useEffect(() => {
    if (dashboardReady === null) {
      dispatch(initializePartnershipsAndCompanyNames());
    }
  }, [dashboardReady, dispatch]);

  useEffect(() => {
    dispatch(
      is360NearboundAccountsUnlocked
        ? loadViewsNearboundAccounts()
        : loadViews()
    );
  }, [dispatch, isGoalBased360Limited, is360NearboundAccountsUnlocked]);

  useEffect(() => {
    dispatch(checkHighWinRatePresence());
  }, [dispatch]);

  useEffect(() => {
    if (!ready) {
      return;
    }

    const loadFunc = is360NearboundAccountsUnlocked
      ? loadViewNearboundAccounts
      : loadView;
    dispatch(
      loadFunc({
        isGoalBased360Limited,
        isGoalBased360Unlocked,
        isNewProspects360Unlocked,
        selectLastView: true,
        pageType: isSearchGoalLeadGen ? PageType.mapping360Expand : undefined,
      })
    );

    if (isSearchGoalLeadGen) {
      if (searchFilterCreatedAtGTE) {
        track(Mapping360Event.downloadNewProspectsWeeklyDigest, { profile });
      }
      searchParams.delete("goal");
      const newSearch = decodeURIComponent(searchParams.toString());
      history.replace({
        ...location,
        search: newSearch ? `?${newSearch}` : "",
      });
    }
  }, [
    dispatch,
    isGoalBased360Limited,
    isGoalBased360Unlocked,
    isNewProspects360Unlocked,
    is360NearboundAccountsUnlocked,
    ready,
    isSearchGoalLeadGen,
    searchParams,
    history,
    location,
    searchFilterCreatedAtGTE,
    track,
    profile,
  ]);

  // fixes any broken view, by checking the selected goal's presets
  useEffect(() => {
    const updateFunc = is360NearboundAccountsUnlocked
      ? updateViewNearboundAccounts
      : updateView;
    if (!view) {
      return;
    }
    const expectedPresets = [
      ...mapping360Goals,
      ...nearboundAccountsGoals,
    ].find((goal) => goal.pageType === view.pageType)?.presets;
    if (
      (expectedPresets?.filter ?? []).length > 0 &&
      _.differenceWith(expectedPresets?.filter, view.filters, _.isEqual)
        .length !== 0
    ) {
      dispatch(
        updateFunc({
          filters: [
            ...(expectedPresets?.filter ?? []),
            ..._.differenceWith(
              view.filters,
              expectedPresets?.filter ?? [],
              (a, b) => a.fieldname !== b.fieldname
            ),
          ],
          id: view.id,
        })
      );
    }
  }, [view, dispatch, is360NearboundAccountsUnlocked]);

  const setView = useCallback(
    (value: Mapping360NearboundAccountsViewParameters) =>
      dispatch(
        is360NearboundAccountsUnlocked
          ? updateViewNearboundAccounts(value)
          : updateView(value)
      ),
    [dispatch, is360NearboundAccountsUnlocked]
  );
  const setSelectedSourceId = (sourceId: number | null) =>
    dispatch(
      is360NearboundAccountsUnlocked
        ? selectSourceIdNearboundAccounts(sourceId)
        : selectSourceId(sourceId)
    );

  const {
    sync,
    records,
    count,
    totalCount,
    fields,
    fetching,
    loadMore,
    openDealFields,
  } = useTableRows(
    isExpandToNewMarketsView
      ? "nearbound_prospects"
      : is360NearboundAccountsUnlocked
      ? "nearbound_accounts"
      : "crm_accounts",
    selectedSourceId
      ? filters.concat({
          fieldname: is360NearboundAccountsUnlocked
            ? "integrationId"
            : "integration",
          value: selectedSourceId,
          type: MatchFilterType._NO_OPERATOR,
        })
      : filters,
    sort,
    null,
    ready && (isExpandToNewMarketsView ? isNewProspects360Unlocked : true),
    isExpandToNewMarketsView || is360NearboundAccountsUnlocked
      ? undefined
      : [
          "owner",
          "partner_presence",
          "ghost_partner_presence",
          "partner_opportunities",
          "ghost_partner_opportunities",
          "open_opportunities",
        ],
    undefined,
    isExpandToNewMarketsView || is360NearboundAccountsUnlocked
      ? undefined
      : [
          "high_win_rate_partnership_ids",
          "partner_signals",
          "partner_connection_count",
          "has_partner_connection_with_no_partner",
          "has_opportunity_details",
        ],
    undefined,
    isExpandToNewMarketsView ? true : undefined,
    is360NearboundAccountsUnlocked ? true : undefined
  );

  const preparedFields:
    | {
        [fieldname: string]: FieldType;
      }
    | undefined = useMemo(() => {
    if (
      fields &&
      isMapping360StandardFields(fields) &&
      !is360NearboundAccountsUnlocked
    ) {
      const result = _.cloneDeep(fields);
      if (!_.isEmpty(fields)) {
        enrichFields(result, Available360MappingColumns.partnerPresence, {
          label: intl.formatMessage(i18n.partnerPresence),
          type: MatchFieldType.PICKLIST,
        });
        enrichFields(result, Available360MappingColumns.partnerOpportunities, {
          label: intl.formatMessage(i18n.partnerOpportunities),
          type: MatchFieldType.PICKLIST,
        });
      }
      return result;
    }
    return fields;
  }, [fields, intl, is360NearboundAccountsUnlocked]);

  // TODO: Remove growth kpi related logic when fully migrated to new 360 API
  const hasGrowthKpisFeature =
    preparedFields &&
    Boolean(
      preparedFields["averageWinRate"] && preparedFields["projectedWinRate"]
    );
  let columns = viewToColumns(
    viewColumns,
    isExpandToNewMarketsView
      ? expandNewMarketsFields
      : (preparedFields as { [key: string]: FieldType } | undefined),
    is360NearboundAccountsUnlocked
      ? nearboundAccountsColumnsConfig
      : mapping360ColumnsConfig,
    is360NearboundAccountsUnlocked
      ? defaultNearboundAccountsColumnConfig
      : default360MappingColumnConfig,
    isGoalBased360Limited || isGoalBased360Unlocked
      ? (columns) => columns
      : applyNearboundAccountsColumnOrder
  );
  const rows = records || [];
  const hiddenRows = is360MappingUnlocked
    ? 0
    : totalCount === null
    ? 0
    : totalCount > 5 && rows.length > 0
    ? totalCount - rows.length
    : 0;
  const hasUnlockDialog = !is360MappingUnlocked && totalCount !== null;

  const [adjustedColumns, setAdjustedColumns] = useState(columns);
  const [adjustedFields, setAdjustedFields] = useState(preparedFields);
  const previousColumnsRef = useRef<ColumnConfigType[] | null>(null); // using this to avoid multiple requests against partnership_upside_potentials API

  useEffect(() => {
    if (
      JSON.stringify(previousColumnsRef.current) === JSON.stringify(columns)
    ) {
      return;
    }
    previousColumnsRef.current = columns;
    setAdjustedColumns(columns);
    setAdjustedFields(preparedFields);

    if (
      (!columns.length ||
        !columns.some(
          (column) =>
            column.key === Available360MappingColumns.partnerSignals ||
            column.key === AvailableNearboundAccountsColumns.PartnerSignalsCount
        )) &&
      (!preparedFields ||
        (!preparedFields[Available360MappingColumns.partnerSignals] &&
          !preparedFields[
            AvailableNearboundAccountsColumns.PartnerSignalsCount
          ]))
    ) {
      return; // no need to check anything else if the column is not present
    }
    if (typeof hasHighWinRatePartners !== "boolean") {
      // if hasHighWinRatePartners is not yet loaded, then we don't know if we need to remove partner signals column and field
      return;
    }
    if (hasHighWinRatePartners) {
      return; // if there is at least one partnership with win_rate_increase >= 0.2, then we don't need to remove partner signals column and field
    }

    setAdjustedColumns((prev) =>
      prev.filter(
        (column) =>
          column.key !== Available360MappingColumns.partnerSignals &&
          column.key !== AvailableNearboundAccountsColumns.PartnerSignalsCount
      )
    );
    setAdjustedFields((prev) => {
      const result = _.cloneDeep(prev);
      delete result?.[Available360MappingColumns.partnerSignals];
      delete result?.[AvailableNearboundAccountsColumns.PartnerSignalsCount];
      return result;
    });
  }, [columns, hasHighWinRatePartners, preparedFields]);

  useEffect(() => {
    if (!view) return;
    if (
      searchFilterCreatedAtGTE &&
      [PageType.mapping360Expand].includes(view?.pageType as PageType)
    ) {
      const newProspectsDigestFilter = [
        {
          fieldname: Available360MappingColumns.createdAt,
          value: searchFilterCreatedAtGTE,
          type: MatchFilterType.GTE,
        },
      ];
      setView({
        filters: newProspectsDigestFilter,
        id: view?.id,
      });
      if (searchFilterCreatedAtGTE) {
        searchParams.delete("filter[created_at__gte]");
        const newSearch = decodeURIComponent(searchParams.toString());
        history.replace({
          ...location,
          search: newSearch ? `?${newSearch}` : "",
        });
      }
    }
  }, [
    history,
    isSearchGoalLeadGen,
    location,
    searchFilterCreatedAtGTE,
    searchParams,
    setView,
    view,
  ]);

  return {
    columns: adjustedColumns,
    count,
    fetching,
    fields: adjustedFields,
    filters,
    hasGrowthKpisFeature,
    hasUnlockDialog,
    hiddenRows,
    isExpandToNewMarketsView,
    loadMore,
    openDealFields,
    ready,
    rows,
    selectedSourceId,
    setSelectedSourceId,
    setView,
    sort,
    sync,
    viewType,
  };
};

const i18n = defineMessages({
  partnerPresence: {
    id: "crm.Ecosystem.EcosystemSignalsTable.partnerPresence",
    defaultMessage: "Is customer of",
  },
  partnerOpportunities: {
    id: "crm.Ecosystem.EcosystemSignalsTable.partnerOpportunities",
    defaultMessage: "Is open opportunity for",
  },
});
