import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import FilterListRoundedIcon from "@mui/icons-material/FilterListRounded";
import SwapVertRoundedIcon from "@mui/icons-material/SwapVertRounded";
import ViewWeekRoundedIcon from "@mui/icons-material/ViewWeekRounded";
import VisibilityOffRoundedIcon from "@mui/icons-material/VisibilityOffRounded";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { T } from "components/ui/Typography";
import muiTheme from "config/theme";
import { makeStyles } from "makeStyles";
import { useState } from "react";
import { defineMessages, FormattedMessage } from "react-intl";

export type HeaderMenuProps = {
  setOpenedWidget: $TSFixMeFunction;
  addFilter: $TSFixMeFunction;
  addSort: $TSFixMeFunction;
  hideColumn: $TSFixMeFunction;
  removable: boolean;
  filterable?: boolean;
  sortable?: boolean;
};

const HeaderMenu = ({
  setOpenedWidget,
  filterable = true,
  sortable = true,
  removable,
  addFilter,
  addSort,
  hideColumn,
}: HeaderMenuProps) => {
  const { classes } = useStyles();
  const { classes: popoverClasses } = usePopoverStyles();
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event: $TSFixMe) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "headerMenu" : undefined;

  const handleAddFilter = () => {
    addFilter();
    setOpenedWidget("filter");
    handleClose();
  };

  const handleAddSort = () => {
    addSort();
    setOpenedWidget("sort");
    handleClose();
  };

  const handleHideColumn = () => {
    hideColumn();
    handleClose();
  };

  const handleInsertColumn = () => {
    setOpenedWidget("column");
    handleClose();
  };

  return (
    <>
      <ArrowDropDownIcon
        aria-describedby={id}
        className={classes.icon}
        data-testid="menu-icon"
        onClick={handleClick}
      />
      <Menu
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        classes={popoverClasses}
      >
        {filterable && (
          <MenuItem onClick={handleAddFilter}>
            <T oldVariant="body1_fontSize:13px" color={muiTheme.palette.comet}>
              <FilterListRoundedIcon className={classes.menuItemIcon} />
              <FormattedMessage {...i18n.addFilter} />
            </T>
          </MenuItem>
        )}
        {sortable && (
          <MenuItem onClick={handleAddSort}>
            <T oldVariant="body1_fontSize:13px" color={muiTheme.palette.comet}>
              <SwapVertRoundedIcon className={classes.menuItemIcon} />
              <FormattedMessage {...i18n.addSort} />
            </T>
          </MenuItem>
        )}
        {removable && (
          <MenuItem onClick={handleHideColumn}>
            <T oldVariant="body1_fontSize:13px" color={muiTheme.palette.comet}>
              <VisibilityOffRoundedIcon className={classes.menuItemIcon} />
              <FormattedMessage {...i18n.hideColumn} />
            </T>
          </MenuItem>
        )}
        <MenuItem onClick={handleInsertColumn}>
          <T oldVariant="body1_fontSize:13px" color={muiTheme.palette.comet}>
            <ViewWeekRoundedIcon className={classes.menuItemIcon} />
            <FormattedMessage {...i18n.insertColumn} />
          </T>
        </MenuItem>
      </Menu>
    </>
  );
};

export default HeaderMenu;

// I18N

const i18n = defineMessages({
  addFilter: {
    id: "crm.AccountMapping.HeaderMenu.addFilter",
    defaultMessage: "Add filter",
  },
  addSort: {
    id: "crm.AccountMapping.HeaderMenu.addSort",
    defaultMessage: "Add sort",
  },
  hideColumn: {
    id: "crm.AccountMapping.HeaderMenu.hideColumn",
    defaultMessage: "Hide column",
  },
  insertColumn: {
    id: "crm.AccountMapping.HeaderMenu.insertColumn",
    defaultMessage: "Insert column",
  },
});

// CSS

const useStyles = makeStyles()((theme) => ({
  icon: {
    fontSize: 20,
    color: theme.palette.midnight,
    cursor: "pointer",
  },
  menuItemIcon: {
    fontSize: 18,
    marginRight: 10,
  },
}));

const usePopoverStyles = makeStyles()((theme) => ({
  paper: {
    marginTop: 35,
    border: `1px solid ${theme.palette.other.grid.darkLine}`,
    // @ts-expect-error ts-migrate(2339) FIXME: Property 'main' does not exist on type 'Color'.
    boxShadow: `-2px 3px 6px ${theme.palette.grey.main} !important`,
    // @ts-expect-error ts-migrate(2339) FIXME: Property 'lightBlue' does not exist on type 'Color... Remove this comment to see the full error message
    backgroundColor: theme.palette.grey.lightBlue,
  },
}));
