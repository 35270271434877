import { Autorenew, Circle, DeleteForeverOutlined } from "@mui/icons-material";
import { CircularProgress } from "@mui/material";
import { AxiosResponse } from "axios";
import withRecord, { WithRecord } from "components/hoc/withRecord";
import Button from "components/ui/Button";
import Popup from "components/ui/Popup";
import Tile from "components/ui/tiles/Tile";
import TileSettings from "components/ui/tiles/TileSettings";
import Toggle from "components/ui/Toggle";
import Tooltip from "components/ui/Tooltip";
import { T } from "components/ui/Typography";
import { ConfigContext } from "config/ConfigProvider";
import { ProviderType } from "config/constants";
import muiTheme from "config/theme";
import useOauthState from "hooks/useOauthState";
import { usePolling } from "hooks/usePolling";
import usePushNotification from "hooks/usePushNotification";
import useSelectorWithDeepEquality from "hooks/useSelectorWithDeepEquality";
import useTrackDisabledDataSource, {
  dataSourceEvent,
} from "hooks/useTrackDisabledDataSource";
import generic from "i18n/generic";
import _, { debounce, isEmpty } from "lodash";
import { makeStyles } from "makeStyles";
import CrmCredential from "models/CrmCredential";
import { API_ERROR, isIntegration } from "models/Integration";
import SourceConfiguration from "models/SourceConfiguration";
import { useCallback, useContext, useEffect, useState } from "react";
import {
  defineMessages,
  FormattedHTMLMessage,
  FormattedMessage,
  MessageDescriptor,
  useIntl,
} from "react-intl";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { updateAttributesOnRecord } from "redux/api/actions";
import { selectAllIntegrations } from "redux/api/selectors";
import { retreive } from "redux/api/thunks";
import { RevealStore } from "redux/typing";
import APIKeyAndDomainDialog from "screens/Frontoffice/screens/Sources/shared/components/APIKeyAndDomainDialog";
import APIKeyDialog from "screens/Frontoffice/screens/Sources/shared/components/APIKeyDialog";
import MsDynamicsCrmUrlDialog from "screens/Frontoffice/screens/Sources/shared/components/MsDynamicsCrmUrlDialog";
import { StripeConnectDialog } from "screens/Frontoffice/screens/Sources/shared/components/StripeConnectDialog";
import {
  isAPIKeyAndDomainProviderType,
  isAPIKeyProviderType,
  isCrmFileOAuthProviderType,
} from "screens/Frontoffice/shared/helpers/types";
import JSONAPIService from "services/JSONAPIService";

import { ISyncMetadata } from "../types";
import TileDefault from "./DefaultSource/TileDefault";
import RefreshFieldsButton from "./RefreshFieldsButton";

type Props = WithRecord<CrmCredential> & {
  isSelected?: boolean;
  ghostPartnershipId?: number;
};

type DialogContent = {
  title?: MessageDescriptor;
  text?: MessageDescriptor;
  btn?: MessageDescriptor;
  onConfirm?: () => void;
};

const SIX_MINS_IN_MILLIS = 360000;

const CrmTile = ({
  isSelected,
  ghostPartnershipId,
  ready,
  record,
  removeRecord,
  updateRecord,
  fetchRecord,
  reloadRecord,
}: Props) => {
  const config = useContext(ConfigContext);
  const { classes, cx } = useStyles();
  const history = useHistory();
  const intl = useIntl();
  const pushNotification = usePushNotification();
  const [dialog, setDialog] = useState<DialogContent>({});
  const [deleting, setDeleting] = useState(false);
  const [syncResult, setSyncResult] = useState<ISyncMetadata | null>(null);
  const [isAPIKeyDialogOpen, setIsAPIKeyDialogOpen] = useState(false);
  const [
    isAPIKeyAndDomainDialogOpen,
    setIsAPIKeyAndDomainDialogOpen,
  ] = useState(false);
  const [isMsDialogOpen, setIsMsDialogOpen] = useState(false);
  const [isStripeConnect, setIsStripeConnectOpen] = useState(false);
  const { start } = useOauthState();
  const details = config.crmProviders
    ? config.crmProviders[record.provider as ProviderType]
    : undefined;
  const dispatch = useDispatch();
  const provider = record.provider;
  const isGsheet = isCrmFileOAuthProviderType(provider);
  const nextOnRedirect = `${history.location.pathname}?provider=${provider}${
    ghostPartnershipId ? `&isGhost=${ghostPartnershipId}` : ""
  }${isGsheet ? "&integrationId=" + record.integrationId : ""}#loading`;

  const integrations = useSelectorWithDeepEquality(selectAllIntegrations);
  const sourceConfigurations = useSelectorWithDeepEquality(
    selectAllSourceConnections
  );
  const integration = integrations.find(
    (integration) => integration.id === record.integrationId
  );
  const defaultCount = integrations.filter(({ defaultSource }) => defaultSource)
    .length;

  const trackDisabledDataSource = useTrackDisabledDataSource(integrations);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedReload = useCallback(debounce(reloadRecord, 1000), [
    reloadRecord,
  ]);
  if (!record.hasEmptyConfig() && !record.integrationId) {
    debouncedReload();
  }

  useEffect(() => {
    if (!isGsheet || record.integrationId === null) {
      return;
    }
    dispatch(
      retreive({
        id: Number(record.integrationId),
        type: ghostPartnershipId
          ? "ghost_source_configurations"
          : "source_configurations",
      })
    );
  }, [
    isGsheet,
    ghostPartnershipId,
    record.integrationId,
    record.provider,
    dispatch,
  ]);

  useEffect(() => {
    if (record.integrationId !== null && integration === undefined) {
      /**
       * If there is a value for integrationId, it means that the integration exists.
       * In such case, if integration is not available, we can safely assume that is
       * can be loaded.
       */
      if (ghostPartnershipId) {
        fetchRecord("ghost_integrations", record.integrationId, {
          filters: { ghost_partnership_id: ghostPartnershipId },
          include: ["partnership_integrations"],
          fields: {
            integrations: [
              "company",
              "mapping_configured",
              "name",
              "provider",
              "default_source",
              "partnership_integrations",
              "api_error",
            ],
          },
        });
        return;
      }
      fetchRecord("integrations", record.integrationId);
    }
  }, [ghostPartnershipId, record.integrationId, integration, fetchRecord]);

  const handleClose = () => setDialog({});

  const handleOpenMSModal = () => {
    setIsMsDialogOpen(true);
  };
  const handleOpenAPIKeyModal = () => {
    setIsAPIKeyDialogOpen(true);
  };
  const handleOpenAPIKeyAndDomainModal = () => {
    setIsAPIKeyAndDomainDialogOpen(true);
  };

  const handleStripeConnect = () => {
    setIsStripeConnectOpen(true);
  };

  const startOauth = () => {
    if (provider === ProviderType.gsheet) {
      if (record) {
        // starting oauth with a record - expecting a reconnection
        localStorage.reconnect_gsheet_key = record.config["gsheet_id_and_tab"];
      } else {
        // new connection - make sure to remove entries from local storage
        localStorage.removeItem("reconnect_gsheet_key");
      }
    }
    start(provider, nextOnRedirect);
  };

  const getCurrentTime = () => {
    return new Date(Date.now()).toISOString();
  };

  const handleDisconnect = () => {
    setDeleting(true);
    updateRecord({ disabled: true })
      .then((result: $TSFixMe) => {
        if (result.error) {
          pushNotification(i18n.errorCannotDisable);
        } else {
          trackDisabledDataSource(dataSourceEvent.disabled, record.provider);
        }
      })
      .finally(() => setDeleting(false));
    if (integration) {
      dispatch(
        updateAttributesOnRecord(
          { id: String(integration.id), type: integration.type },
          { disabled_at: getCurrentTime() }
        )
      );
    }
  };

  const handleDelete = () => {
    setDeleting(true);
    removeRecord().then((result: $TSFixMe) => {
      if (result && result.error) {
        pushNotification(i18n.errorCannotDelete);
      } else {
        trackDisabledDataSource(dataSourceEvent.deleted, record.provider);
        if (integration) {
          dispatch(
            updateAttributesOnRecord(
              { id: String(integration.id), type: integration.type },
              { deleted_at: getCurrentTime() }
            )
          );
        }
      }
    });
  };

  const sync = useCallback(async () => {
    if (!record.integrationId) {
      return;
    }
    setDeleting(true);
    const service = new JSONAPIService("integration_syncs");
    try {
      await service.rawPost(record.integrationId, "/synchronize/", {});
    } catch (error) {
      pushNotification(i18n.errorCannotLaunchSync);
      return;
    } finally {
      setDeleting(false);
    }
    setSyncResult({
      completedAt: null,
      failedAt: null,
      createdAt: Date.now(),
    });
  }, [pushNotification, record.integrationId]);

  const handleSync = async () => {
    await sync();
    handleClose();
  };

  const handleReconnect = () => {
    isAPIKeyAndDomainProviderType(provider)
      ? handleOpenAPIKeyAndDomainModal()
      : isAPIKeyProviderType(provider)
      ? handleOpenAPIKeyModal()
      : provider === ProviderType.msDynamics
      ? handleOpenMSModal()
      : provider === ProviderType.stripe
      ? handleStripeConnect()
      : startOauth();
  };

  const startDisconnect = () => {
    setDialog({
      title: i18n.disconnectDialogTitle,
      text: i18n.disconnectDialogText,
      btn: generic.confirm,
      onConfirm: handleDisconnect,
    });
  };

  const startDelete = () => {
    setDialog({
      title: i18n.deleteDialogTitle,
      text: i18n.deleteDialogText,
      btn: i18n.deleteDialogConfirm,
      onConfirm: handleDelete,
    });
  };

  const syncNow = () => {
    setDialog({
      title: i18n.syncDialogTitle,
      text: i18n.syncDialogText,
      btn: i18n.syncDialogConfirm,
      onConfirm: handleSync,
    });
  };

  const syncFailed = !!syncResult?.failedAt;
  const syncedInSixMins =
    !!syncResult &&
    !!syncResult.completedAt &&
    Date.now() - syncResult.completedAt < SIX_MINS_IN_MILLIS;
  const syncLoading =
    !!syncResult &&
    !!syncResult.createdAt &&
    !syncResult.completedAt &&
    !syncFailed;

  const handleResponse = useCallback(
    (response: {
      created_at?: string;
      failed_at?: string;
      completed_at?: string;
    }) => {
      setSyncResult({
        completedAt: response?.completed_at
          ? new Date(response.completed_at).getTime()
          : null,
        createdAt: response?.created_at
          ? new Date(response.created_at).getTime()
          : null,
        failedAt: response?.failed_at
          ? new Date(response.failed_at).getTime()
          : null,
      });
      if (syncLoading && response?.completed_at) {
        pushNotification(i18n.forceSyncCompleted, {
          name: integration?.name,
        });
      } else if (syncLoading && response?.failed_at) {
        pushNotification(i18n.forceSyncFailed, {
          name: integration?.name,
        });
      }
    },
    [integration?.name, pushNotification, syncLoading]
  );

  const fetchSync = useCallback(async () => {
    setDeleting(true);
    const service = new JSONAPIService("integration_syncs");
    let result: undefined | AxiosResponse = undefined;
    try {
      result = await service.rawGet(`/${record.integrationId}/`);
    } catch (error) {
      return;
    } finally {
      setDeleting(false);
    }
    handleResponse(result?.data.data?.attributes?.last_sync_run_data);
  }, [record.integrationId, handleResponse]);

  useEffect(() => {
    if (!isGsheet || record.integrationId === null) {
      return;
    }
    fetchSync();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  usePolling(fetchSync, 5000, syncLoading);

  let handleSettingsClick: () => void;
  let errorMessage: null | MessageDescriptor;
  if (integration === undefined || !isIntegration(integration)) {
    handleSettingsClick = () => {};
    errorMessage = null;
  } else {
    handleSettingsClick = () => {
      history.push({
        search: `integration=${integration.id}&provider=${record.provider}`,
        hash: isGsheet
          ? ghostPartnershipId
            ? "#column-mapping-private"
            : "#column-mapping"
          : "#status-rules",
      });
    };
    errorMessage = integration.getErrorMessage();
  }
  if (
    isGsheet &&
    integration?.apiError !== API_ERROR.UNAUTHORIZED_CREDENTIALS
  ) {
    // we display reconnect button if we have unauthorized credentials
    // we display error messages in other error cases
    const sourceConfiguration = sourceConfigurations.find(
      (configuration) => configuration.id === record.integrationId
    );
    if (isEmpty(sourceConfiguration?.rawCompanyCustomMapping)) {
      errorMessage = i18n.errorGsheetMissingMapping;
    } else if (!integration?.mappingConfigured) {
      errorMessage = i18n.errorGsheetBrokenMapping;
    }
  }

  const renderGsheetSyncNowButton =
    !record.disabled &&
    isGsheet &&
    ![
      i18n.errorGsheetMissingMapping as MessageDescriptor,
      i18n.errorGsheetBrokenMapping,
    ].includes(errorMessage ?? {});

  const renderGsheetReconnectButton =
    record.disabled &&
    isGsheet &&
    integration?.apiError === API_ERROR.UNAUTHORIZED_CREDENTIALS;

  if (_.isUndefined(details) || record.hasEmptyConfig()) {
    return null;
  }
  return (
    <>
      <Tile
        checked={integration?.defaultSource}
        inactive={deleting}
        size="sm"
        className={classes.tileContainer}
      >
        {integration && !ghostPartnershipId && (
          <TileDefault
            record={record}
            integration={integration}
            defaultCount={defaultCount}
          />
        )}
        <div className={classes.logo}>
          <img src={details.logo} alt="" />
          <T
            h4
            bold
            className={cx(classes.nameContainer, classes.noWrap, {
              [classes.greyText]: !integration?.defaultSource && !isSelected,
            })}
            title={details.name}
          >
            {details.name}
          </T>
        </div>
        <div className={classes.flexGrow}>
          <T
            className={classes.subtitle}
            color={
              errorMessage
                ? muiTheme.palette.error.main
                : !integration?.defaultSource
                ? muiTheme.palette.greyReveal
                : undefined
            }
            title={errorMessage ? intl.formatMessage(errorMessage) : undefined}
          >
            {errorMessage && <FormattedMessage {...errorMessage} />}
            {!errorMessage && !record.disabled && record.config.email && (
              <span title={String(record.config.email)}>
                <FormattedHTMLMessage
                  {...i18n.connectedAs}
                  values={{ email: String(record.config.email) }}
                />
              </span>
            )}
            {!errorMessage && !record.disabled && record.config.api_key && (
              <FormattedMessage
                {...i18n.withApiKey}
                values={{ key: record.config.api_key }}
              />
            )}
          </T>
          {record.config.tab_name && (
            <T
              className={classes.subtitle}
              color={
                !integration?.defaultSource
                  ? muiTheme.palette.greyReveal
                  : undefined
              }
              title={integration?.name ?? undefined}
            >
              {integration?.name}
            </T>
          )}
        </div>
        {/* TODO-RVL-6277 */}
        {(!ready || deleting) && (
          <CircularProgress className={classes.spinner} />
        )}
        <div className={classes.buttonContainer}>
          {renderGsheetSyncNowButton && (
            <Tooltip
              title={
                syncFailed || syncedInSixMins || syncLoading ? (
                  <div className={classes.tooltipContainer}>
                    {syncFailed ? (
                      <FormattedMessage {...i18n.syncFailed} />
                    ) : syncLoading ? (
                      <FormattedMessage {...i18n.syncLoading} />
                    ) : syncedInSixMins ? (
                      <FormattedMessage {...i18n.syncedInSixMins} />
                    ) : (
                      ""
                    )}
                  </div>
                ) : (
                  ""
                )
              }
            >
              <div className={classes.buttonContainer}>
                <Button
                  disabled={
                    !syncFailed && (deleting || syncLoading || syncedInSixMins)
                  }
                  loading={!syncFailed && (deleting || syncLoading)}
                  size="xSmall"
                  variant="secondary"
                  label={syncLoading ? i18n.syncing : i18n.syncNow}
                  onClick={syncNow}
                  RightIcon={
                    !syncFailed && (deleting || syncLoading)
                      ? Circle
                      : undefined
                  }
                  data-testid="google-sync-button"
                />
              </div>
            </Tooltip>
          )}
          {!renderGsheetReconnectButton && (
            <div
              className={cx(classes.labelContainer, {
                [classes.greyText]: !integration?.defaultSource && !isSelected,
              })}
            >
              <T bodySmall>
                <FormattedMessage
                  {...(record.disabled ? i18n.disconnected : i18n.connected)}
                />
              </T>
              <Toggle
                disabled={deleting}
                size="big"
                checked={!record.disabled}
                handleChange={
                  record.disabled ? handleReconnect : startDisconnect
                }
              />
            </div>
          )}
          {renderGsheetReconnectButton && (
            <Button
              data-testid="reconnect-gsheet-button"
              disabled={deleting}
              label={i18n.gsheetReconnectLabel}
              LeftIcon={Autorenew}
              onClick={handleReconnect}
              size="small"
              variant="tertiary"
            />
          )}
          {integration && !isGsheet && !record.disabled && (
            <RefreshFieldsButton integrationId={integration.id} />
          )}
          {integration && (
            <TileSettings
              disabled={deleting}
              handleClick={handleSettingsClick}
            />
          )}
          {record.disabled && (
            <Tooltip title={<FormattedMessage {...i18n.deleteTooltip} />}>
              <div>
                <Button
                  data-testid="delete-crm-button"
                  disabled={deleting}
                  LeftIcon={DeleteForeverOutlined}
                  onClick={startDelete}
                  size="small"
                  variant="tertiary"
                />
              </div>
            </Tooltip>
          )}
        </div>
        <Popup
          isOpen={Boolean(dialog.onConfirm)}
          title={
            dialog.title && (
              <T h3 bold>
                <FormattedMessage {...dialog.title} />
              </T>
            )
          }
          handleClose={handleClose}
          handleSave={() => {
            dialog.onConfirm && dialog.onConfirm();
            handleClose();
          }}
          maxWidth="sm"
          saveButtonText={dialog.btn || undefined}
          variant="secondary"
        >
          {dialog.text && (
            <T>
              <FormattedHTMLMessage {...dialog.text} />
            </T>
          )}
        </Popup>
        {record.config.sandbox && (
          <span className={classes.footnote}>
            <FormattedMessage {...i18n.sandbox} />
          </span>
        )}
      </Tile>
      {isAPIKeyAndDomainProviderType(record.provider) && (
        <APIKeyAndDomainDialog
          provider={record.provider}
          isOpen={isAPIKeyAndDomainDialogOpen}
          onClose={() => setIsAPIKeyAndDomainDialogOpen(false)}
          next={nextOnRedirect}
        />
      )}
      {isAPIKeyProviderType(record.provider) && (
        <APIKeyDialog
          provider={record.provider}
          isOpen={isAPIKeyDialogOpen}
          onClose={() => setIsAPIKeyDialogOpen(false)}
          next={nextOnRedirect}
        />
      )}
      {record.provider === ProviderType.stripe && (
        <StripeConnectDialog
          isOpen={isStripeConnect}
          onClose={() => setIsStripeConnectOpen(false)}
          next={nextOnRedirect}
        />
      )}

      <MsDynamicsCrmUrlDialog
        isOpen={isMsDialogOpen}
        onClose={() => setIsMsDialogOpen(false)}
        next={nextOnRedirect}
      />
    </>
  );
};

export default withRecord<CrmCredential, Props>("crm_credentials", {
  loadOnMount: false,
})(CrmTile);

/// Internal

export const selectAllSourceConnections = (state: RevealStore) =>
  Object.values(
    state.api.entities["source_configurations"] ?? {}
  ) as SourceConfiguration[];

/// CSS

export const useStyles = makeStyles()((theme) => ({
  buttonContainer: {
    alignItems: "center",
    display: "flex",
    justifyContent: "end",
    gap: theme.spacing(1),
    "& > button > div > p": {
      whiteSpace: "nowrap",
    },
  },
  flexGrow: {
    flexGrow: 1,
  },
  greyText: {
    color: theme.palette.greyReveal,
  },
  tileContainer: {
    alignItems: "center",
    display: "flex",
    gap: theme.spacing(1.5),
    padding: "12px !important",
    margin: 0,
    position: "relative",
  },
  labelContainer: {
    alignItems: "center",
    display: "flex",
    gap: theme.spacing(1),
    marginRight: theme.spacing(0.5),
  },
  logo: {
    display: "flex",
    alignItems: "center",
    gap: theme.spacing(1),
    justifyContent: "flex-start",
    "& > img": {
      height: 36,
      borderRadius: 8,
    },
  },
  nameContainer: {
    display: "-webkit-box",
    "-webkit-line-clamp": "2",
    "-webkit-box-orient": "vertical",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  noWrap: {
    whiteSpace: "nowrap",
  },
  subtitle: {
    alignItems: "center",
    display: "-webkit-box",
    overflow: "hidden",
    "-webkit-line-clamp": "1",
    "-webkit-box-orient": "vertical",
    "& > :not(:last-child)": {
      marginRight: theme.spacing(1),
    },
  },
  action: {
    padding: 0,
    borderStyle: "none",
    fontSize: 10,
    fontWeight: 600,
    color: theme.palette.other
      ? theme.palette.link
      : theme.palette.primary.main,
    cursor: "pointer",
    background: "transparent",
    "&:hover": {
      textDecoration: "underline",
    },
  },
  warning: {
    color: theme.palette.warning.main,
  },
  footnote: {
    position: "absolute",
    bottom: theme.spacing(0.5),
    paddingRight: theme.spacing(1),
    fontSize: 10,
    textAlign: "right",
    fontStyle: "italic",
    left: 0,
    width: "100%",
  },
  spinner: {
    color: theme.palette.midnight,
    height: "10px !important",
    width: "10px !important",
  },
  tooltipContainer: {
    textAlign: "center",
    padding: `${theme.spacing(1)} 0`,
  },
}));

const i18n = defineMessages({
  connectedAs: {
    id: "CrmTile.connectedAs",
    defaultMessage: "Connected as {email}",
  },
  deleteTooltip: {
    id: "CrmTile.deleteTooltip",
    defaultMessage: "Delete",
  },
  errorCannotLaunchSync: {
    id: "CrmTile.errorCannotLaunchSync",
    defaultMessage: "Cannot launch sync, please try again",
  },
  sandbox: {
    id: "CrmTile.sandbox",
    defaultMessage: "Sandbox",
  },
  withApiKey: {
    id: "CrmTile.withApiKey",
    defaultMessage: "API Key: {key}",
  },
  connected: {
    id: "CrmTile.disconnect",
    defaultMessage: "Connected",
  },
  disconnected: {
    id: "CrmTile.disabled",
    defaultMessage: "Disconnected",
  },
  errorGsheetBrokenMapping: {
    id: "CrmTile.errorGsheetBrokenMapping",
    defaultMessage: "We encountered a problem with the mapping, please update",
  },
  errorGsheetMissingMapping: {
    id: "CrmTile.errorGsheetMissingMapping",
    defaultMessage:
      "Configure the mapping of this Google Sheet for it to be imported",
  },
  errorCannotDelete: {
    id: "CrmTile.errorCannotDelete",
    defaultMessage:
      "Something wrong happened, the connection could not be deleted",
  },
  errorCannotDisable: {
    id: "CrmTile.errorCannotDisable",
    defaultMessage:
      "Something wrong happened, the connection could not be disabled",
  },
  deleteDialogTitle: {
    id: "CrmTile.deleteDialogTitle",
    defaultMessage: "Are you sure you want to delete this data source?",
  },
  deleteDialogText: {
    id: "CrmTile.deleteDialogText",
    defaultMessage:
      'By clicking "Yes", Reveal will delete all data from your CRM within 24 hours.<br/><br/><strong>You and your Reveal partners will no longer be able to access any shared data from this data source.</strong>',
  },
  disconnectDialogTitle: {
    id: "CrmTile.disonnectDialogTitle",
    defaultMessage: "Are you sure you want to disconnect this data source?",
  },
  disconnectDialogText: {
    id: "CrmTile.disonnectDialogText",
    defaultMessage:
      "Reveal will no longer be accessing data from your CRM instance.<br/><br/>Your active partnerships on Reveal will remain active but your data won't be up-to-date anymore.",
  },
  deleteDialogConfirm: {
    id: "CrmTile.deleteDialogConfirm",
    defaultMessage: "Yes, I want to delete",
  },
  forceSyncCompleted: {
    id: "CrmTile.forceSyncCompleted",
    defaultMessage: "Sync for {name} completed successfully",
  },
  forceSyncFailed: {
    id: "CrmTile.forceSyncFailed",
    defaultMessage: "Sync for {name} has failed",
  },
  gsheetReconnectLabel: {
    id: "CrmTile.gsheetReconnectLabel",
    defaultMessage: "Reconnect Google Sheet",
  },
  syncDialogConfirm: {
    id: "CrmTile.syncDialogConfirm",
    defaultMessage: "Sync Now",
  },
  syncDialogTitle: {
    id: "CrmTile.syncDialogTitle",
    defaultMessage: "Are you sure you want to sync this data source?",
  },
  syncDialogText: {
    id: "CrmTile.syncDialogText",
    defaultMessage:
      'By clicking "Sync Now", Reveal will request for a forced synchronization your Google Sheet.',
  },
  syncFailed: {
    id: "CrmTile.syncFailed",
    defaultMessage: "The last sync has failed",
  },
  syncing: {
    id: "CrmTile.syncing",
    defaultMessage: "Syncing",
  },
  syncLoading: {
    id: "CrmTile.syncLoading",
    defaultMessage:
      "Sync in progress. Please wait for another few minutes before launching a new sync",
  },
  syncedInSixMins: {
    id: "CrmTile.syncedInSixMins",
    defaultMessage:
      "Please wait at least for 5 minutes before launching a new sync",
  },
  syncNow: {
    id: "CrmTile.syncNow",
    defaultMessage: "Sync Now",
  },
});
