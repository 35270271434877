import { Box, darken } from "@mui/material";
import { isFulfilled } from "@reduxjs/toolkit";
import Button from "components/ui/Button";
import Dropdown from "components/ui/Dropdown";
import { IDropdownOption } from "components/ui/Dropdown/components/types";
import Tooltip from "components/ui/Tooltip";
import { T } from "components/ui/Typography";
import theme from "config/theme";
import usePushNotification from "hooks/usePushNotification";
import useSegment from "hooks/useSegment";
import useSelectorWithDeepEquality from "hooks/useSelectorWithDeepEquality";
import useUserProfile from "hooks/useUserProfile";
import generic from "i18n/generic";
import _ from "lodash";
import { makeStyles } from "makeStyles";
import {
  Kind,
  Stage as PartnerConnectionStage,
  stageEncodeMapping as PartnerConnectionStageEncodeMapping,
} from "models/PartnerConnection";
import {
  Stage as PipelineOpportunityStage,
  stageEncodeMapping as PipelineOpportunityStageEncodeMapping,
} from "models/PipelineOpportunity";
import { RecordType } from "models/types";
import User from "models/User";
import { MouseEvent, useEffect, useState } from "react";
import { defineMessages, FormattedMessage, useIntl } from "react-intl";
import { useDispatch } from "react-redux";
import { update } from "redux/api/thunks";
import { AvailablePipelineColumns } from "redux/pipeline/defaults";
import { selectCurrentUser } from "redux/pipeline/selectors";
import { PipelineEvent, PipelineTableEditAction } from "tracking";

import { CellProps } from "../../utils";
import { DropdownCell } from "../shared/DropdownCell";
import { DropdownGroup } from "../shared/useDropdownCell";
import { DropdownFooter } from "./KindCell/components/DropdownFooter";
import { useKindCell } from "./KindCell/hooks/useKindCell";

const StageCell = (props: CellProps) => {
  const { row, value } = props;
  const dispatch = useDispatch();
  const { track } = useSegment();
  const intl = useIntl();
  const pushNotification = usePushNotification();
  const { profile } = useUserProfile();
  const currentUser = useSelectorWithDeepEquality(selectCurrentUser);
  const { allowEdit, disabledText } = getValues({
    row,
    currentUserId: currentUser?.id,
    profile,
  });
  const { classes } = useStyles();
  const { classes: toggleButtonClasses } = useStageStyles({ stage: value });
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

  const isCollaboratePipeline = row.type === RecordType.PartnerConnection;

  const optionsGroups: DropdownGroup<
    PartnerConnectionStage | PipelineOpportunityStage
  >[] = [
    {
      noContentPlaceholder: intl.formatMessage(i18n.noOptionsAvailable),
      options: _.values(
        isCollaboratePipeline
          ? PartnerConnectionStage
          : PipelineOpportunityStage
      ),
      onClick: (stage: PartnerConnectionStage | PipelineOpportunityStage) => {
        if (stage === PartnerConnectionStage.introMade) {
          return handleOpen;
        } else {
          return undefined;
        }
      },
    },
  ];

  const {
    CustomHeader,
    optionsGroups: kindOptionsGroups,
    getLabelWithIcon,
    updateAttributes,
    currentKindValue,
    setCurrentKindValue,
    clearAttributes,
  } = useKindCell({ row });

  const handleChangeKind = (items: string[] | null) => {
    setCurrentKindValue(items?.[0] as Kind);
  };

  const getLabelText = (
    stage: PartnerConnectionStage | PipelineOpportunityStage
  ) => _.capitalize(_.lowerCase(stage));
  const getLabel = (stage: PartnerConnectionStage | PipelineOpportunityStage) =>
    getLabelText(stage);

  const updateStage = async (newStage: IDropdownOption) => {
    if (newStage.id === PartnerConnectionStage.introMade) {
      return;
    }
    setAnchorEl(null);
    const response = await dispatch(
      update({
        id: row.id,
        type: row.type,
        attributes: {
          stage: isCollaboratePipeline
            ? PartnerConnectionStageEncodeMapping[
                newStage.id as PartnerConnectionStage
              ]
            : PipelineOpportunityStageEncodeMapping[
                newStage.id as PipelineOpportunityStage
              ],
          ...(newStage.id === PartnerConnectionStage.toIntro && {
            accepted_date: new Date().toISOString(),
          }),
          ...(newStage.id === PartnerConnectionStage.declined && {
            accepted_date: null,
          }),
        },
      })
    );

    if (isFulfilled(response)) {
      // TODO RVL-10655: add this tracking to attribute dropdown value selected as well
      track(PipelineEvent.tableEdit, {
        action: PipelineTableEditAction.updateStage,
      });
    } else {
      pushNotification("default_error");
    }
  };

  const handleOpen = (
    event: MouseEvent<HTMLDivElement | HTMLButtonElement>
  ) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => setAnchorEl(null);
  const handleSave = (attributionDate: string | null) => {
    updateAttributes(attributionDate, true);
  };

  // handling the hover effect of the dropdown item
  useEffect(() => {
    const element = document.getElementById(PartnerConnectionStage.introMade);
    if (!element) {
      return;
    }

    if (!!anchorEl) {
      element.classList.add("hovered");
    } else {
      element.classList.remove("hovered");
    }
  }, [anchorEl]);

  if (!allowEdit || !isCollaboratePipeline) {
    let label;
    if (value === null) {
      label = "--";
    } else {
      label = getLabelText(value);
    }
    if (disabledText) {
      return (
        <Tooltip title={disabledText} placement="top">
          <Box overflow="hidden">
            <Button label={label} size="small" variant={"septenary"} ellipsis />
          </Box>
        </Tooltip>
      );
    }
    return (
      <div title={label} className={classes.labelContainer}>
        <T noWrap>{label}</T>
      </div>
    );
  }

  return (
    <>
      <DropdownCell<PartnerConnectionStage | PipelineOpportunityStage>
        optionsGroups={optionsGroups}
        getLabel={getLabel}
        selectedItem={value}
        unselectedText={intl.formatMessage(i18n.chooseStage)}
        onChange={updateStage}
        cellType="stage"
        withSearch={false}
        // overwrite 'septenary' instead of just 'btn' because it's the variant used
        // in the DropdownCell component and using 'btn' would require !important, this one doesn't
        // but these styles will not work if the component is not using the 'septenary' variant
        buttonClasses={{ septenary: toggleButtonClasses.root }}
        classes={{ root: classes.tempHiddenOption }} // TODO: Remove this class when BE had those related option removed
      />
      {!!anchorEl && (
        <Dropdown
          position="right"
          CustomFooter={
            <DropdownFooter
              onSave={handleSave}
              value={row.activityDate}
              onClear={clearAttributes}
            />
          }
          CustomHeader={CustomHeader}
          value={[currentKindValue ?? row.kind ?? null]}
          options={kindOptionsGroups[0]?.options?.map((o) => ({
            id: String(o),
            label: getLabelWithIcon(o),
            name: String(o).replace(
              /(^\w|\s\w)(\S*)/g,
              (_, m1, m2) => m1.toUpperCase() + m2.toLowerCase()
            ),
          }))}
          anchorEl={anchorEl}
          onClose={handleClose}
          open={!!anchorEl}
          onChange={handleChangeKind}
          size={"small"}
          withSearch={false}
        />
      )}
    </>
  );
};

export default StageCell;

// Helpers

const STAGE_TO_DISPLAY_COLORS: {
  [key in PartnerConnectionStage]?: {
    background?: string;
    border?: string;
    color?: string;
  };
} = {
  [PartnerConnectionStage.closed]: {
    background: theme.palette.greyDarkMode800,
    color: theme.palette.greyDarkMode200,
  },
  [PartnerConnectionStage.declined]: {
    background: theme.palette.red100,
    color: theme.palette.red700v2,
  },
  [PartnerConnectionStage.introMade]: {
    background: theme.palette.green100v2,
    color: theme.palette.green700v2,
  },
  [PartnerConnectionStage.meetingBooked]: {
    background: theme.palette.cyan100,
    color: theme.palette.cyan700,
  },
  [PartnerConnectionStage.onHold]: {
    background: theme.palette.brown100,
    color: theme.palette.brown700,
  },
  [PartnerConnectionStage.opportunity]: {
    background: theme.palette.purpleBrand100,
    color: theme.palette.purple700,
  },
  [PartnerConnectionStage.toDiscuss]: {
    background: theme.palette.ivory,
    border: theme.palette.greyscale200,
    color: theme.palette.midnight,
  },
  [PartnerConnectionStage.toIntro]: {
    background: theme.palette.yellow100,
    color: theme.palette.yellow700,
  },
};

export const isStageCell = ({ fieldName }: CellProps) =>
  fieldName === AvailablePipelineColumns.stage;

const getValues = ({
  row,
  currentUserId,
  profile,
}: {
  row: any;
  currentUserId?: number;
  profile: User;
}) => {
  if (!currentUserId) {
    return { allowEdit: false, disabledText: undefined };
  }
  const isAdminOrPM =
    profile.isCompanyAdmin() || profile.isPartnershipManager();
  const isUserItem = row.userId === currentUserId;
  const dataFromSync = row.rawOpportunitiesWithRule?.length > 0 ?? false;
  const disabledText = dataFromSync ? (
    <FormattedMessage {...generic.cannotEditFromCRM} />
  ) : undefined;
  const allowEdit = !dataFromSync && (isAdminOrPM || isUserItem);

  return { allowEdit, disabledText };
};

// CSS

const useStyles = makeStyles()(() => ({
  labelContainer: {
    display: "inline-flex",
    overflow: "hidden",
    marginLeft: 10,
  },
  divider: {
    margin: "8px -12px",
    borderBottom: `1px solid ${theme.palette.taupe500}`,
    width: "unset",
  },
  // TODO: Remove this class when BE had those related option removed
  tempHiddenOption: {
    "& #Closed": { display: "none" },
    "& [id='Meeting booked']": { display: "none" },
    "& #Opportunity": { display: "none" },
  },
}));

const useStageStyles = makeStyles<{ stage: PartnerConnectionStage }>()(
  (theme, { stage }) => ({
    root: {
      backgroundColor: STAGE_TO_DISPLAY_COLORS[stage]?.background,
      color: STAGE_TO_DISPLAY_COLORS[stage]?.color,
      borderColor:
        STAGE_TO_DISPLAY_COLORS[stage]?.border ??
        STAGE_TO_DISPLAY_COLORS[stage]?.background,
      "&:hover": {
        backgroundColor: darken(
          (STAGE_TO_DISPLAY_COLORS[stage]?.background ??
            theme.palette.ivory) as string,
          0.1
        ),
      },
    },
  })
);

// I18N

const i18n = defineMessages({
  noOptionsAvailable: {
    id: "CellRenderer.Pipeline.StageCell.noOptionsAvailable",
    defaultMessage: "No options available",
  },
  chooseStage: {
    id: "CellRenderer.Pipeline.StageCell.chooseStage",
    defaultMessage: "Choose stage",
  },
  chooseKind: {
    id: "CellRenderer.Pipeline.StageCell.chooseKind",
    defaultMessage: "Attribute",
  },
});
