import { ChevronDown } from "components/icons";
import Button from "components/ui/Button";
import Dropdown from "components/ui/Dropdown/components/Dropdown";
import { IDropdownOption } from "components/ui/Dropdown/components/types";
import DropdownSubmitFooter from "components/ui/DropdownSubmitFooter";
import _ from "lodash";
import { MouseEvent, useMemo, useRef, useState } from "react";
import { defineMessages, FormattedMessage } from "react-intl";

type Props = {
  options: {
    [key: string]: string | number;
  };
  disabled?: boolean;
  onChange: (ids: string[] | null) => void;
  value: string[] | null;
};

export const OwnerFilterMenu = ({
  options,
  disabled = false,
  onChange,
  value,
}: Props) => {
  const filterMenuRef = useRef(null);
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const [partnerSelectorIsOpen, setPartnerSelectorIsOpen] = useState(false);
  const [ids, setIds] = useState<string[] | null>(value);

  const ownersForDropdown = useMemo(() => {
    const optionsForDropdown: IDropdownOption[] = [];
    Object.entries(options ?? {}).forEach(([key, optionItem]) => {
      const option: IDropdownOption = {
        id: key,
        name: String(optionItem),
      };
      optionsForDropdown.push(option);
    });

    return optionsForDropdown;
  }, [options]);
  const sameSelection = _.isEqual(
    ids,
    ownersForDropdown.map((option) => option.id)
  );

  const handleOpenDropdown = (target: any) => {
    if (!value?.length) {
      checkCheckboxes();
    }
    setAnchorEl(target);
    setPartnerSelectorIsOpen(true);
  };

  const handleCloseDropdown = () => {
    setPartnerSelectorIsOpen(false);
    setAnchorEl(null);
  };

  const handleSubmit = () => {
    onChange(ids);
    handleCloseDropdown();
  };

  const checkCheckboxes = () => {
    setIds(ownersForDropdown.map((option) => option.id));
  };

  const handleClick = (event: MouseEvent<HTMLButtonElement>) => {
    if (!partnerSelectorIsOpen) {
      handleOpenDropdown(event.currentTarget);
    } else {
      handleCloseDropdown();
    }
  };

  return (
    <div ref={filterMenuRef}>
      <Button
        disabled={disabled}
        data-testid="owner-filter-btn"
        label={
          <FormattedMessage
            {...i18n.owners}
            values={{ count: ids?.length ?? 0 }}
          />
        }
        onClick={handleClick}
        RightIcon={ChevronDown}
        size="small"
        variant="quinary"
      />
      <Dropdown
        options={ownersForDropdown}
        anchorEl={anchorEl}
        onClose={handleCloseDropdown}
        open={Boolean(anchorEl)}
        withSearch
        withCheckBox
        value={ids}
        nullValueMeansAllSelected
        onChange={(ids) => setIds(ids)}
        isMultiselect
        allowSelectAll
        CustomFooter={
          <DropdownSubmitFooter
            onApply={handleSubmit}
            onCancel={handleCloseDropdown}
            disabledApplyBtn={sameSelection}
          />
        }
      />
    </div>
  );
};

const i18n = defineMessages({
  owners: {
    id: "Pipeline.OwnerFilterMenu.owners",
    defaultMessage:
      "{count, plural, =0 {Owners} one {1 owner} other {{count} owners}}",
  },
});
