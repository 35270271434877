import { ComponentType } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import {
  attachToRecord,
  createRecord,
  fetchRecord,
  rawPatch,
  rawPost,
  removeRecord,
  updateRecord,
} from "redux/api/legacyProxies";
import {
  attachToRecord as attachToRecordV2,
  create,
  destroy,
  rawPatch as rawPatchV2,
  rawPost as rawPostV2,
  retreive,
  update,
} from "redux/api/thunks";
import { AppDispatch } from "store";

const mapDispatch = (dispatch: AppDispatch) =>
  bindActionCreators(
    {
      attachToRecord,
      createRecord,
      fetchRecord,
      rawPost,
      rawPatch,
      removeRecord,
      updateRecord,
    },
    dispatch
  );

export type WithAPI = {
  createRecord: (
    ...args: Parameters<typeof createRecord>
  ) => Promise<ReturnType<typeof create.rejected> | number>;
  fetchRecord: (
    ...args: Parameters<typeof fetchRecord>
  ) => Promise<
    ReturnType<typeof retreive.rejected | typeof retreive.fulfilled>
  >;
  removeRecord: (
    ...args: Parameters<typeof removeRecord>
  ) => Promise<ReturnType<typeof destroy.rejected | typeof destroy.fulfilled>>;
  updateRecord: (
    ...args: Parameters<typeof updateRecord>
  ) => Promise<ReturnType<typeof update.rejected | typeof update.fulfilled>>;
  rawPatch: (
    ...args: Parameters<typeof rawPatch>
  ) => Promise<
    ReturnType<typeof rawPatchV2.rejected | typeof rawPatchV2.fulfilled>
  >;
  rawPost: (
    ...args: Parameters<typeof rawPost>
  ) => Promise<
    ReturnType<typeof rawPostV2.rejected | typeof rawPostV2.fulfilled>
  >;
  attachToRecord: (
    ...args: Parameters<typeof attachToRecord>
  ) => Promise<
    ReturnType<
      typeof attachToRecordV2.rejected | typeof attachToRecordV2.fulfilled
    >
  >;
};

function withAPI<P extends WithAPI = WithAPI>(
  WrappedComponent: ComponentType<P>
) {
  const Component = (props: P) => <WrappedComponent {...props} />;
  // @ts-expect-error
  const component = connect(null, mapDispatch)(Component) as ComponentType<
    Omit<P, keyof WithAPI>
  >;
  component.displayName = `withAPI(${
    WrappedComponent.displayName || "WrappedComponent"
  })`;
  return component;
}

export default withAPI;
