import { isRejected } from "@reduxjs/toolkit";
import usePushNotification from "hooks/usePushNotification";
import { useCallback, useState } from "react";
import { useDispatch } from "react-redux";
import { rawPost } from "redux/api/thunks";
import { ERROR_MESSAGE } from "redux/api/typing";

export enum DisabledSyncReason {
  inProgress = "sync_in_progress",
  limitReached = "sync_limit_reached",
}

export const useRefreshFieldsButtonApi = ({
  integrationId,
}: {
  integrationId: number;
}) => {
  const dispatch = useDispatch();
  const pushNotification = usePushNotification();
  const [
    disabledSyncReason,
    setDisabledSyncReason,
  ] = useState<DisabledSyncReason | null>(null);

  const refreshFields = useCallback(async () => {
    setDisabledSyncReason(DisabledSyncReason.inProgress);
    const result = await dispatch(
      rawPost({
        type: "integrations",
        id: integrationId,
        path: "/synchronize-fields/",
        payload: undefined,
      })
    );
    if (isRejected(result)) {
      if (result.error.message === ERROR_MESSAGE.ERROR_429) {
        setDisabledSyncReason(DisabledSyncReason.limitReached);
      } else {
        setDisabledSyncReason(null);
        pushNotification("default_error");
      }
    }
  }, [integrationId, dispatch, pushNotification]);

  return {
    disabledSyncReason,
    refreshFields,
  };
};
