import Match from "models/Match";
import PageView from "models/PageView";
import PartnerConnection from "models/PartnerConnection";
import Partnership from "models/Partnership";
import User from "models/User";

import { MatchFieldType } from "../../screens/Frontoffice/screens/DataTables/shared/types";

export enum PipelineActions {
  loadView = "pipeline/loadView",
  updateView = "pipeline/updateView",
  loadUnreadConversations = "pipeline/loadUnreadConversations",
  /**
   * All Filters Actions
   */
  removeAllFiltersExceptView = "pipeline/removeAllFiltersExceptView",
  /**
   * Global Filters Actions
   */
  addPartnerToFilters = "pipeline/addPartnerToFilters",
  removePartnerFromFilters = "pipeline/removePartnerFromFilters",
  setDateRangeFilter = "pipeline/setDateRangeFilter",
  setPartnerFilter = "pipeline/setPartnerFilter",
  setViewFilter = "pipeline/setViewFilter",
  setPerspectiveFilter = "pipeline/setPerspectiveFilter",
  /**
   * Table Filters Actions
   */
  setTableFilters = "pipeline/setTableFilters",
  toggleArchivedFilter = "pipeline/toggleArchivedFilter",
  resetTableFilters = "pipeline/resetTableFilters",
  setOwnerFilter = "pipeline/setOwnerFilter",
  /**
   * Load partner connections
   */
  initializeItems = "pipeline/initializeItems",
  loadPartnerConnections = "pipeline/loadPartnerConnections",
}

export type ColumnFilter = string | string[] | undefined | null;

// TODO: use or remove
export type RawPipelineField = {
  name: string;
  label: string;
  type: MatchFieldType;
  fully_imported?: boolean;
  display_index: number;
  partnership_id: number | null;
};

// TODO: use or remove
export type PipelineField = {
  label: string;
  type: MatchFieldType;
  fullyImported: boolean;
  displayIndex: number;
  partnershipId: null | number;
};

export type ItemState = {
  loading: boolean;
  mine: boolean;
  team: boolean;
  loadedPartnershipIds: number[];
  mineArchived: boolean;
  teamArchived: boolean;
  loadedPartnershipArchivedIds: number[];
};

export type IUnreadConversations = {
  [Match.PERSPECTIVE_MINE]: number;
  [Match.PERSPECTIVE_THEIRS]: number;
};

export interface PipelineState {
  view?: PageView | null;
  ready: boolean;
  unreadConversations: IUnreadConversations;
}

export interface APIEntities {
  partnerships?: {
    [id: string]: Partnership;
  };
  partner_connections?: {
    [id: string]: PartnerConnection;
  };
}

export interface PipelineExpectedState {
  api: {
    entities: APIEntities;
  };
  pipeline: PipelineState;
  user: {
    profile: {
      data: User;
    };
  };
}

export type PartnershipFilterData = {
  id: number;
  partnerId?: number | null;
  name: string;
  logo?: string | null;
  disabled?: boolean;
  owner?: User | null;
  partnerOwner?: User | null;
};

export type RawCompanyIdToPartnerMap = {
  [rawCompanyId: number]: (number | null)[];
};

export type RowSelection = {
  [id: string]: boolean;
};

export type RangeSelection = {
  [id: string]: number[];
};

export type HiddenByFiltersReasons = {
  partnershipId: boolean;
  dateRange: boolean;
  tableFilters: boolean;
};
