import CircularProgress from "@mui/material/CircularProgress";
import Grid from "@mui/material/Grid";
import Button from "components/ui/Button";
import { T } from "components/ui/Typography";
import { makeStyles } from "makeStyles";
import { ReactElement } from "react";
import { defineMessages } from "react-intl";

export type BaseSettingsTabProps = {
  children?: $TSFixMe;
  summary?: ReactElement | string;
  loading?: boolean;
  saving?: boolean;
  handleSave?: $TSFixMeFunction;
  handleClose?: $TSFixMeFunction;
  hideSummary?: boolean;
  callback?: $TSFixMeFunction;
  disabled?: boolean;
  saveLabel?: string;
};

const BaseSettingsTab = ({
  children,
  summary,
  loading,
  saving,
  handleSave,
  handleClose,
  hideSummary,
  callback,
  disabled,
  saveLabel,
}: BaseSettingsTabProps) => {
  const { classes } = useStyles();
  const { classes: btnClasses } = useBtnStyles();

  return (
    <Grid container className={classes.root}>
      {!hideSummary && <T className={classes.summary}>{summary}</T>}
      {loading ? (
        <Grid item xs={12} style={{ margin: 50 }}>
          <CircularProgress size={25} />
        </Grid>
      ) : (
        <Grid container className={classes.childrenContainer}>
          {children}
        </Grid>
      )}
      {handleClose ? (
        <Grid
          container
          direction="row"
          justifyContent="flex-end"
          alignItems="center"
          className={classes.actionsContainer}
        >
          <Button
            label={callback ? i18n.skipBtn : i18n.cancelBtn}
            onClick={callback ? callback : handleClose}
            classes={btnClasses}
            variant="secondary"
          />
          <Button
            label={saveLabel || i18n.saveBtn}
            disabled={loading || disabled || saving}
            loading={saving}
            onClick={handleSave}
            classes={btnClasses}
          />
        </Grid>
      ) : (
        <Button
          label={saveLabel || i18n.saveBtn}
          disabled={loading || disabled}
          loading={saving}
          onClick={handleSave}
          classes={btnClasses}
        />
      )}
    </Grid>
  );
};

export default BaseSettingsTab;

// I18N

const i18n = defineMessages({
  saveBtn: {
    id: "crm.settings.lists.saveBtn",
    defaultMessage: "save",
  },
  cancelBtn: {
    id: "crm.settings.lists.cancelBtn",
    defaultMessage: "cancel",
  },
  skipBtn: {
    id: "crm.settings.lists.skipBtn",
    defaultMessage: "skip for now",
  },
});

// CSS

export const useStyles = makeStyles()((theme) => ({
  root: {
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(4),
    marginBottom: theme.spacing(10),
    [theme.breakpoints.down("md")]: {
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
    },
    [theme.breakpoints.down("sm")]: {
      paddingLeft: theme.spacing(0),
      paddingRight: theme.spacing(0),
    },
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    padding: theme.spacing(0),
    minWidth: 0,
  },
  summary: {
    // TODO: Typography -- deal with different font styles in smaller screens.
    // To help find this later (cmd+f hook): oldVariant="body1_fontSize:14px".
    [theme.breakpoints.down("md")]: {
      fontSize: 14,
    },
    [theme.breakpoints.down("sm")]: {
      marginTop: theme.spacing(2),
    },
  },
  childrenContainer: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(2),
  },
  row: {
    display: "flex",
    flexDirection: "column",
    flexWrap: "wrap",
    rowGap: theme.spacing(1),
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(2),
  },
  actionsContainer: {
    position: "absolute",
    bottom: 0,
    right: 0,
    padding: theme.spacing(2),
    backgroundColor: theme.palette.ivory,
    // @ts-expect-error ts-migrate(2339) FIXME: Property 'light' does not exist on type 'Color'.
    borderTop: `1px solid ${theme.palette.grey.light}`,
    zIndex: 1,
  },
}));
const useBtnStyles = makeStyles()((theme) => ({
  btn: {
    marginLeft: theme.spacing(2),
    textTransform: "uppercase",
  },
}));
