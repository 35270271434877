import { CircularProgress } from "@mui/material";
import Autocomplete from "@mui/material/Autocomplete";
import withAPI, { WithAPI } from "components/hoc/withAPI";
import SearchIcon from "components/icons/Search";
import PageLoader from "components/ui/PageLoader";
import Tag from "components/ui/Tag";
import { TextInput } from "components/ui/TextInput";
import { T } from "components/ui/Typography";
import muiTheme from "config/theme";
import _ from "lodash";
import { makeStyles } from "makeStyles";
import CrmField from "models/CrmField";
import Partnership from "models/Partnership";
import Record from "models/Record";
import { ChangeEvent, useState } from "react";
import {
  defineMessages,
  FormattedHTMLMessage,
  FormattedMessage,
  useIntl,
} from "react-intl";
import { Link } from "react-router-dom";

import ConfirmShareFieldDialog from "./ConfirmShareFieldDialog";

type Props = WithAPI & {
  partnership: Partnership;
  partner: Record | { name: string };
  importedFields: CrmField[];
  fieldsLoading: boolean;
};

const ShareCustomFieldSettings = ({
  partnership,
  partner,
  importedFields,
  fieldsLoading,
  createRecord,
  removeRecord,
}: Props) => {
  const { classes } = useStyles();
  const [autocompleteKey, setAutocompleteKey] = useState(0);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [idBeingDeleted, setIdBeingDeleted] = useState<number | null>(null);
  const [fieldToShare, setFieldToShare] = useState<CrmField | null>(null);
  const [shareLoading, setShareLoading] = useState(false);
  const intl = useIntl();

  if (partnership.integrations?.length === 0) {
    /**
     * When no integrations on partnership, there cannot possibly be any CRM fields shared
     */
    return null;
  }

  const handleOpenDialog = (_evt: ChangeEvent<{}>, field: CrmField | null) => {
    setFieldToShare(field);
    setDialogOpen(field !== null);
  };

  const handleUnshareField = async (field: CrmField) => {
    setIdBeingDeleted(field.id);
    await removeRecord("partnership_crm_fields", field.partnershipCrmFieldId);
    setIdBeingDeleted(null);
  };

  const handleShareField = async () => {
    if (fieldToShare === null) {
      return;
    }
    setDialogOpen(false);
    setAutocompleteKey(autocompleteKey + 1);
    setShareLoading(true);
    await createRecord(
      "partnership_crm_fields",
      {},
      {
        partnership: {
          type: "partnerships",
          id: String(partnership.id),
        },
        crm_field: {
          type: "crm_fields",
          id: String(fieldToShare.id),
        },
      }
    );

    setFieldToShare(null);
    setShareLoading(false);
  };

  const integration = partnership.integrations[0];
  const settingsLink = {
    hash: "custom-fields",
    pathname: "/sources",
    search: `integration=${integration.id}&provider=${integration.provider}`,
  };

  const [sharedFields, unsharedFields] = _.partition(
    importedFields,
    (field) => field.shared && field.partnershipCrmFieldId !== null
  );

  if (fieldsLoading && _.isEmpty(importedFields)) return <PageLoader />;

  return (
    <>
      <div>
        <div>
          <T h4 oldVariant="h5" className={classes.title}>
            <FormattedMessage {...i18n.title} />
          </T>
          {_.isEmpty(sharedFields) && (
            <T
              oldVariant="body1_fontStyle:italic"
              className={classes.noSharedFieldsText}
            >
              <FormattedMessage
                {...i18n.noSharedFields}
                values={{ partner_name: partner.name }}
              />
            </T>
          )}
          <div className={classes.fieldsChips}>
            {sharedFields.map((field) => (
              <Tag
                label={field.crmLabel}
                onDelete={() => handleUnshareField(field)}
                data-testid={`shared-field-chip-${field.id}`}
                disabled={field.id === idBeingDeleted}
              />
            ))}
          </div>
        </div>
        <Autocomplete
          autoHighlight
          key={autocompleteKey}
          id="share-fields-autocomplete"
          className={classes.autocomplete}
          classes={{ paper: classes.paper, popper: classes.popper }}
          clearOnEscape={true}
          onChange={handleOpenDialog}
          autoComplete
          disableClearable
          options={unsharedFields}
          noOptionsText={intl.formatMessage(i18n.noMatchFound)}
          getOptionLabel={(option) => option.crmLabel}
          renderOption={(props, option) => (
            <li {...props}>
              <FormattedHTMLMessage
                tagName="span"
                {...i18n.share}
                values={{ label: option.crmLabel }}
              />
            </li>
          )}
          size={"small"}
          renderInput={(params) => (
            <TextInput
              {...params}
              placeholder={intl.formatMessage(i18n.search)}
              fullWidth
              disabled={shareLoading}
              InputProps={{
                ...params.InputProps,
                endAdornment: shareLoading ? (
                  () => <CircularProgress size={20} />
                ) : (
                  <SearchIcon className={classes.search} />
                ),
              }}
            />
          )}
        />
        <T
          // @ts-expect-error ts-migrate(2339) FIXME: Property 'darkBlue' does not exist on type 'Color'... Remove this comment to see the full error message
          color={muiTheme.palette.grey.darkBlue}
          oldVariant="body1_fontSize:13px"
          className={classes.footer}
        >
          <FormattedMessage {...i18n.footer} />
          <Link to={settingsLink}>
            <FormattedMessage {...i18n.footerLink} />
          </Link>
        </T>
      </div>
      {fieldToShare !== null && (
        <ConfirmShareFieldDialog
          dialogOpen={dialogOpen}
          fieldName={fieldToShare.crmLabel}
          handleCloseDialog={() => setDialogOpen(false)}
          handleShareField={handleShareField}
          partner={partner}
        />
      )}
    </>
  );
};

export default withAPI(ShareCustomFieldSettings);

// CSS

const useStyles = makeStyles()((theme) => ({
  title: {
    marginBottom: theme.spacing(2),
    [theme.breakpoints.down("md")]: {
      paddingLeft: theme.spacing(2),
    },
    [theme.breakpoints.down("sm")]: {
      paddingLeft: theme.spacing(0),
    },
  },
  noSharedFieldsText: {
    paddingBottom: theme.spacing(1),
  },
  fieldsChips: {
    marginTop: theme.spacing(1),
    display: "flex",
    flexWrap: "wrap",
    columnGap: 4,
    rowGap: 4,
  },
  autocomplete: {
    margin: theme.spacing(3, 0),
    width: "70%",
    minWidth: 250,
    "& .MuiFilledInput-root": {
      paddingTop: 0,
      paddingRight: "11px !important",
    },
  },
  popper: {
    border: `1px solid ${theme.palette.greyReveal}`,
    borderRadius: 3,
    fontSize: 13,
    marginTop: "4px !important",
    marginBottom: "4px !important",
  },
  paper: {
    boxShadow: "none",
    margin: 0,
    fontSize: 13,
  },
  footer: {
    marginTop: theme.spacing(3),
  },
  search: {
    fontSize: "0.75rem",
    color: theme.palette.midnight500,
  },
}));

// I18N

const i18n = defineMessages({
  title: {
    id: "crm.PartnershipSettings.ShareCustomFieldSettings.title",
    defaultMessage: "Shared custom fields",
  },
  noSharedFields: {
    id: "crm.PartnershipSettings.ShareCustomFieldSettings.noSharedFields",
    defaultMessage:
      "You're not sharing any custom CRM fields with {partner_name} yet.",
  },
  search: {
    id: "crm.PartnershipSettings.ShareCustomFieldSettings.search",
    defaultMessage: "Search for custom fields to share",
  },
  noMatchFound: {
    id: "crm.PartnershipSettings.ShareCustomFieldSettings.noMatchFound",
    defaultMessage: "No results",
  },
  footer: {
    id: "crm.PartnershipSettings.ShareCustomFieldSettings.footer",
    defaultMessage:
      "Your field is not here ? To import more custom fields from your CRM, ",
  },
  footerLink: {
    id: "crm.PartnershipSettings.ShareCustomFieldSettings.footerLink",
    defaultMessage: "please click here.",
  },
  share: {
    id: "crm.PartnershipSettings.ShareCustomFieldSettings.share",
    defaultMessage: "share <b>{label}</b>",
  },
});
