import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import { Paid } from "components/icons";
import {
  BasicDialog,
  BasicDialogVariant,
} from "components/ui/BasicDialog/BasicDialog";
import useSegment from "hooks/useSegment";
import { makeStyles } from "makeStyles";
import { useCallback } from "react";
import { MessageDescriptor, useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { selectSummaryMetricsEnabled } from "redux/analytics/selectors";
import {
  openWidget,
  toggleFree,
  togglePower,
  togglePro,
} from "redux/premiumPlanWidget/actions";
import { PremiumPlanEvent } from "tracking";

type Props = {
  title: MessageDescriptor;
  subtitle: MessageDescriptor;
  buttonLabel: MessageDescriptor;
  from: string;
};

export const UnlockTableDialog = ({
  title,
  subtitle,
  buttonLabel,
  from,
}: Props) => {
  const summaryMetricsEnabled = useSelector(selectSummaryMetricsEnabled);
  const { classes } = useStyles({ summaryMetricsEnabled });
  const { track } = useSegment();
  const dispatch = useDispatch();
  const intl = useIntl();
  const theme = useTheme();
  const smallWidth = useMediaQuery(theme.breakpoints.down("sm"));

  const openPremiumWidget = useCallback(() => {
    dispatch(toggleFree(false));
    dispatch(togglePro(true));
    dispatch(togglePower(false));
    dispatch(openWidget(true));
    track(PremiumPlanEvent.explorePlan, {
      from,
    });
  }, [dispatch, track, from]);

  if (smallWidth) return null;

  return (
    <div className={classes.root}>
      <BasicDialog
        variant={BasicDialogVariant.Unlock}
        isNotInDialog
        title={intl.formatMessage(title)}
        description={intl.formatMessage(subtitle)}
        buttonProps={[
          {
            label: intl.formatMessage(buttonLabel),
            onClick: openPremiumWidget,
            variant: "secondary",
            LeftIcon: Paid,
          },
        ]}
      />
    </div>
  );
};

// CSS

export const useStyles = makeStyles<{ summaryMetricsEnabled: boolean }>()(
  (theme, { summaryMetricsEnabled }) => ({
    root: {
      position: "absolute",
      top: summaryMetricsEnabled ? 500 : 390,
      marginLeft: "auto",
      marginRight: "auto",
      left: 0,
      right: 0,
      width: 466,
      zIndex: 100,
      padding: 40,
      display: "flex",
      flexDirection: "column",
      borderRadius: 8,
      border: `1px solid ${theme.palette.greyscale200}`,
      boxShadow: `-2px 3px 6px ${theme.palette.taupe} !important`,
      backgroundColor: theme.palette.ivory,
    },
    content: {
      position: "relative",
      display: "flex",
      justifyContent: "center",
      flexDirection: "column",
      alignItems: "center",
      rowGap: 6,
    },
    icon: {
      width: 48,
      height: 48,
      marginBottom: 10,
    },
    subtitle: {
      marginBottom: 18,
    },
  })
);
