import { RouteConfig } from "config/routes/types";

export const computeRoutes = (routes: RouteConfig[] = [], base = "") => {
  let result: string[] = [];
  routes.forEach((route) => {
    const path = base + route.path;
    result = result.concat(path).concat(computeRoutes(route.routes, path));
  });
  return result;
};
