import { createSvgIcon } from "@mui/material/utils";

import { SvgIcon } from "./types";

const displayName = "ArrowUpInverted";

const ArrowUpInverted: SvgIcon = createSvgIcon(
  <g fill="none" transform="matrix(1.5,0,0,1.5,0,0)">
    <rect width="16" height="16" rx="8" fill="#0A151B" />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M10.285 7.21467C10.4681 7.39773 10.7649 7.39773 10.9479 7.21467C11.131 7.03161 11.131 6.73482 10.9479 6.55176L8.84566 4.44951C8.44293 4.04678 7.78998 4.04678 7.38725 4.44951L5.285 6.55176C5.10194 6.73482 5.10194 7.03161 5.285 7.21467C5.46806 7.39773 5.76485 7.39773 5.94791 7.21467L7.6477 5.51488V11.8832C7.6477 12.1421 7.85757 12.352 8.11645 12.352C8.37534 12.352 8.5852 12.1421 8.5852 11.8832V5.51488L10.285 7.21467Z"
      fill="#FAFAF6"
    />
  </g>,
  displayName
);

ArrowUpInverted.displayName = displayName;
export default ArrowUpInverted;
