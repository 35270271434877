import {
  array,
  constant,
  guard,
  iso8601,
  nullable,
  number,
  object,
  optional,
  positiveNumber,
  string,
} from "decoders";
import _ from "lodash";
import { AccountMappingResource } from "redux/accountMapping/types";
import {
  FilterType,
  PersistedColumnType,
  SortType,
} from "screens/Frontoffice/screens/DataTables/shared/types";
import {
  columnDecoder,
  filterDecoder,
  sortDecoder,
} from "screens/Frontoffice/screens/DataTables/shared/types/decoders";

import Record from "./Record";
import { SavedFilterType } from "./SavedFilter";
import { JSONAPIResourceOrRecord } from "./types";

const apiAccountMappingView = object({
  type: constant("account_mapping_views" as const),
  id: string,
  attributes: object({
    active_view_type: positiveNumber,
    matches_columns: array(columnDecoder),
    matches_filters: array(filterDecoder),
    matches_sort: array(sortDecoder),
    matched_accounts_columns: array(columnDecoder),
    matched_accounts_filters: array(filterDecoder),
    matched_accounts_sort: array(sortDecoder),
    shared_accounts_columns: array(columnDecoder),
    filter_order_list: optional(array(number)),
    shared_accounts_filters: array(filterDecoder),
    shared_accounts_sort: array(sortDecoder),
    lead_matches_columns: array(columnDecoder),
    lead_matches_filters: array(filterDecoder),
    lead_matches_sort: array(sortDecoder),
    last_visited_at: nullable(iso8601),
    created_at: iso8601,
    updated_at: iso8601,
  }),
});

const apiAccountMappingViewGuard = guard(apiAccountMappingView);

const viewTypeToAccountMappingResource: {
  [key: number]: AccountMappingResource;
} = {
  [SavedFilterType.AccountMapping]: AccountMappingResource.matches,
  [SavedFilterType.LeadOverlaps]: AccountMappingResource.leadMatches,
  [SavedFilterType.NewProspects]: AccountMappingResource.shared_accounts,
  [SavedFilterType.NewAccountMapping]: AccountMappingResource.matched_accounts,
};

const getAccountMappingResource = (viewType: number) => {
  return (
    viewTypeToAccountMappingResource[viewType] ||
    AccountMappingResource.matched_accounts
  );
};

export const getViewTypeFromAccountMappingResource = (
  resource: AccountMappingResource
) => {
  if (resource === AccountMappingResource.matched_accounts) return 3;
  return +_.invert(viewTypeToAccountMappingResource)[resource];
};

class AccountMappingView extends Record<"account_mapping_views"> {
  activeViewType: AccountMappingResource;
  filterOrderList?: number[] | null;
  matchesColumns: PersistedColumnType[];
  matchesFilters: FilterType[];
  matchesSort: SortType[];
  matchedAccountsColumns: PersistedColumnType[];
  matchedAccountsFilters: FilterType[];
  matchedAccountsSort: SortType[];
  sharedAccountsColumns: PersistedColumnType[];
  sharedAccountsFilters: FilterType[];
  sharedAccountsSort: SortType[];
  leadMatchesColumns: PersistedColumnType[];
  leadMatchesFilters: FilterType[];
  leadMatchesSort: SortType[];
  lastVisitedAt: Date | null;

  constructor(resource: JSONAPIResourceOrRecord<"account_mapping_views">) {
    super(resource);
    const { attributes } = apiAccountMappingViewGuard(resource);
    this.filterOrderList = attributes.filter_order_list;
    this.matchesColumns = attributes.matches_columns;
    this.matchesFilters = attributes.matches_filters;
    this.matchesSort = attributes.matches_sort;
    this.matchedAccountsColumns = attributes.matched_accounts_columns;
    this.matchedAccountsFilters = attributes.matched_accounts_filters;
    this.matchedAccountsSort = attributes.matched_accounts_sort;
    this.sharedAccountsColumns = attributes.shared_accounts_columns;
    this.sharedAccountsFilters = attributes.shared_accounts_filters;
    this.sharedAccountsSort = attributes.shared_accounts_sort;
    this.leadMatchesColumns = attributes.lead_matches_columns;
    this.leadMatchesFilters = attributes.lead_matches_filters;
    this.leadMatchesSort = attributes.lead_matches_sort;
    this.lastVisitedAt = attributes.last_visited_at;
    this.activeViewType = getAccountMappingResource(
      attributes.active_view_type
    );
  }
}

export default AccountMappingView;
