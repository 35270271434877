import { createSvgIcon } from "@mui/material/utils";

import { SvgIcon } from "./types";

const displayName = "Companies";

const Companies: SvgIcon = createSvgIcon(
  // Using transform to enlarge from provided viewBox="0 0 20 20"
  // to default "0 0 24 24" from `createSvgIcon` -> `SvgIcon`.
  <g fill="none" transform="matrix(1.2,0,0,1.2,0,0)">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.56726 2.5C9.56726 2.15482 9.28744 1.875 8.94226 1.875C8.59708 1.875 8.31726 2.15482 8.31726 2.5V3.02856H5C3.96447 3.02856 3.125 3.86803 3.125 4.90356V16.2497C3.125 17.2853 3.96447 18.1247 5 18.1247H11.8269H15.1923C16.2278 18.1247 17.0673 17.2853 17.0673 16.2497V11.8266C17.0673 10.7911 16.2278 9.95164 15.1923 9.95164H14.7596V4.90356C14.7596 3.86803 13.9201 3.02856 12.8846 3.02856H9.56726V2.5ZM8.92331 4.27856C8.9296 4.27875 8.93592 4.27885 8.94226 4.27885C8.9486 4.27885 8.95492 4.27875 8.96121 4.27856H12.8846C13.2298 4.27856 13.5096 4.55839 13.5096 4.90356V9.95164H13.0769C12.0414 9.95164 11.2019 10.7911 11.2019 11.8266V16.8747H5C4.65482 16.8747 4.375 16.5949 4.375 16.2497V4.90356C4.375 4.55839 4.65482 4.27856 5 4.27856H8.92331ZM12.4519 16.8747H15.1923C15.5375 16.8747 15.8173 16.5949 15.8173 16.2497V11.8266C15.8173 11.4815 15.5375 11.2016 15.1923 11.2016H14.1346H13.0769C12.7317 11.2016 12.4519 11.4815 12.4519 11.8266V16.8747ZM6.58661 7.11548C6.58661 6.7703 6.86643 6.49048 7.21161 6.49048L10.6731 6.49048C11.0183 6.49048 11.2981 6.7703 11.2981 7.11548C11.2981 7.46066 11.0183 7.74048 10.6731 7.74048L7.21161 7.74048C6.86643 7.74048 6.58661 7.46066 6.58661 7.11548ZM7.21161 9.375C6.86643 9.375 6.58661 9.65482 6.58661 10C6.58661 10.3452 6.86643 10.625 7.21161 10.625H8.36545C8.71063 10.625 8.99045 10.3452 8.99045 10C8.99045 9.65482 8.71063 9.375 8.36545 9.375H7.21161ZM6.58661 12.8845C6.58661 12.5393 6.86643 12.2595 7.21161 12.2595H8.36545C8.71063 12.2595 8.99045 12.5393 8.99045 12.8845C8.99045 13.2297 8.71063 13.5095 8.36545 13.5095H7.21161C6.86643 13.5095 6.58661 13.2297 6.58661 12.8845Z"
      fill="currentColor"
    />
  </g>,

  displayName
);

Companies.displayName = displayName;
export default Companies;
