import LockIcon from "@mui/icons-material/Lock";
import LockOpenTwoToneIcon from "@mui/icons-material/LockOpenTwoTone";
import { IconButton } from "@mui/material";
import Box from "@mui/material/Box";
import Info from "components/icons/Info";
import {
  getFieldStatusTooltip,
  infoFieldsPopups,
  infoFieldsTooltips,
} from "components/ui/data-grid/fieldTooltips";
import Tooltip from "components/ui/Tooltip";
import { T } from "components/ui/Typography";
import _ from "lodash";
import { makeStyles } from "makeStyles";
import {
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { defineMessages, FormattedMessage } from "react-intl";
import ViewContext from "screens/Frontoffice/screens/DataTables/screens/AccountMapping/contexts/ViewContext";
import {
  MatchField,
  MatchFieldType,
} from "screens/Frontoffice/screens/DataTables/shared/types";

import Popup from "../Popup";
import FieldsContext from "./FieldsContext";
import HeaderMenu, { HeaderMenuProps } from "./HeaderMenu";

export type HeaderRendererProps = {
  name: string;
  smartField?: boolean;
  fullyImported?: boolean;
  isPrivate?: boolean;
  isPartiallyShared?: boolean;
  fieldType: MatchFieldType;
  isPartnerCell?: boolean;
  removable: boolean;
  filterableField: boolean;
  sortableField: boolean;
  hideMenu: boolean;
  isFirstLeft?: boolean;
  isFirstRight?: boolean;
  leftSideTitle?: ReactNode;
  rightSideTitle?: ReactNode;
  noSide?: boolean;
  customMenu?: ((field: string) => JSX.Element | undefined) | undefined;
} & HeaderMenuProps;

const HeaderRenderer = ({
  name,
  smartField = true,
  fullyImported,
  isPrivate = false,
  isPartiallyShared = false,
  fieldType,
  isPartnerCell,
  filterableField,
  sortableField,
  hideMenu = false,
  isFirstLeft = false,
  isFirstRight = false,
  leftSideTitle,
  rightSideTitle,
  noSide = false,
  customMenu,
  ...rest
}: HeaderRendererProps) => {
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const ref = useRef<HTMLDivElement>(null);
  const fields = useContext(FieldsContext);
  const { partnershipId, accountType } = useContext(ViewContext);
  const label = _.get(fields, `${name}.label`, "");
  const tooltip = _.get(infoFieldsTooltips, name, false);
  const align = _.get(fields, `${name}.headerAlign`, "left");
  const { classes, cx } = useStyles({
    hasCustomMenu: !!customMenu,
    align,
  });
  const popupContent = useMemo(
    () =>
      _.get(
        infoFieldsPopups(() => setIsPopupOpen(false)),
        name,
        false
      ),
    [name]
  );

  // The status tooltip is computed only for partiallyShared fields
  // Nothing is displayed for shared fields
  // Default private tooltip is disabled for private fields
  const statusTooltip =
    partnershipId && !isPrivate && isPartiallyShared
      ? getFieldStatusTooltip(
          "partiallyShared",
          name,
          smartField,
          partnershipId,
          accountType
        )
      : false;

  const canApplyFilterOrSort =
    (smartField || fullyImported) && MatchField.canFilterOrSort(fieldType);

  useEffect(() => {
    if (noSide) return;
    if (ref.current !== null) {
      const headerCell: null | HTMLDivElement = ref.current.closest(
        ".MuiDataGrid-columnHeader"
      );
      if (headerCell !== null) {
        headerCell.classList.add(
          !isPartnerCell ? "company-side" : "partner-side"
        );
        if (isFirstRight) {
          headerCell.classList.add("first-right");
        }
      }
    }
  }, [isPartnerCell, isFirstRight, noSide]);

  const handleClick = useCallback(() => {
    if (!popupContent) {
      return;
    }
    setIsPopupOpen(true);
  }, [popupContent]);

  const handleClosePopup = useCallback(() => {
    setIsPopupOpen(false);
  }, []);

  return (
    <>
      <Box ref={ref} className={classes.container} title={label}>
        {isPrivate && (
          <Tooltip title={<FormattedMessage {...i18n.privateField} />}>
            <LockIcon className={classes.lockIcon} data-testid="private-icon" />
          </Tooltip>
        )}
        {!isPrivate && isPartiallyShared && (
          <Tooltip title={statusTooltip}>
            <LockOpenTwoToneIcon
              className={classes.lockIcon}
              data-testid="partially-shared-icon"
            />
          </Tooltip>
        )}
        <T bold noWrap className={classes.shrink}>
          {label}
        </T>
        {label && !hideMenu && (
          <HeaderMenu
            filterable={canApplyFilterOrSort && filterableField}
            sortable={canApplyFilterOrSort && sortableField}
            {...rest}
          />
        )}
        {customMenu?.(name)}
        {(tooltip || popupContent) && (
          <div className={classes.rightContainer}>
            <Tooltip title={tooltip}>
              {popupContent ? (
                <IconButton
                  className={cx(classes.infoButton, classes.marginLeft)}
                  onClick={handleClick}
                >
                  <Info className={classes.infoIcon} data-testid="info-icon" />
                </IconButton>
              ) : (
                <Info
                  className={cx(classes.infoIcon, classes.marginLeft)}
                  data-testid="info-icon"
                />
              )}
            </Tooltip>
          </div>
        )}
      </Box>
      {isFirstLeft && (
        <div className={cx(classes.sideTitle, classes.right)}>
          {leftSideTitle}
        </div>
      )}
      {isFirstRight && (
        <div className={classes.sideTitle}>{rightSideTitle}</div>
      )}
      <Popup
        isOpen={isPopupOpen}
        handleClose={handleClosePopup}
        hideActions
        maxWidth="sm"
        variant="secondary"
        width={480}
      >
        {popupContent}
      </Popup>
    </>
  );
};

export default HeaderRenderer;

// I18N

const i18n = defineMessages({
  privateField: {
    id: "crm.AccountMapping.HeaderRendenderer.privateField",
    defaultMessage:
      "This field is not shared with your partner, it’s only visible to you.",
  },
});

// CSS

const useStyles = makeStyles<{
  align?: string;
  hasCustomMenu?: boolean;
}>()((theme, { align, hasCustomMenu }) => ({
  container: {
    cursor: hasCustomMenu ? "pointer" : "default",
    display: "flex",
    alignItems: "center",
    width: "100%",
    ...(align === "right" && {
      justifyContent: "flex-end",
    }),
  },
  rightContainer: {
    display: "flex",
    alignItems: "center",
    marginLeft: "auto",
  },
  infoButton: {
    height: 24,
    width: 24,
  },
  infoIcon: {
    color: theme.palette.midnight,
    fontSize: 16,
  },
  lockIcon: {
    fontSize: "13px",
    marginTop: "-3px",
    marginRight: 3,
    color: theme.palette.midnight,
  },
  marginLeft: {
    marginLeft: theme.spacing(0.5),
  },
  shrink: {
    flexShrink: 1,
  },
  sideTitle: {
    position: "absolute",
    top: "-22px",
    display: "flex",
    alignItems: "center",
    gap: theme.spacing(1),
  },
  right: {
    left: "-23px",
  },
}));
