import Paper from "@mui/material/Paper";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import { makeStyles } from "makeStyles";
import {
  MatchFieldType,
  MatchSortOrder,
} from "screens/Frontoffice/screens/DataTables/shared/types";

import OrderIcon from "./OrderIcon";

type Props = {
  order: $TSFixMe; // TODO: PropTypes.oneOf(MatchSort.ORDERS)
  type: $TSFixMe; // TODO: PropTypes.oneOf(MatchField.TYPES)
  handleChange: $TSFixMeFunction;
  fieldname?: string;
};

const OrderToggleButton = ({ order, type, handleChange, fieldname }: Props) => {
  const { classes } = useStyles();
  const { classes: toggleButtonClasses } = useToggleButtonStyles();
  const { classes: toggleButtonGroupClasses } = useToggleButtonGroupStyles();

  const left =
    type === MatchFieldType.BOOLEAN
      ? MatchSortOrder.ORDER_DESC
      : MatchSortOrder.ORDER_ASC;
  const right =
    type === MatchFieldType.BOOLEAN
      ? MatchSortOrder.ORDER_ASC
      : MatchSortOrder.ORDER_DESC;

  return (
    <div>
      <Paper elevation={0} className={classes.paper}>
        <ToggleButtonGroup
          value={order}
          exclusive
          onChange={handleChange}
          classes={toggleButtonGroupClasses}
        >
          <ToggleButton value={left} classes={toggleButtonClasses}>
            <OrderIcon
              type={type}
              direction={MatchSortOrder.ORDER_ASC}
              selected={order === left}
              fieldname={fieldname}
            />
          </ToggleButton>
          <ToggleButton value={right} classes={toggleButtonClasses}>
            <OrderIcon
              type={type}
              direction={MatchSortOrder.ORDER_DESC}
              selected={order === right}
              fieldname={fieldname}
            />
          </ToggleButton>
        </ToggleButtonGroup>
      </Paper>
    </div>
  );
};

export default OrderToggleButton;

// CSS

const useStyles = makeStyles()((theme) => ({
  paper: {
    display: "flex",
    flexWrap: "wrap",
  },
}));

const useToggleButtonStyles = makeStyles()((theme) => ({
  root: {
    "&:hover": {
      background: "none",
    },
  },
  selected: {
    // @ts-expect-error ts-migrate(2339) FIXME: Property 'dark' does not exist on type 'Color'.
    backgroundColor: `${theme.palette.grey.dark} !important`,
  },
}));

const useToggleButtonGroupStyles = makeStyles()((theme) => ({
  root: {
    backgroundColor: theme.palette.other.grid.lightBorder,
  },
  grouped: {
    height: 20,
    margin: theme.spacing(0.5),
    border: "none",
    padding: theme.spacing(0, 1),
    "&:not(:first-child)": {
      borderRadius: theme.shape.borderRadius,
    },
    "&:first-child": {
      borderRadius: theme.shape.borderRadius,
    },
    "&:focus": {
      outline: 0,
    },
  },
}));
