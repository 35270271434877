import { isFulfilled } from "@reduxjs/toolkit";
import { IDropdownOption } from "components/ui/Dropdown/components/types";
import Fuse from "fuse.js";
import { useLazyEffect } from "hooks/useLazyEffect";
import usePushNotification from "hooks/usePushNotification";
import { ChangeEvent, useCallback, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { rawGet, rawPost } from "redux/api/thunks";
import { JSONAPIListResponse } from "services/types";

import { Participant } from "../components/Participant";

type Props = {
  partnerConnectionId: number;
  reloadRecord: () => void;
};

type IParticipantOption = IDropdownOption & {
  isRevealUser: boolean;
  isParticipant: boolean;
  role?: string | null;
};

const useParticipantDialogApiLogic = ({
  partnerConnectionId,
  reloadRecord,
}: Props) => {
  const dispatch = useDispatch();
  const pushNotification = usePushNotification();

  const [availableUserOptions, setAvailableUserOptions] = useState<
    IParticipantOption[]
  >([]);
  const [searchQuery, setSearchQuery] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(true);
  const [searchOptions, setSearchOptions] = useState<IParticipantOption[]>([]);

  const addParticipant = useCallback(
    async (value: string | null, options: IParticipantOption[]) => {
      if (!value) return;
      const option = options.find((o) => o.id === value);
      if (!option) return;
      const result = await dispatch(
        rawPost({
          type: "partner_connections",
          path: `${partnerConnectionId}/add-participant/`,
          payload: {
            data: {
              id: partnerConnectionId,
              type: "partner_connections",
              attributes: {
                ...(option.isRevealUser
                  ? { user_id: value }
                  : { raw_user_id: value }),
              },
            },
          },
        })
      );
      if (isFulfilled(result)) {
        reloadRecord();
        await getAvailableUsers();
      } else {
        pushNotification("default_error");
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [dispatch, partnerConnectionId, pushNotification, reloadRecord]
  );

  const getAvailableUsers = useCallback(async () => {
    const result = await dispatch(
      rawGet({
        type: "partner_connections",
        path: `${partnerConnectionId}/available-conversation-users/`,
      })
    );

    if (isFulfilled(result)) {
      const response = result.payload as JSONAPIListResponse;
      if (response.data?.length > 0) {
        const options = response.data.map((user: any) => {
          const props = {
            id: user.user_id?.toString() ?? user.raw_user_id?.toString(),
            name: user.full_name,
            avatar: user.user_avatar,
            companyAvatar: user.company_avatar,
            companyName: user.company_name,
            isRevealUser: !!user.user_id,
            isParticipant: user.is_participant ?? false,
            role: user.user_role,
          };
          return {
            ...props,
            label: (
              <Participant
                {...props}
                action="add"
                onAdd={() => addParticipant(props.id, options)}
              />
            ),
            height: 40,
          };
        }) as IParticipantOption[];
        setAvailableUserOptions(options);
      }
    } else {
      pushNotification("default_error");
    }
    setLoading(false);
  }, [addParticipant, dispatch, partnerConnectionId, pushNotification]);

  const searchParticipant = (event: ChangeEvent<HTMLInputElement>) => {
    setSearchQuery(event.target.value);
  };

  const removeParticipant = async (id: string) => {
    const result = await dispatch(
      rawPost({
        type: "partner_connections",
        path: `${partnerConnectionId}/remove-participant/`,
        payload: {
          data: {
            id: partnerConnectionId,
            type: "partner_connections",
            attributes: {
              participant_id: id,
            },
          },
        },
      })
    );
    if (isFulfilled(result)) {
      reloadRecord();
      await getAvailableUsers();
    } else {
      pushNotification("default_error");
    }
  };

  useEffect(() => {
    getAvailableUsers();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  //logic for searching input only
  useLazyEffect(() => {
    const filteredOptions = availableUserOptions.filter(
      (option) => !option.isParticipant
    );
    const fuzzyIndex = new Fuse(filteredOptions, {
      keys: ["name", "companyName", "role"],
      threshold: 0.4,
    });
    setSearchOptions(
      searchQuery
        ? fuzzyIndex.search(searchQuery).map((result) => result.item)
        : filteredOptions
    );
  }, [searchQuery, availableUserOptions]);

  return {
    loading,
    searchQuery,
    searchOptions,
    availableUserOptions,
    searchParticipant,
    removeParticipant,
  };
};

export default useParticipantDialogApiLogic;
