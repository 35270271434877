import { Box } from "@mui/material";
import {
  GridCallbackDetails,
  GridRowParams,
  MuiEvent,
} from "@mui/x-data-grid-premium";
import BaseDataGrid from "components/ui/data-grid/BaseDataGrid";
import { isRowClickable } from "helpers/partnerConnectionUtils";
import useSegment from "hooks/useSegment";
import useSelectorWithDeepEquality from "hooks/useSelectorWithDeepEquality";
import useUserProfile from "hooks/useUserProfile";
import { makeStyles } from "makeStyles";
import Match from "models/Match";
import Record from "models/Record";
import { useCallback, useState } from "react";
import {
  defineMessages,
  FormattedHTMLMessage,
  FormattedMessage,
} from "react-intl";
import { Link, useHistory } from "react-router-dom";
import {
  applyPipelineColumnOrder,
  AvailablePipelineColumns,
  defaultPipelineColumnConfig,
  defaultPipelineColumns,
  PIPELINE_FIELDS_WITH_YOU_TAG,
  pipelineColumnConfig,
} from "redux/pipeline/defaults";
import {
  selectPerspective,
  selectPipelineSlice,
} from "redux/pipeline/selectors";
import {
  ColumnConfigType,
  DataTableType,
  FieldType,
  PersistedColumnType,
  SortType,
  viewToColumns,
} from "screens/Frontoffice/screens/DataTables/shared/types";
import { WebhookEventName, WebhookService } from "services/WebhookService";

import PartnerConnection from "../../../../../../../models/PartnerConnection";
import { ColumnWidget } from "../../../shared/components/ColumnWidget";
import { PipelineViewParameters } from "../constants";
import { AddPipelineItemModal } from "./AddToPipelineModal";
import PipelineEmptyRowsView from "./PipelineEmptyRowsView";
import { YouTag } from "./YouTag";

type Props = {
  rows?: Record[];
  rowsCount?: number | null;
  fields?: { [filterName: string]: FieldType };
  fetching?: boolean;
  loadMore: () => void;
  loading?: boolean;
  setView: (value: PipelineViewParameters) => void;
  refreshTable: () => void;
};

export const MyPipelineTable = ({
  rows = [],
  rowsCount,
  loading,
  fields,
  fetching,
  loadMore,
  setView,
  refreshTable,
}: Props) => {
  const { classes } = useStyles();
  const { profile } = useUserProfile();
  const [isAddPipelineModalOpen, setIsAddPipelineModalOpen] = useState(false);
  const { view } = useSelectorWithDeepEquality(selectPipelineSlice);
  const { columns: viewColumns } = view ?? {};
  const perspective = useSelectorWithDeepEquality(selectPerspective);
  const history = useHistory();
  const isPartnerView = perspective === Match.PERSPECTIVE_THEIRS;
  const { track } = useSegment();
  const service = new WebhookService();

  const _viewColumns: PersistedColumnType[] =
    viewColumns ?? defaultPipelineColumns;
  const columns = viewToColumns(
    _viewColumns,
    fields,
    pipelineColumnConfig,
    defaultPipelineColumnConfig,
    applyPipelineColumnOrder
  );

  const setColumns = useCallback(
    (columns: ColumnConfigType[]) => {
      setView({ columns });
    },
    [setView]
  );

  const setSort = (value: SortType[]) => {
    setView({ sort: value });
  };

  const startAdding = () => {
    setIsAddPipelineModalOpen(true);
  };

  const trackEvent = (row: PartnerConnection) => {
    service.track({
      profile: profile,
      eventName: WebhookEventName.RevealOpenedMessageDrawer,
      partnerConnection: row,
      rawCompanyId: row.rawCompanyId,
      rawCompanyProviderKey: row.rawCompanyProviderKey,
      companyId: row.companyId,
      partnershipId: row.partnershipId,
    });
  };

  const handleOpenDrawer = (
    params: GridRowParams,
    event: MuiEvent<React.MouseEvent<HTMLElement>>,
    _details: GridCallbackDetails
  ) => {
    if ((event.target as HTMLElement).classList.contains("MuiDataGrid-row")) {
      return;
    }
    trackEvent(params.row);
    track("Opened message drawer");
    history.push({ search: `?discussion=${params.row.id}` });
  };

  const customMenu = (field: string) =>
    PIPELINE_FIELDS_WITH_YOU_TAG.includes(field as AvailablePipelineColumns) ? (
      <Box marginLeft="6px" flexShrink={999999} minWidth={0}>
        <YouTag isPartner={isPartnerView} />
      </Box>
    ) : (
      <></>
    );

  return (
    <div className={classes.root}>
      <BaseDataGrid
        classes={{
          root: !isPartnerView ? classes.inboundView : classes.outboundView,
        }}
        noSide
        hideHeaderMenus
        rows={rows}
        rowsCount={rowsCount ?? 0}
        hasRowSelection={profile.ownsPipeline}
        setSort={setSort}
        sortOnHeaders
        fields={fields ?? {}}
        loadMore={loadMore}
        fetching={fetching}
        columns={columns}
        emptyRowsView={
          <PipelineEmptyRowsView
            loading={loading}
            startAdding={!isPartnerView ? startAdding : undefined}
          />
        }
        headerTooltips={
          !isPartnerView
            ? {
                [AvailablePipelineColumns.rawOpportunityStatus]: (
                  <FormattedMessage
                    {...i18n.pipelineInboundOpportunityTooltip}
                    values={{
                      lnk: (chunks: string) => (
                        <Link
                          className={classes.link}
                          target="_blank"
                          to={"/settings/collaborate"}
                        >
                          {chunks}
                        </Link>
                      ),
                    }}
                  />
                ),
              }
            : undefined
        }
        smallScreenText={
          <>
            <FormattedHTMLMessage {...i18n.screenToSmall} />
          </>
        }
        loading={loading}
        onRowClick={(params, event, details) =>
          isRowClickable(params.row)
            ? handleOpenDrawer(params, event, details)
            : undefined
        }
        rowClickValidator={(params) => isRowClickable(params.row)}
        setColumns={setColumns}
        viewType={DataTableType.COLLABORATE}
        refreshTable={refreshTable}
        customMenu={customMenu}
      />
      <ColumnWidget
        viewType={DataTableType.COLLABORATE}
        columns={columns}
        orderColumns={applyPipelineColumnOrder}
        defaultColumnConfig={defaultPipelineColumnConfig}
        fields={fields ?? {}}
        setColumns={setColumns}
        availableFieldsDefaultConfig={pipelineColumnConfig}
      />
      <AddPipelineItemModal
        isOpen={isAddPipelineModalOpen}
        onClose={() => setIsAddPipelineModalOpen(false)}
        refresh={refreshTable}
        records={[]} // records are empty because this is the empty table view
      />
    </div>
  );
};

// I18N

const i18n = defineMessages({
  screenToSmall: {
    id: "DataTables.MyPipelineTable.screenToSmall",
    defaultMessage: `
      Your screen is too small to display this table.
      <br/><br/>
      Please try it from a wider device.
    `,
  },
  pipelineInboundOpportunityTooltip: {
    id: "DataTables.MyPipelineTable.pipelineInboundOpportunityTooltip",
    defaultMessage:
      "You're not sharing all opportunity details with partners. Each partner will see only the opportunity fields that you've defined as shared in your sharing settings <lnk>here</lnk>.",
  },
});

// CSS

const useStyles = makeStyles()((theme) => ({
  link: {
    textDecoration: "underline",
  },
  root: {
    position: "relative",
    height: "100%",
    paddingRight: 36,
    "& .rightColumn, & .MuiDataGrid-root": {
      borderColor: "transparent",
    },
    "& .MuiDataGrid-columnHeaders": {
      borderRight: "none!important",
    },
  },
  inboundView: {
    "& .MuiDataGrid-cellCheckbox": {
      backgroundColor: theme.palette.purpleBrand050,
    },
    "& .MuiDataGrid-columnHeader.checkbox .MuiDataGrid-columnHeaderTitleContainer": {
      backgroundColor: theme.palette.purpleBrand050,
    },
  },
  outboundView: {
    "& .MuiDataGrid-cellCheckbox": {
      backgroundColor: theme.palette.greenBrand050,
    },
    "& .MuiDataGrid-columnHeader.checkbox .MuiDataGrid-columnHeaderTitleContainer": {
      backgroundColor: theme.palette.greenBrand050,
    },
  },
}));
