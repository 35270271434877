import { defaultIncludeOfflineFilter } from "redux/analytics/defaults";
import {
  AnalyticsOtherFilterFieldname,
  AnalyticsQuickFilterFieldname,
} from "redux/analytics/types";

import { FilterType } from "../../../shared/types";

export const formatFilters = (
  filters: FilterType[] | undefined = [],
  hasAdvancedAnalytics: boolean,
  isToggleFilter: boolean = false
) => {
  if (!hasAdvancedAnalytics) {
    return [];
  }

  let cleanedFilters = filters.filter(
    (filter) =>
      ![
        AnalyticsOtherFilterFieldname.showSummaryMetrics,
        AnalyticsOtherFilterFieldname.highlightHighWinRate,
      ].includes(filter.fieldname as AnalyticsOtherFilterFieldname)
  );

  if (isToggleFilter) {
    // In that case we can remove AnalyticsQuickFilterFieldname.MyPartners (i.e. "my_partnership_owner_id")
    // because if this filter is used from the filter widget, it will be in camelCase.
    cleanedFilters = cleanedFilters.filter(
      (filter) =>
        ![
          AnalyticsQuickFilterFieldname.Favorites,
          AnalyticsQuickFilterFieldname.HighWinRatePartners,
          AnalyticsQuickFilterFieldname.MyPartners,
        ].includes(filter.fieldname as AnalyticsQuickFilterFieldname)
    );
  }

  const hasOffineFilter = Boolean(
    cleanedFilters.find(
      (item) => item.fieldname === AnalyticsOtherFilterFieldname.IncludeOffline
    ) ?? null
  );

  if (!hasOffineFilter) {
    return [...cleanedFilters, defaultIncludeOfflineFilter];
  }
  return cleanedFilters;
};
