import { createSvgIcon } from "@mui/material/utils";

import { SvgIcon } from "./types";

const displayName = "Share";

const Share: SvgIcon = createSvgIcon(
  // Using transform to enlarge from provided viewbox="0 0 16 16"
  // to default "0 0 24 24" from `createSvgIcon` -> `SvgIcon`.
  <g fill="none" transform="matrix(1.5,0,0,1.5,0,0)">
    <path
      d="M9.112 1.50454C9.01616 1.41851 8.89746 1.36209 8.77023 1.34208C8.64301 1.32207 8.51271 1.33934 8.3951 1.39181C8.27748 1.44427 8.17757 1.52968 8.10746 1.6377C8.03734 1.74573 8.00002 1.87176 8 2.00054V5.35254C5.15467 5.50721 0 6.78721 0 14.0005C0.000239546 14.1441 0.0468427 14.2838 0.132873 14.3988C0.218904 14.5138 0.339763 14.5979 0.477463 14.6387C0.615163 14.6794 0.762342 14.6746 0.897095 14.625C1.03185 14.5754 1.14697 14.4836 1.22533 14.3632C1.96032 13.233 2.96358 12.3022 4.14564 11.6538C5.32771 11.0054 6.65185 10.6596 8 10.6472V14.0005C8.00002 14.1293 8.03734 14.2554 8.10746 14.3634C8.17757 14.4714 8.27748 14.5568 8.3951 14.6093C8.51271 14.6617 8.64301 14.679 8.77023 14.659C8.89746 14.639 9.01616 14.5826 9.112 14.4965L15.7787 8.49654C15.8483 8.43402 15.904 8.35755 15.9421 8.27209C15.9802 8.18664 15.9999 8.09411 15.9999 8.00054C15.9999 7.90697 15.9802 7.81445 15.9421 7.729C15.904 7.64354 15.8483 7.56707 15.7787 7.50454L9.112 1.50454Z"
      fill="currentColor"
    />
  </g>,
  displayName
);

Share.displayName = displayName;
export default Share;
