import Grid from "@mui/material/Grid";
import { Lock } from "components/icons";
import UpdatePlanDialog from "components/ui/navbar/PremiumPlanWidget/components/UpdatePlanDialog";
import { T } from "components/ui/Typography";
import useSegment from "hooks/useSegment";
import { makeStyles } from "makeStyles";
import { useState } from "react";
import { defineMessages, FormattedMessage } from "react-intl";
import { PremiumPlanEvent } from "tracking";

type Props = {
  partnerCount: number;
};

const MorePartnerAccountCard = ({ partnerCount }: Props) => {
  const { track } = useSegment();
  const { classes } = useStyles();
  const [dialogOpen, setDialogOpen] = useState(false);

  const handleOpenDialog = () => {
    track(PremiumPlanEvent.incentiveDrawer360);
    setDialogOpen(true);
  };

  return (
    <Grid
      container
      className={classes.partnerCard}
      spacing={2}
      onClick={handleOpenDialog}
    >
      <T className={classes.label}>
        <FormattedMessage {...i18n.moreCount} values={{ partnerCount }} />
      </T>
      <Lock className={classes.lock} />
      <UpdatePlanDialog
        isOpen={dialogOpen}
        onClose={() => setDialogOpen(false)}
      />
    </Grid>
  );
};

export default MorePartnerAccountCard;

// CSS

const useStyles = makeStyles()((theme) => ({
  partnerCard: {
    margin: 0,
    borderRadius: 8,
    border: `1px solid ${theme.palette.greyLight100}`,
    width: "100%",
    padding: 10,
    display: "flex",
    justifyContent: "center",
    columnGap: 4,
    cursor: "pointer",
  },
  label: {
    color: theme.palette.alpha500,
  },
  lock: {
    color: theme.palette.pigeon,
    fontSize: 16,
  },
}));

// I18N

const i18n = defineMessages({
  moreCount: {
    id:
      "Ecosystem.AccountView.AccountDetails.AccountTabs.MorePartnerAccountCard.moreCount",
    defaultMessage: "+ {partnerCount} more",
  },
});
