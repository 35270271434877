import { createSvgIcon } from "@mui/material/utils";

import { SvgIcon } from "./types";

const displayName = "BookmarkOutlined";

const BookmarkOutlined: SvgIcon = createSvgIcon(
  // Using transform to enlarge from provided viewbox="0 0 16 16"
  // to default "0 0 24 24" from `createSvgIcon` -> `SvgIcon`.
  <g fill="none" transform="matrix(1.5,0,0,1.5,0,0)">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2 2.5C2 1.67157 2.67157 1 3.5 1H12.5C13.3284 1 14 1.67157 14 2.5V13.2817C14 14.4318 12.759 15.154 11.759 14.5859L8.247 12.5904C8.09383 12.5034 7.90617 12.5034 7.753 12.5904L4.24101 14.5859C3.24105 15.154 2 14.4318 2 13.2817V2.5ZM3.5 2C3.22386 2 3 2.22386 3 2.5V13.2817C3 13.665 3.41368 13.9058 3.747 13.7164L7.25899 11.721C7.7185 11.4599 8.2815 11.4599 8.74101 11.721L12.253 13.7164C12.5863 13.9058 13 13.665 13 13.2817V2.5C13 2.22386 12.7761 2 12.5 2H3.5Z"
      fill="currentColor"
    />
  </g>,
  displayName
);

BookmarkOutlined.displayName = displayName;
export default BookmarkOutlined;
