import { JSONAPIAttributes, JSONAPIIdentifier } from "models/types";

export enum FieldType {
  Input = "input",
  TextArea = "textarea",
  Checkbox = "checkbox",
  Picklist = "picklist",
  Region = "region",
  Country = "country",
  Datetime = "datetime",
  Number = "number",
}

export enum LeadStatus {
  Open = "open",
}

export type PartnerStackPartnership = {
  groupKey: string;
  id: number;
  partnerKey: string;
  type: string;
  attributes: JSONAPIAttributes;
  relationships: {
    [relation: string]: JSONAPIIdentifier | JSONAPIIdentifier[];
  };
};

export type FieldValue = {
  fieldName: string;
  value: string;
};

export type Values = {
  fields: FieldValue[];
};
