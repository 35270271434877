import { Check } from "components/icons";
import Popup from "components/ui/Popup";
import { T } from "components/ui/Typography";
import generic from "i18n/generic";
import { makeStyles } from "makeStyles";
import {
  defineMessages,
  FormattedHTMLMessage,
  FormattedMessage,
} from "react-intl";

type Props = {
  pendingNumber: number;
  totalNumber: number;
  updatingOverlapsNumber: number;
  updatingWhitespaceNumber: number;
  isOpen: boolean;
  handleSave: () => void;
  handleClose: () => void;
};

const ActivatePartnershipsConfirmDialog = ({
  pendingNumber,
  isOpen,
  handleSave,
  handleClose,
  totalNumber,
  updatingOverlapsNumber,
  updatingWhitespaceNumber,
}: Props) => {
  const { classes } = useStyles();

  return (
    <Popup
      isOpen={isOpen}
      title={
        <T h3 bold>
          <FormattedMessage
            {...i18n.title}
            values={{ partnerNumber: totalNumber }}
          />
        </T>
      }
      handleClose={handleClose}
      handleSave={handleSave}
      maxWidth="sm"
      saveButtonText={generic.confirm}
      variant="secondary"
    >
      <div className={classes.popupContainer}>
        {pendingNumber > 0 && (
          <T>
            <Check className={classes.icon} />
            <FormattedHTMLMessage
              {...i18n.connectText}
              values={{ partnerNumber: pendingNumber }}
            />
          </T>
        )}
        {updatingOverlapsNumber > 0 && (
          <T>
            <Check className={classes.icon} />
            <FormattedHTMLMessage
              {...i18n.shareOverlapsText}
              values={{ partnerNumber: updatingOverlapsNumber }}
            />
          </T>
        )}
        {updatingWhitespaceNumber > 0 && (
          <T>
            <Check className={classes.icon} />
            <FormattedHTMLMessage
              {...i18n.shareWhitespaceText}
              values={{ partnerNumber: updatingWhitespaceNumber }}
            />
          </T>
        )}
      </div>
    </Popup>
  );
};

export default ActivatePartnershipsConfirmDialog;

// I18N

const i18n = defineMessages({
  title: {
    id: "onboarding.activatePartnerships.title",
    defaultMessage:
      "Are you sure you want to activate {partnerNumber, plural, one {1 partnership} other {# partnerships}}?",
  },
  connectText: {
    id: "onboarding.activatePartnerships.connectText",
    defaultMessage: `Connect with {partnerNumber, plural, one {1 new Partner} other {# new Partners}} on Reveal`,
  },
  shareOverlapsText: {
    id: "onboarding.activatePartnerships.shareOverlapsText",
    defaultMessage: `Share Account Overlaps with {partnerNumber, plural, one {1 Partner} other {# Partners}} on Reveal`,
  },
  shareWhitespaceText: {
    id: "onboarding.activatePartnerships.shareWhitespaceText",
    defaultMessage: `Share your entire list of customers with {partnerNumber, plural, one {1 Partner} other {# Partners}} on Reveal`,
  },
});

// CSS
export const useStyles = makeStyles()((theme) => ({
  popupContainer: {
    display: "flex",
    flexDirection: "column",
  },
  icon: {
    marginRight: 8,
    height: 16,
    width: 16,
  },
}));
