import { createSvgIcon } from "@mui/material/utils";

import { SvgIcon } from "./types";

const displayName = "Rows";

const Rows: SvgIcon = createSvgIcon(
  // Using transform to enlarge from provided viewbox="0 0 16 16"
  // to default "0 0 24 24" from `createSvgIcon` -> `SvgIcon`.
  <g fill="none" transform="matrix(1.5,0,0,1.5,0,0)">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12 3H4C3.44772 3 3 3.44772 3 4V5.33333C3 5.88562 3.44772 6.33333 4 6.33333H12C12.5523 6.33333 13 5.88562 13 5.33333V4C13 3.44772 12.5523 3 12 3ZM4 2C2.89543 2 2 2.89543 2 4V5.33333C2 6.4379 2.89543 7.33333 4 7.33333H12C13.1046 7.33333 14 6.4379 14 5.33333V4C14 2.89543 13.1046 2 12 2H4Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12 9.66669H4C3.44772 9.66669 3 10.1144 3 10.6667V12C3 12.5523 3.44772 13 4 13H12C12.5523 13 13 12.5523 13 12V10.6667C13 10.1144 12.5523 9.66669 12 9.66669ZM4 8.66669C2.89543 8.66669 2 9.56212 2 10.6667V12C2 13.1046 2.89543 14 4 14H12C13.1046 14 14 13.1046 14 12V10.6667C14 9.56212 13.1046 8.66669 12 8.66669H4Z"
      fill="currentColor"
    />
  </g>,
  displayName
);

Rows.displayName = displayName;
export default Rows;
