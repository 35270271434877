import { Chip } from "@mui/material";
import axios, { AxiosResponse } from "axios";
import { Close, Plus } from "components/icons";
import CompanyAvatar from "components/ui/avatars/CompanyAvatar";
import Dropdown from "components/ui/Dropdown/components/Dropdown";
import { IDropdownOption } from "components/ui/Dropdown/components/types";
import { TextInput } from "components/ui/TextInput";
import _ from "lodash";
import { makeStyles } from "makeStyles";
import Record from "models/Record";
import { stringify } from "query-string";
import {
  ChangeEvent,
  ComponentProps,
  MouseEvent,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { defineMessages, useIntl } from "react-intl";
import SearchService from "services/SearchService";
import { v4 as uuidv4 } from "uuid";

export const CLEARBIT_AUTOCOMPLETE_URL =
  "https://autocomplete.clearbit.com/v1/companies/suggest?";

export type CompanySuggestion = {
  id?: string | number | null;
  label?: string;
  name: string;
  logo?: string | null;
  domain: string | null;
};

type Props = Omit<
  ComponentProps<typeof TextInput>,
  "name" | "value" | "onChange"
> & {
  extraGroup?: { id: string; name: string; group: CompanySuggestion[] };
  name: string;
  disabled: boolean;
  onChange: (event: {
    target: {
      name: string;
      value: CompanySuggestion;
    };
    type: string;
  }) => void;
  onDeleteCompany?: () => void;
  setValueWhileTyping?: boolean;
  value: CompanySuggestion;
  variant?: string;
  useInternal?: Boolean;
};

const CompanyAutocomplete = ({
  extraGroup,
  placeholder,
  disabled,
  variant,
  name,
  onChange,
  onDeleteCompany,
  setValueWhileTyping = true,
  value = {
    name: "",
    logo: null,
    domain: null,
  },
  useInternal = true,
  error,
}: Props) => {
  const intl = useIntl();
  const { classes } = useStyles();
  const [clearbitSuggestions, setClearbitSuggestions] = useState<
    CompanySuggestion[]
  >([]);
  const [internalSuggestions, setInternalSuggestions] = useState<
    CompanySuggestion[]
  >([]);
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const [inputValue, setInputValue] = useState<string>(value.name);
  const [open, setOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const inputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    setAnchorEl(inputRef.current);
  }, [inputRef]);

  const handleClick = useCallback(
    (event: MouseEvent<HTMLDivElement>) => {
      setAnchorEl(event.currentTarget);
      if (inputValue.length > 0 || extraGroup?.group.length) setOpen(true);
    },
    [extraGroup, inputValue, setAnchorEl]
  );

  const handleCloseDropdown = useCallback(() => {
    setAnchorEl(null);
    setOpen(false);
  }, [setAnchorEl, setOpen]);

  const handleAddNewCompany = useCallback(() => {
    inputValue &&
      onChange({
        type: "change",
        target: {
          name: "company",
          value: { name: inputValue, domain: null, id: "new" },
        },
      });
    handleCloseDropdown();
  }, [handleCloseDropdown, inputValue, onChange]);

  const extraItems: CompanySuggestion[] = useMemo(() => {
    return (
      extraGroup?.group.filter(
        (item) =>
          item.name.toLowerCase().includes(inputValue.toLowerCase()) ||
          item.domain?.toLowerCase().includes(inputValue.toLowerCase())
      ) ?? []
    );
  }, [extraGroup, inputValue]);

  const loadOptions = useMemo<IDropdownOption[]>(() => {
    const suggestions: IDropdownOption[] = [];
    if (extraGroup?.group && extraItems?.length) {
      suggestions.push({
        ...extraGroup,
        group: extraItems.map(converToRow),
      });
    }
    const filteredClearbitSuggestions = clearbitSuggestions.filter(
      (item) =>
        !internalSuggestions.some((internal) => internal.name === item.name)
    );
    const list = [...filteredClearbitSuggestions, ...internalSuggestions];
    if (
      extraItems.length ||
      (internalSuggestions.length && clearbitSuggestions.length)
    ) {
      internalSuggestions.length &&
        suggestions.push({
          id: uuidv4(),
          name: intl.formatMessage(i18n.groupRevealSuggestions),
          group: internalSuggestions.map(converToRow),
        });
      filteredClearbitSuggestions.length &&
        suggestions.push({
          id: uuidv4(),
          name: useInternal
            ? intl.formatMessage(i18n.groupClearbitSuggestions)
            : "",
          group: filteredClearbitSuggestions.map(converToRow),
        });
    } else if (list.length) {
      suggestions.push({
        id: uuidv4(),
        name: "",
        group: list.map(converToRow),
      });
    }
    if (
      inputValue &&
      ![...internalSuggestions, ...clearbitSuggestions, ...extraItems]
        .map((suggestion) => suggestion.name)
        .includes(inputValue)
    ) {
      const inputCompany = {
        name: inputValue,
        label: (
          <Chip
            avatar={<Plus />}
            className={classes.addNew}
            label={intl.formatMessage(i18n.addNew, { name: inputValue })}
            onClick={handleAddNewCompany}
          />
        ),
        domain: null,
        id: uuidv4(),
        isHoverable: false,
      };
      suggestions.unshift({ id: "", name: "", group: [inputCompany] });
    }
    return suggestions;
  }, [
    clearbitSuggestions,
    handleAddNewCompany,
    internalSuggestions,
    inputValue,
    useInternal,
    intl,
    classes.addNew,
    extraItems,
    extraGroup,
  ]);

  const onInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    setIsLoading(true);
    setInputValue(e.target.value);

    debouncedClearbitOptions(e.target.value);
    debouncedInternalOptions(e.target.value);

    if (e.target.value.length >= 1 || extraGroup?.group.length) {
      setAnchorEl(e.currentTarget);
      setOpen(true);
    } else {
      setOpen(false);
      setAnchorEl(null);
    }
    const newValue = {
      name: "",
      logo: null,
      domain: null,
    };
    if (setValueWhileTyping) {
      newValue.name = e.target.value;
      onChange({ target: { value: newValue, name }, type: e.type });
      return;
    }
    if (value.name) {
      onChange({ target: { value: newValue, name }, type: e.type });
    }
  };

  const selectCompany = (value: string[] | null) => {
    if (value) {
      const company = [
        ...clearbitSuggestions,
        ...internalSuggestions,
        ...(extraGroup?.group ?? []),
      ].find((s) => s.id && s.id === value[0]);
      if (company) {
        company.label
          ? setInputValue(company.label)
          : setInputValue(company.name);
      }
      onChange({
        target: {
          name,
          value: company ?? { domain: null, name: inputValue },
        },
        type: "change",
      });
    }

    handleCloseDropdown();
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedClearbitOptions = useCallback(
    _.debounce((evt: string) => {
      if (evt.length > 0) {
        const query = stringify({ query: evt });
        axios
          .get(CLEARBIT_AUTOCOMPLETE_URL + query)
          .then((response: AxiosResponse<CompanySuggestion[]>) => {
            const companySuggestions = response.data.map((s) => {
              return {
                ...s,
                id: uuidv4(),
              };
            });
            const uniqueSuggestions = _.uniqBy(
              companySuggestions,
              (company) => company.domain
            );
            setClearbitSuggestions(uniqueSuggestions);
            setIsLoading(false);
          })
          .catch();
      } else {
        setClearbitSuggestions([]);
        setIsLoading(false);
      }
    }, 300),
    []
  );

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedInternalOptions = useCallback(
    _.debounce(async (input: string) => {
      if (useInternal && input !== "") {
        setIsLoading(true);
        SearchService("companies")
          .autocomplete(input)
          .perform({
            "page[size]": 5,
          })
          .then((response) => {
            setInternalSuggestions(
              response?.records?.map((record: Record) => ({
                id: record.id,
                name: record.name,
                logo: record.avatarUrl,
                domain: record.domainName,
              })) ?? []
            );
            inputRef.current?.focus();
          })
          .finally(() => setIsLoading(false));
      } else {
        setInternalSuggestions([]);
        setIsLoading(false);
      }
    }, 300),
    []
  );

  const handleDelete = () => {
    onDeleteCompany?.();
    setInputValue("");
  };

  return (
    <>
      <TextInput
        autoComplete="off"
        disabled={disabled}
        iconSize="xsmall"
        ref={inputRef}
        onClick={handleClick}
        placeholder={placeholder}
        value={inputValue}
        logo={
          value.logo && (
            <span className={classes.avatarContainer}>
              <CompanyAvatar src={value.logo} size="xs" />
            </span>
          )
        }
        RightIcon={onDeleteCompany && Boolean(value.name) && Close}
        RightIconAction={handleDelete}
        onChange={onInputChange}
        id="input_id"
        variant={variant}
        name={name}
        error={error}
      />
      {!disabled && (
        <Dropdown
          isLoading={isLoading}
          options={loadOptions}
          anchorEl={anchorEl}
          onClose={handleCloseDropdown}
          open={open}
          withGroups={true}
          withSearch={false}
          onChange={selectCompany}
          size="inherit"
        />
      )}
    </>
  );
};

const useStyles = makeStyles()(() => ({
  addNew: {
    fontFamily: "Inter",
    fontSize: 12,
    fontWeight: 500,
    lineHeight: "12px",
    "& .MuiChip-avatar": {
      height: 14,
      width: 14,
    },
    "& .MuiChip-label": {
      paddingLeft: 8,
    },
  },
  avatarContainer: {
    marginRight: 8,
  },
}));

const converToRow = (s: CompanySuggestion) => {
  return {
    id: s.id,
    name: s.name,
    logo: s.logo ?? "/images/background-square.png",
    subText: s.domain,
  } as IDropdownOption;
};

const i18n = defineMessages({
  addNew: {
    id: "companyAutocomplete.addNew",
    defaultMessage: 'Add "{name}"',
  },
  groupRevealSuggestions: {
    id: "companyAutocomplete.groupRevealSuggestions",
    defaultMessage: "Reveal Companies:",
  },
  groupClearbitSuggestions: {
    id: "companyAutocomplete.groupClearbitSuggestions",
    defaultMessage: "Other Companies:",
  },
});

export default CompanyAutocomplete;
