import history from "_history";
import CircularProgress from "@mui/material/CircularProgress";
import Grid from "@mui/material/Grid";
import withCompanyPermissions from "components/hoc/withCompanyPermissions";
import withRecord, { WithRecord } from "components/hoc/withRecord";
import { WithUserProfile } from "components/hoc/withUserProfile";
import Button from "components/ui/Button";
import NotFoundDialogManager from "components/ui/NotFoundDialogManager";
import PageLoader from "components/ui/PageLoader";
import TabList from "components/ui/TabList";
import UnauthorizedInfoDialog from "components/ui/UnauthorizedInfoDialog";
import { partnership as demo } from "data/demo";
import useSegment from "hooks/useSegment";
import useUserProfile from "hooks/useUserProfile";
import { makeStyles } from "makeStyles";
import Partnership from "models/Partnership";
import { useCallback, useEffect, useState } from "react";
import { defineMessages } from "react-intl";
import { useSelector } from "react-redux";
import { RouteComponentProps, useLocation, useParams } from "react-router-dom";
import { fields, included } from "redux/init/constants";
import { selectHasSources } from "redux/user/selectors";

import CollaborationTab from "./CollaborationTab";
import { isTabValue, Tab, TABS } from "./constants";
import CrmMappingTab from "./CrmMappingTab";
import DataSourcesTab from "./DataSourcesTab";
import FollowersTab from "./FollowersTab";
import GeneralSettingsTab from "./GeneralSettingsTab";
import Overview from "./OverviewTab";
import { PartnershipSettingsInsightOverview } from "./PartnershipSettingsInsightOverview";
import { PartnershipSettingsOverview } from "./PartnershipSettingsOverview";
import RestrictedAccountsTab from "./RestrictedAccountsTab";

const TabComponentFor = {
  [Tab.General]: GeneralSettingsTab,
  [Tab.Overview]: Overview,
  [Tab.DataSources]: DataSourcesTab,
  [Tab.Collaboration]: CollaborationTab,
  [Tab.RestrictedAccounts]: RestrictedAccountsTab,
  [Tab.Followers]: FollowersTab,
};

type Params = {
  recordId: string;
  tabName?: string;
};

type Props = WithUserProfile &
  RouteComponentProps<Params> &
  WithRecord<Partnership>;

const PartnershipSettingsPage = ({
  record: partnership,
  ready,
  reloadRecord,
}: Props) => {
  const location = useLocation();
  const { profile } = useUserProfile();
  const [saving, setSaving] = useState(false);
  const [saveButtonEnabled, setSaveButtonEnabled] = useState(false);
  const afterSave = useCallback(() => {
    setSaving(false);
    setSaveButtonEnabled(false);
  }, [setSaving]);
  const { tabName } = useParams<Params>();
  const [currentTab, setCurrentTab] = useState(
    isTabValue(tabName)
      ? tabName
      : partnership.isGhost()
      ? Tab.DataSources
      : Tab.General
  );
  const hasSources = useSelector(selectHasSources);
  const canManageSlack = profile.hasPermissions(
    ["company.manage_slack"],
    profile.company
  );

  const { track } = useSegment();

  const partner = partnership.getPartner(profile);
  const { classes } = useStyles();

  const getAvailableTabs = () => {
    let _tabs = TABS;
    if (partnership.isDemo) {
      return _tabs
        .filter((tab) => [Tab.General, Tab.Overview].includes(tab.value))
        .map((item) =>
          item.value === Tab.General ? { ...item, disabled: true } : item
        );
    }
    if (partnership.isGhost()) {
      _tabs = _tabs.filter(
        (tab) => ![Tab.General, Tab.RestrictedAccounts].includes(tab.value)
      );
    }
    if (!canManageSlack) {
      _tabs = _tabs.filter((tab) => tab.value !== Tab.Collaboration);
    }
    if (!profile.canManagePartnerships) {
      _tabs = TABS.filter((tab) => tab.value === Tab.Overview);
    }
    return _tabs;
  };

  const availableTabs = getAvailableTabs();

  const goBackInHistory = useCallback(() => {
    history.goBack({ fallback: "/partnerships" });
  }, []);

  useEffect(() => {
    if (tabName !== currentTab) {
      history.replace({
        pathname: `/partnerships/${partnership.id}/settings/${currentTab}`,
        search: location.search,
      });
    }
    if (currentTab === Tab.General) {
      track("Viewed general partnership settings");
    } else if (currentTab === Tab.RestrictedAccounts) {
      track("Viewed restricted accounts partnership settings");
    }
  }, [currentTab]); // eslint-disable-line react-hooks/exhaustive-deps

  const TabComponent = Object.entries(TabComponentFor)
    .filter(([value, _component]) =>
      availableTabs.some((item) => item.value === value)
    )
    .reduce(
      (acc, [value, component]) => ({ ...acc, [value]: component }),
      {} as any
    )[currentTab];

  return (
    <Grid container className={classes.root}>
      <Grid item className={classes.side}>
        {!ready ? (
          <div className={classes.circularProgressContainer}>
            <CircularProgress />
          </div>
        ) : (
          <>
            <PartnershipSettingsOverview
              className={classes.marginBottom}
              partnership={partnership}
              reloadRecord={reloadRecord}
              saving={saving}
              setSaving={setSaving}
            />
            <PartnershipSettingsInsightOverview partnership={partnership} />
            {hasSources && profile.canManagePartnerships && (
              <CrmMappingTab
                className={classes.marginTop}
                partnership={partnership}
                partner={partner}
                profile={profile}
                reloadPartnership={reloadRecord}
              />
            )}
          </>
        )}
      </Grid>
      <Grid item className={classes.content}>
        <Grid container direction="column" className={classes.rowGap}>
          {!ready ? (
            <div className={classes.circularProgressContainer}>
              <CircularProgress />
            </div>
          ) : (
            <>
              <div className={classes.tabsAndSaveButton}>
                {profile.canManagePartnerships && (
                  <TabList
                    tabs={availableTabs}
                    selectedValue={currentTab}
                    setValue={(tabValue) => {
                      setCurrentTab(tabValue);
                      setSaveButtonEnabled(false);
                    }}
                    itemCountBeforeDropdown={4}
                  />
                )}
                {currentTab !== Tab.Overview && (
                  <div className={classes.saveAndCancelButtons}>
                    <Button
                      variant="secondary"
                      label={i18n.cancel}
                      onClick={goBackInHistory}
                    />
                    <Button
                      label={i18n.save}
                      classes={{ btn: classes.saveButton }}
                      onClick={() => setSaving(true)}
                      disabled={!saveButtonEnabled}
                      loading={saving}
                    />
                  </div>
                )}
              </div>
              {TabComponent ? (
                <div>
                  <TabComponent
                    partnership={partnership}
                    partner={partner}
                    profile={profile}
                    saving={saving}
                    afterSave={afterSave}
                    setSaveButtonEnabled={setSaveButtonEnabled}
                    reloadPartnership={reloadRecord}
                  />
                </div>
              ) : (
                <UnauthorizedInfoDialog callBackUrl="/partnerships" />
              )}
            </>
          )}
        </Grid>
      </Grid>
    </Grid>
  );
};

const useStyles = makeStyles()((theme) => ({
  content: {
    width: "68%",
    paddingRight: theme.spacing(2.5),
  },
  root: {
    marginTop: theme.spacing(2.5),
  },
  rowGap: {
    rowGap: theme.spacing(2),
  },
  side: {
    width: "32%",
    padding: theme.spacing(0, 2.5),
  },
  actionContainer: {
    display: "flex",
    alignItems: "center",
    columnGap: theme.spacing(0.5),
  },
  tabsAndSaveButton: {
    display: "flex",
    justifyContent: "space-between",
  },
  saveAndCancelButtons: {
    display: "flex",
  },
  saveButton: {
    marginLeft: theme.spacing(1),
  },
  circularProgressContainer: {
    display: "flex",
    justifyContent: "space-around",
    marginTop: theme.spacing(10),
  },
  marginBottom: {
    marginBottom: theme.spacing(1),
  },
  marginTop: {
    marginTop: theme.spacing(1),
  },
}));

const i18n = defineMessages({
  title: {
    id: "crm.PartnershipSettings.PartnershipSettingsPage.title",
    defaultMessage: "Settings",
  },
  settings: {
    id: "crm.PartnershipSettings.PartnershipSettingsPage.settings",
    defaultMessage: "Settings",
  },
  save: {
    id: "crm.PartnershipSettings.PartnershipSettingsPage.save",
    defaultMessage: "Save",
  },
  cancel: {
    id: "crm.PartnershipSettings.PartnershipSettingsPage.cancel",
    defaultMessage: "Cancel",
  },
  deletePartnership: {
    id: "crm.PartnershipSettings.PartnershipSettingsPage.deletePartnership",
    defaultMessage: "Delete partnership",
  },
  collaborationDisabled: {
    id: "crm.PartnershipSettings.PartnershipSettingsPage.collaborationDisabled",
    defaultMessage: "First, set up your Slack integration",
  },
  here: {
    id: "crm.PartnershipSettings.PartnershipSettingsPage.here",
    defaultMessage: "here.",
  },
});

export default withCompanyPermissions<
  WithUserProfile & RouteComponentProps<Params>
>(["company.partners_access"])(
  withRecord<Partnership, Props>("partnerships", {
    Loader: () => <PageLoader />,
    loadOnMount: true,
    ErrorHandler: NotFoundDialogManager,
    include: included,
    fields,
  })(PartnershipSettingsPage)
);

const withDemoRecord = () => <P extends object>(
  WrappedComponent: React.ComponentType<P & Partial<WithRecord<Partnership>>>
) => (props: P) => (
  <WrappedComponent
    {...props}
    ready={true}
    record={demo}
    reloadRecord={() => {}}
  />
);

export const DemoPartnershipSettingsPage = withDemoRecord()(
  PartnershipSettingsPage
);
