import { createReducer } from "@reduxjs/toolkit";

import { setFilters } from "./actions";
import { MeasureState } from "./types";

export default createReducer(
  {
    filters: [],
  } as MeasureState,
  (builder) =>
    builder.addCase(setFilters, (state, action) => {
      state.filters = action.payload;
    })
);
