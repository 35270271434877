import axios, { AxiosInstance, AxiosRequestConfig, AxiosResponse } from "axios";
import config from "config";
import _ from "lodash";
import { JSONSerializable } from "models/types";

import NonUserService from "./NonUserService";

export interface CancellablePromise<T> extends Promise<T> {
  cancel?: () => void;
}

export enum Headers {
  Accept = "Accept",
  AcceptLanguage = "Accept-Language",
  ContentType = "Content-Type",
  JWTAud = "JWT-aud",
  XEnableWorkspace = "X-enable-workspace",
  Authorization = "Authorization",
}

export const API_JSON = "application/json";
export const VND_API_JSON = "application/vnd.api+json";
const ENGLISH_LOCALE = "en";

export const tenantEnabled = () => {
  return window.sessionStorage.getItem("hasMultiTenantEnabled") === "yes";
};

export default class BaseHTTPService {
  axios: AxiosInstance;

  constructor(baseURL: string, headers: { [header: string]: string } = {}) {
    const defaultHeaders = {
      [Headers.ContentType]: VND_API_JSON,
      [Headers.Accept]: VND_API_JSON,
      [Headers.AcceptLanguage]: ENGLISH_LOCALE,
      [Headers.JWTAud]: config().appName,
      [Headers.XEnableWorkspace]: tenantEnabled() ? "yes" : "no",
    };

    this.axios = axios.create({
      baseURL,
      headers: _.defaults(headers, defaultHeaders),
    });
  }

  authenticate = (config: AxiosRequestConfig) => {
    const token = localStorage.getItem("user.token");
    const isNonUser = !!localStorage.getItem(NonUserService.NON_USER_FLAG);
    if (!token) {
      return config;
    }
    return _.merge(config || {}, {
      headers: {
        [Headers.Authorization]: `Bearer ${token}`,
        ...(isNonUser && { [Headers.JWTAud]: "crm_user" }),
      },
    });
  };

  authDelete = (url: string, config: AxiosRequestConfig) =>
    this.axios.delete(url, this.authenticate(config));

  authGet = <T>(url: string, config: AxiosRequestConfig) => {
    const source = axios.CancelToken.source();
    const promise = this.axios.get(url, {
      ...this.authenticate(config),
      cancelToken: source.token,
    }) as CancellablePromise<AxiosResponse<T>>;
    promise.cancel = source.cancel;
    return promise;
  };

  authPatch = <T>(
    url: string,
    data: JSONSerializable | FormData,
    config: AxiosRequestConfig
  ) => this.axios.patch<T>(url, data, this.authenticate(config));

  authPost = <T>(
    url: string,
    data: JSONSerializable | FormData,
    config: AxiosRequestConfig
  ) => this.axios.post<T>(url, data, this.authenticate(config));

  authPut = <T>(
    url: string,
    data: JSONSerializable | FormData,
    config: AxiosRequestConfig
  ) => this.axios.put<T>(url, data, this.authenticate(config));

  authOptions = <T>(url: string, config: AxiosRequestConfig) =>
    this.axios.options<T>(url, this.authenticate(config));
}
