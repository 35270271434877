import DialogActions from "@mui/material/DialogActions";
import FormControl from "@mui/material/FormControl";
import FormHelperText from "@mui/material/FormHelperText";
import Grid from "@mui/material/Grid";
import Button from "components/ui/Button";
import Popup from "components/ui/Popup";
import { TextInput } from "components/ui/TextInput";
import { T } from "components/ui/Typography";
import { ProviderType } from "config/constants";
import { Formik } from "formik";
import { Location } from "history";
import useOauthState from "hooks/useOauthState";
import forms from "i18n/forms";
import generic from "i18n/generic";
import { makeStyles } from "makeStyles";
import { defineMessages, FormattedMessage, useIntl } from "react-intl";
import { object, string } from "yup";

type Props = {
  isOpen?: boolean;
  onClose: () => void;
  next: string | Location;
};

const END_URL_MS = ".dynamics.com";

function isMsUrl(url?: string) {
  return url !== undefined && new RegExp(END_URL_MS).test(url);
}

const schema = object().shape({
  ms_crm_url: string()
    .required(() => "_do_not_display")
    .url(() => forms.url_invalid)
    .test("is-ms-url-valid", () => i18n.notMsFormat, isMsUrl),
});

const MsDynamicsCrmUrlDialog = ({ isOpen = false, onClose, next }: Props) => {
  const { classes } = useStyles();
  const intl = useIntl();

  const { start } = useOauthState();

  const handleMSModalSubmit = async (
    values: { ms_crm_url: string },
    actions: $TSFixMe
  ) => {
    actions.setSubmitting(true);
    // Deleting useless params after END_URL_MS & adding scope
    const scope =
      values.ms_crm_url.split(END_URL_MS)[0] +
      END_URL_MS +
      "//user_impersonation offline_access";
    start(ProviderType.msDynamics, next, scope);
    actions.setSubmitting(false);
  };

  return (
    <Popup
      isOpen={isOpen}
      title={
        <T h2 bold>
          <FormattedMessage {...i18n.msdynamicsTitle} />
        </T>
      }
      handleClose={onClose}
      hideActions
      maxWidth="sm"
      variant="secondary"
    >
      <Formik
        initialValues={{
          ms_crm_url: "",
        }}
        onSubmit={handleMSModalSubmit}
        validationSchema={schema}
      >
        {({
          values,
          handleSubmit,
          handleChange,
          isSubmitting,
          touched,
          errors,
        }) => (
          <form onSubmit={handleSubmit}>
            <FormControl
              fullWidth
              error={Boolean(errors.ms_crm_url && touched.ms_crm_url)}
            >
              <T>
                <FormattedMessage {...i18n.msdynamicsExplanation} />
              </T>
              <br />
              <T>
                <FormattedMessage {...i18n.msdynamicsURLFormatExplanation} />
              </T>
              <br />
              <TextInput
                className={classes.input}
                name="ms_crm_url"
                onChange={handleChange}
                placeholder={intl.formatMessage(i18n.msdynamicsPlaceholder)}
                disabled={isSubmitting}
                error={Boolean(touched.ms_crm_url && errors.ms_crm_url)}
                value={values.ms_crm_url}
              />
              {errors.ms_crm_url &&
                touched.ms_crm_url &&
                errors.ms_crm_url !== "_do_not_display" && (
                  <FormHelperText>
                    <FormattedMessage {...errors.ms_crm_url} />
                  </FormHelperText>
                )}
            </FormControl>
            <DialogActions>
              <Grid container className={classes.actionContainer}>
                <Button
                  label={generic.cancel}
                  onClick={onClose}
                  disabled={isSubmitting}
                  variant="secondary"
                />
                <Button
                  type="submit"
                  label={generic.next}
                  disabled={isSubmitting ? true : undefined}
                />
              </Grid>
            </DialogActions>
          </form>
        )}
      </Formik>
    </Popup>
  );
};

export default MsDynamicsCrmUrlDialog;

/// CSS

const useStyles = makeStyles()((theme) => ({
  input: {
    fontSize: 12,
    margin: theme.spacing(1),
  },
  actionContainer: {
    display: "flex",
    justifyContent: "flex-end",
    columnGap: theme.spacing(0.5),
  },
}));

/// I18N

const i18n = defineMessages({
  msdynamicsTitle: {
    id: "ConnectSource.MsDynamicsCrmUrlDialog.msdynamicsTitle",
    defaultMessage: "Enter your MS Dynamics organization URL",
  },
  msdynamicsPlaceholder: {
    id: "ConnectSource.MsDynamicsCrmUrlDialog.msdynamicsPlaceholder",
    defaultMessage: "Your MS Dynamics URL",
  },
  msdynamicsExplanation: {
    id: "ConnectSource.MsDynamicsCrmUrlDialog.msdynamicsExplanation",
    defaultMessage:
      "It is the URL used to access your Microsoft Dynamics CRM from your browser. It should be in the following format:",
  },
  msdynamicsURLFormatExplanation: {
    id: "ConnectSource.MsDynamicsCrmUrlDialog.msdynamicsURLFormatExplanation",
    defaultMessage: "https://********.crm**.dynamics.com",
  },
  notMsFormat: {
    id: "ConnectSource.MsDynamicsCrmUrlDialog.notMsFormat",
    defaultMessage: `MS Dynamics URL should contain ".dynamics.com"`,
  },
});
