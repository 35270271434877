import { createSvgIcon } from "@mui/material/utils";

import { SvgIcon } from "./types";

const displayName = "Search";

const Search: SvgIcon = createSvgIcon(
  // Using transform to enlarge from provided viewbox="0 0 16 16"
  // to default "0 0 24 24" from `createSvgIcon` -> `SvgIcon`.
  <g fill="none" transform="matrix(1.5,0,0,1.5,0,0)">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.9148 11.2077C12.7997 10.1624 13.3333 8.81016 13.3333 7.33331C13.3333 4.0196 10.647 1.33331 7.33325 1.33331C4.01954 1.33331 1.33325 4.0196 1.33325 7.33331C1.33325 10.647 4.01954 13.3333 7.33325 13.3333C8.8101 13.3333 10.1623 12.7997 11.2077 11.9148L12.9797 13.6869C13.175 13.8821 13.4915 13.8821 13.6868 13.6869C13.8821 13.4916 13.8821 13.175 13.6868 12.9798L11.9148 11.2077ZM12.3333 7.33331C12.3333 10.0947 10.0947 12.3333 7.33325 12.3333C4.57183 12.3333 2.33325 10.0947 2.33325 7.33331C2.33325 4.57189 4.57183 2.33331 7.33325 2.33331C10.0947 2.33331 12.3333 4.57189 12.3333 7.33331Z"
      fill="currentColor"
    />
  </g>,
  displayName
);

Search.displayName = displayName;
export default Search;
