import { RecordType } from "models/types";
import { AvailablePipelineAttributeColumns } from "redux/pipelineAttribute/defaults";

export const isPipelineAttributeOwner = (fieldName: string, row: any) =>
  row?.type === RecordType.PipelineOpportunity &&
  fieldName === AvailablePipelineAttributeColumns.owner;

export const pipelineAttributeOwnerValueFormatter = (value?: string) => {
  return value ?? "--";
};
