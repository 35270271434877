import { T } from "components/ui/Typography";
import { defineMessages, FormattedMessage } from "react-intl";

import { useDropdownStyles } from "./Dropdown";
import DropdownItem from "./DropdownItem";
import { IDropdown, IDropdownItem } from "./types";

const OpportunityDropdown = <T extends string = string>({
  options,
  onChange = () => {},
  value,
  CustomFooter,
}: IDropdown<T>) => {
  const openItems = options?.filter((o) => o.isOpen);
  const closedItems = options?.filter((o) => !o.isOpen);

  const { classes } = useDropdownStyles();

  const onItemSelect = (item: IDropdownItem) => {
    const valueArr = (value ?? []) as T[];
    if (!item.id) {
      return;
    }
    onChange(
      valueArr?.includes(String(item.id) as T)
        ? valueArr?.filter((v) => v !== item.id)
        : [...valueArr, String(item.id) as T]
    );
  };

  return (
    <>
      <p className={classes.opportunityLabel}>
        {<FormattedMessage {...i18n.open} />}
      </p>
      {openItems?.length ? (
        <>
          {openItems?.map((item) => (
            <DropdownItem
              key={item.id}
              id={item.id}
              isOpportunityItem
              name={item.name}
              date={item.date}
              amount={item.amount}
              isOpen
              isWon={false}
              onChange={() => onItemSelect(item)}
              stage={item.stage}
              isChecked={value?.includes(item.id) ?? false}
            />
          ))}
        </>
      ) : (
        <T className={classes.alpha}>
          <FormattedMessage {...i18n.noOpenOpportunity} />
        </T>
      )}
      <p className={classes.opportunityLabel}>
        {<FormattedMessage {...i18n.closed} />}
      </p>
      {closedItems?.length ? (
        <>
          {closedItems?.map((item) => (
            <DropdownItem
              key={item.id}
              id={item.id}
              isOpportunityItem
              name={item.name}
              date={item.date}
              amount={item.amount}
              isOpen={false}
              isWon={item.isWon}
              onChange={() => onItemSelect(item)}
              stage={item.stage}
              isChecked={value?.includes(item.id) ?? false}
            />
          ))}
        </>
      ) : (
        <T className={classes.alpha}>
          <FormattedMessage {...i18n.noClosedOpportunity} />
        </T>
      )}
      {CustomFooter && (
        <>
          <div className={classes.spacer} />
          {CustomFooter}
        </>
      )}
    </>
  );
};

export default OpportunityDropdown;

const i18n = defineMessages({
  open: {
    id: "OpportunityDropdown.open",
    defaultMessage: "Open opportunities",
  },
  noClosedOpportunity: {
    id: "OpportunityDropdown.noClosedOpportunity",
    defaultMessage: "No closed opportunities",
  },
  noOpenOpportunity: {
    id: "OpportunityDropdown.noOpenOpportunity",
    defaultMessage: "No open opportunities",
  },
  closed: {
    id: "OpportunityDropdown.closed",
    defaultMessage: "Closed opportunities",
  },
});
