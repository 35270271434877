import Undo from "@mui/icons-material/Undo";
import Grid from "@mui/material/Grid";
import Button from "components/ui/Button";
import { RecordType } from "models/types";
import { useState } from "react";
import { defineMessages } from "react-intl";
import { useDispatch } from "react-redux";
import { bulkUpdate } from "redux/api/thunks";
import { markNotificationAsRead } from "redux/notifications/actions";
import { NotificationItem } from "redux/notifications/typing";

const i18n = defineMessages({
  undo: {
    id: "Notification.action.undo",
    defaultMessage: "Undo",
  },
});

type Props = {
  notification: NotificationItem;
  partnerConnectionIds: number[];
  shouldArchive: boolean;
};

const NotificationActionArchivePipeline = ({
  notification,
  partnerConnectionIds,
  shouldArchive,
}: Props) => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);

  const undo = async () => {
    setLoading(true);
    const partnerConnectionsToArchive = partnerConnectionIds.map((id) => ({
      type: RecordType.PartnerConnection,
      id,
      attributes: { archivedAt: shouldArchive ? new Date() : null },
    }));
    await dispatch(bulkUpdate(partnerConnectionsToArchive));
    dispatch(markNotificationAsRead(notification.id));
    setLoading(false);
  };

  if (partnerConnectionIds.length === 0) {
    return null;
  }

  return (
    <Grid container spacing={1}>
      <Grid item>
        <Button
          label={i18n.undo}
          LeftIcon={Undo}
          variant="senary"
          onClick={undo}
          loading={loading}
        />
      </Grid>
    </Grid>
  );
};

export default NotificationActionArchivePipeline;
