import { createSvgIcon } from "@mui/material/utils";

import { SvgIcon } from "./types";

const displayName = "QuickFilter";

const QuickFilter: SvgIcon = createSvgIcon(
  // Using transform to enlarge from provided viewbox="0 0 16 16"
  // to default "0 0 24 24" from `createSvgIcon` -> `SvgIcon`.
  <g fill="none" transform="matrix(1.5,0,0,1.5,2,3)">
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M1.76415 1C1.61826 1 1.5 1.11827 1.5 1.26415C1.5 2.11896 1.92721 2.91722 2.63846 3.39138L5.4094 5.23867C5.9658 5.60961 6.3 6.23407 6.3 6.90278V11.5657L7.47735 10.7808C7.61645 10.6881 7.7 10.532 7.7 10.3648V6.90278C7.7 6.23407 8.0342 5.60961 8.5906 5.23867L11.3615 3.39138C12.0728 2.91722 12.5 2.11896 12.5 1.26415C12.5 1.11827 12.3817 1 12.2358 1H1.76415ZM13.5 1.26415C13.5 0.56598 12.934 0 12.2358 0H1.76415C1.06598 0 0.5 0.56598 0.5 1.26415C0.5 2.45332 1.09431 3.5638 2.08376 4.22343L4.8547 6.07072C5.1329 6.25619 5.3 6.56842 5.3 6.90278V12.0329C5.3 12.6319 5.96761 12.9892 6.46603 12.6569L8.03205 11.6129C8.44935 11.3347 8.7 10.8663 8.7 10.3648V6.90278C8.7 6.56842 8.8671 6.25619 9.1453 6.07072L11.9162 4.22343C12.9057 3.5638 13.5 2.45332 13.5 1.26415Z"
      fill="#5A617F"
    />
  </g>,
  displayName
);
QuickFilter.displayName = displayName;
export default QuickFilter;
