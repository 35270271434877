import { Box, Hidden } from "@mui/material";
import BaseDataGrid from "components/ui/data-grid/BaseDataGrid";
import useSegment from "hooks/useSegment";
import { makeStyles } from "makeStyles";
import { COMPANY_ECOSYSTEM_LABELS, COMPANY_TYPE_LABELS } from "models/Company";
import Record from "models/Record";
import { useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { setFilters, setSort } from "redux/directory/actions";
import {
  applyDirectoryColumnOrder,
  AvailableDirectoryColumns,
  defaultDirectoryColumnConfig,
  defaultDirectoryColumns,
  directoryColumnConfig,
} from "redux/directory/defaults";
import { selectDirectoryView } from "redux/directory/selectors";
import JSONAPIService from "services/JSONAPIService";
import { DirectoryEvent } from "tracking";

import {
  DataTableType,
  MatchFilterType,
  SortType,
  viewToColumns,
} from "../../DataTables/shared/types";
import { fields } from "../constants";
import { DirectoryMarketPresenceFilter } from "./DirectoryMarketPresenceFilter";
import { DirectoryMultiSelectFilter } from "./DirectoryMultiSelectFilter";
import {
  DirectoryToggleFilter,
  DirectoryToggleFilterProps,
} from "./DirectoryToggleFilter";
import EmptyRowsView from "./EmptyRowsView";

type Props = {
  rows?: Record[];
  rowsCount?: number | null;
  fetching?: boolean;
  loadMore: () => void;
  loading?: boolean;
  input: JSX.Element;
  reset: () => void;
};

export const Content = ({
  rows = [],
  rowsCount,
  loading,
  fetching,
  loadMore,
  input,
  reset,
}: Props) => {
  const { classes } = useStyles();
  const { track } = useSegment();
  const dispatch = useDispatch();
  const { view } = useSelector(selectDirectoryView);
  const filters = view.filters;
  const [facets, setFacets] = useState<DirectoryToggleFilterProps["facets"]>(
    {}
  );
  const intl = useIntl();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [activeDropdownFieldname, setActiveDropdownFieldname] = useState<
    string | null
  >(null);

  const columns = viewToColumns(
    defaultDirectoryColumns,
    fields,
    directoryColumnConfig,
    defaultDirectoryColumnConfig,
    applyDirectoryColumnOrder
  );

  const handleDropdownOpen = (el: HTMLElement | null, fieldname: string) => {
    setAnchorEl(el);
    setActiveDropdownFieldname(fieldname);
  };

  const handleSetValue = (field: string, type: MatchFilterType) => (
    value: string[] | number | null
  ) => {
    track(DirectoryEvent.filteredDirectory, { field, type, value });
    dispatch(
      setFilters([
        ...filters.filter((item) => item.fieldname !== field),
        { fieldname: field, value, type },
      ])
    );
  };

  const handleSetSort = (value: SortType[]) => {
    dispatch(setSort(value));
  };

  const handleCloseCustomMenu = () => {
    setAnchorEl(null);
    setActiveDropdownFieldname(null);
  };

  const options = (enumObject: object) =>
    Object.entries(enumObject).map((item) => ({
      id: item[0],
      name: item[0],
      label: item[1],
      title: intl.formatMessage(item[1].props),
    }));

  const customMenu = (fieldname: string) => {
    if (!fieldname) return undefined;
    switch (fieldname) {
      case AvailableDirectoryColumns.businessType:
      case AvailableDirectoryColumns.ecosystem:
        return (
          <DirectoryMultiSelectFilter
            value={
              filters.find((item) => item.fieldname === fieldname)?.value ?? []
            }
            onChange={handleSetValue(fieldname, MatchFilterType._IN_OPERATOR)}
            options={options(
              fieldname === AvailableDirectoryColumns.businessType
                ? COMPANY_TYPE_LABELS
                : COMPANY_ECOSYSTEM_LABELS
            )}
            onClose={handleCloseCustomMenu}
            anchorEl={activeDropdownFieldname === fieldname ? anchorEl : null}
          />
        );
      case AvailableDirectoryColumns.marketPresence:
        return (
          <DirectoryMarketPresenceFilter
            onChange={handleSetValue(
              "market_presence",
              MatchFilterType.CONTAINS_ANY
            )}
            onClose={handleCloseCustomMenu}
            anchorEl={activeDropdownFieldname === fieldname ? anchorEl : null}
          />
        );
      default:
        return undefined;
    }
  };

  useEffect(() => {
    const getFacets = async () => {
      try {
        const service = new JSONAPIService("directory_companies");
        const response = await service.rawGet("/metrics/");
        setFacets((response.data as any).data.attributes);
      } catch (error) {
        //
      }
    };
    getFacets();
  }, []);

  return (
    <div className={classes.root}>
      <Box className={classes.actionRow}>
        <DirectoryToggleFilter callback={reset} facets={facets} />
        <Box display="flex" gap="16px">
          <Hidden mdDown>{input}</Hidden>
        </Box>
      </Box>
      <BaseDataGrid
        noSide
        hasRowSelection={false}
        hideHeaderMenus
        headerHeight={36}
        rows={rows}
        rowsCount={rowsCount ?? 0}
        fields={fields ?? {}}
        loadMore={loadMore}
        fetching={fetching}
        columns={columns}
        emptyRowsView={<EmptyRowsView loading={loading} />}
        loading={loading}
        viewType={DataTableType.DIRECTORY}
        customMenu={customMenu}
        onCustomMenuOpen={handleDropdownOpen}
        sortOnHeaders
        setSort={handleSetSort}
      />
    </div>
  );
};

// CSS

const useStyles = makeStyles()((theme) => ({
  actionRow: {
    display: "flex",
    justifyContent: "space-between",
    margin: theme.spacing(2.5),
  },
  root: {
    position: "relative",
    height: "calc(100% - 84px)", // height of the action row is 84 px
    width: "100%",
  },
}));
