import PageView from "models/PageView";
import { RevealStore } from "redux/typing";

export const selectNearboundAccountState = (state: RevealStore) =>
  state.nearboundAccounts;

export const selectViews = (state: RevealStore) => {
  const pageViews = (state.api.entities["page_views"] ?? {}) as Record<
    string,
    PageView
  >;
  return state.nearboundAccounts.viewIds
    .map((id) => pageViews[id])
    .filter(Boolean);
};

export const selectView = (state: RevealStore) => {
  const pageViews = (state.api.entities["page_views"] ?? {}) as Record<
    string,
    PageView
  >;
  return !!state.nearboundAccounts.viewId
    ? pageViews[state.nearboundAccounts.viewId]
    : undefined;
};
