import { Box } from "@mui/material";
import { ChevronDown } from "components/icons";
import CompanyAvatar from "components/ui/avatars/CompanyAvatar";
import Button from "components/ui/Button";
import Dropdown from "components/ui/Dropdown/components/Dropdown";
import { IDropdownOption } from "components/ui/Dropdown/components/types";
import DropdownSubmitFooter from "components/ui/DropdownSubmitFooter";
import { T } from "components/ui/Typography";
import useUserProfile from "hooks/useUserProfile";
import _ from "lodash";
import { MouseEvent, useEffect, useMemo, useRef, useState } from "react";
import { defineMessages, FormattedMessage } from "react-intl";
import { PartnershipFilterData } from "redux/pipeline/typing";

import PartnerFilterLabel from "./PartnerFilterLabel";

type Props = {
  availablePartners: PartnershipFilterData[];
  filteredPartnerIds: string[] | null;
  disabled?: boolean;
  onChange: (ids: string[] | null) => void;
  partnerSelectorIsOpen?: boolean;
  setPartnerSelectorIsOpen: SetState<boolean>;
};

const PartnerFilterMenu = ({
  availablePartners,
  filteredPartnerIds,
  disabled = false,
  onChange,
  partnerSelectorIsOpen,
  setPartnerSelectorIsOpen,
}: Props) => {
  const filterMenuRef = useRef(null);
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const [ids, setIds] = useState<string[] | null>([]);
  const { profile } = useUserProfile();

  // Open Dropdown from MyPipeline
  useEffect(() => {
    if (partnerSelectorIsOpen) {
      handleOpenDropdown(filterMenuRef.current);
    }
  }, [partnerSelectorIsOpen]); // eslint-disable-line react-hooks/exhaustive-deps

  const sameSelection = _.isEqual(ids, filteredPartnerIds);

  const partnershipsForDropdown = useMemo(() => {
    const myPartnerships: IDropdownOption[] = [];
    const otherPartnerships: IDropdownOption[] = [];

    availablePartners.forEach((partner) => {
      const option: IDropdownOption = {
        id: partner.id.toString(),
        name: partner.name,
        label: (
          <Box display="flex" alignItems="center" gap="6px">
            <CompanyAvatar src={partner.logo} size="xxs" />
            {partner.name}
          </Box>
        ),
      };

      if (partner.owner?.id === profile.id) {
        myPartnerships.push(option);
      } else {
        otherPartnerships.push(option);
      }
    });

    if (!myPartnerships.length || !otherPartnerships.length) {
      return [...myPartnerships, ...otherPartnerships];
    }

    return [
      {
        id: "my-partnerships",
        label: (
          <T bold>
            <FormattedMessage
              {...i18n.partnershipsIOwn}
              values={{ count: myPartnerships.length }}
            />
          </T>
        ),
        name: "Partnerships I Own",
        subItems: myPartnerships,
      },
      {
        id: "other-partnerships",
        label: (
          <T bold>
            <FormattedMessage
              {...i18n.otherPartners}
              values={{ count: otherPartnerships.length }}
            />
          </T>
        ),
        name: "Other Partnerships",
        subItems: otherPartnerships,
      },
    ];
  }, [availablePartners, profile]);

  const handleOpenDropdown = (target: any) => {
    checkCheckboxes();
    setAnchorEl(target);
    setPartnerSelectorIsOpen(true);
  };

  const handleCloseDropdown = () => {
    setPartnerSelectorIsOpen(false);
    setAnchorEl(null);
  };

  const handleSubmit = () => {
    onChange(ids);
    handleCloseDropdown();
  };

  const checkCheckboxes = () => {
    setIds(filteredPartnerIds);
  };

  const handleClick = (event: MouseEvent<HTMLButtonElement>) => {
    if (!partnerSelectorIsOpen) {
      handleOpenDropdown(event.currentTarget);
    } else {
      handleCloseDropdown();
    }
  };

  return (
    <div ref={filterMenuRef}>
      <Button
        disabled={disabled}
        data-testid="partner-filter-btn"
        label={
          <PartnerFilterLabel
            availablePartners={availablePartners}
            filteredPartnerIds={
              filteredPartnerIds?.map((id) => Number(id)) || null
            }
          />
        }
        onClick={handleClick}
        RightIcon={ChevronDown}
        size="small"
        variant="quinary"
      />
      <Dropdown
        options={partnershipsForDropdown}
        anchorEl={anchorEl}
        onClose={handleCloseDropdown}
        open={Boolean(anchorEl)}
        withSearch
        withCheckBox
        value={ids}
        nullValueMeansAllSelected
        onChange={(ids) => setIds(ids)}
        isMultiselect
        allowSelectAll
        CustomFooter={
          <DropdownSubmitFooter
            onApply={handleSubmit}
            onCancel={handleCloseDropdown}
            disabledApplyBtn={sameSelection}
          />
        }
      />
    </div>
  );
};

export default PartnerFilterMenu;

const i18n = defineMessages({
  partnershipsIOwn: {
    defaultMessage: "Partnerships I Own ({count})",
    id: "Pipeline.PartnerFilterMenu.partnershipsIOwn",
  },
  otherPartners: {
    defaultMessage: "Other Partnerships ({count})",
    id: "Pipeline.PartnerFilterMenu.otherPartners",
  },
});
