import { createSvgIcon } from "@mui/material/utils";

import { SvgIcon } from "./types";

const displayName = "CircleError";

const CircleError: SvgIcon = createSvgIcon(
  // Using transform to enlarge from provided viewbox="0 0 16 16"
  // to default "0 0 24 24" from `createSvgIcon` -> `SvgIcon`.
  <g fill="none" transform="matrix(1.5,0,0,1.5,0,0)">
    <circle cx="8" cy="8" r="8" fill="currentColor" />
    <path
      d="M10.1 4.5L8 6.6L5.9 4.5L4.5 5.9L6.6 8L4.5 10.1L5.9 11.5L8 9.4L10.1 11.5L11.5 10.1L9.4 8L11.5 5.9L10.1 4.5Z"
      fill="#ffffff"
    />
  </g>,
  displayName
);

CircleError.displayName = displayName;
export default CircleError;
