import { StaticDatePicker } from "@mui/x-date-pickers";
import { ChevronDown } from "components/icons";
import DropdownOption from "components/ui/DropdownOption";
import DropdownPopover from "components/ui/DropdownPopover";
import { TextInput } from "components/ui/TextInput";
import dayjs from "dayjs";
import { makeStyles } from "makeStyles";
import { ChangeEvent } from "react";
import { MouseEvent, useEffect, useRef, useState } from "react";
import { defineMessages, FormattedMessage } from "react-intl";

import { SWQLRightValue } from "../SWQLTypes";

/**
 * Type Definitions & Guards
 */

type TodayValue = "today";
type RelativeFutureDateValue = string;
type RelativePastDateValue = string;
type RelativeDateValue = RelativeFutureDateValue | RelativePastDateValue;
type AbsoluteDateValue = string;
type DateValue = TodayValue | RelativeDateValue | AbsoluteDateValue;
type DatePreset =
  | "absolute"
  | "relativeFuture"
  | "relativePast"
  | "today"
  | null;

const relativeFutureDatePattern = /^(\d+)\sdays\slater$/;

function isRelativeFutureDateValue(
  value: DateValue
): value is RelativeFutureDateValue {
  return value.match(relativeFutureDatePattern) !== null;
}

const relativePastDatePattern = /^(\d+)\sdays\sago$/;

function isRelativePastDateValue(
  value: DateValue
): value is RelativePastDateValue {
  return value.match(relativePastDatePattern) !== null;
}

function isAbsoluteDateValue(value: DateValue): value is AbsoluteDateValue {
  return (
    value.match(/^`\d\d\d\d-(1[0-2]|0[1-9])-(3[0-1]|[1-2]\d|0[1-9])`$/) !== null
  );
}

type Props = {
  value?: string;
  onChange: (
    value: SWQLRightValue | undefined,
    extra: { type: "date" }
  ) => void;
};

/**
 * End of Type Definitions
 */

const getInitialPreset = (value?: DateValue): DatePreset | null => {
  if (value === undefined) {
    return null;
  }
  switch (true) {
    case isRelativeFutureDateValue(value):
      return "relativeFuture";
    case isRelativePastDateValue(value):
      return "relativePast";
    case isAbsoluteDateValue(value):
      return "absolute";
    case value === "today":
      return "today";
    default:
      return null;
  }
};

const getWidgetForPreset = (preset: DatePreset) => {
  switch (preset) {
    case "absolute":
      return CustomDateWidget;
    case "relativeFuture":
      return RelativeFutureDateWidget;
    case "relativePast":
      return RelativePastDateWidget;
    default:
      return TodayWidget;
  }
};

export const convertDateToIso = (date: Date) => {
  const year = date.getFullYear();
  const month = date.getMonth() + 1;
  const dt = date.getDate();
  return (
    year +
    "-" +
    (month < 10 ? "0" + month : month) +
    "-" +
    (dt < 10 ? "0" + dt : dt)
  );
};

const DateField = ({ value, onChange }: Props) => {
  const { classes } = useStyles();
  const [open, setOpen] = useState<boolean>(false);
  const [anchorEl, setAnchorEl] = useState<Element | null>(null);
  const [currentPreset, setCurrentPreset] = useState<DatePreset | null>(
    getInitialPreset(value)
  );

  const handleOpen = (evt: MouseEvent) => {
    setAnchorEl(evt.currentTarget.parentElement);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handlePresetChange = (preset: DatePreset) => {
    setCurrentPreset(preset);
    onChange(preset === "today" ? "today" : undefined, { type: "date" });
    if (preset !== "absolute") {
      handleClose();
    }
  };

  const handleCustomChange = (value: dayjs.Dayjs | null) => {
    const date = (value as any).$d;
    if (date instanceof Date) {
      setCurrentPreset("absolute");
      onChange("`" + convertDateToIso(date) + "`", { type: "date" });
      handleClose();
    }
  };

  const ValueComponent = getWidgetForPreset(currentPreset);

  return (
    <>
      <div className={classes.root}>
        <ValueComponent
          value={value}
          onChange={(value: SWQLRightValue) =>
            onChange(value, { type: "date" })
          }
        />
        <ChevronDown
          className={classes.arrow}
          onClick={handleOpen}
          data-testid="open-date-widget"
        />
      </div>
      <DropdownPopover
        anchorEl={anchorEl}
        onClose={handleClose}
        open={open}
        position="left"
        classes={{ paper: classes.dropDown }}
      >
        <div className={classes.optionList}>
          <DropdownOption
            onClick={() => handlePresetChange("today")}
            isActive={currentPreset === "today"}
          >
            <FormattedMessage {...i18n.Today} />
          </DropdownOption>
          <DropdownOption
            onClick={() => handlePresetChange("relativeFuture")}
            isActive={currentPreset === "relativeFuture"}
          >
            <FormattedMessage {...i18n.inXDays} />
          </DropdownOption>
          <DropdownOption
            onClick={() => handlePresetChange("relativePast")}
            isActive={currentPreset === "relativePast"}
          >
            <FormattedMessage {...i18n.xDaysAgo} />
          </DropdownOption>
          <DropdownOption
            onClick={() => handlePresetChange("absolute")}
            isActive={currentPreset === "absolute"}
          >
            <FormattedMessage {...i18n.customDate} />
          </DropdownOption>
        </div>
        {currentPreset === "absolute" && (
          <div className={classes.container}>
            <StaticDatePicker
              onChange={handleCustomChange}
              value={dayjs(
                value !== undefined && isAbsoluteDateValue(value)
                  ? new Date(value.replace(/`/g, ""))
                  : null
              )}
              slots={{ toolbar: undefined }}
              slotProps={{
                actionBar: {
                  actions: ["today"],
                },
              }}
            />
          </div>
        )}
      </DropdownPopover>
    </>
  );
};

export default DateField;

/**
 * Widgets to display proper input fields
 */

const RelativeDateInput = <T extends string>({
  value,
  onChange,
  parser,
  formatter,
}: {
  value?: T;
  onChange: (value: T) => void;
  parser: (value?: T) => number | "";
  formatter: (value: string) => T;
}) => {
  const inputRef = useRef<HTMLInputElement>();
  const { classes } = useStyles();

  useEffect(() => {
    if (inputRef.current && !value) {
      inputRef.current.focus();
    }
  }, [value]);

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    onChange(formatter(event.target.value));
  };

  return (
    <TextInput
      small
      numeric
      variant="secondary"
      inputRef={inputRef}
      value={parser(value)}
      onChange={handleChange}
      className={classes.daysInput}
      inputProps={{
        "data-testid": "relative-date-input",
      }}
    />
  );
};

const parseRelativeFutureValue = (value?: RelativeFutureDateValue) => {
  if (value !== undefined) {
    const parsed = relativeFutureDatePattern.exec(value);
    if (parsed !== null) {
      return +parsed[1];
    }
  }
  return "";
};

const formatRelativeFutureValue = (value: string) => `${value} days later`;

const RelativeFutureDateWidget = ({
  onChange,
  value,
}: {
  onChange: (value: RelativeFutureDateValue) => void;
  value?: RelativeFutureDateValue;
}) => {
  const { classes } = useStyles();
  return (
    <>
      <span className={classes.label}>
        <FormattedMessage {...i18n.in} />
      </span>
      <RelativeDateInput<RelativeFutureDateValue>
        value={value}
        onChange={onChange}
        formatter={formatRelativeFutureValue}
        parser={parseRelativeFutureValue}
      />
      <span className={classes.label}>
        <FormattedMessage {...i18n.days} />
      </span>
    </>
  );
};

const parseRelativePastValue = (value?: RelativePastDateValue) => {
  if (value !== undefined) {
    const parsed = relativePastDatePattern.exec(value);
    if (parsed !== null) {
      return +parsed[1];
    }
  }
  return "";
};

const formatRelativePastValue = (value: string) => `${value} days ago`;

const RelativePastDateWidget = ({
  value,
  onChange,
}: {
  value?: RelativePastDateValue;
  onChange: (value: RelativePastDateValue) => void;
}) => {
  const { classes } = useStyles();
  return (
    <>
      <RelativeDateInput<RelativePastDateValue>
        value={value}
        onChange={onChange}
        formatter={formatRelativePastValue}
        parser={parseRelativePastValue}
      />
      <span className={classes.label}>
        <FormattedMessage {...i18n.daysAgo} />
      </span>
    </>
  );
};

const CustomDateWidget = ({
  value,
  onChange,
}: {
  value?: AbsoluteDateValue;
  onChange: (value: string) => void;
}) => (
  <TextInput
    small
    type="date"
    value={value ? value.replace(/`/g, "") : undefined}
    onChange={(evt: ChangeEvent<HTMLInputElement>) =>
      onChange("`" + evt.target.value + "`")
    }
    inputProps={{
      autoComplete: "off",
      "data-testid": "date-input",
    }}
  />
);

const TodayWidget = () => {
  const { classes } = useStyles();
  return (
    <span className={classes.label}>
      <FormattedMessage {...i18n.today} />
    </span>
  );
};

/**
 * Styles & Other Utils
 */

const useStyles = makeStyles()((theme) => ({
  root: {
    height: 28,
    fontSize: 12,
    display: "flex",
    alignItems: "center",
    paddingLeft: 2,
    paddingRight: theme.spacing(1),
    backgroundColor: theme.palette.taupe500,
    borderRadius: 18,
  },
  arrow: {
    cursor: "pointer",
    fontSize: 15,
  },
  dropDown: {
    flexDirection: "row",
    alignItems: "inherit",
  },
  optionList: {
    display: "flex",
    flexDirection: "column",
  },
  label: {
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
  },
  container: {
    display: "flex",
  },
  daysInput: {
    width: 50,
    "& .MuiFilledInput-root": {
      backgroundColor: theme.palette.ivory,
      height: 24,
      "&, &:hover, &.Mui-focused": {
        backgroundColor: theme.palette.ivory,
      },
    },
  },
}));

/**
 * I18n
 */

const i18n = defineMessages({
  in: {
    id: "SWQL.SWQLValueFields.DateField.in",
    defaultMessage: "in",
  },
  days: {
    id: "SWQL.SWQLValueFields.DateField.days",
    defaultMessage: "days",
  },
  daysAgo: {
    id: "SWQL.SWQLValueFields.DateField.daysAgo",
    defaultMessage: "days ago",
  },
  today: {
    id: "SWQL.SWQLValueFields.DateField.today",
    defaultMessage: "today",
  },
  Today: {
    id: "SWQL.SWQLValueFields.DateField.Today",
    defaultMessage: "Today",
  },
  inXDays: {
    id: "SWQL.SWQLValueFields.DateField.inXDats",
    defaultMessage: "In X days",
  },
  xDaysAgo: {
    id: "SWQL.SWQLValueFields.DateField.xDaysAgo",
    defaultMessage: "X days ago",
  },
  customDate: {
    id: "SWQL.SWQLValueFields.DateField.customDate",
    defaultMessage: "Custom date",
  },
});
