import { Decoder, json, number, object, oneOf, string } from "decoders";

import { FilterType, PersistedColumnType, SortType } from "./base";
import { MatchFieldType, MatchFilterType, MatchSortOrder } from "./enums";

export const columnDecoder: Decoder<PersistedColumnType> = object({
  key: string,
  width: number,
});

const filterTypeDecoder: Decoder<MatchFilterType> = oneOf(
  Object.values(MatchFilterType)
);

export const filterDecoder: Decoder<FilterType> = object({
  fieldname: string,
  type: filterTypeDecoder,
  value: json,
});

const fieldTypeDecoder: Decoder<MatchFieldType> = oneOf(
  Object.values(MatchFieldType) as MatchFieldType[]
);

const sortOrderDecoder: Decoder<MatchSortOrder> = oneOf(
  Object.values(MatchSortOrder)
);

export const sortDecoder: Decoder<SortType> = object({
  fieldname: string,
  order: sortOrderDecoder,
  fieldtype: fieldTypeDecoder,
});
