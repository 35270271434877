import { Box } from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import { Close } from "components/icons";
import Button from "components/ui/Button";
import { T } from "components/ui/Typography";
import { makeStyles } from "makeStyles";
import { defineMessages, FormattedMessage } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { setFilters } from "redux/directory/actions";
import { selectHasFilterApplied } from "redux/directory/selectors";

type EmptyRowsViewProps = {
  loading?: boolean;
};

export const EmptyRowsView = ({ loading }: EmptyRowsViewProps) => {
  const { classes } = useStyles();
  const dispatch = useDispatch();
  const hasFilters = useSelector(selectHasFilterApplied);

  const message = hasFilters ? i18n.noRecordsForFilter : i18n.noRecords;
  const removeFilters = () => {
    dispatch(setFilters([]));
  };

  return (
    <div className={classes.root}>
      {loading ? (
        <CircularProgress />
      ) : (
        <>
          <Box className={classes.text}>
            <T h3 bold>
              <FormattedMessage {...message} />
            </T>
          </Box>
          {hasFilters && (
            <Box className={classes.button}>
              <Button
                label={i18n.clearFilters}
                onClick={removeFilters}
                LeftIcon={Close}
                classes={{ icon: classes.icon }}
                variant="tertiary"
              />
            </Box>
          )}
        </>
      )}
    </div>
  );
};

export default EmptyRowsView;

/// CSS

const useStyles = makeStyles()((theme) => ({
  button: {
    marginTop: theme.spacing(2.5),
  },
  icon: {
    height: 12,
    width: 12,
  },
  root: {
    display: "flex",
    flexDirection: "column",
    height: "100%",
    alignItems: "center",
    justifyContent: "center",
    padding: theme.spacing(4),
  },
  text: {
    marginBottom: theme.spacing(1),
  },
}));

// I18N

const i18n = defineMessages({
  clearFilters: {
    id: "Directory.EmptyRowsView.clearFilters",
    defaultMessage: "Clear all filters",
  },
  noRecords: {
    id: "Directory.EmptyRowsView.noRecords",
    defaultMessage: "There are no records in your directory",
  },
  noRecordsForFilter: {
    id: "Directory.EmptyRowsView.noRecordsForFilter",
    defaultMessage: "There are no records matching these filters",
  },
});
