import axios, { AxiosResponse } from "axios";
import usePushNotification from "hooks/usePushNotification";
import useSegment from "hooks/useSegment";
import useSelectorWithDeepEquality from "hooks/useSelectorWithDeepEquality";
import Notification from "models/Notification";
import { useCallback, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import { setSelectedPartner } from "redux/notificationWidget/actions";
import { selectUnreadNotificationForPartner } from "redux/notificationWidget/selectors";
import { markNotificationAsRead } from "redux/notificationWidget/thunks";
import { selectPartnershipsFilterData as selectPartnershipsFilterCollaborate } from "redux/pipeline/selectors";
import { setPartnerFilter as setPartnerFilterCollaborate } from "redux/pipeline/thunks";
import { selectPartnershipsFilterData as selectPartnershipsFilterAttribute } from "redux/pipelineAttribute/selectors";
import { setPartnerFilter as setPartnerFilterAttribute } from "redux/pipelineAttribute/thunks";
import { CancellablePromise } from "services/BaseHTTPService";
import JSONAPIService from "services/JSONAPIService";
import { JSONAPIListResponse } from "services/types";
import { PipelineEvent } from "tracking";

import PartnerFilterMenu from "./PartnerFilterMenu";

type Props = {
  partnerSelectorIsOpen?: boolean;
  setPartnerSelectorIsOpen: SetState<boolean>;
  selectedPartnershipId: number | null;
  loadingPartners: boolean;
};

const PartnerFilter = ({
  partnerSelectorIsOpen,
  setPartnerSelectorIsOpen,
  selectedPartnershipId,
  loadingPartners,
}: Props) => {
  const { pathname } = useLocation();
  const isCollaborateView = pathname.includes("/pipeline");
  const selectPartnershipsFilterData = isCollaborateView
    ? selectPartnershipsFilterCollaborate
    : selectPartnershipsFilterAttribute;
  const setPartnerFilter = isCollaborateView
    ? setPartnerFilterCollaborate
    : setPartnerFilterAttribute;
  const {
    filteredPartnerIds,
    data: availablePartnerships,
  } = useSelectorWithDeepEquality(selectPartnershipsFilterData);
  const oneSelectedPartner =
    filteredPartnerIds && filteredPartnerIds.length === 1
      ? filteredPartnerIds[0]
      : null;
  const { notification, partner } = useSelector(
    selectUnreadNotificationForPartner(
      oneSelectedPartner,
      Notification.TYPE_REFERRED_ACCOUNT
    )
  );
  const dispatch = useDispatch();
  const { track } = useSegment();
  const history = useHistory();
  const availablePartnershipIds = availablePartnerships.map(
    (partnership) => partnership.id
  );
  const [
    numberPendingReferredAccountsLoading,
    setNumberPendingReferredAccountsLoading,
  ] = useState(false);
  const [
    numberPendingReferredAccounts,
    setNumberPendingReferredAccounts,
  ] = useState<number | null>(null);
  const currentCountPromise = useRef<CancellablePromise<
    AxiosResponse<JSONAPIListResponse>
  > | null>(null);
  const pushNotification = usePushNotification();

  useEffect(() => {
    if (!loadingPartners && selectedPartnershipId) {
      if (availablePartnershipIds.includes(selectedPartnershipId)) {
        dispatch(setPartnerFilter([selectedPartnershipId]));
        history.replace(isCollaborateView ? "/pipeline" : "/attribute");
      }
    }
  }, [
    setPartnerFilter,
    selectedPartnershipId,
    dispatch,
    history,
    loadingPartners,
    availablePartnershipIds,
    isCollaborateView,
  ]);

  const countPendingReferredAccounts = useCallback(async () => {
    if (partner) {
      setNumberPendingReferredAccountsLoading(true);
      const service = new JSONAPIService("referred_accounts");
      try {
        currentCountPromise.current = service.index({
          filters: { company_id: partner.id },
          page: { number: 1, size: 1 },
        });
        const response = await currentCountPromise.current;
        setNumberPendingReferredAccounts(response.data.meta?.record_count || 0);
      } catch (error) {
        if (!axios.isCancel(error)) {
          pushNotification("default_error");
        }
      }
      setNumberPendingReferredAccountsLoading(false);
    }
  }, [partner, pushNotification]);

  useEffect(() => {
    countPendingReferredAccounts();
    return () => {
      if (currentCountPromise.current && currentCountPromise.current.cancel) {
        currentCountPromise.current.cancel();
      }
    };
  }, [countPendingReferredAccounts]);

  useEffect(() => {
    if (notification) {
      if (
        !numberPendingReferredAccountsLoading &&
        numberPendingReferredAccounts
      ) {
        dispatch(setSelectedPartner(partner));
        dispatch(markNotificationAsRead(notification));
      }
    }
  }, [
    notification,
    partner,
    dispatch,
    numberPendingReferredAccountsLoading,
    numberPendingReferredAccounts,
  ]);

  const handleChange = (value: string[] | null) => {
    dispatch(setPartnerFilter(value?.map((id) => +id) || null));
    if (value !== null) {
      track(PipelineEvent.filteredOnPartner, {
        number_partners_selected: value.length,
      });
    }
  };

  if (loadingPartners) {
    return null;
  }

  return (
    <>
      <PartnerFilterMenu
        availablePartners={availablePartnerships}
        filteredPartnerIds={
          filteredPartnerIds?.map((id: number) => String(id)) || null
        }
        onChange={handleChange}
        disabled={loadingPartners}
        partnerSelectorIsOpen={partnerSelectorIsOpen}
        setPartnerSelectorIsOpen={setPartnerSelectorIsOpen}
      />
    </>
  );
};

export default PartnerFilter;
