import { createSvgIcon } from "@mui/material/utils";

import { SvgIcon } from "./types";

const displayName = "CircleWarningOutlined";

const CircleWarningOutlined: SvgIcon = createSvgIcon(
  <g fill="none">
    <path
      d="M12 2.5625C6.51167 2.5625 2.0625 7.01167 2.0625 12.5C2.0625 17.9883 6.51167 22.4375 12 22.4375C17.4883 22.4375 21.9375 17.9883 21.9375 12.5C21.9375 7.01167 17.4883 2.5625 12 2.5625ZM3.9375 12.5C3.9375 8.0472 7.5472 4.4375 12 4.4375C16.4528 4.4375 20.0625 8.0472 20.0625 12.5C20.0625 16.9528 16.4528 20.5625 12 20.5625C7.5472 20.5625 3.9375 16.9528 3.9375 12.5ZM11.0625 8.75C11.0625 8.23223 11.4822 7.8125 12 7.8125C12.5178 7.8125 12.9375 8.23223 12.9375 8.75V12.875C12.9375 13.3928 12.5178 13.8125 12 13.8125C11.4822 13.8125 11.0625 13.3928 11.0625 12.875V8.75ZM12 17.9375C11.2751 17.9375 10.6875 17.3499 10.6875 16.625C10.6875 15.9001 11.2751 15.3125 12 15.3125C12.7249 15.3125 13.3125 15.9001 13.3125 16.625C13.3125 17.3499 12.7249 17.9375 12 17.9375Z"
      fill="currentColor"
    />
  </g>,
  displayName
);

CircleWarningOutlined.displayName = displayName;
export default CircleWarningOutlined;
