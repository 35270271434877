import { createSvgIcon } from "@mui/material/utils";

import { SvgIcon } from "./types";

const displayName = "ProductionDashboard";

const ProductionDashboard: SvgIcon = createSvgIcon(
  // Using transform to enlarge from provided viewBox="0 0 20 20"
  // to default "0 0 24 24" from `createSvgIcon` -> `SvgIcon`.
  <g fill="none" transform="matrix(1.2,0,0,1.2,0,0)">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10 1.875C5.51269 1.875 1.875 5.51269 1.875 10C1.875 14.4873 5.51269 18.125 10 18.125C14.4873 18.125 18.125 14.4873 18.125 10C18.125 5.51269 14.4873 1.875 10 1.875ZM3.125 10C3.125 6.20304 6.20304 3.125 10 3.125C13.797 3.125 16.875 6.20304 16.875 10C16.875 13.797 13.797 16.875 10 16.875C6.20304 16.875 3.125 13.797 3.125 10ZM9.375 13.2812C9.375 13.6264 9.65482 13.9062 10 13.9062C10.3452 13.9062 10.625 13.6264 10.625 13.2812V9.53125C10.625 9.18607 10.3452 8.90625 10 8.90625C9.65482 8.90625 9.375 9.18607 9.375 9.53125V13.2812ZM10 5.78125C9.48223 5.78125 9.0625 6.20098 9.0625 6.71875C9.0625 7.23652 9.48223 7.65625 10 7.65625C10.5178 7.65625 10.9375 7.23652 10.9375 6.71875C10.9375 6.20098 10.5178 5.78125 10 5.78125Z"
      fill="currentColor"
    />
  </g>,

  displayName
);

ProductionDashboard.displayName = displayName;
export default ProductionDashboard;
