import { Box, CircularProgress } from "@mui/material";
import { isFulfilled } from "@reduxjs/toolkit";
import { Info } from "components/icons";
import DialogManager from "components/ui/DialogManager";
import Toggle from "components/ui/Toggle";
import Tooltip from "components/ui/Tooltip";
import usePushNotification from "hooks/usePushNotification";
import useSegment from "hooks/useSegment";
import useUserProfile from "hooks/useUserProfile";
import { makeStyles } from "makeStyles";
import { ChangeEvent, useCallback, useEffect, useState } from "react";
import { defineMessages, FormattedMessage } from "react-intl";
import { useDispatch } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import { indexAll } from "redux/api/thunks";
import { fields, included } from "redux/init/constants";
import { loadUsers } from "redux/init/thunks";
import { JSONAPIListResponse } from "services/types";
import { UserEvent } from "tracking";

import { UpdatePartnershipOwnerDialog } from "../shared/components/UpdatePartnershipOwnerDialog";
import { useUpdateHasLicense } from "../shared/hooks/useUpdateHasLicense";
import { UnlockInviteTeammatesDialog } from "./UnlockInviteTeammatesDialog";

export const EnableCell = ({ dataItem }: $TSFixMe) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const location = useLocation();
  const { track } = useSegment();
  const { profile } = useUserProfile();
  const pushNotification = usePushNotification();
  const { isLoading, updateUserHasLicense } = useUpdateHasLicense();
  const { hasLicense, licenseRequestedAt } = dataItem;
  const { classes } = useStyles();

  const roles = dataItem.roleAssignments;
  const currentRole = roles?.length ? roles[0].role : null;
  const isPendingApproval = !dataItem.pendingInvitation && !currentRole;
  const [isChecked, setIsChecked] = useState(hasLicense && !isPendingApproval);
  const [
    isOpenUnlockInviteTeammatesDialog,
    setIsOpenUnlockInviteTeammatesDialog,
  ] = useState(false);
  const [loadingIsUsed, setLoadingIsUsed] = useState<boolean>(false);
  const [partnershipIdsToMap, setPartnershipIdsToMap] = useState<number[]>([]);

  const requestedLicense = licenseRequestedAt !== null;

  const openUserLimitationPopUp = useCallback(() => {
    track(UserEvent.openUserLimitationPopUp);
    setIsOpenUnlockInviteTeammatesDialog(true);
  }, [track]);

  const disableUser = useCallback(async () => {
    await updateUserHasLicense(dataItem.id, false, false, setIsChecked);
    dispatch(loadUsers());
  }, [dataItem.id, dispatch, updateUserHasLicense]);

  const activateUser = useCallback(async () => {
    await updateUserHasLicense(dataItem.id, true, true, setIsChecked);
    dispatch(loadUsers());
  }, [dataItem.id, dispatch, updateUserHasLicense]);

  const checkIfUserIsUsed = useCallback(async () => {
    //Checking if user is used as partership owner in any partnership
    setLoadingIsUsed(true);
    const result = await dispatch(
      indexAll({
        type: "partnerships",
        options: {
          filters: {
            partnership_owner_id: dataItem.id,
          },
          include: included,
          fields,
        },
      })
    );
    if (isFulfilled(result)) {
      const response = result.payload as JSONAPIListResponse;
      const partnershipIds = response.data.map(
        (partnership) => +partnership.id
      );

      if (partnershipIds.length > 0) {
        setPartnershipIdsToMap(partnershipIds);
      } else {
        disableUser();
      }
    } else {
      pushNotification("default_error");
    }
    setLoadingIsUsed(false);
  }, [dataItem.id, disableUser, dispatch, pushNotification]);

  const handleChange = useCallback(
    async (event: ChangeEvent<HTMLInputElement>, checked: boolean) => {
      if (profile.isUserLimitReached() && !hasLicense) {
        profile.isUserLimitReached()
          ? openUserLimitationPopUp()
          : history.push({ ...location, hash: "#invite-team" });
        return;
      }
      if (!checked) {
        checkIfUserIsUsed();
      } else if (currentRole) {
        activateUser();
      } else {
        history.push(`/settings/team/${dataItem.id}`, {
          shouldUpdateLicense: true,
        });
      }
    },
    [
      activateUser,
      checkIfUserIsUsed,
      currentRole,
      dataItem.id,
      hasLicense,
      history,
      location,
      openUserLimitationPopUp,
      profile,
    ]
  );

  const closeUpdatePartnershipOwnerDialog = () => {
    setPartnershipIdsToMap([]);
  };

  // Catch change from the userDialog for pending approval user
  // For onClick toggle changes, it's the handleChange that update the toggle to have instant visual change
  useEffect(() => {
    setIsChecked(hasLicense && !isPendingApproval);
  }, [hasLicense, isPendingApproval]);

  return (
    <div className={classes.root}>
      <Toggle
        checked={isChecked}
        handleChange={handleChange}
        disabled={
          dataItem.id === profile.id ||
          !["company.admin", "company.partnership_manager"].includes(
            profile.roleAssignments?.[0].role
          ) ||
          isLoading ||
          loadingIsUsed
        }
      />
      {isLoading || loadingIsUsed ? (
        <Box mt={0.25} ml={0.75}>
          <CircularProgress size={10} />
        </Box>
      ) : requestedLicense ? (
        <Tooltip
          title={<FormattedMessage {...i18n.requestedLicense} />}
          placement="top-start"
        >
          <Info className={classes.icon} />
        </Tooltip>
      ) : undefined}
      <DialogManager
        isOpen={isOpenUnlockInviteTeammatesDialog}
        handleClose={() => setIsOpenUnlockInviteTeammatesDialog(false)}
        fullScreenBreakpoint="sm"
        DialogContentComponent={
          <UnlockInviteTeammatesDialog
            handleClose={() => setIsOpenUnlockInviteTeammatesDialog(false)}
          />
        }
      />
      {partnershipIdsToMap.length > 0 && (
        <UpdatePartnershipOwnerDialog
          handleClose={closeUpdatePartnershipOwnerDialog}
          userName={dataItem.fullName}
          partnershipIds={partnershipIdsToMap}
          callback={disableUser}
        />
      )}
    </div>
  );
};

/// CSS

export const useStyles = makeStyles()((theme) => ({
  root: {
    display: "flex",
    alignItems: "center",
    columnGap: 8,
  },
  icon: {
    width: 16,
    height: 16,
    color: theme.palette.red500,
  },
}));

/// I18N

const i18n = defineMessages({
  requestedLicense: {
    id: "AccountSettings.Users.EnableCell.requestedLicense",
    defaultMessage: "This user requested a license",
  },
});
