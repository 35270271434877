import React from "react";
import Integration from "screens/Backoffice/screens/Integration";

import { RouteConfig } from "./types";
import users from "./users";

const Company = React.lazy(() => import("screens/Backoffice/screens/Company"));
const CompanyList = React.lazy(
  () => import("screens/Backoffice/screens/Companies")
);
const PartnershipList = React.lazy(
  () => import("screens/Backoffice/screens/Partnerships")
);
const User = React.lazy(() => import("screens/Backoffice/screens/User"));
const UserList = React.lazy(() => import("screens/Backoffice/screens/Users"));
const PipelineRunList = React.lazy(
  () => import("screens/Backoffice/screens/Runs")
);
const ProductionDashboard = React.lazy(
  () => import("screens/Backoffice/screens/ProductionDashboard")
);

export default [
  ...users.filter(({ path }) => path !== "/register"),
  {
    path: "/backoffice",
    routes: [
      {
        component: CompanyList,
        exact: true,
        isProtected: true,
        path: "/companies",
      },
      {
        component: Company,
        exact: true,
        isProtected: true,
        path: "/companies/:recordId/:tabName?",
      },
      {
        component: PartnershipList,
        exact: true,
        isProtected: true,
        path: "/partnerships",
        short: "/",
      },
      {
        component: UserList,
        exact: true,
        isProtected: true,
        path: "/users",
      },
      {
        component: User,
        exact: true,
        isProtected: true,
        path: "/users/:recordId",
      },
      {
        component: PipelineRunList,
        exact: true,
        isProtected: true,
        path: "/runs",
      },
      {
        component: ProductionDashboard,
        exact: true,
        isProtected: true,
        path: "/production-dashboard",
      },
      {
        component: Integration,
        exact: true,
        isProtected: true,
        path: "/integrations/:recordId",
      },
    ],
    allowIncompleteOnboarding: true,
  },
] as RouteConfig[];
