import { ClassNameMap } from "@mui/material/styles";
import { makeStyles } from "makeStyles";
import { ReactNode } from "react";
import { useMergedClasses } from "tss-react";

export type Props = {
  children?: ReactNode;
  classes?: ClassNameMap;
  isActive?: boolean;
  onClick: $TSFixMeFunction;
  isDisabled?: boolean;
};

const DropdownOption = ({
  children,
  classes: newClasses,
  isActive = false,
  onClick,
  isDisabled = false,
  ...props
}: Props) => {
  const { classes: baseClasses, cx } = useStyles();
  const classes = useMergedClasses(baseClasses, newClasses);

  return (
    <div
      className={cx(classes.option, {
        [classes.optionActive]: isActive,
        [classes.optionEnabled]: !isDisabled,
        [classes.optionDisabled]: isDisabled,
      })}
      onClick={isDisabled ? () => {} : onClick}
      {...props}
    >
      {children}
    </div>
  );
};

export default DropdownOption;

// CSS

const useStyles = makeStyles()((theme) => ({
  option: {
    borderRadius: 4,
    display: "flex",
    flexDirection: "column",
    boxSizing: "border-box",
    color: theme.palette.midnight,
    width: "100%",
    padding: "8px 4px",
  },
  optionActive: {
    marginTop: 0,
    marginBottom: 0,
    backgroundColor: theme.palette.lightPurple,
  },
  optionEnabled: {
    cursor: "pointer",
    "&:hover": {
      marginTop: 0,
      marginBottom: 0,
      backgroundColor: theme.palette.offWhite,
    },
  },
  optionDisabled: {
    opacity: 0.4,
  },
}));
