import { createSvgIcon } from "@mui/material/utils";

import { SvgIcon } from "./types";

const displayName = "MoveLayerLeft";

const MoveLayerLeft: SvgIcon = createSvgIcon(
  // Using transform to enlarge from provided viewbox="0 0 16 16"
  // to default "0 0 24 24" from `createSvgIcon` -> `SvgIcon`.
  <g fill="none" transform="matrix(1.5,0,0,1.5,0,0)">
    <path
      d="M8 4.5L4 8.5L8 12.5"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M4 8.5H13"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M2 3V14"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </g>,
  displayName
);

MoveLayerLeft.displayName = displayName;
export default MoveLayerLeft;
