import User from "models/User";
import { ComponentElement, ComponentType } from "react";
import { connect, ConnectedComponent } from "react-redux";
import { bindActionCreators } from "redux";
import { fetchProfile, updateProfile } from "redux/user/thunks";
import { AppDispatch } from "store";

type AnyElement = ComponentElement<$TSFixMe, $TSFixMe>;
type Fallback = AnyElement | AnyElement[] | String | $TSFixMeFunction | null;

export interface WithUserProfile {
  fetchProfile: typeof fetchProfile;
  loggedIn: Boolean;
  profile: User;
  loading: Boolean;
  updateProfile: typeof updateProfile;
  initialized: Boolean;
}

const mapUserToProps = ({
  user: {
    login: { loggedIn, pending },
    profile: { data, requested },
  },

  context: { initialized },
}: $TSFixMe) => ({
  loggedIn: loggedIn,
  profile: data,
  loading: requested || pending,
  initialized,
});

const mapDispatchToProps = (dispatch: AppDispatch) =>
  bindActionCreators(
    {
      fetchProfile,
      updateProfile,
    },
    dispatch
  );

function withUserProfile<T extends WithUserProfile = WithUserProfile>(
  WrappedComponent: ComponentType<T>,
  allowAnonymous = false,
  Loader: null | ComponentType<{}> = null
): ConnectedComponent<$TSFixMe, $TSFixMe> {
  const Component = ({
    initialized,
    loading,
    loggedIn,
    fallback = null,
    profile,
    ...props
  }: WithUserProfile & { fallback: Fallback }) => {
    if (!initialized) {
      return Loader ? <Loader /> : null;
    }
    if (loggedIn && (!profile || loading) && Loader) {
      return <Loader />;
    }
    if (!loggedIn && !allowAnonymous) {
      if (fallback instanceof Function) {
        return fallback();
      }
      return fallback;
    }
    const wrappedProps = {
      loggedIn,
      profile: loading ? false : profile ?? new User(),
      ...props,
    } as T;
    return <WrappedComponent {...wrappedProps} />;
  };

  return connect(mapUserToProps, mapDispatchToProps)(Component);
}

export default withUserProfile;
