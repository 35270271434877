import StarIcon from "@mui/icons-material/Star";
import { SWQLTarget } from "components/ui/SWQL/SWQLTypes";
import { makeStyles } from "makeStyles";
import Match from "models/Match";
import {
  defineMessages,
  FormattedHTMLMessage,
  FormattedMessage,
} from "react-intl";
import { useHistory, useLocation } from "react-router-dom";
import {
  crmFileProviderLabelAndLogos,
  crmProviders,
} from "screens/Frontoffice/screens/Sources/constants";

import { updateHistoryUpdatedSource } from "../helpers/utils";
import BaseMappingRulesWidget from "./BaseMappingRulesWidget";

const useStyles = makeStyles()((theme) => ({
  starIcon: {
    color: theme.palette.primary.main,
    width: 22,
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
}));

const i18n = defineMessages({
  summaryStart: {
    id: "crm.settings.StrategicAccountsWidget.summaryStart",
    defaultMessage: "Please help us identify your strategic accounts",
  },
  summaryEnd: {
    id: "crm.settings.StrategicAccountsWidget.summaryEnd",
    defaultMessage: "from your {provider}.",
  },
  strategicAccountsAre: {
    id: "crm.settings.StrategicAccountsWidget.strategicAccountsAre",
    defaultMessage: "<b>Strategic Accounts</b> are companies where",
  },
});

type Props = {
  integrationId: string | number;
  handleClose?: () => void;
  sourceType: string;
  hideSummary?: boolean;
  callback?: () => void;
  noFieldsCallback?: () => void;
};

const StrategicAccountsWidget = ({ sourceType, ...props }: Props) => {
  const history = useHistory();
  const location = useLocation();
  const provider = ({ ...crmProviders, ...crmFileProviderLabelAndLogos } as {
    [key: string]: { name: string };
  })?.[sourceType]?.name;
  const { classes } = useStyles();
  const ruleList = [
    {
      key: Match.IS_STARRED,
      label: <FormattedHTMLMessage {...i18n.strategicAccountsAre} />,
    },
  ];

  const handleSave = () => {
    updateHistoryUpdatedSource(history, location);
    props?.callback?.();
  };

  return (
    <BaseMappingRulesWidget
      ruleList={ruleList}
      attribute={Match.STARRED_ATTRIBUTE}
      swqlTarget={SWQLTarget.RawCompany}
      summary={
        <>
          <FormattedMessage {...i18n.summaryStart} />
          <StarIcon className={classes.starIcon} />
          <FormattedMessage {...i18n.summaryEnd} values={{ provider }} />
        </>
      }
      trackedLocation="data source strategic accounts"
      saveCallback={handleSave}
      {...props}
    />
  );
};

export default StrategicAccountsWidget;
