import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import generic from "i18n/generic";
import { makeStyles } from "makeStyles";
import { ChangeEvent, ReactNode, useState } from "react";
import { defineMessages } from "react-intl";

import Button from "./Button";
import { TextInput } from "./TextInput";

type Props = {
  children?: ReactNode;
  confirmLabel?: string | JSX.Element;
  confirmText?: string;
  handleClose: () => void;
  handleConfirm: () => void;
  isOpen: boolean;
  cancelLabel?: string | JSX.Element;
};

export const ConfirmWithTextDialog = ({
  children,
  confirmLabel,
  confirmText,
  handleClose,
  handleConfirm: handleConfirmProp,
  isOpen,
  cancelLabel,
}: Props) => {
  const { classes } = useStyles();
  const [text, setText] = useState<string | null>(null);

  const handleConfirmationTextChange = (
    event: ChangeEvent<HTMLInputElement>
  ) => {
    setText(event.target.value.toUpperCase());
  };

  const handleCloseModal = () => {
    setText(null);
    handleClose();
  };

  const handleConfirm = () => {
    setText(null);
    handleConfirmProp();
  };

  return (
    <Dialog
      open={isOpen}
      onClose={handleCloseModal}
      classes={{ paper: classes.dialog }}
    >
      <DialogContent>
        {children}
        {!!confirmText && (
          <div>
            <TextInput
              onChange={handleConfirmationTextChange}
              fullWidth
              value={text ?? ""}
            />
          </div>
        )}
      </DialogContent>
      <DialogActions className={classes.dialogActions}>
        <Button
          label={cancelLabel ?? i18n.cancel}
          variant="secondary"
          onClick={handleCloseModal}
        />
        <Button
          label={confirmLabel ?? generic.confirm}
          disabled={!!confirmText && text !== confirmText}
          onClick={handleConfirm}
        />
      </DialogActions>
    </Dialog>
  );
};

// CSS

const useStyles = makeStyles()(() => ({
  dialog: {
    borderRadius: "12px",
    maxWidth: "640px",
    "& .MuiDialogContent-root": {
      padding: "30px 45px",
    },
    "& .MuiDialogActions-root": {
      paddingBottom: "36px",
    },
  },
  dialogActions: {
    justifyContent: "center",
  },
}));

// I18N

const i18n = defineMessages({
  cancel: {
    id: "components.ui.ConfirmWithTextDialog.cancel",
    defaultMessage: "Cancel",
  },
});
