import { DialogActions, Grid } from "@mui/material";
import { isFulfilled } from "@reduxjs/toolkit";
import { Trash } from "components/icons";
import Button from "components/ui/Button";
import Popup from "components/ui/Popup";
import { T } from "components/ui/Typography";
import usePushNotification from "hooks/usePushNotification";
import { useSmartViews } from "hooks/useSmartViews";
import generic from "i18n/generic";
import { makeStyles } from "makeStyles";
import { MouseEventHandler } from "react";
import { defineMessages, FormattedMessage } from "react-intl";
import { useDispatch } from "react-redux";

import { ExtendedAccountMappingResource } from "./constants";
import { useSmartViewTableConfig } from "./helpers";

type Props = {
  isOpen: boolean;
  onClose: () => void;
  accountType: ExtendedAccountMappingResource;
  selectedFilterId: number;
};

const SmartViewDeleteDialog = ({
  accountType,
  isOpen,
  onClose,
  selectedFilterId,
}: Props) => {
  const { classes } = useStyles();
  const dispatch = useDispatch();
  const { deleteView, records } = useSmartViews(accountType);
  const savedFilter = records.find((filter) => filter.id === selectedFilterId);
  const pushNotification = usePushNotification();
  const { orderArray, updateViewThunk } = useSmartViewTableConfig({
    accountType,
  });

  const handleDelete = async () => {
    if (!savedFilter) {
      return;
    }
    onClose();
    const result = await deleteView(savedFilter.id);
    if (isFulfilled(result)) {
      pushNotification(i18n.deletedFilter);
      await dispatch(
        updateViewThunk({
          filterOrderList: orderArray.filter(
            (item: number) => item !== savedFilter.id
          ),
        })
      );
    }
  };

  const handleClose: MouseEventHandler<HTMLDivElement> = (event) => {
    event.preventDefault();
    event.stopPropagation();
    onClose();
  };

  return (
    <Popup
      isOpen={isOpen}
      handleClose={handleClose}
      hideActions
      maxWidth="sm"
      variant="secondary"
    >
      <Grid container>
        <Grid item xs={12} className={classes.contentContainer}>
          <img
            className={classes.bigIcon}
            src="/images/CircleTrashBig.png"
            alt=""
          />
        </Grid>
        <Grid item xs={12} className={classes.contentContainer}>
          <T h3 bold>
            <FormattedMessage
              {...i18n.title}
              values={{ name: savedFilter?.name }}
            />
          </T>
        </Grid>
      </Grid>
      <DialogActions>
        <Grid container className={classes.actionContainer}>
          <Button
            label={generic.cancel}
            onClick={handleClose}
            variant="tertiary"
          />
          <Button
            color="error"
            type="submit"
            LeftIcon={Trash}
            label={i18n.delete}
            onClick={handleDelete}
            variant="tertiary"
          />
        </Grid>
      </DialogActions>
    </Popup>
  );
};

export default SmartViewDeleteDialog;

/// CSS

const useStyles = makeStyles()((theme) => ({
  actionContainer: {
    display: "flex",
    justifyContent: "center",
    columnGap: theme.spacing(0.5),
  },
  bigIcon: {
    height: 48,
    width: 48,
  },
  contentContainer: {
    textAlign: "center",
    marginBottom: theme.spacing(1.5),
  },
  subtitle: {
    color: theme.palette.alpha750,
    marginTop: theme.spacing(1),
  },
}));

/// I18N

const i18n = defineMessages({
  placeholder: {
    id: "SmartViewDeleteDialog.placeholder",
    defaultMessage: "Dynamic view name",
  },
  title: {
    id: "SmartViewDeleteDialog.title",
    defaultMessage: "Are you sure you want to delete “{name}” view?",
  },
  delete: {
    id: "SmartViewDeleteDialog.delete",
    defaultMessage: "Remove",
  },
  deletedFilter: {
    id: "SmartViewDeleteDialog.deletedFilter",
    defaultMessage: "Deleted view",
  },
});
