import { OktaAuthOptions } from "@okta/okta-auth-js";

import { getValidatedEnvironment } from "./schema";

const env = getValidatedEnvironment();

const issuer = env.okta?.issuer;
const customDomain = env.okta?.customDomain;
const clientId = env.okta?.clientId;

const protocol = window.location.protocol;
const host = window.location.host;

const config: OktaAuthOptions = {
  issuer: `https://${customDomain}/oauth2/${issuer}`,
  clientId: clientId,
  redirectUri: `${protocol}//${host}/sso/okta`,
  scopes: ["openid", "profile", "email"],
  pkce: true,
  postLogoutRedirectUri: `${protocol}//${host}/login`,
};

export default config;
