import history from "_history";
import Close from "@mui/icons-material/Close";
import CircularProgress from "@mui/material/CircularProgress";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";
import { Theme } from "@mui/material/styles";
import withRecord, { WithRecord } from "components/hoc/withRecord";
import withUserProfile, {
  WithUserProfile,
} from "components/hoc/withUserProfile";
import NotFoundDialogManager from "components/ui/NotFoundDialogManager";
import UnauthorizedInfoDialog from "components/ui/UnauthorizedInfoDialog";
import { FLAGS } from "config/featureFlags";
import useWidth from "hooks/useWidth";
import { makeStyles } from "makeStyles";
import { PayingFeature } from "models/CompanyPayingFeatureSubscription";
import User from "models/User";
import { ComponentType, CSSProperties, useRef } from "react";
import {
  defineMessages,
  FormattedMessage,
  MessageDescriptor,
} from "react-intl";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { selectActivePayingFeatures } from "redux/api/selectors";
import { fields, included } from "redux/init/constants";

import InviteList from "./InviteList";
import PartnershipDialogTitle from "./PartnershipDialogTitle";

export interface PartnershipDialogConfig {
  Component: ComponentType<any>;
  dialogProps: object;
  paper?: (theme: Theme) => CSSProperties;
  title: MessageDescriptor;
  Subtitle?: ComponentType<any>;
  Switcher?: ComponentType<any>;
  checkPermissions?: (user: User, payingFeatures: PayingFeature[]) => boolean;
}

export type PartnershipDetailDialogProps = WithRecord &
  WithUserProfile & {
    configs: {
      [fragment: string]: $TSFixMeFunction;
    };
  };

const PartnershipDetailDialog = ({
  profile,
  record,
  configs = defaultConfigs,
}: PartnershipDetailDialogProps) => {
  const location = useLocation();
  const dialogRef = useRef(null);
  const currentHash = location.hash;

  const width = useWidth();
  const smallWidth = width === "sm" || width === "xs" || false;

  const { classes } = useStyles();
  const { classes: dialogClasses } = useDialogStyles({ hash: currentHash });
  const payingFeatures = useSelector(selectActivePayingFeatures);
  const config = configs[currentHash];
  const partner = record.getPartner(profile);
  const backToDashboard = () => history.push("/partnerships");

  if (
    !config ||
    (config.checkPermissions &&
      !config.checkPermissions(profile, payingFeatures))
  ) {
    return <UnauthorizedInfoDialog callBackUrl="/partnerships" />;
  }
  config.dialogProps.fullScreen = smallWidth;

  return (
    <Dialog
      ref={dialogRef}
      open
      {...config.dialogProps}
      classes={dialogClasses}
      onClose={backToDashboard}
      data-testid="partnership-details"
    >
      {partner.name && (
        <DialogTitle className={classes.title}>
          <PartnershipDialogTitle
            section={partner.name}
            label={<FormattedMessage {...config.title} />}
            avatar={partner.avatarUrl}
            variant="h2"
            gutter={false}
          />
          <div className={classes.actions}>
            {!smallWidth && config.Switcher && (
              <config.Switcher
                partnership={record}
                className={classes.switcher}
                profile={profile}
                partner={partner}
              />
            )}
          </div>
          <IconButton
            className={classes.iconBtn}
            onClick={backToDashboard}
            size="large"
          >
            <Close className={classes.closeIcon} />
          </IconButton>
        </DialogTitle>
      )}
      {config && config.Subtitle && <config.Subtitle record={record} />}
      <DialogContent className={classes.content}>
        {partner.name ? (
          <config.Component
            recordId={record.id}
            record={record}
            partner={partner}
            profile={profile}
            dialogRef={dialogRef}
          />
        ) : (
          <div className={classes.loader}>
            <CircularProgress />
          </div>
        )}
      </DialogContent>
    </Dialog>
  );
};

export default withRecord("partnerships", {
  loadOnMount: true,
  ErrorHandler: NotFoundDialogManager,
  include: included,
  fields,
})(withUserProfile(PartnershipDetailDialog));

// I18N

const i18n = defineMessages({
  invitationsTitle: {
    id: "crm.PartnershipDetailDialog.invitationsTitle",
    defaultMessage: "Invitations",
  },
});

// Config

export const defaultConfigs: { [hash: string]: PartnershipDialogConfig } = {
  "#invitations": {
    Component: InviteList,
    dialogProps: {
      maxWidth: "sm",
      fullWidth: true,
    },
    paper: (theme: $TSFixMe) => ({
      background: FLAGS.redesign ? theme.palette.ivory : theme.palette.white,
      [theme.breakpoints.down("md")]: {
        height: "100%",
      },
    }),
    title: i18n.invitationsTitle,
    checkPermissions: (profile: User, payingFeatures: PayingFeature[]) =>
      profile.canInvitePartner(payingFeatures),
  },
};

// CSS

const useDialogStyles = makeStyles<{ hash: string }>()((theme, { hash }) => ({
  // @ts-expect-error ts-migrate(2339) FIXME: Property 'hash' does not exist on type '{}'.
  paper: { ...defaultConfigs[hash]?.paper(theme) },
}));

const useStyles = makeStyles()((theme) => ({
  content: {
    padding: 0,
    overflowX: "hidden",
    [theme.breakpoints.down("md")]: {
      height: "100%",
      overflowY: "auto",
    },
  },
  loader: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
    height: "100%",
    position: "absolute",
  },
  title: {
    display: "flex",
    justifyContent: "space-between",
    padding: theme.spacing(3),
  },
  iconBtn: {
    marginRight: theme.spacing(2),
    flexDirection: "column",
    width: 40,
    height: 40,
    position: "absolute",
    top: 24,
    right: 0,
    "&:focus": {
      outline: 0,
    },
  },
  closeIcon: {
    color: theme.palette.midnight,
    fontSize: 24,
  },
  actions: {
    display: "flex",
    alignItems: "center",
    minWidth: 250,
    justifyContent: "flex-end",
    marginRight: 40,
    [theme.breakpoints.down("md")]: {
      minWidth: 50,
    },
  },
  switcher: {
    display: "flex",
    alignItems: "center",
    fontSize: 14,
    color: theme.palette.link,
    "& > :not(:last-child)": {
      marginRight: theme.spacing(1),
    },
    "& > *": {
      whiteSpace: "nowrap",
    },
  },
}));
