import {
  ColumnConfigType,
  orderColumns,
} from "screens/Frontoffice/screens/DataTables/shared/types";

export enum AvailableDirectoryColumns {
  name = "name",
  businessType = "businessType",
  ecosystem = "ecosystem",
  marketPresence = "marketPresenceDisplayValue",
  accountOverlap = "accountOverlap",
  contactOverlap = "contactOverlap",
  partner = "partner",
}

const defaultDirectoryColumnKeys = [
  AvailableDirectoryColumns.name,
  AvailableDirectoryColumns.businessType,
  AvailableDirectoryColumns.ecosystem,
  AvailableDirectoryColumns.marketPresence,
  AvailableDirectoryColumns.accountOverlap,
  AvailableDirectoryColumns.contactOverlap,
  AvailableDirectoryColumns.partner,
];

export const directoryColumnConfig: {
  [key: string]: ColumnConfigType;
} = {
  [AvailableDirectoryColumns.name]: {
    key: AvailableDirectoryColumns.name,
    width: 220,
    frozen: true,
    removable: false,
  },
  [AvailableDirectoryColumns.businessType]: {
    key: AvailableDirectoryColumns.businessType,
    width: 180,
    frozen: false,
    removable: true,
  },
  [AvailableDirectoryColumns.ecosystem]: {
    key: AvailableDirectoryColumns.ecosystem,
    width: 200,
    frozen: false,
    removable: true,
  },
  [AvailableDirectoryColumns.marketPresence]: {
    key: AvailableDirectoryColumns.marketPresence,
    width: 300,
    frozen: false,
    removable: true,
  },
  [AvailableDirectoryColumns.accountOverlap]: {
    key: AvailableDirectoryColumns.accountOverlap,
    width: 160,
    frozen: false,
    removable: true,
  },
  [AvailableDirectoryColumns.contactOverlap]: {
    key: AvailableDirectoryColumns.contactOverlap,
    width: 150,
    frozen: false,
    removable: true,
  },
  [AvailableDirectoryColumns.partner]: {
    key: AvailableDirectoryColumns.partner,
    width: 150,
    frozen: false,
    removable: true,
  },
};

export const defaultDirectoryColumnConfig = (
  key: string,
  field: { label: string }
) => ({
  key,
  width: 175,
  frozen: false,
});

export const applyDirectoryColumnOrder = (columns: ColumnConfigType[]) =>
  orderColumns(columns);

export const defaultDirectoryColumns = applyDirectoryColumnOrder(
  defaultDirectoryColumnKeys.map((key) => directoryColumnConfig[key])
);
