import { SettingsOutlined } from "@mui/icons-material";
import { defineMessages, FormattedMessage } from "react-intl";

import Button from "../Button";
import Tooltip from "../Tooltip";

type Props = {
  disabled?: boolean;
  handleClick: () => void;
};

const TileSettings = ({ disabled, handleClick }: Props) => {
  return (
    <Tooltip title={<FormattedMessage {...i18n.tooltip} />}>
      <div>
        <Button
          data-testid="settings-icon"
          disabled={disabled}
          LeftIcon={SettingsOutlined}
          onClick={handleClick}
          size="small"
          variant="tertiary"
        />
      </div>
    </Tooltip>
  );
};

export default TileSettings;

const i18n = defineMessages({
  tooltip: {
    id: "TileSettings.tooltip",
    defaultMessage: "Settings",
  },
});
