import { Box } from "@mui/material";
import { Download, Launch } from "components/icons";
import { T } from "components/ui/Typography";
import { ConfigContext } from "config/ConfigProvider";
import { useContext } from "react";
import { defineMessages, FormattedMessage } from "react-intl";
import {
  BaseSidePanel,
  ChatLink,
  sharedStyles,
} from "screens/Frontoffice/shared/components/DialogWithSidePanel";

export const InvitationReviewModalSidePanel = () => {
  const { cx, classes } = sharedStyles();
  // const intl = useIntl();
  const config = useContext(ConfigContext);

  return (
    <BaseSidePanel>
      <Box>
        <T h3 className={cx(classes.title, classes.marginBottom)}>
          <FormattedMessage {...i18n.howToTitle} />
        </T>
        {/* <VideoContainer
          title={intl.formatMessage(i18n.videoCsv)}
          src="https://www.youtube.com/embed/La5cptLCs24"
        /> */}
        <a
          className={classes.link}
          target="_blank"
          href={
            "https://reveal.co/legals/privacy-policy?_gl=1*s35ar2*_ga*MzIwNTc5ODkuMTY2NjI2MDY2NA..*_ga_8BTT06DWCD*MTY3Mjk5NDIxNC42NC4xLjE2NzI5OTQyNjguMC4wLjA"
          }
          rel="noopener noreferrer"
        >
          <div className={classes.row}>
            <Launch className={classes.icon} />
            <T className={classes.linkText}>
              <FormattedMessage {...i18n.privacyPolicy} />
            </T>
          </div>
        </a>
        <ChatLink isWhite hasIconLeft />
        <a
          className={classes.link}
          download
          target="_blank"
          href={config.templateNdaPath}
          rel="noopener noreferrer"
        >
          <div className={classes.row}>
            <Download className={classes.icon} />
            <T className={classes.linkText}>
              <FormattedMessage {...i18n.downloadTemplate} />
            </T>
          </div>
        </a>
      </Box>
    </BaseSidePanel>
  );
};

const i18n = defineMessages({
  howToTitle: {
    id: "InvitationReviewModalSidePanel.howToTitle",
    defaultMessage: "Connecting with a partner is safe and simple",
  },
  privacyPolicy: {
    id: "InvitationReviewModalSidePanel.privacyPolicy",
    defaultMessage: "Reveal Privacy Policy",
  },
  downloadTemplate: {
    id: "InvitationReviewModalSidePanel.downloadTemplate",
    defaultMessage: "NDA Template",
  },
  videoCsv: {
    id: "InvitationReviewModalSidePanel.videoCsv",
    defaultMessage: "Video tutorial for CSV upload",
  },
});
