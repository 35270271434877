import { createSvgIcon } from "@mui/material/utils";

import { SvgIcon } from "./types";

const displayName = "ChevronDown";

const ChevronDown: SvgIcon = createSvgIcon(
  // Using transform to enlarge from provided viewbox="0 0 16 16"
  // to default "0 0 24 24" from `createSvgIcon` -> `SvgIcon`.
  <g fill="none" transform="matrix(1.5,0,0,1.5,0,0)">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4.1212 6.1212C4.28279 5.9596 4.54479 5.9596 4.70639 6.1212L7.90247 9.31728C7.95633 9.37114 8.04367 9.37114 8.09753 9.31728L11.2936 6.1212C11.4552 5.9596 11.7172 5.9596 11.8788 6.1212C12.0404 6.28279 12.0404 6.54479 11.8788 6.70639L8.68272 9.90247C8.30567 10.2795 7.69433 10.2795 7.31728 9.90247L4.1212 6.70639C3.9596 6.54479 3.9596 6.28279 4.1212 6.1212Z"
      fill="currentColor"
    />
  </g>,
  displayName
);

ChevronDown.displayName = displayName;
export default ChevronDown;
