import Button from "@mui/material/Button";
import { ConfigContext } from "config/ConfigProvider";
import { makeStyles } from "makeStyles";
import { useContext } from "react";
import CookieConsent from "react-cookie-consent";
import {
  defineMessages,
  FormattedHTMLMessage,
  FormattedMessage,
} from "react-intl";

const i18n = defineMessages({
  text: {
    id: "CookieConsentBar.text",
    defaultMessage:
      "By continuing to browse the site you consent Reveal and its partners to use cookies to provide you with a great user experience.",
  },
  learnMore: {
    id: "CookieConsentBar.learnMore",
    defaultMessage: "Learn more.",
  },
  accept: {
    id: "CookieConsentBar.accept",
    defaultMessage: "Ok!",
  },
});

const useStyles = makeStyles()((theme) => ({
  container: {
    alignItems: "baseline",
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "center",
    left: 0,
    position: "fixed",
    width: "100%",
    zIndex: 99999,
    bottom: 0,
    color: theme.palette.midnight,
    // @ts-expect-error ts-migrate(2339) FIXME: Property 'lightBlue' does not exist on type 'Color... Remove this comment to see the full error message
    backgroundColor: theme.palette.grey.lightBlue,
    borderTop: `1px solid ${theme.palette.other.grid.darkLine}`,
    opacity: "90%",
  },
  content: {
    padding: theme.spacing(1),
    fontSize: 13,
  },
  link: {
    marginLeft: theme.spacing(1),
    color: theme.palette.link,
  },
  acceptButton: {
    fontSize: 13,
    padding: theme.spacing(0.5),
    margin: 12,
  },
}));

const CookieConsentBar = () => {
  const { classes } = useStyles();
  const config = useContext(ConfigContext);

  // @ts-expect-error ts-migrate(2339) FIXME: Property 'ignoreCookieConsent' does not exist on t... Remove this comment to see the full error message
  if (config.ignoreCookieConsent) {
    return null;
  }
  return (
    <CookieConsent
      buttonClasses={classes.acceptButton}
      buttonText={<FormattedMessage {...i18n.accept} />}
      containerClasses={classes.container}
      contentClasses={classes.content}
      disableStyles
      ButtonComponent={(props: $TSFixMe) => (
        <Button variant="contained" color="primary" {...props} />
      )}
    >
      <FormattedHTMLMessage {...i18n.text} />
      <a
        // @ts-expect-error ts-migrate(2339) FIXME: Property 'cookiesPolicyLink' does not exist on typ... Remove this comment to see the full error message
        href={config.cookiesPolicyLink}
        target="_blank"
        rel="noopener noreferrer"
        className={classes.link}
      >
        <FormattedMessage {...i18n.learnMore} />
      </a>
    </CookieConsent>
  );
};

export default CookieConsentBar;
