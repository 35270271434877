import { Divider, Grid } from "@mui/material";
import { FormattedMessage, MessageDescriptor } from "react-intl";

import { T } from "./Typography";

type Props = {
  className?: string;
  align?: "left" | "center";
  value: MessageDescriptor;
};

const DividerWithLabel = ({ className, align = "left", value }: Props) => {
  const label = (
    <Grid item marginLeft={align === "center" ? 1 : 0} marginRight={1}>
      <T bodySmall>
        <FormattedMessage {...value} />
      </T>
    </Grid>
  );

  const divider = (
    <Grid item flexGrow={1}>
      <Divider />
    </Grid>
  );

  return (
    <Grid item xs={12}>
      <Grid className={className} container alignItems="center">
        {align === "center" ? divider : null}
        {label}
        {divider}
      </Grid>
    </Grid>
  );
};
export default DividerWithLabel;
