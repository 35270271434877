import { RouteConfig } from "config/routes/types";
import { Redirect, Route, Switch } from "react-router";

import NotFound from "../ui/NotFound";
import ProtectedRoute from "./ProtectedRoute";

type Props = {
  routes: RouteConfig[];
};

const RoutedApp = ({ routes = [] }: Props) => {
  const renderRoutes = (
    levelPath: string,
    { path, routes = undefined, isProtected, ...props }: RouteConfig
  ) => {
    const Component = isProtected ? ProtectedRoute : Route;
    return (
      <Component key={levelPath + path} path={levelPath + path} {...props}>
        {routes && (
          <Switch>
            {routes.map((route: RouteConfig) =>
              renderRoutes(levelPath + path, route)
            )}
          </Switch>
        )}
      </Component>
    );
  };

  const renderShortener = (
    levelPath: string,
    { path, routes = [], short }: RouteConfig
  ) => {
    const redirects = [];
    if (short) {
      redirects.push(
        <Redirect exact push to={levelPath + path} from={short} />
      );
    }
    routes.forEach((route: RouteConfig) => {
      renderShortener(levelPath + path, route).forEach((subRedirect) =>
        redirects.push(subRedirect)
      );
    });
    return redirects;
  };

  return (
    <Switch>
      {routes.map((route: $TSFixMe) => renderRoutes("", route))}
      {routes.map((route: $TSFixMe) => renderShortener("", route))}
      <Route component={NotFound} />
    </Switch>
  );
};

export default RoutedApp;
