import { ProviderType } from "config/constants";
import Record from "models/Record";

export type OauthProviderType =
  | ProviderType.gsheet
  | ProviderType.hubspot
  | ProviderType.msDynamics
  | ProviderType.salesforce
  | ProviderType.pipedrive
  | ProviderType.zoho;

export type APIKeyProviderType = ProviderType.upsales;

export type APIKeyAndDomainProviderType = ProviderType.freshsales;

export type CRMProviderType =
  | OauthProviderType
  | APIKeyProviderType
  | APIKeyAndDomainProviderType
  | ProviderType.stripe;

export type CRMFileProviderType = ProviderType.gsheet | ProviderType.file;

export type DataProviderType = CRMProviderType | ProviderType.file;

export const isCrmFileType = (value: any): value is CRMFileProviderType => {
  return [ProviderType.gsheet, ProviderType.file].includes(value);
};

export const isCrmFileOAuthProviderType = (
  value: any
): value is CRMFileProviderType => {
  return [ProviderType.gsheet].includes(value);
};

export const isOauthProviderType = (value: any): value is OauthProviderType => {
  return [
    ProviderType.gsheet,
    ProviderType.hubspot,
    ProviderType.msDynamics,
    ProviderType.salesforce,
    ProviderType.pipedrive,
    ProviderType.zoho,
  ].includes(value);
};

export const isAPIKeyProviderType = (
  value: any
): value is APIKeyProviderType => {
  return [ProviderType.upsales].includes(value);
};

export const isAPIKeyAndDomainProviderType = (
  value: any
): value is APIKeyAndDomainProviderType => {
  return [ProviderType.freshsales].includes(value);
};

export const isCrmProviderType = (value: any): value is CRMProviderType => {
  return (
    isOauthProviderType(value) ||
    isAPIKeyProviderType(value) ||
    isAPIKeyAndDomainProviderType(value) ||
    value === ProviderType.stripe
  );
};

export const isDataProviderType = (value: any): value is DataProviderType => {
  return (
    isOauthProviderType(value) ||
    isAPIKeyProviderType(value) ||
    isAPIKeyAndDomainProviderType(value) ||
    value === ProviderType.stripe ||
    value === ProviderType.file
  );
};

export interface ProviderConfig {
  name: string;
  logo: string;
  oauth?: string;
  oauthSandbox?: string;
  clientId?: string;
  scope?: string;
}

export enum GsheetUrl {
  TabSelectionHash = "#gsheet-tab",
  AuthHash = "#gsheet-auth",
}

export const GSHEET_URL_REGEX = new RegExp(
  "(https://docs.google.com/spreadsheets/d/)(.*)(/.*)"
);

export type TagData = {
  tag: Record;
  usage?: number;
  usageTooltip?: string;
};

export const SLACK_INTEGRATION_HASH = "#slack-integration";

export enum NonUserUrl {
  Base = "/non-user-messaging",
}
