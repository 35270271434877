import { Refresh } from "components/icons";
import Button from "components/ui/Button";
import Tooltip from "components/ui/Tooltip";
import {
  defineMessages,
  FormattedHTMLMessage,
  FormattedMessage,
} from "react-intl";

import {
  DisabledSyncReason,
  useRefreshFieldsButtonApi,
} from "../hooks/useRefreshFieldsButtonApi";

type Props = {
  disabled?: boolean;
  integrationId: number;
};

const RefreshFieldsButton = ({ disabled, integrationId }: Props) => {
  const { disabledSyncReason, refreshFields } = useRefreshFieldsButtonApi({
    integrationId,
  });
  const isDisabled = !!disabledSyncReason;

  return (
    <Tooltip
      title={
        disabledSyncReason ? (
          <FormattedHTMLMessage {...DISABLED_LABELS[disabledSyncReason]} />
        ) : (
          <FormattedMessage {...i18n.refreshFields} />
        )
      }
    >
      <div>
        <Button
          data-testid="refresh-fields-btn"
          disabled={disabled || isDisabled}
          LeftIcon={Refresh}
          onClick={refreshFields}
          size="small"
          variant="tertiary"
        />
      </div>
    </Tooltip>
  );
};

export default RefreshFieldsButton;

const i18n = defineMessages({
  refreshFields: {
    id: "RefreshFieldsButton.refreshFields",
    defaultMessage: "Refresh fields",
  },
  inProgress: {
    id: "RefreshFieldsButton.inProgress",
    defaultMessage: "Sync in progress",
  },
  pleaseWait: {
    id: "RefreshFieldsButton.pleaseWait",
    defaultMessage:
      "Please wait at least for 15 minutes<br/>before launching a new sync",
  },
});

// Helpers

const DISABLED_LABELS = {
  [DisabledSyncReason.inProgress]: i18n.inProgress,
  [DisabledSyncReason.limitReached]: i18n.pleaseWait,
};
