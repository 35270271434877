import Alert from "@mui/material/Alert";
import Button from "@mui/material/Button";
import Dialog, { DialogProps } from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { Breakpoint, useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import useUserProfile from "hooks/useUserProfile";
import generic from "i18n/generic";
import { makeStyles } from "makeStyles";
import { PayingFeature } from "models/CompanyPayingFeatureSubscription";
import User from "models/User";
import { ReactElement } from "react";
import { FormattedMessage } from "react-intl";
import { useSelector } from "react-redux";
import { selectActivePayingFeatures } from "redux/api/selectors";

type Props = {
  DialogGlobalComponent?: ReactElement;
  DialogTitleComponent?: ReactElement;
  DialogContentComponent?: ReactElement;
  DialogActionsComponent?: ReactElement;
  handleClose: $TSFixMeFunction;
  isOpen: boolean;
  maxWidth?: Breakpoint;
  modalStyle?: $TSFixMe;
  checkPermissions?: (
    profile: User,
    payingFeatures: PayingFeature[]
  ) => boolean;
  fullScreenBreakpoint?: Breakpoint;
};

const DialogManager = ({
  DialogGlobalComponent,
  DialogTitleComponent,
  DialogContentComponent,
  DialogActionsComponent,
  fullScreenBreakpoint,
  isOpen,
  handleClose,
  modalStyle,
  checkPermissions = () => true,
  ...props
}: Props & Omit<DialogProps, "open">) => {
  const theme = useTheme();
  const { classes: baseStyle, cx } = useStyles();
  const newStyle = modalStyle;

  const breakpoint = fullScreenBreakpoint ? fullScreenBreakpoint : "xs";
  const fullScreen = useMediaQuery(theme.breakpoints.down(breakpoint));
  const dialogClasses = cx(
    baseStyle.dialogContainer,
    newStyle && newStyle.dialogContainer
  );

  const { profile } = useUserProfile();
  const payingFeatures = useSelector(selectActivePayingFeatures);

  return (
    <Dialog
      open={isOpen}
      fullScreen={fullScreen}
      onClose={() => handleClose(false)}
      aria-labelledby="form-dialog-title"
      className={dialogClasses}
      classes={{ paper: baseStyle.paper }}
      {...props}
    >
      {checkPermissions(profile, payingFeatures) ? (
        <>
          <div data-testid="hidden-dialog-close" onClick={handleClose} />
          {DialogGlobalComponent && DialogGlobalComponent}
          {DialogTitleComponent && (
            <DialogTitle>{DialogTitleComponent}</DialogTitle>
          )}
          {DialogContentComponent && (
            <DialogContent>{DialogContentComponent}</DialogContent>
          )}
          {DialogActionsComponent && (
            <DialogActions>{DialogActionsComponent}</DialogActions>
          )}
        </>
      ) : (
        <>
          <DialogTitle></DialogTitle>
          <DialogContent>
            <Alert severity="warning">
              <FormattedMessage {...generic.not_authorized} />
            </Alert>
          </DialogContent>
          <DialogActions>
            <Button variant="contained" color="primary" onClick={handleClose}>
              <FormattedMessage {...generic.dismiss} />
            </Button>
          </DialogActions>
        </>
      )}
    </Dialog>
  );
};

export default DialogManager;

/// CSS

const useStyles = makeStyles()((theme) => ({
  dialogContainer: {
    padding: theme.spacing(0),
  },
  paper: {
    background: theme.palette.ivory,
    padding: theme.spacing(4),
    [theme.breakpoints.up("md")]: {
      width: "100%",
    },
  },
}));
