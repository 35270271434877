import AddIcon from "@mui/icons-material/Add";
import Autocomplete from "@mui/material/Autocomplete";
import CircularProgress from "@mui/material/CircularProgress";
import SearchIcon from "components/icons/Search";
import { TextInput } from "components/ui/TextInput";
import { T } from "components/ui/Typography";
import _ from "lodash";
import { makeStyles } from "makeStyles";
import User from "models/User";
import {
  ChangeEvent,
  SyntheticEvent,
  useEffect,
  useMemo,
  useState,
} from "react";
import { defineMessages, useIntl } from "react-intl";
import SearchService from "services/SearchService";

type Props = {
  addWatcher: $TSFixMeFunction;
  watcherIds: $TSFixMe[];
};

const FollowersSearch = ({ addWatcher, watcherIds }: Props) => {
  const { classes } = useStyles();
  const [query, setQuery] = useState("");
  const [autocompleteKey, setAutocompleteKey] = useState(0);
  const [open, setOpen] = useState(false);
  const [options, setOptions] = useState<User[]>([]);
  const [loading, setLoading] = useState(false);
  const intl = useIntl();

  const handleChange = (_event: SyntheticEvent, value: $TSFixMe) => {
    addWatcher(value, "add");
    // Update the Autocomplete key to re-render the component
    setAutocompleteKey(autocompleteKey + 1);
  };

  const fetchOptions = useMemo(
    () =>
      _.throttle((input: $TSFixMe, callback: $TSFixMe) => {
        if (input !== "") {
          setLoading(true);
          SearchService("users")
            .exclude(watcherIds.join(","))
            .autocomplete(input)
            .perform({ "page[size]": 5 })
            .then(callback)
            .finally(() => setLoading(false));
        }
      }, 200),
    [watcherIds]
  );

  useEffect(() => {
    let active = true;
    if (query === "") {
      setOptions([]);
      return undefined;
    } else {
      fetchOptions(query, ({ records }: $TSFixMe) => {
        if (active) {
          setOptions(records);
        }
      });
    }
    return () => {
      active = false;
    };
  }, [fetchOptions, query]);

  return (
    <Autocomplete
      id="watchers-autocomplete"
      key={autocompleteKey}
      className={classes.root}
      classes={{ paper: classes.paper, popper: classes.popper }}
      open={open}
      onOpen={() => setOpen(true)}
      onClose={() => setOpen(false)}
      onChange={handleChange}
      getOptionLabel={(option) => option.firstName + " " + option.lastName}
      loadingText={intl.formatMessage(i18n.loading)}
      noOptionsText={intl.formatMessage(i18n.nooption)}
      popupIcon={null}
      forcePopupIcon={false}
      renderOption={(props, option) => (
        <li {...props}>
          <div className={classes.option}>
            <div className={classes.optionText}>
              <T noWrap>{option.firstName + " " + option.lastName}</T>
              <T noWrap>{option.email}</T>
            </div>
            <div>
              {/* @ts-expect-error ts-migrate(2339) FIXME: Property 'addIcon' does not exist on type 'Record<... Remove this comment to see the full error message */}
              <AddIcon className={classes.addIcon} />
            </div>
          </div>
        </li>
      )}
      options={options}
      loading={loading}
      disableClearable
      renderInput={(params) => (
        <TextInput
          {...params}
          fullWidth
          placeholder={intl.formatMessage(i18n.placeholder)}
          onChange={(event: ChangeEvent<HTMLInputElement>) =>
            setQuery(event.target.value)
          }
          RightIcon={
            loading
              ? () => <CircularProgress color="inherit" size={20} />
              : SearchIcon
          }
          InputProps={{
            ...params.InputProps,
            className: classes.searchInput,
            margin: "dense",
          }}
        />
      )}
    />
  );
};

export default FollowersSearch;

// I18N

const i18n = defineMessages({
  placeholder: {
    id: "WatcherSearch.placeholder",
    defaultMessage: "Add a follower",
  },
  loading: {
    id: "WatcherSearch.loading",
    defaultMessage: "Loading…",
  },
  nooption: {
    id: "WatcherSearch.nooption",
    defaultMessage: "No user found",
  },
});

// CSS

const useStyles = makeStyles()((theme) => ({
  root: {
    width: 240,
    [theme.breakpoints.down("md")]: {
      width: `calc(100% - ${theme.spacing(2)})`,
    },
  },
  searchInput: {
    fontSize: 14,
    backgroundColor: theme.palette.white,
  },
  option: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    width: "100%",
  },
  optionText: {
    fontSize: 13,
    maxWidth: 190,
  },
  popper: {
    // @ts-expect-error ts-migrate(2339) FIXME: Property 'main' does not exist on type 'Color'.
    border: `1px solid ${theme.palette.grey.main}`,
    borderRadius: 3,
    fontSize: 13,
    marginTop: 4,
  },
  paper: {
    boxShadow: "none",
    margin: 0,
    fontSize: 13,
  },
}));
