import { createSvgIcon } from "@mui/material/utils";

import { SvgIcon } from "./types";

const displayName = "Home";

const Home: SvgIcon = createSvgIcon(
  // Using transform to enlarge from provided viewbox="0 0 16 16"
  // to default "0 0 24 24" from `createSvgIcon` -> `SvgIcon`.
  <g fill="none" transform="matrix(1.5,0,0,1.5,0,0)">
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M9.32742 2.42134C8.58819 1.80028 7.51081 1.79598 6.76664 2.41112L2.22574 6.16474C1.76611 6.54469 1.5 7.10992 1.5 7.70626V12.5001C1.5 13.6046 2.39543 14.5001 3.5 14.5001H12.5C13.6046 14.5001 14.5 13.6046 14.5 12.5001V7.69896C14.5 7.1082 14.2388 6.54767 13.7865 6.16766L9.32742 2.42134ZM7.40377 3.18188C7.77585 2.87431 8.31455 2.87646 8.68416 3.18699L13.1433 6.93331C13.3694 7.12331 13.5 7.40358 13.5 7.69896V12.5001C13.5 13.0523 13.0523 13.5001 12.5 13.5001H10.5V10C10.5 8.89543 9.60457 8 8.5 8H7.5C6.39543 8 5.5 8.89543 5.5 10V13.5001H3.5C2.94772 13.5001 2.5 13.0523 2.5 12.5001V7.70626C2.5 7.40809 2.63306 7.12547 2.86287 6.9355L7.40377 3.18188ZM6.5 13.5001H9.5V10C9.5 9.44772 9.05228 9 8.5 9H7.5C6.94772 9 6.5 9.44772 6.5 10V13.5001Z"
      fill="currentColor"
    />
  </g>,
  displayName
);

Home.displayName = displayName;
export default Home;
