import { Available360MappingColumns } from "redux/mapping360/defaults";

export const isDealInfluencerColumn = (fieldName: string, row: any) => {
  return (
    row?.type === "nearbound_prospects" &&
    fieldName === Available360MappingColumns.dealInfluencerList
  );
};

export const dealInfluencerFormatter = (value: { job_title: string }[]) => {
  const dealInfluencers = value.map((v) => v.job_title);
  return value.length > 0 ? dealInfluencers.join(", ") : "";
};
