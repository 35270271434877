import { createSvgIcon } from "@mui/material/utils";

import { SvgIcon } from "./types";

const displayName = "Sandglass";

const Sandglass: SvgIcon = createSvgIcon(
  // Using transform to enlarge from provided viewbox="0 0 16 16"
  // to default "0 0 24 24" from `createSvgIcon` -> `SvgIcon`.
  <g fill="none" transform="matrix(1.5,0,0,1.5,0,0)">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3 1.525C3 1.23505 3.22747 1 3.50806 1H12.9919C13.2725 1 13.5 1.23505 13.5 1.525C13.5 1.81495 13.2725 2.05 12.9919 2.05V2.74375C12.9919 3.88713 12.6532 5.00311 12.0214 5.94171L10.6357 8L12.0214 10.0583C12.6532 10.9969 12.9919 12.1129 12.9919 13.2562V13.95C13.2725 13.95 13.5 14.1851 13.5 14.475C13.5 14.7649 13.2725 15 12.9919 15H3.50806C3.22747 15 3 14.7649 3 14.475C3 14.1851 3.22747 13.95 3.50806 13.95V13.2562C3.50806 12.1129 3.84676 10.9969 4.47864 10.0583L5.86431 8L4.47864 5.94171C3.84676 5.00311 3.50806 3.88713 3.50806 2.74375V2.05C3.22747 2.05 3 1.81495 3 1.525ZM4.52419 13.95H5.49799L6.52665 12.2493C7.32262 10.9333 9.17738 10.9333 9.97335 12.2493L11.002 13.95H11.9758V13.2562C11.9758 12.3273 11.7006 11.4205 11.1872 10.6579L9.75131 8.525H6.74868L5.31279 10.6579C4.79939 11.4205 4.52419 12.3273 4.52419 13.2562V13.95ZM9.80375 13.95L9.11168 12.8058C8.71369 12.1478 7.78631 12.1478 7.38832 12.8058L6.69625 13.95H9.80375ZM4.52419 2.05H11.9758V2.74375C11.9758 3.41438 11.8324 4.07341 11.5595 4.675H4.94051C4.6676 4.07341 4.52419 3.41438 4.52419 2.74375V2.05ZM5.57056 5.725L6.74868 7.475H9.75131L10.9294 5.725H5.57056Z"
      fill="currentColor"
    />
  </g>,
  displayName
);

Sandglass.displayName = displayName;
export default Sandglass;
