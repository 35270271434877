import Record from "./Record";
import { JSONAPIResourceOrRecord } from "./types";

export class PotentialPartnerOverlap extends Record<"potential_partner_overlaps"> {
  companyId: number;
  matchedCompanyId: number;
  accountOverlap: string;
  contactOverlap: string;

  constructor(data: JSONAPIResourceOrRecord<"potential_partner_overlaps">) {
    super(data);
    this.companyId = this.attributes.company_id as number;
    this.matchedCompanyId = this.attributes.matched_company_id as number;
    this.accountOverlap = this.attributes.account_overlap as string;
    this.contactOverlap = this.attributes.contact_overlap as string;
  }
}

export default PotentialPartnerOverlap;
