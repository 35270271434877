import segment from "helpers/segment";
import sentry from "helpers/sentry";
import { defineMessages, FormattedMessage } from "react-intl";
import { generateRecords } from "redux/api/utils";
import { JSONAPIResponse } from "services/types";

import { PayingFeature } from "./CompanyPayingFeatureSubscription";
import Partnership from "./Partnership";
import { Record } from "./Record";
import { JSONAPIResourceOrRecord } from "./types";

export type IAuthProvider = "google" | "test_auth_provider";

export type IIdentifyManualOptions = {
  integrationCount?: number;
  activePartnershipsCount?: number;
  partnershipInvitesCount?: number;
  pendingPartnershipCount?: number;
  pendingPartnershipToAcceptCount?: number;
};

export default class User extends Record {
  authProvider?: IAuthProvider | null;
  fullName: string;
  firstName: string;
  lastName: string;
  email: string;
  avatarUrl?: string;
  isAnonymous: boolean;
  lastPermissionRequest: string | null;
  intercomHash: string | null;
  userIntention?: string[] | null;
  userRegistrationInfluenceSource?: string | null;

  constructor(data?: JSONAPIResourceOrRecord<"users">) {
    if (!data) {
      super({ id: "0", type: "users" });
    } else {
      if (data.type !== "users") {
        throw new TypeError(`Cannot instanciate User with type "${data.type}"`);
      }
      super(data);
    }
    this.email = (this.attributes.email as string) || "";
    this.fullName = (this.attributes.full_name as string) || "";
    this.firstName = (this.attributes.first_name as string) || "";
    this.lastName = (this.attributes.last_name as string) || "";
    this.avatarUrl = (this.attributes.avatar_url as string) ?? null;
    this.isAnonymous = !this.id;
    this.lastPermissionRequest = this.attributes.last_permission_request
      ? String(this.attributes.last_permission_request)
      : null;
    this.intercomHash = this.attributes.intercom_hash
      ? String(this.attributes.intercom_hash)
      : null;
    this.userIntention = this.attributes.user_intention as string[];
    this.userRegistrationInfluenceSource = this.attributes
      .registration_influence_source as string | null;
    this.authProvider =
      (this.attributes?.auth_provider as IAuthProvider) ?? null;
  }

  identify(options?: IIdentifyManualOptions) {
    segment.identify(this, options);
    sentry.identify(this);
  }

  toString() {
    if (this.isAnonymous) {
      return "[Anonymous User]";
    }
    return `[User: id=${this.id} name=${this.fullName}]`;
  }

  canInvitePartner(payingFeatures: PayingFeature[]) {
    return (
      Boolean(
        this.hasPermissions(["company.manage_partnerships"], this.company)
      ) ||
      (Boolean(
        this.hasPermissions(["company.partnership_invite"], this.company)
      ) &&
        payingFeatures.includes(PayingFeature.SalesInvitations))
    );
  }

  hasPermissions(permissions: string[], record: Record) {
    if (this.roleAssignments) {
      const matchRecord = (roleAssignment: Record) => {
        if (!record) {
          return !roleAssignment.recordId;
        }
        return (
          roleAssignment.recordId &&
          +roleAssignment.recordId === record.id &&
          roleAssignment.recordType === record.type
        );
      };

      const hasOverlap = (roleAssignment: Record) =>
        roleAssignment.permissions.find((perm: string) =>
          permissions.includes(perm)
        );

      const result = this.roleAssignments
        .filter(matchRecord)
        .filter(hasOverlap);
      if (result.length) {
        return result;
      }
    }
    return false;
  }

  hasOnboardingSkipStep(step: string, timeDiffSinceCreatedInMillis?: number) {
    if (this.onboardingSkips) {
      const result = this.onboardingSkips.find(
        (onboardingSkip: Record) => onboardingSkip.step === step
      );
      if (timeDiffSinceCreatedInMillis) {
        if (!result?.completedAt) {
          return false;
        }
        return (
          Date.now() - new Date(result.completedAt).getTime() <
          timeDiffSinceCreatedInMillis
        );
      }
      if (result) {
        return true;
      }
    }
    return false;
  }

  hasPartnerships() {
    const company = this.company;
    return (
      company.activePartnership +
        // pendingToAcceptPartnership = partnerships that my company needs to accept
        (company.pendingPartnership - company.pendingToAcceptPartnership) >
      0
    );
  }

  hasNoPartnership(partnerships: Partnership[]) {
    const company = this.company;
    return (
      company.activePartnership === 0 &&
      company.createdPartnership === 0 &&
      !Object.values(partnerships).some(
        (partnership) =>
          partnership.initiatorCompany.id === company.id ||
          (partnership.destCompany &&
            partnership.destCompany.id === company.id &&
            partnership.status === "accepted")
      )
    );
  }

  isSales() {
    if (this.roleAssignments) {
      const company = this.company;
      const assignment = this.roleAssignments.find((item: $TSFixMe) => {
        return (
          (item.role === "company.sales" ||
            item.role === "company.sales_manager") &&
          item.recordId.toString() === company.id.toString() &&
          item.recordType === "companies"
        );
      });
      if (assignment) {
        return assignment.id;
      }
    }
    return false;
  }

  isCompanyAdmin() {
    if (this.roleAssignments) {
      const company = this.company;
      const assignment = this.roleAssignments.find((item: $TSFixMe) => {
        return (
          item.role === "company.admin" &&
          item.recordId.toString() === company.id.toString() &&
          item.recordType === "companies"
        );
      });
      if (assignment) {
        return assignment.id;
      }
    }
    return false;
  }

  isPartnershipManager() {
    if (this.roleAssignments) {
      const company = this.company;
      const assignment = this.roleAssignments.find((item: $TSFixMe) => {
        return (
          item.role === "company.partnership_manager" &&
          item.recordId.toString() === company.id.toString() &&
          item.recordType === "companies"
        );
      });
      if (assignment) {
        return assignment.id;
      }
    }
    return false;
  }

  get canAccessWorkspace() {
    return Boolean(
      this.hasPermissions(["company.partners_access"], this.company)
    );
  }

  get canManagePartnerships() {
    return Boolean(
      this.hasPermissions(["company.manage_partnerships"], this.company)
    );
  }

  get canDeletePartnerships() {
    return Boolean(
      this.hasPermissions(["company.delete_partnerships"], this.company)
    );
  }

  get canManageSources() {
    return Boolean(
      this.hasPermissions(["company.manage_sources"], this.company)
    );
  }

  get ownsPipeline() {
    return Boolean(
      this.hasPermissions(["company.owns_pipeline"], this.company)
    );
  }

  get canRequestIntro() {
    return Boolean(
      this.hasPermissions(
        ["company.app_introduction_request_access"],
        this.company
      )
    );
  }

  get canManageSlack() {
    return Boolean(this.hasPermissions(["company.manage_slack"], this.company));
  }

  get needsConfirmation() {
    return !Boolean(this.confirmedAt);
  }

  // Only used for tracking
  isPaidCustomer(payingFeatures: PayingFeature[]) {
    const neededFeatures = [
      PayingFeature.My360MappingUnlocked,
      PayingFeature.UnlimitedWeeklyDigestContent,
      PayingFeature.UnlimitedOfflineAccountMapping,
      PayingFeature.UnlimitedPipelineSharing,
      PayingFeature.UnlimitedRowsExport,
    ];
    return Boolean(neededFeatures.every((key) => payingFeatures.includes(key)));
  }

  isUserLimitReached() {
    return Boolean(
      this.company?.licensedUserCount >= this.company?.totalLicenseQuota
    );
  }

  /**
   * Generate User record from the API Response, with relationships.
   * @param responseData: response content from the API
   * @returns User from respone
   */
  static fromResponse(response: JSONAPIResponse) {
    const state = generateRecords(response);
    if (state.users === undefined) {
      throw new Error("Cannot load user into state using provided response");
    }
    return state.users[response.data.id] as User;
  }
}

const i18n = defineMessages({
  coMarketing: {
    id: "models.User.coMarketing",
    defaultMessage: "Co-Market with your partners",
  },
  crmAndEvents: {
    id: "models.User.crmAndEvents",
    defaultMessage: "Identify which accounts are at an event",
  },
  findIceBreaker: {
    id: "models.User.findIceBreaker",
    defaultMessage: "Personalize outreach with the right icebreaker",
  },
  findLeads: {
    id: "models.User.findLeads",
    defaultMessage: "Find new qualified leads",
  },
  getAlerts: {
    id: "models.User.getAlerts",
    defaultMessage: "Get alerts when my opportunities are won by a partner",
  },
  getIntros: {
    id: "models.User.getIntros",
    defaultMessage: "Leverage your partners to broker intros",
  },
  growthOrMarketing: {
    id: "models.User.growthOrMarketing",
    defaultMessage: "Growth/Marketing",
  },
  leverageOverlap: {
    id: "models.User.leverageOverlap",
    defaultMessage: "Leverage business overlaps with my partners",
  },
  newOpportunities: {
    id: "models.User.newOpportunities",
    defaultMessage: "Influence and source new opportunities",
  },
  newPartners: {
    id: "models.User.newPartners",
    defaultMessage: "Find new partners",
  },
  operations: {
    id: "models.User.operations",
    defaultMessage: "Revenue Operations",
  },
  other: {
    id: "models.User.other",
    defaultMessage: "Other",
  },
  partnerPotential: {
    id: "models.User.partnerPotential",
    defaultMessage: "Assess a potential partnership",
  },
  partnerships: {
    id: "models.User.partnerships",
    defaultMessage: "Partnerships",
  },
  prioritizeLeads: {
    id: "models.User.prioritizeLeads",
    defaultMessage: "Prioritize and score your account list",
  },
  sales: {
    id: "models.User.sales",
    defaultMessage: "Sales",
  },
  sourceDeals: {
    id: "models.User.sourceDeals",
    defaultMessage: "Source new deals",
  },
});

export enum UserRole {
  partnerships = "partnerships",
  growthOrMarketing = "growth_or_marketing",
  sales = "sales",
  operations = "operations",
  other = "other",
}

export const USER_ROLE_LABELS = {
  [UserRole.partnerships]: <FormattedMessage {...i18n.partnerships} />,
  [UserRole.growthOrMarketing]: (
    <FormattedMessage {...i18n.growthOrMarketing} />
  ),
  [UserRole.sales]: <FormattedMessage {...i18n.sales} />,
  [UserRole.operations]: <FormattedMessage {...i18n.operations} />,
  [UserRole.other]: <FormattedMessage {...i18n.other} />,
};

export const USER_ROLE_TO_CHILI_PIPER_VALUES = {
  [UserRole.growthOrMarketing]: "Growth/Marketing",
  [UserRole.operations]: "Operations",
  [UserRole.other]: "Other",
  [UserRole.partnerships]: "Partnerships",
  [UserRole.sales]: "Sales",
};

export enum UserIntention {
  CoMarketing = "co_marketing",
  CrmAndEvents = "crm_and_events",
  FindIceBreaker = "find_ice_breaker",
  FindLeads = "find_leads",
  GetAlerts = "get_alerts",
  GetIntros = "get_intros",
  LeverageOverlap = "leverage_overlap",
  NewOpportunities = "new_opportunities",
  NewPartners = "new_partners",
  Other = "other",
  PartnerPotential = "partner_potential",
  PrioritizeLeads = "prioritize_leads",
  SourceDeals = "source_deals",
}

export const USER_INTENTION_LABELS = {
  [UserIntention.CoMarketing]: <FormattedMessage {...i18n.coMarketing} />,
  [UserIntention.CrmAndEvents]: <FormattedMessage {...i18n.crmAndEvents} />,
  [UserIntention.FindIceBreaker]: <FormattedMessage {...i18n.findIceBreaker} />,
  [UserIntention.FindLeads]: <FormattedMessage {...i18n.findLeads} />,
  [UserIntention.GetAlerts]: <FormattedMessage {...i18n.getAlerts} />,
  [UserIntention.GetIntros]: <FormattedMessage {...i18n.getIntros} />,
  [UserIntention.LeverageOverlap]: (
    <FormattedMessage {...i18n.leverageOverlap} />
  ),
  [UserIntention.NewOpportunities]: (
    <FormattedMessage {...i18n.newOpportunities} />
  ),
  [UserIntention.NewPartners]: <FormattedMessage {...i18n.newPartners} />,
  [UserIntention.Other]: <FormattedMessage {...i18n.other} />,
  [UserIntention.PartnerPotential]: (
    <FormattedMessage {...i18n.partnerPotential} />
  ),
  [UserIntention.PrioritizeLeads]: (
    <FormattedMessage {...i18n.prioritizeLeads} />
  ),
  [UserIntention.SourceDeals]: <FormattedMessage {...i18n.sourceDeals} />,
};

const defaultUserIntentions = [
  UserIntention.NewOpportunities,
  UserIntention.NewPartners,
  UserIntention.PartnerPotential,
  UserIntention.LeverageOverlap,
  UserIntention.Other,
];

export const USER_ROLE_TO_USER_INTENTION = {
  [UserRole.partnerships]: defaultUserIntentions,
  [UserRole.growthOrMarketing]: [
    UserIntention.FindLeads,
    UserIntention.PrioritizeLeads,
    UserIntention.CoMarketing,
    UserIntention.CrmAndEvents,
    UserIntention.Other,
  ],
  [UserRole.sales]: [
    UserIntention.SourceDeals,
    UserIntention.GetAlerts,
    UserIntention.GetIntros,
    UserIntention.FindIceBreaker,
    UserIntention.Other,
  ],
  [UserRole.other]: defaultUserIntentions,
  [UserRole.operations]: defaultUserIntentions,
};
