import { Box } from "@mui/material";
import ChevronDown from "components/icons/ChevronDown";
import DropdownOption from "components/ui/DropdownOption";
import DropdownPopover from "components/ui/DropdownPopover";
import { T } from "components/ui/Typography";
import { makeStyles } from "makeStyles";
import { useState } from "react";
import { FormattedMessage, MessageDescriptor, useIntl } from "react-intl";

import { SinceOption } from "../../../shared/types";

export type Option = {
  value: SinceOption;
  label: MessageDescriptor;
};

export type Props = {
  isDisabled?: boolean;
  onChange: (option: Option) => void;
  options: Option[];
  value: SinceOption;
};

export const DisplaySinceDropdown = ({
  isDisabled = false,
  onChange,
  options,
  value,
}: Props) => {
  const { classes } = useStyles({ isDisabled });
  const intl = useIntl();
  const [anchorEl, setAnchorEl] = useState<HTMLDivElement>();

  const i18nLabel = options.find((option) => option.value === value)?.label;

  const handleOpenOptions = (event: React.MouseEvent<HTMLDivElement>) => {
    if (!isDisabled) setAnchorEl(event.currentTarget);
  };

  const handleCloseOptions = () => {
    setAnchorEl(undefined);
  };

  const handleChange = (option: Option) => {
    onChange(option);
    handleCloseOptions();
  };

  return (
    <>
      <Box className={classes.box} onClick={handleOpenOptions}>
        <T>
          <FormattedMessage {...i18nLabel} />
        </T>
        <ChevronDown />
      </Box>
      <DropdownPopover
        anchorEl={anchorEl}
        onClose={handleCloseOptions}
        open={Boolean(anchorEl)}
        position="right"
      >
        {options.map((option) => (
          <DropdownOption
            key={option.value}
            isActive={option.value === value}
            onClick={() => handleChange(option)}
          >
            <T>{intl.formatMessage(option.label)}</T>
          </DropdownOption>
        ))}
      </DropdownPopover>
    </>
  );
};

/// CSS

const useStyles = makeStyles<{ isDisabled: boolean }>()(
  (theme, { isDisabled }) => ({
    box: {
      position: "relative",
      top: "1px",
      display: "flex",
      alignItems: "center",
      color: theme.palette.text[isDisabled ? "disabled" : "primary"],
      borderBottom: `1px solid ${
        isDisabled ? "transparent" : theme.palette.text.primary
      }`,
      cursor: isDisabled ? "auto" : "pointer",
      "& svg": {
        fontSize: "16px",
      },
    },
  })
);
