import { Avatar, AvatarGroup, Box } from "@mui/material";
import { Edit, Plus } from "components/icons";
import Button from "components/ui/Button";
import _ from "lodash";
import { makeStyles } from "makeStyles";
import DiscussionParticipant from "models/DiscussionParticipant";

type Props = {
  activeParticipants?: DiscussionParticipant[];
  handleOpenModal: () => void;
  isDisabled?: boolean;
};

const MAX_AVATAR_COUNT = 5;

export const ParticipantsDetails = ({
  handleOpenModal,
  activeParticipants,
  isDisabled,
}: Props) => {
  const { classes } = useStyles({ isDisabled });
  const hasActiveParticipants = (activeParticipants?.length ?? 0) > 0;

  return (
    <Box display="flex" alignItems="center">
      <AvatarGroup
        spacing={4}
        className={classes.avatarGroup}
        max={MAX_AVATAR_COUNT}
        onClick={!isDisabled ? handleOpenModal : undefined}
      >
        {activeParticipants?.map((participant, index) => (
          <Avatar
            alt={_.camelCase(participant.fullName ?? "").toUpperCase()} // to use only 1 initial letter, the first letter of the first name
            key={participant.id}
            src={participant.avatarUrl ?? undefined}
            sx={{ zIndex: index }}
            title={participant.fullName ?? undefined}
          >
            {!participant.avatarUrl &&
              participant.fullName?.toUpperCase().charAt(0)}
          </Avatar>
        ))}
      </AvatarGroup>
      {!isDisabled && (
        <Button
          classes={{ icon: classes.icon }}
          label={hasActiveParticipants ? undefined : i18n.addParticipants}
          LeftIcon={hasActiveParticipants ? Edit : Plus}
          variant={hasActiveParticipants ? "quinary" : "tertiary"}
          onClick={!isDisabled ? handleOpenModal : undefined}
          size="xxxSmall"
        />
      )}
    </Box>
  );
};

const useStyles = makeStyles<{ isDisabled?: boolean }>()(
  (theme, { isDisabled }) => ({
    avatarGroup: {
      cursor: !isDisabled ? "pointer" : "default",
      justifyContent: "flex-end",
      marginRight: 2,
      "& .MuiAvatarGroup-avatar": {
        backgroundColor: theme.palette.greyscale200,
        border: `1px solid ${theme.palette.greyLight050} !important`,
        color: theme.palette.midnight,
        fontSize: 11,
        fontWeight: 500,
        height: 24,
        lineHeight: 11,
        width: 24,
      },
      "& .MuiAvatarGroup-avatar:first-child": {
        zIndex: 999,
      },
    },
    icon: {
      width: 12,
      height: 12,
    },
  })
);

const i18n = {
  addParticipants: {
    id: "DataTables.Pipeline.MessageDrawer.ParticipantsDetails.addParticipants",
    defaultMessage: "Add participants",
  },
};
