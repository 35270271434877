import { createReducer } from "@reduxjs/toolkit";

import {
  initializePipelineAttributeFields,
  loadView,
  updateView,
} from "./thunks";
import { PipelineState } from "./typing";

export default createReducer<PipelineState>(
  {
    view: null,
    ready: false,
    fieldsState: {
      loading: false,
      fields: null,
    },
  },
  (builder) => {
    // @ts-ignore Type instantiation is excessively deep and possibly infinite.ts(2589)
    builder
      .addCase(loadView.fulfilled, (state, action) => {
        // @ts-ignore
        state.view = action.payload;
        state.ready = true;
      })
      .addCase(updateView.pending, (state, action) => {
        if (state.view) {
          const view = state.view;
          const { filters, columns, sort } = action.meta.arg;
          if (filters !== undefined) {
            view.filters = filters;
          }
          if (sort !== undefined) {
            view.sort = sort;
          }
          if (columns !== undefined) {
            view.columns = columns;
          }
          state.view = view;
        }
      })
      .addCase(updateView.fulfilled, (state, action) => {
        // @ts-ignore
        state.view = action.payload;
      })
      /**
       * Fields
       */
      .addCase(initializePipelineAttributeFields.pending, (state) => {
        state.fieldsState.loading = true;
      })
      .addCase(initializePipelineAttributeFields.fulfilled, (state, action) => {
        state.fieldsState.loading = false;
        state.fieldsState.fields = action.payload.fields;
      });
  }
);
