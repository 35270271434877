import {
  boolean,
  constant,
  guard,
  nullable,
  number,
  object,
  optional,
  string,
} from "decoders";
import { mapNullable } from "helpers/mapNullable";
import { defineMessages } from "react-intl";

import {
  IStatus,
  Status as SharedOpportunityStatus,
} from "./PartnerConnection";
import Record from "./Record";
import { JSONAPIResource } from "./types";

const i18n = defineMessages({
  won: {
    id: "models.RawOpportunity.won",
    defaultMessage: "Won",
  },
  lost: {
    id: "models.RawOpportunity.lost",
    defaultMessage: "Lost",
  },
  open: {
    id: "models.RawOpportunity.open",
    defaultMessage: "Open",
  },
  blank: {
    id: "models.RawOpportunity.blank",
    defaultMessage: "(Blank)",
  },
});

export enum Status {
  Open = "OPPORTUNITY_STATUS_OPEN",
  Won = "OPPORTUNITY_STATUS_WON",
  Lost = "OPPORTUNITY_STATUS_LOST",
  Blank = "OPPORTUNITY_STATUS_BLANK",
}

export const StatusLabel = {
  [Status.Open]: i18n.open,
  [Status.Won]: i18n.won,
  [Status.Lost]: i18n.lost,
  [Status.Blank]: i18n.blank,
};

const apiRawOpportunityDecoder = object({
  type: constant("raw_opportunities" as const),
  id: string,
  attributes: object({
    amount_in_dollars: optional(nullable(number)),
    close_date: optional(nullable(string)),
    is_open: boolean,
    is_won: optional(boolean),
    name: optional(nullable(string)),
    stage: optional(nullable(string)),
    url: optional(nullable(string)),
  }),
});

const apiRawOpportunityGuard = guard(apiRawOpportunityDecoder);

export default class RawOpportunity extends Record<"raw_opportunities"> {
  id: number;
  amountInDollars?: number | null;
  closeDate?: Date | null;
  isOpen: boolean;
  isWon?: boolean;
  name?: string | null;
  stage?: string | null;
  url?: string | null;

  constructor(data: JSONAPIResource<"raw_opportunities">) {
    super(data);
    const apiRawOpportunity = apiRawOpportunityGuard(data);
    const attrs = apiRawOpportunity.attributes;

    this.id = +apiRawOpportunity.id;
    this.amountInDollars = mapNullable(attrs.amount_in_dollars, Number);
    this.closeDate = mapNullable(attrs.close_date, (v) => new Date(v));
    this.isOpen = attrs.is_open;
    this.isWon = attrs.is_won;
    this.stage = attrs.stage;
    this.url = attrs.url;
    this.name = attrs.name;
  }

  getStatus(): Status {
    return this.isOpen ? Status.Open : this.isWon ? Status.Won : Status.Lost;
  }

  getSharedOpportunityStatus(): IStatus {
    return this.isOpen
      ? SharedOpportunityStatus.Open
      : this.isWon
      ? SharedOpportunityStatus.Won
      : SharedOpportunityStatus.Lost;
  }
}

export const example = {
  type: "raw_opportunities",
  id: "75",
  attributes: {
    amount_in_dollars: 12.34,
    close_date: "2021-05-24",
    is_open: false,
    is_won: false,
    stage: "Closed Won",
    url: "https://eu16.salesforce.com/0011t00000vLEUBAA4",
  },
};
