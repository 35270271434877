import Avatar from "@mui/material/Avatar";
import Badge from "@mui/material/Badge";
import Offline from "components/icons/Offline";
import Tooltip from "components/ui/Tooltip";
import { makeStyles } from "makeStyles";
import Partnership from "models/Partnership";
import { defineMessages, FormattedMessage } from "react-intl";

import CompanyAvatar, { CompanyAvatarProps } from "./CompanyAvatar";

type Props = CompanyAvatarProps & {
  partnership: Partnership;
};

export const TileAvatar = ({ partnership, ...rest }: Props) => {
  const { classes } = useStyles();

  if (!partnership.isGhost()) {
    return <CompanyAvatar size="md" {...rest} />;
  }

  return (
    <div>
      <Tooltip title={<FormattedMessage {...i18n.tooltipInfo} />}>
        <Badge
          overlap="circular"
          anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
          badgeContent={
            <Avatar className={classes.badge}>
              <Offline className={classes.badgeIcon} />
            </Avatar>
          }
        >
          <CompanyAvatar size="md" {...rest} />
        </Badge>
      </Tooltip>
    </div>
  );
};

const useStyles = makeStyles()((theme) => ({
  badge: {
    backgroundColor: theme.palette.midnight,
    color: theme.palette.white,
    width: 20,
    height: 20,
    right: -4,
    bottom: 3,
    borderColor: theme.palette.white,
    border: `1.5px solid ${theme.palette.white}`,
    position: "absolute",
    flex: "none",
  },
  badgeIcon: {
    width: 12,
  },
}));

const i18n = defineMessages({
  tooltipInfo: {
    id: "TileAvatar.tooltipInfo",
    defaultMessage: "Offline partnership",
  },
});

export default TileAvatar;
