import { crmProviders, ProviderType } from "config/constants";
import _ from "lodash";
import { makeStyles } from "makeStyles";
import IntegrationModel from "models/Integration";

export type StyleProps = {
  size: "md" | "sm" | "xs";
};

type IntegrationProps = Partial<StyleProps> & {
  integration: IntegrationModel;
};

const Integration = ({
  integration,
  size = "xs",
  ...props
}: IntegrationProps) => {
  const { classes } = useStyles({ size });
  return (
    <span className={classes.root} {...props}>
      <img className={classes.icon} src={getLogoSrc(integration)} alt="" />
      {getLabel(integration)}
    </span>
  );
};

export default Integration;

// Helpers

export const getLogoSrc = (integration: IntegrationModel): string => {
  switch (integration.provider) {
    case "file":
      return "/images/providers/CSV.png";
    default:
      return _.get(crmProviders, integration.provider, { logo: null }).logo;
  }
};

export const getLabel = (integration: IntegrationModel): string => {
  const defaultName = _.get(crmProviders, integration.provider, {
    name: integration.provider,
  }).name;

  switch (integration.provider) {
    case ProviderType.file:
      return integration.name || defaultName;
    case ProviderType.gsheet:
      return integration.name || defaultName;
    default:
      return defaultName;
  }
};

// CSS Helpers

const avatarSize = {
  md: 38,
  sm: 28,
  xs: 24,
};

const avatarBorderRadius = {
  md: 6,
  sm: 4,
  xs: 4,
};

// CSS

export const useStyles = makeStyles<StyleProps>()((theme, { size }) => ({
  root: {
    display: "flex",
    alignItems: "center",
  },
  icon: {
    height: avatarSize[size],
    borderRadius: avatarBorderRadius[size],
    margin: theme.spacing(0, 1),
  },
}));
