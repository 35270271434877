import history from "_history";
import { isRejected } from "@reduxjs/toolkit";
import { ConfirmWithTextDialog } from "components/ui/ConfirmWithTextDialog";
import { T } from "components/ui/Typography";
import usePushNotification from "hooks/usePushNotification";
import useSegment from "hooks/useSegment";
import useUserProfile from "hooks/useUserProfile";
import _ from "lodash";
import { makeStyles } from "makeStyles";
import Partnership from "models/Partnership";
import { useCallback } from "react";
import {
  defineMessages,
  FormattedHTMLMessage,
  FormattedMessage,
} from "react-intl";
import { useDispatch } from "react-redux";
import { destroy, rawPost } from "redux/api/thunks";
import { fields, included } from "redux/init/constants";
import { fetchProfile } from "redux/user/thunks";

type Props = {
  isOpen?: boolean;
  onClose: () => void;
  partnership: Partnership;
  setLoading?: (loading: boolean) => void;
  type: DialogType;
};

export enum DialogType {
  Activate = "Activate",
  Delete = "Delete",
  DeletePending = "DeletePending",
}

const DELETE_TEXT = "DELETE";
const PAUSE_TEXT = "PAUSE";

const PartnershipStatusDialog = ({
  isOpen,
  onClose,
  partnership,
  setLoading,
  type,
}: Props) => {
  const dispatch = useDispatch();
  const pushNotification = usePushNotification();
  const { profile } = useUserProfile();
  const { track } = useSegment();

  const partner = partnership.getPartner(profile);
  const isInitiator = partnership.isInitiator(profile.company);
  const isPartnershipActive = !_.get(
    partnership,
    isInitiator ? "pausedByInit" : "pausedByDest",
    false
  );
  const isPending =
    isInitiator &&
    !partnership.isAccepted() &&
    profile.canManagePartnerships &&
    !partnership.isGhost();

  const { classes } = useStyles();

  const togglePause = useCallback(() => {
    try {
      !!setLoading && setLoading(true);
      const response = dispatch(
        rawPost({
          type: "partnerships",
          id: partnership.id,
          path: "/toggle-pause/",
          payload: {},
          options: {
            fields,
            include: included,
          },
        })
      );
      if (isRejected(response)) {
        throw new Error("Error pause/activate partnership toggle");
      }
      if (isPartnershipActive) {
        track("Paused partnership", { partnerName: partner.name });
      } else {
        track("Reactivated partnership", { partnerName: partner.name });
      }
      !!setLoading && setLoading(false);
    } catch (_) {
      !!setLoading && setLoading(false);
      pushNotification("default_error");
    }
  }, [isPartnershipActive]); // eslint-disable-line react-hooks/exhaustive-deps

  const deletePartnership = () => {
    try {
      !!setLoading && setLoading(true);
      const response = dispatch(
        destroy({
          type: "partnerships",
          id: partnership.id,
        })
      );
      if (isRejected(response)) {
        throw new Error("Error delete partnership");
      }
      const partnerTrackObject = { partnerName: partner.name };
      if (isPending) {
        track("Deleted pending partnership", partnerTrackObject);
        profile.identify({
          partnershipInvitesCount:
            Number(_.get(profile, "company.createdPartnership") ?? 0) - 1,
          pendingPartnershipCount:
            Number(_.get(profile, "company.pendingPartnership") ?? 0) - 1,
        });
      } else if (partnership.isGhost()) {
        track("Deleted offline partnership", partnerTrackObject);
      } else {
        track("Deleted partnership", partnerTrackObject);
        profile.identify({
          activePartnershipsCount:
            Number(_.get(profile, "company.activePartnership") ?? 0) - 1,
        });
      }
    } catch (_) {
      !!setLoading && setLoading(false);
      pushNotification("default_error");
      return;
    }
    dispatch(fetchProfile());
    pushNotification(i18n.deleteNotificationSuccess, { partner: partner.name });
    history.push("/partnerships");
  };

  const handleConfirm = () => {
    type === DialogType.Activate ? togglePause() : deletePartnership();
    onClose();
  };

  return (
    <ConfirmWithTextDialog
      confirmText={
        type === DialogType.Activate
          ? isPartnershipActive
            ? PAUSE_TEXT
            : undefined
          : DELETE_TEXT
      }
      handleClose={onClose}
      handleConfirm={handleConfirm}
      isOpen={!!isOpen}
      confirmLabel={
        <FormattedMessage
          {...(type === DialogType.Activate
            ? isPartnershipActive
              ? i18n.pauseButton
              : i18n.activateButton
            : i18n.deleteButton)}
        />
      }
    >
      <>
        <T h3 className={classes.contentMain}>
          {type === DialogType.Activate ? (
            <FormattedHTMLMessage
              {...(isPartnershipActive ? i18n.pauseTitle : i18n.activateTitle)}
              values={{ partner: partner.name }}
            />
          ) : (
            <FormattedHTMLMessage
              {...(type === DialogType.Delete
                ? i18n.deleteTitle
                : i18n.deletePendingTitle)}
              values={{ partner: partner.name }}
            />
          )}
        </T>
        <T className={classes.contentSub}>
          {type === DialogType.Activate ? (
            <FormattedHTMLMessage
              {...(isPartnershipActive
                ? i18n.pauseContent
                : i18n.activateContent)}
              values={{ partner: partner.name, pauseText: PAUSE_TEXT }}
            />
          ) : (
            <FormattedHTMLMessage
              {...(type === DialogType.Delete
                ? i18n.deleteContent
                : i18n.deletePendingContent)}
              values={{ deleteText: DELETE_TEXT }}
            />
          )}
        </T>
      </>
    </ConfirmWithTextDialog>
  );
};

export default PartnershipStatusDialog;

// CSS

const useStyles = makeStyles()((theme) => ({
  contentMain: {
    marginBottom: theme.spacing(1.5),
    textAlign: "center",
  },
  contentSub: {
    marginBottom: theme.spacing(2),
    textAlign: "center",
  },
}));

// I18N

const i18n = defineMessages({
  activateButton: {
    id: "crm.PartnershipSettings.PartnershipStatus.activateButton",
    defaultMessage: "Activate",
  },
  deleteButton: {
    id: "crm.PartnershipSettings.PartnershipStatus.deleteButton",
    defaultMessage: "Delete",
  },
  deleteContent: {
    id: "crm.PartnershipSettings.PartnershipStatus.deleteContent",
    defaultMessage:
      "To confirm that you want to delete this partnership, type {deleteText} below.",
  },
  deletePendingContent: {
    id: "crm.PartnershipSettings.PartnershipStatus.deletePendingContent",
    defaultMessage:
      "To confirm that you want to delete this invitation, type {deleteText} below.",
  },
  deleteNotificationSuccess: {
    id: "crm.PartnershipSettings.PartnershipStatus.deleteNotificationSuccess",
    defaultMessage: "Your partnership with {partner} was removed",
  },
  deleteTitle: {
    id: "crm.PartnershipSettings.PartnershipStatus.deleteTitle",
    defaultMessage:
      "<b>Deleting your {partner} partnership can’t be undone</b>",
  },
  deletePendingTitle: {
    id: "crm.PartnershipSettings.PartnershipStatus.deletePendingTitle",
    defaultMessage: "<b>Delete your invitation to partner with {partner}</b>",
  },
  pauseButton: {
    id: "crm.PartnershipSettings.PartnershipStatus.pauseButton",
    defaultMessage: "Pause",
  },
  pausedPartnership: {
    id: "crm.PartnershipSettings.PartnershipStatus.pausedPartnership",
    defaultMessage: "Paused",
  },
  pauseTitle: {
    id: "crm.PartnershipSettings.PartnershipStatus.pauseTitle",
    defaultMessage:
      "<b>Pause your partnership with {partner} and data will no longer be shared until you reactivate the partnership</b>",
  },
  pauseContent: {
    id: "crm.PartnershipSettings.PartnershipStatus.pauseContent",
    defaultMessage: `
    To confirm that you want to pause this partnership, type {pauseText} below.
    `,
  },
  activateTitle: {
    id: "crm.PartnershipSettings.PartnershipStatus.activateTitle",
    defaultMessage: "<b>Activate your partnership with {partner}</b>",
  },
  activateContent: {
    id: "crm.PartnershipSettings.PartnershipStatus.activateContent",
    defaultMessage:
      "By reactivating this partnership, data will be shared with {partner} according to your settings.",
  },
});
