import { createReducer } from "@reduxjs/toolkit";
import { transformSuggestion } from "redux/dashboard/actions";
import { logout } from "redux/user/thunks";

import { addSuggestions } from "./actions";
import { dismissSuggestion, initializeSuggestedPartners } from "./thunks";
import { SuggestedPartnersState } from "./types";

const initState: SuggestedPartnersState = {
  hasMore: false,
  status: null,
  suggestionIds: [],
  recordCounts: {},
};

const reducer = createReducer(initState, (builder) =>
  builder
    .addCase(initializeSuggestedPartners.pending, (state) => {
      state.status = "pending";
    })
    .addCase(addSuggestions, (state, action) => {
      state.suggestionIds = [
        ...state.suggestionIds,
        ...action.payload.recordIds,
      ];
      state.hasMore = action.payload.hasMore;
    })
    .addCase(initializeSuggestedPartners.fulfilled, (state, action) => {
      state.status = "ready";
      state.suggestionIds = action.payload.suggestionIds;
    })
    .addCase(dismissSuggestion.fulfilled, (state, action) => {
      state.suggestionIds = action.payload;
    })
    .addCase(transformSuggestion, (state, action) => {
      state.suggestionIds = state.suggestionIds.filter(
        (id) => id !== action.payload.suggestionId
      );
    })
    .addCase(logout.fulfilled, () => initState)
);

export default reducer;
