import { CircularProgress } from "@mui/material";
import withAPI, { WithAPI } from "components/hoc/withAPI";
import { Settings } from "components/icons";
import Button from "components/ui/Button";
import { getLabel, getLogoSrc } from "components/ui/Integration";
import SelectBoxGroup from "components/ui/SelectBoxGroup";
import SettingsSection from "components/ui/SettingsSection";
import { T } from "components/ui/Typography";
import muiTheme from "config/theme";
import useAllRecords from "hooks/useAllRecords";
import usePushNotification from "hooks/usePushNotification";
import generic from "i18n/generic";
import _ from "lodash";
import { makeStyles } from "makeStyles";
import Integration from "models/Integration";
import { useCallback, useEffect, useMemo, useState } from "react";
import { defineMessages, FormattedDate, FormattedMessage } from "react-intl";
import { Link } from "react-router-dom";
import { fields, included } from "redux/init/constants";
import { isCrmFileType } from "screens/Frontoffice/shared/helpers/types";

import { TabProps } from "../../types";
import GhostDataSources from "./GhostDataSources";

const DataSourcesTab = ({
  partner,
  partnership,
  fetchRecord,
  rawPatch,
  saving,
  afterSave,
  setSaveButtonEnabled,
  reloadPartnership,
}: TabProps & WithAPI) => {
  const { cx, classes } = useStyles();
  const { classes: selectClasses } = useSelectStyles();
  const pushNotification = usePushNotification();
  const { loading, records: sources } = useAllRecords<Integration>(
    "integrations",
    {
      include: ["statistics"],
    }
  );
  const integrationIds = _.map(
    partnership.integrations,
    (item: Integration) => item.id
  );

  const [initialIntegrationIds, setInitialIntegrationIds] = useState<number[]>(
    integrationIds
  );
  const [enabledIntegrationIds, setEnabledIntegrationIds] = useState<number[]>(
    integrationIds
  );
  const hasChanged = useMemo(
    () =>
      !_.isEqual(
        _.sortedUniq(enabledIntegrationIds),
        _.sortedUniq(initialIntegrationIds)
      ),
    [enabledIntegrationIds, initialIntegrationIds]
  );

  useEffect(() => {
    // When partnership is reloaded: reset default integration ids
    setInitialIntegrationIds(integrationIds);
    setEnabledIntegrationIds(integrationIds);
  }, [partnership]); // eslint-disable-line react-hooks/exhaustive-deps

  const isEnabled = (integrationId: number) =>
    enabledIntegrationIds.includes(integrationId);

  const getTooltipMessage = (integrationId: number) =>
    !isEnabled(integrationId)
      ? i18n.clickToShare
      : enabledIntegrationIds.length <= 1
      ? i18n.cannotDisable
      : i18n.clickToUnshare;

  const options = sources
    .filter(
      (integration) =>
        !isCrmFileType(integration.provider) || !integration.deletedAt
    )
    .map((integration) => {
      const content = (
        <div className={classes.fileContainer}>
          <div className={classes.sourceNameContainer}>
            <img
              className={classes.logo}
              src={getLogoSrc(integration as Integration)}
              alt=""
            />
            <T
              h4
              bold
              className={cx({ [classes.greyText]: !isEnabled(integration.id) })}
            >
              {getLabel(integration as Integration)}
            </T>
            {!integration.mappingConfigured && (
              <T
                oldVariant="caption"
                color={muiTheme.palette.error.main}
                className={classes.noMappingWarning}
              >
                <FormattedMessage {...i18n.noMappingWarning} />
              </T>
            )}
          </div>
          {integration.provider === "file" && (
            <T className={classes.date}>
              <FormattedDate value={integration.createdAt} />
            </T>
          )}
          <Link
            className={classes.linkToSettings}
            to={`/sources?integration=${integration.id}&provider=${integration.provider}`}
          >
            <Button size="small" variant="tertiary" LeftIcon={Settings} />
          </Link>
        </div>
      );

      const tooltip = (
        <FormattedMessage
          {...getTooltipMessage(integration.id)}
          values={{ partner: partner.name }}
        />
      );
      return {
        value: integration.id,
        content,
        disabled:
          enabledIntegrationIds.length === 1 && isEnabled(integration.id),
        tooltip,
      };
    });

  const toggleSource = (integrationId: number) => {
    if (!isEnabled(integrationId)) {
      setEnabledIntegrationIds((current: number[]) => [
        ...current,
        integrationId,
      ]);
    } else if (isEnabled(integrationId) && enabledIntegrationIds.length > 1) {
      setEnabledIntegrationIds((current: number[]) =>
        _.filter(current, (id: number) => id !== integrationId)
      );
    }
  };

  const handleSave = useCallback(async () => {
    const result = await rawPatch(
      "partnerships",
      partnership.id,
      "/relationships/integrations/",
      {
        data: enabledIntegrationIds.map((id) => ({
          type: "integrations",
          id: `${id}`,
        })),
      }
    );
    await fetchRecord("partnerships", partnership.id, {
      include: included,
      fields,
    });
    if (_.get(result, "error", false)) {
      pushNotification("default_error");
    } else {
      setInitialIntegrationIds(enabledIntegrationIds);
      afterSave();
      pushNotification(generic.edits_saved);
    }
  }, [
    enabledIntegrationIds,
    fetchRecord,
    partnership.id,
    pushNotification,
    rawPatch,
    afterSave,
  ]);

  useEffect(() => {
    if (saving) {
      handleSave();
    }
  }, [saving]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => setSaveButtonEnabled(hasChanged), [
    hasChanged,
    setSaveButtonEnabled,
  ]);

  return (
    <>
      <SettingsSection
        subtitle={
          <FormattedMessage
            {...i18n.subtitle}
            values={{ partner: partner.name }}
          />
        }
        title={<FormattedMessage {...i18n.title} />}
        variant="outlined"
      >
        <div className={classes.root}>
          {loading ? (
            <CircularProgress />
          ) : (
            <SelectBoxGroup
              values={enabledIntegrationIds}
              onSelect={toggleSource}
              options={options}
              orientation="vertical"
              allowDeselect
              classes={selectClasses}
            />
          )}
        </div>
      </SettingsSection>

      {partnership.isGhost() && (
        <GhostDataSources
          partner={partner}
          partnership={partnership}
          selectedIntegrationIds={enabledIntegrationIds}
          setSelectedIntegrationIds={setEnabledIntegrationIds}
          reloadPartnership={reloadPartnership}
        />
      )}
    </>
  );
};

export default withAPI(DataSourcesTab);

const useStyles = makeStyles()((theme) => ({
  greyText: {
    color: theme.palette.greyReveal,
  },
  root: {
    display: "flex",
    flexDirection: "column",
    rowGap: theme.spacing(4),
  },
  sourceNameContainer: {
    display: "flex",
    alignItems: "center",
    columnGap: theme.spacing(1.5),
    marginLeft: 32,
    wordBreak: "break-word",
  },
  logo: {
    height: 36,
    borderRadius: 8,
  },
  fileContainer: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    width: "100%",
  },
  noMappingWarning: {
    margin: "0 8px",
  },
  date: {
    marginLeft: "auto",
  },
  linkToSettings: {
    marginLeft: 12,
  },
}));

export const useSelectStyles = makeStyles()((theme) => ({
  inactive: {
    border: `2px solid ${theme.palette.taupe}`,
    "&:hover": {
      border: `2px solid ${theme.palette.midnight}`,
    },
  },
  innerTile: {
    background: theme.palette.ivory,
    padding: `${theme.spacing(1.5)} ${theme.spacing(2)}`,
  },
  innerTilePaddingless: {
    background: theme.palette.ivory,
    padding: 1,
    "& > .MuiGrid-root": {
      margin: "0 !important",
      border: 0,
    },
  },
  selectable: {
    "&:hover": {
      backgroundColor: theme.palette.ivory,
    },
  },
}));

const i18n = defineMessages({
  cannotDisable: {
    id: "crm.PartnerhsipSettings.DataSourcesTab.cannotDisable",
    defaultMessage: "You must share at least 1 data source",
  },
  clickToShare: {
    id: "crm.PartnerhsipSettings.DataSourcesTab.clickToShare",
    defaultMessage: "Click to share with {partner}",
  },
  clickToUnshare: {
    id: "crm.PartnerhsipSettings.DataSourcesTab.clickToUnshare",
    defaultMessage: "Click to stop sharing with {partner}",
  },
  subtitle: {
    id: "crm.PartnerhsipSettings.DataSourcesTab.subtitle",
    defaultMessage:
      "Select which of your connected data sources should be shared with {partner}",
  },
  title: {
    id: "crm.PartnerhsipSettings.DataSourcesTab.title",
    defaultMessage: "Your data source",
  },
  noMappingWarning: {
    id: "crm.PartnerhsipSettings.DataSourcesTab.noMappingWarning",
    defaultMessage: "Configure the mapping for it to be imported",
  },
});
