import { isRejected } from "@reduxjs/toolkit";
import { AxiosRequestConfig } from "axios";
import {
  JSONAPIAttributes,
  JSONAPIIdentifier,
  JSONSerializable,
} from "models/types";
import {
  attachToRecord as attachToRecordV2,
  create,
  destroy,
  rawPatch as rawPatchV2,
  rawPost as rawPostV2,
  retreive,
  update,
} from "redux/api/thunks";
import { unwrapAsResourceID } from "redux/api/utils";
import { JSONAPIOptions, JSONAPIServiceOptions } from "services/types";
import { AppDispatch } from "store";

/**
 * Migrated to next version
 */
export const createRecord = (
  type: string,
  attributes: JSONAPIAttributes,
  relationships?: {
    [relation: string]: JSONAPIIdentifier | JSONAPIIdentifier[] | null;
  },
  options?: JSONAPIOptions,
  serviceOptions?: JSONAPIServiceOptions
) => async (dispatch: AppDispatch) => {
  const action = await dispatch(
    create({
      type,
      attributes,
      relationships,
      options,
      serviceOptions,
    })
  );
  if (!isRejected(action)) {
    return String(unwrapAsResourceID(action));
  }
  return action;
};

export const updateRecord = (
  type: string,
  id: number,
  attributes: JSONAPIAttributes,
  relationships?: {
    [relation: string]: JSONAPIIdentifier | JSONAPIIdentifier[] | null;
  },
  options?: JSONAPIOptions,
  serviceOptions?: JSONAPIServiceOptions
) =>
  update({
    id,
    type,
    attributes,
    relationships,
    options,
    serviceOptions,
  });

export const rawPatch = (
  type: string,
  id: number | undefined,
  path: string,
  payload: JSONSerializable,
  options?: JSONAPIOptions,
  serviceOptions?: JSONAPIServiceOptions
) =>
  rawPatchV2({
    type,
    id,
    path,
    payload,
    options,
    serviceOptions,
  });

export const rawPost = (
  type: string,
  id: number | undefined,
  path: string,
  payload: JSONSerializable,
  options?: JSONAPIOptions & { config?: AxiosRequestConfig },
  serviceOptions?: JSONAPIServiceOptions
) =>
  rawPostV2({
    type,
    id,
    path,
    payload,
    options,
    serviceOptions,
  });

export const fetchRecord = (
  type: string,
  id: number,
  options?: JSONAPIOptions,
  serviceOptions?: JSONAPIServiceOptions
) => retreive({ type, id, options, serviceOptions });

export const removeRecord = (type: string, id: number) => destroy({ type, id });

export const attachToRecord = (
  type: string,
  recordId: number,
  path: string,
  files: { [name: string]: File }
) => attachToRecordV2({ type, recordId, path, files });
