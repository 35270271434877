import { makeStyles } from "makeStyles";
import { defineMessages, MessageDescriptor } from "react-intl";

import Button from "./Button";

type Props = {
  onCancel: () => void;
  onApply: () => void;
  disabledApplyBtn?: boolean;
  cancelLabel?: MessageDescriptor;
  applyLabel?: MessageDescriptor;
};

const DropdownSubmitFooter = ({
  onCancel,
  onApply,
  disabledApplyBtn,
  cancelLabel,
  applyLabel,
}: Props) => {
  const { classes } = useStyles();

  return (
    <div className={classes.footer}>
      <Button
        size="small"
        label={cancelLabel ?? i18n.cancel}
        variant="secondary"
        onClick={onCancel}
      />
      <Button
        size="small"
        label={applyLabel ?? i18n.apply}
        disabled={disabledApplyBtn}
        variant="primary"
        onClick={onApply}
      />
    </div>
  );
};

export default DropdownSubmitFooter;

const useStyles = makeStyles()(() => ({
  footer: {
    display: "flex",
    justifyContent: "space-between",
    "& button": {
      width: "88px",
    },
  },
}));

const i18n = defineMessages({
  cancel: {
    id: "SubmitFooter.cancel",
    defaultMessage: "Cancel",
  },
  apply: {
    id: "SubmitFooter.apply",
    defaultMessage: "Apply",
  },
});
