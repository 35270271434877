import { ClassNameMap } from "@mui/material/styles";
import { ChooseFieldsBlock, Option } from "components/ui/ChooseFieldsBlock";
import { TextInput } from "components/ui/TextInput";
import _ from "lodash";
import { useMemo } from "react";
import { defineMessages, useIntl } from "react-intl";

import OperatorAddWidget from "../OperatorAddWidget";
import { SWQLBooleanOperator, SWQLField, SWQLRightValue } from "../SWQLTypes";

type Props = {
  classes?: ClassNameMap;
  field: SWQLField;
  onChange: (value: SWQLRightValue, extra?: object) => void;
  onAddNode: (operator: SWQLBooleanOperator) => void;
  value?: string[];
};

const PicklistField = ({ field, onChange, value = [], onAddNode }: Props) => {
  const intl = useIntl();
  let selectedFields: Option[] = [];

  const options = useMemo(
    () =>
      field
        ? [
            { id: "null", crmLabel: "Blank" },
            ..._(field.options)
              .entries()
              .map(([value, label]) => ({ id: value, crmLabel: label }))
              .sortBy(["crmLabel", "id"])
              .value(),
          ]
        : [],
    [field]
  );

  if (value?.length) {
    selectedFields = options.filter((option) =>
      value.includes(option.id.toString())
    );
  }

  const handleChange = (data: Option[]) => {
    selectedFields = data;
    onChange(data ? data.map(_.property("id")) : []);
  };

  return field.hasOptions ? (
    <>
      <ChooseFieldsBlock
        isRulesWidget
        allFields={options}
        selectedFields={selectedFields}
        setSelectedFields={handleChange}
        showReset={false}
        addButtonLabel={intl.formatMessage(i18n.placeholder)}
      />
      {Boolean(value) && <OperatorAddWidget onAddNode={onAddNode} />}
    </>
  ) : (
    <TextInput
      small
      disabled
      placeholder={intl.formatMessage(i18n.noOptions)}
      value={null}
    />
  );
};

export default PicklistField;

const i18n = defineMessages({
  placeholder: {
    id: "SWQL.SwQLValueFields.PicklistField.placeholder",
    defaultMessage: "Add values",
  },
  noOptions: {
    id: "SWQL.SwQLValueFields.TextField.noOptions",
    defaultMessage: "No options available",
  },
});
