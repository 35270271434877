import { SvgIconComponent } from "@mui/icons-material";
import { Divider } from "@mui/material";
import MuiButton from "@mui/material/Button";
import RoutingContext from "components/routes/RoutingContext";
import { T } from "components/ui/Typography";
import muiTheme from "config/theme";
import useUserProfile from "hooks/useUserProfile";
import { makeStyles } from "makeStyles";
import { PayingFeature } from "models/CompanyPayingFeatureSubscription";
import User from "models/User";
import { isValidElement, ReactElement, useContext } from "react";
import {
  FormattedHTMLMessage,
  FormattedMessage,
  MessageDescriptor,
} from "react-intl";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { selectActivePayingFeatures } from "redux/api/selectors";

type StyleProps = {
  active?: boolean;
  expanded: boolean;
};

export type SidebarButtonProps = SelectPartial<StyleProps, "expanded"> & {
  Icon?: SvgIconComponent;
  label: MessageDescriptor | ReactElement | string;
  path: RegExp | string | null;
  to?: string;
  fullWidth?: boolean;
  callback?: Function;
  openSidebar?: () => void;
  title?: MessageDescriptor;
  index?: number;
  shouldDisplay?: (user: User, payingFeatures: PayingFeature[]) => boolean;
};

const SidebarButton = ({
  Icon,
  label,
  path,
  to,
  expanded = true,
  fullWidth = false,
  callback,
  openSidebar = () => {},
  title,
  index,
  shouldDisplay = () => true,
  ...props
}: SidebarButtonProps) => {
  const history = useHistory();
  const active = path !== null && Boolean(window.location.pathname.match(path));
  const { profile } = useUserProfile();
  const payingFeatures = useSelector(selectActivePayingFeatures);

  const { classes, cx } = useStyles({
    active,
    expanded,
  });
  const routes: string[] = useContext(RoutingContext);
  if (to && !routes.some((route) => route.match(to.split("#")[0]))) {
    return null;
  }

  if (!!shouldDisplay && !shouldDisplay(profile, payingFeatures)) {
    return null;
  }

  const handleClick = () => {
    if (to !== undefined) {
      history.push(to);
    }
    if (callback) {
      callback();
    }
  };

  return (
    <>
      {(index ?? 0) > 0 && title && <Divider className={classes.divider} />}
      {title && (
        <T className={classes.title}>
          {expanded && <FormattedMessage {...title} />}
        </T>
      )}
      <MuiButton
        onClick={handleClick}
        className={cx(classes.btn, {
          [classes.btnExpanded]: expanded,
          [classes.btnCollapsed]: !expanded,
          [classes.fullWidth]: fullWidth,
        })}
        onMouseEnter={openSidebar}
        {...props}
      >
        {Icon && (
          <Icon
            className={cx(classes.icon, {
              [classes.iconCollapsed]: !expanded,
            })}
          />
        )}
        {expanded && (
          <T color={muiTheme.palette.offWhite} className={classes.label}>
            {typeof label === "string" ? (
              <span>{label}</span>
            ) : isValidElement(label) ? (
              label
            ) : (
              <FormattedHTMLMessage {...label} />
            )}
          </T>
        )}
      </MuiButton>
    </>
  );
};

export default SidebarButton;

// CSS

const useStyles = makeStyles<StyleProps>()((theme, props) => ({
  btn: {
    flex: 1,
    minWidth: 32,
    height: 32,
    borderRadius: 22,
    padding: 8,
    justifyContent: props.expanded ? "left" : "center",
    backgroundColor: props.active
      ? theme.palette.darkPigeon
      : theme.palette.midnight,
    "&:hover": {
      backgroundColor: theme.palette.comet,
    },
    "& .MuiButton-label": {
      justifyContent: "left",
    },
    overflowX: "hidden",
  },
  btnExpanded: {
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  btnCollapsed: {
    width: 32,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  divider: {
    width: "100%",
    margin: "4px 0px",
    backgroundColor: theme.palette.comet,
  },
  label: {
    display: "flex",
    alignItems: "center",
    justifyContent: "left",
    flex: 1,
    fontWeight: 500,
    height: 16,
  },
  icon: {
    marginRight: 8,
    width: 16,
    height: 16,
    color: props.active ? theme.palette.offWhite : theme.palette.greyscale550,
  },
  iconCollapsed: {
    marginRight: 0,
  },
  fullWidth: {
    width: "100%",
  },
  title: {
    color: theme.palette.greyscale550,
    fontWeight: 500,
    fontSize: "10px",
    lineHeight: "10px",
    letterSpacing: "0.2px",
    marginLeft: theme.spacing(1),
    minHeight: 18,
    padding: "6px 8px 2px 8px",
  },
}));
