import { sharedCrmBaseSidePanelStyles } from "./BaseSidePanel";
import { HelpBox, Props as HelpBoxProps } from "./HelpBox";

type Props = HelpBoxProps & { src: string; videoTitle: string };

export const VideoWithHelpBox = ({
  src,
  extraSlot,
  videoTitle,
  i18nTitle,
  i18nSubtitle,
  linkElementList,
}: Props) => {
  const { classes } = sharedCrmBaseSidePanelStyles();

  return (
    <div>
      <iframe
        className={classes.iframeVideo}
        allowFullScreen
        width="100%"
        height="163px"
        src={src}
        title={videoTitle}
      />
      <HelpBox
        i18nTitle={i18nTitle}
        i18nSubtitle={i18nSubtitle}
        linkElementList={linkElementList}
        extraSlot={extraSlot}
        noTopBorderRadius
      />
    </div>
  );
};
