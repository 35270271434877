import { createSvgIcon } from "@mui/material/utils";

import { SvgIcon } from "./types";

const displayName = "Info";

const Info: SvgIcon = createSvgIcon(
  // Using transform to enlarge from provided viewbox="0 0 16 16"
  // to default "0 0 24 24" from `createSvgIcon` -> `SvgIcon`.
  <g fill="none" transform="matrix(1.5,0,0,1.5,0,0)">
    <g clip-path="url(#clip0_2976_327)">
      <path
        d="M8.625 4.88867C8.625 4.54349 8.34518 4.26367 8 4.26367C7.65482 4.26367 7.375 4.54349 7.375 4.88867V7.99978C7.375 8.34496 7.65482 8.62478 8 8.62478C8.34518 8.62478 8.625 8.34496 8.625 7.99978V4.88867Z"
        fill="currentColor"
      />
      <path
        d="M8 10.4859C7.65482 10.4859 7.375 10.7657 7.375 11.1109C7.375 11.4561 7.65482 11.7359 8 11.7359H8.00778C8.35296 11.7359 8.63278 11.4561 8.63278 11.1109C8.63278 10.7657 8.35296 10.4859 8.00778 10.4859H8Z"
        fill="currentColor"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M4.71159 0.5C4.07663 0.499996 3.5696 0.499993 3.16009 0.533451C2.74008 0.567767 2.37882 0.639756 2.04703 0.808815C1.5139 1.08046 1.08046 1.5139 0.808815 2.04703C0.639756 2.37882 0.567767 2.74008 0.533451 3.16009C0.499993 3.5696 0.499996 4.07663 0.5 4.71159V11.2884C0.499996 11.9234 0.499992 12.4304 0.533451 12.8399C0.567767 13.2599 0.639756 13.6212 0.808815 13.953C1.08046 14.4861 1.5139 14.9195 2.04703 15.1912C2.37882 15.3602 2.74008 15.4322 3.16009 15.4665C3.56959 15.5 4.07662 15.5 4.71158 15.5H11.2884C11.9234 15.5 12.4304 15.5 12.8399 15.4665C13.2599 15.4322 13.6212 15.3602 13.953 15.1912C14.4861 14.9195 14.9195 14.4861 15.1912 13.953C15.3602 13.6212 15.4322 13.2599 15.4665 12.8399C15.5 12.4304 15.5 11.9234 15.5 11.2884V4.71158C15.5 4.07662 15.5 3.56959 15.4665 3.16009C15.4322 2.74008 15.3602 2.37882 15.1912 2.04703C14.9195 1.5139 14.4861 1.08046 13.953 0.808815C13.6212 0.639756 13.2599 0.567767 12.8399 0.533451C12.4304 0.499992 11.9234 0.499996 11.2884 0.5H4.71159ZM2.50102 1.69982C2.66835 1.61456 2.88335 1.55939 3.24152 1.53013C3.60553 1.50039 4.07169 1.5 4.73333 1.5H11.2667C11.9283 1.5 12.3945 1.50039 12.7585 1.53013C13.1166 1.55939 13.3317 1.61456 13.499 1.69982C13.8439 1.87559 14.1244 2.15605 14.3002 2.50102C14.3854 2.66835 14.4406 2.88335 14.4699 3.24152C14.4996 3.60553 14.5 4.07169 14.5 4.73333V11.2667C14.5 11.9283 14.4996 12.3945 14.4699 12.7585C14.4406 13.1166 14.3854 13.3317 14.3002 13.499C14.1244 13.8439 13.8439 14.1244 13.499 14.3002C13.3317 14.3854 13.1166 14.4406 12.7585 14.4699C12.3945 14.4996 11.9283 14.5 11.2667 14.5H4.73333C4.07169 14.5 3.60553 14.4996 3.24152 14.4699C2.88335 14.4406 2.66835 14.3854 2.50102 14.3002C2.15605 14.1244 1.87559 13.8439 1.69982 13.499C1.61456 13.3317 1.55939 13.1166 1.53013 12.7585C1.50039 12.3945 1.5 11.9283 1.5 11.2667V4.73333C1.5 4.07169 1.50039 3.60553 1.53013 3.24152C1.55939 2.88335 1.61456 2.66835 1.69982 2.50102C1.87559 2.15605 2.15605 1.87559 2.50102 1.69982Z"
        fill="currentColor"
      />
    </g>
    <defs>
      <clipPath id="clip0_2976_327">
        <rect width="16" height="16" fill="white" />
      </clipPath>
    </defs>
  </g>,
  displayName
);

Info.displayName = displayName;
export default Info;
