import { createAsyncThunk } from "@reduxjs/toolkit";
import { NotificationStatus } from "components/ui/Notifications/NotificationSnackbar";
import _ from "lodash";
import { Factory } from "models";
import { getViewTypeFromAccountMappingResource } from "models/AccountMappingView";
import { oldGetViewTypeFromAccountMappingResource } from "models/OldAccountMappingView";
import Partnership from "models/Partnership";
import { JSONAPIAttributes, JSONAPIResource } from "models/types";
import { update } from "redux/api/thunks";
import { pushNotification } from "redux/notifications/actions";
import { asJSONSerializable } from "redux/typing";
import { getFiltersWithoutDisplaySince } from "screens/Frontoffice/screens/DataTables/screens/AccountMapping/components/DisplayOnlyNewUpdatesSince/utils";
import {
  asPersistedColumn,
  ColumnConfigType,
  FilterType,
  SortType,
} from "screens/Frontoffice/screens/DataTables/shared/types";
import JSONAPIService from "services/JSONAPIService";
import UserService from "services/UserService";

import { setLastVisitedAt } from "./actions";
import {
  defaultAccountMappingViewData,
  oldDefaultAccountMappingViewData,
} from "./defaults";
import {
  getFilterFieldname,
  selectHasOnlyNewUpdatesSince,
  selectOnlyNewUpdatesSinceFieldname,
} from "./selector";
import {
  AccountMappingActions,
  AccountMappingResource,
  OldRootStateWithAccountMapping,
  RootStateWithAccountMapping,
} from "./types";

export const loadView = createAsyncThunk(
  AccountMappingActions.loadView,
  async (
    {
      partnership,
      isMatchedAccount,
    }: { partnership: Partnership; isMatchedAccount?: boolean },
    thunkAPI
  ) => {
    const state = isMatchedAccount
      ? (thunkAPI.getState() as RootStateWithAccountMapping)
      : (thunkAPI.getState() as OldRootStateWithAccountMapping);
    if (state.accountMapping.views[partnership.id]?.persisted) {
      return state.accountMapping.views[partnership.id].persisted;
    }
    const service = new JSONAPIService("account_mapping_views");
    const response = await service.index({
      filters: {
        partnership: partnership.id,
      },
    });

    let recordData: JSONAPIResource<"account_mapping_views">;
    if (response.data.data.length > 0) {
      recordData = response.data.data[0];
      if (
        isMatchedAccount &&
        recordData?.attributes?.active_view_type === 3 &&
        (recordData?.attributes?.matched_accounts_columns as []).length === 0
      ) {
        recordData.attributes.matched_accounts_columns =
          defaultAccountMappingViewData.matched_accounts_columns;
      }
    } else {
      recordData = (
        await service.create(
          isMatchedAccount
            ? defaultAccountMappingViewData
            : oldDefaultAccountMappingViewData,
          {
            partnership: {
              id: String(partnership.id),
              type: "partnerships",
            },
          }
        )
      ).data.data;
    }
    const record = Factory.createRecord(recordData);

    if (state.accountMapping.views[partnership.id]?.lastVisitedAt === null) {
      await thunkAPI.dispatch(setLastVisitedAt(record.lastVisitedAt));
    }
    const userService = new UserService();
    if (!userService.isImpersonating) {
      await thunkAPI.dispatch(
        update({
          id: record.id,
          type: "account_mapping_views",
          attributes: {
            last_visited_at: new Date().toISOString(),
          },
        })
      );
    }
    return record;
  }
);

export const updateView = createAsyncThunk(
  AccountMappingActions.updateView,
  async (
    {
      accountType,
      filters,
      columns,
      sort,
      lastVisitedAt,
      partnership,
      filterOrderList,
      isMatchedAccount,
    }: {
      accountType?: AccountMappingResource;
      filters?: FilterType[];
      columns?: ColumnConfigType[];
      sort?: SortType[];
      lastVisitedAt?: Date;
      partnership?: Partnership;
      filterOrderList?: number[];
      isMatchedAccount?: boolean;
    },
    thunkAPI
  ) => {
    /**
     * Checking that the state is actually displaying an AccountMapping
     */
    let state: RootStateWithAccountMapping | OldRootStateWithAccountMapping;
    state = isMatchedAccount
      ? (thunkAPI.getState() as RootStateWithAccountMapping)
      : (thunkAPI.getState() as OldRootStateWithAccountMapping);

    const _partnership = partnership ?? state.accountMapping.currentPartnership;
    if (!_partnership) {
      throw Error("No current partnership in state");
    }
    const recordId = state.accountMapping.views[_partnership.id]?.persisted?.id;
    if (recordId === undefined) {
      throw new Error("No view available for current partnership in state");
    }
    /**
     * Preparing payload
     */
    const attributes: JSONAPIAttributes = {};

    /**
     * "Display since" toaster when it is automatically removed from the filters
     */

    const previousHasOnlyNewUpdatesSince = selectHasOnlyNewUpdatesSince(state);
    const previousOnlyNewUpdatesSinceFieldname = selectOnlyNewUpdatesSinceFieldname(
      state
    );
    const displaySinceFilterFieldname = getFilterFieldname(
      filters || [],
      accountType === AccountMappingResource.matched_accounts
    );

    if (
      filters &&
      previousHasOnlyNewUpdatesSince &&
      previousOnlyNewUpdatesSinceFieldname !== displaySinceFilterFieldname
    ) {
      thunkAPI.dispatch(
        pushNotification(
          "“Display only new updates” filter was disabled",
          undefined,
          undefined,
          "The filter was automatically disabled as you switched views. Click the toggle to enable it for this view.",
          NotificationStatus.warning
        )
      );
    }

    if (accountType !== undefined) {
      attributes["active_view_type"] = isMatchedAccount
        ? getViewTypeFromAccountMappingResource(accountType)
        : oldGetViewTypeFromAccountMappingResource(accountType);
    }

    const prefix = state.accountMapping.views[_partnership.id].accountType;

    if (filters !== undefined) {
      const hasNoDisplaySinceFilter =
        (prefix === AccountMappingResource.matches ||
          prefix === AccountMappingResource.matched_accounts) &&
        !filters
          ?.map((matchesFilter) => matchesFilter.fieldname)
          .includes(displaySinceFilterFieldname);

      attributes[prefix + "_filters"] = asJSONSerializable(
        hasNoDisplaySinceFilter
          ? getFiltersWithoutDisplaySince(filters)
          : filters
      );
    }

    if (columns !== undefined) {
      attributes[prefix + "_columns"] = asJSONSerializable(
        columns.map(asPersistedColumn)
      );
    }

    if (sort !== undefined) {
      attributes[prefix + "_sort"] = asJSONSerializable(sort);
    }

    if (lastVisitedAt !== undefined) {
      const userService = new UserService();
      if (!userService.isImpersonating) {
        attributes["last_visited_at"] = lastVisitedAt.toISOString();
      }
    }

    if (filterOrderList) {
      attributes["filter_order_list"] = filterOrderList;
    }

    /**
     * Update if needed
     */
    if (!_.isEmpty(attributes)) {
      const service = new JSONAPIService("account_mapping_views");
      const response = await service.update(recordId, attributes);
      return {
        persisted: Factory.createRecord(response.data.data),
        partnership: _partnership,
      };
    }
    return {
      persisted: state.accountMapping.views[_partnership.id].persisted,
      partnership: _partnership,
    };
  }
);
