import { FLAGS } from "config/featureFlags";

import { MaterialUITypographyConnector } from "./MaterialUITypographyConnector";
import { T as NewT } from "./Typography";

export let T = MaterialUITypographyConnector;

if (FLAGS.redesign) {
  T = NewT;
}
