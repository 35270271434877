import { createReducer } from "@reduxjs/toolkit";
import { addCreatedPartner } from "redux/dashboard/actions";
import { initializePartnershipsAndCompanyNames } from "redux/init/thunks";
import { logout } from "redux/user/thunks";

import { setSearch } from "./actions";
import { DashboardState } from "./types";

const initState: DashboardState = {
  search: {
    value: "",
    index: null,
  },
  overlapsReady: false,
  upsidePartnershipIds: [],
};

const reducer = createReducer(initState, (builder) =>
  builder
    .addCase(initializePartnershipsAndCompanyNames.pending, (state) => {
      state.search.value = "";
    })
    .addCase(
      initializePartnershipsAndCompanyNames.fulfilled,
      (state, action) => {
        state.search.index = action.payload.index;
        state.upsidePartnershipIds = action.payload.upsidePartnershipIds;
      }
    )
    .addCase(addCreatedPartner, (state, action) => {
      !!action.payload.partnerName &&
        state.search.index?.add({
          id: action.payload.id,
          partnerName: action.payload.partnerName,
        });
    })
    .addCase(setSearch, (state, action) => {
      state.search.value = action.payload;
    })
    .addCase(logout.fulfilled, () => initState)
);

export default reducer;
