import ClearRoundedIcon from "@mui/icons-material/ClearRounded";
import CrmFieldSelectDropdown from "components/ui/SWQL/CrmFieldSelectDropdown";
import SWQLBooleanNode from "components/ui/SWQL/SWQLBooleanNode";
import { SWQLContextProvider } from "components/ui/SWQL/SWQLContext";
import { SWQLField, SWQLNode, SWQLTarget } from "components/ui/SWQL/SWQLTypes";
import { T } from "components/ui/Typography";
import { makeStyles } from "makeStyles";
import CrmField from "models/CrmField";
import { Kind } from "models/PartnerConnection";
import { defineMessages, FormattedHTMLMessage } from "react-intl";

type Props = {
  kind: Kind;
  swqlFilterRule: SWQLNode;
  partnerField: SWQLField | null;
  partnerManagerField: SWQLField | null;
  integrationId: number;
  swqlFieldOptions: SWQLField[];
  onChangeSwql: (node: SWQLNode) => void;
  onPartnerChange: (field: SWQLField | null) => void;
  onPartnerManagerChange: (field: SWQLField | null) => void;
};

export const TrackingSettingsForKind = ({
  kind,
  swqlFilterRule,
  partnerField,
  partnerManagerField,
  integrationId,
  swqlFieldOptions,
  onChangeSwql,
  onPartnerChange,
  onPartnerManagerChange,
}: Props) => {
  const { classes, cx } = useStyles();

  const title =
    kind === Kind.SOURCING
      ? i18n.titleSourcing
      : kind === Kind.INFLUENCE
      ? i18n.titleInfluence
      : i18n.titleOther;

  const partner =
    kind === Kind.SOURCING
      ? i18n.sourcingPartner
      : kind === Kind.INFLUENCE
      ? i18n.influencingPartner
      : i18n.otherPartner;

  return (
    <div className={classes.root}>
      <T h4 className={classes.title}>
        <FormattedHTMLMessage {...title} />
      </T>
      <SWQLContextProvider
        integrationId={integrationId}
        rootNode={swqlFilterRule}
        onRootUpdate={onChangeSwql}
        swqlTarget={[SWQLTarget.RawOpportunity]}
        fields={swqlFieldOptions}
        enableDateTimes={true}
      >
        <SWQLBooleanNode pathToNode={[]} />
        <div className={classes.partnerDefinitionBlock}>
          <T className={classes.partnerDefinitionBlockTitle}>
            <FormattedHTMLMessage {...partner} />
          </T>
          <CrmFieldSelectDropdown
            value={partnerField}
            handleChange={onPartnerChange}
            allowEmptyValue
            swTypes={[CrmField.TYPE_REFERENCES_ACCOUNT]}
          />
          {partnerField && (
            <div
              className={cx(classes.deleteBtn, "showOnHover")}
              onClick={() => onPartnerChange(null)}
            >
              <ClearRoundedIcon style={{ width: 15, height: 15 }} />
            </div>
          )}
        </div>
      </SWQLContextProvider>
      {partnerField && (
        <SWQLContextProvider
          integrationId={integrationId}
          rootNode={swqlFilterRule}
          onRootUpdate={onChangeSwql}
          swqlTarget={[SWQLTarget.RawOpportunity, SWQLTarget.RawCompany]}
          fields={swqlFieldOptions}
          enableDateTimes={true}
        >
          <div className={classes.partnerDefinitionBlock}>
            <T className={classes.partnerDefinitionBlockTitle}>
              <FormattedHTMLMessage {...i18n.partnerManager} />
            </T>
            <CrmFieldSelectDropdown
              value={partnerManagerField}
              handleChange={onPartnerManagerChange}
              allowEmptyValue
              partnerField={partnerField}
              size="normal"
              swTypes={[CrmField.TYPE_REFERENCES_USER]}
            />
            {partnerManagerField && (
              <div
                className={cx(classes.deleteBtn, "showOnHover")}
                onClick={() => onPartnerManagerChange(null)}
              >
                <ClearRoundedIcon style={{ width: 15, height: 15 }} />
              </div>
            )}
          </div>
        </SWQLContextProvider>
      )}
    </div>
  );
};

const useStyles = makeStyles()((theme) => ({
  root: {
    width: "100%",
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    marginBottom: theme.spacing(2),
    borderTop: `1px solid ${theme.palette.taupe500}`,
  },
  title: {
    marginBottom: theme.spacing(2),
  },
  partnerDefinitionBlock: {
    display: "flex",
    alignItems: "center",
    marginTop: theme.spacing(2),
    marginLeft: theme.spacing(1),
  },
  partnerDefinitionBlockTitle: {
    marginRight: theme.spacing(1),
  },
  deleteBtn: {
    display: "flex !important",
    alignItems: "center",
    justifyContent: "center",
    color: theme.palette.midnight,
    marginLeft: theme.spacing(1),
    width: 28,
    backgroundColor: theme.palette.taupe500,
    height: 28,
    borderRadius: 50,
    "&:hover": {
      cursor: "pointer",
    },
  },
}));

const i18n = defineMessages({
  titleSourcing: {
    id:
      "Settings.SourceSettings.Widgets.OpportunitiesTrackingWidget.TrackingSettingsForKind.titleSourcing",
    defaultMessage: "Opportunities are <b>Partner Sourced</b> when:",
  },
  titleInfluence: {
    id:
      "Settings.SourceSettings.Widgets.OpportunitiesTrackingWidget.TrackingSettingsForKind.titleInfluence",
    defaultMessage: "Opportunities are <b>Partner Influenced</b> when:",
  },
  titleOther: {
    id:
      "Settings.SourceSettings.Widgets.OpportunitiesTrackingWidget.TrackingSettingsForKind.titleOther",
    defaultMessage: 'Opportunities are <b>"Partner - Other"</b> when:',
  },
  sourcingPartner: {
    id:
      "Settings.SourceSettings.Widgets.OpportunitiesTrackingWidget.TrackingSettingsForKind.sourcingPartner",
    defaultMessage: "<b>Sourcing partner</b> is logged in Salesforce as",
  },
  influencingPartner: {
    id:
      "Settings.SourceSettings.Widgets.OpportunitiesTrackingWidget.TrackingSettingsForKind.influencingPartner",
    defaultMessage: "<b>Influencing partner</b> is logged in Salesforce as",
  },
  otherPartner: {
    id:
      "Settings.SourceSettings.Widgets.OpportunitiesTrackingWidget.TrackingSettingsForKind.otherPartner",
    defaultMessage: "<b>Partner</b> is logged in Salesforce as",
  },
  partnerManager: {
    id:
      "Settings.SourceSettings.Widgets.OpportunitiesTrackingWidget.TrackingSettingsForKind.partnerManager",
    defaultMessage: "<b>Partner manager</b> is logged in Salesforce as",
  },
  selectCrmField: {
    id:
      "Settings.SourceSettings.Widgets.OpportunitiesTrackingWidget.TrackingSettingsForKind.selectCrmField",
    defaultMessage: "select a CRM field",
  },
});
