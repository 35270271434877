import { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { selectFields, selectTableColumns } from "redux/analytics/selectors";
import { updateView } from "redux/analytics/thunks";
import {
  ColumnConfigType,
  SortType,
} from "screens/Frontoffice/screens/DataTables/shared/types";

import { AnalyticsViewParameters } from "../../../constants";

const useAnalyticsTableAPILogic = () => {
  const dispatch = useDispatch();
  const fields = useSelector(selectFields);
  const columns = useSelector(selectTableColumns);

  const setView = useCallback(
    (value: AnalyticsViewParameters) => dispatch(updateView(value)),
    [dispatch]
  );

  const setColumns = useCallback(
    (columns: ColumnConfigType[]) => {
      setView({ columns });
    },
    [setView]
  );

  const setSort = (value: SortType[]) => {
    setView({ sort: value });
  };

  return {
    fields,
    columns,
    setView,
    setSort,
    setColumns,
  };
};

export default useAnalyticsTableAPILogic;
