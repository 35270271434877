import { T } from "components/ui/Typography";
import useAvailableRoles from "hooks/useAvailableRoles";
import { makeStyles } from "makeStyles";
import { defineMessages, FormattedMessage } from "react-intl";

import { hasNotBeenInvitedOrWasMoreThan1DayAgo } from "../shared/utils";

export const RoleCell = ({ dataItem }: $TSFixMe) => {
  const { classes, cx } = useCellStyles();
  const availableRoles = useAvailableRoles(true);

  const roles = dataItem.roleAssignments;
  const currentRole = roles?.length ? roles[0].role : null;
  const canResendInvite = hasNotBeenInvitedOrWasMoreThan1DayAgo(
    dataItem.invitationSentAt
  );

  if (dataItem.pendingInvitation) {
    return (
      <T className={cx({ [classes.disabled]: !canResendInvite })}>
        <FormattedMessage
          {...i18n.pendingInvitation}
          values={{ name: dataItem.invitedBy.fullName }}
        />
      </T>
    );
  }
  if (currentRole !== null) {
    const role = availableRoles.find(
      ({ name }: { name: string }) => name === currentRole
    );
    return (
      <T className={cx({ [classes.disabled]: !dataItem.hasLicense })}>
        {role?.label ?? currentRole}
      </T>
    );
  }
  return (
    <T className={classes.disabled}>
      <FormattedMessage {...i18n.pendingApproval} />
    </T>
  );
};

/// CSS

const useCellStyles = makeStyles()((theme) => ({
  disabled: {
    opacity: "50%",
  },
}));

/// I18N

const i18n = defineMessages({
  pendingInvitation: {
    id: "Users.RoleCell.PendingInvitation",
    defaultMessage: "Invited by {name}",
  },
  pendingApproval: {
    id: "Users.RoleCell.PendingApproval",
    defaultMessage: "Pending approval",
  },
});
