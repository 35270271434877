import { QuickViewFilter } from "components/ui/BaseQuickViews";
import useHasPayingFeature from "hooks/useHasPayingFeature";
import useUserProfile from "hooks/useUserProfile";
import { PayingFeature } from "models/CompanyPayingFeatureSubscription";
import { useSelector } from "react-redux";
import { selectViewForCurrentPartnership } from "redux/accountMapping/selector";
import { updateView as updateViewAM } from "redux/accountMapping/thunks";
import { AccountMappingResource } from "redux/accountMapping/types";
import { selectView as selectView360 } from "redux/mapping360/selectors";
import { updateView as updateView360 } from "redux/mapping360/thunks";
import { selectView as selectViewNearboundAccounts } from "redux/mapping360NearboundAccounts/selectors";
import { updateView as updateViewNearboundAccounts } from "redux/mapping360NearboundAccounts/thunks";

import { ExtendedAccountMappingResource } from "./constants";

type Props = {
  accountType: ExtendedAccountMappingResource;
  selectedFilterId?: number;
};

export const useSmartViewTableConfig = ({ accountType }: Props) => {
  const { profile } = useUserProfile();
  const is360NearboundAccountsUnlocked = useHasPayingFeature(
    PayingFeature.UseNew360,
    profile
  );
  const isAMView = Object.values(AccountMappingResource).includes(
    accountType as AccountMappingResource
  );
  const { tableConfig: viewAM } = useSelector(selectViewForCurrentPartnership);
  const view360 = useSelector(
    is360NearboundAccountsUnlocked ? selectViewNearboundAccounts : selectView360
  );

  type UpdateViewAMParams = Parameters<typeof updateViewAM>[0];
  type UpdateView360Params = Parameters<typeof updateViewNearboundAccounts>[0];

  const updateViewThunk = (
    params: UpdateViewAMParams | UpdateView360Params
  ) => {
    return isAMView
      ? updateViewAM(params)
      : is360NearboundAccountsUnlocked
      ? updateViewNearboundAccounts({ ...params, id: Number(view360?.id) })
      : updateView360({ ...params, id: Number(view360?.id) });
  };

  const view = isAMView ? viewAM : view360;

  return { orderArray: view?.filterOrderList ?? [], updateViewThunk, view };
};

export const quickViewsForSize = (
  filterList: QuickViewFilter[],
  sizeInPixels: number,
  additionalItem: QuickViewFilter
): QuickViewFilter[] => {
  let filterListSize = 0;
  let adaptedFilterListForSize: QuickViewFilter[] = [];
  for (let filter of filterList) {
    if (filter.name) {
      const nameLength =
        typeof filter.name === "string"
          ? filter.name.length
          : (filter.name.defaultMessage?.length ?? 0) + 8;
      filterListSize = filterListSize + 60 + 5 * nameLength; // 60 px ~= space between items + record count display width
    }
    if (filterListSize < sizeInPixels) {
      adaptedFilterListForSize.push(filter);
    }
  }
  adaptedFilterListForSize.push(additionalItem);
  return adaptedFilterListForSize;
};
