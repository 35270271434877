import { Check, Limit, Lock } from "components/icons";
import Tag from "components/ui/Tag";
import { makeStyles } from "makeStyles";
import {
  FEATURE_STATUS_LABELS,
  FeatureStatus,
} from "models/CompanyPayingFeatureSubscription";
import { defineMessages, FormattedMessage } from "react-intl";

type Props = {
  status: FeatureStatus;
  onHeader?: boolean;
  isProgressive?: boolean;
  limit?: number;
  currentValue?: number;
};

export const WidgetTag = ({
  status,
  onHeader = false,
  isProgressive = false,
  limit,
  currentValue,
}: Props) => {
  const isLocked = status === FeatureStatus.locked && !limit;
  const { classes } = useStyles({ isLocked });
  const Icon = getTagIcon(status, limit);

  return (
    <Tag
      isSmall
      disabledUppercase
      icon={<Icon className={classes.icon} />}
      variant={getTagVariant(status, onHeader)}
      label={getTagLabel(status, isProgressive, limit, currentValue)}
    />
  );
};

const getTagVariant = (status: FeatureStatus, onHeader: boolean) => {
  switch (status) {
    case FeatureStatus.limited:
    case FeatureStatus.locked:
      return onHeader ? "limited" : "dark";
    case FeatureStatus.unlimited:
    case FeatureStatus.trial:
      return onHeader ? "unlimited" : "dark";
  }
};

const getTagIcon = (status: FeatureStatus, limit?: number) => {
  switch (status) {
    case FeatureStatus.limited:
      return Limit;
    case FeatureStatus.locked:
      return limit ? Limit : Lock;
    case FeatureStatus.unlimited:
    case FeatureStatus.trial:
      return Check;
  }
};

const getTagLabel = (
  status: FeatureStatus,
  isProgressive: boolean,
  limit?: number,
  currentValue?: number
) => {
  if (status === FeatureStatus.limited || status === FeatureStatus.locked) {
    if (isProgressive) {
      if ((currentValue || currentValue === 0) && limit) {
        return (
          <FormattedMessage
            {...i18n.outOfLimit}
            values={{ currentValue, limit }}
          />
        );
      }
    } else if (limit) {
      return <FormattedMessage {...i18n.upToLimit} values={{ limit }} />;
    }
  }
  return FEATURE_STATUS_LABELS[status];
};

/// CSS

const useStyles = makeStyles<{ isLocked: boolean }>()(
  (theme, { isLocked }) => ({
    icon: {
      fontSize: isLocked ? 13 : 18,
    },
  })
);

/// I18N

const i18n = defineMessages({
  upToLimit: {
    id: "navbar.PremiumPlanWidget.WidgetTag.upToLimit",
    defaultMessage: "Up to {limit} {limit, plural, one {item} other {items}}",
  },
  outOfLimit: {
    id: "navbar.PremiumPlanWidget.WidgetTag.outOfLimit",
    defaultMessage: "{currentValue} out of {limit}",
  },
});
