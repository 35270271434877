import DialogManager from "components/ui/DialogManager";
import { ProviderType } from "config/constants";
import useOauthState from "hooks/useOauthState";

import { useGsheetAuthDialogContent } from "../hooks/useGsheetAuthDialogContent";

type Props = {
  onClose: () => void;
  open?: boolean;
};

export const GsheetAuthDialog = ({ open, onClose }: Props) => {
  const { start } = useOauthState();

  const handleClose = () => {
    onClose();
  };

  const handleClick = () => {
    start(ProviderType.gsheet, next);
  };
  const { dialogContent, dialogTitle, next } = useGsheetAuthDialogContent({
    handleClick,
  });

  return (
    <DialogManager
      fullScreenBreakpoint="sm"
      DialogTitleComponent={dialogTitle}
      DialogContentComponent={dialogContent}
      handleClose={handleClose}
      isOpen={Boolean(open)}
    />
  );
};
