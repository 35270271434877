import { createReducer } from "@reduxjs/toolkit";
import { castDraft } from "immer";
import _ from "lodash";

import {
  setAccountType,
  setLastVisitedAt,
  setSelectedSourceId,
} from "./actions";
import { loadView, updateView } from "./thunks";
import {
  AccountMappingResource,
  OldPartnershipAccountMappingState,
  PartnershipAccountMappingState,
} from "./types";

const OldDefaultInitState = {
  accountType: AccountMappingResource.matches,
  selectedSourceId: null,
  persisted: undefined,
  lastVisitedAt: null,
};

const defaultInitState = {
  accountType: AccountMappingResource.matched_accounts,
  selectedSourceId: null,
  persisted: undefined,
  lastVisitedAt: null,
};

export default createReducer(
  {
    views: {},
    currentPartnership: null,
  } as PartnershipAccountMappingState | OldPartnershipAccountMappingState,
  (builder) =>
    builder
      .addCase(setAccountType, (state, action) => {
        if (
          state.currentPartnership &&
          state.views[state.currentPartnership.id]
        ) {
          state.views[state.currentPartnership.id].accountType = action.payload;
        }
      })
      .addCase(setSelectedSourceId, (state, action) => {
        if (
          state.currentPartnership &&
          state.views[state.currentPartnership.id]
        ) {
          if (
            action.payload === null ||
            state.currentPartnership.integrations.find(
              (integration) => integration.id === action.payload
            )
          ) {
            state.views[state.currentPartnership.id].selectedSourceId =
              action.payload;
          }
        }
      })
      .addCase(setLastVisitedAt, (state, action) => {
        if (
          state.currentPartnership &&
          state.views[state.currentPartnership.id]
        ) {
          state.views[state.currentPartnership.id].lastVisitedAt =
            action.payload;
        }
      })
      .addCase(loadView.pending, (state, action) => {
        // @ts-ignore Type instantiation is excessively deep and possibly infinite.ts(2589)
        state.currentPartnership = action.meta.arg.partnership;
        // @ts-ignore Type instantiation is excessively deep and possibly infinite.ts(2589)
        const currentId = state.currentPartnership?.id;
        if (currentId) {
          state.views[currentId] =
            state.views[currentId] ??
            _.cloneDeep(
              action.meta.arg.isMatchedAccount
                ? defaultInitState
                : OldDefaultInitState
            );
        }
      })
      .addCase(loadView.fulfilled, (state, action) => {
        // @ts-ignore Type instantiation is excessively deep and possibly infinite.ts(2589)
        state.views[action.meta.arg.partnership.id] = {
          ...(state.views[action.meta.arg.partnership.id] ??
            (action.meta.arg.isMatchedAccount
              ? defaultInitState
              : OldDefaultInitState)),
          persisted: castDraft(action.payload),
          accountType:
            action.payload?.activeViewType ??
            (action.meta.arg.isMatchedAccount
              ? AccountMappingResource.matched_accounts
              : AccountMappingResource.matches),
        };
      })
      .addCase(updateView.pending, (state, action) => {
        const partnership =
          // @ts-ignore excessive stack
          action.meta.arg.partnership === undefined
            ? state.currentPartnership
            : action.meta.arg.partnership;
        if (partnership) {
          const persisted = state.views[partnership.id].persisted;
          if (persisted) {
            const prefix = _.camelCase(state.views[partnership.id].accountType);
            persisted[prefix + "Columns"] =
              action.meta.arg.columns ?? persisted[prefix + "Columns"];
            persisted[prefix + "Filters"] =
              action.meta.arg.filters ?? persisted[prefix + "Filters"];
            persisted[prefix + "Sort"] =
              action.meta.arg.sort ?? persisted[prefix + "Sort"];
            persisted.lastVisitedAt =
              action.meta.arg.lastVisitedAt ?? persisted.lastVisitedAt;
            state.views[partnership.id].persisted = persisted;
            state.views[partnership.id].accountType =
              action.meta.arg.accountType ?? persisted.activeViewType;
          }
        }
      })
      .addCase(updateView.fulfilled, (state, action) => {
        const partnership =
          action.meta.arg.partnership === undefined
            ? state.currentPartnership
            : action.meta.arg.partnership;
        if (partnership !== null) {
          state.views[partnership.id].persisted = castDraft(
            action.payload.persisted
          );
          state.views[partnership.id].accountType =
            action.payload?.persisted?.activeViewType ??
            (action.meta.arg.isMatchedAccount
              ? AccountMappingResource.matched_accounts
              : AccountMappingResource.matches);
        }
      })
);
