import _ from "lodash";

import {
  JSONAPIAttributes,
  JSONAPIRelationships,
  JSONAPIResource,
} from "./types";

export class Record<R = string> implements Omit<JSONAPIResource<R>, "id"> {
  readonly id: number;
  readonly type: R;
  readonly attributes: JSONAPIAttributes;
  readonly relationships: JSONAPIRelationships;
  [relationOrAttribute: string]: Record | Record[] | $TSFixMe;

  constructor({
    id,
    type,
    attributes = {},
    relationships = {},
  }: JSONAPIResource<R> | Record<R>) {
    this.id = +id;
    this.type = type;
    this.attributes = attributes;
    this.relationships = relationships;

    // Set Attributes
    const self = this;
    Object.keys(attributes).forEach(
      (key) => (self[_.camelCase(key)] = attributes[key])
    );

    // Set Related Records as attributes
    Object.keys(relationships).forEach((key) => {
      const camelKey = _.camelCase(key);
      const value = relationships[key].data;
      self[camelKey] = value;
      self[`has${_.upperFirst(camelKey)}`] = () => {
        if (Array.isArray(value)) {
          return value.length > 0;
        }
        return Boolean(relationships[key].data);
      };
    });
  }

  toString() {
    return `[Record: type=${this.type} id=${this.id}]`;
  }
}

export default Record;
