import { createSvgIcon } from "@mui/material/utils";

import { SvgIcon } from "./types";

const displayName = "GoogleSheets";

const GoogleSheets: SvgIcon = createSvgIcon(
  <g transform="matrix(1.5,0,0,1.5,0,0)">
    <g clip-path="url(#clip0_1802_1293)">
      <rect width="16" height="16" rx="2" fill="#0F9D58" />
      <path d="M2 7.37931L2 5.31034L14 5.31034V7.37931H2Z" fill="white" />
      <path d="M5.72414 2H7.7931V14H5.72414V2Z" fill="white" />
    </g>
    <defs>
      <clipPath id="clip0_1802_1293">
        <rect width="16" height="16" rx="2" fill="white" />
      </clipPath>
    </defs>
  </g>,
  displayName
);

GoogleSheets.displayName = displayName;
export default GoogleSheets;
