import { Grid } from "@mui/material";
import { Send } from "components/icons";
import Alert from "components/ui/Alert";
import Button from "components/ui/Button";
import { TextInput } from "components/ui/TextInput";
import { T } from "components/ui/Typography";
import { reCaptchaSiteKey } from "config/constants";
import { Formik } from "formik";
import usePushNotification from "hooks/usePushNotification";
import forms from "i18n/forms";
import { makeStyles } from "makeStyles";
import { useRef } from "react";
import ReCAPTCHA from "react-google-recaptcha";
import { FormattedMessage, useIntl } from "react-intl";
import LinkToRegister from "screens/Frontoffice/shared/components/LinkToRegister";
import UserService from "services/UserService";
import { object, string } from "yup";

import i18n from "./i18n";

/** {{{ Formik Functions & Configs */

export const schema = object().shape({
  email: string()
    .required(() => forms.email_required)
    .email(() => forms.email_invalid),
});

export const handleSubmit = (
  { email }: { email: string },
  { props }: $TSFixMe
) => {
  props.triggerResetTokenProcess(email);
};

type ResetPasswordInitFormProps = {
  onSuccess: () => void;
};

/** }}} */

export const ResetPasswordInitForm = ({
  onSuccess,
}: ResetPasswordInitFormProps) => {
  const { classes, cx } = useStyles();
  const pushNotification = usePushNotification();
  const intl = useIntl();
  const recaptchaRef = useRef<ReCAPTCHA>(null);

  const requestPasswordReset = async ({ email }: { email: string }) => {
    // @ts-expect-error ts-migrate(2532) FIXME: Object is possibly 'undefined'.
    const recaptcha_token = await recaptchaRef.current.executeAsync();
    if (recaptcha_token) {
      const services = new UserService();
      const triggered = await services.triggerResetPassword(
        email,
        recaptcha_token
      );
      if (triggered) {
        onSuccess();
      } else {
        pushNotification("default_error");
      }
    } else {
      pushNotification("default_error");
    }
  };

  return (
    <>
      <Grid item className={classes.fullWidth}>
        <T h2 bold textAlign="center">
          <FormattedMessage {...i18n.resetPwdTitle} />
        </T>
      </Grid>
      <Grid item className={classes.fullWidth}>
        <T textAlign="center" className={classes.gutterBottom}>
          <FormattedMessage {...i18n.instructionsTypeEmail} />
        </T>
      </Grid>
      <Formik
        initialValues={{ email: "" }}
        onSubmit={requestPasswordReset}
        validationSchema={schema}
        validateOnMount
      >
        {({
          errors,
          touched,
          handleChange,
          handleSubmit,
          handleBlur,
          isSubmitting,
          values,
        }) => (
          <form onSubmit={handleSubmit}>
            {touched.email && errors.email !== undefined && (
              <Alert variant="error" icon className={classes.gutterBottom}>
                <FormattedMessage {...errors.email} />
              </Alert>
            )}
            <TextInput
              variant="tertiary"
              name="email"
              fullWidth
              placeholder={intl.formatMessage(i18n.enterEmailPh)}
              className={classes.gutterBottom}
              onBlur={handleBlur}
              onChange={handleChange}
              disabled={isSubmitting}
              required
              value={values.email}
              inputProps={{
                "data-testid": "reset-password-email-input",
              }}
              error={Boolean(errors.email && touched.email)}
            />
            <Button
              type="submit"
              label={i18n.sendInstructionsBtn}
              RightIcon={Send}
              loading={isSubmitting}
              classes={{ btn: cx(classes.fullWidth, classes.gutterBottom) }}
            />
            <LinkToRegister />
          </form>
        )}
      </Formik>
      <ReCAPTCHA
        ref={recaptchaRef}
        sitekey={reCaptchaSiteKey ?? ""}
        size="invisible"
      />
    </>
  );
};

export default ResetPasswordInitForm;

// CSS

const useStyles = makeStyles()((theme) => ({
  fullWidth: {
    width: "100%",
  },
  gutterBottom: {
    marginBottom: 24,
    [theme.breakpoints.down("md")]: {
      marginBottom: 16,
    },
  },
}));
