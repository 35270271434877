import { createSvgIcon } from "@mui/material/utils";

import { SvgIcon } from "./types";

const displayName = "Calendar";

const Calendar: SvgIcon = createSvgIcon(
  // Using transform to enlarge from provided viewbox="0 0 16 16"
  // to default "0 0 24 24" from `createSvgIcon` -> `SvgIcon`.
  <g fill="none" transform="matrix(1.5,0,0,1.5,0,0)">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5 2C5.24853 2 5.45 2.20147 5.45 2.45V3.05H10.55V2.45C10.55 2.20147 10.7515 2 11 2C11.2485 2 11.45 2.20147 11.45 2.45V3.05H12.2C13.1941 3.05 14 3.85589 14 4.85V12.05C14 13.0441 13.1941 13.85 12.2 13.85H3.8C2.80589 13.85 2 13.0441 2 12.05V4.85C2 3.85589 2.80589 3.05 3.8 3.05H4.55V2.45C4.55 2.20147 4.75147 2 5 2ZM10.55 3.95V4.85C10.55 5.09853 10.7515 5.3 11 5.3C11.2485 5.3 11.45 5.09853 11.45 4.85V3.95H12.2C12.6971 3.95 13.1 4.35294 13.1 4.85V6.8H2.9V4.85C2.9 4.35294 3.30294 3.95 3.8 3.95H4.55V4.85C4.55 5.09853 4.75147 5.3 5 5.3C5.24853 5.3 5.45 5.09853 5.45 4.85V3.95H10.55ZM2.9 7.7V12.05C2.9 12.5471 3.30294 12.95 3.8 12.95H12.2C12.6971 12.95 13.1 12.5471 13.1 12.05V7.7H2.9Z"
      fill="currentColor"
    />
  </g>,
  displayName
);

Calendar.displayName = displayName;

export default Calendar;
