import Divider from "@mui/material/Divider";
import Grid from "@mui/material/Grid";
import Button from "components/ui/Button";
import Dialog from "components/ui/Dialog";
import _ from "lodash";
import { makeStyles } from "makeStyles";
import { MouseEvent, useCallback, useState } from "react";
import { defineMessages } from "react-intl";
import {
  FieldType,
  FilterType,
} from "screens/Frontoffice/screens/DataTables/shared/types";
import Filters from "screens/Frontoffice/shared/components/Filters";

type Props = {
  open: boolean;
  filters: FilterType[];
  fields: Record<string, FieldType>;
  onCancel: (event?: MouseEvent) => void;
  onChange: (filters: FilterType[]) => void;
  partnerName: string;
};

const OverviewFilters = ({
  open,
  filters,
  fields,
  onChange,
  onCancel,
  partnerName,
}: Props) => {
  const [currentFilters, setCurrentFilters] = useState<FilterType[]>(filters);
  const { classes } = useStyles();

  const applyChanges = useCallback(
    (event: MouseEvent) => {
      event.stopPropagation();
      onChange(currentFilters);
    },
    [onChange, currentFilters]
  );

  return (
    <Dialog
      isOpen={open}
      title={i18n.accountOverlapsFilters}
      maxWidth="md"
      onClose={onCancel}
    >
      <Grid container spacing={2} direction="column" className={classes.root}>
        <Grid item>
          <Filters
            fields={fields}
            partnerName={partnerName}
            filters={currentFilters}
            onChange={setCurrentFilters}
            displayEmpty={currentFilters.length === 0}
          />
        </Grid>
        <Grid item className={classes.divider}>
          <Divider />
        </Grid>
        <Grid item>
          <div className={classes.actions}>
            <Button
              label={i18n.cancel}
              onClick={onCancel}
              variant="secondary"
            />
            <Button
              label={i18n.apply}
              onClick={applyChanges}
              disabled={_.isEqual(filters, currentFilters)}
            />
          </div>
        </Grid>
      </Grid>
    </Dialog>
  );
};

export default OverviewFilters;

/// CSS

const useStyles = makeStyles()(() => ({
  root: {
    padding: 24,
    paddingBottom: 0,
  },
  divider: {
    paddingTop: 16,
  },
  actions: {
    display: "flex",
    justifyContent: "flex-end",
    gap: 16,
  },
}));

/// I18N

const i18n = defineMessages({
  accountOverlapsFilters: {
    id: "Overview.OverviewFilters.accountOverlapsFilters",
    defaultMessage: "Account Overlaps filters",
  },
  apply: {
    id: "Overview.OverviewFilters.apply",
    defaultMessage: "Apply",
  },
  cancel: {
    id: "Overview.OverviewFilters.cancel",
    defaultMessage: "Cancel",
  },
});
