import { Box } from "@mui/material";
import Grid from "@mui/material/Grid";
import { Copy } from "components/icons";
import CompanyAvatar from "components/ui/avatars/CompanyAvatar";
import Tooltip from "components/ui/Tooltip";
import { T } from "components/ui/Typography";
import useSegment from "hooks/useSegment";
import useUserProfile from "hooks/useUserProfile";
import { makeStyles } from "makeStyles";
import Partnership from "models/Partnership";
import ModelRecord from "models/Record";
import { ReactNode, useEffect, useState } from "react";
import {
  defineMessages,
  FormattedMessage,
  IntlShape,
  useIntl,
} from "react-intl";
import { useSelector } from "react-redux";
import { selectAllPartnerships } from "redux/api/selectors";
import { CustomerPresence } from "screens/Frontoffice/screens/DataTables/screens/Mapping360/types";
import { DetailedViewType } from "screens/Frontoffice/screens/DataTables/shared/types";
import { WebhookEventName, WebhookService } from "services/WebhookService";

import { AddToPipelineButtonNearboundProspects } from "./AddToPipelineButtonNearboundProspects";
import { TextWithCopyIcon } from "./TextWithCopyIcon";

type Props = {
  account: CustomerPresence;
  nearboundProspect: ModelRecord<"nearbound_prospects">;
};

export const PartnerAccountCardNearboundProspects = ({
  account,
  nearboundProspect,
}: Props) => {
  const { classes, cx } = useStyles();
  const intl = useIntl();
  const { track } = useSegment();
  const service = new WebhookService();
  const { profile } = useUserProfile();
  const partnerships = useSelector(selectAllPartnerships) as Partnership[];
  const [copied, setCopied] = useState(false);
  const partnership = partnerships.find(
    (partnership) => partnership.id === account.partnership_id
  );

  const partnershipOwner = partnership?.partnerPartnershipOwner;

  useEffect(() => {
    if (copied) {
      const timer = setTimeout(() => setCopied(false), 2000);
      return () => clearTimeout(timer);
    }
  }, [copied]);

  const handleTrackEmail = () => {
    setCopied(true);
    service.track({
      profile: profile,
      eventName: WebhookEventName.RevealCopiedAEEmailFromAccountDetailDrawer,
      partnership: partnership,
      nearboundProspect: nearboundProspect,
    });
    track("Copied account owner email", {
      source: DetailedViewType.ACCOUNT_DRAWER,
    });
  };

  return (
    <Grid container className={classes.partnerCard} spacing={2}>
      <Grid item className={classes.title} xs={12}>
        <CompanyAvatar
          company={partnership?.getPartner(profile)}
          classes={{ avatar: classes.avatar }}
        />
        <Box flexGrow={1}>
          <T className={classes.companyNameTitle}>
            {partnership?.getPartner(profile).name}
          </T>
          <Box display="flex" gap="4px">
            <T className={classes.label}>
              <FormattedMessage {...i18n.partnershipOwner} />
            </T>
            {partnershipOwner && (
              <Box className={classes.ownerContainer}>
                <T bodySmall>
                  {partnershipOwner.email ? (
                    <TextWithCopyIcon
                      email={partnershipOwner.email}
                      handleTrack={handleTrackEmail}
                      hoverText={intl.formatMessage(i18n.copyEmail, {
                        email: partnershipOwner.email,
                      })}
                      text={partnershipOwner.fullName}
                    />
                  ) : (
                    <span>{partnershipOwner.fullName}</span>
                  )}
                </T>
              </Box>
            )}
          </Box>
        </Box>
        {profile.ownsPipeline && (
          <AddToPipelineButtonNearboundProspects
            nearboundProspect={nearboundProspect}
            partnership={partnership}
          />
        )}
      </Grid>
      <Grid item className={classes.content}>
        <Grid
          container
          spacing={2}
          direction="column"
          margin="unset"
          width="unset"
        >
          <Grid item className={classes.rowLabelContainer}>
            <T className={cx(classes.label, classes.rowLabel)}>
              <FormattedMessage
                {...i18n.accountOwner}
                values={{ name: nearboundProspect.name }}
              />
            </T>
            <Box>
              {getOwners(
                intl,
                account,
                nearboundProspect,
                classes,
                handleTrackEmail,
                copied
              ).map((label) => label)}
            </Box>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

// CSS

const useStyles = makeStyles()((theme) => ({
  content: {
    padding: theme.spacing(2),
  },
  ownerContainer: {
    alignItems: "center",
    display: "flex",
    gap: 4,
    height: 18,
  },
  ownerCopyContainer: {
    alignItems: "center",
    borderRadius: 4,
    cursor: "pointer",
    display: "flex",
    gap: 4,
    padding: "0 1px",
    "&:hover": {
      background: theme.palette.greyscale100,
    },
  },
  avatar: {
    borderRadius: 6.66,
    height: 33.3,
    width: 33.3,
  },
  companyNameTitle: {
    color: theme.palette.midnight,
    fontWeight: 600,
    fontSize: 14,
    height: 21,
    lineHeight: "150%",
  },
  label: {
    color: theme.palette.alpha500,
  },
  link: {
    color: theme.palette.purple,
    fontWeight: 400,
    fontSize: 12,
    lineHeight: "150%",
  },
  partnerCard: {
    flexDirection: "column",
    flexWrap: "nowrap",
    margin: 0,
    borderRadius: 8,
    border: `1px solid ${theme.palette.greyLight100}`,
    width: "100%",
  },
  title: {
    alignItems: "center",
    background: theme.palette.greyLight050Alpha050,
    display: "flex",
    flexDirection: "row",
    gap: 6,
    padding: "10px 10px !important",
  },
  rowLabel: {
    flexGrow: 1,
    maxWidth: 216,
  },
  copyIcon: {
    color: theme.palette.midnight,
    height: 12,
    width: 12,
  },
  rowLabelContainer: {
    display: "flex",
    gap: 8,
    paddingLeft: "0 !important",
    paddingTop: "0 !important",
  },
}));

// I18N

const i18n = defineMessages({
  accountOwner: {
    id: "Ecosystem.PartnerAccountCardNearboundProspects.accountOwner",
    defaultMessage: "{name} Account Owner",
  },
  copied: {
    id: "Ecosystem.PartnerAccountCardNearboundProspects.copied",
    defaultMessage: "Copied!",
  },
  copyEmail: {
    id: "Ecosystem.PartnerAccountCardNearboundProspects.copyEmail",
    defaultMessage: "Copy email: {email}",
  },
  copyEmailAddress: {
    id: "Ecosystem.PartnerAccountCardNearboundProspects.copyEmailAddress",
    defaultMessage: "Copy email address",
  },
  moreCount: {
    id: "Ecosystem.PartnerAccountCardNearboundProspects.moreCount",
    defaultMessage: " & {count} more",
  },
  partnershipOwner: {
    id: "Ecosystem.PartnerAccountCardNearboundProspects.partnershipOwner",
    defaultMessage: "Partnership Owner:",
  },
  unassigned: {
    id: "Ecosystem.PartnerAccountCardNearboundProspects.unassigned",
    defaultMessage: "Unassigned",
  },
});

/// Utils

const getOwners = (
  intl: IntlShape,
  account: CustomerPresence,
  nearboundProspect: ModelRecord<"nearbound_prospects">,
  classes: Record<string, string>,
  handleTrack: () => void,
  copied: boolean
): ReactNode[] => {
  const owners = nearboundProspect.ownerList.filter(
    (item: any) => item.company_id === account.partner_id
  );
  if (owners.filter(Boolean).length === 0) {
    return [<T bodySmall>{intl.formatMessage(i18n.unassigned)}</T>];
  }

  const handleCopy = async (email: string) => {
    try {
      await navigator.clipboard.writeText(email);
      handleTrack();
    } catch (err) {
      console.error("Failed to copy text: ", err);
    }
  };

  const formattedOwners = owners.filter(Boolean).map((user: any) => (
    <Box className={classes.ownerContainer} key={String(user.id)}>
      <T>
        <span>{user.full_name}</span>
      </T>
      {user.email && (
        <>
          <span>{" · "}</span>
          <Tooltip
            title={
              <FormattedMessage
                {...(copied ? i18n.copied : i18n.copyEmailAddress)}
              />
            }
          >
            <Box
              className={classes.ownerCopyContainer}
              onClick={() => handleCopy(user.email)}
              onCopy={handleTrack}
            >
              <T className={classes.link}>
                <span>{user.email}</span>
              </T>
              <Copy className={classes.copyIcon} />
            </Box>
          </Tooltip>
        </>
      )}
    </Box>
  ));
  if (formattedOwners.length < 3) {
    return formattedOwners;
  }
  return [
    ...formattedOwners.slice(0, 2),
    <T bodySmall>
      <FormattedMessage
        {...i18n.moreCount}
        values={{ count: formattedOwners.length - 2 }}
      />
    </T>,
  ];
};
