import Popover from "@mui/material/Popover";
import { ClassNameMap } from "@mui/material/styles";
import ChevronDown from "components/icons/ChevronDown";
import Button from "components/ui/Button";
import { T } from "components/ui/Typography";
import { makeStyles } from "makeStyles";
import { ReactNode, useEffect, useState } from "react";
import {
  defineMessages,
  FormattedMessage,
  MessageDescriptor,
} from "react-intl";
import { useMergedClasses } from "tss-react";

type Props = {
  children?: ReactNode;
  disabled?: boolean;
  filterType: string;
  openedWidget?: string | null;
  setOpenedWidget: (filterType: string | null) => void;
  footer?: ReactNode;
  placement?: "left" | "center" | "right";
  classes?: ClassNameMap;
  selectedItemsCount: number | null;
};

const FiltersPopover = ({
  children,
  disabled,
  filterType,
  openedWidget,
  setOpenedWidget,
  footer = null,
  placement = "left",
  classes: newClasses = {},
  selectedItemsCount = null,
}: Props) => {
  const { classes: baseClasses, cx } = useStyles({
    hasItems: Boolean(selectedItemsCount),
    disabled,
  });
  const classes = useMergedClasses(baseClasses, newClasses);
  const { classes: popoverClasses } = usePopoverStyles({
    isColumnWidget: filterType === "column",
  });
  const [anchorEl, setAnchorEl] = useState(null);
  const highlighted = Boolean(selectedItemsCount);

  const handleClick = (event: $TSFixMe) => {
    if (disabled) {
      return;
    }
    setAnchorEl(event.currentTarget);
    setOpenedWidget(filterType);
    event.stopPropagation();
  };

  const handleClose = () => {
    setAnchorEl(null);
    setOpenedWidget(null);
  };

  // Needed to open the widget when I do an action from the table header.
  const simulateClick = (e: HTMLButtonElement | null) => {
    if (e && openedWidget === filterType) {
      e.click();
    }
  };

  const open = Boolean(anchorEl);
  const id = open ? "filters-popover" : undefined;

  const filterName = getLabel(filterType);
  const label =
    selectedItemsCount !== null ? (
      <div className={classes.groupFilter}>
        <T>
          <FormattedMessage {...filterName} />
        </T>
        <T
          uppercase
          className={cx(classes.facet, {
            [classes.displayNone]: selectedItemsCount === 0,
          })}
        >
          {selectedItemsCount}
        </T>
      </div>
    ) : (
      filterName
    );

  const buttonContent = (
    <Button
      label={label}
      onClick={handleClick}
      variant={"quinary"}
      RightIcon={ChevronDown}
      size="small"
      bold={highlighted}
      disabled={open}
      ref={simulateClick}
    />
  );

  useEffect(() => {
    if (!openedWidget) setAnchorEl(null);
  }, [openedWidget]);

  return (
    <div>
      {buttonContent}
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: placement,
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: placement,
        }}
        classes={popoverClasses}
      >
        <div className={classes.childrenContainer}>{children}</div>
        {footer && <div className={classes.footerContainer}>{footer}</div>}
      </Popover>
    </div>
  );
};

export default FiltersPopover;

// Helpers

const getLabel = (filterType: string): MessageDescriptor => {
  switch (filterType) {
    case "column":
      return i18n.columns;
    case "filter":
      return i18n.filters;
    case "sort":
      return i18n.sort;
    default:
      return i18n.select;
  }
};

// I18N

const i18n = defineMessages({
  select: {
    id: "FiltersPopover.select",
    defaultMessage: "Select",
  },
  columns: {
    id: "FiltersPopover.columns",
    defaultMessage: "Columns",
  },
  filters: {
    id: "FiltersPopover.filters",
    defaultMessage: "Filters",
  },
  sort: {
    id: "FiltersPopover.sort",
    defaultMessage: "Sort",
  },
});

// CSS

export const useStyles = makeStyles<{
  disabled?: boolean;
  hasItems: boolean;
}>()((theme, { disabled, hasItems }) => ({
  childrenContainer: {
    padding: 15,
  },
  footerContainer: {
    padding: 10,
    backgroundColor: theme.palette.grey500,
  },
  groupFilter: {
    cursor: disabled ? "not-allowed" : "pointer",
    display: "flex",
    alignItems: "center",
    gap: 5,
  },
  facet: {
    color: hasItems ? theme.palette.ivory : theme.palette.midnight,
    backgroundColor: hasItems ? theme.palette.midnight : theme.palette.ivory,
    padding: "4px 6px",
    borderRadius: 24,
  },
  displayNone: {
    display: "none",
  },
}));

const usePopoverStyles = makeStyles<{ isColumnWidget: boolean }>()(
  (theme, { isColumnWidget }) => ({
    paper: {
      marginTop: 10,
      boxShadow: `-2px 3px 6px ${theme.palette.taupe} !important`,
      width: isColumnWidget ? "max-content" : "fit-content",
      minWidth: 300,
    },
  })
);
