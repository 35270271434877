import { TabPanel } from "@mui/lab";
import { Grid } from "@mui/material";
import { parseSelectedValue } from "components/ui/navbar/GlobalSearch/hooks/useActions";
import { TabListLight } from "components/ui/TabListLight";
import { T } from "components/ui/Typography";
import _ from "lodash";
import { makeStyles } from "makeStyles";
import Match from "models/Match";
import Record from "models/Record";
import { useCallback, useState } from "react";
import { defineMessages, FormattedMessage, useIntl } from "react-intl";
import { useHistory, useLocation } from "react-router-dom";
import {
  CustomerPresence,
  PartnerAccount,
} from "screens/Frontoffice/screens/DataTables/screens/Mapping360/types";
import {
  getActiveProspects,
  getCustomers,
} from "screens/Frontoffice/screens/DataTables/screens/Mapping360/utils";

import MorePartnerAccountCard from "./MorePartnerAccountCard";
import PartnerAccountCard from "./PartnerAccountCard";
import { PartnerAccountCardNearboundProspects } from "./PartnerAccountCardNearboundProspects";

type AccountTabsProps = {
  account: Record<"nearbound_prospects" | "crm_accounts">;
  isLimited?: boolean;
};

const getCurrentTab = (hash: string): number => {
  let tab: number = Match.STATUS_CUSTOMER;
  try {
    const parsed = parseSelectedValue(hash);
    if (parsed.type === "nearbound_accounts" && parsed.tab) {
      tab = parsed.tab;
    }
  } catch {}
  return tab;
};

const AccountTabs = ({ account, isLimited = true }: AccountTabsProps) => {
  const intl = useIntl();
  const location = useLocation();
  const history = useHistory();

  const { classes } = useStyles();
  const [currentTab, setCurrentTab] = useState(getCurrentTab(location.hash));

  const { customers, prospects } = getCustomersAndProspects(account);
  const tabs = [
    {
      value: String(Match.STATUS_CUSTOMER),
      label: intl.formatMessage(i18n.partnerPresence, {
        count: customers.length,
      }),
      count: customers.length,
    },
    {
      value: String(Match.STATUS_PROSPECT),
      label: intl.formatMessage(i18n.partnerOpports, {
        count: prospects.length,
      }),
      count: prospects.length,
    },
  ];

  const handleChangeTab = useCallback(
    (_event: React.SyntheticEvent, newValue: string) => {
      setCurrentTab(+newValue);
      try {
        const parsed = parseSelectedValue(location.hash);
        if (parsed.type === "nearbound_accounts") {
          history.push({ hash: `na-${parsed.rawCompanyId}&tab=${newValue}` });
        }
      } catch {}
    },
    [history, location.hash]
  );

  return (
    <TabListLight
      tabValue={String(currentTab)}
      onChange={handleChangeTab}
      tabsData={account.type === "nearbound_prospects" ? [tabs[0]] : tabs}
    >
      <TabPanel
        className={classes.tabPanel}
        value={String(Match.STATUS_CUSTOMER)}
      >
        {customers.length > 0 ? (
          (customers as any[]).map((item, index) => {
            if (isLimited && index === 1) {
              return (
                <MorePartnerAccountCard partnerCount={customers.length - 1} />
              );
            } else if (isLimited && index > 0) {
              return null;
            } else {
              return (
                <Grid item key={`customer-${index}`}>
                  {"partnership_id" in item ? (
                    <PartnerAccountCardNearboundProspects
                      account={item as CustomerPresence}
                      nearboundProspect={
                        account as Record<"nearbound_prospects">
                      }
                    />
                  ) : (
                    <PartnerAccountCard
                      account={item as PartnerAccount}
                      crmAccount={account as Record<"crm_accounts">}
                    />
                  )}
                </Grid>
              );
            }
          })
        ) : (
          <Grid item>
            <T>
              <FormattedMessage {...i18n.noCustomers} />
            </T>
          </Grid>
        )}
      </TabPanel>
      <TabPanel
        className={classes.tabPanel}
        value={String(Match.STATUS_PROSPECT)}
      >
        {prospects.length > 0 ? (
          <>
            {_.map(prospects, (section: PartnerAccount, index: number) => {
              if (isLimited && index === 1) {
                return (
                  <MorePartnerAccountCard partnerCount={prospects.length - 1} />
                );
              } else if (isLimited && index > 0) {
                return null;
              } else {
                return (
                  <Grid item key={`prospect-${index}`}>
                    <PartnerAccountCard
                      account={section}
                      crmAccount={account as Record<"crm_accounts">}
                      hideStatus
                    />
                  </Grid>
                );
              }
            })}
          </>
        ) : (
          <Grid item>
            <T>
              <FormattedMessage {...i18n.noOpports} />
            </T>
          </Grid>
        )}
      </TabPanel>
    </TabListLight>
  );
};

export default AccountTabs;

// Helper

export const getCustomersAndProspects = (account: Record) => {
  const customers =
    account?.type === "nearbound_prospects"
      ? (account.customerPresenceList as CustomerPresence[])
      : getCustomers(account?.matches);
  const prospects = getActiveProspects(account?.matches);
  return { customers, prospects };
};

// I18N

const i18n = defineMessages({
  partnerPresence: {
    id: "crm.Ecosystem.AccountDetails.AccountTabs.partnerPresence",
    defaultMessage: "Is customer of",
  },
  partnerOpports: {
    id: "crm.Ecosystem.AccountDetails.AccountTabs.partnerOpports",
    defaultMessage: "Is open opportunity for",
  },
  noCustomers: {
    id: "crm.Ecosystem.AccountDetails.AccountTabs.noCustomers",
    defaultMessage:
      "None of your partners has listed this account as Customer.",
  },
  noOpports: {
    id: "crm.Ecosystem.AccountDetails.AccountTabs.noOpports",
    defaultMessage:
      "None of your partners has an open opportunity on this account.",
  },
});

// CSS

export const useStyles = makeStyles()((theme) => ({
  tabPanel: {
    display: "flex",
    flexDirection: "column",
    gap: theme.spacing(1.5),
    padding: theme.spacing(2, 0),
  },
}));
