import { CellProps } from "components/ui/data-grid/utils";
import { T } from "components/ui/Typography";
import { makeStyles } from "makeStyles";
import { AvailablePipelineColumns } from "redux/pipeline/defaults";

export enum CategoryType {
  Bookmark = "Bookmark",
  IntroRequest = "Intro request",
  FromCRM = "From CRM",
  PartnerReferral = "Partner Referral",
}

export const CategoryCell = ({ row }: CellProps) => {
  const category = row.category;
  const { classes } = useStyles({ category });

  return (
    <div className={classes.cellContainer}>
      <T noWrap>{category}</T>
    </div>
  );
};

export const isPipelineCategoryCell = ({ fieldName }: CellProps) => {
  return fieldName === AvailablePipelineColumns.category;
};

// CSS

const useStyles = makeStyles<{ category: CategoryType }>()(
  (theme, { category }) => ({
    cellContainer: {
      backgroundColor:
        category === CategoryType.PartnerReferral
          ? theme.palette.greenBrand050
          : theme.palette.purpleBrand050,
      borderRadius: 4,
      padding: "1px 6px",
      overflow: "hidden",
    },
  })
);
