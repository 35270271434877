import ActionPanel from "components/ui/ActionPanel";
import useSegment from "hooks/useSegment";
import useUserProfile from "hooks/useUserProfile";
import Record from "models/Record";
import { useCallback, useEffect, useState } from "react";
import AccountDetails from "screens/Frontoffice/screens/DataTables/screens/Mapping360/components/AccountView/components/AccountDetails";

import { Handle } from "../../hooks/useActions";
import { loadNearboundAccount, loadNearboundProspect } from "./helpers";

type Props = {
  handle: Handle | null;
  clearHandle: () => void;
};

const Drawer = ({ handle, clearHandle }: Props) => {
  const { profile } = useUserProfile();
  const [loading, setLoading] = useState(false);
  const [record, setRecord] = useState<
    Record<"crm_accounts"> | Record<"nearbound_prospects"> | null
  >(null);
  const { track } = useSegment();

  /* eslint-disable react-hooks/exhaustive-deps */
  useEffect(() => {
    let active = true;
    let promise: Promise<
      Record<"crm_accounts"> | Record<"nearbound_prospects">
    > | null = null;
    if (handle?.type === "nearbound_prospects") {
      setLoading(true);
      promise = loadNearboundProspect(
        profile.companyId,
        handle.countryIsoCode,
        handle.smartDomainKey
      );
    } else if (handle?.type === "nearbound_accounts") {
      setLoading(true);
      promise = loadNearboundAccount(handle.rawCompanyId);
    }
    if (promise) {
      track("Opened Single account view panel", {
        location: "global search bar",
        type: handle?.type,
      });
      promise
        .then((record) => {
          if (active) {
            setRecord(record);
          }
        })
        .catch((error) => {
          console.warn("Error resolving record:", error);
        })
        .finally(() => {
          if (active) {
            setLoading(false);
          }
        });
    }

    return () => {
      active = false;
    };
  }, [
    handle?.type,
    handle?.type === "nearbound_accounts" ? handle.rawCompanyId : "",
    handle?.type === "nearbound_prospects" ? handle.countryIsoCode : "",
    handle?.type === "nearbound_prospects" ? handle.smartDomainKey : "",
    profile.companyId,
    track,
  ]);
  /* eslint-enable react-hooks/exhaustive-deps */

  const handleClose = useCallback(() => {
    setRecord(null);
    clearHandle();
  }, [clearHandle]);

  return (
    <ActionPanel
      isOpen={Boolean(handle && handle.type !== "partnerships")}
      width={720}
      onClose={handleClose}
    >
      <AccountDetails account={record} close={handleClose} ready={!loading} />
    </ActionPanel>
  );
};

export default Drawer;
