import useUserProfile from "hooks/useUserProfile";
import Match from "models/Match";
import { parse } from "querystring";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useHistory, useLocation } from "react-router";

export enum NearboundAccountViewTab {
  customerTab = 1,
  prospetTab = 2,
}

const NEARBOUND_PROSPECT_REGEXP = /^#?np-(?<country_iso_code>([A-Z]{2})?)-(?<smart_domain_key>[\w-.]+)$/;
const NEARBOUND_ACCOUNT_REGEXP = /^#?na-(?<raw_company_id>\d+)(&tab=(?<tab>\d+))?$/;
const PARTNERSHIP_REGEXP = /^#?p-(?<partnership_id>\d+)$/;

const extractGroups = (
  match: RegExpMatchArray | null
): { [key: string]: string } => {
  if (match === null || !match.groups) {
    throw new Error("Invalid value, not matching pattern.");
  }
  return match.groups;
};

type PartnershipHandle = {
  type: "partnerships";
  id: number;
};

const parsePartnership = (value: string): PartnershipHandle => {
  const parsed = value.match(PARTNERSHIP_REGEXP);
  const groups = extractGroups(parsed);
  return {
    type: "partnerships",
    id: +groups.partnership_id,
  };
};

type NearboundAccountHandle = {
  type: "nearbound_accounts";
  rawCompanyId: number;
  tab?: NearboundAccountViewTab;
};

const parseNearboundAccount = (value: string): NearboundAccountHandle => {
  const parsed = value.match(NEARBOUND_ACCOUNT_REGEXP);
  const groups = extractGroups(parsed);
  const tab =
    +groups.tab in NearboundAccountViewTab
      ? (+groups.tab as NearboundAccountViewTab)
      : undefined;
  return {
    type: "nearbound_accounts",
    rawCompanyId: +groups.raw_company_id,
    tab,
  };
};

type NearboundProspectHandle = {
  type: "nearbound_prospects";
  countryIsoCode: string;
  smartDomainKey: string;
};

const parseNearboundProspect = (value: string): NearboundProspectHandle => {
  const parsed = value.match(NEARBOUND_PROSPECT_REGEXP);
  const groups = extractGroups(parsed);
  return {
    type: "nearbound_prospects",
    countryIsoCode: groups.country_iso_code,
    smartDomainKey: groups.smart_domain_key,
  };
};

export const parseSelectedValue = (value: string) => {
  switch (true) {
    case PARTNERSHIP_REGEXP.test(value):
      return parsePartnership(value);
    case NEARBOUND_ACCOUNT_REGEXP.test(value):
      return parseNearboundAccount(value);
    case NEARBOUND_PROSPECT_REGEXP.test(value):
      return parseNearboundProspect(value);
  }
  throw new Error("Cannot parse selected value");
};

const parseLegacyNearboundAccountLinks = (
  search: string
): NearboundAccountHandle | null => {
  const parsedSearch = parse(search) ?? {};
  const rawCompanyId = parsedSearch.selected
    ? +parsedSearch.selected
    : undefined;
  const tab =
    parsedSearch.tab &&
    (+parsedSearch.tab === Match.STATUS_CUSTOMER ||
      +parsedSearch.tab === Match.STATUS_PROSPECT)
      ? +parsedSearch.tab
      : undefined;
  if (rawCompanyId) {
    return {
      type: "nearbound_accounts",
      rawCompanyId,
      tab,
    };
  }
  return null;
};

export type Handle =
  | NearboundAccountHandle
  | NearboundProspectHandle
  | PartnershipHandle;

const useActions = () => {
  const { profile } = useUserProfile();
  const history = useHistory();
  const location = useLocation();
  const [handle, setHandle] = useState<Handle | null>(null);

  useEffect(() => {
    try {
      const parsed = parseSelectedValue(location.hash);
      setHandle(parsed);
    } catch {
      setHandle(
        parseLegacyNearboundAccountLinks(location.search.replace("?", ""))
      );
    }
  }, [location.hash, location.search, profile.id]);

  const handleSelectedValue = useCallback(
    (values: string[] | null) => {
      if (values !== null && values.length === 1) {
        const parsed = parseSelectedValue(values[0]);
        if (parsed.type === "partnerships") {
          history.push(`/partnerships/${parsed.id}/settings/overview`);
        } else {
          history.push({ hash: values[0], pathname: location.pathname });
        }
      } else {
      }
    },
    [location.pathname, history]
  );

  const clearHandle = useCallback(() => {
    setHandle(null);
    history.push({ hash: "" });
  }, [history]);

  return useMemo(
    () => ({
      handleSelectedValue,
      handle,
      clearHandle,
    }),
    [handle, clearHandle, handleSelectedValue]
  );
};

export default useActions;
