import { IDropdownOption } from "components/ui/Dropdown/components/types";
import useSegment from "hooks/useSegment";
import Record from "models/Record";
import { useState } from "react";
import { defineMessages, FormattedMessage } from "react-intl";
import { useSelector } from "react-redux";
import { selectAllPartnershipTags } from "redux/init/selectors";
import { TagsEvent } from "tracking";

import { BaseFilter } from "./BaseFilter";

export type TagFilterType = "and" | "or";

type Props = {
  value: string[];
  toggleFilterValue: TagFilterType;
  disabled?: boolean;
  onChange: (value: Record[]) => void;
  onToggleFilterChange: (value: TagFilterType) => void;
};

export const TagFilter = ({
  value,
  toggleFilterValue,
  disabled,
  onChange,
  onToggleFilterChange,
}: Props) => {
  const { track } = useSegment();
  const partnershipTags = useSelector(selectAllPartnershipTags);
  const [toggleFilter, setToggleFilter] = useState<TagFilterType | undefined>(
    toggleFilterValue
  );
  const [selectedTags, setSelectedTags] = useState<string[] | null>(value);

  const options: IDropdownOption[] = partnershipTags
    .filter((tag) => tag.partnerships.length > 0)
    .map((o) => ({
      id: String(o.id),
      value: o.tagName,
      name: o.tagName,
    }));

  const typeItems = [
    { value: "and", label: <FormattedMessage {...i18n.and} /> },
    { value: "or", label: <FormattedMessage {...i18n.or} /> },
  ];

  const handleToggleFilterChange = (value: TagFilterType) => {
    setToggleFilter(value);
    onToggleFilterChange(value);
  };

  const handleSubmit = () => {
    if (!selectedTags?.length) {
      onChange([]);
    } else {
      const value: Record[] = (selectedTags ?? []).map(
        (id) => partnershipTags.find((tag) => tag.id === +id)!
      );
      onChange(value);
    }
    onToggleFilterChange(toggleFilter ?? "and");
    track(TagsEvent.filterByTag, {
      tagNames:
        selectedTags === null
          ? ""
          : selectedTags
              .map(
                (id) => partnershipTags.find((tag) => tag.id === +id)?.tagName
              )
              .join(", "),
      filterType: toggleFilter ?? "and",
    });
  };

  return (
    <BaseFilter
      label={i18n.tags}
      hasCount
      options={options}
      value={value}
      disabled={disabled}
      selectedItems={selectedTags}
      setSelectedItems={setSelectedTags}
      onSubmit={handleSubmit}
      // ToggleFilterProps
      withToggleFilter
      toggleFilter={toggleFilter}
      typeItems={typeItems}
      onToggleFilterChange={handleToggleFilterChange}
    />
  );
};

/// I18N

const i18n = defineMessages({
  tags: {
    id: "Partners.Partnerships.tags",
    defaultMessage: "Tags",
  },
  and: {
    id: "Partners.Partnerships.and",
    defaultMessage: "AND",
  },
  or: {
    id: "Partners.Partnerships.or",
    defaultMessage: "OR",
  },
});

export const _private = {
  i18n,
};
