import PageView from "models/PageView";
import User from "models/User";
import { APICollection } from "redux/api/typing";

export enum AnalyticsActions {
  loadView = "analytics/loadView",
  updateView = "analytics/updateView",
  checkHighWinRatePresence = "analytics/checkHighWinRatePresence",
}

export interface PartnershipAnalyticsState {
  view?: PageView | null;
  ready: boolean;
  hasHighWinRatePartners: boolean | null;
}

export interface RootStateWithAnalytics {
  analytics: PartnershipAnalyticsState;
  api: {
    entities: {
      users?: APICollection<User>;
    };
  };
}

export enum AnalyticsQuickFilterType {
  All = "all",
  Favorites = "favorites",
  HighWinRatePartners = "highWinRatePartners",
  MyPartners = "myPartners",
}

export enum AnalyticsQuickFilterFieldname {
  Favorites = "watcher_ids",
  HighWinRatePartners = "is_high_win_rate_partner",
  MyPartners = "my_partnership_owner_id",
}

export enum AnalyticsOtherFilterFieldname {
  IncludeOffline = "include_ghost_partnerships",
  Tags = "partnership_tags",
  showSummaryMetrics = "show_summary_metrics",
  highlightHighWinRate = "highlight_high_win_rate",
}

export type AnalyticsFilterFieldname = AnalyticsQuickFilterFieldname &
  AnalyticsOtherFilterFieldname;
