import { createSvgIcon } from "@mui/material/utils";

import { SvgIcon } from "./types";

const displayName = "Directory";

const Directory: SvgIcon = createSvgIcon(
  // Using transform to enlarge from provided viewbox="0 0 16 16"
  // to default "0 0 24 24" from `createSvgIcon` -> `SvgIcon`.
  <g fill="none" transform="matrix(1.5,0,0,1.5,0,0)">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3 3.5C2.44772 3.5 2 3.94772 2 4.5V11.6538C2 12.2061 2.44772 12.6538 3 12.6538H6.00786C6.32946 12.6538 6.64632 12.7314 6.93156 12.8799L7.5 13.1759V4.5C7.5 3.94772 7.05228 3.5 6.5 3.5H6.375H6H3ZM8 3.17709C7.63353 2.76188 7.09735 2.5 6.5 2.5H6.375H6H3C1.89543 2.5 1 3.39543 1 4.5V11.6538C1 12.7584 1.89543 13.6538 3 13.6538H6.00786C6.16866 13.6538 6.32709 13.6926 6.46971 13.7669L7.0763 14.0827C7.65521 14.3842 8.34479 14.3842 8.9237 14.0827L9.53029 13.7669C9.67291 13.6926 9.83134 13.6538 9.99214 13.6538H13C14.1046 13.6538 15 12.7584 15 11.6538V4.5C15 3.39543 14.1046 2.5 13 2.5H10H9.625H9.5C8.90265 2.5 8.36647 2.76188 8 3.17709ZM9.625 3.5H9.5C8.94772 3.5 8.5 3.94772 8.5 4.5V13.1759L9.06844 12.8799C9.35368 12.7314 9.67054 12.6538 9.99214 12.6538H13C13.5523 12.6538 14 12.2061 14 11.6538V4.5C14 3.94772 13.5523 3.5 13 3.5H10H9.625Z"
      fill="currentColor"
    />
  </g>,
  displayName
);

Directory.displayName = displayName;
export default Directory;
