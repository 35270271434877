import { CheckCircle, RadioButtonUnchecked } from "@mui/icons-material";
import Grid from "@mui/material/Grid";
import { ClassNameMap } from "@mui/material/styles";
import Tooltip from "components/ui/Tooltip";
import { makeStyles } from "makeStyles";
import { ReactNode } from "react";
import { useMergedClasses } from "tss-react";

interface Option {
  value: string | number;
  content: ReactNode[] | ReactNode | string;
  outsideContent?: ReactNode[] | ReactNode | string;
  disabled?: boolean;
  tooltip?: string | JSX.Element;
}

type Orientation = "vertical" | "horizontal";

type StyleProps = {
  orientation: Orientation;
};

type Props = Partial<StyleProps> & {
  values?: any[];
  onSelect?: (value: any) => void;
  options: Option[];
  classes?: ClassNameMap;
  allowDeselect?: boolean;
  multiSelect?: boolean;
};

const SelectBoxGroup = ({
  options,
  values = [],
  onSelect,
  orientation = "horizontal",
  classes: customClasses = {},
  allowDeselect,
  multiSelect = true,
}: Props) => {
  const { classes: baseClasses, cx } = useStyles({ orientation });
  const classes = useMergedClasses(baseClasses, customClasses);

  return (
    <Grid
      container
      spacing={1}
      className={classes.container}
      alignItems="stretch"
    >
      {options.map((option: Option) => (
        <Grid item key={option.value} className={classes.optionContainer}>
          <Tooltip title={option.tooltip ?? ""}>
            <div
              className={cx(classes.tile, {
                [classes.active]: Boolean(
                  values && values.includes(option.value) && onSelect
                ),
                [classes.inactive]: Boolean(
                  !values.includes(option.value) && onSelect
                ),
              })}
              onClick={
                onSelect
                  ? () => {
                      if (
                        !option.disabled &&
                        (!values.includes(option.value) || allowDeselect)
                      ) {
                        onSelect(option.value);
                      }
                    }
                  : undefined
              }
            >
              <div
                className={cx(classes.innerTile, {
                  [classes.selectable]: !!onSelect && !option.disabled,
                })}
              >
                {multiSelect &&
                Boolean(values && values.includes(option.value) && onSelect) ? (
                  <CheckCircle
                    className={cx(classes.checkIcon, classes.checkIconActive)}
                  />
                ) : multiSelect ? (
                  <RadioButtonUnchecked className={classes.checkIcon} />
                ) : null}
                {option.content}
              </div>
            </div>
          </Tooltip>
          {/* Adding some content outside the box, to place it above the box hovering, to not trigger it */}
          {option.outsideContent && option.outsideContent}
        </Grid>
      ))}
    </Grid>
  );
};

export default SelectBoxGroup;

/// CSS

const useStyles = makeStyles<StyleProps>()((theme, { orientation }) => ({
  checkIcon: {
    position: "absolute",
    left: 16,
    height: 16,
    color: theme.palette.greyscale250,
    top: "50%",
    transform: "translateY(-4px)",
  },
  checkIconActive: {
    color: theme.palette.greyscale950,
    left: 15,
  },
  container: {
    flexDirection: orientation === "horizontal" ? "row" : "column",
  },
  optionContainer: {
    position: "relative",
  },
  tile: {
    borderRadius: 8,
    height: "100%",
  },
  inactive: {
    backgroundImage: "transparent",
  },
  active: {
    backgroundImage: theme.palette.gradients.blueToGreen,
    padding: 2,
  },
  innerTile: {
    background: theme.palette.offWhite,
    borderRadius: 6,
    padding: 20,
    height: "100%",
  },
  selectable: {
    "&:hover": {
      cursor: "pointer",
      backgroundColor: theme.palette.taupe,
    },
  },
}));
