import { createSvgIcon } from "@mui/material/utils";

import { SvgIcon } from "./types";

const displayName = "BookmarkChecked";

const BookmarkChecked: SvgIcon = createSvgIcon(
  // Using transform to enlarge from provided viewbox="0 0 16 16"
  // to default "0 0 24 24" from `createSvgIcon` -> `SvgIcon`.
  <g fill="none" transform="matrix(1.5,0,0,1.5,0,0)">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2 2.5C2 1.67157 2.67157 1 3.5 1H12.5C13.3284 1 14 1.67157 14 2.5V13.2817C14 14.4318 12.759 15.154 11.759 14.5859L8.247 12.5904C8.09383 12.5034 7.90617 12.5034 7.753 12.5904L4.24101 14.5859C3.24105 15.154 2 14.4318 2 13.2817V2.5ZM11.8536 4.85355C12.0488 4.65829 12.0488 4.34171 11.8536 4.14645C11.6583 3.95118 11.3417 3.95118 11.1464 4.14645L7.32224 7.97065C7.12337 8.16953 6.79965 8.1653 6.60603 7.96131L4.86265 6.12454C4.67255 5.92425 4.35607 5.91599 4.15579 6.1061C3.9555 6.2962 3.94724 6.61268 4.13735 6.81296L5.88073 8.64974C6.46158 9.26171 7.43273 9.27438 8.02935 8.67776L11.8536 4.85355Z"
      fill="currentColor"
    />
  </g>,
  displayName
);

BookmarkChecked.displayName = displayName;
export default BookmarkChecked;
