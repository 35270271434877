import { IDropdownOption } from "components/ui/Dropdown/components/types";
import { BaseFilter } from "components/ui/filters/filter/BaseFilter";
import Record from "models/Record";
import User from "models/User";
import { useState } from "react";
import { defineMessages } from "react-intl";
import { useSelector } from "react-redux";
import { selectAllPartnershipOwners } from "redux/init/selectors";

type Props = {
  value: string[];
  disabled?: boolean;
  onChange: (value: Record[]) => void;
};

const PartnerOwnerFilter = ({ value, disabled, onChange }: Props) => {
  const partnershipOwners = useSelector(selectAllPartnershipOwners) as User[];
  const [selectedPartnerOwnerIds, setSelectedPartnerOwnerIds] = useState<
    string[] | null
  >(value);

  const options: IDropdownOption[] = partnershipOwners.map((po) => ({
    id: String(po.id),
    value: po.fullName,
    name: po.fullName,
  }));

  const handleSubmit = () => {
    if (!selectedPartnerOwnerIds?.length) {
      onChange([]);
    } else {
      const selectedPartnerOwners = (selectedPartnerOwnerIds ?? []).map(
        (id) => partnershipOwners.find((po) => po.id === +id)!
      );
      onChange(selectedPartnerOwners);
    }
  };

  return (
    <BaseFilter
      label={i18n.partnerOwners}
      hasCount
      options={options}
      value={value}
      disabled={disabled}
      selectedItems={selectedPartnerOwnerIds}
      setSelectedItems={setSelectedPartnerOwnerIds}
      onSubmit={handleSubmit}
    />
  );
};
export default PartnerOwnerFilter;

/// I18N

const i18n = defineMessages({
  partnerOwners: {
    id: "Partners.Partnerships.partnerOwners",
    defaultMessage: "Partner Owners",
  },
});
