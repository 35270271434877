import { createSvgIcon } from "@mui/material/utils";

import { SvgIcon } from "./types";

const displayName = "ExportToCsv";

const ExportToCsv: SvgIcon = createSvgIcon(
  // Using transform to center the icon.
  // initial size: "0 0 16 16" to "0 0 24 24"
  <g fill="none" transform="translate(3.5,3.5)">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2.5 2C2.77614 2 3 2.22386 3 2.5V13.1667C3 13.6269 3.3731 14 3.83333 14H14.5C14.7761 14 15 14.2239 15 14.5C15 14.7761 14.7761 15 14.5 15H3.83333C2.82081 15 2 14.1792 2 13.1667V2.5C2 2.22386 2.22386 2 2.5 2Z"
      fill="currentColor"
    />
    <path
      d="M11.8332 5C11.557 5 11.3332 5.22386 11.3332 5.5C11.3332 5.77614 11.557 6 11.8332 6H13.2927L10.2844 9.00838C10.2193 9.07346 10.1137 9.07346 10.0487 9.00838L8.3248 7.28452C7.86918 6.82891 7.13049 6.8289 6.67488 7.28452L4.81295 9.14645C4.61769 9.34171 4.61769 9.65829 4.81295 9.85355C5.00821 10.0488 5.3248 10.0488 5.52006 9.85355L7.38199 7.99162C7.44707 7.92654 7.5526 7.92654 7.61769 7.99162L9.34155 9.71548C9.79716 10.1711 10.5359 10.1711 10.9915 9.71548L13.9998 6.70711V8.16667C13.9998 8.44281 14.2237 8.66667 14.4998 8.66667C14.776 8.66667 14.9998 8.44281 14.9998 8.16667V5.5C14.9998 5.22386 14.776 5 14.4998 5H11.8332Z"
      fill="currentColor"
    />
  </g>,
  displayName
);

ExportToCsv.displayName = displayName;
export default ExportToCsv;
