import { ShareBoth, ShareLeft, ShareNone, ShareRight } from "components/icons";
import _ from "lodash";
import Partnership from "models/Partnership";
import Record from "models/Record";
import { defineMessages, IntlShape } from "react-intl";
import { PartnershipUpsidePotentialData } from "redux/upsides/types";

export const getOverlapItemValues = (
  partnership: Partnership,
  upsideData: PartnershipUpsidePotentialData
) => {
  let marketOverlap: number | null = null;
  let personaOverlap: number | null = null;
  let winRate: number | null = null;
  let revenuePotential: number | null = null;
  let newProspectsRevenuePotential: number | null = null;
  let sourceableProspectsRevenuePotential: number | null = null;
  let influenceableProspectsRevenuePotential: number | null = null;
  let winRateOnPartnersCustomersVerifiesCentralLimit: boolean = false;

  // Market Overlap
  const hasMarketOverlap = _.get(
    partnership,
    "capabilities.has_market_overlap",
    true
  );
  if (hasMarketOverlap || partnership.isDemo) {
    marketOverlap = +partnership.insights?.marketOverlap ?? 0;
  }

  // Persona Overlap
  const hasPersonaOverlap = _.get(
    partnership,
    "capabilities.has_persona_overlap",
    true
  );
  if (hasPersonaOverlap || partnership.isDemo) {
    personaOverlap = +partnership.insights?.personaOverlap ?? 0;
  }

  // Win Rate & Revenue Potential
  if (partnership.isDemo) {
    winRate = 0.15;
    revenuePotential = 3000000;
    newProspectsRevenuePotential = 1000000;
    sourceableProspectsRevenuePotential = 2000000;
    influenceableProspectsRevenuePotential = 4000000;
    winRateOnPartnersCustomersVerifiesCentralLimit = false;
  } else if (upsideData) {
    winRate = upsideData.winRateIncrease;
    revenuePotential = upsideData.revenuePotential;
    newProspectsRevenuePotential = upsideData.newProspectsRevenuePotential;
    sourceableProspectsRevenuePotential =
      upsideData.sourceableProspectsRevenuePotential;
    influenceableProspectsRevenuePotential =
      upsideData.influenceableProspectsRevenuePotential;
    winRateOnPartnersCustomersVerifiesCentralLimit =
      upsideData.winRateOnPartnersCustomersVerifiesCentralLimit;
  }

  return {
    marketOverlap,
    personaOverlap,
    winRate,
    revenuePotential,
    newProspectsRevenuePotential,
    sourceableProspectsRevenuePotential,
    influenceableProspectsRevenuePotential,
    winRateOnPartnersCustomersVerifiesCentralLimit,
  };
};

export type Sharing = {
  left: boolean;
  right: boolean;
};

export const getSharingStatuses = (
  isAccountOverlaps: boolean,
  company: Record,
  partnership: Partnership
) => {
  if (partnership.isDemo || partnership.isGhost()) {
    return {
      left: true,
      right: true,
    };
  }
  return {
    left: partnership.isInitiator(company)
      ? isAccountOverlaps
        ? partnership.isAccountMappingEnabledByInit()
        : partnership.isNewLeadsEnabledByInit()
      : isAccountOverlaps
      ? partnership.isAccountMappingEnabledByDest()
      : partnership.isNewLeadsEnabledByDest(),
    right: partnership.isInitiator(company)
      ? isAccountOverlaps
        ? partnership.isAccountMappingEnabledByDest()
        : partnership.isNewLeadsEnabledByDest()
      : isAccountOverlaps
      ? partnership.isAccountMappingEnabledByInit()
      : partnership.isNewLeadsEnabledByInit(),
  };
};

export const getLeftIcon = (sharing: Sharing) => {
  if (sharing.left && sharing.right) {
    return ShareBoth;
  } else if (sharing.left) {
    return ShareLeft;
  } else if (sharing.right) {
    return ShareRight;
  } else {
    return ShareNone;
  }
};

export const getTooltipMessage = (
  isAccountOverlaps: boolean,
  sharing: Sharing,
  intl: IntlShape
) => {
  if (sharing.left && sharing.right) {
    return intl.formatMessage(i18n.bothSidesSharing, {
      accountMappingResource: isAccountOverlaps
        ? i18n.accountOverlaps.defaultMessage
        : i18n.newProspects.defaultMessage,
    });
  } else if (sharing.left) {
    return intl.formatMessage(i18n.leftSideSharing, {
      accountMappingResource: isAccountOverlaps
        ? i18n.accountOverlaps.defaultMessage
        : i18n.newProspects.defaultMessage,
    });
  } else if (sharing.right) {
    return intl.formatMessage(i18n.rightSideSharing, {
      accountMappingResource: isAccountOverlaps
        ? i18n.accountOverlaps.defaultMessage
        : i18n.newProspects.defaultMessage,
    });
  } else {
    return intl.formatMessage(i18n.noneSharing, {
      accountMappingResource: isAccountOverlaps
        ? i18n.accountOverlaps.defaultMessage
        : i18n.newProspects.defaultMessage,
    });
  }
};

// I18N

const i18n = defineMessages({
  accountOverlaps: {
    id: "partnerTile.accountOverlaps",
    defaultMessage: "Account Overlaps",
  },
  newProspects: {
    id: "partnerTile.newProspects",
    defaultMessage: "New Prospects",
  },
  bothSidesSharing: {
    id: "partnerTile.bothSidesSharing",
    defaultMessage: "Both sides have shared {accountMappingResource}",
  },
  leftSideSharing: {
    id: "partnerTile.leftSideSharing",
    defaultMessage: "Waiting on your partner to share {accountMappingResource}",
  },
  rightSideSharing: {
    id: "partnerTile.rightSideSharing",
    defaultMessage: "Share {accountMappingResource} with your partner",
  },
  noneSharing: {
    id: "partnerTile.noneSharing",
    defaultMessage:
      "{accountMappingResource} have not been shared by either side",
  },
});
