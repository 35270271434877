import { Paid } from "components/icons";
import {
  BasicDialog,
  BasicDialogVariant,
} from "components/ui/BasicDialog/BasicDialog";
import { Props as ButtonProps } from "components/ui/Button";
import useSegment from "hooks/useSegment";
import useUserProfile from "hooks/useUserProfile";
import { FeatureGroupName } from "models/Company";
import { useCallback } from "react";
import { defineMessages, useIntl } from "react-intl";
import { useDispatch } from "react-redux";
import {
  openWidget,
  toggleFree,
  togglePower,
  togglePro,
} from "redux/premiumPlanWidget/actions";
import { PremiumPlanEvent } from "tracking";

type UnlockInviteTeammatesDialogProps = { handleClose: () => void };

export const UnlockInviteTeammatesDialog = ({
  handleClose,
}: UnlockInviteTeammatesDialogProps) => {
  const intl = useIntl();
  const { track } = useSegment();
  const dispatch = useDispatch();
  const { profile } = useUserProfile();
  const { company } = profile;
  const plan: FeatureGroupName = company.featureGroupName;

  const openPremiumWidget = useCallback(() => {
    dispatch(toggleFree(false));
    dispatch(togglePro(plan === FeatureGroupName.Free));
    dispatch(togglePower(plan === FeatureGroupName.Pro));
    dispatch(openWidget(true));
    track(PremiumPlanEvent.explorePlan, {
      from: "unlock invite teammates cta",
    });
  }, [dispatch, track, plan]);

  const upgradeButtonProps: ButtonProps = {
    label: intl.formatMessage(i18n.upgradePlan),
    variant: "secondary",
    LeftIcon: Paid,
    onClick: openPremiumWidget,
  };

  const manageAccountsButtonProps: ButtonProps = {
    label: intl.formatMessage(i18n.manageAccounts),
    variant: "tertiary",
    onClick: handleClose,
  };

  const getButtonProps = () => {
    const btnProps = [];
    if (plan !== FeatureGroupName.Power) btnProps.push(upgradeButtonProps);
    btnProps.push(manageAccountsButtonProps);
    return btnProps;
  };

  return (
    <BasicDialog
      variant={BasicDialogVariant.Unlock}
      title={intl.formatMessage(i18n.title)}
      description={intl.formatMessage(i18n.description)}
      buttonProps={getButtonProps()}
    />
  );
};

// I18N

const i18n = defineMessages({
  title: {
    id: "UnlockInviteTeammatesDialog.title",
    defaultMessage: "Unlock Invite teammates",
  },
  description: {
    id: "UnlockInviteTeammatesDialog.description",
    defaultMessage:
      "You have reached the maximum number of users allowed in your workspace.",
  },
  upgradePlan: {
    id: "UnlockInviteTeammatesDialog.upgradePlan",
    defaultMessage: "Upgrade Plan",
  },
  manageAccounts: {
    id: "UnlockInviteTeammatesDialog.manageAccounts",
    defaultMessage: "Manage accounts",
  },
});
