import { createSvgIcon } from "@mui/material/utils";

import { SvgIcon } from "./types";

const displayName = "New";

const New: SvgIcon = createSvgIcon(
  <g fill="none">
    <rect
      x="0.149902"
      y="5"
      width="23.7"
      height="14.2"
      rx="7.1"
      fill="url(#paint0_linear_8455_3604)"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.55822 9.60156V14.6925H7.62853L5.41369 11.4883H5.3764V14.6925H4.30005V9.60156H5.24465L7.44209 12.8033H7.48684V9.60156H8.55822ZM9.44751 14.6925V9.60156H12.8779V10.489H10.5239V11.7021H12.7014V12.5895H10.5239V13.805H12.8879V14.6925H9.44751ZM14.8796 14.6925L13.4229 9.60156H14.5987L15.4414 13.1388H15.4836L16.4133 9.60156H17.4201L18.3473 13.1463H18.392L19.2347 9.60156H20.4105L18.9538 14.6925H17.9048L16.9353 11.364H16.8956L15.9286 14.6925H14.8796Z"
      fill="#F6F2E8"
    />
    <defs>
      <linearGradient
        id="paint0_linear_8455_3604"
        x1="0.149902"
        y1="14.2434"
        x2="21.6914"
        y2="1.38408"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#5A28C3" />
        <stop offset="1" stop-color="#7E64FF" />
      </linearGradient>
    </defs>
  </g>,
  displayName
);

New.displayName = displayName;
export default New;
