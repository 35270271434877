import { Box } from "@mui/material";
import { SendLead } from "components/icons";
import Button from "components/ui/Button";
import DropdownItem from "components/ui/Dropdown/components/DropdownItem";
import Tooltip from "components/ui/Tooltip";
import { T } from "components/ui/Typography";
import useSegment from "hooks/useSegment";
import { makeStyles } from "makeStyles";
import { PartnerRepresentation } from "models/Partnership";
import { defineMessages, FormattedMessage, useIntl } from "react-intl";
import { ViewType } from "screens/Frontoffice/screens/DataTables/shared/types";
import { AccountReferralEvent } from "tracking";

type Props = {
  onlyButton?: boolean;
  partner?: PartnerRepresentation | null;
  selectedRowCount: number;
  openModal: () => void;
  sourceType: ViewType;
};

const ReferOption = ({
  onlyButton = false,
  partner,
  selectedRowCount,
  openModal,
  sourceType,
}: Props) => {
  const { classes } = useStyles();
  const { track } = useSegment();

  const intl = useIntl();

  const countPart =
    selectedRowCount > 0
      ? intl.formatMessage(i18n.selectedCount, { selectedRowCount })
      : "";
  const partnerPart = partner?.name ?? intl.formatMessage(i18n.partners);

  const onClick = () => {
    openModal();
    track(AccountReferralEvent.clickedOnReferAccountsToPipeline, {
      from: sourceType,
    });
  };

  return (
    <>
      {onlyButton ? (
        <Box display="flex" flexGrow={1}>
          <Button
            label={i18n.referLabel}
            LeftIcon={SendLead}
            classes={{ btn: classes.btn, icon: classes.iconReferButton }}
            onClick={onClick}
            variant="tertiary"
          />{" "}
        </Box>
      ) : (
        <>
          <Tooltip
            title={
              selectedRowCount === 0 ? (
                <FormattedMessage {...i18n.noAccountSelected} />
              ) : (
                ""
              )
            }
            placement="top"
          >
            <span>
              <DropdownItem
                name={
                  <>
                    <T className={classes.title}>
                      <SendLead className={classes.icon} />
                      <FormattedMessage
                        {...i18n.referTo}
                        values={{
                          countPart,
                          partnerPart,
                        }}
                      />
                    </T>
                  </>
                }
                isDisabled={selectedRowCount === 0}
                onChange={onClick}
              />
            </span>
          </Tooltip>
        </>
      )}
    </>
  );
};

export default ReferOption;

/// CSS

const useStyles = makeStyles()((theme) => ({
  btn: {
    display: "flex",
    flexGrow: 1,
  },
  icon: {
    color: theme.palette.midnight,
    fontSize: 16,
  },
  iconReferButton: {
    color: theme.palette.midnight,
    width: theme.spacing(2),
    height: theme.spacing(2),
  },
  title: {
    display: "flex",
    alignItems: "center",
    columnGap: 6,
  },
}));

// I18N

const i18n = defineMessages({
  noAccountSelected: {
    id: "DataTables.ActionBtn.ReferOption.noAccountSelected",
    defaultMessage: "Select accounts to refer",
  },
  referLabel: {
    id: "DataTables.ActionBtn.ReferOption.referLabel",
    defaultMessage: "Refer to partner",
  },
  referTo: {
    id: "DataTables.ActionBtn.ReferOption.referTo",
    defaultMessage: "Refer {countPart} to {partnerPart}",
  },
  partners: {
    id: "DataTables.ActionBtn.ReferOption.partners",
    defaultMessage: "partner(s)",
  },
  selectedCount: {
    id: "DataTables.ActionBtn.ReferOption.selectedCount",
    defaultMessage: "{selectedRowCount} selected",
  },
});
