import SudoInitiator from "components/sudo/SudoInitiator";
import React from "react";
import { CollaborateSettings } from "screens/Frontoffice/screens/AccountSettings/screens/CollaborateSettings";
import { CompanySettings } from "screens/Frontoffice/screens/AccountSettings/screens/Company";
import DirectorySettings from "screens/Frontoffice/screens/AccountSettings/screens/Directory";
import GetIntroTemplate from "screens/Frontoffice/screens/AccountSettings/screens/GetIntroTemplate";
import NotificationsSettings from "screens/Frontoffice/screens/AccountSettings/screens/Notifications";
import Users from "screens/Frontoffice/screens/AccountSettings/screens/Users";
import { AddToPipelineFromWeeklyDigest } from "screens/Frontoffice/screens/AddToPipelineFromWeeklyDigest/components/AddToPipelineFromWeeklyDigest";
import RedirectToAccountMapping from "screens/Frontoffice/screens/DataTables/screens/AccountMapping/components/RedirectToAccountMapping";
import Analytics from "screens/Frontoffice/screens/DataTables/screens/Analytics";
import Mapping360 from "screens/Frontoffice/screens/DataTables/screens/Mapping360";
import MyPipeline from "screens/Frontoffice/screens/DataTables/screens/Pipeline/components/MyPipeline";
import MyPipelineAttribute from "screens/Frontoffice/screens/DataTables/screens/PipelineAttribute/components/MyPipelineAttribute";
import Directory from "screens/Frontoffice/screens/Directory";
import GetStarted from "screens/Frontoffice/screens/GetStarted";
import { IntroRequestPage } from "screens/Frontoffice/screens/IntroRequestPage";
import Invite from "screens/Frontoffice/screens/InvitePartner";
import InvitePrivate from "screens/Frontoffice/screens/InvitePartner/components/InvitePrivate";
import OnboardingActivatePartnerships from "screens/Frontoffice/screens/Onboarding/screens/ActivatePartnerships";
import { OpenPremiumWidget } from "screens/Frontoffice/screens/OpenPremiumWidget/components/OpenPremiumWidget";
import { Orientation } from "screens/Frontoffice/screens/Orientation/components/Orientation";
import Partners from "screens/Frontoffice/screens/Partners";
import PartnershipSettings, {
  DemoPartnershipSettingsPage,
} from "screens/Frontoffice/screens/Partners/screens/PartnershipSettings";
import { NonUserUrl } from "screens/Frontoffice/shared/helpers/types";

import { RouteConfig } from "./types";
import { sharedUsersRoutes } from "./users";

const NonUserMessaging = React.lazy(
  () => import("screens/Frontoffice/screens/NonUserMessaging")
);
const LoginPartners = React.lazy(
  () => import("screens/Frontoffice/screens/Login")
);
const OnboardingPartnersCompany = React.lazy(
  () => import("screens/Frontoffice/screens/Onboarding/screens/CompanyForm")
);
const OnboardingPartnersCompanyDetails = React.lazy(
  () =>
    import("screens/Frontoffice/screens/Onboarding/screens/CompanyFormDetails")
);
const OnboardingPartnersUser = React.lazy(
  () => import("screens/Frontoffice/screens/Onboarding/screens/UserForm")
);
const OnboardingUserIntention = React.lazy(
  () => import("screens/Frontoffice/screens/Onboarding/screens/UserIntention")
);
const Registration = React.lazy(
  () =>
    import("screens/Frontoffice/screens/Registration/components/Registration")
);
const OauthCallback = React.lazy(() => import("./OauthCallback"));
const SettingsSources = React.lazy(
  () => import("screens/Frontoffice/screens/Sources")
);
const SettingsIntegrationList = React.lazy(
  () => import("screens/Frontoffice/screens/Integrations")
);
const DownloadReport = React.lazy(
  () => import("screens/Frontoffice/screens/DownloadReport")
);

const AccountMapping = React.lazy(
  () => import("screens/Frontoffice/screens/DataTables/screens/AccountMapping")
);

const DemoAccountMapping = React.lazy(
  () =>
    import(
      "screens/Frontoffice/screens/DataTables/screens/AccountMapping/components/DemoAccountMapping"
    )
);

const Measure = React.lazy(() => import("screens/Frontoffice/screens/Measure"));

export default [
  ...sharedUsersRoutes,
  {
    path: NonUserUrl.Base,
    component: NonUserMessaging,
    exact: true,
    isProtected: false,
    allowIncompleteOnboarding: true,
    hideNavbar: true,
  },
  {
    path: "/sudo/:userId",
    component: SudoInitiator,
    exact: true,
    isProtected: true,
    allowIncompleteOnboarding: true,
  },
  {
    path: "/register",
    component: Registration,
    exact: true,
    isProtected: false,
    allowIncompleteOnboarding: true,
    hideNavbar: true,
  },
  {
    path: "/login",
    exact: true,
    component: LoginPartners,
    isProtected: false,
    allowIncompleteOnboarding: true,
    hideNavbar: true,
  },
  {
    path: "/onboarding/company",
    component: OnboardingPartnersCompany,
    exact: true,
    isProtected: true,
    allowIncompleteOnboarding: true,
    hideNavbar: true,
  },
  {
    path: "/onboarding/company-details",
    component: OnboardingPartnersCompanyDetails,
    exact: true,
    isProtected: true,
    allowIncompleteOnboarding: true,
    hideNavbar: true,
  },
  {
    path: "/onboarding/user",
    component: OnboardingPartnersUser,
    exact: true,
    isProtected: true,
    allowIncompleteOnboarding: true,
    hideNavbar: true,
  },
  {
    path: "/onboarding/user-intention-influence-book-demo",
    component: OnboardingUserIntention, // this screen includes intention - influence source - book demo
    exact: true,
    isProtected: true,
    allowIncompleteOnboarding: true,
    hideNavbar: true,
  },
  {
    path: "/onboarding/partnership-activation",
    component: OnboardingActivatePartnerships,
    exact: true,
    isProtected: true,
    hideNavbar: true,
  },
  {
    path: "/oauth",
    routes: [
      {
        component: OauthCallback,
        path: "/:provider",
        isProtected: true,
      },
    ],
    allowIncompleteOnboarding: true,
  },
  {
    path: "/:provider/oauth",
    component: OauthCallback,
    isProtected: true,
    allowIncompleteOnboarding: true,
  },
  {
    path: "/analytics",
    component: Analytics,
    exact: true,
    isProtected: true,
    showSidebar: true,
    forceSidebarFolded: true,
  },
  {
    path: "/crm-accounts",
    component: Mapping360,
    exact: true,
    isProtected: true,
    showSidebar: true,
    forceSidebarFolded: true,
  },
  {
    path: "/directory",
    component: Directory,
    exact: true,
    isProtected: true,
    showSidebar: true,
    forceSidebarFolded: true,
  },
  {
    path: "/pipeline/:partnershipId?",
    component: MyPipeline,
    exact: true,
    isProtected: true,
    showSidebar: true,
    forceSidebarFolded: true,
  },
  {
    path: "/measure",
    component: Measure,
    exact: true,
    isProtected: true,
    showSidebar: true,
  },
  {
    path: "/attribute/:partnershipId?",
    component: MyPipelineAttribute,
    exact: true,
    isProtected: true,
    showSidebar: true,
    forceSidebarFolded: true,
  },
  {
    path: "/sources",
    component: SettingsSources,
    exact: true,
    isProtected: true,
    showSidebar: true,
  },
  {
    path: "/settings/team/:userId?",
    showSidebar: true,
    component: Users,
    isProtected: true,
    exact: true,
    short: "/team/:userId?",
  },
  {
    path: "/settings/directory",
    showSidebar: true,
    component: DirectorySettings,
    isProtected: true,
    exact: true,
  },
  {
    path: "/settings/company",
    showSidebar: true,
    component: CompanySettings,
    isProtected: true,
    exact: true,
  },
  {
    path: "/settings/notifications/:integrationId",
    showSidebar: true,
    component: NotificationsSettings,
    isProtected: true,
    exact: true,
  },
  {
    path: "/settings/get-intro-templates",
    showSidebar: true,
    component: GetIntroTemplate,
    isProtected: true,
    exact: true,
  },
  {
    path: "/settings/collaborate",
    showSidebar: true,
    component: CollaborateSettings,
    isProtected: true,
    exact: true,
  },
  {
    path: "/integrations/:section?/",
    component: SettingsIntegrationList,
    exact: true,
    isProtected: true,
    showSidebar: true,
  },
  {
    path: "/invite",
    component: Invite,
    exact: true,
    isProtected: true,
    showSidebar: true,
  },
  {
    path: "/invite-private",
    component: InvitePrivate,
    exact: true,
    isProtected: true,
    showSidebar: true,
  },
  {
    path: "/partnerships",
    routes: [
      {
        path: "/demo/account-mapping",
        exact: true,
        component: DemoAccountMapping,
        isProtected: true,
        showSidebar: true,
        forceSidebarFolded: true,
      },
      {
        path: "/:recordId/account-mapping",
        exact: true,
        component: AccountMapping,
        isProtected: true,
        showSidebar: true,
        forceSidebarFolded: true,
      },
      {
        path: "/:recordId/demo-settings/:tabName?/",
        exact: true,
        component: DemoPartnershipSettingsPage,
        isProtected: true,
        forceSidebarFolded: true,
        showSidebar: true,
      },
      {
        path: "/:recordId/settings/:tabName?/",
        exact: true,
        component: PartnershipSettings,
        isProtected: true,
        forceSidebarFolded: true,
        showSidebar: true,
      },
      {
        path: "/:recordId?/:dialogType?",
        exact: true,
        component: Partners,
        isProtected: true,
        showSidebar: true,
      },
      {
        path: "/:recordId/account-mapping/:resourceType",
        exact: true,
        component: RedirectToAccountMapping,
        isProtected: true,
        showSidebar: true,
        forceSidebarFolded: true,
      },
    ],
    showSidebar: true,
  },
  {
    path: "/i/:invitationId",
    exact: true,
    component: Orientation,
    isProtected: true,
    redirectToSignUp: true,
    showSidebar: true,
  },
  {
    path: "/sales-digest-generate",
    component: DownloadReport,
    exact: true,
    hideNavbar: true,
  },
  {
    path: "/reports-by-token/:recordId/download",
    component: DownloadReport,
    exact: true,
    hideNavbar: true,
  },
  {
    path: "/reports/:recordId/download",
    component: DownloadReport,
    exact: true,
    isProtected: true,
  },
  {
    path: "/orientation",
    short: "/",
    component: Orientation,
    isProtected: true,
  },
  {
    path: "/premium/:section",
    exact: true,
    component: OpenPremiumWidget,
    isProtected: true,
  },
  {
    path:
      "/add-to-pipeline-from-weekly-digest/:templateSource/:partnershipId/:matchId/:userId",
    exact: true,
    component: AddToPipelineFromWeeklyDigest,
    isProtected: true,
    hideNavbar: true,
  },
  {
    path: "/validated-digest-introduction-request/:templateSource",
    exact: true,
    component: IntroRequestPage,
    isProtected: false,
    hideNavbar: true,
  },
  {
    path: "/get-started",
    component: GetStarted,
    exact: true,
    isProtected: true,
    showSidebar: true,
    hideNavbar: true,
  },
] as RouteConfig[];
