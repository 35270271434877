import { createSelector } from "@reduxjs/toolkit";
import _ from "lodash";
import { PayingFeature } from "models/CompanyPayingFeatureSubscription";
import { selectActivePayingFeatures } from "redux/api/selectors";
import { RevealStore } from "redux/typing";
import { fields } from "screens/Frontoffice/screens/DataTables/screens/Analytics/constants";
import {
  MatchFieldType,
  PersistedColumnType,
  viewToColumns,
} from "screens/Frontoffice/screens/DataTables/shared/types";
import UserService from "services/UserService";

import {
  AdditionalAnalyticsColumns,
  analyticsColumnConfig,
  applyAnalyticsColumnOrder,
  defaultAnalyticsColumnConfig,
  defaultAnalyticsColumns,
  notFilterWidgetFilters,
  quickFilterFilters,
} from "./defaults";
import {
  AnalyticsFilterFieldname,
  AnalyticsOtherFilterFieldname,
  AnalyticsQuickFilterFieldname,
  AnalyticsQuickFilterType,
  RootStateWithAnalytics,
} from "./types";

export const selectAnalyticsView = (state: RootStateWithAnalytics) =>
  state.analytics;

export const selectHasAdvancedAnalytics = createSelector(
  selectActivePayingFeatures,
  (features) => {
    const service = new UserService();
    return Boolean(
      service.isImpersonating ||
        features.includes(PayingFeature.AdvancedAnalytics)
    );
  }
);

export const selectFields = createSelector(
  (state: RevealStore) => state.api.entities["users"] ?? {},
  (users) => {
    const _fields = _.cloneDeep(fields); // Without cloning fields will mute and can cause issues while debugging (ie: wrong console log values)
    for (let value of Object.values(_fields)) {
      if (value.type === MatchFieldType.USER) {
        value.options = Object.values(users).reduce((acc, user) => {
          acc[user.id] = user.fullName;
          return acc;
        }, {} as { [id: number]: string });
      }
    }
    return _fields;
  }
);

export const selectTableColumns = createSelector(
  selectFields,
  selectAnalyticsView,
  (fields, { view }) => {
    const _viewColumns: PersistedColumnType[] =
      view?.columns ?? defaultAnalyticsColumns;
    return viewToColumns(
      _viewColumns,
      fields,
      analyticsColumnConfig,
      defaultAnalyticsColumnConfig,
      applyAnalyticsColumnOrder
    );
  }
);

export const selectFilterWidgetFilters = createSelector(
  selectAnalyticsView,
  ({ view }) =>
    view?.filters.filter(
      (item) =>
        !notFilterWidgetFilters.includes(
          item.fieldname as AnalyticsFilterFieldname
        )
    ) ?? []
);

export const selectNotFilterWidgetFilters = createSelector(
  selectAnalyticsView,
  ({ view }) =>
    view?.filters.filter((item) =>
      notFilterWidgetFilters.includes(
        item.fieldname as AnalyticsFilterFieldname
      )
    ) ?? []
);

export const selecTagsFilter = createSelector(
  selectAnalyticsView,
  ({ view }) =>
    view?.filters.find(
      (item) => item.fieldname === AnalyticsOtherFilterFieldname.Tags
    ) ?? null
);

export const selectNotTagsFilters = createSelector(
  selectAnalyticsView,
  ({ view }) =>
    view?.filters.filter(
      (item) => item.fieldname !== AnalyticsOtherFilterFieldname.Tags
    ) ?? []
);

export const selectOffineFilter = createSelector(
  selectAnalyticsView,
  ({ view }) =>
    view?.filters.find(
      (item) => item.fieldname === AnalyticsOtherFilterFieldname.IncludeOffline
    ) ?? null
);

export const selectNotOffineFilters = createSelector(
  selectAnalyticsView,
  ({ view }) =>
    view?.filters.filter(
      (item) => item.fieldname !== AnalyticsOtherFilterFieldname.IncludeOffline
    ) ?? []
);

export const selectNotQuickFilterFilters = createSelector(
  selectAnalyticsView,
  ({ view }) =>
    view?.filters.filter(
      (item) =>
        !quickFilterFilters.includes(
          item.fieldname as AnalyticsQuickFilterFieldname
        )
    ) ?? []
);

export const selectQuickFilterType = createSelector(
  selectAnalyticsView,
  ({ view }) => {
    const filters = view?.filters ?? [];

    if (
      filters.find(
        (item) => item.fieldname === AnalyticsQuickFilterFieldname.Favorites
      )
    ) {
      return AnalyticsQuickFilterType.Favorites;
    } else if (
      filters.find(
        (item) =>
          item.fieldname === AnalyticsQuickFilterFieldname.HighWinRatePartners
      )
    ) {
      return AnalyticsQuickFilterType.HighWinRatePartners;
    } else if (
      filters.find(
        (item) => item.fieldname === AnalyticsQuickFilterFieldname.MyPartners
      )
    ) {
      return AnalyticsQuickFilterType.MyPartners;
    }
    return AnalyticsQuickFilterType.All;
  }
);

export const selectSummaryMetricsEnabled = createSelector(
  selectAnalyticsView,
  ({ view }) =>
    Boolean(
      view?.filters.some(
        (filter) =>
          filter.fieldname === AnalyticsOtherFilterFieldname.showSummaryMetrics
      )
    )
);

export const selectHighlightHighWinRateEnabled = createSelector(
  selectAnalyticsView,
  ({ view }) =>
    Boolean(
      view?.filters.some(
        (filter) =>
          filter.fieldname ===
          AnalyticsOtherFilterFieldname.highlightHighWinRate
      )
    )
);

export const selectExpandRevenueMetricsEnabled = createSelector(
  selectAnalyticsView,
  ({ view }) =>
    Boolean(
      view?.columns.some((column) =>
        (Object.values(AdditionalAnalyticsColumns) as string[]).includes(
          column.key
        )
      )
    )
);
