import { Box } from "@mui/material";
import { QuickFilter, QuickFilterInverted } from "components/icons";
import Dropdown from "components/ui/Dropdown/components/Dropdown";
import { IDropdownOption } from "components/ui/Dropdown/components/types";
import DropdownSubmitFooter from "components/ui/DropdownSubmitFooter";
import { makeStyles } from "makeStyles";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { selectDirectoryView } from "redux/directory/selectors";

import countries from "../countries.json";

interface ICountry {
  code: string;
  country: string;
}

interface IRegion {
  [key: string]: ICountry[];
}

interface IWorld {
  [key: string]: IRegion;
}

export const COUNTRIES_FOR_DROPDOWN: IDropdownOption[] = [];
for (const [region, countriesInRegion] of Object.entries(countries as IWorld)) {
  const subGroups: IDropdownOption[] = [];

  for (const [subRegion, countriesInSubRegion] of Object.entries(
    countriesInRegion
  )) {
    const subItems: IDropdownOption[] = countriesInSubRegion.map((country) => ({
      id: country.code,
      name: country.country,
      title: country.country,
    }));

    subGroups.push({
      id: `${region}${subRegion}`,
      name: subRegion,
      subItems: subItems,
      title: subRegion,
    });
  }

  COUNTRIES_FOR_DROPDOWN.push({
    id: `region-${region}`,
    name: region,
    subItems: subGroups,
  });
}

type Props = {
  onChange: (value: string[] | null) => void;
  onClose: () => void;
  anchorEl: Element | null;
};

export const DirectoryMarketPresenceFilter = ({
  anchorEl,
  onChange,
  onClose,
}: Props) => {
  const [values, setValues] = useState<string[] | null>(null);
  const { view } = useSelector(selectDirectoryView);
  const filters = view.filters;
  const { classes } = useStyles();

  useEffect(() => {
    setValues(
      filters.find((item) => item.fieldname === "market_presence")?.value as
        | string[]
        | null
    );
  }, [anchorEl, filters]);

  const handleChange = (value: string[] | null) => {
    setValues(value);
  };

  const handleSubmit = () => {
    onChange(values);
    onClose();
  };

  return (
    <>
      <Box className={classes.buttonContainer}>
        {(values?.length ?? 0) > 0 ? (
          <QuickFilterInverted className={classes.button} />
        ) : (
          <QuickFilter className={classes.buttonSmall} />
        )}
      </Box>
      <Dropdown
        value={values}
        anchorEl={anchorEl}
        onClose={onClose}
        options={COUNTRIES_FOR_DROPDOWN}
        onChange={handleChange}
        withSearch
        open={!!anchorEl}
        isMultiselect
        withCheckBox
        CustomFooter={
          <DropdownSubmitFooter
            onApply={handleSubmit}
            onCancel={onClose}
            disabledApplyBtn={false}
          />
        }
      />
    </>
  );
};

// TODO/RVL-8562: move these to a shared directory
export const useStyles = makeStyles()((theme) => ({
  button: {
    width: 16,
    height: 16,
  },
  buttonSmall: {
    width: 15,
    height: 15,
  },
  buttonContainer: {
    cursor: "pointer",
    display: "flex",
    gap: "8px",
    alignItems: "center",
    marginLeft: 4,
  },
}));
