import { lighten } from "@mui/material/styles";
import { isFulfilled, isRejected } from "@reduxjs/toolkit";
import { T } from "components/ui/Typography";
import UnauthorizedInfoDialog from "components/ui/UnauthorizedInfoDialog";
import diffObjects from "helpers/diffObjects";
import useAllRecords from "hooks/useAllRecords";
import usePushNotification from "hooks/usePushNotification";
import generic from "i18n/generic";
import _ from "lodash";
import { makeStyles } from "makeStyles";
import { AccountMappingStatus } from "models/AccountMappingSettings";
import { ShareOwner } from "models/AccountMappingSharingSettings";
import CrmField, { SwRecordType } from "models/CrmField";
import Integration from "models/Integration";
import Partnership, { DataShare } from "models/Partnership";
import { useCallback, useEffect, useMemo, useState } from "react";
import {
  defineMessages,
  FormattedHTMLMessage,
  FormattedMessage,
} from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { clearPartnershipCrmFields } from "redux/api/actions";
import { selectPartnershipCrmFields } from "redux/api/selectors";
import { rawPost } from "redux/api/thunks";
import { APICollection, PartnershipCrmField } from "redux/api/typing";
import { selectHasSources } from "redux/user/selectors";
import { useUpdateDataSharing } from "screens/Frontoffice/screens/Partners/shared/api";

import { TabProps } from "../../types";
import DataSharingDetails from "./DataSharingDetails";
import DataSharingTogglesBlock from "./DataSharingTogglesBlock";
import ShareCustomFieldSettings from "./ShareCustomFieldSettings";

const GeneralSettingsTab = ({
  partnership,
  profile,
  saving,
  afterSave,
  setSaveButtonEnabled,
  ...props
}: TabProps) => {
  const { classes } = useStyles();
  const dispatch = useDispatch();
  const pushNotification = usePushNotification();
  const updateDataSharing = useUpdateDataSharing();
  const partnershipCrmFields = useSelector(selectPartnershipCrmFields);

  /// State

  // State to trigger initialState recompute of memoized value.
  const [updateInitialState, setUpdateInitialState] = useState(0);
  const initialState = useMemo(
    () => ({
      dataSharingStatus: partnership.getDataSharingStatus(profile.company),
      shareOwner: partnership.getAccountOwnerSharing(profile.company),
      shareOpports: partnership.getAccountOpportsSharing(profile.company),
    }),
    [partnership, profile, updateInitialState] // eslint-disable-line react-hooks/exhaustive-deps
  );
  const [state, setState] = useState(initialState);
  const [sharedOpportunityFields, setSharedOpportunityFields] = useState(
    [] as CrmField[]
  );

  /// Request hooks

  const {
    loading: importedCrmFieldsLoading,
    records: importedCrmFields,
    refresh: refreshImportedCrmFields,
  }: {
    loading: boolean;
    refresh: () => void;
    records: CrmField[];
  } = useAllRecords("crm_fields", {
    filters: {
      integration__in: _.map(
        partnership.integrations,
        (integration: Integration) => integration.id
      ),
      import_field: true,
      syncable: true,
    },
    include: ["partnership_crm_fields"],
  });

  /// Data processing

  const rawCompanyImportedFields: CrmField[] = importedCrmFields
    .filter((field) => field.swRecordType === SwRecordType.RawCompany)
    .map(
      addSharingStatusAndPartnershipFieldId(partnershipCrmFields, partnership)
    );

  const rawOpportunityImportedFields: CrmField[] = useMemo(
    () =>
      importedCrmFields
        .filter((field) => field.swRecordType === SwRecordType.RawOpportunity)
        .map(
          addSharingStatusAndPartnershipFieldId(
            partnershipCrmFields,
            partnership
          )
        ),
    [importedCrmFields, partnershipCrmFields, partnership]
  );

  const sharedOpportunityFieldsFromBackend = useMemo(
    () =>
      rawOpportunityImportedFields.filter(
        (field) => field.shared && field.partnershipCrmFieldId !== null
      ),
    [rawOpportunityImportedFields]
  );

  useEffect(
    () => setSharedOpportunityFields(sharedOpportunityFieldsFromBackend),
    [importedCrmFields, partnershipCrmFields] // eslint-disable-line react-hooks/exhaustive-deps
  );

  /// Callbacks

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedOnSuccessNotification = useCallback(
    _.debounce(pushNotification, 500),
    [pushNotification]
  );

  const onSave = useCallback(async () => {
    const errors = await updateDataSharing(
      diffObjects(state, initialState),
      partnership,
      profile.company,
      props.partner.name
    );

    const updateSharedOpportsFields = async () =>
      dispatch(
        rawPost({
          type: partnership.type,
          id: partnership.id,
          path: "/shared-opportunity-fields/",
          payload: {
            data: sharedOpportunityFields.map((field) => ({
              type: field.type,
              id: field.id,
            })),
          },
        })
      );

    if (
      !_.isEqual(sharedOpportunityFields, sharedOpportunityFieldsFromBackend)
    ) {
      const response = await updateSharedOpportsFields();
      if (isFulfilled(response)) {
        // Whenever we update shared opportunity fields the back-end removes every entry
        // and add new ones (which have new `id`s). Because we rely on `createOrUpdateRecord`
        // to update the store and this function updates it like `store[recordType][id] = newRecord`,
        // we then have to clear all entries before refreshing, otherwise old `partnershipCrmField`s
        // would be kept and those still point to valid `CrmField`s, breaking this component logic.
        dispatch(clearPartnershipCrmFields());
        await refreshImportedCrmFields();
      } else if (isRejected(response)) {
        errors.push(response);
      }
    }

    if (errors.length > 0) {
      pushNotification("default_error");
    } else {
      debouncedOnSuccessNotification(generic.edits_saved);
      setUpdateInitialState((counter) => counter + 1);
    }

    afterSave();
  }, [
    debouncedOnSuccessNotification,
    state,
    partnership,
    profile,
    props.partner,
    sharedOpportunityFields,
    sharedOpportunityFieldsFromBackend,
    initialState,
    setUpdateInitialState,
    refreshImportedCrmFields,
    updateDataSharing,
    dispatch,
    pushNotification,
    afterSave,
  ]);

  useEffect(() => {
    if (saving) {
      onSave();
    }
  }, [saving]); // eslint-disable-line react-hooks/exhaustive-deps

  const toggleAndSetState = (
    toggleFn: (state: DataShare) => DataShare
  ) => () => {
    const dataSharingStatus = toggleFn(state.dataSharingStatus);
    setState({ ...state, dataSharingStatus });
  };

  /// Child components props

  const dataSharingTogglesBlockProps = {
    partnershipId: partnership.id,
    dataSharingStatus: state.dataSharingStatus,
    hasDataSource: useSelector(selectHasSources),
    hasDataSourceLinkedToPartnership: partnership.hasDataSource(),
    canManageDataSources: profile.hasPermissions(
      ["company.manage_sources"],
      profile.company
    ),
    partner: props.partner,
    onToggleOverlappingAccounts: toggleAndSetState(
      Partnership.toggleSharingOverlapping
    ),
    onToggleWhitespace: toggleAndSetState(Partnership.toggleSharingWhitespace),
    onToggleCommonCustomers: toggleAndSetState(
      Partnership.toggleSharingCommonCustomers
    ),
    disabled: saving,
  };

  const isDataSharingEnabled =
    partnership.hasDataSource() &&
    Partnership.isSharingOverlapping(state.dataSharingStatus);

  const dataSharingDetailsProps = {
    shareStatus: Partnership.isSharingAccountStatus(state.dataSharingStatus),
    shareOwner: state.shareOwner,
    shareOpports: state.shareOpports,
    onOwnerChange: (shareOwner: ShareOwner) =>
      setState({ ...state, shareOwner }),
    onOpportsChange: (shareOpports: boolean) =>
      setState({ ...state, shareOpports }),
    disabled: saving || !isDataSharingEnabled,
    loading: importedCrmFieldsLoading,
    isPartnerSharing: ![
      AccountMappingStatus.Inactive,
      AccountMappingStatus.RequestedByCompany,
    ].includes(partnership.getAccountMappingStatus(profile.company)),
    partnership: partnership,
    partner: props.partner,
    user: profile,
    allOpportunityFields: rawOpportunityImportedFields,
    sharedOpportunityFields,
    setSharedOpportunityFields: (sharedFields: CrmField[]) => {
      if (sharedFields.length > 0 && !state.shareOpports) {
        setState((oldState) => ({ ...oldState, shareOpports: true }));
      }
      setSharedOpportunityFields(sharedFields);
    },
  };

  /// State diff with back-end

  const changes = useMemo(() => diffObjects(state, initialState), [
    state,
    initialState,
  ]);
  const numberOfChanges = useMemo(
    () =>
      _.keys(changes).length +
      (_.isEqual(sharedOpportunityFields, sharedOpportunityFieldsFromBackend)
        ? 0
        : 1),
    [changes, sharedOpportunityFields, sharedOpportunityFieldsFromBackend]
  );

  useEffect(() => {
    setSaveButtonEnabled(numberOfChanges > 0);
  }, [numberOfChanges, setSaveButtonEnabled]);

  /// Not accessible for Ghost partnerhips

  if (partnership.isGhost()) {
    return <UnauthorizedInfoDialog callBackUrl={"/partnerships"} />;
  }

  /// Render

  return (
    <div className={classes.root}>
      {partnership.isPausedByPartner(profile.company) && (
        <div className={classes.infoBox}>
          <T oldVariant="caption">
            <FormattedMessage
              {...i18n.pausedByPartner}
              values={{ partnerName: partnership.getPartner(profile).name }}
            />
          </T>
        </div>
      )}
      <div className={classes.blocksWrapper}>
        <DataSharingTogglesBlock {...dataSharingTogglesBlockProps} />
        {/* @ts-expect-error Types of property 'partner' are incompatible.  Type 'PartnerRepresentation' is not assignable to type 'Record<string>'. */}
        <DataSharingDetails {...dataSharingDetailsProps} />
        <div className={classes.container}>
          {isDataSharingEnabled ? (
            <ShareCustomFieldSettings
              partnership={partnership}
              importedFields={rawCompanyImportedFields}
              fieldsLoading={importedCrmFieldsLoading}
              {...props}
            />
          ) : (
            <>
              <T h4 oldVariant="h5">
                <FormattedMessage {...i18n.customFieldsTitle} />
              </T>
              <T>
                <FormattedHTMLMessage {...i18n.customFieldsDisabled} />
              </T>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

const addSharingStatusAndPartnershipFieldId = (
  partnershipCrmFields: APICollection<PartnershipCrmField>,
  partnership: Partnership
) => (field: CrmField) => {
  const partnershipCrmField = _.values(partnershipCrmFields).find(
    (partnershipCrmField) =>
      `${partnershipCrmField.crmField.id}` === `${field.id}` &&
      `${partnershipCrmField.partnership.id}` === `${partnership.id}`
  );

  field.shared = Boolean(partnershipCrmField);
  field.partnershipCrmFieldId = partnershipCrmField?.id || null;

  return field;
};

const useStyles = makeStyles()((theme) => ({
  root: {
    flexDirection: "column",
    width: "100%",
  },
  title: {
    marginBottom: theme.spacing(2),
    paddingLeft: theme.spacing(4),
    [theme.breakpoints.down("md")]: {
      paddingLeft: theme.spacing(2),
    },
    [theme.breakpoints.down("sm")]: {
      paddingLeft: theme.spacing(0),
    },
  },
  infoBox: {
    backgroundColor: _.get(theme, "palette.other.yellow.light"),
    border: `2px solid ${lighten(
      _.get(theme, "palette.other.yellow.main", theme.palette.primary.main),
      0.6
    )}`,
    borderRadius: 3,
    width: "auto",
    padding: theme.spacing(1, 2),
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(4),
    [theme.breakpoints.down("md")]: {
      margin: theme.spacing(2),
    },
    [theme.breakpoints.down("sm")]: {
      margin: theme.spacing(1, 0),
    },
  },
  blocksWrapper: {
    height: "100%",
    overflowY: "auto",
  },
  container: {
    flexDirection: "column",
    marginBottom: theme.spacing(4),
    padding: theme.spacing(4),
    backgroundColor: theme.palette.ivory,
    borderRadius: theme.spacing(1),
    "& > :not(:last-child)": {
      marginBottom: theme.spacing(2),
    },
    [theme.breakpoints.down("md")]: {
      marginLeft: theme.spacing(2),
      marginRight: theme.spacing(2),
    },
    [theme.breakpoints.down("sm")]: {
      marginLeft: 0,
      marginRight: 0,
    },
  },
  customFieldsDisabledMsg: {
    fontStyle: "italic",
  },
}));

const i18n = defineMessages({
  pausedByPartner: {
    id: "crm.PartnershipSettings.GeneralSettingsTab.pausedByPartner",
    defaultMessage:
      "This partnership is currently being paused by {partnerName}.",
  },
  customFieldsTitle: {
    id: "crm.PartnershipSettings.GeneralSettingsTab.title",
    defaultMessage: "Shared custom fields",
  },
  customFieldsDisabled: {
    id: "crm.PartnershipSettings.GeneralSettingsTab.customFieldsDisabled",
    defaultMessage:
      "<em>Enable Data Sharing to edit your shared custom fields.</em>",
  },
});

export default GeneralSettingsTab;

export const _private = {
  addSharingStatusAndPartnershipFieldId,
};
