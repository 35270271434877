import { useDispatch, useSelector } from "react-redux";
import { markNotificationAsRead } from "redux/notifications/actions";
import { selectFirstUnreadNotification } from "redux/notifications/selectors";

import { Parameters } from "../components/ActionBtn/components/ExportOptions";
import { ReportType } from "../utils";
import { useSharedExport } from "./useSharedExport";

const useCsvExport = (reportType: ReportType, parameters: Parameters) => {
  const dispatch = useDispatch();
  const unreadNotification = useSelector(selectFirstUnreadNotification);

  const markAsReadLastNotification = () => {
    if (unreadNotification) {
      dispatch(markNotificationAsRead(unreadNotification.id));
    }
  };

  const { handleClick, isGenerating, ready } = useSharedExport(
    reportType,
    parameters,
    markAsReadLastNotification
  );

  return {
    isGenerating,
    ready,
    download: handleClick,
  };
};

export default useCsvExport;
