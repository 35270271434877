import { Box, Chip } from "@mui/material";
import { Link } from "components/icons";
import Tooltip from "components/ui/Tooltip";
import { crmProviders, ProviderType } from "config/constants";
import useAllRecords from "hooks/useAllRecords";
import useSegment from "hooks/useSegment";
import { useSlackButton } from "hooks/useSlackButton";
import useUserProfile from "hooks/useUserProfile";
import { makeStyles } from "makeStyles";
import Record from "models/Record";
import { useState } from "react";
import { defineMessages, FormattedMessage } from "react-intl";
import ReferOption from "screens/Frontoffice/screens/DataTables/shared/components/ActionBtn/components/ReferOption";
import ReferDialog from "screens/Frontoffice/screens/DataTables/shared/components/ReferDialog/components/ReferDialog";
import { DetailedViewType } from "screens/Frontoffice/screens/DataTables/shared/types";

type AccountActionButtonsProps = {
  account: Record<"nearbound_prospects" | "crm_accounts">;
};

const AccountActionButtons = ({ account }: AccountActionButtonsProps) => {
  const { track } = useSegment();
  const { profile } = useUserProfile();
  const isSalesUser = profile.isSales();
  const [referModalOpen, setReferModalOpen] = useState<boolean>(false);
  const { classes } = useStyles();
  const { records: slackCreds, loading: loadingSlackCreds } = useAllRecords(
    "slack_credentials",
    {
      include: ["user"],
    }
  );
  const activeSlack = !loadingSlackCreds && slackCreds.length > 0;
  const {
    handleOpenModal,
    tooltipTitle,
    dialog,
    slackIsActive,
  } = useSlackButton({
    activeSlack,
    account,
  });

  const handleCloseReferModal = () => setReferModalOpen(false);
  const handleOpenReferModal = () => setReferModalOpen(true);
  const openUrlInNewTab = (url: string) => {
    window.open(url, "_blank")?.focus();
    track("Clicked on Open in my CRM");
  };

  const copyToClipboard = async () => {
    const currentUrl = window.location.href;
    try {
      await navigator.clipboard.writeText(currentUrl);
    } catch (err) {}
  };

  return (
    <div className={classes.actionButtonsContainer}>
      {account.type === "crm_accounts" && (
        <>
          {!isSalesUser && (
            <>
              <ReferOption
                onlyButton
                selectedRowCount={1}
                openModal={handleOpenReferModal}
                sourceType={DetailedViewType.ACCOUNT_DRAWER}
              />
              <ReferDialog
                isOpen={referModalOpen}
                handleClose={handleCloseReferModal}
                recordsToRefer={[account as Record<"crm_accounts">]}
                sourceType={DetailedViewType.ACCOUNT_DRAWER}
              />
            </>
          )}
          <Tooltip title={tooltipTitle}>
            <Box display="flex" flexGrow={1}>
              <Chip
                disabled={!slackIsActive}
                variant="filled"
                className={classes.chip}
                avatar={<img src="/images/slack-logo.png" alt="" />}
                label={<FormattedMessage {...i18n.sendSlack} />}
                onClick={handleOpenModal}
              />
            </Box>
          </Tooltip>
          <Tooltip
            title={!account.url ? <FormattedMessage {...i18n.notInCrm} /> : ""}
          >
            <Box display="flex" flexGrow={1}>
              <Chip
                disabled={!account.url}
                variant="filled"
                className={classes.chip}
                avatar={
                  <img
                    src={
                      crmProviders?.[
                        account.integrationProvider as ProviderType
                      ]?.logo ?? "/images/providers/Salesforce.png"
                    }
                    alt=""
                  />
                }
                label={<FormattedMessage {...i18n.openInCrm} />}
                onClick={() => account.url && openUrlInNewTab(account.url)}
              />
            </Box>
          </Tooltip>
        </>
      )}
      <Chip
        variant="filled"
        className={classes.chip}
        avatar={<Link />}
        label={<FormattedMessage {...i18n.copyLinkToView} />}
        onClick={copyToClipboard}
      />
      {dialog}
    </div>
  );
};

export default AccountActionButtons;

// I18N

const i18n = defineMessages({
  copyLinkToView: {
    id: "crm.Ecosystem.AccountDetails.copyLinkToView",
    defaultMessage: "Copy link to this view",
  },
  notInCrm: {
    id: "crm.Ecosystem.AccountDetails.notInCrm",
    defaultMessage: "This Record in not in your CRM",
  },
  openInCrm: {
    id: "crm.Ecosystem.AccountDetails.openInCrm",
    defaultMessage: "Open in my CRM",
  },
  sendSlack: {
    id: "crm.Ecosystem.AccountDetails.sendSlack",
    defaultMessage: "Send on Slack",
  },
});

// CSS

export const useStyles = makeStyles()((theme) => ({
  actionButtonsContainer: {
    display: "flex",
    gap: theme.spacing(1),
    justifyContent: "space-between",
  },
  chip: {
    background: theme.palette.taupe500,
    borderRadius: 22,
    cursor: "pointer",
    flexGrow: 1,
    fontFamily: "'Inter', sans-serif",
    fontSize: 12,
    height: 36,
    padding: `${theme.spacing(1.5)} ${theme.spacing(1.5)}`,
    "&:hover": {
      background: theme.palette.taupe,
    },
    "& .MuiChip-avatar": {
      borderRadius: 2,
      height: 16,
      marginLeft: 0,
      marginRight: 2,
      width: 16,
    },
    "& .MuiChip-label": {
      padding: "0 10px",
    },
  },
}));
