import { ClassNameMap, FormControl, FormHelperText } from "@mui/material";
import { FormikErrors, FormikTouched } from "formik";
import useSegment from "hooks/useSegment";
import SuggestedPartner, {
  SuggestedPartnerSource,
} from "models/SuggestedPartner";
import { useMemo } from "react";
import {
  defineMessages,
  FormattedMessage,
  MessageDescriptor,
  useIntl,
} from "react-intl";
import { SuggestionFromCrmEvent } from "tracking";

import CompanyAutocomplete, {
  CompanySuggestion,
} from "../../CompanyAutocomplete";
import { IInviteForm, SelectedCompany } from "../types";

type Props = {
  classes?: ClassNameMap<string>;
  crmSuggestions?: SuggestedPartner[];
  errors: FormikErrors<IInviteForm>;
  handleChange: (event: any) => void;
  isSubmitting: boolean;
  selectedCompany?: SelectedCompany | null;
  setSelectedUser: (contact: null) => void;
  touched: FormikTouched<IInviteForm>;
  values: IInviteForm;
};

export const InviteFormCompanyAutocomplete = ({
  classes,
  crmSuggestions,
  errors,
  handleChange,
  isSubmitting,
  selectedCompany,
  setSelectedUser,
  touched,
  values,
}: Props) => {
  const intl = useIntl();
  const { track } = useSegment();
  const extraGroup = useMemo(() => {
    return crmSuggestions?.length
      ? {
          id: "3",
          name: intl.formatMessage(i18n.suggestedCompaniesFromCrm),
          group: crmSuggestions.map((item) => ({
            id: item.id,
            name: item.partnerName,
            logo: item.partnerLogo || "/images/providers/Salesforce.png",
            domain: item.domain,
          })),
        }
      : undefined;
  }, [crmSuggestions, intl]);

  const handleCompanyChange = (event: {
    target: {
      name: string;
      value: CompanySuggestion;
    };
    type: string;
  }) => {
    handleChange(event);
    setSelectedUser(null);
    if (
      crmSuggestions?.some(
        (suggestion) => suggestion.id === event.target.value.id
      )
    ) {
      track(SuggestionFromCrmEvent.clickedSuggestedPartner, {
        from: "invite-page",
        source: SuggestedPartnerSource.Crm,
      });
    }
  };

  const handleOnDelete = () => {
    handleChange({
      target: { name: "company", value: { name: "" } },
      type: "change",
    });
    if (
      crmSuggestions?.some((suggestion) => suggestion.id === values.company?.id)
    ) {
      track(SuggestionFromCrmEvent.removedSuggestedPartner, {
        from: "invite-page",
        source: SuggestedPartnerSource.Crm,
      });
    }
  };

  return (
    <FormControl
      fullWidth
      error={Boolean(touched.company && errors.company && errors.company.name)}
      className={classes?.companyForm}
    >
      <CompanyAutocomplete
        extraGroup={extraGroup}
        variant="newDesign"
        fullWidth
        name="company"
        onChange={handleCompanyChange}
        onDeleteCompany={handleOnDelete}
        placeholder={intl.formatMessage(i18n.companyName)}
        disabled={!!selectedCompany || isSubmitting}
        data-testid="company-field"
        autoFocus
        error={Boolean(errors?.company?.name)}
        value={values.company}
      />
      {errors?.company?.name && (
        <FormHelperText>
          <FormattedMessage {...(errors.company.name as MessageDescriptor)} />
        </FormHelperText>
      )}
    </FormControl>
  );
};

const i18n = defineMessages({
  companyName: {
    id: "partners.invite.company_name",
    defaultMessage: "Company Name",
  },
  suggestedCompaniesFromCrm: {
    id: "partners.invite.suggestedCompaniesFromCrm",
    defaultMessage: "Suggested companies from CRM",
  },
});
