import { Paper } from "@mui/material";
import SWQLBooleanNode from "components/ui/SWQL/SWQLBooleanNode";
import { SWQLContextProvider } from "components/ui/SWQL/SWQLContext";
import { emptyNode, SWQLNode, SWQLTarget } from "components/ui/SWQL/SWQLTypes";
import { mapCrmFieldToSWQLField } from "components/ui/SWQL/utils";
import { T } from "components/ui/Typography";
import { ProviderType } from "config/constants";
import getFirstValueFromURL from "helpers/getFirstValueFromURL";
import { makeStyles } from "makeStyles";
import CrmField from "models/CrmField";
import Match from "models/Match";
import { ReactNode, SetStateAction } from "react";
import { defineMessages, FormattedMessage } from "react-intl";
import { useLocation } from "react-router-dom";

import { RuleKeyAndLabel } from "./MappingRulesWidget";

type Props = {
  rules: {
    [ruleKey: number]: SWQLNode;
  };
  setRules: (
    value: SetStateAction<{
      [ruleKey: number]: SWQLNode;
    }>
  ) => void;
  currentRule: RuleKeyAndLabel;
  fields: CrmField[];
  integrationId: string | number;
  swqlTarget: SWQLTarget;
  isStatusRules?: boolean;
};

export const MappingRuleRow = ({
  rules,
  setRules,
  currentRule,
  fields,
  integrationId,
  swqlTarget,
  isStatusRules = false,
}: Props) => {
  const { classes } = useStyles();
  const location = useLocation();
  const provider = getFirstValueFromURL(location, "provider") as ProviderType;
  const isNewSource = getFirstValueFromURL(location, "init") as ProviderType;

  const hasSuggestion =
    isNewSource &&
    provider &&
    [
      ProviderType.salesforce,
      ProviderType.hubspot,
      ProviderType.pipedrive,
    ].includes(provider as ProviderType);

  const updateStatusRules = (ruleKey: number, node: SWQLNode) => {
    setRules({
      ...rules,
      [ruleKey]: node,
    });
  };

  const withStatusRulesDesign = (children: ReactNode) =>
    isStatusRules ? (
      <div className={classes.rule}>
        <div className={classes.ruleHeader}>
          <T h4 bold>
            {currentRule.label}
          </T>
          {hasSuggestion && (
            <div className={classes.suggestion}>
              <T bodySmall>
                <FormattedMessage {...i18n.suggestion} />
              </T>
              {getSuggestionTags(currentRule.key, provider)?.map((tag) => (
                <div className={classes.tag} key={tag?.id}>
                  <T bodySmall>
                    <FormattedMessage {...tag} />
                  </T>
                </div>
              ))}
            </div>
          )}
        </div>
        {children}
      </div>
    ) : (
      <>{children}</>
    );

  return withStatusRulesDesign(
    <Paper key={currentRule.key} className={classes.row}>
      {!isStatusRules && <T bodyBig>{currentRule.label}</T>}
      <SWQLContextProvider
        integrationId={integrationId}
        rootNode={rules[currentRule.key] || emptyNode()}
        onRootUpdate={(node: SWQLNode) =>
          updateStatusRules(currentRule.key, node)
        }
        swqlTarget={[swqlTarget]}
        fields={fields.map(mapCrmFieldToSWQLField)}
        enableDateTimes={true}
      >
        <SWQLBooleanNode pathToNode={[]} />
      </SWQLContextProvider>
    </Paper>
  );
};

// HELPERS

const getSuggestionTags = (ruleKey: number, provider: ProviderType) => {
  switch (ruleKey) {
    case Match.STATUS_CUSTOMER:
      return [getFirstTag(provider), i18n.isAnyOf, i18n.customer];
    case Match.STATUS_PROSPECT:
      return [getFirstTag(provider), i18n.isNoneOf, i18n.customerAndPartner];
    case Match.STATUS_PARTNER:
      return [getFirstTag(provider), i18n.isAnyOf, i18n.partner];
  }
};

const getFirstTag = (provider: ProviderType) => {
  switch (provider) {
    case ProviderType.salesforce:
      return i18n.accountType;
    case ProviderType.hubspot:
      return i18n.lifecycleStage;
    case ProviderType.pipedrive:
      return i18n.label;
  }
};

// CSS

const useStyles = makeStyles()((theme) => ({
  rule: {
    display: "flex",
    flexDirection: "column",
    gap: theme.spacing(1),
  },
  ruleHeader: {
    display: "flex",
    justifyContent: "space-between",
    padding: theme.spacing(0.5),
  },
  suggestion: {
    display: "flex",
    gap: 4,
    color: theme.palette.alpha500,
  },
  tag: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    background: theme.palette.greyLight100,
    borderRadius: 18,
    padding: "0px 8px 2px 8px",
  },
  row: {
    background: theme.palette.ivory,
    borderRadius: 8,
    display: "flex",
    flexDirection: "column",
    flexWrap: "wrap",
    padding: theme.spacing(2),
    rowGap: theme.spacing(1),
  },
}));

// I18N

const i18n = defineMessages({
  suggestion: {
    id: "MappingRulesWidget.suggestion",
    defaultMessage: "Suggestion:",
  },
  accountType: {
    id: "MappingRulesWidget.accountType",
    defaultMessage: "Account type",
  },
  lifecycleStage: {
    id: "MappingRulesWidget.lifecycleStage",
    defaultMessage: "Lifecycle stage",
  },
  label: {
    id: "MappingRulesWidget.label",
    defaultMessage: "Label",
  },
  isAnyOf: {
    id: "MappingRulesWidget.isAnyOf",
    defaultMessage: "is any of",
  },
  isNoneOf: {
    id: "MappingRulesWidget.isNoneOf",
    defaultMessage: "is none of",
  },
  customer: {
    id: "MappingRulesWidget.customer",
    defaultMessage: "Customer",
  },
  customerAndPartner: {
    id: "MappingRulesWidget.customerAndPartner",
    defaultMessage: "Customer AND Partner",
  },
  partner: {
    id: "MappingRulesWidget.partner",
    defaultMessage: "Partner",
  },
});
