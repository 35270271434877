import { AnyAction, createReducer } from "@reduxjs/toolkit";
import _ from "lodash";
import PartnershipOverviewView from "models/PartnershipOverviewView";

import { getUpdatedView, initialize, updateView } from "./thunks";
import { IOverviewState, IPartnershipOverviewState } from "./types";

const processResponseAndView = (
  state: IPartnershipOverviewState,
  action:
    | ReturnType<typeof updateView.fulfilled>
    | ReturnType<typeof initialize.fulfilled>
) => {
  const partnershipId = action.meta.arg.partnership.id;
  const response = action.payload.overview ?? {};
  const current = { ...state.views[partnershipId].state };
  const overviewState: IOverviewState =
    response === undefined
      ? current
      : {
          ...current,
          commonCustomers: response.common_customers
            ? {
                value: response.common_customers,
                loading: false,
                error: false,
              }
            : current.commonCustomers,
          commonOpenOpportunities: response.common_opportunities
            ? {
                value: response.common_opportunities,
                loading: false,
                error: false,
              }
            : current.commonOpenOpportunities,
          prospectToCustomers: response.prospects_to_customers
            ? {
                value: response.prospects_to_customers,
                loading: false,
                error: false,
              }
            : current.prospectToCustomers,
          customerToProspects: response.customers_to_prospects
            ? {
                value: response.customers_to_prospects,
                loading: false,
                error: false,
              }
            : current.customerToProspects,
          openOpportunitiesToCustomers: response.open_opportunities_to_customers
            ? {
                value: response.open_opportunities_to_customers,
                loading: false,
                error: false,
              }
            : current.openOpportunitiesToCustomers,
          nbMatchedAccounts:
            response.nb_matched_accounts ?? current.nbMatchedAccounts,
        };
  state.views[partnershipId] = {
    ...state.views[partnershipId],
    view: action.payload.view,
    state: overviewState,
  };
  if (isInitializeAction(action) && !_.isEmpty(action.payload.fields)) {
    state.views[partnershipId].fields = action.payload.fields;
  }
};

const isInitializeAction = (
  action: AnyAction
): action is ReturnType<typeof initialize.fulfilled> =>
  action.type === initialize.fulfilled.toString();

export const initState: IOverviewState = {
  accountOverlap: undefined,
  contactOverlap: undefined,
  commonCustomers: {
    error: false,
    loading: true,
  },
  commonOpenOpportunities: {
    error: false,
    loading: true,
  },
  prospectToCustomers: {
    error: false,
    loading: true,
  },
  customerToProspects: {
    error: false,
    loading: true,
  },
  openOpportunitiesToCustomers: {
    error: false,
    loading: true,
  },
  nbMatchedAccounts: undefined,
};

export default createReducer(
  {
    views: {},
  } as IPartnershipOverviewState,
  (builder) =>
    // @ts-ignore too deep
    builder
      .addCase(initialize.pending, (state, action) => {
        const partnership = action.meta.arg.partnership;
        if (!(partnership.id in state.views)) {
          state.views[partnership.id] = {
            fields: undefined,
            state: {
              ...initState,
              accountOverlap: partnership.insights?.marketOverlap,
              contactOverlap: partnership.insights?.personaOverlap,
            },
          };
        }
      })
      .addCase(initialize.fulfilled, processResponseAndView)
      .addCase(initialize.rejected, (state, action) => {
        const partnership = action.meta.arg.partnership;
        if (partnership.id in state.views) {
          const view = state.views[partnership.id];
          view.state.commonCustomers.error = true;
          view.state.commonCustomers.loading = false;
          view.state.commonOpenOpportunities.error = true;
          view.state.commonOpenOpportunities.loading = false;
          view.state.prospectToCustomers.error = true;
          view.state.prospectToCustomers.loading = false;
          view.state.customerToProspects.error = true;
          view.state.customerToProspects.loading = false;
          view.state.openOpportunitiesToCustomers.error = true;
          view.state.openOpportunitiesToCustomers.loading = false;
        }
      })
      .addCase(updateView.pending, (state, action) => {
        const partnership = action.meta.arg.partnership;
        if (partnership.id in state.views) {
          const view = state.views[partnership.id];
          if (view.view) {
            // @ts-ignore too deep
            const { view: updatedView } = getUpdatedView(
              action.meta.arg.parameters,
              view.view as PartnershipOverviewView
            );
            // @ts-ignore too deep
            view.view = updatedView;
          }
          const parameters = action.meta.arg.parameters;
          if ("common_customers" in parameters || "shared" in parameters) {
            view.state.commonCustomers.loading = true;
          }
          if ("common_opportunities" in parameters || "shared" in parameters) {
            view.state.commonOpenOpportunities.loading = true;
          }
          if (
            "prospects_to_customers" in parameters ||
            "shared" in parameters
          ) {
            view.state.prospectToCustomers.loading = true;
          }
          if (
            "customers_to_prospects" in parameters ||
            "shared" in parameters
          ) {
            view.state.customerToProspects.loading = true;
          }
          if (
            "open_opportunities_to_customers" in parameters ||
            "shared" in parameters
          ) {
            view.state.openOpportunitiesToCustomers.loading = true;
          }
        }
      })
      .addCase(updateView.fulfilled, processResponseAndView)
      .addCase(updateView.rejected, (state, action) => {
        const partnership = action.meta.arg.partnership;
        if (partnership.id in state.views) {
          const view = state.views[partnership.id];
          const parameters = action.meta.arg.parameters;
          if ("common_customers" in parameters || "shared" in parameters) {
            view.state.commonCustomers.loading = false;
            view.state.commonCustomers.error = true;
          }
          if ("common_opportunities" in parameters || "shared" in parameters) {
            view.state.commonOpenOpportunities.loading = false;
            view.state.commonOpenOpportunities.error = true;
          }
          if (
            "prospects_to_customers" in parameters ||
            "shared" in parameters
          ) {
            view.state.prospectToCustomers.loading = false;
            view.state.prospectToCustomers.error = true;
          }
          if (
            "customers_to_prospects" in parameters ||
            "shared" in parameters
          ) {
            view.state.customerToProspects.loading = false;
            view.state.customerToProspects.error = true;
          }
          if (
            "open_opportunities_to_customers" in parameters ||
            "shared" in parameters
          ) {
            view.state.openOpportunitiesToCustomers.loading = false;
            view.state.openOpportunitiesToCustomers.error = true;
          }
        }
      })
);
