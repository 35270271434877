import { Plus } from "components/icons";
import Button from "components/ui/Button";
import { T } from "components/ui/Typography";
import generic from "i18n/generic";
import { makeStyles } from "makeStyles";
import { defineMessages, FormattedMessage } from "react-intl";

type Props = {
  onGsheetClick: () => void;
  onCsvClick: () => void;
};

export const ConnectFileButtonGroup = ({
  onCsvClick,
  onGsheetClick,
}: Props) => {
  const { classes } = useStyles();

  return (
    <div className={classes.container}>
      <Button LeftIcon={Plus} label={i18n.gsheet} onClick={onGsheetClick} />
      <T bodySmall>
        <FormattedMessage {...generic.or} />
      </T>
      <Button LeftIcon={Plus} label={i18n.csv} onClick={onCsvClick} />
    </div>
  );
};

const useStyles = makeStyles()((theme) => ({
  container: {
    display: "flex",
    gap: theme.spacing(1),
    alignItems: "center",
    [theme.breakpoints.down("sm")]: {
      flexWrap: "wrap",
      height: "auto",
    },
  },
}));

const i18n = defineMessages({
  gsheet: {
    id: "ConnectSource.ConnectFileButtonGroup.Gsheet",
    defaultMessage: "Add Google Sheet",
  },
  csv: {
    id: "ConnectSource.ConnectFileButtonGroup.Csv",
    defaultMessage: "Add CSV File",
  },
});
