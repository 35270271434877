import { NotificationStatus } from "components/ui/Notifications/NotificationSnackbar";
import { Enum, toString } from "config/routes/Pages";
import useAmountFormatter, { AmountFormatter } from "hooks/useAmountFormatter";
import usePushNotification from "hooks/usePushNotification";
import useSegment from "hooks/useSegment";
import useSelectorWithDeepEquality from "hooks/useSelectorWithDeepEquality";
import useUserProfile from "hooks/useUserProfile";
import _ from "lodash";
import PartnerConnection from "models/PartnerConnection";
import Partnership from "models/Partnership";
import RawOpportunity from "models/RawOpportunity";
import Record from "models/Record";
import { JSONAPIResource } from "models/types";
import User from "models/User";
import { defineMessages, useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { bulkAddRecords } from "redux/api/actions";
import { selectAllPartnerships } from "redux/api/selectors";
import { NotificationActionType } from "redux/notifications/typing";
import { selectIsCreatedRecordFilteredOut } from "redux/pipeline/selectors";
import JSONAPIService from "services/JSONAPIService";
import { PipelineEvent, PipelineItemSource } from "tracking";

import { CrmFieldAmount, OpportunityItem } from "../helpers/constants";

type Props = {
  account?: Record<"crm_accounts">;
};

export const useCreatePipelines = ({ account }: Props) => {
  const pushNotification = usePushNotification();
  const { track } = useSegment();
  const dispatch = useDispatch();
  const { profile } = useUserProfile();
  const partnerships = useSelector(selectAllPartnerships) as Partnership[];
  const intl = useIntl();
  const amountFormatter = useAmountFormatter(intl);
  const isCreatedRecordFilteredOut = useSelectorWithDeepEquality(
    selectIsCreatedRecordFilteredOut
  );

  const createPipelines = async (
    selectedPartnershipIds: string[],
    selectedOpportunityIds: number[],
    onSuccess?: () => void,
    onError?: () => void,
    onFinally?: () => void
  ) => {
    if (!account) return;
    const service = new JSONAPIService("partner_connections");
    const payload = {
      data: getPipelineCreatePayload(
        profile,
        account.id,
        selectedPartnershipIds,
        selectedOpportunityIds
      ),
    };
    try {
      const response = await service.rawPost<{ data: any }>(
        "",
        "bulk/",
        payload,
        { include: ["discussion_participants"] }
      );
      const createdPartnerConnections =
        (response.data.data as JSONAPIResource<"partner_connections">[]) ?? [];
      await dispatch(bulkAddRecords(response.data));
      const createdPartnerConnectionsModels = createdPartnerConnections.map(
        (item) => new PartnerConnection(item)
      );
      const hiddenReasons = isCreatedRecordFilteredOut(
        createdPartnerConnectionsModels
      );
      if (_(hiddenReasons).values().some()) {
        const isOnlyTableFilters =
          !hiddenReasons.partnershipId &&
          !hiddenReasons.dateRange &&
          hiddenReasons.tableFilters;
        pushNotification(
          isOnlyTableFilters
            ? i18n.warningCreatedRecordCouldBeHidden
            : i18n.warningCreatedRecordIsHidden,
          {
            accountName: createdPartnerConnectionsModels[0]?.rawCompanyName,
            hiddenReasons: hiddenReasons,
          },
          { type: NotificationActionType.removePipelineFiltersActions }
        );
      }
      pushNotification(
        {
          status: NotificationStatus.success,
          message: i18n.partnerConnectionsCreatedWith,
        },
        {
          accountName: account.name,
          count: createdPartnerConnections.length,
          partnerName:
            partnerships
              .find(
                (partnership) =>
                  partnership.id === Number(selectedPartnershipIds[0])
              )
              ?.getPartner(profile).name ?? 0,
        },
        {
          type: NotificationActionType.addedToPipelineActions,
          partnerConnections: createdPartnerConnections.map(
            (item) => new PartnerConnection(item)
          ),
          rawCompanies: [account],
        }
      );
      onSuccess?.();
      track(PipelineEvent.addToPipeline, {
        from: toString(Enum.Collaborate),
        bulk: true,
        number_created: createdPartnerConnections.length,
      });
    } catch (_error: any) {
      onError?.();
      pushNotification(i18n.partnerConnectionsCreationFailed);
    } finally {
      onFinally?.();
    }
  };

  const opportunities = opportunitiesProps(
    account?.openOpportunities ?? [],
    amountFormatter,
    account?.name
  );

  return {
    createPipelines,
    opportunities,
  };
};

const getPipelineCreatePayload = (
  user: User,
  rawCompanyId: number,
  partnershipIds: string[],
  selectedOpportunityIds: number[]
) => {
  if (!partnershipIds.length) {
    return [
      {
        type: "partner_connections",
        attributes: {
          user_id: user.id,
          raw_company_id: rawCompanyId,
          partnership_id: null,
          opportunity_ids_to_attach: selectedOpportunityIds,
          source: PipelineItemSource.manual,
        },
      },
    ];
  }

  return partnershipIds.map((partnershipId) => ({
    type: "partner_connections",
    attributes: {
      user_id: user.id,
      raw_company_id: rawCompanyId,
      partnership_id: partnershipId,
      opportunity_ids_to_attach: selectedOpportunityIds,
      source: PipelineItemSource.manual,
    },
  }));
};

const isCrmFieldAmount = (value: any): value is CrmFieldAmount => {
  return _.isObject(value) && "amount" in value && "currency" in value;
};
const extraFieldRegex = /^(crm)Field\d+$/;
const opportunitiesProps = (
  opportunities: RawOpportunity[],
  amountFormatter: AmountFormatter,
  accountName?: string
): OpportunityItem[] =>
  _(opportunities)
    .filter((opportunity) => opportunity.isOpen)
    .map((opportunity) => {
      const opportunityFieldKeys = _.keys(opportunity).filter((key) =>
        extraFieldRegex.test(key)
      );
      let stage = "";
      let amountDetails = "";
      opportunityFieldKeys.forEach((key) => {
        const value = opportunity[key];

        // Check if the value is for the stage (assuming it's an array)
        if (_.isArray(value)) {
          stage = `${accountName ? accountName + " " : ""}${
            value[0] as string
          }`;
        }

        // Check if the value has an amount and currency (assuming it's an object with these properties)
        if (isCrmFieldAmount(value)) {
          amountDetails = amountFormatter(
            Number(value.amount),
            String(value.currency)
          );
        }
      });

      return {
        id: opportunity.id,
        stage,
        amountDetails,
      };
    })
    .value();

const i18n = defineMessages({
  partnerConnectionsCreatedWith: {
    id: "Pipeline.useCreatePipelines.partnerConnectionsCreatedWith",
    defaultMessage:
      "{count, plural, =0 {} one {1 Collaboration has} other {{count} Collaborations have}} been successfully created on {accountName} {count, plural, =0 {} one {{partnerName, plural, =0 {} other { with {partnerName}}}} other {}}",
  },
  partnerConnectionsCreationFailed: {
    id: "Pipeline.useCreatePipelines.partnerConnectionsCreationFailed",
    defaultMessage: "Something went wrong.",
  },
  warningCreatedRecordIsHidden: {
    id: "Pipeline.useCreatePipelines.warningCreatedRecordIsHidden",
    defaultMessage:
      "{accountName} was added to Collaborate, but is not visible because of filters.",
  },
  warningCreatedRecordCouldBeHidden: {
    id: "Pipeline.useCreatePipelines.warningCreatedRecordCouldBeHidden",
    defaultMessage:
      "{accountName} was added to Collaborate, but may not be visible because of filters.",
  },
});
