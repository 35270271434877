export function mapNullable<
  A extends null extends A ? never : undefined extends A ? never : any,
  B
>(value: A | undefined, operation: (value: A) => B): B | undefined;

export function mapNullable<
  A extends null extends A ? never : undefined extends A ? never : any,
  B
>(value: A | null, operation: (value: A) => B): B | null;

export function mapNullable<
  A extends null extends A ? never : undefined extends A ? never : any,
  B
>(
  value: A | null | undefined,
  operation: (value: A) => B
): B | null | undefined;

export function mapNullable<
  A extends null extends A ? never : undefined extends A ? never : any,
  B
>(
  value: A | null | undefined,
  operation: (value: A) => B
): B | null | undefined {
  if (value === null) {
    return null;
  }

  if (value === undefined) {
    return undefined;
  }

  return operation(value);
}
