import { T } from "components/ui/Typography";
import { makeStyles } from "makeStyles";
import { defineMessages, FormattedMessage } from "react-intl";
import { Link, useLocation } from "react-router-dom";

const LinkToRegister = () => {
  const { classes } = useStyles();
  const { search } = useLocation();

  return (
    <T textAlign="center">
      <FormattedMessage {...i18n.notMember} />{" "}
      <Link to={`/register${search}`} className={classes.linkColor}>
        <FormattedMessage {...i18n.signUp} />
      </Link>
    </T>
  );
};

export default LinkToRegister;

// I18N

const i18n = defineMessages({
  notMember: {
    id: "LinkToRegister.notMember",
    defaultMessage: "Don't have an account yet?",
  },
  signUp: {
    id: "LinkToRegister.login",
    defaultMessage: "Create account",
  },
});

// CSS

const useStyles = makeStyles()((theme) => ({
  linkColor: {
    color: theme.palette.link,
  },
}));
