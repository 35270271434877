import _ from "lodash";
import { AvailableAnalytisColumns } from "redux/analytics/defaults";

export const isPartnerAnalyticsPercentageValue = (
  fieldName: string,
  row: any
) => {
  return (
    row?.type === "partner_analytics" &&
    (fieldName === AvailableAnalytisColumns.marketOverlap ||
      fieldName === AvailableAnalytisColumns.personaOverlap)
  );
};

export const partnerAnalyticPercentageValueFormatter = (
  row: any,
  fieldName: string
) => {
  let value = _.get(row, `metrics.${fieldName}`, "--");
  if (value !== "--") {
    let percentage = (value * 100).toFixed(0);
    value = `${percentage}%`;
  }
  return value;
};
