import { RootStateWithSuggestedPartners } from "redux/suggestedPartners/types";
import {
  FilterType,
  PersistedColumnType,
  SortType,
} from "screens/Frontoffice/screens/DataTables/shared/types";

export enum DirectoryActions {
  setFilters = "directory/setFilters",
  setSort = "directory/setSort",
}

export interface DirectoryState {
  view: {
    filters: FilterType[];
    sort: SortType[];
    columns: PersistedColumnType[];
  };
}

export interface RootStateWithDirectory extends RootStateWithSuggestedPartners {
  directory: DirectoryState;
}

export enum DirectoryToggleFilterType {
  IsInCrm = "isInCrm",
  IsRecommended = "isRecommended",
  IsYouMayKnow = "isYouMayKnow",
}

export enum DirectoryFacets {
  all = "all",
  IsInCrm = "is_in_crm",
  IsRecommended = "is_recommended",
  IsYouMayKnow = "is_you_may_know",
}
