import { SWQLTarget } from "components/ui/SWQL/SWQLTypes";
import Match from "models/Match";
import {
  defineMessages,
  FormattedHTMLMessage,
  FormattedMessage,
} from "react-intl";
import { useHistory, useLocation } from "react-router-dom";
import {
  crmFileProviderLabelAndLogos,
  crmProviders,
} from "screens/Frontoffice/screens/Sources/constants";

import { updateHistoryUpdatedSource } from "../helpers/utils";
import BaseMappingRulesWidget from "./BaseMappingRulesWidget";

const i18n = defineMessages({
  summary: {
    id: "crm.Settings.lists.listSettings.summary",
    defaultMessage:
      "Please help us understand how you define Customers, Prospects and Partners in your {provider}.",
  },
  custormersAre: {
    id: "crm.settings.lists.customers_are",
    defaultMessage: "<b>Customers</b> are companies where",
  },
  prospectsAre: {
    id: "crm.settings.lists.prospects_are",
    defaultMessage: "<b>Prospects</b> are companies where",
  },
  partnersAre: {
    id: "crm.settings.lists.partners_are",
    defaultMessage: "<b>Partners</b> are companies where",
  },
});

type Props = {
  integrationId: number;
  handleClose: () => void;
  sourceType: string;
  callback?: () => void;
  noFieldsCallback?: () => void;
  hideSummary?: boolean;
  isGhost?: boolean;
};

const StatusRulesWidget = ({ sourceType, ...props }: Props) => {
  const history = useHistory();
  const location = useLocation();
  const provider = ({ ...crmProviders, ...crmFileProviderLabelAndLogos } as {
    [key: string]: { name: string };
  })?.[sourceType]?.name;

  const ruleList = [
    {
      key: Match.STATUS_CUSTOMER,
      label: <FormattedHTMLMessage {...i18n.custormersAre} />,
    },
    {
      key: Match.STATUS_PROSPECT,
      label: <FormattedHTMLMessage {...i18n.prospectsAre} />,
    },
    {
      key: Match.STATUS_PARTNER,
      label: <FormattedHTMLMessage {...i18n.partnersAre} />,
    },
  ];

  const handleSave = () => {
    updateHistoryUpdatedSource(history, location);
    props?.callback?.();
  };

  return (
    <BaseMappingRulesWidget
      ruleList={ruleList}
      attribute={Match.STATUS_ATTRIBUTE}
      swqlTarget={SWQLTarget.RawCompany}
      summary={<FormattedMessage {...i18n.summary} values={{ provider }} />}
      trackedLocation="data source status rules"
      saveCallback={handleSave}
      {...props}
    />
  );
};

export default StatusRulesWidget;
