import Skeleton from "@mui/material/Skeleton";
import { T } from "components/ui/Typography";
import useUserProfile from "hooks/useUserProfile";
import _ from "lodash";
import { makeStyles } from "makeStyles";
import { PayingFeature } from "models/CompanyPayingFeatureSubscription";
import Partnership from "models/Partnership";
import Record from "models/Record";
import { useSelector } from "react-redux";
import {
  selectActivePayingFeatures,
  selectAllPartnerships,
} from "redux/api/selectors";
import { AvailableNearboundAccountsColumns } from "redux/mapping360NearboundAccounts/defaults";

import PartnerChip from "../PartnerChip";
import { CellProps } from "../utils";

export const DEFAULT_WIDTH = 400;

type Props = CellProps & {
  width: number;
};

const PartnerPresenceCellNearboundAccounts = ({
  fieldName,
  value,
  row,
  width,
}: Props) => {
  const { classes, cx } = useStyles();
  const { profile } = useUserProfile();
  const payingFeatures = useSelector(selectActivePayingFeatures);
  const partnerships = useSelector(selectAllPartnerships) as Partnership[];
  const hasAdvancedAnalyticsPayingFeature = payingFeatures.includes(
    PayingFeature.AdvancedAnalytics
  );
  const displayedCompanies = value || ([] as Record[]);

  const { hiddenCount } = getDisplayedList(value || [], width);

  return (
    <div className={classes.root}>
      {_.isEmpty(displayedCompanies) && <T className={classes.emptyCell}>--</T>}
      {displayedCompanies.map((item: Record, index: number) => {
        const partnership = partnerships.find(
          (partnership) => item.partnership_id === partnership.id
        );
        const partner = partnership?.getPartner(profile);

        return (
          <PartnerChip
            key={index}
            partnerName={partner?.name}
            avatarUrl={partner?.avatarUrl}
            isGold={
              fieldName === AvailableNearboundAccountsColumns.CustomerOf &&
              hasAdvancedAnalyticsPayingFeature &&
              partnership &&
              item.high_win_rate
            }
          />
        );
      })}
      {Boolean(hiddenCount) && (
        <div className={cx(classes.countContainer, "partnerPresenceGradient")}>
          <T uppercase textAlign="right" className={classes.countText}>
            +{hiddenCount}
          </T>
        </div>
      )}
    </div>
  );
};

// Helpers

export const isPartnerPresenceCellNearboundAccounts = ({
  fieldName,
}: CellProps) => {
  return (
    fieldName === AvailableNearboundAccountsColumns.CustomerOf ||
    fieldName === AvailableNearboundAccountsColumns.OpenOpportunityFor
  );
};

export const getPartnerPresenceCopyContent = ({
  value,
  fieldName,
  row,
}: CellProps) => {
  return (value.map((company: Record) => company.name) || []).join(", ");
};

const getItemWidth = (name: string | undefined) => {
  const padding = 4 + 6 + 8;
  const avatar = 24;
  const columnGap = 6;
  return name ? name.length * 7 + padding + avatar + columnGap : 0;
};

const shouldDisplayItem = (
  itemWidth: number,
  currentWidth: number,
  columnWidth: number
) => currentWidth + itemWidth < columnWidth;

const getDisplayedList = (presentPartners: Record[], columnWidth: number) => {
  let currentWidth = 0;
  let displayedCompanies: Record[] = [];
  let hiddenCount = 0;
  for (var key in presentPartners) {
    const itemWidth = getItemWidth(presentPartners[key].partner_name);
    if (shouldDisplayItem(itemWidth, currentWidth, columnWidth)) {
      currentWidth += itemWidth;
      displayedCompanies.push(presentPartners[key]);
    } else {
      hiddenCount += 1;
    }
  }
  return { displayedCompanies, hiddenCount };
};

export const PartnerPresenceSkeleton = ({
  itemCount,
}: {
  itemCount: number;
}) => {
  const { classes } = useStyles();

  const itemPartner = (
    <div className={classes.skeletonContainer}>
      <Skeleton
        variant="circular"
        width={24}
        height={24}
        animation={false}
        style={{ filter: "blur(2px)" }}
      />
      <Skeleton
        variant="text"
        width={60}
        animation={false}
        style={{ filter: "blur(2px)" }}
      />
    </div>
  );
  return (
    <div className={classes.skeletonRoot}>
      {_.times(itemCount, () => itemPartner)}
    </div>
  );
};

export const getRandomValue = (min: number, max: number) => {
  return Math.ceil(Math.random() * (max - min) + min);
};

// I18N

const useStyles = makeStyles()((theme) => ({
  root: {
    position: "relative",
    display: "flex",
    alignItems: "center",
    columnGap: 6,
    cursor: "pointer",
    width: "100%",
  },
  countContainer: {
    position: "absolute",
    display: "flex",
    height: "100%",
    right: "-10px",
    width: 50,
    alignItems: "center",
    justifyContent: "end",
    paddingRight: 10,
    background: `linear-gradient(270deg, ${theme.palette.ivory} 50.52%, rgba(250, 250, 246, 0) 100%)`,
  },
  countText: {
    borderRadius: 17,
    padding: "3px 6px",
    width: "fit-content",
    backgroundColor: theme.palette.greyscale150,
  },
  emptyCell: {
    marginLeft: theme.spacing(1),
  },
  skeletonRoot: {
    display: "flex",
    alignItems: "center",
    columnGap: theme.spacing(2),
  },
  skeletonContainer: {
    display: "flex",
    alignItems: "center",
    columnGap: theme.spacing(1),
    width: "fit-content",
  },
}));

export default PartnerPresenceCellNearboundAccounts;
