import { isRejected } from "@reduxjs/toolkit";
import axios, { AxiosResponse } from "axios";
import getFirstValueFromURL from "helpers/getFirstValueFromURL";
import usePushNotification from "hooks/usePushNotification";
import useSelectorWithDeepEquality from "hooks/useSelectorWithDeepEquality";
import generic from "i18n/generic";
import _ from "lodash";
import Record from "models/Record";
import { RecordType } from "models/types";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useIntl } from "react-intl";
import { useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";
import { AccountMappingResource } from "redux/accountMapping/types";
import { index } from "redux/api/thunks";
import { RevealStore } from "redux/typing";
import {
  FieldType,
  FilterType,
  MatchFilter,
  MatchSort,
  RawFieldType,
  SortType,
} from "screens/Frontoffice/screens/DataTables/shared/types";
import {
  camelCaseWithDoubleUnderscore,
  snakeCaseNameForNearboundAccountField,
} from "screens/Frontoffice/shared/helpers/snakeCase";
import { CancellablePromise } from "services/BaseHTTPService";
import JSONAPIService from "services/JSONAPIService";
import { FilterValue, JSONAPIOptions } from "services/types";

/* eslint-disable react-hooks/exhaustive-deps */

const DEFAULT_BATCH_SIZE = 25;
const NB_PAGES = 2;

const FILTERS_TO_KEEP_WHEN_LOADING_SLICE = {
  [AccountMappingResource.leadMatches]: ["leftOwnerName"],
};

const useTableRows = (
  resource:
    | AccountMappingResource
    | "crm_accounts"
    | "referred_accounts"
    | "partner_analytics"
    | RecordType
    | "directory_companies"
    | "nearbound_prospects"
    | "nearbound_accounts",
  filters: FilterType[] = [],
  sort: SortType[] = [],
  partnershipId: number | null = null,
  isActive: boolean = true,
  include: string[] = [],
  fieldsToFetch: { [resource: string]: string[] } = {},
  additionalFieldsToFetch?: string[],
  companyId: number | null = null,
  skipCollectFields: boolean = false,
  useDescribeOptionsMethod: boolean = false,
  callbackAfterFetch?: (slice: number[]) => void
) => {
  const dispatch = useDispatch();
  const [fieldsLoaded, setFieldsLoaded] = useState(false);
  const [fields, setFields] = useState<{ [fieldname: string]: FieldType }>({});
  const [openDealFields, setOpenDealFields] = useState<{
    [fieldname: string]: FieldType;
  }>({});
  const [rawFields, setRawFields] = useState<{ name: string }[]>([]);
  const [fetching, setFetching] = useState(0);
  const [refreshCount, setRefreshCount] = useState(0);
  const [recordIds, setRecordIds] = useState<number[] | null>(null);
  const [totalCount, setTotalCount] = useState<number | null>(null);
  const currentPromise = useRef<CancellablePromise<
    AxiosResponse<{ data: number[] }>
  > | null>(null);
  const currentFieldPromise = useRef<CancellablePromise<AxiosResponse> | null>(
    null
  );
  const pushNotification = usePushNotification();
  const location = useLocation();
  const intl = useIntl();
  const batchSize = +getFirstValueFromURL(
    location,
    "batchSize",
    String(DEFAULT_BATCH_SIZE)
  );

  const sync = recordIds !== null;
  const count = recordIds?.length ?? null;

  const allRecords = useSelectorWithDeepEquality(
    (state: RevealStore) => state.api.entities[resource] ?? {}
  );
  const loadedRecords: (Record | undefined)[] = (recordIds ?? []).map(
    (id) => allRecords[id]
  );
  const records = loadedRecords.filter(Boolean) as Record[];

  const [page, setPage] = useState(1);
  const hasMore =
    recordIds === null ? true : records?.length < recordIds.length;

  const loadMore = useCallback(() => setPage((page) => page + NB_PAGES), [
    resource, // Used to reset scroll listener
  ]);

  const addUnassignedInOptions = (field: RawFieldType) =>
    field.name.includes("owner");

  const collectFields = useCallback(
    async (options = {}) => {
      try {
        const service = new JSONAPIService(resource);
        const filters: { [filterName: string]: FilterValue } = {};
        if (partnershipId) {
          if (resource === AccountMappingResource.matched_accounts) {
            filters.partnership_id = partnershipId;
          } else {
            filters.partnership = partnershipId;
          }
        }
        // Get the fields
        currentFieldPromise.current = service.describe<{
          data: {
            included: { [key: string]: { fields: RawFieldType[] } };
            fields: RawFieldType[];
          };
        }>(
          resource === AccountMappingResource.matched_accounts ??
            useDescribeOptionsMethod,
          {
            filters,
            ...options,
          }
        );
        const resourceDescriptionResponse = await currentFieldPromise.current;

        // Merge fields with open-opportunity fields
        const included_fields =
          resourceDescriptionResponse.data.data?.included || {};
        const fields = (
          resourceDescriptionResponse.data.data?.fields || []
        ).concat(
          ..._.map(
            _.values(included_fields),
            (included) => included?.fields || []
          )
        );
        setRawFields(fields);
        const openDealFields =
          resourceDescriptionResponse.data.data?.open_deal_fields ?? [];

        const processField = (field: RawFieldType): FieldType => ({
          label: field.label,
          type: field.type,
          options: addUnassignedInOptions(field)
            ? {
                ...field.options,
                // @ts-expect-error because null cannot be used as index but we need to
                [null]: intl.formatMessage(generic.unassigned),
              }
            : field.options,
          smartField: Boolean(field.smart_field),
          fullyImported: Boolean(field.fully_imported),
          isDisabled: Boolean(field.disabled),
          noSide: Boolean(field.no_side),
          isPrivate: Boolean(field.private),
          isPartiallyShared: Boolean(field.partially_shared),
          partnershipCrmFieldId: field.partnership_crm_field_id,
          partnerField: field.name.startsWith("right"),
          sort: field.sort ?? true,
          filter: field.filter ?? true,
          column: field.column ?? true,
          allowedOperations: field.allowed_operations,
          jsonPath: field.json_path,
          displayIndex: field.display_index,
        });

        const processedFields = _.transform(
          fields,
          (result, field) => {
            let key;
            if (field.json_path && field.json_path.length > 1) {
              key = snakeCaseNameForNearboundAccountField(
                field.name,
                field.json_path
              );
            } else {
              key = camelCaseWithDoubleUnderscore(field.name);
            }
            result[key] = processField(field);
          },
          {} as { [fieldName: string]: FieldType }
        );
        const processedOpenDealFields = _.transform(
          resource === AccountMappingResource.matched_accounts
            ? _.concat(
                openDealFields.left_open_deals,
                openDealFields.right_open_deals
              )
            : openDealFields,
          (result, field) => {
            let key;
            if (field.json_path && field.json_path.length > 1) {
              key = snakeCaseNameForNearboundAccountField(
                field.name,
                field.json_path
              );
            } else {
              key = camelCaseWithDoubleUnderscore(field.name);
            }
            result[key] = processField(field);
          },
          {} as { [fieldName: string]: FieldType }
        );
        // Save them.
        setFields(processedFields);
        setOpenDealFields(processedOpenDealFields);
      } catch (error) {
        if (!axios.isCancel(error)) {
          pushNotification("default_error");
        }
      }
    },
    [resource]
  );

  const loadSlice = async (page: number, recordIds: number[]) => {
    const slice = recordIds.slice((page - 1) * batchSize, page * batchSize);
    if (slice.length > 0) {
      setFetching((current) => current + 1);
      const sliceFilters: { [filterName: string]: FilterValue } = {
        "id.in": slice.join(","),
        ...MatchFilter.forAPI(
          filters.filter((filter: FilterType) =>
            _.get(FILTERS_TO_KEEP_WHEN_LOADING_SLICE, resource, []).includes(
              filter.fieldname
            )
          )
        ),
      };
      if (partnershipId) {
        sliceFilters[
          resource === AccountMappingResource.matched_accounts
            ? "partnership_id"
            : "partnership"
        ] = partnershipId;
      }
      if (companyId) {
        sliceFilters["company_id"] = companyId;
      }
      const result = await dispatch(
        index({
          type: resource,
          options: {
            filters: sliceFilters,
            include,
            fields: additionalFieldsToFetch
              ? {
                  [resource]: [
                    ...rawFields.map((item) => item.name),
                    ...additionalFieldsToFetch,
                    ...include,
                  ],
                }
              : fieldsToFetch,
          },
          config: {
            headers: {
              "X-No-Count": "true",
            },
          },
        })
      );
      if (isRejected(result)) {
        pushNotification(generic.error_unknown);
      }
      setFetching((current) => current - 1);
      if (callbackAfterFetch) {
        callbackAfterFetch(slice);
      }
    }
  };

  const queryFilters: { [filter: string]: number } = {};
  if (partnershipId) {
    queryFilters[
      resource === AccountMappingResource.matched_accounts
        ? "partnership_id"
        : "partnership"
    ] = partnershipId;
  }
  if (companyId) {
    queryFilters["company_id"] = companyId;
  }

  const queryOptions = useMemo(
    () =>
      ({
        filters: !_.isEmpty(queryFilters)
          ? _.merge(queryFilters, MatchFilter.forAPI(filters, fields))
          : MatchFilter.forAPI(filters, fields),
        sort: sort.map((item) =>
          new MatchSort(item.fieldname, item.order).apiName(
            fields?.[item.fieldname]?.jsonPath
          )
        ),
        /* eslint-enable-line react-hooks/exhaustive-deps */
      } as JSONAPIOptions),
    [fields, filters, queryFilters, sort]
  );

  const deps: any[] = [filters.filter(({ value }) => value !== ""), sort];
  if (partnershipId) {
    deps.push(partnershipId);
  }
  const loadRecordIds = useCallback(async () => {
    setRecordIds(null);
    if (
      isActive &&
      (skipCollectFields ||
        (!skipCollectFields && fieldsLoaded && rawFields.length > 0))
    ) {
      try {
        const service = new JSONAPIService(resource);
        currentPromise.current = service.rawGet<{ data: number[] }>(
          "ids/",
          queryOptions
        );
        const response = await currentPromise.current;
        setRecordIds(response.data.data);
        const totalCount = _.get(response.headers, "x-total-count", null);
        setTotalCount(totalCount === "None" ? null : totalCount);
        setPage(1);
      } catch (error) {
        if (!axios.isCancel(error)) {
          pushNotification("default_error");
        }
      }
    }
  }, [
    JSON.stringify(deps),
    isActive,
    resource,
    fieldsLoaded,
    rawFields.length,
  ]);

  useEffect(() => {
    loadRecordIds();
    return () => {
      if (currentPromise.current && currentPromise.current.cancel) {
        currentPromise.current.cancel();
      }
    };
  }, [loadRecordIds, refreshCount]);

  useEffect(() => {
    if (!skipCollectFields) {
      setFieldsLoaded(false);
      collectFields(
        resource === AccountMappingResource.matched_accounts
          ? { include_owner_options: false }
          : {}
      ).then(() => setFieldsLoaded(true));
    }
    return () => {
      if (currentFieldPromise.current && currentFieldPromise.current.cancel) {
        currentFieldPromise.current.cancel();
      }
    };
  }, [isActive, partnershipId, resource]);

  useEffect(() => {
    // If we are in the new account mapping view
    // We perform a second call to fetch owner options
    if (
      !skipCollectFields &&
      resource === AccountMappingResource.matched_accounts &&
      fieldsLoaded &&
      (recordIds?.length ?? 0) > 0
    ) {
      collectFields({ include_owner_options: true });
    }
  }, [
    isActive,
    partnershipId,
    resource,
    fieldsLoaded,
    (recordIds?.length ?? 0) > 0,
  ]);

  useEffect(() => {
    if (recordIds) {
      for (let i = 0; i < NB_PAGES; i++) {
        loadSlice(page + i, recordIds);
      }
    }
  }, [page, JSON.stringify(recordIds)]);

  const isFetching = fetching > 0;
  return useMemo(
    () => ({
      count,
      totalCount,
      fetching: isFetching,
      fields,
      hasMore,
      loadMore,
      openDealFields,
      records,
      sync,
      collectFields: skipCollectFields ? () => {} : collectFields,
      refresh: () => setRefreshCount((current) => current + 1),
    }),
    [
      JSON.stringify(openDealFields),
      resource,
      count,
      totalCount,
      sync,
      isFetching,
      hasMore,
      JSON.stringify(fields),
      loadMore,
      JSON.stringify(records),
    ]
  );
};

export default useTableRows;
