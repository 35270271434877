import { ArrowRight, CloseModal } from "components/icons";
import Button from "components/ui/Button";
import DialogManager from "components/ui/DialogManager";
import { T } from "components/ui/Typography";
import muiTheme from "config/theme";
import useSegment from "hooks/useSegment";
import useUserProfile from "hooks/useUserProfile";
import { makeStyles } from "makeStyles";
import { useEffect } from "react";
import { defineMessages, FormattedMessage, useIntl } from "react-intl";
import { useDispatch } from "react-redux";
import { update } from "redux/api/thunks";
import { fetchProfileInBackground } from "redux/user/thunks";
import { GetStartedEvent } from "tracking";

type Props = {
  isOpen: boolean;
  onClose: () => void;
};

export const WelcomeDialog = ({ isOpen, onClose }: Props) => {
  const { classes } = useStyles();
  const { classes: modalStyle } = useModalStyles();
  const { profile } = useUserProfile();
  const { track } = useSegment();
  const dispatch = useDispatch();
  const intl = useIntl();

  useEffect(() => {
    if (!profile.company.hasSeenOnboardingVideo) {
      (async () => {
        await dispatch(
          update({
            id: profile.company.id,
            type: "companies",
            attributes: { has_seen_onboarding_video: true },
          })
        );
        dispatch(fetchProfileInBackground());
        track(GetStartedEvent.step1Completed);
      })();
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <DialogManager
      modalStyle={modalStyle}
      maxWidth="md"
      isOpen={isOpen}
      handleClose={onClose}
      DialogGlobalComponent={
        <div className={classes.container}>
          <div className={classes.closeBtn} onClick={onClose}>
            <CloseModal />
          </div>
          <div className={classes.titleSection}>
            <T h2 bold>
              <FormattedMessage {...i18n.title} />
            </T>
            <T bodyBig color={muiTheme.palette.alpha500}>
              <FormattedMessage {...i18n.subtitle} />
            </T>
          </div>
          <iframe
            className={classes.video}
            width="100%"
            height="453px"
            allowFullScreen
            src={
              "https://www.youtube.com/embed/xjOm1HMmqBM?autoplay=1&controls=2&showinfo=0&modestbranding=1"
            }
            title={intl.formatMessage(i18n.title)}
          />
          <Button onClick={onClose} label={i18n.next} RightIcon={ArrowRight} />
        </div>
      }
    />
  );
};

const useStyles = makeStyles()((theme) => ({
  container: {
    width: "100%",
    height: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    gap: 32,
    padding: 8,
    "& button": {
      width: 300,
    },
  },
  titleSection: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    gap: 6,
  },
  video: {
    borderRadius: 8,
    borderStyle: "hidden",
  },
  closeBtn: {
    position: "absolute",
    top: 12,
    right: 12,
    cursor: "pointer",
  },
}));

const useModalStyles = makeStyles()((theme) => ({
  dialogContainer: {
    "& .MuiDialog-container .MuiPaper-root": {
      borderRadius: 12,
    },
  },
}));

const i18n = defineMessages({
  title: {
    id: "WelcomeDialog.title",
    defaultMessage: "Welcome to Reveal!",
  },
  subtitle: {
    id: "WelcomeDialog.subtitle",
    defaultMessage: "Discover what you can achieve with Reveal",
  },
  next: {
    id: "WelcomeDialog.next",
    defaultMessage: "Next",
  },
});
