import { HelpOutlineRounded } from "@mui/icons-material";
import { useTheme } from "@mui/material/styles";
import { Trash } from "components/icons";
import AccountAvatarWithCompany from "components/ui/avatars/AccountAvatarWithCompany";
import Tooltip from "components/ui/Tooltip";
import { T } from "components/ui/Typography";
import { getTimeSince } from "helpers/dateUtils";
import { makeStyles } from "makeStyles";
import DiscussionParticipant from "models/DiscussionParticipant";
import Message from "models/Message";
import {
  defineMessages,
  FormattedHTMLMessage,
  FormattedMessage,
  useIntl,
} from "react-intl";

type Props = {
  message: Message;
  participant?: DiscussionParticipant;
  isMigrated?: boolean;
};

export const MessageItem = ({ message, participant, isMigrated }: Props) => {
  const intl = useIntl();
  const muiTheme = useTheme();
  const isInternal = message.isInternal();
  const { isDeleted, isRemoved } = participant ?? {};
  const { classes, cx } = useStyles({ isInternal });

  const date = getTimeSince(message.sentAt.toISOString(), intl);

  return (
    <div className={classes.root}>
      <AccountAvatarWithCompany
        avatar={participant?.avatarUrl}
        fullName={participant?.fullName ?? undefined}
        companyAvatar={participant?.companyAvatarUrl}
        companyName={participant?.companyName ?? undefined}
        bgColor={muiTheme.palette.ivory}
        classes={{ container: classes.avatar }}
      >
        {isDeleted && !isMigrated && <Trash className={classes.icon} />}
        {isMigrated && !isDeleted && (
          <HelpOutlineRounded className={classes.icon} />
        )}
      </AccountAvatarWithCompany>
      <div className={classes.rightContent}>
        <div className={classes.header}>
          <Tooltip
            classes={{ tooltip: classes.tooltip }}
            title={<FormattedHTMLMessage {...i18n.removedUserTooltip} />}
            hidden={!(isRemoved && !isDeleted)}
            placement="top-start"
          >
            <T
              titleSmall
              className={cx({ [classes.alpha500]: isDeleted || isRemoved })}
            >
              {!isDeleted && !isMigrated
                ? participant?.fullName ?? undefined
                : isMigrated
                ? intl.formatMessage(i18n.previousMessage)
                : intl.formatMessage(i18n.deletedUser)}
              {isRemoved && !isDeleted && (
                <Trash className={classes.iconSmall} />
              )}
            </T>
          </Tooltip>
          {isInternal && (
            <>
              <T titleSmall className={classes.alpha500}>
                {"·"}
              </T>
              <T titleSmall className={classes.alpha500}>
                <FormattedMessage {...i18n.internal} />
              </T>
            </>
          )}
          <T titleSmall className={classes.alpha500}>
            {"·"}
          </T>
          <T labelSmall className={classes.alpha500}>
            {date}
          </T>
        </div>
        <T className={classes.preWrap}>{message.content}</T>
      </div>
    </div>
  );
};

// CSS

export const useStyles = makeStyles<{ isInternal: boolean }>()(
  (theme, { isInternal }) => ({
    root: {
      display: "flex",
      columnGap: 10,
      paddingBottom: 6,
    },
    avatar: {
      marginTop: 10,
      height: "fit-content",
    },
    preWrap: {
      overflowWrap: "break-word",
      whiteSpace: "pre-wrap",
      wordWrap: "break-word",
    },
    rightContent: {
      display: "flex",
      flexDirection: "column",
      width: "100%",
      rowGap: 4,
      padding: "10px 12px",
      borderRadius: "0px 8px 8px 8px",
      backgroundColor: isInternal ? "#FFEEC2" : "none",
      overflow: "hidden",
    },
    header: {
      display: "flex",
      columnGap: 4,
      alignItems: "center",
    },

    icon: {
      color: theme.palette.alpha500,
      width: 16,
      height: 16,
    },
    iconSmall: {
      height: 12,
      marginLeft: 4,
      marginTop: -1,
      width: 12,
    },
    alpha500: {
      color: theme.palette.alpha500,
    },
    tooltip: {
      maxWidth: 360,
    },
  })
);

// I18N

const i18n = defineMessages({
  deletedUser: {
    id: "Pipeline.MessageDrawer.Message.deletedUser",
    defaultMessage: "Reveal deleted user",
  },
  internal: {
    id: "Pipeline.MessageDrawer.Message.internal",
    defaultMessage: "Internal note",
  },
  previousMessage: {
    id: "Pipeline.MessageDrawer.Message.previousMessage",
    defaultMessage: "Previous message",
  },
  removedUserTooltip: {
    id: "Pipeline.MessageDrawer.Message.removedUserTooltip",
    defaultMessage:
      "Participant was removed by another participant or an admin.<br/>You can add this user back on Participant settings",
  },
});
