import { createSvgIcon } from "@mui/material/utils";

import { SvgIcon } from "./types";

const displayName = "ShareLeft";

const ShareLeft: SvgIcon = createSvgIcon(
  // Using transform to enlarge from provided viewbox="0 0 16 16"
  // to default "0 0 24 24" from `createSvgIcon` -> `SvgIcon`.
  <g fill="none" transform="matrix(1.5,0,0,1.5,0,0)">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5.48151 1.49937C5.38366 1.4155 5.24594 1.39627 5.12885 1.45012C5.01177 1.50397 4.93674 1.62105 4.93674 1.74993V3.56952C4.53973 3.56713 4.12443 3.56466 3.72687 3.59181C3.21474 3.62679 2.69825 3.71213 2.23802 3.92623C1.76959 4.14415 1.36874 4.49133 1.09028 5.02781C0.815585 5.55702 0.670078 6.25062 0.670078 7.15002C0.670078 7.2835 0.75048 7.40383 0.873793 7.4549C0.997105 7.50598 1.13904 7.47775 1.23342 7.38337C2.29646 6.32034 3.37532 6.33044 4.77631 6.34357L4.93674 6.34502V8.14993C4.93674 8.2788 5.01177 8.39588 5.12885 8.44974C5.24594 8.50359 5.38366 8.48435 5.48151 8.40048L9.21484 5.20048C9.28798 5.13779 9.33008 5.04626 9.33008 4.94993C9.33008 4.85359 9.28798 4.76207 9.21484 4.69937L5.48151 1.49937Z"
      fill="#7E64FF"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10.5187 14.5006C10.6165 14.5844 10.7542 14.6037 10.8713 14.5498C10.9884 14.496 11.0634 14.3789 11.0634 14.25V12.4304C11.4604 12.4328 11.8757 12.4353 12.2733 12.4081C12.7854 12.3732 13.3019 12.2878 13.7621 12.0737C14.2306 11.8558 14.6314 11.5086 14.9099 10.9721C15.1846 10.4429 15.3301 9.74933 15.3301 8.84993C15.3301 8.71645 15.2497 8.59612 15.1264 8.54505C15.0031 8.49397 14.8611 8.5222 14.7667 8.61658C13.7037 9.67962 12.6248 9.66951 11.2238 9.65638L11.0634 9.65494V7.85002C11.0634 7.72115 10.9884 7.60407 10.8713 7.55021C10.7542 7.49636 10.6165 7.5156 10.5187 7.59947L6.78532 10.7995C6.71217 10.8622 6.67008 10.9537 6.67008 11.05C6.67008 11.1464 6.71217 11.2379 6.78532 11.3006L10.5187 14.5006Z"
      fill="#E2DEDA"
    />
  </g>,
  displayName
);

ShareLeft.displayName = displayName;
export default ShareLeft;
