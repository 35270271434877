import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import NonUserService, { NewSltEmailPayload } from "services/NonUserService";

import { NonUserActions } from "./typing";

export const initLongLivedToken = createAsyncThunk<
  void,
  string,
  {
    rejectValue: { errors: { code: string }[] };
  }
>(
  NonUserActions.initLongLivedToken,
  async (shortLivedToken: string, thunkAPI) => {
    const service = new NonUserService();
    try {
      await service.retrieveLongLivedToken(shortLivedToken);
    } catch (error) {
      if (axios.isAxiosError(error)) {
        return thunkAPI.rejectWithValue(error.response?.data);
      }
      throw error;
    }
  }
);

export const initNewShortLivedTokenEmail = createAsyncThunk<
  void,
  NewSltEmailPayload,
  {
    rejectValue: { errors: { code: string }[] };
  }
>(NonUserActions.initNewShortLivedTokenEmail, async (data, thunkAPI) => {
  const service = new NonUserService();
  try {
    await service.refreshShortLivedToken(data);
  } catch (error) {
    if (axios.isAxiosError(error)) {
      return thunkAPI.rejectWithValue(error.response?.data);
    }
    throw error;
  }
});

export const refreshToken = createAsyncThunk<
  void,
  boolean,
  {
    rejectValue: { errors: { status: string }[]; hasSLT: boolean };
  }
>(NonUserActions.refreshToken, async (hasSLT, thunkAPI) => {
  const service = new NonUserService();
  try {
    await service.refreshLongLivedToken();
  } catch (error) {
    if (axios.isAxiosError(error)) {
      return thunkAPI.rejectWithValue({ ...error.response?.data, hasSLT });
    }
    throw error;
  }
});
