import { createSvgIcon } from "@mui/material/utils";

import { SvgIcon } from "./types";

const displayName = "ArrowLeft";

const ArrowLeft: SvgIcon = createSvgIcon(
  // Using transform to enlarge from provided viewbox="0 0 16 16"
  // to default "0 0 24 24" from `createSvgIcon` -> `SvgIcon`.
  <g fill="none" transform="matrix(1.5,0,0,1.5,0,0)">
    <path
      d="M7.02012 11.9798C7.21538 12.175 7.21538 12.4916 7.02012 12.6869C6.82486 12.8821 6.50828 12.8821 6.31301 12.6869L2.45108 8.82495C1.99547 8.36934 1.99547 7.63064 2.45108 7.17503L6.31301 3.3131C6.50828 3.11784 6.82486 3.11784 7.02012 3.3131C7.21538 3.50836 7.21538 3.82495 7.02012 4.02021L3.54034 7.49999H13.3332C13.6094 7.49999 13.8332 7.72385 13.8332 7.99999C13.8332 8.27613 13.6094 8.49999 13.3332 8.49999H3.54034L7.02012 11.9798Z"
      fill="currentColor"
    />
  </g>,
  displayName
);

ArrowLeft.displayName = displayName;
export default ArrowLeft;
