import Button from "components/ui/Button";
import { T } from "components/ui/Typography";
import useUserProfile from "hooks/useUserProfile";
import { makeStyles } from "makeStyles";
import Partnership, { PartnerRepresentation } from "models/Partnership";
import { defineMessages, FormattedMessage, useIntl } from "react-intl";

type Props = {
  recordsToReferCount: number;
  partner?: PartnerRepresentation | null;
  selectedPartners: Partnership[];
  onClose: () => void;
};

const ConfirmationContent = ({
  recordsToReferCount,
  partner,
  selectedPartners,
  onClose,
}: Props) => {
  const { classes } = useStyles();
  const intl = useIntl();
  const { profile } = useUserProfile();

  const partnerConfirmationDescription =
    selectedPartners.length > 1
      ? `${intl.formatMessage(i18n.your)} ${intl.formatMessage(
          i18n.countPartners,
          {
            count: selectedPartners.length,
          }
        )}`
      : selectedPartners[0]?.getPartner(profile).name ?? partner?.name;
  return (
    <div className={classes.centerElement}>
      <T className={classes.description}>
        <FormattedMessage
          {...i18n.confirmationMessage}
          values={{
            count: recordsToReferCount,
            partnerName: partnerConfirmationDescription,
          }}
        />
      </T>
      <Button label={i18n.close} variant="secondary" onClick={onClose} />
    </div>
  );
};

export default ConfirmationContent;

// CSS

const useStyles = makeStyles()(() => ({
  centerElement: {
    textAlign: "center",
  },
  description: {
    textAlign: "center",
    marginBottom: 20,
  },
}));

// I18N

const i18n = defineMessages({
  your: {
    id: "DataTables.ActionBtn.ReferOption.ConfirmationContent.your",
    defaultMessage: "Your",
  },
  countPartners: {
    id: "DataTables.ActionBtn.ReferOption.ConfirmationContent.countPartners",
    defaultMessage: "{count} partners",
  },
  confirmationMessage: {
    id:
      "DataTables.ActionBtn.ReferOption.ConfirmationContent.confirmationMessage",
    defaultMessage:
      "{count, plural, one {This account has} other {These accounts have}} been successfully referred. {partnerName} will be notified to review the referred {count, plural, one {account} other {accounts}}.",
  },
  close: {
    id: "DataTables.ActionBtn.ReferOption.ConfirmationContent.close",
    defaultMessage: "Close",
  },
});
