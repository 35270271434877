import { createSvgIcon } from "@mui/material/utils";

import { SvgIcon } from "./types";

const displayName = "Show";

const Show: SvgIcon = createSvgIcon(
  // Using transform to center the icon.
  // initial size: "0 0 16 16" to "0 0 24 24"
  <g fill="none" transform="matrix(1.5,0,0,1.5,0,0)">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.00003 11.793C10.9571 11.793 12.7124 9.53681 13.4668 8.24958C13.5594 8.09149 13.5594 7.90852 13.4668 7.75044C12.7124 6.46321 10.9571 4.20705 8.00003 4.20705C5.04297 4.20705 3.28765 6.4632 2.53326 7.75044C2.44061 7.90852 2.44061 8.09149 2.53326 8.24958C3.28765 9.53681 5.04297 11.793 8.00003 11.793ZM14.3337 8.78161C13.5329 10.1481 11.5146 12.8274 8.00003 12.8274C4.48548 12.8274 2.46713 10.1481 1.66631 8.7816C1.38174 8.29602 1.38174 7.70399 1.66631 7.21841C2.46713 5.85196 4.48548 3.17261 8.00003 3.17261C11.5146 3.17261 13.5329 5.85196 14.3337 7.21841C14.6183 7.70399 14.6183 8.29602 14.3337 8.78161Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.00006 9.03448C8.55836 9.03448 9.01095 8.57135 9.01095 8.00004C9.01095 7.42873 8.55836 6.9656 8.00006 6.9656C7.44176 6.9656 6.98916 7.42873 6.98916 8.00004C6.98916 8.57135 7.44176 9.03448 8.00006 9.03448ZM8.00006 10.0689C9.11666 10.0689 10.0218 9.14265 10.0218 8.00004C10.0218 6.85742 9.11666 5.93115 8.00006 5.93115C6.88346 5.93115 5.97827 6.85742 5.97827 8.00004C5.97827 9.14265 6.88346 10.0689 8.00006 10.0689Z"
      fill="currentColor"
    />
  </g>,
  displayName
);

Show.displayName = displayName;
export default Show;
