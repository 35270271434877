import CloseIcon from "@mui/icons-material/Close";
import Button from "@mui/material/Button";
import { T } from "components/ui/Typography";
import { makeStyles } from "makeStyles";
import { defineMessages, FormattedMessage } from "react-intl";

const useStyles = makeStyles()((theme) => ({
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    padding: theme.spacing(0),
    minWidth: 0,
  },
  closeIcon: {
    fontSize: 32,
  },
}));

const i18n = defineMessages({
  title: {
    id: "NotFound.resourceTitle",
    defaultMessage: "Page not found.",
  },
});

type Props = {
  handleClose: $TSFixMeFunction;
};

const NotFoundDialogTitle = ({ handleClose }: Props) => {
  const { classes } = useStyles();

  return (
    <>
      <T h1>
        <FormattedMessage {...i18n.title} />
      </T>
      <Button
        aria-label="close"
        className={classes.closeButton}
        onClick={handleClose}
      >
        <CloseIcon className={classes.closeIcon} />
      </Button>
    </>
  );
};

export default NotFoundDialogTitle;
