import { TextInput } from "components/ui/TextInput";
import { T } from "components/ui/Typography";
import UploadZone from "components/ui/UploadZone";
import getFirstValueFromURL from "helpers/getFirstValueFromURL";
import useSegment from "hooks/useSegment";
import useUserProfile from "hooks/useUserProfile";
import { get } from "lodash";
import { ChangeEvent, useState } from "react";
import { defineMessages, FormattedMessage, useIntl } from "react-intl";
import { useDispatch } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import { fetchProfileInBackground } from "redux/user/thunks";
import UploadManager from "screens/Frontoffice/shared/components/UploadManager";

import { BaseContent, sharedStyles } from "./BaseContent";

export const CsvContent = () => {
  const { cx, classes } = sharedStyles({});
  const intl = useIntl();
  const { profile } = useUserProfile();
  const dispatch = useDispatch();
  const [file, setFile] = useState<File | null>(null);
  const [uploading, setUploading] = useState(false);
  const { track } = useSegment();
  const history = useHistory();
  const location = useLocation();
  const isOnboarding = getFirstValueFromURL(location, "onboarding") === "true";
  const [filename, setFilename] = useState("");

  const onUploadSuccess = (integrationId: number) => {
    track("Connected data source", { crm: "file" });
    profile.identify({
      integrationCount:
        Number(get(profile, "company.integrationCount") ?? 0) + 1,
    });
    dispatch(fetchProfileInBackground());
    history.push({
      search: `?integration=${integrationId}&provider=file&updated=true&init=true${
        isOnboarding ? "&onboarding=true" : ""
      }`,
      hash: "#column-mapping",
    });
  };

  const uploadFile = (file: File) => {
    setFile(file);
    setUploading(true);
  };

  return (
    <BaseContent>
      <div className={cx(classes.flexCenter, classes.marginBottom)}>
        <div className={classes.iconContainer}>
          <img
            className={classes.icon}
            src="/images/providers/CSV.png"
            alt=""
          />
        </div>
      </div>
      <T
        h2
        className={cx(
          classes.contentTitle,
          classes.textCenter,
          classes.marginBottomLarge
        )}
      >
        <FormattedMessage {...i18n.title} />
      </T>
      <div className={classes.uploadZone}>
        <TextInput
          name="name"
          value={filename}
          onChange={(event: ChangeEvent<HTMLInputElement>) =>
            setFilename(event.target.value)
          }
          placeholder={intl.formatMessage(i18n.namePlaceholder)}
          inputProps={{ "data-testid": "name-input" }}
          disabled={uploading}
          variant="newDesign"
        />
        {!uploading && <UploadZone disabled={!filename} onDrop={uploadFile} />}
        {uploading && file && (
          <UploadManager
            filename={filename}
            file={file}
            onUploadSuccess={onUploadSuccess}
            onUploadError={() => setUploading(false)}
          />
        )}
      </div>
    </BaseContent>
  );
};

const i18n = defineMessages({
  namePlaceholder: {
    id: "CsvContent.namePlaceholder",
    defaultMessage: "Name this source",
  },
  nameHelper: {
    id: "CsvContent.nameHelper",
    defaultMessage: "Please name this source",
  },
  title: {
    id: "CsvContent.title",
    defaultMessage: "Add a new CSV file",
  },
});
