import { CircularProgress, FormControl, FormLabel } from "@mui/material";
import { Search } from "components/icons";
import Dropdown from "components/ui/Dropdown";
import { IDropdownOption } from "components/ui/Dropdown/components/types";
import { TextInput } from "components/ui/TextInput";
import { T } from "components/ui/Typography";
import { getLogo } from "config/constants";
import { useCrmAccountsSearch } from "hooks/useCrmAccountsSearch";
import { makeStyles } from "makeStyles";
import Record from "models/Record";
import { ChangeEvent, useCallback, useEffect, useMemo, useState } from "react";
import { defineMessages, FormattedMessage, useIntl } from "react-intl";
import { useDispatch } from "react-redux";
import { retreive } from "redux/api/thunks";

type Props = {
  account?: Record<"crm_accounts"> | null;
  isModalOpen: boolean;
  setAccount: (account: Record<"crm_accounts"> | undefined) => void;
  setLoading: (loading: boolean) => void;
};

export const AddPipelineItemAccountDropdown = ({
  account,
  isModalOpen,
  setAccount,
  setLoading,
}: Props) => {
  const intl = useIntl();
  const [inputValue, setInputValue] = useState("");
  const [isLoadingAccountDetails, setIsLoadingAccountDetails] = useState(false);
  const [accountAnchorEl, setAccountAnchorEl] = useState<HTMLElement | null>();
  const [isAccountDropdownOpen, setIsAccountDropdownOpen] = useState(false);
  const dispatch = useDispatch();
  const { classes } = useStyles();

  const { loading: loadingRawAccount, options } = useCrmAccountsSearch(
    inputValue,
    setIsAccountDropdownOpen
  );
  const loading = loadingRawAccount || isLoadingAccountDetails;

  const loadAccount = useCallback(
    async (id: number) => {
      setIsLoadingAccountDetails(true);
      await dispatch(
        retreive({
          id,
          type: "crm_accounts",
          options: {
            fields: {
              crm_accounts: [
                "name",
                "open_opportunities",
                "partner_presence",
                "ghost_partner_presence",
                "partner_opportunities",
                "ghost_partner_opportunities",
              ],
            },
            include: [
              "open_opportunities",
              "partner_presence",
              "ghost_partner_presence",
              "partner_opportunities",
              "ghost_partner_opportunities",
            ],
          },
        })
      );
      setIsLoadingAccountDetails(false);
    },
    [dispatch]
  );

  const handleChangeAccount = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      setAccount(undefined);
      if (!accountAnchorEl) {
        setAccountAnchorEl((event.target as HTMLInputElement) ?? null);
      }
      setInputValue(event.target.value);
    },
    [accountAnchorEl, setAccount]
  );

  const handleSetAccount = useCallback(
    (ids: string[] | null) => {
      if (!ids) {
        setAccount(undefined);
        return;
      }
      const account = options.find((item) => item.id === Number(ids[0]));
      if (account) {
        setAccount(account.source as Record<"crm_accounts">);
        loadAccount(account.id);
      }
      setIsAccountDropdownOpen(false);
    },
    [loadAccount, options, setAccount]
  );

  const accountDropdownOptions = useMemo(() => {
    return options.map((item) => ({
      id: item.id + "",
      name: item.name,
      logo: getLogo(item.provider),
    })) as IDropdownOption[];
  }, [options]);

  useEffect(() => {
    if (isModalOpen) {
      return;
    }
    setAccountAnchorEl(null);
    setInputValue("");
  }, [isModalOpen]);

  useEffect(() => {
    setLoading(loading);
  }, [loading, setLoading]);

  return (
    <FormControl fullWidth className={classes.formControl}>
      <FormLabel className={classes.label}>
        <T bold>
          <FormattedMessage {...i18n.account} />
        </T>
      </FormLabel>
      <TextInput
        variant="newDesign"
        value={account?.name ?? inputValue}
        fullWidth
        onChange={handleChangeAccount}
        placeholder={intl.formatMessage(i18n.searchForAccount)}
        LeftIcon={
          loading
            ? () => (
                <span className={classes.loader}>
                  <CircularProgress color="inherit" size={14} />
                </span>
              )
            : Search
        }
        autoFocus
      />
      <Dropdown
        isLoading={loading}
        options={accountDropdownOptions}
        anchorEl={accountAnchorEl ?? null}
        isVertical={true}
        onClose={() => setIsAccountDropdownOpen(false)}
        open={isAccountDropdownOpen && Boolean(inputValue) && !!accountAnchorEl}
        withSearch={false}
        onChange={handleSetAccount}
        size="inherit"
      />
    </FormControl>
  );
};

const i18n = defineMessages({
  account: {
    id: "Pipeline.AddPipelineItemAccountDropdown.account",
    defaultMessage: "Account",
  },
  searchForAccount: {
    id: "Pipeline.AddPipelineItemAccountDropdown.searchForAccount",
    defaultMessage: "Search for Account",
  },
});

const useStyles = makeStyles()((theme) => ({
  formControl: {
    marginBottom: 20,
  },
  loader: {
    paddingRight: 9,
  },
  label: {
    color: "unset",
    display: "flex",
    flexDirection: "column",
    gap: "2px",
    marginBottom: 6,
  },
}));
