import MuiDialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import { Close } from "components/icons";
import { isMessageDescriptor } from "helpers/isMessageDescriptor";
import { makeStyles } from "makeStyles";
import { FormattedHTMLMessage, MessageDescriptor } from "react-intl";

import Button from "./Button";
import Title from "./Title";

type Props = {
  avatar?: string;
  extraTitleContent?: React.ReactNode;
  fullScreenBreakpoint?: "xs" | "sm" | "md" | "lg";
  fullWidth?: boolean;
  isOpen?: boolean;
  maxWidth?: "xs" | "sm" | "md" | "lg";
  minHeight?: string | number;
  onClose?: () => void;
  rightTitleContent?: React.ReactNode;
  subtitle?:
    | string
    | {
        id: string;
        defaultMessage: string;
      };
  title?: string | MessageDescriptor | React.ReactNode;
  children: React.ReactChildren | React.ReactChild;
};

const Dialog = ({
  children,
  avatar,
  extraTitleContent,
  fullScreenBreakpoint = "sm",
  fullWidth = true,
  isOpen,
  maxWidth = "sm",
  minHeight = "unset",
  onClose,
  rightTitleContent,
  subtitle,
  title,
}: Props) => {
  const { classes } = useStyles();
  const { classes: titleClasses } = useTitleStyles();
  const { classes: dialogClasses } = useDialogStyles({ minHeight });
  const dialogProps = {
    open: isOpen,
    maxWidth: maxWidth,
    fullScreen: useMediaQuery(
      useTheme().breakpoints.down(fullScreenBreakpoint)
    ),
    fullWidth,
  };

  const section = title ? (
    isMessageDescriptor(title) ? (
      <FormattedHTMLMessage {...title} />
    ) : (
      <span>{title}</span>
    )
  ) : (
    ""
  );
  const label = subtitle ? (
    isMessageDescriptor(subtitle) ? (
      <FormattedHTMLMessage {...subtitle} />
    ) : (
      <span>{subtitle}</span>
    )
  ) : (
    ""
  );

  return (
    // @ts-expect-error ts-migrate(2322) FIXME: Type '{ children: Element[]; classes: Record<"pape... Remove this comment to see the full error message
    <MuiDialog {...dialogProps} classes={dialogClasses} onClose={onClose}>
      <DialogTitle className={classes.headerContainer}>
        <div className={classes.titleContainer}>
          <Title
            section={section}
            label={label}
            avatar={avatar}
            variant="h2"
            classes={titleClasses}
            gutter={false}
          />
          {rightTitleContent && rightTitleContent}
        </div>
        {onClose && (
          <div className={classes.iconBtn}>
            <Button
              LeftIcon={Close}
              onClick={onClose}
              variant="tertiary"
              classes={{ icon: classes.closeIcon }}
            />
          </div>
        )}
        {extraTitleContent && extraTitleContent}
      </DialogTitle>
      <DialogContent>{children}</DialogContent>
    </MuiDialog>
  );
};

export default Dialog;

// CSS

const useDialogStyles = makeStyles<{ minHeight: string | number }>()(
  (theme, { minHeight }) => ({
    paper: {
      minHeight: minHeight,
      backgroundColor: theme.palette.ivory,
    },
  })
);

const useTitleStyles = makeStyles()(() => ({
  avatar: {
    width: 50,
    height: 50,
  },
}));

const useStyles = makeStyles()((theme) => ({
  headerContainer: {
    padding: theme.spacing(3),
    paddingBottom: 0,
  },
  titleContainer: {
    display: "flex",
    justifyContent: "space-between",
    paddingRight: theme.spacing(4),
    paddingBottom: theme.spacing(1),
    [theme.breakpoints.down("md")]: {
      flexDirection: "column",
    },
  },
  iconBtn: {
    position: "absolute",
    top: theme.spacing(2),
    right: theme.spacing(2),
  },
  closeIcon: {
    fontSize: 12,
  },
}));
