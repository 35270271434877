import { createSvgIcon } from "@mui/material/utils";

import { SvgIcon } from "./types";

const displayName = "Limit";

const Limit: SvgIcon = createSvgIcon(
  // Using transform to enlarge from provided viewbox="0 0 13 13"
  // to default "0 0 24 24" from `createSvgIcon` -> `SvgIcon`.
  <g fill="none" transform="matrix(1.8,0,0,1.8,0,0)">
    <path
      d="M5.94317 8.01522C6.11645 8.18909 6.3184 8.27163 6.54904 8.26285C6.78007 8.25449 6.96146 8.15001 7.09319 7.9494L9.51606 4.24064L6.01794 6.81345C5.82014 6.94385 5.71491 7.13317 5.70225 7.38143C5.68999 7.62968 5.7703 7.84095 5.94317 8.01522ZM2.93936 10C2.82384 10 2.72079 9.9697 2.6302 9.9091C2.5396 9.84808 2.46543 9.76094 2.40767 9.64768C2.25101 9.36892 2.1438 9.06403 2.08604 8.73303C2.02868 8.40244 2 8.06726 2 7.72748C2 7.06588 2.11947 6.44985 2.35842 5.87937C2.59736 5.3093 2.92077 4.81091 3.32863 4.3842C3.7369 3.95749 4.21498 3.62001 4.76289 3.37175C5.3108 3.12392 5.89392 3 6.51225 3C7.13018 3 7.7133 3.12392 8.26161 3.37175C8.80952 3.62001 9.2874 3.95958 9.69527 4.39047C10.1035 4.82178 10.425 5.32477 10.6596 5.89943C10.8945 6.47409 11.0079 7.08783 10.9996 7.74064C10.9996 8.08042 10.9709 8.41352 10.9135 8.73992C10.8558 9.06633 10.7486 9.37749 10.5919 9.67338C10.5425 9.77787 10.4703 9.85853 10.3753 9.91537C10.2808 9.97179 10.1757 10 10.0602 10H2.93936Z"
      fill="currentColor"
    />
  </g>,
  displayName
);

Limit.displayName = displayName;
export default Limit;
