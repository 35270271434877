import { T } from "components/ui/Typography";
import { defineMessages, FormattedMessage } from "react-intl";

import Button from "../Button";
import { SWQLBooleanOperator } from "./SWQLTypes";

type Props = {
  operator: SWQLBooleanOperator;
  handleClick: (operator: SWQLBooleanOperator) => void;
};

const OperatorAddBtn = ({ operator, handleClick }: Props) => {
  const label =
    operator === "and" ? (
      <T>
        <FormattedMessage {...i18n.and} />
      </T>
    ) : (
      <T>
        <FormattedMessage {...i18n.or} />
      </T>
    );

  return (
    <Button
      size="small"
      variant="secondary"
      label={label}
      onClick={() => handleClick(operator)}
    />
  );
};

export default OperatorAddBtn;

const i18n = defineMessages({
  and: {
    id: "SWQL.OperatorAddBtn.and",
    defaultMessage: "+ AND",
  },
  or: {
    id: "SWQL.OperatorAddBtn.or",
    defaultMessage: "+ OR",
  },
});
