import { Snackbar } from "@mui/material";
import Stack from "@mui/material/Stack";
import _ from "lodash";
import { makeStyles } from "makeStyles";
import { useDispatch, useSelector } from "react-redux";
import { matchPath } from "react-router";
import { markNotificationAsRead } from "redux/notifications/actions";
import { selectUnreadNotifications } from "redux/notifications/selectors";
import {
  NotificationActionType,
  NotificationItem,
} from "redux/notifications/typing";

import NotificationActionAddedToPipeline from "./NotificationActionAddedToPipeline";
import NotificationActionArchivePipeline from "./NotificationActionArchivePipeline";
import NotificationActionLink from "./NotificationActionLink";
import NotificationActionRemovePipelineFilters from "./NotificationActionRemovePipelineFilters";
import NotificationSnackbar from "./NotificationSnackbar";

const throwUnsupportedAction = (value: never) => {
  throw new TypeError("Unsupported value for notification actions");
};

/**
 * Render the actions for a notification
 * @returns rendered actions
 */
const renderActions = (
  notification: NotificationItem,
  handleClose: () => void
) => {
  if (notification.actions === undefined) {
    return null;
  }
  switch (notification.actions.type) {
    case NotificationActionType.linkActions:
      return (
        <NotificationActionLink
          notification={notification}
          label={notification.actions.label}
          link={notification.actions.link}
        />
      );
    case NotificationActionType.addedToPipelineActions:
      // We don't display the action "Go to pipeline" if user already on pipeline
      const isFromPipeline = !!matchPath(window.location.pathname, "/pipeline");
      return (
        <NotificationActionAddedToPipeline
          notification={notification}
          partnerConnections={notification.actions.partnerConnections}
          rawCompanies={notification.actions.rawCompanies}
          goToPipelineAvailable={!isFromPipeline}
        />
      );
    case NotificationActionType.removePipelineFiltersActions:
      return (
        <NotificationActionRemovePipelineFilters
          onRemoveFilters={handleClose}
          hiddenByFiltersReasons={notification.context?.hiddenReasons}
        />
      );
    case NotificationActionType.archivePipelineActions:
      return (
        <NotificationActionArchivePipeline
          notification={notification}
          partnerConnectionIds={notification.actions.partnerConnectionIds}
          shouldArchive={notification.actions.shouldArchive}
        />
      );
    default:
      throwUnsupportedAction(notification.actions);
  }
};

type Props = {
  sidebarFolded: boolean;
};

export const Notification = ({ sidebarFolded = true }: Props) => {
  const dispatch = useDispatch();
  const { classes } = useStyles({ sidebarFolded });
  const currentNotifications = useSelector(selectUnreadNotifications);
  if (currentNotifications.length === 0) {
    return null;
  }

  const handleClose = (notification: NotificationItem) =>
    dispatch(markNotificationAsRead(notification.id));

  const handleCloseAll = () => {
    _.each(currentNotifications, (notification: NotificationItem) =>
      handleClose(notification)
    );
  };

  return (
    <Snackbar
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "left",
      }}
      autoHideDuration={6000}
      open={true}
      onClose={handleCloseAll}
      classes={{
        root: classes.root,
      }}
    >
      <Stack spacing={2} sx={{ flexDirection: "column", rowGap: "4px" }}>
        {currentNotifications.map(
          (notification) =>
            !notification.read && (
              <NotificationSnackbar
                onClose={() => handleClose(notification)}
                message={notification.message}
                description={notification.description}
                status={notification.status}
                context={notification.context}
                actions={renderActions(notification, () =>
                  handleClose(notification)
                )}
              />
            )
        )}
      </Stack>
    </Snackbar>
  );
};

export default Notification;

// CSS

const useStyles = makeStyles<Props>()((theme, { sidebarFolded }) => ({
  root: {
    left: sidebarFolded ? 80 : 204,
    bottom: 12,
  },
}));
