import history from "_history";
import User from "models/User";
import { stringify } from "query-string";
import { RevealStore } from "redux/typing";

export type OnboardingStep = {
  attr: string;
  url: string;
  validator?:
    | ((profile: User, store: Pick<RevealStore, "user">) => boolean)
    | false;
};

export const isOnboardingError = (value: any): value is OnboardingError =>
  value instanceof Error && value.name === "OnboardingError";

export class OnboardingError extends Error {
  param: string;
  url: string;

  constructor(param: string, url: string) {
    super(`User requires Onboarding Steps required for ${param}`);
    this.param = param;
    this.url = url;
    this.name = "OnboardingError";
  }
}

class OnboardingService {
  config: OnboardingStep[];
  profile: User;
  store: Pick<RevealStore, "user">;

  constructor(
    config: { onboarding: OnboardingStep[] },
    store: Pick<RevealStore, "user">
  ) {
    this.config = config.onboarding;
    this.store = store;
    this.profile = store.user.profile.data;
  }

  validateOrRedirect() {
    try {
      this.validate();
    } catch (error) {
      if (isOnboardingError(error)) {
        this.redirect(error.url);
      }
    }
  }

  validate() {
    this.config.forEach(({ attr, url, validator = false }: OnboardingStep) => {
      const skips = Object.keys(this.profile.onboardingSkips || {});
      const isValid = validator
        ? validator(this.profile, this.store)
        : this.profile[attr];
      if (!isValid && skips.indexOf(attr) < 0) {
        throw new OnboardingError(attr, url);
      }
    });
  }

  redirect(url: string) {
    const current = window.location.pathname;
    if (
      this.config.map(({ url }: OnboardingStep) => url).indexOf(current) < 0
    ) {
      const params = {
        next: current,
      };
      if (window.location.search) {
        params.next += window.location.search;
      }
      history.push(`${url}?${stringify(params)}`);
    }
  }
}

export default OnboardingService;
