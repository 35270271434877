import ToggleBtnGroup from "components/ui/ToggleBtnGroup";
import { defineMessages, FormattedMessage } from "react-intl";
import { useSelector } from "react-redux";
import { AvailablePipelineAttributeColumns } from "redux/pipelineAttribute/defaults";
import { selectPipelineAttributeSlice } from "redux/pipelineAttribute/selectors";

import { MatchFilterType } from "../../../shared/types";
import { usePipelineToggleFilterStyles } from "../../Pipeline/constants";
import { PipelineViewParameters } from "../constants";

type OpportunityStatus = "open" | "won" | "lost";

type Props = {
  setView: (value: PipelineViewParameters) => void;
};

const OpportunityStatusFilter = ({ setView }: Props) => {
  const { classes } = usePipelineToggleFilterStyles();
  const { view } = useSelector(selectPipelineAttributeSlice);
  const { filters } = view ?? {};
  const statusFilterValue: OpportunityStatus | undefined = filters?.find(
    (filter) => filter.fieldname === AvailablePipelineAttributeColumns.status
  )?.value;

  const handleSetValue = (value: OpportunityStatus | null) => {
    if (!value) {
      setView({
        filters: filters?.filter(
          (filter) =>
            filter.fieldname !== AvailablePipelineAttributeColumns.status
        ),
      });
      return;
    }
    setView({
      filters: [
        ...(filters?.filter(
          (filter) =>
            filter.fieldname !== AvailablePipelineAttributeColumns.status
        ) ?? []),
        {
          fieldname: AvailablePipelineAttributeColumns.status,
          type: MatchFilterType.ANY_OF,
          value,
        },
      ],
    });
  };

  return (
    <ToggleBtnGroup<OpportunityStatus>
      allowUnselect
      items={[
        {
          label: <FormattedMessage {...i18n.statusOpen} />,
          value: "open",
        },
        {
          label: <FormattedMessage {...i18n.statusWon} />,
          value: "won",
        },
        {
          label: <FormattedMessage {...i18n.statusLost} />,
          value: "lost",
        },
      ]}
      setValue={handleSetValue}
      value={statusFilterValue}
      classes={{ root: classes.toggleButtonGroup }}
    />
  );
};

export default OpportunityStatusFilter;

// I18N

const i18n = defineMessages({
  statusOpen: {
    id: "MyPipeline.OpportunityStatusFilter.Open",
    defaultMessage: "Open",
  },
  statusWon: {
    id: "MyPipeline.OpportunityStatusFilter.Won",
    defaultMessage: "Won",
  },
  statusLost: {
    id: "MyPipeline.OpportunityStatusFilter.Lost",
    defaultMessage: "Lost",
  },
});
