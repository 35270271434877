import { createReducer } from "@reduxjs/toolkit";

import {
  initWidget,
  openWidget,
  toggleFree,
  togglePower,
  togglePro,
} from "./actions";
import { PremiumPlanWidgetState } from "./types";

const initState: PremiumPlanWidgetState = {
  opened: false,
  freeUnfolded: false,
  proUnfolded: false,
  powerUnfolded: false,
};

const reducer = createReducer(initState, (builder) =>
  builder
    .addCase(initWidget, () => initState)
    .addCase(openWidget, (state, action) => {
      state.opened = action.payload;
    })
    .addCase(toggleFree, (state, action) => {
      state.freeUnfolded = action.payload;
    })
    .addCase(togglePro, (state, action) => {
      state.proUnfolded = action.payload;
    })
    .addCase(togglePower, (state, action) => {
      state.powerUnfolded = action.payload;
    })
);

export default reducer;
