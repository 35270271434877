import { ClassNameMap, FormControl } from "@mui/material";
import { TextInput } from "components/ui/TextInput";
import { FormikErrors, FormikTouched } from "formik";
import { defineMessages, useIntl } from "react-intl";

import { DialogView, IInviteForm } from "../types";

type Props = {
  classes: ClassNameMap<string>;
  dialogView: DialogView;
  errors: FormikErrors<IInviteForm>;
  handleChange: (event: any) => void;
  isSubmitting: boolean;
  touched: FormikTouched<IInviteForm>;
  values: IInviteForm;
};

export const InviteFormMessageField = ({
  classes,
  dialogView,
  errors,
  handleChange,
  isSubmitting,
  touched,
  values,
}: Props) => {
  const intl = useIntl();
  return (
    <FormControl
      className={classes.messageControl}
      fullWidth
      error={Boolean(touched.message && errors.message)}
    >
      <TextInput
        autoComplete="off"
        className={classes.input}
        name="message"
        placeholder={intl.formatMessage(
          dialogView === DialogView.SuggestedPartnerDirect ||
            dialogView === DialogView.GenericFirstInvite
            ? i18n.messageOptional
            : i18n.message
        )}
        onChange={handleChange}
        multiline
        rows="3"
        rowsmax="10"
        disabled={isSubmitting}
        value={values.message}
        error={Boolean(touched.message && errors.message)}
        variant="newDesign"
      />
    </FormControl>
  );
};

const i18n = defineMessages({
  message: {
    id: "partners.invite.message",
    defaultMessage: "Write your own message",
  },
  messageOptional: {
    id: "partners.invite.messageOptional",
    defaultMessage: "Write your own message (optional)",
  },
});
