import CompanyPayingFeatureSubscription from "models/CompanyPayingFeatureSubscription";
import User from "models/User";
import { APICollection } from "redux/api/typing";

export enum PremiumPlanWidgetActions {
  initWidget = "premiumPlanWidget/initWidget",
  openWidget = "premiumPlanWidget/openWidget",
  toggleFree = "premiumPlanWidget/toggleFree",
  togglePro = "premiumPlanWidget/togglePro",
  togglePower = "premiumPlanWidget/togglePower",
}

export interface PremiumPlanWidgetState {
  opened: boolean;
  freeUnfolded: boolean;
  proUnfolded: boolean;
  powerUnfolded: boolean;
}

export interface RootStateWithPremiumPlanWidget {
  premiumPlanWidget: PremiumPlanWidgetState;
  api: {
    entities: {
      company_paying_feature_subscriptions?: APICollection<CompanyPayingFeatureSubscription>;
    };
  };
  context: {
    companyPayingFeatureSubscriptionIds: number[];
  };
  user: {
    profile: {
      data: User;
    };
  };
}
