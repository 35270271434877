import { Chip } from "@mui/material";
import { T } from "components/ui/Typography";
import { ConfigContext } from "config/ConfigProvider";
import { makeStyles } from "makeStyles";
import { ReactNode, useContext } from "react";
import { defineMessages, FormattedMessage } from "react-intl";
import { Link } from "react-router-dom";

type Props = {
  hideTitle?: boolean;
  title?: ReactNode;
};

export const FileTemplateButtons = ({ hideTitle, title }: Props) => {
  const { classes } = useStyles();
  const config = useContext(ConfigContext);
  return (
    <div>
      {!hideTitle && (
        <T className={classes.subtitle} bodySmall>
          {title ?? <FormattedMessage {...i18n.subtitle} />}
        </T>
      )}
      <Link
        className={classes.button}
        to={{ pathname: config.templateGsheetLink ?? "" }}
        rel="noopener noreferrer"
        target="_blank"
      >
        <Chip
          variant="filled"
          className={classes.chip}
          avatar={<img src="/images/providers/Gsheet.png" alt="" />}
          label={<FormattedMessage {...i18n.gsheet} />}
        />
      </Link>
      <Link
        className={classes.button}
        to={config.templateFilePath ?? ""}
        target="_blank"
        download
      >
        <Chip
          variant="filled"
          className={classes.chip}
          avatar={<img src="/images/providers/CSV.png" alt="" />}
          label={<FormattedMessage {...i18n.csv} />}
        />
      </Link>
    </div>
  );
};

const useStyles = makeStyles()((theme) => ({
  button: {
    textDecoration: "none",
    "&:hover": {
      textDecoration: "none",
    },
  },
  chip: {
    background: theme.palette.taupe500,
    cursor: "pointer",
    fontFamily: "'Inter', sans-serif",
    fontSize: 12,
    marginTop: theme.spacing(1),
    padding: `${theme.spacing(1.5)} ${theme.spacing(1)}`,
    "&:hover": {
      background: theme.palette.taupe,
    },
  },
  subtitle: {
    marginBottom: theme.spacing(1),
  },
}));

const i18n = defineMessages({
  csv: {
    id: "FileTemplateButtons.Csv",
    defaultMessage: "Download CSV template",
  },
  gsheet: {
    id: "FileTemplateButtons.Gsheet",
    defaultMessage: "Open Google Sheet template",
  },
  subtitle: {
    id: "FileTemplateButtons.Subtitle",
    defaultMessage:
      "Start with a Google Sheet or CSV file to quickly trial Reveal before you connect your actual CRM",
  },
});
