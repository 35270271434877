import AccountMappingView from "models/AccountMappingView";
import OldAccountMappingView from "models/OldAccountMappingView";
import Partnership from "models/Partnership";

export enum AccountMappingResource {
  matches = "matches",
  leadMatches = "lead_matches",
  shared_accounts = "shared_accounts",
  matched_accounts = "matched_accounts",
}

export enum AccountMappingActions {
  // Persisted View
  loadView = "accountMapping/loadView",
  updateView = "accountMapping/updateView",
  // Local View
  setAccountType = "accountMapping/setAccountType",
  setSelectedSourceId = "accountMapping/setSelectedSourceId",
  setLastVisitedAt = "accountMapping/setLastVisitedAt",
}

export interface OldAccountMappingViewState {
  persisted?: OldAccountMappingView;
  accountType: AccountMappingResource;
  selectedSourceId: number | null;
  lastVisitedAt: Date | null;
}
export interface AccountMappingViewState {
  persisted?: AccountMappingView;
  accountType: AccountMappingResource;
  selectedSourceId: number | null;
  lastVisitedAt: Date | null;
}

export interface OldPartnershipAccountMappingState {
  views: {
    [partnershipId: number]: OldAccountMappingViewState;
  };
  currentPartnership: Partnership | null;
}
export interface PartnershipAccountMappingState {
  views: {
    [partnershipId: number]: AccountMappingViewState;
  };
  currentPartnership: Partnership | null;
}

export interface OldRootStateWithAccountMapping {
  accountMapping: OldPartnershipAccountMappingState;
}
export interface RootStateWithAccountMapping {
  accountMapping: PartnershipAccountMappingState;
}
