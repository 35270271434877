import _ from "lodash";
import { Available360MappingColumns } from "redux/mapping360/defaults";
import { isDisplaySinceFilter } from "screens/Frontoffice/screens/DataTables/screens/AccountMapping/components/DisplayOnlyNewUpdatesSince/utils";
import {
  snakeCaseNameForNearboundAccountField,
  snakeCaseWithDoubleUnderscore,
} from "screens/Frontoffice/shared/helpers/snakeCase";

import {
  FieldType,
  FilterType,
  MatchSortOrder,
  Preset,
  SortType,
} from "./types";

export const stripFilters = (filters: FilterType[]): FilterType[] =>
  _(filters)
    .filter((item) => Boolean(item.value))
    .value();

export const stripSort = (sort: SortType[]): SortType[] =>
  _(sort)
    .filter((item) => Boolean(item.fieldname))
    .value();

export const prepareReportSort = (
  sort: SortType[],
  fields?: {
    [fieldname: string]: FieldType;
  }
) =>
  stripSort(sort).map((sortItem) => ({
    reverse: sortItem.order === MatchSortOrder.ORDER_DESC,
    fieldname:
      (fields?.[sortItem.fieldname]?.jsonPath?.length ?? 0) > 0
        ? snakeCaseNameForNearboundAccountField(
            sortItem.fieldname,
            fields?.[sortItem.fieldname].jsonPath ?? []
          )
        : snakeCaseWithDoubleUnderscore(sortItem.fieldname),
  }));

export const isMyAccountsRightColumn = (key: string) =>
  [
    Available360MappingColumns.customerPresence,
    Available360MappingColumns.prospectPresence,
    Available360MappingColumns.customerPresencePartnerTags,
    Available360MappingColumns.prospectPresencePartnerTags,
    Available360MappingColumns.partnerPresence,
    Available360MappingColumns.partnerSignals,
    Available360MappingColumns.partnerOpportunities,
    Available360MappingColumns.winRateMultiplier,
    Available360MappingColumns.revenueMultiplier,
    Available360MappingColumns.dealSizeMultiplier,
    Available360MappingColumns.nbPartners,
    Available360MappingColumns.nbOpportunities,
  ].includes(key as Available360MappingColumns);

export const isAccountMappingRightColumn = (key: string) =>
  key.startsWith("right");

export const isReferredAccountsLeftColumn = (key: string) =>
  key.startsWith("referredLeft");

export const getHeaderPartnerName = (
  key: string,
  rightChipName: string | undefined
) => {
  switch (true) {
    case isAccountMappingRightColumn(key):
    case isMyAccountsRightColumn(key):
    case isReferredAccountsLeftColumn(key):
      return rightChipName;
    default:
      return undefined;
  }
};

export const comparePresetAgainstFields = (
  data: Preset,
  fields: {
    [fieldname: string]: FieldType;
  }
): Preset => {
  const { filter, sort } = data;
  const newFilters: FilterType[] = [];
  _.each(filter, (item) => {
    if (item.fieldname in fields || isDisplaySinceFilter(item)) {
      newFilters.push(item);
    }
  });
  const newSort: SortType[] = [];
  _.each(sort, (item) => {
    if (item.fieldname in fields) {
      newSort.push(item);
    }
  });
  return {
    filter: newFilters,
    sort: newSort,
  };
};

export enum ReportType {
  ACCOUNT_MAPPING = 0,
  MY_ACCOUNTS = 1,
  NEW_LEADS = 2,
  COLLABORATE = 3,
  NEW_PROSPECTS_360_XLSX = 4,
  NEARBOUND_ACCOUNTS_XLSX = 5,
  NEW_ACCOUNT_MAPPING = 6,
}

export enum XlsxReportStatus {
  PENDING = 0,
  GENERATING = 1,
  EXPORTING = 2,
  READY = 3,
  FAILED = 4,
}

export const crmAccountsIncluded = [
  "matches.company",
  "matches.ghost_partner",
  "matches.owner",
  "owner",
  "matches.partnership_dest_owner",
  "matches.partnership_initiator_owner",
];

export const crmAccountsFields = {
  crm_accounts: [
    "domain",
    "country_code",
    "integration_provider",
    "integration_id",
    "name",
    "matches",
    "status",
    "owner",
    "owner_name",
    "partner_connection_count",
    "has_partner_connection_with_no_partner",
  ],
  account_matches: [
    "name",
    "company",
    "ghost_partner",
    "status",
    "owner",
    "first_won_opport_at",
    "number_of_open_opportunities",
    "extra_contact_data_v2",
    "partnership_id",
    "partnership_dest_owner",
    "partnership_initiator_owner",
  ],
};
