import { DateRangeCalendar } from "@mui/x-date-pickers-pro/DateRangeCalendar";
import { ChevronDown } from "components/icons";
import Button, { Variant } from "components/ui/Button";
import DropdownOption from "components/ui/DropdownOption";
import DropdownPopover from "components/ui/DropdownPopover";
import { T } from "components/ui/Typography";
import useSelectorWithDeepEquality from "hooks/useSelectorWithDeepEquality";
import { makeStyles } from "makeStyles";
import {
  defineMessages,
  FormattedMessage,
  MessageDescriptor,
} from "react-intl";
import { useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";
import { selectDateRangeFilterData as selectDateRangeFilterCollaborate } from "redux/pipeline/selectors";
import { setDateRangeFilter as setDateRangeFilterCollaborate } from "redux/pipeline/thunks";
import { selectDateRangeFilterData as selectDateRangeFilterAttribute } from "redux/pipelineAttribute/selectors";
import { setDateRangeFilter as setDateRangeFilterAttribute } from "redux/pipelineAttribute/thunks";
import { DateRangeFilterType, DateRangeTimeframe } from "redux/typing";

import { useDateRangeFilter } from "../hooks/useDateRangeFilter";

const DateRangeFilter = ({
  disabled = false,
  variant = "quinary",
}: {
  disabled?: boolean;
  variant?: Variant;
}) => {
  const { pathname } = useLocation();
  const isCollaborateView = pathname === "/pipeline";
  const selectDateRangeFilterData = isCollaborateView
    ? selectDateRangeFilterCollaborate
    : selectDateRangeFilterAttribute;
  const setDateRangeFilter = isCollaborateView
    ? setDateRangeFilterCollaborate
    : setDateRangeFilterAttribute;
  const data: DateRangeFilterType = useSelectorWithDeepEquality(
    selectDateRangeFilterData
  );
  const dispatch = useDispatch();
  const handleChange = (value: DateRangeFilterType | null) =>
    dispatch(setDateRangeFilter(value));

  const {
    anchorEl,
    handleOpenOptions,
    handleCloseOptions,
    selectOption,
    displayCalendar,
    handleChangeDateRange,
    calendarValue,
    editingRange,
  } = useDateRangeFilter({ data, handleChange });

  const { classes } = useStyles({ displayCalendar });

  return (
    <>
      <Button
        label={getLabel(data?.rangeType)}
        onClick={handleOpenOptions}
        variant={variant}
        RightIcon={ChevronDown}
        disabled={disabled}
        size="small"
      />
      <DropdownPopover
        anchorEl={anchorEl}
        onClose={handleCloseOptions}
        open={Boolean(anchorEl)}
        position="right"
        classes={{ paper: classes.dropDown }}
      >
        <div className={classes.container}>
          {displayCalendar && (
            <DateRangeCalendar
              value={calendarValue}
              onChange={handleChangeDateRange}
            />
          )}
          <div className={classes.optionList}>
            {[
              { value: null, label: <FormattedMessage {...i18n.allTime} /> },
              ...DateRangeTimeframeItems,
            ].map((option) => (
              <DropdownOption
                key={option.value}
                isActive={
                  data === null
                    ? option.value === null
                    : data.rangeType !== null &&
                      ((editingRange &&
                        option.value === DateRangeTimeframe.customRange) ||
                        (!editingRange && data.rangeType === option.value))
                }
                onClick={() => selectOption(option)}
              >
                <T>{option.label}</T>
              </DropdownOption>
            ))}
          </div>
        </div>
      </DropdownPopover>
    </>
  );
};

export default DateRangeFilter;

/// CSS

export const useStyles = makeStyles<{ displayCalendar: boolean }>()(
  (theme, { displayCalendar }) => ({
    container: {
      display: "flex",
      width: displayCalendar ? undefined : "100%",
    },
    dropDown: {
      flexDirection: "row",
      // We need to specify the width of the content to update the size of the popover
      width: displayCalendar ? 811 : 169,
    },
    optionList: {
      display: "flex",
      alignItems: "center",
      flexDirection: "column",
      width: displayCalendar ? undefined : "100%",
    },
  })
);

/// I18N

const i18n = defineMessages({
  allTime: {
    id: "crm.MyPipeline.DateRangeFilter.allTime",
    defaultMessage: "All time",
  },
  thisMonth: {
    id: "models.DateRangeFilter.thisMonth",
    defaultMessage: "This month",
  },
  lastMonth: {
    id: "models.DateRangeFilter.lastMonth",
    defaultMessage: "Last month",
  },
  thisQuarter: {
    id: "models.DateRangeFilter.thisQuarter",
    defaultMessage: "This quarter",
  },
  previousQuarter: {
    id: "models.DateRangeFilter.previousQuarter",
    defaultMessage: "Previous quarter",
  },
  thisYear: {
    id: "models.DateRangeFilter.thisYear",
    defaultMessage: "This year",
  },
  previousYear: {
    id: "models.DateRangeFilter.previousYear",
    defaultMessage: "Previous year",
  },
  customRange: {
    id: "models.DateRangeFilter.customRange",
    defaultMessage: "Custom range",
  },
});

/// Utils

const handleUnsupportedRangeType = (value: never): never => {
  throw new TypeError(`Unsupported value: ${value}`);
};

const getLabel = (
  rangeType: DateRangeTimeframe | undefined
): MessageDescriptor => {
  switch (rangeType) {
    case undefined:
      return i18n.allTime;
    case DateRangeTimeframe.customRange:
      return i18n.customRange;
    case DateRangeTimeframe.lastMonth:
      return i18n.lastMonth;
    case DateRangeTimeframe.thisMonth:
      return i18n.thisMonth;
    case DateRangeTimeframe.previousQuarter:
      return i18n.previousQuarter;
    case DateRangeTimeframe.thisQuarter:
      return i18n.thisQuarter;
    case DateRangeTimeframe.previousYear:
      return i18n.previousYear;
    case DateRangeTimeframe.thisYear:
      return i18n.thisYear;
    default:
      handleUnsupportedRangeType(rangeType);
  }
  return i18n.allTime;
};

const DateRangeTimeframeItems = [
  DateRangeTimeframe.thisMonth,
  DateRangeTimeframe.lastMonth,
  DateRangeTimeframe.thisQuarter,
  DateRangeTimeframe.previousQuarter,
  DateRangeTimeframe.thisYear,
  DateRangeTimeframe.previousYear,
  DateRangeTimeframe.customRange,
].map((value: DateRangeTimeframe) => ({
  value,
  label: <FormattedMessage {...getLabel(value)} />,
}));
