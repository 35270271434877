import { InputAdornment } from "@mui/material";
import Checkbox from "@mui/material/Checkbox";
import InputBase from "@mui/material/InputBase";
import FiltersSelectField from "components/ui/filters/FiltersSelectField";
import _ from "lodash";
import { makeStyles } from "makeStyles";
import { useEffect, useState } from "react";
import { defineMessages, useIntl } from "react-intl";
import { PERCENTAGE_FIELDS } from "redux/analytics/defaults";
import {
  FilterType,
  MatchFieldType,
} from "screens/Frontoffice/screens/DataTables/shared/types";

type Props = {
  isDisabled?: boolean;
  fieldData?: $TSFixMe;
  handleChange: $TSFixMeFunction;
  filter: FilterType;
};

export const FilterValueField = ({
  isDisabled,
  fieldData,
  handleChange,
  filter,
}: Props) => {
  const { classes } = useStyles();
  const intl = useIntl();
  const [currentValue, setCurrentValue] = useState(
    formatValue(filter.value, filter.fieldname)
  );

  useEffect(() => {
    setCurrentValue(formatValue(filter.value, filter.fieldname));
  }, [filter.fieldname]); // eslint-disable-line react-hooks/exhaustive-deps

  const onChange = (value: $TSFixMe) => {
    setCurrentValue(value);
    handleChange(reverseFormatValue(value, filter.fieldname));
  };

  const onChangeValidate = (event: $TSFixMe) => {
    if (event.target.validity.valid) {
      const value = event.target.value;
      onChange(value);
    }
  };

  switch (fieldData?.type) {
    case MatchFieldType.REFERENCES_USER:
    case MatchFieldType.REFERENCES_ACCOUNT:
    case MatchFieldType.REFERENCES_CONTACT:
    case MatchFieldType.REFERENCES_OPPORTUNITY:
    case MatchFieldType.TEXT:
      return (
        <InputBase
          className={classes.input}
          classes={{ focused: classes.inputFocused, input: classes.inputText }}
          disabled={isDisabled}
          value={currentValue}
          onChange={onChangeValidate}
          data-testid="filter-value-text"
        />
      );
    case MatchFieldType.CURRENCY:
      return (
        <InputBase
          className={classes.input}
          classes={{ focused: classes.inputFocused, input: classes.inputText }}
          disabled={isDisabled}
          value={currentValue}
          onChange={onChangeValidate}
          data-testid="filter-value-integer"
          type="number"
          // @ts-expect-error ts-migrate(2769) FIXME: No overload matches this call.
          startAdornment={<InputAdornment>$</InputAdornment>}
        />
      );
    case MatchFieldType.INTEGER:
      return (
        <InputBase
          className={classes.input}
          classes={{ focused: classes.inputFocused, input: classes.inputText }}
          disabled={isDisabled}
          value={currentValue}
          onChange={onChangeValidate}
          data-testid="filter-value-integer"
          type="number"
        />
      );
    case MatchFieldType.NUMBER:
      return (
        <InputBase
          className={classes.input}
          classes={{ focused: classes.inputFocused, input: classes.inputText }}
          disabled={isDisabled}
          value={currentValue}
          onChange={onChangeValidate}
          data-testid="filter-value-number"
          type="number"
          inputProps={{
            step: 0.01,
          }}
        />
      );
    case MatchFieldType.BOOLEAN:
      return (
        <Checkbox
          checked={currentValue}
          className={classes.checkbox}
          classes={{ root: classes.checkbox, checked: classes.checkboxChecked }}
          disabled={isDisabled}
          onChange={(e) => onChange(e.target.checked)}
          color="default"
          size="small"
          data-testid="filter-value-boolean"
        />
      );
    case MatchFieldType.PICKLIST:
    case MatchFieldType.MULTI_PICKLIST:
    case MatchFieldType.SINGLE_CHOICE_PICKLIST:
    case MatchFieldType.USER:
      const options = _(fieldData.options || {})
        .toPairs()
        .map(([value, label]) => ({
          value,
          label,
        }))
        .sort((a, b) =>
          fieldData.isOptionsSorted
            ? 0
            : a.label > b.label
            ? 1
            : a.label < b.label
            ? -1
            : 0
        ) // no sorting if isOptionsSorted is true
        .value();
      const isMulti = fieldData.type !== MatchFieldType.SINGLE_CHOICE_PICKLIST;
      const newCurrentValue = isMulti ? currentValue : [currentValue];
      return (
        <FiltersSelectField
          isDisabled={isDisabled}
          isMulti={isMulti}
          contained
          value={
            _.isArray(newCurrentValue)
              ? options.filter((option: $TSFixMe) =>
                  newCurrentValue
                    .map((val: $TSFixMe) => "" + val)
                    .includes(option.value)
                )
              : []
          }
          options={options}
          handleChange={onChange}
          placeholder={intl.formatMessage(i18n.placeholder)}
          data-testid="filter-value-picklist"
        />
      );
    case MatchFieldType.DATE:
    case MatchFieldType.DATETIME:
      return (
        <InputBase
          className={classes.input}
          classes={{ focused: classes.inputFocused, input: classes.inputText }}
          disabled={isDisabled}
          value={currentValue}
          onChange={onChangeValidate}
          data-testid="filter-value-integer"
          inputProps={{
            type: "date",
          }}
        />
      );
    default:
      return null;
  }
};

export default FilterValueField;

// HELPERS

const formatValue = (value?: any, fieldName?: string) => {
  if (fieldName && PERCENTAGE_FIELDS.includes(fieldName)) {
    return value * 100;
  }
  return value;
};

const reverseFormatValue = (value: any, fieldName: string) => {
  if (PERCENTAGE_FIELDS.includes(fieldName)) {
    return value / 100;
  }
  return value;
};

// CSS

const useStyles = makeStyles()((theme) => ({
  input: {
    // @ts-expect-error ts-migrate(2339) FIXME: Property 'dark' does not exist on type 'Color'.
    color: theme.palette.grey.dark,
    fontWeight: 500,
    fontSize: 12,
    height: 28,
    borderRadius: 18,
    padding: "6px 10px",
    backgroundColor: "transparent",
    border: `1px solid ${theme.palette.greyscale250}`,
    "&:hover": {
      // @ts-expect-error ts-migrate(2339) FIXME: Property 'main' does not exist on type 'Color'.
      borderColor: theme.palette.grey.main,
    },
  },
  inputFocused: {
    border: `1px solid ${theme.palette.greyscale350}`,
  },
  inputText: {
    padding: 0,
  },
  checkbox: {
    padding: 0,
    // @ts-expect-error ts-migrate(2339) FIXME: Property 'main' does not exist on type 'Color'.
    color: theme.palette.grey.main,
  },
  checkboxChecked: {
    color: theme.palette.appGreen,
  },
}));

// I18N

const i18n = defineMessages({
  placeholder: {
    id: "crm.AccountMapping.Filters.Filter.FilterValueField.placeholder",
    defaultMessage: "Select an option",
  },
});
