import _ from "lodash";
import { Factory } from "models";
import { ReactElement } from "react";
import { defineMessages, FormattedMessage } from "react-intl";
import {
  FilterType,
  MatchFilter,
} from "screens/Frontoffice/screens/DataTables/shared/types";
import JSONAPIService from "services/JSONAPIService";
import { JSONAPIResponse } from "services/types";
import { PartnershipEvent } from "tracking";

import AccountMappingSettings, {
  AccountMappingShare,
  AccountMappingStatus,
  NewLeadsShare,
} from "./AccountMappingSettings";
import AccountMappingSharingSettings, {
  ShareOpenDeal,
  ShareOwner,
} from "./AccountMappingSharingSettings";
import Integration from "./Integration";
import PartnershipOverviewView from "./PartnershipOverviewView";
import { Record } from "./Record";
import { JSONSerializable, Partnership_JSONAPIResource } from "./types";
import User from "./User";

const i18n = defineMessages({
  shared: {
    id: "models.Partnership.shared",
    defaultMessage: "Shared",
  },
  notShared: {
    id: "models.Partnership.notShared",
    defaultMessage: "Not shared",
  },
  commonCustomersOnly: {
    id: "models.Partnership.commonCustomersOnly",
    defaultMessage: "Common customers only",
  },
  ownerFull: {
    id: "models.Partnership.ownerFull",
    defaultMessage: "Full name & contact info",
  },
  ownerNameOnly: {
    id: "models.Partnership.ownerNameOnly",
    defaultMessage: "Full name only",
  },
  sharedInclAmount: {
    id: "models.Partnership.opportsInclAmount",
    defaultMessage: "Shared incl. amount",
  },
});

export const DEMO_ID = "demo";

// Data Sharing Enum
export enum DataShare {
  Nothing = "NOTHING",
  Overlapping = "OVERLAPPING",
  OverlappingAndWhitespace = "OVERLAPPING_AND_NEW_LEADS",
  OverlappingCommonCustomers = "OVERLAPPING_COMMON_CUSTOMERS",
  OverlappingCommonCustomersAndWhitespace = "OVERLAPPING_COMMON_CUSTOMERS_AND_NEW_LEADS",
}

export enum ShareAccounts {
  Always = 1,
  CommonCustomers = 2,
  Never = 3,
}

export enum NewLeadsStatus {
  RequestedByCompany = "requestedByCompany",
  RequestedByPartner = "requestedByPartner",
  Active = "active",
  Inactive = "inactive",
}

// Status Enum

export enum PartnershipStatus {
  Accepted = "accepted",
  Declined = "declined",
  Paused = "paused",
  Pending = "pending",
  Ghost = "ghost",
}

export enum PartnershipStatusLabel {
  Active = "active",
  Pending = "pending",
  Paused = "paused",
  Offline = "offline",
}

// Labels

const SHARE_OWNER_LABELS = {
  [ShareOwner.Full]: <FormattedMessage {...i18n.ownerFull} />,
  [ShareOwner.NameOnly]: <FormattedMessage {...i18n.ownerNameOnly} />,
  [ShareOwner.Nothing]: <FormattedMessage {...i18n.notShared} />,
};

type LabelEnum = ShareOpenDeal | ShareOwner;

// Interface & Types

export interface OverviewParameters {
  common_customers?: FilterType[];
  common_opportunities?: FilterType[];
  common_partners?: FilterType[];
  common_other_prospects?: FilterType[];
  prospects_to_customers?: FilterType[];
  customers_to_prospects?: FilterType[];
  open_opportunities_to_customers?: FilterType[];
}

export type PartnerRepresentation =
  | Record
  | {
      id?: number | null;
      name: string;
      avatarUrl?: string;
      websiteUrl?: string;
    };

export interface PartnershipWarnings {
  paused: boolean;
  missingIntegration: boolean;
  missingGhostIntegration?: boolean;
  hasDisabledIntegration: boolean;
  hasNoEnabledIntegration: boolean;
  missingStatusRules: boolean;
  statusRulesProgress: null | number;
}

export enum PartnerHasDisabledIntegration {
  ALL = "all",
  SOME = "some",
}

export type OverviewCustomersType = {
  closed_amount: number;
  count: number;
  count_not_private: number;
};

export type OverviewProspectsType = {
  pipeline_amount: number;
  open_deals: number;
  count: number;
  active_count: number;
  count_not_private: number;
};

export const isOverviewCustomersType = (
  value: undefined | number | OverviewCustomersType | OverviewProspectsType
): value is OverviewCustomersType => {
  return (
    value !== undefined &&
    typeof value !== "number" &&
    _.every(["count", "count_not_private", "closed_amount"], (key: string) => {
      return key in value;
    })
  );
};

export const isOverviewProspectsType = (
  value: undefined | number | OverviewCustomersType | OverviewProspectsType
): value is OverviewProspectsType => {
  return (
    value !== undefined &&
    typeof value !== "number" &&
    _.every(
      [
        "pipeline_amount",
        "open_deals",
        "count",
        "active_count",
        "count_not_private",
      ],
      (key: string) => {
        return key in value;
      }
    )
  );
};

export type OverviewFiltersType = {
  common_customers?: OverviewCustomersType;
  common_opportunities?: OverviewProspectsType;
  customers_to_prospects?: OverviewCustomersType;
  nb_matched_accounts?: number;
  nb_matched_accounts_not_private?: number;
  prospects_to_customers?: OverviewProspectsType;
  open_opportunities_to_customers?: OverviewProspectsType;
};

export enum PartnershipSource {
  InviteForm = "invite_form",
  SuggestedPartner = "suggested_partner",
  Directory = "directory",
  InvitationURL = "invitation_url",
  Other = "other",
}

// Helper
function intoItemsWith<T extends LabelEnum>(
  labelsFor: { [values in T]: ReactElement },
  values: T[]
): { value: T; label: ReactElement }[] {
  return values.map((value) => ({
    value,
    label: labelsFor[value],
  }));
}

const DEFAULT_ASSESSMENT = {
  common_customers: [],
  common_opportunities: [],
  common_partners: [],
  common_other_prospects: [],
  prospects_to_customers: [],
  customers_to_prospects: [],
};

const progressFromRaw = (rawValue: JSONSerializable): number | null => {
  if (rawValue === undefined || rawValue === null) {
    return null;
  } else {
    return +rawValue;
  }
};

export default class Partnership extends Record {
  isDemo: boolean = false;
  ready: boolean = false;
  requestedCompanyDomain: string;
  requestedCompanyName: string;
  source: PartnershipSource;

  /** Related */
  accountMappingSettings: AccountMappingSettings | null;
  initiatorCompany: Record;
  destCompany: Record | null;
  partnershipOwner: User | null;
  partnerPartnershipOwner: User | null;
  partnerHasUnlimitedPipelineSharing: boolean;
  partnershipOverviewView: PartnershipOverviewView | null = null;
  sharingSettings: AccountMappingSharingSettings[] = [];

  /** Integrations */
  integrations: Integration[] = [];

  /** Readiness attributes */
  initMappingRulesSet: boolean;
  destMappingRulesSet: boolean;
  initMappingRulesProgress: number | null;
  destMappingRulesProgress: number | null;
  hasPartnerIntegration: boolean;
  partnerHasDisabledIntegration: PartnerHasDisabledIntegration | null;
  isInitiatorPartnerStackPartnershipEligible: boolean;
  isDestPartnerStackPartnershipEligible: boolean;
  asymmetricalPartnership: boolean;

  static ownerSharingItems = intoItemsWith(SHARE_OWNER_LABELS, [
    ShareOwner.Nothing,
    ShareOwner.NameOnly,
    ShareOwner.Full,
  ]);

  // N.B. We assume that if the company shares new leads
  //      then they won't mind sharing overlapping accounts.
  //      "Who can share more can share less."
  static toggleSharingOverlapping(dataSharingStatus: DataShare) {
    const overlappingToggleMap = {
      [DataShare.Nothing]: DataShare.Overlapping,
      [DataShare.Overlapping]: DataShare.Nothing,
      [DataShare.OverlappingAndWhitespace]: DataShare.Nothing,
      [DataShare.OverlappingCommonCustomers]: DataShare.Nothing,
      [DataShare.OverlappingCommonCustomersAndWhitespace]: DataShare.Nothing,
    };
    return overlappingToggleMap[dataSharingStatus];
  }

  static toggleSharingWhitespace(dataSharingStatus: DataShare) {
    const whitespaceToggleMap = {
      [DataShare.Nothing]: DataShare.OverlappingAndWhitespace,
      [DataShare.Overlapping]: DataShare.OverlappingAndWhitespace,
      [DataShare.OverlappingAndWhitespace]: DataShare.Overlapping,
      [DataShare.OverlappingCommonCustomers]:
        DataShare.OverlappingCommonCustomersAndWhitespace,
      [DataShare.OverlappingCommonCustomersAndWhitespace]:
        DataShare.OverlappingCommonCustomers,
    };
    return whitespaceToggleMap[dataSharingStatus];
  }

  static toggleSharingCommonCustomers(dataSharingStatus: DataShare) {
    const commonCustomersToggleMap = {
      [DataShare.Nothing]: DataShare.Nothing,
      [DataShare.Overlapping]: DataShare.OverlappingCommonCustomers,
      [DataShare.OverlappingAndWhitespace]:
        DataShare.OverlappingCommonCustomersAndWhitespace,
      [DataShare.OverlappingCommonCustomers]: DataShare.Overlapping,
      [DataShare.OverlappingCommonCustomersAndWhitespace]:
        DataShare.OverlappingAndWhitespace,
    };
    return commonCustomersToggleMap[dataSharingStatus];
  }

  static isSharingOverlapping(dataSharingStatus: DataShare) {
    return [
      DataShare.Overlapping,
      DataShare.OverlappingAndWhitespace,
      DataShare.OverlappingCommonCustomers,
      DataShare.OverlappingCommonCustomersAndWhitespace,
    ].includes(dataSharingStatus);
  }

  static isSharingAccountStatus = Partnership.isSharingOverlapping;

  static isSharingWhitespace(dataSharingStatus: DataShare) {
    return [
      DataShare.OverlappingAndWhitespace,
      DataShare.OverlappingCommonCustomersAndWhitespace,
    ].includes(dataSharingStatus);
  }

  static isSharingCommonCustomers(dataSharingStatus: DataShare) {
    return [
      DataShare.OverlappingCommonCustomers,
      DataShare.OverlappingCommonCustomersAndWhitespace,
    ].includes(dataSharingStatus);
  }

  static hasSharingOverlappingChanged(
    newDataSharingStatus: DataShare,
    oldDataSharingStatus: DataShare
  ) {
    return (
      this.isSharingOverlapping(newDataSharingStatus) !==
      this.isSharingOverlapping(oldDataSharingStatus)
    );
  }

  static hasSharingWhitespaceChanged(
    newDataSharingStatus: DataShare,
    oldDataSharingStatus: DataShare
  ) {
    return (
      this.isSharingWhitespace(newDataSharingStatus) !==
      this.isSharingWhitespace(oldDataSharingStatus)
    );
  }

  static hasSharingCommonCustomersChanged(
    newDataSharingStatus: DataShare,
    oldDataSharingStatus: DataShare
  ) {
    return (
      this.isSharingCommonCustomers(newDataSharingStatus) !==
      this.isSharingCommonCustomers(oldDataSharingStatus)
    );
  }

  constructor(data: Partnership_JSONAPIResource) {
    super(data);
    this.isDemo = data.id === DEMO_ID;
    this.ready = this.attributes.ready as boolean;
    this.requestedCompanyName = this.attributes
      .requested_company_name as string;
    this.requestedCompanyDomain = this.attributes
      .requested_company_domain as string;
    this.source = this.attributes.source as PartnershipSource;
    this.initMappingRulesSet = Boolean(this.attributes.init_mapping_rules_set);
    this.destMappingRulesSet = Boolean(this.attributes.dest_mapping_rules_set);
    this.initMappingRulesProgress = progressFromRaw(
      this.attributes.init_mapping_rules_progress
    );
    this.destMappingRulesProgress = progressFromRaw(
      this.attributes.dest_mapping_rules_progress
    );
    this.partnerHasUnlimitedPipelineSharing = !!this.attributes
      .partner_has_unlimited_pipeline_sharing;
    this.partnerHasDisabledIntegration = this.attributes
      .partner_has_disabled_integration as PartnerHasDisabledIntegration;
    this.hasPartnerIntegration = Boolean(
      this.attributes.has_partner_integration
    );
    this.isWhitespaceEnabled = Boolean(this.attributes.is_new_leads_enabled);
    this.isInitiatorPartnerStackPartnershipEligible = Boolean(
      this.attributes.is_initiator_partner_stack_partnership_eligible
    );
    this.isDestPartnerStackPartnershipEligible = Boolean(
      this.attributes.is_dest_partner_stack_partnership_eligible
    );
    this.asymmetricalPartnership = Boolean(
      this.attributes.asymmetrical_privacy
    );

    if (this.relationships.initiator_company.data === null) {
      throw Error("initiator_company is required for Partnership model");
    }
    if (this.relationships.initiator_company.data instanceof Array) {
      throw Error(
        "initiator_company must be a single resource for Partnership model"
      );
    }
    this.initiatorCompany = Factory.createRecord(
      this.relationships.initiator_company.data
    );
    if (
      this.relationships.dest_company &&
      this.relationships.dest_company.data !== null
    ) {
      if (this.relationships.dest_company.data instanceof Array) {
        throw Error(
          "dest_company must be a single resource for Partnership model"
        );
      }
      this.destCompany = Factory.createRecord(
        this.relationships.dest_company.data
      );
    } else {
      this.destCompany = null;
    }

    // Partnership owner
    if (
      this.relationships.partnership_owner &&
      this.relationships.partnership_owner.data !== null
    ) {
      if (this.relationships.partnership_owner.data instanceof Array) {
        throw Error(
          "partnership_owner must be a single resource for Partnership model"
        );
      }
      this.partnershipOwner = Factory.createRecord(
        this.relationships.partnership_owner.data
      ) as User;
    } else {
      this.partnershipOwner = null;
    }

    if (
      this.relationships.partner_partnership_owner &&
      this.relationships.partner_partnership_owner.data !== null
    ) {
      if (this.relationships.partner_partnership_owner.data instanceof Array) {
        throw Error(
          "partner_partnership_owner must be a single resource for Partnership model"
        );
      }
      this.partnerPartnershipOwner = Factory.createRecord(
        this.relationships.partner_partnership_owner.data
      ) as User;
    } else {
      this.partnerPartnershipOwner = null;
    }

    if (
      this.relationships.partnership_overview_view instanceof
      PartnershipOverviewView
    ) {
      this.partnershipOverviewView = this.relationships
        .partnership_overview_view.data as PartnershipOverviewView;
    }

    if (
      this.relationships.account_mapping_settings?.data instanceof
      AccountMappingSettings
    ) {
      this.accountMappingSettings = this.relationships.account_mapping_settings.data;
    } else {
      this.accountMappingSettings = null;
    }

    if (_.isArray(this.relationships.integrations?.data)) {
      this.integrations = this.relationships.integrations.data as Integration[];
    }

    if (_.isArray(this.relationships.sharing_settings?.data)) {
      this.sharingSettings = this.relationships.sharing_settings
        .data as AccountMappingSharingSettings[];
    }
  }

  get hasDisabledIntegration() {
    return _.some(
      this.integrations,
      (integration) => integration.disabledAt !== null
    );
  }

  get hasEnabledIntegration() {
    return _.some(
      this.integrations,
      (integration) => integration.disabledAt === null
    );
  }

  warnings(
    company: Record
  ): { company: PartnershipWarnings; partner: PartnershipWarnings } {
    return {
      company: {
        paused: this.isPausedByCompany(company),
        missingIntegration: this.integrations.length === 0,
        hasDisabledIntegration: this.hasDisabledIntegration,
        hasNoEnabledIntegration: !this.hasEnabledIntegration,
        missingStatusRules: this.isInitiator(company)
          ? !this.initMappingRulesSet
          : !this.destMappingRulesSet,
        statusRulesProgress: this.isInitiator(company)
          ? this.initMappingRulesProgress
          : this.destMappingRulesProgress,
      },
      partner: {
        paused: this.isPausedByPartner(company),
        missingIntegration: !this.hasPartnerIntegration && !this.isGhost(),
        missingGhostIntegration: !this.hasPartnerIntegration && this.isGhost(),
        hasDisabledIntegration: this.partnerHasDisabledIntegration !== null,
        hasNoEnabledIntegration:
          this.partnerHasDisabledIntegration ===
          PartnerHasDisabledIntegration.ALL,
        missingStatusRules: this.isInitiator(company)
          ? !this.destMappingRulesSet
          : !this.initMappingRulesSet,
        statusRulesProgress: this.isInitiator(company)
          ? this.destMappingRulesProgress
          : this.initMappingRulesProgress,
      },
    };
  }

  getTrackingMessageForOverlappingChange(company: Record) {
    switch (this.getAccountMappingStatus(company)) {
      case AccountMappingStatus.Inactive:
        return PartnershipEvent.requestedAM;
      case AccountMappingStatus.RequestedByCompany:
        return PartnershipEvent.cancelledAM;
      case AccountMappingStatus.RequestedByPartner:
        return PartnershipEvent.approvedAM;
      case AccountMappingStatus.Active:
        return PartnershipEvent.revokedAM;
      default:
        return "";
    }
  }

  getTrackingMessageForOverlaps(company: Record) {
    switch (this.getAccountMappingStatus(company)) {
      case AccountMappingStatus.Inactive:
        return PartnershipEvent.off;
      case AccountMappingStatus.RequestedByCompany:
        return PartnershipEvent.enabledByMe;
      case AccountMappingStatus.RequestedByPartner:
        return PartnershipEvent.enabledByPartner;
      case AccountMappingStatus.Active:
        return PartnershipEvent.activated;
      default:
        return "";
    }
  }

  getTrackingMessageForNewProspects(company: Record) {
    switch (this.getNewLeadsStatus(company)) {
      case NewLeadsStatus.Inactive:
        return PartnershipEvent.off;
      case NewLeadsStatus.RequestedByCompany:
        return PartnershipEvent.enabledByMe;
      case NewLeadsStatus.RequestedByPartner:
        return PartnershipEvent.enabledByPartner;
      case NewLeadsStatus.Active:
        return PartnershipEvent.activated;
      default:
        return "";
    }
  }

  hasAccountMappingSettings() {
    return Boolean(this.accountMappingSettings);
  }

  isAccountMappingEnabled() {
    return (
      this.accountMappingSettings?.status === AccountMappingStatus.Active ||
      this.isGhost()
    );
  }

  isNewLeadEnabled() {
    return (
      this.isGhost() ||
      (this.isNewLeadsEnabledByDest() && this.isNewLeadsEnabledByInit())
    );
  }

  isParnershipSettingsAvailable(profile: User) {
    return (
      profile.canManagePartnerships &&
      (this.isAccepted() || this.isPaused() || this.isGhost())
    );
  }

  hasDataSource() {
    return this.integrations?.length > 0;
  }

  getSharingSettings(company: Record) {
    return this.sharingSettings?.find(
      (settings: Record) => settings.company?.id === company?.id
    );
  }

  onlyCommonCustomersShared() {
    const settings = [
      this.accountMappingSettings?.initApproved,
      this.accountMappingSettings?.destApproved,
    ];
    return (
      settings.includes(AccountMappingShare.CommonCustomers) &&
      !settings.includes(AccountMappingShare.Never)
    );
  }

  companyOnlySharingCommonCustomers(company: Record) {
    return this.isInitiator(company)
      ? this.accountMappingSettings?.initApproved ===
          AccountMappingShare.CommonCustomers
      : this.accountMappingSettings?.destApproved ===
          AccountMappingShare.CommonCustomers;
  }

  partnerOnlySharingCommonCustomers(company: Record) {
    return this.isInitiator(company)
      ? this.accountMappingSettings?.destApproved ===
          AccountMappingShare.CommonCustomers
      : this.accountMappingSettings?.initApproved ===
          AccountMappingShare.CommonCustomers;
  }

  getDataSharingStatus(company: Record) {
    const amStatus = this.getAccountMappingStatus(company);
    const amActiveForCompany =
      amStatus === AccountMappingStatus.Active ||
      amStatus === AccountMappingStatus.RequestedByCompany;

    const amSharingWhitespace = this.isInitiator(company)
      ? this.accountMappingSettings?.initSharingNewLeads ===
        NewLeadsShare.Always
      : this.accountMappingSettings?.destSharingNewLeads ===
        NewLeadsShare.Always;

    const amSharingCommonCustomers = this.companyOnlySharingCommonCustomers(
      company
    );

    if (amActiveForCompany) {
      if (amSharingWhitespace && amSharingCommonCustomers) {
        return DataShare.OverlappingCommonCustomersAndWhitespace;
      }

      if (amSharingWhitespace) {
        return DataShare.OverlappingAndWhitespace;
      }

      if (amSharingCommonCustomers) {
        return DataShare.OverlappingCommonCustomers;
      }

      return DataShare.Overlapping;
    }

    return DataShare.Nothing;
  }

  getAccountMappingStatus(company: Record): AccountMappingStatus {
    if (this.isGhost()) {
      return AccountMappingStatus.Active;
    }

    const status =
      this.accountMappingSettings?.status || AccountMappingStatus.Inactive;
    if (status === AccountMappingStatus.Requested__Internal) {
      return AccountMappingStatus.RequestedByCompany;
    }
    if (status === AccountMappingStatus.PendingApproval__Internal) {
      return AccountMappingStatus.RequestedByPartner;
    }
    if (status === AccountMappingStatus.Revoked__Internal) {
      const isInitiator = this.isInitiator(company);
      const isInitiatorRevokeOrigin =
        this.accountMappingSettings?.initApproved === AccountMappingShare.Never;
      if (
        (isInitiator && isInitiatorRevokeOrigin) ||
        (!isInitiator && !isInitiatorRevokeOrigin)
      ) {
        return AccountMappingStatus.RequestedByPartner;
      } else {
        return AccountMappingStatus.RequestedByCompany;
      }
    }
    return status;
  }

  getNewLeadsStatus(company: Record): NewLeadsStatus {
    if (this.isGhost()) {
      return NewLeadsStatus.Active;
    }
    const enabledByInit = this.isNewLeadsEnabledByInit();
    const enabledByDest = this.isNewLeadsEnabledByDest();

    if (enabledByInit && enabledByDest) {
      return NewLeadsStatus.Active;
    }

    const isInit = this.isInitiator(company);
    if (enabledByInit || enabledByDest) {
      return isInit === enabledByInit
        ? NewLeadsStatus.RequestedByCompany
        : NewLeadsStatus.RequestedByPartner;
    }

    return NewLeadsStatus.Inactive;
  }

  static getAccountStatusSharingFromDataSharingStatus(
    dataSharingStatus: DataShare
  ) {
    switch (dataSharingStatus) {
      case DataShare.Overlapping:
      case DataShare.OverlappingAndWhitespace:
        return ShareAccounts.Always;

      case DataShare.OverlappingCommonCustomers:
      case DataShare.OverlappingCommonCustomersAndWhitespace:
        return ShareAccounts.CommonCustomers;

      default:
        return ShareAccounts.Never;
    }
  }

  getAccountOwnerSharing(company: Record): ShareOwner {
    return this.getSharingSettings(company)?.shareOwner || ShareOwner.Full;
  }

  getAccountOpportsSharing(company: Record): boolean {
    return this.getSharingSettings(company)?.shareOpports ?? true;
  }

  isEnabled(sharingSetting?: AccountMappingShare) {
    if (sharingSetting === undefined) {
      return false;
    }
    return [
      AccountMappingShare.Always,
      AccountMappingShare.CommonCustomers,
    ].includes(sharingSetting);
  }

  newLeadsEnabled(newLeadSharingSetting?: NewLeadsShare) {
    return newLeadSharingSetting === NewLeadsShare.Always;
  }

  isAccountMappingEnabledByInit() {
    return this.isEnabled(this.accountMappingSettings?.initApproved);
  }

  isAccountMappingEnabledByDest() {
    return this.isEnabled(this.accountMappingSettings?.destApproved);
  }

  isNewLeadsEnabledByInit() {
    return this.newLeadsEnabled(
      this.accountMappingSettings?.initSharingNewLeads
    );
  }

  isNewLeadsEnabledByDest() {
    return this.newLeadsEnabled(
      this.accountMappingSettings?.destSharingNewLeads
    );
  }

  getDestCompany(): PartnerRepresentation {
    return (
      this.destCompany || {
        name: this.requestedCompanyName,
        avatarUrl: this.requestedCompanyDomain
          ? `https://logo.clearbit.com/${this.requestedCompanyDomain}`
          : undefined,
      }
    );
  }

  getPartner(profile: User): PartnerRepresentation {
    return this.isInitiator(profile.company)
      ? this.getDestCompany()
      : this.initiatorCompany;
  }

  isInitiator(company?: Record | null) {
    return Boolean(
      company !== undefined &&
        company !== null &&
        company.id === this.initiatorCompany.id
    );
  }

  isAccepted() {
    return this.status === PartnershipStatus.Accepted;
  }

  isDeclined() {
    return this.status === PartnershipStatus.Declined;
  }

  isPaused() {
    return this.status === PartnershipStatus.Paused;
  }

  isGhost() {
    return this.status === PartnershipStatus.Ghost;
  }

  isPausedByCompany(company: Record) {
    return (
      this.isPaused() &&
      (this.isInitiator(company) ? this.pausedByInit : this.pausedByDest)
    );
  }

  isPausedByPartner(company: Record) {
    return (
      this.isPaused() &&
      (this.isInitiator(company) ? this.pausedByDest : this.pausedByInit)
    );
  }

  isFavByUser(profile: User) {
    return this.watcherIds.includes(profile.id);
  }

  isToAcceptByCompany(company: Record) {
    const companyNeedsToAcceptPartnership =
      !this.isAccepted() && !this.isInitiator(company);
    return (
      !this.isGhost() && !this.isPaused() && companyNeedsToAcceptPartnership
    );
  }

  // A partnership is pending if either side is waiting for overlap or prospects sharing.
  isStatusPending() {
    return (
      !this.isGhost() &&
      !this.isPaused() &&
      (!this.isAccountMappingEnabled() ||
        this.isNewLeadsEnabledByDest() !== this.isNewLeadsEnabledByInit())
    );
  }

  // A partnership is pending by company if company can
  // give a response (approve or decline) or
  // share overlap or prospects accounts from company side.
  isStatusPendingByCompany(company: Record) {
    const partnerMissingSource = this.warnings(company).partner
      .missingIntegration;
    const companyNeedsToAcceptPartnership =
      !this.isAccepted() && !this.isInitiator(company);
    const companyNeedsToAcceptOverlaps =
      this.isAccepted() &&
      (this.isInitiator(company)
        ? !this.isAccountMappingEnabledByInit() &&
          this.isAccountMappingEnabledByDest()
        : !this.isAccountMappingEnabledByDest() &&
          this.isAccountMappingEnabledByInit());
    const companyNeedsToAcceptNewProspects =
      this.isAccepted() &&
      (this.isInitiator(company)
        ? !this.isNewLeadsEnabledByInit() && this.isNewLeadsEnabledByDest()
        : !this.isNewLeadsEnabledByDest() && this.isNewLeadsEnabledByInit());
    return (
      !this.isGhost() &&
      !this.isPaused() &&
      (companyNeedsToAcceptPartnership ||
        (!partnerMissingSource &&
          (companyNeedsToAcceptOverlaps || companyNeedsToAcceptNewProspects)))
    );
  }

  // A partnership is pending by partner if company is waiting for
  // a response (approve or decline) or
  // overlap or prospects sharing from partner side.
  isStatusPendingByPartner(company: Record) {
    const partnerNeedsToAcceptPartnership =
      !this.isAccepted() && this.isInitiator(company);
    const companyNeedsToAcceptOverlaps =
      this.isAccepted() &&
      (this.isInitiator(company)
        ? !this.isAccountMappingEnabledByDest() &&
          this.isAccountMappingEnabledByInit()
        : !this.isAccountMappingEnabledByInit() &&
          this.isAccountMappingEnabledByDest());
    const companyNeedsToAcceptNewProspects =
      this.isAccepted() &&
      (this.isInitiator(company)
        ? !this.isNewLeadsEnabledByDest() && this.isNewLeadsEnabledByInit()
        : !this.isNewLeadsEnabledByInit() && this.isNewLeadsEnabledByDest());
    return (
      !this.isGhost() &&
      !this.isPaused() &&
      (partnerNeedsToAcceptPartnership ||
        (this.ready &&
          (companyNeedsToAcceptOverlaps || companyNeedsToAcceptNewProspects)))
    );
  }

  // To be active, overlap should be shared.
  isStatusActive(company: Record) {
    const partnerMissingSource = this.warnings(company).partner
      .missingIntegration;
    return (
      !this.isGhost() &&
      !this.isPaused() &&
      this.ready &&
      !partnerMissingSource &&
      this.isAccountMappingEnabled()
    );
  }

  invitedUsername() {
    if (this.invites && this.invites.length > 0) {
      const { firstName, lastName } = this.invites[0];
      return `${firstName} ${lastName}`;
    }
    return this.getDestCompany().name;
  }

  isOverviewAndOverlapsAvailable() {
    return (
      this.isDemo ||
      (this.ready &&
        (this.isAccepted() || this.isGhost()) &&
        !this.isPaused() &&
        this.integrations.length > 0 &&
        this.hasPartnerIntegration)
    );
  }

  // PartnerStack helpers

  // As a connected PartnerStack vendor
  // I can “submit a lead via PartnerStack” from the Account Mapping (for my own company)
  isSubmitViaPartnerStackAvailable(company: Record) {
    return this.isInitiator(company)
      ? this.isInitiatorPartnerStackPartnershipEligible
      : this.isDestPartnerStackPartnershipEligible;
  }

  submitPartnerStackPartnership(company: Record) {
    return this.isInitiator(company)
      ? this.initiatorPartnerStackPartnership
      : this.destPartnerStackPartnership;
  }

  // As a Partner of a connected PartnerStack vendor
  // I can “Refer a lead via PartnerStack” from the Account Mapping (for my partner)
  isReferViaPartnerStackAvailable(company: Record) {
    return this.isInitiator(company)
      ? this.isDestPartnerStackPartnershipEligible
      : this.isInitiatorPartnerStackPartnershipEligible;
  }

  referPartnerStackPartnership(company: Record) {
    return this.isInitiator(company)
      ? this.destPartnerStackPartnership
      : this.initiatorPartnerStackPartnership;
  }

  /**
   * @param kpis - Map of KPIs to load. This is important for performance to load only what is needed
   */
  async loadAssessment(
    kpis: OverviewParameters = DEFAULT_ASSESSMENT,
    includeNbMatchedAccounts: boolean = false
  ) {
    const payload = {} as {
      [kpi: string]: { [filter: string]: JSONSerializable };
    };
    _.forEach(kpis, (filters: FilterType[] | undefined, kpi: string) => {
      if (filters !== undefined && !_.isEmpty(filters)) {
        payload[kpi] = MatchFilter.forAPI(filters);
      }
    });
    const options = {
      fields: {
        partnership_overviews: Object.keys(kpis),
      },
    };
    if (includeNbMatchedAccounts) {
      options.fields.partnership_overviews = [
        ...options.fields.partnership_overviews,
        "nb_matched_accounts",
        "nb_matched_accounts_not_private",
      ];
    }
    const service = new JSONAPIService("partnerships");
    const response = await service.rawPost<JSONAPIResponse>(
      this.id,
      "/overview/",
      payload,
      options
    );
    return response.data.data.attributes as OverviewFiltersType;
  }

  get integration(): Integration | null {
    if (this.integrations.length > 0) {
      return this.integrations[0];
    }
    return null;
  }
}

export const _private = {
  i18n,
};
