import { FormControl, FormHelperText } from "@mui/material";
import { isFulfilled } from "@reduxjs/toolkit";
import { RejectedWithValueActionFromAsyncThunk } from "@reduxjs/toolkit/dist/matchers";
import { AxiosResponse } from "axios";
import Alert from "components/ui/Alert";
import { SelectInput } from "components/ui/SelectInput";
import { TextInput } from "components/ui/TextInput";
import { T } from "components/ui/Typography";
import { ProviderType } from "config/constants";
import { Form, FormikHelpers, FormikProps } from "formik";
import getFirstValueFromURL from "helpers/getFirstValueFromURL";
import useAllRecords from "hooks/useAllRecords";
import usePushNotification from "hooks/usePushNotification";
import useUserProfile from "hooks/useUserProfile";
import { debounce } from "lodash";
import { makeStyles } from "makeStyles";
import CrmCredential from "models/CrmCredential";
import { ChangeEvent, useCallback, useState } from "react";
import {
  defineMessages,
  FormattedHTMLMessage,
  FormattedMessage,
  useIntl,
} from "react-intl";
import { useDispatch } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import { update } from "redux/api/thunks";
import JSONAPIService from "services/JSONAPIService";
import * as yup from "yup";

import { GSHEET_URL_REGEX } from "../helpers/types";

type Props = {
  ghostPartnershipId?: number;
  onClose?: () => void;
};

type SheetResponse = {
  sheet_names: string[];
  title: string;
};

export type IGsheetForm = {
  tab: string;
  url: string;
};

const LOADING = "Loading...";

export const TAB_SELECTION_VALIDATION_SCHEMA = yup.object({
  tab: yup.string().required(),
  url: yup.string().matches(GSHEET_URL_REGEX, {
    excludeEmptyString: true,
    message: "urlRegexError",
  }),
});

export const useGsheetTabSelectionDialogContent = ({
  ghostPartnershipId,
  onClose,
}: Props) => {
  const history = useHistory();
  const location = useLocation();
  const { cx, classes } = useStyles();
  const [sheets, setSheets] = useState<string[]>([]);
  const [sheetsLoading, setSheetsLoading] = useState(false);
  const [title, setTitle] = useState<string>();
  const [error, setError] = useState<string | undefined>(undefined);
  const pushNotification = usePushNotification();
  const dispatch = useDispatch();
  const { profile } = useUserProfile();
  const isOnboarding = getFirstValueFromURL(location, "onboarding") === "true";
  const isNew = getFirstValueFromURL(location, "init") === "true";
  const next = getFirstValueFromURL(
    location,
    "next",
    `${location.pathname}?provider=${ProviderType.gsheet}${
      isNew ? "&init=true" : ""
    }${isOnboarding ? "&onboarding=true" : ""}${
      ghostPartnershipId ? `&isGhost=${ghostPartnershipId}` : ""
    }#loading`
  );

  const intl = useIntl();

  const handleClose = (props: FormikProps<IGsheetForm>) => () => {
    props.resetForm();
    !!onClose && onClose();
  };

  const { records: crmCredentials } = useAllRecords<CrmCredential>(
    "crm_credentials",
    {
      filters: {
        company_id: profile.company.id,
      },
    },
    undefined,
    [profile.company.integrationCount]
  );

  const handleSubmit = async (
    values: IGsheetForm,
    helpers: FormikHelpers<IGsheetForm>
  ) => {
    if (!title) {
      return;
    }
    const { id } =
      crmCredentials.find(
        (credential) =>
          credential.provider === ProviderType.gsheet &&
          !credential.config.gsheet_id &&
          !credential.config.tab_name
      ) ?? {};
    if (!id) {
      return;
    }
    const match = values.url.match(GSHEET_URL_REGEX);
    if (!match) {
      return;
    }
    helpers.setSubmitting(true);
    const sheetId = match[2];
    const result = await dispatch(
      update({
        type: "crm_credentials",
        id,
        attributes: {
          disabled: false,
          config: {
            gsheet_id: sheetId,
            tab_name: values.tab,
            title,
            ghost_partnership_id: ghostPartnershipId ?? null,
          },
        },
      })
    );
    helpers.setSubmitting(false);
    if (isFulfilled(result)) {
      history.push(next);
    } else {
      setError(
        ((result as any) as RejectedWithValueActionFromAsyncThunk<
          typeof update
        >)?.payload?.code
      );
    }
  };

  const handleFetch = useCallback(
    async (event: ChangeEvent<any>) => {
      const { value } = event.target;
      const match = value.match(GSHEET_URL_REGEX);
      if (!match) {
        return;
      }
      const id = match[2];
      const service = new JSONAPIService("crm_credentials_gsheet");
      let response: AxiosResponse<SheetResponse> | undefined = undefined;
      setSheetsLoading(true);
      try {
        response = await service.get<SheetResponse>(id);
        setSheets(response.data.sheet_names);
        setTitle(response.data.title);
      } catch (error: any) {
        setError(error.response?.data?.error?.status);
        pushNotification(i18n.fetchTabError);
      } finally {
        setSheetsLoading(false);
      }
    },
    [setSheets, setSheetsLoading, setError, pushNotification]
  );

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedFetch = useCallback(debounce(handleFetch, 400), [handleFetch]);

  const dialogContent = ({
    errors,
    handleBlur,
    isSubmitting,
    handleChange,
    setFieldValue,
    touched,
    values,
  }: FormikProps<IGsheetForm>) => (
    <div className={classes.flexCenter}>
      <Form className={classes.fullwidth}>
        {!!i18n[error as keyof typeof i18n] && (
          <Alert
            variant="warning"
            icon
            className={cx(classes.fullwidth, classes.marginBottomSmall)}
          >
            <FormattedMessage {...i18n[error as keyof typeof i18n]} />
          </Alert>
        )}
        <FormControl className={classes.marginBottomSmall} fullWidth>
          <TextInput
            disabled={isSubmitting}
            name="url"
            fullWidth
            onBlur={handleBlur}
            onChange={(e: ChangeEvent<HTMLInputElement>) => {
              setSheets([]);
              setTitle(undefined);
              setFieldValue("tab", "");
              setError(undefined);
              handleChange(e);
              debouncedFetch(e);
            }}
            placeholder={intl.formatMessage(i18n.urlPlaceholder)}
            type="text"
            value={values.url ?? ""}
            error={!!errors.url && !!touched.url}
            label={null}
            variant="tertiary"
          />
          <FormHelperText>
            {errors.url && touched.url && (
              <FormattedMessage {...i18n?.[errors.url as keyof typeof i18n]} />
            )}
          </FormHelperText>
        </FormControl>
        <FormControl fullWidth>
          <SelectInput
            disabled={isSubmitting || !sheets.length || sheetsLoading}
            placeholder={intl.formatMessage(i18n.tabPlaceholder)}
            value={sheetsLoading ? LOADING : values.tab ?? ""}
            onChange={handleChange}
            error={!!errors.url && !!touched.url}
            name="tab"
            options={sheets.map((item) => ({
              value: item,
              label: item,
            }))}
            variant="tertiary"
          />
        </FormControl>
      </Form>
    </div>
  );

  const dialogTitle = (
    <div className={cx(classes.flexCenter, classes.marginBottom)}>
      <T h3 bold>
        <FormattedMessage {...i18n.addGsheet} />
      </T>
      <T>
        <FormattedHTMLMessage {...i18n.gsheetUrlDescription} />
      </T>
    </div>
  );

  return { handleClose, handleSubmit, dialogContent, dialogTitle };
};

const useStyles = makeStyles()((theme) => ({
  flexCenter: {
    alignItems: "center",
    flexDirection: "column",
    gap: theme.spacing(2),
    display: "flex",
    justifyContent: "center",
  },
  fullwidth: {
    width: "100%",
  },
  marginBottom: {
    marginBottom: theme.spacing(2),
  },
  marginBottomSmall: {
    marginBottom: theme.spacing(1),
  },
}));

const i18n = defineMessages({
  addGsheet: {
    id: "GsheetTabSelection.addGsheet",
    defaultMessage: "Add Google Sheet",
  },
  gsheetUrlDescription: {
    id: "GsheetTabSelection.gsheetUrlDescription",
    defaultMessage:
      "&#x2022;&nbsp;Google Sheet URL format: https://docs.google.com/spreadsheets/d/*****/ <br/>&#x2022;&nbsp;You must have access to the sheet (or the sharing settings should be <b>anyone with the link</b>)",
  },
  fetchTabError: {
    id: "GsheetTabSelection.fetchTabError",
    defaultMessage: "Failed to fetch the sheets",
  },
  tabPlaceholder: {
    id: "GsheetTabSelection.tabPlaceholder",
    defaultMessage: "Select a sheet",
  },
  urlPlaceholder: {
    id: "GsheetTabSelection.urlPlaceholder",
    defaultMessage: "Enter your Google Sheet URL...",
  },
  urlRegexError: {
    id: "GsheetTabSelection.urlRegexError",
    defaultMessage: "Please enter a valid URL",
  },
  FAILED_PRECONDITION: {
    id: "GsheetTabSelection.FAILED_PRECONDITION",
    defaultMessage: "This file is not a native Google Spreadsheet.",
  },
  NOT_FOUND: {
    id: "GsheetTabSelection.NOT_FOUND",
    defaultMessage: "Requested entity was not found.",
  },
  PERMISSION_DENIED: {
    id: "GsheetTabSelection.PERMISSION_DENIED",
    defaultMessage: "You don't have permission to use this file.",
  },
  DUPLICATE_CREDENTIALS: {
    id: "GsheetTabSelection.DUPLICATE_CREDENTIALS",
    defaultMessage:
      "These credentials are already in use. Please use a different Google Sheet or Tab.",
  },
});
