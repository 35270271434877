import SearchIcon from "@mui/icons-material/Search";
import Grid from "@mui/material/Grid";
import { ClassNameMap, lighten } from "@mui/material/styles";
import Checkbox from "components/ui/Checkbox";
import { ConfigContext } from "config/ConfigProvider";
import { debounce } from "lodash";
import { makeStyles } from "makeStyles";
import { ChangeEvent, useContext, useState } from "react";
import { useIntl } from "react-intl";
import { useDispatch } from "react-redux";
import Select from "react-select";
import { index } from "redux/api/thunks";
import { useMergedClasses } from "tss-react";

import { greyWhite } from "../../../styles/colors";
import { TextInput } from "../TextInput";

type Props = {
  classes?: ClassNameMap;
  handleChange: $TSFixMeFunction;
  query: $TSFixMe;
  selectFilterItems?: $TSFixMe;
  tableFilters: $TSFixMe[];
  resource?: string;
};

export const ListTableFilters = ({
  classes: customClasses = {},
  handleChange,
  query,
  selectFilterItems,
  tableFilters,
  resource,
}: Props) => {
  const { classes: baseClasses } = useStyles();
  const classes = useMergedClasses(baseClasses, customClasses);
  const dispatch = useDispatch();
  const intl = useIntl();
  const mainColor = useContext(ConfigContext).mainColor;
  const [isLoading, setIsLoading] = useState(false);

  const SelectMultipleStyle = {
    control: (styles: $TSFixMe) => ({
      ...styles,
      backgroundColor: "white",
      borderRadius: 20,
      minHeight: 32,
    }),
    clearIndicator: (provided: any) => ({
      ...provided,
      padding: 4,
    }),
    dropdownIndicator: (provided: any) => ({
      ...provided,
      padding: 4,
    }),
    option: (styles: $TSFixMe, { data, isFocused }: $TSFixMe) => {
      return {
        ...styles,
        fontWeight: data.topLevel ? 600 : 400,
        backgroundColor: isFocused
          ? lighten(mainColor, 0.9)
          : data.topLevel
          ? greyWhite
          : "",
      };
    },
  };

  const onInputChange = debounce(async (query: string) => {
    setIsLoading(true);
    await dispatch(
      index({
        type: "companies",
        options: { filters: { search: query } },
      })
    );
    setIsLoading(false);
  }, 300);

  return (
    <Grid className={classes.filterContainer}>
      <form className={classes.filterForm}>
        {tableFilters.map((filter) => {
          switch (filter.type) {
            case "text":
              return (
                <TextInput
                  key={filter.id}
                  small
                  placeholder={intl.formatMessage(filter.message)}
                  value={query[filter.id]}
                  variant="tertiary"
                  onChange={(e: ChangeEvent<HTMLInputElement>) =>
                    handleChange(filter.id, e.target.value)
                  }
                  LeftIcon={SearchIcon}
                />
              );
            case "checkbox":
              return (
                <Checkbox
                  key={filter.id}
                  checked={query[filter.id]}
                  onChange={(e) => handleChange(filter.id, e.target.checked)}
                  label={filter.message}
                />
              );
            case "select-multiple":
              return (
                <Select
                  isLoading={isLoading}
                  key={filter.id}
                  value={selectFilterItems[filter.id]
                    .filter((item: $TSFixMe) => {
                      if (Array.isArray(query[filter.id])) {
                        return query[filter.id].includes(item.id);
                      } else {
                        return query[filter.id] === item.id;
                      }
                    })
                    .map((item: $TSFixMe) => ({
                      value: item.id,
                      label: item.label,
                      topLevel: item.topLevel,
                    }))}
                  isMulti
                  options={selectFilterItems[filter.id].map(
                    (item: $TSFixMe) => ({
                      value: item.id,
                      label: item.label,
                      topLevel: item.topLevel,
                    })
                  )}
                  placeholder={
                    typeof filter.message === "string"
                      ? filter.message
                      : intl.formatMessage(filter.message)
                  }
                  className={classes.selectField}
                  theme={(theme: $TSFixMe) => ({
                    ...theme,

                    colors: {
                      ...theme.colors,
                      primary25: greyWhite,
                      primary: mainColor,
                    },
                  })}
                  onInputChange={
                    resource !== "users" ? () => {} : onInputChange
                  }
                  onChange={(options: $TSFixMe) => {
                    handleChange(
                      filter.id,
                      options ? options.map(({ value }: $TSFixMe) => value) : ""
                    );
                  }}
                  styles={SelectMultipleStyle}
                />
              );
            default:
              return null;
          }
        })}
      </form>
    </Grid>
  );
};

// CSS

const useStyles = makeStyles()((theme) => ({
  filterContainer: {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "flex-start",
    backgroundColor: theme.palette.ivory,
    borderBottom: `1px solid ${theme.palette.alpha200}`,
  },
  filterForm: {
    margin: theme.spacing(1),
    display: "flex",
    alignItems: "center",
    columnGap: theme.spacing(1),
  },
  selectField: {
    margin: theme.spacing(1),
    minWidth: theme.spacing(30),
  },
}));
