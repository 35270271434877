import DialogActions from "@mui/material/DialogActions";
import FormControl from "@mui/material/FormControl";
import FormHelperText from "@mui/material/FormHelperText";
import Grid from "@mui/material/Grid";
import { isFulfilled, isRejected } from "@reduxjs/toolkit";
import Button from "components/ui/Button";
import Popup from "components/ui/Popup";
import { TextInput } from "components/ui/TextInput";
import { T } from "components/ui/Typography";
import { Formik } from "formik";
import useAllRecords from "hooks/useAllRecords";
import usePushNotification from "hooks/usePushNotification";
import forms from "i18n/forms";
import generic from "i18n/generic";
import { makeStyles } from "makeStyles";
import { defineMessages, FormattedHTMLMessage, useIntl } from "react-intl";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { create, update } from "redux/api/thunks";
import { crmProviders } from "screens/Frontoffice/screens/Sources/constants";
import { APIKeyAndDomainProviderType } from "screens/Frontoffice/shared/helpers/types";
import { object, string } from "yup";

type Props = {
  provider: APIKeyAndDomainProviderType;
  isOpen?: boolean;
  onClose: () => void;
  next: string | Location;
};

const HTTP_PREFIX = "^https?://";
const END_DOMAIN_URL = "(freshsales.io|freshworks.com)$";

function isFreshsalesUrl(url?: string) {
  return url !== undefined && new RegExp(END_DOMAIN_URL).test(url);
}

const schema = object().shape({
  key: string().required(),
  host: string()
    .required(() => "_do_not_display")
    .url(() => forms.url_invalid)
    .test(
      "is-domain-url-valid",
      () => i18n.notFreshsalesFormat,
      isFreshsalesUrl
    ),
});

const APIKeyAndDomainDialog = ({
  provider,
  isOpen = false,
  onClose,
  next,
}: Props) => {
  const { classes } = useStyles();
  const history = useHistory();
  const intl = useIntl();
  const dispatch = useDispatch();
  const pushNotification = usePushNotification();
  const providerName = crmProviders[provider].name;

  const { records: crmCredentials } = useAllRecords(
    "crm_credentials",
    { filters: { provider } },
    () => undefined,
    [provider]
  );
  const crmCredentialId = crmCredentials[0]?.id;

  const handleMSModalSubmit = async (
    values: { key: string; host: string },
    actions: $TSFixMe
  ) => {
    actions.setSubmitting(true);
    let result;
    values.host = values.host.replace(new RegExp(HTTP_PREFIX), "");
    crmCredentialId
      ? (result = await dispatch(
          update({
            type: "crm_credentials",
            id: crmCredentialId,
            attributes: {
              provider: provider,
              disabled: false,
              config: { api_key: values.key, host: values.host }, // pragma: allowlist secret
            },
          })
        ))
      : (result = await dispatch(
          create({
            type: "crm_credentials",
            attributes: {
              provider: provider,
              config: { api_key: values.key.trim(), host: values.host }, // pragma: allowlist secret
            },
          })
        ));

    if (isFulfilled(result)) {
      onClose();
      history.push(next);
    }
    if (isRejected(result)) {
      pushNotification("default_error");
    }
    actions.setSubmitting(false);
  };

  return (
    <Popup
      isOpen={isOpen}
      title={
        <T h2 bold>
          <FormattedHTMLMessage
            {...i18n.title}
            values={{ provider: providerName }}
          />
        </T>
      }
      handleClose={onClose}
      hideActions
      maxWidth="sm"
      variant="secondary"
    >
      <Formik
        initialValues={{ key: "", host: "" }}
        onSubmit={handleMSModalSubmit}
        validationSchema={schema}
      >
        {({
          values,
          handleSubmit,
          handleChange,
          isSubmitting,
          touched,
          errors,
        }) => (
          <form onSubmit={handleSubmit}>
            <FormControl
              fullWidth
              error={Boolean(
                errors.key && touched.key && errors.host && touched.host
              )}
            >
              <T>
                <FormattedHTMLMessage
                  {...i18n.freshsalesURLFormatDescription}
                  values={{ provider: providerName }}
                />
              </T>
              <TextInput
                className={classes.input}
                name="host"
                onChange={handleChange}
                placeholder={intl.formatMessage(i18n.placeholderHost)}
                disabled={isSubmitting}
                error={Boolean(errors.host && touched.host)}
                value={values.host}
              />
              {errors.host &&
                touched.host &&
                errors.host !== "_do_not_display" && (
                  <FormHelperText>
                    <FormattedHTMLMessage {...i18n.notFreshsalesFormat} />
                  </FormHelperText>
                )}
              <br />
              <T>
                <FormattedHTMLMessage
                  {...i18n.apiKeyDescription}
                  values={{ provider: providerName }}
                />
              </T>
              <br />
              <TextInput
                className={classes.input}
                name="key"
                onChange={handleChange}
                placeholder={intl.formatMessage(i18n.placeholderApi)}
                disabled={isSubmitting}
                error={Boolean(touched.key && errors.key)}
                value={values.key}
              />
            </FormControl>
            <DialogActions>
              <Grid container className={classes.actionContainer}>
                <Button
                  label={generic.cancel}
                  onClick={onClose}
                  disabled={isSubmitting}
                  variant="secondary"
                />
                <Button
                  type="submit"
                  label={generic.next}
                  disabled={isSubmitting ? true : undefined}
                />
              </Grid>
            </DialogActions>
          </form>
        )}
      </Formik>
    </Popup>
  );
};

export default APIKeyAndDomainDialog;

/// CSS

const useStyles = makeStyles()((theme) => ({
  input: {
    fontSize: 12,
    margin: theme.spacing(1),
  },
  actionContainer: {
    display: "flex",
    justifyContent: "flex-end",
    columnGap: theme.spacing(0.5),
  },
}));

/// I18N

const i18n = defineMessages({
  title: {
    id: "ConnectSource.APIKeyAndDomainDialog.title",
    defaultMessage: "Connect with {provider}",
  },
  apiKeyDescription: {
    id: "ConnectSource.APIKeyAndDomainDialog.apiKeyDescription",
    defaultMessage:
      "Your {provider} API Key can be found within API Settings in your {provider} account.",
  },
  freshsalesURLFormatDescription: {
    id: "ConnectSource.APIKeyAndDomainDialog.freshsalesURLFormatDescription",
    defaultMessage:
      "Your Freshsales organization URL should follow one of the following formats:<ul><li>https://******.freshsales.io</li><li>https://******.freshworks.com</li><li>https://******.myfreshworks.com</li></ul>",
  },
  notFreshsalesFormat: {
    id: "ConnectSource.APIKeyAndDomainDialog.notFreshsalesFormat",
    defaultMessage: `Freshsales URL should either end with "freshsales.io" or "freshworks.com"`,
  },
  placeholderApi: {
    id: "ConnectSource.APIKeyAndDomainDialog.placeholderApi",
    defaultMessage: "API Key",
  },
  placeholderHost: {
    id: "ConnectSource.APIKeyAndDomainDialog.placeholderHost",
    defaultMessage: "Freshsales organization URL",
  },
});
