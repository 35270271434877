import _ from "lodash";
import {
  MatchFilter,
  MatchFilterType,
} from "screens/Frontoffice/screens/DataTables/shared/types";

import Record from "./Record";
import { JSONAPIResourceOrRecord } from "./types";

/**
 * Maps a list of array of objects to MatchFilter instances, filtering invalid ones
 * @param values
 * @returns MatchFilter[]
 */
function toMatchFilters(values: any[]): MatchFilter[] {
  return values
    .map((value) => {
      if (
        "type" in value &&
        _.values(MatchFilterType).includes(value["type"]) &&
        "fieldname" in value &&
        typeof value["fieldname"] === "string" &&
        "value" in value
      ) {
        return new MatchFilter(
          value["fieldname"],
          value["value"],
          value["type"]
        );
      }
      return null;
    })
    .filter((value): value is MatchFilter => value !== null);
}

export default class PartnershipOverviewView extends Record<"partnership_overview_views"> {
  commonCustomersFilters: MatchFilter[] = [];
  commonOpportunitiesFilters: MatchFilter[] = [];
  prospectsToCustomersFilters: MatchFilter[] = [];
  customersToProspectsFilters: MatchFilter[] = [];
  sharedFilters: MatchFilter[] = [];

  constructor(data: JSONAPIResourceOrRecord<"partnership_overview_views">) {
    super(data);
    if (_.isArray(this.attributes.common_customers_filters)) {
      this.commonCustomersFilters = toMatchFilters(
        this.attributes.common_customers_filters
      );
    }
    if (_.isArray(this.attributes.common_opportunities_filters)) {
      this.commonOpportunitiesFilters = toMatchFilters(
        this.attributes.common_opportunities_filters
      );
    }
    if (_.isArray(this.attributes.prospects_to_customers_filters)) {
      this.prospectsToCustomersFilters = toMatchFilters(
        this.attributes.prospects_to_customers_filters
      );
    }
    if (_.isArray(this.attributes.customers_to_prospects_filters)) {
      this.customersToProspectsFilters = toMatchFilters(
        this.attributes.customers_to_prospects_filters
      );
    }
    if (_.isArray(this.attributes.shared_filters)) {
      this.sharedFilters = toMatchFilters(this.attributes.shared_filters);
    }
  }
}
