import history from "_history";
import { Box, CircularProgress } from "@mui/material";
import { isFulfilled, isRejected } from "@reduxjs/toolkit";
import { Plus } from "components/icons";
import Alert from "components/ui/Alert";
import CompanyAvatar from "components/ui/avatars/CompanyAvatar";
import Button from "components/ui/Button";
import Checkbox from "components/ui/Checkbox";
import { PayingFeatureTooltip } from "components/ui/PayingFeatureTooltip";
import ToggleBtnGroup from "components/ui/ToggleBtnGroup";
import { T } from "components/ui/Typography";
import { OFFLINE_ACCOUNT_MAPPING_LIMIT } from "config/constants";
import { Form, Formik, FormikHelpers } from "formik";
import { getTrimmedData } from "helpers/trim";
import useHasPayingFeature from "hooks/useHasPayingFeature";
import usePushNotification from "hooks/usePushNotification";
import useSegment from "hooks/useSegment";
import useUserProfile from "hooks/useUserProfile";
import _ from "lodash";
import { makeStyles } from "makeStyles";
import { PayingFeature } from "models/CompanyPayingFeatureSubscription";
import Partnership from "models/Partnership";
import { SuggestedPartnerSource } from "models/SuggestedPartner";
import { JSONAPIAttributes } from "models/types";
import { useCallback, useEffect, useMemo, useState } from "react";
import { defineMessages, FormattedMessage } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { create, rawPost } from "redux/api/thunks";
import {
  addCreatedPartner,
  transformSuggestion,
} from "redux/dashboard/actions";
import { fields, included } from "redux/init/constants";
import { initializePartnershipsAndCompanyNames } from "redux/init/thunks";
import {
  openWidget,
  toggleFree,
  togglePower,
  togglePro,
} from "redux/premiumPlanWidget/actions";
import { selectSuggestions } from "redux/suggestedPartners/selectors";
import { initializeSuggestedPartners } from "redux/suggestedPartners/thunks";
import { RevealStore } from "redux/typing";
import { fetchProfile } from "redux/user/thunks";
import { JSONAPIResponse } from "services/types";
import {
  InviteFormEvent,
  PremiumPlanEvent,
  SuggestionFromCrmEvent,
} from "tracking";

import {
  DialogView,
  FormStep,
  FROM_URL_TO_PARTNERSHIP_SOURCE,
  IContact,
  IInviteForm,
  INewContact,
  initialValues,
  inviteAdminsSchema,
  inviteSchema,
  partnershipSchema,
  SelectedCompany,
  SentFromURL,
} from "../types";
import { InviteClarification } from "./InviteClarification";
import { InviteFormCompanyAutocomplete } from "./InviteFormCompanyAutocomplete";
import { InviteFormContactSelect } from "./InviteFormContactSelect";
import { InviteFormDialogActions } from "./InviteFormDialogActions";
import { InviteFormGenericFields } from "./InviteFormGenericFields";
import { InviteFormMessageField } from "./InviteFormMessageField";
import { InviteFormStatusRulesForCrmTip } from "./InviteFormStatusRulesForCrmTip";

type Props = {
  partnership?: Partnership;
  onboarding?: boolean;
  onInviteSuccess: $TSFixMeFunction;
  initial?: string;
  fullWidth?: boolean;
};

const InviteForm = ({
  partnership,
  onInviteSuccess,
  initial = "",
  fullWidth = false,
}: Props) => {
  const partnershipId = partnership?.id;
  const { profile } = useUserProfile();
  const { track } = useSegment();
  const pushNotification = usePushNotification();
  const location = useLocation();
  const dispatch = useDispatch();
  const [selectedUser, setSelectedUser] = useState<
    IContact | INewContact | null
  >(null);

  const { suggestions } = useSelector(selectSuggestions);
  const directoryCompanies = useSelector((state: RevealStore) =>
    _.get(state, "api.entities.directory_companies")
  );

  const crmSuggestions = useMemo(
    () =>
      suggestions.filter(
        (company) => company.source === SuggestedPartnerSource.Crm
      ),
    [suggestions]
  );

  useEffect(() => {
    if (location.pathname === "/invite") {
      dispatch(initializeSuggestedPartners({ partnerOnReveal: false }));
    }
    return () => {
      if (location.pathname === "/invite") {
        dispatch(initializeSuggestedPartners({ partnerOnReveal: true }));
      }
    };
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const [dialogView, setDialogView] = useState(
    partnershipId
      ? DialogView.PartnershipExtraInviteDirect
      : DialogView.GenericFirstInvite
  );

  const [
    selectedCompany,
    setSelectedCompany,
  ] = useState<SelectedCompany | null>(null);

  const suggestedPartnerIdString = useMemo(
    () =>
      new URLSearchParams(location.search).get(SentFromURL.SuggestedPartner),
    [location.search]
  );
  const companyFromDirectoryIdString = useMemo(
    () => new URLSearchParams(location.search).get(SentFromURL.Directory),
    [location.search]
  );
  const isSuggestedPartnerFromDirectory = useMemo(
    () =>
      !!new URLSearchParams(location.search).get(
        SentFromURL.SuggestedPartnerFromDirectory
      ),
    [location.search]
  );
  const isPartnerOnReveal = useMemo(
    () => new URLSearchParams(location.search).get("is-external") !== "true",
    [location.search]
  );
  const directoryTab = useMemo(
    () => new URLSearchParams(location.search).get("directory-tab"),
    [location.search]
  );

  const sentFrom = suggestedPartnerIdString
    ? SentFromURL.SuggestedPartner
    : companyFromDirectoryIdString
    ? SentFromURL.Directory
    : SentFromURL.InvitePage;

  const showCrmSuggestions = useMemo(
    () =>
      crmSuggestions.length > 0 &&
      sentFrom === SentFromURL.InvitePage &&
      dialogView === DialogView.GenericFirstInvite,
    [crmSuggestions, sentFrom, dialogView]
  );
  const showStatusRulesForCrmTip = useMemo(
    () =>
      crmSuggestions.length <= 0 &&
      sentFrom === SentFromURL.InvitePage &&
      dialogView === DialogView.GenericFirstInvite &&
      profile.canManageSources,
    [crmSuggestions, sentFrom, dialogView, profile.canManageSources]
  );

  // RVL-TODO: Investigate to remove currentStep
  const [currentStep, setCurrentStep] = useState<FormStep>(
    showCrmSuggestions ? FormStep.CompanyFromCrm : FormStep.CompanyNoCrm
  );

  const [inviterEmailInCc, setInviterEmailInCc] = useState(true);

  useEffect(() => {
    if (showCrmSuggestions) {
      setCurrentStep(FormStep.CompanyFromCrm);
    }
  }, [showCrmSuggestions]);

  const hasSelectedCompany = selectedCompany !== null;
  const loading =
    (suggestedPartnerIdString || companyFromDirectoryIdString) &&
    !hasSelectedCompany;

  const hasUnlimitedOfflineAccountMapping =
    useHasPayingFeature(
      PayingFeature.UnlimitedOfflineAccountMapping,
      profile
    ) || false;

  const offlineAccountMappingLimitReached =
    !hasUnlimitedOfflineAccountMapping &&
    profile.company?.ghostPartnership >= OFFLINE_ACCOUNT_MAPPING_LIMIT;

  const { classes } = useStyles({
    fullWidth,
    hasSelectedCompany,
    isFirstStep: !showCrmSuggestions,
  });
  const { classes: checkboxClasses } = useCheckboxStyles();

  const handleClose = useCallback(() => {
    if (hasSelectedCompany) {
      history.push({ ...location, search: "", hash: "" });
    } else {
      history.goBack({ fallback: "/partnerships" });
    }
    const suggestedPartnerId =
      !!suggestedPartnerIdString && Number(suggestedPartnerIdString);
    track(InviteFormEvent.closed, {
      from: "invite-page",
      requestedCompanyName: selectedCompany?.name,
      requestedCompanyDomain: selectedCompany?.domain,
      ...(suggestedPartnerId
        ? {
            source: suggestions.find(
              (item) => item.partner?.id === selectedCompany?.id
            )?.source,
          }
        : {}),
    });
  }, [
    hasSelectedCompany,
    location,
    selectedCompany,
    suggestions,
    suggestedPartnerIdString,
    track,
  ]);

  const goToInvitePrivate = () => history.push("/invite-private");

  // Get selected company from suggested partner id
  useEffect(() => {
    const suggestedPartnerId = Number(suggestedPartnerIdString);
    if (!suggestedPartnerIdString || isNaN(suggestedPartnerId)) {
      return;
    }

    setDialogView(DialogView.SuggestedPartnerDirect);

    if (companyFromDirectoryIdString) return; // in this case, we arrived here from the directory, therefor we can use directory_companies api instead of suggested_partners

    const suggestedPartner = suggestions.find(
      (item) => item.id === suggestedPartnerId
    );
    if (!suggestedPartner) return;

    const {
      partner,
      partnerLogo,
      partnerDomainName,
      partnerName,
    } = suggestedPartner;

    setSelectedCompany({
      description: partner?.description,
      id: partner?.id,
      name: partnerName,
      logo: partnerLogo,
      domain: partnerDomainName,
      websiteUrl: partner?.websiteUrl,
      companyType: partner?.businessType,
      ecosystem: partner?.ecosystem,
    } as SelectedCompany);
  }, [suggestions, suggestedPartnerIdString, companyFromDirectoryIdString]);

  useEffect(() => {
    const companyFromDirectoryId = Number(companyFromDirectoryIdString);
    if (!companyFromDirectoryIdString || isNaN(companyFromDirectoryId)) {
      return;
    }

    const directoryCompany = directoryCompanies?.[companyFromDirectoryId];
    if (!directoryCompany) return;

    setSelectedCompany({
      accountOverlap: directoryCompany?.accountOverlap,
      contactOverlap: directoryCompany?.contactOverlap,
      description: directoryCompany?.description,
      id: directoryCompany?.companyId,
      name: directoryCompany?.name,
      logo: `https://logo.clearbit.com/${directoryCompany?.domainName}`,
      domain: directoryCompany?.domainName,
      websiteUrl: directoryCompany?.website,
      companyType: directoryCompany?.businessType,
      ecosystem: directoryCompany?.ecosystem,
      size: directoryCompany?.size,
    } as SelectedCompany);

    if (!suggestedPartnerIdString) {
      setDialogView(DialogView.SuggestedPartnerDirect);
    }
  }, [
    directoryCompanies,
    companyFromDirectoryIdString,
    suggestedPartnerIdString,
  ]);

  const createPartnership = async (
    values: IInviteForm,
    fromSelected: Boolean = false
  ) => {
    let data = {
      initiator_company_id: profile.companyId,
      initiator_user_id: profile.id,
      requested_company_name: values.company.name,
      requested_company_domain: values.company.domain,
      invites: [
        {
          type: "partnership_invites",
          attributes: values.email
            ? {
                email: values.email,
                first_name: values.first_name,
                last_name: values.last_name,
                message: values.message,
                inviter_email_in_cc: inviterEmailInCc,
              }
            : {
                email: null,
                first_name: null,
                last_name: null,
                message: values.message,
              },
        },
      ],
      source: FROM_URL_TO_PARTNERSHIP_SOURCE[sentFrom],
    } as JSONAPIAttributes;
    if (fromSelected && selectedCompany) {
      data = {
        ...data,
        requested_company_name: selectedCompany.name,
        requested_company_domain: selectedCompany.domain,
        dest_company: { type: "companies", id: selectedCompany.id },
      };
    }
    const result = await dispatch(
      create({
        type: "partnerships",
        attributes: data,
        options: { include: included, fields },
      })
    );
    if (isFulfilled(result)) {
      const response = result.payload as JSONAPIResponse;
      dispatch(addCreatedPartner({ id: +response.data.id }));
    }
    return result;
  };

  const inviteAdmins = async (values: IInviteForm) =>
    dispatch(
      rawPost({
        type: "partnerships",
        path: `${partnershipId}/invite-admins/`,
        payload: {
          data: {
            type: "partnerships",
            attributes: { message: values.message },
          },
        },
      })
    );

  const createSuggestedPartnership = async (values: IInviteForm) => {
    const suggestionItem = suggestions.find(
      (suggestion) => suggestion.partnerDomainName === values.company.domain
    );
    const suggestedPartnerId = !!suggestedPartnerIdString
      ? Number(suggestedPartnerIdString)
      : suggestionItem?.id ?? -1;
    const destId =
      selectedCompany?.id ?? suggestionItem?.attributes?.partner_id ?? null;
    const requestedCompanyName = suggestionItem?.attributes.partner_name;
    const requestedCompanyDomain =
      suggestionItem?.attributes.partner_domain_name;
    const data = {
      type: "partnerships",
      attributes: {
        initiator_company_id: profile.companyId,
        initiator_user_id: profile.id,
        requested_company_name: requestedCompanyName,
        requested_company_domain: requestedCompanyDomain,
        invites: [
          {
            type: "partnership_invites",
            attributes: values.email
              ? {
                  email: values.email,
                  first_name: values.first_name,
                  last_name: values.last_name,
                  message: values.message,
                  inviter_email_in_cc: inviterEmailInCc,
                }
              : {
                  email: null,
                  first_name: null,
                  last_name: null,
                  message: values.message,
                },
          },
        ],
        source:
          suggestionItem?.source === SuggestedPartnerSource.Crm
            ? FROM_URL_TO_PARTNERSHIP_SOURCE[SentFromURL.SuggestedPartner]
            : FROM_URL_TO_PARTNERSHIP_SOURCE[sentFrom],
        ...(destId ? { dest_company: { type: "companies", id: destId } } : {}),
      },
    };

    const result = await dispatch(
      rawPost({
        type: "suggested_partners",
        id: suggestedPartnerId,
        path: "/create-partnership/",
        payload: { data },
        options: {
          include: included,
          fields,
        },
      })
    );

    if (isFulfilled(result)) {
      if (suggestionItem?.source === SuggestedPartnerSource.Crm) {
        track(SuggestionFromCrmEvent.invited, {
          from: "invite-page",
          requestedCompanyName: requestedCompanyName,
          requestedCompanyDomain: requestedCompanyDomain,
          source: SuggestedPartnerSource.Crm,
          ccCheckboxChecked: inviterEmailInCc,
        });
      } else {
        const response = result.payload as JSONAPIResponse;
        dispatch(
          transformSuggestion({
            partnershipId: +response.data.id,
            suggestionId: selectedCompany?.id ?? -1,
          })
        );
        track(InviteFormEvent.invited, {
          from: "invite-page",
          requestedCompanyName: requestedCompanyName,
          requestedCompanyDomain: requestedCompanyDomain,
          ccCheckboxChecked: inviterEmailInCc,
          ...(suggestedPartnerId ? { source: suggestionItem?.source } : {}),
        });
      }
      dispatch(initializeSuggestedPartners({ partnerOnReveal: true }));
    }
    return result;
  };

  const createInvite = async (values: $TSFixMe) =>
    dispatch(
      create({
        type: "partnership_invites",
        attributes: _(values)
          .pick(["email", "first_name", "last_name", "message"])
          .set("inviter_email_in_cc", inviterEmailInCc)
          .value(),
        relationships: {
          partnership: {
            type: "partnerships",
            id: String(partnershipId),
          },
        },
      })
    );

  const handleSubmit = async (
    valuesUntrimmed: IInviteForm,
    actions: FormikHelpers<any>
  ) => {
    actions.setSubmitting(true);
    const values = getTrimmedData(valuesUntrimmed);
    try {
      const response = await (partnershipId // already invited
        ? dialogView === DialogView.PartnershipExtraInviteDirect
          ? createInvite(values)
          : inviteAdmins(values)
        : selectedCompany
        ? suggestedPartnerIdString
          ? createSuggestedPartnership(values) // suggested partner
          : createPartnership(values, isPartnerOnReveal) // directory
        : values.company?.id &&
          crmSuggestions.some(
            (suggestion) => suggestion.id === values.company.id
          )
        ? createSuggestedPartnership(values) // suggested partner from CRM
        : createPartnership(values));
      if (isRejected(response)) {
        if (typeof response.payload === "string") {
          history.push(response.payload);
        }
      } else {
        pushNotification({ ...i18n.invitationSent });
        actions.resetForm();
        const requestedDomainName = partnership
          ? partnership.requestedCompanyDomain
          : selectedCompany
          ? selectedCompany.domain
          : values.company.domain;
        const receiver =
          dialogView === DialogView.SuggestedPartnerAllAdmins ||
          dialogView === DialogView.PartnershipExtraInviteAllAdmins
            ? "Admins"
            : "Contact";
        track("Invited partner", {
          from: sentFrom,
          destinator: receiver,
          requestedDomainName: requestedDomainName,
          partnershipId: partnershipId ? partnershipId : null,
          ...(renderEmailCcOption && { ccCheckboxChecked: inviterEmailInCc }),
          ...(!isPartnerOnReveal && { isExternal: true }),
          ...(directoryTab && { directoryTab }),
        });
        profile.identify({
          partnershipInvitesCount:
            Number(_.get(profile, "company.createdPartnership") ?? 0) + 1,
          pendingPartnershipCount:
            Number(_.get(profile, "company.pendingPartnership") ?? 0) + 1,
        });
        dispatch(fetchProfile());
        onInviteSuccess();
        dispatch(initializePartnershipsAndCompanyNames());
      }
    } catch (error) {
      pushNotification("default_error");
    }
    actions.setSubmitting(false);
  };

  const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setInviterEmailInCc(event.target.checked);
    track(InviteFormEvent.clickedCcCheckbox, { checked: event.target.checked });
  };

  const openPremiumWidget = useCallback(() => {
    dispatch(toggleFree(false));
    dispatch(togglePro(true));
    dispatch(togglePower(false));
    dispatch(openWidget(true));
    track(PremiumPlanEvent.explorePlan, {
      from: "create offline partner tooltip",
    });
  }, [dispatch, track]);

  const validationSchema = (() => {
    switch (dialogView) {
      case DialogView.GenericFirstInvite:
        return partnershipSchema(profile.email, profile?.company?.name ?? "");
      case DialogView.PartnershipExtraInviteDirect:
      case DialogView.SuggestedPartnerDirect:
        return inviteSchema(profile.email);
      case DialogView.SuggestedPartnerAllAdmins:
      case DialogView.PartnershipExtraInviteAllAdmins:
        return inviteAdminsSchema;
    }
  })();

  const ghostPartnershipSection = (isSubmitting: boolean) =>
    profile.canManagePartnerships &&
    !companyFromDirectoryIdString &&
    !isSuggestedPartnerFromDirectory ? (
      <Alert icon variant="warning" forceBorder className={classes.alert}>
        <div className={classes.alertContainer}>
          <T>
            <FormattedMessage {...i18n.ghostInvite} />
          </T>
          <PayingFeatureTooltip
            active={offlineAccountMappingLimitReached}
            message={i18n.limitReached}
            onClick={openPremiumWidget}
            placement="top"
          >
            <div>
              <Button
                classes={{ btn: classes.ghostButton }}
                size="small"
                label={i18n.create}
                bold
                LeftIcon={Plus}
                onClick={goToInvitePrivate}
                disabled={isSubmitting || offlineAccountMappingLimitReached}
                variant="secondary"
                data-testid="button-invite-private"
              />
            </div>
          </PayingFeatureTooltip>
        </div>
      </Alert>
    ) : (
      <></>
    );

  const toggleBtnGroupItems = useMemo(() => {
    return [
      {
        label: (
          <FormattedMessage
            {...i18n.inviteContact}
            values={{
              partnerName:
                selectedCompany?.name || partnership?.requestedCompanyName,
            }}
          />
        ),
        value: partnershipId
          ? DialogView.PartnershipExtraInviteDirect
          : DialogView.SuggestedPartnerDirect,
      },
      {
        label: (
          <FormattedMessage
            {...i18n.sendToAdmins}
            values={{
              partnerName:
                selectedCompany?.name || partnership?.requestedCompanyName,
            }}
          />
        ),
        value: partnershipId
          ? DialogView.PartnershipExtraInviteAllAdmins
          : DialogView.SuggestedPartnerAllAdmins,
      },
    ];
  }, [selectedCompany?.name, partnership, partnershipId]);

  if (loading) {
    return (
      <div className={classes.loaderContainer}>
        <CircularProgress />
      </div>
    );
  }

  const renderEmailCcOption = [
    DialogView.GenericFirstInvite,
    DialogView.PartnershipExtraInviteAllAdmins,
    DialogView.PartnershipExtraInviteDirect,
    DialogView.SuggestedPartnerDirect,
  ].includes(dialogView);

  return (
    <Formik
      initialValues={{
        ...initialValues,
        company: selectedCompany ?? {
          id: null,
          name: initial,
          logo: null,
          domain: null,
        },
      }}
      onSubmit={handleSubmit}
      validationSchema={validationSchema}
    >
      {({
        values,
        handleChange,
        isSubmitting,
        touched,
        errors,
        setFieldError,
        setFieldValue,
      }) => {
        const isSelectedCrmSuggestionCompany = crmSuggestions.some(
          (suggestion) => suggestion.id === values.company.id
        );

        return (
          <Form autoComplete="off" className={classes.form}>
            {hasSelectedCompany && (
              <div className={classes.logo}>
                <CompanyAvatar
                  companyName={selectedCompany?.name}
                  src={selectedCompany?.logo}
                />
                <T h2 bold>
                  <FormattedMessage
                    {...i18n.inviteTitle}
                    values={{ partnerName: selectedCompany?.name }}
                  />
                </T>
              </div>
            )}
            {(companyFromDirectoryIdString ||
              isSuggestedPartnerFromDirectory) && (
              <Box className={classes.inviteClarification}>
                <InviteClarification
                  unfolded
                  selectedCompany={selectedCompany}
                />
              </Box>
            )}
            {dialogView !== DialogView.GenericFirstInvite &&
              !profile.isSales() &&
              isPartnerOnReveal && (
                <ToggleBtnGroup
                  fullWidth
                  withBorder
                  items={toggleBtnGroupItems}
                  setValue={(view: DialogView) => {
                    // Reset potential error on message field, because it's not required on specific contact invite.
                    setFieldError("message", undefined);
                    setDialogView(view);
                  }}
                  value={dialogView}
                  classes={{ root: classes.toggleBtn }}
                />
              )}
            {dialogView === DialogView.GenericFirstInvite && (
              <>
                <InviteFormCompanyAutocomplete
                  {...{
                    classes,
                    errors,
                    handleChange,
                    isSubmitting,
                    selectedCompany,
                    setSelectedUser,
                    crmSuggestions,
                    touched,
                    values,
                  }}
                />
                {isSelectedCrmSuggestionCompany && (
                  <InviteFormContactSelect
                    {...{
                      classes,
                      selectedUser,
                      setFieldValue,
                      setSelectedUser,
                      suggestions: crmSuggestions,
                      values,
                    }}
                  />
                )}
              </>
            )}
            {(currentStep !== FormStep.CompanyFromCrm ||
              (currentStep === FormStep.CompanyFromCrm &&
                ("isNewContact" in (selectedUser ?? {}) ||
                  (values.company?.id && !isSelectedCrmSuggestionCompany)))) &&
              dialogView !== DialogView.SuggestedPartnerAllAdmins &&
              dialogView !== DialogView.PartnershipExtraInviteAllAdmins && (
                <InviteFormGenericFields
                  {...{
                    classes,
                    dialogView,
                    errors,
                    handleChange,
                    isSubmitting,
                    touched,
                    values,
                  }}
                />
              )}
            {(currentStep !== FormStep.CompanyFromCrm ||
              selectedUser ||
              (values.company?.id && !isSelectedCrmSuggestionCompany)) && (
              <>
                <InviteFormMessageField
                  {...{
                    classes,
                    dialogView,
                    errors,
                    handleChange,
                    isSubmitting,
                    touched,
                    values,
                  }}
                />
                {renderEmailCcOption && (
                  <Checkbox
                    classes={checkboxClasses}
                    checked={inviterEmailInCc}
                    onChange={handleCheckboxChange}
                    label={i18n.includeInCc}
                    data-testid={"include-in-cc"}
                  />
                )}
                <InviteFormDialogActions
                  {...{
                    classes,
                    dialogView,
                    handleClose,
                    isSubmitting,
                    selectedCompany,
                  }}
                />
              </>
            )}
            {showStatusRulesForCrmTip && (
              <InviteFormStatusRulesForCrmTip {...{ classes }} />
            )}
            {ghostPartnershipSection(isSubmitting)}
          </Form>
        );
      }}
    </Formik>
  );
};

export default InviteForm;

// CSS

const useCheckboxStyles = makeStyles()((theme) => ({
  root: {
    marginLeft: 8,
    padding: 8,
    "&:hover": {
      color: theme.palette.primary.main,
    },
  },
  checked: {
    "&&": {
      color: theme.palette.primary.main,
    },
  },
}));

const useStyles = makeStyles<{
  fullWidth: boolean;
  hasSelectedCompany: boolean;
  isFirstStep: boolean;
}>()((theme, { fullWidth, hasSelectedCompany, isFirstStep }) => ({
  ghostButton: {
    width: "max-content",
  },
  loaderContainer: {
    display: "flex",
    justifyContent: "center",
  },
  form: fullWidth
    ? {
        width: "100%",
      }
    : {
        marginTop: 24,
        borderRadius: 8,
        padding: 24,
        backgroundColor: theme.palette.ivory,
        border: `0.5px solid ${theme.palette.ivory}`,
      },
  lastField: {
    marginBottom: theme.spacing(4),
  },
  input: {
    fontSize: 12,
    margin: theme.spacing(1),
  },
  inviteClarification: {
    border: `1px solid ${theme.palette.taupe500}`,
    borderRadius: 8,
    marginBottom: theme.spacing(3),
    padding: theme.spacing(3),
  },
  companyForm: {
    display: hasSelectedCompany ? "none" : "flex",
    padding: 8,
  },
  divider: {
    marginBottom: theme.spacing(2),
  },
  fullDivider: {
    marginBottom: theme.spacing(1),
    marginTop: theme.spacing(1),
  },
  inputAdornment: {
    position: "absolute",
    right: 16,
  },
  closeIcon: {
    fontSize: 12,
  },
  contactSelect: {
    "& .MuiSelect-icon": {
      display: "none",
    },
    paddingRight: 0,
  },
  labelBox: {
    display: "flex",
    alignItems: "center",
    width: "100%",
  },
  contactTitle: {
    marginLeft: "auto",
    marginRight: 8,
  },
  logo: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    gap: theme.spacing(2),
    margin: 16,
    "& > img": {
      height: 36,
      width: 36,
      borderRadius: 6,
    },
  },
  toggleBtn: {
    margin: theme.spacing(1),
  },
  messageControl: {
    margin: theme.spacing(2, 0),
  },
  actionContainer: {
    display: "flex",
    justifyContent: fullWidth ? "space-between" : "flex-end",
    marginBottom: theme.spacing(2),
  },
  alert: {
    marginTop: isFirstStep ? 25 : 90,
    marginBottom: fullWidth ? 20 : 0,
  },
  alertContainer: {
    display: "flex",
    justifyContent: "space-between",
    width: "100%",
    alignItems: "center",
  },
  tooltipLink: {
    cursor: "pointer",
    textDecoration: "underline",
  },
  tip: {
    textAlign: "center",
  },
  suggestionItem: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    gap: 8,
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  suggestionName: {
    fontSize: 12,
  },
  suggestionNameMenuItem: {
    fontSize: 12,
    marginLeft: theme.spacing(1),
  },
  link: {
    color: theme.palette.midnight,
    textDecoration: "underline",
    fontWeight: "bold",
  },
}));

// I18N

const i18n = defineMessages({
  create: {
    id: "partners.invite.create",
    defaultMessage: "Add data source",
  },
  includeInCc: {
    id: "partners.invite.includeInCc",
    defaultMessage: "Include me in the CC of the invitation email",
  },
  invitationSent: {
    id: "partners.invite.invitation_sent",
    defaultMessage: "Your invitation has been sent.",
  },
  inviteContact: {
    id: "partners.invite.inviteContact",
    defaultMessage: "Invite a contact from {partnerName}",
  },
  inviteTitle: {
    id: "partners.invite.inviteTitle",
    defaultMessage: "Invite {partnerName}",
  },
  ghostInvite: {
    id: "partners.invite.ghostInvite",
    defaultMessage:
      "If your partner is not on Reveal, you can still do an Offline Account Mapping by uploading a CSV or connecting a Google Sheet that you received from them.",
  },
  limitReached: {
    id: "partners.invite.limitReached",
    defaultMessage: "Upgrade to create more than 3 offline account mappings.",
  },
  sendToAdmins: {
    id: "partners.invite.sendToAdmins",
    defaultMessage: "Send invite to {partnerName}'s admins",
  },
  suggestionsFromCrm: {
    id: "partners.invite.suggestionsFromCrm",
    defaultMessage: "Suggestions from your CRM",
  },
});
