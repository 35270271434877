import DialogActions from "@mui/material/DialogActions";
import Grid from "@mui/material/Grid";
import withAPI, { WithAPI } from "components/hoc/withAPI";
import Button from "components/ui/Button";
import { T } from "components/ui/Typography";
import { ConfigContext } from "config/ConfigProvider";
import generic from "i18n/generic";
import { makeStyles } from "makeStyles";
import { useContext, useState } from "react";
import { defineMessages, FormattedMessage } from "react-intl";
import { AssignmentWidget } from "screens/Frontoffice/shared/components/SourceSettingsDialog/components/AssignmentWidget";

type Props = WithAPI & {
  record: $TSFixMe;
  integration: $TSFixMe;
  onClose: $TSFixMeFunction;
};

const ToggleDefaultConfirm = ({
  record,
  onClose,
  integration,
  updateRecord,
}: Props) => {
  const { classes } = useStyles();
  const { classes: actionBtnClasses } = useActionBtnStyles();
  const [loading, setLoading] = useState(false);
  const config = useContext(ConfigContext);
  let details = {};
  if (record.type === "crm_credentials") {
    // @ts-expect-error ts-migrate(2339) FIXME: Property 'crmProviders' does not exist on type '{}... Remove this comment to see the full error message
    const detailsFromConfig = config.crmProviders[record.provider];
    details = {
      logo: detailsFromConfig.logo,
      title: detailsFromConfig.name,
      subTitle: record.config.email,
    };
  } else if (record.type === "sources") {
    details = {
      logo: "/images/providers/CSV.png",
      title: record.name,
      subTitle: record.sourceFileName,
    };
  }

  const handleConfirm = async () => {
    setLoading(true);
    await updateRecord(
      "integrations",
      integration.id,
      {
        default_source: !integration.defaultSource,
      },
      {}
    );
    onClose(true);
    setLoading(false);
  };

  return (
    <>
      <T className={classes.subTitle}>
        <FormattedMessage
          {...(integration.defaultSource
            ? i18n.removeDefaultSubtitle
            : i18n.makeDefaultSubtitle)}
        />
      </T>
      {integration.defaultSource ? (
        <>
          <Grid container className={classes.details}>
            <Grid item className={classes.logoContainer}>
              {/* @ts-expect-error ts-migrate(2339) FIXME: Property 'logo' does not exist on type '{}'. */}
              <img src={details.logo} alt="logo" className={classes.logo} />
            </Grid>
            <Grid
              className={classes.detailsContainer}
              container
              justifyContent="flex-start"
              alignItems="flex-start"
              direction="column"
            >
              {/* @ts-expect-error ts-migrate(2339) FIXME: Property 'title' does not exist on type '{}'. */}
              <T>{details.title}</T>
              <T oldVariant="subtitle1">
                {/* @ts-expect-error ts-migrate(2339) FIXME: Property 'subTitle' does not exist on type '{}'. */}
                {record.type === "crm_credentials" && details.subTitle && (
                  <FormattedMessage
                    {...i18n.connectedAs}
                    // @ts-expect-error ts-migrate(2339) FIXME: Property 'subTitle' does not exist on type '{}'.
                    values={{ email: details.subTitle }}
                  />
                )}
                {/* @ts-expect-error ts-migrate(2339) FIXME: Property 'subTitle' does not exist on type '{}'. */}
                {record.type === "sources" && details.subTitle}
              </T>
            </Grid>
          </Grid>
          <DialogActions className={classes.dialogActions}>
            <Button
              label={generic.cancel}
              variant="secondary"
              onClick={onClose}
              classes={actionBtnClasses}
            />
            <Button
              label={generic.confirm}
              onClick={handleConfirm}
              classes={actionBtnClasses}
              loading={loading}
            />
          </DialogActions>
        </>
      ) : (
        <AssignmentWidget
          integrationId={integration.id}
          handleClose={onClose}
          defaultAll
          presave={handleConfirm}
          isFromMakeDefault
        />
      )}
    </>
  );
};

export default withAPI(ToggleDefaultConfirm);

const useStyles = makeStyles()((theme) => ({
  subTitle: {
    marginTop: theme.spacing(1),
  },
  details: {
    margin: theme.spacing(4, 0, 3, 0),
    // @ts-expect-error ts-migrate(2339) FIXME: Property 'light' does not exist on type 'Color'.
    border: `1px solid ${theme.palette.grey.light}`,
    boxShadow: `
        inset 0 0 0 1px ${
          // @ts-expect-error ts-migrate(2339) FIXME: Property 'main' does not exist on type 'Color'.
          theme.palette.grey.main
        },
        0 1px 8px 0 ${
          // @ts-expect-error ts-migrate(2339) FIXME: Property 'main' does not exist on type 'Color'.
          theme.palette.grey.main
        }
      `,
    borderRadius: 5,
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
    flexDirection: "row",
  },
  detailsContainer: {
    flexGrow: 1,
    width: "auto !important",
  },
  logoContainer: {
    margin: theme.spacing(2, 4, 2, 2),
  },
  logo: {
    maxWidth: 128,
    maxHeight: 128,
  },
  dialogActions: {
    padding: 0,
    paddingBottom: theme.spacing(2),
  },
}));

const useActionBtnStyles = makeStyles()((theme) => ({
  btn: {
    textTransform: "uppercase",
  },
}));

const i18n = defineMessages({
  connectedAs: {
    id: "Settings.DefaultSource.ToggleDefaultConfirm.connectedAs",
    defaultMessage: "Connected as {email}",
  },
  makeDefaultSubtitle: {
    id: "Settings.DefaultSource.ToggleDefaultConfirm.makeDefaultSubtitle",
    defaultMessage:
      "You're adding this data source to the following partnership and all new partnerships moving forward.",
  },
  removeDefaultSubtitle: {
    id: "Settings.DefaultSource.ToggleDefaultConfirm.removeDefaultSubtitle",
    defaultMessage:
      "It will no longer be used by default for new partnerships connections. However you will still be able to manually assign it to partnerships.",
  },
});
