import { CloseRounded } from "@mui/icons-material";
import { Box, Divider } from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import { Search } from "components/icons";
import Button from "components/ui/Button";
import Popup from "components/ui/Popup";
import { TextInput } from "components/ui/TextInput";
import { T } from "components/ui/Typography";
import { makeStyles } from "makeStyles";
import DiscussionParticipant from "models/DiscussionParticipant";
import { defineMessages, FormattedMessage, useIntl } from "react-intl";

import useParticipantDialogApiLogic from "../hooks/useParticipantDialogApiLogic";
import { Participant } from "./Participant";

type Props = {
  isOpen: boolean;
  partnerConnectionId: number;
  participants: DiscussionParticipant[];
  onClose: () => void;
  reloadRecord: () => void;
};

export const ParticipantDialog = ({
  isOpen,
  partnerConnectionId,
  participants,
  onClose,
  reloadRecord,
}: Props) => {
  const intl = useIntl();
  const { classes } = useStyles();

  const {
    loading,
    searchQuery,
    searchOptions,
    searchParticipant,
    removeParticipant,
    availableUserOptions,
  } = useParticipantDialogApiLogic({
    partnerConnectionId,
    reloadRecord,
  });

  const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    searchParticipant(event);
  };

  return (
    <Popup
      classes={{
        buttonsContainer: classes.buttonsContainer,
        titleContainer: classes.titleContainer,
      }}
      variant="secondary"
      title={
        <>
          <T h3 bold className={classes.title}>
            <FormattedMessage {...i18n.title} />
          </T>
          <T className={classes.alpha}>
            <FormattedMessage {...i18n.subtitle} />
          </T>
          <Box position="absolute" right={14} top={14}>
            <Button
              size="xSmall"
              variant="tertiary"
              onClick={onClose}
              LeftIcon={CloseRounded}
            />
          </Box>
          <Divider className={classes.dividerHeader} />
        </>
      }
      customActions={
        <>
          <Divider className={classes.dividerBottom} />
          <TextInput
            fullWidth
            variant="newDesign"
            value={searchQuery}
            LeftIcon={Search}
            placeholder={intl.formatMessage(i18n.placeholder)}
            onChange={handleSearch}
          />
        </>
      }
      handleClose={onClose}
      width={520}
      maxWidth={"lg"}
      isOpen={isOpen}
      hideActions
    >
      {loading ? (
        <div className={classes.loaderContainer}>
          <CircularProgress />
        </div>
      ) : (
        <div className={classes.container}>
          <div className={classes.participantContainer}>
            <T titleBig>
              <FormattedMessage {...i18n.participants} />
            </T>
            {participants.map((user) => (
              <Participant
                key={user.userId}
                name={user.fullName ?? ""}
                avatar={user.avatarUrl}
                companyAvatar={user.companyAvatarUrl}
                companyName={user.companyName ?? ""}
                role={
                  user.role ??
                  availableUserOptions.find(
                    (option) => option.id === String(user.rawUserId)
                  )?.role
                }
                onDelete={() => removeParticipant(String(user.id))}
                action="delete"
              />
            ))}
            {participants.length === 0 && (
              <T className={classes.alpha}>
                <FormattedMessage {...i18n.noParticipants} />
              </T>
            )}
          </div>
          <Divider className={classes.divider} />
          <div className={classes.participantContainer}>
            <T titleBig>
              <FormattedMessage {...i18n.people} />
            </T>
            {searchOptions.map((option) => option.label)}
            {searchOptions.length === 0 && (
              <Box marginBottom="8px">
                <T className={classes.alpha}>
                  <FormattedMessage {...i18n.noParticipants} />
                </T>
              </Box>
            )}
          </div>
        </div>
      )}
    </Popup>
  );
};

/// CSS

const useStyles = makeStyles()((theme) => ({
  alpha: {
    color: theme.palette.alpha500,
  },
  buttonsContainer: {
    display: "flex",
    flexDirection: "column",
    marginTop: 0,
  },
  dividerHeader: {
    margin: "20px -24px 4px -24px",
    borderBottom: `1px solid ${theme.palette.alpha200}`,
  },
  divider: {
    margin: "20px 0",
    borderBottom: `1px solid ${theme.palette.alpha200}`,
  },
  dividerBottom: {
    margin: "0 -24px 20px -24px",
    borderBottom: `1px solid ${theme.palette.alpha200}`,
  },
  title: {
    marginBottom: 4,
  },
  titleContainer: {
    marginBottom: 0,
  },
  loaderContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    height: 300,
  },
  container: {
    display: "flex",
    flexDirection: "column",
    paddingTop: 16,
    paddingBottom: 4,
  },
  participantContainer: {
    display: "flex",
    flexDirection: "column",
    rowGap: 12,
  },
}));

/// I18N

const i18n = defineMessages({
  title: {
    id: "Datables.Pipeline.MessageDrawer.ParticipantDialog.title",
    defaultMessage: "Participants",
  },
  subtitle: {
    id: "Datables.Pipeline.MessageDrawer.ParticipantDialog.subtitle",
    defaultMessage:
      "Add the right stakeholders. New participants can access the Discussion's history.",
  },
  noParticipants: {
    id: "Datables.Pipeline.MessageDrawer.ParticipantDialog.noParticipants",
    defaultMessage: "No participants found",
  },
  participants: {
    id: "Datables.Pipeline.MessageDrawer.ParticipantDialog.participants",
    defaultMessage: "Participants",
  },
  people: {
    id: "Datables.Pipeline.MessageDrawer.ParticipantDialog.people",
    defaultMessage: "People you may want to add",
  },
  placeholder: {
    id: "Datables.Pipeline.MessageDrawer.ParticipantDialog.placeholder",
    defaultMessage: "Find other participants to add",
  },
});
