import { AccountMappingResource } from "redux/accountMapping/types";

import {
  ColumnConfigType,
  FieldType,
  FilterType,
  SortType,
} from "../../../shared/types";

export enum SinceOption {
  LastVisit = "lastVisit",
  Last7Days = "last7Days",
  Last15Days = "last15Days",
  Last30Days = "last30Days",
  Last3Months = "last3Months",
  Last6Months = "last6Months",
}

export type AccountMappingDisplaySinceState = {
  isChecked: boolean;
  since: SinceOption;
};

export type AccountMappingViewContext = {
  partnershipId: number | null;
  accountType: AccountMappingResource;
  setAccountType: (value: AccountMappingResource) => void;
  lastVisitedAt: Date | null;
  partnerName: string | null;
  partnerAvatarUrl: string | null;
};

export enum OverlapViewName {
  AllOverlaps = "allOverlaps",
  CommonCustomers = "commonCustomers",
  CommonOpportunities = "commonOpportunities",
  ProspectMatchingCustomers = "prospectMatchingCustomers",
  CustomerMatchingProspects = "customerMatchingProspects",
  OpenOpportunitiesToCustomers = "openOpportunitiesToCustomers",
}

export enum DisplaySinceFilterFieldname {
  CreatedAt = "created_at",
  StatusChanged = "status_changed",
  LastCreatedOpportunityChanged = "last_created_opportunity_changed",
  MyOpportunityOrPartnerStatusChanged = "my_opportunity_or_partner_status_changed",
}

export enum AccountMappingStandardFieldName {
  leftActions = "leftActions",
  leftActive = "leftActive",
  leftHasOpenDeal = "leftHasOpenDeal",
  leftContactCount = "leftContactCount",
  leftCountryCode = "leftCountryCode",
  leftCountryIsoCode = "leftCountryIsoCode",
  leftDomain = "leftDomain",
  leftName = "leftName",
  leftOwnerName = "leftOwnerName",
  leftOwnerFullName = "leftOwnerFullName",
  leftStarred = "leftStarred",
  leftIsStarred = "leftIsStarred",
  leftStatus = "leftStatus",
  rightNewProspectActions = "rightNewProspectActions",
  rightActive = "rightActive",
  rightHasOpenDeal = "rightHasOpenDeal",
  rightContactCount = "rightContactCount",
  rightContactOverlap = "rightContactOverlap",
  rightContactOverlapCount = "rightContactOverlapCount",
  rightCountryCode = "rightCountryCode",
  rightCountryIsoCode = "rightCountryIsoCode",
  rightDomain = "rightDomain",
  rightExtraContacts = "rightExtraContacts",
  rightExtraContactCount = "rightExtraContactCount",
  rightKeyContactOverlap = "rightKeyContactOverlap",
  rightDealInfluencerOverlapCount = "rightDealInfluencerOverlapCount",
  rightExtraKeyContacts = "rightExtraKeyContacts",
  rightExtraDealInfluencerCount = "rightExtraDealInfluencerCount",
  rightName = "rightName",
  rightOwnerName = "rightOwnerName",
  rightOwnerFullName = "rightOwnerFullName",
  rightStatus = "rightStatus",
}

const oldRequiredFields = [
  AccountMappingStandardFieldName.leftActive,
  AccountMappingStandardFieldName.leftContactCount,
  AccountMappingStandardFieldName.leftCountryCode,
  AccountMappingStandardFieldName.leftName,
  AccountMappingStandardFieldName.leftOwnerName,
  AccountMappingStandardFieldName.leftStatus,
  AccountMappingStandardFieldName.rightActive,
  AccountMappingStandardFieldName.rightContactCount,
  AccountMappingStandardFieldName.rightCountryCode,
  AccountMappingStandardFieldName.rightName,
  AccountMappingStandardFieldName.rightOwnerName,
  AccountMappingStandardFieldName.rightStatus,
];
const requiredFields = [
  AccountMappingStandardFieldName.leftHasOpenDeal,
  AccountMappingStandardFieldName.leftContactCount,
  AccountMappingStandardFieldName.leftCountryIsoCode,
  AccountMappingStandardFieldName.leftName,
  AccountMappingStandardFieldName.leftOwnerFullName,
  AccountMappingStandardFieldName.leftStatus,
  AccountMappingStandardFieldName.rightHasOpenDeal,
  AccountMappingStandardFieldName.rightContactCount,
  AccountMappingStandardFieldName.rightCountryIsoCode,
  AccountMappingStandardFieldName.rightName,
  AccountMappingStandardFieldName.rightOwnerFullName,
  AccountMappingStandardFieldName.rightStatus,
];

const requiredFieldsOverlapLeads = [
  AccountMappingStandardFieldName.leftName,
  AccountMappingStandardFieldName.leftStatus,
  AccountMappingStandardFieldName.leftOwnerName,
  AccountMappingStandardFieldName.rightActive,
  AccountMappingStandardFieldName.rightCountryCode,
  AccountMappingStandardFieldName.rightName,
  AccountMappingStandardFieldName.rightOwnerName,
  AccountMappingStandardFieldName.rightStatus,
];

export type AccountMappingStandardFields = Record<
  AccountMappingStandardFieldName,
  FieldType
>;

export const isAccountMappingStandardFields = (
  value: Record<string, FieldType>,
  accountType: AccountMappingResource,
  isMatchedAccount: boolean
): value is AccountMappingStandardFields => {
  let _requiredFields =
    accountType === AccountMappingResource.leadMatches
      ? requiredFieldsOverlapLeads
      : isMatchedAccount &&
        accountType === AccountMappingResource.matched_accounts
      ? requiredFields
      : oldRequiredFields;
  return !_requiredFields.some((fieldname) => !(fieldname in value));
};

export interface AccountMappingViewParameters {
  accountType?: AccountMappingResource;
  filters?: FilterType[];
  columns?: ColumnConfigType[];
  sort?: SortType[];
  lastVisitedAt?: Date;
}
