import { SWQLTarget } from "components/ui/SWQL/SWQLTypes";
import Match from "models/Match";
import {
  defineMessages,
  FormattedHTMLMessage,
  FormattedMessage,
} from "react-intl";
import { useHistory, useLocation } from "react-router-dom";

import { updateHistoryUpdatedSource } from "../helpers/utils";
import BaseMappingRulesWidget from "./BaseMappingRulesWidget";

const i18n = defineMessages({
  summary: {
    id: "crm.Settings.sourceSettings.privateAccounts.summary",
    defaultMessage:
      "Private Accounts are accounts that won’t be shared with any partners in your Account Mappings.",
  },
  privateAccountsAre: {
    id: "crm.settings.sourceSettings.privateAccounts.privateAccountsAre",
    defaultMessage: "<b>Private Accounts</b> are accounts where",
  },
});

type Props = {
  integrationId: number;
  handleClose?: () => void;
  callback?: () => void;
};

const PrivateAccountsWidget = ({ ...props }: Props) => {
  const history = useHistory();
  const location = useLocation();

  const ruleList = [
    {
      key: Match.IS_PRIVATE,
      label: <FormattedHTMLMessage {...i18n.privateAccountsAre} />,
    },
  ];

  const handleSave = () => {
    updateHistoryUpdatedSource(history, location);
    props?.callback?.();
  };

  return (
    <BaseMappingRulesWidget
      ruleList={ruleList}
      attribute={Match.PRIVATE_ATTRIBUTE}
      swqlTarget={SWQLTarget.RawCompany}
      summary={<FormattedMessage {...i18n.summary} />}
      trackedLocation="data source private accounts"
      saveCallback={handleSave}
      {...props}
    />
  );
};

export default PrivateAccountsWidget;
