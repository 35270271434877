import Undo from "@mui/icons-material/Undo";
import Grid from "@mui/material/Grid";
import BookmarkCheckedOutlined from "components/icons/BookmarkCheckedOutlined";
import Button from "components/ui/Button";
import * as Page from "config/routes/Pages";
import useUpdateAttributesForAllRecords from "hooks/useUpdateAttributesForAllRecords";
import _ from "lodash";
import PartnerConnection from "models/PartnerConnection";
import Record from "models/Record";
import { useEffect, useState } from "react";
import { defineMessages } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import { bulkRemoveRecords, updateAttributesOnRecord } from "redux/api/actions";
import { rawDelete, rawPatch } from "redux/api/thunks";
import { markNotificationAsRead } from "redux/notifications/actions";
import { NotificationItem } from "redux/notifications/typing";
import { selectSelectedPartnershipId } from "redux/notificationWidget/selectors";
import { setPartnerFilter } from "redux/pipeline/thunks";

type Props = {
  notification: NotificationItem;
  partnerConnections: PartnerConnection[];
  rawCompanies?: Record[];
  goToPipelineAvailable?: boolean;
};

const NotificationActionAddedToPipeline = ({
  notification,
  partnerConnections,
  rawCompanies,
  goToPipelineAvailable = true,
}: Props) => {
  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();
  const updateAllMatches = useUpdateAttributesForAllRecords("matches");
  const updateAllAMNewProspects = useUpdateAttributesForAllRecords(
    "shared_accounts"
  );
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState("");
  const selectedPartnershipId = useSelector(selectSelectedPartnershipId);

  const partnerConnectionPayload = {
    data: {
      data: partnerConnections.map((item) => ({
        id: item.id.toString(),
        type: "partner_connections",
      })),
    },
  };

  const deletePartnerConnections = async () => {
    await dispatch(
      rawDelete({
        type: "partner_connections",
        prefixPath: "bulk",
        suffixPath: "",
        options: partnerConnectionPayload,
      })
    );
  };

  const cleanPartnerConnections = async () => {
    // Clean deleted records from store
    await dispatch(bulkRemoveRecords(partnerConnectionPayload.data.data));
  };

  const undoFromAccountMapping = async () => {
    await deletePartnerConnections();
    await cleanPartnerConnections();
    partnerConnections.forEach((partnerConnection) => {
      const isPartnerConnectionNewProspect =
        typeof partnerConnection.rawCompanyId !== "number" &&
        !partnerConnection.rawCompanyId;
      if (isPartnerConnectionNewProspect) {
        updateAllAMNewProspects(
          { hasPartnerConnection: false },
          {
            partnershipId: partnerConnection.partnershipId,
            rightCountryCode: partnerConnection.newProspectCountry,
            rightDomain: partnerConnection.newProspectDomain,
          }
        );
      } else {
        updateAllMatches(
          { hasPartnerConnection: false },
          {
            partnershipId: partnerConnection.partnershipId,
            rawCompanyId: partnerConnection.rawCompanyId,
          }
        );
      }
    });
  };

  const undoFromMyAccounts = async () => {
    await deletePartnerConnections();
    await cleanPartnerConnections();
    _.each(rawCompanies, (rawCompany: Record) => {
      if (rawCompany.type === "crm_accounts") {
        dispatch(
          updateAttributesOnRecord(rawCompany, {
            partnerConnectionCount: rawCompany.partnerConnectionCount,
          })
        );
      } else if (rawCompany.type === "nearbound_accounts") {
        dispatch(
          updateAttributesOnRecord(rawCompany, {
            partnerDiscussionFor: rawCompany.partnerDiscussionFor,
          })
        );
      } else if (rawCompany.type === "nearbound_prospects") {
        dispatch(
          updateAttributesOnRecord(rawCompany, {
            partnerConnectionFor: rawCompany.partnerConnectionFor,
          })
        );
      } else {
        throw new Error("Unhandled rawCompany type in undoFromMyAccounts");
      }
    });
  };

  const undoReferredAccounts = async () => {
    // bulk-reset-referred-accounts already manage deletion of partnerConnections
    await dispatch(
      rawPatch({
        type: "referred_accounts",
        path: "bulk-reset-referred-accounts/",
        payload: partnerConnectionPayload.data,
      })
    );
    cleanPartnerConnections();
  };

  const undoPipeline = async () => {
    await deletePartnerConnections();
    await cleanPartnerConnections();
    await history.push({ hash: "#refresh" });
  };

  const undo = async () => {
    setLoading(true);

    switch (page) {
      case Page.Enum.AccountMapping:
        await undoFromAccountMapping();
        break;
      case Page.Enum.MyAccounts:
        await undoFromMyAccounts();
        break;
      case Page.Enum.ReferredAccounts:
        await undoReferredAccounts();
        break;
      case Page.Enum.Collaborate:
        await undoPipeline();
        break;
      default:
        throw new Error("Unhandled page, in Undo Act");
    }

    dispatch(markNotificationAsRead(notification.id));
    setLoading(false);
  };

  const goToPipeline = () => {
    dispatch(markNotificationAsRead(notification.id));
    dispatch(
      setPartnerFilter(
        notification.context?.partnershipId
          ? [notification.context.partnershipId]
          : null
      )
    );
    history.push("/pipeline");
  };

  useEffect(() => {
    // Setting the page in a useEffect because the selectedPartnershipId is only defined at the notification creation.
    // And the undo action can be triggered later.
    setPage(
      selectedPartnershipId !== null
        ? Page.Enum.ReferredAccounts
        : Page.fromLocation(location)
    );
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Grid container spacing={1}>
      {goToPipelineAvailable && (
        <Grid item>
          <Button
            id="pipeline-btn"
            label={i18n.goToPipeline}
            LeftIcon={BookmarkCheckedOutlined}
            variant="primary"
            onClick={goToPipeline}
          />
        </Grid>
      )}
      <Grid item>
        <Button
          label={i18n.undo}
          LeftIcon={Undo}
          variant="senary"
          onClick={undo}
          loading={loading}
        />
      </Grid>
    </Grid>
  );
};

export default NotificationActionAddedToPipeline;

// I18n

const i18n = defineMessages({
  goToPipeline: {
    id: "Notification.action.goToPipeline",
    defaultMessage: "Go to Collaborate",
  },
  undo: {
    id: "Notification.action.undo",
    defaultMessage: "Undo",
  },
});
