import Chip from "@mui/material/Chip";
import Tooltip from "components/ui/Tooltip";
import { T } from "components/ui/Typography";
import { makeStyles } from "makeStyles";
import {
  defineMessages,
  FormattedMessage,
  MessageDescriptor,
} from "react-intl";

type Props = {
  tooltip?: MessageDescriptor;
};

const NewChip = ({ tooltip }: Props) => {
  const { classes } = useStyles();
  return (
    <Tooltip
      title={
        tooltip && (
          <T>
            <FormattedMessage {...tooltip} />
          </T>
        )
      }
    >
      <div>
        <Chip
          className={classes.chip}
          label={<FormattedMessage {...i18n.new} />}
        />
      </div>
    </Tooltip>
  );
};

export default NewChip;

// CSS

const useStyles = makeStyles()((theme) => ({
  chip: {
    display: "flex",
    alignItems: "center",
    fontSize: 10,
    color: theme.palette.gold700,
    borderRadius: 4,
    height: 20,
    fontFamily: "'Inter', sans-serif",
    "& .MuiChip-label": {
      padding: "1px 4px",
      textTransform: "uppercase",
      fontWeight: 500,
    },
    backgroundColor: theme.palette.gold200,
  },
}));

// I18N

const i18n = defineMessages({
  new: {
    id: "crm.AccountMapping.CellFormatters.NewChip.new",
    defaultMessage: "New",
  },
});
