import { CircularProgress } from "@mui/material";
import Checkbox from "@mui/material/Checkbox";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import _ from "lodash";
import { makeStyles } from "makeStyles";
import CrmField from "models/CrmField";
import { useState } from "react";
import { useRowStyles } from "styles/partners";
import { useMergedClasses } from "tss-react";

type Props = {
  record: CrmField;
  handleUpdateField: $TSFixMeFunction;
  loadingDefaultFields: boolean;
  defaultFieldIds: number[];
};

const FieldSettingsRow = ({
  handleUpdateField,
  defaultFieldIds,
  loadingDefaultFields,
  record: field,
}: Props) => {
  const [importFieldCurrentValue, setImportFieldCurrentValue] = useState(
    field.importField
  );

  const { classes: rowClasses } = useRowStyles();
  const { classes: customRowClasses } = useCustomRowStyles();
  const classes = useMergedClasses(rowClasses, customRowClasses);

  const toggleImportField = (value: $TSFixMe) => {
    handleUpdateField(field.id, "import_field", value);
    setImportFieldCurrentValue(value);
  };

  const { classes: toggleButtonStyles } = useToggleButtonStyles();
  const disabled = defaultFieldIds.includes(field.id);
  return (
    <TableRow className={classes.root}>
      <TableCell>{field.crmLabel}</TableCell>
      <TableCell>{_.capitalize(field.crmRecordType)}</TableCell>
      <TableCell size={"small"}>
        {loadingDefaultFields ? (
          <CircularProgress size={20} />
        ) : (
          <Checkbox
            checked={importFieldCurrentValue}
            classes={toggleButtonStyles}
            onChange={(e) => toggleImportField(e.target.checked)}
            color="default"
            data-testid={`import-field-toggle-${field.id}`}
            disabled={disabled}
          />
        )}
      </TableCell>
    </TableRow>
  );
};

export default FieldSettingsRow;

// CSS

const useToggleButtonStyles = makeStyles()((theme) => ({
  root: {
    padding: 0,
    // @ts-expect-error ts-migrate(2339) FIXME: Property 'main' does not exist on type 'Color'.
    color: theme.palette.grey.main,
  },
  checked: {
    color: theme.palette.purple,
  },
}));

const useCustomRowStyles = makeStyles()(() => ({
  root: {
    "&> td": {
      fontSize: 12,
      "&:first-child": {
        width: "60%",
      },
    },
  },
}));
