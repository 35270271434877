import Box from "@mui/material/Box";
import { Dots, GetIntro } from "components/icons";
import DropdownItem from "components/ui/Dropdown/components/DropdownItem";
import DropdownPopover from "components/ui/DropdownPopover";
import Tooltip from "components/ui/Tooltip";
import { T } from "components/ui/Typography";
import useSegment from "hooks/useSegment";
import { useSlackButton } from "hooks/useSlackButton";
import useUserProfile from "hooks/useUserProfile";
import generic from "i18n/generic";
import _ from "lodash";
import { makeStyles } from "makeStyles";
import { PayingFeature } from "models/CompanyPayingFeatureSubscription";
import Record from "models/Record";
import { parse, stringify } from "query-string";
import { MouseEvent, useCallback, useState } from "react";
import { defineMessages, FormattedMessage } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import { selectActivePayingFeatures } from "redux/api/selectors";
import { Available360MappingColumns } from "redux/mapping360/defaults";
import { AvailableNearboundAccountsColumns } from "redux/mapping360NearboundAccounts/defaults";
import {
  openWidget,
  toggleFree,
  togglePower,
  togglePro,
} from "redux/premiumPlanWidget/actions";
import ReferOption from "screens/Frontoffice/screens/DataTables/shared/components/ActionBtn/components/ReferOption";
import ReferDialog from "screens/Frontoffice/screens/DataTables/shared/components/ReferDialog/components/ReferDialog";
import { DataTableType } from "screens/Frontoffice/screens/DataTables/shared/types";
import { Mapping360Event, PremiumPlanEvent } from "tracking";

import { CellProps } from "../../../utils";
import MyAccountsBookmark from "./MyAccountsBookmark";

export const Mapping360ActionCell = ({ row, activeSlack }: CellProps) => {
  const location = useLocation();
  const history = useHistory();
  const { track } = useSegment();
  const dispatch = useDispatch();
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const [referModalOpen, setReferModalOpen] = useState(false);
  const { profile } = useUserProfile();
  const {
    handleOpenModal,
    slackIsActive,
    tooltipTitle,
    dialog,
  } = useSlackButton({ activeSlack, account: row });
  const currentSearch = parse(location.search);
  const payingFeatures = useSelector(selectActivePayingFeatures);
  const isPaidUser = payingFeatures.includes(
    // We rely on this basic feature for now as we don't have any related to intro requests.
    PayingFeature.UnlimitedPipelineSharing
  );
  const { classes } = useStyles({ isPaidUser });
  const isNearboundAccount = row.type === "nearbound_accounts";
  const rawCompanyId = isNearboundAccount ? row.rawCompanyId : row.id;

  const handleClick = (event: MouseEvent<HTMLDivElement>) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleGetIntroClick = (event: MouseEvent<SVGSVGElement>) => {
    event.stopPropagation();
    track(Mapping360Event.getIntroFrom360Clicked);
    if (!isPaidUser) {
      return;
    }
    currentSearch["selected-intro"] = rawCompanyId;
    const { key, search, ...state } = location;
    history.replace({ ...state, search: stringify(currentSearch) });
  };

  const handleCloseReferModal = () => setReferModalOpen(false);
  const handleOpenReferModal = () => setReferModalOpen(true);

  const bookmarkCount = isNearboundAccount
    ? getBookmarkCount(row)
    : _.get(row, "partnerConnectionCount", 0);
  const bookmarkProps = {
    bookmarkCount,
    rawCompanyId,
  };

  const openPremiumWidget = useCallback(() => {
    dispatch(toggleFree(false));
    dispatch(togglePro(true));
    dispatch(togglePower(false));
    dispatch(openWidget(true));
    track(PremiumPlanEvent.explorePlan, {
      from: "get intro from 360 goals",
    });
  }, [dispatch, track]);

  const getIntroTooltip = (
    <T>
      {isPaidUser ? (
        <FormattedMessage {...i18n.getIntro} />
      ) : (
        <>
          <FormattedMessage {...i18n.getIntroUpgrade} />
          &nbsp;
          <span
            className={classes.tooltipLink}
            onClick={() => openPremiumWidget()}
          >
            <FormattedMessage {...generic.explorePlans} />
          </span>
        </>
      )}
    </T>
  );

  return (
    <Box
      className={classes.container}
      id="noTriggerCell"
      data-testid="noTriggerCell"
    >
      {profile.ownsPipeline && (
        <MyAccountsBookmark {...bookmarkProps} row={row} />
      )}
      {!isNearboundAccount && (
        <>
          {profile.canRequestIntro && (
            <Tooltip title={getIntroTooltip} placement={"top-start"}>
              <GetIntro
                className={classes.getIntroIcon}
                onClick={handleGetIntroClick}
              />
            </Tooltip>
          )}
          <div onClick={handleClick} className={classes.iconBtn}>
            <Dots style={{ fontSize: 16 }} />
          </div>
          <DropdownPopover
            anchorEl={anchorEl}
            onClose={handleClose}
            open={!!anchorEl}
            position="left"
          >
            <DropdownItem
              label={<FormattedMessage {...i18n.sendToSlack} />}
              logo="/images/slack-logo.png"
              onChange={handleOpenModal}
              tooltipMessage={tooltipTitle}
              isDisabled={!slackIsActive}
            />
            {profile.ownsPipeline && (
              <ReferOption
                openModal={handleOpenReferModal}
                sourceType={DataTableType.MAPPING_360}
                selectedRowCount={1}
              />
            )}
          </DropdownPopover>
          {dialog}
          {profile.ownsPipeline && (
            <ReferDialog
              isOpen={referModalOpen}
              handleClose={handleCloseReferModal}
              recordsToRefer={[row]}
              sourceType={DataTableType.MAPPING_360}
            />
          )}
        </>
      )}
    </Box>
  );
};

// Helpers

type IPartnerDiscussionFor = {
  partner_name: string;
  partnership_id: string;
};

const getBookmarkCount = (row: Record) => {
  const partnerDiscussionFor = _.get(
    row,
    "partnerDiscussionFor",
    []
  ) as IPartnerDiscussionFor[];
  let uniquePartners = _.uniqWith(partnerDiscussionFor, (a, b) => {
    const isEmptyA = !a.partnership_id;
    const isEmptyB = !b.partnership_id;
    if (isEmptyA && isEmptyB) {
      return true; // Treat all empty name/id as equal
    }
    return (
      a.partner_name === b.partner_name && a.partnership_id === b.partnership_id
    );
  });
  return uniquePartners.length;
};

export const isMapping360ActionCell = ({ fieldName }: CellProps) => {
  return (
    fieldName === Available360MappingColumns.actions ||
    fieldName === AvailableNearboundAccountsColumns.Actions
  );
};

// CSS

const useStyles = makeStyles<{ isPaidUser: boolean }>()(
  (theme, { isPaidUser }) => ({
    container: {
      display: "flex",
      alignItems: "center",
      columnGap: 4,
    },
    iconBtn: {
      cursor: "pointer",
      lineHeight: "15px",
      padding: 1,
      "&:hover": {
        backgroundColor: theme.palette.taupe500,
        borderRadius: 9,
      },
    },
    getIntroIcon: {
      fontSize: 16,
      color: isPaidUser ? theme.palette.midnight : theme.palette.greyReveal,
    },
    tooltipLink: {
      cursor: "pointer",
      textDecoration: "underline",
    },
  })
);

const i18n = defineMessages({
  sendToSlack: {
    id: "ui.datagrid.cellRenderers.Mapping360ActionCell.sendToSlack",
    defaultMessage: "Send to Slack",
  },
  getIntro: {
    id: "ui.datagrid.cellRenderers.Mapping360ActionCell.getIntro",
    defaultMessage: "Get Intro",
  },
  getIntroUpgrade: {
    id: "ui.datagrid.cellRenderers.Mapping360ActionCell.getIntroUpgrade",
    defaultMessage: "Upgrade to use Get Intro.",
  },
});
