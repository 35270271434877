import { defineMessages, FormattedMessage } from "react-intl";
import { useHistory, useLocation } from "react-router-dom";

import { updateHistoryUpdatedSource } from "../helpers/utils";
import { AssignmentWidget } from "./AssignmentWidget";

export const AssignmentWidgetTab = ({
  integrationId,
  provider,
  ...props
}: $TSFixMe) => {
  const history = useHistory();
  const location = useLocation();

  const handleSave = () => {
    updateHistoryUpdatedSource(history, location);
    props?.callback?.();
  };

  return (
    <AssignmentWidget
      integrationId={integrationId}
      summary={
        <FormattedMessage {...i18n.assignmentSummary} values={{ provider }} />
      }
      callback={handleSave}
      {...props}
    />
  );
};

const i18n = defineMessages({
  assignmentSummary: {
    id: "crm.settings.AssignmentWidget.assignmentSummary",
    defaultMessage: "Use this {provider} data source for:",
  },
});
