import { isFulfilled, isRejected } from "@reduxjs/toolkit";
import { Plus } from "components/icons";
import Button from "components/ui/Button";
import Dropdown from "components/ui/Dropdown";
import DropdownItem from "components/ui/Dropdown/components/DropdownItem";
import { IDropdownOption } from "components/ui/Dropdown/components/types";
import Tag from "components/ui/Tag";
import Tooltip from "components/ui/Tooltip";
import usePushNotification from "hooks/usePushNotification";
import useSegment from "hooks/useSegment";
import debounce from "lodash/debounce";
import { makeStyles } from "makeStyles";
import { useState } from "react";
import { defineMessages, FormattedMessage, useIntl } from "react-intl";
import { useDispatch } from "react-redux";
import { rawPost } from "redux/api/thunks";
import { addCreatedTag } from "redux/init/actions";
import { JSONAPIResponse } from "services/types";
import { TagsEvent } from "tracking";

import { TagData } from "../helpers/types";

// Limit of 50 characters per tag name
const TAG_NAME_LIMIT = 50;

type Props = {
  tagList: TagData[];
  selectedTags: TagData[];
  selectTag: (tagId: number, tagName: string) => void;
  unselectTag: (tagId: number, tagName: string) => void;
  allowSelect?: boolean;
  maxWidth?: number | null;
};

const TagsWidget = ({
  tagList,
  selectedTags,
  selectTag,
  unselectTag,
  allowSelect = true,
  maxWidth = null,
}: Props) => {
  const intl = useIntl();
  const { classes } = useStyles();
  const dispatch = useDispatch();
  const { track } = useSegment();
  const pushNotification = usePushNotification();

  const [anchorEl, setAnchorEl] = useState<Element | null>(null);
  const [openDropdown, setOpenDropdown] = useState(false);
  const [searchQuery, setSearchQuery] = useState<string>("");

  const [processing, setProcessing] = useState(false);
  const handleSelectTag = async (tagId: number, tagName: string) => {
    setProcessing(true);
    await selectTag(tagId, tagName);
    setProcessing(false);
  };
  const handleUnselectTag = async (tagId: number, tagName: string) => {
    setProcessing(true);
    await unselectTag(tagId, tagName);
    setProcessing(false);
  };

  const displaySuggestedOption = Boolean(searchQuery.length)
    ? searchQuery.length <= TAG_NAME_LIMIT
    : false;

  const handleOpenDropdown = (event: React.SyntheticEvent) => {
    setAnchorEl(event.currentTarget);
    setOpenDropdown(!openDropdown);
  };

  const handleCloseDropdown = () => {
    setAnchorEl(null);
    setOpenDropdown(false);
    setSearchQuery("");
  };

  const { visibleTags, hiddenTags } = getDisplayedListWithMaxWidth(
    selectedTags,
    maxWidth
  );

  const mapOptionsToDropdown = (options: TagData[]): IDropdownOption[] => {
    return options
      .filter(
        (option) =>
          !visibleTags.find((t: TagData) => t.tag.id === option.tag.id) &&
          !hiddenTags.find((t: TagData) => t.tag.id === option.tag.id)
      )
      .map((o) => ({
        id: String(o.tag.id),
        value: o.tag.tagName,
        name: o.tag.tagName,
        label: (
          <div className={classes.optionContainer}>
            <span>{o.tag.tagName}</span>
            {(o.usage || o.usage === 0) && (
              <Tooltip title={o.usageTooltip}>
                <span className={classes.usageCounter}>{o.usage}</span>
              </Tooltip>
            )}
          </div>
        ),
      }));
  };

  const onInputChange = debounce((query: string) => {
    setSearchQuery(query);
  }, 300);

  const createTag = async (value: string) => {
    const result = await dispatch(
      rawPost({
        type: "partnership_tags",
        path: "",
        payload: {
          data: {
            type: "partnership_tags",
            attributes: {
              tag_name: value,
            },
          },
        },
      })
    );
    if (isFulfilled(result)) {
      handleCloseDropdown();
      const response = result.payload as JSONAPIResponse;
      const tagId = +response.data.id;
      handleSelectTag(tagId, value);
      dispatch(addCreatedTag(tagId));
      track(TagsEvent.tagCreated, {
        tagName: value,
      });
    }
    if (isRejected(result)) {
      pushNotification("default_error");
    }
  };

  const SuggestedOption = () => {
    return (
      <DropdownItem
        id={searchQuery}
        onChange={() => createTag(searchQuery)}
        name={
          <FormattedMessage
            {...i18n.addPlaceholder}
            values={{ query: searchQuery }}
          />
        }
      />
    );
  };

  return (
    <div className={classes.root}>
      {visibleTags.map((t: TagData) =>
        t.usage || t.usage === 0 ? (
          <Tooltip title={t.usageTooltip} key={t.tag.tagName}>
            <span className={classes.flexContainer}>
              <Tag
                isSmall
                label={t.tag.tagName}
                onDelete={() => handleUnselectTag(t.tag.id, t.tag.tagName)}
                type="squared"
              />
            </span>
          </Tooltip>
        ) : (
          <Tag
            key={t.tag.tagName}
            isSmall
            label={t.tag.tagName}
            onDelete={() => handleUnselectTag(t.tag.id, t.tag.tagName)}
            type="squared"
          />
        )
      )}
      {hiddenTags.length > 0 && (
        <Tooltip
          title={
            <div className={classes.tooltipTagsContainer}>
              {hiddenTags.map((t: TagData) => (
                <Tag
                  isSmall
                  variant="dark"
                  label={t.tag.tagName}
                  type="squared"
                />
              ))}
            </div>
          }
        >
          <span className={classes.flexContainer}>
            <Tag isSmall label={`+${hiddenTags.length}`} type="squared" />
          </span>
        </Tooltip>
      )}
      {allowSelect && (
        <Button
          label={selectedTags.length ? undefined : i18n.addTag}
          LeftIcon={Plus}
          onClick={handleOpenDropdown}
          variant="quinary"
          size={"xxSmall"}
          loading={processing}
        />
      )}
      <Dropdown
        onChange={(ids: string[] | null) => {
          const selectedValue = tagList?.find(
            (tagOption) => ids && tagOption.tag.id === +ids[0]
          );
          if (selectedValue) {
            handleSelectTag(+selectedValue.tag.id, selectedValue.tag.tagName);
          }
          handleCloseDropdown();
        }}
        options={mapOptionsToDropdown(tagList)}
        open={openDropdown}
        onClose={handleCloseDropdown}
        anchorEl={anchorEl}
        searchPlaceholder={intl.formatMessage(i18n.enterTag)}
        searchCallback={onInputChange}
        CustomFooter={displaySuggestedOption && <SuggestedOption />}
        size="small"
      />
    </div>
  );
};

export default TagsWidget;

// Helpers

const getTagWidth = (name: string) => name.length * 7 + 6 * 2 + 14; // Label width plus padding plus cross icon

const shouldDisplayItem = (
  itemWidth: number,
  currentWidth: number,
  columnWidth: number
) => currentWidth + itemWidth < columnWidth;

const getDisplayedListWithMaxWidth = (
  selectedTags: TagData[],
  maxWidth: number | null
) => {
  let currentWidth = 51; // add tag button & counter width
  let visibleTags: TagData[] = [];
  let hiddenTags: TagData[] = [];
  selectedTags.forEach((t) => {
    const tagWidth = getTagWidth(t.tag.tagName);
    // Make tag visible when maxWidth doesn't exist or there is still space
    if (
      maxWidth === null ||
      shouldDisplayItem(tagWidth, currentWidth, maxWidth)
    ) {
      currentWidth += tagWidth;
      visibleTags.push(t);
    } else {
      hiddenTags.push(t);
    }
  });
  return { visibleTags, hiddenTags };
};

// CSS

export const useStyles = makeStyles()((theme) => ({
  root: {
    display: "flex",
    flexWrap: "wrap",
    columnGap: 2,
    rowGap: 2,
    alignItems: "center",
  },
  flexContainer: {
    display: "flex",
  },
  tooltipTagsContainer: {
    display: "flex",
    columnGap: 2,
    rowGap: 2,
    padding: "7px, 10px, 7px, 10px",
    flexWrap: "wrap",
  },
  optionContainer: {
    display: "flex",
    columnGap: 6,
  },
  usageCounter: {
    color: theme.palette.greyReveal,
  },
}));

// I18N

const i18n = defineMessages({
  addTag: {
    id: "crm.PartnershipSettings.TagsWidget.addTag",
    defaultMessage: "Add tag",
  },
  addPlaceholder: {
    id: "crm.PartnershipSettings.TagsWidget.addPlaceholder",
    defaultMessage: `Add "{query}"`,
  },
  enterTag: {
    id: "crm.PartnershipSettings.TagsWidget.enterTag",
    defaultMessage: "Enter a Tag",
  },
});
