import { Box, Grid } from "@mui/material";
import {
  DataGridPremium,
  GridColDef,
  GridRenderCellParams,
} from "@mui/x-data-grid-premium";
import Checkbox from "components/ui/Checkbox";
import Popup from "components/ui/Popup";
import { T } from "components/ui/Typography";
import { makeStyles } from "makeStyles";
import { defineMessages, FormattedMessage } from "react-intl";

import { useOpportunitySharingLevelDefinitionsApi } from "../hooks/useOpportunitySharingLevelDefinitionsApi";
import { DefinitionField, SharingLevel } from "../utils";

type Props = {
  isModalOpen: boolean;
  setIsModalOpen: (value: boolean) => void;
};

const DISABLED_FIELDS = [DefinitionField.AccountName, DefinitionField.Status];

export const OpportunitySharingLevelDefinitionsModal = ({
  isModalOpen,
  setIsModalOpen,
}: Props) => {
  const { classes } = useStyles();
  const {
    handleUpdateDefinitionDefaults,
    rows,
    isLoadingDefaults,
  } = useOpportunitySharingLevelDefinitionsApi();

  const generateColumn = (sharingLevel: SharingLevel) => ({
    field: sharingLevel.toString(),
    flex: 1,
    renderHeader: () => (
      <T titleSmall>
        <FormattedMessage {...i18n[`sharingLevel${sharingLevel}`]} />
      </T>
    ),
    renderCell: (params: GridRenderCellParams) =>
      sharingLevel === SharingLevel.None ? null : (
        <Checkbox
          checked={params.row[sharingLevel] ?? false}
          disabled={DISABLED_FIELDS.includes(params.row.opportunityFieldName)}
          onChange={(event) =>
            handleUpdateDefinitionDefaults(sharingLevel, {
              field: params.row.opportunityFieldName,
              value: event.target.checked,
            })
          }
        />
      ),
    disableColumnMenu: true,
    sortable: false,
  });

  const columns: GridColDef[] = [
    {
      field: "opportunityFieldName",
      flex: 1,
      headerName: "",
      minWidth: 180,
      renderHeader: () => (
        <T titleSmall>
          <FormattedMessage {...i18n.opportunityDetails} />
        </T>
      ),
      renderCell: (params) => (
        <T>
          <FormattedMessage
            {...i18n[
              `definition_${params.row.opportunityFieldName}` as keyof typeof i18n
            ]}
          />
        </T>
      ),
      disableColumnMenu: true,
      sortable: false,
    },
    ...[SharingLevel.None, SharingLevel.Limited, SharingLevel.Full].map(
      generateColumn
    ),
  ];

  return (
    <Popup
      isOpen={isModalOpen}
      handleClose={() => setIsModalOpen(false)}
      hideActions
      title={
        <Box marginBottom="4px">
          <T h3 bold className={classes.marginBottom}>
            <FormattedMessage {...i18n.title} />
          </T>
          <T className={classes.alpha}>
            <FormattedMessage {...i18n.description} />
          </T>
        </Box>
      }
      variant="secondary"
      width={772}
    >
      <Grid container>
        <DataGridPremium
          columns={columns}
          disableVirtualization={process.env.NODE_ENV === "test"}
          rows={rows}
          loading={isLoadingDefaults}
          autoHeight
          className={classes.root}
          hideFooter
          rowHeight={38}
          columnHeaderHeight={36}
          disableRowSelectionOnClick
          // TODO: disable cell selection
        />
      </Grid>
    </Popup>
  );
};

const i18n = defineMessages({
  title: {
    id: "CollaborateSettings.OpportunitySharingLevelDefinitionsModal.title",
    defaultMessage:
      "Customize sharing options for opportunities attached to Collabs",
  },
  description: {
    id:
      "CollaborateSettings.OpportunitySharingLevelDefinitionsModal.description",
    defaultMessage:
      "There are 3 options for opportunities sharing in Collabs. You can customize 2 of them below.",
  },
  opportunityDetails: {
    id:
      "CollaborateSettings.OpportunitySharingLevelDefinitionsModal.opportunityDetails",
    defaultMessage: "Opportunity details",
  },
  [`sharingLevel${SharingLevel.None}`]: {
    id:
      "CollaborateSettings.OpportunitySharingLevelDefinitionsModal.sharingLevel.None",
    defaultMessage: "No sharing",
  },
  [`sharingLevel${SharingLevel.Limited}`]: {
    id:
      "CollaborateSettings.OpportunitySharingLevelDefinitionsModal.sharingLevel.Limited",
    defaultMessage: "Limited sharing",
  },
  [`sharingLevel${SharingLevel.Full}`]: {
    id:
      "CollaborateSettings.OpportunitySharingLevelDefinitionsModal.sharingLevel.Full",
    defaultMessage: "Transparent sharing",
  },
  [`definition_${DefinitionField.AccountName}`]: {
    id:
      "CollaborateSettings.OpportunitySharingLevelDefinitionsModal.definition.AccountName",
    defaultMessage: "Account name",
  },
  [`definition_${DefinitionField.Status}`]: {
    id:
      "CollaborateSettings.OpportunitySharingLevelDefinitionsModal.definition.Status",
    defaultMessage: "Status",
  },
  [`definition_${DefinitionField.Stage}`]: {
    id:
      "CollaborateSettings.OpportunitySharingLevelDefinitionsModal.definition.Stage",
    defaultMessage: "Stage",
  },
  [`definition_${DefinitionField.CloseDate}`]: {
    id:
      "CollaborateSettings.OpportunitySharingLevelDefinitionsModal.definition.CloseDate",
    defaultMessage: "Close date",
  },
  [`definition_${DefinitionField.Owner}`]: {
    id:
      "CollaborateSettings.OpportunitySharingLevelDefinitionsModal.definition.Owner",
    defaultMessage: "Owner",
  },
  [`definition_${DefinitionField.OpportunityName}`]: {
    id:
      "CollaborateSettings.OpportunitySharingLevelDefinitionsModal.definition.OpportunityName",
    defaultMessage: "Opportunity name",
  },
  [`definition_${DefinitionField.Amount}`]: {
    id:
      "CollaborateSettings.OpportunitySharingLevelDefinitionsModal.definition.Amount",
    defaultMessage: "Amount",
  },
});

export const useStyles = makeStyles()((theme) => ({
  alpha: {
    color: theme.palette.alpha500,
  },
  marginBottom: {
    marginBottom: theme.spacing(1),
  },
  root: {
    border: `1px solid ${theme.palette.greyLight100}`,
    borderRadius: 6,
    "& .MuiDataGrid-columnHeaders": {
      background: theme.palette.greyLight050,
      borderBottom: `1px solid ${theme.palette.greyLight100}`,
    },
    "& .MuiDataGrid-cell": {
      padding: "10px 12px",
    },
    "& .MuiDataGrid-row": {
      "& .MuiDataGrid-cell:not(:first-child)": {
        borderLeft: `1px solid ${theme.palette.greyLight100}`,
      },
    },
    "& .MuiDataGrid-columnHeader:not(:first-child)": {
      borderLeft: `1px solid ${theme.palette.greyLight100}`,
    },
  },
}));
