import { ClassNameMap } from "@mui/material/styles";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import { makeStyles } from "makeStyles";
import { FormattedMessage } from "react-intl";
import { useMergedClasses } from "tss-react";

const useStyles = makeStyles()((theme) => ({
  sortable: {
    fontWeight: 800,
    color: theme.palette.midnight,
    "&:hover": {
      // @ts-expect-error ts-migrate(2339) FIXME: Property 'mid' does not exist on type 'Color'.
      color: theme.palette.grey.mid,
    },
  },
  bold: {
    fontWeight: 600,
    color: theme.palette.midnight,
  },
  hover: {
    "&:hover": {
      // @ts-expect-error ts-migrate(2339) FIXME: Property 'mid' does not exist on type 'Color'.
      color: theme.palette.grey.mid,
    },
  },
  tableRow: {},
}));

type Props = {
  classes?: ClassNameMap;
  values: $TSFixMe | $TSFixMe[];
  sortableColums?: $TSFixMe[];
  order: string;
  orderBy: string;
  onRequestSort: $TSFixMeFunction;
};

export const ListTableHead = ({
  classes: customClasses = {},
  onRequestSort,
  order,
  orderBy,
  sortableColums,
  values,
}: Props) => {
  const { classes: baseClasses } = useStyles();
  const classes = useMergedClasses(baseClasses, customClasses);
  const createSortHandler = (property: $TSFixMe) => (event: $TSFixMe) =>
    onRequestSort(event, property);

  const columns = Array.isArray(values)
    ? Object.assign({}, ...values.map(({ id, message }) => ({ [id]: message })))
    : values;

  return (
    <TableHead>
      <TableRow className={classes.tableRow}>
        {Object.keys(columns).map((key) =>
          sortableColums && sortableColums.indexOf(key) !== -1 ? (
            <TableCell
              key={key}
              // @ts-expect-error ts-migrate(2322) FIXME: Type 'string | false' is not assignable to type 'f... Remove this comment to see the full error message
              sortDirection={orderBy === key ? order : false}
            >
              <TableSortLabel
                active={orderBy === key}
                // @ts-expect-error ts-migrate(2769) FIXME: No overload matches this call.
                direction={order}
                onClick={createSortHandler(key)}
                className={classes.sortable}
              >
                {typeof columns[key] === "string"
                  ? columns[key]
                  : columns[key].defaultMessage !== "-" && (
                      <FormattedMessage {...columns[key]} />
                    )}
              </TableSortLabel>
            </TableCell>
          ) : (
            <TableCell key={key} className={classes.bold}>
              {typeof columns[key] === "string"
                ? columns[key]
                : columns[key].defaultMessage !== "-" && (
                    <FormattedMessage {...columns[key]} />
                  )}
            </TableCell>
          )
        )}
      </TableRow>
    </TableHead>
  );
};

export default ListTableHead;
