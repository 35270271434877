import { pickersLayoutClasses, StaticDatePicker } from "@mui/x-date-pickers";
import dayjs, { Dayjs } from "dayjs";
import { MouseEvent, useCallback, useState } from "react";
import { defineMessages } from "react-intl";

import DropdownPopover from "./DropdownPopover";
import { T } from "./Typography";

type Props = {
  children: (
    onClick: (event: React.MouseEvent<HTMLButtonElement>) => void
  ) => React.ReactNode;
  onChange: (date: Date | null) => void;
  value?: Date | null;
};

export const DatePicker = ({ children, value, onChange }: Props) => {
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const handleChange = (value: Dayjs | null) => {
    onChange(value ? value.toDate() : null);
    setAnchorEl(null);
  };

  const handleToggleCalendar = useCallback(
    (event: React.MouseEvent<HTMLButtonElement>) => {
      if (anchorEl) {
        setAnchorEl(null);
        return;
      }
      setAnchorEl(event.currentTarget);
    },
    [anchorEl, setAnchorEl]
  );

  const handlePropagation = (event: MouseEvent) => {
    event.stopPropagation();
  };

  return (
    <>
      {children(handleToggleCalendar)}
      <DropdownPopover
        anchorEl={anchorEl}
        onClose={handleToggleCalendar}
        open={Boolean(anchorEl)}
        position="right"
      >
        <T>
          <div onClick={handlePropagation}>
            <StaticDatePicker
              onChange={handleChange}
              value={dayjs(value)}
              slots={{ toolbar: undefined }}
              slotProps={{
                actionBar: {
                  actions: ["clear", "today"],
                },
                layout: {
                  sx: {
                    [`.${pickersLayoutClasses.actionBar}`]: {
                      gridColumn: 2,
                      gridRow: 1, // Used to have action bar at the top (bottom by default)
                    },
                  },
                },
              }}
            />
          </div>
        </T>
      </DropdownPopover>
    </>
  );
};

/// I18N

export const i18n = defineMessages({
  clearBtn: {
    id: "datePicker.clearBtn",
    defaultMessage: "Clear",
  },
});
