import { createSvgIcon } from "@mui/material/utils";

import { SvgIcon } from "./types";

const displayName = "Expand";

const Expand: SvgIcon = createSvgIcon(
  // Using transform to enlarge from provided viewbox="0 0 12 12"
  // to default "0 0 24 24" from `createSvgIcon` -> `SvgIcon`.
  <g fill="none" transform="matrix(2,0,0,2,0,0)">
    <path
      d="M10 4.875C9.79289 4.875 9.625 4.70711 9.625 4.5V2.90536L2.90536 9.625H4.5C4.70711 9.625 4.875 9.79289 4.875 10C4.875 10.2071 4.70711 10.375 4.5 10.375H2.5C2.01675 10.375 1.625 9.98325 1.625 9.5V7.5C1.625 7.29289 1.79289 7.125 2 7.125C2.20711 7.125 2.375 7.29289 2.375 7.5V9.0947L9.0947 2.375L7.5 2.375C7.29289 2.375 7.125 2.20711 7.125 2C7.125 1.79289 7.29289 1.625 7.5 1.625H9.5C9.98325 1.625 10.375 2.01675 10.375 2.5V4.5C10.375 4.70711 10.2071 4.875 10 4.875Z"
      fill="currentColor"
    />
  </g>,
  displayName
);

Expand.displayName = displayName;

export default Expand;
