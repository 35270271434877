import { Box } from "@mui/material";
import { Check, Plus } from "components/icons";
import Button from "components/ui/Button";
import Popup from "components/ui/Popup";
import { T } from "components/ui/Typography";
import useRecord from "hooks/useRecord";
import generic from "i18n/generic";
import { makeStyles } from "makeStyles";
import Record from "models/Record";
import { useEffect, useState } from "react";
import { defineMessages, FormattedHTMLMessage, useIntl } from "react-intl";

import { useCreatePipelines } from "../hooks/useCreatePipelines";
import { AddPipelineItemAccountDropdown } from "./AddPipelineItemAccountDropdown";
import { AddPipelineItemPartnerDropdown } from "./AddPipelineItemPartnerDropdown";
import { OpportunityList } from "./OpportunityList";

type Props = {
  isOpen: boolean;
  onClose: () => void;
  refresh: () => void;
  records?: Record<string>[] | null;
};

export const AddPipelineItemModal = ({
  isOpen,
  onClose,
  refresh,
  records,
}: Props) => {
  const [account, setAccount] = useState<Record<"crm_accounts">>();
  const [selectedPartnerIds, setSelectedPartnerIds] = useState<string[]>([]);
  const [selectedOpportunityIds, setSelectedOpportunityIds] = useState<
    number[]
  >([]);
  const [isLoading, setIsLoading] = useState(false);
  const { classes } = useStyles();
  const [currentStep, setCurrentStep] = useState<1 | 2>(1);
  const intl = useIntl();

  const { record } = useRecord("crm_accounts", account?.id ?? null, false);
  const { createPipelines, opportunities } = useCreatePipelines({
    account,
  });

  const goToNextStep = () => {
    if (opportunities?.length) {
      setCurrentStep(2);
      return;
    }
    handleCreatePipelines(false);
  };

  useEffect(() => {
    if (!record) {
      return;
    }
    setAccount(record);
  }, [record]);

  useEffect(() => {
    setSelectedPartnerIds([]);
    setSelectedOpportunityIds([]);
  }, [account]);

  useEffect(() => {
    setSelectedOpportunityIds(opportunities?.map((o) => o.id) ?? []);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [account]);

  const handleCreatePipelines = async (addOpportunities?: boolean) => {
    handleClose();
    if (!account) {
      return;
    }
    createPipelines(
      selectedPartnerIds,
      addOpportunities ? selectedOpportunityIds : [],
      refresh
    );
  };

  useEffect(() => {
    // only run when prev open is true and current is false, i.e. modal is closing
    if (isOpen) {
      return;
    }

    // delay the cleanup until modal transition ends
    const timeoutId = setTimeout(() => {
      setAccount(undefined);
      setSelectedPartnerIds([]);
      setSelectedOpportunityIds([]);
      setIsLoading(false);
      setCurrentStep(1);
    }, 300);

    return () => clearTimeout(timeoutId);
  }, [isOpen]);

  const handleClose = () => {
    onClose();
  };

  const renderStepContent = () => {
    switch (currentStep) {
      case 1:
        return (
          <>
            <AddPipelineItemAccountDropdown
              account={account}
              isModalOpen={isOpen}
              setLoading={setIsLoading}
              setAccount={setAccount}
            />
            {account ? (
              <AddPipelineItemPartnerDropdown
                account={account}
                isModalOpen={isOpen}
                loading={isLoading}
                setSelectedPartnerIds={setSelectedPartnerIds}
                selectedPartnerIds={selectedPartnerIds}
                records={records as Record<"partner_connections">[]}
              />
            ) : (
              <></>
            )}
          </>
        );
      case 2:
        return (
          <OpportunityList
            items={opportunities}
            onChange={setSelectedOpportunityIds}
            value={selectedOpportunityIds}
          />
        );
      default:
        return <></>;
    }
  };

  return (
    <Popup
      handleClose={
        currentStep === 2 ? () => handleCreatePipelines(false) : handleClose
      }
      hideActions
      isOpen={isOpen}
      maxWidth="sm"
      title={
        <Box display="flex" flexDirection="column" gap="8px">
          <T h3 bold>
            <FormattedHTMLMessage
              {...(currentStep === 1
                ? i18n.createPartnerDiscussions
                : i18n.attachOpportunities)}
            />
          </T>
          <T className={classes.subtitle}>
            <FormattedHTMLMessage
              {...(currentStep === 1
                ? i18n.createPartnerDiscussionsSubtitle
                : i18n.attachOpportunitiesSubtitle)}
              values={{
                accountName: account?.name,
                count: opportunities.length,
              }}
            />
          </T>
        </Box>
      }
      variant="secondary"
      width={520}
      customActions={
        <Box
          display="flex"
          width="100%"
          justifyContent={currentStep === 1 ? "space-between" : "flex-end"}
          gap="4px"
        >
          {((currentStep === 2 && selectedOpportunityIds.length > 0) ||
            currentStep === 1) && (
            <Button
              label={currentStep === 1 ? generic.cancel : i18n.skip}
              onClick={
                currentStep === 2
                  ? () => handleCreatePipelines(false)
                  : handleClose
              }
              variant="tertiary"
            />
          )}
          {currentStep < 2 && (
            <Button
              LeftIcon={Check}
              label={i18n.createPartnerDiscussion}
              onClick={goToNextStep}
            />
          )}
          {currentStep === 2 && (
            <Button
              LeftIcon={Plus}
              label={intl.formatMessage(i18n.attachOpportunitiesCount, {
                count: selectedOpportunityIds.length ?? 0,
              })}
              onClick={() => handleCreatePipelines(true)}
            />
          )}
        </Box>
      }
    >
      {renderStepContent()}
    </Popup>
  );
};

const i18n = defineMessages({
  attachOpportunities: {
    defaultMessage: "Attach Opportunities",
    id: "Pipeline.AddPipelineItemModal.attachOpportunities",
  },
  attachOpportunitiesCount: {
    id: "Pipeline.AddPipelineItemModal.attachOpportunitiesCount",
    defaultMessage:
      "{count, plural, =0 {Attach 0 Opportunities} one {Attach 1 Opportunity} other {Attach # Opportunities}}",
  },
  attachOpportunitiesSubtitle: {
    defaultMessage:
      "{accountName} has {count} attached opportunities.<br/>Do you want to attach them to your Collaborations?",
    id: "Pipeline.AddPipelineItemModal.attachOpportunitiesSubtitle",
  },
  createPartnerDiscussion: {
    defaultMessage: "Add to Collaborate",
    id: "Pipeline.AddPipelineItemModal.createPartnerDiscussion",
  },
  createPartnerDiscussions: {
    defaultMessage: "Add to Collaborate",
    id: "Pipeline.AddPipelineItemModal.createPartnerDiscussions",
  },
  createPartnerDiscussionsSubtitle: {
    defaultMessage: "Start collaborating on your accounts.",
    id: "Pipeline.AddPipelineItemModal.createPartnerDiscussionsSubtitle",
  },
  skip: {
    defaultMessage: "Skip this step",
    id: "Pipeline.AddPipelineItemModal.skip",
  },
});

const useStyles = makeStyles()((theme) => ({
  formControl: {
    marginBottom: 20,
  },
  loader: {
    paddingRight: 9,
  },
  label: {
    color: "unset",
    display: "flex",
    flexDirection: "column",
    gap: "2px",
    marginBottom: 6,
  },
  subtitle: {
    color: theme.palette.alpha750,
  },
}));
