import SplashScreen from "components/ui/SplashScreen";
import { stringify } from "query-string";
import { useSelector } from "react-redux";
import { Redirect, Route, RouteProps, useLocation } from "react-router-dom";
import { RevealStore } from "redux/typing";

const selectState = (state: RevealStore) => ({
  initialized: state.context.initialized,
  loggedIn: state.user.login.loggedIn,
  loading: state.user.login.pending,
});

type Props = {
  redirectToSignUp?: boolean;
} & RouteProps;

const ProtectedRoute = ({ redirectToSignUp, ...props }: Props) => {
  const { loading, loggedIn, initialized } = useSelector(selectState);
  const location = useLocation();
  if (!initialized || loading) {
    return <SplashScreen />;
  }
  if (!loggedIn) {
    const params = stringify({
      next:
        location.pathname +
        (location.search ? location.search : "") +
        (location.hash ? location.hash : ""),
    });
    let url = "login";
    if (redirectToSignUp) {
      url = "register";
    }
    if (location.pathname === "/orientation") {
      return <Redirect to={`/${url}`} />;
    }
    return <Redirect to={`/${url}?${params}`} />;
  }
  return <Route {...props} />;
};

export default ProtectedRoute;
