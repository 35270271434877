import { T } from "components/ui/Typography";
import { ProviderType } from "config/constants";
import getFirstValueFromURL from "helpers/getFirstValueFromURL";
import { Location } from "history";
import {
  defineMessages,
  FormattedHTMLMessage,
  FormattedMessage,
} from "react-intl";
import { useLocation } from "react-router-dom";
import { GsheetUrl } from "screens/Frontoffice/shared/helpers/types";

import { BaseContent, sharedStyles } from "./BaseContent";
import { CrmAuthProviderSelectButtons } from "./CrmAuthProviderSelectButtons";
import { CrmSelectButtons } from "./CrmSelectButtons";
import { CsvContent } from "./CsvContent";
import { GoogleSheetsAuthContent } from "./GoogleSheetsAuthContent";
import { GoogleSheetsTabSelectContent } from "./GoogleSheetsTabSelectContent";

export const SourceSelectContent = () => {
  const { cx, classes } = sharedStyles({});
  const location = useLocation();
  const provider = getFirstValueFromURL(location, "provider");

  return (
    content(provider, location) ?? (
      <BaseContent>
        <T h2 className={cx(classes.contentTitle, classes.textCenter)}>
          <FormattedMessage {...i18n.title} />
        </T>
        <T
          className={cx(
            classes.textCenter,
            classes.marginBottomLarge,
            classes.alphaText
          )}
        >
          <FormattedHTMLMessage {...i18n.subtitle} />
        </T>
        {provider === "crm" ? (
          <CrmAuthProviderSelectButtons />
        ) : (
          <CrmSelectButtons />
        )}
      </BaseContent>
    )
  );
};

const content = (provider: string | undefined, location: Location<unknown>) => {
  switch (provider) {
    case ProviderType.file:
      return <CsvContent />;
    case ProviderType.gsheet:
      if (location.hash === GsheetUrl.TabSelectionHash) {
        return <GoogleSheetsTabSelectContent />;
      } else {
        return <GoogleSheetsAuthContent />;
      }
    default:
      return null;
  }
};

const i18n = defineMessages({
  title: {
    id: "SourceSelectContent.title",
    defaultMessage: "Connect a Data Source",
  },
  subtitle: {
    id: "SourceSelectContent.subtitle",
    defaultMessage:
      "Select the data source you want to connect to Reveal.<br/>No data will be shared to partners without your consent.",
  },
});
