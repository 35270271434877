import { createAction } from "@reduxjs/toolkit";

import { DashboardActions } from "./types";

export const transformSuggestion = createAction<{
  partnershipId: number;
  suggestionId: number;
}>(DashboardActions.transformSuggestion);

export const addCreatedPartner = createAction<{
  id: number;
  partnerName?: string;
}>(DashboardActions.addCreatedPartner);

export const setSearch = createAction<string>(DashboardActions.setSearch);
