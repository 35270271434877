import { createSelector } from "@reduxjs/toolkit";
import Notification from "models/Notification";
import { RevealStore } from "redux/typing";

export const selectSelectedPartner = createSelector(
  (state: RevealStore) => state.notificationWidget,
  ({ selectedPartner }) => selectedPartner
);

export const selectSelectedPartnershipId = createSelector(
  (state: RevealStore) => state.notificationWidget,
  ({ selectedPartnershipId }) => selectedPartnershipId
);

export const selectUnreadNotificationForPartner = (
  partnershipId: number | null,
  notificationType?: number | null
) =>
  createSelector(
    (state: RevealStore) => state.api.entities.notifications ?? {},
    (state: RevealStore) => state.user.profile.data,
    (state: RevealStore) => state.notificationWidget,
    (notifications, user, { selectedPartner }) => {
      const selectedPartnerId = selectedPartner?.id;
      const emptyResult = {
        notification: null,
        partnerId: null,
        partner: null,
      };
      if (partnershipId === null || selectedPartnerId) {
        return emptyResult;
      }
      const notification =
        Object.values(notifications)
          .filter((notification: Notification) => {
            if (
              !notification.readByUser &&
              notification.partnership.id === partnershipId &&
              (!notificationType ||
                notificationType === notification.notificationType)
            ) {
              return true;
            }
            return false;
          })
          .sort(
            (a: Notification, b: Notification) =>
              b.createdAt.getTime() - a.createdAt.getTime()
          )[0] ?? null;

      if (notification === null) {
        return emptyResult;
      }

      const partner = notification.partnership.getPartner(user);
      return { notification, partner, partnerId: partner.id };
    }
  );
