import { Close } from "@mui/icons-material";
import {
  Avatar,
  Box,
  Chip,
  FormControl,
  FormLabel,
  InputAdornment,
} from "@mui/material";
import { ChevronDown } from "components/icons";
import Dropdown from "components/ui/Dropdown";
import { IDropdownOption } from "components/ui/Dropdown/components/types";
import { TextInput } from "components/ui/TextInput";
import { T } from "components/ui/Typography";
import { getPartnerOptionsWithCustomerAndOpportunityGroups } from "helpers/partnersOptionsWithCustomerAndOpportunityGroups";
import useUserProfile from "hooks/useUserProfile";
import { makeStyles } from "makeStyles";
import PartnerConnection from "models/PartnerConnection";
import Partnership from "models/Partnership";
import Record from "models/Record";
import {
  ChangeEvent,
  MouseEvent,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { defineMessages, FormattedMessage, useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { selectAllPartnerships } from "redux/api/selectors";
import { index } from "redux/api/thunks";

type Props = {
  account?: Record<"crm_accounts"> | null;
  isModalOpen: boolean;
  loading: boolean;
  setSelectedPartnerIds: (ids: string[]) => void;
  selectedPartnerIds: string[];
  records?: Record<"partner_connections">[] | null;
};

export const AddPipelineItemPartnerDropdown = ({
  account,
  isModalOpen,
  loading,
  setSelectedPartnerIds,
  selectedPartnerIds,
  records,
}: Props) => {
  const intl = useIntl();
  const { profile } = useUserProfile();
  const { classes } = useStyles({ hasItems: !!selectedPartnerIds.length });
  const dispatch = useDispatch();
  const [partnerAnchorEl, setPartnerAnchorEl] = useState<HTMLElement | null>();
  const [isPartnerDropdownOpen, setIsPartnerDropdownOpen] = useState(false);
  const partnerships = useSelector(selectAllPartnerships) as Partnership[];
  const [
    isLoadingPartnerConnections,
    setIsLoadingPartnerConnections,
  ] = useState(false);
  const inputRef = useRef<HTMLDivElement>(null);

  const handleOpenPartnerDropdown = (
    _event: ChangeEvent<HTMLInputElement> | MouseEvent<HTMLInputElement>
  ) => {
    setPartnerAnchorEl(inputRef.current);
    setIsPartnerDropdownOpen(true);
  };

  const handleCancelEvent = (event: MouseEvent) => {
    event.stopPropagation();
    event.preventDefault();
  };

  const handleChangePartners = (ids: string[] | null) => {
    setSelectedPartnerIds(ids ?? []);
    setIsPartnerDropdownOpen(false);
  };

  useEffect(() => {
    if (isModalOpen) {
      handleOpenPartnerDropdown({} as MouseEvent<HTMLInputElement>);
    }
  }, [isModalOpen]);

  const loadPartnerConnections = useCallback(async () => {
    if (!account) {
      return;
    }
    setIsLoadingPartnerConnections(true);
    await dispatch(
      index({
        type: "partner_connections",
        options: {
          filters: {
            "user.any_of": profile.id,
            raw_company: account.id,
            archived: "false",
          },
        },
      })
    );
    setIsLoadingPartnerConnections(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [account?.id, dispatch, profile.id]);

  useEffect(() => {
    loadPartnerConnections();
  }, [loadPartnerConnections]);

  const partnerDropdownOptions: IDropdownOption[] = useMemo(() => {
    if (!account || loading || isLoadingPartnerConnections) {
      return [];
    }
    return getPartnerOptionsWithCustomerAndOpportunityGroups(
      partnerships,
      account,
      (
        records?.map(
          (item) => new PartnerConnection({ ...item, id: String(item.id) })
        ) ?? []
      )
        .filter((item) => item.rawCompanyId === account.id)
        .map((item) => ({
          id:
            partnerships.find(
              (partnership) => partnership.id === item.partnershipId
            )?.id ?? 0,
          name: String(item.partnerName ?? ""),
        })),
      profile,
      intl
    );
  }, [
    account,
    intl,
    partnerships,
    profile,
    loading,
    records,
    isLoadingPartnerConnections,
  ]);

  useEffect(() => {
    if (isModalOpen) {
      return;
    }
    setPartnerAnchorEl(null);
  }, [isModalOpen]);

  return (
    <FormControl fullWidth ref={inputRef}>
      <FormLabel className={classes.label}>
        <T bold>
          <FormattedMessage {...i18n.withPartners} />
        </T>
        <T className={classes.alpha}>
          <FormattedMessage {...i18n.partnersDescription} />
        </T>
      </FormLabel>
      <TextInput
        className={classes.input}
        variant="newDesign"
        fullWidth
        InputProps={{
          readOnly: true,
          startAdornment: (
            <InputAdornment position="start">
              <Box
                sx={{
                  display: "flex",
                  flexWrap: "wrap",
                  gap: 0.5,
                  height: "auto",
                }}
              >
                {selectedPartnerIds.map((id) => {
                  const company = partnerships
                    .find((item) => String(item.id) === id)
                    ?.getPartner(profile);
                  if (!company) {
                    return null;
                  }
                  return (
                    <Chip
                      avatar={
                        <Avatar src={company.avatarUrl} variant="rounded" />
                      }
                      className={classes.chip}
                      key={`partner-${id}`}
                      label={<T>{company.name}</T>}
                      onClick={handleCancelEvent}
                      onDelete={() =>
                        setSelectedPartnerIds(
                          selectedPartnerIds.filter((item) => item !== id)
                        )
                      }
                      deleteIcon={<Close />}
                      variant="filled"
                    />
                  );
                })}
              </Box>
            </InputAdornment>
          ),
        }}
        manualStartAdornment
        onChange={handleOpenPartnerDropdown}
        onClick={handleOpenPartnerDropdown}
        placeholder={intl.formatMessage(i18n.searchForPartner)}
        RightIcon={ChevronDown}
      />
      <Dropdown
        isLoading={loading || isLoadingPartnerConnections}
        options={partnerDropdownOptions}
        anchorEl={partnerAnchorEl ?? null}
        isVertical={true}
        onClose={() => setIsPartnerDropdownOpen(false)}
        open={isPartnerDropdownOpen && !!partnerAnchorEl}
        onChange={handleChangePartners}
        size="inherit"
        value={selectedPartnerIds}
        withGroups={!!partnerDropdownOptions[0]?.group}
        withCheckBox
        isMultiselect
      />
    </FormControl>
  );
};

const useStyles = makeStyles<{ hasItems: boolean }>()(
  (theme, { hasItems }) => ({
    alpha: {
      color: theme.palette.alpha500,
    },
    chip: {
      borderRadius: 4,
      backgroundColor: theme.palette.greyLight050,
      display: "flex",
      height: 24,
      padding: "2px 4px",
      alignItems: "center",
      gap: "6px",
      "& .MuiChip-deleteIcon": {
        color: theme.palette.alpha800,
        height: 12,
        width: 12,
      },
      "& .MuiAvatar-root": {
        borderRadius: 2,
        height: 16,
        width: 16,
      },
    },
    input: {
      "& .MuiInputBase-root": hasItems
        ? {
            height: "auto",
            paddingTop: 5,
            paddingBottom: 5,
          }
        : {},
      "& input": {
        display: hasItems ? "none" : "inline",
      },
      "& .MuiInputAdornment-filled": {
        height: "auto",
        maxHeight: "unset",
        width: hasItems ? "100%" : "unset",
      },
    },
    label: {
      color: "unset",
      display: "flex",
      flexDirection: "column",
      gap: "2px",
      marginBottom: 6,
    },
  })
);

const i18n = defineMessages({
  partnersDescription: {
    defaultMessage:
      "By selecting multiple partners, you create separate Collaborations.",
    id: "Pipeline.AddPipelineItemPartnerDropdown.partnersDescription",
  },
  searchForPartner: {
    defaultMessage: "Select Partner",
    id: "Pipeline.AddPipelineItemPartnerDropdown.searchForPartner",
  },
  withPartners: {
    defaultMessage: "With partner(s)",
    id: "Pipeline.AddPipelineItemPartnerDropdown.withPartners",
  },
});
