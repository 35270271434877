import { CircularProgress } from "@mui/material";
import Grid from "@mui/material/Grid";
import withAPI, { WithAPI } from "components/hoc/withAPI";
import Alert from "components/ui/Alert";
import { useStyles as useBaseStyles } from "components/ui/BaseSettingsTab";
import { SWQLContextProvider } from "components/ui/SWQL/SWQLContext";
import { SWQLNode, SWQLTarget } from "components/ui/SWQL/SWQLTypes";
import {
  mapCrmFieldToSWQLField,
  simplifySwqlQuery,
} from "components/ui/SWQL/utils";
import { T } from "components/ui/Typography";
import UnauthorizedInfoDialog from "components/ui/UnauthorizedInfoDialog";
import useAllRecords from "hooks/useAllRecords";
import usePushNotification from "hooks/usePushNotification";
import useSegment from "hooks/useSegment";
import generic from "i18n/generic";
import _ from "lodash";
import { makeStyles } from "makeStyles";
import IntegrationModel from "models/Integration";
import { useCallback, useEffect, useState } from "react";
import {
  defineMessages,
  FormattedHTMLMessage,
  FormattedMessage,
} from "react-intl";
import { Link } from "react-router-dom";

import { TabProps } from "../../types";
import RestrictedAccountsForIntegration from "./RestrictedAccountsForIntegration";

const RestrictedAccountsTab = ({
  createRecord,
  partner,
  partnership,
  removeRecord,
  updateRecord,
  saving,
  afterSave,
  setSaveButtonEnabled,
}: TabProps & WithAPI) => {
  const { classes, cx } = useStyles();
  const { classes: baseClasses } = useBaseStyles();
  const pushNotification = usePushNotification();
  const { track } = useSegment();
  const [
    changedRestrictedAccountsFilters,
    setChangedRestrictedAccountsFilters,
  ] = useState<{
    [integrationId: number]: SWQLNode;
  }>({});
  const hasAtLeastOneIntegration = partnership.integrations?.length > 0;

  useEffect(() => {
    track("Viewed partnership restricted accounts", { partner: partner.name });
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const {
    records: restrictedAccountsFilters,
    refresh,
    loading,
  } = useAllRecords("restricted_accounts_filters", {
    filters: {
      partnership: partnership.id,
    },
    include: ["crm_fields"],
  });

  const handleSave = useCallback(async () => {
    try {
      await Promise.all(
        _.map(
          changedRestrictedAccountsFilters,
          async (query: SWQLNode, integrationId: number) => {
            const restrictedAccountsFilter = _.find(
              restrictedAccountsFilters,
              (item: $TSFixMe) => +item.integration.id === +integrationId
            );
            const simplifiedQuery = simplifySwqlQuery(query);
            if (restrictedAccountsFilter) {
              if (_.isEmpty(simplifiedQuery)) {
                await removeRecord(
                  restrictedAccountsFilter.type,
                  restrictedAccountsFilter.id
                );
              } else {
                await updateRecord(
                  restrictedAccountsFilter.type,
                  restrictedAccountsFilter.id,
                  {
                    query: simplifiedQuery,
                  },
                  {}
                );
              }
            } else {
              await createRecord(
                "restricted_accounts_filters",
                { query: simplifiedQuery },
                {
                  integration: {
                    id: String(integrationId),
                    type: "integrations",
                  },
                  partnership: {
                    id: String(partnership.id),
                    type: "partnerships",
                  },
                }
              );
            }
            setChangedRestrictedAccountsFilters((current) => {
              delete current[integrationId];
              return current;
            });
            refresh();
          }
        )
      );
      pushNotification({ ...generic.edits_saved });
      track("Edited partnership restricted accounts filter", {
        partner: partner.name,
      });
      afterSave();
    } catch (_error) {
      pushNotification("default_error");
    }
  }, [
    afterSave,
    changedRestrictedAccountsFilters,
    createRecord,
    partner.name,
    partnership,
    pushNotification,
    refresh,
    removeRecord,
    restrictedAccountsFilters,
    track,
    updateRecord,
  ]);

  useEffect(() => {
    if (saving) {
      handleSave();
    }
  }, [saving]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(
    // TODO: Add `initialState` and compare it to `changedRestrictedAccountsFilters`
    //       to disable `Save` button when they go back to being the same.
    () =>
      setSaveButtonEnabled(
        _.values(changedRestrictedAccountsFilters).length > 0
      ),
    [changedRestrictedAccountsFilters, setSaveButtonEnabled]
  );

  const summary = (
    <>
      <FormattedHTMLMessage
        {...i18n.restrictedAccountsSummary}
        values={{
          partner: partner.name,
        }}
      />
      <br />
      <FormattedMessage {...i18n.noteStart} />
      &nbsp;
      <Link
        target="_blank"
        to={
          hasAtLeastOneIntegration
            ? `/sources/${partnership.integrations[0].attributes.provider}/${partnership.integrations[0].id}#private-accounts`
            : `/sources`
        }
      >
        <FormattedMessage {...i18n.asDefinedHere} />
      </Link>
      &nbsp;
      <FormattedMessage {...i18n.noteEnd} />
    </>
  );

  /// Not accessible for Ghost partnerhips

  if (partnership.isGhost()) {
    return <UnauthorizedInfoDialog callBackUrl={"/partnerships"} />;
  }

  if (loading) {
    return (
      <>
        <div className={classes.titleContainer}>
          <T bodyBig>{summary}</T>
        </div>
        <div className={classes.circularProgressContainer}>
          <CircularProgress />
        </div>
      </>
    );
  }

  return (
    <>
      {hasAtLeastOneIntegration ? (
        <Grid
          item
          xs={12}
          className={cx(baseClasses.row, classes.titleContainer)}
        >
          <T bodyBig>
            <FormattedHTMLMessage
              {...i18n.restrictedAccountsAre}
              values={{
                partner: partner.name,
              }}
            />
          </T>
        </Grid>
      ) : (
        <Alert>
          <Link to={`/partnerships/${partnership.id}/settings#data-sources`}>
            <FormattedMessage
              {...i18n.noSource}
              values={{ partner: partner.name }}
            />
          </Link>
        </Alert>
      )}
      {_.map(partnership.integrations, (integration: IntegrationModel) => {
        const { query: existingQuery, crmFields } = _.defaults(
          _.find(
            restrictedAccountsFilters,
            (item: $TSFixMe) => item.integration.id === integration.id
          ),
          { query: {}, crmFields: [] }
        );
        const query =
          changedRestrictedAccountsFilters[integration.id] || existingQuery;
        return (
          <Grid
            item
            xs={12}
            className={cx(baseClasses.row, classes.row)}
            key={integration.id}
          >
            <SWQLContextProvider
              integrationId={integration.id}
              enableDateTimes={false}
              rootNode={query}
              onRootUpdate={(node: SWQLNode) => {
                setChangedRestrictedAccountsFilters((current) => ({
                  ...current,
                  [integration.id]: node,
                }));
              }}
              fields={crmFields.map(mapCrmFieldToSWQLField)}
              swqlTarget={[SWQLTarget.RawCompany]}
            >
              <RestrictedAccountsForIntegration
                integration={integration}
                partnership={partnership}
              />
            </SWQLContextProvider>
          </Grid>
        );
      })}
    </>
  );
};

const useStyles = makeStyles()((theme) => ({
  row: {
    display: "flex",
  },
  titleContainer: {
    paddingLeft: theme.spacing(1),
  },
  circularProgressContainer: {
    display: "flex",
    justifyContent: "space-around",
    marginTop: theme.spacing(4),
  },
}));

const i18n = defineMessages({
  restrictedAccountsSummary: {
    id: "RestrictedAccountsTab.restrictedAccountsSummary",
    defaultMessage: `
      You can define below by setting up <b>{partner}'s restricted accounts</b>
      which accounts will never be shared with {partner}.`,
  },
  noteStart: {
    id: "RestrictedAccountsTab.noteStart",
    defaultMessage: "Note: Private accounts",
  },
  asDefinedHere: {
    id: "RestrictedAccountsTab.asDefinedHere",
    defaultMessage: "as defined here",
  },
  noteEnd: {
    id: "RestrictedAccountsTab.noteEnd",
    defaultMessage:
      "will also never be shared with any of your Reveal patners.",
  },
  restrictedAccountsAre: {
    id: "RestrictedAccountsTab.restrictedAccountsAre",
    defaultMessage: `<b>{partner}'s restricted accounts</b> are companies`,
  },
  noSource: {
    id: "RestrictedAccountsTab.noSource",
    defaultMessage:
      "To set restricted accounts rules you first need to share a data source with {partner}",
  },
});

export default withAPI(RestrictedAccountsTab);
