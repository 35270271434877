import _ from "lodash";

export const snakeCaseNameForNearboundAccountField = (
  fieldname: string,
  path: string[]
) => `${path[0]}${fieldname.split(path[0])[1].split(path[1])[0]}${path[1]}`;

export const snakeCaseWithDoubleUnderscore = (fieldname: string) => {
  const doubleUnderscoreKey = `_double_underscore_${new Date().getTime()}_`;
  const altered = fieldname.replace("__", doubleUnderscoreKey);
  return _.snakeCase(altered).replace(doubleUnderscoreKey, "__");
};

export const camelCaseWithDoubleUnderscore = (fieldname: string) => {
  const doubleUnderscoreKey = `DoubleUnderscore${new Date().getTime()}`;
  const altered = fieldname.replace("__", doubleUnderscoreKey);

  const camelCaseAltered = _.camelCase(altered).replace(
    new RegExp(doubleUnderscoreKey, "g"),
    "__"
  );

  return camelCaseAltered;
};
