import CircularProgress from "@mui/material/CircularProgress";
import { makeStyles } from "makeStyles";

const useStyles = makeStyles()((theme) => ({
  root: {
    position: "fixed",
    width: "100%",
    height: "100%",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    zIndex: 2000,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: theme.palette.grey500,
  },
  progress: {
    position: "relative",
    zIndex: 2,
  },
  logoContainer: {
    position: "absolute",
    zIndex: 1,
    backgroundColor: theme.palette.midnight,
    borderRadius: 30,
  },
  logo: {
    height: 62,
    width: 62,
    padding: 13,
  },
}));

export const SplashScreen = () => {
  const { classes } = useStyles();
  return (
    <div className={classes.root}>
      <CircularProgress
        color="primary"
        size={74}
        className={classes.progress}
      />
      <div className={classes.logoContainer}>
        <img
          className={classes.logo}
          src={"/images/reveal-icon-purple-square.png"}
          alt="Loading Reveal"
        />
      </div>
    </div>
  );
};

export default SplashScreen;
