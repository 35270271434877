import {
  AnyAction,
  createAsyncThunk,
  isFulfilled,
  isRejected,
  ThunkDispatch,
} from "@reduxjs/toolkit";
import { AxiosResponse } from "axios";
import config from "config";
import Fuse from "fuse.js";
import sentry from "helpers/sentry";
import _ from "lodash";
import CompanyPayingFeatureSubscription, {
  PayingFeature,
} from "models/CompanyPayingFeatureSubscription";
import { PartnershipStatus } from "models/Partnership";
import { isPartnership_JSONAPIResource, JSONAPIResource } from "models/types";
import { index, indexAll, retreive } from "redux/api/thunks";
import {
  initializePartnersToAccept,
  initializePartnersToShare,
} from "redux/onboarding/thunks";
import { loadUnreadConversations } from "redux/pipeline/thunks";
import { RevealStore } from "redux/typing";
import { refreshToken } from "redux/user/thunks";
import JSONAPIService from "services/JSONAPIService";
import { JSONAPIOptions } from "services/types";

import { fields, included } from "./constants";
import { InitActions, Settings } from "./typing";

export const fetchSettings = createAsyncThunk(
  InitActions.fetchSettings,
  async (): Promise<Settings> => {
    const service = new JSONAPIService("settings");
    const response = await service.index();
    const terms_of_use_version =
      String(response.data.data[0]?.attributes?.terms_of_use_version) ?? "";
    return { terms_of_use_version };
  }
);

export const initializePartnershipsAndCompanyNames = createAsyncThunk(
  InitActions.initialize,
  async (_: undefined, thunkAPI) => {
    const state = thunkAPI.getState() as RevealStore;
    const userId = state.user.profile.data.id;
    const companyId = state.user.profile.data.company?.id;

    const indexAllPartnershipAction = await thunkAPI.dispatch(
      indexAll({
        type: "partnerships",
        options: { fields, include: included, page: { size: 100 } },
        concurrency: 5,
      })
    );

    if (isRejected(indexAllPartnershipAction)) {
      throw new Error("Cannot initialize dashboard");
    }

    const companyNames: Record<
      string,
      string
    > = indexAllPartnershipAction.payload.included
      .filter((item) => item.type === "companies")
      .reduce((current, value) => {
        const name = value.attributes?.name;
        if (name) {
          current[value.id] = String(name);
        }
        return current;
      }, {} as Record<string, string>);

    let index = null;
    if (companyId) {
      const items: {
        id: number;
        partnerName: string;
      }[] = indexAllPartnershipAction.payload.data.reduce((current, item) => {
        if (isPartnership_JSONAPIResource(item)) {
          const initiatorId = item.relationships.initiator_company.data.id;
          const destId = item.relationships.dest_company.data?.id;
          if (+initiatorId === companyId && destId && destId in companyNames) {
            current.push({ id: +item.id, partnerName: companyNames[destId] });
          } else if (
            destId === String(companyId) &&
            initiatorId in companyNames
          ) {
            current.push({
              id: +item.id,
              partnerName: companyNames[initiatorId],
            });
          } else if (item.attributes?.requested_company_name) {
            current.push({
              id: +item.id,
              partnerName: String(item.attributes.requested_company_name),
            });
          }
        }
        return current;
      }, [] as { id: number; partnerName: string }[]);
      index = new Fuse(items, {
        includeScore: true,
        keys: ["partnerName"],
        threshold: 0.2,
      });
    }
    const sortedIds = sortByFavorites(
      indexAllPartnershipAction.payload.data,
      userId
    );
    const acceptedIds = filterAccepted(indexAllPartnershipAction.payload.data);
    return {
      partnershipIds: sortedIds,
      upsidePartnershipIds: sortedIds.filter((id) => acceptedIds.includes(id)),
      index,
    };
  }
);

export const setup = async (
  dispatch: ThunkDispatch<RevealStore, any, AnyAction>
) => {
  await Promise.all([
    dispatch(
      indexAll({
        type: "roles",
        options: {
          include: ["permissions", "requires_permission"],
        },
      })
    ),
    dispatch(indexAll({ type: "dollar_exchange_rates" })),
  ]);
  if (config().appName === "partners") {
    dispatch(loadUsers());
    dispatch(loadAdminUsers());
    dispatch(initializePartnersToAccept());
    dispatch(initializePartnersToShare());
    dispatch(initializePartnershipsAndCompanyNames());
    dispatch(loadPartnershipTags());
    dispatch(loadPartnershipsGetIntro());
    await dispatch(loadCompanyPayingFeatureSubscriptions());
    dispatch(loadUnreadConversations());
  }
};

export const initStore = createAsyncThunk(
  InitActions.initStore,
  async (value: void, thunkAPI) => {
    try {
      const refreshTokenResult = await thunkAPI.dispatch(refreshToken());
      await thunkAPI.dispatch(fetchSettings());
      if (
        isFulfilled(refreshTokenResult) &&
        !refreshTokenResult.payload.isAnonymous
      ) {
        await setup(
          thunkAPI.dispatch as ThunkDispatch<RevealStore, any, AnyAction>
        );
      }
    } catch (error) {
      if (error instanceof Error) {
        sentry.reportError(error);
      }
    }
  }
);

export const loadCompanyPayingFeatureSubscriptions = createAsyncThunk(
  InitActions.loadCompanyPayingFeatureSubscriptions,
  async (_args: void, thunkAPI) => {
    const state = thunkAPI.getState() as RevealStore;

    const options = {
      include: ["company_paying_feature_subscriptions"],
    } as JSONAPIOptions;

    const retreiveCompanyPayingFeatureSubscriptions = await thunkAPI.dispatch(
      retreive({
        id: state.user.profile.data.company.id,
        type: "companies",
        options,
      })
    );

    if (isRejected(retreiveCompanyPayingFeatureSubscriptions)) {
      throw new Error("Cannot load company paying feature subscriptions");
    }

    const response = (retreiveCompanyPayingFeatureSubscriptions.payload.data
      .relationships?.company_paying_feature_subscriptions.data ??
      []) as JSONAPIResource<string>[];
    const companyPayingFeatureSubscriptionIds = response.map(
      (item: { id: string }) => +item.id
    );
    setHasMultiTenantEnabled(state, companyPayingFeatureSubscriptionIds);
    return { companyPayingFeatureSubscriptionIds };
  }
);

export const loadPartnershipTags = createAsyncThunk(
  InitActions.loadPartnershipTags,
  async (_: undefined, thunkAPI) => {
    const indexAllPartnershipTagAction = await thunkAPI.dispatch(
      indexAll({
        type: "partnership_tags",
      })
    );

    if (isRejected(indexAllPartnershipTagAction)) {
      throw new Error("Cannot load partnership tags");
    }

    return {
      partnershipTagIds: indexAllPartnershipTagAction.payload.data.map(
        (value: JSONAPIResource) => +value.id
      ),
    };
  }
);

export const loadPartnershipsGetIntro = createAsyncThunk(
  InitActions.loadPartnershipsGetIntro,
  async (_: undefined, thunkAPI) => {
    const result = await thunkAPI.dispatch(
      index({
        type: "sync_to_crm_settings",
        options: {
          filters: {
            target_crm_element: 3,
          },
          fields: { sync_to_crm_settings: ["partnership_ids_to_sync"] },
        },
      })
    );
    if (isRejected(result)) {
      throw new Error("Cannot load the partnership_ids_to_sync");
    }

    const response = result.payload as AxiosResponse;
    return {
      partnershipGetIntroIds:
        response.data[0]?.attributes?.partnership_ids_to_sync,
    };
  }
);

// Retrieving all users with a license
export const loadUsers = createAsyncThunk(
  InitActions.loadUsers,
  async (_: undefined, thunkAPI) => {
    const state = thunkAPI.getState() as RevealStore;
    const profile = state.user.profile.data;

    const indexAllUsersAction = await thunkAPI.dispatch(
      indexAll({
        type: "users",
        options: {
          filters: {
            company: profile.company.id,
            hasLicense: true,
          },
          page: { size: 100 },
        },
      })
    );

    if (isRejected(indexAllUsersAction)) {
      throw new Error("Cannot load users");
    }

    return {
      userIds: indexAllUsersAction.payload.data.map(
        (value: JSONAPIResource) => +value.id
      ),
    };
  }
);

export const loadAdminUsers = createAsyncThunk(
  InitActions.loadAdminUsers,
  async (_: undefined, thunkAPI) => {
    const state = thunkAPI.getState() as RevealStore;
    const profile = state.user.profile.data;

    const indexAdminUsersAction = await thunkAPI.dispatch(
      indexAll({
        type: "users",
        options: {
          filters: {
            company: profile.company.id,
            hasRole: "company.admin",
            hasLicense: true,
          },
          page: { size: 100 },
        },
      })
    );

    if (isRejected(indexAdminUsersAction)) {
      throw new Error("Cannot load admin users");
    }

    return {
      adminUserIds: indexAdminUsersAction.payload.data.map(
        (value: JSONAPIResource) => +value.id
      ),
    };
  }
);

const setHasMultiTenantEnabled = (
  state: RevealStore,
  companyPayingFeatureSubscriptionIds: number[]
) => {
  const companyPayingFeatureSubscriptions =
    state.api.entities.company_paying_feature_subscriptions ?? {};
  const allActiveSubscriptions = companyPayingFeatureSubscriptionIds
    .map((id: number) => companyPayingFeatureSubscriptions[id])
    .filter(Boolean)
    .filter(
      (subscription) => subscription.isActive
    ) as CompanyPayingFeatureSubscription[];
  const allActivePayingFeatures = allActiveSubscriptions.map(
    (subscription) => subscription.payingFeature
  );
  const hasMultiTenantEnabled = allActivePayingFeatures.includes(
    PayingFeature.DedicatedTenant
  );
  window.sessionStorage.setItem(
    "hasMultiTenantEnabled",
    hasMultiTenantEnabled ? "yes" : "no"
  );
};

// Helper to sort partnership list by favorites
const sortByFavorites = (partnerships: JSONAPIResource[], userId: number) => {
  const partnershipList = partnerships.map((partnership) => {
    return {
      id: +partnership.id,
      watcherIds: partnership.attributes?.watcher_ids ?? [],
    } as { id: number; watcherIds: number[] };
  });
  const favoriteIds = _.filter(partnershipList, (partnership) =>
    partnership.watcherIds.includes(userId)
  ).map((partnership) => partnership.id);

  return partnershipList
    .sort(
      (a, b) =>
        Number(favoriteIds.includes(b.id)) - Number(favoriteIds.includes(a.id))
    )
    .map((p) => p.id);
};

// Helper to filter only accepted partnerships
const filterAccepted = (partnerships: JSONAPIResource[]) => {
  const partnershipList = partnerships.map((partnership) => {
    const integrations = partnership.relationships?.integrations.data ?? [];
    return {
      id: +partnership.id,
      status: partnership.attributes?.status ?? "",
      ready: partnership.attributes?.ready ?? false,
      hasIntegrations:
        (partnership.attributes?.has_partner_integration ?? false) &&
        _.isArray(integrations) &&
        (integrations.length > 0 ?? false),
    } as {
      id: number;
      status: PartnershipStatus;
      ready: boolean;
      hasIntegrations: boolean;
    };
  });
  return _.filter(
    partnershipList,
    (partnership) =>
      (partnership.status === PartnershipStatus.Accepted ||
        partnership.status === PartnershipStatus.Ghost) &&
      partnership.ready &&
      partnership.hasIntegrations
  ).map((partnership) => partnership.id);
};
