import { createSvgIcon } from "@mui/material/utils";

import { SvgIcon } from "./types";

const displayName = "Check";

const Check: SvgIcon = createSvgIcon(
  // Using transform to enlarge from provided viewbox="0 0 16 16"
  // to default "0 0 24 24" from `createSvgIcon` -> `SvgIcon`.
  <g fill="none" transform="matrix(1.5,0,0,1.5,0,0)">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M13.6868 4.3131C13.8821 4.50837 13.8821 4.82495 13.6868 5.02021L7.96295 10.7441C7.24699 11.46 6.08619 11.46 5.37022 10.7441L2.9797 8.35354C2.78444 8.15828 2.78444 7.8417 2.9797 7.64644C3.17496 7.45117 3.49154 7.45117 3.68681 7.64644L6.07733 10.037C6.40277 10.3624 6.9304 10.3624 7.25584 10.037L12.9797 4.3131C13.175 4.11784 13.4915 4.11784 13.6868 4.3131Z"
      fill="currentColor"
    />
  </g>,
  displayName
);
Check.displayName = displayName;
export default Check;
