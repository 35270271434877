import { ClassNameMap } from "@mui/material/styles";
import MaterialTooltip from "@mui/material/Tooltip";
// Specially importing `_private.getFontStyles` outside tests because the only way
// to change font styles for Tooltips is via its CSS api.
import { _private as typographyPrivate } from "components/ui/Typography/Typography";
import { makeStyles } from "makeStyles";
import { ReactElement, ReactNode } from "react";
import { useMergedClasses } from "tss-react";

const { getFontStyles, fontFamily } = typographyPrivate;

type StyleProps = {
  placement?:
    | "bottom-end"
    | "bottom-start"
    | "bottom"
    | "left-end"
    | "left-start"
    | "left"
    | "right-end"
    | "right-start"
    | "right"
    | "top-end"
    | "top-start"
    | "top";
};

type Props = StyleProps & {
  children: ReactElement;
  title?: ReactNode;
  arrow?: boolean;
  delay?: number;
  classes?: ClassNameMap;
  hidden?: boolean;
};

const Tooltip = ({
  children,
  title,
  placement = "top",
  arrow = true,
  delay,
  classes: newClasses = {},
  hidden,
  ...props
}: Props) => {
  const { classes: baseClasses } = useStyles({ placement });
  const classes = useMergedClasses(baseClasses, newClasses);

  if (hidden) return children;
  return (
    <MaterialTooltip
      disableInteractive={false}
      classes={classes}
      title={title ?? ""}
      placement={placement}
      arrow={arrow}
      enterDelay={delay}
      enterTouchDelay={delay}
      enterNextDelay={delay}
      {...props}
    >
      {children}
    </MaterialTooltip>
  );
};

export default Tooltip;

// CSS

export const useStyles = makeStyles<StyleProps>()((theme, { placement }) => ({
  arrow: {
    color: theme.palette.midnight,
  },
  tooltip: {
    marginRight: placement === "right" ? theme.spacing(1) : 0,
    borderRadius: 6,
    backgroundColor: theme.palette.midnight,
    color: theme.palette.white,
    maxWidth: 330,
    // Font styles
    ...getFontStyles({ bodySmall: true }),
    fontFamily,
  },
  popper: {
    zIndex: 10000, // 9999 + 1, react-select's dropdown is 9999.
  },
}));
