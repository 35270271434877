import { createSvgIcon } from "@mui/material/utils";

import { SvgIcon } from "./types";

const displayName = "Inbound";

const Inbound: SvgIcon = createSvgIcon(
  <g fill="none" transform="matrix(2,0,0,2,0,0)">
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M5.5 1.46887C7.17661 1.46887 8.64115 2.37922 9.42534 3.73484C9.55497 3.95893 9.84171 4.0355 10.0658 3.90587C10.2899 3.77624 10.3665 3.4895 10.2368 3.26541C9.29198 1.63202 7.5248 0.531372 5.5 0.531372C2.47969 0.531372 0.03125 2.97981 0.03125 6.00012C0.03125 9.02043 2.47969 11.4689 5.5 11.4689C7.5248 11.4689 9.29198 10.3682 10.2368 8.73484C10.3665 8.51075 10.2899 8.224 10.0658 8.09437C9.84171 7.96474 9.55497 8.04132 9.42534 8.26541C8.64115 9.62102 7.17661 10.5314 5.5 10.5314C2.99746 10.5314 0.96875 8.50266 0.96875 6.00012C0.96875 3.49758 2.99746 1.46887 5.5 1.46887ZM5.83146 4.33158C6.01451 4.14852 6.01451 3.85172 5.83146 3.66867C5.6484 3.48561 5.3516 3.48561 5.16854 3.66867L3.16854 5.66867C2.98549 5.85172 2.98549 6.14852 3.16854 6.33158L5.16854 8.33158C5.3516 8.51464 5.6484 8.51464 5.83146 8.33158C6.01451 8.14852 6.01451 7.85172 5.83146 7.66867L4.63166 6.46887H10C10.2589 6.46887 10.4688 6.25901 10.4688 6.00012C10.4688 5.74124 10.2589 5.53137 10 5.53137H4.63166L5.83146 4.33158Z"
      fill="currentColor"
    />
  </g>,
  displayName
);

Inbound.displayName = displayName;
export default Inbound;
