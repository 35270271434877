import Grid from "@mui/material/Grid";
import { isRejected } from "@reduxjs/toolkit";
import BaseLoginPage from "components/ui/BaseLoginPage";
import Button from "components/ui/Button";
import SelectBoxGroup from "components/ui/SelectBoxGroup";
import { T } from "components/ui/Typography";
import { goToNext } from "helpers/goToNext";
import usePushNotification from "hooks/usePushNotification";
import useSegment from "hooks/useSegment";
import useUserProfile from "hooks/useUserProfile";
import generic from "i18n/generic";
import { makeStyles } from "makeStyles";
import User, {
  USER_INTENTION_LABELS,
  USER_ROLE_TO_USER_INTENTION,
  UserIntention,
  UserRole,
} from "models/User";
import { useEffect, useState } from "react";
import { defineMessages, FormattedHTMLMessage } from "react-intl";
import { useDispatch } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import { RevealStore } from "redux/typing";
import { fetchProfileInBackground, updateProfile } from "redux/user/thunks";
import UserService from "services/UserService";

import { LeftPanelContentByRole } from "../../../shared/LeftPanelContentByRole";

export const validateUserIntention = (profile: User, _store: RevealStore) => {
  const service = new UserService();
  if (!service.isImpersonating && !profile.userIntention?.length) {
    return false;
  }
  return true;
};

type Props = {
  skipDemo?: boolean; // will be used to interfere redirecting, to render chili piper in the background
};

const OnboardingUserIntention = ({ skipDemo = true }: Props) => {
  const location = useLocation();
  const history = useHistory();
  const dispatch = useDispatch();
  const { classes } = useStyles();
  const { track } = useSegment();
  const { classes: selectClasses } = useSelectStyles();
  const { profile } = useUserProfile();
  const pushNotification = usePushNotification();

  const [userIntention, setUserIntention] = useState<UserIntention[] | null>(
    null
  );

  const options = profile.role
    ? USER_ROLE_TO_USER_INTENTION[profile.role as UserRole].map((key) => {
        const content = <T textAlign="center">{USER_INTENTION_LABELS[key]}</T>;
        return {
          value: key,
          content,
        };
      })
    : [];

  const handleSubmit = async () => {
    const result = await dispatch(
      updateProfile({
        attributes: { user_intention: userIntention },
      })
    );
    if (isRejected(result)) {
      pushNotification("default_error");
    } else {
      await dispatch(fetchProfileInBackground());
      track("Updated user intentions", { userId: profile.id });
      skipDemo && next();
    }
  };

  const next = () => {
    history.push(goToNext(location, "/"));
  };

  useEffect(() => {
    if (
      profile.userIntention?.length &&
      profile.userRegistrationInfluenceSource &&
      skipDemo
    ) {
      next();
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const contentByRole = LeftPanelContentByRole(profile.role);

  return (
    <BaseLoginPage hideTitle content={contentByRole}>
      <Grid
        container
        direction="column"
        alignContent="center"
        className={classes.root}
      >
        <Grid item xs={12}>
          <T h2 bold textAlign="center">
            <FormattedHTMLMessage {...i18n.title} />
          </T>
          <T textAlign="center">
            <FormattedHTMLMessage {...i18n.subtitle} />
          </T>
        </Grid>
        <Grid item xs={12}>
          <SelectBoxGroup
            allowDeselect
            values={userIntention ?? []}
            onSelect={(value) =>
              setUserIntention((prev) =>
                prev?.includes(value)
                  ? prev.filter((item) => item !== value)
                  : [...(prev ?? []), value]
              )
            }
            options={options}
            orientation="vertical"
            classes={selectClasses}
          />
        </Grid>
        <Grid item xs={12}>
          <Button
            label={generic.next}
            disabled={!userIntention}
            onClick={handleSubmit}
            classes={{ btn: classes.fullWidth }}
          />
        </Grid>
      </Grid>
    </BaseLoginPage>
  );
};

export default OnboardingUserIntention;

// I18N

const i18n = defineMessages({
  title: {
    id: "onboarding.UserIntention.title",
    defaultMessage: "What would you like to do?",
  },
  subtitle: {
    id: "onboarding.UserIntention.subtitle",
    defaultMessage: "Select any of the following choices:",
  },
});

// CSS

const useStyles = makeStyles()((theme) => ({
  root: {
    width: 360,
    rowGap: theme.spacing(3),
    [theme.breakpoints.down("md")]: {
      width: "100%",
    },
  },
  fullWidth: {
    width: "100%",
  },
}));

const useSelectStyles = makeStyles()((theme) => ({
  container: {
    width: 360,
  },
  tile: {
    height: theme.spacing(6),
  },
  inactive: {
    border: `1px solid ${theme.palette.taupe}`,
    "&:hover": {
      border: `1px solid ${theme.palette.midnight}`,
    },
  },
  innerTile: {
    background: theme.palette.ivory,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  selectable: {
    "&:hover": {
      backgroundColor: theme.palette.ivory,
    },
  },
}));
