import { makeStyles } from "makeStyles";
import Highlighter from "react-highlight-words";

type Props = {
  text: string;
  searchWords: string[];
};

const Highlighted = ({ text, searchWords }: Props) => {
  const { classes } = useStyles();

  return (
    <Highlighter
      textToHighlight={text}
      searchWords={searchWords}
      autoescape
      highlightClassName={classes.mark}
    />
  );
};

export default Highlighted;

// CSS

const useStyles = makeStyles()((theme) => ({
  mark: {
    paddingRight: 0,
    paddingLeft: 0,
    background: theme.palette.gold200,
  },
}));
