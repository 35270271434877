import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import Grid from "@mui/material/Grid";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import Button from "components/ui/Button";
import { T } from "components/ui/Typography";
import { makeStyles } from "makeStyles";
import { defineMessages, FormattedMessage } from "react-intl";

import Roles from "./Roles";

type Props = {
  handleClose: () => void;
};

export const RolesDialog = ({ handleClose }: Props) => {
  const { classes } = useStyles();
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));

  return (
    <Dialog open fullScreen={fullScreen} maxWidth="xl" onClose={handleClose}>
      <T h3 bold textAlign="center" className={classes.title}>
        <FormattedMessage {...i18n.title} />
      </T>
      <DialogContent>
        <Roles />
        <Grid
          container
          spacing={1}
          className={classes.container}
          justifyContent="flex-end"
        >
          <Grid item className={classes.buttons}>
            <Button
              label={i18n.close}
              variant="secondary"
              onClick={handleClose}
            />
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
};

/// CSS

const useStyles = makeStyles()((theme) => ({
  title: {
    padding: theme.spacing(3),
  },
  container: {
    paddingTop: 0,
    padding: theme.spacing(3),
  },
  check: {
    width: 16,
    marginRight: 4,
  },
  inactive: {
    backgroundImage: "transparent",
    padding: 2,
  },
  buttons: {
    display: "flex",
    gap: 4,
  },
}));

/// I18N

const i18n = defineMessages({
  title: {
    id: "Users.Roles.Title",
    defaultMessage: "User Roles & Permissions",
  },
  close: {
    id: "Users.RolesDialog.Close",
    defaultMessage: "Close",
  },
});
