import { Box } from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import Toggle from "components/ui/Toggle";
import { T } from "components/ui/Typography";
import muiTheme from "config/theme";
import { makeStyles } from "makeStyles";
import { PartnerRepresentation } from "models/Partnership";
import { defineMessages, FormattedMessage } from "react-intl";
import SlackChannelSelector from "screens/Frontoffice/shared/components/SlackChannelSelector";

import { SetUpSlackIntegrationButton } from "./SetUpSlackIntegrationButton";
import { UpgradeButton } from "./UpgradeButton";

type Props = {
  saving: boolean;
  loading: boolean;
  partner: PartnerRepresentation;
  hasConnectedSlack: boolean;
  hasSlackIntegrationPayingFeature: boolean;
  slackId: string | null;
  setSlackId: SetState<string | null>;
  partnerOwnerShared: boolean;
  isEnabled: boolean;
  setIsEnabled: SetState<boolean>;
};

const SharedChannel = ({
  saving,
  loading,
  hasConnectedSlack,
  hasSlackIntegrationPayingFeature,
  partner,
  slackId,
  setSlackId,
  isEnabled,
  setIsEnabled,
}: Props) => {
  const { classes } = useStyles();

  const handleToggle = (_: any, checked: boolean) => {
    setIsEnabled(checked);
  };

  return (
    <div className={classes.container}>
      <Box display="flex" alignItems="center">
        <Toggle
          checked={isEnabled}
          disabled={!hasSlackIntegrationPayingFeature || !hasConnectedSlack}
          handleChange={handleToggle}
          data-testid="shared-toggle"
          className={classes.toggle}
        />
        <Box ml={1}>
          <T>
            <FormattedMessage
              {...i18n.title}
              values={{ partner: partner.name }}
            />
          </T>
          <T color={muiTheme.palette.alpha500}>
            <FormattedMessage {...i18n.description} />
          </T>
        </Box>
      </Box>
      {loading ? (
        <div>
          <CircularProgress size={15} />
        </div>
      ) : (
        <Box
          display="flex"
          alignItems="center"
          columnGap="8px"
          flexWrap="wrap"
          rowGap="4px"
        >
          <Box display="flex" alignItems="center" gap="10px">
            {hasSlackIntegrationPayingFeature && !hasConnectedSlack && (
              <SetUpSlackIntegrationButton />
            )}
            {!hasSlackIntegrationPayingFeature && <UpgradeButton />}
          </Box>

          {hasSlackIntegrationPayingFeature && hasConnectedSlack && (
            <>
              <SlackChannelSelector
                value={slackId ?? undefined}
                onChange={setSlackId}
                isShared
                hideWarning
                hasUnselectChannelButton
                disabled={saving || !isEnabled}
                error={isEnabled && !slackId}
              />
            </>
          )}
        </Box>
      )}
    </div>
  );
};

export const useStyles = makeStyles()((theme) => ({
  container: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: 16,
  },
  toggle: {
    margin: "0 4px",
  },
}));

const i18n = defineMessages({
  title: {
    id: "CollaborationTab.sharedChannel.title",
    defaultMessage: "Post in shared Slack channel with {partner}",
  },
  description: {
    id: "CollaborationTab.sharedChannel.description",
    defaultMessage:
      "Make sure Sales and Partnership managers from both sides are included in the selected channel",
  },
});

export default SharedChannel;
