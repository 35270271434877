import { createReducer } from "@reduxjs/toolkit";
import Match from "models/Match";

import { loadUnreadConversations, loadView, updateView } from "./thunks";
import { PipelineState } from "./typing";

export default createReducer<PipelineState>(
  {
    view: null,
    ready: false,
    unreadConversations: {
      [Match.PERSPECTIVE_MINE]: 0,
      [Match.PERSPECTIVE_THEIRS]: 0,
    },
  },
  (builder) => {
    // @ts-ignore Type instantiation is excessively deep and possibly infinite.ts(2589)
    builder
      .addCase(loadView.fulfilled, (state, action) => {
        // @ts-ignore
        state.view = action.payload;
        state.ready = true;
      })
      .addCase(updateView.pending, (state, action) => {
        if (state.view) {
          const view = state.view;
          const { filters, columns, sort } = action.meta.arg;
          if (filters !== undefined) {
            view.filters = filters;
          }
          if (sort !== undefined) {
            view.sort = sort;
          }
          if (columns !== undefined) {
            view.columns = columns;
          }
          state.view = view;
        }
      })
      .addCase(updateView.fulfilled, (state, action) => {
        // @ts-ignore
        state.view = action.payload;
      })
      .addCase(loadUnreadConversations.fulfilled, (state, action) => {
        // @ts-ignore
        state.unreadConversations = action.payload;
      });
  }
);
