import { Chip } from "@mui/material";
import { makeStyles } from "makeStyles";
import {
  defineMessages,
  FormattedMessage,
  MessageDescriptor,
} from "react-intl";

type Props = {
  label?: MessageDescriptor;
  onClick?: () => void;
  shape?: "pill" | "default";
  isDisabled?: boolean;
};

export const GoogleButton = ({
  label,
  onClick,
  shape = "default",
  isDisabled = false,
}: Props) => {
  const { classes } = useStyles({ shape });
  return (
    <Chip
      className={classes.googleButton}
      avatar={
        <img
          className={classes.googleIcon}
          src="/images/brands/google-logo.png"
          alt=""
        />
      }
      label={<FormattedMessage {...(label ?? i18n.label)} />}
      onClick={onClick}
      variant="outlined"
      disabled={isDisabled}
    />
  );
};

const useStyles = makeStyles<{ shape: Props["shape"] }>()(
  (theme, { shape }) => ({
    googleButton: {
      alignItems: "center",
      borderRadius: shape === "pill" ? 20 : 4,
      background: "white",
      color: "#3c4043",
      cursor: "pointer",
      display: "flex",
      fontFamily: '"Google Sans",arial,sans-serif',
      fontSize: 14,
      fontWeight: 500,
      height: 40,
      justifyContent: "space-between",
      letterSpacing: "0.25px",
      overflow: "hidden",
      padding: "0 12px",
      textAlign: "center",
      textOverflow: "ellipsis",
      whiteSpace: "nowrap",
      "& > .MuiChip-label": {
        flexGrow: 1,
      },
      "& > .MuiChip-avatar": {
        marginLeft: 0,
        marginRight: 8,
      },
    },
    googleIcon: {
      height: "18px !important",
      width: "18px !important",
    },
  })
);

const i18n = defineMessages({
  label: {
    id: "GoogleButton.label",
    defaultMessage: "Sign in with Google",
  },
});
