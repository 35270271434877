import { Close } from "@mui/icons-material";
import { Box } from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import Button from "components/ui/Button";
import { T } from "components/ui/Typography";
import { makeStyles } from "makeStyles";
import { defineMessages, FormattedMessage } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { selectHasFilterApplied } from "redux/pipeline/selectors";
import { removeAllFiltersExceptView } from "redux/pipeline/thunks";

type PipelineAttributeEmptyRowsViewProps = {
  loading?: boolean;
};

export const PipelineAttributeEmptyRowsView = ({
  loading,
}: PipelineAttributeEmptyRowsViewProps) => {
  const dispatch = useDispatch();
  const { classes } = useStyles();
  const hasFilters = useSelector(selectHasFilterApplied);
  const removeFilters = () => dispatch(removeAllFiltersExceptView());

  const message = hasFilters ? i18n.noRecordsForFilter : i18n.noRecords;

  return (
    <div className={classes.root}>
      {loading ? (
        <CircularProgress />
      ) : (
        <>
          <Box className={classes.text}>
            <T h3 bold>
              <FormattedMessage {...message} />
            </T>
          </Box>
          {hasFilters && (
            <Box className={classes.button}>
              <Button
                label={i18n.clearFilters}
                onClick={removeFilters}
                LeftIcon={Close}
                variant="tertiary"
              />
            </Box>
          )}
        </>
      )}
    </div>
  );
};

export default PipelineAttributeEmptyRowsView;

/// CSS

const useStyles = makeStyles()((theme) => ({
  button: {
    marginTop: theme.spacing(2.5),
  },
  root: {
    display: "flex",
    flexDirection: "column",
    height: "100%",
    alignItems: "center",
    justifyContent: "center",
    padding: theme.spacing(4),
  },
  text: {
    marginBottom: theme.spacing(1),
  },
}));

// I18N

const i18n = defineMessages({
  clearFilters: {
    id: "Pipeline.PipelineAttributeEmptyRowsView.clearFilters",
    defaultMessage: "Clear all filters",
  },
  addToPipeline: {
    id: "Pipeline.PipelineAttributeEmptyRowsView.addToPipeline",
    defaultMessage: "Add to Collaborate",
  },
  noRecords: {
    id: "Pipeline.PipelineAttributeEmptyRowsView.noRecords",
    defaultMessage: "There is no Collaboration yet.",
  },
  noRecordsForFilter: {
    id: "Pipeline.PipelineAttributeEmptyRowsView.noRecordsForFilter",
    defaultMessage: "There are no records matching these filters",
  },
});
