import { ChevronLeft } from "@mui/icons-material";
import { Grid } from "@mui/material";
import { ChevronDown, Link } from "components/icons";
import ProgressPie from "components/icons/ProgressPie";
import Button from "components/ui/Button";
import Tag from "components/ui/Tag";
import { T } from "components/ui/Typography";
import { makeStyles } from "makeStyles";
import {
  COMPANY_ECOSYSTEM_LABELS,
  COMPANY_SIZE_LABELS,
  COMPANY_TYPE_LABELS,
  CompanyEcosystem,
  CompanySize,
  CompanyType,
} from "models/Company";
import { ReactNode, useState } from "react";
import {
  defineMessages,
  FormattedHTMLMessage,
  FormattedMessage,
  useIntl,
} from "react-intl";
import { useSelector } from "react-redux";
import { selectOverlaps } from "redux/dashboard/selectors";
import { overlapToDisplayContent } from "screens/Frontoffice/shared/helpers/overlapToDisplayContent";

import { SelectedCompany } from "../types";

type Props = {
  avatar?: ReactNode;
  selectedCompany: SelectedCompany | null;
  hideDescription?: boolean;
  unfolded?: boolean;
};

export const InviteClarification = ({
  avatar,
  hideDescription = false,
  selectedCompany,
  unfolded = false,
}: Props) => {
  const { formatMessage } = useIntl();
  const { overlaps } = useSelector(selectOverlaps);
  const overlap = selectedCompany
    ? overlaps?.[selectedCompany.id] ??
      (selectedCompany.accountOverlap || selectedCompany.contactOverlap
        ? {
            accountOverlap: selectedCompany.accountOverlap,
            contactOverlap: selectedCompany.contactOverlap,
          }
        : null)
    : null;
  const [toggle, setToggle] = useState(unfolded);
  const { cx, classes } = useStyles();
  const hasOverlap = !!overlap?.accountOverlap;
  const hasContactOverlap = !!overlap?.contactOverlap;
  const displayContent = overlapToDisplayContent(
    hasContactOverlap ? +overlap!.contactOverlap : null
  );
  const companyType = selectedCompany?.companyType;
  const ecosystem = selectedCompany?.ecosystem;
  const companySize = selectedCompany?.size;

  const handleToggle = () => {
    setToggle((value) => !value);
  };

  return (
    <Grid container>
      <Grid item className={classes?.container}>
        <Grid container className={classes.title} marginBottom={toggle ? 1 : 0}>
          <Grid item>
            <T h4 bold>
              {avatar && <>{avatar}&nbsp;&nbsp;</>}
              <FormattedMessage
                {...i18n.about}
                values={{
                  companyName: selectedCompany?.name,
                }}
              />
              &nbsp;&nbsp;
              <Button
                LeftIcon={toggle ? ChevronDown : ChevronLeft}
                onClick={handleToggle}
                variant="quinary"
              />
            </T>
          </Grid>
          <Grid item>
            <Grid container>
              {companyType && companyType !== CompanyType.other && (
                <Grid item className={classes.tagContainer}>
                  <Tag label={COMPANY_TYPE_LABELS[companyType]} />
                </Grid>
              )}
              {ecosystem && ecosystem !== CompanyEcosystem.other && (
                <Grid item className={classes.tagContainer}>
                  <Tag label={COMPANY_ECOSYSTEM_LABELS[ecosystem]} />
                </Grid>
              )}
              {companySize && companySize !== CompanySize._1 && (
                <Grid item className={classes.tagContainer}>
                  <Tag label={COMPANY_SIZE_LABELS[companySize]} />
                </Grid>
              )}
            </Grid>
          </Grid>
        </Grid>
        {toggle && (
          <>
            {selectedCompany?.description && !hideDescription && (
              <Grid container className={classes.description}>
                <Grid item>
                  <T>{selectedCompany.description}</T>
                </Grid>
              </Grid>
            )}
            <Grid container>
              <Grid item>
                <T
                  className={cx(classes.textWithIcon, {
                    [classes.marginBottom]: hasContactOverlap || hasOverlap,
                  })}
                >
                  <a
                    className={classes?.link}
                    href={
                      selectedCompany?.websiteUrl ??
                      `http://${selectedCompany?.domain}`
                    }
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <Link className={classes.icon} />
                    {selectedCompany?.domain}
                  </a>
                </T>
              </Grid>
              {hasOverlap && (
                <T
                  className={cx(classes.textWithIcon, {
                    [classes.marginBottom]: hasContactOverlap,
                  })}
                >
                  <ProgressPie
                    progress={+overlap!.accountOverlap}
                    className={classes.icon}
                  />
                  <FormattedMessage
                    {...i18n.myOverlap}
                    values={{
                      companyName: selectedCompany?.name,
                      value: Math.min(
                        Math.round((+overlap!.accountOverlap ?? 0) * 100),
                        100
                      ),
                    }}
                  />
                </T>
              )}
              {hasContactOverlap && (
                <T className={classes.textWithIcon}>
                  {displayContent.icon && (
                    <displayContent.icon className={classes.icon} />
                  )}
                  <FormattedHTMLMessage
                    {...i18n.myContactOverlap}
                    values={{
                      companyName: selectedCompany?.name,
                      value: formatMessage(displayContent.text),
                    }}
                  />
                </T>
              )}
            </Grid>
          </>
        )}
      </Grid>
    </Grid>
  );
};

const i18n = defineMessages({
  about: {
    id: "partners.inviteClarification.about",
    defaultMessage: "About {companyName}",
  },
  myContactOverlap: {
    id: "partners.inviteClarification.myContactOverlap",
    defaultMessage:
      "Contact overlap with {companyName} is <b>{value}</b> (on a scale from None to Very high)",
  },
  myOverlap: {
    id: "partners.inviteClarification.myOverlap",
    defaultMessage: "{value}% of your CRM overlaps with {companyName}",
  },
});

const useStyles = makeStyles()((theme) => ({
  container: {
    borderRadius: 8,
    width: "100%",
  },
  icon: {
    height: 16,
    width: 16,
    verticalAlign: "middle",
    marginRight: theme.spacing(0.75),
  },
  link: {
    color: theme.palette.midnight,
  },
  marginBottom: {
    marginBottom: theme.spacing(0.5),
  },
  textWithIcon: {
    display: "block",
    width: "100%",
  },
  description: {
    marginBottom: theme.spacing(1.5),
  },
  tagContainer: {
    display: "flex",
    columnGap: 4,
    alignItems: "center",
    flexWrap: "wrap",
    margin: theme.spacing(0.5),
    "& .MuiChip-root": {
      background: theme.palette.greyscale100,
    },
    "& .MuiChip-label": {
      textTransform: "uppercase",
    },
  },
  title: {
    display: "flex",
    justifyContent: "space-between",
  },
}));
