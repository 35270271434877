import AppBar from "@mui/material/AppBar";
import Dialog from "@mui/material/Dialog";
import Toolbar from "@mui/material/Toolbar";
import { Close, Menu } from "components/icons";
import Logo from "components/ui/Logo";
import sections from "components/ui/sidebar/sections";
import SidebarButton from "components/ui/sidebar/SidebarButton";
import SidebarLogoutButton from "components/ui/sidebar/SidebarLogoutButton";
import SidebarProfile from "components/ui/sidebar/SidebarProfile";
import useUserProfile from "hooks/useUserProfile";
import { makeStyles } from "makeStyles";
import { useEffect, useState } from "react";
import { useLocation } from "react-router";

const MobileNavbar = () => {
  const { classes } = useStyles();
  const [open, setOpen] = useState<boolean>(false);
  const { profile } = useUserProfile();
  const location = useLocation();

  useEffect(() => {
    setOpen(false);
  }, [location.pathname]);

  const handleOpen = () => {
    setOpen(true);
  };

  return (
    <>
      <AppBar position="sticky" className={classes.root}>
        <Toolbar className={classes.container}>
          <Logo openSidebar={handleOpen} />
          <div className={classes.menuBtn}>
            <div>
              <Menu onClick={handleOpen} />
            </div>
          </div>
        </Toolbar>
      </AppBar>
      <Dialog fullScreen open={open} classes={{ paper: classes.paper }}>
        <AppBar position="sticky" className={classes.root}>
          <Toolbar className={classes.container}>
            <Logo openSidebar={handleOpen} />
            <div className={classes.menuBtn}>
              <div>
                <Close onClick={() => setOpen(false)} />
              </div>
            </div>
          </Toolbar>
        </AppBar>
        <SidebarProfile profile={profile} expanded />
        <div className={classes.actions}>
          <div className={classes.links}>
            {sections.map(
              (section, index) =>
                section &&
                !section?.hiddenMobile && (
                  <SidebarButton
                    key={`section-${index}`}
                    {...section}
                    fullWidth
                    callback={() => setOpen(false)}
                  />
                )
            )}
          </div>
          <SidebarLogoutButton />
        </div>
      </Dialog>
    </>
  );
};

export default MobileNavbar;

/// CSS

const useStyles = makeStyles()((theme) => ({
  root: {
    background: theme.palette.midnight,
  },
  container: {
    display: "flex",
    flex: "0 0 100%",
    justifyContent: "space-between",
  },
  item: {
    display: "flex",
    gap: 4,
  },
  menuBtn: {
    borderRadius: 999,
    backgroundImage: theme.palette.gradients.blueToGreen,
    padding: 2,
    "& *": {
      backgroundColor: theme.palette.midnight,
      borderRadius: 999,
      padding: 4,
    },
  },
  paper: {
    background: theme.palette.midnight,
  },
  actions: {
    display: "flex",
    flexDirection: "column",
    padding: theme.spacing(1, 2),
    justifyContent: "space-between",
    height: `calc(100% - ${theme.spacing(2)})`,
  },
  links: {
    display: "flex",
    flexDirection: "column",
  },
}));
