import { ClassNameMap, DialogActions, Grid } from "@mui/material";
import { Send } from "components/icons";
import Button from "components/ui/Button";
import generic from "i18n/generic";
import { defineMessages, useIntl } from "react-intl";

import { DialogView, SelectedCompany } from "../types";

type Props = {
  classes?: ClassNameMap<string>;
  dialogView: DialogView;
  handleClose: () => void;
  isSubmitting: boolean;
  selectedCompany?: SelectedCompany | null;
};

export const InviteFormDialogActions = ({
  classes,
  dialogView,
  handleClose,
  isSubmitting,
  selectedCompany,
}: Props) => {
  const intl = useIntl();
  return (
    <DialogActions>
      <Grid container className={classes?.actionContainer}>
        {dialogView !== DialogView.GenericFirstInvite && (
          <Button
            label={generic.cancel}
            onClick={handleClose}
            disabled={isSubmitting}
            variant="secondary"
            data-testid="button-cancel"
            type="button"
          />
        )}
        <Button
          type="submit"
          label={
            dialogView === DialogView.SuggestedPartnerAllAdmins
              ? intl.formatMessage(i18n.sendInviteToSuggestedCompany, {
                  partnerName: selectedCompany?.name,
                })
              : i18n.sendInvite
          }
          disabled={isSubmitting ? true : undefined}
          RightIcon={Send}
          data-testid="button-invite"
        />
      </Grid>
    </DialogActions>
  );
};

const i18n = defineMessages({
  sendInvite: {
    id: "partners.invite.send_invite",
    defaultMessage: "Send invite",
  },
  sendInviteToSuggestedCompany: {
    id: "partners.invite.sendInviteToSuggestedCompany",
    defaultMessage: "Send invite to {partnerName}'s admins",
  },
});
