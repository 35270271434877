import Title from "components/ui/Title";
import { makeStyles } from "makeStyles";

const useStyles = makeStyles()((theme) => ({
  groupTitle: {
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
      alignItems: "flex-start",
    },
  },
  section: {
    [theme.breakpoints.down("sm")]: {
      fontWeight: "bold",
    },
  },
  arrowRight: {
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  label: {
    [theme.breakpoints.down("sm")]: {
      fontSize: 17,
    },
  },
}));

const PartnershipDialogTitle = ({ ...props }) => {
  const { classes } = useStyles();

  return (
    <div className={"partnership-dialog-title"}>
      {/* @ts-expect-error ts-migrate(2741) FIXME: Property 'section' is missing in type '{ classes: ... Remove this comment to see the full error message */}
      <Title classes={classes} {...props} />
    </div>
  );
};

export default PartnershipDialogTitle;
