import Box from "@mui/material/Box";
import { Facet } from "components/ui/Facet";
import { T } from "components/ui/Typography";
import muiTheme from "config/theme";
import _ from "lodash";
import { makeStyles } from "makeStyles";
import { AccountMappingResource } from "redux/accountMapping/types";
import { AccountMappingStandardFieldName } from "screens/Frontoffice/screens/DataTables/screens/AccountMapping/shared/types";

import { CellProps } from "../utils";

type Props = {
  value?: { [name: string]: number };
};

export const OwnersCell = ({ value = {} }: Props) => {
  const { classes } = useStyles();

  if (!value || _.isEmpty(value)) {
    return null;
  }

  var items = Object.keys(value).map(function (key) {
    return { name: key, occurence: value[key] };
  });
  items.sort(sortOwners);
  const mostUsedUser = items.slice(0, 1)[0];
  const otherCount = items.length - 1;

  return (
    <Box className={classes.cellContainer} title={mostUsedUser.name}>
      <T color={muiTheme.palette.comet} noWrap>
        {mostUsedUser.name}
      </T>
      {Boolean(otherCount) && (
        <div className={classes.rightContainer}>
          <Facet isMore value={otherCount} />
        </div>
      )}
    </Box>
  );
};

// Helpers

export const isOwnersCell = (props: CellProps) => {
  const { fieldName, accountType } = props;
  if (
    fieldName === AccountMappingStandardFieldName.leftOwnerName &&
    accountType === AccountMappingResource.leadMatches
  ) {
    return true;
  }
  return false;
};

type Owner = {
  name: string;
  occurence: number;
};

function sortOwners(firstOwner: Owner, secondOwner: Owner) {
  var occurence1 = firstOwner.occurence;
  var occurence2 = secondOwner.occurence;

  var name1 = firstOwner.name.toLowerCase();
  var name2 = secondOwner.name.toLowerCase();

  // Sort by occurence: most used first
  if (occurence1 < occurence2) return 1;
  if (occurence1 > occurence2) return -1;
  // The by alphabetical order
  if (name1 < name2) return -1;
  if (name1 > name2) return 1;
  return 0;
}

// CSS

const useStyles = makeStyles()((theme) => ({
  cellContainer: {
    display: "flex",
    alignItems: "center",
  },
  rightContainer: {
    display: "flex",
    alignItems: "center",
    marginLeft: "auto",
  },
}));
