import { AxiosRequestConfig } from "axios";
import {
  askApiHost,
  resourcesWithV2Enabled,
  servicesMap,
} from "config/constants";
import _ from "lodash";
import Record from "models/Record";
import {
  JSONAPIAttributes,
  JSONAPIIdentifier,
  JSONAPIRelationships,
  JSONAPIResource,
  JSONSerializable,
} from "models/types";
import { stringify } from "query-string";

import BaseHTTPService, { tenantEnabled } from "./BaseHTTPService";
import {
  JSONAPIListResponse,
  JSONAPIOptions,
  JSONAPIResponse,
  JSONAPIServiceOptions,
} from "./types";

function toLinkObject(
  relationshipData:
    | (JSONAPIResource | Record)[]
    | JSONAPIResource
    | Record
    | null
): JSONAPIResource | JSONAPIResource[] | null {
  if (relationshipData) {
    if (_.isArray(relationshipData)) {
      return relationshipData.map(toLinkObject) as JSONAPIResource[];
    }
    return {
      id: String(relationshipData.id),
      type: relationshipData.type,
    };
  }
  return null;
}

const getApiVersion = (resource: string) => {
  const hasTenant = tenantEnabled();
  if (hasTenant && resourcesWithV2Enabled.includes(resource)) {
    return "v2";
  }
  return "v1";
};

export default class JSONAPIService<
  R extends string = string
> extends BaseHTTPService {
  resource_name: R;
  resource_path: string;

  constructor(resource: R, serviceOptions?: JSONAPIServiceOptions) {
    super(
      `${
        servicesMap[serviceOptions?.url_resource ?? resource] || askApiHost
      }/api/${getApiVersion(resource)}/${(
        serviceOptions?.url_resource ?? resource
      ).replace(/_/g, "-")}`
    );
    this.resource_name = resource;
    this.resource_path = resource.replace(/_/g, "-");
  }

  findRelatedRecord(
    { id, type }: JSONAPIIdentifier,
    { included }: { included?: JSONAPIResource[] }
  ) {
    if (!included) {
      return undefined;
    }
    return included.find((record) => record.id === id && record.type === type);
  }

  buildUrlWithOptions(url: string, options: JSONAPIOptions) {
    const {
      include,
      fields,
      filters,
      page,
      sort,
      recaptcha_token,
      include_owner_options,
    } = _.defaults(options, {
      include: [],
      filters: {},
      page: {},
      fields: {},
    });
    let params = {} as { [param: string]: string };
    if (_.keys(filters).length) {
      _.keys(filters).map((key: string) => {
        if (filters[key]) {
          let filterName = `filter[${key}]`;
          let filterValue = filters[key];
          if (_.isArray(filterValue)) {
            filterValue = filterValue.join(",");
          }
          params[filterName] = String(filterValue);
        }
        return params;
      });
    }
    if (_.keys(page).length) {
      if (page.number !== undefined) {
        params["page[number]"] = String(page.number);
      }
      if (page.size !== undefined) {
        params["page[size]"] = String(page.size);
      }
      if (page.offset !== undefined) {
        params["page[offset]"] = String(page.offset);
      }
      if (page.limit !== undefined) {
        params["page[limit]"] = String(page.limit);
      }
    }
    if (_.isArray(sort)) {
      if (sort.length) {
        params["sort"] = sort.join(",");
      }
    } else if (sort !== undefined && sort.orderBy) {
      params["sort"] =
        (sort.order.toLowerCase() === "desc" ? "-" : "") + sort.orderBy;
    }
    _.each(fields, (resourceFields: string[], resourceType: string) => {
      params[`fields[${resourceType}]`] = resourceFields.join(",");
    });
    if (include.length) {
      params.include = include.join(",");
    }
    if (recaptcha_token) {
      params["recaptcha_token"] = recaptcha_token;
    }
    if (include_owner_options !== undefined) {
      params["include_owner_options"] = String(include_owner_options);
    }
    if (_.keys(params).length) {
      url += `?${stringify(params)}`;
    }
    return url;
  }

  describe<T>(
    useOptionsMethod = true,
    options: { config?: AxiosRequestConfig } & JSONAPIOptions = {}
  ) {
    if (useOptionsMethod) {
      return this.authOptions<T>(
        this.buildUrlWithOptions("", options),
        options.config === undefined ? {} : options.config
      );
    }
    return this.authGet<T>(
      this.buildUrlWithOptions("/options/", options),
      options.config === undefined ? {} : options.config
    );
  }

  index(options: JSONAPIOptions = {}, config: AxiosRequestConfig = {}) {
    return this.authGet<JSONAPIListResponse<R>>(
      this.buildUrlWithOptions("/", options),
      config
    );
  }

  get<T = JSONAPIResponse<R>>(
    record_id: number,
    options: { config?: AxiosRequestConfig } & JSONAPIOptions = {}
  ) {
    return this.authGet<T>(
      this.buildUrlWithOptions(`/${record_id}/`, options),
      options.config === undefined ? {} : options.config
    );
  }

  create(
    attributes: JSONAPIAttributes,
    relationships: {
      [relation: string]: JSONAPIIdentifier | JSONAPIIdentifier[] | null;
    },
    options: JSONAPIOptions = {}
  ) {
    const payload = {
      type: this.resource_name,
      attributes,
    } as JSONSerializable & JSONAPIResource<R>;
    if (relationships) {
      const preparedRelationships = {} as JSONAPIRelationships;
      Object.keys(relationships).forEach((relation_name) => {
        preparedRelationships[relation_name] = {
          data: toLinkObject(relationships[relation_name]),
        };
      });
      payload.relationships = preparedRelationships;
    }
    return this.authPost<JSONAPIResponse<R>>(
      this.buildUrlWithOptions("/", options),
      { data: payload },
      {}
    );
  }

  update(
    record_id: number,
    attributes: JSONAPIAttributes,
    relationships: {
      [relation: string]: JSONAPIIdentifier | JSONAPIIdentifier[] | null;
    } = {},
    options: { config?: AxiosRequestConfig } & JSONAPIOptions = {}
  ) {
    const payload = {
      type: this.resource_name,
      attributes,
      id: String(record_id),
    } as JSONAPIResource & JSONSerializable;
    if (relationships) {
      const preparedRelationships = {} as {
        [relation: string]: {
          data: JSONAPIResource | JSONAPIResource[] | null;
        };
      };
      Object.keys(relationships).forEach((relation_name) => {
        preparedRelationships[relation_name] = {
          data: toLinkObject(relationships[relation_name]),
        };
      });
      if (!_.isEmpty(preparedRelationships)) {
        payload.relationships = preparedRelationships;
      }
    }

    return this.authPatch<JSONAPIResponse<R>>(
      this.buildUrlWithOptions(`/${record_id}/`, options),
      {
        data: payload,
      },
      options.config === undefined ? {} : options.config
    );
  }

  delete(record_id: number) {
    return this.authDelete(`/${record_id}/`, {});
  }

  fetchRelated<T = any>(
    record_id: number,
    relation_name: string,
    options: JSONAPIOptions = {},
    config: AxiosRequestConfig = {}
  ) {
    return this.authGet<T>(
      this.buildUrlWithOptions(
        `/${record_id}/${relation_name.replace("_", "-")}`,
        options
      ),
      config
    );
  }

  add_related(
    record_id: number,
    relation_name: string,
    related: JSONAPIIdentifier | JSONAPIIdentifier[]
  ) {
    const payload = related as JSONAPIIdentifier & JSONSerializable;
    return this.authPost(
      `/${record_id}/relationships/${relation_name.replace("_", "-")}/`,
      {
        data: payload,
      },
      {}
    );
  }

  removeRelated(
    record_id: number,
    relation_name: string,
    related: JSONAPIIdentifier | JSONAPIIdentifier[]
  ) {
    return this.authDelete(
      `/${record_id}/relationships/${relation_name.replace("_", "-")}/`,
      {
        data: { data: related },
      }
    );
  }

  rawGet = <T>(
    path: string,
    options: { config?: AxiosRequestConfig } & JSONAPIOptions = {}
  ) => {
    return this.authGet<T>(
      this.buildUrlWithOptions(path, options),
      options.config ?? {}
    );
  };

  rawUpload(id: number, subPath: string, files: { [name: string]: File } = {}) {
    const payload = new FormData();
    Object.keys(files).forEach((key) => payload.append(key, files[key]));
    return this.authPut(`/${id}${subPath}`, payload, {});
  }

  rawPost = <T>(
    id: number | "",
    subPath: string,
    payload: JSONSerializable,
    options: { config?: AxiosRequestConfig } & JSONAPIOptions = {}
  ) => {
    return this.authPost<T>(
      this.buildUrlWithOptions(`/${id}${subPath}`, options),
      payload,
      options.config === undefined ? {} : options.config
    );
  };

  rawPatch = <T>(
    id: number | "",
    subPath: string,
    payload: JSONSerializable,
    options: { config?: AxiosRequestConfig } & JSONAPIOptions = {}
  ) => {
    return this.authPatch<T>(
      this.buildUrlWithOptions(`/${id}${subPath}`, options),
      payload,
      options.config === undefined ? {} : options.config
    );
  };

  rawPut = (
    id: number | "",
    subPath: string,
    payload: JSONSerializable,
    options: { config?: AxiosRequestConfig } & JSONAPIOptions = {}
  ) => {
    return this.authPut(
      this.buildUrlWithOptions(`/${id}${subPath}`, options),
      payload,
      options.config === undefined ? {} : options.config
    );
  };

  rawPatchBulk = (
    data: JSONSerializable,
    options: { config?: AxiosRequestConfig } & JSONAPIOptions = {}
  ) => {
    const conf =
      options.config === undefined
        ? ({} as AxiosRequestConfig)
        : options.config;
    return this.authPatch(
      this.buildUrlWithOptions("/bulk/", options),
      data,
      conf
    );
  };

  rawDelete = (
    id: number | "",
    prefixPath: string,
    suffixPath: string,
    options: {
      config?: AxiosRequestConfig;
      data?: JSONSerializable;
    } & JSONAPIOptions = {}
  ) => {
    const conf =
      options.config === undefined
        ? ({} as AxiosRequestConfig)
        : options.config;
    if (options.data) {
      conf.data = options.data;
    }
    return this.authDelete(
      this.buildUrlWithOptions(`/${prefixPath}${id}${suffixPath}/`, options),
      conf
    );
  };
}
