import DialogManager from "components/ui/DialogManager";

import { UpdatePartnershipOwnerForm } from "./UpdatePartnershipOwnerForm";

type UpdatePartnershipOwnerDialogProps = {
  userName: string;
  partnershipIds: number[];
  callback: () => void;
  handleClose: () => void;
};

export const UpdatePartnershipOwnerDialog = ({
  userName,
  partnershipIds,
  callback,
  handleClose,
}: UpdatePartnershipOwnerDialogProps) => {
  return (
    <DialogManager
      isOpen={true}
      handleClose={handleClose}
      fullScreenBreakpoint="sm"
      DialogContentComponent={
        <UpdatePartnershipOwnerForm
          handleClose={handleClose}
          userName={userName}
          partnershipIds={partnershipIds}
          callback={callback}
        />
      }
    />
  );
};
