import Dialog from "@mui/material/Dialog";
import Grid from "@mui/material/Grid";
import LinearProgress from "@mui/material/LinearProgress";
import { isFulfilled } from "@reduxjs/toolkit";
import withAPI, { WithAPI } from "components/hoc/withAPI";
import Alert from "components/ui/Alert";
import Button from "components/ui/Button";
import { T } from "components/ui/Typography";
import { ProviderType } from "config/constants";
import getFirstValueFromURL from "helpers/getFirstValueFromURL";
import { LocationDescriptor } from "history";
import useAllRecords from "hooks/useAllRecords";
import useSegment from "hooks/useSegment";
import useUserProfile from "hooks/useUserProfile";
import { debounce, isEmpty } from "lodash";
import { makeStyles } from "makeStyles";
import CrmCredential from "models/CrmCredential";
import { useCallback, useEffect, useState } from "react";
import { defineMessages, FormattedMessage } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import { selectAllPartnerships } from "redux/api/selectors";
import { retreive } from "redux/api/thunks";
import { fetchProfileInBackground } from "redux/user/thunks";
import JSONAPIService from "services/JSONAPIService";
import { JSONAPIResponse } from "services/types";

import { isCrmFileOAuthProviderType } from "../helpers/types";

type Props = WithAPI & {
  onboarding?: boolean;
  notReadyText:
    | string
    | {
        id: string;
        defaultMessage: string;
      };
  notReadyHash?: string;
};

const LoadingDataDialog = ({
  onboarding = false,
  notReadyText,
  notReadyHash,
}: Props) => {
  const { classes } = useStyles();
  const { profile } = useUserProfile();
  const { track } = useSegment();
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const [pollCount, setPollCount] = useState(0);
  const {
    records: crmCredentials,
    refresh: refreshCrmCredentials,
  }: { records: CrmCredential[]; refresh: () => void } = useAllRecords(
    "crm_credentials"
  );
  const partnerships = useSelector(selectAllPartnerships);

  const openLoading = location.hash === "#loading";
  const openNotReady = notReadyHash ? location.hash === notReadyHash : false;
  const company = profile.company;
  const noPartnership = profile.hasNoPartnership(partnerships);

  const isNew = getFirstValueFromURL(location, "init") === "true";
  const ghostPartnershipId = getFirstValueFromURL(location, "isGhost");

  const hasStatusRules = company.mappedIntegrationAttributes
    ? company.mappedIntegrationAttributes.includes("status")
    : false;
  const hasStrategicAccounts = company.mappedIntegrationAttributes
    ? company.mappedIntegrationAttributes.includes("starred")
    : false;

  const handleClose = () => {
    history.replace({ search: "", hash: "" });
    setPollCount(0);
  };

  const redirectToLoading = () => {
    const { key, ...state } = location;
    history.push({ ...state, hash: "#loading" });
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedRefreshCrmCredentials = useCallback(
    debounce(refreshCrmCredentials, 1000),
    [refreshCrmCredentials]
  );
  useEffect(() => {
    if (openLoading && pollCount < 20) {
      const timeout = setTimeout(async () => {
        if (
          crmCredentials.some(
            (credential) =>
              !credential.hasEmptyConfig() && !credential.integrationId
          )
        ) {
          debouncedRefreshCrmCredentials();
          setPollCount(pollCount + 1);
          return;
        }
        const service = new JSONAPIService(
          ghostPartnershipId ? "ghost_integrations" : "integrations"
        );
        const filters: Record<string, any> = ghostPartnershipId
          ? {
              ghost_partnership_id: +ghostPartnershipId,
            }
          : {
              company: company.id,
            };
        const provider = getFirstValueFromURL(location, "provider");
        const integrationId = getFirstValueFromURL(location, "integrationId");
        if (provider && !ghostPartnershipId) {
          filters["provider"] = provider;
        }
        const response = await service.index({ filters });
        if (
          (!!integrationId &&
            !response.data.data.some((item) => item.id === integrationId)) ||
          (!integrationId && response.data.data.length <= 0) // when it is the only one of it's kind
        ) {
          setPollCount(pollCount + 1);
          return;
        }
        await dispatch(fetchProfileInBackground());
        const data = response.data.data;
        const integration = integrationId
          ? data.find((item) => item.id === integrationId) ??
            data[data.length - 1]
          : data[data.length - 1];
        let hasMappingRules = false;
        if (isCrmFileOAuthProviderType(provider)) {
          const result = await dispatch(
            retreive({
              id: Number(integration.id),
              type: ghostPartnershipId
                ? "ghost_source_configurations"
                : "source_configurations",
            })
          );
          if (isFulfilled(result)) {
            const response = result.payload as JSONAPIResponse;
            hasMappingRules = !isEmpty(
              response.data.attributes?.raw_company_custom_mapping
            );
          }
        }
        if (!!provider) {
          profile.identify();
        }
        if (ghostPartnershipId) {
          isNew &&
            track("Connected data source - Offline Account Mapping", {
              crm: ProviderType.gsheet,
            });
          history.push({
            search: `integration=${integration.id}&provider=${provider}${
              isNew ? "&init=true" : ""
            }`,
            hash:
              isCrmFileOAuthProviderType(provider) && !hasMappingRules
                ? "#column-mapping-private"
                : "#status-rules",
          });
        } else if (!onboarding) {
          history.push({
            search: `integration=${integration.id}&provider=${provider}${
              isNew ? "&init=true" : ""
            }`,
            hash:
              isCrmFileOAuthProviderType(provider) && !hasMappingRules
                ? "#column-mapping"
                : "#status-rules",
          });
        } else {
          const nextLocation: LocationDescriptor = {
            search: `integration=${integration.id}&provider=${provider}&onboarding=true`,
            hash: "",
          };
          if (isCrmFileOAuthProviderType(provider) && !hasMappingRules) {
            nextLocation.hash = "#column-mapping";
          } else if (!hasStatusRules) {
            nextLocation.hash = "#status-rules";
          } else if (!hasStrategicAccounts) {
            nextLocation.hash = "#strategic-accounts";
          } else if (noPartnership) {
            nextLocation.pathname = "/invite";
            nextLocation.search = "?onboarding=true";
          } else {
            nextLocation.search = "";
          }
          history.push(nextLocation);
        }
      }, 1000);
      return () => {
        clearTimeout(timeout);
      };
    }
  }, [pollCount, openLoading, crmCredentials, profile]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (openNotReady) {
      track(`Data not ready: ${notReadyHash}`);
    }
  }, [openNotReady]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Dialog fullWidth={true} maxWidth="sm" open={openLoading || openNotReady}>
      <div className={classes.root}>
        {openLoading && (
          <>
            <T h4 className={classes.title}>
              <FormattedMessage
                {...(pollCount < 20 ? i18n.loadingData : i18n.error)}
              />
            </T>
            {pollCount < 20 ? (
              <div className={classes.loaderContainer}>
                <LinearProgress />
              </div>
            ) : (
              <Button label={i18n.okBtnLabel} onClick={handleClose} />
            )}
          </>
        )}
        {openNotReady && (
          <Grid container direction="column" spacing={3} alignItems="center">
            <Grid item>
              <Alert variant="warning">
                {typeof notReadyText !== "string" ? (
                  <FormattedMessage {...notReadyText} />
                ) : (
                  <span>{notReadyText}</span>
                )}
              </Alert>
            </Grid>
            <Grid item className={classes.actionBtns}>
              <Button
                label={i18n.dismiss}
                onClick={handleClose}
                variant="secondary"
              />
              <Button label={i18n.refresh} onClick={redirectToLoading} />
            </Grid>
          </Grid>
        )}
      </div>
    </Dialog>
  );
};

export default withAPI(LoadingDataDialog);

/// CSS

const useStyles = makeStyles()((theme) => ({
  root: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
    padding: theme.spacing(3),
  },
  loaderContainer: {
    width: "90%",
    padding: theme.spacing(1),
  },
  title: {
    marginBottom: theme.spacing(3),
    padding: theme.spacing(1),
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    padding: theme.spacing(0),
    minWidth: 0,
  },
  closeIcon: {
    fontSize: 32,
    color: theme.palette.midnight,
  },
  actionBtns: {
    display: "flex",
    justifyContent: "flex-end",
    width: "100%",
    columnGap: theme.spacing(1),
  },
}));

/// I18N

const i18n = defineMessages({
  loadingData: {
    id: "Sources.LoadingDialog.loadingData",
    defaultMessage: "Loading your data can take a few seconds…",
  },
  error: {
    id: "Sources.LoadingDialog.error",
    defaultMessage: `An error occurred, please retry later.`,
  },
  okBtnLabel: {
    id: "Sources.LoadingDialog.okBtnLabel",
    defaultMessage: "OK",
  },
  refresh: {
    id: "Sources.LoadingDialog.refresh",
    defaultMessage: "Refresh",
  },
  dismiss: {
    id: "Sources.LoadingDialog.Dismiss",
    defaultMessage: "Dismiss",
  },
});
