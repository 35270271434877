import history from "_history";
import LensIcon from "@mui/icons-material/Lens";
import { Box, useMediaQuery, useTheme } from "@mui/material";
import Skeleton from "@mui/material/Skeleton";
import { Expand } from "components/icons";
import TileAvatar from "components/ui/avatars/TileAvatar";
import Button from "components/ui/Button";
import Tooltip from "components/ui/Tooltip";
import { T } from "components/ui/Typography";
import useUserProfile from "hooks/useUserProfile";
import { makeStyles } from "makeStyles";
import { PayingFeature } from "models/CompanyPayingFeatureSubscription";
import Notification from "models/Notification";
import Partnership from "models/Partnership";
import Record from "models/Record";
import { useEffect, useRef, useState } from "react";
import { useInView } from "react-intersection-observer";
import { defineMessages, FormattedMessage, useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { loadView, updateView } from "redux/accountMapping/thunks";
import { AccountMappingResource } from "redux/accountMapping/types";
import { selectActivePayingFeatures } from "redux/api/selectors";
import { retreive } from "redux/api/thunks";
import { fields, included } from "redux/init/constants";
import { selectUnreadNotificationForPartner } from "redux/notificationWidget/selectors";
import { setPartnerFilter } from "redux/pipeline/thunks";
import PartnershipTags from "screens/Frontoffice/screens/Partners/screens/PartnershipSettings/components/PartnershipTags";
import {
  getLeftIcon,
  getSharingStatuses,
  getTooltipMessage,
} from "screens/Frontoffice/screens/Partners/shared/utils";

import PartnerTileAcceptedContent from "./PartnerTileAcceptedContent";
import PartnerTileActions from "./PartnerTileActions";
import PartnerTilePendingContent from "./PartnerTilePendingContent";

const PARTNER_NAME_MAX_LENGTH = 240;

type Props = {
  record: Partnership;
};

const PartnerTile = ({ record }: Props) => {
  const { profile } = useUserProfile();
  const intl = useIntl();
  const { ref, inView } = useInView();
  const nameRef = useRef<HTMLDivElement>(null);
  const dispatch = useDispatch();
  const partner = record.getPartner(profile) as Record;
  const partnerName: string = partner?.name ?? "";
  const [nameWidth, setNameWidth] = useState(0);
  const { matches, companyNbOfNewProspects } = record?.insights ?? {};
  const isLongName = nameWidth + 2 < PARTNER_NAME_MAX_LENGTH; // Added a tolerance of 2
  const isInitiator = record.isInitiator(profile.company);
  const isAccepted = record.isAccepted();
  const isPaused = record.isPaused();
  const isGhost = record.isGhost();
  const hasOverviewAndOverlaps = record.isOverviewAndOverlapsAvailable();
  const { notification } = useSelector(
    selectUnreadNotificationForPartner(
      record.id,
      Notification.TYPE_REFERRED_ACCOUNT
    )
  );
  const payingFeatures = useSelector(selectActivePayingFeatures);
  const isMatchedAccount = payingFeatures.includes(
    PayingFeature.UseNewAccountMapping
  );
  const { classes, cx } = useStyles({
    hover: hasOverviewAndOverlaps,
    nameWidth,
    hasPipelineButton: profile.ownsPipeline,
    hasNotification: Boolean(notification),
  });
  const { classes: pipelineButtonClasses } = usePipelineButtonStyles();
  const location = useLocation();
  const theme = useTheme();
  const smallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const reduceContent =
    smallScreen ||
    (record.isDemo &&
      (location.pathname === "/invite" ||
        location.pathname === "/invite-private"));
  const displayAcceptedContent = isAccepted || isPaused || isGhost;

  useEffect(() => {
    if (nameRef?.current) {
      const newNameWidth = nameRef.current.offsetWidth || 0;
      if (nameWidth !== newNameWidth) {
        setNameWidth(newNameWidth);
      }
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const clearbitLogo =
    !partner.avatarUrl && record.requestedCompanyDomain && isInitiator
      ? `https://logo.clearbit.com/${record.requestedCompanyDomain}`
      : null;

  const partnershipPathname = `/partnerships/${
    record.isDemo ? "demo" : record.id
  }`;

  const reloadPartnership = () => {
    dispatch(
      retreive({
        id: record.id,
        type: "partnerships",
        options: {
          include: included,
          fields,
        },
      })
    );
  };

  const goToOverview = () => {
    if (hasOverviewAndOverlaps) {
      history.push({
        pathname: `/partnerships/${record.isDemo ? "demo" : record.id}${
          record.isDemo ? "/demo-settings" : "/settings"
        }/overview`,
      });
    }
  };

  const goToAccountMapping = async (
    resourceType: AccountMappingResource,
    kpi: string
  ) => {
    await handleView(resourceType);
    history.push({
      pathname: `${partnershipPathname}/account-mapping`,
      search: `?kpi=${kpi}`,
    });
  };

  const handleView = async (accountType: AccountMappingResource) => {
    dispatch(loadView({ partnership: record, isMatchedAccount }));
    dispatch(
      updateView({
        accountType,
        partnership: record,
        isMatchedAccount,
      })
    );
  };

  const goToPipeline = () => {
    dispatch(setPartnerFilter(record.isDemo ? null : [record.id]));
    history.push({
      pathname: "pipeline",
    });
  };

  const partnerNameLabel = (
    <div ref={nameRef} className={classes.partnerNameContainer}>
      <Tooltip title={isLongName ? "" : partnerName} placement="top">
        <T h3 bold className={classes.partnerNameMargin}>
          {partnerName}
        </T>
      </Tooltip>
    </div>
  );

  const expandButton = (
    <Tooltip title={<FormattedMessage {...i18n.overview} />} placement="top">
      <span>
        <Button
          label=""
          variant="tertiary"
          size="small"
          LeftIcon={Expand}
          onClick={goToOverview}
        />
      </span>
    </Tooltip>
  );

  const topRightAction = (
    <div className={classes.actionsContainer}>
      <PartnerTileActions profile={profile} partnership={record} />
    </div>
  );

  const tags = (
    <div className={classes.tagsContainer}>
      <PartnershipTags
        partnership={record}
        inTile={true}
        reloadPartnership={reloadPartnership}
      />
    </div>
  );

  const onlyCommonCustomers = record.onlyCommonCustomersShared();
  const overlapSharing = getSharingStatuses(true, profile.company, record);
  const newProspectSharing = getSharingStatuses(false, profile.company, record);
  const overlapsAndPipelineButtons = (
    <div className={classes.btnContainer}>
      {inView && !reduceContent && (
        <Box className={cx("showOnHover")}>{expandButton}</Box>
      )}
      <Tooltip
        title={getTooltipMessage(true, overlapSharing, intl)}
        placement="top"
      >
        <div>
          <Button
            LeftIcon={getLeftIcon(overlapSharing)}
            label={i18n.accountOverlapsWithCount}
            labelValues={{ count: matches }}
            size="small"
            onClick={() =>
              goToAccountMapping(
                isMatchedAccount
                  ? AccountMappingResource.matched_accounts
                  : AccountMappingResource.matches,
                onlyCommonCustomers ? "common_customers" : "all_overlaps"
              )
            }
            variant={"septenary"}
          />
        </div>
      </Tooltip>
      {!reduceContent && (
        <Tooltip
          title={getTooltipMessage(false, newProspectSharing, intl)}
          placement="top"
        >
          <div>
            <Button
              LeftIcon={getLeftIcon(newProspectSharing)}
              label={i18n.newProspectsWithCount}
              labelValues={{ count: companyNbOfNewProspects }}
              size="small"
              onClick={() =>
                goToAccountMapping(
                  AccountMappingResource.shared_accounts,
                  "nb_of_new_prospects"
                )
              }
              variant={"septenary"}
            />
          </div>
        </Tooltip>
      )}
      {profile.ownsPipeline && (
        <Tooltip
          title={
            notification ? (
              <FormattedMessage
                {...i18n.partnerReferredAccounts}
                values={{
                  partnerName: partnerName,
                  count: notification.information.number_of_referred_accounts,
                }}
              />
            ) : (
              ""
            )
          }
        >
          <div>
            <Button
              label={i18n.pipeline}
              size="small"
              onClick={goToPipeline}
              variant="septenary"
              LeftIcon={notification ? LensIcon : undefined}
              classes={pipelineButtonClasses}
            />
          </div>
        </Tooltip>
      )}
    </div>
  );

  return (
    <div ref={ref} className={classes.rootTop}>
      <div className={classes.root} onClick={goToOverview}>
        <div className={classes.avatarAndPartnerNameContainer}>
          {inView ? (
            <TileAvatar
              partnership={record}
              company={partner}
              src={clearbitLogo}
            />
          ) : (
            <Skeleton
              variant="rectangular"
              className={classes.logo}
              width={38}
              height={38}
            />
          )}
          {partnerNameLabel}
        </div>
        {!inView && <Skeleton variant="rectangular" height={30} width="100%" />}
      </div>
      {/* Adding the content with actions, which need to be above the tile hovering,
          outside to not trigger it */}
      {inView && (
        <>
          {displayAcceptedContent ? (
            !reduceContent && (
              <PartnerTileAcceptedContent partnership={record} />
            )
          ) : (
            <>
              <PartnerTilePendingContent partnership={record} />
            </>
          )}
        </>
      )}
      {inView && !record.isDemo && topRightAction}
      {inView &&
        !record.isDemo &&
        (displayAcceptedContent || isInitiator) &&
        !reduceContent &&
        tags}
      {inView && hasOverviewAndOverlaps && overlapsAndPipelineButtons}
    </div>
  );
};

// CSS

const useStyles = makeStyles<{
  hover: boolean;
  nameWidth: number;
  hasPipelineButton: boolean;
  hasNotification: boolean;
}>()((theme, { hover, nameWidth, hasPipelineButton, hasNotification }) => ({
  rootTop: {
    display: "flex",
    flexDirection: "column",
    position: "relative",
    "& .showOnHover": {
      display: "none",
    },
    "&:hover .showOnHover": {
      display: hover ? "block" : "none",
    },
  },
  root: {
    display: "flex",
    flexDirection: "column",
    position: "relative",
    height: 117,
    borderRadius: 8,
    padding: 16,
    backgroundColor: theme.palette.ivory,
    border: `0.5px solid ${theme.palette.ivory}`,
    "&:hover": {
      border: hover ? `1px solid ${theme.palette.greyLight200}` : undefined,
      boxShadow: hover ? "0px 0px 0px 3px rgba(10, 21, 27, 0.06)" : undefined,
    },
    cursor: hover ? "pointer" : "default",
    [theme.breakpoints.up("lg")]: {
      minWidth: 296.7,
    },
    [theme.breakpoints.down("lg")]: {
      minWidth: 214,
    },
  },
  actionsContainer: {
    position: "absolute",
    display: "flex",
    alignItems: "center",
    columnGap: "2px",
    top: 16,
    right: 16,
  },
  avatarAndPartnerNameContainer: {
    display: "flex",
    flexDirection: "row",
    columnGap: 12,
    marginBottom: 15,
  },
  partnerNameContainer: {
    maxWidth: 238,
  },
  partnerNameMargin: {
    minHeight: 21.8,
    lineHeight: 2,
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  logo: {
    borderRadius: 8,
  },
  tagsContainer: {
    position: "absolute",
    display: "flex",
    top: 24,
    left: `calc(${84 + nameWidth}px)`, // 84 = 16 + 38 + 12 + 18
  },
  btnContainer: {
    position: "absolute",
    height: "28px",
    display: "flex",
    alignItems: "center",
    columnGap: 4,
    right: 16,
    bottom: 16,
  },
}));

const usePipelineButtonStyles = makeStyles()((theme) => ({
  icon: {
    color: theme.palette.green600,
    fontSize: 6,
  },
}));

// I18N

const i18n = defineMessages({
  overview: {
    id: "partnerTile.overview",
    defaultMessage: "Overview",
  },
  accountOverlapsWithCount: {
    id: "partnerTile.accountOverlapsWithCount",
    defaultMessage: "<b>{count}</b> Account Overlaps",
  },
  newProspectsWithCount: {
    id: "partnerTile.newProspectsWithCount",
    defaultMessage: "<b>{count}</b> New Prospects",
  },
  pipeline: {
    id: "partnerTile.pipeline",
    defaultMessage: "Collaborate",
  },
  partnerReferredAccounts: {
    id: "partnerTile.partnerReferredAccounts",
    defaultMessage:
      "{partnerName} referred {count} {count, plural, one {account} other {accounts}}",
  },
});

export default PartnerTile;
