import { createSvgIcon } from "@mui/material/utils";

import { SvgIcon } from "./types";

const displayName = "FavoriteFilled";

const FavoriteFilled: SvgIcon = createSvgIcon(
  // Using transform to enlarge from provided viewbox="0 0 16 16"
  // to default "0 0 24 24" from `createSvgIcon` -> `SvgIcon`.
  <g fill="none" transform="matrix(1.5,0,0,1.5,0,0)">
    <path
      d="M13.0466 4.01102C12.7349 3.69105 12.3622 3.43674 11.9507 3.2631C11.5391 3.08946 11.0969 3 10.6502 3C10.2035 3 9.76133 3.08946 9.34975 3.2631C8.93818 3.43674 8.56555 3.69105 8.25384 4.01102C8.17883 4.08603 8.10381 4.23605 8.0288 4.31107C7.95379 4.23705 7.87878 4.08703 7.80377 4.01102C7.49206 3.69105 7.11943 3.43674 6.70785 3.2631C6.29628 3.08946 5.8541 3 5.40739 3C4.96069 3 4.51851 3.08946 4.10693 3.2631C3.69536 3.43674 3.32273 3.69105 3.01102 4.01102C2.69105 4.32273 2.43674 4.69536 2.2631 5.10693C2.08946 5.51851 2 5.96069 2 6.40739C2 6.8541 2.08946 7.29628 2.2631 7.70785C2.43674 8.11943 2.69105 8.49206 3.01102 8.80377L8.0288 13.5225L13.0466 8.80077C13.6588 8.15369 14 7.29671 14 6.40589C14 5.51508 13.6588 4.65809 13.0466 4.01102Z"
      fill="currentColor"
    />
  </g>,
  displayName
);

FavoriteFilled.displayName = displayName;
export default FavoriteFilled;
