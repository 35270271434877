import { createSvgIcon } from "@mui/material/utils";

import { SvgIcon } from "./types";

const displayName = "Team";

const Team: SvgIcon = createSvgIcon(
  <g fill="none">
    <path
      d="M18.0526 14.7625C18.1382 14.8958 18.262 14.9999 18.4048 15.0684C19.4964 15.5925 20.2501 16.7082 20.2501 18V20C20.2501 20.4142 20.5859 20.75 21.0001 20.75C21.4143 20.75 21.7501 20.4142 21.7501 20V18C21.7501 15.3766 19.6234 13.25 17.0001 13.25C16.8914 13.25 16.8424 13.3902 16.9246 13.4614C17.3598 13.838 17.7403 14.2763 18.0526 14.7625Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7 14.75C5.20507 14.75 3.75 16.2051 3.75 18V20C3.75 20.4142 3.41421 20.75 3 20.75C2.58579 20.75 2.25 20.4142 2.25 20V18C2.25 15.3766 4.37665 13.25 7 13.25H13C15.6234 13.25 17.75 15.3766 17.75 18V20C17.75 20.4142 17.4142 20.75 17 20.75C16.5858 20.75 16.25 20.4142 16.25 20V18C16.25 16.2051 14.7949 14.75 13 14.75H7Z"
      fill="currentColor"
    />
    <path
      d="M14.8706 9.55748C14.9595 9.3884 15.1009 9.25336 15.2656 9.15651C16.0043 8.72208 16.5001 7.91894 16.5001 7C16.5001 6.08106 16.0043 5.27792 15.2656 4.84349C15.1009 4.74664 14.9595 4.6116 14.8706 4.44252C14.622 3.9701 14.3067 3.53827 13.9372 3.15954C13.8795 3.10037 13.9174 3 14.0001 3C16.2092 3 18.0001 4.79086 18.0001 7C18.0001 9.20914 16.2092 11 14.0001 11C13.9174 11 13.8795 10.8996 13.9372 10.8405C14.3067 10.4617 14.622 10.0299 14.8706 9.55748Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10 9.5C11.3807 9.5 12.5 8.38071 12.5 7C12.5 5.61929 11.3807 4.5 10 4.5C8.61929 4.5 7.5 5.61929 7.5 7C7.5 8.38071 8.61929 9.5 10 9.5ZM10 11C12.2091 11 14 9.20914 14 7C14 4.79086 12.2091 3 10 3C7.79086 3 6 4.79086 6 7C6 9.20914 7.79086 11 10 11Z"
      fill="currentColor"
    />
  </g>,
  displayName
);

Team.displayName = displayName;
export default Team;
