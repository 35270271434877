import { T } from "components/ui/Typography";
import useUserProfile from "hooks/useUserProfile";
import _ from "lodash";
import { makeStyles } from "makeStyles";
import { PayingFeature } from "models/CompanyPayingFeatureSubscription";
import Partnership from "models/Partnership";
import Record from "models/Record";
import { useMemo } from "react";
import { useSelector } from "react-redux";
import {
  selectActivePayingFeatures,
  selectAllPartnerships,
} from "redux/api/selectors";
import { Available360MappingColumns } from "redux/mapping360/defaults";

import PartnerChip from "../PartnerChip";
import { CellProps } from "../utils";

export const DEFAULT_WIDTH = 400;

type IValue = {
  high_win_rate: boolean;
  partner_id: number;
  partner_name?: string;
  partnership_id?: number;
};

type Props = CellProps & {
  width: number;
};

export const NearboundProspectCustomerCell = ({ width, ...rest }: Props) => {
  const value = rest.value as IValue[] | undefined;
  const { profile } = useUserProfile();
  const { classes, cx } = useStyles();
  const payingFeatures = useSelector(selectActivePayingFeatures);
  const allPartnerships = useSelector(selectAllPartnerships) as Partnership[];
  const hasAdvancedAnalyticsPayingFeature = payingFeatures.includes(
    PayingFeature.AdvancedAnalytics
  );
  const partnerships = useMemo(
    () =>
      allPartnerships.filter((partnership) => {
        const dataSharingStatus = partnership.getDataSharingStatus(
          profile.company
        );
        return (
          partnership.isGhost() ||
          Partnership.isSharingWhitespace(dataSharingStatus)
        );
      }),
    [allPartnerships, profile.company]
  );

  const { hiddenCount } = getDisplayedList(value ?? [], width);

  return (
    <div className={classes.root}>
      {_.isEmpty(value) && <T className={classes.emptyCell}></T>}
      {(value ?? []).map((item, index: number) => {
        const partnership = partnerships.find(
          (partnership) => item?.partnership_id === partnership.id
        );
        const partner = partnership?.getPartner(profile) as Record | undefined;

        return (
          <PartnerChip
            key={index}
            partnerName={partner?.name}
            avatarUrl={partner?.avatarUrl}
            isGold={hasAdvancedAnalyticsPayingFeature && item.high_win_rate}
          />
        );
      })}
      {Boolean(hiddenCount) && (
        <div className={cx(classes.countContainer, "partnerPresenceGradient")}>
          <T uppercase textAlign="right" className={classes.countText}>
            +{hiddenCount}
          </T>
        </div>
      )}
    </div>
  );
};

const getItemWidth = (name: string | undefined) => {
  const padding = 4 + 6 + 8;
  const avatar = 24;
  const columnGap = 6;
  return name ? name.length * 7 + padding + avatar + columnGap : 0;
};

const shouldDisplayItem = (
  itemWidth: number,
  currentWidth: number,
  columnWidth: number
) => currentWidth + itemWidth < columnWidth;

const getDisplayedList = (presentPartners: IValue[], columnWidth: number) => {
  let currentWidth = 0;
  let displayedCompanies: IValue[] = [];
  let hiddenCount = 0;
  for (let partner of presentPartners) {
    const itemWidth = getItemWidth(partner.partner_name);
    if (shouldDisplayItem(itemWidth, currentWidth, columnWidth)) {
      currentWidth += itemWidth;
      displayedCompanies.push(partner);
    } else {
      hiddenCount += 1;
    }
  }
  return { displayedCompanies, hiddenCount };
};

export const isNearboundProspectCustomerCell = ({ fieldName }: CellProps) => {
  return fieldName === Available360MappingColumns.customerOf;
};

export const getNearboundProspectCustomerCopyContent = ({
  value,
}: CellProps) => {
  return (
    value.map((partner: { partner_name: string }) => partner.partner_name) || []
  ).join(", ");
};

// I18N

const useStyles = makeStyles()((theme) => ({
  root: {
    position: "relative",
    display: "flex",
    alignItems: "center",
    columnGap: 6,
    width: "100%",
  },
  countContainer: {
    position: "absolute",
    display: "flex",
    height: "100%",
    right: "-10px",
    width: 50,
    alignItems: "center",
    justifyContent: "end",
    paddingRight: 10,
    background: `linear-gradient(270deg, ${theme.palette.ivory} 50.52%, rgba(250, 250, 246, 0) 100%)`,
  },
  countText: {
    borderRadius: 17,
    padding: "3px 6px",
    width: "fit-content",
    backgroundColor: theme.palette.greyscale150,
  },
  emptyCell: {
    marginLeft: theme.spacing(1),
  },
}));
