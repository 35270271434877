import { createSvgIcon } from "@mui/material/utils";

import { SvgIcon } from "./types";

const displayName = "OpenInNew";

const OpenInNew: SvgIcon = createSvgIcon(
  // Using transform to enlarge from provided viewbox="0 0 16 16"
  // to default "0 0 24 24" from `createSvgIcon` -> `SvgIcon`.
  <g fill="none" transform="matrix(1.5,0,0,1.5,0,0)">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3 3.75C3 3.33579 3.33579 3 3.75 3H6C6.27614 3 6.5 2.77614 6.5 2.5C6.5 2.22386 6.27614 2 6 2H3.75C2.7835 2 2 2.7835 2 3.75V12.25C2 13.2165 2.7835 14 3.75 14H12.25C13.2165 14 14 13.2165 14 12.25V10C14 9.72386 13.7761 9.5 13.5 9.5C13.2239 9.5 13 9.72386 13 10V12.25C13 12.6642 12.6642 13 12.25 13H3.75C3.33579 13 3 12.6642 3 12.25V3.75ZM9.08238 2.57765C9.08238 2.85379 9.30624 3.07765 9.58238 3.07765H12.1222L6.0289 9.17097C5.83364 9.36624 5.83364 9.68282 6.0289 9.87808C6.22417 10.0733 6.54075 10.0733 6.73601 9.87808L12.9577 3.65644V6.45293C12.9577 6.72907 13.1815 6.95293 13.4577 6.95293C13.7338 6.95293 13.9577 6.72907 13.9577 6.45293V3.32765C13.9577 2.6373 13.398 2.07765 12.7077 2.07765L9.58238 2.07765C9.30624 2.07765 9.08238 2.30151 9.08238 2.57765Z"
      fill="currentColor"
    />
  </g>,
  displayName
);

OpenInNew.displayName = displayName;
export default OpenInNew;
