import Avatar from "@mui/material/Avatar";
import { ClassNameMap } from "@mui/material/styles";
import { memoUnlessPropsChange } from "helpers/memoUnlessPropsChange";
import _ from "lodash";
import { makeStyles } from "makeStyles";
import { PartnerRepresentation } from "models/Partnership";
import Record from "models/Record";
import { useMergedClasses } from "tss-react";

export type Size = "xxl" | "xl" | "lg" | "md" | "sm" | "xs" | "xxs" | "xxxs";

type StyleProps = {
  size: Size;
};

export type CompanyAvatarProps = Partial<StyleProps> & {
  company?: Record | null | PartnerRepresentation;
  src?: string | null;
  companyName?: string | null;
  companyDomain?: string | null;
  classes?: ClassNameMap;
};

export const CompanyAvatar = memoUnlessPropsChange(
  ["company", "src", "companyName", "classes", "size"],
  ({
    company,
    src,
    companyName,
    companyDomain,
    classes: newClasses,
    size = "md",
    ...rest
  }: CompanyAvatarProps) => {
    const { classes: baseClasses } = useStyles({
      size,
    });
    const classes = useMergedClasses(baseClasses, newClasses ?? {});
    let name = companyName || (company && company.name) || null;
    const logo =
      src ||
      _.get(company, "avatarUrl") ||
      (companyDomain
        ? `https://logo.clearbit.com/${companyDomain}`
        : undefined);

    return (
      <Avatar {...rest} src={logo} className={classes.avatar}>
        {name && (
          <div className={classes.firstLetterDiv}>
            {name.charAt(0).toUpperCase()}
          </div>
        )}
      </Avatar>
    );
  }
);

// CSS Helpers

const avatarSize = {
  xxl: 64,
  xl: 56,
  lg: 44,
  md: 38,
  sm: 28,
  xs: 24,
  xxs: 16,
  xxxs: 12,
};

const textSize = {
  xxl: 28,
  xl: 24,
  lg: 18,
  md: 16,
  sm: 14,
  xs: 12,
  xxs: 10,
  xxxs: 10,
};

const textLineHeight = {
  xxl: 34,
  xl: 31.2,
  lg: 24.3,
  md: 20.8,
  sm: 21,
  xs: 18,
  xxs: 15,
  xxxs: 15,
};

const avatarBorderRadius = {
  xxl: 12,
  xl: 10,
  lg: 8,
  md: 6,
  sm: 4,
  xs: 4,
  xxs: 2,
  xxxs: 1.5,
};

// CSS

const useStyles = makeStyles<StyleProps>()((theme, { size }) => ({
  avatar: {
    width: avatarSize[size],
    height: avatarSize[size],
    borderRadius: avatarBorderRadius[size],
    backgroundColor: theme.palette.taupe500,
  },
  firstLetterDiv: {
    width: "100%",
    height: "100%",
    color: theme.palette.midnight,
    fontSize: textSize[size],
    lineHeight: textLineHeight[size],
    fontWeight: 500,
    display: "flex",
    alignItems: "center",
    justifyContent: "space-around",
  },
}));

export default CompanyAvatar;
