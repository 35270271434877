import { Avatar, AvatarGroup } from "@mui/material";
import Box from "@mui/material/Box";
import { Edit, Plus } from "components/icons";
import Button from "components/ui/Button";
import { isRowClickable } from "helpers/partnerConnectionUtils";
import useUserProfile from "hooks/useUserProfile";
import _ from "lodash";
import { makeStyles } from "makeStyles";
import DiscussionParticipant from "models/DiscussionParticipant";
import PartnerConnection from "models/PartnerConnection";
import Record from "models/Record";
import { useEffect, useRef, useState } from "react";
import { AvailablePipelineColumns } from "redux/pipeline/defaults";

import { CellProps } from "../utils";

type Props = {
  row: Record;
  value: DiscussionParticipant[];
};

export const ParticipantsCell = ({ value = [], row }: Props) => {
  const { classes, cx } = useStyles();
  const containerRef = useRef<HTMLDivElement>(null);
  const [maxAvatars, setMaxAvatars] = useState(0);
  const participants = value.filter(
    (participant) => !participant.isRemoved && !participant.isDeleted
  );
  const { profile } = useUserProfile();

  const participantId =
    participants.filter((item) => item.userId === profile.id)[0]?.id ?? null;
  const isLocked = !profile.isCompanyAdmin() && !Boolean(participantId);

  // dynamic max avatar count computation
  useEffect(() => {
    const containerElement = containerRef.current?.parentElement;
    const calculateMaxAvatars = () => {
      const containerWidth = containerElement?.offsetWidth;
      if (containerWidth) {
        const avatarSize = 12; // 12px is the size of the avatar
        const maxFit = Math.floor((containerWidth - 44) / avatarSize); // 44px is for edit icon and margins
        setMaxAvatars(maxFit);
      }
    };

    calculateMaxAvatars();

    const resizeObserver = new (window as any).ResizeObserver(() => {
      calculateMaxAvatars();
    });

    if (containerElement) {
      resizeObserver.observe(containerElement);
    }

    return () => {
      if (containerElement) {
        resizeObserver.unobserve(containerElement);
      }
    };
  }, []);

  return (
    <Box className={classes.cellContainer} ref={containerRef}>
      <AvatarGroup spacing={4} className={classes.avatarGroup} max={maxAvatars}>
        {participants.map((participant, index) => (
          <Avatar
            alt={_.camelCase(participant.fullName ?? "").toUpperCase()} // to use only 1 initial letter, the first letter of the first name
            key={participant.id}
            src={participant.avatarUrl ?? ""}
            sx={{ zIndex: index }}
            title={participant.fullName ?? undefined}
          >
            {!participant.avatarUrl &&
              participant.fullName?.toUpperCase().charAt(0)}
          </Avatar>
        ))}
      </AvatarGroup>
      {!isLocked && (
        <Box
          className={cx(classes.editIcon, {
            showOnHover: isRowClickable(row as PartnerConnection),
          })}
        >
          {participants.length > 0 ? (
            <Edit />
          ) : (
            <Button
              label={i18n.addParticipants}
              LeftIcon={Plus}
              variant={"tertiary"}
              size="xxxSmall"
            />
          )}
        </Box>
      )}
    </Box>
  );
};

// Helpers

export const isParticipantsCell = (props: CellProps) => {
  const { fieldName } = props;

  return fieldName === AvailablePipelineColumns.discussionParticipants;
};

// CSS

const useStyles = makeStyles()((theme) => ({
  avatarGroup: {
    marginRight: 6,
    "& .MuiAvatarGroup-avatar": {
      backgroundColor: theme.palette.greyscale200,
      // possible to pass a dynamic value for the border color,
      // for ex. check the background color of the current row, using a ref
      border: `1px solid ${theme.palette.ivory} !important`,
      color: theme.palette.midnight,
      fontSize: "8px",
      fontWeight: 500,
      height: 16,
      lineHeight: "8px",
      width: 16,
    },
    "& .MuiAvatarGroup-avatar:first-child": {
      zIndex: 999,
    },
  },
  cellContainer: {
    display: "flex",
    alignItems: "center",
  },
  editIcon: {
    cursor: "pointer",
    display: "none",
    "& svg": {
      color: theme.palette.alpha500,
      width: 12,
      height: 12,
    },
  },
}));

const i18n = {
  addParticipants: {
    id: "ui.datagrid.cellRenderers.ParticipantsCell.addParticipants",
    defaultMessage: "Add participants",
  },
};
