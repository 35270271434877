import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import { Facet } from "components/ui/Facet";
import { T } from "components/ui/Typography";
import useHasPayingFeature from "hooks/useHasPayingFeature";
import useUserProfile from "hooks/useUserProfile";
import _ from "lodash";
import { makeStyles } from "makeStyles";
import { PayingFeature } from "models/CompanyPayingFeatureSubscription";
import { ReactElement } from "react";
import { MessageDescriptor, useIntl } from "react-intl";
import { useSelector } from "react-redux";
import { selectCurrentUser } from "redux/pipeline/selectors";
import {
  allLeads as oldAllLeads,
  allOverlaps as oldAllOverlaps,
} from "screens/Frontoffice/screens/DataTables/screens/AccountMapping/shared/oldQuickFilterPresets";
import {
  allLeads,
  allOverlaps,
} from "screens/Frontoffice/screens/DataTables/screens/AccountMapping/shared/quickFilterPresets";
import {
  FilterType,
  Preset,
} from "screens/Frontoffice/screens/DataTables/shared/types";

import { ExtendedAccountMappingResource } from "./filters/smartView/constants";

export type QuickViewFilter = {
  hideOnSmallScreens?: boolean;
  isButton?: boolean;
  key: string | number;
  presets: Preset;
  label: ReactElement | string;
  name?: string | MessageDescriptor;
  viewType?: ExtendedAccountMappingResource;
};

export type Props = {
  filterList: QuickViewFilter[];
  facets?: $TSFixMe;
  onSelect: (preset: Preset, id: number) => void;
  selectedFilterKey?: string | number;
};

export const BaseQuickViews = ({
  filterList = [],
  facets = null,
  onSelect,
  selectedFilterKey,
}: Props) => {
  const { profile } = useUserProfile();
  const isNewAM = useHasPayingFeature(
    PayingFeature.UseNewAccountMapping,
    profile
  );
  const currentUser = useSelector(selectCurrentUser);
  const hasSaleRole =
    _.get(currentUser, "roleAssignments[0].role", "") === "company.sales";
  const { classes: toggleButtonGroupClasses } = useToggleButtonGroupStyles();
  const { classes: toggleButtonClasses, cx } = useToggleButtonStyles();
  const intl = useIntl();

  return (
    <ToggleButtonGroup
      classes={toggleButtonGroupClasses}
      size="small"
      value={selectedFilterKey}
      exclusive
    >
      {filterList.map((item, index) => (
        <ToggleButton
          key={index}
          value={item.key}
          className={cx({ [toggleButtonClasses.paddingless]: item.isButton })}
          classes={toggleButtonClasses}
          onClick={() => onSelect(item.presets, Number(item.key))}
        >
          <T
            title={
              item.name
                ? typeof item.name === "string"
                  ? item.name
                  : intl.formatMessage(item.name)
                : undefined
            }
          >
            {item.label}
          </T>
          {!hasSaleRole && facets && !_.isEmpty(facets) && (
            <Facet
              value={facets[item.key]}
              valueWithPrivate={
                item.key === (isNewAM ? allOverlaps.key : oldAllOverlaps.key)
                  ? facets["allMatchesWithPrivate"]
                  : item.key === (isNewAM ? allLeads.key : oldAllLeads.key)
                  ? facets["allNewLeadsWithPrivate"]
                  : undefined
              }
              isSelected={item.key === selectedFilterKey}
              isMatches={item.key === allOverlaps.key}
            />
          )}
        </ToggleButton>
      ))}
    </ToggleButtonGroup>
  );
};

// Helpers

export const getUpdatedFiltersAndSort = (
  preset: Preset,
  currentSelectedFilters: FilterType[],
  quickViewFilteredFields: string[]
) => ({
  filter: [
    ...currentSelectedFilters.filter(
      (tableFilter: FilterType) =>
        !quickViewFilteredFields.includes(tableFilter.fieldname)
    ),
    ...preset.filter,
  ],
  sort: preset.sort,
});

// CSS

const useToggleButtonGroupStyles = makeStyles({ name: { ToggleButtonGroup } })(
  (theme) => ({
    root: {
      height: 36,
      borderRadius: 18,
      backgroundColor: theme.palette.ivory,
      [theme.breakpoints.down(1350)]: {
        display: "table",
        margin: theme.spacing(1),
      },
    },
    grouped: {
      height: 28,
      margin: 4,
      border: "none",
      "&:not(:first-child)": {
        borderRadius: 18,
      },
      "&:first-child": {
        borderRadius: 18,
      },
    },
  })
);

const useToggleButtonStyles = makeStyles({ name: { ToggleButton } })(
  (theme) => ({
    root: {
      textTransform: "none",
      padding: "9px 12px",
      columnGap: 8,
      "&:focus": {
        outline: 0,
      },
      [theme.breakpoints.down(1350)]: {
        marginLeft: "4px !important",
      },
      "&:hover": {
        borderRadius: "18px !important",
        backgroundColor: theme.palette.taupe500,
      },
      "& > p": {
        color: theme.palette.midnight,
        maxWidth: 240,
        whiteSpace: "nowrap",
        overflow: "hidden",
        textOverflow: "ellipsis",
        "& > p": {
          overflow: "hidden",
          textOverflow: "ellipsis",
        },
      },
    },
    paddingless: {
      paddingLeft: "0 !important",
      paddingRight: "0 !important",
    },
    selected: {
      borderRadius: "18px !important",
      backgroundColor: `${theme.palette.midnight} !important`,
      "& > p": {
        color: `${theme.palette.offWhite} !important`,
      },
    },
  })
);
