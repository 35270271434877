import { Close } from "@mui/icons-material";
import StarIcon from "@mui/icons-material/Star";
import { Info } from "components/icons";
import Tooltip from "components/ui/Tooltip";
import { T } from "components/ui/Typography";
import useSelectorWithDeepEquality from "hooks/useSelectorWithDeepEquality";
import { makeStyles } from "makeStyles";
import Match from "models/Match";
import PartnerConnection from "models/PartnerConnection";
import { defineMessages, FormattedMessage } from "react-intl";
import { AvailablePipelineColumns } from "redux/pipeline/defaults";
import { selectPerspective } from "redux/pipeline/selectors";

import { CellProps } from "../../utils";
import { NewProspectAccountButton } from "./NewProspectAccountButton";
import { PartnerDropdown } from "./PartnerDropdown";

export const CompanyNameCell = (
  props: Omit<CellProps, "row"> & { row: PartnerConnection }
) => {
  const { classes } = useStyles();
  const { row, value } = props;

  const isStarred = row.rawCompanyStarred;
  const isPartnerConnectionNewProspect =
    typeof row.rawCompanyId !== "number" && !row.rawCompanyId;
  const perspective = useSelectorWithDeepEquality(selectPerspective);
  const hasPrivateAccountExternalParticipantWarning =
    perspective === Match.PERSPECTIVE_MINE &&
    row.hasExternalParticipants &&
    row.privateCollab;

  return (
    <div className={classes.cellContainer}>
      {isStarred && (
        <Tooltip title={<FormattedMessage {...i18n.strategicAccount} />}>
          <StarIcon className={classes.starIcon} data-testid="starIcon" />
        </Tooltip>
      )}
      {!isPartnerConnectionNewProspect && <T noWrap>{value}</T>}
      {isPartnerConnectionNewProspect && (
        <NewProspectAccountButton partnerConnection={row} />
      )}
      <Close className={classes.xIcon} />
      <PartnerDropdown {...props} />
      {hasPrivateAccountExternalParticipantWarning && (
        <Tooltip
          title={
            <FormattedMessage
              {...i18n.privateAccountExternalParticipantWarning}
            />
          }
        >
          <Info className={classes.icon} />
        </Tooltip>
      )}
    </div>
  );
};

export const isPipelineCompanyNameCell = ({ fieldName }: CellProps) => {
  return fieldName === AvailablePipelineColumns.rawCompanyName;
};

// CSS

const useStyles = makeStyles()((theme) => ({
  cellContainer: {
    display: "flex",
    alignItems: "center",
    width: "100%",
    columnGap: theme.spacing(0.5),
  },
  starIcon: {
    color: theme.palette.comet,
    marginLeft: theme.spacing(0.5),
    width: 14,
  },
  xIcon: {
    color: theme.palette.alpha500,
    margin: "0 6px",
    width: 10,
  },
  icon: {
    color: theme.palette.gold300,
    width: 14,
  },
}));

// I18N

const i18n = defineMessages({
  privateAccountExternalParticipantWarning: {
    id:
      "crm.MyPipeline.CellFormatters.privateAccountExternalParticipantWarning",
    defaultMessage:
      "This account was flagged as a private account, but there are external participants in the discussion.",
  },
  strategicAccount: {
    id: "crm.MyPipeline.CellFormatters.strategicAccount",
    defaultMessage: "Strategic account",
  },
});
