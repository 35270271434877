import { makeStyles } from "makeStyles";

type Props = {
  left: React.ReactNode;
  right?: React.ReactNode;
  avatarSrc?: string;
};

const OptionContainer = ({ left, right }: Props) => {
  const { classes } = useStyles();
  return (
    <div className={classes.optionContainer}>
      <div className={classes.optionLeftSide}>{left}</div>
      <div className={classes.optionRightSide}>{right}</div>
    </div>
  );
};

const useStyles = makeStyles()((theme) => ({
  optionContainer: {
    display: "flex",
    justifyContent: "space-between",
    padding: "4px 0",
  },
  optionLeftSide: {
    display: "flex",
    "& > div:first-of-type": {
      marginRight: 6,
    },
  },
  optionRightSide: {
    display: "flex",
    maxWidth: 115,
    gap: 6,
    color: theme.palette.alpha750,
    "& > p": {
      overflow: "hidden",
      whiteSpace: "nowrap",
      textOverflow: "ellipsis",
    },
  },
}));

export default OptionContainer;
