import { Box, CircularProgress } from "@mui/material";
import { Inbound, Outbound } from "components/icons";
import { NewTag } from "components/ui/NewTag";
import ToggleBtnGroup from "components/ui/ToggleBtnGroup";
import { T } from "components/ui/Typography";
import useSelectorWithDeepEquality from "hooks/useSelectorWithDeepEquality";
import { makeStyles } from "makeStyles";
import Match, { PipelineViewType } from "models/Match";
import { useEffect, useState } from "react";
import { defineMessages, FormattedMessage } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import {
  selectPerspective,
  selectUnreadConversations,
} from "redux/pipeline/selectors";
import { setPerspectiveFilter } from "redux/pipeline/thunks";

export const InboundOutboundSelect = () => {
  const [loadingViewType, setLoadingViewType] = useState<PipelineViewType>();
  const perspective = useSelectorWithDeepEquality(selectPerspective);
  const { classes } = useStyles({ viewType: loadingViewType ?? perspective });
  const dispatch = useDispatch();
  const { mine, theirs } = useSelector(selectUnreadConversations);

  const handleSetValue = (value: PipelineViewType) => {
    setLoadingViewType(value);
    dispatch(setPerspectiveFilter(value));
  };

  useEffect(() => {
    if (!!loadingViewType && loadingViewType === perspective) {
      setLoadingViewType(undefined);
    }
  }, [perspective, loadingViewType]);

  return (
    <>
      <ToggleBtnGroup
        disabled={!!loadingViewType}
        items={[
          {
            label: (
              <Box alignItems="center" display="flex" gap="6px">
                {loadingViewType === Match.PERSPECTIVE_MINE ? (
                  <CircularProgress size={12} />
                ) : (
                  <Inbound className={classes.icon} />
                )}
                <T bold>
                  <FormattedMessage {...i18n.inbound} />
                </T>
                {mine > 0 && <NewTag color="danger" count={mine} />}
              </Box>
            ),
            value: Match.PERSPECTIVE_MINE,
          },
          {
            label: (
              <Box alignItems="center" display="flex" gap="6px">
                {loadingViewType === Match.PERSPECTIVE_THEIRS ? (
                  <CircularProgress size={12} />
                ) : (
                  <Outbound className={classes.icon} />
                )}
                <T bold>
                  <FormattedMessage {...i18n.outbound} />
                </T>
                {theirs > 0 && <NewTag color="danger" count={theirs} />}
              </Box>
            ),
            value: Match.PERSPECTIVE_THEIRS,
          },
        ]}
        setValue={handleSetValue}
        value={loadingViewType ?? perspective}
        classes={{ root: classes.toggleButtonGroup }}
      />
    </>
  );
};

const i18n = defineMessages({
  inbound: {
    id: "Pipeline.InboundOutboundSelect.inbound",
    defaultMessage: "On my deals",
  },
  outbound: {
    id: "Pipeline.InboundOutboundSelect.outbound",
    defaultMessage: "On partners' deals",
  },
});

const useStyles = makeStyles<{ viewType: PipelineViewType }>()(
  (theme, { viewType }) => ({
    icon: {
      width: 12,
      height: 12,
      color: theme.palette.midnight,
    },
    inbound: {
      backgroundColor: theme.palette.purpleBrand050,
    },
    outbound: {
      backgroundColor: theme.palette.greenBrand050,
    },
    toggleButtonGroup: {
      backgroundColor: theme.palette.greyLight100,
      height: 28,
      "& .Mui-selected": {
        backgroundColor: `${
          viewType !== Match.PERSPECTIVE_THEIRS
            ? theme.palette.purpleBrand050
            : theme.palette.greenBrand050
        } !important`,
        "& p, & svg": {
          color: `${theme.palette.midnight} !important`,
        },
      },
      "& p, & svg": {
        color: `${theme.palette.alpha500} !important`,
      },
    },
  })
);
