import _ from "lodash";
import { makeStyles } from "makeStyles";
import { Status } from "models/RawOpportunity";
import { FieldType } from "screens/Frontoffice/screens/DataTables/shared/types";

import { FieldDisplay } from "./FieldDisplay";
import { IOpportunity } from "./types";

type NearboundAccountOpportunityTableItemProps = {
  opportunity: IOpportunity;
  openDealFields: {
    [key: string]: FieldType;
  };
};

type IFieldTypeWithValue = FieldType & { value: string };

export const NearboundAccountOpportunityTableItem = ({
  opportunity,
  openDealFields,
}: NearboundAccountOpportunityTableItemProps) => {
  const status = Status.Open;
  const fieldsWithValues = extractFieldValueData(
    openDealFields as {
      [key: string]: IFieldTypeWithValue;
    },
    opportunity
  );
  const { classes } = useOpportunityStyles({
    status,
    numberOfFields: Object.keys(fieldsWithValues).length,
  });

  return (
    <div className={classes.container}>
      <div className={classes.innerContainer}>
        <div className={classes.statusIndicator} />
        <div className={classes.flexAlignCenter}>
          {fieldsWithValues.map((field) => (
            <FieldDisplay
              key={field.label}
              {...{ rawOpportunityField: true, ...field }}
              numberOfFields={fieldsWithValues.length}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

type StyleProps = {
  status: Status;
  numberOfFields: number;
};

const useOpportunityStyles = makeStyles<StyleProps>()(
  (theme, { numberOfFields: n, status }) => ({
    container: {
      display: "inline-block",
      alignSelf: "start",
    },
    innerContainer: {
      display: "flex",
      alignItems: "center",
      borderRadius: 6,
      padding: 4,
      border: `1px solid ${theme.palette.greyscale250}`,
      justifyContent: "space-between",
      "&:hover": {
        backgroundColor: theme.palette.ivory,
      },
    },
    flexAlignCenter: {
      display: "flex",
      alignItems: "center",
    },
    fieldsWrapper: {
      display: "flex",
      alignItems: "center",
    },
    statusIndicator: {
      width: 4,
      height: 24,
      borderRadius: 2,
      marginRight: theme.spacing(1),
      backgroundColor:
        status === Status.Open
          ? theme.palette.purple
          : status === Status.Won
          ? theme.palette.appGreen
          : theme.palette.darkTaupe,
    },
  })
);

const extractFieldValueData = (
  fieldsRaw: {
    [key: string]: IFieldTypeWithValue;
  },
  opportunity: IOpportunity
) => {
  const fields = _.cloneDeep(fieldsRaw);
  for (const field in _.cloneDeep(fields)) {
    const path = fields[field].jsonPath;
    let value = undefined;
    if (!field.includes(String(opportunity.integration_id))) {
      delete fields[field];
      continue;
    }
    if (_.isEmpty(path) || !path) {
      delete fields[field];
      continue;
    }
    const valueObj = _.get(opportunity, path);
    value = valueObj?.displayable_value ?? valueObj?.value;
    if (_.isEmpty(value) || !value) {
      delete fields[field];
      continue;
    }
    fields[field].value = value;
  }

  return Object.values(fields).sort(
    (fieldLeft, fieldRight) =>
      (fieldLeft?.displayIndex ?? 0) - (fieldRight?.displayIndex ?? 0)
  );
};
