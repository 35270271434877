import autobind from "auto-bind";
import axios, {
  AxiosError,
  AxiosInstance,
  AxiosRequestConfig,
  AxiosResponse,
} from "axios";
import { ProviderType } from "config/constants";
import {
  JSONAPIAttributes,
  JSONAPIIdentifier,
  JSONAPIResource,
} from "models/types";
import { stringify } from "query-string";

import NonUserService from "./NonUserService";
import { JSONAPIResponse } from "./types";

export const includes = [
  "company",
  "role_assignments",
  "onboarding_skips",
  "company.company_paying_feature_subscriptions",
];

export interface InvitationValues {
  email: string;
  first_name: string;
  last_name: string;
  role_name?: string;
  company_id: number;
}

export type InvitationURLValues = {
  invitation_url_id: string;
};

export interface RegistrationValues {
  first_name: string;
  last_name: string;
  email: string;
  password: string;
  recaptcha_token: string;
}

interface CompanyValues {}

enum UserEndpoints {
  LOGIN_URL = "/api/login",
  LOGOUT_URL = "/api/logout",
  REFRESH_TOKEN_URL = "/api/jwt-token/refresh",
  OKTA_URL = "/api/okta",
  OKTA_IDP_URL = "/api/okta/identity-provider",
  AVAILABILITY_CHECK_URL = "/api/v1/users/availability",
  ENABLE_MFA = "/api/v1/profile/enable-mfa",
  DISABLE_MFA = "/api/v1/profile/disable-mfa",
}

class UserService {
  static TOKEN_NAME = "user.token";

  axios: AxiosInstance;

  constructor() {
    const config = require("config").default();
    autobind(this);
    this.axios = axios.create({
      baseURL: config.askApiHost,
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        "Accept-Language": "en",
        "JWT-aud": config.appName,
      },
    });
    this.axios.interceptors.response.use(
      (response: AxiosResponse) => response,
      this.intercept401
    );
  }

  get token() {
    return localStorage.getItem(UserService.TOKEN_NAME);
  }

  set token(value) {
    if (value === null) {
      localStorage.removeItem(UserService.TOKEN_NAME);
    } else {
      localStorage.setItem(UserService.TOKEN_NAME, value);
      localStorage.removeItem(NonUserService.NON_USER_FLAG);
    }
  }

  get parsedToken() {
    if (this.token === null) {
      return {};
    }
    try {
      const base64 = this.token
        .split(".")[1]
        .replace(/-/g, "+")
        .replace(/_/g, "/");
      const jsonPayload = decodeURIComponent(
        atob(base64)
          .split("")
          .map(
            (char) => "%" + ("00" + char.charCodeAt(0).toString(16)).slice(-2)
          )
          .join("")
      );
      return JSON.parse(jsonPayload);
    } catch (_) {
      return {};
    }
  }

  getResponseData<T>(response: AxiosResponse<T>): T {
    return response.data;
  }

  getAuth(jsonAPI: boolean = false) {
    const config: AxiosRequestConfig = this.token
      ? { headers: { Authorization: `Bearer ${this.token}` } }
      : { headers: {} };
    if (jsonAPI) {
      config.headers = {
        ...config.headers,
        Accept: "application/vnd.api+json",
        "Content-Type": "application/vnd.api+json",
      };
    }
    return config;
  }

  extractToken<T>(response: AxiosResponse<T>): T & { token: string | null } {
    if (response.headers.authorization) {
      this.token = response.headers.authorization.split("Bearer ")[1];
    }
    return {
      ...response.data,
      token: this.token,
    };
  }

  intercept401<T>(error: AxiosError<T>) {
    if (error.response && error.response.status === 401) {
      if (error.config?.url === UserEndpoints.LOGOUT_URL) {
        this.token = null;
      }
    }
    return Promise.reject(error);
  }

  async login(
    email: string,
    password?: string,
    recaptcha_token?: string,
    otp?: string
  ) {
    const payload = { api_user: { email, password, recaptcha_token, otp } };
    const response = await this.axios.post<{ id: number; email: string }>(
      UserEndpoints.LOGIN_URL,
      payload
    );
    const data = this.extractToken(response);
    return data;
  }

  async logout() {
    const token = localStorage.getItem("user.token");
    if (token) {
      const config = this.getAuth();
      localStorage.removeItem("user.token");
      await this.axios.delete(UserEndpoints.LOGOUT_URL, config);
    }
    window.sessionStorage.removeItem("hasMultiTenantEnabled");
  }

  async refreshToken() {
    const token = localStorage.getItem("user.token");
    if (!token) {
      return false;
    }
    const response = await this.axios.get(
      UserEndpoints.REFRESH_TOKEN_URL,
      this.getAuth()
    );
    return this.extractToken(response);
  }

  /** {{{ Invitation Methods */

  async createInvitation(target: InvitationValues | InvitationURLValues) {
    const config: AxiosRequestConfig = this.getAuth(true);
    const payload = { user: { ...target } };
    const response = await this.axios.post("/api/invitation", payload, config);
    return this.getResponseData(response);
  }

  async invitationTokenDetails(invitation_token: string) {
    const payload = { user: { invitation_token } };
    try {
      const response = await this.axios.post(
        "/api/invitation/validate-token",
        payload
      );
      return response.data;
    } catch (_error) {
      return null;
    }
  }

  async acceptInvitation(invitation_token: string, values: InvitationValues) {
    const payload = {
      user: {
        invitation_token,
        ...values,
      },
    };
    const response = await this.axios.put("/api/invitation", payload);
    return this.extractToken(response);
  }

  /** }}} End of Invitation Methods */

  /** {{{ Reset Password Methods */

  async triggerResetPassword(email: string, recaptcha_token: string) {
    try {
      await this.axios.post("/api/password", {
        api_user: { email, recaptcha_token },
      });
      return true;
    } catch (_error) {
      return false;
    }
  }

  async updatePassword(reset_password_token: string, password: string) {
    const payload = {
      api_user: { reset_password_token, password },
    };
    const response = await this.axios.put("/api/password", payload);
    return this.extractToken(response);
  }

  async isPasswordTokenValid(reset_password_token: string) {
    const payload = { api_user: { reset_password_token } };
    try {
      await this.axios.post("/api/password/validate-token", payload);
      return true;
    } catch (_error) {
      return false;
    }
  }

  /** }}} End of Reset Password Methods */

  async register(data: RegistrationValues) {
    const response = await this.axios.post("/api/register", { api_user: data });
    return this.extractToken(response);
  }

  async confirmEmail(token: string) {
    const response = await this.axios.get(
      "/api/confirmation?" + stringify({ confirmation_token: token })
    );
    this.extractToken(response);
    return response;
  }

  async resendConfirmEmail(email: string) {
    const response = await this.axios.post("/api/confirmation", {
      api_user: { email },
    });
    return this.getResponseData(response);
  }

  async profile() {
    const config = this.getAuth(true);
    const response = await this.axios.get<JSONAPIResponse>(
      `/api/v1/profile?include=${includes.join(",")}`,
      config
    );
    return this.getResponseData(response);
  }

  async updateProfile(
    id: number,
    attributes: JSONAPIAttributes,
    relationships?: {
      [relation: string]: JSONAPIIdentifier | JSONAPIIdentifier[];
    }
  ) {
    const config = this.getAuth(true);
    const payload = {
      data: {
        id: `${id}`,
        type: "users",
        attributes,
        relationships,
      },
    };
    const response = await this.axios.put(
      `/api/v1/users/${id}?include=${includes.join(",")}`,
      payload,
      config
    );
    return this.getResponseData(response);
  }

  async updateCompany(
    id: number,
    company_data: CompanyValues,
    recaptcha_token: string
  ) {
    const config = this.getAuth(true);
    const url = `/api/v1/users/${id}/update-company`;
    const response = await this.axios.put(
      url,
      { ...company_data, recaptcha_token },
      config
    );
    return this.getResponseData(response);
  }

  async updateAvatar(id: number, file: File) {
    const config = this.getAuth(true);
    const payload = new FormData();
    payload.append("avatar", file);
    const response = await this.axios.put(
      `/api/v1/users/${id}/avatar`,
      payload,
      config
    );
    return response;
  }

  async deleteAvatar(id: number) {
    const config = this.getAuth(true);
    const response = this.axios.delete(`/api/v1/users/${id}/avatar`, config);
    return response;
  }

  async requestPermissions() {
    const config = this.getAuth(true);
    const payload = {};
    const response = await this.axios.post(
      `/api/v1/profile/request-access`,
      payload,
      config
    );
    return this.getResponseData(response);
  }

  async oauth(provider: string, params: any) {
    const config = this.getAuth();
    let path = `/api/oauth/${provider}`;
    if (
      provider === ProviderType.slack ||
      provider === ProviderType.partnerStack
    ) {
      path = `/api/${provider}/oauth`;
    }
    return await this.axios.post(path, params, config);
  }

  async revokeOauth(provider: string) {
    const config = this.getAuth();
    return await this.axios.delete(`/api/oauth/${provider}`, config);
  }

  // Sudo

  get isImpersonating() {
    return Boolean(this.parsedToken.admin_id);
  }

  async impersonate(userId: number) {
    const config = this.getAuth();
    const response = await this.axios.post(`/api/sudo/${userId}`, null, config);
    return this.extractToken(response);
  }

  async depersonate() {
    const config = this.getAuth();
    const response = await this.axios.delete(`/api/sudo`, config);
    return this.extractToken(response);
  }

  // Okta

  async resolveOkta(
    access_token: string,
    sourceButton?: string | string[] | null
  ) {
    const response = await this.axios.post(
      UserEndpoints.OKTA_URL,
      {
        access_token,
        ...(sourceButton ? { source_button: sourceButton } : {}),
      },
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    this.extractToken(response);
  }

  async resolveOktaIdP(
    email: string,
    recaptcha_token: string,
    debugMode?: boolean
  ) {
    const regex = /.*@(?<domain>.*)$/gm;
    const match = regex.exec(email);
    const search = stringify({
      domain: match?.groups?.domain,
      recaptcha_token,
    });
    const response = await this.axios.get<
      any,
      AxiosResponse<{ data: null | JSONAPIResource }>
    >(UserEndpoints.OKTA_IDP_URL + "?" + search);
    const data = response.data.data;
    if (data && (data.attributes?.enforced || debugMode)) {
      return data.id;
    }
    return null;
  }

  // Availability
  async verifyAvailability(email: string, recaptcha_token: string) {
    const search = stringify({
      email,
      recaptcha_token,
    });
    const response = await this.axios.get(
      UserEndpoints.AVAILABILITY_CHECK_URL + "?" + search
    );
    return response.data;
  }

  // MFA

  async enableMFA(otp: string) {
    const config = this.getAuth(true);
    const payload = { otp };
    const response = await this.axios.post(
      UserEndpoints.ENABLE_MFA,
      payload,
      config
    );
    return response.data;
  }

  async disableMFA(password: string) {
    const config = this.getAuth(true);
    const payload = { password };
    const response = await this.axios.post(
      UserEndpoints.DISABLE_MFA,
      payload,
      config
    );
    return response.data;
  }
}

export default UserService;
