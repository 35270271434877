import Grid from "@mui/material/Grid";
import { isRejected } from "@reduxjs/toolkit";
import BaseLoginPage from "components/ui/BaseLoginPage";
import Button from "components/ui/Button";
import { TextInput } from "components/ui/TextInput";
import { T } from "components/ui/Typography";
import { goToNext } from "helpers/goToNext";
import usePushNotification from "hooks/usePushNotification";
import useSegment from "hooks/useSegment";
import useUserProfile from "hooks/useUserProfile";
import generic from "i18n/generic";
import _ from "lodash";
import { makeStyles } from "makeStyles";
import User from "models/User";
import { ChangeEvent, useEffect, useState } from "react";
import {
  defineMessages,
  FormattedHTMLMessage,
  MessageDescriptor,
  useIntl,
} from "react-intl";
import { useDispatch } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import { RevealStore } from "redux/typing";
import { fetchProfileInBackground, updateProfile } from "redux/user/thunks";
import UserService from "services/UserService";

export const validateInfluenceSource = (profile: User, _store: RevealStore) => {
  const service = new UserService();
  if (
    !service.isImpersonating &&
    !profile.userRegistrationInfluenceSource &&
    !_.get(profile, "company.viral")
  ) {
    return false;
  }
  return true;
};

type Props = {
  skipDemo?: boolean; // will be used to interfere redirecting, to render chili piper in the background
};

const OnboardingInfluenceSource = ({ skipDemo = true }: Props) => {
  const location = useLocation();
  const history = useHistory();
  const dispatch = useDispatch();
  const { classes } = useStyles();
  const { track } = useSegment();
  const { profile } = useUserProfile();
  const pushNotification = usePushNotification();
  const intl = useIntl();

  const [influenceSource, setInfluenceSource] = useState<string>("");
  const [error, setError] = useState<MessageDescriptor | null>();

  const handleSubmit = async () => {
    if (!influenceSource) {
      setError(i18n.error);
      return;
    }
    const result = await dispatch(
      updateProfile({
        attributes: { registration_influence_source: influenceSource },
      })
    );
    if (isRejected(result)) {
      pushNotification("default_error");
    } else {
      await dispatch(fetchProfileInBackground());
      track("Updated user registration influence source", {
        userId: profile.id,
        registrationInfluenceSource: influenceSource,
      });
      skipDemo && next();
    }
  };

  const next = () => {
    history.push(goToNext(location, "/"));
  };

  useEffect(() => {
    if (
      profile.userIntention?.length &&
      profile.userRegistrationInfluenceSource &&
      skipDemo
    ) {
      next();
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <BaseLoginPage hideTitle subtitle={i18n.leftPanelSubtitle}>
      <Grid
        container
        direction="column"
        alignContent="center"
        className={classes.root}
      >
        <Grid item xs={12}>
          <T h2 bold textAlign="center">
            <FormattedHTMLMessage {...i18n.title} />
          </T>
        </Grid>
        <Grid item xs={12}>
          <TextInput
            error={!!error}
            fullWidth
            value={influenceSource}
            onChange={(e: ChangeEvent<HTMLInputElement>) =>
              setInfluenceSource(e.target.value)
            }
            placeholder={intl.formatMessage(i18n.placeholder)}
            helperText={error && intl.formatMessage(error)}
            variant="tertiary"
          />
        </Grid>
        <Grid item xs={12}>
          <Button
            label={generic.next}
            disabled={!influenceSource}
            onClick={handleSubmit}
            classes={{ btn: classes.fullWidth }}
          />
        </Grid>
      </Grid>
    </BaseLoginPage>
  );
};

export default OnboardingInfluenceSource;

const i18n = defineMessages({
  error: {
    id: "onboarding.InfluenceSource.error",
    defaultMessage: "Please share how you discovered us",
  },
  leftPanelSubtitle: {
    id: "onboarding.InfluenceSource.leftPanelSubtitle",
    defaultMessage: "We want to know...",
  },
  title: {
    id: "onboarding.InfluenceSource.title",
    defaultMessage: "How did you hear about us?",
  },
  placeholder: {
    id: "onboarding.InfluenceSource.placeholder",
    defaultMessage: "Type here...",
  },
});

const useStyles = makeStyles()((theme) => ({
  root: {
    width: 360,
    rowGap: theme.spacing(3),
    [theme.breakpoints.down("md")]: {
      width: "100%",
    },
  },
  fullWidth: {
    width: "100%",
  },
}));
