import { GridCellParams } from "@mui/x-data-grid-premium";
import _ from "lodash";
import { Component, ReactNode } from "react";

import { MatchFieldType, MatchFilterType, MatchSortOrder } from "./enums";

export interface FilterType {
  fieldname: string;
  type: MatchFilterType;
  value: any;
}

export const isFilterType = (value: any): value is FilterType => {
  const valueAsFilterType = value as FilterType;
  return (
    typeof valueAsFilterType.fieldname === "string" &&
    Object.values(MatchFilterType).includes(valueAsFilterType.type)
  );
};

export const isFilterTypeArray = (value: any): value is FilterType[] =>
  _.isArray(value) && value.every(isFilterType);

export interface SortType {
  fieldname: string;
  order: MatchSortOrder;
  fieldtype: MatchFieldType;
}

export interface Preset {
  columns?: string[];
  filter: FilterType[];
  sort: SortType[];
}

export type Facets = Record<string, number>;

export interface RawFieldType {
  name: string;
  label: string;
  private: boolean;
  type: MatchFieldType;
  record_type: string;
  smart_field: boolean;
  fully_imported: boolean;
  partially_shared: boolean;
  disabled: boolean;
  options: {
    [key: string]: string | number;
  };
  partner_id?: number;
  partnership_crm_field_id?: number;
  no_side?: boolean;
  filter?: boolean;
  sort?: boolean;
  column?: boolean;
  allowed_operations?: string[];
  json_path?: string[];
  display_index?: number;
}

export interface FieldType {
  headerAlign?: "left" | "right" | "center";
  label: string;
  isPrivate: boolean;
  type: MatchFieldType;
  isPartiallyShared?: boolean;
  smartField: boolean;
  fullyImported: boolean;
  isDisabled: boolean;
  partnerField: boolean;
  partnershipCrmFieldId?: number;
  noSide: boolean;
  filter: boolean;
  allowedOperations?: string[];
  sort: boolean;
  options: {
    [key: string]: string | number;
  };
  column?: boolean;
  hidden?: boolean;
  isOptionsSorted?: boolean;
  hideHeaderMenu?: boolean;
  jsonPath?: string[];
  displayIndex?: number;
}

export interface PersistedColumnType {
  key: string;
  width: number;
}

export interface ColumnConfigType {
  key: string;
  width: number;
  frozen: boolean;
  fixedPosition?: number;
  removable?: boolean;
  flex?: number;
  colSpan?: number | ((params: GridCellParams) => number | undefined);
  smartField?: boolean;
}

export interface ColumnType extends ColumnConfigType {
  loading?: boolean;
  headerRenderer?: ReactNode;
  formatter?: ReactNode | Component;
  hash?: string;
}

export const asPersistedColumn = (column: ColumnConfigType) =>
  ({
    key: column.key,
    width: column.width,
  } as PersistedColumnType);

export const orderColumns = (columns: ColumnConfigType[]) => [
  ..._.sortBy(
    columns.filter((column) => (column.fixedPosition ?? 0) > 0),
    (column) => column.fixedPosition ?? 0
  ),
  ...columns.filter((column) => column.fixedPosition === undefined),
  ..._.sortBy(
    columns.filter((column) => (column.fixedPosition ?? 0) < 0),
    (column) => -(column.fixedPosition ?? 0)
  ),
];

export const moveColumnAfter = (
  columns: ColumnConfigType[],
  columnName: string,
  afterColumnName: string
): ColumnConfigType[] => {
  const clonedColumns = _.cloneDeep(columns);

  const index = clonedColumns.findIndex((column) => column.key === columnName);
  const afterIndex = clonedColumns.findIndex(
    (column) => column.key === afterColumnName
  );

  if (index !== -1 && afterIndex !== -1) {
    const [movedColumn] = clonedColumns.splice(index, 1);
    clonedColumns.splice(afterIndex + 1, 0, movedColumn);
  }

  return clonedColumns;
};

export const viewToColumns = (
  viewColumns: PersistedColumnType[],
  fields: { [key: string]: FieldType } | undefined,
  config: { [key: string]: ColumnConfigType },
  defaultConfig: (key: string, field: FieldType) => ColumnConfigType,
  sort: (value: ColumnConfigType[]) => ColumnConfigType[]
) => {
  if (fields === undefined) {
    return [];
  }

  const validAndConfiguredColumns = viewColumns
    .filter(Boolean)
    .filter((item: { key: string }) =>
      _.keys(fields)
        .filter((field) => fields[field].column ?? true)
        .includes(item.key)
    )
    .map(
      (column) =>
        ({
          ...(config[column.key] ??
            defaultConfig(column.key, fields[column.key])),
          ...column,
        } as ColumnConfigType)
    );
  const missingColumns = _.values(config).filter(
    (column) =>
      !(column.removable ?? true) &&
      !validAndConfiguredColumns.map((col) => col.key).includes(column.key)
  );

  return sort([...validAndConfiguredColumns, ...missingColumns]);
};
