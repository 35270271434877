import {
  ColumnConfigType,
  FilterType,
  SortType,
} from "screens/Frontoffice/screens/DataTables/shared/types";

export enum Mapping360Actions {
  addView = "mapping360/addView",
  loadView = "mapping360/loadView",
  loadViews = "mapping360/loadViews",
  updateView = "mapping360/updateView",
  selectSourceId = "mapping360/selectSourceId",
}

export interface Mapping360State {
  viewId?: string | null | number;
  viewIds: number[];
  selectedSourceId: number | null;
  ready: boolean;
}

export interface RootStateWithMapping360 {
  mapping360: Mapping360State;
}

export interface Mapping360ViewParameters {
  filters?: FilterType[];
  columns?: ColumnConfigType[];
  sort?: SortType[];
  pinned?: boolean;
  id: number;
}
