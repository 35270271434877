import { Avatar, AvatarGroup, Box, Theme } from "@mui/material";
import { LinkBroken, OpenInNew, Plus } from "components/icons";
import Button from "components/ui/Button";
import PartnerChip from "components/ui/data-grid/PartnerChip";
import Dropdown from "components/ui/Dropdown/components/Dropdown";
import { IDropdownOption } from "components/ui/Dropdown/components/types";
import { NotificationStatus } from "components/ui/Notifications/NotificationSnackbar";
import Tooltip from "components/ui/Tooltip";
import { T } from "components/ui/Typography";
import { Enum, toString } from "config/routes/Pages";
import {
  getPartnerOptionsWithCustomerAndOpportunityGroups,
  IPartnerNameAndId,
} from "helpers/partnersOptionsWithCustomerAndOpportunityGroups";
import usePushNotification from "hooks/usePushNotification";
import useSegment from "hooks/useSegment";
import useUserProfile from "hooks/useUserProfile";
import _ from "lodash";
import { makeStyles } from "makeStyles";
import PartnerConnection from "models/PartnerConnection";
import Partnership, { PartnerRepresentation } from "models/Partnership";
import Record from "models/Record";
import { JSONAPIResource } from "models/types";
import { MouseEvent, useEffect, useState } from "react";
import { defineMessages, FormattedMessage, useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { bulkAddRecords, updateAttributesOnRecord } from "redux/api/actions";
import { selectAllPartnerships } from "redux/api/selectors";
import { NotificationActionType } from "redux/notifications/typing";
import JSONAPIService from "services/JSONAPIService";
import { WebhookEventName, WebhookService } from "services/WebhookService";
import { PipelineEvent, PipelineItemSource } from "tracking";

type Props = {
  row: Record<"nearbound_prospects">;
};

export const Mapping360NearboundProspectsBookmark = ({ row }: Props) => {
  const { profile } = useUserProfile();
  const [pipelinePartners, setPipelinePartners] = useState<
    undefined | IPartnerNameAndId[]
  >(undefined);
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const [loading, setLoading] = useState(false);
  const [partnershipIds, setPartnershipIds] = useState<string[]>([]);
  const [options, setOptions] = useState<IDropdownOption[]>([]);
  const { classes } = useStyles();
  const intl = useIntl();
  const { track } = useSegment();
  const dispatch = useDispatch();
  const partnerships = useSelector(selectAllPartnerships) as Partnership[];
  const pushNotification = usePushNotification();
  const history = useHistory();
  const bookmarkCount = getBookmarkCount(row);

  useEffect(() => {
    setOptions(
      getPartnerOptionsWithCustomerAndOpportunityGroups(
        partnerships,
        row,
        (row.partnerConnectionFor ?? []).map(
          (item: { partnership_id: number; partner_name: string }) => ({
            id: item.partnership_id,
            name: item.partner_name,
          })
        ),
        profile,
        intl
      )
    );
  }, [intl, partnerships, pipelinePartners, profile, row]);

  const handleCloseDropdown = (e?: MouseEvent) => {
    e?.stopPropagation();
    setAnchorEl(null);
    setPartnershipIds([]);
  };

  const handleOpenDropdown = (event: MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };

  const createPipelines = async () => {
    setLoading(true);
    const service = new JSONAPIService("partner_connections");
    try {
      const payload = getPipelineToCreatePayload(
        profile.id,
        {
          name: row.name,
          countryIsoCode: row.countryIsoCode,
          domain: row.domain,
        },
        partnershipIds
      );
      const response = await service.rawPost<{
        data: JSONAPIResource<string>[];
      }>(
        "",
        "bulk/",
        {
          data: payload,
        },
        { include: ["discussion_participants"] }
      );
      await dispatch(bulkAddRecords(response.data));
      setPipelinePartners(undefined);
      dispatch(
        updateAttributesOnRecord(row, {
          partnerConnectionFor: [
            ...(row.partnerConnectionFor ?? []),
            ...response.data.data.map((item) => ({
              partner_connection_id: Number(item.id),
              partner_name: item.attributes?.partner_name,
              partnership_id: item.attributes?.partnership_id,
            })),
          ],
        })
      );
      const createdPartnerConnections = response.data
        .data as JSONAPIResource<"partner_connections">[];
      if (createdPartnerConnections.length === 1) {
        history.push({
          search: `?discussion=${createdPartnerConnections[0].id}`,
        });
      }
      pushNotification(
        {
          status: NotificationStatus.success,
          message: i18n.partnerConnectionsCreatedWith,
        },
        {
          accountName: row.name,
          count: partnershipIds.length,
          partnerName: partnerships
            .find((partnership) => String(partnership.id) === partnershipIds[0])
            ?.getPartner(profile).name,
        },
        {
          type: NotificationActionType.addedToPipelineActions,
          partnerConnections: createdPartnerConnections.map(
            (item) => new PartnerConnection(item)
          ),
          rawCompanies: [row],
        }
      );
      track(PipelineEvent.addToPipeline, {
        from: toString(Enum.MyAccounts),
        bulk: true,
        number_created: partnershipIds.length,
      });
    } catch (_error: any) {
      pushNotification(i18n.partnerConnectionsCreationFailed);
    } finally {
      setLoading(false);
      setAnchorEl(null);
    }
  };

  const handleSetPartnershipIds = (value: string[] | null) => {
    setPartnershipIds(value || []);
  };

  const avatarGroupPartnersList: (PartnerRepresentation & {
    partner_connection_id: number;
  })[] = _.sortBy(
    _.uniqWith(
      (row?.partnerConnectionFor ?? []).map(
        (item: { partnership_id: number; partner_connection_id: number }) => ({
          ...partnerships
            .find((partnership) => partnership.id === item.partnership_id)
            ?.getPartner(profile),
          partner_connection_id: item.partner_connection_id,
        })
      ),
      (a, b) => a?.name === b?.name
    ),
    (item) => (item.name ? 0 : 1)
  );

  const handleOpenDiscussionDrawer = (partnerConnectionId: number) => (
    e: MouseEvent
  ) => {
    e.preventDefault();
    e.stopPropagation();
    history.push({
      search: `?discussion=${partnerConnectionId}`,
    });

    new WebhookService().track({
      profile: profile,
      eventName: WebhookEventName.RevealOpenedMessageDrawer,
      partnerConnectionId,
    });
    track("Opened message drawer");
  };

  return (
    <>
      {bookmarkCount <= 0 && (
        <Button
          classes={{
            btn: classes.quinaryButton,
            content: classes.addCollabButtonContent,
          }}
          label={
            <Box className={classes.alpha}>
              <T>
                <Plus className={classes.icon} />
                <FormattedMessage {...i18n.addCollab} />
              </T>
            </Box>
          }
          onClick={handleOpenDropdown}
          size="xSmall"
          variant="quinary"
        />
      )}
      <AvatarGroup
        classes={{
          root:
            avatarGroupPartnersList.length > 5
              ? classes.avatarGroup
              : classes.avatarGroupNoCount,
        }}
        spacing={-4}
        max={999}
      >
        {avatarGroupPartnersList.slice(0, 5).map((partner) => (
          <Tooltip
            title={
              <T className={classes.tooltipWithIcon}>
                {partner?.name
                  ? intl.formatMessage(i18n.openCollabWith, {
                      partnerName: partner.name,
                    })
                  : intl.formatMessage(i18n.unassignedPipelineItem)}
                {partner?.name && <OpenInNew />}
              </T>
            }
            key={partner.id}
          >
            <Avatar
              onClick={handleOpenDiscussionDrawer(
                partner.partner_connection_id
              )}
              title={partner.name}
              src={partner.avatarUrl}
              variant="rounded"
            >
              {partner.name ? (
                partner.name.toUpperCase()[0]
              ) : (
                <LinkBroken className={classes.avatarIcon} />
              )}
            </Avatar>
          </Tooltip>
        ))}
        {avatarGroupPartnersList.length > 5 && (
          <Tooltip
            classes={{ popper: classes.avatarListTooltip }}
            title={avatarGroupPartnersList.slice(5).map((partner) => (
              <PartnerChip
                key={
                  partner.name ??
                  intl.formatMessage(i18n.unassignedPipelineItem)
                }
                partnerName={
                  partner.name ??
                  intl.formatMessage(i18n.unassignedPipelineItem)
                }
                avatarUrl={partner.avatarUrl}
                handleClick={handleOpenDiscussionDrawer(
                  partner.partner_connection_id
                )}
              />
            ))}
          >
            <T className={classes.avatarCountLabel}>
              +{avatarGroupPartnersList.length - 5}
            </T>
          </Tooltip>
        )}
      </AvatarGroup>
      {bookmarkCount > 0 && (
        <Box className={!anchorEl ? "showOnHover" : undefined}>
          <Button
            LeftIcon={Plus}
            onClick={handleOpenDropdown}
            size="xSmall"
            variant="tertiary"
          />
        </Box>
      )}
      {!!anchorEl && (
        <Dropdown
          isMultiselect
          withCheckBox
          isLoading={loading}
          withGroups={!!options[0]?.group}
          value={partnershipIds}
          options={options}
          anchorEl={anchorEl}
          onClose={handleCloseDropdown}
          open={!!anchorEl}
          onChange={handleSetPartnershipIds}
          size={388}
          CustomFooter={(value) => (
            <Box display="flex" justifyContent="space-between" gap="4px">
              <Button
                label={i18n.cancel}
                onClick={handleCloseDropdown}
                variant="tertiary"
                size="small"
              />
              <Button
                LeftIcon={Plus}
                label={
                  <Box display="flex" alignItems="center">
                    <FormattedMessage
                      {...i18n.createCollab}
                      values={{ count: value?.length ?? 0 }}
                    />
                    &nbsp;
                    {(value?.length ?? 0) > 0 && (
                      <span className={classes.countBadge}>
                        {value?.length}
                      </span>
                    )}
                  </Box>
                }
                onClick={createPipelines}
                size="small"
                classes={{ btn: classes.btn }}
              />
            </Box>
          )}
        />
      )}
    </>
  );
};

const getPipelineToCreatePayload = (
  userId: number,
  newProspect: {
    name: string;
    countryIsoCode: string;
    domain: string;
  },
  partnershipIds: string[]
) => {
  if (partnershipIds.length === 0) {
    return [
      {
        type: "partner_connections",
        attributes: {
          user_id: userId,
          new_prospect_name: newProspect.name,
          new_prospect_country: newProspect.countryIsoCode || null,
          new_prospect_domain: newProspect.domain,
          source: PipelineItemSource.newProspects360,
          partnership_id: null,
          raw_company_id: null,
        },
      },
    ];
  }

  return partnershipIds.map((partnershipId) => ({
    type: "partner_connections",
    attributes: {
      user_id: userId,
      new_prospect_name: newProspect.name,
      new_prospect_country: newProspect.countryIsoCode || null,
      new_prospect_domain: newProspect.domain,
      partnership_id: Number(partnershipId),
      source: PipelineItemSource.newProspects360,
      raw_company_id: null,
    },
  }));
};

const avatarClasses = (theme: Theme) => ({
  fontSize: 11,
  fontWeight: 500,
  color: theme.palette.midnight,
  backgroundColor: theme.palette.greyscale200,
  border: "none !important",
  height: 16,
  width: 16,
});

const useStyles = makeStyles()((theme) => ({
  addCollabButtonContent: {
    paddingLeft: 4,
    paddingRight: 6,
  },
  alpha: {
    color: theme.palette.alpha500,
  },
  avatarGroup: {
    alignItems: "center",
    cursor: "pointer",
    "& .MuiAvatar-root:not(:first-child)": avatarClasses(theme),
  },
  avatarGroupNoCount: {
    alignItems: "center",
    cursor: "pointer",
    "& .MuiAvatar-root": avatarClasses(theme),
  },
  avatarCountLabel: {
    backgroundColor: "transparent",
    color: theme.palette.alpha500,
    lineHeight: "16px",
    marginLeft: 4,
  },
  avatarIcon: {
    height: 10,
    width: 10,
  },
  avatarListTooltip: {
    "& .MuiTooltip-tooltip": {
      display: "flex",
      flexWrap: "wrap",
      gap: "4px",
      width: 260,
    },
  },
  countBadge: {
    backgroundColor: theme.palette.midnight,
    borderRadius: 10,
    color: theme.palette.ivory,
    fontSize: 10,
    lineHeight: "16px",
    minWidth: 16,
    padding: "0 4px",
  },
  icon: {
    height: 12,
    marginRight: 4,
    width: 12,
  },
  btn: {
    flexGrow: 1,
  },
  quinaryButton: {
    "&:hover": {
      backgroundColor: `${theme.palette.taupe} !important`,
    },
  },
  tooltipWithIcon: {
    display: "flex",
    alignItems: "center",
    gap: 4,
    "& svg": {
      width: 12,
      height: 12,
    },
  },
}));

// I18N

const i18n = defineMessages({
  addCollab: {
    id: "bookmarks.MyAccountsBookmark.addCollab",
    defaultMessage: "Add collab",
  },
  createCollab: {
    id: "bookmarks.MyAccountsBookmark.createCollab",
    defaultMessage:
      "Create {count, plural, =0 {collab (Unassigned)} one {collab} other {collabs}}",
  },
  openCollabWith: {
    id: "bookmarks.MyAccountsBookmark.openCollabWith",
    defaultMessage: "Open collab with {partnerName}",
  },
  partnerConnectionsCreatedWith: {
    id: "bookmarks.MyAccountsBookmark.partnerConnectionsCreatedWith",
    defaultMessage:
      "{accountName} has been successfully added to Collaborate {count, plural, =0 {} one {with {partnerName}} other {with {count} partners}}",
  },
  partnerConnectionsCreationFailed: {
    id: "bookmarks.MyAccountsBookmark.partnerConnectionsCreationFailed",
    defaultMessage:
      "Something went wrong, accounts could not be added to Collaborate",
  },
  cancel: {
    id: "bookmarks.MyAccountsBookmark.cancel",
    defaultMessage: "Cancel",
  },
  unassignedPipelineItem: {
    id: "bookmarks.MyAccountsBookmark.unassignedPipelineItem",
    defaultMessage: "Unassigned collab",
  },
});

// Helpers

type IPartnerConnectionFor = {
  partner_name: string;
  partnership_id: string;
};

const getBookmarkCount = (row: Record) => {
  const partnerConnectionFor = _.get(
    row,
    "partnerConnectionFor",
    []
  ) as IPartnerConnectionFor[];
  let uniquePartners = _.uniqWith(partnerConnectionFor, (a, b) => {
    const isEmptyA = !a.partnership_id;
    const isEmptyB = !b.partnership_id;
    if (isEmptyA && isEmptyB) {
      return true; // Treat all empty name/id as equal
    }
    return (
      a.partner_name === b.partner_name && a.partnership_id === b.partnership_id
    );
  });
  return uniquePartners.length;
};
