import _ from "lodash";
import React, { ComponentType, MemoExoticComponent } from "react";

type IndexedByString = { [key: string]: any };

const arePropsEqual = <Props extends IndexedByString>(accessors: string[]) => (
  prevProps: Props,
  nextProps: Props
): boolean => {
  const checkPropIsEqual = (accessor: string) => {
    const prev = _.get(prevProps, accessor);
    const next = _.get(nextProps, accessor);
    if (
      _.isObject(prev) &&
      _.isObject(next) &&
      _.isEmpty(prev) &&
      _.isEmpty(next)
    ) {
      return true;
    }
    return _.isEqual(prev, next);
  };
  const propsAreEqual = accessors.map(checkPropIsEqual);
  return propsAreEqual.every((propIsEqual) => propIsEqual);
};

export const memoUnlessPropsChange = <Props>(
  propsAccessors: string[],
  element: ComponentType<Props>
): MemoExoticComponent<ComponentType<Props>> => {
  return React.memo(element, arePropsEqual(propsAccessors));
};
