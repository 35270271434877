import { createAction } from "@reduxjs/toolkit";
import Record from "models/Record";
import { JSONAPIAttributes, JSONAPIIdentifier } from "models/types";
import { JSONAPIListResponse, JSONAPIResponse } from "services/types";

import { APIActions } from "./typing";

export const bulkRemoveRecords = createAction<JSONAPIIdentifier[]>(
  APIActions.bulkRemoveRecords
);

export const bulkAddRecords = createAction<
  JSONAPIResponse | JSONAPIListResponse
>(APIActions.bulkAddRecords);

export const updateAttributesOnRecord = createAction(
  APIActions.updateAttributesOnRecord,
  (record: Record | JSONAPIIdentifier, attributes: JSONAPIAttributes) => ({
    payload: {
      record,
      attributes,
    },
  })
);

export const bulkUpdateAttributesOnRecords = createAction(
  APIActions.bulkUpdateAttributesOnRecord,
  (record: (Record | JSONAPIIdentifier)[], attributes: JSONAPIAttributes) => ({
    payload: {
      record,
      attributes,
    },
  })
);

export const updateRelationshipsOnRecord = createAction(
  APIActions.updateRelationshipsOnRecord,
  (
    record: Record | JSONAPIIdentifier,
    relationships: {
      [relation: string]: JSONAPIIdentifier | JSONAPIIdentifier[] | null;
    }
  ) => ({
    payload: {
      record,
      relationships,
    },
  })
);

export const clearPartnershipCrmFields = createAction(
  APIActions.clearPartnershipCrmFields
);
