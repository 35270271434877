import { ClickAwayListener, Popper } from "@mui/material";
import { makeStyles } from "makeStyles";

import { IDropdown } from "./types";

export const DropdownMenu = <T extends string = string>(
  dropdownProps: IDropdown<T>
) => {
  const {
    anchorEl,
    onClose,
    children,
    size = "normal",
    open,
    position,
    isMainSearch,
    disablePortal,
  } = dropdownProps;
  const { classes } = useStyles({ size, anchorEl, isMainSearch });

  return (
    <Popper
      disablePortal={disablePortal} // render inside the parent, for ex. useful to keep the popper inside parent table on scroll
      placement={position}
      anchorEl={anchorEl}
      open={open}
      className={classes.paper}
    >
      {children && (
        <ClickAwayListener onClickAway={onClose}>{children}</ClickAwayListener>
      )}
    </Popper>
  );
};

export default DropdownMenu;

const useStyles = makeStyles<{
  size: "normal" | "small" | "inherit" | number;
  anchorEl: Element | null;
  isMainSearch?: boolean;
}>()((theme, { size, anchorEl, isMainSearch }) => ({
  paper: {
    display: "flex",
    flexDirection: "column",
    borderRadius: isMainSearch ? "0 0 6px 6px" : 8,
    border: isMainSearch
      ? `2px solid ${theme.palette.taupe}`
      : `1px solid ${theme.palette.grey[300]}`,
    borderTopWidth: isMainSearch ? 0 : 1,
    width:
      size === "inherit" && anchorEl
        ? anchorEl.clientWidth
        : size === "normal"
        ? "268px"
        : size === "small"
        ? "204px"
        : typeof size === "number"
        ? size
        : "268px",
    backgroundColor: theme.palette.ivory,
    boxShadow: "0px 4px 8px rgba(130, 136, 136, 0.08)",
    padding: isMainSearch ? 6 : 12,
    margin: isMainSearch ? "0" : "4px !important",
    zIndex: 10000,
    ...(isMainSearch ? { top: "-1px !important" } : {}), // forcing anchor element position
  },
  root: {
    position: size === "normal" ? "unset" : "fixed",
  },
}));

export const usePopperStyles = makeStyles()((theme) => ({
  paper: {
    display: "flex",
    flexDirection: "column",
    borderRadius: 8,
    border: `1px solid ${theme.palette.grey[300]}`,
    width: "204px",
    backgroundColor: theme.palette.ivory,
    boxShadow: "0px 4px 8px rgba(130, 136, 136, 0.08)",
    padding: 12,
    margin: "4px !important",
    zIndex: 10000,
  },
}));
