import Grid from "@mui/material/Grid";
import { isFulfilled } from "@reduxjs/toolkit";
import Button from "components/ui/Button";
import { SelectInput } from "components/ui/SelectInput";
import { Formik } from "formik";
import { goToNext } from "helpers/goToNext";
import usePushNotification from "hooks/usePushNotification";
import useSegment from "hooks/useSegment";
import useUserProfile from "hooks/useUserProfile";
import forms from "i18n/forms";
import generic from "i18n/generic";
import { makeStyles } from "makeStyles";
import { Factory } from "models";
import {
  COMPANY_ECOSYSTEM_LABELS,
  COMPANY_SIZE_LABELS,
  COMPANY_TYPE_LABELS,
  CompanyEcosystem,
  CompanySize,
  CompanyType,
} from "models/Company";
import Record from "models/Record";
import { useEffect } from "react";
import { defineMessages, useIntl } from "react-intl";
import { useDispatch } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import { update } from "redux/api/thunks";
import { setCompany } from "redux/user/actions";
import { fetchProfile } from "redux/user/thunks";
import { JSONAPIResponse } from "services/types";
import { object, string } from "yup";

type Values = {
  size: string;
  ecosystem: string;
  business_type: string;
};

type CompanyFormDetailsProps = {
  company: Record;
};

const CompanyFormDetails = ({ company }: CompanyFormDetailsProps) => {
  const { classes } = useStyles();
  const intl = useIntl();
  const dispatch = useDispatch();
  const location = useLocation();
  const history = useHistory();
  const pushNotification = usePushNotification();
  const { track } = useSegment();
  const { profile } = useUserProfile();

  const validationSchema = object().shape({
    size: string()
      .oneOf(Object.values(CompanySize))
      .required(() => forms.generic_required),
    ecosystem: string().required(() => forms.generic_required),
    business_type: string().required(() => forms.generic_required),
  });

  const next = () => {
    history.push(goToNext(location, "/"));
  };

  const handleSubmit = async (values: Values, actions: $TSFixMe) => {
    actions.setSubmitting(true);
    const attributes = { ...values };
    const result = await dispatch(
      update({
        id: company.id,
        type: "companies",
        attributes,
      })
    );
    if (isFulfilled(result)) {
      const response = result.payload as JSONAPIResponse;
      await dispatch(setCompany(Factory.createRecord(response.data)));
      track("Input company details", { userId: profile.id });
      dispatch(fetchProfile());
    } else {
      pushNotification("default_error");
    }
    actions.setSubmitting(false);
    next();
  };

  useEffect(() => {
    if (company.size && company.ecosystem && company.businessType) {
      next();
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Formik
      initialValues={{
        size: company.size,
        ecosystem: company.ecosystem,
        business_type: company.businessType,
      }}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
    >
      {({ errors, handleChange, values, isSubmitting, submitForm }) => (
        <Grid container className={classes.root}>
          <Grid item xs={12}>
            <SelectInput
              variant="tertiary"
              fullWidth
              placeholder={intl.formatMessage(i18n.sizePlaceholder)}
              required
              value={values.size}
              onChange={handleChange}
              error={Boolean(errors.size)}
              name="size"
              options={Object.values(CompanySize).map((key) => ({
                value: key,
                label: COMPANY_SIZE_LABELS[key],
              }))}
              inputProps={{ "data-testid": "select-size" }}
            />
          </Grid>
          <Grid item xs={12}>
            <SelectInput
              variant="tertiary"
              fullWidth
              placeholder={intl.formatMessage(i18n.ecosystemPlaceholder)}
              required
              value={values.ecosystem}
              onChange={handleChange}
              error={Boolean(errors.ecosystem)}
              name="ecosystem"
              options={Object.values(CompanyEcosystem).map((key) => ({
                value: key,
                label: COMPANY_ECOSYSTEM_LABELS[key],
              }))}
              inputProps={{ "data-testid": "select-ecosystem" }}
            />
          </Grid>
          <Grid item xs={12} className={classes.gutterBottom}>
            <SelectInput
              variant="tertiary"
              fullWidth
              placeholder={intl.formatMessage(i18n.typePlaceholder)}
              required
              value={values.business_type}
              onChange={handleChange}
              error={Boolean(errors.business_type)}
              name="business_type"
              options={Object.values(CompanyType).map((key) => ({
                value: key,
                label: COMPANY_TYPE_LABELS[key],
              }))}
              inputProps={{ "data-testid": "select-business-type" }}
            />
          </Grid>
          <Grid item xs={12}>
            <Button
              label={generic.next}
              loading={isSubmitting}
              onClick={submitForm}
              classes={{ btn: classes.fullWidth }}
            />
          </Grid>
        </Grid>
      )}
    </Formik>
  );
};
export default CompanyFormDetails;

// I18N

const i18n = defineMessages({
  sizePlaceholder: {
    id: "CompanyFormDetails.sizePlaceholder",
    defaultMessage: "Number of employees",
  },
  typePlaceholder: {
    id: "CompanyFormDetails.typePlaceholder",
    defaultMessage: "Company type",
  },
  ecosystemPlaceholder: {
    id: "CompanyFormDetails.ecosystemPlaceholder",
    defaultMessage: "Company ecosystem",
  },
});

// CSS

const useStyles = makeStyles()((theme) => ({
  root: {
    rowGap: theme.spacing(1),
  },
  fullWidth: {
    width: "100%",
  },
  gutterBottom: {
    marginBottom: 24,
    [theme.breakpoints.down("md")]: {
      marginBottom: 16,
    },
  },
}));
