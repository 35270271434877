import { Box, Divider } from "@mui/material";
import Drawer from "@mui/material/Drawer";
import Hidden from "@mui/material/Hidden";
import { MoveLayerLeft, MoveLayerRight } from "components/icons";
import DialogManager from "components/ui/DialogManager";
import SidebarProfile from "components/ui/sidebar/SidebarProfile";
import InvitationModal from "components/users/list/InvitationModal";
import useSegment from "hooks/useSegment";
import { makeStyles } from "makeStyles";
import { PayingFeature } from "models/CompanyPayingFeatureSubscription";
import User from "models/User";
import { useEffect, useMemo, useState } from "react";
import { FormattedMessage, MessageDescriptor } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import { selectActivePayingFeatures } from "redux/api/selectors";
import { toggleSidebar } from "redux/init/actions";
import {
  selectOnboardingPartnershipsToAcceptData,
  selectOnboardingPartnershipsToShareData,
} from "redux/onboarding/selectors";
import { selectUnreadConversations } from "redux/pipeline/selectors";
import { MessageDrawer } from "screens/Frontoffice/screens/DataTables/screens/Pipeline/components/MessageDrawer";
import InviteForm from "screens/Frontoffice/shared/components/InviteForm";

import { ComingSoonTag } from "../ComingSoonTag";
import Logo from "../Logo";
import { NewTag } from "../NewTag";
import Popup from "../Popup";
import { getStartedSection } from "./sections";
import SidebarButton, { SidebarButtonProps } from "./SidebarButton";
import { SidebarHelpCenter } from "./SidebarHelpCenter";

type Props = {
  profile: User;
  sections?: SidebarButtonProps[];
  folded?: boolean;
  hideLockIcon?: boolean;
};

const Sidebar = ({ profile, sections = [], folded, hideLockIcon }: Props) => {
  const { classes, cx } = useStyles();
  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();
  const { track } = useSegment();
  const [open, setOpen] = useState(folded ? false : true);
  const payingFeatures = useSelector(selectActivePayingFeatures);

  const displayOnlyFavorites = !profile.isCompanyAdmin();

  const {
    ready: notAcceptedReady,
    partnerships: notAcceptedPartnerships,
  } = useSelector(selectOnboardingPartnershipsToAcceptData);
  const {
    ready: notSharedReady,
    partnerships: notSharedPartnerships,
  } = useSelector(
    selectOnboardingPartnershipsToShareData(displayOnlyFavorites)
  );
  const isSalesUser = profile.isSales();
  const totalPartners =
    notAcceptedPartnerships.length + notSharedPartnerships.length;
  const hasNewOnboarding = payingFeatures.includes(PayingFeature.NewOnboarding);
  const showGetStarted =
    hasNewOnboarding &&
    profile.isCompanyAdmin() &&
    !profile.company.finalOnboardingStepCompleted;
  const showPendingPartners =
    profile.canInvitePartner(payingFeatures) &&
    notAcceptedReady &&
    notSharedReady &&
    !isSalesUser &&
    totalPartners > 0;
  const { total: totalUnreadConversations } = useSelector(
    selectUnreadConversations
  );

  const hasAttributeFeature = payingFeatures.includes(
    PayingFeature.AttributeEnabled
  );

  const isOpen = open || !folded;
  const LockIcon = folded ? MoveLayerRight : MoveLayerLeft;

  const openSidebar = () => {
    setOpen(true);
  };
  const closeSidebar = () => {
    setOpen(false);
  };

  useEffect(() => {
    // When we access a page where the sidebar is force folded,
    // we need to close it by default
    if (hideLockIcon) {
      closeSidebar();
    }
  }, [location.pathname, hideLockIcon]);

  const onLockClick = () => {
    dispatch(toggleSidebar());
    if (open) {
      closeSidebar();
    }
  };

  const removeUrlParams = () => history.push({ ...location, hash: "" });
  const removeUrlParamsAndSearch = () =>
    history.push({ ...location, search: "", hash: "" });

  const updatedSections = useMemo(
    () =>
      updateSectionsRecursively(
        sections,
        hasAttributeFeature,
        showPendingPartners,
        track,
        totalPartners,
        totalUnreadConversations
      ),
    [
      sections,
      hasAttributeFeature,
      showPendingPartners,
      track,
      totalPartners,
      totalUnreadConversations,
    ]
  );

  return (
    <>
      <Hidden mdDown>
        <Drawer
          variant={folded ? "temporary" : "permanent"}
          open
          hideBackdrop
          disableEnforceFocus
          className={cx(classes.drawer, {
            [classes.drawerOpen]: isOpen,
            [classes.drawerClose]: !isOpen,
          })}
          classes={{
            paper: cx(classes.drawerPaper, {
              [classes.drawerOpen]: isOpen,
              [classes.drawerClose]: !isOpen,
            }),
          }}
          transitionDuration={0}
          onMouseLeave={closeSidebar}
        >
          <div className={classes.content}>
            <Logo showName={isOpen} openSidebar={openSidebar} />
            {!hideLockIcon && (
              <LockIcon
                onClick={onLockClick}
                className={cx(classes.openIcon, "showOnHover")}
                data-testid="lock-icon"
              />
            )}
            <div className={classes.buttonsContainer}>
              {showGetStarted && (
                <SidebarButton
                  {...getStartedSection}
                  expanded={isOpen}
                  openSidebar={openSidebar}
                />
              )}
              {updatedSections.map((section, index) => (
                <RecursiveSidebarButton
                  key={`section-${index}`}
                  {...section}
                  expanded={isOpen}
                  openSidebar={openSidebar}
                  index={index}
                />
              ))}
            </div>
          </div>
          <div className={classes.content}>
            <SidebarHelpCenter openSidebar={openSidebar} />
            <Divider className={classes.divider} />
            <SidebarProfile
              profile={profile}
              expanded={isOpen}
              openSidebar={openSidebar}
            />
          </div>
        </Drawer>
      </Hidden>
      {profile.hasLicense && (
        <DialogManager
          isOpen={location.hash === "#invite-team"}
          handleClose={removeUrlParams}
          fullScreenBreakpoint="sm"
          DialogContentComponent={
            <InvitationModal handleClose={removeUrlParams} />
          }
        />
      )}
      {location.hash === "#invite-partner" && (
        <Popup
          isOpen={location.hash === "#invite-partner"}
          handleClose={removeUrlParamsAndSearch}
          hideActions
          variant="secondary"
          width={640}
        >
          <InviteForm fullWidth onInviteSuccess={removeUrlParams} />
        </Popup>
      )}
      <MessageDrawer />
    </>
  );
};

export default Sidebar;

// CSS

export const widthOpened = 216;
export const widthClosed = 48;

const useStyles = makeStyles()((theme) => ({
  content: {
    margin: theme.spacing(1),
    marginTop: theme.spacing(2),
  },
  divider: {
    width: "100%",
    margin: "4px 0px",
    backgroundColor: theme.palette.comet,
  },
  drawer: {
    width: widthOpened,
    flexShrink: 0,
    whiteSpace: "nowrap",
    "& .showOnHover": {
      display: "none",
    },
    "&:hover .showOnHover": {
      display: "block",
    },
  },
  drawerOpen: {
    width: widthOpened,
    // 'transition' property is set inline when 'folded' is updated
    // so we need to add '!important' to prevent this overwrite
    transition: `${theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    })} !important`,
  },
  drawerClose: {
    width: widthClosed,
    // 'transition' property is set inline when 'folded' is updated
    // so we need to add '!important' to prevent this overwrite
    transition: `${theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    })} !important`,
  },
  drawerPaper: {
    justifyContent: "space-between",
    backgroundColor: theme.palette.midnight,
    borderRightStyle: "none",
    overflow: "visible",
    zIndex: theme.zIndex.drawer + 2, // TO UPDATE WHEN APPBAR WILL BE UPDATED
  },
  menuButton: {
    marginLeft: "auto",
  },
  openIcon: {
    width: 24,
    borderRadius: 12,
    marginTop: 8,
    padding: 6,
    float: "right",
    marginRight: -27,
    backgroundColor: theme.palette.taupe,
    cursor: "pointer",
  },
  buttonsContainer: {
    display: "flex",
    flexDirection: "column",
    rowGap: "4px",
    overflow: "hidden",
  },
}));

type IRecursiveSidebarButtonProps = SidebarButtonProps & {
  subSections?: SidebarButtonProps[];
  isSubItem?: boolean;
};

const RecursiveSidebarButton = ({
  label,
  subSections,
  isSubItem,
  ...props
}: IRecursiveSidebarButtonProps) => {
  const { expanded, Icon, openSidebar } = props;
  const { classes } = useRecursiveStyles({ expanded });

  return (
    <>
      <SidebarButton
        {...props}
        label={label}
        Icon={isSubItem && expanded ? undefined : Icon}
      />
      {subSections && subSections.length > 0 && (
        <div className={classes.subSectionsContainer}>
          {subSections.map((subSection, index) => (
            <RecursiveSidebarButton
              isSubItem={true}
              key={`subSection-${index}`}
              expanded={expanded}
              openSidebar={openSidebar}
              {...subSection}
            />
          ))}
        </div>
      )}
    </>
  );
};

const useRecursiveStyles = makeStyles<{
  expanded: boolean;
}>()((theme, { expanded }) => ({
  subSectionsContainer: {
    display: "flex",
    position: "relative",
    width: 32,
    ...(expanded && {
      paddingLeft: theme.spacing(3),
      width: "unset",
      "&:before": {
        content: '""',
        position: "absolute",
        left: 16,
        top: 0,
        bottom: "50%",
        width: 8,
        height: 18,
        borderLeft: `1px solid ${theme.palette.greyscale550}`,
        borderBottom: `1px solid ${theme.palette.greyscale550}`,
        borderBottomLeftRadius: 8,
        opacity: 1,
      },
      "&:empty": {
        "&:before": {
          opacity: 0, // Hide the :before element when the container has no children
        },
      },
    }),
  },
}));

const updateSectionsRecursively = (
  sections: SidebarButtonProps[],
  hasAttributeFeature: boolean,
  showPendingPartners: boolean,
  track: (eventName: string, data?: Object) => void,
  totalPartners: number,
  unreadConversations: number
) => {
  return sections.map((item) => {
    let updatedItem = { ...item };

    if (item.to === "/attribute" && !hasAttributeFeature) {
      updatedItem = {
        ...updatedItem,
        label: (
          <Box
            alignItems="center"
            display="flex"
            width="100%"
            justifyContent="space-between"
          >
            <FormattedMessage {...(item.label as MessageDescriptor)} />
            <ComingSoonTag />
          </Box>
        ),
        callback: () => track("Attribute clicked"),
        to: undefined,
      };
    }
    if (item.to === "/pipeline" && unreadConversations > 0) {
      updatedItem = {
        ...updatedItem,
        label: (
          <Box
            alignItems="center"
            display="flex"
            width="100%"
            justifyContent="space-between"
          >
            <FormattedMessage {...(item.label as MessageDescriptor)} />
            <NewTag color="danger" count={unreadConversations} />
          </Box>
        ),
      };
    }
    if (item.to === "/onboarding/partnership-activation#fromSidebar") {
      updatedItem = {
        ...updatedItem,
        label: (
          <Box
            alignItems="center"
            display="flex"
            width="100%"
            justifyContent="space-between"
          >
            <FormattedMessage {...(item.label as MessageDescriptor)} />
            <NewTag color="danger" count={totalPartners} />
          </Box>
        ),
        shouldDisplay: (_profile: User, _payingFeatures: PayingFeature[]) =>
          showPendingPartners,
      };
    }

    if (item.subSections) {
      updatedItem.subSections = updateSectionsRecursively(
        item.subSections,
        hasAttributeFeature,
        showPendingPartners,
        track,
        totalPartners,
        unreadConversations
      );
    }

    return updatedItem;
  });
};
