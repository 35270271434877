import { Box } from "@mui/material";
import Tooltip from "@mui/material/Tooltip";
import PageForbidden from "components/ui/PageForbidden";
import { T } from "components/ui/Typography";
import useUserProfile from "hooks/useUserProfile";
import { makeStyles } from "makeStyles";
import { Fragment } from "react";
import { defineMessages, FormattedMessage } from "react-intl";
import { Link } from "react-router-dom";

import { backofficeSections } from "./utils";

type Props = {
  children: JSX.Element;
};
export const AdminPage = ({ children }: Props) => {
  const { classes, cx } = useStyles();
  const { profile } = useUserProfile();
  if (!profile.admin) {
    return <PageForbidden />;
  }
  return (
    <>
      <div className={classes.toolbar}>
        <img
          src={"/images/reveal-icon-purple.png"}
          alt=""
          className={classes.revealLogo}
        />
        {backofficeSections.map((section, key) => {
          return (
            <Fragment key={key}>
              <Tooltip
                title={<FormattedMessage {...section.title} />}
                placement="right"
              >
                <Link
                  className={cx(classes.link, {
                    [classes.active]:
                      window.location.pathname.indexOf(section.path) === 0,
                    [classes.linkMarginTop]: key > 0,
                  })}
                  to={section.path}
                >
                  {<section.icon />}
                </Link>
              </Tooltip>
            </Fragment>
          );
        })}
      </div>
      <div className={classes.content}>
        <Box ml={2} mb={2}>
          <T h2 bold>
            <FormattedMessage {...i18n.backOffice} />
          </T>
        </Box>
        {children}
      </div>
    </>
  );
};

export default AdminPage;

// CSS

const spaceSteps = 2;
const toolBarWidth = 68;

const useStyles = makeStyles()((theme) => ({
  toolbar: {
    height: "100%",
    backgroundColor: theme.palette.midnight,
    position: "fixed",
    width: toolBarWidth,
    top: 0,
    left: 0,
    overflowX: "hidden",
    padding: theme.spacing(spaceSteps),
    paddingTop: theme.spacing(3),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    zIndex: theme.zIndex.drawer + 2,
  },
  revealLogo: {
    width: "23px",
    marginBottom: theme.spacing(7.5),
  },
  hr: {
    width: "100%",
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(1),
  },
  link: {
    width: "36px",
    height: "36px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    color: theme.palette.offWhite,
    borderRadius: "50%",
    "&:hover": {
      color: theme.palette.offWhite,
    },
  },
  linkMarginTop: {
    marginTop: theme.spacing(0.5),
  },
  active: {
    backgroundColor: theme.palette.greyscale800,
  },
  content: {
    marginLeft: toolBarWidth,
    "& > .MuiPaper-root": {
      backgroundColor: theme.palette.offWhite,
    },
    "& .MuiGrid-root": {
      backgroundColor: theme.palette.ivory,
      "& .MuiGrid-root": {
        backgroundColor: "inherit",
      },
    },
  },
}));

// I18N

const i18n = defineMessages({
  backOffice: {
    id: "backoffice.admin_page.backOffice",
    defaultMessage: "Back-Office",
  },
});
