import { AnyAction, isFulfilled, ThunkDispatch } from "@reduxjs/toolkit";
import { Factory } from "models";
import Record from "models/Record";
import {
  FilterType,
  MatchFilterType,
} from "screens/Frontoffice/screens/DataTables/shared/types";
import { JSONAPIResponse } from "services/types";
import UserService from "services/UserService";

import { AnalyticsOtherFilterFieldname } from "./analytics/types";
import { update } from "./api/thunks";
import {
  CustomDateRangeFilterType,
  DateRangeFilterType,
  DateRangeTimeframe,
  RevealStore,
} from "./typing";

export const updateLastVisitedAt = async (
  recordId: number,
  dispatch: ThunkDispatch<RevealStore, any, AnyAction>
) => {
  const userService = new UserService();
  if (!userService.isImpersonating) {
    await dispatch(
      update({
        id: recordId,
        type: "page_views",
        attributes: {
          last_visited_at: new Date().toISOString(),
        },
      })
    );
  }
};

export const updateLastVisitedAtAndFilters = async (
  record: Record,
  dispatch: ThunkDispatch<RevealStore, any, AnyAction>,
  hasAdvancedAnalytics: boolean
) => {
  const userService = new UserService();
  let attributes = null;
  if (!hasAdvancedAnalytics) {
    attributes = {
      filters:
        record.filters.filter(
          (item: FilterType) =>
            item.fieldname ===
              AnalyticsOtherFilterFieldname.showSummaryMetrics ||
            item.fieldname ===
              AnalyticsOtherFilterFieldname.highlightHighWinRate
        ) ?? [],
    };
  } else if (!userService.isImpersonating) {
    attributes = {
      last_visited_at: new Date().toISOString(),
    };
  }
  if (attributes !== null) {
    const result = await dispatch(
      update({
        id: record.id,
        type: "page_views",
        attributes,
      })
    );
    if (isFulfilled(result)) {
      const response = result.payload as JSONAPIResponse;
      const newRecord = Factory.createRecord(response.data);
      return newRecord;
    }
  }
  return record;
};

export const isCustomRangeFilter = (
  value: DateRangeFilterType
): value is CustomDateRangeFilterType =>
  value.rangeType === DateRangeTimeframe.customRange;

export const extractMinAndMaxDates = (
  dateRange: DateRangeFilterType | null
) => {
  if (dateRange === null) {
    return [null, null];
  }

  const rangeType = dateRange.rangeType;
  const date = new Date(Date.now()),
    y = date.getFullYear(),
    m = date.getMonth();
  const quarter = Math.floor(m / 3);
  if (isCustomRangeFilter(dateRange)) {
    const start = dateRange.selectionRange.start
      ? new Date(dateRange.selectionRange.start)
      : null;
    const end = dateRange.selectionRange.end
      ? new Date(dateRange.selectionRange.end)
      : null;
    return [start, end];
  }

  switch (rangeType) {
    case DateRangeTimeframe.thisMonth:
      return [new Date(y, m, 1), new Date(y, m + 1, 1)];
    case DateRangeTimeframe.lastMonth:
      return [new Date(y, m - 1, 1), new Date(y, m, 1)];
    case DateRangeTimeframe.thisQuarter:
      const quarterStartDate = new Date(y, quarter * 3, 1);
      return [
        quarterStartDate,
        new Date(
          quarterStartDate.getFullYear(),
          quarterStartDate.getMonth() + 3,
          0
        ),
      ];
    case DateRangeTimeframe.previousQuarter:
      const prevQuarterStartDate = new Date(y, quarter * 3 - 3, 1);
      return [
        prevQuarterStartDate,
        new Date(
          prevQuarterStartDate.getFullYear(),
          prevQuarterStartDate.getMonth() + 3,
          0
        ),
      ];
    case DateRangeTimeframe.thisYear:
      return [new Date(y, 0, 1), new Date(y, 11, 31)];
    case DateRangeTimeframe.previousYear:
      return [new Date(y - 1, 0, 1), new Date(y - 1, 11, 31)];
    default:
      return [null, null];
  }
};

export const formatRangeFilters = (filters: FilterType[] | undefined = []) => {
  let formattedFilters =
    filters.filter((item) => item.fieldname !== "date_range") ?? [];
  const rangeFilter =
    filters.find((item) => item.fieldname === "date_range") ?? null;
  if (rangeFilter) {
    const [minDate, maxDate] = extractMinAndMaxDates(
      rangeFilter.value as DateRangeFilterType
    );
    if (minDate) {
      formattedFilters.push({
        fieldname: "created_at",
        type: MatchFilterType.GTE,
        value: minDate.toISOString(),
      });
    }
    if (maxDate) {
      formattedFilters.push({
        fieldname: "created_at",
        type: MatchFilterType.LTE,
        value: maxDate.toISOString(),
      });
    }
  }
  return formattedFilters;
};
