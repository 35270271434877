import { defineMessages } from "react-intl";

export default defineMessages({
  true: {
    id: "generic.true",
    defaultMessage: "True",
  },
  false: {
    id: "generic.false",
    defaultMessage: "False",
  },
  category_tool: {
    id: "generic.category.tool",
    description: "Label for `tool` category",
    defaultMessage: "Apps",
  },
  category_expert: {
    id: "generic.category.expert",
    description: "Label for `expert` category",
    defaultMessage: "Experts",
  },
  category_document: {
    id: "generic.category.document",
    description: "Label for `document` category",
    defaultMessage: "Templates",
  },
  category_tip: {
    id: "generic.category.tip",
    description: "Label for `tip` category",
    defaultMessage: "Tips",
  },
  full_name: {
    id: "generic.full_name",
    description: "Full Name of a person",
    defaultMessage: "Name",
  },
  first_name: {
    id: "generic.first_name",
    description: "First Name of a person",
    defaultMessage: "First Name",
  },
  last_name: {
    id: "generic.last_name",
    description: "Last Name of a person",
    defaultMessage: "Last Name",
  },
  email: {
    id: "generic.email",
    description: "Email Address of a person",
    defaultMessage: "Email",
  },
  phone_number: {
    id: "generic.phone_number",
    description: "Phone number of a person",
    defaultMessage: "Phone Number",
  },
  work_email: {
    id: "generic.work_email",
    description: "Work Email Address of a person",
    defaultMessage: "Work Email",
  },
  job_title: {
    id: "generic.job_title",
    description: "Job title of a person",
    defaultMessage: "Job title",
  },
  role: {
    id: "generic.role",
    description: "Sales level of a person",
    defaultMessage: "Role",
  },
  work_experience: {
    id: "generic.work_experience",
    description: "Work experience of a person",
    defaultMessage: "Work Experience",
  },
  company_name: {
    id: "generic.company_name",
    description: "Company name of a person",
    defaultMessage: "Company Name",
  },
  company_size: {
    id: "generic.company_size",
    description: "Company size of a person",
    defaultMessage: "Company Size",
  },
  company_tags: {
    id: "generic.company_tags",
    description: "Company tags of a person",
    defaultMessage: "Company Tags",
  },
  password: {
    id: "generic.password",
    description: "Password of an account",
    defaultMessage: "Password",
  },
  password_confirmation: {
    id: "generic.password_confirmation",
    description: "Confirmation Password of an account",
    defaultMessage: "Password Confirmation",
  },
  save: {
    id: "generic.save",
    description: "Save something",
    defaultMessage: "Save",
  },
  confirm: {
    id: "generic.confirm",
    description: "confirm something",
    defaultMessage: "Confirm",
  },
  send: {
    id: "generic.send",
    description: "Send something",
    defaultMessage: "Send",
  },
  add: {
    id: "generic.add",
    description: "Add something",
    defaultMessage: "Add",
  },
  delete: {
    id: "generic.delete",
    description: "delete something",
    defaultMessage: "Delete",
  },
  cancel: {
    id: "generic.cancel",
    description: "Cancel something",
    defaultMessage: "Cancel",
  },
  skip: {
    id: "generic.skip",
    description: "Skip something",
    defaultMessage: "Skip",
  },
  updateError: {
    id: "generic.updateError",
    defaultMessage: "Failed to update",
  },
  question: {
    id: "generic.question",
    description: "Question",
    defaultMessage: "Question",
  },
  creation_date: {
    id: "generic.created_at",
    description: "Date of the creation of an object",
    defaultMessage: "Creation Date",
  },
  category: {
    id: "generic.category",
    description: "Term designing a category of objects",
    defaultMessage: "Category",
  },
  reveal: {
    id: "generic.reveal",
    description: "The name of the Reveal app in the language",
    defaultMessage: "Reveal",
  },
  edits_saved: {
    id: "generic.edits_saved",
    defaultMessage: "Your edits have been saved.",
  },
  error_unknown: {
    id: "error_unknown.msg",
    defaultMessage: "Oh Snap! Something broke...",
  },
  or: {
    id: "generic.or",
    defaultMessage: "or",
  },
  learn_more: {
    id: "generic.learn_more",
    defaultMessage: "Learn more.",
  },
  linkedin_url: {
    id: "generic.linkedin_url",
    defaultMessage: "LinkedIn Profile URL",
  },
  not_authorized: {
    id: "generic.not_authorized",
    defaultMessage:
      "You do not have the required permissions to perform this action.",
  },
  dismiss: {
    id: "generic.dismiss",
    defaultMessage: "Dismiss",
  },
  unassigned: {
    id: "generic.unassigned",
    defaultMessage: "Unassigned",
  },
  dangerZone: {
    id: "generic.dangerZone",
    defaultMessage: "Danger Zone",
  },
  next: {
    id: "generic.next",
    defaultMessage: "Next",
  },
  back: {
    id: "generic.back",
    defaultMessage: "Back",
  },
  explorePlans: {
    id: "generic.explorePlans",
    defaultMessage: "Explore Plans",
  },
  copied: {
    id: "generic.copied",
    defaultMessage: "Copied!",
  },
  accept: {
    id: "generic.accept",
    defaultMessage: "Accept",
  },
  otp: {
    id: "generic.otp",
    defaultMessage: "One-time Password (6 digits)",
  },
  connect: {
    id: "generic.connect",
    defaultMessage: "Connect",
  },
  disconnect: {
    id: "generic.disconnect",
    defaultMessage: "Disconnect",
  },
  cannotEditFromCRM: {
    id: "generic.cannotEditFromCRM",
    defaultMessage:
      "You can’t edit this data because it’s imported from your CRM.",
  },
  select: {
    id: "generic.select",
    defaultMessage: "Select",
  },
});
