import { Box } from "@mui/material";
import KindCell from "components/ui/data-grid/cellRenderers/pipeline/KindCell";
import StageCell from "components/ui/data-grid/cellRenderers/pipeline/StageCell";
import { T } from "components/ui/Typography";
import useSelectorWithDeepEquality from "hooks/useSelectorWithDeepEquality";
import { makeStyles } from "makeStyles";
import DiscussionParticipant from "models/DiscussionParticipant";
import Match from "models/Match";
import PartnerConnection from "models/PartnerConnection";
import { FormattedMessage } from "react-intl";
import { selectPerspective } from "redux/pipeline/selectors";

import { AccountNameOwner } from "./AccountNameOwner";
import { ParticipantsDetails } from "./ParticipantsDetails";
import { PartnerConnectionCreatedDetails } from "./PartnerConnectionCreatedDetails";

type Props = {
  activeParticipants: DiscussionParticipant[];
  partnerConnection?: PartnerConnection | null;
  handleOpenModal: () => void;
  isLocked?: boolean;
  isNonUser?: boolean;
};

export const DiscussionDetails = ({
  handleOpenModal,
  partnerConnection,
  activeParticipants,
  isLocked,
  isNonUser,
}: Props) => {
  const perspective = useSelectorWithDeepEquality(selectPerspective);
  const { classes, cx } = useStyles({
    isOutbound: perspective === Match.PERSPECTIVE_THEIRS,
    isNonUser,
  });
  const isPartnerConnectionNewProspect =
    typeof partnerConnection?.rawCompanyId !== "number" &&
    !partnerConnection?.rawCompanyId;
  return (
    <div>
      <T h4 bold className={classes.titleBig}>
        <FormattedMessage {...i18n.discussionDetails} />
      </T>
      {!isPartnerConnectionNewProspect && (
        <>
          <T className={classes.title}>
            <FormattedMessage {...i18n.account} />
          </T>
          <AccountNameOwner
            partnerConnection={partnerConnection}
            isDisabled={isNonUser}
          />
        </>
      )}
      <T className={cx(classes.title, classes.marginBottom)}>
        <FormattedMessage {...i18n.participants} />
      </T>
      <ParticipantsDetails
        activeParticipants={activeParticipants}
        handleOpenModal={handleOpenModal}
        isDisabled={isLocked || isNonUser}
      />
      {!!partnerConnection?.kind && (
        <>
          <T className={cx(classes.title, classes.marginBottom)}>
            <FormattedMessage {...i18n.type} />
          </T>
          <Box className={classes.kindButton}>
            <KindCell
              row={partnerConnection}
              value={partnerConnection?.kind}
              fieldName="kind"
            />
          </Box>
        </>
      )}
      <T className={cx(classes.title, classes.marginBottom)}>
        <FormattedMessage {...i18n.stage} />
      </T>
      <Box className={classes.stageButton}>
        <StageCell
          row={partnerConnection}
          value={partnerConnection?.stage}
          fieldName="stage"
        />
      </Box>
      <T className={classes.title}>
        <FormattedMessage {...i18n.createdBy} />
      </T>
      <PartnerConnectionCreatedDetails partnerConnection={partnerConnection} />
    </div>
  );
};

const useStyles = makeStyles<{ isOutbound?: boolean; isNonUser?: boolean }>()(
  (theme, { isOutbound, isNonUser }) => ({
    kindButton: {
      maxWidth: "fit-content",
      ...(isOutbound && {
        padding: "4px 16px 4px 0px",
        background: theme.palette.greenBrand050,
        borderRadius: 4,
        width: "fit-content",
        height: 28,
        display: "flex",
        alignItems: "center",
      }),
      ...(isNonUser && {
        marginLeft: -10,
      }),
      "& > :not(:root) > .MuiButtonBase-root": {
        background: isOutbound
          ? theme.palette.greenBrand050
          : theme.palette.purpleBrand050,
        border: `1px solid ${
          isOutbound
            ? theme.palette.greenBrand050
            : theme.palette.purpleBrand050
        }`,
        padding: 0,
      },
    },
    stageButton: {
      maxWidth: "fit-content",
      ...(isNonUser && {
        marginLeft: -10,
      }),
      "& .MuiButtonBase-root": {
        background: theme.palette.greyLight050,
        border: `1px solid ${theme.palette.greyLight200}`,
      },
    },
    titleBig: {
      marginBottom: theme.spacing(0.5),
    },
    title: {
      color: theme.palette.alpha500,
      marginTop: theme.spacing(1.5),
    },
    marginBottom: {
      marginBottom: theme.spacing(0.5),
    },
  })
);

const i18n = {
  account: {
    id: "Datables.Pipeline.MessageDrawer.DiscussionDetails.account",
    defaultMessage: "Account",
  },
  createdBy: {
    id: "Datables.Pipeline.MessageDrawer.DiscussionDetails.createdBy",
    defaultMessage: "Created by",
  },
  discussionDetails: {
    id: "Datables.Pipeline.MessageDrawer.DiscussionDetails.discussionDetails",
    defaultMessage: "Collaboration details",
  },
  participants: {
    id: "Datables.Pipeline.MessageDrawer.DiscussionDetails.participants",
    defaultMessage: "Participants",
  },
  stage: {
    id: "Datables.Pipeline.MessageDrawer.DiscussionDetails.stage",
    defaultMessage: "Stage",
  },
  type: {
    id: "Datables.Pipeline.MessageDrawer.DiscussionDetails.type",
    defaultMessage: "Attribution",
  },
};
