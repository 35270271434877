import { PushPin, PushPinOutlined } from "@mui/icons-material";
import { Box, Grid } from "@mui/material";
import { Lock } from "components/icons";
import Button from "components/ui/Button";
import { DotStepper } from "components/ui/DotStepper";
import { T } from "components/ui/Typography";
import useHasPayingFeature from "hooks/useHasPayingFeature";
import useUserProfile from "hooks/useUserProfile";
import _ from "lodash";
import { makeStyles } from "makeStyles";
import { PayingFeature } from "models/CompanyPayingFeatureSubscription";
import Integration from "models/Integration";
import { PageType } from "models/PageView";
import { useContext, useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { mapping360PageTypes } from "redux/mapping360/defaults";
import { selectViews } from "redux/mapping360/selectors";
import { nearboundAccountsPageTypes } from "redux/mapping360NearboundAccounts/defaults";
import { selectViews as selectViewsNearboundAccounts } from "redux/mapping360NearboundAccounts/selectors";

import MappingFilterContext from "../../../shared/contexts/MappingFilterContext";
import { Facets } from "../../../shared/types";
import {
  identifyMyOverlaps,
  identifyMyOverlapsNearboundAccounts,
  IGoal,
  IGoalTeaserPreset,
  mapping360Goals,
  mapping360GoalsLocked,
  nearboundAccountsGoals,
  nearboundAccountsGoalsLocked,
} from "../goalsPresets";
import { addFacets } from "../utils";

type Props = {
  onSetDefaultView: (goal: IGoal) => void;
  onPin: (pageType: PageType) => void;
  sources?: Integration[];
  selectedSourceId?: number | null;
};

export const Mapping360Goals = ({
  onSetDefaultView,
  onPin,
  selectedSourceId,
  sources,
}: Props) => {
  const { profile } = useUserProfile();
  const { classes } = useStyles();
  const [facets, setFacets] = useState<Facets>({ [-1]: 0 });

  const is360NearboundAccountsUnlocked = useHasPayingFeature(
    PayingFeature.UseNew360,
    profile
  );

  const views = useSelector(
    is360NearboundAccountsUnlocked ? selectViewsNearboundAccounts : selectViews
  );
  const { defaultView } = useContext(MappingFilterContext);
  const pinnedGoalPageType = views.find(
    (view) =>
      view.pinned &&
      (is360NearboundAccountsUnlocked
        ? nearboundAccountsPageTypes
        : mapping360PageTypes
      ).includes(view.pageType)
  )?.pageType;

  const isGoalBased360Unlocked = useHasPayingFeature(
    PayingFeature.GoalBased360Unlocked,
    profile
  );

  useEffect(() => {
    let result: Facets = { [-1]: 0 };
    const selectedSource = sources?.find(({ id }) => id === selectedSourceId);
    if (selectedSource) {
      result = addFacets(result, selectedSource);
    } else {
      _.each(sources, (source: Integration) => {
        result = addFacets(result, source);
      });
    }
    setFacets(result);
  }, [sources, selectedSourceId]); // eslint-disable-line react-hooks/exhaustive-deps

  const handlePin = (goal: IGoalTeaserPreset) => (event: MouseEvent) => {
    event.preventDefault();
    event.stopPropagation();
    onPin(goal.pageType);
  };

  const updatedMapping360Goals = useMemo(() => {
    return rearrangeGoals(
      isGoalBased360Unlocked,
      is360NearboundAccountsUnlocked,
      pinnedGoalPageType
    );
  }, [
    pinnedGoalPageType,
    isGoalBased360Unlocked,
    is360NearboundAccountsUnlocked,
  ]);

  return (
    <Box>
      <DotStepper value={defaultView?.pageType}>
        {updatedMapping360Goals.map((goal) => (
          <DotStepper.Item
            key={goal.id}
            onClick={() => onSetDefaultView(goal)}
            value={goal.pageType}
          >
            <Grid container justifyContent="space-between">
              <Grid item>
                <T className={classes.title}>{goal.label}</T>
                <T className={classes.description}>{goal.description}</T>
              </Grid>
              <Grid container marginTop="6px">
                {(goal.id === identifyMyOverlaps.id ||
                  goal.id === identifyMyOverlapsNearboundAccounts.id) && (
                  <Grid
                    item
                    xs={12}
                    alignItems="center"
                    display="flex"
                    justifyContent="flex-end"
                  >
                    <T className={classes.alpha} bold>
                      {facets[-1]}
                    </T>
                  </Grid>
                )}
              </Grid>
            </Grid>
            <Box className={classes.absolute}>
              {isGoalBased360Unlocked && (
                <Button
                  LeftIcon={
                    pinnedGoalPageType === goal.pageType
                      ? PushPin
                      : PushPinOutlined
                  }
                  onClick={handlePin(goal)}
                  size="xxSmall"
                  variant="quinary"
                />
              )}
              {!isGoalBased360Unlocked && !goal.isAvailableFree && (
                <Lock className={classes.icon} />
              )}
            </Box>
          </DotStepper.Item>
        ))}
        <DotStepper.Pagination />
      </DotStepper>
    </Box>
  );
};

const rearrangeGoals = (
  isGoalBased360Unlocked: boolean,
  is360NearboundAccountsUnlocked: boolean,
  pinnedPageType?: PageType | null
) => {
  if (is360NearboundAccountsUnlocked) {
    if (!isGoalBased360Unlocked) {
      return nearboundAccountsGoalsLocked;
    }
    const pinnedGoal = nearboundAccountsGoals.find(
      (goal) => goal.pageType === pinnedPageType
    );
    if (!pinnedGoal) {
      return nearboundAccountsGoals;
    }

    return [
      pinnedGoal,
      ...nearboundAccountsGoals.filter(
        (goal) => goal.pageType !== pinnedPageType
      ),
    ];
  }

  if (!isGoalBased360Unlocked) {
    return mapping360GoalsLocked;
  }
  const pinnedGoal = mapping360Goals.find(
    (goal) => goal.pageType === pinnedPageType
  );
  if (!pinnedGoal) {
    return mapping360Goals;
  }

  return [
    pinnedGoal,
    ...mapping360Goals.filter((goal) => goal.pageType !== pinnedPageType),
  ];
};

const useStyles = makeStyles()((theme) => ({
  alpha: {
    color: theme.palette.alpha750,
  },
  icon: {
    height: 12,
    width: 12,
  },
  title: {
    fontWeight: 600,
    fontSize: 14,
    lineHeight: "21px",
    color: theme.palette.midnight,
  },
  description: {
    fontWeight: 400,
    fontSize: 12,
    lineHeight: "18px",
    color: theme.palette.alpha500,
  },
  absolute: {
    display: "flex",
    justifyContent: "flex-end",
    position: "absolute",
    right: 16,
    top: 12,
  },
}));
