import Collapse from "@mui/material/Collapse";
import ListItemButton from "@mui/material/ListItemButton";
import { alpha } from "@mui/material/styles";
import { ChevronDown, ChevronTop } from "components/icons";
import { T } from "components/ui/Typography";
import { makeStyles } from "makeStyles";
import { ReactElement, ReactNode, useCallback } from "react";

type Props = {
  label: ReactElement | string;
  tag: JSX.Element;
  children?: ReactNode;
  isUnfolded?: boolean;
  toggle?: (status: boolean) => void;
};

export const PremiumSection = ({
  label,
  tag,
  children,
  isUnfolded = false,
  toggle = () => {},
}: Props) => {
  const foldable = Boolean(children);
  const { classes } = useStyles({ foldable });

  const setOpen = useCallback(
    (status: boolean) => {
      if (foldable) {
        toggle(status);
      }
    },
    [foldable, toggle]
  );

  return (
    <div className={classes.widgetContainer}>
      <ListItemButton
        classes={{ root: classes.widgetButton }}
        onClick={() => setOpen(!isUnfolded)}
      >
        <T className={classes.title}>
          {label}
          {foldable &&
            (isUnfolded ? (
              <ChevronTop className={classes.icon} />
            ) : (
              <ChevronDown className={classes.icon} />
            ))}
        </T>
        {tag}
      </ListItemButton>
      <Collapse
        in={isUnfolded}
        timeout="auto"
        unmountOnExit
        className={classes.widgetContent}
      >
        {children}
      </Collapse>
    </div>
  );
};

/// CSS

const useStyles = makeStyles<{ foldable: boolean }>()(
  (theme, { foldable }) => ({
    widgetContainer: {
      background: theme.palette.comet,
      borderRadius: "4px",
    },
    widgetButton: {
      display: "flex",
      justifyContent: "space-between",
      borderRadius: "4px",
      color: theme.palette.offWhite,
      cursor: foldable ? "pointer" : "default",
      "&:hover": {
        background: foldable ? theme.palette.darkPigeon : theme.palette.comet,
      },
    },
    title: {
      display: "flex",
      alignItems: "center",
      columnGap: theme.spacing(0.5),
    },
    icon: {
      color: theme.palette.greyReveal,
      width: theme.spacing(2),
      height: theme.spacing(2),
    },
    widgetContent: {
      padding: "0px 16px",
    },
    alert: {
      backgroundColor: alpha(theme.palette.gold as string, 0.1),
      color: theme.palette.offWhite,
      marginTop: "4px",
      marginBottom: "16px",
    },
  })
);
