import { useState } from "react";

const useSort = (initialSort = {}) => {
  // @ts-expect-error ts-migrate(2339) FIXME: Property 'order' does not exist on type '{}'.
  const [order, setOrder] = useState(initialSort.order || null);
  // @ts-expect-error ts-migrate(2339) FIXME: Property 'by' does not exist on type '{}'.
  const [orderBy, setOrderBy] = useState(initialSort.by || null);

  const updateSort = (order: $TSFixMe, by: $TSFixMe) => {
    setOrder(order);
    setOrderBy(by);
  };

  return [order, orderBy, updateSort];
};

export default useSort;
