import { Settings } from "components/icons";
import Alert from "components/ui/Alert";
import { T } from "components/ui/Typography";
import useUserProfile from "hooks/useUserProfile";
import _ from "lodash";
import { makeStyles } from "makeStyles";
import { PartnershipWarnings } from "models/Partnership";
import { defineMessages, FormattedMessage, useIntl } from "react-intl";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { RevealStore } from "redux/typing";

const hasWarning = (warnings: PartnershipWarnings) => {
  return _(warnings)
    .values()
    .some((value) => value !== false && value !== null);
};

const AccountMappingInfo = () => {
  const { classes } = useStyles();
  const intl = useIntl();
  const { profile } = useUserProfile();
  const partnership = useSelector(
    (state: RevealStore) => state.accountMapping.currentPartnership
  );
  if (partnership === null) {
    return null;
  }
  const warnings = partnership.warnings(profile.company);

  /**
   * There is no warning to show whenever:
   *   - there is destCompany
   *   - there is no warning to show on any side (all flags are false or null)
   */
  if (
    partnership.destCompany === null ||
    (!hasWarning(warnings.company) && !hasWarning(warnings.partner))
  ) {
    return null;
  }

  const linkConfigure = (
    <Link target="_blank" to="/sources" className={classes.link}>
      <Settings />
      <T oldVariant="caption">
        <FormattedMessage {...i18n.configure} />
      </T>
    </Link>
  );

  /**
   * Compute the warning component inner content based on the following rules:
   *   - self warnings first
   *   - missing status rules > integration in progress > status rules in progress > disabled
   */
  let infoText;
  switch (true) {
    case warnings.company.missingStatusRules:
      infoText = (
        <>
          <FormattedMessage {...i18n.missingStatusRules} />
          &nbsp;
          {linkConfigure}
        </>
      );
      break;
    case warnings.partner.missingStatusRules:
      infoText = <FormattedMessage {...i18n.missingPartnerStatusRules} />;
      break;
    case warnings.company.statusRulesProgress !== null:
      infoText = (
        <FormattedMessage
          {...i18n.newStatusRules}
          values={{
            company: profile.company.name,
            percent: intl.formatNumber(
              warnings.company.statusRulesProgress || 0,
              {
                style: "percent",
                maximumFractionDigits: 0,
              }
            ),
          }}
        />
      );
      break;
    case warnings.partner.statusRulesProgress !== null:
      infoText = (
        <FormattedMessage
          {...i18n.newStatusRules}
          values={{
            company: partnership.getPartner(profile).name,
            percent: intl.formatNumber(
              warnings.partner.statusRulesProgress || 0,
              {
                style: "percent",
                maximumFractionDigits: 0,
              }
            ),
          }}
        />
      );
      break;
    case warnings.company.hasNoEnabledIntegration:
      infoText = (
        <>
          <FormattedMessage
            {...i18n.noEnabledSource}
            values={{ partner: partnership.getPartner(profile).name }}
          />
          &nbsp;
          {linkConfigure}
        </>
      );
      break;
    case warnings.partner.hasNoEnabledIntegration:
      infoText = (
        <FormattedMessage
          {...i18n.partnersNoEnabledSource}
          values={{ partner: partnership.getPartner(profile).name }}
        />
      );
      break;
    case warnings.company.hasDisabledIntegration:
      infoText = (
        <>
          <FormattedMessage
            {...i18n.someDisabledSource}
            values={{ partner: partnership.getPartner(profile).name }}
          />
          &nbsp;
          {linkConfigure}
        </>
      );
      break;
    case warnings.partner.hasDisabledIntegration:
      infoText = (
        <FormattedMessage
          {...i18n.partnersSomeDisabledSource}
          values={{ partner: partnership.getPartner(profile).name }}
        />
      );
      break;
  }

  return (
    <Alert variant="warning" icon>
      {infoText}
    </Alert>
  );
};

export default AccountMappingInfo;

// CSS

const useStyles = makeStyles()((theme) => ({
  link: {
    display: "flex",
    gap: 4,
    alignItems: "center",
    "& > svg": {
      fontSize: 12,
    },
    color: theme.palette.midnight,
    "&:hover": {
      cursor: "pointer",
      color: theme.palette.midnight,
    },
  },
}));

// I18N

const i18n = defineMessages({
  missingStatusRules: {
    id: "crm.AccountMapping.AccountMappingTable.missingStatusRules",
    defaultMessage: "Status rules are missing in one of your data sources.",
  },
  missingPartnerStatusRules: {
    id: "crm.AccountMapping.AccountMappingTable.missingPartnerStatusRules",
    defaultMessage: "A configuration is missing in your partner’s data source.",
  },
  configure: {
    id: "crm.AccountMapping.AccountMappingTable.configure",
    defaultMessage: "Configure",
  },
  newStatusRules: {
    id: "crm.AccountMapping.AccountMappingTable.newStatusRules",
    defaultMessage:
      "This Account Mapping is currently being refreshed because {company} status rules have changed. ({percent})",
  },
  noEnabledSource: {
    id: "crm.AccountMapping.AccountMappingTable.noEnabledSource",
    defaultMessage:
      "Your data sources have been disconnected, data remains shared with {partner} but will no longer refresh. ",
  },
  someDisabledSource: {
    id: "crm.AccountMapping.AccountMappingTable.someDisabledSource",
    defaultMessage:
      "Some of your data sources have been disconnected, data from these sources remains shared with {partner} but will no longer refresh",
  },
  partnersNoEnabledSource: {
    id: "crm.AccountMapping.AccountMappingTable.partnersNoEnabledSource",
    defaultMessage:
      "Data sources have been disconnected by {partner}, data will no longer refresh.",
  },
  partnersSomeDisabledSource: {
    id: "crm.AccountMapping.AccountMappingTable.partnersSomeDisabledSource",
    defaultMessage:
      "Some data sources have been disconnected by {partner}, data from these sources will no longer refresh.",
  },
});
