import {
  ColumnConfigType,
  MatchFilterType,
  orderColumns,
} from "screens/Frontoffice/screens/DataTables/shared/types";

export enum AvailablePipelineAttributeColumns {
  opportunityName = "name",
  accountName = "rawCompanyName",
  amount = "amountDisplayValue",
  stage = "stage",
  owner = "ownerFullName",
  closeDate = "closedDate",
  sourcingPartner = "sourcingPartner",
  influencingPartner = "influencingPartner",
  partnerDiscussions = "partnerDiscussionList",
  status = "status",
}

const defaultPipelineAttributeColumnKeys = [
  AvailablePipelineAttributeColumns.opportunityName,
  AvailablePipelineAttributeColumns.accountName,
  AvailablePipelineAttributeColumns.amount,
  AvailablePipelineAttributeColumns.stage,
  AvailablePipelineAttributeColumns.owner,
  AvailablePipelineAttributeColumns.closeDate,
  AvailablePipelineAttributeColumns.sourcingPartner,
  AvailablePipelineAttributeColumns.influencingPartner,
  AvailablePipelineAttributeColumns.partnerDiscussions,
];

export const pipelineAttributeColumnConfig: {
  [key: string]: ColumnConfigType;
} = {
  [AvailablePipelineAttributeColumns.opportunityName]: {
    key: AvailablePipelineAttributeColumns.opportunityName,
    width: 310,
    frozen: true,
    removable: false,
    fixedPosition: 1,
  },
  [AvailablePipelineAttributeColumns.accountName]: {
    key: AvailablePipelineAttributeColumns.accountName,
    width: 150,
    frozen: false,
    removable: true,
  },
  [AvailablePipelineAttributeColumns.amount]: {
    key: AvailablePipelineAttributeColumns.amount,
    width: 160,
    frozen: false,
    removable: true,
  },
  [AvailablePipelineAttributeColumns.stage]: {
    key: AvailablePipelineAttributeColumns.stage,
    width: 150,
    frozen: false,
    removable: true,
  },
  [AvailablePipelineAttributeColumns.owner]: {
    key: AvailablePipelineAttributeColumns.owner,
    width: 93,
    frozen: false,
    removable: true,
  },
  [AvailablePipelineAttributeColumns.closeDate]: {
    key: AvailablePipelineAttributeColumns.closeDate,
    width: 140,
    frozen: false,
    removable: true,
  },
  [AvailablePipelineAttributeColumns.sourcingPartner]: {
    key: AvailablePipelineAttributeColumns.sourcingPartner,
    width: 140,
    frozen: false,
    removable: true,
  },
  [AvailablePipelineAttributeColumns.influencingPartner]: {
    key: AvailablePipelineAttributeColumns.influencingPartner,
    width: 132,
    frozen: false,
    removable: true,
  },
  [AvailablePipelineAttributeColumns.partnerDiscussions]: {
    key: AvailablePipelineAttributeColumns.partnerDiscussions,
    width: 124,
    frozen: false,
    removable: true,
  },
};

export const defaultPipelineAttributeColumnConfig = (
  key: string,
  field: { label: string }
) => ({
  key,
  width: 100,
  frozen: false,
});

export const applyPipelineAttributeColumnOrder = (
  columns: ColumnConfigType[]
) => orderColumns(columns);

export const defaultPipelineAttributeColumns = applyPipelineAttributeColumnOrder(
  defaultPipelineAttributeColumnKeys.map(
    (key) => pipelineAttributeColumnConfig[key]
  )
);

export const defaultPipelineAttributeSort = [];

export const getDefaultPipelineFilters = (userId: number) => [
  {
    fieldname: "user",
    type: MatchFilterType.ANY_OF,
    value: [userId + ""],
  },
];
