import _ from "lodash";
import { useCallback, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { persist } from "redux/storage/actions";
import { RevealStore } from "redux/typing";

const useStorage = <T = any>(path: string, options: { default?: T } = {}) => {
  _.defaults(options, { default: null });
  const [value, setValue] = useState<T>(
    useSelector((state: RevealStore) =>
      _.get(state.storage, path, options.default)
    )
  );
  const dispatch = useDispatch();
  const updateStorage = useCallback(
    (value: T) => {
      setValue(value);
      dispatch(persist({ key: path, value }));
    },
    [dispatch, path]
  );
  const hash = JSON.stringify(value);
  return useMemo(() => ({ value, updateStorage }), [hash, updateStorage]); // eslint-disable-line react-hooks/exhaustive-deps
};

export default useStorage;
