import { CircularProgress, Divider, Grid } from "@mui/material";
import { withCompanyPayingFeatures } from "components/hoc/withCompanyPayingFeatures";
import withCompanyPermissions from "components/hoc/withCompanyPermissions";
import { ChevronRight } from "components/icons";
import Box from "components/ui/Box";
import SettingsSection from "components/ui/SettingsSection";
import Toggle from "components/ui/Toggle";
import { T } from "components/ui/Typography";
import _ from "lodash";
import { makeStyles } from "makeStyles";
import { PayingFeature } from "models/CompanyPayingFeatureSubscription";
import { MouseEvent, useState } from "react";
import { defineMessages, FormattedMessage } from "react-intl";

import AccountSettings from "../../../components/AccountSettings";
import { useGetIntroTemplate } from "../hooks/useGetIntroTemplate";
import { GetIntroTemplateDrawer } from "./GetIntroTemplateDrawer";

const GetIntroTemplate = () => {
  const {
    loading,
    introRequestForm,
    toggleEnabled,
    setRecordId,
  } = useGetIntroTemplate();
  const isConfigured = !!introRequestForm;
  const allPartners = Boolean(
    isConfigured &&
      introRequestForm?.isEnabled &&
      _.isEmpty(introRequestForm?.partnershipIds)
  );
  const { classes } = useStyles({ isConfigured });
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);

  const handleClick = (event: MouseEvent) => {
    toggleEnabled();
    event.preventDefault();
    event.stopPropagation();
  };

  if (loading) {
    return (
      <div className={classes.loader}>
        <CircularProgress />
      </div>
    );
  }

  return (
    <AccountSettings tab="get-intro-templates">
      <Grid item className={classes.root}>
        <SettingsSection
          title={<FormattedMessage {...i18n.title} />}
          subtitle={<FormattedMessage {...i18n.description} />}
          iconSrc="/images/template-purplebg.svg"
        >
          <Box
            variant={"hover"}
            containerClassName={classes.container}
            onClick={() => setIsDrawerOpen(true)}
          >
            <div className={classes.row}>
              <div className={classes.toggleContainer}>
                <Toggle
                  checked={introRequestForm?.isEnabled ?? false}
                  onClick={handleClick}
                  disabled={introRequestForm === null}
                />
                <div className={classes.toggleTitle}>
                  <T titleSmall>
                    <FormattedMessage {...i18n.myCustomTemplate} />
                  </T>
                  <T titleSmall className={classes.description}>
                    <FormattedMessage {...i18n.formDescription} />
                  </T>
                </div>
              </div>
              <ChevronRight style={{ fontSize: 18 }} />
            </div>
            <Divider className={classes.divider} />
            <div className={classes.row}>
              <div className={classes.textContainer}>
                <T titleSmall className={classes.description}>
                  <FormattedMessage {...i18n.appliedTo} />
                </T>
                <T>
                  <FormattedMessage
                    {...i18n[allPartners ? "allPartners" : "partnerCount"]}
                    values={{
                      count: introRequestForm?.isEnabled
                        ? introRequestForm.partnershipIds.length
                        : 0,
                    }}
                  />
                </T>
              </div>
              <div className={classes.tag}>
                <T uppercase noWrap>
                  <FormattedMessage
                    {...(isConfigured ? i18n.configured : i18n.notConfigured)}
                  />
                </T>
              </div>
            </div>
          </Box>
          <GetIntroTemplateDrawer
            isDrawerOpen={isDrawerOpen}
            closeDrawer={() => setIsDrawerOpen(false)}
            introRequestForm={introRequestForm}
            setRecordId={setRecordId}
          />
        </SettingsSection>
      </Grid>
    </AccountSettings>
  );
};

export default withCompanyPayingFeatures([
  PayingFeature.IntroductionRequestForms,
])(withCompanyPermissions(["company.manage_admins"])(GetIntroTemplate));

export const useStyles = makeStyles<{ isConfigured: Boolean }>()(
  (theme, { isConfigured }) => ({
    root: {
      display: "flex",
      flexDirection: "column",
      rowGap: theme.spacing(4),
    },
    loader: {
      display: "flex",
      justifyContent: "center",
      marginTop: 100,
    },
    container: {
      display: "flex",
      flexDirection: "column",
      border: `1px solid ${theme.palette.greyLight100}`,
      padding: "16px !important",
      borderRadius: 6,
    },
    row: {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
    },
    divider: {
      margin: "16px 0",
      color: theme.palette.greyLight050,
    },
    toggleContainer: {
      display: "flex",
      alignItems: "center",
      columnGap: 15,
    },
    toggleTitle: {
      display: "flex",
      flexDirection: "column",
      rowGap: 2,
    },
    textContainer: {
      display: "flex",
      alignItems: "center",
      columnGap: 4,
    },
    description: {
      fontWeight: 400,
      color: theme.palette.alpha500,
    },
    tag: {
      backgroundColor: isConfigured
        ? theme.palette.greenBrand100
        : theme.palette.red100,
      color: isConfigured
        ? theme.palette.greenBrand700
        : theme.palette.red700v2,
      borderRadius: 4,
      padding: "5px 6px",
      overflow: "hidden",
    },
  })
);

const i18n = defineMessages({
  title: {
    id: "GetIntroTemplate.title",
    defaultMessage: "Get intro template",
  },
  description: {
    id: "GetIntroTemplate.description",
    defaultMessage:
      "Make the most out of Get Intro by tailoring the process to your needs, and ensuring that your team provide important information that will set them up for success.",
  },
  myCustomTemplate: {
    id: "GetIntroTemplate.myCustomTemplate",
    defaultMessage: "Get Intro custom template",
  },
  formDescription: {
    id: "GetIntroTemplate.formDescription",
    defaultMessage:
      "Customize the Get Intro template that will be displayed to your Sales team when submitting a Get Intro request from the Reveal widget on Salesforce.",
  },
  appliedTo: {
    id: "GetIntroTemplate.appliedTo",
    defaultMessage: "Applied to:",
  },
  allPartners: {
    id: "GetIntroTemplate.allPartners",
    defaultMessage: "All partners",
  },
  partnerCount: {
    id: "GetIntroTemplate.partnerCount",
    defaultMessage: "{count} {count, plural, one {partner} other {partners}}",
  },
  notConfigured: {
    id: "GetIntroTemplate.notConfigured",
    defaultMessage: "Not configured",
  },
  configured: {
    id: "GetIntroTemplate.configured",
    defaultMessage: "Configured",
  },
});
