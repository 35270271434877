import { MoreHoriz } from "@mui/icons-material";
import { IconButton } from "@mui/material";
import { T } from "components/ui/Typography";
import { makeStyles } from "makeStyles";
import { MouseEvent, ReactElement, useState } from "react";

import Dropdown from "./Dropdown/components/Dropdown";
import { IDropdownOption } from "./Dropdown/components/types";
import Tooltip from "./Tooltip";

/**
 * This type, and therefore this component, is parameterized to allow
 * for a more restrictive type checking. Instead of allowing for arbitrary
 * strings it's better to create an enum for them and that enum would be
 * the parameter here.
 *
 * cf. `PartnershipSettings/constants.tsx`
 */
export type Tab<T> = {
  disabled?: boolean;
  value: T;
  label: React.ReactNode;
  count?: number;
  tooltip?: React.ReactNode;
};

type Props<T> = {
  itemCountBeforeDropdown?: number;
  tabs: Tab<T>[];
  selectedValue: T;
  setValue: (tabValue: T) => void;
};

const TabList = <T extends string | number>({
  itemCountBeforeDropdown,
  tabs,
  selectedValue,
  setValue,
}: Props<T>) => {
  const { classes, cx } = useStyles();
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

  const handleClick = (event: MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const options: IDropdownOption[] = itemCountBeforeDropdown
    ? tabs.slice(itemCountBeforeDropdown).map(({ value, label }) => ({
        value: String(value),
        label: label as ReactElement,
        id: String(value),
        name: String(value),
      }))
    : [];

  const itemsBeforeDropdown = itemCountBeforeDropdown
    ? tabs.slice(0, itemCountBeforeDropdown)
    : tabs;

  return (
    <div className={classes.container}>
      <div className={classes.root}>
        {itemsBeforeDropdown.map(
          ({ count, value, label, disabled, tooltip }) => (
            <Tooltip title={tooltip ?? ""}>
              <div
                onClick={disabled ? undefined : () => setValue(value)}
                key={value}
                className={cx(classes.tab, {
                  [classes.selected]: selectedValue === value,
                  [classes.disabled]: disabled,
                })}
              >
                <T labelSmall>{label}</T>
                {count !== undefined && (
                  <T
                    labelSmall
                    className={cx(classes.count, {
                      [classes.countSelected]: selectedValue === value,
                    })}
                  >
                    {count}
                  </T>
                )}
              </div>
            </Tooltip>
          )
        )}
      </div>
      {options.length > 0 && (
        <IconButton className={classes.iconButton} onClick={handleClick}>
          <MoreHoriz className={classes.icon} />
          <Dropdown
            options={options}
            anchorEl={anchorEl}
            onClose={handleClose}
            open={!!anchorEl}
            size="small"
            onChange={(ids: string[] | null) =>
              ids?.[0] && setValue(ids[0] as T)
            }
            withSearch={false}
            value={selectedValue as string}
          />
        </IconButton>
      )}
    </div>
  );
};

const useStyles = makeStyles()((theme) => ({
  container: {
    display: "flex",
  },
  icon: {
    color: theme.palette.alpha800,
  },
  iconButton: {
    height: 38,
  },
  root: {
    display: "flex",
    borderRadius: 18,
    background: theme.palette.greyLight100,
    padding: 4,
  },
  tab: {
    borderRadius: 18,
    padding: "8px 12px",
    display: "flex",
    alignItems: "center",
    margin: 1,
    cursor: "pointer",
    "&:hover": {
      backgroundColor: theme.palette.darkTaupe,
    },
  },
  selected: {
    "&&": {
      backgroundColor: theme.palette.midnight,
      color: theme.palette.offWhite,
    },
  },
  disabled: {
    "&&": {
      color: theme.palette.alpha400,
      cursor: "not-allowed",
    },
  },
  count: {
    color: theme.palette.midnight,
    backgroundColor: theme.palette.taupe500,
    padding: "4px 6px",
    borderRadius: 24,
    marginLeft: 8,
  },
  countSelected: {
    color: theme.palette.offWhite,
    backgroundColor: theme.palette.darkPigeon,
  },
}));

export default TabList;
