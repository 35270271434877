import { SvgIconComponent } from "@mui/icons-material";
import {
  SignalHigh,
  SignalLow,
  SignalMedium,
  SignalNone,
  SignalVeryHigh,
} from "components/icons";
import { defineMessages, MessageDescriptor } from "react-intl";

enum ContactOverlapRange {
  Medium = 0.02,
  High = 0.03,
  VeryHigh = 0.07,
}

export const overlapToDisplayContent = (overlap: number | null) => {
  let icon: SvgIconComponent | null;
  let text: MessageDescriptor | null;
  if (!overlap && overlap !== 0) {
    icon = null;
    text = i18n.contactOverlapEmptyValue;
  } else if (overlap === 0) {
    icon = SignalNone;
    text = i18n.contactOverlapNone;
  } else if (0 < overlap && ContactOverlapRange.Medium > overlap) {
    icon = SignalLow;
    text = i18n.contactOverlapLow;
  } else if (
    ContactOverlapRange.Medium <= overlap &&
    ContactOverlapRange.High > overlap
  ) {
    icon = SignalMedium;
    text = i18n.contactOverlapMedium;
  } else if (
    ContactOverlapRange.High <= overlap &&
    ContactOverlapRange.VeryHigh > overlap
  ) {
    icon = SignalHigh;
    text = i18n.contactOverlapHigh;
  } else if (ContactOverlapRange.VeryHigh <= overlap) {
    icon = SignalVeryHigh;
    text = i18n.contactOverlapVeryHigh;
  } else {
    icon = null;
    text = i18n.contactOverlapEmptyValue;
  }

  return { icon, text };
};

const i18n = defineMessages({
  contactOverlapEmptyValue: {
    id: "Directory.DirectoryTile.contactOverlapEmptyValue",
    defaultMessage: "--",
  },
  contactOverlapNone: {
    id: "Directory.DirectoryTile.contactOverlapNone",
    defaultMessage: "None",
  },
  contactOverlapLow: {
    id: "Directory.DirectoryTile.contactOverlapLow",
    defaultMessage: "Low",
  },
  contactOverlapMedium: {
    id: "Directory.DirectoryTile.contactOverlapMedium",
    defaultMessage: "Medium",
  },
  contactOverlapHigh: {
    id: "Directory.DirectoryTile.contactOverlapHigh",
    defaultMessage: "High",
  },
  contactOverlapVeryHigh: {
    id: "Directory.DirectoryTile.contactOverlapVeryHigh",
    defaultMessage: "Very high",
  },
});
