import { createSvgIcon } from "@mui/material/utils";

import { SvgIcon } from "./types";

const displayName = "Offline";

const Offline: SvgIcon = createSvgIcon(
  // Using transform to enlarge from provided viewbox="0 0 16 16"
  // to default "0 0 24 24" from `createSvgIcon` -> `SvgIcon`.
  <g fill="none" transform="matrix(1.5,0,0,1.5,0,0)">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4.84908 3.62719C3.82116 4.0548 2.89649 4.68525 2.12372 5.46924C1.95874 5.63662 1.95876 5.90798 2.12378 6.07533C2.2888 6.24268 2.55632 6.24266 2.72131 6.07527C3.49865 5.28665 4.44623 4.67133 5.5034 4.29089L4.84908 3.62719Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6.63801 5.44176C5.51377 5.70391 4.50891 6.28346 3.72064 7.08193C3.55552 7.24918 3.55534 7.52054 3.72023 7.68802C3.88512 7.8555 4.15265 7.85569 4.31776 7.68844C5.12051 6.8753 6.18243 6.32679 7.36721 6.18141L6.63801 5.44176Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.55327 6.17227L7.68718 5.29377C7.79079 5.28842 7.89508 5.28571 8 5.28571C9.67072 5.28571 11.1841 5.97251 12.2794 7.08193C12.4445 7.24918 12.4447 7.52054 12.2798 7.68802C12.1149 7.8555 11.8473 7.85569 11.6822 7.68844C10.8616 6.85715 9.77003 6.30244 8.55327 6.17227Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.8281 7.66323L10.5469 9.40662C10.3952 9.46659 10.2163 9.43434 10.094 9.30996C9.5581 8.765 8.81817 8.42857 7.99998 8.42857C7.18178 8.42857 6.44185 8.765 5.90595 9.30996C5.74116 9.47754 5.47363 9.47788 5.30842 9.31073C5.14321 9.14357 5.14287 8.87222 5.30766 8.70464C5.99561 8.00506 6.94827 7.57143 7.99998 7.57143C8.28435 7.57143 8.56148 7.60313 8.8281 7.66323Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6.43508 4.02374L5.7376 3.31627C6.45691 3.11025 7.21581 3 8 3C10.2948 3 12.373 3.9441 13.8763 5.46924C14.0413 5.63662 14.0412 5.90798 13.8762 6.07533C13.7112 6.24268 13.4437 6.24266 13.2787 6.07527C11.9274 4.70434 10.0616 3.85714 8 3.85714C7.46321 3.85714 6.93969 3.91458 6.43508 4.02374Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.12669 10.8571C9.12669 11.4883 8.62224 12 7.99997 12C7.37771 12 6.87326 11.4883 6.87326 10.8571C6.87326 10.226 7.37771 9.71429 7.99997 9.71429C8.62224 9.71429 9.12669 10.226 9.12669 10.8571ZM8.28165 10.8571C8.28165 11.0149 8.15554 11.1429 7.99997 11.1429C7.84441 11.1429 7.7183 11.0149 7.7183 10.8571C7.7183 10.6993 7.84441 10.5714 7.99997 10.5714C8.15554 10.5714 8.28165 10.6993 8.28165 10.8571Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3.12392 2.12392C3.28914 1.95869 3.55702 1.95869 3.72224 2.12392L13.8761 12.2778C14.0413 12.443 14.0413 12.7109 13.8761 12.8761C13.7109 13.0413 13.443 13.0413 13.2778 12.8761L3.12392 2.72224C2.95869 2.55702 2.95869 2.28914 3.12392 2.12392Z"
      fill="currentColor"
    />
  </g>,
  displayName
);

Offline.displayName = displayName;
export default Offline;
