import getFirstValueFromURL from "helpers/getFirstValueFromURL";
import useUserProfile from "hooks/useUserProfile";
import { PayingFeature } from "models/CompanyPayingFeatureSubscription";
import { useSelector } from "react-redux";
import { matchPath } from "react-router";
import { useLocation } from "react-router-dom";
import { selectActivePayingFeatures } from "redux/api/selectors";

import { RequestIntroDialog } from "./RequestIntroDialog";

export const RequestIntro = () => {
  const location = useLocation();
  const { profile } = useUserProfile();
  const payingFeatures = useSelector(selectActivePayingFeatures);
  const isPaidUser = payingFeatures.includes(
    // We rely on this basic feature for now as we don't have any related to intro requests.
    PayingFeature.UnlimitedPipelineSharing
  );

  const accountId = getFirstValueFromURL(location, "selected-intro") ?? null;
  const partnerId = getFirstValueFromURL(location, "partner-intro") ?? null;

  const isFromAccountMapping = !!matchPath(
    location.pathname,
    "/partnerships/:recordId/account-mapping"
  );

  return accountId && profile.canRequestIntro && isPaidUser ? (
    <RequestIntroDialog
      accountId={+accountId}
      partnerId={partnerId ? +partnerId : undefined}
      isFixedPartner={isFromAccountMapping}
    />
  ) : null;
};
