import { configureStore } from "@reduxjs/toolkit";
import { combineReducers } from "redux";

import config from "./config";
import accountMapping from "./redux/accountMapping/reducers";
import analytics from "./redux/analytics/reducers";
import api from "./redux/api/reducers";
import dashboard from "./redux/dashboard/reducers";
import directory from "./redux/directory/reducers";
import context from "./redux/init/reducers";
import mapping360 from "./redux/mapping360/reducers";
import nearboundAccounts from "./redux/mapping360NearboundAccounts/reducers";
import measure from "./redux/measure/reducers";
import nonUser from "./redux/nonUser/reducers";
import notifications from "./redux/notifications/reducers";
import notificationWidget from "./redux/notificationWidget/reducers";
import onboarding from "./redux/onboarding/reducers";
import overview from "./redux/overview/reducers";
import pipeline from "./redux/pipeline/reducers";
import pipelineAttribute from "./redux/pipelineAttribute/reducers";
import premiumPlanWidget from "./redux/premiumPlanWidget/reducers";
import storage from "./redux/storage/reducers";
import suggestedPartners from "./redux/suggestedPartners/reducers";
import upsides from "./redux/upsides/reducers";
import user from "./redux/user/reducers";

const requiredReducers = {
  api,
  context,
  notifications,
  storage,
  user,
};

const frontofficeReducers = {
  accountMapping,
  analytics,
  dashboard,
  directory,
  mapping360,
  nearboundAccounts,
  notificationWidget,
  onboarding,
  overview,
  pipeline,
  pipelineAttribute,
  premiumPlanWidget,
  suggestedPartners,
  upsides,
  nonUser,
  measure,
};

const reducer = combineReducers({
  ...requiredReducers,
  ...(config().appName === "partners" ? frontofficeReducers : {}),
});

const saveState = () => {
  const { storage } = store.getState();
  try {
    // Persist all objects from storage store
    localStorage.persistedStore = JSON.stringify(storage);
  } catch (err) {
    // Ignore errors
  }
};

const loadState = () => {
  try {
    return {
      storage: JSON.parse(localStorage.persistedStore) || undefined,
    };
  } catch (err) {
    return {};
  }
};

export const store = configureStore({
  reducer,
  preloadedState: loadState(),
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});

store.subscribe(saveState);

export type AppDispatch = typeof store.dispatch;
