import { createSvgIcon } from "@mui/material/utils";

import { SvgIcon } from "./types";

const displayName = "Trash";

const Trash: SvgIcon = createSvgIcon(
  // Using transform to enlarge from provided viewbox="0 0 16 16"
  // to default "0 0 24 24" from `createSvgIcon` -> `SvgIcon`.
  <g fill="none" transform="matrix(1.5,0,0,1.5,1,0)">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6.5641 6.74365C6.86148 6.74365 7.10256 6.98473 7.10256 7.28211V10.8719C7.10256 11.1692 6.86148 11.4103 6.5641 11.4103C6.26671 11.4103 6.02563 11.1692 6.02563 10.8719V7.28211C6.02563 6.98473 6.26671 6.74365 6.5641 6.74365Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.43592 6.74365C9.73331 6.74365 9.97438 6.98473 9.97438 7.28211V10.8719C9.97438 11.1692 9.73331 11.4103 9.43592 11.4103C9.13854 11.4103 8.89746 11.1692 8.89746 10.8719V7.28211C8.89746 6.98473 9.13854 6.74365 9.43592 6.74365Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6.5641 1C5.47369 1 4.58974 1.88395 4.58974 2.97436V3.15385H1.53846C1.24108 3.15385 1 3.39492 1 3.69231C1 3.98969 1.24108 4.23077 1.53846 4.23077H2.4359V13.0256C2.4359 14.116 3.31985 15 4.41026 15H11.5897C12.6802 15 13.5641 14.116 13.5641 13.0256V4.23077H14.4615C14.7589 4.23077 15 3.98969 15 3.69231C15 3.39492 14.7589 3.15385 14.4615 3.15385H11.4103V2.97436C11.4103 1.88395 10.5263 1 9.4359 1H6.5641ZM10.3333 3.15385V2.97436C10.3333 2.47872 9.93154 2.07692 9.4359 2.07692H6.5641C6.06846 2.07692 5.66667 2.47872 5.66667 2.97436V3.15385H10.3333ZM3.51282 4.23077V13.0256C3.51282 13.5213 3.91462 13.9231 4.41026 13.9231H11.5897C12.0854 13.9231 12.4872 13.5213 12.4872 13.0256V4.23077H3.51282Z"
      fill="currentColor"
    />
  </g>,
  displayName
);

Trash.displayName = displayName;
export default Trash;
