import { CircularProgress } from "@mui/material";
import Alert from "@mui/material/Alert";
import { AxiosResponse } from "axios";
import withAPI, { WithAPI } from "components/hoc/withAPI";
import {
  emptyNode,
  isSWQLEmptyNode,
  SWQLNode,
  SWQLTarget,
} from "components/ui/SWQL/SWQLTypes";
import { simplifySwqlQuery } from "components/ui/SWQL/utils";
import useAllRecords from "hooks/useAllRecords";
import useIsSwQLReady from "hooks/useIsSwQLReady";
import usePushNotification from "hooks/usePushNotification";
import useSegment from "hooks/useSegment";
import generic from "i18n/generic";
import _ from "lodash";
import { makeStyles } from "makeStyles";
import { Factory } from "models";
import CrmField from "models/CrmField";
import Record from "models/Record";
import { useEffect, useState } from "react";
import { defineMessages, FormattedMessage, useIntl } from "react-intl";
import JSONAPIService from "services/JSONAPIService";
import { JSONAPIListResponse } from "services/types";

import { MappingRuleRow } from "./MappingRuleRow";

export type RuleKeyAndLabel = {
  key: any;
  label: JSX.Element;
};

type MappingRule = {
  value: number;
  crmFields: CrmField[];
  // $TSFixMe -- We should allow `undefined` XOR `null`.
  progress?: number | null;
};

const getProgress = (rules: MappingRule[]) =>
  _(rules)
    .map((rule) => rule.progress)
    .filter((progress) => progress !== null && progress !== undefined)
    .min();

export type MappingRulesWidgetProps = {
  // boolean use to trigger save
  saving: boolean;
  // boolean use to trigger cancel
  cancelling?: boolean;
  integrationId: string | number;
  ruleList: RuleKeyAndLabel[];
  attribute: string;
  trackedLocation?: string;
  saveCallback?: (emptyRules?: boolean) => void;
  noFieldsCallback?: () => void;
  swqlTarget: SWQLTarget;
  isGhost?: boolean;
  setActionsEnabled?: (value: boolean) => void;
  afterAction?: () => void;
  hasDisplayedRulesCallback?: (hasDisplayedRules: boolean) => void;
};

const MappingRulesWidget = ({
  saving,
  cancelling = false,
  integrationId,
  ruleList,
  attribute,
  trackedLocation,
  saveCallback,
  noFieldsCallback,
  swqlTarget,
  rawPost,
  isGhost,
  setActionsEnabled,
  afterAction = () => {},
  hasDisplayedRulesCallback,
}: WithAPI & MappingRulesWidgetProps) => {
  /**
   * CUSTOM HOOKS, STATE AND VARIABLES
   */

  const { classes } = useStyles();
  const intl = useIntl();
  const pushNotification = usePushNotification();
  const { track } = useSegment();
  const { loading: loadingFields, hasFields } = useIsSwQLReady(
    +integrationId,
    swqlTarget
  );
  const {
    records: mappingRuleList,
    loading: loadingRules,
    refresh,
  } = useAllRecords<MappingRule & Record<"mapping_rules">>("mapping_rules", {
    filters: {
      integration: integrationId,
      attribute: attribute,
      record_type: swqlTarget,
    },
    include: ["crm_fields"],
  });

  const [loadingDefaultRules, setLoadingDefaultRules] = useState<boolean>(true);
  const [defaultRulesFields, setDefaultRulesFields] = useState<CrmField[]>([]);
  const [defaultRules, setDefaultRules] = useState<{
    [ruleKey: number]: SWQLNode;
  }>(ruleList.reduce((agg, rule) => ({ ...agg, [rule.key]: emptyNode() }), {}));
  // Rules retrieved from backend
  const [storedRules, setStoredRules] = useState<{
    [ruleKey: number]: SWQLNode;
  }>({});
  // New rules to update
  const [rules, setRules] = useState<{ [ruleKey: number]: SWQLNode }>({});

  const loading = loadingFields || loadingRules || loadingDefaultRules;
  const progress = getProgress(mappingRuleList);
  const fields = defaultRulesFields.concat(
    ...mappingRuleList.map((item: MappingRule) => item.crmFields)
  );
  const hasDisplayedRules =
    !!Object.values(rules)[0]?.type &&
    Object.values(rules)[0]?.type !== "empty";

  /**
   * FUNCTIONS
   */

  const handleSave = async () => {
    if (setActionsEnabled) {
      setActionsEnabled(false);
    }
    const newRules = { ...rules };
    for (var key in newRules) {
      // @ts-expect-error ts-migrate(7053) FIXME: Type '{} | SWQLNode' is not assignable to type 'SWQLNode'.
      newRules[key] = simplifySwqlQuery(newRules[key]);
    }
    setRules(newRules);

    await rawPost(
      "integrations",
      +integrationId,
      "/mapping-rules/",
      {
        data: _.toPairs(newRules).map(([value, query]) => ({
          type: "mapping_rules",
          attributes: {
            attribute,
            query: simplifySwqlQuery(query),
            value: value === "true" ? true : +value,
            record_type: swqlTarget,
          },
        })),
      },
      {
        config: {
          headers: {
            "Content-Type": "application/json",
          },
        },
      },
      {
        url_resource: isGhost ? "ghost_integrations" : null,
      }
    )
      .then((resp: $TSFixMe) => {
        if (resp?.error) {
          pushNotification("default_error");
        } else {
          pushNotification({ ...generic.edits_saved });
          track("Edited " + trackedLocation);
          refresh();
        }
      })
      .finally(() => {
        if (setActionsEnabled) {
          setActionsEnabled(false);
        }
        if (saveCallback) {
          const emptyRules = Object.values(newRules).every(
            (dict) => Object.keys(dict).length === 0
          );
          saveCallback(emptyRules);
        }
      });
  };

  const fetchMetaAndSetDefaultRule = async () => {
    if (
      swqlTarget === SWQLTarget.RawOpportunity ||
      swqlTarget === SWQLTarget.RawUser
    ) {
      const attributeValue = ruleList[0].key;
      const service = new JSONAPIService("integrations");
      const response = (await service.fetchRelated(
        +integrationId,
        "default-mapping-rules/",
        {
          include: ["crm_fields"],
        }
      )) as AxiosResponse<JSONAPIListResponse>;

      const defaultRule = response?.data.data.find(
        (resource) =>
          resource.attributes?.record_type === swqlTarget &&
          resource.attributes?.attribute === attribute &&
          resource.attributes?.value === attributeValue
      );
      let defaultQuery: undefined | SWQLNode = undefined;
      if (defaultRule?.attributes?.query) {
        defaultQuery = defaultRule.attributes.query as SWQLNode;
      }
      if (defaultQuery && response.data.included) {
        setDefaultRulesFields(
          response.data.included.map(
            (resource) => Factory.createRecord(resource) as CrmField
          )
        );
      }
      setDefaultRules({
        [attributeValue]: defaultQuery || emptyNode(),
      });
    }
  };

  /**
   * USE EFFECTS
   */

  useEffect(() => {
    track("Viewed data " + trackedLocation);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (!loadingFields && !hasFields && noFieldsCallback) {
      noFieldsCallback();
    }
  }, [loadingFields]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (!loadingDefaultRules) {
      const result = {};
      _.each(ruleList, (item: RuleKeyAndLabel) => {
        const record = _(mappingRuleList).find(
          (mappingRule: MappingRule) => mappingRule.value === item.key
        );
        if (record && !isSWQLEmptyNode(record.query)) {
          // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
          result[item.key] = record.query;
        }
      });
      setStoredRules({ ...defaultRules, ...result });
      setRules({ ...defaultRules, ...result });
    }
  }, [loadingDefaultRules, JSON.stringify(mappingRuleList)]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    setLoadingDefaultRules(true);
    fetchMetaAndSetDefaultRule().then(() => setLoadingDefaultRules(false));
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    setActionsEnabled?.(!_.isEqual(rules, storedRules));
  }, [setActionsEnabled, rules, storedRules]);

  useEffect(() => {
    hasDisplayedRulesCallback?.(hasDisplayedRules);
  }, [rules]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (saving) {
      handleSave();
      afterAction();
    }
  }, [saving]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (cancelling) {
      setRules(storedRules);
      afterAction();
    }
  }, [cancelling]); // eslint-disable-line react-hooks/exhaustive-deps

  /**
   * RETURN JSX
   */

  return loading ? (
    <CircularProgress size={25} />
  ) : (
    <div className={classes.root}>
      {!_.isUndefined(progress) && (
        <Alert severity="warning">
          <FormattedMessage
            {...i18n.applyInProgress}
            values={{
              percent: intl.formatNumber(progress as number, {
                style: "percent",
                maximumFractionDigits: 0,
              }),
            }}
          />
        </Alert>
      )}
      {ruleList.map((rule) => (
        <MappingRuleRow
          rules={rules}
          setRules={setRules}
          currentRule={rule}
          fields={fields}
          integrationId={integrationId}
          swqlTarget={swqlTarget}
          isStatusRules={trackedLocation?.includes("status rules")}
        />
      ))}
    </div>
  );
};

// CSS

const useStyles = makeStyles()((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    gap: theme.spacing(2),
  },
}));

const i18n = defineMessages({
  applyInProgress: {
    id: "MappingRulesWidget.applyInProgress",
    defaultMessage:
      "We are currently computing this configuration ({percent} done)",
  },
});

export default withAPI(MappingRulesWidget);
