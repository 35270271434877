import SplashScreen from "components/ui/SplashScreen";
import useUserProfile from "hooks/useUserProfile";
import { get } from "lodash";
import { PayingFeature } from "models/CompanyPayingFeatureSubscription";
import { parse } from "query-string";
import { useSelector } from "react-redux";
import { matchPath, Redirect, RouteComponentProps } from "react-router";
import { selectActivePayingFeatures } from "redux/api/selectors";
import { selectLoggedIn } from "redux/user/selectors";

export const Orientation = ({ location }: RouteComponentProps) => {
  const { loggedIn } = useSelector(selectLoggedIn);
  const { profile } = useUserProfile();
  const payingFeatures = useSelector(selectActivePayingFeatures);

  const search = parse(window.location.search);
  const hasInvitation = matchPath(location.pathname, "/i/:invitationId");
  const invitationId = hasInvitation
    ? get(hasInvitation.params, "invitationId").split("?")[0]
    : null;
  const hasNewOnboarding = payingFeatures.includes(PayingFeature.NewOnboarding);
  const skipGetStarted =
    !hasNewOnboarding ||
    !Boolean(profile.isCompanyAdmin()) ||
    profile.company.finalOnboardingStepCompleted;

  if (!search.next && loggedIn) {
    return (
      <Redirect
        to={
          invitationId
            ? `/partnerships${location.pathname}`
            : skipGetStarted
            ? "/partnerships"
            : "/get-started"
        }
      />
    );
  }

  return <SplashScreen />;
};
