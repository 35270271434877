import Close from "@mui/icons-material/Close";
import Grid from "@mui/material/Grid";
import Button from "components/ui/Button";
import { defineMessages } from "react-intl";
import { useDispatch } from "react-redux";
import {
  resetTableFilters,
  setDateRangeFilter,
  setPartnerFilter,
} from "redux/pipeline/thunks";
import { HiddenByFiltersReasons } from "redux/pipeline/typing";

const i18n = defineMessages({
  removeFilters: {
    id: "NotificationActionRemovePipelineFilters.removeFilters",
    defaultMessage: "Remove filters",
  },
});

type Props = {
  onRemoveFilters: () => void;
  hiddenByFiltersReasons: HiddenByFiltersReasons;
};

const NotificationActionRemovePipelineFilters = ({
  onRemoveFilters,
  hiddenByFiltersReasons,
}: Props) => {
  const dispatch = useDispatch();

  const clearFilters = () => {
    if (hiddenByFiltersReasons.partnershipId) {
      dispatch(setPartnerFilter(null));
    }
    if (hiddenByFiltersReasons.dateRange) {
      dispatch(setDateRangeFilter(null));
    }
    if (hiddenByFiltersReasons.tableFilters) {
      dispatch(resetTableFilters());
    }
  };
  return (
    <Grid container spacing={1}>
      <Grid item>
        <Button
          id="remove-filters-btn"
          label={i18n.removeFilters}
          LeftIcon={Close}
          onClick={() => {
            clearFilters();
            onRemoveFilters();
          }}
          variant="senary"
        />
      </Grid>
    </Grid>
  );
};

export default NotificationActionRemovePipelineFilters;
