import useOktaLogin from "hooks/useOktaLogin";
import { parse } from "query-string";
import { ReactNode, useState } from "react";
import { useLocation } from "react-router-dom";

type Props = {
  idp: string;
  children: ReactNode;
  disabled?: boolean;
};

const OktaButton = ({ idp, children, disabled, ...props }: Props) => {
  const [loading, setLoading] = useState(false);
  const oktaLogin = useOktaLogin();
  const { search } = useLocation();

  const next = parse(search).next || "/";

  const login = async () => {
    setLoading(true);
    try {
      await oktaLogin(idp, next);
    } finally {
      setLoading(false);
    }
  };

  return (
    <button onClick={login} disabled={disabled || loading} {...props}>
      {children}
    </button>
  );
};

export default OktaButton;
