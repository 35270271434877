import { createSvgIcon } from "@mui/material/utils";

import { SvgIcon } from "./types";

const displayName = "Chart";

const Chart: SvgIcon = createSvgIcon(
  // Using transform to enlarge from provided viewbox="0 0 16 16"
  // to default "0 0 24 24" from `createSvgIcon` -> `SvgIcon`.
  <g fill="none" transform="matrix(1.5,0,0,1.5,0,0)">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3.00012 2.5C3.00012 2.22386 2.77626 2 2.50012 2C2.22398 2 2.00012 2.22386 2.00012 2.5V12.25C2.00012 13.2165 2.78362 14 3.75012 14H13.5001C13.7763 14 14.0001 13.7761 14.0001 13.5C14.0001 13.2239 13.7763 13 13.5001 13H3.75012C3.33591 13 3.00012 12.6642 3.00012 12.25V2.5ZM13.8357 5.87057C14.0403 5.68518 14.056 5.36898 13.8706 5.16432C13.6852 4.95966 13.369 4.94404 13.1643 5.12943L10.7448 7.32116C10.5543 7.49373 10.2639 7.49373 10.0734 7.32116L9.0981 6.43769C8.52658 5.91997 7.65562 5.91996 7.08408 6.43765L4.66434 8.62942C4.45967 8.8148 4.44404 9.131 4.62942 9.33566C4.8148 9.54033 5.131 9.55596 5.33566 9.37058L7.75541 7.17881C7.94593 7.00625 8.23624 7.00625 8.42675 7.17882L9.40206 8.0623C9.9736 8.58002 10.8446 8.58002 11.4161 8.0623L13.8357 5.87057Z"
      fill="currentColor"
    />
  </g>,
  displayName
);

Chart.displayName = displayName;
export default Chart;
