import { SettingsOutlined } from "@mui/icons-material";
import { Grid } from "@mui/material";
import Alert from "components/ui/Alert";
import Button from "components/ui/Button";
import SelectBoxGroup from "components/ui/SelectBoxGroup";
import SettingsSection from "components/ui/SettingsSection";
import LoaderTile from "components/ui/tiles/LoaderTile";
import { T } from "components/ui/Typography";
import { ProviderType } from "config/constants";
import getFirstValueFromURL from "helpers/getFirstValueFromURL";
import useAllRecords from "hooks/useAllRecords";
import usePushNotification from "hooks/usePushNotification";
import useSegment from "hooks/useSegment";
import useUserProfile from "hooks/useUserProfile";
import _ from "lodash";
import { makeStyles } from "makeStyles";
import CrmCredential from "models/CrmCredential";
import { API_ERROR } from "models/Integration";
import Partnership, { PartnerRepresentation } from "models/Partnership";
import { useMemo } from "react";
import { defineMessages, FormattedDate, FormattedMessage } from "react-intl";
import { useHistory, useLocation } from "react-router-dom";
import CrmTile from "screens/Frontoffice/screens/Sources/components/CrmTile";
import { OnboardingSteps } from "screens/Frontoffice/screens/Sources/types";
import { ConnectFileTile } from "screens/Frontoffice/shared/components/ConnectFileTile";
import { FileTemplateButtons } from "screens/Frontoffice/shared/components/FileTemplateButtons";
import LoadingDataDialog from "screens/Frontoffice/shared/components/LoadingDataDialog";
import SourceSettingsDialog from "screens/Frontoffice/shared/components/SourceSettingsDialog";
import {
  DataProviderType,
  isCrmFileOAuthProviderType,
} from "screens/Frontoffice/shared/helpers/types";

import { useSelectStyles } from "./DataSourcesTab";

type Props = {
  partnership: Partnership;
  partner: PartnerRepresentation;
  selectedIntegrationIds: number[];
  setSelectedIntegrationIds: (integrationIds: number[]) => void;
  reloadPartnership: () => void;
};

const GhostDataSources = ({
  partner,
  partnership,
  selectedIntegrationIds,
  setSelectedIntegrationIds,
  reloadPartnership,
}: Props) => {
  const { cx, classes } = useStyles();
  const { track } = useSegment();
  const { profile } = useUserProfile();
  const { classes: selectClasses } = useSelectStyles();
  const pushNotification = usePushNotification();
  const history = useHistory();
  const location = useLocation();
  const ghostIntegrationId = getFirstValueFromURL(location, "integration");
  const provider = getFirstValueFromURL(location, "provider");
  // Add an entitiesResource name because ghost_integrations are stored in the integrations in the entities
  const {
    records: ghostIntegrations,
    loading: loadingGhostIntegrations,
    refresh,
  } = useAllRecords(
    "ghost_integrations",
    {
      filters: { ghost_partnership_id: partnership.id },
      include: ["partnership_integrations"],
      fields: {
        integrations: [
          "company",
          "mapping_configured",
          "name",
          "provider",
          "default_source",
          "partnership_integrations",
          "api_error",
        ],
      },
    },
    () => undefined,
    [],
    undefined,
    "integrations"
  );

  const {
    loading: loadingCrmCredentials,
    records: crmCredentials,
  } = useAllRecords<CrmCredential>("crm_credentials", {
    filters: {
      company_id: profile.company.id,
    },
  });

  const gsheetCredentials = useMemo(
    () =>
      crmCredentials.filter(
        ({ config, provider, integrationId }) =>
          isCrmFileOAuthProviderType(provider) &&
          config.ghost_partnership_id &&
          ghostIntegrations.some(
            (integration) => integration.id === integrationId
          )
      ),
    [crmCredentials, ghostIntegrations]
  );

  const hasGsheetConnectionError = useMemo(
    () =>
      ghostIntegrations
        ?.filter((integration) =>
          gsheetCredentials.some(
            (credential) => credential.integrationId === integration.id
          )
        )
        .some(
          (integration) =>
            integration.apiError === API_ERROR.UNAUTHORIZED_CREDENTIALS
        ),
    [ghostIntegrations, gsheetCredentials]
  );

  const [
    selectedGhostId,
    otherSelectedIds,
  ] = _.partition(selectedIntegrationIds, (id) =>
    ghostIntegrations.map((item) => item.id).includes(id)
  );

  const selectGhostSource = (integrationId: number) => {
    if (integrationId !== selectedGhostId[0]) {
      setSelectedIntegrationIds([...otherSelectedIds, integrationId]);
    }
  };

  const onUploadSuccess = (integrationId: number) => {
    track("Connected data source - Offline Account Mapping", { crm: "file" });
    pushNotification(i18n.fileUploaded);
    // Refresh ghost integration list
    refresh();
    // Refresh partnership to update the selected ghost integration
    reloadPartnership();
    history.push({
      search: `integration=${integrationId}&provider=file&init=true`,
      hash: "column-mapping-private",
    });
  };

  const options = _.sortBy(ghostIntegrations, ["createdAt", "id"])
    .reverse()
    .filter((integration) => integration.provider !== ProviderType.gsheet)
    .map((integration) => {
      const content = (
        <div className={classes.optionContainer}>
          <img
            className={classes.logo}
            src={
              integration.provider === ProviderType.gsheet
                ? "/images/providers/Gsheet.png"
                : "/images/providers/CSV.png"
            }
            alt=""
          />
          <T
            h4
            bold
            className={cx({
              [classes.greyText]: !(integration.id === selectedGhostId[0]),
            })}
          >
            {integration.name}
          </T>
        </div>
      );
      const handleSettingsClick = () => {
        history.push({
          search: `integration=${integration.id}&provider=${integration.provider}`,
          hash: "column-mapping-private",
        });
      };
      const outsideContent = (
        <div className={classes.rightContent}>
          <T>
            <FormattedDate value={integration.createdAt} />
          </T>
          <Button
            label=""
            size="small"
            LeftIcon={SettingsOutlined}
            onClick={handleSettingsClick}
            data-testid="settings-btn"
            variant="tertiary"
          />
        </div>
      );
      return {
        value: integration.id,
        content,
        disabled: integration.id === selectedGhostId[0],
        outsideContent,
      };
    })
    .concat(
      gsheetCredentials.map((credential) => ({
        content: (
          <CrmTile
            isSelected={credential.integrationId === selectedGhostId[0]}
            ghostPartnershipId={partnership.id}
            key={credential.id}
            recordId={credential.id}
          />
        ),
        value: credential.integrationId ?? 0,
        disabled: credential.integrationId === selectedGhostId[0],
        outsideContent: <></>,
      }))
    );

  const loading = loadingGhostIntegrations || loadingCrmCredentials;

  return (
    <SettingsSection
      title={
        <FormattedMessage
          {...i18n.partnerDataSource}
          values={{ partner: partner.name }}
        />
      }
      subtitle={
        <FileTemplateButtons
          title={
            <FormattedMessage
              {...i18n.subtitle}
              values={{ partner: partner.name }}
            />
          }
        />
      }
    >
      <Grid container>
        {!loading && hasGsheetConnectionError && (
          <Grid item xs={12} className={classes.marginBottom}>
            <Alert variant="error" icon>
              <FormattedMessage {...i18n.gsheetConnectionError} />
            </Alert>
          </Grid>
        )}
        {!loading && !options.length && (
          <Grid item xs={12}>
            <Alert variant="warning" icon>
              <FormattedMessage {...i18n.noFileSourceAlert} />
            </Alert>
          </Grid>
        )}
        {!loading && (
          <SelectBoxGroup
            multiSelect={false}
            values={selectedGhostId}
            onSelect={selectGhostSource}
            options={options}
            orientation="vertical"
            classes={{
              ...selectClasses,
              innerTile: selectClasses.innerTilePaddingless,
            }}
          />
        )}
        {loading && <LoaderTile size="sm" />}
        <div className={classes.buttonGroup}>
          <ConnectFileTile
            partnershipId={partnership.id}
            onUploadSuccess={onUploadSuccess}
          />
        </div>
      </Grid>
      <LoadingDataDialog
        notReadyText={i18n.fieldsNotReady}
        notReadyHash={OnboardingSteps.fieldsNotReady}
      />
      {ghostIntegrationId && (
        <SourceSettingsDialog
          integrationId={+(ghostIntegrationId ?? 0)}
          sourceType={provider as DataProviderType}
          isGhost
          integrationCount={0}
          hasPartnerships={profile.hasPartnerships()}
          refresh={refresh}
        />
      )}
    </SettingsSection>
  );
};

export default GhostDataSources;

const useStyles = makeStyles()((theme) => ({
  buttonGroup: {
    marginTop: theme.spacing(3),
  },
  greyText: {
    color: theme.palette.greyReveal,
  },
  root: {
    display: "flex",
    flexDirection: "column",
    rowGap: theme.spacing(1),
  },
  container: {
    display: "flex",
    justifyContent: "space-between",
    paddingBottom: theme.spacing(3),
  },
  link: {
    display: "flex",
    alignItems: "center",
    columnGap: theme.spacing(0.5),
    color: theme.palette.midnight,
    "&:hover": {
      color: theme.palette.darkPurple,
    },
  },
  marginBottom: {
    marginBottom: theme.spacing(2.5),
  },
  downloadLogo: {
    width: 12,
    height: 12,
  },
  optionContainer: {
    display: "flex",
    alignItems: "center",
    columnGap: theme.spacing(1.5),
    margin: `${theme.spacing(2)} ${theme.spacing(2)}`,
  },
  rightContent: {
    position: "absolute",
    display: "flex",
    alignItems: "center",
    columnGap: theme.spacing(1.5),
    top: 26,
    right: theme.spacing(2),
  },
  logo: {
    width: 28,
    height: 28,
    borderRadius: 4,
  },
}));

const i18n = defineMessages({
  partnerDataSource: {
    id: "crm.PartnerhsipSettings.GhostDataSources.partnerDataSource",
    defaultMessage: "{partner}'s data source",
  },
  fieldsNotReady: {
    id: "crm.PartnerhsipSettings.GhostDataSources.fieldsNotReady",
    defaultMessage:
      "It's taking some time to import your fields. Please refresh this page in a few minutes.",
  },
  fileUploaded: {
    id: "crm.PartnerhsipSettings.fileUploaded",
    defaultMessage: "File successfully uploaded 👌",
  },
  gsheetConnectionError: {
    id: "crm.PartnerhsipSettings.gsheetConnectionError",
    defaultMessage: "Unable to sync. Please reconnect Google Sheets.",
  },
  noFileSourceAlert: {
    id: "crm.PartnerhsipSettings.noFileSourceAlert",
    defaultMessage:
      "No Google Sheet or CSV is connected now. Connect to get started.",
  },
  subtitle: {
    id: "crm.PartnerhsipSettings.subtitle",
    defaultMessage: "Add a Google Sheet or CSV on behalf of {partner}",
  },
});
