import { createSvgIcon } from "@mui/material/utils";

import { SvgIcon } from "./types";

const displayName = "Paid";

const Paid: SvgIcon = createSvgIcon(
  // Using transform to enlarge from provided viewbox="0 0 16 16"
  // to default "0 0 24 24" from `createSvgIcon` -> `SvgIcon`.
  <g fill="none" transform="matrix(1.5,0,0,1.5,0,0)">
    <g clipPath="url(#clip0_588_2786)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.5 0C3.58688 1.61951 4.88049 2.91312 6.5 3C4.88049 3.08688 3.58688 4.38049 3.5 6C3.41312 4.38049 2.11951 3.08688 0.5 3C2.11951 2.91312 3.41312 1.61951 3.5 0ZM9 2.5C9.17375 5.73901 11.761 8.32625 15 8.5C11.761 8.67375 9.17375 11.261 9 14.5C8.82625 11.261 6.23901 8.67375 3 8.5C6.23901 8.32625 8.82625 5.73901 9 2.5ZM16.5 13.5C15.1504 13.4276 14.0724 12.3496 14 11C13.9276 12.3496 12.8496 13.4276 11.5 13.5C12.8496 13.5724 13.9276 14.6504 14 16C14.0724 14.6504 15.1504 13.5724 16.5 13.5Z"
        fill="currentColor"
      />
    </g>
    <defs>
      <clipPath id="clip0_588_2786">
        <rect width="16" height="16" fill="white" transform="translate(0.5)" />
      </clipPath>
    </defs>
  </g>,
  displayName
);

Paid.displayName = displayName;
export default Paid;
