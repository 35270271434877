export enum StorageActions {
  persist = "storage/persist",
  clear = "storage/clear",
}

export type StorageState = { [key: string]: any };

export interface RevealStateWithStorage {
  storage: StorageState;
}
