import { Email } from "components/icons";
import CompanyAvatar from "components/ui/avatars/CompanyAvatar";
import { T } from "components/ui/Typography";
import { makeStyles } from "makeStyles";
import { defineMessages, FormattedMessage, useIntl } from "react-intl";

type Props = {
  externalChannelName: string | null;
  internalChannelName: string | null;
  ownerName: string | null;
  partnerName: string | null;
  shouldEmailPartnershipManager: boolean;
  shouldEmailAccountOwner: boolean;
};

export const IntroRouting = ({
  externalChannelName,
  internalChannelName,
  ownerName,
  partnerName,
  shouldEmailPartnershipManager,
  shouldEmailAccountOwner,
}: Props) => {
  const { classes } = useStyles({});
  const intl = useIntl();

  return (
    <div className={classes.root}>
      {ownerName && <Label name={ownerName} isInternal />}
      {internalChannelName && (
        <Label name={internalChannelName} isSlack isInternal />
      )}
      {shouldEmailPartnershipManager && (
        <Label
          name={intl.formatMessage(i18n.partnershipOwner, {
            partner: partnerName,
          })}
        />
      )}
      {shouldEmailAccountOwner && (
        <Label
          name={intl.formatMessage(i18n.accountOwner, { partner: partnerName })}
        />
      )}
      {externalChannelName && <Label name={externalChannelName} isSlack />}
    </div>
  );
};

type LabelProps = {
  name: string;
  isInternal?: boolean;
  isSlack?: boolean;
};

const Label = ({ name, isInternal = false, isSlack = false }: LabelProps) => {
  const { classes } = useStyles({ isInternal });

  return (
    <div className={classes.container}>
      {isSlack ? (
        <CompanyAvatar src={"/images/slack-logo.png"} size="xxs" />
      ) : (
        <Email className={classes.logo} />
      )}
      <T className={classes.name}>
        {isSlack ? `#${name}` : name}{" "}
        <FormattedMessage {...(isInternal ? i18n.internal : i18n.external)} />
      </T>
    </div>
  );
};

export const useStyles = makeStyles<{ isInternal?: boolean }>()(
  (theme, { isInternal }) => ({
    root: {
      display: "flex",
      flexDirection: "column",
      rowGap: 6,
      width: 350,
    },
    container: {
      borderRadius: 4,
      padding: "1px 4px",
      display: "flex",
      alignItems: "center",
      width: "fit-content",
      columnGap: 4,
      backgroundColor: isInternal
        ? theme.palette.blue100
        : theme.palette.red100,
    },
    logo: {
      fontSize: 12,
      color: isInternal ? "#0176D3" : theme.palette.red500v2,
    },
    name: {
      color: isInternal ? theme.palette.midnight : theme.palette.red700v2,
    },
  })
);

/// I18N

const i18n = defineMessages({
  external: {
    id: "mapping360.RequestIntro.IntroRouting.external",
    defaultMessage: "(External)",
  },
  internal: {
    id: "mapping360.RequestIntro.IntroRouting.internal",
    defaultMessage: "(Internal)",
  },
  partnershipOwner: {
    id: "mapping360.RequestIntro.IntroRouting.partnershipOwner",
    defaultMessage: "Partnership owner at {partner}",
  },
  accountOwner: {
    id: "mapping360.RequestIntro.IntroRouting.accountOwner",
    defaultMessage: "Account owner at {partner}",
  },
});
