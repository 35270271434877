import {
  AnyAction,
  createAsyncThunk,
  isFulfilled,
  isRejected,
  ThunkDispatch,
} from "@reduxjs/toolkit";
import _ from "lodash";
import { Factory } from "models";
import { PageType } from "models/PageView";
import { JSONAPIAttributes, JSONAPIResource } from "models/types";
import { create, index, update } from "redux/api/thunks";
import {
  asJSONSerializable,
  DateRangeFilterType,
  DateRangeTimeframe,
  RevealStore,
} from "redux/typing";
import { isCustomRangeFilter, updateLastVisitedAt } from "redux/utils";
import {
  asPersistedColumn,
  ColumnConfigType,
  FilterType,
  MatchFilterType,
  SortType,
} from "screens/Frontoffice/screens/DataTables/shared/types";
import JSONAPIService from "services/JSONAPIService";
import { JSONAPIResponse } from "services/types";
import UserService from "services/UserService";

import {
  AvailablePipelineAttributeColumns,
  defaultPipelineAttributeColumns,
  defaultPipelineAttributeSort,
} from "./defaults";
import { selectShowArchived } from "./selectors";
import {
  PipelineAttributeActions,
  PipelineAttributeExpectedState,
  PipelineField,
  RawPipelineField,
} from "./typing";

export const loadView = createAsyncThunk(
  PipelineAttributeActions.loadView,
  async (_: void, thunkAPI) => {
    const state = thunkAPI.getState() as PipelineAttributeExpectedState;
    if (state.pipelineAttribute.view) {
      return state.pipelineAttribute.view;
    }

    const indexView = await thunkAPI.dispatch(
      index({
        type: "page_views",
        options: {
          filters: {
            page_type: PageType.pipelineAttribute,
          },
        },
      })
    );

    if (isRejected(indexView)) {
      throw new Error("Cannot load pipeline view");
    }

    let recordData: JSONAPIResource;
    if (indexView.payload.data.length > 0) {
      recordData = indexView.payload.data[0];
      const record = Factory.createRecord(recordData);
      await updateLastVisitedAt(
        record.id,
        thunkAPI.dispatch as ThunkDispatch<RevealStore, any, AnyAction>
      );
      return record;
    } else {
      const result = await thunkAPI.dispatch(
        create({
          type: "page_views",
          attributes: {
            page_type: PageType.pipelineAttribute,
            filters: [],
            sort: defaultPipelineAttributeSort,
            columns: asJSONSerializable(
              defaultPipelineAttributeColumns.map(asPersistedColumn)
            ),
          },
        })
      );
      if (isFulfilled(result)) {
        const response = result.payload as JSONAPIResponse;
        recordData = response.data;
        const record = Factory.createRecord(recordData);
        await updateLastVisitedAt(
          record.id,
          thunkAPI.dispatch as ThunkDispatch<RevealStore, any, AnyAction>
        );
        return record;
      }
    }
  }
);

export const updateView = createAsyncThunk(
  PipelineAttributeActions.updateView,
  async (
    {
      filters,
      columns,
      sort,
      lastVisitedAt,
      filterOrderList,
    }: {
      filters?: FilterType[];
      columns?: ColumnConfigType[];
      sort?: SortType[];
      lastVisitedAt?: Date;
      filterOrderList?: number[];
    },
    thunkAPI
  ) => {
    const state = thunkAPI.getState() as PipelineAttributeExpectedState;
    const recordId = state.pipelineAttribute.view?.id;
    if (recordId === undefined) {
      throw new Error("No view available for pipeline from redux store");
    }

    const attributes: JSONAPIAttributes = {};
    if (filters !== undefined) {
      attributes["filters"] = asJSONSerializable(filters);
    }
    if (columns !== undefined) {
      attributes["columns"] = asJSONSerializable(
        columns.map(asPersistedColumn)
      );
    }
    if (sort !== undefined) {
      attributes["sort"] = asJSONSerializable(sort);
    }
    if (filterOrderList !== undefined) {
      attributes["filter_order_list"] = filterOrderList;
    }
    if (lastVisitedAt !== undefined) {
      const userService = new UserService();
      if (!userService.isImpersonating) {
        attributes["last_visited_at"] = lastVisitedAt.toISOString();
      }
    }

    if (_.isEmpty(attributes)) {
      return state.pipelineAttribute.view;
    }

    const result = await thunkAPI.dispatch(
      update({
        id: recordId,
        type: "page_views",
        attributes,
      })
    );
    if (isFulfilled(result)) {
      const response = result.payload as JSONAPIResponse;
      const record = Factory.createRecord(response.data);
      return record;
    } else {
      throw new Error("Cannot update pipeline view");
    }
  }
);

export const initializePipelineAttributeFields = createAsyncThunk(
  PipelineAttributeActions.initializeFields,
  async (_args: void) => {
    const service = new JSONAPIService("attributed_opportunities");
    const resourceDescriptionResponse = await service.describe<{
      data: {
        included: { raw_opportunities: { fields: RawPipelineField[] } };
      };
    }>(true);
    const opportunityFields =
      resourceDescriptionResponse.data.data?.included?.raw_opportunities
        ?.fields || [];

    const processedFields = opportunityFields.reduce(
      (current: Record<string, PipelineField>, field: RawPipelineField) => ({
        ...current,
        [_.camelCase(field.name)]: {
          label: field.label,
          type: field.type,
          fullyImported: Boolean(field.fully_imported),
          displayIndex: field.display_index,
          partnershipId: field.partnership_id,
        },
      }),
      {} as Record<string, PipelineField>
    );

    return { fields: processedFields };
  }
);

export const setPartnerFilter = createAsyncThunk(
  PipelineAttributeActions.setPartnerFilter,
  async (partnershipIds: number[] | null, thunkAPI) => {
    const state = thunkAPI.getState() as RevealStore;
    const _filters: FilterType[] = state.pipelineAttribute.view?.filters ?? [];
    const otherFilters = _filters.filter(
      (item) => !["partner_attribution"].includes(item.fieldname) ?? []
    );

    const filters = [...otherFilters];

    if (partnershipIds !== null && partnershipIds.length > 0) {
      filters.push({
        fieldname: "partner_attribution",
        type: MatchFilterType.CONTAINS_ANY,
        value: partnershipIds,
      });
    }

    thunkAPI.dispatch(
      updateView({
        filters,
      })
    );
  }
);

export const toggleArchivedFilter = createAsyncThunk(
  PipelineAttributeActions.toggleArchivedFilter,
  async (_: undefined, thunkAPI) => {
    const state = thunkAPI.getState() as RevealStore;
    const _filters: FilterType[] = state.pipelineAttribute.view?.filters ?? [];
    const filters =
      _filters.filter((item) => item.fieldname !== "archived") ?? [];

    filters.push({
      fieldname: "archived",
      type: MatchFilterType._NO_OPERATOR,
      value: !selectShowArchived(state),
    });

    thunkAPI.dispatch(
      updateView({
        filters,
      })
    );
  }
);

export const setDateRangeFilter = createAsyncThunk(
  PipelineAttributeActions.setDateRangeFilter,
  async (dateRange: DateRangeFilterType | null, thunkAPI) => {
    const state = thunkAPI.getState() as RevealStore;
    const _filters: FilterType[] = state.pipelineAttribute.view?.filters ?? [];
    const filters =
      _filters.filter((item) => item.fieldname !== "date_range") ?? [];

    let value = dateRange;
    if (
      dateRange !== null &&
      isCustomRangeFilter(dateRange) &&
      dateRange.selectionRange.start !== null &&
      dateRange.selectionRange.end !== null
    ) {
      const dates = [
        dateRange.selectionRange.start,
        dateRange.selectionRange.end,
      ];
      value = {
        rangeType: DateRangeTimeframe.customRange,
        selectionRange: {
          start: _.min(dates) ?? null,
          end: _.max(dates) ?? null,
        },
      };
    }

    filters.push({
      fieldname: "date_range",
      type: MatchFilterType._NO_OPERATOR,
      value,
    });
    thunkAPI.dispatch(
      updateView({
        filters,
      })
    );
  }
);

export const setOwnerFilter = createAsyncThunk(
  PipelineAttributeActions.setOwnerFilter,
  async (userNames: string[] | null, thunkAPI) => {
    const state = thunkAPI.getState() as RevealStore;
    const _filters: FilterType[] = state.pipelineAttribute.view?.filters ?? [];
    const otherFilters = _filters.filter(
      (item) =>
        ![AvailablePipelineAttributeColumns.owner as string].includes(
          item.fieldname
        ) ?? []
    );

    const filters = [...otherFilters];

    if (userNames) {
      filters.push({
        fieldname: AvailablePipelineAttributeColumns.owner,
        type: MatchFilterType.ANY_OF,
        value: userNames,
      });
    }

    thunkAPI.dispatch(
      updateView({
        filters,
      })
    );
  }
);

export const removeAllFiltersExceptView = createAsyncThunk(
  PipelineAttributeActions.removeAllFiltersExceptView,
  async (_: undefined, thunkAPI) => {
    const state = thunkAPI.getState() as RevealStore;
    const _filters: FilterType[] = state.pipelineAttribute.view?.filters ?? [];
    const neededFilters =
      _filters.filter((item) =>
        ["user", "archived"].includes(item.fieldname)
      ) ?? [];

    thunkAPI.dispatch(
      updateView({
        filters: neededFilters,
      })
    );
  }
);

export const resetTableFilters = createAsyncThunk(
  PipelineAttributeActions.resetTableFilters,
  async (_: undefined, thunkAPI) => {
    const state = thunkAPI.getState() as RevealStore;
    const _filters: FilterType[] = state.pipelineAttribute.view?.filters ?? [];
    const neededFilters =
      _filters.filter((item) =>
        ["user", "archived", "date_range", "partnership"].includes(
          item.fieldname
        )
      ) ?? [];

    thunkAPI.dispatch(
      updateView({
        filters: neededFilters,
      })
    );
  }
);
